export const tabXPadding = 28; // Left padding 16 px and padding around drag handle 12 px
export const iconSize = 18;
export const gap = 8;
export const dropDownXPadding = 16 * 2;
export const safetyMargin = 16;
export const dropDownWidth = dropDownXPadding + 2 * iconSize + gap;
export const addButtonWidth = 38;
export const helpButtonWidth = 40; //paddingRight (base 16px) + icon (24px) = 40px
/*
 * @param textsElements - Array of text elements
 * @param selectedIndex - Index of the selected element
 * @param left - the position of where the container starts
 * @param windowWidth - the width of the visible window
 * @returns The index of the first element that overflows the container or -1 if none of the elements overflow
 */
export const getOverflowingIndex = (
    elementWidths: number[],
    selectedIndex: number,
    left: number,
    windowWidth: number,
): number => {
    let totalWidth = left + dropDownWidth + addButtonWidth + helpButtonWidth + safetyMargin;
    let isDropDownNameAdded = false;
    let i = 0;
    for (; i < elementWidths.length; i++) {
        totalWidth += elementWidths[i];
        if (totalWidth > windowWidth) {
            // Found overflowing index
            if (selectedIndex >= i) {
                // Selected index is overflowing, so we need to hide more elements
                for (; i >= 0; ) {
                    // if selected index is in dropdown menu we need to add the name to the totalWidth
                    // width of selected floorplan name should only be added once
                    if (!isDropDownNameAdded) {
                        totalWidth += elementWidths[selectedIndex];
                        isDropDownNameAdded = true;
                    }

                    totalWidth -= elementWidths[i];
                    if (totalWidth <= windowWidth) {
                        // Found index where the container stops overflowing
                        break;
                    }
                    --i;
                }
            }

            return i;
        }
    }

    return -1;
};
