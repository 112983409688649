import { KeyValueStore } from 'axis-webtools-util';
import type { ICurrencies, ICurrency } from '../models/currency.interfaces';
import type { IDistributor, IDistributorRecord } from '../models/distributor.interfaces';
import type { IDatabasePrices, IPrice, IPriceRecord } from '../models/price.interfaces';

const dbName: string = 'axis.webtools.msrp';
const distributorsTableName: string = 'distributors';
const currencyTableName: string = 'currency';
const lastFetchTableName: string = 'lastFetch';
const isAuthorizedTableName: string = 'isAuthorized';
const msrpTableName: string = 'msrp';

export class Repository {
    private distributorsStore: KeyValueStore<IDistributor>;
    private lastFetchStore: KeyValueStore<number>;
    private currencyStore: KeyValueStore<ICurrency>;
    private isAuthorizedStore: KeyValueStore<boolean>;
    private msrpStore: KeyValueStore<string>;
    private priceStores: Record<string, KeyValueStore<IPrice>>;

    constructor() {
        this.distributorsStore = new KeyValueStore<IDistributor>(dbName, distributorsTableName);
        this.lastFetchStore = new KeyValueStore<number>(dbName, lastFetchTableName);
        this.currencyStore = new KeyValueStore<any>(dbName, currencyTableName);
        this.isAuthorizedStore = new KeyValueStore<boolean>(dbName, isAuthorizedTableName);
        this.msrpStore = new KeyValueStore<string>(dbName, msrpTableName);
        this.priceStores = {};
    }

    public setIsAuthorized(value: boolean): Promise<void> {
        return this.isAuthorizedStore.setItem('authorized', value);
    }

    public getIsAuthorized(): Promise<boolean | undefined> {
        return this.isAuthorizedStore.getItem('authorized').catch(() => undefined);
    }

    public deleteIsAuthorized(): Promise<void> {
        return this.isAuthorizedStore.clear();
    }

    public setLastFetch(key: string, value: number): Promise<void> {
        return this.lastFetchStore.setItem(key, value).catch(() => {});
    }

    public getLastFetch(key: string): Promise<number | undefined> {
        return this.lastFetchStore.getItem(key).catch(() => undefined);
    }

    public deleteLastFetch(): Promise<void> {
        return this.lastFetchStore.clear();
    }

    public async getDistributors(): Promise<IDistributor[]> {
        const distributors: IDistributorRecord =
            (await this.distributorsStore.getItems()) as IDistributorRecord;
        return Object.keys(distributors).map((locid: string) => distributors[locid]);
    }

    public setDistributors(distributors: IDistributorRecord): Promise<void> {
        return this.distributorsStore.setItems(distributors);
    }

    public deleteDistributors(): Promise<void> {
        return this.distributorsStore.clear();
    }

    public async getPrices(priceList: string, partNumbers?: string[]): Promise<IDatabasePrices> {
        const store = this.getOrCreateStore(priceList);
        const [prices, currencyCode] = await Promise.all([
            store.getItems(partNumbers || null),
            this.msrpStore.getItem(priceList),
        ]);

        return { prices, currencyCode };
    }

    public setPrices(
        priceList: string,
        currencyCode: string,
        prices: IPriceRecord,
    ): Promise<void[]> {
        const store = this.getOrCreateStore(priceList);
        return Promise.all([
            store.setItems(prices),
            this.msrpStore.setItem(priceList, currencyCode),
        ]);
    }

    public deletePriceList(priceList: string): Promise<void[]> {
        const store = this.getOrCreateStore(priceList);
        return Promise.all([this.msrpStore.removeItem(priceList), store.clear()]);
    }

    public deleteAllPricesLists(priceLists: string[]): Promise<void[][]> {
        return Promise.all(priceLists.map((priceList: string) => this.deletePriceList(priceList)));
    }

    public getCurrency(currency: string): Promise<ICurrency | undefined> {
        return this.currencyStore.getItem(currency).catch(() => undefined);
    }

    public getCurrencies(): Promise<ICurrencies> {
        return this.currencyStore.getItems();
    }

    public async setCurrencies(currencies: ICurrencies): Promise<void> {
        await this.deleteCurrencies();
        return this.currencyStore.setItems(currencies).catch(() => {});
    }

    public deleteCurrencies(): Promise<void> {
        return this.currencyStore.clear();
    }

    public async clearPriceData(): Promise<void> {
        const distributors = await this.getDistributors();
        const pricesLists = distributors.map((distributor) => {
            return distributor.priceList;
        });

        await this.deleteAllPricesLists(pricesLists);
        await this.deleteDistributors();
        await this.deleteLastFetch();
    }

    public async dropDatabase(): Promise<void> {
        await this.clearPriceData();
        await this.deleteIsAuthorized();
    }

    private getOrCreateStore(tableName: string): KeyValueStore<number> {
        if (tableName in this.priceStores) {
            return this.priceStores[tableName];
        }

        return new KeyValueStore(dbName, tableName);
    }
}

export default new Repository();
