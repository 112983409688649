import * as React from 'react';
import {
    Box,
    Modal,
    Button,
    Spacer,
    PiaImage,
    Stack,
    Text,
    Linkable,
    Clickable,
    IconText,
    Card,
    LinkButton,
} from 'app/components';
import { t } from 'app/translate';
import { t as i18nextT } from 'i18next';
import { useService } from 'app/ioc';
import type { Id } from 'app/core/persistence';
import { CustomItemService } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { IApplicationItem } from 'app/modules/common';
import {
    getPiaItem,
    getIsOtherSelectorOpen,
    getIsPartnerProductAdded,
    getIsStandalone,
    isAxis,
    hasApdParamsBoth,
} from 'app/modules/common';
import type { IPiaItem } from 'app/core/pia';
import { MoreFromVendor } from './MoreFromVendor';
import {
    getFullPartnerContactLink,
    getIsApplicationAddedToDevice,
    getPartnerProductsByVendorId,
    getReadMoreMaxHeight,
    getReadMoreTopPosition,
} from '../selectors';
import { ApplicationInfo } from './ApplicationInfo';
import { ApplicationBulletPoints } from './ApplicationBulletPoints';
import { PartnerLogo } from './PartnerLogo';
import { AddonSelectorActionService, AddonSelectorService } from '../services';

interface IReadModalProps {
    application: IApplicationItem;
    parentDeviceId?: Id;
}

export const ReadMoreModal: React.FC<IReadModalProps> = ({ application, parentDeviceId }) => {
    const topPosition = useSelector(getReadMoreTopPosition);
    const maxHeight = useSelector(getReadMoreMaxHeight);
    const isAxisProduct = isAxis(application.vendor);
    const isStandAlone = useSelector(getIsStandalone);
    const shortenedUrl = isAxisProduct ? undefined : application.url?.replace('https://', '');
    const vendorId = isAxisProduct ? undefined : application.vendorId;
    const { name, vendor, productId } = application;
    const contactLink = useSelector<IStoreState, string>((state) =>
        getFullPartnerContactLink(state, vendor, name),
    );
    const addonSelectorService = useService(AddonSelectorService);
    const addonSelectorActionService = useService(AddonSelectorActionService);
    const closeReadMore = () => addonSelectorActionService.setShowReadMoreModal({ show: false });
    const isPartnerProductOtherSelector = useSelector(getIsOtherSelectorOpen);
    const isApplicationAdded = useSelector<IStoreState, boolean>((state) =>
        getIsApplicationAddedToDevice(state, parentDeviceId, application.productId),
    );
    const isPartnerProductAdded = useSelector<IStoreState, boolean>((state) =>
        getIsPartnerProductAdded(state, application.productId),
    );
    const productsByVendor = useSelector<IStoreState, IApplicationItem[]>((state) =>
        getPartnerProductsByVendorId(state, vendorId),
    );

    const isAdded = isPartnerProductOtherSelector ? isPartnerProductAdded : isApplicationAdded;
    const customItemService = useService(CustomItemService);
    const deselectApplication = () => addonSelectorActionService.setClickedApplication(undefined);

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, parentDeviceId),
    );
    const onToggleAction = () => {
        closeReadMore();
        addonSelectorActionService.setIsOtherProductSelectorOpen(false);
        addonSelectorActionService.setShowPartnerAcapListModal(false);
        deselectApplication();
    };

    const toggleApplication = async () => {
        if (isStandAlone || (!isPartnerProductOtherSelector && !parentDeviceId)) {
            return;
        }

        parentDeviceId
            ? await addonSelectorService.toggleApplication(
                  parentDeviceId,
                  application,
                  !!piaItem && hasApdParamsBoth(piaItem),
              )
            : await customItemService.toggleCustomItem(application);
        onToggleAction();
    };

    // Modal displayed for AXIS applications
    if (isAxis(application.vendor)) {
        return (
            <Modal title={name} minWidth="600px" maxWidth="600px" onClose={closeReadMore}>
                <Box direction="column" width="100%" padding="base" testId={'read-more-modal'}>
                    <Stack alignItems="start">
                        <PiaImage
                            piaId={productId}
                            icon="extension"
                            iconProps={{
                                color: 'grey6',
                                opaque: true,
                            }}
                            imageSize="xxl"
                        />
                        <ApplicationInfo productId={productId} vendorName={vendor} />
                    </Stack>

                    <Spacer />
                    <ApplicationBulletPoints productId={productId} />
                    <Box justifyContent="end" paddingTop="base">
                        <Button text onClick={closeReadMore}>
                            {t.close}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        );
    }

    // Modal displayed for partner products
    return (
        <Modal
            title={name}
            minWidth={isStandAlone ? '0px' : '600px'}
            maxWidth={`${Math.min(
                838,
                // Use frame width as max width if within a frame
                window.innerWidth,
            )}px`}
            maxHeight={isStandAlone ? maxHeight : undefined}
            onClose={closeReadMore}
            transparentBackdropColor={isStandAlone}
            top={isStandAlone ? topPosition : undefined}
        >
            <Stack vertical>
                <Text color="grey6">{vendor}</Text>
                <ApplicationInfo
                    productId={productId}
                    longDescription
                    vendorName={application.vendor}
                />
                {!isStandAlone && (
                    <Box>
                        <Button
                            testId={isAdded ? `read_more_remove_${name}` : `read_more_add_${name}`}
                            primary
                            onClick={() => {
                                toggleApplication();
                            }}
                        >
                            {isAdded ? t.removePiaDevice(name) : t.addPiaDevice(name)}
                        </Button>
                    </Box>
                )}
                <Card color="grey2">
                    <Stack vertical>
                        <Box padding="base" direction="column" testId="aboutVendor">
                            <Text bold>
                                {i18nextT('aboutVendor', {
                                    vendor,
                                    ns: 'partnerProducts',
                                })}
                            </Text>
                            <Stack vertical spacing="half">
                                {vendorId && (
                                    <ApplicationInfo
                                        productId={vendorId}
                                        longDescription
                                        vendorName={application.vendor}
                                    />
                                )}
                                {vendorId && (
                                    <Box maxHeight={70} justifyContent="start" alignItems="center">
                                        <PartnerLogo vendorPiaId={vendorId} />
                                    </Box>
                                )}
                                <Stack>
                                    <LinkButton link={contactLink} external>
                                        {t.contact}
                                    </LinkButton>
                                    {shortenedUrl && (
                                        <Linkable external link={application.url}>
                                            <Clickable>
                                                <IconText
                                                    testId="application_url_link"
                                                    iconProps={{ size: 'sm' }}
                                                    textProps={{
                                                        color: 'blue',
                                                        bold: true,
                                                        wordBreak: 'break-word',
                                                    }}
                                                    color="blue"
                                                    icon="arrow_forward"
                                                    alignIconRight
                                                >
                                                    {shortenedUrl}
                                                </IconText>
                                            </Clickable>
                                        </Linkable>
                                    )}
                                </Stack>
                            </Stack>
                        </Box>
                        {vendorId && productsByVendor.length > 1 && (
                            <MoreFromVendor
                                vendorName={vendor}
                                vendorId={vendorId}
                                selectedId={productId}
                            />
                        )}
                    </Stack>
                </Card>
            </Stack>
        </Modal>
    );
};

ReadMoreModal.displayName = 'ReadMoreModal';
