import { injectable } from 'inversify';
import { KeyValueStore } from 'axis-webtools-util';
import { PartnerConfigCommunicator } from '../communicator/PartnerConfig.communicator';
import { t } from 'app/translate';
import { getUrlPartnerId } from 'app/partnerUrl';
import type { IPartnerConfigServerResponse } from '../models';
import { eventTracking } from 'app/core/tracking';
import type { AccessType } from '../models/AccessType';
import { toaster } from 'app/toaster';
import { saveAs } from 'file-saver';

const PARTNER_CONFIG_FILENAME = 'partnerconfig.json';
const PARTNER_CONFIG_KEY = 'partnerConfig';
const USE_ALLOWLIST_KEY = 'useAllowlist';

@injectable()
export class PartnerConfigService {
    private partnerConfigStore = new KeyValueStore<IPartnerConfigServerResponse>(
        'axis.webtools.partnerConfig',
        PARTNER_CONFIG_KEY,
    );
    private useAllowlistStore = new KeyValueStore<string>(
        'axis.webtools.partnerConfig',
        USE_ALLOWLIST_KEY,
    );

    constructor(private partnerConfigCommunicator: PartnerConfigCommunicator) {}

    // Even though there is an allowlist, user might have toggled menu not to use it.
    public async getUseProductAllowlist(id: string): Promise<boolean> {
        const useAllowlist = await this.useAllowlistStore.getItem(id);
        return useAllowlist ? useAllowlist === 'true' : true;
    }

    public async clear(): Promise<void> {
        await this.useAllowlistStore.clear();
        return this.partnerConfigStore.clear();
    }

    public async setUseAllowlist(id: string, useAllowlist: boolean): Promise<boolean> {
        await this.useAllowlistStore.setItem(id, JSON.stringify(useAllowlist));
        return useAllowlist;
    }

    public async getPartnerConfig(
        locid: number | null | undefined,
        isOnline: boolean,
    ): Promise<IPartnerConfigServerResponse> {
        let partnerConfig: IPartnerConfigServerResponse | null = null;

        // URL has precedence over locid
        const partnerId = getUrlPartnerId();
        if (partnerId) {
            partnerConfig = await this.getSpecificPartnerConfig('PublicUrl', partnerId, isOnline);

            if (!partnerConfig) {
                toaster.error(
                    t.partnerConfigurationErrorLoadingShort,
                    isOnline
                        ? t.partnerConfigurationErrorLoadingGeneral(partnerId)
                        : t.partnerConfigurationErrorLoadingOffline(partnerId),
                );
            }
        } else if (locid) {
            partnerConfig = await this.getSpecificPartnerConfig(
                'LocId',
                locid.toString(),
                isOnline,
            );
        }

        if (!partnerConfig) {
            throw new Error(`Could not download partner config for ${locid}`);
        }

        return {
            ...partnerConfig,
        };
    }

    public savePartnerConfigFile(partnerConfig: IPartnerConfigServerResponse) {
        if (!partnerConfig.id) {
            throw Error('downloadPartnerConfigFile expects locid to be a number');
        }

        const fileContents = JSON.stringify(partnerConfig, null, 2);
        const fileBlob = new Blob([fileContents], {
            type: 'text/json;charset=utf-8',
        });

        saveAs(fileBlob, `${partnerConfig.companyName}_${PARTNER_CONFIG_FILENAME}`);
    }

    public async readPartnerConfigFile(file: File) {
        const content: string = await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject();
                }
            };

            reader.onerror = () => {
                reject(new Error(`Could not load partner config file ${file.name}`));
            };

            reader.readAsText(file);
        });
        const partnerConfigObject = JSON.parse(content) as IPartnerConfigServerResponse;
        if (!partnerConfigObject.id || !partnerConfigObject.companyName) {
            throw Error(`Could not load partner config file ${file.name}`);
        }

        return partnerConfigObject;
    }

    private async getSpecificPartnerConfig(
        accessType: AccessType,
        partnerId: string,
        isOnline: boolean,
    ) {
        if (!isOnline) {
            return this.getPartnerConfigDataFromIndexDB(partnerId, accessType);
        }

        const partnerConfigResponse = await this.fetchPartnerConfig(accessType, partnerId);

        if (partnerConfigResponse) {
            this.addPartnerConfigToIndexDB(partnerId, partnerConfigResponse);
        }

        return partnerConfigResponse;
    }

    private async getPartnerConfigDataFromIndexDB(id: string, accessType: AccessType) {
        const partnerConfig = await this.partnerConfigStore.getItem(id);
        const allowedLocIdAccess = accessType === 'LocId' && partnerConfig.allowLocIdAccess;
        const allowedPublicUrlAccess =
            accessType === 'PublicUrl' && partnerConfig.allowPublicUrlAccess;

        if (allowedLocIdAccess || allowedPublicUrlAccess) {
            return partnerConfig;
        }

        return null;
    }

    private addPartnerConfigToIndexDB = (
        id: string,
        partnerConfig: IPartnerConfigServerResponse,
    ) => {
        this.partnerConfigStore.setItem(id, partnerConfig);
    };

    private async fetchPartnerConfig(
        accessType: AccessType,
        partnerId: string,
    ): Promise<IPartnerConfigServerResponse | null> {
        const partnerConfigResponse = await this.partnerConfigCommunicator.fetchPartnerConfig(
            accessType,
            partnerId,
        );

        if (!partnerConfigResponse) {
            return null;
        }

        const {
            companyName,
            id,
            logo,
            allowlist,
            allowLocIdAccess,
            allowPublicUrlAccess,
            mappedPiaIds,
            style,
        } = partnerConfigResponse;

        if (
            companyName === undefined ||
            id === undefined ||
            allowLocIdAccess === undefined ||
            allowPublicUrlAccess === undefined
        ) {
            eventTracking.logError(
                `Mandatory data missing from partner config for partner ${partnerId}`,
                'PartnerConfigService',
            );
            return null;
        }

        if (allowlist) {
            if (
                allowlist.recommendedProducts === undefined ||
                allowlist.allowExcludedProducts === undefined ||
                allowlist.excludedProductsMessage === undefined ||
                allowlist.otherProducts === undefined
            ) {
                eventTracking.logError(
                    `Mandatory data missing from partner config allowlist for partner ${partnerId}`,
                    'PartnerConfigService',
                );
                return null;
            }
        }

        return {
            companyName,
            id,
            logo,
            allowlist,
            allowLocIdAccess,
            allowPublicUrlAccess,
            mappedPiaIds,
            style,
        };
    }
}
