import { css, cx, keyframes } from '@emotion/css';
import * as React from 'react';

const placeHolderAnimation = keyframes`
    0% { background-position: 10% 0%; }
    50% { background-position: 91% 100%; }
    100% { background-position: 10% 0%; }
`;

const imagePlaceholderStyle = css`
    background: linear-gradient(
        130deg,
        rgba(0, 0, 0, 0.15),
        rgba(0, 0, 0, 0.08),
        rgba(0, 0, 0, 0.15)
    );
    background-size: 200% 200%;
    animation: ${placeHolderAnimation} 2s ease infinite;

    ${placeHolderAnimation}
    ${placeHolderAnimation}
    ${placeHolderAnimation}
`;

interface IImagePlaceholder {
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
}

/**
 * Used to indicate loading state for an image.
 */
export const ImagePlaceholder: React.FC<IImagePlaceholder> = ({
    height = '50px',
    width = '50px',
}) => {
    const sizeStyle = css`
        height: ${height};
        width: ${width};
    `;
    return <div className={cx(imagePlaceholderStyle, sizeStyle)} />;
};

ImagePlaceholder.displayName = 'ImagePlaceholder';
