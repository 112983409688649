import type { IPiaSystemComponent, IPiaSoftware } from 'app/core/pia';
import { PiaItemState, filterProducts } from 'app/core/pia';
import {
    createProductAllowlistFilter,
    getCurrentProjectRegions,
    getPiaIdFromProps,
    getPiaItemsRecord,
    getProductAllowlist,
    getUseProductAllowlist,
    toCacheKey,
} from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { getCurrentProjectRecordingItems } from './getRecordingDeviceQuantities';

export interface IVmsByLicenseType {
    eLicense?: IPiaSystemComponent & IPiaSoftware;
    paperLicense?: IPiaSystemComponent & IPiaSoftware;
}

export interface ISelectedVmsByLicenseTypes {
    eLicenses: IPiaSoftware[];
    paperLicenses: IPiaSoftware[];
}

export type vmsLicenseType = keyof IVmsByLicenseType;

const getAxisVmsLicenses = createSelector(
    [getPiaItemsRecord, getCurrentProjectRegions, getProductAllowlist, getUseProductAllowlist],
    (piaItems, regions, allowlist, useAllowlist) =>
        Object.values(piaItems)
            .filter(filterProducts.byState(PiaItemState.EXTERNALLY_ANNOUNCED))
            .filter(filterProducts.byCategory('vms'))
            .filter(filterProducts.byRegions(regions))
            .filter(filterProducts.byVendor('Axis'))
            .filter(filterProducts.byExternallyHidden())
            .filter((product) => createProductAllowlistFilter(allowlist, useAllowlist)(product.id)),
);

export const getLicenseNameWithoutType = (name: string) => name.split(' - eLicense')[0];

export const getVmsByLicenseTypeRecord = createSelector([getAxisVmsLicenses], (licenses) => {
    const vmsLicenses = licenses as IPiaSystemComponent & IPiaSoftware[];
    const byLicenseTypeRecord: Record<string, IVmsByLicenseType> = {};

    vmsLicenses.forEach((license) => {
        const licenseNameWithoutType = getLicenseNameWithoutType(license.name);
        if (!byLicenseTypeRecord[licenseNameWithoutType]) {
            byLicenseTypeRecord[licenseNameWithoutType] = {
                eLicense: undefined,
                paperLicense: undefined,
            };
        }
        const { isELicense } = license.properties;
        if (isELicense) {
            byLicenseTypeRecord[licenseNameWithoutType].eLicense = license;
        } else {
            byLicenseTypeRecord[licenseNameWithoutType].paperLicense = license;
        }
    });
    return byLicenseTypeRecord;
});

export const getVmsLicenseEquivalent = createCachedSelector(
    [getPiaIdFromProps, getVmsByLicenseTypeRecord, getPiaItemsRecord],
    (piaId, vmsByLicenseRecord, piaItemsRecord) => {
        if (piaId === null || piaId === undefined) {
            return undefined;
        }
        const piaItem = piaItemsRecord[piaId] as IPiaSystemComponent & IPiaSoftware;
        if (piaItem.category !== 'vms') {
            return undefined;
        }
        const { isELicense } = piaItem.properties;
        const desiredLicenseType: vmsLicenseType = isELicense ? 'paperLicense' : 'eLicense';
        return vmsByLicenseRecord[getLicenseNameWithoutType(piaItem.name)][desiredLicenseType];
    },
)(toCacheKey);

export const getSelectedVmsLicensesByType = createSelector(
    [getCurrentProjectRecordingItems, getPiaItemsRecord],
    (selectedRecordingItems, piaItemsRecord) => {
        const selectedLicensesByType: ISelectedVmsByLicenseTypes = {
            eLicenses: [],
            paperLicenses: [],
        };

        selectedRecordingItems.forEach((license) => {
            const piaItem = license.productId
                ? (piaItemsRecord[license.productId] as IPiaSoftware)
                : undefined;

            if (!piaItem || piaItem.category !== 'vms') {
                return;
            }

            const isELicense = piaItem.properties.isELicense;

            if (isELicense) {
                selectedLicensesByType.eLicenses.push(piaItem);
            } else {
                selectedLicensesByType.paperLicenses.push(piaItem);
            }
        });

        return selectedLicensesByType;
    },
);
