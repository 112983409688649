import type { AnyAction, Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import type { IAction } from './store';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { toaster } from 'app/toaster';
import { isCustomPouchError } from './utils';

export interface IExtendedMiddleware extends Middleware {
    (
        api: MiddlewareAPI<Dispatch>,
    ): (next: Dispatch<AnyAction>) => <A extends IAction<any>>(action: A) => A;
}

const POUCHDB_CONFLICT_ERROR_NAME = 'conflict';
export const POUCHDB_ERROR_REASON_QUOTA_EXCEEDED = 'QuotaExceededError';

export const errorReporterMiddleware: IExtendedMiddleware =
    () =>
    (next: Dispatch) =>
    <A extends IAction<any>>(action: A): A => {
        try {
            if (action.type.includes('_REJECTED')) {
                if (typeof action.payload !== 'undefined') {
                    const payload = action.payload;

                    if (isCustomPouchError(payload)) {
                        const pouchError = {
                            status: payload.status,
                            name: payload.name,
                            message: payload.message,
                            reason: payload.reason,
                        };
                        // Checking on name since pouch has different errors with same status code.
                        // In case of conflict user has to be notified to refresh browser.
                        if (pouchError.name === POUCHDB_CONFLICT_ERROR_NAME) {
                            toaster.error(
                                t.errorReporterMiddlewareConflictHeader,
                                t.errorReporterMiddlewareConflictMessage,
                            );
                        } else if (pouchError.reason === POUCHDB_ERROR_REASON_QUOTA_EXCEEDED) {
                            toaster.error(t.errorBoundaryHeader, t.applicationQuotaExceededError);
                        }
                        eventTracking.logError(
                            `CustomPouchError: ${action.type} - ${pouchError.name} - ${pouchError.message} - ${pouchError.reason}`,
                            'errorReporterMiddleware',
                            pouchError.status,
                        );
                    } else if (payload instanceof Error) {
                        eventTracking.logError(
                            `Action rejected: ${action.type} - ${payload.name} - ${payload.message}`,
                            'errorReporterMiddleware',
                        );
                    }
                } else {
                    eventTracking.logError(
                        `Action type: ${action.type}`,
                        'errorReporterMiddleware',
                    );
                }
            }
            return next(action);
        } catch (error) {
            if (error instanceof Error) {
                eventTracking.logError(
                    `General error: ${error.name} - ${error.message} stack: ${error.stack}`,
                    'errorReporterMiddleware',
                );
            } else {
                eventTracking.logError(
                    'General error without error information',
                    'errorReporterMiddleware',
                );
            }
            throw error;
        }
    };
