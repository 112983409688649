import { reducerBuilder } from 'app/store';
import type { ToastModel } from '../models/Toast.model';
import { ToastActions } from './Toast.actions';

export interface IToasterState {
    toasts: ToastModel[];
}

const initialState: IToasterState = {
    toasts: [],
};

export const toastReducer = reducerBuilder<IToasterState>()
    .setInitialState(() => initialState)
    .onAction<ToastModel[]>(ToastActions.ToastItemsChanged, (state, action) => {
        return {
            ...state,
            toasts: action.payload,
        };
    })
    .create();
