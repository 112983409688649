import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IMapsSpeaker } from '../../../../models';
import { ensureSpeakerOrUndefined } from '../../../../models';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import { Stack, Box, Toggle, ToggleItem, Text, Icon, IconText } from 'app/components';
import type { SpeakerPlacement } from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';

interface IPlacementComponentProps {
    device?: IMapsSpeaker;
}

const mapStateToProps = (storeState: IStoreState): IPlacementComponentProps => {
    return {
        device: ensureSpeakerOrUndefined(getSelectedDeviceOrParentDevice(storeState)),
    };
};

const PlacementComponent: React.FunctionComponent<IPlacementComponentProps> = ({ device }) => {
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));

    const setPlacement = (placement: SpeakerPlacement) => {
        eventTracking.logUserEvent(
            'Maps',
            'Change speaker placement',
            `New placement: ${placement}`,
        );
        itemService.updateSpeakerPlacement(device!.id, placement);
    };

    const isPlacementActive = (placement: SpeakerPlacement) =>
        (device?.placement ?? 'ceiling') === placement;

    return (
        <Stack vertical justifyContent="center" spacing="half">
            <Text color="grey7" align="center" style="semibold">
                {t.placement}
            </Text>
            <Box justifyContent="center">
                {device?.canChangePlacement && (
                    <Toggle width="230px">
                        <ToggleItem
                            onClick={() => setPlacement('ceiling')}
                            active={isPlacementActive('ceiling')}
                            fluid
                        >
                            <Icon icon="speaker_ceiling" opaque />
                        </ToggleItem>
                        <ToggleItem
                            onClick={() => setPlacement('wall')}
                            active={isPlacementActive('wall')}
                            fluid
                        >
                            <Icon icon="speaker_wall" opaque />
                        </ToggleItem>
                    </Toggle>
                )}
                {!device?.canChangePlacement && (
                    <IconText
                        icon={device?.placement == 'ceiling' ? 'speaker_ceiling' : 'speaker_wall'}
                    >
                        {device?.placement === 'ceiling'
                            ? t.speakerSelectorCeiling
                            : t.speakerSelectorWall}
                    </IconText>
                )}
            </Box>
        </Stack>
    );
};

export const Placement = connect(mapStateToProps)(PlacementComponent);
Placement.displayName = 'Placement';
