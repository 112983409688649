import { t } from 'app/translate';
import type { ITab } from 'app/components';
import type { DeviceGroup } from '../models';

export interface IDeviceTabs {
    mainTabs: ITab[];
    collapsedTabs?: ITab[];
}

function mainTabs() {
    return [
        {
            id: 'cameras',
            title: t.cameras,
            tabTestId: 'cameras',
        },
        {
            id: 'mainunits',
            title: t.fSeries,
            tabTestId: 'mainunits',
        },
        {
            id: 'encoders',
            title: t.encoders,
            tabTestId: 'encoders',
        },
        {
            id: 'speakers',
            title: t.speakers,
            tabTestId: 'speakers',
        },
        {
            id: 'accessControls',
            title: t.accessControl,
            tabTestId: 'accessControls',
        },
        {
            id: 'wearables',
            title: t.wearables,
            tabTestId: 'wearables',
        },
        {
            id: 'twoNProducts',
            title: t.deviceListAddOtherListTwoN,
            tabTestId: 'twoNProducts',
        },
        {
            id: 'other',
            title: t.other,
            tabTestId: 'other',
        },
    ];
}

// Own tab since never shown with other tabs
function sensorUnitTab() {
    return {
        id: 'sensorunits',
        title: t.fSeries,
        tabTestId: 'sensorunits',
    };
}

function allTabs() {
    return mainTabs().concat(sensorUnitTab());
}

/**
 * Gets an object with arrays of tabs to present in DeviceTabs
 * @param showAll - if all tabs should should show at the same time (add)
 * @param deviceGroup - if undefined - all tabs should be available, otherwise only tab for this deviceGroup (edit/change)
 * @param maxMainTabs - max number of tabs to return in mainTabs array
 * @param shouldShow2NSelector - if 2N products are available
 * @returns IDeviceTabs
 */
export const getDeviceTabs = (
    showAll: boolean,
    deviceGroup: DeviceGroup,
    maxMainTabs: number = Number.MAX_VALUE,
    shouldShow2NSelector?: boolean,
) => {
    // One specific tab, need to filter from allTabs since tab could be sensor units
    if (!showAll) {
        return { mainTabs: allTabs().filter((tab) => tab.id === deviceGroup) };
    }

    const mainTabCount = Math.min(mainTabs().length, maxMainTabs);
    // All main tabs are to be returned, 2N if applicable
    if (mainTabCount >= mainTabs().length) {
        return {
            mainTabs: mainTabs().filter((tab) => tab.id !== 'twoNProducts' || shouldShow2NSelector),
        };
    } else {
        return {
            mainTabs: mainTabs().slice(0, mainTabCount),
            collapsedTabs: mainTabs()
                .slice(mainTabCount, mainTabs().length)
                .filter((tab) => tab.id !== 'twoNProducts' || shouldShow2NSelector),
        };
    }
};
