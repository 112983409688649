import * as React from 'react';
import type {
    Id,
    IItemEntity,
    IItemNetworkSettings,
    IPersistence,
    IProjectNetworkSettings,
} from 'app/core/persistence';

import { getCurrentProjectItem, getCurrentProjectNetworkSettings } from 'app/modules/common';
import { Icon, Table, Text } from 'app/components';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';

interface INetworkItemProps {
    itemId: Id;
    deviceQuantityIndex?: number;
}

export const NetworkItem: React.FC<INetworkItemProps> = ({ itemId, deviceQuantityIndex }) => {
    const device = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, itemId),
    );

    const projectNetworkSettings = useSelector<IStoreState, IProjectNetworkSettings | undefined>(
        (state) => getCurrentProjectNetworkSettings(state),
    );

    const deviceNetworkSettings = device?.networkSettings ? device?.networkSettings[0] : undefined;

    if (!deviceNetworkSettings) {
        return null;
    }

    const getAddresses = (networkSettings: IItemNetworkSettings[] | undefined) => {
        const addresses =
            networkSettings && deviceQuantityIndex !== undefined
                ? networkSettings[deviceQuantityIndex]?.addresses.join(', ')
                : networkSettings
                  ? networkSettings.map((network, index) => (
                        <Text key={index}>{network.addresses.join(', ')}</Text>
                    ))
                  : undefined;
        return addresses ? <>{addresses}</> : '';
    };

    const getRow = (
        ipAddress: string | JSX.Element,
        subnetMask?: string,
        defaultRouter?: string,
    ): Array<string | number | JSX.Element> => {
        const row: Array<string | number | JSX.Element> = [];
        row.push(ipAddress);
        if (subnetMask) {
            row.push(subnetMask);
        }
        if (defaultRouter) {
            row.push(defaultRouter);
        }
        if (deviceNetworkSettings?.dhcp) {
            row.push(<Icon opaque size="sm" icon={'check'} color="yellowText" />);
        }
        return row;
    };

    const ipAddress = !deviceNetworkSettings.dhcp ? getAddresses(device?.networkSettings) : '';

    const subnetMask =
        !deviceNetworkSettings?.dhcp && deviceNetworkSettings.subnetMask
            ? deviceNetworkSettings.subnetMask
            : !deviceNetworkSettings?.dhcp
              ? projectNetworkSettings?.subnetMask
              : undefined;

    const defaultRouter =
        !deviceNetworkSettings?.dhcp && deviceNetworkSettings.defaultRouter
            ? deviceNetworkSettings.defaultRouter
            : !deviceNetworkSettings?.dhcp
              ? projectNetworkSettings?.defaultRouter
              : undefined;

    const headers = [t.ipAddress];
    if (subnetMask) {
        headers.push(t.ipSubnetMask);
    }
    if (defaultRouter) {
        headers.push(t.ipDefaultRouter);
    }
    if (deviceNetworkSettings?.dhcp) {
        headers.push(t.useDHCP);
    }

    return (
        <Table
            headers={headers}
            rows={[getRow(ipAddress, subnetMask, defaultRouter)]}
            wideFirstColumn
            omitLastLine
            testId={device?.name}
        />
    );
};
