import { debounce } from 'lodash-es';
import React from 'react';

/**
 * Used to debounce a callback function
 * @example
 * const callback = (value: number) => repository.saveValue(value);
 * const [debouncedValue, setDebouncedValue] = React.useState<number>(0);
 * const debouncedCallback = useDebounce(callback, 500);
 * React.useEffect(() => {
 *   debouncedCallback(debouncedValue);
 * }, [debouncedValue]);
 *
 * @param callback The callback function to be debounced
 * @param delay The delay in milliseconds
 * @returns The debounced callback
 */
export const useDebounce = <T extends (...args: any) => ReturnType<T>>(
    callback: T,
    delay: number = 500,
) => {
    const callbackRef = React.useRef<T>();

    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const debouncedCallback = React.useMemo(() => {
        const func = (args: any): ReturnType<T> | undefined => {
            return callbackRef.current?.(args);
        };

        return debounce(func, delay);
    }, [delay]);

    return debouncedCallback;
};
