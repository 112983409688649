export const availableLanguages = [
    'cs',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'it',
    'ja',
    'ko',
    'nl',
    'pl',
    'pt',
    'ru',
    'sv',
    'th',
    'tr',
    'vi',
    'zhhans',
    'zhhant',
];
