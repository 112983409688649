import { injectable } from 'inversify';
import { flatMap } from 'lodash-es';
import type { PiaId } from '../../../../pia/client/types';
import { deviceTypeCheckers, getParentId } from '../../../utils';
import type { IItemEntity, Id } from '../../entities';
import type { IPersistence, IPersistenceRepository } from '../../repositories/persistence/models';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { CreateEntityService } from './../../repositories/persistence/CreateEntity.service';

// List of pia id that does not allow quantity
const singleQuantityPiaIds = new Set<PiaId>([
    // Recorders
    48647, // AXIS S1116 Racked
    92411, // AXIS S1216 Rack 8 TB
    81442, // AXIS S1216 Tower 8 TB
    76441, // AXIS S1232 Rack 16 TB
    76444, // AXIS S1232 Rack 32 TB
    76447, // AXIS S1232 Tower 32 TB
    76459, // AXIS S1264 Rack 144 TB
    76453, // AXIS S1264 Rack 24 TB
    76456, // AXIS S1264 Rack 64 TB
    76468, // AXIS S1296 Rack 192 TB
    76465, // AXIS S1296 Rack 96 TB
    76549, // AXIS S2108
    49698, // AXIS S2208
    49710, // AXIS S2212
    49704, // AXIS S2216
    49716, // AXIS S2224
    64160, // AXIS S3008 2 TB
    63359, // AXIS S3008 4 TB
    64157, // AXIS S3008 8 TB
    89599, // AXIS S3008 Mk II 2 TB
    89593, // AXIS S3008 Mk II 4 TB
    89596, // AXIS S3008 Mk II 8 TB
    73288, // AXIS S3016 16 TB
    73285, // AXIS S3016 32 TB
    77080, // AXIS S3016 8 TB
    74959, // Milestone Husky IVO 1000 RACK MOUNT 2U, WS19, 128TB
    74971, // Milestone Husky IVO 1000 RACK MOUNT 2U, WS19, 16TB
    74962, // Milestone Husky IVO 1000 RACK MOUNT 2U, WS19, 32TB
    74965, // Milestone Husky IVO 1000 RACK MOUNT 2U, WS19, 64TB
    74968, // Milestone Husky IVO 1000 RACK MOUNT 2U, WS19, 96TB
    74881, // Milestone Husky IVO 150 DESKTOP, WIN10, 12TB
    74878, // Milestone Husky IVO 150 DESKTOP, WIN10, 16TB
    74887, // Milestone Husky IVO 150 DESKTOP, WIN10, 2TB
    74875, // Milestone Husky IVO 150 DESKTOP, WIN10, 4TB
    74884, // Milestone Husky IVO 150 DESKTOP, WIN10, 8TB
    74977, // Milestone Husky IVO 1800 RACK MOUNT 2U, WS19, 144TB
    74989, // Milestone Husky IVO 1800 RACK MOUNT 2U, WS19, 192TB
    74992, // Milestone Husky IVO 1800 RACK MOUNT 2U, WS19, 24TB
    74980, // Milestone Husky IVO 1800 RACK MOUNT 2U, WS19, 288TB
    74983, // Milestone Husky IVO 1800 RACK MOUNT 2U, WS19, 384TB
    74986, // Milestone Husky IVO 1800 RACK MOUNT 2U, WS19, 48TB
    74974, // Milestone Husky IVO 1800 RACK MOUNT 2U, WS19, 96TB
    74938, // Milestone Husky IVO 350 RACK MOUNT, WIN10, 16TB
    74941, // Milestone Husky IVO 350 RACK MOUNT, WIN10, 24TB
    74932, // Milestone Husky IVO 350 RACK MOUNT, WIN10, 32TB
    74929, // Milestone Husky IVO 350 RACK MOUNT, WIN10, 4TB
    74935, // Milestone Husky IVO 350 RACK MOUNT, WIN10, 8TB
    74917, // Milestone Husky IVO 350 TOWER, WIN10, 16TB
    74914, // Milestone Husky IVO 350 TOWER, WIN10, 24TB
    74920, // Milestone Husky IVO 350 TOWER, WIN10, 32TB
    74923, // Milestone Husky IVO 350 TOWER, WIN10, 4TB
    74926, // Milestone Husky IVO 350 TOWER, WIN10, 8TB
    74947, // Milestone Husky IVO 700 RACK MOUNT, WIN10, 16TB
    74950, // Milestone Husky IVO 700 RACK MOUNT, WIN10, 32TB
    74953, // Milestone Husky IVO 700 RACK MOUNT, WIN10, 48TB
    74956, // Milestone Husky IVO 700 RACK MOUNT, WIN10, 64TB
    74944, // Milestone Husky IVO 700 RACK MOUNT, WIN10, 8TB
    // (discontinued)
    23413, //	AXIS S2008
    23422, //	AXIS S2016
    23428, //	AXIS S2024
    46319, //	AXIS S1148 24 TB
    46322, //	AXIS S1148 64 TB
    46325, //	AXIS S1148 140 TB
    46523, //	AXIS S1132
    48512, //	AXIS S1116 MT
    50899, //	Milestone Husky X2 barebone HX2
    50902, //	Milestone Husky X2 barebone w/16 PoE switch HX2P16
    51640, //	Milestone Husky X8 barebone w/RAID HX8R
    51643, //	Milestone Husky X8 barebone w/RAID and CNA HX8RC
    63755, //	AXIS S1132 Tower 64 TB
    63758, //	AXIS S1132 Tower 32 TB
    64160, //	AXIS S3008 2 TB
    // end of support
    19195, //	AXIS 262
    19241, //	AXIS S1016
    19244, //	AXIS S1032
    19247, //	AXIS S1048
    19418, //	AXIS Q8108-R
    23966, //	AXIS S1016 Mk II
    23969, //	AXIS S1032 Mk II
    23977, //	AXIS S1048 Mk II
    45613, //	Milestone Husky M20 HM20-2T8P
    45616, //	Milestone Husky M20 HM20-4T-16P
    45619, //	Milestone Husky M20 HM20-4T-16
    45622, //	Milestone Husky M20 HM20-12T-16P
    45637, //	Milestone Husky M50 HM50-32T-8
    45640, //	Milestone Husky M50 HM50-16T-8
    45643, //	Milestone Husky M50 HM50-48TR-8
    45646, //	Milestone Husky M50 HM50-16TR-8
    45649, //	Milestone Husky M50 HM50-64TR-8
    45652, //	Milestone Husky M50 HM50-32TR-8
    45655, //	Milestone Husky M50 HM50-8T-8
    45658, //	Milestone Husky M50 HM50-48T-8
    46613, //	AXIS VMS J16-Z
    46631, //	AXIS VMS N16-Z
    46643, //	AXIS VMS N8-Z

    // Desktop terminals
    81451, // AXIS S9301
    81463, // AXIS S9302
    95757, // Genetec Streamvault Workstation SVW-105E-M1-EMB-I7
    95756, // Genetec Streamvault Workstation SVW-305E-SF1-S2000-I7
    95765, // Genetec Streamvault Workstation SVW-305E-T3-S2000-I7
    95762, // Genetec Streamvault Workstation SVW-305E-T3-S4000-I7
    95768, // Genetec Streamvault Workstation SVW-504E-T5-D2000-275
    95759, // Genetec Streamvault Workstation SVW-504E-T5-D4000-275
    // discontinued
    38720, // AXIS S9101
    46532, // AXIS S9002 Mk II
    56398, // Genetec Streamvault Workstation SVW-300E-T4-2000-I7
    56401, // Genetec Streamvault Workstation SVW-300E-T4-D1000-I7
    56404, // Genetec Streamvault Workstation SVW-300E-T4-1000-I7
    56407, // Genetec Streamvault Workstation SVW-300E-SF2-1000-I7
    56410, // Genetec Streamvault Workstation SVW-300E-SF2-P620-I7
    56413, // Genetec Streamvault Workstation SVW-300E-T4-D2000-I7
    56416, // Genetec Streamvault Workstation SVW-501E-T4-D4000-145
    59516, // AXIS S9101 Mk II
    // end of support
    19250, // AXIS S9001
    23957, // AXIS S9001 Mk II
    23960, // AXIS S9002
    38729, // AXIS S9201
    46514, // AXIS S9201 Mk II

    // Network switches
    65525, // AXIS D8004 Unmanaged PoE Switch
    78988, // AXIS D8208-R Industrial PoE++ Switch
    39353, // AXIS T8504-E Outdoor PoE Switch
    48599, // AXIS T8504-R Industrial PoE Switch
    40468, // AXIS T8508 PoE+ Network Switch
    30031, // AXIS T8516 PoE+ Network Switch
    40477, // AXIS T8524 PoE+ Network Switch
]);

/** The max quantity to split into separate items */
const MAX_QUANTITY = 25;

/**
 * Add new items instead of having quantity on Recorders, Network switches & Terminals.
 */
@injectable()
export class Migration43To44 extends MigrationProviderBase {
    public from: number = 43;
    public to: number = 44;

    constructor(private createEntityService: CreateEntityService) {
        super();
    }

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<IItemEntity> => {
            // Check against ID list or properties partner component (partnerSystemComponent)
            if (
                this.isItemEntityWithPiaId(entity) &&
                entity.quantity > 1 &&
                (singleQuantityPiaIds.has(entity.productId!) ||
                    deviceTypeCheckers.isPartnerSystemComponent(entity))
            ) {
                // Do not create new entities of quantity greater than MAX_QUANTITY
                if (entity.quantity > MAX_QUANTITY) {
                    entity.quantity = 1;
                    return entity;
                }
                // Multiply entity
                const newEntities = await this.createEntityCopies(entity, repository);

                // Multiply related accessories (only one level)
                await this.copyAccessoriesToNewEntities(entity, newEntities, repository);

                entity.quantity = 1;
            }

            return entity;
        };
    }

    private async createEntityCopies(
        entity: IPersistence<IItemEntity>,
        repository: IPersistenceRepository,
    ) {
        const parentPath = entity.path.slice(0, -1);
        const entitiesToAdd = new Array(entity.quantity - 1)
            .fill(entity)
            .map((newEntity) => ({ ...newEntity, quantity: 1 }));
        return this.addEntities(entitiesToAdd, parentPath, repository);
    }

    private async copyAccessoriesToNewEntities(
        entity: IPersistence<IItemEntity>,
        newEntities: IPersistence<IItemEntity>[],
        repository: IPersistenceRepository,
    ) {
        // We only want the descendants, not the item it self (slice away first item)
        const descendants = (await repository.getDescendants(entity._id)).slice(1);
        const itemDescendants = descendants.filter(this.isItemEntityWithPiaId);
        if (itemDescendants.length === 0) {
            // Nothing to copy
            return;
        }

        const addAccessoriesPromise = newEntities.map((newEntity) =>
            this.addEntities(itemDescendants, newEntity.path, repository),
        );
        const addedAccessories = flatMap(await Promise.all(addAccessoriesPromise));
        const addRelationsPromise = addedAccessories.map((accessory) =>
            this.addItemRelation(accessory, repository),
        );
        await Promise.all(addRelationsPromise);
    }

    private addEntities(
        itemEntities: IItemEntity[],
        parentPath: Id[],
        repository: IPersistenceRepository,
    ): Promise<IPersistence<IItemEntity>[]> {
        const itemsToAdd = itemEntities.map((item) => this.getItemToAdd(item, parentPath));
        const newEntities = itemsToAdd.map((itemToAdd) =>
            this.createEntityService.create(itemToAdd.type, itemToAdd),
        );

        const result = repository.bulkAdd(newEntities) as Promise<IPersistence<IItemEntity>[]>;
        return result;
    }

    private addItemRelation(
        accessory: IPersistence<IItemEntity>,
        repository: IPersistenceRepository,
    ) {
        const relation = {
            parentId: getParentId(accessory),
            childId: accessory._id,
            path: accessory.path.slice(0, -1),
            archived: accessory.archived,
            relationType: 'accessory',
            type: 'itemRelation',
        };

        const newRelationEntity = this.createEntityService.create('itemRelation', relation);
        return repository.add(newRelationEntity);
    }

    private getItemToAdd(item: IItemEntity, parentPath: Id[]): IItemEntity {
        return {
            type: 'item',
            productId: item.productId,
            name: item.name,
            description: item.description,
            notes: item.notes,
            color: item.color,
            properties: item.properties,
            quantity: item.quantity,
            path: [...parentPath],
            archived: item.archived,
            replaceWithBareboneId: item.replaceWithBareboneId,
            analyticRange: undefined,
        };
    }

    private isItemEntityWithPiaId(entity: any): entity is IPersistence<IItemEntity> {
        return (
            typeof entity?.productId === 'number' &&
            typeof entity?._id === 'string' &&
            entity.type === 'item'
        );
    }
}
