import * as React from 'react';
import type { Id, ProjectState } from 'app/core/persistence';
import { ProjectStateIndicatorComponent } from './ProjectStateIndicator.component';

export interface IProjectStateIndicatorProps {
    projectId: Id;
    projectState: ProjectState;
    showAsBadge?: boolean;
}

export class ProjectStateIndicator extends React.Component<IProjectStateIndicatorProps> {
    constructor(props: IProjectStateIndicatorProps) {
        super(props);
    }

    public render() {
        return (
            <ProjectStateIndicatorComponent
                projectId={this.props.projectId}
                projectState={this.props.projectState}
                showAsBadge={this.props.showAsBadge}
            />
        );
    }
}
