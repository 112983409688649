import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { MountingItem } from './MountingItem.container';
import { getDeviceMountOptions, getSelectedDeviceId } from '../../selectors';
import type { PiaId } from 'app/core/pia';
import { useService } from 'app/ioc';
import { AccessorySelectorService } from '../../services/AccessorySelector.service';
import { t } from 'app/translate';
import type { IRelatedPiaAccessory } from '../../models';
import type { ISelectedPiaMount } from 'app/modules/common';
import { getSelectedDeviceMountsForItemId } from 'app/modules/common';

export const DeviceMount: React.FunctionComponent = () => {
    const service = useService(AccessorySelectorService);
    const selectedDeviceId = useSelector(getSelectedDeviceId);
    const deviceMount = useSelector<IStoreState, ISelectedPiaMount[]>((state) =>
        getSelectedDeviceMountsForItemId(state, selectedDeviceId),
    );
    const productIds = deviceMount?.map((mount) => mount.id) ?? [];
    const deviceMountOptions = useSelector<IStoreState, IRelatedPiaAccessory[][]>((state) =>
        getDeviceMountOptions(state, selectedDeviceId),
    );

    const onDeviceMountChanged = (mountOptionsPiaIds: PiaId[]) =>
        selectedDeviceId &&
        service.setMountingOption(selectedDeviceId, mountOptionsPiaIds, 'deviceMount');

    return (
        <MountingItem
            label={t.mountingAccessories}
            productIds={productIds}
            mountOptions={deviceMountOptions}
            fallbackImage={require('src/assets/images/mounting/directCoupled.png')}
            onMountChanged={onDeviceMountChanged}
        />
    );
};
