import type { InitOptions } from 'i18next';
import { use } from 'i18next';
import HttpApi from 'i18next-http-backend';
import { isProduction } from 'app/isProduction';
import LanguageDetector from 'i18next-browser-languagedetector';
import { availableLanguages } from './translate';
import { initReactI18next } from 'react-i18next';

/**
 * Get the browser language.
 * Exclude the locale (ie. returns "en" if "en-US")
 * Does not support IE10 and below
 */

const navigatorLanguage = {
    name: 'navigatorLanguage',

    lookup() {
        const simplifiedChinese = 'zhhans';
        const traditionalChinese = 'zhhant';
        const navLang = navigator.language.toLowerCase();

        // China and Singapore use simplified Chinese characters (zh_hans)
        if (
            navLang.includes('hans') ||
            navLang === 'zh-cn' ||
            navLang === 'zh-sg' ||
            navLang === 'zh'
        ) {
            return simplifiedChinese;
        }

        // Taiwan, Hong Kong and Macau use traditional Chinese characters (zh_hant)
        if (
            navLang.includes('hant') ||
            navLang === 'zh-tw' ||
            navLang === 'zh-hk' ||
            navLang === 'zh-mo'
        ) {
            return traditionalChinese;
        }

        // Return only the language code
        return navLang.slice(0, 2);
    },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(navigatorLanguage);

export default use(HttpApi)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        ns: ['aed', 'releaseNotes', 'partnerProducts'],
        defaultNS: 'aed',
        debug: !isProduction(),
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
            format(value: string, format: string) {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }

                return value;
            },
        },
        load: 'languageOnly',
        supportedLngs: [...availableLanguages],
        detection: {
            order: [
                'querystring',
                'cookie',
                'localStorage',
                'navigatorLanguage',
                'htmlTag',
                'path',
                'subdomain',
            ],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'],
        },
        backend: {
            loadPath: '/assets/languages/{{lng}}/{{ns}}.json',
            allowMultiLoading: false,
            requestOptions: {
                // used for fetch, can also be a function (payload) => ({ method: 'GET' })
                mode: 'cors',
                credentials: 'same-origin',
                cache: 'default',
            },
        },
    } as InitOptions);
