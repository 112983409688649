import { injectable } from 'inversify';
import type { Id, IPersistence, IItemEntity, ISpeakerItemEntity } from 'app/core/persistence';
import { ProjectService, deviceTypeCheckers } from 'app/core/persistence';
import type { IExportedZone } from '../../../models';
import { isDefined } from 'axis-webtools-util';

@injectable()
export class ZonesExporterService {
    constructor(private projectService: ProjectService) {}

    public async getExportedZones(projectId: Id): Promise<IExportedZone[]> {
        const childItems = await this.projectService.getChildItems(projectId);
        return childItems.map(this.mapEntityToExportedZone).filter(isDefined);
    }

    private mapEntityToExportedZone = (item: IPersistence<IItemEntity>): IExportedZone | null => {
        if (!this.isSpeakerEntity(item) || !item.productId) {
            return null;
        }

        return {
            id: item._id,
            revision: item._rev,
            name: item.name,
        };
    };

    private isSpeakerEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<ISpeakerItemEntity> {
        return deviceTypeCheckers.isSpeaker(item);
    }
}
