import type { IFloorPlanEntity, ILatLng } from 'app/core/persistence';
import { createTransformer } from 'axis-webtools-util';
import { getFloorPlanGeoLocation } from './floorPlan';
import type { ILocation } from '../../locations';

export const createDerotationTransform = (
    floorPlan: IFloorPlanEntity | undefined,
    newPosition?: ILocation,
) => {
    const geoLocation = floorPlan && getFloorPlanGeoLocation(floorPlan);

    if (!geoLocation) {
        // identity function
        return (latLng: ILatLng) => latLng;
    }

    return createTransformer(
        geoLocation.position,
        newPosition ?? geoLocation.position,
        1,
        -geoLocation.angle,
    );
};
