import * as React from 'react';
import { Border, Box, PiaLocationSelect, Stack, Text } from 'app/components';
import { ProjectService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { getCurrentProjectLocation, getPiaLocationOptionsSortedByName } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';

export const LocationSelection: React.FC = () => {
    const [projectService] = React.useState<ProjectService>(() =>
        ServiceLocator.get(ProjectService),
    );
    const piaLocations = useSelector((state: IStoreState) =>
        getPiaLocationOptionsSortedByName(state),
    );
    const selectedLocation = useSelector((state: IStoreState) => getCurrentProjectLocation(state));
    const onChangeLocation = (installationPiaLocationId: number) =>
        projectService.updateCurrentProject({ installationPiaLocationId });

    return (
        <Border color="blackOpacity">
            <Box padding="base" color="whiteOpacity" borderRadius="rounded">
                <Stack vertical spacing="cell" notFullWidth>
                    <Text style="semibold">{t.chooseInstallationCountryRegion}</Text>
                    <PiaLocationSelect
                        selectedPiaLocationId={selectedLocation?.id}
                        piaLocations={piaLocations}
                        onChange={onChangeLocation}
                    />
                </Stack>
            </Box>
        </Border>
    );
};
