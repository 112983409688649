import { createCachedSelector } from 're-reselect';
import { AppConstants } from 'app/AppConstants';
import type { IPiaOptionalDeviceProperties, IPiaMainUnitProperties } from 'app/core/pia';
import { PiaItemPacCategory } from 'app/core/pia';
import type { Id } from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { IStoreState } from 'app/store';
import { getCurrentProjectRelationsRecord } from '../../relations/selectors';
import { getPiaItemsRecord } from '../../piaDevices/selectors';
import { getCurrentProjectItems } from '../../project/selectors';
import { getIdFromPropsRequired } from '../../selectors';
import { toCacheKey } from '../../cacheKey';

export interface IChannelInformation {
    usedChannels: number;
    maxQuantity: number;
}

// itemId must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getParentId = (_state: IStoreState, _itemId: Id, parentId: Id) => parentId;

export const getChannelInformation = createCachedSelector(
    [
        getCurrentProjectRelationsRecord,
        getPiaItemsRecord,
        getCurrentProjectItems,
        getIdFromPropsRequired,
        getParentId,
    ],
    (currentProjectRelationsRecord, piaItemsRecord, currentProjectItems, itemId, parentId) => {
        return {
            usedChannels: getUsedChannels(
                itemId,
                currentProjectRelationsRecord,
                currentProjectItems,
            ),
            maxQuantity: getMaxQuantity(
                itemId,
                currentProjectItems,
                currentProjectRelationsRecord,
                piaItemsRecord,
                parentId,
            ),
        };
    },
)(toCacheKey);

export function getUsedChannels(
    itemId: Id,
    currentProjectRelationsRecord: ReturnType<typeof getCurrentProjectRelationsRecord>,
    currentProjectItems: ReturnType<typeof getCurrentProjectItems>,
) {
    const relations = currentProjectRelationsRecord[itemId];

    if (!relations) {
        return 0;
    }

    const usedChannels = relations
        .filter(
            ({ relationType }) =>
                relationType === 'analogCamera' ||
                relationType === 'sensorUnit' ||
                relationType === 'door' ||
                relationType === 'iorelays',
        )
        .map(({ childId }) => currentProjectItems[childId])
        .filter(isDefined)
        .map(({ quantity }) => quantity)
        .reduce((prev, curr) => {
            return (prev += curr);
        }, 0);

    return usedChannels;
}

function getMaxQuantity(
    itemId: Id,
    currentProjectItems: ReturnType<typeof getCurrentProjectItems>,
    currentProjectRelationsRecord: ReturnType<typeof getCurrentProjectRelationsRecord>,
    piaItemsRecord: ReturnType<typeof getPiaItemsRecord>,
    parentId?: Id,
) {
    const item = currentProjectItems[itemId];
    const parent = parentId ? currentProjectItems[parentId] : undefined;

    if (!parent || !item || !parent.productId) {
        return AppConstants.componentQuantityMax;
    }

    let availableChannels = 0;

    if (deviceTypeCheckers.isPac(item) && item.productId) {
        if (piaItemsRecord[item.productId].category === PiaItemPacCategory.RELAYEXPMODULES) {
            availableChannels =
                (piaItemsRecord[parent.productId].properties as IPiaOptionalDeviceProperties)
                    .nbrSupportedExpansionModules ?? 0;
        }
    } else {
        availableChannels = (piaItemsRecord[parent.productId].properties as IPiaMainUnitProperties)
            .channels;
    }

    if (deviceTypeCheckers.isDoor(item)) {
        return 1;
    }

    const usedChannels = getUsedChannels(
        parent._id,
        currentProjectRelationsRecord,
        currentProjectItems,
    );
    return availableChannels - usedChannels + item.quantity;
}
