import type { IPiaItem, IPiaContext } from '../types';
import { PiaQuery } from './PiaQuery';
import { filterProducts } from '../../utils';

export class PiaItemService<TItem extends IPiaItem> {
    constructor(private ctx: IPiaContext<TItem, any>) {}

    public setContext(ctx: IPiaContext<TItem, any>) {
        this.ctx = ctx;
    }

    public get(id: number): PiaQuery<TItem> {
        return new PiaQuery(() => {
            const item = this.ctx.items.get(id);
            return item ? [item] : [];
        });
    }

    public getMultiple(ids: number[], regions?: string[]): PiaQuery<TItem> {
        return new PiaQuery(() => {
            const items = ids
                .map((id) => this.ctx.items.get(id))
                .filter((item): item is TItem => item !== undefined);
            return regions ? items.filter(filterProducts.byRegions(regions)) : items;
        });
    }

    public getAll(regions?: string[]): PiaQuery<TItem> {
        return new PiaQuery(() => {
            const items = Array.from(this.ctx.items.values());
            return regions ? items.filter(filterProducts.byRegions(regions)) : items;
        });
    }
}
