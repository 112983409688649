import { injectable } from 'inversify';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import { ProjectDevicesActions } from '../projectDevices.actions';
import { CommonActionService, getOverlayPanelOpen } from 'app/modules/common';
import type { IProjectDevicePanelItem } from '../models';
import { ProjectDeviceDetailsTabId } from '../models';
import { IProjectDeviceSort, Id } from 'app/core/persistence';

@injectable()
export class ProjectDevicesActionService {
    constructor(private commonActionService: CommonActionService) {}

    @ActionCreator()
    public resetToInitialState(): IAction<void> {
        return {
            type: ProjectDevicesActions.ResetToInitialState,
            payload: undefined,
        };
    }

    @ActionCreator()
    public setPanelItem(itemId: Id): ThunkAction {
        return (dispatch, getState) => {
            const state = getState();
            const { panelItem } = state.projectDevices;
            const panelOpen = getOverlayPanelOpen(state);

            // we should show (i.e. not close) if it is open and we selected another item
            const shouldShowPanel = panelOpen && itemId !== panelItem?.itemId;
            if (shouldShowPanel) {
                this.commonActionService.showOverlayPanel(shouldShowPanel);
            }
            const payload: Partial<IProjectDevicePanelItem> = { itemId };

            // set the current selection
            dispatch({
                type: ProjectDevicesActions.SetPanelItem,
                payload,
            });
        };
    }

    @ActionCreator()
    public togglePanel(itemId: Id, initialTabId?: ProjectDeviceDetailsTabId): ThunkAction {
        return (dispatch, getState) => {
            const state = getState();
            const { panelItem } = state.projectDevices;
            const panelOpen = getOverlayPanelOpen(state);

            // determine whether to show the panel or not
            const shouldShowPanel = !panelOpen || panelItem?.itemId !== itemId;
            if (shouldShowPanel) {
                this.commonActionService.showOverlayPanel(shouldShowPanel);
            }
            const payload: Partial<IProjectDevicePanelItem> = {
                itemId,
                initialTabId,
            };

            // set the current selection
            dispatch({
                type: ProjectDevicesActions.SetPanelItem,
                payload,
            });
        };
    }

    @ActionCreator()
    public closePanel(): ThunkAction {
        return (_dispatch) => {
            this.commonActionService.showOverlayPanel(false);
        };
    }

    @ActionCreator()
    public deviceFilterChange(value: string): IAction<string> {
        return {
            type: ProjectDevicesActions.DeviceFilterChange,
            payload: value,
        };
    }

    @ActionCreator()
    public setDeviceSortOrder(value: IProjectDeviceSort): IAction<IProjectDeviceSort> {
        return {
            type: ProjectDevicesActions.SetSortOrder,
            payload: value,
        };
    }

    @ActionCreator()
    public openMultipleCopiesModal(deviceId: Id): IAction<Id> {
        return {
            type: ProjectDevicesActions.SetMultipleCopiesModalData,
            payload: deviceId,
        };
    }

    @ActionCreator()
    public closeMultipleCopiesModal(): IAction<void> {
        return {
            type: ProjectDevicesActions.SetMultipleCopiesModalData,
            payload: undefined,
        };
    }

    @ActionCreator()
    public toggleExpandedDevice(itemId: Id): ThunkAction {
        return (dispatch, getState) => {
            const state = getState();
            const { expandedDevices } = state.projectDevices;

            const newExpandedDeviceList = expandedDevices.includes(itemId)
                ? expandedDevices.filter((id) => itemId !== id)
                : expandedDevices.concat([itemId]);

            dispatch({
                type: ProjectDevicesActions.ToggleExpandedDevice,
                payload: newExpandedDeviceList,
            });
        };
    }
}
