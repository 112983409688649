import { Box } from 'app/components/containers/box/Box.component';
import * as React from 'react';
import { Stack } from '../../layout/stack/Stack.component';
import { Action } from '../action/Action.component';
import { IconButton } from '../iconButton';
import { Text } from '../../text/text/Text.component';
import { Border } from 'app/components/style';
import { calculatePagesToShow as calculatePaginationRangeToShow } from './calculatePaginationRangeToShow';

interface IPaginatorProps {
    pageCount: number;
    currentPage: number;
    setCurrentPage(pageNumber: number): void;
}

const middleDots = '···';

export const Paginator: React.FC<IPaginatorProps> = ({
    pageCount,
    currentPage,
    setCurrentPage,
}) => {
    return (
        <Stack spacing="half">
            <IconButton
                icon="keyboard_arrow_left"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
            />
            <Stack spacing="halfQuart">
                {pageCount > 5 ? renderPaginationWithEllipsis() : renderPagination()}
            </Stack>
            <IconButton
                icon="keyboard_arrow_right"
                disabled={currentPage === pageCount}
                onClick={() => setCurrentPage(currentPage + 1)}
            />
        </Stack>
    );

    function renderPaginationWithEllipsis() {
        const firstPage =
            currentPage <= 3 ? null : (
                <>
                    {renderPage(1)}
                    <Text>{middleDots}</Text>
                </>
            );
        const pages = calculatePaginationRangeToShow(currentPage, pageCount).map((page) =>
            currentPage === page ? renderCurrentPage(page) : renderPage(page),
        );
        const lastPage =
            currentPage >= pageCount - 2 ? null : (
                <>
                    <Text>{middleDots}</Text>
                    {renderPage(pageCount)}
                </>
            );
        return [firstPage, ...pages, lastPage];
    }

    function renderPagination() {
        return Array.from({ length: pageCount }, (_v, i) => i + 1).map((page) =>
            currentPage === page ? renderCurrentPage(page) : renderPage(page),
        );
    }

    function renderCurrentPage(page: number) {
        return (
            <Border key={`currentPage-${page}`} bottomWidth={2} color="yellow">
                <Box
                    paddingTop="halfQuart"
                    paddingX="quart"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text style="semibold" color="grey9">
                        {page}
                    </Text>
                </Box>
            </Border>
        );
    }

    function renderPage(page: number) {
        return (
            <Box key={`page-${page}`} paddingX="quart" paddingY="halfQuart">
                <Action
                    title={page.toString()}
                    onClick={() => setCurrentPage(page)}
                    disabled={currentPage === page}
                />
            </Box>
        );
    }
};

Paginator.displayName = 'Paginator';
