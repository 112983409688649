import type { IPiaLocation, IPiaContext } from '../types';
import { PiaQuery } from './PiaQuery';
import { isImplicitlySelectableLocation } from '../../utils/filterLocations';

export class PiaLocationService<TLocation extends IPiaLocation> {
    constructor(private ctx: IPiaContext<any, TLocation>) {}

    public setContext(ctx: IPiaContext<any, TLocation>) {
        this.ctx = ctx;
    }

    public get(id: number): PiaQuery<TLocation> {
        return this.getAll().filter((item) => item.id === id);
    }

    public getByCountryCode(countryCode: string): PiaQuery<TLocation> {
        const theCountryCode = countryCode.toLowerCase();
        return this.getAll()
            .filter(isImplicitlySelectableLocation)
            .filter((item) => item.countryCode === theCountryCode);
    }

    public getMultiple(ids: number[]): PiaQuery<TLocation> {
        return this.getAll().filter((item) => ids.indexOf(item.id) >= 0);
    }

    public getAll(): PiaQuery<TLocation> {
        return new PiaQuery<TLocation>(() => {
            return this.ctx.locations;
        });
    }
}
