export const trigonometry = {
    toRadians(degrees: number) {
        return (degrees * Math.PI) / 180;
    },
    toDegrees(radians: number) {
        return (radians * 180) / Math.PI;
    },
    hypotenuse(a: number, b: number) {
        return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
    },
};
