import type { IPiaSystemComponent, IPiaSoftware } from 'app/core/pia';
import type { IPersistence, IItemEntity, Id, IItemRelationEntity } from 'app/core/persistence';

/**
 * Get the number of ethernet ports provided by an item
 */
export const getNumberOfPorts = (piaItem: IPiaSystemComponent, quantity: number = 1) => {
    const totalPorts =
        ((piaItem.properties.poeClass1PortCount ?? 0) +
            (piaItem.properties.poeClass2PortCount ?? 0) +
            (piaItem.properties.poeClass3PortCount ?? 0) +
            (piaItem.properties.poeClass4PortCount ?? 0) +
            (piaItem.properties.highPoEPortCount ?? 0)) *
        quantity;

    const ports = {
        poeClass1Ports: (piaItem.properties.poeClass1PortCount ?? 0) * quantity,
        poeClass2Ports: (piaItem.properties.poeClass2PortCount ?? 0) * quantity,
        poeClass3Ports: (piaItem.properties.poeClass3PortCount ?? 0) * quantity,
        poeClass4Ports: (piaItem.properties.poeClass4PortCount ?? 0) * quantity,
        poeClass5Ports: (piaItem.properties.poeClass5PortCount ?? 0) * quantity,
        poeClass6Ports: (piaItem.properties.poeClass6PortCount ?? 0) * quantity,
        poeClass7Ports: (piaItem.properties.poeClass7PortCount ?? 0) * quantity,
        poeClass8Ports: (piaItem.properties.poeClass8PortCount ?? 0) * quantity,
        highPoEPorts: (piaItem.properties.highPoEPortCount ?? 0) * quantity,
        totalPorts,
    };

    return ports;
};

/**
 * Get the number of ethernet ports provided by an item
 */
export const getPartnerNumberOfPorts = () => {
    const totalPorts = 0;

    const ports = {
        poeClass1Ports: 0,
        poeClass2Ports: 0,
        poeClass3Ports: 0,
        poeClass4Ports: 0,
        poeClass5Ports: 0,
        poeClass6Ports: 0,
        poeClass7Ports: 0,
        poeClass8Ports: 0,
        highPoEPorts: 0,
        totalPorts,
    };

    return ports;
};

/**
 * Get the number of licenses included in this item.
 * May be either a recorder or an actual software license
 */
export const getNumberOfIncludedLicenses = (piaItem: IPiaSystemComponent & IPiaSoftware) =>
    !piaItem.properties.vmsChannelLicenses && !piaItem.properties.licenseType
        ? 0
        : piaItem.properties.vmsChannelLicenses ||
          (piaItem.properties.licenseType === 'Device' || piaItem.properties.licenseType === 'NVR'
              ? 1
              : 0);

/**
 * The amount of extra storage capacity
 * added to this item by external accessories (hard drives)
 */
export const getAdditionalStorage = (
    item: IPersistence<IItemEntity>,
    projectItems: Record<Id, IItemEntity | undefined>,
    relations: IItemRelationEntity[],
    piaItems: Record<number, IPiaSystemComponent | undefined>,
) =>
    relations
        .filter(
            (relation) => relation.parentId === item._id && relation.relationType === 'accessory',
        )
        .map((relation) => {
            const accessoryItem = projectItems[relation.childId];
            return {
                quantity: accessoryItem?.quantity ?? 0,
                piaData: accessoryItem ? piaItems[accessoryItem.productId!] : undefined,
            };
        })
        .reduce((totalStorage, accessory) => {
            const storage = accessory.piaData?.properties.maxRecordingStorageMegaBytes ?? 0;
            return totalStorage + accessory.quantity * storage;
        }, 0);
