import * as React from 'react';
import { useSelector } from 'react-redux';
import { Border, Box } from 'app/components';
import { MilestoneSolutions } from './MilestoneSolutions';
import { GenetecSelector, GenetecSelectType } from './genetecRecommendations';
import { getSelectedRecordingVendor } from 'app/modules/common';
import { SelectSolutionType } from './SelectSolutionType';

import { isFeatureEnabled } from 'app/featureFlags';
import { AxisSelectCenter, AxisSelectType, AxisSolutions } from './axisRecommendations';
import { getViewingAxisAcsType } from '../selectors';

export const SolutionsSection: React.FC = () => {
    const selectedRecordingVendor = useSelector(getSelectedRecordingVendor);
    const viewingAxisAcsType = useSelector(getViewingAxisAcsType);

    return (
        <Border color="grey2" bottomWidth={1}>
            <Box
                testId="solutions_selection"
                color="white"
                display="flex"
                justifyContent="center"
                direction="column"
                alignItems="center"
                spacing="halfCell"
                padding="base"
                paddingBottom="panel"
            >
                <Box spacing="base">
                    <SelectSolutionType />
                    {selectedRecordingVendor === 'Genetec' && <GenetecSelectType underline />}
                    {selectedRecordingVendor === 'Axis' && <AxisSelectType />}
                    {isFeatureEnabled('acs_center') &&
                        selectedRecordingVendor === 'Axis' &&
                        viewingAxisAcsType === 'CameraStationPro' && (
                            <Border leftWidth={1} color="grey3">
                                <Box paddingLeft="base">
                                    <AxisSelectCenter />
                                </Box>
                            </Border>
                        )}
                </Box>
                {selectedRecordingVendor === 'Milestone' && (
                    <Box paddingY="panel">
                        <MilestoneSolutions />
                    </Box>
                )}
                <Box spacing="cell" justifyContent="center">
                    {selectedRecordingVendor === 'Axis' && <AxisSolutions />}
                    {selectedRecordingVendor === 'Genetec' && <GenetecSelector />}
                </Box>
            </Box>
        </Border>
    );
};
SolutionsSection.displayName = 'SolutionsSection';
