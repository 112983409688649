import type {
    Id,
    IDoorItemEntity,
    IItemEntity,
    IItemRelationEntity,
    IPersistence,
} from 'app/core/persistence';
import type { IPiaDoorController, IPiaItem, PiaId } from 'app/core/pia';
import { PiaItemState, filterProducts, isDiscontinued, PiaAccessoryCategory } from 'app/core/pia';

import type { ISelectedMountAccessory } from 'app/modules/accessorySelector';
import {
    getCurrentProjectItem,
    getCurrentProjectItemRelationsArray,
    getCurrentProjectItems,
    getCurrentProjectRelationsForItem,
    getCurrentProjectRelationsRecord,
    getIdFromProps,
    getPiaItemsRecord,
    getRelatedPiaItemsForItem,
    toCacheKey,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { creationDateReverseComparator } from 'app/utils';
import { isDefined } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';

export const getAllCardReadersForProduct = createCachedSelector(
    [getRelatedPiaItemsForItem],
    (relatedPiaItems) => {
        const cardReaders = relatedPiaItems
            .filter(filterProducts.byCategories([PiaAccessoryCategory.READERS]))
            .filter(isDefined);
        const otherCardReader = {
            id: 0,
            name: '',
            category: PiaAccessoryCategory.READERS,
            categories: [PiaAccessoryCategory.READERS],
            state: PiaItemState.ANY,
            externallyHidden: false,
            relations: [],
            properties: {
                vendor: 'other',
            },
            parentId: 0,
            versions: [],
            isIncluded: false,
            isRecommended: false,
        };
        cardReaders.push(otherCardReader);
        return cardReaders;
    },
)(toCacheKey);

export const getmaxNbrOfDoorsForProduct = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (currentProjectItem, piaItemsRecord) => {
        if (!currentProjectItem?.productId || !currentProjectItem.properties.doorController) {
            return 0;
        }
        const supportedOnboardDoors =
            (piaItemsRecord[currentProjectItem.productId] as IPiaDoorController).properties
                .supportedOnboardDoors ?? 0;
        return supportedOnboardDoors;
    },
)(toCacheKey);

export const getMaxNbrOfReadersForProduct = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (currentProjectItem, piaItemsRecord) => {
        if (!currentProjectItem?.productId || !currentProjectItem.properties.doorController) {
            return 0;
        }
        const supportedOnboardReaders =
            (piaItemsRecord[currentProjectItem.productId] as IPiaDoorController).properties
                .supportedOnboardReaders ?? 0;
        return supportedOnboardReaders;
    },
)(toCacheKey);

export const getMaxNbrOfRexDevicesForProduct = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (currentProjectItem, piaItemsRecord) => {
        if (!currentProjectItem?.productId || !currentProjectItem.properties.doorController) {
            return 0;
        }
        const maxSupportedREXdevices =
            (piaItemsRecord[currentProjectItem.productId] as IPiaDoorController).properties
                .maxSupportedREXdevices ?? 0;
        return maxSupportedREXdevices;
    },
)(toCacheKey);

export const getAllRexDevicesForProduct = createCachedSelector(
    [getRelatedPiaItemsForItem],
    (relatedPiaItems) => {
        const rexDevices = relatedPiaItems
            .filter(filterProducts.byCategories([PiaAccessoryCategory.REX]))
            .filter(isDefined);
        const otherCardReader = {
            id: 0,
            name: '',
            category: PiaAccessoryCategory.REX,
            categories: [PiaAccessoryCategory.REX],
            state: PiaItemState.ANY,
            externallyHidden: false,
            relations: [],
            properties: {
                vendor: 'other',
            },
            parentId: 0,
            versions: [],
            isIncluded: false,
            isRecommended: false,
        };
        rexDevices.push(otherCardReader);
        return rexDevices;
    },
)(toCacheKey);

export const getDoor = createCachedSelector([getCurrentProjectItem], (item) => {
    if (!item?.properties.door) {
        return undefined;
    }
    return item as IPersistence<IDoorItemEntity>;
})(toCacheKey);

// return doors for doorControllerId
export const getDoorsForProduct = createCachedSelector(
    [getCurrentProjectRelationsForItem, getCurrentProjectItems],
    (itemRelations, currentProjectItems) => {
        return getDoorChildrenForDevice(itemRelations, currentProjectItems).sort(
            creationDateReverseComparator,
        );
    },
)(toCacheKey);

/**
 * Returns a record of selected cardReaders per door for doorControllerId where doorId is key
 */
export const getSelectedCardReadersForDoorController = createCachedSelector(
    [
        getCurrentProjectItemRelationsArray,
        getCurrentProjectRelationsForItem,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getIdFromProps,
    ],
    (relations, itemRelations, items, piaItems, id): Record<Id, ISelectedMountAccessory[]> => {
        const doorSideRelations = id
            ? relations.filter(
                  (relation) =>
                      (relation.relationType === 'doorSideA' && relation.path.includes(id)) ||
                      (relation.relationType === 'doorSideB' && relation.path.includes(id)),
              )
            : [];
        const doors = getDoorChildrenForDevice(itemRelations, items).sort(
            creationDateReverseComparator,
        );
        return doors.reduce(
            (cardReaders, door) => {
                const doorRelations = doorSideRelations
                    .map((relation) => {
                        if (relation.parentId === door._id) return relation;
                    })
                    .filter(isDefined);
                cardReaders[door._id] = getAccessoriesForCategory(
                    doorRelations,
                    items,
                    piaItems,
                    PiaAccessoryCategory.READERS,
                );
                return cardReaders;
            },
            {} as Record<Id, ISelectedMountAccessory[]>,
        );
    },
)(toCacheKey);

// return nbr of added doors for doorControllerId
export const getNbrAddedDoorsForProduct = createCachedSelector(
    [getCurrentProjectRelationsForItem, getCurrentProjectItems],
    (itemRelations, currentProjectItems) => {
        const doors = getDoorChildrenForDevice(itemRelations, currentProjectItems).sort(
            creationDateReverseComparator,
        );
        return doors.length;
    },
)(toCacheKey);

// return number of locks used for the doorController
export const getNbrOfLocksUsedForDoorController = createCachedSelector(
    [getDoorsForProduct],
    (doors) => {
        const usedNbrOfLocks = doors.reduce((sum, doorItem) => {
            return sum + (doorItem.properties.door?.nbrOfLocks ?? 1);
        }, 0);
        return usedNbrOfLocks;
    },
)(toCacheKey);

export const getDoorChildrenForDevice = (
    itemRelations: IItemRelationEntity[],
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
) => {
    if (!itemRelations) {
        return [];
    }

    return itemRelations
        .filter(({ relationType }) => relationType === 'door')
        .map(({ childId }) => currentProjectItems[childId])
        .filter(isDefined);
};

/**
 * Returns number of selected card readers for doorControllerId
 */
export const getNbrSelectedCardReadersForDoorController = createCachedSelector(
    [
        getCurrentProjectItemRelationsArray,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getIdFromProps,
    ],
    (relations, items, piaItems, id): number => {
        const doorSideRelations = id
            ? relations.filter(
                  (relation) =>
                      (relation.relationType === 'doorSideA' && relation.path.includes(id)) ||
                      (relation.relationType === 'doorSideB' && relation.path.includes(id)),
              )
            : [];
        const selectedAccessories = getAccessoriesForCategory(
            doorSideRelations,
            items,
            piaItems,
            PiaAccessoryCategory.READERS,
        );
        return selectedAccessories.length;
    },
)(toCacheKey);

/**
 * Returns number of selected rex devices for doorControllerId
 */
export const getNbrSelectedRexDevicesForDoorController = createCachedSelector(
    [
        getCurrentProjectItemRelationsArray,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getIdFromProps,
    ],
    (relations, items, piaItems, id): number => {
        const doorSideRelations = id
            ? relations.filter(
                  (relation) =>
                      (relation.relationType === 'doorSideA' && relation.path.includes(id)) ||
                      (relation.relationType === 'doorSideB' && relation.path.includes(id)),
              )
            : [];
        const selectedAccessories = getAccessoriesForCategory(
            doorSideRelations,
            items,
            piaItems,
            PiaAccessoryCategory.REX,
        );
        return selectedAccessories.length;
    },
)(toCacheKey);

export type DoorSide = 'doorSideA' | 'doorSideB';

const getItemIdAndDoorSide = (_state: IStoreState, itemId: Id, doorSide: DoorSide) => ({
    itemId,
    doorSide,
});

/**
 * returns selected readers for the doorItemId with doorSide (doorSideA or doorSideB)
 */
export const getSelectedCardReadersForIdAndDoorSide = createCachedSelector(
    [
        getCurrentProjectRelationsForItem,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getItemIdAndDoorSide,
    ],
    (relations, items, piaItems, { doorSide }): Record<PiaId, ISelectedMountAccessory> => {
        const doorSideRelations = relations.filter(
            (relation) => relation.relationType === doorSide,
        );
        return getAccessoriesForAccessoryCategory(
            doorSideRelations,
            items,
            piaItems,
            PiaAccessoryCategory.READERS,
        );
    },
)(toCacheKey);

/**
 * returns selected rex devices for the doorItemId with doorSide (doorSideA or doorSideB)
 */
export const getSelectedRexForDoorSideAndId = createCachedSelector(
    [
        getCurrentProjectRelationsForItem,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getItemIdAndDoorSide,
    ],
    (relations, items, piaItems, { doorSide }): Record<PiaId, ISelectedMountAccessory> => {
        const doorSideRelations = relations.filter(
            (relation) => relation.relationType === doorSide,
        );
        return getAccessoriesForAccessoryCategory(
            doorSideRelations,
            items,
            piaItems,
            PiaAccessoryCategory.REX,
        );
    },
)(toCacheKey);

/**
 *
 * @param relations relations for an item
 * @param items current project items
 * @param piaItems piaItems
 * @param category accessory category to find
 * @returns a record of accessories for the provided category
 */

const getAccessoriesForAccessoryCategory = (
    relations: IItemRelationEntity[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    piaItems: Record<number, IPiaItem>,
    category: string,
) => {
    const accessories = getAccessoriesForCategory(relations, items, piaItems, category);
    return accessories.reduce(
        (accessoriesRecord, accessory) => {
            accessoriesRecord[accessory.productId] = accessory;
            return accessoriesRecord;
        },
        {} as Record<PiaId, ISelectedMountAccessory>,
    );
};

/**
 *
 * @param relations relations for an item
 * @param items current project items
 * @param piaItems piaItems
 * @param category accessory category to find
 * @returns a vector of all accessories connected (including duplicates)
 */
export const getAccessoriesForCategory = (
    relations: IItemRelationEntity[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    piaItems: Record<number, IPiaItem>,
    category: string,
) => {
    return relations
        .map((relation) => items[relation.childId])
        .filter(isDefined)
        .filter((relation) => relation.properties.accessory?.category === category)
        .map((item) => {
            const piaItem = item.productId
                ? piaItems[item.productId].category === category
                    ? piaItems[item.productId]
                    : undefined
                : undefined;
            return piaItem
                ? ({
                      id: item._id,
                      rev: item._rev,
                      productId: item.productId,
                      name: piaItem.name,
                      isDiscontinued: isDiscontinued(piaItem),
                  } as ISelectedMountAccessory)
                : ({
                      id: item._id,
                      rev: item._rev,
                      productId: 0,
                      name: item.name,
                      isDiscontinued: false,
                  } as ISelectedMountAccessory);
        })
        .filter(isDefined);
};

/**
 * Returns if there is more doors available to add for the doorControllerId
 */
export const getCanAddDoor = createCachedSelector(
    [
        getCurrentProjectRelationsRecord,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getCurrentProjectItemRelationsArray,
        getmaxNbrOfDoorsForProduct,
        getMaxNbrOfReadersForProduct,
        getIdFromProps,
    ],
    (
        relationsRecord,
        currentProjectItems,
        piaItemsRecord,
        relationsArray,
        maxNbrOfDoors,
        maxNbrOfCardReaders,
        itemId,
    ): boolean => {
        if (!itemId) {
            return false;
        }
        const relations = relationsRecord[itemId];
        const doors = getDoorChildrenForDevice(relations, currentProjectItems).sort(
            creationDateReverseComparator,
        );
        const doorSideRelations = itemId
            ? relationsArray.filter(
                  (relation) =>
                      (relation.relationType === 'doorSideA' && relation.path.includes(itemId)) ||
                      (relation.relationType === 'doorSideB' && relation.path.includes(itemId)),
              )
            : [];
        const selectedAccessories = getAccessoriesForCategory(
            doorSideRelations,
            currentProjectItems,
            piaItemsRecord,
            PiaAccessoryCategory.READERS,
        );
        const nbrSelectedReaders = selectedAccessories.length;
        const usedNbrOfLocks = doors.reduce((sum, door) => {
            return sum + (door.properties.door?.nbrOfLocks || 1);
        }, 0);

        const disableAddDoor =
            maxNbrOfDoors - usedNbrOfLocks < 1 || maxNbrOfCardReaders - nbrSelectedReaders < 1;

        return !disableAddDoor;
    },
)(toCacheKey);
