/**
 * Returns a page array containing the specified number of pages to show given the current page and the total number of pages.
 * The algorithm aims to put current page as close as possible to the middle of the numbers.
 * */
export function calculatePagesToShow(
    currentPage: number,
    pageCount: number,
    pagesToShow = 5,
): number[] {
    const addFromCurrentPage = Math.max(1, currentPage - Math.floor((pagesToShow - 1) / 2));
    const subtractFromPageCount = Math.min(
        0,
        pageCount - (currentPage + Math.floor(pagesToShow / 2)),
    );
    const startPage = addFromCurrentPage + subtractFromPageCount;

    const pages = Array.from({ length: pagesToShow }, (_v, i) => {
        return i + startPage;
    });
    return pages;
}
