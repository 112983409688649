import { createSelector } from 'reselect';
import { getSelectedDeviceId } from './getSelectedId';
import { getAllValidMapDevices } from './getListableDevices';

export const getSelectedDevice = createSelector(
    [getSelectedDeviceId, getAllValidMapDevices],
    (id, devices) => {
        return devices.find((d) => d.id === id);
    },
);
