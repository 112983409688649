import { createCachedSelector } from 're-reselect';
import { getCurrentProjectRelationsRecord } from '../../relations';
import { getCurrentProjectItem } from '../../project';
import { getDeviceAndSubTypeForItem } from './getDeviceTypeForItem';
import type { DeviceAndSubType } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';

const ignoredDeviceTypes = new Set<DeviceAndSubType | undefined>([
    'sensorUnit',
    'analogCamera',
    undefined,
]);

export const getHasApplications = createCachedSelector(
    [getCurrentProjectRelationsRecord, getCurrentProjectItem, getDeviceAndSubTypeForItem],
    (currentProjectRelationsRecord, item, deviceType) => {
        if (!item || ignoredDeviceTypes.has(deviceType)) {
            return undefined;
        }

        const relations = currentProjectRelationsRecord[item._id];

        if (!relations) {
            return false;
        }

        return relations.some(
            (relation) =>
                relation.relationType === 'acap' || relation.relationType === 'partnerAcap',
        );
    },
)(toCacheKey);
