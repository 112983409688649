import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import {
    getPiaItemsRecord,
    createDeepEqualSelector,
    getCurrentProjectItemsArray,
} from 'app/modules/common';
import { UnreachableCaseError } from 'axis-webtools-util';
import type {
    IItemEntity,
    IPersistence,
    Id,
    DeviceType,
    IProjectDeviceSort,
} from 'app/core/persistence';
import { getDeviceType, getParentId } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { sortBy } from 'lodash-es';
import * as moment from 'moment';
import {
    nameAndModelComparator,
    nameAndModelComparatorReverse,
    modelAndNameComparator,
    modelAndNameComparatorReverse,
    quantityAndNameComparator,
    quantityAndNameComparatorReverse,
} from 'app/utils';

export interface IProjectDevice {
    id: Id;
    name: string;
    quantity: number;
    model: string;
    children: IProjectDevice[];
    type: DeviceType | undefined;
}

export const getProjectDeviceFilter = (state: IStoreState) => state.projectDevices.deviceFilter;

const getSorting = (state: IStoreState) => state.projectDevices.sortOrder;

const createFilter =
    (searchStr: string) =>
    ({ name, model }: IProjectDevice) =>
        name.toLowerCase().includes(searchStr.toLowerCase()) ||
        model.toLowerCase().includes(searchStr.toLowerCase());

const getListDevices = createSelector([getCurrentProjectItemsArray], (items) =>
    items.filter(({ properties }) => {
        return (
            properties.camera ||
            properties.encoder ||
            properties.decoder ||
            properties.mainUnit ||
            properties.speaker ||
            properties.pac ||
            properties.radarDetector ||
            properties.analogCamera ||
            properties.alerter ||
            properties.sensorUnit ||
            properties.radarDetector ||
            properties.peopleCounter ||
            properties.bodyWornCamera ||
            properties.dockingStation ||
            properties.systemController ||
            properties.cameraExtension ||
            properties.door ||
            properties.doorController ||
            properties.connectivityDevice ||
            properties.pagingConsole
        );
    }),
);

const getCurrentProjectDevicesWithModel = createSelector(
    [getListDevices, getPiaItemsRecord],
    (devices, piaDevices) => {
        devices = sortBy(devices, (device) => device.path.length);
        devices = sortBy(devices, (device) => moment(device.creationDate).valueOf());

        return devices.reduce((deviceModels, device) => {
            const deviceItem = mapToIProjectDevice(device, piaDevices, deviceModels);
            if (deviceItem) {
                deviceModels.push(deviceItem);
            }
            return deviceModels;
        }, [] as IProjectDevice[]);
    },
);

const mapToIProjectDevice = (
    item: IPersistence<IItemEntity>,
    piaDevices: Record<number, IPiaItem>,
    deviceModels: IProjectDevice[],
): IProjectDevice | undefined => {
    const device: IProjectDevice = {
        id: item._id,
        name: item.name,
        quantity: item.quantity,
        model: !item.productId ? '' : piaDevices[item.productId]?.name ?? '',
        children: [],
        type: getDeviceType(item),
    };

    const parent = deviceModels.find((model) => model.id === getParentId(item));

    if (parent) {
        parent.children.push(device);
        return;
    }

    return device;
};

export const getSortedProjectDevices = createDeepEqualSelector(
    [getProjectDeviceFilter, getCurrentProjectDevicesWithModel, getSorting],
    (searchStr, devices, sorting) =>
        devices.filter(createFilter(searchStr)).sort(getDeviceSortComparator(sorting)),
);

function getDeviceSortComparator(sortProperty: IProjectDeviceSort) {
    switch (sortProperty.sort) {
        case 'none':
            return (_a: IProjectDevice, _b: IProjectDevice) => 0;
        case 'name':
            if (sortProperty.direction === 'ascending') return nameAndModelComparator;
            else return nameAndModelComparatorReverse;

        case 'model':
            if (sortProperty.direction === 'ascending') return modelAndNameComparator;
            else return modelAndNameComparatorReverse;

        case 'quantity':
            if (sortProperty.direction === 'ascending') return quantityAndNameComparator;
            else return quantityAndNameComparatorReverse;
        default:
            throw new UnreachableCaseError(sortProperty.sort);
    }
}
