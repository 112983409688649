import { injectable } from 'inversify';
import { ActionCreator } from 'app/store';
import { eventTracking } from 'app/core/tracking';
import { MapsReportActions } from './MapsReport.actions';
import { IPaperSize, Orientation } from './../models';

const paperSizeToString = (paperSize: IPaperSize) => {
    const navLang = navigator.language.toLowerCase();
    return `name: ${paperSize.name}, width: ${paperSize.width}, height: ${paperSize.height}, unit: ${paperSize.unit}, language: ${navLang}`;
};
@injectable()
export class MapsReportsActionService {
    @ActionCreator()
    public setPaperSize(paperSize: IPaperSize) {
        eventTracking.logUserEvent(
            'Reports',
            'Maps Report Change Paper Size',
            paperSizeToString(paperSize),
        );
        return {
            type: MapsReportActions.SetPaperSize,
            payload: paperSize,
        };
    }

    @ActionCreator()
    public setOrientation(orientation: Orientation) {
        const navLang = navigator.language.toLowerCase();
        eventTracking.logUserEvent(
            'Reports',
            'Maps Report Change Orientation',
            `orientation: ${orientation}, language: ${navLang}`,
        );
        return {
            type: MapsReportActions.SetOrientation,
            payload: orientation,
        };
    }
}
