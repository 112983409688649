import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds default labelOffset to installationPoint.
 */
@injectable()
export class Migration17To18 extends MigrationProviderBase {
    public from: number = 17;
    public to: number = 18;

    public provideMigration() {
        return (entity: any): Promise<any> => {
            if (entity.type === 'installationPoint' && !entity.labelOffset) {
                entity.labelOffset = { x: 0, y: -53 };
            }

            return Promise.resolve(entity);
        };
    }
}
