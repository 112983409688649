import { flatMap, without, difference, times } from 'lodash-es';
import type { IPiaSystemComponent } from 'app/core/pia';
import type { IDeviceRequirement, IRecorderConfiguration, ISwitchConfiguration } from '../common';
import { NoSolutionError, isSwitch, PORT_BLOCKER } from '../common';

import { partitionDevicesToSwitch, partitionDevicesToRecorder } from './partitioning';
import { bySwitchUtilization, bySwitchRecommendation } from '../comparators';

// given a list of recorder configurations, find a set of switches with enough ports
// to connect all recorders to their devices. Multiple recorders can be connected to
// a single switch
const recommendAdditionalSwitches = (
    switches: IPiaSystemComponent[],
    recommendedRecorders: IRecorderConfiguration[],
    recommendedSwitches: ISwitchConfiguration[],
): ISwitchConfiguration[] => {
    const allDevicesWithSwitch = flatMap(recommendedSwitches, ({ devices }) => devices);
    // find out which recorders that still require switches to connect all devices
    const recordersRequiringSwitch = recommendedRecorders.filter(({ devices }) =>
        devices.some((device) => !allDevicesWithSwitch.includes(device)),
    );
    if (recordersRequiringSwitch.length === 0) {
        // exit early if we don't need any more switches
        return [];
    }

    const allDevicesWithRecorder = flatMap(recordersRequiringSwitch, ({ devices }) => devices);

    // figure out which devices that require a switch
    const devicesWithoutSwitch = difference(allDevicesWithRecorder, allDevicesWithSwitch);

    // in order to assign n recorders to one switch we need n-1 free (downlink) ports
    // (one is connected to the uplink port)
    const requiredPortsForRecorders = recordersRequiringSwitch.length - 1;

    // create port blockers for all recorder connections. A port blocker consumes one NoPoE port
    const portBlockers: IDeviceRequirement[] = times(requiredPortsForRecorders, () => PORT_BLOCKER);

    // find the best switch capable of connecting all devices AND all recorders
    const candidates = switches
        .map((networkSwitch) =>
            partitionDevicesToSwitch(networkSwitch, [...devicesWithoutSwitch, ...portBlockers]),
        )
        .sort(bySwitchUtilization);

    const bestCandidate = candidates[candidates.length - 1];
    if (!bestCandidate) {
        throw new NoSolutionError(`Can't find best candidate for additional switches`);
    }

    if (bestCandidate.remainingDevices.length === 0) {
        // we found a switch that can handle all recorders and devices
        const switchConfiguration: ISwitchConfiguration = {
            networkSwitch: bestCandidate.networkSwitch,
            recorders: recordersRequiringSwitch,
            devices: without(bestCandidate.selectedDevices, PORT_BLOCKER),
            overCapacity: bestCandidate.overCapacity,
        };

        return [switchConfiguration];
    } else {
        // we couldn't find a switch that can handle all recorders and devices
        if (recordersRequiringSwitch.length === 1) {
            // if we have just one recorder

            const switchConfiguration = {
                networkSwitch: bestCandidate.networkSwitch,
                recorders: recordersRequiringSwitch,
                devices: bestCandidate.selectedDevices,
                overCapacity: bestCandidate.overCapacity,
            };

            // keep adding switches to that recorder until we can connect everything
            return [
                switchConfiguration,
                ...recommendAdditionalSwitches(switches, recordersRequiringSwitch, [
                    switchConfiguration,
                    ...recommendedSwitches,
                ]),
            ];
        } else {
            // partition the recorders in two equally sized chunks and recurse for each chunk
            const mid = Math.ceil(recordersRequiringSwitch.length / 2);
            const firstHalf = recordersRequiringSwitch.slice(0, mid);
            const secondHalf = recordersRequiringSwitch.slice(mid);

            // recurse in a divide-and-conquer manner
            return [
                ...recommendAdditionalSwitches(switches, firstHalf, recommendedSwitches),
                ...recommendAdditionalSwitches(switches, secondHalf, recommendedSwitches),
            ];
        }
    }
};

/**
 * Recommend switches for a set of recorder configurations
 *
 * @param switches - the available switched
 * @param recommendedRecorders - the recommended recorder configurations
 * @return the switch configuration connecting recorders to devices
 */
export const recommendSwitches = (
    switches: IPiaSystemComponent[],
    recommendedRecorders: IRecorderConfiguration[],
): ISwitchConfiguration[] => {
    const remainingDevices: IDeviceRequirement[] = [];
    const recommendedSwitches: ISwitchConfiguration[] = [];

    // first fill up all built-in switches
    recommendedRecorders.forEach((recorderRecommendation) => {
        const partitioning = partitionDevicesToSwitch(
            recorderRecommendation.recorder,
            recorderRecommendation.devices,
        );
        partitioning.remainingDevices.forEach((device) => {
            remainingDevices.push(device);
        });

        if (partitioning.selectedDevices.length > 0) {
            const switchConfiguration = {
                networkSwitch: partitioning.networkSwitch,
                recorders: [recorderRecommendation],
                devices: partitioning.selectedDevices,
                overCapacity: partitioning.overCapacity,
            };
            recommendedSwitches.push(switchConfiguration);
        }
    });

    if (remainingDevices.length > 0) {
        // we still have unassigned devices, i.e. we need to add more switches
        const additionalSwitches = canShareSwitches(recommendedRecorders)
            ? // allow switch sharing
              recommendAdditionalSwitches(switches, recommendedRecorders, recommendedSwitches)
            : // find switches for each recorder isolated from each other
              flatMap(recommendedRecorders, (recorderRecommendation) =>
                  recommendAdditionalSwitches(
                      switches,
                      [recorderRecommendation],
                      recommendedSwitches,
                  ),
              );
        recommendedSwitches.push(...additionalSwitches);
    }

    return recommendedSwitches;
};

// determine whether the system can share switches
const canShareSwitches = (recorders: IRecorderConfiguration[]) =>
    !recorders.some(({ recorder }) => recorder.properties.series === 'S22');

const findSwitchConfigWithFreeCapacity = (
    switchConfigurations: ISwitchConfiguration[],
    devices: IDeviceRequirement[],
): ISwitchConfiguration | null => {
    for (const switchConfig of switchConfigurations) {
        // try to partition devices to recorder
        const switchPartitioning = partitionDevicesToSwitch(switchConfig.networkSwitch, [
            ...switchConfig.devices,
            ...devices,
        ]);

        if (switchPartitioning.remainingDevices.length === 0) {
            // we found one, now check the connected recorder(s)
            const hasRecorderWithFreeCapacity = switchConfig.recorders.some((recorderConfig) => {
                // try to partition devices to recorder
                const recorderPartitioning = partitionDevicesToRecorder(recorderConfig.recorder, [
                    ...recorderConfig.devices,
                    ...devices,
                ]);

                return recorderPartitioning.remainingDevices.length === 0;
            });

            if (hasRecorderWithFreeCapacity) {
                // at least one of the connected recorders can house the devices
                return switchConfig;
            }
        }
    }
    return null;
};

/**
 * Remove unnecessary switches.
 *
 * @param recommendation - the unoptimized switch configuration
 * @return the optimized recommendation
 */
export const optimizeSwitches = (
    recommendation: ISwitchConfiguration[],
): ISwitchConfiguration[] => {
    if (recommendation.length === 0) return [];

    const [first, ...rest] = recommendation.sort(bySwitchRecommendation);

    if (isSwitch(first.networkSwitch)) {
        // loop through switches and search for one that can be made unnecessary by
        // reassigning its devices to a different recorder.
        const switchWithFreeCapacity = findSwitchConfigWithFreeCapacity(rest, first.devices);

        if (switchWithFreeCapacity) {
            // We found a new home for the devices of the switch and can now safely
            // remove the switch and move its attached devices to the new recorder

            // add devices to the target recorder
            const afterAdd = partitionDevicesToSwitch(switchWithFreeCapacity.networkSwitch, [
                ...switchWithFreeCapacity.devices,
                ...first.devices,
            ]);
            if (afterAdd.remainingDevices.length > 0) {
                // sanity check
                throw Error('Could not add devices to switch');
            }
            // add to new recorder
            for (const recorderConfig of switchWithFreeCapacity.recorders) {
                // try to partition devices to recorder
                const recorderPartitioning = partitionDevicesToRecorder(recorderConfig.recorder, [
                    ...recorderConfig.devices,
                    ...first.devices,
                ]);

                if (recorderPartitioning.remainingDevices.length === 0) {
                    recorderConfig.overCapacity = recorderPartitioning.overCapacity;
                    recorderConfig.devices = recorderPartitioning.selectedDevices;
                    break;
                }
            }
            //remove from old recorder
            for (const recorderConfig of first.recorders) {
                // try to partition devices to recorder
                const recorderPartitioning = partitionDevicesToRecorder(
                    recorderConfig.recorder,
                    without(recorderConfig.devices, ...first.devices),
                );

                if (recorderPartitioning.remainingDevices.length === 0) {
                    recorderConfig.overCapacity = recorderPartitioning.overCapacity;
                    recorderConfig.devices = recorderPartitioning.selectedDevices;
                    break;
                }
            }
            switchWithFreeCapacity.overCapacity = afterAdd.overCapacity;
            switchWithFreeCapacity.devices = afterAdd.selectedDevices;
            return optimizeSwitches(rest);
        }
    }
    return [first, ...optimizeSwitches(rest)];
};
