import { injectable } from 'inversify';

const MAIL_URL = '/feedback';

@injectable()
export class FeedbackService {
    public async sendFeedback(message: string): Promise<void> {
        await fetch(MAIL_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({ message }),
        });
    }
}
