import * as React from 'react';
import type { IClickableProps } from '../../functional';
import { Clickable, Linkable } from '../../functional';
import { Text } from '../../text';
import { Box } from '../../containers';
import type { IAutoTestable } from '../../ui-test';
import { Border } from '../../style/Border';
import { ColorsEnum, type Colors, type Spacings } from 'app/styles';
import { Icon, type Icons } from '../icon';
import type { IExtendableComponent } from 'app/components/models';
import { DragHandle } from '../dragHandle';

interface ITab extends IClickableProps, IAutoTestable, IExtendableComponent {
    /**
     * Use this to pass a ref prop into this component, you will get
     * the underlying div element as a result.
     */
    innerRef?: React.RefObject<HTMLDivElement> | React.ForwardedRef<HTMLDivElement>;
    /**
     * The label
     */
    text?: string;
    /**
     *  The optional icon placed to the left of the label
     */
    icon?: Icons;
    /**
     * Whether the tab is active
     */
    isActive: boolean;
    /**
     * Whether the tab has border radius
     */
    hasBorderRadius?: boolean;
    /**
     * Use a link instead of an onClick
     */
    link?: string;
    /*
     * The color of the active tab
     */
    activeColor?: Colors;
    /**
     * Allow the tab to grow horizontally
     */
    fullWidth?: boolean;
    /**
     * X axis padding
     */
    paddingX?: Spacings;
    /**
     * Y axis padding
     */
    paddingY?: Spacings;
    /**
     * Show drag handle
     */
    hasDragHandle?: boolean;
    /**
     * Show drag handle disabled (hidden)
     */
    showDragHandleDisabled?: boolean;
}

export const Tab: React.FunctionComponent<ITab> = ({
    innerRef,
    text,
    icon,
    isActive,
    hasBorderRadius,
    activeColor,
    link,
    fullWidth,
    paddingX,
    paddingY,
    hasDragHandle,
    showDragHandleDisabled,
    children,
    ...restProps
}) => (
    <Linkable link={link}>
        <Clickable {...restProps} allowDragThrough={hasDragHandle}>
            <Border
                radius={hasBorderRadius ? '4px 4px 0 0' : undefined}
                topWidth={1}
                leftWidth={1}
                rightWidth={1}
                color={isActive ? 'yellow' : 'grey3'}
                sideColor={{ left: 'grey3', right: 'grey3' }}
                shadow={
                    isActive
                        ? `0 1px ${activeColor ? ColorsEnum[activeColor] : 'white'}`
                        : 'rgba(0, 0, 0, 0.05) 0px -2px 2px 0px inset'
                }
                hoverColor={isActive ? undefined : 'grey4'}
            >
                <Box
                    innerRef={innerRef}
                    testId={restProps.testId}
                    justifyContent="center"
                    alignItems="center"
                    flex={fullWidth ? 'grow' : undefined}
                    color={isActive ? activeColor ?? 'white' : 'grey1'}
                    height="100%"
                >
                    <Border
                        topWidth={3}
                        color={isActive ? 'yellow' : 'transparent'}
                        hoverColor={isActive ? 'yellow' : 'grey3'}
                    >
                        <Box
                            paddingY={paddingY ?? 'half'}
                            paddingLeft={paddingX ?? 'base'}
                            paddingRight={hasDragHandle ? 'quart' : paddingX ?? 'base'}
                            width="100%"
                            height="100%"
                            justifyContent="center"
                        >
                            {icon && (
                                <Icon icon={icon} color={isActive ? 'grey9' : 'grey7'} size="ml" />
                            )}
                            {text && (
                                <Text style="semibold" color={isActive ? 'grey9' : 'grey7'}>
                                    {text}
                                </Text>
                            )}
                            {children}
                            {hasDragHandle && (
                                <DragHandle
                                    compact
                                    disabled={showDragHandleDisabled}
                                    color={ColorsEnum.grey4}
                                    disabledColor={ColorsEnum.transparent}
                                />
                            )}
                        </Box>
                    </Border>
                </Box>
            </Border>
        </Clickable>
    </Linkable>
);

Tab.displayName = 'Tab';
