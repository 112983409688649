import * as React from 'react';
import { isReactElement } from 'app/components/services';

/**
 * Recursively traverse the provided ReactNodes and return
 * all children that are strings.
 */
export const getNodeLabels = (children: React.ReactNode): string[] =>
    React.Children.toArray(children).reduce(
        (labels: string[], child) => [...labels, ...getChildLabels(child)],
        [],
    );

/**
 * Return a given ReactChild's child strings. Will return label props.
 */
export const getChildLabels = (child: React.ReactNode): string[] => {
    if (isReactElement(child)) {
        return child.props.label ? [child.props.label] : getNodeLabels(child.props.children);
    } else if (typeof child === 'string' || typeof child === 'number') {
        return [child.toString()];
    } else {
        return [];
    }
};
