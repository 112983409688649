import { createSelector } from 'reselect';
import { getIsSDCardCompanionSystem, isPureCompanionSystem } from './getRecordingSolution';
import { getRecordingSolutionRequirements } from './getRecordingSolutionRequirements';
import { getRecordingSolutionTotalSpecs } from './getRecordingSolutionSpecs';
import { getPoeRequirements } from './getPoeRequirements';
import { getSystemUnderCapacity, getValidationError } from './validationResult';

export interface IRequirementDimensions {
    storage: boolean;
    channels: boolean;
    licenses: boolean;
    bandwidth: boolean;
    power: boolean;
    ports: boolean;
}

/*
 * Get the dimensions that are applicable for the current validation result
 */
export const getDimensionsApplicable = createSelector(
    [
        getValidationError,
        getRecordingSolutionRequirements,
        getRecordingSolutionTotalSpecs,
        getIsSDCardCompanionSystem,
        isPureCompanionSystem,
    ],
    (
        validationError,
        requirements,
        totalSpecs,
        isCompanionSDCardSolution,
        pureCompanionSystem,
    ): IRequirementDimensions => {
        switch (validationError?.validationErrorType) {
            case 'CompanionIncompatible':
            case 'CompanionTooMany':
                return {
                    storage: false,
                    channels: false,
                    licenses: false,
                    bandwidth: false,
                    power: false,
                    ports: false,
                };
            case 'CompanionSDCardMissing':
                return {
                    storage: true,
                    channels: false,
                    licenses: false,
                    bandwidth: false,
                    power: true,
                    ports: true,
                };
            default:
                return {
                    storage: requirements.storage > 0,
                    channels:
                        !isCompanionSDCardSolution &&
                        (requirements.nrOfChannels > 0 || totalSpecs.nrOfChannels > 0),
                    licenses:
                        !pureCompanionSystem &&
                        (requirements.nrOfLicenses > 0 || totalSpecs.nrOfLicenses > 0),
                    bandwidth:
                        !isCompanionSDCardSolution &&
                        (requirements.bandwidth > 0 || totalSpecs.bandwidth > 0),
                    power: requirements.power > 0 || totalSpecs.power > 0,
                    ports: requirements.ports.totalPorts > 0 || totalSpecs.ports.totalPorts > 0,
                };
        }
    },
);

/*
 * Get the dimensions Warnings
 */
export const getDimensionsWarnings = createSelector(
    [getSystemUnderCapacity, getPoeRequirements],
    (underCapacity, poeRequirements): IRequirementDimensions => {
        const hasPortUnderCapacity =
            Math.min(
                underCapacity.ports.poeClass1Ports,
                underCapacity.ports.poeClass2Ports,
                underCapacity.ports.poeClass3Ports,
                underCapacity.ports.poeClass4Ports,
                underCapacity.ports.poeClass5Ports,
                underCapacity.ports.poeClass6Ports,
                underCapacity.ports.poeClass7Ports,
                underCapacity.ports.poeClass8Ports,
                underCapacity.ports.highPoEPorts,
            ) < 0;

        const hasPoeUnderCapacity = poeRequirements.some((req) => !req.requirementFulfilled);

        return {
            storage: underCapacity.storage < 0,
            channels: underCapacity.nrOfChannels < 0,
            licenses: underCapacity.nrOfLicenses < 0,
            bandwidth: underCapacity.bandwidth < 0,
            power: underCapacity.power < 0,
            ports: hasPortUnderCapacity || hasPoeUnderCapacity,
        };
    },
);
