import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/css';
import { useService } from 'app/ioc';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { Box, MenuButton, SidebarMenu, Text } from 'app/components';
import { ProjectDbOrigin, setItemLocalStorageJSON } from 'app/core/persistence';
import { CommonActionService } from 'app/modules/common';
import { UserProjectsActionService } from '../services';
import { getUserProjectsLoaded } from '../selectors';

const textStyle = css`
    font-size: 10px;
    margin-left: 7px;
`;

const menuItemStyle = css`
    width: 100%;
`;

interface IUserProjectsLandingMenu {
    currentView: ProjectDbOrigin;
    isProjectsMenuExpanded: boolean;
    onProjectsMenuChange(): void;
    onViewChanged(): void;
}
export const UserProjectsLandingMenu: React.FunctionComponent<IUserProjectsLandingMenu> = ({
    currentView,
    isProjectsMenuExpanded,
    onProjectsMenuChange,
    onViewChanged,
}) => {
    const actionService = useService(UserProjectsActionService);
    const commonActionService = useService(CommonActionService);
    const [selectedOption, setSelectedOption] = React.useState<string>(currentView);
    const [isProjectsLoaded, setIsProjectsLoaded] = React.useState<boolean>(true);
    const projectsLoaded = useSelector<IStoreState, boolean>(getUserProjectsLoaded);

    React.useEffect(() => {
        setSelectedOption(currentView);
    }, [currentView]);

    React.useEffect(() => {
        setIsProjectsLoaded(projectsLoaded);
        commonActionService.unloadProject();
        commonActionService.unloadCurrentProject();
    }, [currentView, projectsLoaded, commonActionService]);

    const changeView = async (view: ProjectDbOrigin) => {
        setItemLocalStorageJSON('LatestProjectView', view);
        actionService.setCurrentView(view);
        onViewChanged();
    };

    const content = () => {
        return (
            <Box direction="column" width="100%" spacing="panel">
                <div
                    aria-selected={selectedOption === ProjectDbOrigin.asdUserData}
                    className={menuItemStyle}
                >
                    <Box paddingTop="panel" width="100%">
                        <MenuButton
                            icon="person"
                            text={t.myProjects}
                            selected={selectedOption === ProjectDbOrigin.asdUserData}
                            disabled={!isProjectsLoaded}
                            onClick={() => changeView(ProjectDbOrigin.asdUserData)}
                            iconOnly={!isProjectsMenuExpanded}
                        >
                            <div className={textStyle} aria-label="description">
                                <Text color="grey6" wordBreak="normal" testId="my_projects">
                                    {t.myProjectsDescription}
                                </Text>
                            </div>
                        </MenuButton>
                    </Box>
                </div>
                <div
                    aria-selected={selectedOption === ProjectDbOrigin.asdLocalUserData}
                    className={menuItemStyle}
                >
                    <MenuButton
                        testId={'local_projects_button'}
                        icon="cloud_disabled"
                        text={t.localProjects}
                        selected={selectedOption === ProjectDbOrigin.asdLocalUserData}
                        disabled={!isProjectsLoaded}
                        onClick={() => changeView(ProjectDbOrigin.asdLocalUserData)}
                        iconOnly={!isProjectsMenuExpanded}
                    >
                        <div className={textStyle} aria-label="description">
                            <Text color="grey6" wordBreak="normal" testId="local_projects">
                                {t.myLocalProjectsDescription}
                            </Text>
                        </div>
                    </MenuButton>
                </div>
            </Box>
        );
    };

    return (
        <>
            <SidebarMenu
                content={content()}
                isMenuExpanded={isProjectsMenuExpanded}
                onExpandedChange={onProjectsMenuChange}
            />
        </>
    );
};
