import { injectable } from 'inversify';
import type {
    IRecordingSettingsModel,
    IBaseProfileModel,
    IScheduleModel,
    Id,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import type { IProfileEstimateModel, IStorageEstimateModel } from '../../models';
import { StorageCalculationService } from '../storage';
import { BandwidthCalculatorService } from './BandwidthCalculator.service';
import { convert } from 'axis-webtools-util';
import type { ProductBandwidthProperties } from 'app/core/pia';
import type { IScenarioModel } from '../../../profile';
import { ProfileSupportService } from '../../../profile/services';
import type { Frequency } from '../../../models';

import { getDynamicFpsMode, getZipStrengthValue } from '../../../../common/utils';

@injectable()
export class ProfileBandwidthStorageEstimateService {
    constructor(
        private bandwidthCalculatorService: BandwidthCalculatorService,
        private storageCalculationService: StorageCalculationService,
    ) {}

    public getProfileEstimate(
        projectZipSetting: ProjectZipType,
        scenario: IScenarioModel,
        profile: IBaseProfileModel,
        productProperties: ProductBandwidthProperties,
        frequency: Frequency,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
        customBandwidth?: number,
        isGenericCamera?: boolean,
    ): IProfileEstimateModel {
        const recordingAverage = this.getAverageRecordingBandwidthAndStorage(
            projectZipSetting,
            scenario,
            profile,
            productProperties,
            frequency,
            getSchedule,
            projectBandwidthVersion,
            customBandwidth,
            isGenericCamera,
        );

        const liveViewAverageBandwidth = this.getAverageLiveViewBandwidth(
            projectZipSetting,
            scenario,
            profile,
            productProperties,
            frequency,
            getSchedule,
            projectBandwidthVersion,
            customBandwidth,
            isGenericCamera,
        );

        return {
            liveViewBandwidth: liveViewAverageBandwidth,
            recordingBandwidth: recordingAverage.averageBandwidth,
            storageInMB: recordingAverage.storageInMB,
            continuousRecording: recordingAverage.continuousStorage,
            triggeredRecording: recordingAverage.triggeredStorage,
        };
    }

    private getAverageRecordingBandwidthAndStorage(
        projectZipSetting: ProjectZipType,
        scenario: IScenarioModel,
        profile: IBaseProfileModel,
        productProperties: ProductBandwidthProperties,
        frequency: Frequency,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
        customBandwidth?: number,
        isGenericCamera?: boolean,
    ) {
        let triggeredBwQuota = 0;
        let continuousBwQuota = 0;
        if (customBandwidth) {
            // if customBandwidth the value of the bandwidth is specified as a sum for both continuous and triggered recording
            // then we have to find out how the customBandwidth should be divided between triggered and continuous
            const triggeredStorage = this.getTriggeredStorageEstimate(
                projectZipSetting,
                scenario,
                profile,
                productProperties,
                frequency,
                getSchedule,
                projectBandwidthVersion,
                undefined,
                isGenericCamera,
            );
            const continuousStorage = this.getContinuousStorageEstimate(
                projectZipSetting,
                scenario,
                profile,
                productProperties,
                frequency,
                profile.continuousRecording,
                profile.audio.recordingEnabled,
                getSchedule,
                projectBandwidthVersion,
                undefined,
                isGenericCamera,
            );
            const totalAverageBwRecordingEstimate =
                (triggeredStorage?.averageBandwidth ?? 0) +
                (continuousStorage?.averageBandwidth ?? 0);
            triggeredBwQuota = triggeredStorage
                ? triggeredStorage.averageBandwidth / totalAverageBwRecordingEstimate
                : 0;
            continuousBwQuota = continuousStorage
                ? continuousStorage.averageBandwidth / totalAverageBwRecordingEstimate
                : 0;
        }

        const triggeredStorage = this.getTriggeredStorageEstimate(
            projectZipSetting,
            scenario,
            profile,
            productProperties,
            frequency,
            getSchedule,
            projectBandwidthVersion,
            customBandwidth ? customBandwidth * triggeredBwQuota : customBandwidth,
            isGenericCamera,
        );

        const continuousStorage = this.getContinuousStorageEstimate(
            projectZipSetting,
            scenario,
            profile,
            productProperties,
            frequency,
            profile.continuousRecording,
            profile.audio.recordingEnabled,
            getSchedule,
            projectBandwidthVersion,
            customBandwidth ? customBandwidth * continuousBwQuota : customBandwidth,
            isGenericCamera,
        );

        const averageBandwidth = customBandwidth
            ? customBandwidth
            : this.getAverageTriggeredAndContinuousBandwidth(triggeredStorage, continuousStorage);

        const storageInMB = convert.toMega(
            this.getTriggeredAndContinuousStorage(triggeredStorage, continuousStorage),
        );

        return {
            triggeredStorage,
            continuousStorage,
            averageBandwidth,
            storageInMB,
        };
    }

    private getAverageLiveViewBandwidth(
        projectZipSetting: ProjectZipType,
        scenario: IScenarioModel,
        profile: IBaseProfileModel,
        productProperties: ProductBandwidthProperties,
        frequency: Frequency,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
        customBandwidth?: number,
        isGenericCamera?: boolean,
    ): number {
        const liveViewStorage = this.getContinuousStorageEstimate(
            projectZipSetting,
            scenario,
            profile,
            productProperties,
            frequency,
            profile.liveView,
            profile.audio.liveViewEnabled,
            getSchedule,
            projectBandwidthVersion,
            customBandwidth,
            isGenericCamera,
        );

        return liveViewStorage ? liveViewStorage.averageBandwidth : 0;
    }

    private getTriggeredStorageEstimate(
        projectZipSetting: ProjectZipType,
        scenario: IScenarioModel,
        profile: IBaseProfileModel,
        properties: ProductBandwidthProperties,
        frequency: Frequency,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
        customBandwidth?: number,
        isGenericCamera?: boolean,
    ): IStorageEstimateModel | undefined {
        if (profile.triggeredRecording.schedule === null) {
            return undefined;
        }

        const triggeredRecordingEstimate = this.bandwidthCalculatorService.getBandwidthEstimate(
            projectZipSetting,
            scenario,
            profile.scenario,
            profile.triggeredRecording,
            profile.zipstream,
            profile.audio.recordingEnabled,
            properties,
            frequency,
            'triggered',
            projectBandwidthVersion,
            isGenericCamera,
        );

        const schedule = getSchedule(profile.triggeredRecording.schedule);

        if (!schedule) {
            return undefined;
        }

        return this.storageCalculationService.getTriggeredStorageEstimate(
            profile.scenario,
            profile.triggeredRecording,
            profile.storage,
            triggeredRecordingEstimate,
            schedule,
            customBandwidth,
        );
    }

    private getContinuousStorageEstimate(
        projectZipSetting: ProjectZipType,
        scenario: IScenarioModel,
        profile: IBaseProfileModel,
        properties: ProductBandwidthProperties,
        frequency: Frequency,
        recordingSettings: IRecordingSettingsModel,
        audioEnabled: boolean,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
        customBandwidth?: number,
        isGenericCamera?: boolean,
    ): IStorageEstimateModel | undefined {
        if (recordingSettings.schedule === null) {
            return undefined;
        }

        const continuousRecordingEstimate = this.bandwidthCalculatorService.getBandwidthEstimate(
            projectZipSetting,
            scenario,
            profile.scenario,
            recordingSettings,
            profile.zipstream,
            audioEnabled,
            properties,
            frequency,
            'continuous',
            projectBandwidthVersion,
            isGenericCamera,
        );

        const schedule = getSchedule(recordingSettings.schedule!);

        if (!schedule) {
            return undefined;
        }

        const zipStrength = getZipStrengthValue(profile.zipstream, projectZipSetting);
        const fpsMode = getDynamicFpsMode(profile.zipstream, projectZipSetting);

        const usingDynamicFps =
            ProfileSupportService.getFpsMode(properties, zipStrength, fpsMode) === 'dynamic';

        return this.storageCalculationService.getContinuousStorageEstimate(
            profile.scenario,
            recordingSettings,
            profile.storage,
            continuousRecordingEstimate,
            schedule,
            usingDynamicFps,
            customBandwidth,
        );
    }

    private getAverageTriggeredAndContinuousBandwidth(
        triggeredStorageEstimate?: IStorageEstimateModel,
        continuousStorageEstimate?: IStorageEstimateModel,
    ): number {
        if (triggeredStorageEstimate && continuousStorageEstimate) {
            return (
                triggeredStorageEstimate.averageBandwidth +
                continuousStorageEstimate.averageBandwidth
            );
        } else if (triggeredStorageEstimate) {
            return triggeredStorageEstimate.averageBandwidth;
        } else if (continuousStorageEstimate) {
            return continuousStorageEstimate.averageBandwidth;
        } else {
            return 0;
        }
    }

    private getTriggeredAndContinuousStorage(
        triggeredStorageEstimate?: IStorageEstimateModel,
        continuousStorageEstimate?: IStorageEstimateModel,
    ): number {
        if (triggeredStorageEstimate && continuousStorageEstimate) {
            return (
                triggeredStorageEstimate.storageEstimate + continuousStorageEstimate.storageEstimate
            );
        } else if (triggeredStorageEstimate) {
            return triggeredStorageEstimate.storageEstimate;
        } else if (continuousStorageEstimate) {
            return continuousStorageEstimate.storageEstimate;
        } else {
            return 0;
        }
    }
}
