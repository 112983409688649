import * as React from 'react';
import type { Colors, Direction } from 'app/styles';
import { colorToRgba, ColorsEnum } from 'app/styles';
import { css, cx } from '@emotion/css';
import { clamp, debounce } from 'lodash-es';
import { Label } from '../../text/label/Label.component';
import { Text } from '../../text/text/Text.component';
import { NumberInput } from '../numberInput/NumberInput.component';
import type { IAutoTestable } from '../../ui-test';
import { nbsp } from '../../ui/utils';
import { MultiRangeLabels } from './MultiRangeLabels.component';
import { isPageRTL } from 'app/translate';

interface IDualRangeProps extends IAutoTestable {
    /** The highest possible value */
    rangeMax: number;
    /** The lowest possible value, defaults to 0 if not specified */
    rangeMin?: number;
    /** The user selected highest allowed value */
    maxValue: number;
    /** The user selected lowest allowed value */
    minValue: number;
    /** Text to show above the selected max value */
    maxLabel?: string;
    /** Text to show above the selected min value */
    minLabel?: string;
    /** The color to use on the thumbs and selected range */
    color?: Colors;
    /** Show a label above the slider */
    label?: string;
    /** Show the current value in number inputs next to the slider */
    showValue?: boolean;
    /** Show the current value in the label above the slider */
    showValueInLabel?: boolean;
    /** What unit to display in the label */
    unit?: string;
    /** Remove value trailing space in label (ex before degrees) */
    hideValueTrailingSpace?: boolean;
    /** Disables user interaction, but does not change to look */
    readonly?: boolean;
    /** Disables user interaction and lowers the opacity */
    disabled?: boolean;
    /** Defining the direction of the slider and number input fields */
    direction?: Direction;
    /** Determines when a change should be triggered */
    changeCriteria?: 'debounced';
    /** Step value when alt/option is pressed, defaults to 100 */
    altStepValue?: number;
    /** Step value when shift is pressed, defaults to 10 */
    shiftStepValue?: number;
    /** True if this component has focus */
    hasFocus?: boolean;
    /** Called when a value has changed */
    onChange?(minValue: number, maxValue: number): void;
    /** Called when a user has changed a label */
    onLabelsChange?(minValue: string, maxValue: string): void;
    /** Called when a thumb received focus */
    onGotFocus?(): void;
}

type Thumb = 'one' | 'two' | '';
const thumbDiameter = 18;
const sliderHeight = 30;

const sliderRoot = css`
    display: block;
    width: 100%;
    position: relative;
    box-sizing: border-box;
`;

const sliderContainerStyle = css`
    display: flex;
    flex-grow: 1;
`;

const trackStyle = css`
    flex: 1;
    height: 4px;
    background-color: ${ColorsEnum.grey3};
    margin: 0 -${thumbDiameter / 2}px;
`;

const valueInputContainerStyle = css`
    display: flex;
    align-items: center;
    margin-left: 2px;
    justify-content: space-between;
`;

export const DualRange: React.FC<IDualRangeProps> = ({
    rangeMax,
    rangeMin = 0,
    maxValue,
    minValue,
    minLabel,
    maxLabel,
    label,
    showValue,
    showValueInLabel,
    hideValueTrailingSpace,
    unit,
    color = 'blue',
    readonly,
    disabled,
    direction,
    changeCriteria,
    altStepValue = 100,
    shiftStepValue = 10,
    hasFocus = true,
    onChange,
    onLabelsChange,
    onGotFocus,
}) => {
    const sliderRef = React.useRef<HTMLDivElement>(null);
    const [initialPositionX, setInitialPositionX] = React.useState(0);
    const [internalMinValue, setInternalMinValue] = React.useState(minValue);
    const [internalMaxValue, setInternalMaxValue] = React.useState(maxValue);
    const internalMinValueRef = React.useRef(internalMinValue);
    const internalMaxValueRef = React.useRef(internalMaxValue);
    internalMinValueRef.current = internalMinValue;
    internalMaxValueRef.current = internalMaxValue;
    const [positionX, setPositionX] = React.useState(0);
    const [activeThumb, setActiveThumb] = React.useState<Thumb>('');
    const [thumbOneStyle, setThumbOneStyle] = React.useState<string>();
    const [thumbTwoStyle, setThumbTwoStyle] = React.useState<string>();
    const [trackFillStyle, setTrackFillStyle] = React.useState<string>();
    const [initialDragValue, setInitialDragValue] = React.useState<number>(0);
    const trailingSpace = React.useMemo(
        () => (hideValueTrailingSpace ? '' : ' '),
        [hideValueTrailingSpace],
    );

    const sliderStyle = css`
        display: flex;
        flex: 1;
        align-items: center;
        position: relative;
        height: ${sliderHeight}px;
        margin: 0 ${thumbDiameter / 2}px;
    `;

    const thumbStyle = css`
        cursor: ${readonly || disabled ? 'default' : 'pointer'};
        position: absolute;
        height: ${thumbDiameter}px;
        width: ${thumbDiameter}px;
        border: 2px solid ${ColorsEnum.white};
        border-radius: 50%;
        box-sizing: border-box;
        z-index: 1;
        top: ${(sliderHeight - thumbDiameter) / 2}px;
        transition: box-shadow 200ms ease-in-out;
        &::before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            border-top: 4px solid ${minLabel && maxLabel ? ColorsEnum.grey3 : 'transparent'};
            border-right: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 4px solid transparent;
            top: -8px;
            left: 3px;
        }
        &[data-is-active='true'] {
            box-shadow: 0 0 0 3px ${colorToRgba(ColorsEnum[color], 0.3)};
        }
    `;

    const sliderAndInputStyle = css`
        display: flex;
        flex-direction: ${direction};
        outline: none;
    `;

    const onMouseMove = React.useCallback((e: MouseEvent) => {
        setPositionX(e.clientX);
    }, []);

    const onMouseDown = React.useCallback(
        (e: React.MouseEvent) => {
            if (readonly || disabled) {
                return;
            }
            setInitialPositionX(e.clientX);
            setPositionX(e.clientX);
            document.addEventListener('mousemove', onMouseMove, false);
        },
        [disabled, onMouseMove, readonly],
    );

    const onTouchMove = React.useCallback((e: TouchEvent) => {
        setPositionX(e.touches[0].clientX);
    }, []);

    const onDragStop = React.useCallback(() => {
        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('touchmove', onTouchMove, false);
    }, [onMouseMove, onTouchMove]);

    const onTouchStart = React.useCallback(
        (e: React.TouchEvent) => {
            if (readonly || disabled) {
                return;
            }
            setInitialPositionX(e.touches[0].clientX);
            setPositionX(e.touches[0].clientX);
            document.addEventListener('touchmove', onTouchMove, false);
        },
        [disabled, onTouchMove, readonly],
    );

    const onThumbOneMouseDown = React.useCallback(
        (e: React.MouseEvent) => {
            setActiveThumb('one');
            setInitialDragValue(internalMinValue);
            onMouseDown(e);
            onGotFocus && onGotFocus();
        },
        [internalMinValue, onMouseDown, onGotFocus],
    );

    const onThumbTwoMouseDown = React.useCallback(
        (e: React.MouseEvent) => {
            setActiveThumb('two');
            setInitialDragValue(internalMaxValue);
            onMouseDown(e);
            onGotFocus && onGotFocus();
        },
        [internalMaxValue, onMouseDown, onGotFocus],
    );

    const onThumbOneTouchStart = React.useCallback(
        (e: React.TouchEvent) => {
            setActiveThumb('one');
            setInitialDragValue(internalMinValue);
            onTouchStart(e);
            onGotFocus && onGotFocus();
        },
        [internalMinValue, onGotFocus, onTouchStart],
    );

    const onThumbTwoTouchStart = React.useCallback(
        (e: React.TouchEvent) => {
            setActiveThumb('two');
            setInitialDragValue(internalMaxValue);
            onTouchStart(e);
            onGotFocus && onGotFocus();
        },
        [internalMaxValue, onGotFocus, onTouchStart],
    );

    const triggerDebounce = React.useMemo(
        () => debounce((min: number, max: number) => onChange && onChange(min, max), 100),
        [onChange],
    );

    const internalOnChange = React.useCallback(
        (min: number, max: number) => {
            setInternalMinValue(min);
            setInternalMaxValue(max);
            if (!onChange) {
                return;
            }
            changeCriteria === 'debounced' ? triggerDebounce(min, max) : onChange(min, max);
        },
        [changeCriteria, onChange, triggerDebounce],
    );
    const internalOnChangeRef = React.useRef(internalOnChange);
    internalOnChangeRef.current = internalOnChange;

    const updateValue = React.useCallback(
        (suggestedNewValue: number) => {
            if (activeThumb === 'one') {
                const newValue = clamp(
                    suggestedNewValue,
                    rangeMin,
                    internalMaxValueRef.current - 1,
                );
                internalOnChangeRef.current(newValue, internalMaxValueRef.current);
            } else if (activeThumb === 'two') {
                const newValue = clamp(
                    suggestedNewValue,
                    internalMinValueRef.current + 1,
                    rangeMax,
                );
                internalOnChangeRef.current(internalMinValueRef.current, newValue);
            }
        },
        [activeThumb, rangeMax, rangeMin],
    );

    const onKeyDown = React.useCallback(
        (e: KeyboardEvent) => {
            if (!internalOnChangeRef) return;

            const stepValue = e.shiftKey ? shiftStepValue : e.altKey ? altStepValue : 1;
            const currentValue =
                activeThumb === 'one' ? internalMinValueRef.current : internalMaxValueRef.current;
            if (e.key === 'ArrowUp' || e.key === 'ArrowRight') {
                updateValue(currentValue + stepValue);
            }
            if (e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
                updateValue(currentValue - stepValue);
            }
        },
        [activeThumb, altStepValue, shiftStepValue, updateValue],
    );

    React.useEffect(() => {
        if (hasFocus) {
            // To prevent multiple key event listeners we need to first unsubscribe
            document.removeEventListener('keydown', onKeyDown, false);
            document.addEventListener('keydown', onKeyDown, false);
        } else {
            setActiveThumb('');
            document.removeEventListener('keydown', onKeyDown, false);
        }

        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [hasFocus, onKeyDown]);

    React.useEffect(() => {
        document.addEventListener('mouseup', onDragStop, false);
        document.addEventListener('touchend', onDragStop, false);
        return () => {
            document.removeEventListener('mousemove', onMouseMove, false);
            document.removeEventListener('touchmove', onTouchMove, false);
            document.removeEventListener('mouseup', onDragStop, false);
        };
    }, [onDragStop, onMouseMove, onTouchMove]);

    React.useEffect(() => setInternalMinValue(minValue), [minValue]);
    React.useEffect(() => setInternalMaxValue(maxValue), [maxValue]);

    React.useEffect(() => {
        const sliderWidth = sliderRef.current?.clientWidth;
        if (sliderWidth === undefined) {
            return;
        }
        //RTL support
        const deltaPositionValue = isPageRTL()
            ? Math.round(
                  ((1 - (positionX - initialPositionX)) / sliderWidth) * (rangeMax - rangeMin),
              )
            : Math.round(((positionX - initialPositionX) / sliderWidth) * (rangeMax - rangeMin));
        updateValue(initialDragValue + deltaPositionValue);
    }, [positionX, initialPositionX, rangeMax, rangeMin, updateValue, initialDragValue]);

    React.useEffect(() => {
        const leftPosition = ((internalMinValue - rangeMin) / (rangeMax - rangeMin)) * 100;
        setThumbOneStyle(css`
            background-color: ${ColorsEnum[color]};
            inset-inline-start: calc(${leftPosition}% - ${thumbDiameter / 2}px);
        `);
    }, [color, internalMinValue, rangeMax, rangeMin]);

    React.useEffect(() => {
        const leftPosition = ((internalMaxValue - rangeMin) / (rangeMax - rangeMin)) * 100;
        setThumbTwoStyle(css`
            background-color: ${ColorsEnum[color]};
            inset-inline-start: calc(${leftPosition}% - ${thumbDiameter / 2}px);
        `);
    }, [color, internalMaxValue, rangeMax, rangeMin]);

    const TrackFillStyle = css`
        position: absolute;
        height: 4px;
        background-color: ${ColorsEnum[color]};
        inset-inline-start: calc(
            ${(((internalMinValue - rangeMin) / (rangeMax - rangeMin)) * 1000) / 10}%
        );
        inset-inline-end: calc(
            100% - ${(((internalMaxValue - rangeMin) / (rangeMax - rangeMin)) * 1000) / 10}%
        );
    `;
    React.useEffect(() => {
        setTrackFillStyle(TrackFillStyle);
    }, [TrackFillStyle, color, internalMaxValue, internalMinValue, rangeMax, rangeMin]);

    return (
        <div className={sliderRoot} aria-disabled={disabled} aria-readonly={readonly}>
            {label && (
                <Label opaque>
                    {label}
                    {showValueInLabel && (
                        <>
                            :
                            <Text
                                inline
                                color="grey6"
                            >{` ${internalMinValue}${nbsp}-${nbsp}${internalMaxValue}${trailingSpace}${unit}`}</Text>
                        </>
                    )}
                </Label>
            )}
            <MultiRangeLabels
                fullRange={{ min: rangeMin, max: rangeMax }}
                inputRange={{ min: minValue, max: maxValue, minLabel, maxLabel }}
                onLabelsChange={onLabelsChange}
            />
            <div className={sliderAndInputStyle} tabIndex={-1} onBlur={() => setActiveThumb('')}>
                <div className={sliderContainerStyle}>
                    <div ref={sliderRef} className={sliderStyle}>
                        <div className={trackStyle} />
                        <div className={trackFillStyle} />
                        <div
                            className={cx([thumbStyle, thumbOneStyle])}
                            onMouseDown={onThumbOneMouseDown}
                            onTouchStart={onThumbOneTouchStart}
                            data-is-active={activeThumb === 'one' && hasFocus}
                        />
                        <div
                            className={cx([thumbStyle, thumbTwoStyle])}
                            onMouseDown={onThumbTwoMouseDown}
                            onTouchStart={onThumbTwoTouchStart}
                            data-is-active={activeThumb === 'two' && hasFocus}
                        />
                    </div>
                </div>
                {showValue && (
                    <div className={valueInputContainerStyle}>
                        <NumberInput
                            min={rangeMin}
                            max={internalMaxValue - 1}
                            value={internalMinValue}
                            disabled={disabled || readonly}
                            onChange={(value) =>
                                value !== undefined && internalOnChange(value, internalMaxValue)
                            }
                        />
                        <Text color="grey6">{`${nbsp}-${nbsp}`}</Text>
                        <NumberInput
                            min={internalMinValue + 1}
                            max={rangeMax}
                            value={internalMaxValue}
                            disabled={disabled || readonly}
                            onChange={(value) => value && internalOnChange(internalMinValue, value)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

DualRange.displayName = 'DualRange';
