import { useService } from 'app/ioc';
import { getSelectedCenterChoice, getSelectedAxisAcsType } from 'app/modules/common';
import { getNbrCoreLicensesNeeded, RecordingSelectorService } from 'app/modules/recordingSelector';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Hook used for updating Center licenses in recording solution.
 */
export const useCoreLicenseUpdate = () => {
    const selectedCenterChoice = useSelector(getSelectedCenterChoice);
    const selectedAcsType = useSelector(getSelectedAxisAcsType);
    const nbrLicenses = useSelector(getNbrCoreLicensesNeeded);
    const recordingSelectorService = useService(RecordingSelectorService);

    useEffect(() => {
        if (
            selectedAcsType === 'CameraStationPro' &&
            selectedCenterChoice !== 'NoCenter' &&
            nbrLicenses > 0
        ) {
            recordingSelectorService.addOrUpdateCenterLicense(nbrLicenses, selectedCenterChoice);
        }
    }, [nbrLicenses, recordingSelectorService, selectedAcsType, selectedCenterChoice]);
};
