import type { IEntity, IInstallationPointEntity } from 'app/core/persistence';

/**
 * Remove the floor plan link from an installation point
 * @param installationPoint the installation point to remove the floor plan link from
 * @returns the installation point without the floor plan link
 */
export const removeFloorPlanLinkFromInstallationPoint = (
    installationPoint: IInstallationPointEntity,
): IInstallationPointEntity => {
    return {
        ...installationPoint,
        floorPlanId: undefined,
    };
};

/**
 * Change the path of an entity to make it global to the project by placing it
 * directly under the project root
 * @param entity the entity to globalize
 * @returns the globalized entity
 */
export const globalizeEntity = <T extends IEntity>(entity: T): T => {
    const path = isGlobalEntity(entity) ? entity.path : [entity.path[0], ...entity.path.slice(-1)];

    return {
        ...entity,
        path,
    };
};

const isInstallationPointEntity = (entity: IEntity): entity is IInstallationPointEntity => {
    return entity.type === 'installationPoint';
};

/**
 * Determines if an entity is a global entity or a floor plan entity
 * @param entity the entity to check
 * @returns true if the entity is a global entity, false otherwise
 */
export const isGlobalEntity = <T extends IEntity>(entity: T) =>
    isInstallationPointEntity(entity) ? entity.floorPlanId === undefined : entity.path.length === 2;
