import { eventTracking } from 'app/core/tracking';
import { injectable } from 'inversify';
import { UserApiCommunicator } from '../../user';
import type { IPartnerConfigServerResponse } from '../models';
import type { AccessType } from '../models/AccessType';
const PUBLIC_URL_ENDPOINT = 'partner';
const ALLOWLIST_URL = '/allowlist';
@injectable()
export class PartnerConfigCommunicator {
    constructor(private userApiCommunicator: UserApiCommunicator) {}
    public async fetchPartnerConfig(
        accessType: AccessType,
        partnerId: string,
    ): Promise<IPartnerConfigServerResponse | null> {
        try {
            const response =
                accessType === 'LocId'
                    ? await this.getLocIdResponse(partnerId)
                    : await this.getPartnerUrlResponse(partnerId);
            if (!response.ok) {
                // 401 & 404: Not found and unauthorized is common scenarios that we don't wish to log as an error.
                if (response.status === 404 || response.status === 401) {
                    return null;
                }
                throw new Error(
                    `Response from partner config API not ok, status: ${response.status}`,
                );
            } else {
                return response.json();
            }
        } catch (error) {
            const errorMessage =
                error instanceof Error
                    ? `${error.name} ${error.message}`
                    : 'Fetching partner config failed.';
            eventTracking.logError(errorMessage, 'Partner config service');
            return null;
        }
    }
    private async getPartnerUrlResponse(id: string): Promise<Response> {
        const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
        headers.append('Content-Type', 'application/json; charset=utf-8');
        return fetch(`${ALLOWLIST_URL}/${PUBLIC_URL_ENDPOINT}/${id}`, {
            method: 'GET',
            headers,
        });
    }
    private async getLocIdResponse(id: string): Promise<Response> {
        const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
        headers.append('Content-Type', 'application/json; charset=utf-8');
        return fetch(`${ALLOWLIST_URL}/${id}`, {
            method: 'GET',
            credentials: 'include',
            headers,
        });
    }
}
