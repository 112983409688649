import { reducerBuilder } from 'app/store';
import { SystemAccessoriesActions } from './SystemAccessories.actions';
import type { ISystemAccessoriesState } from './ISystemAccessoriesState';
import type { DisplayCategory } from '../components/DisplayCategory';

const menuResetState: ISystemAccessoriesState = {
    isCurrentSelectionSelected: false,
    isSearchResultSelected: false,
    isAllAccessoriesSelected: false,
    selectedCategory: undefined,
};

const initialState: ISystemAccessoriesState = {
    isCurrentSelectionSelected: true,
    isSearchResultSelected: false,
    isAllAccessoriesSelected: false,
    selectedCategory: undefined,
    searchText: undefined,
};

export const systemAccessoriesReducer = reducerBuilder<ISystemAccessoriesState>()
    .setInitialState(initialState)
    .onAction<boolean>(SystemAccessoriesActions.ShowCurrentSelection, (state) => {
        return {
            ...state,
            ...menuResetState,
            isCurrentSelectionSelected: true,
        };
    })
    .onAction<boolean>(SystemAccessoriesActions.ShowSearchResult, (state) => {
        return {
            ...state,
            ...menuResetState,
            isSearchResultSelected: true,
        };
    })
    .onAction<boolean>(SystemAccessoriesActions.ShowAllAccessories, (state) => {
        return {
            ...state,
            ...menuResetState,
            isAllAccessoriesSelected: true,
        };
    })
    .onAction<DisplayCategory>(SystemAccessoriesActions.ShowCategory, (state, action) => {
        return {
            ...state,
            ...menuResetState,
            selectedCategory: action.payload,
        };
    })
    .onAction<string | undefined>(SystemAccessoriesActions.SetSearchText, (state, action) => {
        const isCurrentSelectionSelected = action.payload ? false : true;
        const isSearchResultSelected = !isCurrentSelectionSelected;
        return {
            ...state,
            ...menuResetState,
            isCurrentSelectionSelected,
            isSearchResultSelected,
            searchText: action.payload === '' ? undefined : action.payload,
        };
    })
    .create();
