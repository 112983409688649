import type { IPersistableRangeType } from 'app/core/persistence';
import { getNumberOfAddressesPerQuantity, getNetworkRangeType } from 'app/core/persistence';
import { createSelector } from 'reselect';
import { getPiaItemsRecord } from '../../piaDevices/selectors/getPiaDevices';
import { getCurrentProjectItemsArray } from '../../project';
import { getDevicesOutsideRangeWithOverride } from './getDevicesOutsideRange';

/** Gets required range size for reserved ranges. Excludes override items outside range. */
export const getRequiredIpRangeByType = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord, getDevicesOutsideRangeWithOverride],
    (devices, piaItems, outsideRange): Record<IPersistableRangeType, number> =>
        devices.reduce(
            (counts, device) => {
                const piaItem = device.productId ? piaItems[device.productId] : null;
                const type = getNetworkRangeType(device.properties, piaItem);
                if (type === undefined) return counts;

                return {
                    ...counts,
                    [type]:
                        counts[type] + device.quantity * getNumberOfAddressesPerQuantity(piaItem),
                };
            },
            {
                cameras: 0 - outsideRange.cameras.length,
                other: 0 - outsideRange.other.length,
                recorders: 0 - outsideRange.recorders.length,
            },
        ),
);
