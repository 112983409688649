import * as React from 'react';

import {
    getRecommendations,
    getViewingAxisAcsType,
    getSelectedRecommendationId,
    isCurrentSolutionEmpty,
} from '../../selectors';

import { getSelectedAxisAcsType, getSelectedRecordingSolutionType } from 'app/modules/common';
import { useSelector } from 'react-redux';
import { selectRecommendation } from '../helpers';
import { nameComparator } from 'app/utils';
import { SolutionItemCard } from '../SolutionItemCard';
import { AxisPickYourOwnSolution } from './AxisPickYourOwnSolution';

export const AxisSolutions: React.FC = () => {
    const isSolutionEmpty = useSelector(isCurrentSolutionEmpty);
    const recommendations = useSelector(getRecommendations).sort(nameComparator);
    const selectedRecordingSolutionType = useSelector(getSelectedRecordingSolutionType);
    const selectedRecommendationId = useSelector(getSelectedRecommendationId);
    const viewingAcsType = useSelector(getViewingAxisAcsType);
    const selectedSolutionAcsType = useSelector(getSelectedAxisAcsType);

    return recommendations.length === 0 ? (
        <AxisPickYourOwnSolution />
    ) : (
        <>
            {recommendations.map((recommendation, index) => {
                const selected =
                    selectedRecordingSolutionType === recommendation.id &&
                    selectedRecommendationId === recommendation.id &&
                    viewingAcsType === selectedSolutionAcsType;

                return (
                    <SolutionItemCard
                        key={`${recommendation.id}_${index}`}
                        name={recommendation.name}
                        description={recommendation.description}
                        onSelect={() =>
                            selectRecommendation(
                                recommendation.id,
                                recommendation,
                                isSolutionEmpty,
                                selectedRecordingSolutionType,
                                viewingAcsType,
                            )
                        }
                        isSelected={selected}
                    >
                        {recommendation.items}
                    </SolutionItemCard>
                );
            })}
        </>
    );
};
AxisSolutions.displayName = 'AxisSolutions';
