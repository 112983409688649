import * as React from 'react';
import { Modal, Quantity, Button, Stack, PiaImage, Text, Box } from 'app/components';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { deviceIcons, getModelName, getCurrentProjectItem } from 'app/modules/common';
import { t } from 'app/translate';
import type { Id, DeviceType } from 'app/core/persistence';
import { DuplicationService, getDeviceType } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';
import { ProjectDevicesActionService } from '../../services';
import { eventTracking } from 'app/core/tracking';

interface IMultipleCopiesModalOwnProps {
    deviceId: Id;
}

interface IMultipleCopiesModalProps {
    deviceId: Id;
    deviceName?: string;
    deviceType?: DeviceType;
    productId?: PiaId | null;
    deviceModel: string;
}

interface IMultipleCopiesModalState {
    copyCount: number;
    adding: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IMultipleCopiesModalOwnProps,
): IMultipleCopiesModalProps => {
    const item = getCurrentProjectItem(storeState, ownProps.deviceId);

    return {
        deviceId: ownProps.deviceId,
        deviceModel: getModelName(storeState, ownProps.deviceId),
        deviceName: item?.name,
        deviceType: item ? getDeviceType(item) : undefined,
        productId: item?.productId,
    };
};

class MultipleCopiesModalContainer extends React.Component<
    IMultipleCopiesModalProps,
    IMultipleCopiesModalState
> {
    private actionService: ProjectDevicesActionService;
    private duplicationService: DuplicationService;

    constructor(props: IMultipleCopiesModalProps) {
        super(props);
        this.state = {
            copyCount: 2,
            adding: false,
        };

        this.duplicationService = ServiceLocator.get(DuplicationService);
        this.actionService = ServiceLocator.get(ProjectDevicesActionService);
    }

    public render() {
        return (
            <Modal title={t.multipleCopies} onClose={this.actionService.closeMultipleCopiesModal}>
                <Box paddingTop="panel">
                    <Stack>
                        <PiaImage
                            icon={deviceIcons.toIcon(this.props.deviceType)}
                            piaId={this.props.productId ?? null}
                            imageSize="lg"
                        />
                        <Box direction="column" maxWidth="200px">
                            <Text whiteSpace="nowrap">{this.props.deviceName}</Text>
                            <Text>{this.props.deviceModel}</Text>
                        </Box>
                        <Box flex="none">
                            <Quantity
                                changeOnKey
                                value={this.state.copyCount}
                                min={1}
                                max={99}
                                onChange={(copyCount) => this.setState({ copyCount })}
                            />
                        </Box>
                    </Stack>
                </Box>

                <Box paddingTop="panel" justifyContent="end">
                    <Stack spacing="quart">
                        <Button text onClick={() => this.actionService.closeMultipleCopiesModal()}>
                            {t.close}
                        </Button>
                        <Button primary disabled={this.state.adding} onClick={this.copyDevice}>
                            {t.createCopies}
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        );
    }

    private copyDevice = async () => {
        eventTracking.logUserEvent(
            'Project Devices',
            'Copy Device',
            this.props.deviceType,
            this.state.copyCount,
        );

        this.setState({ adding: true });
        await this.duplicationService.copyItemInCurrentProject(
            this.props.deviceId,
            this.state.copyCount,
        );
        this.actionService.closeMultipleCopiesModal();
    };
}

export const MultipleCopiesModal = connect(mapStateToProps)(MultipleCopiesModalContainer);
