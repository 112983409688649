import * as React from 'react';
import { css } from '@emotion/css';
import { t } from 'app/translate';
import type { IAutoTestable, IWithChildren } from 'app/components';
import { LoadingIndicator } from 'app/components';
import type { IPiaState, IMigrationState } from './models';
import { CommonActionService } from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import type { ICustomPouchError } from 'app/utils';
import { isCustomPouchError } from 'app/utils';

const LoadingIndicatorStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: bold;
`;

const LoadingIndicatorContentStyle = css`
    width: 100%;
`;

const ErrorStyle = css`
    width: 100%;
    padding: ${SpacingsEnum.base};
    text-align: center;
    font-weight: bold;
    color: ${ColorsEnum.red};
`;

export interface IAppLoaderComponentProps extends IAutoTestable, IWithChildren {
    piaState: IPiaState;
    migrationState: IMigrationState;
    msrpReady: boolean;
    userLoaded: boolean;
}

export class AppLoaderComponent extends React.Component<IAppLoaderComponentProps> {
    private commonActionService: CommonActionService;

    constructor(props: IAppLoaderComponentProps) {
        super(props);
        this.commonActionService = ServiceLocator.get(CommonActionService);
    }

    public render() {
        if (
            this.props.piaState.ready &&
            this.props.migrationState.finished &&
            this.props.msrpReady &&
            this.props.userLoaded
        ) {
            return this.renderContent();
        } else if (this.props.piaState.error) {
            return this.renderError(t.piaError, this.props.piaState.error);
        } else if (this.props.migrationState.error) {
            return this.renderError(t.applicationMigrationError, this.props.migrationState.error);
        } else if (this.props.piaState.loading) {
            return this.renderLoader(t.piaLoading, this.props.piaState.progress);
        } else if (this.props.migrationState.migrating) {
            return this.renderLoader(t.applicationMigrating, this.props.migrationState.progress);
        } else if (!this.props.msrpReady || !this.props.userLoaded) {
            return this.renderLoader(t.quotationMsrpLoading);
        }
        return null;
    }

    private renderLoader(message: string, progress?: number) {
        let text = message;
        if (progress !== undefined) {
            text += ` ${Math.round(progress * 100)}%`;
        }

        return (
            <div className={LoadingIndicatorStyle}>
                <LoadingIndicator testId="app_loader" message={text} />
            </div>
        );
    }

    private renderContent() {
        this.commonActionService.getUserCustomItems();
        return <div className={LoadingIndicatorContentStyle}>{this.props.children}</div>;
    }

    private renderError(message: string, error: Error | ICustomPouchError | null) {
        if (isCustomPouchError(error) && error.reason === 'QuotaExceededError') {
            return (
                <div data-test-id="app_error" className={ErrorStyle}>
                    {t.applicationQuotaExceededError}
                </div>
            );
        }

        // PiaClient might fail because indexedDB fails in some cases in Private Browsing Mode
        if (error && error.name === 'indexed_db_went_bad') {
            return (
                <div data-test-id="app_error" className={ErrorStyle}>
                    {t.applicationMigrationError}
                </div>
            );
        }

        return (
            <div data-test-id="app_error" className={ErrorStyle}>
                {message}
            </div>
        );
    }
}
