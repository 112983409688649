import { injectable } from 'inversify';
import type { CompactionStatus } from './CouchDBCompaction.communicator';
import { CouchDBCompactCommunicator } from './CouchDBCompaction.communicator';
import type { ICouchDBInfo } from '../models';
import { AuthenticationService, CleanupService, ReplicationService } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
@injectable()
export class CouchDBCompactionService {
    constructor(
        private authService: AuthenticationService,
        private communicator: CouchDBCompactCommunicator,
        private replicationService: ReplicationService,
        private cleanupService: CleanupService,
    ) {}

    /**
     * Called from ui to initiate compaction
     * - Pause replication
     * - Delete local pouch databases
     * - Start compaction procedure
     */
    public async initiateCompact(logKey: string, fileSize?: number): Promise<void> {
        const authenticated = await this.authService.isAuthenticated();
        if (!authenticated) return;

        this.replicationService.pauseReplication();
        // This will also destory the mrview table as well
        await this.cleanupService.destroyPouchDB();

        const result = await this.compact();
        if (!result) {
            throw new Error();
        }

        eventTracking.logApplicationEvent(
            'Optimization',
            'Filesize before compaction',
            logKey,
            fileSize,
        );
    }

    public compact(): Promise<boolean> {
        return this.communicator.compact();
    }

    public async getCompactStatus(): Promise<CompactionStatus | null> {
        const authenticated = await this.authService.isAuthenticated();
        if (!authenticated) return null;

        const result = await this.communicator.getCompactStatus();
        if (result === null) {
            eventTracking.logError(
                'Failed to get status of compaction procedure - no response',
                'Optimization',
            );
        }
        return result;
    }

    public async getCouchDBInfo(): Promise<ICouchDBInfo | null> {
        const authenticated = await this.authService.isAuthenticated();
        return authenticated ? this.communicator.getDBInfo() : null;
    }

    public async logCompactionResult(status: CompactionStatus, logKey: string): Promise<void> {
        const couchDBInfo = await this.getCouchDBInfo();
        switch (status) {
            case 'Success':
                eventTracking.logApplicationEvent(
                    'Optimization',
                    'Filesize after successful compaction',
                    logKey,
                    couchDBInfo?.fileSize,
                );
                break;
            case 'Fail':
                eventTracking.logApplicationEvent(
                    'Optimization',
                    'Compaction ended with failure',
                    logKey,
                );
                break;
            case 'SeriousError':
                eventTracking.logApplicationEvent(
                    'Optimization',
                    'Compaction ended with SeriousError',
                    logKey,
                );
                break;
            default:
                break;
        }
    }
}
