import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import { getUserWasSignedIn } from '../authentication';
import { getCurrentProjectCreationDate, getIsLocalProject } from '../project/selectors';
import moment from 'moment';
import { t } from 'app/translate';
import { toCacheKey } from '../cacheKey';
import { createCachedSelector } from 're-reselect';

const getSyncState = (state: IStoreState) => state.common.projectSync;

export const getLastSyncDateTime = createSelector(
    [getSyncState],
    (syncState) => syncState.lastSync,
);

export const getWasProjectEverSynced = createSelector(
    [getLastSyncDateTime, getCurrentProjectCreationDate],
    (lastSync, projectCreationDate) => lastSync >= projectCreationDate,
);

export const getSyncStatus = createSelector(
    [getSyncState, getUserWasSignedIn, getWasProjectEverSynced, getIsLocalProject],
    (syncState, wasSignedIn, wasProjectSynced, isLocalProject) => {
        if (isLocalProject) {
            return 'off';
        }
        if (!wasProjectSynced || !wasSignedIn) {
            return 'not_in_sync';
        }

        return syncState.status;
    },
);

const getNowFromProps = (_state: IStoreState, time: number) => time;

export const getLastSyncTimeAgo = createCachedSelector(
    [getWasProjectEverSynced, getLastSyncDateTime, getNowFromProps],
    (wasProjectSynced, lastSync, timeNow) => {
        const timeAgo = wasProjectSynced ? moment(lastSync).from(timeNow) : t.never;
        return timeAgo;
    },
)(toCacheKey);
