import * as React from 'react';
import { t } from 'app/translate';
import { Modal, Box, Stack, Button, Text, Spacer } from 'app/components';
import { useNavigate } from 'react-router-dom';
import { eventTracking } from 'app/core/tracking';

interface ICouchDBCompactionModalProps {
    setShowModal(show: boolean): void;
}

export const CouchDBCompactionModal: React.FC<ICouchDBCompactionModalProps> = ({
    setShowModal,
}) => {
    const navigate = useNavigate();
    const onOptimizePerformance = () => {
        navigate('optimize');
    };
    const onClose = () => {
        eventTracking.logApplicationEvent(
            'Optimization',
            'User chose not to perform compaction in last step, and closed modal.',
            'Cancelled',
        );
        setShowModal(false);
    };

    return (
        <Modal
            onClose={() => {
                setShowModal(false);
            }}
            color="grey1"
            maxWidth="600px"
        >
            <ul>
                <Stack vertical spacing="base">
                    <Text style="title" color="grey8">
                        {t.optimizePerformance}
                    </Text>
                    <Text color="grey8">{t.optimizePerformanceInfo}</Text>
                    <Spacer spacing="quart" />
                    <Text style="subheader" color="grey8">
                        {t.beforeYouBegin}
                    </Text>
                    <Stack vertical spacing="none">
                        <Text style="semibold" color="grey7">
                            <li>{t.exportImportantProjects}</li>
                        </Text>
                        <Text color="grey7">{t.backupProjects}</Text>
                    </Stack>
                    <Stack vertical spacing="none">
                        <Text style="semibold" color="grey7">
                            <li>{t.closeOtherTabs}</li>
                        </Text>
                        <Text color="grey7">{t.closeOtherTabsText}</Text>
                    </Stack>
                    <Spacer spacing="base" />
                    <Text color="grey8">{t.waitForOptimization}</Text>
                    <Box justifyContent="end" paddingTop="base">
                        <Stack>
                            <Button onClick={onOptimizePerformance}>
                                {t.optimizePerformanceNow}
                            </Button>
                            <Button primary onClick={onClose}>
                                {t.close}
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </ul>
        </Modal>
    );
};

CouchDBCompactionModal.displayName = 'CouchDBCompactionModal';
