import { getAllCameraCategories } from './getAllCameraCategories';

import type { IPiaRelationItem, PiaCategory } from '../client/types';
import {
    PiaItemEncoderCategory,
    PiaItemPacCategory,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemSpeakerCategory,
    PiaItemMainUnitCategory,
    PiaItemSensorUnitCategory,
    PiaItemRecorderCategory,
    PiaAccessoryCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from '../client/types';

const cameras = [...getAllCameraCategories()];
const encoders = [PiaItemEncoderCategory.ENCODER];
const fSeries = [
    PiaItemMainUnitCategory.MAINUNIT,
    PiaItemSensorUnitCategory.SENSORUNIT,
    PiaItemSensorUnitCategory.THERMALSENSOR,
];
const accessControls = [
    PiaItemPacCategory.DOORCONTROLLERS,
    PiaItemPacCategory.IORELAYS,
    PiaItemPacCategory.ACCESSSERVER,
    PiaItemPacCategory.NETWORKREADER,
];
const intercoms = [PiaItemPacCategory.ANSWERINGUNIT, PiaItemPacCategory.DOORSTATIONS];
const speakers = [PiaItemSpeakerCategory.SPEAKER];
const recording = [
    PiaItemRecorderCategory.RECORDERS2,
    PiaItemRecorderCategory.DESKTOPTERMINALS,
    PiaAccessoryCategory.NETWORKSWITCHES,
];
const other = [
    PiaItemDecoderCategory.DECODER,
    PiaItemDetectorCategory.RADARDETECTORS,
    PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES,
    PiaItemPagingConsoleCategory.PAGINGCONSOLE,
];

const deviceCategories = new Set([
    ...cameras,
    ...fSeries,
    ...encoders,
    ...speakers,
    ...intercoms,
    ...accessControls,
    ...recording,
    ...other,
]);

export const isDeviceRelation = (relation: IPiaRelationItem): boolean =>
    deviceCategories.has(relation.category as PiaCategory);
