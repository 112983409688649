const IP_OCTETS = 3;

export const convertFromIpRange = (ipStart: string, ipEnd: string): number => {
    const startSplit = ipStart.split('.').map((n) => Number(n));
    const endSplit = ipEnd.split('.').map((n) => Number(n));

    let numberOnRange = 0;

    for (let i = 0; i <= 3; i++) {
        numberOnRange +=
            endSplit[i] * Math.pow(256, IP_OCTETS - i) -
            startSplit[i] * Math.pow(256, IP_OCTETS - i);
    }

    // Return difference plus one. I.E 1.1.1.1 - 1.1.1.2 has difference of 1, but two slots since first in range can be assigned as well
    return numberOnRange + 1;
};
