import { css } from '@emotion/css';
import React from 'react';
import type { Colors } from 'app/styles';
import { ColorsEnum, getFontColor } from 'app/styles';
import { Border, Box, TextInput } from 'app/components';
import type {
    Id,
    IInstallationPoint,
    IInstallationPointEntity,
    IItemEntity,
    IPersistence,
} from 'app/core/persistence';
import { InstallationPointService, getParentId } from 'app/core/persistence';
import {
    getDeviceIpMarkerLabels,
    getParentDeviceForIpId,
    getSortedInstallationPointsForDevice,
} from 'app/modules/maps';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { InstallationPointDropDownMenu } from './InstallationPointDropDownMenu';
import { AppConstants } from 'app/AppConstants';
import { useService } from 'app/ioc';

export type TRows = React.ReactNode[];

interface IInstallationPointListItemProps {
    device: IPersistence<IItemEntity>;
    ip: IInstallationPointEntity;
    rowIndex: number;
}

const rowHeight = 36;

export const InstallationPointListItem: React.FC<IInstallationPointListItemProps> = ({
    device,
    ip,
    rowIndex,
}) => {
    const renderDeviceLabel = (
        color: Colors,
        index: string,
        isChild?: boolean,
        isLast?: boolean,
    ): React.ReactElement => {
        const leftBorderStyle = css`
            border-left: 2px solid ${ColorsEnum[color]};
            height: ${isLast ? '50%' : '100%'};
        `;
        const topBorderStyle = css`
            border-top: 2px solid ${ColorsEnum[color]};
            width: 16px;
        `;
        const labelFontStyle = css`
            color: ${isChild ? getFontColor(ColorsEnum[color]) : ColorsEnum['grey9']};
        `;
        return (
            <Box>
                {isChild && (
                    <Box __htmlAttributes={{ style: { paddingInlineStart: '13px' } }} height={36}>
                        <div className={leftBorderStyle}></div>
                        <Box __htmlAttributes={{ style: { paddingTop: '16px' } }}>
                            <div className={topBorderStyle}></div>
                        </Box>
                    </Box>
                )}
                <Box alignItems="center">
                    <Border radius="50%" width={3} color={color}>
                        <Box
                            alignItems="center"
                            justifyContent="center"
                            width={isChild ? 22 : 30}
                            height={isChild ? 22 : 30}
                            color={isChild ? color : 'transparent'}
                        >
                            <div className={labelFontStyle}>{index}</div>
                        </Box>
                    </Border>
                </Box>
            </Box>
        );
    };

    const installationPoints = useSelector<IStoreState, IInstallationPointEntity[]>((state) =>
        getSortedInstallationPointsForDevice(state, device._id),
    );

    const ipLabelsForDevice = useSelector<IStoreState, Record<Id, string>>((state) =>
        getDeviceIpMarkerLabels(state, device._id),
    );

    // The device corresponding to IInstallationPointEntity parentId if any
    // (e.g. main units, encoders that can have other installation points as children)
    const ipParentDevice = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        (state) => getParentDeviceForIpId(state, ip._id),
    );

    const installationPointService = useService(InstallationPointService);

    const placedParentDevices = React.useMemo(
        () =>
            installationPoints.filter(
                (installationPoint) => getParentId(installationPoint) === device._id,
            ).length,
        [device._id, installationPoints],
    );

    if (!ipParentDevice) return null;

    const nbrDevicesPerQuantity = installationPoints.length / placedParentDevices;
    /** Checks if it's the last device per quantity */
    const isLast: boolean = (rowIndex + 1) % nbrDevicesPerQuantity === 0;

    const index = ipLabelsForDevice[ip._id];

    // (a child is an installation point that belongs to a main unit, encoder or other that can have other installation points as children)
    const isChild = ip.parentId !== undefined;

    const onInstallationPointNameUpdate = (name: string) => {
        if (name.trim()) {
            const updatedInstallationPoint: IInstallationPoint = {
                ...ip,
                name,
            };

            installationPointService.updateInstallationPoint(ip._id, updatedInstallationPoint);
        }
    };

    return (
        <>
            <Box
                // No padding if it is a child (to get the connecting markers right) 2 should have padding, 2a should not.
                paddingTop={isChild ? 'none' : 'halfCell'}
                height={rowHeight}
                alignItems="center"
            >
                {renderDeviceLabel(device.color ?? 'devicePalette7', index, isChild, isLast)}
            </Box>
            <Box
                // No padding if it is a child (to get the connecting markers right) 2 should have padding, 2a should not.
                paddingTop={isChild ? 'none' : 'halfCell'}
                paddingLeft={isChild ? 'cell' : 'none'}
                height={rowHeight}
                alignItems="center"
            >
                <TextInput
                    testId={`placed_device_${ipParentDevice.name}_${index}`}
                    value={ip.name}
                    maxLength={AppConstants.deviceNameMaxLength}
                    onChange={onInstallationPointNameUpdate}
                    placeholder={
                        ipParentDevice?.name && isChild
                            ? ipParentDevice.name
                            : ipParentDevice?.name
                              ? ipParentDevice.quantity > 1
                                  ? `(${index}/${ipParentDevice.quantity}) ${ipParentDevice.name}`
                                  : ipParentDevice.name
                              : ''
                    }
                    changeCriteria="blur"
                    noBorder
                />
            </Box>
            <Box
                // No padding if it is a child (to get the connecting markers right) 2 should have padding, 2a should not.
                paddingTop={isChild ? 'none' : 'halfCell'}
                height={rowHeight}
                alignItems="center"
            >
                <InstallationPointDropDownMenu
                    installationPoint={ip}
                    device={ipParentDevice}
                    indexName={index}
                />
            </Box>
        </>
    );
};

InstallationPointListItem.displayName = 'InstallationPointListItem';
