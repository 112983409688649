import { injectable } from 'inversify';
import type { IExportedProjectSettingsFile } from '../models';
import { eventTracking } from 'app/core/tracking';
import { UserApiCommunicator } from 'app/core/persistence/services/user';

const PROJECT_SETTINGS_SHARE_URL = '/project-settings-share';

@injectable()
export class ProjectSettingsCommunicator {
    constructor(private userApiCommunicator: UserApiCommunicator) {}
    public async uploadProjectSettings(
        projectSettings: IExportedProjectSettingsFile,
    ): Promise<Response | null> {
        try {
            const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
            headers.append('Content-Type', 'application/json; charset=utf-8');
            const response = await fetch(PROJECT_SETTINGS_SHARE_URL, {
                credentials: 'include',
                method: 'POST',
                headers,
                body: JSON.stringify(projectSettings),
            });

            if (!response.ok) {
                eventTracking.logError(
                    response.statusText,
                    PROJECT_SETTINGS_SHARE_URL,
                    response.status,
                );
            }

            return response;
        } catch (e) {
            eventTracking.logError(
                'Failed to uploadProjectSettings',
                'ProjectSettingsShareCommunicator',
            );
            return null;
        }
    }

    public async revokeToken(token: string): Promise<Response | null> {
        const url = `${PROJECT_SETTINGS_SHARE_URL}?token=${token}`;

        try {
            const response = await fetch(url, {
                credentials: 'include',
                method: 'DELETE',
                headers: await this.userApiCommunicator.fetchUserTokenHeaders(),
            });

            if (!response.ok) {
                eventTracking.logError(response.statusText, url, response.status);
            }

            return response;
        } catch (e) {
            eventTracking.logError('Failed to revokeToken', 'ProjectSettingsShareCommunicator');
            return null;
        }
    }
}
