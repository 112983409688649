import { Box, Checkbox } from 'app/components';
import type { DeviceType } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    getSelectedDeviceTypeCoverageAreasLayer,
    getSelectedDeviceTypesLayer,
} from '../../selectors';
import { MapsActionService } from '../../services';
import { eventTracking } from 'app/core/tracking';

interface ILayerDeviceTypeFilterItem {
    selectionCriteria: DeviceType;
    label: string;
    devicesToInclude: DeviceType[];
    hasCoverageArea?: boolean;
}
export const LayerDeviceTypeFilterItem: React.FC<ILayerDeviceTypeFilterItem> = ({
    selectionCriteria,
    label,
    devicesToInclude,
    hasCoverageArea,
}) => {
    const [mapsActionService] = React.useState<MapsActionService>(
        ServiceLocator.get(MapsActionService),
    );
    const setSelectedDevices = () => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Device Type');
        mapsActionService.toggleDeviceTypeLayer(devicesToInclude);
    };
    const setSelectedCoverageAreas = () => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Coverage Area');
        mapsActionService.toggleDeviceTypeCoverageAreaLayer(devicesToInclude);
    };
    const selectedDevices = useSelector<IStoreState, DeviceType[]>(getSelectedDeviceTypesLayer);
    const selectedCoverageAreas = useSelector<IStoreState, DeviceType[]>(
        getSelectedDeviceTypeCoverageAreasLayer,
    );

    return (
        <Box justifyContent="between">
            <Checkbox
                slider
                selected={selectedDevices.includes(selectionCriteria)}
                testId={label}
                onChange={() => setSelectedDevices()}
            >
                {label}
            </Checkbox>
            {hasCoverageArea && (
                <Checkbox
                    testId={`${label}_${'coverage_area'}`}
                    selected={selectedCoverageAreas.includes(selectionCriteria)}
                    onChange={() => setSelectedCoverageAreas()}
                />
            )}
        </Box>
    );
};
