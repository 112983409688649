import * as React from 'react';
import { isNumber } from 'lodash-es';
import { css, cx } from '@emotion/css';
import type { IExtendableComponentWithChildren } from '../models';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { extendableProps, renderReactChildren } from '../services';
import type { Property } from 'csstype';

const noPrintStyle = css`
    @media print {
        border-color: transparent !important;
        box-shadow: none !important;
    }
`;

export interface IBorderProps extends IExtendableComponentWithChildren {
    /**
     * Color of the border
     */
    color?: Colors;
    /**
     * Specifies the color of each side
     */
    sideColor?: { left?: Colors; top?: Colors; right?: Colors; bottom?: Colors };
    /**
     * Color of the border when hovered
     */
    hoverColor?: Colors;
    /**
     * The style of the border.
     * Defaults to "solid"
     */
    style?: Property.BorderInlineStyle;
    /**
     * Width in px of the border
     */
    width?: number;
    /**
     * Width in px of the top border
     */
    topWidth?: number;
    /**
     * Width in px of the right border
     */
    rightWidth?: number;
    /**
     * Width in px of the bottom border
     */
    bottomWidth?: number;
    /**
     * Width in px of the left border
     */
    leftWidth?: number;
    /**
     * Set to true if should be excluded from print
     */
    noPrint?: boolean;
    /**
     * Set a border radius
     */
    radius?: string;
    /**
     * Set a box shadow
     */
    shadow?: string;
    /**
     * Override the color prop with color of your choice
     */
    colorOverride?: Property.Color;
    /**
     * Set a border image
     */
    borderImage?: React.CSSProperties['borderImage'];
}

/**
 * Applies a border to the its child.
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export class Border extends React.Component<IBorderProps> {
    public static defaultProps: Partial<IBorderProps> = {
        noPrint: false,
    };

    public render() {
        const {
            color,
            sideColor,
            hoverColor,
            width,
            topWidth,
            rightWidth,
            bottomWidth,
            leftWidth,
            noPrint,
            children,
            radius,
            shadow,
            style,
            colorOverride,
            borderImage,
            ...extendedProps
        } = this.props;

        const child = React.Children.only(children);

        const hasWidthSet =
            isNumber(topWidth) ||
            isNumber(rightWidth) ||
            isNumber(bottomWidth) ||
            isNumber(leftWidth) ||
            isNumber(width);

        const fallback = hasWidthSet ? 0 : 1;

        const top = topWidth ?? width ?? fallback;
        const right = rightWidth ?? width ?? fallback;
        const bottom = bottomWidth ?? width ?? fallback;
        const left = leftWidth ?? width ?? fallback;
        const printClass = noPrint ? cx(noPrintStyle) : '';
        const hoverClass = hoverColor
            ? css`
                  transition: border-color 150ms ease-in-out;
                  :hover {
                      border-color: ${ColorsEnum[hoverColor]} !important;
                  }
              `
            : '';

        const attributes = extendableProps(extendedProps, {
            className: cx(printClass, hoverClass),
            style: {
                borderBlockStartWidth: `${top}px`, //Logical properties instead of directional for RTL support
                borderInlineEndWidth: `${right}px`,
                borderBlockEndWidth: `${bottom}px`,
                borderInlineStartWidth: `${left}px`,
                borderInlineStartColor: `${
                    colorOverride ||
                    (sideColor && sideColor.left && ColorsEnum[sideColor.left]) ||
                    (color && ColorsEnum[color]) ||
                    ColorsEnum.blue
                }`,
                borderInlineEndColor: `${
                    colorOverride ||
                    (sideColor && sideColor.right && ColorsEnum[sideColor.right]) ||
                    (color && ColorsEnum[color]) ||
                    ColorsEnum.blue
                }`,
                borderBlockStartColor: `${
                    colorOverride ||
                    (sideColor && sideColor.top && ColorsEnum[sideColor.top]) ||
                    (color && ColorsEnum[color]) ||
                    ColorsEnum.blue
                }`,
                borderBlockEndColor: `${
                    colorOverride ||
                    (sideColor && sideColor.bottom && ColorsEnum[sideColor.bottom]) ||
                    (color && ColorsEnum[color]) ||
                    ColorsEnum.blue
                }`,
                borderImage: borderImage,
                borderRadius: radius,
                boxShadow: shadow,
                borderStyle: style || 'solid',
            },
        });

        return renderReactChildren(
            child,
            (c) => React.cloneElement(c, attributes),
            (c) => React.cloneElement(c, attributes.__htmlAttributes),
        );
    }
}
