import type { t } from 'app/translate';

enum GenetecScenarioType {
    AllInOneLocal = 0,
    AllInOneArchiving = 1,
    EntryRackMount = 2,
    Enterprise = 3,
    Centralized = 6,
}

type GenetecGroupType = keyof typeof t.genetecGROUP;
export const GENETEC_CONFIGURED = 100;
export const DEFAULT_GENETEC_TYPE = GenetecScenarioType.Enterprise;

export const GenetecType: Record<GenetecScenarioType, GenetecGroupType> = {
    0: 'solutionTypeAllInOneLocal',
    1: 'solutionTypeAllInOneArchiving',
    2: 'solutionTypeEntryRackmount',
    3: 'solutionTypeEnterprise',
    6: 'solutionTypeCentralized',
};
