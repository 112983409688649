import { createCachedSelector } from 're-reselect';
import {
    getEditedProfileScenarioId,
    getHasEditedCustomBandwidthProfile,
    getHasEditedProfile,
} from './getHasEditedProfile';
import { getAssociatedProfile } from './getAssociatedProfile';
import { t } from 'app/translate';
import { getScenariosRecord } from '../../profile/selectors/getScenarios';
import { toCacheKey } from '../../cacheKey';

/**
 * Get the scenario name from profile attached on the item id.
 * Includes ` - edited` if the scenario is overridden.
 */
export const getScenarioName = createCachedSelector(
    [
        getAssociatedProfile,
        getEditedProfileScenarioId,
        getScenariosRecord,
        getHasEditedProfile,
        getHasEditedCustomBandwidthProfile,
    ],
    (
        associatedProfile,
        editedProfileScenarioId,
        scenariosRecord,
        hasEditedProfile,
        hasCustomBandwidth,
    ) => {
        if (!associatedProfile) {
            return '';
        }

        const scenarioName =
            scenariosRecord[editedProfileScenarioId ?? associatedProfile.scenario.scenarioId]
                ?.name ?? '';

        return hasEditedProfile
            ? `${scenarioName} - ${t.edited}`
            : hasCustomBandwidth
              ? t.customBandwidth
              : scenarioName;
    },
)(toCacheKey);

/**
 * Get the scenario name from profile attached on the item id.
 * Does **not** take into account if the scenario has been edited overridden.
 */
export const getScenarioNameNoOverride = createCachedSelector(
    [getAssociatedProfile, getScenariosRecord],
    (associatedProfile, scenariosRecord) => {
        if (!associatedProfile) {
            return '';
        }

        return scenariosRecord[associatedProfile.scenario.scenarioId]?.name ?? '';
    },
)(toCacheKey);
