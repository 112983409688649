import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

@injectable()
export class Migration2To3 extends MigrationProviderBase {
    public from: number = 2;
    public to: number = 3;

    public provideMigration() {
        return (entity: any): Promise<any> => {
            if (entity.type === 'project') {
                delete entity.endCustomerInfo;
                entity.notes = '';
            }

            return Promise.resolve(entity);
        };
    }
}
