import * as React from 'react';
import { Box, Button, Stack } from 'app/components';
import type { IApplicationItem } from 'app/modules/common';
import {
    getCurrentProjectLocked,
    getIsOtherSelectorOpen,
    getIsPartnerProductAdded,
    getIsStandalone,
    getPiaItem,
    PiaItemMsrp,
    hasApdParamsBoth,
} from 'app/modules/common';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import type { Id } from 'app/core/persistence';
import { CustomItemService } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { getIsApplicationAddedToDevice, getIsClickedApplication } from '../selectors';
import { AddonSelectorActionService, AddonSelectorService } from '../services';

interface IApplicationBottomProps {
    application: IApplicationItem;
    parentDeviceId?: Id;
    onToggled?(): void;
}

/**
 * Lower part of Application Item, containing MSRP and Button to add/remove
 * @param application The application item to render the card for
 * @param parentDeviceId The id of the device the application is added to
 * @param onToggled Callback when application is selected/unselected
 */
export const ApplicationBottom: React.FC<IApplicationBottomProps> = ({
    application,
    parentDeviceId,
    onToggled,
}) => {
    const isApplicationAdded = useSelector<IStoreState, boolean>((state) =>
        getIsApplicationAddedToDevice(state, parentDeviceId, application.productId),
    );
    const isPartnerProductAdded = useSelector<IStoreState, boolean>((state) =>
        getIsPartnerProductAdded(state, application.productId),
    );
    const isPartnerProductOtherSelector = useSelector(getIsOtherSelectorOpen);
    const isAdded = isPartnerProductOtherSelector ? isPartnerProductAdded : isApplicationAdded;

    const isLocked = useSelector<IStoreState, boolean>(getCurrentProjectLocked);
    const isClickedApplication = useSelector<IStoreState, boolean>((state) =>
        getIsClickedApplication(state, application.productId),
    );
    const isStandalone = useSelector(getIsStandalone);

    const addonSelectorService = useService(AddonSelectorService);
    const addonSelectorActionService = useService(AddonSelectorActionService);
    const customItemService = useService(CustomItemService);

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, parentDeviceId),
    );

    const toggleApplication = async () => {
        if (!parentDeviceId && !isPartnerProductOtherSelector) {
            return;
        }
        parentDeviceId
            ? await addonSelectorService.toggleApplication(
                  parentDeviceId,
                  application,
                  !!piaItem && hasApdParamsBoth(piaItem),
              )
            : await customItemService.toggleCustomItem(application);
        // Makes item no longer highlighted after adding/removing it
        if (isClickedApplication) {
            addonSelectorActionService.setClickedApplication(undefined);
        }
        if (onToggled) {
            onToggled();
        }
    };

    return (
        <Box justifyContent="center" paddingBottom="base">
            <Stack vertical alignItems="center" spacing="half">
                {!application.isIncluded && <PiaItemMsrp piaId={application.productId} />}

                {!isStandalone && (
                    <Button
                        disabled={isLocked}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleApplication();
                        }}
                        testId={`${isAdded ? 'remove' : 'add'}_${application.name}`}
                    >
                        {isAdded ? t.remove : t.add}
                    </Button>
                )}
            </Stack>
        </Box>
    );
};

ApplicationBottom.displayName = 'ApplicationBottom';
