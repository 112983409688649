import { injectable } from 'inversify';
import type { IPeopleCounterItemEntity, IPersistence } from 'app/core/persistence';
import type { IInstallationReportPeopleCounterDevice } from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum } from 'app/modules/common';
import type { IPiaPeopleCounter } from 'app/core/pia';

@injectable()
export class PeopleCounterDeviceService {
    constructor(private baseDeviceService: BaseDeviceService) {}

    public async mapDevice(
        item: IPersistence<IPeopleCounterItemEntity>,
        piaItem: IPiaPeopleCounter,
    ): Promise<IInstallationReportPeopleCounterDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.PeopleCounters,
        };
    }
}
