import type { IEnvironment, ISelectedEnvironment } from './../models/IEnvironment';
import {
    getPiaItems,
    getPiaItemsRecord,
    getCurrentProjectItems,
    getCurrentProjectItemRelationsArray,
    getCurrentProjectRegions,
    getUseProductAllowlist,
    getProductAllowlist,
    getIdFromProps,
    toCacheKey,
    getCurrentProjectItemProductId,
} from 'app/modules/common';
import { createSelector } from 'reselect';
import { filterProducts } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { ServiceLocator } from 'app/ioc';
import { MountService } from '../services/Mounts.service';
import { ProductQueriesService } from '../services/ProductQueries.service';
import { t } from 'i18next';
import { getFilterOutdoorForSelectedDevice } from './getIsOnlyOutdoor';

const ENVIRONMENT_NAME_TO_TRANSLATION_KEY: Record<string, string> = {
    '3/4_NPS_pipe': 'threeQuartersNPSpipe',
    '1/2_NPS_pipe': 'halfNPSpipe',
    'DIN rail': 'dinRail',
};

const translateEnvironment = (name: string) =>
    t(`accessoryEnvironmentsGROUP.${ENVIRONMENT_NAME_TO_TRANSLATION_KEY[name] || name}`);

export const getEnvironments = createSelector([getPiaItems], (piaItems) => {
    return piaItems.filter(filterProducts.byCategories(['environments'])).map((environment) => {
        return {
            productId: environment.id,
            name: translateEnvironment(environment.name),
            originalName: environment.name,
        } as IEnvironment;
    });
});

export const getSelectedEnvironment = createCachedSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
        getIdFromProps,
    ],
    (piaItems, items, relations, deviceId) => {
        const environmentMountRelation = relations.find(
            (relation) => relation.relationType === 'environment' && relation.parentId === deviceId,
        );
        const environment = environmentMountRelation
            ? items[environmentMountRelation.childId]
            : undefined;
        const environmentPiaItem = environment?.productId
            ? piaItems[environment.productId]
            : undefined;

        return environment && environmentPiaItem
            ? ({
                  id: environment._id,
                  productId: environmentPiaItem.id,
                  name: translateEnvironment(environmentPiaItem.name),
                  originalName: environmentPiaItem.name,
              } as ISelectedEnvironment)
            : undefined;
    },
)(toCacheKey);

export const getSelectedEnvironmentPiaId = createCachedSelector(
    [getSelectedEnvironment, getIdFromProps],
    (selectedEnvironment) => selectedEnvironment?.productId ?? undefined,
)(toCacheKey);

export const getAvailableEnvironments = createCachedSelector(
    [
        getCurrentProjectItemProductId,
        getUseProductAllowlist,
        getProductAllowlist,
        getCurrentProjectRegions,
        getFilterOutdoorForSelectedDevice,
        getIdFromProps,
    ],
    (itemProductId, useAllowlist, allowlist, regions, isOnlyOutdoor) => {
        if (!itemProductId) {
            return [];
        }

        const productQueries = ServiceLocator.get(ProductQueriesService);
        const environments = productQueries
            .getDeviceCompatibleEnvironments(itemProductId, regions)
            .toList();

        const mountService = ServiceLocator.get(MountService);
        const filteredEnvironments = mountService.environmentsWithAvailableMountingChainsFilter(
            environments,
            itemProductId,
            regions,
            useAllowlist ? allowlist : undefined,
            isOnlyOutdoor,
        );

        return filteredEnvironments;
    },
)(toCacheKey);
