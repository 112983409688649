import { Time24 } from 'app/core/persistence';
import { t } from 'app/translate';
import type { IScenarioModel } from '../models';

export function getScenarioModels(): IScenarioModel[] {
    return [
        {
            name: t.scenarioNamesGROUP.emergencyExit,
            id: 'emergencyExit',
            image: require('src/assets/images/profile/scenarios/emergencyExit.png'),
            inactive: false,
            indoor: true,
            motionRef: {
                0: 0.0025,
                1: 0.0025,
                2: 0.005,
                3: 0.01,
                4: 0.03,
                5: 0.04,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 710,
                triggeredDayMotion: 1,
                triggeredNightMotion: 1,
                continuousDayMotion: 1,
                continuousNightMotion: 1,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.reception,
            id: 'reception',
            image: require('src/assets/images/profile/scenarios/reception.png'),
            inactive: false,
            indoor: true,
            motionRef: {
                0: 0.0025,
                1: 0.005,
                2: 0.01,
                3: 0.015,
                4: 0.03,
                5: 0.06,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 810,
                triggeredDayMotion: 3,
                triggeredNightMotion: 1,
                continuousDayMotion: 3,
                continuousNightMotion: 1,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.parking,
            id: 'parking',
            image: require('src/assets/images/profile/scenarios/parking.png'),
            inactive: false,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.005,
                2: 0.01,
                3: 0.025,
                4: 0.06,
                5: 0.1,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 780,
                triggeredDayMotion: 2,
                triggeredNightMotion: 2,
                continuousDayMotion: 2,
                continuousNightMotion: 2,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.perimeter,
            id: 'perimeter',
            image: require('src/assets/images/profile/scenarios/perimeter.png'),
            inactive: false,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.005,
                2: 0.01,
                3: 0.02,
                4: 0.035,
                5: 0.05,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 660,
                triggeredDayMotion: 1,
                triggeredNightMotion: 1,
                continuousDayMotion: 1,
                continuousNightMotion: 1,
                nightLighting: 1,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.highway,
            id: 'highway',
            image: require('src/assets/images/profile/scenarios/highway.png'),
            inactive: false,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.025,
                2: 0.04,
                3: 0.05,
                4: 0.1,
                5: 0.15,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1200,
                triggeredDayMotion: 3,
                triggeredNightMotion: 2,
                continuousDayMotion: 3,
                continuousNightMotion: 2,
                nightLighting: 1,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.atm,
            id: 'atm',
            image: require('src/assets/images/profile/scenarios/atm.png'),
            inactive: false,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.005,
                2: 0.01,
                3: 0.015,
                4: 0.03,
                5: 0.05,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 850,
                triggeredDayMotion: 2,
                triggeredNightMotion: 1,
                continuousDayMotion: 2,
                continuousNightMotion: 1,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.runningTrain,
            id: 'runningTrain',
            image: require('src/assets/images/profile/scenarios/runningTrain.png'),
            inactive: true,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.005,
                2: 0.025,
                3: 0.06,
                4: 0.08,
                5: 0.11,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1100,
                triggeredDayMotion: 4,
                triggeredNightMotion: 3,
                continuousDayMotion: 4,
                continuousNightMotion: 3,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.streetCorner,
            id: 'streetCorner',
            image: require('src/assets/images/profile/scenarios/streetCorner.png'),
            inactive: false,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.01,
                2: 0.025,
                3: 0.04,
                4: 0.07,
                5: 0.1,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 990,
                triggeredDayMotion: 2,
                triggeredNightMotion: 1,
                continuousDayMotion: 2,
                continuousNightMotion: 1,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.onboard,
            id: 'onboard',
            image: require('src/assets/images/profile/scenarios/onboard.png'),
            inactive: false,
            indoor: true,
            motionRef: {
                0: 0.0025,
                1: 0.005,
                2: 0.01,
                3: 0.015,
                4: 0.04,
                5: 0.08,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 920,
                triggeredDayMotion: 3,
                triggeredNightMotion: 3,
                continuousDayMotion: 3,
                continuousNightMotion: 3,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.intersection,
            id: 'intersection',
            image: require('src/assets/images/profile/scenarios/intersection.png'),
            inactive: false,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.015,
                2: 0.03,
                3: 0.05,
                4: 0.07,
                5: 0.1,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1050,
                triggeredDayMotion: 4,
                triggeredNightMotion: 2,
                continuousDayMotion: 4,
                continuousNightMotion: 2,
                nightLighting: 1,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.retail,
            id: 'retail',
            image: require('src/assets/images/profile/scenarios/retail.png'),
            inactive: false,
            indoor: true,
            motionRef: {
                0: 0.0025,
                1: 0.0075,
                2: 0.015,
                3: 0.03,
                4: 0.05,
                5: 0.1,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1800,
                triggeredDayMotion: 3,
                triggeredNightMotion: 1,
                continuousDayMotion: 3,
                continuousNightMotion: 1,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.pedestrianZone,
            id: 'pedestrianZone',
            image: require('src/assets/images/profile/scenarios/pedestrianZone.png'),
            inactive: false,
            indoor: false,
            motionRef: {
                0: 0.0025,
                1: 0.01,
                2: 0.02,
                3: 0.03,
                4: 0.05,
                5: 0.09,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1300,
                triggeredDayMotion: 4,
                triggeredNightMotion: 3,
                continuousDayMotion: 4,
                continuousNightMotion: 3,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.busyStation,
            id: 'busyStation',
            image: require('src/assets/images/profile/scenarios/busyStation.png'),
            inactive: false,
            indoor: true,
            motionRef: {
                0: 0.0025,
                1: 0.02,
                2: 0.04,
                3: 0.06,
                4: 0.1,
                5: 0.13,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1500,
                triggeredDayMotion: 4,
                triggeredNightMotion: 3,
                continuousDayMotion: 4,
                continuousNightMotion: 3,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.checkoutLine,
            id: 'checkoutLine',
            image: require('src/assets/images/profile/scenarios/checkoutLine.png'),
            inactive: false,
            indoor: true,
            motionRef: {
                0: 0.0025,
                1: 0.005,
                2: 0.01,
                3: 0.04,
                4: 0.08,
                5: 0.13,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1100,
                triggeredDayMotion: 4,
                triggeredNightMotion: 1,
                continuousDayMotion: 4,
                continuousNightMotion: 1,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
        {
            name: t.scenarioNamesGROUP.mall,
            id: 'mall',
            image: require('src/assets/images/profile/scenarios/mall.png'),
            inactive: true,
            indoor: true,
            motionRef: {
                0: 0.0025,
                1: 0.02,
                2: 0.04,
                3: 0.06,
                4: 0.1,
                5: 0.15,
            },
            averageObjectSize: 2,
            dependencies: {
                sceneDetails: 1400,
                triggeredDayMotion: 5,
                triggeredNightMotion: 1,
                continuousDayMotion: 5,
                continuousNightMotion: 1,
                nightLighting: 2,
                scenarioLightStart: new Time24('06:00'),
                scenarioLightEnd: new Time24('18:00'),
            },
        },
    ];
}
