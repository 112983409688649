import { reducerBuilder } from 'app/store';
import type { IAccessoriesWithDataSheets, IDocumentationState } from './models';
import { DocumentationActions } from './DocumentationActions';
import type { PiaId } from 'app/core/pia';

const initialState: IDocumentationState = {
    selectedPiaIds: [],
    searchFilter: '',
    accessories: { latestPiaIdsChecked: [], accessoriesWithDataSheets: new Set([]) },
};

export const documentationReducer = reducerBuilder<IDocumentationState>()
    .setInitialState(initialState)
    .onAction<PiaId>(DocumentationActions.addSelectedId, (state, action) => ({
        ...state,
        selectedPiaIds: [...state.selectedPiaIds, action.payload],
    }))
    .onAction<number>(DocumentationActions.removeId, (state, action) => ({
        ...state,
        selectedPiaIds: state.selectedPiaIds.filter((id) => id !== action.payload),
    }))
    .onAction<number[]>(DocumentationActions.addSeveralIds, (state, action) => ({
        ...state,
        selectedPiaIds: action.payload,
    }))
    .onAction<void>(DocumentationActions.clearSelected, (state) => ({
        ...state,
        selectedPiaIds: [],
    }))
    .onAction<string>(DocumentationActions.deviceFilterChange, (state, action) => ({
        ...state,
        searchFilter: action.payload,
    }))
    .onAction<IAccessoriesWithDataSheets>(
        DocumentationActions.updateAccessories,
        (state, action) => ({
            ...state,
            accessories: action.payload,
        }),
    )
    .create();
