import * as React from 'react';
import { connect } from 'react-redux';
import { ServiceLocator } from 'app/ioc';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import type { DistanceUnit, IInstallationPointModel } from 'app/core/persistence';
import { isSpeaker, InstallationPointService } from 'app/core/persistence';
import {
    DistanceRange,
    getCurrentProjectDisplayUnit,
    generateToDisplayUnitConverter,
    HelpButton,
} from 'app/modules/common';
import {
    Border,
    Box,
    Card,
    Checkbox,
    EditableText,
    Heading,
    IconText,
    Label,
    Stack,
} from 'app/components';
import { calculateSoundPressureLevel, getCeilingMaxDistance } from '../../../../utils';
import type { IMapsSpeaker } from '../../../../models';
import { ensureSpeakerOrUndefined } from '../../../../models';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import { AppConstants } from 'app/AppConstants';
import { speakerCalculationUrl } from 'app/core/common/speakerCalculationUrl';

export interface ISpeakerContextItemOwnProps {
    installationPoint: IInstallationPointModel;
    installationHeight: number;
    onInstallationHeightChange(value: number): void;
    ipName?: string;
    onNameChange(name: string): void;
}

interface ISpeakerContextItemProps extends ISpeakerContextItemOwnProps {
    displayUnit: DistanceUnit;
    isGeneric: boolean;
    isHornSpeaker: boolean;
    basicSolution: boolean;
    outdoor: boolean;
    minInstallationHeight: number;
    maxInstallationHeight: number;
    mapsSpeaker?: IMapsSpeaker;
    targetDistance: number;
    targetHeight: number;
    coverage: number;
    speakerHeight: number;
    soundPressureLevel: number;
    ipName?: string;
    onNameChange(name: string): void;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ISpeakerContextItemOwnProps,
): ISpeakerContextItemProps => {
    const displayUnit = getCurrentProjectDisplayUnit(storeState);

    const convertToDisplayUnit = generateToDisplayUnitConverter(displayUnit);
    const isGeneric = !ownProps.installationPoint.parentPiaDevice;

    const speakerDevice = isSpeaker(ownProps.installationPoint.parentPiaDevice)
        ? ownProps.installationPoint.parentPiaDevice
        : undefined;

    const isHornSpeaker = speakerDevice?.properties.formFactor === 'horn';
    const basicSolution = ownProps.installationPoint.speaker?.settings.basicSolution ?? true;
    const outdoor = ownProps.installationPoint.speaker?.settings.outdoor ?? false;
    const maxInstallationHeight = convertToDisplayUnit(
        Math.min(speakerDevice?.properties.maxRecommendedMountingHeight ?? Number.MAX_VALUE, 20),
    );
    const minInstallationHeight = convertToDisplayUnit(
        Math.max(speakerDevice?.properties.minRecommendedMountingHeight ?? 0, 1),
    );
    const mapsSpeaker = ensureSpeakerOrUndefined(getSelectedDeviceOrParentDevice(storeState));
    const targetDistance = ownProps.installationPoint.speaker?.target.distance ?? 0;
    const targetHeight = ownProps.installationPoint.speaker?.target.height ?? 0;
    const coverage = speakerDevice?.properties.horizontalSpeakerCoverage ?? 0;
    const speakerHeight = ownProps.installationPoint.height;
    const soundPressureLevel = speakerDevice?.properties.soundPressureLevel ?? 0;

    return {
        ...ownProps,
        displayUnit,
        minInstallationHeight,
        maxInstallationHeight,
        isGeneric,
        isHornSpeaker,
        basicSolution,
        outdoor,
        mapsSpeaker,
        targetDistance,
        targetHeight,
        coverage,
        speakerHeight,
        soundPressureLevel,
    };
};

const SpeakerContextItemContainer: React.FC<ISpeakerContextItemProps> = ({
    installationPoint,
    isGeneric,
    displayUnit,
    isHornSpeaker,
    basicSolution,
    outdoor,
    installationHeight,
    maxInstallationHeight,
    minInstallationHeight,
    mapsSpeaker,
    targetDistance,
    targetHeight,
    coverage,
    speakerHeight,
    soundPressureLevel,
    onInstallationHeightChange,
    ipName,
    onNameChange,
}) => {
    const [installationPointService] = React.useState<InstallationPointService>(
        ServiceLocator.get(InstallationPointService),
    );

    const setSpeakerSetting = (
        settings: Partial<NonNullable<IInstallationPointModel['speaker']>['settings']>,
    ) => {
        if (!installationPoint?.speaker) {
            return;
        }

        installationPointService.updateInstallationPointDebounced(installationPoint._id, {
            ...installationPoint,
            speaker: {
                ...installationPoint.speaker,
                settings: {
                    ...installationPoint.speaker.settings,
                    ...settings,
                },
            },
        });
    };

    // The result of the Pythagorean theorem
    const getEuclideanDistance = () => {
        let distance = targetDistance;
        if (!mapsSpeaker) {
            return 0;
        } else if (mapsSpeaker.placement === 'ceiling') {
            distance = getCeilingMaxDistance(speakerHeight, coverage, basicSolution);
        }
        return Math.sqrt(Math.pow(distance, 2) + Math.pow(installationHeight - targetHeight, 2));
    };

    const calculatedSpl = calculateSoundPressureLevel(soundPressureLevel, getEuclideanDistance());

    return (
        <Card paddingY="base">
            <Stack vertical spacing="panel">
                <Border bottomWidth={1} color="grey2">
                    <Box width="100%" paddingBottom="base" paddingLeft="base">
                        <Heading width="100%">
                            <EditableText
                                value={ipName ?? ''}
                                maxLength={AppConstants.deviceNameMaxLength}
                                onChange={onNameChange}
                                placeholder={t.name}
                                testId="name_of_speaker"
                            />
                        </Heading>
                    </Box>
                </Border>
                {isGeneric && (
                    <Box paddingX="base">
                        <IconText
                            icon="info"
                            iconProps={{ opaque: true, color: 'blue', size: 'ms' }}
                        >
                            {t.selectModelToSeeCoverage}
                        </IconText>
                    </Box>
                )}

                <Box paddingX="base" direction="column">
                    <Stack vertical>
                        <DistanceRange
                            color={'blue'}
                            displayUnit={displayUnit}
                            max={maxInstallationHeight}
                            min={minInstallationHeight}
                            step={0.1}
                            showValue
                            showValueInLabel
                            label={t.installationHeight}
                            onChange={onInstallationHeightChange}
                            value={installationHeight}
                            decimals={1}
                            changeCriteria="key"
                        />
                        {!isGeneric && (
                            <Label>
                                {`${t.soundPressureLevel} ${calculatedSpl.toFixed()} ${
                                    t.abbreviationsGROUP.decibel
                                }`}
                            </Label>
                        )}
                        <Stack>
                            <Checkbox
                                slider
                                justifyLeft
                                selected={basicSolution}
                                onChange={(newBasicSolution) =>
                                    setSpeakerSetting({ basicSolution: newBasicSolution })
                                }
                            >
                                {t.speakerSelectorBasicSolution}
                            </Checkbox>
                            <HelpButton
                                modalTitle={t.speakerSelectorCoverageTitle}
                                modalInfo={t.speakerSelectorCoverageMessage}
                                manualLink={speakerCalculationUrl}
                                linkText={t.readMore}
                            />
                        </Stack>
                        {isHornSpeaker && (
                            <Checkbox
                                slider
                                justifyLeft
                                selected={outdoor}
                                onChange={(isOutdoor) => setSpeakerSetting({ outdoor: isOutdoor })}
                            >
                                {t.outdoorPlacement}
                            </Checkbox>
                        )}
                    </Stack>
                </Box>
            </Stack>
        </Card>
    );
};

export const SpeakerContextItem = connect(mapStateToProps)(SpeakerContextItemContainer);

SpeakerContextItem.displayName = 'SpeakerContextItem';
