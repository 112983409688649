import { createCachedSelector } from 're-reselect';
import { getPiaCameraForProductId, toCacheKey } from 'app/modules/common';
import { getBestReplacementLens } from './getBestReplacementLens';
import { t } from 'app/translate';
import { uniq } from 'lodash';
import type { IPiaAccessory, PiaId } from 'app/core/pia';

export interface ISingularLens {
    piaId: PiaId | null;
    name: string;
}

export const getLensesForProductItem = createCachedSelector(
    [getBestReplacementLens, getPiaCameraForProductId],
    (bestLens, piaCamera): IPiaAccessory[] | undefined => {
        if (!bestLens) {
            return undefined;
        }

        const numberOfSensors = piaCamera && piaCamera.properties.imageSensors;

        if (bestLens) {
            // Multiply replacement lens with number of sensors on camera
            return Array(numberOfSensors).fill(bestLens);
        }
    },
)(toCacheKey);

export const getSingularLensToDisplay = createCachedSelector(
    [getLensesForProductItem],
    (lenses): ISingularLens | undefined => {
        if (!lenses) {
            return undefined;
        }
        const isUsingMultipleModels = uniq(lenses.map((lens) => lens.id)).length > 1;

        return {
            piaId: isUsingMultipleModels ? null : lenses[0].id,
            name: isUsingMultipleModels ? t.accessoryDisplayCategoriesGROUP.lenses : lenses[0].name,
        };
    },
)(toCacheKey);
