import * as React from 'react';
import { StandaloneAccessorySelectorView } from 'app/modules/accessorySelector';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import { getCurrentProject, getPiaItemsRecord } from 'app/modules/common';

interface IStandaloneAccessorySelectorContainerProps {
    preSelectedDeviceId?: number;
    currentProjectLoaded: boolean;
}

function mapStateToProps(state: IStoreState): IStandaloneAccessorySelectorContainerProps {
    let pathDeviceId = Number(
        state.app.routerLocation.pathname.replace('/accessory-selector/', '').replace('/', ''),
    );
    if (pathDeviceId === 0 || isNaN(pathDeviceId) || !getPiaItemsRecord(state)[pathDeviceId]) {
        pathDeviceId = 30490;
    }

    return {
        preSelectedDeviceId: pathDeviceId ? Number(pathDeviceId) : undefined,
        currentProjectLoaded: !!getCurrentProject(state)?._id,
    };
}

class StandaloneAccessorySelectorContainer extends React.Component<IStandaloneAccessorySelectorContainerProps> {
    public render() {
        if (!this.props.currentProjectLoaded) {
            return null;
        }

        return (
            <StandaloneAccessorySelectorView preSelectedDeviceId={this.props.preSelectedDeviceId} />
        );
    }
}
export const StandaloneAccessorySelector = connect(mapStateToProps)(
    StandaloneAccessorySelectorContainer,
);
