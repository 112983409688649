import { createCachedSelector } from 're-reselect';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItems } from '../../project';
import { toCacheKey } from '../../cacheKey';
import { getParentIdOfChildDevice } from './getParentIdOfChildDevice';

export const getParentPiaItem = createCachedSelector(
    [getPiaItemsRecord, getParentIdOfChildDevice, getCurrentProjectItems],
    (piaItemsRecord, parentId, currentProjectItems) => {
        if (parentId === undefined) {
            return null;
        }
        const item = currentProjectItems[parentId];

        if (!item || !item.productId) {
            return null;
        }
        const piaItem = piaItemsRecord[item.productId];
        return piaItem;
    },
)(toCacheKey);
