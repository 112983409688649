import * as React from 'react';
import type { Icons } from 'app/components';
import {
    Positioned,
    Box,
    Border,
    Stack,
    PiaImage,
    Text,
    Badge,
    Icon,
    DropDown,
    DropDownMenuItem,
    Clickable,
} from 'app/components';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import type { IPiaAccessory, IPiaItem } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import {
    getCurrentProjectLocked,
    getIsStandalone,
    getPiaItemsRecord,
    PiaItemMsrp,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { AddAccessoriesButton } from './AddAccessoriesButton.container';
import { MOBILE_WINDOW_SIZE } from '../../constants';
import { css } from '@emotion/css';
import type { IRelatedPiaAccessory } from '../../models';
import {
    getFilterOutdoorForSelectedDevice,
    getNotToBeFollowedByAccessories,
    getSelectedMountsAccessoriesAndLensIds,
} from '../../selectors';
import { hasNotToBeFollowedByAccessory } from './hasNotToBeFollowedByAccessory';
import { getProductNamesNotToBeFollowedBy } from './getProductNamesNotToBeFollowedBy';

const cursorStyle = css`
    cursor: default !important;
`;

interface IMountingItemOwnProps {
    productIds: number[];
    mountOptions: IRelatedPiaAccessory[][];
    onMountChanged(newProductIds: number[]): void;
    label?: string;
    fallbackImage?: string;
    icon?: Icons;
}

interface IMountingItemProps extends IMountingItemOwnProps {
    isLocked: boolean;
    isClickable: boolean;
    isStandalone: boolean;
    piaItemsRecord: Record<number, IPiaItem>;
    selectedMounts: IPiaItem[];
    notToBeFollowedByAccessories: Record<number, IPiaItem[][]>;
    selectedAccessoriesAndLensIds: number[];
    isOutdoorSelected: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IMountingItemOwnProps,
): IMountingItemProps => {
    const isLocked = getCurrentProjectLocked(storeState);
    const piaItemsRecord = getPiaItemsRecord(storeState);
    const piaItems = ownProps.productIds.map((id) => piaItemsRecord[id]);
    const isClickable = ownProps.mountOptions.length > 1 && !isLocked;
    const isStandalone = getIsStandalone(storeState);
    const notToBeFollowedByAccessories = getNotToBeFollowedByAccessories(storeState);
    const selectedAccessoriesAndLensIds = getSelectedMountsAccessoriesAndLensIds(storeState);
    const isOutdoorSelected = getFilterOutdoorForSelectedDevice(storeState) ?? false;

    return {
        isLocked,
        isStandalone,
        isClickable,
        piaItemsRecord,
        selectedMounts: piaItems,
        notToBeFollowedByAccessories,
        selectedAccessoriesAndLensIds,
        isOutdoorSelected,
        ...ownProps,
    };
};

const renderItem = (
    name: string,
    fallbackImage?: string,
    isItemDiscontinued?: boolean,
    icon?: Icons,
    productId?: number,
    showIndoorWarning?: boolean,
) => (
    <Box position="relative" width="100%" testId={`${name}-${productId}`}>
        <Stack
            vertical
            alignItems="center"
            justifyContent={window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'}
        >
            <PiaImage
                piaId={productId ?? null}
                icon={icon || 'category'}
                iconProps={{
                    color: 'blue',
                    opaque: true,
                }}
                imageSize={window.innerWidth > MOBILE_WINDOW_SIZE ? 'xl' : 'lg'}
                imageUrlFallback={!productId ? fallbackImage : undefined}
            />
            <Stack vertical spacing="quart" alignItems="center">
                {isItemDiscontinued && <Badge compact label={t.badgeDiscontinued} type="red" />}
                {showIndoorWarning && (
                    <Badge
                        testId={`${name}_indoor_warning`}
                        compact
                        label={t.notOutdoor}
                        type="yellow"
                    />
                )}
                <Box
                    height="3em"
                    width={window.innerWidth > MOBILE_WINDOW_SIZE ? '135px' : '100px'}
                    alignItems="center"
                    justifyContent={window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'}
                >
                    <Text testId={`mounting_${name}`} style="semibold" align="center">
                        {name}
                    </Text>
                </Box>
            </Stack>
        </Stack>
    </Box>
);

const renderDirectCoupledMenuItem = (selected: boolean, onClick: (mounts: number[]) => void) => (
    <Clickable key="direct_coupled" onClick={() => onClick([])}>
        <DropDownMenuItem display="block" onlyContent selected={selected}>
            <Box position="relative" padding="cell" justifyContent="between">
                <Stack>
                    <PiaImage
                        imageUrlFallback={require('src/assets/images/mounting/directCoupled.png')}
                        imageSize="md"
                        piaId={null}
                        icon="category"
                    />
                    <Text>{t.mountIncluded}</Text>
                </Stack>
            </Box>
        </DropDownMenuItem>
    </Clickable>
);

const checkCompatibilityWithMount = (
    mounts: IRelatedPiaAccessory[],
    notToBeFollowedByAccessories: Record<number, IPiaItem[][]>,
    selectedAccessoriesAndLensIds: number[],
) =>
    mounts.some((mount) =>
        hasNotToBeFollowedByAccessory(
            mount.id,
            notToBeFollowedByAccessories,
            selectedAccessoriesAndLensIds,
        ),
    );

const MountingItemContainer: React.FunctionComponent<IMountingItemProps> = ({
    selectedMounts,
    isLocked,
    isClickable,
    isStandalone,
    mountOptions,
    label,
    fallbackImage,
    icon,
    notToBeFollowedByAccessories,
    selectedAccessoriesAndLensIds,
    isOutdoorSelected,
    piaItemsRecord,
    onMountChanged,
}) => {
    return selectedMounts.length > 0 || mountOptions.some((options) => options.length > 0) ? (
        <Stack vertical notFullWidth spacing="quart">
            <Box paddingBottom="half" width="100%" justifyContent="center">
                {label && (
                    <Text bold color="grey5">
                        {label}
                    </Text>
                )}
            </Box>
            <DropDown
                openInPortal
                minWidth={400}
                trigger={
                    <div className={!isClickable ? cursorStyle : undefined}>
                        <Border
                            color="blackOpacity"
                            radius={isStandalone ? '5px' : '10px'}
                            shadow={isClickable ? '0 4px 10px rgba(0,0,0,.15)' : undefined}
                        >
                            <Box
                                position="relative"
                                padding="base"
                                color={isClickable ? 'white' : isStandalone ? 'warmGrey1' : 'grey1'}
                                alignItems="center"
                                // Fixes IE bug set minWidth of box to borders (2x16px) + item width (135/100px) +
                                // additional items and plus sign (167/124px)
                                minWidth={`${
                                    window.innerWidth > MOBILE_WINDOW_SIZE
                                        ? 32 + 135 + 167 * Math.max(0, selectedMounts.length - 1)
                                        : 32 + 100 + 124 * Math.max(0, selectedMounts.length - 1)
                                }px`}
                            >
                                {isClickable && !isLocked && (
                                    <Positioned position="absolute" top={0} right=".25rem">
                                        <Icon icon="keyboard_arrow_down" color="grey6" />
                                    </Positioned>
                                )}
                                <Stack
                                    spacing={
                                        window.innerWidth > MOBILE_WINDOW_SIZE ? 'half' : 'none'
                                    }
                                    flex="fullWidth"
                                >
                                    {selectedMounts.map((piaItem, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 && <Icon icon="add" />}
                                            {renderItem(
                                                piaItem.name,
                                                fallbackImage,
                                                isDiscontinued(piaItem),
                                                icon,
                                                piaItem.id,
                                                isOutdoorSelected &&
                                                    !(piaItem as IPiaAccessory).properties
                                                        .outdoorReady,
                                            )}
                                            {hasNotToBeFollowedByAccessory(
                                                piaItem.id,
                                                notToBeFollowedByAccessories,
                                                selectedAccessoriesAndLensIds,
                                            ) && (
                                                <Positioned
                                                    position="absolute"
                                                    right={
                                                        window.innerWidth > MOBILE_WINDOW_SIZE
                                                            ? '3rem'
                                                            : '2rem'
                                                    }
                                                    top="1rem"
                                                >
                                                    <Icon
                                                        opaque
                                                        icon="warning"
                                                        color="red"
                                                        size="ms"
                                                        title={t.canNotBeUsedWithProductName(
                                                            getProductNamesNotToBeFollowedBy(
                                                                piaItem.id,
                                                                notToBeFollowedByAccessories,
                                                                selectedAccessoriesAndLensIds,
                                                                piaItemsRecord,
                                                            ),
                                                        )}
                                                    />
                                                </Positioned>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {selectedMounts.length === 0 &&
                                        renderItem(
                                            t.mountIncluded,
                                            require('src/assets/images/mounting/directCoupled.png'),
                                        )}
                                </Stack>
                            </Box>
                        </Border>
                    </div>
                }
                contents={
                    isLocked || (selectedMounts.length > 0 && mountOptions.length <= 1)
                        ? null
                        : mountOptions.map((mounts) =>
                              mounts.length === 0 ? (
                                  renderDirectCoupledMenuItem(
                                      selectedMounts.length === 0,
                                      onMountChanged,
                                  )
                              ) : (
                                  <Clickable
                                      key={mounts.map((mount) => mount.id).join()}
                                      onClick={() =>
                                          onMountChanged(mounts.map((mount) => mount.id))
                                      }
                                  >
                                      <DropDownMenuItem
                                          display="block"
                                          onlyContent
                                          selected={mounts.every(
                                              (mount) =>
                                                  selectedMounts.find((m) => m.id === mount.id) &&
                                                  mounts.length === selectedMounts.length,
                                          )}
                                          disabled={checkCompatibilityWithMount(
                                              mounts,
                                              notToBeFollowedByAccessories,
                                              selectedAccessoriesAndLensIds,
                                          )}
                                      >
                                          {mounts.map(({ name, id, isRecommended }) => (
                                              <Box
                                                  position="relative"
                                                  padding="cell"
                                                  key={id}
                                                  justifyContent="between"
                                              >
                                                  <Stack vertical spacing="none">
                                                      <Stack>
                                                          <PiaImage
                                                              imageUrlFallback={
                                                                  name === t.mountIncluded
                                                                      ? require('src/assets/images/mounting/directCoupled.png')
                                                                      : undefined
                                                              }
                                                              imageSize="md"
                                                              piaId={id}
                                                              icon="category"
                                                          />
                                                          <Text>{name}</Text>
                                                      </Stack>
                                                      {hasNotToBeFollowedByAccessory(
                                                          id,
                                                          notToBeFollowedByAccessories,
                                                          selectedAccessoriesAndLensIds,
                                                      ) && (
                                                          <Stack spacing="quart">
                                                              <Icon
                                                                  opaque
                                                                  icon="warning_small"
                                                                  color="red"
                                                                  size="xs"
                                                              />
                                                              <Text small>
                                                                  {t.canNotBeUsedWithProductName(
                                                                      getProductNamesNotToBeFollowedBy(
                                                                          id,
                                                                          notToBeFollowedByAccessories,
                                                                          selectedAccessoriesAndLensIds,
                                                                          piaItemsRecord,
                                                                      ),
                                                                  )}
                                                              </Text>
                                                          </Stack>
                                                      )}
                                                  </Stack>
                                                  <Stack>
                                                      {isRecommended && (
                                                          <Badge
                                                              whiteSpace="nowrap"
                                                              compact
                                                              label={t.recommended}
                                                              type="green"
                                                          />
                                                      )}
                                                      {id && (
                                                          <PiaItemMsrp
                                                              piaId={id}
                                                              includeMultipacks
                                                          />
                                                      )}
                                                  </Stack>
                                              </Box>
                                          ))}
                                      </DropDownMenuItem>
                                  </Clickable>
                              ),
                          )
                }
            />
            <Box paddingX="base">
                <Stack flex="grow" spacing="half" justifyContent="center">
                    {selectedMounts.map((mount, index) => (
                        <React.Fragment key={index}>
                            {index > 0 ? (
                                <>
                                    <Box flex="none">
                                        <Icon icon="add" color="transparent" />
                                    </Box>
                                    <Box flex="evenSpace" justifyContent="center">
                                        <AddAccessoriesButton productId={mount.id} />
                                    </Box>
                                </>
                            ) : (
                                <Box flex="evenSpace" justifyContent="center">
                                    <AddAccessoriesButton productId={mount.id} />
                                </Box>
                            )}
                        </React.Fragment>
                    ))}
                </Stack>
            </Box>
        </Stack>
    ) : null;
};

export const MountingItem = connect(mapStateToProps)(MountingItemContainer);
