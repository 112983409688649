import type { ILatLng } from 'app/core/persistence';
import type { IPolygon, Line } from 'app/modules/common';
import { offset, rotate, trigonometry, type Point } from 'axis-webtools-util';
import type { Vector3 } from 'three';

/** Wrap a line as a polygon suitable for PolyBooljs */
export function toPolygon(line: Line): IPolygon {
    return {
        regions: [line],
        inverted: false,
    };
}

function createRoundFn(decimals: number) {
    const factor = Math.pow(10, decimals);
    return (value: number) => Math.round(value * factor) / factor;
}

const roundCm = createRoundFn(2);

export const roundPoint = (point: Point): Point => [roundCm(point[0]), roundCm(point[1])];

/** Project a 3d point on the ground plane */
export const projectOnGround = (point3d: Vector3): Point => roundPoint([point3d.x, point3d.z]);

/** Place a polygon on a specified LatLng */
export const polygonToLatLngs = (origin: ILatLng, polygon: IPolygon) =>
    polygon.regions.map((region) => region.map(offset(origin)));

/**
 * Generate a line describing a circle sector with a specified radius and directed
 * towards the central angle. The circle is divided into 5 degree steps.
 */
export function getCircle(radius: number, degrees = 360, centralAngle = 0): Line {
    const startAngle = (360 - degrees) / 2 + 180 + centralAngle;
    const step = 5;
    const stopAngle = degrees + startAngle;

    const point: Point = [radius, 0];
    const line: Line = [];

    for (let angle = startAngle; angle <= stopAngle; angle += step) {
        line.push(rotate(trigonometry.toRadians(angle))(point));
    }

    if (degrees < 360) {
        // if degrees is less than 360 we essentially want a cake slice, i.e.
        // start and end in origo
        line.unshift([0, 0]);
        line.push([0, 0]);
    }

    return line;
}

export const rotatePolygon = (polygon: IPolygon, rotationAngleRad: number) => {
    const rotateToAngle = rotate(rotationAngleRad);

    return {
        regions: polygon.regions.map((line) => line.map(rotateToAngle)),
        inverted: polygon.inverted,
    };
};
