import type { IEnvironment } from './../models/IEnvironment';
import type { PiaCategory, IPiaDevice, PiaId } from 'app/core/pia';
import {
    PiaItemPeopleCounterCategory,
    PiaItemWearablesCategory,
    filterProducts,
    PiaItemState,
    getAllCameraCategories,
    PiaItemEncoderCategory,
    PiaItemPacCategory,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemSpeakerCategory,
    PiaItemMainUnitCategory,
    PiaItemSensorUnitCategory,
    PiaItemRecorderCategory,
    PiaItemAlerterCategory,
    PiaAccessoryCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from 'app/core/pia';

import { getAvailablePiaItems } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import type { IDeviceSearchFilter } from '../models/state/IAccessorySelectorState';
import { getEnvironments } from './getEnvironments';
import { nameComparator } from 'app/utils';

export const getDeviceSearchFilter = (state: IStoreState) => state.accessorySelector.deviceFilter;

interface ISupportedEnvironments {
    environments: IEnvironment[];
}

const cameraCategories = [...getAllCameraCategories()];
const encoders = [PiaItemEncoderCategory.ENCODER];
const fSeries = [
    PiaItemMainUnitCategory.MAINUNIT,
    PiaItemSensorUnitCategory.SENSORUNIT,
    PiaItemSensorUnitCategory.THERMALSENSOR,
];
const accessControls = [
    PiaItemPacCategory.DOORCONTROLLERS,
    PiaItemPacCategory.IORELAYS,
    PiaItemPacCategory.ACCESSSERVER,
    PiaItemPacCategory.NETWORKREADER,
    PiaItemPacCategory.RELAYEXPMODULES,
];
const intercoms = [PiaItemPacCategory.ANSWERINGUNIT, PiaItemPacCategory.DOORSTATIONS];
const speakers = [PiaItemSpeakerCategory.SPEAKER];
const recording = [
    PiaItemRecorderCategory.RECORDERS2,
    PiaItemRecorderCategory.DESKTOPTERMINALS,
    PiaAccessoryCategory.NETWORKSWITCHES,
];

const other = [
    PiaItemDecoderCategory.DECODER,
    PiaItemDetectorCategory.RADARDETECTORS,
    PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES,
    PiaItemPeopleCounterCategory.PEOPLECOUNTERS,
    PiaItemAlerterCategory.ALERTERS,
    PiaItemPagingConsoleCategory.PAGINGCONSOLE,
];

const wearables = [
    PiaItemWearablesCategory.CAMERAEXTENSIONS,
    PiaItemWearablesCategory.CAMERAS,
    PiaItemWearablesCategory.CONTROLLER,
    PiaItemWearablesCategory.DOCKING,
];

const includedCategories = [
    ...cameraCategories,
    ...fSeries,
    ...encoders,
    ...speakers,
    ...intercoms,
    ...wearables,
    ...accessControls,
    ...recording,
    ...other,
];

export const getSelectableDevices = createSelector(
    [getAvailablePiaItems, getDeviceSearchFilter],
    (piaDevices, deviceFilter) => {
        return piaDevices
            .filter(
                (device) =>
                    (deviceFilter.includeDiscontinued &&
                        device.state >= PiaItemState.EXTERNALLY_ANNOUNCED) ||
                    device.state === PiaItemState.EXTERNALLY_ANNOUNCED,
            )
            .filter(filterProducts.byCategories(includedCategories))
            .filter(filterProducts.byVendors(['Axis', '2n']))
            .sort(nameComparator);
    },
);

const getDevicesPerEnvironment = (state: IStoreState) =>
    state.accessorySelector.devicesPerEnvironment;

const getSupportedEnvironments = createSelector(
    [getDevicesPerEnvironment, getEnvironments],
    (devicesPerEnvironment, environments) => {
        const environmentPerDevice: Record<PiaId, IEnvironment[]> = {};

        environments.forEach((environment) => {
            if (environment.productId) {
                (devicesPerEnvironment[environment.productId] || []).forEach((deviceId) => {
                    environmentPerDevice[deviceId] = environmentPerDevice[deviceId] || [];
                    environmentPerDevice[deviceId].push(environment);
                });
            }
        });

        return environmentPerDevice;
    },
);

export const getSelectableDevicesWithSupportedEnvironments = createSelector(
    [getSelectableDevices, getSupportedEnvironments],
    (piaDevices, environments) => {
        return piaDevices.map(
            (device) =>
                ({
                    ...device,
                    environments: environments[device.id] ?? [],
                }) as IPiaDevice & ISupportedEnvironments,
        );
    },
);

export interface IDeviceFilterResult {
    piaDevices: IPiaDevice[];
    foundInCategories: boolean;
}

export const getFilteredSelectableDevices = createSelector(
    [getSelectableDevicesWithSupportedEnvironments, getDeviceSearchFilter],
    (devices, deviceFilter): IDeviceFilterResult => {
        if (!deviceFilter) {
            return { piaDevices: [], foundInCategories: true };
        }

        const showAll =
            !deviceFilter.showCameras &&
            !deviceFilter.showModularSeries &&
            !deviceFilter.showEncoders &&
            !deviceFilter.showSpeakers &&
            !deviceFilter.showIntercoms &&
            !deviceFilter.showWearables &&
            !deviceFilter.showAccessControls &&
            !deviceFilter.showRecorders &&
            !deviceFilter.showOthers &&
            deviceFilter.environments.length === 0;

        if (showAll) {
            return {
                piaDevices: getDevicesMatchingSearch(deviceFilter.searchText, devices),
                foundInCategories: true,
            };
        }

        const deviceCategories = getDeviceCategoriesToShow(deviceFilter);
        const devicesMatchingSearch = getDevicesMatchingSearch(deviceFilter.searchText, devices);
        const devicesMatchingSearchAndEnvironment = getDevicesMatchingEnvironment(
            deviceFilter.environments,
            devicesMatchingSearch,
        );
        const devicesMatchingSearchAndEnvironmentAndCategory =
            devicesMatchingSearchAndEnvironment.filter(
                filterProducts.byCategories(deviceCategories),
            );

        return devicesMatchingSearchAndEnvironmentAndCategory.length === 0 &&
            deviceFilter.searchText
            ? { piaDevices: devicesMatchingSearchAndEnvironment, foundInCategories: false }
            : {
                  piaDevices: devicesMatchingSearchAndEnvironmentAndCategory,
                  foundInCategories: true,
              };
    },
);

const getDevicesMatchingSearch = (
    searchText: string | undefined,
    devices: (IPiaDevice & ISupportedEnvironments)[],
) => {
    return searchText
        ? devices.filter((device) => device.name.toLowerCase().includes(searchText.toLowerCase()))
        : devices;
};

const getDevicesMatchingEnvironment = (
    environments: IEnvironment[],
    devices: (IPiaDevice & ISupportedEnvironments)[],
) => {
    return environments.length
        ? devices.filter((device) =>
              device.environments.some((availableEnvironment: IEnvironment) =>
                  environments.some((env) => env.productId === availableEnvironment.productId),
              ),
          )
        : devices;
};

const getDeviceCategoriesToShow = (filter: IDeviceSearchFilter): PiaCategory[] => {
    const categories: PiaCategory[] = [];

    if (filter.showCameras) {
        categories.push(...cameraCategories);
    }
    if (filter.showModularSeries) {
        categories.push(...fSeries);
    }
    if (filter.showEncoders) {
        categories.push(...encoders);
    }
    if (filter.showSpeakers) {
        categories.push(...speakers);
    }
    if (filter.showIntercoms) {
        categories.push(...intercoms);
    }
    if (filter.showWearables) {
        categories.push(...wearables);
    }
    if (filter.showAccessControls) {
        categories.push(...accessControls);
    }
    if (filter.showRecorders) {
        categories.push(...recording);
    }
    if (filter.showOthers) {
        categories.push(...other);
    }
    return categories;
};
