import * as React from 'react';
import { t } from 'app/translate';
import { Stack, Box, Border, Button, Heading, PiaImage, Card, Spacer } from 'app/components';
import { ServiceLocator } from 'app/ioc';
import {
    CommonActionService,
    DeviceNetwork,
    getCurrentProjectItem,
    getPiaItem,
} from 'app/modules/common';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getSelectedRecordingId } from '../selectors';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';

export const RecorderNetworkSettings: React.FunctionComponent = () => {
    const [commonActionService] = React.useState<CommonActionService>(
        ServiceLocator.get(CommonActionService),
    );

    const selectedDeviceId = useSelector<IStoreState, Id | undefined>(getSelectedRecordingId);

    const selectedDevice = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        (state) => getCurrentProjectItem(state, selectedDeviceId),
    );

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, selectedDeviceId),
    );

    const hidePanel = () => {
        commonActionService.showOverlayPanel(false);
    };

    if (!selectedDevice) {
        return null;
    }

    return (
        <Stack vertical>
            <Border bottomWidth={1} color="grey3">
                <Box justifyContent="between" paddingY="half" paddingLeft="base">
                    <Stack spacing="half">
                        {piaItem && <PiaImage imageSize="md" icon="recording" piaId={piaItem.id} />}
                        {piaItem && <Heading>{piaItem.name}</Heading>}
                    </Stack>
                    <Button text onClick={hidePanel}>
                        {t.close}
                    </Button>
                </Box>
            </Border>

            <Box paddingX="doublePanel" direction="column">
                {selectedDeviceId && selectedDevice.networkSettings && (
                    <Card paddingX="base">
                        <Stack vertical alignItems="start">
                            <DeviceNetwork
                                deviceId={selectedDeviceId}
                                networkSettings={selectedDevice?.networkSettings}
                            />
                            <Spacer spacing="base" />
                        </Stack>
                    </Card>
                )}
            </Box>
        </Stack>
    );
};

RecorderNetworkSettings.displayName = 'RecorderNetworkSettings';
