import { createSelector } from 'reselect';
import { getUserSignedIn } from '../../authentication/selectors/getUserSignedIn';
import {
    NORTH_AMERICA_COUNTRY_CODES,
    SOUTH_AMERICA_COUNTRY_CODES,
    MIDDLE_AMERICA_COUNTRY_CODES,
    CARIBBEAN_COUNTRY_CODES,
} from '../countryCodes';
import { getLocationId } from './getCurrentProject';

const projectPricingCountryCodes = [
    ...NORTH_AMERICA_COUNTRY_CODES,
    ...SOUTH_AMERICA_COUNTRY_CODES,
    ...MIDDLE_AMERICA_COUNTRY_CODES,
    ...CARIBBEAN_COUNTRY_CODES,
];

/** Determines if "Request project pricing" button should be shown in Bill of Materials */
export const getShouldShowProjectPricing = createSelector(
    [getLocationId, getUserSignedIn],
    (projectCountryId, isSignedIn) => {
        return projectPricingCountryCodes.includes(projectCountryId) && !!isSignedIn;
    },
);
