import { sortBy, groupBy, range } from 'lodash-es';
import type { IPiaSystemComponent, IPiaSoftware, PiaId, IPiaItem } from 'app/core/pia';
import { PiaItemRecorderCategory, PoeClass } from 'app/core/pia';
import {
    type Id,
    type IItemEntity,
    type IPersistence,
    type RecordingSolutionType,
} from 'app/core/persistence';

type NoPoE = 'NoPoE';
type PortType = PoeClass | NoPoE;

// NOTE: getItemLocalStorage cannot be used here since references from this file to 'app/core/persistence' (types seems allowed though),
// will generate an error, for some obscure reason, when building SiteDesigner in production mode:
//
// ERROR in Conflict: Multiple assets emit different content to the same filename 95f27e0e8e3376804a45e211af8c032f.js

export const DEBUG_MODE =
    typeof localStorage !== 'undefined' && localStorage.getItem('AED_DEBUG_MODE') === 'true';
export const MAX_COMPANION_DEVICE_COUNT = 36;
export const MAX_COMPANION_RECORDER_COUNT = 8;
export const COMPANION_4_PIA_ID = 53509;
export const RECOMMENDATION_VALIDATION_MAX_DEVICES = 1000;

export const PORT_BLOCKER: IDeviceRequirement = {
    itemId: 'blocker',
    storage: 0,
    bandwidth: 0,
    licenseCount: 0,
    channelCount: 0,
    poeClass: 'NoPoE',
    power: 0,
};

export interface IRecordingSolution {
    id: RecordingSolutionType;
    name: string;
    description: string;
    items: IRecordingSolutionItem[];
}

export interface IRecordingSolutionItem {
    piaId: PiaId;
    name: string;
    accessories?: IRecordingSolutionItem[];
    quantity: number;
    piaItem?: IPiaSystemComponent;
    deviceIds?: Id[];
}

export interface ISDCardComponent extends IComponentWithQuantity {
    deviceIds: Id[];
}

export interface IComponentWithStorage extends IPiaSystemComponent {
    extraStorage?: IPiaSystemComponent[];
    memoryCardSizeGB?: number;
}

export interface IComponentWithQuantity {
    component: IPiaSystemComponent;
    quantity: number;
}

export interface IRecorderConfiguration {
    recorder: IPiaSystemComponent;
    disks: IPiaSystemComponent[];
    licenses: IPiaSystemComponent[];
    devices: IDeviceRequirement[];
    overCapacity: IRecorderComponentProps;
}

export interface ISwitchConfiguration {
    networkSwitch: IPiaSystemComponent;
    recorders: IRecorderConfiguration[];
    devices: IDeviceRequirement[];
    overCapacity: ISwitchComponentProps;
}

export interface ISystemConfiguration {
    recorders: IRecorderConfiguration[];
    switches: ISwitchConfiguration[];
    desktopTerminal?: IPiaSystemComponent;
}

export interface IRecorderDiff {
    recorder: IPiaSystemComponent;
    remainingDevices: IDeviceRequirement[];
    selectedDevices: IDeviceRequirement[];
    overCapacity: IRecorderComponentProps;
}

export interface ISwitchDiff {
    networkSwitch: IPiaSystemComponent;
    remainingDevices: IDeviceRequirement[];
    selectedDevices: IDeviceRequirement[];
    overCapacity: ISwitchComponentProps;
}

export const isRecorderProps = (
    props: IRecorderComponentProps | ISwitchComponentProps,
): props is IRecorderComponentProps => 'maxCameraCount' in props;

export class NoSolutionError extends Error {
    constructor(public msg: string) {
        super(msg);
        Object.setPrototypeOf(this, NoSolutionError.prototype);
    }
}

export interface IParentPiaPersistence {
    item: IPersistence<IItemEntity> | undefined;
    piaItem: IPiaItem | undefined;
}

export interface IDeviceRequirement {
    itemId: Id;
    productId?: PiaId; // Needed to be able to check compatibility e.g. SD-cards
    name?: string;
    modelName?: string;
    storage: number;
    bandwidth: number;
    licenseCount: number;
    channelCount: number;
    poeClass: PortType;
    power: number;
    isCompanionCompatible?: boolean;
    accessories?: IPiaSystemComponent[];
    isVirtualCamera?: boolean; // used for SD-card recommendation check
    totalStorageIncludingVirtual?: number; // used for SD-card recommendation check for virtual products
    parentDeviceName?: string;
    parentDeviceModelName?: string;
    virtualChildren?: IPersistence<IItemEntity>[];
}

export interface IPortRequirement {
    poeClass1PortCount: number;
    poeClass2PortCount: number;
    poeClass3PortCount: number;
    poeClass4PortCount: number;
    poeClass5PortCount: number;
    poeClass6PortCount: number;
    poeClass7PortCount: number;
    poeClass8PortCount: number;
    highPoEPortCount: number;
}

export interface IRecorderComponentProps {
    maxCameraCount: number;
    vmsChannelLicenses: number;
    maxRecordingStorageMegaBytes: number;
    maxRecordingBandwidthBits: number;
    freeHddBays: number;
}

export interface ISwitchComponentProps {
    poeTotalPower: number;
    poeClass1PortCount: number;
    poeClass2PortCount: number;
    poeClass3PortCount: number;
    poeClass4PortCount: number;
    poeClass5PortCount: number;
    poeClass6PortCount: number;
    poeClass7PortCount: number;
    poeClass8PortCount: number;
    highPoEPortCount: number;
}

export type IComponentProps = IRecorderComponentProps & ISwitchComponentProps;

export interface IStorageComponentProps {
    maxRecordingStorageMegaBytes: number;
}

export interface IValidationEventData {
    requirements: IDeviceRequirement[];
    preprocessedComponents: IComponentWithQuantity[];
    sdCardRequirements: ISDCardComponent[];
    selectedRecordingSolutionType: RecordingSolutionType | undefined;
}

/**
 * Return an array of length n populated with item
 */
export const nTimes = <T>(item: T, n: number): T[] => range(n).map(() => item);

export const isRecorder = (
    component: IPiaSystemComponent | IPiaSoftware,
): component is IComponentWithStorage => component.category === 'recorders2';

export const isSwitch = (
    component: IPiaSystemComponent | IPiaSoftware,
): component is IPiaSystemComponent => component.category === 'networkswitches';

export const isLicense = (
    component: IPiaSystemComponent | IPiaSoftware,
): component is IPiaSoftware => component.category === 'vms';

export const isStorage = (
    component: IPiaSystemComponent | IPiaSoftware,
): component is IPiaSystemComponent => component.category === 'storage';

export const isSDCardCompanionSystem = (components: IComponentWithQuantity[]) => {
    const hasRecorder = components.some(({ component }) => component.category === 'recorders2');
    const storage = components.filter(({ component }) => component.category === 'storage');
    const hasSDCards = storage.length > 0 && storage.every(({ component }) => isStorage(component));

    return !hasRecorder && hasSDCards;
};

/**
 * Get the properties relevant when comparing recorders. Note that we ignore
 * switch-properties of recorders with built in switch as that case is handled
 * separately
 */
export const getRecorderProps = (component: IPiaSystemComponent): IRecorderComponentProps => ({
    maxCameraCount: component.properties.maxCameraCount ?? 0,
    maxRecordingStorageMegaBytes: component.properties.maxRecordingStorageMegaBytes ?? 0,
    maxRecordingBandwidthBits: component.properties.maxRecordingBandwidthBits ?? 0,
    vmsChannelLicenses: component.properties.vmsChannelLicenses ?? 0,
    freeHddBays: component.properties.freeHddBays ?? 0,
});

/**
 * Get the properties relevant when comparing switches
 */
export const getSwitchProps = (component: IPiaSystemComponent): ISwitchComponentProps => ({
    poeClass1PortCount: component.properties.poeClass1PortCount ?? 0,
    poeClass2PortCount: component.properties.poeClass2PortCount ?? 0,
    poeClass3PortCount: component.properties.poeClass3PortCount ?? 0,
    poeClass4PortCount: component.properties.poeClass4PortCount ?? 0,
    poeClass5PortCount: component.properties.poeClass5PortCount ?? 0,
    poeClass6PortCount: component.properties.poeClass6PortCount ?? 0,
    poeClass7PortCount: component.properties.poeClass7PortCount ?? 0,
    poeClass8PortCount: component.properties.poeClass8PortCount ?? 0,
    highPoEPortCount: component.properties.highPoEPortCount ?? 0,
    poeTotalPower: component.properties.poeTotalPower ?? 0,
});

/**
 * Get the properties relevant when comparing disks
 */
export const getDiskProps = (component: IPiaSystemComponent): IStorageComponentProps => ({
    maxRecordingStorageMegaBytes: component.properties.maxRecordingStorageMegaBytes ?? 0,
});

export const hasBuiltInSwitch = (recorder: IPiaSystemComponent) =>
    getSwitchProps(recorder).poeTotalPower > 0;

/*
 * Subtract each position of subtrahend from minuend. If the result is
 * negative at some position, try to subtract from higher "parent" positions.
 * This is used for calculating an optimal PoE port allocation
 */
export const subtractFromPeerOrParent = (minuend: number[], subtrahend: number[]): number[] => {
    const result: number[] = [];
    for (let i = 0; i < minuend.length; i++) {
        result[i] = minuend[i] - subtrahend[i];
        if (result[i] < 0) {
            for (let j = i - 1; j >= 0; j--) {
                if (result[j] > 0) {
                    const diff: number = result[j] + result[i];
                    if (diff > 0) {
                        result[j] = diff;
                        result[i] = 0;
                        break;
                    } else {
                        result[j] = 0;
                        result[i] = diff;
                    }
                }
            }
        }
    }

    return result;
};

/*
 * Subtract a port of PoEClass poeClass from a system component so that the "smallest"
 * available port is used.
 */
export const subtractPorts = (
    componentProps: ISwitchComponentProps,
    poeClass: PortType,
): IPortRequirement => {
    const minuend = [
        componentProps.highPoEPortCount,
        componentProps.poeClass8PortCount,
        componentProps.poeClass7PortCount,
        componentProps.poeClass6PortCount,
        componentProps.poeClass5PortCount,
        componentProps.poeClass4PortCount,
        componentProps.poeClass3PortCount,
        componentProps.poeClass2PortCount,
        componentProps.poeClass1PortCount,
        0,
    ];

    const subtrahend = [
        poeClass === PoeClass.HighPoE ? 1 : 0,
        poeClass === PoeClass.Class8 ? 1 : 0,
        poeClass === PoeClass.Class7 ? 1 : 0,
        poeClass === PoeClass.Class6 ? 1 : 0,
        poeClass === PoeClass.Class5 ? 1 : 0,
        poeClass === PoeClass.Class4 ? 1 : 0,
        poeClass === PoeClass.Class3 ? 1 : 0,
        poeClass === PoeClass.Class2 ? 1 : 0,
        poeClass === PoeClass.Class1 ? 1 : 0,
        poeClass === 'NoPoE' ? 1 : 0,
    ];

    const result = subtractFromPeerOrParent(minuend, subtrahend);

    return {
        poeClass1PortCount: result[8] + result[9], //aggregate NoPoe under Class1
        poeClass2PortCount: result[7],
        poeClass3PortCount: result[6],
        poeClass4PortCount: result[5],
        poeClass5PortCount: result[4],
        poeClass6PortCount: result[3],
        poeClass7PortCount: result[2],
        poeClass8PortCount: result[1],
        highPoEPortCount: result[0],
    };
};

/*
 * Subtract the requirements of a device from a recorder with built-in switch
 */
export const subtractDeviceFromProps = (
    props: IRecorderComponentProps & ISwitchComponentProps,
    device: IDeviceRequirement,
): IRecorderComponentProps & ISwitchComponentProps => {
    const diff = {
        maxCameraCount: props.maxCameraCount - device.channelCount,
        vmsChannelLicenses: props.vmsChannelLicenses - device.licenseCount,
        maxRecordingStorageMegaBytes: props.maxRecordingStorageMegaBytes - device.storage,
        maxRecordingBandwidthBits: props.maxRecordingBandwidthBits - device.bandwidth,
        poeTotalPower: props.poeTotalPower - device.power,
        ...subtractPorts(props, device.poeClass),
    };

    return {
        ...props,
        ...diff,
    };
};

/*
 * Subtract the requirements of a device from a switch
 */
export const subtractDeviceFromRecorderProps = (
    props: IRecorderComponentProps,
    device: IDeviceRequirement,
): IRecorderComponentProps => {
    return {
        maxCameraCount: props.maxCameraCount - device.channelCount,
        vmsChannelLicenses: props.vmsChannelLicenses - device.licenseCount,
        maxRecordingStorageMegaBytes: props.maxRecordingStorageMegaBytes - device.storage,
        maxRecordingBandwidthBits: props.maxRecordingBandwidthBits - device.bandwidth,
        freeHddBays: props.freeHddBays,
    };
};

/*
 * Subtract the requirements of a device from a switch
 */
export const subtractDeviceFromSwitchProps = (
    switchProps: ISwitchComponentProps,
    device: IDeviceRequirement,
): ISwitchComponentProps => {
    const newPorts = subtractPorts(switchProps, device.poeClass);

    return {
        poeTotalPower: switchProps.poeTotalPower - device.power,
        poeClass1PortCount: newPorts.poeClass1PortCount,
        poeClass2PortCount: newPorts.poeClass2PortCount,
        poeClass3PortCount: newPorts.poeClass3PortCount,
        poeClass4PortCount: newPorts.poeClass4PortCount,
        poeClass5PortCount: newPorts.poeClass5PortCount,
        poeClass6PortCount: newPorts.poeClass6PortCount,
        poeClass7PortCount: newPorts.poeClass7PortCount,
        poeClass8PortCount: newPorts.poeClass8PortCount,
        highPoEPortCount: newPorts.highPoEPortCount,
    };
};

/*
 *  Evenly and deterministically distribute all devices, i.e. given an array of
 *  device requirements, reorganize the array so that each device type appear at even
 *  distances
 *
 *  Example:
 *  input >  AABBBCCCCCCCCCCCBC
 *  output > ABCCCBCCCABCCCBCCC
 */
export const distributeEvenly = (devices: IDeviceRequirement[]) => {
    // group devices with equal properties and sort by number of devices in each group
    const orderedByQuantity = sortBy(
        Object.values(
            groupBy(
                devices,
                ({ storage, bandwidth, licenseCount, channelCount, poeClass, power }) =>
                    [storage, bandwidth, licenseCount, channelCount, poeClass, power].join('|'),
            ),
        ),
        'length',
    );

    // recursively constuct an array with even distribution of the device types
    const iterate = (
        devicesByQuantity: IDeviceRequirement[][],
        n = Infinity, // number of devices to distribute
    ): IDeviceRequirement[] => {
        if (devicesByQuantity.length === 0) {
            // if there are no devices left, return an empty array
            return [];
        } else if (devicesByQuantity.length === 1) {
            // if there is only one type of device left, get n devices of that type
            return devicesByQuantity[0].splice(0, Math.min(n, devicesByQuantity[0].length));
        }
        const [first, ...rest] = devicesByQuantity;
        const [second] = rest;

        const f = Math.ceil(second.length / first.length);
        const distribution: IDeviceRequirement[] = [];
        const nbrOfItems = Math.min(n, first.length);
        for (let i = 0; i < nbrOfItems; i++) {
            const firstDevice = first.shift();
            if (firstDevice) {
                distribution.push(...[firstDevice, ...iterate(rest, f)]);
            }
        }

        return distribution;
    };

    return iterate(orderedByQuantity);
};

/*
 * preprocess companion/s30 recorders
 */
export const preprocessComponent = (
    component: IPiaSystemComponent,
    isCompanion: boolean = false,
) => {
    return component.properties.series === 'S30' && isCompanion
        ? {
              ...component,
              properties: {
                  ...component.properties,
                  vmsChannelLicenses: Number.MAX_VALUE, // don't consider licenses for Companion - handle hybrid solutions later
                  monitorsSupported: Number.MAX_VALUE, // don't add desktop terminals for Companion
              },
          }
        : component;
};

/*
 * enhance a recorder with extra storage and licenses. Used when we want to
 * partition devices to a recorder configuration with extra storage and licenses
 * @param recorder - the recorder to enhance
 * @param extraStorage - the extra storage to add
 * @param extraLicenses - the extra licenses to add
 * @return an enhanced recorder
 */
export const enhanceRecorder = (
    recorder: IPiaSystemComponent,
    extraStorage: number,
    extraLicenses: number,
): IPiaSystemComponent => {
    const props = getRecorderProps(recorder);
    const maxRecordingStorageMegaBytes = props.maxRecordingStorageMegaBytes + extraStorage;
    const vmsChannelLicenses = props.vmsChannelLicenses + extraLicenses;

    return {
        ...recorder,
        properties: {
            ...recorder.properties,
            maxRecordingStorageMegaBytes,
            vmsChannelLicenses,
        },
    };
};

export const ZERO_CAPACITY_RECORDER: IPiaSystemComponent = {
    id: -1,
    parentId: 0,
    name: 'ZERO_CAPACITY_RECORDER',
    category: PiaItemRecorderCategory.RECORDERS2,
    categories: [],
    relations: [],
    externallyHidden: false,
    state: 40,
    versions: [],
    properties: {
        vendor: 'ZERO_CAPACITY_RECORDER',
        series: 'ZERO_CAPACITY_RECORDER',
        maxCameraCount: 0,
        maxRecordingBandwidthBits: 0,
        maxRecordingStorageMegaBytes: 0,
        vmsChannelLicenses: 0,
        freeHddBays: 0,
    },
};

export const INFINITE_CAPACITY_RECORDER: IPiaSystemComponent = {
    id: -2,
    parentId: 0,
    name: 'INFINITE_CAPACITY_RECORDER',
    category: PiaItemRecorderCategory.RECORDERS2,
    categories: [],
    relations: [],
    externallyHidden: false,
    state: 40,
    versions: [],
    properties: {
        vendor: 'INFINITE_CAPACITY_RECORDER',
        series: 'INFINITE_CAPACITY_RECORDER',
        maxCameraCount: Infinity,
        maxRecordingBandwidthBits: Infinity,
        maxRecordingStorageMegaBytes: Infinity,
        vmsChannelLicenses: Infinity,
        freeHddBays: 0,
    },
};

export const INITIAL_UNDERCAPACITY = {
    freeHddBays: 0,
    highPoEPortCount: 0,
    maxCameraCount: 0,
    maxRecordingBandwidthBits: 0,
    maxRecordingStorageMegaBytes: 0,
    poeClass1PortCount: 0,
    poeClass2PortCount: 0,
    poeClass3PortCount: 0,
    poeClass4PortCount: 0,
    poeClass5PortCount: 0,
    poeClass6PortCount: 0,
    poeClass7PortCount: 0,
    poeClass8PortCount: 0,
    poeTotalPower: 0,
    vmsChannelLicenses: 0,
};
