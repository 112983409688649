import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTextWidth } from 'app/modules/common';
import { Border, Box, DropDown, IconText, Text } from 'app/components';
import { ProjectZipStreamSetting } from 'app/modules/application';
import { t } from 'app/translate';
import { getZipStreamSettingsTitle } from '../selectors';

export const ProjectZipStreamSettingsDropDown: React.FunctionComponent = () => {
    const zipStreamTitle = useSelector(getZipStreamSettingsTitle);

    const getWidestOption = () => {
        const options = Object.values(t.zipStepsTitleGROUP);
        const textWidthArray = options.map((value) => getTextWidth(value, 14));
        return Math.max(...textWidthArray) + 30; // add extra width for icon
    };

    const dropDownTrigger = (
        <Box width={`${getWidestOption()}px`}>
            <Border bottomWidth={1} color="grey5">
                <IconText
                    color="grey8"
                    textProps={{ large: true }}
                    alignIconRight
                    icon="keyboard_arrow_down"
                >
                    {zipStreamTitle}
                </IconText>
            </Border>
        </Box>
    );

    return (
        <Box justifyContent="center" alignItems="center" spacing="base" padding="base">
            <Text large style="semibold" color="grey5">
                {`${t.zipStreamGlobal}:`}
            </Text>
            <DropDown
                trigger={dropDownTrigger}
                contents={
                    <Box color="white">
                        <ProjectZipStreamSetting textAlign="center" />
                    </Box>
                }
                centerAlignContent
                width={600}
                stayOpen
            ></DropDown>
        </Box>
    );
};

ProjectZipStreamSettingsDropDown.displayName = 'ProjectZipStreamSettingsDropDown';
