import type { UseCaseType, UseCaseSubType } from 'app/modules/common';
import { isUseCaseMainCat, isUseCaseSubCat } from './isUseCaseMainCat';

export const getUseCaseTranslationKey = (useCase: UseCaseType | UseCaseSubType) => {
    if (isUseCaseMainCat(useCase)) {
        return useCase;
    }
    if (isUseCaseSubCat(useCase)) {
        switch (useCase) {
            case 'Access control via biometrics':
                return 'accessControlViaBiometrics';
            case 'Age detection':
                return 'ageDetection';
            case 'Aggression detection':
                return 'aggressionDetection';
            case 'Audio content':
                return 'audioContent';
            case 'Audio direction detection':
                return 'audioDirectionDetection';
            case 'Broadcasting':
                return 'broadcasting';
            case 'Car alarm detection':
                return 'carAlarmDetection';
            case 'Chemical detection':
                return 'chemicalDetection';
            case 'Conference solution':
                return 'conferenceSolution';
            case 'Contact tracing':
                return 'contactTracing';
            case 'Crowd detection':
                return 'crowdDetection';
            case 'Distraction detection':
                return 'distractionDetection';
            case 'Dwell time':
                return 'dwellTime';
            case 'Empty shelf detection':
                return 'emptyShelfDetection';
            case 'Explosion detection':
                return 'explosionDetection';
            case 'Face detection':
                return 'faceDetection';
            case 'Face mask detection':
                return 'faceMaskDetection';
            case 'Facial recognition':
                return 'facialRecognition';
            case 'Fatigue detection':
                return 'fatigueDetection';
            case 'Fire detection':
                return 'fireDetection';
            case 'Flood detection':
                return 'floodDetection';
            case 'Fog detection':
                return 'fogDetection';
            case 'Gender detection':
                return 'genderDetection';
            case 'Glass break detection':
                return 'glassBreakDetection';
            case 'Gunshot detection':
                return 'gunshotDetection';
            case 'Hard hat detection':
                return 'hardHatDetection';
            case 'Heat mapping':
                return 'heatMapping';
            case 'Illegal parking':
                return 'illegalParking';
            case 'Intersection block detection':
                return 'intersectionBlockDetection';
            case 'Intrusion detection':
                return 'intrusionDetection';
            case 'License plate recognition':
                return 'licensePlateRecognition';
            case 'Loitering detection':
                return 'loiteringDetection';
            case 'Mobility control':
                return 'mobilityControl';
            case 'Mood detection':
                return 'moodDetection';
            case 'Motion detection':
                return 'motionDetection';
            case 'Motion pattern':
                return 'motionPattern';
            case 'Multi-site':
                return 'multiSite';
            case 'Music content':
                return 'musicContent';
            case 'Object color':
                return 'objectColor';
            case 'Object counting':
                return 'objectCounting';
            case 'Object direction':
                return 'objectDirection';
            case 'Object loss':
                return 'objectLoss';
            case 'Object movements':
                return 'objectMovements';
            case 'Object tracking':
                return 'objectTracking';
            case 'Object type':
                return 'objectType';
            case 'Occupancy':
                return 'occupancy';
            case 'Parking management':
                return 'parkingManagement';
            case 'People counting':
                return 'peopleCounting';
            case 'Perimeter protection':
                return 'perimeterProtection';
            case 'Platform incident detection':
                return 'platformIncidentDetection';
            case 'Privacy masking':
                return 'privacyMasking';
            case 'Public address':
                return 'publicAddress';
            case 'POS exception detection':
                return 'posExceptionDetection';
            case 'QR code':
                return 'qrCode';
            case 'Queue detection':
                return 'queueDetection';
            case 'Queue management':
                return 'queueManagement';
            case 'Rainfall intensity detection':
                return 'rainfallIntensityDetection';
            case 'Random selection':
                return 'randomSelection';
            case 'Remote patient care':
                return 'remotePatientCare';
            case 'Remote training':
                return 'remoteTraining';
            case 'Sabotage detection':
                return 'sabotageDetection';
            case 'Single site':
                return 'singleSite';
            case 'Slip and fall detection':
                return 'slipAndFallDetection';
            case 'Slow vehicle detection':
                return 'slowVehicleDetection';
            case 'Smoke detection':
                return 'smokeDetection';
            case 'Snowfall intensity detection':
                return 'snowfallIntensityDetection';
            case 'Social distancing/proximity detection':
                return 'socialDistancingProximityDetection';
            case 'Speakers':
                return 'speakers';
            case 'Speed detection':
                return 'speedDetection';
            case 'Stopped vehicle detection':
                return 'stoppedVehicleDetection';
            case 'Tailgating detection':
                return 'tailgatingDetection';
            case 'Tampering detection':
                return 'tamperingDetection';
            case 'Temperature detection':
                return 'temperatureDetection';
            case 'Time and attendance':
                return 'timeAndAttendance';
            case 'Timelapse':
                return 'timelapse';
            case 'Track incident detection':
                return 'trackIncidentDetection';
            case 'Traffic congestion detection':
                return 'trafficCongestionDetection';
            case 'Traffic data':
                return 'trafficData';
            case 'Traffic speed drop detection':
                return 'trafficSpeedDropDetection';
            case 'Tunnel incident detection':
                return 'tunnelIncidentDetection';
            case 'Unattended object':
                return 'unattendedObject';
            case 'Vehicle access control via LPR':
                return 'vehicleAccessControlViaLpr';
            case 'Vehicle brand':
                return 'vehicleBrand';
            case 'Vehicle color':
                return 'vehicleColor';
            case 'Vehicle counting':
                return 'vehicleCounting';
            case 'Vehicle detection':
                return 'vehicleDetection';
            case 'Vehicle queue detection':
                return 'vehicleQueueDetection';
            case 'Vehicle type':
                return 'vehicleType';
            case 'Water level detection':
                return 'waterLevelDetection';
            case 'Weather data':
                return 'weatherData';
            case 'Wrong way detection':
                return 'wrongWayDetection';
            case 'Zoned groups':
                return 'zonedGroups';

            default:
                return useCase;
        }
    }
    return useCase;
};
