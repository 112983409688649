import * as React from 'react';
import { Modal, Box, TextInput, Button } from 'app/components';
import { useModalLeftMargin } from '../../hooks/useModalLeftMargin';
import { t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import { eventTracking } from 'app/core/tracking';

interface IChangeMapNameModalProps {
    name: string;
    onNameChange(name: string): void;
    onClose?(): void;
}

export const ChangeMapNameModal: React.FC<IChangeMapNameModalProps> = ({
    name,
    onNameChange,
    onClose,
}) => {
    const [locationName, setLocationName] = React.useState(name);
    const modalLeftMargin = useModalLeftMargin();

    const updateName = () => {
        eventTracking.logUserEvent('Maps', 'Change map location name');
        onNameChange(locationName.trim());
    };

    return (
        <Modal
            title={t.changeName}
            onClose={onClose}
            top={200}
            marginLeft={modalLeftMargin}
            minWidth="350px"
        >
            <Box direction="column" spacing="base">
                <TextInput
                    required
                    maxLength={AppConstants.mapNameMaxLength}
                    autoFocusAndSelect
                    value={locationName}
                    onEnter={updateName}
                    onChange={setLocationName}
                    changeCriteria="key"
                />
                <Box justifyContent="end" spacing="base">
                    <Button text onClick={onClose}>
                        {t.cancel}
                    </Button>
                    <Button primary onClick={updateName}>
                        {t.save}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

ChangeMapNameModal.displayName = 'ChangeMapNameModal';
