function addPrefix(val: string) {
    return `MAPS_${val}`;
}

export const MapsActions = {
    AddFloorPlan: addPrefix('ADD_FLOORPLAN'),
    ChangeFloorPlan: addPrefix('CHANGE_FLOORPLAN'),
    DuplicateMapItem: addPrefix('DUPLICATE_MAP_ITEM'),
    SetSelectedMap: addPrefix('SET_SELECTED_MAP'),
    SetSelectedAddTab: addPrefix('SET_SELECTED_ADD_TAB'),
    SetSelectedDragProduct: addPrefix('SET_SELECTED_DRAG_PRODUCT'),
    SetAddCameraSearchFilter: addPrefix('SET_ADD_CAMERA_SEARCH_FILTER'),
    SetMapScale: addPrefix('SET_MAP_SCALE'),
    SetZoomLevel: addPrefix('SET_ZOOM_LEVEL'),
    ToggleAddMapModal: addPrefix('SHOW_ADD_MAP_MODAL'),
    ToggleCopyMapModal: addPrefix('SHOW_COPY_MAP_MODAL'),
    ToggleAddLocationModal: addPrefix('SHOW_ADD_LOCATION_MODAL'),
    ToggleUploading: addPrefix('UPLOADING'),
    ResetToInitialState: addPrefix('RESET'),
    SelectItem: addPrefix('SELECT'),
    SelectCoverageArea: addPrefix('SELECT_COVERAGE_AREA'),
    ToggleScalingTool: addPrefix('TOGGLE_SCALING_TOOL'),
    RemoveInstallationPoint: addPrefix('REMOVE_INSTALLATION_POINT'),
    RemoveFloorPlan: addPrefix('REMOVE_FLOOR_PLAN'),
    AddFileTransfer: addPrefix('ADD_FILE_TRANSFER'),
    UpdateFileTransfer: addPrefix('UPDATE_FILE_TRANSFER'),
    EndFileTransfer: addPrefix('END_FILE_TRANSFER'),
    ClearFileTransfers: addPrefix('CLEAR_FILE_TRANSFERS'),
    ToggleStreetMode: addPrefix('TOGGLE_STREET_MODE'),
    ToggleShowDevicesOnMap: addPrefix('TOGGLE_SHOW_DEVICES_ON_MAP'),
    ToggleGetToKnowMaps: addPrefix('TOGGLE_GET_TO_KNOW_MAPS'),
    ToggleDoriPixels: addPrefix('TOGGLE_DORI_PIXELS'),
    Close3dView: addPrefix('CLOSE_3D_VIEW'),
    Toggle3dView: addPrefix('TOGGLE_3D_VIEW'),
    UpdateBlockerEditState: addPrefix('UPDATE_BLOCKER_EDIT_STATE'),
    UpdateSelectedMapItem: addPrefix('UPDATE_SELECTED_MAP_ITEM'),
    UpdateSelectedCoverageArea: addPrefix('UPDATE_SELECTED_COVERAGE_AREA'),
    UpdateInstallationPoint: addPrefix('UPDATE_INSTALLATIONPOINT'),
    UpdateFloorPlanLocationChangeState: addPrefix('UPDATE_FLOOR_PLAN_LOCATION_CHANGE_STATE'),
    DefaultGeoLocationChanged: addPrefix('DEFAULT_GEOLOCATION_CHANGED'),
    SetPressedModifierKeys: addPrefix('SET_PRESSED_MODIFIER_KEYS'),
    SetDuplicationInfo: addPrefix('SET_DUPLICATION_INFO'),
    SetRadarCoexistingShowWarning: addPrefix('SET_RADAR_COEXISTING_SHOW_WARNING'),
    SetErrorMessage: addPrefix('SET_ERROR_MESSAGE'),
    SetMapViewBounds: addPrefix('SET_MAP_VIEW_BOUNDS'),
    ToggleColorLayer: addPrefix('TOGGLE_COLOR_LAYER'),
    ToggleDeviceTypeLayer: addPrefix('TOGGLE_DEVICE_TYPE_LAYER'),
    ToggleDeviceTypeCoverageAreaLayer: addPrefix('TOGGLE_DEVICE_TYPE_COVERAGE_AREA_LAYER'),
    ToggleLabelsLayer: addPrefix('TOGGLE_LABELS_LAYER'),
    ToggleTextBoxesLayer: addPrefix('TOGGLE_TEXT_BOXES_LAYER'),
    ToggleTinyIcons: addPrefix('TOGGLE_TINY_ICONS'),
    ToggleFreeTextTool: addPrefix('TOGGLE_FREE_TEXT_TOOL'),
    ToggleLayers: addPrefix('TOGGLE_LAYERS'),
    ToggleMeasureTool: addPrefix('TOGGLE_MEASURE_TOOL'),
    ToggleOverlayPanel: addPrefix('TOGGLE_DEVICE_OVERLAY_PANEL'),
    ToggleFloorPlanConfigOverlayPanel: addPrefix('TOGGLE_FLOORPLAN_CONFIG_OVERLAY_PANEL'),
    ToggleGeoLocationTool: addPrefix('TOGGLE_GEO_LOCATION_TOOL'),
    SetFocusedFloorPlanId: addPrefix('SET_FOCUSED_FLOOR_PLAN_ID'),
    UnsetFocusedFloorPlanId: addPrefix('UNSET_FOCUSED_FLOOR_PLAN_ID'),
    SetCurrentMapLocation: addPrefix('SET_CURRENT_MAP_LOCATION'),
    SetDesiredBounds: addPrefix('SET_DESIRED_BOUNDS'),
    SetOriginFilter: addPrefix('SET_ORIGIN_FILTER'),
};
