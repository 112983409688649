import { injectable } from 'inversify';
import type { IPiaPac } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { IPersistence, IItemEntity, IPacItemEntity } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type {
    IExportablePersistedEntity,
    IExportedPhysicalAccessController,
} from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import { toShareModelNames } from '../../mapToShareItemNames';

@injectable()
export class PacsExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaPac>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedPacs = (
        items: IExportablePersistedEntity[],
    ): IExportedPhysicalAccessController[] => {
        const allPacs = items.filter(this.isPacItemEntity);
        const mappedPacs = allPacs.map(this.mapPacToExportedPac);
        return mappedPacs.filter(isDefined);
    };

    private mapPacToExportedPac = (
        pacItem: IExportablePersistedEntity,
    ): IExportedPhysicalAccessController | null => {
        if (!pacItem.productId) {
            return null;
        }

        const pacPiaDevice = this.getPiaDevice(pacItem.productId);

        return {
            ...this.mapItemToExportedItemBase(pacItem),
            ...toShareModelNames(pacPiaDevice.name),
            piaId: pacItem.productId,
        };
    };

    private isPacItemEntity(item: IPersistence<IItemEntity>): item is IPersistence<IPacItemEntity> {
        return (
            (deviceTypeCheckers.isPac(item) && !deviceTypeCheckers.isDoorStation(item)) ||
            deviceTypeCheckers.isDoorController(item)
        );
    }
}
