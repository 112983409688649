import { t } from 'app/translate';
import { format } from 'axis-webtools-util';
import { createSelector } from 'reselect';
import type { IRecordingItemStats } from 'app/modules/recordingSelector';
import {
    getRecordingSolutionTotalSpecs,
    getRecordingSolutionRequirements,
} from 'app/modules/recordingSelector';
import type { Id } from 'app/core/persistence';
import { getDevicePowerRequirements } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { nbsp } from 'app/components';

const getItemIdFromProps = (_state: IStoreState, id: Id) => id;

const getCurrentProjectItemsState = (state: IStoreState) => state.currentProject.items;

export const getSystemRequirementsToDisplay = createSelector(
    [getRecordingSolutionRequirements, getRecordingSolutionTotalSpecs],
    (reqStats, totalSpecs: IRecordingItemStats) => [
        {
            title: t.channels,
            subTitle: nbsp,
            value: reqStats.nrOfChannels.toString(),
        },
        {
            title: t.bandwidth,
            subTitle: format
                .bandwidthRequirements(reqStats.bandwidth, totalSpecs.bandwidth)
                .split(' ')[3],
            value: format
                .bandwidthRequirements(reqStats.bandwidth, totalSpecs.bandwidth)
                .split(' ')[0],
        },
        {
            title: t.encoderSelectorAnalogCameraListHeaderStorage,
            subTitle: format
                .storageRequirements(reqStats.storage, totalSpecs.storage)
                .split(' ')[3],
            value: format.storageRequirements(reqStats.storage, totalSpecs.storage).split(' ')[0],
        },
        {
            title: t.accessoryDisplayCategoriesGROUP.power,
            subTitle: format.powerRequirements(reqStats.power, totalSpecs.power).split(' ')[3],
            value: format.powerRequirements(reqStats.power, totalSpecs.power).split(' ')[0],
        },
    ],
);

export const getRecordingSolutionSpecsToDisplay = createSelector(
    [getRecordingSolutionTotalSpecs, getRecordingSolutionRequirements],
    (totalSpecs, reqStats) => [
        {
            title: t.channels,
            subTitle: nbsp,
            value:
                totalSpecs.nrOfChannels === Number.POSITIVE_INFINITY
                    ? t.abbreviationsGROUP.notApplicable
                    : totalSpecs.nrOfChannels.toString(),
        },
        {
            title: t.bandwidth,
            subTitle: format.bandwidth(totalSpecs.bandwidth).split(' ')[1],
            value: format.bandwidth(totalSpecs.bandwidth).split(' ')[0],
        },
        {
            title: t.encoderSelectorAnalogCameraListHeaderStorage,
            subTitle: format
                .storageRequirements(totalSpecs.storage, reqStats.storage)
                .split(' ')[3],
            value: format.storageRequirements(totalSpecs.storage, reqStats.storage).split(' ')[0],
        },
        {
            title: t.accessoryDisplayCategoriesGROUP.power,
            subTitle: format.powerRequirements(reqStats.power, totalSpecs.power).split(' ')[3],
            value: format.powerRequirements(totalSpecs.power, reqStats.power).split(' ')[0],
        },
    ],
);

export const getDevicePower = createSelector(
    [getItemIdFromProps, getDevicePowerRequirements, getCurrentProjectItemsState],
    (id, powerRequirements, items) => {
        const quantity = items[id]?.quantity ?? 1;
        const powerRequirement = Math.round(powerRequirements[id] * quantity * 10) / 10;
        if (powerRequirement) {
            return {
                title: t.accessoryDisplayCategoriesGROUP.power,
                value: String(powerRequirement),
                subTitle: 'W',
            };
        } else return undefined;
    },
);
