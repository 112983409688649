import { Container } from 'inversify';
import { ContainerBinder } from './ContainerBinder';

export function createContainer(bind: (binder: ContainerBinder) => void) {
    const container = new Container();
    const binder = new ContainerBinder(container);

    bind(binder);

    return container;
}
