import * as React from 'react';
import type { Colors, Overflow } from 'app/styles';
import type { IExtendableComponentWithChildren } from '../../models';
import type { BoxValue } from '../../containers/box/Box.component';
import { Box } from '../../containers/box/Box.component';
import type { IAutoTestable } from '../../ui-test';
import { PrintFix } from '../../functional';

interface IContentPanel extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * The color of the panel
     */
    color?: Colors;
    /**
     * The height of the panel
     */
    height?: BoxValue;
    /**
     * The min height
     */
    minHeight?: BoxValue;
    /**
     * The horizontal overflow behavior
     */
    overflowX?: Overflow;
    /**
     * The vertical overflow behavior
     */
    overflowY?: Overflow;
    /**
     * Whether or not text within the component can be selected by user
     */
    userSelect?: boolean;
}

/**
 * A box with limited props. Used as a container for main content views.
 */
export class ContentPanel extends React.Component<IContentPanel> {
    public render() {
        const {
            color,
            overflowX,
            overflowY,
            height,
            minHeight,
            userSelect,
            children,
            ...attributes
        } = this.props;
        return (
            <PrintFix>
                <Box
                    testId={this.props.testId}
                    display="block"
                    color={color}
                    overflowX={overflowX || 'hidden'}
                    overflowY={overflowY || 'auto'}
                    height={height || '100%'}
                    minHeight={minHeight}
                    padding="base"
                    width="100%"
                    position="relative"
                    userSelect={userSelect}
                    {...attributes}
                >
                    {children}
                </Box>
            </PrintFix>
        );
    }
}
