import { createSelector } from 'reselect';
import { getSelectedDeviceId } from './getSelectedId';
import { getCurrentProjectItems } from './getCurrentProjectItems';
import { getSelectedInstallationPoint } from './getSelectedInstallationPoint';
import { getAllValidMapDevices } from './getListableDevices';
import type { IMapsDevice } from '../models';
import { createCachedSelector } from 're-reselect';
import { getIdFromProps, toCacheKey } from 'app/modules/common';
import {
    getDerotatedInstallationPoints,
    getDerotatedInstallationPointsArray,
    getSelectedMapItem,
} from './getProjectInstallationPoints';
import { getParentId } from 'app/core/persistence';

const getSelectedDeviceOrUndefined = createSelector(
    [getSelectedDeviceId, getCurrentProjectItems],
    (id, devices) => (id ? devices[id] : undefined),
);

export const getParentDeviceForIpId = createCachedSelector(
    [getCurrentProjectItems, getDerotatedInstallationPoints, getIdFromProps],
    (devices, installationPoints, id) => {
        if (!id) return undefined;
        const installationPoint = installationPoints[id];
        if (!installationPoint) return undefined;
        const parentId = getParentId(installationPoint);
        return parentId ? devices[parentId] : undefined;
    },
)(toCacheKey);

/**
 * Get the installation points that should be in focus based on
 * the currently selected device or installation point
 */
export const getFocusedInstallationPoints = createSelector(
    [
        getSelectedDeviceOrUndefined,
        getSelectedInstallationPoint,
        getDerotatedInstallationPointsArray,
    ],
    (selectedDevice, selectedInstallationPoint, installationPoints) => {
        if (selectedDevice) {
            return installationPoints.filter((ip) => ip.parentDevice._id === selectedDevice._id);
        }
        if (selectedInstallationPoint) {
            return installationPoints.filter(
                (ip) => ip.parentDevice._id === selectedInstallationPoint.parentDevice._id,
            );
        }
        return installationPoints;
    },
);

export const getSelectedDeviceOrParentDevice = createSelector(
    [getSelectedDeviceOrUndefined, getSelectedInstallationPoint, getAllValidMapDevices],
    (selectedDevice, selectedInstallationPoint, devices): IMapsDevice | undefined => {
        if (selectedDevice) {
            return devices.find((device) => device.id === selectedDevice._id);
        }
        if (selectedInstallationPoint) {
            return devices.find(
                (device) => device.id === selectedInstallationPoint.parentDevice._id,
            );
        }
    },
);

export const getIsFocused = createCachedSelector(
    [getSelectedMapItem, getIdFromProps, getFocusedInstallationPoints],
    (selectedMapItem, installationPointId, installationPoints) => {
        if (!selectedMapItem || installationPoints.find((ip) => ip._id === installationPointId)) {
            return true;
        }
        return false;
    },
)(toCacheKey);
