export const NORTH_AMERICA_COUNTRY_CODES = [
    37, // Canada
    230, // USA
];
export const SOUTH_AMERICA_COUNTRY_CODES = [
    9, //  Argentina
    26, // Bolivia
    30, // Brazil
    42, // Chile
    46, // Colombia
    62, // Ecuador
    74, // French Guinea
    91, // Guyana
    167, // Paraguay
    168, // Peru
    205, // Suriname
    231, // Uruguay
    234, // Venezuela
];
export const MIDDLE_AMERICA_COUNTRY_CODES = [
    22, // Belize
    51, // Costa Rica
    64, // El Salvador
    87, // Guatemala
    94, // Honduras
    139, // Mexico
    156, // Nicaragua
    165, // Panama
];
export const CARIBBEAN_COUNTRY_CODES = [
    7, // Anguilla
    8, // Antigua and Barbuda
    16, // Bahamas
    19, // Barbados
    39, // Cayman Islands
    60, // Dominica
    61, // Dominican Republic
    84, // Grenada
    85, // Guadeloupe
    92, // Haiti
    106, // Jamaica
    136, // Martinique
    173, // Puerto Rico
    179, // Saint Barthélemy
    182, // Saint Kitts & Nevis
    183, // Saint Lucia
    184, // Saint Martin (French part)
    186, // Saint Vincent and the Grenadines
    196, // Sint Maarten (Dutch part)
    220, // Trinidad and Tobago
    224, // Turks and Caicos Islands
    236, // Virgin Islands
];
export const OCEANIA_COUNTRY_CODES = [
    12, // Australia
    44, // Christmas Island
    45, // Cocos (Keeling) Islands
    50, // Cook Islands
    71, // Fiji
    86, // Guam
    112, // Kiribati
    135, // Marshall Islands
    140, // Micronesia, Federated States of
    150, // Nauru
    155, // New Zealand
    159, // Niue
    164, // Palau
    166, // Papua New Guinea
    170, // Pitcairn
    187, // Samoa
    199, // Solomon Islands
    218, // Tokelau
    219, // Tonga
    225, // Tuvalu
    233, // Vanuatu
];
export const JAPAN_COUNTRY_CODES = [
    107, // Japan
];
export const NORTH_ASIA_COUNTRY_CODES = [
    43, // China
    95, // Hong kong
    126, // Macau
    143, // Mongolia
    113, // Korea, Democratic People's Republic of
    114, // Korea, Republic of
    212, // Taiwan
];
export const SOUTH_ASIA_PACIFIC_COUNTRY_CODES = [
    18, // Bangladesh
    25, // Bhutan
    31, // Brunei Darussalam
    35, // Cambodia
    98, // India
    99, // Indonesia
    117, // Lao People's Democratic Republic
    131, // Malaysia
    132, // Maldives
    148, // Myanmar
    151, // Nepal
    169, // Philippines
    195, // Singapore
    203, // Sri Lanka
    215, // Thailand
    216, // Timor-Leste
    235, // Viet Nam
];
