import type { PanoramaModes } from 'app/core/persistence';
import type { IPiaCameraProperties } from 'app/core/pia';

const getMaxHorizontalFov = (
    piaCameraProperties: Pick<
        IPiaCameraProperties,
        'imageSensors' | 'panCameraType' | 'maxLensCalcFOV' | 'maxHorizontalFOV'
    >,
) => {
    const hasMultipleSensors = piaCameraProperties.imageSensors || 0 > 1;
    const isMultiSensor = piaCameraProperties.panCameraType === 'Multisensor';

    return hasMultipleSensors && !isMultiSensor && piaCameraProperties.maxLensCalcFOV
        ? piaCameraProperties.maxLensCalcFOV
        : piaCameraProperties.maxHorizontalFOV;
};

export const isSelectedCameraSupportedByEditCameraItem = (
    selectedCameraProperties: Pick<
        IPiaCameraProperties,
        | 'imageSensors'
        | 'corridorFormat'
        | 'panCameraType'
        | 'maxLensCalcFOV'
        | 'maxHorizontalFOV'
        | 'defaultPanoramaMode'
    >,
    editPanoramaMode: PanoramaModes,
    editCorridorFormat: boolean,
    editHorizontalFov: number,
) => {
    const isSelectedHorizontalPanorama =
        selectedCameraProperties.defaultPanoramaMode === 'horizontal';
    if (isSelectedHorizontalPanorama) {
        return true;
    }

    const isSelectedVerticalPanorama = selectedCameraProperties.defaultPanoramaMode === 'vertical';
    if (
        isSelectedVerticalPanorama &&
        (editPanoramaMode === 'vertical' || editPanoramaMode === false)
    ) {
        return true;
    }

    const isEditHorizontalPanorama = editPanoramaMode === 'horizontal';
    if (isEditHorizontalPanorama) {
        return isSelectedHorizontalPanorama;
    }

    const maxHorizontalFOV = getMaxHorizontalFov(selectedCameraProperties);
    const corridorFormatCovered =
        editCorridorFormat === false ||
        editCorridorFormat === selectedCameraProperties.corridorFormat;

    const fovIsCovered = maxHorizontalFOV >= editHorizontalFov;

    return corridorFormatCovered && fovIsCovered;
};
