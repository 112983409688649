import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import { isEmpty } from 'lodash-es';

const getUserIsAuth = (storeState: IStoreState) => storeState.common.msrpAuthStatus;
const getMsrpDistributors = (storeState: IStoreState) => storeState.common.distributors;
const getPriceLists = (state: IStoreState) => state.common.msrp;

/**
 * If the user should see any MSRP related information is decided in a combination if the user has MSRP access
 * and if the user has access to any non-empty price lists.
 */
export const getUserShouldSeeMsrp = createSelector(
    [getUserIsAuth, getMsrpDistributors, getPriceLists],
    (userIsAuth, msrpDistributors, priceLists) => {
        return (
            userIsAuth &&
            msrpDistributors != null &&
            priceLists != null &&
            msrpDistributors.list.some((distributor) => {
                const priceList = priceLists.find(({ locid }) => locid === distributor.locid);
                return priceList != null && !isEmpty(priceList.list);
            })
        );
    },
);
