import { t } from 'app/translate';
import { createSelector } from 'reselect';
import { getCurrentProjectUnitSystem } from '../../project';

export const getPixelsPerUnitSystemAbbreviation = createSelector(
    [getCurrentProjectUnitSystem],
    (unitSystem) => {
        const distanceUnit =
            unitSystem === 'metric' ? t.abbreviationsGROUP.meter : t.abbreviationsGROUP.feet;

        return ` ${t.abbreviationsGROUP.pixel}/${distanceUnit}`;
    },
);
