import { deviceTypeCheckers } from 'app/core/persistence';
import { desiredCameraUtils, getCurrentProjectUnitSystem } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { getEditItem } from './getEditItem';

const getDesiredCamera = (state: IStoreState) => state.deviceSelector.cameraFilter.desiredCamera;
const getMainUnitFilter = (state: IStoreState) => state.deviceSelector.mainUnitFilter;
const getEncoderFilter = (state: IStoreState) => state.deviceSelector.encoderFilter;
const getSpeakerFilter = (state: IStoreState) => state.deviceSelector.speakerFilter;
const getDesiredSensorUnit = (state: IStoreState) =>
    state.deviceSelector.sensorUnitFilter.desiredSensorUnit;

/**
 * Checks if device selector filter has changed selecting an item to edit.
 * Compares current filter to filter persisted on item.
 */
export const getFilterHasChanged = createSelector(
    [
        getEditItem,
        getDesiredCamera,
        getMainUnitFilter,
        getEncoderFilter,
        getSpeakerFilter,
        getDesiredSensorUnit,
        getCurrentProjectUnitSystem,
    ],
    (
        editItem,
        desiredCamera,
        mainUnitFilter,
        encoderFilter,
        speakerFilter,
        desiredSensorUnit,
        unitSystem,
    ) => {
        if (!editItem) {
            return false;
        }

        if (deviceTypeCheckers.isCamera(editItem)) {
            const convertedDesiredCamera =
                desiredCameraUtils.convertDesiredCameraToFilterProperties(
                    desiredCamera,
                    desiredCamera.installationHeight,
                    undefined,
                    unitSystem,
                );

            return !isEqual(editItem.properties.camera.filter, convertedDesiredCamera);
        }

        if (deviceTypeCheckers.isMainUnit(editItem)) {
            //* If channels prop is missing, sets it explicitly to undefined to match format of mainUnitFilter
            const itemFilter = {
                ...editItem.properties.mainUnit.filter,
                channels: editItem.properties.mainUnit.filter.channels,
            };

            return !isEqual(mainUnitFilter, itemFilter);
        }

        if (deviceTypeCheckers.isEncoder(editItem)) {
            //* If channels prop is missing, sets it explicitly to undefined to match format of encoderFilter
            const itemFilter = {
                ...editItem.properties.encoder.filter,
                channels: editItem.properties.encoder.filter.channels,
            };

            return !isEqual(encoderFilter, itemFilter);
        }

        if (deviceTypeCheckers.isSpeaker(editItem)) {
            const originalItemFilter = editItem.properties.speaker.filter;

            //* Sets listening area and wall length explicitly to undefined if missing to match the format of speaker filter.
            const convertedItemFilter = {
                ...originalItemFilter,
                listeningArea: originalItemFilter.listeningArea,
                wallLength: originalItemFilter.wallLength,
            };

            return !isEqual(convertedItemFilter, speakerFilter);
        }

        if (deviceTypeCheckers.isSensorUnit(editItem)) {
            const convertedDesiredSensorUnit =
                desiredCameraUtils.convertDesiredCameraToFilterProperties(
                    desiredSensorUnit,
                    desiredSensorUnit.installationHeight,
                    undefined,
                    unitSystem,
                );

            return !isEqual(editItem.properties.sensorUnit.filter, convertedDesiredSensorUnit);
        }

        return false;
    },
);
