import { createCachedSelector } from 're-reselect';
import { getPiaCameraForProductId, toCacheKey } from 'app/modules/common';
import { getBestReplacementLens } from './getBestReplacementLens';
import { getCameraFilter } from './getProductsForDeviceGroup';
import { getFovAndPixelDensityFulfilled } from '../utils';
import type { IPiaAccessory } from 'app/core/pia';
import { getCurrentlyUsedLenses } from './getCurrentlyUsedLenses';

export const getCurrentlyUsedAdequateLenses = createCachedSelector(
    [getBestReplacementLens, getCameraFilter, getPiaCameraForProductId, getCurrentlyUsedLenses],
    (bestLens, cameraFilter, piaCamera, lensesOnEditItem): IPiaAccessory[] | undefined => {
        if (!lensesOnEditItem || !piaCamera) {
            return undefined;
        }

        const allLensesMatchRequirements = lensesOnEditItem.every((piaAccLens) => {
            // Best lens is always matching requirements so no need for recalculating requirements
            if (bestLens && piaAccLens.id === bestLens.id) {
                return true;
            }
            const piaRelationReference = piaCamera.relations.find(({ id }) => id === piaAccLens.id);
            if (piaRelationReference === undefined) {
                return false;
            }

            return getFovAndPixelDensityFulfilled(
                cameraFilter.desiredCamera,
                piaRelationReference,
                piaCamera.properties.maxVideoResolutionHorizontal,
            );
        });

        // Every lens must match requirements
        if (allLensesMatchRequirements === false) {
            return undefined;
        }

        return lensesOnEditItem;
    },
)(toCacheKey);
