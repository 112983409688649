import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { toCacheKey } from '../../cacheKey/toCacheKey';

/**
 * Get the analytic range for an item id
 * @param itemId the id of the item
 * @returns the analytic range
 */
export const getAnalyticRangeForItem = createCachedSelector([getCurrentProjectItem], (item) => {
    if (!item || item.productId === null) return undefined;

    return item.analyticRange;
})(toCacheKey);
