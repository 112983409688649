import { PartnerSystemItemCategory } from 'app/core/partner';
import {
    PiaItemRecorderCategory,
    PiaAccessoryCategory,
    PiaItemSoftwareCategory,
    PiaItemNonExistentCategory,
} from 'app/core/pia';
import type { IItemWithCategoryAndName } from './ItemWithCategoryAndName';
import { nameComparator } from './nameComparator';

const categoryMapping: Record<string, string> = {
    [PiaItemRecorderCategory.RECORDERS2]: '1', // servers
    [PartnerSystemItemCategory.SERVER]: '2', //partner servers
    [PartnerSystemItemCategory.MISC]: '3', //partner misc
    [PiaItemRecorderCategory.DESKTOPTERMINALS]: '4', // terminals
    [PiaAccessoryCategory.NETWORKSWITCHES]: '5', // switches
    [PiaItemSoftwareCategory.VMS]: '6', // licenses
    [PiaAccessoryCategory.STORAGE]: '7', // sd-cards
    [PiaItemNonExistentCategory.NON_EXISTING_PRODUCT]: '1000', // don't crash on non-existent products
};

export const recorderCategoryComparator = (
    a: IItemWithCategoryAndName,
    b: IItemWithCategoryAndName,
) => categoryMapping[a.category].localeCompare(categoryMapping[b.category]) || nameComparator(a, b);
