import {
    CategoryEnum,
    getProductsWithPrices,
    quotationFilter,
    toCacheKey,
    VendorEnum,
} from 'app/modules/common';
import { nameComparator } from 'app/utils';
import { UnreachableCaseError } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { getSectionFromProps } from './getSectionFromProps';

const getProductsForSection = createCachedSelector(
    [getProductsWithPrices, getSectionFromProps],
    (products, section) => {
        switch (section) {
            case 'axis':
                return products.filter(quotationFilter.byVendor(VendorEnum.Axis));
            case 'installationServices':
                return products.filter(
                    quotationFilter.byCategories(CategoryEnum.InstallationService),
                );
            case 'other':
            case 'products':
                let filteredProducts = products.filter(
                    quotationFilter.byCategories(
                        CategoryEnum.Camera,
                        CategoryEnum.Encoder,
                        CategoryEnum.FSeries,
                        CategoryEnum.RecordingAndNetwork,
                        CategoryEnum.Speaker,
                        CategoryEnum.Software,
                        CategoryEnum.Pac,
                        CategoryEnum.Decoder,
                        CategoryEnum.RadarDetector,
                        CategoryEnum.Miscellaneous,
                        CategoryEnum.DoorStation,
                        CategoryEnum.Accessory,
                        CategoryEnum.Wearables,
                        CategoryEnum.DoorControllers,
                        CategoryEnum.Alerters,
                        CategoryEnum.PeopleCounters,
                        CategoryEnum.PagingConsole,
                    ),
                );

                if (section === 'other') {
                    filteredProducts = filteredProducts.filter(
                        quotationFilter.byVendors(
                            VendorEnum.twoN,
                            VendorEnum.Other,
                            VendorEnum.Genetec,
                        ),
                    );
                }

                return filteredProducts;
            default:
                throw new UnreachableCaseError(section);
        }
    },
)(toCacheKey);

export const getSortedProductsForSection = createSelector([getProductsForSection], (products) =>
    products.sort(nameComparator),
);
