import { createSelector } from 'reselect';
import type { Id, IInstallationPointEntity, IPersistence } from 'app/core/persistence';
import { getParentId } from 'app/core/persistence';
import { getInstallationPointsArray } from './getInstallationPoints';
import { getCurrentProjectInstallationPointsArray, getCurrentProjectItem } from '../../project';
import { creationDateReverseComparator, idComparator } from 'app/utils';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';

/** Gets a record of installation points by parent device id */
export const getInstallationPointsByParentIdRecord = createSelector(
    [getInstallationPointsArray],
    (installationPoints) => {
        const ipByParentIdRecord: Record<Id, IPersistence<IInstallationPointEntity>[]> = {};
        installationPoints.forEach((ip) => {
            const parentId = getParentId(ip);

            if (parentId) {
                ipByParentIdRecord[parentId] = [...(ipByParentIdRecord[parentId] ?? []), ip];
            }
        });
        return ipByParentIdRecord;
    },
);

export const getInstallationPointsPerDeviceSortedByCreation = createSelector(
    [getCurrentProjectInstallationPointsArray],
    (installationPoints) => {
        const sortedInstallationPoints = installationPoints
            .sort(idComparator)
            .sort(creationDateReverseComparator);
        return sortedInstallationPoints.reduce(
            (installationPointsPerDevice, installationPoint) => {
                const deviceId = getParentId(installationPoint);
                if (!deviceId) return installationPointsPerDevice;

                installationPointsPerDevice[deviceId] = [
                    ...(installationPointsPerDevice[deviceId] ?? []),
                    installationPoint,
                ];
                return installationPointsPerDevice;
            },
            {} as Record<string, IInstallationPointEntity[]>,
        );
    },
);

export const getUnplacedDevicesQuantity = createCachedSelector(
    [getInstallationPointsByParentIdRecord, getCurrentProjectItem],
    (installationPointsForDeviceRecord, item): number => {
        if (!item) return 0;
        const installationPointsQuantity = installationPointsForDeviceRecord[item._id]?.length ?? 0;
        return item.quantity - installationPointsQuantity;
    },
)(toCacheKey);
