import type { Vector3 } from 'three';
import { BufferAttribute, BufferGeometry } from 'three';

export const getFovGeometry = (
    topLeft: Vector3,
    topRight: Vector3,
    bottomRight: Vector3,
    bottomLeft: Vector3,
): BufferGeometry => {
    const INFINITY = 10000;
    const geometry = new BufferGeometry();

    const camVertex = [0, 0, 0];
    const topLeftVertex = topLeft.clone().multiplyScalar(INFINITY).toArray();
    const topRightVertex = topRight.clone().multiplyScalar(INFINITY).toArray();
    const bottomLeftVertex = bottomLeft.clone().multiplyScalar(INFINITY).toArray();
    const bottomRightVertex = bottomRight.clone().multiplyScalar(INFINITY).toArray();

    const vertices = new Float32Array([
        ...camVertex,
        ...topLeftVertex,
        ...topRightVertex,

        ...camVertex,
        ...topRightVertex,
        ...bottomRightVertex,

        ...camVertex,
        ...bottomRightVertex,
        ...bottomLeftVertex,

        ...camVertex,
        ...bottomLeftVertex,
        ...topLeftVertex,
    ]);

    geometry.setAttribute('position', new BufferAttribute(vertices, 3));

    return geometry;
};
