import * as React from 'react';
import * as DOMPurify from 'dompurify';
import type { PiaId } from 'app/core/pia';
import { Box, CutCorner, Stack, Text, TextPlaceholder } from 'app/components';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import { ColorsEnum } from 'app/styles';
import { usePromise } from 'app/hooks';
import { DeviceDescriptionService } from 'app/core/persistence';

interface IProductHighlightsProps {
    productId: PiaId;
}

export const ProductHighlights: React.FunctionComponent<IProductHighlightsProps> = ({
    productId,
}) => {
    const deviceDescriptionService = useService(DeviceDescriptionService);
    const style = {
        color: ColorsEnum.yellow6,
    };
    const descriptionPromise = React.useMemo(
        () => deviceDescriptionService.getBulletPoints(productId),
        [deviceDescriptionService, productId],
    );
    const { result: bullets, pending: loading } = usePromise(descriptionPromise, [
        descriptionPromise,
    ]);

    const content = loading ? (
        <TextPlaceholder rows={5} />
    ) : bullets && bullets.length > 0 ? (
        <Stack spacing="half" vertical>
            {bullets.map((bullet, i) => (
                <Stack key={i} spacing="halfQuart">
                    <li style={style}>
                        <Text onlyStyle style="body">
                            <span
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bullet) }}
                            ></span>
                        </Text>
                    </li>
                </Stack>
            ))}
        </Stack>
    ) : null;

    return bullets && bullets.length ? (
        <CutCorner color="yellow">
            <Box padding="panel">
                <Stack vertical>
                    <Text style="heading">{t.productHighlights}</Text>
                    {content}
                </Stack>
            </Box>
        </CutCorner>
    ) : null;
};

ProductHighlights.displayName = 'ProductHighlights';
