import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../models';
import { extendableProps, renderReactChildren } from '../services';
import { css } from '@emotion/css';

interface IMargin extends IExtendableComponentWithChildren {
    marginTop?: React.CSSProperties['marginBlockStart'];
    marginLeft?: React.CSSProperties['marginInlineStart'];
    marginRight?: React.CSSProperties['marginInlineEnd'];
    marginBottom?: React.CSSProperties['marginBlockEnd'];
}
/**
 * Sets margins around the children
 */
export class Margin extends React.Component<IMargin> {
    public render() {
        const { children, marginLeft, marginRight, marginTop, marginBottom, ...extendedProps } =
            this.props;
        const attributes = extendableProps(extendedProps, {
            className: css`
                margin-inline-start: ${marginLeft};
                margin-inline-end: ${marginRight};
                margin-block-start: ${marginTop};
                margin-block-start: ${marginBottom};
            `,
        });
        return renderReactChildren(
            children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes.__htmlAttributes),
        );
    }
}
