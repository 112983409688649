import { isDefined } from 'axis-webtools-util';
import type { IStoreState } from 'app/store';
import type { IContactDetails } from '../models';
import { getLocationsState } from '../locations';

interface IContractDetailsWithCountryName extends IContactDetails {
    countryName?: string;
    usStateAbbreviation?: string;
}

const getCountryName = (
    state: IStoreState,
    countryCode: string | undefined,
): string | undefined => {
    if (!countryCode) {
        return undefined;
    }

    const locations = getLocationsState(state);
    const country = locations.find(
        (location) => location.countryCode.toLowerCase() === countryCode.toLowerCase(),
    );

    return country?.name;
};

const getUsStateAbbreviation = (usState: string | undefined): string | undefined =>
    usState ? usState.replace(/^\w{2}-/, '').toUpperCase() : undefined;

const prepareForFormatting = (
    state: IStoreState,
    details: IContactDetails | null,
): IContractDetailsWithCountryName | null => {
    if (!details) {
        return null;
    }

    return {
        ...details,
        usStateAbbreviation: getUsStateAbbreviation(details.usState),
        countryName: getCountryName(state, details.country),
    };
};

const toAddressLine = (...strings: Array<string | undefined>): string => {
    const cleanStrings = strings.filter(isDefined);

    return cleanStrings.length > 0 ? cleanStrings.join(' ') + '\n' : '';
};

const formatUSAddress = (details: IContractDetailsWithCountryName): string =>
    [
        toAddressLine(details?.companyName),
        toAddressLine(details?.firstName, details?.lastName),
        toAddressLine(details?.streetAddress),
        toAddressLine(details?.city, details?.usStateAbbreviation, details?.zipCode),
        toAddressLine(details?.countryName),
        toAddressLine(details?.phoneNumber),
    ].join('');

const formatOtherAddress = (details: IContractDetailsWithCountryName): string =>
    [
        toAddressLine(details?.companyName),
        toAddressLine(details?.firstName, details?.lastName),
        toAddressLine(details?.streetAddress),
        toAddressLine(details?.zipCode, details?.city),
        toAddressLine(details?.countryName),
        toAddressLine(details?.phoneNumber),
    ].join('');

const formatAddress = (details: IContractDetailsWithCountryName | null): string => {
    if (!details) {
        return '';
    }

    if (details?.country === 'US' || details?.usStateAbbreviation) {
        return formatUSAddress(details);
    } else {
        return formatOtherAddress(details);
    }
};

export const getContactDetails = (state: IStoreState) => {
    const address =
        state.common.contactDetails.contactDetails?.address ??
        formatAddress(prepareForFormatting(state, state.common.contactDetails.contactDetails));
    const userApiAddress = formatAddress(
        prepareForFormatting(state, state.common.contactDetails.userApiContactDetails),
    );

    return {
        address,
        userApiAddress,
    };
};

export const getCompanyName = (state: IStoreState) => getMergedContactDetails(state).companyName;

const getMergedContactDetails = (state: IStoreState): IContactDetails => ({
    ...state.common.contactDetails.userApiContactDetails,
    ...state.common.contactDetails.contactDetails,
});
