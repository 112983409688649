import type { IZipstreamSettingsEntity, ZipProfile } from 'app/core/persistence';
import { GopMode } from 'app/core/persistence';

import { t } from 'app/translate';
import { invert } from 'lodash-es';
import type { ZipStrength } from '../profile/models/ZipStrengths';
import { zipStrengths } from '../profile/models/ZipStrengths';
import {
    getGlobalUseAverageBitrate,
    getProjectZipDynamicFps,
    getProjectZipDynamicGop,
    getProjectZipStrength,
} from './getProjectZipSettings';

// if globalzipsetting is used get the values for the corresponding step-setting
export const getZipStrengthValue = (
    zipstreamSettings: IZipstreamSettingsEntity,
    projectZipSetting: number,
) => {
    return zipstreamSettings.useProjectSetting
        ? getProjectZipStrength(projectZipSetting)
        : zipstreamSettings.zipStrength;
};

export const getZipProfileValue = (zipstreamSettings: IZipstreamSettingsEntity): ZipProfile => {
    return zipstreamSettings.useProjectSetting ? 'classic' : zipstreamSettings.zipProfile;
};

export const getGopMode = (
    zipstreamSettings: IZipstreamSettingsEntity,
    projectZipSetting: number,
) => {
    return zipstreamSettings.useProjectSetting
        ? getProjectZipDynamicGop(projectZipSetting)
        : zipstreamSettings.gopMode;
};

export const getDynamicFpsMode = (
    zipstreamSettings: IZipstreamSettingsEntity,
    projectZipSetting: number,
) => {
    return zipstreamSettings.useProjectSetting
        ? getProjectZipDynamicFps(projectZipSetting)
        : zipstreamSettings.fpsMode;
};

export const getUseAverageBitrate = (
    continuousAlwaysScheduleUsed: boolean,
    zipstreamSettings: IZipstreamSettingsEntity,
    projectZipSetting: number,
    useAverageBitrate?: boolean,
) => {
    if (!continuousAlwaysScheduleUsed) {
        return false;
    }
    return zipstreamSettings.useProjectSetting
        ? getGlobalUseAverageBitrate(projectZipSetting)
        : useAverageBitrate;
};

export const getZipSteamReportString = (
    zipstreamSettings: IZipstreamSettingsEntity,
    projectZipSetting: number,
    zipStreamIsSupported: boolean,
    gopIsApplicable: boolean,
) => {
    // if globalzipsetting is used get the values for the corresponding step-setting
    const settingsZipStrength = zipstreamSettings.useProjectSetting
        ? getProjectZipStrength(projectZipSetting)
        : zipstreamSettings.zipStrength;

    const settingsZipProfile = zipstreamSettings.useProjectSetting
        ? projectZipSetting
        : zipstreamSettings.zipProfile;

    const settingsGopMode = zipstreamSettings.useProjectSetting
        ? getProjectZipDynamicGop(projectZipSetting)
        : zipstreamSettings.gopMode;

    const settingsFpsMode = zipstreamSettings.useProjectSetting
        ? getProjectZipDynamicFps(projectZipSetting)
        : zipstreamSettings.fpsMode;

    // zipStrength === 0 represents zipStream being turned off
    const zipStrength =
        t.zipStrengthsGROUP[invert(zipStrengths)[settingsZipStrength] as ZipStrength];

    // bandwidth - concatenate the values for zipStrength,
    const zipStrengthString = zipStreamIsSupported
        ? `${t.installationReportSettingsZipStrength}: ${zipStrength}`
        : '';

    const zipProfileString =
        zipStreamIsSupported && settingsZipProfile === 'storage' ? `, ${t.storageProfile}` : '';

    const gopModeString = gopIsApplicable
        ? settingsGopMode === GopMode.Dynamic
            ? zipStrengthString
                ? `, ${t.dynamicGOP}`
                : `${t.dynamicGOP}`
            : ''
        : '';

    const dynamicFpsString =
        settingsFpsMode === 'dynamic'
            ? zipStrengthString || gopModeString
                ? `, ${t.dynamicFps}`
                : t.dynamicFps
            : '';

    const zipStreamString = `${zipStrengthString}${zipProfileString}${gopModeString}${dynamicFpsString}`;

    return zipStreamString;
};

export const getZipStrengthReportString = (
    zipstreamSettings: IZipstreamSettingsEntity,
    projectZipSetting: number,
    zipStreamIsSupported: boolean,
) => {
    if (!zipStreamIsSupported) {
        return '-';
    }
    // if globalzipsetting is used get the values for the corresponding step-setting
    const settingsZipStrength = zipstreamSettings.useProjectSetting
        ? getProjectZipStrength(projectZipSetting)
        : zipstreamSettings.zipStrength;
    const zipStrength =
        t.zipStrengthsGROUP[invert(zipStrengths)[settingsZipStrength] as ZipStrength];
    return zipStrength;
};
