import * as React from 'react';

import { Box } from '../box/Box.component';
import { Border } from '../../style';
import type { Spacings, Colors, Flex, Direction } from 'app/styles';
import type { IExtendableComponentWithChildren } from '../../models';
import type { IAutoTestable } from 'app/components/ui-test';

interface ICardProps extends IExtendableComponentWithChildren, IAutoTestable {
    minWidth?: string;
    maxWidth?: string;
    maxHeight?: string;
    minHeight?: string;
    paddingX?: Spacings;
    paddingY?: Spacings;
    color?: Colors;
    borderColor?: Colors;
    borderWidth?: number;
    borderRadius?: string;
    hideOverflow?: boolean;
    flex?: Flex;
    /**
     * By default the card will span 100% width.
     * Setting this will disable this feature.
     */
    notFullWidth?: boolean;
    shadow?: boolean;
    direction?: Direction;
}

export const Card: React.FunctionComponent<ICardProps> = ({
    children,
    paddingX,
    paddingY,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    hideOverflow,
    color,
    borderColor,
    borderWidth,
    borderRadius,
    flex,
    notFullWidth,
    shadow,
    direction,
    testId,
    ...extendedProps
}) => (
    <Border
        color={borderColor ?? 'grey3'}
        width={borderWidth}
        radius={borderRadius ?? '8px'}
        {...extendedProps}
        shadow={shadow ? '0 4px 10px rgba(0,0,0,0.2)' : undefined}
    >
        <Box
            testId={testId}
            direction={direction}
            minWidth={minWidth}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            minHeight={minHeight}
            width={notFullWidth ? undefined : '100%'}
            color={color ? color : 'white'}
            overflow={hideOverflow ? 'hidden' : undefined}
            paddingX={paddingX}
            paddingY={paddingY}
            flex={flex ?? 'dontShrink'}
        >
            {children}
        </Box>
    </Border>
);

Card.displayName = 'Card';
