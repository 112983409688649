import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id, IPersistence, IScheduleEntity } from 'app/core/persistence';
import { ScheduleService } from 'app/core/persistence';
import {
    Box,
    Text,
    Stack,
    Icon,
    DropDown,
    DropDownMenuButton,
    DropDownMenuDelete,
    Clickable,
    EditableText,
} from 'app/components';
import { t } from 'app/translate';
import {
    getInvertedScheduleFromOriginalId,
    getTimeSerieAbbreviationsPerSchedule,
    getUsedScheduleIds,
} from './selectors';
import { ServiceLocator } from 'app/ioc';
import { AppConstants } from 'app/AppConstants';
import { getCurrentProjectSchedules } from '../common';

interface IScheduleListItemProps {
    scheduleId: Id;
    onEditSchedule(scheduleId: Id): void;
}

export const ScheduleListItem: React.FC<IScheduleListItemProps> = ({
    scheduleId,
    onEditSchedule,
}) => {
    const schedule = useSelector<IStoreState, IPersistence<IScheduleEntity> | undefined>(
        (storeState) => getCurrentProjectSchedules(storeState)[scheduleId],
    );
    const [scheduleService] = React.useState(ServiceLocator.get(ScheduleService));
    const usedSchedules = useSelector<IStoreState, Id[]>(getUsedScheduleIds);
    const invertedSchedule = useSelector<IStoreState, IPersistence<IScheduleEntity> | undefined>(
        (storeState) => getInvertedScheduleFromOriginalId(storeState, scheduleId),
    );
    const allTimeSerieAbbreviations = useSelector<IStoreState, Record<Id, string[]>>(
        getTimeSerieAbbreviationsPerSchedule,
    );
    const timeSerieAbbreviations = allTimeSerieAbbreviations[scheduleId];
    const invertedTimeSerieAbbreviations = invertedSchedule
        ? allTimeSerieAbbreviations[invertedSchedule._id]
        : [];

    const [scheduleName, setScheduleName] = React.useState(schedule?.name ?? '');
    const inUse = usedSchedules.includes(scheduleId);
    const invertedScheduleInUse = invertedSchedule
        ? usedSchedules.includes(invertedSchedule._id)
        : false;

    React.useEffect(() => setScheduleName(schedule?.name ?? ''), [schedule]);

    const renderTimeSerieAbbreviations = (abbreviations: string[]) => {
        return abbreviations.map((abbreviation, index) => (
            <Text key={index} color="grey6">
                {abbreviation}
            </Text>
        ));
    };

    if (!schedule) {
        return null;
    }

    const updateName = async (newName: string) => {
        setScheduleName(newName);
        if ((await scheduleService.updateSchedule(scheduleId, newName.trim())) === false) {
            setScheduleName(schedule.name);
        }
    };

    const onDeleteSchedule = () => {
        if (inUse) {
            return;
        }
        scheduleService.deleteSchedule(scheduleId);
    };

    const onDuplicateSchedule = () => {
        scheduleService.duplicateSchedule(scheduleId);
    };

    return (
        <Clickable allowClickThrough={true} onClick={() => onEditSchedule(scheduleId)}>
            <Box padding="half" paddingY="base" minHeight="76px" justifyContent="center">
                <Stack spacing="half" justifyContent="between" flex="shrinkAndGrow">
                    <Stack flex="shrinkAndGrow" alignItems="start">
                        <Clickable
                            allowClickThrough={false}
                            onClick={() => onEditSchedule(scheduleId)}
                        >
                            <Box>
                                <Icon icon="schedule" size="lg" color="grey6" />
                            </Box>
                        </Clickable>
                        <Stack vertical spacing="quart">
                            <Text onlyStyle style="semibold" color="grey9">
                                <EditableText
                                    value={scheduleName}
                                    placeholder={t.installationReportSettingsSchedule}
                                    maxLength={AppConstants.scheduleNameMaxLength}
                                    changeCriteria="blur"
                                    onChange={updateName}
                                />
                            </Text>
                            <Clickable
                                allowClickThrough={false}
                                onClick={() => onEditSchedule(scheduleId)}
                            >
                                <Stack vertical spacing="quart">
                                    {renderTimeSerieAbbreviations(timeSerieAbbreviations)}

                                    {invertedSchedule &&
                                        invertedTimeSerieAbbreviations &&
                                        invertedTimeSerieAbbreviations.length > 0 && (
                                            <>
                                                <Text style="semibold" color="grey9">
                                                    {invertedSchedule.name}
                                                </Text>

                                                {renderTimeSerieAbbreviations(
                                                    invertedTimeSerieAbbreviations,
                                                )}
                                            </>
                                        )}
                                </Stack>
                            </Clickable>
                        </Stack>
                    </Stack>
                    <Box justifyContent="end">
                        <Stack>
                            <DropDown
                                trigger={<Icon icon="more_vert" color="grey6" />}
                                contents={[
                                    <DropDownMenuButton
                                        label={t.schedulesEditSchedule}
                                        icon="edit"
                                        onClick={() => onEditSchedule(scheduleId)}
                                    />,
                                    <DropDownMenuButton
                                        label={t.duplicate}
                                        icon="content_copy"
                                        onClick={onDuplicateSchedule}
                                    />,
                                    <DropDownMenuDelete
                                        disabled={inUse || invertedScheduleInUse}
                                        onDelete={onDeleteSchedule}
                                    />,
                                ]}
                            />
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Clickable>
    );
};
