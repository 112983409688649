import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { MountingItem } from './MountingItem.container';
import {
    getPrimaryMountOptions,
    getSelectedDeviceId,
    getSelectedNotToBeFollowedBy,
    getSelectedEnvironmentPiaId,
} from '../../selectors';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import { AccessorySelectorService } from '../../services';
import {
    getCurrentProjectRegions,
    getProductAllowlist,
    getSelectedPrimaryMountPiaIdForItemId,
    getUseProductAllowlist,
} from 'app/modules/common';
import type { IRelatedPiaAccessory } from '../../models';
import type { PiaId } from 'app/core/pia';

export const PrimaryMount: React.FunctionComponent = () => {
    const selectedDeviceId = useSelector(getSelectedDeviceId);
    const productIds = useSelector<IStoreState, PiaId | undefined>((state) =>
        selectedDeviceId
            ? getSelectedPrimaryMountPiaIdForItemId(state, selectedDeviceId)
            : undefined,
    );
    const primaryMountOptions = useSelector<IStoreState, IRelatedPiaAccessory[][]>((state) =>
        getPrimaryMountOptions(state, selectedDeviceId),
    );
    const accessorySelectorService = useService(AccessorySelectorService);
    const regions = useSelector(getCurrentProjectRegions);
    const useAllowlist = useSelector(getUseProductAllowlist);
    const allowlist = useSelector(getProductAllowlist);
    const selectedEnvironmentProductId = useSelector<IStoreState, PiaId | undefined>((state) =>
        getSelectedEnvironmentPiaId(state, selectedDeviceId),
    );
    const selectedNotToBeFollowedBy = useSelector(getSelectedNotToBeFollowedBy);
    const onMountChanged = React.useCallback(
        (mount: PiaId[]) =>
            selectedDeviceId &&
            accessorySelectorService.setPrimaryMount(
                selectedDeviceId,
                mount.length === 0 ? null : mount[0],
                regions,
                selectedEnvironmentProductId ?? null,
                selectedNotToBeFollowedBy,
                useAllowlist ? allowlist : undefined,
            ),
        [
            accessorySelectorService,
            allowlist,
            regions,
            selectedDeviceId,
            selectedEnvironmentProductId,
            selectedNotToBeFollowedBy,
            useAllowlist,
        ],
    );
    return (
        <MountingItem
            label={t.primaryMount}
            productIds={productIds ? [productIds] : []}
            mountOptions={primaryMountOptions}
            fallbackImage={require('src/assets/images/mounting/directCoupled.png')}
            onMountChanged={onMountChanged}
        />
    );
};
