import type { Id } from 'app/core/persistence';
import { t } from 'app/translate';

export type WearableSceneId = 'indoorLow' | 'indoorHigh' | 'outdoor';

export interface IWearableSceneModel {
    id: WearableSceneId;
    name: string;
    image: string;
    indoor: boolean;
}

export function getWearableSceneModels(): IWearableSceneModel[] {
    return [
        {
            name: t.scenarioNamesGROUP.indoorLow,
            id: 'indoorLow',
            image: require('src/assets/images/profile/scenarios/reception.png'),
            indoor: true,
        },
        {
            name: t.scenarioNamesGROUP.indoorHigh,
            id: 'indoorHigh',
            image: require('src/assets/images/profile/scenarios/mall.png'),
            indoor: true,
        },
        {
            name: t.outdoor,
            id: 'outdoor',
            image: require('src/assets/images/profile/scenarios/pedestrianZone.png'),
            indoor: false,
        },
    ];
}

/**
 * Returns a record with scenario id as key and scenario model as value.
 */
export const getWearableScenariosRecord = () => {
    const scenes = getWearableSceneModels();
    return scenes.reduce((obj: Record<Id, IWearableSceneModel>, scene) => {
        obj[scene.id] = scene;
        return obj;
    }, {});
};
