import * as React from 'react';
import { PrintPanel, LoadingIndicator, Paginator } from 'app/components';
import { eventTracking } from 'app/core/tracking';
import { ServiceLocator } from 'app/ioc';
import { ProposalSummary } from './ProposalSummary.container';
import { ProposalFloorPlans } from './ProposalFloorPlans.container';
import type { IStoreState } from 'app/store';
import { connect, useSelector } from 'react-redux';
import type { IProduct } from 'app/modules/common';
import {
    getCurrentProject,
    getCurrentProjectName,
    getProductsWithPrices,
} from 'app/modules/common';
import { EmptyReport } from '../../components';
import type { Id } from 'app/core/persistence';
import { t } from 'app/translate';
import { ProposalRecordingSolution } from './ProposalRecoringSolution.container';
import { ProposalDeviceDetails } from './DeviceDetails/ProposalDeviceDetail.container';

import { InstallationReportActionService } from '../../installationReport/services';
import { getAllProposalDevices } from '../selectors/getProposalDeviceDetails';
import { itemsPerPage } from '../../utils/itemsPerPage';

interface IProposalReportProps {
    products: IProduct[];
    projectId: Id | undefined;
    projectName: string;
    loading: boolean;
}

const mapStateToProps = (storeState: IStoreState) => {
    return {
        products: getProductsWithPrices(storeState),
        projectId: getCurrentProject(storeState)?._id,
        projectName: getCurrentProjectName(storeState),
        loading: storeState.installationReport.loadingDevices,
    };
};

const ProposalReportContainer: React.FunctionComponent<IProposalReportProps> = ({
    products,
    projectId,
    projectName,
    loading,
}) => {
    const items = useSelector(getAllProposalDevices);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const itemCount = items.length;
    const pageCount = Math.ceil(itemCount / itemsPerPage);
    React.useEffect(() => {
        if (projectId) {
            const installationReportActionService = ServiceLocator.get(
                InstallationReportActionService,
            );
            installationReportActionService.getProjectSchedules(projectId);
            installationReportActionService.getInstallationReportItems(projectId);
            installationReportActionService.getProjectScheduleNames(projectId);
        }
    }, [projectId]);

    const renderPaginator = () => {
        return (
            <>
                {pageCount > 1 && (
                    <Paginator
                        pageCount={pageCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                )}
            </>
        );
    };

    const renderProposalReport = () => {
        return (
            <PrintPanel
                testId="panel_reports_system_proposal_panel"
                onPrint={onPrint}
                printable
                buttons={renderPaginator()}
            >
                {currentPage === 1 && <ProposalSummary />}
                {currentPage === 1 && <ProposalFloorPlans />}
                <ProposalDeviceDetails currentPage={currentPage} />
                {currentPage === pageCount && <ProposalRecordingSolution />}
            </PrintPanel>
        );
    };

    const renderEmpty = () => {
        if (!projectId) {
            return null;
        }

        return (
            <EmptyReport
                projectId={projectId}
                projectName={projectName}
                text={t.emptyProposalReport}
            />
        );
    };

    return loading ? (
        <LoadingIndicator message={t.projectReportsCreatingReport} />
    ) : products.length > 0 ? (
        renderProposalReport()
    ) : (
        renderEmpty()
    );
};

const onPrint = () => {
    eventTracking.logUserEvent('Reports', 'Print', 'Proposal Report');
    eventTracking.pushToGA4('SiteDesignerClick', 'Print', 'Proposal Report');
};

export const ProposalReport = connect(mapStateToProps)(ProposalReportContainer);

ProposalReport.displayName = 'ProposalReport';
