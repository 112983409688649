import type { MeshLambertMaterial } from 'three';
import { Mesh, Object3D } from 'three';
import { Colors, selectedNearMaterial, selectedNearMaterialBack } from '../materials';
import { calculateDistanceToTarget } from '../math';
import { createSphere } from './createSphere';

export class ResolutionGuide extends Object3D {
    private mesh!: Mesh;
    private meshBack!: Mesh;

    constructor() {
        super();
        const sphere = createSphere(1);
        this.mesh = new Mesh(undefined, selectedNearMaterial);
        this.mesh.geometry = sphere; // clipped by clipping planes
        this.mesh.renderOrder = 2;

        this.meshBack = new Mesh(undefined, selectedNearMaterialBack);
        this.meshBack.geometry = sphere; // clipped by clipping planes
        this.meshBack.renderOrder = 1;
        this.add(this.mesh);
        this.add(this.meshBack);
    }

    public update(
        cameraHeight: number,
        targetDistance: number,
        targetHeight: number,
        resolutionLimit: number,
        showOnlyError: boolean,
    ) {
        const distance = calculateDistanceToTarget(cameraHeight, targetDistance, targetHeight);

        const color = this.getResolutionLimitColor(resolutionLimit, distance);

        this.mesh.scale.set(resolutionLimit, resolutionLimit, resolutionLimit);
        this.meshBack.scale.set(resolutionLimit, resolutionLimit, resolutionLimit);
        if (showOnlyError) {
            this.mesh.visible = resolutionLimit < distance;
            this.meshBack.visible = resolutionLimit < distance;
        }

        (this.mesh.material as MeshLambertMaterial).color.set(color);
        (this.meshBack.material as MeshLambertMaterial).color.set(color);
    }

    private getResolutionLimitColor(resolutionLimit: number, distance: number) {
        return resolutionLimit < distance ? Colors.invalidMaterial : Colors.defaultMaterial;
    }
}
