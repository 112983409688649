import type { IPiaItem, IPiaPartnerItem } from 'app/core/pia';
import type { IPartnerProductFilter } from '../models';
import { PartnerProductFilter } from '../filters';
import { getCompatiblePartnerProductsForPiaItem } from './getCompatiblePartnerProductsForPiaItem';

type TIgnoredFilter = keyof Pick<
    IPartnerProductFilter,
    'useCase' | 'solutionType' | 'partnerProductType'
>;

/** Returns partner products filtered by all properties except parameter {@link ignoredFilter} */
export const getFilteredPartnerItemsWithBypass = (
    partnerProducts: IPiaPartnerItem[],
    filter: IPartnerProductFilter,
    ignoredFilter: TIgnoredFilter,
    piaItemsRecord: Record<number, IPiaItem>,
) => {
    const compatibleProducts = filter.compatibleWithDevicePiaId
        ? getCompatiblePartnerProductsForPiaItem(
              partnerProducts,
              piaItemsRecord[filter.compatibleWithDevicePiaId],
          )
        : partnerProducts;
    return PartnerProductFilter.filter(compatibleProducts, {
        ...filter,
        useCase: ignoredFilter === 'useCase' ? undefined : filter.useCase,
        solutionType: ignoredFilter === 'solutionType' ? undefined : filter.solutionType,
        partnerProductType:
            ignoredFilter === 'partnerProductType' ? undefined : filter.partnerProductType,
    });
};
