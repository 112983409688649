import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    DropDown,
    IconButton,
    Clickable,
    DropDownMenuItem,
    Box,
    Stack,
    PiaImage,
    Badge,
    Text,
} from 'app/components';
import { ServiceLocator } from 'app/ioc';
import { PartnerConfigActionService } from '../services';
import type { IPiaItem, PiaId } from 'app/core/pia';
import type { IRelatedPiaItem } from '../models';
import { getFilteredAccessoriesRecord } from '../selectors/getAccessories';

interface IEditAccessoriesButtonProps {
    piaItem: IPiaItem;
}

export const EditAccessoriesButton: React.FC<IEditAccessoriesButtonProps> = ({ piaItem }) => {
    const [actions] = React.useState(ServiceLocator.get(PartnerConfigActionService));
    const accessoriesSearchText = useSelector<IStoreState, string | undefined>(
        (state) => state.partnerConfig.accessoriesSearchText,
    );
    const accessoriesByProduct = useSelector<IStoreState, Record<PiaId, IRelatedPiaItem[]>>(
        getFilteredAccessoriesRecord,
    );
    const accessories = accessoriesByProduct[piaItem.id];

    if (!accessories.length) {
        return null;
    }

    return (
        <DropDown
            openInPortal
            includeSearchField
            searchFieldText={accessoriesSearchText ?? ''}
            onSearchTextChanged={actions.setAccessoriesSearchText}
            stayOpen
            minWidth={500}
            trigger={<IconButton icon="add_circle" text={t.addAccessories} />}
            contents={
                <>
                    {accessories.map((accessory) => (
                        <Clickable
                            key={accessory.id}
                            onClick={() =>
                                accessory.isSelected
                                    ? actions.removeFromAllowList(accessory.id)
                                    : actions.addToAllowList(accessory.id)
                            }
                        >
                            <DropDownMenuItem
                                testId={`mounting_${accessory.name}`}
                                onlyContent
                                selected={accessory.isSelected}
                            >
                                <Box
                                    padding="cell"
                                    justifyContent="between"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <Stack>
                                        <PiaImage
                                            piaId={accessory.id}
                                            icon="category"
                                            imageSize="md"
                                        />
                                        <Text>{accessory.name}</Text>
                                    </Stack>
                                    <Stack>
                                        {accessory.isIncluded && (
                                            <Badge compact label={t.included} type="yellow" />
                                        )}
                                        {accessory.isRecommended && !accessory.isIncluded && (
                                            <Badge compact label={t.recommended} type="green" />
                                        )}
                                    </Stack>
                                </Box>
                            </DropDownMenuItem>
                        </Clickable>
                    ))}
                </>
            }
        />
    );
};

EditAccessoriesButton.displayName = 'EditAccessoriesButton';
