import * as React from 'react';
import {
    Text,
    NumberInput,
    Stack,
    Button,
    Heading,
    Positioned,
    Border,
    Box,
    IconButton,
} from 'app/components';
import { t } from 'app/translate';
import { DistanceUnit, distanceUnitShortText, getDistanceUnit } from 'app/core/persistence';
import { useState } from 'react';
import { generateToMetersConverter } from '../../utils';
import { MapMeasureTool } from '../mapMeasureTool';
import { useSelector } from 'react-redux';
import { generateToDisplayUnitConverter, getCurrentProjectUnitSystem } from 'app/modules/common';
import { getSelectedMapOrDefault } from '../../selectors';
import { MapsActionService } from '../../services';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { useMapContext } from '../context';

export const MapScalingTool: React.FC = () => {
    const mapsActionService = useService(MapsActionService);
    const selectedFloorPlan = useSelector(getSelectedMapOrDefault);
    const unitSystem = useSelector(getCurrentProjectUnitSystem);
    const displayUnit = getDistanceUnit(unitSystem);
    const { leafletMap } = useMapContext();

    const convertToMeters = generateToMetersConverter(displayUnit);
    // generate a converter function for the desired unit
    const convertToDisplayUnit = generateToDisplayUnitConverter(displayUnit, false);

    // the default scale value
    let defaultScaleValue = displayUnit === DistanceUnit.Meter ? 10 : 30;
    // we can only cancel if the floor plan already has bounds, otherwise we enforce
    // setting the scale
    const canCancel = !!selectedFloorPlan?.image?.bounds;
    let defaultDistance = 0;

    if (canCancel) {
        const { begin, end } = leafletMap.getCurrentMapScaleToolPosition();

        const distance = begin.distanceTo(end);
        defaultDistance = convertToDisplayUnit(distance);
        defaultScaleValue = defaultDistance;
    }

    const [scaleValue, setScaleValue] = React.useState<number>(defaultScaleValue);
    const [currentDistance, setCurrentDistance] = useState(defaultDistance);

    const rescaleMap = (length: number) => {
        const scaleFactor = length / currentDistance;
        if (!selectedFloorPlan) {
            return;
        }

        if (selectedFloorPlan?.image?.bounds && scaleFactor !== 1) {
            eventTracking.logUserEvent('Maps', 'Change floor plan scale');
        }
        if (scaleFactor !== 1) {
            mapsActionService.rescale(selectedFloorPlan, scaleFactor);
        }

        close();
    };

    const close = () => {
        mapsActionService.toggleScalingTool(false);
    };

    React.useEffect(() => {
        return () => setScaleValue(defaultScaleValue);
    }, [defaultScaleValue]);

    return (
        <>
            <Border color="grey1" radius="20px" shadow="0px 5px 12px rgba(0, 0, 0, 0.15)">
                <Box color="grey1" padding="base" maxWidth="400px" position="relative">
                    {canCancel && (
                        <Positioned position="absolute" top="10px" right="10px">
                            <IconButton opaque={false} color="red" icon="cancel" onClick={close} />
                        </Positioned>
                    )}
                    <Stack vertical alignItems="center" spacing="panel">
                        <Stack vertical spacing="base" alignItems="center">
                            <Heading testId="set_scale_title" style="title">
                                {canCancel ? t.editScale : t.scaleTitle}
                            </Heading>
                            <Box maxWidth="360px">
                                <Stack spacing="halfCell" vertical>
                                    <Text
                                        testId="set_scale_message"
                                        style="semibold"
                                        align="center"
                                    >
                                        {t.scaleMessage}
                                    </Text>
                                    {scaleValue <= 0 && (
                                        <Text align="center" italic color="red">
                                            {t.invalidScaleValue}
                                        </Text>
                                    )}
                                </Stack>
                            </Box>
                            <NumberInput
                                unit={distanceUnitShortText(displayUnit)}
                                min={0}
                                testId="scale_input"
                                value={scaleValue}
                                onChange={(length: number) => setScaleValue(length)}
                                decimals={2}
                                step={1}
                                changeCriteria="key"
                            />
                        </Stack>
                        <Button
                            testId="set_scale"
                            disabled={scaleValue <= 0}
                            primary
                            onClick={() => rescaleMap(convertToMeters(scaleValue))}
                        >
                            {canCancel ? t.change : t.scaleTitle}
                        </Button>
                    </Stack>
                </Box>
            </Border>
            <MapMeasureTool showLabel={false} onDistanceChange={setCurrentDistance} />
        </>
    );
};

MapScalingTool.displayName = 'MapScalingTool';
