interface ICountry {
    countryCode: string;
    countryName: string;
    currencyCode: string;
    languages: string[];
}

/**
 * Returns name, currency and languages for a country.
 */
export function getCountryFromCountryCode(countryCode?: string): ICountry | undefined {
    return countries.find((country) => country.countryCode === countryCode?.toUpperCase());
}

/**
 * Returns the currency for a country.
 */
export function getCurrencyFromCountryCode(countryCode?: string): string {
    const country = getCountryFromCountryCode(countryCode);
    return country ? country.currencyCode : '';
}

/**
 * Returns the most common locale for a country.
 */
export function getLocaleFromCountryCode(countryCode?: string): string {
    const country = getCountryFromCountryCode(countryCode);
    const languages = country ? country.languages : [];

    if (country && languages.length > 0) {
        return languages[0].includes('-') ? languages[0] : `${languages[0]}-${country.countryCode}`;
    }
    return '';
}

/**
 * A list of countries with local currency and languages.
 * Taken from http://download.geonames.org/export/dump/countryInfo.txt
 * and converted to JSON format.
 */
export const countries = [
    {
        countryCode: 'AD',
        countryName: 'Andorra',
        currencyCode: 'EUR',
        languages: ['ca'],
    },
    {
        countryCode: 'AE',
        countryName: 'United Arab Emirates',
        currencyCode: 'AED',
        languages: ['ar-AE', 'fa', 'en', 'hi', 'ur'],
    },
    {
        countryCode: 'AF',
        countryName: 'Afghanistan',
        currencyCode: 'AFN',
        languages: ['fa-AF', 'ps', 'uz-AF', 'tk'],
    },
    {
        countryCode: 'AG',
        countryName: 'Antigua and Barbuda',
        currencyCode: 'XCD',
        languages: ['en-AG'],
    },
    {
        countryCode: 'AI',
        countryName: 'Anguilla',
        currencyCode: 'XCD',
        languages: ['en-AI'],
    },
    {
        countryCode: 'AL',
        countryName: 'Albania',
        currencyCode: 'ALL',
        languages: ['sq', 'el'],
    },
    {
        countryCode: 'AM',
        countryName: 'Armenia',
        currencyCode: 'AMD',
        languages: ['hy'],
    },
    {
        countryCode: 'AO',
        countryName: 'Angola',
        currencyCode: 'AOA',
        languages: ['pt-AO'],
    },
    {
        countryCode: 'AQ',
        countryName: 'Antarctica',
        currencyCode: '',
        languages: [''],
    },
    {
        countryCode: 'AR',
        countryName: 'Argentina',
        currencyCode: 'ARS',
        languages: ['es-AR', 'en', 'it', 'de', 'fr', 'gn'],
    },
    {
        countryCode: 'AS',
        countryName: 'American Samoa',
        currencyCode: 'USD',
        languages: ['en-AS', 'sm', 'to'],
    },
    {
        countryCode: 'AT',
        countryName: 'Austria',
        currencyCode: 'EUR',
        languages: ['de-AT', 'hr', 'hu', 'sl'],
    },
    {
        countryCode: 'AU',
        countryName: 'Australia',
        currencyCode: 'AUD',
        languages: ['en-AU'],
    },
    {
        countryCode: 'AW',
        countryName: 'Aruba',
        currencyCode: 'AWG',
        languages: ['nl-AW', 'es', 'en'],
    },
    {
        countryCode: 'AX',
        countryName: 'Aland Islands',
        currencyCode: 'EUR',
        languages: ['sv-AX'],
    },
    {
        countryCode: 'AZ',
        countryName: 'Azerbaijan',
        currencyCode: 'AZN',
        languages: ['az', 'ru', 'hy'],
    },
    {
        countryCode: 'BA',
        countryName: 'Bosnia and Herzegovina',
        currencyCode: 'BAM',
        languages: ['bs', 'hr-BA', 'sr-BA'],
    },
    {
        countryCode: 'BB',
        countryName: 'Barbados',
        currencyCode: 'BBD',
        languages: ['en-BB'],
    },
    {
        countryCode: 'BD',
        countryName: 'Bangladesh',
        currencyCode: 'BDT',
        languages: ['bn-BD', 'en'],
    },
    {
        countryCode: 'BE',
        countryName: 'Belgium',
        currencyCode: 'EUR',
        languages: ['nl-BE', 'fr-BE', 'de-BE'],
    },
    {
        countryCode: 'BF',
        countryName: 'Burkina Faso',
        currencyCode: 'XOF',
        languages: ['fr-BF'],
    },
    {
        countryCode: 'BG',
        countryName: 'Bulgaria',
        currencyCode: 'BGN',
        languages: ['bg', 'tr-BG', 'rom'],
    },
    {
        countryCode: 'BH',
        countryName: 'Bahrain',
        currencyCode: 'BHD',
        languages: ['ar-BH', 'en', 'fa', 'ur'],
    },
    {
        countryCode: 'BI',
        countryName: 'Burundi',
        currencyCode: 'BIF',
        languages: ['fr-BI', 'rn'],
    },
    {
        countryCode: 'BJ',
        countryName: 'Benin',
        currencyCode: 'XOF',
        languages: ['fr-BJ'],
    },
    {
        countryCode: 'BL',
        countryName: 'Saint Barthelemy',
        currencyCode: 'EUR',
        languages: ['fr'],
    },
    {
        countryCode: 'BM',
        countryName: 'Bermuda',
        currencyCode: 'BMD',
        languages: ['en-BM', 'pt'],
    },
    {
        countryCode: 'BN',
        countryName: 'Brunei',
        currencyCode: 'BND',
        languages: ['ms-BN', 'en-BN'],
    },
    {
        countryCode: 'BO',
        countryName: 'Bolivia',
        currencyCode: 'BOB',
        languages: ['es-BO', 'qu', 'ay'],
    },
    {
        countryCode: 'BQ',
        countryName: 'Bonaire, Saint Eustatius and Saba',
        currencyCode: 'USD',
        languages: ['nl', 'pap', 'en'],
    },
    {
        countryCode: 'BR',
        countryName: 'Brazil',
        currencyCode: 'BRL',
        languages: ['pt-BR', 'es', 'en', 'fr'],
    },
    {
        countryCode: 'BS',
        countryName: 'Bahamas',
        currencyCode: 'BSD',
        languages: ['en-BS'],
    },
    {
        countryCode: 'BT',
        countryName: 'Bhutan',
        currencyCode: 'BTN',
        languages: ['dz'],
    },
    {
        countryCode: 'BV',
        countryName: 'Bouvet Island',
        currencyCode: 'NOK',
        languages: [''],
    },
    {
        countryCode: 'BW',
        countryName: 'Botswana',
        currencyCode: 'BWP',
        languages: ['en-BW', 'tn-BW'],
    },
    {
        countryCode: 'BY',
        countryName: 'Belarus',
        currencyCode: 'BYR',
        languages: ['be', 'ru'],
    },
    {
        countryCode: 'BZ',
        countryName: 'Belize',
        currencyCode: 'BZD',
        languages: ['en-BZ', 'es'],
    },
    {
        countryCode: 'CA',
        countryName: 'Canada',
        currencyCode: 'CAD',
        languages: ['en-CA', 'fr-CA', 'iu'],
    },
    {
        countryCode: 'CC',
        countryName: 'Cocos Islands',
        currencyCode: 'AUD',
        languages: ['ms-CC', 'en'],
    },
    {
        countryCode: 'CD',
        countryName: 'Democratic Republic of the Congo',
        currencyCode: 'CDF',
        languages: ['fr-CD', 'ln', 'kg'],
    },
    {
        countryCode: 'CF',
        countryName: 'Central African Republic',
        currencyCode: 'XAF',
        languages: ['fr-CF', 'sg', 'ln', 'kg'],
    },
    {
        countryCode: 'CG',
        countryName: 'Republic of the Congo',
        currencyCode: 'XAF',
        languages: ['fr-CG', 'kg', 'ln-CG'],
    },
    {
        countryCode: 'CH',
        countryName: 'Switzerland',
        currencyCode: 'CHF',
        languages: ['de-CH', 'fr-CH', 'it-CH', 'rm'],
    },
    {
        countryCode: 'CI',
        countryName: 'Ivory Coast',
        currencyCode: 'XOF',
        languages: ['fr-CI'],
    },
    {
        countryCode: 'CK',
        countryName: 'Cook Islands',
        currencyCode: 'NZD',
        languages: ['en-CK', 'mi'],
    },
    {
        countryCode: 'CL',
        countryName: 'Chile',
        currencyCode: 'CLP',
        languages: ['es-CL'],
    },
    {
        countryCode: 'CM',
        countryName: 'Cameroon',
        currencyCode: 'XAF',
        languages: ['en-CM', 'fr-CM'],
    },
    {
        countryCode: 'CN',
        countryName: 'China',
        currencyCode: 'CNY',
        languages: ['zh-CN', 'yue', 'wuu', 'dta', 'ug', 'za'],
    },
    {
        countryCode: 'CO',
        countryName: 'Colombia',
        currencyCode: 'COP',
        languages: ['es-CO'],
    },
    {
        countryCode: 'CR',
        countryName: 'Costa Rica',
        currencyCode: 'CRC',
        languages: ['es-CR', 'en'],
    },
    {
        countryCode: 'CU',
        countryName: 'Cuba',
        currencyCode: 'CUP',
        languages: ['es-CU'],
    },
    {
        countryCode: 'CV',
        countryName: 'Cape Verde',
        currencyCode: 'CVE',
        languages: ['pt-CV'],
    },
    {
        countryCode: 'CW',
        countryName: 'Curacao',
        currencyCode: 'ANG',
        languages: ['nl', 'pap'],
    },
    {
        countryCode: 'CX',
        countryName: 'Christmas Island',
        currencyCode: 'AUD',
        languages: ['en', 'zh', 'ms-CC'],
    },
    {
        countryCode: 'CY',
        countryName: 'Cyprus',
        currencyCode: 'EUR',
        languages: ['el-CY', 'tr-CY', 'en'],
    },
    {
        countryCode: 'CZ',
        countryName: 'Czechia',
        currencyCode: 'CZK',
        languages: ['cs', 'sk'],
    },
    {
        countryCode: 'DE',
        countryName: 'Germany',
        currencyCode: 'EUR',
        languages: ['de'],
    },
    {
        countryCode: 'DJ',
        countryName: 'Djibouti',
        currencyCode: 'DJF',
        languages: ['fr-DJ', 'ar', 'so-DJ', 'aa'],
    },
    {
        countryCode: 'DK',
        countryName: 'Denmark',
        currencyCode: 'DKK',
        languages: ['da-DK', 'en', 'fo', 'de-DK'],
    },
    {
        countryCode: 'DM',
        countryName: 'Dominica',
        currencyCode: 'XCD',
        languages: ['en-DM'],
    },
    {
        countryCode: 'DO',
        countryName: 'Dominican Republic',
        currencyCode: 'DOP',
        languages: ['es-DO'],
    },
    {
        countryCode: 'DZ',
        countryName: 'Algeria',
        currencyCode: 'DZD',
        languages: ['ar-DZ'],
    },
    {
        countryCode: 'EC',
        countryName: 'Ecuador',
        currencyCode: 'USD',
        languages: ['es-EC'],
    },
    {
        countryCode: 'EE',
        countryName: 'Estonia',
        currencyCode: 'EUR',
        languages: ['et', 'ru'],
    },
    {
        countryCode: 'EG',
        countryName: 'Egypt',
        currencyCode: 'EGP',
        languages: ['ar-EG', 'en', 'fr'],
    },
    {
        countryCode: 'EH',
        countryName: 'Western Sahara',
        currencyCode: 'MAD',
        languages: ['ar', 'mey'],
    },
    {
        countryCode: 'ER',
        countryName: 'Eritrea',
        currencyCode: 'ERN',
        languages: ['aa-ER', 'ar', 'tig', 'kun', 'ti-ER'],
    },
    {
        countryCode: 'ES',
        countryName: 'Spain',
        currencyCode: 'EUR',
        languages: ['es-ES', 'ca', 'gl', 'eu', 'oc'],
    },
    {
        countryCode: 'ET',
        countryName: 'Ethiopia',
        currencyCode: 'ETB',
        languages: ['am', 'en-ET', 'om-ET', 'ti-ET', 'so-ET', 'sid'],
    },
    {
        countryCode: 'FI',
        countryName: 'Finland',
        currencyCode: 'EUR',
        languages: ['fi-FI', 'sv-FI', 'smn'],
    },
    {
        countryCode: 'FJ',
        countryName: 'Fiji',
        currencyCode: 'FJD',
        languages: ['en-FJ', 'fj'],
    },
    {
        countryCode: 'FK',
        countryName: 'Falkland Islands',
        currencyCode: 'FKP',
        languages: ['en-FK'],
    },
    {
        countryCode: 'FM',
        countryName: 'Micronesia',
        currencyCode: 'USD',
        languages: ['en-FM', 'chk', 'pon', 'yap', 'kos', 'uli', 'woe', 'nkr', 'kpg'],
    },
    {
        countryCode: 'FO',
        countryName: 'Faroe Islands',
        currencyCode: 'DKK',
        languages: ['fo', 'da-FO'],
    },
    {
        countryCode: 'FR',
        countryName: 'France',
        currencyCode: 'EUR',
        languages: ['fr-FR', 'frp', 'br', 'co', 'ca', 'eu', 'oc'],
    },
    {
        countryCode: 'GA',
        countryName: 'Gabon',
        currencyCode: 'XAF',
        languages: ['fr-GA'],
    },
    {
        countryCode: 'GB',
        countryName: 'United Kingdom',
        currencyCode: 'GBP',
        languages: ['en-GB', 'cy-GB', 'gd'],
    },
    {
        countryCode: 'GD',
        countryName: 'Grenada',
        currencyCode: 'XCD',
        languages: ['en-GD'],
    },
    {
        countryCode: 'GE',
        countryName: 'Georgia',
        currencyCode: 'GEL',
        languages: ['ka', 'ru', 'hy', 'az'],
    },
    {
        countryCode: 'GF',
        countryName: 'French Guiana',
        currencyCode: 'EUR',
        languages: ['fr-GF'],
    },
    {
        countryCode: 'GG',
        countryName: 'Guernsey',
        currencyCode: 'GBP',
        languages: ['en', 'fr'],
    },
    {
        countryCode: 'GH',
        countryName: 'Ghana',
        currencyCode: 'GHS',
        languages: ['en-GH', 'ak', 'ee', 'tw'],
    },
    {
        countryCode: 'GI',
        countryName: 'Gibraltar',
        currencyCode: 'GIP',
        languages: ['en-GI', 'es', 'it', 'pt'],
    },
    {
        countryCode: 'GL',
        countryName: 'Greenland',
        currencyCode: 'DKK',
        languages: ['kl', 'da-GL', 'en'],
    },
    {
        countryCode: 'GM',
        countryName: 'Gambia',
        currencyCode: 'GMD',
        languages: ['en-GM', 'mnk', 'wof', 'wo', 'ff'],
    },
    {
        countryCode: 'GN',
        countryName: 'Guinea',
        currencyCode: 'GNF',
        languages: ['fr-GN'],
    },
    {
        countryCode: 'GP',
        countryName: 'Guadeloupe',
        currencyCode: 'EUR',
        languages: ['fr-GP'],
    },
    {
        countryCode: 'GQ',
        countryName: 'Equatorial Guinea',
        currencyCode: 'XAF',
        languages: ['es-GQ', 'fr'],
    },
    {
        countryCode: 'GR',
        countryName: 'Greece',
        currencyCode: 'EUR',
        languages: ['el-GR', 'en', 'fr'],
    },
    {
        countryCode: 'GS',
        countryName: 'South Georgia and the South Sandwich Islands',
        currencyCode: 'GBP',
        languages: ['en'],
    },
    {
        countryCode: 'GT',
        countryName: 'Guatemala',
        currencyCode: 'GTQ',
        languages: ['es-GT'],
    },
    {
        countryCode: 'GU',
        countryName: 'Guam',
        currencyCode: 'USD',
        languages: ['en-GU', 'ch-GU'],
    },
    {
        countryCode: 'GW',
        countryName: 'Guinea-Bissau',
        currencyCode: 'XOF',
        languages: ['pt-GW', 'pov'],
    },
    {
        countryCode: 'GY',
        countryName: 'Guyana',
        currencyCode: 'GYD',
        languages: ['en-GY'],
    },
    {
        countryCode: 'HK',
        countryName: 'Hong Kong',
        currencyCode: 'HKD',
        languages: ['zh-HK', 'yue', 'zh', 'en'],
    },
    {
        countryCode: 'HM',
        countryName: 'Heard Island and McDonald Islands',
        currencyCode: 'AUD',
        languages: [''],
    },
    {
        countryCode: 'HN',
        countryName: 'Honduras',
        currencyCode: 'HNL',
        languages: ['es-HN'],
    },
    {
        countryCode: 'HR',
        countryName: 'Croatia',
        currencyCode: 'EUR',
        languages: ['hr-HR', 'sr'],
    },
    {
        countryCode: 'HT',
        countryName: 'Haiti',
        currencyCode: 'HTG',
        languages: ['ht', 'fr-HT'],
    },
    {
        countryCode: 'HU',
        countryName: 'Hungary',
        currencyCode: 'HUF',
        languages: ['hu-HU'],
    },
    {
        countryCode: 'ID',
        countryName: 'Indonesia',
        currencyCode: 'IDR',
        languages: ['id', 'en', 'nl', 'jv'],
    },
    {
        countryCode: 'IE',
        countryName: 'Ireland',
        currencyCode: 'EUR',
        languages: ['en-IE', 'ga-IE'],
    },
    {
        countryCode: 'IL',
        countryName: 'Israel',
        currencyCode: 'ILS',
        languages: ['he', 'ar-IL', 'en-IL', ''],
    },
    {
        countryCode: 'IM',
        countryName: 'Isle of Man',
        currencyCode: 'GBP',
        languages: ['en', 'gv'],
    },
    {
        countryCode: 'IN',
        countryName: 'India',
        currencyCode: 'INR',
        languages: [
            'en-IN',
            'hi',
            'bn',
            'te',
            'mr',
            'ta',
            'ur',
            'gu',
            'kn',
            'ml',
            'or',
            'pa',
            'as',
            'bh',
            'sat',
            'ks',
            'ne',
            'sd',
            'kok',
            'doi',
            'mni',
            'sit',
            'sa',
            'fr',
            'lus',
            'inc',
        ],
    },
    {
        countryCode: 'IO',
        countryName: 'British Indian Ocean Territory',
        currencyCode: 'USD',
        languages: ['en-IO'],
    },
    {
        countryCode: 'IQ',
        countryName: 'Iraq',
        currencyCode: 'IQD',
        languages: ['ar-IQ', 'ku', 'hy'],
    },
    {
        countryCode: 'IR',
        countryName: 'Iran',
        currencyCode: 'IRR',
        languages: ['fa-IR', 'ku'],
    },
    {
        countryCode: 'IS',
        countryName: 'Iceland',
        currencyCode: 'ISK',
        languages: ['is', 'en', 'de', 'da', 'sv', 'no'],
    },
    {
        countryCode: 'IT',
        countryName: 'Italy',
        currencyCode: 'EUR',
        languages: ['it-IT', 'de-IT', 'fr-IT', 'sc', 'ca', 'co', 'sl'],
    },
    {
        countryCode: 'JE',
        countryName: 'Jersey',
        currencyCode: 'GBP',
        languages: ['en', 'pt'],
    },
    {
        countryCode: 'JM',
        countryName: 'Jamaica',
        currencyCode: 'JMD',
        languages: ['en-JM'],
    },
    {
        countryCode: 'JO',
        countryName: 'Jordan',
        currencyCode: 'JOD',
        languages: ['ar-JO', 'en'],
    },
    {
        countryCode: 'JP',
        countryName: 'Japan',
        currencyCode: 'JPY',
        languages: ['ja'],
    },
    {
        countryCode: 'KE',
        countryName: 'Kenya',
        currencyCode: 'KES',
        languages: ['en-KE', 'sw-KE'],
    },
    {
        countryCode: 'KG',
        countryName: 'Kyrgyzstan',
        currencyCode: 'KGS',
        languages: ['ky', 'uz', 'ru'],
    },
    {
        countryCode: 'KH',
        countryName: 'Cambodia',
        currencyCode: 'KHR',
        languages: ['km', 'fr', 'en'],
    },
    {
        countryCode: 'KI',
        countryName: 'Kiribati',
        currencyCode: 'AUD',
        languages: ['en-KI', 'gil'],
    },
    {
        countryCode: 'KM',
        countryName: 'Comoros',
        currencyCode: 'KMF',
        languages: ['ar', 'fr-KM'],
    },
    {
        countryCode: 'KN',
        countryName: 'Saint Kitts and Nevis',
        currencyCode: 'XCD',
        languages: ['en-KN'],
    },
    {
        countryCode: 'KP',
        countryName: 'North Korea',
        currencyCode: 'KPW',
        languages: ['ko-KP'],
    },
    {
        countryCode: 'KR',
        countryName: 'South Korea',
        currencyCode: 'KRW',
        languages: ['ko-KR', 'en'],
    },
    {
        countryCode: 'XK',
        countryName: 'Kosovo',
        currencyCode: 'EUR',
        languages: ['sq', 'sr'],
    },
    {
        countryCode: 'KW',
        countryName: 'Kuwait',
        currencyCode: 'KWD',
        languages: ['ar-KW', 'en'],
    },
    {
        countryCode: 'KY',
        countryName: 'Cayman Islands',
        currencyCode: 'KYD',
        languages: ['en-KY'],
    },
    {
        countryCode: 'KZ',
        countryName: 'Kazakhstan',
        currencyCode: 'KZT',
        languages: ['kk', 'ru'],
    },
    {
        countryCode: 'LA',
        countryName: 'Laos',
        currencyCode: 'LAK',
        languages: ['lo', 'fr', 'en'],
    },
    {
        countryCode: 'LB',
        countryName: 'Lebanon',
        currencyCode: 'LBP',
        languages: ['ar-LB', 'fr-LB', 'en', 'hy'],
    },
    {
        countryCode: 'LC',
        countryName: 'Saint Lucia',
        currencyCode: 'XCD',
        languages: ['en-LC'],
    },
    {
        countryCode: 'LI',
        countryName: 'Liechtenstein',
        currencyCode: 'CHF',
        languages: ['de-LI'],
    },
    {
        countryCode: 'LK',
        countryName: 'Sri Lanka',
        currencyCode: 'LKR',
        languages: ['si', 'ta', 'en'],
    },
    {
        countryCode: 'LR',
        countryName: 'Liberia',
        currencyCode: 'LRD',
        languages: ['en-LR'],
    },
    {
        countryCode: 'LS',
        countryName: 'Lesotho',
        currencyCode: 'LSL',
        languages: ['en-LS', 'st', 'zu', 'xh'],
    },
    {
        countryCode: 'LT',
        countryName: 'Lithuania',
        currencyCode: 'EUR',
        languages: ['lt', 'ru', 'pl'],
    },
    {
        countryCode: 'LU',
        countryName: 'Luxembourg',
        currencyCode: 'EUR',
        languages: ['lb', 'de-LU', 'fr-LU'],
    },
    {
        countryCode: 'LV',
        countryName: 'Latvia',
        currencyCode: 'EUR',
        languages: ['lv', 'ru', 'lt'],
    },
    {
        countryCode: 'LY',
        countryName: 'Libya',
        currencyCode: 'LYD',
        languages: ['ar-LY', 'it', 'en'],
    },
    {
        countryCode: 'MA',
        countryName: 'Morocco',
        currencyCode: 'MAD',
        languages: ['ar-MA', 'ber', 'fr'],
    },
    {
        countryCode: 'MC',
        countryName: 'Monaco',
        currencyCode: 'EUR',
        languages: ['fr-MC', 'en', 'it'],
    },
    {
        countryCode: 'MD',
        countryName: 'Moldova',
        currencyCode: 'MDL',
        languages: ['ro', 'ru', 'gag', 'tr'],
    },
    {
        countryCode: 'ME',
        countryName: 'Montenegro',
        currencyCode: 'EUR',
        languages: ['sr', 'hu', 'bs', 'sq', 'hr', 'rom'],
    },
    {
        countryCode: 'MF',
        countryName: 'Saint Martin',
        currencyCode: 'EUR',
        languages: ['fr'],
    },
    {
        countryCode: 'MG',
        countryName: 'Madagascar',
        currencyCode: 'MGA',
        languages: ['fr-MG', 'mg'],
    },
    {
        countryCode: 'MH',
        countryName: 'Marshall Islands',
        currencyCode: 'USD',
        languages: ['mh', 'en-MH'],
    },
    {
        countryCode: 'MK',
        countryName: 'Macedonia',
        currencyCode: 'MKD',
        languages: ['mk', 'sq', 'tr', 'rmm', 'sr'],
    },
    {
        countryCode: 'ML',
        countryName: 'Mali',
        currencyCode: 'XOF',
        languages: ['fr-ML', 'bm'],
    },
    {
        countryCode: 'MM',
        countryName: 'Myanmar',
        currencyCode: 'MMK',
        languages: ['my'],
    },
    {
        countryCode: 'MN',
        countryName: 'Mongolia',
        currencyCode: 'MNT',
        languages: ['mn', 'ru'],
    },
    {
        countryCode: 'MO',
        countryName: 'Macao',
        currencyCode: 'MOP',
        languages: ['zh', 'zh-MO', 'pt'],
    },
    {
        countryCode: 'MP',
        countryName: 'Northern Mariana Islands',
        currencyCode: 'USD',
        languages: ['fil', 'tl', 'zh', 'ch-MP', 'en-MP'],
    },
    {
        countryCode: 'MQ',
        countryName: 'Martinique',
        currencyCode: 'EUR',
        languages: ['fr-MQ'],
    },
    {
        countryCode: 'MR',
        countryName: 'Mauritania',
        currencyCode: 'MRO',
        languages: ['ar-MR', 'fuc', 'snk', 'fr', 'mey', 'wo'],
    },
    {
        countryCode: 'MS',
        countryName: 'Montserrat',
        currencyCode: 'XCD',
        languages: ['en-MS'],
    },
    {
        countryCode: 'MT',
        countryName: 'Malta',
        currencyCode: 'EUR',
        languages: ['mt', 'en-MT'],
    },
    {
        countryCode: 'MU',
        countryName: 'Mauritius',
        currencyCode: 'MUR',
        languages: ['en-MU', 'bho', 'fr'],
    },
    {
        countryCode: 'MV',
        countryName: 'Maldives',
        currencyCode: 'MVR',
        languages: ['dv', 'en'],
    },
    {
        countryCode: 'MW',
        countryName: 'Malawi',
        currencyCode: 'MWK',
        languages: ['ny', 'yao', 'tum', 'swk'],
    },
    {
        countryCode: 'MX',
        countryName: 'Mexico',
        currencyCode: 'MXN',
        languages: ['es-MX'],
    },
    {
        countryCode: 'MY',
        countryName: 'Malaysia',
        currencyCode: 'MYR',
        languages: ['ms-MY', 'en', 'zh', 'ta', 'te', 'ml', 'pa', 'th'],
    },
    {
        countryCode: 'MZ',
        countryName: 'Mozambique',
        currencyCode: 'MZN',
        languages: ['pt-MZ', 'vmw'],
    },
    {
        countryCode: 'NA',
        countryName: 'Namibia',
        currencyCode: 'NAD',
        languages: ['en-NA', 'af', 'de', 'hz', 'naq'],
    },
    {
        countryCode: 'NC',
        countryName: 'New Caledonia',
        currencyCode: 'XPF',
        languages: ['fr-NC'],
    },
    {
        countryCode: 'NE',
        countryName: 'Niger',
        currencyCode: 'XOF',
        languages: ['fr-NE', 'ha', 'kr', 'dje'],
    },
    {
        countryCode: 'NF',
        countryName: 'Norfolk Island',
        currencyCode: 'AUD',
        languages: ['en-NF'],
    },
    {
        countryCode: 'NG',
        countryName: 'Nigeria',
        currencyCode: 'NGN',
        languages: ['en-NG', 'ha', 'yo', 'ig', 'ff'],
    },
    {
        countryCode: 'NI',
        countryName: 'Nicaragua',
        currencyCode: 'NIO',
        languages: ['es-NI', 'en'],
    },
    {
        countryCode: 'NL',
        countryName: 'Netherlands',
        currencyCode: 'EUR',
        languages: ['nl-NL', 'fy-NL'],
    },
    {
        countryCode: 'NO',
        countryName: 'Norway',
        currencyCode: 'NOK',
        languages: ['no', 'nb', 'nn', 'se', 'fi'],
    },
    {
        countryCode: 'NP',
        countryName: 'Nepal',
        currencyCode: 'NPR',
        languages: ['ne', 'en'],
    },
    {
        countryCode: 'NR',
        countryName: 'Nauru',
        currencyCode: 'AUD',
        languages: ['na', 'en-NR'],
    },
    {
        countryCode: 'NU',
        countryName: 'Niue',
        currencyCode: 'NZD',
        languages: ['niu', 'en-NU'],
    },
    {
        countryCode: 'NZ',
        countryName: 'New Zealand',
        currencyCode: 'NZD',
        languages: ['en-NZ', 'mi'],
    },
    {
        countryCode: 'OM',
        countryName: 'Oman',
        currencyCode: 'OMR',
        languages: ['ar-OM', 'en', 'bal', 'ur'],
    },
    {
        countryCode: 'PA',
        countryName: 'Panama',
        currencyCode: 'PAB',
        languages: ['es-PA', 'en'],
    },
    {
        countryCode: 'PE',
        countryName: 'Peru',
        currencyCode: 'PEN',
        languages: ['es-PE', 'qu', 'ay'],
    },
    {
        countryCode: 'PF',
        countryName: 'French Polynesia',
        currencyCode: 'XPF',
        languages: ['fr-PF', 'ty'],
    },
    {
        countryCode: 'PG',
        countryName: 'Papua New Guinea',
        currencyCode: 'PGK',
        languages: ['en-PG', 'ho', 'meu', 'tpi'],
    },
    {
        countryCode: 'PH',
        countryName: 'Philippines',
        currencyCode: 'PHP',
        languages: ['tl', 'en-PH', 'fil'],
    },
    {
        countryCode: 'PK',
        countryName: 'Pakistan',
        currencyCode: 'PKR',
        languages: ['ur-PK', 'en-PK', 'pa', 'sd', 'ps', 'brh'],
    },
    {
        countryCode: 'PL',
        countryName: 'Poland',
        currencyCode: 'PLN',
        languages: ['pl'],
    },
    {
        countryCode: 'PM',
        countryName: 'Saint Pierre and Miquelon',
        currencyCode: 'EUR',
        languages: ['fr-PM'],
    },
    {
        countryCode: 'PN',
        countryName: 'Pitcairn',
        currencyCode: 'NZD',
        languages: ['en-PN'],
    },
    {
        countryCode: 'PR',
        countryName: 'Puerto Rico',
        currencyCode: 'USD',
        languages: ['en-PR', 'es-PR'],
    },
    {
        countryCode: 'PS',
        countryName: 'Palestinian Territory',
        currencyCode: 'ILS',
        languages: ['ar-PS'],
    },
    {
        countryCode: 'PT',
        countryName: 'Portugal',
        currencyCode: 'EUR',
        languages: ['pt-PT', 'mwl'],
    },
    {
        countryCode: 'PW',
        countryName: 'Palau',
        currencyCode: 'USD',
        languages: ['pau', 'sov', 'en-PW', 'tox', 'ja', 'fil', 'zh'],
    },
    {
        countryCode: 'PY',
        countryName: 'Paraguay',
        currencyCode: 'PYG',
        languages: ['es-PY', 'gn'],
    },
    {
        countryCode: 'QA',
        countryName: 'Qatar',
        currencyCode: 'QAR',
        languages: ['ar-QA', 'es'],
    },
    {
        countryCode: 'RE',
        countryName: 'Reunion',
        currencyCode: 'EUR',
        languages: ['fr-RE'],
    },
    {
        countryCode: 'RO',
        countryName: 'Romania',
        currencyCode: 'RON',
        languages: ['ro', 'hu', 'rom'],
    },
    {
        countryCode: 'RS',
        countryName: 'Serbia',
        currencyCode: 'RSD',
        languages: ['sr', 'hu', 'bs', 'rom'],
    },
    {
        countryCode: 'RU',
        countryName: 'Russia',
        currencyCode: 'RUB',
        languages: [
            'ru',
            'tt',
            'xal',
            'cau',
            'ady',
            'kv',
            'ce',
            'tyv',
            'cv',
            'udm',
            'tut',
            'mns',
            'bua',
            'myv',
            'mdf',
            'chm',
            'ba',
            'inh',
            'tut',
            'kbd',
            'krc',
            'av',
            'sah',
            'nog',
        ],
    },
    {
        countryCode: 'RW',
        countryName: 'Rwanda',
        currencyCode: 'RWF',
        languages: ['rw', 'en-RW', 'fr-RW', 'sw'],
    },
    {
        countryCode: 'SA',
        countryName: 'Saudi Arabia',
        currencyCode: 'SAR',
        languages: ['ar-SA'],
    },
    {
        countryCode: 'SB',
        countryName: 'Solomon Islands',
        currencyCode: 'SBD',
        languages: ['en-SB', 'tpi'],
    },
    {
        countryCode: 'SC',
        countryName: 'Seychelles',
        currencyCode: 'SCR',
        languages: ['en-SC', 'fr-SC'],
    },
    {
        countryCode: 'SD',
        countryName: 'Sudan',
        currencyCode: 'SDG',
        languages: ['ar-SD', 'en', 'fia'],
    },
    {
        countryCode: 'SS',
        countryName: 'South Sudan',
        currencyCode: 'SSP',
        languages: ['en'],
    },
    {
        countryCode: 'SE',
        countryName: 'Sweden',
        currencyCode: 'SEK',
        languages: ['sv-SE', 'se', 'sma', 'fi-SE'],
    },
    {
        countryCode: 'SG',
        countryName: 'Singapore',
        currencyCode: 'SGD',
        languages: ['cmn', 'en-SG', 'ms-SG', 'ta-SG', 'zh-SG'],
    },
    {
        countryCode: 'SH',
        countryName: 'Saint Helena',
        currencyCode: 'SHP',
        languages: ['en-SH'],
    },
    {
        countryCode: 'SI',
        countryName: 'Slovenia',
        currencyCode: 'EUR',
        languages: ['sl', 'sh'],
    },
    {
        countryCode: 'SJ',
        countryName: 'Svalbard and Jan Mayen',
        currencyCode: 'NOK',
        languages: ['no', 'ru'],
    },
    {
        countryCode: 'SK',
        countryName: 'Slovakia',
        currencyCode: 'EUR',
        languages: ['sk', 'hu'],
    },
    {
        countryCode: 'SL',
        countryName: 'Sierra Leone',
        currencyCode: 'SLL',
        languages: ['en-SL', 'men', 'tem'],
    },
    {
        countryCode: 'SM',
        countryName: 'San Marino',
        currencyCode: 'EUR',
        languages: ['it-SM'],
    },
    {
        countryCode: 'SN',
        countryName: 'Senegal',
        currencyCode: 'XOF',
        languages: ['fr-SN', 'wo', 'fuc', 'mnk'],
    },
    {
        countryCode: 'SO',
        countryName: 'Somalia',
        currencyCode: 'SOS',
        languages: ['so-SO', 'ar-SO', 'it', 'en-SO'],
    },
    {
        countryCode: 'SR',
        countryName: 'Suriname',
        currencyCode: 'SRD',
        languages: ['nl-SR', 'en', 'srn', 'hns', 'jv'],
    },
    {
        countryCode: 'ST',
        countryName: 'Sao Tome and Principe',
        currencyCode: 'STD',
        languages: ['pt-ST'],
    },
    {
        countryCode: 'SV',
        countryName: 'El Salvador',
        currencyCode: 'USD',
        languages: ['es-SV'],
    },
    {
        countryCode: 'SX',
        countryName: 'Sint Maarten',
        currencyCode: 'ANG',
        languages: ['nl', 'en'],
    },
    {
        countryCode: 'SY',
        countryName: 'Syria',
        currencyCode: 'SYP',
        languages: ['ar-SY', 'ku', 'hy', 'arc', 'fr', 'en'],
    },
    {
        countryCode: 'SZ',
        countryName: 'Swaziland',
        currencyCode: 'SZL',
        languages: ['en-SZ', 'ss-SZ'],
    },
    {
        countryCode: 'TC',
        countryName: 'Turks and Caicos Islands',
        currencyCode: 'USD',
        languages: ['en-TC'],
    },
    {
        countryCode: 'TD',
        countryName: 'Chad',
        currencyCode: 'XAF',
        languages: ['fr-TD', 'ar-TD', 'sre'],
    },
    {
        countryCode: 'TF',
        countryName: 'French Southern Territories',
        currencyCode: 'EUR',
        languages: ['fr'],
    },
    {
        countryCode: 'TG',
        countryName: 'Togo',
        currencyCode: 'XOF',
        languages: ['fr-TG', 'ee', 'hna', 'kbp', 'dag', 'ha'],
    },
    {
        countryCode: 'TH',
        countryName: 'Thailand',
        currencyCode: 'THB',
        languages: ['th', 'en'],
    },
    {
        countryCode: 'TJ',
        countryName: 'Tajikistan',
        currencyCode: 'TJS',
        languages: ['tg', 'ru'],
    },
    {
        countryCode: 'TK',
        countryName: 'Tokelau',
        currencyCode: 'NZD',
        languages: ['tkl', 'en-TK'],
    },
    {
        countryCode: 'TL',
        countryName: 'East Timor',
        currencyCode: 'USD',
        languages: ['tet', 'pt-TL', 'id', 'en'],
    },
    {
        countryCode: 'TM',
        countryName: 'Turkmenistan',
        currencyCode: 'TMT',
        languages: ['tk', 'ru', 'uz'],
    },
    {
        countryCode: 'TN',
        countryName: 'Tunisia',
        currencyCode: 'TND',
        languages: ['ar-TN', 'fr'],
    },
    {
        countryCode: 'TO',
        countryName: 'Tonga',
        currencyCode: 'TOP',
        languages: ['to', 'en-TO'],
    },
    {
        countryCode: 'TR',
        countryName: 'Turkey',
        currencyCode: 'TRY',
        languages: ['tr-TR', 'ku', 'diq', 'az', 'av'],
    },
    {
        countryCode: 'TT',
        countryName: 'Trinidad and Tobago',
        currencyCode: 'TTD',
        languages: ['en-TT', 'hns', 'fr', 'es', 'zh'],
    },
    {
        countryCode: 'TV',
        countryName: 'Tuvalu',
        currencyCode: 'AUD',
        languages: ['tvl', 'en', 'sm', 'gil'],
    },
    {
        countryCode: 'TW',
        countryName: 'Taiwan',
        currencyCode: 'TWD',
        languages: ['zh-TW', 'zh', 'nan', 'hak'],
    },
    {
        countryCode: 'TZ',
        countryName: 'Tanzania',
        currencyCode: 'TZS',
        languages: ['sw-TZ', 'en', 'ar'],
    },
    {
        countryCode: 'UA',
        countryName: 'Ukraine',
        currencyCode: 'UAH',
        languages: ['uk', 'ru-UA', 'rom', 'pl', 'hu'],
    },
    {
        countryCode: 'UG',
        countryName: 'Uganda',
        currencyCode: 'UGX',
        languages: ['en-UG', 'lg', 'sw', 'ar'],
    },
    {
        countryCode: 'UM',
        countryName: 'United States Minor Outlying Islands',
        currencyCode: 'USD',
        languages: ['en-UM'],
    },
    {
        countryCode: 'US',
        countryName: 'United States',
        currencyCode: 'USD',
        languages: ['en-US', 'es-US', 'haw', 'fr'],
    },
    {
        countryCode: 'UY',
        countryName: 'Uruguay',
        currencyCode: 'UYU',
        languages: ['es-UY'],
    },
    {
        countryCode: 'UZ',
        countryName: 'Uzbekistan',
        currencyCode: 'UZS',
        languages: ['uz', 'ru', 'tg'],
    },
    {
        countryCode: 'VA',
        countryName: 'Vatican',
        currencyCode: 'EUR',
        languages: ['la', 'it', 'fr'],
    },
    {
        countryCode: 'VC',
        countryName: 'Saint Vincent and the Grenadines',
        currencyCode: 'XCD',
        languages: ['en-VC', 'fr'],
    },
    {
        countryCode: 'VE',
        countryName: 'Venezuela',
        currencyCode: 'VEF',
        languages: ['es-VE'],
    },
    {
        countryCode: 'VG',
        countryName: 'British Virgin Islands',
        currencyCode: 'USD',
        languages: ['en-VG'],
    },
    {
        countryCode: 'VI',
        countryName: 'U.S. Virgin Islands',
        currencyCode: 'USD',
        languages: ['en-VI'],
    },
    {
        countryCode: 'VN',
        countryName: 'Vietnam',
        currencyCode: 'VND',
        languages: ['vi', 'en', 'fr', 'zh', 'km'],
    },
    {
        countryCode: 'VU',
        countryName: 'Vanuatu',
        currencyCode: 'VUV',
        languages: ['bi', 'en-VU', 'fr-VU'],
    },
    {
        countryCode: 'WF',
        countryName: 'Wallis and Futuna',
        currencyCode: 'XPF',
        languages: ['wls', 'fud', 'fr-WF'],
    },
    {
        countryCode: 'WS',
        countryName: 'Samoa',
        currencyCode: 'WST',
        languages: ['sm', 'en-WS'],
    },
    {
        countryCode: 'YE',
        countryName: 'Yemen',
        currencyCode: 'YER',
        languages: ['ar-YE'],
    },
    {
        countryCode: 'YT',
        countryName: 'Mayotte',
        currencyCode: 'EUR',
        languages: ['fr-YT'],
    },
    {
        countryCode: 'ZA',
        countryName: 'South Africa',
        currencyCode: 'ZAR',
        languages: ['zu', 'xh', 'af', 'nso', 'en-ZA', 'tn', 'st', 'ts', 'ss', 've', 'nr'],
    },
    {
        countryCode: 'ZM',
        countryName: 'Zambia',
        currencyCode: 'ZMW',
        languages: ['en-ZM', 'bem', 'loz', 'lun', 'lue', 'ny', 'toi'],
    },
    {
        countryCode: 'ZW',
        countryName: 'Zimbabwe',
        currencyCode: 'ZWL',
        languages: ['en-ZW', 'sn', 'nr', 'nd'],
    },
    {
        countryCode: 'CS',
        countryName: 'Serbia and Montenegro',
        currencyCode: 'RSD',
        languages: ['cu', 'hu', 'sq', 'sr'],
    },
    {
        countryCode: 'AN',
        countryName: 'Netherlands Antilles',
        currencyCode: 'ANG',
        languages: ['nl-AN', 'en', 'es'],
    },
];
