import type { IItemNetworkSettings } from 'app/core/persistence';
import { isEqual } from 'lodash-es';

/**
 * Sorts IP addresses and contracts them to IP ranges where they are sequential.
 * I.E ['1.1.1.1', '1.1.1.2', '1.1.1.3'] -> '1.1.1.1 - 1.1.1.3'
 * @param deviceNetworkSettings
 * @returns array of IP addresses which are contracted where they are sequential
 */
// export const contractIpAddresses = (deviceNetworkSettings: IItemNetworkSettings[]) => {
export const contractIpAddresses = (addresses: string[]) => {
    // const addresses = deviceNetworkSettings.map((settings) => settings.address).filter(isDefined);

    let length = addresses.length;

    if (length <= 1) {
        return addresses;
    }

    // Make sure IP Addresses are sorted according to address end
    addresses.sort(sortIpAddresses);

    // Determine if current and previous ip addresses end with sequential numbers
    let isSequential = false;

    for (let i = 1; i < length; i++) {
        const currentIpEnd = Number(addresses[i].split('.')[3]);
        const prevIpEnd: number = !isSequential
            ? Number(addresses[i - 1].split('.')[3])
            : Number(addresses[i - 1].split('.')[6]);

        // Check if first three numbers in ip is identical
        const firstThreeNumbersAreEqual = isEqual(
            addresses[i].split('.').slice(0, 3),
            addresses[i - 1].split('.').slice(0, 3),
        );
        // Re-evaluate if still sequential
        isSequential = currentIpEnd - prevIpEnd === 1 && firstThreeNumbersAreEqual;

        // Only modify array when numbers are sequential
        if (isSequential) {
            const ipRangeStart = addresses[i - 1].split(' - ')[0];

            // Contract previous number with current
            addresses[i - 1] = `${ipRangeStart} - ${addresses[i]}`;

            // Remove current entry
            addresses.splice(i, 1);

            // Account for lowered quantity
            length--;
            i--;
        }
    }
    return addresses;
};

/**
 * Iterates through the four blocks of IP addresses in arguments from left to right
 * and returns sorting comparison when it finds a difference
 * @param addressA First IP address to compare
 * @param addressB Second IP address to compare
 * @returns sorting comparison for IP addresses
 */
const sortIpAddresses = (addressA: string, addressB: string) => {
    const splitAddressA = addressA.split('.');
    const splitAddressB = addressB.split('.');

    // Compares a 'block' in IP address, such as 1.11. -> 23 <- .21
    const numberComparison = (index: number) =>
        Number(splitAddressA[index]) - Number(splitAddressB[index]);

    // Iterate through first 3 blocks
    for (let i = 0; i < 3; i++) {
        if (numberComparison(i) !== 0) {
            return numberComparison(i);
        }
    }
    // Return difference in final block if first 3 are identical
    return numberComparison(3);
};

const getIpAddressesFromNetworkSettings = (deviceNetworkSettings: IItemNetworkSettings[]) =>
    deviceNetworkSettings.reduce((allAddresses, currentSettings) => {
        const { addresses } = currentSettings;
        return [...allAddresses, ...addresses];
    }, [] as string[]);

export const getJointContractedIpAddresses = (deviceNetworkSettings: IItemNetworkSettings[]) =>
    contractIpAddresses(getIpAddressesFromNetworkSettings(deviceNetworkSettings)).join(', ');
