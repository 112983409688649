import type { IStoreState } from 'app/store';

const EXTRA_TOP_MARGIN = 175;

export const getReadMoreTopPosition = (state: IStoreState) =>
    state.addonSelector.standaloneParentBoundaries
        ? state.addonSelector.standaloneParentBoundaries.scrollPosition + EXTRA_TOP_MARGIN
        : undefined;

export const getReadMoreMaxHeight = (state: IStoreState) =>
    state.addonSelector.standaloneParentBoundaries
        ? state.addonSelector.standaloneParentBoundaries.innerHeight - EXTRA_TOP_MARGIN
        : undefined;
