import * as React from 'react';
import { css } from '@emotion/css';
import { Box } from 'app/components';
import { ColorsEnum, CornersEnum } from 'app/styles';

const skeletonStyle = (isTabletSize: boolean) => {
    return css`
        background-color: ${ColorsEnum.grey3};
        @keyframes pulse {
            75% {
                background-color: ${ColorsEnum.grey1};
            }
        }
        border: 1px solid ${ColorsEnum.grey3};
        border-radius: ${CornersEnum.rounded};
        width: 200px;
        height: 212px;
        animation: pulse 1.5s linear 0s infinite;
        min-width: ${isTabletSize ? '40%' : undefined};
        max-width: ${isTabletSize ? '40%' : undefined};
    `;
};

export const LoadingSkeleton: React.FunctionComponent = () => {
    return (
        <Box alignItems="stretch" spacing="base" paddingX="base">
            <div className={skeletonStyle(false)}></div>
            <div className={skeletonStyle(false)}></div>
            <div className={skeletonStyle(false)}></div>
            <div className={skeletonStyle(false)}></div>
        </Box>
    );
};
