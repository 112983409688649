import { injectable } from 'inversify';
import type { Icons } from 'app/components';
import { ToastModel } from '../models/Toast.model';
import { ToasterActionService } from './Toaster.ActionService';
import { ProgressToastModel } from '../models/ProgressToast.model';
import type { IProgressToast } from '../models/IProgressToast.interface';

const MAX_ITEMS = 5;
const DISPLAY_TIME = 7;
const ALERT_DISPLAY_TIME = 10;
const WARNING_DISPLAY_TIME = 10;

@injectable()
export class ToasterService {
    private readonly toastQueue: ToastModel[] = [];
    private readonly toastItems: ToastModel[] = [];
    private messageIndex = 0;

    constructor(private actionService: ToasterActionService) {}

    public success(headline: string, message?: string, icon: Icons = 'check_circle') {
        const toast = new ToastModel(
            this.messageIndex++,
            headline,
            message,
            icon,
            DISPLAY_TIME,
            'success',
            (t: ToastModel) => this.deleteToast(t),
        );
        this.addOrQueueToast(toast);
    }

    public info(headline: string, message?: string, icon: Icons = 'info') {
        const toast = new ToastModel(
            this.messageIndex++,
            headline,
            message,
            icon,
            DISPLAY_TIME,
            'info',
            (t: ToastModel) => this.deleteToast(t),
        );
        this.addOrQueueToast(toast);
    }

    public warning(headline: string, message?: string, icon: Icons = 'warning') {
        const toast = new ToastModel(
            this.messageIndex++,
            headline,
            message,
            icon,
            WARNING_DISPLAY_TIME,
            'warning',
            (t: ToastModel) => this.deleteToast(t),
        );
        this.addOrQueueToast(toast);
    }

    public error(headline: string, message?: string, icon: Icons = 'error') {
        const toast = new ToastModel(
            this.messageIndex++,
            headline,
            message,
            icon,
            ALERT_DISPLAY_TIME,
            'error',
            (t: ToastModel) => this.deleteToast(t),
        );
        this.addOrQueueToast(toast);
    }

    public progress(headline: string, message?: string): IProgressToast {
        const toast = new ProgressToastModel(
            this.messageIndex++,
            headline,
            message,
            DISPLAY_TIME,
            (t: ToastModel) => this.deleteToast(t),
            (t: ToastModel) => this.updateToast(t),
        );
        this.addOrQueueToast(toast);
        return toast;
    }

    public deleteToast(toastToDelete: ToastModel) {
        const indexToDelete = this.toastItems.indexOf(toastToDelete);
        if (indexToDelete < 0) {
            return;
        }

        this.toastItems[indexToDelete].stopDeletionTimer();
        this.toastItems.splice(indexToDelete, 1);
        this.addOrUpdateToasts();

        if (this.toastQueue.length > 0) {
            const toast = this.toastQueue.shift();
            if (toast) {
                this.addOrUpdateToasts(toast);
            }
        }
    }

    private addOrQueueToast(toast: ToastModel) {
        if (this.toastItems.length >= MAX_ITEMS) {
            this.toastQueue.push(toast);
        } else {
            this.addOrUpdateToasts(toast);
        }
    }

    private addOrUpdateToasts(toast?: ToastModel) {
        if (toast) {
            this.toastItems.splice(0, 0, toast);
            if (toast.toastType !== 'progress') {
                toast.startDeletionTimer();
            }
        }
        this.actionService.updateToastItems([...this.toastItems]);
    }

    private updateToast(toast: ToastModel) {
        const indexToUpdate = this.toastItems.findIndex((toastItem) => toastItem.id === toast.id);
        if (indexToUpdate >= 0) {
            this.toastItems[indexToUpdate] = toast;
            this.actionService.updateToastItems(this.toastItems);
        }
    }
}
