import { t as i18nextT } from 'i18next';
import type { SolutionType } from 'app/modules/common';

const t = (key: string) => i18nextT(key, { ns: 'partnerProducts' });

export const getSolutionTypeTranslation = (solutionType: SolutionType | undefined): string => {
    switch (solutionType) {
        case 'Hybrid':
            return t('hybridSolution');
        case 'Stand-alone':
            return t('standAloneSolution');
        case 'anyAcapArchitecture':
        case undefined:
        default:
            return t('anyAcapArchitecture');
    }
};
