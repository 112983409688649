import * as React from 'react';
import { Stack, Text, NumberInput } from 'app/components';

interface IPlacedDevices {
    placedInstallationPoints: number;
    totalQuantity: number;
    updateQuantity(quantity: number, revertCallback: () => void): void;
}

export const PlacedDevices: React.FunctionComponent<IPlacedDevices> = ({
    placedInstallationPoints,
    totalQuantity,
    updateQuantity,
}) => {
    return (
        <Stack spacing="halfCell">
            <Text testId="device_count" style="semibold" sizeOverride={22} color="grey6">
                {placedInstallationPoints}
            </Text>
            <Text style="semibold" sizeOverride={16} color="grey6">
                /
            </Text>
            <NumberInput
                changeCriteria="debounced"
                min={1}
                width="4em"
                value={totalQuantity}
                step={1}
                onChange={updateQuantity}
            ></NumberInput>
        </Stack>
    );
};
