import * as React from 'react';
import { Stack, Box, Border, Text, Currency } from 'app/components';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    getCurrencyFromCountryCode,
    getCurrentProjectLocation,
    getLocaleFromCountryCode,
} from 'app/modules/common';

interface IProposalSummaryCostLineOwnProps {
    text: string;
    cost: number;
}

interface IProposalSummaryCostLineProps extends IProposalSummaryCostLineOwnProps {
    locale: string;
    currency: string;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProposalSummaryCostLineOwnProps,
): IProposalSummaryCostLineProps => {
    const location = getCurrentProjectLocation(storeState);
    const currency = getCurrencyFromCountryCode(location?.countryCode);
    const locale = getLocaleFromCountryCode(location?.countryCode);

    return {
        currency,
        locale,
        ...ownProps,
    };
};

const ProposalSummaryCostLineContainer: React.FunctionComponent<IProposalSummaryCostLineProps> = ({
    text,
    locale,
    currency,
    cost,
}) => {
    return (
        <Stack alignItems="start" flex="shrink">
            <Border rightWidth={1} color="warmGrey4">
                <Box paddingY="base" paddingLeft="cell" flex="shrinkAndGrow">
                    <Text style="subheader" color="warmGrey6">
                        {text}
                    </Text>
                </Box>
            </Border>
            <Box flex="none" paddingY="base" justifyContent="end" alignItems="center" width="100px">
                <Text semiBold color="warmGrey6">
                    <Currency
                        showCurrency
                        hideFractions
                        currency={currency}
                        locale={locale}
                        value={cost}
                    />
                </Text>
            </Box>
        </Stack>
    );
};

export const ProposalSummaryCostLine = connect(mapStateToProps)(ProposalSummaryCostLineContainer);

ProposalSummaryCostLine.displayName = 'ProposalSummaryCostLine';
