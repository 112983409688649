import { ColorsEnum } from './colors.type';

const HexRegex = /^#([A-Fa-f0-9]{6})$/;
const RgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
/**
 * Convert an rgb or hex string to an rgba string with the specified opacity:
 */
export const colorToRgba = (color: string, opacity: number) => {
    if (HexRegex.test(color)) {
        return hexToRgb(color, opacity);
    } else if (RgbRegex.test(color)) {
        return rgbToRgba(color, opacity);
    }

    throw new Error(`Can't convert ${color} to rgba color`);
};

/**
 * Return font color depending of background hex input:
 */
export const getFontColor = (hex: string) => {
    const red = parseInt(hex.slice(1, 3), 16);
    const green = parseInt(hex.slice(3, 5), 16);
    const blue = parseInt(hex.slice(5, 7), 16);

    const L = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    if (L > 0.5) {
        return ColorsEnum.black;
    } else {
        return ColorsEnum.white;
    }
};

/**
 * Convert a rgb string to an rgba string with the specified opacity:
 */
export const rgbToRgba = (rgb: string, opacity: number) => {
    const [r, g, b] = rgb
        .replace('rgb', '')
        .replace('(', '')
        .replace(')', '')
        .split(',')
        .map(Number);

    return toRgba(r, g, b, opacity);
};

/**
 * Convert a hex string to an rgba string with the specified opacity:
 */
export const hexToRgb = (hex: string, opacity: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return toRgba(r, g, b, opacity);
};

const toRgba = (r: number, g: number, b: number, a: number) => `rgba(${r}, ${g}, ${b}, ${a})`;
