import * as React from 'react';
import { t } from 'app/translate';
import { Text, Box, Range } from 'app/components';
import { PropertyOverrideText } from '../common';

interface ILightConditionSettingProps {
    value: number;
    overrideValue?: number;
    label: string;
    color: 'yellow' | 'blue';
    type: 'motionInScene' | 'sceneMoving';
    onChange(value: number): void;
    onMouseUp(): void;
}

export class LightConditionSetting extends React.PureComponent<ILightConditionSettingProps> {
    public render() {
        return (
            <Box direction="column" width="100%">
                <PropertyOverrideText
                    text={this.setLabel()}
                    propertyValue={this.props.overrideValue}
                />
                <Range
                    color={this.props.color}
                    min={this.props.type === 'motionInScene' ? 0 : 1}
                    max={this.props.type === 'motionInScene' ? 100 : 5}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    onMouseUp={this.props.onMouseUp}
                />
                <Box justifyContent="between">
                    <Text>
                        {this.props.type === 'motionInScene'
                            ? t.profilesScheduledRecordingMotionOccurrenceNever
                            : t.profilesScheduledRecordingMotionObjectSizeSmall}
                    </Text>
                    <Text>
                        {this.props.type === 'motionInScene'
                            ? t.profilesScheduledRecordingMotionOccurrenceAlways
                            : t.profilesScheduledRecordingMotionObjectSizeLarge}
                    </Text>
                </Box>
            </Box>
        );
    }

    private setLabel = () => {
        return this.props.type === 'motionInScene'
            ? `${this.props.label}: ${this.props.value}%`
            : `${this.props.label}: ${this.getPartsOfSceneMovingTextFromValue(this.props.value)}`;
    };

    private getPartsOfSceneMovingTextFromValue = (value: number) => {
        switch (value) {
            case 1:
                return t.profilesScheduledRecordingMotionObjectSizeSmall;
            case 2:
                return t.profilesScheduledRecordingMotionObjectSizeSmallMedium;
            case 3:
                return t.profilesScheduledRecordingMotionObjectSizeMedium;
            case 4:
                return t.profilesScheduledRecordingMotionObjectSizeMediumLarge;
            case 5:
                return t.profilesScheduledRecordingMotionObjectSizeLarge;
        }
    };
}
