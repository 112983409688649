import { createCachedSelector } from 're-reselect';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItem } from '../../project';
import { toCacheKey } from '../../cacheKey';

export const getVendor = createCachedSelector(
    [getPiaItemsRecord, getCurrentProjectItem],
    (piaItemsRecord, item) => {
        if (!item || !item.productId) {
            return undefined;
        }
        return piaItemsRecord[item.productId].properties.vendor;
    },
)(toCacheKey);
