import { injectable } from 'inversify';
import type {
    Id,
    IEncoderItemEntity,
    IPersistence,
    IAnalogCameraItemEntity,
} from 'app/core/persistence';
import { CurrentProjectService } from 'app/core/persistence';
import type { IPiaEncoder } from 'app/core/pia';
import type {
    IInstallationReportEncoderDevice,
    IInstallationReportCameraDevice,
} from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum } from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import { InstallationDetailsService } from '../InstallationDetails.service';
import { t } from 'app/translate';

@injectable()
export class EncoderDeviceService {
    constructor(
        private baseDeviceService: BaseDeviceService,
        private installationDetailsService: InstallationDetailsService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async mapDevice(
        item: IPersistence<IEncoderItemEntity>,
        piaItem: IPiaEncoder,
        projectId: Id,
    ): Promise<IInstallationReportEncoderDevice> {
        const analogCameraItems = this.currentProjectService.getDeviceChildren(
            item._id,
            'analogCamera',
        );

        const analogCameras = await Promise.all(
            analogCameraItems
                .map((analogCameraItem) =>
                    this.mapAnalogCamera(
                        analogCameraItem as IPersistence<IAnalogCameraItemEntity>,
                        piaItem,
                        projectId,
                    ),
                )
                .filter(isDefined),
        );
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Encoder,
            analogCameras,
        };
    }

    private mapAnalogCamera = async (
        item: IPersistence<IAnalogCameraItemEntity>,
        piaItem: IPiaEncoder,
        projectId: Id,
    ): Promise<IInstallationReportCameraDevice> => ({
        id: item._id,
        name: item.name,
        description: item.description,
        notes: item.notes,
        quantity: item.quantity,
        model: t.devicesGROUP.analogCamera,
        piaId: 0,
        accessories: [],
        applications: [],
        mounts: [],
        category: CategoryEnum.AnalogCamera,
        ...(await this.installationDetailsService.getCameraSettings(
            item.properties.analogCamera,
            piaItem,
            projectId,
        )),
    });
}
