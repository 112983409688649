import * as React from 'react';

export interface IFramedVideoProps {
    src: string;

    allowFullScreen?: boolean;

    width?: string;

    height?: string;
}

export const VideoFrame: React.FunctionComponent<IFramedVideoProps> = (props) => {
    return (
        <iframe
            src={props.src}
            width={props.width}
            height={props.height}
            frameBorder="0"
            allowFullScreen={props.allowFullScreen}
        />
    );
};

VideoFrame.displayName = 'VideoFrame';
