import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';
import type { IAutoTestable } from 'app/components';
import { Clickable, DropDownMenuItem, Box, Text, Icon } from 'app/components';
import { getCurrentProjectProfiles, getScenarioName } from 'app/modules/common';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import { useResponsive } from 'app/hooks';

// Used to set max width on the profile title on smaller screens
const titleMaxWidthBreakPoint = 1280;
export interface IProfileSelectorOwnProps extends IAutoTestable {
    profileId: Id;
    selected: boolean;
    showWarning: boolean;
    itemId: Id;
    /** Will render this profile item as a drop down item */
    isDropDownItem?: boolean;
}

export const ProfileSelectorItem: React.FC<IProfileSelectorOwnProps> = ({
    showWarning,
    isDropDownItem,
    profileId,
    selected,
    itemId,
    testId,
}) => {
    const itemService = useService(ItemService);
    const profile = useSelector(getCurrentProjectProfiles)[profileId];
    const scenarioName = useSelector<IStoreState, string>((state) =>
        getScenarioName(state, itemId),
    );
    const title = profile?.name ?? '';
    const titleMaxWidth = useResponsive(undefined, 150, undefined, {
        desktop: titleMaxWidthBreakPoint,
        tablet: titleMaxWidthBreakPoint,
        mobile: titleMaxWidthBreakPoint,
    });

    // Only show the scenario name with overrides if selected, else show the name
    // the profile is attached to
    const description = selected
        ? scenarioName
        : profile?.scenario.scenarioId
          ? t.scenarioNamesGROUP[profile?.scenario.scenarioId]
          : '';

    const onSelect = () => {
        if (!selected) {
            itemService.updateProfile(itemId, profileId);
        }
    };

    return isDropDownItem ? (
        <Clickable key={profileId} onClick={onSelect}>
            <DropDownMenuItem
                testIdChild={`${testId}_scene_name`}
                selected={selected}
                description={description}
                maxWidth="100%"
            >
                <Box spacing="half" alignItems="start">
                    <Text testId={`${testId}_scenario_selected`}>{title}</Text>
                    {selected && showWarning && (
                        <Icon icon="warning_small" color="yellow7" size="sm" opaque />
                    )}
                </Box>
            </DropDownMenuItem>
        </Clickable>
    ) : (
        <Box direction="column" paddingLeft="half" maxWidth={titleMaxWidth}>
            <Box spacing="half" alignItems="center" flex="shrinkAndGrow">
                <Text testId={`${testId}_scenario_selected`} whiteSpace="nowrap">
                    {title}
                </Text>
                {selected && showWarning && (
                    <Icon icon="warning_small" color="yellow7" size="sm" opaque />
                )}
            </Box>
            <Text testId={`${testId}_scene_name`} color="grey6">
                {description}
            </Text>
        </Box>
    );
};
