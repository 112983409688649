import * as React from 'react';
import type { IAutoTestable } from 'app/components';
import { Box, Positioned, Icon, Border, Text } from 'app/components';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IMiniMapMarker } from './models';
import { Suspense, useEffect } from 'react';
import { useService } from 'app/ioc';
import type { IDimensions } from 'app/core/persistence';
import { ImageService } from 'app/core/persistence';
import { t } from 'app/translate';
import { usePromise } from 'app/hooks';

interface IMiniMapComponentProps extends IAutoTestable {
    imgKey: string;
    markers: IMiniMapMarker[];
    showLegendIds?: boolean;
    width: string | number;
    height?: string | number;
    dimensions: IDimensions;
    imgOpacity?: number;
    ignoreEXIF?: boolean;
    imgMaxWidth?: React.CSSProperties['maxHeight'];
    imgMaxHeight?: React.CSSProperties['maxHeight'];
    hideCone?: boolean;
}
const imgMaxHeight: number = 400;

export const MiniMapComponent: React.FunctionComponent<IMiniMapComponentProps> = (props) => {
    const [imageError, setImageError] = React.useState(false);
    const [paddingTop, setPaddingTop] = React.useState(0);
    const [paddingBottom, setPaddingBottom] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const imageService = useService(ImageService);

    const imagePromise = React.useMemo(
        () => imageService.getImageUrlAsBase64(props.imgKey),
        [imageService, props.imgKey],
    );

    const { result: imageBase64, pending: loading } = usePromise(imagePromise, [imagePromise]);

    useEffect(() => {
        const boundingBox = props.markers.reduce(
            (acc, ip) => {
                acc.topLeft.xRelative = Math.min(acc.topLeft.xRelative, ip.xRelative);
                acc.topLeft.yRelative = Math.min(acc.topLeft.yRelative, ip.yRelative);
                acc.bottomRight.xRelative = Math.max(acc.bottomRight.xRelative, ip.xRelative);
                acc.bottomRight.yRelative = Math.max(acc.bottomRight.yRelative, ip.yRelative);
                return acc;
            },
            {
                topLeft: {
                    xRelative: 0,
                    yRelative: 0,
                },
                bottomRight: {
                    xRelative: 1,
                    yRelative: 1,
                },
            },
        );
        setPaddingTop(Math.max(-boundingBox.topLeft.yRelative, 0));
        setPaddingBottom(Math.max(boundingBox.bottomRight.yRelative - 1, 0));
        const horizontalOverflow = Math.max(
            Math.abs(boundingBox.topLeft.xRelative) + 1,
            Math.abs(boundingBox.bottomRight.xRelative),
        );
        setWidth(1 / ((horizontalOverflow - 1) * 2 + 1));
    }, [props.markers]);

    const renderCircle = (color: Colors) => {
        return (
            <svg
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="45" cy="45" r="45" fill={ColorsEnum[color]} opacity="0.56" />
            </svg>
        );
    };

    const renderHalfCircle = (color: Colors) => {
        return (
            <svg
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0,45 a1,1 0 0,0 90,0" fill={ColorsEnum[color]} opacity="0.56" />
            </svg>
        );
    };

    const renderCone = (color: Colors) => {
        return (
            <svg
                width="50"
                height="70"
                viewBox="0 0 50 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path opacity="0.56" d="M0 60L25 0L50 60H0Z" fill={ColorsEnum[color]} />
            </svg>
        );
    };

    const renderDeviceAsNbr = (color: Colors, label: string): React.ReactElement => {
        const isChildLabel = new RegExp(/\d+\w/);
        return (
            <Border radius="50%" width={3} color={color}>
                <Box
                    alignItems="center"
                    justifyContent="center"
                    padding="quart"
                    width={25}
                    height={25}
                    color={isChildLabel.test(label) ? color : 'white'}
                >
                    {label}
                </Box>
            </Border>
        );
    };

    const renderDevice = (marker: IMiniMapMarker): React.ReactElement => {
        return (
            <Border width={2} color={marker.color}>
                <Box
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="circle"
                    padding="quart"
                    color={marker.color}
                    width="28px"
                    height="28px"
                >
                    {props.showLegendIds ? (
                        <Text align="center" semiBold color="white">
                            {marker.legendId}
                        </Text>
                    ) : (
                        <Icon opaque size="sm" icon={marker.icon ?? 'device'} color="white" />
                    )}
                </Box>
            </Border>
        );
    };

    const onImageError = () => {
        setImageError(true);
    };
    if (loading) {
        return <div>{t.loading}</div>;
    }

    const imgHeight = Math.min(props.dimensions.height, imgMaxHeight);
    const aspectRatio = props.dimensions.width / props.dimensions.height;

    return (
        <Box
            testId={props.testId}
            direction="column"
            alignItems="center"
            justifyContent="center"
            flex="grow"
            width={props.width}
            height={props.height}
        >
            <div
                style={{
                    // scale map and add padding so that any outliers
                    // will fit into the boundingClientRect of the MiniMap
                    // outer DOM element
                    paddingTop: `${paddingTop * width * 100}%`,
                    paddingBottom: `${paddingBottom * 100}%`,
                    width: `calc(${width * 100}% - 14px)`,
                }}
            >
                <Box
                    display={imageError ? 'none' : 'flex'}
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                    maxWidth="100%"
                >
                    <Suspense fallback={<></>}>
                        <img
                            src={imageBase64}
                            onError={onImageError}
                            height={!imageBase64 ? `${imgHeight}px` : undefined}
                            width={!imageBase64 ? `${imgHeight * aspectRatio}px` : undefined}
                            style={{
                                opacity: props.imgOpacity,
                                imageOrientation: props.ignoreEXIF ? 'none' : 'unset',
                                maxHeight: `${imgMaxHeight}px`,
                                maxWidth: props.imgMaxWidth,
                                aspectRatio: `${aspectRatio} / 1`,
                                height: 'auto',
                                border: !imageBase64
                                    ? `1px solid ${ColorsEnum['grey3']}`
                                    : undefined,
                            }}
                        />
                    </Suspense>
                    {!imageBase64 && (
                        <Positioned position="absolute" top={0} bottom={0} left={0} right={0}>
                            <Box alignItems="center" justifyContent="center">
                                <Text italic style="small" color="grey7">
                                    {t.imageLoadingError}
                                </Text>
                            </Box>
                        </Positioned>
                    )}
                    {props.markers.map((marker, index) => (
                        <Positioned
                            key={index}
                            position="absolute"
                            top={`calc(${marker.yRelative * 100}% - 14px)`}
                            left={`calc(${marker.xRelative * 100}% - 14px)`}
                            sendForward
                        >
                            {marker.label
                                ? renderDeviceAsNbr(marker.color, marker.label)
                                : renderDevice(marker)}
                        </Positioned>
                    ))}
                    {!props.hideCone &&
                        props.markers.map((marker) =>
                            (marker.sensors ?? []).map((sensor, index) => {
                                if (sensor.fov >= 170) {
                                    return (
                                        <Positioned
                                            key={index}
                                            position="absolute"
                                            top={`calc(${marker.yRelative * 100}% - 45px)`}
                                            left={`calc(${marker.xRelative * 100}% - 45px)`}
                                            rotate={`${sensor.rotation ?? 0}deg`}
                                            transformOrigin="50% 50%"
                                        >
                                            {marker.panoramaMode === 'horizontal'
                                                ? renderCircle(marker.color)
                                                : renderHalfCircle(marker.color)}
                                        </Positioned>
                                    );
                                } else {
                                    return (
                                        <Positioned
                                            key={index}
                                            position="absolute"
                                            top={`calc(${marker.yRelative * 100}% - 2px)`}
                                            left={`calc(${marker.xRelative * 100}% - 25px)`}
                                            rotate={`${sensor.rotation ?? 0}deg`}
                                            transformOrigin="50% 0%"
                                        >
                                            {renderCone(marker.color)}
                                        </Positioned>
                                    );
                                }
                            }),
                        )}
                </Box>
            </div>
        </Box>
    );
};
