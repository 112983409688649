import type { IBaseEntity } from 'app/core/persistence';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Removes old property isPlacementActive and allows placement to be undefined in
 * speaker filter.
 */
@injectable()
export class Migration25to26 extends MigrationProviderBase {
    public from: number = 25;
    public to: number = 26;

    public provideMigration() {
        return async (entity: any): Promise<IBaseEntity> => {
            if (entity?.properties?.speaker?.filter?.isPlacementActive === false) {
                entity.properties.speaker.filter.placement = undefined;
                delete entity.properties.speaker.filter.isPlacementActive;
                return entity;
            }

            if (entity?.properties?.speaker?.filter?.isPlacementActive === true) {
                delete entity.properties.speaker.filter.isPlacementActive;
                return entity;
            }

            return entity;
        };
    }
}
