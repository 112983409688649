import type { IRecordingSettingsEntity } from 'app/core/persistence';
import { Resolution } from 'app/core/persistence';
import type { IPiaCameraProperties, IPiaEncoderProperties } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import type { Frequency } from '../../models';
import { getPiaItemsRecord } from '../../piaDevices';
import { ProfileSupportService } from '../../profile/services/';
import {
    getCurrentProjectFrequency,
    getCurrentProjectItem,
    getCurrentProjectItems,
} from '../../project';
import { toCacheKey } from '../../cacheKey';
import { getPiaProductProperties } from './getDeviceProfileSupport';
import { getMergedProfile } from './getMergedProfile';

export interface IFrameRates {
    motionTriggered: number;
    continuous: number;
    live: number;
}

export const getFrameRatesForItem = createCachedSelector(
    [
        getCurrentProjectItem,
        getPiaItemsRecord,
        getCurrentProjectItems,
        getMergedProfile,
        getCurrentProjectFrequency,
    ],
    (item, piaItemsRecord, currentProjectItems, mergedProfile, frequency) => {
        if (!item) {
            return undefined;
        }

        const piaProductProperties = getPiaProductProperties(
            item,
            piaItemsRecord,
            currentProjectItems,
        );

        if (!mergedProfile || !piaProductProperties) {
            return undefined;
        }

        const motionTriggeredFrameRate = getFrameRate(
            mergedProfile.triggeredRecording,
            piaProductProperties,
            frequency,
        );
        const continuousFrameRate = getFrameRate(
            mergedProfile.continuousRecording,
            piaProductProperties,
            frequency,
        );
        const liveFrameRate = getFrameRate(mergedProfile.liveView, piaProductProperties, frequency);

        return {
            motionTriggered: motionTriggeredFrameRate,
            continuous: continuousFrameRate,
            live: liveFrameRate,
        };
    },
)(toCacheKey);

const convertResolution = (resolution: string | Resolution) => {
    return typeof resolution === 'string' ? new Resolution(resolution) : resolution;
};

const getFrameRate = (
    recordingSettings: IRecordingSettingsEntity & Partial<IRecordingSettingsEntity>,
    piaProductProperties: IPiaEncoderProperties | IPiaCameraProperties,
    frequency: Frequency,
) => {
    const resolution = convertResolution(recordingSettings.resolution);

    const maxFrameRate = ProfileSupportService.getMaxFrameRate(
        piaProductProperties,
        resolution.getPixels(),
        frequency,
    );
    return Math.min(recordingSettings.frameRate, maxFrameRate);
};
