import * as React from 'react';
import { Heading, Box, Checkbox, Text, NumberInput, Border, Spacer } from 'app/components';
import { t } from 'app/translate';
import { constants } from '../../constants';
import { PropertyOverrideText, NotSupportedText } from '../common';
import { ProfileEditorRow } from '../profileEditorRow';
import { ZipStrengthSelector } from './ZipStrengthSelector.component';
import type { IProfileSupport } from '../../models';
import { ProjectService } from 'app/core/persistence';
import type { FpsMode, BandwidthVersion, ZipProfile } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { ZipProfileSelector } from './ZipProfileSelector.component';
import { isFeatureEnabled } from 'app/featureFlags';

export interface IZipstreamSettingsComponentProps {
    zipStrength: number;
    zipStrengthOverridden: boolean;
    zipProfile: ZipProfile;
    zipProfileOverridden: boolean;
    dynamicGop: boolean;
    dynamicGopOverridden: boolean;
    fpsMode: FpsMode;
    fpsModeOverridden: boolean;
    minDynamicFps: number;
    minDynamicFpsOverridden: boolean;
    useProjectSetting: boolean;
    profileSupport?: IProfileSupport;
    deactivate?: boolean;
    projectBandwidthVersion: BandwidthVersion;
    continuousRecordingFps?: number;
    useAverageBitrateContinuous?: boolean;
    useAverageBitrateOverridden: boolean;
    continuousAlwaysScheduleUsed: boolean;
    onZipStrengthChanged(value: number): void;
    onZipProfileChanged(profile: ZipProfile): void;
    onDynamicGopChanged(value: boolean): void;
    onFpsModeChanged(value: FpsMode): void;
    onUseProjectSettingChanged(value: boolean): void;
    onMinDynamicFpsChanged(value: number): void;
    onUseAverageBitrateChanged(value: boolean): void;
}

export class ZipstreamSettingsComponent extends React.Component<IZipstreamSettingsComponentProps> {
    private projectService: ProjectService;

    constructor(props: IZipstreamSettingsComponentProps) {
        super(props);
        this.projectService = ServiceLocator.get(ProjectService);
    }

    public render() {
        const { profileSupport } = this.props;
        const divPaddingStyle = { paddingTop: '2px' };

        return (
            <ProfileEditorRow>
                <Box direction="column" spacing="half">
                    <Heading>{t.advancedFiltersGROUP.zipStream}</Heading>
                    <Box spacing="base">
                        <Box width={constants.leftColumnWidth}>
                            <Checkbox
                                testId="zipstream_toggle"
                                slider
                                selected={this.props.useProjectSetting}
                                onChange={this.onUseProjectSettingChanged}
                            >
                                {t.zipStreamGlobalToggle}
                            </Checkbox>
                        </Box>
                        <Box
                            testId="audio_zip_stream_settings_container"
                            flex="shrinkAndGrow"
                            alignItems="end"
                        >
                            <Box alignItems="start" spacing="base">
                                <Box direction="column">
                                    <Spacer customSpacing="25px" />
                                    <Box alignItems="start" spacing="base">
                                        <div>
                                            <Box spacing="halfQuart" direction="column">
                                                <PropertyOverrideText
                                                    text={t.zipStrength}
                                                    propertyValue={this.props.zipStrengthOverridden}
                                                />
                                                <ZipStrengthSelector
                                                    zipStrength={this.props.zipStrength}
                                                    onZipStrengthChange={
                                                        this.props.onZipStrengthChanged
                                                    }
                                                    disabled={
                                                        this.props.deactivate ||
                                                        this.props.useProjectSetting
                                                    }
                                                />
                                                <NotSupportedText
                                                    testId="zip_strength"
                                                    supportedWithMessage={
                                                        profileSupport && profileSupport.zipstream
                                                    }
                                                />
                                            </Box>
                                        </div>
                                        {isFeatureEnabled('zip_profile') && (
                                            <Box spacing="halfQuart" direction="column">
                                                <PropertyOverrideText
                                                    text={t.zipProfile}
                                                    propertyValue={this.props.zipProfileOverridden}
                                                />
                                                <ZipProfileSelector
                                                    zipProfile={this.props.zipProfile}
                                                    onZipProfileChange={
                                                        this.props.onZipProfileChanged
                                                    }
                                                    disabled={
                                                        this.props.deactivate ||
                                                        this.props.useProjectSetting
                                                    }
                                                />
                                            </Box>
                                        )}
                                        <div hidden={!this.props.zipStrength}>
                                            <Box spacing="halfQuart" direction="column">
                                                <PropertyOverrideText
                                                    text={t.dynamicGOP}
                                                    propertyValue={this.props.dynamicGopOverridden}
                                                />
                                                <div style={divPaddingStyle}>
                                                    <Checkbox
                                                        testId="dynamic_gop_checkbox"
                                                        selected={
                                                            this.props.dynamicGop ||
                                                            this.props.zipProfile === 'storage'
                                                        }
                                                        onChange={this.props.onDynamicGopChanged}
                                                        disabled={
                                                            this.props.deactivate ||
                                                            this.props.useProjectSetting ||
                                                            this.props.zipProfile === 'storage'
                                                        }
                                                    />
                                                </div>
                                            </Box>
                                        </div>
                                    </Box>
                                </Box>

                                <Border leftWidth={1} color="grey3">
                                    <Box direction="column" paddingLeft="base">
                                        <Box height="25px">
                                            <Text color="grey6">{t.zipstreamInfo}</Text>
                                        </Box>
                                        <Box alignItems="start" wrap spacing="base">
                                            <div hidden={!this.props.zipStrength}>
                                                <Box spacing="halfQuart" direction="column">
                                                    <PropertyOverrideText
                                                        text={t.dynamicFps}
                                                        propertyValue={this.props.fpsModeOverridden}
                                                    />
                                                    <div style={divPaddingStyle}>
                                                        <Checkbox
                                                            testId="dynamic_fps_checkbox"
                                                            selected={
                                                                this.props.fpsMode === 'dynamic'
                                                            }
                                                            onChange={this.onFpsModeChanged}
                                                            disabled={this.props.useProjectSetting}
                                                        />
                                                    </div>
                                                </Box>
                                            </div>
                                            <div>
                                                <PropertyOverrideText
                                                    text={t.minFps}
                                                    propertyValue={
                                                        this.props.minDynamicFpsOverridden
                                                    }
                                                />
                                                <Box spacing="quart">
                                                    <NumberInput
                                                        testId="minimum_fps_number_input"
                                                        max={this.props.continuousRecordingFps}
                                                        min={1}
                                                        value={this.props.minDynamicFps}
                                                        onChange={this.props.onMinDynamicFpsChanged}
                                                        disabled={this.props.fpsMode === 'fixed'}
                                                        changeCriteria="debounced"
                                                    />
                                                    <Text>{t.fps}</Text>
                                                </Box>
                                            </div>
                                            {this.props.useAverageBitrateContinuous !==
                                                undefined && (
                                                <div>
                                                    <Box spacing="halfQuart" direction="column">
                                                        <PropertyOverrideText
                                                            text={
                                                                t.profilesRecordingSettingsAverageBitrate
                                                            }
                                                            propertyValue={
                                                                this.props
                                                                    .useAverageBitrateOverridden
                                                            }
                                                        />
                                                        <div style={divPaddingStyle}>
                                                            <Checkbox
                                                                testId="average_bitrate_checkbox"
                                                                selected={
                                                                    this.props
                                                                        .useAverageBitrateContinuous ??
                                                                    false
                                                                }
                                                                onChange={
                                                                    this.props
                                                                        .onUseAverageBitrateChanged
                                                                }
                                                                disabled={
                                                                    this.props.deactivate ||
                                                                    this.props.useProjectSetting ||
                                                                    !this.props
                                                                        .continuousAlwaysScheduleUsed
                                                                }
                                                            />
                                                        </div>
                                                    </Box>
                                                </div>
                                            )}
                                        </Box>
                                    </Box>
                                </Border>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ProfileEditorRow>
        );
    }

    private onUseProjectSettingChanged = (on: boolean) => {
        this.props.onUseProjectSettingChanged(on);
    };

    private onFpsModeChanged = async (checked: boolean) => {
        if (this.props.projectBandwidthVersion < 2) {
            const upgrade = await this.projectService.showUpgradeBandwidthDialog();

            if (!upgrade) {
                eventTracking.logUserEvent(
                    'Application',
                    'Bandwidth update rejected - Dynamic FPS',
                );
                return;
            } else {
                eventTracking.logUserEvent(
                    'Application',
                    'Bandwidth update accepted - Dynamic FPS',
                );
            }
        }

        this.props.onFpsModeChanged(checked ? 'dynamic' : 'fixed');
    };
}
