import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { PiaId } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import { getQuantityForProducts } from '../selectors';
import { Stack, PiaImage, Text, Quantity, Button, Box, Badge } from 'app/components';
import { t } from 'app/translate';
import { SystemAccessoriesService } from '../services';
import { ServiceLocator } from 'app/ioc';
import { getCurrentProjectLocked, getPiaItemsRecord, PiaItemMsrp } from 'app/modules/common';

interface IAccessoryItemOwnProps {
    productId: PiaId;
    hideSelectionBackground?: boolean;
}

interface IAccessoryItemProps extends IAccessoryItemOwnProps {
    name?: string;
    quantity?: number;
    isDiscontinued: boolean;
    isLocked: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IAccessoryItemOwnProps,
): IAccessoryItemProps => {
    const quantities = getQuantityForProducts(storeState);
    const accessory = getPiaItemsRecord(storeState)[ownProps.productId];
    const isLocked = getCurrentProjectLocked(storeState);

    return {
        name: accessory.name,
        quantity: quantities[ownProps.productId],
        isDiscontinued: accessory.state > PiaItemState.EXTERNALLY_ANNOUNCED,
        isLocked,
        ...ownProps,
    };
};

class AccessoryItemContainer extends React.Component<IAccessoryItemProps> {
    private systemAccessoriesService: SystemAccessoriesService;

    constructor(props: IAccessoryItemProps) {
        super(props);
        this.systemAccessoriesService = ServiceLocator.get(SystemAccessoriesService);
    }

    public render() {
        return (
            <Box
                paddingY="half"
                paddingX="base"
                color={
                    this.props.quantity && !this.props.hideSelectionBackground
                        ? 'yellow1'
                        : undefined
                }
                testId={`system_accessory_model_name_${this.props.name}`}
            >
                <Stack justifyContent="between" alignItems="center" flex="shrinkAndGrow">
                    <Stack alignItems="center" flex="shrink">
                        <PiaImage icon="category" piaId={this.props.productId} />
                        <Text>{this.props.name}</Text>
                        {this.props.isDiscontinued && (
                            <Badge label={t.badgeDiscontinued} type="red" compact />
                        )}
                    </Stack>
                    <Stack alignItems="center">
                        <PiaItemMsrp piaId={this.props.productId} includeMultipacks />
                        {this.props.quantity && (
                            <Quantity
                                disabled={this.props.isLocked}
                                min={1}
                                max={999}
                                value={this.props.quantity}
                                onDelete={this.delete}
                                onChange={this.updateQuantity}
                            />
                        )}
                        {!this.props.isLocked && !this.props.quantity && (
                            <Button onClick={this.add}>{t.add}</Button>
                        )}
                    </Stack>
                </Stack>
            </Box>
        );
    }

    private add = () => {
        this.systemAccessoriesService.add(this.props.productId);
    };

    private updateQuantity = (value: number) => {
        this.systemAccessoriesService.updateQuantity(this.props.productId, value);
    };

    private delete = () => {
        this.systemAccessoriesService.delete(this.props.productId);
    };
}

export const AccessoryItem = connect(mapStateToProps)(AccessoryItemContainer);
