import { injectable } from 'inversify';
import type { PiaId } from 'app/core/pia';
import type { IItemEntity } from 'app/core/persistence';
import { ItemService, CurrentProjectService } from 'app/core/persistence';

@injectable()
export class SystemAccessoriesService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
    ) {}

    /** Adds or updates quantity of product id */
    public add = (productId: PiaId): Promise<IItemEntity> => {
        const item = this.getSystemAccessoryFromCurrentProject(productId);
        if (item) {
            // We only allow one item per product
            return this.updateQuantity(productId, item.quantity + 1);
        }

        return this.itemService.addByParentId(this.currentProjectService.getProjectId(), {
            name: '',
            description: '',
            notes: '',
            productId,
            quantity: 1,
            properties: {
                systemAccessory: {},
            },
        });
    };

    public updateQuantity = async (productId: PiaId, quantity: number): Promise<IItemEntity> => {
        const item = this.getSystemAccessoryFromCurrentProject(productId);

        if (!item) {
            throw new Error(`Could not find item to update: ${productId}`);
        }

        const updatedItem = await this.itemService.updateItem(item._id, { quantity });
        if (!updatedItem) {
            throw new Error(`Could not updated item: ${item}`);
        }
        return updatedItem;
    };

    public delete = (productId: PiaId) => {
        const item = this.getSystemAccessoryFromCurrentProject(productId);

        if (item) {
            this.itemService.deleteItem(item._id);
        }
    };

    private getSystemAccessoryFromCurrentProject = (productId: PiaId) =>
        this.currentProjectService
            .getAllEntitiesOfType('item')
            .filter((item) => !!item.properties.systemAccessory)
            .find((item) => item.productId === productId);
}
