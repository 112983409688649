import { creationDateReverseComparator, idComparator } from 'app/utils';
import { injectable } from 'inversify';
import type {
    PersistenceMemoryRepository,
    IEntity,
    IProjectEntity,
    Id,
    IScheduleEntity,
    ITimeSerieEntity,
    IProfileEntity,
    IItemEntity,
    IPersistence,
    IItemPropertiesEntity,
    IProfileOverridePropertiesEntity,
    IRecordingSettingsEntity,
    IScenarioEntity,
    IItemRelationEntity,
    ICameraPropertiesFilterEntity,
    IIdRev,
    ICustomItemEntity,
    IBaseEntity,
    IProjectQuotationEntity,
    IFloorPlanEntity,
    IInstallationPointEntity,
    IFloorPlanExportEntity,
    IFreeTextPointEntity,
    ICustomCameraPropertiesEntity,
    ProjectDbOrigin,
    IPartnerItemEntity,
    IPartnerItemPropertiesEntity,
    IBlockerEntity,
    IMapLocationEntity,
} from '../../userDataPersistence';
import {
    defaultDockingStationFilter,
    defaultCameraExtensionFilter,
    ProjectRepository,
    ProfileRepository,
    ScheduleRepository,
    FreeTextPointRepository,
    TimeSerieRepository,
    ItemRepository,
    PersistenceDatabaseRepository,
    ItemRelationRepository,
    CustomItemRepository,
    RepairService,
    ProjectQuotationRepository,
    FloorPlanRepository,
    InstallationPointRepository,
    QuotationProgress,
    PartnerItemRepository,
    BlockerRepository,
    MapLocationRepository,
} from '../../userDataPersistence';

import { getParentId } from '../../utils';
import { FloorPlanService } from '../FloorPlan.service';
import { InstallationPointDeviceService } from '../InstallationPointDevice.service';

type IdMap = Record<Id, IIdRev>;
type ImportEntity = IPersistence<IBaseEntity>;

@injectable()
export class EntityImportService {
    constructor(
        private projectRepository: ProjectRepository,
        private projectQuotationRepository: ProjectQuotationRepository,
        private profileRepository: ProfileRepository,
        private scheduleRepository: ScheduleRepository,
        private timeSerieRepository: TimeSerieRepository,
        private itemRepository: ItemRepository,
        private customItemRepository: CustomItemRepository,
        private databaseRepository: PersistenceDatabaseRepository,
        private itemRelationRepository: ItemRelationRepository,
        private partnerItemRepository: PartnerItemRepository,
        private repairService: RepairService,
        private floorPlanRepository: FloorPlanRepository,
        private freeTextPointRepository: FreeTextPointRepository,
        private installationPointRepository: InstallationPointRepository,
        private floorPlanService: FloorPlanService,
        private blockerRepository: BlockerRepository,
        private mapLocationRepository: MapLocationRepository,
    ) {}

    /**
     * Imports a project and all of its entities. Before importing all data will be migrated
     * to the latest version if required.
     *
     * @param originalProjectId the id of the project to import, stored in the provided memory repository.
     * @param memoryRepository repository containing the project and all of its children and related entities.
     * @param projectName the name to set to the imported project.
     */
    public async importProject(
        originalProjectId: Id,
        memoryRepository: PersistenceMemoryRepository,
        projectName: string,
        newProjectDbOrigin?: ProjectDbOrigin,
    ): Promise<Id> {
        memoryRepository.clearAllRevs();

        const originalProject: IPersistence<IProjectEntity> = (await memoryRepository.get(
            originalProjectId,
        )) as IPersistence<IProjectEntity>;

        if (!originalProject) {
            throw Error(`Project missing during import`);
        }

        await this.repairService.checkAndRepairProject(originalProjectId, memoryRepository);

        /**
         * During the import references need to be updated from old ids to new ids.
         * The idMap contains all mappings for resolving an old id into a new id.
         */
        const idMap: IdMap = {};

        try {
            return await this.importProjectAndChildren(
                originalProject,
                memoryRepository,
                projectName,
                idMap,
                newProjectDbOrigin,
            );
        } catch (error) {
            /*
             * The import flow will be improved in future versions since right now
             * we have a slim chance that corruption can occur which won't be
             * rollbacked properly. Much of this is related to the importing being
             * forced to add entities in multiple transactions since we can't
             * generate ids outside of repositories.
             *
             * If we re-design the id generation a bit we could enable the import
             * to be performed in one single transaction which would prevent the
             * persisted data from being in a corrupt state using the bulkUpdate
             * method.
             *
             * Currently, if we encounter any errors we delete all entities that
             * we know are persisted using a single transaction. If this also fails,
             * we have no good way to recover from the state we are in.
             */
            try {
                await this.rollbackImport(idMap);
            } catch (rollbackImportError) {
                console.error('rollbackImport', rollbackImportError);
            }

            throw error;
        }
    }

    private async importProjectAndChildren(
        originalProject: IPersistence<IProjectEntity>,
        memoryRepository: PersistenceMemoryRepository,
        projectName: string,
        idMap: IdMap,
        newProjectDbOrigin?: ProjectDbOrigin,
    ): Promise<Id> {
        /**
         * The import first obtains an id for the new project. This is then used
         * when constructing the path of the remaining project entities.
         *
         * When all entities except the project itself have been successfully created,
         * we create the project in an atomic operation. The benefit of this approach
         * is that when a project is imported, we either get the entire project or
         * (in the case of a failed import) nothing. If the rollback also fails we
         * may get orphan entities in the database but no corrupted projects.
         */

        const newProjectId = this.projectRepository.createNewEntityId();

        idMap[originalProject._id] = { _id: newProjectId, _rev: '' };

        const projectRootPath = [newProjectId];

        const schedules: IEntity[] = await memoryRepository.getAll('schedule');
        await this.importScheduleEntities(
            memoryRepository,
            idMap,
            projectRootPath,
            schedules as Array<IPersistence<IScheduleEntity>>,
        );

        const quotations: IEntity[] = await memoryRepository.getAll('quotation');
        const importedProjectQuotations = await this.importProjectQuotationEntities(
            idMap,
            projectRootPath,
            quotations as Array<IPersistence<IProjectQuotationEntity>>,
        );

        const profiles: IEntity[] = await memoryRepository.getAll('profile');
        await this.importProfileEntities(
            idMap,
            projectRootPath,
            profiles as Array<IPersistence<IProfileEntity>>,
        );

        const items: IEntity[] = await memoryRepository.getAll('item');
        await this.importItems(idMap, items as Array<IPersistence<IItemEntity>>);

        const customItems: IEntity[] = await memoryRepository.getAll('customItem');
        await this.importItems(idMap, customItems as Array<IPersistence<ICustomItemEntity>>);

        const partnerItems: IEntity[] = await memoryRepository.getAll('partnerItem');
        await this.importItems(idMap, partnerItems as Array<IPersistence<IPartnerItemEntity>>);

        const itemsRelations = (await memoryRepository.getAll('itemRelation')) as Array<
            IPersistence<IItemRelationEntity>
        >;

        await this.importItemRelations(idMap, itemsRelations);

        const mapLocations: IEntity[] = await memoryRepository.getAll('mapLocation');
        const sortedMapLocations = mapLocations.sort(creationDateReverseComparator);
        await this.importItems(
            idMap,
            sortedMapLocations as Array<IPersistence<IMapLocationEntity>>,
        );

        const floorPlans: IEntity[] = await memoryRepository.getAll('floorPlan');
        const sortedFloorPlans = floorPlans.sort(creationDateReverseComparator);
        await this.importItemsSequentially(
            idMap,
            sortedFloorPlans as Array<IPersistence<IFloorPlanEntity>>,
        );

        const blockers: IEntity[] = await memoryRepository.getAll('blocker');
        await this.importItems(idMap, blockers as Array<IPersistence<IBlockerEntity>>);

        const freeTextPoints: IEntity[] = await memoryRepository.getAll('freeTextPoint');
        await this.importItems(idMap, freeTextPoints as Array<IPersistence<IFreeTextPointEntity>>);

        const installationPoints: IEntity[] = await memoryRepository.getAll('installationPoint');
        const sortedInstallationPoints = installationPoints
            .sort(idComparator)
            .sort(creationDateReverseComparator);
        const importedInstallationPoints = await this.importItems(
            idMap,
            sortedInstallationPoints as Array<IPersistence<IInstallationPointEntity>>,
        );

        this.updateProjectQuotationReferences(idMap, importedProjectQuotations);
        this.updateInstallationPointRelations(
            importedInstallationPoints as Array<IPersistence<IInstallationPointEntity>>,
            idMap,
        );

        const newProject = await this.importProjectEntity(
            idMap,
            originalProject,
            newProjectId,
            projectName,
            newProjectDbOrigin,
        );

        return newProject._id;
    }

    private updateInstallationPointRelations(
        installationPoints: IPersistence<IInstallationPointEntity>[],
        idMap: IdMap,
    ) {
        const children = installationPoints.filter(
            (installationPoint) => installationPoint.parentId,
        );
        Promise.all(
            children.map(async (child) => {
                child.parentId = idMap[child.parentId!]._id;
                await this.installationPointRepository.update(child);
            }),
        );
    }

    /**
     * Performs a rollback of the import by deleting all entities already
     * persisted in the database.
     * @param idMap the map of ids that are persisted
     */
    private rollbackImport(idMap: IdMap) {
        const idsOfImportedEntities: IIdRev[] = Object.values(idMap);
        return this.databaseRepository.bulkDelete(idsOfImportedEntities);
    }

    private importProjectEntity(
        idMap: IdMap,
        originalProject: IProjectEntity,
        newProjectId: Id,
        newProjectName: string,
        newProjectDbOrigin?: ProjectDbOrigin,
    ): Promise<IPersistence<IProjectEntity>> {
        return this.projectRepository.addWithId(
            {
                ...originalProject,
                locked: false,
                path: [],
                name: newProjectName,
                state: QuotationProgress.Designing,
                projectDbOrigin: newProjectDbOrigin,
                defaultProfile:
                    originalProject.defaultProfile !== ''
                        ? this.resolveIdOrThrow(idMap, originalProject.defaultProfile)
                        : '',
            },
            newProjectId,
        );
    }

    private updateCustomItemPricesReferences(
        idMap: IdMap,
        customItemPrices: Record<Id, number>,
    ): Record<Id, number> {
        const updatedCustomItemPrices: Record<Id, number> = {};

        Object.keys(customItemPrices).map((key) => {
            // 3rd party products are saved with pia id - eg: 55555
            if (!isNaN(Number(key))) {
                updatedCustomItemPrices[key] = customItemPrices[key];
            } else {
                const updatedId = this.resolveIdOrNull(idMap, key);

                if (updatedId) {
                    updatedCustomItemPrices[updatedId] = customItemPrices[key];
                }
            }
        });

        return updatedCustomItemPrices;
    }

    private async importScheduleEntities(
        memoryRepository: PersistenceMemoryRepository,
        idMap: IdMap,
        path: Id[],
        schedules: Array<IPersistence<IScheduleEntity>>,
    ): Promise<void> {
        // First we add schedules and their timeseries without inverse,
        // then we can use the idMap to get the correct path for the inverted ones,
        // since schedules have an original schedule as parent, and timeseries may point to an original timeserie.

        const schedulesWithoutInverse = schedules.filter((a) => {
            const parentId = getParentId(a);
            return schedules.find((b) => b._id === parentId) === undefined;
        });
        await this.addNonInvertedSchedules(memoryRepository, idMap, path, schedulesWithoutInverse);

        const invertedSchedules = schedules.filter((c) => {
            const invParentId = getParentId(c);
            return schedules.find((d) => d._id === invParentId) !== undefined;
        });
        await this.addInvertedSchedules(memoryRepository, idMap, path, invertedSchedules);
    }

    private async addNonInvertedSchedules(
        memoryRepository: PersistenceMemoryRepository,
        idMap: IdMap,
        path: Id[],
        schedules: Array<IPersistence<IScheduleEntity>>,
    ): Promise<void> {
        for (const originalSchedule of schedules) {
            const importedSchedule = await this.scheduleRepository.add({
                type: 'schedule',
                name: originalSchedule.name,
                systemDefined: originalSchedule.systemDefined,
                path: [...path],
                locked: false,
                archived: originalSchedule.archived,
            });

            idMap[originalSchedule._id] = this.getIdRevObject(importedSchedule);

            const timeSeries = (await memoryRepository.getDescendants(originalSchedule._id)).filter(
                (descendant) => descendant.type === 'timeSerie',
            ) as Array<IPersistence<ITimeSerieEntity>>;
            const nonInvertedTimeSeries = timeSeries
                .filter((timeSerie) => getParentId(timeSerie) === originalSchedule._id)
                .sort((serieA, serieB) => {
                    return serieA.creationDate > serieB.creationDate
                        ? 1
                        : serieA.creationDate < serieB.creationDate
                          ? -1
                          : 0;
                });

            for (const originalTimeSerie of nonInvertedTimeSeries) {
                const importedTimeSerie = await this.timeSerieRepository.add({
                    type: 'timeSerie',
                    days: originalTimeSerie.days,
                    start: originalTimeSerie.start,
                    end: originalTimeSerie.end,
                    path: [...importedSchedule.path],
                    locked: false,
                    archived: originalTimeSerie.archived,
                });

                idMap[originalTimeSerie._id] = this.getIdRevObject(importedTimeSerie);
            }
        }
    }

    private async addInvertedSchedules(
        memoryRepository: PersistenceMemoryRepository,
        idMap: IdMap,
        path: Id[],
        schedules: Array<IPersistence<IScheduleEntity>>,
    ) {
        for (const invertedSchedule of schedules) {
            const newPath = [...path];
            // parentId should exist in the idMap since this is an inverse
            const parentId = getParentId(invertedSchedule);
            if (parentId) {
                newPath.push(idMap[parentId]._id);
            }

            const importedSchedule = await this.scheduleRepository.add({
                type: 'schedule',
                name: invertedSchedule.name,
                systemDefined: invertedSchedule.systemDefined,
                path: [...newPath],
                locked: false,
                archived: invertedSchedule.archived,
            });

            idMap[invertedSchedule._id] = this.getIdRevObject(importedSchedule);

            const invertedTimeSeries = (await memoryRepository.getDescendants(invertedSchedule._id))
                .filter((descendant) => descendant.type === 'timeSerie')
                .sort((serieA, serieB) => {
                    return serieA.creationDate > serieB.creationDate
                        ? 1
                        : serieA.creationDate < serieB.creationDate
                          ? -1
                          : 0;
                }) as Array<IPersistence<ITimeSerieEntity>>;

            for (const invertedTimeSerie of invertedTimeSeries as Array<
                IPersistence<ITimeSerieEntity>
            >) {
                const importedTimeSerie = await this.timeSerieRepository.add({
                    type: 'timeSerie',
                    days: invertedTimeSerie.days,
                    start: invertedTimeSerie.start,
                    end: invertedTimeSerie.end,
                    path: [...importedSchedule.path],
                    locked: false,
                    archived: invertedTimeSerie.archived,
                    originalId: invertedTimeSerie.originalId
                        ? idMap[invertedTimeSerie.originalId]._id
                        : undefined,
                });

                idMap[invertedTimeSerie._id] = this.getIdRevObject(importedTimeSerie);
            }
        }
    }

    private async importProjectQuotationEntities(
        idMap: IdMap,
        path: Id[],
        projectQuotations: Array<IPersistence<IProjectQuotationEntity>>,
    ): Promise<Array<IPersistence<IProjectQuotationEntity>>> {
        const importedProjectQuotations: Array<IPersistence<IProjectQuotationEntity>> = [];

        for (const originalProjectQuotation of projectQuotations) {
            const importedProjectQuotation = await this.projectQuotationRepository.add({
                type: 'quotation',
                path: [...path],
                locked: false,
                archived: originalProjectQuotation.archived,
                pricesByPartNumber: originalProjectQuotation.pricesByPartNumber,
                customItemPrices: originalProjectQuotation.customItemPrices,
                partnerItemPrices: originalProjectQuotation.partnerItemPrices,
                header: originalProjectQuotation.header,
                footer: originalProjectQuotation.footer,
                msrpToQuoteMargin: originalProjectQuotation.msrpToQuoteMargin,
                validUntilDate: originalProjectQuotation.validUntilDate,
            });

            idMap[originalProjectQuotation._id] = this.getIdRevObject(importedProjectQuotation);

            importedProjectQuotations.push(importedProjectQuotation);
        }

        return importedProjectQuotations;
    }

    private updateProjectQuotationReferences(
        idMap: IdMap,
        importedProjectQuotations: Array<IPersistence<IProjectQuotationEntity>>,
    ): void {
        for (const importedProjectQuotation of importedProjectQuotations) {
            importedProjectQuotation.customItemPrices = this.updateCustomItemPricesReferences(
                idMap,
                importedProjectQuotation.customItemPrices,
            );

            this.projectQuotationRepository.update(importedProjectQuotation);
        }
    }

    private async importProfileEntities(
        idMap: IdMap,
        path: Id[],
        profiles: Array<IPersistence<IProfileEntity>>,
    ): Promise<void> {
        for (const originalProfile of profiles) {
            const importedProfile = await this.profileRepository.add({
                type: 'profile',
                path: [...path],
                locked: false,
                archived: originalProfile.archived,
                name: originalProfile.name,
                scenario: this.getImportableScenarioSettings(originalProfile.scenario),
                liveView: this.getImportableRecordingSettings(idMap, originalProfile.liveView),
                continuousRecording: this.getImportableRecordingSettings(
                    idMap,
                    originalProfile.continuousRecording,
                ),
                triggeredRecording: this.getImportableRecordingSettings(
                    idMap,
                    originalProfile.triggeredRecording,
                ),
                storage: {
                    retentionTime: originalProfile.storage.retentionTime,
                    useProjectSetting: originalProfile.storage.useProjectSetting,
                },
                audio: {
                    liveViewEnabled: originalProfile.audio.liveViewEnabled,
                    recordingEnabled: originalProfile.audio.recordingEnabled,
                },
                zipstream: {
                    zipStrength: originalProfile.zipstream.zipStrength,
                    zipProfile: originalProfile.zipstream.zipProfile,
                    gopDefault: originalProfile.zipstream.gopDefault,
                    gopMax: originalProfile.zipstream.gopMax,
                    gopMode: originalProfile.zipstream.gopMode,
                    fpsMode: originalProfile.zipstream.fpsMode,
                    useProjectSetting: originalProfile.zipstream.useProjectSetting,
                    minDynamicFps: originalProfile.zipstream.minDynamicFps,
                },
            });

            idMap[originalProfile._id] = this.getIdRevObject(importedProfile);
        }
    }

    private getImportableScenarioSettings(originalSettings: IScenarioEntity): IScenarioEntity {
        return {
            lightEnd: originalSettings.lightEnd,
            lightStart: originalSettings.lightStart,
            nightLighting: originalSettings.nightLighting,
            scenarioId: originalSettings.scenarioId,
            sceneDetails: originalSettings.sceneDetails,
        };
    }

    private getImportableRecordingSettings(
        idMap: IdMap,
        originalSettings: IRecordingSettingsEntity,
    ): IRecordingSettingsEntity {
        return {
            compression: originalSettings.compression,
            dayMotion: originalSettings.dayMotion,
            dayTriggerTime: originalSettings.dayTriggerTime,
            frameRate: originalSettings.frameRate,
            nightMotion: originalSettings.nightMotion,
            nightTriggerTime: originalSettings.nightTriggerTime,
            resolution: originalSettings.resolution,
            schedule: this.resolveNullableId(idMap, originalSettings.schedule),
            videoEncoding: originalSettings.videoEncoding,
            useAverageBitrate: originalSettings.useAverageBitrate,
        };
    }

    private getCameraPropertiesFilterEntity(
        originalFilter: ICameraPropertiesFilterEntity,
    ): ICameraPropertiesFilterEntity {
        return {
            cameraTypes: originalFilter.cameraTypes,
            sensorUnitTypes: originalFilter.sensorUnitTypes,
            corridorFormat: originalFilter.corridorFormat,
            distanceToTarget: originalFilter.distanceToTarget,
            horizontalFov: originalFilter.horizontalFov,
            installationHeight: originalFilter.installationHeight,
            lightConditions: originalFilter.lightConditions,
            outdoor: originalFilter.outdoor,
            pixelDensity: originalFilter.pixelDensity,
            targetHeight: originalFilter.targetHeight,
            panoramaMode: originalFilter.panoramaMode,
            applications: originalFilter.applications,
            isSceneFilterActive: originalFilter.isSceneFilterActive,
            isFilterChanged: originalFilter.isFilterChanged,
        };
    }

    private getCustomCameraPropertiesEntity(
        originalCustomCamera: ICustomCameraPropertiesEntity | undefined,
    ): ICustomCameraPropertiesEntity | undefined {
        if (!originalCustomCamera) {
            return undefined;
        }
        return {
            activated: originalCustomCamera.activated,
            bandwidth: originalCustomCamera.bandwidth,
            cameraType: originalCustomCamera.cameraType,
            horizontalFovMax: originalCustomCamera.horizontalFovMax,
            horizontalFovMin: originalCustomCamera.horizontalFovMin,
            modelName: originalCustomCamera.modelName,
            powerConsumption: originalCustomCamera.powerConsumption,
            resolutionHorizontal: originalCustomCamera.resolutionHorizontal,
            resolutionVertical: originalCustomCamera.resolutionVertical,
            poe: originalCustomCamera.poe,
        };
    }

    /**
     * Keep a map with id:s of the original (to-be imported) items
     * and the id:s of the newly imported copies of these.
     */
    private addEntityToIdMap(oldId: Id, newEntity: ImportEntity, idMap: IdMap) {
        idMap[oldId] = this.getIdRevObject(newEntity);
        return idMap[oldId];
    }

    /**
     * Take a path of old (to-be imported) id:s and return a new path with the id:s of the same imported entities.
     * Uses the idMap to return the imported id. If an entity in the path is not yet imported, import it and
     * all of its paths.
     */
    private importPath = async (path: Id[], idMap: IdMap, entities: ImportEntity[]) =>
        Promise.all(
            path.map(async (id) => {
                if (!idMap[id]) {
                    const entity = this.findEntityToImportById(id, entities);

                    if (!entity) {
                        throw new Error(`Item with id: ${id} not found in import data`);
                    }

                    const newItem = await this.importEntityWithEntirePath(entity, idMap, entities);

                    return this.addEntityToIdMap(id, newItem, idMap)._id;
                }
                return idMap[id]._id;
            }),
        );

    /**
     * Import an item or relation and all of its path.
     * First add the entity, then recursively follow the entity's path
     * and import entities not yet imported.
     * Update the entity with the new path that contains the new imported
     * entity id:s.
     */
    private async importEntityWithEntirePath(
        entity: ImportEntity,
        idMap: IdMap,
        entities: ImportEntity[],
    ): Promise<ImportEntity> {
        const newEntity = await this.getEntity(entity, idMap);

        this.addEntityToIdMap(entity._id, newEntity, idMap);

        const newPath = await this.importPath(entity.path, idMap, entities);

        return entity.type === 'itemRelation'
            ? this.itemRelationRepository.update({
                  ...(newEntity as IPersistence<IItemRelationEntity>),
                  path: newPath,
              })
            : this.itemRepository.update({
                  ...(newEntity as IPersistence<IItemEntity>),
                  path: newPath,
              });
    }

    private getEntity = async (entity: IBaseEntity, idMap: IdMap) => {
        switch (entity.type) {
            case 'itemRelation':
                return this.addRelationToRepository(
                    entity as IPersistence<IItemRelationEntity>,
                    [],
                    idMap,
                );
            case 'customItem':
                return this.addCustomItemToRepository(
                    entity as IPersistence<ICustomItemEntity>,
                    [],
                    idMap,
                );
            case 'item':
                return this.addItemToRepository(entity as IPersistence<IItemEntity>, [], idMap);
            case 'floorPlan':
                const importableFloorPlan = await this.floorPlanService.getFloorPlanFromExportable(
                    entity as IFloorPlanExportEntity,
                );
                return this.addFloorPlanToRepository(
                    importableFloorPlan as IPersistence<IFloorPlanEntity>,
                    [],
                );
            case 'mapLocation':
                return this.addMapLocationToRepository(
                    entity as IPersistence<IMapLocationEntity>,
                    [],
                    idMap,
                );
            case 'blocker':
                return this.addBlockerToRepository(
                    entity as IPersistence<IBlockerEntity>,
                    [],
                    idMap,
                );
            case 'installationPoint':
                return this.addInstallationPointToRepository(
                    entity as IPersistence<IInstallationPointEntity>,
                    [],
                    idMap,
                );
            case 'freeTextPoint':
                return this.addFreeTextToRepository(
                    entity as IPersistence<IFreeTextPointEntity>,
                    [],
                    idMap,
                );
            case 'partnerItem':
                return this.addPartnerItemToRepository(
                    entity as IPersistence<IPartnerItemEntity>,
                    [],
                );
            default:
                throw new Error(`Entity type ${entity.type} not importable`);
        }
    };

    private importItems = async (idMap: IdMap, items: Array<IPersistence<IBaseEntity>>) =>
        Promise.all(items.map(async (item) => this.importEntityWithEntirePath(item, idMap, items)));

    /**
     * Import items sequentially. This ensures the preservation of chronological order
     */
    private importItemsSequentially = async (
        idMap: IdMap,
        items: Array<IPersistence<IBaseEntity>>,
    ) =>
        items.reduce(
            (promise, item) =>
                promise.then(async () => {
                    await this.importEntityWithEntirePath(item, idMap, items);
                }),
            Promise.resolve(),
        );

    private importItemRelations = async (
        idMap: IdMap,
        relations: Array<IPersistence<IItemRelationEntity>>,
    ) =>
        Promise.all(
            relations.map(async (relation) =>
                this.importEntityWithEntirePath(relation, idMap, relations),
            ),
        );

    private findEntityToImportById = (id: Id, entities: ImportEntity[]) =>
        entities.find((entity) => entity._id === id);

    private addItemToRepository = async (
        item: IPersistence<IItemEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.itemRepository.add({
            type: 'item',
            path,
            locked: false,
            archived: item.archived,
            name: item.name,
            color: item.color,
            description: item.description,
            notes: item.notes,
            productId: item.productId,
            quantity: item.quantity,
            properties: this.getImportableItemProperties(idMap, item.properties),
            replaceWithBareboneId: item.replaceWithBareboneId,
            networkSettings: item.networkSettings,
            analyticRange: item.analyticRange,
        });

    private addPartnerItemToRepository = async (
        item: IPersistence<IPartnerItemEntity>,
        path: Id[],
    ) =>
        this.partnerItemRepository.add({
            type: 'partnerItem',
            path,
            locked: false,
            archived: item.archived,
            name: item.name,
            productId: item.productId,
            quantity: item.quantity,
            vendor: item.vendor,
            url: item.url,
            vendorId: item.vendorId,
            properties: this.getImportablePartnerItemProperties(item.properties),
        });

    private addFloorPlanToRepository = async (
        floorPlan: IPersistence<IFloorPlanEntity>,
        path: Id[],
    ) =>
        this.floorPlanRepository.add({
            type: 'floorPlan',
            path,
            locked: false,
            archived: floorPlan.archived,
            name: floorPlan.name,
            image: floorPlan.image,
            mapType: floorPlan.mapType,
            location: floorPlan.location,
            isDefault: floorPlan.isDefault,
        });

    private addFreeTextToRepository = async (
        freeText: IPersistence<IFreeTextPointEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.freeTextPointRepository.add({
            type: 'freeTextPoint',
            path,
            locked: false,
            archived: freeText.archived,
            text: freeText.text,
            size: freeText.size,
            textColor: freeText.textColor,
            backgroundColor: freeText.backgroundColor,
            location: freeText.location,
            mapOrigin: this.resolveIdOrKeep(idMap, freeText.mapOrigin),
        });

    private addBlockerToRepository = async (
        blocker: IPersistence<IBlockerEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.blockerRepository.add({
            type: 'blocker',
            path,
            locked: false,
            archived: blocker.archived,
            floorLevel: blocker.floorLevel,
            latLngs: blocker.latLngs,
            mapOrigin: this.resolveIdOrKeep(idMap, blocker.mapOrigin),
        });

    private addMapLocationToRepository = async (
        item: IPersistence<IMapLocationEntity>,
        path: Id[],
        _idMap: IdMap,
    ) =>
        this.mapLocationRepository.add({
            type: 'mapLocation',
            path,
            locked: false,
            name: item.name,
            bounds: item.bounds,
            archived: item.archived,
        });

    private addInstallationPointToRepository = async (
        installationPoint: IPersistence<IInstallationPointEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.installationPointRepository.add({
            type: 'installationPoint',
            path,
            locked: false,
            archived: installationPoint.archived,
            location: installationPoint.location,
            mapOrigin: this.resolveIdOrKeep(idMap, installationPoint.mapOrigin),
            labelOffset:
                installationPoint.labelOffset || InstallationPointDeviceService.IP_LABEL_OFFSET,
            height: installationPoint.height,
            sensors: installationPoint.sensors,
            speaker: installationPoint.speaker,
            radar: installationPoint.radar,
            panRange: installationPoint.panRange,
            floorPlanId: installationPoint.floorPlanId
                ? this.resolveIdOrThrow(idMap, installationPoint.floorPlanId)
                : undefined,
            parentId: installationPoint.parentId,
            name: installationPoint.name,
            serialNumber: undefined,
        });

    private addCustomItemToRepository = async (
        item: IPersistence<ICustomItemEntity>,
        path: Id[],
        _idMap: IdMap,
    ) =>
        this.customItemRepository.add({
            category: item.category,
            type: item.type,
            path,
            locked: false,
            archived: item.archived,
            name: item.name,
            partnerProductId: item.partnerProductId,
            partNumber: item.partNumber,
            quantity: item.quantity,
            vendor: item.vendor,
        });

    private addRelationToRepository = async (
        relation: IPersistence<IItemRelationEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.itemRelationRepository.add({
            childId: this.resolveIdOrThrow(idMap, relation.childId),
            parentId: this.resolveIdOrThrow(idMap, relation.parentId),
            path,
            locked: false,
            archived: relation.archived,
            relationType: relation.relationType,
            type: 'itemRelation',
        });

    private getImportableItemProperties(
        idMap: IdMap,
        properties: IItemPropertiesEntity,
    ): IItemPropertiesEntity {
        const importableProperties: IItemPropertiesEntity = {};

        if (properties.analogCamera) {
            importableProperties.analogCamera = {
                associatedProfile: properties.analogCamera.associatedProfile
                    ? this.resolveIdOrThrow(idMap, properties.analogCamera.associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(
                    idMap,
                    properties.analogCamera.profileOverride,
                ),
            };
        }

        if (properties.camera) {
            const { filter, associatedProfile, profileOverride, customCameraProperties } =
                properties.camera;

            importableProperties.camera = {
                filter: this.getCameraPropertiesFilterEntity(filter),
                associatedProfile: associatedProfile
                    ? this.resolveIdOrThrow(idMap, associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(idMap, profileOverride),
                customCameraProperties:
                    this.getCustomCameraPropertiesEntity(customCameraProperties),
            };
        }

        if (properties.sensorUnit) {
            const { filter, associatedProfile, profileOverride } = properties.sensorUnit;

            importableProperties.sensorUnit = {
                filter: this.getCameraPropertiesFilterEntity(filter),
                associatedProfile: associatedProfile
                    ? this.resolveIdOrThrow(idMap, associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(idMap, profileOverride),
            };
        }

        if (properties.virtualProduct) {
            const { associatedProfile, profileOverride } = properties.virtualProduct;
            importableProperties.virtualProduct = {
                associatedProfile: associatedProfile
                    ? this.resolveIdOrThrow(idMap, associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(idMap, profileOverride),
            };
        }

        if (properties.encoder) {
            const filter = properties.encoder.filter;

            importableProperties.encoder = {
                filter: {
                    blade: filter.blade,
                    channels: filter.channels,
                    twoWayAudio: filter.twoWayAudio,
                    outdoor: filter.outdoor,
                },
            };
        }

        if (properties.mainUnit) {
            const filter = properties.mainUnit.filter;

            importableProperties.mainUnit = {
                filter: {
                    channels: filter.channels,
                    twoWayAudio: filter.twoWayAudio,
                    WDRTechnology: filter.WDRTechnology,
                    ruggedizedEN50155: filter.ruggedizedEN50155,
                    alarmInputsOutputs: filter.alarmInputsOutputs,
                    outdoor: filter.outdoor,
                },
            };
        }

        if (properties.doorController) {
            importableProperties.doorController = { filter: properties.doorController.filter };
        }

        if (properties.door) {
            importableProperties.door = {
                nbrOfLocks: properties.door.nbrOfLocks,
            };
        }

        if (properties.alerter) {
            importableProperties.alerter = { filter: properties.alerter.filter };
        }

        if (properties.speaker) {
            const filter = properties.speaker.filter;

            importableProperties.speaker = {
                filter: {
                    placement: filter.placement,
                    outdoor: filter.outdoor,
                    basicSolution: filter.basicSolution,
                    installationHeight: filter.installationHeight,
                    listeningArea: filter.listeningArea,
                    wallLength: filter.wallLength,
                    isFilterChanged: filter.isFilterChanged,
                },
            };
        }

        if (properties.systemComponent) {
            importableProperties.systemComponent = {};
        }

        if (properties.partnerSystemComponent) {
            const {
                vendorName,
                category,
                dataSheetUrl,
                imageUrl,
                maxCameraCount,
                maxRecordingBandwidthBits,
                maxRecordingStorageMegaBytes,
            } = properties.partnerSystemComponent;

            importableProperties.partnerSystemComponent = {
                vendorName,
                category,
                dataSheetUrl,
                imageUrl,
                maxCameraCount,
                maxRecordingBandwidthBits,
                maxRecordingStorageMegaBytes,
            };
        }

        if (properties.environment) {
            importableProperties.environment = {};
        }

        if (properties.pac) {
            importableProperties.pac = { filter: properties.pac.filter };
        }

        if (properties.radarDetector) {
            importableProperties.radarDetector = { filter: properties.radarDetector.filter };
        }

        if (properties.decoder) {
            importableProperties.decoder = { filter: properties.decoder.filter };
        }

        if (properties.systemAccessory) {
            importableProperties.systemAccessory = {};
        }

        if (properties.accessory) {
            importableProperties.accessory = properties.accessory;
        }

        if (properties.application) {
            importableProperties.application = properties.application;
        }

        if (properties.peopleCounter) {
            importableProperties.peopleCounter = { filter: properties.peopleCounter.filter };
        }

        if (properties.lens) {
            const { sensorIndex } = properties.lens;
            importableProperties.lens = { sensorIndex };
        }

        if (properties.bodyWornCamera) {
            importableProperties.bodyWornCamera = {
                profile: {
                    sceneId: properties.bodyWornCamera.profile.sceneId,
                    resolution: properties.bodyWornCamera.profile.resolution,
                    retentionTimeInDays: properties.bodyWornCamera.profile.retentionTimeInDays,
                    scheduleId: properties.bodyWornCamera.profile.scheduleId
                        ? this.resolveIdOrThrow(idMap, properties.bodyWornCamera.profile.scheduleId)
                        : '',
                    activeRecordingInPercent:
                        properties.bodyWornCamera.profile.activeRecordingInPercent,
                },
                filter: properties.bodyWornCamera.filter,
            };
        }

        if (properties.cameraExtension) {
            importableProperties.cameraExtension = { filter: defaultCameraExtensionFilter };
        }

        if (properties.dockingStation) {
            importableProperties.dockingStation = { filter: defaultDockingStationFilter };
        }

        if (properties.systemController) {
            importableProperties.systemController = {};
        }

        if (properties.connectivityDevice) {
            importableProperties.connectivityDevice = {
                filter: properties.connectivityDevice.filter,
            };
        }

        if (properties.pagingConsole) {
            importableProperties.pagingConsole = {
                filter: properties.pagingConsole.filter,
            };
        }

        return importableProperties;
    }

    private getImportableProfileOverride(
        idMap: IdMap,
        profileOverride: IProfileOverridePropertiesEntity,
    ): IProfileOverridePropertiesEntity {
        const {
            scenario,
            liveView,
            continuousRecording,
            triggeredRecording,
            storage,
            customBandwidth,
            audio,
            zipstream,
        } = profileOverride;

        const importableOverride: IProfileOverridePropertiesEntity = {
            scenario: {
                lightEnd: scenario.lightEnd,
                lightStart: scenario.lightStart,
                nightLighting: scenario.nightLighting,
                scenarioId: scenario.scenarioId,
                sceneDetails: scenario.sceneDetails,
            },
            liveView: this.getImportableProfileOverrideRecordingSettings(idMap, liveView),
            continuousRecording: this.getImportableProfileOverrideRecordingSettings(
                idMap,
                continuousRecording,
            ),
            triggeredRecording: this.getImportableProfileOverrideRecordingSettings(
                idMap,
                triggeredRecording,
            ),
            storage: {
                retentionTime: storage.retentionTime,
                useProjectSetting: storage.useProjectSetting,
            },
            audio: {
                liveViewEnabled: audio.liveViewEnabled,
                recordingEnabled: audio.recordingEnabled,
            },
            zipstream: {
                gopDefault: zipstream.gopDefault,
                gopMax: zipstream.gopMax,
                gopMode: zipstream.gopMode,
                minDynamicFps: zipstream.minDynamicFps,
                zipStrength: zipstream.zipStrength,
                fpsMode: zipstream.fpsMode,
                useProjectSetting: zipstream.useProjectSetting,
            },
            customBandwidth,
        };

        if (profileOverride.liveView.schedule) {
            importableOverride.liveView.schedule = this.resolveUndefinableOrNullId(
                idMap,
                profileOverride.liveView.schedule,
            );
        }

        if (profileOverride.triggeredRecording.schedule) {
            importableOverride.triggeredRecording.schedule = this.resolveUndefinableOrNullId(
                idMap,
                profileOverride.triggeredRecording.schedule,
            );
        }

        if (profileOverride.continuousRecording.schedule) {
            importableOverride.continuousRecording.schedule = this.resolveUndefinableOrNullId(
                idMap,
                profileOverride.continuousRecording.schedule,
            );
        }

        return importableOverride;
    }

    private getImportableProfileOverrideRecordingSettings(
        idMap: IdMap,
        settings: Partial<IRecordingSettingsEntity>,
    ): Partial<IRecordingSettingsEntity> {
        return {
            compression: settings.compression,
            dayMotion: settings.dayMotion,
            dayTriggerTime: settings.dayTriggerTime,
            frameRate: settings.frameRate,
            nightMotion: settings.nightMotion,
            nightTriggerTime: settings.nightTriggerTime,
            resolution: settings.resolution,
            schedule: this.resolveUndefinableOrNullId(idMap, settings.schedule),
            useAverageBitrate: settings.useAverageBitrate,
            videoEncoding: settings.videoEncoding,
        };
    }

    private getImportablePartnerItemProperties(
        properties: IPartnerItemPropertiesEntity,
    ): IPartnerItemPropertiesEntity {
        if (properties.application) {
            properties.application = {};
        }
        return properties;
    }

    private resolveIdOrThrow(idMap: IdMap, id: Id): Id {
        const resolvedId = idMap[id];

        if (!resolvedId) {
            throw Error(
                `Tried to resolve id using original id '${id}', but no id was mapped to it.`,
            );
        }

        return resolvedId._id;
    }

    private resolveNullableId(idMap: IdMap, id?: Id | null): Id | null {
        if (id) {
            return this.resolveIdOrThrow(idMap, id);
        }

        return null;
    }

    // in the case of profile overrides, null and undefined means different things
    // `undefined` means "not overridden"
    // `null` means "explicitly unset"
    private resolveUndefinableOrNullId(idMap: IdMap, id?: Id | null): Id | undefined | null {
        if (id === null) {
            return null;
        } else {
            return this.resolveNullableId(idMap, id) || undefined;
        }
    }

    private resolveIdOrNull(idMap: IdMap, id: Id): Id | null {
        const resolvedId = idMap[id];

        if (!resolvedId) {
            return null;
        }
        return resolvedId._id;
    }

    private resolveIdOrKeep(idMap: IdMap, id: Id): Id {
        try {
            return this.resolveIdOrThrow(idMap, id);
        } catch {
            return id;
        }
    }

    private getIdRevObject(entity: IPersistence<IEntity>): IIdRev {
        return {
            _id: entity._id,
            _rev: entity._rev,
        };
    }
}
