import * as React from 'react';
import { Box, Stack, Text, CutCorner } from 'app/components';
import type { IAutoTestable } from 'app/components';
import type { Colors } from 'app/styles';
import type { IDataToDisplay } from '../../models';

interface IProposalRecordingItemProps extends IAutoTestable {
    title: string;
    titleColor?: Colors;
    backgroundColor: Colors;
    items: IDataToDisplay[];
}

export const ProposalRecordingItem: React.FunctionComponent<IProposalRecordingItemProps> = ({
    title,
    titleColor,
    items,
    backgroundColor,
    testId,
}) => (
    <CutCorner color={backgroundColor}>
        <Box padding="panel" paddingTop="base">
            <Stack spacing="base" vertical>
                <Text sizeOverride={16} color={titleColor ? titleColor : 'warmGrey6'} semiBold>
                    {title}
                </Text>
                <Stack spacing="base">
                    {items.map((item, key) => {
                        return (
                            <Box key={key}>
                                <CutCorner smallCut color="white">
                                    <Box
                                        paddingRight="panel"
                                        paddingLeft="panel"
                                        paddingY="half"
                                        paddingBottom="base"
                                    >
                                        <Stack spacing="none" alignItems="center" vertical>
                                            <Box paddingBottom="halfCell">
                                                <Text color="warmGrey6" small>
                                                    {item.title}
                                                </Text>
                                            </Box>
                                            <Text
                                                testId={`${testId}_${item.title}_value_txt`}
                                                sizeOverride={24}
                                                semiBold
                                                color="warmGrey6"
                                            >
                                                {item.value}
                                            </Text>
                                            <Text
                                                testId={`${testId}_${item.title}_unit_txt`}
                                                color="warmGrey6"
                                                semiBold
                                            >
                                                {item.subTitle}
                                            </Text>
                                        </Stack>
                                    </Box>
                                </CutCorner>
                            </Box>
                        );
                    })}
                </Stack>
            </Stack>
        </Box>
    </CutCorner>
);

ProposalRecordingItem.displayName = 'ProposalRecordingItem';
