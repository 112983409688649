export enum PoeClass {
    Class1 = '1',
    Class2 = '2',
    Class3 = '3',
    Class4 = '4',
    Class5 = '5',
    Class6 = '6',
    Class7 = '7',
    Class8 = '8',
    HighPoE = 'highPoe',
}

/**
 * Get a poe class number for a poeClass. Simplifies comparison
 */
export const getPoeClassNumber = (poeClass?: PoeClass): number => {
    switch (poeClass) {
        case PoeClass.Class1:
            return 1;
        case PoeClass.Class2:
            return 2;
        case PoeClass.Class3:
            return 3;
        case PoeClass.Class4:
            return 4;
        case PoeClass.Class5:
            return 5;
        case PoeClass.Class6:
        case PoeClass.HighPoE:
            return 6;
        case PoeClass.Class7:
            return 7;
        case PoeClass.Class8:
            return 8;
        default:
            return 0;
    }
};
