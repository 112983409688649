import { Stack, Box, NumberInput, Text } from 'app/components';
import type { IItemEntity, IPersistence, Id } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ItemService,
    ParentChildInstallationPointService,
    getParentId,
} from 'app/core/persistence';

import { useService } from 'app/ioc';
import type { IChannelInformation } from 'app/modules/common';
import {
    getChannelInformation,
    getCurrentProjectItem,
    getCurrentProjectLocked,
    getModelName,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import * as React from 'react';
import { useSelector } from 'react-redux';

interface IQuantityCol {
    itemId: Id;
    isChild: boolean | undefined;
}

export const QuantityCol: React.FC<IQuantityCol> = ({ itemId, isChild }) => {
    const locked = useSelector<IStoreState, boolean>(getCurrentProjectLocked);
    const item = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, itemId),
    );
    const showQuantity = !(item && deviceTypeCheckers.isDoor(item));
    const parentId = item && getParentId(item);
    const parentDevice = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, parentId),
    );
    const model = useSelector<IStoreState, string>((state) => getModelName(state, itemId));
    const { maxQuantity } = useSelector<IStoreState, IChannelInformation>((state) =>
        getChannelInformation(state, itemId, parentId),
    );

    const itemService = useService(ItemService);
    const parentChildInstallationPointService = useService(ParentChildInstallationPointService);

    const onQuantityChange = async (newQuantity: number, revertCallback: () => void) => {
        if (!item) return;

        const { quantity } = item;
        const updatedItem = await itemService.updateItem(itemId, {
            quantity: newQuantity,
        });

        // If the update did not succeed, revert the value
        if (!updatedItem) {
            revertCallback();
        } else if (newQuantity > quantity && isChild) {
            // If quantity is increased, we need to add child installation points to the parents in maps,
            // to make the child device quantity consistent.
            parentId &&
                (await parentChildInstallationPointService.addChildItemToMap(
                    itemId,
                    newQuantity - quantity,
                    parentId,
                ));
        }
    };

    if (!showQuantity) return null;

    return (
        <Stack spacing="quart" justifyContent="start">
            <Box minWidth="32px" alignItems="center" justifyContent="end">
                {parentDevice?.quantity && parentDevice.quantity > 1 && (
                    <Text inline bold color="grey4">
                        {parentDevice.quantity} ×
                    </Text>
                )}
            </Box>
            <NumberInput
                disabled={locked}
                noBorder
                min={1}
                max={maxQuantity}
                value={item?.quantity}
                onChange={onQuantityChange}
                transparent
                changeCriteria="debounced"
                testId={`${model}_quantity`}
            />
        </Stack>
    );
};
QuantityCol.displayName = 'QuantityCol';
