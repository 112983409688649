import type * as React from 'react';
import * as leaflet from 'leaflet';
import { useEffect, useLayoutEffect, useState } from 'react';
import { offset } from 'axis-webtools-util';
import type { ILatLng } from 'app/core/persistence';
import { useMapContext } from '../../context';
import { createRotatedImageOverlay } from './RotatedImageOverlay';
import { usePrevious } from 'app/hooks';
import { ColorsEnum } from 'app/styles';
import { t } from 'app/translate';

interface IImageProps {
    src: string | undefined;
    loadError?: boolean;
    position: ILatLng;
    width: number;
    height: number;
    angle: number;
    opacity: number;
    onDoubleClick: () => void;
    onDrag?: (latLng: leaflet.LatLng) => void;
}

export const Image: React.FC<IImageProps> = ({
    src,
    loadError,
    position,
    width,
    height,
    angle,
    opacity,
    onDrag,
    onDoubleClick,
}) => {
    const { leafletMap } = useMapContext();
    const prevSrc = usePrevious(src, src);

    const [img] = useState(
        createRotatedImageOverlay(
            src,
            leaflet.latLng(position.lat, position.lng),
            width,
            height,
            angle,
            {
                opacity: 0, // we start out transparent
                interactive: true,
            },
        ),
    );

    const [imgNotLoadedText] = useState(
        leaflet.polyline(
            [
                leaflet.latLng(position.lat, position.lng - 0.0001),
                leaflet.latLng(position.lat, position.lng + 0.0001),
            ],
            { opacity: 0 },
        ),
    );
    const [loaded, setLoaded] = useState(false);

    // set text on image not loaded
    useEffect(() => {
        const text = loadError ? t.imageLoadingError : null;
        imgNotLoadedText.setText(text, {
            center: true,
            attributes: { fill: ColorsEnum.grey6, 'font-size': '14px', 'font-weight': 'bold' },
        });
    }, [loadError, imgNotLoadedText]);

    // add image
    useEffect(() => {
        const onLoad = () => {
            setLoaded(true);
        };

        img.on('load', onLoad);
        img.addTo(leafletMap.map);
        imgNotLoadedText.addTo(leafletMap.map);

        return () => {
            img.off('load', onLoad);
            img.remove();
            imgNotLoadedText.remove();
        };
    }, [leafletMap.map, img, imgNotLoadedText]);

    // register event listeners
    useEffect(() => {
        if (!onDrag) {
            return;
        }
        // create an event handler for the mousemove event. Offset the latlng with
        // the diff between the initial mousedown latlng and the target position.
        const handleMove = (origin: leaflet.LeafletMouseEvent) => {
            const moveOffset = leaflet.latLng(
                origin.target.position.lat - origin.latlng.lat,
                origin.target.position.lng - origin.latlng.lng,
            );

            return (e: leaflet.LeafletMouseEvent) => {
                e.originalEvent.stopPropagation();
                e.originalEvent.preventDefault();
                onDrag(
                    leaflet.latLng(e.latlng.lat + moveOffset.lat, e.latlng.lng + moveOffset.lng),
                );
            };
        };

        img.onTouchStart(() => {
            img.onTouchMove((newPosition: ILatLng) => {
                onDrag(leaflet.latLng(newPosition.lat, newPosition.lng));
            });

            img.onTouchEnd(() => img.offTouchMove());
        });

        img.on('mousedown', (e: leaflet.LeafletMouseEvent) => {
            const onMove = handleMove(e);

            leafletMap.map.on('mousemove', onMove);
            leafletMap.map.once('mouseup', () => {
                leafletMap.map.off('mousemove', onMove);
            });
        });

        return () => {
            img.off('mousedown');
            img.offTouch();
        };
    }, [leafletMap.map, img, onDrag]);

    useEffect(() => {
        const clickHandler = (e: leaflet.LeafletMouseEvent) => {
            leaflet.DomEvent.stopPropagation(e);
            onDoubleClick();
        };

        img.on('dblclick', clickHandler);

        return () => {
            img.off('dblclick', clickHandler);
        };
    }, [img, onDoubleClick]);

    // update img props on change
    useLayoutEffect(() => {
        if (loaded) {
            img.setProps(leaflet.latLng(position.lat, position.lng), width, height, angle);
            img.setOpacity(opacity);
        }

        const left = offset(position)([-width / 2, 0]);
        const rigth = offset(position)([width / 2, 0]);
        imgNotLoadedText.setLatLngs([
            [left.lat, left.lng],
            [rigth.lat, rigth.lng],
        ]);
    }, [position, width, height, angle, loaded, opacity, img, imgNotLoadedText]);

    // update src on change
    useLayoutEffect(() => {
        src !== prevSrc && src !== undefined && img.setUrl(src);
    }, [src, prevSrc, img]);

    return null;
};

Image.displayName = 'Image';
