import type { Vector3 } from 'three';
import { BufferAttribute, BufferGeometry } from 'three';

export const createVerticalAxisGeometry = (
    start: Vector3,
    length: number,
    ticks: boolean,
    tickSpacing: number,
) => {
    const vertices = [start.x, start.y, start.z];

    if (ticks) {
        let segmentStart = start.y;
        while (length >= tickSpacing) {
            vertices.push(0, segmentStart + tickSpacing, 0);
            vertices.push(0.1, segmentStart + tickSpacing, 0);
            vertices.push(0, segmentStart + tickSpacing, 0);
            segmentStart += tickSpacing;
            length -= tickSpacing;
        }
        if (length > 0) {
            vertices.push(0, segmentStart + length, 0);
        }
    } else {
        vertices.push(0, start.y + length, 0);
    }

    const verticesBuffer = new Float32Array(vertices);

    const geometry = new BufferGeometry();

    geometry.setAttribute('position', new BufferAttribute(verticesBuffer, 3));

    return geometry;
};
