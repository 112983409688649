import * as React from 'react';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { Stack } from '../../layout';
import type { Icons } from '../../ui/icon/';
import { Icon } from '../../ui/icon/';
import { Box } from '../../containers/';
import { memoize } from 'lodash-es';

interface IStepRangeProps {
    /**
     * Step labels
     */
    labels: string[];
    /**
     * Icon to the left of the bar
     */
    iconLeft?: Icons;
    /**
     * Icon to the right of the bar
     */
    iconRight?: Icons;
    /**
     * The value of the slider
     */
    value: number;
    /**
     * The function to run when the user changes the value
     */
    onChange(value: number): void;
}

const thumbDiameter = '16px';
const lineHeight = '32px';
const halfLine = '16px';
const bottomMargin = '12px';
/**
 * Generate a range with marked steps with labels. Selected step is marked with a thumb.
 */
export const StepRange: React.FC<IStepRangeProps> = ({
    labels,
    iconLeft,
    iconRight,
    value,
    onChange,
}) => {
    const userAgent = window.navigator.userAgent;
    const isFirefox = userAgent.includes('Firefox');
    const isSafari = () => {
        return /.*Version.*Safari.*/.test(window.navigator.userAgent);
    };

    const rangeStepStyle = css`
        position: relative;
        font-size: 12px;
        padding: 0px;
        width: 100%;

        datalist {
            position: relative;
            display: flex;
            justify-content: space-between;
            height: auto;
            bottom: 10px; // firefox other value
            -webkit-user-select: none; //safari
            -moz-user-select: none; // firefox
            -ms-user-select: none; // edge
            user-select: none;
            pointer-events: none;
        }

        datalist option {
            font-size: 12px;
            background-color: ${ColorsEnum.grey4};
            width: 1px;
            height: 10px;
            text-align: center;
            min-height: ${bottomMargin};
            white-space: nowrap;
            padding: 0;
            line-height: ${lineHeight};
            color: ${ColorsEnum.grey4};
        }

        input {
            font-size: 12px;
            outline: none;
            -webkit-appearance: none;
            background: transparent;
            background-image: ${isFirefox
                ? `linear-gradient(
                transparent,
                transparent 7px,
                ${ColorsEnum.grey4} 7px,
                ${ColorsEnum.grey4} 9px,
                transparent 9px,
                transparent 16px
            )`
                : `linear-gradient(
                transparent,
                transparent 15px,
                ${ColorsEnum.grey4} 15px,
                ${ColorsEnum.grey4} 17px,
                transparent 17px,
                transparent 32px
            )`};
            height: 16px;
            width: 100%;
            margin: auto;
            margin-bottom: ${isFirefox ? `-8px` : isSafari() ? `0px` : 'auto'};

            &::-webkit-slider-thumb {
                margin-top: ${isSafari() ? `12px` : `calc(${thumbDiameter} / 2)`};
                -webkit-appearance: none;
                width: ${thumbDiameter};
                height: ${thumbDiameter};
                margin-left: calc(-1 * ${thumbDiameter} / 2);
                background-color: ${ColorsEnum.blue};
                border-radius: 50%;
                cursor: -webkit-grab;
            }

            &::-moz-range-thumb {
                -webkit-appearance: none;
                border: none;
                width: ${thumbDiameter};
                height: ${thumbDiameter};
                margin-left: calc(-1 * ${thumbDiameter} / 2);
                background-color: ${ColorsEnum.blue};
                border-radius: 50%;
                cursor: -moz-grab;
                margin-bottom: calc(-1 * ${lineHeight} / 2);
            }

            &::-ms-thumb {
                margin-top: calc(${thumbDiameter} / 2);
                -webkit-appearance: none;
                width: ${thumbDiameter};
                height: ${thumbDiameter};
                margin-left: calc(-1 * ${thumbDiameter} / 2);
                background-color: ${ColorsEnum.blue};
                border-radius: 50%;
                cursor: -webkit-grab;
            }

            &::-webkit-slider-runnable-track {
                margin-right: calc(-1 * ${thumbDiameter});
            }
            &::-moz-range-track {
                margin-right: calc(-1 * ${thumbDiameter});
            }
            &::-ms-track {
                margin-right: calc(-1 * ${thumbDiameter});
            }
        }
    `;

    const sliderTicksSafari = css`
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 1px;
        background: ${ColorsEnum.grey4};
        height: 10px;
        line-height: ${lineHeight};
    `;

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(Number(e.target.value));
    };

    /**
     * Measure the text width
     * Re-implemented to avoid dependency to common from components
     */
    const getTextWidth = (text: string) => {
        const getContext = memoize(() => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;
            ctx.font = `12px "Open sans"`;
            return ctx;
        });
        return Math.ceil(getContext().measureText(text).width);
    };

    const getPosition = (steps: number, label: string, index: number) => {
        const textWidth = getTextWidth(label);
        const positionPercent = (index / (steps - 1)) * 100;
        const positionPx = textWidth / 2;
        return `calc(${positionPercent}% - ${positionPx}px)`;
    };

    const renderOptions = (_label: string, index: number) =>
        isSafari() ? (
            <div
                key={index}
                className={sliderTicksSafari}
                style={
                    index === value
                        ? {
                              color: `${ColorsEnum.grey7}`,
                              backgroundColor: 'transparent',
                          }
                        : undefined
                }
            >
                <option
                    key={index}
                    value={index}
                    style={
                        index === value
                            ? {
                                  color: `${ColorsEnum.grey7}`,
                                  backgroundColor: 'transparent',
                              }
                            : undefined
                    }
                ></option>
            </div>
        ) : (
            <option
                key={index}
                value={index}
                style={
                    index === value
                        ? {
                              color: `${ColorsEnum.grey7}`,
                              backgroundColor: 'transparent',
                          }
                        : undefined
                }
            ></option>
        );

    const renderLabels = (label: string, index: number, positionString: string) => (
        <label
            htmlFor="steps"
            key={index}
            style={
                index == value
                    ? {
                          color: `${ColorsEnum.grey7}`,
                          position: 'absolute',
                          insetInlineStart: positionString, //logical property for RTL support
                          top: `calc(-1 * ${halfLine})`,
                          display: 'inline',
                          whiteSpace: 'nowrap',
                      }
                    : {
                          position: 'absolute',
                          insetInlineStart: positionString, //logical property for RTL support
                          top: `calc(-1 * ${halfLine})`,
                          color: `${ColorsEnum.grey4}`,
                          display: 'inline',
                          whiteSpace: 'nowrap',
                      }
            }
        >
            {label}
        </label>
    );

    return (
        <Box maxHeight="44px" paddingTop="panel" width="100%">
            <Stack justifyContent="between" alignItems="center" flex="fullWidth">
                <Box>{iconLeft && <Icon size="md" icon={iconLeft} />}</Box>
                <div className={rangeStepStyle}>
                    <input
                        value={value}
                        type="range"
                        max={labels.length - 1}
                        min="0"
                        step="1"
                        list="steps"
                        onChange={onValueChange}
                    />
                    {labels.map((label, index) =>
                        renderLabels(label, index, getPosition(labels.length, label, index)),
                    )}

                    <datalist id="steps" style={isFirefox ? { bottom: '10px' } : undefined}>
                        {labels.map((label, index) => renderOptions(label, index))}
                    </datalist>
                </div>

                <Box>{iconRight && <Icon size="md" icon={iconRight} />}</Box>
            </Stack>
        </Box>
    );
};
