import * as leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { distanceFromOrigo, trigonometry, type Point } from 'axis-webtools-util';
import { calculateVisibleArea, rotatePolygon } from '../../../utils';
import type { Colors } from 'app/styles';
import type { LeafletMap } from '../LeafletMap';
import type {
    IInstallationPointPanRange,
    IInstallationPointModel,
    Id,
    ILatLng,
    PolyLine,
    IInstallationPoint,
} from 'app/core/persistence';
import type { IPiaCamera } from 'app/core/pia';
import { BaseCone } from './BaseCone';

export class PanRangeCone extends BaseCone {
    public horizontalAngle: number;
    public targetDistance: number;
    private panRange: number;

    constructor(
        installationPointPanRange: IInstallationPointPanRange,
        location: ILatLng,
        private installationPointId: Id,
        piaPanRange: IPiaCamera | undefined,
        map: LeafletMap,
        color: Colors,
    ) {
        super(map, leaflet.latLng(location.lat, location.lng), color);

        this.panRange = piaPanRange?.properties.panRange ?? 0;
        this.targetDistance = installationPointPanRange.target.distance;
        this.horizontalAngle = installationPointPanRange.target.horizontalAngle;
    }

    public getHasTargetLine = () => {
        return false;
    };

    public updateDoriPixelsOn(): void {
        // noop
    }

    public toggleTinyIcons(): void {
        // noop
    }

    public getRotatedTarget(
        installationPoint: IInstallationPoint,
        rotationOffsetInDeg: number,
    ): DeepPartial<IInstallationPoint> {
        const rotatedTarget = this.rotateTargetAngle(
            'panRange',
            installationPoint,
            rotationOffsetInDeg,
        );
        return rotatedTarget;
    }

    public getTargetPopupContent = (_targetDistance: number) => {
        return null;
    };

    public getFovPopupContent = (_angle: number) => {
        return null;
    };

    public getAnalyticsPopupContent() {
        return null;
    }
    public update = (ip: IInstallationPointModel, blockers: PolyLine[] | undefined) => {
        const panRange = ip.panRange;
        if (!panRange) return;

        this.reDraw(blockers, {
            targetDistance: panRange.target.distance,
            rotation: panRange.target.horizontalAngle,
            location: leaflet.latLng(ip.location.lat, ip.location.lng),
        });
    };

    public persistCoverageInfo = async ({
        distance = this.targetDistance,
        horizontalAngle = this.horizontalAngle,
    }) => {
        const target = { distance, horizontalAngle };
        const panRange: IInstallationPointPanRange = { target };

        return this.mapsService.updateCoverageArea(this.installationPointId, {
            panRange,
        });
    };

    public reDraw(
        blockers: PolyLine[] | undefined,
        {
            targetDistance = this.targetDistance,
            rotation = this.horizontalAngle,
            location = this.latLng,
            panRange = this.panRange,
        },
    ) {
        try {
            this.latLng = location;
            this.horizontalAngle = rotation;
            this.targetDistance = targetDistance;
            this.panRange = panRange;

            // Add 90 degrees since the cone assumes 0 is to the right (it is down in our map)
            const rotationAngleRad = trigonometry.toRadians(this.horizontalAngle + 90);

            const renderedTargetDistance = this.targetDistance;

            const visibleArea = calculateVisibleArea(
                panRange,
                Math.PI,
                0,
                0,
                renderedTargetDistance,
                false,
                false,
            );

            const rotatedVisibleArea = rotatePolygon(visibleArea, rotationAngleRad);
            const targetPos: Point = [this.targetDistance, 0];
            const renderHorizon = distanceFromOrigo(targetPos);

            this.applyBlockerShadows(blockers, {
                renderHorizon,
                visibleArea: rotatedVisibleArea,
            });
        } catch (error) {
            console.error(error);
        }
    }

    public getIsFovAdjustable() {
        return false;
    }
    public getIsTargetAdjustable() {
        return !!this.panRange;
    }
    public getIconInfo() {
        return undefined;
    }
    public updateRangeAnalytics() {
        // noop
    }
    public getHasDirectionalArrow() {
        return false;
    }
    public getFovHandleLatLng() {
        return undefined;
    }
    protected onFovHandleDrag = (_e: leaflet.LeafletEvent) => {
        throw Error('Should never be called');
    };
}
