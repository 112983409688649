import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';
import { LightConditions } from 'app/core/persistence';

interface ILightConditionsFilterProps {
    selectedConditions: LightConditions[];
    onClick(newConditions: LightConditions[]): void;
}

export const LightConditionsFilter: React.FC<ILightConditionsFilterProps> = ({
    selectedConditions,
    onClick,
}) => {
    const onLightConditionsChanged = (lightCondition: LightConditions) => {
        onClick(
            selectedConditions.includes(lightCondition)
                ? selectedConditions.filter((condition) => condition !== lightCondition)
                : [...selectedConditions, lightCondition],
        );
    };

    const numberOfSelectedConditions = selectedConditions.filter(
        (condition) => condition !== LightConditions.BACKLIGHT,
    ).length;

    return (
        <DropDown
            trigger={
                <PillButton
                    icon="keyboard_arrow_down"
                    selected={numberOfSelectedConditions > 0}
                    text={
                        numberOfSelectedConditions > 0
                            ? `${t.illumination} \u00b7 ${numberOfSelectedConditions}`
                            : t.illumination
                    }
                />
            }
            contents={
                <>
                    <DropDownMenuButton
                        label={t.cameraSelectorFieldOfViewDaylight}
                        selected={selectedConditions.includes(LightConditions.DAYLIGHT)}
                        onClick={() => onLightConditionsChanged(LightConditions.DAYLIGHT)}
                    />
                    <DropDownMenuButton
                        label={t.cameraSelectorFieldOfViewLowLight}
                        selected={selectedConditions.includes(LightConditions.LOW_LIGHT)}
                        onClick={() => onLightConditionsChanged(LightConditions.LOW_LIGHT)}
                    />
                    <DropDownMenuButton
                        label={t.cameraSelectorFieldOfViewNoLight}
                        selected={selectedConditions.includes(LightConditions.NO_LIGHT)}
                        onClick={() => onLightConditionsChanged(LightConditions.NO_LIGHT)}
                    />
                </>
            }
        />
    );
};

LightConditionsFilter.displayName = 'LightConditionsFilter';
