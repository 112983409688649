import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { IInstallationPointModel } from 'app/core/persistence';
import type { LeafletMap } from '../LeafletMap';
import { LeafletItemFactory } from '../../../services/LeafletItemFactory';
import { ServiceLocator } from 'app/ioc';
import { CoverageArea } from './CoverageArea';
import { getInstallationPointColor } from '../../../selectors/getInstallationPointColor';
import { InstallationPointLabel } from '../InstallationPointLabel';
import type { Colors } from 'app/styles';
import {
    getDefaultImageForDeviceId,
    getHasExternalIRIllumination,
    getImageForDeviceId,
} from 'app/modules/common';
import { getUseBigImages } from '../../../selectors/getZoomLevel';
import { MapsActionService } from '../../../services';
import { getIsUserOnline } from 'app/modules/application';

interface IDragCopyMapItemProps {
    installationPoint: IInstallationPointModel;
    map: LeafletMap;
}

/**
 * If this item exists we are in duplication mode, so we need to update state
 */
export const DragCopyMapItem: React.FC<IDragCopyMapItemProps> = ({ installationPoint, map }) => {
    const [factory] = React.useState(ServiceLocator.get(LeafletItemFactory));
    const [actions] = React.useState(ServiceLocator.get(MapsActionService));
    const useBigIcon = useSelector(getUseBigImages);
    const itemImage = useSelector<IStoreState, string>((state) =>
        getImageForDeviceId(state, installationPoint?.parentDevice._id),
    );
    const itemFallbackImage = useSelector<IStoreState, string>((state) =>
        getDefaultImageForDeviceId(state, installationPoint?.parentDevice._id),
    );
    const isOnline = useSelector(getIsUserOnline);

    const color = useSelector<IStoreState, Colors>((state) =>
        getInstallationPointColor(state, installationPoint?._id),
    );

    const hasExternalIlluminator = useSelector<IStoreState, boolean>((state) =>
        getHasExternalIRIllumination(state, installationPoint.parentDevice._id),
    );
    const [icon] = React.useState(
        useBigIcon
            ? factory.createBigDeviceIcon(isOnline ? itemImage : itemFallbackImage, color)
            : factory.createSmallDeviceIcon(isOnline ? itemImage : itemFallbackImage, color),
    );

    const [draggableMarker] = React.useState(
        factory.createInteractiveItem(installationPoint.location, icon, !map.readOnly),
    );
    const [cones] = React.useState(
        factory.createCones(installationPoint, map, color, hasExternalIlluminator),
    );

    React.useEffect(() => {
        draggableMarker.addTo(map.map);
        actions.setDuplicationInfo({
            installationPointId: installationPoint._id,
            originalLocation: installationPoint.location,
        });

        return () => {
            draggableMarker.removeFrom(map.map);
        };
    }, [actions, draggableMarker, installationPoint._id, installationPoint.location, map.map]);

    return (
        <>
            {cones.map((cone, index) => (
                <CoverageArea
                    key={index}
                    map={map}
                    cone={cone}
                    installationPoint={installationPoint}
                    color={color}
                    isOpaque={true}
                />
            ))}
            <InstallationPointLabel installationPoint={installationPoint} map={map} color={color} />
        </>
    );
};

DragCopyMapItem.displayName = 'DragCopyMapItem';
