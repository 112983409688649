import { DistanceUnit } from 'app/core/persistence';
import { convert } from 'axis-webtools-util';
import { round } from 'lodash-es';

/**
 * Convert distance units from meters to project specific units
 * @param distance
 * @param distanceUnit
 */
export const generateToDisplayUnitConverter = (
    distanceUnit: DistanceUnit.Meter | DistanceUnit.Feet,
    shouldRound: boolean = true,
) => {
    return (distance: number): number => {
        if (distanceUnit === DistanceUnit.Feet) {
            distance = convert.metersToFeet(distance);
        }

        return shouldRound ? round(distance, 1) : distance;
    };
};
