import * as React from 'react';
import { css, cx } from '@emotion/css';
import type { InputChangeCriteria } from '../numberInput/NumberInput.component';
import { NumberInput } from '../numberInput/NumberInput.component';
import { Label } from '../../text';
import type { IAutoTestable } from '../../ui-test';
import { ColorsEnum } from 'app/styles';
import type { IWithChildren } from 'app/components/models';
import { isPageRTL } from 'app/translate';

const trackHeight = '4px';
const thumbDiameter = '18px';
const thumbBorder = '2px';

const ContainerStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SliderStyle = css`
    display: flex;
    flex: 1;
    height: ${thumbDiameter};
    outline: none;
    background-color: ${ColorsEnum.transparent};
    appearance: none;
    box-shadow: none;
`;

const TrackStyle = css`
    &::-webkit-slider-runnable-track {
        height: ${trackHeight};
        border-width: 0;
        background: linear-gradient(${ColorsEnum.blue}, ${ColorsEnum.blue}) no-repeat
            ${ColorsEnum.grey3};
        background-size: var(--bg-width) 100% !important;
        background-position: var(--bg-position);
    }

    &::-moz-range-track {
        height: ${trackHeight};
        border-width: 0;
        background: linear-gradient(${ColorsEnum.blue}, ${ColorsEnum.blue}) no-repeat
            ${ColorsEnum.grey3};
        background-size: var(--bg-width) 100% !important;
        background-position: var(--bg-position);
    }

    &::-ms-track {
        height: ${trackHeight};
        border-width: 0;
        background: linear-gradient(${ColorsEnum.blue}, ${ColorsEnum.blue}) no-repeat
            ${ColorsEnum.grey3};
        background-size: var(--bg-width) 100% !important;
        background-position: var(--bg-position);
    }
`;

const YellowTrackStyle = css`
    &::-webkit-slider-runnable-track {
        height: ${trackHeight};
        border-width: 0;
        background: linear-gradient(${ColorsEnum.yellow}, ${ColorsEnum.yellow}) no-repeat
            ${ColorsEnum.grey3};
        background-size: var(--bg-width) 100% !important;
        background-position: var(--bg-position);
    }

    &::-moz-range-track {
        height: ${trackHeight};
        border-width: 0;
        background: linear-gradient(${ColorsEnum.yellow}, ${ColorsEnum.yellow}) no-repeat
            ${ColorsEnum.grey3};
        background-size: var(--bg-width) 100% !important;
        background-position: var(--bg-position);
    }

    &::-ms-track {
        height: ${trackHeight};
        border-width: 0;
        background: linear-gradient(${ColorsEnum.yellow}, ${ColorsEnum.yellow}) no-repeat
            ${ColorsEnum.grey3};
        background-size: var(--bg-width) 100% !important;
        background-position: var(--bg-position);
    }
`;

const ThumbStyle = css`
    &::-webkit-slider-thumb {
        box-sizing: border-box;

        width: ${thumbDiameter};
        height: ${thumbDiameter};

        margin-top: calc(-1 * ${thumbDiameter} / 2 + ${thumbBorder});

        border: ${thumbBorder} solid #fff;
        border-radius: 50%;

        background-color: ${ColorsEnum.blue};
        cursor: pointer;

        appearance: none;
    }

    &::-moz-range-thumb {
        box-sizing: border-box;

        width: ${thumbDiameter};
        height: ${thumbDiameter};

        margin-top: calc(-1 * ${thumbDiameter} / 2 + ${thumbBorder});

        border: ${thumbBorder} solid #fff;
        border-radius: 50%;

        background-color: ${ColorsEnum.blue};
        cursor: pointer;
    }

    &::-ms-thumb {
        box-sizing: border-box;

        width: ${thumbDiameter};
        height: ${thumbDiameter};

        /* The vertical alignment of the thumb differs in Edge compablue to FF/Chrome */
        margin-top: 0;

        border: ${thumbBorder} solid #fff;
        border-radius: 50%;

        background-color: ${ColorsEnum.blue};
        background-clip: padding-box;
        cursor: pointer;
    }

    &::-ms-tooltip {
        display: none;
    }
`;

const YellowThumbStyle = css`
    &::-webkit-slider-thumb {
        box-sizing: border-box;

        width: ${thumbDiameter};
        height: ${thumbDiameter};

        margin-top: calc(-1 * (${thumbDiameter}) / 2 + (${thumbBorder}));

        border: ${thumbBorder} solid #fff;
        border-radius: 50%;

        background-color: ${ColorsEnum.yellow};
        cursor: pointer;

        appearance: none;
    }

    &::-moz-range-thumb {
        box-sizing: border-box;

        width: ${thumbDiameter};
        height: ${thumbDiameter};

        margin-top: calc(-1 * (${thumbDiameter}) / 2 + (${thumbBorder}));

        border: ${thumbBorder} solid #fff;
        border-radius: 50%;

        background-color: ${ColorsEnum.yellow};
        cursor: pointer;
    }

    &::-ms-thumb {
        box-sizing: border-box;

        width: ${thumbDiameter};
        height: ${thumbDiameter};

        /* The vertical alignment of the thumb differs in Edge compablue to FF/Chrome */
        margin-top: 0;

        border: ${thumbBorder} solid #fff;
        border-radius: 50%;

        background-color: ${ColorsEnum.yellow};
        background-clip: padding-box;
        cursor: pointer;
    }

    &::-ms-tooltip {
        display: none;
    }
`;

const WidgetStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LabelValueStyle = css`
    opacity: 0.54;
    white-space: pre;
    display: inline-block;
`;

export interface IRange extends IAutoTestable, IWithChildren {
    /**
     * Show a label above the slider
     */
    label?: string;
    /**
     * Set the color of the filled slider
     */
    color: 'blue' | 'yellow';
    /**
     * The value at the bottom of the scale
     */
    min: number;
    /**
     * The value at the top of the scale
     */
    max: number;
    /**
     * Increment the slider in steps
     */
    step?: number;
    /**
     * The value of the slider
     */
    value: number;
    /**
     * Wether to show decimals or not
     */
    decimals?: number;
    /**
     * Use a logarithmic scale for the slider instead of a linear one.
     */
    logarithmic?: boolean;
    /**
     * What unit to display in the label
     */
    unit?: string;
    /**
     * Show the current value in a number input next to the slider
     */
    showValue?: boolean;
    /**
     * Show the current value in the label above the slider
     */
    showValueInLabel?: boolean;
    /**
     * Trigger the `onChange()` callback on key or blur events.
     */
    changeCriteria?: InputChangeCriteria;
    /**
     * Remove value trailing space in label (ex before degrees)
     */
    hideValueTrailingSpace?: boolean;
    /**
     * Disable the control
     */
    disabled?: boolean;
    /**
     * The function to run when the user changes the value
     */
    onChange(value: number): void;
    /**
     * Provide an `onMouseUp()` callback to the `<input>` element
     */
    onMouseUp?(): void;
}

/**
 * Display a slider for range type inputs.
 */
export class Range extends React.Component<IRange> {
    public render() {
        const internalValue = this.inverseValueFunction(this.props.value);

        const labelValue = this.props.value.toFixed(this.props.decimals ? this.props.decimals : 0);

        const sliderBgPercentage = this.getSliderBgPercentage(internalValue);

        const step = this.props.logarithmic ? 'any' : this.props.step || 1;
        const valueTrailingSpace = this.props.hideValueTrailingSpace ? '' : ' ';

        return (
            <div className={ContainerStyle}>
                {this.props.label && (
                    <Label opaque>
                        {this.props.label}
                        {this.props.showValueInLabel && (
                            <>
                                :
                                <span className={LabelValueStyle}>
                                    {` ${labelValue}${valueTrailingSpace}${this.props.unit}`}
                                </span>
                            </>
                        )}
                    </Label>
                )}
                <div className={WidgetStyle}>
                    <input
                        type="range"
                        value={internalValue}
                        min={this.inverseValueFunction(this.props.min)}
                        max={this.inverseValueFunction(this.props.max)}
                        step={step}
                        onChange={this.onValueChange}
                        onMouseUp={this.props.onMouseUp}
                        className={cx(
                            SliderStyle,
                            this.props.color === 'blue' ? TrackStyle : YellowTrackStyle,
                            this.props.color === 'blue' ? ThumbStyle : YellowThumbStyle,
                        )}
                        disabled={this.props.disabled}
                        style={
                            /* React typings has no support for --bg-width */
                            {
                                '--bg-width': sliderBgPercentage,
                                '--bg-position': isPageRTL() ? 'right' : 'left',
                            } as any
                        }
                    />
                    {this.props.showValue && (
                        <NumberInput
                            testId={this.props.testId}
                            min={this.props.min}
                            max={this.props.max}
                            value={this.props.value}
                            onChange={this.onInputValueChange}
                            step={this.props.step}
                            decimals={this.props.decimals}
                            changeCriteria={this.props.changeCriteria}
                            disabled={this.props.disabled}
                        />
                    )}
                </div>
                {this.props.children && <div>{this.props.children}</div>}
            </div>
        );
    }

    private onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = this.valueFunction(Number(e.target.value));
        this.props.onChange(value);
    };

    private onInputValueChange = (value: number) => {
        this.props.onChange(value);
    };

    private getSliderBgPercentage = (value: number) => {
        const min = this.inverseValueFunction(this.props.min || 0);
        const reducedValue = value - min;
        const percentage = (reducedValue / (this.inverseValueFunction(this.props.max) - min)) * 100;

        return percentage + '%';
    };

    private getValueFunctionFactor() {
        return Math.log(this.props.max) / 100000;
    }

    /**
     * Converts internal value representation to external. For linear ranges this is
     * the identity function
     *
     * @param value the value to convert
     */
    private valueFunction(value: number): number {
        return this.props.logarithmic ? Math.exp(value * this.getValueFunctionFactor()) - 1 : value;
    }

    /**
     * Converts external value representation to internal. For linear ranges this is
     * the identity function
     *
     * @param value the value to convert
     */
    private inverseValueFunction(value: number): number {
        return this.props.logarithmic ? Math.log(value + 1) / this.getValueFunctionFactor() : value;
    }
}
