import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import type { Id, IProfileEntity, IPersistence } from 'app/core/persistence';
import { ProfileService, CoreProfileNameError } from 'app/core/persistence';
import { t } from 'app/translate';
import { toaster } from 'app/toaster';
import {
    getProfile,
    getProfileUsesCount,
    getSortedProfiles,
    ProfileEditorHeaderComponent,
} from 'app/modules/common';

interface IProfileHeaderOwnProps {
    profileId: Id;
    onClose(): void;
}

interface IProfileHeaderProps extends IProfileHeaderOwnProps {
    profile?: IPersistence<IProfileEntity>;
    profileNames: string[];
    deviceCount: number;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IProfileHeaderOwnProps,
): IProfileHeaderProps => {
    const profile = getProfile(state, ownProps.profileId);
    const usedBy = getProfileUsesCount(ownProps.profileId)(state);
    const profileNames = getSortedProfiles(state)
        .filter(({ _id }) => _id !== ownProps.profileId)
        .map(({ name }) => name);

    return {
        profile,
        profileNames,
        deviceCount: usedBy,
        ...ownProps,
    };
};

class ProfileEditorHeaderContainer extends React.Component<IProfileHeaderProps> {
    private profileService: ProfileService;

    constructor(props: IProfileHeaderProps) {
        super(props);
        this.profileService = ServiceLocator.get(ProfileService);
    }

    public render() {
        return this.props.profile ? (
            <ProfileEditorHeaderComponent
                name={this.props.profile.name}
                deviceCount={this.props.deviceCount}
                onNameChange={this.onNameChange}
                onClose={this.props.onClose}
            />
        ) : null;
    }

    private onNameChange = async (newName: string) => {
        const oldName = this.props.profile ? this.props.profile.name : undefined;
        try {
            if (this.props.profile) {
                this.props.profile.name = newName;
                await this.profileService.updateProfileEntity(this.props.profile);
            }
        } catch (error) {
            if (error instanceof CoreProfileNameError) {
                if (this.props.profile && oldName) {
                    this.props.profile.name = oldName;
                }
                toaster.warning(
                    t.profilesNameChangeFailedToastHeader,
                    t.profilesNameChangeFailedToastBody(newName),
                );
            } else {
                throw error;
            }
        }
    };
}

export const ProfileEditorHeader = connect(mapStateToProps)(ProfileEditorHeaderContainer);
