import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import type { ICommand } from 'app/core/persistence';

const getRawUndoQueue = (state: IStoreState) => state.currentProject.history.undoQueue;
const getRawRedoQueue = (state: IStoreState) => state.currentProject.history.redoQueue;

/*
 * Determine whether a command is reversible
 */
const isReversible = (command: ICommand) => {
    const creates = command.creates ?? [];
    const updates = command.updates ?? [];

    if (creates.some((item) => item.props.type === 'floorPlan')) {
        // recreating a floorplan won't work since the uploaded image is gone
        return false;
    }

    if (updates.some((item) => 'shareToken' in item.props)) {
        // changes to shareToken can not be undone
        return false;
    }

    if (updates.some((item) => 'bandwidthVersion' in item.props)) {
        // changes to bandwidthVersion can not be undone
        return false;
    }

    // add more irreversible commands here if applicable
    // if (condition for command to be irreversible) {
    //     return false
    // }

    return true;
};

/*
 * Keep only reversible commands
 */
export const keepReversible = (queue: ICommand[]): ICommand[] => {
    const newQueue: ICommand[] = [];

    for (const command of [...queue].reverse()) {
        if (!isReversible(command)) {
            // scrap remaining queue since it may depend on this command
            break;
        }

        newQueue.unshift(command);
    }

    return newQueue;
};

export const getUndoQueue = createSelector([getRawUndoQueue], keepReversible);
export const getRedoQueue = createSelector([getRawRedoQueue], keepReversible);

export const hasUndoCommand = createSelector([getUndoQueue], (queue) => queue.length > 0);
export const hasRedoCommand = createSelector([getRedoQueue], (queue) => queue.length > 0);
