import { IMappedPartNumber } from './../models/IMappedPartNumber';
import { PiaId } from 'app/core/pia';
import { injectable } from 'inversify';
import { ActionCreator, ThunkAction } from 'app/store';
import { PartnerConfigActions } from '../partnerConfig.actions';
import { IPartnerHeaderStyle } from 'app/core/persistence';
import type { IPartnerConfigState, ISearchFilter } from '../models/IPartnerConfigState';

@injectable()
export class PartnerConfigActionService {
    @ActionCreator()
    public setPartnerConfig(partnerConfig: Partial<IPartnerConfigState>) {
        return {
            type: PartnerConfigActions.SetPartnerConfig,
            payload: partnerConfig,
        };
    }

    @ActionCreator()
    public setName(name: string) {
        return {
            type: PartnerConfigActions.SetName,
            payload: name,
        };
    }

    @ActionCreator()
    public setLogo(logo: string | undefined) {
        return {
            type: PartnerConfigActions.SetLogo,
            payload: logo,
        };
    }

    @ActionCreator()
    public setStyle(style: IPartnerHeaderStyle) {
        return {
            type: PartnerConfigActions.SetStyle,
            payload: style,
        };
    }

    @ActionCreator()
    public addToAllowList(piaId: PiaId) {
        return {
            type: PartnerConfigActions.AddToAllowList,
            payload: piaId,
        };
    }

    @ActionCreator()
    public removeFromAllowList(piaId: PiaId) {
        return {
            type: PartnerConfigActions.RemoveFromAllowList,
            payload: piaId,
        };
    }

    @ActionCreator()
    public toggleIsPartnerConfigEnabled(): ThunkAction {
        return (dispatch, getState) =>
            dispatch({
                type: PartnerConfigActions.IsPartnerConfigEnabled,
                payload: !getState().partnerConfig.isPartnerConfigEnabled,
            });
    }

    @ActionCreator()
    public toggleIsPartnerConfigUrlEnabled(): ThunkAction {
        return (dispatch, getState) =>
            dispatch({
                type: PartnerConfigActions.IsPartnerUrlEnabled,
                payload: !getState().partnerConfig.isPartnerConfigUrlEnabled,
            });
    }

    @ActionCreator()
    public toggleIsOnlyRecommendedProductsAvailable(): ThunkAction {
        return (dispatch, getState) =>
            dispatch({
                type: PartnerConfigActions.IsOnlyRecommendedProductsAvailable,
                payload: !getState().partnerConfig.isOnlySelectedProductsAvailable,
            });
    }

    @ActionCreator()
    public toggleIsCustomPartNumberEnabled(): ThunkAction {
        return (dispatch, getState) =>
            dispatch({
                type: PartnerConfigActions.IsCustomPartNumberEnabled,
                payload: !getState().partnerConfig.isCustomPartNumberEnabled,
            });
    }

    @ActionCreator()
    public setExcludedProductMessage(message: string) {
        return {
            type: PartnerConfigActions.SetExcludedProductMessage,
            payload: message,
        };
    }

    @ActionCreator()
    public setSearchFilter(filter: Partial<ISearchFilter>) {
        return {
            type: PartnerConfigActions.SetSearchFilter,
            payload: filter,
        };
    }

    @ActionCreator()
    public setSelectedProductsSearchText(searchText: string) {
        return {
            type: PartnerConfigActions.SetSelectedProductsSearch,
            payload: searchText,
        };
    }

    @ActionCreator()
    public setAccessoriesSearchText(searchText: string) {
        return {
            type: PartnerConfigActions.SetAccessoriesSearchText,
            payload: searchText,
        };
    }

    @ActionCreator()
    public setCustomPartNumber(mappedPartNumber: IMappedPartNumber) {
        return {
            type: PartnerConfigActions.SetCustomPartNumber,
            payload: mappedPartNumber,
        };
    }
}
