import * as React from 'react';
import { getCurrentProject, getCurrentProjectHasFloorPlans } from 'app/modules/common';
import { useService } from 'app/ioc';
import {
    Heading,
    Box,
    Clickable,
    Border,
    Positioned,
    IconButton,
    Icon,
    Margin,
    Button,
} from 'app/components';
import { t } from 'app/translate';
import { MapsActionService } from '../../services';
import { useSelector } from 'react-redux';
import { getIsUploadingFloorPlan, getShowAddMapModal, getShowLocationModal } from '../../selectors';
import { usePrevious } from 'app/hooks';
import { AddLocationModal, UploadFloorPlanModal } from '../overlaypanel';
import { WelcomeCard } from './WelcomeCard';
import { eventTracking } from 'app/core/tracking';

export interface IMapWelcomeProps {
    onClose(): void;
}

export const MapWelcome: React.FC<IMapWelcomeProps> = ({ onClose }) => {
    const mapsActionService = useService(MapsActionService);
    const currentProject = useSelector(getCurrentProject);
    const showAddMapModal = useSelector(getShowAddMapModal);
    const showAddLocationModal = useSelector(getShowLocationModal);
    const uploadingFloorPlan = useSelector(getIsUploadingFloorPlan);
    const hasMaps = useSelector(getCurrentProjectHasFloorPlans);
    const previousUploadingFloorPlan = usePrevious(uploadingFloorPlan);

    /** Auto-close dialog if floorplan was uploaded */
    React.useEffect(() => {
        if (!showAddMapModal && previousUploadingFloorPlan && !uploadingFloorPlan) {
            onClose();
        }
    }, [onClose, previousUploadingFloorPlan, showAddMapModal, uploadingFloorPlan]);

    const toggleAddLocationModal = async () => {
        eventTracking.logUserEvent('Maps', 'Welcome dialog add location');
        mapsActionService.toggleAddLocationModal(!showAddLocationModal);
    };

    const toggleAddMapModal = () => {
        eventTracking.logUserEvent('Maps', 'Welcome dialog add map');
        mapsActionService.toggleAddMapModal(!showAddMapModal);
    };

    const onSkip = () => {
        eventTracking.logUserEvent('Maps', 'Welcome dialog skip');
        onClose();
    };

    const onPlayVideo = () => {
        eventTracking.logUserEvent('Maps', 'Welcome dialog play video');
        mapsActionService.toggleGetToKnowMaps();
    };

    return (
        <>
            {showAddMapModal && <UploadFloorPlanModal onClose={toggleAddMapModal} />}

            {showAddLocationModal && <AddLocationModal onClose={onClose} />}

            <Margin marginTop="-10%">
                <Positioned position="relative" bringToFront>
                    <Border color="grey3" radius="10px" shadow="0 5px 15px 7px rgb(0 0 0 / 35%)">
                        <Box
                            direction="column"
                            spacing="base"
                            color="white"
                            padding="base"
                            testId="maps_welcome_module"
                        >
                            {hasMaps && (
                                <Positioned position="absolute" top={0} right={0}>
                                    <Box padding="base">
                                        <IconButton
                                            icon="close_circle_outline"
                                            color="grey5"
                                            onClick={onClose}
                                        />
                                    </Box>
                                </Positioned>
                            )}
                            <Box direction="column" alignItems="center" justifyContent="center">
                                <Heading style="headline" color="grey6">
                                    {t.mapWelcome}
                                </Heading>
                            </Box>

                            <Box justifyContent="center">
                                <Box
                                    display="grid"
                                    grid={{
                                        gridTemplateColumns: `repeat(${!currentProject.location ? 3 : 2}, 1fr)`,
                                    }}
                                    spacing="base"
                                >
                                    <Clickable onClick={onPlayVideo}>
                                        <WelcomeCard showTracks header={t.getToKnowMaps}>
                                            <Border width={2} color="grey5">
                                                <Box
                                                    borderRadius="round"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    padding="base"
                                                    width={100}
                                                    height={100}
                                                >
                                                    <Icon
                                                        opaque
                                                        icon="play"
                                                        color="yellow"
                                                        size="xl"
                                                    />
                                                </Box>
                                            </Border>
                                        </WelcomeCard>
                                    </Clickable>

                                    <Clickable onClick={toggleAddLocationModal}>
                                        <WelcomeCard header={t.addLocation}>
                                            <img src={require('assets/images/satellite-map.svg')} />
                                        </WelcomeCard>
                                    </Clickable>

                                    <Clickable onClick={toggleAddMapModal}>
                                        <WelcomeCard header={t.addNewFloorPlan}>
                                            <img
                                                width="122px"
                                                height="auto"
                                                src={require('assets/images/floorplan.svg')}
                                            />
                                        </WelcomeCard>
                                    </Clickable>
                                </Box>
                            </Box>
                            <Box justifyContent="end">
                                <Button text onClick={onSkip} testId="skip_btn">
                                    {t.skip}
                                </Button>
                            </Box>
                        </Box>
                    </Border>
                </Positioned>
            </Margin>
        </>
    );
};
