import { uniq } from 'lodash-es';
import type { Id, IProjectEntity } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';
import { reducerBuilder } from 'app/store';
import type {
    IDeviceGroupInfo,
    SortOrder,
    IAccessControlFilter,
    IWearablesFilter,
    ISpeakerFilter,
    IOtherDeviceFilter,
    ITwoNFilter,
    ICameraFilter,
    ISensorUnitFilter,
    IPiaIdWithModel,
} from '../models';
import {
    defaultWearablesFilter,
    getDefaultSensorUnitFilter,
    defaultOtherDeviceFilter,
    getDefaultCameraFilter,
    defaultMainUnitFilter,
    defaultEncoderFilter,
    defaultAccessControlFilter,
    getDefaultSpeakerFilter,
    defaultTwoNFilter,
} from '../models';
import type { IDesiredSensorUnit } from '../models/cameras/IDesiredSensorUnit';

import { DeviceSelectorActions } from './DeviceSelector.actions';
import type { IDeviceSelectorState } from './IDeviceSelectorState';
import type { IDesiredCamera, IEncoderFilter, IMainUnitFilter } from 'app/modules/common';

export const MAX_COMPARE_COUNT = 4;

const initialState: IDeviceSelectorState = {
    deviceGroup: 'cameras',
    categories: [],
    cameraFilter: getDefaultCameraFilter('metric', 3),
    sensorUnitFilter: getDefaultSensorUnitFilter('metric', 3),
    mainUnitFilter: defaultMainUnitFilter,
    encoderFilter: defaultEncoderFilter,
    speakerFilter: getDefaultSpeakerFilter(3),
    twoNFilter: defaultTwoNFilter,
    accessControlFilter: defaultAccessControlFilter,
    wearablesFilter: defaultWearablesFilter,
    otherDeviceFilter: defaultOtherDeviceFilter,
    sortOrderOptions: [],
    currentSortOrder: 'bySeries',
    searchText: '',
    quickAddSearchValue: '',
    selectedProductId: null,
    includeDiscontinued: false,
    editDeviceId: null,
    parentId: null,
    speakerQuantity: 1,
    topRecommendationVisible: false,
    bigScene3dVisible: false,
    isFilterPanelExpanded: true,
    showComparison: false,
    productsToCompare: [],
    productToAdd: null,
    isAddingOrUpdating: false,
};

export const deviceSelectorReducer = reducerBuilder<IDeviceSelectorState>()
    .setInitialState(() => initialState)
    .onAction<void>(DeviceSelectorActions.ResetFilters, () => ({
        ...initialState,
    }))
    .onAction<IDeviceGroupInfo>(DeviceSelectorActions.SetDeviceGroupInfo, (state, action) => ({
        ...state,
        deviceGroup: action.payload.deviceGroup,
        categories: action.payload.categories,
        sortOrderOptions: action.payload.sortOrders,
        currentSortOrder: action.payload.defaultSortOrder,
    }))
    .onAction<string>(DeviceSelectorActions.SetSearchText, (state, action) => ({
        ...state,
        searchText: action.payload,
    }))
    .onAction<boolean>(DeviceSelectorActions.SetFilterPanelExpanded, (state, action) => ({
        ...state,
        isFilterPanelExpanded: action.payload,
    }))
    .onAction<SortOrder>(DeviceSelectorActions.SetSortOrder, (state, action) => ({
        ...state,
        currentSortOrder: action.payload,
    }))
    .onAction<IWearablesFilter>(DeviceSelectorActions.UpdateWearablesFilter, (state, action) => ({
        ...state,
        wearablesFilter: action.payload,
    }))
    .onAction<boolean | undefined>(DeviceSelectorActions.ToggleDiscontinued, (state, action) => ({
        ...state,
        includeDiscontinued: action.payload ?? !state.includeDiscontinued,
    }))
    .onAction<number | null>(DeviceSelectorActions.SetSelectedProductId, (state, action) => ({
        ...state,
        selectedProductId: action.payload,
    }))
    .onAction<void>(DeviceSelectorActions.ClearCompare, (state) => ({
        ...state,
        showComparison: false,
        productsToCompare: [],
    }))
    .onAction<boolean>(DeviceSelectorActions.ShowComparison, (state, action) => ({
        ...state,
        showComparison: action.payload,
    }))
    .onAction<PiaId>(DeviceSelectorActions.AddToCompare, (state, action) => ({
        ...state,
        productsToCompare: uniq(
            [...state.productsToCompare, action.payload].slice(0, MAX_COMPARE_COUNT),
        ),
    }))
    .onAction<PiaId>(DeviceSelectorActions.RemoveFromCompare, (state, action) => ({
        ...state,
        productsToCompare: state.productsToCompare.filter(
            (productId) => productId !== action.payload,
        ),
    }))
    .onAction<IProjectEntity>(DeviceSelectorActions.SetDefaultFilters, (state, action) => {
        return {
            ...state,
            cameraFilter: getDefaultCameraFilter(
                action.payload.unitSystem,
                action.payload.customInstallationHeight,
            ),
            mainUnitFilter: defaultMainUnitFilter,
            encoderFilter: defaultEncoderFilter,
            speakerFilter: getDefaultSpeakerFilter(action.payload.customInstallationHeight),
            sensorUnitFilter: getDefaultSensorUnitFilter(
                action.payload.unitSystem,
                action.payload.customInstallationHeight,
            ),
            twoNFilter: defaultTwoNFilter,
            accessControlFilter: defaultAccessControlFilter,
            wearablesFilter: defaultWearablesFilter,
            otherDeviceFilter: defaultOtherDeviceFilter,
            includeDiscontinued: false,
            searchText: '',
        };
    })
    .onAction<Partial<ICameraFilter>>(DeviceSelectorActions.SetCameraFilter, (state, action) => {
        state.cameraFilter = {
            ...state.cameraFilter,
            ...action.payload,
        };
        return {
            ...state,
        };
    })
    .onAction<ISpeakerFilter>(DeviceSelectorActions.SetSpeakerFilter, (state, action) => {
        state.speakerFilter = action.payload;
        return {
            ...state,
        };
    })
    .onAction<ISpeakerFilter>(DeviceSelectorActions.ResetSpeakerFilter, (state, action) => ({
        ...state,
        speakerFilter: action.payload,
        includeDiscontinued: false,
        searchText: '',
    }))
    .onAction<Partial<IDesiredCamera>>(
        DeviceSelectorActions.UpdateDesiredCamera,
        (state, action) => ({
            ...state,
            cameraFilter: {
                ...state.cameraFilter,
                desiredCamera: {
                    ...state.cameraFilter.desiredCamera,
                    ...action.payload,
                },
            },
        }),
    )
    .onAction<Partial<IDesiredSensorUnit>>(
        DeviceSelectorActions.UpdateDesiredSensorUnit,
        (state, action) => ({
            ...state,
            sensorUnitFilter: {
                ...state.sensorUnitFilter,
                desiredSensorUnit: {
                    ...state.sensorUnitFilter.desiredSensorUnit,
                    ...action.payload,
                },
            },
        }),
    )
    .onAction<Partial<ISensorUnitFilter>>(
        DeviceSelectorActions.UpdateSensorUnitFilter,
        (state, action) => ({
            ...state,
            sensorUnitFilter: {
                ...state.sensorUnitFilter,
                ...action.payload,
            },
        }),
    )
    .onAction<boolean>(
        DeviceSelectorActions.SetIsOperationalTemperatureFilterUsed,
        (state, action) => ({
            ...state,
            sensorUnitFilter: {
                ...state.sensorUnitFilter,
                operationalTemperature: {
                    ...state.sensorUnitFilter.operationalTemperature,
                    active: action.payload,
                },
            },
        }),
    )
    .onAction<Partial<ICameraFilter>>(
        DeviceSelectorActions.UpdateCameraFilter,
        (state, action) => ({
            ...state,
            cameraFilter: {
                ...state.cameraFilter,
                ...action.payload,
            },
        }),
    )
    .onAction<Partial<IMainUnitFilter>>(
        DeviceSelectorActions.UpdateMainUnitFilter,
        (state, action) => ({
            ...state,
            mainUnitFilter: {
                ...state.mainUnitFilter,
                ...action.payload,
            },
        }),
    )
    .onAction<Partial<IEncoderFilter>>(
        DeviceSelectorActions.UpdateEncoderFilter,
        (state, action) => ({
            ...state,
            encoderFilter: {
                ...state.encoderFilter,
                ...action.payload,
            },
        }),
    )
    .onAction<Partial<IAccessControlFilter>>(
        DeviceSelectorActions.UpdateAccessControlFilter,
        (state, action) => ({
            ...state,
            accessControlFilter: {
                ...state.accessControlFilter,
                ...action.payload,
            },
        }),
    )
    .onAction<Id | null>(DeviceSelectorActions.SetEditDeviceId, (state, action) => ({
        ...state,
        editDeviceId: action.payload,
    }))
    .onAction<Id | null>(DeviceSelectorActions.SetParentId, (state, action) => ({
        ...state,
        parentId: action.payload,
    }))
    .onAction<Partial<ISpeakerFilter>>(
        DeviceSelectorActions.UpdateSpeakerFilter,
        (state, action) => ({
            ...state,
            speakerFilter: {
                ...state.speakerFilter,
                ...action.payload,
            },
        }),
    )
    .onAction<Partial<IOtherDeviceFilter>>(
        DeviceSelectorActions.UpdateOtherDeviceFilter,
        (state, action) => ({
            ...state,
            otherDeviceFilter: {
                ...state.otherDeviceFilter,
                ...action.payload,
            },
        }),
    )
    .onAction<Partial<ITwoNFilter>>(DeviceSelectorActions.UpdateTwoNFilter, (state, action) => ({
        ...state,
        twoNFilter: {
            ...state.twoNFilter,
            ...action.payload,
        },
    }))
    .onAction<boolean>(DeviceSelectorActions.SetTopRecommendationVisible, (state, action) => ({
        ...state,
        topRecommendationVisible: action.payload,
    }))
    .onAction<boolean>(DeviceSelectorActions.SetBigScene3dVisible, (state, action) => ({
        ...state,
        bigScene3dVisible: action.payload,
    }))
    .onAction<IPiaIdWithModel | null>(DeviceSelectorActions.SetProductToAdd, (state, action) => ({
        ...state,
        productToAdd: action.payload,
    }))
    .onAction<boolean>(DeviceSelectorActions.SetIsAddingOrUpdating, (state, action) => ({
        ...state,
        isAddingOrUpdating: action.payload,
    }))
    .onAction<string>(DeviceSelectorActions.SetSelectedQuickAddDevice, (state, action) => ({
        ...state,
        quickAddSearchValue: action.payload,
    }))
    .create();
