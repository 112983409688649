import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Removes unused property FPS mode
 */
@injectable()
export class Migration8To9 extends MigrationProviderBase {
    public from: number = 8;
    public to: number = 9;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'item') {
                const profileOverride = entity.properties.camera
                    ? entity.properties.camera.profileOverride
                    : entity.properties.analogCamera
                      ? entity.properties.analogCamera.profileOverride
                      : entity.properties.sensorUnit
                        ? entity.properties.sensorUnit.profileOverride
                        : undefined;

                if (profileOverride) {
                    delete profileOverride.triggeredRecording.fpsMode;
                    delete profileOverride.continuousRecording.fpsMode;
                    delete profileOverride.liveView.fpsMode;
                }
            } else if (entity.type === 'profile') {
                delete entity.triggeredRecording.fpsMode;
                delete entity.continuousRecording.fpsMode;
                delete entity.liveView.fpsMode;
            }

            return entity;
        };
    }
}
