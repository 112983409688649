export const defaultMainUnitFilter: IMainUnitPropertiesFilterEntity = {
    channels: undefined,
    twoWayAudio: false,
    WDRTechnology: false,
    ruggedizedEN50155: false,
    alarmInputsOutputs: false,
    outdoor: false,
};

export interface IMainUnitPropertiesFilterEntity {
    channels: number | undefined;
    twoWayAudio: boolean;
    WDRTechnology: boolean;
    ruggedizedEN50155: boolean;
    alarmInputsOutputs: boolean;
    outdoor: boolean;
}
