import * as React from 'react';
import { t } from 'app/translate';
import {
    Badge,
    Box,
    Clickable,
    DropDown,
    DropDownMenuItem,
    PiaImage,
    Stack,
    Text,
} from 'app/components';
import type { Id, IDoorItemEntity, IIdRev, IItemEntity, IPersistence } from 'app/core/persistence';
import { ShadowButton } from './ShadowButton';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IRelatedPiaItem } from 'app/modules/common';
import {
    getPiaItem,
    getCurrentProjectLocked,
    PiaItemMsrp,
    DoorControllerService,
} from 'app/modules/common';
import { t as i18nextT } from 'i18next';
import { PiaAccessoryCategory } from 'app/core/pia';
import type { IPiaItem, PiaId } from 'app/core/pia';
import {
    getAllCardReadersForProduct,
    getDoor,
    getDoorsForProduct,
    getMaxNbrOfReadersForProduct,
    getNbrSelectedCardReadersForDoorController,
    getSelectedCardReadersForDoorController,
    getSelectedCardReadersForIdAndDoorSide,
} from '../selectors';
import { ServiceLocator } from 'app/ioc';
import type { ISelectedMountAccessory } from 'app/modules/accessorySelector';

interface ICardReaderSelectorProps {
    deviceItemId: Id;
    doorItemId: Id;
    isSideA: boolean;
}

export const CardReaderSelector: React.FC<ICardReaderSelectorProps> = ({
    deviceItemId,
    doorItemId,
    isSideA,
}) => {
    const isLocked = useSelector<IStoreState, boolean>((state) => getCurrentProjectLocked(state));

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, deviceItemId),
    );

    const cardReaders = useSelector<IStoreState, IRelatedPiaItem[]>((state) =>
        getAllCardReadersForProduct(state, piaItem?.id),
    );

    const door = useSelector<IStoreState, IPersistence<IDoorItemEntity> | undefined>((state) =>
        getDoor(state, doorItemId),
    );

    const maxNbrOfCardReaders = useSelector<IStoreState, number>((state) =>
        getMaxNbrOfReadersForProduct(state, deviceItemId),
    );

    const [doorControllerService] = React.useState<DoorControllerService>(
        ServiceLocator.get(DoorControllerService),
    );

    const selectedCardReadersForDoorSide = useSelector<
        IStoreState,
        Record<PiaId, ISelectedMountAccessory> | undefined
    >((state) =>
        getSelectedCardReadersForIdAndDoorSide(
            state,
            doorItemId,
            isSideA ? 'doorSideA' : 'doorSideB',
        ),
    );

    const totalNbrSelectedReaders = useSelector<IStoreState, number>((state) =>
        getNbrSelectedCardReadersForDoorController(state, deviceItemId),
    );

    const cardReadersForDoorController = useSelector<
        IStoreState,
        Record<Id, ISelectedMountAccessory[]>
    >((state) => getSelectedCardReadersForDoorController(state, deviceItemId));

    const doors = useSelector<IStoreState, IPersistence<IItemEntity>[]>((state) =>
        getDoorsForProduct(state, deviceItemId),
    );

    // If a door is added without a selected cardReader we want to reserve a reader for that one.
    const reservedReaders = doors.reduce((sum, currentDoor) => {
        return (
            sum +
            (doorItemId !== currentDoor._id &&
            cardReadersForDoorController[currentDoor._id].length === 0
                ? 1
                : 0)
        );
    }, 0);

    const nbrCardReadersThisSide =
        (selectedCardReadersForDoorSide && Object.values(selectedCardReadersForDoorSide).length) ||
        0;

    const disableCardReader =
        nbrCardReadersThisSide < 1 && // only disable if card reader is not selected
        maxNbrOfCardReaders - totalNbrSelectedReaders - reservedReaders < 1; // disable if there are not enough readers left

    // for now we only allow one card reader. Take the name of the first one (and only)
    const selectedCardReaderName =
        selectedCardReadersForDoorSide && Object.values(selectedCardReadersForDoorSide)[0]
            ? Object.values(selectedCardReadersForDoorSide)[0].productId
                ? Object.values(selectedCardReadersForDoorSide)[0].name
                : t.other
            : undefined;

    const getCardReadersToRemove = (): IIdRev[] => {
        let cardReadersToRemove: IIdRev[] = [];
        if (
            selectedCardReadersForDoorSide &&
            Object.keys(selectedCardReadersForDoorSide).length > 0
        ) {
            const cardReadersToRemoveAxis = Object.values(selectedCardReadersForDoorSide).map(
                (reader) => ({ _id: reader.id, _rev: reader.rev }),
            );
            cardReadersToRemove = cardReadersToRemove.concat(cardReadersToRemoveAxis);
        }
        return cardReadersToRemove;
    };

    const onCardReaderSelect = (piaId: number) => {
        if (!door?.properties.door) {
            return;
        }
        // if card-reader already selected remove it
        if (selectedCardReadersForDoorSide && selectedCardReadersForDoorSide[piaId]) {
            const itemIdToRemove = selectedCardReadersForDoorSide[piaId];
            doorControllerService.removeCardReader({
                _id: itemIdToRemove.id,
                _rev: itemIdToRemove.rev,
            });
        } else {
            // if some cardReader selected, remove them
            const cardReadersToRemove = getCardReadersToRemove();
            doorControllerService.addCardReader(door?._id, piaId, isSideA, cardReadersToRemove);
        }
    };

    const content = [
        <DropDownMenuItem isText>
            <Text style="heading">
                {i18nextT(`accessoryDisplayCategoriesGROUP.${PiaAccessoryCategory.READERS}`)}
            </Text>
        </DropDownMenuItem>,
        ...cardReaders.map((cardReader) => (
            <Clickable
                key={cardReader.id}
                onClick={() => {
                    onCardReaderSelect(cardReader.id);
                }}
            >
                <DropDownMenuItem
                    testId={`card_reader_${cardReader.name}`}
                    onlyContent
                    selected={
                        selectedCardReadersForDoorSide &&
                        selectedCardReadersForDoorSide[cardReader.id] !== undefined
                    }
                >
                    <Box padding="cell" justifyContent="between" alignItems="center" width="100%">
                        <Stack>
                            <PiaImage piaId={cardReader.id} icon="category" imageSize="md" />
                            <Stack vertical spacing="none">
                                <Text>{cardReader.id ? cardReader.name : t.other}</Text>
                            </Stack>
                        </Stack>
                        <Stack>
                            {cardReader.isIncluded && (
                                <Badge compact label={t.included} type="yellow" />
                            )}
                            {cardReader.isRecommended && !cardReader.isIncluded && (
                                <Badge compact label={t.recommended} type="green" />
                            )}
                            {!cardReader.isIncluded && (
                                <PiaItemMsrp piaId={cardReader.id} includeMultipacks />
                            )}
                        </Stack>
                    </Box>
                </DropDownMenuItem>
            </Clickable>
        )),
    ];

    return (
        <DropDown
            testId="select_card_reader"
            disabled={isLocked || disableCardReader}
            minWidth={400}
            trigger={
                <ShadowButton
                    icon="add"
                    label={
                        selectedCardReaderName
                            ? `${t.cardReader}: ${selectedCardReaderName}`
                            : t.cardReader
                    }
                    selected={selectedCardReaderName !== undefined}
                />
            }
            placeHolder={t.cardReader}
            contents={content}
            openInPortal
        />
    );
};

CardReaderSelector.displayName = 'CardReaderSelector';
