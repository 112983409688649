import type { IRecorderConfiguration } from '../common';
import { enhanceRecorder } from '../common';
import { partitionDevicesToRecorder } from './partitioning';
import { cloneDeep } from 'lodash-es';

// compare recorders by number of extra licenses
const byExtraLicenses = (a: IRecorderConfiguration, b: IRecorderConfiguration) =>
    b.licenses.length - a.licenses.length;

// get the disk size of a recorder
const getDiskSize = (recorder: IRecorderConfiguration) =>
    recorder.disks.reduce(
        (acc, disk) => acc + (disk.properties.maxRecordingStorageMegaBytes ?? 0),
        0,
    );

/*
 * Optimize the recorders by reassigning devices from recorders with additional
 * licenses to recorders with unused licenses.
 */
export const optimizeRecorders = (
    recordersToOptimize: IRecorderConfiguration[],
): IRecorderConfiguration[] => {
    // base case: if there are no recorders to optimize, we return an empty array
    if (recordersToOptimize.length === 0) {
        return [];
    }

    // clone the recorders to avoid side effects when modifying them
    const recorders = cloneDeep(recordersToOptimize);

    // sort the recorders by number of extra licenses. The recorders with extra
    // licenses will be the first ones in the array
    const [source, ...rest] = recorders.sort(byExtraLicenses);

    // if the source recorder has no extra licenses we don't need to optimize it
    if (source.licenses.length === 0) {
        return recorders;
    }

    // loop through the rest of the recorders and try to reassign devices to
    // recorders with unused licenses
    for (const target of rest) {
        // if the recorder has no extra licenses, we can skip it
        if (target.overCapacity.vmsChannelLicenses === 0) {
            continue;
        }

        // the target recorder is the recorder that we want to reassign devices to
        const enhancedTarget = enhanceRecorder(target.recorder, getDiskSize(target), 0);

        // loop through the devices of the first recorder and try to reassign them
        // to the current recorder
        for (const device of source.devices) {
            // if we have no more licenses to reassign, we can break the loop
            if (source.licenses.length === 0) {
                break;
            }

            // try to reassign the device to the target recorder
            const targetPartitioning = partitionDevicesToRecorder(enhancedTarget, [
                ...target.devices,
                device,
            ]);

            if (targetPartitioning.remainingDevices.length === 0) {
                // if the device could be reassigned, we update the recorder
                target.overCapacity = {
                    ...targetPartitioning.overCapacity,
                    freeHddBays: target.overCapacity.freeHddBays,
                };
                // update the devices of the recorder (add the reassigned device)
                target.devices = targetPartitioning.selectedDevices;

                // now we need to update the first recorder. To do so we first construct
                // an enhanced version of the source recorder with the extra licenses and
                // storage. Since we are removing a device from the source recorder, we also
                // remove an extra license
                const enhancedSource = enhanceRecorder(
                    source.recorder,
                    getDiskSize(source),
                    source.licenses.length - 1,
                );

                // remove the reassigned device from the source recorder's devices
                const newDevices = source.devices.filter((it) => it !== device);

                // calculate the partitioning of the recorder without the reassigned device
                const sourcePartitioning = partitionDevicesToRecorder(enhancedSource, newDevices);

                // update the recorder with the new overcapacity and devices.
                // We also remove an extra license from the recorder (which is the
                // whole point of this optimization)
                source.overCapacity = {
                    ...sourcePartitioning.overCapacity,
                    freeHddBays: source.overCapacity.freeHddBays,
                };
                source.devices = sourcePartitioning.selectedDevices;
                source.licenses.pop();
            }
        }
    }

    return [source, ...optimizeRecorders(rest)];
};
