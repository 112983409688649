import { t } from 'app/translate';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FilterPanel } from './FilterPanel';
import {
    Action,
    Box,
    DropDown,
    DropDownMenuButton,
    Grid,
    PillButton,
    Stack,
    Text,
} from 'app/components';
import type { IStoreState } from 'app/store';
import type { ISpeakerFilter } from '../../models';
import type { DistanceUnit, SpeakerPlacement, UnitSystem } from 'app/core/persistence';
import { distanceUnitShortText } from 'app/core/persistence';
import {
    DistanceRange,
    getCurrentProjectDisplayUnit,
    getCurrentProjectUnitSystem,
} from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../../services';
import { convertToDisplayValue } from '../../utils';
import { SearchField } from './common';
import { SpeakerRecommendedQuantityContainer } from './speaker';
import { SustainabilityFilter } from './SustainabilityFilter';

export const SpeakerFilterPanel: React.FC = () => {
    const speakerFilter = useSelector<IStoreState, ISpeakerFilter>(
        (store) => store.deviceSelector.speakerFilter,
    );

    const unitSystem = useSelector<IStoreState, UnitSystem>((store) =>
        getCurrentProjectUnitSystem(store),
    );

    const displayUnit = useSelector<IStoreState, DistanceUnit>((store) =>
        getCurrentProjectDisplayUnit(store),
    );

    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const { placement, outdoor, installationHeight } = speakerFilter;

    const channelButtonText = !placement
        ? t.placement
        : placement === 'ceiling'
          ? t.speakerSelectorCeiling
          : t.speakerSelectorWall;

    const getInstallationHeightButtonText = () => {
        const installationHeightWithUnit = convertToDisplayValue(
            unitSystem,
            false,
            installationHeight,
        );
        return `${t.installationHeight}: ${installationHeightWithUnit} ${distanceUnitShortText(
            displayUnit,
        )}`;
    };

    /**
     * Resets speaker filter to its default values.
     * Also sets includeDiscontinued to false and clears search text.
     */
    const resetFilter = () => {
        actionService.resetSpeakerFilter();
    };

    const updateSpeakerPlacement = (speakerPlacement: SpeakerPlacement) => {
        actionService.updateSpeakerFilter({
            placement: placement === speakerPlacement ? undefined : speakerPlacement,
        });
    };

    const updateSpeakerHeight = (newValue: number) => {
        const updatedHeight = {
            installationHeight: newValue,
        };
        actionService.updateSpeakerFilter(updatedHeight);
    };

    const maxHeight = unitSystem === 'metric' ? 50 : 150;

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                <Stack alignItems="center" justifyContent="between">
                    <Text style="semibold" color="grey6">
                        {t.cameraSelectorRequirementsTitle}
                    </Text>
                    <Action title={t.reset} onClick={resetFilter} />
                </Stack>
                <Grid spacing="halfQuart">
                    <DropDown
                        trigger={
                            <PillButton
                                testId="speaker_placement"
                                text={channelButtonText}
                                icon="keyboard_arrow_down"
                                selected={!!placement}
                            />
                        }
                        contents={
                            <>
                                <DropDownMenuButton
                                    label={t.speakerSelectorCeiling}
                                    selected={placement === 'ceiling'}
                                    onClick={() => updateSpeakerPlacement('ceiling')}
                                />
                                <DropDownMenuButton
                                    label={t.speakerSelectorWall}
                                    selected={placement === 'wall'}
                                    onClick={() => updateSpeakerPlacement('wall')}
                                />
                            </>
                        }
                    />
                    <DropDown
                        stayOpen={true}
                        trigger={
                            <PillButton
                                text={getInstallationHeightButtonText()}
                                icon="keyboard_arrow_down"
                                selected={installationHeight != undefined}
                            />
                        }
                        contents={
                            <Box
                                borderRadius="blunt"
                                color="white"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="40px"
                                paddingX="halfCell"
                                paddingY="cell"
                            >
                                {
                                    <DistanceRange
                                        testId="npt_speaker_height"
                                        color="blue"
                                        displayUnit={displayUnit}
                                        min={0}
                                        max={maxHeight}
                                        step={0.1}
                                        showValue={true}
                                        showValueInLabel={true}
                                        label={''}
                                        logarithmic={true}
                                        onChange={(newDistance) => updateSpeakerHeight(newDistance)}
                                        value={installationHeight}
                                        decimals={1}
                                        changeCriteria="key"
                                    />
                                }
                            </Box>
                        }
                    />
                    <PillButton
                        checkable
                        text={t.outdoor}
                        selected={outdoor}
                        onClick={() =>
                            actionService.updateSpeakerFilter({
                                outdoor: !outdoor,
                            })
                        }
                    />
                    <SustainabilityFilter />
                </Grid>
            </Stack>
            <SpeakerRecommendedQuantityContainer />
        </FilterPanel>
    );
};

SpeakerFilterPanel.displayName = 'SpeakerFilterPanel';
