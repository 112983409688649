import { reducerBuilder } from 'app/store';
import { RecordingSelectorActions } from './RecordingSelector.actions';
import {
    type CameraStationType,
    type CameraStationCenterType,
    type Id,
    type IPartnerRecommendationResponse,
    type IPartnerSystemComponent,
    type RecordingCategoriesFilter,
    defaultRecordingCategory,
} from 'app/core/persistence';
import type { IRecordingSelectorState } from './IRecordingSelectorState';
import type { IValidationResult } from '../selectors/validation';

const initialState: IRecordingSelectorState = {
    showProductSelector: false,
    selectedDeviceDetails: undefined,
    filterByCategory: defaultRecordingCategory,
    ItemIdForNetworkSettings: undefined,
    smallOverlay: false,
    validation: {
        running: false,
        result: null,
    },
    genetecRecommendationsState: {
        isFetchingProducts: false,
        isFetchingRecommendations: false,
        products: [],
        recommendations: [],
        recommendationError: false,
    },
    searchFilter: '',
};

export const recordingSelectorReducer = reducerBuilder<IRecordingSelectorState>()
    .setInitialState(initialState)
    .onAction<RecordingCategoriesFilter>(
        RecordingSelectorActions.FilterProductSelector,
        (state, action) => {
            return {
                ...state,
                filterByCategory: action.payload,
            };
        },
    )
    .onAction<Id | undefined>(RecordingSelectorActions.ToggleDeviceDetails, (state, action) => {
        return {
            ...state,
            showProductSelector: false,
            selectedDeviceDetails: action.payload,
        };
    })
    .onAction<Id | undefined>(
        RecordingSelectorActions.SetNetworkSettingsDetails,
        (state, action) => {
            return {
                ...state,
                showProductSelector: false,
                ItemIdForNetworkSettings: action.payload,
            };
        },
    )
    .onAction<boolean>(RecordingSelectorActions.ToggleProductSelector, (state, action) => {
        return {
            ...state,
            showProductSelector: action.payload,
        };
    })
    .onAction<boolean>(RecordingSelectorActions.SetSmallOverlay, (state, action) => {
        return {
            ...state,
            smallOverlay: action.payload,
        };
    })
    .onAction(RecordingSelectorActions.SetValidationRunning, (state) => ({
        ...state,
        validation: {
            ...state.validation,
            running: true,
        },
    }))
    .onAction<IValidationResult | null>(
        RecordingSelectorActions.SetValidationResult,
        (state, action) => ({
            ...state,
            validation: {
                running: false,
                result: action.payload,
            },
        }),
    )
    .onAsyncAction(RecordingSelectorActions.GetGenetecRecommendations, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                genetecRecommendationsState: {
                    ...state.genetecRecommendationsState,
                    isFetchingRecommendations: true,
                    recommendations: [],
                    recommendationError: false,
                },
            }))
            .onFulfilled<IPartnerRecommendationResponse>((state, action) => ({
                ...state,
                genetecRecommendationsState: {
                    ...state.genetecRecommendationsState,
                    isFetchingRecommendations: false,
                    recommendations: action.payload.partnerRecommendation,
                    recommendationError: action.payload.error,
                },
            }))
            .onRejected<IPartnerRecommendationResponse>((state) => ({
                ...state,
                genetecRecommendationsState: {
                    ...state.genetecRecommendationsState,
                    isFetchingRecommendations: false,
                    recommendations: [],
                },
            }));
    })
    .onAsyncAction(RecordingSelectorActions.GetGenetecProducts, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                genetecRecommendationsState: {
                    ...state.genetecRecommendationsState,
                    isFetchingProducts: true,
                    products: [],
                },
            }))
            .onFulfilled<IPartnerSystemComponent[]>((state, action) => ({
                ...state,
                genetecRecommendationsState: {
                    ...state.genetecRecommendationsState,
                    isFetchingProducts: false,
                    products: action.payload,
                },
            }))
            .onRejected<IPartnerSystemComponent[]>((state) => ({
                ...state,
                genetecRecommendationsState: {
                    ...state.genetecRecommendationsState,
                    isFetchingProducts: false,
                    products: [],
                },
            }));
    })
    .onAction<IRecordingSelectorState>(RecordingSelectorActions.ResetToInitialState, () => ({
        ...initialState,
    }))
    .onAction<string>(RecordingSelectorActions.SetSearchFilter, (state, { payload }) => ({
        ...state,
        searchFilter: payload,
    }))
    .onAction<CameraStationType>(
        RecordingSelectorActions.SetViewingAxisAcsType,
        (state, action) => ({
            ...state,
            recordingViewingAxisAcsType: action.payload,
        }),
    )
    .onAction<CameraStationCenterType>(RecordingSelectorActions.SetAxisCenter, (state, action) => ({
        ...state,
        recordingCenterSelection: action.payload,
    }))
    .create();
