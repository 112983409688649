import { getCurrentProjectItem, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { t } from 'app/translate';
import { UnreachableCaseError } from 'axis-webtools-util';
import { getDeviceType } from 'app/core/persistence';

export const getChangeLabel = createCachedSelector([getCurrentProjectItem], (item) => {
    if (!item) {
        return '';
    }

    const deviceType = getDeviceType(item);

    switch (deviceType) {
        case 'camera':
        case 'bodyWornCamera':
            return t.deviceListChangeCamera;
        case 'encoder':
            return t.deviceListChangeEncoder;
        case 'mainUnit':
            return t.deviceListChangeMainUnit;
        case 'sensorUnit':
            return t.deviceListChangeSensorUnit;
        case 'speaker':
            return t.deviceListChangeSpeaker;
        case 'pac':
        case 'analogCamera':
        case 'radardetector':
        case 'decoder':
        case 'doorstation':
        case 'peopleCounter':
        case 'systemController':
        case 'dockingStation':
        case 'cameraExtension':
        case 'door':
        case 'doorcontroller':
        case 'alerter':
        case 'connectivitydevice':
        case 'pagingconsole':
        case undefined:
            return t.deviceListChangeDevice;
        default:
            throw new UnreachableCaseError(deviceType, `Missing change label.`);
    }
})(toCacheKey);
