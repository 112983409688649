const CLIENT_IDS: Record<string, string> = {
    'https://sitedesigner.playground.webtools.axis.com': 'sitedesigner_webapp_playground',
    'https://sitedesigner.sandbox.webtools.axis.com': 'sitedesigner_webapp_sandbox',
    'https://beta-sitedesigner.sandbox.webtools.axis.com': 'sitedesigner_webapp_beta_sandbox',
    'https://sitedesigner.webtools-dev.axis.com': 'sitedesigner_webapp_dev',
    'https://sitedesigner.webtools-stage.axis.com': 'sitedesigner_webapp_stage',
    'https://sitedesigner.axis.com': 'sitedesigner_webapp_prod',
    'https://enterprisedesigner.local.webtools.se.axis.com:8080': 'sitedesigner_webapp_local',
};

export const originToClientId = (origin: string): string =>
    CLIENT_IDS[origin] || 'sitedesigner_webapp_prod';

export class AppSettings {
    public readonly axisLoginUrl =
        `https://auth.axis.com/oauth2/oauth-authorize` +
        `?client_id=${originToClientId(window.location.origin)}` +
        `&response_type=code` +
        `&redirect_uri=${window.location.origin}${window.appConfig.auth.authorizationCallback}` +
        `&scope=openid%20profile%20email`;
    public readonly axisLogoutUrl =
        `https://auth.axis.com/authn/anonymous/logout` +
        `?redirect_uri=${window.location.origin}${window.appConfig.auth.logoutCallback}`;
    public readonly userInfoDbName = 'axis.webtools.userApiInfo';
    public readonly userInfoStoreName = 'userInfo';
    public readonly localFloorPlansDbName = 'axis.webtools.floorPlans';
    public readonly localFloorPlansStoreName = 'floorPlans';
    public readonly userInfoOverrideStoreName = 'userInfoOverride';
    public readonly userApiEndpoint = `${window.appConfig.userApi.endpoint}${'/v1/users'}`;
    public readonly userApiPrivilegesEndpoint = window.appConfig.userPrivileges.endpoint;
    public readonly userApiTokenEndpoint = window.appConfig.userApiTokenUrl;
    public readonly namespace = window.appConfig.namespace;
    public readonly priceSubUrlDistributors = window.appConfig.priceApi.subUrlDistributors;
    public readonly priceSubUrlPrices = window.appConfig.priceApi.subUrlPrices;
    public readonly priceSubUrlRates = window.appConfig.priceApi.subUrlRates;
    public readonly priceUrl = window.appConfig.priceApi.endpoint;
    public readonly authenticationCookie = 'axis_auth';
}
