import type { IExtendableComponent, htmlAttributes } from '../models';

/**
 * Merge the __htmlAttributes prop from one component with
 * a new htmlAttributes object. The new object will overwrite
 * the old one except for className and style which will be merged
 * together.
 *
 * Set the last parameter to return the props
 * as they are, otherwise you get an object with the __htmlAttributes
 * prop set to the new props.
 */
export const extendableProps = (
    props: IExtendableComponent | undefined,
    newProps: htmlAttributes,
    isElement = false,
) => {
    const oldProps = props ? props.__htmlAttributes : {};
    const newAttributes: htmlAttributes = { ...oldProps, ...newProps };

    newAttributes.className = [
        (oldProps && oldProps.className) || '',
        (newProps && newProps.className) || '',
    ].join(' ');

    const oldPropsStyle = oldProps ? oldProps.style || {} : {};
    const newPropsStyle = newProps ? newProps.style || {} : {};

    newAttributes.style = {
        ...oldPropsStyle,
        ...newPropsStyle,
    };

    return isElement ? { ...newAttributes } : { __htmlAttributes: { ...newAttributes } };
};

export type ExtendableProps = ReturnType<typeof extendableProps>;
