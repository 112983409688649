import * as PolyBool from 'polybooljs';
import type { Point } from 'axis-webtools-util';

export type Line = Point[];

export interface IPolygon {
    regions: Line[];
    inverted: boolean;
}

/**
 * Calculate the bounding box of a polygon by iterating over all points
 */
const getBoundingBox = (
    polygon: IPolygon,
): { xMin: number; xMax: number; yMin: number; yMax: number } => {
    return polygon.regions.reduce(
        (regionAcc, region) => {
            return region.reduce((acc, point) => {
                return {
                    xMin: Math.min(acc.xMin, point[0]),
                    xMax: Math.max(acc.xMax, point[0]),
                    yMin: Math.min(acc.yMin, point[1]),
                    yMax: Math.max(acc.yMax, point[1]),
                };
            }, regionAcc);
        },
        { xMin: Infinity, xMax: -Infinity, yMin: Infinity, yMax: -Infinity },
    );
};

/**
 * Check if two polygons can intersect by checking that both are non-empty and that
 * their bounding boxes intersect. Used as a quick check before running more expensive
 * intersection calculations.
 */
const canPolygonsIntersect = (a: IPolygon, b: IPolygon): boolean => {
    // If any of the polygons is empty, the intersection is empty
    if (a.regions.length === 0 || b.regions.length === 0) {
        return false;
    }

    // If the bounding boxes of the two polygons do not intersect, the polygons do not intersect
    const aBounds = getBoundingBox(a);
    const bBounds = getBoundingBox(b);
    return !(
        aBounds.xMax < bBounds.xMin ||
        aBounds.xMin > bBounds.xMax ||
        aBounds.yMax < bBounds.yMin ||
        aBounds.yMin > bBounds.yMax
    );
};

/**
 * Calculate the difference between polygons a and b. On fail try with a larger epsilon
 * @param a
 * @param b
 */
export const diffPolygons = (a: IPolygon, b: IPolygon, triesLeft = 5): IPolygon => {
    if (triesLeft === 0) {
        return a;
    }

    if (!canPolygonsIntersect(a, b)) {
        return a;
    }

    try {
        return PolyBool.difference(a, b);
    } catch (e) {
        // As any other PolyBool operation `difference` sometimes fails and throws an
        // exception. In many cases retrying with a different epsion value helps.
        PolyBool.epsilon(PolyBool.epsilon() * 10);
        const value = diffPolygons(a, b, triesLeft - 1);
        PolyBool.epsilon(PolyBool.epsilon() / 10);
        return value;
    }
};

/**
 * Calculate the intersection of polygons a and b. On fail try with a larger epsilon
 * @param a
 * @param b
 */
export const intersectPolygons = (a: IPolygon, b: IPolygon, triesLeft = 5): IPolygon => {
    if (triesLeft === 0) {
        return a;
    }

    if (!canPolygonsIntersect(a, b)) {
        return { regions: [], inverted: false };
    }

    try {
        return PolyBool.intersect(a, b);
    } catch (e) {
        // As any other PolyBool operation `intersect` sometimes fails and throws an
        // exception. In many cases retrying with a different epsion value helps.
        PolyBool.epsilon(PolyBool.epsilon() * 10);
        const value = intersectPolygons(a, b, triesLeft - 1);
        PolyBool.epsilon(PolyBool.epsilon() / 10);
        return value;
    }
};

/**
 * Remove multiple polygons from original
 * @param original
 * @param toSubtract
 */
export const diffMultiplePolygons = (original: IPolygon, toSubtract: IPolygon[]): IPolygon => {
    return toSubtract.reduce(
        (acc: IPolygon, subtrahend) => diffPolygons(acc, subtrahend),
        original,
    );
};
