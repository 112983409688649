import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { Icons } from 'app/components';
import { Box, PiaImage } from 'app/components';
import type { Spacings } from 'app/styles';
import { getPiaIcon } from '../../piaDevices/selectors/getPiaIcon';
import { isPriceListAvailable } from '../selectors/isPriceListAvailable';
import { doesPiaIdMsrpExist } from '../selectors/getItemMsrp';
import { PiaItemMsrp } from './PiaItemMsrp.container';

interface IMsrpWithPiaImageProps {
    piaId: number;
    displayImage: boolean;
    includeMultipacks?: boolean;
    paddingX?: Spacings;
}

export const MsrpWithPiaImage: React.FunctionComponent<IMsrpWithPiaImageProps> = ({
    displayImage,
    includeMultipacks,
    piaId,
    paddingX = 'quart',
}) => {
    const selectorProps = {
        piaId: piaId,
        includeMultipacks,
    };

    const icon = useSelector<IStoreState, Icons>((state) => getPiaIcon(state, piaId));
    const doesPriceListExist = useSelector(isPriceListAvailable);
    const msrpExists = useSelector<IStoreState, boolean>((state) =>
        doesPiaIdMsrpExist(state, selectorProps),
    );

    if (!piaId) return null;

    return (
        <Box alignItems="center" paddingX={paddingX}>
            {displayImage && doesPriceListExist && (
                <PiaImage piaId={piaId} icon={icon} imageSize="sm" />
            )}
            <Box paddingLeft="quart">
                {msrpExists ? (
                    <PiaItemMsrp includeMultipacks={includeMultipacks} piaId={piaId} />
                ) : doesPriceListExist ? (
                    '-'
                ) : null}
            </Box>
        </Box>
    );
};
