import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getDeviceAndSubTypeForItem } from './getDeviceTypeForItem';

export const getIsChildDevice = createCachedSelector([getDeviceAndSubTypeForItem], (deviceType) => {
    return (
        deviceType === 'analogCamera' ||
        deviceType === 'sensorUnit' ||
        deviceType === 'door' ||
        deviceType === 'relayexpmodules'
    );
})(toCacheKey);
