import type { Id, IFloorPlanEntity } from 'app/core/persistence';
import { FloorPlanService } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';
import { getCurrentProjectFloorPlans } from 'app/modules/common';
import { getMapMarkersForFloorPlanWithDeviceIds } from './getMapMarkersForFloorPlan';
import { uniq } from 'lodash-es';
import { isDefined } from 'axis-webtools-util';
import { nameComparator } from 'app/utils';

// itemIds must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getFloorPlanIdFromProps = (_state: IStoreState, _itemIds: Id[], floorPlanId?: Id) =>
    floorPlanId;

const getItemIds = (_state: IStoreState, itemIds: Id[]) => itemIds;

export const getFloorPlansWithDeviceIds = createCachedSelector(
    [
        getMapMarkersForFloorPlanWithDeviceIds,
        getCurrentProjectFloorPlans,
        getItemIds,
        getFloorPlanIdFromProps,
    ],
    (mapMarkersForFloorPlanWithDeviceIds, floorPlans, _itemIds, floorPlanId) => {
        return uniq(
            Object.keys(mapMarkersForFloorPlanWithDeviceIds)
                .reduce((filteredFloorPlans, markerFloorPlanId) => {
                    const floorPlan = floorPlans[markerFloorPlanId];

                    if ((!floorPlanId || floorPlanId === markerFloorPlanId) && floorPlan) {
                        filteredFloorPlans.push(floorPlan);
                    }
                    return filteredFloorPlans;
                }, [] as IFloorPlanEntity[])
                .filter((floorPlan) => FloorPlanService.isFloorPlanMapType(floorPlan)) // Street Map support will be added later
                .filter(isDefined)
                .sort(nameComparator),
        );
    },
)((_state, itemIds, floorPlanId) => itemIds.join(',') + '_' + floorPlanId);
