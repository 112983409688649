import type { IPiaDocumentRepository } from '../types';

// User to do prefix range search in pouch
const HIGH_VALUE_UNICODE = '\uffff';

export abstract class PiaBasePouchDbRepository<TDocument extends Record<string, any>>
    implements IPiaDocumentRepository<TDocument>
{
    constructor(
        private database: PouchDB.Database,
        private idPrefix: string,
    ) {}

    public get(id: string): Promise<Readonly<TDocument>> {
        return this.database.get<Readonly<TDocument>>(this.prefixId(id));
    }

    public async getMultiple(ids: string[]): Promise<Array<Readonly<TDocument>>> {
        const docs = await this.database.allDocs({
            include_docs: true,
            startkey: this.idPrefix,
            endkey: `${this.idPrefix}${HIGH_VALUE_UNICODE}`,
            keys: ids.map((id) => this.prefixId(id)),
        });
        return this.extractDocumentsFromResponse(docs as PouchDB.Core.AllDocsResponse<TDocument>);
    }

    public async getAll(): Promise<TDocument[]> {
        const docs = await this.database.allDocs({
            include_docs: true,
            startkey: this.idPrefix,
            endkey: `${this.idPrefix}${HIGH_VALUE_UNICODE}`,
        });
        return this.extractDocumentsFromResponse(docs as PouchDB.Core.AllDocsResponse<TDocument>);
    }

    private prefixId(unprefixedId: string) {
        return `${this.idPrefix}${unprefixedId}`;
    }

    private extractDocumentsFromResponse(
        response: PouchDB.Core.AllDocsResponse<TDocument>,
    ): TDocument[] {
        return response.rows
            .filter((row) => !row.hasOwnProperty('error'))
            .map((row) => row.doc as TDocument);
    }
}
