import { createSelector } from 'reselect';
import type { Colors } from 'app/styles';
import { getCurrentProjectItemsArray } from 'app/modules/common';
import { defaultColors } from 'app/core/common';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';

const getDefaultColor = (item: IPersistence<IItemEntity>): Colors | undefined => {
    if (item.properties.speaker || item.properties.pagingConsole) {
        return defaultColors.DEFAULT_SPEAKER_COLOR;
    }
    if (item.properties.radarDetector) {
        return defaultColors.DEFAULT_RADAR_COLOR;
    }
    if (item.properties.doorController) {
        return defaultColors.DEFAULT_DOOR_CONTROLLER_COLOR;
    }

    return defaultColors.DEFAULT_DEVICE_COLOR;
};

export const getCurrentProjectItems = createSelector([getCurrentProjectItemsArray], (items) =>
    items.reduce(
        (acc, item) => {
            acc[item._id] = {
                ...item,
                color: item.color ?? getDefaultColor(item),
            };
            return acc;
        },
        {} as Record<Id, IPersistence<IItemEntity>>,
    ),
);
