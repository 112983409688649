import { createSelector } from 'reselect';
import { getCameraFilter, getDeviceGroup, getSensorUnitFilter } from './getProductsForDeviceGroup';

/**
 * Determines if we should show resolution preview on ProductItem.
 * We only want to display it for device groups that have access to scene settings
 * in their filter panels and have them activated.
 */
export const getShowResolutionPreview = createSelector(
    [getDeviceGroup, getCameraFilter, getSensorUnitFilter],
    (deviceGroup, cameraFilter, sensorUnitFilter) => {
        if (deviceGroup === 'cameras') {
            return cameraFilter.desiredCamera.isSceneFilterActive;
        }
        if (deviceGroup === 'sensorunits') {
            return sensorUnitFilter.desiredSensorUnit.isSceneFilterActive;
        }
        return false;
    },
);
