import { injectable } from 'inversify';
import type {
    Id,
    IFloorPlanImage,
    ICurrentProjectRepository,
    ILatLng,
    IPersistence,
    IInstallationPointEntity,
    IFloorPlanEntity,
    IItemRelationEntity,
    IItemEntity,
    IBounds,
    IBlockerEntity,
} from 'app/core/persistence';
import { ItemService, ProjectModelService, getParentId, ImageService } from 'app/core/persistence';
import type {
    IExportedMap,
    IMapImage,
    IMapBlocker,
    IMapInstallationPoint,
    IImageSensor,
    IGeoMapImage,
} from '../../../models';
import type { IPiaDevice, IPiaCamera, IPiaSpeaker, IPiaRadarDetector } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import { isDefined, offset } from 'axis-webtools-util';
import { ColorsEnum } from 'app/styles';
import { t } from 'app/translate';
import { generateUniqueSplitId } from '../../splitByQuantity';
import { toaster } from 'app/toaster';
import {
    createDerotationTransform,
    estimateVerticalFOV,
    getFloorPlanGeoLocation,
    isGeoLocatedFloorPlan,
    mapBlockersToFloorPlan,
    mapBlockersToGeoMap,
    mapInstallationEntityToModel,
    mapInstallationPointsToFloorPlan,
    mapInstallationPointsToGeoMap,
    transformBlockerEntity,
    transformInstallationPoint,
} from 'app/modules/common';
import { creationDateReverseComparator, idComparator } from 'app/utils';

@injectable()
export class MapsExporterService {
    constructor(
        private projectModelService: ProjectModelService,
        private itemService: ItemService,
        private piaItemService: PiaItemService<IPiaDevice>,
        private imageService: ImageService,
    ) {}

    public async getExportedMaps(projectId: Id, embedImages: boolean): Promise<IExportedMap[]> {
        const exportedMaps = await this.mapExportedMaps(projectId, embedImages);

        // If any of the exported map images are missing an image, something went wrong trying to fetch them in the image store communicator
        if (exportedMaps.find((map) => map.images.some((img) => !(img.image || img.imageUrl)))) {
            toaster.info(t.acsShareMapDownloadErrorTitle, t.acsShareMapDownloadErrorMessage);
        }

        return exportedMaps;
    }

    public async mapExportedMaps(projectId: Id, embedImages: boolean): Promise<IExportedMap[]> {
        const project = await this.projectModelService.getProjectModels(projectId);

        // Record of items that has new ids in the export because of the split logic.
        // The index is the item id and the value is the number of times it has been used
        // in the installation points.
        const itemsWithSplitIds: Record<string, number> = {};
        // since an installation point can exist multiple times in export
        // (both represented on the geolocated floorplan and in geomap)
        // we need to keep track if the ip has already been indexed
        const indexedIp: Record<Id, Id> = {};

        return Promise.all(
            Object.values(project.floorPlans)
                .filter(isDefined)
                .map(async (floorPlan): Promise<IExportedMap> => {
                    return {
                        id: floorPlan._id,
                        name: floorPlan.name,
                        type: floorPlan.mapType,
                        images:
                            floorPlan.mapType === 'StreetMap'
                                ? await this.mapStreetMapImages(
                                      embedImages,
                                      projectId,
                                      project.floorPlans,
                                  )
                                : [
                                      await this.mapFloorPlanImage(
                                          floorPlan.image,
                                          embedImages,
                                          projectId,
                                      ),
                                  ].filter(isDefined),
                        blockers: this.mapBlockersForFloorPlanId(
                            this.getBlockersForFloorPlanId(project.blockers, floorPlan),
                            floorPlan,
                        ),
                        installationPoints: (
                            await this.mapInstallationPoints(
                                this.getInstallationPointsForFloorPlanId(
                                    project.installationPoints,
                                    floorPlan,
                                ),
                                itemsWithSplitIds,
                                indexedIp,
                                floorPlan,
                                project.itemRelations,
                                project.items,
                            )
                        ).filter(isDefined),
                        revision: floorPlan._rev,
                    };
                }),
        );
    }

    private getInstallationPointsForFloorPlanId = (
        installationPoints: ICurrentProjectRepository['installationPoints'],
        floorPlan: IPersistence<IFloorPlanEntity>,
    ) => {
        const installationPointEntities = Object.values(installationPoints).filter(isDefined);
        if (installationPointEntities.length === 0) return [];
        if (floorPlan.mapType === 'StreetMap') {
            const mappedInstallationPointsToGeoMaps = mapInstallationPointsToGeoMap(
                installationPointEntities,
                [floorPlan],
            );
            return mappedInstallationPointsToGeoMaps[floorPlan._id] ?? [];
        } else {
            const mappedInstallationPoints = mapInstallationPointsToFloorPlan(
                installationPointEntities,
                [floorPlan],
            );

            return mappedInstallationPoints[floorPlan._id] ?? [];
        }
    };

    private getBlockersForFloorPlanId = (
        blockers: ICurrentProjectRepository['blockers'],
        floorPlan: IPersistence<IFloorPlanEntity>,
    ): IBlockerEntity[] => {
        const blockerEntities = Object.values(blockers).filter(isDefined);
        if (blockerEntities.length === 0) return [];
        if (floorPlan.mapType === 'StreetMap') {
            const mappedBlockersToGeoMaps = mapBlockersToGeoMap(blockerEntities, [floorPlan]);
            return mappedBlockersToGeoMaps[floorPlan._id] ?? [];
        } else {
            const mappedBlockers = mapBlockersToFloorPlan(blockerEntities, [floorPlan]);
            return mappedBlockers[floorPlan._id] ?? [];
        }
    };

    // Map all blockers to share export format (color and coordinates) for the provided floorPlanId
    // (https://confluence.se.axis.com/pages/viewpage.action?spaceKey=WEBTOOLS&title=Project+Settings+Share+API)
    private mapBlockersForFloorPlanId = (
        blockers: Array<IPersistence<IBlockerEntity>>,
        floorPlan: IPersistence<IFloorPlanEntity>,
    ): IMapBlocker[] => {
        return Object.values(blockers)
            .filter(isDefined)
            .map((blocker): IMapBlocker | undefined => {
                // blockers should be transformed (derotated) if they are placed on a floorplan and the floorplan is geolocated
                const transform = createDerotationTransform(floorPlan);
                const transBlocker = transformBlockerEntity(transform, blocker);
                return {
                    color: ColorsEnum.blue6,
                    polyline: transBlocker.latLngs as ILatLng[],
                };
            })
            .filter(isDefined);
    };

    private getDerotatedInstallationPoint = (
        floorPlan: IPersistence<IFloorPlanEntity>,
        installationPoint: IPersistence<IInstallationPointEntity>,
        projectRelations: Record<string, IPersistence<IItemRelationEntity> | undefined>,
        projectItems: Record<string, IPersistence<IItemEntity> | undefined>,
    ) => {
        const floorPlanGeoLocation = getFloorPlanGeoLocation(floorPlan);
        if (!floorPlanGeoLocation || !projectRelations) return installationPoint;
        const transform = createDerotationTransform(floorPlan);
        const angle = -floorPlanGeoLocation.angle ?? 0;
        if (!projectRelations) return installationPoint;
        if (!projectRelations) {
            return installationPoint;
        } else {
            const installationPontModel = mapInstallationEntityToModel(
                installationPoint,
                projectItems,
                Object.values(projectRelations).filter(isDefined),
            );
            if (!installationPontModel) return installationPoint;
            return transformInstallationPoint(transform, angle, installationPontModel);
        }
    };

    // add split-ids to the itemsWithSplitIds record
    private addSplitIds = (
        itemsWithSplitIds: Record<string, number>,
        parentItemId: Id,
        indexedInstallationPoints: Record<Id, Id>,
        installationPointId: Id,
    ) => {
        // If the item has a quantity more then 1 then it has been split up with new ids in the export
        // that we need to use instead.
        if (itemsWithSplitIds[parentItemId] === undefined) {
            // The id index starts with 1.
            itemsWithSplitIds[parentItemId] = 1;
        } else {
            // Else we increase the number of times this item has been used.
            itemsWithSplitIds[parentItemId] += 1;
        }
        // add installation point to record to keep track if same ip on another map (geomap)
        indexedInstallationPoints[installationPointId] = generateUniqueSplitId(
            parentItemId,
            itemsWithSplitIds[parentItemId],
        );
    };

    // return radar share export format
    private getRadars = (
        installationPoint: IPersistence<IInstallationPointEntity>,
        piaRadar: IPiaRadarDetector,
    ) => {
        return [
            ...(installationPoint.radar && piaRadar
                ? [
                      {
                          horizontalFieldOfDetection: parseInt(
                              piaRadar.properties.radarHorizontalFieldOfDetection,
                          ),
                          rotation: this.getAbsoluteRotation(
                              installationPoint.radar.target.horizontalAngle,
                          ),
                          targetDistance: installationPoint.radar.target.distance,
                      },
                  ]
                : []),
        ];
    };

    // return speaker share export format
    private getSpeakers = (
        installationPoint: IPersistence<IInstallationPointEntity>,
        piaSpeaker: IPiaSpeaker,
        speakerHorizontalCoverageOverride: 360 | undefined,
    ) => {
        return [
            ...(installationPoint.speaker && piaSpeaker
                ? [
                      {
                          horizontalSpeakerCoverage:
                              speakerHorizontalCoverageOverride ??
                              piaSpeaker.properties.horizontalSpeakerCoverage,
                          rotation: this.getAbsoluteRotation(
                              installationPoint.speaker.target.horizontalAngle,
                          ),
                          targetDistance: installationPoint.speaker.target.distance,
                      },
                  ]
                : []),
        ];
    };

    /**
     *
     * @param indexedInstallationPoints - record of already indexed installation points (used since ip can exist in multiple maps if floorplan geolocated)
     * @param installationPointId - id of current installation point
     * @param parentItemId - id of parent of installation point
     * @param itemsWithSplitIds - record of items with split ids (i.e items with quantity > 1)
     * @returns The generated parent id of the installation point used for export (since ACS does not handle quantity)
     */
    private getExportParentItemId = (
        indexedInstallationPoints: Record<Id, Id>,
        installationPointId: Id,
        parentItemId: Id,
        itemsWithSplitIds: Record<string, number>,
    ) => {
        return indexedInstallationPoints[installationPointId]
            ? indexedInstallationPoints[installationPointId]
            : itemsWithSplitIds[parentItemId] !== undefined
              ? generateUniqueSplitId(parentItemId, itemsWithSplitIds[parentItemId])
              : parentItemId;
    };

    // map all installation points on the floorPlan to export format for share
    // (https://confluence.se.axis.com/pages/viewpage.action?spaceKey=WEBTOOLS&title=Project+Settings+Share+API)
    private async mapInstallationPoints(
        installationPoints: Array<IPersistence<IInstallationPointEntity>>,
        itemsWithSplitIds: Record<string, number>,
        indexedInstallationPoints: Record<Id, Id>,
        floorPlan: IPersistence<IFloorPlanEntity>,
        projectRelations: Record<string, IPersistence<IItemRelationEntity> | undefined>,
        projectItems: Record<string, IPersistence<IItemEntity> | undefined>,
    ): Promise<Array<undefined | IMapInstallationPoint>> {
        const sortedInstallationPoints = installationPoints
            .sort(idComparator)
            .sort(creationDateReverseComparator);
        return Promise.all(
            sortedInstallationPoints.map(async (installationPoint) => {
                const parentItemId = getParentId(installationPoint);

                if (!parentItemId) {
                    return undefined;
                }

                const item = await this.itemService.getItem(parentItemId);
                if (!item.productId) {
                    return undefined;
                }
                const piaDevice = this.piaItemService.get(item.productId).first();
                if (!piaDevice) {
                    return undefined;
                }

                const piaCamera = piaDevice as IPiaCamera;
                const piaSpeaker = piaDevice as IPiaSpeaker;
                const piaRadar = piaDevice as IPiaRadarDetector;
                let installationPointToExport = installationPoint;
                // If the current floorPlan entity is of mapType 'FloorPlan' and is geolocated, installation points needs to be derotated
                if (
                    !installationPoint.floorPlanId &&
                    floorPlan.mapType === 'FloorPlan' &&
                    isGeoLocatedFloorPlan(floorPlan)
                ) {
                    installationPointToExport = this.getDerotatedInstallationPoint(
                        floorPlan,
                        installationPoint,
                        projectRelations,
                        projectItems,
                    );
                }
                if (
                    item.quantity > 1 &&
                    indexedInstallationPoints[installationPointToExport._id] === undefined
                ) {
                    this.addSplitIds(
                        itemsWithSplitIds,
                        parentItemId,
                        indexedInstallationPoints,
                        installationPointToExport._id,
                    );
                }

                const aspectRatio =
                    piaCamera.properties.maxVideoResolutionHorizontal /
                    piaCamera.properties.maxVideoResolutionVertical;

                const panoramaMode =
                    (item.properties.camera && item.properties.camera.filter.panoramaMode) || false;

                const horizontalFovOverride = panoramaMode === 'horizontal' ? 360 : undefined;

                const speakerPlacement =
                    item.properties.speaker && item.properties.speaker.filter.placement;

                const speakerHorizontalCoverageOverride =
                    speakerPlacement === 'ceiling' ? 360 : undefined;

                const generatedItemId = this.getExportParentItemId(
                    indexedInstallationPoints,
                    installationPointToExport._id,
                    parentItemId,
                    itemsWithSplitIds,
                );

                return {
                    height: installationPointToExport.height,
                    id: installationPointToExport._id,
                    revision: installationPointToExport._rev,
                    location: installationPointToExport.location,
                    color: item.color ? ColorsEnum[item.color] : ColorsEnum.blue,
                    itemId: generatedItemId,
                    imageSensors: installationPointToExport.sensors.map(
                        (sensor): IImageSensor => ({
                            index: sensor.sensorId,
                            targetDistance: sensor.target.distance,
                            targetHeight: sensor.target.height,
                            rotation: this.getAbsoluteRotation(sensor.target.horizontalAngle),
                            horizontalFov: horizontalFovOverride ?? sensor.settings.horizontalFov,
                            verticalFov: Math.round(
                                estimateVerticalFOV(sensor.settings.horizontalFov, aspectRatio),
                            ),
                            corridorFormat: sensor.settings.corridorFormat,
                        }),
                    ),
                    radars: this.getRadars(installationPointToExport, piaRadar),
                    speakers: this.getSpeakers(
                        installationPointToExport,
                        piaSpeaker,
                        speakerHorizontalCoverageOverride,
                    ),
                };
            }),
        );
    }

    private async mapFloorPlanImage(
        floorPlanImage: IFloorPlanImage | undefined,
        embedImages: boolean,
        projectId: Id,
    ): Promise<IMapImage | undefined> {
        if (!floorPlanImage?.bounds) {
            return undefined;
        }
        let bounds: IBounds = floorPlanImage.bounds;

        // If the floorPlanImage is geolocated, we need to calculate the topLeft and bottomRight corners
        if (floorPlanImage.geoLocation !== undefined) {
            //geolocated image - get new topLeft and bottomRight
            const offsetPosition = offset(floorPlanImage.geoLocation.position);
            const bottomRight = offsetPosition([
                -floorPlanImage.geoLocation.width / 2,
                floorPlanImage.geoLocation.height / 2,
            ]);
            const topLeft = offsetPosition([
                floorPlanImage.geoLocation.width / 2,
                -floorPlanImage.geoLocation.height / 2,
            ]);
            bounds = {
                bottomRight,
                topLeft,
            };
        }
        const floorPlanProperties = {
            height: floorPlanImage.dimensions.height,
            imageBounds: bounds,
            width: floorPlanImage.dimensions.width,
            key: floorPlanImage.key,
            geoLocation: floorPlanImage.geoLocation,
        };

        try {
            const image = embedImages
                ? await this.imageService.getImageAsBase64(floorPlanImage.key, projectId)
                : undefined;
            const imageUrl = !embedImages
                ? await this.imageService.getPresignedUrl(floorPlanImage.key)
                : undefined;

            return {
                ...floorPlanProperties,
                image,
                imageUrl,
            };
        } catch {
            return {
                ...floorPlanProperties,
            };
        }
    }

    private async mapStreetMapImages(
        embedImages: boolean,
        projectId: Id,
        projectFloorPlans: Record<string, IPersistence<IFloorPlanEntity> | undefined>,
    ): Promise<IGeoMapImage[]> {
        // find all geolocated floorplans and return export format for streetMap images
        const floorPlanImages = await Promise.all(
            Object.values(projectFloorPlans)
                .filter(isGeoLocatedFloorPlan)
                .map(async (floorPlan) => {
                    const floorPlanProperties = {
                        key: floorPlan.image.key,
                        geoLocation: floorPlan.image.geoLocation,
                    };

                    try {
                        const image = embedImages
                            ? await this.imageService.getImageAsBase64(
                                  floorPlan.image.key,
                                  projectId,
                              )
                            : undefined;
                        const imageUrl = !embedImages
                            ? await this.imageService.getPresignedUrl(floorPlan.image.key)
                            : undefined;

                        return {
                            ...floorPlanProperties,
                            image,
                            imageUrl,
                        };
                    } catch {
                        return {
                            ...floorPlanProperties,
                        };
                    }
                }),
        );
        return floorPlanImages;
    }

    private getAbsoluteRotation(rotation: number): number {
        if (rotation < 0) {
            return 360 + rotation;
        }
        return rotation;
    }
}
