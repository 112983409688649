import * as React from 'react';
import { t } from 'app/translate';
import type { IRecordingSettingsModel, VideoEncoding } from 'app/core/persistence';
import { Resolution } from 'app/core/persistence';
import type { IOptionProps, IAutoTestable } from 'app/components';
import { NumberInput, Select, Stack, Box, Text, Grid } from 'app/components';

import { constants } from '../../constants';
import { merge } from 'lodash-es';
import type { IScheduleRecordingSupport } from '../../models';
import { PropertyOverrideText, NotSupportedText } from '../common';
import { ServiceLocator } from 'app/ioc';
import { ProfileOverrideService } from '../../services';
import { CompressionSelector } from './CompressionSelector.component';
import { VideoEncodingSelector } from './VideoEncodingSelector.component';
import { getCurrentProjectProjectZipSetting } from 'app/modules/common/project/selectors';
import type { IStoreState } from 'app/store/IStoreState';
import { connect } from 'react-redux';

export interface IRecordingSettingsOwnProps extends IAutoTestable {
    recordingSettings: IRecordingSettingsModel;
    useProjectSetting?: boolean;
    scheduleRecordingSupport?: IScheduleRecordingSupport;
    recordingSettingsOverride?: Partial<IRecordingSettingsModel>;
    deactivate?: boolean;
    onRecordingSettingsChange(settings: Partial<IRecordingSettingsModel>): void;
}

export interface IRecordingSettingsProps extends IAutoTestable {
    recordingSettings: IRecordingSettingsModel;
    useProjectSetting?: boolean;
    scheduleRecordingSupport?: IScheduleRecordingSupport;
    recordingSettingsOverride?: Partial<IRecordingSettingsModel>;
    deactivate?: boolean;
    onRecordingSettingsChange(settings: Partial<IRecordingSettingsModel>): void;
    globalZipstreamSetting: number;
}

interface IRecordingSettingsState {
    mergedRecordingSettings: IRecordingSettingsModel;
    model: Partial<IRecordingSettingsModel>;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IRecordingSettingsOwnProps,
): IRecordingSettingsProps => {
    const projectZipSetting = getCurrentProjectProjectZipSetting(storeState);

    return {
        recordingSettings: ownProps.recordingSettings,
        useProjectSetting: ownProps.useProjectSetting,
        scheduleRecordingSupport: ownProps.scheduleRecordingSupport,
        recordingSettingsOverride: ownProps.recordingSettingsOverride,
        deactivate: ownProps.deactivate,
        onRecordingSettingsChange: ownProps.onRecordingSettingsChange,
        testId: ownProps.testId,
        globalZipstreamSetting: projectZipSetting,
    };
};

export class RecordingSettingsContainer extends React.Component<
    IRecordingSettingsProps,
    IRecordingSettingsState
> {
    private profileOverrideService: ProfileOverrideService;
    constructor(props: IRecordingSettingsProps) {
        super(props);
        this.state = {
            mergedRecordingSettings: this.getMergedSettings(),
            model: this.props.recordingSettingsOverride || this.props.recordingSettings,
        };
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public componentDidUpdate(oldProps: IRecordingSettingsProps) {
        if (
            this.props.recordingSettings !== oldProps.recordingSettings ||
            this.props.recordingSettingsOverride !== oldProps.recordingSettingsOverride
        ) {
            this.setState({
                mergedRecordingSettings: this.getMergedSettings(),
                model: this.props.recordingSettingsOverride || this.props.recordingSettings,
            });
        }
    }
    public render() {
        const { mergedRecordingSettings: settings } = this.state;
        const { recordingSettingsOverride: override, scheduleRecordingSupport } = this.props;
        /**
         * Adds support for custom resolutions that we don't have in the list.
         * Useful for old Site Designer 1 cameras with weird resolutions.
         */
        const currentResolution = settings.resolution.toString();
        const resolutionOptions = this.selectResolutionOptions();
        const cameraHasCustomResolution = !resolutionOptions.some(
            (resolution) => resolution.value === currentResolution,
        );
        if (cameraHasCustomResolution) {
            resolutionOptions.push({
                text: currentResolution,
                value: currentResolution,
            });
        }

        return (
            <Stack>
                <Box width={constants.leftColumnWidth} />
                <Box
                    testId={`${this.props.testId}_settings_container`}
                    width={constants.rightColumnWidth}
                >
                    <Grid>
                        <div>
                            <Stack spacing="halfQuart" vertical>
                                <div>
                                    <PropertyOverrideText
                                        text={t.profilesRecordingSettingsFrameRate}
                                        propertyValue={override && override.frameRate !== undefined}
                                    />
                                    <Stack spacing="quart">
                                        <NumberInput
                                            testId={`${this.props.testId}_frame_rate_npt`}
                                            value={settings.frameRate}
                                            min={0}
                                            onChange={this.onFrameRateChange}
                                            disabled={this.props.deactivate}
                                            changeCriteria="debounced"
                                        />
                                        <Text>{t.fps}</Text>
                                    </Stack>
                                </div>
                                <NotSupportedText
                                    testId={`${this.props.testId}_frame_rate`}
                                    supportedWithMessage={
                                        scheduleRecordingSupport &&
                                        scheduleRecordingSupport.frameRate
                                    }
                                />
                            </Stack>
                        </div>
                        <div>
                            <Stack spacing="halfQuart" vertical>
                                <div>
                                    <PropertyOverrideText
                                        text={t.profilesRecordingSettingsResolution}
                                        propertyValue={
                                            override && override.resolution !== undefined
                                        }
                                    />
                                    <Select
                                        testIdChild={`${this.props.testId}_resolution_selected`}
                                        value={currentResolution}
                                        options={resolutionOptions}
                                        onChange={this.onResolutionChange}
                                        disabled={this.props.deactivate}
                                    />
                                </div>
                                <NotSupportedText
                                    testId={`${this.props.testId}_resolution`}
                                    supportedWithMessage={
                                        scheduleRecordingSupport &&
                                        scheduleRecordingSupport.resolution
                                    }
                                />
                            </Stack>
                        </div>
                        <div>
                            <Stack spacing="halfQuart" vertical>
                                <div>
                                    <PropertyOverrideText
                                        text={t.profilesRecordingSettingsVideoEncoding}
                                        propertyValue={override && override.videoEncoding}
                                    />
                                    <VideoEncodingSelector
                                        testId={this.props.testId}
                                        videoEncoding={settings.videoEncoding}
                                        onVideoEncodingChange={this.onVideoEncodingChange}
                                        disabled={this.props.deactivate}
                                    />
                                </div>
                                <NotSupportedText
                                    testId={`${this.props.testId}_video_encoding`}
                                    supportedWithMessage={
                                        scheduleRecordingSupport &&
                                        scheduleRecordingSupport.videoEncoding
                                    }
                                />
                            </Stack>
                        </div>
                        <div>
                            <Stack spacing="halfQuart" vertical>
                                <div>
                                    <PropertyOverrideText
                                        text={t.profilesRecordingSettingsCompression}
                                        propertyValue={override && override.compression}
                                    />
                                    <CompressionSelector
                                        testId={this.props.testId}
                                        compression={settings.compression}
                                        onCompressionChange={this.onCompressionChange}
                                        disabled={this.props.deactivate}
                                    />
                                </div>
                            </Stack>
                        </div>
                    </Grid>
                </Box>
            </Stack>
        );
    }

    private getMergedSettings(): IRecordingSettingsModel {
        return merge({}, this.props.recordingSettings, this.props.recordingSettingsOverride);
    }

    private selectResolutionOptions = (): IOptionProps[] => {
        return [
            {
                text: t.profilesRecordingSettingsCameraMaxResolution,
                value: `${Number.MAX_SAFE_INTEGER}x${Number.MAX_SAFE_INTEGER}`,
            },
            {
                text: '4320p (8K)',
                value: '7680x4320',
            },
            {
                text: '11MP',
                value: '3840x2880',
            },
            {
                text: '2160p (4K)',
                value: '3840x2160',
            },
            {
                text: '5MP',
                value: '2560x1920',
            },
            {
                text: '4MP',
                value: '2688x1512',
            },
            {
                text: '3MP',
                value: '2048x1536',
            },
            {
                text: '1080p (2MP)',
                value: '1920x1080',
            },
            {
                text: '1.2 MP',
                value: '1280x960',
            },
            {
                text: '720p (1MP)',
                value: '1280x720',
            },
            {
                text: 'VGA',
                value: '640x480',
            },
        ];
    };

    private onFrameRateChange = (fps: number) => {
        const fpsValue = this.profileOverrideService.getValueToSet<number>(
            this.props.recordingSettings.frameRate,
            fps,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({
            ...this.state.model,
            frameRate: fpsValue,
        });
    };

    private onResolutionChange = (newResolution: string) => {
        const defaultHorizontal = this.props.recordingSettings.resolution.getHorizontal();
        const defaultVertical = this.props.recordingSettings.resolution.getVertical();
        const resolutionValue = this.profileOverrideService.getValueToSet<string>(
            defaultHorizontal + 'x' + defaultVertical,
            newResolution,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({
            ...this.state.model,
            resolution:
                resolutionValue === undefined ? resolutionValue : new Resolution(resolutionValue),
        });
    };

    private onCompressionChange = (value: number) => {
        const valueToSet = this.profileOverrideService.getValueToSet<number>(
            this.props.recordingSettings.compression,
            value,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({
            ...this.state.model,
            compression: valueToSet,
        });
    };

    private onVideoEncodingChange = (value: VideoEncoding) => {
        const valueToSet = this.profileOverrideService.getValueToSet<VideoEncoding>(
            this.props.recordingSettings.videoEncoding,
            value,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({
            ...this.state.model,
            videoEncoding: valueToSet,
        });
    };
}

export const RecordingSettings = connect(mapStateToProps)(RecordingSettingsContainer);
