import type { Container, interfaces } from 'inversify';

export class ContainerBinder {
    private servicesToCreateOnStartup: interfaces.Newable<any>[] = [];
    constructor(private container: Container) {}

    /**
     * Binds a service to the Container. All services will always be injected as
     * singletons, which means that the service will be created once and then re-used.
     *
     * @param serviceType reference to the Service class that is used to identify the
     *  service when injecting it.
     * @param createOnStartup only set this to true if your service needs to be created
     * before the application is started.
     */
    public bindService<TService>(
        serviceType: interfaces.Newable<TService>,
        createOnStartup: boolean = false,
    ) {
        this.bindSingleton<TService>(serviceType);
        if (createOnStartup) {
            this.servicesToCreateOnStartup.push(serviceType);
        }
    }

    /**
     * Binds a constant value or instance to the container. This is useful when there
     * is a need to create e.g config objects that require information during application
     * bootstrap that needs to be shared with other parts of the application.
     *
     * A constant and singleton behave in a very similar fashion, the difference is that
     * a constant is created by the user and then registered using that created instance.
     *
     * @param constantType reference to the constant's type, used to identify the
     *  constant when injecting it.
     * @param instance the instance/value that will be used when injecting the constant.
     */
    public bindConstant<TConstant>(
        constantType: symbol | interfaces.Newable<TConstant> | interfaces.Abstract<TConstant>,
        instance: TConstant,
    ) {
        this.container.bind<TConstant>(constantType).toConstantValue(instance);
    }

    /**
     * Binds a factory method that will be used when creating an instance of the
     * dynamic type when created during injection. The created instance can be set
     * to be either singleton or transient.
     *
     * @param dynamicType reference to the dynamic's type, used to identify the
     *  dynamic when injecting it.
     * @param factory function responsible for creating and returning an instance
     *  of the dynamic when created.
     * @param singleton wether the dynamic's instance should be a singleton or not.
     *  Defaults to true.
     */
    public bindDynamic<TDynamic>(
        dynamicType: interfaces.Newable<TDynamic>,
        factory: (container: interfaces.Container) => TDynamic,
        singleton: boolean = true,
    ) {
        const dynamicBind = this.container
            .bind<TDynamic>(dynamicType)
            .toDynamicValue((context: interfaces.Context) => {
                return factory(context.container);
            });

        if (singleton) {
            dynamicBind.inSingletonScope();
        } else {
            dynamicBind.inTransientScope();
        }
    }

    /**
     * Binds a singleton type to the Container. All singletons will always be created only
     * once, and then the same instance will be shared across all injections.
     *
     * This is the normal case when running things in the browser since we want to limit
     * the amount of instances we have of things. The difference between a singleton
     * and a constant is that the singleton is created by the container when it is required
     * during injection.
     *
     * @param singletonType reference to the singleton class that is used to identify the
     *  singleton when injecting it.
     */
    public bindSingleton<TSingleton>(singletonType: interfaces.Newable<TSingleton>) {
        this.container.bind<TSingleton>(singletonType).to(singletonType).inSingletonScope();
    }

    /**
     * Binds a singleton type to the Container. All singletons will always be created only
     * once, and then the same instance will be shared across all injections.
     *
     * This is the normal case when running things in the browser since we want to limit
     * the amount of instances we have of things. The difference between a singleton
     * and a constant is that the singleton is created by the container when it is required
     * during injection.
     *
     * @param asType reference to the class that will be used when injecting the singleton
     * @param singletonType reference to the singleton class that is used to identify the
     *  singleton when injecting it.
     */
    public bindSingletonAs<TAs, TSingleton extends TAs>(
        asType: interfaces.Abstract<TAs> | interfaces.Newable<TAs>,
        singletonType: interfaces.Newable<TSingleton>,
    ) {
        this.container.bind<TAs>(asType).to(singletonType).inSingletonScope();
    }

    /**
     * Makes sure that services that are crucial for the system to work are created when the application
     * is started.
     */
    public initializeServicesOnStartup() {
        this.servicesToCreateOnStartup.forEach((service) => this.container.get(service));
    }
}
