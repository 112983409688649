import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import type { IPaperSize } from './../models/IPaperSize';
import { getCurrentProjectUnitSystem } from 'app/modules/common';

const metricPaperSizes: Record<string, IPaperSize> = {
    A4: {
        name: 'A4',
        width: 210,
        height: 297,
        unit: 'mm',
    },
    A3: {
        name: 'A3',
        width: 297,
        height: 420,
        unit: 'mm',
    },
    A2: {
        name: 'A2',
        width: 420,
        height: 594,
        unit: 'mm',
    },
    A1: {
        name: 'A1',
        width: 594,
        height: 841,
        unit: 'mm',
    },
    A0: {
        name: 'A0',
        width: 841,
        height: 1189,
        unit: 'mm',
    },
    Letter: {
        name: 'Letter',
        width: 216,
        height: 279,
        unit: 'mm',
    },
    Legal: {
        name: 'Legal',
        width: 216,
        height: 356,
        unit: 'mm',
    },
    Ledger: {
        name: 'Ledger',
        width: 279,
        height: 432,
        unit: 'mm',
    },
};

const imperialPaperSizes: Record<string, IPaperSize> = {
    Letter: {
        name: 'Letter',
        width: 8.5,
        height: 11.0,
        unit: 'in',
    },
    Legal: {
        name: 'Legal',
        width: 8.5,
        height: 14.0,
        unit: 'in',
    },
    Ledger: {
        name: 'Ledger',
        width: 11,
        height: 17,
        unit: 'in',
    },
    A4: {
        name: 'A4',
        width: 8.3,
        height: 11.7,
        unit: 'in',
    },
    A3: {
        name: 'A3',
        width: 11.7,
        height: 16.5,
        unit: 'in',
    },
    A2: {
        name: 'A2',
        width: 16.5,
        height: 23.4,
        unit: 'in',
    },
    A1: {
        name: 'A1',
        width: 23.4,
        height: 33.1,
        unit: 'in',
    },
    A0: {
        name: 'A0',
        width: 33.1,
        height: 46.8,
        unit: 'in',
    },
};

export const getPaperSizeTemplates = createSelector([getCurrentProjectUnitSystem], (unitSystem) => {
    return unitSystem === 'metric' ? metricPaperSizes : imperialPaperSizes;
});

const PAPER_MARGIN = { metric: 5, imperial: 0.2 };
export const getDefaultMargin = createSelector([getCurrentProjectUnitSystem], (unitSystem) => {
    return `${PAPER_MARGIN[unitSystem]}${unitSystem === 'metric' ? 'mm' : 'in'}`;
});

export const getSizeUnit = createSelector([getCurrentProjectUnitSystem], (unitSystem) => {
    return `${unitSystem === 'metric' ? 'mm' : 'in'}`;
});

const getSelectedPaperSize = (store: IStoreState) => store.mapsReport.paperSize;
export const getOrientation = (store: IStoreState) => store.mapsReport.orientation ?? 'landscape';
export const getCurrentPaperSize = createSelector(
    [getCurrentProjectUnitSystem, getSelectedPaperSize, getOrientation],
    (unitSystem, selectedPaper, orientation) => {
        const currentPaper =
            selectedPaper ??
            (unitSystem === 'metric' ? metricPaperSizes.A4 : imperialPaperSizes.Letter);

        return orientation === 'portrait' || currentPaper.name === 'Custom'
            ? currentPaper
            : {
                  ...currentPaper,
                  width: currentPaper.height,
                  height: currentPaper.width,
              };
    },
);

export const getPaperMargin = createSelector([getCurrentProjectUnitSystem], (unitSystem) => {
    const margin = unitSystem === 'metric' ? PAPER_MARGIN.metric : PAPER_MARGIN.imperial;
    const extraMargin = unitSystem === 'metric' ? 1 : 0.04;
    return margin * 2 + extraMargin;
});
