import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../models';
import { extendableProps, renderReactChildren } from '../services';

export interface IReadDirection extends IExtendableComponentWithChildren {
    /**
     * Sets direction attribute on child element.
     */
    direction?: 'rtl' | 'ltr';
}

/**
 * Used to set page direction attribute on children.
 */
export class ReadDirection extends React.Component<IReadDirection> {
    public render() {
        const { children, direction, ...extendedProps } = this.props;

        const attributes = direction
            ? extendableProps(extendedProps, {
                  dir: direction,
              })
            : undefined;

        return renderReactChildren(
            children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes?.__htmlAttributes),
        );
    }
}
