import * as React from 'react';
import { css } from '@emotion/css';
import { debounce } from 'lodash-es';
import { ColorsEnum } from 'app/styles';
import { Icon } from '../icon';
import type { IWithChildren } from 'app/components/models';

interface IColorPickerProps extends IWithChildren {
    /** The selected color of the color picker */
    color: React.CSSProperties['color'];
    /** The width of the trigger element, defaults to 60px */
    width?: number;
    /** The height of the trigger element, defaults to 60px */
    height?: number;
    /** The border radius of the trigger element, defaults to 10px */
    borderRadius?: number;
    /** Change is called when selected color has changed, debounced with 200ms */
    onColorChange?(newColor: React.CSSProperties['color']): void;
}

const colorInputStyle = css`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
`;

const selectColorIconStyle = css`
    position: absolute;
    z-index: 0;
`;

export const ColorPicker: React.FC<IColorPickerProps> = ({
    color,
    width = 60,
    height = 60,
    borderRadius = 10,
    onColorChange,
    children,
}) => {
    // Using internal color to be able to debounce changes
    const [internalColor, updateInternalColor] = React.useState(color ?? '');
    React.useEffect(() => updateInternalColor(color ?? ''), [color]);

    const inputWrapperStyle = css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${width}px;
        height: ${height}px;
        border-radius: ${borderRadius}px;
        border: 1px solid ${children ? 'transparent' : ColorsEnum.blackOpacity};
        background: ${children ? 'transparent' : internalColor};
    `;

    const updateColor = React.useMemo(
        () =>
            debounce(
                (newColor: React.CSSProperties['color']) =>
                    onColorChange && onColorChange(newColor),
                200,
            ),
        [onColorChange],
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateInternalColor(event.target.value);
        updateColor(event.target.value);
    };

    return (
        <div className={inputWrapperStyle}>
            {internalColor === '' && (
                <div className={selectColorIconStyle}>
                    <Icon icon="color_palette" color="grey4" size="lg" />
                </div>
            )}
            {children}
            <input
                type="color"
                className={colorInputStyle}
                value={internalColor}
                onChange={onChange}
            />
        </div>
    );
};

ColorPicker.displayName = 'ColorPicker';
