import type { IItemEntity } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';
import { trigonometry } from 'axis-webtools-util';
import { clamp } from 'lodash-es';
import type { IFOVLimits } from '../piaDevices';

interface IPixelDensityCalculationData {
    distance: number;
    horizontalFovRadians: number;
    parentDevice?: IItemEntity;
    piaCamera?: IPiaCamera | IPiaSensorUnit | null;
    lensFovLimits?: IFOVLimits;
}

export function calculatePixelDensity(options: IPixelDensityCalculationData) {
    const pixelDensity = options.piaCamera
        ? getPixelsPerMeterForCameraItem(
              options.distance,
              options.horizontalFovRadians,
              options.piaCamera,
              options.lensFovLimits,
          )
        : getPixelsPerMeterForCustomCamera(
              options.distance,
              options.horizontalFovRadians,
              options.parentDevice,
          );

    return Math.round(pixelDensity);
}

function getPixelsPerMeterForCustomCamera(
    distance: number,
    horizontalFovRadians: number,
    parentDevice?: IItemEntity,
): number {
    const customCamera = parentDevice?.properties.camera?.customCameraProperties;
    if (!customCamera || (parentDevice && !isCustomCamera(parentDevice))) {
        // Default fallback value
        return 1000;
    }
    const selectedMaxHorizontalFovRad = trigonometry.toRadians(customCamera.horizontalFovMax);
    const selectedMinHorizontalFovRad = trigonometry.toRadians(customCamera.horizontalFovMin);

    const clampedHorizontalFOVRadians = clamp(
        horizontalFovRadians,
        selectedMinHorizontalFovRad,
        selectedMaxHorizontalFovRad,
    );

    return calculatePixelsPerMeter(
        clampedHorizontalFOVRadians,
        customCamera.resolutionHorizontal,
        distance,
    );
}

function getPixelsPerMeterForCameraItem(
    distance: number,
    horizontalFovRadians: number,
    piaCamera: IPiaCamera | IPiaSensorUnit,
    lensFOVLimits?: IFOVLimits,
): number {
    const shouldUseLensCalcFov =
        piaCamera.properties.panCameraType === 'Multisensor' ||
        piaCamera.properties.panCameraType === 'Multidirectional';

    const maxHorizontalFovRadians = trigonometry.toRadians(piaCamera.properties.maxVerticalFOV);
    const minHorizontalFovRadians =
        shouldUseLensCalcFov && piaCamera.properties.minLensCalcFOV !== undefined
            ? trigonometry.toRadians(piaCamera.properties.minLensCalcFOV)
            : trigonometry.toRadians(piaCamera.properties.minHorizontalFOV);
    const maxVideoResolutionHorizontal = piaCamera.properties.maxVideoResolutionHorizontal;
    const nbrOfChannels = piaCamera.properties.channels;
    const panCameraType = piaCamera.properties.panCameraType;

    const lensMinHorizontalFovRad = lensFOVLimits?.horizontal.min
        ? trigonometry.toRadians(lensFOVLimits.horizontal.min)
        : undefined;
    const lensMaxHorizontalFovRad = lensFOVLimits?.horizontal.max
        ? trigonometry.toRadians(lensFOVLimits.horizontal.max)
        : undefined;

    const clampedHorizontalFovRadians = clamp(
        horizontalFovRadians,
        lensMinHorizontalFovRad ?? minHorizontalFovRadians,
        lensMaxHorizontalFovRad ?? maxHorizontalFovRadians,
    );

    // multiply multi-sensor camera with numOfChannels
    const resolutionFactor = panCameraType === 'Multisensor' ? nbrOfChannels ?? 1 : 1;

    const videoResolutionHorizontal = maxVideoResolutionHorizontal * resolutionFactor;

    return calculatePixelsPerMeter(
        clampedHorizontalFovRadians,
        videoResolutionHorizontal,
        distance,
    );
}

function calculatePixelsPerMeter(
    horizontalFovRadians: number,
    videoResolutionHorizontal: number,
    distance: number,
): number {
    const fovWidth = distance * horizontalFovRadians;
    const pixelsPerMeter = videoResolutionHorizontal / fovWidth;

    return pixelsPerMeter;
}
