import type { UseCaseType, UseCaseSubType } from './PartnerUseCaseTypes';

type ManageAccessType =
    | 'Access control via biometrics'
    | 'Multi-site'
    | 'QR code'
    | 'Single site'
    | 'Tailgating detection'
    | 'Time and attendance'
    | 'Vehicle access control via LPR';

type ManageAudioType =
    | 'Audio content'
    | 'Music content'
    | 'Public address'
    | 'Speakers'
    | 'Zoned groups';

type OptimizeBusinessAndServicesType =
    | 'Dwell time'
    | 'Empty shelf detection'
    | 'Heat mapping'
    | 'Motion pattern'
    | 'POS exception detection'
    | 'Queue detection'
    | 'Queue management'
    | 'Random selection';

type CountingType = 'Object counting' | 'Occupancy' | 'People counting' | 'Vehicle counting';

type DetectIncidentsType =
    | 'Crowd detection'
    | 'Intrusion detection'
    | 'Loitering detection'
    | 'Motion detection'
    | 'Perimeter protection'
    | 'Sabotage detection'
    | 'Slip and fall detection'
    | 'Tampering detection';

type DetectObjectType =
    | 'Object color'
    | 'Object direction'
    | 'Object loss'
    | 'Object movements'
    | 'Object tracking'
    | 'Object type'
    | 'Unattended object';

type DetectPeopleType =
    | 'Age detection'
    | 'Contact tracing'
    | 'Distraction detection'
    | 'Face detection'
    | 'Face mask detection'
    | 'Facial recognition'
    | 'Fatigue detection'
    | 'Gender detection'
    | 'Hard hat detection'
    | 'Mood detection'
    | 'Privacy masking'
    | 'Social distancing/proximity detection';

type DetectSoundType =
    | 'Aggression detection'
    | 'Audio direction detection'
    | 'Car alarm detection'
    | 'Explosion detection'
    | 'Glass break detection'
    | 'Gunshot detection';

type DetectVehicleType =
    | 'Illegal parking'
    | 'License plate recognition'
    | 'Slow vehicle detection'
    | 'Speed detection'
    | 'Stopped vehicle detection'
    | 'Vehicle brand'
    | 'Vehicle color'
    | 'Vehicle detection'
    | 'Vehicle type';

type ManageTrafficType =
    | 'Intersection block detection'
    | 'Mobility control'
    | 'Parking management'
    | 'Platform incident detection'
    | 'Track incident detection'
    | 'Traffic congestion detection'
    | 'Traffic data'
    | 'Traffic speed drop detection'
    | 'Tunnel incident detection'
    | 'Vehicle queue detection'
    | 'Wrong way detection';

type MonitorEnvironmentType =
    | 'Chemical detection'
    | 'Fire detection'
    | 'Flood detection'
    | 'Fog detection'
    | 'Rainfall intensity detection'
    | 'Smoke detection'
    | 'Snowfall intensity detection'
    | 'Temperature detection'
    | 'Water level detection'
    | 'Weather data';

type RemoteMonitoringType =
    | 'Broadcasting'
    | 'Conference solution'
    | 'Remote patient care'
    | 'Remote training'
    | 'Timelapse';

const remoteMonitoringCategories: RemoteMonitoringType[] = [
    'Broadcasting',
    'Conference solution',
    'Remote patient care',
    'Remote training',
    'Timelapse',
];

export const useCaseCategories: UseCaseType[] = [
    'manageAccess',
    'manageAudio',
    'optimizeBusinessAndServices',
    'counting',
    'detectIncidents',
    'detectObject',
    'detectPeople',
    'detectSound',
    'detectVehicles',
    'manageTraffic',
    'monitorEnvironment',
    'remoteMonitoring',
];

const manageAccessCategories: ManageAccessType[] = [
    'Access control via biometrics',
    'Multi-site',
    'QR code',
    'Single site',
    'Tailgating detection',
    'Time and attendance',
    'Vehicle access control via LPR',
];

const manageAudioCategories: ManageAudioType[] = [
    'Audio content',
    'Music content',
    'Public address',
    'Speakers',
    'Zoned groups',
];

const optimizeBusinessAndServicesCategories: OptimizeBusinessAndServicesType[] = [
    'Dwell time',
    'Empty shelf detection',
    'Heat mapping',
    'Motion pattern',
    'POS exception detection',
    'Queue detection',
    'Queue management',
    'Random selection',
];

const countingCategories: CountingType[] = [
    'Object counting',
    'Occupancy',
    'People counting',
    'Vehicle counting',
];

const detectIncidentsCategories: DetectIncidentsType[] = [
    'Crowd detection',
    'Intrusion detection',
    'Loitering detection',
    'Motion detection',
    'Perimeter protection',
    'Sabotage detection',
    'Slip and fall detection',
    'Tampering detection',
];

const detectObjectCategories: DetectObjectType[] = [
    'Object color',
    'Object direction',
    'Object loss',
    'Object movements',
    'Object tracking',
    'Object type',
    'Unattended object',
];

const detectPeopleCategories: DetectPeopleType[] = [
    'Age detection',
    'Contact tracing',
    'Distraction detection',
    'Face detection',
    'Face mask detection',
    'Facial recognition',
    'Fatigue detection',
    'Gender detection',
    'Hard hat detection',
    'Mood detection',
    'Privacy masking',
    'Social distancing/proximity detection',
];

const detectSoundCategories: DetectSoundType[] = [
    'Aggression detection',
    'Audio direction detection',
    'Car alarm detection',
    'Explosion detection',
    'Glass break detection',
    'Gunshot detection',
];

const detectVehicleCategories: DetectVehicleType[] = [
    'Illegal parking',
    'License plate recognition',
    'Slow vehicle detection',
    'Speed detection',
    'Stopped vehicle detection',
    'Vehicle brand',
    'Vehicle color',
    'Vehicle detection',
    'Vehicle type',
];

const manageTrafficCategories: ManageTrafficType[] = [
    'Intersection block detection',
    'Mobility control',
    'Parking management',
    'Platform incident detection',
    'Track incident detection',
    'Traffic congestion detection',
    'Traffic data',
    'Traffic speed drop detection',
    'Tunnel incident detection',
    'Vehicle queue detection',
    'Wrong way detection',
];

const monitorEnvironmentCategories: MonitorEnvironmentType[] = [
    'Chemical detection',
    'Fire detection',
    'Flood detection',
    'Fog detection',
    'Rainfall intensity detection',
    'Smoke detection',
    'Snowfall intensity detection',
    'Temperature detection',
    'Water level detection',
    'Weather data',
];

export const useCaseSubCategories: UseCaseSubType[] = [
    ...manageAccessCategories,
    ...manageAudioCategories,
    ...optimizeBusinessAndServicesCategories,
    ...countingCategories,
    ...detectIncidentsCategories,
    ...detectObjectCategories,
    ...detectPeopleCategories,
    ...detectSoundCategories,
    ...detectVehicleCategories,
    ...manageTrafficCategories,
    ...monitorEnvironmentCategories,
    ...remoteMonitoringCategories,
];
