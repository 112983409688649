import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    CommonActionService,
    getCurrentProjectLocked,
    getSelectedRecordingSolutionType,
} from 'app/modules/common';
import { Border, Box, Button, GridItem, NoPrint } from 'app/components';
import { cx } from '@emotion/css';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import {
    headerStyle,
    subHeaderStyle,
    dashedLeftBorder,
    noRightBorder,
} from './solutionTable.styles';
import { resetRecordingSolution } from './helpers';

export const SelectedSolutionTableHeader: React.FC = () => {
    const isLocked = useSelector(getCurrentProjectLocked);
    const selectedRecordingSolutionType = useSelector(getSelectedRecordingSolutionType);
    const commonActions = useService(CommonActionService);
    const showProductsSelectorPanel = () => {
        commonActions.setRecordingOverlay(undefined, undefined, true);
        eventTracking.logUserEvent('System Components', 'Click customize solution');
    };

    const resetSolution = () => {
        resetRecordingSolution(selectedRecordingSolutionType);
    };

    return (
        <>
            {/* CUSTOMIZE SOLUTION BUTTON */}
            <GridItem gridRow="1 / 3">
                <Border bottomWidth={1} color="grey3">
                    <Box paddingY="half">
                        <NoPrint>
                            <Box spacing="cell">
                                <Button
                                    primary
                                    testId={'customize_solution'}
                                    disabled={isLocked}
                                    onClick={showProductsSelectorPanel}
                                >
                                    {t.customizeSolution}
                                </Button>
                                <Button
                                    testId={'reset_solution'}
                                    disabled={isLocked}
                                    onClick={resetSolution}
                                >
                                    {t.reset}
                                </Button>
                            </Box>
                        </NoPrint>
                    </Box>
                </Border>
            </GridItem>

            {/* HEADER GROUPS */}
            <div className={headerStyle(2, 4)}>{t.recording}</div>
            <div className={headerStyle(6, 2)}>{t.network}</div>
            <div className={headerStyle(8, 1)}>{t.output}</div>

            {/* SUB HEADERS */}
            <div className={cx(subHeaderStyle, dashedLeftBorder)}>{t.channels}</div>
            <div className={subHeaderStyle}>{t.licenses}</div>
            <div className={subHeaderStyle}>{t.storage}</div>
            <div className={subHeaderStyle}>{t.bandwidth}</div>
            <div className={cx(subHeaderStyle, dashedLeftBorder)}>{t.poePower}</div>
            <div className={subHeaderStyle}>{t.ports}</div>
            <div className={cx(subHeaderStyle, dashedLeftBorder, noRightBorder)}>
                {t.videoPorts}
            </div>
        </>
    );
};
