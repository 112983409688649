import * as React from 'react';
import { css } from '@emotion/css';
import type { IExtendableComponentWithChildren } from '../models';
import { extendableProps, renderReactChildren } from '../services';

const disabledStyle = css`
    opacity: 0.4;
`;

export interface IOpacityProps extends IExtendableComponentWithChildren {
    /**
     * if alpha is submitted then the opacity is set accordingly
     * 0 is fully transparent
     * 1 is fully opaque
     */
    alpha?: React.CSSProperties['opacity'];
    /**
     * if disabled is true then the alpha value is overridden and the opacity is set to 0.4
     */
    disabled?: boolean;
    /**
     * Enter css transition value
     *  */
    animation?: React.CSSProperties['transition'];
}

/**
 * Applies custom opacity or standardised "disabled" opacity to its children.
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export class Opacity extends React.Component<IOpacityProps> {
    public render() {
        const { children, disabled = false, alpha = 1, animation, ...extendedProps } = this.props;

        let style = css`
            opacity: ${alpha};
        `;

        if (animation) {
            style = css`
                opacity: ${alpha};
                transition: ${animation};
            `;
        }

        if (disabled) {
            style = disabledStyle;
        }

        const attributes = extendableProps(extendedProps, { className: style });
        return renderReactChildren(
            children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes.__htmlAttributes),
        );
    }
}
