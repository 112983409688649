import * as React from 'react';
import { t } from 'app/translate';
import { Range } from 'app/components';
import { FloorPlanService, type IFloorPlanEntity } from 'app/core/persistence';
import { useService } from 'app/ioc';

interface IFloorPlanOpacityRangeProps {
    floorPlan: IFloorPlanEntity;
    hideValue?: boolean;
    onMouseUp?: () => void;
}

export const FloorPlanOpacityRange: React.FC<IFloorPlanOpacityRangeProps> = ({
    floorPlan,
    hideValue = false,
    onMouseUp,
}) => {
    const floorPlanService = useService(FloorPlanService);
    const [opacity, setOpacity] = React.useState<number>(floorPlan.image?.opacity || 0.5);
    const onOpacityChange = (newOpacity: number) => {
        setOpacity(newOpacity / 100);
        floorPlanService.updateFloorPlanOpacityDebounced(floorPlan._id, newOpacity / 100);
    };

    return (
        <Range
            color="blue"
            max={100}
            min={0}
            onChange={(value) => onOpacityChange(value)}
            onMouseUp={onMouseUp ? onMouseUp : undefined}
            value={opacity * 100}
            changeCriteria="key"
            decimals={0}
            label={t.floorPlanOpacity}
            step={1}
            showValue={hideValue === false}
            showValueInLabel
            unit="%"
        />
    );
};

FloorPlanOpacityRange.displayName = 'FloorPlanOpacityRange';
