import * as React from 'react';
import { Stack, Heading, IconText } from 'app/components';
import type { IScheduleSelectorModel, IScheduleRecordingSupport } from '../../models';
import type { Id, IRecordingSettingsModel } from 'app/core/persistence';
import { PropertyOverrideText } from '../common';
import { ProfileEditorRow } from '../profileEditorRow';
import { ScheduleSelector } from './ScheduleSelector.component';
import { RecordingSceneEditor } from './RecordingSceneEditor.component';
import { RecordingSettings } from './RecordingSettings.component';

import { t } from 'app/translate';

export interface IScheduledRecordingEditorProps {
    heading: string;
    expanded: boolean;
    deactivate?: boolean;
    liveView?: boolean;
    scheduleSelector: IScheduleSelectorModel;
    recordingSettings: IRecordingSettingsModel;
    useProjectSetting?: boolean;
    scheduleRecordingSupport?: IScheduleRecordingSupport;
    recordingSettingsOverride?: Partial<IRecordingSettingsModel>;
    inconsistentRecordingQuality?: boolean;
    showRecordingSolutionWarning?: boolean;
    onScheduleChange(scheduleId: Id | null): void;
    onRecordingSettingsChange(settings: IRecordingSettingsModel): void;
    onToggleRecordingSettings(): void;
}

export const ScheduledRecordingEditorComponent: React.FunctionComponent<
    IScheduledRecordingEditorProps
> = ({
    heading,
    expanded,
    scheduleSelector,
    scheduleRecordingSupport,
    recordingSettings,
    useProjectSetting,
    recordingSettingsOverride,
    inconsistentRecordingQuality,
    deactivate,
    liveView,
    showRecordingSolutionWarning,
    onScheduleChange,
    onRecordingSettingsChange,
    onToggleRecordingSettings,
}) => {
    return (
        <ProfileEditorRow>
            <Stack vertical spacing="half">
                <Stack vertical spacing="quart">
                    <Heading>{heading}</Heading>
                    {showRecordingSolutionWarning && (
                        <IconText
                            testId="warning_text_indoor_scenario_modal"
                            icon="warning"
                            iconProps={{ color: 'yellow5', size: 'sm' }}
                            textProps={{ color: 'grey7' }}
                        >
                            {t.noMultipleRecordingsWithRecordingSolution}
                        </IconText>
                    )}
                </Stack>
                <PropertyOverrideText
                    propertyValue={
                        recordingSettingsOverride &&
                        recordingSettingsOverride.schedule !== undefined
                    }
                />
                <ScheduleSelector
                    testId={heading}
                    noSchedule={!scheduleSelector.selectedSchedule}
                    expanded={expanded}
                    scheduleSelector={scheduleSelector}
                    onScheduleChange={onScheduleChange}
                    onToggleRecordingSettings={onToggleRecordingSettings}
                    deactivate={!deactivate}
                    liveView={liveView}
                />
                <Stack vertical>
                    {!deactivate && (
                        <RecordingSceneEditor
                            noSchedule={!scheduleSelector.selectedSchedule}
                            expanded={expanded}
                            recordingSettings={recordingSettings}
                            recordingSettingsOverride={recordingSettingsOverride}
                            onRecordingSettingsChange={onRecordingSettingsChange}
                            onToggleRecordingSettings={onToggleRecordingSettings}
                        />
                    )}
                    {scheduleSelector.selectedSchedule && (
                        <RecordingSettings
                            testId={heading}
                            scheduleRecordingSupport={scheduleRecordingSupport}
                            recordingSettings={recordingSettings}
                            recordingSettingsOverride={recordingSettingsOverride}
                            onRecordingSettingsChange={onRecordingSettingsChange}
                            deactivate={!!deactivate}
                            useProjectSetting={useProjectSetting}
                        />
                    )}
                </Stack>
                {inconsistentRecordingQuality && (
                    <IconText
                        icon="warning_small"
                        align="start"
                        iconProps={{ color: 'yellow7', size: 'sm', opaque: true }}
                    >
                        {t.profilesScheduledRecordingInconsistentRecordingQualityWarning}
                    </IconText>
                )}
            </Stack>
        </ProfileEditorRow>
    );
};

ScheduledRecordingEditorComponent.displayName = 'ScheduledRecordingEditorComponent';
