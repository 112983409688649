import * as React from 'react';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import { Modal, Border, Box, Button, Text } from 'app/components';
import type { IPlaceSearchResult } from 'app/modules/common';
import { PlaceSearch } from 'app/modules/common';
import { MapsActionService } from '../../services';
import { useEnterKeyDown } from 'app/hooks';
import { useModalLeftMargin } from '../../hooks/useModalLeftMargin';

export const AddLocationModal: React.FunctionComponent<{
    onClose: () => void;
}> = ({ onClose }) => {
    const mapsActionService = useService(MapsActionService);
    const [placeSearch, setPlaceSearch] = React.useState<IPlaceSearchResult>();
    const modalLeftMargin = useModalLeftMargin();

    const changeLocation = async (place: IPlaceSearchResult) => {
        mapsActionService.addMapLocationBySearchResult(place);
        mapsActionService.toggleAddLocationModal(false);
        onClose();
    };

    useEnterKeyDown(() => placeSearch && changeLocation(placeSearch));

    return (
        <Modal
            title={t.addLocation}
            onClose={() => mapsActionService.toggleAddLocationModal(false)}
            minWidth="460px"
            maxWidth="600px"
            marginBottom={20}
            marginLeft={modalLeftMargin}
        >
            <Box direction="column" spacing="panel">
                <Text color="grey8">{t.placeSearchHint}</Text>
                <Box direction="column" spacing="cell">
                    <Border width={1} color="grey3" sideColor={{ bottom: 'blue' }}>
                        <Box
                            display="grid"
                            grid={{ gridTemplateColumns: '1fr max-content' }}
                            minHeight={35}
                        >
                            <PlaceSearch
                                noBorder
                                openInPortal
                                onSelect={setPlaceSearch}
                                onClear={() => setPlaceSearch(undefined)}
                            />
                            <Button
                                primary
                                disabled={!placeSearch}
                                onClick={() => placeSearch && changeLocation(placeSearch)}
                            >
                                {t.goToLocation}
                            </Button>
                        </Box>
                    </Border>
                    <Box justifyContent="end">
                        <Button
                            text
                            onClick={() => mapsActionService.toggleAddLocationModal(false)}
                        >
                            {t.cancel}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

AddLocationModal.displayName = 'AddLocationModal';
