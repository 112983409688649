import { AppConstants } from 'app/AppConstants';
import {
    Positioned,
    Box,
    Border,
    TextInput,
    ColorPicker,
    IconButton,
    Icon,
    Text,
    RadioButton,
} from 'app/components';
import type { IPersistence, IFreeTextPointEntity } from 'app/core/persistence';
import { FreeTextPointService, FontSize } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import React from 'react';

interface IEditFreeTextProps {
    freeTextEntity: IPersistence<IFreeTextPointEntity>;
    position: { x: number; y: number };
    onClose(): void;
}

export const EditFreeText: React.FC<IEditFreeTextProps> = ({
    freeTextEntity,
    position,
    onClose,
}) => {
    const freeTextService = useService(FreeTextPointService);
    const id = freeTextEntity._id;
    const setText = (text: string) => {
        freeTextService.update(id, { text });
    };
    const setFontSize = (size: FontSize) => {
        eventTracking.logUserEvent('Maps', 'Changed free text font size', size.toLocaleString());
        freeTextService.update(id, { size });
    };
    const setTextColor = (color: string) => {
        eventTracking.logUserEvent('Maps', 'Changed free text color', color);
        freeTextService.update(id, { textColor: color });
    };
    const setBackgroundColor = (color: string) => {
        eventTracking.logUserEvent('Maps', 'Changed free text background color', color);
        freeTextService.update(id, { backgroundColor: color });
    };
    const deleteItem = () => {
        freeTextService.delete(id);
    };

    const getOffset = () => {
        switch (freeTextEntity?.size) {
            case FontSize.small:
                return 30;
            case FontSize.medium:
                return 35;
            case FontSize.large:
                return 40;
            default:
                return 30;
        }
    };

    React.useEffect(() => {
        return () => {
            if (freeTextEntity.creationDate === freeTextEntity.updatedDate) {
                // Update text on close to prevent auto-showing edit next time
                freeTextService.update(freeTextEntity._id, {});
            }
        };
    }, [freeTextEntity, freeTextService]);

    return (
        <Positioned aboveMap position="fixed" top={position.y + getOffset()} left={position.x}>
            <Box>
                <Border shadow="0px 2px 4px rgba(0, 0, 0, 0.25)" width={0} radius="8px">
                    <Box padding="half" color="white" direction="column" spacing="half">
                        <TextInput
                            autoFocus
                            autoFocusAndSelect={freeTextEntity.text === t.newText}
                            maxLength={AppConstants.freeTextMaxLength}
                            value={freeTextEntity.text}
                            changeCriteria="debounced"
                            onEnter={onClose}
                            onChange={setText}
                        />
                        <Box justifyContent="between" spacing="doublePanel">
                            <Box>
                                <RadioButton
                                    isActive={freeTextEntity?.size === FontSize.small}
                                    paddingX="half"
                                    paddingY="half"
                                    onClick={() => setFontSize(FontSize.small)}
                                >
                                    <Box
                                        width={20}
                                        height={20}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Text small>T</Text>
                                    </Box>
                                </RadioButton>
                                <RadioButton
                                    isActive={freeTextEntity?.size === FontSize.medium}
                                    paddingX="half"
                                    paddingY="half"
                                    onClick={() => setFontSize(FontSize.medium)}
                                >
                                    <Box
                                        width={20}
                                        height={20}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Text style="heading">T</Text>
                                    </Box>
                                </RadioButton>
                                <RadioButton
                                    isActive={freeTextEntity?.size === FontSize.large}
                                    paddingX="half"
                                    paddingY="half"
                                    onClick={() => setFontSize(FontSize.large)}
                                >
                                    <Box
                                        width={20}
                                        height={20}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Text style="title">T</Text>
                                    </Box>
                                </RadioButton>
                            </Box>
                            <Box>
                                <ColorPicker
                                    color={freeTextEntity?.textColor}
                                    onColorChange={setTextColor}
                                    width={30}
                                    height={30}
                                >
                                    <Icon opaque icon="color_text" />
                                </ColorPicker>
                                <ColorPicker
                                    color={freeTextEntity?.backgroundColor ?? '#000000'}
                                    onColorChange={setBackgroundColor}
                                    width={30}
                                    height={30}
                                >
                                    <Icon opaque icon="color_fill" />
                                </ColorPicker>
                            </Box>
                        </Box>
                        <Box alignItems="center" justifyContent="center" paddingTop="base">
                            <IconButton icon="delete" text={t.delete} onClick={deleteItem} />
                        </Box>
                    </Box>
                </Border>
            </Box>
        </Positioned>
    );
};
