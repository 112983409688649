import { groupBy } from 'lodash-es';
import type { IRecordingSolutionItem } from './common';

/**
 * A function to group by piaId and sum the quantities and accessories.
 */
export const groupByPiaId = (items: IRecordingSolutionItem[]): IRecordingSolutionItem[] =>
    Object.values(groupBy(items, 'piaId')).map((groupedItems) =>
        groupedItems.slice(1).reduce(
            (acc, it) => {
                const previousAccessories = acc.accessories ?? [];
                const currentAccessories = it.accessories ?? [];
                acc.quantity += it.quantity;
                acc.accessories = [...previousAccessories, ...currentAccessories];
                return acc;
            },
            { ...groupedItems[0] },
        ),
    );
