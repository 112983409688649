interface IItemWithNameAndModel {
    name: string;
    model: string;
}

interface IItemWithNameAndQuantity {
    name: string;
    quantity: number;
}

/** Sorts by name first, model second. Ascending order. */
export const nameAndModelComparator = (
    a: IItemWithNameAndModel,
    b: IItemWithNameAndModel,
): number =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true }) ||
    a.model.toLowerCase().localeCompare(b.model.toLowerCase(), undefined, { numeric: true });

/** Sorts by name first, model second. Descending order. */
export const nameAndModelComparatorReverse = (
    a: IItemWithNameAndModel,
    b: IItemWithNameAndModel,
): number => nameAndModelComparator(b, a);

/** Sorts by model first, name second. Ascending order. */
export const modelAndNameComparator = (
    a: IItemWithNameAndModel,
    b: IItemWithNameAndModel,
): number =>
    a.model.toLowerCase().localeCompare(b.model.toLowerCase(), undefined, { numeric: true }) ||
    a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true });

/** Sorts by model first, name second. Descending order. */
export const modelAndNameComparatorReverse = (
    a: IItemWithNameAndModel,
    b: IItemWithNameAndModel,
): number => modelAndNameComparator(b, a);

/** Sorts by quantity first, name second. Ascending order. */
export const quantityAndNameComparator = (
    a: IItemWithNameAndQuantity,
    b: IItemWithNameAndQuantity,
): number =>
    b.quantity - a.quantity ||
    a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true });

/** Sorts by quantity first, name second. Descending order. */
export const quantityAndNameComparatorReverse = (
    a: IItemWithNameAndQuantity,
    b: IItemWithNameAndQuantity,
): number => quantityAndNameComparator(b, a);
