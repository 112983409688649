import type {
    IBaseProfileModel,
    IProfileEntity,
    IProfileOverridePropertiesEntity,
    IProfileOverridePropertiesModel,
} from 'app/core/persistence';
import { isEmpty, merge } from 'lodash-es';

export const mergeProfiles = (
    profile: IProfileEntity | IBaseProfileModel,
    profileOverride: IProfileOverridePropertiesEntity | IProfileOverridePropertiesModel | undefined,
    projectRetentionTime: number,
) => {
    const isUsingProjectSetting =
        profileOverride?.storage.useProjectSetting !== undefined
            ? profileOverride.storage.useProjectSetting
            : profile?.storage.useProjectSetting;
    let storageProfile = profile?.storage;
    if (profile && storageProfile && isUsingProjectSetting) {
        storageProfile = { ...profile.storage, retentionTime: projectRetentionTime };
        profile = { ...profile, storage: storageProfile };
    }
    const mergedProfile = merge({}, profile, profileOverride);
    return isEmpty(mergedProfile) ? undefined : mergedProfile;
};
