import { injectable } from 'inversify';

/** Handles communications with the ACX API.
 *
 * API repository: https://github.com/axteams-software/sp-acx-graphql-api/
 *
 * API documentation: https://docs.stage.cs.connect.axis.com/
 *
 * Manage organizations and resource groups: https://portal.iam.connect.axis.com/
 */
@injectable()
export class ACXApiCommunicator {
    constructor() {}
    /** Takes a GraphQL query and sends it as a request to the ACX API.
     * @argument query A GraphQL formatted query
     * @returns JSON formatted data from the ACX API response
     */
    public async fetchGraphQL(query: string, accessToken: string, variables?: any) {
        // Fetch data from GitHub's GraphQL API:
        const response = await fetch(window.appConfig.acxApi.endpoint, {
            method: 'POST',
            headers: {
                'acx-client-key': window.appConfig.acxApi.acxClientKey,
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables,
            }),
        });

        // Get the response as JSON
        const jsonResponse = await response.json();
        return jsonResponse.data;
    }
}
