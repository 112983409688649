import * as React from 'react';
import { css, cx } from '@emotion/css';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '../../layout';
import { Label } from '../../text';
import { ColorsEnum } from 'app/styles';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { IconStyle } from '../../ui/icon';

const userAgent = window.navigator.userAgent;
const isEdgeOrExplorer = userAgent.includes('Trident') || userAgent.includes('Edge');

const baseStyle = css`
    appearance: none;
    position: relative;
    cursor: pointer;
`;

const customStyle = {
    checkmark: css`
        width: 16px;
        height: 16px;
        background: ${ColorsEnum.white};
        outline: 1px solid ${ColorsEnum.grey4};

        &:focus,
        &:active {
            outline: 1px solid ${ColorsEnum.blue};
        }

        &:checked::before {
            ${IconStyle}
            opacity: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: 16px;
            color: ${ColorsEnum.blue};
            content: 'check';
            text-align: center;
        }
        &::-ms-check {
            color: ${ColorsEnum.blue};
            border: none;
        }
    `,
    slider: css`
        background: ${ColorsEnum.grey3};
        border-radius: 10px;
        width: 34px;
        height: 15px;
        outline: none;

        &:checked {
            background: ${ColorsEnum.blue1};
        }

        &::before {
            position: absolute;
            content: ' ';
            top: -3px;
            left: -4px;
            background: ${ColorsEnum.white};
            border-radius: 100%;
            height: 18px;
            width: 18px;
            border: 1px solid ${ColorsEnum.white};
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        }

        &:checked::before {
            background: ${ColorsEnum.blue};
            border: 1px solid ${ColorsEnum.blue};
            right: -4px;
            left: auto;
            box-shadow: 0px 2px 4px 0px rgba(0, 90, 255, 0.25);
        }

        &:focus::before,
        &:active::before {
            border-color: ${ColorsEnum.blue};
        }

        &:checked:focus::before,
        &:checked:active::before {
            border-color: ${ColorsEnum.blue0};
        }
        [dir='rtl'] & {
            transform: rotate(180deg);
        }
    `,
};

interface ICheckboxProps extends IAutoTestable {
    /**
     * Provide a element ID. If not it will be automatically generated.
     */
    id?: string;
    /**
     * Whether the checkbox is checked
     */
    selected: boolean;
    /**
     * Disables checkbox
     */
    disabled?: boolean;
    /**
     * Will take a string as a child and render it as a proper HTML label
     */
    children?: string;
    /**
     * Will add a descriptive text under the label.
     */
    description?: React.ReactNode;
    /**
     * Set label to left of checkbox
     */
    labelLeft?: boolean;
    /**
     * Justify checkbox and label left
     */
    justifyLeft?: boolean;
    /**
     * Shows label without opacity
     */
    opaque?: boolean;
    /**
     * Display as a slider toggle instead of a checkbox with checkmark.
     * Note: this will not work in IE11 and Edge, it will just render like an ordinary checkmark checkbox.
     */
    slider?: boolean;
    /**
     * onChange callback function when user checks/un-checks the checkbox
     */
    onChange(newVal: boolean): void;
}

/**
 * An ordinary html checkbox input.
 */
export class Checkbox extends React.Component<ICheckboxProps> {
    private uuid = uuidv4();

    public render() {
        const id = this.props.id || `id-${this.uuid}`;
        const CheckBoxAndLabel = [
            ...(this.props.children || this.props.description
                ? [
                      <Stack key="label" vertical notFullWidth spacing="halfQuart">
                          <Label for={id} disabled={this.props.disabled} opaque={this.props.opaque}>
                              {this.props.children}
                          </Label>
                          {this.props.description}
                      </Stack>,
                  ]
                : []),
            <input
                key="input"
                className={cx(
                    baseStyle,
                    customStyle[this.props.slider && !isEdgeOrExplorer ? 'slider' : 'checkmark'],
                )}
                data-test-id={toTestIdFormat(this.props.testId)}
                id={id}
                checked={this.props.selected}
                type="checkbox"
                disabled={this.props.disabled}
                onChange={this.onChange}
            />,
        ];

        return (
            <Stack
                spacing="base"
                alignItems={this.props.description ? 'start' : 'center'}
                justifyContent={this.props.justifyLeft ? 'start' : 'between'}
            >
                {this.props.labelLeft ? CheckBoxAndLabel : CheckBoxAndLabel.reverse()}
            </Stack>
        );
    }

    private onChange = () => {
        if (this.props.disabled) {
            return;
        }
        this.props.onChange(!this.props.selected);
    };
}
