import { Border, Box, Clickable, NoPrint, Positioned } from 'app/components';
import * as React from 'react';
import { MapsActionService } from '../../services';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import { getIsDefaultFloorPlanSelected, getStreetModeOn, getUseTinyIcons } from '../../selectors';
import { css } from '@emotion/css';
import { useMouseHover } from 'app/hooks';
import { isPageRTL } from 'app/translate';

const imgStyle = css`
    border-radius: 2px;
`;

type IconType = 'crisp' | 'classic';
type MapType = 'street' | 'satellite';

export const MapTypeButton: React.FunctionComponent = () => {
    const mapActions = useService(MapsActionService);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const isHovering = useMouseHover(containerRef);
    const streetModeOn = useSelector(getStreetModeOn);
    const useTinyIcons = useSelector(getUseTinyIcons);
    const isFloorPlan = !useSelector(getIsDefaultFloorPlanSelected);
    const isRTL = isPageRTL();

    const hoverButtonImage = React.useMemo(() => {
        if (isFloorPlan) {
            return require(
                `src/assets/images/mapTypes/${useTinyIcons ? 'classic' : 'crisp'}-street.png`,
            );
        }
        const iconMode: IconType = useTinyIcons ? 'crisp' : 'classic';
        const streetMode: MapType = streetModeOn ? 'satellite' : 'street';
        return require(`src/assets/images/mapTypes/${iconMode}-${streetMode}.png`);
    }, [isFloorPlan, streetModeOn, useTinyIcons]);

    const renderMapTypeButton = (iconType: IconType, mapType: MapType) => {
        const selectedIconType: IconType = useTinyIcons ? 'crisp' : 'classic';
        const selectedMapType: MapType = streetModeOn ? 'street' : 'satellite';
        const selected = iconType === selectedIconType && mapType === selectedMapType;

        return (
            <Box testId={`${mapType}_${iconType}`}>
                <Clickable
                    onClick={() => {
                        if (mapType !== selectedMapType) {
                            mapActions.toggleStreetMode();
                        }

                        if (iconType !== selectedIconType) {
                            mapActions.toggleTinyIcons(!useTinyIcons);
                        }
                    }}
                >
                    <Border
                        radius="4px"
                        color={selected ? 'blue' : 'transparent'}
                        hoverColor="blue"
                    >
                        <Box height={60} width={60} color="white" padding="halfQuart">
                            <img
                                className={imgStyle}
                                src={require(
                                    `src/assets/images/mapTypes/${iconType}-${mapType}.png`,
                                )}
                            />
                        </Box>
                    </Border>
                </Clickable>
            </Box>
        );
    };

    return (
        <NoPrint>
            <Positioned
                position="absolute"
                top={10}
                left={isRTL ? 10 : undefined}
                right={isRTL ? undefined : 10}
                aboveMap
            >
                <Box position="relative">
                    <Box innerRef={containerRef} testId="map_type_button">
                        <Clickable
                            onClick={() =>
                                isFloorPlan
                                    ? mapActions.toggleTinyIcons(!useTinyIcons)
                                    : mapActions.toggleStreetMode()
                            }
                        >
                            <Border radius="4px" width={0} shadow="0 0 2px 2px rgba(0 0 0 / 0.2)">
                                <Box height={60} width={60} color="white" padding="halfQuart">
                                    <img className={imgStyle} src={hoverButtonImage} />
                                </Box>
                            </Border>
                        </Clickable>
                        {isHovering && !isFloorPlan && (
                            <Positioned
                                position="absolute"
                                right={isRTL ? undefined : '100%'}
                                left={isRTL ? '100%' : undefined}
                            >
                                <Box paddingRight="half">
                                    <Border
                                        topWidth={2}
                                        bottomWidth={2}
                                        color="black"
                                        shadow="0px 4px 10px 0px rgba(0, 0, 0, 0.25)"
                                    >
                                        <Box
                                            display="grid"
                                            grid={{ gridTemplateColumns: '1fr 1fr' }}
                                            color="white"
                                            spacing="halfQuart"
                                            padding="quart"
                                        >
                                            {renderMapTypeButton('classic', 'street')}
                                            {renderMapTypeButton('classic', 'satellite')}
                                            {renderMapTypeButton('crisp', 'street')}
                                            {renderMapTypeButton('crisp', 'satellite')}
                                        </Box>
                                    </Border>
                                </Box>
                            </Positioned>
                        )}
                    </Box>
                </Box>
            </Positioned>
        </NoPrint>
    );
};

MapTypeButton.displayName = 'MapTypeButton';
