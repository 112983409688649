import * as React from 'react';
import { Clickable } from '../clickable/Clickable';
import type { IExtendableComponent } from '../../models';

export interface IToggleOnClickProps extends IExtendableComponent {
    /**
     * Disable the toggle
     */
    disabled?: boolean;
    /**
     * A function that renders the children.
     * Provides a boolean that indicates whether it has been clicked once
     */
    children(hasBeenClicked: boolean): JSX.Element;
}

export interface IToggleOnClickState {
    hasBeenClicked: boolean;
}

/**
 * A component that allows you to render something based on if it
 * was clicked once or not. Useful for delete buttons that should require
 * two clicks (the last one to confirm)
 */
export class ToggleOnClick extends React.Component<IToggleOnClickProps, IToggleOnClickState> {
    constructor(props: IToggleOnClickProps) {
        super(props);
        this.state = {
            hasBeenClicked: false,
        };
    }

    public render() {
        const { children, ...props } = this.props;

        return (
            <Clickable disabled={this.props.disabled} onClick={this.onClick} {...props}>
                {children(this.state.hasBeenClicked)}
            </Clickable>
        );
    }

    private onClick = () => {
        this.setState({ hasBeenClicked: true });
    };
}
