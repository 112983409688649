import {
    calculatePixelDensity,
    getFovLimits,
    getPiaCameraForProductId,
    toCacheKey,
    calculate,
} from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { getCameraFilter } from './getProductsForDeviceGroup';

export const getPixelDensityInMeters = createCachedSelector(
    [getCameraFilter, getFovLimits, getPiaCameraForProductId],
    (cameraFilter, lensFovLimits, piaCamera): number => {
        const desiredCamera = cameraFilter ? cameraFilter.desiredCamera : null;
        const horizontalFovRadians = desiredCamera ? desiredCamera.horizontalFOVRadians : 0;
        const distance = desiredCamera
            ? calculate.trueDistance(
                  desiredCamera.installationHeight,
                  desiredCamera.distanceToTarget,
                  desiredCamera.targetHeight,
              )
            : 0;

        return calculatePixelDensity({
            distance,
            horizontalFovRadians,
            lensFovLimits,
            piaCamera,
        });
    },
)(toCacheKey);
