import { injectable } from 'inversify';
import type {
    Id,
    ItemRelationType,
    IItem,
    IPersistence,
    IItemEntity,
    IIdRev,
} from 'app/core/persistence';
import {
    ItemService,
    CurrentProjectService,
    getDefaultProfileOverrideEntity,
} from 'app/core/persistence';
import { AppStore } from 'app/store';
import { getIsStandalone } from 'app/modules/common';
import type { PiaId } from 'app/core/pia';

@injectable()
export class AccessoryPersistenceService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private appStore: AppStore,
    ) {}

    public async addStandaloneItem(piaId: PiaId) {
        if (!getIsStandalone(this.appStore.Store.getState())) {
            throw new Error('only for standalone');
        }

        return this.itemService.addToCurrentProject({
            description: '',
            name: '',
            notes: '',
            productId: piaId,
            properties: {
                camera: {
                    associatedProfile: '',
                    filter: this.currentProjectService.getProjectDefaultCameraFilter(),
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
            quantity: 1,
        });
    }

    public get(id: Id): Promise<IPersistence<IItemEntity>> {
        return this.itemService.getItem(id);
    }

    public async setItem(
        parentId: Id,
        productId: PiaId | null,
        itemRelationType: ItemRelationType,
    ): Promise<IPersistence<IItemEntity>> {
        const item: IItem = {
            name: '',
            description: '',
            notes: '',
            productId,
            quantity: 1,
            properties: {},
        };

        if (itemRelationType === 'environment') {
            item.properties.environment = {};
        } else {
            item.properties.accessory = {};
        }

        const persistedItem = await this.itemService.addByParentId(parentId, item);
        await this.itemService.addItemRelation(parentId, persistedItem._id, itemRelationType);

        return persistedItem;
    }

    public updateProductId = async (
        item: IPersistence<IItemEntity>,
        productId: number | null,
    ): Promise<IPersistence<IItemEntity> | undefined> =>
        this.itemService.updateItem(item._id, { productId });

    public updateQuantity = async (
        itemId: Id,
        quantity: number,
    ): Promise<IPersistence<IItemEntity> | undefined> =>
        this.itemService.updateItem(itemId, { quantity });

    public getChildItemsByRelation = async (
        parentId: Id,
        itemRelationType: ItemRelationType,
    ): Promise<IPersistence<IItemEntity>[]> => {
        const childRelation = this.currentProjectService
            .getAllEntitiesOfType('itemRelation')
            .filter(
                (relation) =>
                    relation.parentId === parentId && relation.relationType === itemRelationType,
            );

        return Promise.all(
            childRelation.map((relation) => this.itemService.getItem(relation.childId)),
        );
    };

    public async delete(itemId?: Id): Promise<Id | undefined> {
        if (itemId) {
            return this.itemService.deleteItem(itemId);
        }
    }

    public async deleteItems(idRevs: IIdRev[]) {
        return this.itemService.deleteItems(idRevs);
    }

    public async deleteMountOption(
        deviceId: Id,
        itemRelationType: ItemRelationType,
    ): Promise<Id[]> {
        const mountItems = await this.getChildItemsByRelation(deviceId, itemRelationType);
        return this.itemService.deleteItems(mountItems);
    }

    public getEnvironmentFromDevice = (deviceId: Id) =>
        this.getChildItemsByRelation(deviceId, 'environment');

    public getPrimaryMountFromDevice = (deviceId: Id) =>
        this.getChildItemsByRelation(deviceId, 'primaryMount');
}
