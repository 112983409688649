import { createCachedSelector } from 're-reselect';
import type { IPersistence, IItemEntity } from 'app/core/persistence';
import { isSensorUnit } from 'app/core/persistence';
import type { IPiaItem, IPiaMainUnit, IPiaSensorUnit, IPiaCamera } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import { MainUnitEstimateService } from '../../bandwidth';
import { toCacheKey } from '../../cacheKey';
import { getPiaItemsRecord } from '../../piaDevices';
import {
    getCurrentProjectFrequency,
    getCurrentProjectItems,
    getCurrentProjectProfilesArray,
} from '../../project';
import { getPiaIdFromProps } from '../../selectors';
import { ProfileSupportService } from '../../profile/services';

const getParentIdState = (state: IStoreState) => state.deviceSelector.parentId;

export function getPiaDeviceProductProperties(
    piaItem: IPiaItem,
    piaItemsRecord: Record<string, IPiaItem>,
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
    parentId: string | null,
) {
    if (piaItem.id === null) {
        return null;
    }

    if (isSensorUnit(piaItem)) {
        if (!parentId) {
            return null;
        }

        const mainUnit = currentProjectItems[parentId];
        if (!mainUnit || mainUnit.productId === null || piaItem.id === null) {
            return null;
        }

        const mainUnitPiaProduct = piaItemsRecord[mainUnit.productId] as IPiaMainUnit;
        const sensorUnitProduct = piaItem as IPiaSensorUnit;

        if (!mainUnitPiaProduct || !sensorUnitProduct) {
            return null;
        }

        return MainUnitEstimateService.mergeFSeriesProperties(
            mainUnitPiaProduct,
            sensorUnitProduct,
        );
    }

    return (piaItem as IPiaCamera)?.properties ?? null;
}

export const getPiaDeviceProfileSupport = createCachedSelector(
    [
        getCurrentProjectFrequency,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getPiaIdFromProps,
        getParentIdState,
        getCurrentProjectProfilesArray,
    ],
    (currentProjectFrequency, currentProjectItems, piaItemsRecord, piaId, parentId, profiles) => {
        if (piaId === null || piaId === undefined || !profiles) {
            return {};
        }

        const piaItem = piaItemsRecord[piaId];

        const piaProductProperties = getPiaDeviceProductProperties(
            piaItem,
            piaItemsRecord,
            currentProjectItems,
            parentId,
        );

        if (!piaProductProperties) {
            return {};
        }

        const profileSupportRecord: Record<string, boolean> = {};

        profiles.forEach((scenario) => {
            const profileIsUnSupported = ProfileSupportService.getProfileSupportSync(
                scenario,
                piaProductProperties,
                currentProjectFrequency,
            ).isAnySettingUnsupported;

            profileSupportRecord[scenario._id] = !profileIsUnSupported;
        });

        return profileSupportRecord;
    },
)(toCacheKey);
