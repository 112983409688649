import { createSelector } from 'reselect';
import { PoeClass } from 'app/core/pia';
import { getRecordingSolutionTotalSpecs } from './getRecordingSolutionSpecs';
import { getRecordingSolutionRequirements } from './getRecordingSolutionRequirements';

export interface IPoeRequirement {
    poeClass: PoeClass | NoPoeClass;
    availablePorts: number;
    requiredPorts: number;
    missingPorts: number;
    requirementFulfilled: boolean;
}

export enum NoPoeClass {
    NoPoe = 'nonPoe',
}

/**
 * Returns available and required ports for each PoE class.
 */
export const getPoeRequirements = createSelector(
    [getRecordingSolutionTotalSpecs, getRecordingSolutionRequirements],
    (specs, requirement): IPoeRequirement[] => {
        let remainingPorts = 0;
        const details: IPoeRequirement[] = [
            {
                availablePorts: specs.ports.highPoEPorts,
                requiredPorts: requirement.ports.highPoEPorts,
                poeClass: PoeClass.HighPoE,
            },
            {
                availablePorts: specs.ports.poeClass8Ports,
                requiredPorts: requirement.ports.poeClass8Ports,
                poeClass: PoeClass.Class8,
            },
            {
                availablePorts: specs.ports.poeClass7Ports,
                requiredPorts: requirement.ports.poeClass7Ports,
                poeClass: PoeClass.Class7,
            },
            {
                availablePorts: specs.ports.poeClass6Ports,
                requiredPorts: requirement.ports.poeClass6Ports,
                poeClass: PoeClass.Class6,
            },
            {
                availablePorts: specs.ports.poeClass5Ports,
                requiredPorts: requirement.ports.poeClass5Ports,
                poeClass: PoeClass.Class5,
            },
            {
                availablePorts: specs.ports.poeClass4Ports,
                requiredPorts: requirement.ports.poeClass4Ports,
                poeClass: PoeClass.Class4,
            },
            {
                availablePorts: specs.ports.poeClass3Ports,
                requiredPorts: requirement.ports.poeClass3Ports,
                poeClass: PoeClass.Class3,
            },
            {
                availablePorts: specs.ports.poeClass2Ports,
                requiredPorts: requirement.ports.poeClass2Ports,
                poeClass: PoeClass.Class2,
            },
            {
                availablePorts: specs.ports.poeClass1Ports,
                requiredPorts: requirement.ports.poeClass1Ports,
                poeClass: PoeClass.Class1,
            },
        ].map(({ availablePorts, requiredPorts, poeClass }) => {
            remainingPorts = Math.max(remainingPorts, 0) + availablePorts - requiredPorts;
            const requirementFulfilled = remainingPorts >= 0;
            return {
                poeClass,
                availablePorts: availablePorts,
                requiredPorts: remainingPorts - requiredPorts,
                missingPorts: requirementFulfilled ? 0 : remainingPorts * -1,
                requirementFulfilled,
            };
        });

        // Add ports that do not require PoE power
        const missingPoePorts = details.reduce(
            (missingPorts, detail) => missingPorts + detail.missingPorts,
            0,
        );
        const missingPorts = requirement.ports.totalPorts - specs.ports.totalPorts;
        const noPoePorts = missingPorts - missingPoePorts;
        if (noPoePorts > 0) {
            details.push({
                poeClass: NoPoeClass.NoPoe,
                availablePorts: 0,
                requiredPorts: noPoePorts,
                missingPorts: noPoePorts,
                requirementFulfilled: false,
            });
        }
        return details.reverse();
    },
);
