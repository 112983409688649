import * as React from 'react';
import { Stack } from '../layout/stack/Stack.component';
import type { Icons } from '../ui/icon';
import { Icon } from '../ui/icon';
import type { IAutoTestable } from '../ui-test';
import { BarChart } from './BarChart.component';
import { PieChart } from './PieChart';
import { Text } from '../text';
import { Clickable, Linkable } from '../functional';
import type { IWithChildren } from '../models';

export type ChartType = 'pie' | 'bar';

interface IChartData {
    value: number;
    maxValue: number;
    chartType: ChartType;
}

interface IDataWidgetProps extends IAutoTestable, IWithChildren {
    text: any;
    description: string;
    icon?: Icons;
    chart?: IChartData;
    secondaryIcon?: Icons;
    link?: string;
}

export const DataWidget: React.FunctionComponent<IDataWidgetProps> = ({
    text,
    description,
    icon,
    chart,
    testId,
    secondaryIcon,
    link,
    children,
}) => {
    let percent = 0;
    if (chart && chart.value > 0) {
        percent = chart.maxValue > 0 ? (chart.value / chart.maxValue) * 100 : 101;
    }
    const getMainText = (mainText: any, extraIcon?: Icons) => {
        if (children) {
            return children;
        }
        if (extraIcon) {
            return (
                <Stack spacing="half">
                    <Text style="headline" whiteSpace="nowrap" lineHeight={1} testId={testId}>
                        {mainText}
                    </Text>
                    <Icon icon={extraIcon} size="sm" />
                </Stack>
            );
        }
        return (
            <Text testId={testId} style="headline" whiteSpace="nowrap" lineHeight={1}>
                {text}
            </Text>
        );
    };

    const widget = (
        <Stack>
            {chart && chart.chartType === 'bar' && <BarChart percent={percent} />}
            {chart && chart.chartType === 'pie' && <PieChart percent={percent} />}
            {!chart && icon && <Icon opaque icon={icon} color="grey4" />}
            <Stack vertical spacing="none">
                {getMainText(text, secondaryIcon)}
                <Text whiteSpace="nowrap" color="grey5" small>
                    {description}
                </Text>
            </Stack>
        </Stack>
    );

    return link ? (
        <Linkable link={link}>
            <Clickable>{widget}</Clickable>
        </Linkable>
    ) : (
        widget
    );
};

DataWidget.displayName = 'DataWidget';
