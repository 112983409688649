import * as React from 'react';
import { t } from 'app/translate';
import type { ScheduleDays } from 'app/core/persistence';
import { Stack, Text } from 'app/components';

export interface IDaySelector {
    /**
     * The days in this schedule
     */
    days: ScheduleDays;
}

export class DaySelector extends React.PureComponent<IDaySelector> {
    public render() {
        const { days } = this.props;
        return (
            <Stack spacing="half">
                <Text inline style="semibold" color={this.getColor(days.hasMonday())}>
                    {t.dayAbbreviationsGROUP.monday}
                </Text>
                <Text inline style="semibold" color={this.getColor(days.hasTuesday())}>
                    {t.dayAbbreviationsGROUP.tuesday}
                </Text>
                <Text inline style="semibold" color={this.getColor(days.hasWednesday())}>
                    {t.dayAbbreviationsGROUP.wednesday}
                </Text>
                <Text inline style="semibold" color={this.getColor(days.hasThursday())}>
                    {t.dayAbbreviationsGROUP.thursday}
                </Text>
                <Text inline style="semibold" color={this.getColor(days.hasFriday())}>
                    {t.dayAbbreviationsGROUP.friday}
                </Text>
                <Text inline style="semibold" color={this.getColor(days.hasSaturday())}>
                    {t.dayAbbreviationsGROUP.saturday}
                </Text>
                <Text inline style="semibold" color={this.getColor(days.hasSunday())}>
                    {t.dayAbbreviationsGROUP.sunday}
                </Text>
            </Stack>
        );
    }
    private getColor = (selected: boolean) => {
        return selected ? 'grey9' : 'transparent';
    };
}
