import * as React from 'react';
import { Heading, Box, NumberInput, Text, Checkbox } from 'app/components';
import { t } from 'app/translate';
import { constants } from '../../profile/constants';
import { PropertyOverrideText } from './common';
import { ProfileEditorRow } from './profileEditorRow';
import type { IStorageSettingsModel } from 'app/core/persistence';

export interface IStorageSettingsProps {
    profileSetting: IStorageSettingsModel;
    profileSettingOverride?: Partial<IStorageSettingsModel>;
    projectSettingRetentionTime?: number;
    hideProjectSettingToggle?: boolean;
    onChangeRetentionTime(value: number): void;
    onChangeUseProjectSetting(value: boolean): void;
}

export const StorageSettingsComponent: React.FunctionComponent<IStorageSettingsProps> = ({
    profileSetting,
    profileSettingOverride,
    projectSettingRetentionTime,
    hideProjectSettingToggle,
    onChangeRetentionTime,
    onChangeUseProjectSetting,
}) => {
    const isUsingProjectSetting =
        profileSettingOverride?.useProjectSetting !== undefined
            ? profileSettingOverride.useProjectSetting
            : profileSetting.useProjectSetting;

    return (
        <ProfileEditorRow>
            <Box direction="column" spacing="half">
                <Heading>{t.storage}</Heading>
                <Box spacing="base">
                    <Box width={constants.leftColumnWidth}>
                        {hideProjectSettingToggle !== true && (
                            <Checkbox
                                slider
                                selected={isUsingProjectSetting}
                                onChange={onChangeUseProjectSetting}
                            >
                                {t.zipStreamGlobalToggle}
                            </Checkbox>
                        )}
                    </Box>
                    <Box flex="shrinkAndGrow">
                        <div>
                            <PropertyOverrideText
                                text={t.profilesStorageSettingsStorageTime}
                                propertyValue={profileSettingOverride?.retentionTime}
                            />
                            <Box spacing="quart">
                                <NumberInput
                                    testId="storage_retention_time_npt"
                                    disabled={isUsingProjectSetting}
                                    value={
                                        isUsingProjectSetting
                                            ? projectSettingRetentionTime
                                            : profileSettingOverride?.retentionTime ??
                                              profileSetting.retentionTime
                                    }
                                    min={1}
                                    max={9999}
                                    onChange={onChangeRetentionTime}
                                    changeCriteria="debounced"
                                />
                                <Text>{t.daysLowerCase}</Text>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Box>
        </ProfileEditorRow>
    );
};

StorageSettingsComponent.displayName = 'StorageSettingsComponent';
