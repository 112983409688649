import { ProjectZipType } from 'app/core/persistence';
import { getCurrentProjectProjectZipSetting } from 'app/modules/common';
import { t } from 'app/translate';
import { createSelector } from 'reselect';

/**
 * Get translation title for projects zip setting. Depending on the value, the title is retrieved from either zipStepsTitleGROUP or zipStepsGROUP.
 * @returns the title string for the project zip settings.
 */
export const getZipStreamSettingsTitle = createSelector(
    [getCurrentProjectProjectZipSetting],
    (projectZipSetting) => {
        switch (projectZipSetting) {
            case ProjectZipType.off:
                return t.zipStepsGROUP.off;
            case ProjectZipType.low:
                return t.zipStepsTitleGROUP.low;
            case ProjectZipType.medium:
                return t.zipStepsGROUP.medium;
            case ProjectZipType.high:
                return t.zipStepsTitleGROUP.high;
            case ProjectZipType.max:
                return t.zipStepsTitleGROUP.max;
        }
    },
);
