import { injectable } from 'inversify';
import { last } from 'lodash-es';
import type { IMigration } from './models/IMigration';
import { migrate1to2 } from './Migrate1to2';
import { migrate2to3 } from './Migrate2to3';
import { migrate3to4 } from './Migrate3to4';
import { migrate4to5 } from './Migrate4to5';
import { migrate5to6 } from './Migrate5to6';
import { getItemLocalStorage, setItemLocalStorage } from '../../utils';

/***
 * Performs migrations of storage areas. E.g renaming, moving, or deleting storage areas.
 */
@injectable()
export class StorageMigrationService {
    private migrations: IMigration[];

    constructor() {
        this.migrations = [migrate1to2, migrate2to3, migrate3to4, migrate4to5, migrate5to6];
    }

    public async triggerMigrationIfRequired(): Promise<void> {
        const lastMigration = last(this.migrations);

        if (!lastMigration) {
            return;
        }

        const currentVersion = this.getStorageVersion();

        if (currentVersion < lastMigration.to) {
            const migrationToRun = this.migrations.find(({ from }) => from === currentVersion);

            if (!migrationToRun) {
                throw Error(`No migration available from ${currentVersion} to ${lastMigration.to}`);
            }

            await migrationToRun.run();
            this.setMigrationCompleteFlag(migrationToRun.to);

            return this.triggerMigrationIfRequired();
        }
    }

    private getStorageVersion(): number {
        const storageVersion = Number(getItemLocalStorage('StorageVersion'));
        return storageVersion ? storageVersion : 1;
    }

    private setMigrationCompleteFlag(version: number) {
        setItemLocalStorage('StorageVersion', String(version));
    }
}
