import * as React from 'react';
import { t } from 'app/translate';
import type { IOptionProps } from 'app/components';
import { Select, Box, Heading, Stack, Text } from 'app/components';

import type {
    Id,
    IDoorControllerItemEntity,
    IItemEntity,
    IPersistence,
} from 'app/core/persistence';
import { PowerSupplyType } from 'app/core/persistence';
import { DoorControllerService, getCurrentProjectItem, getPiaItem } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { IPiaDoorController } from 'app/core/pia';
import { useService } from 'app/ioc';

interface IDoorsHeaderProps {
    deviceItemId: Id;
}

export const DoorsHeader: React.FC<IDoorsHeaderProps> = ({ deviceItemId }) => {
    const doorControllerService = useService(DoorControllerService);

    const device = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, deviceItemId),
    );

    const piaDoorController = useSelector<IStoreState, IPiaDoorController | null>(
        (state) => getPiaItem(state, device?._id) as IPiaDoorController,
    );

    const onChangePowerSupply = (value: PowerSupplyType) =>
        device &&
        doorControllerService.updatePowerSupply(device as IPersistence<IDoorControllerItemEntity>, {
            powerSupply: value,
        });

    const powerSupplyOptions: IOptionProps[] = [
        {
            text: t.advancedFiltersGROUP.powerOverEthernet,
            value: PowerSupplyType.PoE,
        },
        {
            text: t.dcPowerSupply,
            value: PowerSupplyType.DC,
        },
    ];

    const powerSupplyWatt =
        device?.properties.doorController?.powerSupply === PowerSupplyType.DC
            ? piaDoorController?.properties.powerBudgetDC
            : piaDoorController?.properties.powerBudgetPoEClass4;

    return (
        <Box alignItems="center">
            <Stack justifyContent="center" spacing="doublePanel">
                {piaDoorController?.properties.powerBudgetDC &&
                    piaDoorController?.properties.powerBudgetPoEClass4 && (
                        <Stack spacing="half">
                            <Heading>{t.power}</Heading>
                            <Select
                                value={
                                    device?.properties.doorController?.powerSupply ||
                                    PowerSupplyType.PoE
                                }
                                onChange={onChangePowerSupply}
                                options={powerSupplyOptions}
                            />
                            <Box paddingLeft="panel">
                                <Text color="grey6">
                                    {powerSupplyWatt ? t.totalPowerBudget(powerSupplyWatt) : ''}
                                </Text>
                            </Box>
                        </Stack>
                    )}
            </Stack>
        </Box>
    );
};

DoorsHeader.displayName = 'DoorsHeader';
