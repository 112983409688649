import React from 'react';
import { useMapContext } from '../context';
import { ServiceLocator } from 'app/ioc';
import { LeafletItemFactory } from '../../services';
import { useSelector } from 'react-redux';
import { getIsChildDevice, getItemColor } from 'app/modules/common';
import type { IInstallationPointModel, IPersistence, Id } from 'app/core/persistence';
import { getParentId } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import type { Colors } from 'app/styles';
import { getRotatedInstallationPointOnFloorPlan } from '../../selectors';

interface INumberedMarker {
    installationPointId: Id;
    markerLabels: Record<Id, string>;
    floorPlanId: Id;
}
/**
 * Renders a numbered static marker for an installation point.
 * @installationPointId The installation point to render
 *
 * @example
 * <MapProvider floorPlan={floorPlan}}>
 *    {installationPoints.map((ip) => (
 *       <NumberedMarker installationPointId={installationPoint._id} markerLabels={markerLabels} key={installationPoint._id} />
 *   ))}
 * </MapProvider>
 */
export const NumberedMarker: React.FC<INumberedMarker> = React.memo(
    ({ installationPointId, markerLabels, floorPlanId }) => {
        const { leafletMap } = useMapContext();
        const [factory] = React.useState(ServiceLocator.get(LeafletItemFactory));

        const installationPoint = useSelector<
            IStoreState,
            IPersistence<IInstallationPointModel> | undefined
        >((state) =>
            getRotatedInstallationPointOnFloorPlan(state, floorPlanId, installationPointId),
        );

        const itemId = installationPoint ? getParentId(installationPoint) : undefined;
        const color = useSelector<IStoreState, Colors>((state) => getItemColor(state, itemId));
        const ipIndex = markerLabels[installationPointId];
        const isChildDevice = useSelector<IStoreState, boolean>((state) =>
            getIsChildDevice(state, itemId),
        );

        const [marker] = React.useState(
            factory.createStaticItem(
                installationPoint?.location,
                factory.createNumberedIcon(ipIndex, color, isChildDevice),
            ),
        );

        /** Update marker on changes */
        React.useEffect(() => {
            marker.setIcon(factory.createNumberedIcon(ipIndex, color, isChildDevice));
        }, [color, factory, installationPoint, ipIndex, isChildDevice, marker]);

        /** Add/remove installation point to map */
        React.useEffect(() => {
            marker.addTo(leafletMap.map);

            return () => {
                marker.removeFrom(leafletMap.map);
            };
        }, [marker, leafletMap.map, ipIndex]);

        return null;
    },
);

NumberedMarker.displayName = 'NumberedMarker';
