import * as React from 'react';
import { Heading, Stack, Box, OverlayPanel, Spacer } from 'app/components';
import type { IStoreState } from 'app/store';
import type { IRecordingItemStats, VendorNameType } from '../selectors';
import {
    getRecordingSolutionTotalSpecs,
    getRecordingSolutionRequirements,
    getSelectedRecordingAccessories,
    isPureCompanionSystem,
    getSelectedVendorName,
} from '../selectors';
import type { IItemEntity, IPersistence, Id } from 'app/core/persistence';
import { connect, useSelector } from 'react-redux';
import { ProjectRequirements } from './ProjectRequirements.component';
import { ServiceLocator } from 'app/ioc';
import {
    CommonActionService,
    getCurrentProjectLocked,
    getCurrentSolutionSortedByRecorderCategory,
    getOverlayPanelOpen,
} from 'app/modules/common';
import { AccessoriesSelector } from './AccessoriesSelector.container';
import { CustomizeSolutionTabs } from './CustomizeSolutionTabs.container';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { RecorderNetworkSettings } from './RecorderNetworkSettings';
import { GenetecRecommendModal } from './genetecRecommendations';
import { VendorCard } from './VendorCard';
import { SelectedSolutionTable } from './SelectedSolutionTable';
import { getSelectedSolutionHeader } from '../selectors/getSelectedSolutionHeader';
import { InfoArea } from './InfoArea';

interface IRecordingSelectorProps {
    recordingSolution: IPersistence<IItemEntity>[];
    recordingAccessories: IPersistence<IItemEntity>[];
    totalSpecs: IRecordingItemStats;
    requirements: IRecordingItemStats;
    showProductSelector: boolean;
    selectedVendorName: VendorNameType | undefined;
    selectedSolutionHeader: string;
    selectedDeviceDetails: Id | undefined;
    ItemIdForNetworkSettings?: Id;
    panelOpen: boolean;
    isLocked: boolean;
    disableLicenses: boolean;
}

const mapStateToProps = (storeState: IStoreState): IRecordingSelectorProps => {
    return {
        recordingSolution: getCurrentSolutionSortedByRecorderCategory(storeState),
        recordingAccessories: getSelectedRecordingAccessories(storeState),
        totalSpecs: getRecordingSolutionTotalSpecs(storeState),
        requirements: getRecordingSolutionRequirements(storeState),
        showProductSelector: storeState.recordingSelector.showProductSelector,
        selectedVendorName: getSelectedVendorName(storeState),
        selectedSolutionHeader: getSelectedSolutionHeader(storeState),
        selectedDeviceDetails: storeState.recordingSelector.selectedDeviceDetails,
        ItemIdForNetworkSettings: storeState.recordingSelector.ItemIdForNetworkSettings,
        panelOpen: getOverlayPanelOpen(storeState),
        isLocked: getCurrentProjectLocked(storeState),
        disableLicenses: isPureCompanionSystem(storeState),
    };
};

const RecordingSelectorContainer: React.FunctionComponent<IRecordingSelectorProps> = ({
    recordingSolution,
    selectedDeviceDetails,
    selectedSolutionHeader,
    showProductSelector,
    panelOpen,
    isLocked,
    ItemIdForNetworkSettings,
}) => {
    const smallOverlay = useSelector<IStoreState>((state) => state.recordingSelector.smallOverlay);
    const [genetecModal, setGenetecModal] = React.useState(false);

    const [commonActionService] = React.useState<CommonActionService>(
        ServiceLocator.get(CommonActionService),
    );

    const hidePanel = () => {
        commonActionService.showOverlayPanel(false);
    };

    const toggleGenetecRecommendModal = () => {
        eventTracking.logUserEvent('System Components', 'Toggle Genetec Recommended modal');
        setGenetecModal(!genetecModal);
    };

    return (
        <>
            <Box
                testId="panel_recording"
                height="100%"
                position="relative"
                overflowX="hidden"
                justifyContent="center"
            >
                <OverlayPanel
                    active={panelOpen}
                    maxWidth={smallOverlay ? '63%' : '90%'}
                    minWidth={smallOverlay ? '700px' : '900px'}
                    onInactivate={hidePanel}
                >
                    {showProductSelector && <CustomizeSolutionTabs />}
                    {selectedDeviceDetails && <AccessoriesSelector />}
                    {ItemIdForNetworkSettings && <RecorderNetworkSettings />}
                </OverlayPanel>
                <Box
                    position="relative"
                    width="100%"
                    height="100%"
                    overflowY="auto"
                    padding="doublePanel"
                    display="tableCell"
                >
                    <Stack vertical spacing="base">
                        <Stack vertical alignItems="center">
                            <Heading style="title" color="grey5">
                                {t.projectRequirements}
                            </Heading>
                            <ProjectRequirements fullWidth />
                        </Stack>
                        {!isLocked && (
                            <Box direction="column" alignItems="center" spacing="base">
                                <InfoArea />
                                {recordingSolution.length === 0 && (
                                    <Heading style="title" color="grey5">
                                        {t.chooseRecordingSolution}
                                    </Heading>
                                )}
                                {recordingSolution.length === 0 && (
                                    <Box justifyContent="center" spacing="cell">
                                        <VendorCard vendor="Axis" />
                                        <VendorCard vendor="Genetec" />
                                        <VendorCard vendor="Milestone" />
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Stack vertical alignItems="center" flex="dontShrink">
                            {recordingSolution.length > 0 && (
                                <Box direction="column" spacing="base">
                                    <Heading style="title" color="grey5" align="center">
                                        {selectedSolutionHeader}
                                    </Heading>
                                    <SelectedSolutionTable />
                                </Box>
                            )}
                            <Spacer />
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            {genetecModal && (
                <GenetecRecommendModal
                    title={t.genetecGROUP.whatWouldGenetecRecommend}
                    onClose={toggleGenetecRecommendModal}
                />
            )}
        </>
    );
};

export const RecordingSelector = connect(mapStateToProps)(RecordingSelectorContainer);

RecordingSelectorContainer.displayName = 'RecordingSelector';
