import type { PiaId, IPiaCamera, IPiaSensorUnit, IPiaRelationPropertyItem } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import type { IItemEntity, IPersistence } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { getPiaItemsRecord, getPiaCameraForProductId } from './getPiaDevices';
import { toCacheKey } from '../../cacheKey';

const DEFAULT_ASPECT_RATIO = 16 / 9;

interface IFOVLimit {
    min: number;
    max: number;
}

export interface IFOVLimits {
    horizontal: IFOVLimit;
    vertical: IFOVLimit;
}

export const estimateVerticalFOV = (horizontalFOV: number, aspectRatio = DEFAULT_ASPECT_RATIO) =>
    (Math.atan(Math.tan((horizontalFOV * Math.PI) / 360) / aspectRatio) * 360) / Math.PI;

const estimateVerticalFOVLimit = (
    horizontalFOVLimit: IFOVLimit,
    aspectRatio: number,
): IFOVLimit => ({
    min: estimateVerticalFOV(horizontalFOVLimit.min, aspectRatio),
    max: estimateVerticalFOV(horizontalFOVLimit.max, aspectRatio),
});

const getProductIdFromProps = (_state: IStoreState, productId: PiaId | null | undefined) =>
    productId;

// productId must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getRelatedProductId = (
    _state: IStoreState,
    _productId: PiaId | null | undefined,
    relatedProductId: PiaId | null | undefined,
) => relatedProductId;

/**
 * Get properties of the relation between two pia items
 */
const getPiaRelationProperties = createCachedSelector(
    [getPiaItemsRecord, getProductIdFromProps, getRelatedProductId],
    (piaItems, productId, relatedProductId) => {
        if (!productId) return undefined;

        const piaItem = piaItems[productId];
        const relation = piaItem.relations.find(({ id }) => id === relatedProductId);

        return relation?.relationProperties;
    },
)(toCacheKey);

const getPiaCamera = (state: IStoreState, productId: PiaId | null | undefined) =>
    productId ? getPiaCameraForProductId(state, productId) : undefined;

export const calculateFOVLimits = (
    piaCamera: IPiaCamera | IPiaSensorUnit | undefined,
    relationProps: IPiaRelationPropertyItem | undefined,
    parentDevice?: IPersistence<IItemEntity>,
): IFOVLimits => {
    if (relationProps?.horizontalFOV && piaCamera) {
        const aspectRatio =
            piaCamera.properties.maxVideoResolutionHorizontal /
            piaCamera.properties.maxVideoResolutionVertical;

        return {
            horizontal: {
                min: relationProps.horizontalFOV.min,
                max: relationProps.horizontalFOV.max,
            },
            vertical:
                relationProps.verticalFOV ??
                estimateVerticalFOVLimit(relationProps.horizontalFOV, aspectRatio),
        };
    } else if (piaCamera) {
        // see https://confluence.se.axis.com/display/WEBTOOLS/Panoramic+cameras
        const shouldUseLensCalcFov = piaCamera.properties.panCameraType === 'Multidirectional';

        return {
            horizontal: {
                min:
                    (shouldUseLensCalcFov && piaCamera.properties.minLensCalcFOV) ||
                    piaCamera.properties.minHorizontalFOV,
                max:
                    (shouldUseLensCalcFov && piaCamera.properties.maxLensCalcFOV) ||
                    piaCamera.properties.maxHorizontalFOV,
            },
            vertical: {
                min: piaCamera.properties.minVerticalFOV,
                max: piaCamera.properties.maxVerticalFOV,
            },
        };
    } else if (parentDevice && isCustomCamera(parentDevice)) {
        const { horizontalFovMin, horizontalFovMax, resolutionHorizontal, resolutionVertical } =
            parentDevice.properties.camera.customCameraProperties;
        const aspectRatio = resolutionHorizontal / resolutionVertical;

        return {
            horizontal: {
                min: horizontalFovMin,
                max: horizontalFovMax,
            },
            vertical: estimateVerticalFOVLimit(
                {
                    min: horizontalFovMin,
                    max: horizontalFovMax,
                },
                aspectRatio,
            ),
        };
    } else {
        return {
            horizontal: {
                min: 0,
                max: 180,
            },
            vertical: {
                min: 0,
                max: 180,
            },
        };
    }
};

/**
 * Get the FOV limit of a camera with an optional additional lens
 */
export const getFovLimits = createSelector(
    [getPiaCamera, getPiaRelationProperties],
    calculateFOVLimits,
);
