import { Box, Icon, PiaImage, Stack, Text } from 'app/components';
import type { PiaId } from 'app/core/pia';
import { cropTextMiddle } from 'app/modules/common';
import React from 'react';

interface IDeviceWithLensProps {
    mainDevicePiaId: PiaId;
    lensName?: string;
    lensPiaId?: PiaId;
}

export const DeviceWithLens: React.FC<IDeviceWithLensProps> = ({
    lensPiaId,
    mainDevicePiaId,
    lensName,
}) => (
    <Box
        justifyContent="center"
        alignItems="center"
        direction="column"
        minWidth="135px"
        height="80px"
    >
        <PiaImage icon="device" piaId={mainDevicePiaId} imageSize={lensPiaId ? 'xl' : 'xxl'} />
        {lensPiaId && (
            <Box maxWidth="135px">
                <Stack spacing="none" justifyContent="center" alignItems="center">
                    <Icon icon="add" size="sm" />
                    <PiaImage piaId={lensPiaId ?? null} icon="lens" imageSize="md" />
                    <Box paddingLeft="quart" maxWidth="100px">
                        {lensName && (
                            <Text style="semibold" whiteSpace="normal">
                                {cropTextMiddle(lensName, 30)}
                            </Text>
                        )}
                    </Box>
                </Stack>
            </Box>
        )}
    </Box>
);

DeviceWithLens.displayName = 'DeviceWithLens';
