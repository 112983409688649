import { createCachedSelector } from 're-reselect';
import type { IRecordingSettingsModel } from 'app/core/persistence';
import { mapToRecordingSettingsModel, Resolution } from 'app/core/persistence';
import { getRecordingType } from './getRecordingType';
import { getProfile } from './getProfiles';
import { getAssociatedProfile, getProfileOverride } from '../../item';
import { toCacheKey } from '../../cacheKey';
import { getProfileIdFromProps } from '../../selectors';

export const getDeviceRecordingSettings = createCachedSelector(
    [getAssociatedProfile, getProfileIdFromProps, getRecordingType],
    (profile, _profileId, recordingType): IRecordingSettingsModel | undefined => {
        if (!profile) {
            return undefined;
        }

        return mapToRecordingSettingsModel(profile[recordingType]);
    },
)(toCacheKey);

export const getProfileRecordingSettings = createCachedSelector(
    [getProfile, getProfileIdFromProps, getRecordingType],
    (profile, _profileId, recordingType): IRecordingSettingsModel | undefined => {
        if (!profile) {
            return undefined;
        }

        return mapToRecordingSettingsModel(profile[recordingType]);
    },
)(toCacheKey);

export const getRecordingSettingsOverride = createCachedSelector(
    [getProfileOverride, getProfileIdFromProps, getRecordingType],
    (profileOverride, _profileId, recordingType): Partial<IRecordingSettingsModel> | undefined => {
        if (!profileOverride) {
            return undefined;
        }
        const overrideResolution = profileOverride[recordingType].resolution;
        return {
            ...profileOverride[recordingType],
            resolution: overrideResolution ? new Resolution(overrideResolution) : undefined,
        };
    },
)(toCacheKey);
