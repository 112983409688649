import { injectable } from 'inversify';
import { eventTracking } from 'app/core/tracking';
import type { IStreamVaultJSONPayload } from './IStreamVaultJSONPayload';

const GENETEC_RECOMMENDATIONS_URL = '/svcalculator/recommendations';
const GENETEC_PROJECT_URL = '/svcalculator/projects';

const GENETEC_GET_PRODUCTS_URL = '/svcalculator/products';

@injectable()
export class GenetecRecommendationCommunicator {
    constructor() {}
    public async postGetGenetecRecommendations(
        genetecJson: IStreamVaultJSONPayload,
    ): Promise<Response | null> {
        try {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json; charset=utf-8');

            const response = await fetch(GENETEC_RECOMMENDATIONS_URL, {
                method: 'POST',
                headers,
                body: JSON.stringify(genetecJson),
            });

            if (!response.ok) {
                eventTracking.logError(
                    response.statusText,
                    GENETEC_RECOMMENDATIONS_URL,
                    response.status,
                );
            }

            return response;
        } catch (e) {
            eventTracking.logError(
                'Failed to get recommendations from genetec',
                'GenetecRecommendationCommunicator',
            );
            return null;
        }
    }

    public async postGetGenetecProject(
        genetecJson: IStreamVaultJSONPayload,
    ): Promise<Response | null> {
        try {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json; charset=utf-8');

            const response = await fetch(GENETEC_PROJECT_URL, {
                method: 'POST',
                headers,
                body: JSON.stringify(genetecJson),
            });

            if (!response.ok) {
                eventTracking.logError(response.statusText, GENETEC_PROJECT_URL, response.status);
            }

            return response;
        } catch (e) {
            eventTracking.logError(
                'Failed to post to genetec project',
                'GenetecRecommendationCommunicator',
            );
            return null;
        }
    }

    public async getGenetecProducts(countryCode: string): Promise<Response | null> {
        try {
            const headers = new Headers();
            headers.append('Access-Control-Allow-Origin', '*');

            const url = `${GENETEC_GET_PRODUCTS_URL}?countryCode=${countryCode}`;

            const response = await fetch(url, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                eventTracking.logError(response.statusText, url, response.status);
                return null;
            }

            return response;
        } catch (e) {
            eventTracking.logError(
                'Failed to upload_get_genetec_recommendations',
                'GenetecRecommendationCommunicator',
            );
            return null;
        }
    }
}
