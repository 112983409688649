import { injectable } from 'inversify';
import type { IScheduleModel, ITimeSerieModel } from 'app/core/persistence';
import type { IAggregatedScheduleModel, ISegmentModel } from '../../models';

@injectable()
export class ScheduleAggregationService {
    /**
     * Returns an aggregated schedule where all time series are joined and merged into
     * weekdays.
     */
    public getAggregatedSchedule(schedule: IScheduleModel): IAggregatedScheduleModel {
        const aggregatedSchedule: IAggregatedScheduleModel = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        };

        // // Get only series belonging to this schedule, not the inverted, which is a child to a schedule
        const nonInvertedTimeSeries = schedule.timeSeries.filter((ts) => !ts.isInverted);
        nonInvertedTimeSeries.forEach((serie) => {
            serie !== undefined && this.aggregateDays(serie, aggregatedSchedule);
        });

        return aggregatedSchedule;
    }

    private aggregateDays(
        timeSerie: ITimeSerieModel,
        aggregatedSchedule: IAggregatedScheduleModel,
    ) {
        if (timeSerie.days.hasMonday()) {
            aggregatedSchedule.monday = this.aggregateDay(timeSerie, aggregatedSchedule.monday);
        }

        if (timeSerie.days.hasTuesday()) {
            aggregatedSchedule.tuesday = this.aggregateDay(timeSerie, aggregatedSchedule.tuesday);
        }

        if (timeSerie.days.hasWednesday()) {
            aggregatedSchedule.wednesday = this.aggregateDay(
                timeSerie,
                aggregatedSchedule.wednesday,
            );
        }

        if (timeSerie.days.hasThursday()) {
            aggregatedSchedule.thursday = this.aggregateDay(timeSerie, aggregatedSchedule.thursday);
        }

        if (timeSerie.days.hasFriday()) {
            aggregatedSchedule.friday = this.aggregateDay(timeSerie, aggregatedSchedule.friday);
        }

        if (timeSerie.days.hasSaturday()) {
            aggregatedSchedule.saturday = this.aggregateDay(timeSerie, aggregatedSchedule.saturday);
        }

        if (timeSerie.days.hasSunday()) {
            aggregatedSchedule.sunday = this.aggregateDay(timeSerie, aggregatedSchedule.sunday);
        }
    }

    private aggregateDay(
        timeSerie: ITimeSerieModel,
        aggregatedSegments: ISegmentModel[],
    ): ISegmentModel[] {
        const start = timeSerie.start.toNumber();
        const end = timeSerie.end.toNumber();
        let segments: ISegmentModel[];

        if (start > end) {
            // Time serie is inverted
            segments = [
                { start: 0, end },
                { start, end: 24 },
            ];
        } else {
            segments = [{ start, end }];
        }

        segments = segments.concat(aggregatedSegments);
        segments = this.sortSegments(segments);
        return this.mergeOverlappingSegments(segments);
    }

    private sortSegments(segments: ISegmentModel[]) {
        return segments.sort((a, b) =>
            // If start is the same for both
            a.start === b.start
                ? // Sort by end, latest last
                  a.end - b.end
                : // Otherwise by start, earliest first
                  a.start - b.start,
        );
    }

    private mergeOverlappingSegments(segments: ISegmentModel[]) {
        return segments.reduce((newSegments: ISegmentModel[], segment: ISegmentModel) => {
            if (newSegments.length === 0) {
                newSegments.push(segment);
            } else {
                if (this.isSegmentBetweenSegments(segment, newSegments)) {
                    this.joinSegments(segment, newSegments);
                } else {
                    newSegments.push(segment);
                }
            }

            return newSegments;
        }, []);
    }

    private isSegmentBetweenSegments(
        segment: ISegmentModel,
        aggregatedSegments: ISegmentModel[],
    ): boolean {
        const lastSegment = this.getLastSegment(aggregatedSegments);

        return segment.start >= lastSegment.start && segment.start <= lastSegment.end;
    }

    private joinSegments(segment: ISegmentModel, aggregatedSegments: ISegmentModel[]) {
        const lastSegment = this.getLastSegment(aggregatedSegments);
        lastSegment.end = Math.max(segment.end, lastSegment.end);
    }

    private getLastSegment(segments: ISegmentModel[]): ISegmentModel {
        return segments[segments.length - 1];
    }
}
