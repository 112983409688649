import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';

interface IResolutionFilterProps {
    selectedResolution?: string;
    onClick(rating: string): void;
}

export const ResolutionFilter: React.FC<IResolutionFilterProps> = ({
    selectedResolution,
    onClick,
}) => {
    return (
        <DropDown
            trigger={
                <PillButton
                    icon="keyboard_arrow_down"
                    selected={!!selectedResolution}
                    text={`${t.advancedFiltersGROUP.minResolution}${
                        selectedResolution ? `: ${selectedResolution}` : ''
                    }`}
                />
            }
            contents={
                <>
                    <DropDownMenuButton
                        label="2160p (4K)"
                        selected={selectedResolution === '2160p'}
                        onClick={() => onClick('2160p')}
                    />
                    <DropDownMenuButton
                        label="5MP"
                        selected={selectedResolution === '5MP'}
                        onClick={() => onClick('5MP')}
                    />
                    <DropDownMenuButton
                        label="4MP"
                        selected={selectedResolution === '4MP'}
                        onClick={() => onClick('4MP')}
                    />
                    <DropDownMenuButton
                        label="3MP"
                        selected={selectedResolution === '3MP'}
                        onClick={() => onClick('3MP')}
                    />
                    <DropDownMenuButton
                        label="1080p (2MP)"
                        selected={selectedResolution === '1080p'}
                        onClick={() => onClick('1080p')}
                    />
                    <DropDownMenuButton
                        label="720p (1MP)"
                        selected={selectedResolution === '720p'}
                        onClick={() => onClick('720p')}
                    />
                    <DropDownMenuButton
                        label="VGA"
                        selected={selectedResolution === 'VGA'}
                        onClick={() => onClick('VGA')}
                    />
                </>
            }
        />
    );
};

ResolutionFilter.displayName = 'ResolutionFilter';
