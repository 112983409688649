import * as React from 'react';
export interface IAuthContextProps {
    idToken?: string;
    name?: string;
    getAccessToken: () => Promise<string>;
    reauthorize: () => void;
}

export const AuthContext = React.createContext<IAuthContextProps>({
    getAccessToken: async () => '',
    reauthorize: () => {},
});

export const useAuth = (): IAuthContextProps => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthConsumer = ({ children }: React.ConsumerProps<IAuthContextProps>) => (
    <AuthContext.Consumer>
        {(context) => {
            if (context === undefined) {
                throw new Error('AuthConsumer must be used within a AuthProvider');
            }
            return children(context);
        }}
    </AuthContext.Consumer>
);

AuthContext.displayName = 'AuthContext';
