import { injectable } from 'inversify';
import { eventTracking } from 'app/core/tracking';
import { toaster } from 'app/toaster';
import { t } from 'app/translate';
import type { IPartnerProductResponse } from 'app/modules/common';

const FETCH_PARTNER_PRODUCTS_URL = '/fetch-partner-products';

@injectable()
export class FetchPartnerProductsCommunicator {
    public async fetchAllPartnerProducts(
        key: string | null,
    ): Promise<IPartnerProductResponse | null> {
        const url = key
            ? `${FETCH_PARTNER_PRODUCTS_URL}?&next=${key}`
            : `${FETCH_PARTNER_PRODUCTS_URL}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
            });

            if (!response.ok) {
                eventTracking.logError(response.statusText, url, response.status);
                this.handleNetworkError();
                return null;
            }

            return response.json();
        } catch (e) {
            eventTracking.logError(
                'Failed to fetchPartnerProducts',
                'FetchPartnerProductsCommunicator',
            );
            this.handleNetworkError();
            return null;
        }
    }

    private handleNetworkError(): void {
        const errorMessage = t.projectShareErrorUnknownNetworkError;

        toaster.error(t.errorLoadingPartnerApplication, errorMessage);
    }
}
