import * as React from 'react';
import { Box, Border, Text } from 'app/components';

interface IReportHeadingProps {
    title: string;
}

export const ReportHeading: React.FunctionComponent<IReportHeadingProps> = ({ title }) => (
    <Border bottomWidth={2} color="yellow">
        <Box paddingY="quart" paddingX="half">
            <Text style="semibold">{title}</Text>
        </Box>
    </Border>
);

ReportHeading.displayName = 'ReportHeading';
