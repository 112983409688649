import { convertDensityToMeters, doriPixelLimitsFeet, doriPixelLimitsMeter } from '../../common';
import type {
    UnitSystem,
    PanoramaModes,
    ICameraPropertiesFilterEntity,
} from 'app/core/persistence';

import { DistanceUnit } from 'app/core/persistence/models/DistanceUnit';
import { convert } from 'axis-webtools-util';

const TargetHeight = {
    metric: 2.5,
    imperial: convert.feetToMeters(8.0),
};

const DefaultDistance = {
    metric: 7,
    imperial: convert.feetToMeters(23.0),
};
const DefaultHorizontalFov = 55;

export function getDefaultCameraFilterEntity(
    unit: UnitSystem = 'metric',
    installationHeight: number,
    panoramaMode?: PanoramaModes,
): ICameraPropertiesFilterEntity {
    return {
        horizontalFov: DefaultHorizontalFov,
        installationHeight,
        corridorFormat: false,
        pixelDensity:
            unit === 'imperial'
                ? convertDensityToMeters(doriPixelLimitsFeet.DORI_LIMIT_DETECT, DistanceUnit.Feet)
                : doriPixelLimitsMeter.DORI_LIMIT_DETECT,
        lightConditions: [],
        cameraTypes: [],
        sensorUnitTypes: [],
        distanceToTarget: DefaultDistance[unit],
        targetHeight: TargetHeight[unit],
        outdoor: false,
        panoramaMode: panoramaMode ?? false,
        applications: [],
        isSceneFilterActive: false,
        isFilterChanged: false,
    };
}
