import { EventEmitter } from 'events';
import PouchDB from 'pouchdb-browser';
import { PiaItemPouchDbRepository } from './repositories/PiaItemPouchDb.repository';
import { PiaLocationPouchDbRepository } from './repositories/PiaLocationPouchDb.repository';
import { PiaRelationsPouchDbRepository } from './repositories/PiaRelationsPouchDb.repository';
import { PiaItemService } from './services/PiaItem.service';
import { PiaLocationService } from './services/PiaLocation.service';
import { PiaRelationService } from './services/PiaRelation.service';
import type {
    IPiaItem,
    IPiaLocation,
    IPiaContext,
    IPiaDocumentRepository,
    IPiaRelationRepository,
} from './types';

export enum PiaClientEvents {
    Initializing = 'initializing',
    Ready = 'ready',
    Change = 'change',
    Progress = 'progress',
    NewItems = 'newItems',
    InitialReplicationStart = 'initialReplicationStart',
    InitialReplicationError = 'initialReplicationError',
    InitialReplicationComplete = 'initialReplicationComplete',
    ContinuousReplicationError = 'continuousReplicationError',
}

enum PiaClientLifecycleState {
    Idle = 0,
    Initializing,
    Ready,
}

export interface IPiaClientOptions {
    remoteDatabaseUrl: string;
    databaseName: string;
}

// Sync PIA once every hour
const PIA_SYNC_INTERVAL = 1000 * 60 * 60;

// How many document to process at the same time
const BATCH_SIZE = 300;

export class PiaClient<
    TItem extends IPiaItem,
    TLocation extends IPiaLocation,
> extends EventEmitter {
    public initialized!: Promise<{ documentCount: number }>;
    public initialReplicationPromise!: Promise<void>;

    private lifecycleState!: PiaClientLifecycleState;

    private localDb!: PouchDB.Database<TItem | TLocation>;
    private remoteDb!: PouchDB.Database<TItem | TLocation>;
    private ctx: IPiaContext<TItem, TLocation>;

    private itemRepository!: IPiaDocumentRepository<TItem>;
    private locationRepository!: IPiaDocumentRepository<TLocation>;
    private relationsRepository!: IPiaRelationRepository;

    private itemsService: PiaItemService<TItem>;
    private locationsService: PiaLocationService<TLocation>;
    private relationService: PiaRelationService;

    constructor(private options: IPiaClientOptions) {
        super();
        this.setLifecycleState(PiaClientLifecycleState.Idle);

        const voidCtx: IPiaContext<TItem, TLocation> = (this.ctx = {
            items: new Map<number, TItem>(),
            locations: [],
            relationsGraph: [],
        });

        this.itemsService = new PiaItemService<TItem>(voidCtx);
        this.locationsService = new PiaLocationService<TLocation>(voidCtx);
        this.relationService = new PiaRelationService(voidCtx);
    }

    public get items(): PiaItemService<TItem> {
        return this.itemsService;
    }

    public get locations(): PiaLocationService<TLocation> {
        return this.locationsService;
    }

    public get relations(): PiaRelationService {
        return this.relationService;
    }

    public async initialize(): Promise<{ documentCount: number }> {
        if (this.lifecycleState !== PiaClientLifecycleState.Idle) {
            throw new Error('PiaClient is already initialized');
        }

        // eslint-disable-next-line no-async-promise-executor
        this.initialized = new Promise(async (resolve, reject) => {
            this.setLifecycleState(PiaClientLifecycleState.Initializing);
            this.initializeStorage();
            this.initializeRepositories();

            let dbInfo;

            try {
                // Can fail in FF Private Browsing Mode
                [dbInfo] = await Promise.all([this.localDb.info(), this.updatePiaContext()]);
            } catch (e) {
                return reject(e);
            }

            const status = { documentCount: dbInfo.doc_count };

            this.setLifecycleState(PiaClientLifecycleState.Ready, status);

            this.initializeInitialReplication().then(() => this.initializeContinuousReplication());

            resolve(status);
        });

        return this.initialized;
    }

    private initializeStorage() {
        this.remoteDb = new PouchDB(this.options.remoteDatabaseUrl, {
            skip_setup: true,
        });

        this.localDb = new PouchDB(this.options.databaseName, {
            revs_limit: 0,
            auto_compaction: true,
        });
    }

    private initializeRepositories() {
        const localDb = this.localDb;

        this.itemRepository = new PiaItemPouchDbRepository(localDb);
        this.locationRepository = new PiaLocationPouchDbRepository(localDb);
        this.relationsRepository = new PiaRelationsPouchDbRepository(localDb);
    }

    private initializeInitialReplication(): Promise<void> {
        this.emit(PiaClientEvents.InitialReplicationStart);

        this.initialReplicationPromise = this.localDb.replicate
            .from(this.remoteDb, {
                batch_size: BATCH_SIZE,
            })
            .on('change', (info) => {
                const progress = info.docs_written / (info.docs_written + info.pending);

                this.emit(PiaClientEvents.Progress, progress);
            })
            .then(
                () => {
                    this.emit(PiaClientEvents.InitialReplicationComplete);
                    return this.updatePiaContext();
                },
                (error) => {
                    this.emit(PiaClientEvents.InitialReplicationError, error);
                },
            );

        return this.initialReplicationPromise;
    }

    private initializeContinuousReplication() {
        /**
         * Since PIA data only changes once per day and on every deploy it very unnecessary to
         * rely on PouchDBs ordinary syncing feature which expects changes to happen much more
         * often.
         * Instead we replace the syncing here with our own manually triggered sync on a set
         * interval. This solution removes a lot of unnecessary HTTP calls performed by
         * PouchDB for checking PIA data.
         */
        setInterval(() => {
            const replication = this.localDb.replicate.from<TItem | TLocation>(this.remoteDb);

            replication.on('error', (error) =>
                this.emit(PiaClientEvents.ContinuousReplicationError, error),
            );
            replication.on('change', async (info) => {
                const newDocs = info.docs.filter((doc) => this.ctx.items.has(doc.id));

                await this.updatePiaContext();

                if (newDocs.length > 0) {
                    this.emit(PiaClientEvents.NewItems, newDocs);
                }
            });
        }, PIA_SYNC_INTERVAL);
    }

    private setLifecycleState(state: PiaClientLifecycleState, eventData?: any) {
        this.lifecycleState = state;

        switch (state) {
            case PiaClientLifecycleState.Initializing:
                this.emit(PiaClientEvents.Initializing);
                break;
            case PiaClientLifecycleState.Ready:
                this.emit(PiaClientEvents.Ready, eventData);
                break;
        }
    }

    private async updatePiaContext(): Promise<void> {
        const ctx = (this.ctx = await this.generatePiaContext());

        this.itemsService.setContext(ctx);
        this.locationsService.setContext(ctx);
        this.relationService.setContext(ctx);
        this.emit(PiaClientEvents.Change);
    }

    private async generatePiaContext(): Promise<IPiaContext<TItem, TLocation>> {
        const [items, locations, relationsGraph] = await Promise.all([
            this.itemRepository.getAll(),
            this.locationRepository.getAll(),
            this.relationsRepository.getRelationsGraph(),
        ]);

        return {
            items: new Map(items.map((item): [number, TItem] => [item.id, item])),
            locations,
            relationsGraph,
        };
    }
}
