function addPrefix(actionName: string) {
    return `SYSTEM_ACCESSORIES_${actionName}`;
}

export const SystemAccessoriesActions = {
    ShowCurrentSelection: addPrefix('SHOW_CURRENT_SELECTION'),
    ShowSearchResult: addPrefix('SHOW_SEARCH_RESULT'),
    ShowAllAccessories: addPrefix('SHOW_ALL_ACCESSORIES'),
    ShowCategory: addPrefix('SHOW_ACCESSORY_CATEGORY'),
    SetSearchText: addPrefix('SEARCH'),
};
