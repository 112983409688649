import * as React from 'react';
import { Grid } from 'app/components';
import type { ILens } from '../models';
import { StandardLens } from './StandardLens';
import { Lens } from './Lens.component';
import type { PiaId } from 'app/core/pia';

interface ILensGrid {
    lenses: ILens[];
    disabled: boolean;
    sensorIndex: number;
    readOnly: boolean;
    standardLensName: string;
    hasNotToBeFollowedByAccessory: (lensPiaId: PiaId) => boolean;
    getProductNamesNotToBeFollowedBy: (lensPiaId: PiaId) => string;
    onSelect(lens: ILens): void;
    onClear(sensorIndex: number): void;
}

export const LensGrid: React.FC<ILensGrid> = ({
    lenses,
    onSelect,
    onClear,
    disabled,
    readOnly,
    sensorIndex,
    standardLensName,
    hasNotToBeFollowedByAccessory,
    getProductNamesNotToBeFollowedBy,
}) => {
    const [isStandardSelected, setIsStandardSelected] = React.useState<boolean>(true);
    React.useEffect(() => {
        setIsStandardSelected(lenses.every((lens) => lens.id === undefined));
    }, [lenses]);
    const handleClear = React.useCallback(() => {
        onClear(sensorIndex);
    }, [sensorIndex, onClear]);
    return (
        <Grid spacing="half">
            <StandardLens
                onSelect={handleClear}
                selected={isStandardSelected}
                disabled={disabled}
                readOnly={readOnly}
                standardLensName={standardLensName}
            />
            {lenses.map((lens) => (
                <Lens
                    key={`${lens.productId}_${lens.sensorIndex}`}
                    lens={lens}
                    disabled={disabled || hasNotToBeFollowedByAccessory(lens.productId)}
                    productNamesNotToBeFollowedBy={getProductNamesNotToBeFollowedBy(lens.productId)}
                    readOnly={readOnly}
                    onSelect={onSelect}
                />
            ))}
        </Grid>
    );
};

LensGrid.displayName = 'LensGridComponent';
