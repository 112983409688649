import * as React from 'react';
import { Box, PiaImage, Stack, Text, Positioned, Print, Icon } from 'app/components';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    getCurrentProjectItems,
    getIsLocalProject,
    getPiaItemsRecord,
    getUserSignedIn,
} from 'app/modules/common';

import { ProposalDeviceDetailedSpecification } from './ProposalDeviceDetailSpecification.container';
import { ProposalDeviceDetailSettings } from './PropsalDeviceDetailSettings.component';
import { ProductHighlights } from './ProductHighligts.component';
import { ReportHeader } from 'app/modules/reports/components/ReportHeader.container';
import type { IPiaItem } from 'app/core/pia';
import type {
    IInstallationBaseReportAccessory,
    IInstallationReportDoor,
    IProposalOrCustomDevice,
} from 'app/modules/reports';
import { getAllProposalDevices } from '../../selectors';
import { MiniMap } from 'app/modules/maps';
import { itemsPerPage } from '../../../utils/itemsPerPage';

interface IProposalDeviceDetailsProps {
    currentPage: number;
}

const getDoorAccessorySection = (door: IInstallationReportDoor) => {
    const joinDoorAccessories = (accessories: IInstallationBaseReportAccessory[]) =>
        accessories.map((accessory) => (accessory.piaId ? accessory.model : t.other)).join();
    let doorReaderSideA = joinDoorAccessories(door.doorSideA.reader);
    let doorReaderSideB = joinDoorAccessories(door.doorSideB.reader);
    let doorRexSideA = joinDoorAccessories(door.doorSideA.rex);
    let doorRexSideB = joinDoorAccessories(door.doorSideB.rex);
    doorReaderSideA = doorReaderSideA ? `${t.cardReader}: ${doorReaderSideA}` : doorReaderSideA;
    doorReaderSideB = doorReaderSideB ? `${t.cardReader}: ${doorReaderSideB}` : doorReaderSideB;
    doorRexSideA = doorRexSideA ? `${t.rexDevice}: ${doorRexSideA}` : doorRexSideA;
    doorRexSideB = doorRexSideB ? `${t.rexDevice}: ${doorRexSideB}` : doorRexSideB;

    const doorSideA =
        doorReaderSideA || doorRexSideA ? `${t.doorSideA}: ${doorReaderSideA} ${doorRexSideA}` : '';
    const doorSideB =
        doorReaderSideB || doorRexSideB ? `${t.doorSideB}: ${doorReaderSideB} ${doorRexSideB}` : '';

    return (
        <Stack spacing="halfCell" vertical>
            <Text>{doorSideA}</Text>
            <Text>{doorSideB}</Text>
        </Stack>
    );
};

export const ProposalDeviceDetails: React.FunctionComponent<IProposalDeviceDetailsProps> = ({
    currentPage,
}) => {
    const isLocalProject = useSelector(getIsLocalProject);
    const detailedDevicesList = useSelector(getAllProposalDevices);
    const showMiniMap = useSelector(getUserSignedIn) || isLocalProject;
    const currentProjectItems = useSelector(getCurrentProjectItems);
    const piaItemsRecord = useSelector(getPiaItemsRecord);

    return (
        <>
            {detailedDevicesList
                .slice((currentPage - 1) * itemsPerPage, itemsPerPage * currentPage)
                .map((item: IProposalOrCustomDevice, index) => {
                    const childUnitIds = [
                        ...item.sensorUnits,
                        ...item.analogCameras,
                        ...item.doors,
                        ...item.expansionModules,
                    ].map(({ id }) => id);
                    const bareboneId = currentProjectItems[item.id]?.replaceWithBareboneId;
                    const itemModel = bareboneId
                        ? (piaItemsRecord[bareboneId] as IPiaItem).name
                        : item.model;

                    return (
                        item && (
                            <Box
                                key={item.id}
                                pageBreakBefore={currentPage === 1 || index !== 0}
                                display="block"
                                paddingX="cell"
                            >
                                <Print display="flex">
                                    <ReportHeader title={t.systemProposal} />
                                </Print>
                                <Box justifyContent="center">
                                    <Stack spacing="none" vertical lineBetweenColor="grey4">
                                        <Box paddingY="panel" paddingX="base">
                                            <Stack
                                                justifyContent="between"
                                                flex="grow"
                                                width="100%"
                                            >
                                                <Stack spacing="base" flex="shrinkAndGrow">
                                                    <PiaImage
                                                        piaId={item.piaId}
                                                        // The only non pia items in system proposal are custom cameras, which should have the video cam icon
                                                        icon={item.piaId ? 'device' : 'videocam'}
                                                        imageSize="xxl"
                                                    />
                                                    <Stack spacing="halfCell" vertical>
                                                        <Text style="headline">{item.name}</Text>
                                                        <Text
                                                            style="title"
                                                            color="warmGrey6"
                                                        >{`${item.quantity} x ${itemModel}`}</Text>
                                                    </Stack>
                                                </Stack>
                                                <ProposalDeviceDetailedSpecification
                                                    testId={itemModel}
                                                    id={item.id}
                                                />
                                            </Stack>
                                        </Box>
                                        {showMiniMap && item.isOnMaps && (
                                            <Box overflow="hidden">
                                                <MiniMap
                                                    testId={`mini_map_proposal_detail`}
                                                    showName
                                                    noBorder
                                                    deviceIds={[item.id, ...childUnitIds]}
                                                />
                                            </Box>
                                        )}
                                        <Stack justifyContent="between">
                                            <Box>
                                                {(item.sensorUnits.length > 0 ||
                                                    item.accessories.length > 0 ||
                                                    item.doors.length > 0 ||
                                                    item.expansionModules.length > 0) && (
                                                    <Box paddingLeft="doublePanel" paddingY="base">
                                                        <Stack vertical>
                                                            {item.sensorUnits.length > 0 && (
                                                                <Stack vertical>
                                                                    {item.sensorUnits.map(
                                                                        (sensorUnit) => (
                                                                            <Stack
                                                                                key={sensorUnit.id}
                                                                            >
                                                                                <PiaImage
                                                                                    icon="sensor_unit"
                                                                                    imageSize="md"
                                                                                    piaId={
                                                                                        sensorUnit.piaId
                                                                                    }
                                                                                />
                                                                                <Text style="body">{`${sensorUnit.quantity} ${sensorUnit.model}`}</Text>
                                                                            </Stack>
                                                                        ),
                                                                    )}
                                                                </Stack>
                                                            )}
                                                            {item.expansionModules.length > 0 && (
                                                                <Stack vertical>
                                                                    {item.expansionModules.map(
                                                                        (expansionModule) => (
                                                                            <Stack
                                                                                key={
                                                                                    expansionModule.id
                                                                                }
                                                                            >
                                                                                <PiaImage
                                                                                    icon="add_box"
                                                                                    imageSize="md"
                                                                                    piaId={
                                                                                        expansionModule.piaId
                                                                                    }
                                                                                />
                                                                                <Text style="body">{`${expansionModule.quantity} ${expansionModule.model}`}</Text>
                                                                            </Stack>
                                                                        ),
                                                                    )}
                                                                </Stack>
                                                            )}
                                                            <Stack vertical>
                                                                {item.doors.length > 0 && (
                                                                    <Stack vertical>
                                                                        {item.doors.map((door) => (
                                                                            <Stack key={door.id}>
                                                                                <Box
                                                                                    alignItems="start"
                                                                                    height={'100%'}
                                                                                >
                                                                                    <Icon
                                                                                        icon="door_front"
                                                                                        size="md"
                                                                                        color="grey8"
                                                                                    />
                                                                                </Box>
                                                                                <Box
                                                                                    height={'100%'}
                                                                                    minWidth={100}
                                                                                    alignItems="start"
                                                                                >
                                                                                    <Text style="body">{`${door.name}`}</Text>
                                                                                </Box>
                                                                                {getDoorAccessorySection(
                                                                                    door.door,
                                                                                )}
                                                                            </Stack>
                                                                        ))}
                                                                    </Stack>
                                                                )}
                                                            </Stack>
                                                            {item.accessories.length > 0 && (
                                                                <Stack vertical>
                                                                    <Text style="heading">
                                                                        {t.accessories}
                                                                    </Text>
                                                                    {item.accessories.map(
                                                                        (accessory, i) => {
                                                                            return (
                                                                                <Text
                                                                                    testId={
                                                                                        itemModel +
                                                                                        '_' +
                                                                                        accessory.model
                                                                                    }
                                                                                    key={i}
                                                                                    style="body"
                                                                                >{`${accessory.quantity} ${accessory.model}`}</Text>
                                                                            );
                                                                        },
                                                                    )}
                                                                </Stack>
                                                            )}
                                                        </Stack>
                                                    </Box>
                                                )}
                                            </Box>

                                            <Positioned position="relative" top="-20px">
                                                <Box
                                                    paddingX="base"
                                                    paddingY="none"
                                                    height="fit-content"
                                                >
                                                    {item.piaId && (
                                                        <ProductHighlights productId={item.piaId} />
                                                    )}
                                                </Box>
                                            </Positioned>
                                        </Stack>
                                        <ProposalDeviceDetailSettings
                                            id={item.id}
                                            motionTriggeredFramerate={
                                                item.triggeredRecordingFrameRate
                                            }
                                            motionTriggeredResolution={
                                                item.triggeredRecordingResolution
                                            }
                                            motionTriggeredScheduleId={
                                                item.triggeredRecordingScheduleId || undefined
                                            }
                                            continuousRecordingScheduleId={
                                                item.continuousRecordingScheduleId || undefined
                                            }
                                            continuousRecordingFramerate={
                                                item.continuousRecordingFrameRate
                                            }
                                            continuousRecordingResolution={
                                                item.continuousRecordingResolution
                                            }
                                            retentionTime={item.retentionTime}
                                        />
                                    </Stack>
                                </Box>
                            </Box>
                        )
                    );
                })}
        </>
    );
};

ProposalDeviceDetails.displayName = 'ProposalDeviceDetails';
