import * as React from 'react';
import { useSelector } from 'react-redux';

import { getRecordingSolutionTypeFromProject } from 'app/modules/common';

import { showInfoForS30Limitations, isRecordingSolutionValid } from '../selectors';
import { S30SolutionInfo } from './S30SolutionInfo';
import { UpdateSolutionWarning } from './UpdateSolutionWarning';

/**
 * Shows warning if chosen Axis solution is no longer valid
 * If no warning is shown, show info about S30, if S30 selected (in that case UpdateSolutionWarning should not take up space)
 *  */
export const InfoArea: React.FC = () => {
    const selectedSolutionType = useSelector(getRecordingSolutionTypeFromProject);
    const isValidSolution = useSelector(isRecordingSolutionValid);

    /** Shows info about S30 limitations if S30 solution is selected */
    const showInfo = useSelector(showInfoForS30Limitations);

    /** Shows a warning if the chosen Axis solution (recommendation) is no longer valid */
    const showWarning =
        !isValidSolution &&
        selectedSolutionType &&
        selectedSolutionType !== 'Genetec' &&
        selectedSolutionType !== 'Milestone';

    return (
        <>
            {!showWarning && showInfo ? null : <UpdateSolutionWarning showWarning={showWarning} />}
            {showInfo && !showWarning && <S30SolutionInfo />}
        </>
    );
};

InfoArea.displayName = 'InfoArea';
