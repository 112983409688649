import * as React from 'react';
import { Text, Icon, Action, Box, PiaImage, DropDown, DropDownMenuButton } from 'app/components';
import { t } from 'app/translate';
import type { IPiaItemWithPrice } from 'app/modules/common';
import {
    deviceIcons,
    getCurrentProjectId,
    getNumberOfConnectableChildren,
    getChannelInformation,
    ChildItemService,
} from 'app/modules/common';
import { SpacingsEnum } from 'app/styles';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id, DeviceAndSubType } from 'app/core/persistence';
import { ParentChildInstallationPointService } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { getChildType, getIsExpanded } from '../../selectors';
import { useNavigate } from 'react-router-dom';
import { css, cx } from '@emotion/css';
import { getCompatibleExpansionModules } from 'app/modules/deviceSelector/selectors';
import type { IPiaItem } from 'app/core/pia';

const buttonBgStyle = {
    width: `${SpacingsEnum.base}px`,
};

const collapsedStyle = css`
    display: none;
    opacity: 0;
`;

const visibleStyle = css`
    opacity: 1;
`;

interface IChildItemControlsProps {
    parentId: Id;
}

const ChildItemControlsContainer: React.FC<IChildItemControlsProps> = ({ parentId }) => {
    const childItemService = useService(ChildItemService);
    const parentChildInstallationPointService = useService(ParentChildInstallationPointService);
    const navigate = useNavigate();

    const projectId = useSelector(getCurrentProjectId);
    const deviceType = useSelector<IStoreState, DeviceAndSubType>((storeState) =>
        getChildType(storeState, parentId),
    );
    const availableChannels = useSelector<IStoreState, number>((storeState) =>
        getNumberOfConnectableChildren(storeState, parentId),
    );
    const usedChannels = useSelector<IStoreState, number>(
        (storeState) => getChannelInformation(storeState, parentId).usedChannels,
    );
    const availableExpansionModules = useSelector<IStoreState, IPiaItemWithPrice<IPiaItem>[]>(
        (storeState) => getCompatibleExpansionModules(storeState, parentId),
    );

    const expanded = useSelector<IStoreState, boolean>((state) => getIsExpanded(state, parentId));

    const showExpansionModulesDropDown = availableExpansionModules.length > 1;

    const addAnalogCamera = async () => {
        const quantity = availableChannels - usedChannels;
        const addedItem = await childItemService.addAnalogCamera(parentId, quantity);
        parentChildInstallationPointService.addChildItemToMap(addedItem._id, quantity, parentId);
    };

    const addSensorUnit = () => {
        navigate(`/project/${projectId}/device-selector/sensorunits/parent/${parentId}/`);
    };

    const addDoor = async () => {
        const addedItem = await childItemService.addDoor(parentId);
        parentChildInstallationPointService.addChildItemToMap(addedItem._id, 1, parentId);
    };

    const addRelayExpansionModule = async (piaItemId: number) => {
        const addedItem = await childItemService.addRelayExpansionModule(parentId, piaItemId);
        parentChildInstallationPointService.addChildItemToMap(addedItem._id, 1, parentId);
    };

    const onAddClick = () => {
        eventTracking.logUserEvent('Project Devices', 'Add Child Device', deviceType);
        if (deviceType === 'analogCamera') {
            addAnalogCamera();
        } else if (deviceType === 'sensorUnit') {
            addSensorUnit();
        } else if (deviceType === 'relayexpmodules') {
            if (availableExpansionModules.length === 1) {
                addRelayExpansionModule(availableExpansionModules[0].piaItem.id);
            }
        } else {
            addDoor();
        }
    };

    return (
        <tr aria-details={parentId} className={cx(expanded ? collapsedStyle : visibleStyle)}>
            <td style={buttonBgStyle} />
            <td
                style={{
                    padding: SpacingsEnum.half,
                    height: '100%',
                    width: '55px',
                    maxWidth: '55px',
                }}
                data-symbol={'\u2514'}
            />
            <td style={{ padding: SpacingsEnum.half }} colSpan={8}>
                <Box spacing="base" alignItems="center">
                    {showExpansionModulesDropDown && (
                        <DropDown
                            openInPortal
                            fillDropdownWidth
                            alignItems="stretch"
                            trigger={
                                <Box alignItems="center" spacing="base">
                                    <Icon
                                        size="lg"
                                        opaque
                                        color="blue"
                                        icon={deviceIcons.toIcon(deviceType)}
                                    />
                                    <Text semiBold color={'blue'}>
                                        {getTitle(deviceType)}{' '}
                                    </Text>
                                    <Text inline bold color="grey5">
                                        {t.deviceListUsedModules(usedChannels, availableChannels)}
                                    </Text>
                                </Box>
                            }
                            contents={availableExpansionModules.map((module, index) => (
                                <DropDownMenuButton
                                    selected={false}
                                    onClick={() => {
                                        addRelayExpansionModule(module.piaItem.id);
                                    }}
                                >
                                    <Box key={index} justifyContent="between" alignItems="center">
                                        <Box spacing="base" alignItems="center">
                                            <PiaImage
                                                imageSize="md"
                                                piaId={module.piaItem.id}
                                                icon="add_box"
                                            />
                                            <Text>{module.piaItem.name}</Text>
                                        </Box>
                                    </Box>
                                </DropDownMenuButton>
                            ))}
                        />
                    )}
                    {!showExpansionModulesDropDown && (
                        <>
                            <Icon
                                size="lg"
                                opaque
                                color="blue"
                                icon={deviceIcons.toIcon(deviceType)}
                            />
                            <Action
                                title={getTitle(deviceType)}
                                onClick={onAddClick}
                                testId={`child_item_control_${deviceType}`}
                            />
                            {deviceType !== 'door' && deviceType !== 'relayexpmodules' && (
                                <Text inline bold color="grey5">
                                    {t.deviceListUsedChannels(usedChannels, availableChannels)}
                                </Text>
                            )}
                            {deviceType === 'relayexpmodules' && (
                                <Text inline bold color="grey5">
                                    {t.deviceListUsedModules(usedChannels, availableChannels)}
                                </Text>
                            )}
                        </>
                    )}
                </Box>
            </td>
        </tr>
    );
};

const getTitle = (parentType: DeviceAndSubType) => {
    switch (parentType) {
        case 'analogCamera':
            return t.deviceListAddAnalogCamera;
        case 'sensorUnit':
            return t.deviceListAddSensorUnit;
        case 'door':
            return t.addDoor;
        case 'relayexpmodules':
            return t.addIORelayExpansion;
        default:
            return '';
    }
};

export const ChildItemControls = ChildItemControlsContainer;

ChildItemControls.displayName = 'ChildItemControls';
