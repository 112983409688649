import * as React from 'react';
import { Border } from '../../style';
import type { IAutoTestable } from '../../ui-test';
import { Box } from '../../containers';
import { Text } from '../../text';
import type { IClickableProps } from '../../functional';
import { Clickable } from '../../functional';
import type { Icons } from '../icon';
import { Icon } from '../icon';
import { Stack } from '../../layout';
import type { Colors } from 'app/styles';

type style = 'default' | 'axis.com';

export interface IPillButtonProps extends IAutoTestable, IClickableProps {
    text?: string;
    checkable?: boolean;
    selected?: boolean;
    color?: Colors;
    textColor?: Colors;
    borderColor?: Colors;
    icon?: Icons;
    iconColor?: Colors;
    style?: style;
    minWidth?: string | number;
}

export const PillButton: React.FunctionComponent<IPillButtonProps> = ({
    text,
    textColor,
    checkable,
    selected,
    testId,
    color,
    borderColor,
    icon,
    iconColor,
    minWidth,
    style,
    disabled,
    ...props
}) => {
    return (
        <Border
            radius={style === 'axis.com' ? '4px' : '18px'}
            color={borderColor ? borderColor : selected ? 'yellow' : 'grey4'}
            hoverColor={
                disabled ? undefined : borderColor ? borderColor : selected ? 'yellow6' : 'grey6'
            }
            width={style === 'axis.com' ? 2 : 1}
        >
            <Clickable {...props}>
                <Box
                    testId={testId}
                    minHeight="36px"
                    color={
                        color
                            ? color
                            : selected
                              ? style === 'axis.com'
                                  ? 'yellow'
                                  : 'yellow1'
                              : 'white'
                    }
                    hoverColor={
                        color
                            ? color
                            : selected
                              ? style === 'axis.com'
                                  ? 'yellow'
                                  : 'yellow1'
                              : 'white'
                    }
                    justifyContent="center"
                    alignItems="center"
                    paddingX="base"
                    paddingY="half"
                    minWidth={minWidth}
                >
                    <Stack flex="fullWidth" spacing="quart">
                        {style !== 'axis.com' && checkable && !selected && (
                            <Icon
                                testId={`${testId}_add_icon`}
                                opaque
                                size="sm"
                                icon={icon || 'add'}
                                color={iconColor}
                            />
                        )}
                        {style !== 'axis.com' && checkable && selected && (
                            <Icon
                                testId={`${testId}_checked_icon`}
                                opaque
                                size="sm"
                                icon={icon || 'check'}
                                color="yellowText"
                            />
                        )}
                        <Box flex="fullWidth" justifyContent="center">
                            <Text
                                color={
                                    textColor
                                        ? textColor
                                        : selected
                                          ? 'yellowText'
                                          : style === 'axis.com'
                                            ? 'grey9'
                                            : 'grey6'
                                }
                                style="semibold"
                                whiteSpace="nowrap"
                            >
                                {text}
                            </Text>
                        </Box>
                        {!checkable && icon && (
                            <Box flex="none">
                                <Icon
                                    opaque
                                    icon={icon}
                                    size="sm"
                                    color={selected && !iconColor ? 'yellowText' : iconColor}
                                />
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Clickable>
        </Border>
    );
};
