import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import type { PiaCategory, PiaId } from 'app/core/pia';
import type { IAutoTestable, ITabAction } from 'app/components';
import { Border, Box, TabbedPanel } from 'app/components';
import { t } from 'app/translate';
import { AccessorySelectorView } from 'app/modules/accessorySelector';
import { ProjectDevicesActionService } from '../../services';
import type { IItemWithVirtualCategory } from 'app/modules/common';
import {
    getScenarioCategoryString,
    getDeviceAndSubTypeForItem,
    getPiaCamera,
    getShouldHaveScenarioTab,
    getCurrentProjectItem,
    getPiaCategory,
    DeviceNetwork,
    getShouldHaveWearableScenarioTab,
    getVirtualChildrenWithCategory,
} from 'app/modules/common';
import type {
    DeviceAndSubType,
    Id,
    IItemNetworkSettings,
    IPersistence,
} from 'app/core/persistence';
import type { ProjectDeviceDetailsTabId } from '../../models';
import { DeviceOverview } from '../deviceOverview';
import { DeviceProfileEditor, DeviceProfileEditorWearables } from '../deviceProfiles';
import { getLenses, LensSelectorView } from 'app/modules/lensSelector';
import { Doors } from 'app/modules/doorSelector';
import { ApplicationSelectorView } from 'app/modules/addonSelector';
import { MapsActionService } from 'app/modules/maps';
import { getInitialTab } from '../../selectors/getInitialTab';
import { DeviceDetailsHeader } from './DeviceDetailsHeader';
interface IDeviceDetailsOwnProps {
    deviceId: Id;
    initialTab?: ProjectDeviceDetailsTabId;
}

interface IDeviceDetailsProps extends IDeviceDetailsOwnProps, IAutoTestable {
    initialTabId?: ProjectDeviceDetailsTabId;
    piaCategory?: PiaCategory;
    deviceType?: DeviceAndSubType;
    productId?: PiaId | null;
    deviceShouldHaveScenarioTab: boolean;
    deviceShouldHaveWearableScenarioTab: boolean;
    lensChangeable: boolean;
    networkSettings?: IItemNetworkSettings[];
    showLensTab: boolean;
    virtualProductsForItem: IPersistence<IItemWithVirtualCategory>[];
}

interface IDeviceDetailsState {
    activeTab: ProjectDeviceDetailsTabId;
}

interface IDeviceTab {
    id: ProjectDeviceDetailsTabId;
    title: string;
    content: JSX.Element;
    tabTestId: string;
    hidden?: boolean;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceDetailsOwnProps,
): IDeviceDetailsProps => {
    const device = getCurrentProjectItem(state, ownProps.deviceId);
    const virtualProductsForItem = getVirtualChildrenWithCategory(state, ownProps.deviceId);

    const deviceShouldHaveScenarioTab = device
        ? getShouldHaveScenarioTab(state, device._id)
        : false;
    const deviceShouldHaveWearableScenarioTab = device
        ? getShouldHaveWearableScenarioTab(state, device._id)
        : false;
    const piaCategory = getPiaCategory(state, ownProps.deviceId);

    const initialTabId = ownProps.initialTab ?? getInitialTab(state);
    const piaCamera = getPiaCamera(state, ownProps.deviceId);
    const lensesArray = getLenses(state, ownProps.deviceId);
    const showLensTab =
        lensesArray &&
        lensesArray.some((lensesForSensor) => {
            return lensesForSensor[1].length > 0 ?? false;
        });

    return {
        initialTabId,
        piaCategory,
        deviceId: ownProps.deviceId,
        productId: device?.productId,
        deviceType: getDeviceAndSubTypeForItem(state, ownProps.deviceId),
        deviceShouldHaveScenarioTab,
        deviceShouldHaveWearableScenarioTab,
        lensChangeable: !!piaCamera?.properties.lensChangeable,
        networkSettings: device?.networkSettings,
        showLensTab,
        virtualProductsForItem,
    };
};

class DeviceDetailsContainer extends React.Component<IDeviceDetailsProps, IDeviceDetailsState> {
    private actionService: ProjectDevicesActionService;
    private mapsActionService: MapsActionService;

    constructor(props: IDeviceDetailsProps) {
        super(props);
        this.actionService = ServiceLocator.get(ProjectDevicesActionService);
        this.mapsActionService = ServiceLocator.get(MapsActionService);
        this.state = { activeTab: this.props.initialTabId || 'overview' };
    }

    public componentDidUpdate(prevProps: IDeviceDetailsProps) {
        if (this.props.deviceId !== prevProps.deviceId && !this.isActiveValidForDevice()) {
            this.setState({ activeTab: this.props.initialTabId || 'overview' });
        }
    }

    public render() {
        const tabs = this.getTabs();

        return (
            <>
                <DeviceDetailsHeader />
                <Border width={0} shadow="rgb(0 0 0 / 3%) 0px -10px 12px">
                    <Box>
                        <TabbedPanel
                            testId={this.props.testId}
                            tabs={tabs}
                            activeTab={this.state.activeTab}
                            activeTabColor="grey1"
                            onActiveTabChange={this.onChange}
                            actions={this.getActions()}
                        />
                    </Box>
                </Border>
            </>
        );
    }

    private getTabs(): IDeviceTab[] {
        const {
            deviceType,
            deviceId,
            productId,
            deviceShouldHaveScenarioTab,
            deviceShouldHaveWearableScenarioTab,
            lensChangeable,
            networkSettings,
        } = this.props;

        if (!deviceId) {
            return [];
        }
        const tabs: IDeviceTab[] = [
            {
                id: 'overview',
                title: t.deviceDetailsOverviewHeading,
                content: <DeviceOverview deviceId={deviceId} />,
                tabTestId: 'overview',
            },
            {
                id: 'accessories',
                title: t.accessories,
                hidden:
                    productId === null || deviceType === 'analogCamera' || !this.props.piaCategory,
                content: <AccessorySelectorView />,
                tabTestId: 'accessories',
            },
            {
                id: 'applications',
                title: t.deviceDetailsApplicationsApplications,
                hidden:
                    productId === null ||
                    deviceType === 'analogCamera' ||
                    deviceType === 'sensorUnit',
                content: <ApplicationSelectorView deviceItemId={deviceId} />,
                tabTestId: 'new_applications',
            },
            {
                id: 'profile',
                title: this.getScenarioTabName(),
                hidden: !deviceShouldHaveScenarioTab,
                content: <DeviceProfileEditor deviceId={deviceId} />,
                tabTestId: 'scenario',
            },
            {
                id: 'profile',
                title: t.deviceDetailsProfileHeading,
                hidden: !deviceShouldHaveWearableScenarioTab,
                content: <DeviceProfileEditorWearables deviceId={deviceId} />,
                tabTestId: 'scenario',
            },
            {
                id: 'doors',
                title: t.doors,
                hidden:
                    productId === null ||
                    deviceType !== 'doorcontroller' ||
                    !this.props.piaCategory,
                content: <Doors deviceItemId={deviceId} />,
                tabTestId: 'doors',
            },
            //* Adds scenario tabs for virtual products
            ...this.props.virtualProductsForItem.map((item, index) => {
                const id: ProjectDeviceDetailsTabId = `profile_${item._id}`;
                return {
                    id,
                    title: this.getScenarioTabName(item.virtualProductCategory),
                    hidden: !deviceShouldHaveScenarioTab,
                    content: <DeviceProfileEditor deviceId={item._id} />,
                    tabTestId: `virtualProductScenario_${index}`,
                };
            }),
        ];

        if (lensChangeable && this.props.showLensTab) {
            const lensesTab: IDeviceTab = {
                id: 'lenses',
                title: t.accessoryDisplayCategoriesGROUP.lenses,
                hidden:
                    productId === null || deviceType === 'analogCamera' || !this.props.piaCategory,
                content: <LensSelectorView itemId={deviceId} />,
                tabTestId: 'lenses',
            };
            tabs.splice(1, 0, lensesTab);
        }

        if (networkSettings) {
            const ipConfigurationTab: IDeviceTab = {
                id: 'ipConfiguration',
                title: t.ipConfiguration,
                content: <DeviceNetwork deviceId={deviceId} networkSettings={networkSettings} />,
                tabTestId: 'network',
            };
            tabs.push(ipConfigurationTab);
        }

        return tabs;
    }

    private getActions(): ITabAction[] {
        return [
            {
                text: t.close,
                onClick: this.onCloseClicked,
            },
        ];
    }

    private isActiveValidForDevice() {
        return this.getTabs().some((tab) => !tab.hidden && tab.id === this.state.activeTab);
    }

    private onChange = (tabId: ProjectDeviceDetailsTabId) => {
        this.setState({ activeTab: tabId });
    };

    private onCloseClicked = () => {
        this.actionService.closePanel();
        this.mapsActionService.toggleDeviceOverlayPanel();
    };
    private getScenarioTabName(virtualProductCategory?: PiaCategory[]) {
        /** First listed virtual product category */
        const virtualCategory = virtualProductCategory?.length
            ? virtualProductCategory?.[0]
            : undefined;

        // Get scenario tab name for virtual product
        if (virtualCategory) {
            return getScenarioCategoryString(virtualCategory);
        }

        // Get scenario tab name for main device containing virtual product(s)
        if (this.props.virtualProductsForItem.length && !virtualCategory) {
            return getScenarioCategoryString(this.props.piaCategory);
        }

        return t.deviceDetailsProfileHeading;
    }
}

export const DeviceDetails = connect(mapStateToProps)(DeviceDetailsContainer);
