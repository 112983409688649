import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Removes bounds property on IFloorPlanEntity
 */
@injectable()
export class Migration36To37 extends MigrationProviderBase {
    public from: number = 36;
    public to: number = 37;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'floorPlan') {
                delete entity.bounds;
            }

            return entity;
        };
    }
}
