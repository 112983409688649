import {
    PiaItemCameraCategory,
    PiaItemEncoderCategory,
    PiaItemDecoderCategory,
    PiaItemPacCategory,
    PiaItemSoftwareCategory,
    PiaItemSpeakerCategory,
    PiaItemMainUnitCategory,
    PiaItemSensorUnitCategory,
    PiaAccessoryCategory,
    PiaItemRecorderCategory,
    PiaItemWearablesCategory,
    PiaItemDetectorCategory,
    PiaItemPeopleCounterCategory,
} from 'app/core/pia';

export enum CategoryGroupsEnum {
    All = 'allowlistCategoryAll',
    Others = 'other',
    Cameras = 'allowlistCategoryCameras',
    Modulars = 'allowlistCategoryModulars',
    EncodersDecoders = 'allowlistCategoryEncodersDecoders',
    Audio = 'allowlistCategoryAudio',
    Pacs = 'quotationPacs',
    Software = 'quotationSoftware',
    Recorders = 'recorders',
    Accessories = 'accessories',
    Wearables = 'wearables',
}

export function isCategoryInCategoryGroup(category: string, categoryGroup: CategoryGroupsEnum) {
    switch (categoryGroup) {
        case CategoryGroupsEnum.All:
            return true;
        case CategoryGroupsEnum.Cameras:
            return doesEnumIncludeValue(PiaItemCameraCategory, category);
        case CategoryGroupsEnum.Recorders:
            return doesEnumIncludeValue(PiaItemRecorderCategory, category);

        case CategoryGroupsEnum.EncodersDecoders:
            return (
                doesEnumIncludeValue(PiaItemEncoderCategory, category) ||
                doesEnumIncludeValue(PiaItemDecoderCategory, category)
            );
        case CategoryGroupsEnum.Pacs:
            return doesEnumIncludeValue(PiaItemPacCategory, category);
        case CategoryGroupsEnum.Software:
            return doesEnumIncludeValue(PiaItemSoftwareCategory, category);
        case CategoryGroupsEnum.Audio:
            return doesEnumIncludeValue(PiaItemSpeakerCategory, category);
        case CategoryGroupsEnum.Modulars:
            return (
                doesEnumIncludeValue(PiaItemMainUnitCategory, category) ||
                doesEnumIncludeValue(PiaItemSensorUnitCategory, category)
            );
        case CategoryGroupsEnum.Accessories:
            return doesEnumIncludeValue(PiaAccessoryCategory, category);
        case CategoryGroupsEnum.Wearables:
            return doesEnumIncludeValue(PiaItemWearablesCategory, category);
        case CategoryGroupsEnum.Others:
            return (
                doesEnumIncludeValue(PiaItemDetectorCategory, category) ||
                doesEnumIncludeValue(PiaItemPeopleCounterCategory, category)
            );
        default:
            return false;
    }
}

export function isCategorySupportedGroup(category: string) {
    return (
        doesEnumIncludeValue(PiaItemCameraCategory, category) ||
        doesEnumIncludeValue(PiaItemRecorderCategory, category) ||
        doesEnumIncludeValue(PiaItemEncoderCategory, category) ||
        doesEnumIncludeValue(PiaItemDecoderCategory, category) ||
        doesEnumIncludeValue(PiaItemPacCategory, category) ||
        doesEnumIncludeValue(PiaItemSoftwareCategory, category) ||
        doesEnumIncludeValue(PiaItemSpeakerCategory, category) ||
        doesEnumIncludeValue(PiaItemMainUnitCategory, category) ||
        doesEnumIncludeValue(PiaItemSensorUnitCategory, category) ||
        doesEnumIncludeValue(PiaAccessoryCategory, category) ||
        doesEnumIncludeValue(PiaItemWearablesCategory, category) ||
        doesEnumIncludeValue(PiaItemDetectorCategory, category) ||
        doesEnumIncludeValue(PiaItemPeopleCounterCategory, category)
    );
}

export function isCategoryNotInCategoryGroup(category: string, categoryGroup: CategoryGroupsEnum) {
    switch (categoryGroup) {
        case CategoryGroupsEnum.All:
            return false;
        case CategoryGroupsEnum.Cameras:
            return !doesEnumIncludeValue(PiaItemCameraCategory, category);
        case CategoryGroupsEnum.Recorders:
            return !doesEnumIncludeValue(PiaItemRecorderCategory, category);

        case CategoryGroupsEnum.EncodersDecoders:
            return (
                !doesEnumIncludeValue(PiaItemEncoderCategory, category) ||
                !doesEnumIncludeValue(PiaItemDecoderCategory, category)
            );
        case CategoryGroupsEnum.Pacs:
            return !doesEnumIncludeValue(PiaItemPacCategory, category);
        case CategoryGroupsEnum.Software:
            return !doesEnumIncludeValue(PiaItemSoftwareCategory, category);
        case CategoryGroupsEnum.Audio:
            return !doesEnumIncludeValue(PiaItemSpeakerCategory, category);
        case CategoryGroupsEnum.Modulars:
            return (
                !doesEnumIncludeValue(PiaItemMainUnitCategory, category) ||
                !doesEnumIncludeValue(PiaItemSensorUnitCategory, category)
            );
        case CategoryGroupsEnum.Accessories:
            return !doesEnumIncludeValue(PiaAccessoryCategory, category);
        case CategoryGroupsEnum.Wearables:
            return !doesEnumIncludeValue(PiaItemWearablesCategory, category);
        case CategoryGroupsEnum.Others:
            return (
                !doesEnumIncludeValue(PiaItemDetectorCategory, category) ||
                !doesEnumIncludeValue(PiaItemPeopleCounterCategory, category)
            );
        default:
            return false;
    }
}

export function getCategoryGroup(category: string) {
    if (doesEnumIncludeValue(PiaItemCameraCategory, category)) {
        return CategoryGroupsEnum.Cameras;
    }

    if (
        doesEnumIncludeValue(PiaItemEncoderCategory, category) ||
        doesEnumIncludeValue(PiaItemDecoderCategory, category)
    ) {
        return CategoryGroupsEnum.EncodersDecoders;
    }

    if (doesEnumIncludeValue(PiaItemRecorderCategory, category)) {
        return CategoryGroupsEnum.Recorders;
    }

    if (doesEnumIncludeValue(PiaItemPacCategory, category)) {
        return CategoryGroupsEnum.Pacs;
    }

    if (doesEnumIncludeValue(PiaItemSoftwareCategory, category)) {
        return CategoryGroupsEnum.Software;
    }

    if (doesEnumIncludeValue(PiaItemSpeakerCategory, category)) {
        return CategoryGroupsEnum.Audio;
    }

    if (doesEnumIncludeValue(PiaAccessoryCategory, category)) {
        return CategoryGroupsEnum.Accessories;
    }

    if (
        doesEnumIncludeValue(PiaItemMainUnitCategory, category) ||
        doesEnumIncludeValue(PiaItemSensorUnitCategory, category)
    ) {
        return CategoryGroupsEnum.Modulars;
    }

    return CategoryGroupsEnum.Others;
}

/**
 * doesEnumIncludeValue checks if typescriptEnum contains the passed value
 * inspired by: https://stackoverflow.com/questions/43804805/check-if-value-exists-in-enum-in-typescript
 */
export function doesEnumIncludeValue(typescriptEnum: any, value: any) {
    return Object.values(typescriptEnum).includes(value);
}
