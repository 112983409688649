import * as React from 'react';
import type { WhiteSpace } from '../../text';
import { Text, IconText } from '../../text';
import { SpacingsEnum, ColorsEnum } from 'app/styles';
import type { IExtendableComponent } from '../../models';
import type { Icons } from '../icon';
import { css, cx } from '@emotion/css';
import { extendableProps } from '../../services';
import type { IBadgeColor } from './BadgeColors';
import { primaryColor, outlineColor, outlineBackgroundColor, textColor } from './BadgeColors';
import type { IAutoTestable } from '../../ui-test/IAutoTestable';
import { toTestIdFormat } from '../../ui-test/stringFormatter';

interface IBadgeProps extends IAutoTestable, IExtendableComponent {
    /**
     * The type of badge determines its background color.
     * If outline-prop is passed the type determines the color of the text and outline.
     */
    type: keyof IBadgeColor;
    /**
     * The text to print on the badge
     */
    label: string;
    /**
     * An icon next to the label
     */
    icon?: Icons;
    /**
     * Badge with less padding
     */
    compact?: boolean;
    /**
     * Smaller badge with less padding
     */
    mini?: boolean;
    /**
     * Badge with an outline instead of a background color
     */
    outline?: boolean;
    /**
     * Set the whitespace css behavior
     */
    whiteSpace?: WhiteSpace;
}

/**
 * Used as a label on things.
 * For example to show that a product is discontinued.
 */
export class Badge extends React.PureComponent<IBadgeProps> {
    public baseStyle = css`
        display: flex;
        padding: ${SpacingsEnum.quart} ${SpacingsEnum.cell};
        border-radius: 5px;
    `;

    public compactStyle = css`
        padding: ${SpacingsEnum.none} ${SpacingsEnum.half};
    `;

    public miniStyle = css`
        padding: ${SpacingsEnum.none} ${SpacingsEnum.quart};
        border-radius: 4px;
    `;

    public render() {
        const { type, label, compact, mini, outline, icon, whiteSpace, ...extendedProps } =
            this.props;

        const primaryStyle = css`
            background-color: ${ColorsEnum[primaryColor[type]]};
            border: 1px solid ${ColorsEnum[primaryColor[type]]};
        `;
        const outlineStyle = css`
            background-color: ${ColorsEnum[outlineBackgroundColor[type]]};
            border: 1px solid ${ColorsEnum[outlineColor[type]]};
        `;
        const noWrap = css`
            white-space: nowrap;
        `;

        const className = cx(
            this.baseStyle,
            { [primaryStyle]: !outline },
            { [outlineStyle]: !!outline },
            { [this.compactStyle]: !!compact },
            { [this.miniStyle]: !!mini },
            { [noWrap]: whiteSpace === 'nowrap' },
        );
        const attributes = extendableProps({ ...extendedProps }, { className }, true);
        const colorText = mini ? textColor[type] : outline ? outlineColor[type] : textColor[type];

        return (
            <span data-test-id={toTestIdFormat(this.props.testId)} {...attributes}>
                {icon ? (
                    <IconText
                        icon={icon}
                        iconProps={{ size: 'xs' }}
                        textProps={{ semiBold: !compact, small: compact || mini, align: 'center' }}
                        color={colorText}
                    >
                        {label}
                    </IconText>
                ) : (
                    <Text
                        whiteSpace={whiteSpace}
                        inline
                        semiBold={!mini}
                        align="center"
                        color={colorText}
                        small={compact || mini}
                    >
                        {label}
                    </Text>
                )}
            </span>
        );
    }
}
