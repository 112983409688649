import { useEffect, useRef } from 'react';

/**
 * Use this to get a reference to the value of a prop from the previous render
 */
export const usePrevious = <T>(value: T, initialValue?: T) => {
    const ref = useRef<T | undefined>(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
