import type { IMigration } from './models/IMigration';
import type { IItems } from 'axis-webtools-util';
import { KeyValueStore } from 'axis-webtools-util';
import { deleteDatabase } from './databaseUtil';

/**
 * Migration for ASD1 user contact details overrides.
 * Also deletes ASD1 user database as last step.
 */
export const migrate3to4: IMigration = {
    from: 3,
    to: 4,
    run: migrate,
};

const ASD1_USER_DB_NAME = 'axis.webtools.user';
const ASD1_USER_INFO_KEY = 'info';
const ASD2_USER_INFO_DB_NAME = 'axis.webtools.userApiInfo';
const ASD2_USER_INFO_OVERRIDE_STORE_NAME = 'userInfoOverride';

async function migrate(): Promise<void> {
    const userInfoStore = new KeyValueStore<string | undefined>(
        ASD1_USER_DB_NAME,
        ASD1_USER_INFO_KEY,
    );

    const userInfoItems = await userInfoStore.getItems([
        'city',
        'cc',
        'email',
        'fname',
        'lname',
        'phoneNumber',
        'streetAddress',
        'userPresentationName',
        'zipCode',
        'state',
    ]);

    const userInfoOverride = getUserInfoOverride(userInfoItems);

    // If we have any overrides we want to put them in ASD2 value store
    if (Object.keys(userInfoOverride).length > 0) {
        const userInfoOverrideStore = new KeyValueStore<typeof userInfoOverride>(
            ASD2_USER_INFO_DB_NAME,
            ASD2_USER_INFO_OVERRIDE_STORE_NAME,
        );

        await userInfoOverrideStore.setItem(ASD2_USER_INFO_OVERRIDE_STORE_NAME, userInfoOverride);
    }

    // Lastly remove the ASD1 database
    await deleteDatabase(ASD1_USER_DB_NAME);
}

/**
 * Map ASD1 user info to ASD2 user info override
 *
 * @param userInfoItems asd1 user info
 */
function getUserInfoOverride(userInfoItems: IItems<string | undefined>) {
    const userInfoOverride: Record<string, string> = {};

    if (userInfoItems.city) {
        userInfoOverride.city = userInfoItems.city;
    }

    if (userInfoItems.cc) {
        userInfoOverride.country = userInfoItems.cc;
    }

    if (userInfoItems.email) {
        userInfoOverride.email = userInfoItems.email;
    }

    if (userInfoItems.fname) {
        userInfoOverride.firstName = userInfoItems.fname;
    }

    if (userInfoItems.lname) {
        userInfoOverride.lastName = userInfoItems.lname;
    }

    if (userInfoItems.phoneNumber) {
        userInfoOverride.phoneNumber = userInfoItems.phoneNumber;
    }

    if (userInfoItems.streetAddress) {
        userInfoOverride.streetAddress = userInfoItems.streetAddress;
    }

    if (userInfoItems.userPresentationName) {
        userInfoOverride.companyName = userInfoItems.userPresentationName;
    }

    if (userInfoItems.zipCode) {
        userInfoOverride.zipCode = userInfoItems.zipCode;
    }

    if (userInfoItems.state) {
        userInfoOverride.usState = userInfoItems.state;
    }

    return userInfoOverride;
}
