import { Module } from 'app/ioc';
import {
    AddonSelectorActionService,
    AddonSelectorService,
    FetchPartnerProductsCommunicator,
} from './services';
import { addonSelectorReducer } from './AddonSelectorReducer';

@Module({
    binder: (binder) => {
        binder.bindService(AddonSelectorActionService);
        binder.bindService(AddonSelectorService);
        binder.bindService(FetchPartnerProductsCommunicator);
    },
    reducers: {
        addonSelector: addonSelectorReducer,
    },
})
export class AddonSelectorModule {}
