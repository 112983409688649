import saveAs from 'file-saver';
import JSZip from 'jszip';

/**
 * Get headers from HTML table
 * @param table HTML table
 * @returns an array of headers
 */
const getHeaders = (table: HTMLTableElement) => {
    const headers: string[] = [];
    const headerRow = table.rows[0];
    for (let i = 0; i < headerRow.cells.length; i++) {
        headers.push(headerRow.cells[i].innerText);
    }
    return headers;
};

/**
 * Get data rows from HTML table
 * @param table HTML table
 * @returns an array of data rows
 */
const getDataRows = (table: HTMLTableElement) => {
    const dataRows: string[][] = [];
    for (let i = 1; i < table.rows.length; i++) {
        const dataRow: string[] = [];
        for (let j = 0; j < table.rows[i].cells.length; j++) {
            dataRow.push(table.rows[i].cells[j].innerText);
        }
        dataRows.push(dataRow);
    }
    return dataRows;
};

/**
 * Generate excel file
 * @param fileName Name of the file
 * @param title Name of the sheet
 */
export const generateExcelFile = (
    fileName: string,
    title: string,
    headers: string[],
    dataRows: string[][],
    tables?: HTMLTableElement[],
) => {
    const zip = new JSZip();

    // Add file [Content_Types].xml
    zip.file(
        '[Content_Types].xml',
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
            '<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">' +
            '<Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml"/>' +
            '<Default Extension="xml" ContentType="application/xml"/>' +
            '<Override PartName="/xl/workbook.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml"/>' +
            '<Override PartName="/xl/worksheets/sheet1.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml"/>' +
            '<Override PartName="/xl/theme/theme1.xml" ContentType="application/vnd.openxmlformats-officedocument.theme+xml"/>' +
            '<Override PartName="/xl/styles.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml"/>' +
            '<Override PartName="/docProps/core.xml" ContentType="application/vnd.openxmlformats-package.core-properties+xml"/>' +
            '<Override PartName="/docProps/app.xml" ContentType="application/vnd.openxmlformats-officedocument.extended-properties+xml"/>' +
            '</Types>',
    );

    // Add folder _rels
    const rels = zip.folder('_rels');
    if (rels === null) {
        console.error('rels folder not found');
        return;
    }

    // Add file _rels/.rels
    rels.file(
        '.rels',
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
            '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">' +
            '<Relationship Id="rId3" Type="http://purl.oclc.org/ooxml/officeDocument/relationships/extendedProperties" Target="docProps/app.xml"/>' +
            '<Relationship Id="rId2" Type="http://schemas.openxmlformats.org/package/2006/relationships/metadata/core-properties" Target="docProps/core.xml"/>' +
            '<Relationship Id="rId1" Type="http://purl.oclc.org/ooxml/officeDocument/relationships/officeDocument" Target="xl/workbook.xml"/>' +
            '</Relationships>',
    );

    // Add folder xl
    const xl = zip.folder('xl');
    if (xl === null) {
        console.error('xl folder not found');
        return;
    }

    // Add folder xl/_rels
    const xl_rels = xl.folder('_rels');

    if (xl_rels === null) {
        console.error('xl_rels folder not found');
        return;
    }

    // Add file xl/_rels/workbook.xml.rels
    xl_rels.file(
        'workbook.xml.rels',
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
            '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">' +
            '<Relationship Id="rId3" Type="http://purl.oclc.org/ooxml/officeDocument/relationships/styles" Target="styles.xml"/>' +
            '<Relationship Id="rId2" Type="http://purl.oclc.org/ooxml/officeDocument/relationships/theme" Target="theme/theme1.xml"/>' +
            '<Relationship Id="rId1" Type="http://purl.oclc.org/ooxml/officeDocument/relationships/worksheet" Target="worksheets/sheet1.xml"/>' +
            '</Relationships>',
    );

    // Add file xl/styles.xml
    xl.file(
        'styles.xml',
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
            '<styleSheet ' +
            'xmlns="http://purl.oclc.org/ooxml/spreadsheetml/main" ' +
            'xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" ' +
            'mc:Ignorable="x14ac x16r2 xr" ' +
            'xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac" ' +
            'xmlns:x16r2="http://schemas.microsoft.com/office/spreadsheetml/2015/02/main" ' +
            'xmlns:xr="http://schemas.microsoft.com/office/spreadsheetml/2014/revision">' +
            '<fonts count="1"><font /></fonts>' +
            '<fills count="1"><fill /></fills>' +
            '<borders count="1"><border /></borders>' +
            '<cellStyleXfs count="1"><xf /></cellStyleXfs>' +
            '<cellXfs count="1"><xf /></cellXfs>' +
            '</styleSheet>',
    );

    // Add file xl/workbook.xml
    xl.file(
        'workbook.xml',
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
            '<workbook xmlns="http://purl.oclc.org/ooxml/spreadsheetml/main" ' +
            'xmlns:r="http://purl.oclc.org/ooxml/officeDocument/relationships" ' +
            'xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" ' +
            'mc:Ignorable="x15 xr xr6 xr10 xr2" ' +
            'xmlns:x15="http://schemas.microsoft.com/office/spreadsheetml/2010/11/main" ' +
            'xmlns:xr="http://schemas.microsoft.com/office/spreadsheetml/2014/revision" ' +
            'xmlns:xr6="http://schemas.microsoft.com/office/spreadsheetml/2016/revision6" ' +
            'xmlns:xr10="http://schemas.microsoft.com/office/spreadsheetml/2016/revision10" ' +
            'xmlns:xr2="http://schemas.microsoft.com/office/spreadsheetml/2015/revision2" ' +
            'conformance="strict">' +
            '<fileVersion appName="xl" lastEdited="7" lowestEdited="7" rupBuild="23801"/>' +
            '<workbookPr dateCompatibility="0" defaultThemeVersion="166925"/>' +
            '<xr:revisionPtr revIDLastSave="0" documentId="8_{3A289708-EEB4-4C61-80F8-0077D98C4C49}" xr6:coauthVersionLast="46" xr6:coauthVersionMax="46" xr10:uidLastSave="{00000000-0000-0000-0000-000000000000}"/>' +
            '<bookViews>' +
            '<workbookView xWindow="-108" yWindow="-108" windowWidth="23256" windowHeight="12576" xr2:uid="{7AE87E27-093C-4FCA-9244-038BA05B474C}"/>' +
            '</bookViews>' +
            '<sheets>' +
            `<sheet name="${title}" sheetId="1" r:id="rId1"/>` +
            '</sheets>' +
            '<calcPr calcId="191029"/>' +
            '<extLst>' +
            '<ext uri="{79F54976-1DA5-4618-B147-4CDE4B953A38}" xmlns:x14="http://schemas.microsoft.com/office/spreadsheetml/2009/9/main">' +
            '<x14:workbookPr/>' +
            '</ext>' +
            '<ext uri="{140A7094-0E35-4892-8432-C4D2E57EDEB5}" xmlns:x15="http://schemas.microsoft.com/office/spreadsheetml/2010/11/main">' +
            '<x15:workbookPr chartTrackingRefBase="1"/>' +
            '</ext>' +
            '<ext uri="{B58B0392-4F1F-4190-BB64-5DF3571DCE5F}" xmlns:xcalcf="http://schemas.microsoft.com/office/spreadsheetml/2018/calcfeatures">' +
            '<xcalcf:calcFeatures>' +
            '<xcalcf:feature name="microsoft.com:RD"/>' +
            '<xcalcf:feature name="microsoft.com:Single"/>' +
            '<xcalcf:feature name="microsoft.com:FV"/>' +
            '<xcalcf:feature name="microsoft.com:CNMTM"/>' +
            '<xcalcf:feature name="microsoft.com:LET_WF"/>' +
            '</xcalcf:calcFeatures>' +
            '</ext>' +
            '</extLst>' +
            '</workbook>',
    );

    // Add folder xl/worksheets
    const worksheets = xl.folder('worksheets');
    if (worksheets === null) {
        console.error('worksheets folder not found');
        return;
    }

    // Add file xl/worksheets/sheet1.xml
    worksheets.file(
        'sheet1.xml',
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
            '<worksheet xmlns="http://purl.oclc.org/ooxml/spreadsheetml/main"' +
            ' xmlns:r="http://purl.oclc.org/ooxml/officeDocument/relationships"' +
            ' xmlns:v="urn:schemas-microsoft-com:vml"' +
            ' xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006"' +
            ' mc:Ignorable="x14ac xr xr2 xr3"' +
            ' xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac"' +
            ' xmlns:xr="http://schemas.microsoft.com/office/spreadsheetml/2014/revision"' +
            ' xmlns:xr2="http://schemas.microsoft.com/office/spreadsheetml/2015/revision2"' +
            ' xmlns:xr3="http://schemas.microsoft.com/office/spreadsheetml/2016/revision3"' +
            ' xr:uid="{7692402F-0706-4FB4-BC3D-F5CCF87FAADF}">' +
            '<dimension ref="A1"/>' +
            '<sheetViews>' +
            '<sheetView tabSelected="1" workbookViewId="0"/>' +
            '</sheetViews>' +
            '<sheetFormatPr defaultRowHeight="14.4" x14ac:dyDescent="0.3"/>' +
            '<sheetData>' +
            `${tables ? getRowsForAllTables(tables) : getRows(headers, dataRows)}` +
            '</sheetData>' +
            '<pageMargins left="0.7" right="0.7" top="0.75" bottom="0.75" header="0.3" footer="0.3"/>' +
            '</worksheet>',
    );

    // Generate excel file and trigger download
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${fileName}.xlsx`);
    });
};

const getRowsForAllTables = (tables: HTMLTableElement[]) => {
    let rows: string = '';
    tables.forEach((table) => {
        rows += getRowsTable(table);
    });
    return rows;
};

/**
 * Add rows to the sheet
 * @param headers Array of headers
 * @param dataRows Data rows
 * @returns a string of rows
 */
const getRowsTable = (table: HTMLTableElement) => {
    const headers = getHeaders(table);
    const dataRows = getDataRows(table);
    let rows = '';

    // Add headers
    rows += '<row>';
    headers.forEach((header) => {
        rows += `<c t="inlineStr"><is><t>${header}</t></is></c>`;
    });
    rows += '</row>';

    // Add data rows
    dataRows.forEach((dataRow) => {
        rows += '<row>';
        dataRow.forEach((data) => {
            // Check if data is a number then add as a number else add as a string.
            if (!isNaN(Number(data))) {
                rows += `<c t="n"><v>${data}</v></c>`;
            } else {
                rows += `<c t="inlineStr"><is><t>${data}</t></is></c>`;
            }
        });
        rows += '</row>';
    });

    return rows;
};

/**
 * Add rows to the sheet
 * @param headers Array of headers
 * @param dataRows Data rows
 * @returns a string of rows
 */
const getRows = (headers: string[], dataRows: string[][]) => {
    let rows = '';

    // Add headers
    rows += '<row>';
    headers.forEach((header) => {
        rows += `<c t="inlineStr"><is><t>${header}</t></is></c>`;
    });
    rows += '</row>';

    // Add data rows
    dataRows.forEach((dataRow) => {
        rows += '<row>';
        dataRow.forEach((data) => {
            // Check if data is a number then add as a number else add as a string.
            if (!isNaN(Number(data))) {
                rows += `<c t="n"><v>${data}</v></c>`;
            } else {
                rows += `<c t="inlineStr"><is><t>${data}</t></is></c>`;
            }
        });
        rows += '</row>';
    });

    return rows;
};
