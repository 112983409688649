import * as React from 'react';
import type { IAutoTestable } from 'app/components';
import { ReadDirection, Text } from 'app/components';
import { SpacingsEnum } from 'app/styles';
import { css } from '@emotion/css';

export interface IPropertyOverrideTextProps extends IAutoTestable {
    text?: string;
    propertyValue?: unknown;
    alignRight?: boolean;
}

const squareLeftStyle = css`
    :before {
        background-color: red;
        height: 6px;
        width: 6px;
        margin-inline-start: calc(-1 * 12px);
        margin-top: ${SpacingsEnum.quart};
        content: '';
        position: absolute;
    }
`;

const squareRightStyle = css`
    :after {
        background-color: red;
        height: 6px;
        width: 6px;
        margin-top: ${SpacingsEnum.quart};
        content: '';
        position: absolute;
        inset-inline-end: 0;
    }
`;
export class PropertyOverrideText extends React.PureComponent<IPropertyOverrideTextProps> {
    public render() {
        const squareVisible = !!this.props.propertyValue;

        return (
            <div
                style={{
                    paddingInlineEnd:
                        squareVisible && this.props.alignRight ? SpacingsEnum.cell : undefined,
                    position: 'relative',
                    whiteSpace: 'nowrap',
                }}
            >
                <span
                    className={this.props.alignRight ? squareRightStyle : squareLeftStyle}
                    hidden={!squareVisible}
                />
                <ReadDirection direction="ltr">
                    <Text testId={this.props.testId}>{this.props.text}</Text>
                </ReadDirection>
            </div>
        );
    }
}
