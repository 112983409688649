import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { DeletableEntity } from '../models';

/**
 * Removes recurring services from projects
 */
@injectable()
export class Migration20To21 extends MigrationProviderBase {
    public from: number = 20;
    public to: number = 21;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'customItem' && entity.category === 'recurringService') {
                return new DeletableEntity(entity);
            }

            return entity;
        };
    }
}
