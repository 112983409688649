import * as React from 'react';
import { Box, Text, ContentPanel } from 'app/components';
import { IconContent } from './IconContent';

export const Styleguide: React.FC = () => {
    return (
        <ContentPanel>
            <Box direction="column" spacing="base">
                <Box justifyContent="center">
                    <Text style="headline">Icons</Text>
                </Box>
                <IconContent />
            </Box>
        </ContentPanel>
    );
};
