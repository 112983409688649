import * as React from 'react';
import { t } from 'app/translate';
import moment from 'moment';
import type { IIconProps } from 'app/components';
import {
    Spinner,
    Stack,
    IconButton,
    DropDownMenu,
    DropDownMenuDelete,
    DropDownMenuLink,
    DropDownMenuButton,
    Box,
    DateField,
    Text,
    Icon,
    Border,
    EditableText,
    Heading,
    Modal,
    Button,
} from 'app/components';
import { AppConstants } from 'app/AppConstants';
import type { IUserProjectsListItem } from '../models';
import { connect } from 'react-redux';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import type { IStoreState } from 'app/store';
import { ExportProject } from 'app/modules/exportProjectSettings';
import { UserProjectsService } from '../../services';
import { ProjectDbOrigin } from 'app/core/persistence';
import { JobQueue, type Job } from 'axis-webtools-util';
import { UserProjectsActionService } from '../services';
import { getCurrentView } from '../selectors';
import { History } from 'app/NavigateSetter';
import { ModalService } from 'app/modal';
import { ProjectStateIndicator } from './ProjectStateIndicator.container';

const TIME_BETWEEN_EXPORT_AND_PROJECT_SAVE_SECONDS = 5;

const iconProps: IIconProps = {
    size: 'sm',
    color: 'grey8',
};

interface IProjectItemContainerState {
    showExportModal: boolean;
    mounted: boolean;
    deepDataUpdated: boolean;
}

interface IProjectItemOwnProps {
    project: IUserProjectsListItem;
    index?: number;
}

interface IProjectItemProps extends IProjectItemOwnProps {
    isSelected: boolean;
    isMultiSelecting: boolean;
    currentView: ProjectDbOrigin;
    usedImageQuota?: number;
    totalImageQuota?: number;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProjectItemOwnProps,
): IProjectItemProps => {
    const project = ownProps.project;
    const isSelected = storeState.userProjects.selectedProjectIds.includes(project.id);
    const isMultiSelecting = storeState.userProjects.selectedProjectIds.length > 0;
    const currentView = getCurrentView(storeState);
    const usedImageQuota = storeState.common.quota.usedBytes;
    const totalImageQuota = storeState.common.quota.totalBytes;
    return {
        project,
        isSelected,
        isMultiSelecting,
        currentView,
        usedImageQuota,
        totalImageQuota,
    };
};

// queue for sequential processing
const jobQueue = new JobQueue();

class ProjectItemContainer extends React.Component<IProjectItemProps, IProjectItemContainerState> {
    private actionService: UserProjectsActionService;
    private userProjectsService: UserProjectsService;
    private modalService: ModalService;
    private determineFloorPlanStatusJob?: Job;

    constructor(props: IProjectItemProps) {
        super(props);
        this.actionService = ServiceLocator.get(UserProjectsActionService);
        this.userProjectsService = ServiceLocator.get(UserProjectsService);
        this.modalService = ServiceLocator.get(ModalService);
        this.state = {
            showExportModal: false,
            mounted: true,
            deepDataUpdated: false,
        };
    }

    private shouldShowExportButton() {
        // Project is updated after an export since we're updating the lastExportedDate property on project,
        // so we need to allow a small diff in timestamps.
        const diff = moment(this.props.project.updatedDate).diff(
            this.props.project.lastExportedDate,
            'seconds',
        );
        const showExportButton =
            !this.props.project.lastExportedDate ||
            diff > TIME_BETWEEN_EXPORT_AND_PROJECT_SAVE_SECONDS;
        return showExportButton;
    }

    public componentDidMount() {
        // Create a new job for determining whether the project has a floor plan.
        // We don't want to flood our persistence layer with gazillions of simultaneous
        // requests and the queue makes sure that they are processed one by one
        this.determineFloorPlanStatusJob = async () => {
            if (!this.state.mounted) {
                // the component was unmounted while the entity was read - Abort to
                // avoid react warnings
                return;
            }

            await this.actionService.getDeepProjectData(this.props.project.id).payload;
            this.setState({ deepDataUpdated: true });
        };

        // enqueue the job for sequential processing
        jobQueue.addJob(this.determineFloorPlanStatusJob);
    }

    public componentWillUnmount() {
        // remove the job if it is still in the queue (this avoids unnecessary processing
        // after the component has been unmounted
        jobQueue.removeJob(this.determineFloorPlanStatusJob);

        this.setState({ mounted: false });
    }

    private async onOpen(project: IUserProjectsListItem) {
        if (project.archived) {
            const confirmed = await this.modalService.createConfirmDialog({
                header: t.projectArchived,
                body: t.projectRestoreToOpen,
                confirmButtonText: t.projectRestore,
                cancelButtonText: t.cancel,
            })();

            if (!confirmed) {
                return;
            }
        }

        History.navigate(`/project/${project.id}/`);
    }

    public render() {
        const { project, isSelected } = this.props;

        return (
            <>
                {this.state.showExportModal && (
                    <Modal
                        title={t.exportProject}
                        onClose={this.closeExportProjectModal}
                        color="white"
                        maxWidth="900px"
                    >
                        <ExportProject
                            projectId={this.props.project.id}
                            projectSettingToken={this.props.project.shareToken}
                        />
                        <Box justifyContent="end">
                            <Button text onClick={this.closeExportProjectModal}>
                                {t.close}
                            </Button>
                        </Box>
                    </Modal>
                )}

                <Box
                    width="100%"
                    color={isSelected ? 'yellow1' : 'transparent'}
                    minHeight="56px"
                    testId={`row_${this.props.index}`}
                >
                    <Stack flex="fullWidth" spacing="half">
                        <Box
                            flex="none"
                            width="40px"
                            justifyContent="end"
                            alignItems="start"
                            paddingY="base"
                        >
                            <IconButton
                                icon="open"
                                testId={`btn_${project.name}`}
                                onClick={() => this.onOpen(project)}
                            />
                        </Box>
                        <Box flex="shrinkAndGrow">
                            <Heading style="semibold" width="100%">
                                <EditableText
                                    changeCriteria="blur"
                                    maxLength={AppConstants.projectNameMaxLength}
                                    value={project.name}
                                    onChange={this.onProjectNameChange}
                                    placeholder={t.projectProjectName}
                                />
                            </Heading>
                        </Box>
                        <Box justifyContent="end">
                            <Stack justifyContent="end" alignItems="center">
                                {project.archived && (
                                    <Icon {...iconProps} icon="archive" title={t.projectArchived} />
                                )}
                                {project.locked && (
                                    <ProjectStateIndicator
                                        projectId={project.id}
                                        projectState={project.state}
                                        showAsBadge
                                    />
                                )}

                                <Icon
                                    icon="satellite_off"
                                    title={t.projectHasFloorPlans}
                                    hidden={!this.props.project.hasFloorPlanOrLocation}
                                />
                            </Stack>
                        </Box>
                        <Box flex="none" justifyContent="start" width="180px">
                            <Stack>
                                <Text inline>
                                    <DateField
                                        date={this.props.project.updatedDate}
                                        display="date"
                                    />
                                </Text>
                                <Text inline color="grey4">
                                    <DateField
                                        date={this.props.project.updatedDate}
                                        display="time"
                                    />
                                </Text>
                            </Stack>
                        </Box>
                        <Box flex="none" justifyContent="start" width="100px">
                            {this.state.deepDataUpdated ? (
                                <Text testId={`devices_quantity_${project.name}`}>
                                    {this.props.project.devicesQuantity}
                                </Text>
                            ) : (
                                <Spinner size={12} />
                            )}
                        </Box>
                        <Box flex="none" justifyContent="start" width="40px">
                            <Border color="grey4">
                                <IconButton
                                    testId={`${project.name}_checkbox`}
                                    icon="check"
                                    size="ms"
                                    color={isSelected ? 'grey9' : 'transparent'}
                                    onClick={this.onSelectedChanged}
                                />
                            </Border>
                        </Box>
                        <Box flex="none" justifyContent="end" width="110px">
                            {this.props.currentView === ProjectDbOrigin.asdLocalUserData &&
                                this.shouldShowExportButton() && (
                                    <IconButton
                                        icon="file_download"
                                        size="ms"
                                        text={t.projectExport}
                                        onClick={this.onDownloadProjectClick}
                                    />
                                )}
                        </Box>
                        <Box flex="none" justifyContent="end" width="60px" paddingRight="half">
                            {this.renderProjectDropdown()}
                        </Box>
                    </Stack>
                </Box>
            </>
        );
    }

    private renderProjectDropdown = () => {
        if (this.props.isMultiSelecting) {
            return null;
        }

        return (
            <DropDownMenu
                testId={`select_ddm_${this.props.project.name}`}
                testIdChild="ddm_project"
                maxHeight={500}
            >
                <DropDownMenuLink
                    testId="btn_open_in_ddm_project"
                    label={t.open}
                    icon="open"
                    link={`/project/${this.props.project.id}/`}
                />
                <DropDownMenuLink
                    testId="btn_view_reports_in_ddm_project"
                    label={t.viewReports}
                    icon="description"
                    link={`/project/${this.props.project.id}/reports/`}
                />
                <DropDownMenuButton
                    testId="btn_archive_in_ddm_project"
                    label={
                        this.props.project.archived ? t.projectRestoreFromArchive : t.projectArchive
                    }
                    icon={this.props.project.archived ? 'unarchive' : 'archive'}
                    onClick={this.onArchiveChange}
                />
                <DropDownMenuButton
                    testId="btn_share_to_acs_from_project_list"
                    label={t.exportProject}
                    icon={'export'}
                    onClick={this.openExportProjectModal}
                />
                <DropDownMenuButton
                    testId="btn_duplicate_in_ddm_project"
                    label={t.duplicate}
                    icon={'content_copy'}
                    onClick={this.onDuplicateProjectClick}
                />
                <DropDownMenuButton
                    testId="btn_export_in_ddm_project"
                    label={t.downloadProjectFile}
                    icon={'file_download'}
                    onClick={this.onDownloadProjectClick}
                />
                <DropDownMenuDelete
                    testId="btn_delete_in_ddm_project"
                    onDelete={this.onDeleteClick}
                />
            </DropDownMenu>
        );
    };

    private openExportProjectModal = () => {
        eventTracking.logUserEvent('User Projects', 'Open Share To ACS Modal');
        this.setState({ showExportModal: true });
    };

    private closeExportProjectModal = () => {
        this.setState({ showExportModal: false });
    };

    private onDeleteClick = () => {
        eventTracking.logUserEvent('User Projects', 'Delete Project');
        this.actionService.deleteUserProject(this.props.project.id, this.props.project.rev);
    };

    private onProjectNameChange = (value: string) => {
        if (value.trim() && value !== this.props.project.name) {
            this.userProjectsService.updateProjectTitle(value, this.props.project.id);
        }
    };

    private onArchiveChange = () => {
        eventTracking.logUserEvent(
            'User Projects',
            'Set Archived',
            String(!this.props.project.archived),
        );

        this.userProjectsService.setArchived(this.props.project.id, !this.props.project.archived);
    };

    private onDownloadProjectClick = () => {
        eventTracking.logUserEvent('User Projects', 'Export Project');
        this.userProjectsService.updateExportDate(this.props.project.id);
        this.actionService.exportProject(this.props.project.id);
    };

    private onDuplicateProjectClick = () => {
        eventTracking.logUserEvent('User Projects', 'Duplicate Project');
        this.actionService.duplicateProject(
            this.props.project.id,
            this.props.usedImageQuota,
            this.props.totalImageQuota,
        );
    };

    private onSelectedChanged = () => {
        if (!this.props.isSelected) {
            eventTracking.logUserEvent('User Projects', 'Select Project');
            this.actionService.selectProject(this.props.project.id);
        } else {
            eventTracking.logUserEvent('User Projects', 'Deselect Project');
            this.actionService.unselectProject(this.props.project.id);
        }
    };
}

export const ProjectItem = connect(mapStateToProps)(ProjectItemContainer);
