import { deviceTypeCheckers } from 'app/core/persistence';
import { getCurrentProjectItems } from 'app/modules/common';
import { createSelector } from 'reselect';
import { getSelectedDeviceId } from './getSelectedDevice';

export const getFilterOutdoorForSelectedDevice = createSelector(
    [getSelectedDeviceId, getCurrentProjectItems],
    (id, items) => {
        if (!id) return false;
        const item = items[id];
        if (!item) return false;
        if (deviceTypeCheckers.isCamera(item)) {
            return item.properties.camera.filter.outdoor;
        }
        if (deviceTypeCheckers.isSensorUnit(item)) {
            return item.properties.sensorUnit.filter.outdoor;
        }
        if (deviceTypeCheckers.isSpeaker(item)) {
            return item.properties.speaker.filter.outdoor;
        }
        if (deviceTypeCheckers.isAlerter(item)) {
            return item.properties.alerter.filter.outdoor;
        }
        if (deviceTypeCheckers.isRadarDetector(item)) {
            return item.properties.radarDetector.filter.outdoor;
        }
        if (deviceTypeCheckers.isPac(item)) {
            return item.properties.pac.filter.outdoor;
        }
        if (deviceTypeCheckers.isPeopleCounter(item)) {
            return item.properties.peopleCounter.filter.outdoor;
        }
        if (deviceTypeCheckers.isDoorController(item)) {
            return item.properties.doorController.filter.outdoor;
        }
        if (deviceTypeCheckers.isMainUnit(item)) {
            return item.properties.mainUnit.filter.outdoor;
        }
        if (deviceTypeCheckers.isDecoder(item)) {
            return item.properties.decoder.filter.outdoor;
        }
        if (deviceTypeCheckers.isBodyWornCamera(item)) {
            return item.properties.bodyWornCamera.filter.outdoor;
        }
        if (deviceTypeCheckers.isCameraExtension(item)) {
            return item.properties.cameraExtension.filter.outdoor;
        }
        if (deviceTypeCheckers.isDockingStation(item)) {
            return item.properties.dockingStation.filter.outdoor;
        }
        if (deviceTypeCheckers.isConnectivityDevice(item)) {
            return item.properties.connectivityDevice.filter.outdoor;
        }

        console.error('No outdoor filter found for item: ', item);
        return false;
    },
);
