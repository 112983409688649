import { t } from 'app/translate';
import * as React from 'react';
import type { IMenuItem } from 'app/components';
import { MenuGroup, Stack } from 'app/components';
import { ProjectMenuItem } from './ProjectMenuItem';
import { withTrailingSlash } from 'app/utils';

interface IProjectMainMenuProps {
    basePath: string;
    namespace: ApplicationNamespace;
    validRecordingSolution: boolean;
}

export const recordingMenuTestId = 'li_recording';
export const systemAccessoriesMenuTestId = 'li_accessories';

export class ProjectMainMenu extends React.Component<IProjectMainMenuProps> {
    private firstGroup: IMenuItem[];
    private secondGroup: IMenuItem[];
    private thirdGroup: IMenuItem[];

    constructor(props: IProjectMainMenuProps) {
        super(props);

        const basePath = withTrailingSlash(props.basePath);

        this.firstGroup = [
            {
                link: `${basePath}dashboard/`,
                icon: 'dashboard',
                text: t.projectOverview,
                testId: 'li_dashboard',
            },
        ];

        this.secondGroup = [
            {
                link: `${basePath}devices/`,
                icon: 'device',
                text: t.devices,
                testId: 'li_devices',
            },

            {
                link: `${basePath}recording/`,
                icon: 'recording',
                text: t.recording,
                testId: recordingMenuTestId,
                warn: true,
            },
            {
                link: `${basePath}accessories/`,
                icon: 'category',
                text: t.accessories,
                testId: systemAccessoriesMenuTestId,
            },
            {
                link: `${basePath}customitems/`,
                icon: 'list_alt',
                text: t.other,
                testId: 'li_customitems',
            },
        ];

        this.thirdGroup = [
            {
                link: `${basePath}maps/`,
                icon: 'place',
                text: t.maps,
                testId: 'li_maps',
            },
            {
                link: `${basePath}reports/quotation/edit/`,
                icon: 'description',
                text: t.reports,
                testId: 'li_reports',
            },
            {
                link: `${basePath}documentation/`,
                icon: 'import_contacts',
                text: t.documentation,
                testId: 'li_documentation',
            },
        ];
    }

    public render() {
        return (
            <Stack vertical flex="shrinkAndGrow" spacing="none">
                <nav data-test-id="project_menu_list_nav">
                    <MenuGroup>{this.firstGroup.map(this.getMenuItem)}</MenuGroup>
                    <MenuGroup>{this.secondGroup.map(this.getMenuItem)}</MenuGroup>
                    <MenuGroup>{this.thirdGroup.map(this.getMenuItem)}</MenuGroup>
                </nav>
            </Stack>
        );
    }

    private getMenuItem = (item: IMenuItem) => (
        <ProjectMenuItem
            key={item.link}
            link={item.link}
            icon={item.icon}
            text={item.text}
            testId={item.testId}
            warn={!this.props.validRecordingSolution && item.warn}
        />
    );
}
