import { injectable } from 'inversify';
import type {
    Id,
    IItemEntity,
    IPartnerConfigAllowlist,
    IPersistence,
    ItemRelationType,
} from 'app/core/persistence';
import { CurrentProjectService, ItemService } from 'app/core/persistence';
import { MountService } from './Mounts.service';
import type { PiaId, IPiaAccessory, IPiaRelationReference, IPiaLocation } from 'app/core/pia';
import {
    PiaItemState,
    PiaRelationService,
    filterProducts,
    PiaAccessoryCategory,
} from 'app/core/pia';
import { ProductQueriesService } from './ProductQueries.service';
import { AccessoryPersistenceService } from './AccessoryPersistence.service';
import { memoize } from 'lodash-es';
import type { IAccessory } from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';

@injectable()
export class AccessorySelectorService {
    /**
     * Get compatible accessories
     *
     * @param  {number} productId - Product Id.
     * @param  {IPiaRelationReference[]} piaRelations - Product relations.
     * @param  {boolean} isStandalone - If false filter out lenses to avoid duplicated functionality in tabs Lenses and Accessories {@see getIsStandalone}.
     */
    public getCompatibleIAccessoriesMemoizedByProductId = memoize(
        (productId: number, piaRelations: IPiaRelationReference[], isStandalone: boolean) => {
            return this.getCompatibleIAccessoriesFromRelations(
                productId,
                piaRelations,
                isStandalone,
            );
        },
    );

    constructor(
        private mountService: MountService,
        private productQueries: ProductQueriesService,
        private accessoryPersistenceService: AccessoryPersistenceService,
        private piaRelationService: PiaRelationService,
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public resetEnvironment = async (deviceId: Id): Promise<void> => {
        const mounts = (
            await Promise.all([
                this.accessoryPersistenceService.getPrimaryMountFromDevice(deviceId),
                this.accessoryPersistenceService.getEnvironmentFromDevice(deviceId),
                this.accessoryPersistenceService.getChildItemsByRelation(deviceId, 'deviceMount'),
                this.accessoryPersistenceService.getChildItemsByRelation(
                    deviceId,
                    'environmentMount',
                ),
            ])
        ).flat();

        await this.accessoryPersistenceService.deleteItems(mounts.filter(isDefined));
    };

    public resetAll = async (deviceId: Id): Promise<void> => {
        await this.resetEnvironment(deviceId);
        await this.accessoryPersistenceService.delete(deviceId);
    };

    public async setEnvironment(
        deviceId: Id,
        deviceProductId: PiaId,
        environmentItemId: Id | undefined,
        newEnvironmentProductId: PiaId,
        projectLocation: IPiaLocation,
        notToBeFollowedByMountIds: PiaId[],
        onlyOutdoor?: boolean,
        partnerConfig?: IPartnerConfigAllowlist,
    ) {
        const environment = environmentItemId
            ? await this.itemService.updateItem(environmentItemId, {
                  productId: newEnvironmentProductId,
              })
            : await this.accessoryPersistenceService.setItem(
                  deviceId,
                  newEnvironmentProductId,
                  'environment',
              );

        const regions = projectLocation.productVersions;

        const primaryMounts = this.mountService.getPrimaryMounts(
            newEnvironmentProductId,
            deviceProductId,
            regions,
            onlyOutdoor,
            partnerConfig,
        );

        /** First primary mount that isn't included in notToBeFollowedBy list. */
        const mountIncluded = primaryMounts.find((pm) => pm === null);
        const mountToSelect =
            mountIncluded === null
                ? null
                : primaryMounts.find(
                      (pm) => pm !== null && !notToBeFollowedByMountIds?.includes(pm.id),
                  )?.id;

        await this.setPrimaryMount(
            deviceId,
            mountToSelect ?? null,
            regions,
            environment ? environment.productId : null,
            notToBeFollowedByMountIds,
            partnerConfig,
            onlyOutdoor,
        );
    }

    public setPrimaryMount = async (
        deviceId: Id,
        primaryMountProductId: PiaId | null,
        regions: string[],
        environmentProductId: number | null,
        notToBeFollowedByIds: PiaId[],
        partnerConfig?: IPartnerConfigAllowlist,
        onlyOutdoor?: boolean,
    ) => {
        const deviceItem = await this.accessoryPersistenceService.get(deviceId);
        const oldPrimaryMount = (
            await this.accessoryPersistenceService.getPrimaryMountFromDevice(deviceId)
        )[0];

        const primaryMountItem =
            oldPrimaryMount && oldPrimaryMount._id
                ? await this.accessoryPersistenceService.updateProductId(
                      oldPrimaryMount,
                      primaryMountProductId,
                  )
                : await this.accessoryPersistenceService.setItem(
                      deviceId,
                      primaryMountProductId,
                      'primaryMount',
                  );

        const { deviceMounts = [], environmentMounts = [] } =
            primaryMountItem?.productId && environmentProductId && deviceItem.productId
                ? this.mountService.getMounts(
                      primaryMountItem.productId,
                      environmentProductId,
                      deviceItem.productId,
                      regions,
                      undefined,
                      undefined,
                      partnerConfig,
                      onlyOutdoor,
                  )
                : {};

        const selectedDeviceMountProductId = this.getFirstMountOption(
            deviceMounts,
            notToBeFollowedByIds,
        ).map((mounts) => mounts.id);

        const selectedEnvironmentMountProductId = this.getFirstMountOption(
            environmentMounts,
            notToBeFollowedByIds,
        ).map((mounts) => mounts.id);
        const setDeviceMount = this.setMountingOption(
            deviceId,
            selectedDeviceMountProductId,
            'deviceMount',
        );
        const setEnvironmentMount = this.setMountingOption(
            deviceId,
            selectedEnvironmentMountProductId,
            'environmentMount',
        );
        await Promise.all([setDeviceMount, setEnvironmentMount]);

        if (oldPrimaryMount && oldPrimaryMount._id) {
            // Remove accessories associated with the old primary mount
            const relatedItemIds = this.currentProjectService
                .getItemRelations(oldPrimaryMount._id)
                .map((relation) => relation.childId);
            relatedItemIds.forEach((id) => this.itemService.deleteItem(id));
        }
    };

    public setMountingOption = async (
        deviceId: Id,
        options: PiaId[],
        itemRelationType: ItemRelationType,
    ) => {
        // Delete previous items and relations including child items (accessories)
        await this.accessoryPersistenceService.deleteMountOption(deviceId, itemRelationType);

        /*
         * No options means mount included and should be persisted to make sure
         * the user choice is consistent, else the mountingOption would be
         * recalculated on each display of the mounting selector possibly causing
         * the mounting chain to automatically change without user interaction if
         * PIA data changes.
         */
        if (options.length === 0) {
            await this.accessoryPersistenceService.setItem(deviceId, null, itemRelationType);
            return;
        }

        let parentId = deviceId;

        // Add new items and relations
        for (const option of options) {
            const item = await this.accessoryPersistenceService.setItem(
                parentId,
                option,
                itemRelationType,
            );
            // When the mount option needs multiple items we create a parent relation chain
            parentId = item._id;
        }
    };

    public async addStandaloneItem(piaId: PiaId) {
        const item = await this.accessoryPersistenceService.addStandaloneItem(piaId);
        return item._id;
    }

    public setMountingFilterOutdoor = async (
        itemId: Id,
        outdoor: boolean,
    ): Promise<IPersistence<IItemEntity> | undefined> => {
        const item = await this.itemService.getItem(itemId);
        let update = false;
        if (item.properties.camera || item.properties.sensorUnit) {
            (item.properties.camera ?? item.properties.sensorUnit)!.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.speaker) {
            item.properties.speaker.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.alerter) {
            item.properties.alerter.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.radarDetector) {
            item.properties.radarDetector.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.pac) {
            item.properties.pac.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.peopleCounter) {
            item.properties.peopleCounter.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.doorController) {
            item.properties.doorController.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.decoder) {
            item.properties.decoder.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.bodyWornCamera) {
            item.properties.bodyWornCamera.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.cameraExtension) {
            item.properties.cameraExtension.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.dockingStation) {
            item.properties.dockingStation.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.connectivityDevice) {
            item.properties.connectivityDevice.filter.outdoor = outdoor;
            update = true;
        }
        if (item.properties.pagingConsole) {
            item.properties.pagingConsole.filter.outdoor = outdoor;
            update = true;
        }
        if (update) {
            return this.itemService.updateItem(item._id, { properties: item.properties });
        }

        throw new Error('Item type does not have filter outdoor property');
    };

    private piaAccessoryToIAccessoryNoChildren =
        (parentId: number | null) =>
        (accessory: IPiaAccessory): IAccessory => ({
            productId: accessory.id,
            productCategory: accessory.category,
            name: accessory.name,
            discontinued: accessory.state > PiaItemState.EXTERNALLY_ANNOUNCED,
            quantity: 1,
            isRecommended: parentId
                ? this.piaRelationService.isRecommended(parentId, accessory.id)
                : false,
            isIncluded: parentId
                ? this.piaRelationService.isIncluded(parentId, accessory.id)
                : false,
            children: [],
            versions: accessory.versions,
            msrp: {},
            sortingPriority: accessory.properties
                ? accessory.properties.sortingPriority
                : undefined,
        });

    private getCompatibleIAccessoriesFromRelations(
        productId: number | null,
        piaRelations: IPiaRelationReference[],
        isStandalone: boolean,
        regions?: string[],
    ) {
        return this.productQueries
            .getAccessoriesByRelations(piaRelations, regions)
            .filter((accessory) =>
                // filter out lenses to avoid duplicated functionality in tabs Lenses and Accessories.
                isStandalone
                    ? true
                    : filterProducts.byExcludingCategory(PiaAccessoryCategory.LENSES)(accessory),
            )
            .toList()
            .map((piaAccessory) =>
                this.piaAccessoryToIAccessoryNoChildren(productId)(piaAccessory),
            );
    }

    private getFirstMountOption = (
        mountOptions: IPiaAccessory[][],
        notToBeFollowedByIds: PiaId[],
    ): IPiaAccessory[] => {
        const firstAcceptable = mountOptions.findIndex((option) =>
            option.every((o) => !notToBeFollowedByIds.includes(o.id)),
        );
        // If there is no option that satisfies notToBeFollowedBy, simply select first option in list.
        const indexToSelect = firstAcceptable === -1 ? 0 : firstAcceptable;
        return mountOptions.length === 0
            ? []
            : mountOptions[indexToSelect].filter((option) => option !== null);
    };
}
