import type { INetworkRange, IPersistableRangeType } from 'app/core/persistence';
import { getNetworkRangeType } from 'app/core/persistence';
import type { IPv6 } from 'ip-num';
import { Validator, IPv4, RangedSet } from 'ip-num';
import { createSelector } from 'reselect';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItemsArray, getCurrentProjectNetworkSettings } from '../../project';
import { getReservedRanges } from './getReservedRanges';

/** Checks how many slots in an IP range that are occupied by non corresponding device types */
export const getNbrNonCorrespondingTypeInRange = createSelector(
    [
        getCurrentProjectItemsArray,
        getReservedRanges,
        getPiaItemsRecord,
        getCurrentProjectNetworkSettings,
    ],
    (devices, ranges, piaItems, networkSettings): Record<IPersistableRangeType, number> => {
        const inRanges = { cameras: 0, other: 0, recorders: 0 };
        if (!networkSettings || !ranges || networkSettings.dhcp) return inRanges;

        const cameraRange = toRangedSet(ranges.cameras);
        const otherRange = toRangedSet(ranges.other);
        const recorderRange = toRangedSet(ranges.recorders);

        // Account for router if within ranges
        const ipv4Router = IPv4.fromDecimalDottedString(networkSettings.defaultRouter);
        if (isWithinRange(cameraRange, ipv4Router)) inRanges.cameras++;
        if (isWithinRange(otherRange, ipv4Router)) inRanges.other++;
        if (isWithinRange(recorderRange, ipv4Router)) inRanges.recorders++;

        devices.forEach((device) => {
            const piaItem = device.productId ? piaItems[device.productId] : null;
            const deviceType = getNetworkRangeType(device.properties, piaItem);

            device.networkSettings?.forEach((setting) =>
                setting.addresses.forEach((address) => {
                    // Skip DHCP addresses
                    if (!Validator.isValidIPv4String(address)[0]) {
                        return;
                    }
                    const ipv4Address = IPv4.fromDecimalDottedString(address);
                    const inCameraRange = isWithinRange(cameraRange, ipv4Address);
                    const inOtherRange = isWithinRange(otherRange, ipv4Address);
                    const inRecorderRange = isWithinRange(recorderRange, ipv4Address);

                    // Only add non corresponding device types' addresses
                    if (inCameraRange && deviceType !== 'cameras') inRanges.cameras++;
                    if (inOtherRange && deviceType !== 'other') inRanges.other++;
                    if (inRecorderRange && deviceType !== 'recorders') inRanges.recorders++;
                }),
            );
        });
        return inRanges;
    },
);

const toRangedSet = (range: INetworkRange) =>
    RangedSet.fromRangeString(`${range.ipStart}-${range.ipEnd}`);

const isWithinRange = (range: RangedSet<IPv4> | RangedSet<IPv6>, address: IPv4) => {
    return (
        address.isGreaterThanOrEquals(range.getFirst()) &&
        address.isLessThanOrEquals(range.getLast())
    );
};
