import { useEffect, useRef } from 'react';

/**
 * Hook used for setting up mount/unmount actions.
 * @param onMountAction Called when the component is mounted
 * @param onUnmountAction Called when the component is unmounted
 */
export const useMount = (onMountAction: () => void, onUnmountAction?: () => void) => {
    /** Ensures that useMount action has only been run once. */
    const hasBeenRun = useRef(false);
    useEffect(() => {
        // Run on mount action
        if (!hasBeenRun.current) {
            onMountAction();
            hasBeenRun.current = true;
        }
        return () => {
            // Run unmount action when component unloads
            onUnmountAction && onUnmountAction();
        };
        // It is important that this is only run on mount and unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
