import { createCachedSelector } from 're-reselect';
import { isCamera, isSensorUnit } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';
import { getPiaItemsRecord } from '../../piaDevices';
import { getPiaIdFromProps } from '../../selectors';

/**
 * Checks if scenario selection is eligible for a piaItem
 * @returns boolean value
 */
export const getPiaItemShouldHaveScenarios = createCachedSelector(
    [getPiaItemsRecord, getPiaIdFromProps],
    (piaItemsRecord, piaId): boolean => {
        if (!piaId) {
            return false;
        }
        const piaItem = piaItemsRecord[piaId];

        return isCamera(piaItem) || isSensorUnit(piaItem);
    },
)(toCacheKey);
