import type * as React from 'react';
import type * as leaflet from 'leaflet';
import { useEffect, useCallback } from 'react';
import type { PolyLine } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import { MapsActionService, MapsService } from '../../services';
import { useMount, useOldValueIfUnchanged } from 'app/hooks';
import { EventsEnum } from './draw/Events';
import { useMapContext } from '../context';
import { getDerotatedBlockers } from '../../selectors';
import { diffBlockers } from '../../utils';
import { isDefined } from 'axis-webtools-util';

export const Blockers: React.FC = () => {
    const mapsService = useService(MapsService);
    const mapsActionService = useService(MapsActionService);
    const { leafletMap } = useMapContext();
    const rawBlockers = useSelector(getDerotatedBlockers);

    useMount(
        () => {},
        () => {
            leafletMap.swapBlockers([]);
        },
    );

    // Don't get new blockers every time
    const blockers = useOldValueIfUnchanged(rawBlockers);

    /** Set blockers in redux when they change in leaflet */
    const setBlockerPolylines = useCallback(
        (newBlockerPolylines: PolyLine[]) => {
            const diff = diffBlockers(blockers, newBlockerPolylines);

            if (diff.polylinesToAdd.length > 0) {
                eventTracking.logUserEvent('Maps', 'Add blocker');
                mapsService.addBlockers(diff.polylinesToAdd);
            } else if (diff.blockersToDelete.length > 0) {
                eventTracking.logUserEvent('Maps', 'Remove blocker');
                mapsService.deleteBlockers(diff.blockersToDelete.map(({ _id }) => _id));
            } else if (diff.blockersToUpdate.length > 0) {
                eventTracking.logUserEvent('Maps', 'Edit blockers');
                mapsService.updateBlockers(diff.blockersToUpdate);
            }
        },
        [blockers, mapsService],
    );

    /** Swap out blockers when they change */
    useEffect(() => {
        leafletMap.swapBlockers(blockers.map(({ latLngs }) => latLngs).filter(isDefined));
    }, [leafletMap, blockers]);

    /** Register and unregister draw event listeners */
    useEffect(() => {
        const onCreateStart = () => {
            mapsActionService.toggleFreeTextTool(false);
            mapsActionService.updateBlockerEditState('create_start');
        };

        const onCreating = () => mapsActionService.updateBlockerEditState('creating');

        const onCreateStop = () => mapsActionService.updateBlockerEditState('none');

        const onCreated = (e: leaflet.LeafletEvent) => {
            const blocker = e.layer as leaflet.Polygon | leaflet.Polyline;
            const newBlocker = [blocker.getLatLngs()] as Array<leaflet.LatLng[] | leaflet.LatLng[]>;
            leafletMap.addBlockers(newBlocker);
            const blockerPolylines = leafletMap.getBlockers();
            setBlockerPolylines(blockerPolylines);
        };

        const onEditStart = () => {
            mapsActionService.toggleFreeTextTool(false);
            mapsActionService.updateBlockerEditState('edit_start');
        };
        const onEditing = () => mapsActionService.updateBlockerEditState('none');

        const onEditStop = () => mapsActionService.updateBlockerEditState('none');

        const onEdited = () => {
            const blockerPolylines = leafletMap.getBlockers();
            setBlockerPolylines(blockerPolylines);
        };

        const onDeleteStart = () => {
            mapsActionService.toggleFreeTextTool(false);
            mapsActionService.updateBlockerEditState('deleting');
        };
        const onDeleteStop = () => mapsActionService.updateBlockerEditState('none');

        leafletMap.map.on(EventsEnum.Created, onCreated);
        leafletMap.map.on(EventsEnum.Edited, onEdited);
        leafletMap.map.on(EventsEnum.CreateStart, onCreateStart);
        leafletMap.map.on(EventsEnum.Creating, onCreating);
        leafletMap.map.on(EventsEnum.CreateStop, onCreateStop);
        leafletMap.map.on(EventsEnum.EditStart, onEditStart);
        leafletMap.map.on(EventsEnum.Editing, onEditing);
        leafletMap.map.on(EventsEnum.EditStop, onEditStop);
        leafletMap.map.on(EventsEnum.DeleteStart, onDeleteStart);
        leafletMap.map.on(EventsEnum.DeleteStop, onDeleteStop);

        return () => {
            leafletMap.map.off(EventsEnum.Created, onCreated);
            leafletMap.map.off(EventsEnum.Edited, onEdited);
            leafletMap.map.off(EventsEnum.CreateStart, onCreateStart);
            leafletMap.map.off(EventsEnum.Creating, onCreating);
            leafletMap.map.off(EventsEnum.CreateStop, onCreateStop);
            leafletMap.map.off(EventsEnum.EditStart, onEditStart);
            leafletMap.map.off(EventsEnum.Editing, onEditing);
            leafletMap.map.off(EventsEnum.EditStop, onEditStop);
            leafletMap.map.off(EventsEnum.DeleteStart, onDeleteStart);
            leafletMap.map.off(EventsEnum.DeleteStop, onDeleteStop);
        };
    }, [leafletMap, setBlockerPolylines, mapsActionService]);

    return null;
};

Blockers.displayName = 'Blockers';
