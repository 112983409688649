import type { IRelatedPiaItem } from 'app/modules/common';
import { getCurrentProjectItems, getPiaItemsRecord } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

const getParentDeviceId = (state: IStoreState) => state.deviceSelector.parentId;

const getParentPiaDeviceId = createSelector(
    [getCurrentProjectItems, getParentDeviceId],
    (items, parentDeviceId) => {
        if (!parentDeviceId) {
            return undefined;
        }

        return items[parentDeviceId]?.productId;
    },
);

export const getCompatibleSensorUnits = createSelector(
    [getPiaItemsRecord, getParentPiaDeviceId],
    (piaItems, productId) => {
        if (!productId) {
            return [];
        }

        const piaItem = piaItems[productId];
        const relations = piaItem.relations.filter(
            (relation) => relation.relationType === 'compatible',
        );
        return Object.values(relations)
            .map(
                (relation) =>
                    ({
                        ...piaItems[relation.id],
                        isIncluded: relation.relationType === 'includes',
                        isRecommended: relation.relationType === 'recommends',
                    }) as IRelatedPiaItem,
            )
            .filter(
                (relation) =>
                    relation.category === 'sensorunits' || relation.category === 'thermalsensor',
            );
    },
);
