import type { IBaseEntity } from 'app/core/persistence';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Migrate old property isChannelsActive and allows channels to be undefined in
 * main unit and encoder filters.
 */
@injectable()
export class Migration24to25 extends MigrationProviderBase {
    public from: number = 24;
    public to: number = 25;

    public provideMigration() {
        return async (entity: any): Promise<IBaseEntity> => {
            if (entity?.properties?.mainUnit?.filter?.isChannelsActive === false) {
                delete entity.properties.mainUnit.filter.channels;
                delete entity.properties.mainUnit.filter.isChannelsActive;
                return entity;
            }

            if (entity?.properties?.mainUnit?.filter?.isChannelsActive === true) {
                delete entity.properties.mainUnit.filter.isChannelsActive;
                return entity;
            }

            if (entity?.properties?.encoder?.filter?.isChannelsActive === false) {
                delete entity.properties.encoder.filter.channels;
                delete entity.properties.encoder.filter.isChannelsActive;
                return entity;
            }

            if (entity?.properties?.encoder?.filter?.isChannelsActive === true) {
                delete entity.properties.encoder.filter.isChannelsActive;
                return entity;
            }

            return entity;
        };
    }
}
