import * as React from 'react';
import { css, cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import type { IExtendableComponent } from '../../models';
import { extendableProps } from '../../services';
import type { IAutoTestable } from 'app/components';

interface IEditableParagraphProps extends IExtendableComponent, IAutoTestable {
    value: string;
    placeholder: string;
    rows: number;
    maxLength?: number;
    disabled?: boolean;
    onChange(newValue: string): void;
}

const textareaStyle = css`
    width: 100%;
    height: 100%;
    resize: none;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background: transparent;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;

    ::placeholder {
        color: ${ColorsEnum.grey5};
    }

    &[disabled] {
        color: ${ColorsEnum.grey9};
        opacity: 1 !important;
    }
`;

const containerStyle = css`
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
`;

const overlayStyle = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: transparent;
    background-color: transparent;
    overflow: auto;
`;

const highlightStyle = css`
    position: relative;
    color: transparent;
    background-color: transparent;

    ::after {
        content: '';
        position: absolute;
        left: 1px;
        right: 1px;
        top: 2.2ex;
        bottom: 1px;
        border-bottom: 2px dotted ${ColorsEnum.blue};
        opacity: 0.3;
        transition: all 75ms;
    }
`;

const activeStyle = css`
    ::after {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-bottom: 2px solid ${ColorsEnum.blue};
        opacity: 0.75;
    }
`;

const disabledStyle = css`
    ::after {
        display: none;
    }
`;

/**
 * An in-place editable paragraph of text.
 */
export const EditableParagraph: React.FunctionComponent<IEditableParagraphProps> = ({
    value,
    placeholder,
    rows,
    maxLength,
    onChange: onChangeCallback,
    testId,
    disabled,
    ...restProps
}) => {
    const [currentValue, setCurrentValue] = React.useState<string>(value);
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const overlayRef = React.useRef<HTMLDivElement>(null);

    /** Listen to prop changes for value */
    React.useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value ?? '';
        setCurrentValue(newValue);
        onChangeCallback(newValue.trim());
    };

    const onFocus = () => {
        setIsActive(true);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Escape') {
            setCurrentValue(value);
            onChangeCallback(value.trim());
        }
    };

    const onBlur = () => {
        setIsActive(false);
        if (currentValue.trim() === '') {
            setCurrentValue(value);
        }
    };

    const onScroll = (event: React.UIEvent<HTMLTextAreaElement>) => {
        overlayRef.current?.scrollTo({ top: event.currentTarget.scrollTop });
    };

    return (
        <div {...extendableProps(restProps, { className: containerStyle }, true)}>
            <div className={overlayStyle} ref={overlayRef}>
                {(currentValue || placeholder).split('\n').map((stringValue, index) => [
                    <mark
                        key={index}
                        className={cx(
                            highlightStyle,
                            isActive && activeStyle,
                            disabled && disabledStyle,
                        )}
                    >
                        {stringValue}
                    </mark>,
                    '\n',
                ])}
            </div>
            <textarea
                rows={rows}
                maxLength={maxLength}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onScroll={onScroll}
                value={currentValue}
                data-test-id={testId}
                placeholder={placeholder}
                className={textareaStyle}
                spellCheck={false}
                disabled={disabled}
            />
        </div>
    );
};

EditableParagraph.displayName = 'EditableParagraph';
