import * as React from 'react';
import { Box, Button } from 'app/components';
import { css, cx } from '@emotion/css';
import type { IApplicationItem } from 'app/modules/common';
import {
    getIsOtherSelectorOpen,
    getIsStandalone,
    hoverContainerStyle,
    isAxis,
} from 'app/modules/common';
import { ColorsEnum } from 'app/styles';
import { AppConstants } from 'app/AppConstants';
import { t } from 'app/translate';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import type { Id } from 'app/core/persistence';
import { getShowReadMoreModal } from '../selectors';
import type { IReadMore } from '../models';
import { ReadMoreModal } from './ReadMoreModal';
import { ApplicationBulletPoints } from './ApplicationBulletPoints';
import { ApplicationInfo } from './ApplicationInfo';
import { AddonSelectorActionService } from '../services';

interface IApplicationHoverSectionProps {
    application: IApplicationItem;
    parentDeviceId?: Id;
    isSelected: boolean;
    isClicked: boolean;
    /** Sets hover to false. When a modal is open the parent div does not register onMouseLeave which causes UI bug. */
    setHoverFalse(): void;
}

const depthStyle = css`
    z-index: ${AppConstants.productItemDepth};
`;

export const ApplicationHoverSection: React.FC<IApplicationHoverSectionProps> = ({
    application,
    parentDeviceId,
    isSelected,
    isClicked,
    setHoverFalse,
}) => {
    const borderColor = isSelected ? 'yellow4' : 'grey3';

    const borderColorStyle = css`
        border-color: ${ColorsEnum[borderColor]};
    `;

    const hoverSectionStyle = css`
        width: 197px;
        background-color: ${isClicked ? ColorsEnum.yellow1 : ColorsEnum.white};
        position: absolute;
        top: -6px;
        padding-bottom: 5px;
        border-style: solid;
        border-width: 0px 1px 1px 1px;
        border-radius: 0 0 8px 8px;
        animation: shadowAnimation 300ms ease-in-out forwards;
        @keyframes shadowAnimation {
            0% {
                box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
            }
            100% {
                box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
            }
        }
    `;

    const addonActionService = useService(AddonSelectorActionService);

    const setClickedApplication = () => {
        return addonActionService.setClickedApplication(application.productId);
    };
    const readMoreState = useSelector(getShowReadMoreModal);
    const isStandAlone = useSelector(getIsStandalone);
    const isPartnerProductOtherSelector = useSelector(getIsOtherSelectorOpen);

    const onClickReadMore = () => {
        setClickedApplication();
        if (application.vendor !== undefined) {
            const readMore: IReadMore = {
                show: true,
                application,
            };
            addonActionService.setShowReadMoreModal(readMore);
            setHoverFalse();
        }
    };

    return (
        <>
            {readMoreState.show &&
                readMoreState.application &&
                (isStandAlone || isPartnerProductOtherSelector) && (
                    <ReadMoreModal
                        application={readMoreState.application ?? application}
                        parentDeviceId={parentDeviceId}
                    />
                )}
            <div className={hoverContainerStyle}>
                <div className={cx(hoverSectionStyle, borderColorStyle, depthStyle)}>
                    <Box
                        testId={`${application.name}_camera_hover_section`}
                        color={isClicked ? 'yellow1' : 'transparent'}
                        direction="column"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        paddingLeft="base"
                        paddingRight="halfCell"
                        paddingBottom="half"
                    >
                        {isAxis(application.vendor) ? (
                            <ApplicationBulletPoints productId={application.productId} />
                        ) : (
                            <ApplicationInfo
                                productId={application.productId}
                                vendorName={application.vendor}
                            />
                        )}
                        {application.vendor && (
                            <Button
                                text
                                onClick={() => {
                                    onClickReadMore();
                                }}
                                testId={`read_more_${application.name}`}
                            >
                                {t.readMore}
                            </Button>
                        )}
                    </Box>
                </div>
            </div>
        </>
    );
};

ApplicationHoverSection.displayName = 'ApplicationHoverSection';
