import { ArrowHelper, Group, Vector3 } from 'three';
import type { ICameraRays } from './getCornerRays';

interface IDebugArrows {
    topLeft: ArrowHelper;
    topRight: ArrowHelper;
    bottomLeft: ArrowHelper;
    bottomRight: ArrowHelper;
}

export class DebugArrows extends Group {
    private debugArrows: {
        selected: IDebugArrows;
        desired: IDebugArrows;
    } = {
        selected: {
            topLeft: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x0000ff),
            topRight: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x0000ff),
            bottomLeft: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x0000ff),
            bottomRight: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x0000ff),
        },
        desired: {
            topLeft: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x00ff00),
            topRight: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x00ff00),
            bottomLeft: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x00ff00),
            bottomRight: new ArrowHelper(new Vector3(), new Vector3(), 10, 0x00ff00),
        },
    };

    constructor() {
        super();
        this.add(this.debugArrows.desired.topLeft);
        this.add(this.debugArrows.desired.topRight);
        this.add(this.debugArrows.desired.bottomLeft);
        this.add(this.debugArrows.desired.bottomRight);
        this.add(this.debugArrows.selected.topLeft);
        this.add(this.debugArrows.selected.topRight);
        this.add(this.debugArrows.selected.bottomLeft);
        this.add(this.debugArrows.selected.bottomRight);
    }

    public update(selectedCameraRays: ICameraRays, desiredCameraRays: ICameraRays) {
        this.debugArrows.selected.topLeft.position.copy(selectedCameraRays.topLeft.origin);
        this.debugArrows.selected.topLeft.setDirection(selectedCameraRays.topLeft.direction);
        this.debugArrows.selected.topRight.position.copy(selectedCameraRays.topRight.origin);
        this.debugArrows.selected.topRight.setDirection(selectedCameraRays.topRight.direction);
        this.debugArrows.selected.bottomLeft.position.copy(selectedCameraRays.bottomLeft.origin);
        this.debugArrows.selected.bottomLeft.setDirection(selectedCameraRays.bottomLeft.direction);
        this.debugArrows.selected.bottomRight.position.copy(selectedCameraRays.bottomRight.origin);
        this.debugArrows.selected.bottomRight.setDirection(
            selectedCameraRays.bottomRight.direction,
        );

        this.debugArrows.desired.topLeft.position.copy(desiredCameraRays.topLeft.origin);
        this.debugArrows.desired.topLeft.setDirection(desiredCameraRays.topLeft.direction);
        this.debugArrows.desired.topRight.position.copy(desiredCameraRays.topRight.origin);
        this.debugArrows.desired.topRight.setDirection(desiredCameraRays.topRight.direction);
        this.debugArrows.desired.bottomLeft.position.copy(desiredCameraRays.bottomLeft.origin);
        this.debugArrows.desired.bottomLeft.setDirection(desiredCameraRays.bottomLeft.direction);
        this.debugArrows.desired.bottomRight.position.copy(desiredCameraRays.bottomRight.origin);
        this.debugArrows.desired.bottomRight.setDirection(desiredCameraRays.bottomRight.direction);
    }
}
