import { Module } from 'app/ioc';
import { AppStore } from 'app/store/AppStore';
import { ProjectMenuAnimationService } from './project/services/ProjectMenuAnimation.service';

@Module({
    binder: (binder) => {
        binder.bindSingleton(AppStore);
        binder.bindService(ProjectMenuAnimationService);
    },
})
export class RoutesModule {}
