import { isDefined } from 'axis-webtools-util';
import { uniq, sortBy } from 'lodash';
import type { IPrice } from 'app/modules/common';
import {
    getPriceListForRegion,
    getPiaItemsWithPrice,
    getAvailableSystemAccessoriesPiaItems,
    getPiaItemsRecord,
    getCurrentProjectItemsArray,
    getCurrentProjectLocation,
} from 'app/modules/common';
import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import type { IPiaItem, PiaId, IPiaAccessory, PiaCategory } from 'app/core/pia';
import {
    PiaItemWearablesCategory,
    PiaItemState,
    PiaAccessoryCategory,
    PiaItemSoftwareCategory,
} from 'app/core/pia';

import {
    PIA_CATEGORY_TO_DISPLAY_CATEGORY,
    DisplayCategory,
    DISPLAY_CATEGORY_TO_PIA_CATEGORY,
} from '../components/DisplayCategory';
import { nameComparator } from 'app/utils';

const AUDIO_AND_IO_CATEGORIES: Partial<PiaCategory>[] = [
    PiaAccessoryCategory.AUDIOANDIO,
    PiaItemSoftwareCategory.AUDIO_MANAGEMENT_SOFTWARE,
    PiaAccessoryCategory.AMPLIFIERS,
    PiaAccessoryCategory.BRIDGES,
    PiaAccessoryCategory.MICROPHONES,
    PiaAccessoryCategory.SERVERS,
    PiaItemWearablesCategory.DEVICE,
];

const CONNECTIVITY_CATEGORIES: Partial<PiaCategory>[] = [
    PiaAccessoryCategory.CABLESCON,
    PiaAccessoryCategory.ETHERNETOVERCOAX,
    PiaAccessoryCategory.MEDIACONVERTERS,
    PiaAccessoryCategory.SURGEPROTECTORS,
    PiaAccessoryCategory.POEEXTENDERS,
];

const getSystemAccessoriesState = (state: IStoreState) => state.systemAccessories;

/**
 * Get the persisted system accessory items from the current project.
 */
const getCurrentProjectSystemAccessoriesItems = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items) => items.filter((item) => !!item.properties.systemAccessory && item.productId),
);

/**
 * Get the currently selected PiaItems.
 */
export const getCurrentProjectSystemAccessoriesPiaItems = createSelector(
    [getCurrentProjectSystemAccessoriesItems, getPiaItemsRecord],
    (items, piaItems) =>
        Object.values(items)
            .map((item) => piaItems[item.productId!])
            .filter(isDefined)
            .sort(nameComparator),
);

/**
 * Calculates how many system accessories are selected in the project,
 * including quantities.
 */
export const getCurrentProjectSystemAccessoriesCount = createSelector(
    [getCurrentProjectSystemAccessoriesItems],
    (items) => Object.values(items).reduce((acc, item) => acc + item.quantity, 0),
);

/**
 * Get all system accessory categories with currently available products
 */
export const getAvailableSystemAccessoryCategories = createSelector(
    [getAvailableSystemAccessoriesPiaItems],
    (piaItems: IPiaItem[]) =>
        sortBy(
            uniq(
                uniq(piaItems.map((accessory) => accessory.category)).map(
                    (category) => PIA_CATEGORY_TO_DISPLAY_CATEGORY[category],
                ),
            ),
        ),
);

/**
 * Returns a record with accessory category as key and the currently selected quantity
 * as value, including individual quantities.
 */
export const getQuantityForCategory = createSelector(
    [getCurrentProjectSystemAccessoriesItems, getPiaItemsRecord],
    (selectedAccessories, piaItems) =>
        selectedAccessories.reduce((categoryQuantities: Record<string, number>, item) => {
            if (item.productId) {
                const piaItem = piaItems[item.productId];
                if (piaItem) {
                    const quantity =
                        piaItem.state === PiaItemState.EXTERNALLY_ANNOUNCED ? item.quantity : 0;
                    const category = PIA_CATEGORY_TO_DISPLAY_CATEGORY[piaItem.category];

                    categoryQuantities[category] = categoryQuantities[category]
                        ? categoryQuantities[category] + quantity
                        : quantity;
                }
            }
            return categoryQuantities;
        }, {}),
);

/**
 * Returns a record with PiaId as key and the currently selected quantity
 * as value, including individual quantities for that PiaId.
 */
export const getQuantityForProducts = createSelector(
    [getCurrentProjectSystemAccessoriesItems],
    (selectedAccessories) =>
        selectedAccessories.reduce((obj: Record<PiaId, number>, item) => {
            if (item.productId) {
                obj[item.productId] = item.quantity;
            }
            return obj;
        }, {}),
);

/**
 * Returns a record with PiaId as key and the MSRP Price object as value.
 */
export const getPriceForProducts = createSelector(
    [getAvailableSystemAccessoriesPiaItems, getPriceListForRegion, getCurrentProjectLocation],
    (piaItems, priceListForRegion, projectLocation) => {
        const productsWithPrices = getPiaItemsWithPrice<IPiaAccessory>(
            piaItems,
            priceListForRegion,
            projectLocation,
        );

        return productsWithPrices.reduce((prices: Record<PiaId, IPrice | undefined>, product) => {
            prices[product.piaItem.id] = product.price;

            return prices;
        }, {});
    },
);

export const getAccessoriesToShow = createSelector(
    [
        getSystemAccessoriesState,
        getAvailableSystemAccessoriesPiaItems,
        getCurrentProjectSystemAccessoriesPiaItems,
    ],
    (systemAccessoriesState, accessories, selectedAccessories) => {
        if (systemAccessoriesState.isCurrentSelectionSelected) {
            return selectedAccessories;
        }

        if (systemAccessoriesState.isAllAccessoriesSelected) {
            return accessories;
        }

        if (systemAccessoriesState.isSearchResultSelected) {
            const matchingResult = accessories.filter((accessory) =>
                accessory.name
                    .toLowerCase()
                    .includes(systemAccessoriesState.searchText?.toLowerCase() ?? ''),
            );
            return matchingResult;
        }

        if (systemAccessoriesState.selectedCategory) {
            return accessories.filter((accessory) => {
                if (
                    systemAccessoriesState.selectedCategory &&
                    systemAccessoriesState.selectedCategory === DisplayCategory.AUDIOANDIO
                ) {
                    return isAudioAndIoCategory(accessory.category);
                } else if (
                    systemAccessoriesState.selectedCategory &&
                    systemAccessoriesState.selectedCategory === DisplayCategory.CONNECTIVITY
                ) {
                    return isConnectivityCategory(accessory.category);
                } else if (systemAccessoriesState.selectedCategory) {
                    return (
                        DISPLAY_CATEGORY_TO_PIA_CATEGORY[
                            systemAccessoriesState.selectedCategory
                        ] === accessory.category
                    );
                }
            });
        }

        return [];
    },
);

const isAudioAndIoCategory = (piaCategory: PiaCategory) =>
    AUDIO_AND_IO_CATEGORIES.includes(piaCategory);

const isConnectivityCategory = (piaCategory: PiaCategory) =>
    CONNECTIVITY_CATEGORIES.includes(piaCategory);
