import * as React from 'react';
import { Box, Text, SearchField, Card, Stack, Alert } from 'app/components';
import { useSelector } from 'react-redux';
import {
    getDocumentationDevicesRecord,
    getDocumentationCategories,
    getDeviceFilter,
    getSelectedAccessoryIds,
} from './selectors';
import { DocumentationActionService } from './services';
import { useService } from 'app/ioc';
import type { LanguageCode } from 'app/utils';
import { DocumentationBottom } from './components';
import { t } from 'app/translate';
import i18next from 'i18next';
import { CsiCompile } from './components/CsiCompile.container';
import { renderEmpty as renderEmpty } from './renderEmpty';
import { renderCategories } from './renderCategories';
import { getCurrentProjectId } from '../common';

export const Documentation: React.FC = () => {
    const documentationActionService = useService(DocumentationActionService);
    const documentationDevices = useSelector(getDocumentationDevicesRecord);
    const accessoryIds = useSelector(getSelectedAccessoryIds);
    const deviceFilter = useSelector(getDeviceFilter);
    const projectId = useSelector(getCurrentProjectId);
    const categories = useSelector(getDocumentationCategories);

    const selectedLanguageCode = i18next.language as LanguageCode;

    React.useEffect(() => {
        // Check and update which accessories have data sheets on mount
        documentationActionService.updateAccessories(accessoryIds);
        return () => {
            documentationActionService.clearDeviceFilter();
        };
    }, [accessoryIds, documentationActionService]);

    return (
        <Box justifyContent="center" paddingX="doublePanel" paddingBottom="doublePanel">
            <Box width="100%" paddingTop="doublePanel" maxWidth="800px" justifyContent="center">
                <Stack vertical>
                    <Box justifyContent="between">
                        <Text style="headline">{t.downloadDocumentation}</Text>
                        <SearchField
                            value={deviceFilter}
                            onValueChange={documentationActionService.deviceFilterChange}
                        />
                    </Box>
                    <Card>
                        <Box
                            justifyContent="center"
                            paddingY="panel"
                            width="100%"
                            direction="column"
                        >
                            <Stack vertical spacing="cell">
                                {categories.length > 0 ? (
                                    renderCategories(categories, documentationDevices)
                                ) : deviceFilter.length === 0 ? (
                                    renderEmpty(projectId)
                                ) : (
                                    <Text>{t.noSearchResultsFor(deviceFilter)}</Text>
                                )}

                                {selectedLanguageCode !== 'en' && (
                                    <Alert
                                        text={t.documentationInEnglishAsFallback}
                                        color="blue"
                                        level="info"
                                    />
                                )}
                            </Stack>
                        </Box>
                    </Card>
                    <DocumentationBottom />
                    <CsiCompile />
                </Stack>
            </Box>
        </Box>
    );
};
