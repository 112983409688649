import type {
    IAudioSettingsEntity,
    IRecordingSettingsEntity,
    IScenarioEntity,
    IStorageSettingsEntity,
    IZipstreamSettingsEntity,
} from '../../../userDataPersistence/entities';
import type { IRecordingSettingsModel } from '../IRecordingSettingsModel';
import { Resolution } from '../Resolution';
import type { IScenarioSettingsModel } from '../IScenarioSettingsModel';
import { Time24 } from '../../schedule/Time24';
import type { IAudioSettingsModel } from '../IAudioSettingsModel';
import type { IZipstreamSettingsModel } from '../IZipstreamSettingsModel';
import type { IStorageSettingsModel } from '../IStorageSettingsModel';

export const mapToRecordingSettingsModel = (
    entity: IRecordingSettingsEntity,
): IRecordingSettingsModel => {
    return {
        ...entity,
        resolution: new Resolution(entity.resolution),
    };
};

export const mapToScenarioModel = (entity: IScenarioEntity): IScenarioSettingsModel => {
    return {
        ...entity,
        lightStart: new Time24(entity.lightStart),
        lightEnd: new Time24(entity.lightEnd),
    };
};

export const mapToAudioEntity = (model: IAudioSettingsModel): IAudioSettingsEntity => {
    return {
        liveViewEnabled: model.liveViewEnabled,
        recordingEnabled: model.recordingEnabled,
    };
};

export const mapToZipstreamEntity = (model: IZipstreamSettingsModel): IZipstreamSettingsEntity => {
    return {
        gopDefault: model.gopDefault,
        gopMax: model.gopMax,
        gopMode: model.gopMode,
        zipStrength: model.zipStrength,
        zipProfile: model.zipProfile,
        fpsMode: model.fpsMode,
        useProjectSetting: model.useProjectSetting,
        minDynamicFps: model.minDynamicFps,
    };
};

export const mapToScenarioEntity = (model: IScenarioSettingsModel): IScenarioEntity => {
    return {
        scenarioId: model.scenarioId,
        sceneDetails: model.sceneDetails,
        nightLighting: model.nightLighting,
        lightStart: model.lightStart.toPersistable(),
        lightEnd: model.lightEnd.toPersistable(),
    };
};

export const mapToStorageEntity = (model: IStorageSettingsModel): IStorageSettingsEntity => {
    return {
        retentionTime: model.retentionTime,
        useProjectSetting: model.useProjectSetting,
    };
};

export const mapToRecordingSettingsEntity = (
    model: IRecordingSettingsModel,
): IRecordingSettingsEntity => {
    return {
        frameRate: model.frameRate,
        compression: model.compression,
        dayMotion: model.dayMotion,
        dayTriggerTime: model.dayTriggerTime,
        nightMotion: model.nightMotion,
        nightTriggerTime: model.nightTriggerTime,
        schedule: model.schedule,
        videoEncoding: model.videoEncoding,
        resolution: model.resolution.toPersistable(),
        useAverageBitrate: model.useAverageBitrate,
    };
};
