import { createSelector } from 'reselect';
import type {
    IPiaAccessControl,
    IPiaEncoder,
    IPiaItem,
    IPiaLocation,
    IPiaMainUnit,
    IPiaOtherDevice,
    IPiaSensorDevice,
    IPiaSpeaker,
    IPiaTwoNCategory,
    IPiaWearable,
} from 'app/core/pia';
import { PiaItemBareboneCategory, filterProducts } from 'app/core/pia';
import type { IEncoderFilter, IMainUnitFilter } from 'app/modules/common';
import {
    externallyAnnouncedOnly,
    Frequency,
    getAvailablePiaItems,
    getCurrentProjectLocation,
    getPiaItemsWithPrice,
    getPiaLensesRecord,
    getPriceListForRegion,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import type {
    IAccessControlFilter,
    ICameraFilter,
    IOtherDeviceFilter,
    ISensorUnitFilter,
    ISpeakerFilter,
    ITwoNFilter,
    IWearablesFilter,
} from '../models';
import {
    SensorUnitFilter,
    AccessControlFilter,
    CameraFilter,
    EncoderFilter,
    MainUnitFilter,
    OtherDeviceFilter,
    ProductSorter,
    SpeakerFilter,
    TwoNFilter,
    WearablesFilter,
} from '../models';
import { getCompatibleSensorUnits } from './getCompatibleSensorUnits';
import { getFpsPerFrequencyKey } from './getFpsOptions';
import { UnreachableCaseError } from 'axis-webtools-util';

export const getDeviceGroup = (state: IStoreState) => state.deviceSelector.deviceGroup;
export const getCurrentSortOrder = (state: IStoreState) => state.deviceSelector.currentSortOrder;
export const getIncludeDiscontinued = (state: IStoreState) =>
    state.deviceSelector.includeDiscontinued;
export const getSortOrderOptions = (state: IStoreState) => state.deviceSelector.sortOrderOptions;
const getPiaCategories = (state: IStoreState) => state.deviceSelector.categories;
export const getCameraFilter = (state: IStoreState) => state.deviceSelector.cameraFilter;
export const getDesiredCamera = (state: IStoreState) =>
    state.deviceSelector.cameraFilter.desiredCamera;
export const getSensorUnitFilter = (state: IStoreState) => state.deviceSelector.sensorUnitFilter;
const getEncoderFilter = (state: IStoreState) => state.deviceSelector.encoderFilter;
const getMainUnitFilter = (state: IStoreState) => state.deviceSelector.mainUnitFilter;
const getSpeakerFilter = (state: IStoreState) => state.deviceSelector.speakerFilter;
export const getAccessControlFilter = (state: IStoreState) =>
    state.deviceSelector.accessControlFilter;
const getWearablesFilter = (state: IStoreState) => state.deviceSelector.wearablesFilter;
const getOtherDeviceFilter = (state: IStoreState) => state.deviceSelector.otherDeviceFilter;
const getTwoNFilter = (state: IStoreState) => state.deviceSelector.twoNFilter;
const getSearchText = (state: IStoreState) => state.deviceSelector.searchText;
const getSelectedProductId = (state: IStoreState) => state.deviceSelector.selectedProductId;
const getEditDeviceId = (state: IStoreState) => state.deviceSelector.editDeviceId;

const getFpsKey = (location?: IPiaLocation) => {
    if (!location?.frequency50Hz && !location?.frequency60Hz) {
        return undefined;
    }
    const frequency: Frequency | undefined = location.frequency60Hz
        ? Frequency.Hz60
        : location.frequency50Hz
          ? Frequency.Hz50
          : undefined;
    return getFpsPerFrequencyKey(frequency);
};

const getSelectablePiaItems = createSelector(
    [getAvailablePiaItems, getIncludeDiscontinued],
    (piaItems, includeDiscontinued) =>
        includeDiscontinued ? piaItems : piaItems.filter(externallyAnnouncedOnly),
);

export const getAllProductsForDeviceGroup = createSelector(
    [
        getSelectablePiaItems,
        getPiaCategories,
        getDeviceGroup,
        getCompatibleSensorUnits,
        getEditDeviceId,
    ],
    (piaItems, categories, deviceGroup, compatibleSensorUnits, editDeviceId) => {
        return piaItems
            .filter(
                editDeviceId
                    ? filterProducts.byCategories(categories)
                    : filterProducts.byIncludesAnyOfCategoriesExceptCategory(
                          categories,
                          PiaItemBareboneCategory.BAREBONE,
                      ), // This is to make sure q1656-DLE (Oxxo) appears below radars in other products
            )
            .filter((piaItem) => {
                return deviceGroup === 'sensorunits'
                    ? compatibleSensorUnits.some((sensorUnit) => sensorUnit.id === piaItem.id)
                    : true;
            })
            .filter(filterProducts.byVendor(deviceGroup === 'twoNProducts' ? '2N' : 'Axis'));
    },
);

export const getDeviceGroupFilter = createSelector(
    [
        getDeviceGroup,
        getCameraFilter,
        getSensorUnitFilter,
        getEncoderFilter,
        getMainUnitFilter,
        getSpeakerFilter,
        getAccessControlFilter,
        getWearablesFilter,
        getOtherDeviceFilter,
        getTwoNFilter,
    ],
    (
        deviceGroup,
        cameraFilter,
        sensorUnitFilter,
        encoderFilter,
        mainUnitFilter,
        speakerFilter,
        accessControlFilter,
        wearablesFilter,
        otherDeviceFilter,
        twoNFilter,
    ) => {
        switch (deviceGroup) {
            case 'cameras':
                return cameraFilter;
            case 'sensorunits':
                return sensorUnitFilter;
            case 'encoders':
                return encoderFilter;
            case 'mainunits':
                return mainUnitFilter;
            case 'speakers':
                return speakerFilter;
            case 'accessControls':
                return accessControlFilter;
            case 'wearables':
                return wearablesFilter;
            case 'other':
                return otherDeviceFilter;
            case 'twoNProducts':
                return twoNFilter;
            default:
                throw new UnreachableCaseError(
                    deviceGroup,
                    `${deviceGroup} does not have a filter`,
                );
        }
    },
);

export const getFilteredProducts = createSelector(
    [
        getDeviceGroup,
        getAllProductsForDeviceGroup,
        getPriceListForRegion,
        getCurrentProjectLocation,
        getSearchText,
        getDeviceGroupFilter,
        getPiaLensesRecord,
    ],
    (
        deviceGroup,
        availableDevices,
        priceListForRegion,
        projectLocation,
        searchText,
        filter,
        piaLenses,
    ) => {
        switch (deviceGroup) {
            case 'cameras':
                return getPiaItemsWithPrice<IPiaSensorDevice>(
                    CameraFilter.filter(
                        filter as ICameraFilter,
                        availableDevices as IPiaSensorDevice[],
                        searchText,
                        piaLenses,
                        getFpsKey(projectLocation),
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'sensorunits':
                return getPiaItemsWithPrice<IPiaSensorDevice>(
                    SensorUnitFilter.filter(
                        filter as ISensorUnitFilter,
                        availableDevices as IPiaSensorDevice[],
                        searchText,
                        piaLenses,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'encoders':
                return getPiaItemsWithPrice<IPiaItem>(
                    EncoderFilter.filter(
                        filter as IEncoderFilter,
                        availableDevices as IPiaEncoder[],
                        searchText,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'mainunits':
                return getPiaItemsWithPrice<IPiaItem>(
                    MainUnitFilter.filter(
                        filter as IMainUnitFilter,
                        availableDevices as IPiaMainUnit[],
                        searchText,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'speakers':
                return getPiaItemsWithPrice<IPiaItem>(
                    SpeakerFilter.filter(
                        filter as ISpeakerFilter,
                        availableDevices as IPiaSpeaker[],
                        searchText,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'accessControls':
                return getPiaItemsWithPrice<IPiaItem>(
                    AccessControlFilter.filter(
                        filter as IAccessControlFilter,
                        availableDevices as IPiaAccessControl[],
                        searchText,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'wearables':
                return getPiaItemsWithPrice<IPiaItem>(
                    WearablesFilter.filter(
                        filter as IWearablesFilter,
                        availableDevices as IPiaWearable[],
                        searchText,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'other':
                return getPiaItemsWithPrice<IPiaItem>(
                    OtherDeviceFilter.filter(
                        filter as IOtherDeviceFilter,
                        availableDevices as IPiaOtherDevice[],
                        searchText,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            case 'twoNProducts':
                return getPiaItemsWithPrice<IPiaItem>(
                    TwoNFilter.filter(
                        filter as ITwoNFilter,
                        availableDevices as IPiaTwoNCategory[],
                        searchText,
                    ),
                    priceListForRegion,
                    projectLocation,
                );
            default:
                return getPiaItemsWithPrice<IPiaItem>(
                    availableDevices,
                    priceListForRegion,
                    projectLocation,
                );
        }
    },
);

export const getHorizontalFOVRadians = createSelector(
    [getCameraFilter, getDeviceGroup],
    (filter, deviceGroup) => {
        if (
            (deviceGroup === 'cameras' || deviceGroup === 'sensorunits') &&
            filter.desiredCamera.isSceneFilterActive
        ) {
            return filter.desiredCamera.horizontalFOVRadians;
        }
        return undefined;
    },
);

export const getProductsForDeviceGroup = createSelector(
    [getFilteredProducts, getCurrentSortOrder, getHorizontalFOVRadians, getDeviceGroup],
    (filteredProducts, sortOrder, horizontalFOVRadians, deviceGroup) =>
        ProductSorter.sort(
            filteredProducts,
            sortOrder,
            deviceGroup === 'other' ?? false,
            horizontalFOVRadians,
            deviceGroup === 'twoNProducts' ? '2N' : undefined,
        ),
);

export const getMatchingProductsCount = createSelector(
    [getFilteredProducts],
    (filteredProducts) => filteredProducts.length,
);

export const getTotalProductsCount = createSelector(
    [getAllProductsForDeviceGroup],
    (allProducts) => allProducts.length,
);

export const isSelectedProductInCurrentDeviceGroup = createSelector(
    [getAllProductsForDeviceGroup, getSelectedProductId],
    (allProducts, selectedProductId) => allProducts.some(({ id }) => id === selectedProductId),
);

export const getCameraFilterExceptSustainability = createSelector(
    [getCameraFilter],
    (cameraFilter): Omit<ICameraFilter, 'minRecycledPlastic' | 'pvcFree' | 'bfrCfrFree'> => {
        const filter: any = { ...cameraFilter };
        delete filter.bfrCfrFree;
        delete filter.minRecycledPlastic;
        delete filter.pvcFree;
        return filter as Omit<ICameraFilter, 'minRecycledPlastic' | 'pvcFree' | 'bfrCfrFree'>;
    },
);
