import type { IMinMax } from 'app/core/persistence';
import { createSelector } from 'reselect';
import { getCurrentProjectNetworkSettings } from '../../project';
import { getInitMax, getInitMin } from '../modal/IPRangeSettings/ipRangeInitValues';
import { getTotalAvailableIPAddresses } from './getTotalAvailableIPAddresses';

interface IReservedRangesInitMinValues {
    cameras: IMinMax;
    other: IMinMax;
    recorders: IMinMax;
}

export const getReservedRangesInit = createSelector(
    [getCurrentProjectNetworkSettings, getTotalAvailableIPAddresses],
    (projectNetwork, rangeSize): IReservedRangesInitMinValues => {
        const cameraMin = getInitMin('cameras', projectNetwork, rangeSize);
        const otherMin = getInitMin('other', projectNetwork, rangeSize);
        const recorderMin = getInitMin('recorders', projectNetwork, rangeSize);

        return {
            cameras: {
                min: cameraMin,
                max: getInitMax('cameras', projectNetwork, rangeSize, cameraMin),
            },
            other: {
                min: otherMin,
                max: getInitMax('other', projectNetwork, rangeSize, otherMin),
            },
            recorders: {
                min: recorderMin,
                max: getInitMax('recorders', projectNetwork, rangeSize, recorderMin),
            },
        };
    },
);
