import { Module } from 'app/ioc';
import { schedulesReducer } from './state/Schedules.reducer';
import { SchedulesActionService } from './services/SchedulesActionService.service';

@Module({
    binder: (binder) => {
        binder.bindService(SchedulesActionService);
    },
    reducers: {
        schedules: schedulesReducer,
    },
})
export class SchedulesModule {}
