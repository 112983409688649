import * as React from 'react';
import { css } from '@emotion/css';
import classNames from 'classnames';
import type { Property } from 'csstype';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';

export const TextStyle = css`
    color: ${ColorsEnum.grey9};
    margin-top: 0;
    margin-bottom: 0;

    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;

    @media print {
        color: ${ColorsEnum.black};
    }
`;

const HeadingStyle = css`
    font-size: 14px;
    font-weight: 600;
`;

const HeadlineStyle = css`
    font-size: 24px;
    font-weight: 600;
`;

const TitleStyle = css`
    font-size: 20px;
    font-weight: 600;
`;

const SubheaderStyle = css`
    font-size: 16px;
    font-weight: 600;
`;

const BodyStyle = css`
    font-size: 12px;
    font-weight: 400;
`;

const SemiboldStyle = css`
    font-size: 12px;
    font-weight: 600;
`;

const CaptionStyle = css`
    font-size: 12px;
    font-weight: 400;
    color: grey6;
`;

export const SmallStyle = css`
    font-size: 10px;
    color: grey6;
    font-weight: 400;
`;

const AccessCodeStyle = css`
    padding: 2px 4px;
    font-family: monospace;
    font-weight: bold;
    letter-spacing: 0.3px;
`;

export const HideOverflowStyle = css`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const FullWidthStyle = css`
    width: 100%;
`;

const FadedStyle = css`
    opacity: 0.54;
`;

const BoldStyle = css`
    font-weight: bold;
`;

const ItalicStyle = css`
    font-style: italic;
`;

const SemiBoldStyle = css`
    font-weight: 600;
`;

const TextAlignLeftStyle = css`
    text-align: start;
`;

const TextAlignCenterStyle = css`
    text-align: center;
`;

const TextAlignRightStyle = css`
    text-align: end;
`;

const TextSizeLargeStyle = css`
    font-size: 14px;
`;

const TextSizeSmallStyle = css`
    font-size: 10px;
`;

const UppercaseStyle = css`
    text-transform: uppercase;
`;

const LowercaseStyle = css`
    text-transform: lowercase;
`;

type TextAlign = 'left' | 'center' | 'right';

export type WhiteSpace =
    | 'inherit'
    | 'initial'
    | 'unset'
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-line'
    | 'pre-wrap';

export type WordBreak =
    | 'inherit'
    | 'initial'
    | 'unset'
    | 'normal'
    | 'break-all'
    | 'keep-all'
    | 'break-word';

export interface ITextProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * Set the text color
     */
    color?: Colors;
    /**
     * Render the text as a `<span>` instead of `<p>`
     */
    inline?: boolean;
    /**
     * Set the opacity to 0.54 for a faded look
     */
    faded?: boolean;
    /**
     * Align the text
     */
    align?: TextAlign;
    /**
     * Sets the size of the text to 14px instead of 12px
     */
    large?: boolean;
    /**
     * Sets the size of the text to 10px instead of 12px
     */
    small?: boolean;
    /**
     * Set the whitespace css behavior
     */
    whiteSpace?: WhiteSpace;
    /**
     * Set the word-break css behavior
     */
    wordBreak?: WordBreak;
    /**
     * Set the type in bold
     */
    bold?: boolean;
    /**
     * Set the type in italic
     */
    italic?: boolean;
    /**
     * Set the type in semi bold
     * @deprecated Do not use! Use `style="semibold"` instead.
     */
    semiBold?: boolean;
    /**
     * The Figma style to use
     */
    style?:
        | 'heading'
        | 'headline'
        | 'title'
        | 'subheader'
        | 'body'
        | 'semibold'
        | 'caption'
        | 'small'
        | 'accesscode';
    /**
     * Use this in combination with a style for custom font sizes (in px)
     */
    sizeOverride?: number;
    /**
     * Set an explicit lineHeight. Defaults to browser standard.
     */
    lineHeight?: number;
    /**
     * Makes all characters uppercase.
     */
    uppercase?: boolean;
    /**
     * Makes all characters lowercase.
     */
    lowercase?: boolean;
    /**
     * Apply style to child without creating a DOM element.
     */
    onlyStyle?: boolean;
    /**
     * Apply full width to Text.
     */
    fullWidth?: boolean;
    /**
     * Override the color prop with color of your choice
     */
    colorOverride?: Property.Color;
    /** Sets the number of rows the text is allowed to have */
    lineClamp?: number;
}

/**
 * This is our main text component and should be used whenever any text is displayed.
 * The text will be a very dark grey in order to lessen the contrast slightly.
 *
 * It supports the text styles defined in Figma by using the `style` prop.
 */
export const Text: React.FunctionComponent<ITextProps> = ({
    children,
    color,
    inline,
    faded,
    align = 'left',
    large,
    small,
    whiteSpace,
    wordBreak = 'keep-all',
    bold,
    italic,
    semiBold,
    style,
    sizeOverride,
    lineHeight,
    uppercase,
    lowercase,
    testId,
    onlyStyle,
    fullWidth,
    colorOverride,
    lineClamp,
    ...extendedProps
}) => {
    const styleProps: React.CSSProperties = {
        whiteSpace,
        wordBreak: wordBreak,
        color: colorOverride ?? (color ? ColorsEnum[color] : undefined),
        fontSize: sizeOverride ? `${sizeOverride}px` : undefined,
        lineHeight,
    };

    const lineClampStyle = lineClamp
        ? css`
              display: -webkit-box;
              -webkit-line-clamp: ${lineClamp};
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              overflow: hidden;
          `
        : undefined;

    const className = classNames(TextStyle, lineClampStyle, {
        [FadedStyle]: faded,
        [BoldStyle]: bold,
        [ItalicStyle]: italic,
        [SemiBoldStyle]: semiBold,
        [TextAlignLeftStyle]: align === 'left',
        [TextAlignCenterStyle]: align === 'center',
        [TextAlignRightStyle]: align === 'right',
        [TextSizeLargeStyle]: large,
        [TextSizeSmallStyle]: small,
        [HideOverflowStyle]: whiteSpace === 'nowrap',
        [BodyStyle]: style === 'body',
        [HeadingStyle]: style === 'heading',
        [HeadlineStyle]: style === 'headline',
        [TitleStyle]: style === 'title',
        [SubheaderStyle]: style === 'subheader',
        [CaptionStyle]: style === 'caption',
        [SemiboldStyle]: style === 'semibold',
        [UppercaseStyle]: uppercase,
        [LowercaseStyle]: lowercase,
        [SmallStyle]: style === 'small',
        [AccessCodeStyle]: style === 'accesscode',
        [FullWidthStyle]: fullWidth,
    });
    const attributes = extendableProps(
        { ...extendedProps },
        { className, style: styleProps },
        true,
    );
    return inline ? (
        <span data-test-id={toTestIdFormat(testId)} {...attributes}>
            {children}
        </span>
    ) : onlyStyle ? (
        (renderReactChildren(
            children,
            (child) => React.cloneElement(child, { __htmlAttributes: attributes }),
            (child) => React.cloneElement(child, attributes),
        ) as any)
    ) : (
        <p data-test-id={toTestIdFormat(testId)} {...attributes}>
            {children}
        </p>
    );
};

Text.displayName = 'Text';
