import * as React from 'react';
import { t } from 'app/translate';
import { DropDownMenuButton } from './DropDownMenuButton.component';
import { DropDownMenuToggle } from './DropDownMenuToggle.component';
import type { IAutoTestable } from '../../ui-test';

export interface IDropDownMenuDeleteProps extends IAutoTestable {
    disabled?: boolean;
    onDelete(): void;
}

/**
 * A drop down menu item which will render a toggle button that allows
 * the user to confirm a delete operation.
 */
export class DropDownMenuDelete extends React.PureComponent<IDropDownMenuDeleteProps> {
    public render() {
        return (
            <DropDownMenuToggle
                testId={this.props.testId}
                label={t.delete}
                icon="delete"
                disabled={this.props.disabled}
            >
                <DropDownMenuButton
                    testId="btn_delete_forever"
                    label={t.confirmDelete}
                    icon="delete_forever"
                    onClick={this.props.onDelete}
                    iconProps={{ color: 'red', opaque: true }}
                    disabled={this.props.disabled}
                />
            </DropDownMenuToggle>
        );
    }
}
