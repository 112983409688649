import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import { DeviceProfile } from './DeviceProfile.container';
import {
    ScheduledRecordingTypes,
    getProfileOverride,
    getCurrentProjectItem,
} from 'app/modules/common';
import { DeviceScenarioEditor } from './DeviceScenarioEditor.container';
import { DeviceScheduledRecordingEditor } from './DeviceScheduledRecordingEditor.container';
import { DeviceStorageSettings } from './DeviceStorageSettings.container';
import { DeviceZipstreamSettings } from './DeviceZipstreamSettings.container';
import { DeviceAudioSettings } from './DeviceAudioSettings.container';

interface IDeviceProfileEditorOwnProps {
    deviceId: Id;
}

interface IDeviceProfileEditorProps {
    deviceId: Id;
    deactivate: boolean;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceProfileEditorOwnProps,
): IDeviceProfileEditorProps => {
    const item = getCurrentProjectItem(state, ownProps.deviceId);
    return {
        deviceId: ownProps.deviceId,
        deactivate:
            (item && isCustomCamera(item)) ||
            !!getProfileOverride(state, ownProps.deviceId)?.customBandwidth,
    };
};

class DeviceProfileEditorContainer extends React.Component<IDeviceProfileEditorProps> {
    public render() {
        return (
            <>
                <DeviceProfile deviceId={this.props.deviceId} />
                <DeviceScenarioEditor
                    deviceId={this.props.deviceId}
                    deactivate={this.props.deactivate}
                />
                <DeviceScheduledRecordingEditor
                    deviceId={this.props.deviceId}
                    deactivate={this.props.deactivate}
                    recordingType={ScheduledRecordingTypes.triggered}
                />
                <DeviceScheduledRecordingEditor
                    deviceId={this.props.deviceId}
                    deactivate={this.props.deactivate}
                    recordingType={ScheduledRecordingTypes.continuous}
                />
                <DeviceScheduledRecordingEditor
                    deviceId={this.props.deviceId}
                    deactivate={this.props.deactivate}
                    recordingType={ScheduledRecordingTypes.liveView}
                />
                <DeviceAudioSettings
                    deviceId={this.props.deviceId}
                    deactivate={this.props.deactivate}
                />
                <DeviceZipstreamSettings
                    deviceId={this.props.deviceId}
                    deactivate={this.props.deactivate}
                />
                <DeviceStorageSettings deviceId={this.props.deviceId} />
            </>
        );
    }
}

export const DeviceProfileEditor = connect(mapStateToProps)(DeviceProfileEditorContainer);
