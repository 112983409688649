import * as React from 'react';
import { Icon, Stack, Box, IconText } from 'app/components';
import { t } from 'app/translate';
import { toIcon, toColor } from './ipRangeStyleUtils';
import type { INetworkRangeType } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { getShowIpRangeWarningIcon } from '../../selectors';

interface IIPRangeIconProps {
    type: INetworkRangeType;
}

/** Component that adds a title and icon for a reserved range */
export const IPRangeIcon: React.FC<IIPRangeIconProps> = ({ type }) => {
    const showWarning = useSelector(getShowIpRangeWarningIcon)[type];

    return (
        <Box height="30px">
            <Stack spacing="half">
                <IconText
                    icon={toIcon(type)}
                    iconProps={{ color: toColor(type), size: 'sm', opaque: true }}
                    textProps={{ style: 'semibold' }}
                >
                    {t[type]}
                </IconText>
                {showWarning ? (
                    <Icon color="yellow" icon="info" size="xs" opaque />
                ) : (
                    <Box width="13px" />
                )}
            </Stack>
        </Box>
    );
};

IPRangeIcon.displayName = 'IPRangeIcon';
