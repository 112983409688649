import * as React from 'react';
import { Box, Button, Card, Checkbox, Stack, Text, TextInput } from 'app/components';
import type { INetworkRange, IProjectNetworkSettings } from 'app/core/persistence';
import { getNbrTotalAvailableIPAddresses } from 'app/core/persistence';
import { t } from 'app/translate';
import { hasAnyError, validateProjectNetworkSettings } from './validateProjectNetworkSettings';
import type { IProjectNetworkFormErrors } from './validateProjectNetworkSettings';
import { IPAlertCard } from '../components';

const networkImage = require('assets/images/network.svg');

interface IProjectNetworkFormProps {
    onSubmit(network: IProjectNetworkSettings): void;
    onCancel(): void;
    projectNetworkSettings?: IProjectNetworkSettings;
    nbrRequiredIPAddresses: number;
}

const defaultReservedRange: INetworkRange = {
    ipStart: '',
    ipEnd: '',
};

const isValidForm = (formValue: IProjectNetworkSettings, errors: IProjectNetworkFormErrors) =>
    formValue.dhcp ||
    (!hasAnyError(errors) &&
        formValue.ipStart &&
        formValue.ipEnd &&
        formValue.subnetMask &&
        formValue.defaultRouter);

export const ProjectNetworkForm: React.FC<IProjectNetworkFormProps> = ({
    projectNetworkSettings,
    nbrRequiredIPAddresses,
    onCancel,
    onSubmit,
}) => {
    const [formValue, setFormValue] = React.useState<IProjectNetworkSettings>({
        dhcp: projectNetworkSettings?.dhcp ?? false,
        ipStart: projectNetworkSettings?.ipStart ?? '',
        ipEnd: projectNetworkSettings?.ipEnd ?? '',
        subnetMask: projectNetworkSettings?.subnetMask ?? '',
        defaultRouter: projectNetworkSettings?.defaultRouter ?? '',
        ranges: projectNetworkSettings?.ranges ?? {
            cameras: defaultReservedRange,
            other: defaultReservedRange,
            recorders: defaultReservedRange,
        },
    });
    const [submitting, setSubmitting] = React.useState(false);
    const [errors, setErrors] = React.useState<IProjectNetworkFormErrors>({
        ipStart: false,
        ipEnd: false,
        subnetMask: false,
        defaultRouter: false,
    });
    const [info, setInfo] = React.useState<JSX.Element>();

    React.useEffect(() => {
        // Update errors
        const validationErrors = validateProjectNetworkSettings(formValue);
        setErrors(validationErrors);

        // Update number of available addresses
        const nbrAvailableAddresses = getNbrTotalAvailableIPAddresses(formValue);

        // Only show info after user clicked submit button
        if (submitting) {
            if (isValidForm(formValue, validationErrors)) {
                setInfo(
                    nbrRequiredIPAddresses > nbrAvailableAddresses ? (
                        <IPAlertCard
                            message={t.ipConfigurationAlertNumberOfDeviceInRange(
                                nbrAvailableAddresses,
                            )}
                        />
                    ) : (
                        <Text align="center">
                            {t.ipNetworkWillSupportUpToNumberDevices(nbrAvailableAddresses)}
                        </Text>
                    ),
                );
            } else {
                // Found error in formValue
                setInfo(
                    <Text align="center" color="red">
                        {t.ipAddressFieldError}
                    </Text>,
                );
            }
        }
    }, [formValue, nbrRequiredIPAddresses, submitting]);

    return (
        <Card direction="column" paddingY="base">
            <Box justifyContent="start">
                <Box paddingX="base" direction="column" width="100%">
                    <Stack spacing="panel" alignItems="start">
                        <Box
                            direction="column"
                            height="100%"
                            justifyContent="center"
                            alignItems="center"
                            flex="none"
                        >
                            <img src={networkImage} />
                        </Box>
                        <Box direction="column" width="100%">
                            <Stack vertical spacing="cell">
                                <Stack justifyContent="start" alignItems="start">
                                    <Checkbox
                                        slider
                                        selected={formValue.dhcp}
                                        onChange={(value) =>
                                            setFormValue({ ...formValue, dhcp: value })
                                        }
                                    >
                                        {t.obtainIPAddressesAutomatically}
                                    </Checkbox>
                                </Stack>
                                <Box direction="row" width="100%">
                                    <Stack flex="fullWidth">
                                        <Box width="50%">
                                            <TextInput
                                                testId="IP_Start"
                                                grow
                                                required={!formValue.dhcp}
                                                disabled={formValue.dhcp}
                                                label={t.ipRangeStart}
                                                value={formValue.ipStart}
                                                changeCriteria={'key'}
                                                error={submitting && errors.ipStart}
                                                onChange={(value) =>
                                                    setFormValue({
                                                        ...formValue,
                                                        ipStart: value,
                                                    })
                                                }
                                            />
                                        </Box>
                                        <Box width="50%">
                                            <TextInput
                                                testId="IP_End"
                                                grow
                                                required={!formValue.dhcp}
                                                disabled={formValue.dhcp}
                                                label={t.ipRangeEnd}
                                                value={formValue.ipEnd}
                                                changeCriteria={'key'}
                                                error={submitting && errors.ipEnd}
                                                onChange={(value) =>
                                                    setFormValue({ ...formValue, ipEnd: value })
                                                }
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box direction="row" width="100%">
                                    <Stack flex="fullWidth">
                                        <Box width="50%">
                                            <TextInput
                                                testId="Subnet_Mask"
                                                grow
                                                required={!formValue.dhcp}
                                                disabled={formValue.dhcp}
                                                label={t.ipSubnetMask}
                                                value={formValue.subnetMask}
                                                changeCriteria={'key'}
                                                error={submitting && errors.subnetMask}
                                                onChange={(value) =>
                                                    setFormValue({
                                                        ...formValue,
                                                        subnetMask: value,
                                                    })
                                                }
                                            />
                                        </Box>
                                        <Box width="50%">
                                            <TextInput
                                                testId="Default_Router"
                                                grow
                                                required={!formValue.dhcp}
                                                disabled={formValue.dhcp}
                                                label={t.ipDefaultRouter}
                                                value={formValue.defaultRouter}
                                                changeCriteria={'key'}
                                                error={submitting && errors.defaultRouter}
                                                onChange={(value) =>
                                                    setFormValue({
                                                        ...formValue,
                                                        defaultRouter: value,
                                                    })
                                                }
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                    {!formValue.dhcp && info && (
                        <Box justifyContent="center" paddingTop="base">
                            {info}
                        </Box>
                    )}
                </Box>
            </Box>
            <Box justifyContent="end" paddingTop="base" paddingRight="base">
                <Stack justifyContent="end">
                    <Button testId={`project_network_form_cancel_btn`} text onClick={onCancel}>
                        {t.cancel}
                    </Button>
                    <Button
                        testId={`project_network_form_submit_btn`}
                        primary
                        onClick={() => {
                            setSubmitting(true);
                            if (isValidForm(formValue, errors)) {
                                onSubmit(formValue);
                            }
                        }}
                    >
                        {projectNetworkSettings ? t.save : t.addSetting}
                    </Button>
                </Stack>
            </Box>
        </Card>
    );
};

ProjectNetworkForm.displayName = 'ProjectNetworkForm';
