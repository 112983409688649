import { createSelector } from 'reselect';
import { getCurrentProjectFloorPlans } from 'app/modules/common';
import { getSelectedMapId } from './getSelectedId';

export const getSelectedFloorPlan = createSelector(
    [getSelectedMapId, getCurrentProjectFloorPlans],
    (id, floorPlans) => {
        if (!id || !floorPlans[id]) {
            return undefined;
        }
        return floorPlans[id];
    },
);

export const getIsDefaultFloorPlanSelected = createSelector([getSelectedFloorPlan], (floorPlan) => {
    return floorPlan?.isDefault ?? false;
});
