import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectFloorPlans,
    getCurrentProjectFreeTextPoints,
    getIdFromProps,
    isGeoLocated,
    toCacheKey,
    transformFreeTextPoint,
} from 'app/modules/common';
import { createSelector } from 'reselect';
import { getDerotationTransform } from './geolocations';
import { getSelectedMapOrDefault } from './getFloorPlans';
import { getOriginFilter } from './getOriginFilter';
import { getFreeTextPointsPerFloorPlan } from './floorPlanMapping';

const getAllFreeTextPoints = createSelector(
    [getCurrentProjectFreeTextPoints, getDerotationTransform],
    (freeTextPoints, transform) =>
        Object.keys(freeTextPoints).reduce(
            (acc, key) => {
                const freeTextPoint = freeTextPoints[key];
                if (freeTextPoint) {
                    acc[key] = transformFreeTextPoint(transform, freeTextPoint);
                }
                return acc;
            },
            {} as ReturnType<typeof getCurrentProjectFreeTextPoints>,
        ),
);

export const getFreeTextPointEntityFromId = createCachedSelector(
    [getAllFreeTextPoints, getIdFromProps],
    (freeTextPoints, id) => (id ? freeTextPoints[id] : undefined),
)(toCacheKey);

const getGeoLocatedFreeTextPoints = createSelector(
    [getFreeTextPointsPerFloorPlan, getCurrentProjectFloorPlans],
    (freeTextsPerFloorPlan, floorPlans) => {
        return Object.keys(freeTextsPerFloorPlan)
            .filter((floorPlanId) => {
                const floorPlan = floorPlans[floorPlanId];
                return !floorPlan || isGeoLocated(floorPlan);
            })
            .map((floorPlanId) => freeTextsPerFloorPlan[floorPlanId])
            .flat();
    },
);

export const getCurrentFloorPlanFreeTextPoints = createSelector(
    [getFreeTextPointsPerFloorPlan, getSelectedMapOrDefault],
    (fpMap, currentFloorPlan) => {
        return (currentFloorPlan && fpMap[currentFloorPlan._id]) ?? [];
    },
);

const getFreeTexts = createSelector(
    [getCurrentFloorPlanFreeTextPoints, getGeoLocatedFreeTextPoints, getSelectedMapOrDefault],
    (currentFloorPlanFreeTextPoints, geoLocatedFreeTextPoints, currentFloorPlan) =>
        isGeoLocated(currentFloorPlan) ? geoLocatedFreeTextPoints : currentFloorPlanFreeTextPoints,
);

const getFilteredFreeTexts = createSelector(
    [getFreeTexts, getOriginFilter],
    (freeTextPoints, originFilter) =>
        freeTextPoints.filter((blocker) => !originFilter || blocker.mapOrigin === originFilter),
);

export const getFreeTextIds = createSelector([getFilteredFreeTexts], (freeTextPoints) =>
    freeTextPoints.map(({ _id }) => _id),
);
