import { injectable } from 'inversify';
import type { IItemEntity, IPersistence, IInstallationPointEntity } from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';

@injectable()
export class MapDeviceService {
    constructor(private itemService: ItemService) {}

    public async canIncreaseDeviceQuantity(
        device: IPersistence<IItemEntity>,
        installationPoints: IPersistence<IInstallationPointEntity>[],
        projectLocked: boolean,
    ): Promise<boolean> {
        const nbrAddedInstallationPoints = installationPoints.filter(
            (ip) => ip.path.includes(device._id) && ip.path.length <= device.path.length + 1,
        ).length;

        if (projectLocked && nbrAddedInstallationPoints >= device.quantity) {
            return false;
        }

        if (device.quantity < nbrAddedInstallationPoints + 1) {
            return (
                (await this.itemService.updateItem(device._id, {
                    quantity: nbrAddedInstallationPoints + 1,
                })) !== null
            );
        }

        return true;
    }

    public async increaseChildItemQuantity(device: IPersistence<IItemEntity>) {
        return (
            (await this.itemService.updateItem(device._id, { quantity: device.quantity + 1 })) !==
            null
        );
    }
}
