import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Transforms formFactor property on camera entities to cameraTypes property.
 * Also transforms the different values 'Box', 'Cube' and 'Bullet' into a single value 'fixed'.
 */
@injectable()
export class Migration0To1 extends MigrationProviderBase {
    public from: number = 0;
    public to: number = 1;

    public provideMigration() {
        return (entity: any): Promise<any> => {
            if (entity.type === 'item' && entity.properties && entity.properties.camera) {
                const migratedTypes = [];
                const currentTypes = entity.properties.camera.filter.formFactor as string[];

                if (
                    currentTypes.indexOf('Box') >= 0 ||
                    currentTypes.indexOf('Cube') >= 0 ||
                    currentTypes.indexOf('Bullet') >= 0
                ) {
                    migratedTypes.push('fixed');
                }
                if (currentTypes.indexOf('Dome') >= 0) {
                    migratedTypes.push('dome');
                }
                if (currentTypes.indexOf('PTZ') >= 0) {
                    migratedTypes.push('ptz');
                }

                entity.properties.camera.filter.cameraTypes = migratedTypes;
                delete entity.properties.camera.filter.formFactor;
            }

            return Promise.resolve(entity);
        };
    }
}
