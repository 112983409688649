import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';

export type ExcludeEnvironments = 'iOS';

interface IExcludeProps extends IExtendableComponentWithChildren {
    /** What environment to exclude */
    for: ExcludeEnvironments;
}

const ENVIRONMENT_TO_KEYWORDS: Record<ExcludeEnvironments, string[]> = {
    iOS: ['iPad', 'iPhone', 'iPod'],
};

/**
 * This component can be used to exclude children based on browser detection.
 *
 * WARNING: Detecting browsers, operating systems etc. using the user agent is generally
 * not a good idea, prefer to use feature detection or other workaround.
 *
 * Read more here:
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
export class Exclude extends React.Component<IExcludeProps> {
    public render() {
        const { for: environment, children, ...extendedProps } = this.props;
        const keywords = ENVIRONMENT_TO_KEYWORDS[environment];

        if (!keywords || keywords.length === 0) {
            throw Error(`Could not use Exclude component for environment ${environment}`);
        }

        const attributes = extendableProps(extendedProps, {});
        const isExcluded = keywords.some((keyword) => navigator.userAgent.includes(keyword));

        return isExcluded
            ? null
            : renderReactChildren(
                  children,
                  (child) => React.cloneElement(child, attributes),
                  (child) => React.cloneElement(child, attributes.__htmlAttributes),
                  (child) => <span>{child}</span>,
              );
    }
}
