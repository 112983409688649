export enum PiaItemPacCategory {
    ACCESSSERVER = 'accessServers',
    ANSWERINGUNIT = 'answeringUnits',
    DOORCONTROLLERS = 'doorcontrollers',
    RELAYEXPMODULES = 'relayexpmodules',
    IORELAYS = 'iorelays',
    DOORSTATIONS = 'doorstations',
    NETWORKREADER = 'networkReaders',
    READERS = 'readers',
}
