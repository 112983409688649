import 'reflect-metadata';
import { ModuleRegistry } from './ModuleRegistry';
import type { IModuleOptions } from './models';

export function Module(options: IModuleOptions): ClassDecorator {
    // In this particular case we are actually dealing with an untyped function
    // and are unable to handle it as any other type.
    //

    // eslint-disable-next-line @typescript-eslint/ban-types
    return (target: Function) => {
        ModuleRegistry.registerModule(target.name, options);
    };
}
