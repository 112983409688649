import type { PartnerProductType, SolutionType, UseCaseSubType } from 'app/modules/common';

type partnerSortOrder = 'byPopularity' | 'byVendor';

export interface IPartnerProductFilter {
    /** Since products can be added with categories that are unaccounted for, we put these items under 'other' until categories have been added for these. */
    searchFilter: string;
    sortOrder: partnerSortOrder;
    useCase?: UseCaseSubType;
    solutionType?: SolutionType;
    partnerProductType: PartnerProductType | undefined;
    /** Determines if products for any country will be shown. Disables country filter. */
    anyCountry: boolean;
    compatibleWithDevicePiaId?: number;
}

export const defaultPartnerProductFilter: IPartnerProductFilter = {
    searchFilter: '',
    useCase: undefined,
    solutionType: undefined,
    sortOrder: 'byPopularity',
    partnerProductType: undefined,
    anyCountry: false,
    compatibleWithDevicePiaId: undefined,
};
