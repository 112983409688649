import * as React from 'react';
import { ServiceLocator } from 'app/ioc';
import { ApplicationActionService } from '../services';
import { Modal, Box, Button, Stack, Textarea, Text } from 'app/components';
import { t } from 'app/translate';
import { toaster } from 'app/toaster';

interface IFeedbackModalProps {
    sendButtonDisabled: boolean;
    onClose(): void;
}

interface IFeedbackModalState {
    value: string;
    sendButtonDisabled: boolean;
}

export class FeedbackModal extends React.Component<IFeedbackModalProps, IFeedbackModalState> {
    private applicationActionService: ApplicationActionService;

    constructor(props: IFeedbackModalProps) {
        super(props);
        this.state = {
            value: '',
            sendButtonDisabled: true,
        };

        this.applicationActionService = ServiceLocator.get(ApplicationActionService);
    }

    public render() {
        return (
            <Modal title={'AXIS Site Designer'}>
                <Stack vertical justifyContent="center">
                    <Text>{t.applicationFeedbackText}</Text>
                    <Textarea
                        maxLength={10e3}
                        rows={7}
                        value={this.state.value}
                        onChange={this.onTextChange}
                        changeCriteria="key"
                        autoFocus
                    />
                    <Text>{t.applicationFeedbackAnonymityNote}</Text>
                    <Box flex="shrinkAndGrow" justifyContent="end">
                        <Stack spacing="quart">
                            <Button text onClick={this.props.onClose}>
                                {t.close}
                            </Button>
                            <Button
                                onClick={this.onFeedbackSend}
                                primary
                                disabled={this.state.sendButtonDisabled}
                            >
                                {t.applicationFeedbackSend}
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Modal>
        );
    }

    private onTextChange = (textValue: string) => {
        this.setState({ ...this.state, sendButtonDisabled: textValue === '', value: textValue });
    };

    private getFeedbackText = (): string => {
        const feedbackValue = this.state.value;
        if (feedbackValue === '') {
            return '';
        }
        return `${this.state.value}`;
    };

    private onFeedbackSend = async () => {
        const feedback = this.getFeedbackText();
        this.props.onClose();
        if (feedback) {
            await this.applicationActionService.sendFeedback(feedback).payload;
            toaster.success(
                t.applicationFeedbackFeedbackSentHeader,
                t.applicationFeedbackFeedbackSentMessage,
            );
        }
    };
}
