import { createSelector } from 'reselect';
import { uniq, xor } from 'lodash-es';
import { getSelectedMapOrDefault, getAllMaps } from './getFloorPlans';
import { getProjectInstallationPoints } from './getProjectInstallationPoints';
import { isDefined } from 'axis-webtools-util';
import { getListableMapDevices, getListableMapDevicesMap } from './getListableDevices';
import { creationDateComparator, creationDateReverseComparator, idComparator } from 'app/utils';
import { getMapsState } from './getMapsState';
import { getInstallationPointsPerMap } from './floorPlanMapping';
import { getDeviceChildrenIds, getIdFromProps, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { toRecord, getParentId } from 'app/core/persistence';
import type { IInstallationPointEntity, Id, IFloorPlanEntity } from 'app/core/persistence';

export const getDevicesOnMapToggle = createSelector([getMapsState], (state) => {
    return state.showDevicesOnMapToggle;
});

/**
 * Returns the type of the currently selected floor plan.
 * Return values 'StreetMap' or 'FloorPlan'
 * @param state
 * @returns the devices that belong to the currently selected floor plan
 */
export const getSelectedFloorPlanType = createSelector(
    [getSelectedMapOrDefault],
    (currentFloorPlan) => {
        return currentFloorPlan?.mapType;
    },
);

/**
 * Returns the devices that are on the currently selected map.
 * First, it gets the installation points for the selected map.
 * Then, it gets the devices that are associated with those installation points
 * and returns them.
 */
export const getDevicesOnSelectedMap = createSelector(
    [getSelectedMapOrDefault, getInstallationPointsPerMap, getListableMapDevices],
    (currentMap, ipMap, devices) => {
        const ips = (currentMap && ipMap[currentMap._id]) ?? [];
        return uniq(
            ips
                .map(
                    ({ parentDevice }) =>
                        parentDevice && devices.find((item) => item.id === parentDevice._id),
                )
                .filter(isDefined)
                .sort(creationDateComparator),
        );
    },
);

/**
 * Returns a record from device id to a list of maps that the device is on.
 * First, it gets the installation points for each map.
 * Then, it gets the devices that are associated with those installation points
 * Then creates a record from device id to a list of maps that the device is on.
 *
 * Example:
 * {
 *  "device1": [{map1}, {map2}],
 *  "device2": [{map1}],
 * }
 */
export const getMapsPerDevice = createSelector(
    [getInstallationPointsPerMap, getListableMapDevicesMap, getAllMaps],
    (ipMap, devices, allMaps): Record<Id, IFloorPlanEntity[]> => {
        //ipMap is a map from map id to installation points
        //devices is a map from device id to device
        //allMaps is a map from map id to map
        const mapsPerDevice: Record<Id, Set<IFloorPlanEntity>> = {};

        //for each map in the map
        Object.keys(ipMap).forEach((mapId) => {
            //get the installation points for the map
            const ips = ipMap[mapId] ?? [];
            //for each installation point in the map
            ips.forEach((ip) => {
                //get the device for the installation point
                const device = devices[ip.parentDevice._id];

                const map = allMaps[mapId];
                //if the device exists add the map to the list of maps for the device
                if (device && map) {
                    mapsPerDevice[device.id] = mapsPerDevice[device.id] ?? new Set();
                    mapsPerDevice[device.id].add(map);
                }
            });
        });

        //convert the sets to arrays
        return Object.keys(mapsPerDevice).reduce(
            (acc, deviceId) => {
                acc[deviceId] = Array.from(mapsPerDevice[deviceId]);
                return acc;
            },
            {} as Record<Id, IFloorPlanEntity[]>,
        );
    },
);

export const getUnplacedDevicesOnFloorPlan = createSelector(
    [getListableMapDevices, getProjectInstallationPoints],
    (devices, installationsPoints) => {
        return xor(
            devices,
            installationsPoints
                .map((ip) => devices.find((device) => device.id === ip.parentDevice._id))
                .filter(isDefined),
        );
    },
);

const getUnplacedDevicesRecord = createSelector([getUnplacedDevicesOnFloorPlan], (devices) =>
    toRecord(devices, 'id'),
);
export const getUnplacedDevice = createCachedSelector(
    [getUnplacedDevicesRecord, getIdFromProps],
    (devicesRecord, id) => (id ? devicesRecord[id] : undefined),
)(toCacheKey);

export const getAllPlacedDevices = createSelector(
    [getListableMapDevices, getUnplacedDevicesOnFloorPlan],
    (devices, unplacedDevices) => {
        return xor(devices, unplacedDevices).sort(creationDateComparator);
    },
);

const getAllPlacedDevicesRecord = createSelector([getAllPlacedDevices], (devices) =>
    toRecord(devices, 'id'),
);

export const getPlacedDevice = createCachedSelector(
    [getAllPlacedDevicesRecord, getIdFromProps],
    (devicesRecord, id) => (id ? devicesRecord[id] : undefined),
)(toCacheKey);

export const getDeviceIpMarkerLabels = createCachedSelector(
    [getPlacedDevice, getDeviceChildrenIds, getProjectInstallationPoints],
    (placedDevice, childIds, installationPoints): Record<Id, string> => {
        if (!placedDevice) return {};
        // get the ids of the placed device and its children
        // example: ["device1", "device2", "device3"]
        const deviceIds = [placedDevice.id, ...childIds];

        // get the installation points for the device and its children
        // example: { "device1": [{ip1}, {ip2}], "device2": [{ip3}] }
        const ipsOnFloorPlans = deviceIds.reduce(
            (ipRecord, id) => {
                ipRecord[id] = installationPoints
                    .filter((ip) => getParentId(ip) === id)
                    .sort(idComparator)
                    .sort(creationDateReverseComparator);
                return ipRecord;
            },
            {} as Record<Id, IInstallationPointEntity[]>,
        );

        // get the installation points for the device and its children
        // example: { "device1": [{ip1}, {ip2}], "device2": [{ip3}] }
        const installationPointsForDeviceRecord = deviceIds.reduce(
            (ipsForDevice, id) => {
                // get the installation points for the device and its children
                const ipsForId = ipsOnFloorPlans[id];
                // for each installation point, add it to the record
                ipsForId.forEach((ip) => {
                    if (ip.parentId) {
                        // if the installation point has a parent, add it to the parent's list
                        ipsForDevice[ip.parentId] = (ipsForDevice[ip.parentId] ?? []).concat(ip);
                    } else {
                        // if the installation point does not have a parent, add it to the device's list
                        ipsForDevice[ip._id] = (ipsForDevice[ip._id] ?? []).concat(ip);
                    }
                });
                return ipsForDevice;
            },
            {} as Record<Id, IInstallationPointEntity[]>,
        );

        // get an array of arrays of installation points for each device
        // example: [[{ip1}, {ip2}], [{ip3}]]
        const deviceIps = Object.values(installationPointsForDeviceRecord);

        // create a map from installation point id to a label
        // if there are multiple installation points for a device, the labels of the
        // children will get a letter appended to the number of the parent
        // example: { "ip1": "1", "ip2": "1a", "ip3": "2" }
        const markerLabels: Record<Id, string> = {};
        for (let mainIndex = 0; mainIndex < deviceIps.length; mainIndex++) {
            for (let subIndex = 0; subIndex < deviceIps[mainIndex].length; subIndex++) {
                const ipId = deviceIps[mainIndex][subIndex]
                    ? deviceIps[mainIndex][subIndex]._id
                    : 'unplaced';
                const nameNumber = `${
                    subIndex === 0
                        ? mainIndex + 1
                        : mainIndex + 1 + String.fromCharCode(96 + subIndex)
                }`;
                markerLabels[ipId] = nameNumber;
            }
        }

        return markerLabels;
    },
)(toCacheKey);
