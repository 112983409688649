import { reducerBuilder } from 'app/store';
import type { IApplicationItem, IPartnerProductResponse } from 'app/modules/common';
import type { IPiaPartnerItem } from 'app/core/pia';
import type {
    IReadMore,
    IAddonSelectorState,
    IPartnerProductFilter,
    IIFrameParentBoundaries,
} from './models';
import { defaultPartnerProductFilter } from './models';
import { addonSelectorActions } from './addonSelectorActions';

const initialState: IAddonSelectorState = {
    isELicensePreferred: true,
    clickedApplication: undefined,
    showReadMoreModal: {
        show: false,
    },
    loadingPartnerProducts: false,
    partnerProducts: {
        items: [],
        next: null,
    },
    partnerItems: [],
    allPartnerProductsLoaded: false,
    partnerProductFilter: defaultPartnerProductFilter,
    standaloneParentBoundaries: undefined,
    isOtherSelectorOpen: false,
    showPartnerAcapListModal: false,
};

export const addonSelectorReducer = reducerBuilder<IAddonSelectorState>()
    .setInitialState(initialState)
    .onAction<boolean>(
        addonSelectorActions.ToggleELicense,
        (state, action): IAddonSelectorState => ({
            ...state,
            isELicensePreferred: action.payload,
        }),
    )
    .onAction<number | undefined>(
        addonSelectorActions.SetClickedApplication,
        (state, action): IAddonSelectorState => ({
            ...state,
            clickedApplication: action.payload,
        }),
    )
    .onAction<IReadMore>(addonSelectorActions.SetShowReadMoreModal, (state, action) => ({
        ...state,
        showReadMoreModal: action.payload,
    }))
    .onAction<IApplicationItem>(addonSelectorActions.SetReadMoreApplication, (state, action) => ({
        ...state,
        showReadMoreModal: {
            application: action.payload,
            show: true,
        },
    }))
    .onAction<Partial<IPartnerProductFilter>>(
        addonSelectorActions.UpdatePartnerProductFilter,
        (state, action) => ({
            ...state,
            partnerProductFilter: { ...state.partnerProductFilter, ...action.payload },
        }),
    )
    .onAction(addonSelectorActions.ResetPartnerProductFilter, (state) => ({
        ...state,
        partnerProductFilter: {
            ...defaultPartnerProductFilter,
        },
    }))
    .onAction(addonSelectorActions.ResetPartnerProductFilterExceptCompatible, (state) => ({
        ...state,
        partnerProductFilter: {
            ...defaultPartnerProductFilter,
            compatibleWithDevicePiaId: state.partnerProductFilter.compatibleWithDevicePiaId,
        },
    }))
    .onAsyncAction(addonSelectorActions.GetAllPartnerProducts, (handler) => {
        handler.onPending((state) => ({
            ...state,
            loadingPartnerProducts: true,
        }));

        handler.onFulfilled<IPartnerProductResponse>((state, action) => {
            return {
                ...state,
                partnerProducts: {
                    ...state.partnerProducts,
                    items: action.payload.items,
                },
                loadingPartnerProducts: false,
            };
        });

        handler.onRejected((state) => ({
            ...state,
            loadingPartnerProducts: false,
        }));
    })
    .onAction<IPiaPartnerItem[]>(addonSelectorActions.SetItems, (state, action) => {
        return {
            ...state,
            partnerItems: [...state.partnerItems, ...action.payload],
        };
    })
    .onAction<IPiaPartnerItem[]>(addonSelectorActions.SetAllProductsLoaded, (state) => {
        return {
            ...state,
            allPartnerProductsLoaded: true,
        };
    })
    .onAction<IAddonSelectorState[]>(addonSelectorActions.ResetPartnerProducts, (state) => {
        return {
            ...state,
            loadingPartnerProducts: false,
            partnerProducts: {
                items: [],
                next: null,
            },
            partnerItems: [],
            allPartnerProductsLoaded: false,
        };
    })
    .onAction<IIFrameParentBoundaries>(
        addonSelectorActions.UpdateReadMoreTopPosition,
        (state, action) => {
            return {
                ...state,
                standaloneParentBoundaries: action.payload,
            };
        },
    )
    .onAction<boolean>(addonSelectorActions.SetIsOtherProductSelectorOpen, (state, action) => ({
        ...state,
        isOtherSelectorOpen: action.payload,
    }))
    .onAction<boolean>(addonSelectorActions.SetShowPartnerAcapListModal, (state, action) => ({
        ...state,
        showPartnerAcapListModal: action.payload,
    }))
    .create();
