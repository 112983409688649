import * as React from 'react';
import type { Colors } from 'app/styles';
import type { Icons, IWithChildren } from 'app/components';
import { Box, Icon, Text, Stack, Clickable, Border } from 'app/components';

interface IMenuItem extends IWithChildren {
    icon?: Icons;
    text?: string;
    selected?: boolean;
    disabled?: boolean;
    onClick(): void;
}

export const MenuItem: React.FunctionComponent<IMenuItem> = ({
    children,
    icon,
    text,
    selected,
    disabled,
    onClick,
}) => {
    const textAndIconColor: Colors = selected ? 'grey9' : disabled ? 'grey4' : 'grey6';

    return (
        <Border rightWidth={3} color={selected ? 'yellow' : 'transparent'}>
            <Clickable disabled={disabled} onClick={onClick}>
                <Box
                    width="100%"
                    justifyContent="between"
                    paddingY="quart"
                    paddingRight="cell"
                    alignItems="center"
                >
                    <Stack alignItems="center" spacing="half">
                        {icon && <Icon icon={icon} color={textAndIconColor} size="sm" />}
                        {text && (
                            <Text style="semibold" color={textAndIconColor}>
                                {text}
                            </Text>
                        )}
                    </Stack>
                    <Box paddingLeft="panel" alignItems="center" justifyContent="center">
                        {children}
                    </Box>
                </Box>
            </Clickable>
        </Border>
    );
};

MenuItem.displayName = 'MenuItem';
