import { createSelector } from 'reselect';
import {
    getCurrentProjectFloorPlansArray,
    getCurrentProjectFreeTextPointsArray,
    getCurrentProjectBlockersArray,
    mapInstallationPointsToFloorPlan,
    mapInstallationPointsToGeoMap,
    mapFreeTextPointsToFloorPlan,
    mapFreeTextPointsToGeoMap,
    mapBlockersToFloorPlan,
    mapBlockersToGeoMap,
} from 'app/modules/common';
import { getProjectInstallationPoints } from './getProjectInstallationPoints';

// Installation Points

/**
 * Get the installation points as a map of floor plan id to installation points.
 */
export const getInstallationPointsPerFloorPlan = createSelector(
    [getProjectInstallationPoints, getCurrentProjectFloorPlansArray],
    mapInstallationPointsToFloorPlan,
);

/**
 * Get the installation points as a map of geo map id to installation points.
 */
export const getInstallationPointsPerGeoMap = createSelector(
    [getProjectInstallationPoints, getCurrentProjectFloorPlansArray],
    mapInstallationPointsToGeoMap,
);

/**
 * Get the installation points as a map map id to installation points.
 */
export const getInstallationPointsPerMap = createSelector(
    [getInstallationPointsPerFloorPlan, getInstallationPointsPerGeoMap],
    (installationPointsPerFloorPlan, installationPointsPerGeoMap) => {
        return {
            ...installationPointsPerFloorPlan,
            ...installationPointsPerGeoMap,
        };
    },
);

// Free Text Points

/**
 * Get the free text points as a map of floor plan id to free text points.
 */
export const getFreeTextPointsPerFloorPlan = createSelector(
    [getCurrentProjectFreeTextPointsArray, getCurrentProjectFloorPlansArray],
    mapFreeTextPointsToFloorPlan,
);

/**
 * Get the free text points as a map of geo map id to free text points.
 */
export const getFreeTextPointsPerGeoMap = createSelector(
    [getCurrentProjectFreeTextPointsArray, getCurrentProjectFloorPlansArray],
    mapFreeTextPointsToGeoMap,
);

/**
 * Get the free text points as a map map id to free text points.
 */
export const getFreeTextPointsPerMap = createSelector(
    [getFreeTextPointsPerFloorPlan, getFreeTextPointsPerGeoMap],
    (freeTextPointsPerFloorPlan, freeTextPointsPerGeoMap) => {
        return {
            ...freeTextPointsPerFloorPlan,
            ...freeTextPointsPerGeoMap,
        };
    },
);

// Blockers

/**
 * Get the blockers as a map of floor plan id to blockers.
 */
export const getBlockersPerFloorPlan = createSelector(
    [getCurrentProjectBlockersArray, getCurrentProjectFloorPlansArray],
    mapBlockersToFloorPlan,
);

/**
 * Get the blockers as a map of geo map id to blockers.
 */
export const getBlockersPerGeoMap = createSelector(
    [getCurrentProjectBlockersArray, getCurrentProjectFloorPlansArray],
    mapBlockersToGeoMap,
);

/**
 * Get the blockers as a map of map id to blockers.
 */
export const getBlockersPerMap = createSelector(
    [getBlockersPerFloorPlan, getBlockersPerGeoMap],
    (blockersPerFloorPlan, blockersPerGeoMap) => {
        return {
            ...blockersPerFloorPlan,
            ...blockersPerGeoMap,
        };
    },
);
