import * as React from 'react';
import type { IAutoTestable } from 'app/components/ui-test';
import { TextInput } from '../textInput/TextInput.component';

interface ISearchField extends IAutoTestable {
    /**
     * The displayed value of the input
     */
    value: string;
    /**
     * A method to trigger on change
     */
    onValueChange(newValue: string): void;
}

/**
 * Provides a text input with a search icon and
 * the ability to clear the value with a cancel button.
 * Will trigger on change.
 */
export const SearchField: React.FunctionComponent<ISearchField> = (props) => {
    return (
        <TextInput
            testId={props.testId}
            value={props.value}
            onChange={props.onValueChange}
            icon="search"
            clear={() => props.onValueChange('')}
            changeCriteria="key"
        />
    );
};

SearchField.displayName = 'SearchField';
