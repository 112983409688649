import type { IPiaCameraProperties, IPiaSensorDevice } from 'app/core/pia';
import { trigonometry } from 'axis-webtools-util';
import type { IDesiredCamera } from 'app/modules/common';
import { calculate } from 'app/modules/common';

/**
 * Pixel density is measured per sensor while other horizontal fov related filters combine all
 * sensors. Therefore lensCalcFov is used for multi sensor cameras here.
 */
export const isPixelDensityFulfilled = (
    properties: IPiaCameraProperties,
    desiredCamera: IDesiredCamera,
    trueDistance: number,
): boolean => {
    const isMultiSensor = properties.imageSensors && properties.imageSensors > 1;

    const maxHorizontalFOV =
        isMultiSensor && properties.maxLensCalcFOV
            ? properties.maxLensCalcFOV
            : properties.maxHorizontalFOV;

    const maxHorizontalFovRadians = trigonometry.toRadians(maxHorizontalFOV);

    const minHorizontalFOV =
        isMultiSensor && properties.minLensCalcFOV
            ? properties.minLensCalcFOV
            : properties.minHorizontalFOV;

    const minHorizontalFovRadians = trigonometry.toRadians(minHorizontalFOV);

    const selectedHorizontalFOVRadians = calculate.getSelectedFovRadians(
        desiredCamera.horizontalFOVRadians,
        minHorizontalFovRadians,
        maxHorizontalFovRadians,
    );

    const pixelDensity =
        properties.maxVideoResolutionHorizontal / (selectedHorizontalFOVRadians * trueDistance);

    return pixelDensity >= desiredCamera.pixelDensity;
};

export const hasMinimumVideoResolution = (
    sensorDevice: IPiaSensorDevice,
    resolution: string,
): boolean => {
    // Accept all if VGA is selected
    if (resolution === 'VGA') {
        return true;
    }

    let desiredResolution: number = 0;
    switch (resolution) {
        case '2160p':
            desiredResolution = 3840 * 2160;
            break;
        case '5MP':
            desiredResolution = 2560 * 1920;
            break;
        case '4MP':
            desiredResolution = 2688 * 1512;
            break;
        case '3MP':
            desiredResolution = 2048 * 1536;
            break;
        case '1080p':
            desiredResolution = 1920 * 1080;
            break;
        case '720p':
            desiredResolution = 1280 * 720;
            break;
        default:
            break;
    }

    const sensorUnitResolution =
        sensorDevice.properties.maxVideoResolutionHorizontal *
        sensorDevice.properties.maxVideoResolutionVertical;

    //* Allow a margin of 3% lower pixel amount than desired resolution.
    return sensorUnitResolution >= 0.97 * desiredResolution;
};
