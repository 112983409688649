import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { useService } from 'app/ioc';
import { getCurrentProjectName } from 'app/modules/common';
import { ApplicationActionService } from '../services';
import { SidebarMenu } from 'app/components/ui/sidebarMenu';
import { css } from '@emotion/css';
import { useWindowSize } from 'app/hooks';

const windowSizeBreakpoint = 1100;
const containerStyle = css`
    height: 100%;
    display: flex;

    @media screen and (max-width: ${windowSizeBreakpoint}px) {
        article:first-of-type {
            padding-left: 64px;
        }
    }

    @media print {
        display: block !important;
    }
`;
const mainPanelStyle = css`
    flex: 1 1 100%;
    overflow-y: auto;
`;
interface IMainPanelProps {
    sidebarContent: React.ReactNode;
    children?: React.ReactNode;
    showSidebar?: boolean;
}

export const MainPanel: React.FC<IMainPanelProps> = ({ sidebarContent, showSidebar, children }) => {
    const applicationActionService = useService(ApplicationActionService);
    const projectName = useSelector<IStoreState, string>((state) => getCurrentProjectName(state));
    const shouldShowSidebar = showSidebar ?? !!projectName;
    const leftMenuExpanded = useSelector<IStoreState, boolean>(
        (state) => state.app.userPreferences.leftMenuExpanded,
    );
    const [isMenuExpanded, setIsMenuExpanded] = React.useState(leftMenuExpanded);
    const location = window.location.pathname;
    const windowSize = useWindowSize();

    const closeOnSmallScreens = () => {
        if (window.innerWidth <= windowSizeBreakpoint) {
            setIsMenuExpanded(false);
        }
    };

    React.useEffect(closeOnSmallScreens, [windowSize]);

    React.useEffect(closeOnSmallScreens, [location]);

    const onExpandedChange = () => {
        const newState = !isMenuExpanded;
        applicationActionService.setUserPreferences({
            leftMenuExpanded: newState,
        });
        setIsMenuExpanded(newState);
    };

    return (
        <section className={containerStyle}>
            {shouldShowSidebar && (
                <SidebarMenu
                    testId="main_menu"
                    content={sidebarContent}
                    isMenuExpanded={isMenuExpanded}
                    onExpandedChange={onExpandedChange}
                />
            )}
            <article aria-expanded={leftMenuExpanded} className={mainPanelStyle}>
                {children}
            </article>
        </section>
    );
};

MainPanel.displayName = 'MainPanelContainer';
