import * as React from 'react';
import type { IBodyWornCameraProfile, Id } from 'app/core/persistence';
import { BandwidthInfo } from './BandwidthInfo.container';
import { SceneSelector } from './SceneSelector.container';
import { StorageSelector } from './StorageSelector.container';
import { ScheduleSelectorWearable } from './ScheduleSelector.container';
import { ResolutionSelector } from './ResolutionSelector.container';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getWearableProfile } from 'app/modules/common';

interface IDeviceProfileEditorWearablesProps {
    deviceId: Id;
}

export const DeviceProfileEditorWearables: React.FC<IDeviceProfileEditorWearablesProps> = ({
    deviceId,
}) => {
    const profile = useSelector<IStoreState, IBodyWornCameraProfile | undefined>((state) =>
        getWearableProfile(state, deviceId),
    );

    return (
        <>
            <BandwidthInfo deviceId={deviceId} />
            <SceneSelector deviceId={deviceId} />
            {profile && (
                <>
                    <ResolutionSelector deviceId={deviceId} profile={profile} />
                    <ScheduleSelectorWearable deviceId={deviceId} profile={profile} />
                </>
            )}
            <StorageSelector deviceId={deviceId} />
        </>
    );
};
