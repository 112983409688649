import type { IStyle } from './style';
import { fromClassName, entity } from './style';

export const mapping =
    (map: Record<string, string>) =>
    (value: string): IStyle =>
        Object.prototype.hasOwnProperty.call(map, value) ? fromClassName(map[value]) : entity();

export const toggle =
    (...classNames: string[]) =>
    (val?: boolean) =>
        val ? fromClassName(...classNames) : entity();
