import * as React from 'react';
import { t } from 'app/translate';
import { FilterPanel } from './FilterPanel';
import { Action, Box, Grid, PillButton, Stack, Text } from 'app/components';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { getIsEditMode } from '../../selectors';
import type { IWearablesFilter, WearablesType } from '../../models';
import { defaultWearablesFilter } from '../../models';
import { SearchField } from './common';
import { SustainabilityFilter } from './SustainabilityFilter';
import { WearablesRequirements } from '../wearables/';

export const WearablesFilterPanel: React.FC = () => {
    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );
    const wearablesFilter = useSelector<IStoreState, IWearablesFilter>(
        (store) => store.deviceSelector.wearablesFilter,
    );
    const { selectedCategory } = wearablesFilter;
    const isEditMode = useSelector<IStoreState, boolean>((store) => getIsEditMode(store));

    const resetFilter = () => {
        actionService.updateWearablesFilter(defaultWearablesFilter);
        actionService.setSearchText('');
    };

    const updateSelectedCategory = (filterType: WearablesType) => {
        actionService.updateWearablesFilter({
            selectedCategory: filterType === selectedCategory ? undefined : filterType,
        });
    };

    return (
        <FilterPanel>
            <Box direction="column" spacing="half" paddingBottom="panel">
                <SearchField />
                {!isEditMode && (
                    <>
                        <Stack alignItems="center" justifyContent="between">
                            <Text style="semibold" color="grey6">
                                {t.cameraSelectorRequirementsTitle}
                            </Text>
                            <Action title={t.reset} onClick={resetFilter} />
                        </Stack>
                        <Grid spacing="halfQuart">
                            <PillButton
                                selected={selectedCategory === 'bwCameras'}
                                text={t.cameras}
                                onClick={() => updateSelectedCategory('bwCameras')}
                            />
                            <PillButton
                                selected={selectedCategory === 'cameraExtensions'}
                                text={t.cameraExtensions}
                                onClick={() => updateSelectedCategory('cameraExtensions')}
                            />
                            <PillButton
                                selected={selectedCategory === 'dockingStations'}
                                text={t.dockingStations}
                                onClick={() => updateSelectedCategory('dockingStations')}
                            />
                            <PillButton
                                selected={selectedCategory === 'systemControllers'}
                                text={t.systemControllers}
                                onClick={() => updateSelectedCategory('systemControllers')}
                            />
                            <SustainabilityFilter />
                        </Grid>
                    </>
                )}
            </Box>
            {<WearablesRequirements />}
        </FilterPanel>
    );
};
