import * as React from 'react';
import { NumberInput } from '../../input/numberInput/NumberInput.component';
import { IconButton } from '../../ui/iconButton/IconButton.component';
import { Text } from '../../text/text/Text.component';
import { Box } from '../../containers/box/Box.component';
import { clamp } from 'lodash-es';

interface IQuantity {
    /**
     * The value to show
     */
    value: number;
    /**
     * The smallest possible value to choose
     */
    min?: number;
    /**
     * The largest possible value to choose
     */
    max?: number;
    /**
     * Disables the input and +/- buttons
     */
    disabled?: boolean;
    /**
     * Removes the input field
     */
    disabledInput?: boolean;
    /**
     * Trigger `onChange()` callback on key events instead of on blur
     */
    changeOnKey?: boolean;
    /**
     * When the counter is at the specified min value (or 1 if no min value is specified)
     */
    onDelete(): void;
    /**
     * The callback to run when the user edits the value
     */
    onChange(value: number): void;
}

/**
 * Renders a user controllable input for integers.
 */
export class Quantity extends React.Component<IQuantity> {
    public static defaultProps: Partial<IQuantity> = {
        min: 0,
        max: Number.MAX_VALUE,
    };

    public render() {
        const { min, max, value, onDelete, disabled, disabledInput, changeOnKey } = this.props;

        const shouldShowDelete = !!onDelete && value === (min || 1);
        const widthThresholdNumber = 999;

        return (
            <Box alignItems="center" spacing="half">
                <IconButton
                    icon={shouldShowDelete ? 'close_circle_outline' : 'remove_circle_outline'}
                    color={shouldShowDelete ? 'red' : 'blue'}
                    size="ms"
                    disabled={disabled || (!onDelete && min !== undefined && value <= min)}
                    onClick={shouldShowDelete ? onDelete : this.decreaseValue}
                />
                {!disabledInput && (
                    <NumberInput
                        testId="number_input"
                        value={value}
                        min={min}
                        max={this.props.max}
                        step={1}
                        width={value <= widthThresholdNumber ? '3em' : '3.2em'}
                        borderRadius="4px"
                        changeCriteria={changeOnKey ? 'key' : 'blur'}
                        disabled={disabled}
                        onChange={this.valueChanged}
                        centerText
                        hideSpinner
                    />
                )}
                {disabledInput && (
                    <Box justifyContent="center" width="2.3em">
                        <Text style="semibold" whiteSpace="nowrap">
                            {value}
                        </Text>
                    </Box>
                )}
                <IconButton
                    icon="add_circle_outline"
                    color="blue"
                    size="ms"
                    disabled={disabled || (max !== undefined && value >= max)}
                    onClick={this.increaseValue}
                />
            </Box>
        );
    }

    private increaseValue = () => this.valueChanged(this.props.value + 1);

    private decreaseValue = () => this.valueChanged(this.props.value - 1);

    private valueChanged = (newValue: number) =>
        this.props.onChange(clamp(newValue, this.props.min ?? 0, this.props.max ?? 9999));
}
