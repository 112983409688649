import type { Vector3 } from 'three';
import { BufferGeometry, BufferAttribute, EdgesGeometry } from 'three';
import { calculateRadialVectors } from '../../../utils';
import type { IConeGeometry } from './IConeGeometry';

export const getPanoramicCameraCone = (
    targetDistance: number,
    tiltAngle: number,
    intersectWithBlockers: (vector: Vector3, maxDistance: number) => Vector3,
    resolution = 300,
): IConeGeometry => {
    const coords = [];

    const isCeilingMounted = tiltAngle !== 0;

    const verticalResolution = isCeilingMounted ? resolution / 5 : resolution / 10;
    const horizontalResolution = isCeilingMounted ? resolution : resolution / 2;

    // calculate all vertices of hemisphere
    for (let i = 0; i < verticalResolution; i++) {
        // calculate the "latitude"
        const tilt = isCeilingMounted
            ? ((Math.PI / 2) * i) / verticalResolution
            : -Math.PI / 2 + (Math.PI * i) / verticalResolution;

        // get all vectors along the circle of latitude
        const vectors = calculateRadialVectors(
            tilt,
            isCeilingMounted ? Math.PI * 2 : Math.PI,
            horizontalResolution,
        );
        const edges = vectors.map((vector) => intersectWithBlockers(vector, targetDistance));

        coords.push(edges);
    }

    const camVertex = [0, 0, 0];

    // construct the geometry by adding all the vertices
    const verticeArray = [];

    for (let i = 0; i < coords[0].length - 1; i++) {
        const vector = coords[0][i];
        const nextVector = coords[0][i + 1];

        // construct face between camera, and both edge vectors
        verticeArray.push(...camVertex);
        verticeArray.push(...vector.toArray());
        verticeArray.push(...nextVector.toArray());
    }

    const topGeometry = new BufferGeometry();
    topGeometry.setAttribute('position', new BufferAttribute(new Float32Array(verticeArray), 3));
    const edges = new EdgesGeometry(topGeometry, 20);

    for (let i = 0; i < coords.length - 1; i++) {
        for (let j = 0; j < coords[i].length - 1; j++) {
            const vector = coords[i][j];
            const nextVector = coords[i][j + 1];
            const vector2 = coords[i + 1][j];
            const nextVector2 = coords[i + 1][j + 1];

            // construct face between camera, and both edge vectors
            verticeArray.push(...vector.toArray());
            verticeArray.push(...nextVector.toArray());
            verticeArray.push(...vector2.toArray());

            verticeArray.push(...nextVector.toArray());
            verticeArray.push(...nextVector2.toArray());
            verticeArray.push(...vector2.toArray());
        }
    }

    const vertices = new Float32Array(verticeArray);

    const geometry = new BufferGeometry();

    geometry.setAttribute('position', new BufferAttribute(vertices, 3));

    // cancel rotation used when calculating intersections. The geometry is rotated
    // together with the axis camera model later
    geometry.rotateZ(tiltAngle);
    edges.rotateZ(tiltAngle);

    return {
        geometry,
        edges,
    };
};
