import * as React from 'react';
import { css } from '@emotion/css';
import { isArray } from 'lodash-es';
import { Text } from '../../text/text/Text.component';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { isReactElement } from '../../services';
import { ColorsEnum } from 'app/styles';

const IndentedStyle = css`
    & > td:first-child {
        padding-inline-start: 35px;
    }
`;
export interface ITableRowProps extends IAutoTestable {
    /**
     * The contents of the row.
     * An array where each item is the contents of the cell
     */
    cells?: Array<string | number | undefined | JSX.Element>;
    /**
     * Adds some padding in the first cell
     */
    indented?: boolean;
    /**
     * Either specify a single value that will apply to all columns or specify
     * each column width separately in an array.
     */
    columnWidth?: string | string[];
    /**
     * If this row is to have another color
     */
    highLighted?: boolean;
}

/**
 * Allows easier creation of table rows
 */
export class TableRow extends React.Component<ITableRowProps> {
    public render() {
        return this.props.cells ? (
            <tr
                data-test-id={toTestIdFormat(this.props.testId)}
                className={this.props.indented ? IndentedStyle : ''}
            >
                {this.props.cells.map((cell, index) => (
                    <td
                        key={`${cell}-${index}`}
                        className={css`
                            width: ${this.getColumnWidth(index)};
                            vertical-align: baseline;
                            background-color: ${this.props.highLighted
                                ? ColorsEnum.yellow1
                                : 'inherit'};
                        `}
                    >
                        {isReactElement(cell) ? (
                            cell
                        ) : (
                            <Text testId={`${this.props.testId}_td_${index}`}>{cell ?? ''}</Text>
                        )}
                    </td>
                ))}
            </tr>
        ) : (
            <tr />
        );
    }

    private getColumnWidth(columnIndex: number): string | undefined {
        if (!this.props.columnWidth) {
            return undefined;
        }

        // Individual column widths
        if (isArray(this.props.columnWidth)) {
            return this.props.columnWidth.length > columnIndex
                ? this.props.columnWidth[columnIndex]
                : undefined;
        }
        // Column width is same for all columns
        else {
            return this.props.columnWidth;
        }
    }
}
