import { injectable } from 'inversify';
import { t } from 'app/translate';
import type {
    Id,
    IScheduleModel,
    IBaseProfileModel,
    ScenarioId,
    IRecordingSettingsModel,
    IAudioSettingsModel,
    IZipstreamSettingsModel,
    IStorageSettingsModel,
    IPersistence,
    IProjectEntity,
    IProfileEntity,
} from 'app/core/persistence';
import {
    ProjectService,
    ProfileService,
    ScheduleModelService,
    Time24,
    LightCondition,
    VideoEncoding,
    GopMode,
    Resolution,
    ScheduleDays,
    DEFAULT_RETENTION_TIME_IN_DAYS,
} from 'app/core/persistence';
import { ScenarioService } from './Scenario.service';
import { getAlwaysSchedule } from '../utils/getAlwaysSchedule';

@injectable()
export class ProjectDefaultsService {
    constructor(
        private scheduleModelService: ScheduleModelService,
        private profileService: ProfileService,
        private projectService: ProjectService,
        private scenarioService: ScenarioService,
    ) {}

    public async addProjectDefaults(projectId: Id): Promise<IPersistence<IProjectEntity>> {
        const alwaysSchedule = await this.addDefaultSchedules(projectId);
        const profile = await this.addDefaultProfiles(projectId, alwaysSchedule);
        return this.projectService.update(projectId, {
            defaultProfile: profile._id,
        });
    }

    public async addMissingDefaults(projectId: Id): Promise<IPersistence<IProjectEntity>> {
        const schedules = await this.scheduleModelService.getProjectSchedules(projectId);
        const profiles = await this.profileService.getProjectProfiles(projectId);
        let alwaysSchedule = schedules.length > 0 && getAlwaysSchedule(schedules);
        let profile = profiles[0];

        if (!alwaysSchedule) {
            alwaysSchedule = await this.addDefaultSchedules(projectId);
        }

        if (profiles.length < 1) {
            profile = await this.addDefaultProfiles(projectId, alwaysSchedule);
        }

        return this.projectService.update(projectId, {
            defaultProfile: profile._id,
        });
    }

    public getDefaultProfileSettings(
        profileName: string,
        alwaysSchedule: IScheduleModel,
        scenarioModelId: ScenarioId,
    ): IBaseProfileModel {
        const scenario = this.scenarioService.getScenario(scenarioModelId);

        if (!scenario) {
            throw new Error(`Default scenario ${scenarioModelId} is missing`);
        }

        const {
            triggeredDayMotion,
            triggeredNightMotion,
            continuousDayMotion,
            continuousNightMotion,
        } = scenario.dependencies;

        return {
            name: profileName,
            scenario: {
                scenarioId: scenario.id,
                sceneDetails: scenario.dependencies.sceneDetails,
                lightStart: new Time24('6:00'),
                lightEnd: new Time24('18:00'),
                nightLighting: LightCondition.Illuminated,
            },
            triggeredRecording: this.getDefaultRecordingSettings(
                triggeredDayMotion,
                triggeredNightMotion,
                alwaysSchedule.id,
            ),
            continuousRecording: this.getDefaultRecordingSettings(
                continuousDayMotion,
                continuousNightMotion,
                null,
                false,
                true,
            ),
            liveView: this.getDefaultRecordingSettings(
                continuousDayMotion,
                continuousNightMotion,
                null,
            ),
            storage: this.getDefaultStorageSettings(),
            audio: this.getDefaultAudioSettings(),
            zipstream: this.getDefaultZipstreamSettings(),
        };
    }

    private getDefaultStorageSettings(): IStorageSettingsModel {
        return {
            retentionTime: DEFAULT_RETENTION_TIME_IN_DAYS,
            useProjectSetting: true,
        };
    }

    private getDefaultRecordingSettings(
        dayMotion: number,
        nightMotion: number,
        scheduleId: Id | null,
        highFps: boolean = true,
        isContinousRecording: boolean = false,
    ): IRecordingSettingsModel {
        return {
            compression: 30,
            videoEncoding: VideoEncoding.h264,
            dayMotion,
            dayTriggerTime: 25,
            nightMotion,
            nightTriggerTime: 5,
            frameRate: highFps ? 25 : 15,
            schedule: scheduleId,
            resolution: new Resolution(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER),
            useAverageBitrate: isContinousRecording ? false : undefined,
        };
    }

    private getDefaultAudioSettings(): IAudioSettingsModel {
        return {
            liveViewEnabled: false,
            recordingEnabled: false,
        };
    }

    private getDefaultZipstreamSettings(): IZipstreamSettingsModel {
        return {
            gopDefault: 32,
            gopMax: 300,
            gopMode: GopMode.Fixed,
            zipStrength: 10,
            zipProfile: 'classic',
            fpsMode: 'fixed',
            useProjectSetting: true,
            minDynamicFps: 5,
        };
    }

    private async addDefaultSchedules(projectId: Id): Promise<IScheduleModel> {
        const alwaysSchedule = await this.scheduleModelService.addProjectSchedule(projectId, {
            name: t.schedulesSystemDefinedNamesAlways,
            systemDefined: true,
            timeSeries: [
                {
                    start: new Time24(0),
                    end: new Time24(24),
                    days: new ScheduleDays([true, true, true, true, true, true, true]),
                    isInverted: false,
                },
            ],
        });

        await this.scheduleModelService.addProjectSchedule(projectId, {
            name: t.schedulesSystemDefinedNamesOfficeHours,
            systemDefined: false,
            timeSeries: [
                {
                    start: new Time24(8),
                    end: new Time24(17),
                    days: new ScheduleDays([true, true, true, true, true, false, false]),
                    isInverted: false,
                },
            ],
        });

        return alwaysSchedule;
    }

    private async addDefaultProfiles(
        projectId: Id,
        alwaysSchedule: IScheduleModel,
    ): Promise<IPersistence<IProfileEntity>> {
        await this.profileService.addProjectProfile(
            projectId,
            this.getDefaultProfileSettings(
                t.profilesSystemDefinedNamesOutdoorProfile,
                alwaysSchedule,
                'parking',
            ),
        );

        return this.profileService.addProjectProfile(
            projectId,
            this.getDefaultProfileSettings(
                t.profilesSystemDefinedNamesIndoorProfile,
                alwaysSchedule,
                'retail',
            ),
        );
    }
}
