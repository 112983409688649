import * as React from 'react';
import { Box } from 'app/components';
import { getItemLocalStorage, setItemLocalStorage, ProjectService } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { GenetecStreamVaultProjectService, RecordingSelectorActionService } from '../../services';
import { ServiceLocator, useService } from 'app/ioc';
import {
    getDeviceRequirements,
    getGenetecProjectId,
    getGenetecRecommendationError,
    getGenetecRecommendationType,
    getGenetecRecommendations,
    getIsFetchingGenetecRecommendations,
} from '../../selectors';
import {
    getBandwidthStorageEstimateForItems,
    getCurrentProjectCountryCode,
    getPiaItemsRecord,
    getCurrentProjectItemsArray,
} from 'app/modules/common';
import { DEFAULT_GENETEC_TYPE } from '../../types';
import { useMount, usePrevious, useWindowSize } from 'app/hooks';
import { GenetecTermsAndConditions } from './GenetecTermsAndConditions';
import { getIsUserOnline } from 'app/modules/application';
import { GenetecOffline } from './GenetecOffline';
import { TABLET_WINDOW_SIZE } from 'app/modules/accessorySelector/constants';
import { eventTracking } from 'app/core/tracking';
import { GenetecEmptyProject } from './GenetecEmptyProject';
import { GenetecError } from './GenetecError';
import { GenetecRecommendations } from './GenetecRecommendations';

const STREAM_VAULT_CALCULATOR_LINK = 'https://svcalculator.genetec.com';

export const GenetecSelector: React.FunctionComponent = () => {
    const streamVaultProjectService = useService(GenetecStreamVaultProjectService);
    const projectService = useService(ProjectService);
    const allDeviceRequirements = useSelector(getDeviceRequirements);
    const bandwidthStorageEstimateForItems = useSelector(getBandwidthStorageEstimateForItems);
    const piaItems = useSelector(getPiaItemsRecord);
    const installationCountryCode = useSelector(getCurrentProjectCountryCode) ?? 'US';
    const isProjectEmpty = useSelector(getCurrentProjectItemsArray).length === 0;
    const isOnline = useSelector(getIsUserOnline);

    const recommendations = useSelector(getGenetecRecommendations);
    const isFetchingRecommendations = useSelector(getIsFetchingGenetecRecommendations);
    const selectedRecommendationType =
        useSelector(getGenetecRecommendationType) ?? DEFAULT_GENETEC_TYPE;
    const genetecProjectId = useSelector(getGenetecProjectId);
    const genetecRecommendationError = useSelector(getGenetecRecommendationError);
    const windowSize = useWindowSize();

    const isTabletSize = windowSize.width <= TABLET_WINDOW_SIZE;

    const isTermsAndConditionsAccepted =
        getItemLocalStorage('TermsAndConditionsAccepted') === 'true';

    const showOffline = !isOnline && recommendations.length === 0;
    const showProjectEmpty = !showOffline && isProjectEmpty;
    const showGenetecError =
        genetecRecommendationError && !isFetchingRecommendations && !showOffline && !isProjectEmpty;
    const showGenetecRecommendations = !showOffline && !showProjectEmpty && !showGenetecError;

    const [recordingSelectorActionService] = React.useState<RecordingSelectorActionService>(
        ServiceLocator.get(RecordingSelectorActionService),
    );

    const lastSelectedType = usePrevious(selectedRecommendationType);

    const [isUserOnline, setIsOnline] = React.useState<boolean>(isOnline);

    /* Get Genetec recommendations when mounting*/
    useMount(() => {
        if (!isProjectEmpty && recommendations.length < 1 && isTermsAndConditionsAccepted) {
            getRecommendations();
        }
    });

    /**When user has confirmed terms and conditions get recommendations from Genetec */
    const onConfirmTermsAndConditions = () => {
        setItemLocalStorage('TermsAndConditionsAccepted', true);
        eventTracking.logUserEvent('System Components', 'Accepted terms and conditions', 'Genetec');
        getRecommendations();
    };

    const getRecommendations = React.useCallback(async () => {
        return recordingSelectorActionService.getGenetecRecommendations(
            allDeviceRequirements,
            bandwidthStorageEstimateForItems,
            piaItems,
            installationCountryCode,
            selectedRecommendationType,
            genetecProjectId ?? undefined,
        );
    }, [
        recordingSelectorActionService,
        allDeviceRequirements,
        bandwidthStorageEstimateForItems,
        piaItems,
        installationCountryCode,
        selectedRecommendationType,
        genetecProjectId,
    ]);

    const onModifyRecommendations = async () => {
        // navigate to streamvault calculator
        eventTracking.logUserEvent('System Components', 'Genetec Streamvault calculator');

        const project = await streamVaultProjectService.getGenetecProject(
            allDeviceRequirements,
            bandwidthStorageEstimateForItems,
            piaItems,
            installationCountryCode,
            selectedRecommendationType,
            genetecProjectId ?? undefined,
            window.location.href,
        );

        if (project) {
            projectService.updateCurrentProject({
                genetecProjectId: project?.ProjectId,
            });
            recordingSelectorActionService.setGenetecProjectId(project?.ProjectId);

            window.open(`${STREAM_VAULT_CALCULATOR_LINK}/?projectId=${project?.ProjectId}`);
        }
    };

    /* Get recommendations when user has changed selectedType*/
    React.useEffect(() => {
        if (
            isTermsAndConditionsAccepted &&
            !isProjectEmpty &&
            lastSelectedType !== selectedRecommendationType
        ) {
            getRecommendations();
        }
    }, [
        getRecommendations,
        isProjectEmpty,
        isTermsAndConditionsAccepted,
        lastSelectedType,
        selectedRecommendationType,
    ]);

    /* get recommendations from genetec if no solutions in state and terms and conditions are accepted and user goes online*/
    React.useEffect(() => {
        if (
            !isProjectEmpty &&
            isTermsAndConditionsAccepted &&
            recommendations.length < 1 &&
            !isUserOnline &&
            isOnline
        ) {
            getRecommendations();
        }
        setIsOnline(isOnline);
    }, [
        getRecommendations,
        isOnline,
        isProjectEmpty,
        isTermsAndConditionsAccepted,
        isUserOnline,
        recommendations.length,
    ]);

    /*Get recommendations if the tab gets visible (if user has visited another window or another tab*/
    React.useEffect(() => {
        const onVisibilityChange = async () => {
            if (document.visibilityState === 'visible') {
                getRecommendations();
            }
        };
        window.addEventListener('visibilitychange', onVisibilityChange);
        return () => {
            window.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, [getRecommendations]);

    return (
        <Box
            maxWidth={isTabletSize ? '900px' : '1200px'}
            minWidth="900px"
            minHeight="300px"
            justifyContent="center"
        >
            {isTermsAndConditionsAccepted ? (
                <Box direction="column" width="100%">
                    {showOffline && <GenetecOffline />}
                    {showProjectEmpty && <GenetecEmptyProject />}
                    {showGenetecError && (
                        <GenetecError
                            onReload={() => getRecommendations()}
                            onModifyRecommendations={onModifyRecommendations}
                        />
                    )}
                    {showGenetecRecommendations && (
                        <GenetecRecommendations onModifyRecommendations={onModifyRecommendations} />
                    )}
                </Box>
            ) : (
                <Box direction="column" spacing="base" justifyContent="center">
                    {showProjectEmpty && <GenetecEmptyProject />}
                    <GenetecTermsAndConditions
                        onConfirmTermsAndConditions={onConfirmTermsAndConditions}
                    />
                </Box>
            )}
        </Box>
    );
};

GenetecSelector.displayName = 'GenetecSelector';
