import * as React from 'react';
import { css } from '@emotion/css';
import { AccessorySelectorView } from './AccessorySelector.view';

const EXTRA_MARGIN = 70;

const containerStyle = css`
    height: 100%;
    position: relative;
`;

interface IAccessorySelectorViewOwnProps {
    preSelectedDeviceId?: number;
}

export class StandaloneAccessorySelectorView extends React.PureComponent<IAccessorySelectorViewOwnProps> {
    private wrapperRef = React.createRef<HTMLDivElement>();
    private resizeObserver = new ResizeObserver((resizeEntries: ResizeObserverEntry[]) => {
        const accessoriesView = this.wrapperRef.current?.children[0];
        resizeEntries.forEach((resizeEntry: ResizeObserverEntry) => {
            if (resizeEntry.target === accessoriesView) {
                const newHeight = Math.round(resizeEntries[0].contentRect.height);
                // Send message to container that height has changed, using * here to support different targets
                parent.postMessage(`${newHeight + EXTRA_MARGIN}px`, '*');
            }
        });
    });

    componentDidMount() {
        const accessoriesView = this.wrapperRef.current?.children[0];
        if (accessoriesView) {
            this.resizeObserver.observe(accessoriesView);
        }
    }

    componentWillUnmount() {
        const accessoriesView = this.wrapperRef.current?.children[0];
        if (accessoriesView) {
            this.resizeObserver.unobserve(accessoriesView);
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef} className={containerStyle}>
                <AccessorySelectorView />
            </div>
        );
    }
}
