import { Border, Box, Positioned, Text } from 'app/components';
import { getBlockerEditState, getIsFreeTextToolActive } from '../../../selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';

export const FollowMouseText: React.FC = () => {
    const blockerEditState = useSelector(getBlockerEditState);
    const isFreeTextToolActive = useSelector(getIsFreeTextToolActive);
    const [mousePosition, setMousePosition] = React.useState<{ x: number; y: number }>({
        x: 10000,
        y: -1000,
    });

    const getFollowMouseText = () => {
        if (isFreeTextToolActive) {
            return t.clickToAddText;
        }

        switch (blockerEditState) {
            case 'create_start':
                return t.leafletPolylineStart;
            case 'edit_start':
                return t.leafletEditTooltipText;
            case 'deleting':
                return t.leafletRemoveTooltipText;
            default:
                return undefined;
        }
    };

    const followMouseText = getFollowMouseText();

    React.useEffect(() => {
        const updatePosition = (mouseEvent: MouseEvent) =>
            setMousePosition({ x: mouseEvent.x, y: mouseEvent.y });
        window.addEventListener('mousemove', updatePosition);
        return () => {
            window.removeEventListener('mousemove', updatePosition);
        };
    }, []);
    return followMouseText === undefined ? null : (
        <Positioned
            aboveMap
            position="fixed"
            left={mousePosition.x + 15}
            top={mousePosition.y - 15}
        >
            <Border shadow="0 1px 4px 1px rgba(0,0,0, 0.15)" width={0} radius="4px">
                <Box padding="half" color="white">
                    <Text style="small" color="grey9" whiteSpace="nowrap">
                        {followMouseText}
                    </Text>
                </Box>
            </Border>
        </Positioned>
    );
};

FollowMouseText.displayName = 'FollowMouseText';
