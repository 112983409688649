import { delay } from './delay';
export type Job = () => Promise<any>;

export class JobQueue {
    private queue: Job[] = [];
    private isProcessing = false;

    constructor(private delayBetween = 10) {}

    /**
     * Add a job to the queue and start processing
     *
     * @param job the job to add
     */
    public addJob(job: Job) {
        this.queue.push(job);

        if (!this.isProcessing) {
            this.processQueue();
        }
    }

    /**
     * Remove a job from the queue
     *
     * @param jobToRemove the job to remove
     */
    public removeJob(jobToRemove?: Job) {
        this.queue = this.queue.filter((job) => job !== jobToRemove);
    }

    /**
     * Sequentially process the queue
     */
    private processQueue(): Promise<any> {
        this.isProcessing = true;

        const job = this.queue.shift();

        if (!job) {
            // all items have been processed so we're done
            this.isProcessing = false;
            return Promise.resolve();
        }

        const processTail = () => delay(this.delayBetween).then(() => this.processQueue());

        // execute job, then rest of queue
        // Note: Using a finally block here doesn't seem to play well with jest, which
        // detects an unhandled promise rejection then. Hence the identical
        // resolution- and rejection handlers
        return job().then(processTail, processTail);
    }
}
