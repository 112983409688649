import type { IStoreState } from 'app/store';
import { isEmpty } from 'lodash-es';
import { createSelector } from 'reselect';
import { getPiaItemsRecord } from '../../piaDevices';
import { getSelectedLenses } from './getSelectedLenses';

const getSelectedSensorId = (state: IStoreState) => state.maps.selected?.coverageAreaInfo?.sensorId;

/** Gets lens for currently selected sensor in maps */
export const getSelectedDeviceChosenLens = createSelector(
    [getSelectedLenses, getSelectedSensorId, getPiaItemsRecord],
    (selectedLenses, selectedSensorId, piaItemsRecord) => {
        const sensorId = selectedSensorId || 1;

        if (!selectedLenses) {
            return undefined;
        }

        const selectedLens = selectedLenses[sensorId - 1];

        if (isEmpty(selectedLens)) {
            return undefined;
        }

        // selectedLens returns an object with a single key-value pair. To access the value object
        // we iterate over the keys and select the first (and only) entry.
        const lensItem = selectedLens[Object.keys(selectedLens)[0]];
        const productId = lensItem.productId;
        return productId ? piaItemsRecord[productId] : undefined;
    },
);
