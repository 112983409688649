import type { IPiaItem } from 'app/core/pia';
import type { IPv6 } from 'ip-num';
import { IPv4, IPv4CidrRange, IPv4Mask, IPv4Prefix, RangedSet, Validator } from 'ip-num';
import type {
    Id,
    IItemEntity,
    IItemNetworkSettings,
    IItemPropertiesEntity,
    IPersistence,
    IProjectNetworkSettings,
} from '../../userDataPersistence';
import { getNetworkRangeType, hasNetworkSettingsCapability } from '../../utils';
import { getNumberOfAddressesPerQuantity } from './getNumberOfAddressesPerQuantity';
import { toaster } from 'app/toaster';
import { t } from 'app/translate';

/**
 * Get a new network settings for item in current project.
 * @param id - item id used to find network setting.
 * @param properties - item properties to find correct network range.
 * @param quantity - item quantity determines how many IP addresses to take from network range.
 * @param piaItem - device from PIA used to determine if item is a recorder or software license.
 * Null represents that no product is selected.
 * @param projectNetworkSettings - Network settings for project or null if missing.
 * @param items - Array of items for current project. Used to determine occupied addresses.
 * @param occupiedIPv4Addresses - optional array of additional occupied IPv4 addresses.
 * @returns Array of IP addresses or undefined if not project network settings.
 */
export const getNewItemNetworkSettings = (
    id: Id | null,
    properties: IItemPropertiesEntity,
    quantity: number,
    piaItem: IPiaItem | null,
    projectNetworkSettings: IProjectNetworkSettings | undefined,
    items: IPersistence<IItemEntity>[],
    occupiedIPv4Addresses?: IPv4[],
): IItemNetworkSettings[] | undefined => {
    if (!projectNetworkSettings || !hasNetworkSettingsCapability(properties, piaItem)) {
        return undefined;
    }

    const addressesPerQuantity = getNumberOfAddressesPerQuantity(piaItem);

    if (projectNetworkSettings.dhcp) {
        return Array<IItemNetworkSettings>(quantity).fill({
            addresses: Array(addressesPerQuantity).fill(''),
            dhcp: projectNetworkSettings.dhcp,
        });
    }

    // Type of device for a network range.
    const rangeType = getNetworkRangeType(properties, piaItem);
    if (!rangeType) {
        // Not supported network range type
        return undefined;
    }

    const { defaultRouter, subnetMask } = projectNetworkSettings;

    // Find network range for given type in project
    const networkRange = !projectNetworkSettings.ranges[rangeType]
        ? undefined
        : projectNetworkSettings.ranges[rangeType];

    if (!networkRange) {
        return undefined;
    }

    const ipStart = networkRange.ipStart;
    const ipEnd = networkRange.ipEnd;

    let rangeSet: RangedSet<IPv4> | RangedSet<IPv6>;
    try {
        rangeSet = RangedSet.fromRangeString(`${ipStart}-${ipEnd}`);
    } catch (e) {
        // Not supported network range
        return undefined;
    }
    const ipv4CidrRange = new IPv4CidrRange(
        IPv4.fromString(ipStart),
        new IPv4Prefix(BigInt(IPv4Mask.fromDecimalDottedString(subnetMask).prefix)),
    );

    // Get occupied IP addresses
    let occupiedAddresses = items.reduce((allAddresses, currentItem) => {
        if (!currentItem.networkSettings || currentItem._id === id) {
            return allAddresses;
        }

        const addressesPerItem: IPv4[] = [];
        currentItem.networkSettings.forEach((setting) =>
            setting.addresses.forEach((address) => {
                if (Validator.isValidIPv4String(address)[0]) {
                    const ipv4Address = IPv4.fromDecimalDottedString(address);
                    if (
                        rangeSet.getFirst().isLessThanOrEquals(ipv4Address) &&
                        rangeSet.getLast().isGreaterThanOrEquals(ipv4Address)
                    ) {
                        addressesPerItem.push(ipv4Address);
                    }
                }
            }),
        );
        return [...allAddresses, ...addressesPerItem];
    }, [] as IPv4[]);

    occupiedAddresses = occupiedIPv4Addresses
        ? occupiedAddresses.concat(occupiedIPv4Addresses)
        : occupiedAddresses;

    // Add already taken IP addresses
    const router = IPv4.fromDecimalDottedString(defaultRouter);
    if (
        rangeSet.getFirst().isLessThanOrEquals(router) &&
        rangeSet.getLast().isGreaterThanOrEquals(router)
    ) {
        occupiedAddresses.push(router);
    }

    // first address is the network identification.
    if (ipv4CidrRange.getFirst().isEquals(rangeSet.getFirst())) {
        occupiedAddresses.push(ipv4CidrRange.getFirst()); // *.*.*.0 address
    }
    // last address is the broadcast.
    if (ipv4CidrRange.getLast().isEquals(rangeSet.getLast())) {
        occupiedAddresses.push(ipv4CidrRange.getLast()); // *.*.*.255 address
    }

    let addressesLeftPerQuantity = addressesPerQuantity;

    // Check available number of available IP addresses.
    let nrOfIpAddressesLeft = quantity * addressesLeftPerQuantity;
    if (Number(rangeSet.getSize()) - occupiedAddresses.length - quantity < 0) {
        const currentItem = items.find((item) => item._id === id);
        toaster.info(t.noFreeIp, t.noFreeIp_description);
        return currentItem?.networkSettings ?? undefined;
    }

    // Add IP addresses.
    const newNetworkSettings: IItemNetworkSettings[] = [];

    let currentIndex = 0;

    for (const ip of rangeSet.take()) {
        // Validate not occupied address.
        if (!occupiedAddresses.some((occupied) => occupied.isEquals(ip))) {
            nrOfIpAddressesLeft--;
            addressesLeftPerQuantity--;
            if (!newNetworkSettings[currentIndex]) {
                newNetworkSettings.push({ addresses: [ip.toString()] });
            } else {
                newNetworkSettings[currentIndex].addresses.push(ip.toString());
            }
            if (nrOfIpAddressesLeft === 0) {
                break;
            }
            if (addressesLeftPerQuantity === 0) {
                currentIndex++;
                addressesLeftPerQuantity = addressesPerQuantity;
            }
        }
    }

    return newNetworkSettings.length > 0 ? newNetworkSettings : undefined;
};
