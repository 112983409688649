import { trigonometry } from 'axis-webtools-util';
import type { SpeakerPlacement } from 'app/core/persistence';
import * as PolyBool from 'polybooljs';
import type { Line } from 'app/modules/common';
import { getCircle, toPolygon } from './cameraCalc';

const HORN_COVERAGE_OUTDOOR = 70;
const SPEAKER_COVERAGE_FACTOR = 3;
const SPEAKER_COVERAGE_THRESHOLD = 140;
const WIDE_ANGLE_BASE_WIDTH = 1; // wide angle speakers are mounted flush to the wall and have a small fixed base width

const isWideAngle = (coverage: number) => coverage >= SPEAKER_COVERAGE_THRESHOLD;

/**
 * get the width of the base of the speaker cone
 */
const getWallSpeakerBaseWidth = (speakerHeight: number, targetHeight: number, coverage: number) =>
    isWideAngle(coverage)
        ? WIDE_ANGLE_BASE_WIDTH
        : SPEAKER_COVERAGE_FACTOR * (speakerHeight - targetHeight);

/**
 * get the maximum audible distance of a ceiling mounted speaker
 */
export const getCeilingMaxDistance = (
    speakerHeight: number,
    coverage: number,
    basicSolution: boolean,
) => {
    const coverageFactor = isWideAngle(coverage) ? 2.0 : 1.5;
    const premiumDistance = speakerHeight * coverageFactor;

    return premiumDistance / (basicSolution ? 1 : Math.sqrt(2));
};

/**
 * get the maximum audible distance of a wall mounted speaker
 */
const getWallMaxDistance = (
    speakerHeight: number,
    speakerMaxHeight: number,
    coverage: number,
    basicSolution: boolean,
) => {
    // we calculate the max distance for wall mounted speakers from the max distance of an imagined ceiling mounted camera
    const ceilingMaxDistance = getCeilingMaxDistance(speakerMaxHeight, coverage, basicSolution); // 2d max distance for ceiling camera
    const hyp2 = Math.pow(speakerMaxHeight, 2) + Math.pow(ceilingMaxDistance, 2); // squared hypotenuse (3d max distance^2)
    const distance = Math.sqrt(hyp2 - Math.pow(speakerHeight, 2)); // 2d max distance for wall camera

    return distance;
};

const getHornSpeakerMaxDistance = () => 2 * HORN_COVERAGE_OUTDOOR;

/**
 * Calculate the speaker area for ceiling mounted speaker
 */
const calculateWallSpeakerArea = (
    speakerHeight: number,
    targetHeight: number,
    targetDistance: number,
    coverage: number,
    basicSolution: boolean,
    outdoor: boolean,
    isHornSpeaker: boolean,
    maxHeight: number,
): Line => {
    const premiumBaseWidth =
        isHornSpeaker && outdoor
            ? HORN_COVERAGE_OUTDOOR
            : getWallSpeakerBaseWidth(speakerHeight, targetHeight, coverage);
    const baseWidth = premiumBaseWidth * (basicSolution ? 2 : 1);

    const maxDistance =
        isHornSpeaker && outdoor
            ? getHornSpeakerMaxDistance()
            : getWallMaxDistance(speakerHeight, maxHeight, coverage, false);

    const distance = Math.min(maxDistance, targetDistance);

    const alpha = trigonometry.toRadians(coverage) / 2; //coverage angle
    const y = distance * Math.tan(alpha);

    // create a convex isosceles trapezoid from speaker to distance
    // the smaller of the bases has the width baseWidth, the larger base is
    // calculated from the distance and coverage angle
    //
    //        baseWidth
    //       _____o_____
    //      |           \
    //     |             \
    //    |               \
    //   |                 \
    //  |___________________\
    //
    const baseShape: Line = [
        [0, baseWidth / 2],
        [distance, y],
        [distance, -y],
        [0, -baseWidth / 2],
    ];

    // create a circle segment
    const circleSegment = getCircle(distance, 180);

    // intersect the two shapes
    const intersections = PolyBool.intersect(toPolygon(baseShape), toPolygon(circleSegment));

    return intersections.regions.length > 0 ? intersections.regions[0] : [];
};

/**
 * Calculate the speaker area for ceiling mounted speaker
 */
const calculateCeilingSpeakerArea = (
    speakerHeight: number,
    coverage: number,
    basicSolution: boolean,
): Line => {
    const radius = getCeilingMaxDistance(speakerHeight, coverage, basicSolution);

    return getCircle(radius);
};

/**
 * Calculate the audible area for a speaker
 */
export const calculateSpeakerArea = (
    placement: SpeakerPlacement,
    speakerHeight: number,
    targetHeight: number,
    targetDistance: number,
    coverage: number,
    basicSolution: boolean,
    outdoor: boolean,
    isHornSpeaker: boolean,
    maxHeight: number,
): Line =>
    placement === 'ceiling'
        ? calculateCeilingSpeakerArea(speakerHeight, coverage, basicSolution)
        : calculateWallSpeakerArea(
              speakerHeight,
              targetHeight,
              targetDistance,
              coverage,
              basicSolution,
              outdoor,
              isHornSpeaker,
              maxHeight,
          );
