import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { t } from 'app/translate';

/**
 * Changes D3110 Connectivity hub properties from systemAccessory to connectivityDevice for projects
 * that have added D3110 when it had category 'audioandio'. Will also add name since no name was
 * added to system accessories
 */
@injectable()
export class Migration49To50 extends MigrationProviderBase {
    public from: number = 49;
    public to: number = 50;

    constructor() {
        super();
    }

    public provideMigration() {
        const AXIS_D3110_CONNECTIVITY_HUB_PIA_ID = 72053;
        return async (entity: any): Promise<any> => {
            if (
                entity.productId === AXIS_D3110_CONNECTIVITY_HUB_PIA_ID &&
                entity.type === 'item' &&
                entity.properties.systemAccessory
            ) {
                entity.properties = { connectivityDevice: { filter: { outdoor: false } } };
                entity.name = entity.name ? entity.name : t.newConnectivityDevice;
            }

            return entity;
        };
    }
}
