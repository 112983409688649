import * as React from 'react';
import { t } from 'app/translate';
import { Card, Box, Button, Icon, Text, Opacity } from 'app/components';
import { useSelector } from 'react-redux';
import { getRecommendations, getViewingAxisAcsType } from '../selectors';
import { CommonActionService, getRecordingSolutionTypeFromProject } from 'app/modules/common';
import { selectRecommendation } from './helpers';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';

interface IUpdateSolutionWarningProps {
    showWarning: boolean | undefined;
}

/** Shows a warning if the chosen Axis solution (recommendation) is no longer valid */
export const UpdateSolutionWarning: React.FC<IUpdateSolutionWarningProps> = ({ showWarning }) => {
    const actions = useService(CommonActionService);
    const selectedSolutionType = useSelector(getRecordingSolutionTypeFromProject);
    const recommendations = useSelector(getRecommendations);
    const viewingAcsType = useSelector(getViewingAxisAcsType);
    const recommendationToUse = recommendations.find(
        ({ id: recommendationType }) => recommendationType === selectedSolutionType,
    );

    const canUpdateSolution = selectedSolutionType && !!recommendationToUse;

    const updateSolution = () => {
        eventTracking.logUserEvent(
            'System Components',
            'Update Solution',
            'Update from solution warning',
        );
        showWarning &&
            recommendationToUse &&
            selectRecommendation(
                selectedSolutionType,
                recommendationToUse,
                false,
                selectedSolutionType,
                viewingAcsType,
            );
    };

    const customizeSolution = () => {
        actions.setRecordingOverlay(undefined, undefined, true);
    };

    return (
        <Opacity alpha={showWarning ? 1 : 0} animation="opacity 150ms ease-in-out 0.5s">
            <Card
                borderColor="red"
                notFullWidth
                paddingX="half"
                paddingY="half"
                testId="update_solution_warning"
            >
                <Box
                    display="grid"
                    spacing="half"
                    alignItems="center"
                    grid={{
                        gridTemplateColumns: 'min-content 1fr min-content',
                    }}
                >
                    <Icon opaque icon="error" color="red" />
                    <Text>{canUpdateSolution ? t.needToUpdateSolution : t.solutionNotViable}</Text>
                    {canUpdateSolution && (
                        <Button text onClick={updateSolution} disabled={!showWarning}>
                            <Text whiteSpace="nowrap" style="semibold" color="blue">
                                {t.updateSolution}
                            </Text>
                        </Button>
                    )}
                    {!canUpdateSolution && (
                        <Button text onClick={customizeSolution} disabled={!showWarning}>
                            <Text whiteSpace="nowrap" style="semibold" color="blue">
                                {t.selectNewSolution}
                            </Text>
                        </Button>
                    )}
                </Box>
            </Card>
        </Opacity>
    );
};

UpdateSolutionWarning.displayName = 'UpdateSolutionWarning';
