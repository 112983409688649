import { trigonometry } from 'axis-webtools-util';
import type { AnalyticsComputeCapabilityType, UnitSystem } from 'app/core/persistence';
import { LightConditions, getDefaultCameraFilterEntity, CameraType } from 'app/core/persistence';
import type {
    IPiaBandwidthCalculationProperties,
    IPiaItem,
    IPiaSensorDevice,
    IPRatings,
    PoeClass,
} from 'app/core/pia';
import { getPoeClassNumber, PiaItemCameraCategory } from 'app/core/pia';

import type {
    IMultiOptionalSelectorFilter,
    IOptionalNumberFilter,
    IOptionalSelectorFilter,
} from '../cameras';
import { hasMinimumVideoResolution, isPixelDensityFulfilled, hasApplicableLens } from '../../utils';
import {
    defaultMinOperationalTemperature,
    defaultMaxOperationalTemperature,
} from '../../constants';
import type { IDesiredCamera } from 'app/modules/common';
import { calculate, desiredCameraUtils } from 'app/modules/common';
import { meetsSustainabilityRequirements } from './SustainabilityFilter';

export interface ICameraFilter {
    alarmInputsOutputs: boolean;
    analyticsComputeCapability?: AnalyticsComputeCapabilityType;
    audioSupport: boolean;
    bfrCfrFree: boolean;
    builtInMicrophone: boolean;
    casingMaterial: boolean;
    dayNightFunc: boolean;
    desiredCamera: IDesiredCamera;
    edgeVault: boolean;
    HDMIOutput: boolean;
    imageStabilization: boolean;
    intelligentVideoCapable: boolean;
    ipRating: IPRatings | undefined;
    IRLEDs: boolean;
    lensChangeable: boolean;
    lowLightTechnology: boolean;
    minRecycledPlastic: number | undefined;
    minVideoResolution: string | undefined;
    maxPoeClass: PoeClass | undefined;
    ONVIFProfile: IMultiOptionalSelectorFilter;
    operationalTemperature: IOptionalNumberFilter;
    opticalZoom: IOptionalSelectorFilter;
    powerOverEthernet: boolean;
    pvcFree: boolean;
    remoteFocus: boolean;
    remoteZoom: boolean;
    minFps: number;
    ruggedizedEN50155: boolean;
    secureBoot: boolean;
    signedFirmware: boolean;
    signedVideo: boolean;
    slotForMemoryCard: boolean;
    TPM: boolean;
    twoWayAudio: boolean;
    vandalResistant: boolean;
    vandalRating: IOptionalSelectorFilter;
    varifocal: boolean;
    WDRTechnology: IOptionalSelectorFilter;
    wireless: boolean;
    zipStream: boolean;
}

export function getDefaultCameraFilter(
    unit: UnitSystem,
    installationHeight: number,
): ICameraFilter {
    return {
        casingMaterial: false,
        minVideoResolution: undefined,
        maxPoeClass: undefined,
        vandalResistant: false,
        ruggedizedEN50155: false,
        IRLEDs: false,
        dayNightFunc: false,
        edgeVault: false,
        wireless: false,
        powerOverEthernet: false,
        audioSupport: false,
        zipStream: false,
        lowLightTechnology: false,
        opticalZoom: {
            option: '-15x',
            active: false,
        },
        varifocal: false,
        HDMIOutput: false,
        imageStabilization: false,
        lensChangeable: false,
        remoteFocus: false,
        remoteZoom: false,
        intelligentVideoCapable: false,
        pvcFree: false,
        slotForMemoryCard: false,
        secureBoot: false,
        signedFirmware: false,
        signedVideo: false,
        minFps: 0,
        TPM: false,
        twoWayAudio: false,
        alarmInputsOutputs: false,
        builtInMicrophone: false,
        ONVIFProfile: {
            option: [],
            active: false,
        },
        WDRTechnology: {
            option: 'Forensic Capture|Dynamic Capture|Dynamic Contrast|Forensic WDR|WDR - dynamic capture|WDR - dynamic contrast',
            active: false,
        },
        vandalRating: {
            option: 'IK08',
            active: false,
        },
        ipRating: undefined,
        // Currently the 'worst' values for min and max temp
        // ? Can this be set dynamically?
        operationalTemperature: {
            option: defaultMinOperationalTemperature,
            secondaryOption: defaultMaxOperationalTemperature,
            active: false,
        },
        analyticsComputeCapability: undefined,
        desiredCamera: desiredCameraUtils.convertPropertiesToDesiredCamera(
            getDefaultCameraFilterEntity(unit, installationHeight),
        ),
        bfrCfrFree: false,
        minRecycledPlastic: undefined,
    };
}
export class CameraFilter {
    public static filter(
        cameraFilter: ICameraFilter,
        allCameras: IPiaSensorDevice[],
        searchFilter: string,
        piaLenses: Record<number, IPiaItem>,
        maxFpsKey?: keyof Pick<IPiaBandwidthCalculationProperties, 'maxFPS50Hz' | 'maxFPS60Hz'>,
    ): IPiaSensorDevice[] {
        const desiredCameraCategories = CameraFilter.getDesiredCameraCategories(
            cameraFilter.desiredCamera.cameraTypes,
        );

        const lowerCaseSearchFilter = searchFilter.toLowerCase();
        const matchingCameras = allCameras.filter((camera) => {
            if (!camera.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (cameraFilter.vandalResistant && !camera.properties.vandalResistant) {
                return false;
            }
            if (maxFpsKey && camera.properties[maxFpsKey] < cameraFilter.minFps) {
                return false;
            }
            if (cameraFilter.ruggedizedEN50155 && !camera.properties.ruggedizedEN50155) {
                return false;
            }
            if (cameraFilter.IRLEDs && !camera.properties.IRLEDs) {
                return false;
            }
            if (cameraFilter.dayNightFunc && !camera.properties.dayNightFunc) {
                return false;
            }
            if (cameraFilter.wireless && !camera.properties.wireless) {
                return false;
            }
            if (cameraFilter.powerOverEthernet && !camera.properties.PoEClass) {
                return false;
            }
            if (cameraFilter.audioSupport && !camera.properties.audioSupport) {
                return false;
            }
            if (cameraFilter.twoWayAudio && !camera.properties.twoWayAudio) {
                return false;
            }
            if (cameraFilter.zipStream && !camera.properties.zipStream) {
                return false;
            }
            if (
                cameraFilter.lowLightTechnology &&
                camera.properties.lowLightTechnology !== 'Lightfinder'
            ) {
                return false;
            }
            if (cameraFilter.varifocal && !camera.properties.varifocal) {
                return false;
            }
            if (cameraFilter.lensChangeable && !camera.properties.lensChangeable) {
                return false;
            }
            if (cameraFilter.remoteFocus && !camera.properties.remoteFocus) {
                return false;
            }
            if (cameraFilter.remoteZoom && !camera.properties.remoteZoom) {
                return false;
            }
            if (!CameraFilter.hasDesiredCameraType(desiredCameraCategories, camera)) {
                return false;
            }
            if (cameraFilter.desiredCamera.outdoor && !camera.properties.outdoorReady) {
                return false;
            }
            if (cameraFilter.desiredCamera.corridorFormat && !camera.properties.corridorFormat) {
                return false;
            }
            if (
                cameraFilter.desiredCamera.lightConditions.indexOf(LightConditions.BACKLIGHT) >=
                    0 &&
                !camera.properties.WideDynamicRange
            ) {
                return false;
            }
            if (
                !CameraFilter.hasValidLightCondition(
                    cameraFilter.desiredCamera.lightConditions,
                    camera,
                )
            ) {
                return false;
            }
            if (
                cameraFilter.intelligentVideoCapable &&
                !camera.properties.intelligentVideoCapable
            ) {
                return false;
            }
            if (cameraFilter.slotForMemoryCard && !camera.properties.slotForMemoryCard) {
                return false;
            }
            if (cameraFilter.alarmInputsOutputs && !camera.properties.alarmInputsOutputs) {
                return false;
            }
            if (cameraFilter.builtInMicrophone && !camera.properties.builtInMicrophone) {
                return false;
            }
            if (
                cameraFilter.minVideoResolution &&
                !hasMinimumVideoResolution(camera, cameraFilter.minVideoResolution)
            ) {
                return false;
            }
            if (
                cameraFilter.ONVIFProfile.active &&
                !CameraFilter.hasValidONVIFProfile(camera, cameraFilter.ONVIFProfile.option)
            ) {
                return false;
            }
            if (
                cameraFilter.vandalRating.active &&
                cameraFilter.vandalRating.option !== camera.properties.vandalRating
            ) {
                return false;
            }
            if (
                cameraFilter.casingMaterial &&
                (!camera.properties.casingMaterial ||
                    camera.properties.casingMaterial.indexOf('Stainless steel') === -1)
            ) {
                return false;
            }
            if (cameraFilter.imageStabilization && !camera.properties.imageStabilization) {
                return false;
            }
            if (cameraFilter.HDMIOutput && !camera.properties.HDMIOutput) {
                return false;
            }
            if (!meetsSustainabilityRequirements(cameraFilter, camera)) {
                return false;
            }
            if (
                cameraFilter.WDRTechnology.active &&
                !CameraFilter.hasValidWDR(camera, cameraFilter.WDRTechnology.option)
            ) {
                return false;
            }
            if (
                cameraFilter.opticalZoom.active &&
                !CameraFilter.hasValidZoom(camera, cameraFilter.opticalZoom.option)
            ) {
                return false;
            }

            const maxHorizontalFovRadians = trigonometry.toRadians(
                camera.properties.maxHorizontalFOV,
            );

            const desiredFoVRadians = cameraFilter.desiredCamera.horizontalFOVRadians;

            if (
                cameraFilter.desiredCamera.isSceneFilterActive &&
                desiredFoVRadians > maxHorizontalFovRadians &&
                !hasApplicableLens(
                    camera.relations,
                    piaLenses,
                    cameraFilter.desiredCamera,
                    camera.properties.maxVideoResolutionHorizontal,
                )
            ) {
                {
                    return false;
                }
            }

            if (cameraFilter.desiredCamera.applications?.length > 0) {
                const piaRelations = camera.relations;
                const compatibleIds = piaRelations
                    .filter((relation) => relation.relationType === 'compatible')
                    .map((relation) => relation.id);

                const showCamera = cameraFilter.desiredCamera.applications.every((id) =>
                    compatibleIds.includes(id),
                );

                if (!showCamera) {
                    return false;
                }
            }

            const trueDistance = calculate.trueDistance(
                cameraFilter.desiredCamera.installationHeight,
                cameraFilter.desiredCamera.distanceToTarget,
                cameraFilter.desiredCamera.targetHeight,
            );
            if (
                cameraFilter.desiredCamera.isSceneFilterActive &&
                !isPixelDensityFulfilled(
                    camera.properties,
                    cameraFilter.desiredCamera,
                    trueDistance,
                ) &&
                !hasApplicableLens(
                    camera.relations,
                    piaLenses,
                    cameraFilter.desiredCamera,
                    camera.properties.maxVideoResolutionHorizontal,
                )
            ) {
                return false;
            }
            /**
             ** Filter out cameras that are missing temperature values or
             ** have higher min temp than filter requirement or
             ** have lower max temp than filter requirement.
             */
            if (
                cameraFilter.operationalTemperature &&
                cameraFilter.operationalTemperature.active &&
                (camera.properties.operationalTemperatureC?.min === undefined ||
                    cameraFilter.operationalTemperature.option <
                        camera.properties.operationalTemperatureC.min ||
                    camera.properties.operationalTemperatureC.max === undefined ||
                    cameraFilter.operationalTemperature.secondaryOption >
                        camera.properties.operationalTemperatureC.max)
            ) {
                return false;
            }

            if (cameraFilter.edgeVault && !camera.properties.edgeVault) {
                return false;
            }

            if (
                !this.hasDesiredAnalyticsCompute(
                    cameraFilter.analyticsComputeCapability,
                    camera.properties.analyticsComputeCapability,
                )
            ) {
                return false;
            }

            if (cameraFilter.secureBoot && !camera.properties.secureBoot) {
                return false;
            }

            if (cameraFilter.signedFirmware && !camera.properties.signedFirmware) {
                return false;
            }

            if (cameraFilter.signedVideo && !camera.properties.signedVideo) {
                return false;
            }

            if (cameraFilter.TPM && !camera.properties.TPM) {
                return false;
            }

            if (
                cameraFilter.maxPoeClass &&
                getPoeClassNumber(camera.properties.PoEClass) >
                    getPoeClassNumber(cameraFilter.maxPoeClass)
            ) {
                return false;
            }

            if (
                cameraFilter.ipRating &&
                !camera.properties.IPRating?.includes(cameraFilter.ipRating)
            ) {
                return false;
            }

            return true;
        });

        return matchingCameras;
    }

    private static hasValidONVIFProfile(camera: IPiaSensorDevice, profiles: string[]): boolean {
        if (!camera.properties.ONVIFProfile) {
            return false;
        }
        let cameraFulfillProfiles = true;
        profiles.forEach((profile) => {
            const cameraSupportProfile = camera.properties.ONVIFProfile?.indexOf(profile) !== -1;
            if (!cameraSupportProfile) {
                cameraFulfillProfiles = false;
            }
        });
        return cameraFulfillProfiles;
    }

    private static hasDesiredAnalyticsCompute(
        desired?: AnalyticsComputeCapabilityType,
        onCamera?: AnalyticsComputeCapabilityType,
    ) {
        if (!desired) {
            return true;
        }
        return desired === onCamera;
    }

    private static hasValidWDR(camera: IPiaSensorDevice, wdr: string) {
        return wdr.split('|').find((option) => {
            return option === camera.properties.WDRTechnology;
        });
    }

    private static hasValidZoom(camera: IPiaSensorDevice, opticalZoom: string): boolean {
        if (!camera.properties.opticalZoom) {
            return false;
        }
        switch (opticalZoom) {
            case '-15x':
                return Number(camera.properties.opticalZoom) <= 15;
            case '16-24x':
                return (
                    Number(camera.properties.opticalZoom) >= 16 &&
                    Number(camera.properties.opticalZoom) <= 24
                );
            case '25x-':
                return Number(camera.properties.opticalZoom) >= 25;
            default:
                return false;
        }
    }

    /**
     *
     * Return all cameras if no selection, or only daylight is set according to Göran.
     * Daylight condition itself would only return about 70 cameras, and miss cameras with other sensors that also work in daylight.
     *
     * @param conditions Array<string> - telling us what light condition buttons are selected.
     * @param camera ICamera - Any camera being filtered.
     */
    private static hasValidLightCondition(conditions: string[], camera: IPiaSensorDevice): boolean {
        if (
            conditions.indexOf(LightConditions.LOW_LIGHT) < 0 &&
            conditions.indexOf(LightConditions.NO_LIGHT) < 0
        ) {
            return true;
        }

        const dayLight = conditions.indexOf(LightConditions.DAYLIGHT) >= 0;
        const lowLight = conditions.indexOf(LightConditions.LOW_LIGHT) >= 0;
        const noLight = conditions.indexOf(LightConditions.NO_LIGHT) >= 0;

        const hasAutoIris = Boolean(
            camera.properties.DCiris ||
                camera.properties.PIris ||
                camera.categories.indexOf(PiaItemCameraCategory.PTZ) >= 0,
        );
        const hasLowLight = Boolean(
            camera.properties.lowLight ||
                (camera.properties.lowLightTechnology &&
                    camera.properties.lowLightTechnology === 'Lightfinder'),
        );
        const hasNoLight = Boolean(camera.properties.IRLEDs && camera.properties.dayNightFunc);
        const isThermal = camera.categories.indexOf(PiaItemCameraCategory.THERMAL) >= 0;

        if (dayLight && lowLight && noLight) {
            return (hasAutoIris && hasLowLight && hasNoLight) || isThermal;
        } else if (dayLight && lowLight) {
            return hasAutoIris && hasLowLight;
        } else if (dayLight && noLight) {
            return hasNoLight || isThermal;
        } else if (lowLight && noLight) {
            return (hasLowLight && hasNoLight) || isThermal;
        } else if (lowLight) {
            return hasLowLight;
        } else if (noLight) {
            return hasNoLight || isThermal;
        } else {
            return false;
        }
    }

    private static hasDesiredCameraType(
        desiredCameraCategories: string[],
        camera: IPiaSensorDevice,
    ) {
        return (
            desiredCameraCategories.length === 0 ||
            camera.categories.some((category) => desiredCameraCategories.includes(category))
        );
    }

    private static getDesiredCameraCategories(desiredCameraTypes: CameraType[] = []) {
        const desiredCategories: string[] = [];

        if (desiredCameraTypes.includes(CameraType.PTZ)) {
            desiredCategories.push(PiaItemCameraCategory.PTZ);
        }
        if (desiredCameraTypes.includes(CameraType.Fixed)) {
            desiredCategories.push(PiaItemCameraCategory.COMPLETEMODULAR);
            desiredCategories.push(PiaItemCameraCategory.FIXED);
        }
        if (desiredCameraTypes.includes(CameraType.Dome)) {
            desiredCategories.push(PiaItemCameraCategory.FIXEDDOME);
        }
        if (desiredCameraTypes.includes(CameraType.Thermal)) {
            desiredCategories.push(PiaItemCameraCategory.THERMAL);
        }
        if (desiredCameraTypes.includes(CameraType.ExplosionProtected)) {
            desiredCategories.push(PiaItemCameraCategory.CAMERAEX);
        }
        return desiredCategories;
    }
}
