import { reducerBuilder } from 'app/store';
import { ShareActions as Actions } from './actions';
import type { IExportProjectSettingsState } from './models';

const initialState: IExportProjectSettingsState = {
    loading: false,
    projectSettingToken: null,
};

export const exportProjectSettingsReducer = reducerBuilder<IExportProjectSettingsState>()
    .setInitialState(initialState)
    .onAsyncAction(Actions.GenerateAccessCode, (handler) => {
        handler.onPending((state) => ({
            ...state,
            loading: true,
        }));

        handler.onFulfilled<void>((state) => ({
            ...state,
            loading: false,
        }));

        handler.onRejected((state) => ({
            ...state,
            loading: false,
        }));
    })
    .onAsyncAction(Actions.RevokeAccessCode, (handler) => {
        handler.onPending((state) => ({
            ...state,
            loading: true,
        }));

        handler.onFulfilled<boolean>((state) => ({
            ...state,
            loading: false,
        }));

        handler.onRejected((state) => ({
            ...state,
            loading: false,
        }));
    })
    .create();
