import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { MountingItem } from './MountingItem.container';
import { getEnvironmentMountOptions, getSelectedDeviceId } from '../../selectors';
import { ServiceLocator } from 'app/ioc';
import { AccessorySelectorService } from '../../services/AccessorySelector.service';
import { t } from 'app/translate';
import type { IRelatedPiaAccessory } from '../../models';
import { getSelectedEnvironmentMountsForItemId } from 'app/modules/common';

interface IEnvironmentMountProps {
    productIds: number[];
    environmentMountOptions: IRelatedPiaAccessory[][];
    onEnvironmentMountChanged(newProductIds: number[]): void;
}

const mapStateToProps = (storeState: IStoreState): IEnvironmentMountProps => {
    const selectedDeviceId = getSelectedDeviceId(storeState);
    if (!selectedDeviceId) {
        return {
            productIds: [],
            environmentMountOptions: new Array<IRelatedPiaAccessory[]>(),
            onEnvironmentMountChanged: () => {},
        };
    }

    const environmentMount = getSelectedEnvironmentMountsForItemId(storeState, selectedDeviceId);
    const environmentMountOptions = getEnvironmentMountOptions(storeState, selectedDeviceId);

    const service = ServiceLocator.get(AccessorySelectorService);

    return {
        productIds: environmentMount?.map((mount) => mount.id) ?? [],
        environmentMountOptions,
        onEnvironmentMountChanged: (mountOptionsPiaIds) =>
            service.setMountingOption(selectedDeviceId, mountOptionsPiaIds, 'environmentMount'),
    };
};

const EnvironmentMountContainer: React.FunctionComponent<IEnvironmentMountProps> = ({
    productIds,
    environmentMountOptions,
    onEnvironmentMountChanged,
}) => {
    return (
        <MountingItem
            label={t.mountingAccessories}
            productIds={productIds}
            mountOptions={environmentMountOptions}
            fallbackImage={require('src/assets/images/mounting/directCoupled.png')}
            onMountChanged={onEnvironmentMountChanged}
        />
    );
};

export const EnvironmentMount = connect(mapStateToProps)(EnvironmentMountContainer);
