import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { EmptyReport } from 'app/modules/reports/components';
import type { Id } from 'app/core/persistence';
import {
    ImageService,
    ProjectService,
    QuotationProgress as QuotationProgressState,
} from 'app/core/persistence';

import type { IProduct } from 'app/modules/common';
import {
    getIsLocalProject,
    getCurrentProjectLocation,
    ContactDetails,
    getCurrentProjectCurrency,
    getCurrentProjectId,
    getCurrentProjectLocale,
    getCurrentProjectLocked,
    getCurrentProjectName,
    getCurrentProjectQuotation,
    getProductsWithPrices,
} from 'app/modules/common';

import { t } from 'app/translate';
import { QuotationViewSection } from './QuotationViewSection.container';
import {
    Box,
    Button,
    Exclude,
    Heading,
    IconButton,
    Label,
    Linkable,
    PrintPanel,
    Text,
} from 'app/components';
import { QuotationSectionTotal } from '../total';
import { eventTracking } from 'app/core/tracking';
import { getUploadedLogoKey } from 'app/modules/user';
import { generateExcelFile } from 'app/utils/generateExcelFile';

interface IViewQuotationProps {
    products: IProduct[];
    customerInfo: string;
    userLogoUrlKey: string | null;
    projectId: Id;
    projectName: string;
    projectLocked: boolean;
    currency: string;
    validUntil?: string;
    footer: string;
    header: string;
    locale: string;
    locationName: string;
    isLocalProject: boolean;
}

export interface IViewQuotationState {
    logoUrl: string | undefined;
}

const mapStateToProps = (storeState: IStoreState): IViewQuotationProps => {
    const projectQuotation = getCurrentProjectQuotation(storeState);

    return {
        products: getProductsWithPrices(storeState),
        customerInfo: storeState.common.projectCustomerInfo,
        userLogoUrlKey: getUploadedLogoKey(storeState),
        projectId: getCurrentProjectId(storeState),
        projectName: getCurrentProjectName(storeState),
        projectLocked: getCurrentProjectLocked(storeState),
        currency: getCurrentProjectCurrency(storeState),
        validUntil: projectQuotation?.validUntilDate,
        footer: projectQuotation?.footer ?? '',
        header: projectQuotation?.header ?? '',
        locale: getCurrentProjectLocale(storeState),
        locationName: getCurrentProjectLocation(storeState)?.name ?? '',
        isLocalProject: getIsLocalProject(storeState),
    };
};

class QuotationViewContainer extends React.Component<IViewQuotationProps, IViewQuotationState> {
    private projectService: ProjectService;
    private imageService: ImageService;
    constructor(props: IViewQuotationProps) {
        super(props);
        this.projectService = ServiceLocator.get(ProjectService);
        this.imageService = ServiceLocator.get(ImageService);
        this.state = {
            logoUrl: '',
        };
    }

    public componentDidMount() {
        this.getLogoUrlAsBase64(this.props.userLogoUrlKey);
    }

    public render() {
        return this.props.products.length > 0 ? this.renderTable() : this.renderEmpty();
    }

    private renderTable() {
        const productsSection = (
            <>
                <QuotationViewSection sectionHeader={t.quotationAxisProducts} sectionType="axis" />
                <QuotationViewSection sectionHeader={t.quotationOther} sectionType="other" />
            </>
        );

        const editQuotationButton = (
            <Linkable link={`/project/${this.props.projectId}/reports/quotation/edit/`}>
                <IconButton icon="edit" text={t.projectReportsEditQuotationHeading} />
            </Linkable>
        );

        const exportButton = (
            <IconButton
                icon="file_download"
                text={t.quotationExportToExcel}
                onClick={this.onExportToExcel}
            />
        );

        const validUntil = new Date(this.props.validUntil ?? '');

        return (
            <>
                <PrintPanel
                    testId="panel_reports_quotation_view_panel"
                    buttons={
                        <>
                            {!this.props.projectLocked && editQuotationButton}
                            <Exclude for="iOS">{exportButton}</Exclude>
                        </>
                    }
                    printable
                    onPrint={this.onPrint}
                >
                    {this.props.userLogoUrlKey && this.state.logoUrl && (
                        // Outer box is needed since inner box padding compresses the image
                        <Box paddingBottom="cell">
                            <Box
                                color={'white'}
                                width={165}
                                height={80}
                                alignItems="center"
                                justifyContent="start"
                            >
                                <img src={this.state.logoUrl} height="100%" width="auto" />
                            </Box>
                        </Box>
                    )}
                    <Heading style="title">{this.props.projectName}</Heading>
                    <Box paddingY="half" justifyContent="between">
                        <ContactDetails hideEdit />
                        <Box width="50%">
                            <Box display="block" flex="shrinkAndGrow">
                                <Label opaque>{t.labelPreparedFor}</Label>
                                <Text whiteSpace={'pre-wrap'}>{this.props.customerInfo}</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Text whiteSpace={'pre-wrap'}>{this.props.header}</Text>
                    {productsSection}
                    <QuotationViewSection
                        sectionHeader={t.quotationServices}
                        sectionType={'installationServices'}
                    />
                    <Box direction="column" padding="half">
                        <Text whiteSpace={'pre-wrap'}>{this.props.footer}</Text>
                        <Box direction="column" alignItems="end">
                            <QuotationSectionTotal productType="products" />
                            <QuotationSectionTotal productType="services" hideIfZero={true} />
                        </Box>
                    </Box>
                    <Box paddingY="panel" justifyContent="between">
                        <Box direction="column">
                            <Text bold>
                                {t.quotationQuotationValidUntilDate(
                                    validUntil.toLocaleDateString(this.props.locale),
                                )}
                            </Text>
                        </Box>
                        <Text bold>{t.quotationExcludingTaxes(this.props.currency)}</Text>
                    </Box>
                </PrintPanel>
                <Box justifyContent="center">
                    <Box paddingY="base" justifyContent="end" maxWidth="1300px" width="100%">
                        <Button primary onClick={this.updateSalesQuoteProgress}>
                            {this.props.projectLocked ? t.quotationUnlockProject : t.lockProject}
                        </Button>
                    </Box>
                </Box>
            </>
        );
    }
    private renderEmpty() {
        return (
            <EmptyReport
                projectId={this.props.projectId}
                projectName={this.props.projectName}
                text={t.quotationNoDevices}
            />
        );
    }

    private async getLogoUrlAsBase64(uploadedLogoKey: string | null) {
        const logoImageSrc = await this.imageService.getLogoUrlAsBase64(uploadedLogoKey || '');
        this.setState({ logoUrl: logoImageSrc });
    }

    private updateSalesQuoteProgress = () => {
        const currentLock = this.props.projectLocked;

        this.projectService.updateLockStatus(this.props.projectId, !currentLock);
        this.projectService.updateCurrentProject({
            state: currentLock
                ? QuotationProgressState.Designing
                : QuotationProgressState.QuoteSent,
        });
    };

    private onPrint = () => {
        eventTracking.logUserEvent('Reports', 'Print', 'Quotation');
    };

    private onExportToExcel = () => {
        eventTracking.logUserEvent('Reports', 'Export To Excel', 'Quotation');
        const dataTitles: string[] = [
            `${t.quotationModel}`,
            `${t.quotationPartNumber}`,
            `${t.quotationExcelQuotePrice(this.props.currency)}`,
            `${t.quotationQuantity}`,
            `${t.quotationTotal}`,
        ];

        const dataToExport: string[][] = [];
        this.props.products.forEach((product: IProduct) => {
            const model = product.name || '';
            const partNumber = product.partNumber || '-';
            const quantity = product.quantity?.toString() || '-';
            const quotePrice = product.quotePrice?.toString() || '';
            const total = product.quotePrice ? product.quantity * product.quotePrice : '';
            dataToExport.push([model, partNumber, quotePrice, quantity, total.toString()]);
        });
        // Add an empty row after the products
        dataToExport.push(['', '', '', '', '', '']);
        // Add a row of country information
        dataToExport.push([
            '',
            '',
            '',
            '',
            '',
            `${t.quotationExcelCountryInfo(this.props.locationName)}`,
        ]);
        generateExcelFile(
            t.quotationExcelFileName(this.props.projectName),
            t.quotationExcelSheetTitle,
            dataTitles,
            dataToExport,
        );
    };
}

export const QuotationView = connect(mapStateToProps)(QuotationViewContainer);
