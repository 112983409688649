import { DistanceUnit, distanceUnitShortText } from 'app/core/persistence';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { convert } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectInstallationPoints,
    getCurrentProjectItems,
    getCurrentProjectUnitSystem,
    getIdFromProps,
    toCacheKey,
} from 'app/modules/common';

// itemId must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getInstallationPointIdFromProps = (
    _state: IStoreState,
    _itemId: Id,
    installationPointId: Id,
) => installationPointId;

const getInstallationHeightForItem = (item: IPersistence<IItemEntity> | undefined) => {
    return (
        item?.properties.camera?.filter.installationHeight ??
        item?.properties.sensorUnit?.filter.installationHeight ??
        item?.properties.speaker?.filter.installationHeight ??
        undefined
    );
};

/**
 * Get installation height for installation point or item.
 * If installation point id is missing, the installation height on the item filter will be returned instead.
 */
export const getInstallationHeightText = createCachedSelector(
    [
        getCurrentProjectInstallationPoints,
        getCurrentProjectUnitSystem,
        getCurrentProjectItems,
        getIdFromProps,
        getInstallationPointIdFromProps,
    ],
    (installationPoints, unitSystem, currentProjectItems, itemId, installationPointId) => {
        const height =
            installationPointId && installationPointId != 'unplaced'
                ? installationPoints[installationPointId]?.height
                : itemId
                  ? getInstallationHeightForItem(currentProjectItems[itemId])
                  : undefined;

        if (!height) return '';

        if (unitSystem === 'imperial') {
            return `${Math.round(convert.metersToFeet(height) * 100) / 100} ${distanceUnitShortText(
                DistanceUnit.Feet,
            )}`;
        }

        return `${Math.round(height * 100) / 100} ${distanceUnitShortText(DistanceUnit.Meter)}`;
    },
)(toCacheKey);
