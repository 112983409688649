import { t } from 'app/translate';
import { ServiceLocator } from 'app/ioc';
import { ModalService } from 'app/modal';
import type { CameraStationType, RecordingSolutionType } from 'app/core/persistence';
import { ProjectService } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import type { IRecordingSolution } from '../../selectors';
import { RecordingSelectorService } from '../../services';

export const selectRecommendation = async (
    selectedRecordingSolutionType: RecordingSolutionType | undefined,
    newSolution: IRecordingSolution,
    isSolutionEmpty: boolean,
    lastSelectedSolutionId: RecordingSolutionType | undefined,
    viewingAcsType: CameraStationType | undefined,
) => {
    const modalService = ServiceLocator.get(ModalService);
    const recordingService = ServiceLocator.get(RecordingSelectorService);
    const projectService = ServiceLocator.get(ProjectService);

    let confirmedDialog = false;
    if (newSolution.id === 'AxisCompanionSDCard') {
        if (await recordingService.hasExistingSDCards()) {
            confirmedDialog = await getConfirmedSDCardWarning();
            if (!confirmedDialog) {
                return;
            }
        }
    }

    const shouldGoAhead =
        confirmedDialog ||
        isSolutionEmpty ||
        (await modalService.createConfirmDialog({
            header: t.replaceRecordingSolutionHeading,
            body: t.replaceRecordingSolutionMessage,
            confirmButtonText: t.proceed,
            cancelButtonText: t.cancel,
        })());

    if (shouldGoAhead) {
        await projectService.updateCurrentProject({
            recordingSolutionType: selectedRecordingSolutionType,
        });
        recordingService.replaceSolution(newSolution, lastSelectedSolutionId, viewingAcsType);
        eventTracking.logUserEvent('System Components', 'Select recommendation', newSolution.id);
    }
};

const getConfirmedSDCardWarning = () => {
    const modalService = ServiceLocator.get(ModalService);

    return modalService.createConfirmDialog({
        header: t.replaceSDCardAccessoriesHeading,
        body: t.replaceSDCardAccessoriesMessage,
        confirmButtonText: t.proceed,
        cancelButtonText: t.cancel,
    })();
};
