import type { CameraStationType, CameraStationCenterType } from 'app/core/persistence';
import { toErrorMessage } from 'app/core/persistence';
import type { IPiaSystemComponent } from 'app/core/pia';
import { eventTracking } from 'app/core/tracking';
import type { IDeviceRequirement, IComponentWithQuantity, ISystemConfiguration } from '../common';
import {
    nTimes,
    getRecorderProps,
    getDiskProps,
    NoSolutionError,
    RECOMMENDATION_VALIDATION_MAX_DEVICES,
} from '../common';
import { recommendRecorders } from './greedyRecommendation';
import { preprocessRecorders } from './preprocessing';
import { bySortingPriority } from '../comparators';
import { CENTER_LICENSE_PIAID_FIVE_YEAR, CENTER_LICENSE_PIAID_ONE_YEAR } from '../../constants';

export const AXIS_CAMERA_STATION_PRO = 94521; // PiaId for AXIS Camera Station Pro
export const AXIS_CAMERA_STATION_5 = 34074; // PiaId for AXIS Camera Station 5;

/**
 * Get the optimal external disk configuration. Note that AXIS discourages
 * the mixing of disk sizes, which simplifies this function quite a lot
 *
 * @param recorder - the recorder
 * @param devices - the partitioned devices
 * @param disks - the available disks to choose from
 * @return The optimal disk configuration
 */
export const optimizeExtraStorage = (
    recorder: IPiaSystemComponent,
    devices: IDeviceRequirement[],
    disks: IPiaSystemComponent[],
    quantities: Map<IPiaSystemComponent, number>,
): IPiaSystemComponent[] => {
    const recorderProps = getRecorderProps(recorder);
    const availableStorage = recorderProps.maxRecordingStorageMegaBytes;
    const requiredStorage = devices.reduce((acc, device) => acc + device.storage, 0);
    const diff = requiredStorage - availableStorage;

    if (diff <= 0) {
        return [];
    }

    const diskConfiguration = disks
        .map((disk) => ({
            disk,
            numberRequired: diff / getDiskProps(disk).maxRecordingStorageMegaBytes,
        }))
        .filter(
            ({ disk, numberRequired }) =>
                numberRequired <= Math.min(recorderProps.freeHddBays, quantities.get(disk) ?? 0),
        )
        .sort((a, b) => b.numberRequired - a.numberRequired)[0];

    if (!diskConfiguration) {
        throw new Error('Could not satisfy storage requirement');
    }

    return nTimes(diskConfiguration.disk, diskConfiguration.numberRequired);
};

export const getRequiredExtraLicenses = (
    recorder: IPiaSystemComponent,
    devices: IDeviceRequirement[],
): number => {
    const recorderProps = getRecorderProps(recorder);
    const availableLicenses = recorderProps.vmsChannelLicenses;
    const requiredLicenses = devices.reduce((acc, device) => acc + device.licenseCount, 0);
    const diff = requiredLicenses - availableLicenses;

    return Math.max(0, diff);
};

const hasVideoPorts = (recommendation: ISystemConfiguration): boolean =>
    recommendation.recorders.some(
        ({ recorder }) => (recorder.properties.monitorsSupported ?? 0) > 0,
    );
/**
 * Generate a recording solution recommendation graph
 *
 * @param devices - the device requirements
 * @param componentsWithQuantity - the available components (recorders, switches, disks, licenses) and their quantities
 * @return
 *   a recording solution recommendation that fulfills the device requirements
 *   or null if there is no solution
 */
export const getRecommendationGraph = (
    devices: IDeviceRequirement[],
    components: IPiaSystemComponent[],
    selectedAcsType: CameraStationType,
    selectedCenterType: CameraStationCenterType,
    maxRecorders = Number.MAX_VALUE,
): ISystemConfiguration | null => {
    if (devices.length > RECOMMENDATION_VALIDATION_MAX_DEVICES) {
        return null;
    }
    const desktopTerminals = components
        .filter(({ category }) => category === 'desktopterminals')
        .sort(bySortingPriority);
    const desktopTerminal = desktopTerminals[0];

    const recommendation = getRecommendation(
        devices,
        components,
        maxRecorders,
        selectedAcsType,
        selectedCenterType,
    );

    if (recommendation?.recorders.length && desktopTerminal && !hasVideoPorts(recommendation)) {
        recommendation.desktopTerminal = desktopTerminal;
    }

    return recommendation;
};
/**
 * Generate a recording solution recommendation
 *
 * @param devices - the device requirements
 * @param components - the available components (recorders, switches, disks, licenses)
 * @return
 *   a recording solution recommendation that fulfills the device requirements
 *   or null if there is no solution
 */
export const getRecommendation = (
    devices: IDeviceRequirement[],
    components: IPiaSystemComponent[],
    maxRecorders: number,
    selectedAcsType: CameraStationType = 'CameraStation5',
    axisCenterType: CameraStationCenterType = 'NoCenter',
): ISystemConfiguration | null => {
    const componentsWithQuantity = components.map((component) => ({
        component,
        quantity: Number.MAX_VALUE,
    }));
    return constructSolutionGraph(
        devices,
        componentsWithQuantity,
        maxRecorders,
        selectedAcsType,
        axisCenterType,
    );
};

/**
 * Construct a solution graph
 *
 * @param devices - the device requirements
 * @param components - the available components with quantities
 * @return
 *   a recording solution graph that fulfills the device requirements
 *   or null if there is no solution
 */
export const constructSolutionGraph = (
    devices: IDeviceRequirement[],
    componentsWithQuantity: IComponentWithQuantity[],
    maxRecorders: number,
    selectedAcsType: CameraStationType,
    selectedCenterType: CameraStationCenterType,
): ISystemConfiguration | null => {
    const components = componentsWithQuantity.map(({ component }) => component);
    const recorders = components.filter(({ category }) => category === 'recorders2');
    const switches = components.filter(({ category }) => category === 'networkswitches');
    const storage = components.filter(({ category }) => category === 'storage');
    // If not CameraStation5 we should choose a pro-license instead of a camera station 5 license
    const licenses = components
        .filter(({ category }) => category === 'vms')
        .filter((license) =>
            selectedAcsType === 'CameraStationPro'
                ? license.parentId === AXIS_CAMERA_STATION_PRO
                : license.parentId === AXIS_CAMERA_STATION_5,
        )
        .sort(bySortingPriority);
    const license = licenses[0]; // sorted by sortingPriority
    let centerLicense = null;
    if (selectedCenterType === 'Center1year' && selectedAcsType === 'CameraStationPro') {
        centerLicense = components.find((lic) => lic.id === CENTER_LICENSE_PIAID_ONE_YEAR) ?? null;
    } else if (selectedCenterType === 'Center5years' && selectedAcsType === 'CameraStationPro') {
        centerLicense = components.find((lic) => lic.id === CENTER_LICENSE_PIAID_FIVE_YEAR) ?? null;
    }

    if (recorders.length === 0) {
        //we will never be able to satisfy project requirements with 0 recorders
        return null;
    }

    try {
        return recommendSolution(
            preprocessRecorders(recorders, devices),
            switches,
            storage,
            license,
            devices,
            maxRecorders,
            centerLicense,
        );
    } catch (e) {
        if (!(e instanceof NoSolutionError)) {
            // Something went wrong. We log the error and return null, signalling that
            // no solution could be found but we don't crash the entire app
            eventTracking.logError(
                `Recommendation error: ${toErrorMessage(e)}`,
                'recommendationEngine',
            );
            console.error(`recommendationEngine - Recommendation error: ${toErrorMessage(e)}`);
        }

        return null;
    }
};

const recommendSolution = (
    availableRecorders: IPiaSystemComponent[],
    availableSwitches: IPiaSystemComponent[],
    availableStorage: IPiaSystemComponent[],
    availableLicense: IPiaSystemComponent | null,
    devices: IDeviceRequirement[],
    maxRecorders: number,
    coreLicense: IPiaSystemComponent | null,
): ISystemConfiguration => {
    const solution = recommendRecorders(
        availableRecorders,
        availableSwitches,
        availableStorage,
        availableLicense,
        devices,
        maxRecorders,
        coreLicense,
    );

    return solution;
};
