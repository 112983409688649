import { PiaItemState, PiaRelationService } from 'app/core/pia';
import { isDefined } from 'axis-webtools-util';
import { ServiceLocator } from 'app/ioc';
import { createCachedSelector } from 're-reselect';
import { getAvailablePiaDevicesRecord, getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItem } from '../../project';
import { getPiaIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';

const isDiscontinued = (state: PiaItemState) => state > PiaItemState.EXTERNALLY_ANNOUNCED;

export const getReplacements = createCachedSelector(
    [getAvailablePiaDevicesRecord, getPiaItemsRecord, getCurrentProjectItem],
    (availablePiaDevices, piaItemsRecord, item) => {
        if (!item) {
            return [];
        }
        const piaRelationService = ServiceLocator.get(PiaRelationService);

        const product = item.productId ? piaItemsRecord[item.productId] : undefined;
        const discontinued = product ? isDiscontinued(product.state) : false;

        if (discontinued && product) {
            const replacements = piaRelationService.searchReplacements(product.id);
            const mapped = replacements.map((piaId) => availablePiaDevices[piaId]);
            return mapped.filter(isDefined);
        }
        return [];
    },
)(toCacheKey);

export const getReplacementsForPiaId = createCachedSelector(
    [getPiaItemsRecord, getPiaIdFromProps],
    (piaItemsRecord, piaId) => {
        if (!piaId) {
            return [];
        }
        const piaRelationService = ServiceLocator.get(PiaRelationService);

        const replacements = piaRelationService.searchReplacements(piaId);
        const mapped = replacements.map((productId) => piaItemsRecord[productId]);
        return mapped.filter(isDefined);
    },
)(toCacheKey);
