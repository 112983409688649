import { createSelector } from 'reselect';
import { groupBy } from 'lodash-es';
import { isDefined } from 'axis-webtools-util';
import { PiaAccessoryCategory, PiaItemRecorderCategory } from 'app/core/pia';
import type { IItemWithCategoryAndName } from 'app/utils';
import { recorderCategoryComparator } from 'app/utils';
import { getParentId, deviceTypeCheckers } from 'app/core/persistence';
import type { IItemEntity, RecordingSolutionType } from 'app/core/persistence';
import { getCurrentProject, getCurrentProjectItemsArray } from '../project/selectors';
import { getPiaItemsRecord } from '../piaDevices/selectors/getPiaDevices';
import { PartnerSystemItemCategory } from 'app/core/partner';

const isCompanionRelevantSDCardHolder = (item: IItemEntity) =>
    deviceTypeCheckers.isCamera(item) ||
    deviceTypeCheckers.isEncoder(item) ||
    deviceTypeCheckers.isMainUnit(item) ||
    deviceTypeCheckers.isDoorStation(item);
/**
 * Get and aggregate all SDCards in project
 */
export const getCurrentProjectSDCards = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItems) => {
        const getParent = (item: IItemEntity) => items.find(({ _id }) => _id === getParentId(item));

        const accessoryItems = items
            .filter((item) => !!item.properties.accessory && item.productId)
            .filter(
                ({ productId }) =>
                    productId && piaItems[productId]?.category === PiaAccessoryCategory.STORAGE,
            )
            .filter((item) => {
                const parent = getParent(item);
                return !parent || isCompanionRelevantSDCardHolder(parent);
            })
            .map((item) => {
                const parent = getParent(item);

                return {
                    ...item,
                    quantity: item.quantity * (parent?.quantity ?? 1),
                };
            });

        const aggregatedItems = Object.values(groupBy(accessoryItems, 'productId')).map((group) =>
            group.slice(1).reduce(
                (acc, it) => {
                    acc.quantity += it.quantity;

                    return acc;
                },
                { ...group[0] },
            ),
        );

        return aggregatedItems;
    },
);

/**
 * Get the system components in the current project
 */
export const getCurrentProjectSystemComponentsIncludingPartner = createSelector(
    [getCurrentProjectItemsArray],
    (items) =>
        items.filter(
            (item) =>
                (!!item.properties.systemComponent && item.productId) ||
                !!item.properties.partnerSystemComponent,
        ),
);

/**
 * Get the system components in the current project
 */
const getInferredRecordingSolutionType = createSelector(
    [
        getCurrentProjectSystemComponentsIncludingPartner,
        getCurrentProjectSDCards,
        getPiaItemsRecord,
    ],
    (systemComponents, sdCards, piaItems): RecordingSolutionType => {
        const piaComponents = systemComponents
            .map((item) => (item.productId === null ? undefined : piaItems[item.productId]))
            .filter(isDefined);
        const recorders = piaComponents.filter(
            (piaItem) => piaItem?.category === PiaItemRecorderCategory.RECORDERS2,
        );

        const switches = piaComponents.filter(
            (piaItem) => piaItem?.category === PiaAccessoryCategory.NETWORKSWITCHES,
        );

        if (recorders.length > 0) {
            switch (recorders[0].properties.series) {
                case 'S30':
                    return 'AxisCompanionS30';
                case 'S21':
                case 'S22':
                    return 'AxisS2X';
                case 'S11':
                case 'S12':
                    return 'AxisS1X';
            }
            switch (recorders[0].properties.vendor) {
                case 'Genetec':
                    return 'Genetec';
                case 'Milestone':
                    return 'Milestone';
            }
        } else if (sdCards.length > 0 && switches.length > 0) {
            return 'AxisCompanionSDCard';
        }

        // default to S1X.
        return 'AxisS1X';
    },
);

export const getRecordingSolutionTypeFromProject = createSelector(
    [getCurrentProject],
    (project) => project?.recordingSolutionType,
);

export const getSelectedRecordingSolutionType = createSelector(
    [getRecordingSolutionTypeFromProject, getInferredRecordingSolutionType],
    (typeFromProject, inferredType) => typeFromProject || inferredType,
);

export const getIsMilestoneRecordingSolutionType = createSelector(
    [getSelectedRecordingSolutionType],
    (selectedSolutionType) => selectedSolutionType === 'Milestone',
);

export const getIsThirdPartySolution = createSelector(
    [getSelectedRecordingSolutionType],
    (recordingSolutionType) =>
        recordingSolutionType === 'Genetec' || recordingSolutionType === 'Milestone',
);

export const getIsCompanionSolutionSelected = createSelector(
    [getSelectedRecordingSolutionType],
    (recordingSolutionType) =>
        recordingSolutionType === 'AxisCompanionSDCard' ||
        recordingSolutionType === 'AxisCompanionS30',
);

/**
 * Get the recording solution in the current project
 */
export const getCurrentProjectRecordingSolutionItems = createSelector(
    [
        getCurrentProjectSystemComponentsIncludingPartner,
        getCurrentProjectSDCards,
        getPiaItemsRecord,
        getSelectedRecordingSolutionType,
    ],
    (systemComponents, sdCards, piaItems, selectedRecordingSolutionType) => {
        const allItems = [...systemComponents];

        const hasRecorder = systemComponents.some((item) => {
            const piaItem = item.productId === null ? undefined : piaItems[item.productId];
            const partnerSystemItem = item.properties.partnerSystemComponent;
            return (
                piaItem?.category === PiaItemRecorderCategory.RECORDERS2 ||
                partnerSystemItem?.category === PartnerSystemItemCategory.SERVER
            );
        });

        if (
            selectedRecordingSolutionType === 'AxisCompanionSDCard' ||
            (selectedRecordingSolutionType === undefined && !hasRecorder)
        ) {
            allItems.push(...sdCards);
        }
        return allItems;
    },
);

/**
 * Get the persisted recording solution in current project sorted by recording categories (servers, terminals, switches, licenses)
 */
export const getCurrentSolutionSortedByRecorderCategory = createSelector(
    [getCurrentProjectRecordingSolutionItems, getPiaItemsRecord],
    (items, piaItems) => {
        return [...items].sort((a, b) => {
            const itemA: IItemWithCategoryAndName = a.productId
                ? piaItems[a.productId]
                : { name: a.name, category: a.properties.partnerSystemComponent?.category ?? '' };
            const itemB: IItemWithCategoryAndName = b.productId
                ? piaItems[b.productId]
                : { name: b.name, category: b.properties.partnerSystemComponent?.category ?? '' };
            return recorderCategoryComparator(itemA, itemB);
        });
    },
);

/**
 * Returns true if selected recording solution includes Axis recorder (nvr)
 * If the user has selected AxisCompanionS30, we return false, since we do not want to show NVR licenses
 * for Companion (Edge) solutions.
 */
export const getCurrentSolutionIncludesAxisRecorder = createSelector(
    [
        getCurrentProjectSystemComponentsIncludingPartner,
        getPiaItemsRecord,
        getSelectedRecordingSolutionType,
    ],
    (systemComponents, piaItems, selectedSolutionType) => {
        const hasRecorder = systemComponents.some((item) => {
            const piaItem = item.productId === null ? undefined : piaItems[item.productId];
            const partnerSystemItem = item.properties.partnerSystemComponent;
            return (
                piaItem?.category === PiaItemRecorderCategory.RECORDERS2 ||
                partnerSystemItem?.category === PartnerSystemItemCategory.SERVER
            );
        });
        // Ignore AxisCompanionS30, since we do not want to show NVR licenses for Companion (Edge) solutions.
        return selectedSolutionType !== 'AxisCompanionS30' && hasRecorder;
    },
);
