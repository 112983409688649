import { isDefined } from 'axis-webtools-util';
import {
    getPiaItems,
    getCurrentProjectRegions,
    getPiaItemsRecord,
    getUseProductAllowlist,
    getProductAllowlist,
    createProductAllowlistFilter,
    getCurrentProjectItems,
    getCurrentProjectItemsArray,
    getCurrentProjectLocked,
    getCurrentProjectRecordingSolutionItems,
    getCurrentAxisRecordingItems,
    getIdFromProps,
} from 'app/modules/common';
import { createSelector } from 'reselect';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { filterProducts, PiaItemState, PiaAccessoryCategory } from 'app/core/pia';
import type { Id, IItemEntity, IItemRelationEntity, IPersistence } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { mapToDisplayCategory, DisplayCategory } from '../components/accessoryUtils';
import { getCurrentProjectRelationsRecord } from 'app/modules/common/relations';
import { nameComparator } from 'app/utils';

const ACCESSORY_CATEGORIES: string[] = [
    // Connectivity
    PiaAccessoryCategory.CABLESCON,
    PiaAccessoryCategory.ETHERNETOVERCOAX,
    PiaAccessoryCategory.MEDIACONVERTERS,
    PiaAccessoryCategory.SURGEPROTECTORS,
    PiaAccessoryCategory.POEEXTENDERS,
    // Power
    PiaAccessoryCategory.POWERSUPPLIES,
    // Control board and joystick
    PiaAccessoryCategory.JOYSTICKSCONTR,
    // Storage
    PiaAccessoryCategory.STORAGE,
    // Housings and cabinets
    PiaAccessoryCategory.COVERSDOMES,
    PiaAccessoryCategory.CABINETS,
    PiaAccessoryCategory.HOUSINGS,
    // Mounts
    PiaAccessoryCategory.MOUNTS,
    // Audio and I/O
    PiaAccessoryCategory.AUDIOANDIO,
];

interface IHasSelectedOrAvailableAccessories {
    hasSelectedAccessories: boolean;
    hasAvailableAccessories: boolean;
}

const getSelectedDevice = (storeState: IStoreState) =>
    storeState.recordingSelector.selectedDeviceDetails;

// parentId must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getAccessoryPiaId = (_state: IStoreState, _parentId: Id | undefined, accessoryPiaId: PiaId) =>
    accessoryPiaId;

const getRecordingAccessories = createSelector([getPiaItems], (piaItems) =>
    piaItems.filter(filterProducts.byCategories(ACCESSORY_CATEGORIES)),
);

export const getSelectedAccessories = createSelector([getCurrentProjectItemsArray], (items) =>
    items.filter((item) => !!item.properties.accessory && item.productId),
);

const getSelectedDeviceDetails = (store: IStoreState) =>
    store.recordingSelector.selectedDeviceDetails;

export const getSelectedRecordingId = (store: IStoreState) =>
    store.recordingSelector.ItemIdForNetworkSettings;

export const getSelectedRecordingProduct = createSelector(
    [getSelectedDeviceDetails, getCurrentProjectItems, getPiaItemsRecord],
    (selectedDevice, projectItems, piaItems) => {
        if (!selectedDevice) {
            return undefined;
        }
        const selectedItem = projectItems[selectedDevice];
        return selectedItem?.productId ? piaItems[selectedItem.productId] : undefined;
    },
);

/**
 * Get all currently available accessories.
 * Filters on allowlist, region and discontinued state.
 */
export const getAvailableRecordingAccessories = createSelector(
    [
        getRecordingAccessories,
        getCurrentProjectRegions,
        getUseProductAllowlist,
        getProductAllowlist,
    ],
    (piaAccessories, regions, useAllowlist, allowlist) => {
        const availablePiaAccessories = piaAccessories
            .filter(filterProducts.byRegions(regions))
            .filter(filterProducts.byState(PiaItemState.EXTERNALLY_ANNOUNCED))
            .filter((product) => createProductAllowlistFilter(allowlist, useAllowlist)(product.id));
        return availablePiaAccessories;
    },
);

export interface IPiaItemRecordingAccessory extends IPiaItem {
    recommended: boolean;
}

export const getSelectedRecordingAccessories = createSelector(
    [
        getCurrentProjectRecordingSolutionItems,
        getCurrentProjectRelationsRecord,
        getCurrentProjectItems,
    ],
    (recordingItems, relations, currentProjectItems) =>
        recordingItems.reduce((recordingAccessories, item) => {
            if (item.productId) {
                const relationsForRecordingItem: IItemRelationEntity[] | undefined =
                    relations[item._id];
                if (relationsForRecordingItem) {
                    const accessoryPiaItems = relationsForRecordingItem
                        .map((relation) => currentProjectItems[relation.childId])
                        .filter(isDefined);
                    recordingAccessories = [...recordingAccessories, ...accessoryPiaItems];
                }
            }

            return recordingAccessories;
        }, new Array<IPersistence<IItemEntity>>()),
);

/**
 * Gets all available recording accessories, including discontinued products that have been
 * added to the project.
 */
export const getAvailableRecordingAccessoriesRecord = createSelector(
    [
        getAvailableRecordingAccessories,
        getCurrentAxisRecordingItems,
        getPiaItemsRecord,
        getCurrentProjectRelationsRecord,
        getCurrentProjectItems,
        getCurrentProjectLocked,
    ],
    (accessories, recordingItems, piaItems, relations, projectItems, isLocked) => {
        const availableAccessoriesRecord = recordingItems.reduce(
            (record, item) => {
                const piaItem = piaItems[item.productId!];
                const accessoryRelations = piaItem.relations.filter(
                    (relation) =>
                        relation.relationType === 'compatible' ||
                        relation.relationType === 'recommends',
                );
                const accessoryRelationIds = accessoryRelations.map((relation) => relation.id);
                const availableAccessories = isLocked
                    ? []
                    : accessories
                          .filter((accessory) => accessoryRelationIds.includes(accessory.id))
                          .map((accessory) => {
                              const recommended = accessoryRelations.some(
                                  (relation) =>
                                      relation.id === accessory.id &&
                                      relation.relationType === 'recommends',
                              );
                              return { ...accessory, recommended };
                          });

                const discontinuedSelectedAccessories = (relations[item._id] ?? [])
                    .map((accessory) => projectItems[accessory.childId])
                    .filter(isDefined)
                    .filter(
                        (projectItem) =>
                            !availableAccessories.find(
                                (accessoryItem) => accessoryItem.id === projectItem.productId,
                            ),
                    )
                    .map((projectItem) => {
                        return { ...piaItems[projectItem.productId!], recommended: false };
                    });

                const allAccessories = [
                    ...availableAccessories,
                    ...discontinuedSelectedAccessories,
                ];
                record[item._id] = allAccessories.sort(nameComparator);
                return record;
            },
            {} as Record<Id, IPiaItemRecordingAccessory[]>,
        );

        return availableAccessoriesRecord;
    },
);

export const getIsRecommended = createSelector(
    [getAvailableRecordingAccessoriesRecord, getIdFromProps, getAccessoryPiaId],
    (availableAccessories, parentId, accessoryPiaId) => {
        const isRecommended =
            parentId &&
            availableAccessories[parentId].some(
                (accessory) => accessory.id === accessoryPiaId && accessory.recommended,
            );
        return Boolean(isRecommended);
    },
);

/**
 * Returns a record with ItemId as key and information if the item has available accessories and selected accessories.
 */
export const getHasSelectedAndAvailableAccessories = createSelector(
    [
        getCurrentProjectRecordingSolutionItems,
        getCurrentProjectRelationsRecord,
        getAvailableRecordingAccessories,
        getPiaItemsRecord,
    ],
    (recordingItems, relations, recordingAccessories, piaItems) =>
        recordingItems.reduce((obj: Record<Id, IHasSelectedOrAvailableAccessories>, item) => {
            if (item.productId) {
                const piaItem = piaItems[item.productId];
                const hasAvailableAccessories = piaItem.relations
                    .filter(
                        (relation) =>
                            relation.relationType === 'compatible' ||
                            relation.relationType === 'recommends',
                    )
                    .some((relation) => recordingAccessories.some((acc) => acc.id === relation.id));
                const hasSelectedAccessories = relations[item._id] !== undefined;
                obj[item._id] = {
                    hasAvailableAccessories,
                    hasSelectedAccessories,
                };
            } else {
                obj[item._id] = {
                    hasAvailableAccessories: false,
                    hasSelectedAccessories: false,
                };
            }

            return obj;
        }, {}),
);

export const getQuantityForRecordingAccessories = createSelector(
    [getSelectedAccessories, (_state: IStoreState, parentId: string | undefined) => parentId],
    (selectedAccessories, parentId) =>
        selectedAccessories.reduce(
            (quantityRecord, item) => {
                if (parentId && item.productId && item.path.includes(parentId)) {
                    quantityRecord[item.productId] = item.quantity;
                }
                return quantityRecord;
            },
            {} as Record<PiaId, number>,
        ),
);

export const getRecordingAccessoriesByCategory = createSelector(
    [getSelectedDevice, getAvailableRecordingAccessoriesRecord],
    (selectedDevice, availableAccessories) => {
        const recordingAccessories = selectedDevice ? availableAccessories[selectedDevice] : [];
        const recordingAccessoriesByCategory = recordingAccessories.reduce(
            (accessoriesByCategory, item) => {
                const category = mapToDisplayCategory[item.category] ?? DisplayCategory.Other;
                const itemsInCategory = accessoriesByCategory[category]
                    ? [...accessoriesByCategory[category], item]
                    : [item];
                accessoriesByCategory[category] = itemsInCategory;
                return accessoriesByCategory;
            },
            {} as Record<string, IPiaItemRecordingAccessory[]>,
        );

        return recordingAccessoriesByCategory;
    },
);
