import { toCacheKey } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';

const getInstallationReportSchedules = (storeState: IStoreState) =>
    storeState.installationReport.schedules;

const getScheduleIdFromProps = (_state: IStoreState, scheduleId: string | undefined) => scheduleId;

export const getScheduleForId = createCachedSelector(
    [getInstallationReportSchedules, getScheduleIdFromProps],
    (schedules, scheduleIdFromProps) => {
        return schedules.find((schedule) => schedule.id === scheduleIdFromProps);
    },
)(toCacheKey);
