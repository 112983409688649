import type { DeviceAndSubType, DeviceType } from 'app/core/persistence';
import type { PiaCategory } from 'app/core/pia';
import {
    getAllCameraCategories,
    PiaItemEncoderCategory,
    PiaItemMainUnitCategory,
    PiaItemSpeakerCategory,
    PiaItemDecoderCategory,
    PiaItemPacCategory,
    PiaItemDetectorCategory,
    PiaItemPeopleCounterCategory,
    PiaItemWearablesCategory,
    PiaItemAlerterCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from 'app/core/pia';

export const getDeviceTypeFromPiaCategory = (category: PiaCategory): DeviceType | undefined => {
    if (getAllCameraCategories().includes(category)) {
        return 'camera';
    }

    switch (category) {
        case PiaItemEncoderCategory.ENCODER:
            return 'encoder';
        case PiaItemMainUnitCategory.MAINUNIT:
            return 'mainUnit';
        case PiaItemSpeakerCategory.SPEAKER:
            return 'speaker';
        case PiaItemDecoderCategory.DECODER:
            return 'decoder';
        case PiaItemPacCategory.ACCESSSERVER:
        case PiaItemPacCategory.ANSWERINGUNIT:
        case PiaItemPacCategory.IORELAYS:
        case PiaItemPacCategory.NETWORKREADER:
            return 'pac';
        case PiaItemPacCategory.DOORCONTROLLERS:
            return 'doorcontroller';
        case PiaItemPacCategory.DOORSTATIONS:
            return 'doorstation';
        case PiaItemDetectorCategory.RADARDETECTORS:
            return 'radardetector';
        case PiaItemPeopleCounterCategory.PEOPLECOUNTERS:
            return 'peopleCounter';
        case PiaItemWearablesCategory.CAMERAS:
            return 'bodyWornCamera';
        case PiaItemWearablesCategory.CAMERAEXTENSIONS:
            return 'cameraExtension';
        case PiaItemWearablesCategory.CONTROLLER:
            return 'systemController';
        case PiaItemWearablesCategory.DOCKING:
            return 'dockingStation';
        case PiaItemAlerterCategory.ALERTERS:
            return 'alerter';
        case PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES:
            return 'connectivitydevice';
        case PiaItemPagingConsoleCategory.PAGINGCONSOLE:
            return 'pagingconsole';
        default:
            return undefined;
    }
};

export const getDeviceAndSubTypeFromPiaCategory = (
    category: PiaCategory,
): DeviceAndSubType | undefined => {
    if (getAllCameraCategories().includes(category)) {
        return 'camera';
    }

    switch (category) {
        case PiaItemEncoderCategory.ENCODER:
            return 'encoder';
        case PiaItemMainUnitCategory.MAINUNIT:
            return 'mainUnit';
        case PiaItemSpeakerCategory.SPEAKER:
            return 'speaker';
        case PiaItemDecoderCategory.DECODER:
            return 'decoder';
        case PiaItemPacCategory.ACCESSSERVER:
            return 'accessServer';
        case PiaItemPacCategory.ANSWERINGUNIT:
            return 'answeringUnit';
        case PiaItemPacCategory.IORELAYS:
            return 'iorelay';
        case PiaItemPacCategory.NETWORKREADER:
            return 'networkReader';
        case PiaItemPacCategory.DOORCONTROLLERS:
            return 'doorcontroller';
        case PiaItemPacCategory.DOORSTATIONS:
            return 'doorstation';
        case PiaItemDetectorCategory.RADARDETECTORS:
            return 'radardetector';
        case PiaItemPeopleCounterCategory.PEOPLECOUNTERS:
            return 'peopleCounter';
        case PiaItemWearablesCategory.CAMERAS:
            return 'bodyWornCamera';
        case PiaItemWearablesCategory.CAMERAEXTENSIONS:
            return 'cameraExtension';
        case PiaItemWearablesCategory.CONTROLLER:
            return 'systemController';
        case PiaItemWearablesCategory.DOCKING:
            return 'dockingStation';
        case PiaItemAlerterCategory.ALERTERS:
            return 'alerter';
        case PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES:
            return 'connectivitydevice';
        default:
            return undefined;
    }
};
