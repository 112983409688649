import * as React from 'react';
import type { DeviceType } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import type { Colors } from 'app/styles';
import { useSelector } from 'react-redux';
import { getSelectedColorLayers, getSelectedDeviceTypesLayer } from '../selectors';

export const useIsLayerHidden = (
    deviceType: DeviceType | undefined,
    deviceColor?: Colors,
): boolean => {
    const selectedDeviceTypes = useSelector<IStoreState, DeviceType[]>(getSelectedDeviceTypesLayer);
    const selectedDeviceColors = useSelector<IStoreState, string[]>(getSelectedColorLayers);

    const isLayerHidden = React.useMemo(() => {
        const isTypeHidden = !selectedDeviceTypes.some((type) => type === deviceType);
        const isColorHidden =
            !!deviceColor && !selectedDeviceColors.some((color) => color === deviceColor);
        return isTypeHidden || isColorHidden;
    }, [deviceColor, deviceType, selectedDeviceColors, selectedDeviceTypes]);

    return isLayerHidden;
};
