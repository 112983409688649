import { createSelector } from 'reselect';
import type { IRecordingItemStats } from './getRecordingSolutionSpecs';
import {
    getRequiredLicenses,
    getRequiredChannels,
    getRequiredPorts,
    getRequiredPower,
    getRecordingBandwidthUsed,
    getStorageUsed,
} from 'app/modules/common';
import { getIsSolutionWithoutRecorder } from './getRecordingSolution';

const getRequirementsForRecorderSolution = createSelector(
    [
        getStorageUsed,
        getRecordingBandwidthUsed,
        getRequiredLicenses,
        getRequiredChannels,
        getRequiredPorts,
        getRequiredPower,
    ],
    (storage, bandwidth, nrOfLicenses, nrOfChannels, ports, power): IRecordingItemStats => ({
        storage,
        bandwidth,
        nrOfChannels,
        nrOfLicenses,
        ports,
        power,
        video: 0,
        additionalStorage: 0,
    }),
);

const getRequirementsForRecorderLessSolution = createSelector(
    [getStorageUsed, getRequiredPorts, getRequiredPower],
    (storage, ports, power): IRecordingItemStats => ({
        storage,
        bandwidth: 0,
        nrOfChannels: 0,
        nrOfLicenses: 0,
        ports,
        power,
        video: 0,
        additionalStorage: 0,
    }),
);

/**
 * Get the total system requirements for this project
 */
export const getRecordingSolutionRequirements = createSelector(
    [
        getIsSolutionWithoutRecorder,
        getRequirementsForRecorderSolution,
        getRequirementsForRecorderLessSolution,
    ],
    (noRecorder, requirementsForRecorderSolution, requirementsForRecorderLessSolution) => {
        return noRecorder ? requirementsForRecorderLessSolution : requirementsForRecorderSolution;
    },
);
