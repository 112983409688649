import { createCachedSelector } from 're-reselect';
import type { IPersistence, IProfileEntity, IScenarioSettingsModel } from 'app/core/persistence';
import { LightCondition, Time24 } from 'app/core/persistence';
import { getProfile } from './getProfiles';
import { toCacheKey } from '../../cacheKey';
import { getAssociatedProfile } from '../../item/selectors/getAssociatedProfile';
import { getProfileOverride } from '../../item/selectors/getProfileOverride';

export const getDeviceScenarioSettings = createCachedSelector(
    [getAssociatedProfile],
    (profile): IScenarioSettingsModel => getDefaultScenarioSettings(profile),
)(toCacheKey);

export const getProfileScenarioSettings = createCachedSelector(
    [getProfile],
    (profile): IScenarioSettingsModel => getDefaultScenarioSettings(profile),
)(toCacheKey);

export const getScenarioSettingsOverride = createCachedSelector(
    [getProfileOverride],
    (profileOverride): Partial<IScenarioSettingsModel> | undefined => {
        if (!profileOverride) {
            return undefined;
        }

        return {
            scenarioId: profileOverride.scenario.scenarioId,
            lightStart: profileOverride.scenario.lightStart
                ? new Time24(profileOverride.scenario.lightStart)
                : undefined,
            lightEnd: profileOverride.scenario.lightEnd
                ? new Time24(profileOverride.scenario.lightEnd)
                : undefined,
            nightLighting: profileOverride?.scenario.nightLighting,
            sceneDetails: profileOverride?.scenario.sceneDetails,
        };
    },
)(toCacheKey);

const getDefaultScenarioSettings = (profile?: IPersistence<IProfileEntity>) => {
    return {
        scenarioId: profile ? profile.scenario.scenarioId : 'atm',
        lightStart: profile ? new Time24(profile.scenario.lightStart) : new Time24(8),
        lightEnd: profile ? new Time24(profile.scenario.lightEnd) : new Time24(16),
        nightLighting: profile?.scenario.nightLighting ?? LightCondition.NoIllumination,
        sceneDetails: profile?.scenario.sceneDetails ?? 10,
    };
};
