import type { IItemRelationEntity, IPersistence } from '../../userDataPersistence';

export class CoreItemProductIdUpdateError extends Error {
    constructor(public affectingRelations: IPersistence<IItemRelationEntity>[]) {
        super(`Error when updating product id on item`);

        Object.setPrototypeOf(this, CoreItemProductIdUpdateError.prototype);
    }

    public getAccessoriesOrApplicationsRelations() {
        return this.affectingRelations.filter(
            (relation) =>
                relation.relationType === 'acap' ||
                relation.relationType === 'accessory' ||
                relation.relationType === 'partnerAcap',
        );
    }

    public getSensorUnitsRelations() {
        return this.affectingRelations.filter((relation) => relation.relationType === 'sensorUnit');
    }

    public hasAccessoriesOrApplications() {
        return this.getAccessoriesOrApplicationsRelations().length > 0;
    }

    public hasSensorUnits() {
        return this.getSensorUnitsRelations().length > 0;
    }
}
