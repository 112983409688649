import * as React from 'react';
import { css } from '@emotion/css';
import { Box } from '../../../components/containers';

const spinner = require('./spinner.png');

const spinnerStyle = css`
    @keyframes spinner {
        100% {
            transform: rotate(360deg);
        }
    }

    animation: spinner 0.75s linear 0s infinite;
    height: 100%;
`;

interface ISpinner {
    size?: string | number | undefined;
}

export const Spinner: React.FunctionComponent<ISpinner> = (props) => {
    return (
        <Box height={props.size}>
            <img src={spinner} className={spinnerStyle} />
        </Box>
    );
};

Spinner.displayName = 'Spinner';
