import { createSelector } from 'reselect';
import type { IPiaSystemComponent, IPiaSoftware } from 'app/core/pia';
import { PiaItemRecorderCategory } from 'app/core/pia';
import type { Id } from 'app/core/persistence';
import {
    getPiaItemsRecord,
    getCurrentProjectItems,
    getCurrentProjectItemRelationsArray,
    getCurrentProjectRecordingSolutionItems,
} from 'app/modules/common';
import {
    getAdditionalStorage,
    getNumberOfIncludedLicenses,
    getNumberOfPorts,
    getPartnerNumberOfPorts,
} from './utils';
import { PartnerSystemItemCategory } from 'app/core/partner';
import { CENTER_LICENSE_PIAID_FIVE_YEAR, CENTER_LICENSE_PIAID_ONE_YEAR } from '../constants';

export interface IPoeStats {
    poeClass1Ports: number;
    poeClass2Ports: number;
    poeClass3Ports: number;
    poeClass4Ports: number;
    poeClass5Ports: number;
    poeClass6Ports: number;
    poeClass7Ports: number;
    poeClass8Ports: number;
    highPoEPorts: number;
    totalPorts: number;
}

export interface IRecordingItemStats {
    /**
     * The number of channels supported by the recorder
     */
    nrOfChannels: number;
    /**
     * The number of licenses included in this product
     */
    nrOfLicenses: number;
    /**
     * The amount of storage provided by the recorder
     */
    storage: number;
    /**
     * The maximum recording bandwidth attained by the recorder
     */
    bandwidth: number;
    /**
     * The amount of PoE power supplied by this product
     */
    power: number;
    /**
     * The number of network ports provided by this product
     */
    ports: IPoeStats;
    /**
     * The number of video outputs provided by this product
     */
    video: number;
    /**
     * The amount of extra storage added by accessories (hard drives)
     */
    additionalStorage: number;
}

const getPartnerStats = () => {
    return {
        nrOfLicenses: 0,
        power: 0,
        video: 0,
        ports: getPartnerNumberOfPorts(),
        additionalStorage: 0,
    };
};

/**
 * Get the the specifications of the current recording solution.
 * Returns a record with the persisted item id as key and a stats object
 * as value.
 */
export const getRecordingSolutionSpecsRecord = createSelector(
    [
        getCurrentProjectRecordingSolutionItems,
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
    ],
    (items, piaItems, allProjectItems, relations) =>
        items.reduce(
            (specs, item) => {
                const isPartnerSystemComponent =
                    item.properties.partnerSystemComponent !== undefined;
                const piaItem = piaItems[item.productId!] as IPiaSystemComponent & IPiaSoftware;
                const isCoreLicense =
                    piaItem?.id === CENTER_LICENSE_PIAID_ONE_YEAR ||
                    piaItem?.id === CENTER_LICENSE_PIAID_FIVE_YEAR;

                const isCategoryRecorders2 = isPartnerSystemComponent
                    ? item.properties.partnerSystemComponent?.category ===
                      PartnerSystemItemCategory.SERVER
                    : piaItem.category === PiaItemRecorderCategory.RECORDERS2;

                const maxRecordingStorageMb = isPartnerSystemComponent
                    ? item.properties.partnerSystemComponent?.maxRecordingStorageMegaBytes ?? 0
                    : piaItem.properties.maxRecordingStorageMegaBytes ||
                      (piaItem.properties.memoryCardSize &&
                          piaItem.properties.memoryCardSize * 1000) ||
                      0;

                const additionalStorage =
                    item.quantity *
                    getAdditionalStorage(item, allProjectItems, relations, piaItems);

                const storage =
                    item.quantity *
                    (isPartnerSystemComponent
                        ? maxRecordingStorageMb
                        : maxRecordingStorageMb + additionalStorage);

                const maxCameraCount = isPartnerSystemComponent
                    ? item.properties.partnerSystemComponent?.maxCameraCount
                    : piaItem.properties.maxCameraCount;

                const maxRecordingBandwidthBits = isPartnerSystemComponent
                    ? item.properties.partnerSystemComponent?.maxRecordingBandwidthBits
                    : piaItem.properties.maxRecordingBandwidthBits;

                const omittedStats: Omit<
                    IRecordingItemStats,
                    'nrOfChannels' | 'bandwidth' | 'storage'
                > = isPartnerSystemComponent
                    ? getPartnerStats()
                    : {
                          nrOfLicenses: isCoreLicense
                              ? 0
                              : item.quantity * getNumberOfIncludedLicenses(piaItem),
                          power: item.quantity * (piaItem.properties.poeTotalPower || 0),
                          video: item.quantity * (piaItem.properties.monitorsSupported || 0),
                          ports: getNumberOfPorts(piaItem, item.quantity),
                          additionalStorage,
                      };

                const stats: IRecordingItemStats = {
                    nrOfChannels:
                        maxCameraCount === undefined && isCategoryRecorders2
                            ? Number.POSITIVE_INFINITY
                            : item.quantity * (maxCameraCount || 0),
                    bandwidth: item.quantity * (maxRecordingBandwidthBits || 0),
                    storage,
                    ...omittedStats,
                };
                specs[item._id] = stats;
                return specs;
            },
            {} as Record<Id, IRecordingItemStats>,
        ),
);

/**
 * Get the sum of the current recording solutions specifications
 */
export const getRecordingSolutionTotalSpecs = createSelector(
    [getRecordingSolutionSpecsRecord],
    (statRecord) =>
        Object.values(statRecord).reduce(
            (specs: IRecordingItemStats, current: IRecordingItemStats): IRecordingItemStats => {
                return {
                    nrOfChannels: specs.nrOfChannels + current.nrOfChannels,
                    nrOfLicenses: specs.nrOfLicenses + current.nrOfLicenses,
                    storage: specs.storage + current.storage,
                    bandwidth: specs.bandwidth + current.bandwidth,
                    power: specs.power + current.power,
                    ports: countPorts(specs.ports, current.ports),
                    video: specs.video + current.video,
                    additionalStorage: specs.additionalStorage + current.additionalStorage,
                };
            },
            {
                nrOfChannels: 0,
                nrOfLicenses: 0,
                storage: 0,
                bandwidth: 0,
                power: 0,
                ports: {
                    poeClass1Ports: 0,
                    poeClass2Ports: 0,
                    poeClass3Ports: 0,
                    poeClass4Ports: 0,
                    poeClass5Ports: 0,
                    poeClass6Ports: 0,
                    poeClass7Ports: 0,
                    poeClass8Ports: 0,
                    highPoEPorts: 0,
                    totalPorts: 0,
                },
                video: 0,
                additionalStorage: 0,
            },
        ),
);

const countPorts = (specsPorts: IPoeStats, currentPorts: IPoeStats): IPoeStats => {
    return {
        poeClass1Ports: specsPorts.poeClass1Ports + currentPorts.poeClass1Ports,
        poeClass2Ports: specsPorts.poeClass2Ports + currentPorts.poeClass2Ports,
        poeClass3Ports: specsPorts.poeClass3Ports + currentPorts.poeClass3Ports,
        poeClass4Ports: specsPorts.poeClass4Ports + currentPorts.poeClass4Ports,
        poeClass5Ports: specsPorts.poeClass5Ports + currentPorts.poeClass5Ports,
        poeClass6Ports: specsPorts.poeClass6Ports + currentPorts.poeClass6Ports,
        poeClass7Ports: specsPorts.poeClass7Ports + currentPorts.poeClass7Ports,
        poeClass8Ports: specsPorts.poeClass8Ports + currentPorts.poeClass8Ports,
        highPoEPorts: specsPorts.highPoEPorts + currentPorts.highPoEPorts,
        totalPorts: specsPorts.totalPorts + currentPorts.totalPorts,
    };
};
