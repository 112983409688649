import type { IPolygon } from 'app/modules/common';
import { intersectPolygons } from 'app/modules/common';
import { rotate, offset, type Point } from 'axis-webtools-util';

import { ICONS_ZOOM_BREAKPOINT, ICON_OFFSET_DOUBLE } from './iconConstants';
import type * as leaflet from 'leaflet';
import { latLngToPolygon } from './polygon';

export interface IIconProps {
    iconWidth: number;
    iconHeight: number;
    offsetY: number;
}
export interface IDeviceProps {
    cameraHeight: number;
    targetHeight: number;
    rangeLimit: number;
    targetDistance: number;
    rotationAngleRad: number;
    targetRotationOffset: number;
}
export interface IDevicePolygons {
    resolutionGuidePolygon: leaflet.Polygon;
    visibleAreaPolygon: leaflet.Polygon;
    detectionGuidePolygon?: leaflet.Polygon;
    observationGuidePolygon?: leaflet.Polygon;
    recognitionGuidePolygon?: leaflet.Polygon;
    identificationGuidePolygon?: leaflet.Polygon;
}
// find out if icons should be shown or hidden
// show if:
// 1. icon is inside the cone i.e not behind blocker
// 2. if zoom level is not to small
// 3. if cameraHeight > targetHeight i.e the camera cone does cover the complete person which is an requirement (not applicable to radar)
// 4. if Math.abs(this.cameraHeight - this.targetHeight) < rangeLimit i.e rangeLimit is the hypotenuse

export const showRangeIcon = (
    deviceProps: IDeviceProps,
    devicePolygons: IDevicePolygons,
    iconProps: IIconProps,
    latLng: leaflet.LatLng,
    map: leaflet.Map,
    zoomLevel: number,
    isCamera: boolean,
    doriPixelsOn?: boolean,
) => {
    const iconPolygon = getIconPolygon(
        deviceProps,
        latLng,
        map,
        iconProps.iconHeight,
        iconProps.iconWidth,
        iconProps.offsetY,
    );
    // 1. icon is inside the cone i.e not behind blocker
    const isIconInsideVisibleArea = getIconIntersectVisibleArea(
        iconPolygon,
        map,
        devicePolygons,
        doriPixelsOn,
    );
    // 2. if zoom level is not to small
    const zoomLevelOk = zoomLevel > ICONS_ZOOM_BREAKPOINT;

    // 3. if cameraHeight > targetHeight i.e the camera cone does cover the complete person which is an requirement (not applicable to radar)
    // 4. if Math.abs(this.cameraHeight - this.targetHeight) < rangeLimit i.e rangeLimit is the hypotenuse
    const cameraChecks = isCamera
        ? deviceProps.cameraHeight > deviceProps.targetHeight &&
          Math.abs(deviceProps.cameraHeight - deviceProps.targetHeight) < deviceProps.rangeLimit
        : true;
    return isIconInsideVisibleArea && zoomLevelOk && cameraChecks;
};

const getIconIntersectVisibleArea = (
    iconPolygon: IPolygon,
    map: leaflet.Map,
    devicePolygons: IDevicePolygons,
    doriPixelsOn?: boolean,
): boolean => {
    // find out if the icon polygon intersects either the resolutionGuide or visibleArea polygon
    let insidePolygon = false;
    if (devicePolygons.resolutionGuidePolygon) {
        const intersectPolygon = intersectPolygons(
            iconPolygon,
            latLngToPolygon(devicePolygons.resolutionGuidePolygon, map),
        );
        insidePolygon = intersectPolygon.regions.length > 0;
    }
    if (!insidePolygon && devicePolygons.visibleAreaPolygon) {
        const intersectPolygon = intersectPolygons(
            iconPolygon,
            latLngToPolygon(devicePolygons.visibleAreaPolygon, map),
        );
        insidePolygon = intersectPolygon.regions.length > 0;
    }

    // if doriPixels is on we might need to check the different DORI polygons as well
    if (doriPixelsOn) {
        if (!insidePolygon && devicePolygons.detectionGuidePolygon) {
            const intersectPolygon = intersectPolygons(
                iconPolygon,
                latLngToPolygon(devicePolygons.detectionGuidePolygon, map),
            );
            insidePolygon = intersectPolygon.regions.length > 0;
        }
        if (!insidePolygon && devicePolygons.observationGuidePolygon) {
            const intersectPolygon = intersectPolygons(
                iconPolygon,
                latLngToPolygon(devicePolygons.observationGuidePolygon, map),
            );
            insidePolygon = intersectPolygon.regions.length > 0;
        }
        if (!insidePolygon && devicePolygons.recognitionGuidePolygon) {
            const intersectPolygon = intersectPolygons(
                iconPolygon,
                latLngToPolygon(devicePolygons.recognitionGuidePolygon, map),
            );
            insidePolygon = intersectPolygon.regions.length > 0;
        }
        if (!insidePolygon && devicePolygons.identificationGuidePolygon) {
            const intersectPolygon = intersectPolygons(
                iconPolygon,
                latLngToPolygon(devicePolygons.identificationGuidePolygon, map),
            );
            insidePolygon = intersectPolygon.regions.length > 0;
        }
    }

    return insidePolygon;
};

const getIconPolygon = (
    deviceProps: IDeviceProps,
    latLng: leaflet.LatLng,
    map: leaflet.Map,
    iconHeight: number,
    iconWidth: number,
    offsetY: number,
) => {
    const useIconPosZero =
        Math.abs(deviceProps.cameraHeight - deviceProps.targetHeight) >= deviceProps.rangeLimit;
    const iconPosGround = useIconPosZero
        ? 0
        : Math.sqrt(
              Math.pow(deviceProps.rangeLimit, 2) -
                  Math.pow(deviceProps.cameraHeight - deviceProps.targetHeight, 2),
          );

    // calculate the icon position in pixel coordinates
    const maxPosition = Math.min(deviceProps.targetDistance, iconPosGround);
    const distancePerPixel = getDistancePerPixel(
        iconPosGround,
        deviceProps.targetDistance,
        map,
        latLng,
    );
    const offsetIcon = distancePerPixel * (iconHeight / 2 + ICON_OFFSET_DOUBLE);
    const newIconPos: Point = [maxPosition - offsetIcon, offsetY];
    const rotationAngleRadWithOffset =
        deviceProps.rotationAngleRad - deviceProps.targetRotationOffset;
    const newRotatedIconPos = rotate(rotationAngleRadWithOffset)(newIconPos);
    const latLngIconPos = offset(latLng)(newRotatedIconPos);
    const pixelIconPos = map.project(latLngIconPos, map.getZoom());

    // calculate icon corner positions - and create a polygon
    const upperLeftCorner: Point = [
        pixelIconPos.x - iconWidth / 2,
        pixelIconPos.y + iconHeight / 2,
    ];
    const upperRightCorner: Point = [
        pixelIconPos.x + iconWidth / 2,
        pixelIconPos.y + iconHeight / 2,
    ];
    const lowerLeftCorner: Point = [
        pixelIconPos.x - iconWidth / 2,
        pixelIconPos.y - iconHeight / 2,
    ];
    const lowerRightCorner: Point = [
        pixelIconPos.x - iconWidth / 2,
        pixelIconPos.y + iconHeight / 2,
    ];
    const iconPolygon: IPolygon = {
        regions: [[upperLeftCorner, upperRightCorner, lowerLeftCorner, lowerRightCorner]],
        inverted: false,
    };
    return iconPolygon;
};

export const getDistancePerPixel = (
    iconLimit: number,
    targetDistance: number,
    map: leaflet.Map,
    latLng: leaflet.LatLng,
) => {
    const maxPosition = Math.min(targetDistance, iconLimit);
    const iconPos: Point = [maxPosition, 0];
    const latLngMaxPos = offset(latLng)(iconPos);
    const pixelPosOrigin = map.project(latLng, map.getZoom());
    const pixelPosMaxPos = map.project(latLngMaxPos, map.getZoom());

    // calculate the distance/pixel from origo, since this is done before rotation only x is relevant
    const distancePerPixel =
        Math.abs(pixelPosMaxPos.x - pixelPosOrigin.x) > 0
            ? maxPosition / Math.abs(pixelPosMaxPos.x - pixelPosOrigin.x)
            : 0;
    return distancePerPixel;
};

export const getIconMaxPosPixels = (
    rangeLimit: number,
    targetDistance: number,
    map: leaflet.Map,
    latLng: leaflet.LatLng,
    offsetY: number = 0,
) => {
    const maxPosition = Math.min(targetDistance, rangeLimit);
    const iconPos: Point = [maxPosition, offsetY];
    const latLngMaxPos = offset(latLng)(iconPos);
    const pixelPosMaxPos = map.project(latLngMaxPos, map.getZoom());
    return pixelPosMaxPos;
};

// calculate position for icon when placed inside the cone instead of outside
// take rotation and fact that icon is placed from the upper left corner
// (svgIconStyleCenter fixes that icon pos is set for center of icon)
export const getOffsetIconPosLatLng = (
    iconHeight: number,
    rangeLimit: number,
    rotationAngleRad: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    targetRotationOffset: number,
    latLng: leaflet.LatLng,
    map: leaflet.Map,
    offsetY: number = 0,
) => {
    const rotationAngleRadWithOffset = rotationAngleRad - targetRotationOffset;
    // Icon is placed in the ground-plane. Calculate distance from origin for limit
    const useIconPosZero = cameraHeight - targetHeight >= rangeLimit;
    const iconPosGround = useIconPosZero
        ? 0
        : Math.sqrt(Math.abs(Math.pow(rangeLimit, 2) - Math.pow(cameraHeight - targetHeight, 2)));
    const maxPosition = Math.min(targetDistance, iconPosGround);
    const distancePerPixel = getDistancePerPixel(iconPosGround, targetDistance, map, latLng);

    const offsetIcon = distancePerPixel * (iconHeight / 2 + ICON_OFFSET_DOUBLE);
    const newIconPos: Point = [maxPosition - offsetIcon, offsetY];

    const newRotatedIconPos = rotate(rotationAngleRadWithOffset)(newIconPos);
    const newLatLngMaxPos = offset(latLng)(newRotatedIconPos);

    return newLatLngMaxPos;
};
