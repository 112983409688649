import { SchedulesActions } from '../state/Schedules.actions';
import { injectable } from 'inversify';
import { ActionCreator, IAction } from 'app/store';
import { Id } from 'app/core/persistence';

@injectable()
export class SchedulesActionService {
    constructor() {}

    @ActionCreator()
    public editSchedule(scheduleId: Id): IAction<Id> {
        return {
            type: SchedulesActions.EditSchedule,
            payload: scheduleId,
        };
    }
}
