import { trigonometry } from './trigonometry';
import { mul, type Point } from './point';

interface ILatLng {
    lat: number;
    lng: number;
}

const F = 111111;

/** Offset a LatLng with a point */
export const offset = (origin: ILatLng) => (point: Point) => ({
    lat: origin.lat + point[1] / F,
    lng: origin.lng + point[0] / (F * Math.cos(trigonometry.toRadians(origin.lat))),
});

/** get the offset between two LatLngs */
export const getOffset =
    (origin: ILatLng) =>
    (target: ILatLng): Point => [
        (target.lng - origin.lng) * (F * Math.cos(trigonometry.toRadians(origin.lat))),
        (target.lat - origin.lat) * F,
    ];

/** Rotate a point around origo */
export const rotate =
    (angle: number) =>
    (point: Point): Point => [
        point[0] * Math.cos(angle) + point[1] * Math.sin(angle),
        point[0] * -Math.sin(angle) + point[1] * Math.cos(angle),
    ];

/** Get the bearing between two LatLngs */
export function getBearing(a: ILatLng, b: ILatLng): number {
    const startLat = trigonometry.toRadians(a.lat);
    const startLong = trigonometry.toRadians(a.lng);
    const endLat = trigonometry.toRadians(b.lat);
    const endLong = trigonometry.toRadians(b.lng);

    let dLong = endLong - startLong;

    const dPhi = Math.log(
        Math.tan(endLat / 2 + Math.PI / 4) / Math.tan(startLat / 2 + Math.PI / 4),
    );

    if (Math.abs(dLong) > Math.PI) {
        if (dLong > 0) {
            dLong = -(2 * Math.PI - dLong);
        } else {
            dLong = 2 * Math.PI + dLong;
        }
    }

    return Math.atan2(dLong, dPhi);
}

/**
 * Calculate the Mercator projection of a latitude
 * @param lat the latitude to project
 * @returns the Mercator projection of the latitude
 */
function mercatorProjectLat(lat: number): number {
    // Calculate the sin of the latitude
    const sin = Math.sin(trigonometry.toRadians(lat));

    // Mercator project the latitude
    // https://en.wikipedia.org/wiki/Mercator_projection
    const mercatorY = Math.log((1 + sin) / (1 - sin)) / 2;

    // Clamp the result. Needed to avoid NaNs when we approach the poles
    return Math.max(Math.min(mercatorY, Math.PI), -Math.PI);
}

/**
 * Calculate the zoom level that fits the bounds
 *
 * @param topLeft the top left corner of the bounds
 * @param bottomRight the bottom right corner of the bounds
 * @param width the width of the map
 * @param height the height of the map
 * @returns the zoom level
 */
export function getZoomLevel(
    topLeft: ILatLng,
    bottomRight: ILatLng,
    width: number,
    height: number,
): number {
    const TILE_SIZE = 256;

    const north = topLeft.lat;
    const south = bottomRight.lat;
    let east = bottomRight.lng;
    let west = topLeft.lng;

    // if the bounds cross the antimeridian, adjust them
    if (Math.abs(east - west) > 180) {
        west = (west + 360) % 360;
        east = (east + 360) % 360;
    }

    // Calculate the fraction of the Mercator projected latitudes. This value will become
    // larger as we approach the poles.
    const latFraction =
        Math.abs(mercatorProjectLat(north) - mercatorProjectLat(south)) / (Math.PI * 2);
    // Calculate the fraction of the longitudes
    const lngFraction = Math.abs(east - west) / 360;

    // Calculate the zoom levels required to fit the fractions in the available map size
    const latZoom = Math.floor(Math.log2(TILE_SIZE / height / latFraction));
    const lngZoom = Math.floor(Math.log2(TILE_SIZE / width / lngFraction));

    // Return the smallest zoom level but clamp between 1 and 20
    return Math.max(Math.min(latZoom, lngZoom, 20), 1);
}

/**
 * Round an angle to the provider angular resolution
 * @param angularResolution the angular resolution to round to, i.e. the number of
 * slices to divide the full circle into
 * @param alpha the angle to round
 * @returns the rounded angle
 */
export const roundAngle = (angularResolution: number) => (alpha: number) =>
    (Math.round((alpha * angularResolution) / (2 * Math.PI)) / angularResolution) * 2 * Math.PI;

/**
 * Get the midpoint for a number of latlngs
 * @param {...*} latLngs an arbirtrary number of latlngs
 * @returns the midpoint
 */
export const getMidpoint = (first: ILatLng, ...rest: ILatLng[]): ILatLng => {
    const sum = rest.reduce(
        (acc, it) => ({
            lat: acc.lat + it.lat,
            lng: acc.lng + it.lng,
        }),
        first,
    );

    return {
        lat: sum.lat / (rest.length + 1),
        lng: sum.lng / (rest.length + 1),
    };
};

/**
 * Create a transformer function for the passed in offset, scale, and rotation
 * @param origin the origin latLng
 * @param target the target latLng. Scaling and rotation is applied relative to this
 * @param scale the desired scale factor
 * @param angle the desired rotation
 * @returns a transform function, transforming a passed in latLng according to above
 */
export const createTransformer =
    (oldPos: ILatLng, newPos: ILatLng, scale: number, angle: number) =>
    (latLng: ILatLng): ILatLng => {
        const getOffsetFromCenter = getOffset(newPos);
        const offsetFromCenter = offset(newPos);
        const rotatePoint = rotate(angle);

        const offsetLatLng =
            oldPos === newPos // no change in position
                ? latLng
                : offset(newPos)(getOffset(oldPos)(latLng));

        const scaledLatLng =
            scale === 1 // no change in scale
                ? offsetLatLng
                : offsetFromCenter(mul(getOffsetFromCenter(offsetLatLng), scale));

        const rotatedLatLng =
            angle === 0 // no change in rotation
                ? scaledLatLng
                : offsetFromCenter(rotatePoint(getOffsetFromCenter(scaledLatLng)));

        return rotatedLatLng;
    };
