import type { IPiaPartnerItem } from 'app/core/pia';
import { PiaItemSoftwareCategory } from 'app/core/pia';
import {
    getCurrentProjectLocation,
    getIsOtherSelectorOpen,
    getIsStandalone,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import { getIsAnyCountrySelected } from './getIsAnyCountrySelected';

const getAllPartnerProducts = (state: IStoreState): IPiaPartnerItem[] =>
    state.addonSelector.partnerItems;
const onlyInStandaloneVendors = ['Genetec', 'Milestone'];
const isOtherSelectorPartnerProduct = (product: IPiaPartnerItem): boolean =>
    product.category !== PiaItemSoftwareCategory.ACAP &&
    !onlyInStandaloneVendors.includes(product.properties.vendor);
const isPartnerAcapProduct = (product: IPiaPartnerItem): boolean =>
    product.categories.includes(PiaItemSoftwareCategory.ACAP) &&
    !onlyInStandaloneVendors.includes(product.properties.vendor);

const getAllPartnerProductsForCountry = createSelector(
    [getAllPartnerProducts, getCurrentProjectLocation, getIsAnyCountrySelected],
    (partnerProducts, location, isAnyCountrySelected) => {
        if (isAnyCountrySelected) {
            return partnerProducts;
        }
        return partnerProducts.filter(
            ({ properties }) =>
                // no location
                !location ||
                // product has no country exception rules
                !properties.countryExceptions ||
                // product has an allowlist
                (properties.countryExceptions.allowed &&
                    properties.countryExceptions.countryList.includes(location.countryCode)) ||
                // product has a disallowlist
                (!properties.countryExceptions.allowed &&
                    !properties.countryExceptions.countryList.includes(location.countryCode)),
        );
    },
);

/** Gets all partner products - if standalone all partner products, only acaps - if SiteDesigner application selector, all except acaps if other selector). */
export const getPartnerProducts = createSelector(
    [getAllPartnerProductsForCountry, getIsStandalone, getIsOtherSelectorOpen],
    (partnerProducts, isStandAlone, isOtherSelector) => {
        if (isStandAlone) {
            return partnerProducts;
        }
        // If user is located at the "Other products and services" page we don't want to include ACAPs since those products have their own selector.
        if (isOtherSelector) {
            return partnerProducts.filter(isOtherSelectorPartnerProduct);
        }
        // User is located at "Add partner application" selector and should only see ACAPs here.
        return partnerProducts.filter(isPartnerAcapProduct);
    },
);
