import * as React from 'react';
import {
    Box,
    Clickable,
    Grid,
    DropDownMenuColor,
    Stack,
    Tab,
    Text,
    Checkbox,
    Border,
    ColorPaletteColors,
} from 'app/components';
import { t } from 'app/translate';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { ServiceLocator } from 'app/ioc';
import { MapsActionService } from '../../services';
import {
    getLabelsLayerVisible,
    getSelectedColorLayers,
    getTextBoxesVisible,
} from '../../selectors';
import { css } from '@emotion/css';
import { AppConstants } from 'app/AppConstants';
import { LayerDeviceTypeFilterItem } from './LayerDeviceTypeFilterItem';
import { eventTracking } from 'app/core/tracking';

const layerOptions = css`
    position: absolute;
    z-index: ${AppConstants.mapUIDepth};
    background-color: ${ColorsEnum.white};
    border-top: 2px solid ${ColorsEnum.black};
    border-bottom: 2px solid ${ColorsEnum.black};
    top: 200px;
    width: 280px;
    inset-inline-end: 54px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;

export type LayerOptionsTabs = 'deviceType' | 'color';

interface ILayerOptions {
    selectedTab: LayerOptionsTabs;
    onTabChange(tab: LayerOptionsTabs): void;
}

export const LayerOptions: React.FC<ILayerOptions> = ({ selectedTab, onTabChange }) => {
    const selectedColors = useSelector<IStoreState, string[]>(getSelectedColorLayers);
    const labelsVisible = useSelector(getLabelsLayerVisible);
    const textBoxesVisible = useSelector(getTextBoxesVisible);
    const [mapsActionService] = React.useState<MapsActionService>(
        ServiceLocator.get(MapsActionService),
    );

    const setSelectedColor = (color: Colors) => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Color');
        mapsActionService.toggleColorLayer(color);
    };
    const toggleLabelsLayerVisible = () => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Label');
        mapsActionService.toggleLabelsLayer(!labelsVisible);
    };
    const toggleTextBoxesVisible = () => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Text Boxes');
        mapsActionService.toggleTextBoxesLayer(!textBoxesVisible);
    };

    return (
        <div className={layerOptions}>
            <Stack vertical alignItems="center" spacing="none">
                <Border bottomWidth={1} color="grey3">
                    <Box
                        color="grey3"
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="around"
                    >
                        <Tab
                            testId="layer_picker_devices_tab_button"
                            onClick={() => onTabChange('deviceType')}
                            text={t.deviceType}
                            isActive={selectedTab === 'deviceType'}
                            fullWidth
                        />
                        <Tab
                            testId="layer_picker_color_tab_button"
                            onClick={() => onTabChange('color')}
                            text={t.color}
                            isActive={selectedTab === 'color'}
                            fullWidth
                        />
                    </Box>
                </Border>
                {selectedTab === 'color' && (
                    <Box padding="base" color="white" width="260px" height="200px">
                        <Grid alignItems="center" justifyContent="center" spacing="half">
                            {ColorPaletteColors.map((theColor) => (
                                <Clickable
                                    key={`dropdown-color-${theColor}`}
                                    onClick={() => setSelectedColor(theColor)}
                                >
                                    <DropDownMenuColor
                                        color={theColor}
                                        size="md"
                                        selected={selectedColors.includes(theColor)}
                                    />
                                </Clickable>
                            ))}
                        </Grid>
                    </Box>
                )}
                {selectedTab === 'deviceType' && (
                    <Box width="100%" padding="base">
                        <Stack vertical>
                            <Text align="right" fullWidth style="semibold" color="grey6">
                                {t.coverageArea}
                            </Text>

                            <LayerDeviceTypeFilterItem
                                label={t.cameras}
                                selectionCriteria="camera"
                                hasCoverageArea={true}
                                devicesToInclude={[
                                    'camera',
                                    'encoder',
                                    'mainUnit',
                                    'doorstation',
                                    'sensorUnit',
                                    'analogCamera',
                                ]}
                            />
                            <LayerDeviceTypeFilterItem
                                label={t.speakers}
                                hasCoverageArea={true}
                                selectionCriteria="speaker"
                                devicesToInclude={['speaker']}
                            />
                            <LayerDeviceTypeFilterItem
                                label={t.radarDetectors}
                                hasCoverageArea={true}
                                selectionCriteria="radardetector"
                                devicesToInclude={['radardetector']}
                            />
                            <LayerDeviceTypeFilterItem
                                label={t.doorControllers}
                                selectionCriteria="doorcontroller"
                                devicesToInclude={['doorcontroller', 'door']}
                            />
                            <LayerDeviceTypeFilterItem
                                label={t.other}
                                selectionCriteria="alerter"
                                devicesToInclude={[
                                    'alerter',
                                    'peopleCounter',
                                    'systemController',
                                    'dockingStation',
                                    'decoder',
                                    'pac',
                                    'connectivitydevice',
                                    'pagingconsole',
                                ]}
                            />
                        </Stack>
                    </Box>
                )}
            </Stack>
            <Border color="grey3" topWidth={1}>
                <Box color="grey1" width="100%" padding="base">
                    <Stack vertical>
                        <Box justifyContent="between">
                            <Checkbox
                                slider
                                selected={labelsVisible}
                                onChange={() => toggleLabelsLayerVisible()}
                                testId="labels"
                            >
                                {t.labels}
                            </Checkbox>
                        </Box>
                        <Box justifyContent="between">
                            <Checkbox
                                slider
                                selected={textBoxesVisible}
                                onChange={() => toggleTextBoxesVisible()}
                                testId="text_boxes"
                            >
                                {t.textBoxes}
                            </Checkbox>
                        </Box>
                    </Stack>
                </Box>
            </Border>
        </div>
    );
};

LayerOptions.displayName = 'SelectLayerModal';
