import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';

export const getSelectedAddDeviceTab = createSelector(
    [getMapsState],
    (mapsState) => mapsState.selectedAddTab,
);

const getSelectedAddPiaDeviceRecord = createSelector(
    [getMapsState],
    (mapsState) => mapsState.selectedDragProduct,
);

export const getSelectedAddPiaDevice = createSelector(
    [getSelectedAddDeviceTab, getSelectedAddPiaDeviceRecord],
    (selectedTab, selectedProducts) => selectedProducts[selectedTab.name] ?? null,
);
