import type { DeviceType } from 'app/core/persistence';
import { getDeviceIcon, getDeviceImage, getDeviceType } from 'app/core/persistence';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey/toCacheKey';
import { getCurrentProjectItem } from '../../project/selectors/getCurrentProject';
import { getPiaItem } from '../../item/selectors/getPiaItem';

export const getImageForDeviceId = createCachedSelector([getCurrentProjectItem], (item) => {
    const image = getDeviceImage(item?.productId, item);
    return image;
})(toCacheKey);

export const getImageIconForDeviceId = createCachedSelector([getCurrentProjectItem], (item) => {
    const icon = getDeviceIcon(item);
    return icon;
})(toCacheKey);

const getImageNameForDeviceType = (deviceType: DeviceType | undefined) => {
    switch (deviceType) {
        case 'mainUnit':
            return 'mainUnit';
        case 'sensorUnit':
            return 'sensorUnit';
        case 'encoder':
            return 'encoder';
        case 'speaker':
            return 'speaker';
        case 'radardetector':
            return 'radardetector';
        case 'doorcontroller':
            return 'doorcontroller';
        case 'doorstation':
        case 'camera':
            return 'camera';
        case 'analogCamera':
            return 'analogCamera';
        case 'door':
            return 'door';
        default:
            return undefined;
    }
};

export const getDefaultImageForDeviceId = createCachedSelector(
    [getCurrentProjectItem, getPiaItem],
    (item, piaItem) => {
        if (!item) {
            return require(`src/assets/images/axis.png`);
        }
        const deviceType = getDeviceType(item);
        const imgName = getImageNameForDeviceType(deviceType);
        if (imgName) {
            return require(`src/assets/images/${imgName}-grey.png`);
        }

        if (piaItem?.properties.vendor.toUpperCase() === '2N') {
            return require(`src/assets/images/2n.png`);
        }

        return require(`src/assets/images/axis.png`);
    },
)(toCacheKey);
