import * as React from 'react';
import { t } from 'app/translate';
import type { IOptionProps } from 'app/components';
import { Box, Select } from 'app/components';
import type { SelectedVendorType } from 'app/core/persistence';
import { ProjectService } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { getSelectedRecordingVendor } from 'app/modules/common';
import { useSelector } from 'react-redux';
import { eventTracking } from 'app/core/tracking';

export const SelectSolutionType: React.FC = () => {
    const selectSolutionOptions: IOptionProps[] = [
        {
            text: t.recordingSolutionAxis,
            value: 'Axis',
        },
        {
            text: t.recordingSolutionGenetec,
            value: 'Genetec',
        },
        {
            text: t.recordingSolutionMilestone,
            value: 'Milestone',
        },
    ];
    const projectService = useService(ProjectService);
    const selectedRecordingVendor = useSelector(getSelectedRecordingVendor);

    const onChangeSolutionVendor = (vendor: string) => {
        eventTracking.logUserEvent('System Components', 'Select vendor', vendor);
        projectService.updateCurrentProject({
            selectedRecordingVendor: vendor as SelectedVendorType,
        });
    };

    return (
        <Box height={40}>
            <Select
                fillDropdownWidth
                fillWidth
                fullHeight
                options={selectSolutionOptions}
                onChange={onChangeSolutionVendor}
                value={selectedRecordingVendor}
                underline
                adjustWidthToLongestOption={false}
            />
        </Box>
    );
};
SelectSolutionType.displayName = 'SelectSolutionType';
