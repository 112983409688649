import type { IDashboardState } from './IDashboardState';
import { reducerBuilder } from 'app/store';
import { DashboardActions } from './Dashboard.actions';

const initialState: IDashboardState = {
    showEditProjectSettings: false,
    showEditProjectLocation: false,
    showShare: false,
    showProjectNetwork: false,
};

export const dashboardReducer = reducerBuilder<IDashboardState>()
    .setInitialState(() => initialState)
    .onAction<boolean>(DashboardActions.EditProjectSettings, (state, action) => {
        return {
            ...state,
            showEditProjectSettings: action.payload,
        };
    })
    .onAction<boolean>(DashboardActions.EditProjectLocation, (state, action) => {
        return {
            ...state,
            showEditProjectLocation: action.payload,
        };
    })
    .onAction<boolean>(DashboardActions.ShowShareProject, (state, action) => {
        return {
            ...state,
            showShare: action.payload,
        };
    })
    .onAction<boolean>(DashboardActions.ShowProjectNetwork, (state, action) => {
        return {
            ...state,
            showProjectNetwork: action.payload,
        };
    })
    .create();
