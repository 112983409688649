import { getAddCameraSearchFilter } from './getAddCameraSearchFilter';
import { nameComparator } from 'app/utils';
import type { DeviceType } from 'app/core/persistence';
import type { PiaCategory } from 'app/core/pia';
import {
    filterProducts,
    getAllCameraCategories,
    PiaItemSpeakerCategory,
    PiaItemWearablesCategory,
    PiaItemDetectorCategory,
    PiaItemPacCategory,
    PiaItemEncoderCategory,
    PiaItemMainUnitCategory,
    PiaItemSensorUnitCategory,
    PiaItemDecoderCategory,
    PiaItemPeopleCounterCategory,
    PiaItemAlerterCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from 'app/core/pia';
import {
    exceptCamerasWithoutLens,
    getCurrentAvailablePiaDevices,
    getShouldShow2NSelector,
} from 'app/modules/common';
import { createSelector } from 'reselect';
import { getSelectedAddDeviceTab } from './getSelectedAddTab';

const selectedTabToPiaCategory = (deviceTypes: DeviceType[]): PiaCategory[] => {
    const piaCategories: PiaCategory[] = [];
    if (deviceTypes.includes('camera')) piaCategories.push(...getAllCameraCategories());
    if (deviceTypes.includes('speaker')) piaCategories.push(PiaItemSpeakerCategory.SPEAKER);
    if (deviceTypes.includes('radardetector'))
        piaCategories.push(PiaItemDetectorCategory.RADARDETECTORS);
    if (deviceTypes.includes('connectivitydevice'))
        piaCategories.push(PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES);
    if (deviceTypes.includes('doorcontroller'))
        piaCategories.push(PiaItemPacCategory.DOORCONTROLLERS);
    if (deviceTypes.includes('encoder')) piaCategories.push(PiaItemEncoderCategory.ENCODER);
    if (deviceTypes.includes('mainUnit')) piaCategories.push(PiaItemMainUnitCategory.MAINUNIT);
    if (deviceTypes.includes('bodyWornCamera'))
        piaCategories.push(PiaItemWearablesCategory.CAMERAS);
    if (deviceTypes.includes('cameraExtension'))
        piaCategories.push(PiaItemWearablesCategory.CAMERAEXTENSIONS);
    if (deviceTypes.includes('dockingStation'))
        piaCategories.push(PiaItemWearablesCategory.DOCKING);
    if (deviceTypes.includes('systemController'))
        piaCategories.push(PiaItemWearablesCategory.CONTROLLER);
    if (deviceTypes.includes('sensorUnit'))
        piaCategories.push(
            ...[PiaItemSensorUnitCategory.SENSORUNIT, PiaItemSensorUnitCategory.THERMALSENSOR],
        );
    if (deviceTypes.includes('decoder')) piaCategories.push(PiaItemDecoderCategory.DECODER);
    if (deviceTypes.includes('alerter')) piaCategories.push(PiaItemAlerterCategory.ALERTERS);
    if (deviceTypes.includes('pac'))
        piaCategories.push(
            ...[
                PiaItemPacCategory.ACCESSSERVER,
                PiaItemPacCategory.ANSWERINGUNIT,
                PiaItemPacCategory.IORELAYS,
                PiaItemPacCategory.NETWORKREADER,
                PiaItemPacCategory.READERS,
                PiaItemPacCategory.DOORSTATIONS,
            ],
        );
    if (deviceTypes.includes('doorstation')) piaCategories.push(PiaItemPacCategory.DOORSTATIONS);
    if (deviceTypes.includes('peopleCounter'))
        piaCategories.push(PiaItemPeopleCounterCategory.PEOPLECOUNTERS);
    if (deviceTypes.includes('pagingconsole'))
        piaCategories.push(PiaItemPagingConsoleCategory.PAGINGCONSOLE);

    if (piaCategories.length === 0) throw new Error(`Unsupported device types ${deviceTypes}`);

    return piaCategories;
};

const getAvailablePiaDevicesSortedByName = createSelector(
    [getCurrentAvailablePiaDevices],
    (piaDevices) => piaDevices.sort(nameComparator),
);

export const getDragDropDownProducts = createSelector(
    [
        getAvailablePiaDevicesSortedByName,
        getSelectedAddDeviceTab,
        getShouldShow2NSelector,
        getAddCameraSearchFilter,
    ],
    (piaItems, selectedTab, show2N, searchText) => {
        const filterCategories = selectedTabToPiaCategory(selectedTab.deviceTypes);
        const axisDevices = piaItems
            .filter(filterProducts.byIncludesAnyOfCategories(filterCategories))
            .filter(filterProducts.byVendor('axis'));
        const twoNDevices =
            show2N && selectedTab.name === 'others'
                ? piaItems.filter(filterProducts.byVendor('2n'))
                : [];

        const piaDevices = [...axisDevices, ...twoNDevices];

        if (
            selectedTab.name === 'cameras' ||
            (selectedTab.name === 'others' && searchText.length > 0)
        ) {
            return piaDevices
                .filter(exceptCamerasWithoutLens)
                .filter((piaDevice) =>
                    piaDevice.name.toLowerCase().includes(searchText.toLowerCase()),
                );
        }
        return piaDevices;
    },
);
