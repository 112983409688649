import type { IPiaDevice } from 'app/core/pia';
import type { DeviceGroupFilter } from './DeviceGroupFilter';

/** Checks if a device fulfills sustainability requirements in filter */
export const meetsSustainabilityRequirements = (
    filter: DeviceGroupFilter,
    device: IPiaDevice,
): boolean => {
    if (
        !!filter.minRecycledPlastic &&
        (device.properties.recycledPlasticPercent ?? 0) < filter.minRecycledPlastic
    ) {
        return false;
    }
    if (filter.pvcFree && !device.properties.pvcFree) {
        return false;
    }
    if (filter.bfrCfrFree && !device.properties.bfrCfrFree) {
        return false;
    }
    return true;
};
