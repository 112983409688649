import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { deviceTypeCheckers, getBodyWornCameraDefaultProfile } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey/toCacheKey';

export const getWearableProfile = createCachedSelector([getCurrentProjectItem], (item) =>
    !!item && deviceTypeCheckers.isBodyWornCamera(item)
        ? item.properties.bodyWornCamera.profile
            ? item.properties.bodyWornCamera.profile
            : getBodyWornCameraDefaultProfile()
        : undefined,
)(toCacheKey);
