import { Box, Button, Spacer, Stack, Text } from 'app/components';
import { CommonActionService, getUserSignedIn } from 'app/modules/common';
import { t } from 'app/translate';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsCreatingAccessCode, getIsLocalProjectOrView } from '../selectors';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { ExportProjectActionService } from '../services';
import type { Id } from 'app/core/persistence';
import { GeneratedCode } from './GeneratedCode';
import { DownloadProjectFile } from 'app/modules/userProjects';
import type { IProjectSettingToken } from 'app/modules/userProjects';

interface IExportProjectProps {
    projectId: Id;
    projectSettingToken: IProjectSettingToken | null;
}

export const ExportProject: React.FC<IExportProjectProps> = ({
    projectId,
    projectSettingToken,
}) => {
    const isSignedIn = useSelector(getUserSignedIn);
    const isLocalProject = useSelector(getIsLocalProjectOrView);
    const isCreatingAccessCode = useSelector(getIsCreatingAccessCode);

    const actions = useService(CommonActionService);
    const exportProjectActions = useService(ExportProjectActionService);

    const showGenerateAccessCode = isSignedIn && !projectSettingToken && !isCreatingAccessCode;
    const showSignIn = !isSignedIn;

    const generateToken = () => {
        eventTracking.logUserEvent('Export project', 'Generate Token');
        exportProjectActions.generateToken(projectId, projectSettingToken);
    };

    const downloadSettingsFile = () => {
        eventTracking.logUserEvent('Export project', 'Save Settings To File');
        exportProjectActions.saveSettingsToFile(projectId);
    };

    return (
        <Stack vertical lineBetweenColor="grey3" alignItems="start">
            <Stack vertical>
                <Text style="subheader">{t.publishProjectForInstallation}</Text>
                <Text color="grey8">
                    <Trans i18nKey="projectShareDetails" />
                </Text>
                {!isLocalProject && (
                    <Stack vertical spacing="half" alignItems="start">
                        <Text style="semibold" color="grey9">
                            {t.projectShareShareByAccessCode}
                        </Text>
                        <Text color="grey8">{t.projectShareByAccessCodeDetails}</Text>
                        {showGenerateAccessCode ? (
                            <Box>
                                <Button
                                    testId="generate_code_btn"
                                    primary
                                    onClick={generateToken}
                                    disabled={isLocalProject}
                                >
                                    {t.projectShareGenerateCode}
                                </Button>
                            </Box>
                        ) : (
                            <GeneratedCode
                                projectId={projectId}
                                projectSettingToken={projectSettingToken}
                            />
                        )}
                        {showSignIn && !projectSettingToken && (
                            <Box>
                                <Button primary onClick={actions.login}>
                                    {t.userSignIn}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                )}

                <Stack vertical spacing="half" alignItems="start">
                    <Text style="semibold" color="grey9">
                        {t.projectShareShareByFile}
                    </Text>
                    <Text color="grey8">{t.projectShareBySettingsFileDetails}</Text>
                    <Button primary onClick={downloadSettingsFile}>
                        {t.projectShareDownloadSettingsFile}
                    </Button>
                    <Spacer />
                </Stack>
            </Stack>

            <DownloadProjectFile projectId={projectId} />
        </Stack>
    );
};

ExportProject.displayName = 'ExportProject';
