import * as React from 'react';
import { DeviceSelector } from 'app/modules/deviceSelector';
import { useParams } from 'react-router-dom';
import type { DeviceGroup } from 'app/modules/deviceSelector/models';

export const ProjectDeviceSelectorAddChild: React.FunctionComponent = () => {
    // If deviceGroup not provided - all device groups are shown in device selector
    return (
        <DeviceSelector
            parentId={useParams().itemId}
            urlDeviceGroup={useParams().deviceGroup as DeviceGroup}
        />
    );
};

ProjectDeviceSelectorAddChild.displayName = 'ProjectDeviceSelectorAddChild';
