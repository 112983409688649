export interface IStyle {
    className: Set<string>;
    inlineStyle: { [key: string]: any };
}

interface IStyleProps {
    className?: string;
    style?: { [key: string]: any };
}

/**
 * An entity is the result of a parsed styling prop.
 * Some props like margin will result in a set of class names,
 * others like width will result in an inline style property
 * Entities are merged into one final entity possibly containing
 * many class names in the className set and many properties in
 * the inlineStyle object
 */
export const entity = (): IStyle => ({
    className: new Set(),
    inlineStyle: {},
});

export const fromClassName = (...classNames: string[]): IStyle => ({
    className: new Set(classNames),
    inlineStyle: {},
});

export const fromInlineStyle = (inlineStyle: { [key: string]: any }): IStyle => ({
    className: new Set(),
    inlineStyle,
});

/**
 * Merging entities
 */
export const concat = (styles: IStyle[]): IStyle =>
    styles.reduce(
        (
            { className: classNameA, inlineStyle: inlineStyleA },
            { className: classNameB, inlineStyle: inlineStyleB },
        ) => ({
            className: new Set([
                ...Array.from(classNameA.values()),
                ...Array.from(classNameB.values()),
            ]),
            inlineStyle: {
                ...inlineStyleA,
                ...inlineStyleB,
            },
        }),
        entity(),
    );

/**
 * Converting entity to attributes to be spread on a JSX element
 */
export const toProps = ({ className, inlineStyle }: IStyle): IStyleProps => {
    const props: IStyleProps = {};

    if (className.size > 0) {
        props.className = Array.from(className).sort().join(' ');
    }

    if (Object.keys(inlineStyle).length > 0) {
        props.style = inlineStyle;
    }

    return props;
};
