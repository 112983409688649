import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { isCustomCamera } from 'app/core/persistence';
import type { PreviewImageType } from 'app/modules/common';
import {
    getSelectedDeviceChosenLens,
    getPixelsPerUnitSystemAbbreviation,
    getPreviewImageType,
    getPiaCameraForProductId,
    ResolutionPreviewComponent,
} from 'app/modules/common';
import { getPixelDensity, getSelectedInstallationPoint, getTiltAngle } from '../../../../selectors';
import { Box, IconButton, Positioned } from 'app/components';
import { MapsActionService } from '../../../../services/MapsAction.service';
import { ServiceLocator } from 'app/ioc';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';

interface IViewDetailsContainerProps {
    isGenericCamera: boolean;
    resolutionPreviewImageType: PreviewImageType;
    unit: string;
    alternateLensName?: string;
    pixelDensity: number;
    tiltAngle?: number;
    piaCamera?: IPiaCamera | IPiaSensorUnit | undefined;
}

const mapStateToProps = (storeState: IStoreState): IViewDetailsContainerProps => {
    const installationPoint = getSelectedInstallationPoint(storeState);
    const alternateLensName = getSelectedDeviceChosenLens(
        storeState,
        installationPoint?.parentDevice._id,
    )?.name;
    const piaCamera = getPiaCameraForProductId(
        storeState,
        installationPoint?.parentDevice.productId ?? null,
    );

    return {
        isGenericCamera:
            installationPoint?.parentDevice.productId === null &&
            !isCustomCamera(installationPoint.parentDevice),
        resolutionPreviewImageType: getPreviewImageType(
            storeState,
            installationPoint?.parentPiaDevice?.category,
        ),
        unit: getPixelsPerUnitSystemAbbreviation(storeState),
        alternateLensName,
        pixelDensity: getPixelDensity(storeState, installationPoint?.parentPiaDevice?.id),
        tiltAngle: getTiltAngle(storeState, installationPoint?._id),
        piaCamera,
    };
};

class ViewDetailsContainer extends React.Component<IViewDetailsContainerProps> {
    private mapsActionService: MapsActionService;

    constructor(props: IViewDetailsContainerProps) {
        super(props);
        this.mapsActionService = ServiceLocator.get(MapsActionService);
    }

    public render() {
        return (
            <Box position="relative" direction="column">
                <Positioned position="absolute" top="3px" right="3px">
                    <IconButton
                        icon="3d_rotation"
                        size="md"
                        onClick={this.mapsActionService.toggle3dView}
                    />
                </Positioned>
                <ResolutionPreviewComponent
                    pixelDensityPerMeter={this.props.pixelDensity}
                    resolutionPreviewImageType={this.props.resolutionPreviewImageType}
                    unit={this.props.unit}
                    alternateLensName={this.props.alternateLensName}
                    isGenericCamera={this.props.isGenericCamera}
                    showDetails
                    tiltAngle={this.props.tiltAngle}
                    piaCamera={this.props.piaCamera}
                />
            </Box>
        );
    }
}

export const ViewDetails = connect(mapStateToProps)(ViewDetailsContainer);
