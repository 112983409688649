import type { IPiaItem, PiaId } from 'app/core/pia';
import { injectable } from 'inversify';
import { IPv4, Validator } from 'ip-num';
import type {
    IItemEntity,
    INetworkRange,
    IPersistableRangeType,
    IPersistence,
    IProjectEntity,
} from '../userDataPersistence';
import { CurrentProjectService } from './CurrentProject.service';
import { getNewItemNetworkSettings, ItemService } from './item';
import { ProjectService } from './Project.service';
@injectable()
export class IPRangeService {
    constructor(
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private itemService: ItemService,
    ) {}

    private getProject(): Promise<IPersistence<IProjectEntity>> {
        const projectId = this.currentProjectService.getProjectId();
        return this.projectService.get(projectId);
    }

    /** Adds a network range for a specific device type */
    public updateRange = async (
        type: IPersistableRangeType,
        range: INetworkRange,
    ): Promise<void> => {
        const networkSettings = (await this.getProject()).networkSettings;
        if (!networkSettings) return;

        const persistedRanges = networkSettings.ranges;
        persistedRanges[type] = range;

        this.projectService.updateCurrentProject({
            networkSettings: { ...networkSettings, ranges: persistedRanges },
        });
    };

    public async setIpConfigurationsForItems(
        itemsToUpdate: IPersistence<IItemEntity>[],
        piaItemsRecord: Record<PiaId, IPiaItem>,
    ) {
        const allItems = this.currentProjectService.getAllEntitiesOfType('item');
        const projectNetworkSettings =
            this.currentProjectService.getCurrentProjectNetworkSettings();

        // keep track of occupied address when updating all items, used to avoid duplicates.
        const occupiedIPv4Addresses: IPv4[] = [];
        for (const item of itemsToUpdate) {
            const piaItem = item.productId !== null ? piaItemsRecord[item.productId] : null;
            const networkSettings = getNewItemNetworkSettings(
                item._id,
                item.properties,
                item.quantity,
                piaItem,
                projectNetworkSettings,
                allItems,
                occupiedIPv4Addresses,
            );
            if (networkSettings) {
                networkSettings.forEach(({ addresses }) =>
                    addresses.forEach((address) => {
                        if (Validator.isValidIPv4String(address)[0]) {
                            occupiedIPv4Addresses.push(IPv4.fromDecimalDottedString(address));
                        }
                    }),
                );
            }
            await this.itemService.updateItem(item._id, {
                networkSettings,
            });
        }
    }
}
