import * as React from 'react';
import { Box } from '../box/Box.component';
import type { IExtendableComponentWithChildren } from '../../models';
import type { IAutoTestable } from '../../ui-test';
import type { Colors } from 'app/styles';
import { PrintFix } from 'app/components/functional';

interface IWrapperProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * Hide overflowing content
     */
    hideOverflow?: boolean;
    /**
     * Setting this will change direction to `row` instead of `column`
     */
    horizontal?: boolean;
    /**
     * The minimum allowed width of the wrapper.
     */
    minWidth?: string;
    /**
     * Sets the background color.
     */
    color?: Colors;
}

/**
 * Used to wrap content at a high level.
 *
 * Useful when having a top row of tabs and main scrolling content to avoid
 * double scrollbars.
 *
 * Also makes sure that printing works in Firefox (see `<PrintFix>`)
 */
export class Wrapper extends React.Component<IWrapperProps> {
    public render() {
        const { testId, hideOverflow, horizontal, minWidth, color, ...extendedProps } = this.props;
        return (
            <PrintFix {...extendedProps}>
                <Box
                    testId={testId}
                    height={'100%'}
                    width={'100%'}
                    minWidth={minWidth}
                    overflow={hideOverflow ? 'hidden' : undefined}
                    direction={horizontal ? undefined : 'column'}
                    color={color}
                >
                    {this.props.children}
                </Box>
            </PrintFix>
        );
    }
}
