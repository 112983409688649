import * as React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import type { Spacings } from 'app/styles/spacings.type';
import { SpacingsEnum } from 'app/styles/spacings.type';
import type { Flex, AlignItems, JustifyContent } from 'app/styles/layout.type';
import {
    JustifyAround,
    FlexFullWidth,
    FlexGrow,
    DontShrink,
    FlexNone,
    FlexShrink,
    FlexShrinkAndGrow,
    FlexWrap,
    ItemsBaseline,
    ItemsCenter,
    ItemsEnd,
    ItemsStart,
    ItemsStretch,
    JustifyBetween,
    JustifyCenter,
    JustifyEnd,
} from 'app/styles/layout.type';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps } from '../../services';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';

const StackStyle = css`
    display: flex;
`;

const ColumnStyle = css`
    flex-direction: column;
`;

const ColumnStyleReverse = css`
    flex-direction: column-reverse;
`;

const RowStyleReverse = css`
    flex-direction: row-reverse;
`;

const FullWidthStyle = css`
    width: 100%;
`;

const FullHeightStyle = css`
    height: 100%;
`;

const SpacingStyle = (spacing: Spacings) => css`
    gap: ${SpacingsEnum[spacing]};
`;

interface IStackProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * The spacing between children
     */
    spacing?: Spacings;
    /**
     * Display children in a vertical stack instead of horizontal.
     * Sets the spacing above and below the elements in a column
     * instead of to the left and right (in a row). Defaults
     * to stretching the items horizontally.
     */
    vertical?: boolean;
    /**
     * Avoid setting max-width to 100%;
     */
    notFullWidth?: boolean;
    /**
     * Toggle full height
     */
    fullHeight?: boolean;
    /**
     * Toggle flex display
     */
    flex?: Flex;
    /**
     * Align the children
     */
    alignItems?: AlignItems;
    /**
     * Justify the children
     */
    justifyContent?: JustifyContent;
    /**
     * Adds a colored line between each element in the stack.
     */
    lineBetweenColor?: Colors;
    /**
     * By default, flex items will all try to fit onto one line. You can change that and allow the items to wrap onto multiple lines, from top to bottom.
     */
    wrap?: boolean;
    /**
     * Set min-width for the stack.
     */
    minWidth?: string;
    /**
     * Sets the width for the outer div
     */
    width?: React.CSSProperties['width'];
    /**
     * Set the direction to reverse
     */
    reverseDirection?: boolean;
}

/**
 * @deprecated Use Box instead
 *
 * Stack child items with space between them.
 * Set the `vertical` prop for a vertical stack (ie. a column) instead of
 * a horizontal stack (ie. a row).
 */
export class Stack extends React.Component<IStackProps> {
    public render() {
        const {
            children,
            vertical,
            spacing = 'base',
            flex,
            alignItems,
            justifyContent,
            notFullWidth,
            fullHeight,
            lineBetweenColor,
            wrap,
            minWidth,
            width,
            reverseDirection,
            ...extendedProps
        } = this.props;

        const attributes = extendableProps(
            extendedProps,
            {
                className: classNames(
                    StackStyle,
                    {
                        [ColumnStyle]: vertical && !reverseDirection,
                        [ColumnStyleReverse]: reverseDirection && vertical,
                        [RowStyleReverse]: reverseDirection && !vertical,
                        [FullWidthStyle]: vertical && !notFullWidth,
                        [FullHeightStyle]: fullHeight,
                        [FlexFullWidth]: flex === 'fullWidth',
                        [FlexGrow]: flex === 'grow',
                        [FlexShrinkAndGrow]: flex === 'shrinkAndGrow',
                        [FlexShrink]: flex === 'shrink',
                        [FlexNone]: flex === 'none',
                        [FlexWrap]: !!wrap,
                        [DontShrink]: flex === 'dontShrink' || undefined,
                        [ItemsCenter]:
                            alignItems === 'center' ||
                            (alignItems == null && !vertical && !justifyContent),
                        [ItemsStart]: alignItems === 'start',
                        [ItemsEnd]: alignItems === 'end',
                        [ItemsBaseline]: alignItems === 'baseline',
                        [ItemsStretch]:
                            alignItems === 'stretch' || (alignItems == null && vertical),
                        [JustifyBetween]: justifyContent === 'between',
                        [JustifyAround]: justifyContent === 'around',
                        [JustifyEnd]: justifyContent === 'end',
                        [JustifyCenter]: justifyContent === 'center',
                        [SpacingStyle(spacing!)]: !!spacing,
                    },
                    lineBetweenColor &&
                        vertical &&
                        css`
                            > *:not(:last-child) {
                                border-bottom: 1px solid ${ColorsEnum[lineBetweenColor]} !important;
                            }
                        `,
                    lineBetweenColor &&
                        !vertical &&
                        css`
                            > *:not(:last-child) {
                                border-inline-end: 1px solid ${ColorsEnum[lineBetweenColor]} !important;
                            }
                        `,
                    minWidth &&
                        css`
                            min-width: ${minWidth};
                        `,
                    width &&
                        css`
                            width: ${width};
                        `,
                ),
            },
            true,
        );

        return (
            <div data-test-id={toTestIdFormat(this.props.testId)} {...attributes}>
                {children}
            </div>
        );
    }
}
