import { injectable } from 'inversify';
import type { IPiaDetector } from 'app/core/pia';
import type { IPersistence, IRadarItemEntity } from 'app/core/persistence';
import type { IInstallationReportRadarDetectorDevice } from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum } from 'app/modules/common';

@injectable()
export class RadarDetectorDeviceService {
    constructor(private baseDeviceService: BaseDeviceService) {}

    public async mapDevice(
        item: IPersistence<IRadarItemEntity>,
        piaItem: IPiaDetector,
    ): Promise<IInstallationReportRadarDetectorDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.RadarDetector,
        };
    }
}
