import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IBodyWornCameraProfile, Id } from 'app/core/persistence';
import type { WearableSceneId } from 'app/modules/common';
import {
    getWearableProfile,
    getWearableScenariosRecord,
    getWearableSceneModels,
    ScenarioEditorComponent,
} from 'app/modules/common';

import { ItemService } from 'app/core/persistence';

import { ServiceLocator } from 'app/ioc';

interface ISceneSelectorProps {
    deviceId: Id;
}

export const SceneSelector: React.FC<ISceneSelectorProps> = ({ deviceId }) => {
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));
    const scenes = getWearableSceneModels();
    const profile = useSelector<IStoreState, IBodyWornCameraProfile | undefined>((state) =>
        getWearableProfile(state, deviceId),
    );
    if (!profile) {
        return null;
    }

    const onScenarioIdOverride = (id: WearableSceneId) => {
        itemService.updateWearableProfile(deviceId, { sceneId: id });
    };

    const scene = getWearableScenariosRecord()[profile.sceneId];
    return (
        <ScenarioEditorComponent
            scenarios={scenes}
            scenario={scene}
            onScenarioIdChange={onScenarioIdOverride}
        />
    );
};
