import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';

interface IOpticalZoomFilterProps {
    selectedOpticalZoom?: string;
    onClick(opticalZoom: string): void;
}

export const OpticalZoomFilter: React.FC<IOpticalZoomFilterProps> = ({
    selectedOpticalZoom,
    onClick,
}) => {
    return (
        <DropDown
            trigger={
                <PillButton
                    icon="keyboard_arrow_down"
                    selected={!!selectedOpticalZoom}
                    text={`${t.advancedFiltersGROUP.opticalZoom}${
                        selectedOpticalZoom ? `: ${selectedOpticalZoom}` : ''
                    }`}
                />
            }
            contents={
                <>
                    <DropDownMenuButton
                        label="- 15x"
                        selected={selectedOpticalZoom === '-15x'}
                        onClick={() => onClick('-15x')}
                    />
                    <DropDownMenuButton
                        label="16-24x"
                        selected={selectedOpticalZoom === '16-24x'}
                        onClick={() => onClick('16-24x')}
                    />
                    <DropDownMenuButton
                        label="25x -"
                        selected={selectedOpticalZoom === '25x-'}
                        onClick={() => onClick('25x-')}
                    />
                </>
            }
        />
    );
};

OpticalZoomFilter.displayName = 'OpticalZoomFilter';
