import type { IPiaPartnerItem, PiaId } from 'app/core/pia';
import type { IApplicationItem } from 'app/modules/common';
import {
    toCacheKey,
    getPiaIdFromProps,
    PARTNER_PRODUCT_TYPE_ALL,
    getPiaItemsRecord,
} from 'app/modules/common';

import type { IStoreState } from 'app/store';
import { nameComparator } from 'app/utils';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import type { IPartnerProductFilter } from '../models';
import { getPartnerProducts } from './getPartnerProductsCommon';
import { getPartnerFilter } from './getPartnerFilter';
import { getSelectedProductTypePartnerProducts } from './getSelectedProductTypePartnerProducts';
import { PartnerProductFilter } from '../filters';
import {
    getCompatiblePartnerProductsForPiaItem,
    getFilteredPartnerItemsWithBypass,
    toIApplicationItem,
} from '../utils';

export const getPartnerProductsLoading = (state: IStoreState) =>
    state.addonSelector.loadingPartnerProducts;
export const getPartnerProductsNextKey = (state: IStoreState) =>
    state.addonSelector.partnerProducts.next;
const PARTNER_PRODUCT_TYPE: keyof IPartnerProductFilter = 'partnerProductType';

export const getPartnerProductTypeAmounts = createSelector(
    [getPartnerProducts, getPartnerFilter, getPiaItemsRecord],
    (partnerProducts, filter, piaItemsRecord) => {
        const filteredProducts = getFilteredPartnerItemsWithBypass(
            partnerProducts,
            filter,
            PARTNER_PRODUCT_TYPE,
            piaItemsRecord,
        );

        const typeAmounts = filteredProducts.reduce(
            (productTypeAmounts, product) => {
                product.properties.partnerProductType?.forEach((productType) => {
                    productTypeAmounts[productType] = (productTypeAmounts[productType] || 0) + 1;
                });
                return productTypeAmounts;
            },
            {} as Record<string, number>,
        );
        typeAmounts[PARTNER_PRODUCT_TYPE_ALL] = filteredProducts.length;
        return typeAmounts;
    },
);

/** Gets all filtered partner products (from selected Category) */
const getFilteredPartnerProducts = createSelector(
    [getSelectedProductTypePartnerProducts, getPartnerFilter, getPiaItemsRecord],
    (partnerProducts, filter, piaItemsRecord) => {
        const compatibleProducts = filter.compatibleWithDevicePiaId
            ? getCompatiblePartnerProductsForPiaItem(
                  partnerProducts,
                  piaItemsRecord[filter.compatibleWithDevicePiaId],
              )
            : partnerProducts;
        return PartnerProductFilter.filter(compatibleProducts, filter);
    },
);

/** Gets selected sort order */
const getPartnerProductSortOrder = (state: IStoreState) =>
    state.addonSelector.partnerProductFilter.sortOrder;

const sortByVendor = (a: IPiaPartnerItem, b: IPiaPartnerItem) =>
    a.properties.vendor.toLowerCase().localeCompare(b.properties.vendor.toLowerCase());

/** get partnerApplications as IPiaPartnerApplicationItem[] */
export const getSortedPartnerProducts = createSelector(
    [getFilteredPartnerProducts, getPartnerProductSortOrder],
    (partnerProducts, sortOrder) => {
        switch (sortOrder) {
            case 'byPopularity':
                partnerProducts.sort(
                    (a, b) =>
                        b.properties.numberOfInstallationsWithAxis -
                            a.properties.numberOfInstallationsWithAxis || sortByVendor(a, b),
                );
                break;
            case 'byVendor':
                partnerProducts.sort(sortByVendor);
                break;
            default:
                partnerProducts.sort(nameComparator);
                break;
        }
        return [...partnerProducts];
    },
);

/** get partnerApplications as IApplicationItem[] */
export const getSortedPartnerProductsAsIApplicationItem = createSelector(
    [getSortedPartnerProducts],
    (partnerProducts): IApplicationItem[] => partnerProducts.map(toIApplicationItem),
);

/** Gets a record of selected category products */
const getPartnerItemsRecord = createSelector(
    [getSelectedProductTypePartnerProducts],
    (partnerItems): Record<PiaId, IPiaPartnerItem> =>
        partnerItems.reduce((partnerItemsRecord, currentItem) => {
            return { ...partnerItemsRecord, [currentItem.id]: currentItem };
        }, {}),
);

/** Get partner item with id */
export const getPartnerItem = createCachedSelector(
    [getPartnerItemsRecord, getPiaIdFromProps],
    (partnerProductRecord, piaId) => {
        return piaId ? partnerProductRecord[piaId] : undefined;
    },
)(toCacheKey);
