import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { useService } from 'app/ioc';
import { UserConfirmationModal, Eula, Welcome } from '../components';
import { ApplicationActionService } from '../services';
import {
    CommonActionService,
    getIsStandalone,
    getUserSignedIn,
    GoogleMapsService,
} from 'app/modules/common';
import { AppLoaderComponent } from './AppLoader.component';
import { OfflineService } from 'app/core/persistence';
import type { IMigrationState, IPiaState } from './models';
import { CONSTANTS } from '../constants';
import type { IWithChildren } from 'app/components';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'app/hooks';
import { getUrlPartnerId } from 'app/partnerUrl';

interface IAppLoaderContainerProps extends IWithChildren {
    piaState: IPiaState;
    migrationState: IMigrationState;
    showEula: boolean;
    showWelcome: boolean;
    msrpReady: boolean;
    userLoaded: boolean;
    isStandalone: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IWithChildren,
): IAppLoaderContainerProps => {
    const isStandalone = getIsStandalone(storeState);

    return {
        piaState: storeState.app.piaState,
        migrationState: storeState.app.migrationState,
        showEula: !isStandalone && storeState.app.eulaAcceptedVersion < CONSTANTS.eulaVersion,
        showWelcome: !isStandalone && !storeState.app.welcomeFinished,
        msrpReady:
            isStandalone ||
            (storeState.common.msrpAuthStatusLoaded &&
                storeState.common.msrpLoaded &&
                storeState.common.distributorsLoaded),
        userLoaded: isStandalone || storeState.common.user.loaded,
        isStandalone,
        children: ownProps.children,
    };
};

const AppLoaderContainer: React.FC<IAppLoaderContainerProps> = ({
    piaState,
    migrationState,
    showEula,
    showWelcome,
    msrpReady,
    userLoaded,
    isStandalone,
    children,
}) => {
    const applicationActionService = useService(ApplicationActionService);
    const commonActionService = useService(CommonActionService);
    const offlineService = useService(OfflineService);
    const navigate = useNavigate();
    const isSignedIn = useSelector(getUserSignedIn);

    const registerNetworkEventListeners = React.useCallback(() => {
        offlineService.on('online', () => applicationActionService.setUserOnline());
        offlineService.on('offline', () => applicationActionService.setUserOffline());
    }, [applicationActionService, offlineService]);

    const removeNetworkEventListeners = React.useCallback(() => {
        offlineService.off('online', () => applicationActionService.setUserOnline());
        offlineService.off('offline', () => applicationActionService.setUserOffline());
    }, [applicationActionService, offlineService]);

    useMount(() => {
        // On mount
        applicationActionService.isPiaAndStorageReady();
        if (!isStandalone) {
            GoogleMapsService.loadGoogleMaps();
        }
        applicationActionService.getUserPreferences();
        applicationActionService.getUserFinishedWelcome();
        commonActionService.getUser();
        sessionStorage.setItem('partnerId', getUrlPartnerId() ?? '');

        registerNetworkEventListeners();

        return () => {
            // On unmount
            removeNetworkEventListeners();
        };
    });

    // Update user image quota when user signs in or out.
    React.useEffect(() => {
        commonActionService.getUserImageQuota();
    }, [commonActionService, isSignedIn]);

    // Get couchDB info (filesize, tombstones etc.)
    // We need to wait for migration or we will get an error for new users without couchDB.
    // We check for compact status in InitializeUserDataStorage.service to keep lambda invocations at a minimum.
    React.useEffect(() => {
        commonActionService.getCouchDBInfo();
    }, [commonActionService, migrationState.finished]);

    // Update partner config when user has loaded.
    React.useEffect(() => {
        commonActionService.getPartnerConfig();
    }, [commonActionService, userLoaded]);

    const onContinueClicked = React.useCallback(() => {
        applicationActionService.onUserAcceptedEula(navigate);
    }, [applicationActionService, navigate]);

    if (showEula) {
        return <Eula onContinueClicked={onContinueClicked} />;
    }

    return (
        <AppLoaderComponent
            key="0"
            piaState={piaState}
            migrationState={migrationState}
            msrpReady={msrpReady}
            userLoaded={userLoaded}
        >
            {children}
            <UserConfirmationModal key="1" />
            {showWelcome && (
                <Welcome key="2" onFinished={applicationActionService.onUserFinishedWelcome} />
            )}
        </AppLoaderComponent>
    );
};

export const AppLoader = connect(mapStateToProps)(AppLoaderContainer);
