import * as React from 'react';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type {
    Id,
    IProfileEntity,
    IPersistence,
    IRecordingSettingsModel,
} from 'app/core/persistence';
import { ProfileService, Time24, mapToRecordingSettingsEntity } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import type { IScheduleSelectorModel } from 'app/modules/common';
import {
    getIsProjectProfileValidForRecordingSolution,
    ScheduledRecordingEditorComponent,
    ScheduledRecordingTypes,
    getProfile,
    getHasInconsistentRecordingQuality,
    getProfileRecordingSettings,
    getScheduleModels,
} from 'app/modules/common';

interface IScheduledRecordingEditorOwnProps {
    profileId: Id;
    recordingType: ScheduledRecordingTypes;
}

interface IScheduledRecordingEditorProps extends IScheduledRecordingEditorOwnProps {
    profile?: IPersistence<IProfileEntity>;
    scheduleSelector?: IScheduleSelectorModel;
    recordingSettings?: IRecordingSettingsModel;
    hasInconsistentRecordingQuality: boolean;
    isValidForRecordingSolution: boolean;
}
interface IScheduledRecordingEditorState {
    recordingSceneExpanded: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IScheduledRecordingEditorOwnProps,
): IScheduledRecordingEditorProps => {
    const profile = getProfile(storeState, ownProps.profileId);
    const isValidForRecordingSolution = getIsProjectProfileValidForRecordingSolution(
        storeState,
        ownProps.profileId,
    );
    const recordingSettings = getProfileRecordingSettings(
        storeState,
        ownProps.profileId,
        ownProps.recordingType,
    );
    const schedules = getScheduleModels(storeState);
    const selectedSchedule = schedules.find(
        (schedule) => schedule.id === recordingSettings?.schedule,
    );
    const scheduleSelector = profile
        ? {
              lightStart: new Time24(profile.scenario.lightStart),
              lightEnd: new Time24(profile.scenario.lightEnd),
              nightLighting: profile.scenario.nightLighting,
              schedules,
              selectedSchedule,
          }
        : undefined;
    const hasInconsistentRecordingQuality = getHasInconsistentRecordingQuality(
        storeState,
        ownProps.profileId,
        ownProps.recordingType,
    );

    return {
        profile,
        scheduleSelector,
        recordingSettings,
        hasInconsistentRecordingQuality,
        isValidForRecordingSolution,
        ...ownProps,
    };
};

class ScheduledRecordingEditorContainer extends React.Component<
    IScheduledRecordingEditorProps,
    IScheduledRecordingEditorState
> {
    private profileService: ProfileService;

    constructor(props: IScheduledRecordingEditorProps) {
        super(props);
        this.state = {
            recordingSceneExpanded: false,
        };
        this.profileService = ServiceLocator.get(ProfileService);
    }

    public render() {
        const {
            profile,
            recordingType,
            scheduleSelector,
            recordingSettings,
            hasInconsistentRecordingQuality,
            isValidForRecordingSolution,
        } = this.props;
        return (
            <>
                {scheduleSelector && recordingSettings && profile && (
                    <ScheduledRecordingEditorComponent
                        heading={this.getRecordingTypeHeader(recordingType)}
                        expanded={this.state.recordingSceneExpanded}
                        scheduleSelector={scheduleSelector}
                        recordingSettings={recordingSettings}
                        onScheduleChange={this.onScheduleChange}
                        onRecordingSettingsChange={this.onRecordingSettingsChange}
                        onToggleRecordingSettings={this.onToggleRecordingSettings}
                        inconsistentRecordingQuality={hasInconsistentRecordingQuality}
                        liveView={this.props.recordingType === ScheduledRecordingTypes.liveView}
                        useProjectSetting={profile.zipstream.useProjectSetting}
                        showRecordingSolutionWarning={
                            !isValidForRecordingSolution &&
                            recordingType === ScheduledRecordingTypes.continuous
                        }
                    />
                )}
            </>
        );
    }

    private getRecordingTypeHeader(recordingType: ScheduledRecordingTypes) {
        switch (recordingType) {
            case ScheduledRecordingTypes.continuous:
                return t.profilesScheduledRecordingContinuousRecording;
            case ScheduledRecordingTypes.triggered:
                return t.profilesScheduledRecordingTriggeredRecording;
            case ScheduledRecordingTypes.liveView:
                return t.profilesScheduledRecordingLiveView;
            default:
                throw new Error(`Unknown recording type: ${recordingType}`);
        }
    }

    private onScheduleChange = (scheduleId: Id | null) => {
        if (this.props.profile) {
            this.props.profile[this.props.recordingType].schedule = scheduleId;
            return this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onRecordingSettingsChange = (settings: IRecordingSettingsModel) => {
        if (this.props.profile) {
            // Remap to persistable entity
            const recordingEntitySettings = mapToRecordingSettingsEntity(settings);
            this.props.profile[this.props.recordingType] = recordingEntitySettings;
            return this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onToggleRecordingSettings = () => {
        this.setState({ recordingSceneExpanded: !this.state.recordingSceneExpanded });
    };
}

export const ScheduledRecordingEditor = connect(mapStateToProps)(ScheduledRecordingEditorContainer);
