import { getPiaItem, toCacheKey } from 'app/modules/common';
import { createSelector } from 'reselect';
import { getPartnerFilter } from './getPartnerFilter';
import type { IPartnerProductFilter } from '../models';
import { getSelectedProductTypePartnerProducts } from './getSelectedProductTypePartnerProducts';
import { PartnerProductFilter } from '../filters';
import { createCachedSelector } from 're-reselect';
import { getCompatiblePartnerProductsForPiaItem } from '../utils';

/** Gets all partner products ignoring filter for useCase and solutionType i.e filtered on Country*/
const getPartnerProductsFilteredByCountry = createSelector(
    [getSelectedProductTypePartnerProducts, getPartnerFilter],
    (partnerProducts, filter) => {
        const modifiedFilter: IPartnerProductFilter = {
            ...filter,
            useCase: undefined,
            solutionType: undefined,
            searchFilter: '',
        };
        return PartnerProductFilter.filter(partnerProducts, modifiedFilter);
    },
);

/** returns true if compatible application exists for ItemId */
export const getCompatiblePartnerProductExistsForItemId = createCachedSelector(
    [getPartnerProductsFilteredByCountry, getPiaItem],
    (partnerProducts, piaItem): boolean => {
        const compatiblePartnerProducts = getCompatiblePartnerProductsForPiaItem(
            partnerProducts,
            piaItem,
        );
        return compatiblePartnerProducts.length > 0;
    },
)(toCacheKey);
