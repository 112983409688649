import * as React from 'react';
import { css, cx } from '@emotion/css';
import { ColorsEnum, colorToRgba } from 'app/styles';

interface IRequirementBarProps {
    /** Use to force error color */
    warn?: boolean;
    /**
     * The current value
     */
    value: number;
    /**
     * The required value to be satisfied
     */
    requiredValue: number;
    /**
     * The margin above the required value to show.
     * Expressed in percentage 0 - 100.
     * Defaults to 20, which means the total bar will show
     * up to 120% of the required value.
     */
    margin?: number;
    disabled?: boolean;
}

const containerStyle = css`
    width: 90px;
    position: relative;
    height: 24px;
    display: flex;
    align-items: center;
`;

const barStyle = css`
    width: 90px;
    height: 6px;
    background: linear-gradient(90deg, rgba(141, 198, 63, 1) 50%, rgba(141, 198, 63, 0.5) 50%);
`;

const disabledBarStyle = css`
    width: 90px;
    height: 6px;
    background: linear-gradient(90deg, rgba(205, 205, 205, 1) 50%, rgba(205, 205, 205, 0.5) 50%);
`;

const requiredIndicatorStyle = css`
    box-sizing: border-box;
    border-right: 1px solid ${ColorsEnum.grey3};
    width: 50%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
`;

const baseIndicatorStyle = css`
    box-sizing: border-box;
    border-right: 1px solid ${ColorsEnum.grey2};
    width: 1px;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
`;

export const RequirementBar: React.FunctionComponent<IRequirementBarProps> = ({
    value,
    requiredValue,
    warn,
    margin = 20,
    disabled,
}) => {
    const meetsRequirement = value >= requiredValue;
    const barColor = meetsRequirement && warn !== true ? ColorsEnum.green : ColorsEnum.red;
    const backgroundColor = meetsRequirement
        ? colorToRgba(warn ? ColorsEnum.red : ColorsEnum.green, 0.5)
        : ColorsEnum.transparent;
    const maxValue = requiredValue * ((100 + margin) / 100);
    const percentageOfTotal = (Math.min(value, requiredValue) / maxValue) * 100;
    const overFlowPercentage = (value / maxValue) * 100;
    const currentBarValueStyle = css`
        background: linear-gradient(
            90deg,
            ${barColor} ${Math.max(percentageOfTotal, 3)}%,
            ${backgroundColor} ${Math.max(percentageOfTotal, 3)}%,
            ${backgroundColor} ${overFlowPercentage}%,
            ${ColorsEnum.white} ${overFlowPercentage}%
        );
        [dir='rtl'] & {
            rotate: 180deg;
        }
    `;
    const currentIndicatorStyle = css`
        width: ${(requiredValue / maxValue) * 100}%;
        [dir='rtl'] & {
            rotate: 180deg;
        }
    `;
    return (
        <div className={containerStyle}>
            <div className={cx(disabled ? disabledBarStyle : barStyle, currentBarValueStyle)} />
            <div className={cx(requiredIndicatorStyle, currentIndicatorStyle)} />
            <div className={baseIndicatorStyle} />
        </div>
    );
};

RequirementBar.displayName = 'RequirementBar';
