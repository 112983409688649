import { AXIS_PERIMETER_DEFENDER } from 'app/core/common';
import { isCamera } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import type { IApplicationItem } from '../application/IApplicationItem';

export const isValidAcap = (
    acap: IApplicationItem,
    rangeApplicationIds: number[],
    blockedApplicationIds: number[],
) =>
    acap.acapId &&
    rangeApplicationIds.includes(acap.acapId) &&
    !blockedApplicationIds.includes(acap.productId)
        ? true
        : false;

export const isApd = (acapId: number) => {
    return acapId === AXIS_PERIMETER_DEFENDER;
};

export const hasApdParams = (piaDevice?: IPiaItem) => {
    if (isCamera(piaDevice)) {
        return (piaDevice.properties.apdMinObjectSizeHumans &&
            piaDevice.properties.apdMinObjectSizeVehicles) ||
            (piaDevice.properties.apdAiMinObjectSizeHumans &&
                piaDevice.properties.apdAiMinObjectSizeVehicles)
            ? true
            : false;
    }
    return false;
};

export const hasApdParamsBoth = (piaDevice?: IPiaItem) => {
    if (isCamera(piaDevice)) {
        return piaDevice.properties.apdMinObjectSizeHumans &&
            piaDevice.properties.apdMinObjectSizeVehicles &&
            piaDevice.properties.apdAiMinObjectSizeHumans &&
            piaDevice.properties.apdAiMinObjectSizeVehicles
            ? true
            : false;
    }
    return false;
};
