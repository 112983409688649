import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    Action,
    Border,
    Box,
    Card,
    Checkbox,
    ColorPicker,
    Heading,
    Icon,
    Spacer,
    Stack,
    TextInput,
    Text,
    IconButton,
    Label,
    Spinner,
    Button,
    ToggleOnClick,
    UploadFile,
} from 'app/components';
import { copyToClipboard } from '../../common';
import type { IPartnerHeaderStyle } from 'app/core/persistence';
import { PartnerConfigService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { PartnerConfigActionService } from '../services';
import type { IPartnerConfigState } from '../models';
import { toaster } from 'app/toaster';
import type { IPiaItem } from 'app/core/pia';
import { DeviceItem } from './DeviceItem.component';
import { getFilteredPartnerProductsByCategory } from '../selectors/getDevices';
import { Logo } from './Logo.component';
import { EditProductsModal } from './EditProductsModal';
import { EditAccessoriesButton } from './EditAccessoriesButton';
import { ThemePreview } from './ThemePreview';
import {
    convertToPartnerConfigServerResponse,
    convertToPartnerConfigState,
} from '../services/convert';
import { AppConstants } from 'app/AppConstants';
import { usePromise } from 'app/hooks';

const partnerUrl = 'https://sitedesigner.axis.com/partner/';
const colorRegExp = new RegExp(/^#[0-9A-F]{6}$/i);
const colorMaxLength = 7;
const messageMaxLength = 500;

export const PartnerConfig: React.FC = () => {
    const [actions] = React.useState(ServiceLocator.get(PartnerConfigActionService));
    const [partnerConfigService] = React.useState(ServiceLocator.get(PartnerConfigService));
    const [foregroundError, setForegroundError] = React.useState(false);
    const [backgroundError, setBackgroundError] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const locId = useSelector<IStoreState, number | undefined>(
        (state) => state.common.user.user?.locid,
    );
    const style = useSelector<IStoreState, IPartnerHeaderStyle | undefined>(
        (state) => state.partnerConfig.style,
    );
    const partnerConfig = useSelector<IStoreState, IPartnerConfigState>(
        (state) => state.partnerConfig,
    );
    const selectedProducts = useSelector<IStoreState, Record<string, IPiaItem[]>>(
        getFilteredPartnerProductsByCategory,
    );
    const {
        result: downloadedPartnerConfig,
        error: loadFail,
        pending: loading,
    } = usePromise(partnerConfigService.getPartnerConfig(locId, true), []);

    React.useEffect(() => {
        if (loadFail) {
            toaster.error(t.partnerConfigurationErrorLoadingShort);
        }
    }, [loadFail]);

    React.useEffect(() => {
        if (downloadedPartnerConfig) {
            actions.setPartnerConfig(convertToPartnerConfigState(downloadedPartnerConfig));
        }
    }, [actions, downloadedPartnerConfig]);

    const setBackgroundColor = (color: string | undefined) => {
        if (color === undefined || color === '' || colorRegExp.test(color)) {
            setBackgroundError(false);
            actions.setStyle({
                backgroundColor: color,
            });
        } else {
            setBackgroundError(true);
        }
    };

    const setForegroundColor = (color: string | undefined) => {
        if (color === undefined || color === '' || colorRegExp.test(color)) {
            setForegroundError(false);
            actions.setStyle({
                foregroundColor: color,
            });
        } else {
            setForegroundError(true);
        }
    };

    const removeTheme = () => {
        actions.setStyle({
            foregroundColor: undefined,
            backgroundColor: undefined,
        });
    };

    const setSelectedProductsSearchText = (text?: string) => {
        actions.setSelectedProductsSearchText(text ?? '');
    };

    const savePartnerConfig = () => {
        if (partnerConfig.partnerConfigId) {
            partnerConfigService.savePartnerConfigFile(
                convertToPartnerConfigServerResponse(partnerConfig),
            );
        } else {
            toaster.error(t.downloadDocumentErrorTitle);
        }
    };

    const loadPartnerConfig = async (file: File) => {
        try {
            const partnerConfigState = await partnerConfigService.readPartnerConfigFile(file);
            actions.setPartnerConfig(convertToPartnerConfigState(partnerConfigState));
            toaster.success(t.partnerConfigLoadSuccess);
        } catch {
            toaster.error(t.uploadFailedHeader);
        }
    };

    const renderPartnerConfig = () => (
        <>
            {showModal && <EditProductsModal setShowModal={setShowModal} />}
            <Box width="100%" justifyContent="center">
                <Box maxWidth="1000px" width="100%">
                    <Stack vertical>
                        <Box paddingX="half" width="100%">
                            <Stack justifyContent="between" alignItems="center" flex="fullWidth">
                                <Heading style="title">{t.yourOrganization}</Heading>
                                <Stack>
                                    <UploadFile
                                        accept={'application/json'}
                                        onFileUploaded={loadPartnerConfig}
                                    >
                                        <Button>{t.partnerConfigLoad}</Button>
                                    </UploadFile>
                                    <Button onClick={savePartnerConfig} primary>
                                        {t.partnerConfigDownload}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>

                        <Card paddingX="base" paddingY="base">
                            <Stack vertical spacing="doublePanel">
                                <Stack alignItems="start">
                                    <Logo
                                        urlEncodedLogo={partnerConfig.logo}
                                        setLogo={actions.setLogo}
                                    />
                                    <Box flex="grow" maxWidth="500px">
                                        <Stack vertical notFullWidth spacing="halfQuart">
                                            <TextInput
                                                label={t.organizationName}
                                                value={partnerConfig.companyName}
                                                onChange={actions.setName}
                                                maxLength={AppConstants.projectNameMaxLength}
                                            />
                                            <Text italic color="grey6">
                                                {t.partnerConfigCompanyNameDescription}
                                            </Text>
                                        </Stack>
                                    </Box>
                                </Stack>
                                <Stack vertical>
                                    <Text style="caption">{t.theme}</Text>
                                    <Stack>
                                        <ColorPicker
                                            color={style?.backgroundColor}
                                            onColorChange={setBackgroundColor}
                                        />
                                        <TextInput
                                            label={t.backgroundColor}
                                            value={style?.backgroundColor ?? ''}
                                            onChange={setBackgroundColor}
                                            error={backgroundError}
                                            maxLength={colorMaxLength}
                                        />
                                        <Spacer />
                                        <ColorPicker
                                            color={style?.foregroundColor}
                                            onColorChange={setForegroundColor}
                                        />
                                        <TextInput
                                            label={t.foregroundColor}
                                            value={style?.foregroundColor ?? ''}
                                            onChange={setForegroundColor}
                                            error={foregroundError}
                                            maxLength={colorMaxLength}
                                        />
                                    </Stack>
                                    {style?.foregroundColor && style?.backgroundColor && (
                                        <>
                                            <Box>
                                                <IconButton
                                                    icon="delete"
                                                    text={t.removeTheme}
                                                    onClick={removeTheme}
                                                />
                                            </Box>
                                            <ThemePreview />
                                        </>
                                    )}
                                </Stack>

                                <Stack vertical>
                                    <Text style="caption">{t.configuration}</Text>
                                    <Checkbox
                                        justifyLeft
                                        opaque
                                        slider
                                        selected={partnerConfig.isPartnerConfigEnabled}
                                        onChange={actions.toggleIsPartnerConfigEnabled}
                                        description={
                                            <Text italic color="grey6">
                                                {t.partnerConfigEnableLocIdAccessInfo}
                                            </Text>
                                        }
                                    >
                                        {t.partnerConfigEnableLocIdAccessDescription}
                                    </Checkbox>
                                    <Checkbox
                                        justifyLeft
                                        opaque
                                        slider
                                        selected={partnerConfig.isPartnerConfigUrlEnabled}
                                        onChange={actions.toggleIsPartnerConfigUrlEnabled}
                                        description={
                                            <Stack vertical spacing="halfQuart">
                                                <Box userSelect>
                                                    <Text
                                                        semiBold
                                                        color={
                                                            partnerConfig.isPartnerConfigUrlEnabled
                                                                ? undefined
                                                                : 'grey4'
                                                        }
                                                    >{`${t.url}: ${partnerUrl}${
                                                        partnerConfig.partnerConfigId ?? ''
                                                    }`}</Text>
                                                </Box>
                                                <Action
                                                    disabled={
                                                        !partnerConfig.isPartnerConfigUrlEnabled
                                                    }
                                                    title={t.clipboardCopyToClipboard}
                                                    onClick={() =>
                                                        copyToClipboard(
                                                            `${partnerUrl}${
                                                                partnerConfig.partnerConfigId ?? ''
                                                            }`,
                                                        )
                                                    }
                                                />
                                            </Stack>
                                        }
                                    >
                                        {t.partnerConfigEnablePublicUrlAccessDescription}
                                    </Checkbox>
                                    <Checkbox
                                        justifyLeft
                                        opaque
                                        slider
                                        selected={partnerConfig.isOnlySelectedProductsAvailable}
                                        onChange={actions.toggleIsOnlyRecommendedProductsAvailable}
                                        description={
                                            <Stack vertical spacing="halfQuart">
                                                <Text italic color="grey6">
                                                    {t.allowlistDescription}
                                                </Text>
                                                <TextInput
                                                    label={
                                                        t.partnerConfigExcludedProductsMessageLabel
                                                    }
                                                    maxLength={messageMaxLength}
                                                    value={partnerConfig.excludedProductMessage}
                                                    onChange={actions.setExcludedProductMessage}
                                                />
                                            </Stack>
                                        }
                                    >
                                        {t.partnerConfigEnableLimitedDeviceList}
                                    </Checkbox>
                                    <Checkbox
                                        justifyLeft
                                        opaque
                                        slider
                                        selected={partnerConfig.isCustomPartNumberEnabled}
                                        onChange={actions.toggleIsCustomPartNumberEnabled}
                                        description={
                                            <Text italic color="grey6">
                                                {t.partnerConfigEnableCustomPartNumberDescription}
                                            </Text>
                                        }
                                    >
                                        {t.partnerConfigEnableCustomPartNumber}
                                    </Checkbox>
                                </Stack>
                            </Stack>
                        </Card>

                        <Box paddingX="half" width="100%">
                            <Stack justifyContent="between" alignItems="center" flex="fullWidth">
                                <Heading style="title">{t.selectedProducts}</Heading>
                                <IconButton
                                    icon="edit"
                                    text={t.edit}
                                    onClick={() => setShowModal(!showModal)}
                                />
                            </Stack>
                        </Box>
                        <Card paddingX="base" paddingY="base">
                            <Stack vertical>
                                <Box width="100%">
                                    <Border bottomWidth={2} color="black">
                                        <Box width="100%">
                                            <Stack flex="fullWidth" spacing="half">
                                                <Icon opaque icon="search" color="black" />
                                                <Box display="block" width="100%">
                                                    <Text inline style="subheader">
                                                        <TextInput
                                                            noBorder
                                                            grow
                                                            clear={setSelectedProductsSearchText}
                                                            changeCriteria="debounced"
                                                            value={
                                                                partnerConfig.selectedProductsSearchText
                                                            }
                                                            onChange={setSelectedProductsSearchText}
                                                        />
                                                    </Text>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Border>
                                </Box>
                                {Object.keys(selectedProducts).map((category) => (
                                    <Box key={category} direction="column" width="100%">
                                        <Box paddingTop="half" paddingBottom="base">
                                            <Heading>{category}</Heading>
                                        </Box>
                                        <Stack vertical lineBetweenColor="grey2" spacing="none">
                                            {selectedProducts[category].length === 0 &&
                                                partnerConfig.selectedProductsSearchText && (
                                                    <Text italic color="grey7">
                                                        {t.noSearchResultsFor(
                                                            partnerConfig.selectedProductsSearchText,
                                                        )}
                                                    </Text>
                                                )}
                                            {selectedProducts[category].map((piaItem: IPiaItem) => (
                                                <DeviceItem key={piaItem.id} piaId={piaItem.id}>
                                                    <Stack alignItems="center">
                                                        {partnerConfig.isCustomPartNumberEnabled && (
                                                            <>
                                                                <Label>{t.partNumber}:</Label>
                                                                <TextInput
                                                                    changeCriteria="blur"
                                                                    value={
                                                                        partnerConfig
                                                                            .mappedPartNumbers[
                                                                            piaItem.id
                                                                        ] ?? ''
                                                                    }
                                                                    onChange={(partNumber) =>
                                                                        actions.setCustomPartNumber(
                                                                            {
                                                                                piaId: piaItem.id,
                                                                                customPartNumber:
                                                                                    partNumber,
                                                                            },
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        <Box minWidth="120px">
                                                            <Stack vertical alignItems="start">
                                                                <EditAccessoriesButton
                                                                    piaItem={piaItem}
                                                                />
                                                                <ToggleOnClick>
                                                                    {(hasBeenClicked: boolean) =>
                                                                        !hasBeenClicked ? (
                                                                            <Box>
                                                                                <IconButton
                                                                                    icon="delete"
                                                                                    text={t.remove}
                                                                                />
                                                                            </Box>
                                                                        ) : (
                                                                            <IconButton
                                                                                icon="delete_forever"
                                                                                color="red"
                                                                                text={
                                                                                    t.confirmDelete
                                                                                }
                                                                                onClick={() =>
                                                                                    actions.removeFromAllowList(
                                                                                        piaItem.id,
                                                                                    )
                                                                                }
                                                                            />
                                                                        )
                                                                    }
                                                                </ToggleOnClick>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                </DeviceItem>
                                            ))}
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Card>
                    </Stack>
                </Box>
            </Box>
        </>
    );

    return loading ? (
        <Stack justifyContent="center" alignItems="center" flex="fullWidth">
            <Spinner size="30px" />
            <Text>{t.loading}</Text>
        </Stack>
    ) : (
        renderPartnerConfig()
    );
};

PartnerConfig.displayName = 'PartnerConfig';
