import type { IPiaRadarDetector } from 'app/core/pia';
import { PiaItemDetectorCategory } from 'app/core/pia';
import { createSelector } from 'reselect';
import { getSelectedFloorPlansInstallationPoints } from './getSelectedFloorPlansInstallationPoints';
import type leaflet from 'leaflet';
import type { IStoreState } from 'app/store';
import { getCoexistingInstallationPointIds } from '../utils/calculateRadarCoexistence';

const getRadarsWithCoexistenceFromSelectedFloorPlan = createSelector(
    [getSelectedFloorPlansInstallationPoints],
    (installationPoints) =>
        installationPoints.filter((ip) => {
            const piaDevice = ip.parentPiaDevice as IPiaRadarDetector;
            return (
                (ip.radar ||
                    ip.parentPiaDevice?.categories.includes(
                        PiaItemDetectorCategory.RADARDETECTORS,
                    )) &&
                piaDevice &&
                piaDevice.properties.radarCoexistenceMax &&
                piaDevice.properties.radarCoexistenceRadius
            );
        }),
);

const getMapFromProps = (_state: IStoreState, map: leaflet.Map) => map;

export const getRadarsWithCoexistenceWarning = createSelector(
    [getRadarsWithCoexistenceFromSelectedFloorPlan, getMapFromProps],
    (installationPoints, map) => getCoexistingInstallationPointIds(installationPoints, map),
);
