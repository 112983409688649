import * as React from 'react';
import type { IAutoTestable } from 'app/components';
import { IconText } from 'app/components';
import type { ISupportedWithMessage } from '../../models';

interface INotSupportedText extends IAutoTestable {
    supportedWithMessage?: ISupportedWithMessage;
}

export class NotSupportedText extends React.Component<INotSupportedText> {
    public render() {
        const { supportedWithMessage } = this.props;

        if (!supportedWithMessage || supportedWithMessage.supported) {
            return null;
        }

        return (
            <IconText
                testId={`${this.props.testId}_not_supported_text`}
                icon="warning_small"
                align="start"
                iconProps={{ color: 'yellow7', size: 'sm', opaque: true }}
            >
                {supportedWithMessage.message}
            </IconText>
        );
    }
}
