import type { IPiaItemBaseProperties } from './IPiaItemBaseProperties';

export enum axisLicenseModels {
    acs5 = 'ACS5',
    oneTime = 'One-time',
    subscription = 'Subscription',
    tiedToHardware = 'Tied-to-hardware',
}

export interface IPiaSoftwareProperties extends IPiaItemBaseProperties {
    /**
     * Determines the system products for which the license is valid.
     * Examples: `Device` if the license is required per product, `Device-locked` if the license is locked to a specific serial number, or "System" if the license is valid for an entire system.
     */
    licenseType: string;

    /**
     * Applicable for Axis licenses.
     * Determines the license model for which the license is valid.
     * Values: 'ACS5', 'One-time', 'Subscription', 'Tied-to-hardware'
     */
    licenseModel?: axisLicenseModels;

    /**
     * The product categories for which a device license is valid. `All` if the license is valid for all devices.
     */
    licenseProdCat: string[];

    sortingPriority?: number;

    isELicense?: boolean;
    /**
     * Number set when the license has a recurrent cost, set in months (i.e 1 year is 12, 5 years is 60)
     */
    subscriptionIntervalInMonths?: number;
}
