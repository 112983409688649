import { injectable } from 'inversify';
import { clamp } from 'lodash-es';
import type { IPiaCamera, IPiaCameraProperties, IPiaRadarDetector } from 'app/core/pia';
import { isCameraCategory, PiaItemService, PiaRelationService } from 'app/core/pia';

import type {
    ICameraPropertiesFilterEntity,
    Id,
    IInstallationPointSensor,
    IItemEntity,
    IPersistence,
} from '../userDataPersistence';
import { InstallationPointRepository } from '../userDataPersistence';
import type { ILatLng } from '../models';
import { CurrentProjectService } from './CurrentProject.service';
import { ItemService } from './item';
import { DEFAULT_SPEAKER_PLACEMENT, getDefaultSensors } from '../utils';

@injectable()
export class InstallationPointDeviceService {
    static IP_LABEL_OFFSET = {
        x: 0,
        y: -53,
    };

    constructor(
        private piaCameraService: PiaItemService<IPiaCamera>,
        private currentProjectService: CurrentProjectService,
        private installationPointRepository: InstallationPointRepository,
        private piaRelationService: PiaRelationService,
        private piaRadarService: PiaItemService<IPiaRadarDetector>,
        private itemService: ItemService,
    ) {}

    public async addCameraInstallationPoint(
        item: IItemEntity,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
            parentId?: Id;
            lenses?: IPersistence<IItemEntity>[];
            installationPointName?: string;
        },
    ) {
        // Both cameras and sensor-units end up here since sensor-unit has same filter
        let filter: ICameraPropertiesFilterEntity;
        if (item.properties.camera) {
            filter = item.properties.camera.filter;
        } else if (item.properties.sensorUnit) {
            filter = item.properties.sensorUnit.filter;
        } else {
            throw new Error(`Filter not found for unexpected camera entity type: ${item.type}`);
        }

        const piaDevice = item.productId ? this.piaCameraService.get(item.productId).first() : null;
        const piaDeviceIsCamera = piaDevice && isCameraCategory(piaDevice.category);
        const hasMultipleSensors =
            piaDeviceIsCamera && piaDevice?.properties.panCameraType === 'Multidirectional';
        const imageSensors = piaDevice?.properties.imageSensors ?? 1;
        const nbrImageSensor = hasMultipleSensors ? imageSensors : 1;

        let installationPointSensors = getDefaultSensors(
            filter.horizontalFov,
            filter.panoramaMode,
            filter.distanceToTarget,
            filter.targetHeight,
            filter.corridorFormat,
            piaDevice,
            nbrImageSensor,
        );

        if (piaDevice) {
            // find out if the item has any virtually related items

            const virtualRelatedItems = this.itemService.getVirtualRelatedItems(piaDevice);

            // if product has virtual related items add sensors to the ip
            await Promise.all(
                virtualRelatedItems.map((virtualPiaItem) => {
                    const virtualItemProperties = virtualPiaItem.properties as IPiaCameraProperties;
                    const virtualHasMultipleSensors =
                        virtualItemProperties.panCameraType === 'Multidirectional';
                    const virtualImageSensors = virtualItemProperties.imageSensors ?? 1;
                    const virtualNbrImageSensor = virtualHasMultipleSensors
                        ? virtualImageSensors
                        : 1;
                    const virtualInstallationPointSensors = virtualItemProperties
                        ? getDefaultSensors(
                              filter.horizontalFov,
                              filter.panoramaMode,
                              filter.distanceToTarget,
                              filter.targetHeight,
                              filter.corridorFormat,
                              virtualPiaItem as IPiaCamera,
                              virtualNbrImageSensor,
                          )
                        : null;
                    if (virtualInstallationPointSensors) {
                        virtualInstallationPointSensors.forEach((virtualSensor) => {
                            virtualSensor.sensorId = virtualSensor.sensorId + nbrImageSensor;
                            virtualSensor.isVirtual = true;
                            installationPointSensors.push(virtualSensor);
                        });
                    }
                }),
            );
        }

        //check if camera has replaced lenses and update horizontalFov if needed
        if (options.lenses && options.lenses.length) {
            installationPointSensors = installationPointSensors.map((sensor) => {
                const lensItemEntity = options.lenses?.find((lens) => {
                    // sensorIndex starts at 0 and sensorId starts at 1.
                    const sensorIndex = lens.properties.lens?.sensorIndex ?? 0;
                    return sensorIndex + 1 === sensor.sensorId;
                });

                const relation =
                    item.productId && lensItemEntity?.productId
                        ? this.piaRelationService.getRelationProperties(
                              item.productId,
                              lensItemEntity.productId,
                          )
                        : undefined;

                return {
                    ...sensor,
                    settings: {
                        ...sensor.settings,
                        horizontalFov: clamp(
                            filter.horizontalFov,
                            relation?.horizontalFOV?.min ?? 0,
                            relation?.horizontalFOV?.max ?? 360,
                        ),
                    },
                };
            });
        }

        return this.installationPointRepository.add({
            type: 'installationPoint',
            path: [...item.path],
            location,
            labelOffset: InstallationPointDeviceService.IP_LABEL_OFFSET,
            height: filter.installationHeight,
            sensors: installationPointSensors,
            floorPlanId: options.global ? undefined : mapId,
            mapOrigin: mapId,
            locked: item.locked,
            archived: item.archived,
            parentId: options.parentId,
            name: options.installationPointName ?? '',
            serialNumber: undefined,
        });
    }

    public async addAnalogCameraInstallationPoint(
        item: IItemEntity,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
            parentId?: Id;
            installationPointName?: string;
        },
    ) {
        return this.installationPointRepository.add({
            type: 'installationPoint',
            path: [...item.path],
            location,
            labelOffset: InstallationPointDeviceService.IP_LABEL_OFFSET,
            height: this.currentProjectService.getProjectCustomInstallationHeight(),
            sensors: [this.getSensorForAnalogCamera()],
            floorPlanId: options.global ? undefined : mapId,
            mapOrigin: mapId,
            locked: item.locked,
            archived: item.archived,
            parentId: options.parentId,
            name: options.installationPointName ?? '',
            serialNumber: undefined,
        });
    }

    public async addDoorInstallationPoint(
        item: IItemEntity,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
            parentId?: Id;
            installationPointName?: string;
        },
    ) {
        return this.addDeviceInstallationPoint(item, mapId, location, options);
    }

    public async addIORelayExpansionModulePoint(
        item: IItemEntity,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
            parentId?: Id;
            installationPointName?: string;
        },
    ) {
        return this.addDeviceInstallationPoint(item, mapId, location, options);
    }

    public async addSpeakerInstallationPoint(
        item: IPersistence<IItemEntity>,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
            installationPointName?: string;
        },
    ) {
        let filter;
        if (item.properties.speaker) {
            filter = item.properties.speaker.filter;
        } else {
            throw new Error(`Filter not found for unexpected entity type: ${item.type}`);
        }
        // Update filter to match default behavior of speaker installation points
        if (item.properties.speaker.filter.placement === undefined) {
            this.itemService.updateSpeakerPlacement(item._id, DEFAULT_SPEAKER_PLACEMENT);
        }

        return this.installationPointRepository.add({
            type: 'installationPoint',
            path: [...item.path],
            location,
            labelOffset: InstallationPointDeviceService.IP_LABEL_OFFSET,
            height: filter.installationHeight,
            sensors: [],
            speaker: {
                target: {
                    distance: 3,
                    height: 1,
                    horizontalAngle: 0,
                },
                settings: {
                    basicSolution: filter.basicSolution,
                    outdoor: filter.outdoor,
                },
            },
            floorPlanId: options.global ? undefined : mapId,
            mapOrigin: mapId,
            locked: item.locked,
            archived: item.archived,
            name: options.installationPointName ?? '',
            serialNumber: undefined,
        });
    }

    public async addRadarDetectorInstallationPoint(
        item: IItemEntity,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
            installationPointName?: string;
        },
    ) {
        const piaDevice = item.productId ? this.piaRadarService.get(item.productId).first() : null;

        const radarDetectionRange =
            piaDevice?.properties?.minRadarDetectionRangeHuman &&
            piaDevice?.properties?.maxRadarDetectionRangeHuman &&
            piaDevice?.properties?.minRadarDetectionRangeVehicle &&
            piaDevice?.properties?.maxRadarDetectionRangeVehicle
                ? {
                      vehicle: {
                          min: piaDevice.properties.minRadarDetectionRangeVehicle,
                          max: piaDevice.properties.maxRadarDetectionRangeVehicle,
                      },
                      human: {
                          min: piaDevice.properties.minRadarDetectionRangeHuman,
                          max: piaDevice.properties.maxRadarDetectionRangeHuman,
                      },
                  }
                : undefined;

        return this.installationPointRepository.add({
            type: 'installationPoint',
            path: [...item.path],
            location,
            labelOffset: InstallationPointDeviceService.IP_LABEL_OFFSET,
            height: 3.5,
            sensors: [],
            radar: {
                target: {
                    distance: radarDetectionRange
                        ? Math.max(radarDetectionRange?.vehicle.max, radarDetectionRange?.human.max)
                        : 50,
                    height: 3.5,
                    horizontalAngle: 0,
                },
            },
            floorPlanId: options.global ? undefined : mapId,
            mapOrigin: mapId,
            locked: item.locked,
            archived: item.archived,
            name: options.installationPointName ?? '',
            serialNumber: undefined,
        });
    }

    public async addDeviceInstallationPoint(
        item: IItemEntity,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
            /** Used when the device is a child, parent ID is then the parents item id */
            parentId?: Id;
            installationPointName?: string;
        },
    ) {
        return this.installationPointRepository.add({
            type: 'installationPoint',
            path: [...item.path],
            location,
            labelOffset: InstallationPointDeviceService.IP_LABEL_OFFSET,
            height: this.currentProjectService.getProjectCustomInstallationHeight(),
            sensors: [],
            floorPlanId: options.global ? undefined : mapId,
            mapOrigin: mapId,
            locked: item.locked,
            archived: item.archived,
            parentId: options.parentId,
            name: options.installationPointName ?? '',
            serialNumber: undefined,
        });
    }

    private getSensorForAnalogCamera(): IInstallationPointSensor {
        return {
            parentPiaDeviceId: null,
            sensorId: 1,
            isVirtual: false,
            target: {
                distance: 7,
                height: 2,
                horizontalAngle: 0,
            },
            settings: {
                horizontalFov: 55,
                corridorFormat: false,
            },
        };
    }
}
