import * as React from 'react';

import { Box, Card, Heading, Text } from 'app/components';

import { t } from 'app/translate';

export const MilestoneSolutions: React.FC = () => {
    return (
        <Card
            notFullWidth
            paddingX="base"
            paddingY="base"
            maxWidth="1200px"
            minWidth="900px"
            color="grey1"
        >
            <Box
                flex="evenSpace"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing="base"
            >
                <Heading style="subheader" align="center">
                    {'Axis + Milestone'}
                </Heading>
                <Text style="semibold" align="center" color="grey6">
                    {t.partnerRecordingSolutionDescription('Milestone')}
                </Text>
            </Box>
        </Card>
    );
};
MilestoneSolutions.displayName = 'MilestoneSolutions';
