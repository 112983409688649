import * as React from 'react';
import { css, cx } from '@emotion/css';
import { IconButton } from '../../ui/iconButton/IconButton.component';
import { ColorsEnum } from 'app/styles';
import { debounce } from 'lodash';
import { useMount } from 'app/hooks';
import type { IWithChildren } from 'app/components/models';
import { isPageRTL } from 'app/translate';

type Direction = 'left' | 'right';

const flexGrowStyle = css`
    flex-grow: 1;
`;

const containerStyle = css`
    overflow: hidden;
    position: relative;
    display: flex;
`;

const viewportContainerStyle = css`
    display: flex;
    align-items: center;
    overflow-x: auto;
    touch-action: pan-x;
    // Used to hide the scroll bar
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const viewportStyle = css`
    display: flex;
    padding: 8px 0;
`;

const navButtonRightContainerStyle = css`
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 1;
    transition: opacity 200ms ease-in-out;
    :hover {
        opacity: 100%;
    }
`;

const navBtnRightOverlayStyle = css`
    position: absolute;
    inset-inline-end: 0;
    opacity: 70%;
`;

const navigationPaddingStyle = css`
    padding: 0 40px;
`;

const navButtonBackgroundStyle = css`
    background-color: ${ColorsEnum['whiteOpacity']};
    border-radius: 50%;
`;

const gapStyle = css`
    gap: 8px;
`;

/**
 * Display a carousel component for cycling through a series of content by using the navigation buttons.
 * Navigation buttons are placed alongside the carousel, unless @navigationBtnOverlay is set to true.
 *
 * @children The content that is displayed in the carousel
 * @navigationBtnOverlay Sets the navigation buttons on top of the carousel items.
 *
 * @example
 * <Carousel>
 *    {children}
 * </Carousel>
 */
interface ICarouselProps extends IWithChildren {
    navigationBtnOverlay?: boolean;
}

export const Carousel: React.FC<ICarouselProps> = ({ children, navigationBtnOverlay }) => {
    const container = React.useRef<HTMLDivElement>(null);
    const viewportContainer = React.useRef<HTMLDivElement>(null);
    const [isScrollMin, setIsScrollMin] = React.useState(true);
    const [isScrollMax, setIsScrollMax] = React.useState(false);
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const isRTL = isPageRTL();
    const setScrollStateDebounced = debounce(
        () => setScrollPosition(viewportContainer.current?.scrollLeft ?? 0),
        100,
    );

    const smoothScroll = React.useCallback(
        (direction: Direction) => {
            const viewportContainerWidth = viewportContainer.current?.scrollWidth ?? 0;
            if (!viewportContainer.current || viewportContainerWidth === 0) return;

            const maxScroll =
                (viewportContainer.current?.scrollWidth ?? 0) -
                (viewportContainer.current?.clientWidth ?? 0);
            const canScroll =
                viewportContainer.current?.scrollWidth !== viewportContainer.current?.clientWidth;
            if (!canScroll) {
                setIsScrollMin(true);
                setIsScrollMax(true);
            }
            const viewElement = viewportContainer.current;
            // container width depends on whether navigation buttons are placed alongside or on top of carousel
            const containerWidth = navigationBtnOverlay
                ? container.current?.getBoundingClientRect().width
                : viewElement?.getBoundingClientRect().width;
            const scrollDistance = containerWidth ?? 0 * (navigationBtnOverlay ? 0.8 : 1);

            const newScrollPosition =
                direction === 'left'
                    ? Math.max(0, scrollPosition - scrollDistance)
                    : Math.min(maxScroll, scrollPosition + scrollDistance);
            viewElement.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth',
            });
        },
        [navigationBtnOverlay, scrollPosition],
    );

    //Scroll in RTL mode
    const smoothScrollRTL = React.useCallback(
        (direction: Direction): void => {
            const viewportContainerWidth = viewportContainer.current?.scrollWidth ?? 0;
            if (!viewportContainer.current || viewportContainerWidth === 0) return;

            //maxScroll is negative value in RTL
            const maxScroll =
                (viewportContainer.current?.clientWidth ?? 0) -
                (viewportContainer.current?.scrollWidth ?? 0);
            const canScroll =
                viewportContainer.current?.scrollWidth !== viewportContainer.current?.clientWidth;
            if (!canScroll) {
                setIsScrollMin(true);
                setIsScrollMax(true);
            }
            const viewElement = viewportContainer.current;
            // container width depends on whether navigation buttons are placed alongside or on top of carousel
            const containerWidth = navigationBtnOverlay
                ? container.current?.getBoundingClientRect().width
                : viewElement?.getBoundingClientRect().width;
            const scrollDistance = containerWidth ?? 0 * (navigationBtnOverlay ? 0.8 : 1);

            const newScrollPosition =
                direction === 'left'
                    ? Math.max(maxScroll, scrollPosition - scrollDistance)
                    : Math.min(0, scrollPosition + scrollDistance);
            viewElement.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth',
            });
        },
        [navigationBtnOverlay, scrollPosition],
    );

    React.useEffect(() => {
        const maxScroll =
            (viewportContainer.current?.scrollWidth ?? 0) -
            (viewportContainer.current?.clientWidth ?? 0);

        //In RTL mode
        if (isRTL) {
            const currentPosition = Math.floor(scrollPosition);
            switch (currentPosition) {
                case 0:
                    setIsScrollMax(false);
                    setIsScrollMin(true);
                    return;
                case -maxScroll:
                    setIsScrollMax(true);
                    setIsScrollMin(false);
                    return;
                default:
                    setIsScrollMax(false);
                    setIsScrollMin(false);
            }
        }
        switch (scrollPosition) {
            case 0:
                setIsScrollMax(false);
                setIsScrollMin(true);
                return;
            case maxScroll:
                setIsScrollMax(true);
                setIsScrollMin(false);
                return;
            default:
                setIsScrollMax(false);
                setIsScrollMin(false);
        }
    }, [isRTL, scrollPosition]);

    useMount(
        () => {
            viewportContainer.current?.addEventListener('scroll', setScrollStateDebounced);
        },
        () => {
            viewportContainer.current?.removeEventListener('scroll', setScrollStateDebounced);
        },
    );

    const navButtonLeftContainerStyle = css`
        ${navButtonRightContainerStyle}
        position: ${navigationBtnOverlay ? 'absolute' : 'static'};
        inset-inline-start: ${navigationBtnOverlay ? 0 : 'auto'};
    `;

    return (
        <div
            id="carousel-container"
            ref={container}
            className={cx([containerStyle, !navigationBtnOverlay && flexGrowStyle])}
        >
            <div className={navButtonLeftContainerStyle}>
                <div className={navButtonBackgroundStyle}>
                    <IconButton
                        icon={isRTL ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
                        color={isScrollMin ? 'grey6' : 'blue'}
                        size="lg"
                        disabled={isScrollMin}
                        onClick={() => (isRTL ? smoothScrollRTL('right') : smoothScroll('left'))}
                    />
                </div>
            </div>
            <div
                id="carousel-viewport-container"
                ref={viewportContainer}
                className={cx(
                    viewportContainerStyle,
                    navigationBtnOverlay ? navigationPaddingStyle : flexGrowStyle,
                )}
            >
                <div
                    id="carousel-view-port"
                    className={cx([viewportStyle, navigationBtnOverlay ? gapStyle : flexGrowStyle])}
                >
                    {children}
                </div>
            </div>
            <div
                className={cx([
                    navButtonRightContainerStyle,
                    navigationBtnOverlay && navBtnRightOverlayStyle,
                ])}
            >
                <div className={navButtonBackgroundStyle}>
                    <IconButton
                        testId="carousel_arrow_right"
                        icon={isRTL ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
                        color={isScrollMax ? 'grey6' : 'blue'}
                        size="lg"
                        disabled={isScrollMax}
                        onClick={() => (isRTL ? smoothScrollRTL('left') : smoothScroll('right'))}
                    />
                </div>
            </div>
        </div>
    );
};

Carousel.displayName = 'Carousel';
