import type { interfaces } from 'inversify';
import { useState } from 'react';
import { ServiceLocator } from './ServiceLocator';

export function useService<T>(
    serviceIdentifier: string | symbol | interfaces.Newable<T> | interfaces.Abstract<T>,
): T {
    const [service] = useState(ServiceLocator.get(serviceIdentifier));
    return service;
}
