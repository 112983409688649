import * as React from 'react';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import { DeviceSelectorActionService } from '../../../services/DeviceSelectorAction.service';
import type { DeviceGroup } from '../../../models/DeviceGroup';
import { getDeviceGroup } from '../../../selectors/getProductsForDeviceGroup';
import type { Property } from 'csstype';
import { Box, Closeable, IconButton, NoPrint, Positioned } from 'app/components';
import { DeviceSelector3dView } from './DeviceSelector3dView';
import type { IDesiredCamera } from 'app/modules/common';

const timeout = {
    appear: 0,
    enter: 0,
    exit: 400,
};

const transitionDuration = 400;

const stateToTranslation: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '100%',
    entering: '100%',
    entered: '-20px',
    exiting: '100%',
    exited: '100%',
};

export const FloatingScene3d: React.FC = () => {
    const [actionService] = React.useState(ServiceLocator.get(DeviceSelectorActionService));
    const showBigScene3d = useSelector<IStoreState, boolean>(
        (state) => state.deviceSelector.bigScene3dVisible,
    );
    const deviceGroup = useSelector<IStoreState, DeviceGroup>(getDeviceGroup);
    const desiredFilter = useSelector<IStoreState, IDesiredCamera>((store) =>
        store.deviceSelector.deviceGroup === 'cameras'
            ? store.deviceSelector.cameraFilter.desiredCamera
            : store.deviceSelector.sensorUnitFilter.desiredSensorUnit,
    );

    const updateDesiredFilter = (desiredCameraUpdates: Partial<IDesiredCamera>) => {
        if (deviceGroup === 'cameras') {
            actionService.updateDesiredCamera(desiredCameraUpdates);
        } else {
            actionService.updateDesiredSensorUnit(desiredCameraUpdates);
        }
    };

    React.useEffect(() => {
        actionService.hideBigScene3d();
        return () => {
            actionService.hideBigScene3d();
        };
    }, [actionService, deviceGroup]);

    return (
        <Transition in={showBigScene3d} timeout={timeout} mountOnEnter unmountOnExit>
            {(state) => (
                <Closeable close={() => {}} customShadow="rgb(0 0 0 / 20%) 0px 4px 10px">
                    <NoPrint>
                        <Positioned
                            position="sticky"
                            bottom="0"
                            translateY={stateToTranslation[state]}
                            transition
                            duration={transitionDuration}
                            bringToFront
                        >
                            <Box
                                color="grey1"
                                borderRadius="rounded"
                                width="100%"
                                height="700px"
                                maxHeight="60vh"
                                position="relative"
                                overflow="hidden"
                            >
                                <DeviceSelector3dView
                                    desiredFilter={desiredFilter}
                                    onChangeInstallationHeight={(newHeight) =>
                                        updateDesiredFilter({
                                            installationHeight: newHeight,
                                            isSceneFilterActive: true,
                                        })
                                    }
                                    onChangeTargetDistance={(newDistance) =>
                                        updateDesiredFilter({
                                            distanceToTarget: newDistance,
                                            isSceneFilterActive: true,
                                        })
                                    }
                                    onChangePanoramaMode={(panoramaMode) =>
                                        updateDesiredFilter({
                                            panoramaMode,
                                            isSceneFilterActive: true,
                                        })
                                    }
                                />
                                <Positioned position="absolute" top="8px" right="8px">
                                    <IconButton
                                        icon="close_circle_outline"
                                        color="grey7"
                                        onClick={actionService.hideBigScene3d}
                                    />
                                </Positioned>
                            </Box>
                        </Positioned>
                    </NoPrint>
                </Closeable>
            )}
        </Transition>
    );
};

FloatingScene3d.displayName = 'FloatingScene3d';
