import type { IProfileOverridePropertiesEntity } from '../userDataPersistence';

export function getDefaultProfileOverrideEntity(): IProfileOverridePropertiesEntity {
    return {
        scenario: {},
        continuousRecording: {},
        triggeredRecording: {},
        liveView: {},
        storage: {},
        zipstream: {},
        audio: {},
    };
}
