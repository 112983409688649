import { Box, Button } from 'app/components';
import { t } from 'app/translate';
import * as React from 'react';

interface IGenetecStreamVaultCalculatorProps {
    disabled: boolean;
    onModifyRecommendations(): void;
}

export const GenetecStreamVaultCalculator: React.FC<IGenetecStreamVaultCalculatorProps> = ({
    disabled,
    onModifyRecommendations,
}) => {
    return (
        <Box justifyContent="center" testId={'genetec_streamvault_calculator'}>
            <Button text disabled={disabled} onClick={onModifyRecommendations}>
                {t.genetecGROUP.configureByUseCalculator}
            </Button>
        </Box>
    );
};

GenetecStreamVaultCalculator.displayName = 'GenetecStreamVaultCalculator';
