import * as React from 'react';
import { Border } from '../../style/Border';
import { Box } from '../../containers';
import { Positioned, Stack } from '../../layout';
import type { Icons } from '../../ui/icon';
import { IconButton } from '../../ui/iconButton';
import { css, cx } from '@emotion/css';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps } from '../../services';

const cardStackingStyle = css`
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const bottomContentStyle = css`
    padding-bottom: 42px !important;
`;

interface IProductCardProps extends IExtendableComponentWithChildren {
    /**
     * An icon to show in the top right corner
     */
    icon?: Icons;
    /**
     * Content that will display at the bottom of the card. Usually MSRP.
     */
    bottomContent?: JSX.Element;
    /**
     * Wether the card is selected or not.
     * Will render in yellow if true.
     */
    selected?: boolean;
}

/**
 * Display a product card with child contents.
 * Has the ability to render an icon in the top right corner and some content in the bottom.
 */
export class ProductCard extends React.Component<IProductCardProps> {
    public render() {
        const className = cx(cardStackingStyle, {
            [bottomContentStyle]: !!this.props.bottomContent,
        });
        const props = extendableProps(this.props, { className });
        return (
            <Border
                color={this.props.selected ? 'yellow4' : 'grey3'}
                __htmlAttributes={props.__htmlAttributes}
                radius="10px"
            >
                <Box
                    color={this.props.selected ? 'yellow1' : 'white'}
                    position="relative"
                    paddingY="panel"
                    paddingX="base"
                    overflow="hidden"
                    alignItems="center"
                    justifyContent="center"
                >
                    {this.props.icon && (
                        <Positioned position="absolute" top="4px" right="6px">
                            <IconButton color="grey4" icon={this.props.icon} />
                        </Positioned>
                    )}
                    <Stack vertical spacing="base" alignItems="center" justifyContent="evenly">
                        {this.props.children}
                    </Stack>
                    {this.props.bottomContent && this.props.bottomContent}
                </Box>
            </Border>
        );
    }
}
