import { injectable } from 'inversify';
import type {
    IProfileOverridePropertiesModel,
    IBaseProfileModel,
    IScenarioSettingsModel,
    IRecordingSettingsModel,
    IStorageSettingsModel,
    IAudioSettingsModel,
    IZipstreamSettingsModel,
    Id,
    IProfileOverridePropertiesEntity,
    IProfileEntity,
    IPersistence,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
} from 'app/core/persistence';
import {
    BaseProfile,
    Time24,
    ProfileService,
    deviceTypeCheckers,
    CurrentProjectService,
    ItemService,
    getDefaultProfileOverrideEntity,
    Resolution,
} from 'app/core/persistence';
import { merge } from 'lodash-es';
import { mergeProfiles } from '../utils';

@injectable()
export class ProfileOverrideService {
    constructor(
        private profileService: ProfileService,
        private currentProjectService: CurrentProjectService,
        private itemService: ItemService,
    ) {}

    public static getHasOverrides(
        profileOverride?: IProfileOverridePropertiesModel | IProfileOverridePropertiesEntity,
    ) {
        if (profileOverride) {
            const members = Object.values(profileOverride);

            return members.some((properties) => {
                if (typeof properties === 'object') {
                    const values = Object.values(properties);
                    return values.some((value) => value !== undefined);
                }

                return typeof properties !== 'undefined';
            });
        }
        return false;
    }

    public getMergedProfile(
        profile: IPersistence<IProfileEntity>,
        override: IProfileOverridePropertiesModel,
        projectRetentionTimeInDays: number,
    ): IBaseProfileModel {
        const baseProfile = new BaseProfile(profile);
        return mergeProfiles(
            baseProfile,
            override,
            projectRetentionTimeInDays,
        ) as IBaseProfileModel;
    }

    public async getMergedProfileFromDevice(
        item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        projectRetentionTimeInDays: number,
    ): Promise<IBaseProfileModel> {
        const profile = await this.profileService.getProfile(item.associatedProfile);
        return this.getMergedProfileFromDeviceAndProfile(item, profile, projectRetentionTimeInDays);
    }

    public getMergedProfileFromDeviceAndProfile(
        item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        profile: IPersistence<IProfileEntity>,
        projectRetentionTimeInDays: number,
    ): IBaseProfileModel {
        const newLightStart = this.toTime24(item.profileOverride.scenario.lightStart);
        const newLightEnd = this.toTime24(item.profileOverride.scenario.lightEnd);

        const overrideItem = {
            ...item,
            profileOverride: {
                ...item.profileOverride,
                scenario: {
                    ...item.profileOverride.scenario,
                    lightStart: newLightStart,
                    lightEnd: newLightEnd,
                },
                triggeredRecording: {
                    ...item.profileOverride.triggeredRecording,
                    resolution: this.toResolutionObject(
                        item.profileOverride.triggeredRecording.resolution,
                    ),
                },
                continuousRecording: {
                    ...item.profileOverride.continuousRecording,
                    resolution: this.toResolutionObject(
                        item.profileOverride.continuousRecording.resolution,
                    ),
                },
                liveView: {
                    ...item.profileOverride.liveView,
                    resolution: this.toResolutionObject(item.profileOverride.liveView.resolution),
                },
            },
        };

        return this.getMergedProfile(
            profile,
            overrideItem.profileOverride,
            projectRetentionTimeInDays,
        );
    }

    public getValueToSet<T>(
        defaultValue: T,
        newValue: T,
        override?:
            | Partial<IScenarioSettingsModel>
            | Partial<IRecordingSettingsModel>
            | Partial<IStorageSettingsModel>
            | Partial<IAudioSettingsModel>
            | Partial<IZipstreamSettingsModel>,
    ): T | undefined {
        if (override) {
            if (defaultValue instanceof Time24 && newValue instanceof Time24) {
                return String(defaultValue) === String(newValue) ? undefined : newValue;
            }
            return defaultValue === newValue ? undefined : newValue;
        }
        return newValue;
    }

    public async updateProfileOverride(
        itemId: Id,
        newProfileOverride: Partial<IProfileOverridePropertiesEntity>,
    ) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');

        if (deviceTypeCheckers.isCamera(entity) || deviceTypeCheckers.isDoorStation(entity)) {
            let profile = this.currentProjectService.getEntity(
                entity.properties.camera.associatedProfile,
                'profile',
            );

            const projectRetentionTime =
                this.currentProjectService.getProjectEntity().recordingRetentionTimeInDays;

            if (profile.storage.useProjectSetting) {
                profile = {
                    ...profile,
                    storage: {
                        ...profile.storage,
                        retentionTime: projectRetentionTime,
                    },
                };
            }

            const profileOverride = this.recursivelyGetValueToSet(
                profile,
                entity.properties.camera.profileOverride,
                newProfileOverride,
            );

            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    camera: {
                        ...entity.properties.camera,
                        profileOverride,
                    },
                },
            });
        } else if (deviceTypeCheckers.isSensorUnit(entity)) {
            const profile = this.currentProjectService.getEntity(
                entity.properties.sensorUnit.associatedProfile,
                'profile',
            );

            const profileOverride = this.recursivelyGetValueToSet(
                profile,
                entity.properties.sensorUnit.profileOverride,
                newProfileOverride,
            );

            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    sensorUnit: {
                        ...entity.properties.sensorUnit,
                        profileOverride,
                    },
                },
            });
        } else if (deviceTypeCheckers.isAnalogCamera(entity)) {
            const profile = this.currentProjectService.getEntity(
                entity.properties.analogCamera.associatedProfile,
                'profile',
            );

            const profileOverride = this.recursivelyGetValueToSet(
                profile,
                entity.properties.analogCamera.profileOverride,
                newProfileOverride,
            );

            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    analogCamera: {
                        ...entity.properties.analogCamera,
                        profileOverride,
                    },
                },
            });
        } else if (deviceTypeCheckers.isVirtualProduct(entity)) {
            const profile = this.currentProjectService.getEntity(
                entity.properties.virtualProduct.associatedProfile,
                'profile',
            );

            const profileOverride = this.recursivelyGetValueToSet(
                profile,
                entity.properties.virtualProduct.profileOverride,
                newProfileOverride,
            );

            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    virtualProduct: {
                        ...entity.properties.virtualProduct,
                        profileOverride,
                    },
                },
            });
        }
    }

    public async createProfileFromOverride(itemId: Id, name: string) {
        const item = this.currentProjectService.getEntity(itemId, 'item');
        if (!item) {
            throw new Error(`Could not find item`);
        }

        const profile = this.profileService.getAssociatedProfile(itemId);
        if (!profile) {
            throw new Error(`Could not find profile for item`);
        }

        const profileOverride = this.getProfileOverride(itemId);

        const mergedProfile: IProfileEntity = merge({}, profile, profileOverride);
        mergedProfile.name = name;

        const response = await this.profileService.addProjectProfileWithEntity(mergedProfile);

        await this.itemService.updateProfile(itemId, response._id);

        return response;
    }

    public getProfileOverride(itemId: Id) {
        const item = this.currentProjectService.getEntity(itemId, 'item');

        if (!item) {
            return undefined;
        }

        let profileOverride: IProfileOverridePropertiesEntity | undefined;

        if (deviceTypeCheckers.isAnalogCamera(item)) {
            profileOverride = item.properties.analogCamera.profileOverride;
        }

        if (deviceTypeCheckers.isDoorStation(item) || deviceTypeCheckers.isCamera(item)) {
            profileOverride = item.properties.camera.profileOverride;
        }

        if (deviceTypeCheckers.isSensorUnit(item)) {
            profileOverride = item.properties.sensorUnit.profileOverride;
        }

        if (deviceTypeCheckers.isVirtualProduct(item)) {
            profileOverride = item.properties.virtualProduct.profileOverride;
        }

        return profileOverride;
    }

    public async resetProfileOverride(itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');

        if (deviceTypeCheckers.isCamera(entity) || deviceTypeCheckers.isDoorStation(entity)) {
            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    camera: {
                        ...entity.properties.camera,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        } else if (deviceTypeCheckers.isSensorUnit(entity)) {
            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    sensorUnit: {
                        ...entity.properties.sensorUnit,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        } else if (deviceTypeCheckers.isAnalogCamera(entity)) {
            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    analogCamera: {
                        ...entity.properties.analogCamera,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        } else if (deviceTypeCheckers.isVirtualProduct(entity)) {
            return this.itemService.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    virtualProduct: {
                        ...entity.properties.virtualProduct,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        }
    }

    private toTime24 = (time: string | undefined) => (time ? new Time24(time) : undefined);
    private toResolutionObject = (res: string | undefined) =>
        res ? new Resolution(res) : undefined;

    /**
     * Recursively checks the newProfileOverride values against the
     * profile object. If the new profile override has the same value as
     * the original profile value it will be set to undefined to remove the override.
     *
     * @returns Updated profile override entity
     */
    private recursivelyGetValueToSet(
        profile: IProfileEntity,
        existingProfileOverride: IProfileOverridePropertiesEntity,
        newProfileOverride: Partial<IProfileOverridePropertiesEntity>,
    ): IProfileOverridePropertiesEntity {
        const result: any = { ...existingProfileOverride };

        // The nature of this check makes it hard (impossible?) to type correctly, since using any.
        Object.entries(newProfileOverride).forEach(([key, value]) => {
            if (typeof value === 'object' && value != null) {
                result[key] = this.recursivelyGetValueToSet(
                    (profile as any)[key],
                    (existingProfileOverride as any)[key],
                    value as any,
                );
            } else {
                result[key] = (profile as any)[key] === value ? undefined : value;
            }
        });

        return result;
    }
}
