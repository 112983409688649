import { BandwidthOptions } from './BandwidthOptions';
import { Condition } from './Condition';
import { DynamicFpsCalculator } from './DynamicFps.calculator';
import { FixedFpsCalculator } from './FixedFps.calculator';
import type { IBandwidthCalculationResult, IBandwidthParams } from '../../models';

export class BandwidthCalculation {
    private readonly dynamicFpsCalculator: DynamicFpsCalculator;
    private readonly fixedFpsCalculator: FixedFpsCalculator;
    private readonly bandwidthOptions: BandwidthOptions;

    constructor(
        options: IBandwidthParams,
        private hasAudio: boolean,
        private badWeatherPercent: number = 0.3,
    ) {
        this.bandwidthOptions = new BandwidthOptions({ ...options });
        this.dynamicFpsCalculator = new DynamicFpsCalculator(this.bandwidthOptions);
        this.fixedFpsCalculator = new FixedFpsCalculator(this.bandwidthOptions);
    }

    /**
     * Used when the user wants to define their own conditions, or by unit tests.
     */
    public getBitRates(conditions: Condition[]) {
        const results: Record<string, number> = {};

        conditions.forEach((condition) => {
            results[condition.name] = this.getCalculator(condition).getBitrate(condition);
        });

        return results;
    }

    /**
     * Used by Site Designer that uses predefined conditions.
     */
    public getBitRatesSiteDesigner(): IBandwidthCalculationResult {
        const dynamicFps = this.getCanUseDynamicFps();
        const bitRates = this.getBitRates(this.getSiteDesignerConditions(dynamicFps));

        return {
            goodLight: this.getWeatherBasedBitRate(
                bitRates.goodLight,
                bitRates.goodLightBadWeather,
            ),
            goodLightIdle: this.getWeatherBasedBitRate(
                bitRates.goodLightIdle,
                bitRates.goodLightIdleBadWeather,
            ),
            lowLight: this.getWeatherBasedBitRate(bitRates.lowLight, bitRates.lowLightBadWeather),
            lowLightIdle: this.getWeatherBasedBitRate(
                bitRates.lowLightIdle,
                bitRates.lowLightIdleBadWeather,
            ),
        };
    }

    private getCanUseDynamicFps() {
        // MotionJPEG does not support dynamic fps
        return (
            this.bandwidthOptions.scalingCostType !== 'MJPEG' &&
            this.bandwidthOptions.fpsMode === 'dynamic'
        );
    }

    private getCalculator(condition: Condition) {
        // If the motion fraction is 100% (always in motion) we simplify it
        // and use the fixed fps calculation instead since it will give the same result.
        return this.getCanUseDynamicFps() && condition.motionFrac < 1
            ? this.dynamicFpsCalculator
            : this.fixedFpsCalculator;
    }

    private getWeatherBasedBitRate(bitRate: number = 0, badWeatherBitRate: number = 0) {
        const goodWeatherPercent = 1 - this.badWeatherPercent;
        const good = bitRate * goodWeatherPercent;
        const bad = badWeatherBitRate * this.badWeatherPercent;
        const audio = this.hasAudio ? 32 : 0;

        // Summarize good and bad bit rates and add audio bitrate if available
        return good + bad + audio;
    }

    private getSiteDesignerConditions(dynamicFps: boolean) {
        const conditions: Condition[] = [
            new Condition({
                name: 'goodLight',
                lighting: 3,
                weather: false,
                motion: this.bandwidthOptions.goodLightMotion,
                motionFrac: this.bandwidthOptions.goodLightMotionFrac,
                nature: this.bandwidthOptions.nature,
            }),
            new Condition({
                name: 'lowLight',
                lighting: this.bandwidthOptions.nightLight,
                weather: false,
                nature: this.bandwidthOptions.nature,
                motion: this.bandwidthOptions.lowLightMotion,
                motionFrac: this.bandwidthOptions.lowLightMotionFrac,
            }),
            new Condition({
                name: 'goodLightBadWeather',
                lighting: 3,
                weather: true,
                nature: this.bandwidthOptions.nature,
                motion: this.bandwidthOptions.goodLightMotion,
                motionFrac: this.bandwidthOptions.goodLightMotionFrac,
            }),
            new Condition({
                name: 'lowLightBadWeather',
                lighting: this.bandwidthOptions.nightLight,
                weather: true,
                nature: this.bandwidthOptions.nature,
                motion: this.bandwidthOptions.lowLightMotion,
                motionFrac: this.bandwidthOptions.lowLightMotionFrac,
            }),
        ];

        // Dynamic FPS already has idle calculations factored into the result
        if (!dynamicFps) {
            conditions.push(
                new Condition({
                    name: 'goodLightIdle',
                    lighting: 3,
                    weather: false,
                    nature: this.bandwidthOptions.nature,
                    motion: 0,
                    motionFrac: 0,
                }),
                new Condition({
                    name: 'goodLightIdleBadWeather',
                    lighting: 3,
                    weather: true,
                    nature: this.bandwidthOptions.nature,
                    motion: 0,
                    motionFrac: 0,
                }),
                new Condition({
                    name: 'lowLightIdle',
                    lighting: this.bandwidthOptions.nightLight,
                    weather: false,
                    nature: this.bandwidthOptions.nature,
                    motion: 0,
                    motionFrac: 0,
                }),
                new Condition({
                    name: 'lowLightIdleBadWeather',
                    lighting: this.bandwidthOptions.nightLight,
                    weather: true,
                    nature: this.bandwidthOptions.nature,
                    motion: 0,
                    motionFrac: 0,
                }),
            );
        }

        return conditions;
    }
}
