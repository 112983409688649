import * as React from 'react';
import { css } from '@emotion/css';
import type { IWithChildren } from 'app/components/models';

const menuGroupStyle = css`
    padding: 0;
    margin: 0 0 32px 0;

    /* Fixes margins for IE11 and Edge - https://jira.se.axis.com/browse/WT-534 */
    li {
        display: inline;
    }
`;
export class MenuGroup extends React.PureComponent<IWithChildren> {
    public render() {
        return <ul className={menuGroupStyle}>{this.props.children}</ul>;
    }
}
