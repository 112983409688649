import * as React from 'react';
import { t } from 'app/translate';
import {
    Box,
    DropDownMenu,
    DropDownMenuButton,
    EditableText,
    Icon,
    IconButton,
    IconText,
    Stack,
} from 'app/components';
import type { Id, IDoorItemEntity, IPersistence } from 'app/core/persistence';
import { AppConstants } from 'app/AppConstants';
import { debounce } from 'lodash-es';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { DoorControllerService } from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import {
    getDoor,
    getmaxNbrOfDoorsForProduct,
    getNbrOfLocksUsedForDoorController,
} from '../selectors';

const getLockItems = (nbrLocks: number): string[] => {
    const dropDownMenu: string[] = [];
    for (let i = 0; i < nbrLocks; i++) {
        dropDownMenu.push(`${i + 1}`);
    }
    return dropDownMenu;
};

interface IDoorHeaderProps {
    deviceItemId: Id;
    doorItemId: Id;
    showDelete?: boolean;
}

export const DoorHeader: React.FC<IDoorHeaderProps> = ({
    deviceItemId,
    doorItemId,
    showDelete = true,
}) => {
    const door = useSelector<IStoreState, IPersistence<IDoorItemEntity> | undefined>((state) =>
        getDoor(state, doorItemId),
    );

    const [doorControllerService] = React.useState<DoorControllerService>(
        ServiceLocator.get(DoorControllerService),
    );

    const [doorName, setDoorName] = React.useState<string>(
        door ? door.name : t.devicesGROUP['door'],
    );

    const maxNbrOfLocks = useSelector<IStoreState, number>((state) =>
        getmaxNbrOfDoorsForProduct(state, deviceItemId),
    );

    const locks: string[] = getLockItems(maxNbrOfLocks);

    const onLocksChange = (value: number) => {
        if (door?.properties.door) {
            doorControllerService.updateDoorProps(door as IPersistence<IDoorItemEntity>, {
                nbrOfLocks: value,
            });
        }
    };

    const onDeleteDoor = () => {
        door && doorControllerService.removeDoor(door?._id);
    };

    const onChangeName = (newName: string) => {
        setDoorName(newName);
        if (door?.properties.door) {
            doorControllerService.updateDoor(door?._id, { name: newName });
        }
    };

    const usedNbrOfLocks = useSelector<IStoreState, number>((state) =>
        getNbrOfLocksUsedForDoorController(state, deviceItemId),
    );

    if (!door) {
        return null;
    }

    return (
        <Box width="100%" direction="column" paddingBottom="base">
            <Box
                justifyContent="end"
                width="100%"
                paddingTop="quart"
                paddingRight="quart"
                minHeight={24}
            >
                {showDelete && (
                    <IconButton icon="delete" size="md" onClick={() => onDeleteDoor()} />
                )}
            </Box>
            <Box alignItems="center" width="100%" paddingX="doublePanel" paddingBottom="base">
                <Box flex="fullWidth" />
                <Box justifyContent="center" minWidth="400px">
                    <Box width="100%" alignItems="center" justifyContent="center">
                        <Icon icon="door_front" size="lg" color="grey8" />
                        <EditableText
                            value={doorName}
                            maxLength={AppConstants.deviceNameMaxLength}
                            onChange={debounce(onChangeName, 600)}
                            placeholder={t.devicesGROUP['door']}
                            changeCriteria="blur"
                        />
                    </Box>
                </Box>
                <Box flex="fullWidth" justifyContent="end">
                    <Stack spacing="halfQuart" alignItems="center">
                        <IconText icon="lock_black" color="grey8">
                            {t.locks}:
                        </IconText>
                        <DropDownMenu
                            arrow
                            border
                            color="grey8"
                            displaySelected
                            useSelectedWidth={false}
                            showOnlyTriggerContent
                        >
                            {locks.map((lock, index) => (
                                <DropDownMenuButton
                                    key={index}
                                    label={lock}
                                    onClick={() => onLocksChange(index + 1)}
                                    selected={index + 1 == door.properties.door.nbrOfLocks}
                                    disabled={
                                        maxNbrOfLocks - usedNbrOfLocks < index &&
                                        !(index + 1 == door.properties.door.nbrOfLocks)
                                    }
                                />
                            ))}
                        </DropDownMenu>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};

DoorHeader.displayName = 'DoorHeader';
