import * as React from 'react';
import { Stack, Border, Box, Icon, Text, Linkable, Clickable, Badge } from 'app/components';
import type { ProjectState, Id } from 'app/core/persistence';
import { getProjectStateLabel } from 'app/modules/common';

export interface IProjectStateIndicatorComponentProps {
    projectId: Id;
    projectState: ProjectState;
    showAsBadge?: boolean;
}

export class ProjectStateIndicatorComponent extends React.Component<IProjectStateIndicatorComponentProps> {
    public render() {
        const { projectId, projectState } = this.props;
        const color = this.getColor(projectState);

        return this.props.showAsBadge ? (
            <Badge
                icon="lock_black"
                label={getProjectStateLabel(projectState)}
                type={this.getBadgeType(projectState)}
                whiteSpace="nowrap"
            />
        ) : (
            <Linkable link={`/project/${projectId}/dashboard/`}>
                <Clickable>
                    <Stack spacing="half" flex="none">
                        <Border width={4} color={color}>
                            <Box
                                borderRadius="circle"
                                width="30px"
                                height="30px"
                                alignItems="center"
                                justifyContent="center"
                                flex="none"
                            >
                                <Icon icon="lock_black" size="xs" color={color} />
                            </Box>
                        </Border>
                        <Text large color="grey7">
                            {getProjectStateLabel(projectState)}
                        </Text>
                    </Stack>
                </Clickable>
            </Linkable>
        );
    }

    private getColor(projectState: ProjectState) {
        switch (projectState) {
            case 'done':
                return 'green';
            default:
                return 'blue';
        }
    }

    private getBadgeType(projectState: ProjectState) {
        switch (projectState) {
            case 'done':
                return 'green';
            default:
                return 'grey';
        }
    }
}
