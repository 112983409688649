import { debounce } from 'lodash';
import type { EventCategory } from './models';
import { isProduction } from 'app/isProduction';
import { isMeasurementAllowedByCookie, withTrailingSlash } from 'app/utils';
import { stripIds } from 'app/initializeAnalytics';
import i18next from 'i18next';

const standaloneAccessorySelector = window.location.pathname.includes('/accessory-selector');
const standalonePartnerProducts = window.location.pathname.includes('/technology-partner-finder');

/**
 * Send analytic data to Matomo if Site Designer is run in production mode,
 * if it is not run as standAloneAccessorySelector,
 * or if it is not run as Standalone partner products (TIP selector),
 * and measurement is allowed by cookies.
 *
 */
export const sendAnalyticsMatomo = () => {
    if (
        isProduction() &&
        !standaloneAccessorySelector &&
        !standalonePartnerProducts &&
        isMeasurementAllowedByCookie() &&
        window._paq
    ) {
        window._paq.push(['setConsentGiven']);
    }
};

const hasMatomo = () => typeof window._paq !== 'undefined';

/**
 * Application wide functions for logging events
 */
export const eventTracking = {
    /**
     * Log an event due to user interaction
     * @param category - Where, what module?
     * @param action - What happened? F.e. camera added
     * @param label - Optional: Specific information of the event f.e. 'M1065-LW'
     * @param value - Optional: A means of recording a numerical value against an event.
     */
    logUserEvent(category: EventCategory, action: string, label?: string, value?: number) {
        // track matomo event
        hasMatomo() && window._paq.push(['trackEvent', category, action, label, value]);
    },

    /**
     * Log a non interactive event
     * @param category - Where, what module e.g Application?
     * @param action - What happened? F.e. synced data to PouchDB
     * @param label - Optional: Specific information of the event
     * @param value - Optional: A means of recording a numerical value against an event.
     */
    logApplicationEvent(category: EventCategory, action: string, label?: string, value?: number) {
        // track matomo event
        if (window._paq) {
            window._paq.push(['trackEvent', category, action, label, value]);
        }
    },

    /**
     * Log a page navigation.
     * @param path - The page path we navigated to.
     */
    logPageView: debounce((path: string) => {
        const pathnameWithoutIds = stripIds(path);
        // Make sure all page views have the same format, e.g. avoid
        // logging XXX/ and XXX as different pages
        const pathToLog = withTrailingSlash(pathnameWithoutIds);
        // track matomo pageview
        if (hasMatomo()) {
            window._paq.push(['setCustomUrl', pathToLog]);
            window._paq.push(['trackPageView']);

            // track used language
            window._paq.push(['setCustomDimension', 1, i18next.language]);
            window._paq.push(['trackPageView']);
        }
    }, 200),

    /**
     * Log an error or exception
     * @param message - What went wrong?
     * @param location - Where did it happen? If this was an api call, the api name, otherwise the module name.
     * @param fatal - Optional: Error severity as boolean, set to true if serious error, default false.
     * @param errorCode - Error number if any f.e 404
     * @param stackIncluded - optional set to true if called from ErrorBoundary and stack is included - we do not want to track error with stack to Matomo
     */
    logError(
        message: string,
        location: string,
        errorCode?: number,
        stackIncluded: boolean = false,
    ) {
        const errorCodeParam = errorCode ? `${String(errorCode)}:` : '';
        const errorDescription = `${location} - ${errorCodeParam} ${message}`;
        // track matomo event
        hasMatomo() &&
            !stackIncluded &&
            window._paq.push(['trackEvent', 'Error', errorDescription]);

        // If development mode, log to console
        if (!isProduction()) {
            console.error(errorDescription);
        }
    },

    pushToGA4(event: string, eventAction: string, eventData: string, fileType?: string) {
        if (window.dataLayer) {
            window.dataLayer.push({
                event,
                eventAction,
                eventData,
                fileType,
            });
        }
    },
};
