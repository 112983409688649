import type { IItemRelationEntity, IPersistence, IItemEntity, Id } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    isIoRelay,
    isDoorController,
    isEncoder,
    isMainUnit,
} from 'app/core/persistence';
import type {
    IPiaDoorController,
    IPiaEncoder,
    IPiaIoRelay,
    IPiaItem,
    IPiaMainUnit,
} from 'app/core/pia';
import { PiaAccessoryCategory } from 'app/core/pia';
import type {
    getCurrentProjectItems,
    getCurrentProjectRelationsRecord,
    getPiaItemsRecord,
    getCurrentProjectItemRelationsArray,
    IRelatedPiaItem,
} from 'app/modules/common';
import { getUsedChannels } from 'app/modules/common';
import { getDoorChildrenForDevice, getAccessoriesForCategory } from 'app/modules/doorSelector';
import { creationDateReverseComparator } from 'app/utils';

const getAvailableDoors = (
    piaItem: IPiaDoorController,
    currentProjectRelationsRecord: Record<string, IItemRelationEntity[]>,
    itemId: Id,
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
    piaItemsRecord: Record<number, IPiaItem>,
    currentProjectRelationsArray: IPersistence<IItemRelationEntity>[],
) => {
    const maxNbrOfCardReaders = piaItem.properties.supportedOnboardReaders ?? 0;
    const maxNbrOfDoors = piaItem.properties.supportedOnboardDoors ?? 0;
    const relations = currentProjectRelationsRecord[itemId];
    const doors = getDoorChildrenForDevice(relations, currentProjectItems).sort(
        creationDateReverseComparator,
    );
    const doorSideRelations = itemId
        ? currentProjectRelationsArray.filter(
              (relation) =>
                  (relation.relationType === 'doorSideA' && relation.path.includes(itemId)) ||
                  (relation.relationType === 'doorSideB' && relation.path.includes(itemId)),
          )
        : [];
    const selectedAccessories = getAccessoriesForCategory(
        doorSideRelations,
        currentProjectItems,
        piaItemsRecord,
        PiaAccessoryCategory.READERS,
    );
    const nbrSelectedReaders = selectedAccessories.length;

    const usedNbrOfLocks = doors.reduce((sum, door) => {
        return sum + (door.properties.door?.nbrOfLocks || 1);
    }, 0);

    const disableAddDoor =
        maxNbrOfDoors - usedNbrOfLocks < 1 || maxNbrOfCardReaders - nbrSelectedReaders < 1;

    return !disableAddDoor;
};

// returns true if it is possible to add more expansion modules
const getAvailableExpansionSlots = (
    piaItem: IPiaIoRelay,
    piaItemsRecord: ReturnType<typeof getPiaItemsRecord>,
    currentProjectRelationsRecord: Record<string, IItemRelationEntity[]>,
    itemId: Id,
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
) => {
    const relations = piaItem.relations.filter(
        (relation) => relation.relationType === 'compatible',
    );
    const compatibleExpansionModules = Object.values(relations)
        .map(
            (relation) =>
                ({
                    ...piaItemsRecord[relation.id],
                    isIncluded: relation.relationType === 'includes',
                    isRecommended: relation.relationType === 'recommends',
                }) as IRelatedPiaItem,
        )
        .filter((relation) => relation.category === 'relayexpmodules');
    if (compatibleExpansionModules.length === 0) {
        return false;
    }
    const addedExpandedModules = getUsedChannels(
        itemId,
        currentProjectRelationsRecord,
        currentProjectItems,
    );

    const nbrSupportedExpansionModules = piaItem.properties.nbrSupportedExpansionModules ?? 0;
    const freeSlots = nbrSupportedExpansionModules - addedExpandedModules;

    return freeSlots > 0;
};

const getAvailableChannels = (
    piaItem: IPiaEncoder | IPiaMainUnit,
    itemId: Id,
    currentProjectRelationsRecord: Record<string, IItemRelationEntity[]>,
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
) => {
    const availableChannels = piaItem.properties.channels;
    const usedChannels = getUsedChannels(
        itemId,
        currentProjectRelationsRecord,
        currentProjectItems,
    );
    return usedChannels < availableChannels;
};

const isExpandableIoRelay = (piaItem: IPiaItem): piaItem is IPiaIoRelay => {
    return isIoRelay(piaItem) && Boolean(piaItem.properties.nbrSupportedExpansionModules);
};

const hasChildren = (item: IPersistence<IItemEntity>) =>
    deviceTypeCheckers.isEncoder(item) ||
    deviceTypeCheckers.isMainUnit(item) ||
    deviceTypeCheckers.isPac(item) ||
    deviceTypeCheckers.isDoorController(item);

export function getHasAvailableChildren(
    itemId: Id,
    currentProjectItems: ReturnType<typeof getCurrentProjectItems>,
    currentProjectRelationsRecord: ReturnType<typeof getCurrentProjectRelationsRecord>,
    piaItemsRecord: ReturnType<typeof getPiaItemsRecord>,
    currentProjectRelationsArray: ReturnType<typeof getCurrentProjectItemRelationsArray>,
): boolean {
    const item = currentProjectItems[itemId];
    if (!item?.productId || !hasChildren(item)) {
        return false;
    }
    const piaItem = piaItemsRecord[item.productId];

    if (isExpandableIoRelay(piaItem)) {
        return getAvailableExpansionSlots(
            piaItem,
            piaItemsRecord,
            currentProjectRelationsRecord,
            itemId,
            currentProjectItems,
        );
    }

    if (isDoorController(piaItem)) {
        return getAvailableDoors(
            piaItem,
            currentProjectRelationsRecord,
            itemId,
            currentProjectItems,
            piaItemsRecord,
            currentProjectRelationsArray,
        );
    }

    if (isEncoder(piaItem) || isMainUnit(piaItem)) {
        return getAvailableChannels(
            piaItem,
            itemId,
            currentProjectRelationsRecord,
            currentProjectItems,
        );
    }

    return false;
}
