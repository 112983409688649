import { Service } from './services/service/service';
import { TimeService } from './services/service/time.service';
import { Config } from './services/config';

export { IDistributor } from './services/models/distributor.interfaces';
export { IDistributors } from './services/models/distributor.interfaces';
export { IPriceRecord } from './services/models/price.interfaces';
export { IPrices } from './services/models/price.interfaces';
export { ICurrencies, ICurrency } from './services/models/currency.interfaces';

const msrpService = new Service(new TimeService(Date));

export { msrpService, Config };
