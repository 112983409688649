import { t } from 'app/translate';
import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Box, Text, NoPrint, Action, Modal } from 'app/components';
import { getContactDetails } from '../selectors';
import { EditContactDetails } from './EditContactDetails.container';

interface IContactDetailsOwnProps {
    hideEdit?: boolean;
}

interface IContactDetailsProps extends IContactDetailsOwnProps {
    address: string;
    userApiAddress: string;
}

interface IContactDetailsState {
    showEditContactDetailsModal: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IContactDetailsOwnProps,
): IContactDetailsProps => {
    const { address, userApiAddress } = getContactDetails(storeState);

    return {
        ...ownProps,
        userApiAddress,
        address,
    };
};

class ContactDetailsContainer extends React.Component<IContactDetailsProps, IContactDetailsState> {
    constructor(props: IContactDetailsProps) {
        super(props);
        this.state = { showEditContactDetailsModal: false };
    }

    public render() {
        return (
            <>
                {this.state.showEditContactDetailsModal && (
                    <Modal title={t.userContactDetailsEditTitle}>
                        <EditContactDetails onSaved={this.closeModal} onCancel={this.closeModal} />
                    </Modal>
                )}
                <Box direction="column" alignItems="start">
                    <Text whiteSpace="pre-wrap" testId="contact_details">
                        {this.props.address}
                    </Text>
                    {!this.props.hideEdit && (
                        <NoPrint>
                            <Box paddingY="half">
                                <Action
                                    title={t.userContactDetailsEditTitle}
                                    onClick={() =>
                                        this.setState({ showEditContactDetailsModal: true })
                                    }
                                />
                            </Box>
                        </NoPrint>
                    )}
                </Box>
            </>
        );
    }

    private closeModal = () => {
        this.setState({
            showEditContactDetailsModal: false,
        });
    };
}

export const ContactDetails = connect(mapStateToProps)(ContactDetailsContainer);
