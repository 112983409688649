import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Financing state removed so this migration will change project.state to projectState 'quoteSent' if state was one of the old
 * financing states 'offerSent', 'processing', 'finalApproval' or 'active'. Financing states 'designing' and 'installed' are the same
 * states for projectState.
 */
@injectable()
export class Migration18To19 extends MigrationProviderBase {
    public from: number = 18;
    public to: number = 19;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'project') {
                delete entity.financing;

                if (
                    entity.state === 'offerSent' ||
                    entity.state === 'processing' ||
                    entity.state === 'finalApproval' ||
                    entity.state === 'active'
                ) {
                    entity.state = 'quoteSent';
                }
            }

            return entity;
        };
    }
}
