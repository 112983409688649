import { getCurrentProjectItems } from 'app/modules/common';
import { createSelector } from 'reselect';
import { getEditDeviceId } from './getProductItem';

export const getEditItem = createSelector(
    [getEditDeviceId, getCurrentProjectItems],
    (editDeviceId, items) => {
        if (!editDeviceId) {
            return undefined;
        }

        return items[editDeviceId];
    },
);
