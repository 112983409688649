import * as leaflet from 'leaflet';
import { useEffect, useState } from 'react';

export const useDragStartEvent = (
    leafletMarker: leaflet.Marker<any>,
    onDragCallback: (e: leaflet.LeafletMouseEvent) => void,
) => {
    useEffect(() => {
        const callback = (e: any) => onDragCallback(e as leaflet.LeafletMouseEvent);
        leafletMarker.on('dragstart', callback);
        return () => {
            leafletMarker.off('dragstart', callback);
        };
    }, [leafletMarker, onDragCallback]);
};

export const useDragEvent = (
    leafletMarker: leaflet.Marker<any>,
    onDragCallback: (e: leaflet.LeafletMouseEvent) => void,
) => {
    useEffect(() => {
        const callback = (e: any) => onDragCallback(e as leaflet.LeafletMouseEvent);
        leafletMarker.on('drag', callback);
        return () => {
            leafletMarker.off('drag', callback);
        };
    }, [leafletMarker, onDragCallback]);
};

export const useDragEndEvent = (
    leafletMarker: leaflet.Marker<any>,
    onDragEndCallback: () => void,
) => {
    useEffect(() => {
        leafletMarker.on('dragend', onDragEndCallback);
        return () => {
            leafletMarker.off('dragend', onDragEndCallback);
        };
    }, [leafletMarker, onDragEndCallback]);
};

export const useMouseDownEvent = (
    leafletMarker: leaflet.Marker<any>,
    onMouseDownCallback: (e: leaflet.LeafletMouseEvent) => void,
) => {
    useEffect(() => {
        const callback = (e: any) => {
            onMouseDownCallback(e as leaflet.LeafletMouseEvent);
            leaflet.DomEvent.stopPropagation(e);
        };
        leafletMarker.on('mousedown', callback);
        return () => {
            leafletMarker.off('mousedown', callback);
        };
    }, [leafletMarker, onMouseDownCallback]);
};

export const useMouseUpEvent = (
    leafletMarker: leaflet.Marker<any>,
    onMouseUpCallback: (e: leaflet.LeafletMouseEvent) => void,
) => {
    useEffect(() => {
        const callback = (e: any) => {
            onMouseUpCallback(e as leaflet.LeafletMouseEvent);
            leaflet.DomEvent.stopPropagation(e);
        };
        leafletMarker.on('mouseup', callback);
        return () => {
            leafletMarker.off('mouseup', callback);
        };
    }, [leafletMarker, onMouseUpCallback]);
};

export const useMouseClickEvent = (
    leafletMarker: leaflet.Marker<any>,
    onMouseClickCallback: (e: leaflet.LeafletMouseEvent) => void,
) => {
    useEffect(() => {
        const callback = (e: any) => {
            onMouseClickCallback(e as leaflet.LeafletMouseEvent);
            leaflet.DomEvent.stopPropagation(e);
        };
        leafletMarker.on('click', callback);
        return () => {
            leafletMarker.off('click', callback);
        };
    }, [leafletMarker, onMouseClickCallback]);
};

export const useMouseOverEvent = (leafletMarker: leaflet.Marker<any>) => {
    const [isOver, setIsOver] = useState(false);
    useEffect(() => {
        leafletMarker.on('mouseover', () => setIsOver(true));
        leafletMarker.on('mouseout', () => setIsOver(false));
        return () => {
            leafletMarker.off('mouseover', () => setIsOver(true));
            leafletMarker.off('mouseout', () => setIsOver(false));
        };
    }, [leafletMarker]);
    return isOver;
};
