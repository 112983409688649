import { eventTracking } from '../../tracking/';
import { injectable } from 'inversify';
import type { IPiaAccessory, IPiaCamera, PiaId } from '../../pia/client/types';
import { PiaAccessoryCategory } from '../../pia/client/types';
import type { Id, IItem, IItemEntity, IPersistence } from '../userDataPersistence';
import { PiaItemService, PiaRelationService } from '../../pia';
import { ItemService } from './item';
import { CurrentProjectService } from './CurrentProject.service';
import { InstallationPointService } from './InstallationPoint.service';
import type { IUpdateLens } from '../models';

const DEFAULT_SENSOR_ID = 0;

@injectable()
export class LensSelectorService {
    constructor(
        private piaAccessoryItemService: PiaItemService<IPiaAccessory>,
        private itemService: ItemService,
        private piaRelationService: PiaRelationService,
        private currentProjectService: CurrentProjectService,
        private installationPointService: InstallationPointService,
        private piaCameraItemService: PiaItemService<IPiaCamera>,
    ) {}

    private getLens = async (
        itemId: Id,
        productId: number,
    ): Promise<IPersistence<IItemEntity> | undefined> => {
        const lensItems = this.currentProjectService.getDeviceChildren(itemId, 'lenses');
        return lensItems.find((item) => item.productId === productId);
    };

    private getLenses = async (itemId: Id): Promise<IPersistence<IItemEntity>[] | undefined> => {
        const lensItems = this.currentProjectService.getDeviceChildren(itemId, 'lenses');
        return lensItems;
    };

    private async addLens(
        itemId: Id,
        productId: PiaId,
        itemRelationType: 'lenses',
        sensorIndex: number,
    ): Promise<IPersistence<IItemEntity>> {
        const item: IItem = {
            name: '',
            description: '',
            notes: '',
            productId,
            quantity: 1,
            properties: {
                lens: {
                    sensorIndex,
                },
            },
        };

        await this.clearLenses(itemId, sensorIndex, false);
        const persistedItem = await this.itemService.addByParentId(itemId, item);

        const cameraItem = await this.itemService.getItem(itemId);
        const relation = cameraItem.productId
            ? this.piaRelationService.getRelationProperties(cameraItem.productId, productId)
            : undefined;

        await this.itemService.addItemRelation(itemId, persistedItem._id, itemRelationType);

        if (relation?.horizontalFOV) {
            await this.installationPointService.updateInstallationPointsForDevice(
                itemId,
                sensorIndex,
                relation.horizontalFOV.min,
                relation.horizontalFOV.max,
            );
        }

        return persistedItem;
    }

    /**
     * Remove lenses with ItemRelationType "accessory" for a given itemId.
     * New lenses should be added with ItemRelationType "lenses",
     * {@see ItemRelationType}.
     * @param  {Id} itemId - item with child lenses.
     * @returns {Promise<boolean>} - Promise with deleted Ids.
     */
    public async removeUnsupportedLenses(itemId: Id): Promise<Id[]> {
        let ret: Id[] = [];
        const accessoryItems = this.currentProjectService.getDeviceChildren(itemId, 'accessory');

        const lenses = accessoryItems.filter((accessoryItem) => {
            const piaItem = accessoryItem.productId
                ? this.piaAccessoryItemService.get(accessoryItem.productId).first()
                : undefined;
            return piaItem?.category === PiaAccessoryCategory.LENSES;
        });

        if (lenses.length > 0) {
            ret = await Promise.all(lenses.map((lens) => this.itemService.deleteItem(lens._id)));
        }

        return ret;
    }

    /**
     * Clear lenses for given itemId and sensorIndex.
     * @param  {Id} itemId - item with child lenses.
     * @param  {number} sensorIndex - Sensor index with lenses.
     * @param  {boolean} updateIp - Indicate if installationpoint should be updated (user selected standard lens)
     * @returns {Id[]} - Array with deleted Ids.
     */
    public async clearLenses(itemId: Id, sensorIndex: number, updateIp: boolean): Promise<Id[]> {
        const items = await this.getLenses(itemId);
        const promises: Promise<string>[] = [];
        items?.map(async (item) => {
            if (item.properties.lens?.sensorIndex === sensorIndex) {
                promises.push(this.itemService.deleteItem(item._id));
            }
        });

        const removedLenses = await Promise.all(promises);

        if (updateIp) {
            const device = this.currentProjectService.getEntity(itemId, 'item');
            if (device.productId) {
                const piaDevice = this.piaCameraItemService.get(device.productId).first();
                if (piaDevice) {
                    await this.installationPointService.updateInstallationPointsForDevice(
                        itemId,
                        sensorIndex,
                        piaDevice.properties.minHorizontalFOV,
                        piaDevice.properties.maxHorizontalFOV,
                    );
                }
            }
        }
        return removedLenses;
    }

    /**
     * Toggle lens will remove all lenses and add a new lens to given itemId.
     * @param  {Id} itemId - Add lens to product/parent with item id {@see Id}, e.g. item:661e0b0f-6324-46af-b6d8-cea04821da0c.
     * @param  {PiaId} productId - Product id of lens to be added {@see PiaId}, e.g. 65852.
     * @param  {number} sensorIndex - Which sensor id to add lens to {@see IPiaCameraProperties.imageSensors}.
     * @throws {Error} - Pia lens with id {@see productId} was not found.
     * @returns {Promise<IUpdateLens>} - Promise with updated lens.
     */
    public toggleLens = async (
        itemId: Id,
        productId: PiaId,
        sensorIndex: number,
    ): Promise<IUpdateLens> => {
        const piaLens = this.piaAccessoryItemService.get(productId).first();

        if (!piaLens) {
            throw Error(`Pia lens with id ${productId} was not found.`);
        }
        const item = await this.getLens(itemId, productId);

        eventTracking.logUserEvent(
            'Application',
            item?.properties.lens?.sensorIndex === sensorIndex ? 'Unselect lens' : 'Select lens',
            productId.toString(),
        );
        if (item?.properties.lens?.sensorIndex === sensorIndex) {
            await this.itemService.deleteItem(item._id);

            return {
                id: undefined,
                rev: undefined,
                productId,
                quantity: 1,
                sensorIndex,
            };
        }

        const persistedItem = await this.addLens(itemId, productId, 'lenses', sensorIndex);

        return {
            id: persistedItem._id,
            rev: persistedItem._rev,
            productId,
            quantity: 1,
            sensorIndex: persistedItem.properties.lens?.sensorIndex || DEFAULT_SENSOR_ID,
        };
    };
}
