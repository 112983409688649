import { Heading, Text } from 'app/components';
import * as React from 'react';

interface IWarningContent {
    /**
     * warning content that consists of heading and text.
     * Heading will render in the color set.
     */
    heading?: string;
    /**
     * The body text of content.
     */
    text?: string;
    /**
     * The color of the heading
     */
    color: 'blue' | 'yellow' | 'red';
}

/**
 * content containing header and text.
 */
export const ApplicationWarningContent: React.FunctionComponent<IWarningContent> = ({
    heading,
    text,
    color,
}) => {
    return heading || text ? (
        <>
            {heading && <Heading color={color}>{heading ?? ''}</Heading>}
            {text && <Text color="grey9">{text ?? ''}</Text>}
        </>
    ) : null;
};

ApplicationWarningContent.displayName = 'WarningContent';
