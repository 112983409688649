import * as React from 'react';
import type { IAutoTestable } from 'app/components';
import { Stack, TextInput, Text } from 'app/components';
import { AppConstants } from 'app/AppConstants';

interface IIPAddressFieldProps extends IAutoTestable {
    testId: string;
    override?: boolean;
    label: string;
    value: string;
    error?: boolean;
    onChange?(value: string): void;
}

export const IPAddressField: React.FC<IIPAddressFieldProps> = ({
    testId,
    override,
    label,
    value,
    error,
    onChange,
}) => {
    const splitAddresses = value.split(', ');

    const renderSplitAddresses = () => (
        <Stack spacing="none" vertical>
            {splitAddresses.map((address) => (
                <Text testId={testId} key={address}>
                    {address}
                </Text>
            ))}
        </Stack>
    );

    return (
        <React.Fragment>
            {override ? (
                <TextInput
                    testId={testId}
                    error={error}
                    label={label}
                    value={value}
                    changeCriteria="debounced"
                    onChange={onChange}
                    maxLength={AppConstants.ipAddressMaxLength}
                />
            ) : (
                <Stack spacing="none" vertical>
                    <Text inline color="grey6">
                        {label}
                    </Text>
                    {renderSplitAddresses()}
                </Stack>
            )}
        </React.Fragment>
    );
};

IPAddressField.displayName = 'IPAddressField';
