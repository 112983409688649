import { ServiceLocator } from 'app/ioc';
import type { ISelectedPiaMount } from 'app/modules/common';
import {
    getCurrentProjectRelationsRecord,
    toCacheKey,
    getPiaItemsRecord,
    getCurrentProjectItems,
    getCurrentProjectItemRelationsArray,
    getUseProductAllowlist,
    getProductAllowlist,
    getCurrentProjectRegions,
    getCurrentProjectItemProductId,
    createDeepEqualSelector,
    getIdFromProps,
    getSelectedEnvironmentMountsForItemId,
    getSelectedDeviceMountsForItemId,
    getSelectedMounts,
    mapSelectedMountPiaId,
    getSelectedPrimaryMountPiaIdForItemId,
    getPrimaryMount,
    mapSelectedMountItemId,
} from 'app/modules/common';
import type { Id } from 'app/core/persistence';
import type { IPiaAccessory, IPiaRelationReference, PiaId } from 'app/core/pia';
import { PiaRelationTypes } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { MountService } from '../services/Mounts.service';
import { getSelectedEnvironmentPiaId } from './getEnvironments';
import type { IRelatedPiaAccessory } from '../models';
import { createSelector } from 'reselect';
import { getSelectedDeviceId } from './getSelectedDevice';
import { getFilterOutdoorForSelectedDevice } from './getIsOnlyOutdoor';

export const getPrimaryMountOptions = createCachedSelector(
    [
        getSelectedEnvironmentPiaId,
        getCurrentProjectItemProductId,
        getCurrentProjectRegions,
        getFilterOutdoorForSelectedDevice,
        getUseProductAllowlist,
        getProductAllowlist,
        getIdFromProps,
    ],
    (environmentPiaId, itemPiaId, regions, onlyOutdoor, useAllowlist, allowlist) => {
        if (environmentPiaId && itemPiaId) {
            const mountService = ServiceLocator.get(MountService);
            const primaryMounts = mountService.getPrimaryMounts(
                environmentPiaId,
                itemPiaId,
                regions,
                onlyOutdoor,
                useAllowlist ? allowlist : undefined,
            );
            return primaryMounts.map((mount) =>
                mount ? [mapToRelatedPiaAccessory(mount, itemPiaId)] : [],
            );
        }

        return new Array<IRelatedPiaAccessory[]>();
    },
)(toCacheKey);

/** Gets selected device mounts for the currently selected item in device details */
export const getSelectedMountsForSelectedItem = createSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
        getSelectedDeviceId,
        getCurrentProjectRelationsRecord,
    ],
    (piaItems, items, relations, deviceId, relationsRecord) =>
        getSelectedMounts(piaItems, items, relations, deviceId, relationsRecord, 'deviceMount'),
);

/** Gets selected primary mount for the currently selected item in device details */
export const getSelectedPrimaryMountForSelectedItem = createSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectRelationsRecord,
        getSelectedDeviceId,
    ],
    (piaItems, items, relationsRecord, deviceId): ISelectedPiaMount | undefined =>
        deviceId && relationsRecord[deviceId]
            ? getPrimaryMount(piaItems, items, relationsRecord[deviceId], deviceId)
            : undefined,
);

/** Gets selected environment mount pia ids for a specific id */
export const getSelectedEnvironmentMountPiaIdsForItemId = createDeepEqualSelector(
    [getSelectedEnvironmentMountsForItemId, getIdFromProps],
    (selectedMounts) => selectedMounts.map((mount) => mount.id),
);

/** Gets selected device mount pia ids for a specific id */
export const getSelectedDeviceMountPiaIdsForItemId = createDeepEqualSelector(
    [getSelectedDeviceMountsForItemId, getIdFromProps],
    (selectedMounts) => selectedMounts.map((mount) => mount.id),
);

/** Gets selected environment mount for the currently selected item in device details */
export const getSelectedEnvironmentMountForSelectedItem = createSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
        getSelectedDeviceId,
        getCurrentProjectRelationsRecord,
    ],
    (piaItems, items, relations, deviceId, relationsRecord) =>
        getSelectedMounts(
            piaItems,
            items,
            relations,
            deviceId,
            relationsRecord,
            'environmentMount',
        ),
);

/** Gets selected mount ids for the currently selected item in device details */
export const getSelectedMountIdsForSelectedItem = createSelector(
    [
        getSelectedMountsForSelectedItem,
        getSelectedPrimaryMountForSelectedItem,
        getSelectedEnvironmentMountForSelectedItem,
    ],
    (deviceMount, primaryMount, environmentMount): Id[] =>
        mapSelectedMountItemId(deviceMount, primaryMount, environmentMount),
);

/** Gets selected device mount PiaIds for the currently selected item in device details */
export const getSelectedMountPIAIdsForSelectedItem = createSelector(
    [
        getSelectedMountsForSelectedItem,
        getSelectedPrimaryMountForSelectedItem,
        getSelectedEnvironmentMountForSelectedItem,
    ],
    (deviceMount, primaryMount, environmentMount): PiaId[] =>
        mapSelectedMountPiaId(deviceMount, primaryMount, environmentMount),
);

export const getDeviceMountOptions = createCachedSelector(
    [
        getSelectedPrimaryMountPiaIdForItemId,
        getSelectedEnvironmentPiaId,
        getSelectedEnvironmentMountPiaIdsForItemId,
        getCurrentProjectRegions,
        getProductAllowlist,
        getUseProductAllowlist,
        getCurrentProjectItemProductId,
        getFilterOutdoorForSelectedDevice,
        getIdFromProps,
    ],
    (
        primaryMountPiaId,
        environmentPiaId,
        environmentMountsPiaIds,
        regions,
        allowlist,
        useAllowlist,
        itemProductId,
        onlyOutdoor,
    ) => {
        if (primaryMountPiaId && environmentPiaId && itemProductId) {
            const mountService = ServiceLocator.get(MountService);
            const mounts = mountService.getMounts(
                primaryMountPiaId,
                environmentPiaId,
                itemProductId,
                regions,
                undefined,
                environmentMountsPiaIds,
                useAllowlist ? allowlist : undefined,
                onlyOutdoor,
            );

            const deviceMounts = mounts.deviceMounts.map((array) =>
                array.map((mount) => mapToRelatedPiaAccessory(mount, itemProductId)),
            );

            return deviceMounts;
        }

        const emptyMatrix = new Array<IRelatedPiaAccessory[]>();
        return emptyMatrix;
    },
)(toCacheKey);

export const getEnvironmentMountOptions = createCachedSelector(
    [
        getSelectedPrimaryMountPiaIdForItemId,
        getSelectedEnvironmentPiaId,
        getSelectedDeviceMountPiaIdsForItemId,
        getCurrentProjectRegions,
        getProductAllowlist,
        getUseProductAllowlist,
        getCurrentProjectItemProductId,
        getFilterOutdoorForSelectedDevice,
        getIdFromProps,
    ],
    (
        primaryMountPiaId,
        environmentPiaId,
        selectedDeviceMountPiaIds,
        regions,
        allowlist,
        useAllowlist,
        itemProductId,
        onlyOutdoor,
    ) => {
        if (primaryMountPiaId && environmentPiaId && itemProductId) {
            const mountService = ServiceLocator.get(MountService);
            const mounts = mountService.getMounts(
                primaryMountPiaId,
                environmentPiaId,
                itemProductId,
                regions,
                selectedDeviceMountPiaIds,
                undefined,
                useAllowlist ? allowlist : undefined,
                onlyOutdoor,
            );

            const environmentMounts = mounts.environmentMounts.map((array) =>
                array.map((mount) => mapToRelatedPiaAccessory(mount, itemProductId)),
            );

            return environmentMounts;
        }

        const emptyMatrix = new Array<IRelatedPiaAccessory[]>();
        return emptyMatrix;
    },
)(toCacheKey);

const mapToRelatedPiaAccessory = (mount: IPiaAccessory, productId: number) => {
    const recommended = mount.relations.some(
        (rel: IPiaRelationReference) =>
            rel.relationType === PiaRelationTypes.RecommendedBy && rel.id === productId,
    );
    return { ...mount, isRecommended: recommended } as IRelatedPiaAccessory;
};
