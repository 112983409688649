export enum QueryOperationTypesEnum {
    Filter = 0,
    Map,
    Sort,
}

export type QueryOperation = IQueryFilterOperation | IQuerySortOperation | IQueryMapOperation;

export interface IQueryFilterOperation {
    type: QueryOperationTypesEnum.Filter;
    cb(item: any): boolean;
}
export interface IQueryMapOperation {
    type: QueryOperationTypesEnum.Map;
    cb(item: any): any;
}
export interface IQuerySortOperation {
    type: QueryOperationTypesEnum.Sort;
    cb(a: any, b: any): number;
}

export class PiaQuery<TItem> {
    constructor(
        private dataSourceCb: () => any[],
        private operations: QueryOperation[] = [],
    ) {}

    public filter(cb: (item: TItem) => boolean): PiaQuery<TItem> {
        const operation: IQueryFilterOperation = { type: QueryOperationTypesEnum.Filter, cb };
        return new PiaQuery<TItem>(this.dataSourceCb, this.operations.concat(operation));
    }

    public sort(cb: (a: any, b: any) => number): PiaQuery<TItem> {
        const operation: IQuerySortOperation = { type: QueryOperationTypesEnum.Sort, cb };
        return new PiaQuery<TItem>(this.dataSourceCb, this.operations.concat(operation));
    }

    public map<UItem>(cb: (item: TItem) => UItem): PiaQuery<UItem> {
        const operation: IQueryMapOperation = { type: QueryOperationTypesEnum.Map, cb };
        return new PiaQuery<UItem>(this.dataSourceCb, this.operations.concat(operation));
    }

    public take(n: number): TItem[] {
        const result = this.evaluate();
        return result.slice(0, n);
    }

    public takeFromEnd(n: number): TItem[] {
        const result = this.evaluate();
        return result.slice(-n);
    }

    public first(): TItem | null {
        const result = this.evaluate();
        return result.length > 0 ? result[0] : null;
    }

    public firstOrDefault<TDefault>(fallback: TDefault): TItem | TDefault {
        const result = this.evaluate();
        return result.length > 0 ? result[0] : fallback;
    }

    public last(): TItem | null {
        const result = this.evaluate();

        return result.length > 0 ? result[result.length - 1] : null;
    }

    public lastOrDefault<TDefault>(fallback: TDefault): TItem | TDefault {
        const result = this.evaluate();

        return result.length > 0 ? result[result.length - 1] : fallback;
    }

    public toList(): TItem[] {
        return this.evaluate();
    }

    public toDictionary(keySelector: (item: TItem) => string): Record<string, TItem> {
        const result = this.evaluate();
        return result.reduce((dict, item) => ({ ...dict, [keySelector(item)]: item }), {});
    }

    private evaluate(): TItem[] {
        const operations = this.operations;
        const numberOfOperations = this.operations.length;
        let currOperation;

        let data = this.dataSourceCb();

        for (let i = 0; i < numberOfOperations; i += 1) {
            currOperation = operations[i];

            switch (currOperation.type) {
                case QueryOperationTypesEnum.Filter:
                    data = data.filter(currOperation.cb);
                    break;
                case QueryOperationTypesEnum.Map:
                    data = data.map(currOperation.cb);
                    break;
                case QueryOperationTypesEnum.Sort:
                    data = data.concat().sort(currOperation.cb);
                    break;
            }
        }

        return data as TItem[];
    }
}
