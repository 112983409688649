import { getSelectedEnvironment, getEnvironments } from '../selectors/getEnvironments';
import { getSelectedNotToBeFollowedBy } from '../selectors/getNotToBeFollowedByAccessories';
import { injectable } from 'inversify';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import { PiaId, PiaRelationService } from 'app/core/pia';
import { AccessorySelectorService } from './AccessorySelector.service';
import { accessorySelectorActions as Actions } from '../actions';
import { IDeviceSearchFilter } from '../models';
import { Id } from 'app/core/persistence';

import {
    getPiaItem,
    getCurrentProjectLocation,
    getCurrentProjectRegions,
} from 'app/modules/common';
import { getFilterOutdoorForSelectedDevice } from '../selectors';

@injectable()
export class AccessorySelectorActionService {
    constructor(
        private accessorySelectorService: AccessorySelectorService,
        private piaRelationService: PiaRelationService,
    ) {}

    @ActionCreator()
    public addStandaloneItem(productId: PiaId, environmentId?: PiaId): ThunkAction {
        return async (dispatch, getState) => {
            const standaloneItemId =
                await this.accessorySelectorService.addStandaloneItem(productId);
            const selectedEnvironment = getSelectedEnvironment(getState(), standaloneItemId);
            const isOnlyOutdoor = getFilterOutdoorForSelectedDevice(getState());
            const deviceItem = getPiaItem(getState(), standaloneItemId);
            const projectLocation = getCurrentProjectLocation(getState());
            const selectedNotToBeFollowedBy = getSelectedNotToBeFollowedBy(getState());
            if (deviceItem && projectLocation && environmentId) {
                await this.accessorySelectorService.setEnvironment(
                    standaloneItemId,
                    deviceItem?.id,
                    selectedEnvironment?.id,
                    environmentId,
                    projectLocation,
                    selectedNotToBeFollowedBy,
                    isOnlyOutdoor,
                );
            }

            dispatch({
                type: Actions.AddItemId,
                payload: standaloneItemId,
            });
        };
    }

    @ActionCreator()
    public reset(itemId: Id): IAction<Promise<void>> {
        return {
            type: Actions.Reset,
            payload: this.accessorySelectorService.resetAll(itemId),
        };
    }

    @ActionCreator()
    public setDeviceSearchText(searchText: string): IAction<string> {
        return {
            type: Actions.DeviceSearchText,
            payload: searchText,
        };
    }

    @ActionCreator()
    public setAccessorySearchText(searchText: string): IAction<string> {
        return {
            type: Actions.AccessorySearchText,
            payload: searchText,
        };
    }

    @ActionCreator()
    public setDeviceFilter(deviceSearchFilter: IDeviceSearchFilter): IAction<IDeviceSearchFilter> {
        return {
            type: Actions.DeviceFilter,
            payload: deviceSearchFilter,
        };
    }

    @ActionCreator()
    public calculateSupportedEnvironments(): ThunkAction {
        return (dispatch, getState) => {
            const environments = getEnvironments(getState());
            const regions = getCurrentProjectRegions(getState());

            environments.map((environment) => {
                if (environment.productId) {
                    const compatibleDeviceIds = this.piaRelationService.getCompatibleDeviceIds(
                        environment.productId,
                        regions,
                    );
                    dispatch({
                        type: Actions.SetEnvironmentDevices,
                        payload: {
                            environmentId: environment.productId,
                            deviceIds: compatibleDeviceIds,
                        },
                    });
                }
            });
        };
    }
}
