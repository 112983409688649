import { injectable } from 'inversify';
import { BaseItemExporterService } from './BaseItemExporter.service';
import type {
    IPersistence,
    Id,
    ISensorUnitItemEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    CurrentProjectService,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import type { Frequency } from 'app/modules/common';
import {
    ProfileOverrideService,
    ProfileSupportService,
    MainUnitEstimateService,
    BandwidthCalculatorService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import type { IPiaDevice, IPiaMainUnit, IPiaSensorUnit } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type {
    IExportedMainUnit,
    IExportedAxisCamera,
    IExportablePersistedEntity,
} from '../../../models';
import { isDefined } from 'axis-webtools-util';
import { splitByQuantity } from '../../splitByQuantity';
import { toShareModelNames } from '../../mapToShareItemNames';
import { creationDateReverseComparator } from 'app/utils';

@injectable()
export class MainUnitsExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDevice>,
        projectModelService: ProjectModelService,
        private currentProjectService: CurrentProjectService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    private mapSensorUnitsToExportedSensorUnitsType = (
        items: IPersistence<ISensorUnitItemEntity>[],
    ): IExportablePersistedEntity[] => {
        const exportedTypes = items.map((item) => ({
            ...item,
            exportId: item._id,
        }));
        return exportedTypes;
    };

    public mapItemsToExportedMainUnits = async (
        projectZipSetting: ProjectZipType,
        projectId: Id,
        items: IExportablePersistedEntity[],
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
    ): Promise<IExportedMainUnit[]> => {
        const allMainUnits = items.filter(deviceTypeCheckers.isMainUnit);

        const mappedMainUnits = await Promise.all(
            allMainUnits.map(
                async (encoder) =>
                    encoder &&
                    this.mapMainUnitToExportedMainUnit(
                        projectZipSetting,
                        projectId,
                        encoder,
                        frequency,
                        projectBandwidthVersion,
                        projectRetentionTimeInDays,
                    ),
            ),
        );

        return mappedMainUnits.filter(isDefined);
    };

    private mapMainUnitToExportedMainUnit = async (
        projectZipSetting: ProjectZipType,
        projectId: Id,
        mainUnitItem: IExportablePersistedEntity,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
    ): Promise<IExportedMainUnit | null> => {
        if (!mainUnitItem.productId) {
            return null;
        }

        const mainUnitPiaDevice = this.getPiaDevice(mainUnitItem.productId) as IPiaMainUnit;
        const sensorUnits = await this.getSensorUnits(projectId, mainUnitItem._id);
        const sensorUnitsExportedTypes = this.mapSensorUnitsToExportedSensorUnitsType(sensorUnits);
        const mappedSensorUnits = await Promise.all(
            sensorUnitsExportedTypes
                .sort(creationDateReverseComparator)
                .map(async (sensorUnit) =>
                    this.mapSensorUnitsToExportedAxisCameras(
                        projectZipSetting,
                        sensorUnit,
                        mainUnitPiaDevice,
                        frequency,
                        projectBandwidthVersion,
                        projectRetentionTimeInDays,
                    ),
                ),
        );

        return {
            ...this.mapItemToExportedItemBase(mainUnitItem),
            ...toShareModelNames(mainUnitPiaDevice.name),
            piaId: mainUnitItem.productId,
            sensorUnits: splitByQuantity(mappedSensorUnits.filter(isDefined)),
        };
    };

    private mapSensorUnitsToExportedAxisCameras = async (
        projectZipSetting: ProjectZipType,
        sensorUnit: IExportablePersistedEntity,
        mainUnitProperties: IPiaMainUnit,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
    ): Promise<IExportedAxisCamera | null> => {
        if (!sensorUnit.productId || !sensorUnit.properties.sensorUnit) {
            return null;
        }
        const sensorUnitPiaDevice = this.getPiaDevice(sensorUnit.productId) as IPiaSensorUnit;
        const mergedProfile = await this.profileOverrideService.getMergedProfileFromDevice(
            sensorUnit.properties.sensorUnit,
            projectRetentionTimeInDays,
        );

        const mergedProductProperties = MainUnitEstimateService.mergeFSeriesProperties(
            mainUnitProperties,
            sensorUnitPiaDevice,
        );

        const exportedCamera = await this.mapCameraToExportedCamera(
            projectZipSetting,
            sensorUnit,
            mergedProfile,
            mergedProductProperties,
            frequency,
            projectBandwidthVersion,
        );

        return {
            ...exportedCamera,
            ...toShareModelNames(sensorUnitPiaDevice.name),
            piaId: sensorUnit.productId,
        };
    };

    private async getSensorUnits(
        projectId: Id,
        id: Id,
    ): Promise<IPersistence<ISensorUnitItemEntity>[]> {
        const sensorUnits = this.currentProjectService.isCurrentProjectLoaded()
            ? this.currentProjectService.getDeviceChildren(id, 'sensorUnit')
            : this.getDeviceChildren(projectId, id, 'sensorUnit');
        return sensorUnits as IPersistence<ISensorUnitItemEntity>[];
    }
}
