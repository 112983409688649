import type { PiaCategory } from 'app/core/pia';
import { PiaItemCameraCategory } from 'app/core/pia';
import { t } from 'app/translate';

export const getScenarioCategoryString = (
    /** The category represented. I.E category === "ptz" => "Scenario (PTZ)"" */
    category: PiaCategory | undefined,
    /** Beginning of string. Defaults to "scenario".  */
    stringStart: string = t.deviceListProfile,
): string => {
    switch (category) {
        case PiaItemCameraCategory.CAMERAEX:
            return `${stringStart} (${t.cameraTypeGROUP.explosionProtected})`;
        case PiaItemCameraCategory.COMPLETEMODULAR:
            return `${stringStart} (${t.advancedFiltersGROUP.completeModular})`;
        case PiaItemCameraCategory.FIXEDDOME:
            return `${stringStart} (${t.deviceDetailsOverviewHeading})`;
        case PiaItemCameraCategory.FIXED:
            return `${stringStart} (${t.cameraTypeGROUP.fixed})`;
        case PiaItemCameraCategory.PTZ:
            return `${stringStart} (${t.cameraTypeGROUP.ptz})`;
        case PiaItemCameraCategory.THERMAL:
            return `${stringStart} (${t.cameraTypeGROUP.thermal})`;
        default:
            return stringStart;
    }
};
