import type { ICameraPropertiesFilterEntity } from '../userDataPersistence';
import type { UnitSystem } from '../models';
import { isEqual } from 'lodash-es';
import { getDefaultCameraFilterEntity } from '../utils/defaultCameraFilterEntity';

export const isDefaultFilter = (
    currentFilter: ICameraPropertiesFilterEntity,
    installationHeight: number,
    unitSystem?: UnitSystem,
) => {
    const defaultFilter = getDefaultCameraFilterEntity(unitSystem, installationHeight);

    return isEqual(currentFilter, defaultFilter);
};
