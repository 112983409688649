import { rectifyImageBounds } from 'app/modules/common';
import { createSelector } from 'reselect';
import {
    getGeolocatedBlockers,
    getGeolocatedFloorPlans,
    getGeolocatedFreeTextPoints,
} from './geolocations';

import { getDefaultGeoMap, getMapsSortedByType, getSelectedMap } from './getFloorPlans';
import { getGeoLocatedInstallationPoints } from './getProjectInstallationPoints';

/**
 * Returns true if any item is geolocated, otherwise false
 */
const isAnyItemPlacedOnGeoMap = createSelector(
    [
        getGeolocatedFloorPlans,
        getGeoLocatedInstallationPoints,
        getGeolocatedBlockers,
        getGeolocatedFreeTextPoints,
    ],
    (floorPlans, installationPoints, blockers, freeTextPoints) => {
        return (
            floorPlans.length > 0 ||
            installationPoints.length > 0 ||
            blockers.length > 0 ||
            freeTextPoints.length > 0
        );
    },
);

/**
 * Returns the map to set when entering maps in the following priority:
 * 1. User selected map (within this session, not saved anywhere)
 * 2. Geomap if items are placed on it
 * 3. First floorplan if it exists
 * 4. Geomap
 */
export const getInitialTabMap = createSelector(
    [getSelectedMap, getDefaultGeoMap, getMapsSortedByType, isAnyItemPlacedOnGeoMap],
    (selectedMap, defaultMap, floorPlans, isAnyItemGeolocated) => {
        if (!selectedMap) {
            if (isAnyItemGeolocated && defaultMap) {
                return defaultMap;
            }
            const allFloorPlans = floorPlans.filter(
                (floorPlan) => floorPlan.mapType === 'FloorPlan',
            );
            return allFloorPlans[0] ?? defaultMap;
        }

        return rectifyImageBounds(selectedMap);
    },
);
