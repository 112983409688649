import * as React from 'react';
import { Box } from '../../containers';
import { Text } from '../../text';
import { Clickable } from '../../functional';
import type { IIconProps } from '../icon';
import { Icon } from '../icon';
import { Stack } from '../../layout';
import type { IWithChildren } from 'app/components/models';

export interface IToggleItem extends IIconProps, IWithChildren {
    active?: boolean;
    fluid?: boolean;
    id?: string;
    title?: string;
    disabled?: boolean;
    onClick?(tabId?: string): void;
}

export class ToggleItem extends React.Component<IToggleItem> {
    public render() {
        const background = this.props.active ? 'blue' : undefined;
        const color = this.props.active ? 'white' : 'grey6';

        return (
            <Clickable onClick={this.onItemClick} disabled={this.props.disabled}>
                <Box
                    testId={this.props.testId}
                    color={background}
                    justifyContent="center"
                    flex="evenSpace"
                    alignItems="center"
                    paddingX="base"
                    minWidth={this.props.fluid ? undefined : '140px'}
                >
                    <Stack spacing="half" justifyContent="center" alignItems="center">
                        {this.props.icon && <Icon color={color} {...this.props} size="sm" opaque />}
                        <Text semiBold color={color}>
                            {this.props.title ?? this.props.children}
                        </Text>
                    </Stack>
                </Box>
            </Clickable>
        );
    }

    private onItemClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.id);
        }
    };
}
