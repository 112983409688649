import * as React from 'react';
import {
    DropDown,
    PillButton,
    DropDownMenuButton,
    Text,
    Button,
    DropDownMenuNavigationButton,
    Box,
} from 'app/components';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import { t as i18nextT } from 'i18next';
import { t as translate } from 'app/translate';
import type {
    SolutionType,
    UseCaseType,
    UseCaseSubType,
    PartnerProductType,
} from 'app/modules/common';
import {
    PARTNER_PRODUCT_TYPE_ALL,
    getCurrentProjectLocation,
    getIsOtherSelectorOpen,
    getIsStandalone,
    getTipPiaLocationOptions,
    partnerProductTypes,
} from 'app/modules/common';
import { ProjectService } from 'app/core/persistence';
import { css, keyframes } from '@emotion/css';
import type { IPiaLocationOption } from 'app/modules/userProjects';
import {
    getCategorizedSortedPartnerUseCases,
    getIsAnyCountrySelected,
    getPartnerProductSolutionType,
    getPartnerProductType,
    getPartnerProductTypeAmounts,
    getPartnerProductUseCase,
    getPartnerProductsUseCaseAmounts,
    getShowResetFilterButton,
    getShowSolutionTypeOptions,
    getSolutionTypeAmounts,
    getSortedPartnerProductCategories,
} from '../selectors';
import { AddonSelectorActionService } from '../services';
import {
    getPartnerProductTypeTranslation,
    getSolutionTypeTranslation,
    getUseCaseTranslationKey,
} from '../utils';

const SMALL_MOBILE_WIDTH = 340;

const fadeInAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const resetButtonStyle = css`
    opacity: 1;
    animation: ${fadeInAnimation} 0.5s ease-out;
`;

const t = (key: string) => i18nextT(key, { ns: 'partnerProducts' });

/** Shell for third party acap filter. NOT releasable in current condition! */
export const PartnerProductFilterComponent: React.FC = () => {
    const [selectedUseCaseMainCat, setSelectedUseCaseMainCat] = React.useState<
        UseCaseType | undefined
    >(undefined);
    const actionService = useService(AddonSelectorActionService);

    const useCaseCategories = useSelector(getSortedPartnerProductCategories);

    const allUseCases = useSelector(getCategorizedSortedPartnerUseCases);

    const isUseCaseMainCat = (category: UseCaseType | UseCaseSubType): category is UseCaseType =>
        useCaseCategories.includes(category as UseCaseType);

    const solution = useSelector(getPartnerProductSolutionType);
    const selectedUseCaseSubCat = useSelector(getPartnerProductUseCase);
    const useCaseAmounts = useSelector(getPartnerProductsUseCaseAmounts);
    const productTypeAmounts = useSelector(getPartnerProductTypeAmounts);
    const solutionTypeAmounts = useSelector(getSolutionTypeAmounts);
    const showSolutionType = useSelector(getShowSolutionTypeOptions);
    const showResetFilterButton = useSelector(getShowResetFilterButton);
    const selectedPartnerProductType = useSelector(getPartnerProductType);
    const isStandAlone = useSelector(getIsStandalone);
    const isPartnerProductOtherSelector = useSelector(getIsOtherSelectorOpen);
    const selectedLocation = useSelector(getCurrentProjectLocation);
    const piaLocations = useSelector(getTipPiaLocationOptions);
    const isAnyCountrySelected = useSelector(getIsAnyCountrySelected);

    const projectService = useService(ProjectService);

    const updateSolutionType = (type: SolutionType | undefined) => {
        actionService.updatePartnerProductFilter({
            solutionType: type !== 'anyAcapArchitecture' && type !== solution ? type : undefined,
        });
    };

    const updateLocation = React.useCallback(
        (location: IPiaLocationOption) => {
            if (location.value === 'anyCountry') {
                actionService.updatePartnerProductFilter({
                    anyCountry: true,
                });
                return projectService.updateCurrentProject({
                    installationPiaLocationId: undefined,
                });
            }

            actionService.updatePartnerProductFilter({
                anyCountry: false,
            });

            return projectService.updateCurrentProject({
                installationPiaLocationId: Number(location.value),
            });
        },
        [actionService, projectService],
    );

    const updatePartnerProductType = (partnerType: PartnerProductType | undefined) => {
        // solutionType should only be used as filter if partner product type is Acap application
        // therefore we need to reset that filter if we select another product type.
        // If partner product type is changed we need to reset both useCase (i.e subType) and main use case;
        if (partnerType !== 'ACAP application') {
            actionService.updatePartnerProductFilter({
                partnerProductType: partnerType,
                solutionType: undefined,
                useCase: undefined,
            });
        } else {
            actionService.updatePartnerProductFilter({
                partnerProductType: partnerType,
                useCase: undefined,
            });
        }
        setSelectedUseCaseMainCat(undefined);
    };

    const resetFilter = () => {
        setSelectedUseCaseMainCat(undefined);
        actionService.resetPartnerProductFilterExceptCompatible();
    };

    const onBackToMainUseCase = () => {
        setSelectedUseCaseMainCat(undefined);
    };

    const [useCaseOptionsToDisplay, setUseCaseOptionsToDisplay] = React.useState(
        selectedUseCaseMainCat && allUseCases[selectedUseCaseMainCat]
            ? allUseCases[selectedUseCaseMainCat]
            : useCaseCategories,
    );

    /** Update use cases to display when main category changes */
    React.useEffect(() => {
        /** Use case options to display sorted by translated name */
        const optionsToDisplay = selectedUseCaseMainCat
            ? allUseCases[selectedUseCaseMainCat]
            : useCaseCategories;

        setUseCaseOptionsToDisplay(optionsToDisplay);
    }, [selectedUseCaseMainCat, allUseCases, useCaseCategories]);

    const onUseCaseClick = (category: UseCaseType | UseCaseSubType) => {
        if (isUseCaseMainCat(category)) {
            setSelectedUseCaseMainCat(category);
        } else {
            actionService.updatePartnerProductFilter({
                useCase: category === selectedUseCaseSubCat ? undefined : category,
            });
        }
    };

    const dropDownWidth = window.innerWidth < SMALL_MOBILE_WIDTH ? window.innerWidth * 0.6 : 290;

    return (
        <Box spacing="quart" wrap>
            {(isPartnerProductOtherSelector || isStandAlone) && (
                <DropDown
                    minWidth={dropDownWidth}
                    trigger={
                        <PillButton
                            testId="partner_product_filter"
                            icon="keyboard_arrow_down"
                            selected={!!selectedPartnerProductType}
                            text={getPartnerProductTypeTranslation(selectedPartnerProductType)}
                            style={isStandAlone ? 'axis.com' : undefined}
                        />
                    }
                    contents={partnerProductTypes
                        .filter((type) =>
                            isPartnerProductOtherSelector ? type !== 'ACAP application' : true,
                        )
                        .map(
                            (productType, index) =>
                                (productTypeAmounts[productType] || productType === 'All') && (
                                    <DropDownMenuButton
                                        testId={productType}
                                        key={`${productType}_${index}`}
                                        selected={selectedPartnerProductType === productType}
                                        onClick={() =>
                                            updatePartnerProductType(
                                                productType == PARTNER_PRODUCT_TYPE_ALL
                                                    ? undefined
                                                    : productType,
                                            )
                                        }
                                    >
                                        <Box justifyContent="between" alignItems="center">
                                            <Text>
                                                {getPartnerProductTypeTranslation(productType)}
                                            </Text>

                                            <Text color="grey5">
                                                {productTypeAmounts[productType]}
                                            </Text>
                                        </Box>
                                    </DropDownMenuButton>
                                ),
                        )}
                />
            )}
            {useCaseCategories.length > 0 && (
                <DropDown
                    minWidth={dropDownWidth}
                    trigger={
                        <PillButton
                            testId="use_case"
                            icon="keyboard_arrow_down"
                            selected={!!selectedUseCaseSubCat}
                            text={t(
                                selectedUseCaseSubCat
                                    ? getUseCaseTranslationKey(selectedUseCaseSubCat)
                                    : 'anyUseCase',
                            )}
                            style={isStandAlone ? 'axis.com' : undefined}
                        />
                    }
                    contents={
                        <>
                            {/* Button for going back to main use case categories */}
                            {selectedUseCaseMainCat && (
                                <DropDownMenuNavigationButton
                                    direction="back"
                                    label={t('useCase')}
                                    onNavigate={onBackToMainUseCase}
                                />
                            )}
                            {/* Button for selecting all 'Any use case' */}
                            {!selectedUseCaseMainCat && (
                                <DropDownMenuButton
                                    testId={t('anyUseCase')}
                                    selected={false}
                                    onClick={() =>
                                        actionService.updatePartnerProductFilter({
                                            useCase: undefined,
                                        })
                                    }
                                >
                                    <Box justifyContent="between" alignItems="center">
                                        <Text>{t('anyUseCase')}</Text>
                                        <Text color="grey5">
                                            {useCaseAmounts['anyUseCase'] ?? 0}
                                        </Text>
                                    </Box>
                                </DropDownMenuButton>
                            )}
                            {useCaseOptionsToDisplay.map((useCase, index) => {
                                return isUseCaseMainCat(useCase) ? (
                                    <DropDownMenuNavigationButton
                                        testId={t(getUseCaseTranslationKey(useCase))}
                                        key={`${useCase}_${index}`}
                                        selected={allUseCases[useCase].includes(
                                            selectedUseCaseSubCat as UseCaseSubType,
                                        )}
                                        direction="forward"
                                        label={t(getUseCaseTranslationKey(useCase))}
                                        onNavigate={() => onUseCaseClick(useCase)}
                                    />
                                ) : (
                                    <DropDownMenuButton
                                        testId={t(getUseCaseTranslationKey(useCase))}
                                        key={`${useCase}_${index}`}
                                        selected={useCase === selectedUseCaseSubCat}
                                        onClick={() => onUseCaseClick(useCase)}
                                    >
                                        <Box justifyContent="between" alignItems="center">
                                            <Text>{t(getUseCaseTranslationKey(useCase))}</Text>
                                            <Text color="grey5">
                                                {useCaseAmounts[useCase] ?? 0}
                                            </Text>
                                        </Box>
                                    </DropDownMenuButton>
                                );
                            })}
                        </>
                    }
                />
            )}
            {showSolutionType && (
                <DropDown
                    minWidth={dropDownWidth}
                    trigger={
                        <PillButton
                            testId="solution_type"
                            icon="keyboard_arrow_down"
                            selected={!!solution}
                            text={getSolutionTypeTranslation(solution)}
                            style={isStandAlone ? 'axis.com' : undefined}
                        />
                    }
                    contents={
                        <>
                            {(Object.keys(solutionTypeAmounts) as SolutionType[]).map(
                                (solutionTypeOption, index) => (
                                    <DropDownMenuButton
                                        testId={`${solutionTypeOption}`}
                                        key={`${solutionTypeOption}_${index}`}
                                        selected={solution === solutionTypeOption}
                                        onClick={() => updateSolutionType(solutionTypeOption)}
                                    >
                                        <Box justifyContent="between" alignItems="center">
                                            <Text whiteSpace="nowrap">
                                                {getSolutionTypeTranslation(solutionTypeOption)}
                                            </Text>
                                            <Text color="grey5">
                                                {solutionTypeAmounts[solutionTypeOption]}
                                            </Text>
                                        </Box>
                                    </DropDownMenuButton>
                                ),
                            )}
                        </>
                    }
                />
            )}
            {isStandAlone && (
                <DropDown
                    minWidth={dropDownWidth}
                    trigger={
                        <PillButton
                            testId="partner_location"
                            icon="keyboard_arrow_down"
                            selected={!isAnyCountrySelected && !!selectedLocation?.name}
                            text={
                                isAnyCountrySelected
                                    ? t('anyCountry')
                                    : selectedLocation?.name ?? t('projectLocation')
                            }
                            style={isStandAlone ? 'axis.com' : undefined}
                        />
                    }
                    contents={piaLocations.map((location) => (
                        <DropDownMenuButton
                            testId={location.text}
                            key={location.value}
                            label={location.text}
                            selected={
                                !isAnyCountrySelected && selectedLocation?.id === location.value
                            }
                            onClick={() => updateLocation(location)}
                        />
                    ))}
                />
            )}
            {showResetFilterButton && (
                <div className={resetButtonStyle}>
                    <Button text onClick={resetFilter}>
                        {translate.reset}
                    </Button>
                </div>
            )}
        </Box>
    );
};

PartnerProductFilterComponent.displayName = 'PartnerProductFilterComponent';
