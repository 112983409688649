import { t } from 'app/translate';
import * as React from 'react';
import { Range, Stack, Action, Box } from 'app/components';
import type { IDesiredCamera } from 'app/modules/common';
import { DistanceRange, getCurrentProjectDisplayUnit } from 'app/modules/common';
import { trigonometry } from 'axis-webtools-util';
import type { DistanceUnit } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { DeviceSelector3dView } from './DeviceSelector3dView';
import type { IStoreState } from 'app/store';
import type { ISensorUnitFilter, CameraFilter } from '../../../models';
import { getSelectedPanoramaMode } from './util';
import { getSelectedCamera, getSelectedSensorUnit } from '../../../selectors';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../../../services';
import { PixelDensity } from '../../PixelDensity.component';

interface ISceneFilterSettingsContainerProps {
    updateFilter(updatedFilter: Partial<ISensorUnitFilter> | Partial<CameraFilter>): void;
}

export const SceneFilterSettingsContainer: React.FC<ISceneFilterSettingsContainerProps> = ({
    updateFilter,
}) => {
    const [actionService] = React.useState(ServiceLocator.get(DeviceSelectorActionService));
    const displayUnit = useSelector<IStoreState, DistanceUnit>((store) =>
        getCurrentProjectDisplayUnit(store),
    );

    const desiredFilter = useSelector<IStoreState, IDesiredCamera>((store) =>
        store.deviceSelector.deviceGroup === 'cameras'
            ? store.deviceSelector.cameraFilter.desiredCamera
            : store.deviceSelector.sensorUnitFilter.desiredSensorUnit,
    );

    const selectedDevice = useSelector((state: IStoreState) =>
        state.deviceSelector.deviceGroup === 'cameras'
            ? getSelectedCamera(state)
            : getSelectedSensorUnit(state),
    );

    const bigScene3dVisible = useSelector<IStoreState, boolean>(
        (store) => store.deviceSelector.bigScene3dVisible,
    );

    return (
        <>
            <Stack vertical>
                <Range
                    testId="npt_horizon_fov"
                    color={'blue'}
                    min={1}
                    max={180}
                    step={1}
                    showValue={true}
                    showValueInLabel={true}
                    hideValueTrailingSpace={true}
                    label={t.cameraSelectorFieldOfViewHorizontalFieldOfView}
                    onChange={(newValue: number) =>
                        updateFilter({
                            isSceneFilterActive: true,
                            horizontalFOVRadians: trigonometry.toRadians(newValue),
                            panoramaMode: getSelectedPanoramaMode(
                                selectedDevice,
                                desiredFilter.panoramaMode,
                                trigonometry.toRadians(newValue),
                            ),
                        })
                    }
                    value={trigonometry.toDegrees(desiredFilter.horizontalFOVRadians)}
                    unit="&#176;"
                    changeCriteria="key"
                >
                    <Stack>
                        <span>{t.cameraSelectorFieldOfViewSetTo}</span>
                        <Action
                            onClick={() => {
                                updateFilter({
                                    isSceneFilterActive: true,
                                    horizontalFOVRadians: trigonometry.toRadians(20),
                                    panoramaMode: getSelectedPanoramaMode(
                                        selectedDevice,
                                        desiredFilter.panoramaMode,
                                        trigonometry.toRadians(20),
                                    ),
                                });
                            }}
                            title={t.cameraSelectorFieldOfViewTele}
                        />
                        <Action
                            onClick={() => {
                                updateFilter({
                                    isSceneFilterActive: true,
                                    horizontalFOVRadians: trigonometry.toRadians(55),
                                    panoramaMode: getSelectedPanoramaMode(
                                        selectedDevice,
                                        desiredFilter.panoramaMode,
                                        trigonometry.toRadians(55),
                                    ),
                                });
                            }}
                            title={t.cameraSelectorFieldOfViewNormal}
                        />
                        <Action
                            onClick={() => {
                                updateFilter({
                                    isSceneFilterActive: true,
                                    horizontalFOVRadians: trigonometry.toRadians(90),
                                    panoramaMode: getSelectedPanoramaMode(
                                        selectedDevice,
                                        desiredFilter.panoramaMode,
                                        trigonometry.toRadians(90),
                                    ),
                                });
                            }}
                            title={t.cameraSelectorFieldOfViewWide}
                        />
                        <Action
                            onClick={() => {
                                updateFilter({
                                    isSceneFilterActive: true,
                                    horizontalFOVRadians: trigonometry.toRadians(180),
                                    panoramaMode: getSelectedPanoramaMode(
                                        selectedDevice,
                                        desiredFilter.panoramaMode,
                                        trigonometry.toRadians(180),
                                    ),
                                });
                            }}
                            title={t.cameraSelectorFieldOfViewPanoramic}
                        />
                    </Stack>
                </Range>
                <DistanceRange
                    testId="npt_distance_to_target"
                    color={'blue'}
                    displayUnit={displayUnit}
                    min={1}
                    max={6000}
                    step={0.1}
                    showValue={true}
                    showValueInLabel={true}
                    label={t.cameraSelectorFieldOfViewDistanceToTarget}
                    logarithmic={true}
                    onChange={(newDistance) =>
                        updateFilter({
                            isSceneFilterActive: true,
                            distanceToTarget: newDistance,
                        })
                    }
                    value={desiredFilter.distanceToTarget}
                    decimals={1}
                    changeCriteria="key"
                />
                <DistanceRange
                    testId="npt_target_height"
                    color={'blue'}
                    displayUnit={displayUnit}
                    min={0}
                    max={50}
                    step={0.1}
                    showValue={true}
                    showValueInLabel={true}
                    label={t.cameraSelectorFieldOfViewTargetHeight}
                    onChange={(newHeight) =>
                        updateFilter({
                            isSceneFilterActive: true,
                            targetHeight: newHeight,
                        })
                    }
                    value={desiredFilter.targetHeight}
                    decimals={1}
                    changeCriteria="key"
                />
                <DistanceRange
                    testId="npt_installation_height"
                    color={'blue'}
                    displayUnit={displayUnit}
                    min={1}
                    max={50}
                    step={0.1}
                    showValue={true}
                    showValueInLabel={true}
                    label={t.cameraSelectorFieldOfViewInstallationHeight}
                    onChange={(newHeight) =>
                        updateFilter({
                            isSceneFilterActive: true,
                            installationHeight: newHeight,
                        })
                    }
                    value={desiredFilter.installationHeight}
                    decimals={1}
                    changeCriteria="key"
                />
                <PixelDensity
                    pixelDensity={desiredFilter.pixelDensity}
                    max={2000}
                    displayUnit={displayUnit}
                    onPixelDensityChange={(newPixelDensity) =>
                        updateFilter({
                            isSceneFilterActive: true,
                            pixelDensity: newPixelDensity,
                        })
                    }
                />
                <Box height="275px" width="100%">
                    <DeviceSelector3dView
                        desiredFilter={desiredFilter}
                        onChangeInstallationHeight={(newHeight) =>
                            updateFilter({
                                installationHeight: newHeight,
                                isSceneFilterActive: true,
                            })
                        }
                        onChangeTargetDistance={(newDistance) =>
                            updateFilter({
                                distanceToTarget: newDistance,
                                isSceneFilterActive: true,
                            })
                        }
                        onChangePanoramaMode={(panoramaMode) =>
                            updateFilter({ panoramaMode, isSceneFilterActive: true })
                        }
                        onExpand={
                            bigScene3dVisible
                                ? actionService.hideBigScene3d
                                : actionService.showBigScene3d
                        }
                    />
                </Box>
            </Stack>
        </>
    );
};

SceneFilterSettingsContainer.displayName = 'SceneFilterSettings';
