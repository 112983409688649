import * as React from 'react';
import { ColorsEnum } from 'app/styles';
import { css, cx } from '@emotion/css';

const wrapperStyle = {
    base: css`
        position: absolute;
        left: 0;
        top: 60px;
        width: 100%;
        visibility: visible;
        transition: all 500ms;
    `,
    hidden: css`
        opacity: 0;
    `,
};

const barStyle = css`
    background-color: ${ColorsEnum.yellow4};
    box-shadow: 0 1px 3px 1px rgba(70, 70, 70, 0.12);
    height: 2px;
    transition: all 300ms ease;
`;

interface IProgressBarProps {
    /**
     * Progress in percentage to be represented by bar
     */
    percent: number;
}

export class ProgressBar extends React.Component<IProgressBarProps> {
    public render() {
        return (
            <div
                className={cx(wrapperStyle.base, {
                    [wrapperStyle.hidden]: this.props.percent === 0 || this.props.percent > 99,
                })}
            >
                <div className={barStyle} style={{ width: this.props.percent + '%' }}></div>
            </div>
        );
    }
}
