import * as L from 'leaflet';
import { isEqual } from 'lodash-es';
import { css } from '@emotion/css';
import {
    getOffset,
    offset,
    getBearing,
    rotate,
    roundAngle,
    distanceFromOrigo,
} from 'axis-webtools-util';

import { ColorsEnum } from 'app/styles';
import { EventsEnum } from './Events';
import { KeysEnum } from './Constants';
import type { ILatLng } from 'app/core/persistence';

const MARKER_ZINDEX_OFFSET = 3000;
const MOUSE_TRACKER_ZINDEX_OFFSET = 2000;

const pointStyle = css`
    background-color: ${ColorsEnum.white};
    border: 1px solid ${ColorsEnum.black};
    box-sizing: border-box;
    outline: none;
`;

const dummyMouseMarkerStyle = css`
    background-color: transparent;
    cursor: crosshair !important;
    outline: none;
`;

const pointIcon = new L.DivIcon({
    html: `<div>`,
    className: pointStyle,
    iconSize: [20, 20],
});

const mouseTrackerMarkerIcon = new L.DivIcon({
    html: `<div>`,
    className: dummyMouseMarkerStyle,
    iconSize: [20, 20],
});

export class PolylineHandler extends L.Handler {
    private latLngs: ILatLng[] = [];
    private markers: L.Marker[] = [];
    private polyline = new L.Polyline([], { color: ColorsEnum.blue6 });
    private drawPreview = new L.Polyline([], {
        dashArray: '10, 10',
        color: ColorsEnum.blue6,
    });
    private shouldSnap: boolean = false;
    private currentLatLng: ILatLng = new L.LatLng(0, 0);

    // in order to catch all mouse events under the mouse pointer and block interaction
    // with other map elements, we keep a transparent dummy marker below the mouse pointer
    private mouseTrackerMarker = L.marker([0, 0], {
        icon: mouseTrackerMarkerIcon,
        zIndexOffset: MOUSE_TRACKER_ZINDEX_OFFSET,
    });

    constructor(private map: L.Map) {
        super(map);
    }

    /**
     * Lifecycle method that is called when the handler is enabled.
     */
    public addHooks() {
        const map = this.map;

        this.polyline.addTo(map);
        this.drawPreview.addTo(map);

        map.on('mousemove', this.onMouseMove, this);
        L.DomEvent.on(window.document.documentElement, 'keyup', this.onKeyUp, this);
        L.DomEvent.on(window.document.documentElement, 'keydown', this.onKeyDown, this);

        this.map.fire(EventsEnum.CreateStart);

        this.mouseTrackerMarker
            .on('mousedown', this.onMapClick, this)
            .on('contextmenu', this.onRightClick, this)
            .addTo(map);
    }

    /**
     * Lifecycle method that is called when the handler is disabled.
     */
    public removeHooks() {
        const map = this.map;

        this.cancelDrawing();
        this.polyline.removeFrom(map);
        this.drawPreview.removeFrom(map);

        map.off('mousemove', this.onMouseMove, this);
        L.DomEvent.off(window.document.documentElement, 'keyup', this.onKeyUp, this);
        L.DomEvent.off(window.document.documentElement, 'keydown', this.onKeyDown, this);

        this.mouseTrackerMarker
            .off('mousedown', this.onMapClick, this)
            .off('contextmenu', this.onRightClick, this)
            .removeFrom(map);

        map.fire(EventsEnum.CreateStop);
    }

    /**
     * Saves the current polyline and fires the Created event.
     */
    public save() {
        this.map.fire(EventsEnum.Created, { layer: this.polyline });
        this.latLngs = [];
        this.update();
    }

    /**
     * Returns the last latLng in the polyline.
     */
    private getLastLatLng(): ILatLng | undefined {
        return this.latLngs[this.latLngs.length - 1];
    }

    /**
     * Snaps the given latLng to the nearest 15 degree angle.
     * If snapping is disabled, the latLng is returned as is.
     *
     * @param latLng The point to snap
     * @returns The snapped point
     */
    private snap(latLng: ILatLng) {
        const lastLatLng = this.getLastLatLng();
        if (!this.shouldSnap || !lastLatLng) {
            // if snapping is disabled or there are no previous point to snap to,
            // just return the new point
            return latLng;
        }

        // get the metric offset from lastLatLng to latLng
        const metricOffset = getOffset(lastLatLng)(latLng);

        // convert to polar coordinates
        const distance = distanceFromOrigo(metricOffset);
        const alpha = getBearing(lastLatLng, latLng);

        // round angle to nearest 15 degree (360/24) value
        const roundedAlpha = roundAngle(24)(alpha);

        // convert back to Cartesian coordinates
        const snappedOffset = rotate(roundedAlpha)([0, distance]);

        // calculate the new latlng
        return offset(lastLatLng)(snappedOffset);
    }

    /**
     * Updates the polyline and markers to reflect the current state of the latLngs array.
     */
    private update() {
        if (this.latLngs.length > 0) {
            this.map.fire(EventsEnum.Creating);
        } else {
            this.map.fire(EventsEnum.CreateStart);
        }

        this.polyline.setLatLngs(this.latLngs.map(({ lat, lng }) => [lat, lng]));
        this.markers.forEach((marker) => {
            marker.off('mousedown', this.onMarkerClick, this);
            marker.removeFrom(this.map);
        });
        this.markers = [];
        this.latLngs.forEach(({ lat, lng }) => {
            const marker = L.marker([lat, lng], {
                icon: pointIcon,
                zIndexOffset: MARKER_ZINDEX_OFFSET,
            });

            marker.on('mousedown', this.onMarkerClick, this);

            marker.addTo(this.map);
            this.markers.push(marker);
        });

        this.drawPreview.setLatLngs([]);
    }

    /**
     * Cancels the current drawing and clears the latLngs array.
     */
    private cancelDrawing() {
        this.latLngs = [];
        this.update();
    }

    /**
     * Enables or disables snapping
     */
    private setSnap(value: boolean) {
        this.shouldSnap = value;
        // Enable/disable box zoom (if enabled), since it conflicts with SHIFT key when drawing snappy blockers.
        if (!this.map.options.boxZoom) return;

        if (value) {
            this.map.boxZoom.disable();
        } else {
            this.map.boxZoom.enable();
        }
    }

    /**
     * called when the user releases a key
     */
    private onKeyUp(event: Event) {
        const keyCode = (event as KeyboardEvent).keyCode;
        switch (keyCode) {
            case KeysEnum.Enter:
                // save and disable drawing
                this.save();
                this.disable();
                break;
            case KeysEnum.Esc:
                // cancel drawing and disable
                this.cancelDrawing();
                this.disable();
                break;
            case KeysEnum.Shift:
                // disable snapping
                this.setSnap(false);

                // Update the preview line to the current mouse position
                // (it was previously snapped, so we need to update it)
                this.updatePreviewLine(this.currentLatLng);
                break;
        }
    }

    /**
     * called when the user presses a key
     */
    private onKeyDown(event: Event) {
        const keyCode = (event as KeyboardEvent).keyCode;
        if (keyCode === KeysEnum.Shift) {
            // enable snapping
            this.setSnap(true);

            // Update the preview line, taking snapping into account
            this.updatePreviewLine(this.currentLatLng);
        }
    }

    /**
     * called when the user moves the mouse
     */
    private onMouseMove(event: L.LeafletMouseEvent) {
        const latLng = event.latlng;

        // Store the current mouse position
        this.currentLatLng = latLng;

        // Update the mouse tracker position to keep it below the mouse
        this.mouseTrackerMarker.setLatLng(latLng);

        // Update the preview line to the current mouse position
        this.updatePreviewLine(latLng);
    }

    /*
     * Draws a preview line from the last point in the blocker to the given latLng.
     * Used to show the user where the next point will be added.
     * If snapping is enabled, the preview line will snap to the nearest 15 degree angle.
     */
    private updatePreviewLine(latLng?: ILatLng) {
        if (!latLng) return;

        const lastLatLng = this.getLastLatLng();
        if (!lastLatLng) {
            this.drawPreview.setLatLngs([]);
        } else {
            const snapPoint = this.snap(latLng);
            this.drawPreview.setLatLngs([lastLatLng, snapPoint]);
        }
    }

    /**
     * Called when the user right-clicks on the map.
     */
    private onRightClick(event: L.LeafletMouseEvent) {
        event.originalEvent.stopPropagation();

        this.cancelDrawing();
    }

    /*
     * Called when a user clicks on the map while drawing a polyline.
     * Adds a new latLng to the polyline, taking snapping into account.
     */
    private onMapClick(event: L.LeafletMouseEvent) {
        event.originalEvent.stopPropagation();
        this.addLatLng(this.snap(event.latlng));
    }

    /*
     * Called when a user clicks on a marker while drawing a polyline.
     * Adds a new latLng to the polyline at the marker's position.
     */
    private onMarkerClick(event: L.LeafletMouseEvent) {
        event.originalEvent.stopPropagation();
        this.addLatLng(event.latlng);
    }

    private addLatLng(latLng: ILatLng) {
        const shouldCloseLine = this.latLngs.length > 1 && isEqual(latLng, this.latLngs[0]);
        const shouldEndLine = this.latLngs.length > 1 && isEqual(latLng, this.getLastLatLng());

        if (shouldCloseLine) {
            this.latLngs.push(this.latLngs[0]);
            this.update();
            this.save();
        } else if (shouldEndLine) {
            this.update();
            this.save();
        } else {
            this.latLngs.push(latLng);
            this.update();
        }
    }
}
