import { createCachedSelector } from 're-reselect';
import i18next from 'i18next';
import { getVendor } from './getVendor';
import { getPiaItemsRecord } from '../../piaDevices';
import { getPiaIdFromProps } from '../../selectors';
import type { LanguageCode } from 'app/utils';
import { toCacheKey } from '../../cacheKey';

const supportedLanguages = new Set([
    'zhhans',
    'zhhant',
    'de',
    'en',
    'es',
    'fr',
    'it',
    'ja',
    'ko',
    'pt',
    'ru',
]);
const supportedVendors = ['Axis'];

export const getShouldShowPiaDescriptionForItemId = createCachedSelector([getVendor], (vendor) => {
    if (vendor === undefined) return false;

    const selectedLanguageCode = i18next.language as LanguageCode;
    const supportedLanguage = supportedLanguages.has(selectedLanguageCode);
    const supportedVendor = supportedVendors.includes(vendor);

    return supportedLanguage && supportedVendor;
})(toCacheKey);

export const getShouldShowPiaDescriptionForPiaId = createCachedSelector(
    [getPiaItemsRecord, getPiaIdFromProps],
    (piaItemsRecord, piaId) => {
        if (!piaId) return false;

        const vendor = piaItemsRecord[piaId]?.properties.vendor;

        const selectedLanguageCode = i18next.language as LanguageCode;
        const supportedLanguage = supportedLanguages.has(selectedLanguageCode);
        const supportedVendor = !vendor || supportedVendors.includes(vendor);
        return supportedLanguage && supportedVendor;
    },
)(toCacheKey);
