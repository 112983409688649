import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import type { IInstallationPointModel } from 'app/core/persistence';
import { isCamera, DistanceUnit } from 'app/core/persistence';
import type { PanCameraType } from 'app/core/pia';
import {
    getCurrentProjectDisplayUnit,
    DistanceRange,
    hasVirtualRelatedItems,
} from 'app/modules/common';
import { Border, Box, Card, EditableText, Heading, Stack, Text } from 'app/components';
import { ViewDetails } from './ViewDetails.container';
import { SensorSettings } from './SensorSettings.container';
import { AppConstants } from 'app/AppConstants';

interface ICameraContextItemOwnProps {
    installationPoint: IInstallationPointModel;
    installationHeight: number;
    onInstallationHeightChange(value: number): void;
    onNameChange(name: string): void;
}

interface ICameraContextItemProps extends ICameraContextItemOwnProps {
    displayUnit: DistanceUnit;
    maxInstallationHeight: number;
    panCameraType?: PanCameraType;
    isAnalogCamera: boolean;
    hasVirtualProduct: boolean;
    name?: string;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ICameraContextItemOwnProps,
): ICameraContextItemProps => {
    const displayUnit = getCurrentProjectDisplayUnit(storeState);

    const piaCamera = isCamera(ownProps.installationPoint.parentPiaDevice)
        ? ownProps.installationPoint.parentPiaDevice
        : undefined;

    const isAnalogCamera = !!ownProps.installationPoint?.parentDevice.properties.analogCamera;

    const maxInstallationHeight = displayUnit === DistanceUnit.Meter ? 50 : 150;
    const panCameraType = piaCamera?.properties.panCameraType;
    const hasVirtualProduct = piaCamera ? hasVirtualRelatedItems(storeState, piaCamera.id) : false;

    return {
        ...ownProps,
        displayUnit,
        maxInstallationHeight,
        panCameraType,
        isAnalogCamera,
        hasVirtualProduct,
        name: ownProps.installationPoint.name,
    };
};

const CameraContextItemContainer: React.FC<ICameraContextItemProps> = ({
    panCameraType,
    displayUnit,
    installationHeight,
    maxInstallationHeight,
    isAnalogCamera,
    onInstallationHeightChange,
    onNameChange,
    name,
    hasVirtualProduct,
}) => {
    if (panCameraType === 'Multidirectional' || hasVirtualProduct) {
        return (
            <>
                <Card paddingY="base">
                    <Stack vertical>
                        <Border bottomWidth={1} color="grey2">
                            <Box width="100%" paddingBottom="base" paddingLeft="base">
                                <Heading width="100%">
                                    <EditableText
                                        value={name ?? ''}
                                        maxLength={AppConstants.deviceNameMaxLength}
                                        onChange={onNameChange}
                                        placeholder={t.name}
                                    />
                                </Heading>
                            </Box>
                        </Border>
                        <Box paddingX="base">
                            <SensorSettings />
                        </Box>
                    </Stack>
                </Card>
                <Text style="semibold" color="grey5">
                    {t.installation}
                </Text>
                <Card paddingY="base" paddingX="base">
                    <Stack vertical>
                        <DistanceRange
                            color={'blue'}
                            displayUnit={displayUnit}
                            max={maxInstallationHeight}
                            min={1}
                            step={0.1}
                            showValue
                            showValueInLabel
                            label={t.installationHeight}
                            onChange={onInstallationHeightChange}
                            value={installationHeight}
                            decimals={1}
                            changeCriteria="key"
                        />
                    </Stack>
                </Card>
            </>
        );
    } else {
        return (
            <Card paddingY="base">
                <Stack vertical>
                    <Border bottomWidth={1} color="grey2">
                        <Box width="100%" paddingBottom="base" paddingLeft="base">
                            <Heading width="100%">
                                <EditableText
                                    value={name ?? ''}
                                    maxLength={AppConstants.deviceNameMaxLength}
                                    onChange={onNameChange}
                                    placeholder={t.name}
                                    testId="name_of_camera"
                                />
                            </Heading>
                        </Box>
                    </Border>
                    {/* Hide resolution preview for analog cameras */}
                    <Box paddingX="base" direction="column">
                        {!isAnalogCamera && <ViewDetails />}
                        <DistanceRange
                            testId="installation_height_npt"
                            color={'blue'}
                            displayUnit={displayUnit}
                            max={maxInstallationHeight}
                            min={1}
                            step={0.1}
                            showValue
                            showValueInLabel
                            label={t.installationHeight}
                            onChange={onInstallationHeightChange}
                            value={installationHeight}
                            decimals={1}
                            changeCriteria="key"
                        />
                        <SensorSettings />
                    </Box>
                </Stack>
            </Card>
        );
    }
};

export const CameraContextItem = connect(mapStateToProps)(CameraContextItemContainer);

CameraContextItem.displayName = 'CameraContextItem';
