import type { IPiaItem, PiaId } from 'app/core/pia';
import { t } from 'app/translate';
import { PiaItemState, PiaItemNonExistentCategory } from 'app/core/pia';

const NON_EXISTING_PIA_ITEM: IPiaItem = {
    id: 0,
    name: '',
    category: PiaItemNonExistentCategory.NON_EXISTING_PRODUCT,
    categories: [],
    externallyHidden: false,
    state: PiaItemState.EXTERNALLY_ANNOUNCED,
    relations: [],
    versions: [],
    properties: {
        vendor: 'Axis',
    },
    parentId: -1,
};

/**
 * Return a fallback PiaItem for a given productId. This is used when the PiaItem is not available
 * in the PIA database. This can happen when a product is erroneously published to the PIA database
 * and then removed.
 */
export const getFallbackPiaItem = (productId: PiaId): IPiaItem => {
    return {
        ...NON_EXISTING_PIA_ITEM,
        name: t.notAvailable,
        id: productId,
    };
};
