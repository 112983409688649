import * as React from 'react';
import { connect } from 'react-redux';
import type { Id } from 'app/core/persistence';
import { CoreProfileNameError, ItemService } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import {
    PropertyOverrideText,
    StorageBandwidthInfo,
    getProfileOptions,
    getCurrentProjectItem,
    ProfileOverrideService,
    getAssociatedProfile,
    getProfileOverride,
    getDeviceBandwidthPerDevice,
    getDeviceCount,
} from 'app/modules/common';
import { CreateProfileModal } from './components';
import type { IOptionProps } from 'app/components';
import { IconText, Box, Stack, Select, Text, Action } from 'app/components';
import { convert, format } from 'axis-webtools-util';
import { t } from 'app/translate';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { toaster } from 'app/toaster';

export interface IDeviceProfileOwnProps {
    deviceId: Id;
}

interface IDeviceProfileProps {
    deviceId: Id;
    hasOverrides: boolean;
    selectedProfile?: Id;
    profiles: IOptionProps[];
    customBandwidth?: number;
    hasLiveView: boolean;
    disabledCustomBandwidth: boolean;
    recordingBandwidth?: string;
    liveViewBandwidth?: string;
    storage?: string;
    cameraCount: number;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceProfileOwnProps,
): IDeviceProfileProps => {
    const profile = getAssociatedProfile(state, ownProps.deviceId);
    const profileOverride = getProfileOverride(state, ownProps.deviceId);
    const bandwidthEstimate = getDeviceBandwidthPerDevice(state, ownProps.deviceId);
    const isGenericDevice = getCurrentProjectItem(state, ownProps.deviceId)?.productId === null;
    const isAnalogCamera =
        getCurrentProjectItem(state, ownProps.deviceId)?.properties.analogCamera !== undefined;

    const item = getCurrentProjectItem(state, ownProps.deviceId);
    const customCameraBandwidth = item?.properties.camera?.customCameraProperties?.bandwidth;

    const customBandwidth = customCameraBandwidth
        ? convert.toMega(customCameraBandwidth)
        : profileOverride?.customBandwidth
          ? convert.toMega(profileOverride.customBandwidth)
          : profileOverride?.customBandwidth;

    return {
        selectedProfile: profile?._id,
        profiles: getProfileOptions(state),
        disabledCustomBandwidth: isGenericDevice && !isAnalogCamera,
        cameraCount: getDeviceCount(state, ownProps.deviceId),
        hasLiveView:
            Boolean(profile?.liveView.schedule) || Boolean(profileOverride?.liveView.schedule),
        hasOverrides: ProfileOverrideService.getHasOverrides(profileOverride),
        customBandwidth,
        liveViewBandwidth: customCameraBandwidth
            ? format.bandwidth(customCameraBandwidth)
            : bandwidthEstimate?.formattedLiveViewBandwidth,
        recordingBandwidth: customCameraBandwidth
            ? format.bandwidth(customCameraBandwidth)
            : bandwidthEstimate?.formattedRecordingBandwidth,
        storage: bandwidthEstimate?.formattedStorage,
        deviceId: ownProps.deviceId,
    };
};

interface IDeviceProfileState {
    showModal: boolean;
}

class DeviceProfileContainer extends React.Component<IDeviceProfileProps, IDeviceProfileState> {
    private itemService: ItemService;
    private profileOverrideService: ProfileOverrideService;

    constructor(props: IDeviceProfileProps) {
        super(props);
        this.state = { showModal: false };
        this.itemService = ServiceLocator.get(ItemService);
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public render() {
        return (
            <>
                <Box justifyContent="between" alignItems="center">
                    <Stack>
                        <Box alignItems="center">
                            <Text whiteSpace="pre">{t.deviceDetailsProfileUsing} </Text>
                            <Select
                                value={this.props.selectedProfile}
                                options={this.props.profiles}
                                onChange={this.onChangeProfile}
                            />
                            {this.props.hasOverrides && (
                                <>
                                    <Text whiteSpace="pre"> </Text>
                                    <PropertyOverrideText
                                        text={t.deviceDetailsProfileWithCustomSettings}
                                        propertyValue={true}
                                        alignRight
                                    />
                                </>
                            )}
                        </Box>
                        {this.props.hasOverrides && (
                            <>
                                <Action
                                    disabled={!this.props.hasOverrides}
                                    title={t.deviceDetailsProfileResetToOriginalProfile}
                                    onClick={this.onResetToOriginal}
                                />
                                {!this.props.customBandwidth && (
                                    <Action
                                        disabled={!this.props.hasOverrides}
                                        title={t.deviceDetailsProfileCreateProfileAction}
                                        onClick={this.onCreateProfileClick}
                                    />
                                )}
                            </>
                        )}
                        {!this.props.selectedProfile && (
                            <IconText icon="warning" color="red">
                                {t.deviceListNoProfileSelected}
                            </IconText>
                        )}
                    </Stack>
                    <StorageBandwidthInfo
                        hasLiveView={this.props.hasLiveView}
                        formattedBandwidth={this.props.recordingBandwidth}
                        formattedLiveView={this.props.liveViewBandwidth}
                        formattedStorage={this.props.storage}
                        cameraCount={this.props.cameraCount}
                        customBandwidth={this.props.customBandwidth}
                        customBandwidthChange={this.onCustomBandwidthChange}
                        disabledCustomBandwidth={this.props.disabledCustomBandwidth}
                    />
                </Box>
                {this.state.showModal && (
                    <CreateProfileModal
                        onClose={this.onClose}
                        onCreateProfile={this.onCreateProfile}
                    />
                )}
            </>
        );
    }

    private onCustomBandwidthChange = (newVal: number | undefined) => {
        eventTracking.logUserEvent('Project Devices', 'Custom Bandwidth Change');
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            customBandwidth: newVal ? convert.fromMega(newVal) : undefined,
        });
    };

    private onChangeProfile = (profileId: Id) => {
        this.itemService.updateProfile(this.props.deviceId, profileId);
    };

    private onResetToOriginal = () => {
        this.profileOverrideService.resetProfileOverride(this.props.deviceId);
    };

    private onCreateProfileClick = () => {
        this.setState({ showModal: true });
    };

    private onCreateProfile = async (name: string) => {
        try {
            eventTracking.logUserEvent('Project Devices', 'Create Scenario');
            await this.profileOverrideService.createProfileFromOverride(this.props.deviceId, name);
            this.closeModal();
        } catch (error) {
            if (error instanceof CoreProfileNameError) {
                toaster.warning(
                    t.profilesNameChangeFailedToastHeader,
                    t.profilesNameChangeFailedToastBody(name),
                );
            } else {
                throw error;
            }
        }
    };

    private onClose = () => {
        this.closeModal();
    };

    private closeModal() {
        this.setState({ showModal: false });
    }
}

export const DeviceProfile = connect(mapStateToProps)(DeviceProfileContainer);
