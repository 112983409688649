import { filterProducts, PiaItemBareboneCategory } from 'app/core/pia';
import { getPiaItem, getPiaItems, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

export const getBarebones = createSelector([getPiaItems], (piaItems) => {
    return piaItems.filter(filterProducts.byCategory(PiaItemBareboneCategory.BAREBONE));
});

/**
 * Get the corresponding barebone for the PiaItem with itemId
 * For now there is no relation between the piaCamera and its barebone product,
 * for now we get the barebone product by comparing the piaCameras parentId and its name (removing Barebone)
 */
export const getBareboneForId = createCachedSelector(
    [getBarebones, getPiaItem],
    (bareboneItems, piaItem) => {
        if (bareboneItems && piaItem) {
            return bareboneItems.find(
                (bareboneItem) =>
                    bareboneItem.parentId === piaItem.parentId &&
                    bareboneItem.name.replace('Barebone', '').match(piaItem.name),
            );
        } else {
            return undefined;
        }
    },
)(toCacheKey);
