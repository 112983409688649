import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/css';
import { useService } from 'app/ioc';
import { useWindowSize } from 'app/hooks';
import { Box } from 'app/components';
import {
    getCurrentView,
    UserProjects,
    UserProjectsActionService,
    UserProjectsLandingMenu,
} from './userProjects';

const windowSizeBreakpoint = 1100;
const menuExpandedStyle = css`
    display: flex;
    flex: 1 1 100%;
    overflow-y: auto;
    padding-left: 0;
`;
const menuCollapsedStyle = css`
    display: flex;
    flex: 1 1 100%;
    overflow-y: auto;
    @media screen and (max-width: ${windowSizeBreakpoint}px) {
        padding-left: 64px;
    }
`;

export const UserProjectsLandingView: React.FunctionComponent = () => {
    const actionService = useService(UserProjectsActionService);
    const currentView = useSelector(getCurrentView);
    const location = window.location.pathname;
    const windowSize = useWindowSize();
    const [isProjectsMenuExpanded, setIsProjectsMenuExpanded] = React.useState(true);

    const closeOnSmallScreens = () => {
        if (window.innerWidth <= windowSizeBreakpoint) {
            setIsProjectsMenuExpanded(false);
        }
    };

    // Close menu when resizing to less than windowSizeBreakpoint
    React.useEffect(closeOnSmallScreens, [windowSize]);

    // Close menu if loaded on small screen
    // Close menu when navigating
    React.useEffect(closeOnSmallScreens, [location]);

    // Close menu when changing view
    const onViewChanged = () => {
        closeOnSmallScreens();
    };

    React.useEffect(() => {
        actionService.changeProjectDbOrigin(currentView);
    }, [actionService, currentView]);

    const onProjectsMenuChange = () => {
        setIsProjectsMenuExpanded(!isProjectsMenuExpanded);
    };

    return (
        <Box width="100%" height="100%">
            <UserProjectsLandingMenu
                currentView={currentView}
                isProjectsMenuExpanded={isProjectsMenuExpanded}
                onProjectsMenuChange={onProjectsMenuChange}
                onViewChanged={onViewChanged}
            />
            <div className={isProjectsMenuExpanded ? menuExpandedStyle : menuCollapsedStyle}>
                <Box display="block" width="100%">
                    <Box justifyContent="center">
                        <Box maxWidth={`${windowSizeBreakpoint}px`} width="100%">
                            <UserProjects currentView={currentView} />
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
};
UserProjectsLandingView.displayName = 'UserProjectsLandingView';
