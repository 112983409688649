import type { IScenario } from './../../models/IScenario';
import { constants } from './constants';

interface IConditionParams {
    name: string;
    lighting: number;
    weather: boolean;
    motion: number;
    nature: boolean;
    motionFrac: number;
}

export class Condition {
    public readonly name: string;
    public readonly lighting: keyof typeof constants.lightingFactor;
    public readonly weather: boolean;
    public readonly motion: keyof IScenario['motionRef'];
    public readonly lightingFactor: number;
    public readonly nature: boolean;
    public readonly motionFrac: number;

    constructor(options: IConditionParams) {
        this.name = options.name;
        this.lighting = options.lighting as keyof typeof constants.lightingFactor;
        this.weather = options.weather;
        this.motion = options.motion as keyof IScenario['motionRef'];
        this.nature = options.nature;
        this.motionFrac = options.motionFrac;

        this.lightingFactor = this.getLightingFactor(this.lighting);
    }

    private getLightingFactor(lighting: keyof typeof constants.lightingFactor) {
        return constants.lightingFactor[lighting];
    }
}
