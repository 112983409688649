/*
 * Calculate the vertical field of view based on the horizontal field of view
 * and the aspect ratio of the camera.
 *
 * Copied from PiaSyncAgent/-/blob/develop/src/middlewares/items/utils/itemTransformUtils.ts#L345
 */
export const calculateVerticalFOV = (aspectRatio: number, horizontalFOV: number) =>
    Number(
        (
            (Math.atan(Math.tan((horizontalFOV * Math.PI) / 360) / aspectRatio) * 360) /
            Math.PI
        ).toFixed(1),
    );
