import * as React from 'react';
import type { IIconProps } from '../icon';
import { Icon } from '../icon';
import type { IClickableProps } from '../../functional';
import { Clickable } from '../../functional';
import { Text } from '../../text/text/Text.component';
import { Stack } from '../../layout/stack/Stack.component';
import type { IAutoTestable } from '../../ui-test';

export interface IIconButtonProps extends IIconProps, IAutoTestable {
    text?: string;
    iconRight?: boolean;
    wrapText?: boolean;
}

/**
 * Generate a clickable icon with or without text
 */
export class IconButton extends React.Component<IIconButtonProps & IClickableProps> {
    public render() {
        const iconProps = { ...this.props };
        delete iconProps.__htmlAttributes;
        return this.props.text ? (
            <Clickable {...this.props}>
                <Stack spacing="half" justifyContent="center" alignItems="center">
                    {!iconProps.iconRight && (
                        <Icon color={this.props.color || 'blue'} size="ms" opaque {...iconProps} />
                    )}
                    {!iconProps.hidden && (
                        <Text
                            color={this.props.color || 'blue'}
                            semiBold
                            whiteSpace={this.props.wrapText === false ? 'nowrap' : undefined}
                            testId={this.props.text}
                        >
                            {this.props.text}
                        </Text>
                    )}
                    {iconProps.iconRight && (
                        <Icon color={this.props.color || 'blue'} size="ms" opaque {...iconProps} />
                    )}
                </Stack>
            </Clickable>
        ) : (
            <Clickable {...this.props}>
                <Icon
                    testId={this.props.testId}
                    color={this.props.color || 'blue'}
                    opaque
                    {...this.props}
                />
            </Clickable>
        );
    }
}
