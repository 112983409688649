import { getItemLocalStorage } from './core/persistence/utils/localStorage';
import { isProduction } from './isProduction';

/**
 * Name of the feature that you want to group logic to.
 *
 * @todo 'unsaved_changes' - can be removed when https://jira.se.axis.com/browse/WT-3632 is done.
 */
type feature =
    | 'zip_profile' // https://jira.se.axis.com/browse/WT-7858
    | 'aoa_light_conditions' // https://jira.se.axis.com/browse/WT-6597,
    | 'custom_cameras_export_import' //https://jira.se.axis.com/browse/WT-4129,
    | 'export_bidcon' // Remove when securitas is ready to export bidcon
    | 'third_party_acap_selector' // https://jira.se.axis.com/browse/WT-5172
    | 'other_partner_products' // https://jira.se.axis.com/browse/WT-6097
    | 'rtl_languages' // https://jira.se.axis.com/browse/WT-7371
    | 'acs_edge' // https://jira.se.axis.com/browse/WT-8468 (acs_pro needs to be activated for this to work)
    | 'acs_center' // https://jira.se.axis.com/browse/WT-8469 (acs_pro needs to be activated for this to work)
    | 'mocked_acs_center_licenses'; // https://jira.se.axis.com/browse/WT-8109 // after pro was released 24-05-01 only center licenses are mocked
/**
 * Add this method and what feature this logic belongs to to make it only show up in develop environments.
 *
 * @param _feature this parameter is used to group the logic to a feature. For now this does not do anything else.
 */
export function isFeatureEnabled(_feature: feature) {
    return (
        !isProduction() &&
        process.env.HIDE_FEATURE_FLAGGED_ON_DEV !== 'true' &&
        getItemLocalStorage('FeatureFlagsOff') !== 'true'
    );
}
