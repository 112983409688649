import * as React from 'react';
import type { Id } from 'app/core/persistence';
import { ApplicationSelector } from './components';

interface IApplicationSelectorViewProps {
    deviceItemId: Id;
}

export const ApplicationSelectorView: React.FunctionComponent<IApplicationSelectorViewProps> = ({
    deviceItemId,
}) => <ApplicationSelector deviceItemId={deviceItemId} />;

ApplicationSelectorView.displayName = 'ApplicationSelectorView';
