import * as React from 'react';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Stack, Box, IconButton, Text, Card } from 'app/components';
import {
    getCurrentProjectIdRev,
    getProfileIdsSortedByProfileName,
    ProjectProfileService,
} from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import { ProfileItem } from './ProfileItem.container';
import type { Id, IIdRevModel } from 'app/core/persistence';
import { ProjectZipStreamSetting } from '../application';
import { ProjectRetentionTimeSetting } from './ProjectRetentionTimeSetting';

interface IProfilesListOwnProps {
    onEditProfile(profileId: Id): void;
}

interface IProfilesListProps extends IProfilesListOwnProps {
    project?: IIdRevModel;
    profileIds: Id[];
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProfilesListOwnProps,
): IProfilesListProps => {
    return {
        project: getCurrentProjectIdRev(storeState),
        profileIds: getProfileIdsSortedByProfileName(storeState),
        ...ownProps,
    };
};

class ProfilesListContainer extends React.Component<IProfilesListProps> {
    private projectProfileService: ProjectProfileService;
    constructor(props: IProfilesListProps) {
        super(props);
        this.projectProfileService = ServiceLocator.get(ProjectProfileService);
    }

    public render() {
        return (
            <Box direction="column" width="100%">
                <Box padding="half" paddingRight="base" display="block">
                    <Stack justifyContent="between" alignItems="center">
                        <Text color="grey6" semiBold>
                            {t.profiles}
                        </Text>
                        <IconButton
                            icon="add_circle"
                            text={t.profilesAddProfile}
                            onClick={() => this.addProfile()}
                        />
                    </Stack>
                </Box>
                <Card>
                    <Stack vertical spacing="none" lineBetweenColor="grey3">
                        <Box
                            display="grid"
                            grid={{
                                gridTemplateColumns: '1fr max-content',
                            }}
                            lineBetweenColor="grey3"
                        >
                            <ProjectZipStreamSetting />
                            <ProjectRetentionTimeSetting />
                        </Box>
                        {this.props.profileIds.map(
                            (profileId) =>
                                this.props.project && (
                                    <ProfileItem
                                        key={profileId}
                                        profileId={profileId}
                                        onEditProfile={(p) => this.props.onEditProfile(p)}
                                    />
                                ),
                        )}
                    </Stack>
                </Card>
            </Box>
        );
    }

    private addProfile = async () => {
        if (this.props.project) {
            const profile = await this.projectProfileService.addProjectProfile(
                this.props.project.id,
            );
            this.props.onEditProfile(profile._id);
        }
    };
}

export const ProfilesList = connect(mapStateToProps)(ProfilesListContainer);
