import type { PartnerProductType } from 'app/modules/common';
import { t as i18nextT } from 'i18next';

const t = (key: string) => i18nextT(key, { ns: 'partnerProducts' });

export const getPartnerProductTypeTranslation = (
    partnerProduct: PartnerProductType | undefined,
): string => {
    switch (partnerProduct) {
        case 'ACAP application':
            return t('acapApplication');
        case 'Access control equipment':
            return t('accessControlEquipment');
        case 'Access control system':
            return t('accessControlSystem');
        case 'Accessories':
            return t('accessories');
        case 'Audio':
            return t('audioEquipment');
        case 'Audio analytics':
            return t('audioAnalytics');
        case 'Audio solution':
            return t('audioSolution');
        case 'Body worn solution':
            return t('bodyWornSolution');
        case 'Billing management platform':
            return t('billingManagementPlatform');
        case 'Common management platform':
            return t('commonManagementPlatform');
        case 'Computing':
            return t('computing');
        case 'Content management':
            return t('contentManagement');
        case 'Display':
            return t('display');
        case 'Hardware integration':
            return t('hardwareIntegration');
        case 'Home automation':
            return t('homeAutomation');
        case 'Integrated platform':
            return t('integratedPlatform');
        case 'IT integration service':
            return t('itIntegrationPlatform');
        case 'Live streaming solution':
            return t('liveStreamingSolution');
        case 'Management and maintenance':
            return t('managementAndMaintenance');
        case 'Networking':
            return t('networking');
        case 'Sensors':
            return t('sensors');
        case 'Storage':
            return t('storage');
        case 'Video analytics':
            return t('videoAnalytics');
        case 'Video management system':
            return t('videoManagementSystem');

        case undefined:
        default:
            return t('anySolutionType');
    }
};
