import { injectable } from 'inversify';

export interface IAnimationObserver {
    animate(animationId: string): void;
}

@injectable()
export class ProjectMenuAnimationService {
    private observers: IAnimationObserver[] = [];

    public attach(observer: IAnimationObserver): void {
        const isAttached = this.observers.includes(observer);
        if (isAttached) {
            // Observer is already attached
            return;
        }

        this.observers.push(observer);
    }

    public detach(observer: IAnimationObserver): void {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex === -1) {
            // Observer is not attached
            return;
        }

        this.observers.splice(observerIndex, 1);
    }

    public notify(animationId: string): void {
        this.observers.forEach((observer) => {
            observer.animate(animationId);
        });
    }
}
