import type { IBandwidthCalculationResult } from '../../../models';
import type { ILightHoursModel } from '../../../models/ILightHoursModel';
import { getIdleTime, getPercentValueAsDecimal } from './StorageUtils';

/**
 *
 * @param bandwidthEstimate estimated bandwidth for the specified device and scenario (not custom bandwidth)
 * @returns bandwidth quota for the different light hours
 */
export const getTriggeredQuota = (bandwidthEstimate: IBandwidthCalculationResult) => {
    const estimateBandwidthTotal =
        bandwidthEstimate.goodLight +
        bandwidthEstimate.goodLightIdle +
        bandwidthEstimate.lowLight +
        bandwidthEstimate.lowLightIdle;

    const goodLightQuota = bandwidthEstimate.goodLight / estimateBandwidthTotal;
    const lowLightQuota = bandwidthEstimate.lowLight / estimateBandwidthTotal;
    return {
        goodLightQuota,
        lowLightQuota,
    };
};

/**
 *
 * @param customBandwidth user defined bandwidth for the scenario
 * @param lightHours lightHours specified by the scheme
 * @param goodLightQuota goodLightQuota calculated as if no customBandwidth specified
 * @param lowLightQuota lowLightQuota calculated as if no customBandwidth specified
 * @returns estimated total bandwidth, calculated from the user defined average bandwidth for scenario
 */
export const getTriggeredBandwidthTotal = (
    customBandwidth: number,
    lightHours: ILightHoursModel,
    goodLightQuota: number,
    lowLightQuota: number,
) => {
    return customBandwidth
        ? (customBandwidth * (lightHours.goodLightHours + lightHours.lowLightHours)) /
              (lightHours.goodLightHours * goodLightQuota +
                  lightHours.lowLightHours * lowLightQuota)
        : customBandwidth;
};

/**
 *
 * @param bandwidthEstimate estimated bandwidth for the specified device and scenario (not custom bandwidth)
 * @returns bandwidth quota for the different light hours
 */
export const getContinuousQuota = (bandwidthEstimate: IBandwidthCalculationResult) => {
    const estimateBandwidthTotal =
        bandwidthEstimate.goodLight +
        bandwidthEstimate.goodLightIdle +
        bandwidthEstimate.lowLight +
        bandwidthEstimate.lowLightIdle;
    const goodLightQuota = bandwidthEstimate.goodLight / estimateBandwidthTotal;
    const goodLightIdleQuota = bandwidthEstimate.goodLightIdle / estimateBandwidthTotal;
    const lowLightQuota = bandwidthEstimate.lowLight / estimateBandwidthTotal;
    const lowLightIdleQuota = bandwidthEstimate.lowLightIdle / estimateBandwidthTotal;

    return {
        goodLightQuota,
        goodLightIdleQuota,
        lowLightQuota,
        lowLightIdleQuota,
    };
};

/**
 * Method calculate the total custom bandwidth for the continuous recording
 * (the customBandwidth specified is the average bandwidth and we need to know the
 * total bandwidth to calculate how the customBandwidth should be divided)
 * @param customBandwidth user defined bandwidth for the scenario
 * @param lightHours lightHours specified by the scheme
 * @param bandwidthEstimate estimate for the scenario and device if no customBandwidth
 * @param goodLightTriggerTime time for goodLight
 * @param lowLightTriggerTime time for lowLight
 * @param dynamicFps if dynamic Fps is used
 * @returns estimated total bandwidth, calculated from the user defined average bandwidth for scenario
 */
export const getTotalCustomBandwidthContinuous = (
    customBandwidth: number,
    lightHours: ILightHoursModel,
    bandwidthEstimate: IBandwidthCalculationResult,
    goodLightTriggerTime: number,
    lowLightTriggerTime: number,
    dynamicFps: boolean,
): number => {
    const estimateBandwidthTotal =
        bandwidthEstimate.goodLight +
        bandwidthEstimate.goodLightIdle +
        bandwidthEstimate.lowLight +
        bandwidthEstimate.lowLightIdle;
    const goodLightPercent = bandwidthEstimate.goodLight / estimateBandwidthTotal;
    const goodLightIdlePercent = bandwidthEstimate.goodLightIdle / estimateBandwidthTotal;
    const lowLightPercent = bandwidthEstimate.lowLight / estimateBandwidthTotal;
    const lowLightIdlePercent = bandwidthEstimate.lowLightIdle / estimateBandwidthTotal;

    const goodLightTrigger = dynamicFps ? 1 : getPercentValueAsDecimal(goodLightTriggerTime);
    const lowLightTrigger = dynamicFps ? 1 : getPercentValueAsDecimal(lowLightTriggerTime);
    const goodLightIdleTrigger = dynamicFps
        ? 1
        : getPercentValueAsDecimal(getIdleTime(goodLightTriggerTime));
    const lowLightIdleTrigger = dynamicFps
        ? 1
        : getPercentValueAsDecimal(getIdleTime(lowLightTriggerTime));

    const totalBandwidth =
        (customBandwidth * (lightHours.goodLightHours + lightHours.lowLightHours)) /
        (goodLightTrigger * lightHours.goodLightHours * goodLightPercent +
            lowLightTrigger * lightHours.lowLightHours * lowLightPercent +
            goodLightIdleTrigger * lightHours.goodLightHours * goodLightIdlePercent +
            lowLightIdleTrigger * lightHours.lowLightHours * lowLightIdlePercent);
    return totalBandwidth;
};
