import * as React from 'react';
import { css } from '@emotion/css';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';

const NoPrintStyle = css`
    @media print {
        display: none !important;
    }
`;

/**
 * Hides the children when printing
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export class NoPrint extends React.Component<IExtendableComponentWithChildren> {
    public render() {
        const { children, ...extendedProps } = this.props;
        const attributes = extendableProps(extendedProps, { className: NoPrintStyle });
        return renderReactChildren(
            children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes.__htmlAttributes),
            (child) => <span className={NoPrintStyle}>{child}</span>,
        );
    }
}
