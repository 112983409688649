import { t } from 'app/translate';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getPiaCamera } from './getPiaCamera';

/**
 * @description Returns translated string for standard lens concatenated with focal length
 * in mm if that information is provided in pia.
 * @param state storeState
 * @param itemId Id for the camera which focal length you want to retrieve
 */
export const getStandardLensName = createCachedSelector([getPiaCamera], (piaCamera) => {
    //CharCode 160 represents non breaking space
    const nonBreakingSpace = String.fromCharCode(160);
    const standardLensString = t.standardLens;

    if (!piaCamera) {
        return standardLensString;
    }
    const focalLength = piaCamera.properties.focalLength;
    if (
        piaCamera.properties.maxHorizontalFOV === 0 &&
        piaCamera.properties.minHorizontalFOV === 0
    ) {
        return t.noLens;
    }

    if (!piaCamera.properties.focalLength) {
        return standardLensString;
    }

    if (focalLength?.min === focalLength?.max) {
        return `${standardLensString} ${focalLength?.min}${nonBreakingSpace}mm`;
    }

    return `${standardLensString} ${focalLength?.min} - ${focalLength?.max}${nonBreakingSpace}mm`;
})(toCacheKey);
