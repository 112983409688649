import type { ILightCondition } from '../models';
import { t } from 'app/translate';

export function getLightConditions(): ILightCondition[] {
    return [
        {
            name: t.bandwidthCalculatorLightConditionsNoIllumination,
            value: 1,
            image: require('src/assets/images/profile/lightConditions/no-illumination.png'),
        },
        {
            name: t.bandwidthCalculatorLightConditionsIlluminated,
            value: 2,
            image: require('src/assets/images/profile/lightConditions/illuminated.png'),
        },
        {
            name: t.bandwidthCalculatorLightConditionsWellLit,
            value: 3,
            image: require('src/assets/images/profile/lightConditions/well-lit.png'),
        },
    ];
}
