import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../../models';
import { css } from '@emotion/css';

const noPageBreakStyle = css`
    page-break-inside: avoid;
`;

/**
 * Prevent page breaks inside when printing
 */
export const NoPageBreak: React.FunctionComponent<IExtendableComponentWithChildren> = ({
    children,
}) => <div className={noPageBreakStyle}>{children}</div>;

NoPageBreak.displayName = 'NoPageBreak';
