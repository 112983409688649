import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import { Box, Card, Checkbox, Heading, Spacer, Stack, Text } from 'app/components';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { LensGrid } from './LensGrid.component';
import type { LensesArray } from '../selectors';
import { getBareboneForId, getLenses } from '../selectors';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { LensSelectorService, ItemService } from 'app/core/persistence';
import { TextCircle } from './TextCircle';
import { ToggleLensStatus, useToggleLens } from '../hooks/useToggleLens';
import {
    getCurrentProjectLocked,
    getPiaItem,
    getCurrentProjectItem,
    getStandardLensName,
    getImageSensors,
    getOnlyMultipackForPiaItem,
} from 'app/modules/common';
import type { IPiaItem, PiaId } from 'app/core/pia';
import type { ILens } from '../models';
import { eventTracking } from 'app/core/tracking';
import { getNotToBeFollowedByLenses } from 'app/modules/accessorySelector';

interface ILensSelectorProps {
    itemId: Id;
}

export const LensSelector: React.FC<ILensSelectorProps> = ({ itemId }) => {
    const [lensSelectorService] = React.useState(ServiceLocator.get(LensSelectorService));
    const [itemService] = React.useState(ServiceLocator.get(ItemService));
    const [numberOfLenses, setNumberOfLenses] = React.useState(0);
    const { onSelect, toggleStatus } = useToggleLens(itemId);
    const lensesArray = useSelector<IStoreState, LensesArray>((state) => getLenses(state, itemId));
    const [updatedLenses, setUpdatedLenses] = React.useState<LensesArray>(lensesArray);
    const disabled = useSelector<IStoreState, boolean>((state) => getCurrentProjectLocked(state));
    const imageSensors = useSelector<IStoreState, number>((state) =>
        getImageSensors(state, itemId),
    );

    const [userClicked, setUserClicked] = React.useState(false);

    const handleClear = React.useCallback(
        (sensorIndex: number) => {
            lensSelectorService.clearLenses(itemId, sensorIndex, true);
        },
        [lensSelectorService, itemId],
    );

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) => getPiaItem(state, itemId));
    const barebonePiaItem = useSelector<IStoreState, IPiaItem | undefined>((state) =>
        getBareboneForId(state, itemId),
    );
    const onlyBareboneMultipack = useSelector<IStoreState, boolean>((state) =>
        getOnlyMultipackForPiaItem(state, barebonePiaItem?.id),
    );

    const deviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, itemId),
    );

    const standardLensName = useSelector<IStoreState, string>((state) =>
        getStandardLensName(state, itemId),
    );

    const notToBeFollowedByForLenses = useSelector<IStoreState, Record<PiaId, IPiaItem[]>>(
        getNotToBeFollowedByLenses,
    );

    const getProductNamesNotToBeFollowedBy = React.useCallback(
        (lensPiaId: PiaId) =>
            notToBeFollowedByForLenses[lensPiaId]
                ? notToBeFollowedByForLenses[lensPiaId].map(({ name }) => name).join(', ')
                : '',
        [notToBeFollowedByForLenses],
    );

    const hasNotToBeFollowedByAccessory = React.useCallback(
        (lensPiaId: PiaId) =>
            notToBeFollowedByForLenses[lensPiaId] &&
            notToBeFollowedByForLenses[lensPiaId].length > 0,
        [notToBeFollowedByForLenses],
    );

    /**
     *
     * @param replaceBarebone boolean indicating if barebone should be replaced or not
     * If barebone was previously replaced for this item, the id will be cleared
     * If barebone should be used, the piaId for the lens is set.
     */
    const handleBarebone = React.useCallback(
        (replaceBarebone: boolean) => {
            if (barebonePiaItem && replaceBarebone) {
                return itemService.setBareboneId(itemId, barebonePiaItem.id);
            } else if (!replaceBarebone && barebonePiaItem && barebonePiaItem.id) {
                return itemService.clearBareboneId(itemId);
            }
        },
        [barebonePiaItem, itemId, itemService],
    );

    const onSelectLens = (lens: ILens) => {
        setUserClicked(true);
        onSelect(lens);
    };

    const onClearClicked = (sensorIndex: number) => {
        eventTracking.logUserEvent('Application', 'Select standard lens');
        if (barebonePiaItem) {
            handleBarebone(false);
        }
        handleClear(sensorIndex);
    };

    const isPending = toggleStatus === ToggleLensStatus.pending;

    const updateBareboneStatusOnLensSelectionChanged = React.useCallback(() => {
        const standardSelectedForSensor: boolean[] = [];
        lensesArray.forEach((sensorLenses) => {
            const [, extraLensesForSensor] = sensorLenses;
            const standardSelected = extraLensesForSensor.every(
                (sensorLens) => sensorLens.id === undefined,
            );
            standardSelectedForSensor.push(standardSelected);
        });
        const showToggle = standardSelectedForSensor.every((selection) => selection === false);

        if (showToggle && barebonePiaItem) {
            // Automatically choose barebone if single pack exists
            handleBarebone(!onlyBareboneMultipack);
        } else if (barebonePiaItem) {
            handleBarebone(false);
        }
        setUserClicked(false);
    }, [barebonePiaItem, handleBarebone, lensesArray, onlyBareboneMultipack]);

    React.useEffect(() => {
        if (!isPending) {
            setUpdatedLenses(lensesArray);
            let nbr = 0;
            const [, lenses] = lensesArray[0];
            lenses.map((lens) => {
                if (!lens.isIncluded) {
                    nbr++;
                }
            });
            setNumberOfLenses(nbr);

            const standardSelectedForSensor: boolean[] = [];
            lensesArray.forEach((sensorLenses) => {
                const [, extraLensesForSensor] = sensorLenses;
                const standardSelected = extraLensesForSensor.every(
                    (sensorLens) => sensorLens.id === undefined,
                );
                standardSelectedForSensor.push(standardSelected);
            });
            setShowBareboneToggle(
                standardSelectedForSensor.every((selection) => selection === false),
            );
        }
        if (userClicked && toggleStatus === ToggleLensStatus.fulfilled) {
            updateBareboneStatusOnLensSelectionChanged();
        }
    }, [
        isPending,
        lensesArray,
        toggleStatus,
        updateBareboneStatusOnLensSelectionChanged,
        userClicked,
    ]);

    const [showBareboneToggle, setShowBareboneToggle] = React.useState<boolean>(false);

    return numberOfLenses > 0 ? (
        <Stack vertical>
            <Box justifyContent="between" alignItems="center">
                <Stack>
                    <Heading>{t.compatibleLenses}</Heading>
                </Stack>
            </Box>
            {updatedLenses.map(([sensorIndex, lenses]) =>
                imageSensors > 1 ? (
                    <Card paddingX="base" paddingY="panel" key={sensorIndex}>
                        <Stack justifyContent="end">
                            <TextCircle label={`${sensorIndex + 1}`} />
                            <LensGrid
                                lenses={lenses}
                                onSelect={onSelectLens}
                                onClear={onClearClicked}
                                disabled={disabled}
                                sensorIndex={sensorIndex}
                                readOnly={isPending}
                                standardLensName={standardLensName}
                                hasNotToBeFollowedByAccessory={hasNotToBeFollowedByAccessory}
                                getProductNamesNotToBeFollowedBy={getProductNamesNotToBeFollowedBy}
                            />
                        </Stack>
                    </Card>
                ) : (
                    <LensGrid
                        key={sensorIndex}
                        lenses={lenses}
                        onSelect={onSelectLens}
                        onClear={onClearClicked}
                        disabled={disabled}
                        sensorIndex={sensorIndex}
                        readOnly={isPending}
                        standardLensName={standardLensName}
                        hasNotToBeFollowedByAccessory={hasNotToBeFollowedByAccessory}
                        getProductNamesNotToBeFollowedBy={getProductNamesNotToBeFollowedBy}
                    />
                ),
            )}
            <Box justifyContent="between" alignItems="start" direction="column">
                {showBareboneToggle && piaItem && barebonePiaItem && (
                    <Checkbox
                        selected={deviceItem?.replaceWithBareboneId ? true : false}
                        onChange={handleBarebone}
                        slider={true}
                    >
                        {t.replaceProduct(piaItem.name, barebonePiaItem.name)}
                    </Checkbox>
                )}
                {showBareboneToggle && piaItem && barebonePiaItem && (
                    <Spacer spacing="base"></Spacer>
                )}
                {showBareboneToggle && piaItem && barebonePiaItem && (
                    <Text>{t.bareboneModels}</Text>
                )}
            </Box>
        </Stack>
    ) : (
        <Text>{t.noLensesAvailableForThisDevice}</Text>
    );
};

LensSelector.displayName = 'LensSelector';
