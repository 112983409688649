import type * as React from 'react';
import * as leaflet from 'leaflet';
import { useEffect, useLayoutEffect, useState } from 'react';
import { ColorsEnum } from 'app/styles';
import type { Colors } from 'app/styles';
import type { ILatLng } from 'app/core/persistence';
import { useMapContext } from '../../context';

interface IPolyLineProps {
    latLngs: ILatLng[];
    color?: Colors;
    opacity?: number;
    weight?: number;
}

export const PolyLine: React.FC<IPolyLineProps> = ({ latLngs, color, opacity, weight }) => {
    const { leafletMap } = useMapContext();

    const [line] = useState(
        leaflet.polyline([], {
            color: color ? ColorsEnum[color] : ColorsEnum.blue,
            opacity: opacity ? opacity : 0.7,
            weight: weight ? weight : 3,
        }),
    );

    useEffect(() => {
        line.addTo(leafletMap.map);

        return () => {
            line.remove();
        };
    }, [leafletMap.map, line]);

    useLayoutEffect(() => {
        line.setLatLngs(latLngs.map(({ lat, lng }) => leaflet.latLng(lat, lng)));
    }, [latLngs, line]);

    return null;
};

PolyLine.displayName = 'PolyLine';
