import { createSelector } from 'reselect';
import { getPiaItemsRecord } from '../../piaDevices';
import {
    getCurrentProjectItems,
    getCurrentProjectItemsArray,
    getCurrentProjectItemRelationsArray,
} from '../../project';
import type { IStoreState } from 'app/store';
import type { IItemEntity, IPersistence, Id } from 'app/core/persistence';
import {
    isVirtualCamera,
    PowerSupplyType,
    getParentId,
    deviceTypeCheckers,
    isCustomCamera,
    isPiaPoeConsumer,
    isSystemComponent,
    needsPort,
    needsLicense,
} from 'app/core/persistence';
import type { IPiaDevice, IPiaOptionalDeviceProperties, IPiaItem, IPiaCamera } from 'app/core/pia';
import { getPoeClassNumber, PoeClass, PiaRelationTypes } from 'app/core/pia';
import { isDefined } from 'axis-webtools-util';
import { range } from 'lodash-es';

const getCurrentProjectPowerCalculationMethod = (state: IStoreState) =>
    state.currentProject.project?.powerCalcMethod;

/**
 * Return an array of length n populated with item
 */
const nTimes = <T>(item: T, n: number): T[] => range(n).map(() => item);

/**
 * Get the PoE class of a piaItem.
 * @return PoE class. If the item has included power supply null is returned.
 */
const getPoeClass = (
    piaItem: IPiaDevice,
    piaItems: Record<Id, IPiaItem>,
    item: IPersistence<IItemEntity>,
) => {
    const hasPowerSupply =
        item.properties.doorController?.powerSupply === PowerSupplyType.DC ||
        hasIncludedPowerSupply(piaItem, piaItems);

    return hasPowerSupply ? null : piaItem.properties.PoEClass ?? null;
};

/** Gets an array of items that requires an ethernet port */
export const getItemsWithPortRequirement = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItems) => {
        return items.filter((item) =>
            needsPort(item, item.productId ? piaItems[item.productId] : undefined),
        );
    },
);

/**
 * Get a map between device id and pia accessories
 */
export const getDeviceAccessories = createSelector(
    [getCurrentProjectItems, getCurrentProjectItemRelationsArray, getPiaItemsRecord],
    (items, itemRelations, piaItems) =>
        itemRelations.reduce((acc: Record<Id, IPiaItem[]>, relation) => {
            const accessory = items[relation.childId];
            if (accessory?.productId) {
                const piaAccessory = piaItems[accessory.productId];
                if (piaAccessory) {
                    acc[relation.parentId] = [
                        ...(acc[relation.parentId] ?? []),
                        ...nTimes(piaAccessory, accessory.quantity),
                    ];
                }
            }
            return acc;
        }, {}),
);

/**
 * Get a map between device id and whether it has a PoE accessory
 * (in which case we shouldn't consider its PoE class or power consumption
 * in subsequent calculations)
 */
export const getHasPoeAccessory = createSelector([getDeviceAccessories], (accessoriesMap) =>
    Object.keys(accessoriesMap).reduce((acc: Record<Id, boolean>, id) => {
        const accessories = accessoriesMap[id] || [];
        const hasPoeAccessory = accessories.some(({ category }) => category === 'poe');
        acc[id] = hasPoeAccessory;
        return acc;
    }, {}),
);

/**
 * Get the PoE class of each device that has one
 */
export const getDevicePoeClass = createSelector(
    [getItemsWithPortRequirement, getPiaItemsRecord, getHasPoeAccessory],
    (items, piaItems, poeAccessoryMap) =>
        items.reduce((map: Record<Id, PoeClass>, item) => {
            const piaItem = item.productId ? (piaItems[item.productId] as IPiaDevice) : null;
            const poeClass = isCustomCamera(item)
                ? item.properties.camera?.customCameraProperties?.poe
                : piaItem
                  ? getPoeClass(piaItem, piaItems, item)
                  : null;

            if (poeClass) {
                const hasPoeAccessory = poeAccessoryMap[item._id] ?? false;
                if (!hasPoeAccessory) {
                    map[item._id] = poeClass;
                }
            }
            return map;
        }, {}),
);

/**
 * Map a poeClass to its maximum power draw including power loss
 */
export const portMaxPower: Record<PoeClass, number> = {
    [PoeClass.Class1]: 4,
    [PoeClass.Class2]: 7,
    [PoeClass.Class3]: 15.4,
    [PoeClass.Class4]: 30,
    [PoeClass.Class5]: 45,
    [PoeClass.Class6]: 60,
    [PoeClass.Class7]: 75,
    [PoeClass.Class8]: 99,
    [PoeClass.HighPoE]: 60,
};

/**
 * Map a poeClass to its maximum power draw excluding power loss
 */
export const portMaxPowerExcludingPowerLoss: Record<PoeClass, number> = {
    [PoeClass.Class1]: 3.84,
    [PoeClass.Class2]: 6.49,
    [PoeClass.Class3]: 12.95,
    [PoeClass.Class4]: 25.5,
    [PoeClass.Class5]: 40,
    [PoeClass.Class6]: 51,
    [PoeClass.Class7]: 62,
    [PoeClass.Class8]: 71.3,
    [PoeClass.HighPoE]: 51,
};

/**
 * Estimate the PSE (usually switch) output voltage from PoE class
 * Numbers from this paper https://ethernetalliance.org/wp-content/uploads/2017/06/EA_Whitepaper_PoE_Cable_Losses_June.pdf
 */
const getWorstCasePSEOutputVoltage = (poeClass?: PoeClass) => {
    const poeClassNumber = getPoeClassNumber(poeClass);
    return poeClassNumber < 4 ? 44 : poeClassNumber < 7 ? 50 : 52;
};

/** Estimate the worst case cable resistance from PoE class
 * Numbers from this paper https://ethernetalliance.org/wp-content/uploads/2017/06/EA_Whitepaper_PoE_Cable_Losses_June.pdf
 */
const getWorstCaseCableResistance = (poeClass?: PoeClass) => {
    const poeClassNumber = getPoeClassNumber(poeClass);
    return poeClassNumber < 4 ? 20 : poeClassNumber < 5 ? 12.5 : 6.25;
};

/**
 * Calculate the POE power loss as described here:
 * https://ethernetalliance.org/wp-content/uploads/2017/06/EA_Whitepaper_PoE_Cable_Losses_June.pdf
 *
 * @param vPSE - Output voltage of PSE
 * @param rChan - resistance
 * @param pDevice - Power consumed by device
 *
 */
const calculatePOEPowerLoss = (vPSE: number, rChan: number, pDevice: number) =>
    Math.pow((vPSE - Math.sqrt(Math.pow(vPSE, 2) - 4 * rChan * pDevice)) / (2 * rChan), 2) * rChan;

/**
 * Calculate a worst-case PoE power loss.
 * @param devicePower - Power requirement of the device
 * @param poeClass - poe class of the device
 */
export const calculateWorstCasePowerLoss = (devicePower: number, poeClass?: PoeClass) => {
    const vPSE = getWorstCasePSEOutputVoltage(poeClass);
    const rChan = getWorstCaseCableResistance(poeClass);

    return calculatePOEPowerLoss(vPSE, rChan, devicePower) || 0;
};

/**
 * Get the power requirements of a custom camera. Will fall back
 * to using PoE-class if power consumption is not given.
 */
export const getCustomCameraMaxRating = (item: IPersistence<IItemEntity>) => {
    if (!item.properties.camera?.customCameraProperties) {
        return 0;
    }
    const { powerConsumption, poe } = item.properties.camera.customCameraProperties;

    const poeClassPower = getCustomCameraPowerFromClass(item);
    const maxPowerAndLoss = powerConsumption + calculateWorstCasePowerLoss(powerConsumption, poe);

    return powerConsumption > 0
        ? Math.min(poeClassPower, maxPowerAndLoss) // poeClassPower is already worst-case
        : poeClassPower;
};

/**
 * Get the power requirements of a custom camera without power loss. Will fall back
 * to using PoE-class if power consumption is not given.
 */
export const getCustomCameraMaxRatingNoLoss = (item: IPersistence<IItemEntity>) => {
    if (!item.properties.camera?.customCameraProperties) {
        return 0;
    }
    const { powerConsumption } = item.properties.camera.customCameraProperties;

    const poeClassPower = getCustomCameraPowerFromClass(item);
    const maxPowerNoLoss = powerConsumption ?? poeClassPower;

    return maxPowerNoLoss;
};

const getCustomCameraPowerFromClass = (item: IPersistence<IItemEntity>) => {
    return item.properties.camera?.customCameraProperties?.poe
        ? portMaxPower[item.properties.camera.customCameraProperties.poe]
        : 0;
};

/**
 * Wether this items should count as a device in the system
 */
export const shouldCountAsDevice = (
    item: IPersistence<IItemEntity>,
    piaItem: IPiaItem | undefined,
) =>
    ((item.properties.camera ||
        item.properties.encoder ||
        item.properties.mainUnit ||
        isVirtualCamera(piaItem) ||
        item.properties.connectivityDevice ||
        item.properties.pagingConsole ||
        item.properties.peopleCounter) &&
        item.productId !== null) ||
    isCustomCamera(item);

/**
 * Get number of channels for an item
 */
export const getNrOfChannels = (
    item: IPersistence<IItemEntity>,
    projectItems: IPersistence<IItemEntity>[],
    piaItemsRecord: Record<number, IPiaItem>,
): number => {
    // given that one will not watch the video from these channels, they count as one for people counters
    if (item.properties.peopleCounter) {
        return 1;
    }

    const piaItem = item.productId ? piaItemsRecord[item.productId] : undefined;
    if (piaItem && item.properties.camera) {
        return (piaItem as IPiaCamera).properties.channels ?? 1;
    }

    if (isVirtualCamera(piaItem)) {
        return piaItem.properties.channels ?? 1;
    }

    if (isCustomCamera(item)) {
        return 1;
    }

    if (item.properties.mainUnit || item.properties.encoder) {
        const children = projectItems.filter(
            (child) =>
                getParentId(child) === item._id &&
                (deviceTypeCheckers.isAnalogCamera(child) ||
                    (deviceTypeCheckers.isSensorUnit(child) && child.productId !== null)),
        );
        return children.reduce((count, { quantity }) => {
            return count + quantity;
        }, 0);
    }

    return 1;
};

/**
 * Determine if the item has an included power supply
 * accessory. In which case it shouldn't add to the project's PoE requirement
 */
const hasIncludedPowerSupply = (piaItem: IPiaDevice, piaItems: Record<Id, IPiaItem>) =>
    piaItem.relations
        .filter((item) => item.relationType === PiaRelationTypes.Includes)
        .map((item) => piaItems[item.id])
        .filter(isDefined)
        .some((item) => item.category === 'poe');

/** Gets an array of items that require licenses */
export const getItemsWithLicenseRequirement = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItems) => {
        return items.filter((item) => {
            const piaItem = item.productId ? piaItems[item.productId] : undefined;
            return needsLicense(item, piaItem);
        });
    },
);

/**
 * Get the number of required software licenses
 * for the current project
 */
export const getRequiredLicenses = createSelector(
    [getItemsWithLicenseRequirement, getPiaItemsRecord],
    (items, piaItems) =>
        items.reduce((count, item) => {
            const piaItem = piaItems[item.productId!];
            const includedLicenses =
                (piaItem?.properties as IPiaOptionalDeviceProperties)?.includedLicensesCount ?? 0;
            return count + (item.quantity - includedLicenses * item.quantity);
        }, 0),
);

/**
 * Returns the number of channels that a recording
 * solution needs to handle
 */
export const getRequiredChannels = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItemsRecord) =>
        items
            .filter((item) =>
                shouldCountAsDevice(
                    item,
                    item.productId ? piaItemsRecord[item.productId] : undefined,
                ),
            )
            .reduce((count, item) => {
                let quantity = item.quantity;
                const piaItem = item.productId ? piaItemsRecord[item.productId] : undefined;
                if (item && isVirtualCamera(piaItem)) {
                    quantity =
                        items.find((projectItem) => projectItem._id === getParentId(item))
                            ?.quantity ?? 1;
                }

                return count + quantity * getNrOfChannels(item, items, piaItemsRecord);
            }, 0),
);

/**
 * Get the number of required ethernet ports for the project
 */
export const getRequiredPorts = createSelector(
    [getItemsWithPortRequirement, getDevicePoeClass],
    (projectItems, poeDevices) =>
        projectItems.reduce(
            (poeClassPorts, item) => {
                const poeClass = poeDevices[item._id];
                if (!poeClass) {
                    return {
                        ...poeClassPorts,
                        totalPorts: poeClassPorts.totalPorts + item.quantity,
                    };
                }

                switch (poeClass) {
                    case PoeClass.Class1:
                        return {
                            ...poeClassPorts,
                            poeClass1Ports: poeClassPorts.poeClass1Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.Class2:
                        return {
                            ...poeClassPorts,
                            poeClass2Ports: poeClassPorts.poeClass2Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.Class3:
                        return {
                            ...poeClassPorts,
                            poeClass3Ports: poeClassPorts.poeClass3Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.Class4:
                        return {
                            ...poeClassPorts,
                            poeClass4Ports: poeClassPorts.poeClass4Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.Class5:
                        return {
                            ...poeClassPorts,
                            poeClass5Ports: poeClassPorts.poeClass5Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.Class6:
                        return {
                            ...poeClassPorts,
                            poeClass6Ports: poeClassPorts.poeClass6Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.Class7:
                        return {
                            ...poeClassPorts,
                            poeClass7Ports: poeClassPorts.poeClass7Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.Class8:
                        return {
                            ...poeClassPorts,
                            poeClass8Ports: poeClassPorts.poeClass8Ports + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                    case PoeClass.HighPoE:
                        return {
                            ...poeClassPorts,
                            highPoEPorts: poeClassPorts.highPoEPorts + item.quantity,
                            totalPorts: poeClassPorts.totalPorts + item.quantity,
                        };
                }
            },
            {
                poeClass1Ports: 0,
                poeClass2Ports: 0,
                poeClass3Ports: 0,
                poeClass4Ports: 0,
                poeClass5Ports: 0,
                poeClass6Ports: 0,
                poeClass7Ports: 0,
                poeClass8Ports: 0,
                highPoEPorts: 0,
                totalPorts: 0,
            },
        ),
);

/**
 * Get the power requirement of a pia item using a
 * PoE-class estimate.
 */
const getPowerFromClass = (piaItem: IPiaDevice, piaItems: Record<Id, IPiaItem>) => {
    const hasPowerSupply = hasIncludedPowerSupply(piaItem, piaItems);
    return hasPowerSupply
        ? 0
        : piaItem.properties.PoEClass
          ? portMaxPower[piaItem.properties.PoEClass]
          : 0;
};
/** Rounds up to closest 0.5W */
export const roundToHalfIntCeiling = (value: number) => Math.ceil(value * 2) / 2;

/**
 * Get the power requirement of a pia item using the
 * maximum power draw data from PIA. Will fall back
 * to using PoE-class if PIA data is missing.
 */
export const getPowerFromMaxRating = (piaItem: IPiaDevice, piaItems: Record<Id, IPiaItem>) => {
    const hasPowerSupply = hasIncludedPowerSupply(piaItem, piaItems);
    const powerFromPiaItem = piaItem.properties.powerOverEthernet
        ? piaItem.properties.powerConsumptionW
        : undefined;

    if (hasPowerSupply) return 0;

    const poeClassPower = getPowerFromClass(piaItem, piaItems);

    if (powerFromPiaItem !== undefined) {
        const maxPowerAndLoss = roundToHalfIntCeiling(
            powerFromPiaItem +
                calculateWorstCasePowerLoss(powerFromPiaItem, piaItem.properties.PoEClass),
        );

        return Math.min(poeClassPower, maxPowerAndLoss); // poeClassPower is already worst-case
    }

    return poeClassPower;
};

const getPowerFromSystemComponent = (piaItem: IPiaItem) => {
    if (isSystemComponent(piaItem)) {
        return piaItem.properties.powerConsumptionW;
    }
    return undefined;
};

/**
 * Get the power requirement of a pia item using the
 * maximum power draw data from PIA.
 */
export const getPowerFromPowerConsumptionWOrPoeClass = (piaItem: IPiaItem) => {
    const power = getPowerFromSystemComponent(piaItem);
    if (power !== undefined) {
        return power;
    }

    if (!isPiaPoeConsumer(piaItem)) return undefined;

    if (piaItem.properties.powerConsumptionW) {
        return piaItem.properties.powerConsumptionW;
    }

    if (piaItem.properties.maxPoEPower15WPSE) {
        return piaItem.properties.maxPoEPower15WPSE;
    }

    if (piaItem.properties.PoEClass) {
        return portMaxPowerExcludingPowerLoss[piaItem.properties.PoEClass];
    }

    return undefined;
};

/**
 * Get the power requirement of a pia item using the
 * typical power data from PIA.
 * devices supporting poe:
 * typicalPoEPower typical power consumption at the devices, not including cable power loss - fallback powerConsumptionW
 * devices without poe:
 * typicalACPower - typical power consumption for AC
 * typicalDCPower - typical power consumption for DC
 * Calculate the typical power from the device as the max value of typicalACPower and typicalDCPower,
 * fallback to powerConsumptionW if these are missing
 */
export const getTypicalPowerFromPiaForDevice = (piaItem: IPiaItem) => {
    const power = getPowerFromSystemComponent(piaItem);
    if (power !== undefined) {
        return power;
    }

    if (!isPiaPoeConsumer(piaItem)) return undefined;

    if (piaItem.properties.powerOverEthernet && piaItem.properties.typicalPoEPower) {
        return piaItem.properties.typicalPoEPower;
    } else if (piaItem.properties.typicalACPower || piaItem.properties.typicalDCPower) {
        if (piaItem.properties.typicalACPower && piaItem.properties.typicalDCPower) {
            return Math.max(piaItem.properties.typicalACPower, piaItem.properties.typicalDCPower);
        }
        if (piaItem.properties.typicalACPower) {
            return piaItem.properties.typicalACPower;
        }
        if (piaItem.properties.typicalDCPower) {
            return piaItem.properties.typicalDCPower;
        }
    }

    return piaItem.properties.powerConsumptionW;
};

/**
 * Get the power required by each device calculated using
 * the PoE-class of the devices
 */
export const getDevicePowerFromClass = createSelector(
    [getItemsWithPortRequirement, getPiaItemsRecord, getHasPoeAccessory],
    (items, piaItems, poeAccessoryMap) =>
        items.reduce((map: Record<Id, number>, item) => {
            let power = 0;
            const hasPoeAccessory = poeAccessoryMap[item._id] ?? false;
            if (!hasPoeAccessory) {
                const piaItem = piaItems[item.productId!] as IPiaDevice;
                power = isCustomCamera(item)
                    ? getCustomCameraPowerFromClass(item)
                    : getPowerFromClass(piaItem, piaItems);
            }
            map[item._id] = power;
            return map;
        }, {}),
);

/**
 * Get the power required by each device calculated using
 * the maximum power draw data from PIA.
 * Will fall back to PoE-class if PIA data is missing.
 */
export const getDevicePowerFromMaxPower = createSelector(
    [getItemsWithPortRequirement, getPiaItemsRecord, getHasPoeAccessory],
    (items, piaItems, poeAccessoryMap) =>
        items.reduce((map: Record<Id, number>, item) => {
            let power = 0;
            const hasPoeAccessory = poeAccessoryMap[item._id] ?? false;
            if (!hasPoeAccessory) {
                const piaItem = item.productId ? (piaItems[item.productId] as IPiaDevice) : null;
                power = isCustomCamera(item)
                    ? getCustomCameraMaxRating(item)
                    : piaItem
                      ? getPowerFromMaxRating(piaItem, piaItems)
                      : 0;
            }
            map[item._id] = power;
            return map;
        }, {}),
);

/**
 * Implementation shared between getRequiredPowerFromClass and getRequiredPowerFromMaxPower
 */
const getTotalPower = (
    items: IPersistence<IItemEntity>[],
    powerMap: Record<Id, number>,
    piaItems: Record<number, IPiaItem>,
) =>
    items
        .filter((item) => needsPort(item, item.productId ? piaItems[item.productId] : undefined))
        .reduce((totalPower, item) => {
            const power = powerMap[item._id] ?? 0;
            return totalPower + power * item.quantity;
        }, 0);

/**
 * Get the total amount of power required by the system
 * calculated using the PoE-class of the devices
 */
export const getRequiredPowerFromClass = createSelector(
    [getCurrentProjectItemsArray, getDevicePowerFromClass, getPiaItemsRecord],
    getTotalPower,
);

/**
 * Get the total amount of power required by the system
 * calculated using the maximum power draw data from PIA.
 * Will fall back to PoE-class if PIA data is missing.
 */
export const getRequiredPowerFromMaxPower = createSelector(
    [getCurrentProjectItemsArray, getDevicePowerFromMaxPower, getPiaItemsRecord],
    getTotalPower,
);

/**
 * Get the total power requirement for the project.
 * Uses the project settings to return either the PoE-class-based approximation
 * or the maximum power draw from PIA data.
 */
export const getRequiredPower = createSelector(
    [
        getRequiredPowerFromClass,
        getRequiredPowerFromMaxPower,
        getCurrentProjectPowerCalculationMethod,
    ],
    (powerFromClass, powerFromMax, powerCalcMethod) =>
        powerCalcMethod === 'poeClass' ? powerFromClass : powerFromMax,
);

/**
 * Get the power requirement for all items in project.
 * Uses the project settings to return either the PoE-class-based approximation
 * or the maximum power draw from PIA data.
 */
export const getDevicePowerRequirements = createSelector(
    [getDevicePowerFromClass, getDevicePowerFromMaxPower, getCurrentProjectPowerCalculationMethod],
    (powerFromClass, powerFromMax, powerCalcMethod) =>
        powerCalcMethod === 'poeClass' ? powerFromClass : powerFromMax,
);
