import type {
    ICameraPropertiesFilterEntity,
    IPacPropertiesFilterEntity,
    LightConditions,
    UnitSystem,
} from 'app/core/persistence';
import {
    isCameraDoorStation,
    PAC_DEFAULT_INSTALLATION_HEIGHT,
    isDefaultFilter,
} from 'app/core/persistence';
import { trigonometry } from 'axis-webtools-util';
import type { IDesiredCamera } from '../models';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';

export const desiredCameraUtils = {
    convertDesiredCameraToFilterProperties(
        desiredCamera: IDesiredCamera,
        installationHeight: number,
        piaCamera?: IPiaCamera | IPiaSensorUnit | null,
        unitSystem?: UnitSystem,
    ): ICameraPropertiesFilterEntity {
        const panoramaForPiaCamera = !piaCamera?.properties.canChangePanoramaMode
            ? false
            : desiredCamera.panoramaMode === false
              ? piaCamera.properties.defaultPanoramaMode
              : desiredCamera.panoramaMode;
        const panoramaMode = !piaCamera ? desiredCamera.panoramaMode : panoramaForPiaCamera;
        const isDoorStation = isCameraDoorStation(piaCamera);

        const isFilterChanged =
            desiredCamera.isFilterChanged ||
            !isDefaultFilter(
                {
                    horizontalFov: trigonometry.toDegrees(desiredCamera.horizontalFOVRadians),
                    installationHeight: desiredCamera.installationHeight,
                    corridorFormat: desiredCamera.corridorFormat,
                    pixelDensity: desiredCamera.pixelDensity,
                    lightConditions: desiredCamera.lightConditions,
                    cameraTypes: desiredCamera.cameraTypes,
                    sensorUnitTypes: desiredCamera.sensorUnitTypes,
                    targetHeight: desiredCamera.targetHeight,
                    distanceToTarget: desiredCamera.distanceToTarget,
                    outdoor: desiredCamera.outdoor,
                    panoramaMode,
                    applications: desiredCamera.applications,
                    isSceneFilterActive: desiredCamera.isSceneFilterActive,
                    isFilterChanged: desiredCamera.isFilterChanged,
                },
                installationHeight,
                unitSystem,
            );

        return {
            lightConditions: desiredCamera.lightConditions,
            corridorFormat: desiredCamera.corridorFormat,
            distanceToTarget: desiredCamera.distanceToTarget,
            cameraTypes: desiredCamera.cameraTypes,
            sensorUnitTypes: desiredCamera.sensorUnitTypes,
            horizontalFov: trigonometry.toDegrees(desiredCamera.horizontalFOVRadians),
            installationHeight:
                isDoorStation && !isFilterChanged
                    ? PAC_DEFAULT_INSTALLATION_HEIGHT
                    : desiredCamera.installationHeight,
            pixelDensity: desiredCamera.pixelDensity,
            targetHeight: desiredCamera.targetHeight,
            outdoor: desiredCamera.outdoor,
            panoramaMode,
            isSceneFilterActive: desiredCamera.isSceneFilterActive,
            applications: desiredCamera.applications,
            isFilterChanged,
        };
    },
    convertPropertiesToDesiredCamera(properties: ICameraPropertiesFilterEntity): IDesiredCamera {
        return {
            horizontalFOVRadians: trigonometry.toRadians(properties.horizontalFov),
            corridorFormat: properties.corridorFormat,
            installationHeight: properties.installationHeight,
            lightConditions: properties.lightConditions as LightConditions[],
            pixelDensity: properties.pixelDensity,
            cameraTypes: properties.cameraTypes,
            sensorUnitTypes: properties.sensorUnitTypes,
            distanceToTarget: properties.distanceToTarget,
            targetHeight: properties.targetHeight,
            outdoor: properties.outdoor,
            panoramaMode: properties.panoramaMode,
            applications: properties.applications,
            isSceneFilterActive: properties.isSceneFilterActive,
            isFilterChanged: properties.isFilterChanged,
        };
    },
    convertDesiredCameraToPacFilterProperties(
        desiredCamera: IDesiredCamera,
    ): IPacPropertiesFilterEntity {
        return {
            outdoor: desiredCamera.outdoor,
        };
    },
};
