import { injectable } from 'inversify';
import { SharedProjectRepository } from './SharedProject.repository';

@injectable()
export class SharedProjectsService {
    constructor(private sharedProjectRepository: SharedProjectRepository) {}

    /**
     * Clear all locally stored shared project info.
     */
    public clearSharedProjectInfo = () => this.sharedProjectRepository.clearSharedProjectInfo();

    /**
     * A function to check if resource group in shared project info has changed,
     * previously selected resource group not same as current selected resource group.
     * @returns true if resource group in shared project info has changed, false otherwise.
     */
    public hasResourceGroupChanged = async () => {
        const previousSharedProject =
            await this.sharedProjectRepository.getPreviousSelectedSharedProjectInfo();
        const sharedProject = await this.sharedProjectRepository.getSelectedSharedProjectInfo();
        if (!previousSharedProject) {
            if (sharedProject) {
                await this.sharedProjectRepository.setPreviousSelectedSharedProjectInfo(
                    sharedProject,
                );
            }
            // No previous user data - nothing to clean
            return false;
        }

        if (sharedProject) {
            await this.sharedProjectRepository.setPreviousSelectedSharedProjectInfo(sharedProject);
        }
        if (
            sharedProject !== null &&
            previousSharedProject !== null &&
            sharedProject?.resourceGroup?.arn !== previousSharedProject?.resourceGroup?.arn
        ) {
            return true;
        }

        // Logged in user is same as previous user - do nothing
        return false;
    };
}
