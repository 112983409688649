import type { PiaId } from 'app/core/pia';
import type {
    DeviceType,
    IBounds,
    Id,
    IDeviceAnalyticRange,
    ILocated,
    IPoint,
    ILatLng,
} from 'app/core/persistence';
import type { IFileTransferState } from './IFileTransferStates';
import type { IFOVLimits } from 'app/modules/common';
import type { IPressedModifierKeys } from './IPressedModifierKeys';
import type { IDuplicationInfo } from './IDuplicationInfo';
import type { Colors } from 'app/styles';
export type contextMenuType = 'device' | 'installationPoint';

export interface ISelectedMapItem extends ILocated {
    id: Id;
    parentDeviceId: Id;
    labelOffset: IPoint;
    height: number;
}

export interface ISelectedCoverageAreaInfo {
    sensorId: number;
    targetDistance: number;
    horizontalAngle: number;
    targetHeight?: number;
}

export interface ISensorCoverageAreaInfo extends ISelectedCoverageAreaInfo {
    fovLimits: IFOVLimits;
    horizontalFov: number;
    corridorFormat: boolean;
}

export function isSensorCoverageAreaInfo(object: unknown): object is ISensorCoverageAreaInfo {
    return object
        ? Object.prototype.hasOwnProperty.call(object, 'fovLimits') &&
              Object.prototype.hasOwnProperty.call(object, 'horizontalFov') &&
              Object.prototype.hasOwnProperty.call(object, 'corridorFormat')
        : false;
}

export interface ILayerFilter {
    selectedDeviceTypes: DeviceType[];
    selectedDeviceTypeCoverageAreas: DeviceType[];
    selectedColors: Colors[];
    labelsVisible: boolean;
    textBoxesVisible: boolean;
}

export type BlockerEditState = 'none' | 'creating' | 'create_start' | 'edit_start' | 'deleting';
export type AddDeviceTabName =
    | 'cameras'
    | 'speakers'
    | 'radars'
    | 'doorControllers'
    | 'mainUnits'
    | 'encoders'
    | 'others';

export interface IAddDeviceTab {
    name: AddDeviceTabName;
    deviceTypes: DeviceType[];
}

export interface IFloorPlanGeolocation {
    position: ILatLng;
    width: number;
    height: number;
    angle: number;
}

export interface IFloorPlanGeolocationChanges {
    [id: Id]: IFloorPlanGeolocation;
}

export interface IMapsState {
    selectedAddTab: IAddDeviceTab;
    selectedDragProduct: Record<AddDeviceTabName, PiaId | null>;
    addCameraSearchFilter: string;
    selectedMap?: Id;
    selectedFreeText?: Id;
    uploadErrorMessage?: string;
    uploading: boolean;
    showAddMapModal: boolean;
    showAddLocationModal: boolean;
    showCopyMapModal: boolean;
    showDevicesOnMapToggle: boolean;
    enableScalingTool: boolean;
    streetModeOn: boolean;
    doriOn: boolean;
    enable3dView: boolean;
    fileTransfers: IFileTransferState[];
    zoomLevel: number;
    /**
     * Refers to items that are displayed in the context menu
     */
    selected?: {
        mapItem?: ISelectedMapItem;
        deviceId?: Id;
        floorPlanId?: Id;
        type: contextMenuType;
        coverageAreaInfo?: ISelectedCoverageAreaInfo;
    };
    analyticRange?: IDeviceAnalyticRange;
    pressedModifierKeys: IPressedModifierKeys;
    duplicationInfo: IDuplicationInfo | null;
    showRadarCoexistenceWarning: boolean;
    errorMessage: boolean;
    mapViewBounds: Record<Id, IBounds | undefined>;
    selectedLayers: ILayerFilter;
    useTinyIcons: boolean;
    showFreeTextTool: boolean;
    showLayerSelector: boolean;
    showMeasureTool: boolean;
    showKeyboardShortcuts: boolean;
    showGetToKnowMaps: boolean;
    focusedFloorPlanId?: Id; // the floor plan (if any) id that is currently focused in leaflet
    blockerEditState: BlockerEditState;
    deviceOverlayPanel?: { isOpen: boolean; initialTab?: 'accessories' | 'applications' };
    floorPlanConfigOverlayPanel?: boolean;
    floorPlanToGeolocate: Id | null;
    floorPlanGeoLocationChanges: IFloorPlanGeolocationChanges;
    defaultGeoLocationChanged: boolean;
    currentMapLocation?: ILatLng;
    desiredBounds: Record<Id, IBounds | undefined>;
    originFilter: Id | undefined;
}
