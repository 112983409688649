import { injectable } from 'inversify';
import type { PiaId } from 'app/core/pia';
import { encrypt } from '../../utils/crypto';
import type { IBullet, IDescriptionText, ILogoUrl } from './DeviceDescriptionModels';
import { isErrorType } from '../../utils';
import { isEmpty } from 'lodash-es';

type PiaIdString = string;
type Endpoint = string;
type Language = string;
type Descriptions = Record<Endpoint, string>;
type ProductInfo = Record<Language, Descriptions>;
type IJsonResponse = Record<PiaIdString, ProductInfo>;

const TEXT_API_URL = '/content';

/**
 * Provides translated descriptive device summaries.
 */
@injectable()
export class DeviceDescriptionCommunicator {
    public async fetchDescription(
        piaId: PiaId,
        languageCode: string,
        /** Fetch longDescriptiveCopy instead of descriptiveCopy */
        long?: boolean,
    ): Promise<IDescriptionText> {
        const endpoint = long ? 'longDescriptiveCopy' : 'descriptiveCopy';

        const jsonResponse: any = await this.tryFetch(piaId, languageCode, endpoint);
        if (isEmpty(jsonResponse)) {
            return {
                description: '',
                languageCode,
                long,
            };
        }

        const hasChosenLanguage: boolean = !!jsonResponse[`${piaId}`][`${languageCode}`];

        /** Description in chosen language. If not found checks for English description. */
        const description: string =
            jsonResponse[`${piaId}`][hasChosenLanguage ? `${languageCode}` : 'en-US'][endpoint]
                .value;

        /** Stringified description without wrapping "" characters. Allows us to read special characters such as \n */
        const stringified = JSON.stringify(description).slice(1, -1);
        /** Reformated description to parse line breaks and tabs */
        const withLineBreaks = stringified.replaceAll('\\n', '<br>').replaceAll('\\t', '&emsp;');

        return {
            description: withLineBreaks,
            languageCode,
            long,
        };
    }

    public async fetchPartnerLogo(piaId: PiaId, languageCode: string): Promise<ILogoUrl> {
        const jsonResponse: any = await this.tryFetch(piaId, languageCode, 'url_PartnerLogo');

        const logoUrl = jsonResponse[`${piaId}`][`${languageCode}`].url_PartnerLogo.value;

        return { logoUrl };
    }

    public async fetchBulletPoints(piaId: PiaId, languageCode: string): Promise<IBullet> {
        const jsonResponse: any = await this.tryFetch(piaId, languageCode, 'bullets');

        const hasChosenLanguage: boolean = !!jsonResponse[`${piaId}`]?.[`${languageCode}`];
        const bullets =
            isEmpty(jsonResponse) || !hasChosenLanguage
                ? []
                : jsonResponse[`${piaId}`][`${languageCode}`].bullets.value;
        return { bullets, languageCode };
    }

    /** Attempts to fetch data from TXT API */
    private async tryFetch(
        piaId: number,
        languageCode: string,
        endpoint: string,
    ): Promise<IJsonResponse | Error | undefined> {
        try {
            return fetch(`${TEXT_API_URL}/${encrypt(piaId)}/${languageCode}/${endpoint}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            }).then((response) => response.json());
        } catch (error) {
            if (isErrorType(error)) return error;
        }
    }
}
