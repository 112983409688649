import * as React from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';

interface INavigateProps {
    children: (navigate: NavigateFunction) => any;
}

const Navigate: React.FC<INavigateProps> = ({ children }) => {
    const navigate = useNavigate();
    return children(navigate);
};

interface ILinkableProps extends IExtendableComponentWithChildren {
    /**
     * URL to redirect to.
     * If not set will render children.
     */
    link?: string;
    /**
     * If you want to link to an external URL set this prop
     */
    external?: boolean;
    /**
     * Called before navigating to specified link.
     */
    onNavigate?(): void;
}

/**
 * Takes a child and attaches
 * an onClick event that causes a redirect (using react-router)
 * to the specified link/route.
 *
 * With the `external` prop this will use window.location to
 * redirect the user to an external URL instead.
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export class Linkable extends React.Component<ILinkableProps> {
    constructor(props: ILinkableProps) {
        super(props);
    }

    public render() {
        const { link, children, ...extendedProps } = this.props;

        if (!link) {
            return children;
        }

        const child = React.Children.only(children);

        return (
            <Navigate>
                {(navigate) => {
                    const onClick = (event: React.MouseEvent) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (this.props.onNavigate) {
                            this.props.onNavigate();
                        }
                        if (this.props.external) {
                            return window.open(this.props.link, '_blank');
                        }
                        if (this.props.link) {
                            navigate(this.props.link);
                        }
                    };

                    const htmlAttributes = extendableProps(
                        extendedProps,
                        {
                            onClick,
                        },
                        true,
                    );

                    const props = {
                        onClick,
                        ...extendedProps,
                    };
                    return renderReactChildren(
                        child,
                        (c) => React.cloneElement(c, props),
                        (c) => React.cloneElement(c, htmlAttributes),
                    );
                }}
            </Navigate>
        );
    }
}
