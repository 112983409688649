import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { getInstallationPointsArray } from '../../installationPoints';
import { toCacheKey } from '../../cacheKey';
import { getParentId } from 'app/core/persistence';

export const getInstallationPointsForItem = createCachedSelector(
    [getCurrentProjectItem, getInstallationPointsArray],
    (item, installationPoints) => {
        if (!item) {
            return [];
        }
        return installationPoints.filter((ip) => getParentId(ip) === item._id);
    },
)(toCacheKey);

export const getInstallationPointsForItemCount = createCachedSelector(
    [getInstallationPointsForItem],
    (installationsPoints) => installationsPoints.length,
)(toCacheKey);
