import type { ISustainabilityFilter } from 'app/core/persistence';
import {
    defaultSustainabilityFilter,
    isAccessServer,
    isAnsweringUnit,
    isDoorStation,
    isNetworkReader,
} from 'app/core/persistence';
import type { IPiaTwoNCategory } from 'app/core/pia';

const accessServerType = 'accessServers';
const answeringUnitType = 'answeringUnits';
const interComType = 'interComs';
const networkReaderType = 'networkReaders';

export type twoNType =
    | typeof accessServerType
    | typeof answeringUnitType
    | typeof interComType
    | typeof networkReaderType;
export interface ITwoNFilter extends ISustainabilityFilter {
    selectedCategory?: twoNType;
}

export const defaultTwoNFilter: ITwoNFilter = {
    selectedCategory: undefined,
    ...defaultSustainabilityFilter,
};

export class TwoNFilter {
    public static filter(
        twoNFilter: ITwoNFilter,
        allTwoNDevices: IPiaTwoNCategory[],
        searchFilter: string,
    ): IPiaTwoNCategory[] {
        const lowerCaseSearchFilter = searchFilter.toLowerCase();

        return allTwoNDevices.filter((twoNDevice) => {
            if (twoNDevice.properties.vendor !== '2N') {
                return false;
            }
            if (!twoNDevice.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (twoNFilter.selectedCategory === 'accessServers' && !isAccessServer(twoNDevice)) {
                return false;
            }
            if (twoNFilter.selectedCategory === 'answeringUnits' && !isAnsweringUnit(twoNDevice)) {
                return false;
            }
            if (twoNFilter.selectedCategory === 'interComs' && !isDoorStation(twoNDevice)) {
                return false;
            }
            if (twoNFilter.selectedCategory === 'networkReaders' && !isNetworkReader(twoNDevice)) {
                return false;
            }

            return true;
        });
    }
}
