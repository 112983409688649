import * as React from 'react';
import { Border, Box, Text } from 'app/components';

interface IHeadlineProps {
    text?: string;
}

export const Headline: React.FunctionComponent<IHeadlineProps> = ({ text }) => {
    return (
        <Border bottomWidth={1} color="warmGrey4">
            <Box width="100%" display="block" paddingBottom="base">
                <Text style="headline" color="warmGrey6">
                    {text}
                </Text>
            </Box>
        </Border>
    );
};

Headline.displayName = 'Headline';
