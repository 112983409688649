import { Module } from 'app/ioc';
import { ToasterService } from './services/Toaster.service';
import { ToasterActionService } from './services/Toaster.ActionService';
import { toastReducer } from './state/Toast.reducer';

@Module({
    binder: (binder) => {
        binder.bindService(ToasterActionService);
        binder.bindService(ToasterService);
    },
    reducers: {
        toasts: toastReducer,
    },
})
export class ToasterModule {}
