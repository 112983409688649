import type { PolyLine, ILatLng, IBlockerEntity } from 'app/core/persistence';
import { uniq, differenceWith, isEqual } from 'lodash-es';

export const mergeBlockers = (a: PolyLine[], b: PolyLine[]): PolyLine[] => {
    return uniq([...a, ...b]);
};

/**
 * Returns the diff of oldBlockers and newBlockerPolylines
 * @param oldBlockers an array of blocker entities
 * @param newBlockerPolylines an array of blockers latLngs
 * @returns an object with the arrays `added`, `deleted` and `edited`
 */
export const diffBlockers = (oldBlockers: IBlockerEntity[], newBlockerPolylines: ILatLng[][]) => {
    // find out which blockers that are present in newBlockerPolylines but not in oldBlockers
    const addedOrEditedBlockers = differenceWith(newBlockerPolylines, oldBlockers, (a, b) =>
        isEqual(a, b.latLngs),
    );

    // find out which blockers that are present in oldBlockers but not in newBlockerPolylines
    const removedBlockers = differenceWith(oldBlockers, newBlockerPolylines, (a, b) =>
        isEqual(a.latLngs, b),
    );

    // create a list of all added blockers
    const polylinesToAdd =
        newBlockerPolylines.length > oldBlockers.length ? addedOrEditedBlockers : [];

    // create a list of all deleted blockers
    const blockersToDelete = oldBlockers.length > newBlockerPolylines.length ? removedBlockers : [];

    // create a list of all edited blockers
    const blockersToUpdate = addedOrEditedBlockers
        .slice(0, removedBlockers.length)
        .map((latLngs, i) => {
            return {
                ...removedBlockers[i],
                latLngs,
            };
        });

    return {
        polylinesToAdd,
        blockersToDelete,
        blockersToUpdate,
    };
};
