import { memoize } from 'lodash-es';

// save the canvas to avoid recreation every time
// 2d context cannot be null
const getCanvasContext = memoize((fontSize: number, fontWeight: string) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    ctx.font = `${fontWeight} ${fontSize}px "Open sans"`;
    return ctx;
});

/**
 * Measure the text width
 *
 * @param text - the input text to measure
 * @param fontSize - the size of the font
 * @returns The width in pixels
 */
export const getTextWidth = (text: string, fontSize = 10, fontWeight = 'normal') => {
    const context = getCanvasContext(fontSize, fontWeight);
    return Math.ceil(context.measureText(text).width);
};

/**
 * returns the text, cropped with ellipsis in middle if longer than maxLength
 *
 * @param textToCrop - the input text to crop if longer than maxLength
 * @param maxLength - max length of text
 * @returns The text, cropped if needed
 */
export const cropTextMiddle = (textToCrop: string, maxLength: number) => {
    let croppedLabel = textToCrop;
    if (croppedLabel.length > maxLength) {
        const start = textToCrop.slice(0, maxLength / 2);
        const ellipsis = '...';
        const end = textToCrop.slice(-maxLength / 2);
        croppedLabel = start + ellipsis + end;
    }
    return croppedLabel;
};
