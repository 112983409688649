import * as React from 'react';
import { t } from 'app/translate';
import {
    Box,
    Border,
    Stack,
    Spinner,
    TextInput,
    TextPlaceholder,
    Button,
    Text,
    IconButton,
} from 'app/components';
import { eventTracking } from 'app/core/tracking';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import { getIsCreatingAccessCode } from '../selectors';
import { ExportProjectActionService } from '../services';
import type { Id } from 'app/core/persistence';
import { getUserSignedIn } from 'app/modules/common';
import { toaster } from 'app/toaster';
import type { IProjectSettingToken } from 'app/modules/userProjects';
import copy from 'clipboard-copy';

interface IGeneratedCodeProps {
    projectId: Id;
    projectSettingToken: IProjectSettingToken | null;
}

export const GeneratedCode: React.FC<IGeneratedCodeProps> = ({
    projectId,
    projectSettingToken,
}) => {
    const isCreatingAccessCode = useSelector(getIsCreatingAccessCode);
    const exportProjectActions = useService(ExportProjectActionService);
    const isExpired = Boolean(projectSettingToken?.expired);
    const isSignedIn = useSelector(getUserSignedIn);

    const generateToken = () => {
        eventTracking.logUserEvent('Export project', 'Generate Token');
        if (isSignedIn) {
            exportProjectActions.generateToken(projectId, projectSettingToken);
        } else {
            toaster.info(t.projectShareErrorUnauthorized);
        }
    };

    const revokeToken = () => {
        eventTracking.logUserEvent('Export project', 'Revoke Token');
        if (isSignedIn) {
            exportProjectActions.revokeToken(projectId, projectSettingToken);
        } else {
            toaster.warning(t.projectShareErrorRevokeCodeFailed);
        }
    };

    const copyCode = (token: string) => {
        copy(token);
        toaster.success(t.clipboardCopiedToClipboardSuccess);
    };

    return (
        <Box width="100%" justifyContent="center">
            <Box justifyContent="center" paddingY="base">
                <Border color={isExpired ? 'red' : 'grey4'} radius="4px" style="dashed">
                    <Box color="grey1" paddingY="base" paddingX="panel">
                        <Stack vertical alignItems="center">
                            <Text style="semibold" color="grey9">
                                {t.yourAccessCode}
                            </Text>
                            {isCreatingAccessCode ? (
                                <Spinner size="30px" />
                            ) : (
                                <Box
                                    display="grid"
                                    spacing="base"
                                    alignItems="center"
                                    grid={{ gridTemplateColumns: '1fr max-content 1fr' }}
                                >
                                    <Box />
                                    <TextInput
                                        testId="generated_code_npt"
                                        value={projectSettingToken?.token}
                                        onChange={() => {}}
                                        align="center"
                                        code
                                        readOnly
                                    />
                                    {projectSettingToken?.token && (
                                        <IconButton
                                            size="ms"
                                            icon="content_copy"
                                            onClick={() => copyCode(projectSettingToken.token)}
                                        />
                                    )}
                                </Box>
                            )}
                            {isCreatingAccessCode ? (
                                <TextPlaceholder width="140px" textAlign="center" />
                            ) : (
                                <Text
                                    color={isExpired ? 'red' : 'grey6'}
                                    style={isExpired ? 'semibold' : undefined}
                                >
                                    {isExpired
                                        ? t.projectShareCodeExpired(
                                              projectSettingToken?.validUntil.toLocaleDateString() ??
                                                  '?',
                                          )
                                        : t.projectShareCodeExpires(
                                              projectSettingToken?.validUntil.toLocaleDateString() ??
                                                  '?',
                                          )}
                                </Text>
                            )}
                            <Stack>
                                <Button testId="revoke_code_btn" text onClick={revokeToken}>
                                    {t.projectShareRevokeCode}
                                </Button>
                                <Button
                                    testId="re_generate_code_btn"
                                    primary={isExpired}
                                    onClick={generateToken}
                                >
                                    {t.projectShareGenerateNewCode}
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Border>
            </Box>
        </Box>
    );
};

GeneratedCode.displayName = 'GeneratedCode';
