import { injectGlobal } from '@emotion/css';
import { ColorsEnum } from './colors.type';

import * as openSansBold from './google/OpenSans-Bold.ttf';
import * as openSansItalic from './google/OpenSans-Italic.ttf';
import * as openSansLight from './google/OpenSans-Light.ttf';
import * as openSansLightItalic from './google/OpenSans-LightItalic.ttf';
import * as openSansRegular from './google/OpenSans-Regular.ttf';
import * as openSansSemiBold from './google/OpenSans-SemiBold.ttf';

import * as ttf from '../components/ui/icon/axis-wt-icons.ttf';
import * as woff from '../components/ui/icon/axis-wt-icons.woff';

const fontSize = '12px';
const lineHeight = 1.42857143;

injectGlobal`
    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        src: local('Open Sans Bold'), local('OpenSans-Bold'),
            url(${openSansBold}) format('truetype');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        src: local('Open Sans Italic'), local('OpenSans-Italic'),
            url(${openSansItalic}) format('truetype');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        src: local('Open Sans Light'), local('OpenSans-Light'),
            url(${openSansLight}) format('truetype');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        src: local('Open Sans LightItalic'), local('OpenSans-LightItalic'),
            url(${openSansLightItalic}) format('truetype');
    }
    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        src: local('Open Sans Regular'), local('OpenSans-Regular'),
            url(${openSansRegular}) format('truetype');
    }
    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
            url(${openSansSemiBold}) format('truetype');
    }

    @font-face {
        font-family: 'axis-wt-icons';
        src: url(${ttf}) format('truetype'), url(${woff}) format('woff');

        font-weight: normal;
        font-style: normal;
    }

    @font-size: ${fontSize};
    @line-height: ${lineHeight};


    body,
    input,
    textarea,
    select,
    button {
        font-family: 'Open Sans', 'Arial', sans-serif;
        font-size: ${fontSize};
        line-height: ${lineHeight};
        padding: 0;
    }

    a {
        color: ${ColorsEnum.blue};
        text-decoration: none;
        font-weight: 600;
    }

    input,
    textarea {
        border-width: 0;
    }

    input::-ms-clear {
        display: none;
    }

    @media print {
        body {
            background-color: unset !important;
        }
        /* Remove scrollbars on print */
        * {
            overflow: visible !important;
        }
    }

    @page {
        margin: 5mm;
    }

    [aria-disabled='true'],
    [disabled] {
        opacity: 0.4 !important;
        cursor: default !important;
    }

    .leaflet-tile-container img {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
    }

    .leaflet-container .leaflet-control-attribution {
        background: none !important;
    }

    .leaflet-container .leaflet-top {
        top: 70px;
    }
`;
