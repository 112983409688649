import { KeyValueStore } from 'axis-webtools-util';

export function deleteDatabase(databaseName: string): Promise<void> {
    return new Promise((resolve, reject) => {
        const req = indexedDB.deleteDatabase(databaseName);

        req.onsuccess = () => resolve();
        req.onerror = reject;
        req.onblocked = reject;
    });
}

export function deleteDatabaseKey(databaseName: string, key: string): Promise<void> {
    const store = new KeyValueStore<any>(databaseName, key);

    return store.clear();
}
