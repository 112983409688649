import { createSelector } from 'reselect';
import {
    getBandwidthStorageEstimate,
    getRecordingBandwidthStorageEstimate,
} from './getBandwidthStorageEstimate';
import { getBandwidthStorageTotal } from './getBandwidthStorageTotal';

/**
 * Selector for getting the formatted bandwidth of currentProject
 */
export const getFormattedBandwidth = createSelector(
    [getBandwidthStorageEstimate],
    (estimate) => estimate.formattedBandwidth,
);

/**
 * Selector for getting the used bandwidth of currentProject
 */
export const getBandwidthUsed = createSelector(
    [getBandwidthStorageEstimate],
    (estimate) => estimate.bandwidth,
);

/**
 * Selector for getting the used recording bandwidth of currentProject
 */
export const getRecordingBandwidthUsed = createSelector(
    [getRecordingBandwidthStorageEstimate],
    (estimate) => estimate.bandwidth,
);

/**
 * Selector for getting the total available bandwidth in currentProject
 */
export const getBandwidthTotal = createSelector(
    [getBandwidthStorageTotal],
    (total) => total.bandwidth,
);
