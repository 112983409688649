import * as React from 'react';
import type { IClickableProps } from '../../functional';
import { Clickable } from '../../functional';
import { Text } from '../../text';
import { Box } from '../../containers';
import type { IAutoTestable } from '../../ui-test';
import { Border } from '../../style/Border';
import type { Spacings } from 'app/styles';
import type { IExtendableComponent } from '../../models/IExtendableComponent';

interface IRadioButton extends IClickableProps, IAutoTestable, IExtendableComponent {
    /**
     * The label
     */
    text?: string;
    /**
     * Whether the tab is active
     */
    isActive: boolean;
    /**
     * Allow the tab to grow horizontally
     */
    fullWidth?: boolean;
    /**
     * X axis padding
     */
    paddingX?: Spacings;
    /**
     * Y axis padding
     */
    paddingY?: Spacings;
    /**
     * Top padding
     */
}

export const RadioButton: React.FunctionComponent<IRadioButton> = ({
    text,
    isActive,
    fullWidth,
    paddingX,
    paddingY,
    children,
    ...restProps
}) => (
    <Clickable {...restProps}>
        <Border bottomWidth={3} color={isActive ? 'yellow' : 'transparent'}>
            <Box
                testId={restProps.testId}
                paddingY={paddingY ?? 'base'}
                paddingX={fullWidth ? undefined : paddingX ?? 'panel'}
                justifyContent="center"
                flex={fullWidth ? 'grow' : undefined}
            >
                {text && (
                    <Text style="semibold" color={isActive ? 'grey9' : 'grey7'}>
                        {text}
                    </Text>
                )}
                {children}
            </Box>
        </Border>
    </Clickable>
);

RadioButton.displayName = 'RadioButton';
