import { Table } from 'app/components';
import { t } from 'app/translate';
import * as React from 'react';

interface IInstallationDetailsTableProps {
    tiltAngles?: number[];
    installationHeight?: string;
}

export const InstallationDetailsTable: React.FC<IInstallationDetailsTableProps> = ({
    tiltAngles,
    installationHeight,
}) => {
    if (!tiltAngles && !installationHeight) return null;
    const sensorCount = tiltAngles?.length;

    const headers = [
        ...(installationHeight ? [t.installationHeight] : []),
        ...(sensorCount === 1 ? [t.installationReportInstallationTiltTitle] : []),
    ];

    const rows = [
        [
            ...(installationHeight ? [installationHeight] : []),
            ...(tiltAngles && sensorCount === 1 ? [`${tiltAngles[0]}°`] : []),
        ],
    ];

    return <Table headers={headers} rows={rows}></Table>;
};
