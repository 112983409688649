import type { IScalingCostParameters } from '../../models';
import type { BandwidthOptions } from './BandwidthOptions';
import type { Condition } from './Condition';
import { constants } from './constants';

/**
 * Abstract base class used by calculators
 */
export abstract class BitrateCalculator {
    constructor(protected bandwidthOptions: BandwidthOptions) {}

    abstract getBitrate(condition: Condition): number;

    protected calcBitrate(condition: Condition, motionLevel: number, frameRate: number) {
        const pNoise = this.getPNoise(condition.lighting, condition.weather);
        const backgroundLevel = this.getBackgroundLevel(condition.lightingFactor);

        const iFrameBRef = this.getReferenceCameraLfsProperty(condition.lighting, 'bI');
        const pFrameBRef = this.getReferenceCameraLfsProperty(condition.lighting, 'bP');
        const iFrameCRef = this.getReferenceCameraLfsProperty(condition.lighting, 'cI');
        const pFrameCRef = this.getReferenceCameraLfsProperty(condition.lighting, 'cP');
        const iFrameB = this.getCameraLfsProperty(condition.lighting, 'bI');
        const pFrameB = this.getCameraLfsProperty(condition.lighting, 'bP');
        const iFrameC = this.getCameraLfsProperty(condition.lighting, 'cI');
        const pFrameC = this.getCameraLfsProperty(condition.lighting, 'cP');
        const iFrameScaleFactorRef = this.getIFrameScaleFactorRef(iFrameBRef, iFrameCRef);
        const pFrameScaleFactorRef = this.getPFrameScaleFactorRef(pFrameBRef, pFrameCRef);
        const iFrameScaleFactor = this.getIFrameScaleFactor(iFrameB, iFrameC);
        const pFrameScaleFactor = this.getPFrameScaleFactor(pFrameB, pFrameC);
        const iFrameScaleFactorZip = this.getIFrameScaleFactorZip(iFrameB, iFrameC);
        const pFrameScaleFactorZip = this.getPFrameScaleFactorZip(pFrameB, pFrameC);
        const iFrameScaleFactorThroughRef =
            (iFrameScaleFactor + this.calcDiSize(iFrameScaleFactor)) / iFrameScaleFactorRef;
        const iFrameScaleFactorZipThroughRef = iFrameScaleFactorZip / iFrameScaleFactorRef;
        const pFrameScaleFactorThroughRef = pFrameScaleFactor / pFrameScaleFactorRef;
        const pFrameScaleFactorZipThroughRef = pFrameScaleFactorZip / pFrameScaleFactorRef;
        const iGen = this.getIGen(condition.nature, pNoise, condition.lightingFactor);

        let iFrameSize = this.getIFrameSize(
            iGen,
            backgroundLevel,
            iFrameScaleFactorThroughRef,
            iFrameScaleFactorZipThroughRef,
        );

        if (this.bandwidthOptions.scalingCostType === 'MJPEG') {
            iFrameSize *= 2;
        }

        const pFrameSize = Math.min(
            this.getPFrameSize(
                pNoise,
                motionLevel,
                pFrameScaleFactorZipThroughRef,
                iFrameScaleFactorThroughRef,
                pFrameScaleFactorThroughRef,
                iGen,
            ),
            iFrameSize,
        );

        const bFrameSize = pFrameSize * constants.storageBFrameFactor;

        const gop = this.getGOP(frameRate, motionLevel);

        const bitrate =
            (((iFrameSize +
                (gop - 1) *
                    ((pFrameSize + (this.bandwidthOptions.miniGop - 1) * bFrameSize) /
                        this.bandwidthOptions.miniGop)) /
                gop) *
                (this.bandwidthOptions.resolution * frameRate)) /
            1_000_000;
        return bitrate;
    }

    /**
     * Calculate delta_i_size. The delta adjusts I-frame scaling factors
     * based on resolution. Used since I-frames become larger than expected for
     * non-maximum resolutions. This function is empirically derived.
     */
    private calcDiSize(iFrameScaleFactor: number) {
        // Bandwidth version 1 does not use di size
        if (this.bandwidthOptions.version === 1) {
            return 0;
        }

        const cam_max_res = this.bandwidthOptions.maxResolution;
        return (
            3 *
            Math.sqrt(iFrameScaleFactor) *
            Math.sqrt(
                Math.max(0, cam_max_res - this.bandwidthOptions.resolution) /
                    this.bandwidthOptions.resolution,
            ) *
            Math.sqrt(constants.resolutionRef / cam_max_res)
        );
    }

    private getGOP(frameRate: number, motionLevel: number) {
        if (this.bandwidthOptions.scalingCostType === 'MJPEG') {
            return 1;
        }

        if (
            this.bandwidthOptions.version === 1 ||
            (this.bandwidthOptions.zipStrength !== 0 && this.bandwidthOptions.gopMode === 'dynamic')
        ) {
            const gop = Math.max(
                this.bandwidthOptions.gop,
                Math.min(
                    this.bandwidthOptions.gopMax,
                    this.bandwidthOptions.gopFactor * frameRate * (1 - 7 * motionLevel),
                ),
            );

            // In version 1 we don't round this value
            return this.bandwidthOptions.version === 1 ? gop : Math.round(gop);
        }

        return this.bandwidthOptions.gop;
    }

    private getPNoise(lighting: number, isWeather: boolean) {
        let val = 0;
        switch (String(lighting)) {
            case '1':
                val = constants.pNoise['1'];
                break;
            case '2':
                val = constants.pNoise['2'];
                break;
            case '3':
                val = this.bandwidthOptions.scenario.indoor
                    ? constants.pNoise.i3
                    : constants.pNoise.o3;
                break;
        }
        val += isWeather && !this.bandwidthOptions.scenario.indoor ? constants.weatherPNoise : 0;
        return val;
    }

    private getBackgroundLevel(lightingFactor: number) {
        return Math.max(
            0.1,
            Math.min(
                0.8,
                constants.backgroundLevelRef /
                    Math.sqrt(lightingFactor * this.bandwidthOptions.sizeOfAverageObjectFactor),
            ),
        );
    }

    private getReferenceCameraLfsProperty(
        lighting: number,
        name: keyof IScalingCostParameters,
    ): number {
        switch (String(lighting)) {
            case '1':
                return constants.refCam.scalingCost['1'][name];
            case '2':
                return constants.refCam.scalingCost['2'][name];
            case '3':
                return this.bandwidthOptions.scenario.indoor
                    ? constants.refCam.scalingCost.i3[name]
                    : constants.refCam.scalingCost.o3[name];
            default:
                return 0;
        }
    }

    private getCameraLfsProperty(lighting: number, name: keyof IScalingCostParameters): number {
        switch (String(lighting)) {
            case '1':
                return this.bandwidthOptions.scalingCost['1'][name];
            case '2':
                return this.bandwidthOptions.scalingCost['2'][name];
            case '3':
                return this.bandwidthOptions.scenario.indoor
                    ? this.bandwidthOptions.scalingCost.i3[name]
                    : this.bandwidthOptions.scalingCost.o3[name];
            default:
                return 0;
        }
    }

    private getIFrameScaleFactorRef(iFrameBRef: number, iFrameCRef: number) {
        return constants.aRef + Math.exp(iFrameBRef - iFrameCRef * constants.qPRef);
    }

    private getPFrameScaleFactorRef(pFrameBRef: number, pFrameCRef: number) {
        return constants.aRef + Math.exp(pFrameBRef - pFrameCRef * constants.qPRef);
    }

    private getIFrameScaleFactor(iFrameB: number, iFrameC: number) {
        return this.bandwidthOptions.a + Math.exp(iFrameB - iFrameC * this.bandwidthOptions.qp);
    }

    private getIFrameScaleFactorZip(iFrameB: number, iFrameC: number) {
        return (
            this.bandwidthOptions.a +
            Math.exp(
                iFrameB -
                    iFrameC * (this.bandwidthOptions.qp + this.bandwidthOptions.iFrameDeltaQPZip),
            )
        );
    }

    private getPFrameScaleFactorZip(pFrameB: number, pFrameC: number) {
        return (
            this.bandwidthOptions.a +
            Math.exp(
                pFrameB -
                    pFrameC *
                        (this.bandwidthOptions.qp +
                            this.bandwidthOptions.pFrameQPOffset +
                            this.bandwidthOptions.pFrameDeltaQPZip),
            )
        );
    }

    private getPFrameScaleFactor(pFrameB: number, pFrameC: number) {
        return (
            this.bandwidthOptions.a +
            Math.exp(
                pFrameB -
                    pFrameC * (this.bandwidthOptions.qp + this.bandwidthOptions.pFrameQPOffset),
            )
        );
    }

    private getIGen(isNature: boolean, pNoise: number, lightingFactor: number) {
        return (
            this.bandwidthOptions.scenario.details *
                (isNature ? constants.natureFactor : 1) *
                this.bandwidthOptions.sizeOfAverageObjectFactor *
                (this.bandwidthOptions.wdr ? constants.wdrFactor : 1) *
                lightingFactor +
            pNoise
        );
    }

    private getIFrameSize(
        iGen: number,
        backgroundLevel: number,
        iFrameScaleFactorThroughRef: number,
        iFrameScaleFactorZipThroughRef: number,
    ) {
        return (
            iGen * backgroundLevel * iFrameScaleFactorZipThroughRef +
            iGen * (1 - backgroundLevel) * iFrameScaleFactorThroughRef
        );
    }

    private getPFrameSize(
        pNoise: number,
        motionLevel: number,
        pFrameScaleFactorZipThroughRef: number,
        iFrameScaleFactorThroughRef: number,
        pFrameScaleFactorThroughRef: number,
        iGen: number,
    ) {
        const pFrameMotion = this.bandwidthOptions.cPlatform * iGen;
        return (
            pNoise * (1 - motionLevel) * pFrameScaleFactorZipThroughRef +
            motionLevel *
                (pFrameMotion * iFrameScaleFactorThroughRef + pNoise * pFrameScaleFactorThroughRef)
        );
    }
}
