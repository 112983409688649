import type { IPiaDevice } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getDeviceTypeForParentItem } from './getDeviceTypeForParentItem';
import { getParentPiaItem } from './getParentPiaItem';

export const getParentPiaDevice = createCachedSelector(
    [getParentPiaItem, getDeviceTypeForParentItem],
    (piaItem, deviceType) => {
        if (!piaItem) {
            return undefined;
        }

        switch (deviceType) {
            case 'accessServer':
            case 'answeringUnit':
            case 'camera':
            case 'encoder':
            case 'mainUnit':
            case 'networkReader':
            case 'sensorUnit':
            case 'speaker':
            case 'iorelay':
            case 'doorcontroller':
            case 'decoder':
            case 'radardetector':
            case 'doorstation':
            case 'peopleCounter':
            case 'analogCamera':
                return piaItem as IPiaDevice;

            default:
                undefined;
        }
    },
)(toCacheKey);
