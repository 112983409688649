import { injectable } from 'inversify';
import type {
    IItemPropertiesEntity,
    IPersistence,
    IItemEntity,
    IEntity,
    IItem,
    Id,
    IIdRev,
    IDeviceAnalyticRange,
    AnalyticMode,
    zoneType,
    IProjectNetworkSettings,
} from '../../userDataPersistence';
import {
    ItemRepository,
    ItemRelationRepository,
    ProjectRepository,
} from '../../userDataPersistence';

import type { ItemRelationType, PanoramaModes, SpeakerPlacement } from '../../models';
import { CurrentProjectService } from '../CurrentProject.service';
import { getDefaultProfileOverrideEntity, deviceTypeCheckers, getParentId } from '../../utils';
import { CoreItemProductIdUpdateError } from '../errors';
import { QuantityUpdateVerifier } from '../QuantityUpdateVerifier.service';
import { PiaItemService, PiaRelationTypes } from 'app/core/pia';
import type { IPiaSystemComponent, PiaId, IPiaDevice } from 'app/core/pia';
import { getNewItemNetworkSettings } from './getNewItemNetworkSettings';
import type { IBodyWornCameraProfile } from '../../userDataPersistence/entities/item/IBodyWornCameraPropertiesEntity';
import { debounce } from 'lodash-es';
import { AXIS_OBJECT_ANALYTICS, AXIS_PERIMETER_DEFENDER } from 'app/core/common';
@injectable()
export class ItemService {
    constructor(
        private itemRepository: ItemRepository,
        private projectRepository: ProjectRepository,
        private itemRelationRepository: ItemRelationRepository,
        private currentProjectService: CurrentProjectService,
        private quantityUpdateVerifier: QuantityUpdateVerifier,
        private piaSystemComponentService: PiaItemService<IPiaSystemComponent>,
        private piaItemService: PiaItemService<IPiaDevice>,
    ) {}

    public async addToCurrentProject(item: IItem) {
        return this.addItem([this.currentProjectService.getProjectId()], item);
    }

    public async addItemsToCurrentProject(item: IItem[]) {
        return this.addItems([this.currentProjectService.getProjectId()], item);
    }

    public async addItem(parentPath: Id[], item: IItem): Promise<IPersistence<IItemEntity>> {
        return (await this.addItems(parentPath, [item]))[0];
    }

    public async addItems(parentPath: Id[], items: IItem[]): Promise<IPersistence<IItemEntity>[]> {
        const projectId = parentPath[0];
        const { _id, archived, networkSettings } = await this.projectRepository.get(projectId);
        const allProjectItems = await this.getAllProjectItems(_id);
        const itemsToAdd = items.map((item) =>
            this.getItemToAdd(item, parentPath, allProjectItems, networkSettings, archived),
        );
        return this.itemRepository.bulkAdd(itemsToAdd);
    }

    public async addByParentId(parentId: Id, item: IItem): Promise<IPersistence<IItemEntity>> {
        const parentItem = await this.itemRepository.get(parentId);
        return this.addItem(parentItem.path, item);
    }

    public async addItemRelation(
        parentId: Id,
        childId: Id,
        itemRelationType: ItemRelationType,
    ): Promise<IIdRev> {
        const parent = await this.itemRepository.get(parentId);

        const itemRelation = await this.itemRelationRepository.add({
            parentId,
            childId,
            path: parent.path.slice(),
            archived: parent.archived,
            relationType: itemRelationType,
            type: 'itemRelation',
        });
        return itemRelation;
    }

    public async removeItemRelation(itemRelationId: Id, ItemRelationRev: string): Promise<Id> {
        await this.itemRelationRepository.delete(itemRelationId, ItemRelationRev);
        return itemRelationId;
    }

    public async deleteItem(itemId: Id): Promise<Id> {
        const { _rev } = this.currentProjectService.getEntity(itemId, 'item');
        await this.deleteItems([{ _id: itemId, _rev }]);
        return itemId;
    }

    public async deleteItems(idRevs: IIdRev[]): Promise<Id[]> {
        await this.itemRepository.bulkDelete(idRevs);
        // Touches the current project in order for date/time to update correctly
        this.debouncedUpdateLastChangedProjectEntity();
        return idRevs.map(({ _id }) => _id);
    }

    public async deletePartnerItem(itemId: Id): Promise<Id> {
        const { _rev } = this.currentProjectService.getEntity(itemId, 'partnerItem');
        const deletedItemId = await this.itemRepository.delete(itemId, _rev);
        // Touches the current project in order for date/time to update correctly
        this.debouncedUpdateLastChangedProjectEntity();

        return deletedItemId;
    }

    public async getItem(id: Id): Promise<IPersistence<IItemEntity>> {
        return this.itemRepository.get(id);
    }

    public async getAllProjectItems(projectId: Id): Promise<IPersistence<IItemEntity>[]> {
        const projectWithDescendants =
            await this.projectRepository.getProjectAndAllDescendantsWithItems(projectId);
        return projectWithDescendants.items;
    }

    public async updateItem(itemId: Id, props: Partial<IItem>) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');

        if (props.quantity !== undefined && props.quantity !== entity.quantity) {
            const changeAllowed = await this.quantityUpdateVerifier.inquireQuantityUpdate(
                entity,
                props.quantity,
            );
            if (!changeAllowed) {
                return;
            }
        }

        if (props.productId !== undefined && props.productId !== entity.productId) {
            await this.checkProductIdUpdateIsOk(entity);
        }

        let networkSettings =
            'networkSettings' in props ? props.networkSettings : entity.networkSettings;
        if (props.quantity !== undefined && props.quantity !== entity.quantity) {
            // Create new network settings for item when changing quantity
            networkSettings = getNewItemNetworkSettings(
                entity._id,
                entity.properties,
                props.quantity,
                entity.productId
                    ? this.piaSystemComponentService.get(entity.productId).first()
                    : null,
                this.currentProjectService.getCurrentProjectNetworkSettings(),
                this.currentProjectService.getAllEntitiesOfType('item'),
            );
        }
        //* Assign new IP address when user turns off dhcp in override
        const dhcpTurnedOff =
            props.networkSettings?.[0].dhcp === false && entity.networkSettings?.[0].dhcp === true;
        if (dhcpTurnedOff && networkSettings) {
            networkSettings[0].addresses =
                this.getNewIpAddresses(entity) ?? networkSettings[0].addresses;
        }

        const updatedItem = await this.itemRepository.update({
            _id: entity._id,
            _rev: entity._rev,
            creationDate: entity.creationDate,
            entityVersion: entity.entityVersion,
            locked: entity.locked,
            type: entity.type,
            path: entity.path,
            archived: entity.archived,
            updatedDate: entity.updatedDate,
            name: props.name && props.name.trim() !== '' ? props.name.trim() : entity.name,
            description: props.description?.trim() ?? entity.description,
            notes: props.notes?.trim() ?? entity.notes,
            productId: props.productId !== undefined ? props.productId : entity.productId,
            properties: props.properties
                ? this.getCleanedUpItemProperties(props.properties)
                : entity.properties,
            quantity: props.quantity ?? entity.quantity,
            color: props.color ?? entity.color,
            replaceWithBareboneId:
                props.replaceWithBareboneId !== undefined
                    ? props.replaceWithBareboneId
                    : entity.replaceWithBareboneId,
            networkSettings,
            //* We want to be able to set analyticRange to undefined specifically through props
            analyticRange: props.hasOwnProperty('analyticRange')
                ? props.analyticRange
                : entity.analyticRange,
        });
        return updatedItem;
    }

    /**
     * Update analytic range for a device. Set default values for AOA and APD if not set.
     * @param itemId The itemId for the device to set analytic range for, note that selected application is not set
     * You must set the applicationId by setAnalyticRangeApplicationId
     * @param applicationId: ACAPid for the application to add range values for
     * @param hasBothApdParams: true if device support both AI and Calibration
     * @param zone: Zone to display if other than default (for radar zones Oxxo)
     * @returns The updated item
     */
    public async addAnalyticRangeValues(
        itemId: Id,
        applicationId: number,
        hasBothApdParams: boolean,
        zone?: zoneType,
    ) {
        const item = await this.getItem(itemId);
        const updatedItem = await this.itemRepository.update({
            ...item,
            analyticRange: {
                applicationId: item.analyticRange?.applicationId,
                activeTypes: item.analyticRange?.activeTypes ?? ['vehicle', 'person'],
                zone,
                analyticMode: item.analyticRange?.analyticMode
                    ? item.analyticRange?.analyticMode
                    : applicationId === AXIS_PERIMETER_DEFENDER
                      ? hasBothApdParams
                          ? 'ai'
                          : 'calibration'
                      : undefined,
                lightCondition: item.analyticRange?.lightCondition
                    ? item.analyticRange?.lightCondition
                    : applicationId === AXIS_OBJECT_ANALYTICS
                      ? 'medium_light'
                      : undefined,
                dayCondition: item.analyticRange?.dayCondition
                    ? item.analyticRange?.dayCondition
                    : applicationId === AXIS_PERIMETER_DEFENDER
                      ? 'day'
                      : undefined,
                weatherCondition: item.analyticRange?.weatherCondition
                    ? item.analyticRange?.weatherCondition
                    : applicationId === AXIS_PERIMETER_DEFENDER
                      ? 'clear'
                      : undefined,
            },
        });
        return updatedItem;
    }

    /**
     * Update analytic range for a device
     * @param itemId The itemId for the device to set analytic range for
     * @param updatedAnalyticRanges The new values to set
     * @returns The updated item
     */
    public async updateAnalyticRange(
        itemId: Id,
        updatedAnalyticRanges: IDeviceAnalyticRange | undefined,
    ) {
        const item = await this.getItem(itemId);
        const updatedItem = await this.itemRepository.update({
            ...item,
            analyticRange: updatedAnalyticRanges,
        });
        return updatedItem;
    }

    /**
     * Update analytic mode for the device - if no analyticRange is set
     * the old item will be returned and no item updated
     * @param itemId The itemId for the device to update analytic mode for
     * @param analyticMode ai or calibration (or undefined)
     * @returns The updated item
     */
    public async updateAnalyticMode(itemId: Id, analyticMode: AnalyticMode | undefined) {
        const item = await this.getItem(itemId);
        if (item.analyticRange) {
            const updatedItem = await this.itemRepository.update({
                ...item,
                analyticRange: item.analyticRange
                    ? { ...item.analyticRange, analyticMode }
                    : {
                          applicationId: undefined,
                          activeTypes: ['vehicle', 'person'],
                          zone: undefined,
                      },
            });
            return updatedItem;
        }

        return item;
    }

    /**
     * Set the analytic range selected applicationId. Note that it should
     * be possible to set the selected applicationId although the analyticRange
     * is not set (undefined).
     * @param itemId The itemId for the device to set the selected analyticRange for
     * @param newApplicationId The id for the application to show analytic range for
     * @returns The updated item
     */
    public async setAnalyticRangeApplicationId(itemId: Id, newApplicationId: number | undefined) {
        const item = await this.getItem(itemId);
        const updatedItem = await this.itemRepository.update({
            ...item,
            analyticRange: item.analyticRange
                ? {
                      ...item.analyticRange,
                      applicationId: newApplicationId,
                  }
                : {
                      applicationId: newApplicationId,
                      activeTypes: ['vehicle', 'person'],
                      zone: undefined,
                  },
        });
        return updatedItem;
    }

    /**
     * Clear the parameters associated with APD (Axis Perimeter Defender)
     * typically called when the APD is removed from the device
     * @param itemId The id for the device
     * @returns The updated item if analyticRange was defined, existing item
     * if analyticRange was undefined
     */
    public async clearAPDAnalyticRange(itemId: Id) {
        const item = await this.getItem(itemId);
        if (item.analyticRange) {
            const updatedItem = await this.itemRepository.update({
                ...item,
                analyticRange: {
                    ...item.analyticRange,
                    analyticMode: undefined,
                    dayCondition: undefined,
                    weatherCondition: undefined,
                    applicationId:
                        item.analyticRange.applicationId === AXIS_PERIMETER_DEFENDER
                            ? undefined
                            : item.analyticRange.applicationId,
                },
            });
            return updatedItem;
        }
        return item;
    }

    /**
     * Clear the parameters associated with AOA (Axis Object Analytic)
     * typically called when the APD is removed from the device
     * @param itemId The id for the device
     * @returns The updated item if analyticRange was defined, existing item
     * if analyticRange was undefined
     */
    public async clearAOAAnalyticRange(itemId: Id) {
        const item = await this.getItem(itemId);
        if (item.analyticRange) {
            const updatedItem = await this.itemRepository.update({
                ...item,
                analyticRange: item.analyticRange
                    ? {
                          ...item.analyticRange,
                          lightCondition: undefined,
                          applicationId:
                              item.analyticRange.applicationId === AXIS_OBJECT_ANALYTICS
                                  ? undefined
                                  : item.analyticRange.applicationId,
                      }
                    : undefined,
            });
            return updatedItem;
        }
        return item;
    }

    public async updateProfile(itemId: Id, profileId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');

        if (deviceTypeCheckers.isCamera(entity) || deviceTypeCheckers.isDoorStation(entity)) {
            return this.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    camera: {
                        ...entity.properties.camera,
                        associatedProfile: profileId,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        } else if (deviceTypeCheckers.isSensorUnit(entity)) {
            return this.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    sensorUnit: {
                        ...entity.properties.sensorUnit,
                        associatedProfile: profileId,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        } else if (deviceTypeCheckers.isAnalogCamera(entity)) {
            return this.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    analogCamera: {
                        ...entity.properties.analogCamera,
                        associatedProfile: profileId,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        } else if (deviceTypeCheckers.isVirtualProduct(entity)) {
            return this.updateItem(itemId, {
                properties: {
                    ...entity.properties,
                    virtualProduct: {
                        ...entity.properties.virtualProduct,
                        associatedProfile: profileId,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });
        }
    }

    public async updateWearableProfile(itemId: Id, props: Partial<IBodyWornCameraProfile>) {
        const item = await this.getItem(itemId);
        if (deviceTypeCheckers.isBodyWornCamera(item)) {
            item.properties.bodyWornCamera.profile = {
                activeRecordingInPercent:
                    props.activeRecordingInPercent ??
                    item.properties.bodyWornCamera.profile.activeRecordingInPercent,
                retentionTimeInDays:
                    props.retentionTimeInDays ??
                    item.properties.bodyWornCamera.profile.retentionTimeInDays,
                scheduleId: props.scheduleId ?? item.properties.bodyWornCamera.profile.scheduleId,
                resolution: props.resolution ?? item.properties.bodyWornCamera.profile.resolution,
                sceneId: props.sceneId ?? item.properties.bodyWornCamera.profile.sceneId,
            };
            return this.updateItem(item._id, { properties: item.properties });
        }
    }
    public updateWearableProfileDebounced = debounce(this.updateWearableProfile, 200);

    public updatePixelDensity = async (
        itemId: Id,
        pixelDensity: number,
    ): Promise<IPersistence<IItemEntity> | undefined> => {
        const item = await this.getItem(itemId);
        if (item.properties.camera || item.properties.sensorUnit) {
            (item.properties.camera ?? item.properties.sensorUnit)!.filter.pixelDensity =
                pixelDensity;
            return this.updateItem(item._id, { properties: item.properties });
        }
        throw new Error("Can't set pixel density when item is not a camera");
    };

    public updatePanoramaMode = async (
        itemId: Id,
        panoramaMode: PanoramaModes,
    ): Promise<IPersistence<IItemEntity> | undefined> => {
        const item = await this.getItem(itemId);
        this.setPanoramaMode(item, panoramaMode);

        return this.updateItem(item._id, { properties: item.properties });
    };

    public updateSpeakerPlacement = async (
        itemId: Id,
        placement: SpeakerPlacement,
    ): Promise<IPersistence<IItemEntity> | undefined> => {
        const item = await this.getItem(itemId);
        this.setPlacement(item, placement);

        return this.updateItem(item._id, { properties: item.properties });
    };

    public setBareboneId = async (
        itemId: Id,
        replaceId: PiaId,
    ): Promise<IPersistence<IItemEntity> | undefined> => {
        const item = await this.getItem(itemId);
        this.setReplaceWithBarebone(item, replaceId);

        return this.updateItem(item._id, { replaceWithBareboneId: item.replaceWithBareboneId });
    };

    public clearBareboneId = async (itemId: Id): Promise<IPersistence<IItemEntity> | undefined> => {
        const item = await this.getItem(itemId);
        this.clearReplaceWithBarebone(item);

        return this.updateItem(item._id, { replaceWithBareboneId: item.replaceWithBareboneId });
    };

    public mapDescendants(
        descendants: Array<IPersistence<IItemEntity>>,
        parentId?: Id,
    ): IPersistence<IItemEntity>[] {
        if (parentId) {
            // An entity always has itself as last in the path, this filters out so we only
            // have entities that are direct children of the parentId
            descendants = descendants.filter((descendant) => getParentId(descendant) === parentId);
        }

        return descendants;
    }

    public async getEntityDescendants<T extends IEntity>(
        parentId: string,
        onlyDirectChildren?: boolean,
    ) {
        const { descendants, parent } =
            await this.itemRepository.getDescendantsWithRelations<T>(parentId);

        return {
            parent,
            descendants: this.mapDescendants(
                descendants,
                onlyDirectChildren ? parentId : undefined,
            ),
        };
    }

    public getVirtualRelatedItems(product?: IPiaDevice) {
        if (!product) return [];

        return product.relations.reduce((items, virtualItem) => {
            if (virtualItem.relationType !== PiaRelationTypes.VirtuallyIncludes) return items;
            const virtualProduct = this.piaItemService.get(virtualItem.id).first();
            if (!virtualProduct) return items;
            return [...items, virtualProduct];
        }, [] as IPiaDevice[]);
    }

    private getItemToAdd(
        item: IItem,
        parentPath: Id[],
        allProjectItems: IPersistence<IItemEntity>[],
        networkSettings: IProjectNetworkSettings | undefined,
        archived: boolean,
    ): IItemEntity {
        return {
            type: 'item',
            productId: item.productId,
            name: item.name,
            description: item.description,
            notes: item.notes,
            color: item.color,
            properties: this.getCleanedUpItemProperties(item.properties),
            quantity: item.quantity,
            path: [...parentPath],
            archived,
            replaceWithBareboneId: item.replaceWithBareboneId,
            networkSettings: getNewItemNetworkSettings(
                null,
                item.properties,
                item.quantity,
                item.productId ? this.piaSystemComponentService.get(item.productId).first() : null,
                networkSettings,
                allProjectItems,
            ),
            analyticRange: undefined,
        };
    }

    // debounced dispatch of touch project to avoid db conflict
    private debouncedUpdateLastChangedProjectEntity = debounce(
        this.updateLastChangedProjectEntity,
        500,
    );

    private async updateLastChangedProjectEntity() {
        return this.currentProjectService.touchCurrentProjectEntity();
    }

    private getNewIpAddresses(entity: IPersistence<IItemEntity>): string[] | undefined {
        return getNewItemNetworkSettings(
            entity._id,
            entity.properties,
            1,
            entity.productId ? this.piaSystemComponentService.get(entity.productId).first() : null,
            this.currentProjectService.getCurrentProjectNetworkSettings(),
            this.currentProjectService.getAllEntitiesOfType('item'),
        )?.[0].addresses;
    }

    private setPanoramaMode(item: IPersistence<IItemEntity>, panoramaMode: PanoramaModes) {
        if (item.properties.camera || item.properties.sensorUnit) {
            (item.properties.camera ?? item.properties.sensorUnit)!.filter.panoramaMode =
                panoramaMode;
            if (item.productId === null) {
                (item.properties.camera ?? item.properties.sensorUnit)!.filter.horizontalFov =
                    !panoramaMode ? 55 : 180;
            }
        }
    }

    private setPlacement(item: IPersistence<IItemEntity>, placement: SpeakerPlacement) {
        if (item.properties.speaker) {
            item.properties.speaker.filter.placement = placement;
        }
    }

    private setReplaceWithBarebone(item: IPersistence<IItemEntity>, replaceId: PiaId) {
        item.replaceWithBareboneId = replaceId;
    }

    private clearReplaceWithBarebone(item: IPersistence<IItemEntity>) {
        item.replaceWithBareboneId = null;
    }

    public createRecord<T extends IEntity>(entities: T[]): Record<Id, T | undefined> {
        const record: Record<Id, T | undefined> = {};

        entities.forEach((entity) => {
            record[entity._id] = entity;
        });

        return record;
    }

    /**
     * Makes sure unwanted data does not end up in the database, e.g if a spread operator
     * was used to pass in data.
     * */
    private getCleanedUpItemProperties(
        itemProperties: IItemPropertiesEntity,
    ): IItemPropertiesEntity {
        const properties: IItemPropertiesEntity = {};

        if (itemProperties.analogCamera) {
            properties.analogCamera = {
                associatedProfile: itemProperties.analogCamera.associatedProfile,
                profileOverride: itemProperties.analogCamera.profileOverride,
            };
        }

        if (itemProperties.door) {
            properties.door = {
                nbrOfLocks: itemProperties.door.nbrOfLocks,
            };
        }

        if (itemProperties.camera) {
            properties.camera = {
                filter: {
                    lightConditions: itemProperties.camera.filter.lightConditions,
                    corridorFormat: itemProperties.camera.filter.corridorFormat,
                    distanceToTarget: itemProperties.camera.filter.distanceToTarget,
                    cameraTypes: itemProperties.camera.filter.cameraTypes,
                    sensorUnitTypes: itemProperties.camera.filter.sensorUnitTypes,
                    horizontalFov: itemProperties.camera.filter.horizontalFov,
                    installationHeight: itemProperties.camera.filter.installationHeight,
                    pixelDensity: itemProperties.camera.filter.pixelDensity,
                    targetHeight: itemProperties.camera.filter.targetHeight,
                    outdoor: itemProperties.camera.filter.outdoor,
                    panoramaMode: itemProperties.camera.filter.panoramaMode,
                    applications: itemProperties.camera.filter.applications,
                    isSceneFilterActive: itemProperties.camera.filter.isSceneFilterActive,
                    isFilterChanged: itemProperties.camera.filter.isFilterChanged,
                },
                associatedProfile: itemProperties.camera.associatedProfile,
                profileOverride: itemProperties.camera.profileOverride,
                customCameraProperties: itemProperties.camera.customCameraProperties,
            };
        }

        if (itemProperties.encoder) {
            properties.encoder = {
                filter: {
                    blade: itemProperties.encoder.filter.blade,
                    channels: itemProperties.encoder.filter.channels,
                    twoWayAudio: itemProperties.encoder.filter.twoWayAudio,
                    outdoor: itemProperties.encoder.filter.outdoor,
                },
            };
        }

        if (itemProperties.virtualProduct) {
            properties.virtualProduct = {
                associatedProfile: itemProperties.virtualProduct.associatedProfile,
                profileOverride: itemProperties.virtualProduct.profileOverride,
            };
        }

        if (itemProperties.mainUnit) {
            properties.mainUnit = {
                filter: {
                    channels: itemProperties.mainUnit.filter.channels,
                    twoWayAudio: itemProperties.mainUnit.filter.twoWayAudio,
                    WDRTechnology: itemProperties.mainUnit.filter.WDRTechnology,
                    ruggedizedEN50155: itemProperties.mainUnit.filter.ruggedizedEN50155,
                    alarmInputsOutputs: itemProperties.mainUnit.filter.alarmInputsOutputs,
                    outdoor: itemProperties.mainUnit.filter.outdoor,
                },
            };
        }

        if (itemProperties.sensorUnit) {
            properties.sensorUnit = {
                filter: {
                    lightConditions: itemProperties.sensorUnit.filter.lightConditions,
                    corridorFormat: itemProperties.sensorUnit.filter.corridorFormat,
                    distanceToTarget: itemProperties.sensorUnit.filter.distanceToTarget,
                    cameraTypes: itemProperties.sensorUnit.filter.cameraTypes,
                    sensorUnitTypes: itemProperties.sensorUnit.filter.sensorUnitTypes,
                    horizontalFov: itemProperties.sensorUnit.filter.horizontalFov,
                    installationHeight: itemProperties.sensorUnit.filter.installationHeight,
                    pixelDensity: itemProperties.sensorUnit.filter.pixelDensity,
                    targetHeight: itemProperties.sensorUnit.filter.targetHeight,
                    outdoor: itemProperties.sensorUnit.filter.outdoor,
                    panoramaMode: itemProperties.sensorUnit.filter.panoramaMode,
                    applications: itemProperties.sensorUnit.filter.applications,
                    isSceneFilterActive: itemProperties.sensorUnit.filter.isSceneFilterActive,
                    isFilterChanged: itemProperties.sensorUnit.filter.isFilterChanged,
                },
                associatedProfile: itemProperties.sensorUnit.associatedProfile,
                profileOverride: itemProperties.sensorUnit.profileOverride,
            };
        }

        if (itemProperties.speaker) {
            properties.speaker = {
                filter: {
                    placement: itemProperties.speaker.filter.placement,
                    outdoor: itemProperties.speaker.filter.outdoor,
                    basicSolution: itemProperties.speaker.filter.basicSolution,
                    installationHeight: itemProperties.speaker.filter.installationHeight,
                    listeningArea: itemProperties.speaker.filter.listeningArea,
                    wallLength: itemProperties.speaker.filter.wallLength,
                    isFilterChanged: itemProperties.speaker.filter.isFilterChanged,
                },
            };
        }

        if (itemProperties.doorController) {
            properties.doorController = {
                powerSupply: itemProperties.doorController.powerSupply,
                filter: itemProperties.doorController.filter,
            };
        }

        if (itemProperties.systemComponent) {
            properties.systemComponent = {};
        }

        if (itemProperties.accessory) {
            properties.accessory = {
                category: itemProperties.accessory.category,
            };
        }

        if (itemProperties.application) {
            properties.application = {
                officialFullName: itemProperties.application.officialFullName,
                productId: itemProperties.application.productId,
                discontinued: itemProperties.application.discontinued,
                isIncluded: itemProperties.application.isIncluded,
                versions: itemProperties.application.versions,
                acapId: itemProperties.application.acapId,
                isELicense: itemProperties.application.isELicense,
                vendorName: itemProperties.application.vendorName,
            };
        }

        if (itemProperties.alerter) {
            properties.alerter = { filter: itemProperties.alerter.filter };
        }

        if (itemProperties.environment) {
            properties.environment = {};
        }

        if (itemProperties.pac) {
            properties.pac = { filter: itemProperties.pac.filter };
        }

        if (itemProperties.radarDetector) {
            properties.radarDetector = { filter: itemProperties.radarDetector.filter };
        }

        if (itemProperties.decoder) {
            properties.decoder = { filter: itemProperties.decoder.filter };
        }

        if (itemProperties.systemAccessory) {
            properties.systemAccessory = {};
        }

        if (itemProperties.lens) {
            properties.lens = {
                sensorIndex: itemProperties.lens.sensorIndex,
            };
        }

        if (itemProperties.peopleCounter) {
            properties.peopleCounter = { filter: itemProperties.peopleCounter.filter };
        }

        if (itemProperties.bodyWornCamera) {
            properties.bodyWornCamera = {
                profile: {
                    sceneId: itemProperties.bodyWornCamera.profile.sceneId,
                    resolution: itemProperties.bodyWornCamera.profile.resolution,
                    retentionTimeInDays: itemProperties.bodyWornCamera.profile.retentionTimeInDays,
                    scheduleId: itemProperties.bodyWornCamera.profile.scheduleId,
                    activeRecordingInPercent:
                        itemProperties.bodyWornCamera.profile.activeRecordingInPercent,
                },
                filter: itemProperties.bodyWornCamera.filter,
            };
        }

        if (itemProperties.systemController) {
            properties.systemController = {};
        }

        if (itemProperties.dockingStation) {
            properties.dockingStation = { filter: itemProperties.dockingStation.filter };
        }

        if (itemProperties.cameraExtension) {
            properties.cameraExtension = { filter: itemProperties.cameraExtension.filter };
        }

        if (itemProperties.partnerSystemComponent) {
            properties.partnerSystemComponent = {
                category: itemProperties.partnerSystemComponent.category,
                vendorName: itemProperties.partnerSystemComponent.vendorName,
                imageUrl: itemProperties.partnerSystemComponent.imageUrl,
                dataSheetUrl: itemProperties.partnerSystemComponent.dataSheetUrl,
                maxCameraCount: itemProperties.partnerSystemComponent.maxCameraCount,
                maxRecordingBandwidthBits:
                    itemProperties.partnerSystemComponent.maxRecordingBandwidthBits,
                maxRecordingStorageMegaBytes:
                    itemProperties.partnerSystemComponent.maxRecordingStorageMegaBytes,
            };
        }

        if (itemProperties.connectivityDevice) {
            properties.connectivityDevice = { filter: itemProperties.connectivityDevice.filter };
        }

        if (itemProperties.pagingConsole) {
            properties.pagingConsole = { filter: itemProperties.pagingConsole.filter };
        }

        return properties;
    }

    /**
     * Checks that all accessories, applications and sensor units, analog cameras are removed before changing product id
     */
    private async checkProductIdUpdateIsOk(entity: IPersistence<IItemEntity>) {
        if (entity.properties.accessory) {
            // Always allow accessories to change product (e.g. changing primary mount)
            return;
        }

        const { descendants } = await this.itemRelationRepository.getDescendants(entity._id);
        const parentDescendants = descendants.filter(
            (descendant) => descendant.parentId === entity._id,
        );

        const notAllowedRelations: ItemRelationType[] = ['partnerAcap', 'sensorUnit'];
        const hasNotAllowedRelations = parentDescendants.some((relation) =>
            notAllowedRelations.includes(relation.relationType),
        );

        if (hasNotAllowedRelations) {
            const relationsToAskBeforeDelete = parentDescendants.filter((relation) =>
                notAllowedRelations.includes(relation.relationType),
            );
            throw new CoreItemProductIdUpdateError(relationsToAskBeforeDelete);
        }
    }
}
