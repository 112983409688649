import { reducerBuilder } from 'app/store';
import { CommonActions } from './actions';
import type {
    Id,
    ICurrentProjectRepository,
    IItemEntity,
    IPersistence,
    IProjectEntity,
    IScheduleEntity,
    ICustomItemEntity,
    ITimeSerieEntity,
    IProjectQuotationEntity,
    IFloorPlanEntity,
    IProfileEntity,
    IItemRelationEntity,
    IInstallationPointEntity,
    ICommand,
    IFreeTextPointEntity,
    IPartnerItemEntity,
    IBlockerEntity,
    IMapLocationEntity,
} from 'app/core/persistence';
import {
    updateEntityInRecord,
    deleteEntityInRecord,
    PersistenceActions,
} from 'app/core/persistence';

import { updateCommandQueueIds, addCommand } from './undoredo';

interface IHistory {
    undoQueue: ICommand[];
    redoQueue: ICommand[];
    commandRunning: boolean;
}

export interface ICurrentProjectState extends ICurrentProjectRepository {
    projectError: Error | null;
    projectLoaded: boolean;
    projectBroken: boolean;
    history: IHistory;
}

const initialCurrentProjectState: ICurrentProjectState = {
    project: null,
    items: {},
    itemRelations: {},
    profiles: {},
    schedules: {},
    customItems: {},
    timeSeries: {},
    quotations: null,
    floorPlans: {},
    installationPoints: {},
    mapLocations: {},
    freeTextPoints: {},
    projectLoaded: false,
    projectError: null,
    projectBroken: false,
    history: {
        undoQueue: [],
        redoQueue: [],
        commandRunning: false,
    },
    partnerItems: {},
    blockers: {},
};

export const currentProjectReducer = reducerBuilder<ICurrentProjectState>()
    .setInitialState(initialCurrentProjectState)
    .onAsyncAction(CommonActions.GetCurrentProject, (handler) => {
        handler

            .onFulfilled<ICurrentProjectState>((state, action) => ({
                ...state,
                project: action.payload.project,
                customItems: action.payload.customItems,
                items: action.payload.items,
                itemRelations: action.payload.itemRelations,
                profiles: action.payload.profiles,
                schedules: action.payload.schedules,
                timeSeries: action.payload.timeSeries,
                quotations: action.payload.quotations,
                floorPlans: action.payload.floorPlans,
                mapLocations: action.payload.mapLocations,
                installationPoints: action.payload.installationPoints,
                freeTextPoints: action.payload.freeTextPoints,
                projectLoaded: action.payload.projectLoaded ?? true,
                projectBroken: action.payload.projectBroken,
                projectError: action.payload.projectError ?? null,
                partnerItems: action.payload.partnerItems,
                blockers: action.payload.blockers,
            }))
            .onPending(() => ({
                ...initialCurrentProjectState,
                projectLoaded: false,
                projectError: null,
            }))
            .onRejected<Error>((_state, action) => ({
                ...initialCurrentProjectState,
                projectLoaded: true,
                projectError: action.payload,
            }));
    })
    .onAction<ICurrentProjectState>(CommonActions.ConfirmBrokenProject, (state, _action) => {
        return {
            ...state,
            projectBroken: false,
        };
    })
    .onAction<ICurrentProjectState>(CommonActions.UnloadCurrentProject, () => ({
        ...initialCurrentProjectState,
    }))
    .onAction<IPersistence<IItemEntity>>(PersistenceActions.UpdateItem, (state, action) => {
        return {
            ...state,
            items: updateEntityInRecord(state.items, action),
        };
    })
    .onAction<IPersistence<IProjectEntity>>(PersistenceActions.UpdateProject, (state, action) => {
        return {
            ...state,
            project: action.payload,
        };
    })
    .onAction<IPersistence<IProfileEntity>>(PersistenceActions.UpdateProfile, (state, action) => {
        return {
            ...state,
            profiles: updateEntityInRecord(state.profiles, action),
        };
    })
    .onAction<IPersistence<IItemRelationEntity>>(
        PersistenceActions.UpdateItemRelation,
        (state, action) => {
            return {
                ...state,
                itemRelations: updateEntityInRecord(state.itemRelations, action),
            };
        },
    )
    .onAction<IPersistence<IScheduleEntity>>(PersistenceActions.UpdateSchedule, (state, action) => {
        return {
            ...state,
            schedules: updateEntityInRecord(state.schedules, action),
        };
    })
    .onAction<IPersistence<ICustomItemEntity>>(
        PersistenceActions.UpdateCustomItem,
        (state, action) => {
            return {
                ...state,
                customItems: updateEntityInRecord(state.customItems, action),
            };
        },
    )
    .onAction<IPersistence<ITimeSerieEntity>>(
        PersistenceActions.UpdateTimeSerie,
        (state, action) => {
            return {
                ...state,
                timeSeries: updateEntityInRecord(state.timeSeries, action),
            };
        },
    )
    .onAction<IPersistence<IProjectQuotationEntity>>(
        PersistenceActions.UpdateQuotation,
        (state, action) => {
            return {
                ...state,
                quotations: action.payload,
            };
        },
    )
    .onAction<IPersistence<IFloorPlanEntity>>(
        PersistenceActions.UpdateFloorPlan,
        (state, action) => {
            return {
                ...state,
                floorPlans: updateEntityInRecord(state.floorPlans, action),
            };
        },
    )
    .onAction<IPersistence<IMapLocationEntity>>(
        PersistenceActions.UpdateMapLocation,
        (state, action) => {
            return {
                ...state,
                mapLocations: updateEntityInRecord(state.mapLocations, action),
            };
        },
    )
    .onAction<IPersistence<IInstallationPointEntity>>(
        PersistenceActions.UpdateInstallationPoint,
        (state, action) => {
            return {
                ...state,
                installationPoints: updateEntityInRecord(state.installationPoints, action),
            };
        },
    )
    .onAction<IPersistence<IFreeTextPointEntity>>(
        PersistenceActions.UpdateFreeTextPoint,
        (state, action) => {
            return {
                ...state,
                freeTextPoints: updateEntityInRecord(state.freeTextPoints, action),
            };
        },
    )
    .onAction<IPersistence<IPartnerItemEntity>>(
        PersistenceActions.UpdatePartnerItem,
        (state, action) => {
            return {
                ...state,
                partnerItems: updateEntityInRecord(state.partnerItems, action),
            };
        },
    )
    .onAction<IPersistence<IBlockerEntity>>(PersistenceActions.UpdateBlocker, (state, action) => {
        return {
            ...state,
            blockers: updateEntityInRecord(state.blockers, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteItem, (state, action) => {
        return {
            ...state,
            items: deleteEntityInRecord(state.items, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteProfile, (state, action) => {
        return {
            ...state,
            profiles: deleteEntityInRecord(state.profiles, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteItemRelation, (state, action) => {
        return {
            ...state,
            itemRelations: deleteEntityInRecord(state.itemRelations, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteSchedule, (state, action) => {
        return {
            ...state,
            schedules: deleteEntityInRecord(state.schedules, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteCustomItem, (state, action) => {
        return {
            ...state,
            customItems: deleteEntityInRecord(state.customItems, action),
        };
    })
    .onAction<string>(PersistenceActions.DeletePartnerItem, (state, action) => {
        return {
            ...state,
            partnerItems: deleteEntityInRecord(state.partnerItems, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteBlocker, (state, action) => {
        return {
            ...state,
            blockers: deleteEntityInRecord(state.blockers, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteTimeSerie, (state, action) => {
        return {
            ...state,
            timeSeries: deleteEntityInRecord(state.timeSeries, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteFloorPlan, (state, action) => {
        return {
            ...state,
            floorPlans: deleteEntityInRecord(state.floorPlans, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteMapLocation, (state, action) => {
        return {
            ...state,
            mapLocations: deleteEntityInRecord(state.mapLocations, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteInstallationPoint, (state, action) => {
        return {
            ...state,
            installationPoints: deleteEntityInRecord(state.installationPoints, action),
        };
    })
    .onAction<string>(PersistenceActions.DeleteFreeTextPoint, (state, action) => {
        return {
            ...state,
            freeTextPoints: deleteEntityInRecord(state.freeTextPoints, action),
        };
    })
    .onAction<ICommand>(PersistenceActions.PushUndoAction, (state, action) => {
        return {
            ...state,
            history: {
                ...state.history,
                undoQueue: addCommand(state.history.undoQueue, action.payload),
            },
        };
    })
    .onAction<ICommand>(PersistenceActions.PushRedoAction, (state, action) => {
        return {
            ...state,
            history: {
                ...state.history,
                redoQueue: addCommand(state.history.redoQueue, action.payload),
            },
        };
    })
    .onAction(PersistenceActions.PopUndoAction, (state) => {
        return {
            ...state,
            history: {
                ...state.history,
                undoQueue: state.history.undoQueue.slice(0, -1),
                commandRunning: false,
            },
        };
    })
    .onAction(PersistenceActions.PopRedoAction, (state) => {
        return {
            ...state,
            history: {
                ...state.history,
                redoQueue: state.history.redoQueue.slice(0, -1),
                commandRunning: false,
            },
        };
    })
    .onAction(PersistenceActions.StartHistoryCommand, (state) => {
        return {
            ...state,
            history: { ...state.history, commandRunning: true },
        };
    })
    .onAction(PersistenceActions.ClearRedoQueue, (state) => {
        return {
            ...state,
            history: { ...state.history, redoQueue: [] },
        };
    })
    .onAction<Map<Id, Id>>(PersistenceActions.UpdateHistoryIds, (state, action) => {
        return {
            ...state,
            history: {
                ...state.history,
                undoQueue: updateCommandQueueIds(state.history.undoQueue, action.payload),
                redoQueue: updateCommandQueueIds(state.history.redoQueue, action.payload),
            },
        };
    })
    .create();
