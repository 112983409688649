import { injectable } from 'inversify';
import type { IPiaLocation } from 'app/core/pia';
import { PiaLocationService } from 'app/core/pia';

@injectable()
export class LocationService {
    constructor(private piaLocationService: PiaLocationService<IPiaLocation>) {}

    public getLocations(): IPiaLocation[] {
        return this.piaLocationService.getAll().toList();
    }

    public getLocation(id: number): IPiaLocation | null {
        return this.piaLocationService.get(id).first();
    }
}
