import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps } from '../../services';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { css, cx } from '@emotion/css';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import type { Property } from 'csstype';

const baseStyle = css`
    color: ${ColorsEnum.grey9};
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;

    @media print {
        color: ${ColorsEnum.black};
    }
`;

const figmaStyles = {
    headline: css`
        font-size: 24px;
    `,
    title: css`
        font-size: 20px;
    `,
    heading: css`
        font-size: 14px;
    `,
    subheader: css`
        font-size: 16px;
    `,
    body: css`
        font-size: 12px;
        font-weight: 400;
    `,
    semibold: css`
        font-size: 12px;
    `,
    caption: css`
        font-size: 12px;
        font-weight: 400;
        color: ${ColorsEnum.grey6};
    `,
};

export interface IHeadingProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * The Figma style to use for this heading.
     * Defaults to `heading`.
     */
    style?: keyof typeof figmaStyles;
    /**
     * Override the heading color.
     * Defaults to the style defined color
     */
    color?: Colors;
    /**
     * The width of the header
     */
    width?: string;
    /**
     * Text alignment
     */
    align?: Property.TextAlign;
    /**
     * Allows word breaks in cases of very long words if set to true
     */
    breakWord?: boolean;
    /**
     * Do not wrap text, instead show ellipsis when there is not enough room for the text with
     * specified number of rows.
     */
    lineClamp?: number;
    /**
     * Wrap text, but show ellipsis when there is not enough room for the text.
     */
    overflowEllipsis?: boolean;
    /**
     * Override the color prop with color of your choice
     */
    colorOverride?: Property.Color;
}

/**
 * Use this component when you want a heading.
 * It will generate an `<h1>` element.
 *
 * By setting the `style` prop you can use any of our
 * heading styles as defined in Figma.
 *
 * You can override the color of the heading with the `color` prop.
 */
export const Heading: React.FunctionComponent<IHeadingProps> = ({
    children,
    color,
    width,
    align,
    breakWord,
    overflowEllipsis,
    lineClamp,
    style = 'heading',
    colorOverride,
    ...extendedProps
}) => {
    const colorStyle =
        (color || colorOverride) &&
        css`
            color: ${colorOverride || (color && ColorsEnum[color])};
        `;
    const widthStyle =
        width &&
        css`
            width: ${width};
        `;
    const alignStyle =
        align &&
        css`
            text-align: ${align};
        `;
    const breakWordStyle =
        breakWord &&
        css`
            overflow-wrap: anywhere;
        `;
    const ellipsisStyle =
        overflowEllipsis &&
        css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        `;
    const lineClampStyle =
        lineClamp !== undefined &&
        css`
            @supports (-webkit-line-clamp: ${lineClamp}) {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: ${lineClamp};
                -webkit-box-orient: vertical;
            }
        `;
    const figmaStyle = figmaStyles[style];
    const className = cx(
        baseStyle,
        figmaStyle,
        colorStyle,
        widthStyle,
        alignStyle,
        breakWordStyle,
        ellipsisStyle,
        lineClampStyle,
    );
    const attributes = extendableProps({ ...extendedProps }, { className }, true);

    return (
        <h1 data-test-id={toTestIdFormat(extendedProps.testId)} {...attributes}>
            {children}
        </h1>
    );
};

Heading.displayName = 'Heading';
