import { getBodyWornCameraDefaultProfile } from 'app/core/persistence/utils/defaultWearableProfileEntity';
import { injectable } from 'inversify';
import type { IBaseEntity } from '../../entities';
import type { IPersistenceRepository } from '../../repositories';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds `profile` property to bodyWornCamera entities
 * property profile includes `sceneId`, `resolution`, `retentionTime`,
 *  `scheduleId` and `activeRecordingInPercent`
 */
@injectable()
export class Migration27to28 extends MigrationProviderBase {
    public from: number = 27;
    public to: number = 28;

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<IBaseEntity> => {
            if (
                entity?.properties?.bodyWornCamera &&
                entity?.properties?.bodyWornCamera?.profile === undefined
            ) {
                entity.properties.bodyWornCamera.profile = getBodyWornCameraDefaultProfile();
                const allSchedules = (await repository.getAll('schedule')) as any;

                // Since we for now only have one systemDefined schedule which is 'Always' the
                // lookup will be to take the first systemDefined schedule we find
                const alwaysSchedule = allSchedules.find(
                    (schedule: any) =>
                        schedule.systemDefined &&
                        this.getScheduleProjectId(schedule) === this.getEntityProjectId(entity),
                );

                entity.properties.bodyWornCamera.profile.scheduleId = alwaysSchedule?._id || '';
                return entity;
            }

            return entity;
        };
    }

    private getScheduleProjectId(schedule: any) {
        return schedule.path[0];
    }

    private getEntityProjectId(entity: any) {
        return entity && entity.path[0];
    }
}
