/* eslint-disable react/jsx-no-undef */
import * as React from 'react';
import {
    Checkbox,
    Positioned,
    Spacer,
    Card,
    Box,
    Text,
    Border,
    LoadingIndicator,
    SearchField,
} from 'app/components';
import { useSelector } from 'react-redux';
import {
    getAvailableRecordingDevices,
    getFilteredGenetecRecordingServers,
    getIsFetchingGenetecProducts,
    getRecordingFilter,
    getSearchFilter,
} from '../selectors/getRecordingDevices';
import { t } from 'app/translate';
import { RecordingSelectorActionService, RecordingSelectorService } from '../services';
import { useService } from 'app/ioc';
import { getSelectedVmsLicensesByType, getVmsByLicenseTypeRecord } from '../selectors';
import { GenetecRecommendModal } from './genetecRecommendations';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { ProjectRequirements } from './ProjectRequirements.component';
import { RecordingItem } from './RecordingItem.component';

import { useMount } from 'app/hooks';
import { getCurrentProjectCountryCode, getSelectedRecordingVendor } from 'app/modules/common';
import { debounce } from 'lodash-es';

const tableStyle = css`
    width: 100%;
    border-collapse: collapse;

    td,
    th {
        padding: 6px;
    }

    tbody td,
    tbody th {
        height: 48px;
        min-width: 48px;
    }

    tbody td,
    tbody th {
        border-bottom: 1px solid ${ColorsEnum.grey2};
    }

    tbody tr:last-of-type td,
    tbody tr:last-of-type th {
        border-bottom: none;
    }

    colgroup col {
        border-inline-start: 1px solid ${ColorsEnum.grey2};
    }

    colgroup col:first-of-type {
        border-inline-start-style: dashed;
        border-inline-start-color: ${ColorsEnum.grey4};
    }

    colgroup:first-of-type col {
        border-inline-start: none;
    }
`;

export const CustomizeSolution: React.FunctionComponent = () => {
    const recordingDevices = useSelector(getAvailableRecordingDevices);
    const selectedCategory = useSelector(getRecordingFilter);
    const licensesByType = useSelector(getVmsByLicenseTypeRecord);
    const selectedLicenses = useSelector(getSelectedVmsLicensesByType);
    const installationCountryCode = useSelector(getCurrentProjectCountryCode) ?? 'US';
    const genetecRecorders = useSelector(getFilteredGenetecRecordingServers);
    const isFetchingGenetecProducts = useSelector(getIsFetchingGenetecProducts);
    const selectedRecordingVendor = useSelector(getSelectedRecordingVendor);

    const recordingSelectorService = useService(RecordingSelectorService);
    const recordingSelectorActionService = useService(RecordingSelectorActionService);
    const [genetecModal, setGenetecModal] = React.useState(false);

    const isGenetecEnabled = selectedRecordingVendor === 'Genetec';

    const showGenetecServers = isGenetecEnabled && selectedCategory === 'servers';

    const fetchGenetecServers =
        isGenetecEnabled && (selectedCategory === 'servers' || selectedCategory === 'all');

    const showLicenses = selectedCategory === 'licenses' && selectedRecordingVendor === 'Axis';

    useMount(() => {
        if (showGenetecServers) {
            recordingSelectorActionService.getGenetecProducts(installationCountryCode);
        }
    });

    React.useEffect(() => {
        if (fetchGenetecServers && genetecRecorders.length === 0) {
            recordingSelectorActionService.getGenetecProducts(installationCountryCode);
        }
    }, [
        genetecRecorders.length,
        installationCountryCode,
        recordingSelectorActionService,
        fetchGenetecServers,
        selectedRecordingVendor,
    ]);

    const searchFilter = useSelector(getSearchFilter);

    const [preferELicense, setPreferELicense] = React.useState(
        selectedLicenses.eLicenses.length >= selectedLicenses.paperLicenses.length,
    );

    const handlePreferELicense = () => {
        recordingSelectorService.updateELicensePreference(
            !preferELicense ? 'eLicense' : 'paperLicense',
            selectedLicenses,
            licensesByType,
        );
        setPreferELicense((isPreferred) => !isPreferred);
    };

    const showContent = showGenetecServers
        ? genetecRecorders.length > 0
        : recordingDevices.length > 0;

    const debouncedOnChange = debounce(
        (value) => recordingSelectorActionService.setSearchFilter(value),
        600,
    );

    const alignItems =
        recordingDevices.length > 0 || genetecRecorders.length > 0 ? 'center' : 'start';
    return (
        <>
            <Box direction="column" spacing="panel">
                {isFetchingGenetecProducts && showGenetecServers && <LoadingIndicator />}
                {(selectedCategory !== 'servers' || !isFetchingGenetecProducts) && (
                    <Box direction="column" spacing="panel" testId="milestone_modal">
                        <Box direction="column" alignItems={alignItems} flex="none">
                            <Box
                                direction="column"
                                alignItems="end"
                                flex="none"
                                paddingBottom="half"
                                width="100%"
                            >
                                <SearchField
                                    testId="search_customize_product"
                                    value={searchFilter}
                                    onValueChange={debouncedOnChange}
                                />
                            </Box>
                            {showContent ? (
                                <Card paddingX="base" paddingY="base">
                                    <table className={tableStyle}>
                                        <colgroup>
                                            <col />
                                        </colgroup>
                                        <colgroup>
                                            <col />
                                            <col />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <colgroup>
                                            <col />
                                            <col />
                                        </colgroup>
                                        <colgroup>
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="colgroup">
                                                    {showLicenses && (
                                                        <Box
                                                            justifyContent="end"
                                                            paddingRight="halfQuart"
                                                        >
                                                            <Checkbox
                                                                disabled={false}
                                                                slider
                                                                justifyLeft
                                                                selected={preferELicense}
                                                                labelLeft={true}
                                                                onChange={handlePreferELicense}
                                                            >
                                                                {
                                                                    t.applicationSelectorPreferELicense
                                                                }
                                                            </Checkbox>
                                                        </Box>
                                                    )}
                                                </th>
                                                <th scope="colgroup" colSpan={4}>
                                                    <Text style="semibold" color="grey5">
                                                        {t.recording}
                                                    </Text>
                                                </th>
                                                <th scope="colgroup" colSpan={2}>
                                                    <Text style="semibold" color="grey5">
                                                        {t.network}
                                                    </Text>
                                                </th>
                                                <th scope="colgroup">
                                                    <Text style="semibold" color="grey5">
                                                        {t.output}
                                                    </Text>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">
                                                    <Text style="small" color="grey6">
                                                        {t.channels}
                                                    </Text>
                                                </th>
                                                <th scope="col">
                                                    <Text style="small" color="grey6">
                                                        {t.licenses}
                                                    </Text>
                                                </th>
                                                <th scope="col">
                                                    <Text style="small" color="grey6">
                                                        {t.storage}
                                                    </Text>
                                                </th>
                                                <th scope="col">
                                                    <Text style="small" color="grey6">
                                                        {t.bandwidth}
                                                    </Text>
                                                </th>
                                                <th scope="col">
                                                    <Text style="small" color="grey6">
                                                        {t.poePower}
                                                    </Text>
                                                </th>
                                                <th scope="col">
                                                    <Text style="small" color="grey6">
                                                        {t.ports}
                                                    </Text>
                                                </th>
                                                <th scope="col">
                                                    <Text style="small" color="grey6">
                                                        {t.videoPorts}
                                                    </Text>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showGenetecServers
                                                ? genetecRecorders &&
                                                  genetecRecorders.map((device, index) => (
                                                      <RecordingItem
                                                          item={device}
                                                          key={index}
                                                          eLicensePreference={preferELicense}
                                                      />
                                                  ))
                                                : recordingDevices.map((device, index) => (
                                                      <RecordingItem
                                                          item={device}
                                                          key={index}
                                                          eLicensePreference={preferELicense}
                                                      />
                                                  ))}
                                        </tbody>
                                    </table>
                                </Card>
                            ) : (
                                <Text>{t.noProductsAvailable}</Text>
                            )}
                        </Box>
                        <Spacer />
                        <Positioned sendForward position="sticky" bottom="30px">
                            <Box justifyContent="center" flex="dontShrink">
                                <Border width={0} shadow={`0px 0px 30px 10px ${ColorsEnum.grey1}`}>
                                    <Box>
                                        <ProjectRequirements hideZipstreamSettings />
                                    </Box>
                                </Border>
                            </Box>
                        </Positioned>
                    </Box>
                )}
            </Box>
            {genetecModal && (
                <GenetecRecommendModal
                    title={t.genetecGROUP.whatWouldGenetecRecommend}
                    onClose={() => setGenetecModal(false)}
                />
            )}
        </>
    );
};

CustomizeSolution.displayName = 'CustomizeSolution';
