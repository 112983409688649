import * as React from 'react';
import { format } from 'axis-webtools-util';

export interface ICurrencyProps {
    /**
     * The number to format.
     * Prints "-" for null values
     */
    value: number | null;
    /**
     * What locale this currency should be displayed in
     */
    locale: string;
    /**
     * What currency to use
     */
    currency: string;
    /**
     * Show the appropriate currency symbol
     */
    showCurrency?: boolean;
    /**
     * Round the currency to remove fractions
     */
    hideFractions?: boolean;
}

/**
 * Format a number as a currency based on a locale
 */
export class Currency extends React.Component<ICurrencyProps> {
    public render() {
        const { value, locale, currency, showCurrency, hideFractions } = this.props;
        try {
            return value === null
                ? '-'
                : format.money(value, locale, currency, showCurrency, hideFractions);
        } catch {
            return '-';
        }
    }
}
