import { Vector3, Plane } from 'three';
import * as leaflet from 'leaflet';
import { getOffset, trigonometry, type Point } from 'axis-webtools-util';
import { calculateCornerRays } from './calculateBoundingRays';
import {
    calculateHorizontalFov,
    calculateVerticalFov,
    constants,
    estimateVerticalFOV,
} from 'app/modules/common';
import { projectOnGround } from './cameraCalc';
import type { IInstallationPointModel, ILatLng } from 'app/core/persistence';
import type { ISensorCoverageAreaInfo } from '../models';

/** Return the point where the FOV handle should be shown */
export function calculateCameraFovHandlePoint(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
): Point | undefined {
    if (horizontalFov >= 180) {
        return undefined;
    }
    const boundingRays = calculateCornerRays(
        trigonometry.toRadians(horizontalFov),
        verticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
    );

    const targetPlane = new Plane(new Vector3(-1, 0, 0), targetDistance);
    const intersection = boundingRays.topRight.intersectPlane(targetPlane, new Vector3());

    return projectOnGround(intersection ?? new Vector3());
}
export function getHorizontalFov(
    installationPoint: IInstallationPointModel,
    sensorCoverageAreaInfo: ISensorCoverageAreaInfo,
    fovHandlePos: ILatLng,
    targetPos: ILatLng,
): number {
    const fromOrigin = getOffset(installationPoint.location);
    const fovHandleCoords = fromOrigin(fovHandlePos);
    const targetHandleCoords = fromOrigin(targetPos);
    const cameraHeight = installationPoint.height;
    // targetHeight should not be undefined on an ISensorCoverageAreaInfo
    const targetHeight = sensorCoverageAreaInfo.targetHeight!;
    const targetDistance = leaflet
        .latLng(installationPoint.location.lat, installationPoint.location.lng)
        .distanceTo(targetPos);
    const corridorFormat = sensorCoverageAreaInfo.corridorFormat;
    const isGeneric = installationPoint.parentDevice.productId === null;
    const horizontalFov = sensorCoverageAreaInfo.horizontalFov;
    const verticalFov = !isGeneric
        ? calculateVerticalFov(
              sensorCoverageAreaInfo.horizontalFov,
              sensorCoverageAreaInfo.fovLimits.horizontal.max,
              sensorCoverageAreaInfo.fovLimits.horizontal.min,
              sensorCoverageAreaInfo.fovLimits.vertical.max,
              sensorCoverageAreaInfo.fovLimits.vertical.min,
          )
        : estimateVerticalFOV(horizontalFov);

    const camera = new Vector3(0, cameraHeight, 0);
    const target = new Vector3(targetHandleCoords[0], targetHeight, targetHandleCoords[1]);
    const fovhandle = new Vector3(fovHandleCoords[0], targetHeight, fovHandleCoords[1]);

    const camToTarget = target.clone().sub(camera);
    const camToFovHandle = fovhandle.clone().sub(camera);
    const halfWidthAtTarget =
        camera.distanceTo(target) * Math.tan(camToFovHandle.angleTo(camToTarget));
    const topOfFovTilt = Math.atan((cameraHeight - targetHeight) / targetDistance);
    const fovRad = trigonometry.toRadians(corridorFormat ? horizontalFov : verticalFov);
    const opticalAxisDistance = (targetDistance * Math.cos(fovRad / 2)) / Math.cos(topOfFovTilt);
    const fovAngle = 2 * Math.atan(halfWidthAtTarget / opticalAxisDistance);
    const dragFov = trigonometry.toDegrees(fovAngle);

    // limit field-of-view to what camera model is capable of
    const limitedDragFov = corridorFormat
        ? Math.max(
              Math.min(Math.abs(dragFov), sensorCoverageAreaInfo.fovLimits.vertical.max),
              sensorCoverageAreaInfo.fovLimits.vertical.min,
          )
        : Math.max(
              Math.min(Math.abs(dragFov), sensorCoverageAreaInfo.fovLimits.horizontal.max),
              sensorCoverageAreaInfo.fovLimits.horizontal.min,
          );

    const calculatedHorizontalFov = sensorCoverageAreaInfo.corridorFormat
        ? installationPoint.parentDevice.productId
            ? calculateHorizontalFov(
                  limitedDragFov,
                  sensorCoverageAreaInfo.fovLimits.horizontal.max,
                  sensorCoverageAreaInfo.fovLimits.horizontal.min,
                  sensorCoverageAreaInfo.fovLimits.vertical.max,
                  sensorCoverageAreaInfo.fovLimits.vertical.min,
              )
            : getHorizontalFieldOfView(limitedDragFov)
        : limitedDragFov;

    return calculatedHorizontalFov;
}

function getHorizontalFieldOfView(verticalFov: number) {
    const vFovRad = trigonometry.toRadians(verticalFov);
    const hFovRad = 2 * Math.atan(constants.ASPECT_RATIO * Math.tan(vFovRad / 2));
    return trigonometry.toDegrees(hFovRad);
}
