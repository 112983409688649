function addPrefix(val: string) {
    return `ADDON_SELECTOR_${val}`;
}

export const addonSelectorActions = {
    ResetPartnerProducts: addPrefix('RESET_PRODUCTS'),
    SetAllProductsLoaded: addPrefix('SET_ALL_PRODUCTS_LOADED'),
    SetClickedApplication: addPrefix('SET_CLICKED_APPLICATION'),
    SetItems: addPrefix('SET_ITEMS'),
    SetIsOtherProductSelectorOpen: addPrefix('SET_IS_OTHER_SELECTOR_OPEN'),
    SetReadMoreApplication: addPrefix('SET_READ_MORE_APPLICATION'),
    SetShowPartnerAcapListModal: addPrefix('SET_SHOW_PARTNER_ACAP_LIST_MODAL'),
    SetShowReadMoreModal: addPrefix('SET_SHOW_READ_MORE_MODAL'),
    ToggleELicense: addPrefix('TOGGLE_E_LICENSE'),
    GetAllPartnerProducts: addPrefix('GET_ALL'),
    UpdatePartnerProductFilter: addPrefix('UPDATE_PARTNER_PRODUCT_FILTER'),
    ResetPartnerProductFilter: addPrefix('RESET_PARTNER_PRODUCT_FILTER'),
    ResetPartnerProductFilterExceptCompatible: addPrefix(
        'RESET_PARTNER_PRODUCT_FILTER_EXCEPT_COMPATIBLE',
    ),
    UpdateReadMoreTopPosition: addPrefix('UPDATE_READ_MORE_TOP_POSITION'),
};
