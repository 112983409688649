const clipboardCopy = require('clipboard-copy');

import { t } from 'app/translate';
import { toaster } from 'app/toaster';

export function copyToClipboard(
    data: any,
    successTitle?: string,
    successMessage?: string,
    errorTitle?: string,
    errorMessage?: string,
) {
    const success = clipboardCopy(data) as Promise<void>;

    success
        .then(() => {
            toaster.success(
                successTitle || t.clipboardCopiedToClipboardSuccess,
                successMessage || t.clipboardCopiedToClipboardSuccessVerbose,
            );
        })
        .catch(() => {
            toaster.error(
                errorTitle || t.clipboardCopiedToClipboardError,
                errorMessage || t.clipboardTrySelectAndCopyInstead,
            );
        });
}
