import * as React from 'react';
import type { Icons } from 'app/components';
import {
    Box,
    Text,
    DropDown,
    Stack,
    Border,
    Linkable,
    Clickable,
    Icon,
    IconText,
} from 'app/components';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getPartnerConfigHeaderStyle, getNeedHelpConfig } from 'app/modules/common';
import { getItemLocalStorage, setItemLocalStorage } from 'app/core/persistence';

export const NeedHelpMenu: React.FC = () => {
    const needHelpConfig = useSelector(getNeedHelpConfig);
    const colorOverride = useSelector<IStoreState, string | undefined>(
        (store) => getPartnerConfigHeaderStyle(store)?.foregroundColor,
    );

    React.useEffect(() => {
        if (!needHelpConfig) return;

        setItemLocalStorage('NeedHelpShown', 'true');
    }, [needHelpConfig]);

    const hasNoLocalStorageKey = () => getItemLocalStorage('NeedHelpShown') !== 'true';

    const triggerElement = (
        <IconText
            icon="contact_support"
            textProps={{ color: 'yellowText', semiBold: true, colorOverride }}
            iconProps={{ opaque: true, color: 'yellowText', colorOverride }}
        >
            {t.needHelp}
        </IconText>
    );

    const getSupportLink = (
        text: string | undefined,
        icon: Icons,
        link: string | undefined,
        secondIcon?: Icons,
        subText?: string,
    ) =>
        text && link ? (
            <Linkable external link={link}>
                <Clickable>
                    <Box
                        hoverColor="grey2"
                        padding="quart"
                        flex="evenSpace"
                        justifyContent="center"
                        height="60px"
                        alignItems="center"
                    >
                        <IconText textProps={{ whiteSpace: 'nowrap' }} icon={icon}>
                            <Stack vertical spacing="none" fullHeight>
                                {text}
                                {subText && (
                                    <Text color="grey6" style="small">
                                        {subText}
                                    </Text>
                                )}
                            </Stack>
                        </IconText>
                        {secondIcon && (
                            <Box paddingLeft="base">
                                <Icon icon={secondIcon} />
                            </Box>
                        )}
                    </Box>
                </Clickable>
            </Linkable>
        ) : null;

    if (!needHelpConfig) {
        return null;
    }

    return (
        <DropDown
            openInPortal
            minWidth={430}
            isOpenByDefault={hasNoLocalStorageKey()}
            trigger={triggerElement}
            contents={
                <Box direction="column" color="white">
                    <Border color="grey3" bottomWidth={1}>
                        <Box justifyContent="center" paddingY="base" paddingX="quart" color="grey1">
                            <Text whiteSpace="nowrap" style="semibold" color="grey8">
                                {needHelpConfig.title}
                            </Text>
                        </Box>
                    </Border>
                    <Stack spacing="none" lineBetweenColor="grey3">
                        {getSupportLink(
                            needHelpConfig.phoneNumber,
                            'smartphone',
                            `tel:${needHelpConfig.telephoneLink}`,
                            undefined,
                            needHelpConfig.supportOfficeHours,
                        )}
                        {getSupportLink(
                            needHelpConfig.linkText,
                            'question_answer',
                            needHelpConfig.link,
                            'arrow_forward',
                        )}
                    </Stack>
                </Box>
            }
        />
    );
};

NeedHelpMenu.displayName = 'NeedHelpMenu';
