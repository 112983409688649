import type { Container, interfaces } from 'inversify';
import { initializeContainer } from './initializeContainer';

export class ServiceLocator {
    public static initialize() {
        this.container = initializeContainer();
    }

    public static get<T>(
        serviceIdentifier: string | symbol | interfaces.Newable<T> | interfaces.Abstract<T>,
    ): T {
        return this.container.get<T>(serviceIdentifier);
    }

    private static container: Container;
}
