import * as React from 'react';
import { TextInput, NumberInput, Select, Button, Stack, Icon, DropDown } from 'app/components';
import type {
    CustomItemCategory,
    ICustomItem,
    Id,
    IPersistence,
    IUserCustomItemEntity,
} from 'app/core/persistence';
import { CustomItemCategoryType } from 'app/core/persistence';
import { t } from 'app/translate';
import { getCategoryName } from '../services';
import { AppConstants } from 'app/AppConstants';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import { getCustomUserItemsArray } from '../selectors/getUserCustomItems';
import { UserCustomItemDropDownItem } from './UserCusomItemDropDownItem';
import type { ICustomItemDisplayCat } from '../models';
import { getDropdownUserItems } from '../functions/getDropdownUserItems';

interface IAddCustomItemOwnProps {
    onAdd(customItem: ICustomItem, fromUserCustomItems?: boolean): void;
    onDeleteUserCustomItem(id: Id): void;
}

interface IAddCustomItemStateProps {
    userCustomItems: IPersistence<IUserCustomItemEntity>[];
}

type IAddCustomItemProps = IAddCustomItemOwnProps & IAddCustomItemStateProps;

export type CustomItemField = keyof Omit<ICustomItem, 'quantity' | 'category' | 'partnerProductId'>;

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IAddCustomItemOwnProps,
): IAddCustomItemProps => {
    return {
        userCustomItems: getCustomUserItemsArray(storeState),
        onAdd: ownProps.onAdd,
        onDeleteUserCustomItem: ownProps.onDeleteUserCustomItem,
    };
};

class AddCustomItemContainer extends React.Component<IAddCustomItemProps, ICustomItem> {
    private initialState: ICustomItem = {
        name: '',
        partNumber: '',
        vendor: '',
        category: Object.keys(CustomItemCategoryType)[0] as CustomItemCategory,
        quantity: 1,
    };

    constructor(props: IAddCustomItemProps) {
        super(props);
        this.state = this.initialState;
    }

    public render() {
        const isAddDisabled = this.state.name.trim() === '' && this.state.partNumber.trim() === '';
        return (
            <tr>
                <td style={{ width: '40px' }}>
                    <Icon size="lg" icon="add" color="grey3" />
                </td>
                <td>
                    <DropDown
                        openInPortal
                        hasInput
                        hideEmptyContentBorder
                        contents={this.userCustomItemsBy('name').map(this.getDropdownItem)}
                        trigger={
                            <TextInput
                                changeCriteria="key"
                                placeholder={t.customItemsName}
                                value={this.state.name}
                                onChange={this.changeName}
                                maxLength={AppConstants.deviceNameMaxLength}
                                testId="input_name"
                            />
                        }
                    ></DropDown>
                </td>
                <td>
                    <DropDown
                        openInPortal
                        hasInput
                        hideEmptyContentBorder
                        contents={this.userCustomItemsBy('vendor').map(this.getDropdownItem)}
                        trigger={
                            <TextInput
                                changeCriteria="key"
                                placeholder={t.systemComponentsVendor}
                                value={this.state.vendor}
                                onChange={this.changeVendor}
                                maxLength={AppConstants.deviceNameMaxLength}
                                testId="input_vendor"
                            />
                        }
                    ></DropDown>
                </td>
                <td>
                    <DropDown
                        openInPortal
                        hasInput
                        hideEmptyContentBorder
                        contents={this.userCustomItemsBy('partNumber').map(this.getDropdownItem)}
                        trigger={
                            <TextInput
                                changeCriteria="key"
                                placeholder={t.customItemsPartNumber}
                                value={this.state.partNumber}
                                onChange={this.changePartNumber}
                                maxLength={AppConstants.deviceNameMaxLength}
                                testId="input_part_number"
                            />
                        }
                    ></DropDown>
                </td>
                <td>
                    <Select
                        options={Object.keys(CustomItemCategoryType).map((category) => ({
                            text: getCategoryName(category as CustomItemCategory),
                            value: category,
                        }))}
                        value={this.state.category}
                        onChange={this.changeCategory}
                        fillWidth
                        dropDownMinWidth={237}
                    />
                </td>
                <td>
                    <NumberInput
                        changeCriteria="key"
                        min={1}
                        max={AppConstants.componentQuantityMax}
                        value={this.state.quantity}
                        onChange={this.changeQuantity}
                    />
                </td>
                <td style={{ paddingRight: '0px' }}>
                    <Stack justifyContent="end">
                        <Button
                            testId="btn_add_custom_item"
                            disabled={isAddDisabled}
                            primary
                            onClick={this.addCustomItem}
                        >
                            {t.customItemsAdd}
                        </Button>
                    </Stack>
                </td>
            </tr>
        );
    }

    private userCustomItemsBy = (field: CustomItemField) =>
        getDropdownUserItems(this.props.userCustomItems, field, this.state[field]);

    private changeName = (name: string) => this.setState({ name });
    private changeVendor = (vendor: string) => this.setState({ vendor });
    private changePartNumber = (partNumber: string) => this.setState({ partNumber });
    private changeQuantity = (quantity: number) => this.setState({ quantity });
    private changeCategory = (category: CustomItemCategory) => this.setState({ category });
    private quickAddCustomItem = (item: ICustomItem) => {
        this.props.onAdd({ ...item, quantity: 1 }, true);
        this.setState(this.initialState);
    };
    private addCustomItem = () => {
        if (this.state.name === '' && this.state.partNumber === '') {
            return;
        }
        this.props.onAdd(this.state);
        this.setState(this.initialState);
    };

    private getDropdownItem = (item: ICustomItemDisplayCat) => (
        <UserCustomItemDropDownItem
            item={item}
            onAdd={this.quickAddCustomItem}
            onDelete={this.props.onDeleteUserCustomItem}
        />
    );
}

export const AddCustomItem = connect(mapStateToProps)(AddCustomItemContainer);
