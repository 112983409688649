import { PiaItemCameraCategory } from '../client/types';
import type { PiaCategory } from '../client';

export function getAllCameraCategories(): PiaCategory[] {
    return [
        PiaItemCameraCategory.THERMAL,
        PiaItemCameraCategory.PTZ,
        PiaItemCameraCategory.FIXEDDOME,
        PiaItemCameraCategory.FIXED,
        PiaItemCameraCategory.CAMERAEX,
        PiaItemCameraCategory.COMPLETEMODULAR,
    ];
}
