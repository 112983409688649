function addPrefix(actionName: string) {
    return `APPLICATION_${actionName}`;
}
export const AppActions = {
    GetUserPreferences: addPrefix('GET_USER_PREFERENCES'),
    WindowResize: addPrefix('WINDOW_RESIZE'),
    PiaReady: addPrefix('PIA_READY'),
    PiaProgress: addPrefix('PIA_PROGRESS'),
    UserDataProgress: addPrefix('USER_DATA_PROGRESS'),
    InitializingStorage: addPrefix('INITIALIZING_STORAGE'),
    UserAcceptedEulaVersion: addPrefix('USER_ACCEPTED_EULA_VERSION'),
    GetUserFinishedWelcome: addPrefix('GET_USER_FINISHED_WELCOME'),
    UserFinishedWelcome: addPrefix('USER_FINISHED_WELCOME'),
    SendFeedback: addPrefix('SEND_FEEDBACK'),
    FetchUserInfo: addPrefix('FETCH_USER_INFO'),
    UserNetworkChange: addPrefix('USER_NETWORK_CHANGE'),
    UpdateAvailable: addPrefix('UPDATE_AVAILABLE'),
    UpdateRouterLocation: addPrefix('UPDATE_ROUTER_LOCATION'),
};
