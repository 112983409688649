import * as React from 'react';
import type { IOptionProps } from '../../input/select/Select.component';
import { Select } from '../../input/select/Select.component';
import { IconText } from '../../text/iconText/IconText.component';

interface IActionSelectProps {
    /**
     * A label to print before the selected value
     */
    label?: string;
    selected: IOptionProps;
    options: IOptionProps[];
    onChange(sortProperty: string): void;
}

/**
 * A select box that renders a blue link with an arrow.
 * Mostly used for simple sorting options.
 */
export class ActionSelect extends React.Component<IActionSelectProps> {
    public render() {
        return (
            <Select
                value={this.props.selected.value}
                onChange={this.props.onChange}
                options={this.props.options}
                customTrigger={
                    <IconText
                        icon="arrow_down_special"
                        color="blue"
                        iconProps={{ opaque: true, size: 'sm' }}
                        textProps={{ style: 'semibold' }}
                        alignIconRight
                    >
                        {this.props.label} {this.props.selected.text.toLowerCase()}
                    </IconText>
                }
            />
        );
    }
}
