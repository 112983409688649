import type { IDeviceAnalyticRange, IMinMax } from 'app/core/persistence';
import type { IPiaCamera, IPiaMainUnit, IPiaRadarDetector, IPiaSpeaker } from 'app/core/pia';
import { PiaItemDetectorCategory } from 'app/core/pia';
import type { IPiaRadarDetectorProperties } from 'app/core/pia/client/types/IPiaRadarDetectorProperties';
import { getAnalyticProperties } from './getAnalyticProperties';
import { trigonometry } from 'axis-webtools-util';
import { AXIS_OBJECT_ANALYTICS } from 'app/core/common';

export interface IRangeLimits {
    human: IMinMax;
    vehicle: IMinMax;
}

/**
 * Get the analytic range limits for a radar from the minRadarDetectionRange prop values
 * @param analyticRange information about the analytic
 * @param parentPiaDevice device parent pia device
 * @returns IRangeLimits for the device. Undefined if device is not of radar type, no sensor or is not AOA
 */
const getAnalyticRadarStaticLimits = (
    analyticRange: IDeviceAnalyticRange,
    parentPiaDevice?: IPiaCamera | IPiaSpeaker | IPiaMainUnit | IPiaRadarDetector | null,
): IRangeLimits | undefined => {
    if (
        parentPiaDevice &&
        analyticRange.applicationId === AXIS_OBJECT_ANALYTICS &&
        parentPiaDevice?.categories.includes(PiaItemDetectorCategory.RADARDETECTORS)
    ) {
        const radarProperties = parentPiaDevice.properties as IPiaRadarDetectorProperties;
        return {
            human: {
                min: radarProperties.minRadarDetectionRangeHuman,
                max: radarProperties.maxRadarDetectionRangeHuman,
            },
            vehicle: {
                min: radarProperties.minRadarDetectionRangeVehicle,
                max: radarProperties.maxRadarDetectionRangeVehicle,
            },
        };
    }
    return undefined;
};

/**
 * Bilinear interpolation:
 *
 *   |
 * y2|----------q12----------R2--------------q22---------
 *   |           |           |                |
 *   |           |           |                |
 *   |           |           |                |
 *  y|-----------------------P---------------------------
 *   |           |           |                |
 *   |           |           |                |
 *   |           |           |                |
 *   |           |           |                |
 *   |           |           |                |
 * y1|----------q11----------R1---------------q21--------
 *   |           |           |                |
 *   |___________|___________|________________|_____________
 *               x1          x                x2
 *
 * There is a set of data coordinates (xCoordinates and yCoordinates)
 * The coordinates defines the positions of the points q11, q12, q21 and q22.
 * For any given point(x,y) (inside the measurements), we can find the value for P.
 * To find the point value we do the following:
 * 1. two linear interpolations along the x-axis
 * 2. one linear interpolation along the y-axis, finding the point P
 *
 * 1a. R1 = q11 * (x2-x)/(x2-x1) + q21 * (x-x1)/(x2-x1)
 * 1b. R2 = q12 * (x2-x)/(x2-x1) + q22 * (x-x1)/(x2-x1)
 *
 * 2. P = R1 * (y2-y)/(y2-y1) + R2 * (y-y1)/(y2-y1)
 */

/**
 * Finds the best corresponding value for the current point, determined with bilinear interpolation
 * (see // https://x-engineer.org/bilinear-interpolation/)
 * for the given x and y coordinates and given measurement values
 * @param xCoordinates The given points for camera installation height (in meters)
 * @param yCoordinates The given points for camera tilt angle (in degrees)
 * @param measurements The given values for distance limit (in meters)
 * @param xPoint The current installation height for the device (meter)
 * @param yPoint The current tilt angle for the device (degrees)
 * @returns The best approximated value (P above) for distance limit (in meters)
 */
// only export to make possible to test
export const bilinearInterpolation = (
    xCoordinates: number[],
    yCoordinates: number[],
    measurements: number[][],
    xPoint: number,
    yPoint: number,
): number => {
    let xValue = xPoint;
    let yValue = yPoint;

    // Handle if measure point is outside measurements
    if (xValue < xCoordinates[0] || xValue > xCoordinates[xCoordinates.length - 1]) {
        xValue = xValue < xCoordinates[0] ? xCoordinates[0] : xCoordinates[xCoordinates.length - 1];
    }
    if (yValue < yCoordinates[0] || yValue > yCoordinates[yCoordinates.length - 1]) {
        yValue = yValue < yCoordinates[0] ? yCoordinates[0] : yCoordinates[yCoordinates.length - 1];
    }

    // Find the four nearest grid points to the (x, y) point
    let x1 = 0;
    let x2 = 0;
    let y1 = 0;
    let y2 = 0;

    // Find which two values that the current point fits between
    for (let i = 0; i < xCoordinates.length; i++) {
        if (xCoordinates[i] <= xValue && xCoordinates[i + 1] >= xValue) {
            x1 = xCoordinates[i];
            x2 = xCoordinates[i + 1];
            break;
        }
    }
    for (let i = 0; i < yCoordinates.length; i++) {
        if (yCoordinates[i] <= yValue && yCoordinates[i + 1] >= yValue) {
            y1 = yCoordinates[i];
            y2 = yCoordinates[i + 1];
            break;
        }
    }

    // Get the measurements at the four nearest grid points
    const q11 = measurements[xCoordinates.indexOf(x1)][yCoordinates.indexOf(y1)];
    const q21 = measurements[xCoordinates.indexOf(x2)][yCoordinates.indexOf(y1)];
    const q12 = measurements[xCoordinates.indexOf(x1)][yCoordinates.indexOf(y2)];
    const q22 = measurements[xCoordinates.indexOf(x2)][yCoordinates.indexOf(y2)];

    // Perform the interpolation
    const R1 = q11 * ((x2 - xValue) / (x2 - x1)) + q21 * ((xValue - x1) / (x2 - x1));
    const R2 = q12 * ((x2 - xValue) / (x2 - x1)) + q22 * ((xValue - x1) / (x2 - x1));
    const limitDistance = R1 * ((y2 - yValue) / (y2 - y1)) + R2 * ((yValue - y1) / (y2 - y1));

    return limitDistance;
};

/**
 * Get analytic range radar limits taking installation height and tilt into account
 * @param analyticRange information about the analytic selected
 * @param tiltAngle current tilt angle for the device (degrees)
 * @param sensorHeight installation height of the sensor (meter)
 * @param parentPiaDevice device parent pia device
 * @returns IRangeLimits for the device or undefined if not AOA, no sensor or device is not radar
 */
const getAnalyticRadarLimits = (
    analyticRange: IDeviceAnalyticRange,
    tiltAngle: number,
    sensorHeight: number,
    parentPiaDevice?: IPiaCamera | IPiaSpeaker | IPiaMainUnit | IPiaRadarDetector | null,
): IRangeLimits | undefined => {
    if (parentPiaDevice && analyticRange.applicationId === AXIS_OBJECT_ANALYTICS) {
        if (parentPiaDevice?.categories.includes(PiaItemDetectorCategory.RADARDETECTORS)) {
            const radarProperties = parentPiaDevice.properties as IPiaRadarDetectorProperties;
            if (
                radarProperties.radarNearDetectionLimitForHumans &&
                radarProperties.radarNearDetectionLimitForVehicles &&
                radarProperties.radarFarDetectionLimitForHumans &&
                radarProperties.radarFarDetectionLimitForVehicles
            ) {
                const humanLimitNear = bilinearInterpolation(
                    radarProperties.radarNearDetectionLimitForHumans.heightValues,
                    radarProperties.radarNearDetectionLimitForHumans.tiltValues,
                    radarProperties.radarNearDetectionLimitForHumans.distanceValues,
                    sensorHeight,
                    tiltAngle,
                );
                const humanLimitFar = bilinearInterpolation(
                    radarProperties.radarFarDetectionLimitForHumans.heightValues,
                    radarProperties.radarFarDetectionLimitForHumans.tiltValues,
                    radarProperties.radarFarDetectionLimitForHumans.distanceValues,
                    sensorHeight,
                    tiltAngle,
                );
                const vehicleLimitNear = bilinearInterpolation(
                    radarProperties.radarNearDetectionLimitForVehicles.heightValues,
                    radarProperties.radarNearDetectionLimitForVehicles.tiltValues,
                    radarProperties.radarNearDetectionLimitForVehicles.distanceValues,
                    sensorHeight,
                    tiltAngle,
                );
                const vehicleLimitFar = bilinearInterpolation(
                    radarProperties.radarFarDetectionLimitForVehicles.heightValues,
                    radarProperties.radarFarDetectionLimitForVehicles.tiltValues,
                    radarProperties.radarFarDetectionLimitForVehicles.distanceValues,
                    sensorHeight,
                    tiltAngle,
                );

                return {
                    human: {
                        min: humanLimitNear,
                        max: humanLimitFar,
                    },
                    vehicle: {
                        min: vehicleLimitNear,
                        max: vehicleLimitFar,
                    },
                };
            } else {
                getAnalyticRadarStaticLimits(analyticRange, parentPiaDevice);
            }
        }
    }
    return undefined;
};

/**
 * Get analytic min and max ranges for human and vehicle when radar and camera works
 * combined, i.e both radar and camera should detect
 * @param analyticRange analytic range information
 * @param verticalFov vertical fov for the device
 * @param sensorHeight installation height of the sensor (meter)
 * @param parentPiaDevice device parent pia device
 * @returns IRangeLimits for the device
 */
const getAnalyticFusionLimits = (
    analyticRange: IDeviceAnalyticRange,
    verticalFov: number,
    tiltAngle: number,
    sensorHeight: number,
    parentPiaDevice?: IPiaCamera | IPiaSpeaker | IPiaMainUnit | IPiaRadarDetector | null,
): IRangeLimits | undefined => {
    if (!parentPiaDevice) {
        return undefined;
    }
    const radarLimits = getAnalyticRadarLimits(
        analyticRange,
        tiltAngle,
        sensorHeight,
        parentPiaDevice,
    );
    const cameraLimits = getAnalyticCameraStaticLimits(analyticRange, verticalFov, parentPiaDevice);
    if (!cameraLimits) {
        return undefined;
    }
    return {
        human: {
            min: radarLimits
                ? Math.max(radarLimits.human.min, cameraLimits.human.min)
                : cameraLimits.human.min,
            max: radarLimits
                ? Math.min(radarLimits.human.max, cameraLimits.human.max)
                : cameraLimits.human.max,
        },
        vehicle: {
            min: radarLimits
                ? Math.max(radarLimits.vehicle.min, cameraLimits.vehicle.min)
                : cameraLimits.vehicle.min,
            max: radarLimits
                ? Math.min(radarLimits.vehicle.max, cameraLimits.vehicle.max)
                : cameraLimits.vehicle.max,
        },
    };
};

/** returns a distance in meter where the given height is x percent of the total image */
const getDistancePercentOfImageHeight = (
    height: number,
    percent: number,
    verticalFovRad: number,
): number => {
    return height / ((percent / 100) * verticalFovRad);
};

/**
 * Return analytic range limits for a camera device
 * @param analyticRange information about the analytic selected
 * @param verticalFov vertical fov of the device
 * @param parentPiaDevice device parent pia device
 * @returns IRangeLimits for the selected camera device
 */
const getAnalyticCameraStaticLimits = (
    analyticRange: IDeviceAnalyticRange,
    verticalFov: number,
    parentPiaDevice?: IPiaCamera | IPiaSpeaker | IPiaMainUnit | IPiaRadarDetector | null,
): IRangeLimits | undefined => {
    const analyticProperties = analyticRange.applicationId
        ? getAnalyticProperties(
              analyticRange.applicationId,
              parentPiaDevice,
              analyticRange.lightCondition,
              analyticRange.analyticMode,
          )
        : undefined;
    if (!analyticProperties) {
        return undefined;
    }
    const vehicleRangeLimit = getDistancePercentOfImageHeight(
        analyticProperties.vehicle.height,
        analyticProperties.vehicle.percent,
        trigonometry.toRadians(verticalFov),
    );

    const personRangeLimit = getDistancePercentOfImageHeight(
        analyticProperties.person.height,
        analyticProperties.person.percent,
        trigonometry.toRadians(verticalFov),
    );
    return {
        human: {
            min: 0,
            max: personRangeLimit,
        },
        vehicle: {
            min: 0,
            max: vehicleRangeLimit,
        },
    };
};

/**
 * Return analytic ranges for the selected device and zone (applicable for AOA and Oxxo Q1656-DLE so far)
 * @param analyticRange analytic information of selected application
 * @param verticalFov vertical fov for the device (degrees)
 * @param tiltAngle current tilt angle for device (degrees)
 * @param sensorHeight installation height of the sensor (meter)
 * @param parentPiaDevice device parent pia device
 * @returns min and max values (in meters) for analytic limits (person and vehicle)
 */
export const getAnalyticZoneLimits = (
    analyticRange: IDeviceAnalyticRange,
    verticalFov: number,
    tiltAngle: number,
    sensorHeight: number,
    parentPiaDevice?: IPiaCamera | IPiaSpeaker | IPiaMainUnit | IPiaRadarDetector | null,
): IRangeLimits | undefined => {
    if (
        parentPiaDevice &&
        analyticRange.applicationId === AXIS_OBJECT_ANALYTICS &&
        parentPiaDevice?.categories.includes(PiaItemDetectorCategory.RADARDETECTORS) &&
        (analyticRange.zone === 'radar' ||
            analyticRange.zone === 'fusion' ||
            analyticRange.zone === 'camera')
    ) {
        if (analyticRange.zone === 'radar')
            return getAnalyticRadarLimits(analyticRange, tiltAngle, sensorHeight, parentPiaDevice);
        if (analyticRange.zone === 'fusion') {
            return getAnalyticFusionLimits(
                analyticRange,
                verticalFov,
                tiltAngle,
                sensorHeight,
                parentPiaDevice,
            );
        }
        if (analyticRange.zone === 'camera')
            return getAnalyticCameraStaticLimits(analyticRange, verticalFov, parentPiaDevice);
    }
    return getAnalyticCameraStaticLimits(analyticRange, verticalFov, parentPiaDevice);
};
