import type { IEntity, IBaseEntity } from '../IEntity';
import type { IMapOrigin } from '../maps/IMapOrigin';
import type { ILocated } from './IInstallationPointEntity';

export enum FontSize {
    small = 10,
    medium = 15,
    large = 20,
}

export interface IFreeTextPoint extends ILocated, IMapOrigin {
    text: string;
    /**
     * Font size of the free text
     */
    size: FontSize;
    textColor?: string;
    backgroundColor?: string;
}

export interface IFreeTextPointBaseEntity extends IFreeTextPoint, IBaseEntity {
    readonly type: 'freeTextPoint';
}

export interface IFreeTextPointEntity extends IFreeTextPoint, IEntity {
    readonly type: 'freeTextPoint';
}
