import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getMapMarkersForFloorPlanWithDeviceIds, getFloorPlansWithDeviceIds } from './selectors';
import { MiniMapComponent } from './MiniMap.component';
import type {
    Id,
    IFloorPlanEntity,
    IPersistence,
    IFloorPlanMapType,
    IInstallationPointEntity,
    MapType,
} from 'app/core/persistence';
import type { IAutoTestable } from 'app/components';
import { Border, Box, Stack, Text } from 'app/components';
import type { IMiniMapMarker } from './models';
import { getInstallationPointsOnFloorPlansSortByCreationFactory } from '../../selectors';

interface IMiniMapContainerOwnProps extends IAutoTestable {
    /** First id is main device. Following ids are possible child units. */
    deviceIds: Id[];
    noBorder?: boolean;
    floorPlanId?: Id;
    showLegendIds?: boolean;
    showName?: boolean;
    ipId?: string;
    deviceQuantity?: number;
    hideCone?: boolean;
}

interface IInstallationPointEntityWithMapType extends IInstallationPointEntity {
    mapType: MapType;
}

interface IMiniMapContainerProps extends IMiniMapContainerOwnProps {
    floorPlansForDevice: IPersistence<IFloorPlanEntity>[];
    markers: Record<Id, IMiniMapMarker[]>;
    installationPointsOnMapsForDevices: Record<Id, IInstallationPointEntityWithMapType[]>;
}

function mapStateToProps(
    storeState: IStoreState,
    ownProps: IMiniMapContainerOwnProps,
): IMiniMapContainerProps {
    const ipsOnMapsForDevices: Record<Id, IInstallationPointEntityWithMapType[]> = {};
    ownProps.deviceIds.forEach((deviceId) => {
        // get all installation points for this device (that is placed on a map) sorted by creation
        const ipForDevice =
            getInstallationPointsOnFloorPlansSortByCreationFactory(storeState)(deviceId);
        ipsOnMapsForDevices[deviceId] = ipForDevice;
    });

    return {
        floorPlansForDevice: getFloorPlansWithDeviceIds(
            storeState,
            ownProps.deviceIds,
            ownProps.floorPlanId,
        ),
        markers: getMapMarkersForFloorPlanWithDeviceIds(storeState, ownProps.deviceIds),
        installationPointsOnMapsForDevices: ipsOnMapsForDevices,
        ...ownProps,
    };
}

class MiniMapContainer extends React.Component<IMiniMapContainerProps> {
    constructor(props: IMiniMapContainerProps) {
        super(props);
    }

    public render() {
        if (
            Object.values(this.props.markers).length < 1 ||
            this.props.floorPlansForDevice.length < 1
        ) {
            return null;
        }

        const borderWidth = this.props.noBorder ? 0 : 1;
        let floorPlanToRender;
        let markerToRender;

        const markerLabels: Record<Id, string> = {};

        if (this.props.deviceQuantity) {
            const installationPointsForDevice = this.props.deviceIds.map(
                (id) => this.props.installationPointsOnMapsForDevices[id],
            );

            for (let index = 0; index < installationPointsForDevice.length; index++) {
                // get the right number (index) for the installationPoints including ip that has been removed (unplaced) from map (to get the same index as the one used in maps)
                for (let i = 0; i < this.props.deviceQuantity; i++) {
                    const addedProperties = {
                        ipId: installationPointsForDevice[index][i]
                            ? installationPointsForDevice[index][i]._id
                            : 'unplaced',
                        nameNumber: `${
                            index === 0 ? i + 1 : i + 1 + String.fromCharCode(96 + index)
                        }`,
                    };
                    markerLabels[addedProperties.ipId] = addedProperties.nameNumber;
                }
            }
        }

        if (this.props.ipId == 'unplaced') {
            return null;
        }
        if (this.props.ipId) {
            for (
                let floorplanIndex = 0;
                floorplanIndex < this.props.floorPlansForDevice.length;
                floorplanIndex++
            ) {
                const floorPlan = this.props.floorPlansForDevice[floorplanIndex];
                markerToRender = this.props.markers[floorPlan._id].find(
                    (markerItem) => markerItem.installationPointId === this.props.ipId,
                );

                if (markerToRender) {
                    markerToRender.label = markerLabels[markerToRender.installationPointId];
                    floorPlanToRender = floorPlan as IFloorPlanMapType;
                    break;
                }
            }
        } else {
            this.props.floorPlansForDevice.forEach((floorPlan) => {
                const updatedMarkers = this.props.markers[floorPlan._id];
                updatedMarkers.forEach((marker) => {
                    marker.label = markerLabels[marker.installationPointId];
                });
            });
        }

        return (
            <Border topWidth={borderWidth} bottomWidth={borderWidth} color="warmGrey4">
                <Box width="100%" justifyContent="center">
                    <Stack lineBetweenColor="grey4" justifyContent="center" alignItems="center">
                        {floorPlanToRender
                            ? this.renderFloorPlan(floorPlanToRender, markerToRender)
                            : this.props.floorPlansForDevice.map((floorPlan) =>
                                  this.renderFloorPlan(floorPlan as IFloorPlanMapType),
                              )}
                    </Stack>
                </Box>
            </Border>
        );
    }

    private renderFloorPlan = (
        floorPlan: IFloorPlanMapType,
        markerToRender?: IMiniMapMarker,
    ): JSX.Element => {
        return (
            <Box
                key={floorPlan.image.key}
                flex="evenSpace"
                direction="column"
                padding="base"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                {this.props.showName && (
                    <Text align="center" semiBold>
                        {floorPlan.name}
                    </Text>
                )}
                <MiniMapComponent
                    testId={this.props.testId}
                    imgKey={floorPlan.image.key}
                    markers={markerToRender ? [markerToRender] : this.props.markers[floorPlan._id]}
                    width={'80%'}
                    dimensions={floorPlan.image.dimensions}
                    imgMaxWidth="100%"
                    imgOpacity={floorPlan.image.opacity}
                    ignoreEXIF={floorPlan.image.ignoreEXIF}
                    showLegendIds={this.props.showLegendIds}
                    hideCone={this.props.hideCone}
                    height={'100%'}
                />
            </Box>
        );
    };
}

export const MiniMap = connect(mapStateToProps)(MiniMapContainer);
