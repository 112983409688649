import * as React from 'react';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { getFpsOptions } from '../../selectors';

interface IFpsFilterProps {
    selectedFps: number;
    onClick(rating: number): void;
}

/** Filter button with dropdown options for minimum required FPS */
export const FpsFilter: React.FC<IFpsFilterProps> = ({ selectedFps, onClick }) => {
    const fpsOptions = useSelector<IStoreState, number[]>(getFpsOptions);

    if (fpsOptions.length === 0) {
        return null;
    }

    return (
        <DropDown
            openInPortal
            trigger={
                <PillButton
                    icon="keyboard_arrow_down"
                    selected={!!selectedFps}
                    text={`${t.minFps}${selectedFps ? `: ${String(selectedFps)}` : ''}`}
                />
            }
            contents={
                <>
                    {fpsOptions.map((option) => (
                        <DropDownMenuButton
                            key={option}
                            label={String(option)}
                            selected={selectedFps === option}
                            onClick={() => onClick(selectedFps === option ? 0 : option)}
                        />
                    ))}
                </>
            }
        />
    );
};

FpsFilter.displayName = 'FpsFilter';
