import { DistanceUnit } from 'app/core/persistence';
import { convert } from 'axis-webtools-util';

/**
 * Returns a distance unit converter function from project specific units to meters for persistance
 * @param distance
 * @param distanceUnit
 */
export const generateToMetersConverter = (distanceUnit: DistanceUnit.Meter | DistanceUnit.Feet) => {
    return (distance: number): number => {
        if (distanceUnit === DistanceUnit.Feet) {
            distance = convert.feetToMeters(distance);
        }
        return distance;
    };
};
