import * as React from 'react';
import { TextPlaceholder, Stack, IconText } from 'app/components';
import type { PiaId } from 'app/core/pia';
import { usePromise } from 'app/hooks';
import { useService } from 'app/ioc';
import { DeviceDescriptionService } from 'app/core/persistence';

interface IApplicationBulletPointsProps {
    productId: PiaId;
}

export const ApplicationBulletPoints: React.FC<IApplicationBulletPointsProps> = ({ productId }) => {
    const deviceDescriptionService = useService(DeviceDescriptionService);
    const bulletPromise = React.useMemo(
        () => deviceDescriptionService.getBulletPoints(productId),
        [deviceDescriptionService, productId],
    );

    const { result: bullets, pending: loading } = usePromise(bulletPromise, [bulletPromise]);

    return loading ? (
        <TextPlaceholder rows={5} />
    ) : bullets && bullets.length > 0 ? (
        <Stack spacing="none" vertical>
            {bullets.map((bullet, i) => (
                <IconText
                    icon="check"
                    align="start"
                    key={i}
                    textProps={{ style: 'small', wordBreak: 'normal' }}
                    iconProps={{ size: 'sm', color: 'yellow6' }}
                >
                    {bullet}
                </IconText>
            ))}
        </Stack>
    ) : null;
};

ApplicationBulletPoints.displayName = 'ApplicationBulletPoints';
