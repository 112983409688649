import * as React from 'react';
import type { AlignItems, Spacings, Colors } from 'app/styles';
import type { IExtendableComponentWithChildren } from '../../models';
import { Stack } from '../../layout';
import type { ITextProps } from '../text/Text.component';
import { Text } from '../text/Text.component';
import type { IIconProps, Icons } from '../../ui/icon';
import { Icon } from '../../ui/icon';
import type { IAutoTestable } from '../../ui-test';

interface IIconTextProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * Specify which icon to display
     */
    icon: Icons;
    /**
     * Override the color of both `Text` and `Icon`
     */
    color?: Colors;
    /**
     * Set the icon to the right of text instead of to the left
     */
    alignIconRight?: boolean;
    /**
     * Set the `Icon` props
     */
    iconProps?: Omit<IIconProps, 'icon'>;
    /**
     * Set the `Text` props
     */
    textProps?: ITextProps;
    /**
     * Set the spacing between the icon and the text
     */
    spacing?: Spacings;
    /**
     * Override the `alignItems` prop of the `Stack`.
     * Defaults to "center"
     */
    align?: AlignItems;
}

/**
 * Display an icon with text.
 *
 * Will render an `<Icon>` and a `<Text>` inside a `<Stack>`.
 */
export const IconText: React.FunctionComponent<IIconTextProps> = ({
    children,
    iconProps = {},
    icon,
    align,
    spacing,
    textProps = {},
    color,
    alignIconRight,
    ...extendedProps
}) => {
    if (color) {
        iconProps.color = iconProps.color ?? color;
        textProps.color = textProps.color ?? color;
    }

    const iconAndText = [
        <Icon testId={`${icon}_link_icon`} key="icon" icon={icon} {...iconProps} />,
        <Text testId={extendedProps.testId} inline key="text" {...textProps}>
            {children}
        </Text>,
    ];

    return (
        <Stack spacing={spacing || 'quart'} alignItems={align || 'center'} {...extendedProps}>
            {alignIconRight ? iconAndText.reverse() : iconAndText}
        </Stack>
    );
};

IconText.displayName = 'IconText';
