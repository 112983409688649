import type { IItemEntity, IPersistence } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import type { IPiaCamera, IPiaRelationPropertyItem, IPiaSensorUnit } from 'app/core/pia';
import { calculateFOVLimits } from '../piaDevices';
import { calculateTiltAngleFromDegrees } from './calculateTiltAngle';
import { getVerticalFovOfCamera } from './calculateVerticalFov';

export const toTiltAngle = (
    installationHeight: number,
    targetHeight: number,
    distanceToTarget: number,
    corridorFormat: boolean,
    horizontalFOV: number,
    item: IPersistence<IItemEntity>,
    piaCamera: IPiaCamera | IPiaSensorUnit | null,
    relationProperties?: IPiaRelationPropertyItem,
) => {
    const customCameraProperties = isCustomCamera(item)
        ? item.properties.camera.customCameraProperties
        : undefined;
    const aspectRatio = customCameraProperties
        ? customCameraProperties.resolutionHorizontal / customCameraProperties.resolutionVertical
        : undefined;

    const lensFOVLimits =
        piaCamera && relationProperties
            ? calculateFOVLimits(piaCamera, relationProperties, item)
            : undefined;

    const verticalFOV = getVerticalFovOfCamera(
        horizontalFOV,
        piaCamera?.properties,
        lensFOVLimits,
        aspectRatio,
    );

    const fov = corridorFormat ? horizontalFOV : verticalFOV;

    return calculateTiltAngleFromDegrees(installationHeight, targetHeight, distanceToTarget, fov);
};
