import { injectable } from 'inversify';
import { memoize } from 'axis-webtools-util';
import { eventTracking } from 'app/core/tracking';
import { FileTransferService } from 'app/modules/maps/services/FileTransfer.service';
import { toErrorMessage } from '../../utils';
import { X_AXIS_USER_API_AUTH, UserApiCommunicator } from '../user/services/UserApi.communicator';

interface IImageUploadResponse {
    imageId: string;
}

interface IImageUploadReturn {
    key: string;
}

const IMAGE_STORE_URL = '/imagestore';
const IMAGE_STORE_PRESIGNED_URL = '/imagestore/signedurl';
const IMAGE_QUOTA_URL = '/imagestore/quota';
const IMAGE_STORE_ORPHAN_IMAGES = '/imagestore/orphans?id=';

@injectable()
export class ImageStoreCommunicator {
    constructor(
        private fileTransferService: FileTransferService,
        private userApiCommunicator: UserApiCommunicator,
    ) {}
    public getImageUrlAsBase64(key: string): Promise<string> {
        if (!key) {
            return Promise.resolve('');
        }
        return this.getImageAsBase64(key);
    }

    public getUserImageQuotaUrl(): string {
        return `${IMAGE_QUOTA_URL}`;
    }

    public uploadImage = async (image: Blob): Promise<IImageUploadReturn> => {
        try {
            const tokens = await this.userApiCommunicator.fetchUserToken();
            const response = await new Promise<IImageUploadResponse>((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.withCredentials = true;

                const timestamp = Date.now();
                this.fileTransferService.addFileTransfer(timestamp);

                xhr.upload.onprogress = (event: ProgressEvent) => {
                    this.fileTransferService.updateFileTransfer({
                        id: timestamp,
                        total: event.total,
                        loaded: event.loaded,
                    });
                };

                xhr.onerror = () => {
                    this.fileTransferService.endFileTransfer(timestamp);
                    reject(new Error(String(xhr.status)));
                };

                xhr.upload.onerror = () => {
                    this.fileTransferService.endFileTransfer(timestamp);
                    reject(new Error(String(xhr.status)));
                };

                // need to use xhr.onload instead of xhr.upload.onload
                // else it won't wait for server response with imageId
                xhr.onload = () => {
                    this.fileTransferService.endFileTransfer(timestamp);

                    if (xhr.status !== 200) {
                        reject(new Error(String(xhr.status)));
                    }

                    // using JSON.parse instead of xhr.responseType="json" for
                    // IE11 compatibility
                    resolve(JSON.parse(xhr.response));
                };

                xhr.open('POST', IMAGE_STORE_URL);
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                xhr.setRequestHeader('Content-Type', image.type);
                xhr.setRequestHeader(X_AXIS_USER_API_AUTH, `Bearer ${tokens?.accessToken}`);
                xhr.send(image);
            });

            return {
                key: response.imageId,
            };
        } catch (error) {
            eventTracking.logError(
                `Failed to upload image ${toErrorMessage(error)}`,
                'ImageStoreCommunicator',
            );
            throw error;
        }
    };

    /**
     * Deletes a file from the S3 bucket with a certain key
     * Allows silent failure, since this is used when changing an image, and we don't want to throw if the user can use the newly added image.
     */
    public deleteImage = async (key: string, throwOnError = true): Promise<void> => {
        try {
            const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
            const response = await fetch(`${IMAGE_STORE_URL}/${key}`, {
                method: 'DELETE',
                credentials: 'include',
                headers,
            });

            if (!response.ok && throwOnError) {
                throw Error(String(response.status));
            }
        } catch (error) {
            eventTracking.logError(
                `Failed to remove image ${toErrorMessage(error)}`,
                'ImageStoreCommunicator',
            );
            if (throwOnError) {
                throw error;
            }
        }
    };

    public uploadImageFile = async (file: File): Promise<IImageUploadReturn> => {
        try {
            return await this.uploadImage(file);
        } catch (error) {
            eventTracking.logError(
                `Failed to upload base64 image ${toErrorMessage(error)}`,
                'ImageStoreCommunicator',
            );
            throw error;
        }
    };

    public getPresignedUrl = async (key: string): Promise<string> => {
        try {
            const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
            const response = await fetch(this.getPresignedImageUrl(key), {
                method: 'GET',
                credentials: 'include',
                headers,
            });

            if (!response.ok) {
                throw Error(String(response.status));
            }
            return response.json();
        } catch (error) {
            eventTracking.logError(
                `Failed to get presigned image url ${toErrorMessage(error)}`,
                'ImageStoreCommunicator',
            );
            throw error;
        }
    };

    public getUserImageQuota = async (): Promise<string> => {
        try {
            const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
            const response = await fetch(this.getUserImageQuotaUrl(), {
                method: 'GET',
                credentials: 'include',
                headers,
            });

            if (!response.ok) {
                throw Error(String(response.status));
            }
            return response.json();
        } catch (error) {
            eventTracking.logError(
                `Failed to get user image quota url ${toErrorMessage(error)}`,
                'ImageStoreCommunicator',
            );
            throw error;
        }
    };

    public getOrphanImages = async (floorPlanIds: string[]): Promise<string[]> => {
        try {
            const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
            const response = await fetch(`${IMAGE_STORE_ORPHAN_IMAGES}${floorPlanIds}`, {
                method: 'GET',
                credentials: 'include',
                headers,
            });

            if (!response.ok) {
                throw Error(String(response.status));
            }
            return response.json();
        } catch (error) {
            eventTracking.logError(
                `Failed to investigate orphan images ${toErrorMessage(error)}`,
                'ImageStoreCommunicator',
            );
            throw error;
        }
    };

    /**
     * Gets an image as a base64 encoded string. Keeps a cache of the last 10 images.
     * @param key The key of the image to get
     * @returns A promise that resolves to the base64 encoded image
     */
    public getImageAsBase64 = memoize(async (key: string): Promise<string> => {
        try {
            const blob = await this.getImageAsBlob(key);

            const base64Image = await new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    resolve(reader.result);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsDataURL(blob);
            });

            return base64Image as string;
        } catch (error) {
            eventTracking.logError(
                `Failed to get base64 image ${toErrorMessage(error)}`,
                'ImageStoreCommunicator',
            );
            throw error;
        }
    }, 10); // keep 10 images in cache

    public getImageAsBlob = async (key: string): Promise<Blob> => {
        const headers = await this.userApiCommunicator.fetchUserTokenHeaders();
        const response = await fetch(`${IMAGE_STORE_URL}/${key}`, {
            credentials: 'include',
            headers,
        });

        if (response.status !== 200) {
            throw new Error(response.statusText);
        }

        return response.blob();
    };

    private getPresignedImageUrl(key: string) {
        return `${IMAGE_STORE_PRESIGNED_URL}/${key}`;
    }
}
