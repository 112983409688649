import {
    getAllCameraCategories,
    PiaAccessoryCategory,
    PiaItemAlerterCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemEncoderCategory,
    PiaItemMainUnitCategory,
    PiaItemPacCategory,
    PiaItemPagingConsoleCategory,
    PiaItemPeopleCounterCategory,
    PiaItemRecorderCategory,
    PiaItemSensorUnitCategory,
    PiaItemSoftwareCategory,
    PiaItemSpeakerCategory,
    PiaItemVirtualProductCategory,
    PiaItemWearablesCategory,
} from 'app/core/pia';
import type {
    IPiaStorageDevice,
    IPiaTwoNCategory,
    IPiaAccessServer,
    IPiaAnsweringUnit,
    IPiaApplication,
    IPiaBodyWornCamera,
    IPiaCamera,
    IPiaCameraExtension,
    IPiaDecoder,
    IPiaDoorController,
    IPiaDockingStation,
    IPiaDoorStation,
    IPiaEncoder,
    IPiaIoRelay,
    IPiaItem,
    IPiaMainUnit,
    IPiaNetworkReader,
    IPiaPac,
    IPiaPeopleCounter,
    IPiaSensorUnit,
    IPiaSpeaker,
    IPiaSystemComponent,
    IPiaSystemController,
    IPiaAccessory,
    IPiaSensorDevice,
    IPiaRadarDetector,
    IPiaAlerter,
    IPiaVirtualProduct,
    IPiaVirtualCamera,
    IPiaPoeConsumerProperties,
    IPiaPoeConsumer,
    IPiaRelayExpansionModule,
    IPiaSoftware,
    IPiaConnectivityDevice,
    IPiaPagingConsole,
} from 'app/core/pia';

export const isEncoder = (device: IPiaItem | null | undefined): device is IPiaEncoder =>
    !!device && device.category === PiaItemEncoderCategory.ENCODER;

export const isMainUnit = (device: IPiaItem | null | undefined): device is IPiaMainUnit =>
    !!device && device.category === PiaItemMainUnitCategory.MAINUNIT;

export const isSpeaker = (device: IPiaItem | null | undefined): device is IPiaSpeaker =>
    !!device && device.category === PiaItemSpeakerCategory.SPEAKER;

export const isSensorUnit = (device: IPiaItem | null | undefined): device is IPiaSensorUnit =>
    !!device &&
    (device.category === PiaItemSensorUnitCategory.SENSORUNIT ||
        device.category === PiaItemSensorUnitCategory.THERMALSENSOR);

export const isCamera = (device: IPiaItem | null | undefined): device is IPiaCamera =>
    !!device && device.categories.some((category) => getAllCameraCategories().includes(category));

export const isVirtualProduct = (
    device: IPiaItem | null | undefined,
): device is IPiaVirtualProduct =>
    !!device && device.category === PiaItemVirtualProductCategory.VIRTUAL_PRODUCT;

export const isVirtualCamera = (device: IPiaItem | null | undefined): device is IPiaVirtualCamera =>
    isVirtualProduct(device) &&
    device.properties.virtualProductCategory.some((category) =>
        getAllCameraCategories().includes(category),
    );

export const isPac = (device: IPiaItem | null | undefined): device is IPiaPac =>
    !!device &&
    (device.category === PiaItemPacCategory.DOORCONTROLLERS ||
        device.category === PiaItemPacCategory.IORELAYS);

export const isIoRelay = (device: IPiaItem | null | undefined): device is IPiaIoRelay =>
    !!device && device.category === PiaItemPacCategory.IORELAYS;

export const isExpandableIoRelay = (device: IPiaItem | null | undefined): device is IPiaIoRelay =>
    isIoRelay(device) && !!device.properties.nbrSupportedExpansionModules;

export const isDoorController = (
    device: IPiaItem | null | undefined,
): device is IPiaDoorController =>
    !!device && device.category === PiaItemPacCategory.DOORCONTROLLERS;

export const isDecoder = (device: IPiaItem | null | undefined): device is IPiaDecoder =>
    !!device && device.category === PiaItemDecoderCategory.DECODER;

export const isRadar = (device: IPiaItem | null): device is IPiaRadarDetector =>
    !!device && device.category === PiaItemDetectorCategory.RADARDETECTORS;

export const isConnectivityDevice = (device: IPiaItem | null): device is IPiaConnectivityDevice =>
    !!device && device.category === PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES;

export const isPagingConsole = (device: IPiaItem | null): device is IPiaPagingConsole =>
    !!device && device.category === PiaItemPagingConsoleCategory.PAGINGCONSOLE;

export const isAlerter = (device: IPiaItem | null): device is IPiaAlerter =>
    !!device && device.category === PiaItemAlerterCategory.ALERTERS;

export const isDoorStation = (device: IPiaItem | null | undefined): device is IPiaDoorStation =>
    !!device && device.category === PiaItemPacCategory.DOORSTATIONS;

export const isCameraDoorStation = (piaCamera: IPiaCamera | IPiaSensorUnit | null | undefined) =>
    !!piaCamera?.categories.includes(PiaItemPacCategory.DOORSTATIONS);

export const isPeopleCounter = (device: IPiaItem | null | undefined): device is IPiaPeopleCounter =>
    !!device && device.category === PiaItemPeopleCounterCategory.PEOPLECOUNTERS;

export const isAccessServer = (device: IPiaItem | null | undefined): device is IPiaAccessServer =>
    !!device && device.category === PiaItemPacCategory.ACCESSSERVER;

export const isAnsweringUnit = (device: IPiaItem | null | undefined): device is IPiaAnsweringUnit =>
    !!device && device.category === PiaItemPacCategory.ANSWERINGUNIT;

export const isNetworkReader = (device: IPiaItem | null | undefined): device is IPiaNetworkReader =>
    !!device && device.category === PiaItemPacCategory.NETWORKREADER;

export const isApplication = (device: IPiaItem | null | undefined): device is IPiaApplication =>
    !!device && device.category === PiaItemSoftwareCategory.ACAP;

export const isStorageDevice = (device: IPiaItem | null | undefined): device is IPiaStorageDevice =>
    !!device && device.category === PiaAccessoryCategory.STORAGE;

export const isBodyWornCamera = (
    device: IPiaItem | null | undefined,
): device is IPiaBodyWornCamera => !!device && device.category === PiaItemWearablesCategory.CAMERAS;

export const isSystemController = (
    device: IPiaItem | null | undefined,
): device is IPiaSystemController =>
    !!device && device.category === PiaItemWearablesCategory.CONTROLLER;

export const isDockingStation = (
    device: IPiaItem | null | undefined,
): device is IPiaDockingStation => !!device && device.category === PiaItemWearablesCategory.DOCKING;

export const isCameraExtension = (
    device: IPiaItem | null | undefined,
): device is IPiaCameraExtension =>
    !!device && device.category === PiaItemWearablesCategory.CAMERAEXTENSIONS;

export const isWearable = (
    device: IPiaItem | null | undefined,
): device is IPiaDockingStation | IPiaSystemController | IPiaBodyWornCamera =>
    !!device &&
    (isBodyWornCamera(device) || isSystemController(device) || isDockingStation(device));

export const isTwoNCategory = (device: IPiaItem | null | undefined): device is IPiaTwoNCategory =>
    !!device &&
    (device.category === PiaItemPacCategory.ACCESSSERVER ||
        device.category === PiaItemPacCategory.ANSWERINGUNIT ||
        device.category === PiaItemPacCategory.DOORSTATIONS ||
        device.category === PiaItemPacCategory.NETWORKREADER);

export const isRecordingSolutionComponent = (
    device: IPiaItem | null | undefined,
): device is IPiaSystemComponent =>
    !!device &&
    (device.category === PiaAccessoryCategory.NETWORKSWITCHES ||
        device.category === PiaItemRecorderCategory.RECORDERS2 ||
        device.category === PiaItemRecorderCategory.DESKTOPTERMINALS ||
        device.category === PiaAccessoryCategory.STORAGE ||
        device.category === PiaItemSoftwareCategory.VMS);

export const isSystemComponent = (
    device: IPiaItem | null | undefined,
): device is IPiaSystemComponent =>
    !!device &&
    (device.category === PiaAccessoryCategory.NETWORKSWITCHES ||
        device.category === PiaItemRecorderCategory.RECORDERS2 ||
        device.category === PiaItemRecorderCategory.DESKTOPTERMINALS);

export const isAccessory = (device: IPiaItem | null | undefined): device is IPiaAccessory =>
    (!!device &&
        Object.values(PiaAccessoryCategory).includes(device.category as PiaAccessoryCategory)) ||
    device?.category === PiaItemWearablesCategory.DEVICE;

export const isSensorDevice = (device: IPiaItem | null | undefined): device is IPiaSensorDevice =>
    isCamera(device) || isSensorUnit(device) || isVirtualCamera(device);

export const isAccessoryLens = (device: IPiaItem | null | undefined): device is IPiaAccessory =>
    device?.category === PiaAccessoryCategory.LENSES;

export const isAccessoryBridge = (device: IPiaItem | null | undefined): device is IPiaAccessory =>
    device?.category === PiaAccessoryCategory.BRIDGES;

export const isAccessoryServer = (device: IPiaItem | null | undefined): device is IPiaAccessory =>
    device?.category === PiaAccessoryCategory.SERVERS;

export const isAccessoryAmplifier = (
    device: IPiaItem | null | undefined,
): device is IPiaAccessory => device?.category === PiaAccessoryCategory.AMPLIFIERS;

export const isWearableSystemDevice = (
    device: IPiaItem | null | undefined,
): device is IPiaAccessory => device?.category === PiaItemWearablesCategory.DEVICE;

export const isSystemAccessory = (item: IPiaItem | null | undefined): item is IPiaAccessory =>
    !!item &&
    (item.category === PiaAccessoryCategory.AUDIOANDIO ||
        item.category === PiaAccessoryCategory.CABINETS ||
        item.category === PiaAccessoryCategory.JOYSTICKSCONTR ||
        item.category === PiaAccessoryCategory.INSTALLTOOLS ||
        item.category === PiaAccessoryCategory.MISCELLANEOUS ||
        item.category === PiaAccessoryCategory.BRIDGES ||
        item.category === PiaAccessoryCategory.MICROPHONES ||
        item.category === PiaAccessoryCategory.SERVERS ||
        item.category === PiaAccessoryCategory.AMPLIFIERS ||
        item.category === PiaAccessoryCategory.CABLESCON ||
        item.category === PiaAccessoryCategory.ETHERNETOVERCOAX ||
        item.category === PiaAccessoryCategory.MEDIACONVERTERS ||
        item.category === PiaAccessoryCategory.SURGEPROTECTORS ||
        item.category === PiaAccessoryCategory.POEEXTENDERS ||
        item.category === PiaItemWearablesCategory.DEVICE);

export const isPiaPoeConsumer = (item: IPiaItem | null | undefined): item is IPiaPoeConsumer =>
    (item?.properties as IPiaPoeConsumerProperties)?.powerConsumptionW !== undefined ||
    (item?.properties as IPiaPoeConsumerProperties)?.PoEClass !== undefined ||
    (item?.properties as IPiaPoeConsumerProperties)?.typicalPoEPower !== undefined ||
    (item?.properties as IPiaPoeConsumerProperties)?.typicalACPower !== undefined ||
    (item?.properties as IPiaPoeConsumerProperties)?.ACInputVoltage !== undefined ||
    (item?.properties as IPiaPoeConsumerProperties)?.DCInputVoltage !== undefined ||
    !!(item?.properties as IPiaPoeConsumerProperties)?.powerOverEthernet;

export const isRelayExpansionModule = (
    device: IPiaItem | null | undefined,
): device is IPiaRelayExpansionModule =>
    !!device && device.category === PiaItemPacCategory.RELAYEXPMODULES;

export const isAudioManagementSoftware = (
    device: IPiaItem | null | undefined,
): device is IPiaSoftware =>
    !!device &&
    Object.values(PiaItemSoftwareCategory).includes(device.category as PiaItemSoftwareCategory) &&
    device?.category === PiaItemSoftwareCategory.AUDIO_MANAGEMENT_SOFTWARE;
