import type { IModule, IModuleOptions } from './models';
import { isProduction } from 'app/isProduction';

export class ModuleRegistry {
    public static registerModule(name: string, options: IModuleOptions) {
        /*
         * During development we need to reload the application if a service has been changed to make
         * it work correctly with hot-reloading.
         *
         * Important - only do this a development build. A production build will not work since the names are modified.
         */
        if (!isProduction()) {
            const foundIndex = this.modules.findIndex(
                (m) => m.name.toLowerCase() === name.toLowerCase(),
            );
            if (foundIndex >= 0) {
                this.modules.splice(foundIndex, 1);
            }
        }

        this.modules.push({
            name,
            options,
        });
    }

    public static getModules(): IModule[] {
        return this.modules;
    }

    private static readonly modules: IModule[] = [];
}
