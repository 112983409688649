import { DocumentationCommunicator } from './Documentation.communicator';
import { injectable } from 'inversify';
import type { PiaId } from 'app/core/pia';
import { eventTracking } from 'app/core/tracking';
import { t } from 'app/translate';
import { toaster } from 'app/toaster';
import { PiaQueriesService } from 'app/modules/common';

@injectable()
export class DocumentationService {
    constructor(
        private communicator: DocumentationCommunicator,
        private piaQueriesService: PiaQueriesService,
    ) {}

    getDatasheets = (piaIds: PiaId[], projectName: string) => {
        this.showDownloadInProgressToaster();
        this.communicator
            .getDataSheets(piaIds, projectName)
            .then((res) => {
                res !== undefined
                    ? this.handleErrorDownloading(piaIds.toString())
                    : this.showSuccessfulToaster();
            })
            .catch(() => {
                this.handleErrorDownloading(piaIds.toString());
            });
    };

    getDatasheet = (piaId: PiaId) => {
        const modelName = this.piaQueriesService.getPiaItemFromId(piaId).name;
        this.showDownloadInProgressToaster();
        this.communicator
            .getDataSheets([piaId], modelName)
            .then((res) => {
                res !== undefined
                    ? this.handleErrorDownloading(piaId.toString())
                    : this.successfulDownload();
            })
            .catch(() => {
                this.handleErrorDownloading(piaId.toString());
            });
    };

    /** Checks if given piaIds have available documentation in English. It ignores selected language. */
    checkDocumentationAvailability = async (piaIds: PiaId[]): Promise<Set<PiaId>> => {
        const response = await this.communicator.checkDocumentationAvailability(piaIds);
        if (response) {
            return new Set(Object.keys(response).map((id) => parseInt(id)));
        }
        return new Set([]);
    };

    showDownloadInProgressToaster = () => {
        toaster.info(t.documentationDownloadingDatasheetTitle, t.documentationDownloadingDatasheet);
    };

    successfulDownload = () => {
        this.showSuccessfulToaster();
        eventTracking.logUserEvent('Documentation', 'Successfully downloaded datasheets');
    };

    handleErrorDownloading = (id: string) => {
        this.showErrorToaster();

        eventTracking.logError(`Failed downloading datasheet for  ${id}`, 'DocumentationService');
    };

    showErrorToaster = () => {
        toaster.warning(t.downloadDocumentErrorTitle, t.downloadDocumentErrorErrorMessage);
    };

    showSuccessfulToaster = () => {
        toaster.success(
            t.documentationSuccessfullDownloadTitle,
            t.documentationSuccessfullDownload,
        );
    };
}
