import type {
    ICustomItemSort,
    IProjectDeviceSort,
    IUserPreferences,
    RecordingCategoriesFilter,
} from '../models';

export const defaultCustomItemSortOrder: ICustomItemSort = {
    sortBy: 'name',
    direction: 'ascending',
};

export const defaultDeviceListSortOrder: IProjectDeviceSort = {
    sort: 'name',
    direction: 'ascending',
};

export const defaultRecordingCategory: RecordingCategoriesFilter = 'servers';

export const defaultUserPreferences: IUserPreferences = {
    leftMenuExpanded: true,
    addProductExpanded: false,
    showOnlyDevicesOnMap: true,
    customItemListSorting: defaultCustomItemSortOrder,
    deviceListSorting: defaultDeviceListSortOrder,
    customizeSolutionRecordingCategory: defaultRecordingCategory,
};
