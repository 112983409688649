import { injectable } from 'inversify';
import { KeyValueStore } from 'axis-webtools-util';
import type { IPersistedUserInfo, IPersistedUserPrivileges } from '../models';
import { eventTracking } from 'app/core/tracking';

const USER_DATA_DATABASE_NAME = 'axis.webtools.userApiInfo';
const USER_DATA_STORE_NAME = 'userInfo';

const USER_INFO_KEY = 'userInfo';
const PREVIOUS_USER_INFO_KEY = 'previousUserInfo';
const USER_PRIVILEGES_KEY = 'privileges';

@injectable()
export class UserDataRepository {
    private userDataStore: KeyValueStore<any>;

    constructor() {
        this.userDataStore = new KeyValueStore<any>(USER_DATA_DATABASE_NAME, USER_DATA_STORE_NAME);
    }

    public getUserInfo = (): Promise<IPersistedUserInfo | undefined> => {
        try {
            return this.userDataStore.getItem(USER_INFO_KEY);
        } catch (error) {
            eventTracking.logError(
                'Retrieving user info from IndexedDB failed.',
                'UserDataRepository',
            );
            throw new Error('Retrieving user info from IndexedDB failed.');
        }
    };

    public setUserInfo = async (userInfo: IPersistedUserInfo): Promise<void> => {
        try {
            await this.userDataStore.setItem(USER_INFO_KEY, userInfo);
        } catch (error) {
            eventTracking.logError('Storing user info in IndexedDB failed.', 'UserDataRepository');
            throw new Error('Storing user info in IndexedDB failed.');
        }
    };

    public getUserPrivileges = (): Promise<IPersistedUserPrivileges | undefined> => {
        try {
            return this.userDataStore.getItem(USER_PRIVILEGES_KEY);
        } catch (error) {
            eventTracking.logError(
                'Retrieving privileges from IndexedDB failed.',
                'UserDataRepository',
            );
            throw new Error('Retrieving privileges from IndexedDB failed.');
        }
    };

    public setUserPrivileges = async (privileges: IPersistedUserPrivileges): Promise<void> => {
        try {
            await this.userDataStore.setItem(USER_PRIVILEGES_KEY, privileges);
        } catch (error) {
            eventTracking.logError('Storing privileges in IndexedDB failed.', 'UserDataRepository');
            throw new Error('Storing privileges in IndexedDB failed.');
        }
    };

    public clearUserInfo = () => this.userDataStore.clear();

    public async getPreviousUserHid(): Promise<string | undefined> {
        try {
            const previousUserHid = await this.userDataStore.getItem(PREVIOUS_USER_INFO_KEY);
            if (!previousUserHid) {
                const userInfo = await this.getUserInfo();
                return userInfo && userInfo.hid;
            }
            return previousUserHid;
        } catch {
            eventTracking.logError(
                'Retrieving previous user info from IndexedDB failed.',
                'UserDataRepository',
            );
            throw new Error('Retrieving previous user info from IndexedDB failed.');
        }
    }

    public async setPreviousUserHid(unid: string): Promise<void> {
        try {
            await this.userDataStore.setItem(PREVIOUS_USER_INFO_KEY, unid);
        } catch {
            eventTracking.logError(
                'Storing previous user info in IndexedDB failed.',
                'UserDataRepository',
            );
            throw new Error('Storing previous user info in IndexedDB failed.');
        }
    }
}
