import * as React from 'react';
import { useSelector } from 'react-redux';
import type { PiaId } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import { getComparableProducts } from '../../selectors/getCompare';
import { getPiaItemsRecord } from 'app/modules/common';
import { Spacer, Text } from 'app/components';
import { t } from 'app/translate';

interface ICompareDiscontinuedProps {
    piaId: PiaId;
    piaName: string;
}

export const CompareDiscontinued: React.FC<ICompareDiscontinuedProps> = ({ piaId, piaName }) => {
    const productsToCompare = useSelector(getComparableProducts);
    const piaItems = useSelector(getPiaItemsRecord);

    const hasDiscontinuedProduct = productsToCompare.some((product) =>
        isDiscontinued(piaItems[product.piaId]),
    );

    if (!hasDiscontinuedProduct) return null;

    /** Represents if any compared product has both a long name and is discontinued */
    const hasDiscontinuedLongNameProduct =
        hasDiscontinuedProduct &&
        productsToCompare.some((product) => product.name.split(' ').length > 2);

    const discontinued = isDiscontinued(piaItems[piaId]);
    const hasLongName = piaName.split(' ').length > 2;

    /** Height to compensate for empty rows. Each text row has a height of 17. */
    const spacerHeight = (Number(!hasLongName) + Number(!discontinued)) * 17;

    return (
        <>
            {hasDiscontinuedLongNameProduct && spacerHeight > 0 && (
                <Spacer customSpacing={`${spacerHeight}px`} />
            )}
            {discontinued && (
                <Text color="red" align="center">
                    {t.discontinued}
                </Text>
            )}
        </>
    );
};

CompareDiscontinued.displayName = 'CompareDiscontinued';
