import type { Store } from '@reduxjs/toolkit';
import type { IStoreState } from 'app/store/IStoreState';
import { injectable } from 'inversify';

@injectable()
export class AppStore {
    private store!: Store<IStoreState>;

    public setStore(store: Store<IStoreState>) {
        this.store = store;
    }

    public get Store() {
        return this.store;
    }
}
