import { reducerBuilder } from 'app/store';
import type { IMapsReportState } from './IMapsReportState';
import type { Orientation, IPaperSize } from './../models';
import { MapsReportActions } from './MapsReport.actions';

export const mapsReportReducer = reducerBuilder<IMapsReportState>()
    .setInitialState({})
    .onAction<IPaperSize>(MapsReportActions.SetPaperSize, (state, action) => ({
        ...state,
        paperSize: action.payload,
    }))
    .onAction<Orientation>(MapsReportActions.SetOrientation, (state, action) => ({
        ...state,
        orientation: action.payload,
    }))
    .create();
