export const languageCodeToLanguageName = {
    ar: 'العربية',
    cs: 'Česky',
    zhhans: '简体中文',
    zhhant: '繁體中文',
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    he: 'עברית',
    it: 'Italiano',
    ja: '日本語',
    ko: '한국어',
    nl: 'Nederlands',
    pl: 'Polski',
    pt: 'Português',
    ru: 'Русский',
    fi: 'Suomi',
    sv: 'Svenska',
    th: 'ไทยภาษา',
    tr: 'Türkçe',
    vi: 'Tiếng Việt',
};

export const languageCodeToTextLanguage = {
    ar: 'ar-EG',
    cs: 'cs',
    zhhans: 'zh-CN', // simplified do not know which it should be transformed to
    zhhant: 'zh-TW', // traditional do not know which it should be transformed to
    de: 'de-DE',
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    it: 'it-IT',
    he: 'he',
    ja: 'ja-JP',
    ko: 'ko-KR',
    nl: 'nl',
    pl: 'pl',
    pt: 'pt-BR',
    ru: 'ru-RU',
    fi: 'fi',
    sv: 'sv',
    th: 'th',
    tr: 'tr',
    vi: 'vi',
};

export const getHtmlLangTag = (langCode: LanguageCode): string => {
    return langCode.includes('zhhan') ? langCode.replace('hh', 'h-H') : langCode;
};

export type LanguageCode = keyof typeof languageCodeToLanguageName;
