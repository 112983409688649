import { eventTracking } from 'app/core/tracking';
import {
    isAccessory,
    isApplication,
    isRelayExpansionModule,
    isRecordingSolutionComponent,
    isSensorUnit,
    isStorageDevice,
    isSystemAccessory,
    isAudioManagementSoftware,
} from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { t } from 'app/translate';

/**
 * Gets a description for reason to why the item can not be added
 * in quick add or where it will be added
 * */
export const getQuickAddDescription = (item: IPiaItem) => {
    if (isSensorUnit(item)) {
        return t.mustBeAddedToMainUnit;
    }
    // Storage devices need to be added to devices
    if (isStorageDevice(item) || isRelayExpansionModule(item)) {
        return t.mustBeAddedToDevice;
    }
    // Network switches are both recording components and accessories.
    // We need to check if item is recording solution before
    // isAccessory since we want the message for recording to be displayed
    if (isRecordingSolutionComponent(item)) {
        return t.willAddToRecordingSolution;
    }
    if (isAccessory(item) || isAudioManagementSoftware(item)) {
        if (isSystemAccessory(item) || isAudioManagementSoftware(item)) {
            return t.willAddAsSystemAccessory;
        }
        return t.mustBeAddedToDevice;
    }
    if (isApplication(item)) {
        return t.mustBeAddedToDevice;
    }

    eventTracking.logError(`Item reason not found for category: ${item.category}`, 'quickAdd');
    return t.cannotQuickAdd;
};
