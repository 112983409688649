import { flatMap, memoize, uniq } from 'lodash-es';
import type {
    PiaId,
    IPiaContext,
    IPiaRelationItem,
    IPiaItem,
    IPiaAccessory,
    IPiaRelationPropertyItem,
} from '../types';
import { PiaItemState, PiaRelationTypes } from '../types';
import { filterProducts, isDeviceRelation } from '../../utils';

const DEFAULT_MAX_PATH_LENGTH = 7;
const WILDCARD = 1;

export class PiaRelationService {
    /**
     * Get included items for an item
     * @param piaId - Id of the source item
     */
    public getIncluded = memoize((piaId: number): number[] => {
        return this.getRelations(piaId)
            .filter(this.filterByType(PiaRelationTypes.Includes))
            .map(this.idFromRelationOrItem);
    });

    private ctx: IPiaContext<any, any>;

    /** All main mounts in Pia */
    private mainMountsPiaIds: number[] = [];

    constructor(ctx: IPiaContext<any, any>) {
        this.ctx = ctx;
        this.updateMainMountPiaIds();
    }

    public setContext(ctx: IPiaContext<any, any>) {
        this.ctx = ctx;
        this.updateMainMountPiaIds();
    }

    /**
     * Get compatible items (not implicitly compatible) for the given item
     * @param piaId - The id of the item to check compatible items for
     */
    public getCompatible(piaId: number): number[] {
        const compatibleFilter = this.filterByType(PiaRelationTypes.Compatible);
        return this.getRelations(piaId).filter(compatibleFilter).map(this.idFromRelationOrItem);
    }

    /**
     * Check if an item (target) is compatible with another item (source)
     * @param piaId - Id of the  source item of which its relation will be checked
     * @param relatedPiaId - Id of the related item to check if it is compatible with the source item
     */
    public isCompatibleWith(piaId: number, relatedPiaId: number): boolean {
        const compatible = this.getRelations(piaId)
            .filter((item) => {
                return (
                    this.filterByType(PiaRelationTypes.Compatible)(item) ||
                    this.filterByType(PiaRelationTypes.ImplicitlyCompatible)(item)
                );
            })
            .map(this.idFromRelationOrItem);

        return compatible.indexOf(relatedPiaId) >= 0;
    }

    /**
     * Get the recommended items for an item
     * @param source - The  piaId item of which its recommended items will returned
     */
    public getRecommended(piaId: number): number[] {
        return this.getRelations(piaId)
            .filter(this.filterByType(PiaRelationTypes.Recommends))
            .map(this.idFromRelationOrItem);
    }

    /**
     * Check if an item (target) is recommended by another item (source)
     * @param piaId - Id of the source item
     * @param relatedPiaId - Id of the related item to check if it is recommended by the source item
     */
    public isRecommended(piaId: number, relatedPiaId: number): boolean {
        const recommendations = this.getRelations(piaId)
            .filter(this.filterByType(PiaRelationTypes.Recommends))
            .filter((relation) => relation.id === relatedPiaId)
            .map(this.idFromRelationOrItem);

        return recommendations.indexOf(relatedPiaId) >= 0;
    }

    /**
     * Check if an item (relatedPiaId) is included by another item (piaId)
     * @param piaId - Id of the source item of which the target will be checked if included
     * @param relatedPiaId - Id of the related item to check if it is included by the source item
     */
    public isIncluded(piaId: number, relatedPiaId: number): boolean {
        const includes = this.getRelations(piaId)
            .filter(this.filterByType(PiaRelationTypes.Includes))
            .map(this.idFromRelationOrItem);

        return includes.indexOf(relatedPiaId) >= 0;
    }

    /**
     * Get properties of relation between piaId and relatedPiaId.
     * @param piaId - Id of the source item.
     * @param relatedPiaId - Id of the related item to source item.
     */
    public getRelationProperties(
        piaId: number,
        relatedPiaId: number,
    ): IPiaRelationPropertyItem | undefined {
        const relation = this.getRelations(piaId).find(
            (piaRelationItem) => piaRelationItem.id === relatedPiaId,
        );
        return relation?.relationProperties;
    }

    /**
     * Depth first search that searches for all replacement for the source item. Returns
     * an empty list if no valid replacements are found.
     *
     * @param piaId - The item that replacements are  searched for
     * @param depth - Used by recursion to limit the depth of the search in order to handle cyclic relations gracefully.
     */
    public searchReplacements(piaId: number, depth = 20): number[] {
        // We have reached our maximum search depth - abort
        if (depth === 0) {
            // PIA data is corrupt. Report this in Google Analytics when we add events
            return [];
        }

        const replacements = this.getRelations(piaId).filter(
            this.filterByType(PiaRelationTypes.ReplacedBy),
        );

        // dead branch
        if (replacements.length === 0) {
            return [];
        }

        const getLeaves = (relations: IPiaRelationItem[]) =>
            relations.flatMap((replacement) =>
                replacement.state === PiaItemState.EXTERNALLY_ANNOUNCED
                    ? [replacement.id]
                    : this.searchReplacements(replacement.id, depth - 1),
            );

        return uniq(getLeaves(replacements));
    }

    /**
     * Gets all compatible paths from a device pia id to an environment pia id. The path must contain at least one main mount.
     * Returns the paths in sorted array with the shortest paths first.
     *
     * @param devicePiaId - The PIA id of the device
     * @param environmentPiaId - The PIA id of the environment
     * @param states - A list of valid PIA states for the related item, only relations for items that satisfy this will be considered. Defaults to Externally Announced
     * @param categories - A list of valid categories for the related item, only relations for items that satisfy this will be considered.
     *    Empty list will result in no category filtering.
     * @param maxPathLength - The max length for the resulting path. Min length is 1.
     * @param outdoor - Set to true if path must be outdoor compatible
     */
    public getAllCompatiblePaths(
        devicePiaId: number,
        environmentPiaId: number,
        regions: string[],
        categories: string[] = [],
        states: PiaItemState[] = [PiaItemState.EXTERNALLY_ANNOUNCED],
        maxPathLength: number = DEFAULT_MAX_PATH_LENGTH,
        outdoor: boolean = false,
    ): number[][] {
        if (devicePiaId === environmentPiaId) {
            return [[environmentPiaId]];
        }

        if (maxPathLength < 1) {
            maxPathLength = 1;
        }

        const relationOrTargetFilter = this.createRelationOrTargetFilter(
            categories,
            states,
            environmentPiaId,
        );
        const allPaths: number[][] = [];

        const getNeighbors = memoize((id) =>
            this.getRelations(id, regions)
                .filter(relationOrTargetFilter)
                .map(this.idFromRelationOrItem),
        );

        /**
         * Recursive function that gets all relations for the current pia id and tries to find
         * the target pia id. Appends found paths on the `allPaths` variable.
         */
        const traversePaths = (currentPiaId: number, currentPath: number[] = []): void => {
            const path = [...currentPath, currentPiaId];

            const neighbors = getNeighbors(currentPiaId);

            for (const neighborPiaId of neighbors) {
                if (neighborPiaId === environmentPiaId) {
                    allPaths.push([...path, environmentPiaId]);
                } else if (!path.includes(neighborPiaId) && path.length + 1 < maxPathLength) {
                    traversePaths(neighborPiaId, path);
                }
            }
        };

        // Start recursion
        traversePaths(devicePiaId);

        const filterNotToBeFollowedBy = (path: number[]) => this.checkNotToBeFollowedBy(path);
        const filterOutdoorChain = (path: number[]) =>
            outdoor ? this.checkIsOutdoorChain(path) : true;

        const lengthComparator = (a: number[], b: number[]) => a.length - b.length;

        return Array.from(
            allPaths
                .filter(
                    (path) =>
                        this.isValidPathChain(path) &&
                        filterNotToBeFollowedBy(path) &&
                        filterOutdoorChain(path),
                )
                .map(this.excludeIncluded)
                .reduce((map, path) => map.set(path.join('|'), path), new Map<string, number[]>())
                .values(),
        ).sort(lengthComparator);
    }

    /**
     * Performs a breadth first search to see if there exists a path between the source and the target node using the compatible relation type
     *
     * @param devicePiaId - The PIA id of the device
     * @param environmentPiaId - The PIA id of the environment
     * @param states - A list of valid PIA states for the related item, only relations for items that satisfy this will be considered. Defaults to Externally Announced
     * @param categories - A list of valid categories for the related item, only relations for items that satisfy this will be considered.
     *    Empty list will result in no category filtering.
     * @param maxPathLength - The max length for the resulting path. Min length is 1.
     */
    public isTransitivelyCompatible(
        devicePiaId: number,
        environmentPiaId: number,
        regions: string[],
        states: PiaItemState[] = [PiaItemState.EXTERNALLY_ANNOUNCED],
        categories: string[] = [],
        maxPathLength: number = DEFAULT_MAX_PATH_LENGTH,
    ): boolean {
        if (devicePiaId === environmentPiaId) {
            return true;
        }

        if (maxPathLength < 1) {
            maxPathLength = 1;
        }

        const relationOrTargetFilter = this.createRelationOrTargetFilter(
            categories,
            states,
            environmentPiaId,
        );
        const nodesToVisit = [devicePiaId];
        let currentNode: number | undefined;
        const visited = new Set<number>();
        const predecessors = new Map<number, number>();

        while (nodesToVisit.length > 0) {
            currentNode = nodesToVisit.shift();

            if (currentNode === undefined) {
                continue;
            }

            const neighbors = this.getRelations(currentNode, regions)
                .filter(relationOrTargetFilter)
                .map(this.idFromRelationOrItem);

            for (const neighborNode of neighbors) {
                if (visited.has(neighborNode) === false) {
                    if (neighborNode === environmentPiaId) {
                        // Found, backtrack to get the path
                        const path = [neighborNode, currentNode];

                        while (currentNode !== devicePiaId) {
                            const node = predecessors.get(currentNode);
                            if (!node) {
                                throw Error(`Could not get node: ${currentNode} from predecessors`);
                            }
                            path.push(node);
                            currentNode = node;
                        }

                        // Only return if the path is valid, else continue looking
                        if (path.length <= maxPathLength && this.isValidPathChain(path)) {
                            return true;
                        }
                    } else {
                        visited.add(neighborNode);
                        predecessors.set(neighborNode, currentNode);
                        nodesToVisit.push(neighborNode);
                    }
                }
            }
        }

        return false;
    }

    public getCompatibleDeviceIds(
        environmentPiaId: PiaId,
        regions: string[],
        states: PiaItemState[] = [PiaItemState.EXTERNALLY_ANNOUNCED],
        maxPathLength: number = DEFAULT_MAX_PATH_LENGTH,
    ): PiaId[] {
        const visited = new Set<number>();

        const recurse = (nodeId: number, depth: number): number[] => {
            if (depth <= 0) {
                // max depth reached
                return [];
            }

            const neighbors = this.getRelations(nodeId, regions).filter(
                (relation) =>
                    states.some((state) => this.filterByPiaState(state)) &&
                    relation.relationType === PiaRelationTypes.ImplicitlyCompatible,
            );

            return flatMap(neighbors, (neighborNode) => {
                if (visited.has(neighborNode.id)) {
                    // we have already visited this node so don't go any further
                    return [];
                } else {
                    visited.add(neighborNode.id);
                    if (isDeviceRelation(neighborNode)) {
                        // we found a device
                        return [neighborNode.id];
                    } else {
                        // go deeper
                        return recurse(neighborNode.id, depth - 1);
                    }
                }
            });
        };

        return recurse(environmentPiaId, maxPathLength);
    }

    private createRelationOrTargetFilter(
        categories: string[],
        states: PiaItemState[],
        targetPiaId?: number,
    ): (relation: IPiaRelationItem) => boolean {
        const categoryFilter = (relation: IPiaRelationItem) =>
            categories.length === 0 || categories.indexOf(relation.category) >= 0;

        const stateFilter = (relation: IPiaRelationItem) =>
            states.some(
                (state: PiaItemState) => state === PiaItemState.ANY || relation.state === state,
            );

        const relationTypeFilter = (relation: IPiaRelationItem) =>
            relation.relationType === PiaRelationTypes.Compatible;

        const relationFilter = (relation: IPiaRelationItem) =>
            relationTypeFilter(relation) && stateFilter(relation) && categoryFilter(relation);

        const isTargetFilter = (relation: IPiaRelationItem) =>
            targetPiaId ? relation.id === targetPiaId : true;

        const targetFilter = (relation: IPiaRelationItem) =>
            relationTypeFilter(relation) && isTargetFilter(relation);

        return (relation: IPiaRelationItem) => relationFilter(relation) || targetFilter(relation);
    }

    /**
     * Paths that consists only of a device and an environment, i.e. length === 2, or contain
     * at least one main mount are considered valid.
     * @param path - Whole or part of the given path
     */
    private isValidPathChain = (path: number[]): boolean =>
        path.length === 2 || path.some((p) => this.mainMountsPiaIds.includes(p));

    /**
     * Checks if item X in a given path includes any of the remaining items in the path.
     * @param path - Whole or part of the given path
     */
    private excludeIncluded = (path: number[]): number[] => {
        if (path.length === 1) {
            return path;
        }

        const first = path[0];
        const tail = path.slice(1);
        const includedPiaIds = this.getIncluded(first);
        const remaining = tail.filter((piaId) => !includedPiaIds.includes(piaId));

        return [first, ...this.excludeIncluded(remaining)];
    };

    private matchesPattern(path: number[], pattern: Array<number | null>): boolean {
        if (path.length === 0 && pattern.length === 0) {
            return true;
        }

        // if the pattern contains a wildcard (1), interpret it as a 0. If there is no match, interpret it as nothing
        if (pattern[0] === WILDCARD) {
            return this.matchesPattern(path.slice(1), pattern.slice(1))
                ? true
                : this.matchesPattern(path, pattern.slice(1));
        }

        return (
            (pattern[0] === null || pattern[0] === path[0]) &&
            this.matchesPattern(path.slice(1), pattern.slice(1))
        );
    }

    /**
     * Check if the path is a path that match the notToBeFollowedBy property
     * @param path path of pia-items to check
     * @returns false if the path is a notToBeFollowedBy, i.e not a valid path.
     */
    private checkNotToBeFollowedBy(path: PiaId[]): boolean {
        if (path.length === 1) {
            return true;
        }

        const first = path[0];
        const tail = path.slice(1);
        const item = this.getItem(first);
        const environmentPiaId = path[path.length - 1];

        if (item && item.properties && item.properties.notToBeFollowedBy) {
            const notToBeFollowedBy = item.properties.notToBeFollowedBy;

            const match = notToBeFollowedBy.some((pattern: Array<number | null>) => {
                const newTail =
                    pattern[pattern.length - 1] === environmentPiaId ? tail : tail.slice(0, -1);

                return (
                    (pattern.includes(1) || pattern.length === newTail.length) &&
                    this.matchesPattern(newTail, pattern)
                );
            });

            if (match) {
                return false;
            }
        }

        return this.checkNotToBeFollowedBy(tail);
    }

    /**
     * Check if the path is a path that supports outdoor
     * @param path path of pia-items to check
     * @returns false if the path is not an outdoor valid path, i.e not a valid path.
     */
    public checkIsOutdoorChain(path: number[]): boolean {
        const innerMountingChain = path.slice(1, -1);
        return innerMountingChain.every((id) => {
            const piaItem = this.getItem(id);
            return piaItem?.properties.outdoorReady;
        });
    }

    private updateMainMountPiaIds() {
        this.mainMountsPiaIds = Array.from(this.ctx.items.values())
            .filter((item: IPiaAccessory) => item.properties.accMainMount)
            .map(this.idFromRelationOrItem);
    }

    private getItem(id: number): IPiaAccessory {
        return this.ctx.items.get(id);
    }

    private getRelations(id: number, regions?: string[]): IPiaRelationItem[] {
        if (!this.ctx.relationsGraph.hasOwnProperty(id)) {
            return [];
        }

        const regionFilter = regions ? filterProducts.byRegions(regions) : () => true;

        const relations = this.ctx.relationsGraph[id].filter((relation) =>
            regionFilter(this.getItem(relation.id)),
        );

        return relations;
    }

    private idFromRelationOrItem(relation: IPiaRelationItem | IPiaItem): number {
        return relation.id;
    }

    private filterByPiaState(state: PiaItemState): (piaItem: IPiaRelationItem) => boolean {
        return (relation) => state === PiaItemState.ANY || relation.state === state;
    }

    private filterByType(type: PiaRelationTypes): (piaItem: IPiaRelationItem) => boolean {
        return (relation) => relation.relationType === type;
    }
}
