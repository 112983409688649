import type { IProduct } from 'app/modules/common';
import { quotationFilter, CategoryEnum } from 'app/modules/common';

export const quotationCostCalculator = {
    /**
     * Calculate the summed quote price for an array of products
     */
    calculateTotalCost: (products: IProduct[]): number =>
        products
            .filter(
                quotationFilter.byCategories(
                    CategoryEnum.Camera,
                    CategoryEnum.Encoder,
                    CategoryEnum.FSeries,
                    CategoryEnum.RecordingAndNetwork,
                    CategoryEnum.Speaker,
                    CategoryEnum.Software,
                    CategoryEnum.Pac,
                    CategoryEnum.Decoder,
                    CategoryEnum.RadarDetector,
                    CategoryEnum.Miscellaneous,
                    CategoryEnum.DoorStation,
                    CategoryEnum.Accessory,
                    CategoryEnum.InstallationService,
                    CategoryEnum.PeopleCounters,
                    CategoryEnum.Wearables,
                    CategoryEnum.DoorControllers,
                    CategoryEnum.Alerters,
                    CategoryEnum.ConnectivityDevice,
                    CategoryEnum.PagingConsole,
                ),
            )
            .reduce(addProductCost, 0),
};

function addProductCost(total: number, product: IProduct) {
    return total + product.quantity * (product.quotePrice || 0);
}
