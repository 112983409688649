import * as React from 'react';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { Box } from '../containers';
import { Border } from '../style';

const svgStyle = css`
    transform: rotate(-0.25turn);
    flex-grow: 1;
    width: 100%;
    height: 100%;
`;

const pieStyle = (color?: Colors) => css`
    fill: ${color ? ColorsEnum[color] : ColorsEnum['grey4']};
`;

interface IPieChartProps {
    percent: number;
    color?: Colors;
}

export const PieChart: React.FunctionComponent<IPieChartProps> = ({ percent, color }) => {
    const normalizedPercent = Math.max(0, Math.min(percent, 100));
    if (normalizedPercent !== percent && percent > 100) {
        color = 'red';
        percent = normalizedPercent;
    }

    return (
        <Border color={percent >= 100 ? 'transparent' : color ? color : 'grey4'}>
            <Box borderRadius="circle" width="37px" height="37px" flex="none">
                {/* The view box and svg style is used to make a cartesian coordinate system */}
                <svg viewBox="-1 -1 2 2" className={svgStyle}>
                    <path d={getPathData(percent)} className={pieStyle(color)} />
                </svg>
            </Box>
        </Border>
    );
};

PieChart.displayName = 'PieChart';

const getPathData = (percent: number): string => {
    const [startX, startY] = [1, 0];
    const [endX, endY] = getCoordinatesForPercent(percent);
    const largeArcFlag = percent > 50 ? 1 : 0;

    const pathData = [
        `M ${startX} ${startY}`,
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
        `L 0 0`,
    ].join(' ');

    return pathData;
};

const getCoordinatesForPercent = (percent: number) => {
    const x = Math.cos((2 * Math.PI * percent) / 100);
    const y = percent >= 100 ? -0.0001 : Math.sin((2 * Math.PI * percent) / 100);
    return [x, y];
};
