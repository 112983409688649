import * as React from 'react';
import type {
    IInstallationBaseReportAccessory,
    IInstallationReportSpeakerDevice,
} from '../../models/devices';
import { Stack, Box, Text } from 'app/components';
import { t } from 'app/translate';
import { AccessoryTable, MountingChain, NetworkItem, ReportHeading } from '../common';
import type {
    Id,
    IPersistence,
    IProfileEntity,
    IProjectNetworkSettings,
} from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import {
    getReportAccessories,
    getReportApplication,
    getReportMounts,
    getScheduleNamesRecord,
    getSpeakerReportDevice,
} from '../../selectors';
import { getCurrentProjectNetworkSettings, getMergedProfile } from 'app/modules/common';

const RAIN_PROOF_IMG = require('src/assets/images/speakers/speaker-splash-proof-do.svg');
const FACE_DOWN_IMG = require('src/assets/images/speakers/speaker-cabinet-ceiling-do.svg');
const DISTRIBUTE_IMG = require('src/assets/images/speakers/speaker-ceiling-do.svg');

interface ISpeakerReportItemOwnProps {
    id: Id;
    deviceQuantityIndex?: number;
}

interface ISpeakerReportItemProps extends ISpeakerReportItemOwnProps {
    profile?: IPersistence<IProfileEntity> | undefined;
    mounts: IInstallationBaseReportAccessory[];
    accessories: IInstallationBaseReportAccessory[];
    applications: IInstallationBaseReportAccessory[];
    device: IInstallationReportSpeakerDevice | undefined;
    scheduleNames?: Record<string, string>;
    networkSettings?: IProjectNetworkSettings;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ISpeakerReportItemOwnProps,
): ISpeakerReportItemProps => {
    return {
        ...ownProps,
        mounts: getReportMounts(storeState, ownProps.id),
        accessories: getReportAccessories(storeState, ownProps.id),
        applications: getReportApplication(storeState, ownProps.id),
        profile: getMergedProfile(storeState, ownProps.id),
        device: getSpeakerReportDevice(storeState, ownProps.id),
        scheduleNames: getScheduleNamesRecord(storeState),
        networkSettings: getCurrentProjectNetworkSettings(storeState),
    };
};

class SpeakerReportItemContainer extends React.PureComponent<ISpeakerReportItemProps> {
    public render() {
        const { deviceQuantityIndex, mounts, device, accessories, applications, networkSettings } =
            this.props;

        if (!device) {
            return null;
        }

        const mountsForTable = mounts.slice(0, -1);
        const mountsForMountingChain = [device, ...mounts];

        const displayMountingChain = mountsForMountingChain.length > 1;
        const displayInstructions =
            device.placement === 'ceiling' || (device.outdoor && device.splashProof);
        const displayAccessoryTables =
            mountsForTable.length > 0 || accessories.length > 0 || displayInstructions;
        const displayApplications = applications.length > 0;
        const displayNetwork = !!networkSettings;

        return displayMountingChain ||
            displayAccessoryTables ||
            displayApplications ||
            displayNetwork ? (
            <Box paddingY="base">
                <Stack spacing="panel" vertical>
                    {displayMountingChain && (
                        <>
                            <ReportHeading title={t.installationReportMounts} />
                            <MountingChain testId={device.model} mounts={mountsForMountingChain} />
                        </>
                    )}

                    {displayAccessoryTables && (
                        <Stack alignItems="start">
                            <Box flex="shrinkAndGrow">
                                <AccessoryTable
                                    testId={device.model}
                                    title={t.installationReportMountAccessories}
                                    items={mountsForTable}
                                />
                            </Box>
                            <Box flex="shrinkAndGrow">
                                <AccessoryTable
                                    title={t.installationReportAccessories}
                                    items={accessories}
                                />
                            </Box>
                            <Box flex="shrinkAndGrow">
                                {displayInstructions && this.renderInstructions(device)}
                            </Box>
                        </Stack>
                    )}
                    {displayApplications && (
                        <AccessoryTable
                            title={t.installationReportApplications}
                            items={applications}
                        />
                    )}
                    {displayNetwork && (
                        <NetworkItem
                            itemId={this.props.id}
                            deviceQuantityIndex={deviceQuantityIndex}
                        />
                    )}
                </Stack>
            </Box>
        ) : null;
    }

    private renderInstructions = (device: IInstallationReportSpeakerDevice) => {
        return (
            <Stack vertical testId="installation_instructions">
                {device.placement === 'ceiling' &&
                    this.renderInstruction(FACE_DOWN_IMG, t.installationReportSpeakersFaceDown)}
                {device.placement === 'ceiling' &&
                    this.renderInstruction(
                        DISTRIBUTE_IMG,
                        t.installationReportSpeakersEvenlyDistributed,
                    )}
                {device.outdoor &&
                    device.splashProof &&
                    this.renderInstruction(RAIN_PROOF_IMG, t.installationReportSpeakersRainProof)}
            </Stack>
        );
    };

    private renderInstruction = (img: string, text: string) => (
        <Box color="grey1" padding="base">
            <Stack spacing="base" alignItems="center">
                <img src={img} height="49px" width="59px" />
                <Text>{text} </Text>
            </Stack>
        </Box>
    );
}

export const SpeakerReportItem = connect(mapStateToProps)(SpeakerReportItemContainer);
