function addPrefix(val: string) {
    return `ACCESSORY_SELECTOR_${val}`;
}

export const accessorySelectorActions = {
    AddItemId: addPrefix('ADD_ITEM_ID'),
    Reset: addPrefix('RESET'),
    DeviceSearchText: addPrefix('DEVICE_SEARCH_TEXT'),
    DeviceFilter: addPrefix('DEVICE_FILTER'),
    AccessorySearchText: addPrefix('ACCESSORY_SEARCH_TEXT'),
    SetEnvironmentDevices: addPrefix('SET_ENVIRONMENT_DEVICES'),
};
