import { PiaRelationTypes, type IPiaItem, type IPiaPartnerItem } from 'app/core/pia';

/**
 *
 * @param partnerProducts Partner products that are in state fetched from DynamoDB
 * @param piaItem corresponding piaItem for parentId, null if not found or no parentId provided
 * @returns compatible partner products for parentId or all partner products if no parentId provided.
 */
export const getCompatiblePartnerProductsForPiaItem = (
    partnerProducts: IPiaPartnerItem[],
    piaItem: IPiaItem | null,
) => {
    const allPartnerProducts = partnerProducts.filter((product) => {
        const relations = product.relations ?? [];
        const implicitlyCompatibleRelations = relations.find(
            (relation) => relation.type === PiaRelationTypes.ImplicitlyCompatible,
        );
        const compatibleWith = implicitlyCompatibleRelations
            ? implicitlyCompatibleRelations.items.some(
                  (relation) => relation.id === piaItem?.parentId,
              )
            : false;
        return compatibleWith;
    });
    return allPartnerProducts;
};
