import type { Store, Middleware } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import promiseMiddleware from 'redux-promise-middleware';
import { combineModuleReducers } from './combineModuleReducers';
import type { IStoreState } from './store';
import { createLogger } from 'redux-logger';
import { errorReporterMiddleware } from './errorReporterMiddleware';
import { isProduction } from 'app/isProduction';
import { getItemLocalStorage } from './core/persistence';

export function initializeStore(): Store<IStoreState> {
    const rootReducer: any = combineModuleReducers();
    const devTools = !isProduction() && isReduxLoggerOn();
    const middleware = [promiseMiddleware, errorReporterMiddleware];

    if (devTools) {
        // redux-logger used 'redux' and not '@reduxjs/toolkit'
        middleware.push(createLogger() as Middleware);
    }

    // https://redux-toolkit.js.org/api/configureStore
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
                ...middleware,
            ),
        devTools,
    });
}

type ReduxDebugLoggerState = 'on' | '1' | 'true' | null;

function isReduxLoggerOn() {
    const reduxLoggerDefined = getItemLocalStorage<ReduxDebugLoggerState>('ReduxDebugLogger');
    if (!reduxLoggerDefined) return false;

    switch (reduxLoggerDefined.toLowerCase()) {
        case 'on':
        case '1':
        case 'true':
            return true;
        default:
            return false;
    }
}
