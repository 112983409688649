import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { Action, Text, Grid, PillButton, Stack } from 'app/components';
import { FilterPanel } from './FilterPanel';
import type { accessControlType } from '../../models';
import { defaultAccessControlFilter } from '../../models';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { isEqual } from 'lodash-es';
import { getAccessControlFilter, getIsEditMode } from '../../selectors';
import { SearchField } from './common';
import { SustainabilityFilter } from './SustainabilityFilter';

export const AccessControlFilterPanel: React.FC = () => {
    const [deviceSelectorActionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);

    const accessControlFilter = useSelector(getAccessControlFilter);

    const { selectedCategory } = accessControlFilter;

    /**
     * Updates redux state for selected category.
     * Clicking a selected category clears filter.
     * Otherwise, selected category is swapped with the new one.
     * @param filterType - The category to select/deselect
     */
    const updateSelectedCategory = (filterType: accessControlType) => {
        deviceSelectorActionService.updateAccessControlFilter({
            selectedCategory: filterType === selectedCategory ? undefined : filterType,
        });
    };

    const isEditMode = useSelector<IStoreState, boolean>((store) => getIsEditMode(store));

    /**
     * Resets access control filter to its default values.
     * Also sets includeDiscontinued to false and removes search text.
     */
    const resetFilter = () => {
        if (searchText) {
            deviceSelectorActionService.setSearchText('');
        }
        if (!isEqual(accessControlFilter, defaultAccessControlFilter)) {
            deviceSelectorActionService.updateAccessControlFilter(defaultAccessControlFilter);
        }
    };

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                {!isEditMode && (
                    <>
                        <Stack alignItems="center" justifyContent="between">
                            <Text style="semibold" color="grey6">
                                {t.cameraSelectorRequirementsTitle}
                            </Text>
                            <Action title={t.reset} onClick={resetFilter} />
                        </Stack>
                        <Grid spacing="halfQuart">
                            <PillButton
                                text={t.otherProjectDeviceSelectorCategoriesGROUP.doorControllers}
                                selected={selectedCategory === 'doorControllers'}
                                onClick={() => updateSelectedCategory('doorControllers')}
                            />
                            <PillButton
                                text={t.otherProjectDeviceSelectorCategoriesGROUP.doorStations}
                                selected={selectedCategory === 'interComs'}
                                onClick={() => updateSelectedCategory('interComs')}
                            />
                            <PillButton
                                text={t.otherProjectDeviceSelectorCategoriesGROUP.ioRelayModules}
                                selected={selectedCategory === 'ioRelays'}
                                onClick={() => updateSelectedCategory('ioRelays')}
                            />
                            <SustainabilityFilter />
                        </Grid>
                    </>
                )}
            </Stack>
        </FilterPanel>
    );
};

AccessControlFilterPanel.displayName = 'AccessControlFilterPanel';
