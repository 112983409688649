import * as React from 'react';
import { Modal } from 'app/components';
import type { Id } from 'app/core/persistence';
import { t as i18nextT } from 'i18next';
import { PartnerProductSelector } from './PartnerProductSelector';
interface IListPartnerApplicationsModalProps {
    parentId: Id;
    onClose(): void;
}
const t = (key: string) => i18nextT(key, { ns: 'partnerProducts' });

export const PartnerApplicationsModal: React.FC<IListPartnerApplicationsModalProps> = ({
    parentId,
    onClose,
}) => {
    return (
        <Modal onClose={onClose} title={t('addPartnerApplication')} color="white" minWidth="80%">
            <PartnerProductSelector onClose={onClose} parentId={parentId} />
        </Modal>
    );
};

PartnerApplicationsModal.displayName = 'PartnerApplicationsModal';
