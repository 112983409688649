import type { Icons } from 'app/components';
import { UnreachableCaseError } from 'axis-webtools-util';
import type { PiaCategory } from 'app/core/pia';
import { CategoryEnum } from '../../../quotation/Category.enum';
import type { DeviceAndSubType } from 'app/core/persistence';

export const deviceIcons = {
    toIcon(deviceType?: DeviceAndSubType): Icons {
        if (!deviceType) {
            return 'device';
        }

        switch (deviceType) {
            case 'camera':
            case 'decoder':
            case 'bodyWornCamera':
            case 'cameraExtension':
            case 'dockingStation':
                return 'device';
            case 'accessServer':
            case 'answeringUnit':
            case 'networkReader':
                return 'list_alt';
            case 'encoder':
                return 'encoder';
            case 'analogCamera':
                return 'analog_camera';
            case 'mainUnit':
            case 'systemController':
                return 'main_unit';
            case 'sensorUnit':
                return 'sensor_unit';
            case 'speaker':
                return 'speaker';
            case 'pac':
            case 'iorelay':
            case 'doorstation':
                return 'pac';
            case 'doorcontroller':
                return 'door_controller';
            case 'radardetector':
                return 'radar';
            case 'door':
                return 'door_front';
            case 'alerter':
            case 'peopleCounter':
                return 'device'; // TODO: icon for peopleCounter
            case 'relayexpmodules':
                return 'add_box';
            case 'connectivitydevice':
                return 'main_unit';
            case 'pagingconsole':
                return 'pagingconsole';
            default:
                throw new UnreachableCaseError(deviceType, `Unknown device type ${deviceType}`);
        }
    },
    categoryToIcon(category?: PiaCategory): Icons {
        if (!category) {
            return 'device';
        }

        switch (category) {
            case 'encoders':
                return 'encoder';
            case 'mainunits':
                return 'main_unit';
            case 'sensorunits':
            case 'thermalsensor':
                return 'sensor_unit';
            case 'speakers':
                return 'speaker';
            case 'doorcontrollers':
            case 'iorelays':
            case 'doorstations':
            case 'readers':
                return 'pac';
            case 'radardetectors':
                return 'radar';
            case 'accessServers':
            case 'answeringUnits':
            case 'networkReaders':
                return 'list_alt';
            case 'lenses':
                return 'lens';
            case 'acap':
                return 'extension';
            case 'connectivitydevices':
                return 'main_unit';
            case 'pagingconsole':
                return 'pagingconsole';

            default:
                return 'device';
        }
    },
    categoryEnumToIcon(categoryEnum?: CategoryEnum): Icons {
        if (!categoryEnum) {
            return 'device';
        }

        switch (categoryEnum) {
            case CategoryEnum.Encoder:
                return 'encoder';
            case CategoryEnum.AnalogCamera:
                return 'analog_camera';
            case CategoryEnum.Speaker:
                return 'speaker';
            case CategoryEnum.RecordingAndNetwork:
                return 'recording';
            case CategoryEnum.Pac:
            case CategoryEnum.DoorStation:
                return 'pac';
            case CategoryEnum.RadarDetector:
                return 'radar';
            case CategoryEnum.Accessory:
                return 'category';
            case CategoryEnum.Software:
                return 'extension';
            default:
                return 'device';
        }
    },
};
