import { injectable } from 'inversify';
import type {
    IItemEntity,
    ICameraItemEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import type { Frequency } from 'app/modules/common';
import {
    ProfileOverrideService,
    ProfileSupportService,
    BandwidthCalculatorService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import type { IExportablePersistedEntity, IExportedAxisCamera } from '../../../models';
import { isDefined } from 'axis-webtools-util';
import { BaseItemExporterService } from './BaseItemExporter.service';
import type { IPiaDevice, IPiaCamera } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';

@injectable()
export class CamerasExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDevice>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedAxisCameras = async (
        projectZipSetting: ProjectZipType,
        items: IExportablePersistedEntity[],
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
    ): Promise<IExportedAxisCamera[]> => {
        const allCameras = items.filter(this.isCameraItemEntity);

        const mappedCameras = await Promise.all(
            allCameras.map(
                async (camera) =>
                    camera &&
                    this.mapCameraToExportedAxisCamera(
                        projectZipSetting,
                        camera,
                        frequency,
                        projectBandwidthVersion,
                        projectRetentionTimeInDays,
                    ),
            ),
        );

        return mappedCameras.filter(isDefined);
    };

    private mapCameraToExportedAxisCamera = async (
        projectZipSetting: ProjectZipType,
        cameraItem: IExportablePersistedEntity,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
    ): Promise<IExportedAxisCamera | null> => {
        if (!cameraItem.productId || !cameraItem.properties.camera) {
            return null;
        }
        const cameraPiaDevice = this.getPiaDevice(cameraItem.productId) as IPiaCamera;

        const mergedProfile = await this.profileOverrideService.getMergedProfileFromDevice(
            cameraItem.properties.camera,
            projectRetentionTimeInDays,
        );

        const exportedCamera = await this.mapCameraToExportedCamera(
            projectZipSetting,
            cameraItem,
            mergedProfile,
            cameraPiaDevice.properties,
            frequency,
            projectBandwidthVersion,
            cameraItem.properties.camera.profileOverride.customBandwidth,
        );

        const cameraPiaDeviceShortName = cameraPiaDevice.name
            .replace(/\s?(50|60)\s?hz\s?/i, ' ')
            .trim();

        return {
            ...exportedCamera,
            modelName: cameraPiaDeviceShortName,
            productVariantName: cameraPiaDevice.name,
            modelShortName: cameraPiaDeviceShortName,
            piaId: cameraItem.productId,
        };
    };

    private isCameraItemEntity(item: IItemEntity): item is ICameraItemEntity {
        return deviceTypeCheckers.isCamera(item) && !deviceTypeCheckers.isDoorStation(item);
    }
}
