import * as moment from 'moment';

interface IItemWithUpdatedDate {
    updatedDate: Date;
}

export const updatedDateComparator = (a: IItemWithUpdatedDate, b: IItemWithUpdatedDate) => {
    const firstDate = moment(a.updatedDate);
    const secondDate = moment(b.updatedDate);
    return secondDate.diff(firstDate);
};

export const updatedDateComparatorReverse = (a: IItemWithUpdatedDate, b: IItemWithUpdatedDate) => {
    return updatedDateComparator(b, a);
};
