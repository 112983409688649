import { deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaBodyWornCamera } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project/selectors';
import { getPiaItemsRecord } from './getPiaDevices';
import { toCacheKey } from '../../cacheKey';

export const getBodyWornFpsSpecificResolutions = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (item, piaItemsRecord) => {
        if (!item || !item.productId || !deviceTypeCheckers.isBodyWornCamera(item)) {
            return [];
        }
        const properties =
            (piaItemsRecord[item.productId] as IPiaBodyWornCamera)?.properties ?? undefined;
        const fpsSpecificResolutions = properties.lensCalcFPS ?? [];
        return fpsSpecificResolutions;
    },
)(toCacheKey);
