import { createCachedSelector } from 're-reselect';
import type { IStoreState } from 'app/store';
import { getIdFromProps, getIdFromPropsRequired, toCacheKey } from 'app/modules/common';
import { createSelector } from 'reselect';
import type { Id, IInstallationPointSensorModel } from 'app/core/persistence';
import { getMapsState } from './getMapsState';

const getSelectedMapItem = createSelector([getMapsState], (mapsState) => {
    return mapsState.selected?.mapItem;
});

// installationPointId must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getSelectedCoverageAreaInfoProps = (
    _state: IStoreState,
    _installationPointId: Id,
    coverageAreaSensor: IInstallationPointSensorModel | undefined,
) => coverageAreaSensor;

export const getSelectedSensorId = createSelector([getMapsState], (mapsState) => {
    return mapsState.selected?.coverageAreaInfo?.sensorId;
});

export const getSelectedCoverageAreaInfo = createSelector([getMapsState], (mapsState) => {
    return mapsState.selected?.coverageAreaInfo;
});

export const getSelectedMapItemId = createSelector([getSelectedMapItem], (mapItem) => mapItem?.id);

export const getIsSensorSelected = createCachedSelector(
    [
        getSelectedMapItemId,
        getIdFromPropsRequired,
        getSelectedCoverageAreaInfoProps,
        getSelectedCoverageAreaInfo,
    ],
    (selectedMapItemId, installationPointId, coverageAreaSensor, selectedCoverageArea) => {
        const isMapItemSelected = installationPointId === selectedMapItemId;
        if (!isMapItemSelected) return false;

        return (
            !selectedCoverageArea ||
            selectedCoverageArea.sensorId === coverageAreaSensor?.sensorId ||
            coverageAreaSensor === undefined
        );
    },
)((_state, _, coverageAreaSensor) => coverageAreaSensor?.sensorId ?? '');

/**
 * Get selected map id and fallback to first street map in no selection
 *
 * This is the map that is currently shown in the leaflet view
 *
 */
export const getSelectedMapId = createSelector([getMapsState], (mapsState) => {
    return mapsState.selectedMap;
});

export const getSelectedDeviceId = createSelector([getMapsState], (mapsState) => {
    return mapsState.selected?.deviceId ?? mapsState.selected?.mapItem?.parentDeviceId;
});

export const getIsInstallationPointSelected = createCachedSelector(
    [getSelectedMapItem, getIdFromProps],
    (selectedMapItem, installationPointId) => selectedMapItem?.id === installationPointId,
)(toCacheKey);
