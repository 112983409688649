import * as React from 'react';
import { t } from 'app/translate';
import { Textarea, Box, Stack, Button } from 'app/components';

interface IContactDetailsProps {
    userApiAddress: string;
    address: string;
    onSave(address: string): void;
    onCancel(): void;
}

interface IContactDetailsState {
    unsavedAddress: string;
}

export class EditContactDetailsComponent extends React.Component<
    IContactDetailsProps,
    IContactDetailsState
> {
    constructor(props: IContactDetailsProps) {
        super(props);
        this.state = { unsavedAddress: props.address };
    }

    public componentDidUpdate(prevProps: IContactDetailsProps) {
        if (prevProps.address !== this.props.address) {
            this.setState({ unsavedAddress: this.props.address });
        }
    }

    public render() {
        const isClearable =
            this.hasUserApiAddress() && this.props.userApiAddress !== this.state.unsavedAddress;

        return (
            <Box minWidth={740}>
                <Stack vertical spacing="panel">
                    <Box display="block" width="100%" flex="shrinkAndGrow" padding="quart">
                        <Textarea
                            rows={8}
                            value={this.state.unsavedAddress}
                            changeCriteria="key"
                            onChange={(value) => this.updateAddress(value)}
                            clearIcon="reset"
                            clear={isClearable ? () => this.onClear() : undefined}
                        />
                    </Box>
                    <Box justifyContent="end">
                        <Button text onClick={() => this.props.onCancel()}>
                            {t.cancel}
                        </Button>
                        <Button
                            primary
                            onClick={() => this.props.onSave(this.state.unsavedAddress)}
                        >
                            {t.userContactDetailsSave}
                        </Button>
                    </Box>
                </Stack>
            </Box>
        );
    }

    private updateAddress(address: string) {
        this.setState({
            unsavedAddress: address,
        });
    }

    private onClear() {
        this.updateAddress(this.props.userApiAddress ?? '');
    }

    private hasUserApiAddress = () => this.props.userApiAddress !== '';
}
