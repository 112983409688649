import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds project.state and sets its value to the default: 'designing'
 */
@injectable()
export class Migration10To11 extends MigrationProviderBase {
    public from: number = 10;
    public to: number = 11;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'item') {
                if (entity.properties.camera) {
                    this.updateProfileOverride(entity.properties.camera);
                }

                if (entity.properties.analogCamera) {
                    this.updateProfileOverride(entity.properties.analogCamera);
                }

                if (entity.properties.sensorUnit) {
                    this.updateProfileOverride(entity.properties.sensorUnit);
                }
            } else if (entity.type === 'profile') {
                this.deleteOnRecordingSetting(entity.triggeredRecording);
                this.deleteOnRecordingSetting(entity.continuousRecording);
                this.deleteOnRecordingSetting(entity.liveView);

                entity.audio = {
                    liveViewEnabled: false,
                    recordingEnabled: false,
                };

                entity.zipstream = {
                    gopDefault: 32,
                    gopMax: 300,
                    gopMode: 'fixed',
                    zipStrength: 10,
                };
            }

            return entity;
        };
    }

    private updateProfileOverride(itemProperty: any) {
        this.deleteOnRecordingSetting(itemProperty.profileOverride.triggeredRecording);
        this.deleteOnRecordingSetting(itemProperty.profileOverride.continuousRecording);
        this.deleteOnRecordingSetting(itemProperty.profileOverride.liveView);

        itemProperty.profileOverride.audio = {};
        itemProperty.profileOverride.zipstream = {};
    }

    private deleteOnRecordingSetting(recordingSetting: any) {
        delete recordingSetting.gopDefault;
        delete recordingSetting.gopMax;
        delete recordingSetting.gopMode;
        delete recordingSetting.zipStrength;
        delete recordingSetting.quality;
        delete recordingSetting.audio;
    }
}
