import { Module } from 'app/ioc';
import { PiaClient, PiaItemService, PiaLocationService, PiaRelationService } from './client';
import type { interfaces } from 'inversify';
import { PiaClientFactory } from './PiaClient.factory';

type ConcretePiaClient = PiaClient<any, any>;

@Module({
    binder: (binder) => {
        binder.bindConstant<ConcretePiaClient>(PiaClient, PiaClientFactory());

        binder.bindDynamic<PiaItemService<any>>(
            PiaItemService,
            (container: interfaces.Container) => {
                const piaClient = container.get(PiaClient);
                return piaClient.items;
            },
        );

        binder.bindDynamic<PiaLocationService<any>>(
            PiaLocationService,
            (container: interfaces.Container) => {
                const piaClient = container.get(PiaClient);
                return piaClient.locations;
            },
        );

        binder.bindDynamic<PiaRelationService>(
            PiaRelationService,
            (container: interfaces.Container) => {
                const piaClient = container.get(PiaClient);
                return piaClient.relations;
            },
        );
    },
})
export class PiaModule {}
