import { createSelector } from 'reselect';
import {
    CARIBBEAN_COUNTRY_CODES,
    MIDDLE_AMERICA_COUNTRY_CODES,
    NORTH_AMERICA_COUNTRY_CODES,
    SOUTH_AMERICA_COUNTRY_CODES,
} from '../countryCodes';
import { getLocationId } from './getCurrentProject';

const eligible2NCountries = [
    ...NORTH_AMERICA_COUNTRY_CODES,
    ...SOUTH_AMERICA_COUNTRY_CODES,
    ...MIDDLE_AMERICA_COUNTRY_CODES,
    ...CARIBBEAN_COUNTRY_CODES,
    12, // Australia
    95, // Hong kong
    155, // New Zealand
    195, //Singapore
];

export const getShouldShow2NSelector = createSelector(
    [getLocationId],
    (projectCountryId): boolean => eligible2NCountries.includes(projectCountryId),
);
