import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { PiaItemMsrp, getRequiredComponentsForPiaItem } from 'app/modules/common';
import { useMount } from 'app/hooks';
import type { IAutoTestable } from 'app/components';
import {
    Box,
    DropDown,
    Text,
    Icon,
    DropDownMenuItem,
    Label,
    PiaImage,
    Clickable,
} from 'app/components';
import { t } from 'app/translate';

interface IRequiredComponentsSelectorProps extends IAutoTestable {
    piaId?: PiaId;
    onChange(requiredComponents: PiaId[]): void;
}

export const RequiredComponentsSelector: React.FC<IRequiredComponentsSelectorProps> = ({
    piaId,
    onChange,
    testId,
}) => {
    const requiredPiaItems = useSelector<IStoreState, IPiaItem[][]>((state) =>
        getRequiredComponentsForPiaItem(state, piaId),
    );

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onSelectedRequiredComponentsChanged = (index: number) => {
        setSelectedIndex(index);
        onChange(requiredPiaItems[index].map((component) => component.id));
    };

    useMount(() =>
        onChange(
            requiredPiaItems.length > 0
                ? requiredPiaItems[selectedIndex].map((component) => component.id)
                : [],
        ),
    );

    if (requiredPiaItems.length === 0) {
        return null;
    }

    return (
        <Box direction="column">
            <Label>{t.accessories}</Label>
            <DropDown
                testId={testId}
                openInPortal
                border
                fillDropdownWidth
                background="white"
                trigger={
                    <Box
                        width="100%"
                        spacing="half"
                        display="grid"
                        grid={{ gridTemplateColumns: '1fr auto' }}
                    >
                        <Text whiteSpace="nowrap">
                            {requiredPiaItems[selectedIndex].map((item) => item.name).join(', ')}
                        </Text>
                        <Icon icon="arrow_down_special" size="sm" />
                    </Box>
                }
                contents={requiredPiaItems.map((piaItemsGroup, index) => (
                    <Clickable onClick={() => onSelectedRequiredComponentsChanged(index)}>
                        <DropDownMenuItem
                            onlyContent
                            key={index}
                            selected={index === selectedIndex}
                        >
                            <Box direction="column" padding="half" spacing="quart">
                                {piaItemsGroup.map((piaItem) => (
                                    <Box
                                        key={`${piaItem.id}_${index}`}
                                        alignItems="center"
                                        spacing="base"
                                        display="grid"
                                        grid={{
                                            gridTemplateColumns:
                                                'auto minmax(min-content, 1fr) auto',
                                        }}
                                    >
                                        <PiaImage
                                            piaId={piaItem.id}
                                            icon="category"
                                            imageSize="md"
                                        />
                                        <Text>{piaItem.name}</Text>
                                        <PiaItemMsrp includeMultipacks piaId={piaItem.id} />
                                    </Box>
                                ))}
                            </Box>
                        </DropDownMenuItem>
                    </Clickable>
                ))}
            />
        </Box>
    );
};

RequiredComponentsSelector.displayName = 'RequiredComponentsSelector';
