import { injectable, multiInject } from 'inversify';
import { MigrationProviderBase } from './MigrationProviderBase';
import type { DeletableEntity } from './models';
import { AddableEntity } from './models';
import {
    MigrationUnsupportedVersionError,
    MigrationVersioningError,
    MigrationSequenceError,
    MigrationVersionToHighError,
    MigrationNotSameAsEntityVersionError,
} from './errors';
import { EntitySettings } from '../repositories/persistence/EntitySettings';
import type { IPersistenceRepository } from '../repositories/persistence';
import { eventTracking } from 'app/core/tracking';

@injectable()
export class MigrationService {
    public readonly migrations: MigrationProviderBase[];

    constructor(
        private entitySettings: EntitySettings,
        @multiInject(MigrationProviderBase) migrations: MigrationProviderBase[],
    ) {
        this.migrations = migrations.sort((cur, next) => {
            return cur.from - next.from;
        });

        this.sanityCheckMigrations();
    }

    /**
     * Migrates every entity with version < entityVersion, and persist entities with updated versions.
     * Throws an error if entity found with higher version than current
     */
    public async migrateAll(persistenceRepository: IPersistenceRepository): Promise<void> {
        const allEntities = await persistenceRepository.getAll();

        if (allEntities.some((entity) => !this.isNumber(entity.entityVersion))) {
            eventTracking.logError(
                'Trying to migrate entities without versions',
                'Migration service',
            );
        }

        const allEntitiesWithVersion = allEntities.filter((entity) =>
            this.isNumber(entity.entityVersion),
        );

        if (
            allEntitiesWithVersion.some((entity) => {
                return entity.entityVersion > this.entitySettings.version;
            })
        ) {
            if (localStorage.AED_SKIP_MIGRATION_WARNING) {
                console.warn('Skipping migration warning');
                return;
            } else {
                throw new MigrationUnsupportedVersionError(this.entitySettings.version);
            }
        }

        // Get the migration version we need to start with
        let migrateFrom = this.entitySettings.version;
        allEntitiesWithVersion.forEach((entity) => {
            migrateFrom = Math.min(entity.entityVersion, migrateFrom);
        });

        // Only run migrations that are needed
        const migrationsToRun = this.migrations.filter((migration) => {
            return migration.to > migrateFrom;
        });

        for (const migration of migrationsToRun) {
            await this.runMigration(migration, persistenceRepository);
        }
    }

    private async runMigration(
        migrationProvider: MigrationProviderBase,
        persistenceRepository: IPersistenceRepository,
    ) {
        const entitiesToDelete: any[] = [];
        const entitiesToAdd: any[] = [];
        const entitiesToUpdate: any[] = [];

        const allEntities = await persistenceRepository.getAll();
        const entitiesForMigration = allEntities.filter(
            (entity) =>
                this.isNumber(entity.entityVersion) &&
                entity.entityVersion >= migrationProvider.from &&
                entity.entityVersion < migrationProvider.to,
        );

        const migration = migrationProvider.provideMigration(persistenceRepository);

        for (const entity of entitiesForMigration) {
            let migratedEntities = await migration(entity);

            if (!Array.isArray(migratedEntities)) {
                migratedEntities = [migratedEntities];
            }

            for (const migratedEntity of migratedEntities) {
                if (this.isDeletable(migratedEntity)) {
                    entitiesToDelete.push(migratedEntity.entity);
                } else if (this.isAddable(migratedEntity)) {
                    entitiesToAdd.push(migratedEntity.entity);
                } else {
                    // Update version on entity so that next migration will run.
                    migratedEntity.entityVersion = migrationProvider.to;
                    entitiesToUpdate.push(migratedEntity);
                }
            }
        }

        // Delete entities marked for deletion.
        if (entitiesToDelete.length > 0) {
            await persistenceRepository.bulkDelete(entitiesToDelete);
        }

        // Add entities marked for addition.
        if (entitiesToAdd.length > 0) {
            await persistenceRepository.bulkAdd(entitiesToAdd);
        }

        // Persist entities, note it is important that we do this last and for all entities
        // at the same time so we avoid unnecessary revision updates that affects the
        // list of entities that we are working on.
        if (entitiesToUpdate.length > 0) {
            await persistenceRepository.bulkUpdate(entitiesToUpdate);
        }
    }

    private isNumber(value: any): value is number {
        return typeof value === 'number';
    }

    private isDeletable(entity: any): entity is DeletableEntity {
        return entity.deletable === true;
    }

    private isAddable(entity: any): entity is AddableEntity {
        return entity instanceof AddableEntity;
    }

    /**
     * Sanity check for all migrations.
     * We want to be able to rely on our migrations so this does some basic checks like we
     * migrate to the current entity version, don't have overlapping migrations, sane versioning
     * and have sequential migrations that cover all versions.
     */
    private sanityCheckMigrations() {
        let migrationVersion = this.migrations.length ? this.migrations[0].from : 0;

        this.migrations.forEach((migration) => {
            if (migration.to > this.entitySettings.version) {
                throw new MigrationVersionToHighError(migration.to, this.entitySettings.version);
            }

            if (migration.to <= migration.from) {
                throw new MigrationVersioningError(migration.from, migration.to);
            }

            if (migration.from !== migrationVersion) {
                throw new MigrationSequenceError(migrationVersion, migration.from);
            }

            migrationVersion = migration.to;
        });

        if (migrationVersion !== this.entitySettings.version) {
            throw new MigrationNotSameAsEntityVersionError(
                migrationVersion,
                this.entitySettings.version,
            );
        }
    }
}
