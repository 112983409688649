const addPrefix = (type: string) => {
    return `DOCUMENTATION_${type}`;
};

export const DocumentationActions = {
    addSelectedId: addPrefix('ADD_ID'),
    removeId: addPrefix('REMOVE_ID'),
    addSeveralIds: addPrefix('ADD_SEVERAL_IDS'),
    clearSelected: addPrefix('CLEAR_IDS'),
    deviceFilterChange: addPrefix('FILTER_CHANGE'),
    updateAccessories: addPrefix('UPDATE_ACCESSORIES'),
};
