import * as React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import type { IExtendableComponent } from '../../models';
import { extendableProps } from '../../services';
import type { Icons, IIconProps } from '../../ui/icon';
import { Icon } from '../../ui/icon';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';

const ImageStyle = css`
    image[aria-hidden='true'] {
        display: none;
    }
`;

const SmallStyle = css`
    height: 14px;
    width: 14px;
    min-height: 14px;
    min-width: 14px;
`;

const MediumSmallStyle = css`
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px;
`;

const MediumStyle = css`
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
`;

const MediumLargeStyle = css`
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
`;

const LargeStyle = css`
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
`;

const ExtraLargeStyle = css`
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
`;

const ExtraExtraLargeStyle = css`
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
`;

const ExtraExtraExtraLargeStyle = css`
    height: 100px;
    width: 100px;
    min-height: 100px;
    min-width: 100px;
`;

const MaxStyle = css`
    max-height: 100%;
    max-width: 100%;
`;

const NoPointerEventsStyle = css`
    pointer-events: none;
`;

type Size = 'sm' | 'ms' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'max';

export interface IPiaImageProps extends IExtendableComponent, IAutoTestable {
    /**
     * The PIA ID of the device to show
     */
    piaId: number | null;
    /**
     * The icon to show before the image has loaded or if no piaId is provided
     */
    icon: Icons;
    /**
     * Optionally specify the icon props for the icon
     */
    iconProps?: Omit<IIconProps, 'icon'>;
    /**
     * Sets the size of the product image, if not set inherits parents size
     */
    imageSize?: Size;
    /**
     * Will show this image instead of the icon in case that the piaId
     * is null.
     */
    imageUrlFallback?: string;
    /**
     * Disable pointer events for this image
     */
    noPointerEvents?: boolean;
}

export const PiaImage: React.FunctionComponent<IPiaImageProps> = React.memo(
    ({
        piaId,
        icon,
        imageSize,
        imageUrlFallback,
        iconProps,
        noPointerEvents,
        ...extendedProps
    }) => {
        const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
        const [shouldRenderImage, setShouldRenderImage] = React.useState<boolean>(
            piaId !== null || (piaId === null && imageUrlFallback !== undefined),
        );
        const [shouldRenderIcon, setShouldRenderIcon] = React.useState<boolean>(!shouldRenderImage);

        React.useEffect(() => {
            const hasImage = piaId !== null || (piaId === null && imageUrlFallback !== undefined);
            setShouldRenderImage(hasImage);
            setShouldRenderIcon(!hasImage);
        }, [imageUrlFallback, piaId]);

        const imageAttributes = extendableProps(
            extendedProps,
            {
                className: classNames(ImageStyle, {
                    [SmallStyle]: imageSize === 'sm',
                    [MediumSmallStyle]: imageSize === 'ms',
                    [MediumStyle]: imageSize === 'md',
                    [MediumLargeStyle]: imageSize === 'ml',
                    [LargeStyle]: imageSize === 'lg',
                    [ExtraLargeStyle]: imageSize === 'xl' || !imageSize,
                    [ExtraExtraLargeStyle]: imageSize === 'xxl',
                    [ExtraExtraExtraLargeStyle]: imageSize === 'xxxl',
                    [MaxStyle]: imageSize === 'max',
                    [NoPointerEventsStyle]: noPointerEvents === true,
                }),
            },
            true,
        );

        const srcUrl =
            piaId !== null
                ? `https://www.axis.com/images/products/${piaId}-100.png`
                : imageUrlFallback ?? '';

        return (
            <>
                {shouldRenderIcon && (
                    <Icon {...extendedProps} icon={icon} size={imageSize || 'xl'} {...iconProps} />
                )}
                {shouldRenderImage && !shouldRenderIcon && (
                    <img
                        data-test-id={toTestIdFormat(extendedProps.testId)}
                        {...imageAttributes}
                        aria-hidden={!imageLoaded}
                        src={srcUrl}
                        onLoad={() => setImageLoaded(true)}
                        onError={() => setShouldRenderIcon(true)}
                    />
                )}
            </>
        );
    },
);

PiaImage.displayName = 'PiaImage';
