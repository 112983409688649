import { isProduction } from 'app/isProduction';
import { getItemLocalStorage } from './core/persistence';
import { ServiceLocator } from './ioc';
import { ApplicationActionService } from './modules/application';

const CHECK_FOR_UPDATE_INTERVAL_MS = 1000 * 3600 * 12;

export async function initializeOfflineSupport() {
    return new Promise<void>((resolve) => {
        try {
            if (isProduction() && 'serviceWorker' in navigator) {
                console.info('[PWA] SW installation starting');

                window.addEventListener('load', () => {
                    if (getItemLocalStorage('ServiceWorkersDisabled')) {
                        console.info('[PWA] AED_DISABLE_SERVICE_WORKERS is active');
                        unregisterServiceWorkers()
                            .then((hasServiceWorkers) => {
                                if (hasServiceWorkers) {
                                    location.reload();
                                    return;
                                }
                            })
                            .finally(() => {
                                resolve();
                            });
                    } else {
                        registerServiceWorker(resolve);
                    }
                });
            } else {
                resolve();
            }
        } catch (registrationError) {
            console.info('[PWA] SW registration failed: ', registrationError);
            unregisterServiceWorkers().finally(() => {
                resolve();
            });
        }
    });
}

function registerServiceWorker(resolve: () => void) {
    navigator.serviceWorker
        .getRegistrations()
        .then((existingRegistrations) => {
            console.info('[PWA] Got registrations', existingRegistrations);
            let reload = false;

            navigator.serviceWorker
                .register('/sw.js')
                .then((registration) => {
                    console.info('[PWA] SW registered: ', registration);

                    // Finally is important here to make sure the promise will resolve
                    registration.update().finally(() => {
                        console.info('[PWA] SW update');

                        const { installing } = registration;
                        if (!installing) {
                            if (reload) {
                                // New version installed and ready, reload page
                                location.reload();
                                return;
                            }

                            console.info('[PWA] No update found');
                            resolve();

                            // Check regularly to be able to notify users that don't close their browser.
                            setInterval(
                                () => checkForUpdate(registration),
                                CHECK_FOR_UPDATE_INTERVAL_MS,
                            );

                            return;
                        }

                        listenToInstallEvents(installing, registration, resolve);
                    });
                })
                .catch((err) => {
                    console.info('[PWA] SW registration failed: ', err);
                    unregisterServiceWorkers().finally(() => {
                        resolve();
                    });
                });

            // If there are no existing registrations, continue load page
            if (existingRegistrations.length === 0) {
                console.info('[PWA] No existing registrations');
                resolve();
            }

            if (existingRegistrations.length === 1) {
                if (!existingRegistrations[0].active) {
                    console.info('[PWA] Registration is not yet active');
                    resolve();
                } else if (existingRegistrations[0].installing) {
                    console.info('[PWA] Registration is installing with an active SW');
                    reload = true;
                }
            }
        })
        .catch((err) => {
            console.info('[PWA] SW get registration failed: ', err);
            unregisterServiceWorkers().finally(() => {
                resolve();
            });
        });
}

async function checkForUpdate(registration: ServiceWorkerRegistration) {
    await registration.update();
    const { installing } = registration;
    if (installing) {
        console.info('[PWA] SW update detected');
        const applicationActionService = ServiceLocator.get(ApplicationActionService);
        applicationActionService.setUpdateAvailable();
    }
}

async function listenToInstallEvents(
    installing: ServiceWorker,
    registration: ServiceWorkerRegistration,
    resolve: () => void,
) {
    let activating = false;
    installing.onstatechange = async function () {
        switch (this.state) {
            case 'installing':
                console.info('[PWA] SW is installing...');
                break;
            case 'installed':
                console.info('[PWA] SW Install successful');
                // This is a fail switch if we decide to change our service worker solution.
                // The Workbox solution will start the 'activating' step because of the `skipWaiting` property.
                setTimeout(async () => {
                    if (!activating && registration.waiting) {
                        console.info('[PWA] SW stuck in waiting, unregister service workers...');

                        await unregisterServiceWorkers();
                        location.reload();
                    }
                }, 1000);
                break;
            case 'redundant':
                console.info('[PWA] SW Install failed (redundant)');
                await unregisterServiceWorkers();
                resolve();
                break;
            case 'activating':
                console.info('[PWA] SW is activating...');
                activating = true;
                break;
            case 'activated':
                // New version installed and ready, reload page
                location.reload();
                break;
        }
    };
}

async function unregisterServiceWorkers() {
    let hadServiceWorkers = false;
    try {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const reg of registrations) {
            await reg.unregister();
            hadServiceWorkers = true;
        }
    } catch {
        // Nothing
    }
    return hadServiceWorkers;
}
