import * as React from 'react';
import { css } from '@emotion/css';
import {
    Box,
    Button,
    Card,
    Heading,
    NoPrint,
    NumberInput,
    Select,
    Stack,
    Toggle,
    ToggleItem,
} from 'app/components';
import { ColorsEnum } from 'app/styles';
import type { IPaperSize, Orientation } from '../models';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    getCurrentPaperSize,
    getDefaultMargin,
    getOrientation,
    getPaperMargin,
    getPaperSizeTemplates,
    getSizeUnit,
} from '../selectors';
import { t } from 'app/translate';
import { useService } from 'app/ioc';
import { MapsReportsActionService } from '../state/MapsReportsAction.service';
import { MapsActionService } from 'app/modules/maps/services';
import { EmptyReport } from '../../components';
import {
    getCurrentProjectHasFloorPlans,
    getCurrentProjectId,
    getCurrentProjectName,
} from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import { Map, getMapsSortedByType, getSelectedMapOrDefault } from 'app/modules/maps';
import { nameComparator } from 'app/utils';
import type { Id } from 'app/core/persistence';

export const MapsReport: React.FunctionComponent = () => {
    const actionService = useService(MapsReportsActionService);
    const mapsActionService = useService(MapsActionService);
    const selected = useSelector(getSelectedMapOrDefault);
    const floorPlans = useSelector(getMapsSortedByType)
        .map((fp) => ({
            name: fp.name || t.map,
            text: fp.name || t.map,
            value: fp._id,
        }))
        .sort(nameComparator);
    const selectMap = (id: Id) => {
        if (!id || id === selected._id) return;
        mapsActionService.deselectItem();
        mapsActionService.setSelectedMap(id);
        mapsActionService.fitBounds(id);
    };
    React.useEffect(() => {
        // Reset map state
        mapsActionService.deselectItem();
        mapsActionService.toggleScalingTool(false);
    }, [mapsActionService]);
    const paperSizes = useSelector<IStoreState, Record<string, IPaperSize>>((store) =>
        getPaperSizeTemplates(store),
    );
    const customPaperSizeName = 'Custom';
    const paperSizeOptions = [
        ...Object.values(paperSizes).map((paper) => {
            return { text: paper.name, value: paper.name };
        }),
        { text: t.custom, value: customPaperSizeName },
    ];

    const paperSize = useSelector<IStoreState, IPaperSize>((store) => getCurrentPaperSize(store));
    const orientation = useSelector<IStoreState, Orientation>((store) => getOrientation(store));
    const defaultMargin = useSelector<IStoreState, string>((store) => getDefaultMargin(store));
    const unit = useSelector<IStoreState, string>((store) => getSizeUnit(store));
    const paperMargin = useSelector<IStoreState, number>((store) => getPaperMargin(store));

    const printContainer = css`
        max-width: 100%;
        overflow-x: auto;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

        @media print {
            overflow: visible;
            max-width: unset;
            box-shadow: none;
        }
    `;

    const paperWidth = `${paperSize.width - paperMargin}${paperSize.unit}`;
    const paperHeight = `${paperSize.height - paperMargin}${paperSize.unit}`;

    const printPaper = css`
        background: white;
        box-sizing: border-box;
        width: ${paperWidth};
        height: ${paperHeight};
        border: 1px solid ${ColorsEnum.grey3};
        padding: ${defaultMargin};

        @media print {
            margin: 0;
            box-sizing: border-box;
            overflow: hidden !important;
            border: 0;
        }
    `;

    const printWrapper = css`
        width: 100%;
        height: 100%;
        @media print {
            width: 100vw;
            height: 100vh;
            margin: -15px;
        }
    `;

    const onPaperSizeChanged = (value: string) => {
        const selectedPaperSize = paperSizes[value];
        if (selectedPaperSize && selectedPaperSize.name !== customPaperSizeName) {
            actionService.setPaperSize(selectedPaperSize);
        } else {
            actionService.setPaperSize({
                ...paperSize,
                name: customPaperSizeName,
            });
        }
    };
    const onCustomPaperSizeChanged = (newPaperSize: Partial<IPaperSize>) => {
        actionService.setPaperSize({
            ...paperSize,
            ...newPaperSize,
        });
    };

    const hasFloorPlans = useSelector<IStoreState, boolean>((state) =>
        getCurrentProjectHasFloorPlans(state),
    );

    const projectId = useSelector<IStoreState, string>((state) => getCurrentProjectId(state));
    const projectName = useSelector<IStoreState, string>((state) => getCurrentProjectName(state));

    return !hasFloorPlans ? (
        <EmptyReport
            projectId={projectId}
            projectName={projectName}
            text={t.clickToAddMap}
            link="maps"
            actionText={t.addFloorPlan}
        />
    ) : (
        <Box direction="column" alignItems="center" testId="panel_reports_maps_panel">
            <NoPrint>
                <Box paddingBottom="base">
                    <Card
                        notFullWidth
                        color="white"
                        paddingX="base"
                        paddingY="base"
                        direction="column"
                    >
                        <Heading align="center">{t.printSettings}</Heading>
                        <Box paddingTop="base" spacing="panel" display="grid">
                            <Box alignItems="start">
                                <Select
                                    fillWidth
                                    label={t.floorPlans}
                                    options={floorPlans}
                                    value={selected._id}
                                    onChange={selectMap}
                                    dropDownMinWidth={282}
                                />
                            </Box>
                            <Box direction="column" spacing="base">
                                <Select
                                    fillWidth
                                    label={t.paperSize}
                                    options={paperSizeOptions}
                                    value={paperSize.name}
                                    onChange={onPaperSizeChanged}
                                    dropDownMinWidth={282}
                                />
                                {paperSize.name === customPaperSizeName && (
                                    <Stack>
                                        <NumberInput
                                            label="Width"
                                            unit={unit}
                                            value={paperSize.width}
                                            changeCriteria="key"
                                            onChange={(value) =>
                                                onCustomPaperSizeChanged({ width: value })
                                            }
                                        />
                                        <NumberInput
                                            label="Height"
                                            unit={unit}
                                            value={paperSize.height}
                                            changeCriteria="key"
                                            onChange={(value) =>
                                                onCustomPaperSizeChanged({ height: value })
                                            }
                                        />
                                    </Stack>
                                )}
                                <Box>
                                    <Toggle>
                                        <ToggleItem
                                            id="id1"
                                            title={t.landscape}
                                            onClick={() =>
                                                actionService.setOrientation('landscape')
                                            }
                                            active={orientation === 'landscape'}
                                            disabled={paperSize.name === customPaperSizeName}
                                        />
                                        <ToggleItem
                                            id="id2"
                                            title={t.portrait}
                                            onClick={() => actionService.setOrientation('portrait')}
                                            active={orientation === 'portrait'}
                                            disabled={paperSize.name === customPaperSizeName}
                                        />
                                    </Toggle>
                                </Box>
                            </Box>
                        </Box>
                        <Box width="100%" justifyContent="end" paddingTop="base">
                            <Button primary onClick={onPrint}>
                                {t.print}
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </NoPrint>
            <div className={printContainer}>
                <div className={printPaper}>
                    <div className={printWrapper}>
                        <Map showWelcome={false} readOnly={true} />
                    </div>
                </div>
            </div>
        </Box>
    );
};

const onPrint = () => {
    print();
    eventTracking.pushToGA4('SiteDesignerClick', 'Print', 'Maps');
};
