import type { ReducersMapObject } from 'redux';
import { combineReducers } from 'redux';
import { ModuleRegistry } from 'app/ioc';

export function combineModuleReducers() {
    const allReducers: ReducersMapObject = {};

    ModuleRegistry.getModules().forEach((module) => {
        const moduleReducers = module.options.reducers;

        if (moduleReducers) {
            Object.keys(moduleReducers).forEach((key) => {
                if (key in allReducers) {
                    throw Error(`Reducer with name '${key}' already exists`);
                }

                allReducers[key] = (moduleReducers as any)[key];
            });
        }
    });

    return combineReducers({
        ...allReducers,
    });
}
