import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/css';
import { UnreachableCaseError } from 'axis-webtools-util';
import { t } from 'app/translate';
import type { Icons } from 'app/components';
import { Box, DropDown, Icon, Positioned, Text } from 'app/components';
import type { SyncStatus } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { getSyncStatus, getLastSyncTimeAgo } from './syncSelectors';
import { getIsLocalProject } from '../project';
import type { IStoreState } from 'app/store';

const opacityAnimationStyle = css`
    opacity: 1;
    animation: opacityAnimation 2s ease infinite;
    @keyframes opacityAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
`;

const getStatusIcon = (syncStatus: SyncStatus): Icons => {
    switch (syncStatus) {
        case 'in_sync':
            return 'cloud_done_small';
        case 'syncing':
            return 'cloud_queue_small';
        case 'not_in_sync':
            return 'cloud_off_small';
        case 'off':
            return 'cloud_disabled_small';
        case 'sync_failed':
            return 'cloud_warning_small';
        default:
            throw new UnreachableCaseError(syncStatus);
    }
};

const getStatusColor = (syncStatus: SyncStatus): Colors => {
    switch (syncStatus) {
        case 'in_sync':
        case 'syncing':
        case 'not_in_sync':
        case 'off':
            return 'grey5';
        case 'sync_failed':
            return 'yellow5';
        default:
            throw new UnreachableCaseError(syncStatus);
    }
};

const getStyle = (syncStatus: SyncStatus) =>
    syncStatus === 'syncing' ? opacityAnimationStyle : '';

export const SyncIndicator: React.FC = () => {
    const syncStatus = useSelector(getSyncStatus);
    const icon = getStatusIcon(syncStatus);
    const color = getStatusColor(syncStatus);
    const style = getStyle(syncStatus);

    const triggerElement = (
        <div className={style}>
            <Positioned position="relative" top="-4px">
                <Icon color={color} opaque size="xs" icon={icon} />
            </Positioned>
        </div>
    );

    return <DropDown trigger={triggerElement} contents={<TimeAgoContent />} />;
};

const TimeAgoContent: React.FC = () => {
    const isLocalProject = useSelector(getIsLocalProject);
    const timeAgo = useSelector<IStoreState, string>((state) =>
        getLastSyncTimeAgo(state, Date.now()),
    );

    return (
        <Box padding="base">
            <Text whiteSpace="nowrap">
                {isLocalProject ? t.localProjectsNoSync : `${t.lastSync}: ${timeAgo}`}
            </Text>
        </Box>
    );
};

SyncIndicator.displayName = 'SyncIndicator';
