import { CategoryEnum, VendorEnum, getProductsWithPrices, toCacheKey } from 'app/modules/common';
import { createSelector } from 'reselect';
import type { QuotationSection } from '../models';
import { createCachedSelector } from 're-reselect';
import { getSectionFromProps } from './getSectionFromProps';

/** Finds out which section, if any, that should display MSRP disclaimer.
 *  If no selected product has MSRP, return undefined.
 *  Otherwise return the bottom most rendered section with a product that has MSRP. */
const getSectionForMsrpDisclaimer = createSelector(
    [getProductsWithPrices],
    (products): QuotationSection | undefined => {
        if (
            products.some(
                (product) =>
                    product.category === CategoryEnum.InstallationService && product.msrp?.price,
            )
        ) {
            return 'installationServices';
        }
        if (
            products.some(
                (product) =>
                    [VendorEnum.Other, VendorEnum.twoN, VendorEnum.Genetec].includes(
                        product.vendor,
                    ) && product.msrp?.price,
            )
        ) {
            return 'other';
        }
        if (products.some((product) => product.vendor === VendorEnum.Axis && product.msrp?.price)) {
            return 'axis';
        }
        return undefined;
    },
);

/** Checks if a specific quotation section should render MSRP disclaimer */
export const getSectionHasMsrpDisclaimer = createCachedSelector(
    [getSectionForMsrpDisclaimer, getSectionFromProps],
    (sectionWithMsrpDisclaimer, sectionFromProps): boolean =>
        sectionWithMsrpDisclaimer === sectionFromProps,
)(toCacheKey);
