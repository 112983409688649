import { isDiscontinued } from 'app/core/pia';
import type { ISelectableProduct } from './../models/ISelectableProduct';
import type { IStoreState } from 'app/store';
import { nameComparator } from 'app/utils';
import { getPiaItems } from 'app/modules/common';
import { createSelector } from 'reselect';
import { getPartnerConfigProducts } from './getDevices';
import {
    isCategoryInCategoryGroup,
    isCategoryNotInCategoryGroup,
    isCategorySupportedGroup,
} from '../services/CategoryGroups.service';

const getSearchFilter = (state: IStoreState) => state.partnerConfig.searchFilter;

export const getAxisPiaItems = createSelector([getPiaItems], (piaItems) => {
    return piaItems.filter((item) => item.properties.vendor.toLowerCase() === 'axis');
});

export const getSearchResult = createSelector(
    [getAxisPiaItems, getPartnerConfigProducts, getSearchFilter],
    (axisPiaItems, partnerProducts, searchFilter) => {
        return axisPiaItems
            .filter(
                (item) =>
                    isCategoryInCategoryGroup(item.category, searchFilter.category) &&
                    (searchFilter.searchText.length > 0
                        ? item.name.toLowerCase().includes(searchFilter.searchText.toLowerCase())
                        : true),
            )
            .map((item) => {
                return {
                    ...item,
                    selected: partnerProducts.findIndex((product) => product.id === item.id) >= 0,
                } as ISelectableProduct;
            })
            .filter((item) => (item.selected ? true : !isDiscontinued(item)))
            .sort(nameComparator);
    },
);

// Get search results for all other categories than the selected one but only if search-field is > 0
export const getSearchResultAllExceptCategory = createSelector(
    [getAxisPiaItems, getPartnerConfigProducts, getSearchFilter],
    (axisPiaItems, partnerProducts, searchFilter) => {
        return searchFilter.searchText.length > 0
            ? axisPiaItems
                  .filter(
                      (item) =>
                          isCategorySupportedGroup(item.category) &&
                          isCategoryNotInCategoryGroup(item.category, searchFilter.category) &&
                          (searchFilter.searchText.length > 0
                              ? item.name
                                    .toLowerCase()
                                    .includes(searchFilter.searchText.toLowerCase())
                              : true),
                  )
                  .map((item) => {
                      return {
                          ...item,
                          selected:
                              partnerProducts.findIndex((product) => product.id === item.id) >= 0,
                      } as ISelectableProduct;
                  })
                  .filter((item) => (item.selected ? true : !isDiscontinued(item)))
                  .sort(nameComparator)
            : [];
    },
);
