import type { PiaId, IPiaItem } from 'app/core/pia';
import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { IWithChildren } from 'app/components';
import { Box, PiaImage, Stack, Text } from 'app/components';
import { getPiaItemsRecord } from 'app/modules/common';

interface IDeviceItemProps extends IWithChildren {
    piaId: PiaId;
    selected?: boolean;
}

export const DeviceItem: React.FC<IDeviceItemProps> = ({ piaId, selected, children }) => {
    const product = useSelector<IStoreState, IPiaItem>((store) => getPiaItemsRecord(store)[piaId]);

    return (
        <Box
            justifyContent="between"
            alignItems="center"
            color={selected ? 'yellow1' : undefined}
            paddingY="base"
        >
            <Stack alignItems="center">
                <PiaImage icon="device" piaId={product.id} />
                <Text style="semibold">{product.name}</Text>
            </Stack>
            {children}
        </Box>
    );
};

DeviceItem.displayName = 'DeviceItem';
