import { injectable } from 'inversify';
import { t } from 'app/translate';
import type { Id, IInstallationPointEntity } from 'app/core/persistence';
import {
    ItemService,
    CoreItemProductIdUpdateError,
    DuplicationService,
    InstallationPointService,
} from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';
import { ModalService } from 'app/modal';

@injectable()
export class ProjectDevicesService {
    constructor(
        private itemService: ItemService,
        private modalService: ModalService,
        private duplicationService: DuplicationService,
        private installationPointService: InstallationPointService,
    ) {}

    /**
     * Make unique means that the installation point @installationPoint belonging to device with @param deviceId will
     * instead be copied to a new device with same properties as the old one and be removed from the old one (and hence
     * the quantity will be decreased by one for the old device)
     * @param installationPoint installation point entity
     * @param deviceId id of device that installation point was child to
     * @param deviceQuantity quantity for the device the installation point should be removed from
     */
    public async makeUnique(
        installationPoint: IInstallationPointEntity,
        deviceId: Id,
        deviceQuantity: number,
    ) {
        const newItem = await this.duplicationService.copyItem(deviceId, 1);
        await this.installationPointService.duplicateInstallationPointToDevice(
            installationPoint._id,
            newItem[0]._id,
            installationPoint.name ?? '',
            installationPoint.location,
        );

        await this.installationPointService.removeInstallationPoint(
            installationPoint._id,
            installationPoint._rev,
        );
        await this.itemService.updateItem(deviceId, {
            quantity: deviceQuantity - 1,
        });
    }

    public async changeModel(itemId: Id, piaId: PiaId) {
        try {
            await this.itemService.updateItem(itemId, {
                productId: piaId,
                replaceWithBareboneId: null,
            });
        } catch (error) {
            if (error instanceof CoreItemProductIdUpdateError) {
                let confirmationTextHeader = t.removeAccessoriesConfirmationGROUP.header;
                let confirmationTextBody = t.removeAccessoriesConfirmationBody;

                if (error.hasSensorUnits()) {
                    confirmationTextHeader = error.hasAccessoriesOrApplications()
                        ? t.removeSensorUnitsAndAccessoriesConfirmationGROUP.header
                        : t.removeSensorUnitsConfirmationGROUP.header;

                    confirmationTextBody = error.hasAccessoriesOrApplications()
                        ? t.removeSensorUnitsAndAccessoriesConfirmationBody
                        : t.removeSensorUnitsConfirmationGROUP.body;
                }

                const confirm = await this.modalService.createConfirmDialog({
                    header: confirmationTextHeader,
                    body: confirmationTextBody,
                    cancelButtonText: t.cancel,
                    confirmButtonText: t.change,
                })();

                // If the user clicked cancel we should not continue
                if (!confirm) {
                    return;
                }

                await Promise.all(
                    error.affectingRelations.map((relation) =>
                        this.itemService.deleteItem(relation.childId),
                    ),
                );

                await this.changeModel(itemId, piaId);
            } else {
                throw error;
            }
        }
    }
}
