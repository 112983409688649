import { eventTracking } from 'app/core/tracking';
import { UserApiCommunicator, X_AXIS_USER_API_AUTH } from 'app/core/persistence';
import { injectable } from 'inversify';
import type { ICouchDBInfo } from '../models';

/**
 * IMPORTANT!
 * CompactionStatus is also used within the compaction lambdas in aws-lambdas.
 * Changes to the type must also be updated in the other files.
 */
export type CompactionStatus = 'Available' | 'Initiated' | 'Success' | 'Fail' | 'SeriousError';
const COMPACT_URL = '/couchdb';

@injectable()
export class CouchDBCompactCommunicator {
    constructor(private userApiCommunicator: UserApiCommunicator) {}

    public async compact(): Promise<boolean> {
        const compactResponse = await this.makeRequest('compact', 'POST', 'compact() failed');
        return compactResponse ? true : false;
    }

    public async getCompactStatus(): Promise<CompactionStatus | null> {
        const statusResponse = await this.makeRequest(
            'compact',
            'GET',
            'getCompactStatus() failed',
        );
        if (statusResponse !== null) {
            const status = (await statusResponse.json()) as CompactionStatus;
            return status;
        }
        return null;
    }

    public async getDBInfo(): Promise<ICouchDBInfo | null> {
        const dbInfoResponse = await this.makeRequest('dbinfo', 'GET', 'getDBInfo() failed');
        if (dbInfoResponse !== null) {
            const dbInfo = (await dbInfoResponse.json()) as ICouchDBInfo;
            return dbInfo;
        }
        return null;
    }

    private async makeRequest(
        endpoint: string,
        method: string,
        errorMessage: string,
    ): Promise<Response | null> {
        const token = await this.userApiCommunicator.fetchUserToken();
        const headers = new Headers();
        headers.append(X_AXIS_USER_API_AUTH, `Bearer ${token?.accessToken}`);

        try {
            const response = await fetch(`${COMPACT_URL}/${endpoint}`, {
                credentials: 'include',
                headers,
                method,
            });
            return response;
        } catch (error) {
            eventTracking.logError(`${errorMessage} - ${error}`, 'Optimization');
            return null;
        }
    }
}
