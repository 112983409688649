import * as React from 'react';
import { Text } from '../../text';
import { useState } from 'react';
import { Box } from '../../containers';
import { Clickable } from '../../functional';
import { Icon } from '../icon';
import type { IExtendableComponentWithChildren } from '../../models';

type BannerType = 'info' | 'warning';
type ColorObject = {
    background: 'blue' | 'red';
    text: 'white';
};

const bannerStyle: Record<BannerType, ColorObject> = {
    info: {
        background: 'blue',
        text: 'white',
    },
    warning: {
        background: 'red',
        text: 'white',
    },
};

interface IBannerProps extends IExtendableComponentWithChildren {
    /**
     * The message displayed on the banner.
     */
    text?: string;
    /**
     * Determines the color theme and which icon to be used in the banner. Defaults to info if omitted.
     */
    type?: 'info' | 'warning';
}

/**
 * A banner to display important information to the user.
 */
export const Banner: React.FunctionComponent<IBannerProps> = ({
    text,
    type = 'info',
    children,
}) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <Clickable onClick={() => setExpanded(!expanded)}>
            <Box color={bannerStyle[type].background} paddingX="base" alignItems="center">
                <Icon color={bannerStyle[type].text} icon={type} opaque size="sm"></Icon>
                <Box
                    maxHeight={expanded ? 'inherit' : 24}
                    paddingX="base"
                    paddingY={expanded ? 'base' : 'quart'}
                >
                    <Text color={bannerStyle[type].text}>{children || text || `${type}!`}</Text>
                </Box>
                <Icon
                    testId="btn_arrow_banner_collapse"
                    icon={expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    color={bannerStyle[type].text}
                    opaque
                />
            </Box>
        </Clickable>
    );
};

Banner.displayName = 'Banner';
