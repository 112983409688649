import * as React from 'react';
import { Border, Box, Text } from 'app/components';
import type { Colors } from 'app/styles';

interface ITextCircleProps {
    /**
     * The text to print on the badge
     */
    label: string;

    /**
     * Color of border {@see Colors}
     */
    borderColor?: Colors;

    /**
     * Width in px of the border
     */
    borderWidth?: number;

    /**
     * Color of text {@see Colors}
     */
    textColor?: Colors;
}

/**
 * @example
 *  <TextCircle
 *      borderColor="grey4"
        borderWidth={3}
 *      textColor="grey6"
 *      label="Hello World!"
 *  />
 */
export const TextCircle: React.FC<ITextCircleProps> = ({
    borderColor = 'grey4',
    borderWidth = 3,
    textColor = 'grey6',
    label,
}) => (
    <Border color={borderColor} width={borderWidth}>
        <Box
            justifyContent="center"
            alignItems="center"
            height="40px"
            width="40px"
            minHeight="40px"
            minWidth="40px"
            borderRadius="circle"
            color="white"
            flex="none"
        >
            <Text inline style="headline" color={textColor}>
                {label}
            </Text>
        </Box>
    </Border>
);

TextCircle.displayName = 'TextCircle';
