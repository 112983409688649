import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';
import { getFloorPlans, getAllMaps, getSelectedMapOrDefault } from './getFloorPlans';
import { getSelectedFloorPlan } from './getSelectedFloorPlan';
import { getOffset, isDefined } from 'axis-webtools-util';
import {
    createDerotationTransform,
    createRotationTransform,
    getCurrentProjectBlockersArray,
    getCurrentProjectFreeTextPointsArray,
    getFloorPlanGeoLocation,
    getFloorPlanGeoLocationWithFallback,
    getIdFromPropsRequired,
    isGeoLocated,
} from 'app/modules/common';
import { isGlobalEntity } from '../utils';

/**
 * Return the geolocation changes for each floor plan
 */
export const getFloorPlanGeolocations = createSelector([getMapsState], (state) => {
    return state.floorPlanGeoLocationChanges;
});

/**
 * Return the id of the floor plan that is currently being geolocated
 */
export const getFloorPlanToGeolocateId = createSelector([getMapsState], (state) => {
    return state.floorPlanToGeolocate;
});

export const getPendingGeolocation = createSelector(
    [getFloorPlanToGeolocateId, getFloorPlanGeolocations],
    (id, geolocations) => {
        return id && geolocations[id];
    },
);

/**
 * Return whether the floor plan geolocation tool is enabled
 */
export const getShowGeoLocationTool = createSelector(
    [getFloorPlanToGeolocateId],
    (floorPlanToGeolocate) => floorPlanToGeolocate !== null,
);

/**
 * Return whether the default geolocation of the project has changed
 */
export const getDefaultGeoLocationChanged = createSelector([getMapsState], (state) => {
    return state.defaultGeoLocationChanged;
});

/**
 * Return the changed geolocations for each floor plan on the map
 */
export const getGeolocationChanges = createSelector(
    [getFloorPlanGeolocations, getAllMaps],
    (geolocations, floorPlans) => {
        return Object.keys(geolocations)
            .map((id) => {
                const geoLocation = geolocations[id];
                const floorPlan = floorPlans[id];

                if (!floorPlan?.image || !geoLocation) return;

                const oldGeoLocation = getFloorPlanGeoLocation(floorPlan);

                if (!oldGeoLocation) return;

                const offset = getOffset(oldGeoLocation.position)(geoLocation.position);
                const scale = geoLocation.width / oldGeoLocation.width;
                const rotation = geoLocation.angle - oldGeoLocation.angle;

                if (offset[0] === 0 && offset[1] === 0 && scale === 1 && rotation === 0) return;

                const change = {
                    offset,
                    scale,
                    rotation,
                };

                return {
                    floorPlan,
                    geoLocation,
                    change,
                };
            })
            .filter(isDefined);
    },
);

/**
 * Return the stored geolocation for the selected floor plan
 */
export const getSelectedFloorPlanGeoLocation = createSelector(
    [getSelectedFloorPlan],
    (floorPlan) => {
        return floorPlan?.image?.geoLocation;
    },
);

/**
 * Return a transformation function that derotates the map by the selected floor plan's rotation
 */
export const getDerotationTransform = createSelector(
    [getSelectedFloorPlan],
    createDerotationTransform,
);

export const getDerotationTransformForFloorPlan = createSelector(
    [getIdFromPropsRequired, getAllMaps],
    (id, floorPlans) => {
        return createDerotationTransform(floorPlans[id]);
    },
);

/**
 * Return a transformation function that rotates the map by the selected floor plan's rotation.
 * The inverse of the derotation transform.
 */
export const getRotationTransform = createSelector([getSelectedFloorPlan], createRotationTransform);

/**
 * Return the angle by which the current floor plan has to be derotated in order to
 * show it upright
 */
export const getDerotationAngle = createSelector(
    [getSelectedFloorPlanGeoLocation],
    (selectedFpGeoLocation) => {
        return -(selectedFpGeoLocation?.angle ?? 0);
    },
);

/**
 * Return the angle by which changes to a derotated floor plan have to be rotated in order map
 * them to the real world
 */
export const getRotationAngle = createSelector(
    [getSelectedFloorPlanGeoLocation],
    (selectedFpGeoLocation) => {
        return selectedFpGeoLocation?.angle ?? 0;
    },
);

/**
 * Return the floor plans that have a geolocation or are currently being geolocated
 * extended with the pending geolocations
 */
export const getGeolocatedFloorPlans = createSelector(
    [getFloorPlans, getFloorPlanGeolocations],
    (floorPlans, pendingGeoLocations) => {
        return (
            floorPlans
                // only keep geolocated or geolocating floor plans
                .filter(({ _id, image }) => !!image?.geoLocation || !!pendingGeoLocations[_id])
                .map((fp) => {
                    if (fp.image?.geoLocation) {
                        return fp;
                    } else {
                        // extend with the pending geolocation
                        return {
                            ...fp,
                            image: fp.image && {
                                ...fp.image,
                                geoLocation: pendingGeoLocations[fp._id],
                            },
                        };
                    }
                })
        );
    },
);

/**
 * Return a list of all floor plans with their pending geolocations if available
 */
export const getFloorPlansArrayWithPendingGeolocation = createSelector(
    [getGeolocatedFloorPlans, getSelectedMapOrDefault, getShowGeoLocationTool],
    (geoLocatedFloorPlans, selectedMap, isGeolocating) => {
        if (isGeoLocated(selectedMap) || isGeolocating) {
            return geoLocatedFloorPlans;
        } else if (selectedMap) {
            // if we have an unlocated floor plan, extend it with the default location
            // This is required in order to be able to place installation points and
            // blockers on unlocated maps
            return [
                {
                    ...selectedMap,
                    image: selectedMap.image && {
                        ...selectedMap.image,
                        geoLocation: getFloorPlanGeoLocationWithFallback(selectedMap),
                    },
                },
            ];
        } else {
            return [];
        }
    },
);

/**
 * Get the free text points on all geo maps.
 */
export const getGeolocatedFreeTextPoints = createSelector(
    [getCurrentProjectFreeTextPointsArray],
    (allFreeTextPoints) => {
        return allFreeTextPoints.filter((freeTextPoint) => isGlobalEntity(freeTextPoint));
    },
);

/**
 * Get the blockers on all geo maps.
 */
export const getGeolocatedBlockers = createSelector(
    [getCurrentProjectBlockersArray],
    (allBlockers) => {
        return allBlockers.filter((blocker) => isGlobalEntity(blocker));
    },
);
