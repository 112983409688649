import type { IDistributors, IDistributor, IPrices } from 'app/modules/msrp';
import { msrpService } from 'app/modules/msrp';
import { injectable } from 'inversify';
import type { IPriceList } from '../../models/IPriceList';

interface IDistributorPriceList {
    distributor: IDistributor;
    getPrices: Promise<IPrices>;
}

@injectable()
export class MsrpService {
    public getMsrp = async (distributors: IDistributors): Promise<IPriceList[] | null> => {
        try {
            const distributorPriceLists: IDistributorPriceList[] = distributors.list.reduce(
                (distributorPriceList: IDistributorPriceList[], distributor: IDistributor) => {
                    const existingPriceList = distributorPriceList.find(
                        (d) => d.distributor.priceList === distributor.priceList,
                    );
                    if (existingPriceList) {
                        return [
                            ...distributorPriceList,
                            { getPrices: existingPriceList.getPrices, distributor },
                        ];
                    }

                    return [
                        ...distributorPriceList,
                        { getPrices: msrpService.getPrices(distributor), distributor },
                    ];
                },
                [],
            );

            const priceLists = distributorPriceLists.map(async (distributorPriceList) => ({
                ...(await distributorPriceList.getPrices),
                locid: distributorPriceList.distributor.locid,
            }));

            return await Promise.all(priceLists);
        } catch {
            return null;
        }
    };

    public getCurrencies = () => {
        try {
            return msrpService.getCurrencies();
        } catch {
            return null;
        }
    };

    public getDistributors = async (): Promise<IDistributors | null> => {
        try {
            return await msrpService.getDistributors();
        } catch {
            return null;
        }
    };

    public convertPriceToCurrency = (
        msrp: number,
        currentCurrencyCode: string,
        newCurrencyCode: string,
    ): Promise<number | null> => {
        return msrpService.convertPriceToCurrency(msrp, currentCurrencyCode, newCurrencyCode);
    };

    public getUserAuthStatus = (): Promise<boolean> => msrpService.isAuthorized();
}
