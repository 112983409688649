function addPrefix(actionName: string) {
    return `USER_PROJECTS_LIST_${actionName}`;
}

export const UserProjectsActions = {
    INITIALIZE: addPrefix('INITIALIZE'),
    GET_DEEP_PROJECT_DATA: addPrefix('GET_DEEP_PROJECT_DATA'),
    GET_MULTIPLE_DEEP_PROJECT_DATA: addPrefix('GET_MULTIPLE_DEEP_PROJECT_DATA'),
    ADD_PROJECT: addPrefix('ADD_PROJECT'),
    USER_PROJECTS_FILTER_CHANGE: addPrefix('USER_PROJECTS_FILTER_CHANGE'),
    DELETE_USERPROJECT: addPrefix('DELETE_USERPROJECT'),
    MULTI_DELETE_USERPROJECTS: addPrefix('MULTI_DELETE_USERPROJECTS'),
    MULTI_ARCHIVE_USERPROJECTS: addPrefix('MULTI_ARCHIVE_USERPROJECTS'),
    SHOW_ARCHIVED_PROJECTS: addPrefix('SHOW_ARCHIVED_PROJECTS'),
    IMPORT_PROJECT: 'IMPORT_PROJECT',
    EXPORT_PROJECT: 'EXPORT_PROJECT',
    SET_ORDER: addPrefix('SET_ORDER'),
    SELECT_PROJECT: addPrefix('SET_PROJECT_SELECTED'),
    UNSELECT_PROJECT: addPrefix('SET_PROJECT_UNSELECTED'),
    CLEAR_SELECTED_PROJECTS: addPrefix('CLEAR_SELECTED_PROJECTS'),
    SET_CURRENT_VIEW: addPrefix('SET_CURRENT_VIEW'),
    CLEAR_USER_PROJECTS: addPrefix('CLEAR_USER_PROJECTS'),
    SET_USER_PROJECTS_LOADED: addPrefix('SET_USER_PROJECTS_LOADED'),
    SET_ORGANIZATIONS: addPrefix('SET_ORGANIZATIONS'),
    SET_SHARED_PROJECT_INFO: addPrefix('SET_SHARED_PROJECT_INFO'),
    SET_SELECTED_ORGANIZATION: addPrefix('SET_SELECTED_ORGANIZATION'),
    REPLICATE: addPrefix('REPLICATE'),
};
