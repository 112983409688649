function addPrefix(val: string) {
    return `DEVICE_SELECTOR_${val}`;
}

export const DeviceSelectorActions = {
    SetSearchText: addPrefix('SET_SEARCH_TEXT'),
    SetFilterPanelExpanded: addPrefix('SET_FILTER_PANEL_EXPANDED'),
    SetSortOrder: addPrefix('SET_SORT_ORDER'),
    SetDeviceGroupInfo: addPrefix('SET_DEVICE_GROUP_INFO'),
    FilterChange: addPrefix('FILTER_CHANGE'),
    ResetFilters: addPrefix('RESET_FILTERS'),
    SetDefaultFilters: addPrefix('SET_DEFAULT_FILTERS'),
    SetCameraFilter: addPrefix('SET_CAMERA_FILTER'),
    SetSpeakerFilter: addPrefix('SET_SPEAKER_FILTER'),
    UpdateDesiredCamera: addPrefix('UPDATE_DESIRED_CAMERA'),
    UpdateCameraFilter: addPrefix('UPDATE_CAMERA_FILTER'),
    UpdateDesiredSensorUnit: addPrefix('UPDATE_DESIRED_SENSOR_UNIT'),
    UpdateSensorUnitFilter: addPrefix('UPDATE_SENSOR_UNIT_FILTER'),
    SetIsOperationalTemperatureFilterUsed: addPrefix('SET_IS_OPERATIONAL_TEMPERATURE_FILTER_USED'),
    UpdateMainUnitFilter: addPrefix('UPDATE_MAIN_UNIT_FILTER'),
    UpdateEncoderFilter: addPrefix('UPDATE_ENCODER_FILTER'),
    UpdateAccessControlFilter: addPrefix('UPDATE_ACCESS_CONTROL_FILTER'),
    UpdateWearablesFilter: addPrefix('UPDATE_WEARABLES_FILTER'),
    UpdateOtherDeviceFilter: addPrefix('UPDATE_OTHER_DEVICE_FILTER'),
    UpdateTwoNFilter: addPrefix('UPDATE_2N_FILTER'),
    SetSelectedProductId: addPrefix('SET_SELECTED_PRODUCT_ID'),
    ToggleDiscontinued: addPrefix('TOGGLE_DISCONTINUED'),
    SetEditDeviceId: addPrefix('SET_EDIT_DEVICE_ID'),
    SetParentId: addPrefix('SET_PARENT_ID'),
    AddOrUpdateDevice: addPrefix('ADD_OR_UPDATE_DEVICE'),
    AddOrUpdateChildDevice: addPrefix('ADD_OR_UPDATE_CHILD_DEVICE'),
    UpdateSpeakerFilter: addPrefix('UPDATE_SPEAKER_FILTER'),
    ResetSpeakerFilter: addPrefix('RESET_SPEAKER_FILTER'),
    SetTopRecommendationVisible: addPrefix('SET_TOP_RECOMMENDATION_VISIBLE'),
    SetBigScene3dVisible: addPrefix('SET_BIG_SCENE_3D_VISIBLE'),
    AddToCompare: addPrefix('ADD_TO_COMPARE'),
    RemoveFromCompare: addPrefix('REMOVE_FROM_COMPARE'),
    ShowComparison: addPrefix('SHOW_COMPARISON'),
    ClearCompare: addPrefix('CLEAR_COMPARE'),
    SetProductToAdd: addPrefix('SET_PRODUCT_TO_ADD'),
    SetIsAddingOrUpdating: addPrefix('SET_IS_ADDING_OR_UPDATING'),
    SetSelectedQuickAddDevice: addPrefix('SET_SELECTED_QUICK_ADD_DEVICE'),
};
