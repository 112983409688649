/*
Main entry point for client application
*/
import 'reflect-metadata';
import 'drag-drop-touch';
import 'element-scroll-polyfill';
import 'intersection-observer';

import * as React from 'react';
import i18next from 'i18next';
import i18n from './i18n';
import { createRoot } from 'react-dom/client';
import { ServiceLocator } from './ioc';
import { App } from './routes/App.container';
import { AppStore } from './store';
import { initializeStore } from './initializeStore';
import { initializePardotIfProd, stripIds } from './initializeAnalytics';
import { Provider } from 'react-redux';
import { initializeOfflineSupport } from './initializeOfflineSupport';
import type { LanguageCode } from './utils';
import { languageCodeToTextLanguage } from './utils';
import * as moment from 'moment';
import { eventTracking } from './core/tracking';
import { AppConstants } from './AppConstants';
import './routes';
import './GlobalModule';
import './modules/modulesLoader';
import './styles/globalStyles';
import './styles/globalStylesAdditions';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { BrowserRouter } from 'react-router-dom';
import { NavigateSetter } from './NavigateSetter';

i18next.on('loaded', () => {
    const selectedLanguage = i18next.language as LanguageCode;
    const textLanguage = languageCodeToTextLanguage[selectedLanguage];

    moment.locale(textLanguage);
});

i18next.on('failedLoading', (_lng: string, _ns: string, msg: string) => {
    console.error(`i18next failed loading: ${msg}`);
});

initializeOfflineSupport().finally(() => {
    ServiceLocator.initialize();

    i18n.then(() => {
        renderDOM();
    }).catch(() => {
        console.error('i18n failed loading');
        renderDOM();
    });

    // Logs CSP errors to Matomo as a fatal error
    document.addEventListener('securitypolicyviolation', (e) => {
        let blockedURI: string;

        try {
            // Only log the origin to easier merge them in GA
            blockedURI = new URL(e.blockedURI).origin;
        } catch {
            // blockedURI was not a valid URL - just send the raw format
            blockedURI = e.blockedURI;
        }

        eventTracking.logError(
            [
                AppConstants.appVersion,
                blockedURI,
                e.violatedDirective,
                stripIds(e.documentURI),
                e.referrer || 'no referrer',
                (e as any).disposition,
                e.originalPolicy,
            ].join(' - '),
            'SecurityPolicyViolation',
        );
    });

    async function renderDOM() {
        const store = initializeStore();

        initializePardotIfProd();

        const appStore = ServiceLocator.get(AppStore);
        appStore.setStore(store);
        const container = document.getElementById('app');
        const root = createRoot(container!);

        root.render(
            <Provider store={store}>
                <BrowserRouter>
                    <NavigateSetter />
                    <App />
                </BrowserRouter>
            </Provider>,
        );
    }
});
