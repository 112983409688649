import * as React from 'react';
import type { IClickableProps } from 'app/components';
import {
    Title,
    Border,
    Box,
    Icon,
    Spacer,
    Stack,
    DropDown,
    DropDownMenuButton,
    Text,
} from 'app/components';

import type { IMapsDevice } from '../../../../../models';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { ServiceLocator } from 'app/ioc';
import type {
    AnalyticMode,
    AnalyticRangeDayCondition,
    AnalyticRangeLightCondition,
    AnalyticRangeWeatherCondition,
    IDeviceAnalyticRange,
} from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';
import { analyticSettings, getAnalyticsInfo } from './AnalyticsInformation';
import type { IApplicationItem } from 'app/modules/common';
import {
    getAnalyticRangeForItem,
    getAnalyticRangeLightCondition,
    getPiaItem,
    getPiaItemsRecord,
    HelpButton,
    isValidAcap,
    isApd,
    hasApdParams,
    hasApdParamsBoth,
} from 'app/modules/common';
import {
    getCompatibleRangeApplications,
    getPersistedApplications,
} from 'app/modules/addonSelector';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemDetectorCategory } from 'app/core/pia';

import { nameComparator } from 'app/utils';
import { isDefined } from 'axis-webtools-util';

import { ApplicationDetectionRange } from './ApplicationDetectionRange';
import { Acap, getDescriptiveText } from './Acap';
import { type IRangeComponentApplication } from './IRangeComponentApplication';
import { isFeatureEnabled } from 'app/featureFlags';
import { LightConditionsSelector } from './LightConditionsSelector';
import {
    AXIS_OBJECT_ANALYTICS,
    AXIS_PERIMETER_DEFENDER,
    RANGE_APPLICATION_IDS,
} from 'app/core/common';
import { WeatherConditionsSelector } from './WeatherConditionsSelector';
import { eventTracking } from 'app/core/tracking';

const APD_BLOCKED_PROD_ID = 22681;
const BLOCKED_APPLICATION_IDS = [APD_BLOCKED_PROD_ID];

interface IDeviceAnalyticRanges extends IClickableProps {
    device: IMapsDevice;
}

export const DeviceAnalyticRangesComponent: React.FunctionComponent<IDeviceAnalyticRanges> = ({
    device,
}) => {
    const compatibleAcaps = useSelector<IStoreState, IApplicationItem[]>((store) =>
        getCompatibleRangeApplications(store, device.id).sort(nameComparator),
    );

    const selectedApplications = useSelector<IStoreState, IApplicationItem[]>((store) =>
        getPersistedApplications(store, device.id).sort(nameComparator),
    );
    const isRadarCombined = Boolean(
        device.piaProduct?.categories.includes(PiaItemDetectorCategory.RADARDETECTORS),
    );

    const piaDevice = useSelector<IStoreState, IPiaItem | undefined>((store) =>
        device.productId ? getPiaItemsRecord(store)[device.productId] : undefined,
    );

    const lightCondition = useSelector<IStoreState, AnalyticRangeLightCondition>((store) =>
        getAnalyticRangeLightCondition(store, device.id),
    );

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, device.id),
    );

    const getRangeApplicationsToShow = (
        rangeApplications: IApplicationItem[],
        mapsDevice: IMapsDevice,
    ): IRangeComponentApplication[] => {
        const rangeApplicationsToShow: IRangeComponentApplication[] = [];

        rangeApplications.forEach((acap) => {
            if (
                acap.acapId === AXIS_OBJECT_ANALYTICS &&
                mapsDevice.piaProduct?.categories.includes(PiaItemDetectorCategory.RADARDETECTORS) // not isRadarCombined since need dependency to device in effect hook
            ) {
                rangeApplicationsToShow.push({
                    ...acap,
                    zone: 'fusion',
                });
                rangeApplicationsToShow.push({
                    ...acap,
                    zone: 'radar',
                });
            } else {
                rangeApplicationsToShow.push({
                    ...acap,
                });
            }
        });
        return rangeApplicationsToShow;
    };

    const analyticRange = useSelector<IStoreState, IDeviceAnalyticRange | undefined>((store) =>
        getAnalyticRangeForItem(store, device.id),
    );

    const getStartIndex = React.useCallback(
        (applications: IRangeComponentApplication[]) => {
            return analyticRange && analyticRange.applicationId
                ? applications.findIndex(
                      (acap) =>
                          acap.acapId === analyticRange.applicationId &&
                          analyticRange.zone === acap.zone,
                  ) + 1
                : 0;
        },
        [analyticRange],
    );

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [rangeApplicationsToShow, setRangeApplicationsToShow] = useState<
        IRangeComponentApplication[]
    >([]);

    const application = selectedIndex > 0 ? rangeApplicationsToShow[selectedIndex - 1] : undefined;

    React.useEffect(() => {
        const filteredRangeApplications = compatibleAcaps
            .map((acap) => {
                if (
                    acap.acapId &&
                    isValidAcap(acap, RANGE_APPLICATION_IDS, BLOCKED_APPLICATION_IDS)
                ) {
                    // only show perimeter defender acap if parameters exist in PIA
                    if (isApd(acap.acapId)) {
                        if (hasApdParams(piaDevice)) {
                            return acap;
                        }
                    } else {
                        return acap;
                    }
                }
            })
            .filter(isDefined);
        const acapsToShow = getRangeApplicationsToShow(filteredRangeApplications, device);

        const startIndex = getStartIndex(acapsToShow);
        setSelectedIndex(startIndex);
        setRangeApplicationsToShow(acapsToShow);
    }, [device, getStartIndex, selectedIndex, analyticRange, compatibleAcaps, piaDevice]);

    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));

    const isApplicationAdded = (applicationId: number | undefined) => {
        return (
            selectedApplications.find((selectedApp) => selectedApp.acapId === applicationId) !==
            undefined
        );
    };

    const setAnalyticApplication = async (index: number) => {
        if (index > 0) {
            eventTracking.logUserEvent(
                'Maps',
                'Set analytic application',
                rangeApplicationsToShow[index - 1].name,
            );
            const applicationId = rangeApplicationsToShow[index - 1].acapId;
            if (applicationId) {
                //set selected application to show range for
                if (
                    applicationId === AXIS_OBJECT_ANALYTICS ||
                    applicationId === AXIS_PERIMETER_DEFENDER
                ) {
                    await itemService.addAnalyticRangeValues(
                        device.id,
                        applicationId,
                        !!piaItem && hasApdParamsBoth(piaItem),
                        rangeApplicationsToShow[index - 1].zone, // only used for radar so far (Oxxo)
                    );
                }
                await itemService.setAnalyticRangeApplicationId(device.id, applicationId);
            } else {
                // if no application selected set selected application id to undefined
                await itemService.setAnalyticRangeApplicationId(device.id, undefined);
            }
        } else {
            // no application selected
            await itemService.setAnalyticRangeApplicationId(device.id, undefined);
        }
    };

    if (rangeApplicationsToShow.length === 0) {
        return null;
    }

    // first index represents no selected application
    const selectedAcap = selectedIndex > 0 ? rangeApplicationsToShow[selectedIndex - 1] : undefined;
    const analyticsInfo = getAnalyticsInfo(
        selectedAcap?.acapId,
        isRadarCombined && device.model ? device.model : undefined,
    );

    const onSelectLightCondition = (selectedLightCondition: AnalyticRangeLightCondition) => {
        if (application?.acapId) {
            eventTracking.logUserEvent('Maps', 'Change light conditions', application.name);
            itemService.updateAnalyticRange(device.id, {
                applicationId: application.acapId,
                activeTypes: ['vehicle', 'person'],
                zone: application.zone,
                lightCondition: selectedLightCondition,
                analyticMode: analyticRange?.analyticMode,
                dayCondition: analyticRange?.dayCondition,
                weatherCondition: analyticRange?.weatherCondition,
            });
        }
    };

    const onSelectWeatherCondition = (selectedWeatherCondition: AnalyticRangeWeatherCondition) => {
        if (application?.acapId) {
            eventTracking.logUserEvent('Maps', 'Change fog conditions', application.name);
            itemService.updateAnalyticRange(device.id, {
                applicationId: application.acapId,
                activeTypes: ['vehicle', 'person'],
                zone: application.zone,
                lightCondition: analyticRange?.lightCondition,
                analyticMode: analyticRange?.analyticMode,
                dayCondition: analyticRange?.dayCondition,
                weatherCondition: selectedWeatherCondition,
            });
        }
    };

    const onSelectDayCondition = (selectedDayCondition: AnalyticRangeDayCondition) => {
        if (application?.acapId) {
            eventTracking.logUserEvent('Maps', 'Change day conditions', application.name);
            itemService.updateAnalyticRange(device.id, {
                applicationId: application.acapId,
                activeTypes: ['vehicle', 'person'],
                zone: application.zone,
                lightCondition: analyticRange?.lightCondition,
                analyticMode: analyticRange?.analyticMode,
                dayCondition: selectedDayCondition,
                weatherCondition: analyticRange?.weatherCondition,
            });
        }
    };

    const onSelectAnalyticMode = (selection: AnalyticMode) => {
        if (application?.acapId) {
            eventTracking.logUserEvent('Maps', 'Change analytic mode in maps', application.name);
            itemService.updateAnalyticRange(device.id, {
                applicationId: application.acapId,
                activeTypes: ['vehicle', 'person'],
                zone: application.zone,
                lightCondition: analyticRange?.lightCondition,
                analyticMode: selection,
                dayCondition: analyticRange?.dayCondition,
                weatherCondition: analyticRange?.weatherCondition,
            });
        }
    };

    return (
        <Stack vertical spacing="half">
            <Stack justifyContent="center" spacing="half">
                {analyticsInfo && !isRadarCombined && <Spacer horizontal customSpacing="18px" />}
                <Text style="semibold" align="center" color="grey7" lineHeight={1.5}>
                    {t.applicationDetectionRangeTitle}
                </Text>
                {analyticsInfo && (
                    <HelpButton
                        modalTitle={analyticsInfo.analyticName}
                        modalInfo={analyticsInfo.analyticInfo}
                        manualLink={analyticsInfo.analyticManualLink}
                        linkText={t.readManual}
                        productInfo={analyticsInfo.productInfo}
                        productManualLink={analyticsInfo.productManualLink}
                        additionalInfo={analyticsInfo.additionalInfo}
                    />
                )}
            </Stack>
            <Border color="grey3" radius="8px">
                <Stack vertical spacing="none" lineBetweenColor="grey3">
                    <DropDown
                        minWidth={255}
                        trigger={
                            <ApplicationDetectionRange
                                application={rangeApplicationsToShow[selectedIndex - 1]}
                                isRadarCombined={isRadarCombined}
                            />
                        }
                        contents={
                            <>
                                <DropDownMenuButton
                                    key={0}
                                    label={t.applicationNone}
                                    selected={0 === selectedIndex}
                                    onClick={() => setAnalyticApplication(0)}
                                />
                                {rangeApplicationsToShow.map((acap, index) => {
                                    return (
                                        <DropDownMenuButton
                                            testId={`application_${getDescriptiveText(acap.zone)}`}
                                            key={index + 1}
                                            selected={index + 1 === selectedIndex}
                                            onClick={() =>
                                                isApplicationAdded(acap.acapId) &&
                                                setAnalyticApplication(index + 1)
                                            }
                                            piaImageProps={{
                                                icon: 'extension',
                                                piaId: acap.productId,
                                                imageSize: 'md',
                                            }}
                                        >
                                            <Acap
                                                application={acap}
                                                isRadarCombined={isRadarCombined}
                                                isApplicationAdded={isApplicationAdded(acap.acapId)}
                                                showToggleButton={index !== undefined}
                                                deviceId={device.id}
                                                onSelectApplication={async () => {
                                                    setAnalyticApplication(index + 1);
                                                }}
                                            />
                                        </DropDownMenuButton>
                                    );
                                })}
                            </>
                        }
                    />
                    {application?.acapId === AXIS_PERIMETER_DEFENDER &&
                        piaItem &&
                        hasApdParamsBoth(piaItem) && (
                            <DropDown
                                testId="analytic_mode_selector"
                                minWidth={255}
                                trigger={
                                    <Box paddingY="half" paddingX="half" width="100%">
                                        <Stack
                                            justifyContent="between"
                                            alignItems="center"
                                            flex="shrinkAndGrow"
                                            spacing="half"
                                            width="100%"
                                        >
                                            <Stack spacing="half">
                                                <Title title={t.analyticModeDescription}>
                                                    <Icon opaque icon="settings" color="grey4" />
                                                    <Box maxWidth="130px" alignItems="center">
                                                        <Text
                                                            style="caption"
                                                            color="grey7"
                                                            testId="analytic_mode_selector_value"
                                                        >
                                                            {analyticRange?.analyticMode === 'ai'
                                                                ? t.analyticModeAi
                                                                : t.analyticModeCalibration}
                                                        </Text>
                                                    </Box>
                                                </Title>
                                            </Stack>

                                            <Icon opaque icon="keyboard_arrow_down" color="grey4" />
                                        </Stack>
                                    </Box>
                                }
                                contents={analyticSettings.map((setting) => (
                                    <DropDownMenuButton
                                        label={
                                            setting === 'ai'
                                                ? t.analyticModeAi
                                                : t.analyticModeCalibration
                                        }
                                        selected={analyticRange?.analyticMode === setting}
                                        onClick={() => {
                                            onSelectAnalyticMode(setting);
                                        }}
                                        testId={`select_analytic_mode_${setting}`}
                                    />
                                ))}
                            />
                        )}

                    {isFeatureEnabled('aoa_light_conditions') &&
                        application?.acapId === AXIS_OBJECT_ANALYTICS && (
                            <LightConditionsSelector
                                itemId={device.id}
                                selectedLightCondition={lightCondition}
                                onSelect={onSelectLightCondition}
                            />
                        )}
                    {application?.acapId === AXIS_PERIMETER_DEFENDER && (
                        <WeatherConditionsSelector
                            itemId={device.id}
                            onSelectWeather={onSelectWeatherCondition}
                            onSelectDay={onSelectDayCondition}
                        />
                    )}
                </Stack>
            </Border>
        </Stack>
    );
};

DeviceAnalyticRangesComponent.displayName = 'DeviceAnalyticRangesComponent';
