import type { IItemEntity, IPersistence, IProjectNetworkSettings } from 'app/core/persistence';
import { IPv4, RangedSet, Validator } from 'ip-num';
import { createSelector } from 'reselect';
import { getCurrentProjectNetworkSettings } from '../../project';
import { getCurrentProjectItemsWithNetworkSettings } from './getCurrentProjectItemsWithNetworkSettings';

export const getCurrentProjectNbrRequiredIPAddresses = createSelector(
    [getCurrentProjectNetworkSettings, getCurrentProjectItemsWithNetworkSettings],
    (projectSettings, itemsWithSettings) =>
        getNbrAddressesInUse(projectSettings, itemsWithSettings),
);

export const getNbrAddressesInUse = (
    projectSettings: IProjectNetworkSettings | undefined,
    itemsWithSettings: IPersistence<IItemEntity>[],
) => {
    if (!projectSettings || projectSettings.dhcp) {
        return 0;
    }

    const ipEnd = projectSettings?.ipEnd;
    const ipStart = projectSettings?.ipStart;
    if (!ipEnd || !ipStart) {
        return 0;
    }

    let rangedSet;
    try {
        rangedSet = RangedSet.fromRangeString(
            `${projectSettings.ipStart}-${projectSettings.ipEnd}`,
        );
    } catch (e) {
        // Not supported network range;
        return 0;
    }

    const rangeStart = rangedSet.getFirst();
    const rangeEnd = rangedSet.getLast();

    const isWithinRange = (address: string) => {
        // IPv4.fromDecimalDottedString crashes if address is not valid. Hence we return false early instead.
        if (!Validator.isValidIPv4String(address)[0]) {
            return false;
        }
        const ipv4Address = IPv4.fromDecimalDottedString(address);
        return (
            rangeStart.isLessThanOrEquals(ipv4Address) &&
            rangeEnd.isGreaterThanOrEquals(ipv4Address)
        );
    };

    const addressSet = new Set<string>([]);
    //* Project settings default router is already accounted for in getNbrTotalAvailableIPAddresses
    if (projectSettings?.defaultRouter) {
        addressSet.add(projectSettings.defaultRouter);
    }

    const assignedInRange = itemsWithSettings.reduce((sum, item) => {
        if (!item.networkSettings) {
            return sum;
        }
        const count = item.networkSettings?.reduce((itemAddressCount, setting) => {
            if (!setting.addresses) {
                return itemAddressCount;
            }
            let settingsCount = 0;
            setting.addresses.forEach((address) => {
                if (setting.addresses && !addressSet.has(address) && isWithinRange(address)) {
                    addressSet.add(address);
                    settingsCount++;
                }
            });
            if (
                setting.defaultRouter &&
                !addressSet.has(setting.defaultRouter) &&
                isWithinRange(setting.defaultRouter)
            ) {
                addressSet.add(setting.defaultRouter);
                settingsCount++;
            }
            return itemAddressCount + settingsCount;
        }, 0);
        return sum + count;
    }, 0);

    return assignedInRange;
};
