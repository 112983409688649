import * as React from 'react';
import type { Icons, IIconProps, ITextProps } from 'app/components';
import { Stack, Box, Icon, Text } from 'app/components';

interface ILoginIconTextProps {
    icon?: Icons;
    iconProps?: Omit<IIconProps, 'icon'>;
    nameProps?: ITextProps;
    companyNameProps?: ITextProps;
    name: string;
    companyName?: string;
}

export const LoginIconText: React.FunctionComponent<ILoginIconTextProps> = ({
    icon,
    iconProps,
    nameProps,
    companyNameProps,
    name,
    companyName,
}) => {
    return (
        <Stack spacing={'quart'} alignItems={'center'}>
            <Icon {...iconProps} icon={icon} />
            <Box direction="column" testId="signed_in">
                <Text {...nameProps}>{name}</Text>
                {companyName && <Text {...companyNameProps}>{companyName}</Text>}
            </Box>
        </Stack>
    );
};

LoginIconText.displayName = 'LoginIconText';
