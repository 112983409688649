import type { PartnerSystemItemCategory } from '../../../partner/types/PartnerSystemItemCategory';
import type { PiaAccessoryCategory } from './PiaAccessoryCategory.enum';
import type { PiaItemRecorderCategory } from './PiaItemRecorderCategory.enum';

export const isSingleQuantityCategory = (
    category: string | undefined,
): category is
    | PiaItemRecorderCategory
    | PiaAccessoryCategory.NETWORKSWITCHES
    | PartnerSystemItemCategory.SERVER =>
    category === 'recorders2' ||
    category === 'desktopterminals' ||
    category === 'networkswitches' ||
    category === 'partnerServers';
