import { createSelector } from 'reselect';
import { getUserProjectsState } from './getUserProjectsState';
import type { IStoreState } from 'app/store';

const getCurrentlySelectedOrganization = (state: IStoreState) =>
    state.userProjects.selectedOrganization;

export const getOrganizations = createSelector(
    [getUserProjectsState],
    (userProjectsState) => userProjectsState.organizations,
);

export const getHasOrganizations = createSelector(
    [getOrganizations],
    (organizations) => organizations.length > 0,
);

export const getSharedProjectTreeInfo = createSelector(
    [getUserProjectsState],
    ({ sharedProjectTreeInfo }) => sharedProjectTreeInfo,
);

export const getSelectedOrganization = createSelector(
    [getCurrentlySelectedOrganization, getSharedProjectTreeInfo],
    (selectedOrganization, sharedProjectTreeInfo) =>
        selectedOrganization ?? sharedProjectTreeInfo?.organization,
);
