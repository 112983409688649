import { css } from '@emotion/css';
import React from 'react';
import { SpacingsEnum } from 'app/styles';
import { Box } from 'app/components';
import type { IInstallationPointEntity, IItemEntity, IPersistence } from 'app/core/persistence';
import { getSortedInstallationPointsForDevice } from 'app/modules/maps';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { InstallationPointListItem } from './InstallationPointListItem.component';

interface IDeviceListIpTableProps {
    item: IPersistence<IItemEntity>;
}

export const DeviceListIpTable: React.FC<IDeviceListIpTableProps> = ({ item }) => {
    const devicesTableStyle = css`
        display: grid;
        grid-template-columns: auto minmax(0, 3fr) auto;
        height: fit-content;
        width: 100%;
        gap: 0 ${SpacingsEnum.cell};
    `;

    const installationPoints = useSelector<IStoreState, IInstallationPointEntity[]>((state) =>
        getSortedInstallationPointsForDevice(state, item._id),
    );
    return (
        <Box width="100%" key="outerBox01">
            <div className={devicesTableStyle}>
                {installationPoints.map((ip, index) => (
                    <InstallationPointListItem ip={ip} device={item} rowIndex={index} key={index} />
                ))}
            </div>
        </Box>
    );
};

DeviceListIpTable.displayName = 'DeviceListIpTable';
