import * as React from 'react';
import { t } from 'app/translate';
import { Login } from './Login.component';
import { UndoRedo } from './UndoRedo';
import {
    Box,
    DropDownMenu,
    DropDownMenuButton,
    Modal,
    Text,
    ProgressBar,
    Linkable,
} from 'app/components';
import { AppConstants } from 'app/AppConstants';
import { AppTitle } from './AppTitle.container';
import { About } from '../about/About.component';
import { FeedbackModal } from './FeedbackModal.component';
import { LanguageSelector } from './LanguageSelector.component';
import { eventTracking } from 'app/core/tracking';
import { ColorsEnum, colorToRgba, SpacingsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { NeedHelpMenu } from './NeedHelpMenu';
import type { IPartnerHeaderStyle } from 'app/core/persistence';
import { isErrorType } from 'app/core/persistence';
import { VersionUpdateNotifier } from './VersionUpdateNotifier';
import { saveAs } from 'file-saver';

const logo = require('src/assets/images/axislogo@2x.png');
const TERMS_OF_USE_PATH = '/terms-of-use/';

export interface IAppHeaderComponentProps {
    basePath: string;
    isSignedIn?: boolean;
    partnerLogo?: string;
    showAxisLogo: boolean;
    progressPercent: number;
    partnerHeaderStyle?: IPartnerHeaderStyle;
}

interface IAppHeaderState {
    showAboutModal: boolean;
    showFeedbackModal: boolean;
    showLearnMoreModal: boolean;
}

export class AppHeaderComponent extends React.Component<IAppHeaderComponentProps, IAppHeaderState> {
    constructor(props: IAppHeaderComponentProps) {
        super(props);
        this.state = {
            showAboutModal: false,
            showFeedbackModal: false,
            showLearnMoreModal: false,
        };
    }

    public render() {
        const headerStyle = this.getHeaderStyle();
        const userSettingsStyle = this.getUserSettingsStyle();

        return (
            <>
                {this.state.showAboutModal && (
                    <Modal title={'AXIS Site Designer'} onClose={this.closeAbout}>
                        <About />
                    </Modal>
                )}

                {this.state.showFeedbackModal && (
                    <FeedbackModal sendButtonDisabled onClose={this.closeFeedback} />
                )}

                <header className={headerStyle}>
                    <Box
                        alignItems="center"
                        justifyContent="between"
                        flex="shrinkAndGrow"
                        paddingX="base"
                        minWidth="0"
                    >
                        <AppTitle />
                        {this.props.partnerLogo && (
                            <Box spacing="base" flex="grow" testId="partner_page">
                                <Box alignItems="center">
                                    <Text
                                        style="semibold"
                                        colorOverride={
                                            this.props.partnerHeaderStyle?.foregroundColor
                                        }
                                    >
                                        {t.partnerConfigurationPartnerSiteFor}
                                    </Text>
                                </Box>
                                <img
                                    src={this.props.partnerLogo}
                                    height={AppConstants.logoHeight}
                                />
                            </Box>
                        )}
                        {this.props.showAxisLogo && (
                            <img height={AppConstants.logoHeight} src={logo} />
                        )}
                    </Box>
                    <div className={userSettingsStyle}>
                        <Box spacing="base" alignItems="center">
                            <UndoRedo />
                            <NeedHelpMenu />
                            <Login />
                            <LanguageSelector />
                            <VersionUpdateNotifier />
                            <DropDownMenu
                                testId="select_ddm_hdr_more"
                                testIdChild="ddm_hdr_more"
                                icon="more_vert"
                                iconProps={{
                                    opaque: true,
                                    color: 'yellowText',
                                    colorOverride: this.props.partnerHeaderStyle?.foregroundColor,
                                }}
                            >
                                <DropDownMenuButton
                                    testId="btn_about_in_ddm_hdr_more"
                                    label={t.about}
                                    icon="info"
                                    iconProps={{
                                        color: 'black',
                                    }}
                                    onClick={this.showAbout}
                                />
                                <DropDownMenuButton
                                    label={t.feedback}
                                    icon="feedback"
                                    iconProps={{
                                        color: 'black',
                                    }}
                                    onClick={this.showFeedback}
                                />
                                <DropDownMenuButton
                                    label={t.learnMore}
                                    icon="description"
                                    iconProps={{
                                        color: 'black',
                                    }}
                                    onClick={this.saveLearnMore}
                                />
                                <Linkable link={TERMS_OF_USE_PATH}>
                                    <DropDownMenuButton
                                        testId="terms_of_use_in_ddm"
                                        label={t.termsOfUse}
                                        icon="grading"
                                        iconProps={{
                                            color: 'black',
                                        }}
                                        onClick={this.showTermsOfUse}
                                    />
                                </Linkable>
                            </DropDownMenu>
                        </Box>
                    </div>

                    <ProgressBar percent={this.props.progressPercent} />
                </header>
            </>
        );
    }

    private getHeaderStyle = () => {
        const textColor = this.props.partnerHeaderStyle?.foregroundColor;

        const headerStyleBackgroundColor =
            this.props.partnerHeaderStyle?.backgroundColor || ColorsEnum.white;

        return css`
            display: flex;
            align-content: space-between;
            box-shadow: 0 1px 3px 1px rgba(70, 70, 70, 0.12);
            color: ${textColor};
            min-height: 60px;
            background-color: ${headerStyleBackgroundColor};
            z-index: ${AppConstants.headerDepth};

            @media print {
                display: none !important;
            }
        `;
    };

    private getUserSettingsStyle = () => {
        const userSettingsBackgroundColor =
            this.props.partnerHeaderStyle?.backgroundColor || ColorsEnum.yellow;

        const backgroundColor = this.props.partnerHeaderStyle?.backgroundColor || 'white 49%';
        // transparentColor is needed because Safari render transparent different from Chrome.
        const transparentColor = this.props.partnerHeaderStyle?.backgroundColor
            ? colorToRgba(this.props.partnerHeaderStyle?.backgroundColor, 0)
            : 'rgba(255, 255, 255, 0)';

        return css`
            background-color: ${userSettingsBackgroundColor};
            display: flex;
            padding-inline-end: ${SpacingsEnum.base};
            align-items: center;

            &:before {
                content: ' ';
                width: 60px;
                height: 100%;
                background: linear-gradient(
                    to bottom right,
                    ${backgroundColor},
                    ${transparentColor} 50%
                );
                [dir='rtl'] & {
                    background: linear-gradient(
                        to bottom left,
                        ${backgroundColor},
                        ${transparentColor} 50%
                    );
                }
            }
        `;
    };

    private showAbout = () => {
        eventTracking.logUserEvent('Application', 'Show About');
        this.setState({ showAboutModal: true });
    };

    private closeAbout = () => this.setState({ showAboutModal: false });

    private showFeedback = () => this.setState({ showFeedbackModal: true });

    private closeFeedback = () => this.setState({ showFeedbackModal: false });

    private saveLearnMore = async () => {
        eventTracking.logUserEvent('Application', 'Show Learn More');
        const fetchString = 'assets/pdf/AXIS-Site-Designer-Product-Overview.pdf';
        try {
            await fetch(fetchString, {
                method: 'GET',
            })
                .then((response) => {
                    return response.blob();
                })
                .then((file) => {
                    saveAs(file, `AXIS-Site-Designer-Product-Overview.pdf`);
                });
        } catch (error) {
            if (isErrorType(error)) {
                throw error;
            }
        }
    };

    private showTermsOfUse = () => {
        eventTracking.logUserEvent('Application', 'Show Terms Of Use');
    };
}
