import * as React from 'react';
import { t } from 'app/translate';
import { IconButton, Modal, Box, Button, Stack, Text } from 'app/components';
import type { IAutoTestable } from 'app/components';

interface IArchiveHelpButtonProps extends IAutoTestable {
    header: string;
    body: string;
    hoverText?: string;
}

export const ArchiveHelpButton: React.FC<IArchiveHelpButtonProps> = ({ header, body }) => {
    const [showModal, setShowModal] = React.useState(false);

    const onClose = () => setShowModal(false);

    return (
        <>
            <IconButton
                testId="btn_help"
                icon="help"
                size="ms"
                onClick={() => setShowModal(true)}
            />
            {showModal && (
                <Modal onClose={onClose} title={header} maxWidth="465px">
                    <Box paddingY="half">
                        <Text>{body}</Text>
                    </Box>
                    <Stack justifyContent="end">
                        <Button text onClick={onClose}>
                            {t.close}
                        </Button>
                    </Stack>
                </Modal>
            )}
        </>
    );
};

ArchiveHelpButton.displayName = 'ArchiveHelpButton';
