import { css } from '@emotion/css';
import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../../models';
import {
    extendableProps,
    windowService,
    isReactElement,
    renderReactChildren,
    isReactComponent,
} from '../../services';

const printStyle = css`
    @media print {
        box-shadow: none !important;
        max-width: 100% !important;
        max-height: 100% !important;
        border-radius: 0 !important;
        background-color: white !important;
        width: 100% !important;
        height: 100% !important;
    }
`;
export interface ICloseableProps extends IExtendableComponentWithChildren {
    /**
     * If you already have a ref in your component
     * you should use this prop instead of the ref
     * on the child.
     */
    innerRef?: React.RefObject<HTMLElement>;
    /**
     * Don't allow this window to close by clicking outside
     * or hitting `escape`.
     */
    persistent?: boolean;
    /**
     * Will close if the user tries to scroll the browser window
     */
    closeOnScroll?: boolean;
    /**
     * Set an explicit parent element to ensure that clicking the parent
     * will close/toggle the window correctly. Useful for modals/portal rendering
     */
    parentRef?: React.RefObject<HTMLElement>;
    /**
     * Shadows on closables are autogenerated. You can use this property to set your own shadow.
     */
    customShadow?: string;
    /**
     * The method to call when the element should be closed.
     */
    close(): void;
}

interface ICloseableState {
    zIndex: number;
}

/**
 * Use this component to add an element or component
 * to the window service, thereby allowing it to be
 * closed by clicking outside of it or pressing `escape`.
 * The window will automatically get a z-index and a
 * box shadow based on the z-index.
 */
export class Closeable extends React.Component<ICloseableProps, ICloseableState> {
    private innerRef: React.RefObject<HTMLElement> = this.props.innerRef || React.createRef();
    private scrollableParents: HTMLElement[] = [];

    constructor(props: ICloseableProps) {
        super(props);
        this.state = { zIndex: 0 };
    }

    public componentDidMount() {
        windowService.addWindow(
            this.innerRef,
            this.props.close,
            this.props.persistent,
            this.props.parentRef,
        );
        this.setState({ zIndex: windowService.getDepth(this.innerRef) });

        if (this.props.closeOnScroll) {
            this.addScrollListeners();
        }
    }

    public componentWillUnmount() {
        windowService.removeWindow(this.innerRef);

        if (this.props.closeOnScroll) {
            this.removeScrollListeners();
        }
    }

    public render() {
        const child = React.Children.only(this.props.children);
        const style: React.HtmlHTMLAttributes<HTMLElement>['style'] = {
            zIndex: this.state.zIndex,
            boxShadow:
                this.props.customShadow ||
                `0 10px ${this.state.zIndex * 2 + 10}px -2px rgba(0, 0, 0, 0.2)`,
        };

        const attributes = isReactComponent(child)
            ? extendableProps(child.props, { style, className: printStyle })
            : extendableProps(
                  { __htmlAttributes: isReactElement(child) ? child.props : {} },
                  { style, className: printStyle },
              );

        return renderReactChildren(
            child,
            (c) => React.cloneElement(c, { ...attributes, innerRef: this.innerRef }),
            (c) => React.cloneElement(c, { ...attributes.__htmlAttributes, ref: this.innerRef }),
        );
    }

    private removeScrollListeners = () => {
        this.scrollableParents.forEach((element) =>
            element.removeEventListener('scroll', this.props.close),
        );
        this.scrollableParents = [];
    };

    /**
     * Listen to all scrollable parent elements. Uses the parentRef if set
     * otherwise uses the innerRef.
     */
    private addScrollListeners = () => {
        const parentElement =
            (this.props.parentRef && this.props.parentRef.current) ||
            (this.props.innerRef && this.props.innerRef.current);

        if (!parentElement) {
            return;
        }
        this.scrollableParents = this.getScrollableParents(parentElement);
        this.scrollableParents.forEach((element) =>
            element.addEventListener('scroll', this.props.close),
        );
    };

    /**
     * Return a list of all scrollable parent elements in the DOM tree.
     */
    private getScrollableParents = (element: HTMLElement): HTMLElement[] => {
        const scrollableParents: HTMLElement[] = [];

        const scrollRegex = /(auto|scroll)/;

        const getStylePropValue = (node: HTMLElement, prop: string) =>
            getComputedStyle(node, null).getPropertyValue(prop);

        const isScrollable = (node: HTMLElement) =>
            scrollRegex.test(
                getStylePropValue(node, 'overflow') +
                    getStylePropValue(node, 'overflow-y') +
                    getStylePropValue(node, 'overflow-x'),
            );

        const getScrollableParent = (node: HTMLElement | null): HTMLElement[] => {
            if (!node || node === document.body) {
                scrollableParents.push(document.body);
                return scrollableParents;
            }
            if (isScrollable(node)) {
                scrollableParents.push(node);
            }
            return getScrollableParent(node.parentNode as HTMLElement);
        };

        return getScrollableParent(element);
    };
}
