import { createSelector } from 'reselect';
import type { Id, IPersistence, ITimeSerieEntity, IScheduleEntity } from 'app/core/persistence';
import { ScheduleDays, Time24, getParentId } from 'app/core/persistence';
import { uniq } from 'lodash-es';
import { isDefined } from 'axis-webtools-util';
import {
    getCurrentProjectItemsArray,
    getCurrentProjectProfilesArray,
    getCurrentProjectSchedulesArray,
    getCurrentProjectTimeSeriesArray,
    toCacheKey,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';
import { nameComparator } from 'app/utils';

const getSelectedScheduleId = (state: IStoreState) => state.schedules.selectedScheduleId;

export const getUsedScheduleIds = createSelector(
    [getCurrentProjectProfilesArray, getCurrentProjectItemsArray],
    (profiles, items) => {
        const usedScenarioIds: Id[] = [];

        profiles.forEach((profile) => {
            if (profile.continuousRecording.schedule) {
                usedScenarioIds.push(profile.continuousRecording.schedule);
            }
            if (profile.triggeredRecording.schedule) {
                usedScenarioIds.push(profile.triggeredRecording.schedule);
            }
            if (profile.liveView.schedule) {
                usedScenarioIds.push(profile.liveView.schedule);
            }
        });

        items.forEach((item) => {
            // Find out what kind of IItemPropertiesEntity we're dealing with (ICameraPropertiesEntity etc.),
            // and check if they have the profileOverride property
            const itemPropertiesEntity = Object.values(item.properties).find((prop) =>
                isDefined(prop),
            );
            if (itemPropertiesEntity?.profileOverride) {
                const profileOverride = itemPropertiesEntity.profileOverride;
                if (profileOverride.continuousRecording.schedule) {
                    usedScenarioIds.push(profileOverride.continuousRecording.schedule);
                }
                if (profileOverride.triggeredRecording.schedule) {
                    usedScenarioIds.push(profileOverride.triggeredRecording.schedule);
                }
                if (profileOverride.liveView.schedule) {
                    usedScenarioIds.push(profileOverride.liveView.schedule);
                }
            }
        });
        return uniq(usedScenarioIds);
    },
);

export const getSortedSchedules = createSelector([getCurrentProjectSchedulesArray], (schedules) =>
    schedules
        .filter((schedule) => !schedule.systemDefined && !isInvertedSchedule(schedule, schedules))
        .sort(nameComparator),
);

export const getSortedScheduleIds = createSelector([getSortedSchedules], (schedules) =>
    schedules.map((schedule) => schedule._id),
);

export const getSelectedSchedule = createSelector(
    [getSortedSchedules, getSelectedScheduleId],
    (schedules, selectedScheduleId) =>
        schedules.find((schedule) => schedule._id === selectedScheduleId),
);

export const getNextSchedule = createSelector(
    [getSortedSchedules, getSelectedScheduleId],
    (schedules, selectedScheduleId) =>
        schedules[schedules.findIndex((schedule) => schedule._id === selectedScheduleId) + 1],
);

export const getPreviousSchedule = createSelector(
    [getSortedSchedules, getSelectedScheduleId],
    (schedules, selectedScheduleId) =>
        schedules[schedules.findIndex((schedule) => schedule._id === selectedScheduleId) - 1],
);

export const getInvertedScheduleFromSelectedSchedule = createSelector(
    [getSelectedScheduleId, getCurrentProjectSchedulesArray],
    (selectedScheduleId, schedules) =>
        schedules.find((schedule) => getParentId(schedule) === selectedScheduleId),
);

export const getInvertedScheduleFromOriginalId = createCachedSelector(
    [getCurrentProjectSchedulesArray, (_state: IStoreState, scheduleId: Id) => scheduleId],
    (schedules, scheduleId) => {
        return schedules.find((schedule) => getParentId(schedule) === scheduleId);
    },
)(toCacheKey);

export const getNameFromProps = (_state: IStoreState, scheduleName: string) => scheduleName;

export const getInvertedScheduleInUse = createSelector(
    [getUsedScheduleIds, getInvertedScheduleFromSelectedSchedule],
    (usedSchedules, invertedSchedule) =>
        invertedSchedule ? usedSchedules.includes(invertedSchedule._id) : false,
);

export const getTimeSerieIdsForSelectedSchedule = createSelector(
    [getCurrentProjectTimeSeriesArray, getSelectedScheduleId],
    (timeSeries, scheduleId) =>
        timeSeries
            .filter((serie) => scheduleId && getParentId(serie) === scheduleId)
            .sort((serieA, serieB) => {
                return serieA.creationDate > serieB.creationDate
                    ? 1
                    : serieA.creationDate < serieB.creationDate
                      ? -1
                      : 0;
            })
            .map((serie) => serie._id),
);

export const getTimeSerieIdsForInvertedSchedule = createSelector(
    [getCurrentProjectTimeSeriesArray, getInvertedScheduleFromSelectedSchedule],
    (timeSeries, invertedSchedule) =>
        timeSeries
            .filter((serie) => invertedSchedule?._id && getParentId(serie) === invertedSchedule._id)
            .sort((serieA, serieB) => {
                return !serieA.originalId && serieB.originalId
                    ? 1
                    : serieA.originalId && !serieB.originalId
                      ? -1
                      : serieA.creationDate > serieB.creationDate
                        ? 1
                        : serieA.creationDate < serieB.creationDate
                          ? -1
                          : 0;
            })
            .map((serie) => serie._id),
);

export const getTimeSeriesForSchedulesRecord = createSelector(
    [getCurrentProjectTimeSeriesArray],
    (currentProjectTimeSeries) =>
        currentProjectTimeSeries
            .sort((serieA, serieB) => {
                return serieA.creationDate > serieB.creationDate
                    ? 1
                    : serieA.creationDate < serieB.creationDate
                      ? -1
                      : 0;
            })
            .reduce(
                (
                    timeSeries: Record<Id, IPersistence<ITimeSerieEntity>[]>,
                    timeSerie: IPersistence<ITimeSerieEntity>,
                ) => {
                    const scheduleId = getParentId(timeSerie);
                    if (scheduleId) {
                        if (timeSeries[scheduleId]) {
                            timeSeries[scheduleId].push(timeSerie);
                        } else {
                            timeSeries[scheduleId] = [timeSerie];
                        }
                    }

                    return timeSeries;
                },
                {},
            ),
);

export const getTimeSerieAbbreviationsPerSchedule = createSelector(
    [getCurrentProjectSchedulesArray, getTimeSeriesForSchedulesRecord],
    (schedules, timeSeries) =>
        schedules.reduce((abbreviations: Record<Id, string[]>, schedule) => {
            if (!timeSeries[schedule._id]) {
                abbreviations[schedule._id] = [];
            } else {
                const timeSerieAbbreviations = timeSeries[schedule._id].map((timeSerie) => {
                    return `${new ScheduleDays(timeSerie.days).abbreviatedDaysString()}${
                        new ScheduleDays(timeSerie.days).isAnyDayActive() ? ',' : ''
                    } ${new Time24(timeSerie.start)} - ${new Time24(timeSerie.end)}`;
                });
                abbreviations[schedule._id] = timeSerieAbbreviations;
            }

            return abbreviations;
        }, {}),
);

const isInvertedSchedule = (
    schedule: IPersistence<IScheduleEntity>,
    allSchedules: IPersistence<IScheduleEntity>[],
) => {
    const parentId = getParentId(schedule);
    return !parentId || allSchedules.find((parent) => parent._id === parentId) !== undefined;
};
