import type { Id } from '../../entities';
import type { RepositoryAction } from '../RepositoryAction';

export class RepositoryMissingEntityVersionError extends Error {
    constructor(action: RepositoryAction, invalidIds: Id[] = []) {
        super(
            `Missing entity version was encountered, action: ${action}, ids: ${invalidIds.join(
                ', ',
            )}`,
        );

        Object.setPrototypeOf(this, RepositoryMissingEntityVersionError.prototype);
    }
}
