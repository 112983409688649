import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import type { IMapsDevice } from '../../../../models/IMapsDevice';
import {
    getDevicesOnSelectedMap,
    getDevicesOnMapToggle,
    getUnplacedDevicesOnFloorPlan,
    getAllPlacedDevices,
    getSelectedFloorPlanType,
} from '../../../../selectors';
import { DeviceMenuItem } from './DeviceMenuItem.container';
import type { Id, MapType } from 'app/core/persistence';
import { UserPreferencesService } from 'app/core/persistence';
import { getCurrentProjectId, getCurrentProjectLocked } from 'app/modules/common';
import { Box, DropDown, DropDownMenuButton, IconButton, Label, Stack } from 'app/components';
import { t } from 'app/translate';
import { MapsActionService } from '../../../../services';
import { AddDeviceSelectorMenuItem } from './AddDeviceSelectorMenuItem';

interface IDevicesContextMenuContainerProps {
    allDevices: IMapsDevice[];
    devicesOnMap: IMapsDevice[];
    unplacedDevices: IMapsDevice[];
    projectId: Id;
    isLocked: boolean;
    onlyOnThisMap: boolean;
    selectedMapType: MapType;
}

const mapStateToProps = (storeState: IStoreState): IDevicesContextMenuContainerProps => {
    return {
        allDevices: getAllPlacedDevices(storeState),
        devicesOnMap: getDevicesOnSelectedMap(storeState),
        unplacedDevices: getUnplacedDevicesOnFloorPlan(storeState),
        projectId: getCurrentProjectId(storeState),
        isLocked: getCurrentProjectLocked(storeState),
        onlyOnThisMap: getDevicesOnMapToggle(storeState),
        selectedMapType: getSelectedFloorPlanType(storeState),
    };
};

class DevicesContextMenuContainer extends React.Component<IDevicesContextMenuContainerProps> {
    private mapsActionService: MapsActionService;
    private userPreferencesService: UserPreferencesService;

    constructor(props: IDevicesContextMenuContainerProps) {
        super(props);
        this.mapsActionService = ServiceLocator.get(MapsActionService);
        this.userPreferencesService = ServiceLocator.get(UserPreferencesService);
    }

    componentDidMount() {
        // Load state from local storage
        const showDevicesOnMap = this.userPreferencesService.get().showOnlyDevicesOnMap;
        this.mapsActionService.setShowDevicesOnMap(showDevicesOnMap);
    }

    public render() {
        return (
            <Box width="100%" height="100%" overflowY="auto">
                <Stack vertical spacing="quart">
                    <Stack vertical spacing="quart" flex="dontShrink">
                        <Box paddingX="quart" flex="none" paddingTop="half">
                            <AddDeviceSelectorMenuItem />
                        </Box>
                    </Stack>
                    <Box paddingX="quart">
                        <Stack vertical spacing="quart">
                            <>
                                {this.props.unplacedDevices.length > 0 && (
                                    <Box
                                        direction="column"
                                        justifyContent="center"
                                        paddingTop="cell"
                                    >
                                        <Label align="center">{t.notOnMap}</Label>
                                        <Stack vertical spacing="quart">
                                            {this.renderUnplacedDevices()}
                                        </Stack>
                                    </Box>
                                )}

                                <Box justifyContent="center" flex="none" paddingTop="cell">
                                    <DropDown
                                        trigger={
                                            <IconButton
                                                icon="filter_list"
                                                text={
                                                    this.props.onlyOnThisMap
                                                        ? this.props.selectedMapType === 'FloorPlan'
                                                            ? t.allDevicesOnFloorPlan
                                                            : t.allDevicesOnLocation
                                                        : t.allDevices
                                                }
                                            />
                                        }
                                        contents={
                                            <DropDownMenuButton
                                                label={
                                                    this.props.selectedMapType === 'FloorPlan'
                                                        ? t.onlyOnThisFloorPlan
                                                        : t.onlyOnThisLocation
                                                }
                                                checked={this.props.onlyOnThisMap}
                                                onClick={this.toggleDevicesOnMap}
                                            />
                                        }
                                    />
                                </Box>
                                {this.props.onlyOnThisMap
                                    ? this.renderDevicesOnSelectedMap()
                                    : this.renderAllPlacedDevices()}
                            </>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        );
    }

    private toggleDevicesOnMap = () => {
        const showDevicesOnMap = !this.props.onlyOnThisMap;
        this.mapsActionService.setShowDevicesOnMap(showDevicesOnMap);
        this.userPreferencesService.set({ showOnlyDevicesOnMap: showDevicesOnMap });
    };

    private renderDevicesOnSelectedMap = () =>
        this.props.devicesOnMap.map((device) => {
            return (
                <DeviceMenuItem
                    key={device.id}
                    color={device.color}
                    id={device.id}
                    openSettings={() => this.mapsActionService.selectDevice(device.id)}
                />
            );
        });

    private renderUnplacedDevices = () =>
        this.props.unplacedDevices.map((device) => {
            return (
                <DeviceMenuItem
                    key={device.id}
                    color={device.color}
                    id={device.id}
                    openSettings={() => this.mapsActionService.selectDevice(device.id)}
                />
            );
        });

    private renderAllPlacedDevices = () =>
        this.props.allDevices.map((device) => {
            return (
                <DeviceMenuItem
                    key={device.id}
                    color={device.color}
                    id={device.id}
                    openSettings={() => this.mapsActionService.selectDevice(device.id)}
                />
            );
        });
}

export const DevicesContextMenu = connect(mapStateToProps)(DevicesContextMenuContainer);
