import * as React from 'react';
import { Box, Text, CutCorner } from 'app/components';
import type { IAutoTestable } from 'app/components';
import type { Colors } from 'app/styles';
import type { IDataToDisplay } from '../../models';

export interface IInfoToDisplay {
    title: string;
    value: string;
    subTitle: string;
}

interface ISummaryInfoProps extends IAutoTestable {
    title?: string;
    titleColor?: Colors;
    backgroundColor: Colors;
    items: IDataToDisplay[];
}

export const SummaryInfo: React.FunctionComponent<ISummaryInfoProps> = ({
    items,
    backgroundColor,
    testId,
}) => (
    <CutCorner color={backgroundColor}>
        <Box padding="base" spacing="base">
            {items.map((item, key) => {
                return (
                    <CutCorner key={key} smallCut color="white">
                        <Box
                            padding="base"
                            maxWidth={120}
                            minWidth={120}
                            spacing="none"
                            alignItems="center"
                            direction="column"
                        >
                            <Box minHeight={30} alignItems="center">
                                <Text color="warmGrey6" small align="center">
                                    {item.title}
                                </Text>
                            </Box>
                            <Text
                                testId={`${testId}_${item.title}_value_txt`}
                                sizeOverride={24}
                                semiBold
                                color="warmGrey6"
                            >
                                {item.value}
                            </Text>
                            <Text
                                testId={`${testId}_${item.title}_unit_txt`}
                                color="warmGrey6"
                                semiBold
                            >
                                {item.subTitle}
                            </Text>
                        </Box>
                    </CutCorner>
                );
            })}
        </Box>
    </CutCorner>
);

SummaryInfo.displayName = 'ProposalRecordingItem';
