import type { IMinMax } from 'app/core/persistence';
import { memoize } from 'lodash-es';
import { convertToIpRange } from './IPRangeSettings/convertToIpRange';

export interface ILabeledMinMax extends IMinMax {
    minLabel: string;
    maxLabel: string;
}

export const getNotUsedRanges = memoize(
    (deviceTypeRanges: IMinMax[], rangeSize: number, ipStart: string): ILabeledMinMax[] => {
        const otherRanges: ILabeledMinMax[] = [];
        deviceTypeRanges.sort((a, b) => a.min - b.min);

        let prevInRange: boolean = false;
        const curRange: IMinMax = { min: 0, max: 0 };

        for (let i = 1; i <= rangeSize; i++) {
            // Not covered by other ranges
            if (!deviceTypeRanges.some((range) => range.min <= i && range.max >= i)) {
                if (!prevInRange) {
                    curRange.min = curRange.max = i;
                } else {
                    curRange.max++;
                }
                prevInRange = true;

                // If end of full range is unoccupied, push a range representing this space as well
                if (i === rangeSize) {
                    otherRanges.push({
                        ...curRange,
                        minLabel: convertToIpRange(ipStart, curRange.min),
                        maxLabel: convertToIpRange(ipStart, curRange.max),
                    });
                }
            } else {
                // Is covered by other ranges
                if (prevInRange) {
                    otherRanges.push({
                        ...curRange,
                        minLabel: convertToIpRange(ipStart, curRange.min),
                        maxLabel: convertToIpRange(ipStart, curRange.max),
                    });
                }
                prevInRange = false;
            }
        }

        return otherRanges;
    },
    (...args) => JSON.stringify(args),
);
