import type { IItemRelationEntity, Id, IPersistence, IItemEntity } from 'app/core/persistence';
import { createSelector } from 'reselect';
import { uniq } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItemRelationsArray, getCurrentProjectItems } from '../../project';
import { getPiaItemsRecord } from '../../piaDevices';
import { getIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';
import type { IPiaItem } from 'app/core/pia';

export const MAX_ACCESSORY_DEPTH = 3;

/**
 * Get a record of the relations for each item in current project.
 */
export const getCurrentProjectRelationsRecord = createSelector(
    [getCurrentProjectItemRelationsArray],
    (relations) => {
        const parentIds = uniq(relations.map((relation) => relation.parentId));
        const relationRecord = parentIds.reduce(
            (record, parentId) => {
                const relationItems = relations.filter(
                    (relation) => relation.parentId === parentId,
                );
                record[parentId] = relationItems;
                return record;
            },
            {} as Record<Id, IItemRelationEntity[]>,
        );

        return relationRecord;
    },
);

export const getCurrentProjectRelationsForItem = createCachedSelector(
    [getCurrentProjectRelationsRecord, getIdFromProps],
    (currentProjectRelations, itemId) => {
        if (!itemId) {
            return [];
        }

        return currentProjectRelations[itemId] ?? [];
    },
)(toCacheKey);

/**
 * Get a record of the relations with child as key.
 */
export const getParentRelationsRecord = createSelector(
    [getCurrentProjectItemRelationsArray],
    (relations) => {
        const childIds = uniq(relations.map((relation) => relation.childId));
        const relationRecord = childIds.reduce(
            (record, childId) => {
                const relationItem = relations.find((relation) => relation.childId === childId);
                if (relationItem) {
                    record[childId] = relationItem;
                }
                return record;
            },
            {} as Record<Id, IItemRelationEntity>,
        );

        return relationRecord;
    },
);

/**
 * Get a record of the accessory relations with child as key.
 */
export const getAccessoryRelationsRecord = createSelector(
    [getCurrentProjectItemRelationsArray],
    (relations) => {
        const childIds = uniq(
            relations
                .filter((relation) => relation.relationType === 'accessory')
                .map((relation) => relation.childId),
        );
        const relationRecord = childIds.reduce(
            (record, childId) => {
                const relationItem = relations.find((relation) => relation.childId === childId);
                if (relationItem) {
                    record[childId] = relationItem;
                }
                return record;
            },
            {} as Record<Id, IItemRelationEntity>,
        );

        return relationRecord;
    },
);

const getRelatedAccessories = createCachedSelector(
    [getCurrentProjectItemRelationsArray, getCurrentProjectItems, getIdFromProps],
    (projectRelations, currentProjectItems, idFromProps) => {
        if (!idFromProps) {
            return [];
        }
        //* Gets accessory relations for an item id
        const getAccessoryRelations = (id: Id) =>
            projectRelations.filter(
                (relation) => relation.parentId === id && relation.relationType === 'accessory',
            );

        //* allAccessories will contain all accessories associated with main device
        const allAccessories: IPersistence<IItemEntity>[] = [];

        //* Get relations for main device
        let relations: IItemRelationEntity[] = getAccessoryRelations(idFromProps);

        //* We set a limit to maximum allowed depth for accessories to accessories to avoid risk of infinite loops.
        for (let i = 0; i < MAX_ACCESSORY_DEPTH; i++) {
            //* New relations will contain relations to accessories of accessories in current loop
            const newAccessoryRelations: IItemRelationEntity[] = [];
            relations.forEach((rel) => {
                const newAccessory = currentProjectItems[rel.childId];
                if (newAccessory?.productId) {
                    //* Add accessory relations of item to new relations
                    newAccessoryRelations.push(...getAccessoryRelations(newAccessory._id));
                    //* Add accessory to allAccessories
                    allAccessories.push(newAccessory);
                }
            });
            //* Update relations to go one step deeper
            relations = newAccessoryRelations;
        }
        return allAccessories;
    },
)(toCacheKey);

export const getTotalNbrAccessories = createCachedSelector(
    [getRelatedAccessories],
    (accessories) => accessories.length,
)(toCacheKey);

export const getPiaAccessories = createCachedSelector(
    [getPiaItemsRecord, getRelatedAccessories],
    (piaItemsRecord, accessories): IPiaItem[] =>
        accessories.reduce(
            (piaAccessories, { productId }) =>
                productId ? piaAccessories.concat(piaItemsRecord[productId]) : piaAccessories,
            new Array<IPiaItem>(),
        ),
)(toCacheKey);
