import * as React from 'react';
import { saveAs } from 'file-saver';
import type { IProduct } from 'app/modules/common';
import { VendorEnum } from 'app/modules/common';
import { t } from 'app/translate';
import type { IWithChildren } from 'app/components';

export interface IExportBOM extends IWithChildren {
    projectName: string;
    products: IProduct[];
}

export class BillOfMaterialsExportToBOMFile extends React.Component<IExportBOM> {
    public render() {
        return <div onClick={this.downloadFile}>{this.props.children}</div>;
    }

    private writeTextFile = () => {
        const metadataText = '\n"metadata": {"source": "AXIS Site Designer"}';
        const products = this.props.products
            .filter(
                (product) =>
                    [VendorEnum.Axis, VendorEnum.twoN].includes(product.vendor) &&
                    product.partNumber,
            )
            .map(
                (product) =>
                    `\t{"quantity": ${product.quantity},"productNumber": "${product.partNumber}"}`,
            )
            .join(',\n');
        const productsText = `"products": [\n${products}\n\t]`;
        return `{${[metadataText, productsText].join(',\n')}\n}`;
    };

    private downloadFile = () => {
        const fileContents = this.writeTextFile();
        const fileBlob = new Blob([fileContents], {
            type: 'application/json',
        });
        const filename = t.billOfMaterialsExcelFileName(this.props.projectName) + '.json';

        saveAs(fileBlob, filename);
    };
}
