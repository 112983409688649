import { css } from '@emotion/css';

export enum CornersEnum {
    blunt = '2px',
    rounded = '8px',
    round = '1000px',
    circle = '100%',
}

export type Corners = keyof typeof CornersEnum;

export const CornerBlunt = css`
    border-radius: ${CornersEnum.blunt};
`;
export const CornerRounded = css`
    border-radius: ${CornersEnum.rounded};
`;
export const CornerRound = css`
    border-radius: ${CornersEnum.round};
`;
export const CornerCircle = css`
    border-radius: ${CornersEnum.circle};
`;
