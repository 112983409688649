import * as React from 'react';
import type { IOptionProps } from 'app/components';
import { Select, IconText } from 'app/components';
import i18next, { changeLanguage } from 'i18next';
import { availableLanguages } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import type { LanguageCode } from 'app/utils';
import { languageCodeToLanguageName, getHtmlLangTag } from 'app/utils';
import { getPartnerConfigHeaderStyle } from 'app/modules/common';

import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import type { Property } from 'csstype';
import { isFeatureEnabled } from 'app/featureFlags';

interface ILanguageSelectorProps {
    colorOverride?: Property.Color;
}

const mapStateToProps = (storeState: IStoreState): ILanguageSelectorProps => {
    return {
        colorOverride: getPartnerConfigHeaderStyle(storeState)?.foregroundColor,
    };
};
export class LanguageSelectorContainer extends React.Component<ILanguageSelectorProps> {
    public render() {
        const selectedLanguageCode = i18next.language as LanguageCode;

        document.documentElement.lang = getHtmlLangTag(selectedLanguageCode);

        const languages: IOptionProps[] = availableLanguages
            .map((languageCode: string) => ({
                text: languageCodeToLanguageName[languageCode as LanguageCode],
                value: languageCode,
            }))
            .sort((first: IOptionProps, second: IOptionProps) =>
                first.text.localeCompare(second.text),
            )
            .filter((languageOption) =>
                isFeatureEnabled('rtl_languages')
                    ? true
                    : languageOption.value !== 'he' && languageOption.value !== 'ar',
            );

        const triggerElement = (
            <IconText
                icon="language"
                textProps={{
                    color: 'yellowText',
                    semiBold: true,
                    colorOverride: this.props.colorOverride,
                }}
                iconProps={{
                    opaque: true,
                    color: 'yellowText',
                    colorOverride: this.props.colorOverride,
                }}
            >
                {languageCodeToLanguageName[selectedLanguageCode]}
            </IconText>
        );

        return (
            <Select
                customTrigger={triggerElement}
                options={languages}
                onChange={this.setLanguage}
                value={selectedLanguageCode}
            />
        );
    }

    private setLanguage = (languageCode: string) => {
        eventTracking.logUserEvent('Application', 'Set Language', languageCode);
        changeLanguage(languageCode).then(() => {
            location.reload();
        });
        // Since Redux 2.x no longer supports hot reload we have to reload everything
        // instead of listening to the languageChanged event.
        // See https://github.com/reactjs/react-redux/releases/tag/v2.0.0
    };
}

export const LanguageSelector = connect(mapStateToProps)(LanguageSelectorContainer);
