import { injectable } from 'inversify';
import type {
    Id,
    ICameraPropertiesFilterModel,
    IAnalogCameraPropertiesEntity,
    ICameraPropertiesEntity,
} from 'app/core/persistence';
import { CurrentProjectService } from 'app/core/persistence';
import type { IInstallationReportCameraDevice } from '../models/devices';
import type {
    IPiaCamera,
    IPiaCameraProperties,
    IPiaSensorUnit,
    IPiaEncoder,
    IPiaDoorStation,
} from 'app/core/pia';
import { PiaItemCameraCategory } from 'app/core/pia';
import {
    ProfileOverrideService,
    ProfileSupportService,
    ProjectFrequencyService,
} from 'app/modules/common';
import { clamp } from 'lodash-es';
import { trigonometry, convert } from 'axis-webtools-util';

type IInstallationReportCameraSettings = Pick<
    IInstallationReportCameraDevice,
    'settings' | 'installationTilt' | 'installationHeight'
>;

@injectable()
export class InstallationDetailsService {
    constructor(
        private profileOverrideService: ProfileOverrideService,
        private projectFrequencyService: ProjectFrequencyService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public getCameraSettings = async (
        itemProperties: IAnalogCameraPropertiesEntity | ICameraPropertiesEntity,
        piaItem: IPiaCamera | IPiaSensorUnit | IPiaEncoder | IPiaDoorStation,
        projectId: Id,
    ): Promise<Pick<IInstallationReportCameraSettings, 'settings'>> => {
        const frequency = await this.projectFrequencyService.getFrequency(projectId);
        const projectRetentionTimeInDays =
            this.currentProjectService.getProjectEntity().recordingRetentionTimeInDays;
        const profile = await this.profileOverrideService.getMergedProfileFromDevice(
            itemProperties,
            projectRetentionTimeInDays,
        );
        const settings = ProfileSupportService.getDeviceProfileSettings(
            frequency,
            piaItem.properties,
            profile,
        );
        return {
            settings: {
                ...settings,
                retentionTime: profile.storage.retentionTime,
                zipstream: profile.zipstream,
            },
        };
    };

    /**
     * Get installation details (height and tilt) for Axis cameras based on camera filters.
     */
    public getCameraInstallationDetails(
        itemProperties: ICameraPropertiesEntity,
        piaItem: IPiaCamera | IPiaSensorUnit,
    ): Pick<IInstallationReportCameraSettings, 'installationHeight' | 'installationTilt'> {
        const installationTilt = this.getInstallationTilt(piaItem, itemProperties);
        const installationHeight = this.getInstallationHeight(
            itemProperties.filter.installationHeight,
        );

        return {
            installationTilt,
            installationHeight,
        };
    }

    /**
     * Get installation height in meters or feet depending on the project
     * unit system
     */
    public getInstallationHeight(installationHeight: number): number {
        const { unitSystem } = this.currentProjectService.getProjectEntity();
        return unitSystem === 'imperial'
            ? convert.metersToFeet(installationHeight)
            : installationHeight;
    }

    /**
     * Get the camera installation angle in degrees up or down.
     */
    private getInstallationTilt = (
        piaItem: IPiaCamera | IPiaSensorUnit,
        itemProperties: ICameraPropertiesEntity,
    ): IInstallationReportCameraSettings['installationTilt'] => {
        const isPtz = piaItem.category === PiaItemCameraCategory.PTZ;
        const isPanoramic = piaItem.properties.maxHorizontalFOV >= 180;

        const tiltAngle = this.getInstallAngle(piaItem.properties, itemProperties.filter);
        const degrees = Math.abs(Math.round(trigonometry.toDegrees(tiltAngle)));

        return isPtz || isPanoramic
            ? null
            : {
                  degrees,
                  direction: tiltAngle >= 0 ? 'down' : 'up',
              };
    };

    private getVerticalFov = (
        cameraProperties: IPiaCameraProperties,
        installSettings: ICameraPropertiesFilterModel,
    ) => {
        const installHorizontalFov = trigonometry.toRadians(installSettings.horizontalFov);

        const minVerticalFov = trigonometry.toRadians(cameraProperties.minVerticalFOV);
        const maxVerticalFov = trigonometry.toRadians(cameraProperties.maxVerticalFOV);

        // If camera has fixed focal length, return the selected cameras vertical FOV
        if (minVerticalFov === maxVerticalFov) {
            return maxVerticalFov;
        }

        const maxHorizontalFov = trigonometry.toRadians(cameraProperties.maxHorizontalFOV);
        const minHorizontalFov = trigonometry.toRadians(cameraProperties.minHorizontalFOV);

        const cameraHorizontalFov = clamp(installHorizontalFov, minHorizontalFov, maxHorizontalFov);

        const slope =
            (cameraHorizontalFov - minHorizontalFov) / (maxHorizontalFov - minHorizontalFov);
        const span = maxVerticalFov - minVerticalFov;

        const verticalFov = slope * span + minVerticalFov;
        return clamp(verticalFov, minVerticalFov, maxVerticalFov);
    };

    /**
     * Get the angle (0 degrees is straight forward) between the camera
     * and the target (compensated for that the target is actually the
     * top of the target so that a target height of 2 meters would yield an
     * image where 2 meters is in the top of the view) in degrees.
     */
    private getInstallAngle = (
        cameraProperties: IPiaCameraProperties,
        installSettings: ICameraPropertiesFilterModel,
    ): number => {
        const verticalFov = this.getVerticalFov(cameraProperties, installSettings);
        return (
            Math.min(
                Math.atan(
                    (installSettings.installationHeight - installSettings.targetHeight) /
                        installSettings.distanceToTarget,
                ) +
                    verticalFov / 2,
                Math.PI / 2,
            ) || 0
        );
    };
}
