import { injectable } from 'inversify';
import type { Action, Dispatch } from '@reduxjs/toolkit';
import type { ImportErrorReason, IIdRevModel } from 'app/core/persistence';
import {
    isErrorType,
    Id,
    ProjectImportService,
    ProjectExportService,
    ImportError,
    ProjectService,
    OfflineService,
    FloorPlanService,
    ProjectDbOrigin,
    DuplicationError,
    ProjectRepository,
    ReplicationService,
} from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import { UserProjectsService } from '../../services/UserProjects.service';
import type { IUserProjectsListItem } from '../models';
import { UserProjectsActions } from '../state/UserProjects.actions';
import { t } from 'app/translate';
import {
    getUserSignedIn,
    CommonActionService,
    InitializeDataStorageService,
} from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import { IProjectListSort } from '../../models';
import type { IProgressToast } from 'app/toaster';
import { toaster } from 'app/toaster';
import { ModalService } from 'app/modal';
import { NavigateFunction } from 'react-router-dom';
import { getCurrentView } from '../selectors/getVisibleUserProjects';

@injectable()
export class UserProjectsActionService {
    constructor(
        private userProjectsService: UserProjectsService,
        private projectService: ProjectService,
        private projectImportService: ProjectImportService,
        private projectExportService: ProjectExportService,
        private modalService: ModalService,
        private offlineService: OfflineService,
        private commonActionService: CommonActionService,
        private initializeDataStorageService: InitializeDataStorageService,
        private projectRepository: ProjectRepository,
        private replicationService: ReplicationService,
    ) {}

    @ActionCreator()
    public addProject(navigate: NavigateFunction, origin?: ProjectDbOrigin): ThunkAction {
        return async (dispatch: any, getState) => {
            const state = getState();
            const userInfo = state.common.user.user;
            const userAccountCountryCode = userInfo ? userInfo.country : null;
            // Switch to correct database repository before adding.
            const projectDbOrigin = origin ?? getCurrentView(state);
            if (projectDbOrigin === ProjectDbOrigin.asdLocalUserData) {
                eventTracking.logUserEvent('User Projects', 'Add Project', 'Local project');
            } else if (projectDbOrigin === ProjectDbOrigin.asdUserData) {
                eventTracking.logUserEvent('User Projects', 'Add Project', 'User project');
            }
            await this.initializeDataStorageService.switchDataRepository(projectDbOrigin);

            const project = await this.userProjectsService.addProjectListItemWithEntity(
                userAccountCountryCode,
                projectDbOrigin,
            );

            dispatch({
                type: UserProjectsActions.ADD_PROJECT,
                payload: project,
            });

            toaster.success(t.projectSuccessToastHeader, t.projectSuccessToastBody);

            navigate(`/project/${project.id}/dashboard/`);
        };
    }

    @ActionCreator()
    public duplicateProject(projectId: Id, usedImageQuota?: number, totalImageQuota?: number) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();
            const hasFloorPlans = await this.projectHasFloorPlanMapType(projectId);
            const isLocalProject = await this.projectService.getIsLocalProject(projectId);
            const confirmDuplicateWithoutFloorPlans = this.modalService.createConfirmDialog({
                header: t.projectDuplicatingHeader,
                body: t.projectExportRequirementError,
                confirmButtonText: t.projectExportWithoutFloorPlans,
                cancelButtonText: t.cancel,
                warning: true,
            });
            let newProject;

            try {
                // only special case if project has floorplans, but user not online or signed-in
                if (hasFloorPlans && !isLocalProject && !(isSignedIn && isOnline)) {
                    const duplicateWithoutFloorPlans = await confirmDuplicateWithoutFloorPlans();

                    eventTracking.logUserEvent(
                        'User Projects',
                        'Duplicate Project',
                        'Confirm exclude floor plan',
                        +duplicateWithoutFloorPlans,
                    );

                    if (duplicateWithoutFloorPlans) {
                        toaster.info(t.projectDuplicatingHeader, t.projectDuplicatingMessage);

                        newProject =
                            await this.userProjectsService.duplicateProjectWithoutFloorPlans(
                                projectId,
                            );
                    } else {
                        // user aborted duplication without floor plans
                        return;
                    }
                } else {
                    // fetching floor plans will take time, better confirm action
                    if (hasFloorPlans) {
                        toaster.info(t.projectDuplicatingHeader, t.projectDuplicatingMessage);
                    }
                    newProject = await this.userProjectsService.duplicateProject(
                        projectId,
                        usedImageQuota,
                        totalImageQuota,
                    );
                }

                dispatch({
                    type: UserProjectsActions.ADD_PROJECT,
                    payload: newProject,
                });

                this.getDeepProjectData(newProject.id);

                toaster.success(
                    t.projectDuplicateSuccessHeader,
                    t.projectDuplicateSuccessMessage(newProject.name),
                );
            } catch (error) {
                console.error(error);
                if (error instanceof DuplicationError) {
                    toaster.error(t.projectDuplicateErrorHeader, t.errorQuota);
                    return;
                }
                toaster.error(t.projectDuplicateErrorHeader, t.projectDuplicateErrorMessage);
            }
        };
    }

    @ActionCreator()
    public userProjectFilterChange(value: string): IAction<string> {
        return {
            type: UserProjectsActions.USER_PROJECTS_FILTER_CHANGE,
            payload: value,
        };
    }

    @ActionCreator()
    public getDeepProjectData(projectId: Id): IAction<Promise<IUserProjectsListItem | null>> {
        return {
            type: UserProjectsActions.GET_DEEP_PROJECT_DATA,
            payload: this.replicationService
                .waitForReplication()
                .then(() =>
                    this.userProjectsService.getProjectWithEntity(projectId).catch(() => null),
                ), // convert rejections to null.
        };
    }

    @ActionCreator()
    public deleteUserProject(projectId: Id, rev: string) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();
            const isLocalProject = await this.projectService.getIsLocalProject(projectId);
            const hasFloorPlans = await this.projectHasFloorPlanMapType(projectId);
            if (hasFloorPlans && !isLocalProject && (!isOnline || !isSignedIn)) {
                toaster.error(t.deleteProjectErrorHeader, t.deleteProjectAuthErrorMessage);
                return;
            }

            dispatch({
                type: UserProjectsActions.DELETE_USERPROJECT,
                payload: this.userProjectsService
                    .deleteProject(projectId, rev)
                    .catch((error: any) => {
                        switch (error.message) {
                            case 'AuthError':
                                toaster.error(
                                    t.deleteProjectErrorHeader,
                                    t.deleteProjectAuthErrorMessage,
                                );
                                break;

                            case 'UnknownNetworkError':
                                toaster.error(
                                    t.deleteProjectErrorHeader,
                                    t.deleteProjectUnknownNetworkErrorMessage,
                                );
                                break;

                            default:
                                toaster.error(
                                    t.deleteProjectErrorHeader,
                                    t.deleteProjectUnknownError,
                                );
                        }
                    }),
            });
        };
    }

    @ActionCreator()
    public deleteMultipleProjects(projects: IIdRevModel[]) {
        let couldNotDeleteProject = false;

        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();

            const deletedProjects: string[] = [];
            await Promise.all(
                projects.map(async (project) => {
                    const hasFloorPlans = await this.projectHasFloorPlanMapType(project.id);
                    const isLocalProject = await this.projectService.getIsLocalProject(project.id);
                    if (hasFloorPlans && !isLocalProject && (!isOnline || !isSignedIn)) {
                        couldNotDeleteProject = true;
                    } else {
                        const deletedProject = await this.userProjectsService
                            .deleteProject(project.id, project.rev)
                            .catch((error: any) => {
                                switch (error.message) {
                                    case 'AuthError':
                                        toaster.error(
                                            t.deleteProjectErrorHeader,
                                            t.deleteProjectAuthErrorMessage,
                                        );
                                        break;

                                    case 'UnknownNetworkError':
                                        toaster.error(
                                            t.deleteProjectErrorHeader,
                                            t.deleteProjectUnknownNetworkErrorMessage,
                                        );
                                        break;

                                    default:
                                        toaster.error(
                                            t.deleteProjectErrorHeader,
                                            t.deleteProjectUnknownError,
                                        );
                                }
                            });
                        if (deletedProject) {
                            deletedProjects.push(deletedProject);
                        }
                    }
                }),
            );
            dispatch({
                type: UserProjectsActions.MULTI_DELETE_USERPROJECTS,
                payload: deletedProjects,
            });

            if (couldNotDeleteProject) {
                toaster.error(t.deleteProjectErrorHeader, t.deleteProjectAuthErrorMessage);
                dispatch({
                    type: UserProjectsActions.INITIALIZE,
                    payload: this.userProjectsService.initialize(),
                });
            }
        };
    }

    @ActionCreator()
    public syncUnarchived(): IAction<Promise<void>> {
        return {
            type: UserProjectsActions.REPLICATE,
            payload: this.replicationService.syncUnarchived(),
        };
    }

    @ActionCreator()
    public showArchivedProjects(value: boolean) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            if (value) {
                const isLocalProjectsSelected =
                    getCurrentView(getState()) === ProjectDbOrigin.asdLocalUserData;

                if (!isLocalProjectsSelected) {
                    // only trigger sync for non-local projects
                    this.commonActionService.syncAll();
                }
            }
            dispatch({
                type: UserProjectsActions.SHOW_ARCHIVED_PROJECTS,
                payload: value,
            });
        };
    }

    @ActionCreator()
    public setSortOrder(sortOrder: IProjectListSort): IAction<IProjectListSort> {
        return {
            type: UserProjectsActions.SET_ORDER,
            payload: sortOrder,
        };
    }

    @ActionCreator()
    public exportProject(projectId: Id) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();
            const hasFloorPlans = await this.projectHasFloorPlanMapType(projectId);
            const isLocalProject = await this.projectService.getIsLocalProject(projectId);
            const confirmIncludeFloorPlans = this.modalService.createConfirmDialog({
                header: t.projectExportModalTitle,
                body: t.projectExportModalMessage,
                confirmButtonText: t.projectExportWithFloorPlans,
                cancelButtonText: t.projectExportWithoutFloorPlans,
            });
            const confirmExportWithoutFloorPlans = this.modalService.createConfirmDialog({
                header: t.projectExportModalTitle,
                body: t.projectExportRequirementError,
                confirmButtonText: t.projectExportWithoutFloorPlans,
                cancelButtonText: t.cancel,
                warning: true,
            });
            let exportResult;

            let progressToast: IProgressToast | undefined = undefined;
            try {
                if (hasFloorPlans) {
                    if ((isOnline && isSignedIn) || isLocalProject) {
                        const includeFloorPlans = await confirmIncludeFloorPlans();

                        if (includeFloorPlans) {
                            toaster.info(t.projectExportingHeader, t.projectIsBeingExportedMessage);
                        }

                        eventTracking.logUserEvent(
                            'User Projects',
                            'Export Project',
                            'Confirm include floor plan',
                            +includeFloorPlans,
                        );
                        progressToast = toaster.progress(t.creatingProjectFile);
                        exportResult = includeFloorPlans
                            ? await this.projectExportService.exportProject(projectId)
                            : await this.projectExportService.exportProjectWithoutFloorPlans(
                                  projectId,
                              );
                    } else {
                        const exportWithoutFloorPlans = await confirmExportWithoutFloorPlans();

                        eventTracking.logUserEvent(
                            'User Projects',
                            'Export Project',
                            'Confirm exclude floor plan',
                            +exportWithoutFloorPlans,
                        );

                        if (exportWithoutFloorPlans) {
                            exportResult =
                                await this.projectExportService.exportProjectWithoutFloorPlans(
                                    projectId,
                                );
                        } else {
                            // user aborted export without floor plans
                            return;
                        }
                    }
                } else {
                    exportResult = await this.projectExportService.exportProject(projectId);
                }

                dispatch({
                    type: UserProjectsActions.EXPORT_PROJECT,
                    payload: exportResult,
                });

                progressToast?.setSuccess(
                    t.documentationSuccessfullDownloadTitle,
                    t.projectExportSuccessMessage,
                );
            } catch (error) {
                console.error(error);
                progressToast?.setError(t.couldNotDownloadFile);
            }
        };
    }

    public async exportMultipleProjects(projectIds: Id[], isSignedIn: boolean) {
        const isOnline = await this.offlineService.isOnline();

        /** Whether or not any selected project has floor plans */
        const haveFloorPlans: boolean = await Promise.all(
            projectIds.map(async (id) => this.projectHasFloorPlanMapType(id)),
        ).then((hasFloorPlan) => hasFloorPlan.some((has) => !!has));

        /** Whether or not only local projects are selected */
        const onlyLocalProjects: boolean = await Promise.all(
            projectIds.map(async (id) => this.projectService.getIsLocalProject(id)),
        ).then((isLocal) => isLocal.some((local) => !!local));

        const confirmIncludeFloorPlans = this.modalService.createConfirmDialog({
            header: t.projectExportModalTitle,
            body: t.projectMultiExportModalMessage,
            confirmButtonText: t.projectExportWithFloorPlans,
            cancelButtonText: t.projectExportWithoutFloorPlans,
        });
        const confirmExportWithoutFloorPlans = this.modalService.createConfirmDialog({
            header: t.projectExportModalTitle,
            body: t.projectMultiExportRequirementError,
            confirmButtonText: t.projectExportWithoutFloorPlans,
            cancelButtonText: t.cancel,
            warning: true,
        });

        try {
            if (haveFloorPlans) {
                if ((isOnline && isSignedIn) || onlyLocalProjects) {
                    const includeFloorPlans = await confirmIncludeFloorPlans();

                    if (includeFloorPlans) {
                        toaster.info(t.exportingProjects, t.projectsAreBeingExported);
                    }

                    eventTracking.logUserEvent(
                        'User Projects',
                        'Export Multiple Projects',
                        'Confirm include floor plan',
                        +includeFloorPlans,
                    );

                    await Promise.all(
                        projectIds.map((id) =>
                            includeFloorPlans
                                ? this.projectExportService.exportProject(id)
                                : this.projectExportService.exportProjectWithoutFloorPlans(id),
                        ),
                    );
                } else {
                    const exportWithoutFloorPlans = await confirmExportWithoutFloorPlans();

                    eventTracking.logUserEvent(
                        'User Projects',
                        'Export Multiple Projects',
                        'Confirm exclude floor plan',
                        +exportWithoutFloorPlans,
                    );

                    if (exportWithoutFloorPlans) {
                        await Promise.all(
                            projectIds.map((id) =>
                                this.projectExportService.exportProjectWithoutFloorPlans(id),
                            ),
                        );
                    } else {
                        // user aborted export without floor plans
                        return;
                    }
                }
            } else {
                await Promise.all(
                    projectIds.map((id) => this.projectExportService.exportProject(id)),
                );
            }

            toaster.success(t.projectsExported, t.projectsExportedSuccessfully);
        } catch (error) {
            console.error(error);
            toaster.error(t.projectExportErrorHeader, t.projectsFailedToExport);
        }
    }

    @ActionCreator()
    public importProject(file: File) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            // Where will this project end up, local, user or shared database?
            const currentView = getCurrentView(storeState);
            const newProjectDbOrigin = currentView;
            const newProjectIsLocalProject =
                newProjectDbOrigin === ProjectDbOrigin.asdLocalUserData;

            const isOnline = await this.offlineService.isOnline();
            let hasFloorPlans = false;
            // will fail with invalid files, so must then abort import
            try {
                hasFloorPlans = await this.projectImportService.hasFloorPlans(file);
            } catch (error) {
                if (isErrorType(error)) {
                    this.handleImportError(error);
                }
                return;
            }

            const confirmImportWithoutFloorPlans = this.modalService.createConfirmDialog({
                header: t.projectImportModalTitle,
                body: t.projectImportModalMessage,
                confirmButtonText: t.projectImportWithoutFloorPlans,
                cancelButtonText: t.cancel,
            });
            let excludeFloorPlanMaps = false;

            // special case when user tries to import project containing floor plans while off-line/ signed-out
            if (hasFloorPlans && !newProjectIsLocalProject && !(isSignedIn && isOnline)) {
                excludeFloorPlanMaps = await confirmImportWithoutFloorPlans();

                eventTracking.logUserEvent(
                    'User Projects',
                    'Import Project',
                    'Confirm exclude floor plan',
                    +excludeFloorPlanMaps,
                );

                // user aborted import without floor plans
                if (!excludeFloorPlanMaps) {
                    return;
                }
            }

            dispatch({
                type: UserProjectsActions.IMPORT_PROJECT,
                payload: this.handleImportProject(
                    file,
                    excludeFloorPlanMaps,
                    newProjectDbOrigin,
                    storeState.common.quota.usedBytes,
                    storeState.common.quota.totalBytes,
                ).then(
                    (projectId: Id) => this.handleSuccessfulImport(projectId, hasFloorPlans),
                    this.handleImportError,
                ),
            });
        };
    }

    @ActionCreator()
    public selectProject(id: Id): IAction<any> {
        return {
            type: UserProjectsActions.SELECT_PROJECT,
            payload: id,
        };
    }

    @ActionCreator()
    public unselectProject(id: Id): IAction<any> {
        return {
            type: UserProjectsActions.UNSELECT_PROJECT,
            payload: id,
        };
    }

    @ActionCreator()
    public clearSelection(): IAction<any> {
        return {
            type: UserProjectsActions.CLEAR_SELECTED_PROJECTS,
            payload: null,
        };
    }

    @ActionCreator()
    public clearUserProjects(): IAction<IUserProjectsListItem[]> {
        return {
            type: UserProjectsActions.CLEAR_USER_PROJECTS,
            payload: [],
        };
    }

    @ActionCreator()
    public setCurrentView(view: ProjectDbOrigin): IAction<ProjectDbOrigin> {
        return {
            type: UserProjectsActions.SET_CURRENT_VIEW,
            payload: view,
        };
    }

    @ActionCreator()
    public changeProjectDbOrigin(view: ProjectDbOrigin): ThunkAction {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const loading = storeState.userProjects.loading;
            const loaded = storeState.userProjects.loaded;
            const nbrLoadedUserProjects = storeState.userProjects.userProjects.length;

            // No projects loaded - load projects for current dataSource.
            if (!loading && !loaded) {
                return dispatch({
                    type: UserProjectsActions.INITIALIZE,
                    payload: this.userProjectsService.initialize(),
                });
            }

            // View has changed - clear projects, change dataSource and
            // trigger new collection of projects for new dataSource, if needed.
            if (view !== this.projectRepository.getDbName()) {
                this.clearUserProjects();
                await this.initializeDataStorageService.switchDataRepository(view);

                dispatch({
                    type: UserProjectsActions.INITIALIZE,
                    payload: this.userProjectsService.initialize(),
                });
            } else if (nbrLoadedUserProjects > 0) {
                // 1. New view is recent, no need change dataSource or fetch projects.
                // 2. Going from recent view, but we already have correct dataSource loaded.
                // 3. For the initial loading the view will always be set to 'asd-user-data'
                //    which is the same as project repository.
                //    We do not want the project to be set as loaded in that case so we check
                //    if state contains any projects
                dispatch({
                    type: UserProjectsActions.SET_USER_PROJECTS_LOADED,
                    payload: true,
                });
            }
        };
    }

    public async archiveMultipleProjects(projectIds: Id[], archive: boolean) {
        for (const id of projectIds) {
            await this.userProjectsService.setArchived(id, archive);
        }
    }

    private async projectHasFloorPlanMapType(projectId: Id): Promise<boolean> {
        const entities = await this.projectService.getDescendants(projectId);
        return entities.some((entity) => FloorPlanService.isFloorPlanMapType(entity));
    }

    private handleImportProject(
        file: File,
        excludeFloorPlanMaps: boolean,
        newProjectDbOrigin: ProjectDbOrigin,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<string> {
        toaster.info(t.projectImportImportingHeader, t.projectImportImportingMessage);
        return this.projectImportService.importProject(
            file,
            excludeFloorPlanMaps,
            newProjectDbOrigin,
            usedImageQuota,
            totalImageQuota,
        );
    }

    private async handleSuccessfulImport(projectId: Id, hasFloorPlans: boolean) {
        const project = await this.userProjectsService.getProjectWithEntity(projectId);

        toaster.success(t.projectImportSuccessHeader, t.projectImportSuccessMessage(project.name));
        hasFloorPlans && this.commonActionService.getUserImageQuota();
    }

    private handleImportError(error: Error) {
        const importErrors: Record<ImportErrorReason, string> = {
            fileContentsUnreadable: t.projectImportErrorsFileContentsUnreadable,
            invalidFileFormat: t.projectImportErrorsInvalidFileFormat,
            entityImportFailed: t.projectImportErrorsEntityImportFailed,
            imageQuotaFull: t.errorQuota,
            diskSpaceFull: t.applicationQuotaExceededError,
        };

        if (error instanceof ImportError) {
            console.error(
                `Import error - reason: ${error.reason} - message: ${
                    error.optionalMessage || error.message
                }`,
            );
            const errorMessage =
                importErrors[error.reason] || `Unknown import error reason: ${error.message}`;
            toaster.error(t.projectImportErrorHeader, errorMessage);
        } else {
            console.error(error);
            toaster.error(t.projectImportErrorHeader, t.projectImportErrorsUnknownError);
        }
    }
}
