import type { IPiaItem, PiaId } from 'app/core/pia';
import type { ILensToAdd } from '../../models';
import { isCamera, isSensorUnit } from 'app/core/persistence';

const getBarebonePiaId = (
    piaId: PiaId,
    bareboneParentRecord: Record<PiaId, IPiaItem>,
    piaDeviceRecord: Record<PiaId, IPiaItem>,
) => bareboneParentRecord[piaDeviceRecord[piaId].parentId]?.id;

const getNumberOfSensors = (piaId: PiaId, piaDeviceRecord: Record<PiaId, IPiaItem>) => {
    const piaItem = piaDeviceRecord[piaId];
    return isCamera(piaItem) || isSensorUnit(piaItem) ? piaItem.properties.imageSensors : undefined;
};

export const getLensToAdd = (
    lensPiaId: PiaId | undefined,
    devicePiaId: PiaId,
    piaDeviceRecord: Record<PiaId, IPiaItem>,
    bareboneParentRecord: Record<PiaId, IPiaItem>,
): ILensToAdd | undefined => {
    if (lensPiaId === undefined) {
        return undefined;
    }
    return {
        lensPiaId,
        numberOfSensors: getNumberOfSensors(devicePiaId, piaDeviceRecord) ?? 1,
        barebonePiaId: getBarebonePiaId(devicePiaId, bareboneParentRecord, piaDeviceRecord),
    };
};
