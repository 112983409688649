import { injectable } from 'inversify';
import type { Id, IScheduleModel, IItemEntity, IPersistence } from 'app/core/persistence';
import {
    ProjectService,
    ScheduleModelService,
    CurrentProjectService,
    deviceTypeCheckers,
} from 'app/core/persistence';
import type { IInstallationReportDevice, IInstallationReportDevicesResponse } from '../models';
import { isDefined } from 'axis-webtools-util';
import type {
    IPiaItem,
    IPiaCamera,
    IPiaDoorStation,
    IPiaPac,
    IPiaEncoder,
    IPiaDecoder,
    IPiaSpeaker,
    IPiaMainUnit,
    IPiaDetector,
    IPiaPeopleCounter,
    IPiaDoorController,
    IPiaAlerter,
    IPiaConnectivityDevice,
    IPiaPagingConsole,
} from 'app/core/pia';
import {
    CameraDeviceService,
    DecoderDeviceService,
    DoorStationDeviceService,
    EncoderDeviceService,
    FSeriesDeviceService,
    PacDeviceService,
    RadarDetectorDeviceService,
    SpeakerDeviceService,
    PeopleCounterDeviceService,
    DoorControllerDeviceService,
    AlerterDeviceService,
    ConnectivityDeviceService,
    PagingConsoleService,
} from './devices';
import { DeviceCountService, PiaQueriesService } from 'app/modules/common';
import { WearablesDeviceService } from './devices/WearableDevice.service';

@injectable()
export class InstallationReportService {
    constructor(
        private currentProjectService: CurrentProjectService,
        private projectService: ProjectService,
        private piaQueryService: PiaQueriesService,
        private cameraDeviceService: CameraDeviceService,
        private decoderDeviceService: DecoderDeviceService,
        private doorStationDeviceService: DoorStationDeviceService,
        private encoderDeviceService: EncoderDeviceService,
        private fSeriesDeviceService: FSeriesDeviceService,
        private pacDeviceService: PacDeviceService,
        private radarDetectorDeviceService: RadarDetectorDeviceService,
        private speakerDeviceService: SpeakerDeviceService,
        private deviceCountService: DeviceCountService,
        private scheduleModelService: ScheduleModelService,
        private peopleCounterDeviceService: PeopleCounterDeviceService,
        private wearableDeviceService: WearablesDeviceService,
        private doorControllerDeviceService: DoorControllerDeviceService,
        private alerterDeviceService: AlerterDeviceService,
        private connectivityDeviceService: ConnectivityDeviceService,
        private pagingConsoleService: PagingConsoleService,
    ) {}

    public async getInstallationReportItems(
        projectId: Id,
    ): Promise<IInstallationReportDevicesResponse> {
        // Device count uses IItemEntity now, when the installation report uses current project fully
        // we won't have to get the child items twice
        const deviceCount = this.deviceCountService.getDeviceCount(
            this.currentProjectService.getAllEntitiesOfType('item'),
            this.currentProjectService.getAllEntitiesOfType('itemRelation'),
        );

        const childEntities = await this.projectService.getChildItems(projectId);

        const devices = await Promise.all(
            childEntities.map((item) => this.mapItemToInstallationReportDevice(projectId, item)),
        );

        return {
            deviceCount,
            totalDevicesCount: this.deviceCountService.getInstallableDevicesCount(deviceCount),
            devices: devices.filter(isDefined),
        };
    }

    public async getScheduleNames(
        projectId: Id,
    ): Promise<Record<IScheduleModel['id'], IScheduleModel['name']>> {
        const schedules = await this.scheduleModelService.getProjectSchedules(projectId);
        return schedules.reduce((mapping, schedule) => {
            return {
                ...mapping,
                [schedule.id]: schedule.name,
            };
        }, {});
    }

    private async mapItemToInstallationReportDevice(
        projectId: Id,
        item: IPersistence<IItemEntity>,
    ): Promise<IInstallationReportDevice | null> {
        if (!item.productId) {
            return null;
        }

        try {
            const piaItem = this.piaQueryService.getPiaItemFromId(item.productId);
            return this.getReportDevice(projectId, item, piaItem);
        } catch {
            return null;
        }
    }

    private async getReportDevice(
        projectId: Id,
        item: IPersistence<IItemEntity>,
        piaItem: IPiaItem,
    ): Promise<IInstallationReportDevice | null> {
        if (deviceTypeCheckers.isDoorStation(item)) {
            return this.doorStationDeviceService.mapDevice(
                item,
                piaItem as IPiaDoorStation,
                projectId,
            );
        } else if (deviceTypeCheckers.isCamera(item)) {
            return this.cameraDeviceService.mapDevice(item, piaItem as IPiaCamera, projectId);
        } else if (deviceTypeCheckers.isPac(item)) {
            return this.pacDeviceService.mapDevice(item, piaItem as IPiaPac);
        } else if (deviceTypeCheckers.isEncoder(item)) {
            return this.encoderDeviceService.mapDevice(item, piaItem as IPiaEncoder, projectId);
        } else if (deviceTypeCheckers.isDecoder(item)) {
            return this.decoderDeviceService.mapDevice(item, piaItem as IPiaDecoder);
        } else if (deviceTypeCheckers.isSpeaker(item)) {
            return this.speakerDeviceService.mapDevice(item, piaItem as IPiaSpeaker);
        } else if (deviceTypeCheckers.isMainUnit(item)) {
            return this.fSeriesDeviceService.mapDevice(item, piaItem as IPiaMainUnit, projectId);
        } else if (deviceTypeCheckers.isRadarDetector(item)) {
            return this.radarDetectorDeviceService.mapDevice(item, piaItem as IPiaDetector);
        } else if (deviceTypeCheckers.isPeopleCounter(item)) {
            return this.peopleCounterDeviceService.mapDevice(item, piaItem as IPiaPeopleCounter);
        } else if (deviceTypeCheckers.isWearable(item)) {
            return this.wearableDeviceService.mapDevice(item, piaItem);
        } else if (deviceTypeCheckers.isDoorController(item)) {
            return this.doorControllerDeviceService.mapDevice(item, piaItem as IPiaDoorController);
        } else if (deviceTypeCheckers.isAlerter(item)) {
            return this.alerterDeviceService.mapDevice(item, piaItem as IPiaAlerter);
        } else if (deviceTypeCheckers.isConnectivityDevice(item)) {
            return this.connectivityDeviceService.mapDevice(
                item,
                piaItem as IPiaConnectivityDevice,
            );
        } else if (deviceTypeCheckers.isPagingConsole(item)) {
            return this.pagingConsoleService.mapDevice(item, piaItem as IPiaPagingConsole);
        } else {
            return null;
        }
    }
}
