import type { IDistributors } from 'app/modules/msrp';
import { injectable } from 'inversify';

import type {
    IProjectQuotationEntity,
    IPersistence,
    Id,
    IProjectQuotation,
} from '../userDataPersistence';
import {
    ProjectQuotationRepository,
    getDefaultProjectQuotationEntity,
} from '../userDataPersistence';

@injectable()
export class ProjectQuotationService {
    constructor(private projectQuotationRepository: ProjectQuotationRepository) {}

    public async get(id: Id): Promise<IPersistence<IProjectQuotationEntity>> {
        return this.projectQuotationRepository.get(id);
    }

    public async add(projectId: Id): Promise<IPersistence<IProjectQuotationEntity>> {
        if (!projectId) {
            throw new Error('Project quotation must have a project id');
        }

        const quotations = await this.projectQuotationRepository.getDescendants(projectId);
        if (quotations.descendants.length > 0) {
            throw new Error('A project quotation already exists in the project');
        }
        return this.projectQuotationRepository.add(getDefaultProjectQuotationEntity([projectId]));
    }

    public async update(
        id: Id,
        props: Partial<IProjectQuotation>,
    ): Promise<IPersistence<IProjectQuotationEntity>> {
        const quotationInDatabase = await this.projectQuotationRepository.get(id);

        const updatedQuotation = await this.projectQuotationRepository.update({
            _id: quotationInDatabase._id,
            _rev: quotationInDatabase._rev,
            creationDate: quotationInDatabase.creationDate,
            entityVersion: quotationInDatabase.entityVersion,
            type: quotationInDatabase.type,
            path: quotationInDatabase.path,
            archived: quotationInDatabase.archived,
            updatedDate: quotationInDatabase.updatedDate,
            pricesByPartNumber: {
                ...quotationInDatabase.pricesByPartNumber,
                ...props.pricesByPartNumber,
            },
            customItemPrices: {
                ...quotationInDatabase.customItemPrices,
                ...props.customItemPrices,
            },
            partnerItemPrices: {
                ...quotationInDatabase.partnerItemPrices,
                ...props.partnerItemPrices,
            },
            header: props.header ?? quotationInDatabase.header,
            footer: props.footer ?? quotationInDatabase.footer,
            msrpToQuoteMargin: props.msrpToQuoteMargin ?? quotationInDatabase.msrpToQuoteMargin,
            validUntilDate:
                'validUntilDate' in props
                    ? props.validUntilDate
                    : quotationInDatabase.validUntilDate,
        });

        return updatedQuotation;
    }

    public async setValidUntilDate(
        projectQuotationId: Id,
        distributors: IDistributors | null,
    ): Promise<Date> {
        const quotation = await this.get(projectQuotationId);

        let untilDate = quotation.validUntilDate ? new Date(quotation.validUntilDate) : undefined;
        if (!untilDate) {
            untilDate =
                distributors && distributors.lastUpdated
                    ? this.getMsrpEndDate(distributors.lastUpdated)
                    : this.getNextMonthDate();

            await this.update(projectQuotationId, {
                validUntilDate: untilDate.toISOString(),
            });
        }

        return untilDate;
    }

    /**
     * Returns the last Date when current MSRP prices are valid
     */
    private getMsrpEndDate(lastUpdated: number) {
        const lastDate = new Date(lastUpdated);
        // Add a month to lastUpdated, rollback one day (since date is 1-based)
        return new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0);
    }

    /**
     * Returns a Date one month in the future
     */
    private getNextMonthDate() {
        const currentDate = new Date();
        // Add a month to current month, use same date
        const nextMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            currentDate.getDate(),
        );
        return nextMonth;
    }
}
