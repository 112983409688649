import { createSelector } from 'reselect';
import { getCurrentProjectBlockers, isGeoLocated } from 'app/modules/common';
import { getSelectedMapOrDefault } from './getFloorPlans';
import { getOriginFilter } from './getOriginFilter';
import { isDefined } from 'axis-webtools-util';
import { isGlobalEntity } from '../utils';

/**
 * Returns all blockers for the current project as an array
 * @returns {Array} Array of blockers
 */
export const getProjectBlockersArray = createSelector([getCurrentProjectBlockers], (blockers) => {
    if (!blockers) return [];

    return Object.values(blockers).filter(isDefined);
});

/**
 * Returns all blockers for the current as an array
 * @returns {Array} Array of blockers
 */
const getCurrentMapBlockers = createSelector(
    [getSelectedMapOrDefault, getProjectBlockersArray],
    (floorPlan, blockers) => {
        if (!floorPlan || !blockers) return [];

        return blockers.filter(
            (blocker) => !isGlobalEntity(blocker) && blocker.path[1] === floorPlan._id,
        );
    },
);

/**
 * Returns all global blockers for the current project as an array
 * @returns {Array} Array of blockers
 */
const getGlobalBlockers = createSelector([getProjectBlockersArray], (blockers) => {
    if (!blockers) return [];

    return blockers.filter(isGlobalEntity);
});

/**
 * Returns all blockers relevant for the current map as an array
 * If the current map is geolocated, the global blockers are returned
 * If the current map is a non-geolocated floor plan, only that floor plan's blockers
 * are returned
 * @returns {Array} Array of blockers
 */
export const getBlockersForCurrentMap = createSelector(
    [getSelectedMapOrDefault, getGlobalBlockers, getCurrentMapBlockers, getOriginFilter],
    (map, globalBlockers, floorPlanBlockers, originFilter) => {
        const blockers = isGeoLocated(map) ? globalBlockers : floorPlanBlockers;
        return blockers.filter((blocker) => !originFilter || blocker.mapOrigin === originFilter);
    },
);
