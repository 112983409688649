import { injectable } from 'inversify';
import { ActionCreator, ThunkAction } from 'app/store';
import { saveAs } from 'file-saver';
import { getBidconExportFileContent } from '../selectors';
import { getCurrentProjectName } from 'app/modules/common';

@injectable()
export class BillOfMaterialsActionService {
    @ActionCreator()
    public generateBidconExportFile(): ThunkAction {
        return (_dispatch, getState) => {
            const state = getState();
            const content = getBidconExportFileContent(state);
            const projectName = getCurrentProjectName(state);

            const fileBlob = new Blob([content.join('\r\n')], {
                type: 'text/plain;charset=utf-8',
            });
            const filename = `${projectName}.bidcon.csv`;

            saveAs(fileBlob, filename);
        };
    }
}
