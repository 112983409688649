import * as React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentSolutionSortedByRecorderCategory } from 'app/modules/common';
import { Border, Box } from 'app/components';
import { getRecordingSolutionSpecsRecord } from '../selectors/getRecordingSolutionSpecs';
import { SelectedSolutionTableItem } from './SelectedSolutionTableItem';
import { SelectedSolutionTableHeader } from './SelectedSolutionTableHeader';
import { SelectedSolutionTableFooter } from './SelectedSolutionTableFooter';

export const SelectedSolutionTable: React.FC = () => {
    const recordingSolution = useSelector(getCurrentSolutionSortedByRecorderCategory);
    const stats = useSelector(getRecordingSolutionSpecsRecord);

    return (
        <Box direction="column" justifyContent="center">
            <Box
                minWidth={900}
                display="grid"
                grid={{
                    gridTemplateColumns: 'auto repeat(7, minmax(max-content, 1fr))',
                    gridTemplateRows: 'min-content min-content',
                    gridAutoRows: '60px',
                }}
            >
                <SelectedSolutionTableHeader />
                {recordingSolution.map((item, index) => (
                    <SelectedSolutionTableItem
                        key={`${item._id}_${index}`}
                        item={item}
                        stats={stats[item._id]}
                    />
                ))}
                <SelectedSolutionTableFooter />
            </Box>
            <Border radius="0 0 8px 8px" leftWidth={1} rightWidth={1} bottomWidth={1} color="grey3">
                <Box height={16} color="white" overflow="hidden" />
            </Border>
        </Box>
    );
};

SelectedSolutionTable.displayName = 'SelectedSolutionTable';
