import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import type { Id, IPersistence, IItemEntity } from 'app/core/persistence';
import { Box, Text, EditableText, Heading, Card } from 'app/components';
import type { IPiaItemWithPrice } from 'app/modules/common';
import {
    getCurrentProjectItem,
    getCurrentProjectLocked,
    getDeviceChildren,
} from 'app/modules/common';
import { DeviceMenuItem } from '../devices/DeviceMenuItem.container';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import { AppConstants } from 'app/AppConstants';
import { getCompatibleExpansionModules } from 'app/modules/deviceSelector/selectors';
import type { IPiaItem } from 'app/core/pia';
import { AddExpansionModuleMenuItem } from '../devices';

export const IoRelayContextItem: React.FunctionComponent<{
    itemId: Id;
    maxConnections: number;
    ipName?: string;
    onNameChange(name: string): void;
}> = ({ itemId, maxConnections, ipName, onNameChange }) => {
    const compatibleExtensionModules = useSelector<IStoreState, IPiaItemWithPrice<IPiaItem>[]>(
        (state) => getCompatibleExpansionModules(state, itemId),
    );
    const locked = useSelector(getCurrentProjectLocked);
    const device = useSelector(getSelectedDeviceOrParentDevice);
    const item = useSelector<IStoreState, IPersistence<IItemEntity> | undefined | null>((state) =>
        getCurrentProjectItem(state, itemId),
    );
    const children = useSelector<IStoreState, IPersistence<IItemEntity>[]>((state) =>
        getDeviceChildren(state, itemId),
    );

    const usedConnections = children.reduce(
        (sum, expansionModule) => (sum += expansionModule.quantity),
        0,
    );
    const canConnectMoreExpansions = usedConnections < maxConnections;

    return (
        compatibleExtensionModules.length > 0 && (
            <Box direction="column" spacing="panel" width="100%">
                <Card paddingY="base" paddingX="base">
                    <Heading width="100%">
                        <EditableText
                            value={ipName ?? ''}
                            maxLength={AppConstants.deviceNameMaxLength}
                            onChange={onNameChange}
                            placeholder={t.name}
                        />
                    </Heading>
                </Card>

                {children.length > 0 && (
                    <Box direction="column" spacing="quart">
                        {children.map((child) => (
                            <DeviceMenuItem
                                key={child._id}
                                id={child._id}
                                color={device?.color || 'transparent'}
                                disabled={!canConnectMoreExpansions}
                                openSettings={() => {}}
                            />
                        ))}
                    </Box>
                )}
                {item?.productId !== null && !locked && usedConnections < maxConnections && (
                    <Box spacing="quart" direction="column" flex="none">
                        {compatibleExtensionModules.map((module) => (
                            <AddExpansionModuleMenuItem
                                key={module.piaItem.id}
                                locked={locked}
                                text={t.addIORelayExpansion}
                                parentId={itemId}
                                productId={module.piaItem.id}
                            />
                        ))}
                    </Box>
                )}
                <Text
                    testId="expansion_module_quantity"
                    color="grey5"
                    style="semibold"
                    align="center"
                >
                    {t.deviceListUsedModules(usedConnections, maxConnections)}
                </Text>
            </Box>
        )
    );
};

IoRelayContextItem.displayName = 'IoRelayContextItem';
