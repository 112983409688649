export const cssGradient = {
    DIRECTIONS: {
        TO_TOP: 'to top',
        TO_BOTTOM: 'to bottom',
        TO_RIGHT: 'to right',
        TO_LEFT: 'to left',
    },
    linearGradient(direction: string, ...colorStops: string[]): string {
        if (!colorStops.length) {
            throw new Error('linearGradient: missing colorStops');
        }
        direction = direction || this.DIRECTIONS.TO_BOTTOM;
        return `linear-gradient(${direction}, ${colorStops.join(', ')})`;
    },
    colorStop(color: string, stopPosition?: number): string {
        return color + (stopPosition ? ' ' + stopPosition : ' 0');
    },
};
