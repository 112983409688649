import { eventTracking } from './../../tracking/eventTracking';
import type { ILatLng } from './../models/maps/ILatLng';
import { FontSize, FreeTextPointRepository } from '../userDataPersistence';
import type {
    Id,
    IFreeTextPointEntity,
    IFreeTextPointBaseEntity,
    IFreeTextPoint,
    IIdRev,
} from '../userDataPersistence';
import { injectable } from 'inversify';
import { t } from 'app/translate';

@injectable()
export class FreeTextPointService {
    constructor(private repository: FreeTextPointRepository) {}

    public async add(mapId: Id, projectId: Id, location: ILatLng, options: { global: boolean }) {
        eventTracking.logUserEvent('Maps', 'Add free text');
        return this.repository.add(
            this.getDefaultFreeTextPointEntity(mapId, projectId, location, options),
            true,
            false,
        );
    }

    public async update(id: Id, update: Partial<IFreeTextPoint>) {
        return this.repository.updatePartial(id, update, true, true);
    }

    public async updateFreeTextPoints(
        freeTextPoints: IFreeTextPointEntity[],
    ): Promise<IFreeTextPointEntity[]> {
        return this.repository.bulkUpdate(freeTextPoints);
    }

    public async delete(id: Id) {
        eventTracking.logUserEvent('Maps', 'Delete free text');
        return this.repository.deleteById(id, true, true);
    }

    public removeFreeTextPoints(freeTextPoints: IIdRev[]): Promise<IIdRev[]> {
        return this.repository.bulkDelete(freeTextPoints);
    }

    private getDefaultFreeTextPointEntity(
        mapId: Id,
        projectId: Id,
        location: ILatLng,
        options: { global: boolean },
    ): IFreeTextPointBaseEntity {
        // If global, we put the free text point should be directly under the project
        const path = options.global ? [projectId] : [projectId, mapId];

        return {
            type: 'freeTextPoint',
            archived: false,
            location,
            text: t.newText,
            path: path,
            mapOrigin: mapId,
            size: FontSize.small,
            textColor: '#000000',
            backgroundColor: undefined,
        };
    }
}
