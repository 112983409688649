import { SystemAccessoriesActions } from './../state/SystemAccessories.actions';
import { injectable } from 'inversify';
import { IAction, ActionCreator } from 'app/store';
import { DisplayCategory } from '../components/DisplayCategory';

@injectable()
export class SystemAccessoriesActionService {
    @ActionCreator()
    public showCurrentSelection() {
        return {
            type: SystemAccessoriesActions.ShowCurrentSelection,
        };
    }

    @ActionCreator()
    public showSearchResult() {
        return {
            type: SystemAccessoriesActions.ShowSearchResult,
        };
    }

    @ActionCreator()
    public showAllAccessories() {
        return {
            type: SystemAccessoriesActions.ShowAllAccessories,
        };
    }

    @ActionCreator()
    public showCategory(category: DisplayCategory): IAction<DisplayCategory> {
        return {
            type: SystemAccessoriesActions.ShowCategory,
            payload: category,
        };
    }

    @ActionCreator()
    public search(searchText: string): IAction<string> {
        return {
            type: SystemAccessoriesActions.SetSearchText,
            payload: searchText,
        };
    }
}
