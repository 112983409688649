import { css, cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { isPageRTL } from 'app/translate';
import * as React from 'react';

const progressBarStyle = (progress: number, isRTL: boolean) => css`
    width: 100%;
    position: absolute;
    bottom: -11px;
    height: 3px;
    background-color: ${ColorsEnum.yellow5};
    scale: ${progress * 100}% 100%;
    transform-origin: ${isRTL ? 'right' : 'left'};
    opacity: ${progress === 1 ? 0 : 1};
    transition:
        scale 1s ease-out,
        opacity 200ms ease-in 1s;
`;

export const ProjectProgressBar: React.FC<{ progress: number }> = ({ progress }) => {
    return <div className={cx(progressBarStyle(progress, isPageRTL()))} />;
};

ProjectProgressBar.displayName = 'ProjectProgressBar';
