import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, NumberInput, Text } from 'app/components';
import { clamp, getCurrentProjectId, getCurrentProjectRetentionTime } from 'app/modules/common';
import { useService } from 'app/ioc';
import { ProjectService } from 'app/core/persistence';
import { t } from 'app/translate';
import { useDebounce } from 'app/hooks';
import { getIsUsingProjectRetentionTime } from './selectors';

const MIN_RETENTION_TIME = 1;
const MAX_RETENTION_TIME = 9999;

export const ProjectRetentionTimeSetting: React.FC = () => {
    const projectService = useService(ProjectService);
    const projectRetentionTimeInDays = useSelector(getCurrentProjectRetentionTime);
    const projectRetentionTimeInDaysRef = React.useRef(projectRetentionTimeInDays);
    projectRetentionTimeInDaysRef.current = projectRetentionTimeInDays;
    const projectId = useSelector(getCurrentProjectId);
    const [retentionTime, setRetentionTime] = React.useState(projectRetentionTimeInDays);
    const isUsingProjectRetentionTime = useSelector(getIsUsingProjectRetentionTime);

    const persistRetentionTime = useDebounce((newRetentionTime: number) => {
        projectService.update(projectId, {
            recordingRetentionTimeInDays: clamp(
                newRetentionTime,
                MIN_RETENTION_TIME,
                MAX_RETENTION_TIME,
            ),
        });
    });

    React.useEffect(() => {
        if (retentionTime === projectRetentionTimeInDaysRef.current) {
            return;
        }

        persistRetentionTime(retentionTime);
    }, [persistRetentionTime, retentionTime]);

    if (!isUsingProjectRetentionTime) {
        // Don't show the setting if the user is not using project retention time
        return null;
    }

    return (
        <Box direction="column" padding="half" paddingX="base" spacing="base" alignItems="center">
            <Text color="grey6">{t.projectStorageTime}</Text>
            <Box direction="column" spacing="quart" alignItems="center" paddingTop="halfQuart">
                <Box spacing="half" alignItems="center" paddingTop="half">
                    <IconButton
                        icon="remove_circle_outline"
                        size="ms"
                        disabled={retentionTime <= MIN_RETENTION_TIME}
                        onClick={() => setRetentionTime((time) => time - 1)}
                    />
                    <Text onlyStyle style={retentionTime > 999 ? 'heading' : 'headline'}>
                        <NumberInput
                            testId="number_input"
                            value={retentionTime}
                            width="50px"
                            min={MIN_RETENTION_TIME}
                            max={MAX_RETENTION_TIME}
                            step={1}
                            borderRadius="4px"
                            changeCriteria="key"
                            onChange={(newValue) => newValue && setRetentionTime(newValue)}
                            centerText
                            hideSpinner
                        />
                    </Text>
                    <IconButton
                        disabled={retentionTime >= MAX_RETENTION_TIME}
                        size="ms"
                        icon="add_circle_outline"
                        onClick={() => setRetentionTime((time) => time + 1)}
                    />
                </Box>
                <Text color="grey6">{t.daysLowerCase}</Text>
            </Box>
        </Box>
    );
};

ProjectRetentionTimeSetting.displayName = 'ProjectRetentionTimeSetting';
