import * as React from 'react';
import { AppConstants } from 'app/AppConstants';
import { Box, TextInput, Text, Button, Label, Icon, Stack } from 'app/components';
import { t } from 'app/translate';
import { ProfileEditorRow } from './profileEditorRow';

interface IProfileHeaderProps {
    name: string;
    deviceCount: number;
    onNameChange(name: string): void;
    onClose(): void;
}

export const ProfileEditorHeaderComponent: React.FunctionComponent<IProfileHeaderProps> = ({
    name,
    deviceCount,
    onNameChange,
    onClose,
}) => {
    const changeProfileWarning = deviceCount > 0 && (
        <Box alignItems="center">
            <Icon icon="warning" color="blue" size="sm" opaque />
            <Box paddingX="halfQuart">
                <Text>{t.profilesChangeProfileWarning(deviceCount)}</Text>
            </Box>
        </Box>
    );

    return (
        <ProfileEditorRow>
            <Label>{t.profilesProfileName}</Label>
            <Stack alignItems="center">
                <Box flex="shrinkAndGrow">
                    <TextInput
                        maxLength={AppConstants.profileNameMaxLength}
                        value={name}
                        onChange={onNameChange}
                        required
                    />
                </Box>
                {changeProfileWarning}
                <Button testId="close_indoor_scenario_modal" onClick={onClose} text>
                    {t.close}
                </Button>
            </Stack>
        </ProfileEditorRow>
    );
};

ProfileEditorHeaderComponent.displayName = 'ProfileEditorHeaderComponent';
