import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Box, ReadDirection, ReportCard, SectionHeader, Stack, Text } from 'app/components';
import { BandwidthReportSection } from '../../../bandwidthReport';
import { t } from 'app/translate';
import { getFormattedStorage, getFormattedBandwidth } from 'app/modules/common';

interface IStorageAndBandwidthProps {
    formattedStorage: string;
    formattedBandwidth: string;
}

const mapStateToProps = (storeState: IStoreState): IStorageAndBandwidthProps => {
    return {
        formattedStorage: getFormattedStorage(storeState),
        formattedBandwidth: getFormattedBandwidth(storeState),
    };
};

class StorageAndBandwidthContainer extends React.Component<IStorageAndBandwidthProps> {
    public render() {
        return (
            <Box pageBreakBefore>
                <Stack vertical>
                    <SectionHeader text={t.installationReportBandwidthStorageHeader} />
                    <Stack justifyContent="around">
                        <ReportCard flex="shrinkAndGrow">
                            {this.renderTotalsBox(
                                t.installationReportBandwidthStorageTotalStorage,
                                this.props.formattedStorage,
                                'total_estimated_storage_storage_and_bandwidth',
                            )}
                        </ReportCard>
                        <ReportCard cutCorner flex="shrinkAndGrow">
                            {this.renderTotalsBox(
                                t.installationReportBandwidthStorageTotalBandwidth,
                                this.props.formattedBandwidth,
                                'total_estimated_bandwidth_storage_and_bandwidth',
                            )}
                        </ReportCard>
                    </Stack>
                    <BandwidthReportSection />
                </Stack>
            </Box>
        );
    }

    private renderTotalsBox(header: string, value: string, testId: string) {
        return (
            <Stack vertical>
                <Text large semiBold>
                    {header}
                </Text>
                <Stack>
                    <ReadDirection direction="ltr">
                        <Text testId={testId} large bold>
                            {value}
                        </Text>
                    </ReadDirection>
                </Stack>
            </Stack>
        );
    }
}

export const StorageAndBandwidth = connect(mapStateToProps)(StorageAndBandwidthContainer);
