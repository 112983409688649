import type { PanoramaModes } from 'app/core/persistence/models';
import type { IPiaCamera } from 'app/core/pia';

export const getDefaultSensors = (
    horizontalFov: number,
    panoramaMode: PanoramaModes,
    distanceToTarget: number,
    targetHeight: number,
    corridorFormat: boolean,
    piaDevice: IPiaCamera | null,
    nbrOfSensors: number,
) => {
    const maxHorizontalFov = getLimitedHorizontalFov(horizontalFov, panoramaMode, piaDevice);

    const sensors = [...Array(nbrOfSensors)].map((_sensor, index) => ({
        parentPiaDeviceId: piaDevice?.id ?? null,
        sensorId: index + 1,
        isVirtual: false,
        target: {
            distance: distanceToTarget,
            height: targetHeight,
            horizontalAngle: index * 90,
        },
        settings: {
            horizontalFov: maxHorizontalFov,
            corridorFormat: corridorFormat,
        },
    }));

    return sensors;
};

export const getLimitedHorizontalFov = (
    horizontalFov: number,
    panoramaMode: PanoramaModes,
    piaDevice: IPiaCamera | null,
) => {
    if (!piaDevice) {
        // Generic cameras can only have maximum of 145 degrees FOV when not in panorama mode
        return Math.min(horizontalFov, panoramaMode ? 180 : 145);
    }

    // Limit FoV to what the device can have
    const maxHorizontalFov =
        piaDevice.properties.panCameraType === 'Multidirectional'
            ? Math.min(
                  Math.max(horizontalFov, piaDevice.properties.minLensCalcFOV ?? 1),
                  piaDevice.properties.maxLensCalcFOV ?? 145,
              )
            : Math.min(
                  Math.max(horizontalFov, piaDevice.properties.minHorizontalFOV),
                  piaDevice.properties.maxHorizontalFOV,
              );

    return maxHorizontalFov;
};
