import * as React from 'react';
import { connect } from 'react-redux';
import type {
    BandwidthVersion,
    FpsMode,
    Id,
    IPersistence,
    IProfileEntity,
    ZipProfile,
} from 'app/core/persistence';
import { GopMode, ProfileService } from 'app/core/persistence';
import {
    ZipstreamSettingsComponent,
    getCurrentProjectBandwidthVersion,
    getProfile,
    getCurrentProjectProjectZipSetting,
    getDynamicFpsMode,
    getGopMode,
    getZipStrengthValue,
    getUseAverageBitrate,
    getScheduleWithId,
    getZipProfileValue,
} from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';

export interface IZipstreamSettingsOwnProps {
    profileId: Id;
}

interface IZipstreamSettingsProps extends IZipstreamSettingsOwnProps {
    profile?: IPersistence<IProfileEntity>;
    projectBandwidthVersion: BandwidthVersion;
    projectGlobalZipStreamValue: number;
    continuousAlwaysScheduleUsed: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IZipstreamSettingsOwnProps,
): IZipstreamSettingsProps => {
    // for now we only have one systemDefined schedule which is 'Always'
    const profileUsed = getProfile(storeState, ownProps.profileId);
    const continuousAlwaysScheduleUsed =
        profileUsed && profileUsed.continuousRecording.schedule
            ? getScheduleWithId(storeState, profileUsed.continuousRecording.schedule)
                  ?.systemDefined === true
            : false;

    return {
        profile: profileUsed,
        projectBandwidthVersion: getCurrentProjectBandwidthVersion(storeState),
        projectGlobalZipStreamValue: getCurrentProjectProjectZipSetting(storeState),
        continuousAlwaysScheduleUsed,
        ...ownProps,
    };
};
class ZipstreamSettingsContainer extends React.Component<IZipstreamSettingsProps> {
    private profileService: ProfileService;
    constructor(props: IZipstreamSettingsProps) {
        super(props);
        this.profileService = ServiceLocator.get(ProfileService);
    }

    public render() {
        if (this.props.profile) {
            const useProjectSetting = this.props.profile.zipstream.useProjectSetting;
            const zipStrength = getZipStrengthValue(
                this.props.profile.zipstream,
                this.props.projectGlobalZipStreamValue,
            );
            const zipProfile = getZipProfileValue(this.props.profile.zipstream);

            const fpsMode = getDynamicFpsMode(
                this.props.profile.zipstream,
                this.props.projectGlobalZipStreamValue,
            );

            const dynamicGop = getGopMode(
                this.props.profile.zipstream,
                this.props.projectGlobalZipStreamValue,
            );
            const minDynamicFps = this.props.profile.zipstream.minDynamicFps;
            const continuousRecordingFps = this.props.profile.continuousRecording.frameRate;

            const averageBitrateContinuous = getUseAverageBitrate(
                this.props.continuousAlwaysScheduleUsed,
                this.props.profile.zipstream,
                this.props.projectGlobalZipStreamValue,
                this.props.profile.continuousRecording.useAverageBitrate,
            );

            return (
                <ZipstreamSettingsComponent
                    dynamicGop={dynamicGop === GopMode.Dynamic}
                    dynamicGopOverridden={false}
                    zipStrength={zipStrength}
                    zipStrengthOverridden={false}
                    zipProfile={zipProfile}
                    zipProfileOverridden={false}
                    fpsMode={fpsMode}
                    fpsModeOverridden={false}
                    minDynamicFps={minDynamicFps}
                    minDynamicFpsOverridden={false}
                    onMinDynamicFpsChanged={this.onMinDynamicFpsChanged}
                    useProjectSetting={useProjectSetting}
                    projectBandwidthVersion={this.props.projectBandwidthVersion}
                    onDynamicGopChanged={this.onDynamicGopChanged}
                    onZipStrengthChanged={this.onZipStrengthChanged}
                    onZipProfileChanged={this.onZipProfileChanged}
                    onFpsModeChanged={this.onFpsModeChanged}
                    onUseProjectSettingChanged={this.onUseProjectSettingChanged}
                    continuousRecordingFps={continuousRecordingFps}
                    useAverageBitrateContinuous={averageBitrateContinuous}
                    onUseAverageBitrateChanged={this.onUseAverageBitrateChanged}
                    useAverageBitrateOverridden={false}
                    continuousAlwaysScheduleUsed={this.props.continuousAlwaysScheduleUsed}
                />
            );
        }
        return null;
    }

    private onFpsModeChanged = (fpsMode: FpsMode) => {
        if (this.props.profile) {
            this.props.profile.zipstream.fpsMode = fpsMode;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onMinDynamicFpsChanged = (minFps: number) => {
        if (this.props.profile) {
            this.props.profile.zipstream.minDynamicFps = minFps;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onDynamicGopChanged = (dynamicGopEnabled: boolean) => {
        if (this.props.profile) {
            this.props.profile.zipstream.gopMode = dynamicGopEnabled
                ? GopMode.Dynamic
                : GopMode.Fixed;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onZipStrengthChanged = (newZipStrength: number) => {
        if (this.props.profile) {
            this.props.profile.zipstream.zipStrength = newZipStrength;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onZipProfileChanged = (newZipProfile: ZipProfile) => {
        if (this.props.profile) {
            this.props.profile.zipstream.zipProfile = newZipProfile;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onUseProjectSettingChanged = (newUseProjectSetting: boolean) => {
        if (this.props.profile) {
            this.props.profile.zipstream.useProjectSetting = newUseProjectSetting;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onUseAverageBitrateChanged = (newUseAverageBitrate: boolean) => {
        if (this.props.profile) {
            this.props.profile.continuousRecording.useAverageBitrate = newUseAverageBitrate;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };
}

export const ZipstreamSettings = connect(mapStateToProps)(ZipstreamSettingsContainer);
