import type { IPiaSystemComponent } from 'app/core/pia';
import type { IDeviceRequirement, IRecorderDiff, ISwitchDiff } from '../common';
import {
    getRecorderProps,
    getSwitchProps,
    subtractDeviceFromRecorderProps,
    subtractDeviceFromSwitchProps,
} from '../common';
import { bySwitchRequirement } from '../comparators';

/**
 * Partition as many devices as possible to a recorder
 *
 * @param recorder - the recorder
 * @param devices - the devices to partition
 * @return The device partitioning and remaining capacity of the recorder
 */
export const partitionDevicesToRecorder = (
    recorder: IPiaSystemComponent,
    devices: IDeviceRequirement[],
): IRecorderDiff => {
    const remainingDevices: IDeviceRequirement[] = [];
    const selectedDevices: IDeviceRequirement[] = [];

    let recorderProps = getRecorderProps(recorder);

    devices.forEach((device) => {
        const remainingRecorderProps = subtractDeviceFromRecorderProps(recorderProps, device);
        const noCapacityLeft =
            Math.min(
                remainingRecorderProps.maxRecordingStorageMegaBytes,
                remainingRecorderProps.maxRecordingBandwidthBits,
                remainingRecorderProps.maxCameraCount,
                remainingRecorderProps.vmsChannelLicenses,
            ) < 0;

        if (noCapacityLeft) {
            remainingDevices.push(device);
        } else {
            selectedDevices.push(device);
            recorderProps = remainingRecorderProps;
        }
    });

    return {
        recorder,
        remainingDevices,
        selectedDevices,
        overCapacity: recorderProps,
    };
};

/**
 * Partition as many devices as possible to a switch
 *
 * @param networkSwitch - the switch
 * @param devices - the devices to partition
 * @return The device partitioning and remaining capacity of the switch
 */
export const partitionDevicesToSwitch = (
    networkSwitch: IPiaSystemComponent,
    devices: IDeviceRequirement[],
): ISwitchDiff => {
    const remainingDevices: IDeviceRequirement[] = [];
    const selectedDevices: IDeviceRequirement[] = [];

    let switchProps = getSwitchProps(networkSwitch);

    [...devices]
        .sort(bySwitchRequirement)
        .reverse()
        .forEach((device) => {
            const remainingSwitchProps = subtractDeviceFromSwitchProps(switchProps, device);
            const hasCapacityLeft =
                Math.min(
                    remainingSwitchProps.poeTotalPower,
                    remainingSwitchProps.poeClass1PortCount,
                    remainingSwitchProps.poeClass2PortCount,
                    remainingSwitchProps.poeClass3PortCount,
                    remainingSwitchProps.poeClass4PortCount,
                    remainingSwitchProps.poeClass5PortCount,
                    remainingSwitchProps.poeClass6PortCount,
                    remainingSwitchProps.poeClass7PortCount,
                    remainingSwitchProps.poeClass8PortCount,
                    remainingSwitchProps.highPoEPortCount,
                ) >= 0;

            if (hasCapacityLeft) {
                selectedDevices.push(device);
                switchProps = remainingSwitchProps;
            } else {
                remainingDevices.push(device);
            }
        });

    return {
        networkSwitch,
        remainingDevices,
        selectedDevices,
        overCapacity: switchProps,
    };
};
