import { createSelector } from 'reselect';
import { convertFromIpRange } from '../modal/IPRangeSettings/convertFromIPRange';
import { getRequiredIpRangeByType } from './getRequiredIpRangeByType';
import { getNbrNonCorrespondingTypeInRange } from './getNbrNonCorrespondingTypeInRange';
import { getReservedRanges } from './getReservedRanges';

/** Checks if any range lacks room for the number of devices in current project */
export const getHasInsufficientRange = createSelector(
    [getReservedRanges, getRequiredIpRangeByType, getNbrNonCorrespondingTypeInRange],
    (ranges, required, nonCorrespondingByType): boolean => {
        if (!ranges) return false;

        const cameraSlots = convertFromIpRange(ranges.cameras.ipStart, ranges.cameras.ipEnd);
        const otherSlots = convertFromIpRange(ranges.other.ipStart, ranges.other.ipEnd);
        const recorderSlots = convertFromIpRange(ranges.recorders.ipStart, ranges.recorders.ipEnd);

        return (
            cameraSlots < required.cameras + nonCorrespondingByType.cameras ||
            otherSlots < required.other + nonCorrespondingByType.other ||
            recorderSlots < required.recorders + nonCorrespondingByType.recorders
        );
    },
);
