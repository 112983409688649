import type { IPiaPartnerItem } from 'app/core/pia';
import type { IApplicationItem } from 'app/modules/common';

export const toIApplicationItem = (partnerProduct: IPiaPartnerItem): IApplicationItem => ({
    productId: partnerProduct.id,
    name: partnerProduct.name,
    vendor: partnerProduct.properties.vendor,
    url: partnerProduct.properties.url,
    numberOfInstallationsWithAxis: partnerProduct.properties.numberOfInstallationsWithAxis,
    solutionType: partnerProduct.properties.solutionType,
    vendorId: partnerProduct.properties.vendorId,
    categories: partnerProduct.categories,
    category: partnerProduct.category,
});
