import * as React from 'react';
import { DropDown } from '../dropDown';
import { Icon } from '../icon';
import { Border } from '../../style/Border';
import { Box } from '../../containers/box/Box.component';
import type { Colors } from 'app/styles';
import { DropDownMenuColorButton } from 'app/components';

export const ColorPaletteColors = [
    'devicePalette1',
    'devicePalette2',
    'devicePalette3',
    'devicePalette4',
    'devicePalette5',
    'devicePalette6',
    'devicePalette7',
    'devicePalette8',
    'devicePalette9',
] as Colors[];

interface IColorPaletteProps {
    color: Colors;
    updateColor(color: Colors): void;
    disabled?: boolean;
}

export const ColorPalette: React.FunctionComponent<IColorPaletteProps> = ({
    color,
    updateColor,
    disabled,
}) => (
    <DropDown
        disabled={disabled}
        openInPortal
        minWidth={120}
        width={120}
        alignItems="stretch"
        trigger={
            <Border width={0} radius="2px">
                <Box paddingLeft="panel" color={color} alignItems="center" minHeight={30}>
                    <Icon opaque icon="keyboard_arrow_down" color="white" />
                </Box>
            </Border>
        }
        wrapLine={true}
        contents={
            <>
                {ColorPaletteColors.map((theColor) => (
                    <DropDownMenuColorButton
                        key={theColor}
                        color={theColor}
                        selected={theColor === color}
                        onClick={() => updateColor(theColor)}
                        padding="quart"
                    />
                ))}
            </>
        }
    />
);

ColorPalette.displayName = 'ColorPalette';
