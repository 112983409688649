import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { Action, Text, Grid, PillButton, Stack } from 'app/components';
import { FilterPanel } from './FilterPanel';
import type { IOtherDeviceFilter, otherDeviceType } from '../../models';
import { defaultOtherDeviceFilter } from '../../models';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { isEqual } from 'lodash-es';
import { getIsEditMode } from '../../selectors';
import { SearchField } from './common';
import { SustainabilityFilter } from './SustainabilityFilter';

export const OtherDeviceFilterPanel: React.FC = () => {
    const [deviceSelectorActionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);

    const otherDeviceFilter = useSelector<IStoreState, IOtherDeviceFilter>(
        (store) => store.deviceSelector.otherDeviceFilter,
    );

    const isEditMode = useSelector<IStoreState, boolean>((store) => getIsEditMode(store));

    const { selectedCategory } = otherDeviceFilter;

    /**
     * Updates redux state for selected category.
     * Clicking a selected category clears filter.
     * Otherwise, selected category is swapped with the new one.
     * @param filterType - The category to select/deselect
     */
    const updateSelectedCategory = (filterType: otherDeviceType) => {
        deviceSelectorActionService.updateOtherDeviceFilter({
            selectedCategory: filterType === selectedCategory ? undefined : filterType,
        });
    };

    /**
     * Resets access control filter to its default values.
     * Also sets includeDiscontinued to false and removes search text.
     */
    const resetFilter = () => {
        if (searchText) {
            deviceSelectorActionService.setSearchText('');
        }
        if (!isEqual(otherDeviceFilter, defaultOtherDeviceFilter)) {
            deviceSelectorActionService.updateOtherDeviceFilter(defaultOtherDeviceFilter);
        }
    };

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                {!isEditMode && (
                    <>
                        <Stack alignItems="center" justifyContent="between">
                            <Text style="semibold" color="grey6">
                                {t.cameraSelectorRequirementsTitle}
                            </Text>
                            <Action title={t.reset} onClick={resetFilter} />
                        </Stack>
                        <Grid spacing="halfQuart">
                            <PillButton
                                text={t.otherProjectDeviceSelectorCategoriesGROUP.peopleCounters}
                                selected={selectedCategory === 'peopleCounters'}
                                onClick={() => updateSelectedCategory('peopleCounters')}
                            />
                            <PillButton
                                text={t.otherProjectDeviceSelectorCategoriesGROUP.radarDetectors}
                                selected={selectedCategory === 'securityRadars'}
                                onClick={() => updateSelectedCategory('securityRadars')}
                            />
                            <PillButton
                                text={t.otherProjectDeviceSelectorCategoriesGROUP.videoDecoders}
                                selected={selectedCategory === 'videoDecoders'}
                                onClick={() => updateSelectedCategory('videoDecoders')}
                            />
                            <PillButton
                                text={
                                    t.otherProjectDeviceSelectorCategoriesGROUP.audioVisualAlerters
                                }
                                selected={selectedCategory === 'alerters'}
                                onClick={() => updateSelectedCategory('alerters')}
                            />
                            <PillButton
                                text={
                                    t.otherProjectDeviceSelectorCategoriesGROUP.connectivityDevices
                                }
                                selected={selectedCategory === 'connectivityDevices'}
                                onClick={() => updateSelectedCategory('connectivityDevices')}
                            />
                            <PillButton
                                text={t.otherProjectDeviceSelectorCategoriesGROUP.pagingConsoles}
                                selected={selectedCategory === 'pagingConsoles'}
                                onClick={() => updateSelectedCategory('pagingConsoles')}
                            />
                            <SustainabilityFilter />
                        </Grid>
                    </>
                )}
            </Stack>
        </FilterPanel>
    );
};

OtherDeviceFilterPanel.displayName = 'OtherDeviceFilterPanel';
