import * as React from 'react';
import { Box, LinkButton, Text } from 'app/components';
import { t } from 'app/translate';

export const renderEmpty = (projectId: string) => {
    return (
        <Box paddingX="panel">
            <Box
                padding="base"
                color="grey2"
                justifyContent="between"
                flex="shrinkAndGrow"
                alignItems="center"
            >
                <Text faded> {t.deviceListNoAddedDevices}</Text>

                <LinkButton primary link={`/project/${projectId}/devices/`}>
                    {t.quotationAddDevices}
                </LinkButton>
            </Box>
        </Box>
    );
};
