import { useSelector } from 'react-redux';
import * as React from 'react';
import * as leaflet from 'leaflet';
import type {
    DeviceType,
    Id,
    IInstallationPoint,
    IInstallationPointModel,
    ILatLng,
} from 'app/core/persistence';
import { InstallationPointService, deviceTypeCheckers, getDeviceType } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IStoreState } from 'app/store';
import { ServiceLocator, useService } from 'app/ioc';
import { MapsActionService, LeafletItemFactory } from '../../services';
import { CoverageArea } from './cone/CoverageArea';
import { DragCopyMapItem } from './cone/DragCopyMapItem';
import { InstallationPointLabel } from './InstallationPointLabel';
import {
    getIsFocused,
    getInstallationPointColor,
    getInstallationPointForMapItem,
    getParentLocation,
    getUseBigImages,
    getPressedModifierKeys,
    getDuplicationInfo,
    getSelectedDeviceTypeCoverageAreasLayer,
    getLabelsLayerVisible,
    getIsInstallationPointSelected,
    getSelectedMapItem,
    getUseTinyIcons,
} from '../../selectors';
import {
    useDragEndEvent,
    useDragEvent,
    useDragStartEvent,
    useMouseUpEvent,
} from '../../hooks/useLeafletMouseEvent';
import { useKeyDown, usePrevious } from 'app/hooks';
import type { BaseCone } from './cone/BaseCone';
import {
    getDefaultImageForDeviceId,
    getHasExternalIRIllumination,
    getImageForDeviceId,
    getInstallationPoint,
    getImageIconForDeviceId,
} from 'app/modules/common';
import { getIsUserOnline } from 'app/modules/application';
import { useMapContext } from '../context';
import { debounce, isEqual, merge } from 'lodash-es';

const DeleteKey = 'Delete';
const ArrowLeftKey = 'ArrowLeft';
const ArrowRightKey = 'ArrowRight';
const ArrowUpKey = 'ArrowUp';
const ArrowDownKey = 'ArrowDown';
type MoveDistance = {
    left: number;
    right: number;
    up: number;
    down: number;
};
const zeroMoveDistance: MoveDistance = { left: 0, right: 0, up: 0, down: 0 };
const distanceToMoveInPx = 5;
const LeafletItemClass = 'leaflet';

interface IInstallationPointMapItem {
    installationPointId: Id;
}

/**
 * // This installation point will be a 'ghost' during drag to copy/duplicate
 */
export const InstallationPointMapItem: React.FC<IInstallationPointMapItem> = React.memo(
    ({ installationPointId }) => {
        const { leafletMap } = useMapContext();
        const installationPointService = useService(InstallationPointService);
        const installationPoint = useSelector<IStoreState, IInstallationPointModel | undefined>(
            (state) => getInstallationPointForMapItem(state, installationPointId),
        );

        const installationPointEntity = useSelector<IStoreState, IInstallationPoint | undefined>(
            (state) => getInstallationPoint(state, installationPointId),
        );
        const previousInstallationPoint = usePrevious(installationPoint);
        const useBigIcon = useSelector(getUseBigImages);
        const itemImage = useSelector<IStoreState, string>((state) =>
            getImageForDeviceId(state, installationPoint?.parentDevice._id),
        );
        const itemIcon = useSelector<IStoreState, string>((state) =>
            getImageIconForDeviceId(state, installationPoint?.parentDevice._id),
        );
        const itemFallbackImage = useSelector<IStoreState, string>((state) =>
            getDefaultImageForDeviceId(state, installationPoint?.parentDevice._id),
        );
        const isOnline = useSelector(getIsUserOnline);
        const parentLocation = useSelector<IStoreState, ILatLng | undefined>((state) => {
            return getParentLocation(state, installationPoint?.parentId);
        });
        const color = useSelector<IStoreState, Colors>((state) =>
            getInstallationPointColor(state, installationPointId),
        );
        const colorRef = React.useRef(color);
        colorRef.current = color;
        const isSelected = useSelector<IStoreState, boolean>((state) =>
            getIsInstallationPointSelected(state, installationPoint?._id),
        );
        const selectedMapItem = useSelector(getSelectedMapItem);
        const labelsLayerVisible = useSelector<IStoreState, boolean>(getLabelsLayerVisible);
        const isFocused = useSelector<IStoreState, boolean>((state) =>
            getIsFocused(state, installationPointId),
        );
        const modifierKeys = useSelector(getPressedModifierKeys);
        const duplicationInfo = useSelector(getDuplicationInfo);
        const selectedCoverageAreas = useSelector<IStoreState, DeviceType[]>(
            getSelectedDeviceTypeCoverageAreasLayer,
        );
        const hasExternalIlluminator = useSelector<IStoreState, boolean>((state) =>
            getHasExternalIRIllumination(state, installationPoint?.parentDevice._id),
        );
        const useTinyIcons = useSelector(getUseTinyIcons);
        const [factory] = React.useState(ServiceLocator.get(LeafletItemFactory));
        const [actions] = React.useState(ServiceLocator.get(MapsActionService));
        const [cones, setCones] = React.useState<BaseCone[] | null>(null);
        const [deviceImage, setDeviceImage] = React.useState(
            isOnline ? (useTinyIcons ? itemIcon : itemImage) : itemFallbackImage,
        );
        const [draggableMarker] = React.useState(
            factory.createInteractiveItem(
                installationPoint?.location,
                useBigIcon
                    ? factory.createBigDeviceIcon(deviceImage, color)
                    : factory.createSmallDeviceIcon(deviceImage, color),
                !leafletMap.readOnly,
            ),
        );
        const [parentLine] = React.useState(factory.createDashedPolyline([], color));

        const [isDragging, setIsDragging] = React.useState(false);
        const [originalLocation, setOriginalLocation] = React.useState<ILatLng>(
            draggableMarker.getLatLng(),
        );
        const [showCoverageArea, setShowCoverageArea] = React.useState(false);

        const isBeingDuplicated = duplicationInfo?.installationPointId === installationPointId;
        const isOpaque = isBeingDuplicated ? false : isFocused;

        // Active leaflet items (no child items e.g sensor units etc.) can be removed.
        const removeInstallationPoint = () => {
            if (
                isSelected &&
                installationPoint &&
                installationPoint?.parentId === undefined &&
                document.activeElement?.className.includes(LeafletItemClass)
            ) {
                installationPointService.removeInstallationPointDebounced(
                    installationPoint._id,
                    installationPoint._rev,
                );
            }
        };

        const moveInstallationPoint = (moveDistance: Partial<MoveDistance>) => {
            if (!isSelected || !selectedMapItem || !installationPoint) return;
            const moveDirections = { ...zeroMoveDistance, ...moveDistance };
            const projectionPoint = leafletMap.map.project(
                selectedMapItem.location,
                leafletMap.map.getZoom(),
            );
            const newLocationPoint: leaflet.Point = new leaflet.Point(
                projectionPoint.x - moveDirections.left + moveDirections.right,
                projectionPoint.y - moveDirections.up + moveDirections.down,
            );
            const location = leafletMap.map.unproject(newLocationPoint, leafletMap.map.getZoom());
            // Update position of selected item
            actions.updateSelectedMapItem({
                location,
            });
            // Update position in database debounced to avoid many writes to the database
            // when continuously pressing a move key
            updateSelectedMapItemDebounced({ ...installationPoint, location });
        };

        const rotateInstallationPoint = (degrees: number) => {
            if (!cones?.length || !installationPointEntity) return;
            const rotationOffset = degrees;
            const rotatedInstallationPoint = cones.reduce((mergedInstallationPoint, cone) => {
                return merge(
                    mergedInstallationPoint,
                    cone.getRotatedTarget(mergedInstallationPoint, rotationOffset),
                ) as IInstallationPointModel;
            }, installationPointEntity);

            return installationPointService.updateInstallationPoint(
                installationPointId,
                rotatedInstallationPoint,
            );
        };

        const updateSelectedMapItemDebounced = React.useMemo(
            () =>
                debounce(
                    (installationPointToUpdate: IInstallationPointModel) =>
                        actions.updateInstallationPoint(installationPointToUpdate),
                    150,
                ),
            [actions],
        );

        useKeyDown((e) => {
            if (!installationPoint || !isSelected) {
                return;
            }

            if (
                [DeleteKey, ArrowLeftKey, ArrowRightKey, ArrowUpKey, ArrowDownKey].includes(e.key)
            ) {
                e.stopPropagation();
            }

            if (
                modifierKeys.isShiftDown &&
                [ArrowRightKey, ArrowUpKey, ArrowLeftKey, ArrowDownKey].includes(e.key)
            ) {
                const rotationOffset = [ArrowRightKey, ArrowUpKey].includes(e.key) ? 90 : -90;
                return rotateInstallationPoint(rotationOffset);
            }

            if (e.key === DeleteKey) removeInstallationPoint();
            if (e.key === ArrowLeftKey) moveInstallationPoint({ left: distanceToMoveInPx });
            if (e.key === ArrowRightKey) moveInstallationPoint({ right: distanceToMoveInPx });
            if (e.key === ArrowUpKey) moveInstallationPoint({ up: distanceToMoveInPx });
            if (e.key === ArrowDownKey) moveInstallationPoint({ down: distanceToMoveInPx });
        }, leafletMap.map.getContainer());

        useDragStartEvent(draggableMarker, () => {
            // Save the original position, since this might turn into a copy-drag operation
            setOriginalLocation(draggableMarker.getLatLng());
            setIsDragging(true);
            actions.selectMapItem(installationPoint, 'installationPoint');
            draggableMarker.setZIndexOffset(1000);
        });
        useDragEvent(draggableMarker, (e) => {
            if (!installationPoint) return;

            actions.updateSelectedMapItem({
                id: installationPoint._id,
                parentDeviceId: installationPoint.parentDevice._id,
                location: (e as leaflet.LeafletMouseEvent).latlng,
                labelOffset: installationPoint.labelOffset,
                height: installationPoint.height,
            });
        });
        useDragEndEvent(draggableMarker, () => {
            setIsDragging(false);
            if (!installationPoint) return;

            if (modifierKeys.isAltDown) {
                actions.duplicateMapItem(installationPoint, modifierKeys.isControlDown);
            } else {
                actions.updateInstallationPoint(installationPoint);
            }
        });

        useMouseUpEvent(draggableMarker, () => {
            if (!leafletMap.readOnly) {
                actions.selectMapItem(installationPoint, 'installationPoint');
            }
        });

        /** Add/remove installation point to map */
        React.useEffect(() => {
            draggableMarker.addTo(leafletMap.map);
            parentLine?.addTo(leafletMap.map);

            return () => {
                draggableMarker.removeFrom(leafletMap.map);
                parentLine?.removeFrom(leafletMap.map);
            };
        }, [draggableMarker, leafletMap.map, parentLine]);

        // Remove ghost item if alt is released
        React.useEffect(() => {
            if (duplicationInfo && !modifierKeys.isAltDown) {
                actions.setDuplicationInfo(null);
            }
        }, [actions, duplicationInfo, modifierKeys.isAltDown]);

        // This repositions the installationPointMapItem after a copy-drag operation
        React.useEffect(() => {
            if (!isDragging && installationPoint?.location) {
                draggableMarker.setLatLng(installationPoint.location);
            }
        }, [draggableMarker, installationPoint?.location, isDragging]);

        React.useEffect(() => {
            if (parentLine && parentLocation && installationPoint) {
                duplicationInfo &&
                duplicationInfo.installationPointId === installationPoint.parentId
                    ? parentLine.setLatLngs([
                          installationPoint.location,
                          duplicationInfo.originalLocation,
                      ])
                    : parentLine.setLatLngs([installationPoint.location, parentLocation]);
            }
        }, [installationPoint, parentLocation, parentLine, duplicationInfo]);

        React.useEffect(() => {
            const isCamera =
                installationPoint?.parentDevice &&
                deviceTypeCheckers.isCamera(installationPoint?.parentDevice);
            draggableMarker.setIcon(
                useTinyIcons
                    ? factory.createTinyDeviceIcon(
                          deviceImage,
                          isCamera ? installationPoint?.sensors[0]?.target.horizontalAngle + 90 : 0,
                      )
                    : useBigIcon
                      ? factory.createBigDeviceIcon(deviceImage, color)
                      : factory.createSmallDeviceIcon(deviceImage, color),
            );
        }, [
            factory,
            useBigIcon,
            draggableMarker,
            color,
            deviceImage,
            installationPoint?.sensors,
            installationPoint?.parentDevice,
            useTinyIcons,
        ]);

        /** Changes the color of the dotted line to its' parent */
        React.useEffect(() => {
            if (parentLine) {
                parentLine.setStyle({
                    color: ColorsEnum[color],
                });
            }
        }, [color, parentLine]);

        React.useEffect(() => {
            if (isOpaque) {
                draggableMarker?.getElement()?.removeAttribute('data-blurred');
                parentLine?.setStyle({ opacity: 1 });
            } else {
                draggableMarker?.getElement()?.setAttribute('data-blurred', 'true');
                parentLine?.setStyle({ opacity: 0.4 });
            }
        }, [draggableMarker, isOpaque, parentLine]);

        React.useEffect(() => {
            if (isSelected) {
                draggableMarker?.setZIndexOffset(1000);
            } else {
                draggableMarker?.setZIndexOffset(0);
            }
        }, [draggableMarker, isSelected]);

        /** Re-create coverage areas when installation point changes */
        React.useEffect(() => {
            if (!isEqual(previousInstallationPoint, installationPoint)) {
                setCones(
                    factory.createCones(
                        installationPoint,
                        leafletMap,
                        colorRef.current,
                        hasExternalIlluminator,
                    ),
                );
            }
        }, [
            factory,
            hasExternalIlluminator,
            installationPoint,
            leafletMap,
            previousInstallationPoint,
        ]);

        React.useEffect(() => {
            const deviceType = installationPoint && getDeviceType(installationPoint.parentDevice);
            setShowCoverageArea(
                deviceType
                    ? selectedCoverageAreas.includes(deviceType) ||
                          selectedMapItem?.id == installationPoint._id
                    : false,
            );
        }, [installationPoint, selectedCoverageAreas, selectedMapItem?.id]);

        React.useEffect(() => {
            if (isOnline) {
                setDeviceImage(useTinyIcons ? itemIcon : itemImage);
            }
        }, [isOnline, itemIcon, itemImage, useTinyIcons]);

        if (!installationPoint || !cones) return null;

        return (
            <>
                {showCoverageArea &&
                    cones.map((cone, index) => (
                        <CoverageArea
                            key={index}
                            map={leafletMap}
                            cone={cone}
                            installationPoint={installationPoint}
                            color={color}
                            isOpaque={isOpaque}
                        />
                    ))}
                {labelsLayerVisible && !isBeingDuplicated && (
                    <InstallationPointLabel
                        installationPoint={installationPoint}
                        map={leafletMap}
                        color={color}
                    />
                )}
                {isDragging && modifierKeys.isAltDown && (
                    <DragCopyMapItem
                        installationPoint={{ ...installationPoint, location: originalLocation }}
                        map={leafletMap}
                    />
                )}
            </>
        );
    },
);

InstallationPointMapItem.displayName = 'InstallationPointMapItem';
