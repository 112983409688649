import type { IMapsDragDropData } from './../models/IDropData';
import { useState, useEffect } from 'react';
import { flushSync } from 'react-dom';

interface IUseDrag {
    /**
     * A ref to the element that is to be draggable
     */
    ref: React.RefObject<HTMLElement>;
    /**
     * A ref to the HTMLElement to display when dragging the component. This
     * element is bitmap-rendered and shown during drag
     */
    imageRef?: React.RefObject<HTMLElement>;
    /**
     * The dropEffect to use for the drag.
     * Defaults to move
     */
    effect?: 'none' | 'copy' | 'move' | 'link';
    /**
     * An object that will be set as the dataTransfer `source` data,
     * used to identify the dragged item
     */
    data: IMapsDragDropData;
    imageOffsetX?: number;
    imageOffsetY?: number;
    isDragDisabled?: boolean;
    onDragStart?(): void;
    onDragOver?(): void;
    onDragEnd?(): void;
}

/**
 * Enable dragging a component.
 *
 * It uses the `useEffect` hook to add event listeners to the
 * element supplied by the `ref` prop.
 */
export const useDrag = ({
    effect = 'move',
    ref,
    imageRef,
    onDragStart,
    onDragOver,
    onDragEnd,
    imageOffsetX = 0,
    imageOffsetY = 0,
    isDragDisabled = false,
    data,
}: IUseDrag) => {
    const [dragState, updateDragState] = useState('draggable');
    const dragStart = (event: DragEvent) => {
        updateDragState('dragStart');

        const deviceData = JSON.stringify(data);
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = effect;
            event.dataTransfer.setData('text', deviceData);

            if (imageRef?.current) {
                const refImage = imageRef.current;
                refImage.style.opacity = '0.99';
                event.dataTransfer.setDragImage(refImage, imageOffsetX, imageOffsetY);
            }
        }

        if (onDragStart) {
            onDragStart();
        }
    };

    const dragOver = () => {
        updateDragState('dragOver');
        if (onDragOver) {
            onDragOver();
        }
    };

    const dragEnd = () => {
        // Without flushSync React 18 batches these updates so that dragEnd never gets set.
        flushSync(() => {
            updateDragState('dragEnd');
        });
        if (onDragEnd) {
            onDragEnd();
        }
        // reset state when drag end is finished
        updateDragState('draggable');
    };

    useEffect(() => {
        const element = ref.current;
        if (!isDragDisabled && element) {
            element.setAttribute('draggable', 'true');
            element.addEventListener('dragstart', dragStart);
            element.addEventListener('dragover', dragOver);
            element.addEventListener('dragend', dragEnd);
            return () => {
                element.removeEventListener('dragstart', dragStart);
                element.removeEventListener('dragover', dragOver);
                element.removeEventListener('dragend', dragEnd);
            };
        } else if (element) {
            element.setAttribute('draggable', 'false');
        }
    });
    return {
        dragState,
    };
};
