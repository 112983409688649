import { isDefined } from 'axis-webtools-util';
import type { IPiaItem } from 'app/core/pia';
import { nameComparator } from 'app/utils';
import { getPiaItemsRecord } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import { CategoryGroupsEnum, getCategoryGroup } from '../services/CategoryGroups.service';
import { t } from 'app/translate';

function getDeviceSortOrder(category: CategoryGroupsEnum): number {
    switch (category) {
        case CategoryGroupsEnum.Cameras:
            return 0;
        case CategoryGroupsEnum.Modulars:
            return 1;
        case CategoryGroupsEnum.EncodersDecoders:
            return 2;
        case CategoryGroupsEnum.Audio:
            return 3;
        case CategoryGroupsEnum.Pacs:
            return 4;
        case CategoryGroupsEnum.Wearables:
            return 5;
        case CategoryGroupsEnum.Recorders:
            return 6;
        case CategoryGroupsEnum.Software:
            return 7;
        default:
            return 8;
    }
}

const getAllowedProductsPiaIds = (state: IStoreState) => state.partnerConfig.allowedProducts;

export const getPartnerConfigProducts = createSelector(
    [getAllowedProductsPiaIds, getPiaItemsRecord],
    (recommendedProductsPiaIds, piaDevices) => {
        return recommendedProductsPiaIds
            .map((piaId) => piaDevices[piaId])
            .filter(isDefined)
            .sort(nameComparator);
    },
);

export const getPartnerProductsByCategory = createSelector(
    [getPartnerConfigProducts],
    (products) => {
        const productsByCategory = products.reduce(
            (byCategory, product) => {
                const productCategory = getCategoryGroup(product.category);
                byCategory[productCategory] = [...(byCategory[productCategory] ?? []), product];
                return byCategory;
            },
            {} as Record<CategoryGroupsEnum, IPiaItem[]>,
        );

        return (Object.keys(productsByCategory) as CategoryGroupsEnum[])
            .sort((catA, catB) => getDeviceSortOrder(catA) - getDeviceSortOrder(catB))
            .reduce(
                (productsSortedByNameAndCategory, category) => {
                    const translatedCategory = t[category] ?? '';
                    productsSortedByNameAndCategory[translatedCategory] =
                        productsByCategory[category];
                    return productsSortedByNameAndCategory;
                },
                {} as Record<string, IPiaItem[]>,
            );
    },
);

const getSelectedProductsSearchText = (store: IStoreState) =>
    store.partnerConfig.selectedProductsSearchText;

export const getFilteredPartnerProductsByCategory = createSelector(
    [getPartnerProductsByCategory, getSelectedProductsSearchText],
    (products, searchText) => {
        if (!searchText) {
            return products;
        }

        const filteredProducts: Record<string, IPiaItem[]> = {};

        Object.keys(products).forEach((category) => {
            filteredProducts[category] = products[category].filter((product) =>
                product.name.toLowerCase().includes(searchText.toLowerCase()),
            );
        });

        return filteredProducts;
    },
);
