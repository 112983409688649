import * as React from 'react';
import type { IStoreState } from 'app/store';
import type { PreviewImageType } from 'app/modules/common';
import {
    getPixelsPerUnitSystemAbbreviation,
    getPiaCameraForProductId,
    getPreviewImageType,
} from 'app/modules/common';
import type { IPiaCamera, IPiaSensorUnit, PiaId } from 'app/core/pia';
import { useSelector } from 'react-redux';
import { ResolutionPreviewComponent } from './ResolutionPreview.component';

interface IResolutionPreviewProps {
    hidePixelPerUnit?: boolean;
    productId: PiaId;
    pixelDensityPerMeter: number;
}

export const ResolutionPreview: React.FC<IResolutionPreviewProps> = ({
    hidePixelPerUnit,
    productId,
    pixelDensityPerMeter,
}) => {
    const unitSystem: string = useSelector(getPixelsPerUnitSystemAbbreviation);
    const piaCamera = useSelector<IStoreState, IPiaCamera | IPiaSensorUnit | undefined>((state) =>
        getPiaCameraForProductId(state, productId),
    );
    const previewImageType = useSelector<IStoreState, PreviewImageType>((state) =>
        getPreviewImageType(state, piaCamera?.category),
    );

    if (productId === null) {
        return null;
    }
    return (
        <ResolutionPreviewComponent
            pixelDensityPerMeter={pixelDensityPerMeter}
            resolutionPreviewImageType={previewImageType}
            unit={unitSystem}
            hidePixelPerUnit={hidePixelPerUnit}
            piaCamera={piaCamera}
        />
    );
};

ResolutionPreview.displayName = 'ResolutionPreview';
