import * as React from 'react';
import { connect } from 'react-redux';
import { Box, ProgressIndicator, Text, Stack, Icon } from 'app/components';
import { t } from 'app/translate';
import type { Id } from 'app/core/persistence';
import { ProjectService, QuotationProgress as QuotationProgressState } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { isProjectStateQuotationProgress, QuotationProgressOrder } from '../../util';
import {
    getCurrentProjectId,
    getCurrentProjectLocked,
    getCurrentProjectQuotationProgress,
} from 'app/modules/common';

interface IQuotationProgressProps {
    projectQuotationProgress: QuotationProgressState;
    projectId: Id;
    projectLocked: boolean;
}

interface IQuotationProgressState {
    updatingLockState: boolean;
}

function mapStateToProps(storeState: IStoreState): IQuotationProgressProps {
    return {
        projectQuotationProgress: getCurrentProjectQuotationProgress(storeState),
        projectId: getCurrentProjectId(storeState),
        projectLocked: getCurrentProjectLocked(storeState),
    };
}

class QuotationProgressContainer extends React.Component<
    IQuotationProgressProps,
    IQuotationProgressState
> {
    private projectService: ProjectService;

    constructor(props: IQuotationProgressProps) {
        super(props);
        this.projectService = ServiceLocator.get(ProjectService);
        this.state = { updatingLockState: false };
    }

    public render() {
        return (
            <Stack vertical spacing="none">
                <ProgressIndicator
                    first
                    state={this.getProgressIndicatorState(QuotationProgressState.Designing)}
                    active={
                        this.props.projectQuotationProgress === QuotationProgressState.Designing
                    }
                    disabled={this.state.updatingLockState}
                    onClick={() => this.updateSalesQuoteProgress(QuotationProgressState.Designing)}
                >
                    <Stack alignItems="center" spacing="half">
                        <Text semiBold color="inherit">
                            {t.quotationStateGROUP.design}
                        </Text>
                        <Icon icon="lock_open" opaque size="ms" />
                    </Stack>
                </ProgressIndicator>
                <ProgressIndicator
                    state={this.getProgressIndicatorState(QuotationProgressState.QuoteSent)}
                    active={
                        this.props.projectQuotationProgress === QuotationProgressState.QuoteSent
                    }
                    disabled={this.state.updatingLockState}
                    onClick={() => this.updateSalesQuoteProgress(QuotationProgressState.QuoteSent)}
                >
                    <Box>
                        <Text semiBold color="inherit">
                            {t.quotationStateGROUP.quoteSent}
                        </Text>
                    </Box>
                </ProgressIndicator>
                <ProgressIndicator
                    state={this.getProgressIndicatorState(QuotationProgressState.QuoteAccepted)}
                    active={
                        this.props.projectQuotationProgress === QuotationProgressState.QuoteAccepted
                    }
                    disabled={this.state.updatingLockState}
                    onClick={() =>
                        this.updateSalesQuoteProgress(QuotationProgressState.QuoteAccepted)
                    }
                >
                    <Box>
                        <Text semiBold color="inherit">
                            {t.quotationStateGROUP.quoteAccepted}
                        </Text>
                    </Box>
                </ProgressIndicator>
                <ProgressIndicator
                    state={this.getProgressIndicatorState(QuotationProgressState.Installed)}
                    active={
                        this.props.projectQuotationProgress === QuotationProgressState.Installed
                    }
                    disabled={this.state.updatingLockState}
                    onClick={() => this.updateSalesQuoteProgress(QuotationProgressState.Installed)}
                >
                    <Box>
                        <Text semiBold color="inherit">
                            {t.quotationStateGROUP.installed}
                        </Text>
                    </Box>
                </ProgressIndicator>
                <ProgressIndicator
                    last
                    state={this.getProgressIndicatorState(QuotationProgressState.Done)}
                    active={this.props.projectQuotationProgress === QuotationProgressState.Done}
                    disabled={this.state.updatingLockState}
                    onClick={() => this.updateSalesQuoteProgress(QuotationProgressState.Done)}
                >
                    <Box>
                        <Text semiBold color="inherit">
                            {t.quotationStateGROUP.done}
                        </Text>
                    </Box>
                </ProgressIndicator>
            </Stack>
        );
    }

    private getProgressIndicatorState(
        state: QuotationProgressState,
    ): 'ok' | 'finished' | undefined {
        const progressState = this.props.projectQuotationProgress;

        if (!isProjectStateQuotationProgress(progressState)) {
            return;
        }

        if (progressState === QuotationProgressState.Done) {
            return 'finished';
        }

        if (QuotationProgressOrder[state] > QuotationProgressOrder[progressState]) {
            return undefined;
        }

        return 'ok';
    }

    private updateSalesQuoteProgress = async (progress: QuotationProgressState) => {
        if (this.state.updatingLockState) {
            return;
        }

        await this.projectService.updateCurrentProject({
            state: progress,
        });
        const shouldBeLocked = progress !== QuotationProgressState.Designing;
        if (this.props.projectLocked !== shouldBeLocked) {
            this.setState({ ...this.state, updatingLockState: true });
            await this.projectService.updateLockStatus(this.props.projectId, shouldBeLocked);
            this.setState({ ...this.state, updatingLockState: false });
        }
    };
}

export const QuotationProgress = connect(mapStateToProps)(QuotationProgressContainer);
