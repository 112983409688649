import * as React from 'react';
import { Button, Card, Stack, Box, DataWidget, Spacer } from 'app/components';
import type { IProjectNetworkSettings } from 'app/core/persistence';
import { generateExcelFile } from 'app/utils/generateExcelFile';
import { t } from 'app/translate';
import { IPAddressField } from '../components/IPAddressField';
import { useSelector } from 'react-redux';
import { getCurrentProjectItemsWithNetworkSettings, getIpExcelData } from '../selectors';

const networkImage = require('assets/images/network.svg');

interface IProjectNetworkCardProps {
    projectNetworkSettings: IProjectNetworkSettings;
    nbrRequiredIPAddresses: number;
    nbrTotalAvailableIPAddresses: number;
    onDelete?(): void;
    onEdit?(): void;
}

export const ProjectNetworkCard: React.FC<IProjectNetworkCardProps> = ({
    projectNetworkSettings,
    nbrRequiredIPAddresses,
    nbrTotalAvailableIPAddresses,
    onDelete,
    onEdit,
}) => {
    const ipExcelData = useSelector(getIpExcelData);
    const hasIpAddresses = useSelector(getCurrentProjectItemsWithNetworkSettings).length > 0;
    const availableAddresses = nbrTotalAvailableIPAddresses - nbrRequiredIPAddresses;

    const downloadIpExcelSheet = () => {
        const { fileName, title, headers, dataRows } = ipExcelData;
        generateExcelFile(fileName, title, headers, dataRows);
    };

    return (
        <Card paddingX="base" paddingY="base">
            <Stack spacing="panel" alignItems="end" flex="fullWidth">
                <Box
                    direction="column"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    flex="none"
                >
                    <img src={networkImage} />
                </Box>
                {projectNetworkSettings.dhcp ? (
                    <Box direction="column" minHeight="130px" flex="shrinkAndGrow">
                        <IPAddressField testId="DHCP" label={t.ipAddressRange} value={t.useDHCP} />
                    </Box>
                ) : (
                    <Stack vertical spacing="half" flex="shrinkAndGrow">
                        <IPAddressField
                            testId="IP_Address"
                            label={t.ipAddressRange}
                            value={t.ipRangeIpStartToIpEnd(
                                projectNetworkSettings.ipStart,
                                projectNetworkSettings.ipEnd,
                            )}
                        />
                        <IPAddressField
                            testId="Subnet_mask"
                            label={t.ipSubnetMask}
                            value={projectNetworkSettings.subnetMask}
                        />
                        <IPAddressField
                            testId="Default_router"
                            label={t.ipDefaultRouter}
                            value={projectNetworkSettings.defaultRouter}
                        />
                    </Stack>
                )}
                <Box direction="column" justifyContent="end" height="100%" flex="none">
                    {!projectNetworkSettings.dhcp && (
                        <Box padding="none" alignItems="start" height="100%" justifyContent="end">
                            <DataWidget
                                testId="nbr_available_ip_addresses"
                                text={availableAddresses}
                                description={t.ipAvailableAddresses}
                                chart={{
                                    chartType: 'pie',
                                    value: nbrRequiredIPAddresses,
                                    maxValue: nbrTotalAvailableIPAddresses,
                                }}
                            />
                        </Box>
                    )}
                    <Stack spacing="none" alignItems="end" justifyContent="end">
                        {hasIpAddresses && (
                            <Button text noXPadding onClick={downloadIpExcelSheet}>
                                {t.quotationExportToExcel}
                            </Button>
                        )}
                        {onDelete && (
                            <>
                                <Spacer horizontal />
                                <Button
                                    testId={`project_network_card_delete_btn`}
                                    text
                                    noXPadding
                                    onClick={onDelete}
                                >
                                    {t.remove}
                                </Button>
                            </>
                        )}
                        {onEdit && (
                            <>
                                <Spacer horizontal />
                                <Button
                                    testId={`project_network_card_edit_btn`}
                                    text
                                    noXPadding
                                    onClick={onEdit}
                                >
                                    {t.edit}
                                </Button>
                            </>
                        )}
                    </Stack>
                </Box>
            </Stack>
        </Card>
    );
};

ProjectNetworkCard.displayName = 'ProjectNetworkCard';
