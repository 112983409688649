import * as React from 'react';
import type { IAutoTestable } from 'app/components';
import { Box, ReadDirection, RingChart, Text } from 'app/components';
import { t } from 'app/translate';

interface ICompactRequirementItem extends IAutoTestable {
    label: string;
    percent: number;
    inSolutionText: React.ReactNode;
    requiredText: React.ReactNode;
    warn: boolean;
    notApplicable?: boolean;
}

export const CompactRequirementItem: React.FC<ICompactRequirementItem> = ({
    label,
    percent,
    inSolutionText,
    requiredText,
    warn,
    notApplicable,
    testId,
}) => {
    const color = warn ? 'red' : 'green';

    return (
        <Box testId={testId} alignItems="start" spacing="half">
            <RingChart
                smallText
                percent={notApplicable ? 0 : percent}
                backgroundColor={notApplicable ? 'grey3' : undefined}
                hidePercent={notApplicable}
                setBackgroundAsColor={!warn && !notApplicable}
                text={notApplicable ? t.abbreviationsGROUP.notApplicable : undefined}
                size="36px"
            />
            <Box direction="column">
                <Text style="heading" color="grey9">
                    {label}
                </Text>
                {notApplicable && (
                    <Text style="semibold" whiteSpace="nowrap" color="grey6" lineHeight={1}>
                        {t.abbreviationsGROUP.notApplicable}
                    </Text>
                )}
                {!notApplicable && (
                    <Box spacing="quart">
                        <ReadDirection direction="ltr">
                            <Text style="semibold" whiteSpace="nowrap" color="grey6" lineHeight={1}>
                                {inSolutionText}
                            </Text>
                        </ReadDirection>
                        <Text color="grey6" lineHeight={1}>
                            |
                        </Text>
                        <ReadDirection direction="ltr">
                            <Text style="semibold" whiteSpace="nowrap" color={color} lineHeight={1}>
                                {requiredText}
                            </Text>
                        </ReadDirection>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

CompactRequirementItem.displayName = 'CompactRequirementItem';
