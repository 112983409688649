import type { Icons } from 'app/components';
import { Border, Box, Clickable, Icon, Positioned, Stack } from 'app/components';
import type { Id } from 'app/core/persistence';
import { useService } from 'app/ioc';
import {
    getHasAccessories,
    getHasApplications,
    getHasRequiredComponents,
    getModelName,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ProjectDevicesActionService } from '../../../services';
import type { PiaId } from 'app/core/pia';
import { SpacingsEnum } from 'app/styles';

interface IAddOnsCol {
    itemId: Id;
    piaId: PiaId | null;
}

type AddOn = 'applications' | 'accessories';

export const AddOnsCol: React.FC<IAddOnsCol> = ({ itemId, piaId }) => {
    const model = useSelector<IStoreState, string>((state) => getModelName(state, itemId));

    const actionService = useService(ProjectDevicesActionService);
    const hasAccessories = useSelector<IStoreState, boolean | undefined>((state) =>
        getHasAccessories(state, itemId),
    );
    const hasApplications = useSelector<IStoreState, boolean | undefined>((state) =>
        getHasApplications(state, itemId),
    );
    const needRequiredAccessory = useSelector<IStoreState, boolean>(
        (state) => !getHasRequiredComponents(state, itemId),
    );

    const onEditAccessories = () => {
        actionService.togglePanel(itemId, 'accessories');
    };
    const onEditApplications = () => {
        actionService.togglePanel(itemId, 'applications');
    };

    const renderAddOnsButton = (
        addOn: AddOn,
        icon: Icons,
        onEdit: () => void,
        hasAddOns?: boolean,
    ) => {
        const hidden = hasAddOns === undefined;
        const noAddOnStyle = hidden ? 'transparent' : 'grey3';

        return (
            <Clickable onClick={onEdit} disabled={hidden}>
                <Border radius={SpacingsEnum.quart} color={hasAddOns ? 'blue3' : noAddOnStyle}>
                    <Box
                        testId={`${addOn}_${model}_${hasAddOns ? 'blue' : 'grey'}`}
                        padding="base"
                        alignItems="center"
                        justifyContent="center"
                        flex="none"
                        position="relative"
                    >
                        <Icon
                            icon={icon}
                            hidden={hidden}
                            opaque={hasAddOns}
                            color={hasAddOns ? 'blue4' : 'grey4'}
                        />
                        {addOn === 'accessories' && needRequiredAccessory && (
                            <Positioned position="absolute" top="4px" right="4px">
                                <Icon icon="warning_small" color="yellow7" size="xs" />
                            </Positioned>
                        )}
                    </Box>
                </Border>
            </Clickable>
        );
    };

    const renderAccessoryIcon = () => {
        return renderAddOnsButton('accessories', 'category', onEditAccessories, hasAccessories);
    };
    const renderApplicationIcon = () => {
        return renderAddOnsButton('applications', 'extension', onEditApplications, hasApplications);
    };

    return (
        <Stack spacing="halfCell" justifyContent="start">
            {piaId && renderAccessoryIcon()}
            {piaId && renderApplicationIcon()}
        </Stack>
    );
};

AddOnsCol.displayName = 'AddOnsCol';
