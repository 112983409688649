import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IPersistenceRepository } from '../../repositories';
import { v4 as uuidv4 } from 'uuid';

/**
 * Removes relations on item entity properties and instead uses
 * ItemRelationEntity to resolve relations.
 */
@injectable()
export class Migration7To8 extends MigrationProviderBase {
    public from: number = 7;
    public to: number = 8;

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'item' && entity.relations) {
                for (const relation of entity.relations) {
                    const id = 'itemRelation:' + uuidv4();

                    await repository.add({
                        parentId: entity._id,
                        childId: relation.id,
                        relationType: relation.type,
                        _id: id,
                        _rev: '',
                        creationDate: new Date().toISOString(),
                        updatedDate: new Date().toISOString(),
                        entityVersion: this.to,
                        type: 'itemRelation',
                        path: [...entity.path, id],
                    } as any);
                }
                delete entity.relations;
            }

            return entity;
        };
    }
}
