import { IconText } from 'app/components';
import { roundNumber } from 'app/core/common';
import { t } from 'app/translate';
import * as React from 'react';

interface ISubscriptionProps {
    subscriptionIntervalInMonths?: number;
}

export const Subscription: React.FC<ISubscriptionProps> = ({ subscriptionIntervalInMonths }) => {
    if (subscriptionIntervalInMonths && subscriptionIntervalInMonths % 12 !== 0) {
        console.error('Unexpected subscription interval, translation needs to be added');
    }

    return subscriptionIntervalInMonths ? (
        <IconText
            icon="info"
            iconProps={{ color: 'blue', size: 'sm', opaque: true }}
            textProps={{ color: 'grey7', style: 'small', whiteSpace: 'nowrap' }}
        >
            {subscriptionIntervalInMonths === 12
                ? t.subscriptionIntervalOne(roundNumber(subscriptionIntervalInMonths / 12, 1))
                : t.subscriptionIntervalMany(roundNumber(subscriptionIntervalInMonths / 12, 1))}
        </IconText>
    ) : null;
};
Subscription.displayName = 'Subscription';
