import { reducerBuilder } from 'app/store';
import { PersistenceActions, ProjectDbOrigin, getItemLocalStorageJSON } from 'app/core/persistence';
import type { Id, IProjectEntity, IPersistence, IDetailedOrganization } from 'app/core/persistence';
import type { IDeepProjectData, IUserProjectsState } from './IUserProjectsState';
import type { ISharedProjectTreeInfo, IUserProjectsListItem, ProjectsSortOrder } from '../models';
import { UserProjectsActions } from './UserProjects.actions';
import { mapGeneratedTokenToProjectSettingToken } from '../selectors/getProjectSettingToken';

const initialState: IUserProjectsState = {
    userProjects: [],
    deepProjectData: {},
    loading: false,
    loaded: false,
    error: null,
    replicating: false,
    userProjectsFilter: '',
    duplicatedProjectId: null,
    showArchivedProjects: false,
    sortOrder: { sort: 'updated', direction: 'ascending' },
    selectedProjectIds: [],
    currentView: getItemLocalStorageJSON('LatestProjectView', ProjectDbOrigin.asdUserData),
    organizations: [],
    sharedProjectTreeInfo: undefined,
    selectedOrganization: undefined,
};

export const userProjectsReducer = reducerBuilder<IUserProjectsState>()
    .setInitialState(initialState)
    .onAsyncAction(UserProjectsActions.INITIALIZE, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                loading: true,
                loaded: false,
            }))
            .onFulfilled<IUserProjectsListItem[]>((state, action) => ({
                ...state,
                userProjects: action.payload,
                loading: false,
                loaded: true,
                error: null,
                duplicatedProjectId: null,
            }))
            .onRejected<Error>((state, action) => ({
                ...state,
                loading: false,
                loaded: true,
                error: action.payload,
            }));
    })
    .onAsyncAction(UserProjectsActions.GET_DEEP_PROJECT_DATA, (handler) => {
        handler.onFulfilled<IDeepProjectData | null>((state, action) =>
            action.payload
                ? {
                      ...state,
                      deepProjectData: {
                          ...state.deepProjectData,
                          [action.payload.id]: action.payload,
                      },
                  }
                : state,
        );
    })
    .onAction<string>(UserProjectsActions.USER_PROJECTS_FILTER_CHANGE, (state, action) => {
        return {
            ...state,
            userProjectsFilter: action.payload,
        };
    })
    .onAction<Id>(UserProjectsActions.SELECT_PROJECT, (state, action) => {
        return {
            ...state,
            selectedProjectIds: [...state.selectedProjectIds, action.payload],
        };
    })
    .onAction<Id>(UserProjectsActions.UNSELECT_PROJECT, (state, action) => {
        return {
            ...state,
            selectedProjectIds: state.selectedProjectIds.filter((id) => id !== action.payload),
        };
    })
    .onAction<any>(UserProjectsActions.CLEAR_SELECTED_PROJECTS, (state) => {
        return {
            ...state,
            selectedProjectIds: [],
        };
    })
    .onAction<any>(UserProjectsActions.SET_ORGANIZATIONS, (state, action) => ({
        ...state,
        organizations: action.payload,
    }))
    .onAction<Id>(PersistenceActions.PROJECT_DELETED, (state, action) => {
        const userProjects = state.userProjects.filter((project) => action.payload !== project.id);
        if (userProjects.length === state.userProjects.length) {
            // optimization: don't update state if no change
            // We tend to get lots of tombstones from pouch and don't want the state
            // to mutate the redux state if we don't have anything to delete
            return state;
        }

        return {
            ...state,
            userProjects,
            selectedProjectIds: state.selectedProjectIds.filter((id) => action.payload !== id),
        };
    })
    .onAction<IPersistence<IProjectEntity>>(PersistenceActions.PROJECT_UPDATED, (state, action) => {
        let updated = false;

        // if we already have the project in the state we update it
        const userProjects: IUserProjectsListItem[] = state.userProjects.map((project) => {
            if (project.id === action.payload._id) {
                updated = true;
                return {
                    ...project,
                    name: action.payload.name,
                    shareToken: mapGeneratedTokenToProjectSettingToken(action.payload.shareToken),
                    updatedDate: new Date(action.payload.updatedDate),
                    rev: action.payload._rev,
                    archived: Boolean(action.payload.archived),
                    state: action.payload.state,
                    lastExportedDate: new Date(action.payload.lastExportedDate),
                };
            } else {
                return project;
            }
        });

        if (!updated) {
            // we now know that the project isn't in the state already so we can safely Append it
            userProjects.push({
                ...action.payload,
                name: action.payload.name,
                shareToken: mapGeneratedTokenToProjectSettingToken(action.payload.shareToken),
                creationDate: new Date(action.payload.creationDate),
                updatedDate: new Date(action.payload.updatedDate),
                id: action.payload._id,
                rev: action.payload._rev,
                archived: Boolean(action.payload.archived),
                state: action.payload.state,
                lastExportedDate: new Date(action.payload.lastExportedDate),
                devicesQuantity: 0,
                hasFloorPlanOrLocation: false,
            });
        }

        return {
            ...state,
            userProjects,
        };
    })
    .onAction<Array<Id>>(UserProjectsActions.MULTI_DELETE_USERPROJECTS, (state, action) => ({
        ...state,
        userProjects: state.userProjects.filter((project) => !action.payload.includes(project.id)),
        selectedProjectIds: state.selectedProjectIds.filter((id) => !action.payload.includes(id)),
    }))
    .onAsyncAction(UserProjectsActions.DELETE_USERPROJECT, (handler) => {
        handler
            .onFulfilled<string>((state, action) => ({
                ...state,
                userProjects: state.userProjects.filter((project) => project.id !== action.payload),
            }))
            .onRejected<undefined>((state) => ({
                ...state,
                loaded: false,
            }));
    })
    .onAction<IUserProjectsListItem>(UserProjectsActions.ADD_PROJECT, (state, action) => {
        const exists = state.userProjects.some(({ id }) => id === action.payload.id);
        if (exists) {
            // Project already exists in state, do nothing
            return state;
        }

        return {
            ...state,
            userProjects: state.userProjects.concat(action.payload),
        };
    })
    .onAction<any>(UserProjectsActions.CLEAR_USER_PROJECTS, (state) => {
        return {
            ...state,
            userProjects: [],
        };
    })
    .onAction<boolean>(UserProjectsActions.SHOW_ARCHIVED_PROJECTS, (state, action) => ({
        ...state,
        showArchivedProjects: action.payload,
    }))
    .onAction<ProjectsSortOrder>(UserProjectsActions.SET_ORDER, (state, action) => ({
        ...state,
        sortOrder: action.payload,
    }))
    .onAction<ProjectDbOrigin>(UserProjectsActions.SET_CURRENT_VIEW, (state, action) => ({
        ...state,
        currentView: action.payload,
    }))
    .onAction<boolean>(UserProjectsActions.SET_USER_PROJECTS_LOADED, (state, action) => ({
        ...state,
        loaded: action.payload,
    }))
    .onAction<ISharedProjectTreeInfo>(
        UserProjectsActions.SET_SHARED_PROJECT_INFO,
        (state, action) => ({
            ...state,
            sharedProjectTreeInfo: action.payload,
        }),
    )
    .onAction<IDetailedOrganization | undefined>(
        UserProjectsActions.SET_SELECTED_ORGANIZATION,
        (state, action) => ({
            ...state,
            selectedOrganization: action.payload,
        }),
    )
    .onAsyncAction(UserProjectsActions.REPLICATE, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                replicating: true,
            }))
            .onFulfilled((state) => ({
                ...state,
                replicating: false,
            }))
            .onRejected<Error>((state) => ({
                ...state,
                replicating: false,
            }));
    })
    .create();
