import * as React from 'react';
import {
    Clickable,
    Box,
    Icon,
    Text,
    Border,
    LoadingIndicator,
    Modal,
    Spacer,
} from 'app/components';
import { productItemStyle } from 'app/modules/common';
import { useService } from 'app/ioc';
import { ModalService } from 'app/modal';
import { getIsUserOnline } from 'app/modules/application';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { Id } from 'app/core/persistence';
import ErrorBoundaryLazy from './ErrorBoundaryLazy';
import { eventTracking } from 'app/core/tracking';
import { getShowPartnerAcapListModal } from '../selectors';
import { AddonSelectorActionService } from '../services';

const PartnerProductsLazy = React.lazy(() => import('./PartnerApplications'));

interface IAddParentApplicationProps {
    parentId: Id;
}

export const AddPartnerApplication: React.FC<IAddParentApplicationProps> = ({ parentId }) => {
    const addonSelectorActionService = useService(AddonSelectorActionService);

    const isOnline = useSelector(getIsUserOnline);
    const modalService = useService(ModalService);
    const showPartnerListModal = useSelector(getShowPartnerAcapListModal);

    const showInfoMessage = () => {
        modalService.createConfirmDialog({
            header: t.errorLoadingPartnerApplication,
            body: t.errorLoadingPartnerApplicationMessage,
            confirmButtonText: t.close,
        })();
    };

    const setShowPartnerAcapListModal = () => {
        eventTracking.logUserEvent('Application Selector', 'List partner applications');
        isOnline ? addonSelectorActionService.setShowPartnerAcapListModal(true) : showInfoMessage();
    };

    const closePartnerListModal = () => {
        addonSelectorActionService.setShowPartnerAcapListModal(false);
    };

    return (
        <>
            <div className={productItemStyle}>
                {showPartnerListModal && (
                    <React.Suspense
                        fallback={
                            <Modal>
                                <Box
                                    width="197px"
                                    height="250px "
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <LoadingIndicator message={t.loading} />
                                </Box>
                            </Modal>
                        }
                    >
                        <ErrorBoundaryLazy
                            onClose={() => closePartnerListModal()}
                            title={t.errorLoadingPartnerApplication}
                        >
                            <PartnerProductsLazy parentId={parentId} />
                        </ErrorBoundaryLazy>
                    </React.Suspense>
                )}
                <Clickable onClick={setShowPartnerAcapListModal}>
                    <Border color="grey3" radius="8px">
                        <Box
                            testId="open_add_partner_application_modal"
                            color="transparent"
                            direction="column"
                            alignItems="center"
                            width="197px"
                            height="250px"
                        >
                            <Spacer customSpacing="15px" />
                            <Box
                                paddingTop="base"
                                direction="column"
                                justifyContent="between"
                                alignItems="center"
                            >
                                <Icon icon="add_circle_outline" size="xxl" color="blue" opaque />
                            </Box>
                            <Box
                                direction="column"
                                height="35px"
                                alignItems="center"
                                paddingX="half"
                                maxWidth="100%"
                                justifyContent="end"
                            >
                                <Text bold color="blue" align="center">
                                    {t.addPartnerApplication}
                                </Text>
                            </Box>
                            <Box padding="half">
                                <Text color="grey6" align="center" wordBreak="normal">
                                    {t.findPartnerApplications}
                                </Text>
                            </Box>
                        </Box>
                    </Border>
                </Clickable>
            </div>
        </>
    );
};

AddPartnerApplication.displayName = 'AddPartnerApplication';
