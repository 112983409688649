import { injectable } from 'inversify';
import { preprocessComponent, RECOMMENDATION_VALIDATION_MAX_DEVICES } from '../selectors';
import type {
    IValidationEventData,
    IComponentWithQuantity,
    IDeviceRequirement,
    ISDCardComponent,
    IValidationResult,
} from '../selectors';
import type { RecordingSolutionType } from 'app/core/persistence';
import ValidationWorker from 'worker-loader!../workers/ValidationWorker';

const VALIDATION_TIMEOUT = 20 * 1000;

const isThirdPartySolutionType = (recordingSolutionType: RecordingSolutionType | undefined) =>
    recordingSolutionType === 'Milestone' || recordingSolutionType === 'Genetec';

@injectable()
export class RecordingValidatorService {
    constructor() {}

    public validateSystem = async (
        requirements: IDeviceRequirement[],
        components: IComponentWithQuantity[],
        sdCardRequirements: ISDCardComponent[],
        basicCheckOk: boolean,
        isSuperset: boolean,
        selectedRecordingSolutionType: RecordingSolutionType | undefined,
    ): Promise<IValidationResult | null> => {
        this.cancelCurrentValidation();
        if (!basicCheckOk || isSuperset) {
            return null; // no need to run expensive validation
        }

        if (isThirdPartySolutionType(selectedRecordingSolutionType)) {
            return null; // we don't validate third party vendors at the moment
        }

        // We don't need licenses for S30 Companion systems
        const licensesNotRequired = selectedRecordingSolutionType === 'AxisCompanionS30';
        const preprocessedComponents = components.map(({ component, quantity }) => ({
            component: preprocessComponent(component, licensesNotRequired),
            quantity,
        }));

        return new Promise<IValidationResult>((resolve, reject) => {
            if (requirements.length > RECOMMENDATION_VALIDATION_MAX_DEVICES) {
                return reject(Error('System too large for validation'));
            }

            let timer = 0;
            const worker = new ValidationWorker();

            const cleanup = () => {
                this.cancelCurrentValidation = () => {};
                worker.terminate();
                clearTimeout(timer);
            };

            timer = window.setTimeout(() => {
                cleanup();
                reject(Error('timeout'));
            }, VALIDATION_TIMEOUT);

            worker.addEventListener('message', (evt: MessageEvent<IValidationResult>) => {
                cleanup();
                resolve(evt.data);
            });

            worker.addEventListener('error', (evt) => {
                cleanup();
                reject(evt);
            });

            this.cancelCurrentValidation = () => {
                cleanup();
                reject(Error('cancelled'));
            };

            worker.postMessage({
                requirements,
                preprocessedComponents,
                sdCardRequirements,
                selectedRecordingSolutionType,
            } as IValidationEventData);
        });
    };

    private cancelCurrentValidation = () => {};
}
