import type { IProductNameParts } from '../../models';

export function getProductNameParts(productName: string): IProductNameParts {
    const nameParts = productName.split(' ');

    return {
        model: nameParts.splice(0, 2).join(' '),
        description: nameParts.join(' '),
    };
}
