import type { Icons } from 'app/components';
import type { Colors } from 'app/styles';

export type ToastType = 'success' | 'info' | 'warning' | 'error' | 'progress';

export class ToastModel {
    private deleteTimeout: NodeJS.Timeout | undefined;

    constructor(
        readonly id: number,
        public headline: string,
        public message: string | undefined,
        public icon: Icons | undefined,
        readonly secondsToLive: number = 0,
        public toastType: ToastType = 'success',
        readonly deleteAction: (toastToDelete: ToastModel) => void,
    ) {}

    public startDeletionTimer() {
        if (this.deleteTimeout) {
            // Already started
            return;
        }
        this.deleteTimeout = setTimeout(() => this.deleteAction(this), this.secondsToLive * 1000);
    }

    public stopDeletionTimer() {
        if (this.deleteTimeout) {
            clearTimeout(this.deleteTimeout);
        }
    }

    public getColor(): Colors {
        switch (this.toastType) {
            case 'error':
                return 'red';
            case 'info':
                return 'blue';
            case 'progress':
                return 'grey9';
            case 'success':
                return 'green';
            case 'warning':
                return 'yellowDark';
            default:
                return 'grey9';
        }
    }
}
