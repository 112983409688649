import * as React from 'react';
import { Text, TextPlaceholder } from 'app/components';
import type { PiaId } from 'app/core/pia';
import { usePromise } from 'app/hooks';
import { useService } from 'app/ioc';
import DOMPurify from 'dompurify';
import { DeviceDescriptionService } from 'app/core/persistence';
import { isAxis } from 'app/modules/common';

interface IApplicationInfoProps {
    /** Pia Id of the requested product */
    productId: PiaId;
    /** Vendor of the application */
    vendorName: string;
    /** Requests longDescriptiveCopy instead of descriptiveCopy */
    longDescription?: boolean;
}

export const ApplicationInfo: React.FC<IApplicationInfoProps> = ({
    productId,
    vendorName,
    longDescription,
}) => {
    const deviceDescriptionService = useService(DeviceDescriptionService);
    const isPartnerProduct = !isAxis(vendorName);
    const descriptionPromise = React.useMemo(
        () =>
            longDescription
                ? deviceDescriptionService.getLongDescription(productId, isPartnerProduct)
                : deviceDescriptionService.getShortDescription(productId),
        [deviceDescriptionService, isPartnerProduct, longDescription, productId],
    );

    const { result: description, pending: loading } = usePromise(descriptionPromise, [
        descriptionPromise,
    ]);

    if (loading) {
        return <TextPlaceholder rows={5} />;
    }

    return description ? (
        <Text onlyStyle>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
        </Text>
    ) : null;
};

ApplicationInfo.displayName = 'ApplicationInfo';
