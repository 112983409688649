import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    getCurrentProjectId,
    getCurrentProjectName,
    getPowerDeviceItems,
} from 'app/modules/common';
import { EmptyReport, ReportHeader } from '../../components';
import { t } from 'app/translate';
import type { Id } from 'app/core/persistence';
import { Box, Exclude, IconButton, PrintPanel } from 'app/components';
import { BandwidthReportSection } from './BandwidthReportSection.container';
import { PowerReportSection } from './PowerReportSection.container';
import { eventTracking } from 'app/core/tracking';
import { getBandwidthDeviceIds } from '../../selectors';
import { generateExcelFile } from 'app/utils/generateExcelFile';

interface IBandwidthReportProps {
    projectId: Id;
    projectName: Id;
    hasDevices: boolean;
}

const mapStateToProps = (storeState: IStoreState): IBandwidthReportProps => ({
    projectId: getCurrentProjectId(storeState),
    projectName: getCurrentProjectName(storeState),
    hasDevices:
        getBandwidthDeviceIds(storeState).length > 0 || getPowerDeviceItems(storeState).length > 0,
});

class BandwidthReportContainer extends React.Component<IBandwidthReportProps> {
    private tableRefBandwidth: HTMLTableElement | null;
    private tableRefPowerDevice: HTMLTableElement | null;
    private tableRefPowerIlluminators: HTMLTableElement | null;
    private tableRefPowerRecorders: HTMLTableElement | null;

    constructor(props: IBandwidthReportProps) {
        super(props);
        this.tableRefBandwidth = null;
        this.tableRefPowerDevice = null;
        this.tableRefPowerIlluminators = null;
        this.tableRefPowerRecorders = null;
    }

    public render() {
        return this.props.hasDevices ? (
            this.renderReport()
        ) : (
            <EmptyReport
                projectId={this.props.projectId}
                projectName={this.props.projectName}
                text={t.bandwidthAndPowerReportNoDevices}
            />
        );
    }

    private renderReport() {
        return (
            <PrintPanel
                testId="panel_reports_bandwidth_report_panel"
                buttons={
                    <Exclude for="iOS">
                        <IconButton
                            icon="file_download"
                            text={t.quotationExportToExcel}
                            onClick={this.exportToExcel}
                        />
                    </Exclude>
                }
                printable
                onPrint={this.trackPrintEvent}
            >
                <ReportHeader title={t.projectReportsPowerAndBandwidthReportHeading} />
                <Box spacing="panel" direction="column">
                    <PowerReportSection
                        tableRefDevice={(element) => (this.tableRefPowerDevice = element)}
                        tableRefIlluminator={(element) =>
                            (this.tableRefPowerIlluminators = element)
                        }
                        tableRefRecorders={(element) => (this.tableRefPowerRecorders = element)}
                    />
                    <BandwidthReportSection
                        showTotals
                        tableRef={(element) => (this.tableRefBandwidth = element)}
                    />
                </Box>
            </PrintPanel>
        );
    }

    private exportToExcel = () => {
        if (!this.tableRefBandwidth) {
            eventTracking.logError(
                'Could not find tableRef when exporting to excel',
                'BandwidthReport',
            );
            return;
        }

        const tables: HTMLTableElement[] = [];
        if (this.tableRefPowerDevice) {
            tables.push(this.tableRefPowerDevice);
        }
        if (this.tableRefPowerIlluminators) {
            tables.push(this.tableRefPowerIlluminators);
        }
        if (this.tableRefPowerRecorders) {
            tables.push(this.tableRefPowerRecorders);
        }
        if (this.tableRefBandwidth) {
            tables.push(this.tableRefBandwidth);
        }

        generateExcelFile(
            t.bandwidthReportExcelFileName(this.props.projectName),
            t.projectReportsPowerAndBandwidthReportHeading,
            [],
            [],
            tables,
        );

        eventTracking.logUserEvent('Reports', 'Export to Excel', 'Bandwidth Report');
        eventTracking.pushToGA4('SiteDesignerClick', 'Export', 'Bandwidth Report', 'Excel');
    };

    private trackPrintEvent() {
        eventTracking.logUserEvent('Reports', 'Print', 'Bandwidth Report');
        eventTracking.pushToGA4('SiteDesignerClick', 'Print', 'Bandwidth Report');
    }
}

export const PowerAndBandwidthReport = connect(mapStateToProps)(BandwidthReportContainer);
