import type { IPersistence, IItemEntity, IItemRelationEntity, Id } from 'app/core/persistence';

export const countUses = (
    profileId: string,
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    relations: Array<IPersistence<IItemRelationEntity>>,
) => {
    return Object.values(items).reduce((acc, item) => {
        let cameraCount = 0;
        if (item?.properties.camera) {
            cameraCount =
                item?.properties.camera.associatedProfile === profileId ? item?.quantity : 0;
        } else if (item?.properties.encoder) {
            const itemRelations = relations.filter((relation) => relation.parentId === item._id);
            cameraCount =
                item?.quantity * calculateTotalAnalogCameraUses(items, itemRelations, profileId);
        } else if (item?.properties.mainUnit) {
            const itemRelations = relations.filter((relation) => relation.parentId === item._id);
            cameraCount =
                item?.quantity * calculateTotalSensorUnitUses(items, itemRelations, profileId);
        }

        return acc + cameraCount;
    }, 0);
};

const calculateTotalAnalogCameraUses = (
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    itemRelations: ReadonlyArray<IItemRelationEntity>,
    profileId: Id,
): number =>
    itemRelations.reduce((totalCameraCount, itemRelation) => {
        const item = items[itemRelation.childId];
        return (
            totalCameraCount +
            (itemRelation.relationType === 'analogCamera' &&
            item?.properties.analogCamera?.associatedProfile === profileId
                ? item?.quantity
                : 0)
        );
    }, 0);

const calculateTotalSensorUnitUses = (
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    itemRelations: ReadonlyArray<IItemRelationEntity>,
    profileId: Id,
): number =>
    itemRelations.reduce((totalCameraCount, itemRelation) => {
        const item = items[itemRelation.childId];
        return (
            totalCameraCount +
            (itemRelation.relationType === 'sensorUnit' &&
            item?.properties.sensorUnit?.associatedProfile === profileId
                ? item?.quantity
                : 0)
        );
    }, 0);
