import { type ILatLng } from 'app/core/persistence';
import { useService } from 'app/ioc';
import * as React from 'react';
import { LeafletMap } from '../components/map/LeafletMap';
import { MapsActionService } from '../services';
import { DEFAULT_GOOGLE_MAP_ID } from '../constants';
import { useSelector } from 'react-redux';
import { getCurrentProjectUnitSystem } from 'app/modules/common';

interface IUseMap {
    /** A reference to the html div in which the map is rendered */
    mapDivRef: React.RefObject<HTMLElement>;
    /** Zoom level changed */
    onZoomLevelChanged(zoomLevel: number): void;
    onLocationChanged(location: ILatLng): void;
    /** Id of the google map div, defaults to DEFAULT_GOOGLE_MAP_ID */
    googleMapDivId?: string;
    /** Sets the map to read only
     * Hides controls and menu.
     * Disables interaction with markers and cones.
     * Labels are still possible to move.
     */
    readOnly?: boolean;
}

export const useMap = ({
    mapDivRef,
    onZoomLevelChanged,
    onLocationChanged,
    googleMapDivId = DEFAULT_GOOGLE_MAP_ID,
    readOnly,
}: IUseMap) => {
    const [map, setMap] = React.useState<LeafletMap>();
    const mapsActionService = useService(MapsActionService);
    const unitSystem = useSelector(getCurrentProjectUnitSystem);

    React.useEffect(() => {
        if (!mapDivRef.current) {
            return;
        }

        const leafletMap = new LeafletMap(
            mapDivRef.current,
            onZoomLevelChanged,
            onLocationChanged,
            mapsActionService,
            unitSystem,
            readOnly,
            googleMapDivId,
        );

        setMap(leafletMap);

        return leafletMap.destroyMap;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapDivRef]);

    return map;
};
