import { injectable } from 'inversify';
import type { IBaseEntity, IItemEntity } from '../../entities';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Add property filter.outdoor
 */
const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

@injectable()
export class Migration41to42 extends MigrationProviderBase {
    public from: number = 41;
    public to: number = 42;

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (entity.properties.doorController) {
                    entity.properties.doorController = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.alerter) {
                    entity.properties.alerter = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.pac) {
                    entity.properties.pac = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.radarDetector) {
                    entity.properties.radarDetector = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.encoder) {
                    entity.properties.encoder.filter = {
                        ...entity.properties.encoder.filter,
                        outdoor: false,
                    };
                }
                if (entity.properties.decoder) {
                    entity.properties.decoder = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.peopleCounter) {
                    entity.properties.peopleCounter = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.bodyWornCamera) {
                    entity.properties.bodyWornCamera = {
                        ...entity.properties.bodyWornCamera,
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.cameraExtension) {
                    entity.properties.cameraExtension = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.dockingStation) {
                    entity.properties.dockingStation = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.radarDetector) {
                    entity.properties.radarDetector = {
                        filter: {
                            outdoor: false,
                        },
                    };
                }
                if (entity.properties.mainUnit) {
                    entity.properties.mainUnit.filter = {
                        ...entity.properties.mainUnit.filter,
                        outdoor: false,
                    };
                }
            }

            return entity;
        };
    }
}
