import { injectable } from 'inversify';
import {
    CleanupService,
    OfflineService,
    ProjectDbOrigin,
    ReplicationService,
} from 'app/core/persistence';
import { t } from 'app/translate';
import { getPartnerBase } from 'app/partnerUrl';
import { ModalService } from 'app/modal';
import { InitializeDataStorageService } from '../../services/InitializeUserDataStorage.service';

@injectable()
export class LoginService {
    private confirmLogout = this.modalService.createConfirmDialog(t.confirmLogoutGROUP);
    private confirmUnsaved = this.modalService.createConfirmDialog(t.confirmLogoutUnsavedGROUP);
    private confirmOffline = this.modalService.createConfirmDialog(t.confirmLogoutOfflineGROUP);

    constructor(
        private cleanupService: CleanupService,
        private modalService: ModalService,
        private offlineService: OfflineService,
        private replicationService: ReplicationService,
        private initializeDataStorageService: InitializeDataStorageService,
    ) {}

    public login(url: string): void {
        this.redirect(url);
    }

    public async logout(url: string): Promise<void> {
        let confirmed = false;
        const isOnline = await this.offlineService.isOnline();
        if (!isOnline) {
            confirmed = !(await this.confirmOffline());
        } else if (isOnline && !(await this.replicationService.isChangesReplicated())) {
            confirmed = !(await this.confirmUnsaved());
        } else {
            confirmed = await this.confirmLogout();
        }

        if (!confirmed) {
            return;
        }

        await this.initializeDataStorageService.switchDataRepository(ProjectDbOrigin.asdUserData);
        await this.cleanupService.clearAllUserData();

        const href = window.location.origin + (getPartnerBase() || '');
        if (isOnline) {
            // if we are online we redirect to perform a real server side logout
            this.redirect(url);
        } else {
            // else we just refresh
            this.redirect(href);
        }
    }

    private redirect(url: string, href = '') {
        window.location.href = url + href;
    }
}
