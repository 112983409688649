import { isCamera, isSensorUnit } from 'app/core/persistence';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';
import { getPiaLensesRecord, getPiaItemsRecord } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import { isPixelDensityFulfilled } from '../utils';
import type { IProductLensRecord } from './getBestReplacementLens';
import { findBestLens, getTrueDistanceAndDesiredFilter } from './getBestReplacementLens';
import { getCameraFilter, getSensorUnitFilter } from './getProductsForDeviceGroup';

const getProductIdsToCompare = (state: IStoreState) => state.deviceSelector.productsToCompare;

export const getCompareProductLenses = createSelector(
    [
        getCameraFilter,
        getProductIdsToCompare,
        getPiaLensesRecord,
        getSensorUnitFilter,
        getPiaItemsRecord,
    ],
    (
        cameraFilter,
        productIds,
        piaLensRecord,
        sensorUnitFilter,
        piaItemsRecord,
    ): IProductLensRecord => {
        const piaDevices = productIds.reduce(
            (devices, id) => {
                const piaItem = piaItemsRecord[id];
                if (!piaItem) {
                    return devices;
                }
                return isCamera(piaItem)
                    ? [...devices, piaItem as IPiaCamera]
                    : isSensorUnit(piaItem)
                      ? [...devices, piaItem as IPiaSensorUnit]
                      : devices;
            },
            [] as (IPiaCamera | IPiaSensorUnit)[],
        );

        const lensForDeviceRecord: IProductLensRecord = {};
        piaDevices.forEach((device) => {
            const { desiredFilter, trueDistance } = getTrueDistanceAndDesiredFilter(
                cameraFilter,
                device,
                sensorUnitFilter,
            );
            const pixelDensityFulfilled = isPixelDensityFulfilled(
                device.properties,
                desiredFilter,
                trueDistance,
            );

            const lens = findBestLens(
                cameraFilter,
                device,
                piaLensRecord,
                pixelDensityFulfilled,
                sensorUnitFilter,
            );

            lensForDeviceRecord[device.id] = lens;
        });
        return lensForDeviceRecord;
    },
);
