function addPrefix(val: string) {
    return `PARTNER_CONFIG_${val}`;
}

export const PartnerConfigActions = {
    SetPartnerConfig: addPrefix('SET_PARTNER_CONFIG'),
    SetStyle: addPrefix('SET_THEME'),
    SetLogo: addPrefix('SET_LOGO'),
    SetName: addPrefix('SET_NAME'),
    SetExcludedProductMessage: addPrefix('SET_EXCLUDED_PRODUCT_MESSAGE'),
    SetProductFilter: addPrefix('SET_FILTER'),
    SetSearchFilter: addPrefix('SET_SEARCH_FILTER'),
    SetSelectedProductsSearch: addPrefix('SET_SEARCH_TEXT'),
    SetAccessoriesSearchText: addPrefix('SET_ACCESSORIES_SEARCH_TEXT'),
    SetCustomPartNumber: addPrefix('SET_CUSTOM_PART_NUMBER'),
    AddToAllowList: addPrefix('ADD_TO_ALLOWED'),
    RemoveFromAllowList: addPrefix('REMOVE_FROM_ALLOWED'),
    ToggleEditProducts: addPrefix('TOGGLE_EDIT_PRODUCTS'),
    IsPartnerConfigEnabled: addPrefix('TOGGLE_PARTNER_CONFIG'),
    IsPartnerUrlEnabled: addPrefix('TOGGLE_PARTNER_URL'),
    IsOnlyRecommendedProductsAvailable: addPrefix('TOGGLE_RECOMMENDED_ONLY'),
    IsCustomPartNumberEnabled: addPrefix('TOGGLE_CUSTOM_PART_NUMBER'),
};
