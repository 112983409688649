import type { IMappedPartNumber } from './models/IMappedPartNumber';
import type { PiaId } from 'app/core/pia';
import { reducerBuilder } from 'app/store';
import type { IPartnerHeaderStyle } from 'app/core/persistence';
import type { ISearchFilter } from './models/IPartnerConfigState';
import { PartnerConfigActions } from './partnerConfig.actions';
import type { IPartnerConfigState } from './models';
import { CategoryGroupsEnum } from './services/CategoryGroups.service';

const initialState: IPartnerConfigState = {
    partnerConfigId: undefined,
    companyName: '',
    allowedProducts: [],
    mappedPartNumbers: {},
    isPartnerConfigEnabled: false,
    isPartnerConfigUrlEnabled: false,
    isOnlySelectedProductsAvailable: false,
    isCustomPartNumberEnabled: false,
    excludedProductMessage: '',
    searchFilter: {
        searchText: '',
        category: CategoryGroupsEnum.Cameras,
    },
    accessoriesSearchText: '',
    selectedProductsSearchText: '',
};

export const PartnerConfigReducer = reducerBuilder<IPartnerConfigState>()
    .setInitialState(initialState)
    .onAction<IPartnerConfigState>(PartnerConfigActions.SetPartnerConfig, (state, action) => ({
        ...state,
        ...action.payload,
    }))
    .onAction<string>(PartnerConfigActions.SetName, (state, action) => ({
        ...state,
        companyName: action.payload,
    }))
    .onAction<string | undefined>(PartnerConfigActions.SetLogo, (state, action) => ({
        ...state,
        logo: action.payload,
    }))
    .onAction<IPartnerHeaderStyle>(PartnerConfigActions.SetStyle, (state, action) => ({
        ...state,
        style: { ...state.style, ...action.payload },
    }))
    .onAction<PiaId>(PartnerConfigActions.AddToAllowList, (state, action) => {
        if (state.allowedProducts.includes(action.payload)) {
            return state;
        }

        return {
            ...state,
            allowedProducts: [...state.allowedProducts, action.payload],
        };
    })
    .onAction<PiaId>(PartnerConfigActions.RemoveFromAllowList, (state, action) => ({
        ...state,
        allowedProducts: state.allowedProducts.filter((piaId) => piaId !== action.payload),
    }))
    .onAction<boolean>(PartnerConfigActions.IsPartnerConfigEnabled, (state, action) => ({
        ...state,
        isPartnerConfigEnabled: action.payload,
    }))
    .onAction<boolean>(PartnerConfigActions.IsPartnerUrlEnabled, (state, action) => ({
        ...state,
        isPartnerConfigUrlEnabled: action.payload,
    }))
    .onAction<boolean>(
        PartnerConfigActions.IsOnlyRecommendedProductsAvailable,
        (state, action) => ({
            ...state,
            isOnlySelectedProductsAvailable: action.payload,
        }),
    )
    .onAction<boolean>(PartnerConfigActions.IsCustomPartNumberEnabled, (state, action) => ({
        ...state,
        isCustomPartNumberEnabled: action.payload,
    }))
    .onAction<string>(PartnerConfigActions.SetExcludedProductMessage, (state, action) => ({
        ...state,
        excludedProductMessage: action.payload,
    }))
    .onAction<Partial<ISearchFilter>>(PartnerConfigActions.SetSearchFilter, (state, action) => ({
        ...state,
        searchFilter: {
            ...state.searchFilter,
            ...action.payload,
        },
    }))
    .onAction<string>(PartnerConfigActions.SetAccessoriesSearchText, (state, action) => ({
        ...state,
        accessoriesSearchText: action.payload,
    }))
    .onAction<string>(PartnerConfigActions.SetSelectedProductsSearch, (state, action) => ({
        ...state,
        selectedProductsSearchText: action.payload,
    }))
    .onAction<IMappedPartNumber>(PartnerConfigActions.SetCustomPartNumber, (state, action) => {
        if (!action.payload.customPartNumber) {
            const mappedPartNumbers = state.mappedPartNumbers;
            delete mappedPartNumbers[action.payload.piaId];
            return {
                ...state,
                mappedPartNumbers,
            };
        }

        state.mappedPartNumbers[action.payload.piaId] = action.payload.customPartNumber;
        return state;
    })
    .create();
