import { deviceTypeCheckers, isDeviceSpecified, isCustomCamera } from 'app/core/persistence';

import type { IPersistence, IItemEntity } from 'app/core/persistence';

export const isBandwidthDevice = (device: IPersistence<IItemEntity>): boolean => {
    return (
        ((deviceTypeCheckers.isCamera(device) ||
            deviceTypeCheckers.isEncoder(device) ||
            deviceTypeCheckers.isBodyWornCamera(device) ||
            deviceTypeCheckers.isVirtualProduct(device) ||
            deviceTypeCheckers.isMainUnit(device)) &&
            isDeviceSpecified(device)) ||
        isCustomCamera(device)
    );
};
