import { css } from '@emotion/css';
import { Icons, Box, IconText, Clickable, Border } from 'app/components';
import { useResizeObserver } from 'app/hooks';
import { copyToClipboard } from 'app/modules/common';
import * as React from 'react';

const getStyle = (rows: number) => css`
    display: grid;
    grid-template-rows: repeat(${rows}, 1fr);
    grid-auto-columns: minmax(250px, 1fr);
    grid-auto-flow: column;
    gap: 4px;
`;

export const IconContent: React.FC = () => {
    const [rows, setRows] = React.useState(1);
    const { ref: containerRef } = useResizeObserver<HTMLDivElement>({
        onResize: () => {
            const columnCount = Math.floor((containerRef.current?.offsetWidth ?? 250) / 250);
            const rowCount = Math.ceil(Icons.length / columnCount);
            setRows(rowCount);
        },
    });
    return (
        <div ref={containerRef} className={getStyle(rows)}>
            {Icons.map((icon) => (
                <Clickable key={icon} onClick={() => copyIconName(icon)}>
                    <Border hoverColor="blue" radius="4px" color="transparent">
                        <Box hoverColor="white" padding="quart">
                            <IconText
                                icon={icon}
                                iconProps={{ color: 'blue', size: 'ml', opaque: true }}
                            >
                                {icon}
                            </IconText>
                        </Box>
                    </Border>
                </Clickable>
            ))}
        </div>
    );
};

function copyIconName(iconName: string) {
    copyToClipboard(iconName, `${iconName} copied`, `${iconName} has been copied to clipboard`);
}
