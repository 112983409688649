import * as React from 'react';
import { t } from 'app/translate';
import type { IAutoTestable } from 'app/components';
import { Text, CutCorner, Stack, Box } from 'app/components';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { getDevicePower } from '../../selectors';
import type { IDataToDisplay } from '../../../models';
import {
    getDeviceBandwidthTotalSystemProposal,
    getDeviceStorageTotalSystemProposal,
} from 'app/modules/common';

interface IPropsalDeviceDetailedSpecificationOwnProps extends IAutoTestable {
    id: Id;
}

interface IPropsalDeviceDetailedSpecificationProps extends IAutoTestable {
    bandwidth?: string;
    storage?: string;
    power: IDataToDisplay | undefined;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IPropsalDeviceDetailedSpecificationOwnProps,
): IPropsalDeviceDetailedSpecificationProps => {
    return {
        bandwidth: getDeviceBandwidthTotalSystemProposal(storeState, ownProps.id),
        storage: getDeviceStorageTotalSystemProposal(storeState, ownProps.id),
        power: getDevicePower(storeState, ownProps.id),
    };
};

class ProposalDeviceDetailedSpecificationContainer extends React.Component<IPropsalDeviceDetailedSpecificationProps> {
    public render() {
        const { bandwidth, storage, power, testId } = this.props;
        const bandwidthCount = bandwidth?.split(' ')[0];
        const bandwidthUnit = bandwidth?.split(' ')[1];
        const storageCount = storage?.split(' ')[0];
        const storageUnit = storage?.split(' ')[1];

        return (
            <Stack testId={`${testId}_bandwidth_storage_power_container`} spacing="cell">
                {bandwidth && (
                    <CutCorner smallCut color="warmGrey6">
                        <Box
                            paddingLeft="panel"
                            paddingRight="panel"
                            paddingY="base"
                            minWidth="100px"
                        >
                            <Stack alignItems="center" spacing="halfQuart" vertical>
                                <Text color="white" small>
                                    {t.bandwidth}
                                </Text>
                                <Text
                                    testId={`${testId}_bandwidth_estimation_value_txt`}
                                    style="headline"
                                    color="white"
                                >
                                    {bandwidthCount}
                                </Text>
                                <Text
                                    testId={`${testId}_bandwidth_estimation_unit_txt`}
                                    style="semibold"
                                    color="white"
                                >
                                    {bandwidthUnit}
                                </Text>
                            </Stack>
                        </Box>
                    </CutCorner>
                )}
                {storage && (
                    <CutCorner smallCut color="warmGrey6">
                        <Box
                            paddingLeft="panel"
                            paddingRight="panel"
                            paddingY="base"
                            minWidth="100px"
                        >
                            <Stack alignItems="center" spacing="halfQuart" vertical>
                                <Text color="white" small>
                                    {t.storage}
                                </Text>
                                <Text
                                    testId={`${testId}_storage_estimation_value_txt`}
                                    style="headline"
                                    color="white"
                                >
                                    {storageCount}
                                </Text>
                                <Text
                                    testId={`${testId}_storage_estimation_unit_txt`}
                                    style="semibold"
                                    color="white"
                                >
                                    {storageUnit}
                                </Text>
                            </Stack>
                        </Box>
                    </CutCorner>
                )}
                {power?.value && (
                    <CutCorner smallCut color="warmGrey6">
                        <Box
                            paddingLeft="panel"
                            paddingRight="panel"
                            paddingY="base"
                            minWidth="100px"
                        >
                            <Stack alignItems="center" spacing="halfQuart" vertical>
                                <Text color="white" small>
                                    {t.power}
                                </Text>
                                <Text
                                    testId={`${testId}_power_value_txt`}
                                    style="headline"
                                    color="white"
                                >
                                    {power.value}
                                </Text>
                                <Text
                                    testId={`${testId}_power_unit_txt`}
                                    style="semibold"
                                    color="white"
                                >
                                    {power.subTitle}
                                </Text>
                            </Stack>
                        </Box>
                    </CutCorner>
                )}
            </Stack>
        );
    }
}

export const ProposalDeviceDetailedSpecification = connect(mapStateToProps)(
    ProposalDeviceDetailedSpecificationContainer,
);
