import * as React from 'react';
import type { IRecordingSolutionItem } from './selectors';
import { Box } from 'app/components';
import { flatMap } from 'lodash-es';
import { PiaItemsMsrp } from '../common';

export const RecordingSolutionMsrp: React.FunctionComponent<{
    items: IRecordingSolutionItem[];
}> = ({ items }) => {
    return (
        <Box justifyContent="center">
            <PiaItemsMsrp
                piaIds={flatMap(
                    items.map((item) => [
                        item.piaId,
                        ...(item.accessories?.map((acc) => acc.piaId) ?? []),
                    ]),
                )}
                quantities={items.reduce(
                    (record, item) => {
                        record[item.piaId] = item.quantity;
                        if (item.accessories) {
                            item.accessories.forEach((acc) => (record[acc.piaId] = acc.quantity));
                        }
                        return record;
                    },
                    {} as Record<number, number>,
                )}
            />
        </Box>
    );
};

RecordingSolutionMsrp.displayName = 'RecordingSolutionMsrp';
