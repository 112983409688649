import * as React from 'react';
import { Box, Border, Clickable, Icon, Heading } from 'app/components';

import { FloorPlanOpacityRange } from '../opacityRange';
import type { IFloorPlanEntity } from 'app/core/persistence';

export interface IFloorplanOpacity {
    floorPlan: IFloorPlanEntity;
    onClose(): void;
}

export const FloorplanOpacity: React.FC<IFloorplanOpacity> = ({ floorPlan, onClose }) => {
    return (
        <Border
            shadow="rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"
            color="grey3"
        >
            <Box
                direction="row"
                maxWidth={250}
                maxHeight={100}
                color={'grey1'}
                borderRadius="rounded"
                paddingLeft="base"
                paddingBottom="base"
                paddingTop="quart"
                paddingRight="quart"
            >
                <Box direction="column" spacing="half" paddingRight="half">
                    <Box paddingTop="quart">
                        <Heading align="left" style="caption" lineClamp={1}>
                            {floorPlan.name}
                        </Heading>
                    </Box>
                    <FloorPlanOpacityRange hideValue floorPlan={floorPlan} onMouseUp={onClose} />
                </Box>
                <Clickable onClick={onClose}>
                    <Icon color="grey7" icon="close_circle_outline" />
                </Clickable>
            </Box>
        </Border>
    );
};
