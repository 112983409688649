import { createSelector } from 'reselect';
import { getCurrentProject } from '../project';

export const getSelectedRecordingVendor = createSelector(
    [getCurrentProject],
    (projectState) => projectState?.selectedRecordingVendor ?? 'Axis',
);

export const getSelectedAxisAcsType = createSelector(
    [getCurrentProject],
    (projectState) => projectState?.selectedCameraStationType,
);

export const getSelectedCenterChoice = createSelector(
    [getCurrentProject],
    (projectState) => projectState?.selectedCameraStationCenterType ?? 'NoCenter',
);
