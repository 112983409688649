import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppConstants } from 'app/AppConstants';
import { t } from 'app/translate';
import { toaster } from 'app/toaster';
import { useService } from 'app/ioc';
import type { IFloorPlanEntity } from 'app/core/persistence';
import { FloorPlanService, IMAGE_LOAD_ERROR } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import {
    Modal,
    Box,
    UploadFile,
    Clickable,
    Border,
    Heading,
    Icon,
    LoadingIndicator,
    TextInput,
    Text,
    Button,
} from 'app/components';
import { getCurrentProject, getIsLocalProject } from 'app/modules/common';
import { PdfViewer } from '../mapMenu/contextMenus/maps/PdfViewer';
import { StorageUsed } from '../mapMenu/contextMenus/storageUsed';
import type { IUploadFloorPlanOptions } from '../../models';
import { getIsUploadingFloorPlan, getUploadErrorMessage } from '../../selectors';
import { MapsActionService } from '../../services';
import { usePrevious } from 'app/hooks';
import { useModalLeftMargin } from '../../hooks/useModalLeftMargin';

const MIME_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
const IMAGE_TYPES = '.jpg, .jpeg, .png, .pdf';
const CONTENT_WIDTH = '400px';
const MAX_HEIGHT = '436px';

interface IUploadFloorPlanModal {
    /** Set if this is an existing floorplan */
    currentFloorPlan?: IFloorPlanEntity;
    /** Fired when manually closed */
    onClose(): void;
}

export const UploadFloorPlanModal: React.FC<IUploadFloorPlanModal> = ({
    currentFloorPlan,
    onClose,
}) => {
    const mapsActionService = useService(MapsActionService);
    const currentProject = useSelector(getCurrentProject);
    const isLocalProject = useSelector(getIsLocalProject);
    const uploading = useSelector(getIsUploadingFloorPlan);
    const previousUploading = usePrevious(uploading);
    const uploadComplete = previousUploading && !uploading;
    const uploadError = useSelector(getUploadErrorMessage);
    const [objectUrl, setObjectUrl] = React.useState('');
    const [fileName, setFileName] = React.useState(currentFloorPlan?.name ?? '');
    const [currentFile, setCurrentFile] = React.useState<File | undefined>();
    const [uploadErrorMessage, setUploadErrorMessage] = React.useState<string | undefined>();
    const modalLeftMargin = useModalLeftMargin();
    const [selectedDPI, setSelectedDPI] = React.useState(150);
    const [selectedPage, setSelectedPage] = React.useState(1);

    const getErrorMessage = React.useCallback(() => {
        switch (uploadError) {
            case '413':
                return t.uploadFileTooLarge;
            case '403':
                return t.uploadFileQuotaExceeded;
            case IMAGE_LOAD_ERROR:
                return t.uploadFileLoadError;
            case '401':
                return t.unauthenticated;
            default:
                return isLocalProject ? t.applicationQuotaExceededError : t.uploadFileUnknownError;
        }
    }, [isLocalProject, uploadError]);

    React.useEffect(() => {
        if (uploadError) {
            const errorMessage = getErrorMessage();
            setUploadErrorMessage(errorMessage);
        }
    }, [getErrorMessage, uploadError]);

    /** Used to make sure the dialog is closed when upload is successful */
    React.useEffect(() => {
        if (uploadComplete && !uploadError) {
            onClose();
        }
    }, [onClose, uploadComplete, uploadError]);

    const onAddOrChangeFloorPlan = async () => {
        if (!currentFile || !fileName.trim()) {
            return;
        }
        const uploadOptions: IUploadFloorPlanOptions = {
            name: fileName,
            uploadOptions: {
                selectedDPI,
                selectedPage,
                file: currentFile,
            },
        };

        if (currentFloorPlan && FloorPlanService.isFloorPlanMapType(currentFloorPlan)) {
            mapsActionService.changeFloorPlan(currentFloorPlan, uploadOptions.uploadOptions);
        } else {
            mapsActionService.addFloorPlan(currentProject._id, uploadOptions);
        }
    };

    const onFileUploaded = (file: File) => {
        if (!MIME_TYPES.includes(file.type)) {
            eventTracking.logUserEvent('Maps', 'Failed file upload', file.type);
            toaster.warning(t.uploadFailedHeader, t.supportedFileTypes(IMAGE_TYPES));
        }

        eventTracking.logUserEvent('Maps', 'File Uploaded', file.type);

        setCurrentFile(file);
        setObjectUrl(URL.createObjectURL(file));
    };

    const isPDF = currentFile?.type === 'application/pdf';
    const isUploadButtonDisabled = currentFloorPlan
        ? !currentFile
        : !(fileName.trim() && currentFile);

    return (
        <Modal
            marginLeft={modalLeftMargin}
            title={currentFloorPlan ? t.changeFloorPlan : t.addNewFloorPlan}
            onClose={uploading ? undefined : onClose}
        >
            {/* Render Uploading */}
            {uploading ? (
                <Box width={CONTENT_WIDTH} alignItems="center" justifyContent="center">
                    <LoadingIndicator message={t.uploadingFloorPlan} />
                </Box>
            ) : (
                <Box direction="column" spacing="base">
                    {/* Render PDF Preview */}
                    {isPDF ? (
                        <Box width={CONTENT_WIDTH}>
                            <PdfViewer
                                objectUrl={objectUrl}
                                contentWidth={CONTENT_WIDTH}
                                maxHeight={MAX_HEIGHT}
                                imageTypes={IMAGE_TYPES}
                                onFileUploaded={onFileUploaded}
                                selectedDPI={selectedDPI}
                                selectedPage={selectedPage}
                                setSelectedDPI={setSelectedDPI}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    ) : (
                        // Render upload button or preview image
                        <UploadFile accept={IMAGE_TYPES} onFileUploaded={onFileUploaded}>
                            <Clickable>
                                {!objectUrl && (
                                    <Border color="grey4" radius="4px">
                                        <Box
                                            direction="column"
                                            alignItems="center"
                                            flex="fullWidth"
                                            width={CONTENT_WIDTH}
                                            color="white"
                                            padding="base"
                                        >
                                            <Icon
                                                icon="file_upload"
                                                size="lg"
                                                opaque
                                                color="grey6"
                                            />
                                            <Heading>{t.uploadFloorPlan}</Heading>
                                        </Box>
                                    </Border>
                                )}

                                {objectUrl && (
                                    <Border color="grey4" radius="4px">
                                        <Box
                                            width={CONTENT_WIDTH}
                                            overflow="hidden"
                                            maxHeight={MAX_HEIGHT}
                                        >
                                            <img width={CONTENT_WIDTH} src={objectUrl} />
                                        </Box>
                                    </Border>
                                )}
                            </Clickable>
                        </UploadFile>
                    )}

                    {!currentFloorPlan && (
                        <TextInput
                            label={t.name}
                            value={fileName}
                            placeholder={t.floorPlanNamePlaceholder}
                            autoFocus
                            changeCriteria="key"
                            onEnter={onAddOrChangeFloorPlan}
                            onChange={setFileName}
                            maxLength={AppConstants.mapNameMaxLength}
                            testId="enter_map_name"
                        />
                    )}

                    <StorageUsed />

                    <Box flex="shrinkAndGrow" justifyContent="end">
                        <Box spacing="quart">
                            <Button text onClick={onClose}>
                                {t.cancel}
                            </Button>
                            <Button
                                onClick={onAddOrChangeFloorPlan}
                                primary
                                disabled={isUploadButtonDisabled}
                                testId="add_new_floorplan"
                            >
                                {currentFloorPlan ? t.changeFloorPlan : t.addNewFloorPlan}
                            </Button>
                        </Box>
                    </Box>

                    {/* Render upload error */}
                    {uploadError && (
                        <Box width={CONTENT_WIDTH}>
                            <Text style="semibold" color="red">
                                {uploadErrorMessage}
                            </Text>
                        </Box>
                    )}
                </Box>
            )}
        </Modal>
    );
};
