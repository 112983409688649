import type { AnyAction, Reducer } from '@reduxjs/toolkit';
import type { IAction } from '../IAction';
import type { IActionHandler, ActionCallback, StateProvider } from './reducer.types';
import { ActionHandler } from './ActionHandler';
import { AsyncActionHandler } from './AsyncActionHandler';

export class ReducerProvider<TState> {
    private readonly handlers: Array<IActionHandler<TState, any>> = [];
    private initialState!: TState | StateProvider<TState>;

    public setInitialState(state: TState | StateProvider<TState>): ReducerProvider<TState> {
        this.initialState = state;
        return this;
    }

    public onAsyncAction(
        actionName: string,
        asyncActionHandlerFactory: (handler: AsyncActionHandler<TState>) => void,
    ): ReducerProvider<TState> {
        const handler = new AsyncActionHandler<TState>(actionName);
        asyncActionHandlerFactory(handler);
        this.handlers.push(handler);
        return this;
    }

    public onAction<TPayload>(
        actionName: string,
        actionHandler: ActionCallback<TState, TPayload>,
    ): ReducerProvider<TState> {
        this.handlers.push(new ActionHandler<TState, TPayload>(actionName, actionHandler));
        return this;
    }

    public create(): Reducer<TState> {
        if (typeof this.initialState === 'undefined') {
            throw new Error('setInitialState must be called before creating the reducer');
        }

        return (state: TState | undefined, action: AnyAction): TState => {
            if (!state) {
                if (typeof this.initialState === 'function') {
                    state = (this.initialState as StateProvider<TState>)();
                } else {
                    state = this.initialState;
                }
            }

            this.handlers.forEach((handler) => {
                state = handler.handle(state!, action as IAction<any>);
            });

            return state;
        };
    }
}
