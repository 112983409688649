import type { LocalStorageKey } from 'app/core/persistence';
import { getItemLocalStorageJSON, setItemLocalStorageJSON } from 'app/core/persistence';
import { useEffect, useState } from 'react';

export const usePersistedState = (key: LocalStorageKey, defaultValue: any) => {
    const [state, setState] = useState(() => getItemLocalStorageJSON(key, defaultValue));
    useEffect(() => {
        setItemLocalStorageJSON(key, state);
    }, [key, state]);
    return [state, setState];
};
