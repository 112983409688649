import { injectable } from 'inversify';
import type { IBaseEntity } from '../../entities';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Migrate recording solution type
 */
@injectable()
export class Migration44to45 extends MigrationProviderBase {
    public from: number = 44;
    public to: number = 45;

    public provideMigration() {
        return async (entity: any): Promise<IBaseEntity> => {
            if (entity.type !== 'project') {
                // Only migrate projects
                return entity;
            }

            // Migrate recording solution type
            entity.recordingSolutionType =
                entity.recordingSolutionType === 'AxisS22'
                    ? 'AxisS2X'
                    : entity.recordingSolutionType === 'AxisS11'
                      ? 'AxisS1X'
                      : entity.recordingSolutionType;

            return entity;
        };
    }
}
