import type * as React from 'react';
import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as leaflet from 'leaflet';
import { isPageRTL, t } from 'app/translate';
import { ColorsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { getCurrentProjectId } from 'app/modules/common';
import { useMapContext } from '../context';
import {
    getIsDoriPixelsOn,
    getIsMeasureToolActive,
    getIsLayerSelectorActive,
    getShowRadarCoexistenceWarning,
    getIsScalingToolActive,
    getIsGoogleMap,
    getIsRadarWarningDismissed,
    getShowGeoLocationTool,
} from '../../selectors';
import { MapsActionService, LeafletItemFactory } from '../../services';
import { createDrawControls } from './draw';

const hideOnPrint = css`
    @media print {
        display: none;
    }
`;

interface ILeafletMenuProps {
    readOnly?: boolean;
}

export const LeafletMenu: React.FC<ILeafletMenuProps> = ({ readOnly }) => {
    const { leafletMap, floorPlan } = useMapContext();

    const navigate = useNavigate();

    //RTL support
    const topPosition = isPageRTL() ? 'topleft' : 'topright';
    const bottomPosition = isPageRTL() ? 'bottomleft' : 'bottomright';

    const isScalingToolActive = useSelector(getIsScalingToolActive);
    const currentProjectId = useSelector(getCurrentProjectId);
    const isMeasureToolOn = useSelector(getIsMeasureToolActive);
    const isDoriPixelsOn = useSelector(getIsDoriPixelsOn);
    const isLayerSelectorActive = useSelector(getIsLayerSelectorActive);
    const showRadarCoexistenceWarning = useSelector(getShowRadarCoexistenceWarning);
    const isRadarWarningDismissed = useSelector(getIsRadarWarningDismissed);
    const isGoogleMap = useSelector(getIsGoogleMap);
    const isGeoLocationActive = useSelector(getShowGeoLocationTool);
    const mapsActionService = useService(MapsActionService);
    const itemFactory = useService(LeafletItemFactory);
    const isDefaultMap = floorPlan?.isDefault;

    const floorPlanId = floorPlan?._id;

    const scaleToFit = useCallback(() => {
        if (floorPlanId) {
            mapsActionService.scaleToFit(floorPlanId);
        }
    }, [mapsActionService, floorPlanId]);

    const onPrint = useCallback(() => {
        eventTracking.logUserEvent('Maps', 'Print');
        navigate(`/project/${currentProjectId}/reports/maps/`);
    }, [navigate, currentProjectId]);

    useEffect(() => {
        const zoomControl = leaflet.control.zoom({
            zoomInTitle: t.leafletZoomInTitle,
            zoomOutTitle: t.leafletZoomOutTitle,
            position: topPosition,
        });

        // scale to fit button
        const scaleToFitControl = itemFactory.createLeafletMenuButton({
            position: topPosition,
            button: {
                onClick: () => {
                    scaleToFit();
                },
                icon: 'fit',
                text: t.leafletCenterMap,
                testId: 'scale_to_fit',
            },
        });

        // layer controls
        const layerControl = itemFactory.createLeafletMenuButton({
            position: topPosition,
            button: {
                onClick: () => {
                    mapsActionService.toggleLayers(!isLayerSelectorActive);
                },
                icon: 'layers',
                text: t.selectLayers,
                testId: 'layers',
            },
        });

        // free text tool button
        const addFreeTextToolControl = itemFactory.createLeafletMenuButton({
            position: topPosition,
            button: {
                onClick: () => {
                    drawControls.disable();
                    mapsActionService.toggleFreeTextTool(true);
                },
                icon: 'T',
                text: t.addNewFreeText,
            },
        });

        // draw controls
        const drawControls = createDrawControls({
            edit: {
                featureGroup: leafletMap.blockersFeatureGroup,
            },
            position: topPosition,
        });

        // measure tool
        const measureToolControl = itemFactory.createLeafletMenuButton({
            position: topPosition,
            button: {
                onClick: () => {
                    mapsActionService.toggleMeasureTool(!isMeasureToolOn);
                },
                testId: 'measure_tool',
                icon: isMeasureToolOn ? 'ruler_off' : 'ruler',
                text: t.leafletToggleMeasureTool,
                disabled: isScalingToolActive,
            },
        });

        // dori tool
        const doriControl = itemFactory.createLeafletMenuButton({
            position: topPosition,
            button: {
                onClick: () => {
                    mapsActionService.toggleDoriPixels(!isDoriPixelsOn);
                },
                icon: isDoriPixelsOn ? 'dori_off' : 'dori_on',
                text: t.leafletToggleDori,
            },
        });

        // print
        const printControl = itemFactory.createLeafletMenuButton({
            position: bottomPosition,
            button: {
                onClick: () => {
                    onPrint();
                },
                icon: 'print',
                text: t.print,
            },
        });

        // radar coexistence
        const radarCoexistenceWarningControl = itemFactory.createLeafletMenuButton({
            position: bottomPosition,
            button: {
                onClick: () => {
                    mapsActionService.setCurrentFloorPlanUnDismissRadarWarning();
                },
                icon: 'warning',
                text: t.radarWarningUnDismiss,
                color: ColorsEnum.red,
                testId: 'warning_icon',
            },
        });

        leafletMap.map.addControl(zoomControl);
        zoomControl.getContainer()!.className = `leaflet-control-zoom leaflet-bar leaflet-control ${hideOnPrint}`;
        leafletMap.map.addControl(scaleToFitControl);
        leafletMap.map.addControl(layerControl);

        if (!readOnly) {
            leafletMap.map.addControl(addFreeTextToolControl);
            leafletMap.map.addControl(drawControls);
            leafletMap.map.addControl(measureToolControl);
        }
        leafletMap.map.addControl(doriControl);

        if (!readOnly) {
            leafletMap.map.addControl(printControl);

            if (showRadarCoexistenceWarning && isRadarWarningDismissed) {
                leafletMap.map.addControl(radarCoexistenceWarningControl);
            }
            if (drawControls && isGeoLocationActive) {
                drawControls.disableDrawButtons();
            }
        }

        return () => {
            zoomControl.remove();
            scaleToFitControl.remove();
            layerControl.remove();
            addFreeTextToolControl.remove();
            drawControls.remove();
            measureToolControl.remove();
            doriControl.remove();
            printControl.remove();
            radarCoexistenceWarningControl.remove();
        };
    }, [
        leafletMap,
        isGoogleMap,
        isDoriPixelsOn,
        isMeasureToolOn,
        isLayerSelectorActive,
        isRadarWarningDismissed,
        isScalingToolActive,
        showRadarCoexistenceWarning,
        mapsActionService,
        itemFactory,
        onPrint,
        scaleToFit,
        readOnly,
        topPosition,
        bottomPosition,
        isGeoLocationActive,
        isDefaultMap,
    ]);

    return null;
};

LeafletMenu.displayName = 'LeafletMenu';
