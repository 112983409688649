import { injectable } from 'inversify';
import type { IPersistence, IProfileEntity, Id } from '../userDataPersistence';
import { ProfileRepository } from '../userDataPersistence';
import type { IBaseProfileModel } from '../models';
import { ProjectService } from './Project.service';
import { NameGenerationService } from './NameGeneration.service';
import { AppConstants } from 'app/AppConstants';
import { CurrentProjectService } from './CurrentProject.service';
import { deviceTypeCheckers } from '../utils';
import { CoreProfileNameError } from './errors';
import {
    mapToAudioEntity,
    mapToRecordingSettingsEntity,
    mapToScenarioEntity,
    mapToStorageEntity,
    mapToZipstreamEntity,
} from '../models/profile';

@injectable()
export class ProfileService {
    constructor(
        private profileRepository: ProfileRepository,
        private projectService: ProjectService,
        private nameGenerationService: NameGenerationService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async getProjectProfiles(projectId: Id): Promise<IPersistence<IProfileEntity>[]> {
        const profiles = await this.profileRepository.getDescendants(projectId);
        return profiles.descendants;
    }

    public async getProfile(id: Id): Promise<IPersistence<IProfileEntity>> {
        return this.profileRepository.get(id);
    }

    public async getProjectId(id: Id): Promise<string> {
        const profile = await this.profileRepository.get(id);
        return profile.path[0];
    }

    public async addProjectProfile(
        projectId: Id,
        profile: IBaseProfileModel,
    ): Promise<IPersistence<IProfileEntity>> {
        const entity: IProfileEntity = {
            type: 'profile',
            path: [projectId],
            name: profile.name,
            scenario: mapToScenarioEntity(profile.scenario),
            triggeredRecording: mapToRecordingSettingsEntity(profile.triggeredRecording),
            continuousRecording: mapToRecordingSettingsEntity(profile.continuousRecording),
            liveView: mapToRecordingSettingsEntity(profile.liveView),
            storage: mapToStorageEntity(profile.storage),
            audio: mapToAudioEntity(profile.audio),
            zipstream: mapToZipstreamEntity(profile.zipstream),
            locked: await this.projectService.isLocked(projectId),
            archived: await this.projectService.isArchived(projectId),
        };

        return this.profileRepository.add(entity);
    }

    public async addProjectProfileWithEntity(
        profile: IProfileEntity,
    ): Promise<IPersistence<IProfileEntity>> {
        const projectId = this.currentProjectService.getProjectId();
        await this.IsProfileNameOk(profile.name);

        const entity: IProfileEntity = {
            type: 'profile',
            path: [projectId],
            name: profile.name,
            scenario: profile.scenario,
            triggeredRecording: profile.triggeredRecording,
            continuousRecording: profile.continuousRecording,
            liveView: profile.liveView,
            storage: profile.storage,
            audio: profile.audio,
            zipstream: profile.zipstream,
            locked: await this.projectService.isLocked(projectId),
            archived: await this.projectService.isArchived(projectId),
        };

        return this.profileRepository.add(entity);
    }

    public async updateProfileEntity(
        updatedProfile: IPersistence<IProfileEntity>,
    ): Promise<IPersistence<IProfileEntity>> {
        const originalEntity = await this.profileRepository.get(updatedProfile._id);

        if (originalEntity.name !== updatedProfile.name) {
            await this.IsProfileNameOk(updatedProfile.name);
        }

        return this.profileRepository.update(updatedProfile);
    }

    private async IsProfileNameOk(newName: string) {
        const projectId = this.currentProjectService.getProjectId();
        const profileNames = (await this.getProjectProfiles(projectId)).map(({ name }) => name);
        const profileNameUsed = profileNames.some((profileName) => profileName === newName);
        if (profileNameUsed || newName.trim().length < 1) {
            throw new CoreProfileNameError(newName, newName.trim().length < 1);
        }
    }

    public async updateDefaultProfile(projectId: Id, profileId: Id): Promise<void> {
        this.projectService.update(projectId, { defaultProfile: profileId });
    }

    public async deleteProfile(id: Id, rev: string): Promise<Id> {
        return this.profileRepository.delete(id, rev);
    }

    public async duplicateProfile(id: Id): Promise<IPersistence<IProfileEntity>> {
        const entity = await this.profileRepository.get(id);
        return this.profileRepository.add(await this.copyEntity(entity));
    }

    public getAssociatedProfile(itemId: Id) {
        const item = this.currentProjectService.getEntity(itemId, 'item');

        if (!item) {
            return undefined;
        }

        let associatedProfile: string | undefined;

        if (deviceTypeCheckers.isAnalogCamera(item)) {
            associatedProfile = item.properties.analogCamera.associatedProfile;
        }

        if (deviceTypeCheckers.isDoorStation(item) || deviceTypeCheckers.isCamera(item)) {
            associatedProfile = item.properties.camera.associatedProfile;
        }

        if (deviceTypeCheckers.isSensorUnit(item)) {
            associatedProfile = item.properties.sensorUnit.associatedProfile;
        }

        if (deviceTypeCheckers.isVirtualProduct(item)) {
            associatedProfile = item.properties.virtualProduct.associatedProfile;
        }

        return associatedProfile
            ? this.currentProjectService.getEntity(associatedProfile, 'profile')
            : undefined;
    }

    private async copyEntity(entity: IProfileEntity): Promise<IProfileEntity> {
        const projectId = entity.path[0];
        const names = (await this.profileRepository.getDescendants(projectId)).descendants.map(
            ({ name }) => name,
        );
        const newName = this.nameGenerationService.getName(
            entity.name.trim(),
            names,
            AppConstants.profileNameMaxLength,
        );
        return { ...entity, path: [projectId], name: newName };
    }
}
