import * as React from 'react';
import { Box, OverlayPanel, Positioned } from 'app/components';
import { ProjectDevicesActionService } from './services';
import { ServiceLocator } from 'app/ioc';
import { DeviceDetails } from './components/deviceDetails';
import type { Id } from 'app/core/persistence';
import { getOverlayPanelOpen } from 'app/modules/common';
import { DeviceList, MultipleCopiesModal, ProjectDevicesHeader } from './components';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';

export const ProjectDevicesView: React.FC = () => {
    const [actionService] = React.useState<ProjectDevicesActionService>(
        ServiceLocator.get(ProjectDevicesActionService),
    );

    const panelItemId = useSelector<IStoreState, Id | undefined>(
        (state) => state.projectDevices.panelItem?.itemId,
    );
    const panelOpen = useSelector(getOverlayPanelOpen);
    const multipleCopiesModalId = useSelector<IStoreState, Id | undefined>(
        (state) => state.projectDevices.multipleCopiesModalDeviceId,
    );

    React.useEffect(() => {
        return () => {
            actionService.closePanel();
        };
    }, [actionService]);

    return (
        <>
            {multipleCopiesModalId && <MultipleCopiesModal deviceId={multipleCopiesModalId} />}
            <Box
                testId="panel_devices"
                direction="column"
                height="100%"
                position="relative"
                overflowX="hidden"
                paddingLeft="half"
            >
                <OverlayPanel active={panelOpen} onInactivate={actionService.closePanel}>
                    {panelItemId && <DeviceDetails deviceId={panelItemId} />}
                </OverlayPanel>
                <ProjectDevicesHeader />
                <DeviceList />
                {/* 
                    Below boxes are used to cover the expander button as they 
                    scroll past the header and footer of the table 
                */}
                <Positioned sendForward position="absolute" top="62px">
                    <Box color="grey1" height={40} width={25} />
                </Positioned>
                <Positioned sendForward position="absolute" bottom={0}>
                    <Box color="grey1" height={29} width={25} />
                </Positioned>
            </Box>
        </>
    );
};

ProjectDevicesView.displayName = 'ProjectDevicesView';
