import * as React from 'react';
import { Box, Stack, Text } from 'app/components';
import { CardReaderSelector } from './CardReaderSelector';
import type { Id } from 'app/core/persistence';
import { DoorAccessoryList } from './DoorAccessoryList';
import { RexSelector } from './RexSelector';

interface IDoorSideProps {
    deviceItemId: Id;
    doorItemId: Id;
    label: string;
    isSideA: boolean;
}

export const DoorSide: React.FC<IDoorSideProps> = ({
    label,
    deviceItemId,
    doorItemId,
    isSideA,
}) => {
    return (
        <Box width="100%">
            <Stack vertical alignItems="center">
                <Text style="semibold">{label}</Text>
                <CardReaderSelector
                    deviceItemId={deviceItemId}
                    doorItemId={doorItemId}
                    isSideA={isSideA}
                />
                <RexSelector
                    deviceItemId={deviceItemId}
                    doorItemId={doorItemId}
                    isSideA={isSideA}
                />
                <DoorAccessoryList doorItemId={doorItemId} isSideA={isSideA} />
            </Stack>
        </Box>
    );
};

DoorSide.displayName = 'DoorSide';
