import type { PiaId } from 'app/core/pia';
import { ActionCreator, IAction, ThunkAction } from 'app/store';
import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { DocumentationActions } from '../DocumentationActions';
import { DocumentationService } from './Documentation.service';

@injectable()
export class DocumentationActionService {
    constructor(private documentationService: DocumentationService) {}

    @ActionCreator()
    public selectDevice(deviceId: number): IAction<number> {
        return {
            type: DocumentationActions.addSelectedId,
            payload: deviceId,
        };
    }

    @ActionCreator()
    public deselectDevice(deviceId: number): IAction<number> {
        return {
            type: DocumentationActions.removeId,
            payload: deviceId,
        };
    }

    @ActionCreator()
    public selectDevices(deviceIds: number[]): IAction<number[]> {
        return {
            type: DocumentationActions.addSeveralIds,
            payload: deviceIds,
        };
    }

    @ActionCreator()
    public clearSelectedDevices(): IAction<number[]> {
        return {
            type: DocumentationActions.clearSelected,
            payload: [],
        };
    }

    @ActionCreator()
    public deviceFilterChange(value: string): IAction<string> {
        return {
            type: DocumentationActions.deviceFilterChange,
            payload: value,
        };
    }

    @ActionCreator()
    public clearDeviceFilter(): IAction<string> {
        return {
            type: DocumentationActions.deviceFilterChange,
            payload: '',
        };
    }

    /** Check which accessories have data sheets and update redux state accordingly */
    @ActionCreator()
    public updateAccessories(accessoriesToCheck: PiaId[]): ThunkAction {
        return async (dispatch, getState) => {
            if (
                isEqual(
                    accessoriesToCheck,
                    getState().documentation.accessories.latestPiaIdsChecked,
                )
            ) {
                return;
            }

            const accessoriesWithDataSheets =
                await this.documentationService.checkDocumentationAvailability(accessoriesToCheck);

            dispatch({
                type: DocumentationActions.updateAccessories,
                payload: {
                    latestPiaIdsChecked: accessoriesToCheck,
                    accessoriesWithDataSheets: accessoriesWithDataSheets,
                },
            });
        };
    }
}
