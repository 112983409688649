import { isDefined } from 'axis-webtools-util';
import { uniq } from 'lodash-es';
import type { ILocation } from '../models';
import { ColorsEnum } from 'app/styles';
import type { IBounds } from 'app/core/persistence';
import { Loader } from '@googlemaps/js-api-loader';

export interface IGeocodedLocation {
    address: string;
    countryCode?: string;
    location: ILocation;
    bounds: IBounds;
}

const MAPS_API_KEY = 'AIzaSyCjPDCJkh9SHM8TJjQnIpHZ8UzP5mUTxmo';
const STATIC_MAPS_API_URL = `https://maps.googleapis.com/maps/api/staticmap?key=${MAPS_API_KEY}&style=feature:poi|visibility:off`;

const toMapColor = (color: string) => `0x${color.slice(1)}`;

export class GoogleMapsService {
    public static async loadGoogleMaps(): Promise<void> {
        const loader = new Loader({
            apiKey: MAPS_API_KEY,
            libraries: ['places'],
            version: 'weekly',
        });

        return new Promise((resolve) => {
            loader
                .importLibrary('maps')
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    console.error('Failed to load Google Maps API', error);
                });
        });
    }

    private static placePredictions: google.maps.places.AutocompletePrediction[] = [];

    public static getMapImageUrl(
        zoom: number,
        width: number,
        height: number,
        location = '',
        language: string,
        lat?: number,
        lng?: number,
    ): string {
        const hasLatLng = isDefined(lat) && isDefined(lng);
        const center = hasLatLng ? `${lat},${lng}` : encodeURI(location);
        const markers = hasLatLng ? `color:${toMapColor(ColorsEnum.yellow4)}|${center}` : '';
        return `${STATIC_MAPS_API_URL}&center=${center}&language=${language}&size=${width}x${height}&zoom=${zoom}&markers=${markers}&scale=2`;
    }

    public static async getLocationForAddress(address: string) {
        return this.geocode('address', address);
    }

    public static async getLocationForPlaceId(placeId: string): Promise<IGeocodedLocation[]> {
        const selectedItemName = this.getPlaceName(placeId);
        return this.geocode('placeId', placeId, selectedItemName);
    }

    public static async getLocationForLatLng(latLng: string) {
        return this.geocodeFromLatLng(latLng);
    }

    public static async getAutoCompleteResultsForAddress(address: string) {
        if (address === '') {
            return Promise.resolve([]);
        }

        return new Promise<google.maps.places.AutocompletePrediction[]>((resolve) =>
            new google.maps.places.AutocompleteService().getPlacePredictions(
                { input: address },
                (result, status) => {
                    this.placePredictions = result || []; // store place predictions for later reference

                    status !== google.maps.places.PlacesServiceStatus.OK || result?.length === 0
                        ? resolve([])
                        : resolve(result || []);
                },
            ),
        );
    }

    private static async geocode(type: string, value: string, selectedItemName?: string) {
        return new Promise<IGeocodedLocation[]>((resolve, reject) =>
            new google.maps.Geocoder().geocode({ [type]: value }, (result, status) =>
                status !== google.maps.GeocoderStatus.OK || result?.length === 0
                    ? reject()
                    : resolve(this.convertGeocoderResultToLocation(result || [], selectedItemName)),
            ),
        );
    }

    private static async geocodeFromLatLng(value: string) {
        if (value === '') {
            return Promise.resolve([]);
        }
        const splitted = value.split(',');
        const latlng = new google.maps.LatLng(Number(splitted[0]), Number(splitted[1]));
        return new Promise<Array<{ description: string; place_id: string }>>((resolve, reject) =>
            new google.maps.Geocoder().geocode({ location: latlng }, (result, status) => {
                if (status !== google.maps.GeocoderStatus.OK || result?.length === 0) {
                    reject();
                } else {
                    resolve(this.convertGeocoderResultToDescriptionPlace(result || []));
                }
            }),
        );
    }

    private static convertGeocoderResultToDescriptionPlace(
        geocoderResult: google.maps.GeocoderResult[],
    ): Array<{ description: string; place_id: string }> {
        return geocoderResult.map((result) => {
            return {
                description: result.formatted_address,
                place_id: result.place_id,
            };
        });
    }

    private static convertGeocoderResultToLocation(
        geocoderResult: google.maps.GeocoderResult[],
        selectedItemName?: string,
    ): IGeocodedLocation[] {
        return geocoderResult.map((result) => {
            const countryCode = result.address_components.find(({ types }) =>
                types.includes('country'),
            )?.short_name;

            const geometry = result.geometry;
            const address = this.constructAddress(result.formatted_address, selectedItemName);

            // Google recommends using viewport instead of bounds when the area
            // should be displayed to a user. Bounds are more strict and may not
            // include all the area that the user would expect to see.
            const { lat: south, lng: west } = geometry.viewport.getSouthWest();
            const { lat: north, lng: east } = geometry.viewport.getNorthEast();
            const bounds: IBounds = {
                topLeft: { lat: north(), lng: west() },
                bottomRight: { lat: south(), lng: east() },
            };

            return {
                address,
                location: {
                    lat: geometry.location.lat(),
                    lng: geometry.location.lng(),
                },
                bounds,
                countryCode,
            };
        });
    }

    // fetch place name from stored predictions
    private static getPlaceName(placeId: string): string | undefined {
        const place = this.placePredictions.find(({ place_id }) => place_id === placeId);
        return place?.structured_formatting.main_text;
    }

    private static constructAddress(address: string, selectedItemName?: string) {
        const addressLines = address.split(', ');

        if (selectedItemName) {
            addressLines.unshift(selectedItemName);
        }

        return uniq(addressLines).join('\n');
    }
}
