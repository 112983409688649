import type { CSSProperties } from 'react';

/**
 * Calculates the left margin for the modal, based on the width of the main menu and the map menu,
 * to make it appear on the center of the map instead of center of the screen.
 */
export const useModalLeftMargin = (): CSSProperties['marginInlineStart'] => {
    const mapMainMenu = document.querySelectorAll('[data-test-id="map_main_menu"]')[0];
    const mainMenu = document.querySelectorAll('[data-test-id="main_menu"]')[0];

    const mainMenuWidth = mainMenu?.getBoundingClientRect().width ?? 0;
    const mapMenuWidth = mapMainMenu?.getBoundingClientRect().width ?? 0;
    const leftMargin = mainMenuWidth + mapMenuWidth;

    return `${leftMargin}px`;
};
