import { Vector3 } from 'three';
import { calculateCornerVectors } from '../../utils';

export const getCameraCornerVectors = (
    horizontalFov: number,
    verticalFov: number,
    tiltAngle: number,
) => {
    const rotationAxis = new Vector3(0, 0, 1);
    const vectors = calculateCornerVectors(horizontalFov, verticalFov);
    Object.values(vectors).forEach((vector) => vector.applyAxisAngle(rotationAxis, -tiltAngle));
    return vectors;
};
