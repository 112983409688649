import type { getPriceListForRegion } from './getPriceListForRegion';
import type { IPiaLocation, IPiaItemVersion, IHasPiaVersion } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import type { IPiaItemWithPrice } from '../../models';
import { isDefined } from 'axis-webtools-util';

import type { IPriceList } from '../../models/IPriceList';
import type { IPrice } from '../models';

export function getPiaItemsWithPrice<T extends IHasPiaVersion>(
    piaItems: T[],
    priceListForRegion: ReturnType<typeof getPriceListForRegion>,
    projectLocation?: IPiaLocation,
): Array<IPiaItemWithPrice<T>> {
    if (!piaItems || !projectLocation) {
        return [];
    }

    const piaItemsWithPrice: Array<IPiaItemWithPrice<T>> = piaItems.map((piaItem) => {
        return {
            price: undefined,
            piaItem,
        };
    });

    if (!priceListForRegion) {
        return piaItemsWithPrice;
    }

    return piaItemsWithPrice.map(addPrice(projectLocation, priceListForRegion)).filter(isDefined);
}

const addPrice =
    (projectLocation: IPiaLocation, priceListForRegion: IPriceList) =>
    <T extends IHasPiaVersion>(piaItemWithMsrp: IPiaItemWithPrice<T>) => {
        const msrp = createPrice(
            piaItemWithMsrp.piaItem.versions,
            projectLocation,
            priceListForRegion,
        );
        piaItemWithMsrp.price = { value: msrp.value, currency: msrp.currency };
        return piaItemWithMsrp;
    };

export const createPrice = (
    piaVersions: IPiaItemVersion[],
    projectLocation: IPiaLocation,
    priceListForRegion: IPriceList,
): IPrice => {
    const versionsCopy = [...piaVersions];
    const version = versionsCopy
        .filter(filterAllButExternallyAnnounced)
        .filter(getMatchingVersion(projectLocation))
        // Prioritize single packs over multi-packs to make versions consistent with product cards in device selector.
        .sort((a, b) => a.scaleQuantity - b.scaleQuantity)
        .shift();

    return version
        ? {
              currency: priceListForRegion.currencyCode || undefined,
              value: priceListForRegion.list[version.partno],
              scaleQuantity: version.scaleQuantity,
          }
        : {};
};

const filterAllButExternallyAnnounced = (version: IPiaItemVersion) =>
    version.state === PiaItemState.EXTERNALLY_ANNOUNCED;

const getMatchingVersion = (projectLocation: IPiaLocation) => (version: IPiaItemVersion) =>
    version.versions.includes(projectLocation.productVersions[0]);
