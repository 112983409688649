import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds default powerCalcMethod property to older projects.
 */
@injectable()
export class Migration16To17 extends MigrationProviderBase {
    public from: number = 16;
    public to: number = 17;

    public provideMigration() {
        return (entity: any): Promise<any> => {
            if (entity.type === 'project') {
                entity.powerCalcMethod = entity.powerCalcMethod || 'maxPower';
            }

            return Promise.resolve(entity);
        };
    }
}
