import { deviceTypeCheckers } from 'app/core/persistence';
import { filterProducts } from 'app/core/pia';
import {
    getChildrenForDevice,
    getCurrentProjectDevices,
    getCurrentProjectItems,
    getCurrentProjectRelationsRecord,
} from 'app/modules/common';
import { createSelector } from 'reselect';

export const getInstallableTotalDeviceCount = createSelector(
    [getCurrentProjectDevices, getCurrentProjectRelationsRecord, getCurrentProjectItems],
    (devices, currentProjectRelationsRecord, currentProjectItems) =>
        devices.filter(filterProducts.byExcludingGenerics()).reduce((prev, curr) => {
            const children = getChildrenForDevice(
                currentProjectRelationsRecord[curr._id],
                currentProjectItems,
            ).filter(filterProducts.byExcludingGenerics());

            const childQuantity = children.reduce((prevChild, currChild) => {
                return deviceTypeCheckers.isAnalogCamera(currChild)
                    ? prevChild
                    : (prevChild += currChild.quantity);
            }, 0);

            return (prev += curr.quantity + curr.quantity * childQuantity);
        }, 0),
);
