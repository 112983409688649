import { ColorsEnum } from 'app/styles';
import * as THREE from 'three';

export const Colors = {
    defaultMaterial: 0xffcc00,
    defaultMaterialEdge: ColorsEnum.yellow5,
    desiredMaterial: 0xb5121b,
    targetMaterial: 0x404040,
    floorMeshMaterial: 0xffffff,
    cameraMaterial: 0xefefef,
    groundMaterial: 0xd0d0d0,
    invalidMaterial: 0xff0000,
    aspectMaterial: 0x000000,
    intersectionLines: 0xb5121b,
    axisMaterial: 0x404040,
    hoverMaterial: 0x202020,
    defaultSky: 0x66ccff,
    defaultAmbientLight: 0xffffff,
    defaultDirectionalLight: 0xffffff,
    ground: 0xd0d0d0,
    grid: 0xe6e6e6,
};

export const transparentMaterial = new THREE.MeshPhongMaterial({
    side: THREE.DoubleSide,
    color: ColorsEnum.white,
    transparent: true,
    opacity: 0.0,
});

export const blockerMaterial = new THREE.MeshPhongMaterial({
    side: THREE.DoubleSide,
    color: 0xa0a0a0,
    transparent: true,
    opacity: 0.8,
});

export const blockerEdgeMaterial = new THREE.LineBasicMaterial({
    color: ColorsEnum.grey9,
    linewidth: 2,
    transparent: blockerMaterial.transparent,
    opacity: blockerMaterial.opacity,
});

export const blockerFloorMaterial = new THREE.LineBasicMaterial({
    color: ColorsEnum.blue6,
    linewidth: 4,
    transparent: blockerMaterial.transparent,
    opacity: blockerMaterial.opacity,
});

export const selectedNearMaterial = new THREE.MeshLambertMaterial({
    side: THREE.FrontSide,
    color: Colors.defaultMaterial,
    transparent: true,
    opacity: 0.5,
});

export const selectedNearMaterialBack = new THREE.MeshLambertMaterial({
    side: THREE.BackSide,
    color: selectedNearMaterial.color,
    transparent: selectedNearMaterial.transparent,
    opacity: selectedNearMaterial.opacity,
});

export const selectedAspectMaterial = new THREE.MeshLambertMaterial({
    side: THREE.BackSide,
    color: Colors.aspectMaterial,
    transparent: true,
    opacity: 0.9,
    clipIntersection: true,
});

export const selectedDistantMaterial = new THREE.MeshBasicMaterial({
    side: THREE.FrontSide,
    color: Colors.defaultMaterial,
    transparent: true,
    opacity: 0.3,
});

export const coneEdgeMaterial = new THREE.LineBasicMaterial({
    color: Colors.desiredMaterial,
    linewidth: 2,
    transparent: true,
    opacity: 0.7,
});

export const selectedDistantMaterialBack = new THREE.MeshBasicMaterial({
    side: THREE.BackSide,
    color: selectedDistantMaterial.color,
    transparent: selectedDistantMaterial.transparent,
    opacity: selectedDistantMaterial.opacity,
});

export const floorMeshMaterial = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    color: Colors.floorMeshMaterial,
    transparent: true,
    opacity: 0.4,
});

export const desiredMaterial = new THREE.MeshBasicMaterial({
    color: Colors.desiredMaterial,
    wireframe: true,
});

export const targetMaterial = new THREE.MeshLambertMaterial({
    side: THREE.DoubleSide,
    color: Colors.targetMaterial,
});

export const cameraMaterial = new THREE.MeshLambertMaterial({
    side: THREE.DoubleSide,
    color: Colors.cameraMaterial,
});

export const groundMaterial = new THREE.MeshBasicMaterial({
    color: Colors.groundMaterial,
    side: THREE.DoubleSide,
});

export const axisMaterial = new THREE.LineBasicMaterial({
    color: Colors.axisMaterial,
});

export const hoverMaterial = new THREE.MeshLambertMaterial({
    color: Colors.hoverMaterial,
});
