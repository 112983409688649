export const CHUNK_SIZE = 20;

export type SortOrder =
    | 'alphabetical'
    | 'byName'
    | 'bySeries'
    | 'byFov'
    | 'byPriceLowToHigh'
    | 'byPriceHighToLow'
    | 'byPowerConsumption';
