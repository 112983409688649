import * as React from 'react';
import type { Id } from 'app/core/persistence';
import { LensSelectorService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { toaster } from 'app/toaster';
import { t } from 'app/translate';
import type { ILens } from '../models';

export enum ToggleLensStatus {
    idle = 'idle',
    pending = 'pending',
    fulfilled = 'fulfilled',
    rejected = 'rejected',
}

/**
 * Hook to toggle selected lens and get status {@see ToggleLensStatus} when promise return.
 * @param  {Id} itemId - parent
 * @example
 *     const { onSelect, toggleStatus } = useToggleLens(itemId);
 */
export const useToggleLens = (itemId: Id) => {
    const [lensSelectorService] = React.useState(ServiceLocator.get(LensSelectorService));
    const [toggleStatus, setStatus] = React.useState<ToggleLensStatus>(ToggleLensStatus.idle);

    const onSelect = React.useCallback(
        async (lens: ILens) => {
            setStatus(ToggleLensStatus.pending);

            const ids = await lensSelectorService.removeUnsupportedLenses(itemId);
            if (ids.length > 0) {
                toaster.info(t.removeUnsupportedLenses);
            }
            return lensSelectorService
                .toggleLens(itemId, lens.productId, lens.sensorIndex)
                .then(() => setStatus(ToggleLensStatus.fulfilled))
                .catch(() => setStatus(ToggleLensStatus.rejected));
        },
        [itemId, lensSelectorService],
    );
    return { onSelect, toggleStatus };
};
