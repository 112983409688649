import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Modal, Stack, Text } from 'app/components';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import type {
    IItemEntity,
    INetworkRange,
    IPersistence,
    IProjectNetworkSettings,
} from 'app/core/persistence';
import { getNbrTotalAvailableIPAddresses, ProjectService, ItemService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { ModalService } from 'app/modal';
import { getCurrentProjectNetworkSettings } from '../../project';
import {
    getCurrentProjectNbrRequiredIPAddresses,
    getCurrentProjectItemsWithNetworkSettings,
} from '../selectors';
import { ProjectNetworkCard } from './ProjectNetworkCard';
import { ProjectNetworkForm } from './ProjectNetworkForm';
import { IPRangeSettings } from './IPRangeSettings';
import { IPRangeWarning } from './IPRangeSettings/IPRangeWarning';

interface IProjectNetworkModalProps {
    onClose(): void;
}

const defaultReservedRange: INetworkRange = { ipStart: '', ipEnd: '' };

export const ProjectNetworkModal: React.FC<IProjectNetworkModalProps> = ({ onClose }) => {
    const [editMode, setEditMode] = React.useState(false);
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));
    const [projectService] = React.useState(ServiceLocator.get(ProjectService));
    const [modalService] = React.useState(ServiceLocator.get(ModalService));
    const projectNetworkSettings = useSelector<IStoreState, IProjectNetworkSettings | undefined>(
        getCurrentProjectNetworkSettings,
    );
    const allItems = useSelector<IStoreState, IPersistence<IItemEntity>[]>(
        getCurrentProjectItemsWithNetworkSettings,
    );
    const nbrRequiredIPAddresses = useSelector<IStoreState, number>(
        getCurrentProjectNbrRequiredIPAddresses,
    );
    const nbrTotalAvailableIPAddresses = projectNetworkSettings
        ? getNbrTotalAvailableIPAddresses(projectNetworkSettings)
        : 0;

    const deleteAllItemNetworkSettings = () => {
        allItems.map(({ _id }) => {
            itemService.updateItem(_id, {
                networkSettings: undefined,
            });
        });
    };

    return (
        <Modal title={t.ipAddresses} onClose={onClose} maxWidth="600px" minWidth="600px">
            <Box direction="column">
                <Stack vertical spacing="panel">
                    <Text>
                        {projectNetworkSettings
                            ? t.ipAddressesCreatedMessage
                            : t.ipAddressesCreateMessage}
                    </Text>
                    {projectNetworkSettings && !editMode ? (
                        <ProjectNetworkCard
                            projectNetworkSettings={projectNetworkSettings}
                            nbrTotalAvailableIPAddresses={nbrTotalAvailableIPAddresses}
                            nbrRequiredIPAddresses={nbrRequiredIPAddresses}
                            onEdit={() => setEditMode(true)}
                            onDelete={async () => {
                                onClose();
                                const confirm = await modalService.createConfirmDialog({
                                    header: t.removeProjectNetworkConfirmationGROUP.header,
                                    body: t.removeProjectNetworkConfirmationGROUP.body,
                                    cancelButtonText: t.cancel,
                                    confirmButtonText: t.remove,
                                })();
                                if (confirm) {
                                    projectService.updateCurrentProject({
                                        networkSettings: undefined,
                                    });
                                    deleteAllItemNetworkSettings();
                                } else onClose();
                            }}
                        />
                    ) : (
                        <ProjectNetworkForm
                            nbrRequiredIPAddresses={nbrRequiredIPAddresses}
                            projectNetworkSettings={projectNetworkSettings}
                            onCancel={() =>
                                projectNetworkSettings ? setEditMode(false) : onClose()
                            }
                            onSubmit={async (networkSettingsFormValue) => {
                                const newProjectNetworkSettings: IProjectNetworkSettings =
                                    networkSettingsFormValue.dhcp
                                        ? {
                                              dhcp: true,
                                              ipStart: '',
                                              ipEnd: '',
                                              ranges: {
                                                  cameras: defaultReservedRange,
                                                  other: defaultReservedRange,
                                                  recorders: defaultReservedRange,
                                              },
                                              subnetMask: '',
                                              defaultRouter: '',
                                          }
                                        : networkSettingsFormValue;

                                const projectRange: INetworkRange = {
                                    ipStart: networkSettingsFormValue.ipStart,
                                    ipEnd: newProjectNetworkSettings.ipEnd,
                                };

                                await projectService.updateCurrentProject({
                                    networkSettings: {
                                        ...newProjectNetworkSettings,
                                        ranges: {
                                            cameras: projectRange,
                                            other: projectRange,
                                            recorders: projectRange,
                                        },
                                    },
                                });
                                setEditMode(false);
                            }}
                        />
                    )}
                </Stack>
            </Box>
            {projectNetworkSettings && !projectNetworkSettings.dhcp && !editMode && (
                <IPRangeSettings />
            )}
            <IPRangeWarning />
        </Modal>
    );
};

ProjectNetworkModal.displayName = 'ProjectNetworkModal';
