export const NUMBER_FORMAT_PRECISION = 3;

/**
 * Formats the output of requirement calculations and has a special forceMaxLength parameter for enterprise designer.
 *
 * @param value1 A numeric value
 * @param value2 A numeric value
 * @param unit The unit
 * @param significantFigures Optional (Default: 3) - How many significant figures the result should provide
 * @param forceMaxLength Optional (Default: true) - Ensures that values are returned with no more than 4 chars including period character.
 */

export function formatMultiValues(
    value1: number,
    value2: number,
    unit: string,
    significantFigures: number = NUMBER_FORMAT_PRECISION,
    forceMaxLength: boolean = true,
): string {
    let stringValue1 = value1.toPrecision(significantFigures);
    let stringValue2 = value2.toPrecision(significantFigures);
    if (forceMaxLength && value1 < 1000 && value2 < 1000) {
        stringValue1 = value1 >= 100 ? stringValue1.substring(0, 3) : stringValue1.substring(0, 4);
        stringValue2 = value2 >= 100 ? stringValue2.substring(0, 3) : stringValue2.substring(0, 4);
    }

    return stringValue1.concat(' / ', stringValue2, ' ', unit);
}

/**
 * Formats the output of requirement calculations and has a special forceMaxLength parameter for enterprise designer.
 *
 * @param value1 A numeric value
 * @param unit The unit
 * @param significantFigures Optional (Default: 3) - How many significant figures the result should provide
 * @param forceMaxLength Optional (Default: true) - Ensures that values are returned with no more than 4 chars including period character.
 */

export function formatValue(
    value: number,
    unit: string,
    significantFigures: number = NUMBER_FORMAT_PRECISION,
    forceMaxLength: boolean = true,
): string {
    let stringValue = value.toPrecision(significantFigures);
    if (forceMaxLength && value < 1000) {
        stringValue = value >= 100 ? stringValue.substring(0, 3) : stringValue.substring(0, 4);
    }

    return stringValue.concat(' ', unit);
}
