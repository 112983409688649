import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import type { IPiaLocationOption } from 'app/modules/userProjects';
import { textComparator } from 'app/utils';
import { BLOCKED_COUNTRIES } from './constants';

export const getPiaLocations = (state: IStoreState) => state.common.locations;

const getAllowedPiaLocations = createSelector([getPiaLocations], (locations) =>
    locations.filter(({ countryCode }) => !BLOCKED_COUNTRIES.includes(countryCode.toLowerCase())),
);

export const getPiaLocationOptions = createSelector([getAllowedPiaLocations], (piaLocations) =>
    piaLocations.map(
        (location): IPiaLocationOption => ({
            text: location.name,
            value: location.id,
        }),
    ),
);

export const getPiaLocationOptionsSortedByName = createSelector(
    [getPiaLocationOptions],
    (piaLocations) => piaLocations.sort(textComparator),
);
