import * as React from 'react';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { useService } from 'app/ioc';
import { MainPanel } from 'app/modules/application';
import { CommonActionService, getCurrentProject } from 'app/modules/common';
import { ProjectRoutes } from './components/ProjectRoutes.component';
import { LoadingIndicator } from 'app/components';
import { ProjectMainMenu } from './components/ProjectMainMenu.component';
import { ProjectError } from './components/ProjectMissing.component';
import { ValidationTrigger, isRecordingSolutionValid } from 'app/modules/recordingSelector';
import { ModalService } from 'app/modal';
import { MapsActionService } from 'app/modules/maps/services';
import { POUCHDB_ERROR_REASON_QUOTA_EXCEEDED } from 'app/errorReporterMiddleware';
import { isCustomPouchError } from 'app/utils';
import { useParams } from 'react-router-dom';
import { DocumentationActionService } from 'app/modules/documentation';
import { ProjectDevicesActionService } from 'app/modules/projectDevices/services';
import { RecordingSelectorActionService } from 'app/modules/recordingSelector/services';
import { useCoreLicenseUpdate } from './useCoreLicenseUpdate.hook';

export const Project: React.FC = () => {
    // hooked called to update core-licenses in recording solution
    useCoreLicenseUpdate();
    const brokenProject = useSelector<IStoreState, boolean>(
        (state) => state.currentProject.projectBroken,
    );
    const loading = useSelector<IStoreState, boolean>(
        (state) => !state.currentProject.projectLoaded,
    );
    const error = useSelector<IStoreState, Error | null>(
        (state) => state.currentProject.projectError,
    );
    const project = useSelector(getCurrentProject);
    const commonActionService = useService(CommonActionService);
    const mapsActionService = useService(MapsActionService);
    const recordingActionService = useService(RecordingSelectorActionService);
    const projectDeviceActionsService = useService(ProjectDevicesActionService);
    const documentationActionService = useService(DocumentationActionService);
    const modalService = useService(ModalService);
    const projectId = useParams().projectId ?? '/';

    const matchUrl = `/project/${projectId}/`;
    const namespace = useSelector<IStoreState, ApplicationNamespace>(
        (state) => state.app.appSettings.namespace,
    );
    const validRecordingSolution = useSelector(isRecordingSolutionValid);

    React.useEffect(() => {
        if (brokenProject) {
            const showModal = async () => {
                await modalService
                    .createConfirmDialog({
                        header: t.repairProjectHeader,
                        body: t.repairProjectBody,
                        confirmButtonText: t.gotIt,
                    })()
                    .then(() => commonActionService.confirmProjectBroken(projectId));
                return;
            };
            showModal();
        }
    }, [brokenProject, commonActionService, modalService, projectId]);

    React.useEffect(() => {
        commonActionService.loadCurrentProject(projectId);
        return () => {
            mapsActionService.resetToInitialState();
            projectDeviceActionsService.resetToInitialState();
            documentationActionService.clearSelectedDevices();
            recordingActionService.resetToInitialState();
        };
    }, [
        commonActionService,
        documentationActionService,
        mapsActionService,
        projectDeviceActionsService,
        projectId,
        recordingActionService,
    ]);

    const renderError = () => {
        let heading = t.projectNotFoundHeader;
        let message = t.projectNotFoundInfo;

        if (isCustomPouchError(error) && error.reason === POUCHDB_ERROR_REASON_QUOTA_EXCEEDED) {
            heading = t.errorBoundaryHeader;
            message = t.applicationQuotaExceededError;
        }

        return <ProjectError heading={heading} message={message} />;
    };

    const renderLoading = () => {
        return <LoadingIndicator message={t.projectLoading} />;
    };

    const renderRoutes = () => {
        return (
            <MainPanel
                sidebarContent={
                    <ProjectMainMenu
                        namespace={namespace}
                        basePath={matchUrl}
                        validRecordingSolution={validRecordingSolution}
                    />
                }
            >
                {loading ? (
                    renderLoading()
                ) : (
                    <ProjectRoutes projectId={projectId} projectLocked={project.locked} />
                )}
                <ValidationTrigger />
            </MainPanel>
        );
    };

    if (error) {
        return renderError();
    }
    return renderRoutes();
};

Project.displayName = 'Project';
