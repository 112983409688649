import { reducerBuilder } from 'app/store';
import type {
    IPersistence,
    IUserCustomItemEntity,
    UserCustomItemRecord,
} from 'app/core/persistence';
import {
    deleteEntityInAllDefinedRecord,
    updateEntityInAllDefinedRecord,
    PersistenceActions,
} from 'app/core/persistence';

export const userCustomItemReducer = reducerBuilder<UserCustomItemRecord>()
    .setInitialState((): UserCustomItemRecord => {
        return {};
    })
    .onAction<UserCustomItemRecord>(PersistenceActions.GetUserCustomItems, (_state, action) => {
        return action.payload;
    })
    .onAction<IPersistence<IUserCustomItemEntity>>(
        PersistenceActions.UpdateUserCustomItem,
        (state, action) => {
            return updateEntityInAllDefinedRecord(state, action);
        },
    )
    .onAction<string>(PersistenceActions.DeleteUserCustomItem, (state, action) => {
        return deleteEntityInAllDefinedRecord(state, action);
    })
    .create();
