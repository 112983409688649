import { Config } from '../config';
import type { ICurrencies, ICurrency } from '../models/currency.interfaces';
import type {
    IDistributor,
    IDistributorRecord,
    IFetchedDistributor,
    IFetchedDistributorInfo,
} from '../models/distributor.interfaces';
import type { IPriceRecord, IServerMsrpResponse } from '../models/price.interfaces';

export interface IMsrp {
    prices: IPriceRecord;
    currencyCode: string;
}

export class Communicator {
    public getDistributors(): Promise<IDistributorRecord> {
        return this.getRequest(Config.get().subUrlDistributors).then(
            (json: IFetchedDistributorInfo) => {
                const distributors: IDistributor[] = json.info.res.map(
                    (distributor: IFetchedDistributor) => {
                        return this.toDistributorStructure(distributor);
                    },
                );

                return distributors.reduce(
                    (distributorsObject: IDistributorRecord, distributor: IDistributor) => {
                        distributorsObject[distributor.locid] = distributor;
                        return distributorsObject;
                    },
                    {},
                );
            },
        );
    }

    public getPrices(priceList: string, locId: number): Promise<IMsrp> {
        return this.postRequest(
            Config.get().subUrlPrices,
            JSON.stringify({
                price_list: priceList,
                locid: locId,
            }),
        ).then((json: IServerMsrpResponse) => {
            const partNumbers = Object.keys(json.products);
            const addValueAsNumber = (prices: IPriceRecord, partNumber: string) => {
                prices[partNumber] = Number(json.products[partNumber]);
                return prices;
            };
            return {
                prices: partNumbers.reduce(addValueAsNumber, {}),
                currencyCode: json.currency.toUpperCase(),
            };
        });
    }

    /**
     * Call the MSRP API and resolve to
     * the response if the status is OK
     * or 403. Otherwise reject. Also
     * rejects on network fail.
     */
    public async testApi() {
        const testUrl = Config.get().url + Config.get().subUrlDistributors;
        const headers = await Config.get().userApiCommunicator.fetchUserTokenHeaders();
        headers.append('Accept', 'application/json');

        const response = await fetch(testUrl, {
            method: 'get',
            headers,
            credentials: 'include',
        });

        const apiResponseIsOk = response.ok === true || response.status === 403;

        return apiResponseIsOk ? response : Promise.reject('API NOT OK');
    }

    public async getCurrencies(): Promise<ICurrencies> {
        const result: any = await this.getRequest(Config.get().subUrlRates);
        const incomingCurrencies = result.info.res;

        return Object.keys(incomingCurrencies).reduce((currencies, currencyCode) => {
            const serverCurrency = incomingCurrencies[currencyCode];
            currencyCode = currencyCode.toUpperCase();
            currencies[currencyCode] = this.createCurrency(serverCurrency, currencyCode);
            return currencies;
        }, {} as ICurrencies);
    }

    private createCurrency(serverCurrency: any, currencyCode: string): ICurrency {
        return {
            name: serverCurrency.name,
            code: currencyCode,
            toUsdRate: serverCurrency.rate,
            validSince: serverCurrency.validsince,
        };
    }

    private postRequest(subUrl: string, payload: string): Promise<any> {
        return this.genericRequest(subUrl, 'post', payload);
    }

    private getRequest(subUrl: string): Promise<any> {
        return this.genericRequest(subUrl, 'get');
    }

    private async genericRequest(
        subUrl: string,
        httpMethod: string,
        payload?: string,
    ): Promise<any> {
        const headers = await Config.get().userApiCommunicator.fetchUserTokenHeaders();
        headers.append('Accept', 'application/json');
        return fetch(Config.get().url + subUrl, {
            method: httpMethod,
            headers,
            body: payload,
            credentials: 'include',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    private toDistributorStructure(distributor: IFetchedDistributor): IDistributor {
        const transformedDistributor: IDistributor = {
            locid: distributor.locid,
            priceList: distributor.price_list,
            name: distributor.name,
            currency: distributor.curr,
            countryCode: distributor.cc,
        };

        return transformedDistributor;
    }
}

export const communicator = new Communicator();
