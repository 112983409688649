import type { IPiaApplication } from 'app/core/pia';
import { PiaRelationTypes } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectLocation } from '../../project';
import { getPiaIdFromProps } from '../../selectors';
import { getAxisApplications } from './getAxisApplications';

/** Gets compatible axis applications for a given product id. */
export const getCompatibleApplicationsForPiaId = createCachedSelector(
    [getAxisApplications, getCurrentProjectLocation, getPiaItemsRecord, getPiaIdFromProps],
    (axisApps, location, piaItemsRecord, piaId) => {
        if (!piaId || !location) return [];

        const compatibleIdSet = new Set<number>();

        (piaItemsRecord[piaId] as IPiaApplication).relations.forEach((relation) => {
            if (relation.relationType === PiaRelationTypes.Compatible) {
                compatibleIdSet.add(relation.id);
            }
        });
        return axisApps.filter((app) => compatibleIdSet.has(app.id));
    },
)(toCacheKey);
