import * as React from 'react';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';
import type { AnalyticsComputeCapabilityType } from 'app/core/persistence';
import { t } from 'app/translate';

interface IAnalyticsComputeCapabilityFilterProps {
    selectedAnalyticsComputeCapability?: AnalyticsComputeCapabilityType;
    onClick(analyticsComputeCapability: AnalyticsComputeCapabilityType): void;
}

export const AnalyticsComputeCapabilityFilter: React.FC<IAnalyticsComputeCapabilityFilterProps> = ({
    selectedAnalyticsComputeCapability,
    onClick,
}) => {
    return (
        <DropDown
            trigger={
                <PillButton
                    minWidth="100px"
                    text={selectedAnalyticsComputeCapability ?? t.analyticsComputeCapability}
                    icon="keyboard_arrow_down"
                    selected={!!selectedAnalyticsComputeCapability}
                />
            }
            contents={
                <>
                    <DropDownMenuButton
                        label={t.dlpu}
                        selected={selectedAnalyticsComputeCapability === 'DLPU'}
                        onClick={() => onClick('DLPU')}
                    />
                    <DropDownMenuButton
                        label={t.mlpu}
                        selected={selectedAnalyticsComputeCapability === 'MLPU'}
                        onClick={() => onClick('MLPU')}
                    />
                </>
            }
        />
    );
};

AnalyticsComputeCapabilityFilter.displayName = 'AnalyticsComputeCapabilityFilter';
