import * as React from 'react';
import type { Icons } from 'app/components';
import { Clickable, Border, Box, Stack, Icon, Text } from 'app/components';

interface IAddCardReaderButtonProps {
    selected?: boolean;
    icon?: Icons;
    label: string;
    disabled?: boolean;
    onClick?(): void;
}

/**
 * A button that have a shadow-like look and feel. White button with blue text
 * if not selected, yellow button with dark text if selected
 *
 * selected - set to true if the button should look selected
 * icon - provide an icon if you want one to de displayed to the left of the text
 * label - text to be set to the button
 * disabled - set to true if no pointer event should be displayed
 * onClick - action to trigger on click
 */
export const ShadowButton: React.FC<IAddCardReaderButtonProps> = ({
    selected,
    icon,
    label,
    disabled,
    onClick,
}) => {
    return (
        <Clickable disabled={disabled} onClick={onClick}>
            <Border color="blackOpacity" radius="5px" shadow="0 0 8px rgba(0,0,0,.15)">
                <Box
                    color={selected ? 'yellow1' : 'white'}
                    alignItems="center"
                    justifyContent="center"
                    padding="half"
                    flex="dontShrink"
                >
                    <Stack spacing="half" justifyContent="center" alignItems="center">
                        {selected && icon ? (
                            <Text semiBold color={selected ? 'yellowText' : 'blue'}>
                                {selected}
                            </Text>
                        ) : icon ? (
                            <Icon opaque size="ms" icon="add" color="blue" />
                        ) : null}
                        <Text semiBold color={selected ? 'yellowText' : 'blue'}>
                            {label}
                        </Text>
                    </Stack>
                </Box>
            </Border>
        </Clickable>
    );
};

ShadowButton.displayName = 'ShadowButton';
