import type { IPiaDevice } from 'app/core/pia';
import { createSelector } from 'reselect';
import { MIN_RECYCLED_PLASTIC_PERCENT } from '../constants/minRecycledPlasticPercent';
import { getAllProductsForDeviceGroup } from './getProductsForDeviceGroup';

/** Checks if selected product category contains any product manufactured with a significant proportion of recycled plastics.
 *  Many products do not meet this requirement yet, so we want to hide the "Recycled plastic" filter if there are no products to show. */
export const getHasRecycledPlasticProducts = createSelector(
    [getAllProductsForDeviceGroup],
    (products): boolean => {
        return (products as IPiaDevice[]).some(
            (product) =>
                (product.properties.recycledPlasticPercent ?? 0) >= MIN_RECYCLED_PLASTIC_PERCENT,
        );
    },
);

/** Checks if product category has any bfr/cfr free product */
export const getHasBfrCfrFreeProducts = createSelector(
    [getAllProductsForDeviceGroup],
    (products): boolean => {
        return (products as IPiaDevice[]).some((product) => !!product.properties.bfrCfrFree);
    },
);

/** Checks if product category has any PVC free product */
export const getHasPvcFreeProducts = createSelector(
    [getAllProductsForDeviceGroup],
    (products): boolean => {
        return (products as IPiaDevice[]).some((product) => !!product.properties.pvcFree);
    },
);

/** Checks if product category has any product with any sustainability property */
export const getHasSustainableProducts = createSelector(
    [getHasRecycledPlasticProducts, getHasBfrCfrFreeProducts, getHasPvcFreeProducts],
    (hasRecycledPlasticProducts, hasBfrCfrFreeProducts, hasPvcFreeProducts) => {
        return hasRecycledPlasticProducts || hasBfrCfrFreeProducts || hasPvcFreeProducts;
    },
);
