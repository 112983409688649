import { injectable } from 'inversify';
import { BaseRepository } from './Base.repository';
import {
    PersistenceDatabaseRepository,
    TimestampProviderService,
    EntitySettings,
    CreateEntityService,
    PersistenceMemoryRepository,
} from './persistence';
import type { IInstallationPointBaseEntity } from '../entities';

@injectable()
export class InstallationPointRepository extends BaseRepository<IInstallationPointBaseEntity> {
    constructor(
        entitySettings: EntitySettings,
        persistenceRepository: PersistenceDatabaseRepository,
        persistenceMemoryRepository: PersistenceMemoryRepository,
        timestampProvider: TimestampProviderService,
        createEntityService: CreateEntityService,
    ) {
        super(
            entitySettings,
            persistenceRepository,

            persistenceMemoryRepository,
            timestampProvider,
            createEntityService,
        );
    }

    protected prefix() {
        return 'installationPoint';
    }
}
