import { isProduction } from './isProduction';
import { isMarketingAllowedByCookie } from './utils';

// Regex to find id components in path, e.g. /a/b/c:123abc/d
//                                                ^^^^^^^^
const REPLACE_ID_REGEX = /(\w):[^/]*/g;

// Remove ids from pathname e.g. /a/b/c:123abc/d -> /a/b/c:{id}/d
export const stripIds = (pathname: string): string => pathname.replace(REPLACE_ID_REGEX, '$1:id');

export function initializePardotIfProd() {
    // Only initialize Pardot tracking on production
    if (isProduction() && isMarketingAllowedByCookie()) {
        initializePardotTracking();
    }
}

function initializePardotTracking() {
    (window as any).piAId = '134061';
    (window as any).piCId = '121763';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://pi.pardot.com/pd.js';
    const c = document.getElementsByTagName('script')[0];
    if (c.parentNode) {
        c.parentNode.insertBefore(script, c);
    }
}
