import { createCachedSelector } from 're-reselect';
import { toCacheKey } from 'app/modules/common';
import { getBestReplacementLens } from './getBestReplacementLens';
import { getCurrentlyUsedLenses } from './getCurrentlyUsedLenses';
import { uniq } from 'lodash-es';

/**
 * Checks if lenses should be updated for item upon changing it.
 * This should occur if a replacement lens is required to fulfill requirements
 * but the item is not using the best possible lens for every sensor to do so.
 */
export const getShouldUpdateLenses = createCachedSelector(
    [getBestReplacementLens, getCurrentlyUsedLenses],
    (bestReplacementLens, currentLenses): boolean => {
        const currentLensesUniqueIds = currentLenses
            ? uniq(currentLenses).map((lens) => lens.id)
            : undefined;

        // If multiple lens models are chosen, change should always update lenses to the model shown on the product card
        if (currentLensesUniqueIds && currentLensesUniqueIds.length > 1) {
            return true;
        }

        const bestLensPiaId = bestReplacementLens?.id;
        const currentLensPiaId = currentLensesUniqueIds?.[0];

        return bestLensPiaId !== currentLensPiaId;
    },
)(toCacheKey);
