import * as React from 'react';
import { PiaImage, Border, Box, Icon, Stack } from 'app/components';

import { Acap } from './Acap';
import type { IRangeComponentApplication } from './IRangeComponentApplication';

interface IApplicationDetectionRange {
    isRadarCombined: boolean;
    application: IRangeComponentApplication;
}

export const ApplicationDetectionRange: React.FC<IApplicationDetectionRange> = ({
    isRadarCombined,
    application,
}) => {
    return (
        <Border radius="8px 8px 0 0" width={0}>
            <Box
                testId="choose_analytics_range"
                padding="half"
                width="100%"
                color={application?.productId ? 'yellow1' : undefined}
            >
                <Stack
                    justifyContent="between"
                    alignItems="center"
                    flex="shrinkAndGrow"
                    spacing="half"
                >
                    <PiaImage
                        icon="extension"
                        piaId={application?.productId ?? null}
                        imageSize="md"
                    />
                    <Acap
                        isApplicationAdded={true}
                        application={application}
                        isRadarCombined={isRadarCombined}
                    />

                    <Icon opaque icon="keyboard_arrow_down" color="grey4" />
                </Stack>
            </Box>
        </Border>
    );
};

ApplicationDetectionRange.displayName = 'ApplicationDetectionRange';
