import { injectable } from 'inversify';
import { ItemRelationRepository, PartnerItemRepository } from '../userDataPersistence';
import type {
    IPartnerItemEntity,
    IPartnerItemPropertiesEntity,
    IPartnerItem,
    Id,
    IIdRev,
    IPersistence,
} from '../userDataPersistence';
import { CurrentProjectService } from './CurrentProject.service';
import type { ItemRelationType } from '../models';

@injectable()
export class PartnerItemService {
    constructor(
        private partnerItemRepository: PartnerItemRepository,
        private itemRelationRepository: ItemRelationRepository,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async addPartnerItem(
        parentPath: Id[],
        item: IPartnerItem,
    ): Promise<IPersistence<IPartnerItemEntity>> {
        return this.partnerItemRepository.add({
            type: 'partnerItem',
            productId: item.productId,
            name: item.name,
            properties: this.getCleanedUpPartnerItemProperties(item.properties),
            quantity: item.quantity,
            archived: await this.currentProjectService.getIsProjectArchived(),
            path: [...parentPath],
            vendor: item.vendor,
            url: item.url,
            vendorId: item.vendorId,
        });
    }

    public async addByParentId(
        parentId: Id,
        item: IPartnerItem,
    ): Promise<IPersistence<IPartnerItemEntity>> {
        const parentItem = await this.partnerItemRepository.get(parentId);
        const addedItem = await this.addPartnerItem(parentItem.path, item);
        return addedItem;
    }

    public async addItemRelation(
        parentId: Id,
        childId: Id,
        itemRelationType: ItemRelationType,
    ): Promise<IIdRev> {
        const parent = await this.partnerItemRepository.get(parentId);

        const itemRelation = await this.itemRelationRepository.add({
            parentId,
            childId,
            path: parent.path.slice(),
            archived: await this.currentProjectService.getIsProjectArchived(),
            relationType: itemRelationType,
            type: 'itemRelation',
        });
        return itemRelation;
    }

    public async deleteItem(itemId: Id) {
        const { _rev } = this.currentProjectService.getEntity(itemId, 'partnerItem');
        return this.partnerItemRepository.delete(itemId, _rev);
    }

    /**
     * Makes sure unwanted data does not end up in the database, e.g if a spread operator
     * was used to pass in data.
     * */
    private getCleanedUpPartnerItemProperties(
        itemProperties: IPartnerItemPropertiesEntity,
    ): IPartnerItemPropertiesEntity {
        const properties: IPartnerItemPropertiesEntity = {};

        if (itemProperties.application) {
            properties.application = {};
        }

        return properties;
    }
}
