import { getAllPiaEncoders, getPiaIdFromProps, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';

export const getSelectedEncoderChannels = createCachedSelector(
    [getAllPiaEncoders, getPiaIdFromProps],
    (encoders, piaId) => {
        const encoder = encoders.find(({ id }) => id === piaId);
        return encoder?.properties.channels ?? 0;
    },
)(toCacheKey);
