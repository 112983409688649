import type { IMinMax } from 'app/core/persistence';
import type { IPiaCameraProperties } from 'app/core/pia';
import {
    Frequency,
    getAvailablePiaSensorDevices,
    getPiaIdFromProps,
    getProjectFrequencies,
    toCacheKey,
} from 'app/modules/common';
import { t } from 'app/translate';
import { createCachedSelector } from 're-reselect';
import type { ISensorDetails } from '../models/cameras';
import { getAccessoryLensFov } from './getAccessoryLensFov';

export const getSensorDetails = createCachedSelector(
    [getAvailablePiaSensorDevices, getProjectFrequencies, getPiaIdFromProps, getAccessoryLensFov],
    (devices, frequencies, productId, lensFov): ISensorDetails[] => {
        const sensor = devices.find((device) => device.id === productId);
        const sensorDetails: ISensorDetails[] = [];
        if (!sensor) {
            return sensorDetails;
        }

        const sensorProps = lensFov ?? sensor.properties;
        const { maxHorizontalFOV, minHorizontalFOV, maxVerticalFOV, minVerticalFOV } = sensorProps;

        sensorDetails.push({
            icon: 'angle',
            value: `${t.horizontalShort}: ${getFov({
                max: maxHorizontalFOV,
                min: minHorizontalFOV,
            })}`,
            secondaryValue: `${t.verticalShort}: ${getFov({
                max: maxVerticalFOV,
                min: minVerticalFOV,
            })}`,
        });
        sensorDetails.push({
            label: `${t.installationReportSettingsResolution}`,
            value: getResolution(sensor.properties),
        });
        sensorDetails.push({
            label: `${t.installationReportSettingsFrameRate}`,
            value: getFps(sensor.properties, frequencies),
        });

        return sensorDetails;
    },
)(toCacheKey);

const getFov = (properties: IMinMax) =>
    properties.max === properties.min
        ? `${properties.max}`
        : `${properties.max} - ${properties.min}`;

const getResolution = (properties: IPiaCameraProperties) =>
    `${properties.maxVideoResolutionHorizontal} × ${properties.maxVideoResolutionVertical}`;

const getFps = (properties: IPiaCameraProperties, frequencies: Frequency[]) =>
    frequencies.includes(Frequency.Hz60) ? `${properties.maxFPS60Hz}` : `${properties.maxFPS50Hz}`;
