import * as React from 'react';
import { connect } from 'react-redux';
import { format } from 'axis-webtools-util';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { Stack, Box, Text, FillableBar } from 'app/components';
import { getIsLocalProject } from 'app/modules/common';

interface IStorageUsedProps {
    usedBytes: number | undefined;
    totalBytes: number | undefined;
    visible: boolean;
}

const mapStateToProps = (storeState: IStoreState): IStorageUsedProps => {
    return {
        usedBytes: storeState.common.quota.usedBytes,
        totalBytes: storeState.common.quota.totalBytes,
        visible: !getIsLocalProject(storeState),
    };
};

class StorageUsedContainer extends React.Component<IStorageUsedProps> {
    private oneMegaByte: number = 1000000;

    public render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Box flex="none">
                <Stack vertical fullHeight justifyContent="end">
                    <Box padding="base">
                        <Stack vertical spacing="half" alignItems="center">
                            <FillableBar
                                partAmount={this.props.usedBytes || 0}
                                totalAmount={this.props.totalBytes || 1}
                            />
                            <Text align="center" color="grey5" style="semibold">
                                {this.props.usedBytes === undefined ||
                                this.props.totalBytes === undefined
                                    ? t.storageUnreachable
                                    : t.storageCapacity(
                                          this.props.usedBytes === 0
                                              ? 0
                                              : Math.round(this.props.usedBytes / this.oneMegaByte),

                                          format.storage(
                                              this.props.totalBytes / this.oneMegaByte,
                                              1,
                                          ),
                                      )}
                            </Text>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        );
    }
}

export const StorageUsed = connect(mapStateToProps)(StorageUsedContainer);
