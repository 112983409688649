import { defaultInstallationHeightMetric } from './../../../utils/persistenceConstants';
import { isDefaultFilter } from './../../../services/isDefaultFilter';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IItemEntity, IBaseEntity } from '../../entities';

const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

/**
 * Migrate isFilterChanged in camera filter properties.
 */
@injectable()
export class Migration14To15 extends MigrationProviderBase {
    public from: number = 14;
    public to: number = 15;

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (
                    entity.properties.camera &&
                    entity.properties.camera.filter.isFilterChanged === undefined
                ) {
                    entity.properties.camera.filter.isFilterChanged = false;
                    entity.properties.camera.filter.isFilterChanged = !isDefaultFilter(
                        entity.properties.camera.filter,
                        defaultInstallationHeightMetric,
                    );
                }
            }
            return entity;
        };
    }
}
