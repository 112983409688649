import { injectable } from 'inversify';
import type { IPersistence, IScheduleEntity, Id, ITimeSerieEntity } from '../userDataPersistence';
import { ScheduleRepository, TimeSerieRepository } from '../userDataPersistence';
import type {
    IBaseScheduleModel,
    IScheduleModel,
    ITimeSerieModel,
    IBaseTimeSerieModel,
} from '../models';
import { Time24, ScheduleDays } from '../models';
import { ProjectService } from './Project.service';
import { getParentId } from '../utils';

/**
 * The ScheduleModel service is used for providing schedule models including timeseries,
 * to other services using schedules to perform various calculations.
 * The schedules ui has been rewritten when moved to dashboard and use the ScheduleService,
 * to execute various ui operations as adding, removing schedules and time series.
 *
 * NOTE: The concept of using models instead of entities is deprecated, but a schedule is pretty much useless without its timeseries,
 * and the timeserie property is not part of the entity.
 * This is not a normal model-to-entity mapping, but rather a mapping of two entities into a model widely used in the application so we decided to leave it as is.
 */
@injectable()
export class ScheduleModelService {
    public static mapToScheduleModel(
        entity: IPersistence<IScheduleEntity>,
        timeSeries: ITimeSerieModel[],
    ): IScheduleModel {
        return {
            id: entity._id,
            rev: entity._rev,
            type: entity.type,
            creationDate: new Date(entity.creationDate),
            updatedDate: new Date(entity.updatedDate),
            name: entity.name,
            timeSeries,
            entityVersion: entity.entityVersion,
            systemDefined: entity.systemDefined,
            locked: entity.locked,
        };
    }

    public static mapToTimeSeriesModels(
        scheduleId: Id,
        timeSeries: Array<IPersistence<ITimeSerieEntity>>,
    ): ITimeSerieModel[] {
        return timeSeries.map(
            (series): ITimeSerieModel => ({
                id: series._id,
                rev: series._rev,
                entityVersion: series.entityVersion,
                type: series.type,
                creationDate: new Date(series.creationDate),
                updatedDate: new Date(series.updatedDate),
                start: new Time24(series.start),
                end: new Time24(series.end),
                days: new ScheduleDays(series.days),
                locked: series.locked,
                isInverted: getParentId(series) !== scheduleId,
            }),
        );
    }

    constructor(
        private scheduleRepository: ScheduleRepository,
        private timeSerieRepository: TimeSerieRepository,
        private projectService: ProjectService,
    ) {}

    public async getProjectSchedules(projectId: Id): Promise<IScheduleModel[]> {
        const schedules = await this.scheduleRepository.getDescendants(projectId);

        return Promise.all(
            schedules.descendants.map(async (schedule) =>
                ScheduleModelService.mapToScheduleModel(
                    schedule,
                    await this.getScheduleTimeSeries(schedule._id),
                ),
            ),
        );
    }

    public async getSchedule(id: Id): Promise<IScheduleModel> {
        const schedule = await this.scheduleRepository.get(id);
        return ScheduleModelService.mapToScheduleModel(
            schedule,
            await this.getScheduleTimeSeries(schedule._id),
        );
    }

    public async addProjectSchedule(
        projectId: Id,
        schedule: IBaseScheduleModel,
    ): Promise<IScheduleModel> {
        const scheduleEntity: IScheduleEntity = {
            type: 'schedule',
            path: [projectId],
            name: schedule.name,
            systemDefined: schedule.systemDefined,
            locked: await this.projectService.isLocked(projectId),
            archived: await this.projectService.isArchived(projectId),
        };

        const scheduleResponse = await this.scheduleRepository.add(scheduleEntity);
        const timeSeriesResponse = await this.addTimeSeries(scheduleResponse, schedule.timeSeries);

        return ScheduleModelService.mapToScheduleModel(scheduleResponse, timeSeriesResponse);
    }

    private async addTimeSeries(
        schedule: IPersistence<IScheduleEntity>,
        timeSeries: IBaseTimeSerieModel[],
    ): Promise<ITimeSerieModel[]> {
        const entities = timeSeries.map(
            (series): ITimeSerieEntity => ({
                type: 'timeSerie',
                path: [...schedule.path],
                start: series.start.toPersistable(),
                end: series.end.toPersistable(),
                days: series.days.toPersistable(),
                locked: schedule.locked,
                archived: schedule.archived,
            }),
        );

        const persistedTimeSeries = await Promise.all(
            entities.map(async (entity) => {
                return this.timeSerieRepository.add(entity);
            }),
        );

        return ScheduleModelService.mapToTimeSeriesModels(schedule._id, persistedTimeSeries);
    }

    private async getScheduleTimeSeries(scheduleId: Id): Promise<ITimeSerieModel[]> {
        const timeSeries = await this.timeSerieRepository.getDescendants(scheduleId);
        return ScheduleModelService.mapToTimeSeriesModels(scheduleId, timeSeries.descendants);
    }
}
