import * as React from 'react';
import { Clickable, Border, Box, Icon, Text, Stack, Spinner } from 'app/components';
import { ToasterService } from '../services';
import type { ToastModel } from '../models';
import { ServiceLocator } from 'app/ioc';
import { css, cx, keyframes } from '@emotion/css';

interface IToastProps {
    toast: ToastModel;
}

export const Toast: React.FC<IToastProps> = ({ toast }) => {
    const [toasterService] = React.useState<ToasterService>(ServiceLocator.get(ToasterService));
    const toastInAnimation = keyframes`
        0%  {transform: translateX(150%); max-height: 0;}
        40% {transform: translateX(150%); max-height: 200px;}
        100% {transform: translateX(0); max-height: 200px;}
    `;

    const toastOutAnimation = keyframes`
        0%  {transform: translateX(0); max-height: 200px;}
        80% {transform: translateX(150%); max-height: 200px;}
        100% {transform: translateX(150%); max-height: 0;}
    `;

    const toastInStyle = css`
        display: flex;
        max-height: 0;
        transform: translateX(150%);
        animation: ${toastInAnimation} 600ms ease-in-out forwards;
    `;

    const toastOutStyle = css`
        animation:
            ${toastInAnimation} 600ms ease-in-out forwards,
            ${toastOutAnimation} 300ms ease-in-out ${toast.secondsToLive * 1000 - 900}ms forwards;
    `;

    return (
        <div
            className={cx(toastInStyle, toast.toastType === 'progress' ? undefined : toastOutStyle)}
        >
            <Box paddingY="half">
                <Clickable onClick={() => toasterService.deleteToast(toast)}>
                    <Border shadow="0 10px 20px -2px rgba(0,0,0,.15)" color="grey8" width={0}>
                        <Box
                            color={toast.getColor()}
                            borderRadius="blunt"
                            minWidth="200px"
                            maxWidth="400px"
                            alignItems="center"
                            paddingX="base"
                            paddingY="half"
                        >
                            <Box testId={toast.headline} paddingY="quart">
                                <Stack alignItems="start">
                                    {toast.icon && <Icon icon={toast.icon} color="white" />}
                                    {toast.toastType === 'progress' && (
                                        <Box padding="halfQuart">
                                            <Spinner size={20} />
                                        </Box>
                                    )}
                                    <Stack vertical spacing="quart">
                                        <Text style="heading" color="white">
                                            {toast.headline}
                                        </Text>
                                        {toast.message && (
                                            <Text color="white">{toast.message}</Text>
                                        )}
                                    </Stack>
                                </Stack>
                            </Box>
                        </Box>
                    </Border>
                </Clickable>
            </Box>
        </div>
    );
};
