import * as React from 'react';
import { css } from '@emotion/css';
import { Spinner } from './Spinner.component';
import classNames from 'classnames';
import type { IAutoTestable } from '../../ui-test';
import { appBold } from 'app/styles/defaults';
import { SpacingsEnum } from 'app/styles';

const MessageStyle = css`
    font-weight: ${appBold};
    padding-top: ${SpacingsEnum.base};
`;

const LoadingIndicatorStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px auto;
    align-items: center;
`;

const SmallLoaderIndicatorStyle = css`
    margin-left: 20px;
`;
interface ILoadingIndicatorProps extends IAutoTestable {
    /**
     * The message to show while loading
     */
    message?: string;
    /*
     * The size of the loading indicator
     */
    small?: boolean;
}

export const LoadingIndicator: React.FunctionComponent<ILoadingIndicatorProps> = (props) => {
    return (
        <div
            data-test-id={props.testId}
            className={classNames({
                [LoadingIndicatorStyle]: !props.small,
                [SmallLoaderIndicatorStyle]: props.small,
            })}
        >
            <Spinner size={props.small ? '35px' : '60px'} />
            <span className={MessageStyle}>{props.message}</span>
        </div>
    );
};

LoadingIndicator.displayName = 'LoadingIndicator';
