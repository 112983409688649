import * as React from 'react';
import { connect } from 'react-redux';
import { Border, Box, ReadDirection, ReportCard, Stack, Text } from 'app/components';
import type { IPersistence, IProjectEntity } from 'app/core/persistence';
import {
    getCurrentProject,
    getCurrentProjectInstallationNotes,
    getCurrentProjectLocale,
    getFormattedBandwidth,
    getFormattedStorage,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { SummaryDetails } from './SummaryDetails.container';
import { getInstallableTotalDeviceCount } from '../../selectors';

interface IProjectSummaryProps {
    project: IPersistence<IProjectEntity>;
    formattedStorage: string;
    formattedBandwidth: string;
    locale: string;
    totalDeviceCount: number;
    installationNotes: string;
}

const mapStateToProps = (state: IStoreState): IProjectSummaryProps => {
    return {
        project: getCurrentProject(state),
        formattedStorage: getFormattedStorage(state),
        formattedBandwidth: getFormattedBandwidth(state),
        locale: getCurrentProjectLocale(state),
        totalDeviceCount: getInstallableTotalDeviceCount(state),
        installationNotes: getCurrentProjectInstallationNotes(state),
    };
};

class ProjectSummaryContainer extends React.PureComponent<IProjectSummaryProps> {
    render() {
        const { project, formattedBandwidth, formattedStorage, locale, totalDeviceCount } =
            this.props;

        const validUntil = this.props.project.shareToken
            ? new Date(this.props.project.shareToken.validUntil).toLocaleDateString(locale)
            : '';

        return (
            <Box pageBreakBefore={!!this.props.installationNotes}>
                <Stack vertical>
                    {project.shareToken && (
                        <Text>
                            {t.accessCodeForCameraStation}:&ensp;
                            <Border color="black">
                                <Text inline style="accesscode">
                                    {project.shareToken?.token}
                                </Text>
                            </Border>
                            &ensp;
                            <Text inline italic>
                                {t.validUntil(validUntil)}
                            </Text>
                        </Text>
                    )}
                    <Stack justifyContent="around">
                        <ReportCard title={t.devices} flex="shrinkAndGrow">
                            <Box
                                alignItems="center"
                                justifyContent="center"
                                direction="column"
                                padding="panel"
                                flex="shrinkAndGrow"
                            >
                                <Text testId="nbr_devices_to_install" sizeOverride={64}>
                                    {totalDeviceCount}
                                </Text>
                                <Text>{t.installationReportSummaryDevicesToInstall}</Text>
                            </Box>
                        </ReportCard>
                        <ReportCard
                            cutCorner
                            title={t.installationReportBandwidthStorageHeader}
                            flex="shrinkAndGrow"
                        >
                            <Stack vertical>
                                <Text>{t.installationReportBandwidthStorageTotalStorage}</Text>
                                <Stack>
                                    <ReadDirection direction="ltr">
                                        <Text
                                            testId="total_estimated_storage_project_summery"
                                            style="title"
                                        >
                                            {formattedStorage}
                                        </Text>
                                    </ReadDirection>
                                </Stack>
                                <Text>{t.installationReportBandwidthStorageTotalBandwidth}</Text>
                                <Stack>
                                    <ReadDirection direction="ltr">
                                        <Text
                                            testId="total_estimated_bandwidth_project_summery"
                                            style="title"
                                        >
                                            {formattedBandwidth}
                                        </Text>
                                    </ReadDirection>
                                </Stack>
                            </Stack>
                        </ReportCard>
                    </Stack>

                    <SummaryDetails />
                </Stack>
            </Box>
        );
    }
}

export const ProjectSummary = connect(mapStateToProps)(ProjectSummaryContainer);

ProjectSummary.displayName = 'ProjectSummary';
