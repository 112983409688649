import * as React from 'react';
import { Box, Border, Clickable, DragHandle } from 'app/components';
import {
    getCurrentProjectItem,
    getDeviceAndSubTypeForItem,
    getDeviceTypeForItem,
    getModelName,
} from 'app/modules/common';
import type { PiaId } from 'app/core/pia';
import type { Colors } from 'app/styles';
import { useDrag, useIsLayerHidden } from '../../../../hooks';
import { DeviceSummary } from '../device/DeviceSummary.component';
import type { DeviceType, Id, DeviceAndSubType } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { toaster } from 'app/toaster';
import { t } from 'app/translate';

interface IMapMenuDeviceItemProps {
    id: Id;
    name?: string;
    model: string;
    quantity?: number;
    deviceType?: DeviceType;
    deviceSubType?: DeviceAndSubType;
    productId: PiaId | null;
    color: Colors;
    isCustomDevice: boolean;
    disabled?: boolean;
    openSettings(): void;
}

export interface IMapMenuDeviceItemOwnProps {
    id: Id;
    color: Colors;
    disabled?: boolean;
    openSettings(): void;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IMapMenuDeviceItemOwnProps,
): IMapMenuDeviceItemProps => {
    const item = getCurrentProjectItem(storeState, ownProps.id);
    const deviceType = item ? getDeviceTypeForItem(storeState, item._id) : undefined;
    const deviceSubType = item ? getDeviceAndSubTypeForItem(storeState, item._id) : undefined;

    return {
        id: ownProps.id,
        name: item?.name,
        model: getModelName(storeState, ownProps.id),
        quantity: item?.quantity,
        deviceType,
        deviceSubType,
        productId: item ? item.productId : null,
        color: ownProps.color,
        disabled: ownProps.disabled,
        openSettings: ownProps.openSettings,
        isCustomDevice: item ? isCustomCamera(item) : false,
    };
};

const DeviceMenuItemContainer: React.FunctionComponent<IMapMenuDeviceItemProps> = ({
    id,
    name,
    model,
    quantity,
    deviceType,
    deviceSubType,
    productId,
    color,
    disabled,
    openSettings,
    isCustomDevice,
}) => {
    const dragRef = React.useRef<HTMLDivElement>(null);
    const dragImageRef = React.useRef<HTMLDivElement>(null);

    const dragState = useDrag({
        effect: 'link',
        ref: dragRef,
        imageRef: dragImageRef,
        imageOffsetX: 25,
        imageOffsetY: 25,
        data: { id, type: deviceSubType ?? 'camera' },
    });

    const isLayerHidden = useIsLayerHidden(deviceType, color);
    React.useEffect(() => {
        if (isLayerHidden && dragState.dragState === 'dragEnd') {
            toaster.info(t.deviceIsHidden);
        }
    }, [dragState.dragState, isLayerHidden]);

    return (
        <Clickable disabled={disabled} onClick={openSettings} allowDragThrough>
            <Border width={1} color="grey3" radius="4px">
                <Box
                    testId={`device_item_${name}`}
                    innerRef={dragRef}
                    padding="quart"
                    color="white"
                    alignItems="center"
                    width="100%"
                    minHeight="66px"
                >
                    {deviceType && quantity && name && (
                        <DeviceSummary
                            ref={dragImageRef}
                            quantity={quantity}
                            name={name}
                            productId={productId}
                            model={model}
                            deviceSubType={deviceSubType ?? deviceType}
                            deviceType={deviceType}
                            color={color}
                            isCustomCamera={isCustomDevice}
                            disabled={isLayerHidden}
                        />
                    )}
                    <DragHandle disabled={isLayerHidden} />
                </Box>
            </Border>
        </Clickable>
    );
};

export const DeviceMenuItem = connect(mapStateToProps)(DeviceMenuItemContainer);
DeviceMenuItem.displayName = 'DeviceMenuItem';
