import * as React from 'react';
import { Stack, Box, Border, PiaImage, Text, Badge, Currency, Grid } from 'app/components';
import type { IProduct } from 'app/modules/common';
import {
    deviceIcons,
    getLocaleFromCountryCode,
    getCurrencyFromCountryCode,
    PiaDescription,
    getCurrentProjectLocation,
} from 'app/modules/common';
import type { PiaId } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import { getProductUsps } from '../selectors/getProposalSummary';

interface IProposalSummaryItemOwnProps {
    product: IProduct;
}

interface IProposalSummaryItemProps extends IProposalSummaryItemOwnProps {
    usps: Record<PiaId, string[]>;
    locale: string;
    currency: string;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProposalSummaryItemOwnProps,
): IProposalSummaryItemProps => {
    const location = getCurrentProjectLocation(storeState);

    return {
        usps: getProductUsps(storeState),
        locale: getLocaleFromCountryCode(location?.countryCode),
        currency: getCurrencyFromCountryCode(location?.countryCode),
        ...ownProps,
    };
};

const ProposalSummaryItemContainer: React.FunctionComponent<IProposalSummaryItemProps> = ({
    usps,
    product,
    locale,
    currency,
}) => {
    return (
        <Stack alignItems="start" flex="shrink">
            <Border rightWidth={1} color="warmGrey4">
                <Box
                    paddingTop="base"
                    paddingBottom="doublePanel"
                    paddingRight="doublePanel"
                    flex="shrinkAndGrow"
                >
                    <Stack spacing="doublePanel" alignItems="start" flex="shrinkAndGrow">
                        <PiaImage
                            piaId={product.piaId ?? null}
                            icon={deviceIcons.categoryToIcon(product.piaCategory)}
                            imageSize="xxl"
                        />
                        <Stack vertical spacing="none" flex="shrinkAndGrow">
                            <Text style="subheader" color="warmGrey6">
                                {product.quantity > 1 && `${product.quantity} × `}
                                {product.name}
                            </Text>
                            {product.piaId && <PiaDescription productId={product.piaId} />}
                            <Grid spacing="quart">
                                {product.piaId &&
                                    usps[product.piaId]?.map((usp, index) => (
                                        <Badge key={index} label={usp} type="white" mini outline />
                                    ))}
                            </Grid>
                        </Stack>
                    </Stack>
                </Box>
            </Border>
            <Box
                flex="none"
                paddingTop="base"
                justifyContent="end"
                alignItems="start"
                width="100px"
            >
                <Stack notFullWidth vertical spacing="none" alignItems="end">
                    {product.quotePrice && product.quotePrice > 0 ? (
                        <Text onlyStyle semiBold color="green">
                            <Currency
                                showCurrency
                                hideFractions
                                currency={currency}
                                locale={locale}
                                value={product.quotePrice * product.quantity}
                            />
                        </Text>
                    ) : null}
                </Stack>
            </Box>
        </Stack>
    );
};

export const ProposalSummaryItem = connect(mapStateToProps)(ProposalSummaryItemContainer);

ProposalSummaryItem.displayName = 'ProposalSummaryItem';
