import * as React from 'react';
import { PiaImage } from '../piaImage';
import type { BoxValue } from '../../containers';
import { Box } from '../../containers';
import { Text } from '../../text';
import type { Icons } from '../../ui/icon';
import { Icon } from '../../ui/icon';
import { Badge } from '../../ui/badge';
import { t } from 'app/translate';
import type { IAutoTestable } from '../../ui-test';

interface IProductCardItemProps extends IAutoTestable {
    /**
     * The product name
     */
    name: string;
    /**
     * The product id or null if no product is selected
     */
    productId: number | null;
    /**
     * Show a discontinued badge
     */
    discontinued?: boolean;
    /**
     * What image to display if productId is null
     */
    fallbackImage?: string;
    /**
     * What icon to display if no pia image is found.
     * Defaults to "device"
     */
    icon?: Icons;
    /**
     * If set show warning icon with this text.
     */
    warningText?: string;
    /**
     * Sets the size of the image.
     */
    imageSize?: 'xl' | 'xxl';
    /**
     * Max-width of the card
     */
    maxWidth?: BoxValue;
}

/**
 * Display a product with image/icon and name vertically.
 * Used inside `<ProductCard>`
 */
export class ProductCardItem extends React.Component<IProductCardItemProps> {
    public render() {
        return (
            <Box direction="column" alignItems="center" maxWidth={this.props.maxWidth}>
                <PiaImage
                    piaId={this.props.productId}
                    icon={this.props.icon || 'category'}
                    iconProps={{
                        color: 'blue',
                        opaque: true,
                    }}
                    imageSize={this.props.imageSize ?? 'xxl'}
                    imageUrlFallback={
                        this.props.productId === null ? this.props.fallbackImage : undefined
                    }
                />
                {this.props.discontinued && (
                    <Badge compact label={t.badgeDiscontinued} type="red" />
                )}
                <Box
                    height="3em"
                    width={this.props.maxWidth ?? 165}
                    maxWidth={this.props.maxWidth}
                    alignItems="center"
                    justifyContent="center"
                    paddingTop="base"
                    direction="column"
                    spacing="base"
                >
                    <Text testId={this.props.testId} style="semibold" align="center">
                        {this.props.name}
                    </Text>
                    {this.props.warningText && (
                        <Box spacing="quart">
                            <Icon opaque icon="warning_small" color="red" size="xs" />
                            <Text small>{this.props.warningText}</Text>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }
}
