export enum PiaRelationTypes {
    Compatible = 'compatible',
    ImplicitlyCompatible = 'implicitlycompatible',
    Includes = 'includes',
    IncludedIn = 'includedin',
    Recommends = 'recommends',
    RecommendedBy = 'recommendedby',
    Replaces = 'replaces',
    ReplacedBy = 'replacedby',
    VirtuallyIncludedIn = 'virtuallyincludedin',
    VirtuallyIncludes = 'virtuallyincludes',
}
