import { EditFreeText } from './EditFreeText';
import * as React from 'react';
import { useService } from 'app/ioc';
import type { Id, IFreeTextPointEntity, IPersistence } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { useDragEndEvent } from '../../hooks';
import { useFreeTextDraggableItem } from './useFreeTextDraggableItem';
import { getFreeTextPointEntityFromId } from '../../selectors/getFreeTextPointEntity';
import { MapsService } from '../../services';
import { getTextBoxesVisible } from '../../selectors';
import { useMapContext } from '../context';

interface IFreeTextMapItemProps {
    id: Id;
}

export const FreeTextMapItem: React.FC<IFreeTextMapItemProps> = ({ id }) => {
    const { leafletMap } = useMapContext();
    const mapsService = useService(MapsService);
    const visible = useSelector(getTextBoxesVisible);
    const freeTextEntity = useSelector<IStoreState, IPersistence<IFreeTextPointEntity> | undefined>(
        (state) => getFreeTextPointEntityFromId(state, id),
    );
    const [isEditing, setIsEditing] = React.useState<boolean>(
        freeTextEntity?.creationDate === freeTextEntity?.updatedDate,
    );
    const closeFreeTextEdit = () => setIsEditing(false);
    const draggableFreeText = useFreeTextDraggableItem(freeTextEntity, isEditing);
    const [positionOnScreen, setPositionOnScreen] = React.useState<{ x: number; y: number }>({
        x: 0,
        y: 0,
    });

    useDragEndEvent(draggableFreeText, () => {
        mapsService.updateFreeTextPointLocation(id, draggableFreeText.getLatLng());
    });

    React.useEffect(() => {
        if (visible) {
            draggableFreeText.addTo(leafletMap.map);
        }
        return () => {
            draggableFreeText.removeFrom(leafletMap.map);
        };
    }, [draggableFreeText, leafletMap, visible]);

    React.useEffect(() => {
        const rect = draggableFreeText.getElement()?.getBoundingClientRect();
        setPositionOnScreen({ x: rect?.x ?? 0, y: rect?.y ?? 0 });
        leafletMap.map.off('mousedown', closeFreeTextEdit);
        leafletMap.map.on('mousedown', closeFreeTextEdit);
        draggableFreeText.off();
        draggableFreeText.on('dblclick', () => {
            setIsEditing(true);
        });

        return () => {
            draggableFreeText.off();
            leafletMap.map.off('mousedown', closeFreeTextEdit);
        };
    }, [draggableFreeText, leafletMap]);

    return freeTextEntity && isEditing ? (
        <EditFreeText
            freeTextEntity={freeTextEntity}
            position={positionOnScreen}
            onClose={closeFreeTextEdit}
        />
    ) : null;
};

FreeTextMapItem.displayName = 'FreeTextMapItem';
