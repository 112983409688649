import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

const getExportProjectSettingsState = (store: IStoreState) => store.exportProjectSettings;

/** True if we are generating or revoking an access token */
export const getIsCreatingAccessCode = createSelector(
    [getExportProjectSettingsState],
    (exportProjectState) => exportProjectState.loading,
);
