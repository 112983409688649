import type { IAction } from '../IAction';
import type { ActionCallback, IActionHandler } from './reducer.types';

/**
 * `IActionHandler` for handling a specific action.
 */
export class ActionHandler<TState, TPayload> implements IActionHandler<TState, TPayload> {
    /**
     * Creates a new `ActionHandler`.
     *
     * @param actionName the name of the action the handler should handle.
     * @param callback the callback triggered when handling a specific action.
     */
    constructor(
        private actionName: string,
        private callback: ActionCallback<TState, TPayload>,
    ) {}

    public handle(prevState: TState, action: IAction<TPayload>) {
        if (action.type === this.actionName) {
            return this.callback(prevState, action);
        }

        return prevState;
    }
}
