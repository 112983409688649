import * as leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { offset, rotate, trigonometry, type Point } from 'axis-webtools-util';
import { getShadowPolygons } from '../../../utils';
import { css } from '@emotion/css';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { LeafletMap } from '../LeafletMap';
import type { IPolygon } from 'app/modules/common';
import { diffMultiplePolygons, intersectPolygons } from 'app/modules/common';
import type { IAnalyticsPopupContent } from '../../../models/IAnalyticsPopupContent';
import { getItemLocalStorage } from 'app/core/persistence';
import { MapsService } from '../../../services';
import type {
    IInstallationPointModel,
    IInstallationPointSensorModel,
    ILatLng,
    IInstallationPointEntity,
    IDeviceAnalyticRange,
    PolyLine,
    IInstallationPoint,
} from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import type { IconTypes } from './utils/iconConstants';
import { throttle } from 'lodash-es';

export interface IBlockerShadowParams {
    renderHorizon: number;
    visibleArea?: IPolygon;
    resolutionGuide?: IPolygon;
    outline?: IPolygon;
    resolutionGuideAngle?: number;
}

export interface IIconInfo {
    latLng: ILatLng;
    visible: boolean;
}

const DEBUG_MODE = getItemLocalStorage('DebugMode') === 'true';

const polygonToLatLngs = (origin: leaflet.LatLng, polygon: IPolygon) =>
    polygon.regions.map((region) => region.map(offset(origin)));

const animationStyle = css`
    transition:
        opacity 200ms ease-in-out,
        fill 200ms ease-in-out,
        fill-opacity 200ms ease-in-out;
`;

const visibleAreaPrintOptions = (
    print: boolean,
    color: ColorsEnum,
): leaflet.PolylineOptions | undefined => {
    if (!print) return { stroke: false, fillColor: color };
    return { weight: 1, fillColor: ColorsEnum.grey2 };
};

const resolutionAreaPrintOptions = (print: boolean, color: ColorsEnum): leaflet.PolylineOptions => {
    if (!print) return { fillColor: color, weight: 3 };
    return { fillColor: ColorsEnum.grey2, stroke: true, weight: 1 };
};

type onConeClickType = (sensorId: number) => void;

export abstract class BaseCone {
    public visibleAreaPolygon: leaflet.Polygon;
    public resolutionGuidePolygon: leaflet.Polygon;
    public outlinePolygon: leaflet.Polygon;
    public debugPolygon: leaflet.Polygon;
    public sensor?: IInstallationPointSensorModel;
    public onClick: onConeClickType;
    /**
     * Update redraws the cone based on persisted properties in the installation point and sensor models.
     * Called typically on dragEnd when the installation point is persisted.
     */
    public abstract update(
        installationPoint: IInstallationPointModel,
        blockers: PolyLine[] | undefined,
        sensorId?: number,
    ): void;
    /**
     * reDraw uses the properties handed to it to redraw the cone.
     * Typically used when dragging something and passing properties from the redux state.
     */
    public abstract reDraw(blockers: PolyLine[] | undefined, props: Record<string, unknown>): void;

    /**
     * Rotates the target of this cone for the specified installation point
     * @returns The change required to rotate the target
     * */
    public abstract getRotatedTarget(
        installationPoint: IInstallationPoint,
        rotationOffsetInDeg: number,
    ): DeepPartial<IInstallationPoint>;
    /**
     * Persists the installation point to the repository
     */
    public abstract persistCoverageInfo(
        props: Record<string, unknown>,
    ): Promise<IInstallationPointEntity>;
    /**
     * The content to show in the target popup handle when dragging - return null to not show popup
     * @param targetDistance - distance to target handle
     */
    public abstract getTargetPopupContent(targetDistance: number): string | null;
    public abstract getFovPopupContent(angle: number): string | null;
    public abstract getAnalyticsPopupContent(): IAnalyticsPopupContent | null;
    public abstract getIsFovAdjustable(): boolean;
    public abstract getIsTargetAdjustable(): boolean;
    public abstract getHasDirectionalArrow(): boolean;
    public abstract getHasTargetLine(): boolean;
    public abstract updateDoriPixelsOn(blockers: PolyLine[] | undefined, isOn?: boolean): void;
    public abstract toggleTinyIcons(blockers: PolyLine[] | undefined, isOn?: boolean): void;
    public abstract updateRangeAnalytics(
        blockers: PolyLine[] | undefined,
        analyticRange?: IDeviceAnalyticRange,
    ): void;
    public abstract getIconInfo(): Record<IconTypes, IIconInfo> | undefined;
    public abstract getFovHandleLatLng(): ILatLng | undefined;
    public onIconPositionsChanged?: (iconEvent: Record<IconTypes, IIconInfo> | undefined) => void;
    public abstract horizontalAngle: number;
    protected abstract targetDistance: number;
    protected latLng: leaflet.LatLng;
    protected color: Colors;
    protected map: leaflet.Map;
    protected blockerLayers: leaflet.Layer[];
    protected selected: boolean;
    protected mapsService: MapsService;
    protected doriPixelsOn: boolean = false;
    protected useTinyIcons: boolean = false;

    constructor(
        protected leafletMap: LeafletMap,
        latLng: leaflet.LatLng,
        color: Colors,
    ) {
        this.map = leafletMap.map;
        this.color = color;
        this.latLng = latLng;
        this.selected = false;
        this.onClick = this.onConeClick;

        this.mapsService = ServiceLocator.get(MapsService);

        this.visibleAreaPolygon = leaflet.polygon([], {
            bubblingMouseEvents: false,
            className: animationStyle,
            color: ColorsEnum[this.color],
            ...visibleAreaPrintOptions(this.useTinyIcons, ColorsEnum[this.color]),
        });

        this.visibleAreaPolygon
            .on('click', () => this.onConeClick())
            .on('mousedown', this.stopPropagation);

        this.resolutionGuidePolygon = leaflet.polygon([], {
            fillOpacity: 0.5,
            bubblingMouseEvents: false,
            className: animationStyle,
            color: ColorsEnum[this.color],
            ...resolutionAreaPrintOptions(this.useTinyIcons, ColorsEnum[this.color]),
        });

        this.resolutionGuidePolygon
            .on('click', () => this.onConeClick())
            .on('mousedown', this.stopPropagation);

        this.outlinePolygon = leaflet.polygon([], {
            stroke: true,
            weight: this.useTinyIcons ? 1 : 3,
            fillOpacity: 0,
            bubblingMouseEvents: false,
            className: animationStyle,
            color: ColorsEnum[this.color],
        });

        this.outlinePolygon
            .on('click', () => this.onConeClick())
            .on('mousedown', this.stopPropagation);

        this.debugPolygon = leaflet.polygon([], {
            color: 'red',
            fillOpacity: 0.2,
            opacity: 0.3,
        });

        this.blockerLayers = leafletMap.getBlockerLayers();

        this.map.on('zoom', throttle(this.updateIconPosition, 200));
    }

    public updateIconPosition = () =>
        this.onIconPositionsChanged && this.onIconPositionsChanged(this.getIconInfo());

    public addToMap() {
        this.outlinePolygon.addTo(this.map);
        this.visibleAreaPolygon.addTo(this.map);
        this.resolutionGuidePolygon.addTo(this.map);
        this.debugPolygon.addTo(this.map);
    }

    public removeFromMap() {
        this.visibleAreaPolygon.removeFrom(this.map);
        this.resolutionGuidePolygon.removeFrom(this.map);
        this.outlinePolygon.removeFrom(this.map);
        this.debugPolygon.removeFrom(this.map);
    }

    public getCoords(): ILatLng {
        return this.latLng;
    }

    public getTargetCoords(): ILatLng {
        // Add 90 degrees since the cone assumes 0 is to the right (it is down in our map)
        const rotationAngleRad = trigonometry.toRadians(this.horizontalAngle + 90);

        const targetPos: Point = [this.targetDistance, 0];
        const rotatedPos = rotate(rotationAngleRad)(targetPos);
        return offset(this.latLng)(rotatedPos);
    }

    public getTargetRotationOffset(): number {
        return 0;
    }

    public getDoriPixelsOn() {
        return this.doriPixelsOn;
    }

    public getUseTinyIcons() {
        return this.useTinyIcons;
    }

    public setColor(color: Colors, useTinyIcons: boolean = false) {
        this.color = color;

        this.resolutionGuidePolygon.setStyle({
            color: ColorsEnum[this.color],
            ...resolutionAreaPrintOptions(useTinyIcons, ColorsEnum[this.color]),
        });

        this.visibleAreaPolygon.setStyle({
            ...visibleAreaPrintOptions(useTinyIcons, ColorsEnum[this.color]),
        });

        this.outlinePolygon
            .setStyle({
                color: ColorsEnum[this.color],
                weight: useTinyIcons ? 1 : 3,
            })
            .bringToFront();
    }

    public focus() {
        this.visibleAreaPolygon.setStyle({
            fillOpacity: 0.2,
            opacity: 1,
        });
        this.resolutionGuidePolygon.setStyle({
            fillOpacity: 0.5,
            opacity: 1,
        });
        this.outlinePolygon.setStyle({
            opacity: 1,
        });
    }

    public blur() {
        this.visibleAreaPolygon.setStyle({
            fillOpacity: 0.05,
            opacity: 0.2,
        });
        this.resolutionGuidePolygon.setStyle({
            fillOpacity: 0.2,
            opacity: 0.5,
        });
        this.outlinePolygon.setStyle({
            opacity: 0.2,
        });
    }

    protected onConeClick(sensorId?: number) {
        this.onClick(sensorId ?? 1);
    }

    /** Gets target rotation angle for single cone devices */
    protected rotateTargetAngle(
        coneType: keyof Pick<IInstallationPoint, 'speaker' | 'radar' | 'panRange'>,
        installationPoint: IInstallationPoint,
        rotationOffsetInDeg: number,
    ): Partial<IInstallationPoint> {
        const coneTargetToUpdate = installationPoint[coneType]?.target;
        if (!coneTargetToUpdate) return installationPoint;

        const rotatedTarget = {
            target: { horizontalAngle: coneTargetToUpdate.horizontalAngle + rotationOffsetInDeg },
        };
        return {
            [coneType]: rotatedTarget,
        };
    }

    protected applyBlockerShadows(
        blockers: PolyLine[] | undefined,
        { renderHorizon, visibleArea, resolutionGuide, outline }: IBlockerShadowParams,
    ) {
        const shadows = getShadowPolygons(this.latLng, blockers, renderHorizon);

        let visibleAreaPolygon;

        // visible area polygon
        if (visibleArea) {
            visibleAreaPolygon = diffMultiplePolygons(visibleArea, shadows);
            this.setPolygon(this.visibleAreaPolygon, visibleAreaPolygon);
        }

        // outline polygon
        if (outline) {
            const outlinePolygon = diffMultiplePolygons(outline, shadows);
            this.setPolygon(this.outlinePolygon, outlinePolygon);
        }

        // resolution guide polygon
        if (resolutionGuide) {
            const resolutionGuidePolygon = visibleAreaPolygon
                ? intersectPolygons(resolutionGuide, visibleAreaPolygon)
                : diffMultiplePolygons(resolutionGuide, shadows);
            this.setPolygon(this.resolutionGuidePolygon, resolutionGuidePolygon);
        }

        if (DEBUG_MODE) {
            console.info('Number of shadows:', shadows.length);
            const shadowsLatLngs = shadows.map(
                (shadow) => polygonToLatLngs(this.latLng, shadow)[0],
            );
            this.debugPolygon.setLatLngs(shadowsLatLngs);
        }
    }

    protected stopPropagation = (e: leaflet.LeafletMouseEvent) => {
        // due to error in leaflet.DomEvent.stopPropagation
        // https://github.com/Leaflet/Leaflet/blob/3084db0bcb92c8bc5e8f68d297ee8feabed5de98/src/dom/DomEvent.js#L164
        leaflet.DomEvent.stopPropagation(e);
    };

    protected setPolygon = (leafletPolygon: leaflet.Polygon, polygon: IPolygon) => {
        const latLngs = polygonToLatLngs(this.latLng, polygon);
        leafletPolygon.setLatLngs(latLngs);
    };
}
