import { injectable } from 'inversify';
import type {
    IPersistence,
    IItemEntity,
    Id,
    IIdRev,
    IItem,
    IProfileOverridePropertiesEntity,
    IItemPropertiesEntity,
} from 'app/core/persistence';
import {
    defaultDecoderFilter,
    defaultPacFilter,
    defaultAlerterFilter,
    defaultRadarFilter,
    defaultPeopleCounterFilter,
    ItemService,
    CurrentProjectService,
    InstallationPointService,
    getDefaultProfileOverrideEntity,
    defaultDockingStationFilter,
    defaultPagingConsoleFilter,
    defaultConnectivityDeviceFilter,
} from 'app/core/persistence';

import { t } from 'app/translate';
import type { PiaCategory, PiaId } from 'app/core/pia';
import {
    PiaItemDecoderCategory,
    PiaItemPacCategory,
    PiaItemDetectorCategory,
    PiaItemPeopleCounterCategory,
    PiaItemAlerterCategory,
    isCameraCategory,
    PiaItemWearablesCategory,
    getAllCameraCategories,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from 'app/core/pia';
import { ModalService } from 'app/modal';
import { defaultColors } from 'app/core/common';
import { AccessoryService } from '../../accessory/services';
import { IAddProductProps } from '../models';

@injectable()
export class OtherDevicesService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private modalService: ModalService,
        private installationPointService: InstallationPointService,
        private accessoryService: AccessoryService,
    ) {}

    // Generic radar (without PiaId) can be added from maps
    public addRadar = (productId: PiaId | null, quantity: number, name: string) => {
        const defaultProfile = this.currentProjectService.getProjectEntity().defaultProfile;
        const item: IItem = {
            description: '',
            name,
            notes: '',
            productId,
            properties: this.toProperties(
                PiaItemDetectorCategory.RADARDETECTORS,
                [PiaItemDetectorCategory.RADARDETECTORS],
                defaultProfile,
            ),
            quantity,
            color: defaultColors.DEFAULT_RADAR_COLOR,
        };

        return this.itemService.addToCurrentProject(item);
    };

    // Other devices cannot have PiaId null since no generic devices
    public addOrUpdateDevice = async (
        piaItemId: PiaId,
        category: PiaCategory,
        categories: PiaCategory[],
        defaultProfile: Id,
        installationPointsIdRev: IIdRev[],
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
    ) => {
        if (itemToEdit) {
            return this.updateOtherDevice(
                piaItemId,
                category,
                categories,
                itemToEdit,
                installationPointsIdRev,
            );
        } else if (newItemProps) {
            return this.add(piaItemId, newItemProps, category, categories, defaultProfile);
        }
    };

    private add = async (
        productId: PiaId,
        newItemProps: IAddProductProps,
        category: PiaCategory,
        categories: PiaCategory[],
        defaultProfile: Id,
    ) => {
        const item: IItem = {
            description: '',
            name: newItemProps.name,
            notes: newItemProps.notes || '',
            productId,
            properties: this.toProperties(category, categories, defaultProfile),
            quantity: newItemProps.quantity,
            color: newItemProps.color,
        };

        return this.itemService.addToCurrentProject(item);
    };

    private toProperties(
        category: PiaCategory,
        categories: PiaCategory[],
        defaultProfile: Id,
        itemToUpdate?: IPersistence<IItemEntity>,
        profileOverride?: IProfileOverridePropertiesEntity,
        customPixelDensity?: number,
    ): IItemPropertiesEntity {
        const isRadarCombined =
            categories.includes(PiaItemDetectorCategory.RADARDETECTORS) &&
            isCameraCategory(category);
        // If the other product is a camera/radar combination (i.e Oxxo Q1656-DLE) the properties that should
        // be added are the camera properties.
        if (isRadarCombined) {
            const properties = {
                camera: {
                    filter: this.currentProjectService.getProjectDefaultCameraFilter(),
                    associatedProfile: defaultProfile,
                    profileOverride: profileOverride
                        ? profileOverride
                        : getDefaultProfileOverrideEntity(),
                },
            };
            return properties;
        }
        switch (category) {
            case PiaItemAlerterCategory.ALERTERS:
                return {
                    alerter: {
                        filter:
                            itemToUpdate?.properties.alerter !== undefined
                                ? itemToUpdate.properties.alerter.filter
                                : defaultAlerterFilter,
                    },
                };
            case PiaItemDecoderCategory.DECODER:
                return {
                    decoder: {
                        filter:
                            itemToUpdate?.properties.decoder !== undefined
                                ? itemToUpdate.properties.decoder.filter
                                : defaultDecoderFilter,
                    },
                };
            case PiaItemPacCategory.DOORCONTROLLERS:
            case PiaItemPacCategory.IORELAYS:
            case PiaItemPacCategory.READERS:
            case PiaItemPacCategory.RELAYEXPMODULES:
                return {
                    pac: {
                        filter:
                            itemToUpdate?.properties.pac !== undefined
                                ? itemToUpdate.properties.pac.filter
                                : defaultPacFilter,
                    },
                };
            case PiaItemDetectorCategory.RADARDETECTORS:
                return {
                    radarDetector: {
                        filter:
                            itemToUpdate?.properties.radarDetector !== undefined
                                ? itemToUpdate.properties.radarDetector.filter
                                : defaultRadarFilter,
                    },
                };
            case PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES:
                return {
                    connectivityDevice: {
                        filter:
                            itemToUpdate?.properties.connectivityDevice !== undefined
                                ? itemToUpdate.properties.connectivityDevice.filter
                                : defaultConnectivityDeviceFilter,
                    },
                };
            case PiaItemPagingConsoleCategory.PAGINGCONSOLE:
                return {
                    pagingConsole: {
                        filter:
                            itemToUpdate?.properties.pagingConsole !== undefined
                                ? itemToUpdate.properties.pagingConsole.filter
                                : defaultPagingConsoleFilter,
                    },
                };
            case PiaItemPacCategory.DOORSTATIONS:
                const props = {
                    pac: {
                        filter:
                            itemToUpdate?.properties.pac !== undefined
                                ? itemToUpdate.properties.pac.filter
                                : defaultPacFilter,
                    },
                    camera: categories.some((cat) => getAllCameraCategories().includes(cat))
                        ? {
                              filter:
                                  itemToUpdate?.properties.camera !== undefined
                                      ? itemToUpdate.properties.camera.filter
                                      : this.currentProjectService.getProjectDefaultCameraFilter(
                                            true,
                                        ),
                              associatedProfile: defaultProfile,
                              profileOverride: profileOverride
                                  ? profileOverride
                                  : getDefaultProfileOverrideEntity(),
                          }
                        : undefined,
                };
                if (customPixelDensity !== undefined && props.camera) {
                    props.camera.filter.pixelDensity = customPixelDensity;
                }
                return props;
            case PiaItemPeopleCounterCategory.PEOPLECOUNTERS:
                return { peopleCounter: { filter: defaultPeopleCounterFilter } };
            case PiaItemWearablesCategory.CONTROLLER:
                return { systemController: {} };
            case PiaItemWearablesCategory.DOCKING:
                return { dockingStation: { filter: defaultDockingStationFilter } };
            default:
                throw new Error(`Not an other device category: ${category}`);
        }
    }

    private async updateOtherDevice(
        piaId: PiaId,
        category: PiaCategory,
        categories: PiaCategory[],
        itemToUpdate: IPersistence<IItemEntity>,
        installationPointsIdRev?: IIdRev[],
    ) {
        const originalItemEntity = await this.itemService.getItem(itemToUpdate._id);
        const productIdIsUpdated = itemToUpdate && itemToUpdate.productId !== piaId;
        const hasIncompatibleChildren = this.accessoryService.getHasPiaItemsToRemove(
            itemToUpdate._id,
            piaId,
        );
        const accessoriesMustBeRemoved = productIdIsUpdated && hasIncompatibleChildren;
        const nbrInstallationPoints = installationPointsIdRev ? installationPointsIdRev.length : 0;
        const quantityLessThanIPs = itemToUpdate.quantity < nbrInstallationPoints;

        if (accessoriesMustBeRemoved) {
            const confirm = await this.accessoryService.getConfirmDialogue(itemToUpdate._id, piaId);

            // If the user clicked cancel we should not continue
            if (!confirm) {
                return;
            }
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                originalItemEntity._id,
                piaId,
            );
        } else if (quantityLessThanIPs && installationPointsIdRev) {
            const confirm = await this.modalService.createConfirmDialog({
                header: t.removeInstallationPointsConfirmationGROUP.header,
                body: t.removeInstallationPointsConfirmationGROUP.body,
                cancelButtonText: t.cancel,
                confirmButtonText: t.change,
            })();

            // If the user clicked cancel we should not continue
            if (!confirm) {
                return;
            }
            await this.installationPointService.removeInstallationPoints(installationPointsIdRev);
        }

        const defaultProfile = this.currentProjectService.getProjectEntity().defaultProfile;

        const updatedProps = {
            quantity: itemToUpdate.quantity,
            name: itemToUpdate.name,
            productId: piaId,
            properties: this.toProperties(
                category,
                categories,
                originalItemEntity.properties.camera
                    ? originalItemEntity.properties.camera.associatedProfile
                    : defaultProfile,
                itemToUpdate,
                originalItemEntity.properties.camera
                    ? originalItemEntity.properties.camera.profileOverride
                    : undefined,
                originalItemEntity.properties.camera?.filter.pixelDensity ?? undefined,
            ),
        };

        const updatedDevice = await this.itemService.updateItem(itemToUpdate._id, updatedProps);

        const installationPointsForDevice = (
            await this.installationPointService.getAllInstallationPoints()
        ).filter((ip) => ip.path.includes(itemToUpdate._id || ''));

        if (installationPointsIdRev && updatedDevice) {
            await this.installationPointService.updateInstallationPointsToNewDevice(
                updatedDevice._id,
                itemToUpdate.productId,
                installationPointsForDevice,
            );
        }

        return updatedDevice;
    }
}
