import * as React from 'react';
import { useSelector } from 'react-redux';
import { css, cx } from '@emotion/css';
import { t } from 'app/translate';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import {
    Box,
    Button,
    Card,
    Clickable,
    Icon,
    IconButton,
    PiaImage,
    Stack,
    Text,
    toTestIdFormat,
} from 'app/components';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaCamera, IPiaItem, IPiaSensorUnit, PiaId } from 'app/core/pia';
import type { IAddProductProps } from 'app/modules/common';
import {
    addProductToPreviouslyAdded,
    compareStyle,
    discontinuedLabelStyle,
    getBarebonePiaId,
    getPiaCameraForProductId,
    hoverContainerStyle,
    hoverProductSectionStyle,
    MsrpWithPiaImage,
    PiaItemMsrp,
    productItemStyle,
    ProductName,
    selectedItemHoverContainerStyle,
    selectedItemStyle,
    uspContainerStyle,
    uspTextStyle,
    warningStyle,
} from 'app/modules/common';
import { DocumentationService } from 'app/modules/documentation';
import type { ISingularLens } from '../selectors';
import {
    getShouldUpdateLenses,
    getBestReplacementLens,
    getEditItem,
    getParentDeviceId,
    getPiaIdsForMsrp,
    getProductItem,
    getSingularLensToDisplay,
} from '../selectors';
import type { IProductItem } from '../models';
import { DeviceSelectorActionService } from '../services';
import { SensorDetails } from './SensorDetails.container';
import { AddProductModal } from './AddProductModal';
import { AppConstants } from 'app/AppConstants';
import { getFilterHasChanged } from '../selectors/getFilterHasChanged';
import { uniq } from 'lodash';
import { getCoverageAreaSupportedForPiaId } from '../selectors/getCoverageAreaSupported';
import { useResponsive } from 'app/hooks';

interface IProductItemProps {
    productId: PiaId;
    /**
     * If the item has been selected by the user through interaction, not to be confused by 'isCurrentSelection',
     * that refers to the existing item in an edit/change operation.
     */
    selected: boolean;
    /**
     * If the item is top recommended it has a higher z-index value so that it is not covered by the product selector header
     */
    topRecommended?: boolean;
}

export const ProductItem: React.FC<IProductItemProps> = React.memo(
    ({ productId, selected, topRecommended }) => {
        // Use desktop item width before useResponsive returns a value to avoid resizing of product cards for the majority of users.
        const itemWidth = useResponsive('248px', '163px', '163px') ?? '248px';
        const smallItemHeight = useResponsive('270px', '235px', '235px');
        const piaImageSize = useResponsive('xxl', 'xl', 'xl');
        const piaImageBoxSize = useResponsive('130px', '98px', '98px');
        const lensNameTextWidth = useResponsive('180px', '100px', '100px');

        const [actionService] = React.useState<DeviceSelectorActionService>(
            ServiceLocator.get(DeviceSelectorActionService),
        );
        const [documentationService] = React.useState<DocumentationService>(
            ServiceLocator.get(DocumentationService),
        );

        const filterHasChanged = useSelector<IStoreState, boolean>(getFilterHasChanged);

        // Parent device id is used to determine if we are adding/editing a child device
        const parentDeviceId = useSelector<IStoreState, Id | null>(getParentDeviceId);

        const productItem = useSelector<IStoreState, IProductItem | undefined>((state) =>
            getProductItem(state, productId),
        );

        const coverageAreaSupported = useSelector<IStoreState, boolean>((state) =>
            getCoverageAreaSupportedForPiaId(state, productId),
        );

        const isSensor = useSelector<IStoreState, IPiaCamera | IPiaSensorUnit | undefined>(
            (state) => getPiaCameraForProductId(state, productId),
        );

        const editDeviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
            getEditItem,
        );

        const replacementLens = useSelector<IStoreState, IPiaItem | undefined>((state) =>
            getBestReplacementLens(state, productId),
        );

        const barebonePiaId = useSelector<IStoreState, number | undefined>((state) =>
            getBarebonePiaId(state, productId),
        );

        const piaIdsForMsrp = useSelector<IStoreState, PiaId[]>((state) =>
            getPiaIdsForMsrp(state, productId),
        );

        const shouldUpdateLenses = useSelector<IStoreState, boolean>((state) =>
            getShouldUpdateLenses(state, productId),
        );

        const lensToDisplay = useSelector<IStoreState, ISingularLens | undefined>((state) =>
            getSingularLensToDisplay(state, productId),
        );

        const numberOfSensors = isSensor?.properties.imageSensors || 1;

        const isEditMode = editDeviceItem !== undefined;
        const [hover, setHover] = React.useState<boolean>(false);
        const [borderColor, setBorderColor] = React.useState<Colors>('grey3');
        const [showAddProductModal, setShowAddProductModal] = React.useState<boolean>(false);

        // This is the current selected item if there is an editDeviceItem without a productId
        const isCurrentSelection =
            editDeviceItem && editDeviceItem.productId === productId && !shouldUpdateLenses;

        React.useEffect(() => {
            setBorderColor(
                productItem?.discontinued
                    ? 'red'
                    : selected || isCurrentSelection
                      ? 'yellow'
                      : 'grey3',
            );
        }, [selected, isCurrentSelection, productItem?.discontinued]);

        if (!productItem) {
            return null;
        }

        const vendorIs2N = productItem.vendor === '2N';

        // Set/reset selected item
        const toggleSelectedItem = () => {
            if (!isCurrentSelection) {
                actionService.setSelectedProductId(selected ? null : productId);
            }
        };

        const borderColorStyle = css`
            border-color: ${ColorsEnum[borderColor]};
        `;

        const depthStyle = css`
            z-index: ${topRecommended
                ? AppConstants.topRecommendedProductDepth
                : AppConstants.productItemDepth};
        `;

        const downloadProductSheet = () => {
            documentationService.getDatasheet(productItem.productId);
        };

        const toggleAddProductModalVisibility = (visible: boolean) => {
            if (!selected) {
                toggleSelectedItem();
            }
            setShowAddProductModal(visible);
            setHover(false);
        };

        const addOrUpdateProduct = (event: React.SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            if (editDeviceItem) {
                updateProduct();
            } else {
                toggleAddProductModalVisibility(true);
            }
        };

        const addProduct = (addProductProps: IAddProductProps) => {
            parentDeviceId
                ? actionService.addOrUpdateChildDevice(
                      productItem.productId,
                      parentDeviceId,
                      undefined,
                      addProductProps,
                      replacementLens && {
                          lensPiaId: replacementLens.id,
                          numberOfSensors,
                          barebonePiaId,
                      },
                  )
                : actionService.addOrUpdateDevice(
                      productItem.productId,
                      undefined,
                      addProductProps,
                      replacementLens && {
                          lensPiaId: replacementLens.id,
                          numberOfSensors,
                          barebonePiaId,
                      },
                  );
            addProductToPreviouslyAdded(productItem.productId);
            setShowAddProductModal(false);
            setHover(false);
        };

        const updateProduct = () => {
            parentDeviceId
                ? actionService.addOrUpdateChildDevice(
                      productItem.productId,
                      parentDeviceId,
                      editDeviceItem,
                      undefined,
                      shouldUpdateLenses
                          ? replacementLens
                              ? {
                                    lensPiaId: replacementLens.id,
                                    numberOfSensors,
                                    barebonePiaId,
                                }
                              : null
                          : undefined,
                  )
                : actionService.addOrUpdateDevice(
                      productItem.productId,
                      editDeviceItem,
                      undefined,
                      shouldUpdateLenses
                          ? replacementLens
                              ? {
                                    lensPiaId: replacementLens.id,
                                    numberOfSensors,
                                    barebonePiaId,
                                }
                              : null
                          : undefined,
                  );
            addProductToPreviouslyAdded(productItem.productId);
        };

        const renderHoverSection = (className: string) => {
            if (vendorIs2N && !isSensor) {
                // 2N products do not have a product sheet and
                // should only display sensor details if it is a sensor type
                return null;
            }

            return (
                <div className={className}>
                    <div
                        className={cx(hoverProductSectionStyle(248), borderColorStyle, depthStyle)}
                    >
                        <Box
                            testId={`${productItem.name}_camera_hover_section`}
                            color={isCurrentSelection ? 'yellow1' : 'transparent'}
                            direction="column"
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {!vendorIs2N && (
                                <Box paddingY="half">
                                    <IconButton
                                        color="blue"
                                        icon="file_download"
                                        text={t.documentationDatasheet}
                                        onClick={downloadProductSheet}
                                    />
                                </Box>
                            )}
                            {isSensor && <SensorDetails productId={productItem.productId} />}
                        </Box>
                    </div>
                </div>
            );
        };

        return (
            <React.Fragment>
                {showAddProductModal && (
                    <AddProductModal
                        productInfo={{
                            productId: productItem.productId,
                            name: productItem.name,
                        }}
                        parentDeviceId={parentDeviceId}
                        onClose={() => toggleAddProductModalVisibility(false)}
                        onAddProduct={addProduct}
                    />
                )}
                <div
                    className={
                        selected || isCurrentSelection ? selectedItemStyle : productItemStyle
                    }
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    {productItem.discontinued && (
                        <div className={discontinuedLabelStyle}>
                            <Text color="white" style="small">
                                {t.discontinued}
                            </Text>
                        </div>
                    )}
                    <Card notFullWidth hideOverflow borderColor={borderColor}>
                        <Clickable onClick={toggleSelectedItem}>
                            <Box
                                testId={`${productItem.name}_camera_card`}
                                color={isCurrentSelection ? 'yellow1' : 'transparent'}
                                direction="column"
                                alignItems="center"
                                width={itemWidth}
                                // Changed to go with smallItemHeight only if no usps, since some cameras have an empty array of usps,
                                // and will get wrong height when surrounded by other cameras.
                                height={productItem.usps ? '340px' : smallItemHeight}
                            >
                                {!coverageAreaSupported && (
                                    <div className={cx('full-opacity', warningStyle)}>
                                        <Icon
                                            icon="warning"
                                            color="yellowDark"
                                            title={t.notSupportedByMap}
                                        />
                                    </div>
                                )}
                                {isSensor && (
                                    <div className={cx('full-opacity', compareStyle)}>
                                        <Box
                                            padding="quart"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                icon="compare"
                                                title={t.compare}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    actionService.addProductToCompare(productItem);
                                                }}
                                                testId={toTestIdFormat(
                                                    `${productItem.name}_camera_compare`,
                                                )}
                                            />
                                        </Box>
                                    </div>
                                )}
                                <Box
                                    padding="panel"
                                    height={piaImageBoxSize}
                                    direction="column"
                                    justifyContent="between"
                                    alignItems="center"
                                >
                                    <PiaImage
                                        imageSize={lensToDisplay ? 'xl' : piaImageSize}
                                        piaId={productItem.productId}
                                        icon={productItem.icon}
                                    />
                                    {lensToDisplay && (
                                        <Stack
                                            spacing="none"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon icon="add" size="sm" />
                                            <PiaImage
                                                piaId={lensToDisplay.piaId}
                                                icon="lens"
                                                imageSize="md"
                                            />
                                            <Box maxWidth={lensNameTextWidth} paddingLeft="quart">
                                                <Text
                                                    style="semibold"
                                                    whiteSpace="nowrap"
                                                    testId={`${productItem.name}_recommended_lens`}
                                                >{`${lensToDisplay.name}`}</Text>
                                            </Box>
                                        </Stack>
                                    )}
                                </Box>
                                <Box
                                    direction="column"
                                    height="40px"
                                    alignItems="center"
                                    paddingX="half"
                                    maxWidth="100%"
                                >
                                    <ProductName
                                        fullWidth
                                        whiteSpace="nowrap"
                                        name={productItem.name}
                                        large
                                        allBold={vendorIs2N}
                                    />
                                </Box>
                                {productItem.usps && (
                                    <div className={uspContainerStyle}>
                                        <div className={uspTextStyle}>
                                            <Text inline color="grey6">
                                                {productItem.usps.join(', ')}
                                            </Text>
                                        </div>
                                    </div>
                                )}
                                <Box
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight="38px"
                                    width="80%"
                                >
                                    {uniq(piaIdsForMsrp).map((piaId, index) =>
                                        piaIdsForMsrp.length > 1 ? (
                                            <MsrpWithPiaImage
                                                includeMultipacks
                                                key={`${piaId}_${index}`}
                                                piaId={piaId}
                                                displayImage={lensToDisplay !== undefined}
                                            />
                                        ) : (
                                            <PiaItemMsrp
                                                includeMultipacks
                                                key={`${piaId}_${index}`}
                                                piaId={piaId}
                                            />
                                        ),
                                    )}
                                </Box>
                                <Box minWidth="110px" justifyContent="center" padding="half">
                                    <Button
                                        primary={selected}
                                        onClick={addOrUpdateProduct}
                                        disabled={
                                            isCurrentSelection &&
                                            !filterHasChanged &&
                                            !shouldUpdateLenses
                                        }
                                        testId={`${productItem.name}_add_Btn`}
                                    >
                                        {isEditMode ? t.change : t.add}
                                    </Button>
                                </Box>
                            </Box>
                        </Clickable>
                    </Card>

                    {hover && renderHoverSection(hoverContainerStyle)}
                    {selected && renderHoverSection(selectedItemHoverContainerStyle)}
                </div>
            </React.Fragment>
        );
    },
);

ProductItem.displayName = 'ProductItem';
