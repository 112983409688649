import { createSelector } from 'reselect';
import {
    getCurrentProjectFloorPlans,
    getIdFromProps,
    getMapLocation,
    isGeoMap,
    rectifyImageBounds,
    sortMapsByDistance,
    toCacheKey,
} from 'app/modules/common';
import { getSelectedMapId } from './getSelectedId';
import { getMapsState } from './getMapsState';
import { getCurrentMapLocation } from './location';
import { creationDateReverseComparator, nameComparator } from 'app/utils';
import { createCachedSelector } from 're-reselect';
import { isDefined } from 'axis-webtools-util';
import { t } from 'app/translate';
import type { IFloorPlanEntity } from 'app/core/persistence';

/**
 * Returns the floor plan sort order
 */
const sortFloorPlans = (a: IFloorPlanEntity, b: IFloorPlanEntity) => {
    // default map should always be first
    if (a.isDefault) return -1;
    if (b.isDefault) return 1;

    // street map should always be second
    if (a.mapType === 'StreetMap' && b.mapType === 'FloorPlan') return -1;
    if (b.mapType === 'StreetMap' && a.mapType === 'FloorPlan') return 1;

    // sort by creation date reverse
    return a.creationDate < b.creationDate ? -1 : 1;
};

// renamed for clarity without breaking existing code
export const getAllMaps = getCurrentProjectFloorPlans;

const getAllMapsArray = createSelector([getAllMaps], (maps) =>
    Object.values(maps).filter(isDefined),
);

/**
 * Returns all maps sorted by creation date
 */
export const getMapsSortedByDate = createSelector([getAllMapsArray], (floorPlans) =>
    floorPlans.sort(creationDateReverseComparator),
);

/**
 * Returns all maps sorted so that the default map is always first, followed by old street
 * maps, followed by floor plans sorted by creation date
 */
export const getMapsSortedByType = createSelector([getAllMapsArray], (floorPlans) => {
    return floorPlans.sort(sortFloorPlans);
});

/**
 * Returns the map names for the current project
 * */
export const getMapNames = createSelector([getMapsSortedByDate], (floorPlans) =>
    floorPlans.map(({ name }) => name),
);

/**
 * Returns the focused floor plan id if it exists. This is the geolocated floor plan currently
 * zoomed in on in the map view.
 */
const getFocusedFloorPlanId = createSelector([getMapsState], (state) => {
    return state.focusedFloorPlanId;
});

/**
 * Returns the focused floor plan if it exists. This is the geolocated floor plan currently
 * zoomed in on in the map view.
 */
export const getFocusedFloorPlan = createSelector(
    [getAllMaps, getFocusedFloorPlanId],
    (floorPlansRecord, focusedFloorPlanId) => {
        const focusedFloorPlan = focusedFloorPlanId
            ? floorPlansRecord[focusedFloorPlanId]
            : undefined;

        if (isGeoMap(focusedFloorPlan)) {
            throw new Error('the focused floor plan cannot be a geomap');
        }

        if (!focusedFloorPlan) {
            // Either focused floor plan was removed or not yet available
            return undefined;
        }

        return rectifyImageBounds(focusedFloorPlan);
    },
);

/**
 * Returns all floor plans for the current project
 */
export const getFloorPlans = createSelector([getMapsSortedByDate], (maps) => {
    return maps.filter((map) => !isGeoMap(map));
});

/**
 * Returns the geomaps for the current project
 */
export const getGeoMaps = createSelector([getMapsSortedByDate], (maps) => {
    return maps.filter(isGeoMap);
});

/**
 * Get a specific geomap by id
 */
export const getGeoMap = createCachedSelector([getGeoMaps, getIdFromProps], (geoMaps, geoMapId) =>
    geoMaps.find((map) => map._id === geoMapId),
)(toCacheKey);

/**
 * Returns the default geomap for the current project
 */
export const getDefaultGeoMap = createSelector([getGeoMaps], (geoMaps) => {
    return geoMaps.find((map) => map.isDefault);
});

/**
 * Returns the geomaps for the current project, with the default map first.
 */
export const getGeoMapsSortedByName = createSelector(
    [getDefaultGeoMap, getGeoMaps],
    (defaultMap, maps) => {
        if (!defaultMap) return [];
        const defaultGeoMap = { ...defaultMap, name: t.currentLocation } as typeof defaultMap;
        return [defaultGeoMap, ...maps.filter((map) => !map.isDefault).sort(nameComparator)];
    },
);

/**
 * Returns the currently selected map
 */
export const getSelectedMap = createSelector(
    [getAllMaps, getSelectedMapId],
    (mapsRecord, selectedMapId) => (selectedMapId && mapsRecord[selectedMapId]) || undefined,
);

/**
 * Get the geomap closest to the current view (the leaflet viewport)
 */
const getGeoMapClosestToCurrentView = createSelector(
    [getGeoMaps, getCurrentMapLocation],
    (geoMaps, location) => {
        if (!location) {
            return undefined;
        }
        const sortedGeoMaps = sortMapsByDistance(geoMaps, location);

        return sortedGeoMaps[0];
    },
);

/**
 * Returns the currently selected map. Falls back to the default map if no map
 * is selected
 */
export const getSelectedMapOrDefault = createSelector(
    [getSelectedMap, getGeoMapClosestToCurrentView, getDefaultGeoMap, getMapsSortedByType],
    (selectedMap, currentGeoMap, defaultMap, floorPlans) => {
        if (!selectedMap) {
            return currentGeoMap ?? floorPlans[0] ?? defaultMap;
        }

        return rectifyImageBounds(selectedMap);
    },
);

const getSelectedMapLocation = createSelector([getSelectedMapOrDefault], getMapLocation);

/**
 * Get the closest geomap to the currently selected map. Falls back to the default geomap
 */
export const getGeoMapClosestToSelectedMap = createSelector(
    [getSelectedMapLocation, getGeoMaps, getDefaultGeoMap],
    (location, geoMaps, defaultMap) => {
        const sortedGeoMaps = sortMapsByDistance(geoMaps, location);

        return sortedGeoMaps[0] ?? defaultMap;
    },
);
