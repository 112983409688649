import * as React from 'react';
import { css } from '@emotion/css';
import classNames from 'classnames';
import { clamp, debounce } from 'lodash-es';
import { Label } from '../../text';
import { Box } from '../../containers/box/Box.component';
import { Text } from '../../text/text/Text.component';
import { Stack } from '../../layout/stack/Stack.component';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { TextInputStyle } from '../textInput/TextInput.component';
import type { IExtendableComponent } from 'app/components/models';

const NumberInputStyle = css`
    width: 5.1em;
    &:focus,
    &:hover {
        background-color: ${ColorsEnum.white};
    }
`;

const InheritHeightStyle = css`
    height: auto;
`;

const NoBorderStyle = css`
    border-width: 0 !important;
`;

const TransparentStyle = css`
    background: ${ColorsEnum.transparent};
`;

/* Hide spinner for firefox */
/* Hide spinner for webkit */
const HideSpinnerStyle = css`
    -moz-appearance: textfield;
    width: 4.1em;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const CenterTextStyle = css`
    text-align: center;
`;

export type InputChangeCriteria = 'blur' | 'key' | 'debounced';

interface INumberInput extends IAutoTestable, IExtendableComponent {
    /**
     * The value
     */
    value?: number;
    /**
     * The min value
     */
    min?: number;
    /**
     * The max value
     */
    max?: number;

    /**
     * The number of decimals
     */
    decimals?: number;

    /**
     * Show a label above the input
     */
    label?: string;

    /**
     * Print a unit after the input
     */
    unit?: string;

    /**
     * Determines when a change should be triggered
     */
    changeCriteria?: InputChangeCriteria;
    /**
     * Determines whether empty values should be allowed
     */
    optional?: boolean;
    /**
     * disabled
     */
    disabled?: boolean;
    /**
     * Removes the border
     */
    noBorder?: boolean;
    /**
     * Makes component transparent
     */
    transparent?: boolean;
    /**
     * Hide the spinner
     */
    hideSpinner?: boolean;
    /**
     * Center input text.
     */
    centerText?: boolean;
    /**
     * Step size
     */
    step?: number;
    /**
     * Background color
     */
    color?: Colors;
    /**
     * Remove the set element height
     */
    noHeight?: boolean;
    /**
     * Override the element width
     */
    width?: string;
    /**
     * Align the input value to the right instead of to the left
     */
    rightAlign?: boolean;
    /**
     * Override the default border radius
     */
    borderRadius?: string;

    /**
     * Triggered when a change was made to the input by the user
     *
     * @param value The new value displayed in the input
     * @param revertCallback Can be used to revert the displayed value back to the value it was given in props
     */
    onChange(value: number | undefined, revertCallback: () => void): void;
}

const UP_KEY = 'ArrowUp';
const UP_KEY_IE = 'Up';
const DOWN_KEY = 'ArrowDown';
const DOWN_KEY_IE = 'Down';
const ESC_KEY = 'Escape';
const ENTER_KEY = 'Enter';

/**
 * A number input featuring advanced Aron hackery
 */
export const NumberInput: React.FunctionComponent<INumberInput> = ({
    value,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    decimals,
    label,
    unit,
    changeCriteria = 'blur',
    optional,
    disabled,
    noBorder,
    transparent,
    hideSpinner,
    centerText,
    step,
    color,
    noHeight,
    width,
    rightAlign,
    borderRadius,
    onChange,
    testId,
    ...extendedProps
}) => {
    const calculateNewValue = (newValue: number | undefined): number | undefined =>
        newValue === undefined ? undefined : clamp(newValue, min, max);

    const formatValue = (newValue: number | undefined, numberOfDecimals = 0): string => {
        if (newValue === undefined) {
            return '';
        }

        return newValue.toFixed(numberOfDecimals);
    };

    const triggerChange = (newValue: number | undefined, oldValue: number | undefined) => {
        setWaitingForBlur(false);

        // Only trigger change event if the value has actually changed
        if (!equals(newValue, oldValue)) {
            onChange(newValue !== undefined ? round(newValue, decimals) : newValue, () =>
                setValue(oldValue, true),
            );
        }
    };

    const debouncedTriggerChange = React.useRef(
        debounce((newValue: number | undefined, oldValue: number | undefined) => {
            triggerChange(newValue, oldValue);
        }, 300),
    );

    React.useEffect(() => {
        const currentTriggerChange = debouncedTriggerChange.current;

        return () => {
            currentTriggerChange.cancel();
        };
    }, []);

    const inputRef = React.createRef<HTMLInputElement>();

    const onBlur = () => {
        onTextBlur();
    };

    const onChangedBlurCriteria = (inputValue: number | undefined) => {
        const newValue = calculateNewValue(inputValue);
        setValue(newValue, true);

        if (changeCriteria === 'debounced') {
            debouncedTriggerChange.current.flush();
        }

        if (changeCriteria === 'blur' || waitingForBlur) {
            triggerChange(newValue, value);
        }
    };

    const onTextBlur = () => {
        if (formattedInternalValue === '' && !optional) {
            // empty field -> revert to old value
            revertStateValue();
        } else {
            onChangedBlurCriteria(internalValue);
        }
    };

    React.useEffect(() => {
        const roundedInternalValue = round(internalValue || 0, decimals);
        if (roundedInternalValue !== value) {
            const newValue = calculateNewValue(value);
            setValue(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const [internalValue, setInternalValue] = React.useState<number | undefined>(
        calculateNewValue(value === undefined && !optional ? min || 0 : value),
    );
    const [formattedInternalValue, setFormattedInternalValue] = React.useState<string>(
        formatValue(value, decimals),
    );

    const [waitingForBlur, setWaitingForBlur] = React.useState<boolean>(false);

    const classes = classNames(TextInputStyle, NumberInputStyle, {
        [NoBorderStyle]: noBorder,
        [TransparentStyle]: transparent,
        [HideSpinnerStyle]: hideSpinner,
        [CenterTextStyle]: centerText,
        [InheritHeightStyle]: noHeight,
    });

    const stepToSendAsProps =
        step !== undefined ? step : decimals !== undefined ? 1 / Math.pow(10, decimals) : 1;

    const noop = () => {};

    const round = (inputValue: number, numberOfDecimals = 0): number => {
        const decimalFactor = Math.pow(10, numberOfDecimals);
        return Math.round(inputValue * decimalFactor) / decimalFactor;
    };

    const equals = (a: number | undefined, b: number | undefined) => {
        if (a === undefined || b === undefined) {
            return a === b;
        } else {
            return Math.abs(a - b) < Number.EPSILON;
        }
    };

    const parseValue = (inputValue: string): number | undefined => {
        if (inputValue === '') {
            return undefined;
        }

        const parsed = Number(inputValue.replace(',', '.'));

        return Number.isNaN(parsed) ? 0 : parsed;
    };

    const revertStateValue = (callback?: () => void) => {
        setValue(value, true, callback);
    };

    const setValue = (inputValue?: number, format = true, callback?: () => void) => {
        if (format) {
            setInternalValue(inputValue);
            setFormattedInternalValue(formatValue(inputValue, decimals));
            if (callback) {
                callback();
            }
        } else {
            setInternalValue(inputValue);
            if (callback) {
                callback();
            }
        }
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case ESC_KEY:
                // Need to save the target because of synthetic event wrapper see
                // https://fb.me/react-event-pooling
                const currentTarget = e.currentTarget;
                revertStateValue(() => currentTarget.blur());
                break;
            case ENTER_KEY:
                onTextBlur();
                break;
            case UP_KEY:
            case UP_KEY_IE:
            case DOWN_KEY:
            case DOWN_KEY_IE:
                onValueChange(e.currentTarget.value);
                break;
        }
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(e.target.value);
    };

    const onValueChange = (inputValue: string) => {
        setFormattedInternalValue(inputValue);
        const parsedValue = parseValue(inputValue);

        if (changeCriteria === 'key' || changeCriteria === 'debounced') {
            onChangedKeyCriteria(parsedValue);
        } else {
            setValue(parsedValue, false);
        }
    };

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.currentTarget.select();
    };

    const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
        // Focus the element if is not already focused, fixes problems with Firefox
        // where using the spinners not automatically focusing the input.
        if (document.activeElement !== e.currentTarget) {
            e.currentTarget.focus();
        }
    };

    const onChangedKeyCriteria = (inputValue: number | undefined) => {
        const newValue = calculateNewValue(inputValue);
        const isValid = inputValue !== undefined || optional;

        const valueIsInRange = isValid && equals(inputValue, newValue);

        if (valueIsInRange) {
            setValue(newValue, false, () => {
                if (changeCriteria === 'debounced') {
                    debouncedTriggerChange.current(newValue, value);
                } else if (changeCriteria === 'key') {
                    triggerChange(newValue, value);
                }
            });
        } else {
            // else just update value and wait until blur
            setWaitingForBlur(true);
            setValue(inputValue, false);
        }
    };

    return (
        <Box direction="column" {...extendedProps}>
            {label && <Label opaque={!disabled}>{label}</Label>}
            <Stack alignItems="center" spacing="half">
                <input
                    data-test-id={toTestIdFormat(testId)}
                    ref={inputRef}
                    type="number"
                    className={classes}
                    value={formattedInternalValue}
                    onChange={onInputChange}
                    onClick={onClick}
                    onBlur={onBlur}
                    onKeyUp={onKeyUp}
                    onFocus={onFocus}
                    onWheel={noop}
                    min={min}
                    max={max}
                    disabled={disabled}
                    step={stepToSendAsProps}
                    style={{
                        backgroundColor: color ? ColorsEnum[color] : undefined,
                        width: width,
                        borderRadius: borderRadius,
                        textAlign: rightAlign ? 'right' : undefined,
                    }}
                    formNoValidate
                />
                {unit && <Text faded={disabled}>{unit}</Text>}
            </Stack>
        </Box>
    );
};

NumberInput.displayName = 'NumberInput';
