import * as React from 'react';
import { t } from 'app/translate';
import {
    Badge,
    Border,
    Box,
    Clickable,
    Positioned,
    ProductCard,
    ProductCardItem,
} from 'app/components';
import type { ILens } from '../models';
import { PiaItemMsrp } from 'app/modules/common';

interface ILensProps {
    lens: ILens;
    disabled: boolean;
    readOnly: boolean;
    productNamesNotToBeFollowedBy?: string;
    onSelect(lens: ILens): void;
}

export const Lens: React.FC<ILensProps> = ({
    lens,
    disabled,
    onSelect,
    readOnly,
    productNamesNotToBeFollowedBy,
}) => (
    <Clickable
        noInteraction={lens.isIncluded}
        disabled={disabled}
        readOnly={readOnly}
        onClick={() => (!lens.isIncluded ? onSelect(lens) : null)}
    >
        <ProductCard
            bottomContent={
                !lens.isIncluded ? (
                    <PiaItemMsrp
                        piaId={lens.productId}
                        includeMultipacks
                        renderFn={(msrp) => (
                            <Positioned position="absolute" bottom={0} left={0} right={0}>
                                <Border topWidth={1} color={'grey3'}>
                                    <Box
                                        color={'grey1'}
                                        alignItems="center"
                                        justifyContent="center"
                                        paddingY="quart"
                                    >
                                        {msrp}
                                    </Box>
                                </Border>
                            </Positioned>
                        )}
                    />
                ) : undefined
            }
            selected={!!lens.id || lens.isIncluded}
        >
            <ProductCardItem
                name={lens.name}
                icon={'lens'}
                testId={`lens_${lens.name}`}
                productId={lens.productId}
                discontinued={lens.discontinued}
                warningText={
                    productNamesNotToBeFollowedBy
                        ? t.canNotBeUsedWithProductName(productNamesNotToBeFollowedBy)
                        : undefined
                }
            />
            {lens.isIncluded && (
                <Badge outline label={t.applicationSelectorIncluded} type="yellow" />
            )}
        </ProductCard>
    </Clickable>
);

Lens.displayName = 'Lens';
