import * as React from 'react';
import { AppConstants } from 'app/AppConstants';
import { Box, Button, LoadingIndicator, Modal, Stack, TextInput } from 'app/components';
import { StorageUsed } from '../mapMenu/contextMenus/storageUsed';
import { ColorsEnum } from 'app/styles';
import { t } from 'app/translate';
import { NameGenerationService } from 'app/core/persistence';
import type { Id, IFloorPlanEntity } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { MapsActionService } from '../../services';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { getMapNames } from '../../selectors';

interface ICopyFloorPlanModalProps {
    projectId?: Id;
    floorPlan?: IFloorPlanEntity;
    previewImage?: string;
    uploading: boolean;
    onClose(): void;
}

export const CopyFloorPlanModal: React.FC<ICopyFloorPlanModalProps> = ({
    projectId,
    floorPlan,
    previewImage,
    uploading,
    onClose,
}) => {
    const floorPlanNames = useSelector<IStoreState, string[]>(getMapNames);
    const [copyFloorPlanName, setCopyFloorPlanName] = React.useState('');
    const [mapsActionService] = React.useState<MapsActionService>(
        ServiceLocator.get(MapsActionService),
    );
    const [nameGenerationService] = React.useState<NameGenerationService>(
        ServiceLocator.get(NameGenerationService),
    );
    const copyPlaceHolderName = floorPlan
        ? nameGenerationService.getName(
              floorPlan.name,
              floorPlanNames,
              AppConstants.mapNameMaxLength,
          )
        : '';

    /** Copies floor plan including blockers. */
    const copyFloorPlan = async () => {
        const name = copyFloorPlanName === '' ? copyPlaceHolderName : copyFloorPlanName;
        if (floorPlan && projectId) {
            mapsActionService.toggleUploading(true);
            mapsActionService.copyFloorPlan(projectId, floorPlan, name);
        }
    };

    return !projectId || !floorPlan ? null : (
        <Modal title={t.copyFloorPlan} onClose={onClose}>
            {uploading ? (
                <Box width="400px" alignItems="center" justifyContent="center">
                    <LoadingIndicator message={t.uploadingFloorPlan} />
                </Box>
            ) : (
                <Stack vertical>
                    <Stack justifyContent="center">
                        <img
                            style={{
                                width: '400px',
                                maxHeight: '500px',
                                border: `1px solid ${ColorsEnum.grey4}`,
                                borderRadius: '4px',
                                imageOrientation: floorPlan.image?.ignoreEXIF ? 'none' : 'unset',
                            }}
                            src={previewImage}
                        />
                    </Stack>
                    <TextInput
                        label={t.name}
                        value={copyFloorPlanName}
                        placeholder={copyPlaceHolderName}
                        autoFocus
                        changeCriteria="key"
                        onEnter={copyFloorPlan}
                        onChange={setCopyFloorPlanName}
                        maxLength={AppConstants.mapNameMaxLength}
                    />
                    <StorageUsed />
                    <Box flex="shrinkAndGrow" justifyContent="end">
                        <Stack spacing="quart">
                            <Button text onClick={onClose}>
                                {t.cancel}
                            </Button>
                            <Button onClick={copyFloorPlan} primary testId="copy_floor_plan_modal">
                                {t.copyFloorPlan}
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            )}
        </Modal>
    );
};
