import { injectable } from 'inversify';
import PouchDB from 'pouchdb-browser';
import { PersistenceDatabaseRepository } from '../userDataPersistence/repositories/persistence/PersistenceDatabase.repository';
import { StorageMigrationService } from './storageMigration';
import {
    EVENT_UPDATE_AVAILABLE,
    MigrationService,
    MigrationUnsupportedVersionError,
    ProjectDbOrigin,
} from '../userDataPersistence';
import type { IEntity, IIdRev } from '../userDataPersistence';
import { ProjectModelService } from './ProjectModel.service';
import { eventTracking } from 'app/core/tracking';
import { EventEmitter } from 'events';
import type { IPersistenceConfigurationService } from './IPersistenceConfigurationService';

@injectable()
export class LocalPersistenceConfigurationService implements IPersistenceConfigurationService {
    private emitter = new EventEmitter();
    constructor(
        private storageMigrationService: StorageMigrationService,
        private persistenceRepository: PersistenceDatabaseRepository,
        private projectModelService: ProjectModelService,
        private migrationService: MigrationService,
    ) {}

    public getDbOrigin(): ProjectDbOrigin {
        return ProjectDbOrigin.asdLocalUserData;
    }

    public async initialize() {
        await this.storageMigrationService.triggerMigrationIfRequired();
        await this.initDatabase();

        try {
            await this.migrationService.migrateAll(this.persistenceRepository);
        } catch (e) {
            if (e instanceof MigrationUnsupportedVersionError) {
                this.emitUpdateAppEvent();
            } else {
                throw e;
            }
        }

        const deletedDocsCount = await this.persistenceRepository.localDbIndexCleanup();
        if (deletedDocsCount > 0) {
            eventTracking.logApplicationEvent(
                'Persistence',
                'Local db cleanup',
                'Deleted indexes',
                deletedDocsCount,
            );
        }

        this.persistenceRepository.emitter.on('change', (update: IEntity) => {
            this.projectModelService.handleEntityUpdate(update);
        });

        this.persistenceRepository.emitter.on('delete', (deleted: IIdRev) => {
            this.projectModelService.handleEntityDelete(deleted);
        });
    }

    public async initDatabase() {
        const localDb = new PouchDB(ProjectDbOrigin.asdLocalUserData, {
            auto_compaction: true,
        });
        await this.persistenceRepository.initialize(localDb);
        return localDb;
    }

    public async syncAll() {
        // No-op
    }

    private emitUpdateAppEvent() {
        this.emitter.emit(EVENT_UPDATE_AVAILABLE);
    }
}
