import { createCachedSelector } from 're-reselect';
import { isDefined } from 'axis-webtools-util';
import type { IItemRelationEntity, IPersistence, IItemEntity } from 'app/core/persistence';
import { isVirtualProduct } from 'app/core/persistence';
import { getCurrentProjectRelationsForItem } from '../../relations';
import { getCurrentProjectItems } from '../../project';
import { createDeepEqualSelector } from '../../selectors';
import { toCacheKey } from '../../cacheKey';
import { creationDateReverseComparator } from 'app/utils';
import { getPiaCameraForProductId, getPiaItemsRecord } from '../../piaDevices';
import type { IPiaDevice, PiaCategory } from 'app/core/pia';
import { PiaRelationTypes } from 'app/core/pia';

export const getDeviceChildren = createCachedSelector(
    [getCurrentProjectRelationsForItem, getCurrentProjectItems],
    (itemRelations, currentProjectItems) => {
        return getChildrenForDevice(itemRelations, currentProjectItems).sort(
            creationDateReverseComparator,
        );
    },
)(toCacheKey);

/** Gets virtual products for item */
export const getDeviceVirtualChildren = createCachedSelector(
    [getCurrentProjectRelationsForItem, getCurrentProjectItems],
    (itemRelations, currentProjectItems) =>
        itemRelations.reduce((virtualProducts, relation) => {
            if (relation.relationType === 'virtualProduct') {
                const item = currentProjectItems[relation.childId];
                if (!item) return virtualProducts;
                return [...virtualProducts, item];
            }
            return virtualProducts;
        }, [] as IPersistence<IItemEntity>[]),
)(toCacheKey);

/**
 * Get virtual related items from the relations of the piaItem
 */
export const getVirtualRelatedItems = createCachedSelector(
    [getPiaCameraForProductId, getPiaItemsRecord],
    (piaDevice, piaItems) => {
        if (!piaDevice) return [];

        return piaDevice.relations.reduce((items: any, virtualItem: any) => {
            if (virtualItem.relationType !== PiaRelationTypes.VirtuallyIncludes) return items;
            const virtualProduct = piaItems[virtualItem.id];
            if (!virtualProduct) return items;
            return [...items, virtualProduct];
        }, [] as IPiaDevice[]);
    },
)(toCacheKey);

export interface IItemWithVirtualCategory extends IItemEntity {
    virtualProductCategory: PiaCategory[] | undefined;
}

export const getVirtualChildrenWithCategory = createCachedSelector(
    [getDeviceVirtualChildren, getPiaItemsRecord],
    (virtualItems, piaItemsRecord): IPersistence<IItemWithVirtualCategory>[] =>
        virtualItems.map((virtualItem) => {
            const piaItem = virtualItem.productId
                ? piaItemsRecord[virtualItem.productId]
                : undefined;
            const category = isVirtualProduct(piaItem)
                ? piaItem.properties.virtualProductCategory
                : undefined;
            return { ...virtualItem, virtualProductCategory: category };
        }),
)(toCacheKey);

export const getDeviceChildrenIds = createCachedSelector([getDeviceChildren], (deviceChildren) => {
    return deviceChildren.map(({ _id }) => _id);
})({
    selectorCreator: createDeepEqualSelector,
    keySelector: toCacheKey,
});

export function getChildrenForDevice(
    itemRelations: IItemRelationEntity[],
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
) {
    if (!itemRelations) {
        return [];
    }

    return itemRelations
        .filter(
            ({ relationType }) =>
                relationType === 'analogCamera' ||
                relationType === 'sensorUnit' ||
                relationType === 'door' ||
                relationType === 'iorelays',
        )
        .map(({ childId }) => currentProjectItems[childId])
        .filter(isDefined);
}
