import * as L from 'leaflet';
import { getOffset, mul, offset } from 'axis-webtools-util';
import type { IBounds, IFloorPlanEntity, IGeoLocation } from 'app/core/persistence';
import type { ILocation } from 'app/modules/common';
import { utils } from 'app/modules/common';

/**
 * Calculates the bounds for a geo location. Take the rotation into account.
 * @param {IGeoLocation} geoLocation - The geo location to calculate bounds for.
 * @returns {L.LatLngBounds} - The calculated bounds for the geo location.
 */
export const calculateBounds = ({ position, width, height, angle }: IGeoLocation) => {
    const boundingHeight = Math.abs(height * Math.cos(angle)) + Math.abs(width * Math.sin(angle));
    const boundingWidth = Math.abs(width * Math.cos(angle)) + Math.abs(height * Math.sin(angle));

    const topLeft = offset(position)([-boundingWidth / 2, boundingHeight / 2]);
    const bottomRight = offset(position)([boundingWidth / 2, -boundingHeight / 2]);
    const corner1 = L.latLng(topLeft.lat, topLeft.lng);
    const corner2 = L.latLng(bottomRight.lat, bottomRight.lng);
    return L.latLngBounds(corner1, corner2);
};
/**
 * Calculates the bounds for a position. Uses default values for rotation, width and height.
 */
export const calculateBoundsFromLocation = (position: ILocation) => {
    return calculateBounds({
        position,
        height: 160,
        width: 160,
        angle: 0,
    });
};

/**
 * Calculates the location (center) of the bounds.
 * @param bounds
 * @returns
 */
export const calculateLocationFromBounds = (bounds: IBounds) => {
    return offset(bounds.topLeft)(mul(getOffset(bounds.topLeft)(bounds.bottomRight), 0.5));
};

/**
 * Calculates the bounds for a new position.
 * @param bounds the old bounds
 * @param position the new position
 * @returns the new bounds
 */
export const calculateNewBoundsForPosition = (bounds: IBounds, position: ILocation) => {
    const delta = getOffset(bounds.topLeft)(bounds.bottomRight);

    const newBounds: IBounds = {
        // calculate the new top left corner by offsetting the new location by half the negative delta
        topLeft: offset(position)(mul(delta, -0.5)),
        // calculate the new bottom right corner by offsetting the new location by half the delta
        bottomRight: offset(position)(mul(delta, 0.5)),
    };
    return newBounds;
};

/**
 * Calculates the bounds for a floor plan entity.
 *
 * @param {IFloorPlanEntity} floorPlan - The floor plan entity to calculate bounds for.
 * @returns {L.LatLngBounds} - The calculated bounds for the floor plan.
 * @throws {Error} If the floor plan does not have a location.
 */
export const calculateFloorPlanBounds = (floorPlan: IFloorPlanEntity) => {
    const image = floorPlan.image;

    if (image) {
        if (image.geoLocation) {
            return calculateBounds(image.geoLocation);
        } else if (image.bounds) {
            return L.latLngBounds(image.bounds.topLeft, image.bounds.bottomRight);
        } else {
            const bounds = utils.getFloorPlanImageTemporaryBounds(image);
            return L.latLngBounds(bounds.topLeft, bounds.bottomRight);
        }
    } else if (floorPlan.location) {
        return L.latLngBounds(floorPlan.location, floorPlan.location);
    } else {
        throw Error(`The floor plan doesn't have a location`);
    }
};

/** Converts LatLngBounds to IBound */
export const toBounds = (bounds: L.LatLngBounds) => {
    return {
        topLeft: {
            lat: bounds.getNorth(),
            lng: bounds.getWest(),
        },
        bottomRight: {
            lat: bounds.getSouth(),
            lng: bounds.getEast(),
        },
    };
};

/** Converts IBound to LatLngBounds */
export const toLatLngBounds = (bounds: IBounds) => {
    return L.latLngBounds(
        [bounds.topLeft.lat, bounds.topLeft.lng],
        [bounds.bottomRight.lat, bounds.bottomRight.lng],
    );
};
