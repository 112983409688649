import { Module } from 'app/ioc';
import {
    DeviceSelectorActionService,
    WearablesRecommendations,
    WearablesService,
    QuickAddDeviceService,
} from './services';
import { deviceSelectorReducer } from './state';

@Module({
    binder: (binder) => {
        binder.bindService(DeviceSelectorActionService);
        binder.bindService(WearablesRecommendations);
        binder.bindService(WearablesService);
        binder.bindService(QuickAddDeviceService);
    },
    reducers: {
        deviceSelector: deviceSelectorReducer,
    },
})
export class DeviceGroupModule {}
