import * as React from 'react';
import type { Id, IAudioSettingsModel, BandwidthVersion } from 'app/core/persistence';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IProfileSupport } from 'app/modules/common';
import {
    AudioSettingsComponent,
    getCurrentProjectBandwidthVersion,
    getProfileOverride,
    getMergedProfile,
    getDeviceProfileSupport,
    ProfileOverrideService,
} from 'app/modules/common';

import { ServiceLocator } from 'app/ioc';

interface IDeviceAudioSettingsOwnProps {
    deactivate?: boolean;
    deviceId: Id;
}

export interface IDeviceAudioSettingsProps {
    deviceId: Id;
    deactivate?: boolean;
    mergedAudioSettings: IAudioSettingsModel | undefined;
    audioSettingsOverride: Partial<IAudioSettingsModel>;
    projectBandwidthVersion: BandwidthVersion;
    profileSupport?: IProfileSupport;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceAudioSettingsOwnProps,
): IDeviceAudioSettingsProps => {
    const profileOverride = getProfileOverride(state, ownProps.deviceId);

    const mergedProfile = getMergedProfile(state, ownProps.deviceId);

    return {
        deviceId: ownProps.deviceId,
        mergedAudioSettings: mergedProfile?.audio ?? undefined,
        audioSettingsOverride: profileOverride?.audio ?? {},
        profileSupport: getDeviceProfileSupport(state, ownProps.deviceId),
        deactivate: ownProps.deactivate,
        projectBandwidthVersion: getCurrentProjectBandwidthVersion(state),
    };
};

class DeviceAudioSettingsContainer extends React.Component<IDeviceAudioSettingsProps> {
    private profileOverrideService: ProfileOverrideService;

    constructor(props: IDeviceAudioSettingsProps) {
        super(props);
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public render() {
        if (!this.props.mergedAudioSettings) {
            return null;
        }
        return (
            <AudioSettingsComponent
                liveViewAudioEnabled={this.props.mergedAudioSettings.liveViewEnabled}
                liveViewAudioOverridden={
                    this.props.audioSettingsOverride.liveViewEnabled !== undefined
                }
                recordingAudioEnabled={this.props.mergedAudioSettings.recordingEnabled}
                recordingAudioOverridden={
                    this.props.audioSettingsOverride.recordingEnabled !== undefined
                }
                onLiveViewAudioChanged={this.onLiveViewAudioChanged}
                onRecordingAudioChanged={this.onRecordingAudioChanged}
                deactivate={this.props.deactivate}
                profileSupport={this.props.profileSupport}
            />
        );
    }

    private onLiveViewAudioChanged = (enabled: boolean) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            audio: {
                liveViewEnabled: enabled,
            },
        });
    };

    private onRecordingAudioChanged = (enabled: boolean) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            audio: {
                recordingEnabled: enabled,
            },
        });
    };
}

export const DeviceAudioSettings = connect(mapStateToProps)(DeviceAudioSettingsContainer);
