import type { IBaseEntity } from 'app/core/persistence';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds `bandwidthVersion` property to project entities
 * Adds `fpsMode` property for zipstream to profile entities
 */
@injectable()
export class Migration26to27 extends MigrationProviderBase {
    public from: number = 26;
    public to: number = 27;

    public provideMigration() {
        return async (entity: any): Promise<IBaseEntity> => {
            if (entity.type === 'project') {
                entity.bandwidthVersion = 1;
                return entity;
            }

            if (entity.type === 'profile') {
                entity.zipstream.fpsMode = 'fixed';
                return entity;
            }

            return entity;
        };
    }
}
