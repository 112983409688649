import { trigonometry } from 'axis-webtools-util';
import type { ISustainabilityFilter, UnitSystem } from 'app/core/persistence';
import {
    defaultSustainabilityFilter,
    SensorUnitType,
    LightConditions,
    getDefaultCameraFilterEntity,
} from 'app/core/persistence';
import type { IPiaItem, IPiaSensorDevice } from 'app/core/pia';
import { PiaItemSensorUnitCategory } from 'app/core/pia';
import type { IOptionalNumberFilter, IOptionalSelectorFilter } from '../cameras';
import type { IDesiredCamera } from 'app/modules/common';
import { calculate, desiredCameraUtils } from 'app/modules/common';
import { hasMinimumVideoResolution, isPixelDensityFulfilled, hasApplicableLens } from '../../utils';
import {
    defaultMaxOperationalTemperature,
    defaultMinOperationalTemperature,
} from '../../constants';

export interface ISensorUnitFilter extends ISustainabilityFilter {
    desiredSensorUnit: IDesiredCamera;
    IRLEDs: boolean;
    minVideoResolution: IOptionalSelectorFilter;
    operationalTemperature: IOptionalNumberFilter;
    remoteFocus: boolean;
    remoteZoom: boolean;
    varifocal: boolean;
}

export function getDefaultSensorUnitFilter(
    unit: UnitSystem,
    installationHeight: number,
): ISensorUnitFilter {
    return {
        desiredSensorUnit: desiredCameraUtils.convertPropertiesToDesiredCamera(
            getDefaultCameraFilterEntity(unit, installationHeight),
        ),
        IRLEDs: false,
        minVideoResolution: {
            option: '2160p',
            active: false,
        },
        // Currently the 'worst' values for min and max temp
        // ? Can this be set dynamically?
        operationalTemperature: {
            option: defaultMinOperationalTemperature,
            secondaryOption: defaultMaxOperationalTemperature,
            active: false,
        },
        remoteFocus: false,
        remoteZoom: false,
        varifocal: false,
        ...defaultSustainabilityFilter,
    };
}

export class SensorUnitFilter {
    public static filter(
        sensorUnitFilter: ISensorUnitFilter,
        allSensorUnits: IPiaSensorDevice[],
        searchFilter: string,
        piaLenses: Record<number, IPiaItem>,
    ): IPiaSensorDevice[] {
        const desiredSensorUnitCategories = SensorUnitFilter.getDesiredSensorUnitCategories(
            sensorUnitFilter.desiredSensorUnit.sensorUnitTypes,
        );

        const lowerCaseSearchFilter = searchFilter.toLowerCase();
        const matchingCameras = allSensorUnits.filter((sensorUnit) => {
            if (!sensorUnit.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (sensorUnitFilter.IRLEDs && !sensorUnit.properties.IRLEDs) {
                return false;
            }
            if (sensorUnitFilter.varifocal && !sensorUnit.properties.varifocal) {
                return false;
            }
            if (sensorUnitFilter.pvcFree && !sensorUnit.properties.pvcFree) {
                return false;
            }
            if (
                sensorUnitFilter.desiredSensorUnit.lightConditions.indexOf(
                    LightConditions.BACKLIGHT,
                ) >= 0 &&
                !sensorUnit.properties.WideDynamicRange
            ) {
                return false;
            }
            if (
                sensorUnitFilter.desiredSensorUnit.corridorFormat &&
                !sensorUnit.properties.corridorFormat
            ) {
                return false;
            }
            /**
             ** Filter out sensor units that are missing temperature values or
             ** have higher min temp than filter requirement or
             ** have lower max temp than filter requirement.
             */
            if (
                sensorUnitFilter.operationalTemperature.active &&
                (sensorUnit.properties.operationalTemperatureC?.min === undefined ||
                    sensorUnitFilter.operationalTemperature.option <
                        sensorUnit.properties.operationalTemperatureC.min ||
                    sensorUnit.properties.operationalTemperatureC.max === undefined ||
                    sensorUnitFilter.operationalTemperature.secondaryOption >
                        sensorUnit.properties.operationalTemperatureC.max)
            ) {
                return false;
            }
            if (sensorUnitFilter.remoteFocus && !sensorUnit.properties.remoteFocus) {
                return false;
            }
            if (sensorUnitFilter.remoteZoom && !sensorUnit.properties.remoteZoom) {
                return false;
            }
            if (
                !SensorUnitFilter.hasDesiredSensorUnitType(desiredSensorUnitCategories, sensorUnit)
            ) {
                return false;
            }
            if (sensorUnitFilter.desiredSensorUnit.outdoor && !sensorUnit.properties.outdoorReady) {
                return false;
            }
            if (
                sensorUnitFilter.minVideoResolution.active &&
                !hasMinimumVideoResolution(sensorUnit, sensorUnitFilter.minVideoResolution.option)
            ) {
                return false;
            }

            const maxHorizontalFovRadians = trigonometry.toRadians(
                sensorUnit.properties.maxHorizontalFOV,
            );

            if (
                sensorUnitFilter.desiredSensorUnit.isSceneFilterActive &&
                sensorUnitFilter.desiredSensorUnit.horizontalFOVRadians > maxHorizontalFovRadians &&
                !hasApplicableLens(
                    sensorUnit.relations,
                    piaLenses,
                    sensorUnitFilter.desiredSensorUnit,
                    sensorUnit.properties.maxVideoResolutionHorizontal,
                )
            ) {
                return false;
            }

            const trueDistance = calculate.trueDistance(
                sensorUnitFilter.desiredSensorUnit.installationHeight,
                sensorUnitFilter.desiredSensorUnit.distanceToTarget,
                sensorUnitFilter.desiredSensorUnit.targetHeight,
            );
            if (
                sensorUnitFilter.desiredSensorUnit.isSceneFilterActive &&
                !isPixelDensityFulfilled(
                    sensorUnit.properties,
                    sensorUnitFilter.desiredSensorUnit,
                    trueDistance,
                ) &&
                !hasApplicableLens(
                    sensorUnit.relations,
                    piaLenses,
                    sensorUnitFilter.desiredSensorUnit,
                    sensorUnit.properties.maxVideoResolutionHorizontal,
                )
            ) {
                return false;
            }

            return true;
        });

        return matchingCameras;
    }

    private static hasDesiredSensorUnitType(
        desiredSensorUnitCategories: string[],
        sensorUnit: IPiaSensorDevice,
    ) {
        return (
            desiredSensorUnitCategories.length === 0 ||
            sensorUnit.categories.some((category) => desiredSensorUnitCategories.includes(category))
        );
    }

    private static getDesiredSensorUnitCategories(desiredSensorUnitTypes: SensorUnitType[] = []) {
        const desiredCategories: string[] = [];

        if (desiredSensorUnitTypes.includes(SensorUnitType.SensorUnit)) {
            desiredCategories.push(PiaItemSensorUnitCategory.SENSORUNIT);
        }
        if (desiredSensorUnitTypes.includes(SensorUnitType.Thermal)) {
            desiredCategories.push(PiaItemSensorUnitCategory.THERMALSENSOR);
        }
        return desiredCategories;
    }
}
