import { IPv4, IPv4CidrRange, IPv4Mask, IPv4Prefix, Validator } from 'ip-num';
import type { IProjectNetworkSettings } from 'app/core/persistence';

export type IProjectNetworkFormErrors = {
    ipStart: boolean;
    ipEnd: boolean;
    subnetMask: boolean;
    defaultRouter: boolean;
};

export const hasAnyError = (errors: IProjectNetworkFormErrors): boolean => {
    for (const [, value] of Object.entries(errors)) {
        if (value) {
            return true;
        }
    }
    return false;
};

export const validateProjectNetworkSettings = ({
    ipStart,
    ipEnd,
    subnetMask,
    defaultRouter,
    dhcp,
}: IProjectNetworkSettings) => {
    const errors: IProjectNetworkFormErrors = {
        ipStart: false,
        ipEnd: false,
        subnetMask: false,
        defaultRouter: false,
    };

    if (dhcp) {
        return errors;
    }

    // Required strings
    errors.ipStart = !Validator.isValidIPv4String(ipStart)[0];
    errors.ipEnd = !Validator.isValidIPv4String(ipEnd)[0];
    errors.subnetMask =
        !Validator.isValidIPv4String(subnetMask)[0] || !Validator.isValidIPv4Mask(subnetMask)[0];
    errors.defaultRouter = !Validator.isValidIPv4String(defaultRouter)[0];

    // Validate IP range
    if (ipStart && subnetMask && !hasAnyError(errors)) {
        try {
            const ipv4Start = IPv4.fromDecimalDottedString(ipStart);
            const ipv4End = IPv4.fromDecimalDottedString(ipEnd);

            const prefix = BigInt(IPv4Mask.fromDecimalDottedString(subnetMask).prefix);
            const ipv4CidrRangeStart = new IPv4CidrRange(ipv4Start, new IPv4Prefix(prefix));
            if (ipEnd) {
                const ipv4CidrRangeEnd = new IPv4CidrRange(ipv4End, new IPv4Prefix(prefix));
                if (!ipv4CidrRangeStart.inside(ipv4CidrRangeEnd)) {
                    errors.ipEnd = true;
                }
                // End of IP range cannot come before start of IP range
                if (!ipv4Start.isLessThanOrEquals(ipv4End)) {
                    errors.ipStart = errors.ipEnd = true;
                }
            }
            if (defaultRouter) {
                const ipv4CidrRangeDefaultRouter = new IPv4CidrRange(
                    IPv4.fromDecimalDottedString(defaultRouter),
                    new IPv4Prefix(prefix),
                );
                if (!ipv4CidrRangeStart.inside(ipv4CidrRangeDefaultRouter)) {
                    errors.defaultRouter = true;
                }
            }
        } catch (e) {
            // Ignore error when parsing IP address strings
        }
    }
    return errors;
};
