import * as React from 'react';
import { GoogleMapsService } from './services';
import { isDefined, getZoomLevel } from 'axis-webtools-util';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import type { IExtendableComponentWithChildren } from 'app/components';
import { extendableProps } from 'app/components';
import i18next from 'i18next';
import type { LanguageCode } from 'app/utils';
import type { IBounds } from 'app/core/persistence';

interface IGoogleStaticMapProps extends IExtendableComponentWithChildren {
    lat?: number;
    lng?: number;
    location?: string;
    width?: number;
    height?: number;
    zoom?: number;
    bounds?: IBounds;
    containerWidth?: string;
}

export const GoogleStaticMap: React.FunctionComponent<IGoogleStaticMapProps> = ({
    lat,
    lng,
    location,
    children,
    width = 990,
    height = 320,
    zoom,
    bounds,
    containerWidth = '100%',
    ...extendedProps
}) => {
    const language = i18next.language as LanguageCode;
    const shouldShowMap = location || (isDefined(lat) && isDefined(lng));

    // If zoom level is provided, use it
    let zoomLevel = zoom;

    // If bounds are provided, calculate the zoom level from the bounds
    if (!zoomLevel && bounds) {
        zoomLevel = getZoomLevel(bounds.topLeft, bounds.bottomRight, width, height);
    }

    // If location is provided and is 'United States' or 'Canada', set zoom level to 2
    if (!zoomLevel && location && ['united states', 'canada'].includes(location.toLowerCase())) {
        zoomLevel = 2;
    }

    // If zoom level is not provided, default to 15
    if (!zoomLevel) {
        zoomLevel = 15;
    }

    const googleMapImageUrl =
        shouldShowMap &&
        GoogleMapsService.getMapImageUrl(
            zoomLevel,
            Math.round(width / 2),
            Math.round(height / 2),
            location,
            language,
            lat,
            lng,
        );

    const className = css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${containerWidth};
        max-height: 100%;
        height: ${height}px;
        border: 1px solid ${ColorsEnum.grey3};
        border-radius: 4px;
        background-color: ${ColorsEnum.grey2};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${googleMapImageUrl});
        box-sizing: border-box;
    `;

    return <div {...extendableProps(extendedProps, { className }, true)}>{children}</div>;
};

GoogleStaticMap.displayName = 'GoogleStaticMap';
