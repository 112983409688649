import { createSelector } from 'reselect';
import { getPartnerProducts } from './getPartnerProductsCommon';
import { getPartnerProductType } from './getPartnerProductType';

/** Gets all partner products of selected product partner type in redux state. */
export const getSelectedProductTypePartnerProducts = createSelector(
    [getPartnerProducts, getPartnerProductType],
    (partnerProducts, selectedProductType) =>
        partnerProducts.filter((product) =>
            selectedProductType
                ? product.properties.partnerProductType.includes(selectedProductType)
                : true,
        ),
);
