import type { IBodyWornCameraProfile } from '../userDataPersistence/entities/item/IBodyWornCameraPropertiesEntity';

export const DEFAULT_RETENTION_TIME_IN_DAYS = 30;

export function getBodyWornCameraDefaultProfile(): IBodyWornCameraProfile {
    return {
        sceneId: 'outdoor',
        resolution: '1920x1080',
        retentionTimeInDays: DEFAULT_RETENTION_TIME_IN_DAYS,
        scheduleId: '',
        activeRecordingInPercent: 25,
    };
}
