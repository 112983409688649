import { ProjectService, type RecordingSolutionType } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { ModalService } from 'app/modal';
import { RecordingSelectorActionService, RecordingSelectorService } from '../../services';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { DEFAULT_GENETEC_TYPE } from '../../types';

export const resetRecordingSolution = async (
    lastSelectedSolutionId: RecordingSolutionType | undefined,
) => {
    const modalService = ServiceLocator.get(ModalService);
    const recordingService = ServiceLocator.get(RecordingSelectorService);
    const recordingActionService = ServiceLocator.get(RecordingSelectorActionService);
    const projectService = ServiceLocator.get(ProjectService);

    const shouldGoAhead = await modalService.createConfirmDialog({
        header: t.resetRecordingSolutionHeading,
        body: t.resetRecordingSolutionMessage,
        confirmButtonText: t.reset,
        cancelButtonText: t.cancel,
    })();

    if (shouldGoAhead) {
        recordingActionService.setViewingAxisAcsType('CameraStationPro');
        await recordingService.clearCurrentSolution(lastSelectedSolutionId);
        await projectService.updateCurrentProject({
            selectedCameraStationType: 'CameraStationPro',
            selectedCameraStationCenterType: 'NoCenter',
            selectedRecordingVendor: undefined,
            recordingSolutionType: undefined,
            genetecProjectId: null,
            genetecRecommendationType: DEFAULT_GENETEC_TYPE,
        });
        eventTracking.logUserEvent('System Components', 'Reset recording solution');
    }
};
