import type { Id } from '..';
import type { UnitSystem, PowerCalcMethod } from 'app/core/persistence';
import type { IProjectOriginEntity } from './IProjectOriginEntity';
import type { ProjectState } from './ProjectState';
import type { IProjectShareToken } from './IProjectShareToken';
import type { BandwidthVersion, TemperatureScale } from 'app/core/persistence/models';
import type { IProjectNetworkSettings } from './IProjectNetworkSettings';
import type { IBounds } from '../../../models/maps/IBounds';

export type RecordingSolutionType =
    | 'AxisS1X'
    | 'AxisS2X'
    | 'AxisS30'
    | 'AxisS1XPro'
    | 'AxisS2XPro'
    | 'AxisS30Pro'
    | 'AxisCompanionS30'
    | 'AxisCompanionSDCard'
    | 'Milestone'
    | 'Genetec';

export type SelectedVendorType = 'Axis' | 'Genetec' | 'Milestone';

export type CameraStationType = 'CameraStation5' | 'CameraStationPro';

export type CameraStationCenterType = 'NoCenter' | 'Center1year' | 'Center5years';

export enum ProjectZipType {
    off = 0,
    low = 1,
    medium = 2,
    high = 3,
    max = 4,
}

export enum ProjectDbOrigin {
    asdUserData = 'asd-user-data',
    asdLocalUserData = 'asd-local-user-data',
}

export interface IProject {
    archived: boolean;
    defaultProfile: Id;
    installationPiaLocationId: number;
    location?: { lat: number; lng: number };
    bounds?: IBounds;
    locationName?: string;
    name: string;
    notes: string;
    origin?: IProjectOriginEntity;
    powerCalcMethod: PowerCalcMethod;
    shareToken?: IProjectShareToken;
    state: ProjectState;
    unitSystem: UnitSystem;
    temperatureScale: TemperatureScale;
    customInstallationHeight: number;
    networkSettings?: IProjectNetworkSettings;
    bandwidthVersion: BandwidthVersion;
    projectZipSetting: ProjectZipType;
    recordingRetentionTimeInDays: number;
    recordingSolutionType?: RecordingSolutionType;
    projectDbOrigin?: ProjectDbOrigin;
    lastExportedDate: string;
    installationReportNotes?: string;
    genetecProjectId?: string | null;
    genetecRecommendationType?: number;
    selectedRecordingVendor?: SelectedVendorType;
    selectedCameraStationType?: CameraStationType;
    selectedCameraStationCenterType?: CameraStationCenterType;
    powerIlluminatorTimeInHours?: number;
}
