import * as React from 'react';
import { css } from '@emotion/css';
import { Box } from '../../containers';
import { Heading } from '../../text';
import { Icon } from '../icon';
import { Stack } from '../../layout';
import { ColorsEnum } from 'app/styles';
import type { IAutoTestable } from 'app/components/ui-test';
import { toTestIdFormat } from 'app/components/ui-test';

const SectionHeaderStyle = css`
    position: relative;
    background: ${ColorsEnum.grey2};
    min-height: 60px;
    margin-inline-end: 60px;

    &:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 60px;
        height: 60px;
        bottom: 0;
        inset-inline-end: 0;
        background-image: linear-gradient(
            to right,
            ${ColorsEnum.grey2},
            ${ColorsEnum.grey2} 40px,
            ${ColorsEnum.white} 41px,
            ${ColorsEnum.white} 44px,
            ${ColorsEnum.yellow} 45px
        );
        transform-origin: bottom left;
        transform: skew(-45deg, 0);

        [dir='rtl'] & {
            background-image: linear-gradient(
                to left,
                ${ColorsEnum.grey2},
                ${ColorsEnum.grey2} 40px,
                ${ColorsEnum.white} 41px,
                ${ColorsEnum.white} 44px,
                ${ColorsEnum.yellow} 45px
            );
            transform: skew(45deg, 0);
        }
    }
`;
export interface ISectionHeaderProps extends IAutoTestable {
    text: string;
    breadCrumb?: string;
}

export class SectionHeader extends React.Component<ISectionHeaderProps> {
    public render() {
        const { breadCrumb, text } = this.props;
        return (
            <div className={SectionHeaderStyle} data-test-id={toTestIdFormat(this.props.testId)}>
                <Box alignItems="center" padding="base">
                    {breadCrumb && this.renderBreadCrumb(breadCrumb)}
                    <Heading
                        testId={this.props.testId ? `${this.props.testId}_${text}` : undefined}
                        style="subheader"
                    >
                        {text}
                    </Heading>
                </Box>
            </div>
        );
    }

    private renderBreadCrumb = (breadCrumb: string) => {
        return (
            <Stack spacing="halfQuart">
                <Heading style="subheader" color="grey6">
                    {breadCrumb}
                </Heading>
                <Icon icon="keyboard_arrow_right" color="grey6" />
            </Stack>
        );
    };
}
