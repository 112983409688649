import { Box, Text } from 'app/components';
import type { IPiaAccessory, PiaId } from 'app/core/pia';
import { ResolutionPreview } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getLensesForProductItem, getPixelDensityInMeters } from '../../selectors';

interface ICompareResolutionProps {
    piaId: PiaId;
    pixelDensity: number[];
    getPixelDensityString: (density: number) => string;
    index: number;
}

export const CompareResolution: React.FC<ICompareResolutionProps> = ({
    piaId,
    pixelDensity,
    getPixelDensityString,
    index,
}) => {
    const accessoryLens = useSelector<IStoreState, IPiaAccessory | undefined>(
        (state) => getLensesForProductItem(state, piaId)?.[0],
    );
    const pixelDensityPerMeter = useSelector<IStoreState, number>((state) =>
        getPixelDensityInMeters(state, piaId, accessoryLens?.id),
    );

    return (
        <Box justifyContent="center">
            <Box
                maxWidth="130px"
                minWidth="90px"
                minHeight="90px"
                direction="column"
                alignItems="center"
            >
                <ResolutionPreview
                    pixelDensityPerMeter={pixelDensityPerMeter}
                    hidePixelPerUnit
                    productId={piaId}
                />
                <Text>{getPixelDensityString(pixelDensity[index])}</Text>
            </Box>
        </Box>
    );
};

CompareResolution.displayName = 'CompareResolution';
