import { injectable } from 'inversify';
import type {
    IEntity,
    IPersistence,
    IProfileEntity,
    IItemEntity,
    IItemRelationEntity,
    IScheduleEntity,
    ITimeSerieEntity,
    ICustomItemEntity,
    IProjectQuotationEntity,
    IFloorPlanEntity,
    IBlockerEntity,
    IProjectEntity,
    IIdRev,
    IInstallationPointEntity,
    IUserSettingsEntity,
    Id,
    IUserCustomItemEntity,
    IFreeTextPointEntity,
    IPartnerItemEntity,
    IMapLocationEntity,
} from '../userDataPersistence';
import { ProjectRepository, ReplicationService } from '../userDataPersistence';
import type { ICurrentProjectRepository } from '../models/ICurrentProjectRepository';
import type { IAction } from 'app/store';
import { AppStore } from 'app/store';
import { PersistenceActions } from '../PersistenceActions';
import { toRecord } from './serviceUtils';

@injectable()
export class ProjectModelService {
    constructor(
        private projectRepository: ProjectRepository,
        private appStore: AppStore,
        private replicationService: ReplicationService,
    ) {}

    private async getProjectAndSync(projectId: Id) {
        try {
            this.replicationService.cancelReplication();
            const result = await this.projectRepository.getProjectAndAllDescendants(projectId);
            this.replicationService.syncProjectAndUserSettings(projectId, { replicate: false });

            return result;
        } catch (e) {
            await this.replicationService.syncProjectAndUserSettings(projectId, {
                replicate: true,
            });
            return this.projectRepository.getProjectAndAllDescendants(projectId);
        }
    }

    public async getProjectModels(projectId: Id): Promise<ICurrentProjectRepository> {
        const { project, descendants } = await this.getProjectAndSync(projectId);

        const {
            items,
            customItems,
            profiles,
            schedules,
            timeSeries,
            quotations,
            itemRelations,
            mapLocations,
            floorPlans,
            installationPoints,
            freeTextPoints,
            partnerItems,
            blockers,
        } = this.mapDescendants(descendants);

        return {
            project,
            items: toRecord(items, '_id'),
            profiles: toRecord(profiles, '_id'),
            customItems: toRecord(customItems, '_id'),
            schedules: toRecord(schedules, '_id'),
            timeSeries: toRecord(timeSeries, '_id'),
            quotations: quotations[0],
            floorPlans: toRecord(floorPlans, '_id'),
            mapLocations: toRecord(mapLocations, '_id'),
            itemRelations: toRecord(itemRelations, '_id'),
            installationPoints: toRecord(installationPoints, '_id'),
            freeTextPoints: toRecord(freeTextPoints, '_id'),
            partnerItems: toRecord(partnerItems, '_id'),
            blockers: toRecord(blockers, '_id'),
        };
    }

    private mapDescendants(descendants: IEntity[]) {
        const profiles: Array<IPersistence<IProfileEntity>> = [];
        const items: Array<IPersistence<IItemEntity>> = [];
        const itemRelations: Array<IPersistence<IItemRelationEntity>> = [];
        const schedules: Array<IPersistence<IScheduleEntity>> = [];
        const timeSeries: Array<IPersistence<ITimeSerieEntity>> = [];
        const customItems: Array<IPersistence<ICustomItemEntity>> = [];
        const quotations: Array<IPersistence<IProjectQuotationEntity>> = [];
        const floorPlans: Array<IPersistence<IFloorPlanEntity>> = [];
        const mapLocations: Array<IPersistence<IMapLocationEntity>> = [];
        const installationPoints: Array<IPersistence<IInstallationPointEntity>> = [];
        const freeTextPoints: Array<IPersistence<IFreeTextPointEntity>> = [];
        const partnerItems: Array<IPersistence<IPartnerItemEntity>> = [];
        const blockers: Array<IPersistence<IBlockerEntity>> = [];

        descendants.forEach((descendant) => {
            switch (descendant.type) {
                case 'item':
                    items.push(descendant as IPersistence<IItemEntity>);
                    break;
                case 'profile':
                    profiles.push(descendant as IPersistence<IProfileEntity>);
                    break;
                case 'itemRelation':
                    itemRelations.push(descendant as IPersistence<IItemRelationEntity>);
                    break;
                case 'schedule':
                    schedules.push(descendant as IPersistence<IScheduleEntity>);
                    break;
                case 'customItem':
                    customItems.push(descendant as IPersistence<ICustomItemEntity>);
                    break;
                case 'partnerItem':
                    partnerItems.push(descendant as IPersistence<IPartnerItemEntity>);
                    break;
                case 'timeSerie':
                    timeSeries.push(descendant as IPersistence<ITimeSerieEntity>);
                    break;
                case 'quotation':
                    quotations.push(descendant as IPersistence<IProjectQuotationEntity>);
                    break;
                case 'floorPlan':
                    floorPlans.push(descendant as IPersistence<IFloorPlanEntity>);
                    break;
                case 'mapLocation':
                    mapLocations.push(descendant as IPersistence<IMapLocationEntity>);
                    break;
                case 'installationPoint':
                    installationPoints.push(descendant as IPersistence<IInstallationPointEntity>);
                    break;
                case 'freeTextPoint':
                    freeTextPoints.push(descendant as IPersistence<IFreeTextPointEntity>);
                    break;
                case 'blocker':
                    blockers.push(descendant as IPersistence<IBlockerEntity>);
                    break;
                default:
                    throw Error('unknown descendant ' + descendant.type);
            }
        });

        return {
            profiles,
            items,
            itemRelations,
            schedules,
            timeSeries,
            customItems,
            partnerItems,
            quotations,
            floorPlans,
            mapLocations,
            installationPoints,
            freeTextPoints,
            blockers,
        };
    }

    public handleEntityUpdate(entity: IEntity) {
        const state = this.appStore.Store.getState();

        /**
         * Since userSettings are shared across all projects
         * we need to check for entities of type userSettings
         * before disregarding entities outside of loaded project.
         */
        if (entity.type === 'userSettings') {
            const currentHid = state.common.user.user?.hid;
            const userSettingsEntity = entity as IPersistence<IUserSettingsEntity>;
            if (currentHid !== userSettingsEntity.hid) {
                // The user entity that was updated did not belong the the current user
                return;
            }

            const userSettingsAction: IAction<IPersistence<IUserSettingsEntity>> = {
                type: PersistenceActions.UpdateUserSettings,
                payload: userSettingsEntity,
            };

            return this.appStore.Store.dispatch(userSettingsAction);
        }

        // User items should be shared across all projects
        if (entity.type === 'userCustomItem') {
            const userCustomItemAction: IAction<IPersistence<IUserCustomItemEntity>> = {
                type: PersistenceActions.UpdateUserCustomItem,
                payload: entity as IPersistence<IUserCustomItemEntity>,
            };
            return this.appStore.Store.dispatch(userCustomItemAction);
        }

        // Ignore entities not in loaded project
        if (!state.currentProject.project || state.currentProject.project._id !== entity.path[0]) {
            // we want to update userProjects also if you are not in currentProject (i.e in project list)
            if (entity.type === 'project') {
                // also update userProjects
                const projectUpdatedAction: IAction<IPersistence<IProjectEntity>> = {
                    type: PersistenceActions.PROJECT_UPDATED,
                    payload: entity as IPersistence<IProjectEntity>,
                };

                return this.appStore.Store.dispatch(projectUpdatedAction);
            }
            return;
        }

        switch (entity.type) {
            case 'project':
                const projectAction: IAction<IPersistence<IProjectEntity>> = {
                    type: PersistenceActions.UpdateProject,
                    payload: entity as IPersistence<IProjectEntity>,
                };

                this.appStore.Store.dispatch(projectAction);
                // also update userProjects if a change is made for a project entity
                const projectUpdatedAction: IAction<IPersistence<IProjectEntity>> = {
                    type: PersistenceActions.PROJECT_UPDATED,
                    payload: entity as IPersistence<IProjectEntity>,
                };

                this.appStore.Store.dispatch(projectUpdatedAction);

                break;
            case 'item':
                const itemAction: IAction<IPersistence<IItemEntity>> = {
                    type: PersistenceActions.UpdateItem,
                    payload: entity as IPersistence<IItemEntity>,
                };

                this.appStore.Store.dispatch(itemAction);
                break;
            case 'profile':
                const profileAction: IAction<IPersistence<IProfileEntity>> = {
                    type: PersistenceActions.UpdateProfile,
                    payload: entity as IPersistence<IProfileEntity>,
                };

                this.appStore.Store.dispatch(profileAction);
                break;
            case 'itemRelation':
                const itemRelationAction: IAction<IPersistence<IItemRelationEntity>> = {
                    type: PersistenceActions.UpdateItemRelation,
                    payload: entity as IPersistence<IItemRelationEntity>,
                };

                this.appStore.Store.dispatch(itemRelationAction);
                break;
            case 'schedule':
                const scheduleAction: IAction<IPersistence<IScheduleEntity>> = {
                    type: PersistenceActions.UpdateSchedule,
                    payload: entity as IPersistence<IScheduleEntity>,
                };

                this.appStore.Store.dispatch(scheduleAction);
                break;
            case 'customItem':
                const customItemAction: IAction<IPersistence<ICustomItemEntity>> = {
                    type: PersistenceActions.UpdateCustomItem,
                    payload: entity as IPersistence<ICustomItemEntity>,
                };

                this.appStore.Store.dispatch(customItemAction);
                break;
            case 'timeSerie':
                const timeSerieAction: IAction<IPersistence<ITimeSerieEntity>> = {
                    type: PersistenceActions.UpdateTimeSerie,
                    payload: entity as IPersistence<ITimeSerieEntity>,
                };

                this.appStore.Store.dispatch(timeSerieAction);
                break;
            case 'quotation':
                const quotationAction: IAction<IPersistence<IProjectQuotationEntity>> = {
                    type: PersistenceActions.UpdateQuotation,
                    payload: entity as IPersistence<IProjectQuotationEntity>,
                };

                this.appStore.Store.dispatch(quotationAction);
                break;
            case 'floorPlan':
                const floorPlanAction: IAction<IPersistence<IFloorPlanEntity>> = {
                    type: PersistenceActions.UpdateFloorPlan,
                    payload: entity as IPersistence<IFloorPlanEntity>,
                };

                this.appStore.Store.dispatch(floorPlanAction);
                break;
            case 'mapLocation':
                const mapLocationAction: IAction<IPersistence<IMapLocationEntity>> = {
                    type: PersistenceActions.UpdateMapLocation,
                    payload: entity as IPersistence<IMapLocationEntity>,
                };

                this.appStore.Store.dispatch(mapLocationAction);
                break;
            case 'installationPoint':
                const installationPoint: IAction<IPersistence<IInstallationPointEntity>> = {
                    type: PersistenceActions.UpdateInstallationPoint,
                    payload: entity as IPersistence<IInstallationPointEntity>,
                };

                this.appStore.Store.dispatch(installationPoint);
                break;
            case 'freeTextPoint':
                const freeTextPoint: IAction<IPersistence<IFreeTextPointEntity>> = {
                    type: PersistenceActions.UpdateFreeTextPoint,
                    payload: entity as IPersistence<IFreeTextPointEntity>,
                };

                this.appStore.Store.dispatch(freeTextPoint);
                break;
            case 'partnerItem':
                const partnerItem: IAction<IPersistence<IPartnerItemEntity>> = {
                    type: PersistenceActions.UpdatePartnerItem,
                    payload: entity as IPersistence<IPartnerItemEntity>,
                };

                this.appStore.Store.dispatch(partnerItem);
                break;
            case 'blocker':
                const blocker: IAction<IPersistence<IBlockerEntity>> = {
                    type: PersistenceActions.UpdateBlocker,
                    payload: entity as IPersistence<IBlockerEntity>,
                };

                this.appStore.Store.dispatch(blocker);
                break;
            default:
                throw Error(`unknown type ${entity.type} with id ${entity._id}`);
        }
    }

    public handleEntityDelete(entityIdRev: IIdRev) {
        const type = entityIdRev._id.split(':')[0];

        switch (type) {
            case 'project':
                const projectAction: IAction<string> = {
                    type: PersistenceActions.PROJECT_DELETED,
                    payload: entityIdRev._id,
                };
                this.appStore.Store.dispatch(projectAction);
                break;
            case 'userSettings':
                // If userSettings is deleted do nothing
                break;
            case 'userCustomItem':
                const userCustomItemAction: IAction<string> = {
                    type: PersistenceActions.DeleteUserCustomItem,
                    payload: entityIdRev._id,
                };
                this.appStore.Store.dispatch(userCustomItemAction);
                break;
            case 'item':
                const itemAction: IAction<string> = {
                    type: PersistenceActions.DeleteItem,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(itemAction);
                break;
            case 'profile':
                const profileAction: IAction<string> = {
                    type: PersistenceActions.DeleteProfile,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(profileAction);
                break;
            case 'itemRelation':
                const itemRelationAction: IAction<string> = {
                    type: PersistenceActions.DeleteItemRelation,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(itemRelationAction);
                break;
            case 'schedule':
                const scheduleAction: IAction<string> = {
                    type: PersistenceActions.DeleteSchedule,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(scheduleAction);
                break;
            case 'customItem':
                const customItemAction: IAction<string> = {
                    type: PersistenceActions.DeleteCustomItem,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(customItemAction);
                break;
            case 'timeSerie':
                const timeSerieAction: IAction<string> = {
                    type: PersistenceActions.DeleteTimeSerie,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(timeSerieAction);
                break;
            case 'quotation':
                // If quotation is deleted do nothing
                break;
            case 'floorPlan':
                const floorPlanAction: IAction<string> = {
                    type: PersistenceActions.DeleteFloorPlan,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(floorPlanAction);
                break;
            case 'mapLocation':
                const mapLocationAction: IAction<string> = {
                    type: PersistenceActions.DeleteMapLocation,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(mapLocationAction);
                break;
            case 'installationPoint':
                const installationPointAction: IAction<string> = {
                    type: PersistenceActions.DeleteInstallationPoint,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(installationPointAction);
                break;
            case 'freeTextPoint':
                const freeTextPointAction: IAction<string> = {
                    type: PersistenceActions.DeleteFreeTextPoint,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(freeTextPointAction);
                break;
            case 'partnerItem':
                const partnerItemAction: IAction<string> = {
                    type: PersistenceActions.DeletePartnerItem,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(partnerItemAction);
                break;
            case 'blocker':
                const blockerAction: IAction<string> = {
                    type: PersistenceActions.DeleteBlocker,
                    payload: entityIdRev._id,
                };

                this.appStore.Store.dispatch(blockerAction);
                break;
            default:
                throw Error(`unknown type ${type} for entity with id ${entityIdRev._id}`);
        }
    }
}
