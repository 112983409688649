import * as React from 'react';
import { Modal, Stack, Text, TextInput, Box, Button, Icon } from 'app/components';
import { t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import { eventTracking } from 'app/core/tracking';

export interface ICreateProfileModalProps {
    onCreateProfile(name: string): void;
    onClose(): void;
}

interface ICreateProfileModalState {
    profileName: string;
}

export class CreateProfileModal extends React.Component<
    ICreateProfileModalProps,
    ICreateProfileModalState
> {
    constructor(props: ICreateProfileModalProps) {
        super(props);
        this.state = { profileName: '' };
    }
    public render() {
        return (
            <Modal title={t.deviceDetailsProfileCreateProfile} onClose={this.props.onClose}>
                <Box paddingTop="half">
                    <Stack alignItems="start" spacing="half">
                        <Icon icon="info" color="blue" opaque />
                        <Box direction="column" paddingY="quart">
                            <Stack spacing="half">
                                <Text>{t.deviceDetailsProfileProfileExplanation}</Text>
                            </Stack>
                            <br />
                            <Stack spacing="half" alignItems="center">
                                <Text>{t.deviceDetailsProfileProfileName}</Text>
                                <TextInput
                                    value={this.state.profileName}
                                    placeholder={t.deviceDetailsProfileProfileNamePlaceholder}
                                    autoFocus
                                    changeCriteria="key"
                                    onChange={this.onTextChange}
                                    maxLength={AppConstants.profileNameMaxLength}
                                />
                            </Stack>
                            <Box flex="shrinkAndGrow" justifyContent="end">
                                <Stack spacing="quart">
                                    <Button text onClick={this.props.onClose}>
                                        {t.cancel}
                                    </Button>
                                    <Button
                                        onClick={this.onCreateProfile}
                                        primary
                                        disabled={!this.state.profileName.trim()}
                                    >
                                        {t.create}
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        );
    }

    private onTextChange = (value: string) => {
        this.setState({ profileName: value });
    };

    private onCreateProfile = () => {
        eventTracking.logUserEvent('Project Devices', 'Create Scenario');
        this.props.onCreateProfile(this.state.profileName);
    };
}
