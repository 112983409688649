import * as React from 'react';
import { Text } from '../../text';
import { ColorsEnum } from 'app/styles';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps } from '../../services';
import type { BoxValue, IBoxProps } from '../../containers';
import { Box } from '../../containers';
import type { Icons, IIconProps } from '../../ui/icon';
import { Icon } from '../../ui/icon';
import type { IPiaImageProps } from '../../ui/piaImage';
import { PiaImage } from '../../ui/piaImage';
import type { IAutoTestable } from '../../ui-test';

export interface IDropDownMenuItemProps extends IExtendableComponentWithChildren, IAutoTestable {
    id?: number | string;
    /**
     * Display an icon in the item
     */
    icon?: Icons;
    /**
     * Display an image in the item
     */
    imageSrc?: string;
    /**
     * Display a PiaImage in the item
     */
    piaImageProps?: IPiaImageProps;
    /**
     * Will be greyed out with default cursor
     */
    disabled?: boolean;
    /**
     * Add a checked symbol when true.
     * When false adds space for the checked symbol
     */
    checked?: boolean;
    /**
     * Adds a yellow border to show that this item
     * is selected.
     */
    selected?: boolean;
    /**
     * Display a description beneath the label in a grey color.
     */
    description?: string | React.ReactNode;
    /**
     * Remove all padding. Mostly used when rendering a drop menu trigger.
     */
    onlyContent?: boolean;
    /**
     * Width of the element
     */
    width?: number;
    /**
     * The max width
     */
    maxWidth?: BoxValue;
    /**
     * Props for the icon
     */
    iconProps?: Omit<IIconProps, 'icon'>;
    /**
     * Display as a header or warning with special appearance
     * and without focusability.
     */
    isText?: boolean;
    /**
     * Override display mode of the item.
     * Defaults to "flex"
     */
    display?: IBoxProps['display'];
    /**
     * When set to true this menu item does not close
     * the drop down on keyboard actions.
     */
    shouldKeepDropDownOpen?: boolean;
}

/**
 * A flexible menu item for use in drop downs.
 */
export class DropDownMenuItem extends React.Component<IDropDownMenuItemProps> {
    public render() {
        const {
            id,
            icon,
            piaImageProps,
            imageSrc,
            disabled,
            checked,
            selected,
            description,
            children,
            width,
            maxWidth,
            iconProps,
            isText,
            shouldKeepDropDownOpen,
            ...extendedProps
        } = this.props;
        const attributes = extendableProps(extendedProps, {
            'aria-selected': selected,
            'aria-disabled': disabled,
            ['data-should-not-close-drop-down']: shouldKeepDropDownOpen,
            tabIndex: -1,
            role: isText ? 'presentation' : undefined,
            style: {
                borderColor: ColorsEnum.grey3,
                borderLeftColor: ColorsEnum.yellow,
            },
        });

        return (
            <Box
                flex="none"
                testId={this.props.testId}
                __htmlAttributes={attributes.__htmlAttributes}
                alignItems={this.props.onlyContent ? undefined : 'center'}
                padding={this.props.onlyContent ? 'none' : 'half'}
                key={id}
                width={width}
                maxWidth={maxWidth}
                minHeight={this.props.onlyContent ? undefined : '48px'}
                display={this.props.display || undefined}
            >
                {checked !== undefined && <Icon icon="check" hidden={!checked} {...iconProps} />}
                {icon && (
                    <Box paddingLeft={selected ? 'half' : undefined}>
                        <Icon icon={icon} {...iconProps} />
                    </Box>
                )}
                {piaImageProps && (
                    <Box paddingLeft={selected ? 'half' : undefined}>
                        <PiaImage {...piaImageProps} />
                    </Box>
                )}
                {imageSrc && (
                    <img
                        src={imageSrc}
                        style={{ width: '39px', marginLeft: selected ? '8px' : undefined }}
                    />
                )}
                <Box
                    paddingX={this.props.onlyContent ? 'none' : selected ? 'base' : 'half'}
                    direction="column"
                    maxWidth="100%"
                    width="100%"
                >
                    {React.Children.map(children, (child) => {
                        return typeof child === 'string' || typeof child === 'number' ? (
                            <Text
                                whiteSpace="nowrap"
                                semiBold={isText}
                                color={isText ? 'blue7' : 'grey9'}
                            >
                                {child}
                            </Text>
                        ) : (
                            child
                        );
                    })}
                    {description && typeof description === 'string' && (
                        <Text
                            testId={this.props.testIdChild}
                            whiteSpace="nowrap"
                            color={isText ? 'blue7' : 'grey6'}
                        >
                            {description}
                        </Text>
                    )}
                    {description &&
                        typeof description !== 'string' &&
                        React.Children.map(description, (child) => child)}
                </Box>
            </Box>
        );
    }
}
