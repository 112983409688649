import * as React from 'react';
import { t } from 'app/translate';
import type { Time24, IScenarioSettingsModel, LightCondition } from 'app/core/persistence';
import { Box, Stack, DropDownMenu, Clickable, DropDownMenuItem } from 'app/components';
import { PropertyOverrideText } from '../common';
import { merge } from 'lodash-es';
import { ServiceLocator } from 'app/ioc';
import type { ILightCondition } from '../../models';
import { ProfileOverrideService } from '../../../profile/services';
import { TwentyFourHourSlider } from '../../../twentyFourHourSlider';

export interface ILightSelectorProps {
    lightConditions: ILightCondition[];
    scenarioSettings: IScenarioSettingsModel;
    scenarioSettingsOverride?: Partial<IScenarioSettingsModel>;
    disabled?: boolean;
    onChange(model: Partial<IScenarioSettingsModel>): void;
}

interface ILightSelectorState {
    mergedScenarioSettings: IScenarioSettingsModel;
}

export class LightSelector extends React.Component<ILightSelectorProps, ILightSelectorState> {
    private profileOverrideService: ProfileOverrideService;

    constructor(props: ILightSelectorProps) {
        super(props);
        this.state = {
            mergedScenarioSettings: this.getMergedSettings(),
        };
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public componentDidUpdate(oldProps: ILightSelectorProps) {
        if (
            this.props.scenarioSettings !== oldProps.scenarioSettings ||
            this.props.scenarioSettingsOverride !== oldProps.scenarioSettingsOverride
        ) {
            this.setState({ mergedScenarioSettings: this.getMergedSettings() });
        }
    }

    public render() {
        const { lightStart, lightEnd } = this.state.mergedScenarioSettings;

        return (
            <Box flex="shrinkAndGrow">
                <Stack vertical spacing="half">
                    <Box direction="column">
                        <PropertyOverrideText
                            text={t.profilesScenarioEditorLowGoodLight}
                            propertyValue={
                                this.props.scenarioSettingsOverride &&
                                (this.props.scenarioSettingsOverride.lightStart ||
                                    this.props.scenarioSettingsOverride.lightEnd)
                            }
                        />
                        <TwentyFourHourSlider
                            darkLight={this.state.mergedScenarioSettings.nightLighting}
                            setLight={true}
                            readOnly={!!this.props.disabled}
                            lightStart={lightStart}
                            lightEnd={lightEnd}
                            start={lightStart}
                            end={lightEnd}
                            onChange={this.onSliderMouseUp}
                        />
                    </Box>
                    <PropertyOverrideText
                        propertyValue={
                            this.props.scenarioSettingsOverride &&
                            this.props.scenarioSettingsOverride.nightLighting
                        }
                    />
                    <Box>
                        <DropDownMenu
                            displaySelected
                            arrow
                            useSelectedWidth
                            disabled={this.props.disabled}
                        >
                            <Clickable
                                onClick={() =>
                                    this.onLightConditionClick(this.props.lightConditions[2])
                                }
                            >
                                <DropDownMenuItem
                                    selected={
                                        this.props.lightConditions[
                                            this.state.mergedScenarioSettings.nightLighting - 1
                                        ] === this.props.lightConditions[2]
                                    }
                                    imageSrc={this.props.lightConditions[2].image}
                                >
                                    {this.props.lightConditions[2].name}
                                </DropDownMenuItem>
                            </Clickable>
                            <Clickable
                                onClick={() =>
                                    this.onLightConditionClick(this.props.lightConditions[1])
                                }
                            >
                                <DropDownMenuItem
                                    selected={
                                        this.props.lightConditions[
                                            this.state.mergedScenarioSettings.nightLighting - 1
                                        ] === this.props.lightConditions[1]
                                    }
                                    imageSrc={this.props.lightConditions[1].image}
                                >
                                    {this.props.lightConditions[1].name}
                                </DropDownMenuItem>
                            </Clickable>
                            <Clickable
                                onClick={() =>
                                    this.onLightConditionClick(this.props.lightConditions[0])
                                }
                            >
                                <DropDownMenuItem
                                    selected={
                                        this.props.lightConditions[
                                            this.state.mergedScenarioSettings.nightLighting - 1
                                        ] === this.props.lightConditions[0]
                                    }
                                    imageSrc={this.props.lightConditions[0].image}
                                >
                                    {this.props.lightConditions[0].name}
                                </DropDownMenuItem>
                            </Clickable>
                        </DropDownMenu>
                    </Box>
                </Stack>
            </Box>
        );
    }

    private getMergedSettings(): IScenarioSettingsModel {
        return merge({}, this.props.scenarioSettings, this.props.scenarioSettingsOverride);
    }

    private onSliderMouseUp = (startValue: Time24, endValue: Time24) => {
        const model = this.props.scenarioSettingsOverride
            ? this.props.scenarioSettingsOverride
            : this.props.scenarioSettings;

        const startTimeValue = this.profileOverrideService.getValueToSet<Time24>(
            this.props.scenarioSettings.lightStart,
            startValue,
            this.props.scenarioSettingsOverride,
        );
        const endTimeValue = this.profileOverrideService.getValueToSet<Time24>(
            this.props.scenarioSettings.lightEnd,
            endValue,
            this.props.scenarioSettingsOverride,
        );

        this.props.onChange({
            ...model,
            lightStart: startTimeValue,
            lightEnd: endTimeValue,
        });
    };

    private onLightConditionClick = (condition: ILightCondition) => {
        const model = this.props.scenarioSettingsOverride
            ? this.props.scenarioSettingsOverride
            : this.props.scenarioSettings;

        const lightConditionValue = this.profileOverrideService.getValueToSet<LightCondition>(
            this.props.scenarioSettings.nightLighting,
            condition.value,
            this.props.scenarioSettingsOverride,
        );

        this.props.onChange({
            ...model,
            nightLighting: lightConditionValue,
        });
    };
}
