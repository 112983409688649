import * as React from 'react';
import { t } from 'app/translate';
import { IPAlertCard } from '../../components';
import { useSelector } from 'react-redux';
import {
    getCurrentProjectItemsWithInvalidNetworkSettings,
    getHasInsufficientRange,
    getDevicesOutsideRange,
    getHasAnyDeviceOutsideRanges,
} from '../../selectors';
import { IPRangeService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { getPiaItemsRecord } from '../../../piaDevices';
import { Box } from 'app/components';

type ipErrorType = 'insufficientRange' | 'noIpConfig' | 'outsideRange';

export const IPRangeWarning: React.FC = () => {
    const hasInsufficientRange = useSelector(getHasInsufficientRange);
    const [errorType, setErrorType] = React.useState<ipErrorType | undefined>(undefined);
    const unassignedDevices = useSelector(getCurrentProjectItemsWithInvalidNetworkSettings);
    const anyDeviceOutsideRange = useSelector(getHasAnyDeviceOutsideRanges);
    const deviceOutsideRange = useSelector(getDevicesOutsideRange);
    const piaItemsRecord = useSelector(getPiaItemsRecord);

    const [ipRangeService] = React.useState(ServiceLocator.get(IPRangeService));

    // Sets or removes warnings that are displayed in IPRangeWarning
    React.useEffect(() => {
        if (hasInsufficientRange) {
            setErrorType('insufficientRange');
        } else if (unassignedDevices.length > 0) {
            setErrorType('noIpConfig');
        } else if (anyDeviceOutsideRange) {
            setErrorType('outsideRange');
        } else {
            setErrorType(undefined);
        }
    }, [anyDeviceOutsideRange, hasInsufficientRange, unassignedDevices.length]);

    const getErrorMessage = () => {
        switch (errorType) {
            case 'insufficientRange':
                return t.reservedRangesInsufficient;
            case 'noIpConfig':
                return t.ipConfigurationAlertNonMatchingItems;
            case 'outsideRange':
                return t.ipOutsideRange;
            default:
                return '';
        }
    };

    const fixProblem = () => {
        switch (errorType) {
            case 'noIpConfig':
                return ipRangeService.setIpConfigurationsForItems(
                    unassignedDevices,
                    piaItemsRecord,
                );

            case 'outsideRange':
                return ipRangeService.setIpConfigurationsForItems(
                    [
                        ...deviceOutsideRange.cameras,
                        ...deviceOutsideRange.other,
                        ...deviceOutsideRange.recorders,
                    ],
                    piaItemsRecord,
                );
            case 'insufficientRange':
                return undefined;
            default:
                return undefined;
        }
    };

    if (!errorType) return null;

    return (
        <Box paddingTop="base">
            <IPAlertCard
                message={getErrorMessage()}
                buttonText={t.reassignIp}
                onClick={errorType === 'insufficientRange' ? undefined : fixProblem}
            />
        </Box>
    );
};

IPRangeWarning.displayName = 'IPRangeWarning';
