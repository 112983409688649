import * as React from 'react';
import type { IDropDownMenuItemProps } from './DropDownMenuItem.component';
import { DropDownMenuItem } from './DropDownMenuItem.component';

export interface IDropDownMenuTextProps extends IDropDownMenuItemProps {
    /**
     * The text label of the item
     */
    label: string;
}

/**
 * A drop down menu item which only displays text.
 * Suitable for headers or warnings.
 */
export class DropDownMenuText extends React.PureComponent<IDropDownMenuTextProps> {
    public render() {
        const { label, ...props } = this.props;
        return (
            <DropDownMenuItem isText disabled {...props}>
                {label}
            </DropDownMenuItem>
        );
    }
}
