import type { IWithChildren } from 'app/components/models';
import * as React from 'react';
import { Box } from '../../containers/box/Box.component';

interface IColumnsProps extends IWithChildren {
    /**
     * The widths of the columns in percent. By index, so the first number
     * corresponds to the first cell etc. Otherwise cells will be equal in width.
     */
    widths?: number[];
    /**
     * Will make the edge columns align with their respective edges,
     * so that content in the left most cell is lef aligned and
     * content in the right most cell is right aligned
     *
     */
    justify?: boolean;
}

/**
 * Automatically generate an even column layout based on the number of children
 */
export const Columns: React.FunctionComponent<IColumnsProps> = ({ children, widths, justify }) => {
    const numberOfChildren = React.Children.count(children);

    const align = (index: number) => {
        if (!justify) {
            return 'center';
        }
        if (index === 0) {
            return 'start';
        }
        if (index + 1 === numberOfChildren) {
            return 'end';
        }
        return 'center';
    };

    return (
        <Box width="100%" flex="dontShrink">
            {React.Children.map(children, (child, index) => (
                <Box
                    justifyContent={align(index)}
                    alignItems="center"
                    width={widths ? `${widths[index]}%` : `${100 / numberOfChildren}%`}
                    overflowX="auto"
                    overflowY="hidden"
                >
                    {child}
                </Box>
            ))}
        </Box>
    );
};

Columns.displayName = 'Columns';
