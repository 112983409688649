import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { deviceIcons, getPiaItem } from 'app/modules/common';
import { Stack, PiaImage, Box, Text, Border } from 'app/components';
import type { IPiaItem } from 'app/core/pia';
import { t } from 'app/translate';
import { AddAccessoriesButton } from './AddAccessoriesButton.container';
import { getSelectedDeviceId } from '../../selectors';
import { MOBILE_WINDOW_SIZE } from '../../constants';

interface IDeviceItemProps {
    piaDevice: IPiaItem | null;
}

const mapStateToProps = (storeState: IStoreState): IDeviceItemProps => {
    const selectedDeviceId = getSelectedDeviceId(storeState);
    return { piaDevice: getPiaItem(storeState, selectedDeviceId) };
};

const DeviceItemContainer: React.FunctionComponent<IDeviceItemProps> = ({ piaDevice }) => {
    return (
        <Stack spacing="quart" vertical notFullWidth>
            <Box paddingBottom="half" width="100%" justifyContent="center">
                <Text bold color="grey5">
                    {t.device}
                </Text>
            </Box>
            <Border width={1} color="transparent">
                <Box padding="base">
                    <Stack
                        vertical={window.innerWidth > MOBILE_WINDOW_SIZE}
                        alignItems="center"
                        justifyContent={window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'}
                        flex="fullWidth"
                        notFullWidth
                    >
                        <PiaImage
                            piaId={piaDevice?.id ?? null}
                            icon={deviceIcons.categoryToIcon(piaDevice?.category) || 'category'}
                            iconProps={{
                                color: 'blue',
                                opaque: true,
                            }}
                            imageSize={window.innerWidth > MOBILE_WINDOW_SIZE ? 'xl' : 'lg'}
                        />
                        <Box
                            height="3em"
                            width={window.innerWidth > MOBILE_WINDOW_SIZE ? '135px' : undefined}
                            alignItems="center"
                            justifyContent={
                                window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'
                            }
                        >
                            <Text
                                testId={`mounting_${piaDevice && piaDevice.name}`}
                                style="semibold"
                                align={
                                    window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : undefined
                                }
                            >
                                {piaDevice && piaDevice.name}
                            </Text>
                        </Box>
                    </Stack>
                </Box>
            </Border>
            <Box paddingX="base" justifyContent="center">
                {piaDevice && <AddAccessoriesButton productId={piaDevice.id} />}
            </Box>
        </Stack>
    );
};

export const DeviceItem = connect(mapStateToProps)(DeviceItemContainer);
