import { flatMap } from 'lodash-es';
import { isDefined } from 'axis-webtools-util';
import { PiaItemState } from 'app/core/pia';
import type { IPiaItem } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { getSelectedDeviceId } from './getSelectedDevice';
import { getSelectedMountAccessoriesForItemId } from './getMountAccessories';
import { getPiaIdFromProps, toCacheKey, getPiaItemsRecord, getPiaItem } from 'app/modules/common';

const getSelectedPiaItem = createSelector(
    [getSelectedDeviceId, (state: IStoreState) => state],
    (selectedDeviceId, state) => getPiaItem(state, selectedDeviceId),
);

const getSelectedAccessories = createSelector(
    [getSelectedDeviceId, getPiaItemsRecord, (state: IStoreState) => state],
    (selectedDeviceId, piaItems, state) =>
        Object.values(getSelectedMountAccessoriesForItemId(state, selectedDeviceId)).map(
            ({ productId }) => piaItems[productId],
        ),
);

const getAllAccessoryRequirements = createSelector(
    [getPiaItemsRecord, getSelectedPiaItem],
    (piaItems, selectedPiaItem) => {
        const allAccessoryRequirements = (selectedPiaItem?.relations ?? [])
            // get all compatible relations that have required comopnents
            .filter(({ relationType }) => relationType === 'compatible')
            .filter(({ relationProperties }) => relationProperties?.reqComponents)
            // map to remove any required components that aren't EXTERNALLY_ANNOUNCED
            .map((relation) => ({
                ...relation,
                relationProperties: {
                    ...relation.relationProperties,
                    reqComponents: (
                        relation.relationProperties.reqComponents ?? new Array<number[]>()
                    ).filter((reqComponent) =>
                        reqComponent.every(
                            (piaId) => piaItems[piaId]?.state === PiaItemState.EXTERNALLY_ANNOUNCED,
                        ),
                    ),
                },
            }));

        return allAccessoryRequirements;
    },
);

const getAccessoriesWithRequirements = createSelector(
    [getAllAccessoryRequirements, getPiaItemsRecord, getSelectedAccessories],
    (allAccessoryRequirements, piaItems, selectedAccessoryItems) => {
        const selectedIds = selectedAccessoryItems.map(({ id }) => id);
        const selectedParentIds = selectedAccessoryItems.map(({ parentId }) => parentId);

        // get all requirement items
        const allAccessoryRequirementItems = allAccessoryRequirements.map(({ id }) => piaItems[id]);

        // filter out requirements of accessories that aren't selected
        const selectedAccessoriesWithRequirements = allAccessoryRequirementItems.filter(
            ({ parentId }) => selectedParentIds.includes(parentId),
        );

        // get the requirement relations (contiaining the actual requirements)
        const requirementsOfSelectedAccessories = selectedAccessoriesWithRequirements
            .map((accessory) =>
                allAccessoryRequirements.find((requirement) => requirement.id === accessory.id),
            )
            .filter(isDefined);

        // filter out all requirement relations that are fulfilled, i.e. at least one
        // of its reqComponents is already selected.
        const remainingRequirements = requirementsOfSelectedAccessories.filter(
            ({ relationProperties }) =>
                !(relationProperties?.reqComponents ?? []).some((components) =>
                    components.every((component) => selectedIds.includes(component)),
                ),
        );

        return remainingRequirements;
    },
);

export const hasUnmatchedAccessoryRequirement = createSelector(
    [getAccessoriesWithRequirements],
    (accessoriesWithRequirements) => accessoriesWithRequirements.length > 0,
);

export const getAllRequiredAccessories = createSelector(
    [getAccessoriesWithRequirements, getPiaItemsRecord],
    (accessoriesWithRequirements, piaItems): IPiaItem[][] => {
        // get all requirements
        const requiredAccessories = flatMap(
            accessoriesWithRequirements,
            ({ relationProperties }) => relationProperties.reqComponents,
        ).filter(isDefined);

        // get the PIA items for each requirement
        const requiredAccessoryItems = requiredAccessories
            .map((accessoryIds) => accessoryIds.map((id) => piaItems[id]))
            .filter((accessories) => accessories.every(isDefined));

        return requiredAccessoryItems;
    },
);

export const hasAccessoryRequiredAccessories = createCachedSelector(
    [getAllAccessoryRequirements, getSelectedAccessories, getPiaIdFromProps],
    (accessoryRequirements, selectedAccessoryItems, piaId): boolean => {
        const selectedIds = selectedAccessoryItems.map(({ id }) => id);

        // find the requirements of the currently selected accessory
        const requirements = accessoryRequirements.find(({ id }) => id === piaId);

        // check whether requirements are fulfilled
        const areRequirementsFulfilled = (
            requirements?.relationProperties?.reqComponents ?? []
        ).some((components) => components.every((component) => selectedIds.includes(component)));

        return !areRequirementsFulfilled && requirements !== undefined;
    },
)(toCacheKey);
