import { createCachedSelector } from 're-reselect';
import { isSensorDevice } from 'app/core/persistence';
import type {
    AnalyticMode,
    AnalyticRangeDayCondition,
    AnalyticRangeLightCondition,
} from 'app/core/persistence';
import { getAnalyticRangeForItem } from './getAnalyticRange';
import { getPiaItem } from './getPiaItem';
import { getPiaAccessories } from '../../relations';
import { toCacheKey } from '../../cacheKey/toCacheKey';
import { PiaItemCameraCategory } from 'app/core/pia';

/**
 * Get the selected analytic range light condition for an item id
 * @param itemId the id of the item
 * @returns the selected light condition. Default to 'medium_light' if undefined
 */
export const getAnalyticRangeLightCondition = createCachedSelector(
    [getAnalyticRangeForItem],
    (range): AnalyticRangeLightCondition => range?.lightCondition ?? 'medium_light',
)(toCacheKey);

/**
 * Get the selected analytic range day condition for an item id
 * @param itemId the id of the item
 * @returns the selected day condition. Default to 'day' if undefined
 */
export const getAnalyticRangeDayCondition = createCachedSelector(
    [getAnalyticRangeForItem],
    (range): AnalyticRangeDayCondition => range?.dayCondition ?? 'day',
)(toCacheKey);

/**
 * Get the selected analytic mode for an item id
 * @param itemId the id of the item
 * @returns the selected analytic mode (ai or calibrated) or undefined
 */
export const getAnalyticMode = createCachedSelector(
    [getAnalyticRangeForItem],
    (range): AnalyticMode | undefined => range?.analyticMode,
)(toCacheKey);

/**
 * Check whether the item has a device with either a built-in IR light or an
 * illuminator accessory
 * @param itemId the id of the item
 * @returns true if the item has a device with IR light or accessory
 */
export const getHasIRIllumination = createCachedSelector(
    [getPiaItem, getPiaAccessories],
    (piaItem, accessories): boolean => {
        return (
            (isSensorDevice(piaItem) && piaItem.properties.IRLEDs === true) ||
            accessories.some(({ category }) => category === 'illuminators')
        );
    },
)(toCacheKey);

/**
 * Check whether the item has a device with an illuminator accessory
 *
 * @param itemId the id of the item
 * @returns true if the item has a device with IR accessory
 */
export const getHasExternalIRIllumination = createCachedSelector(
    [getPiaAccessories],
    (accessories): boolean => {
        return accessories.some(({ category }) => category === 'illuminators');
    },
)(toCacheKey);

/**
 * Check whether the item is of type Thermal
 * @param itemId the id of the item
 * @returns true if the item is a thermal camera
 */
export const getIsThermal = createCachedSelector([getPiaItem], (piaItem): boolean => {
    return isSensorDevice(piaItem) && piaItem.categories.includes(PiaItemCameraCategory.THERMAL);
})(toCacheKey);

/**
 * Check whether we have selected an IR-light condition but no IR-illuminator
 * (built-in or assigned as an accessory)
 * @param itemId the id of the item
 * @returns true if an IR illuminator is required
 */
export const getRequiresIRIllumination = createCachedSelector(
    [getAnalyticRangeLightCondition, getHasIRIllumination],
    (selectedLightCondition, hasIRIllumination): boolean => {
        const isIRLightSelected = selectedLightCondition === 'ir';
        return isIRLightSelected && !hasIRIllumination;
    },
)(toCacheKey);

/**
 * Check whether we have selected night but no IR-illuminator
 * (built-in or assigned as an accessory)
 * @param itemId the id of the item
 * @returns true if an IR illuminator is required
 */
export const getRequiresAPDIRIllumination = createCachedSelector(
    [getAnalyticRangeDayCondition, getHasIRIllumination],
    (selectedDayCondition, hasIRIllumination): boolean => {
        const isIRLightSelected = selectedDayCondition === 'night';
        return isIRLightSelected && !hasIRIllumination;
    },
)(toCacheKey);
