import type { IPiaAccessControl } from 'app/core/pia';
import {
    isDoorController,
    isDoorStation,
    isIoRelay,
    defaultSustainabilityFilter,
} from 'app/core/persistence';
import type { ISustainabilityFilter } from 'app/core/persistence';
import { meetsSustainabilityRequirements } from './SustainabilityFilter';

const doorControllerType = 'doorControllers';
const interComType = 'interComs';
const ioRelayType = 'ioRelays';

export type accessControlType =
    | typeof doorControllerType
    | typeof interComType
    | typeof ioRelayType;
export interface IAccessControlFilter extends ISustainabilityFilter {
    selectedCategory?: accessControlType;
}

export const defaultAccessControlFilter: IAccessControlFilter = {
    selectedCategory: undefined,
    ...defaultSustainabilityFilter,
};

export class AccessControlFilter {
    public static filter(
        accessControlFilter: IAccessControlFilter,
        allAccessControls: IPiaAccessControl[],
        searchFilter: string,
    ): IPiaAccessControl[] {
        const lowerCaseSearchFilter = searchFilter.toLowerCase();

        return allAccessControls.filter((accessControl) => {
            // 2N devices have a separate dedicated tab
            if (accessControl.properties.vendor !== 'Axis') {
                return false;
            }
            if (!accessControl.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (
                accessControlFilter.selectedCategory === 'doorControllers' &&
                !isDoorController(accessControl)
            ) {
                return false;
            }
            if (
                accessControlFilter.selectedCategory === 'interComs' &&
                !isDoorStation(accessControl)
            ) {
                return false;
            }
            if (accessControlFilter.selectedCategory === 'ioRelays' && !isIoRelay(accessControl)) {
                return false;
            }
            if (!meetsSustainabilityRequirements(accessControlFilter, accessControl)) {
                return false;
            }

            return true;
        });
    }
}
