import { createSelector } from 'reselect';
import type { IPiaAccessory, IPiaRelationReference, PiaId } from 'app/core/pia';
import { filterProducts, PiaAccessoryCategory, PiaItemState } from 'app/core/pia';
import type { IRelatedPiaItem } from 'app/modules/common';
import {
    toCacheKey,
    getCurrentProjectLocation,
    getCurrentProjectRegions,
    getPiaItemsRecord,
    getPiaItemsWithPrice,
    getPriceListForRegion,
} from 'app/modules/common';

import { isDefined } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import { getCameraProductId } from './getCameraProductId';

const getRelatedPiaItemsForItem = createCachedSelector(
    [getPiaItemsRecord, getCurrentProjectRegions, getCameraProductId],
    (piaItems, regions, productId) => {
        if (!productId) return [];
        const piaItem = piaItems[productId];

        const relations = piaItem.relations
            .filter(
                (relation) =>
                    relation.relationType === 'includes' ||
                    relation.relationType === 'recommends' ||
                    relation.relationType === 'compatible',
            )
            .reduce(
                (uniqRelations, relation) => {
                    uniqRelations[relation.id] = relation;
                    return uniqRelations;
                },
                {} as Record<PiaId, IPiaRelationReference>,
            );
        return Object.values(relations)
            .map(
                (relation) =>
                    ({
                        ...piaItems[relation.id],
                        isIncluded: relation.relationType === 'includes',
                        isRecommended: relation.relationType === 'recommends',
                    }) as IRelatedPiaItem,
            )
            .filter(isDefined)
            .filter(filterProducts.byStates([PiaItemState.EXTERNALLY_ANNOUNCED]))
            .filter(filterProducts.byRegions(regions))
            .filter(filterProducts.byExternallyHidden())
            .filter(filterProducts.byCategory(PiaAccessoryCategory.LENSES))
            .filter((item) => !item.isIncluded);
    },
)(toCacheKey);

/**
 * Get PiaItems with msrp as {@see IPiaItemWithMsrp}
 * @throws {Error} Project location not found.
 * @return {IPiaItemWithMsrp<IPiaAccessory>[]} piaAccessories
 */
export const getPiaItemsWithPriceAsIPiaAccessory = createSelector(
    [getRelatedPiaItemsForItem, getPriceListForRegion, getCurrentProjectLocation],
    (relatedPiaItems, priceListForRegion, currentProjectLocation) => {
        if (!currentProjectLocation) {
            throw Error('Project location not found');
        }
        return getPiaItemsWithPrice<IPiaAccessory>(
            relatedPiaItems,
            priceListForRegion,
            currentProjectLocation,
        );
    },
);
