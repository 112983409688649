import type { Colors } from 'app/styles';

export const doriPixelOpacity = {
    DORI_OPACITY_IDENTIFY: 0.8,
    DORI_OPACITY_RECOGNIZE: 0.6,
    DORI_OPACITY_OBSERVE: 0.4,
    DORI_OPACITY_DETECT: 0.2,
};

export const doriPixelLimitsMeter = {
    DORI_LIMIT_IDENTIFY: 250,
    DORI_LIMIT_RECOGNIZE: 125,
    DORI_LIMIT_OBSERVE: 63,
    DORI_LIMIT_DETECT: 25,
};

export const doriPixelLimitsFeet = {
    DORI_LIMIT_IDENTIFY: 80,
    DORI_LIMIT_RECOGNIZE: 40,
    DORI_LIMIT_OBSERVE: 20,
    DORI_LIMIT_DETECT: 8,
};

export const AXIS_OBJECT_ANALYTICS = 412806;
export const AXIS_PERIMETER_DEFENDER = 48330;
export const RANGE_APPLICATION_IDS = [AXIS_OBJECT_ANALYTICS, AXIS_PERIMETER_DEFENDER];

export const defaultColors = {
    DEFAULT_DEVICE_COLOR: 'devicePalette7' as Colors,
    DEFAULT_SPEAKER_COLOR: 'devicePalette3' as Colors,
    DEFAULT_RADAR_COLOR: 'devicePalette5' as Colors,
    DEFAULT_DOOR_CONTROLLER_COLOR: 'devicePalette8' as Colors,
    DEFAULT_BLOCKER_COLOR: 'blue6' as Colors,
};
