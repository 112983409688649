import * as React from 'react';
import { t } from 'app/translate';
import {
    Stack,
    Icon,
    Text,
    Title,
    NumberInput,
    Clickable,
    ReadDirection,
    PopOver,
} from 'app/components';
import { PropertyOverrideText } from './common';

export interface IStorageBandwidthInfo {
    hasLiveView: boolean;
    formattedBandwidth?: string;
    formattedStorage?: string;
    formattedLiveView?: string;
    cameraCount: number;
    customBandwidth?: number;
    disabledCustomBandwidth: boolean;
    customBandwidthChange?(customBandwidth?: number): void;
}

export const StorageBandwidthInfo: React.FunctionComponent<IStorageBandwidthInfo> = ({
    hasLiveView,
    formattedBandwidth,
    formattedStorage,
    formattedLiveView,
    cameraCount,
    customBandwidth,
    disabledCustomBandwidth,
    customBandwidthChange,
}) => {
    return (
        <Stack>
            <Text color={'grey6'}>{cameraCount} ×</Text>
            {(formattedBandwidth !== undefined || customBandwidth !== undefined) && (
                <Title title={t.recordingBandwidth}>
                    <Stack spacing="quart" alignItems="center">
                        <Icon icon="recording_bw_xs" size="xs" />
                        <PropertyOverrideText
                            testId="scenario_recording_bandwidth_estimate"
                            text={formattedBandwidth}
                            propertyValue={customBandwidth}
                            alignRight
                        />
                        {customBandwidthChange && (
                            <PopOver
                                disabled={disabledCustomBandwidth}
                                trigger={
                                    <Clickable>
                                        <Icon
                                            icon={'edit'}
                                            size={'xs'}
                                            opaque={!!customBandwidth}
                                        />
                                    </Clickable>
                                }
                                content={
                                    <NumberInput
                                        step={0.1}
                                        unit={'Mbit/s'}
                                        min={0}
                                        max={100}
                                        value={customBandwidth}
                                        decimals={2}
                                        onChange={customBandwidthChange}
                                        changeCriteria={'blur'}
                                        transparent
                                        centerText
                                        optional
                                        disabled={disabledCustomBandwidth}
                                    />
                                }
                            />
                        )}
                    </Stack>
                </Title>
            )}
            {hasLiveView && (
                <Title title={t.liveViewBandwidth}>
                    <Stack spacing="quart" alignItems="center">
                        <Icon icon="live_bw_xs" size="xs" />
                        <ReadDirection direction="ltr">
                            <Text testId="scenario_liveview_bandwidth_estimate">
                                {formattedLiveView}
                            </Text>
                        </ReadDirection>
                    </Stack>
                </Title>
            )}
            <Title title={t.storage}>
                <Stack spacing="quart" alignItems="center">
                    <Icon icon="storage_xs" size="xs" />
                    <ReadDirection direction="ltr">
                        <Text testId="scenario_storage_estimate">{formattedStorage}</Text>
                    </ReadDirection>
                </Stack>
            </Title>
        </Stack>
    );
};

StorageBandwidthInfo.displayName = 'StorageBandwidthInfo';
