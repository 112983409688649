export const convertToIpRange = (ipStart: string, numOnRange: number): string => {
    //Account for first number in range
    numOnRange--;

    const ipSplit = ipStart.split('.').map((n) => Number(n));

    const r3 = add(ipSplit[3], numOnRange);
    ipSplit[3] = r3.mod256;

    const r2 = add(ipSplit[2], r3.quotient);
    ipSplit[2] = r2.mod256;

    const r1 = add(ipSplit[1], r2.quotient);
    ipSplit[1] = r1.mod256;

    const r0 = add(ipSplit[0], r1.quotient);
    ipSplit[0] = r0.mod256;

    if (r0.quotient > 0) {
        return '255.255.255.255';
    }

    return ipSplit.join('.');
};

const add = (val: number, inc: number) => {
    const sum = val + inc;
    const mod256 = sum % 256;
    const quotient = Math.floor(sum / 256);
    return {
        mod256,
        quotient,
    };
};
