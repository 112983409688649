import * as React from 'react';
import { ReadDirection, Stack, TableRow, Text } from 'app/components';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import {
    getCurrentProjectItem,
    getDeviceChildren,
    getMergedProfile,
    getModelName,
    getScenarioName,
    getWearableProfile,
    getDeviceBandwidthTotal,
    getDeviceVirtualChildren,
    getDeviceStorageTotal,
} from 'app/modules/common';
import type { Id, IItemEntity, IPersistence, IProfileEntity } from 'app/core/persistence';
import { deviceTypeCheckers, isDeviceSpecified } from 'app/core/persistence';
import type { IBandwidthTotals } from '../../models';
import { t } from 'app/translate';
import { getStorageBandwidthSum } from '../../selectors';
import { getBareboneForId } from 'app/modules/lensSelector/selectors';
import { isEqual } from 'lodash-es';
import { isDefined } from 'axis-webtools-util';

interface IBandwidthTableRowOwnProps {
    itemId: Id;
    isChild?: boolean;
}

interface IBandwidthTableRowProps extends IBandwidthTableRowOwnProps {
    product?: IPersistence<IItemEntity>;
    profile?: IPersistence<IProfileEntity>;
    scenarioName: string;
    modelName: string;
    bandwidthTotal: IBandwidthTotals;
    children: Id[];
    bodyWornRetentionTime: number | undefined;
    /** Merged profiles for item's virtual products */
    virtualProductScenarios: IPersistence<IProfileEntity>[];
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IBandwidthTableRowOwnProps,
): IBandwidthTableRowProps => {
    const profile = getMergedProfile(storeState, ownProps.itemId);

    const bandwidth = getDeviceBandwidthTotal(storeState, ownProps.itemId);
    const storage = getDeviceStorageTotal(storeState, ownProps.itemId);
    const formattedStorage = storage && storage.storage > 0 ? storage.formattedStorage : '';
    const formattedBandwidth =
        bandwidth && bandwidth.bandwidth > 0 ? bandwidth.formattedBandwidth : '';

    const storageBandwidthSumOfChildren = getStorageBandwidthSum(storeState, ownProps.itemId);
    const scenarioName = getScenarioName(storeState, ownProps.itemId);
    const children = getDeviceChildren(storeState, ownProps.itemId)
        .filter((device) => isDeviceSpecified(device) || deviceTypeCheckers.isAnalogCamera(device))
        .map((child) => child._id);
    const product = getCurrentProjectItem(storeState, ownProps.itemId);
    const bareboneProduct = product?.replaceWithBareboneId
        ? getBareboneForId(storeState, product._id)
        : undefined;

    const virtualProductsForItem = getDeviceVirtualChildren(storeState, ownProps.itemId);
    const virtualProductScenarios = virtualProductsForItem
        .map((vp) => getMergedProfile(storeState, vp._id))
        .filter(isDefined);

    return {
        ...ownProps,
        product: product,
        profile,
        scenarioName,
        modelName:
            product?.replaceWithBareboneId && bareboneProduct
                ? bareboneProduct.name
                : getModelName(storeState, ownProps.itemId),
        bandwidthTotal: storageBandwidthSumOfChildren ?? {
            formattedStorage,
            formattedBandwidth,
        },
        children,
        bodyWornRetentionTime: getWearableProfile(storeState, ownProps.itemId)?.retentionTimeInDays,
        virtualProductScenarios,
    };
};
const getScenarioString = (
    profile: IPersistence<IProfileEntity> | undefined,
    virtualProductScenarios: IPersistence<IProfileEntity>[],
    scenarioName: string,
    isParent: boolean,
): string => {
    const hasMultipleScenarios = virtualProductScenarios.some(
        (vpScenario) => !isEqual(vpScenario, profile),
    );
    if (hasMultipleScenarios) return t.multiple;
    if (isParent || !profile) return '';
    return `${profile.name} - ${scenarioName}`;
};

const getRetentionTimeString = (
    profile: IPersistence<IProfileEntity> | undefined,
    virtualProductScenarios: IPersistence<IProfileEntity>[],
    bodyWornRetentionTime: number | undefined,
    isParent: boolean,
): string => {
    const hasMultipleRetentionTimes = virtualProductScenarios.some(
        (vp) => vp.storage.retentionTime !== profile?.storage.retentionTime,
    );
    if (hasMultipleRetentionTimes) return t.multiple;
    const retentionTime = profile?.storage.retentionTime ?? bodyWornRetentionTime;
    if (isParent || !retentionTime) return '';
    return `${retentionTime} ${t.daysLowerCase}`;
};

const BandwidthTableRowContainer: React.FunctionComponent<IBandwidthTableRowProps> = ({
    product,
    profile,
    isChild,
    scenarioName,
    modelName,
    bandwidthTotal,
    children,
    bodyWornRetentionTime,
    virtualProductScenarios,
}) => {
    const isParent = !isChild && children.length > 0;

    return (
        <>
            <TableRow
                testId={`tr_${modelName}`}
                indented={isChild}
                cells={[
                    product?.name,
                    isChild ? product?.quantity + ' x ' + modelName : modelName,
                    isChild ? '' : product?.quantity,
                    getScenarioString(profile, virtualProductScenarios, scenarioName, isParent),
                    getRetentionTimeString(
                        profile,
                        virtualProductScenarios,
                        bodyWornRetentionTime,
                        isParent,
                    ),
                    isParent ? (
                        ''
                    ) : (
                        <Stack>
                            <ReadDirection direction="ltr">
                                <Text testId={`tr_${modelName}_td_5`}>
                                    {bandwidthTotal.formattedBandwidth}
                                </Text>
                            </ReadDirection>
                        </Stack>
                    ),
                    isParent ? (
                        ''
                    ) : (
                        <Stack justifyContent="end">
                            <ReadDirection direction="ltr">
                                <Text testId={`tr_${modelName}_td_6`}>
                                    {bandwidthTotal.formattedStorage}
                                </Text>
                            </ReadDirection>
                        </Stack>
                    ),
                ]}
            />
            {isParent &&
                children.map((childId) => (
                    <BandwidthTableRow key={childId} itemId={childId} isChild />
                ))}
        </>
    );
};

export const BandwidthTableRow = connect(mapStateToProps)(BandwidthTableRowContainer);
