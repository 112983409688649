import * as React from 'react';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type {
    IBodyWornCameraProfile,
    Id,
    IPersistence,
    IScheduleEntity,
    IScheduleModel,
} from 'app/core/persistence';
import { ScheduleDays, Time24, ItemService } from 'app/core/persistence';

import {
    DaySelector,
    getSchedule,
    getScheduleModels,
    getSchedules,
    ProfileEditorRow,
    TwentyFourHourSlider,
} from 'app/modules/common';

import { Stack, Heading, Box, Select, Range, Spacer } from 'app/components';
import { constants } from 'app/modules/common/profile/constants';
import { ServiceLocator } from 'app/ioc';
import { debounce } from 'lodash-es';
import { nameComparator } from 'app/utils';

interface IScheduleSelectorWearableProps {
    deviceId: Id;
    profile: IBodyWornCameraProfile;
}

export const ScheduleSelectorWearable: React.FC<IScheduleSelectorWearableProps> = ({
    deviceId,
    profile,
}) => {
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));

    const updateWearableDebounceHook = React.useMemo(
        () =>
            debounce(
                (newValue) =>
                    itemService.updateWearableProfile(deviceId, {
                        activeRecordingInPercent: newValue,
                    }),
                200,
            ),
        [deviceId, itemService],
    );

    const scheduleModels = useSelector<IStoreState, IScheduleModel[]>((state) =>
        getScheduleModels(state),
    );
    const selectedSchedule = scheduleModels.find(
        (scheduleModel: IScheduleModel) => scheduleModel.id === profile.scheduleId,
    );
    const allSchedules = useSelector<IStoreState, IPersistence<IScheduleEntity>[]>((state) =>
        getSchedules(state),
    );
    const activeRecordingInPercent = profile.activeRecordingInPercent;

    const [percentValue, setPercentValue] = React.useState<number>(activeRecordingInPercent ?? 50);

    React.useEffect(() => {
        setPercentValue(profile.activeRecordingInPercent);
    }, [profile.activeRecordingInPercent]);

    const renderTimeSerieSliders = () => {
        if (selectedSchedule && selectedSchedule.timeSeries.length > 0) {
            return (
                <Stack vertical>
                    {selectedSchedule.timeSeries.map((timeSerie, index) =>
                        renderTimeSerieSlider(
                            timeSerie.start,
                            timeSerie.end,
                            timeSerie.days,
                            index,
                        ),
                    )}
                </Stack>
            );
        } else {
            // If no schedule or no time series we render a single slider
            return renderTimeSerieSlider(
                new Time24('00:00'),
                new Time24('00:00'),
                new ScheduleDays(0),
            );
        }
    };

    const renderTimeSerieSlider = (
        start: Time24,
        end: Time24,
        days: ScheduleDays,
        key?: number,
    ) => {
        const scheduleSelector = {
            lightStart: new Time24('00:00'),
            lightEnd: new Time24('24:00'),
            nightLighting: 2,
            schedules: allSchedules,
            selectedSchedule: getSchedule(profile.scheduleId),
        };
        return (
            <Box key={key} flex="shrinkAndGrow" direction="column">
                <TwentyFourHourSlider
                    setLight={false}
                    readOnly={true}
                    lightStart={scheduleSelector.lightStart}
                    lightEnd={scheduleSelector.lightEnd}
                    start={start}
                    end={end}
                />
                <DaySelector days={days} />
            </Box>
        );
    };

    const getScheduleOptions = (schedules: IPersistence<IScheduleEntity>[]) => {
        const additionalOption = scheduleOffOption();
        return schedules
            .sort(nameComparator)
            .map(({ _id, name }) => ({
                value: _id,
                text: name,
            }))
            .concat([additionalOption]);
    };

    const scheduleOffOption = () => {
        return {
            text: t.schedulesSystemDefinedNamesOff,
            value: '',
        };
    };

    const onChangeSchedule = (newScheduleId: Id) => {
        itemService.updateWearableProfile(deviceId, { scheduleId: newScheduleId });
    };

    const onPercentChanged = (newValue: number) => {
        setPercentValue(newValue);
        updateWearableDebounceHook(newValue);
    };

    return (
        <ProfileEditorRow>
            <Stack vertical spacing="half">
                <Heading>{t.installationReportSettingsSchedule}</Heading>
                <Stack flex="shrinkAndGrow">
                    <Stack spacing="quart" alignItems="center">
                        <Select
                            value={profile.scheduleId}
                            options={getScheduleOptions(allSchedules)}
                            onChange={onChangeSchedule}
                        />
                    </Stack>
                </Stack>
                <Stack>
                    <Box width={constants.leftColumnWidth} />
                    <Box flex="shrinkAndGrow">{renderTimeSerieSliders()}</Box>
                </Stack>
                <Spacer></Spacer>
                <Stack>
                    <Box width={constants.leftColumnWidth} />
                    <Box flex="shrinkAndGrow">
                        <Range
                            color={'blue'}
                            max={100}
                            min={0}
                            onChange={(value) => onPercentChanged(value)}
                            value={percentValue}
                            changeCriteria="key"
                            decimals={0}
                            label={t.percentOfTime}
                            step={1}
                            showValue
                            showValueInLabel
                            unit="%"
                        />
                    </Box>
                </Stack>
            </Stack>
        </ProfileEditorRow>
    );
};
