import * as React from 'react';
import type { IAutoTestable, IOptionProps } from 'app/components';
import { Select } from 'app/components';
import type { ZipProfile } from 'app/core/persistence';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';

export interface IZipStrengthProps extends IAutoTestable {
    zipProfile: ZipProfile;
    disabled?: boolean;
    onZipProfileChange(profile: ZipProfile): void;
}

export const ZipProfileSelector: React.FC<IZipStrengthProps> = ({
    zipProfile,
    disabled,
    onZipProfileChange,
}) => {
    const onZipProfileChangeInternal = (profile: ZipProfile) => {
        eventTracking.logUserEvent('Project Scenarios', 'Zip profile', profile);
        onZipProfileChange(profile);
    };

    const zipProfileOptions: IOptionProps[] = [
        {
            text: t.classic,
            value: 'classic',
        },
        {
            text: t.storage,
            value: 'storage',
        },
    ];

    return (
        <Select
            testIdChild="zip_profile_selected"
            value={zipProfile}
            options={zipProfileOptions}
            onChange={onZipProfileChangeInternal}
            disabled={disabled}
        />
    );
};
