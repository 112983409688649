import { reducerBuilder } from 'app/store';
import type { ISchedulesState } from './ISchedulesState';
import { SchedulesActions } from './Schedules.actions';
import type { Id } from 'app/core/persistence';

const initialState: ISchedulesState = {
    selectedScheduleId: undefined,
};

export const schedulesReducer = reducerBuilder<ISchedulesState>()
    .setInitialState(initialState)
    .onAction<Id>(SchedulesActions.EditSchedule, (state, action) => {
        return {
            ...state,
            selectedScheduleId: action.payload,
        };
    })
    .create();
