import * as React from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from 'app/hooks';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../services';
import { useLeafletMapBounds } from '../../hooks';
import { getDesiredBoundsPerMap, getMapViewBoundsFactory } from '../../selectors';
import type { LeafletMap } from './LeafletMap';
import type { IFloorPlanEntity } from 'app/core/persistence';
import { toLatLngBounds } from '../../utils';

interface IFloorPlanMap {
    leafletMap: LeafletMap;
    floorPlan: IFloorPlanEntity;
}

/** Component that renders a floor plan image in leaflet */
export const FloorPlanMap: React.FC<IFloorPlanMap> = ({ leafletMap, floorPlan }) => {
    const mapsActionService = useService(MapsActionService);
    const desiredBounds = useSelector(getDesiredBoundsPerMap)[floorPlan._id];

    const leafletBounds = useLeafletMapBounds(leafletMap);
    const previousLeafletBounds = usePrevious(leafletBounds);

    const previousFloorPlan = usePrevious(floorPlan, floorPlan);
    const floorPlanImageRef = React.useRef(floorPlan.image);
    const mapViewBounds = useSelector(getMapViewBoundsFactory)(floorPlan._id);
    floorPlanImageRef.current = floorPlan.image;

    React.useEffect(() => {
        if (desiredBounds) {
            const bounds = toLatLngBounds(desiredBounds);
            leafletMap.jumpTo(bounds);
        }
    }, [floorPlan._id, desiredBounds, leafletMap, mapsActionService]);

    /** Update view bounds in the state when leaflet bounds change */
    React.useEffect(() => {
        // if the bounds have changed while the floor plan is the same, update the view bounds.
        // This happens when the user pans or zooms the map.
        if (previousFloorPlan?._id === floorPlan._id && leafletBounds !== previousLeafletBounds) {
            mapsActionService.setViewBounds(floorPlan._id, leafletBounds);
        }
    }, [
        mapsActionService,
        leafletBounds,
        previousLeafletBounds,
        floorPlan._id,
        previousFloorPlan?._id,
    ]);

    /** Fit bounds if no map view bounds exists */
    React.useEffect(() => {
        !mapViewBounds && mapsActionService.fitBounds(floorPlan._id);
    }, [mapsActionService, floorPlan._id, mapViewBounds]);

    return null;
};

FloorPlanMap.displayName = 'FloorPlanMap';
