import { getCurrentProjectProfiles } from 'app/modules/common';
import { createSelector } from 'reselect';

export const getIsUsingProjectRetentionTime = createSelector(
    [getCurrentProjectProfiles],
    (profiles) => {
        const isUsingProjectRetentionTime = Object.values(profiles).some(
            (profile) => profile?.storage.useProjectSetting,
        );
        return isUsingProjectRetentionTime;
    },
);
