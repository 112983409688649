import type { Id, ILatLng, IBounds, IFloorPlanEntity } from 'app/core/persistence';
import { isGeoMap } from 'app/modules/common';
import { LatLngBounds, LatLng } from 'leaflet';
import { createSelector } from 'reselect';
import {
    getInstallationPointsPerGeoMap,
    getFreeTextPointsPerGeoMap,
    getBlockersPerGeoMap,
} from './floorPlanMapping';
import { getAllMaps } from './getFloorPlans';
import { getMapsState } from './getMapsState';

const DEFAULT_MAP_BOUNDS_RADIUS = 200;

/**
 * Convert a leaflet bounds to IBounds.
 * @param leafletBounds The leaflet bounds to convert.
 * @returns The bounds as IBounds.
 */
const toBounds = (leafletBounds: LatLngBounds): IBounds => {
    const topLeft = leafletBounds.getNorthWest();
    const bottomRight = leafletBounds.getSouthEast();
    return {
        topLeft: { lat: topLeft.lat, lng: topLeft.lng },
        bottomRight: { lat: bottomRight.lat, lng: bottomRight.lng },
    };
};

/**
 * Get the locations of the installation points per geo map.
 */
const getInstallationPointsLocationsPerGeoMap = createSelector(
    [getInstallationPointsPerGeoMap],
    (ipMapping): Record<Id, ILatLng[]> => {
        return Object.keys(ipMapping).reduce(
            (acc, mapId) => {
                const ips = ipMapping[mapId];
                const ipLocations = ips.map((ip) => ip.location);
                acc[mapId] = ipLocations;
                return acc;
            },
            {} as Record<Id, ILatLng[]>,
        );
    },
);

/**
 * Get the locations of the free text points per geo map.
 */
const getFreeTextPointsLocationsPerGeoMap = createSelector(
    [getFreeTextPointsPerGeoMap],
    (ftpMapping): Record<Id, ILatLng[]> => {
        return Object.keys(ftpMapping).reduce(
            (acc, mapId) => {
                const ftps = ftpMapping[mapId];
                const ftpLocations = ftps.map((ftp) => ftp.location);
                acc[mapId] = ftpLocations;
                return acc;
            },
            {} as Record<Id, ILatLng[]>,
        );
    },
);

/**
 * Get the locations of the blockers per geo map.
 */
const getBlockersLocationsPerGeoMap = createSelector(
    [getBlockersPerGeoMap],
    (blockerMapping): Record<Id, ILatLng[]> => {
        return Object.keys(blockerMapping).reduce(
            (acc, mapId) => {
                const blockers = blockerMapping[mapId];
                const blockerLocations = blockers.map((blocker) => blocker.latLngs[0]);
                acc[mapId] = blockerLocations;
                return acc;
            },
            {} as Record<Id, ILatLng[]>,
        );
    },
);

/**
 * Get the bounds of a map based on its location. The bounds are returned as an array
 * of two corner latLngs.
 * @param map The map to get the bounds for.
 * @returns The bounds of the map as an array of two latLngs.
 */
const getMapBounds = (map: IFloorPlanEntity): ILatLng[] => {
    if (isGeoMap(map) && map.location) {
        const location = new LatLng(map.location.lat, map.location.lng);
        const bounds = location.toBounds(DEFAULT_MAP_BOUNDS_RADIUS);
        return [bounds.getNorthWest(), bounds.getSouthEast()].map((latLng) => ({
            lat: latLng.lat,
            lng: latLng.lng,
        }));
    }
    return [];
};

/**
 * Get all locations relevant for calculating the bounds of the geo maps.
 * This includes the installation points, free text points, blockers and also
 * the map location itself.
 */
const getLocationsPerGeoMap = createSelector(
    [
        getInstallationPointsLocationsPerGeoMap,
        getFreeTextPointsLocationsPerGeoMap,
        getBlockersLocationsPerGeoMap,
        getAllMaps,
    ],
    (ipLocations, ftpLocations, blockerLocations, geoMaps): Record<Id, ILatLng[]> => {
        return Object.keys(geoMaps).reduce(
            (acc, mapId) => {
                const map = geoMaps[mapId];
                if (isGeoMap(map)) {
                    const ips = ipLocations[mapId] ?? [];
                    const ftps = ftpLocations[mapId] ?? [];
                    const blockers = blockerLocations[mapId] ?? [];
                    const mapLocation = getMapBounds(map);

                    // create a new array with the locations that should be included in the bounds
                    acc[mapId] = Array.from(
                        new Set([...ips, ...ftps, ...blockers, ...mapLocation]),
                    );
                }
                return acc;
            },
            {} as Record<Id, ILatLng[]>,
        );
    },
);

/**
 * Get the bounds of each geo map.
 * @returns A record mapping the map id to its bounds.
 */
export const getBoundsPerGeoMap = createSelector(
    [getLocationsPerGeoMap],
    (locationsPerGeoMap): Record<Id, IBounds> => {
        return Object.keys(locationsPerGeoMap).reduce(
            (acc, mapId) => {
                const locations = locationsPerGeoMap[mapId];
                if (locations.length === 0) {
                    return acc;
                }

                const leafletBounds = new LatLngBounds(
                    locations.map((location) => [location.lat, location.lng]),
                )
                    // Add padding to the bounds to make sure all points are visible
                    .pad(0.1);

                acc[mapId] = toBounds(leafletBounds);
                return acc;
            },
            {} as Record<Id, IBounds>,
        );
    },
);

/**
 * Get the bounds desired by the user for each map. This is the bounds that leaflet is currently
 * panning/zooming to. Once the map has finished panning/zooming, the bounds will be
 * reset to undefined.
 */
export const getDesiredBoundsPerMap = createSelector(
    [getMapsState],
    (state): Record<Id, IBounds | undefined> => {
        return state.desiredBounds;
    },
);

export const getDesiredBoundsFactory = createSelector(
    [getDesiredBoundsPerMap],
    (desiredBoundsRecord) =>
        (mapId: Id): IBounds | undefined => {
            return desiredBoundsRecord[mapId];
        },
);
