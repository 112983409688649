import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { deviceTypeCheckers } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';
import { calculateTotalsForDevice, getBandwidthStorageEstimateForItems } from '../../bandwidth';
import { getDeviceVirtualChildren } from '../../devices/selectors/getDeviceChildren';

/**
 * In deviceList and bandwidthReport we want to show bandwidth for cameras, main units and encoders
 * (but in device list and bandwidth report we want to show bandwidth per analog camera
 * and sensor unit instead)
 *  Bodyworn camera has storage but not bandwidth.
 */
export const getDeviceBandwidthTotalSystemProposal = createCachedSelector(
    [getCurrentProjectItem, getDeviceVirtualChildren, getBandwidthStorageEstimateForItems],
    (item, virtualChildren, bandwidthStorageEstimateForItems) => {
        const showBandwidth =
            item &&
            (deviceTypeCheckers.isCamera(item) ||
                deviceTypeCheckers.isMainUnit(item) ||
                deviceTypeCheckers.isEncoder(item));
        if (!showBandwidth) {
            return undefined;
        }
        return calculateTotalsForDevice(item._id, virtualChildren, bandwidthStorageEstimateForItems)
            .formattedBandwidth;
    },
)(toCacheKey);

/**
 * In deviceList and bandwidthReport we want to show storage for cameras, main units and encoders
 * (but in device list and bandwidth report we want to show storage per analog camera
 * and sensor unit instead)
 *  Bodyworn camera has storage but not bandwidth.
 */
export const getDeviceStorageTotalSystemProposal = createCachedSelector(
    [getCurrentProjectItem, getDeviceVirtualChildren, getBandwidthStorageEstimateForItems],
    (item, virtualChildren, bandwidthStorageEstimateForItems) => {
        const showBandwidth =
            item &&
            (deviceTypeCheckers.isCamera(item) ||
                deviceTypeCheckers.isMainUnit(item) ||
                deviceTypeCheckers.isEncoder(item) ||
                deviceTypeCheckers.isBodyWornCamera(item));
        if (!showBandwidth) {
            return undefined;
        }
        return calculateTotalsForDevice(item._id, virtualChildren, bandwidthStorageEstimateForItems)
            .formattedStorage;
    },
)(toCacheKey);
