import { injectable } from 'inversify';
import type { ICameraItemEntity, Id, IPersistence } from 'app/core/persistence';
import type { IInstallationReportCameraDevice } from '../../models/devices';
import type { IPiaCamera } from 'app/core/pia';
import { CategoryEnum } from 'app/modules/common';
import { BaseDeviceService } from './BaseDevice.service';
import { InstallationDetailsService } from '../InstallationDetails.service';

@injectable()
export class CameraDeviceService {
    constructor(
        private baseDeviceService: BaseDeviceService,
        private installationDetailsService: InstallationDetailsService,
    ) {}

    public async mapDevice(
        item: IPersistence<ICameraItemEntity>,
        piaItem: IPiaCamera,
        projectId: Id,
    ): Promise<IInstallationReportCameraDevice> {
        const cameraProps = item.properties.camera;

        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            ...(await this.installationDetailsService.getCameraSettings(
                cameraProps,
                piaItem,
                projectId,
            )),
            ...this.installationDetailsService.getCameraInstallationDetails(cameraProps, piaItem),
            category: CategoryEnum.Camera,
        };
    }
}
