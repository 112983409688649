import { isEqual } from 'lodash-es';
import { useRef } from 'react';

/**
 * Perform a deep equality check and only return a new value if the deep equality
 * check returns false.
 */
export const useOldValueIfUnchanged = <T>(value: T): T => {
    const ref = useRef<T>(value);

    if (!isEqual(ref.current, value)) {
        ref.current = value;
    }

    return ref.current;
};
