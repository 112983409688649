import * as React from 'react';
import { useSelector } from 'react-redux';
import { Border, Box, Button, Modal, PillButton, Text } from 'app/components';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import {
    PlaceSearch,
    cropTextMiddle,
    getCurrentProjectFloorPlan,
    getCurrentProjectMapLocationsArray,
} from 'app/modules/common';
import type { IFloorPlanEntity, Id, IBounds } from 'app/core/persistence';
import { GeoMapSelector } from '../../mapSelector/GeoMapSelector';
import { MapsActionService } from '../../../services';
import { getCurrentMapViewBounds } from '../../../selectors';
import { useEnterKeyDown } from 'app/hooks';
import { useModalLeftMargin } from '../../../hooks/useModalLeftMargin';

const MAX_BUTTON_LOCATIONS = 5;

interface ISetGeoLocationModal {
    mapId: Id;
    onGoToBounds(): void;
    onCancel(): void;
}

export const SetGeoLocationModal: React.FC<ISetGeoLocationModal> = ({
    mapId,
    onCancel,
    onGoToBounds,
}) => {
    const mapsActionService = useService(MapsActionService);

    const [newBounds, setNewBounds] = React.useState<IBounds>();

    const namedLocations = useSelector(getCurrentProjectMapLocationsArray);
    const currentBounds = useSelector(getCurrentMapViewBounds);
    const floorPlanToGeoLocate = useSelector<IStoreState, IFloorPlanEntity | undefined>((store) =>
        getCurrentProjectFloorPlan(store, mapId),
    );
    const modalLeftMargin = useModalLeftMargin();

    const goToBounds = (bounds?: IBounds) => {
        if (!bounds || !floorPlanToGeoLocate) return;
        onGoToBounds();
        mapsActionService.setNewFloorPlanBounds(floorPlanToGeoLocate, bounds);
    };

    useEnterKeyDown(() => goToBounds(newBounds ?? currentBounds));

    return (
        <Modal
            title={t.chooseLocation}
            onClose={onCancel}
            minWidth="460px"
            maxWidth="600px"
            marginLeft={modalLeftMargin}
        >
            <Box direction="column" spacing="panel" paddingTop="half">
                <Box direction="column" spacing="base">
                    {/* Render as buttons */}
                    {namedLocations.length <= MAX_BUTTON_LOCATIONS && (
                        <>
                            <Text style="heading">{t.selectLocation}</Text>
                            <Box spacing="half" flex="evenSpace" wrap>
                                {currentBounds && (
                                    <PillButton
                                        checkable
                                        selected
                                        icon="place"
                                        text={t.currentLocation}
                                        onClick={() => goToBounds(currentBounds)}
                                    />
                                )}
                                {namedLocations.map((namedLocation) => {
                                    return (
                                        <PillButton
                                            key={namedLocation._id}
                                            checkable
                                            textColor="blue"
                                            iconColor="blue"
                                            icon="place"
                                            text={cropTextMiddle(namedLocation.name, 22)}
                                            onClick={() => goToBounds(namedLocation.bounds)}
                                        />
                                    );
                                })}
                            </Box>
                        </>
                    )}
                    {/* Render as dropdown */}
                    {namedLocations.length > MAX_BUTTON_LOCATIONS && (
                        <GeoMapSelector
                            namedLocations={[
                                {
                                    _id: 'currentLocation',
                                    name: t.currentLocation,
                                    bounds: currentBounds!,
                                },
                                ...namedLocations,
                            ]}
                            onClick={(bounds) => goToBounds(bounds)}
                        />
                    )}
                </Box>
                <Box direction="column" spacing="base">
                    {namedLocations.length > 0 && (
                        <Text style="heading">{t.specifyNewLocation}</Text>
                    )}

                    <Border width={1} color="grey3" sideColor={{ bottom: 'blue' }}>
                        <Box
                            display="grid"
                            grid={{ gridTemplateColumns: '1fr max-content' }}
                            minHeight={35}
                        >
                            <PlaceSearch
                                noBorder
                                openInPortal
                                onSelect={(result) => setNewBounds(result.bounds)}
                                onClear={() => setNewBounds(undefined)}
                            />
                            <Button
                                testId="go_to_location_button"
                                primary
                                disabled={!newBounds}
                                onClick={() => goToBounds(newBounds)}
                            >
                                {t.goToLocation}
                            </Button>
                        </Box>
                    </Border>
                    <Box spacing="base" justifyContent="end" paddingTop="base">
                        <Button text onClick={onCancel}>
                            {t.close}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
