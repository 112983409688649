import * as React from 'react';
import { useSelector } from 'react-redux';
import type { ITimeSerieEntity, Id, IPersistence } from 'app/core/persistence';
import { Time24, ScheduleDays, ScheduleService } from 'app/core/persistence';
import { Box, IconButton, Card, Stack } from 'app/components';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { t } from 'app/translate';
import {
    TwentyFourHourSlider,
    EditableDayComponent,
    getCurrentProjectTimeSeries,
} from 'app/modules/common';

interface ITimeSerieProps {
    timeSerieId: Id;
    readOnly: boolean;
    isRemovable: boolean;
}

export const TimeSerie: React.FC<ITimeSerieProps> = ({ timeSerieId, readOnly, isRemovable }) => {
    const [scheduleService] = React.useState(ServiceLocator.get(ScheduleService));
    const timeSerie = useSelector<IStoreState, IPersistence<ITimeSerieEntity> | undefined>(
        (state) => getCurrentProjectTimeSeries(state)[timeSerieId],
    );
    const days = timeSerie ? new ScheduleDays(timeSerie.days) : null;

    const onRemove = () => {
        scheduleService.removeTimeSerie(timeSerieId);
    };

    const onSliderChange = (startValue: Time24, endValue: Time24) => {
        scheduleService.updateTimeSerieTime(timeSerieId, startValue, endValue);
    };

    const onDaysChange = (newDays: ScheduleDays) => {
        scheduleService.updateTimeSerieDays(timeSerieId, newDays);
    };

    return !timeSerie || !days ? null : (
        <Card flex="dontShrink">
            <Stack vertical>
                <Box padding="half" justifyContent="end">
                    <IconButton
                        opaque={false}
                        disabled={!isRemovable || readOnly}
                        icon="close_circle_outline"
                        color="red"
                        onClick={onRemove}
                    />
                </Box>
                <Box direction="column" paddingX="doublePanel" alignItems="center">
                    <TwentyFourHourSlider
                        green
                        setLight={false}
                        lightStart={new Time24(0)}
                        lightEnd={new Time24(24)}
                        start={new Time24(timeSerie.start)}
                        end={new Time24(timeSerie.end)}
                        readOnly={readOnly}
                        onChange={onSliderChange}
                    />

                    <Box padding="panel">
                        <Stack spacing="base">
                            <EditableDayComponent
                                day={t.dayAbbreviationsGROUP.monday}
                                isActive={days.hasMonday()}
                                readOnly={readOnly}
                                onToggleDay={() => onDaysChange(days.setMonday(!days.hasMonday()))}
                            />
                            <EditableDayComponent
                                day={t.dayAbbreviationsGROUP.tuesday}
                                isActive={days.hasTuesday()}
                                readOnly={readOnly}
                                onToggleDay={() =>
                                    onDaysChange(days.setTuesday(!days.hasTuesday()))
                                }
                            />
                            <EditableDayComponent
                                day={t.dayAbbreviationsGROUP.wednesday}
                                isActive={days.hasWednesday()}
                                readOnly={readOnly}
                                onToggleDay={() =>
                                    onDaysChange(days.setWednesday(!days.hasWednesday()))
                                }
                            />
                            <EditableDayComponent
                                day={t.dayAbbreviationsGROUP.thursday}
                                isActive={days.hasThursday()}
                                readOnly={readOnly}
                                onToggleDay={() =>
                                    onDaysChange(days.setThursday(!days.hasThursday()))
                                }
                            />
                            <EditableDayComponent
                                day={t.dayAbbreviationsGROUP.friday}
                                isActive={days.hasFriday()}
                                readOnly={readOnly}
                                onToggleDay={() => onDaysChange(days.setFriday(!days.hasFriday()))}
                            />
                            <EditableDayComponent
                                day={t.dayAbbreviationsGROUP.saturday}
                                isActive={days.hasSaturday()}
                                readOnly={readOnly}
                                onToggleDay={() =>
                                    onDaysChange(days.setSaturday(!days.hasSaturday()))
                                }
                            />
                            <EditableDayComponent
                                day={t.dayAbbreviationsGROUP.sunday}
                                isActive={days.hasSunday()}
                                readOnly={readOnly}
                                onToggleDay={() => onDaysChange(days.setSunday(!days.hasSunday()))}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
};

TimeSerie.displayName = 'TimeSerie';
