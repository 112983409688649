import { keyBy, times } from 'lodash-es';
import { createSelector } from 'reselect';
import { isDefined } from 'axis-webtools-util';
import { getCurrentProjectRelationsRecord } from '../../relations';
import { getCurrentProjectItems } from '../../project';
import { getImageSensors } from './getImageSensors';
import { getIdFromProps } from '../../selectors';

export const getSelectedLenses = createSelector(
    [getCurrentProjectRelationsRecord, getCurrentProjectItems, getImageSensors, getIdFromProps],
    (currentProjectRelationsRecord, currentProjectItems, imageSensors, itemId) =>
        times(imageSensors, (sensorIndex) =>
            keyBy(
                itemId && currentProjectRelationsRecord[itemId]
                    ? currentProjectRelationsRecord[itemId]
                          .filter(({ relationType }) => relationType === 'lenses')
                          .map(({ childId }) =>
                              sensorIndex ===
                              currentProjectItems[childId]?.properties.lens?.sensorIndex
                                  ? currentProjectItems[childId]
                                  : undefined,
                          )
                          .filter(isDefined)
                    : [],
                'productId',
            ),
        ),
);
