import { useEffect, useReducer } from 'react';

type State<T> = {
    result?: T;
    error?: boolean;
    pending: boolean;
};

type Action<T> =
    | { type: states.pending }
    | { type: states.rejected; error: true }
    | { type: states.resolved; result: T };

enum states {
    'pending',
    'rejected',
    'resolved',
}

const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
        case states.pending:
            return { pending: true };

        case states.resolved:
            return {
                result: action.result,
                error: undefined,
                pending: false,
            };

        case states.rejected:
            return {
                result: undefined,
                error: action.error,
                pending: false,
            };

        default:
            return state;
    }
};

export const usePromise = <T>(promise: Promise<T>, deps?: React.DependencyList | undefined) => {
    const [{ result, error, pending }, dispatch] = useReducer<React.Reducer<State<T>, Action<T>>>(
        reducer,
        { pending: true },
    );

    useEffect(() => {
        let canceled = false;
        dispatch({ type: states.pending });

        promise
            .then(
                (promiseResult) =>
                    !canceled &&
                    dispatch({
                        result: promiseResult,
                        type: states.resolved,
                    }),
            )
            .catch(
                (promiseError) =>
                    !canceled &&
                    dispatch({
                        error: promiseError,
                        type: states.rejected,
                    }),
            );

        return () => {
            canceled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return { result, error, pending };
};
