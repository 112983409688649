import * as React from 'react';
import { t } from 'app/translate';
import {
    Border,
    Box,
    ColorPalette,
    Heading,
    LinkButton,
    PiaImage,
    Stack,
    Text,
} from 'app/components';

import {
    getCurrentProjectItem,
    getCurrentProjectLocked,
    getDeviceSelectorEditLink,
    getItemColor,
    getModelName,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import {
    getSelectedDeviceName,
    getSelectedDeviceIdPiaItem,
    getSelectedDeviceId,
    getSelectedDevicePiaLoadingIcon,
} from 'app/modules/accessorySelector';
import { ItemService, deviceTypeCheckers } from 'app/core/persistence';
import type { IPersistence, IItemEntity } from 'app/core/persistence';
import { useService } from 'app/ioc';
import type { Colors } from 'app/styles';
import { getShouldHideChangeDevice } from '../../selectors';

export const DeviceDetailsHeader: React.FC = () => {
    const itemService = useService(ItemService);

    const name = useSelector(getSelectedDeviceName);
    const selectedProduct = useSelector(getSelectedDeviceIdPiaItem);
    const piaLoadingIcon = useSelector(getSelectedDevicePiaLoadingIcon);
    const deviceItemId = useSelector(getSelectedDeviceId);
    const model = useSelector<IStoreState, string | undefined>((state) =>
        getModelName(state, deviceItemId),
    );
    const device = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, deviceItemId),
    );
    const deviceColor = useSelector<IStoreState, Colors>((state) =>
        getItemColor(state, deviceItemId),
    );
    const isLocked = useSelector(getCurrentProjectLocked);

    const editLink = useSelector<IStoreState, string | undefined>((state) =>
        getDeviceSelectorEditLink(state, deviceItemId),
    );

    const shouldHideChangeDevice = useSelector<IStoreState, boolean>((state) =>
        getShouldHideChangeDevice(state, deviceItemId),
    );

    const shouldHideColorPicker = !!(device && deviceTypeCheckers.isSensorUnit(device));

    const updateColor = (color: Colors) => {
        deviceItemId && itemService.updateItem(deviceItemId, { color });
    };

    return (
        <Box alignItems="center" color="grey2" padding="base">
            <Border radius="100%" color="transparent" width={3}>
                <Box
                    width="70px"
                    height="70px"
                    alignItems="center"
                    justifyContent="center"
                    color="transparent"
                >
                    <PiaImage
                        imageSize={selectedProduct?.id ? 'xxl' : 'xl'}
                        icon={piaLoadingIcon}
                        piaId={selectedProduct?.id ?? null}
                    />
                </Box>
            </Border>
            <Stack justifyContent="center" spacing="doublePanel">
                <Stack alignItems="center">
                    <Box direction="column" paddingLeft="base">
                        <Heading>{name}</Heading>
                        <Text wordBreak="normal">{model}</Text>
                    </Box>
                    {editLink && !shouldHideChangeDevice && (
                        <Stack spacing="halfCell" alignItems="stretch">
                            {!isLocked && (
                                <LinkButton
                                    testId="details_change_device_btn"
                                    link={editLink}
                                    disabled={isLocked}
                                >
                                    {t.productsChangeDevice}
                                </LinkButton>
                            )}
                            <ColorPalette
                                color={deviceColor ?? 'devicePalette7'}
                                updateColor={updateColor}
                                disabled={shouldHideColorPicker}
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

DeviceDetailsHeader.displayName = 'DeviceDetailsHeader';
