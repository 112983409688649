import { injectable } from 'inversify';
import type { IBaseEntity, IItemEntity } from '../../entities';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { Colors } from 'app/styles';

/**
 * Change color for devices to devicePalette
 */
const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

const colorMap: Partial<Record<Colors, Colors>> = {
    yellow: 'devicePalette3',
    green: 'devicePalette5',
    blue: 'devicePalette7',
};

const allowedColors: Set<Colors> = new Set([
    'devicePalette1',
    'devicePalette2',
    'devicePalette3',
    'devicePalette4',
    'devicePalette5',
    'devicePalette6',
    'devicePalette7',
    'devicePalette8',
    'devicePalette9',
]);

@injectable()
export class Migration39to40 extends MigrationProviderBase {
    public from: number = 39;
    public to: number = 40;

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (entity.color) {
                    // if the item has a color check if it has to be mapped
                    const newColor = colorMap[entity.color];
                    if (newColor) {
                        // use the mapped color
                        entity.color = newColor;
                    } else if (!allowedColors.has(entity.color)) {
                        // fallback if the item doesn't have an allowed color
                        entity.color = 'devicePalette7';
                    }
                }
            }

            return entity;
        };
    }
}
