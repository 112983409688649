import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import { Box, Button, Card, Heading, IconButton, Linkable, Stack, Text } from 'app/components';
import { CommonActionService, getUserSignedIn, useProjectName } from 'app/modules/common';
import type { PiaId } from 'app/core/pia';
import { getSelectedDocumentationProductIds } from '../selectors';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { useLanguage } from 'app/hooks';

export const CsiCompile: React.FC = () => {
    const commonActions = useService(CommonActionService);
    const isSignedIn = useSelector<IStoreState, boolean>(
        (state) => getUserSignedIn(state) === true,
    );
    const projectName = useProjectName();
    const selectedPiaIds = useSelector<IStoreState, PiaId[]>((state) =>
        getSelectedDocumentationProductIds(state),
    );
    const language = useLanguage();
    const signIn = () => {
        eventTracking.logUserEvent('Application', 'Login');
        commonActions.login();
    };
    const getCsiLink = () =>
        encodeURI(
            `https://ae-media.axis.com/?piaIds=${selectedPiaIds.join(
                ',',
            )}&projectName=${projectName}&application=SiteDesigner&lg=${language}`,
        );

    return (
        <Card paddingX="panel" paddingY="panel">
            <Stack vertical>
                <Heading>AXIS Specification Compiler</Heading>
                <Stack vertical spacing="quart">
                    <Text style="semibold">
                        {isSignedIn
                            ? t.generateCombinedSpecification
                            : t.generateCombinedSpecificationSignIn}
                    </Text>
                    <Text>{t.availableToAndEProgram}</Text>
                </Stack>
                <Box justifyContent="end">
                    {isSignedIn ? (
                        <Linkable external link={getCsiLink()}>
                            <IconButton
                                iconRight
                                icon="arrow_forward"
                                text={t.downloadSpecification}
                            />
                        </Linkable>
                    ) : (
                        <Button primary onClick={signIn}>
                            {t.userSignIn}
                        </Button>
                    )}
                </Box>
            </Stack>
        </Card>
    );
};

CsiCompile.displayName = 'CsiCompile';
