import { Module } from 'app/ioc';
import { recordingSelectorReducer } from './state/RecordingSelector.reducer';
import {
    RecordingSelectorActionService,
    RecordingSelectorService,
    RecordingAccessoriesService,
    GenetecStreamVaultProjectService,
    GenetecRecommendationCommunicator,
    RecordingValidatorService,
} from './services';

@Module({
    binder: (binder) => {
        binder.bindService(RecordingSelectorActionService);
        binder.bindService(RecordingSelectorService);
        binder.bindService(RecordingValidatorService);
        binder.bindService(RecordingAccessoriesService);
        binder.bindService(GenetecStreamVaultProjectService);
        binder.bindService(GenetecRecommendationCommunicator);
    },
    reducers: {
        recordingSelector: recordingSelectorReducer,
    },
})
export class RecordingSelectorModule {}
