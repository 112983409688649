import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ChangeDeviceComponent } from './ChangeDevice.component';
import type { IMapsDevice } from '../../../../models';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import {
    getCurrentProjectItem,
    getDeviceSelectorEditLink,
    getHasParentAvailableConnections,
} from 'app/modules/common';
import { getParentId } from 'app/core/persistence';
import { PiaItemPacCategory } from 'app/core/pia';

interface IChangeDeviceContainerProps {
    link?: string;
    device?: IMapsDevice;
    isDragDisabled?: boolean;
}

const mapStateToProps = (storeState: IStoreState): IChangeDeviceContainerProps => {
    const device = getSelectedDeviceOrParentDevice(storeState);
    let isDragDisabled = undefined;
    if (
        device?.deviceType === 'sensorUnit' ||
        device?.piaProduct?.category === PiaItemPacCategory.RELAYEXPMODULES
    ) {
        // find out if drag should be disabled
        const item = getCurrentProjectItem(storeState, device.id);
        const parentId = item ? getParentId(item) : undefined;
        const hasAvailableChannels = getHasParentAvailableConnections(storeState, parentId);
        isDragDisabled = !hasAvailableChannels;
    }
    return {
        device,
        link: device ? getDeviceSelectorEditLink(storeState, device.id) : undefined,
        isDragDisabled,
    };
};

class ChangeDeviceContainer extends React.Component<IChangeDeviceContainerProps> {
    public render() {
        return this.props.device ? (
            <ChangeDeviceComponent
                device={this.props.device}
                link={this.props.link}
                isDragDisabled={this.props.isDragDisabled}
            />
        ) : null;
    }
}

export const ChangeDevice = connect(mapStateToProps)(ChangeDeviceContainer);
