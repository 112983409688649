import { injectable } from 'inversify';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { isDefined } from 'axis-webtools-util';
import type { IPersistence, IUserSettingsEntity } from '../userDataPersistence';
import { UserSettingsRepository } from '../userDataPersistence';
import { ImageService } from './imageStore';
import { UserDataRepository } from './user';
import * as moment from 'moment';
import { toaster } from 'app/toaster';

@injectable()
export class UserSettingsService {
    constructor(
        private userSettingsRepository: UserSettingsRepository,
        private userDataRepository: UserDataRepository,
        private imageService: ImageService,
    ) {}

    private getDefaultUserSettings = (currentHid: string | undefined): IUserSettingsEntity => {
        return {
            type: 'userSettings',
            path: [],
            archived: false,
            logoKey: null,
            hid: currentHid,
        };
    };

    private deleteUnMatchingUserEntities = async (
        idOfEntityToKeep: string | undefined,
        allUserSettings: IPersistence<IUserSettingsEntity>[],
    ) => {
        for (const userSetting of allUserSettings) {
            if (idOfEntityToKeep !== userSetting._id) {
                await this.userSettingsRepository.delete(userSetting._id, userSetting._rev);
            }
        }
    };

    public async getUserSettings(): Promise<IPersistence<IUserSettingsEntity>> {
        const allUserSettings = await this.userSettingsRepository.getAll();

        const userInfo = await this.userDataRepository.getUserInfo();
        const currentHid = userInfo?.hid;

        // If no user settings are found, a new user settings entity is created
        if (!allUserSettings.length) {
            return this.userSettingsRepository.add(this.getDefaultUserSettings(currentHid));
        }

        /**
         *  If we have user settings in our repository we want to find the one that matches our hid.
         *  If user is not signed in, this can receive a user with undefined hid
         */
        const desiredUserEntities = allUserSettings.filter(
            (userSetting: IUserSettingsEntity) => userSetting.hid === currentHid,
        );

        /**
         *  This should never happen. But if we somehow have multiple userSettings
         *  relating to the same hid, we use the most recently updated and delete the rest.
         */
        desiredUserEntities.sort((a, b) => moment(b.updatedDate).diff(a.updatedDate));
        const desiredUserEntity = desiredUserEntities[0];

        if (desiredUserEntity) {
            await this.deleteUnMatchingUserEntities(desiredUserEntity._id, allUserSettings);
            return desiredUserEntity;
        } else {
            /**
             *   This case should only trigger when no userSettings have been created for the current hid
             *   IE upon first logging in to your account. Then, if we have userSettings for an anonymous
             *   user, these shall be updated with our current hid, effectively transferring these settings
             *   to the new user.
             */
            await this.deleteUnMatchingUserEntities(allUserSettings[0]._id, allUserSettings);
            await this.updateUserSettings({
                hid: currentHid,
            });
            return allUserSettings[0];
        }
    }

    public async updateUserSettings(
        props: DeepPartial<IUserSettingsEntity>,
    ): Promise<IUserSettingsEntity> {
        const allUserSettings = await this.userSettingsRepository.getAll();
        const userSettings = allUserSettings[0];

        return this.userSettingsRepository.update({
            logoKey: props.logoKey !== undefined ? props.logoKey : userSettings.logoKey,
            hid: props.hid !== undefined ? props.hid : userSettings.hid,
            type: userSettings.type,
            path: userSettings.path,
            archived: false,
            _id: userSettings._id,
            _rev: userSettings._rev,
            creationDate: userSettings.creationDate,
            updatedDate: userSettings.updatedDate,
            entityVersion: userSettings.entityVersion,
        });
    }

    public async addLogo(logoFile: File) {
        try {
            await this.deleteAllLogos();
            const imageData = await this.imageService.addImage(
                {
                    file: logoFile,
                },
                false,
            );
            await this.updateUserSettings({ logoKey: imageData.key });

            eventTracking.logUserEvent('User', 'Logo Uploaded', logoFile.type);
        } catch (error) {
            console.error(error, 'Unable to add logo');
            eventTracking.logError('Logo Upload Unsuccessful', 'UserSettingsService');

            switch (String(error)) {
                // Error 401 is sent when user is not authenticated. We should block users from
                // attempting to upload logos when unauthenticated, but this could be good to keep just in case.
                case 'Error: 401':
                    return toaster.error(t.logoToasterLogoUploadFailureHeader, t.unauthenticated);
                // Error 403 is sent when max quota is reached
                case 'Error: 403':
                    return toaster.error(
                        t.logoToasterLogoUploadFailureHeader,
                        t.uploadFileQuotaExceeded,
                    );
                // Error: 413 is sent when file size is too large
                case 'Error: 413':
                    return toaster.error(
                        t.logoToasterLogoUploadFailureHeader,
                        t.uploadFileTooLarge,
                    );
                // If none of the above, the user is most likely offline
                default:
                    return toaster.error(
                        t.logoToasterLogoUploadFailureHeader,
                        t.uploadFileUnknownError,
                    );
            }
        }
    }

    /**
     * This function is called upon adding a logo since each user should only have one.
     * We only want to show information about the deletion failing when a user actively tries to remove a logo though.
     * That's why enableErrorToaster is an optional parameter.
     */
    public async deleteAllLogos(enableErrorToaster?: boolean) {
        try {
            const userSettingsRepositories = await this.userSettingsRepository.getAll();
            await Promise.all(
                userSettingsRepositories
                    .map((userRepo) => userRepo.logoKey)
                    .filter(isDefined)
                    .map((logoKey) => this.imageService.deleteImage(logoKey)),
            );

            await this.updateUserSettings({ logoKey: null });

            eventTracking.logUserEvent('User', 'Logo removed');
        } catch (error) {
            console.error(error, 'Unable to delete logo');
            eventTracking.logError('Logo Removal Unsuccessful', 'UserSettingsService');
            if (enableErrorToaster) {
                toaster.error(
                    t.logoToasterLogoDeleteFailureHeader,
                    t.logoToasterLogoDeleteFailureBody,
                );
            }
        }
    }
}
