import { getPiaIdFromProps, toCacheKey } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

const getApplicationState = (state: IStoreState) => state.addonSelector;

const getClickedApplication = createSelector(
    [getApplicationState],
    (applicationState) => applicationState.clickedApplication,
);

export const getIsClickedApplication = createCachedSelector(
    [getClickedApplication, getPiaIdFromProps],
    (clickedApplication, piaId): boolean => {
        return clickedApplication === piaId;
    },
)(toCacheKey);
