import { GridHelper, Mesh, Object3D, PlaneGeometry } from 'three';

import { groundMaterial, transparentMaterial } from '../materials';

export class Ground extends Object3D {
    private grid: GridHelper;
    private groundMesh: Mesh;
    private aboveGroundMesh: Mesh;

    constructor(unitLength: number) {
        super();
        this.grid = this.createGrid(unitLength);

        const baseGeometry = new PlaneGeometry(1000, 1000, 1, 1);
        this.groundMesh = new Mesh(baseGeometry, groundMaterial);
        this.groundMesh.rotation.x = Math.PI / 2;
        this.groundMesh.position.y = -0.1;

        this.aboveGroundMesh = new Mesh(baseGeometry, transparentMaterial);
        this.aboveGroundMesh.rotation.x = Math.PI / 2;
        this.aboveGroundMesh.position.y = 0.02;

        this.add(this.groundMesh);
        this.add(this.aboveGroundMesh);
        this.add(this.grid);
    }

    public update(unitLength: number) {
        this.grid.geometry = this.createGrid(unitLength).geometry;
    }

    public getIntersectObjects(): Array<Object3D> {
        return [this.aboveGroundMesh];
    }

    private createGrid(unitLength: number) {
        return new GridHelper(1000 * unitLength, 1000);
    }
}
