import { toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { getInstallationPointForMapItem } from './getProjectInstallationPoints';

export type CoverageAreaIcons = 'human' | 'vehicle';

const getHasRangeAnalytics = createCachedSelector(
    [getInstallationPointForMapItem],
    (installationPoint) => {
        const analyticRange = installationPoint?.parentDevice.analyticRange;
        return !analyticRange
            ? false
            : analyticRange.activeTypes.includes('person') ||
                  analyticRange.activeTypes.includes('vehicle');
    },
)(toCacheKey);
export const getHasCoverageAreaIcons = createCachedSelector(
    [getInstallationPointForMapItem, getHasRangeAnalytics],
    (installationPoint, hasRangeAnalytics) => {
        return (installationPoint && installationPoint.radar !== undefined) || hasRangeAnalytics;
    },
)(toCacheKey);
