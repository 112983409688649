import { hasNetworkSettingsCapability } from 'app/core/persistence';
import type { IPersistence, IItemEntity, IProjectNetworkSettings } from 'app/core/persistence';
import { IPv4, RangedSet } from 'ip-num';
import { createSelector } from 'reselect';
import { getCurrentProjectItemsArray, getCurrentProjectNetworkSettings } from '../../project';
import { getPiaItems } from '../../piaDevices';
import type { IPiaItem } from 'app/core/pia';

const hasNetworkSettings = (item: IPersistence<IItemEntity>) => !!item.networkSettings;

const hasValidQuantity = (item: IPersistence<IItemEntity>) =>
    item.networkSettings?.length === item.quantity;

const hasOverride = (item: IPersistence<IItemEntity>) =>
    !!item.networkSettings?.find(({ override }) => !!override);

const hasDHCP = (item: IPersistence<IItemEntity>) =>
    !!item.networkSettings?.find(({ dhcp }) => !!dhcp);

const hasValidIPRange = (
    item: IPersistence<IItemEntity>,
    projectNetworkSettings?: IProjectNetworkSettings,
) => {
    let result = false;
    try {
        if (projectNetworkSettings && item.networkSettings) {
            const rangedSet = RangedSet.fromRangeString(
                `${projectNetworkSettings.ipStart}-${projectNetworkSettings.ipEnd}`,
            );
            result = !!item.networkSettings.find(({ addresses }) => {
                if (addresses.length) {
                    return addresses.every((address) => {
                        const addressIPv4 = IPv4.fromDecimalDottedString(address);
                        return (
                            rangedSet.getFirst().isLessThanOrEquals(addressIPv4) &&
                            rangedSet.getLast().isGreaterThanOrEquals(addressIPv4)
                        );
                    });
                }
            });
        }
    } catch (e) {
        return false;
    }
    return result;
};

const hasInvalidNetworkSettings = (
    item: IPersistence<IItemEntity>,
    piaDevice: IPiaItem | null,
    projectNetworkSettings?: IProjectNetworkSettings,
): boolean =>
    !!projectNetworkSettings &&
    !projectNetworkSettings?.dhcp &&
    hasNetworkSettingsCapability(item.properties, piaDevice) &&
    !hasOverride(item) &&
    (!hasNetworkSettings(item) ||
        !hasValidQuantity(item) ||
        !hasValidIPRange(item, projectNetworkSettings));

const hasInvalidDHCP = (
    item: IPersistence<IItemEntity>,
    piaDevice: IPiaItem | null,
    projectNetworkSettings?: IProjectNetworkSettings,
): boolean =>
    !!projectNetworkSettings?.dhcp &&
    hasNetworkSettingsCapability(item.properties, piaDevice) &&
    !hasOverride(item) &&
    (!hasNetworkSettings(item) || !hasDHCP(item));

export const getCurrentProjectItemsWithInvalidNetworkSettings = createSelector(
    [getCurrentProjectItemsArray, getCurrentProjectNetworkSettings, getPiaItems],
    (items, projectNetworkSettings, piaItems) =>
        items.filter((item) => {
            const piaDevice = piaItems.find((piaItem) => piaItem.id === item.productId) ?? null;
            return (
                hasInvalidNetworkSettings(item, piaDevice, projectNetworkSettings) ||
                hasInvalidDHCP(item, piaDevice, projectNetworkSettings)
            );
        }),
);
