import { isEqual } from 'lodash-es';

// creates a new function with the same signature as the passed in function but only
// calls it when the arguments change
export const callWhenArgsChange = <T extends (...args: any[]) => any>(fn: T) => {
    let lastArgs: any[];
    return (...args: any[]) => {
        if (!isEqual(args, lastArgs)) {
            lastArgs = args;
            fn(...args);
        }
    };
};
