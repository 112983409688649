import { injectable } from 'inversify';
import type { IPiaPeopleCounter } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { IItemEntity, IPersistence, IPeopleCounterItemEntity } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { IExportablePersistedEntity, IExportedPeopleCounter } from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import { toShareModelNames } from '../../mapToShareItemNames';

@injectable()
export class PeopleCountersExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaPeopleCounter>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedPeopleCounters = (
        items: IExportablePersistedEntity[],
    ): IExportedPeopleCounter[] => {
        const allPeopleCounters = items.filter(this.isPeopleCountersItemEntity);

        const mappedPeopleCounters = allPeopleCounters.map(
            this.mapPeopleCountersToExportedPeopleCounters,
        );
        return mappedPeopleCounters.filter(isDefined);
    };

    public mapPeopleCountersToExportedPeopleCounters = (
        peopleCounterItem: IExportablePersistedEntity,
    ): IExportedPeopleCounter | null => {
        if (!peopleCounterItem.productId) {
            return null;
        }

        const peopleCounterPiaDevice = this.getPiaDevice(peopleCounterItem.productId);

        return {
            ...this.mapItemToExportedItemBase(peopleCounterItem),
            ...toShareModelNames(peopleCounterPiaDevice.name),
            piaId: peopleCounterItem.productId,
        };
    };

    private isPeopleCountersItemEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<IPeopleCounterItemEntity> {
        return deviceTypeCheckers.isPeopleCounter(item);
    }
}
