import * as React from 'react';
import {
    Modal,
    Box,
    Stack,
    Text,
    Heading,
    Button,
    IconButton,
    Linkable,
    Clickable,
} from 'app/components';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import { getDeviceRequirements } from '../../selectors';
import {
    getBandwidthStorageEstimateForItems,
    getCurrentProjectName,
    getPiaItemsRecord,
} from 'app/modules/common';
import { useService } from 'app/ioc';
import { GenetecStreamVaultProjectService } from '../../services';
import saveAs from 'file-saver';
import { eventTracking } from 'app/core/tracking';

interface IProps {
    title: string;
    onClose: () => void;
}

const StreamVaultCalculatorURL = 'https://svcalculator.genetec.com/';

export const GenetecRecommendModal: React.FC<IProps> = ({ title, onClose }) => {
    const streamVaultProjectService = useService(GenetecStreamVaultProjectService);
    const allDeviceRequirements = useSelector(getDeviceRequirements);
    const bandwidthStorageEstimateForItems = useSelector(getBandwidthStorageEstimateForItems);
    const piaItems = useSelector(getPiaItemsRecord);
    const projectName = useSelector(getCurrentProjectName);

    const downloadStreamVaultProjectFile = () => {
        eventTracking.logUserEvent(
            'System Components',
            'Download Genetec StreamVault project file',
        );
        const fileContent = JSON.stringify(
            streamVaultProjectService.getStreamVaultCalculatorProject(
                allDeviceRequirements,
                bandwidthStorageEstimateForItems,
                piaItems,
            ),
        );
        const fileBlob = new Blob([fileContent], {
            type: 'application/json',
        });
        const filename = `${projectName.replace(/\s/g, '_')}.svj`;
        saveAs(fileBlob, filename);
    };

    return (
        <Modal onClose={onClose} title={title} maxWidth="600px" minWidth="600px">
            <Box paddingY="half">
                <Text>{t.genetecGROUP.useGenetec}</Text>
            </Box>
            <Stack spacing="base" vertical>
                <Box paddingTop="base">
                    <Stack justifyContent="start">
                        <Heading style="subheader">1.</Heading>
                        <Stack vertical alignItems="start">
                            <Heading style="subheader">{t.genetecGROUP.exportProjectFile}</Heading>
                            <IconButton
                                icon="file_download"
                                text={t.download}
                                onClick={downloadStreamVaultProjectFile}
                            />
                        </Stack>
                    </Stack>
                </Box>
                <Box paddingTop="base">
                    <Stack justifyContent="start">
                        <Heading style="subheader">2.</Heading>
                        <Stack vertical>
                            <Heading style="subheader">
                                {t.genetecGROUP.useStreamvaultCalculator}
                            </Heading>
                            <Stack justifyContent="start" spacing="half">
                                <Text>{t.genetecGROUP.visit}</Text>
                                <Linkable external link={StreamVaultCalculatorURL}>
                                    <Clickable>
                                        <Text color="blue">{StreamVaultCalculatorURL}</Text>
                                    </Clickable>
                                </Linkable>

                                <Text>{t.genetecGROUP.andChoose}</Text>
                            </Stack>
                            <Text>{t.genetecGROUP.selectExportedFile}</Text>
                        </Stack>
                    </Stack>
                </Box>
                <Box paddingTop="base">
                    <Stack justifyContent="start">
                        <Heading style="subheader">3.</Heading>
                        <Stack vertical>
                            <Heading style="subheader">
                                {t.genetecGROUP.addRecommendationsToAxis}
                            </Heading>
                            <Text>{t.genetecGROUP.selectRecommendedProducts}</Text>
                        </Stack>
                    </Stack>
                </Box>
                <Box justifyContent="end">
                    <Button primary onClick={onClose}>
                        {t.close}
                    </Button>
                </Box>
            </Stack>
        </Modal>
    );
};
