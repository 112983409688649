import type { IPiaEncoder } from 'app/core/pia';
import { defaultSustainabilityFilter } from 'app/core/persistence';
import { meetsSustainabilityRequirements } from './SustainabilityFilter';
import type { IEncoderFilter } from 'app/modules/common';

export const defaultEncoderFilter: IEncoderFilter = {
    channels: undefined,
    blade: false,
    twoWayAudio: false,
    outdoor: false,
    ...defaultSustainabilityFilter,
};

export class EncoderFilter {
    public static filter(
        encoderFilter: IEncoderFilter,
        allEncoders: IPiaEncoder[],
        searchFilter: string,
    ): IPiaEncoder[] {
        const lowerCaseSearchFilter = searchFilter.toLowerCase();
        return allEncoders.filter((encoder) => {
            if (!encoder.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (encoderFilter === null) {
                return true;
            }
            if (encoderFilter.blade === true && encoder.properties.formFactor !== 'Blade') {
                return false;
            }
            if (encoderFilter.twoWayAudio === true && encoder.properties.twoWayAudio === false) {
                return false;
            }
            if (
                typeof encoderFilter.channels === 'number' &&
                encoderFilter.channels > 0 &&
                encoder.properties.channels !== Number(encoderFilter.channels)
            ) {
                return false;
            }
            if (!meetsSustainabilityRequirements(encoderFilter, encoder)) {
                return false;
            }

            return true;
        });
    }
}
