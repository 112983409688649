export const PersistenceActions = {
    PROJECT_UPDATED: 'CORE_PROJECT_UPDATED',
    PROJECT_DELETED: 'CORE_PROJECT_DELETED',
    UpdateProject: 'CURRENT_PROJECT_UPDATE_PROJECT',
    UpdateItem: 'CURRENT_PROJECT_UPDATE_ITEM',
    UpdateProfile: 'CURRENT_PROJECT_UPDATE_PROFILE',
    UpdateItemRelation: 'CURRENT_PROJECT_UPDATE_ITEM_RELATION',
    UpdateSchedule: 'CURRENT_PROJECT_UPDATE_SCHEDULE',
    UpdateCustomItem: 'CURRENT_PROJECT_UPDATE_CUSTOM_ITEM',
    UpdateTimeSerie: 'CURRENT_PROJECT_UPDATE_TIME_SERIE',
    UpdateQuotation: 'CURRENT_PROJECT_UPDATE_QUOTATION',
    UpdateFloorPlan: 'CURRENT_PROJECT_UPDATE_FLOOR_PLAN',
    UpdateMapLocation: 'CURRENT_PROJECT_UPDATE_MAP_LOCATION',
    UpdateInstallationPoint: 'CURRENT_PROJECT_UPDATE_INSTALLATION_POINT',
    UpdateFreeTextPoint: 'CURRENT_PROJECT_UPDATE_FREE_TEXT_POINT',
    UpdateUserSettings: 'UPDATE_USER_SETTINGS',
    UpdatePartnerItem: 'UPDATE_PARTNER_ITEM',
    GetUserCustomItems: 'GET_USER_CUSTOM_ITEMS',
    UpdateUserCustomItem: 'UPDATE_USER_CUSTOM_ITEM',
    DeleteUserCustomItem: 'DELETE_USER_CUSTOM_ITEM',
    DeleteProject: 'CURRENT_PROJECT_DELETE_PROJECT',
    DeleteItem: 'CURRENT_PROJECT_DELETE_ITEM',
    DeleteProfile: 'CURRENT_PROJECT_DELETE_PROFILE',
    DeleteItemRelation: 'CURRENT_PROJECT_DELETE_ITEM_RELATION',
    DeleteSchedule: 'CURRENT_PROJECT_DELETE_SCHEDULE',
    DeleteCustomItem: 'CURRENT_PROJECT_DELETE_CUSTOM_ITEM',
    DeleteTimeSerie: 'CURRENT_PROJECT_DELETE_TIME_SERIE',
    DeleteFloorPlan: 'CURRENT_PROJECT_DELETE_FLOOR_PLAN',
    DeleteMapLocation: 'CURRENT_PROJECT_DELETE_MAP_LOCATION',
    DeleteInstallationPoint: 'CURRENT_PROJECT_DELETE_INSTALLATION_POINT',
    DeleteFreeTextPoint: 'CURRENT_PROJECT_DELETE_FREE_TEXT_POINT',
    PushUndoAction: 'CURRENT_PROJECT_PUSH_UNDO',
    PushRedoAction: 'CURRENT_PROJECT_PUSH_REDO',
    PopUndoAction: 'CURRENT_PROJECT_POP_UNDO',
    PopRedoAction: 'CURRENT_PROJECT_POP_REDO',
    StartHistoryCommand: 'CURRENT_PROJECT_START_HISTORY_COMMAND',
    ClearRedoQueue: 'CURRENT_PROJECT_CLEAR_REDO',
    UpdateHistoryIds: 'CURRENT_PROJECT_UPDATE_HISTORY_IDS',
    UpdateSyncStatus: 'CURRENT_PROJECT_UPDATE_SYNC_STATUS',
    UpdateSyncDateTime: 'CURRENT_PROJECT_UPDATE_SYNC_DATETIME',
    DeletePartnerItem: 'CURRENT_PROJECT_DELETE_PARTNER_ITEM',
    UpdateBlocker: 'CURRENT_PROJECT_UPDATE_BLOCKER',
    DeleteBlocker: 'CURRENT_PROJECT_DELETE_BLOCKER',
};
