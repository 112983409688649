import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { cx, css } from '@emotion/css';
import { Border, Box, Stack, Icon, Text } from 'app/components';
import type { IPartnerHeaderStyle } from 'app/core/persistence';
import { AppConstants } from 'app/AppConstants';

const headerStyle = css`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

export const ThemePreview: React.FC = () => {
    const style = useSelector<IStoreState, IPartnerHeaderStyle | undefined>(
        (state) => state.partnerConfig.style,
    );
    const icon = useSelector<IStoreState, string | undefined>((state) => state.partnerConfig.logo);

    return (
        <Border style="dashed" radius="16px" width={2} color="grey5">
            <Box padding="base">
                <div
                    className={cx(
                        headerStyle,
                        css`
                            background-color: ${style?.backgroundColor};
                        `,
                    )}
                >
                    <Text colorOverride={style?.foregroundColor} style="title">
                        AXIS Site Designer
                    </Text>
                    <Stack>
                        <Stack spacing="half">
                            <Stack spacing="panel">
                                {icon && <img src={icon} height={AppConstants.logoHeight} />}
                                <Icon opaque icon="person" colorOverride={style?.foregroundColor} />
                            </Stack>
                            <Text style="semibold" colorOverride={style?.foregroundColor}>
                                Jane Doe
                            </Text>
                        </Stack>
                        <Stack spacing="half">
                            <Icon opaque icon="language" colorOverride={style?.foregroundColor} />
                            <Text style="semibold" colorOverride={style?.foregroundColor}>
                                English
                            </Text>
                        </Stack>
                        <Icon opaque icon="more_vert" colorOverride={style?.foregroundColor} />
                    </Stack>
                </div>
            </Box>
        </Border>
    );
};

ThemePreview.displayName = 'ThemePreview';
