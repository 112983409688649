import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectItem,
    getCurrentProjectItemParent,
    getCurrentProject,
} from '../../project';
import type { DeviceAndSubType } from 'app/core/persistence';
import { getDeviceAndSubTypeForItem, getVendor } from '../../item';
import { toCacheKey } from '../../cacheKey';

export const getDeviceEditLink = createCachedSelector(
    [getCurrentProjectItem, getCurrentProjectItemParent, getDeviceAndSubTypeForItem, getVendor],
    (item, parent, deviceType, vendor) => {
        if (vendor === '2N') {
            return `./2N/edit/${item?._id}/`;
        }

        if (deviceType === 'analogCamera' || deviceType === undefined) {
            return undefined;
        }

        if (deviceType === 'sensorUnit') {
            return `./mainunit/${parent?._id}/sensorunit/edit/${item?._id}/`;
        }

        const route = `./${getBasePath(deviceType)}/edit/${item?._id}/`;
        return route;
    },
)(toCacheKey);

export const getDeviceSelectorEditLink = createCachedSelector(
    [
        getCurrentProject,
        getCurrentProjectItem,
        getCurrentProjectItemParent,
        getDeviceAndSubTypeForItem,
    ],
    (project, item, parent, deviceType) => {
        if (
            deviceType === 'analogCamera' ||
            deviceType === 'door' ||
            deviceType === 'relayexpmodules'
        ) {
            return undefined;
        }
        return parent
            ? `/project/${project?._id}/device-selector/edit/parent/${parent?._id}/item/${item?._id}/`
            : `/project/${project?._id}/device-selector/edit/${item?._id}/`;
    },
)(toCacheKey);

export const getFullDeviceEditLink = createCachedSelector(
    [getCurrentProject, getDeviceEditLink],
    (project, link) => {
        return `/project/${project?._id}/devices/${link?.slice(2)}`;
    },
)(toCacheKey);

function getBasePath(deviceType: DeviceAndSubType) {
    switch (deviceType) {
        case 'pac':
        case 'decoder':
        case 'doorstation':
        case 'iorelay':
        case 'doorcontroller':
        case 'peopleCounter':
        case 'radardetector':
            return 'otherProjectDevices';
        default:
            return deviceType;
    }
}
