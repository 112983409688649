import { useEffect } from 'react';

interface IKeyDownEventOrigin {
    addEventListener(key: 'keydown', callback: (e: KeyboardEvent) => void): void;
    removeEventListener(key: 'keydown', callback: (e: KeyboardEvent) => void): void;
}

/**
 * Used to subscribe to key down events from the specified origin.
 * If no origin is specified, window will be used as origin.
 * @param onKeyDownCallback Called when a key is pressed
 * @param eventOrigin The element used for event subscription
 */
export const useKeyDown = (
    onKeyDownCallback: (e: KeyboardEvent) => void,
    eventOrigin: IKeyDownEventOrigin = window,
) => {
    useEffect(() => {
        eventOrigin.addEventListener('keydown', onKeyDownCallback);
        // Remove event listeners on cleanup
        return () => {
            eventOrigin.removeEventListener('keydown', onKeyDownCallback);
        };
    }, [eventOrigin, onKeyDownCallback]);
};

/**
 * Used to subscribe to key down events from the enter key.
 * If no origin is specified, window will be used as origin.
 * @param onKeyDownCallback Called when enter key is pressed
 * @param eventOrigin The element used for event subscription
 */
export const useEnterKeyDown = (
    onKeyDownCallback: (e: KeyboardEvent) => void,
    eventOrigin: IKeyDownEventOrigin = window,
) => {
    useEffect(() => {
        const callback = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                onKeyDownCallback(event);
            }
        };

        eventOrigin.addEventListener('keydown', callback);
        // Remove event listeners on cleanup
        return () => {
            eventOrigin.removeEventListener('keydown', callback);
        };
    }, [eventOrigin, onKeyDownCallback]);
};
