import { createCachedSelector } from 're-reselect';
import { isIoRelay, isMainUnit } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';
import { getDeviceChildren } from './getDeviceChildren';
import { getPiaItem } from '../../item';

/**
 * Return true if device has available connections for child unit
 */
export const getHasParentAvailableConnections = createCachedSelector(
    [getDeviceChildren, getPiaItem],
    (children, piaItem) => {
        const usedConnections: number = children.reduce((sum, child) => (sum += child.quantity), 0);

        if (piaItem && isMainUnit(piaItem)) {
            const mainUnitProps = piaItem.properties;
            const maxChannels = mainUnitProps.channels ?? 0;
            return usedConnections < maxChannels;
        }

        if (piaItem && isIoRelay(piaItem)) {
            const piaProps = piaItem.properties;
            const maxExpansions = piaProps.nbrSupportedExpansionModules ?? 0;
            return usedConnections < maxExpansions;
        }
        return false;
    },
)(toCacheKey);
