import type {
    IItemEntity,
    IItemNetworkSettings,
    INetworkRange,
    IPersistableRangeType,
    IPersistence,
    IProjectNetworkSettings,
} from 'app/core/persistence';
import { getNetworkRangeType } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { IPv4, Validator } from 'ip-num';
import { createSelector } from 'reselect';
import { getPiaItemsRecord } from '../../piaDevices/selectors/getPiaDevices';
import {
    getCurrentProjectItemsArray,
    getCurrentProjectNetworkSettings,
} from '../../project/selectors/getCurrentProject';

/** Gets all cameras, speakers and servers without override that are outside of their reserved ranges */
export const getDevicesOutsideRange = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord, getCurrentProjectNetworkSettings],
    (items, piaItemsRecord, networkSettings) => {
        return findDevicesOutsideRange(items, piaItemsRecord, networkSettings, false);
    },
);

/** Gets all cameras, speakers and servers that has override and are outside of their reserved ranges */
export const getDevicesOutsideRangeWithOverride = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord, getCurrentProjectNetworkSettings],
    (items, piaItemsRecord, networkSettings) => {
        return findDevicesOutsideRange(items, piaItemsRecord, networkSettings, true);
    },
);

/** Checks if any device is outside its reserved range */
export const getHasAnyDeviceOutsideRanges = createSelector([getDevicesOutsideRange], (devices) =>
    Object.values(devices).some((deviceCategory) => !!deviceCategory.length),
);

/**
 * Finds cameras, speakers and servers that are outside their network range.
 * @param onlyWithOverride If true, only items with network override active will be included, otherwise overridden network settings are excluded
 * @returns Devices outside range by product type.
 */
const findDevicesOutsideRange = (
    items: IPersistence<IItemEntity>[],
    piaItemsRecord: Record<number, IPiaItem>,
    networkSettings: IProjectNetworkSettings | undefined,
    onlyWithOverride: boolean,
): Record<IPersistableRangeType, IPersistence<IItemEntity>[]> => {
    const result = {
        cameras: [] as IPersistence<IItemEntity>[],
        other: [] as IPersistence<IItemEntity>[],
        recorders: [] as IPersistence<IItemEntity>[],
    };

    items.forEach((item) => {
        const piaItem = item.productId ? piaItemsRecord[item.productId] : null;
        const deviceRangeType = getNetworkRangeType(item.properties, piaItem);
        if (!deviceRangeType) return;
        const range = networkSettings?.ranges[deviceRangeType];

        if (
            item.networkSettings?.some(
                (setting) =>
                    Boolean(setting.override) === onlyWithOverride &&
                    isOutsideRange(setting, range, onlyWithOverride),
            )
        ) {
            result[deviceRangeType].push(item);
        }
    });
    return result;
};

/**
 * Checks if an IP address in the network setting is outside of a certain range.
 * @param setting Network settings of item to check.
 * @param range IP range corresponding to device type.
 * @param includeOverride Determines if devices with override should be counted.
 * @returns True if outside range. Otherwise false.
 */
const isOutsideRange = (
    setting: IItemNetworkSettings,
    range: INetworkRange | undefined,
    includeOverride?: boolean,
) => {
    if ((setting.override && !includeOverride) || !range) {
        return false;
    }
    if (
        !Validator.isValidIPv4String(range.ipStart)[0] ||
        !Validator.isValidIPv4String(range.ipEnd)[0]
    ) {
        return false;
    }
    for (const address of setting.addresses) {
        if (!Validator.isValidIPv4String(address)[0]) {
            continue;
        }
        const ipv4Address = IPv4.fromDecimalDottedString(address);

        if (
            ipv4Address.isLessThan(IPv4.fromDecimalDottedString(range.ipStart)) ||
            ipv4Address.isGreaterThan(IPv4.fromDecimalDottedString(range.ipEnd))
        ) {
            return true;
        }
    }

    return false;
};
