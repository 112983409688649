import { t } from 'app/translate';
import * as React from 'react';
import { LinkButton, Text, Box, PrintPanel } from 'app/components';

export interface IEmptyReportProps {
    projectId: string;
    projectName: string;
    text: string;
    hideAddButton?: boolean;
    link?: string;
    actionText?: string;
}

export const EmptyReport: React.FunctionComponent<IEmptyReportProps> = ({
    projectId,
    projectName,
    text,
    hideAddButton,
    link = 'devices',
    actionText = t.quotationAddDevices,
}) => {
    return (
        <PrintPanel testId="panel_reports_empty_report_panel" heading={projectName}>
            <Box
                padding="base"
                color="grey2"
                justifyContent="between"
                flex="shrinkAndGrow"
                alignItems="center"
            >
                <Text faded>{text}</Text>
                {!hideAddButton && (
                    <LinkButton primary link={`/project/${projectId}/${link}/`}>
                        {actionText}
                    </LinkButton>
                )}
            </Box>
        </PrintPanel>
    );
};

EmptyReport.displayName = 'EmptyReport';
