import * as React from 'react';
import { css } from '@emotion/css';
import classNames from 'classnames';
import type { Spacings } from 'app/styles/spacings.type';
import { SpacingsEnum } from 'app/styles/spacings.type';
import { extendableProps } from '../../services';
import type { IExtendableComponentWithChildren } from '../../models';
import type { AlignItems, JustifyContent } from 'app/styles';
import {
    ItemsBaseline,
    ItemsCenter,
    ItemsEnd,
    ItemsStart,
    ItemsStretch,
    JustifyAround,
    JustifyBetween,
    JustifyCenter,
    JustifyEnd,
    JustifyEvenly,
    JustifyStart,
} from 'app/styles';
import type { IAutoTestable } from 'app/components/ui-test';
import { toTestIdFormat } from 'app/components/ui-test';

const GridStyle = css`
    display: flex;
    flex-wrap: wrap;
    width: fill-available;
`;

const PanelStyle = css`
    margin: calc(-1 * ${SpacingsEnum.panel});
    & > * {
        margin: ${SpacingsEnum.panel};
    }
`;

const BaseStyle = css`
    margin: calc(-1 * ${SpacingsEnum.base});
    & > * {
        margin: ${SpacingsEnum.base};
    }
`;

const HalfStyle = css`
    margin: calc(-1 * ${SpacingsEnum.half});
    & > * {
        margin: ${SpacingsEnum.half};
    }
`;

const QuartStyle = css`
    margin: calc(-1 * ${SpacingsEnum.quart});
    & > * {
        margin: ${SpacingsEnum.quart};
    }
`;

const HalfQuartStyle = css`
    margin: calc(-1 * ${SpacingsEnum.halfQuart});
    & > * {
        margin: ${SpacingsEnum.halfQuart};
    }
`;

interface IGridProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * Aligns a flex container's lines within when there is extra space in the cross-axis, similar to how justify-content aligns individual items within the main-axis
     */
    alignItems?: AlignItems;
    /**
     * Defines the alignment along the main axis. It helps distribute extra free space left over when either all the flex items on a line are inflexible, or are flexible but have reached their maximum size. It also exerts some control over the alignment of items when they overflow the line.
     */
    justifyContent?: JustifyContent;
    /**
     * The spacing between children
     */
    spacing?: Spacings;
}

/**
 * Display children in a grid-like manner.
 * Rows will wrap and children will have consistent
 * margins between them in all dimensions.
 */
export const Grid: React.FunctionComponent<IGridProps> = ({
    children,
    spacing = 'base',
    alignItems,
    justifyContent,
    testId,
    ...extendedProps
}) => {
    const attributes = extendableProps(
        extendedProps,
        {
            className: classNames(GridStyle, {
                [BaseStyle]: spacing === 'base',
                [HalfStyle]: spacing === 'half',
                [QuartStyle]: spacing === 'quart',
                [PanelStyle]: spacing === 'panel',
                [HalfQuartStyle]: spacing === 'halfQuart',
                [JustifyStart]: justifyContent === 'start',
                [JustifyEnd]: justifyContent === 'end',
                [JustifyCenter]: justifyContent === 'center',
                [JustifyBetween]: justifyContent === 'between',
                [JustifyAround]: justifyContent === 'around',
                [JustifyEvenly]: justifyContent === 'evenly',
                [ItemsStart]: alignItems === 'start',
                [ItemsEnd]: alignItems === 'end',
                [ItemsCenter]: alignItems === 'center',
                [ItemsBaseline]: alignItems === 'baseline',
                [ItemsStretch]: alignItems === 'stretch',
            }),
        },
        true,
    );
    return (
        <div data-test-id={toTestIdFormat(testId)} {...attributes}>
            {children}
        </div>
    );
};

Grid.displayName = 'Grid';
