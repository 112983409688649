import { getMapsState } from './getMapsState';
import { createSelector } from 'reselect';

export const getDeviceOverlayPanel = createSelector(
    [getMapsState],
    (mapsState) => mapsState.deviceOverlayPanel,
);

export const getFloorPlanConfigOverlayPanel = createSelector(
    [getMapsState],
    (mapsState) => mapsState.floorPlanConfigOverlayPanel,
);
