import { Box, SectionHeader, Stack, Table, Text } from 'app/components';
import type {
    IItemEntity,
    IItemNetworkSettings,
    IPersistence,
    IProjectNetworkSettings,
} from 'app/core/persistence';
import {
    isCustomCamera,
    deviceTypeCheckers,
    getNbrTotalAvailableIPAddresses,
} from 'app/core/persistence';

import type { IPiaItem } from 'app/core/pia';
import {
    getCurrentProjectCustomCameras,
    getCurrentProjectNetworkSettings,
    getCurrentSolutionSortedByRecorderCategory,
    getPiaItemsRecord,
    ProjectNetworkCard,
} from 'app/modules/common';
import { getCurrentProjectNbrRequiredIPAddresses } from 'app/modules/common/network/selectors';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import React from 'react';
import { useSelector } from 'react-redux';
import type { IItemWithChildren } from '../../selectors';
import { getSortedCurrentProjectDevices } from '../../selectors';

export const NetworkTable: React.FC = () => {
    const devices = useSelector<IStoreState, IItemWithChildren[]>((state) =>
        getSortedCurrentProjectDevices(state),
    );

    const customCameras = useSelector<IStoreState, IPersistence<IItemEntity>[]>((state) =>
        getCurrentProjectCustomCameras(state),
    );

    const projectNetworkSettings = useSelector<IStoreState, IProjectNetworkSettings | undefined>(
        (state) => getCurrentProjectNetworkSettings(state),
    );

    const piaItemsRecord = useSelector<IStoreState, Record<number, IPiaItem>>((state) =>
        getPiaItemsRecord(state),
    );

    const nbrTotalAvailableIPAddresses = projectNetworkSettings
        ? getNbrTotalAvailableIPAddresses(projectNetworkSettings)
        : 0;

    const nbrRequiredIPAddresses = useSelector<IStoreState, number>((state) =>
        getCurrentProjectNbrRequiredIPAddresses(state),
    );

    const recordingDevices = useSelector<IStoreState, IPersistence<IItemEntity>[]>(
        getCurrentSolutionSortedByRecorderCategory,
    );

    const allDevices = [...devices, ...customCameras, ...recordingDevices];

    /**
     * Get all ip-addresses to be displayed for the device, putting one address per line if multiple quantity
     * The first line is used as a heading and ip-address should be empty
     * @param networkSettings network settings for the device
     */
    const getAddresses = (networkSettings: IItemNetworkSettings[]) => {
        const addresses = networkSettings.map((network, index) => {
            return (
                <Text key={index}>
                    {network.addresses.filter((address) => !!address).join(', ')}
                </Text>
            );
        });
        if (networkSettings.length > 1) {
            addresses.unshift(<br key={-1} />);
        }
        return <>{addresses}</>;
    };

    /**
     * Get all names to be displayed for the device, putting one name according to index per line if multiple quantity
     * The first line is used as a heading and original device name is used
     * @param networkSettings network settings for the device
     */
    const getNames = (device: IPersistence<IItemEntity>) => {
        const nameToPrint = [];
        if (device.quantity > 1) {
            for (let index = 0; index < device.quantity; index++) {
                nameToPrint.push(`${device.name} (${index + 1}/${device.quantity})`);
            }
        }
        const names = nameToPrint.map((name, index) => (
            <Text key={index} faded>
                {name}
            </Text>
        ));
        // Recorders don't have custom names yet. When this text becomes empty it doesn't occupy the expected cell in the table.
        // Inserting a non breaking space instead solves this issue.
        names.unshift(
            device.name ? <Text key={-1}>{device.name}</Text> : <span key={-1}>&nbsp;</span>,
        );
        return <>{names}</>;
    };

    const hasDeviceOverrideNoDhcp = (networkSettings: IItemNetworkSettings[]) => {
        return networkSettings.some((network) => !network.dhcp && network.override);
    };

    const showOverrideSettings = allDevices.some((device) => {
        return device.networkSettings && hasDeviceOverrideNoDhcp(device.networkSettings) == true;
    });

    const getRows = (): Array<Array<string | number | JSX.Element> | null> => {
        const rowsToShow: ((string | number | JSX.Element)[] | null)[] | undefined = [];

        allDevices.forEach((device) => {
            const deviceNetworkSettings = device.networkSettings
                ? device.networkSettings[0]
                : undefined;

            if (deviceNetworkSettings) {
                const ipAddresses =
                    device?.networkSettings && device?.networkSettings?.length > 1
                        ? getAddresses(device.networkSettings)
                        : deviceNetworkSettings?.addresses.filter((address) => !!address);

                let deviceName;

                if (!isCustomCamera(device)) {
                    const idForName = device.replaceWithBareboneId
                        ? device.replaceWithBareboneId
                        : device.productId;
                    deviceName = idForName
                        ? piaItemsRecord[idForName].name
                        : deviceTypeCheckers.isAnalogCamera(device)
                          ? t.devicesGROUP.analogCamera
                          : '';
                } else {
                    deviceName = device.properties.camera.customCameraProperties.modelName;
                }

                const name = getNames(device);
                const override = deviceNetworkSettings.override;
                const address =
                    ipAddresses && !deviceNetworkSettings.dhcp
                        ? Array.isArray(ipAddresses)
                            ? ipAddresses.join(', ')
                            : ipAddresses
                        : '';

                const subnetMask =
                    override && deviceNetworkSettings.subnetMask && !deviceNetworkSettings.dhcp
                        ? deviceNetworkSettings.subnetMask
                        : '';
                const defaultRouter =
                    override && deviceNetworkSettings.defaultRouter && !deviceNetworkSettings.dhcp
                        ? deviceNetworkSettings.defaultRouter
                        : '';
                const dhcp = deviceNetworkSettings.dhcp ?? false;

                const itemRow = getRow(name, deviceName, address, subnetMask, defaultRouter, dhcp);
                rowsToShow.push(itemRow);
            }
        });
        return rowsToShow;
    };

    const getRow = (
        name: string | JSX.Element,
        model: string,
        ipAddress: string | JSX.Element,
        subnetMask: string,
        defaultRouter: string,
        dhcp: boolean,
    ): Array<string | number | JSX.Element> => {
        const text = ipAddress ? <div>{ipAddress}</div> : dhcp ? t.useDHCP : '';

        const row = [name, model, text];
        if (showOverrideSettings) {
            row.push(subnetMask, defaultRouter);
        }

        return row;
    };

    if (!projectNetworkSettings) {
        return null;
    }

    const headings = [t.name, t.modelName, t.ipAddress];

    if (showOverrideSettings) {
        headings.push(t.ipSubnetMask, t.ipDefaultRouter);
    }

    return (
        <Box pageBreakBefore>
            <Stack vertical>
                <SectionHeader text={t.network} />
                <Box width="100%" alignItems="center" justifyContent="center">
                    <ProjectNetworkCard
                        projectNetworkSettings={projectNetworkSettings}
                        nbrTotalAvailableIPAddresses={nbrTotalAvailableIPAddresses}
                        nbrRequiredIPAddresses={nbrRequiredIPAddresses}
                    />
                </Box>
                <Table headers={headings} rows={getRows()} omitLastLine testId="Network" />
            </Stack>
        </Box>
    );
};
