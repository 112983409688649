import { css } from '@emotion/css';
import type { Icons } from 'app/components';
import type { INetworkRangeType } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { animationTime } from 'app/styles/defaults';

/** Style for smoothly expanding and contracting an element. */
export const ipAccordionStyle = css`
    overflow: hidden;
    max-height: 0px;
    transition: max-height ${animationTime} ease-in-out;

    &[aria-expanded='true'] {
        max-height: 1200px;
    }
`;

/** Style for rotating an element 180 degrees. */
export const ipExpandButtonStyle = css`
    transition: ease-in-out ${animationTime};

    &[aria-expanded='true'] {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
`;

/** Gets color associated with a specific IP range type. */
export const toColor = (type: INetworkRangeType): Colors => {
    switch (type) {
        case 'cameras':
            return 'blue';
        case 'other':
            return 'yellow';
        case 'recorders':
            return 'green';
        case 'notUsed':
        default:
            return 'grey6';
    }
};

/** Gets icon for a specific IP range type. */
export const toIcon = (type: INetworkRangeType): Icons => {
    switch (type) {
        case 'cameras':
            return 'device';
        case 'other':
            return 'recording_bw_xs';
        case 'recorders':
            return 'recording';
        case 'notUsed':
            return 'category';
        default:
            return 'list_alt';
    }
};
