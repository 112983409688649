import { createSelector } from 'reselect';
import { CategoryEnum, getProductAllowlist, getProducts } from 'app/modules/common';

export const getProductsWithAllowlistProperty = createSelector(
    [getProducts, getProductAllowlist],
    (products, allowlist) => {
        const filteredProducts = products.filter(
            (product) => product.category !== CategoryEnum.InstallationService,
        );

        if (!allowlist) {
            return filteredProducts;
        }

        return filteredProducts.map((product) => {
            if (!product.piaId) {
                return product;
            }

            product.allowlisted =
                allowlist.recommendedProducts.includes(product.piaId) ||
                allowlist.otherProducts.includes(product.piaId);

            return product;
        });
    },
);
