import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectItems,
    getCurrentProjectRelationsRecord,
    getPiaCameraForProductId,
    getPiaIdFromProps,
    getPiaLensesRecord,
    toCacheKey,
} from 'app/modules/common';
import { getEditItem } from './getEditItem';
import type { IPiaAccessory } from 'app/core/pia';

export const getCurrentlyUsedLenses = createCachedSelector(
    [
        getPiaIdFromProps,
        getEditItem,
        getCurrentProjectRelationsRecord,
        getCurrentProjectItems,
        getPiaCameraForProductId,
        getPiaLensesRecord,
    ],
    (
        piaIdFromProps,
        editItem,
        relationsRecord,
        currentProjectItems,
        piaCamera,
        piaLenses,
    ): IPiaAccessory[] | undefined => {
        if (!editItem || !relationsRecord[editItem._id] || !piaCamera) {
            return undefined;
        }
        const isEditedItem = editItem?.productId === piaIdFromProps;

        if (!isEditedItem) {
            return undefined;
        }

        const lensesOnEditItem = relationsRecord[editItem._id].reduce(
            (lensItems, currentRelation) => {
                if (currentRelation.relationType !== 'lenses') {
                    return lensItems;
                }
                const lensItem = currentProjectItems[currentRelation.childId];
                if (!lensItem || lensItem.productId === null) {
                    return lensItems;
                }
                const lensPiaItem = piaLenses[lensItem.productId];
                if (!lensPiaItem) {
                    return lensItems;
                }
                return [...lensItems, lensPiaItem];
            },
            [] as IPiaAccessory[],
        );

        return lensesOnEditItem;
    },
)(toCacheKey);
