import { injectable } from 'inversify';
import type { Time24 } from 'app/core/persistence';
import type { IAggregatedScheduleModel, ILightHoursModel, ISegmentModel } from '../../models';

@injectable()
export class LightHoursCalculationService {
    /**
     * Returns the amount of good light hours and low light hours
     * for an aggregated week schedule.
     *
     * @param aggregatedSchedule the aggregated schedule
     * @param lightStart when good light starts
     * @param lightEnd when good light ends
     */
    public getLightHours(
        aggregatedSchedule: IAggregatedScheduleModel,
        lightStart: Time24,
        lightEnd: Time24,
    ): ILightHoursModel {
        const start = lightStart.toNumber();
        const end = lightEnd.toNumber();

        const segments: ISegmentModel[] = [
            ...aggregatedSchedule.monday,
            ...aggregatedSchedule.tuesday,
            ...aggregatedSchedule.wednesday,
            ...aggregatedSchedule.thursday,
            ...aggregatedSchedule.friday,
            ...aggregatedSchedule.saturday,
            ...aggregatedSchedule.sunday,
        ];

        const result: ILightHoursModel = {
            goodLightHours: 0,
            lowLightHours: 0,
        };

        segments.forEach((segment: ISegmentModel) => {
            result.goodLightHours += this.getGoodLightHours(segment.start, segment.end, start, end);

            result.lowLightHours += this.getLowLightHours(segment.start, segment.end, start, end);
        });

        return result;
    }

    private getGoodLightHours(
        start: number,
        end: number,
        lightStart: number,
        lightEnd: number,
    ): number {
        if (!this.hasGoodLightHours(start, end, lightStart, lightEnd)) {
            return 0;
        }

        const hours = this.getHoursWithinLimit(start, end, lightStart, lightEnd);
        return hours === null ? 0 : hours;
    }

    private getLowLightHours(
        start: number,
        end: number,
        lightStart: number,
        lightEnd: number,
    ): number {
        if (!this.hasLowLightHours(start, end, lightStart, lightEnd)) {
            return 0;
        }

        const hours = this.getHoursWithinLimit(start, end, lightEnd, lightStart);
        return hours === null ? end - start : hours;
    }

    private hasGoodLightHours(
        start: number,
        end: number,
        lightStart: number,
        lightEnd: number,
    ): boolean {
        if (start === end || (start === 24 && end === 0) || (lightEnd === 0 && lightStart === 24)) {
            return false;
        }

        if (lightEnd >= lightStart) {
            if (lightStart < start && lightEnd < start) {
                return false;
            }
            if (lightStart > end && lightEnd > end) {
                return false;
            }
        }
        if (lightStart >= end && lightEnd <= start) {
            // Only dark hours
            return false;
        }

        return true;
    }

    private hasLowLightHours(
        start: number,
        end: number,
        lightStart: number,
        lightEnd: number,
    ): boolean {
        if ((start === 24 && end === 0) || (lightEnd === 24 && lightStart === 0)) {
            return false;
        }
        if (lightStart > lightEnd) {
            if (lightStart < start && lightEnd < start) {
                return false;
            }
            if (lightStart > end && lightEnd > end) {
                return false;
            }
        }

        if (lightEnd >= end && lightStart <= start) {
            // Only light hours
            return false;
        }

        return true;
    }

    private getHoursWithinLimit(
        start: number,
        end: number,
        earlyLimit: number,
        lateLimit: number,
    ): number | null {
        if (earlyLimit > lateLimit) {
            if (
                (earlyLimit <= start && lateLimit <= start) ||
                (earlyLimit >= end && lateLimit >= end)
            ) {
                // All hours
                return end - start;
            }
            return this.combineParts(start, end, earlyLimit, lateLimit);
        }

        if (earlyLimit < lateLimit) {
            if (earlyLimit <= start && lateLimit >= end) {
                // All hours
                return end - start;
            }
            return Math.min(end, lateLimit) - Math.max(start, earlyLimit);
        }

        return null;
    }

    private combineParts(
        start: number,
        end: number,
        earlyLimit: number,
        lateLimit: number,
    ): number {
        const part1 = earlyLimit < end ? end - earlyLimit : 0;
        const part2 = lateLimit > start ? lateLimit - start : 0;
        return part1 + part2;
    }
}
