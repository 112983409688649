import type { PiaCategory } from 'app/core/pia';
import {
    PiaItemWearablesCategory,
    PiaAccessoryCategory,
    PiaItemSoftwareCategory,
} from 'app/core/pia';

export enum DisplayCategory {
    AUDIOANDIO = 'audioandio',
    CONNECTIVITY = 'connectivity',
    CABINETS = 'cabinets',
    INSTALLTOOLS = 'installtools',
    MISCELLANEOUS = 'misc',
    JOYSTICKSCONTR = 'joystickscontr',
}

export const PIA_CATEGORY_TO_DISPLAY_CATEGORY: Record<string, DisplayCategory> = {
    [PiaAccessoryCategory.CABLESCON]: DisplayCategory.CONNECTIVITY,
    [PiaAccessoryCategory.ETHERNETOVERCOAX]: DisplayCategory.CONNECTIVITY,
    [PiaAccessoryCategory.MEDIACONVERTERS]: DisplayCategory.CONNECTIVITY,
    [PiaAccessoryCategory.SURGEPROTECTORS]: DisplayCategory.CONNECTIVITY,
    [PiaAccessoryCategory.POEEXTENDERS]: DisplayCategory.CONNECTIVITY,

    [PiaAccessoryCategory.AUDIOANDIO]: DisplayCategory.AUDIOANDIO,
    [PiaAccessoryCategory.AMPLIFIERS]: DisplayCategory.AUDIOANDIO,
    [PiaAccessoryCategory.BRIDGES]: DisplayCategory.AUDIOANDIO,
    [PiaAccessoryCategory.MICROPHONES]: DisplayCategory.AUDIOANDIO,
    [PiaAccessoryCategory.SERVERS]: DisplayCategory.AUDIOANDIO,
    [PiaItemWearablesCategory.DEVICE]: DisplayCategory.AUDIOANDIO,

    [PiaAccessoryCategory.CABINETS]: DisplayCategory.CABINETS,

    [PiaAccessoryCategory.JOYSTICKSCONTR]: DisplayCategory.JOYSTICKSCONTR,

    [PiaAccessoryCategory.MISCELLANEOUS]: DisplayCategory.MISCELLANEOUS,

    [PiaAccessoryCategory.INSTALLTOOLS]: DisplayCategory.INSTALLTOOLS,
    [PiaItemSoftwareCategory.AUDIO_MANAGEMENT_SOFTWARE]: DisplayCategory.AUDIOANDIO,
};

export const DISPLAY_CATEGORY_TO_PIA_CATEGORY: Record<string, PiaCategory> = {
    // NOT DisplayCategory.CONNECTIVITY and DisplayCategory.AUDIOANDIO
    [DisplayCategory.JOYSTICKSCONTR]: PiaAccessoryCategory.JOYSTICKSCONTR,
    [DisplayCategory.MISCELLANEOUS]: PiaAccessoryCategory.MISCELLANEOUS,
    [DisplayCategory.INSTALLTOOLS]: PiaAccessoryCategory.INSTALLTOOLS,
    [DisplayCategory.CABINETS]: PiaAccessoryCategory.CABINETS,
};
