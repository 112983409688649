import * as React from 'react';
import type { IHourSegment } from '../../models';
import { Box, Text } from 'app/components';
import { ColorsEnum } from 'app/styles';

export interface IQuarterTimeLineProps {
    dayText: string;
    hours: IHourSegment[];
}

const QUARTER_WIDTH = 3;
const HOUR_WIDTH = QUARTER_WIDTH * 4;
const TIMELINE_XOFFSET = 55;
const TIMELINE_YOFFSET = 15;
const TIME_TEXT_YPOSITION = 10;
const TIME_TEXT_XOFFSET = -15;
const HOUR_LINE_HEIGHT = 10;
const QUARTER_LINE_HIGHT = HOUR_LINE_HEIGHT * 0.6;
const QUARTER_LINE_YOFFSET = (HOUR_LINE_HEIGHT - QUARTER_LINE_HIGHT) / 2;
const SVG_WIDTH = 2 * TIMELINE_XOFFSET + 24 * HOUR_WIDTH + 2; // 2 is a buffert needed on some screens
const SVG_HEIGHT = TIMELINE_YOFFSET + HOUR_LINE_HEIGHT + 5;
const WEEKDAY_YOFFSET = TIMELINE_YOFFSET + HOUR_LINE_HEIGHT * 0.8;

export class QuarterTimeLineComponent extends React.Component<IQuarterTimeLineProps> {
    public render() {
        return <Box>{this.renderTimeLine()}</Box>;
    }

    private getXOffset(hour: number, quarter = 0): number {
        return hour * HOUR_WIDTH + quarter * QUARTER_WIDTH + TIMELINE_XOFFSET;
    }

    private renderTimeLine() {
        return (
            <svg
                width={SVG_WIDTH}
                height={SVG_HEIGHT}
                viewBox={`0.5 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
                xmlns="http://www.w3.org/2000/svg"
            >
                <Text onlyStyle bold>
                    <text x="0" y={WEEKDAY_YOFFSET}>
                        {this.props.dayText}
                    </text>
                </Text>
                {this.props.hours.map((hour, hourIdx) => {
                    return (
                        <React.Fragment key={hourIdx}>
                            {hour.quarters.map((quarter, quarterIdx) => {
                                const x = this.getXOffset(hourIdx, quarterIdx);
                                const color = quarter ? ColorsEnum.yellow : 'transparent';
                                return (
                                    <React.Fragment key={hourIdx + quarterIdx}>
                                        <rect
                                            x={x}
                                            y={QUARTER_LINE_YOFFSET + TIMELINE_YOFFSET}
                                            width={QUARTER_WIDTH}
                                            height={QUARTER_LINE_HIGHT}
                                            fill={color}
                                        />
                                        {quarterIdx === 0 ? (
                                            <line
                                                x1={x}
                                                y1={TIMELINE_YOFFSET}
                                                x2={x}
                                                y2={HOUR_LINE_HEIGHT + TIMELINE_YOFFSET}
                                                stroke={ColorsEnum.black}
                                                strokeWidth="1"
                                            />
                                        ) : (
                                            <line
                                                x1={x}
                                                y1={QUARTER_LINE_YOFFSET + TIMELINE_YOFFSET}
                                                x2={x}
                                                y2={
                                                    QUARTER_LINE_YOFFSET +
                                                    QUARTER_LINE_HIGHT +
                                                    TIMELINE_YOFFSET
                                                }
                                                stroke={ColorsEnum.yellow7}
                                                strokeWidth="1"
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
                <text x={this.getXOffset(0)} dx={TIME_TEXT_XOFFSET} y={TIME_TEXT_YPOSITION}>
                    00:00
                </text>
                <text x={this.getXOffset(6)} dx={TIME_TEXT_XOFFSET} y={TIME_TEXT_YPOSITION}>
                    06:00
                </text>
                <text x={this.getXOffset(12)} dx={TIME_TEXT_XOFFSET} y={TIME_TEXT_YPOSITION}>
                    12:00
                </text>
                <text x={this.getXOffset(18)} dx={TIME_TEXT_XOFFSET} y={TIME_TEXT_YPOSITION}>
                    18:00
                </text>
                <text x={this.getXOffset(24)} dx={TIME_TEXT_XOFFSET} y={TIME_TEXT_YPOSITION}>
                    24:00
                </text>
                <line
                    x1={this.getXOffset(24)}
                    y1={TIMELINE_YOFFSET}
                    x2={this.getXOffset(24)}
                    y2={HOUR_LINE_HEIGHT + TIMELINE_YOFFSET}
                    stroke={ColorsEnum.black}
                    width="1"
                />
            </svg>
        );
    }
}
