import { injectable } from 'inversify';
import { v4 as uuidv4 } from 'uuid';
import { AppSettings } from 'app/AppSettings';
import { KeyValueStore } from 'axis-webtools-util';
import { eventTracking } from 'app/core/tracking';
import { toErrorMessage } from '../utils';

@injectable()
export class LocalImageService {
    private localFloorPlansStore: KeyValueStore<Blob>;

    constructor(appSettings: AppSettings) {
        this.localFloorPlansStore = new KeyValueStore<Blob>(
            appSettings.localFloorPlansDbName,
            appSettings.localFloorPlansStoreName,
        );
    }

    public async addLocalImage(image: Blob): Promise<string> {
        // Create a uniqe key
        const key = uuidv4();
        try {
            await this.localFloorPlansStore.setItem(key, image);
        } catch (error) {
            eventTracking.logError(
                `Storing floorplan in IndexedDB failed ${toErrorMessage(error)}`,
                'LocalImageService',
            );
            throw error;
        }
        return key;
    }

    public async copyLocalImage(key: string) {
        const originalBlob = await this.getLocalImageAsBlob(key);
        return this.addLocalImage(originalBlob);
    }

    public async getLocalImageUrlAsBase64(key: string) {
        const blob = await this.getLocalImageAsBlob(key);
        if (!blob) return '';

        const base64Image = await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(blob);
        });

        return base64Image as string;
    }

    public getLocalImageAsBlob(key: string) {
        return this.localFloorPlansStore.getItem(key);
    }

    public async deleteLocalImage(key: string, throwOnError = true): Promise<void> {
        try {
            await this.localFloorPlansStore.removeItem(key);
        } catch (error) {
            eventTracking.logError(
                `Failed to remove image ${toErrorMessage(error)}`,
                'LocalImageService',
            );
            if (throwOnError) {
                throw error;
            }
        }
    }
}
