export interface IJwtPayload {
    hid: string;
    firstName: string;
    lastName: string;
    email: string;
    expiresIn: number;
}

const base64urlUnescape = (str: string): string => {
    str += new Array(5 - (str.length % 4)).join('=');
    return str.replace(/-/g, '+').replace(/_/g, '/');
};

export const parseJwt = (jwt: string): IJwtPayload | undefined => {
    try {
        const base64URI = jwt.split('.')[1];
        const payload = Buffer.from(base64urlUnescape(base64URI), 'base64').toString();

        // https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
        const { sub, given_name, family_name, email, exp } = JSON.parse(payload);

        return {
            hid: sub,
            firstName: given_name,
            lastName: family_name,
            email: email,
            expiresIn: exp,
        };
    } catch {
        return undefined;
    }
};
