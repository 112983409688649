import * as React from 'react';
import type { IStoreState } from 'app/store';
import { Box, Print, Stack, Text } from 'app/components';
import { connect } from 'react-redux';
import { t } from 'app/translate';
import { Headline } from './Headline.component';
import { ProposalRecordingItem } from './ProposalRecordingItem.component';
import { getCurrentProjectNotes } from 'app/modules/common';
import { getRecordingSolutionSpecsToDisplay, getSystemRequirementsToDisplay } from '../selectors';
import type { IDataToDisplay } from '../../models';
import { ReportHeader } from '../../components/ReportHeader.container';

interface IProposalRecordingSolutionProps {
    recordingStatsToDisplay: IDataToDisplay[];
    systemPropertiesToDisplay: IDataToDisplay[];

    notes: string;
}

const mapStateToProps = (storeState: IStoreState): IProposalRecordingSolutionProps => {
    return {
        recordingStatsToDisplay: getRecordingSolutionSpecsToDisplay(storeState),
        systemPropertiesToDisplay: getSystemRequirementsToDisplay(storeState),
        notes: getCurrentProjectNotes(storeState),
    };
};

const ProposalRecordingSolutionContainer: React.FunctionComponent<
    IProposalRecordingSolutionProps
> = ({ systemPropertiesToDisplay, recordingStatsToDisplay, notes }) => {
    return (
        <React.Fragment>
            <Box paddingTop="doublePanel" display="block" justifyContent="center" pageBreakBefore>
                <Print display="flex">
                    <ReportHeader title={t.systemProposal} />
                </Print>
                <Headline text={t.proposalSummaryCategoriesGROUP.recordingSolution} />
                <Box wrap direction="row" paddingY="panel" justifyContent="start">
                    <Box paddingRight="panel" paddingY="base">
                        <ProposalRecordingItem
                            title={t.proposalSummaryCategoriesGROUP.systemProperties}
                            backgroundColor="warmGrey4"
                            items={systemPropertiesToDisplay}
                            testId="system_properties_container"
                        />
                    </Box>
                    <Box paddingY="base">
                        <ProposalRecordingItem
                            title={t.proposalSummaryCategoriesGROUP.recordingSolutionSpec}
                            titleColor="yellowText"
                            backgroundColor="yellow"
                            items={recordingStatsToDisplay}
                            testId="recording_solution_container"
                        />
                    </Box>
                </Box>
                {notes && (
                    <Stack vertical spacing="base">
                        <Headline text={t.notes} />

                        <Box paddingTop="base">
                            <Text testId="note_system_proposal_page" whiteSpace="pre-wrap">
                                {notes}
                            </Text>
                        </Box>
                    </Stack>
                )}
            </Box>
        </React.Fragment>
    );
};

export const ProposalRecordingSolution = connect(mapStateToProps)(
    ProposalRecordingSolutionContainer,
);

ProposalRecordingSolution.displayName = 'ProposalRecordingSolution';
