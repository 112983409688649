import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Badge, Box, Clickable, LinkIconButton, PiaImage, Stack, Text } from 'app/components';
import type { PiaId } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import { deviceIcons, getPiaItemForProductId } from 'app/modules/common';
import { t } from 'app/translate';
import { useResponsive } from 'app/hooks';

interface ISelectableDevice {
    productId: PiaId;
    onClick?(productId: PiaId): void;
}

export const SelectableDevice: React.FunctionComponent<ISelectableDevice> = ({
    productId,
    onClick,
}) => {
    const piaItem = useSelector((state: IStoreState) => getPiaItemForProductId(state, productId));
    const isMobile = useResponsive(false, true, false);
    if (!piaItem) {
        return null;
    }
    const discontinued = isDiscontinued(piaItem);

    const onSelect = () => onClick && onClick(piaItem.id);
    return (
        <Clickable onClick={onSelect}>
            <Box padding="half" width="100%">
                <Stack justifyContent="between" flex="fullWidth" alignItems="center">
                    <Stack>
                        <PiaImage
                            piaId={piaItem.id}
                            icon={deviceIcons.categoryToIcon(piaItem.category)}
                        />
                        <Text testId={piaItem.name}>{piaItem.name}</Text>
                    </Stack>
                    {discontinued ? (
                        <Badge type="red" label={t.discontinued} />
                    ) : (
                        <LinkIconButton
                            icon="description"
                            text={isMobile ? undefined : t.visitProductPage}
                            external
                            link={`https://www.axis.com/find/product/${piaItem.parentId}`}
                        />
                    )}
                </Stack>
            </Box>
        </Clickable>
    );
};
