import { css, cx } from '@emotion/css';
import type { IMinMax } from 'app/core/persistence';
import { ColorsEnum } from 'app/styles';
import * as React from 'react';
import { EditableText } from '../editableText/EditableText';
import type { ILabeledMinMax } from './models/ILabeledMinMax';

const thumbDiameter = 18;
const labelMaxLength = 15;

const thumbLabelStyle = css`
    position: absolute;
    background-color: ${ColorsEnum.yellow1};
    color: ${ColorsEnum.yellowText};
    border-radius: 4px;
    font-size: 10px;
    border: 1px solid ${ColorsEnum.grey3};
    padding: 1px 2px;
    top: -18px;
`;

const labelsContainerStyle = css`
    position: relative;
    margin: 0 ${thumbDiameter / 2}px;
`;

interface IIPRangeLabelsProps {
    fullRange: IMinMax;
    inputRange: ILabeledMinMax;
    onLabelsChange?(minValue: string, maxValue: string): void;
}

export const MultiRangeLabels: React.FC<IIPRangeLabelsProps> = ({
    fullRange,
    inputRange,
    onLabelsChange,
}) => {
    /** Reference to DOM element for the full slider range */
    const fullRangeRef = React.useRef<HTMLDivElement>(null);
    /** Reference to DOM element for min label */
    const minLabelRef = React.useRef<HTMLDivElement>(null);
    /** Reference to DOM element for max label */
    const maxLabelRef = React.useRef<HTMLDivElement>(null);

    const minLabelWidth = minLabelRef.current?.clientWidth ?? 0;
    const maxLabelWidth = maxLabelRef.current?.clientWidth ?? 0;

    /** Width of the entire slider range */
    const fullWidth = fullRangeRef.current?.clientWidth ?? 0;

    /** Percentage position of min value on range */
    const left = ((inputRange.min - fullRange.min) / (fullRange.max - fullRange.min)) * 100;
    /** Percentage position of max value on range */
    const right = ((inputRange.max - fullRange.min) / (fullRange.max - fullRange.min)) * 100;

    /** Represents position in pixels where the min label ends counted from start of project range */
    const minLabelRightEdge = Math.max(
        minLabelWidth - 7,
        (left / 100) * fullWidth + minLabelWidth / 2 + 1,
    );

    /** Represents position in pixels where the max label begins counted from start of project range */
    const maxLabelLeftEdge = Math.min(
        fullWidth - maxLabelWidth + 6,
        (right / 100) * fullWidth - maxLabelWidth / 2 - 1,
    );

    /** Distance in pixels that labels must be adjusted by to not overlap */
    const overlapLength = Math.max(0, minLabelRightEdge - maxLabelLeftEdge);

    /** Sets position for min label */
    const minLabelStyle = css`
        inset-inline-start: max(
            -${thumbDiameter / 2}px - ${overlapLength / 2}px,
            calc(${left}% - ${minLabelWidth / 2}px - 1px - ${overlapLength / 2}px)
        );
    `;
    /** Sets position for max label */
    const maxLabelStyle = css`
        inset-inline-end: max(
            -${thumbDiameter / 2 - 2}px - ${overlapLength / 2}px,
            calc(100% - ${right}% - ${maxLabelWidth / 2}px - ${overlapLength / 2}px)
        );
    `;

    return (
        <div className={labelsContainerStyle} ref={fullRangeRef}>
            {inputRange.minLabel && (
                <div ref={minLabelRef} className={cx([thumbLabelStyle, minLabelStyle])}>
                    {onLabelsChange ? (
                        <EditableText
                            autoFillToContainer={false}
                            changeCriteria="blur"
                            maxLength={labelMaxLength}
                            value={inputRange.minLabel}
                            placeholder=""
                            forceUpdateOnBlur
                            onChange={(value) => onLabelsChange(value, inputRange.maxLabel ?? '')}
                        />
                    ) : (
                        inputRange.minLabel
                    )}
                </div>
            )}
            {inputRange.maxLabel && (
                <div ref={maxLabelRef} className={cx([thumbLabelStyle, maxLabelStyle])}>
                    {onLabelsChange ? (
                        <EditableText
                            autoFillToContainer={false}
                            changeCriteria="blur"
                            maxLength={labelMaxLength}
                            value={inputRange.maxLabel}
                            placeholder=""
                            forceUpdateOnBlur
                            onChange={(value) => onLabelsChange(inputRange.minLabel ?? '', value)}
                        />
                    ) : (
                        inputRange.maxLabel
                    )}
                </div>
            )}
        </div>
    );
};

MultiRangeLabels.displayName = 'MultiRangeLabels';
