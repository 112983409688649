/* eslint-disable  @typescript-eslint/no-duplicate-enum-values */
export enum ColorsEnum {
    inherit = 'inherit',
    transparent = 'transparent',
    white = '#ffffff',
    whiteOpacity = 'rgba(255,255,255,0.85)',
    black = '#000000',
    blackOpacity = 'rgba(0, 0, 0, 0.1)',
    yellow = '#ffcc33',
    yellowDark = '#f8ac00',
    blue = '#009ddc',
    red = '#b5121b',
    green = '#8dc63f',
    grey1 = '#fafafa',
    grey2 = '#f4f4f4',
    grey3 = '#e6e6e6',
    grey4 = '#cdcdcd',
    grey5 = '#b2b2b2',
    grey6 = '#8c8c8c',
    grey7 = '#666666',
    grey8 = '#4d4d4d',
    grey9 = '#373737',
    warmGrey1 = '#FAF8F6',
    warmGrey3 = '#E6E3E0',
    warmGrey4 = '#D8CFC6',
    warmGrey6 = '#9A8B7D',
    yellow1 = '#fff8e3',
    yellow2 = '#ffe9a9',
    yellow3 = '#ffdb6e',
    yellow4 = '#ffcc33',
    yellow5 = '#f0b400',
    yellow6 = '#d6a100',
    yellow7 = '#bc8d00',
    yellowText = '#4e3b00',
    blue0 = '#e7f8ff',
    blue1 = '#b0e9ff',
    blue2 = '#78d9ff',
    blue3 = '#3dc8ff',
    blue4 = '#009ddc',
    blue5 = '#0082b5',
    blue6 = '#00668f',
    blue7 = '#004966',
    blue4Light = '#00a9ec',
    blue4Dark = '#0097d3',
    devicePalette1 = '#b5121b',
    devicePalette2 = '#ff0033',
    devicePalette3 = '#ffcc33',
    devicePalette4 = '#d6e03d',
    devicePalette5 = '#8dc63f',
    devicePalette6 = '#72cdf4',
    devicePalette7 = '#009ddc',
    devicePalette8 = '#812990',
    devicePalette9 = '#d60c8c',
}
/* eslint-enable  @typescript-eslint/no-duplicate-enum-values */

export type Colors = keyof typeof ColorsEnum;
