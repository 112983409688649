import { eventTracking } from 'app/core/tracking';

export class Resolution {
    private horizontal: number;
    private vertical: number;

    /**
     * Creates a new Resolution using a string using the format:
     * <horizontal>x<vertical>
     *
     * e.g 1280x720
     *
     * @param resolution the string.
     */
    constructor(resolution: string);

    /**
     * Creates a new Resolution using horizontal and vertical pixels.
     *
     * @param horizontal the horizontal pixels
     * @param vertical the vertical pixels
     */
    constructor(horizontal: number, vertical: number);

    constructor(value: string | number, vertical?: number) {
        if (typeof value === 'string') {
            const valueSplit = value.split('x');

            if (valueSplit.length !== 2) {
                throw new Error(`Resolution invoked with invalid string: ${value}`);
            }

            this.horizontal = Number(valueSplit[0]);
            this.vertical = Number(valueSplit[1]);
        } else if (typeof value === 'number') {
            this.horizontal = value;

            if (vertical == null) {
                throw new Error(
                    `Resolution must be invoked with vertical pixels if horizontal pixels is set`,
                );
            }

            this.vertical = vertical;
        } else {
            this.horizontal = 0;
            this.vertical = 0;
            eventTracking.logError(
                `Resolution invoked with wrong type: ${typeof value}`,
                'Persistence',
            );
        }

        if (this.horizontal == null || Number.isNaN(this.horizontal)) {
            throw new Error(`Resolution invoked with invalid horizontal pixels`);
        }

        if (this.vertical == null || Number.isNaN(this.vertical)) {
            throw new Error(`Resolution invoked with invalid vertical pixels`);
        }
    }

    /**
     * Gets the amount of horizontal pixels
     */
    public getHorizontal(): number {
        return this.horizontal;
    }

    /**
     * Gets the amount of vertical pixels
     */
    public getVertical(): number {
        return this.vertical;
    }

    /**
     * Gets the total amount of pixels
     */
    public getPixels(): number {
        return this.horizontal * this.vertical;
    }

    public toString(): string {
        return this.toPersistable();
    }

    public toPersistable(): string {
        return `${this.horizontal}x${this.vertical}`;
    }

    public fitInside(resolution: Resolution) {
        return (
            this.getHorizontal() <= resolution.getHorizontal() &&
            this.getVertical() <= resolution.getVertical()
        );
    }

    public resolutionLower(resolution: Resolution) {
        return (
            this.getHorizontal() < resolution.getHorizontal() &&
            this.getVertical() < resolution.getVertical()
        );
    }
}

export class NoResolution extends Resolution {
    constructor() {
        super(0, 0);
    }
}
