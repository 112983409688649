import * as React from 'react';
import { t } from 'app/translate';
import { Box, Tab, Button, Border, Positioned } from 'app/components';
import { ServiceLocator, useService } from 'app/ioc';
import { RecordingSelectorActionService } from '../services';
import { CustomizeSolution } from './CustomizeSolution.component';
import type { IStoreState } from 'app/store';
import { getRecordingFilter } from '../selectors/getRecordingDevices';
import { useSelector } from 'react-redux';
import { eventTracking } from 'app/core/tracking';
import { CommonActionService } from 'app/modules/common';
import { SolutionsSection } from './SolutionsSection';
import type { RecordingCategoriesFilter } from 'app/core/persistence';
import { UserPreferencesService } from 'app/core/persistence';

export const CustomizeSolutionTabs: React.FunctionComponent = () => {
    const userPreferencesService = useService(UserPreferencesService);

    const selectedCategory = useSelector<IStoreState, RecordingCategoriesFilter>(
        getRecordingFilter,
    );

    const [actionService] = React.useState<RecordingSelectorActionService>(
        ServiceLocator.get(RecordingSelectorActionService),
    );

    const [commonActionService] = React.useState<CommonActionService>(
        ServiceLocator.get(CommonActionService),
    );

    const close = () => {
        commonActionService.showOverlayPanel(false);
    };

    const [selectedTab, setSelectedTab] = React.useState<RecordingCategoriesFilter>(
        userPreferencesService.get().customizeSolutionRecordingCategory || selectedCategory,
    );

    const filterProducts = React.useCallback(
        (category: RecordingCategoriesFilter, onMount?: boolean) => {
            actionService.filterProductSelector(category);

            // We shouldn't track user event when filterProducts is triggered upon mounting.
            if (!onMount) {
                eventTracking.logUserEvent(
                    'System Components',
                    'Filter customize solutions',
                    category,
                );
                userPreferencesService.set({ customizeSolutionRecordingCategory: category });
                setSelectedTab(category);
            }
        },
        [actionService, setSelectedTab, userPreferencesService],
    );

    React.useEffect(() => {
        filterProducts(selectedTab, true);
    }, [filterProducts, selectedTab]);

    return (
        <Box height="100%" direction="column" overflowX="hidden" overflowY="auto">
            <SolutionsSection />

            <Border width={0} shadow="rgb(0 0 0 / 5%) 0px -5px 6px">
                <Box direction="column">
                    <Border bottomWidth={1} color="grey3">
                        <Positioned position="sticky" top={0} sendForward>
                            <Box width="100%" justifyContent="between" color="grey1">
                                <Box>
                                    <Tab
                                        activeColor="grey1"
                                        testId={'customize_solution_tab_servers'}
                                        text={t.servers}
                                        isActive={selectedTab === 'servers'}
                                        onClick={() => filterProducts('servers')}
                                    />
                                    <Tab
                                        activeColor="grey1"
                                        text={t.terminals}
                                        isActive={selectedTab === 'terminals'}
                                        onClick={() => filterProducts('terminals')}
                                    />
                                    <Tab
                                        activeColor="grey1"
                                        text={t.switches}
                                        isActive={selectedTab === 'switches'}
                                        onClick={() => filterProducts('switches')}
                                    />
                                    <Tab
                                        activeColor="grey1"
                                        testId={'customize_solution_tab_licenses'}
                                        text={t.licenses}
                                        isActive={selectedTab === 'licenses'}
                                        onClick={() => filterProducts('licenses')}
                                    />
                                    <Tab
                                        activeColor="grey1"
                                        testId={'customize_solution_tab_all'}
                                        text={t.all}
                                        isActive={selectedTab === 'all'}
                                        onClick={() => filterProducts('all')}
                                    />
                                </Box>
                                <Button
                                    testId="tab_btn_close_recorder_custom_solution_panel"
                                    text
                                    onClick={close}
                                    key={t.close}
                                >
                                    {t.close}
                                </Button>
                            </Box>
                        </Positioned>
                    </Border>

                    <Box
                        direction="column"
                        paddingX="base"
                        paddingTop="base"
                        paddingBottom="doublePanel"
                        position="relative"
                        display="tableCell"
                    >
                        <CustomizeSolution />
                    </Box>
                </Box>
            </Border>
        </Box>
    );
};

CustomizeSolutionTabs.displayName = 'CustomizeSolutionTabs';
