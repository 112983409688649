import * as React from 'react';
import { trigonometry } from 'axis-webtools-util';
import { clamp } from 'lodash-es';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import {
    getBestReplacementLens,
    getDeviceGroup,
    getEditDeviceId,
    getSelectedCamera,
    getSelectedSensorUnit,
} from '../../../selectors';
import type { PanoramaModes, IItemEntity, IPersistence } from 'app/core/persistence';
import { isDoorStation, isCustomCamera } from 'app/core/persistence';

import type { IDesiredCamera } from 'app/modules/common';
import {
    calculateVerticalFov,
    getCurrentProjectUnitSystem,
    estimateVerticalFOV,
    getCurrentProjectItem,
    getPiaCamera,
    getPiaSensorUnit,
    isMultiDirectional,
    isTiltableMultisensor,
    getSelectedDeviceChosenLens,
    getPiaItemForProductId,
    Scene3d,
    calculateDistanceToTarget,
    getVirtualRelatedItems,
} from 'app/modules/common';
import type {
    IPiaCamera,
    IPiaCameraProperties,
    IPiaDevice,
    IPiaItem,
    IPiaSensorUnit,
} from 'app/core/pia';
import {
    getFovRange,
    getSelectedPanoramaMode,
    getVideoResolution,
    canChangePanoramaMode,
    getCustomCameraVerticalFov,
} from './util';

interface IDeviceSelector3dViewProps {
    desiredFilter: IDesiredCamera;
    onChangeInstallationHeight(newHeight: number): void;
    onChangeTargetDistance(newDistance: number): void;
    onChangePanoramaMode(panoramaMode: PanoramaModes): void;
    onExpand?(): void;
}

export const DeviceSelector3dView: React.FunctionComponent<IDeviceSelector3dViewProps> = ({
    desiredFilter,
    onChangeTargetDistance,
    onChangeInstallationHeight,
    onChangePanoramaMode,
    onExpand,
}) => {
    const isCamera = useSelector((state: IStoreState) => getDeviceGroup(state)) === 'cameras';
    const selectedPiaDevice = useSelector((state: IStoreState) => {
        return isCamera ? getSelectedCamera(state) : getSelectedSensorUnit(state);
    });

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItemForProductId(state, selectedPiaDevice?.id ?? null),
    );
    const isTiltable = !isDoorStation(piaItem);

    const editDevice = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) => {
        const editDeviceId = getEditDeviceId(state) ?? undefined;
        return getCurrentProjectItem(state, editDeviceId);
    });

    const editPiaDevice = useSelector<IStoreState, IPiaCamera | IPiaSensorUnit | null>((state) => {
        return isCamera
            ? getPiaCamera(state, editDevice?._id)
            : getPiaSensorUnit(state, editDevice?._id);
    });

    const virtualRelatedItems = useSelector<IStoreState, IPiaDevice[]>((state) =>
        getVirtualRelatedItems(state, editPiaDevice?.id || selectedPiaDevice?.id || null),
    );

    const totalNbrOfVirtualSensors = virtualRelatedItems.reduce((sensors, virtualPiaItem) => {
        const virtualItemProperties = virtualPiaItem.properties as IPiaCameraProperties;
        const virtualHasMultipleSensors =
            virtualItemProperties.panCameraType === 'Multidirectional';
        const virtualImageSensors = virtualItemProperties.imageSensors ?? 1;
        const virtualNbrImageSensor = virtualHasMultipleSensors ? virtualImageSensors : 1;
        return sensors + virtualNbrImageSensor;
    }, 0);

    const corridorFormatSupported = (selectedPiaDevice || editPiaDevice)?.properties.corridorFormat;

    const unitSystem = useSelector((state: IStoreState) => getCurrentProjectUnitSystem(state));
    const selectedCamera = selectedPiaDevice
        ? selectedPiaDevice
        : editPiaDevice
          ? editPiaDevice
          : undefined;

    const selectedProductId = useSelector(
        (state: IStoreState) => state.deviceSelector.selectedProductId,
    );

    const lensRelations = useSelector((state: IStoreState) => {
        const editDeviceId = getEditDeviceId(state);
        //* When editing a device check for its current lens if no other product has been selected.
        //* Otherwise check if best replacement lens is used to pass filter settings
        const selectedLens =
            editDeviceId && selectedProductId === null
                ? getSelectedDeviceChosenLens(state, editDeviceId)
                : getBestReplacementLens(state, selectedCamera?.id ?? null);
        return (selectedCamera?.relations ?? []).find(
            (relation) => relation.id === selectedLens?.id,
        );
    });

    if (!desiredFilter) return null;

    const {
        installationHeight,
        targetHeight,
        distanceToTarget,
        pixelDensity,
        horizontalFOVRadians,
        corridorFormat,
        panoramaMode,
    } = desiredFilter;

    const selectedPanoramaMode = !isTiltable
        ? 'vertical'
        : getSelectedPanoramaMode(selectedCamera, panoramaMode, horizontalFOVRadians);

    const desiredHorizontalFov =
        selectedPanoramaMode !== false && !isTiltableMultisensor(selectedCamera ?? null)
            ? Math.PI
            : Math.min(horizontalFOVRadians, Math.PI - 0.01);

    const desiredVerticalFov = trigonometry.toRadians(
        estimateVerticalFOV(trigonometry.toDegrees(desiredHorizontalFov)),
    );

    const panoramaSelectorVisible = canChangePanoramaMode(selectedCamera, horizontalFOVRadians);

    const { minHorizontalFovRad, maxHorizontalFovRad, minVerticalFovRad, maxVerticalFovRad } =
        getFovRange(selectedCamera, selectedPanoramaMode, lensRelations);

    // figure out the horizontal FOV of the selected camera
    const selectedHorizontalFov = clamp(
        desiredHorizontalFov,
        minHorizontalFovRad ?? 0,
        maxHorizontalFovRad ?? Math.PI,
    );

    // figure out the vertical FOV of the selected camera
    const selectedVerticalFov = isCustomCamera(editDevice)
        ? getCustomCameraVerticalFov(editDevice, selectedHorizontalFov)
        : calculateVerticalFov(
              selectedHorizontalFov,
              maxHorizontalFovRad,
              minHorizontalFovRad,
              maxVerticalFovRad,
              minVerticalFovRad,
          );

    const sensorCount = (selectedCamera?.properties.imageSensors ?? 0) + totalNbrOfVirtualSensors;
    const sensorInfo =
        isMultiDirectional(selectedCamera ?? null) || totalNbrOfVirtualSensors > 0
            ? t.cameraSelectorMultiChannelMessage(sensorCount)
            : undefined;

    const distance = calculateDistanceToTarget(installationHeight, distanceToTarget, targetHeight);
    const horizontalResolution = getVideoResolution(selectedCamera);
    const resolutionLimit = horizontalResolution
        ? horizontalResolution / (selectedHorizontalFov * pixelDensity)
        : distance;

    // flip fovs if corridor format selected
    const actualDesiredHorizontalFov = corridorFormat ? desiredVerticalFov : desiredHorizontalFov;
    const actualDesiredVerticalFov = corridorFormat ? desiredHorizontalFov : desiredVerticalFov;
    const actualSelectedHorizontalFov =
        corridorFormatSupported && corridorFormat ? selectedVerticalFov : selectedHorizontalFov;
    const actualSelectedVerticalFov =
        corridorFormatSupported && corridorFormat ? selectedHorizontalFov : selectedVerticalFov;

    return (
        <Scene3d
            cameraHeight={installationHeight}
            targetHeight={targetHeight}
            targetDistance={distanceToTarget}
            resolutionLimit={resolutionLimit}
            desiredHorizontalFov={actualDesiredHorizontalFov}
            desiredVerticalFov={actualDesiredVerticalFov}
            selectedHorizontalFov={actualSelectedHorizontalFov}
            selectedVerticalFov={actualSelectedVerticalFov}
            unitSystem={unitSystem}
            panoramaMode={selectedPanoramaMode}
            tiltable={isTiltable}
            sensorInfo={sensorInfo}
            floorPlans={[]}
            onChangeInstallationHeight={onChangeInstallationHeight}
            onChangeTargetDistance={onChangeTargetDistance}
            onChangePanoramaMode={panoramaSelectorVisible ? onChangePanoramaMode : undefined}
            onExpand={onExpand}
        />
    );
};

DeviceSelector3dView.displayName = 'DeviceSelector3dView';
