import * as React from 'react';
import type {
    Id,
    IZipstreamSettingsModel,
    FpsMode,
    IRecordingSettingsEntity,
    BandwidthVersion,
    ZipProfile,
} from 'app/core/persistence';
import { GopMode } from 'app/core/persistence';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IProfileSupport } from 'app/modules/common';
import {
    getScheduleWithId,
    getUseAverageBitrate,
    getCurrentProjectProjectZipSetting,
    getCurrentProjectBandwidthVersion,
    ZipstreamSettingsComponent,
    getProfileOverride,
    getMergedProfile,
    getDeviceProfileSupport,
    ProfileOverrideService,
    getDynamicFpsMode,
    getGopMode,
    getZipStrengthValue,
} from 'app/modules/common';

import { ServiceLocator } from 'app/ioc';

interface IDeviceZipstreamSettingsOwnProps {
    deactivate?: boolean;
    deviceId: Id;
}

export interface IDeviceZipstreamSettingsProps {
    deviceId: Id;
    projectGlobalZipStreamValue: number;
    deactivate?: boolean;
    mergedZipstreamSettings: IZipstreamSettingsModel | undefined;
    zipstreamSettingsOverride: Partial<IZipstreamSettingsModel>;
    projectBandwidthVersion: BandwidthVersion;
    profileSupport?: IProfileSupport;
    continuousRecordingFps?: number;
    useAverageBitrateContinuous?: boolean;
    continuousRecordingOverride?: Partial<IRecordingSettingsEntity>;
    continuousAlwaysScheduleUsed: boolean;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceZipstreamSettingsOwnProps,
): IDeviceZipstreamSettingsProps => {
    const profileOverride = getProfileOverride(state, ownProps.deviceId);
    const mergedProfile = getMergedProfile(state, ownProps.deviceId);
    const continuousRecordingFps = mergedProfile?.continuousRecording.frameRate;
    const scheduleIdUsed = mergedProfile?.continuousRecording.schedule;
    // for now we only have one systemDefined schedule which is 'Always'
    const continuousAlwaysScheduleUsed = scheduleIdUsed
        ? getScheduleWithId(state, scheduleIdUsed)?.systemDefined === true
        : false;

    return {
        deviceId: ownProps.deviceId,
        projectGlobalZipStreamValue: getCurrentProjectProjectZipSetting(state),
        mergedZipstreamSettings: mergedProfile?.zipstream ?? undefined,
        zipstreamSettingsOverride: profileOverride?.zipstream ?? {},
        profileSupport: getDeviceProfileSupport(state, ownProps.deviceId),
        deactivate: ownProps.deactivate,
        projectBandwidthVersion: getCurrentProjectBandwidthVersion(state),
        continuousRecordingFps,
        useAverageBitrateContinuous: mergedProfile?.continuousRecording.useAverageBitrate,
        continuousRecordingOverride: profileOverride?.continuousRecording,
        continuousAlwaysScheduleUsed,
    };
};

class DeviceZipstreamSettingsContainer extends React.Component<IDeviceZipstreamSettingsProps> {
    private profileOverrideService: ProfileOverrideService;

    constructor(props: IDeviceZipstreamSettingsProps) {
        super(props);
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public render() {
        if (!this.props.mergedZipstreamSettings) {
            return null;
        }
        const useProjectSetting = this.props.mergedZipstreamSettings.useProjectSetting;
        const zipStrength = getZipStrengthValue(
            this.props.mergedZipstreamSettings,
            this.props.projectGlobalZipStreamValue,
        );

        const fpsMode = getDynamicFpsMode(
            this.props.mergedZipstreamSettings,
            this.props.projectGlobalZipStreamValue,
        );

        const dynamicGop = getGopMode(
            this.props.mergedZipstreamSettings,
            this.props.projectGlobalZipStreamValue,
        );

        const useAverageBitrate = getUseAverageBitrate(
            this.props.continuousAlwaysScheduleUsed,
            this.props.mergedZipstreamSettings,
            this.props.projectGlobalZipStreamValue,
            this.props.useAverageBitrateContinuous,
        );

        return (
            <ZipstreamSettingsComponent
                dynamicGop={dynamicGop === GopMode.Dynamic}
                dynamicGopOverridden={this.props.zipstreamSettingsOverride.gopMode !== undefined}
                zipStrength={zipStrength}
                zipStrengthOverridden={
                    this.props.zipstreamSettingsOverride.zipStrength !== undefined
                }
                zipProfile={this.props.mergedZipstreamSettings.zipProfile}
                zipProfileOverridden={this.props.zipstreamSettingsOverride.zipProfile !== undefined}
                fpsMode={fpsMode}
                fpsModeOverridden={this.props.zipstreamSettingsOverride.fpsMode !== undefined}
                useProjectSetting={useProjectSetting}
                projectBandwidthVersion={this.props.projectBandwidthVersion}
                profileSupport={this.props.profileSupport}
                onDynamicGopChanged={this.onDynamicGopChanged}
                onZipStrengthChanged={this.onZipStrengthChanged}
                onZipProfileChanged={this.onZipProfileChanged}
                onFpsModeChanged={this.onFpsModeChanged}
                onUseProjectSettingChanged={this.onUseProjectSettingChanged}
                deactivate={this.props.deactivate}
                minDynamicFps={this.props.mergedZipstreamSettings.minDynamicFps}
                minDynamicFpsOverridden={
                    this.props.zipstreamSettingsOverride.minDynamicFps !== undefined
                }
                onMinDynamicFpsChanged={this.onMinDynamicFpsChanged}
                continuousRecordingFps={this.props.continuousRecordingFps}
                useAverageBitrateContinuous={useAverageBitrate}
                onUseAverageBitrateChanged={this.onUseAverageBitrateChanged}
                useAverageBitrateOverridden={
                    this.props.continuousRecordingOverride?.useAverageBitrate !== undefined
                }
                continuousAlwaysScheduleUsed={this.props.continuousAlwaysScheduleUsed}
            />
        );
    }

    private onFpsModeChanged = (fpsMode: FpsMode) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            zipstream: {
                fpsMode,
            },
        });
    };

    private onDynamicGopChanged = (dynamicGopEnabled: boolean) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            zipstream: {
                gopMode: dynamicGopEnabled ? GopMode.Dynamic : GopMode.Fixed,
            },
        });
    };

    private onZipStrengthChanged = (newZipStrength: number) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            zipstream: {
                zipStrength: newZipStrength,
            },
        });
    };

    private onZipProfileChanged = (newZipProfile: ZipProfile) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            zipstream: {
                zipProfile: newZipProfile,
            },
        });
    };

    private onMinDynamicFpsChanged = (newMinFps: number) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            zipstream: {
                minDynamicFps: newMinFps,
            },
        });
    };

    private onUseProjectSettingChanged = (newUseProjectSetting: boolean) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            zipstream: {
                useProjectSetting: newUseProjectSetting,
            },
        });
    };

    private onUseAverageBitrateChanged = (newUseAverageBitrate: boolean) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            ['continuousRecording']: {
                ...this.props.continuousRecordingOverride,
                useAverageBitrate: newUseAverageBitrate === true ? newUseAverageBitrate : undefined,
            },
        });
    };
}

export const DeviceZipstreamSettings = connect(mapStateToProps)(DeviceZipstreamSettingsContainer);
