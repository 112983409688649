import { createSelector } from 'reselect';
import { getPiaLocationOptionsSortedByName } from './getPiaLocations';
import { t } from 'i18next';

export const getTipPiaLocationOptions = createSelector(
    [getPiaLocationOptionsSortedByName],
    (piaLocations) => [
        { text: t('anyCountry', { ns: 'partnerProducts' }), value: 'anyCountry' },
        ...piaLocations,
    ],
);
