import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Box, SectionHeader, Stack } from 'app/components';
import { t } from 'app/translate';
import { getInstallationReportSchedules } from '../../selectors/';
import type { DaySegments, IInstallationReportSchedule } from '../../models';
import type { IAggregatedScheduleModel } from 'app/modules/common';
import { QuarterTimeLineComponent } from './QuarterTimeLine.component';

interface ISchedulesOwnProps {
    hideHeader?: boolean;
    schedules?: IInstallationReportSchedule[];
}

interface ISchedulesProps extends ISchedulesOwnProps {
    schedules: IInstallationReportSchedule[];
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ISchedulesOwnProps,
): ISchedulesProps => {
    return {
        ...ownProps,
        schedules: ownProps.schedules ?? getInstallationReportSchedules(storeState),
    };
};

class SchedulesContainer extends React.PureComponent<ISchedulesProps> {
    public render() {
        const padding = this.props.hideHeader ? 'none' : 'panel';

        return this.props.schedules.map((schedule) => (
            <Box key={schedule.id} display="block" paddingTop="base" pageBreakBefore>
                {!this.props.hideHeader && (
                    <SectionHeader
                        text={schedule.name}
                        breadCrumb={t.installationReportSchedulesHeader}
                    />
                )}
                <Box display="block" paddingY={padding}>
                    <Stack spacing="none" vertical>
                        {this.renderTimeLine('monday', schedule.daySegments)}
                        {this.renderTimeLine('tuesday', schedule.daySegments)}
                        {this.renderTimeLine('wednesday', schedule.daySegments)}
                        {this.renderTimeLine('thursday', schedule.daySegments)}
                        {this.renderTimeLine('friday', schedule.daySegments)}
                        {this.renderTimeLine('saturday', schedule.daySegments)}
                        {this.renderTimeLine('sunday', schedule.daySegments)}
                    </Stack>
                </Box>
            </Box>
        ));
    }

    private renderTimeLine = (day: keyof IAggregatedScheduleModel, daySegments: DaySegments) => {
        return (
            <QuarterTimeLineComponent
                dayText={t.dayAbbreviationsGROUP[day]}
                hours={daySegments[day]}
            />
        );
    };
}

export const Schedules = connect(mapStateToProps)(SchedulesContainer);
