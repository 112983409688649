import { toCacheKey, getVerticalFovForIp } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { getSelectedSensorId } from './getSelectedId';

/**
 * Figure out the vertical FOV for the selected sensor
 */
export const getVerticalFovOfSensor = createCachedSelector(
    [getVerticalFovForIp, getSelectedSensorId],
    (verticalFovs, selectedSensorId = 1) => {
        // default to first sensor if no selected
        return verticalFovs[selectedSensorId - 1];
    },
)(toCacheKey);
