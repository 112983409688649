import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getPiaItem } from 'app/modules/common';
import {
    Border,
    Box,
    Stack,
    Text,
    Clickable,
    PiaImage,
    Modal,
    PillButton,
    Icon,
    Grid,
    DropDown,
    DropDownMenuButton,
    TextInput,
    Checkbox,
    Positioned,
} from 'app/components';
import {
    getSelectedDeviceId,
    getFilteredSelectableDevices,
    getDeviceSearchFilter,
    getEnvironments,
    getIncludeDiscontinued,
} from '../../selectors';
import { t } from 'app/translate';
import type { PiaId } from 'app/core/pia';
import { AccessorySelectorActionService } from '../../services/AccessorySelectorAction.service';
import { ServiceLocator } from 'app/ioc';
import { MOBILE_WINDOW_SIZE } from '../../constants';
import { SelectableDevice } from './SelectableDeviceItem.container';
import type { IDeviceSearchFilter, IEnvironment } from '../../models';
import { AddAccessoriesButton } from '../mounting/AddAccessoriesButton.container';

export const DeviceSelection: React.FunctionComponent = () => {
    const [showDeviceSelection, setShowDeviceSelection] = React.useState<boolean>(false);
    const [accessorySelectorActionService] = React.useState(() =>
        ServiceLocator.get(AccessorySelectorActionService),
    );

    const selectedDeviceId = useSelector((state: IStoreState) => getSelectedDeviceId(state));
    const includeDiscontinued = useSelector(getIncludeDiscontinued);
    const selectedPiaItem = useSelector((state: IStoreState) =>
        selectedDeviceId ? getPiaItem(state, selectedDeviceId) ?? undefined : undefined,
    );
    const { piaDevices, foundInCategories } = useSelector((state: IStoreState) =>
        getFilteredSelectableDevices(state),
    );
    const deviceSearchFilter = useSelector((state: IStoreState) => getDeviceSearchFilter(state));
    const environments = useSelector((state: IStoreState) => getEnvironments(state));
    const onSelectDevice = (productId: PiaId) => {
        accessorySelectorActionService.addStandaloneItem(
            productId,
            deviceSearchFilter.environments[0]?.productId ?? undefined,
        );
        setShowDeviceSelection(false);
    };
    const onSearchTextChanged = (searchText: string = '') => {
        accessorySelectorActionService.setDeviceSearchText(searchText);
    };

    const onChangeDeviceFilter = React.useCallback(
        (filterChange: Partial<IDeviceSearchFilter>) => {
            if (deviceSearchFilter) {
                accessorySelectorActionService.setDeviceFilter({
                    ...deviceSearchFilter,
                    showCameras: false,
                    showModularSeries: false,
                    showEncoders: false,
                    showSpeakers: false,
                    showIntercoms: false,
                    showWearables: false,
                    showAccessControls: false,
                    showRecorders: false,
                    showOthers: false,
                    ...filterChange,
                });
            }
        },
        [accessorySelectorActionService, deviceSearchFilter],
    );

    const onChangeEnvironmentFilter = React.useCallback(
        (environment: IEnvironment) => {
            if (deviceSearchFilter) {
                accessorySelectorActionService.setDeviceFilter({
                    ...deviceSearchFilter,
                    environments: deviceSearchFilter.environments.includes(environment)
                        ? []
                        : [environment],
                });
            }
        },
        [accessorySelectorActionService, deviceSearchFilter],
    );

    const onChangeIncludeDiscontinued = (include: boolean) => {
        if (deviceSearchFilter) {
            accessorySelectorActionService.setDeviceFilter({
                ...deviceSearchFilter,
                includeDiscontinued: include,
            });
        }
    };

    return (
        <>
            {showDeviceSelection && (
                <Modal
                    transparentBackdropColor
                    title={<Box padding="base">{t.selectDevice}</Box>}
                    height="75%"
                    minWidth={window.innerWidth > MOBILE_WINDOW_SIZE ? '500px' : undefined}
                    color="white"
                    padding="none"
                    onClose={() => setShowDeviceSelection(false)}
                >
                    <Box height="100%">
                        <Stack vertical>
                            <Box paddingX="base">
                                <Stack flex="shrinkAndGrow" wrap>
                                    <Border bottomWidth={2} color="black">
                                        <Box minWidth="280px" flex="evenSpace">
                                            <Stack flex="fullWidth" spacing="half">
                                                <Icon opaque icon="search" color="black" />
                                                <Box display="block" width="100%">
                                                    <Text inline style="subheader">
                                                        <TextInput
                                                            testId="search"
                                                            autoFocus
                                                            noBorder
                                                            grow
                                                            clear={() => onSearchTextChanged()}
                                                            value={deviceSearchFilter?.searchText}
                                                            changeCriteria="debounced"
                                                            onChange={onSearchTextChanged}
                                                            placeholder={t.searchForModel}
                                                        />
                                                    </Text>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Border>
                                    <Stack flex="none">
                                        <Text color="grey7" bold whiteSpace="nowrap">
                                            {t.sortOrderGROUP.includeDiscontinued}
                                        </Text>
                                        <Checkbox
                                            testId="include_discontinued"
                                            selected={includeDiscontinued}
                                            onChange={onChangeIncludeDiscontinued}
                                            slider
                                        />
                                    </Stack>
                                </Stack>
                            </Box>
                            <Box paddingX="base">
                                <DropDown
                                    alignItems="start"
                                    trigger={
                                        <PillButton
                                            style="axis.com"
                                            text={
                                                deviceSearchFilter?.environments.length
                                                    ? deviceSearchFilter?.environments
                                                          .map((env) => env.name)
                                                          .join(', ')
                                                    : t.placement
                                            }
                                            selected={deviceSearchFilter.environments.length > 0}
                                            icon="keyboard_arrow_down"
                                        />
                                    }
                                    contents={environments.map((env) => (
                                        <DropDownMenuButton
                                            label={env.name}
                                            selected={deviceSearchFilter?.environments.includes(
                                                env,
                                            )}
                                            onClick={() => {
                                                onChangeEnvironmentFilter(env);
                                            }}
                                        />
                                    ))}
                                />
                                <Box paddingX="half">
                                    <Box width="1px" color="blackOpacity" />
                                </Box>
                                <Grid spacing="quart">
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        selected={deviceSearchFilter?.showCameras}
                                        text={t.cameras}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showCameras: !deviceSearchFilter?.showCameras,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        text={t.fSeries}
                                        selected={deviceSearchFilter?.showModularSeries}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showModularSeries:
                                                    !deviceSearchFilter?.showModularSeries,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        selected={deviceSearchFilter?.showEncoders}
                                        text={t.encoders}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showEncoders: !deviceSearchFilter?.showEncoders,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        text={t.speakers}
                                        selected={deviceSearchFilter?.showSpeakers}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showSpeakers: !deviceSearchFilter?.showSpeakers,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        text={t.doorStations}
                                        selected={deviceSearchFilter?.showIntercoms}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showIntercoms: !deviceSearchFilter?.showIntercoms,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        text={t.wearables}
                                        selected={deviceSearchFilter?.showWearables}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showWearables: !deviceSearchFilter?.showWearables,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        text={t.accessControl}
                                        selected={deviceSearchFilter?.showAccessControls}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showAccessControls:
                                                    !deviceSearchFilter?.showAccessControls,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        text={t.recorders}
                                        selected={deviceSearchFilter?.showRecorders}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showRecorders: !deviceSearchFilter?.showRecorders,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        style="axis.com"
                                        text={t.other}
                                        selected={deviceSearchFilter?.showOthers}
                                        onClick={() =>
                                            onChangeDeviceFilter({
                                                showOthers: !deviceSearchFilter?.showOthers,
                                            })
                                        }
                                    />
                                </Grid>
                            </Box>
                            <Stack vertical lineBetweenColor="blackOpacity" spacing="none">
                                {!foundInCategories && piaDevices.length > 0 && (
                                    <Box padding="base">
                                        <Text italic>{t.searchResultsMissingFoundOthers}</Text>
                                    </Box>
                                )}
                                {piaDevices.length === 0 && (
                                    <Box padding="base">
                                        <Text italic testId="matching_results_missing">
                                            {t.searchResultsMissing}
                                        </Text>
                                    </Box>
                                )}
                                {piaDevices.map((device) => {
                                    return (
                                        <SelectableDevice
                                            key={device.id}
                                            productId={device.id}
                                            onClick={onSelectDevice}
                                        />
                                    );
                                })}
                            </Stack>
                        </Stack>
                    </Box>
                </Modal>
            )}
            <Stack vertical spacing="quart" notFullWidth>
                <Box paddingBottom="half" width="100%" justifyContent="center">
                    <Text bold color="grey5">
                        {t.device}
                    </Text>
                </Box>

                <Border color="blackOpacity" radius="5px" shadow="0 4px 10px rgba(0,0,0,.15)">
                    <Clickable onClick={() => setShowDeviceSelection(true)}>
                        <Box
                            testId={`mounting_${selectedPiaItem?.name ?? t.selectDevice}`}
                            padding="base"
                            color="white"
                            position="relative"
                        >
                            <Positioned position="absolute" top={0} right=".25rem">
                                <Icon icon="keyboard_arrow_down" color="grey6" />
                            </Positioned>
                            <Stack
                                vertical={window.innerWidth > MOBILE_WINDOW_SIZE}
                                alignItems="center"
                                justifyContent={
                                    window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'
                                }
                            >
                                <PiaImage
                                    piaId={selectedPiaItem?.id ?? null}
                                    icon="device"
                                    iconProps={{
                                        color: 'grey7',
                                        opaque: true,
                                    }}
                                    imageSize={window.innerWidth > MOBILE_WINDOW_SIZE ? 'xl' : 'lg'}
                                />
                                <Box
                                    height="3em"
                                    width={
                                        window.innerWidth > MOBILE_WINDOW_SIZE ? '135px' : undefined
                                    }
                                    alignItems="center"
                                    justifyContent={
                                        window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'
                                    }
                                >
                                    <Text
                                        testId={`mounting_${
                                            selectedPiaItem?.name ?? t.selectDevice
                                        }`}
                                        style="semibold"
                                        align={
                                            window.innerWidth > MOBILE_WINDOW_SIZE
                                                ? 'center'
                                                : undefined
                                        }
                                    >
                                        {selectedPiaItem?.name ?? t.selectDevice}
                                    </Text>
                                </Box>
                            </Stack>
                        </Box>
                    </Clickable>
                </Border>
                <Box paddingX="base" justifyContent="center">
                    {selectedPiaItem && <AddAccessoriesButton productId={selectedPiaItem.id} />}
                </Box>
            </Stack>
        </>
    );
};
