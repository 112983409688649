import * as React from 'react';
import type { IPlaylistItem } from 'app/components';
import { Video } from 'app/components';
import { t } from 'app/translate';

export const HelpVideo: React.FC = () => {
    const [playlist] = React.useState([
        { header: t.addFloorPlan, startTime: 0 },
        { header: t.addFloorPlanToMap, startTime: 24 },
        { header: t.addBlockers, startTime: 64 },
        { header: t.addCameras, startTime: 122 },
        { header: t.addOtherDevices, startTime: 186 },
        { header: t.increaseAndDuplicate, startTime: 222 },
        { header: t.reviewCameraViews, startTime: 258 },
        { header: t.addAccessories, startTime: 275 },
        { header: t.addAnalyticsDetectionRange, startTime: 293 },
        { header: t.useLayers, startTime: 304 },
        { header: t.viewKeyboardShortcuts, startTime: 315 },
        { header: t.printMap, startTime: 324 },
    ] as IPlaylistItem[]);
    return <Video src="videos/maps-help.mp4" playlist={playlist} />;
};

HelpVideo.displayName = 'HelpVideo';
