import { Module } from 'app/ioc';
import {
    ProjectLoadingTimerService,
    UserProjectsActionService,
    userProjectsReducer,
} from './userProjects';
import { UserProjectsService, LocationQueriesService } from './services';

@Module({
    binder: (binder) => {
        binder.bindService(LocationQueriesService);
        binder.bindService(UserProjectsService);
        binder.bindService(UserProjectsActionService);
        binder.bindService(ProjectLoadingTimerService);
    },
    reducers: {
        userProjects: userProjectsReducer,
    },
})
export class UserProjectsModule {}
