import * as React from 'react';
import { Box } from 'app/components';
import { CustomItems } from './CustomItems.container';
import { useService } from 'app/ioc';
import { useMount } from 'app/hooks';
import { AddonSelectorActionService } from '../addonSelector';

export const CustomItemsView: React.FunctionComponent = () => {
    const addonSelectorActionService = useService(AddonSelectorActionService);

    //reset filter since filter shared with application
    useMount(
        () => null,
        () => {
            addonSelectorActionService.resetPartnerProductFilter();
        },
    );

    return (
        <Box justifyContent="center">
            <Box maxWidth="1400px" width="100%">
                <CustomItems />
            </Box>
        </Box>
    );
};

CustomItemsView.displayName = 'CustomItemsView';
