import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Renames the otherAccessory property to systemAccessory
 */
@injectable()
export class Migration11To12 extends MigrationProviderBase {
    public from: number = 11;
    public to: number = 12;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'item' && entity.properties.otherAccessory) {
                entity.properties.systemAccessory = entity.properties.otherAccessory;
                delete entity.properties.otherAccessory;
            }
            return entity;
        };
    }
}
