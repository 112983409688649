import * as React from 'react';
import { css } from '@emotion/css';
import { ScenarioItem } from './ScenarioItem.component';
import { Icon, Clickable, Box } from 'app/components';
import type { IScenarioSettingsModel, ScenarioId } from 'app/core/persistence';
import { PropertyOverrideText } from '../common';
import type { IScenarioModel } from '../../models';
import { forLarge, forMedium, forXlarge, fromLarge } from 'app/styles/breakpoints.type';

const ContentStyle = css`
    left: 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: 3px;
    padding-left: 6px;
    background-color: rgba(255, 255, 255, 0.8);
    height: 20px;
`;

const ImageStyle = css`
    width: 180px;

    @media ${fromLarge} {
        width: auto;
    }
`;

const PushContentStyle = css`
    display: none;
    max-height: 0;
    width: 900px;
    flex-wrap: wrap;
    overflow-y: hidden;
    transition: max-height 0.3s;

    &[aria-expanded='true'] {
        display: flex;
        max-height: 1300px;
    }

    @media ${forXlarge} {
        width: 700px;
    }

    @media ${forLarge} {
        width: 700px;
    }

    @media ${forMedium} {
        width: 500px;
    }
`;

import type { IWearableSceneModel } from '../../scenario/WearableScenes';

export interface IScenarioSelectorProps {
    scenario: IScenarioModel | IWearableSceneModel;
    scenarioOverride?: Partial<IScenarioSettingsModel>;
    scenarios: IScenarioModel[] | IWearableSceneModel[];
    disabled?: boolean;
    onScenarioChange(id: ScenarioId): void;
}

interface IScenarioSelectorState {
    isExpanded: boolean;
}

export class ScenarioSelector extends React.Component<
    IScenarioSelectorProps,
    IScenarioSelectorState
> {
    constructor(props: IScenarioSelectorProps) {
        super(props);
        this.state = {
            isExpanded: false,
        };
    }

    public render() {
        return (
            <div>
                <PropertyOverrideText
                    propertyValue={
                        this.props.scenarioOverride && this.props.scenarioOverride.scenarioId
                    }
                />
                <Clickable onClick={this.onToggleExpand} disabled={this.props.disabled}>
                    <Box position="relative">
                        <img className={ImageStyle} src={this.props.scenario.image} />
                        <div
                            data-test-id="scenario_panel_scene_name_selected"
                            className={ContentStyle}
                        >
                            {this.props.scenario.name}
                            <Icon opaque icon="arrow_down" />
                        </div>
                    </Box>
                </Clickable>
                <div className={PushContentStyle} aria-expanded={this.state.isExpanded}>
                    {this.props.scenarios.map((scenario: IScenarioModel | IWearableSceneModel) => {
                        return (
                            <ScenarioItem
                                key={scenario.id}
                                scenario={scenario}
                                onScenarioClick={this.onScenarioClick}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    private onScenarioClick = (id: ScenarioId) => {
        if (this.props.scenario.id !== id) {
            this.props.onScenarioChange(id);
        }
        this.onToggleExpand();
    };

    private onToggleExpand = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };
}
