import { injectable } from 'inversify';
import { t } from 'app/translate';
import type { Id } from 'app/core/persistence';
import { ProjectQuotationService } from 'app/core/persistence';
import type { IPrices } from 'app/modules/msrp';
import type { IMsrpInfo } from '../models/IMsrpInfo';

@injectable()
export class QuotationService {
    constructor(private projectQuotationService: ProjectQuotationService) {}

    public async getLocalCurrencyPriceListAndMsrpProps(
        projectQuotationId: Id,
        msrpCurrency: string | null,
        localCurrency: string | null,
        msrpLastUpdated: number | null,
        priceList: IPrices | undefined,
    ): Promise<IMsrpInfo> {
        if (!msrpCurrency || !msrpLastUpdated || !priceList) {
            return { priceList: priceList || null };
        }
        const persistedQuotation = await this.projectQuotationService.get(projectQuotationId);
        const lastUpdated = new Date(msrpLastUpdated);

        const msrpInformationText = t.quotationMsrpValidFor(
            t.monthsGROUP[lastUpdated.getMonth()],
            lastUpdated.getFullYear(),
        );
        const msrp = {
            initialMsrpMargin: persistedQuotation.msrpToQuoteMargin,
            msrpCurrency,
            msrpAvailableInLocalCurrency: priceList.currencyCode === localCurrency,
            msrpInformationText,
        };
        return { msrpProps: msrp, priceList };
    }
}
