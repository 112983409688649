import { isDefined } from 'axis-webtools-util';
import type { IPiaSystemComponent } from 'app/core/pia';
import type { IDeviceRequirement, IRecorderConfiguration, ISystemConfiguration } from '../common';
import {
    nTimes,
    enhanceRecorder,
    getRecorderProps,
    getDiskProps,
    distributeEvenly,
    NoSolutionError,
} from '../common';
import { partitionDevicesToRecorder } from './partitioning';
import { byRecorderUtilization, byStorageSize, byItemId } from '../comparators';
import { recommendSwitches, optimizeSwitches } from './recommendSwitches';
import { optimizeRecorders } from './optimizeRecorders';

interface IAdditionalStorage {
    hdds: IPiaSystemComponent[];
    freeBays: number;
    maximum: number;
}

interface IRecorderWithAdditionastorage {
    recorder: IPiaSystemComponent;
    additionalStorage: IAdditionalStorage;
}

/**
 * Get the possible additional storage of a recorder
 *
 * @param recorder - the recorder
 * @param storage - an array of available storage devices
 * @return The disks, number of free hdd bays, and the maximum disk configuration
 */
const getAdditionalStorage = (
    recorder: IPiaSystemComponent,
    storage: IPiaSystemComponent[],
): IAdditionalStorage => {
    const hdds = recorder.relations
        .filter(({ relationType }) => relationType === 'compatible')
        .map(({ id }) => storage.find((storageItem) => storageItem.id === id))
        .filter(isDefined)
        .sort(byStorageSize);

    const freeBays = getRecorderProps(recorder).freeHddBays;
    const largestHdd = hdds[hdds.length - 1];
    const maximum = (largestHdd?.properties?.maxRecordingStorageMegaBytes ?? 0) * freeBays;

    return {
        hdds,
        freeBays,
        maximum,
    };
};

/**
 * Get the optimal external disk configuration. Note that AXIS discourages
 * the mixing of disk sizes, which simplifies this function quite a lot
 *
 * @param recorder - the recorder
 * @param devices - the partitioned devices
 * @param disks - the available disks to choose from
 * @return The optimal disk configuration
 */
export const optimizeExtraStorage = (
    recorder: IPiaSystemComponent,
    devices: IDeviceRequirement[],
    disks: IPiaSystemComponent[],
): IPiaSystemComponent[] => {
    const recorderProps = getRecorderProps(recorder);
    const availableStorage = recorderProps.maxRecordingStorageMegaBytes;
    const requiredStorage = devices.reduce((acc, device) => acc + device.storage, 0);
    const diff = requiredStorage - availableStorage;

    if (diff <= 0) {
        return [];
    }

    const diskConfiguration = disks
        .map((disk) => ({
            disk,
            numberRequired: diff / getDiskProps(disk).maxRecordingStorageMegaBytes,
        }))
        .filter(({ numberRequired }) => numberRequired <= recorderProps.freeHddBays)
        .sort((a, b) => b.numberRequired - a.numberRequired)[0];

    if (!diskConfiguration) {
        throw new Error('Could not satisfy storage requirement');
    }

    return nTimes(diskConfiguration.disk, diskConfiguration.numberRequired);
};

export const getRequiredExtraLicenses = (
    recorder: IPiaSystemComponent,
    devices: IDeviceRequirement[],
): number => {
    const recorderProps = getRecorderProps(recorder);
    const availableLicenses = recorderProps.vmsChannelLicenses;
    const requiredLicenses = devices.reduce((acc, device) => acc + device.licenseCount, 0);
    const diff = requiredLicenses - availableLicenses;

    return Math.max(0, diff);
};

/**
 * Recommend recorders for an array of device requirements.
 *
 * @param recorders - the recorders to choose from
 * @param storage - the extra storage to choose from (if the recorder supports it)
 * @param license - the extra license that can be added when required
 * @param devices - the devices requirements
 * @return an array of recorder configurations (without any switches)
 * @throws an error if there is no solution
 */
export const recommendRecorders = (
    recorders: IPiaSystemComponent[],
    switches: IPiaSystemComponent[],
    storage: IPiaSystemComponent[],
    license: IPiaSystemComponent | null,
    devices: IDeviceRequirement[],
    maxRecorders: number,
    coreLicense: IPiaSystemComponent | null,
): ISystemConfiguration => {
    // perform a greedy search for the recorder that satisfies as many device
    // requirements as possible
    const greedySearch = (
        deviceRequirements: IDeviceRequirement[],
        recordersLeft: number,
    ): IRecorderConfiguration[] => {
        if (deviceRequirements.length === 0) {
            return [];
        }

        if (recordersLeft === 0) {
            throw new NoSolutionError(
                `Maximum number of recorders reached without finding a solution`,
            );
        }

        const maxExtraLicenses = license ? Number.MAX_VALUE : 0;

        const recordersWithStorage = recorders.map((recorder): IRecorderWithAdditionastorage => {
            const additionalStorage = getAdditionalStorage(recorder, storage);

            return {
                recorder,
                additionalStorage,
            };
        });

        const candidates = recordersWithStorage
            .map((rec) => ({
                ...partitionDevicesToRecorder(
                    enhanceRecorder(rec.recorder, rec.additionalStorage.maximum, maxExtraLicenses),
                    deviceRequirements,
                ),
                recorder: rec.recorder,
                additionalStorage: rec.additionalStorage,
            }))
            .sort(byRecorderUtilization);

        const bestCandidate = candidates[0];

        if (!bestCandidate || bestCandidate.remainingDevices.length === deviceRequirements.length) {
            // abort if no solution was found
            throw new NoSolutionError(`No recorder satisfying the requirements could be found`);
        }

        // find out how many extra licenses we need
        const nbrExtraLicensesRequired = getRequiredExtraLicenses(
            bestCandidate.recorder,
            bestCandidate.selectedDevices,
        );

        let extraLicenses = license ? nTimes(license, nbrExtraLicensesRequired) : [];
        if (coreLicense) {
            const requiredLicenses = bestCandidate.selectedDevices.reduce(
                (acc, device) => acc + device.licenseCount,
                0,
            );
            const extraCoreLicenses = nTimes(coreLicense, requiredLicenses);
            extraLicenses = extraLicenses.concat(extraCoreLicenses);
        }

        // get the optimal disk configuration
        const extraStorageDisks = optimizeExtraStorage(
            bestCandidate.recorder,
            bestCandidate.selectedDevices,
            bestCandidate.additionalStorage.hdds,
        );
        const vmsChannelLicenses =
            (bestCandidate.recorder.properties.vmsChannelLicenses ?? 0) +
            nbrExtraLicensesRequired -
            bestCandidate.selectedDevices.reduce((acc, it) => acc + it.licenseCount, 0);

        // create the recorder recommendation
        const recorderConfiguration: IRecorderConfiguration = {
            recorder: bestCandidate.recorder,
            disks: extraStorageDisks,
            licenses: extraLicenses,
            devices: bestCandidate.selectedDevices,
            overCapacity: {
                ...bestCandidate.overCapacity,
                freeHddBays: bestCandidate.overCapacity.freeHddBays - extraStorageDisks.length,
                vmsChannelLicenses,
            },
        };

        return [
            recorderConfiguration,
            ...greedySearch(bestCandidate.remainingDevices, recordersLeft - 1),
        ];
    };

    const distributedDevices = distributeEvenly(devices);
    const recommendedRecorders = greedySearch(distributedDevices, maxRecorders);

    // post-process the recorder recommendations to remove unnecessary extra licenses
    const optimizedRecorderRecommendation = optimizeRecorders(recommendedRecorders);

    const recommendedSwitches = recommendSwitches(switches, optimizedRecorderRecommendation);

    // post-process the switch recommendations to remove unnecessary switches
    const optimizedSwitchRecommendation = optimizeSwitches(recommendedSwitches);

    for (const recorderRecommendation of optimizedRecorderRecommendation) {
        recorderRecommendation.devices.sort(byItemId);
    }

    for (const switchRecommendation of optimizedSwitchRecommendation) {
        switchRecommendation.devices.sort(byItemId);
    }
    return {
        recorders: optimizedRecorderRecommendation,
        switches: optimizedSwitchRecommendation,
    };
};
