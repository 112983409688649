import * as React from 'react';
import { css } from '@emotion/css';
import { ServiceLocator } from 'app/ioc';
import type { IIFrameParentBoundaries } from './models';
import { PartnerProductSelector } from './components';
import { AddonSelectorActionService } from './services';

const EXTRA_MARGIN = 70;

const containerStyle = css`
    height: 100%;
    position: relative;
`;

// Tests that value is *.axis.com or *.axis.com:8080
const isAxisDomain = new RegExp(/^.+\.axis.com(:8080)?(\/)?$/);

const isObject = (obj: any) => typeof obj === 'object';
const hasHeightAndScrollPosition = (obj: any): obj is IIFrameParentBoundaries =>
    Number.isInteger(obj.innerHeight) && Number.isInteger(obj.scrollPosition);

export class StandalonePartnerProductSelectorView extends React.PureComponent {
    private wrapperRef = React.createRef<HTMLDivElement>();
    private resizeObserver = new ResizeObserver((resizeEntries: ResizeObserverEntry[]) => {
        const partnerProductView = this.wrapperRef.current?.children[0];
        resizeEntries.forEach((resizeEntry: ResizeObserverEntry) => {
            if (resizeEntry.target === partnerProductView) {
                const newHeight = Math.round(resizeEntries[0].contentRect.height);
                // Send message to container that height has changed, using * here to support different targets
                parent.postMessage(`${newHeight + EXTRA_MARGIN}px`, '*');
            }
        });
    });

    componentDidMount() {
        const partnerProductView = this.wrapperRef.current?.children[0];
        if (partnerProductView) {
            this.resizeObserver.observe(partnerProductView);
        }
        addEventListener('message', this.messageEventHandler);
    }

    componentWillUnmount() {
        const partnerProductView = this.wrapperRef.current?.children[0];
        if (partnerProductView) {
            this.resizeObserver.unobserve(partnerProductView);
        }
        removeEventListener('message', this.messageEventHandler);
    }

    render() {
        return (
            <div ref={this.wrapperRef} className={containerStyle}>
                <PartnerProductSelector />
            </div>
        );
    }

    private messageEventHandler(event: MessageEvent<any>) {
        if (
            isAxisDomain.test(event.origin) &&
            isObject(event.data) &&
            hasHeightAndScrollPosition(event.data)
        ) {
            const actions = ServiceLocator.get(AddonSelectorActionService);
            actions.updateReadMoreModalTopPosition({
                scrollPosition: Number(event.data.scrollPosition),
                innerHeight: Number(event.data.innerHeight),
            });
        }
    }
}
