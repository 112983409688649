import { eventTracking } from 'app/core/tracking';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Removes fovLimits, height, parentPiaDevice, parentDevice, installationPointId and location from sensor property on IInstallationPointEntity
 */
@injectable()
export class Migration37To38 extends MigrationProviderBase {
    public from: number = 37;
    public to: number = 38;

    public provideMigration() {
        const projectsCleaned: string[] = [];
        return async (entity: any): Promise<any> => {
            const projectForEntity = entity.path && entity.path.length > 0 ? entity.path[0] : '';
            let sensorDataRemoved = false;
            if (entity.type === 'installationPoint') {
                entity.sensors.map((sensor: any) => {
                    if (
                        sensor.fovLimits ||
                        sensor.height ||
                        sensor.parentPiaDevice ||
                        sensor.parentDevice ||
                        sensor.installationPointId ||
                        sensor.location
                    ) {
                        sensorDataRemoved = true;
                        delete sensor.fovLimits;
                        delete sensor.height;
                        delete sensor.parentPiaDevice;
                        delete sensor.parentDevice;
                        delete sensor.installationPointId;
                        delete sensor.location;
                    }
                });
            }
            if (
                sensorDataRemoved &&
                projectForEntity !== '' &&
                !projectsCleaned.includes(projectForEntity)
            ) {
                projectsCleaned.push(projectForEntity);
                eventTracking.logApplicationEvent(
                    'Migration',
                    'sensor db cleanup',
                    'Deleted sensor model data in project',
                );
            }

            return entity;
        };
    }
}
