import { injectable } from 'inversify';
import { EventEmitter } from 'events';
import { AuthenticationService } from './Authentication.service';

// Timeout for the online check. If we don't get a response within 5 seconds we
// consider ourself as offline. Changed from 1 second to 5 seconds to fix WT-5149.
// consider ourself as offline. Changed from 5 second to 20 seconds to fix WT-5192.
const ONLINE_CHECK_TIMEOUT = 20000;

// if the online check fails after the online event has been received
// we start to check periodically with this interval. Note that this won't have a
// detrimental effect on the server since we only do this when offline.
const RETRY_INTERVAL = 10 * 1000;

type Event = 'online' | 'offline';

@injectable()
export class OfflineService {
    private onlineCheckTimer: number = 0;

    private emitter = new EventEmitter();

    private checkOnlineWithTimeout = this.timeout(
        () => this.checkOnline(),
        ONLINE_CHECK_TIMEOUT,
        false,
    );

    constructor(private authService: AuthenticationService) {
        window.addEventListener('offline', () => this.handleOfflineEvent());
        window.addEventListener('online', () => this.handleOnlineEvent());
    }

    public isOnline(): Promise<boolean> {
        return this.checkOnlineWithTimeout();
    }

    public on(event: Event, fn: () => any) {
        this.emitter.on(event, fn);
    }

    public once(event: Event, fn: () => any) {
        this.emitter.once(event, fn);
    }

    public off(event: Event, fn: () => any) {
        this.emitter.removeListener(event, fn);
    }

    private timeout<T>(fn: () => Promise<T>, timeout: number, timeoutValue: T): () => Promise<T> {
        return () => {
            return Promise.race([
                new Promise<T>((resolve) => setTimeout(() => resolve(timeoutValue), timeout)),
                fn(),
            ]);
        };
    }

    private async checkOnline(): Promise<boolean> {
        if (!navigator.onLine) {
            return false;
        }

        const online = await this.authService.isOnline();
        return online;
    }

    private handleOfflineEvent() {
        clearTimeout(this.onlineCheckTimer);
        this.emitter.emit('offline');
    }

    private async handleOnlineEvent() {
        // if we get the `online` event we may still be offline, hence check
        if (await this.checkOnlineWithTimeout()) {
            this.emitter.emit('online');
        } else {
            this.onlineCheckTimer = window.setTimeout(
                () => this.handleOnlineEvent(),
                RETRY_INTERVAL,
            );
        }
    }
}
