import * as React from 'react';
import { Clickable, Box, Stack, Icon, Text } from 'app/components';
import { t } from 'app/translate';

interface IAddCardReaderButtonProps {
    disabled?: boolean;
    onClick?(): void;
}

/**
 * An add button for doors
 *
 * disabled - set to true if no pointer event should be displayed and button should look disabled
 * onClick - action to trigger on click
 */
export const AddDoorButton: React.FC<IAddCardReaderButtonProps> = ({ disabled, onClick }) => {
    return (
        <Clickable readOnly={disabled} onClick={onClick}>
            <Box alignItems="center" justifyContent="center" padding="half" flex="dontShrink">
                <Stack spacing="half" justifyContent="center" alignItems="center">
                    <Icon opaque size="md" icon="door_front" color={disabled ? 'grey5' : 'blue'} />
                    <Text semiBold color={disabled ? 'grey5' : 'blue'}>
                        {t.addDoor}
                    </Text>
                </Stack>
            </Box>
        </Clickable>
    );
};

AddDoorButton.displayName = 'AddDoorButton';
