import { Box, Button, Heading, Text } from 'app/components';
import { getCurrentProjectItemsArray } from 'app/modules/common';
import { t } from 'app/translate';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

export const LINK_TERMS_AND_CONDITIONS = 'https://www.genetec.com/legal/termsofuse';

interface IGenetecTermsAndConditionsProps {
    onConfirmTermsAndConditions(): void;
}

export const GenetecTermsAndConditions: React.FC<IGenetecTermsAndConditionsProps> = ({
    onConfirmTermsAndConditions,
}) => {
    const isProjectEmpty = useSelector(getCurrentProjectItemsArray).length === 0;
    const confirmMessageNode = React.useMemo(
        () => (
            <Text color="grey8">
                <Trans
                    i18nKey="genetecGROUP.confirmMessageLink"
                    components={{
                        Link: (
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={LINK_TERMS_AND_CONDITIONS}
                            ></a>
                        ),
                    }}
                />
            </Text>
        ),
        [],
    );
    return (
        <Box width="100%" justifyContent="center" testId="genetec_terms_and_conditions">
            <Box direction="column" spacing="base" width="80%">
                <Heading style="title" breakWord>
                    {t.genetecGROUP.confirmHeader}
                </Heading>
                {confirmMessageNode}
                <Box justifyContent="end">
                    <Button
                        primary
                        disabled={isProjectEmpty}
                        onClick={onConfirmTermsAndConditions}
                        testId="genetec_get_solutions"
                    >
                        {t.getSolutions}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

GenetecTermsAndConditions.displayName = 'GenetecTermsAndConditions';
