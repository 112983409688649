import * as React from 'react';
import { connect } from 'react-redux';
import {
    IconText,
    Clickable,
    DropDown,
    DropDownMenuButton,
    DropDownMenuItem,
    Persona,
} from 'app/components';
import type { IStoreState } from 'app/store';
import {
    CommonActionService,
    getPartnerConfigHeaderStyle,
    getUserSignedIn,
} from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import { t } from 'app/translate';
import { AuthenticationService } from 'app/core/persistence';
import type { IUserInfo, IPartnerConfigAllowlist, IPartnerHeaderStyle } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import type { Property } from 'csstype';
import { LoginIconText } from './LoginIconText.component';
import { ColorsEnum } from 'app/styles';

import { getUrlPartnerId } from 'app/partnerUrl';

const AUTHENTICATION_INTERVAL_MS = 60000;

interface ILoginComponentProps {
    foregroundColor?: Property.Color;
    backgroundColor?: Property.Color;
    user: IUserInfo | null;
    isSignedIn?: boolean;
    loading: boolean;
    loaded: boolean;
    error: string | null;
    partnerConfigId: string;
    partnerCofigCompanyName: string;
    allowlist?: IPartnerConfigAllowlist;
    useAllowlist: boolean;
    allowLocIdAccess: boolean;
}

const mapStateToProps = (storeState: IStoreState): ILoginComponentProps => {
    const partnerConfigStyle = getPartnerConfigHeaderStyle(storeState);
    return {
        foregroundColor: partnerConfigStyle?.foregroundColor,
        backgroundColor: partnerConfigStyle?.backgroundColor,
        user: storeState.common.user.user,
        isSignedIn: getUserSignedIn(storeState),
        loading: storeState.common.user.loading,
        loaded: storeState.common.user.loaded,
        error: storeState.common.user.error !== null ? storeState.common.user.error.message : null,
        partnerConfigId: storeState.common.partnerConfig.id,
        partnerCofigCompanyName: storeState.common.partnerConfig.companyName,
        allowlist: storeState.common.partnerConfig.allowlist,
        useAllowlist: storeState.common.partnerConfig.useAllowlist,
        allowLocIdAccess: storeState.common.partnerConfig.allowLocIdAccess,
    };
};

interface ILoggedInUserProps {
    user: IUserInfo | null;
    isSignedIn?: boolean;
    foregroundColor?: Property.Color;
    backgroundColor?: Property.Color;
    partnerConfigId: string;
    partnerConfigCompanyName: string;
    allowlist?: IPartnerConfigAllowlist;
    useAllowlist: boolean;
    allowLocIdAccess: boolean;
    partnerHeaderStyle?: IPartnerHeaderStyle;
    login(): void;
    logout(): void;
}

const LoggedInUser: React.FunctionComponent<ILoggedInUserProps> = ({
    isSignedIn,
    user,
    foregroundColor,
    partnerConfigCompanyName,
    allowlist,
    allowLocIdAccess,
    useAllowlist,
    partnerConfigId,
    login,
    logout,
}) => {
    let labelText = '';

    React.useEffect(() => {
        const actionService = ServiceLocator.get(CommonActionService);
        const authenticationCheck = setInterval(() => {
            actionService.authenticateUser();
        }, AUTHENTICATION_INTERVAL_MS);
        return () => {
            clearInterval(authenticationCheck);
        };
    }, []);

    if (user != null) {
        const { firstName, lastName, email } = user;
        labelText =
            firstName && lastName ? `${firstName} ${lastName}` : email ? email : t.userSignedIn;
    } else if (isSignedIn) {
        labelText = t.userSignedIn;
    }

    if (!isSignedIn) {
        return (
            <Clickable onClick={login}>
                <IconText
                    icon="person_outline"
                    textProps={{
                        color: 'yellowText',
                        semiBold: true,
                        colorOverride: foregroundColor,
                    }}
                    iconProps={{
                        opaque: true,
                        color: 'yellowText',
                        colorOverride: foregroundColor,
                    }}
                >
                    {`${labelText} (${t.userSignBackIn})`}
                </IconText>
            </Clickable>
        );
    }

    const actionService = ServiceLocator.get(CommonActionService);

    const avatarMenuItem = (
        <div
            style={{
                color: `${ColorsEnum.grey3}`,
                background: `${ColorsEnum.grey2}`,
            }}
        >
            <DropDownMenuItem onlyContent isText disabled>
                {user && (
                    <Persona
                        primaryText={`${user.firstName} ${user.lastName}`}
                        secondaryText={user.email}
                        tertiaryText={
                            user?.companyName
                                ? user.companyName
                                : allowlist && allowLocIdAccess && !getUrlPartnerId()
                                  ? partnerConfigCompanyName
                                  : undefined
                        }
                    />
                )}
            </DropDownMenuItem>
        </div>
    );

    const content =
        allowlist && allowLocIdAccess
            ? user?.email
                ? [
                      avatarMenuItem,
                      <DropDownMenuButton
                          label={t.organizationSettingsOnlyRecommendedProducts}
                          iconProps={{
                              color: 'yellowText',
                              opaque: true,
                          }}
                          checked={useAllowlist}
                          disabled={!allowlist || !allowlist.allowExcludedProducts}
                          onClick={() => {
                              actionService.useProductAllowlistChanged(
                                  partnerConfigId,
                                  !useAllowlist,
                              );
                          }}
                      />,
                      <DropDownMenuButton
                          testId="btn_signout_in_ddm_hdr_more"
                          label={t.userSignOut}
                          icon="exit_to_app"
                          iconProps={{
                              color: 'black',
                          }}
                          onClick={logout}
                      />,
                  ]
                : [
                      avatarMenuItem,
                      <DropDownMenuButton
                          label={t.organizationSettingsOnlyRecommendedProducts}
                          checked={useAllowlist}
                          disabled={!allowlist || !allowlist.allowExcludedProducts}
                          onClick={() => {
                              actionService.useProductAllowlistChanged(
                                  partnerConfigId,
                                  !useAllowlist,
                              );
                          }}
                      />,
                      <DropDownMenuButton
                          testId="btn_signout_in_ddm_hdr_more"
                          label={t.userSignOut}
                          icon="exit_to_app"
                          onClick={logout}
                          iconProps={{
                              color: 'black',
                          }}
                      />,
                  ]
            : [
                  avatarMenuItem,
                  <DropDownMenuButton
                      testId="btn_signout_in_ddm_hdr_more"
                      label={t.userSignOut}
                      icon="exit_to_app"
                      onClick={logout}
                      iconProps={{
                          color: 'black',
                      }}
                  />,
              ];

    return (
        <>
            {
                <DropDown
                    stayOpen
                    trigger={
                        <LoginIconText
                            icon="person"
                            nameProps={{
                                color: 'yellowText',
                                semiBold: true,
                                colorOverride: foregroundColor,
                            }}
                            companyNameProps={{
                                color: 'yellowText',
                                colorOverride: foregroundColor,
                            }}
                            iconProps={{
                                opaque: true,
                                color: 'yellowText',
                                colorOverride: foregroundColor,
                            }}
                            name={labelText}
                        />
                    }
                    contents={content}
                />
            }
        </>
    );
};

LoggedInUser.displayName = 'LoggedInUser';

interface ILoggedOutUserProps {
    login(): void;
    colorOverride?: Property.Color;
}

const LoggedOutUser: React.FunctionComponent<ILoggedOutUserProps> = ({ login, colorOverride }) => (
    <Clickable onClick={login}>
        <IconText
            icon="person_outline"
            textProps={{
                color: 'yellowText',
                semiBold: true,
                colorOverride: colorOverride,
            }}
            iconProps={{
                opaque: true,
                color: 'yellowText',
                colorOverride: colorOverride,
            }}
        >
            {t.userSignIn}
        </IconText>
    </Clickable>
);

LoggedOutUser.displayName = 'LoggedOutUser';

class LoginComponent extends React.Component<ILoginComponentProps, { isAuthenticated: boolean }> {
    private commonActionService: CommonActionService;
    private authenticationService: AuthenticationService;

    constructor(props: ILoginComponentProps) {
        super(props);

        this.commonActionService = ServiceLocator.get(CommonActionService);
        this.authenticationService = ServiceLocator.get(AuthenticationService);
        this.state = { isAuthenticated: true };
    }

    public componentDidMount() {
        this.authenticationService
            .isAuthenticated()
            .then((isAuthenticated) => this.setState({ isAuthenticated }));
    }

    public render() {
        let content;
        if (this.props.loading) {
            content = <div>{t.applicationRetrievingUser}</div>;
        } else if (this.props.isSignedIn || this.props.user) {
            content = (
                <LoggedInUser
                    isSignedIn={this.props.isSignedIn && this.state.isAuthenticated}
                    user={this.props.user}
                    foregroundColor={this.props.foregroundColor}
                    backgroundColor={this.props.backgroundColor}
                    partnerConfigCompanyName={this.props.partnerCofigCompanyName}
                    allowlist={this.props.allowlist}
                    allowLocIdAccess={this.props.allowLocIdAccess}
                    useAllowlist={this.props.useAllowlist}
                    partnerConfigId={this.props.partnerConfigId}
                    login={this.onLogin}
                    logout={this.onLogout}
                />
            );
        } else {
            content = (
                <LoggedOutUser login={this.onLogin} colorOverride={this.props.foregroundColor} />
            );
        }

        return content;
    }

    private onLogin = () => {
        eventTracking.logUserEvent('Application', 'Login');
        this.commonActionService.login();
    };

    private onLogout = () => {
        eventTracking.logUserEvent('Application', 'Logout');
        this.commonActionService.logout();
    };
}

export const Login = connect(mapStateToProps)(LoginComponent);
