import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import type { IPiaItem, PiaId } from 'app/core/pia';
import {
    addProductToPreviouslyAdded,
    deviceIcons,
    getProductNameParts,
    getPreviouslyAdded,
    getSelectedRecordingSolutionType,
    getSelectedRecordingVendor,
} from 'app/modules/common';
import type { IAutoTestable } from 'app/components';
import {
    IconText,
    Stack,
    Text,
    Border,
    Box,
    AutoComplete,
    DropDownMenuButton,
} from 'app/components';
import { t } from 'app/translate';
import type { DeviceType, RecordingSolutionType, SelectedVendorType } from 'app/core/persistence';
import {
    isRecordingSolutionComponent,
    isSystemAccessory,
    getSubType,
    getDeviceTypeFromPiaCategory,
    isAudioManagementSoftware,
} from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { getQuickAddDescription } from './QuickAddDescription.factory';
import { isDisabledQuickAddItem } from './isDisabledQuickAddItem';
import { isCurrentSolutionEmpty } from 'app/modules/recordingSelector';
import { getFilteredQuickAddItems, getPiaDevicesForPiaIds } from '../../selectors';
import { DeviceSelectorActionService, QuickAddDeviceService } from '../../services';

interface IQuickAddDevicesProps extends IAutoTestable {
    quickAddItems: IPiaItem[];
    previouslyAddedProducts: IPiaItem[];
    quickAddSearchValue: string;
    selectedRecordingSolution: RecordingSolutionType;
    selectedVendor: SelectedVendorType | undefined;
    isSolutionEmpty: boolean;
}
interface IQuickAddDevicesState {
    maxNumberOfItems: number;
}

const DEFAULT_ITEM_LIMIT = 20;
const MAX_ITEM_LIMIT = 1000;

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IAutoTestable,
): IQuickAddDevicesProps => {
    const previouslyAdded: PiaId[] = getPreviouslyAdded();

    return {
        quickAddItems: getFilteredQuickAddItems(storeState),
        previouslyAddedProducts: getPiaDevicesForPiaIds(storeState, previouslyAdded),
        quickAddSearchValue: storeState.deviceSelector.quickAddSearchValue,
        testId: ownProps.testId,
        selectedRecordingSolution: getSelectedRecordingSolutionType(storeState),
        selectedVendor: getSelectedRecordingVendor(storeState),
        isSolutionEmpty: isCurrentSolutionEmpty(storeState),
    };
};

class QuickAddDevicesContainer extends React.Component<
    IQuickAddDevicesProps,
    IQuickAddDevicesState
> {
    private actionService: DeviceSelectorActionService;
    private quickAddDeviceService: QuickAddDeviceService;

    constructor(props: IQuickAddDevicesProps) {
        super(props);

        this.actionService = ServiceLocator.get(DeviceSelectorActionService);
        this.quickAddDeviceService = ServiceLocator.get(QuickAddDeviceService);
        this.state = { maxNumberOfItems: DEFAULT_ITEM_LIMIT };
    }

    public componentDidUpdate(prevProps: Readonly<IQuickAddDevicesProps>): void {
        if (prevProps.quickAddSearchValue !== '' && this.props.quickAddSearchValue === '') {
            this.setState({ maxNumberOfItems: DEFAULT_ITEM_LIMIT });
        }
    }

    public render() {
        return (
            <Border width={1} color="blue">
                <Box borderRadius="blunt" height="35px" alignItems="center" color="white">
                    <AutoComplete
                        noBorder
                        testId={this.props.testId}
                        icon="add_circle_outline"
                        placeholderHint={t.quickAddPlaceholder}
                        noMatchHint={t.quickAddNoMatch}
                        searchHint={t.quickAddHelp}
                        searchText={this.props.quickAddSearchValue}
                        minWidth={340}
                        suggestions={this.renderSuggestedItems()}
                        onValueChange={this.onAutoCompleteFilterChange}
                    />
                </Box>
            </Border>
        );
    }

    private renderSuggestedItems() {
        const quickAddItems =
            this.props.quickAddSearchValue.length > 0
                ? this.props.quickAddItems
                : this.props.previouslyAddedProducts;
        const hasMoreItems = quickAddItems.length > this.state.maxNumberOfItems;

        const deviceButtons = quickAddItems
            .map((item: IPiaItem) => {
                const deviceName = getProductNameParts(item.name);
                const supportedDeviceType = getDeviceTypeFromPiaCategory(item.category);
                const isSystemAccessoryItem =
                    isSystemAccessory(item) || isAudioManagementSoftware(item);
                const isRecordingSolutionItem = isRecordingSolutionComponent(item);
                const description = supportedDeviceType
                    ? deviceName.description
                    : this.getAddInformation(item);
                const subType = getSubType(item);

                return (
                    <DropDownMenuButton
                        label={deviceName.model}
                        description={description}
                        piaImageProps={{
                            icon: deviceIcons.toIcon(subType ?? supportedDeviceType),
                            piaId: item.id,
                            imageSize: 'lg',
                        }}
                        onClick={() =>
                            this.addItem(
                                item,
                                supportedDeviceType,
                                isSystemAccessoryItem,
                                isRecordingSolutionItem,
                                this.props.selectedRecordingSolution,
                                this.props.selectedVendor,
                                this.props.isSolutionEmpty,
                            )
                        }
                        disabled={isDisabledQuickAddItem(item)}
                        testId={`${deviceName.model}_quick_add`}
                    />
                );
            })
            .slice(0, this.state.maxNumberOfItems);

        if (hasMoreItems) {
            const showMoreMenuButton = (
                <DropDownMenuButton
                    shouldKeepDropDownOpen={false}
                    stopPropagation
                    label={t.showAll}
                    onClick={() => this.setState({ maxNumberOfItems: MAX_ITEM_LIMIT })}
                />
            );
            return [...deviceButtons, showMoreMenuButton];
        }

        return deviceButtons;
    }

    private async addItem(
        item: IPiaItem,
        deviceType: DeviceType | undefined,
        isSystemAccessoryItem: boolean,
        isRecordingSolutionItem: boolean,
        selectedRecordingSolution: RecordingSolutionType,
        selectedVendor: SelectedVendorType | undefined,
        isSolutionEmpty: boolean,
    ) {
        this.setState({ maxNumberOfItems: DEFAULT_ITEM_LIMIT });
        eventTracking.logUserEvent('Project Devices', 'Quick Add Device', item.name);
        addProductToPreviouslyAdded(item.id);
        this.actionService.quickAddFilterChange('');
        if (deviceType) {
            await this.quickAddDeviceService.quickAddDevice(deviceType, item);
            return;
        }

        if (isSystemAccessoryItem) {
            await this.quickAddDeviceService.quickAddSystemAccessory(item);
            return;
        }

        if (isRecordingSolutionItem) {
            await this.quickAddDeviceService.quickAddRecordingSolutionItem(
                item,
                selectedRecordingSolution,
                selectedVendor,
                isSolutionEmpty,
            );
            return;
        }
    }

    private getAddInformation(item: IPiaItem) {
        const { description } = getProductNameParts(item.name);
        const information = getQuickAddDescription(item);
        return (
            <Stack vertical spacing="quart" alignItems="start">
                <Text>{description}</Text>
                <IconText icon="info" iconProps={{ size: 'sm', color: 'blue' }}>
                    {information}
                </IconText>
            </Stack>
        );
    }

    private onAutoCompleteFilterChange = (inputValue: string) => {
        this.actionService.quickAddFilterChange(inputValue);
    };
}

export const QuickAddDevices = connect(mapStateToProps)(QuickAddDevicesContainer);

QuickAddDevices.displayName = 'QuickAddDevices';
