import * as React from 'react';
import { Title, Box, Icon, Stack, Text, Clickable, Opacity } from 'app/components';

import { t } from 'app/translate';
import type { Id, zoneType } from 'app/core/persistence';

import type { IRangeComponentApplication } from './IRangeComponentApplication';
import { useService } from 'app/ioc';
import type { IPiaItem } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { eventTracking } from 'app/core/tracking';
import { getPiaItem, hasApdParamsBoth } from 'app/modules/common';
import { AddonSelectorService } from 'app/modules/addonSelector';

const renderIcon = (zone?: zoneType) => {
    if (zone === 'fusion') {
        return (
            <Box>
                <Icon size="sm" icon={'device'} />
                <Text>+</Text>
                <Icon size="sm" icon={'radar'} />
            </Box>
        );
    } else if (zone === 'radar') {
        return <Icon size="sm" icon={'radar'} />;
    }
    return null;
};

export const getDescriptiveText = (zone: zoneType | undefined): string => {
    return zone === 'fusion' ? t.applicationDetectionZoneFusion : t.applicationDetectionZoneRadar;
};

const getHoverText = (
    isApplicationAdded: boolean,
    isRadarCombined: boolean,
    zone?: zoneType,
): string => {
    if (!isApplicationAdded) {
        return t.addApplication;
    }
    if (isRadarCombined) {
        if (zone === 'fusion') {
            return t.applicationDetectionZoneFusionHover;
        }
        if (zone === 'radar') {
            return t.applicationDetectionZoneRadarHover;
        }
    }
    return '';
};

interface IAcapProps {
    application?: IRangeComponentApplication;
    isRadarCombined: boolean;
    isApplicationAdded: boolean;
    deviceId?: Id;
    showToggleButton?: boolean;
    onSelectApplication?(): Promise<void>;
}

export const Acap: React.FC<IAcapProps> = ({
    application,
    isRadarCombined,
    isApplicationAdded,
    deviceId,
    showToggleButton,
    onSelectApplication,
}) => {
    const addonSelectorService = useService(AddonSelectorService);
    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, deviceId),
    );

    const onToggleApplication = async (event: React.SyntheticEvent) => {
        event.stopPropagation();
        eventTracking.logUserEvent('Maps', 'Added analytic application in maps', application?.name);
        if (application && deviceId) {
            const isAdded = await addonSelectorService.toggleApplication(
                deviceId,
                application,
                !!piaItem && hasApdParamsBoth(piaItem),
            );
            onSelectApplication && isAdded && (await onSelectApplication());
        }
    };

    return (
        <Title title={getHoverText(!isApplicationAdded, isRadarCombined, application?.zone)}>
            <Box
                minWidth="130px"
                minHeight="34px"
                alignItems="center"
                testId={`analytic_application_${application ? application?.name : 'none'}`}
            >
                <Stack
                    justifyContent="between"
                    alignItems="center"
                    flex="shrinkAndGrow"
                    spacing="half"
                    width="100%"
                >
                    <Opacity disabled={!isApplicationAdded}>
                        {application && (
                            <Stack vertical spacing="none">
                                <Text style="semibold" color="yellowText">
                                    {application.name}
                                </Text>
                                {isRadarCombined && application.zone && (
                                    <Text color="grey7" style="small">
                                        {getDescriptiveText(application.zone)}
                                    </Text>
                                )}
                            </Stack>
                        )}
                        {!application && (
                            <Text color="grey7" wordBreak="normal">
                                {t.applicationDetectionRangeInfo}
                            </Text>
                        )}
                        {application && isRadarCombined && (
                            <Stack>{renderIcon(application.zone)}</Stack>
                        )}
                    </Opacity>
                    {application?.acapId && showToggleButton && (
                        <Clickable onClick={onToggleApplication}>
                            {isApplicationAdded ? (
                                <Icon
                                    size="md"
                                    icon="close_circle_outline"
                                    color="red"
                                    testId={`remove_application_${
                                        application ? application?.name : 'none'
                                    }`}
                                />
                            ) : (
                                <Icon
                                    size="md"
                                    icon="add_circle_outline"
                                    color="blue"
                                    testId={`add_application_${
                                        application ? application?.name : 'none'
                                    }`}
                                />
                            )}
                        </Clickable>
                    )}
                </Stack>
            </Box>
        </Title>
    );
};

Acap.displayName = 'Acap';
