import { injectable } from 'inversify';
import { ActionCreator, IAction } from 'app/store';
import { ProjectCustomItemsActions } from '../actions';
import { ICustomItemSort, UserPreferencesService } from 'app/core/persistence';

@injectable()
export class CustomItemsActionService {
    constructor(private userPreferencesService: UserPreferencesService) {}
    @ActionCreator()
    public filterCustomItems(filter: string): IAction<string> {
        return {
            type: ProjectCustomItemsActions.FilterCustomItems,
            payload: filter,
        };
    }
    @ActionCreator()
    public updateSortOrder(sortOrder: ICustomItemSort): IAction<ICustomItemSort> {
        this.userPreferencesService.set({ customItemListSorting: sortOrder });
        return {
            type: ProjectCustomItemsActions.UpdateSort,
            payload: sortOrder,
        };
    }
}
