import type { IAutoTestable } from 'app/components/ui-test';
import * as React from 'react';
import type { IClickableProps } from '../../functional';
import { Clickable } from '../../functional';
import { Text } from '../../text';

interface IAction extends IClickableProps, IAutoTestable {
    /**
     * The text to print as action
     */
    title: string;
}

/**
 * Indicates an actionable text.
 * Usually used for reset actions.
 */
export const Action: React.FunctionComponent<IAction> = ({ title, testId, ...props }) => (
    <Clickable {...props}>
        <Text semiBold color={'blue'} testId={testId}>
            {title}
        </Text>
    </Clickable>
);

Action.displayName = 'Action';
