import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    getCurrentSortOrder,
    getDeviceGroup,
    getDeviceGroupFilter,
    getHasBfrCfrFreeProducts,
    getHasPvcFreeProducts,
    getHasRecycledPlasticProducts,
    getHasSustainableProducts,
    getSortOrderOptions,
} from '../../selectors';
import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Linkable,
    Modal,
    PillButton,
    Stack,
    Text,
} from 'app/components';
import { useService } from 'app/ioc';
import { DeviceSelectorActionService } from '../../services';
import { getSustainabilityFilterCount } from '../../selectors/getSustainabilityFilter';
import type { ISustainabilityFilter } from 'app/core/persistence';
import { MIN_RECYCLED_PLASTIC_PERCENT } from '../../constants/minRecycledPlasticPercent';

const sustainabilityUrl = 'https://www.axis.com/about-axis/sustainability';

export const SustainabilityFilter: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const deviceGroup = useSelector(getDeviceGroup);
    const productFilter = useSelector(getDeviceGroupFilter);
    const actionService = useService(DeviceSelectorActionService);
    const currentSortOrder = useSelector(getCurrentSortOrder);
    const hasRecycledPlasticProducts = useSelector(getHasRecycledPlasticProducts);
    const hasBfrCfrFreeProducts = useSelector(getHasBfrCfrFreeProducts);
    const hasPvcFreeProducts = useSelector(getHasPvcFreeProducts);
    const hasSustainableProducts = useSelector(getHasSustainableProducts);
    const updateProductFilter = (filter: Partial<ISustainabilityFilter>) =>
        actionService.updateSustainabilityFilter(filter, deviceGroup);
    const activeSustainabilityFilterCount = useSelector(getSustainabilityFilterCount);
    const isFilterActive = activeSustainabilityFilterCount > 0;
    const sortOrders = useSelector(getSortOrderOptions);

    if (!hasSustainableProducts) return null;

    return (
        <>
            {modalOpen && (
                <Modal title={t.sustainability} onClose={() => setModalOpen(false)}>
                    <Stack spacing="panel" vertical>
                        <Box>
                            <Linkable external link={sustainabilityUrl}>
                                <IconButton
                                    iconRight
                                    text={t.readMoreAboutSustainability}
                                    icon="arrow_forward"
                                />
                            </Linkable>
                        </Box>
                        <Stack vertical>
                            <Text style="semibold" color="grey6">
                                {t.productCharacteristics}
                            </Text>
                            <Stack spacing="halfQuart" wrap>
                                {hasPvcFreeProducts && (
                                    <PillButton
                                        checkable
                                        selected={productFilter.pvcFree}
                                        text={t.advancedFiltersGROUP.pvcFree}
                                        onClick={() =>
                                            updateProductFilter({ pvcFree: !productFilter.pvcFree })
                                        }
                                    />
                                )}
                                {hasBfrCfrFreeProducts && (
                                    <PillButton
                                        checkable
                                        selected={productFilter.bfrCfrFree}
                                        text={t.advancedFiltersGROUP.bfrCfrFree}
                                        onClick={() =>
                                            updateProductFilter({
                                                bfrCfrFree: !productFilter.bfrCfrFree,
                                            })
                                        }
                                    />
                                )}
                                {hasRecycledPlasticProducts && (
                                    <PillButton
                                        checkable
                                        selected={(productFilter.minRecycledPlastic ?? 0) > 0}
                                        text={t.advancedFiltersGROUP.recycledPlastics(
                                            MIN_RECYCLED_PLASTIC_PERCENT,
                                        )}
                                        onClick={() =>
                                            updateProductFilter({
                                                minRecycledPlastic: productFilter.minRecycledPlastic
                                                    ? undefined
                                                    : MIN_RECYCLED_PLASTIC_PERCENT,
                                            })
                                        }
                                    />
                                )}
                            </Stack>
                        </Stack>
                        {sortOrders.includes('byPowerConsumption') && (
                            <Box>
                                <Checkbox
                                    slider
                                    selected={currentSortOrder === 'byPowerConsumption'}
                                    onChange={(selected) =>
                                        actionService.setSortOrder(
                                            selected ? 'byPowerConsumption' : 'bySeries',
                                        )
                                    }
                                >
                                    {t.sortOrderGROUP.byPowerConsumption}
                                </Checkbox>
                            </Box>
                        )}
                        <Box justifyContent="end">
                            <Button primary onClick={() => setModalOpen(false)}>
                                {t.close}
                            </Button>
                        </Box>
                    </Stack>
                </Modal>
            )}
            <PillButton
                color={isFilterActive ? 'green' : 'white'}
                icon="eco"
                borderColor="green"
                iconColor={isFilterActive ? 'white' : 'green'}
                textColor={isFilterActive ? 'white' : 'green'}
                text={
                    activeSustainabilityFilterCount > 0
                        ? `${t.sustainability} \u00b7 ${activeSustainabilityFilterCount}`
                        : t.sustainability
                }
                selected={isFilterActive}
                onClick={() => setModalOpen(true)}
            />
        </>
    );
};

SustainabilityFilter.displayName = 'SustainabilityFilter';
