import { createCachedSelector } from 're-reselect';
import { getPartnerProducts } from './getPartnerProductsCommon';
import type { IApplicationItem } from 'app/modules/common';
import { getPiaIdFromProps, getPiaItemsRecord, toCacheKey } from 'app/modules/common';
import { getPartnerFilter } from './getPartnerFilter';
import { getCompatiblePartnerProductsForPiaItem, toIApplicationItem } from '../utils';

/** Get partner applications by vendor id */
export const getPartnerProductsByVendorId = createCachedSelector(
    [getPartnerProducts, getPiaIdFromProps, getPartnerFilter, getPiaItemsRecord],
    (partnerProducts, vendorId, partnerFilter, piaItemsRecord): IApplicationItem[] => {
        const compatibleProducts = partnerFilter.compatibleWithDevicePiaId
            ? getCompatiblePartnerProductsForPiaItem(
                  partnerProducts,
                  piaItemsRecord[partnerFilter.compatibleWithDevicePiaId],
              )
            : partnerProducts;
        return compatibleProducts
            .filter(({ properties }) => properties.vendorId === vendorId)
            .map(toIApplicationItem);
    },
)(toCacheKey);
