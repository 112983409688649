import * as React from 'react';
import { Box, DualRange } from 'app/components';
import { IPRangeService } from 'app/core/persistence';
import type { IMinMax, INetworkRange, IPersistableRangeType } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { getTotalAvailableIPAddresses } from '../../selectors/getTotalAvailableIPAddresses';
import { toColor } from './ipRangeStyleUtils';
import { convertToIpRange } from './convertToIpRange';
import { debounce } from 'axis-webtools-util';
import { ServiceLocator } from 'app/ioc';
import { getCurrentProjectNetworkSettings } from '../../../project/selectors/getCurrentProject';
import { convertFromIpRange } from './convertFromIPRange';
import { Validator } from 'ip-num';
import { clamp } from 'lodash-es';

interface IIPRange extends IMinMax {
    type: IPersistableRangeType;
}

interface IIPRangeSliderProps {
    range: IIPRange;
    hasFocus: boolean;
    setRange(range: IIPRange): void;
    onGotFocus(): void;
}

/** Component that adds a range slider and checkbox for a reserved range. */
export const IPRangeSlider: React.FC<IIPRangeSliderProps> = ({
    range,
    setRange,
    hasFocus,
    onGotFocus,
}) => {
    const [ipRangeService] = React.useState<IPRangeService>(ServiceLocator.get(IPRangeService));
    const projectNetwork = useSelector(getCurrentProjectNetworkSettings);
    const rangeSize = useSelector(getTotalAvailableIPAddresses);
    const rangeMinLabel = projectNetwork ? convertToIpRange(projectNetwork.ipStart, range.min) : '';
    const rangeMaxLabel = projectNetwork ? convertToIpRange(projectNetwork.ipStart, range.max) : '';
    /** Used to keep track of whether or not it component has rendered more than once */
    const isInitialRender = React.useRef(true);

    /** Sends a debounced update to database for reserved range. */
    const updateRangeCallback = React.useMemo(
        () =>
            debounce(
                (type: IPersistableRangeType, updatedRange: INetworkRange) =>
                    ipRangeService.updateRange(type, updatedRange),
                300,
            ),
        [ipRangeService],
    );

    React.useEffect(() => {
        // Don't run useEffect on first render to avoid conflicting updates
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        updateRangeCallback(range.type, {
            ipStart: rangeMinLabel,
            ipEnd: rangeMaxLabel,
        });
    }, [rangeMinLabel, rangeMaxLabel, updateRangeCallback, range.type]);

    /** Updates ip start and end for reserved range. */
    const updateMinMax = React.useCallback(
        (min: number, max: number) => {
            setRange({ ...range, min, max });
        },
        [range, setRange],
    );

    const onLabelsChange = React.useCallback(
        (min: string, max: string) => {
            if (
                !projectNetwork?.ipStart ||
                !Validator.isValidIPv4String(min)[0] ||
                !Validator.isValidIPv4String(max)[0]
            ) {
                return;
            }

            const newMinValue = clamp(
                convertFromIpRange(projectNetwork.ipStart, min),
                1,
                range.max - 1,
            );
            const newMaxValue = clamp(
                convertFromIpRange(projectNetwork.ipStart, max),
                range.min + 1,
                rangeSize,
            );
            updateMinMax(newMinValue, newMaxValue);
        },
        [projectNetwork?.ipStart, range.max, range.min, rangeSize, updateMinMax],
    );

    return (
        <Box>
            <Box justifyContent="evenly" paddingRight="cell" width="100%">
                <DualRange
                    rangeMax={rangeSize}
                    rangeMin={1}
                    minValue={range.min}
                    maxValue={range.max}
                    minLabel={rangeMinLabel}
                    maxLabel={rangeMaxLabel}
                    hasFocus={hasFocus}
                    altStepValue={256}
                    onChange={updateMinMax}
                    onLabelsChange={onLabelsChange}
                    onGotFocus={onGotFocus}
                    color={toColor(range.type)}
                />
            </Box>
        </Box>
    );
};

IPRangeSlider.displayName = 'IPRangeSlider';
