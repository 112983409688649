import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppHeaderComponent } from './AppHeader.component';
import {
    getHeaderStyle,
    getPartnerLogo,
    getProgressPercent,
    getShowAxisLogo,
    getUserSignedIn,
} from 'app/modules/common';
import { useParams } from 'react-router-dom';

export const AppHeader: React.FC = () => {
    const isSignedIn = useSelector(getUserSignedIn);
    const partnerLogo = useSelector(getPartnerLogo);
    const showAxisLogo = useSelector(getShowAxisLogo);
    const progressPercent = useSelector(getProgressPercent);
    const headerStyle = useSelector(getHeaderStyle);

    return (
        <AppHeaderComponent
            basePath={useParams().pathname ?? '/'}
            isSignedIn={isSignedIn}
            partnerLogo={partnerLogo}
            showAxisLogo={showAxisLogo}
            progressPercent={progressPercent}
            partnerHeaderStyle={headerStyle}
        />
    );
};

AppHeader.displayName = 'AppHeader';
