import { injectable } from 'inversify';
import { BaseRepository } from './Base.repository';
import {
    PersistenceDatabaseRepository,
    TimestampProviderService,
    EntitySettings,
    CreateEntityService,
    PersistenceMemoryRepository,
} from './persistence';
import type { IPersistence } from './persistence';
import type { IItemRelationEntity, Id } from '../entities';
import { pushUndoCommand } from './utils';

@injectable()
export class ItemRelationRepository extends BaseRepository<IItemRelationEntity> {
    constructor(
        entitySettings: EntitySettings,
        persistenceRepository: PersistenceDatabaseRepository,
        persistenceMemoryRepository: PersistenceMemoryRepository,
        timestampProvider: TimestampProviderService,
        createEntityService: CreateEntityService,
    ) {
        super(
            entitySettings,
            persistenceRepository,

            persistenceMemoryRepository,
            timestampProvider,
            createEntityService,
        );
    }

    public async deleteAllRelationsForEntity(...entityIds: Id[]) {
        const allRelations = await this.getAll();

        const uniqueRecord: Record<Id, IPersistence<IItemRelationEntity>> = {};
        allRelations.forEach((relationDoc) => {
            if (
                (entityIds.includes(relationDoc.childId) ||
                    entityIds.includes(relationDoc.parentId)) &&
                !uniqueRecord[relationDoc._id]
            ) {
                uniqueRecord[relationDoc._id] = relationDoc;
            }
        });
        const uniqueDocs = Object.values(uniqueRecord);
        await this.getRepository().bulkDelete(uniqueDocs);

        const revertingCommand = {
            creates: uniqueDocs.map((entity) => ({
                props: entity,
            })),
        };

        pushUndoCommand(revertingCommand);
    }

    public prefix() {
        return 'itemRelation';
    }
}
