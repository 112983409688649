import type { RepositoryAction } from '../RepositoryAction';

export class RepositoryConflictError extends Error {
    constructor(
        action: RepositoryAction,
        invalidEntities: Array<PouchDB.Core.Response | PouchDB.Core.Error> = [],
    ) {
        super(
            `Conflicts occurred when persisting data, action: ${action}, ids: ${invalidEntities
                .map((e) => e.id)
                .join(', ')}`,
        );

        Object.setPrototypeOf(this, RepositoryConflictError.prototype);
    }
}
