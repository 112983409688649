import { Text } from 'app/components';
import { t } from 'app/translate';
import * as React from 'react';

export const GenetecEmptyProject: React.FC = () => {
    return (
        <Text color="grey5" align="center" testId="genetec_empty_project">
            {t.addDevicesToGetRecommendation}
        </Text>
    );
};

GenetecEmptyProject.displayName = 'GenetecEmptyProject';
