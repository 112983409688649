import type { IPiaLocation } from '../client/types/IPiaLocation';

const NOT_IMPLICTLY_SELECTABLE_LOCATIONS = [
    129, // Madeira
    15, // Azores
];

/**
 * Filter out locations that are not implicitly selectable. Some locations share the
 * same country code. When the location is implicitly determined from the country code
 * delivered by the geolocation api we want to make sure that we pick the right pia location.
 * Unformtunately, PIA doesn't provide any means to distinguish between these locations, so we have to
 * filter out the ones that shouldn't be implicitly selected. Currently this is Maderia
 * and Azores. With these filtered out, Portugal will be selected when the country code is PT
 * which makes sense even if the user is in Madeira or Azores. Madeira and Azores can still be
 * selected manually.
 */
export const isImplicitlySelectableLocation = (location: IPiaLocation) =>
    !NOT_IMPLICTLY_SELECTABLE_LOCATIONS.includes(location.id);
