import * as React from 'react';
import { t } from 'app/translate';
import { Box, Border, Text } from 'app/components';

import { type IItemEntity, type IPersistence } from 'app/core/persistence';
import { DeviceListIpTable } from './DeviceListIpTable.component';

interface IPlacedDevicesProps {
    item: IPersistence<IItemEntity>;
}

export const DevicesPlacedOnMap: React.FC<IPlacedDevicesProps> = ({ item }) => {
    return (
        <Box direction="column" flex="fullWidth" width="100%">
            <Border bottomWidth={1} color="grey4">
                <Box justifyContent="between" flex="fullWidth">
                    <Text style="semibold" color="grey5">
                        {t.onMap}
                    </Text>
                </Box>
            </Border>
            <Box paddingTop="half" flex="fullWidth">
                <DeviceListIpTable item={item} />
            </Box>
        </Box>
    );
};

DevicesPlacedOnMap.displayName = 'DevicesPlacedOnMap';
