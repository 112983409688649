import * as React from 'react';
import { Box, Border, Stack, Icon, TextInput, Text, IconButton } from 'app/components';
import { t } from 'app/translate';
import { DeviceSelectorActionService } from '../../../services';
import { ServiceLocator } from 'app/ioc';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';

export const SearchField: React.FC = () => {
    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );
    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);
    const onSearchTextChanged = (newSearchText: string) =>
        actionService.setSearchText(newSearchText);

    return (
        <Box width="100%" paddingY="base">
            <Border bottomWidth={1} color="grey7">
                <Box width="100%">
                    <Stack flex="fullWidth" spacing="half">
                        {searchText ? (
                            <IconButton
                                testId="clear_device_selector_search"
                                icon="cancel"
                                color="blue"
                                onClick={() => onSearchTextChanged('')}
                            />
                        ) : (
                            <Icon opaque icon="search" color="grey8" />
                        )}
                        <Box display="block" width="100%">
                            <Text inline style="subheader">
                                <TextInput
                                    noBorder
                                    grow
                                    alwaysTransparent
                                    clear={() => onSearchTextChanged('')}
                                    value={searchText ?? ''}
                                    changeCriteria="debounced"
                                    onChange={onSearchTextChanged}
                                    placeholder={t.searchForModel}
                                    noClearButton
                                    testId="device_selector_search_field"
                                />
                            </Text>
                        </Box>
                    </Stack>
                </Box>
            </Border>
        </Box>
    );
};
