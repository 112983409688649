import type {
    ICameraItemEntity,
    Id,
    IItemEntity,
    IPersistence,
    ISensorUnitItemEntity,
} from 'app/core/persistence';
import { isMainUnit, getParentId, deviceTypeCheckers } from 'app/core/persistence';
import { createSelector } from 'reselect';
import {
    getCurrentProjectItems,
    getInstallationPointsByParentIdRecord,
    getPiaCameraForProductId,
    getPiaItemsRecord,
    isSelectedCameraSupportedByEditCameraItem,
    largestHorizontalFov,
    toCacheKey,
    usesCorridorFormat,
} from 'app/modules/common';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { getEditItem } from './getEditItem';
import { createCachedSelector } from 're-reselect';

const getEditCameraItem = createSelector([getEditItem], (editItem) => {
    if (!editItem) return undefined;
    if (deviceTypeCheckers.isSensorUnit(editItem)) {
        return editItem as IPersistence<ISensorUnitItemEntity>;
    }
    if (deviceTypeCheckers.isCamera(editItem)) {
        return editItem as IPersistence<ICameraItemEntity>;
    }

    return undefined;
});

/** Checks if a device model can handle all coverage areas of edited device's installation points */
export const getCoverageAreaSupportedForPiaId = createCachedSelector(
    [
        getPiaCameraForProductId,
        getEditCameraItem,
        getInstallationPointsByParentIdRecord,
        getPiaItemsRecord,
        getCurrentProjectItems,
    ],
    (piaCamera, editCameraItem, installationPointsByParentId, piaItems, currentProjectItems) => {
        if (!piaCamera || !editCameraItem) {
            return true;
        }

        const installationPointsForEditItem = installationPointsByParentId[editCameraItem._id];

        if (!installationPointsForEditItem?.length) {
            return true;
        }

        // Check if any sensor is set to corridor format
        const editCorridorFormat = usesCorridorFormat(installationPointsForEditItem);

        // Corridor format support for sensor units depend on the main unit they are connected to
        const piaCorridorFormat =
            getMainUnitCorridorFormat(editCameraItem, currentProjectItems, piaItems) ??
            piaCamera.properties.corridorFormat;

        // Check current panorama mode
        const editPanoramaMode = deviceTypeCheckers.isCamera(editCameraItem)
            ? editCameraItem.properties.camera.filter.panoramaMode
            : editCameraItem.properties.sensorUnit.filter.panoramaMode;

        // Get largest field of view of all sensors
        const editHorizontalFov = largestHorizontalFov(installationPointsForEditItem);

        return isSelectedCameraSupportedByEditCameraItem(
            { ...piaCamera.properties, corridorFormat: piaCorridorFormat },
            editPanoramaMode,
            editCorridorFormat,
            editHorizontalFov,
        );
    },
)(toCacheKey);

/** Checks if sensorItem is a sensor uint. If so it returns corridor format for parent main unit. Otherwise undefined. */
const getMainUnitCorridorFormat = (
    sensorItem: IPersistence<ISensorUnitItemEntity> | IPersistence<ICameraItemEntity>,
    currentProjectItems: Record<Id, IPersistence<IItemEntity> | undefined>,
    piaItems: Record<PiaId, IPiaItem>,
) => {
    if (!deviceTypeCheckers.isSensorUnit(sensorItem)) {
        return undefined;
    }
    const parentId = getParentId(sensorItem);
    if (!parentId) {
        return undefined;
    }
    const parentItem = currentProjectItems[parentId];
    const parentPiaItem = parentItem?.productId ? piaItems[parentItem.productId] : undefined;
    const mainUnitCorridor = isMainUnit(parentPiaItem)
        ? parentPiaItem.properties.corridorFormat
        : undefined;
    return mainUnitCorridor;
};
