import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import { Box, Grid, Modal, PillButton, Stack, Text } from 'app/components';
import type { ICameraFilter } from '../../models';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { CameraTypeFilter } from './CameraTypeFilter.component';
import { ApplicationSupportFilter } from './ApplicationSupportFilter';
import { WDRTechnologyFilter } from './WDRTechnologyFilter.component';
import { OpticalZoomFilter } from './OpticalZoomFilter';
import { IKRatingFilter } from './IKRatingFilter.component';
import { ONVIFProfileFilter } from './ONVIFProfileFilter.component';
import { LightConditionsFilter } from './LightConditionsFilter.component';
import { ResolutionFilter } from './ResolutionFilter.component';
import { OperationalTemperatureFilter } from './common';
import { LightConditions } from 'app/core/persistence';
import { MaxPoeClassFilter } from './MaxPoeClassFilter';
import type { PoeClass } from 'app/core/pia';
import type { AnalyticsComputeCapabilityType } from 'app/core/persistence';
import { AnalyticsComputeCapabilityFilter } from './AnalyticsComputeCapabilityFilter';
import { IPRatingFilter } from './IPRatingFilter';
import { FpsFilter } from './FpsFilter';
import { getCameraFilterExceptSustainability } from '../../selectors';
import type { IDesiredCamera } from 'app/modules/common';

export const AdvancedFilters: React.FC = () => {
    const [actions] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );
    const [showFiltersModal, setShowFiltersModal] = React.useState<boolean>(false);
    const cameraFilter = useSelector(getCameraFilterExceptSustainability);

    const updateFilter = React.useCallback(
        (newFilter: Partial<ICameraFilter>) => {
            actions.updateCameraFilter(newFilter);
        },
        [actions],
    );

    const updateDesiredCamera = React.useCallback(
        (newDesiredCamera: Partial<IDesiredCamera>) => {
            actions.updateDesiredCamera(newDesiredCamera);
        },
        [actions],
    );

    const isBacklightActive = () =>
        cameraFilter.desiredCamera.lightConditions.some(
            (condition) => condition === LightConditions.BACKLIGHT,
        );

    const setBacklightCondition = () => {
        updateDesiredCamera({
            lightConditions: isBacklightActive()
                ? cameraFilter.desiredCamera.lightConditions.filter(
                      (condition) => condition !== LightConditions.BACKLIGHT,
                  )
                : [...cameraFilter.desiredCamera.lightConditions, LightConditions.BACKLIGHT],
        });
    };

    const updateAnalyticsComputeCapability = React.useCallback(
        (analyticsComputeCapability: AnalyticsComputeCapabilityType) => {
            // De-select filter when clicking already selected option
            if (cameraFilter.analyticsComputeCapability === analyticsComputeCapability) {
                return updateFilter({ analyticsComputeCapability: undefined });
            }
            updateFilter({ analyticsComputeCapability });
        },
        [cameraFilter.analyticsComputeCapability, updateFilter],
    );

    const isFilterActive = (value: any) => {
        if (typeof value === 'boolean') return !!value;
        if (typeof value === 'string') return !!value;
        if (typeof value === 'number') return !!value;
        const optionalFilter = value as { active: boolean };
        if (optionalFilter) return optionalFilter.active;
    };

    const updateMaxPoe = React.useCallback(
        (poeClass: PoeClass) => {
            updateFilter({
                maxPoeClass: poeClass === cameraFilter.maxPoeClass ? undefined : poeClass,
            });
        },
        [cameraFilter.maxPoeClass, updateFilter],
    );

    return (
        <>
            {showFiltersModal && (
                <Modal
                    maxWidth="810px"
                    title={t.moreFilters}
                    onClose={() => setShowFiltersModal(false)}
                >
                    <Box paddingRight="half" paddingBottom="panel">
                        <Stack vertical spacing="panel">
                            <Stack vertical spacing="quart">
                                <Text style="semibold" color="grey5">
                                    {t.productCharacteristics}
                                </Text>
                                <Grid spacing="halfQuart">
                                    <CameraTypeFilter
                                        selectedCameraType={
                                            cameraFilter.desiredCamera.cameraTypes[0]
                                        }
                                        onClick={(cameraType) =>
                                            updateDesiredCamera({
                                                cameraTypes:
                                                    cameraFilter.desiredCamera.cameraTypes[0] ===
                                                    cameraType
                                                        ? []
                                                        : [cameraType],
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.desiredCamera.outdoor}
                                        text={t.advancedFiltersGROUP.outdoorReady}
                                        onClick={() =>
                                            updateDesiredCamera({
                                                outdoor: !cameraFilter.desiredCamera.outdoor,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.vandalResistant}
                                        text={t.advancedFiltersGROUP.vandalResistant}
                                        onClick={() =>
                                            updateFilter({
                                                vandalResistant: !cameraFilter.vandalResistant,
                                            })
                                        }
                                    />
                                    <IKRatingFilter
                                        selectedIKRating={
                                            cameraFilter.vandalRating.active
                                                ? cameraFilter.vandalRating.option
                                                : undefined
                                        }
                                        onClick={(newIKRating) =>
                                            updateFilter({
                                                vandalRating:
                                                    newIKRating ===
                                                        cameraFilter.vandalRating.option &&
                                                    cameraFilter.vandalRating.active
                                                        ? {
                                                              active: false,
                                                              option: '',
                                                          }
                                                        : {
                                                              active: true,
                                                              option: newIKRating,
                                                          },
                                            })
                                        }
                                    />
                                    <IPRatingFilter
                                        selectedIPRating={cameraFilter.ipRating}
                                        onClick={(ipRating) =>
                                            updateFilter({
                                                ipRating,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.ruggedizedEN50155}
                                        text={t.ruggedized}
                                        onClick={() =>
                                            updateFilter({
                                                ruggedizedEN50155: !cameraFilter.ruggedizedEN50155,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.casingMaterial}
                                        text={t.advancedFiltersGROUP.casingMaterial}
                                        onClick={() =>
                                            updateFilter({
                                                casingMaterial: !cameraFilter.casingMaterial,
                                            })
                                        }
                                    />
                                    <OperationalTemperatureFilter
                                        filterActive={cameraFilter.operationalTemperature.active}
                                        filterMin={cameraFilter.operationalTemperature.option}
                                        filterMax={
                                            cameraFilter.operationalTemperature.secondaryOption
                                        }
                                        setOperationalTemperature={(min, max) =>
                                            updateFilter({
                                                operationalTemperature: {
                                                    active: true,
                                                    option: min,
                                                    secondaryOption: max,
                                                },
                                            })
                                        }
                                        inactivateOperationalTemperature={(min, max) =>
                                            updateFilter({
                                                operationalTemperature: {
                                                    active: false,
                                                    option: min,
                                                    secondaryOption: max,
                                                },
                                            })
                                        }
                                    />
                                    <MaxPoeClassFilter
                                        selectedMaxPoe={cameraFilter.maxPoeClass}
                                        onClick={updateMaxPoe}
                                    />
                                </Grid>
                            </Stack>
                            <Stack vertical spacing="quart">
                                <Text style="semibold" color="grey5">
                                    {t.imaging}
                                </Text>
                                <Stack vertical>
                                    <Grid spacing="halfQuart">
                                        <ResolutionFilter
                                            selectedResolution={cameraFilter.minVideoResolution}
                                            onClick={(resolution) =>
                                                updateFilter({
                                                    minVideoResolution:
                                                        cameraFilter.minVideoResolution ===
                                                        resolution
                                                            ? undefined
                                                            : resolution,
                                                })
                                            }
                                        />
                                        <FpsFilter
                                            selectedFps={cameraFilter.minFps}
                                            onClick={(minFps) =>
                                                updateFilter({
                                                    minFps,
                                                })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.desiredCamera.corridorFormat}
                                            text={t.cameraSelectorFieldOfViewCorridor}
                                            onClick={() =>
                                                updateDesiredCamera({
                                                    corridorFormat:
                                                        !cameraFilter.desiredCamera.corridorFormat,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid spacing="halfQuart">
                                        <PillButton
                                            checkable
                                            text={t.cameraSelectorFieldOfViewBacklight}
                                            selected={isBacklightActive()}
                                            onClick={setBacklightCondition}
                                        />
                                        <LightConditionsFilter
                                            selectedConditions={cameraFilter.desiredCamera.lightConditions.filter(
                                                (condition) =>
                                                    condition !== LightConditions.BACKLIGHT,
                                            )}
                                            onClick={(lightConditions) =>
                                                updateDesiredCamera({ lightConditions })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.lowLightTechnology}
                                            text={t.advancedFiltersGROUP.lightFinder}
                                            onClick={() =>
                                                updateFilter({
                                                    lowLightTechnology:
                                                        !cameraFilter.lowLightTechnology,
                                                })
                                            }
                                        />
                                        <WDRTechnologyFilter
                                            selectedWDRTechnology={
                                                cameraFilter.WDRTechnology.active
                                                    ? cameraFilter.WDRTechnology.option
                                                    : undefined
                                            }
                                            onClick={(newWRDTechnology) =>
                                                updateFilter({
                                                    WDRTechnology:
                                                        newWRDTechnology ===
                                                            cameraFilter.WDRTechnology.option &&
                                                        cameraFilter.WDRTechnology.active
                                                            ? {
                                                                  active: false,
                                                                  option: '',
                                                              }
                                                            : {
                                                                  active: true,
                                                                  option: newWRDTechnology,
                                                              },
                                                })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.dayNightFunc}
                                            text={t.advancedFiltersGROUP.dayNightFunc}
                                            onClick={() =>
                                                updateFilter({
                                                    dayNightFunc: !cameraFilter.dayNightFunc,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid spacing="halfQuart">
                                        <OpticalZoomFilter
                                            selectedOpticalZoom={
                                                cameraFilter.opticalZoom.active
                                                    ? cameraFilter.opticalZoom.option
                                                    : undefined
                                            }
                                            onClick={(newOpticalZoom) =>
                                                updateFilter({
                                                    opticalZoom:
                                                        newOpticalZoom ===
                                                            cameraFilter.opticalZoom.option &&
                                                        cameraFilter.opticalZoom.active
                                                            ? {
                                                                  active: false,
                                                                  option: '',
                                                              }
                                                            : {
                                                                  active: true,
                                                                  option: newOpticalZoom,
                                                              },
                                                })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.varifocal}
                                            text={t.advancedFiltersGROUP.varifocal}
                                            onClick={() =>
                                                updateFilter({
                                                    varifocal: !cameraFilter.varifocal,
                                                })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.lensChangeable}
                                            text={t.advancedFiltersGROUP.lensChangeable}
                                            onClick={() =>
                                                updateFilter({
                                                    lensChangeable: !cameraFilter.lensChangeable,
                                                })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.remoteFocus}
                                            text={t.advancedFiltersGROUP.remoteFocus}
                                            onClick={() =>
                                                updateFilter({
                                                    remoteFocus: !cameraFilter.remoteFocus,
                                                })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.remoteZoom}
                                            text={t.advancedFiltersGROUP.remoteZoom}
                                            onClick={() =>
                                                updateFilter({
                                                    remoteZoom: !cameraFilter.remoteZoom,
                                                })
                                            }
                                        />
                                        <PillButton
                                            checkable
                                            selected={cameraFilter.imageStabilization}
                                            text={t.advancedFiltersGROUP.imageStabilization}
                                            onClick={() =>
                                                updateFilter({
                                                    imageStabilization:
                                                        !cameraFilter.imageStabilization,
                                                })
                                            }
                                        />
                                    </Grid>
                                </Stack>
                            </Stack>
                            <Stack vertical spacing="quart">
                                <Text style="semibold" color="grey5">
                                    {t.functionality}
                                </Text>
                                <Grid spacing="halfQuart">
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.alarmInputsOutputs}
                                        text={t.alarmInputsOutputs}
                                        onClick={() =>
                                            updateFilter({
                                                alarmInputsOutputs:
                                                    !cameraFilter.alarmInputsOutputs,
                                            })
                                        }
                                    />
                                    <ApplicationSupportFilter />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.audioSupport}
                                        text={t.advancedFiltersGROUP.audioSupport}
                                        onClick={() =>
                                            updateFilter({
                                                audioSupport: !cameraFilter.audioSupport,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.twoWayAudio}
                                        text={t.cameraSelectorUspsTwoWayAudio}
                                        onClick={() =>
                                            updateFilter({
                                                twoWayAudio: !cameraFilter.twoWayAudio,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.builtInMicrophone}
                                        text={t.advancedFiltersGROUP.builtInMicrophone}
                                        onClick={() =>
                                            updateFilter({
                                                builtInMicrophone: !cameraFilter.builtInMicrophone,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.IRLEDs}
                                        text={t.advancedFiltersGROUP.builtInIR}
                                        onClick={() =>
                                            updateFilter({
                                                IRLEDs: !cameraFilter.IRLEDs,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.zipStream}
                                        text={t.advancedFiltersGROUP.zipStream}
                                        onClick={() =>
                                            updateFilter({
                                                zipStream: !cameraFilter.zipStream,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.HDMIOutput}
                                        text={t.advancedFiltersGROUP.hdmi}
                                        onClick={() =>
                                            updateFilter({
                                                HDMIOutput: !cameraFilter.HDMIOutput,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.slotForMemoryCard}
                                        text={t.advancedFiltersGROUP.slotForMemoryCard}
                                        onClick={() =>
                                            updateFilter({
                                                slotForMemoryCard: !cameraFilter.slotForMemoryCard,
                                            })
                                        }
                                    />
                                    <ONVIFProfileFilter
                                        selectedProfiles={cameraFilter.ONVIFProfile.option}
                                        onClick={(profiles) =>
                                            updateFilter({
                                                ONVIFProfile: {
                                                    active: profiles.length > 0,
                                                    option: profiles,
                                                },
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.powerOverEthernet}
                                        text={t.advancedFiltersGROUP.powerOverEthernet}
                                        onClick={() =>
                                            updateFilter({
                                                powerOverEthernet: !cameraFilter.powerOverEthernet,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.wireless}
                                        text={t.advancedFiltersGROUP.wireless}
                                        onClick={() =>
                                            updateFilter({
                                                wireless: !cameraFilter.wireless,
                                            })
                                        }
                                    />

                                    <AnalyticsComputeCapabilityFilter
                                        selectedAnalyticsComputeCapability={
                                            cameraFilter.analyticsComputeCapability
                                        }
                                        onClick={updateAnalyticsComputeCapability}
                                    />
                                </Grid>
                            </Stack>
                            <Stack vertical spacing="quart">
                                <Text style="semibold" color="grey5">
                                    {t.deviceSecurity}
                                </Text>
                                <Grid spacing="halfQuart">
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.edgeVault}
                                        text={t.edgeVault}
                                        onClick={() =>
                                            updateFilter({
                                                edgeVault: !cameraFilter.edgeVault,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.secureBoot}
                                        text={t.secureBoot}
                                        onClick={() =>
                                            updateFilter({
                                                secureBoot: !cameraFilter.secureBoot,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.signedFirmware}
                                        text={t.signedOS}
                                        onClick={() =>
                                            updateFilter({
                                                signedFirmware: !cameraFilter.signedFirmware,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.signedVideo}
                                        text={t.signedVideo}
                                        onClick={() =>
                                            updateFilter({
                                                signedVideo: !cameraFilter.signedVideo,
                                            })
                                        }
                                    />
                                    <PillButton
                                        checkable
                                        selected={cameraFilter.TPM}
                                        text={t.tpm}
                                        onClick={() =>
                                            updateFilter({
                                                TPM: !cameraFilter.TPM,
                                            })
                                        }
                                    />
                                </Grid>
                            </Stack>
                        </Stack>
                    </Box>
                </Modal>
            )}
            <PillButton
                selected={Object.values(cameraFilter).some(isFilterActive)}
                text={
                    Object.values(cameraFilter).filter(isFilterActive).length > 0
                        ? `${t.moreFilters} \u00b7 ${
                              Object.values(cameraFilter).filter(isFilterActive).length
                          }`
                        : t.moreFilters
                }
                onClick={() => setShowFiltersModal(true)}
            />
        </>
    );
};

AdvancedFilters.displayName = 'AdvancedFilters';
