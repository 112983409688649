import * as React from 'react';
import type { IOptionProps } from 'app/components';
import { Box, Select } from 'app/components';
import { ProjectService } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { getSelectedPowerIlluminatorTime } from 'app/modules/common';
import { useSelector } from 'react-redux';
import { eventTracking } from 'app/core/tracking';

const getSelectTimeOptions = (): IOptionProps[] => {
    const selectTimeOptions: IOptionProps[] = [];
    for (let i = 1; i <= 24; i++) {
        selectTimeOptions.push({
            text: i.toString(),
            value: i,
        });
    }
    return selectTimeOptions;
};

export const SelectIlluminationTime: React.FC = () => {
    // Memoize the options to prevent unnecessary re-renders
    const selectTimeOptions = React.useMemo(getSelectTimeOptions, []);
    const projectService = useService(ProjectService);
    const selectedIlluminatorTime = useSelector(getSelectedPowerIlluminatorTime);

    const onChangeIlluminationTime = (timeInHours: number) => {
        eventTracking.logUserEvent(
            'Reports',
            'Select power report illuminatorTimeInHours',
            timeInHours.toString(),
        );
        projectService.updateCurrentProject({
            powerIlluminatorTimeInHours: timeInHours,
        });
    };

    return (
        <Box>
            <Select
                options={selectTimeOptions}
                onChange={onChangeIlluminationTime}
                value={selectedIlluminatorTime}
                noBorder
                adjustWidthToLongestOption={false}
                simpleArrowDown
            />
        </Box>
    );
};
SelectIlluminationTime.displayName = 'SelectIlluminationTime';
