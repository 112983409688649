import { injectable } from 'inversify';
import type {
    ISpeakerPropertiesFilterEntity,
    IItem,
    IItemEntity,
    IPersistence,
    IInstallationPointEntity,
} from 'app/core/persistence';
import { ItemService, InstallationPointService } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';
import type { Colors } from 'app/styles';
import { AccessoryService } from '../../accessory/services';
import { IAddProductProps } from '../models';

@injectable()
export class SpeakersService {
    constructor(
        private itemService: ItemService,
        private accessoryService: AccessoryService,
        private installationPointService: InstallationPointService,
    ) {}

    public async addOrUpdateDevice(
        productId: PiaId | null,
        filter: ISpeakerPropertiesFilterEntity,
        installationPoints: IInstallationPointEntity[],
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
    ) {
        if (itemToEdit) {
            return this.updateSpeaker(productId, filter, installationPoints, itemToEdit);
        } else if (newItemProps) {
            return this.addSpeaker(productId, newItemProps, filter);
        }
    }

    public async addSpeaker(
        speakerId: PiaId | null,
        newItemProps: IAddProductProps,
        filter: ISpeakerPropertiesFilterEntity,
    ) {
        const item = this.toIItem(
            newItemProps.name,
            '',
            newItemProps.notes || '',
            newItemProps.quantity,
            newItemProps.color,
            speakerId,
            filter,
        );

        return this.itemService.addToCurrentProject(item);
    }

    private async updateSpeaker(
        productId: PiaId | null,
        filter: ISpeakerPropertiesFilterEntity,
        installationPoints: IInstallationPointEntity[],
        itemToEdit: IPersistence<IItemEntity>,
    ) {
        const newPiaProduct = itemToEdit.productId !== productId;

        if (newPiaProduct) {
            const hasIncompatibleChildren = this.accessoryService.getHasPiaItemsToRemove(
                itemToEdit._id,
                productId,
            );

            if (hasIncompatibleChildren) {
                const confirm = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                );
                // If the user clicked cancel we should not continue
                if (!confirm) {
                    return;
                } else {
                    await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                        itemToEdit._id,
                        productId,
                    );
                }
            }

            if (installationPoints) {
                await this.installationPointService.updateInstallationPointsToNewSpeakerEntity(
                    itemToEdit.productId || undefined,
                    installationPoints,
                );
            }
        }

        const updatedSpeaker = await this.itemService.updateItem(itemToEdit._id, {
            name: itemToEdit.name,
            productId,
            description: itemToEdit.description,
            notes: itemToEdit.notes,
            quantity: itemToEdit.quantity,
            properties: {
                speaker: { filter },
            },
        });

        if (!updatedSpeaker) {
            throw new Error(`Speaker item with id: ${itemToEdit._id} could not be updated`);
        }

        return updatedSpeaker;
    }

    private toIItem(
        name: string,
        description: string,
        notes: string,
        quantity: number,
        color: Colors,
        selectedSpeakerId: number | null,
        filter: ISpeakerPropertiesFilterEntity,
    ): IItem {
        const item: IItem = {
            name,
            description,
            notes,
            productId: selectedSpeakerId,
            quantity,
            color,
            properties: {
                speaker: {
                    filter,
                },
            },
        };

        return item;
    }
}
