import type { ICustomItemSort, IUserPreferences } from 'app/core/persistence';
import { defaultCustomItemSortOrder, getItemLocalStorageJSON } from 'app/core/persistence';
import { reducerBuilder } from 'app/store';
import { ProjectCustomItemsActions } from './actions';
import type { ICustomItemsContainerState } from './models';

/** Gets sort order settings from local storage if they exist. Otherwise sort ascending by name. */
const getInitialSortOrder = () => {
    const userPreferences: Partial<IUserPreferences> = getItemLocalStorageJSON('UserPreferences', {
        customItemListSorting: defaultCustomItemSortOrder,
    });
    return userPreferences.customItemListSorting || defaultCustomItemSortOrder;
};

const initialState: ICustomItemsContainerState = {
    searchFilter: '',
    sortOrder: getInitialSortOrder(),
};

export const customItemsReducer = reducerBuilder<ICustomItemsContainerState>()
    .setInitialState(initialState)
    .onAction<string>(ProjectCustomItemsActions.FilterCustomItems, (state, { payload }) => ({
        ...state,
        searchFilter: payload,
    }))
    .onAction<ICustomItemSort>(ProjectCustomItemsActions.UpdateSort, (state, { payload }) => ({
        ...state,
        sortOrder: payload,
    }))
    .create();
