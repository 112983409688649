import * as React from 'react';
import { t } from 'app/translate';
import { ReadDirection, Text, SelectableList, Box, Spacer } from 'app/components';
import {
    TableHeaderSort,
    getBandwidthStorageEstimate,
    getProjectTotalDeviceCount,
    getCurrentProjectLocked,
} from 'app/modules/common';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ProjectDevicesActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import type { IProjectDevice } from '../../selectors';
import { getSortedProjectDevices, getDevicesWithAvailableChannelsIds } from '../../selectors';
import { debounce, isEqual } from 'lodash-es';
import type { IProjectDeviceSort, Id } from 'app/core/persistence';
import { UserPreferencesService } from 'app/core/persistence';
import { ChildItemControls } from './ChildItemControls.container';
import { DeviceListItem } from './DeviceListItem';

const CHUNK_SIZE = 100;
const CHUNK_LOAD_INTERVAL = 10;

interface IDeviceListProps {
    sortProperty: IProjectDeviceSort;
    deviceCount: number;
    bandwidthEstimate: string;
    storageEstimate: string;
    isFiltered: boolean;
    sortedDeviceIds: IProjectDevice[];
    devicesWithAvailableChannelsIds: Id[];
    locked: boolean;
}

interface IDeviceListState {
    lastIndex: number;
}

const mapStateToProps = (storeState: IStoreState): IDeviceListProps => {
    return {
        sortProperty: storeState.projectDevices.sortOrder,
        deviceCount: getProjectTotalDeviceCount(storeState),
        bandwidthEstimate: getBandwidthStorageEstimate(storeState).formattedBandwidth,
        storageEstimate: getBandwidthStorageEstimate(storeState).formattedStorage,
        isFiltered: storeState.projectDevices.deviceFilter.length > 0,
        sortedDeviceIds: getSortedProjectDevices(storeState),
        devicesWithAvailableChannelsIds: getDevicesWithAvailableChannelsIds(storeState),
        locked: getCurrentProjectLocked(storeState),
    };
};

class DeviceListContainer extends React.PureComponent<IDeviceListProps, IDeviceListState> {
    private actionService: ProjectDevicesActionService;
    private userPreferencesService: UserPreferencesService;

    private timer: number = 0;

    private loadDevices = debounce(() => {
        this.setState({ lastIndex: CHUNK_SIZE }, this.loadChunk);
    }, 200);

    constructor(props: IDeviceListProps) {
        super(props);
        this.actionService = ServiceLocator.get(ProjectDevicesActionService);
        this.userPreferencesService = ServiceLocator.get(UserPreferencesService);
        this.state = { lastIndex: CHUNK_SIZE };
    }

    public componentDidMount() {
        // Load state from local storage
        const sortProperty =
            this.userPreferencesService.get().deviceListSorting || this.props.sortProperty;
        this.actionService.setDeviceSortOrder(sortProperty);
        this.loadDevices();
    }

    public componentDidUpdate(prevProps: IDeviceListProps) {
        if (!isEqual(this.props.sortedDeviceIds, prevProps.sortedDeviceIds)) {
            clearTimeout(this.timer);
            this.loadDevices();
        }
    }

    public componentWillUnmount() {
        clearTimeout(this.timer);
        this.loadDevices.cancel();
    }

    private getDoorChildParent(itemId: Id): IProjectDevice | undefined {
        return this.props.sortedDeviceIds.find((device) => {
            return device.children.find((child) => child.id === itemId && child.type === 'door');
        });
    }

    private onSelect(itemId: Id) {
        const doorParent = this.getDoorChildParent(itemId);
        this.actionService.setPanelItem(doorParent?.id ?? itemId);
    }
    public render() {
        return (
            <SelectableList
                noBackground
                testId="table_devices_list"
                onSelect={(id) => this.onSelect(id)}
                onDeselect={this.actionService.closePanel}
                emptyText={this.props.isFiltered ? t.filterNoMatch : t.deviceListNoAddedDevices}
                emptyIcon="device"
                columnsToHideInCompactView={[8]}
                header={this.renderHeader()}
                footer={this.renderFooter()}
                outerLeftPadding="panel"
                compactRows
            >
                {this.renderDevices()}
            </SelectableList>
        );
    }

    private renderDevices = () => {
        return this.props.sortedDeviceIds.slice(0, this.state.lastIndex).map((device) => {
            let elements: JSX.Element[] = [];

            elements.push(<DeviceListItem key={device.id} itemId={device.id} />);

            const availableChannels =
                this.props.devicesWithAvailableChannelsIds.includes(device.id) &&
                !this.props.locked;

            elements = elements.concat(
                device.children.map((child, index) => (
                    <DeviceListItem
                        key={child.id}
                        itemId={child.id}
                        isChild={true}
                        parentId={device.id}
                        isLast={index === device.children.length - 1 && !availableChannels}
                    />
                )),
            );

            if (availableChannels) {
                elements.push(
                    <ChildItemControls key={`${device.id}-child-control`} parentId={device.id} />,
                );
            }

            return elements;
        });
    };

    private loadChunk = () => {
        clearTimeout(this.timer);

        if (this.state.lastIndex < this.props.sortedDeviceIds.length) {
            this.setState(
                {
                    lastIndex: Math.min(
                        this.state.lastIndex + CHUNK_SIZE,
                        this.props.sortedDeviceIds.length,
                    ),
                },
                () => {
                    this.timer = window.setTimeout(() => {
                        this.loadChunk();
                    }, CHUNK_LOAD_INTERVAL);
                },
            );
        }
    };

    private onSortingChanged = (sortProperty: IProjectDeviceSort['sort']) => {
        eventTracking.logUserEvent('Project Devices', 'Change Sort Order', sortProperty);
        let currentSorting = this.props.sortProperty;

        if (currentSorting === undefined) {
            throw Error(`Selected sorting: ${sortProperty} not found in options`);
        }

        if (currentSorting.direction === 'descending' || currentSorting.sort !== sortProperty) {
            currentSorting = { sort: sortProperty, direction: 'ascending' };
        } else {
            currentSorting = { sort: sortProperty, direction: 'descending' };
        }
        this.actionService.setDeviceSortOrder(currentSorting);
        this.userPreferencesService.set({ deviceListSorting: currentSorting });
    };

    private renderHeader = () => [
        <Text />,
        <Text />,
        <TableHeaderSort
            onClick={() => this.onSortingChanged('name')}
            direction={
                this.props.sortProperty.sort === 'name' ? this.props.sortProperty.direction : 'none'
            }
        >
            <Spacer spacing="quart" />
            <Text color="grey6">{t.deviceListName}</Text>
        </TableHeaderSort>,
        <TableHeaderSort
            onClick={() => this.onSortingChanged('quantity')}
            direction={
                this.props.sortProperty.sort === 'quantity'
                    ? this.props.sortProperty.direction
                    : 'none'
            }
        >
            <Spacer horizontal customSpacing="34px" />
            <Text color="grey6">{t.sortingGROUP.quantity}</Text>
        </TableHeaderSort>,
        <TableHeaderSort
            onClick={() => this.onSortingChanged('model')}
            direction={
                this.props.sortProperty.sort === 'model'
                    ? this.props.sortProperty.direction
                    : 'none'
            }
        >
            <Text color="grey6">{t.deviceListModel}</Text>
        </TableHeaderSort>,
        <Box paddingBottom="halfQuart">
            <Text color="grey6">{t.deviceListProfile}</Text>
        </Box>,
        <Box paddingBottom="halfQuart">
            <Text color="grey6">{t.addOns}</Text>
        </Box>,
        <Box paddingBottom="halfQuart">
            <Text color="grey6">{t.deviceListBandwidth}</Text>
        </Box>,
        <Box paddingBottom="halfQuart">
            <Text color="grey6">{t.deviceListStorage}</Text>
        </Box>,
        <Text></Text>,
    ];

    private renderFooter = () => {
        if (!this.props.deviceCount) {
            return undefined;
        }

        return [
            <Text />,
            <Text color="grey6" semiBold>
                {t.total}
            </Text>,
            <Text />,
            <Box spacing="quart">
                <Box minWidth={32} />
                <Text testId="tfoot_devices_quantity" color="grey6" semiBold>
                    {this.props.deviceCount}
                </Text>
            </Box>,
            <Text />,
            <Text />,
            <Text />,
            <Box>
                <ReadDirection direction="ltr">
                    <Text
                        testId="tfoot_bandwidth_estimate"
                        color="grey6"
                        style="semibold"
                        whiteSpace="nowrap"
                    >
                        {this.props.bandwidthEstimate}
                    </Text>
                </ReadDirection>
            </Box>,
            <Box>
                <ReadDirection direction="ltr">
                    <Text
                        testId="tfoot_storage_estimate"
                        color="grey6"
                        style="semibold"
                        whiteSpace="nowrap"
                    >
                        {this.props.storageEstimate}
                    </Text>
                </ReadDirection>
            </Box>,
            <Text />,
        ];
    };
}

export const DeviceList = connect(mapStateToProps)(DeviceListContainer);

DeviceList.displayName = 'DeviceList';
