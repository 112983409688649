import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Badge } from 'app/components';
import { getQuantityForCategory, getCurrentProjectSystemAccessoriesCount } from '../selectors';
import type { DisplayCategory } from './DisplayCategory';

interface ICountBadgeOwnProps {
    category?: DisplayCategory;
}

interface ICountBadgeProps {
    count: number;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ICountBadgeOwnProps,
): ICountBadgeProps => {
    const count = ownProps.category
        ? getQuantityForCategory(storeState)[ownProps.category]
        : getCurrentProjectSystemAccessoriesCount(storeState) ?? 0;

    return {
        count,
    };
};

class CountBadgeContainer extends React.Component<ICountBadgeProps> {
    public render() {
        return (
            this.props.count > 0 && (
                <Badge mini outline type="white" label={this.props.count.toString()} />
            )
        );
    }
}

export const CountBadge = connect(mapStateToProps)(CountBadgeContainer);
