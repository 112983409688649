import { createSelector } from 'reselect';
import { getCurrentProjectDirectChildItems } from '../../project';

export const getCurrentProjectDevices = createSelector(
    [getCurrentProjectDirectChildItems],
    (childItems) =>
        childItems.filter(
            ({ properties }) =>
                properties.camera ||
                properties.encoder ||
                properties.decoder ||
                properties.mainUnit ||
                properties.speaker ||
                properties.radarDetector ||
                properties.pac ||
                properties.peopleCounter ||
                properties.bodyWornCamera ||
                properties.cameraExtension ||
                properties.dockingStation ||
                properties.systemController ||
                properties.doorController ||
                properties.alerter ||
                properties.connectivityDevice ||
                properties.pagingConsole,
        ),
);
