import { Box, Stack, Text } from 'app/components';
import { t } from 'app/translate';
import * as React from 'react';
import type { CategoryEnum } from '../common';
import { translateCategory } from '../common';
import { DocumentationItem } from './components';
import type { IDocumentationDevice } from './models';

export const renderCategories = (
    categories: CategoryEnum[],
    documentationDevices: Record<CategoryEnum, IDocumentationDevice[]>,
) => {
    return categories.map((category) => (
        <Box
            paddingTop="panel"
            paddingBottom="cell"
            justifyContent="between"
            direction="column"
            key={category}
        >
            <Box justifyContent="between" paddingX="panel">
                <Text style="heading">{translateCategory(category)}</Text>
                <Text color="grey6">{t.documentationDatasheet}</Text>
            </Box>
            <Stack vertical lineBetweenColor="grey2" spacing="none">
                {documentationDevices[category].map((item) => (
                    <DocumentationItem
                        productId={item.id}
                        productName={item.name}
                        productCategory={item.category}
                        key={item.id}
                    />
                ))}
            </Stack>
        </Box>
    ));
};
