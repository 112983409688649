import * as React from 'react';
import { css } from '@emotion/css';
import classNames from 'classnames';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { extendableProps } from '../../services';
import type { IExtendableComponent } from '../../models';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import type { Icons } from './icons.generated';
import type { Property } from 'csstype';
import { iconFontSize } from 'app/styles/defaults';

export const IconStyle = css`
    font-family: 'axis-wt-icons' !important;
    speak: none;
    font-size: ${iconFontSize};
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    opacity: 0.64;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    font-feature-settings: 'liga';
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &[aria-hidden='true'] {
        visibility: hidden;
    }
`;

const OpaqueStyle = css`
    opacity: 1;
`;

const SpinnerStyle = css`
    @keyframes spinner {
        100% {
            transform: rotate(360deg);
        }
    }
    animation: spinner 1s ease 0s infinite;
`;

const ArrowStyle = css`
    [dir='rtl'] & {
        transform: rotate(180deg);
    }
`;

const InheritStyle = css`
    font-size: inherit;
`;

const ExtraSmallStyle = css`
    font-size: 13px;
`;

const SmallStyle = css`
    font-size: 14.4px;
`;

const MediumSmallStyle = css`
    font-size: 18px;
`;

const MediumStyle = css`
    font-size: 24px;
`;

const MediumLargeStyle = css`
    font-size: 28px;
`;

const LargeStyle = css`
    font-size: 40px;
`;

const ExtraLargeStyle = css`
    font-size: 48px;
`;

const ExtraExtraLargeStyle = css`
    font-size: 80px;
`;

const ExtraExtraExtraLargeStyle = css`
    font-size: 100px;
`;

type Size = 'xs' | 'sm' | 'ms' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'inherit' | 'max';

export interface IIconProps extends IExtendableComponent, IAutoTestable {
    /**
     * Set the color of the icon
     */
    color?: Colors;
    /**
     * Set transparency to 1 (ieconst  not transparent) instead of 0.54
     */
    opaque?: boolean;
    /**
     * Spin the icon
     */
    spinner?: boolean;
    /**
     * Set the size of the icon
     */
    size?: Size;

    /**
     * The icon to display
     */
    icon?: Icons;
    /**
     * Set the visibility to hidden and adds the
     * aria-hidden property. This hides the element
     * from view but still renders its bounding box.
     */
    hidden?: boolean;
    /**
     * The meaning of the icon.
     * Will populate the title attribute and show up on hover"
     */
    title?: string;
    /**
     * Override the color prop with color of your choice
     */
    colorOverride?: Property.Color;
}
export class Icon extends React.PureComponent<IIconProps> {
    public render() {
        const {
            testId,
            opaque,
            spinner,
            size,
            color,
            hidden,
            icon,
            title,
            colorOverride,
            ...extendedProps
        } = this.props;

        /** Check if icon is arrow */
        const iconName = this.props.icon;
        const arrowIcon =
            iconName === 'open' ||
            iconName === 'arrow_left' ||
            iconName === 'arrow_right' ||
            iconName === 'keyboard_arrow_left' ||
            iconName === 'keyboard_arrow_right' ||
            iconName === 'arrow_back' ||
            iconName === 'arrow_forward';

        const attributes = extendableProps(
            extendedProps,
            {
                style: {
                    color: colorOverride
                        ? colorOverride
                        : color
                          ? ColorsEnum[color]
                          : ColorsEnum.inherit,
                },
                className: classNames(IconStyle, {
                    [OpaqueStyle]: opaque,
                    [SpinnerStyle]: spinner,
                    [ArrowStyle]: arrowIcon,
                    [ExtraSmallStyle]: size === 'xs',
                    [SmallStyle]: size === 'sm',
                    [MediumSmallStyle]: size === 'ms',
                    [MediumStyle]: size === 'md',
                    [MediumLargeStyle]: size === 'ml',
                    [LargeStyle]: size === 'lg',
                    [ExtraLargeStyle]: size === 'xl',
                    [ExtraExtraLargeStyle]: size === 'xxl',
                    [ExtraExtraExtraLargeStyle]: size === 'xxxl',
                    [InheritStyle]: size === 'inherit',
                }),
            },
            true,
        );

        return (
            <i
                {...attributes}
                aria-hidden={hidden}
                title={title}
                data-test-id={toTestIdFormat(testId)}
            >
                {icon}
            </i>
        );
    }
}
