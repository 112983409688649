/*
 * Time24 is an immutable represention of a time of day.
 *
 * It supports a time range between '00:00' - '24:00'
 * instead of the standard '00:00' - '23:59'.
 * Only supports time of day with an hour and minute precision,
 * seconds are not allowed.
 */
export class Time24 {
    private hours: number;
    private minutes: number;

    /**
     * Creates a Time24 object from either a time string or a
     * decimal time number.
     *
     * The time string must use the format HH:mm or H:m.
     *
     * A decimal time number uses the integer part for
     * hours and the decimal part for minutes, e.g
     * 18.25 is the same as 18:15.
     *
     * @param time the time string or decimal time number.
     */
    constructor(time: string | number) {
        let hours = 0;
        let minutes = 0;
        if (typeof time === 'string') {
            const timeSplit = time.split(':');

            if (timeSplit.length <= 1) {
                throw new Error(`Time has an invalid format: ${time}`);
            } else if (timeSplit.length > 2) {
                throw new Error(`Time has an invalid format: ${time}`);
            }

            hours = this.parseHours(timeSplit[0]);
            minutes = this.parseMinutes(timeSplit[1]);
        } else if (typeof time === 'number') {
            time = Number(time);
            hours = Math.floor(time);
            minutes = Math.round((time % 1) * 60);
        } else {
            throw new Error(`Unsupported type: ${typeof time}`);
        }

        if (hours > 24) {
            throw new Error(`Time is invalid, hours exceeded: ${time}`);
        } else if (hours < 0) {
            throw new Error(`Time is invalid, hours are negative: ${time}`);
        } else if (minutes > 59 || (hours === 24 && minutes > 0)) {
            throw new Error(`Time is invalid, minutes exceeded: ${time}`);
        } else if (minutes < 0) {
            throw new Error(`Time is invalid, minutes are negative: ${time}`);
        }

        this.hours = hours;
        this.minutes = minutes;
    }

    public getHours(): number {
        return this.hours;
    }

    public getMinutes(): number {
        return this.minutes;
    }

    /**
     * Returns a string represenation of the time.
     */
    public toString(): string {
        const hours = this.getUnitWithLeadingZero(this.hours);
        const minutes = this.getUnitWithLeadingZero(this.minutes);

        return `${hours}:${minutes}`;
    }

    /**
     * Returns the time as a persistable string.
     */
    public toPersistable(): string {
        return this.toString();
    }

    /**
     * Returns a decimal representation of the time.
     * e.g 06:30 would become 6,5.
     */
    public toNumber(): number {
        const minutes = this.getMinutes() / 60;
        return this.getHours() + minutes;
    }

    private parseHours(hoursText: string) {
        try {
            return this.parseTimeUnit(hoursText);
        } catch (error) {
            throw new Error(`Hours consists of an invalid value: ${hoursText}`);
        }
    }

    private parseMinutes(minutesText: string) {
        try {
            return this.parseTimeUnit(minutesText);
        } catch (error) {
            throw new Error(`Minutes consists of an invalid value: ${minutesText}`);
        }
    }

    private parseTimeUnit(unitText: string): number {
        const unit = Number.parseInt(unitText);

        if (isNaN(unit)) {
            throw new Error('Unit is not a number');
        }

        return unit;
    }

    private getUnitWithLeadingZero(unit: number): string {
        return unit < 10 ? `0${unit}` : `${unit}`;
    }
}
