import * as React from 'react';
import { Stack, Icon, Text, Wrapper, PrintFix, ReadDirection } from 'app/components';
import { AppLoader, AppHeader } from 'app/modules/application';
import { Project } from '../routes/project';
import { Diagnostics } from './diagnostics';
import { PartnerConfig } from './partnerConfig';
import { getReadDirection, t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import ErrorBoundary from 'app/modules/common/errorBoundary/ErrorBoundary.container';
import { StandaloneAccessorySelector } from './accessorySelector';
import {
    AuthProvider,
    getIsStandalone,
    getIsCompactionAvailable,
    getCompactionInitiated,
} from 'app/modules/common';
import { useSelector, useDispatch } from 'react-redux';
import { ToasterContainer } from 'app/toaster';
import { css } from '@emotion/css';
import { EulaRoute } from './eula/EulaRoute';
import { EstimatesPageComponent } from 'app/modules/estimatePage';
import { UserProjectsRoute } from './user';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { StandalonePartnerProductSelectorView } from 'app/modules/addonSelector';
import { AppActions } from 'app/modules/application/AppActions';
import { sendAnalyticsMatomo } from 'app/core/tracking';
import { CouchDBCompaction } from './couchDBCompaction';
import { isDev, isLocal, isProduction } from 'app/isProduction';
import { PrivateRoute } from './components';
import { Styleguide } from './styleguide';

const portraitWarningStyle = css`
    display: none;

    @media only screen and (max-device-width: 900px) and (orientation: portrait) {
        display: flex;
        position: fixed;
        background: rgba(0, 0, 0, 0.9);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
    }
`;

export const App: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const direction = getReadDirection();
    // Update routerLocation in redux state when location changes
    React.useEffect(() => {
        dispatch({ type: AppActions.UpdateRouterLocation, payload: location });
    }, [dispatch, location]);

    const isStandAlone = useSelector(getIsStandalone);
    const isCompactionAvailable = useSelector(getIsCompactionAvailable);
    const compactionInitiated = useSelector(getCompactionInitiated);

    sendAnalyticsMatomo();

    return (
        <ReadDirection direction={direction}>
            <ErrorBoundary>
                <PrintFix>
                    <AuthProvider>
                        <Wrapper
                            minWidth={isStandAlone ? undefined : '1024px'}
                            color={isStandAlone ? undefined : 'grey1'}
                        >
                            {!isStandAlone && <AppHeader />}
                            <Wrapper hideOverflow horizontal>
                                <AppLoader>
                                    <Routes>
                                        <Route path="/diagnostics/" element={<Diagnostics />} />
                                        {!isProduction() && (
                                            <Route
                                                path="/estimates/"
                                                element={<EstimatesPageComponent />}
                                            />
                                        )}
                                        {(isCompactionAvailable || compactionInitiated) && (
                                            <Route
                                                path="/optimize/"
                                                element={<CouchDBCompaction />}
                                            />
                                        )}
                                        <Route
                                            path="/accessory-selector/"
                                            element={<StandaloneAccessorySelector />}
                                        />
                                        <Route
                                            path="/technology-partner-finder/"
                                            element={<StandalonePartnerProductSelectorView />}
                                        />
                                        <Route
                                            path="/styleguide/"
                                            element={
                                                <PrivateRoute
                                                    component={<Styleguide />}
                                                    condition={isLocal() || isDev()}
                                                    fallbackRoute="/"
                                                />
                                            }
                                        />
                                        <Route path="/project/:projectId/*" element={<Project />} />
                                        <Route path="/partnerconfig/" element={<PartnerConfig />} />
                                        <Route path="/terms-of-use/" element={<EulaRoute />} />
                                        <Route path="/" element={<UserProjectsRoute />} />
                                        {/* //* Redirect non-matching urls to root page */}
                                        <Route path="*" element={<Navigate replace to="/" />} />
                                    </Routes>
                                </AppLoader>
                            </Wrapper>
                            <ToasterContainer />
                            <div
                                style={{
                                    zIndex: AppConstants.modalDepth,
                                    position: 'absolute',
                                    insetInlineStart: 0,
                                    left: 0,
                                    top: 0,
                                }}
                                id={AppConstants.modalRootId}
                            />
                            {!isStandAlone && (
                                <div
                                    style={{ zIndex: AppConstants.portraitWarningDepth }}
                                    className={portraitWarningStyle}
                                >
                                    <Stack alignItems="center" vertical spacing="base">
                                        <Icon icon="screen_rotation" size="xl" color="white" />
                                        <Text align="center" color="white" bold large>
                                            {t.applicationPortraitWarningTitle}
                                        </Text>
                                        <Text color="white">
                                            {t.applicationPortraitWarningDescription}
                                        </Text>
                                    </Stack>
                                </div>
                            )}
                        </Wrapper>
                    </AuthProvider>
                </PrintFix>
            </ErrorBoundary>
        </ReadDirection>
    );
};

App.displayName = 'App';
