import { createSelector } from 'reselect';
import {
    PiaItemSoftwareCategory,
    filterProducts,
    PiaItemState,
    PiaAccessoryCategory,
    PiaItemWearablesCategory,
} from 'app/core/pia';
import {
    getPiaItems,
    getUseProductAllowlist,
    getProductAllowlist,
} from '../../piaDevices/selectors/getPiaDevices';
import { createProductAllowlistFilter } from '../../piaDevices/selectors/createProductAllowlistFilter';
import { getCurrentProjectRegions, getShouldShow2NSelector } from '../../project';
import { nameComparator } from 'app/utils';

const SystemAccessoryCategories: (PiaAccessoryCategory | PiaItemSoftwareCategory)[] = [
    PiaAccessoryCategory.AUDIOANDIO,
    PiaItemSoftwareCategory.AUDIO_MANAGEMENT_SOFTWARE,
    PiaAccessoryCategory.JOYSTICKSCONTR,
    PiaAccessoryCategory.INSTALLTOOLS,
    PiaAccessoryCategory.MISCELLANEOUS,
    PiaAccessoryCategory.BRIDGES,
    PiaAccessoryCategory.MICROPHONES,
    PiaAccessoryCategory.SERVERS,
    PiaAccessoryCategory.AMPLIFIERS,
    PiaAccessoryCategory.CABLESCON,
    PiaAccessoryCategory.ETHERNETOVERCOAX,
    PiaAccessoryCategory.MEDIACONVERTERS,
    PiaAccessoryCategory.SURGEPROTECTORS,
    PiaAccessoryCategory.POEEXTENDERS,
    PiaAccessoryCategory.CABINETS,
];

const systemAccessoriesCategories = (SystemAccessoryCategories as string[]).concat(
    PiaItemWearablesCategory.DEVICE,
);

/**
 * Get all currently available system accessories.
 * Filters on allowlist, region and discontinued state.
 * If project location is a 2N available country include 2N products, if not filter out 2N
 * (see const eligible2NCountries in getShouldShow2NSelector.ts)
 */
export const getAvailableSystemAccessoriesPiaItems = createSelector(
    [
        getPiaItems,
        getCurrentProjectRegions,
        getUseProductAllowlist,
        getProductAllowlist,
        getShouldShow2NSelector,
    ],
    (piaItems, regions, useAllowlist, allowlist, show2NAccessories) =>
        piaItems
            .filter(filterProducts.byState(PiaItemState.EXTERNALLY_ANNOUNCED))
            .filter(filterProducts.byCategories(systemAccessoriesCategories))
            .filter(filterProducts.byRegions(regions))
            .filter(filterProducts.byExternallyHidden())
            .filter((product) => createProductAllowlistFilter(allowlist, useAllowlist)(product.id))
            .filter(show2NAccessories ? () => true : filterProducts.byExcludingVendor('2N'))
            .sort(nameComparator),
);
