import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

@injectable()
export class Migration46to47 extends MigrationProviderBase {
    public from: number = 46;
    public to: number = 47;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            // due to a bug, this migration was a noop so we make it a cheaper noop
            return entity;
        };
    }
}
