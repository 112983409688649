export enum RepositoryAction {
    Add,
    Update,
    Delete,
    BulkAdd,
    BulkUpdate,
    BulkDelete,
    Lock,
    Get,
}
