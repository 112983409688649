import * as React from 'react';
import { Tab } from '../../ui/tab/Tab.component';
import { Box } from '../../containers';
import type { Colors } from 'app/styles';

type selectedItemId = string | number;

export interface ISegmentedControlItem {
    /**
     * text
     */
    text: string;
    /**
     * id
     */
    id: selectedItemId;
    /**
     * link
     */
    link?: string;
    /**
     * The automation test id of the tab
     */
    tabTestId?: string;
}

interface ISegmentedControl {
    items: ISegmentedControlItem[];
    selectedId: selectedItemId;
    wrap?: boolean;
    activeTabColor?: Colors;
    onChange?(selectedItemId: selectedItemId): void;
}

export class SegmentedControl extends React.Component<ISegmentedControl> {
    public render() {
        return (
            <Box wrap={this.props.wrap} position="relative">
                {this.props.items.map((item: ISegmentedControlItem) => (
                    <Tab
                        testId={`tab_btn_${item.tabTestId}`}
                        key={item.id}
                        onClick={this.clickHandler(item.id)}
                        text={item.text}
                        link={item.link}
                        activeColor={this.props.activeTabColor}
                        isActive={item.id === this.props.selectedId}
                    />
                ))}
            </Box>
        );
    }

    private clickHandler = (itemId: selectedItemId) => () => {
        if (this.props.onChange && itemId !== this.props.selectedId) {
            this.props.onChange(itemId);
        }
    };
}
