/*
 * DragControls for dragging three.js objects constrained to specific axes easily.
 *
 * Inpired by Three.js example code and
 * https://github.com/zz85/ThreeLabs/blob/master/DragControls.js
 */
import * as THREE from 'three';
import { EventEmitter } from 'events';

type Constraint = 'x' | 'y' | 'z' | 'xy' | 'xz' | 'yz' | 'xyz';

export class DragControls extends EventEmitter {
    private object: THREE.Object3D;
    private camera: THREE.Camera;
    private domElement: HTMLElement;

    private raycaster = new THREE.Raycaster();
    private mousePosition = new THREE.Vector2();
    private offset = new THREE.Vector3();
    private intersection = new THREE.Vector3();
    private plane = new THREE.Plane();
    private constraint: Constraint;
    private selectedObject: THREE.Object3D | null = null;
    private hoveredObject: THREE.Object3D | null = null;

    constructor(
        object: THREE.Object3D,
        camera: THREE.Camera,
        domElement: HTMLElement,
        constraint: Constraint = 'xyz',
    ) {
        super();
        this.object = object;
        this.camera = camera;
        this.domElement = domElement;
        this.constraint = constraint;

        domElement.addEventListener('mousedown', this.onMouseDown, false);
        domElement.addEventListener('mousemove', this.onMouseMove, false);
        domElement.addEventListener('mouseup', this.onMouseUp, false);
    }

    private onMouseDown = (e: MouseEvent) => {
        e.preventDefault();

        this.raycaster.setFromCamera(this.mousePosition, this.camera);

        const intersections = this.raycaster.intersectObject(this.object, true);
        if (intersections.length > 0) {
            this.selectedObject = intersections[0].object;
            if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
                this.offset
                    .copy(this.intersection)
                    .sub(this.object.getWorldPosition(new THREE.Vector3()));
            }
            this.domElement.style.cursor = 'move';
            this.emit('dragstart', this.object);
        }
    };

    private onMouseMove = (e: MouseEvent) => {
        e.preventDefault();

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        const rect = this.domElement.getBoundingClientRect();
        this.mousePosition.x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
        this.mousePosition.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;

        this.raycaster.setFromCamera(this.mousePosition, this.camera);

        if (this.selectedObject) {
            if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
                const newPos = this.constrain(this.intersection.sub(this.offset));

                this.emit('drag', newPos);
            }
            return;
        }

        // update the picking ray with the camera and mouse position

        // calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObject(this.object, true);

        if (intersects.length > 0) {
            const object = intersects[0].object;
            this.plane.setFromNormalAndCoplanarPoint(
                this.camera.getWorldDirection(this.plane.normal),
                object.position,
            );

            if (this.hoveredObject !== object) {
                this.domElement.style.cursor = 'pointer';
                this.hoveredObject = object;
                this.emit('hoveron', this.object);
            }
        } else {
            if (this.hoveredObject !== null) {
                this.domElement.style.cursor = 'auto';
                this.emit('hoveroff', this.object);
                this.hoveredObject = null;
            }
        }
    };

    private onMouseUp = (e: MouseEvent) => {
        e.preventDefault();

        if (this.selectedObject) {
            this.emit('dragend', this.object);
            this.selectedObject = null;
        }

        this.domElement.style.cursor = 'auto';
    };

    private constrain = (point: THREE.Vector3) => {
        const constrainedPoint = new THREE.Vector3(0, 0, 0);

        if (this.constraint.includes('x')) {
            constrainedPoint.x = point.x;
        }

        if (this.constraint.includes('y')) {
            constrainedPoint.y = point.y;
        }

        if (this.constraint.includes('z')) {
            constrainedPoint.z = point.z;
        }

        return constrainedPoint;
    };

    public destroy() {
        this.domElement.removeEventListener('mousedown', this.onMouseDown);
        this.domElement.removeEventListener('mousemove', this.onMouseMove);
        this.domElement.removeEventListener('mouseup', this.onMouseUp);
    }
}
