import { createCachedSelector } from 're-reselect';
import type { IApplicationItemEntity, IPersistence } from 'app/core/persistence';
import { getParentId, deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaApplication } from 'app/core/pia';
import { PiaRelationTypes } from 'app/core/pia';
import {
    getIdFromProps,
    getCurrentProjectItemsArray,
    getCurrentProjectItem,
    getCurrentProjectLocation,
    getPiaItemsRecord,
    toCacheKey,
    getAxisApplications,
    isAxis,
} from 'app/modules/common';

import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import type { IApplicationItem } from 'app/modules/common';
import { RANGE_APPLICATION_IDS } from 'app/core/common';
import { getPersistedApplications } from './getPersistedApplications';
import { mapIPiaApplicationToIApplicationItem, sortByIncluded } from '../utils';

export const getPersistedAxisApplicationsForId = createCachedSelector(
    [getCurrentProjectItemsArray, getIdFromProps],
    (currentProjectItems, itemId): IPersistence<IApplicationItemEntity>[] => {
        if (!currentProjectItems) return [];

        const items = currentProjectItems
            .filter((item) => getParentId(item) === itemId)
            .filter((item) =>
                deviceTypeCheckers.isApplication(item),
            ) as IPersistence<IApplicationItemEntity>[];
        return items;
    },
)(toCacheKey);

export const getCompatibleApplications = createCachedSelector(
    [
        getCurrentProjectItem,
        getAxisApplications,
        getCurrentProjectLocation,
        getPiaItemsRecord,
        getPersistedApplications,
    ],
    (item, axisApps, location, piaItemsRecord, selectedApplications) => {
        if (!item || item.productId === null || !location) return [];

        const piaApplication = piaItemsRecord[item.productId] as IPiaApplication;

        const compatibleIds = piaApplication.relations
            .filter((piaRelation) => piaRelation.relationType === PiaRelationTypes.Compatible)
            .map((rel) => rel.id);

        const compatibleApplications: IApplicationItem[] = axisApps
            .filter((app) => compatibleIds.includes(app.id))
            .map(mapIPiaApplicationToIApplicationItem(piaApplication.relations));

        const applicationsFromRelations = [
            ...compatibleApplications.filter(
                (compatibleApplication) =>
                    !selectedApplications.some(
                        (application) => compatibleApplication.productId === application.productId,
                    ),
            ),
            ...selectedApplications,
        ];

        return applicationsFromRelations;
    },
)(toCacheKey);

/**
 * Selector that returns compatible range applications IApplicationItem[] for the given productId:s,
 *  i.e compatible applications that are also
 * included in the RANGE_APPLICATION_IDS that represent range applications we support
 * (for now RANGE_APPLICATION_IDS = [AXIS_OBJECT_ANALYTICS, AXIS_PERIMETER_DEFENDER];)
 */
export const getCompatibleRangeApplications = createCachedSelector(
    [getCompatibleApplications],
    (compatibleApplications) => {
        const compatibleRangeApplications = compatibleApplications.reduce(
            (
                compatibleAnalyticApplications: IApplicationItem[],
                currentApplication: IApplicationItem,
            ) => {
                if (
                    currentApplication.acapId &&
                    RANGE_APPLICATION_IDS.includes(currentApplication.acapId)
                ) {
                    return [...compatibleAnalyticApplications, currentApplication];
                }
                return compatibleAnalyticApplications;
            },
            [],
        );
        return compatibleRangeApplications;
    },
)(toCacheKey);

export const getCompatibleAxisApplications = createCachedSelector(
    [getCurrentProjectItem, getAxisApplications, getCurrentProjectLocation, getPiaItemsRecord],
    (item, axisApps, location, piaItemsRecord) => {
        if (!item || item.productId === null || !location) return [];

        const piaApplication = piaItemsRecord[item.productId] as IPiaApplication;

        const compatibleIds = piaApplication.relations
            .filter((piaRelation) => piaRelation.relationType === PiaRelationTypes.Compatible)
            .map((rel) => rel.id);

        const compatibleApplications = axisApps.filter((app) => compatibleIds.includes(app.id));

        return compatibleApplications;
    },
)(toCacheKey);

const getIsELicensePreferred = (state: IStoreState) => state.addonSelector.isELicensePreferred;

export const getVisibleApplications = createSelector(
    [getCompatibleApplications, getIsELicensePreferred],
    (applications, isELicensePreferred) =>
        filterPreferredApplications(applications, isELicensePreferred),
);

export const getNonPersistedCompatibleApplications = createCachedSelector(
    [getCompatibleApplications, getPersistedApplications, getIsELicensePreferred],
    (compatible, selected, isELicensePreferred) => {
        const selectedIds = selected.map((app) => app.productId);

        return filterPreferredApplications(compatible, isELicensePreferred)
            .filter((app) => !selectedIds.includes(app.productId))
            .sort(sortByIncluded);
    },
)(toCacheKey);

export const getMultipleApplicationsSelected = createSelector(
    [getPersistedApplications],
    (selected) => selected.length > 1,
);

export const getIsPartnerAcapAdded = createSelector([getPersistedApplications], (selected) =>
    selected.some((acap) => !isAxis(acap.vendor)),
);

function filterPreferredApplications(
    applications: IApplicationItem[],
    isELicensePreferred: boolean,
) {
    const preferredApplications = applications.filter(
        (application) => application.isELicense === isELicensePreferred,
    );

    const nonPreferredApplications = applications.filter(
        (application) => application.isELicense !== isELicensePreferred,
    );

    const preferredApplicationIds = preferredApplications.map((application) => application.acapId);

    const notAvailableAsPreferredApplications = nonPreferredApplications.filter(
        (application) => !preferredApplicationIds.includes(application.acapId),
    );

    const filteredApplications = [...preferredApplications, ...notAvailableAsPreferredApplications];

    const sortByName = (a: IApplicationItem, b: IApplicationItem) => a.name.localeCompare(b.name);
    filteredApplications.sort(sortByName);

    return filteredApplications;
}
