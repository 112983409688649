import { injectable } from 'inversify';
import type {
    ICustomCameraPropertiesEntity,
    Id,
    IItemEntity,
    IPersistence,
} from 'app/core/persistence';
import {
    toErrorMessage,
    CameraType,
    CurrentProjectService,
    ItemService,
    Resolution,
    InstallationPointService,
} from 'app/core/persistence';
import type { PoeClass } from 'app/core/pia';
import { t } from 'app/translate';
import { convert } from 'axis-webtools-util';
import { toaster } from 'app/toaster';
import { saveAs } from 'file-saver';
import { isNumber } from 'lodash-es';
import { eventTracking } from 'app/core/tracking';

/**
 * Initializes and updates properties on a custom camera
 *
 * Default values are set when initializing a camera
 * When deactivating custom camera, the property `activated` is set to false ( and assigned values are still saved )
 */

const IMPORT_FAILED = 'Failed to load or parse custom camera.';
const UNABLE_TO_IMPORT = 'Unable to import custom camera. ';
const NOT_CUSTOM_CAMERA = 'Not a custom camera';

@injectable()
export class CustomCameraSettingsService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private installationPointService: InstallationPointService,
    ) {}

    public async activateCustomCamera(itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera) {
            return undefined;
        }

        if (entity.properties.camera.customCameraProperties) {
            return this.toggleCustomCamera(itemId, entity, true);
        }

        eventTracking.logUserEvent('Custom Camera', 'Add custom camera');

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        activated: true,
                        modelName: t.genericCamera,
                        bandwidth: 0,
                        cameraType: CameraType.Fixed,
                        horizontalFovMax: 145,
                        horizontalFovMin: 0,
                        powerConsumption: 0,
                        resolutionHorizontal: 1280,
                        resolutionVertical: 720,
                    },
                },
            },
        });
    }

    public async deactivateCustomCamera(itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        return this.toggleCustomCamera(itemId, entity, false);
    }

    private async toggleCustomCamera(
        itemId: Id,
        entity: IPersistence<IItemEntity>,
        activated: boolean,
    ) {
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        eventTracking.logUserEvent('Custom Camera', 'Toggle custom camera', String(activated));

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        ...entity.properties.camera.customCameraProperties,
                        activated,
                    },
                },
            },
        });
    }

    public exportCustomCamera(customCamera: ICustomCameraPropertiesEntity, name: string) {
        const partialCustomCamera: Partial<ICustomCameraPropertiesEntity> = {
            ...customCamera,
            activated: undefined,
        };

        const fileContents = JSON.stringify(partialCustomCamera);
        const fileBlob = new Blob([fileContents], {
            type: 'application/json',
        });
        const filename = name + '.json';

        saveAs(fileBlob, filename);
    }

    public async importCustomCamera(file: File, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');

        if (!entity.properties.camera) {
            return undefined;
        }
        try {
            const content = await this.readFile(file);
            const customCamera = JSON.parse(content) as ICustomCameraPropertiesEntity;

            if (!this.isCustomCameraProperties(customCamera)) {
                throw new Error(NOT_CUSTOM_CAMERA);
            }

            return this.itemService.updateItem(itemId, {
                ...entity,
                properties: {
                    ...entity.properties,
                    camera: {
                        ...entity.properties.camera,
                        customCameraProperties: { ...customCamera, activated: true },
                    },
                },
            });
        } catch (error) {
            console.error(UNABLE_TO_IMPORT, toErrorMessage(error));
            toaster.error(t.uploadCustomCameraHeader, t.uploadCustomCamera);
        }
    }

    private isCustomCameraProperties(
        device: ICustomCameraPropertiesEntity,
    ): device is ICustomCameraPropertiesEntity {
        return (
            !!device.modelName &&
            !!device.cameraType &&
            isNumber(device.bandwidth) &&
            isNumber(device.horizontalFovMax) &&
            isNumber(device.horizontalFovMin) &&
            isNumber(device.powerConsumption) &&
            isNumber(device.resolutionHorizontal) &&
            isNumber(device.resolutionVertical)
        );
    }

    private async readFile(file: File) {
        const content: string = await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result && typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject();
                }
            };

            reader.onerror = () => {
                reject(new Error(IMPORT_FAILED));
            };

            reader.readAsText(file);
        });
        return content;
    }

    public async updateModelName(value: string, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        ...entity.properties.camera?.customCameraProperties,
                        modelName:
                            value && value.trim() !== ''
                                ? value
                                : entity.properties.camera.customCameraProperties?.modelName,
                    },
                },
            },
        });
    }

    public async updateCameraType(value: CameraType, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        eventTracking.logUserEvent('Custom Camera', 'Update camera type', value);

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        ...entity.properties.camera?.customCameraProperties,
                        cameraType: value,
                    },
                },
            },
        });
    }

    public async updateResolution(value: string, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        const resolution = new Resolution(value);

        eventTracking.logUserEvent('Custom Camera', 'Update resolution', value);

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        ...entity.properties.camera?.customCameraProperties,
                        resolutionHorizontal: resolution.getHorizontal(),
                        resolutionVertical: resolution.getVertical(),
                    },
                },
            },
        });
    }

    public async updateHorizontalFOV(min: number, max: number, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        // Update installation points
        const installationPoints =
            await this.installationPointService.getInstallationPointDescendants(itemId);
        for (const ip of installationPoints.descendants) {
            for (const sensor of ip.sensors) {
                if (sensor.settings.horizontalFov < min) {
                    await this.installationPointService.updateSensorHorizontalFov(
                        ip._id,
                        ip.sensors.indexOf(sensor),
                        min,
                    );
                } else if (sensor.settings.horizontalFov > max) {
                    await this.installationPointService.updateSensorHorizontalFov(
                        ip._id,
                        ip.sensors.indexOf(sensor),
                        max,
                    );
                }
            }
        }

        eventTracking.logUserEvent('Custom Camera', 'Update horizontal FOV', min + ' - ' + max);

        const panoramaMode =
            max < 170 ? false : entity.properties.camera.filter.panoramaMode || 'vertical';

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    filter: {
                        ...entity.properties.camera.filter,
                        panoramaMode,
                    },
                    customCameraProperties: {
                        ...entity.properties.camera?.customCameraProperties,
                        horizontalFovMin: min,
                        horizontalFovMax: max,
                    },
                },
            },
        });
    }

    public async updatePowerConsumption(value: number, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        eventTracking.logUserEvent('Custom Camera', 'Update power consumption', String(value));

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        ...entity.properties.camera?.customCameraProperties,
                        powerConsumption: value,
                    },
                },
            },
        });
    }

    public async updatePoeClass(value: PoeClass, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        eventTracking.logUserEvent('Custom Camera', 'Update PoE', String(value));

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        ...entity.properties.camera?.customCameraProperties,
                        poe: value,
                    },
                },
            },
        });
    }

    public async updateBandwidth(value: number, itemId: Id) {
        const entity = this.currentProjectService.getEntity(itemId, 'item');
        if (!entity.properties.camera?.customCameraProperties) {
            return undefined;
        }

        eventTracking.logUserEvent('Custom Camera', 'Update bandwidth', String(value));

        return this.itemService.updateItem(itemId, {
            ...entity,
            properties: {
                ...entity.properties,
                camera: {
                    ...entity.properties.camera,
                    customCameraProperties: {
                        ...entity.properties.camera.customCameraProperties,
                        bandwidth: convert.fromMega(value),
                    },
                },
            },
        });
    }
}
