import * as React from 'react';
import { Modal } from './Modal.component';
import { Box } from '../../containers';
import { Text } from '../../text';
import { Button } from '../button';

interface IMessageModalProps {
    title: string;
    message: string;
    buttonText: string;
    onClose(): void;
}

export const MessageModal: React.FunctionComponent<IMessageModalProps> = (props) => (
    <Modal title={props.title} onClose={props.onClose}>
        <Box direction="column">
            <Text>{props.message}</Text>
            <Box justifyContent="end" paddingTop="base">
                <Button text onClick={props.onClose}>
                    {props.buttonText}
                </Button>
            </Box>
        </Box>
    </Modal>
);

MessageModal.displayName = 'MessageModal';
