import { injectable } from 'inversify';
import type { IBaseEntity, IProfileEntity } from '../../entities';
import { MigrationProviderBase } from '../MigrationProviderBase';

const entityIsProfile = (entity: IBaseEntity): entity is IProfileEntity =>
    entity.type === 'profile';

/**
 * Add property for zip stream storage profile, default value 'classic'.
 */
@injectable()
export class Migration42to43 extends MigrationProviderBase {
    public from: number = 42;
    public to: number = 43;

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsProfile(entity) && entity.zipstream.zipProfile === undefined) {
                entity.zipstream.zipProfile = 'classic';
            }

            return entity;
        };
    }
}
