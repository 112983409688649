import type { BandwidthOptions } from './BandwidthOptions';
import { BitrateCalculator } from './Bitrate.calculator';
import type { Condition } from './Condition';
import { constants } from './constants';
import { linspace, Guassian } from '../../utils';
import interp1 from '../../utils/interpolate';

/**
 * Used for dynamic mode fps calculation
 */
export class DynamicFpsCalculator extends BitrateCalculator {
    constructor(bandwidthOptions: BandwidthOptions) {
        super(bandwidthOptions);
    }

    public getBitrate(condition: Condition) {
        const levelDists = this.calcMotionLevelDist(
            condition.motionFrac,
            this.bandwidthOptions.scenario.motionRef[condition.motion],
        );

        return levelDists.reduce((prev, curr) => {
            const calcFrameRate = this.calcFrameRate(curr.level);
            const calcMotionLevel = this.calcAdjustedMotionLevel(
                curr.level,
                calcFrameRate,
                condition.weather,
                this.bandwidthOptions.scenario.indoor,
            );

            return (prev +=
                this.calcBitrate(condition, calcMotionLevel, calcFrameRate) * curr.dist);
        }, 0);
    }

    /**
     * Use predefined tables to calculate what fraction of fps is used for dfps.
     */
    private interpolateDfpsFactorFromMotionLevel(motion: number): number {
        // When using dynamic fps, the fps used for calculation is a scaled version of the user input fps.
        // DFPS_MOTION_LEVELS and DFPS_FACTORS are used to interpolate the scaling factor from the motion level.
        // SCALE_FACTOR is used to convert from pixel color change to pixel movement. Padded with extra value at
        // end for easy interpolation.
        return interp1(constants.motionLevel.dfpsMotionLevels, constants.motionLevel.dfpsFactors, [
            motion,
        ])[0];
    }

    /**
     * Calculate frameRate. Only affects fps if we are using dynamic fps.
     */
    private calcFrameRate(motion: number) {
        const { frameRate, fpsMin } = this.bandwidthOptions;

        const interpolate = this.interpolateDfpsFactorFromMotionLevel(motion) * frameRate;

        // Clamp frameRate above fpsMin if frameRate is greater than fpsMin.
        // This logic is copied from libaxe.
        return Math.max(interpolate, Math.min(frameRate, fpsMin));
    }

    private calcMotionLevelDist(motionFrac: number, motion: number) {
        const { timeInMotionMax, timeInMotionMin, stdScale, adjustmentTerm } =
            constants.motionLevel;

        // Clamp fraction_in_motion, prevents division by zero.
        const fractionInMotion = Math.max(Math.min(motionFrac, timeInMotionMax), timeInMotionMin);

        const stdSceneMotion = motion / stdScale;

        const fractionInMotionAdjusted = Math.pow(fractionInMotion, 1 + adjustmentTerm);

        const stdNoMotion =
            ((Math.sqrt(Math.PI) / Math.sqrt(2)) *
                fractionInMotion *
                motion *
                (1 - Math.pow(fractionInMotion, adjustmentTerm))) /
            (1 - Math.pow(fractionInMotion, 1 + adjustmentTerm));

        const { thresholds, levels } = this.generateWeightedLevels(
            stdSceneMotion,
            stdNoMotion,
            motion,
        );

        const distSceneMotion = new Guassian(0, stdNoMotion)
            .pdf(levels)
            .map((level) => level * (1 - fractionInMotionAdjusted) * 2);

        const distNoMotion = new Guassian(motion, stdSceneMotion)
            .pdf(levels)
            .map((level) => level * fractionInMotionAdjusted);

        const distTotal = distSceneMotion.map((level, index) => level + distNoMotion[index]);
        const dist = this.normalizeDistribution(levels, distTotal, thresholds);

        return levels.map((level, index) => ({ level, dist: dist[index] }));
    }

    /**
     *  Normalize distribution of motion levels. Controlled by the sampling interval
     *  and the threshold. This normalization assumes that the sampling is split into
     *  three sections, divided at 'thresholds'.
     */
    private normalizeDistribution(levels: number[], distribution: number[], thresholds: number[]) {
        const dist = distribution.slice(0);

        const centerIndex = Math.floor(
            this.bandwidthOptions.numSamples *
                (constants.motionLevel.sampleDistributionWeights[0] +
                    constants.motionLevel.sampleDistributionWeights[1] / 2),
        );

        const stepSizeLeft = levels[1] - levels[0];
        const stepSizeCenter = levels[centerIndex] - levels[centerIndex - 1];
        const stepSizeRight = levels[levels.length - 1] - levels[levels.length - 2];

        for (let index = 0; index < dist.length; index++) {
            if (levels[index] > thresholds[1]) {
                dist[index] *= stepSizeRight;
            } else if (levels[index] > thresholds[0]) {
                dist[index] *= stepSizeCenter;
            } else {
                dist[index] *= stepSizeLeft;
            }
        }
        return dist;
    }

    private generateWeightedLevels(stdSceneMotion: number, stdNoMotion: number, motion: number) {
        const { stdThreshold, motionMax, motionMin, sampleDistributionWeights } =
            constants.motionLevel;

        const { numSamples } = this.bandwidthOptions;

        const sampleDensityThresholds = [
            stdNoMotion * stdThreshold,
            motion + stdThreshold * stdSceneMotion,
        ];

        // Generate list of motion levels in range [0, 1].
        const levelsLeft = linspace(
            motionMin,
            sampleDensityThresholds[0],
            Math.floor(numSamples * sampleDistributionWeights[0]),
            false,
        );

        const levelsCenter = linspace(
            sampleDensityThresholds[0],
            sampleDensityThresholds[1],
            Math.floor(numSamples * sampleDistributionWeights[1]),
            false,
        );

        const levelsRight = linspace(
            sampleDensityThresholds[1],
            motionMax,
            Math.floor(numSamples * sampleDistributionWeights[2]),
        );

        const levels = levelsLeft.concat(levelsCenter).concat(levelsRight);

        return { levels, thresholds: sampleDensityThresholds };
    }

    /**
     * Calculate motion level from FPS, weather and scene motion.
     * Higher fps leads to a decreased fps_factor since there is less temporal
     * movement compared to low fps. input_ml is used when calculating bitrate
     * for a specific motion value, used when looping over the entire range of motion
     * values used in H264/5 calculations.
     */
    private calcAdjustedMotionLevel(
        calcMotionLevel: number,
        calcFrameRate: number,
        isWeather: boolean,
        indoor: boolean,
    ) {
        const { minFpsFactor, maxFpsFactor } = constants.motionLevel;

        const fpsFactor = Math.max(
            minFpsFactor,
            Math.min(maxFpsFactor, Math.sqrt(constants.fpsRef / calcFrameRate)),
        );

        const ml_weather = isWeather && !indoor ? constants.weatherMotionLevel : 0;

        return fpsFactor * calcMotionLevel + ml_weather;
    }
}
