import * as React from 'react';
import { licenses } from './licenses.generated';

export const Licenses: React.FunctionComponent = () => (
    <>
        {licenses.map((license, index) => (
            <div key={`${license.name}_${index}`}>
                <h2>
                    {license.name} - {license.version}
                </h2>
                <p style={{ whiteSpace: 'pre-line' }}>{license.licenseText}</p>
            </div>
        ))}
    </>
);

Licenses.displayName = 'Licenses';
