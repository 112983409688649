import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { Id, IInstallationPointEntity, IPersistence } from 'app/core/persistence';
import { DataWidget, Text } from 'app/components';
import type { IAutoTestable } from 'app/components';
import { getGeoLocatedInstallationPoint } from '../../selectors';

interface ILocationDetailsProps extends IAutoTestable {
    installationPointId?: Id;
    modelName: string;
}

export const LocationDetails: React.FC<ILocationDetailsProps> = ({
    installationPointId,
    modelName,
}) => {
    const installationPoint = useSelector<
        IStoreState,
        IPersistence<IInstallationPointEntity> | undefined
    >((store) => getGeoLocatedInstallationPoint(store, installationPointId));

    return installationPoint ? (
        <DataWidget
            testId={`installation_report_location_${installationPointId}`}
            icon="place"
            text=""
            description={t.location}
        >
            <Text style="subheader" testId={`${modelName}_coordinates`}>
                {`${installationPoint.location.lat.toFixed(
                    6,
                )}, ${installationPoint.location.lng.toFixed(6)}`}
            </Text>
        </DataWidget>
    ) : null;
};

LocationDetails.displayName = 'LocationDetails';
