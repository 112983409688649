import { debounce } from 'lodash-es';
import React from 'react';
import { useResizeObserver, useWindowSize } from 'app/hooks';
import { getTextWidth } from 'app/modules/common';
import { gap, getOverflowingIndex, iconSize, tabXPadding } from './getOverflowingIndex';

const dragHandleWidth = 12;
const semibold = '600';

/**
 * Hook to find the index of the first element that overflows the container
 * @param containerRef - Ref to the container element
 * @param textsElements - Array of text elements
 * @param selectedIndex - Index of the selected element
 * @returns The index of the first element that overflows the container or -1 if none of the elements overflow
 * @example const overflowingIndex = useFindOverflowingIndex(containerRef, elementWidths);
 * */
export const useFindOverflowingIndex = (
    containerRef: React.RefObject<HTMLElement>,
    textsElements: string[],
    selectedIndex: number,
) => {
    const [overflowingIndex, setOverflowingIndex] = React.useState<number>(-1);
    const [containerWidth, setContainerWidth] = React.useState(0);
    useResizeObserver({
        ref: containerRef,
        onResize: (size) => debounce(() => setContainerWidth(size.width ?? 0), 50),
    });
    const windowSize = useWindowSize();
    const elementWidths = React.useMemo(
        () =>
            textsElements.map(
                (text) =>
                    getTextWidth(text, 12, semibold) +
                    tabXPadding +
                    iconSize +
                    dragHandleWidth +
                    gap,
            ),
        [textsElements],
    );

    React.useEffect(() => {
        const { left } = containerRef.current?.getBoundingClientRect() ?? {
            left: 0,
        };

        const overflowIndex = getOverflowingIndex(
            elementWidths,
            selectedIndex,
            left,
            windowSize.width,
        );

        setOverflowingIndex(overflowIndex);
    }, [
        windowSize.width,
        elementWidths,
        selectedIndex,
        textsElements,
        containerRef,
        containerWidth,
    ]);

    return overflowingIndex;
};
