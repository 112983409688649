import * as React from 'react';
import { ItemService, type IItemEntity, type IPersistence } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { Box, Border, Clickable, Icon, Text } from 'app/components';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import { getCurrentProjectLocked, getUnplacedDevicesQuantity } from 'app/modules/common';
import type { IStoreState } from 'app/store';

interface IUnplacedDevicesProps {
    item: IPersistence<IItemEntity>;
}

export const DevicesNotPlacedOnMap: React.FC<IUnplacedDevicesProps> = ({ item }) => {
    const unplacedQuantity = useSelector((state: IStoreState) =>
        getUnplacedDevicesQuantity(state, item._id),
    );
    const placedQuantity = item.quantity - unplacedQuantity;
    const disabledDeleteUnplaced = useSelector(getCurrentProjectLocked);
    const itemService = useService(ItemService);
    const handleDeleteUnplaced = () => {
        if (placedQuantity === 0) return;
        return itemService.updateItem(item._id, {
            quantity: placedQuantity,
        });
    };

    return (
        <Box direction="column" flex="fullWidth" width="100%">
            <Border bottomWidth={1} color="grey4">
                <Box justifyContent="between" flex="fullWidth">
                    <Text style="semibold" color="grey5">
                        {t.notOnMap}
                    </Text>
                </Box>
            </Border>
            <Box paddingTop="half" flex="fullWidth" justifyContent="between">
                <Text color="grey9" testId={`unplaced_devices_${unplacedQuantity}`}>
                    {t.devicesNotOnMap(unplacedQuantity)}
                </Text>
                {placedQuantity > 0 && (
                    <Clickable disabled={disabledDeleteUnplaced} onClick={handleDeleteUnplaced}>
                        <Box paddingRight="halfCell" alignItems="center">
                            <Icon opaque icon="delete" color="blue" size="sm" />
                            <Text color="blue">{t.delete}</Text>
                        </Box>
                    </Clickable>
                )}
            </Box>
        </Box>
    );
};

DevicesNotPlacedOnMap.displayName = 'DevicesNotPlacedOnMap';
