import { createSelector } from 'reselect';
import type { Id, IFloorPlanEntity } from 'app/core/persistence';
import {
    getCurrentProjectFloorPlansArray,
    mapInstallationPointToFloorPlan,
} from 'app/modules/common';
import { getProjectInstallationPoints } from './getProjectInstallationPoints';
import { creationDateReverseComparator } from 'app/utils';

/**
 * Map installation points to floor plans.
 * @param installationPoints The installation points to map.
 * @param floorPlans The floor plans to map to.
 */
export const getFloorPlanForInstallationPoint = createSelector(
    [getProjectInstallationPoints, getCurrentProjectFloorPlansArray],
    (installationPoints, floorPlans) =>
        installationPoints.reduce((acc: Record<Id, IFloorPlanEntity>, installationPoint) => {
            const floorPlan = mapInstallationPointToFloorPlan(
                installationPoint,
                installationPoints,
                floorPlans,
            );

            if (floorPlan) {
                acc[installationPoint._id] = floorPlan;
            }
            return acc;
        }, {}),
);

/**
 * Get a function returning the installation points of a device with map type if available.
 * @param deviceId The device id to get installation points for.
 */
export const getInstallationPointsOnFloorPlansSortByCreationFactory = createSelector(
    [getProjectInstallationPoints, getFloorPlanForInstallationPoint],
    (installationPoints, ipMap) => (deviceId: string) => {
        const installationPointsOnFloorPlan = installationPoints
            .filter((ip) => ipMap[ip._id])
            .filter((ip) => ip.parentDevice._id === deviceId)
            .sort(creationDateReverseComparator);
        const ipWithMapType = installationPointsOnFloorPlan.map((ip) => ({
            ...ip,
            mapType: ipMap[ip._id].mapType,
        }));
        return ipWithMapType;
    },
);
