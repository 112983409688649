import * as React from 'react';
import { t } from 'app/translate';
import {
    Box,
    Stack,
    Icon,
    Heading,
    Border,
    Button,
    Clickable,
    UploadFile,
    Modal,
    LoadingIndicator,
} from 'app/components';

import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { ServiceLocator, useService } from 'app/ioc';
import { StorageUsed } from 'app/modules/maps';
import { ImageService, MAX_IMAGE_SIZE_BYTES, UserSettingsService } from 'app/core/persistence';
import { CommonActionService, getIsLocalProject } from 'app/modules/common';
import { toaster } from 'app/toaster';
import { getUploadedLogoKey } from './selectors';
import { ModalService } from 'app/modal';
import { usePromise } from 'app/hooks';

const ALLOWED_IMAGE_TYPES = '.jpg, .jpeg, .png';
const MIME_TYPES = new Set(['image/png', 'image/jpg', 'image/jpeg']);
const CONTENT_WIDTH = '400px';

interface ILogoProps {
    /**
     * If add logo should be hidden
     */
    hideAddLogo?: boolean;
}
export const Logo: React.FunctionComponent<ILogoProps> = (props) => {
    const [file, setFile] = React.useState<null | File>(null);
    const [selectedLogoURL, setSelectedLogoURL] = React.useState<null | string>(null);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [isUploadingOrDeletingLogo, setIsUploadingOrDeletingLogo] =
        React.useState<boolean>(false);
    const imageService = useService(ImageService);
    const userSettingsService = useService(UserSettingsService);

    const [commonActionService] = React.useState<CommonActionService>(
        ServiceLocator.get(CommonActionService),
    );

    const [modalService] = React.useState<ModalService>(ServiceLocator.get(ModalService));

    const uploadedLogoKey = useSelector<IStoreState, string | null>((state) =>
        getUploadedLogoKey(state),
    );

    const isLocalProject = useSelector<IStoreState, boolean>((state) => getIsLocalProject(state));

    const imagePromise = React.useMemo(
        () => imageService.getLogoUrlAsBase64(uploadedLogoKey || ''),
        [imageService, uploadedLogoKey],
    );

    const { result: logoImage } = usePromise(imagePromise, [imagePromise]);

    const onFileSelected = (selectedFile: File) => {
        if (MIME_TYPES.has(selectedFile.type)) {
            setFile(selectedFile);
            setSelectedLogoURL(URL.createObjectURL(selectedFile));
        } else {
            toaster.warning(t.uploadFailedHeader, t.supportedFileTypes(ALLOWED_IMAGE_TYPES));
        }
    };

    const showFileTooLargeDialog = async () => {
        setShowModal(false);
        await modalService.createConfirmDialog({
            header: t.addFileTooLargeHeading,
            body: t.addFileTooLarge,
            confirmButtonText: t.close,
        })();
        setShowModal(true);
    };

    const addLogo = async (logoFile: File) => {
        const hasValidFileSize = logoFile.size <= MAX_IMAGE_SIZE_BYTES;
        if (hasValidFileSize) {
            setIsUploadingOrDeletingLogo(true);
            setShowModal(false);
            await userSettingsService.addLogo(logoFile);
            commonActionService.getUserSettings();
            setIsUploadingOrDeletingLogo(false);
            commonActionService.getUserImageQuota();
        } else {
            showFileTooLargeDialog();
        }
    };

    const deleteLogo = async () => {
        setIsUploadingOrDeletingLogo(true);
        setSelectedLogoURL(null);
        await userSettingsService.deleteAllLogos(true);
        commonActionService.getUserSettings();
        setIsUploadingOrDeletingLogo(false);
        commonActionService.getUserImageQuota();
    };

    const closeModal = () => {
        setSelectedLogoURL(null);
        setShowModal(false);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const onModalFooterButtonClick = () => (logoImage ? deleteLogo() : file && addLogo(file));

    const getCurrentHeader = () => (logoImage ? t.removeLogoShort : t.addLogo);
    const uploadedOrSelectedLogo = () => logoImage || selectedLogoURL || undefined;

    const hideAddLogo = props.hideAddLogo && !logoImage;

    const logoButton = (
        <Clickable onClick={openModal} disabled={isUploadingOrDeletingLogo}>
            <Box
                color={logoImage ? 'white' : 'grey2'}
                width={165}
                height={60}
                alignItems={'center'}
                justifyContent={logoImage ? 'end' : 'center'}
            >
                {isUploadingOrDeletingLogo ? (
                    <LoadingIndicator small />
                ) : logoImage ? (
                    <img data-test-id="logo_image" src={logoImage} height="100%" width="auto" />
                ) : (
                    <Heading color="blue">{t.addLogo}</Heading>
                )}
            </Box>
        </Clickable>
    );

    const modalSelectLogoButton = (
        <UploadFile accept={ALLOWED_IMAGE_TYPES} onFileUploaded={onFileSelected}>
            <Clickable>
                {uploadedOrSelectedLogo() ? (
                    <img src={uploadedOrSelectedLogo()} height={58}></img>
                ) : (
                    <Border color="grey4" radius="4px">
                        <Box width={CONTENT_WIDTH} color="white" padding="base">
                            <Stack vertical alignItems="center">
                                <Icon icon="add_circle" size="lg" opaque color="blue" />
                                <Heading color="blue">{t.uploadLogoShort}</Heading>
                            </Stack>
                        </Box>
                    </Border>
                )}
            </Clickable>
        </UploadFile>
    );

    const modalFooter = (
        <Box
            flex="shrinkAndGrow"
            justifyContent="end"
            paddingTop={isLocalProject ? 'base' : undefined}
        >
            <Stack spacing="quart">
                <Button text onClick={closeModal}>
                    {t.cancel}
                </Button>
                <Button
                    onClick={onModalFooterButtonClick}
                    primary
                    disabled={isUploadingOrDeletingLogo}
                >
                    {getCurrentHeader()}
                </Button>
            </Stack>
        </Box>
    );

    return (
        <>
            {!hideAddLogo && logoButton}
            {showModal && (
                <Modal onClose={closeModal} title={getCurrentHeader()}>
                    <Box direction="column">
                        <Box width={400} color="white" padding="base" justifyContent="center">
                            {uploadedOrSelectedLogo() !== undefined ? (
                                <img src={uploadedOrSelectedLogo() || undefined} height={58}></img>
                            ) : (
                                modalSelectLogoButton
                            )}
                        </Box>
                        <StorageUsed />
                        {uploadedOrSelectedLogo() && modalFooter}
                    </Box>
                </Modal>
            )}
        </>
    );
};

Logo.displayName = 'Logo';
