/**
 * Get display vertical field of view
 * @param horizontalFov - Horizontal field of view
 * @param verticalFov - Vertical field of view
 * @param aspect - Camera frustum aspect ratio, window width divided by window height.
 * @returns  The vertical field of view for the "through the lens" camera is calculated to adapt to the aspect ratio of of the selected camera and
 * the canvas in order to display the actual field of view in letter box format or pillarbox format.
 */
export const getDisplayVerticalFOV = (
    horizontalFov: number,
    verticalFov: number,
    aspect: number,
) => {
    return Math.min(
        Math.PI - 0.01,
        Math.tan(horizontalFov / 2) / Math.tan(verticalFov / 2) <= aspect
            ? verticalFov
            : 2 * Math.atan(Math.tan(horizontalFov / 2) / aspect),
    );
};
