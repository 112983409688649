import { reducerBuilder } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { CommonActions } from './actions';
import type {
    IUserInfo,
    IPartnerConfigServerResponse,
    IUserSettingsEntity,
    IPersistence,
    IPartnerConfig,
    SyncStatus,
} from 'app/core/persistence';
import { PersistenceActions } from 'app/core/persistence';
import type {
    ICommonContainerState,
    IUserState,
    IProjectCustomerInfoResponse,
    IContactDetails,
    IContactDetailsState,
    IUserImageQuota,
    IPriceList,
    IAuth,
} from './models';
import { AuthState } from './models';
import type { IPiaLocation, IPiaItem } from 'app/core/pia';
import type { IDistributors, ICurrencies } from 'app/modules/msrp';
import { ScenarioService } from './profile';
import type { IAuthTokens } from './utils/auth/authTokens';
import {
    clearAuthTokens,
    getDisplayName,
    loadAuthTokens,
    saveAuthTokens,
} from './utils/auth/authTokens';
import type { CompactionStatus, ICouchDBInfo } from './couchDBCompaction';

const initialState: IUserState = {
    user: null,
    isSignedIn: undefined,
    loading: false,
    loaded: false,
    error: null,
    userSettings: null,
};

const initialPartnerConfigState: IPartnerConfig = {
    loaded: false,
    id: '0',
    companyName: '',
    style: undefined,
    useAllowlist: false,
    allowPublicUrlAccess: false,
    allowLocIdAccess: false,
};

const initialContactDetailsState: IContactDetailsState = {
    userApiContactDetails: {},
    contactDetails: {},
};

const initialAuth: IAuth = {
    state: AuthState.NONE,
};

export const commonReducer = reducerBuilder<ICommonContainerState>()
    .setInitialState((): ICommonContainerState => {
        const scenarioService = ServiceLocator.get(ScenarioService);

        return {
            piaItems: [],
            scenarios: scenarioService.getScenarios(),
            user: initialState,
            locations: [],
            msrp: null,
            msrpLoaded: false,
            msrpAuthStatus: false,
            msrpAuthStatusLoaded: false,
            distributors: null,
            distributorsLoaded: false,
            projectCustomerInfo: '',
            partnerConfig: initialPartnerConfigState,
            contactDetails: initialContactDetailsState,
            overlayPanelOpen: false,
            quota: { usedBytes: undefined, totalBytes: undefined },
            currencies: null,
            auth: initialAuth,
            projectSync: {
                status: 'not_in_sync',
                lastSync: new Date(0),
            },
            couchDBInfo: null,
            compactionStatus: null,
            compactionInitiated: false,
            hasCouchDB: false,
        };
    })
    .onAction<IPiaItem[]>(CommonActions.GetPiaDevices, (state, action) => ({
        ...state,
        piaItems: action.payload,
    }))
    .onAction<boolean>(CommonActions.GetIsAuthenticated, (state, action) => ({
        ...state,
        user: {
            ...state.user,
            isSignedIn: action.payload,
        },
    }))
    .onAsyncAction(CommonActions.GetUser, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                user: {
                    ...state.user,
                    loading: true,
                    loaded: false,
                },
            }))
            .onFulfilled<IUserInfo>((state, action) => ({
                ...state,
                user: {
                    ...state.user,
                    loading: false,
                    loaded: true,
                    error: null,
                    user: action.payload,
                },
            }))
            .onRejected<Error>((state, action) => ({
                ...state,
                user: {
                    ...state.user,
                    loading: false,
                    loaded: true,
                    error: action.payload,
                },
            }));
    })
    .onAction<void>(CommonActions.UnloadProject, (state) => ({
        ...state,
        project: {
            projectQuotation: null,
        },
    }))
    .onAction<IPiaLocation[]>(CommonActions.GetLocations, (state, action) => ({
        ...state,
        locations: action.payload,
    }))
    .onAction<void>(CommonActions.PricesNotAvailable, (state) => ({
        ...state,
        msrp: null,
        msrpLoaded: true,
        distributors: null,
        distributorsLoaded: true,
        msrpAuthStatus: false,
        msrpAuthStatusLoaded: true,
    }))
    .onAsyncAction(CommonActions.GetMsrp, (handler) => {
        handler
            .onFulfilled<IPriceList[]>((state, action) => ({
                ...state,
                msrp: action.payload,
                msrpLoaded: true,
            }))
            .onRejected<Error>((state) => ({
                ...state,
                msrp: null,
                msrpLoaded: true,
            }));
    })
    .onAsyncAction(CommonActions.GetCurrencies, (handler) => {
        handler
            .onFulfilled<ICurrencies>((state, action) => ({
                ...state,
                currencies: action.payload,
            }))
            .onRejected<Error>((state) => ({
                ...state,
                currencies: null,
            }));
    })
    .onAction<IDistributors>(CommonActions.GetDistributors, (state, action) => ({
        ...state,
        distributors: action.payload,
        distributorsLoaded: true,
    }))
    .onAction<IPersistence<IUserSettingsEntity>>(
        PersistenceActions.UpdateUserSettings,
        (state, action) => ({
            ...state,
            user: {
                ...state.user,
                userSettings: action.payload,
            },
        }),
    )
    .onAsyncAction(CommonActions.GetMsrpAuthStatus, (handler) => {
        handler
            .onFulfilled<boolean>((state, action) => ({
                ...state,
                msrpAuthStatus: action.payload,
                msrpAuthStatusLoaded: true,
            }))
            .onRejected<Error>((state) => ({
                ...state,
                msrpAuthStatus: false,
                msrpAuthStatusLoaded: true,
            }));
    })
    .onAsyncAction(CommonActions.UpdateProjectCustomerInfo, (handler) => {
        handler.onFulfilled<IProjectCustomerInfoResponse>((state, action) => ({
            ...state,
            projectCustomerInfo: action.payload.customerInfo,
        }));
    })
    .onAsyncAction(CommonActions.GetUserImageQuota, (handler) => {
        handler
            .onFulfilled<IUserImageQuota>((state, action) => ({
                ...state,
                quota: action.payload,
            }))
            .onRejected<Error>((state) => ({
                ...state,
                quota: {
                    usedBytes: undefined,
                    totalBytes: undefined,
                },
            }));
    })
    .onAsyncAction(CommonActions.UpdateCompactStatus, (handler) => {
        handler.onFulfilled<CompactionStatus | null>((state, action) => ({
            ...state,
            compactionStatus: action.payload,
        }));
    })
    .onAction<boolean>(CommonActions.SetHasCouchDB, (state, action) => ({
        ...state,
        hasCouchDB: action.payload,
    }))
    .onAction<boolean>(CommonActions.SetCompactionInitiated, (state, action) => ({
        ...state,
        compactionInitiated: action.payload,
    }))
    .onAction<ICouchDBInfo | null>(
        CommonActions.GetCouchDBInfo,
        (state, action): ICommonContainerState => {
            return {
                ...state,
                couchDBInfo: action.payload,
            };
        },
    )
    .onAction<IPartnerConfigServerResponse | null>(
        CommonActions.GetPartnerConfig,
        (state, action): ICommonContainerState => {
            if (!action.payload) {
                return {
                    ...state,
                    partnerConfig: {
                        ...initialPartnerConfigState,
                        loaded: true,
                    },
                };
            }

            return {
                ...state,
                partnerConfig: {
                    ...state.partnerConfig,
                    ...action.payload,
                    loaded: true,
                },
            };
        },
    )
    .onAction<boolean>(
        CommonActions.UseAllowlistChanged,
        (state, action): ICommonContainerState => ({
            ...state,
            partnerConfig: {
                ...state.partnerConfig,
                useAllowlist: action.payload,
            },
        }),
    )
    .onAction<boolean>(CommonActions.ShowOverlayPanel, (state, action) => ({
        ...state,
        overlayPanelOpen: action.payload,
    }))
    .onAction<IContactDetailsState>(CommonActions.GetContactDetails, (state, action) => {
        return {
            ...state,
            contactDetails: {
                ...state.contactDetails,
                userApiContactDetails: action.payload.userApiContactDetails,
                contactDetails: action.payload.contactDetails,
            },
        };
    })
    .onAction<IContactDetails>(CommonActions.UpdateContactDetails, (state, action) => {
        return {
            ...state,
            contactDetails: {
                ...state.contactDetails,
                contactDetails: action.payload,
            },
        };
    })
    .onAsyncAction(CommonActions.Authorize, (handler) => {
        handler.onFulfilled<IAuth>((state) => {
            const tokens = loadAuthTokens();
            if (tokens) {
                const { idToken } = tokens;
                const name = getDisplayName(idToken);
                return {
                    ...state,
                    auth: { state: AuthState.AUTHORIZED, idToken, name },
                };
            }
            return state;
        });
    })
    .onAsyncAction(CommonActions.Reauthorize, (handler) => {
        handler.onFulfilled<IAuth>((state) => {
            return state;
        });
    })
    .onAsyncAction(CommonActions.Logout, (handler) => {
        handler.onFulfilled<IAuth>((state) => {
            clearAuthTokens();
            return state;
        });
    })
    .onAction<IAuthTokens>(CommonActions.UpdateTokens, (state, action) => {
        const tokens: IAuthTokens = { ...loadAuthTokens(), ...action.payload };
        const { idToken } = tokens;
        const name = getDisplayName(idToken);
        saveAuthTokens(tokens);
        return {
            ...state,
            auth: {
                state: AuthState.AUTHORIZED,
                idToken,
                name,
            },
        };
    })
    .onAction<IAuthTokens>(CommonActions.SetAuthLoading, (state) => {
        return {
            ...state,
            auth: {
                state: AuthState.LOADING,
            },
        };
    })
    .onAction<IAuthTokens>(CommonActions.SetAuthFailure, (state) => {
        return {
            ...state,
            auth: {
                state: AuthState.NONE,
            },
        };
    })
    .onAction<SyncStatus>(PersistenceActions.UpdateSyncStatus, (state, action) => ({
        ...state,
        projectSync: {
            ...state.projectSync,
            status: action.payload,
        },
    }))
    .onAction<Date>(PersistenceActions.UpdateSyncDateTime, (state, action) => ({
        ...state,
        projectSync: {
            ...state.projectSync,
            lastSync: action.payload,
        },
    }))
    .create();
