import { constants } from './constants';
import type { IBandwidthParams, IScalingCost } from '../../models';
import type { BandwidthVersion } from 'app/core/persistence';
import { UnreachableCaseError } from 'axis-webtools-util';

const notStorageBFramePlatforms = ['ARTPEC-4', 'ARTPEC-5', 'ARTPEC-6', 'S2', 'S3L', 'S5', 'S5L'];

export class BandwidthOptions {
    public readonly gop: number;
    public readonly gopMax: number;
    public readonly cPlatform: number;
    public readonly compQPmap: Record<string, number>;
    public readonly scalingCost: IScalingCost;
    public readonly a: number;
    public readonly qp: number;
    public readonly sizeOfAverageObjectFactor: number;
    public readonly iFrameDeltaQPZip: number;
    public readonly pFrameDeltaQPZip: number;
    public readonly pFrameQPOffset: number;
    public readonly fpsFactor: number;
    public readonly gopFactor: number;
    public readonly fpsMin: number;
    public readonly frameRate: number;
    public readonly resolution: number;
    public readonly maxResolution: number;
    public readonly scalingCostType: IBandwidthParams['scalingCostType'];
    public readonly zipStrength: number;
    public readonly gopMode: IBandwidthParams['gopMode'];
    public readonly miniGop: number;
    public readonly scenario: IBandwidthParams['scenario'];
    public readonly wdr: boolean;
    public readonly version: BandwidthVersion;
    public readonly nature: boolean;
    public readonly fpsMode: IBandwidthParams['fpsMode'];
    public readonly goodLightMotion: number;
    public readonly goodLightMotionFrac: number;
    public readonly lowLightMotion: number;
    public readonly lowLightMotionFrac: number;
    public readonly nightLight: number;
    public readonly numSamples: number;

    constructor(options: IBandwidthParams) {
        // MJPEG overrides some settings.
        if (options.scalingCostType === 'MJPEG') {
            options.gop = 1;
            options.zipStrength = 0;
        }

        // bandwidth version 1 never enables nature
        if (options.version === 1) {
            options.nature = false;
        }

        this.gop = options.gop || 32;
        this.miniGop =
            options.zipProfile === 'classic' ||
            notStorageBFramePlatforms.includes(options.platform ?? '')
                ? 1
                : 3;
        this.gopMax = options.gopMax || 300;
        this.fpsMin = options.fpsMin || 1;
        this.cPlatform = options.cPlatform || constants.refCam.cPlatform;
        this.compQPmap = options.compQPmap || constants.refCam.compQPmap;
        this.scalingCost =
            options.scalingCost ||
            (options.scalingCostType === 'H.265'
                ? constants.refCam.scalingCostH265
                : constants.refCam.scalingCost);

        this.a = this.getA(options.resolution);
        this.qp = this.getQP(options.compression);
        const zipStrengthOption = options.zipStrength as keyof typeof constants.zipStrengthQpGop;
        const zipStrength =
            options.zipProfile === 'storage'
                ? this.toZipStrengthStorage(zipStrengthOption)
                : zipStrengthOption;
        const sizeOfAverageObject =
            options.sizeOfAverageObject as keyof typeof constants.sizeOfAverageObject;
        this.sizeOfAverageObjectFactor = this.getSAOFactor(sizeOfAverageObject);
        this.iFrameDeltaQPZip = this.getIFrameDeltaQPZip(zipStrength);
        this.pFrameDeltaQPZip = this.getPFrameDeltaQPZip(zipStrength);
        this.pFrameQPOffset = this.getPFrameQPOffset(options.maxResolution, options.resolution);
        this.fpsFactor = this.getFpsFactor(options.frameRate);
        this.gopFactor = this.getGopFactor(options.gopMode, zipStrength);

        this.frameRate = options.frameRate;
        this.resolution = options.resolution;
        this.maxResolution = options.maxResolution;
        this.scalingCostType = options.scalingCostType;
        this.zipStrength = zipStrength;
        this.gopMode = options.zipProfile === 'storage' ? 'dynamic' : options.gopMode;
        this.scenario = options.scenario;
        this.wdr = options.wdr;
        this.version = options.version;
        this.nature = options.nature;
        this.fpsMode = options.fpsMode;
        this.goodLightMotion = options.goodLightMotion;
        this.goodLightMotionFrac = options.goodLightMotionFrac;
        this.lowLightMotion = options.lowLightMotion;
        this.lowLightMotionFrac = options.lowLightMotionFrac;
        this.nightLight = options.nightLight;
        this.numSamples = options.numSamples || 1000;
    }

    private getA(resolution: number) {
        return constants.aRef * Math.pow(constants.resolutionRef / resolution, 0.5);
    }

    private getQP(compression: number) {
        return this.compQPmap[String(compression)];
    }

    private getSAOFactor(val: keyof typeof constants.sizeOfAverageObject): number {
        return constants.sizeOfAverageObject[val];
    }

    private getIFrameDeltaQPZip(zipStrength: keyof typeof constants.zipStrengthQpGop) {
        return constants.zipStrengthQpGop[zipStrength].QPI;
    }

    private getPFrameDeltaQPZip(zipStrength: keyof typeof constants.zipStrengthQpGop) {
        return constants.zipStrengthQpGop[zipStrength].QPP;
    }

    private getPFrameQPOffset(maxResolution: number, resolution: number) {
        return (
            3 *
            Math.pow(Math.max(0, maxResolution - resolution) / maxResolution, 2) *
            Math.sqrt(maxResolution / constants.resolutionRef)
        );
    }

    private getFpsFactor(frameRate: number) {
        return Math.max(0.5, Math.min(2, Math.sqrt(constants.fpsRef / frameRate)));
    }

    private getGopFactor(gopMode: string, zipStrength: keyof typeof constants.zipStrengthQpGop) {
        if (gopMode === 'dynamic') {
            return constants.zipStrengthQpGop[zipStrength].gopFactor;
        }
        return 1;
    }

    private toZipStrengthStorage(zipStrength: keyof typeof constants.zipStrengthQpGop) {
        switch (zipStrength) {
            case 0:
                return 0;
            case 10:
                return 20;
            case 20:
                return 25;
            case 25:
                return 30;
            case 30:
                return 40;
            case 40:
                return 50;
            case 50:
                return 50;
            default:
                throw new UnreachableCaseError(zipStrength);
        }
    }
}
