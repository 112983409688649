import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppConstants } from 'app/AppConstants';
import { Positioned, Spacer, Stack } from '../../layout';
import { Box } from '../../containers';
import { Closeable, NoPrint, Clickable, Title } from '../../functional';
import { Heading } from '../../text';
import { Icon } from '../icon';
import type { Colors, Spacings } from 'app/styles';
import { IconButton } from '../iconButton';
import { t } from 'app/translate';
import { Border, Margin } from 'app/components/style';
import type { IWithChildren } from 'app/components/models';

interface IModalProps extends IWithChildren {
    /**
     * The title header of the modal window.
     */
    title?: React.ReactNode;
    /**
     * The max width of the modal window. Defaults to 80%.
     */
    maxWidth?: string;
    /**
     * The min width of the modal window. Defaults to undefined.
     */
    minWidth?: string;
    /**
     * The desired height of the modal.
     */
    height?: string;
    /**
     * The desired width of the modal.
     */
    width?: string;
    /**
     * Max height of the modal in pixels.
     */
    maxHeight?: number;
    /**
     * Display warning triangle before modal title
     */
    warning?: boolean;
    /**
     * Sets the content padding of the modal.
     */
    padding?: Spacings;
    /**
     * Sets the margins.
     * */
    marginTop?: React.CSSProperties['marginBlockStart'];
    marginLeft?: React.CSSProperties['marginInlineStart'];
    marginRight?: React.CSSProperties['marginInlineEnd'];
    marginBottom?: React.CSSProperties['marginBlockEnd'];
    /**
     * The background color of the modal
     */
    color?: Colors;
    /**
     * Shows a print button next to close button.
     */
    printButton?: boolean;
    /**
     * Adds a button to expand the modal to full screen.
     */
    expandable?: boolean;
    /**
     * Sets backdrop color to transparent.
     */
    transparentBackdropColor?: boolean;
    /**
     * Set custom top position of the modal.
     * Can be used when it should not be centered on the window,
     * e.g. when used inside an iFrame. The modal is then absolutely positioned at the
     * specified top value.
     */
    top?: React.CSSProperties['top'];
    /**
     * The method to run when the modal should close.
     * Set this to allow the window to close upon clicking outside of it or hitting `escape`.
     * Will display a close icon in the modal header.
     */
    onClose?(): void;
}

/**
 * Display a modal with an optional dark backdrop.
 * maxHeight is decided by the browser height.
 * If used in an iFrame the maxHeight property is used to limit the height
 */
export const Modal: React.FunctionComponent<IModalProps> = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const close = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return ReactDOM.createPortal(
        <Closeable close={close} customShadow="0">
            <Positioned position="absolute">
                <Box
                    height="100vh"
                    width="100vw"
                    maxHeight={props.maxHeight ?? window.innerHeight}
                    alignItems="center"
                    justifyContent="center"
                >
                    <NoPrint>
                        <Positioned top="0" left="0" position="fixed" right="0" bottom="0">
                            <Box
                                color={
                                    props.transparentBackdropColor ? 'transparent' : 'blackOpacity'
                                }
                                isBackdrop
                            />
                        </Positioned>
                    </NoPrint>
                    <Border
                        shadow="rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"
                        color="grey3"
                        noPrint={props.printButton ? true : undefined}
                    >
                        <Positioned
                            position={props.top === undefined ? 'relative' : 'absolute'}
                            top={props.top}
                        >
                            <Margin
                                marginLeft={expanded ? undefined : props.marginLeft}
                                marginRight={expanded ? undefined : props.marginRight}
                                marginTop={expanded ? undefined : props.marginTop}
                                marginBottom={expanded ? undefined : props.marginBottom}
                            >
                                <Box
                                    testId="app_modal"
                                    display="block"
                                    maxWidth={expanded ? undefined : props.maxWidth || '80%'}
                                    minWidth={props.minWidth}
                                    maxHeight={
                                        expanded
                                            ? undefined
                                            : props.printButton
                                              ? '90%'
                                              : (props.maxHeight ?? window.innerHeight) * 0.9
                                    }
                                    height={expanded ? 'calc(100vh - 16px)' : props.height}
                                    width={expanded ? 'calc(100vw - 16px)' : props.width}
                                    color={props.color || 'grey1'}
                                    borderRadius="rounded"
                                    padding={props.padding || 'base'}
                                >
                                    {props.onClose && (
                                        <NoPrint>
                                            <Positioned
                                                position="absolute"
                                                top={16}
                                                insetInlineEnd={16}
                                            >
                                                <Box spacing="half" alignItems="center">
                                                    {props.printButton && (
                                                        <IconButton
                                                            icon="print"
                                                            text={t.print}
                                                            onClick={() => window.print()}
                                                        />
                                                    )}
                                                    {props.expandable && (
                                                        <Title
                                                            title={
                                                                expanded
                                                                    ? t.exitFullScreen
                                                                    : t.showInFullScreen
                                                            }
                                                        >
                                                            <Clickable
                                                                onClick={() =>
                                                                    setExpanded((prev) => !prev)
                                                                }
                                                            >
                                                                <Icon
                                                                    icon={
                                                                        expanded
                                                                            ? 'minimise'
                                                                            : 'open_in_full'
                                                                    }
                                                                    color="grey7"
                                                                />
                                                            </Clickable>
                                                        </Title>
                                                    )}
                                                    <Clickable onClick={close}>
                                                        <Icon
                                                            testId="btn_close_panel"
                                                            color="grey7"
                                                            icon="close_circle_outline"
                                                        />
                                                    </Clickable>
                                                </Box>
                                            </Positioned>
                                        </NoPrint>
                                    )}

                                    {!props.title && (
                                        <NoPrint>
                                            <Spacer customSpacing="30px" />
                                        </NoPrint>
                                    )}

                                    {(props.title || props.warning) && (
                                        <Box
                                            paddingRight="doublePanel"
                                            paddingBottom="half"
                                            wrap={true}
                                        >
                                            <Stack alignItems="center" spacing="half">
                                                {props.warning && (
                                                    <Icon
                                                        color="red"
                                                        icon="warning"
                                                        opaque
                                                        size="md"
                                                    />
                                                )}
                                                <Heading style="title" breakWord>
                                                    {props.title}
                                                </Heading>
                                            </Stack>
                                        </Box>
                                    )}

                                    <Box
                                        testId="modal_btn_container"
                                        display="block"
                                        overflowY="auto"
                                        height={expanded ? undefined : 'calc(100% - 80px)'}
                                        maxHeight={
                                            expanded
                                                ? 'calc(100vh - 16px - 32px - 42px)' // Screen - margin - padding - header
                                                : (props.maxHeight ?? window.innerHeight) * 0.9 - 80
                                        }
                                    >
                                        {props.children}
                                    </Box>
                                </Box>
                            </Margin>
                        </Positioned>
                    </Border>
                </Box>
            </Positioned>
        </Closeable>,
        document.getElementById(AppConstants.modalRootId)!,
    );
};
