import * as React from 'react';
import { css } from '@emotion/css';
import { t } from 'app/translate';
import type { Id } from 'app/core/persistence';
import { Time24, ScheduleDays } from 'app/core/persistence';
import type { IOptionProps, IAutoTestable } from 'app/components';
import { Select, Box, Stack, IconButton } from 'app/components';
import { constants } from '../../constants';
// import * as styles from './ScheduleSelector.component.css';
import type { IScheduleSelectorModel } from '../../models';
import { TwentyFourHourSlider, DaySelector } from '../../../twentyFourHourSlider';
import { animationTime } from 'app/styles/defaults';

const ArrowStyle = css`
    transition: ease-in-out ${animationTime};

    &[aria-expanded='true'] {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
`;
export interface IScheduleSelectorProps extends IAutoTestable {
    noSchedule: boolean;
    expanded: boolean;
    scheduleSelector: IScheduleSelectorModel;
    deactivate?: boolean;
    liveView?: boolean;
    onScheduleChange(id: Id | null): void;
    onToggleRecordingSettings(): void;
}

export class ScheduleSelector extends React.Component<IScheduleSelectorProps> {
    public render() {
        return (
            <Stack alignItems="start">
                <Box width={constants.leftColumnWidth} justifyContent="between">
                    <Box maxWidth="85%">
                        <Select
                            value={
                                this.props.scheduleSelector.selectedSchedule
                                    ? this.props.scheduleSelector.selectedSchedule.id
                                    : ''
                            }
                            options={this.scheduleOptions()}
                            onChange={this.onScheduleChange}
                            testIdChild={`${this.props.testId}_schedule_selected`}
                        />
                    </Box>
                    {!this.props.noSchedule && !!this.props.deactivate && (
                        <div aria-expanded={this.props.expanded} className={ArrowStyle}>
                            <IconButton
                                onClick={this.props.onToggleRecordingSettings}
                                icon="keyboard_arrow_down"
                            />
                        </div>
                    )}
                </Box>
                <Box flex="shrinkAndGrow">{this.renderTimeSerieSliders()}</Box>
            </Stack>
        );
    }

    private renderTimeSerieSliders() {
        if (
            this.props.scheduleSelector.selectedSchedule &&
            this.props.scheduleSelector.selectedSchedule.timeSeries.length > 0
        ) {
            return (
                <Stack vertical>
                    {this.props.scheduleSelector.selectedSchedule.timeSeries.map(
                        (timeSerie, index) =>
                            this.renderTimeSerieSlider(
                                timeSerie.start,
                                timeSerie.end,
                                timeSerie.days,
                                index,
                            ),
                    )}
                </Stack>
            );
        } else {
            // If no schedule or no time series we render a single slider
            return this.renderTimeSerieSlider(
                new Time24('00:00'),
                new Time24('00:00'),
                new ScheduleDays(0),
            );
        }
    }

    private renderTimeSerieSlider(start: Time24, end: Time24, days: ScheduleDays, key?: number) {
        return (
            <Box key={key} flex="shrinkAndGrow" direction="column">
                <TwentyFourHourSlider
                    darkLight={this.props.scheduleSelector.nightLighting}
                    setLight={false}
                    readOnly={true}
                    lightStart={this.props.scheduleSelector.lightStart}
                    lightEnd={this.props.scheduleSelector.lightEnd}
                    start={start}
                    end={end}
                />
                <DaySelector days={days} />
            </Box>
        );
    }

    private scheduleOptions(): IOptionProps[] {
        const additionalOption = this.props.liveView
            ? this.scheduleUseMotionTriggeredRecording()
            : this.scheduleOffOption();

        return this.props.scheduleSelector.schedules
            .map((schedule) => ({
                value: String(schedule.id),
                text: schedule.name,
            }))
            .concat([additionalOption]);
    }

    private scheduleOffOption() {
        return {
            text: t.schedulesSystemDefinedNamesOff,
            value: '',
        };
    }

    private scheduleUseMotionTriggeredRecording() {
        return {
            text: t.schedulesSystemDefinedNamesUseMotionTriggerRec,
            value: '',
        };
    }

    private onScheduleChange = (id: Id) => {
        this.props.onScheduleChange(id ? id : null);
    };
}
