import { trigonometry } from 'axis-webtools-util';
import type { PanoramaModes, IItemEntity, IPersistence } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import type { IPiaCamera, IPiaRelationReference, IPiaSensorUnit } from 'app/core/pia';
import {
    isTiltableMultisensor,
    hasMultipleSensors,
    calculateGenericVerticalFovRad,
} from 'app/modules/common';

const ASPECT_16_9 = 16 / 9;

export const isPanoramic = (camera: IPiaCamera | IPiaSensorUnit | undefined) =>
    !!(camera?.properties.canChangePanoramaMode || camera?.properties.defaultPanoramaMode);

const toRadiansOrUndefined = (degrees: number | undefined) =>
    degrees === undefined ? undefined : trigonometry.toRadians(degrees);

export const getFovRange = (
    camera: IPiaCamera | IPiaSensorUnit | undefined,
    panoramaMode: PanoramaModes,
    lensRelations?: IPiaRelationReference,
) => {
    const cameraIsPanoramic = isPanoramic(camera);
    const isTiltable = isTiltableMultisensor(camera ?? null);
    const shouldUseLensCalcFov = hasMultipleSensors(camera ?? null) && !isTiltable;

    if (panoramaMode && !isTiltable && cameraIsPanoramic) {
        return {
            minHorizontalFovRad: Math.PI,
            maxHorizontalFovRad: Math.PI,
            minVerticalFovRad: Math.PI,
            maxVerticalFovRad: Math.PI,
        };
    }

    const maxHorizontalFovRad = toRadiansOrUndefined(
        shouldUseLensCalcFov
            ? camera?.properties.maxLensCalcFOV
            : lensRelations
              ? lensRelations?.relationProperties.horizontalFOV?.max
              : camera?.properties.maxHorizontalFOV,
    );

    const minHorizontalFovRad = toRadiansOrUndefined(
        shouldUseLensCalcFov
            ? camera?.properties.minLensCalcFOV
            : lensRelations
              ? lensRelations?.relationProperties.horizontalFOV?.min
              : camera?.properties.minHorizontalFOV,
    );

    const minVerticalFovRad = toRadiansOrUndefined(
        lensRelations
            ? lensRelations?.relationProperties.verticalFOV?.min
            : camera?.properties.minVerticalFOV,
    );

    const maxVerticalFovRad = toRadiansOrUndefined(
        lensRelations
            ? lensRelations.relationProperties.verticalFOV?.max
            : camera?.properties.maxVerticalFOV,
    );

    return {
        minHorizontalFovRad,
        maxHorizontalFovRad,
        minVerticalFovRad,
        maxVerticalFovRad,
    };
};

export const getSelectedPanoramaMode = (
    camera: IPiaCamera | IPiaSensorUnit | undefined,
    desiredPanoramaMode: PanoramaModes,
    desiredHorizontalFovRadians: number,
) => {
    if (!camera) {
        return desiredHorizontalFovRadians >= Math.PI ? desiredPanoramaMode || 'horizontal' : false;
    }

    return canChangePanoramaMode(camera, desiredHorizontalFovRadians)
        ? desiredPanoramaMode || camera?.properties.defaultPanoramaMode
        : false;
};

export const canChangePanoramaMode = (
    camera: IPiaCamera | IPiaSensorUnit | undefined,
    desiredHorizontalFovRadians: number,
) => {
    if (!camera) {
        return desiredHorizontalFovRadians >= Math.PI;
    }

    return camera.properties.canChangePanoramaMode || isTiltableMultisensor(camera ?? null);
};

export const getVideoResolution = (camera: IPiaCamera | IPiaSensorUnit | undefined) => {
    if (camera === undefined) return undefined;

    return camera.properties.maxVideoResolutionHorizontal;
};

export const getCustomCameraVerticalFov = (
    customCamera: IPersistence<IItemEntity>,
    horizontalFov: number,
): number => {
    const aspectRatio = isCustomCamera(customCamera)
        ? customCamera.properties.camera.customCameraProperties.resolutionHorizontal /
          customCamera.properties.camera.customCameraProperties.resolutionVertical
        : ASPECT_16_9; // fallback to something reasonable

    return calculateGenericVerticalFovRad(horizontalFov, aspectRatio);
};
