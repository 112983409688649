export enum EventsEnum {
    CreateStart = 'CreateStart',
    Creating = 'Creating',
    CreateStop = 'CreateStop',
    Created = 'Created',
    EditStart = 'EditStart',
    Editing = 'Editing',
    EditStop = 'EditStop',
    Edited = 'Edited',
    DeleteStart = 'DeleteStart',
    DeleteStop = 'DeleteStop',
}

export type Events = keyof typeof EventsEnum;
