import type { Id } from '../../entities';
import type { RepositoryAction } from '../RepositoryAction';

export class RepositoryInvalidRevError extends Error {
    constructor(action: RepositoryAction, invalidIds: Id[] = []) {
        super(
            `Invalid revs occurred when persisting data, action: ${action}, ids: ${invalidIds.join(
                ', ',
            )}`,
        );

        Object.setPrototypeOf(this, RepositoryInvalidRevError.prototype);
    }
}
