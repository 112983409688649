/**
 * Find out if a drop down should open downwards (instead of upwards)
 *
 * @param triggerElement A React reference to the element that opens the drop down
 * @param contentHeight The approximate expected height of the contents to open in the drop down
 * @param margin How large the margin should be before switching direction. Defaults to 60
 */
export const getShouldOpenDownwards = (
    triggerElement: React.RefObject<HTMLElement>,
    contentHeight: number,
    margin: number = 60,
) => {
    if (!triggerElement.current) {
        return true;
    }
    const triggerDomRect = triggerElement.current.getBoundingClientRect();
    const triggerYPosition = triggerDomRect.bottom;
    const contentWillFitInViewport = window.innerHeight - (triggerYPosition + contentHeight) > 0;
    const contentWillFitUpwards = triggerYPosition - margin > contentHeight;

    return contentWillFitInViewport ? true : !contentWillFitUpwards;
};

/**
 * Find out if a drop down should open to the right (instead of to the left)
 *
 * @param triggerElement A React reference to the element that opens the drop down
 * @param margin How large the margin should be before switching direction. This could be the content width. Defaults to 200
 */
export const getShouldOpenRightwards = (
    triggerElement: React.RefObject<HTMLElement>,
    contentWidth: number,
) => {
    if (!triggerElement.current) {
        return true;
    }

    const triggerDomRect = triggerElement.current.getBoundingClientRect();
    const triggerXPosition = triggerDomRect.left + triggerDomRect.width / 2;
    const contentWillFitInViewport = window.innerWidth - (triggerXPosition + contentWidth) > 0;

    return contentWillFitInViewport;
};

/**
 * RTL support
 * Find out if a drop down should open to the left (instead of to the right)
 *
 * @param triggerElement A React reference to the element that opens the drop down
 */
export const getShouldOpenLeftwards = (
    triggerElement: React.RefObject<HTMLElement>,
    contentWidth: number,
) => {
    if (!triggerElement.current) {
        return true;
    }

    const triggerDomRect = triggerElement.current.getBoundingClientRect();
    const triggerXPosition = triggerDomRect.right - triggerDomRect.width / 2;
    const contentWillFitInViewport = triggerXPosition - contentWidth > 0;

    return contentWillFitInViewport;
};
