import * as React from 'react';
import { t } from 'app/translate';
import type { Id } from 'app/core/persistence';
import { isSensorUnit } from 'app/core/persistence';
import { Border, Box, Clickable, IconText, Stack } from 'app/components';
import { SpacingsEnum } from 'app/styles';
import { useSelector } from 'react-redux';
import { getHasAccessories, getHasApplications, getPiaItem } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import type { IPiaItem } from 'app/core/pia';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../../../services';

interface IDeviceAddOnsProps {
    deviceId: Id;
}

export const DeviceAddOns: React.FC<IDeviceAddOnsProps> = ({ deviceId }) => {
    const mapsActions = useService(MapsActionService);
    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, deviceId),
    );
    const isItemSensorUnit = isSensorUnit(piaItem);
    const hasAccessories = useSelector<IStoreState, boolean | undefined>((state) =>
        getHasAccessories(state, deviceId),
    );
    const hasApplications = useSelector<IStoreState, boolean | undefined>((state) =>
        getHasApplications(state, deviceId),
    );

    const renderAddOnButton = (type: 'accessories' | 'applications', hasItems?: boolean) => {
        const color = hasItems ? 'yellow1' : 'white';
        const hoverColor = hasItems ? 'yellow2' : 'grey1';
        const textColor = hasItems ? 'yellowText' : 'grey7';
        return (
            <Clickable onClick={() => mapsActions.toggleDeviceOverlayPanel(true, type)}>
                <Border radius={SpacingsEnum.quart} color="grey3">
                    <Box
                        padding="half"
                        alignItems="center"
                        justifyContent="center"
                        flex="grow"
                        color={color}
                        hoverColor={hoverColor}
                    >
                        <IconText
                            icon={type === 'accessories' ? 'category' : 'extension'}
                            iconProps={{ color: textColor, opaque: true }}
                            textProps={{ color: textColor }}
                        >
                            {type === 'accessories' ? t.accessories : t.deviceListApplications}
                        </IconText>
                    </Box>
                </Border>
            </Clickable>
        );
    };

    if (!piaItem) return null;

    return (
        <Stack vertical spacing="half">
            <Stack justifyContent="center" spacing="quart" wrap>
                {renderAddOnButton('accessories', hasAccessories)}
                {!isItemSensorUnit && renderAddOnButton('applications', hasApplications)}
            </Stack>
        </Stack>
    );
};

DeviceAddOns.displayName = 'DeviceAddOns';
