import { useService } from 'app/ioc';
import { useCallback, useEffect, useState } from 'react';
import type { IPressedModifierKeys } from '../models';
import { MapsActionService } from '../services';

/**
 * Hook used to listen to modifier key down events and fires an action to update redux state
 * @returns Pressed modifier keys
 */
export const useModifierKeysDown = () => {
    const [keysPressed, setKeysPressed] = useState<IPressedModifierKeys>({
        isAltDown: false,
        isControlDown: false,
        isShiftDown: false,
    });
    const actions = useService(MapsActionService);

    /** On mac we would like to be able to create new instance also with the command key (meta key)
     *  combination so we also treat this as the ctrl key
     */
    const onKeyChanged = useCallback(
        (keyboardEvent: KeyboardEvent) => {
            const ctrlKey = keyboardEvent.metaKey || keyboardEvent.ctrlKey;
            if (
                keyboardEvent.altKey !== keysPressed.isAltDown ||
                ctrlKey !== keysPressed.isControlDown ||
                keyboardEvent.shiftKey !== keysPressed.isShiftDown
            ) {
                setKeysPressed({
                    isAltDown: keyboardEvent.altKey,
                    isControlDown: ctrlKey,
                    isShiftDown: keyboardEvent.shiftKey,
                });
                actions.setPressedModifierKeys({
                    isAltDown: keyboardEvent.altKey,
                    isControlDown: ctrlKey,
                    isShiftDown: keyboardEvent.shiftKey,
                });
            }
        },
        [actions, keysPressed.isAltDown, keysPressed.isControlDown, keysPressed.isShiftDown],
    );

    const resetKeys = useCallback(() => {
        setKeysPressed({
            isAltDown: false,
            isControlDown: false,
            isShiftDown: false,
        });
        actions.setPressedModifierKeys({
            isAltDown: false,
            isControlDown: false,
            isShiftDown: false,
        });
    }, [actions]);

    // Add event listeners
    useEffect(() => {
        window.addEventListener('keydown', onKeyChanged);
        window.addEventListener('keyup', onKeyChanged);
        window.addEventListener('blur', resetKeys);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', onKeyChanged);
            window.removeEventListener('keyup', onKeyChanged);
            window.removeEventListener('blur', resetKeys);
        };
    }, [onKeyChanged, resetKeys]);

    return keysPressed;
};
