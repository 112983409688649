import type { IPiaCameraProperties } from 'app/core/pia';
import { portMaxPower } from '../../devices/selectors/deviceRequirements';

export const getPowerForCameraFromMaxRating = (piaCameraProperties: IPiaCameraProperties) => {
    const powerFromPiaItem = piaCameraProperties.powerConsumptionW;

    if (powerFromPiaItem) return powerFromPiaItem;

    const poeClassPower = getPowerForCameraFromClass(piaCameraProperties);

    return poeClassPower;
};

/**
 * Get the power requirement of a pia item using a
 * PoE-class estimate.
 */
const getPowerForCameraFromClass = (piaCameraProperties: IPiaCameraProperties) => {
    return piaCameraProperties.PoEClass ? portMaxPower[piaCameraProperties.PoEClass] : 0;
};
