import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';

interface IONVIFProfileFilterProps {
    selectedProfiles: string[];
    onClick(profiles: string[]): void;
}

const allONVIFProfiles: string[] = ['G', 'M', 'S', 'T'];

export const ONVIFProfileFilter: React.FC<IONVIFProfileFilterProps> = ({
    selectedProfiles,
    onClick,
}) => {
    const isProfileSelected = (profile: string) => {
        return selectedProfiles.includes(profile);
    };

    const updateProfiles = (clickedProfile: string) => {
        if (selectedProfiles.includes(clickedProfile)) {
            const removedItemArray = selectedProfiles.filter(
                (selectedProfile) => clickedProfile !== selectedProfile,
            );
            onClick(removedItemArray);
        } else {
            const addedProfileArray = [...selectedProfiles, clickedProfile];
            onClick(addedProfileArray);
        }
    };

    return (
        <DropDown
            stayOpen
            trigger={
                <PillButton
                    text={
                        selectedProfiles.length > 0
                            ? `${t.advancedFiltersGROUP.ONVIFProfiles} \u00b7 ${selectedProfiles.length}`
                            : t.advancedFiltersGROUP.ONVIFProfiles
                    }
                    icon="keyboard_arrow_down"
                    selected={selectedProfiles?.length > 0}
                />
            }
            contents={
                <>
                    {allONVIFProfiles.map((profile) => {
                        return (
                            <DropDownMenuButton
                                key={profile}
                                label={profile}
                                selected={isProfileSelected(profile)}
                                onClick={() => updateProfiles(profile)}
                            />
                        );
                    })}
                </>
            }
        />
    );
};

ONVIFProfileFilter.displayName = 'ONVIFProfileFilter';
