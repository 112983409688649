import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { isDefined } from 'axis-webtools-util';
import { getDefaultProfileOverrideEntity } from 'app/core/persistence/utils/defaultProfileOverridesEntity';
import { PiaItemService } from 'app/core/pia/client/services/PiaItem.service';
import type { IPiaDevice } from 'app/core/pia/client/types/IPiaDevice';
import { getVirtuallyIncludedProducts } from 'app/core/persistence/utils/getVirtuallyIncludedProducts';
import type { IPersistence } from '../../repositories/persistence/models/IPersistence';
import type { IItemEntity } from '../../entities/item/IItemEntity';
import { deviceTypeCheckers } from 'app/core/persistence/utils/deviceUtil';
import type { IItemRelationEntity } from '../../entities/itemRelation/IItemRelationEntity';
import type { IEntity } from '../../entities/IEntity';
import { CreateEntityService } from '../../repositories/persistence/CreateEntity.service';
import type { IPersistenceRepository } from '../../repositories/persistence/models/IPersistenceRepository';

type entityWithChildId = IEntity & { childId: string };
type entityWithProductId = IEntity & { productId: number };

/**
 * Add virtual products to items if missing
 */
@injectable()
export class Migration35To36 extends MigrationProviderBase {
    public from: number = 35;
    public to: number = 36;
    constructor(
        private piaItemService: PiaItemService<IPiaDevice>,
        private createEntityService: CreateEntityService,
    ) {
        super();
    }

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            if (entity.type !== 'item') {
                return entity;
            }

            const piaDevice = this.piaItemService.get(entity.productId).first();
            const virtualProductPiaIds = getVirtuallyIncludedProducts(piaDevice);
            //* Return early if there are no related virtual products for pia device
            if (!virtualProductPiaIds.length || !piaDevice) {
                return entity;
            }
            //* Check if ItemEntity has added relation to its virtual products
            const itemRelations = await repository.getAll('itemRelation');
            const virtualProductRelations = itemRelations.filter(
                (rel: any) => rel.parentId === entity._id && rel.relationType === 'virtualProduct',
            );

            const virtualPiaIdsInProject = (
                await Promise.all(
                    (virtualProductRelations as entityWithChildId[]).map(
                        async (relation) =>
                            ((await repository.get(relation.childId)) as entityWithProductId)
                                .productId,
                    ),
                )
            ).filter(isDefined);

            virtualProductPiaIds.forEach(async (piaId) => {
                const hasVirtualProduct = virtualPiaIdsInProject.includes(piaId);
                // If item doesn't have relation it should have, add new virtual product and item relation
                if (!hasVirtualProduct) {
                    const virtualProductEntity: IEntity = this.createEntityService.create('item', {
                        type: 'item',
                        path: entity.path.slice(),
                    } as unknown as IItemRelationEntity);
                    const virtualProduct: IPersistence<IItemEntity> = {
                        ...virtualProductEntity,
                        name: '',
                        type: 'item',
                        quantity: 1,
                        description: '',
                        notes: '',
                        productId: piaId,
                        properties: {
                            virtualProduct: {
                                associatedProfile: this.getAssociatedProfile(entity),
                                profileOverride: getDefaultProfileOverrideEntity(),
                            },
                        },
                    };

                    await repository.add(virtualProduct);

                    const itemRelationEntity: IEntity = this.createEntityService.create(
                        'itemRelation',
                        {
                            type: 'itemRelation',
                            path: entity.path.slice(),
                        } as unknown as IItemRelationEntity,
                    );
                    const itemRelation: IPersistence<IItemRelationEntity> = {
                        ...itemRelationEntity,
                        type: 'itemRelation',
                        relationType: 'virtualProduct',
                        childId: virtualProduct._id,
                        parentId: entity._id,
                    };

                    await repository.add(itemRelation);
                }
            });
            return entity;
        };
    }

    private getAssociatedProfile(item: IPersistence<IItemEntity>) {
        if (deviceTypeCheckers.isAnalogCamera(item)) {
            return item.properties.analogCamera.associatedProfile;
        }
        if (deviceTypeCheckers.isDoorStation(item) || deviceTypeCheckers.isCamera(item)) {
            return item.properties.camera.associatedProfile;
        }
        if (deviceTypeCheckers.isSensorUnit(item)) {
            return item.properties.sensorUnit.associatedProfile;
        }
        if (deviceTypeCheckers.isVirtualProduct(item)) {
            return item.properties.virtualProduct.associatedProfile;
        }
        return '';
    }
}
