import * as React from 'react';
import type { Colors, Direction } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { css, cx } from '@emotion/css';
import type { IAutoTestable } from '../../ui-test';
import { MultiRangeLabels } from './MultiRangeLabels.component';
import type { ILabeledMinMax } from './models/ILabeledMinMax';

interface IDualRangeProps extends IAutoTestable {
    /** The highest possible value */
    rangeMax: number;
    /** The lowest possible value, defaults to 0 if not specified */
    rangeMin?: number;
    /** Ranges with start and end point */
    ranges: ILabeledMinMax[];
    /** The color to use on the thumbs and selected range */
    color?: Colors;
    /** Defining the direction of the slider and number input fields */
    direction?: Direction;
}

const thumbDiameter = 18;
const sliderHeight = 30;

const sliderRoot = css`
    display: block;
    width: 100%;
    position: relative;
    box-sizing: border-box;
`;

const sliderContainerStyle = css`
    display: flex;
    flex-grow: 1;
`;

const trackStyle = css`
    flex: 1;
    height: 4px;
    background-color: ${ColorsEnum.grey3};
    margin: 0 -${thumbDiameter / 6}px;
`;

/** Component that represents multiple ranges on a slider but without drag functionality. */
export const MultiRange: React.FC<IDualRangeProps> = ({
    rangeMax,
    rangeMin = 0,
    color = 'blue',
    direction,
    ranges,
}) => {
    const sliderRef = React.useRef<HTMLDivElement>(null);

    const sliderStyle = css`
        display: flex;
        flex: 1;
        align-items: center;
        position: relative;
        height: ${sliderHeight}px;
        margin: 0 ${thumbDiameter / 6}px;
    `;

    const thumbStyle = css`
        cursor: default;
        position: absolute;
        height: ${thumbDiameter}px;
        width: ${thumbDiameter}px;
        border: 2px solid ${ColorsEnum.transparent};
        border-radius: 50%;
        box-sizing: border-box;
        z-index: 1;
        top: ${(sliderHeight - thumbDiameter) / 2}px;
        &::before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            border-top: 4px solid ${ColorsEnum.grey3};
            border-right: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 4px solid transparent;
            top: -8px;
            left: 3px;
        }
    `;

    const sliderAndInputStyle = css`
        display: flex;
        flex-direction: ${direction};
    `;

    const getTrackFillStyle = (min: number, max: number) => {
        return css`
            position: absolute;
            top: 13px;
            height: 4px;
            background-color: ${ColorsEnum[color]};
            left: calc(${(((min - rangeMin) / (rangeMax - rangeMin)) * 1000) / 10}%);
            right: calc(100% - ${(((max - rangeMin) / (rangeMax - rangeMin)) * 1000) / 10}%);
        `;
    };

    const getThumbOneStyle = (min: number) => {
        const left = ((min - rangeMin) / (rangeMax - rangeMin)) * 100;
        return css`
            background: radial-gradient(
                ${ColorsEnum[color]},
                ${ColorsEnum[color]} 35%,
                rgba(255, 255, 255, 0) 35%
            );
            left: calc(${left}% - ${thumbDiameter / 2}px);
        `;
    };

    const getThumbTwoStyle = (max: number) => {
        const right = ((max - rangeMin) / (rangeMax - rangeMin)) * 100;
        return css`
            background: radial-gradient(
                ${ColorsEnum[color]},
                ${ColorsEnum[color]} 35%,
                rgba(255, 255, 255, 0) 35%
            );
            left: calc(${right}% - ${thumbDiameter / 2}px);
        `;
    };

    return (
        <div className={sliderRoot} aria-readonly>
            {ranges.map((range) => (
                <MultiRangeLabels
                    key={`${range.min}-${range.max}`}
                    fullRange={{ min: rangeMin, max: rangeMax }}
                    inputRange={range}
                />
            ))}
            <div className={sliderAndInputStyle}>
                <div className={sliderContainerStyle}>
                    <div ref={sliderRef} className={sliderStyle}>
                        <div className={trackStyle} />
                        {ranges.map((range) => (
                            <div key={`${range.min}-${range.max}`}>
                                <div className={getTrackFillStyle(range.min, range.max)} />
                                <div className={cx([thumbStyle, getThumbOneStyle(range.min)])} />
                                <div className={cx([thumbStyle, getThumbTwoStyle(range.max)])} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

MultiRange.displayName = 'MultiRange';
