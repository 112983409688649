import * as React from 'react';
import type { IBodyWornCameraProfile, Id } from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';

import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { StorageSettingsComponent, getWearableProfile } from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';

interface IStorageSelectorProps {
    deviceId: Id;
}

export const StorageSelector: React.FC<IStorageSelectorProps> = ({ deviceId }) => {
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));

    const profile = useSelector<IStoreState, IBodyWornCameraProfile | undefined>((state) =>
        getWearableProfile(state, deviceId),
    );
    if (!profile) {
        return null;
    }

    const onRetentionTimeChanged = (retentionTimeInDays: number) => {
        itemService.updateWearableProfile(deviceId, { retentionTimeInDays });
    };

    return (
        <StorageSettingsComponent
            hideProjectSettingToggle
            profileSetting={{
                retentionTime: profile.retentionTimeInDays,
                useProjectSetting: false,
            }}
            onChangeRetentionTime={onRetentionTimeChanged}
            onChangeUseProjectSetting={() => {}}
        />
    );
};
