import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IItemEntity, IBaseEntity } from '../../entities';

const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

/**
 * Migrate sensorUnitTypes in camera filter properties.
 */
@injectable()
export class Migration22To23 extends MigrationProviderBase {
    public from: number = 22;
    public to: number = 23;

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (
                    entity.properties.camera &&
                    entity.properties.camera.filter.sensorUnitTypes === undefined
                ) {
                    entity.properties.camera.filter.sensorUnitTypes = [];
                }
                if (
                    entity.properties.sensorUnit &&
                    entity.properties.sensorUnit.filter.sensorUnitTypes === undefined
                ) {
                    entity.properties.sensorUnit.filter.sensorUnitTypes = [];
                }
            }
            return entity;
        };
    }
}
