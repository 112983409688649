import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItems } from '../../project';
import { getPiaItemsRecord } from '../../piaDevices';
import { getDeviceType, getSubType } from 'app/core/persistence';
import { getParentIdOfChildDevice } from './getParentIdOfChildDevice';
import { toCacheKey } from '../../cacheKey';

export const getDeviceTypeForParentItem = createCachedSelector(
    [getCurrentProjectItems, getParentIdOfChildDevice, getPiaItemsRecord],
    (currentProjectItems, parentId, piaItemsRecord) => {
        if (!parentId) {
            return undefined;
        }

        const item = currentProjectItems[parentId];
        if (!item || !item.productId) {
            return undefined;
        }

        if (item.productId) {
            const piaItem = piaItemsRecord[item.productId];
            const subType = getSubType(piaItem);

            if (subType) {
                return subType;
            }
        }

        return getDeviceType(item);
    },
)(toCacheKey);
