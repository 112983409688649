import { createSelector } from 'reselect';
import { getDocumentationDevicesRecord } from './getDocumentationDevices';
import { CategoryEnum } from 'app/modules/common';

const allCategories: CategoryEnum[] = Object.keys(CategoryEnum).map(
    (name: string) => (CategoryEnum as any)[name],
);

function getFilteredCategories(categories: CategoryEnum[]): CategoryEnum[] {
    return allCategories.filter((cat) => categories.includes(cat));
}

export const getDocumentationCategories = createSelector(
    [getDocumentationDevicesRecord],
    (documentationDevices) =>
        getFilteredCategories(Object.keys(documentationDevices) as CategoryEnum[]),
);
