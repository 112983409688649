import { reducerBuilder } from 'app/store';
import { QuotationActions } from './actions';
import type { IMsrpInfo, IQuotationState } from './models';

const initialState: IQuotationState = {
    msrpProps: undefined,
    priceList: undefined,
};

export const quotationReducer = reducerBuilder<IQuotationState>()
    .setInitialState(initialState)
    .onAsyncAction(QuotationActions.GetMsrpProps, (handler) => {
        handler.onFulfilled<IMsrpInfo>((state, action) => ({
            ...state,
            msrpProps: action.payload.msrpProps,
            priceList: action.payload.priceList,
        }));
    })
    .create();
