import * as React from 'react';
import { css } from '@emotion/css';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { extendableProps } from '../../services/extendableProps';
import { renderReactChildren } from '../../services/ComponentHelpers';
import type { IExtendableComponentWithChildren } from '../../models';

export interface ICutCornerProps extends IExtendableComponentWithChildren {
    /**
     * Background color of the container
     */
    color: Colors;
    /** Uses small cut */
    smallCut?: boolean;
}

/**
 * Applies a border to the its child.
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export class CutCorner extends React.Component<ICutCornerProps> {
    public render() {
        const { color, smallCut, children, ...extendedProps } = this.props;

        const child = React.Children.only(children);
        const cutSize = smallCut ? 15 : 25;

        const cutCornerStyle = css`
            color-adjust: exact;
            -webkit-print-color-adjust: exact;
            background: linear-gradient(-45deg, transparent ${cutSize}px, ${ColorsEnum[color]} 0);
            [dir='rtl'] & {
                background: linear-gradient(
                    45deg,
                    transparent ${cutSize}px,
                    ${ColorsEnum[color]} 0
                );
            }
        `;

        const attributes = extendableProps(extendedProps, {
            className: cutCornerStyle,
        });

        return renderReactChildren(
            child,
            (c) => React.cloneElement(c, attributes),
            (c) => React.cloneElement(c, attributes.__htmlAttributes),
        );
    }
}
