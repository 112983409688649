import { createCachedSelector } from 're-reselect';
import type { IPiaItemWithPrice } from 'app/modules/common';
import {
    toCacheKey,
    createProductAllowlistFilter,
    getImageSensors,
    getProductAllowlist,
    getSelectedLenses,
    getUseProductAllowlist,
    getPiaItem,
} from 'app/modules/common';

import type { IPiaAccessory, PiaId } from 'app/core/pia';
import { PiaItemState, PiaRelationService } from 'app/core/pia';
import type { ILens } from '../models';
import { ServiceLocator } from 'app/ioc';
import { once, times } from 'lodash-es';
import { calculateVerticalFOV } from './utils';
import { getPiaItemsWithPriceAsIPiaAccessory } from './getPiaItemsWithPriceAsIPiaAccessory';
import { isCamera } from 'app/core/persistence';

/**
 * Array with [sensorIndex, ILens[]] items
 */
export type LensesArray = [number, ILens[]][];

/**
 * Get lenses, cached on itemId.
 * @return {LensesArray} Array with [sensorIndex, ILens[]] items.
 * @example
 *     import { useSelector } from 'react-redux';
 *
 *     const lensesArray = useSelector<IStoreState, LensesArray>((state) => getLenses(state, itemId));
 *     {lensesArray.map(([sensorIndex, lenses]) =>
 *      ...
 *     )}
 */

// We should never use services from selectors since they may have side effects!
// ServiceLocator.get is also slower than expected. To fix the acute performance issue we
// hide it behind a memoized function. Real fix in wt-8604.
const getRelationService = once(() => ServiceLocator.get(PiaRelationService));

export const getLenses = createCachedSelector(
    [
        getPiaItemsWithPriceAsIPiaAccessory,
        getSelectedLenses,
        getImageSensors,
        getUseProductAllowlist,
        getProductAllowlist,
        getPiaItem,
    ],
    (piaAccessories, selectedLenses, imageSensors, useAllowlist, allowlist, piaCamera) => {
        if (!piaCamera) return [];

        const piaRelationService = getRelationService();

        const productId = piaCamera.id;

        const aspectRatio = isCamera(piaCamera)
            ? piaCamera.properties.maxVideoResolutionHorizontal /
              piaCamera.properties.maxVideoResolutionVertical
            : 0;

        const lenses: LensesArray = [];

        const getFoVLimits = (cameraId: PiaId, lensId: PiaId) => {
            const relation = piaRelationService.getRelationProperties(cameraId, lensId);
            if (!relation?.horizontalFOV) return undefined;

            const horizontal = relation.horizontalFOV;
            const vertical = relation.verticalFOV ?? {
                min: calculateVerticalFOV(aspectRatio, relation.horizontalFOV.min),
                max: calculateVerticalFOV(aspectRatio, relation.horizontalFOV.max),
            };

            return {
                horizontal,
                vertical,
            };
        };

        const toILens = (item: IPiaItemWithPrice<IPiaAccessory>, sensorIndex: number): ILens => {
            const fovLimits = getFoVLimits(productId, item.piaItem.id);

            return {
                productId: item.piaItem.id,
                productCategory: item.piaItem.category,
                name: item.piaItem.name,
                discontinued: item.piaItem.state > PiaItemState.EXTERNALLY_ANNOUNCED,
                quantity: 1,
                isRecommended: productId
                    ? piaRelationService.isRecommended(productId, item.piaItem.id)
                    : false,
                isIncluded: productId
                    ? piaRelationService.isIncluded(productId, item.piaItem.id)
                    : false,
                versions: item.piaItem.versions,
                price: item.price,
                id:
                    selectedLenses[sensorIndex][item.piaItem.id] &&
                    selectedLenses[sensorIndex][item.piaItem.id].properties.lens?.sensorIndex ===
                        sensorIndex
                        ? selectedLenses[sensorIndex][item.piaItem.id]._id
                        : undefined,
                rev:
                    selectedLenses[sensorIndex][item.piaItem.id] &&
                    selectedLenses[sensorIndex][item.piaItem.id].properties.lens?.sensorIndex ===
                        sensorIndex
                        ? selectedLenses[sensorIndex][item.piaItem.id]._rev
                        : undefined,
                sensorIndex,
                minHorizontalFOV: fovLimits?.horizontal?.min,
                maxHorizontalFOV: fovLimits?.horizontal?.max,
                minVerticalFOV: fovLimits?.vertical?.min,
                maxVerticalFOV: fovLimits?.vertical?.max,
            };
        };

        times(imageSensors, (sensorIndex) => {
            lenses.push([
                sensorIndex,
                piaAccessories
                    .filter(
                        (item) =>
                            // Filter out allowed lenses.
                            createProductAllowlistFilter(
                                allowlist,
                                useAllowlist,
                            )(item.piaItem.id) ||
                            // Ignore filter allowed lenses if they are already selected.
                            selectedLenses.some((selectedLens) => !!selectedLens[item.piaItem.id]),
                    )
                    .map((lens) => toILens(lens, sensorIndex))
                    // Only use lenses with horizontal fov in PIA, vertical fov is calculated if missing.
                    .filter((lens) => !!lens.maxHorizontalFOV && !!lens.minHorizontalFOV),
            ]);
        });

        return lenses;
    },
)(toCacheKey);
