import * as React from 'react';
import { css } from '@emotion/css';
import type { Icons } from 'app/components';
import { Icon } from 'app/components';
import classNames from 'classnames';

const LargeStyle = css`
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
`;

const ExtraExtraLargeStyle = css`
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
`;

type Size = 'lg' | 'xxl';

interface IPartnerImageProps {
    imageUrl?: string;
    imageSize?: Size;
    icon: Icons;
}

export const PartnerImage: React.FC<IPartnerImageProps> = ({ imageUrl, icon, imageSize }) => {
    const [brokenImage, setBrokenImage] = React.useState<boolean>(false);
    if (!imageUrl) return <Icon icon="extension" size="lg" color="grey5" opaque />;

    return brokenImage ? (
        <Icon icon={icon} size="lg" opaque color="grey5" />
    ) : (
        <img
            className={classNames({
                [LargeStyle]: imageSize === 'lg' || !imageSize,
                [ExtraExtraLargeStyle]: imageSize === 'xxl',
            })}
            src={imageUrl}
            onError={() => setBrokenImage(true)}
        />
    );
};

PartnerImage.displayName = 'PartnerImage';
