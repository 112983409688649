import type { ICouchDBInfo } from 'app/modules/common';

/**
 * These two constants MIN_FILE_SIZE_BYTES and MIN_RATIO are a way of controlling
 * how many users we would target with the compaction feature, and especially
 * MIN_FILE_SIZE_BYTES are intended to be lowered over time to let more and more users do a compaction.
 */

/** MIN_FILE_SIZE_BYTES
 *
 * The minimum database size a user must have to be able to perform a compaction.
 * The initial 25 MB (2023-10) will make it possible for approximately 100 - 150 customers.
 * NOTE: It really should be lowered over time to make the functionality available to more users in a controlled way.
 *
 * --- HISTORY ---
 * 2023-10: 25000000
 * 2024-01: 12000000
 */
const MIN_FILE_SIZE_BYTES = 12000000;

/** MIN_RATIO
 *
 * The MIN_RATIO is the number of tombstones divided by active documents (doc_del_count / doc_count).
 * This variable has two purposes:
 *
 * 1.   It is a measure of how great the need is for a compation.
 *      If a user has less than 1/5 tombstones, then maybe that user is not in such a great need of compaction,
 *      as another user that has a higher amount of deleted documents. It is always a risk involved offering a compaction.
 *
 * 2.   It will prevent users who performed a successful compaction to do another one within short time,
 *      as they will need to build up their ratio of tombstones from 0 to MIN_RATIO (0.2).
 *      They might still have a very large database after compaction, exceeding MIN_FILE_SIZE_BYTES,
 *      that would make them qualify again.
 *      NOTE: There is no current reason for changing this parameter, but lowering the MIN_FILE_SIZE_BYTES,
 *      is the way to make the compaction available for more users.
 */
const MIN_RATIO = 0.2;

/**
 * This class validates the need for opmization (database compation)
 *  due to different parameter values configured in this file.
 * @param dbInfo
 */
export const couchDBInfoValidator = {
    isCompactionAvailable(dbInfo: ICouchDBInfo) {
        if (dbInfo.fileSize > MIN_FILE_SIZE_BYTES && dbInfo.ratio > MIN_RATIO) {
            return true;
        }
        return false;
    },
};
