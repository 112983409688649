import { createCachedSelector } from 're-reselect';
import { getProfileOverride } from './getProfileOverride';
import { getAssociatedProfile } from './getAssociatedProfile';
import { isEmpty } from 'lodash-es';
import { toCacheKey } from '../../cacheKey';
import type { IPersistence, IProfileEntity } from 'app/core/persistence';
import { getCurrentProjectRetentionTime } from '../../project';
import { mergeProfiles } from '../../profile/utils';

export const getMergedProfile = createCachedSelector(
    [getProfileOverride, getAssociatedProfile, getCurrentProjectRetentionTime],
    (profileOverride, profile, projectRetentionTime): IPersistence<IProfileEntity> | undefined => {
        if (!profile) return undefined;

        const mergedProfile = mergeProfiles(
            profile,
            profileOverride,
            projectRetentionTime,
        ) as IPersistence<IProfileEntity>;
        return isEmpty(mergedProfile) ? undefined : mergedProfile;
    },
)(toCacheKey);
