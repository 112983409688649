import type {
    Id,
    IDoorItemEntity,
    IItem,
    IItemEntity,
    IItemPropertiesEntity,
    IPersistence,
    IDoorPropertiesEntity,
    IDoorControllerItemEntity,
    IDoorControllerPropertiesEntity,
    IIdRev,
} from 'app/core/persistence';
import {
    defaultDoorControllerFilter,
    isDoorController,
    ItemService,
    CurrentProjectService,
    InstallationPointService,
} from 'app/core/persistence';

import { PiaAccessoryCategory, PiaItemService, PiaRelationService } from 'app/core/pia';

import type { IPiaAccessControl, PiaId } from 'app/core/pia';
import { ModalService } from 'app/modal';
import { t } from 'app/translate';
import { injectable } from 'inversify';
import type { IAddProductProps } from '../models';
import { AccessoryService } from '../../accessory/services';
import { ChildItemService } from '../../item/services';

@injectable()
export class DoorControllerService {
    constructor(
        private currentProjectService: CurrentProjectService,
        private itemService: ItemService,
        private installationPointService: InstallationPointService,
        private modalService: ModalService,
        private piaItemService: PiaItemService<IPiaAccessControl>,
        private piaRelationService: PiaRelationService,
        private accessoryService: AccessoryService,
        private childItemService: ChildItemService,
    ) {}

    public async addOrUpdateDevice(
        piaItemId: PiaId | null,
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
        shouldAddDoor: boolean = true,
    ) {
        if (itemToEdit) {
            return this.updateDoorController(piaItemId, itemToEdit);
        } else if (newItemProps) {
            return this.addDoorController(piaItemId, newItemProps, shouldAddDoor);
        }
    }

    public async addCardReader(
        doorId: Id,
        cardReaderPiaId: PiaId,
        isSideA: boolean,
        idsToRemove: IIdRev[],
    ): Promise<IPersistence<IItemEntity>> {
        const item: IItem = {
            name: '',
            description: '',
            notes: '',
            productId: cardReaderPiaId ? cardReaderPiaId : null,
            quantity: 1,
            properties: {},
        };

        item.properties.accessory = {
            category: PiaAccessoryCategory.READERS,
        };
        await this.removeCardReaders(idsToRemove);
        const persistedItem = await this.itemService.addByParentId(doorId, item);
        await this.itemService.addItemRelation(
            doorId,
            persistedItem._id,
            isSideA ? 'doorSideA' : 'doorSideB',
        );

        return persistedItem;
    }

    public async addRexDevice(
        doorId: Id,
        cardReaderPiaId: PiaId,
        isSideA: boolean,
        idsToRemove: IIdRev[],
    ): Promise<IPersistence<IItemEntity>> {
        const item: IItem = {
            name: '',
            description: '',
            notes: '',
            productId: cardReaderPiaId ? cardReaderPiaId : null,
            quantity: 1,
            properties: {},
        };

        item.properties.accessory = {
            category: PiaAccessoryCategory.REX,
        };

        const persistedItem = await this.itemService.addByParentId(doorId, item);
        await this.itemService.addItemRelation(
            doorId,
            persistedItem._id,
            isSideA ? 'doorSideA' : 'doorSideB',
        );
        await this.removeCardReaders(idsToRemove);

        return persistedItem;
    }

    public async addDoor(doorControllerId: Id) {
        const door = await this.itemService.addByParentId(doorControllerId, {
            name: t.devicesGROUP['door'],
            quantity: 1,
            description: '',
            notes: '',
            productId: null,
            properties: {
                door: {
                    nbrOfLocks: 1,
                },
            },
        });

        await this.itemService.addItemRelation(doorControllerId, door._id, 'door');
        return door;
    }

    public removeCardReader = async (cardReader: IIdRev) => {
        await this.removeCardReaders([cardReader]);
    };

    public removeCardReaders = async (cardReaders: IIdRev[]) => {
        await this.itemService.deleteItems(cardReaders);
    };

    public async updateDoor(itemId: Id, props: Partial<IItem>) {
        const updatedDoor = await this.itemService.updateItem(itemId, props);

        if (!updatedDoor) {
            throw new Error(`Door item with id: ${itemId} could not be updated`);
        }

        return updatedDoor;
    }

    public async updateDoorProps(
        itemToEdit: IPersistence<IDoorItemEntity>,
        props: Partial<IDoorPropertiesEntity>,
    ) {
        const updatedDoor = await this.itemService.updateItem(itemToEdit._id, {
            name: itemToEdit.name,
            productId: itemToEdit.productId,
            description: itemToEdit.description,
            notes: itemToEdit.notes,
            quantity: itemToEdit.quantity,
            properties: {
                ...itemToEdit.properties,
                door: {
                    nbrOfLocks: props.nbrOfLocks
                        ? props.nbrOfLocks
                        : itemToEdit.properties.door?.nbrOfLocks,
                },
            },
        });

        if (!updatedDoor) {
            throw new Error(`Door item with id: ${itemToEdit._id} could not be updated`);
        }

        return updatedDoor;
    }

    public removeDoor = async (doorId: Id) => {
        await this.itemService.deleteItem(doorId);
    };

    public updatePowerSupply = async (
        itemToEdit: IPersistence<IDoorControllerItemEntity>,
        props: Partial<IDoorControllerPropertiesEntity>,
    ) => {
        const updateDoorController = await this.itemService.updateItem(itemToEdit._id, {
            ...itemToEdit,
            properties: {
                ...itemToEdit.properties,
                doorController: {
                    filter: itemToEdit.properties.doorController.filter,
                    powerSupply: props.powerSupply,
                },
            },
        });
        return updateDoorController;
    };

    private addDoorController = async (
        productId: PiaId | null,
        newItemProps: IAddProductProps,
        shouldAddDoor: boolean = true,
    ) => {
        const item: IItem = {
            description: '',
            name: newItemProps.name,
            notes: newItemProps.notes || '',
            productId,
            properties: this.toProperties(),
            quantity: newItemProps.quantity,
            color: newItemProps.color,
        };

        const doorController = await this.itemService.addToCurrentProject(item);
        if (productId && shouldAddDoor) {
            this.childItemService.addDoor(doorController._id);
        }
        return doorController;
    };

    private toProperties(): IItemPropertiesEntity {
        return { doorController: { filter: defaultDoorControllerFilter } };
    }

    /**
     * Check if doorController to change to
     * 1.has support for all selected number of doors
     * 2.has support for all selected number of locks
     * 3.has support for possible selected readers and rex devices
     * @param itemToEditId item id of the current selected doorController
     * @param productId piaId of the doorController chosen to change to
     * @returns true if old doorController with selected doors, readers and rex devices,
     *  is compatible with new one, false otherwise
     */
    private isCompatibleWithNewDoorController(itemToEditId: Id, productId: PiaId | null): boolean {
        const piaDoorController = productId
            ? this.piaItemService.get(productId).first()
            : undefined;
        if (!productId || !isDoorController(piaDoorController)) {
            return false;
        }

        const doors = this.getDoorsItems(itemToEditId);

        const usedNbrOfLocks = this.getUsedNbrOfLocksForDoors(doors);
        if (usedNbrOfLocks > piaDoorController.properties.supportedOnboardDoors) {
            return false;
        }

        const allDoorReaders: IPersistence<IItemEntity>[] = doors.flatMap((door) =>
            this.getDoorReaders(door._id),
        );

        const allDoorRexs: IPersistence<IItemEntity>[] = doors.flatMap((door) =>
            this.getDoorRexs(door._id),
        );

        if (
            doors.length > piaDoorController.properties.supportedOnboardDoors ||
            allDoorRexs.length > piaDoorController.properties.maxSupportedREXdevices ||
            allDoorReaders.length > piaDoorController.properties.supportedOnboardReaders
        ) {
            return false;
        }

        //check if selected readers and rex devices are compatible with the new doorController
        const areReadersCompatible = allDoorReaders.every((reader) => {
            if (reader.productId) {
                return this.piaRelationService.isCompatibleWith(productId, reader.productId);
            } else {
                // if no product id, the item is other and is compatible
                return true;
            }
        });

        if (!areReadersCompatible) {
            return false;
        }

        const areRexsCompatible = allDoorRexs.every((rex) => {
            if (rex.productId) {
                return this.piaRelationService.isCompatibleWith(productId, rex.productId);
            } else {
                // if no product id, the item is other and is compatible
                return true;
            }
        });

        if (!areRexsCompatible) {
            return false;
        }

        return true;
    }

    private getDoorsItems = (doorControllerId: Id) =>
        this.currentProjectService.getDeviceChildren(
            doorControllerId,
            'door',
        ) as IPersistence<IDoorItemEntity>[];

    /**
     * get the readers for the door with doorId (including other)
     * @param doorId
     * @returns readers for the door and side
     */
    private getDoorReaders = (doorId: Id): IPersistence<IItemEntity>[] => {
        const children = this.currentProjectService.getDeviceChildren(doorId, [
            'doorSideA',
            'doorSideB',
        ]);
        const readers = children.filter(
            (child) => child.properties.accessory?.category === PiaAccessoryCategory.READERS,
        );
        return readers;
    };

    /**
     * get the rex devices for the door with doorId (including other)
     * @param doorId
     * @returns rex devices for the door and side
     */
    private getDoorRexs = (doorId: Id): IPersistence<IItemEntity>[] => {
        const children = this.currentProjectService.getDeviceChildren(doorId, [
            'doorSideA',
            'doorSideB',
        ]);
        const rexs = children.filter(
            (child) => child.properties.accessory?.category === PiaAccessoryCategory.REX,
        );

        return rexs;
    };

    /**
     *
     * @param doors doors to get used locks for
     * @returns number of used locks for the doors
     */
    private getUsedNbrOfLocksForDoors = (doors: IPersistence<IDoorItemEntity>[]): number => {
        const usedNbrOfLocks = doors.reduce((sum, doorItem) => {
            return sum + (doorItem.properties.door?.nbrOfLocks ?? 1);
        }, 0);
        return usedNbrOfLocks;
    };

    private removeAllDoors = async (doorControllerId: Id) => {
        const doors = await this.getDoorsItems(doorControllerId);

        await this.removeDoors(doors);
        return this.itemService.getItem(doorControllerId);
    };

    private removeDoors = async (doors: IPersistence<IDoorItemEntity>[]) => {
        Promise.all(doors.map(async (door) => this.itemService.deleteItem(door._id)));
    };

    private updateDoorController = async (
        productId: PiaId | null,
        itemToEdit: IPersistence<IItemEntity>,
    ) => {
        const modelChanged = itemToEdit.productId !== productId;
        const { descendants } = await this.installationPointService.getInstallationPointDescendants(
            itemToEdit._id,
        );
        const doorsInMap = descendants.filter((item) => item.parentId);

        if (modelChanged) {
            const isCompatibleWithNewDoorController = this.isCompatibleWithNewDoorController(
                itemToEdit._id,
                productId,
            );

            let isAllAccessoriesRemoved = false;

            const hasIncompatibleChildren = this.accessoryService.getHasPiaItemsToRemove(
                itemToEdit._id,
                productId,
            );

            if (!isCompatibleWithNewDoorController) {
                const confirmed = hasIncompatibleChildren
                    ? await this.accessoryService.getConfirmDialogue(
                          itemToEdit._id,
                          productId,
                          t.removeDoorsAndAccessoriesConfirmationGROUP.header,
                          t.removeDoorsAndAccessoriesConfirmationBody,
                      )
                    : await this.modalService.createConfirmDialog({
                          header: t.removeDoorsConfirmationGROUP.header,
                          body: t.removeDoorsConfirmationGROUP.body,
                          cancelButtonText: t.cancel,
                          confirmButtonText: t.change,
                      })();
                if (confirmed) {
                    await this.installationPointService.removeInstallationPoints(doorsInMap);
                    await this.removeAllDoors(itemToEdit._id);
                    await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                        itemToEdit._id,
                        productId,
                    );
                    isAllAccessoriesRemoved = true;
                } else {
                    return;
                }
            }

            if (hasIncompatibleChildren && !isAllAccessoriesRemoved) {
                const confirmed = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                );

                if (confirmed) {
                    await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                        itemToEdit._id,
                        productId,
                    );
                } else {
                    return;
                }
            }
        }

        itemToEdit.properties.doorController = {
            filter:
                itemToEdit?.properties.doorController !== undefined
                    ? itemToEdit.properties.doorController.filter
                    : defaultDoorControllerFilter,
        };

        const itemProps: Partial<IItem> = {
            name: itemToEdit.name,
            quantity: itemToEdit.quantity,
            description: itemToEdit.description,
            notes: itemToEdit.notes,
            productId,
            properties: itemToEdit.properties,
        };

        return this.itemService.updateItem(itemToEdit._id, itemProps);
    };
}
