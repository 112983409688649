import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Stack } from 'app/components';
import { AppConstants } from 'app/AppConstants';
import { Toast } from './Toast.component';
import type { ToastModel } from '../models/Toast.model';

interface IToasterProps {
    toasts: ToastModel[];
}

function mapStateToProps(storeState: IStoreState): IToasterProps {
    return {
        toasts: storeState.toasts.toasts,
    };
}

class Toaster extends React.Component<IToasterProps> {
    public render() {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: '100px',
                    right: '50px',
                    zIndex: AppConstants.toastDepth,
                }}
            >
                <Stack vertical spacing="none" justifyContent="end" alignItems="end">
                    {this.props.toasts.map((toast) => (
                        <Toast key={toast.id} toast={toast} />
                    ))}
                </Stack>
            </div>
        );
    }
}

export const ToasterContainer = connect(mapStateToProps)(Toaster);
