import type { IPiaItem, PiaId } from 'app/core/pia';
import { PiaItemBareboneCategory, PiaItemState } from 'app/core/pia';
import { createSelector } from 'reselect';
import { getAvailablePiaItems } from './getPiaDevices';

export const getBareboneParentRecord = createSelector([getAvailablePiaItems], (piaItems) =>
    piaItems.reduce(
        (record, current) => {
            if (
                current.category === PiaItemBareboneCategory.BAREBONE &&
                current.state === PiaItemState.EXTERNALLY_ANNOUNCED
            ) {
                record[current.parentId] = current;
            }
            return record;
        },
        {} as Record<PiaId, IPiaItem>,
    ),
);
