import * as React from 'react';
import { ProductCardItem, ProductCard, Clickable } from 'app/components';

interface IStandardLens {
    onSelect(): void;
    selected: boolean;
    disabled: boolean;
    readOnly: boolean;
    standardLensName: string;
}

/**
 * @example <StandardLens onSelect={() => console.info('select') selected={false} />
 */
export const StandardLens: React.FC<IStandardLens> = ({
    onSelect,
    selected,
    disabled,
    standardLensName,
}) => (
    <Clickable onClick={onSelect} disabled={disabled}>
        <ProductCard selected={selected}>
            <ProductCardItem name={standardLensName} icon={'lens'} productId={null} />
        </ProductCard>
    </Clickable>
);

StandardLens.displayName = 'StandardLens';
