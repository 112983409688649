import { Module } from 'app/ioc';
import { ProjectCustomerInfoService } from './services/ProjectCustomerInfo.service';
import {
    ItemRepository,
    ProjectRepository,
    ScheduleRepository,
    TimeSerieRepository,
    ProfileRepository,
    ItemRelationRepository,
    CustomItemRepository,
    ProjectQuotationRepository,
    InstallationPointRepository,
    UserSettingsRepository,
    GenericEntityRepository,
    UserCustomItemRepository,
    FreeTextPointRepository,
    PartnerItemRepository,
} from './userDataPersistence/repositories';
import {
    EntitySettings,
    PersistenceDatabaseRepository,
    TimestampProviderService,
    PersistenceMemoryRepository,
} from './userDataPersistence/repositories/persistence';
import {
    ConflictResolutionService,
    UserDataStorageCommunicator,
    ReplicationService,
    SharedUserDataCommunicator,
} from './userDataPersistence/replication';
import {
    ProjectService,
    ItemService,
    UserPreferencesService,
    ProfileService,
    DuplicationService,
    CleanupService,
    PersistenceConfigurationService,
    OfflineService,
    SyncService,
    AuthenticationService,
    EulaService,
    WelcomeService,
    ProjectImportService,
    ProjectExportService,
    EntityImportService,
    NameGenerationService,
    CustomItemService,
    StorageMigrationService,
    PriceService,
    ContactDetailsService,
    UserDataRepository,
    UserService,
    UserApiCommunicator,
    ProjectQuotationService,
    FloorPlanService,
    BlockerService,
    InstallationPointService,
    InstallationPointDeviceService,
    ParentChildInstallationPointService,
    ImageStoreCommunicator,
    CurrentProjectService,
    ProjectModelService,
    ScheduleModelService,
    ScheduleService,
    MemoryPersistenceConfigurationService,
    ImageService,
    QuantityUpdateVerifier,
    LensSelectorService,
    UserCustomItemService,
    IPRangeService,
    LocalPersistenceConfigurationService,
    FreeTextPointService,
    LocalImageService,
    DeviceDescriptionCommunicator,
    DeviceDescriptionService,
    LanguageService,
    PartnerItemService,
    OrganizationsService,
    ACXApiCommunicator,
    PersistenceConfigurationServiceFactory,
    MapLocationsService,
} from './services';
import {
    MigrationService,
    MigrationProviderBase,
    Migration0To1,
    Migration1To2,
    Migration2To3,
    Migration3To4,
    Migration4To5,
    Migration5To6,
    Migration6To7,
    Migration7To8,
    Migration8To9,
    Migration9To10,
    Migration10To11,
    Migration11To12,
    Migration12To13,
    Migration13To14,
    Migration14To15,
    Migration15To16,
    Migration16To17,
    Migration17To18,
    Migration18To19,
    Migration19To20,
    Migration20To21,
    Migration21To22,
    Migration22To23,
    Migration23To24,
    Migration24to25,
    Migration25to26,
    Migration26to27,
    Migration27to28,
    Migration28To29,
    Migration29To30,
    Migration30To31,
    Migration31To32,
    Migration32To33,
    Migration33To34,
    Migration34To35,
    Migration35To36,
    Migration36To37,
    Migration37To38,
    Migration38To39,
    Migration39to40,
    Migration40to41,
    Migration41to42,
    Migration42to43,
    Migration43To44,
    Migration44to45,
    Migration45to46,
    Migration46to47,
    Migration47to48,
    Migration48To49,
    Migration49To50,
} from './userDataPersistence/migration';
import {
    RepairService,
    CreateEntityService,
    FloorPlanRepository,
    BlockerRepository,
} from './userDataPersistence';
import { PartnerConfigCommunicator, PartnerConfigService } from './services/partnerConfig';
import { SharedProjectRepository, SharedProjectsService } from './services/sharedProjects';
import { MapLocationRepository } from './userDataPersistence/repositories/MapLocation.repository';

@Module({
    binder: (binder) => {
        binder.bindConstant(EntitySettings, new EntitySettings());
        binder.bindConstant(
            PersistenceMemoryRepository,
            new PersistenceMemoryRepository([], new EntitySettings()),
        );

        binder.bindSingleton(PersistenceDatabaseRepository);
        binder.bindSingleton(ItemRepository);
        binder.bindSingleton(ProjectRepository);
        binder.bindSingleton(ScheduleRepository);
        binder.bindSingleton(TimeSerieRepository);
        binder.bindSingleton(ProfileRepository);
        binder.bindSingleton(ItemRelationRepository);
        binder.bindSingleton(CustomItemRepository);
        binder.bindSingleton(UserCustomItemRepository);
        binder.bindSingleton(UserDataRepository);
        binder.bindSingleton(ProjectQuotationRepository);
        binder.bindSingleton(FloorPlanRepository);
        binder.bindSingleton(BlockerRepository);
        binder.bindSingleton(InstallationPointRepository);
        binder.bindSingleton(MapLocationRepository);
        binder.bindSingleton(UserSettingsRepository);
        binder.bindSingleton(GenericEntityRepository);
        binder.bindSingleton(FreeTextPointRepository);
        binder.bindSingleton(PartnerItemRepository);

        binder.bindService(TimestampProviderService);
        binder.bindService(UserDataStorageCommunicator);
        binder.bindService(SharedUserDataCommunicator);
        binder.bindService(ConflictResolutionService);
        binder.bindService(ReplicationService);
        binder.bindService(QuantityUpdateVerifier);

        binder.bindService(CurrentProjectService);
        binder.bindService(ImageService);
        binder.bindService(ImageStoreCommunicator);
        binder.bindService(PersistenceConfigurationService);
        binder.bindService(IPRangeService);
        binder.bindService(ProjectService);
        binder.bindService(ItemService);
        binder.bindService(UserPreferencesService);
        binder.bindService(ScheduleModelService);
        binder.bindService(CleanupService);
        binder.bindService(ProfileService);
        binder.bindService(PriceService);
        binder.bindService(DuplicationService);
        binder.bindService(ProjectCustomerInfoService);
        binder.bindService(OfflineService);
        binder.bindService(SyncService);
        binder.bindService(AuthenticationService);
        binder.bindService(EulaService);
        binder.bindService(WelcomeService);
        binder.bindService(ProjectImportService);
        binder.bindService(ProjectExportService);
        binder.bindService(EntityImportService);
        binder.bindService(NameGenerationService);
        binder.bindService(CustomItemService);
        binder.bindService(StorageMigrationService);
        binder.bindService(RepairService);
        binder.bindService(CreateEntityService);
        binder.bindService(ContactDetailsService);
        binder.bindService(UserApiCommunicator);
        binder.bindService(UserService);
        binder.bindService(UserCustomItemService);
        binder.bindService(MigrationService);
        binder.bindService(ProjectModelService);
        binder.bindService(ProjectQuotationService);
        binder.bindService(FloorPlanService);
        binder.bindService(BlockerService);
        binder.bindService(LocalImageService);
        binder.bindService(InstallationPointService, true);
        binder.bindService(InstallationPointDeviceService);
        binder.bindService(MapLocationsService);
        binder.bindService(ParentChildInstallationPointService);
        binder.bindService(PartnerConfigService);
        binder.bindService(PartnerConfigCommunicator);
        binder.bindService(ScheduleService);
        binder.bindService(MemoryPersistenceConfigurationService);
        binder.bindService(LensSelectorService);
        binder.bindService(LocalPersistenceConfigurationService);
        binder.bindService(FreeTextPointService);
        binder.bindService(LanguageService);
        binder.bindService(DeviceDescriptionService);
        binder.bindService(DeviceDescriptionCommunicator);
        binder.bindService(PartnerItemService);
        binder.bindService(OrganizationsService);
        binder.bindService(ACXApiCommunicator);
        binder.bindService(SharedProjectsService);
        binder.bindService(SharedProjectRepository);
        binder.bindService(PersistenceConfigurationServiceFactory);

        binder.bindSingletonAs(MigrationProviderBase, Migration0To1);
        binder.bindSingletonAs(MigrationProviderBase, Migration1To2);
        binder.bindSingletonAs(MigrationProviderBase, Migration2To3);
        binder.bindSingletonAs(MigrationProviderBase, Migration3To4);
        binder.bindSingletonAs(MigrationProviderBase, Migration4To5);
        binder.bindSingletonAs(MigrationProviderBase, Migration5To6);
        binder.bindSingletonAs(MigrationProviderBase, Migration6To7);
        binder.bindSingletonAs(MigrationProviderBase, Migration7To8);
        binder.bindSingletonAs(MigrationProviderBase, Migration8To9);
        binder.bindSingletonAs(MigrationProviderBase, Migration9To10);
        binder.bindSingletonAs(MigrationProviderBase, Migration10To11);
        binder.bindSingletonAs(MigrationProviderBase, Migration11To12);
        binder.bindSingletonAs(MigrationProviderBase, Migration12To13);
        binder.bindSingletonAs(MigrationProviderBase, Migration13To14);
        binder.bindSingletonAs(MigrationProviderBase, Migration14To15);
        binder.bindSingletonAs(MigrationProviderBase, Migration15To16);
        binder.bindSingletonAs(MigrationProviderBase, Migration16To17);
        binder.bindSingletonAs(MigrationProviderBase, Migration17To18);
        binder.bindSingletonAs(MigrationProviderBase, Migration18To19);
        binder.bindSingletonAs(MigrationProviderBase, Migration19To20);
        binder.bindSingletonAs(MigrationProviderBase, Migration20To21);
        binder.bindSingletonAs(MigrationProviderBase, Migration21To22);
        binder.bindSingletonAs(MigrationProviderBase, Migration22To23);
        binder.bindSingletonAs(MigrationProviderBase, Migration23To24);
        binder.bindSingletonAs(MigrationProviderBase, Migration24to25);
        binder.bindSingletonAs(MigrationProviderBase, Migration25to26);
        binder.bindSingletonAs(MigrationProviderBase, Migration26to27);
        binder.bindSingletonAs(MigrationProviderBase, Migration27to28);
        binder.bindSingletonAs(MigrationProviderBase, Migration28To29);
        binder.bindSingletonAs(MigrationProviderBase, Migration29To30);
        binder.bindSingletonAs(MigrationProviderBase, Migration30To31);
        binder.bindSingletonAs(MigrationProviderBase, Migration31To32);
        binder.bindSingletonAs(MigrationProviderBase, Migration32To33);
        binder.bindSingletonAs(MigrationProviderBase, Migration33To34);
        binder.bindSingletonAs(MigrationProviderBase, Migration34To35);
        binder.bindSingletonAs(MigrationProviderBase, Migration35To36);
        binder.bindSingletonAs(MigrationProviderBase, Migration36To37);
        binder.bindSingletonAs(MigrationProviderBase, Migration37To38);
        binder.bindSingletonAs(MigrationProviderBase, Migration38To39);
        binder.bindSingletonAs(MigrationProviderBase, Migration39to40);
        binder.bindSingletonAs(MigrationProviderBase, Migration40to41);
        binder.bindSingletonAs(MigrationProviderBase, Migration41to42);
        binder.bindSingletonAs(MigrationProviderBase, Migration42to43);
        binder.bindSingletonAs(MigrationProviderBase, Migration43To44);
        binder.bindSingletonAs(MigrationProviderBase, Migration44to45);
        binder.bindSingletonAs(MigrationProviderBase, Migration45to46);
        binder.bindSingletonAs(MigrationProviderBase, Migration46to47);
        binder.bindSingletonAs(MigrationProviderBase, Migration47to48);
        binder.bindSingletonAs(MigrationProviderBase, Migration48To49);
        binder.bindSingletonAs(MigrationProviderBase, Migration49To50);
    },
})
export class PersistenceModule {}
