import type { IPiaItem, IPiaLocation } from 'app/core/pia';
import { PiaItemService, PiaLocationService } from 'app/core/pia';
import { injectable } from 'inversify';
import { getFallbackPiaItem } from '../utils/getFallbackPiaItem';

@injectable()
export class PiaQueriesService {
    constructor(
        private piaItemService: PiaItemService<IPiaItem>,
        private piaLocationService: PiaLocationService<IPiaLocation>,
    ) {}

    public getPiaItemFromId(piaId: number): IPiaItem {
        const piaItem = this.piaItemService.get(piaId).first();

        if (!piaItem) {
            return getFallbackPiaItem(piaId);
        }
        return piaItem;
    }

    public getLocationFromId(locationId: number): IPiaLocation {
        const foundLocation = this.piaLocationService.get(locationId).first();

        if (!foundLocation) {
            throw new Error(`Could not find location with id: ${locationId}`);
        }
        return foundLocation;
    }
}
