import type { IPiaBandwidthCalculationProperties } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import { Frequency, getPiaCameras, getProjectFrequency } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

/**
 * Takes a frequency and returns the corresponding fps property key from {@link IPiaBandwidthCalculationProperties}
 * @param projectFrequency Frequency of project location
 * @returns Fps per frequency key or undefined
 */
export const getFpsPerFrequencyKey = (
    projectFrequency: Frequency | undefined,
): keyof Pick<IPiaBandwidthCalculationProperties, 'maxFPS50Hz' | 'maxFPS60Hz'> | undefined => {
    switch (projectFrequency) {
        case Frequency.Hz50:
            return 'maxFPS50Hz';
        case Frequency.Hz60:
            return 'maxFPS60Hz';
        default:
            return undefined;
    }
};

const getIncludeDiscontinued = (state: IStoreState) => state.deviceSelector.includeDiscontinued;
const getMinFpsFilter = (state: IStoreState) => state.deviceSelector.cameraFilter.minFps;

/** Creates an array of unique fps options based on all camera values for project frequency. */
export const getFpsOptions = createSelector(
    [getPiaCameras, getProjectFrequency, getIncludeDiscontinued, getMinFpsFilter],
    (piaItems, frequency, includeDiscontinued, fpsFilter) => {
        const frequencyKey = getFpsPerFrequencyKey(frequency);
        if (!frequencyKey || !frequency) {
            return [];
        }
        // Make sure selected filter always appear in options even if no displayed cameras match value
        const uniqueFps = new Set<number>(fpsFilter ? [fpsFilter] : []);

        piaItems.forEach((item) => {
            if (includeDiscontinued || !isDiscontinued(item))
                uniqueFps.add(item.properties[frequencyKey]);
        });

        return Array.from(uniqueFps).sort((a, b) => a - b);
    },
);
