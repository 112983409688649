import type { IPartnerSystemComponent } from 'app/core/persistence';
import type { IRecordingSolutionItem } from '../selectors';

export const isAxisProduct = (
    items: IRecordingSolutionItem[] | IPartnerSystemComponent[],
): items is IRecordingSolutionItem[] => {
    if (items.length === 0) {
        return false;
    }
    return (items as IRecordingSolutionItem[])[0].piaId !== undefined;
};

export const isPartnerProduct = (
    items: IRecordingSolutionItem[] | IPartnerSystemComponent[],
): items is IPartnerSystemComponent[] => {
    if (items.length === 0) {
        return false;
    }
    return (items as IPartnerSystemComponent[])[0].dataSheetUrl !== undefined;
};
