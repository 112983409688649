import { PiaAccessoryCategory } from 'app/core/pia';

export enum DisplayCategory {
    AudioAndIo = 'audioandio',
    Connectivity = 'connectivity',
    HousingsAndCabinets = 'housingsandcabinets',
    Illuminators = 'illuminators',
    Joysticks = 'joystickscontr',
    Lenses = 'lenses',
    Mounts = 'mounts',
    Other = 'other',
    Power = 'power',
    Readers = 'readers',
    Storage = 'storage',
}

export const mapToDisplayCategory: Record<string, DisplayCategory> = {
    [PiaAccessoryCategory.ILLUMINATORS]: DisplayCategory.Illuminators,
    [PiaAccessoryCategory.LENSES]: DisplayCategory.Lenses,

    [PiaAccessoryCategory.POWERSUPPLIES]: DisplayCategory.Power,
    [PiaAccessoryCategory.POE]: DisplayCategory.Power,

    [PiaAccessoryCategory.CABLESCON]: DisplayCategory.Connectivity,
    [PiaAccessoryCategory.ETHERNETOVERCOAX]: DisplayCategory.Connectivity,
    [PiaAccessoryCategory.MEDIACONVERTERS]: DisplayCategory.Connectivity,
    [PiaAccessoryCategory.SURGEPROTECTORS]: DisplayCategory.Connectivity,
    [PiaAccessoryCategory.POEEXTENDERS]: DisplayCategory.Connectivity,

    [PiaAccessoryCategory.COVERSDOMES]: DisplayCategory.HousingsAndCabinets,
    [PiaAccessoryCategory.CABINETS]: DisplayCategory.HousingsAndCabinets,
    [PiaAccessoryCategory.HOUSINGS]: DisplayCategory.HousingsAndCabinets,

    [PiaAccessoryCategory.AUDIOANDIO]: DisplayCategory.AudioAndIo,
    [PiaAccessoryCategory.JOYSTICKSCONTR]: DisplayCategory.Joysticks,
    [PiaAccessoryCategory.READERS]: DisplayCategory.Readers,
    [PiaAccessoryCategory.MOUNTS]: DisplayCategory.Mounts,
    [PiaAccessoryCategory.STORAGE]: DisplayCategory.Storage,
};
