import { createCachedSelector } from 're-reselect';
import type { IDesiredCamera } from 'app/modules/common';
import {
    getPiaCameraForProductId,
    getPiaLensesRecord,
    toCacheKey,
    calculate,
} from 'app/modules/common';
import { getCameraFilter, getSensorUnitFilter } from './getProductsForDeviceGroup';
import { trigonometry } from 'axis-webtools-util';
import { getBestApplicableLens, isPixelDensityFulfilled } from '../utils';
import type { IPiaAccessory, IPiaCamera, IPiaItem, IPiaSensorUnit, PiaId } from 'app/core/pia';
import type { ICameraFilter, ISensorUnitFilter } from '../models';
import { isSensorUnit } from 'app/core/persistence';

export type IProductLensRecord = Record<PiaId, IPiaAccessory | undefined>;

export const getIsPixelDensityFulfilled = createCachedSelector(
    [getCameraFilter, getPiaCameraForProductId, getSensorUnitFilter],
    (cameraFilter, camera, sensorUnitFilter): boolean => {
        if (!camera) {
            return false;
        }
        const { trueDistance, desiredFilter } = getTrueDistanceAndDesiredFilter(
            cameraFilter,
            camera,
            sensorUnitFilter,
        );
        return isPixelDensityFulfilled(camera.properties, desiredFilter, trueDistance);
    },
)(toCacheKey);

export const getBestReplacementLens = createCachedSelector(
    [
        getCameraFilter,
        getPiaCameraForProductId,
        getPiaLensesRecord,
        getIsPixelDensityFulfilled,
        getSensorUnitFilter,
    ],
    (
        cameraFilter,
        camera,
        piaLenses,
        pixelDensityFulfilled,
        sensorUnitFilter,
    ): IPiaAccessory | undefined =>
        findBestLens(cameraFilter, camera, piaLenses, pixelDensityFulfilled, sensorUnitFilter),
)(toCacheKey);

export const findBestLens = (
    cameraFilter: ICameraFilter,
    camera: IPiaSensorUnit | IPiaCamera | undefined,
    piaLenses: Record<number, IPiaItem>,
    pixelDensityFulfilled: boolean,
    sensorUnitFilter: ISensorUnitFilter,
): IPiaAccessory | undefined => {
    if (!camera) {
        return undefined;
    }
    const isSensor = isSensorUnit(camera);
    const desiredFilter = isSensor
        ? sensorUnitFilter.desiredSensorUnit
        : cameraFilter.desiredCamera;

    //* Never replace lens if device has no scene requirements, but make an exception if no lens exists, i.e FOV=0
    if (
        !desiredFilter.isSceneFilterActive &&
        camera.properties.maxHorizontalFOV !== 0 &&
        camera.properties.minHorizontalFOV !== 0
    ) {
        return undefined;
    }
    //* Check if device fulfills scene requirements with standard lens
    const maxHorizontalFovRadians = trigonometry.toRadians(camera.properties.maxHorizontalFOV);
    const filterHorizontalFov = desiredFilter.horizontalFOVRadians;
    const FovFulfilled = maxHorizontalFovRadians >= filterHorizontalFov;

    //* If both max horizontal FoV and pixel density requirements are fulfilled we shouldn't replace the standard lens
    if (FovFulfilled && pixelDensityFulfilled) {
        return undefined;
    }

    return getBestApplicableLens(
        camera.relations,
        piaLenses,
        desiredFilter,
        camera.properties.maxVideoResolutionHorizontal,
        !pixelDensityFulfilled,
    );
};

export const getTrueDistanceAndDesiredFilter = (
    cameraFilter: ICameraFilter,
    device: IPiaCamera | IPiaSensorUnit,
    sensorUnitFilter: ISensorUnitFilter,
): { desiredFilter: IDesiredCamera; trueDistance: number } => {
    const desiredFilter: IDesiredCamera = isSensorUnit(device)
        ? sensorUnitFilter.desiredSensorUnit
        : cameraFilter.desiredCamera;
    const { distanceToTarget, installationHeight, targetHeight } = desiredFilter;
    return {
        desiredFilter,
        trueDistance: calculate.trueDistance(installationHeight, distanceToTarget, targetHeight),
    };
};
