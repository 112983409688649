import { isMultisensor } from './multisensor';
import type { IInstallationPointSensorModel } from 'app/core/persistence';
import { isSensorDevice } from 'app/core/persistence';

export const calculateSensorResolutionLimit = (
    sensor: IInstallationPointSensorModel,
    horizontalFov: number,
) => {
    const piaDevice = sensor.parentPiaDevice;
    const parentDevice = sensor.parentDevice;

    if (!isSensorDevice(piaDevice)) {
        return undefined;
    }

    const maxVideoResolution = piaDevice.properties.maxVideoResolutionHorizontal;

    const nbrOfChannels =
        isMultisensor(piaDevice) && piaDevice?.properties.channels
            ? piaDevice.properties.channels
            : 1;

    const horizontalResolution = maxVideoResolution * nbrOfChannels;

    const pixelDensity = parentDevice.properties.camera?.filter.pixelDensity ?? 0;

    return horizontalResolution / (horizontalFov * pixelDensity);
};
