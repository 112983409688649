import { ServiceLocator } from 'app/ioc';
import { AppStore } from '../AppStore';
import type { IAction } from '../IAction';

let appStore: AppStore | null = null;

/**
 * Automatically dispatches the action returned from the decorated method and then
 * returns the action to the caller.
 */
export function ActionCreator(): MethodDecorator {
    return (_target: any, key: string | symbol, descriptor: TypedPropertyDescriptor<any>) => {
        return {
            configurable: true,
            get(this: any): any {
                // Bind the this-context to the instance of the class the ActionCreator
                // resides in and then wrap the bound function in a dispatcher call.
                const bound: any = descriptor.value.bind(this);
                const dispatcherWrappedFunction = (...args: any[]) => triggerAction(bound, ...args);

                // Cache the wrapped function onto the class to minimize the times we execute
                // the wrapping code.
                Object.defineProperty(this, key, {
                    value: dispatcherWrappedFunction,
                    configurable: true,
                    writable: true,
                });

                return dispatcherWrappedFunction;
            },
        };
    };
}

function triggerAction(
    actionCreator: (...bindings: any[]) => IAction<any>,
    ...args: any[]
): IAction<any> {
    // Since actions can be triggered insanely often we want to avoid the lookup
    // required to get the store instance.
    if (!appStore) {
        appStore = ServiceLocator.get(AppStore);
    }

    const result = actionCreator(...args);
    appStore.Store.dispatch(result);

    return result;
}
