import * as React from 'react';
import { Box, Wrapper } from '../../containers';
import { NoPrint } from '../../functional';
import { SegmentedControl } from '../../ui/segmentedControl';
import { Border } from '../../style';
import { Button } from '../button';
import type { Colors } from 'app/styles';
import type { IAutoTestable } from '../../ui-test';

export interface ITabbedPanel extends IAutoTestable {
    /**
     * The active tab Id
     */
    activeTab: string;
    /**
     * The tabs to show
     */
    tabs: ITab[];
    /**
     * Actions are rendered to the right of the tabbed panel
     */
    actions?: ITabAction[];
    /**
     * Background color of the panel
     */
    background?: Colors;
    /**
     * The color of the active tab
     */
    activeTabColor?: Colors;
    /**
     * Set to true if hiding the grey thin border beneath the tab control
     */
    noBorder?: boolean;
    /**
     * Wrap tabs.
     */
    wrap?: boolean;
    /**
     * called on tab change
     */
    onActiveTabChange(tabId: string): void;
}

export interface ITabAction {
    text: string;
    onClick(): void;
}

export interface ITab<T = string> {
    /**
     * The id of the tab
     */
    id: T;
    /**
     * The title of the tab
     */
    title: string;
    /**
     * Whether the tab should be hidden
     */
    hidden?: boolean;
    /**
     * The content of the tab
     */
    content?: React.ReactNode;
    /**
     * The automation test id of the tab
     */
    tabTestId?: string;
}

export class TabbedPanel extends React.Component<ITabbedPanel> {
    public render() {
        const tabs = this.props.tabs.filter(({ hidden }) => !hidden);
        const tabHeaders = tabs.map(({ id, title, tabTestId }) => ({
            text: title,
            id,
            tabTestId,
        }));

        const tabContent = tabs.map(
            ({ id, content, tabTestId }) =>
                this.props.activeTab === id &&
                content && (
                    <Box
                        testId={`panel_tab_content_${tabTestId}`}
                        height="100%"
                        key={id}
                        display="block"
                        overflowX={'hidden'}
                        overflowY={'auto'}
                        padding="base"
                        width="100%"
                        position="relative"
                    >
                        {content}
                    </Box>
                ),
        );

        return (
            <Wrapper testId="panel_device_details">
                <Border color="grey3" bottomWidth={this.props.noBorder ? 0 : 1}>
                    <NoPrint>
                        <Box justifyContent="between" color={this.props.background}>
                            <SegmentedControl
                                wrap={this.props.wrap}
                                items={tabHeaders}
                                selectedId={this.props.activeTab}
                                activeTabColor={this.props.activeTabColor}
                                onChange={this.props.onActiveTabChange}
                            />
                            {this.props.actions && (
                                <Box alignItems="center">
                                    {this.props.actions.map(this.renderAction)}
                                </Box>
                            )}
                        </Box>
                    </NoPrint>
                </Border>
                {tabContent}
            </Wrapper>
        );
    }

    private renderAction = ({ onClick, text }: ITabAction) => {
        return (
            <Button testId="tab_btn_close_device_details_panel" text onClick={onClick} key={text}>
                {text}
            </Button>
        );
    };
}
