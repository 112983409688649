import * as React from 'react';
import { Text, Clickable, DropDownMenuItem, Icon, IconButton, Stack } from 'app/components';
import type { CustomItemCategory, IUserCustomItem, Id } from 'app/core/persistence';
import { ColorsEnum } from 'app/styles';
import type { ICustomItemDisplayCat } from '../models';
import { getCategoryName } from '../services';

interface IUserCustomItemDropDownItemProps {
    item: ICustomItemDisplayCat;
    onAdd(userCustomItem: IUserCustomItem): void;
    onDelete(id: Id): void;
}

export const UserCustomItemDropDownItem: React.FC<IUserCustomItemDropDownItemProps> = ({
    item,
    onAdd,
    onDelete,
}) => (
    <Clickable onClick={() => onAdd(item)}>
        <DropDownMenuItem>
            <Stack justifyContent="between">
                <Stack spacing="half">
                    <Icon icon="add" size="sm" />
                    <Text whiteSpace="nowrap">
                        <span style={{ color: ColorsEnum.grey9 }}>
                            {[item.name, item.vendor].filter((property) => !!property).join(', ')}
                            {/* Add extra comma between name/vendor and part number */}
                            {(!!item.name || !!item.vendor) && ', '}
                        </span>
                        {item.partNumber && (
                            <span style={{ color: ColorsEnum.warmGrey6 }}>
                                {item.partNumber}
                                {item.displayCategory
                                    ? ` (${getCategoryName(item.category as CustomItemCategory)})`
                                    : ''}
                            </span>
                        )}
                    </Text>
                </Stack>
                <IconButton
                    icon="close_circle_outline"
                    color="red"
                    size="ms"
                    onClick={(event: React.SyntheticEvent) => {
                        event.stopPropagation();
                        onDelete(item._id);
                    }}
                />
            </Stack>
        </DropDownMenuItem>
    </Clickable>
);
