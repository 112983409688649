import { isDefined } from 'axis-webtools-util';
import { PiaItemState } from 'app/core/pia';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { createSelector } from 'reselect';
import {
    getSelectedDeviceId,
    getSelectedDeviceIdPiaItem,
    getSelectedDevicePiaLenses,
    getSelectedDeviceSelectedLenses,
} from './getSelectedDevice';
import { getSelectedMountAccessoriesForSelectedItem } from './getMountAccessories';
import { getPiaItemsRecord } from 'app/modules/common';
import { getSelectedMountPIAIdsForSelectedItem } from './getMounts';
import { uniq } from 'lodash-es';

const getAllAccessoryNotToBeFollowedBy = createSelector(
    [getPiaItemsRecord, getSelectedDeviceIdPiaItem],
    (piaItems, selectedPiaItem) => {
        const allAccessoryNotToBeFollowedBy = (selectedPiaItem?.relations ?? [])
            // get all compatible relations that have notToBeFollowedBy components
            .filter(({ relationType }) => relationType === 'compatible')
            .filter(({ relationProperties }) => relationProperties?.notToBeFollowedBy)
            // map to remove any notToBeFollowedBy components that aren't EXTERNALLY_ANNOUNCED
            .map((relation) => ({
                ...relation,
                relationProperties: {
                    ...relation.relationProperties,
                    notToBeFollowedBy: (relation.relationProperties.notToBeFollowedBy ?? []).filter(
                        (notToBeFollowedBy) =>
                            notToBeFollowedBy.every(
                                (piaId) =>
                                    piaItems[piaId]?.state === PiaItemState.EXTERNALLY_ANNOUNCED,
                            ),
                    ),
                },
            }));

        return allAccessoryNotToBeFollowedBy;
    },
);

export const getSelectedMountsAccessoriesAndLenses = createSelector(
    [
        getSelectedDeviceId,
        getPiaItemsRecord,
        getSelectedDeviceSelectedLenses,
        getSelectedMountAccessoriesForSelectedItem,
        getSelectedMountPIAIdsForSelectedItem,
    ],

    (
        selectedDeviceId,
        piaItems,
        selectedLenses,
        selectedMountAccessories,
        selectedMountPIAIds,
    ): IPiaItem[] => {
        if (!selectedDeviceId) {
            return [];
        }

        const accessories: IPiaItem[] = Object.values(selectedMountAccessories).map(
            ({ productId }) => piaItems[productId],
        );
        const mounts = selectedMountPIAIds.map((id) => piaItems[id]);
        return accessories.concat(mounts, selectedLenses);
    },
);

export const getSelectedMountsAccessoriesAndLensIds = createSelector(
    [getSelectedMountsAccessoriesAndLenses],
    (selectedMountsAccessoriesAndLens) =>
        selectedMountsAccessoriesAndLens.map((accessory) => accessory.id),
);

export const getSelectedMountsAndAccessoriesIds = createSelector(
    [getSelectedMountAccessoriesForSelectedItem, getSelectedMountPIAIdsForSelectedItem],
    (selectedAccessories, selectedMountPIAIds) => {
        const accessories = Object.keys(selectedAccessories).map((acc) => parseInt(acc));
        return uniq([...accessories, ...selectedMountPIAIds]);
    },
);

export const getNotToBeFollowedByAccessories = createSelector(
    [getAllAccessoryNotToBeFollowedBy, getPiaItemsRecord],
    (allAccessoryNotToBeFollowedBy, piaItems): Record<PiaId, IPiaItem[][]> => {
        // get the notToBeFollowedBy relations
        const notToBeFollowedByOfSelectedAccessories = allAccessoryNotToBeFollowedBy
            .map((accessory) =>
                allAccessoryNotToBeFollowedBy.find(
                    (notToBeFollowedBy) => notToBeFollowedBy.id === accessory.id,
                ),
            )
            .filter(isDefined);

        return notToBeFollowedByOfSelectedAccessories.reduce(
            (result, relation) => {
                result[relation.id] = relation.relationProperties.notToBeFollowedBy.map((ids) =>
                    ids.map((id) => piaItems[id]),
                );
                return result;
            },
            {} as Record<PiaId, IPiaItem[][]>,
        );
    },
);

/** Gets all not to be followed by ids for selected device. */
export const getSelectedNotToBeFollowedBy = createSelector(
    [getNotToBeFollowedByAccessories, getSelectedMountsAccessoriesAndLensIds],
    (notToBeFollowedByAccessories, selectedMountsAccessoriesAndLensIds): PiaId[] => {
        const filteredRecord = { ...notToBeFollowedByAccessories };
        // Remove accessories that aren't selected from filteredRecord
        Object.keys(notToBeFollowedByAccessories).forEach((acc) => {
            if (!selectedMountsAccessoriesAndLensIds.includes(Number(acc))) {
                delete filteredRecord[Number(acc)];
            }
        });
        return Object.values(filteredRecord)
            .flat(2)
            .map((piaItem: IPiaItem) => piaItem.id);
    },
);

export const getNotToBeFollowedByLenses = createSelector(
    [
        getNotToBeFollowedByAccessories,
        getPiaItemsRecord,
        getSelectedMountsAndAccessoriesIds,
        getSelectedDevicePiaLenses,
    ],
    (notToBeFollowedByAccessories, piaItems, selectedAccessoriesAndMountIds, lenses) => {
        const result = {} as Record<PiaId, IPiaItem[]>;

        // For all lenses for selected device
        lenses.forEach((lens) => {
            selectedAccessoriesAndMountIds.forEach((accessoryProductId) => {
                const productVariants = notToBeFollowedByAccessories[accessoryProductId];
                const ntbfbLens = notToBeFollowedByAccessories[lens.id];

                const foundPiaItems: Array<IPiaItem> = [];
                // add notToBeFollowedBy accessories to foundPiaItems for the accessory
                productVariants &&
                    productVariants.forEach((productVariant) => {
                        if (
                            productVariant.find(({ id }) => id === lens.id) &&
                            //* Exclude duplicates
                            !foundPiaItems.some(
                                (item) => item.id === piaItems[accessoryProductId].id,
                            )
                        ) {
                            foundPiaItems.push(piaItems[accessoryProductId]);
                        }
                    });

                // add notToBeFollowedBy accessories to foundPiaItems for the lens
                ntbfbLens &&
                    ntbfbLens.forEach((accessories) => {
                        if (
                            accessories.find(({ id }) => id === accessoryProductId) &&
                            //* Exclude duplicates
                            !foundPiaItems.some(
                                (item) => item.id === piaItems[accessoryProductId].id,
                            )
                        ) {
                            foundPiaItems.push(piaItems[accessoryProductId]);
                        }
                    });

                if (result[lens.id]) {
                    // Merge with existing
                    result[lens.id] = [...result[lens.id], ...foundPiaItems];
                } else {
                    // Create new
                    result[lens.id] = foundPiaItems;
                }
            });
        });
        return result;
    },
);
