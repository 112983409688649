import { injectable } from 'inversify';
import type { IPiaAlerter } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { IItemEntity, IPersistence, IAlerterItemEntity } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { IExportablePersistedEntity, IExportedAlerter } from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import { toShareModelNames } from '../../mapToShareItemNames';

@injectable()
export class AlertersExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaAlerter>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedAlerters = (
        items: IExportablePersistedEntity[],
    ): IExportedAlerter[] => {
        const allAlerters = items.filter(this.isAlertersItemEntity);

        const mappedAlerters = allAlerters.map(this.mapAlertersToExportedAlerters);
        return mappedAlerters.filter(isDefined);
    };

    public mapAlertersToExportedAlerters = (
        alerterItem: IExportablePersistedEntity,
    ): IExportedAlerter | null => {
        if (!alerterItem.productId) {
            return null;
        }

        const alerterPiaDevice = this.getPiaDevice(alerterItem.productId);

        return {
            ...this.mapItemToExportedItemBase(alerterItem),
            ...toShareModelNames(alerterPiaDevice.name),
            piaId: alerterItem.productId,
        };
    };

    private isAlertersItemEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<IAlerterItemEntity> {
        return deviceTypeCheckers.isAlerter(item);
    }
}
