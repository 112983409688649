import type { Id } from 'app/core/persistence';
import { reducerBuilder } from 'app/store';
import { accessorySelectorActions as Actions } from './actions';
import type { IAccessorySelectorState, IDeviceSearchFilter } from './models';
import type { PiaId } from 'app/core/pia';

const initialState: IAccessorySelectorState = {
    deviceId: null,
    deviceFilter: {
        searchText: '',
        environments: [],
        includeDiscontinued: false,
        showCameras: true,
        showModularSeries: false,
        showEncoders: false,
        showSpeakers: false,
        showIntercoms: false,
        showWearables: false,
        showAccessControls: false,
        showRecorders: false,
        showOthers: false,
    },
    accessoryFilter: {
        searchText: '',
    },
    showDeviceSelection: false,
    devicesPerEnvironment: {},
};

export const accessorySelectorReducer = reducerBuilder<IAccessorySelectorState>()
    .setInitialState(initialState)
    .onAction<{ environmentId: PiaId; deviceIds: PiaId[] }>(
        Actions.SetEnvironmentDevices,
        (state, action) => {
            const devicesPerEnvironment = { ...state.devicesPerEnvironment };
            devicesPerEnvironment[action.payload.environmentId] = action.payload.deviceIds;
            return {
                ...state,
                devicesPerEnvironment,
            };
        },
    )
    .onAction<Id>(Actions.AddItemId, (state, action) => {
        return {
            ...state,
            deviceId: action.payload,
        };
    })
    .onAsyncAction(Actions.Reset, (handler) => {
        handler.onPending((state) => ({
            ...state,
            deviceId: null,
        }));
        handler.onFulfilled((state) => ({
            ...state,
            deviceId: null,
        }));
    })
    .onAction<string>(Actions.DeviceSearchText, (state, action) => {
        return {
            ...state,
            deviceFilter: {
                ...state.deviceFilter,
                searchText: action.payload,
            },
        };
    })
    .onAction<IDeviceSearchFilter>(Actions.DeviceFilter, (state, action) => {
        return {
            ...state,
            deviceFilter: action.payload,
        };
    })
    .onAction<string>(Actions.AccessorySearchText, (state, action) => {
        return {
            ...state,
            accessoryFilter: {
                ...state.deviceFilter,
                searchText: action.payload,
            },
        };
    })
    .create();
