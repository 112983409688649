import { Box, Icon } from 'app/components';
import type { IExtendableComponent, IAutoTestable } from 'app/components';
import * as React from 'react';
import { ApplicationWarningContent } from './ApplicationWarningContent';

interface IContent {
    /**
     * The heading of content part
     * Will render in the color set.
     */
    heading?: string;
    /**
     * The body text of content part.
     */
    text?: string;
}

interface IApplicationWarning extends IExtendableComponent, IAutoTestable {
    /**
     * array of content. Each element consists of a heading and corresponding text
     */
    content: IContent[];
    /**
     * Determines the icon to show on the alert
     */
    level: 'info' | 'warning' | 'error' | 'help';
    /**
     * The color of the icon and heading
     */
    color: 'blue' | 'yellow' | 'red';
}

/**
 * A panel containing a warning icon and sections of content (header and text)
 */
export const ApplicationWarning: React.FunctionComponent<IApplicationWarning> = ({
    content,
    level,
    color,
    ...extendedProps
}) => {
    return content.length > 0 ? (
        <Box {...extendedProps} color="grey2" padding="panel" testId={extendedProps.testId}>
            <Box spacing="panel" alignItems="center">
                <Icon color={color} icon={level} opaque size="xl" />
                <Box direction="column" spacing="half">
                    {content.map((item, index) => (
                        <ApplicationWarningContent
                            key={index}
                            heading={item.heading}
                            text={item.text}
                            color={color}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    ) : null;
};

ApplicationWarning.displayName = 'ApplicationWarning';
