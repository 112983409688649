import { injectable } from 'inversify';
import type { Id, IPersistence, IDoorStationItemEntity } from 'app/core/persistence';
import type { IPiaDoorStation } from 'app/core/pia';
import type { IInstallationReportDoorStationDevice } from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum } from 'app/modules/common';
import { InstallationDetailsService } from '../InstallationDetails.service';

@injectable()
export class DoorStationDeviceService {
    constructor(
        private baseDeviceService: BaseDeviceService,
        private installationDetailsService: InstallationDetailsService,
    ) {}

    public async mapDevice(
        item: IPersistence<IDoorStationItemEntity>,
        piaItem: IPiaDoorStation,
        projectId: Id,
    ): Promise<IInstallationReportDoorStationDevice> {
        const cameraProps = item.properties.camera;

        return {
            category: CategoryEnum.DoorStation,
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            ...(await this.installationDetailsService.getCameraSettings(
                cameraProps,
                piaItem,
                projectId,
            )),
        };
    }
}
