import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { ServiceLocator } from 'app/ioc';
import { AccessoryPersistenceService } from '../../services';
import type { ISelectedMountWithAccessories } from '../../selectors';
import {
    getMountWithAccessoriesIncludingAllMounts,
    getSelectedDeviceId,
    hasUnmatchedAccessoryRequirement,
    getAllRequiredAccessories,
} from '../../selectors';
import { AccessoriesListItem } from './AccessoriesListItem.container';
import {
    Border,
    Box,
    Clickable,
    DropDown,
    DropDownMenuItem,
    IconText,
    PiaImage,
    SelectableList,
    Stack,
    Text,
} from 'app/components';
import { t } from 'app/translate';
import {
    PiaItemMsrp,
    getHasRequiredComponents,
    getRequiredComponentsForItem,
} from 'app/modules/common';

export const DeviceAccessoriesList: React.FC = () => {
    const selectedDeviceId = useSelector<IStoreState, Id | undefined>((state) =>
        getSelectedDeviceId(state),
    );

    const deviceWithAccessories = useSelector<
        IStoreState,
        ISelectedMountWithAccessories | undefined
    >((state) =>
        selectedDeviceId
            ? getMountWithAccessoriesIncludingAllMounts(state, selectedDeviceId)
            : undefined,
    );

    const hasAccessories = deviceWithAccessories && deviceWithAccessories?.accessories.length > 0;
    const hasMissingAccessories = useSelector<IStoreState>(hasUnmatchedAccessoryRequirement);
    const hasMissingRequiredComponent = useSelector<IStoreState, boolean>(
        (state) => !getHasRequiredComponents(state, selectedDeviceId),
    );
    const needsRequiredAccessories = hasMissingAccessories || hasMissingRequiredComponent;
    const requiredAccessoryAccessories = useSelector<IStoreState, IPiaItem[][]>(
        getAllRequiredAccessories,
    );
    const requiredComponentsAccessories = useSelector<IStoreState, IPiaItem[][]>((state) =>
        getRequiredComponentsForItem(state, selectedDeviceId),
    );
    const requiredAccessories = requiredAccessoryAccessories.concat(requiredComponentsAccessories);

    const dropDownItems = requiredAccessories.map((accessoryCombination, i) => (
        <Clickable
            key={i}
            onClick={() => {
                if (selectedDeviceId) {
                    const accessoryPersistenceService = ServiceLocator.get(
                        AccessoryPersistenceService,
                    );
                    accessoryCombination.forEach(({ id }) =>
                        accessoryPersistenceService.setItem(selectedDeviceId, id, 'accessory'),
                    );
                }
            }}
        >
            <DropDownMenuItem>
                {accessoryCombination.map((accessory) => (
                    <Box key={accessory.id} justifyContent="between" alignItems="center">
                        <Box spacing="base" alignItems="center">
                            <PiaImage imageSize="md" piaId={accessory.id} icon="category" />
                            <Text>{accessory.name}</Text>
                        </Box>
                        <PiaItemMsrp piaId={accessory.id} includeMultipacks />
                    </Box>
                ))}
            </DropDownMenuItem>
        </Clickable>
    ));

    return (
        <Stack vertical>
            <Stack>
                <Text style="heading">{t.accessories}</Text>
                {needsRequiredAccessories ? (
                    <DropDown
                        trigger={
                            <Border leftWidth={1} color="grey4">
                                <Box paddingLeft="base">
                                    <IconText
                                        icon="warning_small"
                                        color="yellow7"
                                        spacing="half"
                                        iconProps={{ size: 'xs' }}
                                        textProps={{ style: 'heading' }}
                                        testId="warning_required_accessory"
                                    >
                                        {t.addRequiredAccessories}
                                    </IconText>
                                </Box>
                            </Border>
                        }
                        contents={dropDownItems}
                        minWidth={500}
                        openInPortal
                    />
                ) : null}
            </Stack>
            <SelectableList
                notScrollable
                noBackground
                notStickyHeader
                noHeaderBackground
                header={[
                    <Text color="grey6" whiteSpace="nowrap">
                        {t.name}
                    </Text>,
                    '',
                    hasAccessories ? (
                        <Text color="grey6" whiteSpace="nowrap">
                            {t.quantity}
                        </Text>
                    ) : (
                        ''
                    ),
                    '',
                ]}
                emptyIcon="category"
                emptyText={t.noAccessoriesSelectedForDevice}
                verticallyCenterContents
            >
                {deviceWithAccessories?.accessories.map((accessory) => (
                    <AccessoriesListItem
                        key={accessory.id}
                        itemId={accessory.id}
                        hideParent
                        isChildAccessory
                        parentQuantity={deviceWithAccessories.quantity}
                        showAccessoryButton
                    />
                )) ?? []}
            </SelectableList>
        </Stack>
    );
};
