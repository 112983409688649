import { createSelector } from 'reselect';
import type { PolyLine } from 'app/core/persistence';
import {
    getCurrentProjectFloorPlans,
    transformBlocker,
    transformFloorPlan,
} from 'app/modules/common';
import {
    getDerotationTransform,
    getDerotationAngle,
    getFloorPlansArrayWithPendingGeolocation,
} from './geolocations';
import { getBlockersForCurrentMap } from './blockers';
import { isDefined } from 'axis-webtools-util';

/**
 * Get a record of all maps in the current project, derotated by the currently
 * selected geolocalized floor plan's rotation. If no floor plan is selected or if it
 * isn't geolocalized, the maps are not derotated.
 */
export const getDerotatedMaps = createSelector(
    [getCurrentProjectFloorPlans, getDerotationTransform, getDerotationAngle],
    (floorPlans, transform, rotation) =>
        Object.keys(floorPlans).reduce(
            (acc, key) => {
                const floorPlan = floorPlans[key];
                if (floorPlan) {
                    acc[key] = transformFloorPlan(transform, rotation, floorPlan);
                }
                return acc;
            },
            {} as ReturnType<typeof getCurrentProjectFloorPlans>,
        ),
);

/**
 * Get a list of all maps in the current project, derotated by the currently
 * selected geolocalized floor plan's rotation. If no floor plan is selected or if it
 * isn't geolocalized, the maps are not derotated.
 */
export const getDerotatedMapsArray = createSelector([getDerotatedMaps], (floorPlans) =>
    Object.values(floorPlans).filter(isDefined),
);

/**
 * Get a list of all maps in the cuurent project, including there pending geolocations
 * and derotated by the currently selected geolocalized floor plan's rotation. If no
 * floor plan is selected or if it isn't geolocalized, the maps are not derotated.
 */
export const getDerotatedMapsArrayWithPendingGeolocation = createSelector(
    [getFloorPlansArrayWithPendingGeolocation, getDerotationTransform, getDerotationAngle],
    (floorPlans, transform, rotation) =>
        floorPlans.map((floorPlan) => transformFloorPlan(transform, rotation, floorPlan)),
);

/**
 * Returns all blockers relevant for the current floor plan as an array
 * If the current floor plan is geolocated, the global blockers are returned
 * If the current floor plan is not geolocated, the floor plan blockers are returned
 * @returns {Array} Array of blockers
 */
export const getDerotatedBlockers = createSelector(
    [getBlockersForCurrentMap, getDerotationTransform],
    (blockers, transform) => {
        return blockers.map((blocker) => ({
            ...blocker,
            latLngs: transformBlocker(transform, blocker.latLngs),
        }));
    },
);

/**
 * Returns all blocker polylines relevant for the current floor plan as an array
 */
export const getDerotatedBlockerPolylines = createSelector(
    [getDerotatedBlockers],
    (blockers): PolyLine[] => {
        return blockers.map((blocker) => blocker.latLngs).filter(isDefined);
    },
);
