import * as React from 'react';
import { Box, ReadDirection, Text } from 'app/components';
import { ProjectService } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { RecordingSelectorService } from '../../services';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    getGenetecRecommendations,
    getIsFetchingGenetecRecommendations,
    getSelectedGenetecRecommendationIndex,
    isCurrentSolutionEmpty,
} from '../../selectors';
import { getSelectedRecordingSolutionType } from 'app/modules/common';
import { ModalService } from 'app/modal';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useWindowSize } from 'app/hooks';
import { TABLET_WINDOW_SIZE } from 'app/modules/accessorySelector/constants';
import { eventTracking } from 'app/core/tracking';
import { SolutionItemCard } from '../SolutionItemCard';

interface IGenetecRecommendationItemsProps {
    maxWidth?: number;
}

export const GenetecRecommendationItems: React.FC<IGenetecRecommendationItemsProps> = () => {
    const recordingSelectorService = useService(RecordingSelectorService);
    const modalService = useService(ModalService);
    const projectService = useService(ProjectService);

    const selectedRecordingSolutionType = useSelector(getSelectedRecordingSolutionType);
    const recommendations = useSelector(getGenetecRecommendations);
    const isFetching = useSelector(getIsFetchingGenetecRecommendations);
    const isSolutionEmpty = useSelector(isCurrentSolutionEmpty);
    const selectedRecommendationIndex = useSelector(getSelectedGenetecRecommendationIndex);
    const windowSize = useWindowSize();
    const isTabletSize = windowSize.width <= TABLET_WINDOW_SIZE;

    const selectRecommendation = async (index: number) => {
        const shouldAskToClearSolution =
            !isSolutionEmpty && selectedRecordingSolutionType !== 'Genetec';
        if (shouldAskToClearSolution) {
            const confirm = await modalService.createConfirmDialog({
                header: t.replaceRecordingSolutionHeading,
                body: t.replaceRecordingSolutionMessage,
                confirmButtonText: t.proceed,
                cancelButtonText: t.cancel,
            })();

            if (!confirm) {
                return;
            }
        }

        if (!isSolutionEmpty) {
            await recordingSelectorService.clearCurrentSolution(
                selectedRecordingSolutionType,
                'Genetec',
            );
        }

        if (selectedRecordingSolutionType !== 'Genetec') {
            projectService.updateCurrentProject({
                recordingSolutionType: 'Genetec',
                selectedCameraStationCenterType: 'NoCenter',
            });
        }

        if (recommendations) {
            await recordingSelectorService.addPartnerSystemComponents(
                recommendations[index].components,
            );
        }
        eventTracking.logUserEvent('System Components', 'Selected Genetec solution');
    };

    return (
        <Box
            spacing="base"
            maxWidth="100%"
            justifyContent={isTabletSize ? 'center' : undefined}
            wrap
        >
            {isFetching && (
                <Box width="100%">
                    <LoadingSkeleton />
                </Box>
            )}

            {!isFetching &&
                recommendations.map((rec, index) => (
                    <SolutionItemCard
                        key={index}
                        name={rec.name}
                        description={
                            <ReadDirection direction="ltr">
                                <Text inline style="small" color="grey6">
                                    {rec.description ?? ''}
                                </Text>
                            </ReadDirection>
                        }
                        onSelect={() => selectRecommendation(index)}
                        isSelected={index === selectedRecommendationIndex}
                    >
                        {rec.components}
                    </SolutionItemCard>
                ))}
        </Box>
    );
};

GenetecRecommendationItems.displayName = 'GenetecRecommendations';
