import type { PiaId, IPiaRelationReference, IPiaItem } from 'app/core/pia';
import { filterProducts, PiaItemState } from 'app/core/pia';
import { isDefined } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import { createProductAllowlistFilter } from '../../piaDevices/selectors/createProductAllowlistFilter';
import {
    getPiaItemsRecord,
    getProductAllowlist,
    getUseProductAllowlist,
} from '../../piaDevices/selectors/getPiaDevices';
import { getCurrentProjectItems, getCurrentProjectRegions } from '../../project/selectors';
import { getPiaIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';
import type { IPartnerConfigAllowlist } from 'app/core/persistence';
import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';

export interface IRelatedPiaItem extends IPiaItem {
    isIncluded: boolean;
    isRecommended: boolean;
}

const getSelectedDeviceId = (store: IStoreState) =>
    store.projectDevices.panelItem?.itemId ?? store.accessorySelector.deviceId;

/**
 * Gets pia items that are compatible, included, and recommended to the specified PiaId.
 * Returned items are externally announced and filtered on region and allowlist.
 */
export const getRelatedPiaItemsForItem = createCachedSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectRegions,
        getUseProductAllowlist,
        getProductAllowlist,
        getPiaIdFromProps,
    ],
    (piaItems, regions, useAllowlist, allowlist, productId) =>
        getRelatedItems(piaItems, regions, useAllowlist, allowlist, productId),
)(toCacheKey);

/**
 * Gets pia items that are compatible, included, and recommended to the selected item in device details.
 * Returned items are externally announced and filtered on region and allowlist.
 */
export const getRelatedPiaItemsForSelectedDevice = createSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectRegions,
        getUseProductAllowlist,
        getProductAllowlist,
        getSelectedDeviceId,
        getCurrentProjectItems,
    ],
    (piaItems, regions, useAllowlist, allowlist, selectedId, itemsRecord) => {
        const productId = selectedId ? itemsRecord[selectedId]?.productId : undefined;
        return getRelatedItems(piaItems, regions, useAllowlist, allowlist, productId);
    },
);

const getRelatedItems = (
    piaItems: Record<number, IPiaItem>,
    regions: string[],
    useAllowlist: boolean,
    allowlist: IPartnerConfigAllowlist | undefined,
    productId: PiaId | null | undefined,
): IRelatedPiaItem[] => {
    if (productId === undefined || productId === null) {
        return [];
    }
    const piaItem = piaItems[productId];

    const relations = piaItem.relations
        .filter(
            (relation) =>
                relation.relationType === 'includes' ||
                relation.relationType === 'recommends' ||
                relation.relationType === 'compatible',
        )
        .reduce(
            (uniqRelations, relation) => {
                const existingRelation = uniqRelations[relation.id];
                if (existingRelation && existingRelation.relationType === 'includes') {
                    // Some accessories exists in both 'includes' and 'recommends' types, prioritize the ones that are of 'includes' type
                    return uniqRelations;
                }

                uniqRelations[relation.id] = relation;
                return uniqRelations;
            },
            {} as Record<PiaId, IPiaRelationReference>,
        );
    return Object.values(relations)
        .map(
            (relation) =>
                ({
                    ...piaItems[relation.id],
                    isIncluded: relation.relationType === 'includes',
                    isRecommended: relation.relationType === 'recommends',
                }) as IRelatedPiaItem,
        )
        .filter(isDefined)
        .filter((item) => createProductAllowlistFilter(allowlist, useAllowlist)(item.id))
        .filter(filterProducts.byStates([PiaItemState.EXTERNALLY_ANNOUNCED]))
        .filter(filterProducts.byRegions(regions))
        .filter(filterProducts.byExternallyHidden());
};
