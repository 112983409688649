import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IPersistenceRepository } from '../../repositories';
import { DeletableEntity } from '../models';

@injectable()
export class Migration47to48 extends MigrationProviderBase {
    public from: number = 47;
    public to: number = 48;

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            if (entity.type !== 'floorPlan') {
                return entity;
            }

            const projectId = entity.path[0];

            if (entity.isDefault) {
                // Get all entities of the project
                const descendants = await repository.getDescendants(projectId);

                // Check if there are any other default floor plans in the project
                const alreadyHasDefaultFloorPlan = descendants.some((descendant: any) => {
                    return (
                        descendant.type === 'floorPlan' &&
                        descendant.isDefault &&
                        descendant._id !== entity._id
                    );
                });

                if (alreadyHasDefaultFloorPlan) {
                    // Remove the floor plan entity by returning a deletable entity
                    return new DeletableEntity(entity);
                }
            }

            return entity;
        };
    }
}
