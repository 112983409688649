import type { IItemEntity } from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemSoftwareCategory } from 'app/core/pia';
import { CategoryEnum } from './Category.enum';

export function getCategory(item: IItemEntity, piaItem?: IPiaItem): CategoryEnum {
    if (piaItem?.category === 'doorstations' || (item.properties.camera && item.properties.pac)) {
        return CategoryEnum.DoorStation;
    } else if (item.properties.camera) {
        return CategoryEnum.Camera;
    } else if (item.properties.encoder) {
        return CategoryEnum.Encoder;
    } else if (item.properties.analogCamera) {
        return CategoryEnum.AnalogCamera;
    } else if (item.properties.mainUnit || item.properties.sensorUnit) {
        return CategoryEnum.FSeries;
    } else if (item.properties.speaker) {
        return CategoryEnum.Speaker;
    } else if (item.properties.decoder) {
        return CategoryEnum.Decoder;
    } else if (item.properties.pac) {
        return CategoryEnum.Pac;
    } else if (item.properties.radarDetector) {
        return CategoryEnum.RadarDetector;
    } else if (
        item.properties.accessory ||
        item.properties.systemAccessory ||
        item.properties.lens
    ) {
        return CategoryEnum.Accessory;
    } else if (item.properties.systemComponent || item.properties.partnerSystemComponent) {
        if (piaItem?.category === PiaItemSoftwareCategory.VMS) {
            return CategoryEnum.Software;
        } else {
            return CategoryEnum.RecordingAndNetwork;
        }
    } else if (item.properties.application) {
        return CategoryEnum.Software;
    } else if (item.properties.peopleCounter) {
        return CategoryEnum.PeopleCounters;
    } else if (deviceTypeCheckers.isWearable(item) || deviceTypeCheckers.isCameraExtension(item)) {
        return CategoryEnum.Wearables;
    } else if (deviceTypeCheckers.isDoorController(item)) {
        return CategoryEnum.DoorControllers;
    } else if (deviceTypeCheckers.isAlerter(item)) {
        return CategoryEnum.Alerters;
    } else if (deviceTypeCheckers.isConnectivityDevice(item)) {
        return CategoryEnum.ConnectivityDevice;
    } else if (deviceTypeCheckers.isPagingConsole(item)) {
        return CategoryEnum.PagingConsole;
    }
    return CategoryEnum.Miscellaneous;
}
