import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';

// state.router is undefined in unit-tests
export const getRouterPathname = (state: IStoreState) => state.app.routerLocation.pathname;

export const getIsStandalone = createSelector(
    [getRouterPathname],
    (routerPathname) =>
        routerPathname.includes('/accessory-selector') ||
        routerPathname.includes('/technology-partner-finder'),
);
