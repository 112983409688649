import type { IPiaDevice } from 'app/core/pia';
import { getCurrentProjectItems, getPiaItemsRecord } from 'app/modules/common';
import { createSelector } from 'reselect';
import { getParentDeviceId } from './getProductItem';

export const getSelectedParentPiaDevice = createSelector(
    [getParentDeviceId, getCurrentProjectItems, getPiaItemsRecord],
    (parentId, currentProjectItems, piaItemsRecord) => {
        if (!parentId) {
            return undefined;
        }
        const itemEntity = currentProjectItems[parentId];
        if (!itemEntity || itemEntity.productId === null) {
            return undefined;
        }
        return piaItemsRecord[itemEntity.productId] as IPiaDevice;
    },
);
