import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds archived property to project.
 */
@injectable()
export class Migration4To5 extends MigrationProviderBase {
    public from: number = 4;
    public to: number = 5;

    public provideMigration() {
        return (entity: any): Promise<any> => {
            if (entity.type === 'project') {
                entity.archived = false;
            }

            return Promise.resolve(entity);
        };
    }
}
