import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { convertMsrpToProjectCurrency, getTotalMsrpForPiaIds } from '../selectors';
import type { ILocaleCurrencyProps } from './LocaleCurrency.container';
import { LocaleCurrency } from './LocaleCurrency.container';

interface IPiaItemsMsrpOwnProps {
    /**
     * An array of PIA ID:s for which to look up MSRP:s and sum them
     */
    piaIds: number[];
    /**
     * A record where the key is the PIA ID and the value
     * is the quantity of that product. If provided, will multiply
     * the MSRP for that PIA ID with the specified quantity.
     */
    quantities?: Record<number, number>;
}

function mapStateToProps(
    storeState: IStoreState,
    ownProps: IPiaItemsMsrpOwnProps,
): ILocaleCurrencyProps {
    const msrpInPriceListCurrency = getTotalMsrpForPiaIds(storeState, ownProps);

    // Return nothing if there's no MSRP to show
    if (!msrpInPriceListCurrency || msrpInPriceListCurrency.value === 0) {
        return {};
    }

    const msrpInProjectCurrency = convertMsrpToProjectCurrency(storeState, msrpInPriceListCurrency);

    return {
        msrpInPriceListCurrency,
        msrpInProjectCurrency,
    };
}

/**
 * A component that fetches the MSRP of the provided
 * PIA id:s, sums them and displays the result in the appropriate currency
 * and locale.
 */
export const PiaItemsMsrp = connect(mapStateToProps)(LocaleCurrency);

PiaItemsMsrp.displayName = 'ItemMsrp';
