import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    Border,
    Box,
    Button,
    Checkbox,
    Columns,
    EditableText,
    Heading,
    IconButton,
    Stack,
    Text,
} from 'app/components';
import type { IStoreState } from 'app/store';
import type { Id, IPersistence, IScheduleEntity } from 'app/core/persistence';
import { ScheduleService } from 'app/core/persistence';
import {
    getInvertedScheduleFromSelectedSchedule,
    getInvertedScheduleInUse,
    getNextSchedule,
    getPreviousSchedule,
    getSelectedSchedule,
} from './selectors';
import { TimeSeries } from './TimeSeries.container';
import { AppConstants } from 'app/AppConstants';
import { debounce } from 'lodash-es';
import { ServiceLocator } from 'app/ioc';
import { SchedulesActionService } from './services/SchedulesActionService.service';

interface ISchedulesEditorProps {
    onClose(): void;
}

export const SchedulesEditor: React.FC<ISchedulesEditorProps> = ({ onClose }) => {
    const [scheduleService] = React.useState(ServiceLocator.get(ScheduleService));
    const [actionService] = React.useState(ServiceLocator.get(SchedulesActionService));

    const selectedSchedule = useSelector<IStoreState, IPersistence<IScheduleEntity> | undefined>(
        (state) => getSelectedSchedule(state),
    );
    const invertedSchedule = useSelector<IStoreState, IPersistence<IScheduleEntity> | undefined>(
        (state) => getInvertedScheduleFromSelectedSchedule(state),
    );
    const nextSchedule = useSelector<IStoreState, IPersistence<IScheduleEntity> | undefined>(
        (state) => getNextSchedule(state),
    );
    const previousSchedule = useSelector<IStoreState, IPersistence<IScheduleEntity> | undefined>(
        (state) => getPreviousSchedule(state),
    );
    const invertedScheduleInUse = useSelector<IStoreState, boolean>((state) =>
        getInvertedScheduleInUse(state),
    );

    const updateName = (scheduleId: Id) => (name: string) => {
        name.trim() && scheduleService.updateSchedule(scheduleId, name);
    };

    const addNewTimeSerie = () => {
        selectedSchedule && scheduleService.addTimeSerie(selectedSchedule);
    };

    const toggleInvertedSchedule = () => {
        if (invertedSchedule) {
            removeInvertedSchedule();
        } else {
            addInvertedSchedule();
        }
    };
    const addInvertedSchedule = () => {
        selectedSchedule && scheduleService.addInvertedSchedule(selectedSchedule._id);
    };

    const removeInvertedSchedule = () =>
        invertedSchedule && scheduleService.deleteSchedule(invertedSchedule._id);

    const renderInvertedSchedule = () => {
        if (invertedSchedule) {
            return (
                <>
                    <Heading align="center" style="title" width="100%">
                        <EditableText
                            placeholder={t.schedulesNewScheduleName}
                            maxLength={AppConstants.scheduleNameMaxLength}
                            value={invertedSchedule.name}
                            onChange={debounce(updateName(invertedSchedule._id), 800)}
                        />
                    </Heading>

                    <TimeSeries inverted={true} />
                </>
            );
        }
        return null;
    };

    return selectedSchedule ? (
        <Stack vertical fullHeight>
            <Border bottomWidth={1} color="grey3">
                <Box justifyContent="end" padding="base">
                    <Button onClick={onClose} text>
                        {t.close}
                    </Button>
                </Box>
            </Border>
            <Box padding="base" height="100%">
                <Stack vertical alignItems="center">
                    <Columns justify widths={[20, 60, 20]}>
                        <IconButton
                            hidden={!previousSchedule}
                            icon="keyboard_arrow_left"
                            size="md"
                            color="grey6"
                            text={previousSchedule?.name}
                            onClick={() => actionService.editSchedule(previousSchedule!._id)}
                        />
                        <Heading align="center" style="title" width="100%">
                            <EditableText
                                placeholder={t.schedulesNewScheduleName}
                                maxLength={AppConstants.scheduleNameMaxLength}
                                value={selectedSchedule.name}
                                onChange={debounce(updateName(selectedSchedule._id), 800)}
                            />
                        </Heading>
                        <IconButton
                            hidden={!nextSchedule}
                            icon="keyboard_arrow_right"
                            size="md"
                            color="grey6"
                            iconRight
                            text={nextSchedule?.name}
                            onClick={() => actionService.editSchedule(nextSchedule!._id)}
                        />
                    </Columns>
                    <TimeSeries inverted={false} />
                    <Columns justify widths={[45, 10, 45]}>
                        <Stack>
                            <Checkbox
                                selected={invertedSchedule !== undefined}
                                disabled={invertedScheduleInUse}
                                onChange={toggleInvertedSchedule}
                                slider
                            >
                                {t.addInverse}
                            </Checkbox>
                        </Stack>
                        <IconButton icon="add_circle" size="xl" onClick={addNewTimeSerie} />
                    </Columns>

                    {renderInvertedSchedule()}
                </Stack>
            </Box>
        </Stack>
    ) : (
        <Box height="80%" justifyContent="center" alignItems="center">
            <Stack vertical alignItems="center" justifyContent="center">
                <Text style="heading">{t.missingSchedule}</Text>
                <Button primary onClick={onClose}>
                    {t.close}
                </Button>
            </Stack>
        </Box>
    );
};

SchedulesEditor.displayName = 'SchedulesEditor';
