import * as React from 'react';
import { Text, ReportCard, Spacer, Box, NoPrint } from 'app/components';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { t } from 'app/translate';
import { debounce } from 'lodash-es';
import { useService } from 'app/ioc';
import { ProjectService } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { getCurrentProjectInstallationNotes } from 'app/modules/common';

/** Styling for the text shown when printing. */
const textOnPrintStyle = css`
    display: none;
    @media print {
        display: block;
        white-space: pre-wrap;
    }
`;

/** Hides content on print. It's being used to hide entire report card from print when notes are empty. */
const NoPrintStyle = css`
    @media print {
        display: none;
    }
`;

const growWrapStyle = css`
    display: grid;
    width: 100%;

    &&::after {
        /* Needed to prevent jumpy behavior. */
        content: attr(data-replicated-value) ' ';

        /* This is how textarea text behaves */
        white-space: pre-wrap;
        visibility: hidden;
    }
    && > textarea {
        resize: none;
        overflow: hidden;
        width: 100%;
        min-height: 40px;
        outline: none;
        background: ${ColorsEnum.grey1};
    }
    && > textarea,
    &&::after {
        font: inherit;
        padding-bottom: 4px;
        word-break: break-word;

        /* Place on top of each other */
        grid-area: 1 / 1 / 2 / 2;
    }
`;

/** Component with a dynamically expanding/contracting text area for installation report notes.
 *
 *  Displays a static \<Text /> component on print instead of \<TextArea /> since print can't handle overflow: visible.
 */
export const InstallationNotes: React.FC = () => {
    const currentProjectInstallationNotes = useSelector(getCurrentProjectInstallationNotes);
    const [notes, setNotes] = React.useState<string>(currentProjectInstallationNotes);
    const projectService = useService(ProjectService);
    const parentDiv = React.useRef<HTMLDivElement>(null);
    const updateDbNotes = React.useMemo(
        () =>
            debounce(
                (newValue: string) =>
                    projectService.updateCurrentProject({ installationReportNotes: newValue }),
                600,
            ),
        [projectService],
    );

    const updateNotes = (value: string) => {
        setNotes(value);
        updateDbNotes(value);
    };

    // Handle ctr+z/ctrl+y
    React.useEffect(() => {
        setNotes(currentProjectInstallationNotes);
    }, [currentProjectInstallationNotes]);

    // Update height on mount and when notes update
    React.useEffect(() => {
        parentDiv.current?.setAttribute('data-replicated-value', notes);
    }, [notes]);

    return (
        <div className={notes ? undefined : NoPrintStyle}>
            <ReportCard title={t.notes}>
                <div className={textOnPrintStyle}>
                    <Text wordBreak="break-word">{notes}</Text>
                </div>
                <NoPrint>
                    <Box testId="installation-report-notes">
                        <div ref={parentDiv} className={growWrapStyle}>
                            <textarea
                                id="installationReportNotes"
                                value={notes}
                                onChange={(e) => updateNotes(e.target.value)}
                                placeholder={t.addNotes}
                                maxLength={4000}
                            />
                        </div>
                    </Box>
                </NoPrint>
            </ReportCard>
            <Spacer spacing="base" />
        </div>
    );
};

InstallationNotes.displayName = 'InstallationNotes';
