import * as React from 'react';
import * as DOMPurify from 'dompurify';
import { css } from '@emotion/css';
import { Box, Checkbox, Heading, Button, Stack, Text, Icon, IconButton } from 'app/components';
import { t } from 'app/translate';
import i18next from 'i18next';
import { eventTracking } from 'app/core/tracking';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { CONSTANTS } from '../constants';
import { getItemLocalStorage } from 'app/core/persistence';
import { useNavigate } from 'react-router-dom';

const backgroundImageUrl = require('assets/images/axis-site-designer-bg.jpg');

const EulaBackgroundStyle = css`
    background: transparent url(${backgroundImageUrl}) center center no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    filter: blur(3px);
`;

const EulaWrapperStyle = css`
    position: relative;
    left: -50%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 25px auto;
    width: 90%;
    max-width: 820px;
    height: 90%;
    max-height: 600px;
    @media screen and (max-height: 414px) {
        width: 100%;
    }
`;

const EulaStyle = css`
    overflow-y: auto;
    max-height: 400px;
    user-select: text;
    height: 100%;

    @media screen and (max-height: 640px) {
        max-height: 320px;
    }

    @media screen and (max-height: 414px) {
        max-height: 40vh;
    }
`;

const CloseButtonStyle = css`
    position: absolute;
    top: 28px;
    right: 20px;
`;

const fallbackEula = require('src/assets/eula/eula.en.tpl.html');

interface IEulaProps {
    /**
     * User has navigated to the Eula by url, and we should not show accept / continue panel.
     */
    byRoute?: boolean;
    onContinueClicked?(): void;
}

export const Eula: React.FC<IEulaProps> = ({ byRoute, onContinueClicked }) => {
    const hasUserAcceptedFirstEula = getItemLocalStorage('EulaAccepted') === 'true';
    const eulaAcceptedVersion = useSelector<IStoreState, number>(
        (state) => state.app.eulaAcceptedVersion,
    );
    const showMapsInfo =
        hasUserAcceptedFirstEula && eulaAcceptedVersion < CONSTANTS.eulaMapsVersion;
    const [agreementChecked, setAgreementChecked] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const onClose = () => {
        navigate(-1);
    };

    const getEulaHtml = (): React.DOMAttributes<HTMLDivElement>['dangerouslySetInnerHTML'] => {
        const languageCode = i18next.language;
        let eulaHtml: string;

        try {
            eulaHtml = require(`assets/eula/eula.${languageCode}.tpl.html`);
        } catch {
            eventTracking.logError(
                `Could not find EULA for language: ${languageCode} - using fallback`,
                'Eula',
            );
            eulaHtml = fallbackEula;
        }

        return {
            __html: DOMPurify.sanitize(eulaHtml),
        };
    };

    return (
        <>
            <div className={EulaBackgroundStyle} />
            <div style={{ position: 'absolute', left: '50%' }}>
                <div className={EulaWrapperStyle}>
                    <Stack testId="terms_of_use_container" vertical spacing="half" fullHeight>
                        <Box direction="column" color="white" padding="panel" maxHeight="100%">
                            <Stack vertical fullHeight spacing="half">
                                <Box justifyContent="center">
                                    <Heading style="title">{t.eulaWelcome}</Heading>
                                    {byRoute && (
                                        <div className={CloseButtonStyle}>
                                            <IconButton
                                                icon="close_circle_outline"
                                                color="grey5"
                                                onClick={onClose}
                                            />
                                        </div>
                                    )}
                                </Box>
                                {showMapsInfo && (
                                    <Box
                                        flex="none"
                                        padding="half"
                                        justifyContent="center"
                                        color="blue"
                                        borderRadius="rounded"
                                    >
                                        <Stack>
                                            <Icon opaque color="white" icon="info" />
                                            <Box display="block">
                                                <Heading color="white">{t.tosUpdateTitle}</Heading>
                                                <Text color="white" whiteSpace="normal">
                                                    {t.tosUpdateMessage}
                                                </Text>
                                            </Box>
                                        </Stack>
                                    </Box>
                                )}
                                <div
                                    data-test-id="eula-style"
                                    className={EulaStyle}
                                    dangerouslySetInnerHTML={getEulaHtml()}
                                />
                                {!byRoute && (
                                    <Box justifyContent="between" alignItems="center">
                                        <Checkbox
                                            testId="checkbox_agreement"
                                            selected={agreementChecked}
                                            onChange={() => setAgreementChecked(!agreementChecked)}
                                        >
                                            {t.eulaAccept}
                                        </Checkbox>
                                        <Button
                                            testId="btn_continue"
                                            primary
                                            disabled={!agreementChecked}
                                            onClick={onContinueClicked}
                                        >
                                            {t.continue}
                                        </Button>
                                    </Box>
                                )}
                            </Stack>
                        </Box>
                    </Stack>
                </div>
            </div>
        </>
    );
};

Eula.displayName = 'Eula';
