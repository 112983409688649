import type { UserApiCommunicator } from 'app/core/persistence';

export interface IConfig {
    url: string;
    userApiCommunicator: UserApiCommunicator;
    subUrlDistributors: string;
    subUrlPrices: string;
    subUrlRates: string;
}

export class Config {
    public static set(config: IConfig) {
        if (!config.subUrlDistributors) {
            config.subUrlDistributors = '/company/mydistributor';
        }
        if (!config.subUrlPrices) {
            config.subUrlPrices = '/rpl/msrp';
        }
        if (!config.subUrlRates) {
            config.subUrlRates = '/currency/rates';
        }

        this.currentConfig = config;
    }

    public static get(): IConfig {
        return this.currentConfig;
    }

    private static currentConfig: IConfig;
}
