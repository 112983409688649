import type { INetworkRange, INetworkRangeType, IPersistableRangeType } from 'app/core/persistence';
import { createSelector } from 'reselect';
import { convertFromIpRange } from '../modal/IPRangeSettings/convertFromIPRange';
import { getRequiredIpRangeByType } from './getRequiredIpRangeByType';
import { getDevicesOutsideRange } from './getDevicesOutsideRange';
import { getNbrNonCorrespondingTypeInRange } from './getNbrNonCorrespondingTypeInRange';
import { getReservedRanges } from './getReservedRanges';

export const getShowIpRangeWarningIcon = createSelector(
    [
        getReservedRanges,
        getRequiredIpRangeByType,
        getDevicesOutsideRange,
        getNbrNonCorrespondingTypeInRange,
    ],
    (
        ranges,
        requiredIpRangeByType,
        devicesOutsideRange,
        occupiedByOtherDeviceTypes,
    ): Record<INetworkRangeType, boolean> => {
        const showWarning = (type: IPersistableRangeType, range?: INetworkRange): boolean =>
            !!range &&
            (convertFromIpRange(range.ipStart, range.ipEnd) <
                requiredIpRangeByType[type] + occupiedByOtherDeviceTypes[type] ||
                !!devicesOutsideRange[type].length);

        return {
            cameras: showWarning('cameras', ranges?.cameras),
            other: showWarning('other', ranges?.other),
            recorders: showWarning('recorders', ranges?.recorders),
            notUsed: false,
        };
    },
);
