import { toCacheKey, getTiltAnglesForIp } from 'app/modules/common';
import { clamp } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { getSelectedSensorId } from './getSelectedId';

/**
 * Calculate the current tilt angle based on the sensor settings.
 * Will return undefined for panoramic cameras.
 */
export const getTiltAngle = createCachedSelector(
    [getTiltAnglesForIp, getSelectedSensorId],
    (tiltAngles, selectedSensorId) => {
        const sensorId = clamp(selectedSensorId || 1, 1, tiltAngles.length);
        return tiltAngles.length > 0 ? tiltAngles[sensorId - 1] : undefined;
    },
)(toCacheKey);
