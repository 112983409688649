import * as React from 'react';
import { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash-es';
import { eventTracking } from 'app/core/tracking';
import { getOffset, rotate, isDefined, trigonometry, type Point } from 'axis-webtools-util';
import { SceneRenderer, SceneRenderView, type IFloorPlan3d } from './SceneRenderer';
import { ImageService } from 'app/core/persistence';
import type {
    IInstallationPointModel,
    IFloorPlanEntity,
    ILatLng,
    PolyLine,
} from 'app/core/persistence';
import { usePromise, useResizeObserver, useWindowSize } from 'app/hooks';
import { useService } from 'app/ioc';
import { getFloorPlanGeoLocationWithFallback } from '../maps';

interface ISceneRendererProps {
    targetDistance: number;
    targetHeight: number;
    cameraHeight: number;
    resolutionLimit?: number;
    desiredHorizontalFov?: number;
    desiredVerticalFov?: number;
    desiredTiltAngle?: number;
    selectedHorizontalFov: number;
    selectedVerticalFov: number;
    selectedTiltAngle: number;
    horizontalAngle?: number;
    selectedView: SceneRenderView;
    location?: IInstallationPointModel['location'];
    floorPlans: IFloorPlanEntity[];
    blockers?: PolyLine[];
    onChangeInstallationHeight?(newHeight: number): void;
    onChangeTargetDistance?(newDistance: number): void;
    setBlindSpot?(blindSpot: number | undefined): void;
    setWidthAtTarget?(widthAtTarget: number): void;
    enableDragEvents: boolean;
}

const debouncedLogUserEvent = debounce(eventTracking.logUserEvent, 500);

const isLatLng = (pointOrPoly: ILatLng | ILatLng[]): pointOrPoly is ILatLng =>
    'lat' in pointOrPoly && 'lng' in pointOrPoly;

const max180 = (value?: number) => value && Math.min(value, Math.PI);

export const SceneRendererComponent: React.FunctionComponent<ISceneRendererProps> = ({
    targetDistance,
    targetHeight,
    cameraHeight,
    resolutionLimit,
    desiredHorizontalFov,
    desiredVerticalFov,
    desiredTiltAngle,
    selectedHorizontalFov,
    selectedVerticalFov,
    selectedTiltAngle,
    horizontalAngle,
    selectedView,
    location,
    floorPlans,
    blockers,
    onChangeInstallationHeight,
    onChangeTargetDistance,
    setBlindSpot,
    setWidthAtTarget,
    enableDragEvents,
}) => {
    const { ref: parentRef } = useResizeObserver<HTMLDivElement>({
        onResize: () => {
            if (parentRef.current) {
                rendererRef.current?.resize(parentRef.current);
            }
        },
    });

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const rendererRef = useRef<SceneRenderer>(new SceneRenderer());
    const imageService = useService(ImageService);

    const screenSize = useWindowSize();

    const [ready, setReady] = useState(false);

    const onChangeTargetDistanceCallback = useCallback(
        (distance: number) => {
            debouncedLogUserEvent('DeviceSelector3DView', 'Change target distance');
            onChangeTargetDistance?.(distance);
        },
        [onChangeTargetDistance],
    );
    const onChangeInstallationHeightCallback = useCallback(
        (height: number) => {
            debouncedLogUserEvent('DeviceSelector3DView', 'Change camera height');
            onChangeInstallationHeight?.(height);
        },
        [onChangeInstallationHeight],
    );

    useEffect(() => {
        if (parentRef.current) rendererRef.current?.resize(parentRef.current);
    }, [parentRef, screenSize]);

    useEffect(() => {
        // initialize renderer and setup event listeners
        if (!canvasRef.current || !parentRef.current) return;

        let isMounted = true;

        const renderer = rendererRef.current;

        renderer.init(canvasRef.current).then(() => {
            // the promise may resolve after the component has unmounted...
            if (isMounted) {
                if (parentRef.current) {
                    renderer?.resize(parentRef.current);
                }
                setReady(true);
            }
        });

        renderer.on('setupRenderError', (errorMessage) => {
            eventTracking.logError(`Setup render error: ${errorMessage}`, 'FieldOfViewFilter');
        });

        return () => {
            isMounted = false;
            renderer.destroy();
        };
    }, [canvasRef, parentRef]);

    useEffect(() => {
        if (!rendererRef.current) return;

        const renderer = rendererRef.current;
        renderer.on('distanceChange', onChangeTargetDistanceCallback);
        renderer.on('cameraHeightChange', onChangeInstallationHeightCallback);

        return () => {
            renderer.off('distanceChange', onChangeTargetDistanceCallback);
            renderer.off('cameraHeightChange', onChangeInstallationHeightCallback);
        };
    }, [onChangeTargetDistanceCallback, onChangeInstallationHeightCallback]);

    useEffect(() => {
        if (!ready || !rendererRef.current) return;

        rendererRef.current.enableDragEvents(enableDragEvents);
    }, [ready, enableDragEvents]);

    // update SceneRenderer
    useEffect(() => {
        // update the 3D view when something changed
        if (!ready || !rendererRef.current) return;

        rendererRef.current.set({
            targetDistance: targetDistance,
            targetHeight: targetHeight,
            cameraHeight: cameraHeight,
            resolutionLimit: resolutionLimit,
            desiredHorizontalFov: max180(desiredHorizontalFov),
            desiredVerticalFov: max180(desiredVerticalFov),
            desiredTiltAngle: desiredTiltAngle,
            selectedHorizontalFov: max180(selectedHorizontalFov),
            selectedVerticalFov: max180(selectedVerticalFov),
            selectedTiltAngle: selectedTiltAngle,
            showResolutionGuideOnError: !!location,
        });
        rendererRef.current.update();

        setBlindSpot?.(rendererRef.current.getBlindSpot());
        setWidthAtTarget?.(rendererRef.current.getWidthAtTarget());
    }, [
        ready,
        targetDistance,
        targetHeight,
        cameraHeight,
        resolutionLimit,
        desiredHorizontalFov,
        desiredVerticalFov,
        desiredTiltAngle,
        selectedHorizontalFov,
        selectedVerticalFov,
        selectedTiltAngle,
        setBlindSpot,
        setWidthAtTarget,
        location,
    ]);

    // load floor plan images. This will generally be quite fast since they are already
    // loaded for display on the map and cached in imageService
    const imagesPromise = useMemo(async () => {
        const imagemap = new Map<string, string>();
        for (const floorPlan of floorPlans) {
            if (floorPlan.image) {
                const url = await imageService.getImageUrlAsBase64(floorPlan.image.key);
                imagemap.set(floorPlan.image.key, url);
            }
        }

        return imagemap;
    }, [floorPlans, imageService]);

    const { result: images, pending: loadingImages } = usePromise(imagesPromise, [imagesPromise]);

    // update floor plans in 3d view
    useEffect(() => {
        if (
            !ready ||
            !location ||
            horizontalAngle === undefined ||
            !rendererRef.current ||
            loadingImages ||
            !images
        ) {
            return;
        }

        const offsetFromCam = getOffset(location);

        // construct an array of floor plan images
        const floorPlanImages = floorPlans.reduce((acc: IFloorPlan3d[], floorPlan) => {
            if (floorPlan.image) {
                // get the url from the images map
                const url = images.get(floorPlan.image.key) ?? '';
                const geoLocation = getFloorPlanGeoLocationWithFallback(floorPlan);

                if (!geoLocation) {
                    throw Error('No geoLocation found for floorPlan');
                }

                // add 90 degrees to match the 3D view coordinate system
                const horizontalAngleRad = trigonometry.toRadians(90 + horizontalAngle);

                // calculate the offset from camera to the floor plan
                const floorPlanOffset = offsetFromCam(geoLocation.position);
                const rotatedOffset = rotate(-horizontalAngleRad)(floorPlanOffset);

                acc.push({
                    key: floorPlan.image.key,
                    url,
                    width: geoLocation.width,
                    height: geoLocation.height,
                    angle: horizontalAngleRad - geoLocation.angle,
                    offsetX: rotatedOffset[0],
                    offsetZ: -rotatedOffset[1],
                });
            }
            return acc;
        }, []);

        // set the floor plans in the 3D view
        rendererRef.current.setFloorPlans(floorPlanImages);
    }, [location, horizontalAngle, floorPlans, images, ready, loadingImages]);

    // update blockers
    useEffect(() => {
        if (
            !ready ||
            !rendererRef.current ||
            !location ||
            !blockers ||
            horizontalAngle === undefined
        )
            return;

        const offsetFromCam = getOffset(location);
        const angle = ((90 + horizontalAngle) / 180) * Math.PI;
        const rotateToAngle = rotate(-angle);

        const mappedBlockers: Point[][] = (blockers ?? []).map((blocker) =>
            blocker
                .map((point) => (isLatLng(point) ? offsetFromCam(point) : undefined))
                .filter(isDefined)
                .map(rotateToAngle)
                .map(([a, b]) => [a, -b]),
        );

        const height = Math.max(cameraHeight + 1, targetHeight);

        rendererRef.current.setBlockers(mappedBlockers, height);

        rendererRef.current.update();
    }, [
        location,
        horizontalAngle,
        cameraHeight,
        targetHeight,
        targetDistance,
        blockers,
        ready,
        selectedView,
        desiredTiltAngle,
        selectedHorizontalFov,
        selectedVerticalFov,
    ]);

    // update view
    useEffect(() => {
        if (!ready) return;
        // update 3D view when view selection changes
        rendererRef.current?.setView(selectedView);
        rendererRef.current?.update();
    }, [selectedView, ready]);

    const onCanvasMouseDown = () => {
        if (selectedView === SceneRenderView.ORBIT) {
            eventTracking.logUserEvent('DeviceSelector3DView', 'Mouse orbit');
        }
        // tell renderer
        rendererRef.current?.onMouseDown();
    };

    return (
        <div
            ref={parentRef}
            style={{
                /* this div is required to make resizing work as expected
                 * Not used at the moment but since offering a possibility
                 * to enlarge the 3D view is high on the wishlist it makes
                 * sense to keep it */
                height: '100%',
                width: '100%',
                position: 'relative',
            }}
        >
            <canvas
                style={{
                    outline: 0,
                }}
                ref={canvasRef}
                onPointerDown={onCanvasMouseDown}
            />
        </div>
    );
};

SceneRendererComponent.displayName = 'SceneRendererComponent';
