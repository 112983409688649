import * as React from 'react';
import { DeviceSelector } from 'app/modules/deviceSelector';
import { useParams } from 'react-router-dom';
import type { DeviceGroup } from 'app/modules/deviceSelector/models';
import type { PiaCategory } from 'app/core/pia';

export const ProjectDeviceSelectorAdd: React.FunctionComponent = () => {
    // If deviceGroup not provided - all device groups are shown in device selector
    const params = useParams();

    return (
        <DeviceSelector
            urlDeviceGroup={params.deviceGroup as DeviceGroup}
            showAll={!params.deviceGroup}
            category={params.category as PiaCategory}
        />
    );
};

ProjectDeviceSelectorAdd.displayName = 'ProjectDeviceSelectorAdd';
