import * as React from 'react';
import { Licenses } from './components';
import { t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import { VideoFrame, Box } from 'app/components';
import { SBOMDownload } from './SBOMDownload';

export const About: React.FunctionComponent = () => {
    return (
        <div data-test-id="panel_about" style={{ maxHeight: '600px', maxWidth: '800px' }}>
            <p data-test-id="app_version">{`${t.versionLabel}: ${AppConstants.appVersion}`}</p>
            <Box>
                <VideoFrame
                    src="https://www.axis.com/videoplayer/38208"
                    allowFullScreen
                    width="640px"
                    height="360px"
                />
            </Box>
            <Box alignItems="start" paddingTop="base">
                <SBOMDownload />
            </Box>
            <h1>{t.licensesHeader}</h1>
            <p>{t.licensesNoticeText}</p>
            <Licenses />
        </div>
    );
};

About.displayName = 'About';
