import * as React from 'react';
import { Card, Box, Text, Border, Icon, IconText, Spacer, RequirementItem } from 'app/components';
import { PoeClass } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import type { IRecordingItemStats, IPoeRequirement, IRequirementDimensions } from '../selectors';
import {
    getIsS30WithoutTerminalOrServer,
    getNbrOfDoorsForProject,
    getRecordingSolutionTotalSpecs,
    getRecordingSolutionRequirements,
    getPoeRequirements,
    getValidationError,
    getValidationInfo,
    getDimensionsApplicable,
    getDimensionsWarnings,
    NoPoeClass,
} from '../selectors';
import { connect } from 'react-redux';
import { format } from 'axis-webtools-util';
import { t } from 'app/translate';
import { ProjectZipStreamSettingsDropDown } from './ProjectZipStreamSettingsDropDown';
import { getHasAcsSolution } from 'app/modules/common';

interface IProjectRequirementsOwnProps {
    fullWidth?: boolean;
    hideZipstreamSettings?: boolean;
}
interface IProjectRequirementsStateProps {
    totalSpecs: IRecordingItemStats;
    requirements: IRecordingItemStats;
    poeRequirements: IPoeRequirement[];
    relevantDimensions: IRequirementDimensions;
    dimensionWarnings: IRequirementDimensions;
    hasS30WithoutTerminalOrServer: boolean;
    validationError?: string;
    validationInfo?: string;
    validationInfoDoors?: string;
}

type IProjectRequirementsProps = IProjectRequirementsOwnProps & IProjectRequirementsStateProps;

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProjectRequirementsOwnProps,
): IProjectRequirementsProps => {
    const totalSpecs = getRecordingSolutionTotalSpecs(storeState);
    const totalNbrOfDoors = getNbrOfDoorsForProject(storeState);
    const hasAcsSolution = getHasAcsSolution(storeState);

    return {
        totalSpecs,
        requirements: getRecordingSolutionRequirements(storeState),
        poeRequirements: getPoeRequirements(storeState),
        validationError: getValidationError(storeState)?.validationErrorMessage,
        validationInfo: getValidationInfo(storeState)?.validationInfoMessage,
        validationInfoDoors:
            hasAcsSolution && totalNbrOfDoors > totalSpecs.nrOfChannels * 2
                ? t.recordingValidationNbrDoorsNotSupported(
                      totalNbrOfDoors,
                      Math.ceil(totalNbrOfDoors / 2),
                  )
                : undefined,
        relevantDimensions: getDimensionsApplicable(storeState),
        dimensionWarnings: getDimensionsWarnings(storeState),
        hasS30WithoutTerminalOrServer: getIsS30WithoutTerminalOrServer(storeState),
        ...ownProps,
    };
};

const ProjectRequirementsContainer: React.FunctionComponent<IProjectRequirementsProps> = ({
    totalSpecs,
    requirements,
    poeRequirements,
    validationError,
    validationInfo,
    validationInfoDoors,
    relevantDimensions,
    dimensionWarnings,
    fullWidth,
    hideZipstreamSettings,
    hasS30WithoutTerminalOrServer,
}) => {
    const getPoeClassTranslation = React.useCallback((poeClass: string) => {
        if (poeClass === PoeClass.HighPoE) {
            return t.poeClassesHighPoe;
        }
        if (poeClass === NoPoeClass.NoPoe) {
            return t.poeClassesNonPoe;
        }
        return t.poeClassesClassNbr(poeClass);
    }, []);

    return (
        <Card notFullWidth paddingX="base">
            <Box direction="column" spacing="base">
                <Box direction="column">
                    <Box spacing="panel" justifyContent="center">
                        <Box
                            paddingY="base"
                            justifyContent="center"
                            spacing={fullWidth ? 'quart' : 'base'}
                            display="grid"
                            grid={{
                                gridTemplateColumns: fullWidth
                                    ? 'repeat(6, 1fr)'
                                    : 'repeat(3, 1fr)',
                            }}
                        >
                            <RequirementItem
                                label={t.storage}
                                totalInSolution={totalSpecs.storage}
                                totalRequired={requirements.storage}
                                warn={dimensionWarnings.storage}
                                testId="storage"
                                inSolutionText={format.storageRequirementsOneValue(
                                    requirements.storage,
                                    totalSpecs.storage,
                                    false,
                                )}
                                requiredText={format.storageRequirementsOneValue(
                                    requirements.storage,
                                    totalSpecs.storage,
                                    true,
                                )}
                                notApplicable={!relevantDimensions.storage}
                                showSmall={!fullWidth}
                            />
                            <RequirementItem
                                label={t.channels}
                                totalInSolution={totalSpecs.nrOfChannels}
                                totalRequired={requirements.nrOfChannels}
                                warn={dimensionWarnings.channels}
                                testId="devices"
                                inSolutionText={totalSpecs.nrOfChannels}
                                requiredText={requirements.nrOfChannels}
                                notApplicable={!relevantDimensions.channels}
                                showSmall={!fullWidth}
                            />
                            <RequirementItem
                                label={t.licenses}
                                totalInSolution={totalSpecs.nrOfLicenses}
                                totalRequired={requirements.nrOfLicenses}
                                warn={dimensionWarnings.licenses}
                                testId="licenses"
                                inSolutionText={totalSpecs.nrOfLicenses}
                                requiredText={requirements.nrOfLicenses}
                                notApplicable={!relevantDimensions.licenses}
                                showSmall={!fullWidth}
                            />
                            <RequirementItem
                                label={t.bandwidth}
                                totalInSolution={totalSpecs.bandwidth}
                                totalRequired={requirements.bandwidth}
                                warn={dimensionWarnings.bandwidth}
                                testId="bandwidth"
                                inSolutionText={format.bandwidthRequirementsOneValue(
                                    totalSpecs.bandwidth,
                                    requirements.bandwidth,
                                    true,
                                )}
                                requiredText={format.bandwidthRequirementsOneValue(
                                    totalSpecs.bandwidth,
                                    requirements.bandwidth,
                                    false,
                                )}
                                notApplicable={!relevantDimensions.bandwidth}
                                showSmall={!fullWidth}
                            />
                            <RequirementItem
                                label={t.power}
                                totalInSolution={totalSpecs.power}
                                totalRequired={requirements.power}
                                warn={dimensionWarnings.power}
                                testId="power"
                                inSolutionText={format.poePower(
                                    requirements.power,
                                    totalSpecs.power,
                                    false,
                                )}
                                requiredText={format.poePower(
                                    requirements.power,
                                    totalSpecs.power,
                                    true,
                                )}
                                notApplicable={!relevantDimensions.power}
                                showSmall={!fullWidth}
                            />
                            <RequirementItem
                                label={t.ports}
                                totalInSolution={totalSpecs.ports.totalPorts}
                                totalRequired={requirements.ports.totalPorts}
                                warn={
                                    totalSpecs.ports.totalPorts > requirements.ports.totalPorts &&
                                    dimensionWarnings.ports
                                }
                                testId="ports"
                                inSolutionText={totalSpecs.ports.totalPorts}
                                requiredText={requirements.ports.totalPorts}
                                notApplicable={!relevantDimensions.ports}
                                showSmall={!fullWidth}
                            />
                        </Box>
                        {poeRequirements.some((poeReq) => !poeReq.requirementFulfilled) && (
                            <Border leftWidth={1} color="grey3">
                                <Box paddingLeft="base" paddingY="base" height="100%">
                                    <Box direction="column" spacing="none">
                                        <Box spacing="quart">
                                            <Icon icon="warning" color="red" size="xs" />
                                            <Text style="semibold" color="grey5">
                                                {t.missingPorts}
                                            </Text>
                                        </Box>
                                        {poeRequirements
                                            .filter((poeReq) => !poeReq.requirementFulfilled)
                                            .map((poeReq) => (
                                                <Box key={poeReq.poeClass} spacing="base">
                                                    <Box width="60px">
                                                        <Text
                                                            whiteSpace="nowrap"
                                                            color="red"
                                                            style="semibold"
                                                        >
                                                            {getPoeClassTranslation(
                                                                poeReq.poeClass,
                                                            )}
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <Text
                                                            whiteSpace="nowrap"
                                                            color="red"
                                                            style="semibold"
                                                        >
                                                            {poeReq.missingPorts}
                                                        </Text>
                                                    </Box>
                                                </Box>
                                            ))}
                                    </Box>
                                </Box>
                            </Border>
                        )}
                    </Box>
                    <Box justifyContent="center" alignItems="center">
                        {(validationError ||
                            validationInfo ||
                            validationInfoDoors ||
                            hasS30WithoutTerminalOrServer) && (
                            <Box
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                                maxWidth="800px"
                                direction="column"
                                paddingBottom="base"
                            >
                                {validationError && (
                                    <IconText
                                        icon="warning"
                                        spacing="half"
                                        iconProps={{ color: 'red', opaque: true }}
                                    >
                                        {validationError}
                                    </IconText>
                                )}
                                {validationInfo && (
                                    <IconText
                                        icon="info"
                                        spacing="half"
                                        iconProps={{ color: 'blue', opaque: true }}
                                    >
                                        {validationInfo}
                                    </IconText>
                                )}

                                {(validationError || validationInfo) && validationInfoDoors && (
                                    <Spacer />
                                )}
                                {validationInfoDoors && (
                                    <IconText
                                        icon="info"
                                        spacing="half"
                                        iconProps={{ color: 'blue', opaque: true }}
                                    >
                                        {validationInfoDoors}
                                    </IconText>
                                )}
                                {hasS30WithoutTerminalOrServer && (
                                    <IconText
                                        icon="info"
                                        spacing="half"
                                        iconProps={{ color: 'blue', opaque: true }}
                                    >
                                        {t.acsMissingFromS30RecorderSolution}
                                    </IconText>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>

                {!hideZipstreamSettings && <ProjectZipStreamSettingsDropDown />}
            </Box>
        </Card>
    );
};

export const ProjectRequirements = connect(mapStateToProps)(ProjectRequirementsContainer);
ProjectRequirements.displayName = 'ProjectRequirements';
