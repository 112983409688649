import type { IItem } from 'app/core/persistence';
import { getParentId } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import {
    getCurrentProjectFloorPlans,
    getCurrentProjectInstallationPointsArray,
    getCurrentProjectItems,
    getCurrentProjectLocation,
    getPartnerConfig,
    getPiaItems,
    getItemVersion,
} from 'app/modules/common';
import { ColorsEnum } from 'app/styles';
import { groupBy } from 'lodash-es';
import { createSelector } from 'reselect';

interface IBidconLine {
    name: string;
    color: ColorsEnum;
    notes: string;
    mapName: string;
    quantity: number;
    mappedId: string;
    externalId: string;
}

export const getBidconExportFileContent = createSelector(
    [
        getCurrentProjectItems,
        getCurrentProjectFloorPlans,
        getCurrentProjectInstallationPointsArray,
        getPartnerConfig,
        getPiaItems,
        getCurrentProjectLocation,
    ],
    (items, floorPlans, installationPoints, { mappedPiaIds }, piaItems, location) => {
        const csvLines = [
            [
                'Ämne',
                'Anmärkning',
                'Sidetikett',
                'Färg',
                'Lager',
                'Utrymme',
                'Längd',
                'Längd Enhet',
                'Antal',
                'Bidcon enhet',
                'Total vertikal längd',
                'Vertikal höjd',
                'Summerad längd',
                'Ledningsantal',
                'Exporterad',
                'Exportvärde',
                'System ID',
                'Externt ID',
            ].join(';'),
        ];

        const lines: IBidconLine[] = [];

        Object.values(items).forEach((item) => {
            if (!item || !item.productId) {
                return;
            }
            const piaItem = piaItems.find(({ id }) => item.productId === id);
            const version = getItemVersion(piaItem, location, true);
            const externalId = version?.partno ?? '';
            const mappedId = mappedPiaIds?.[item.productId] ?? '';
            const ips = installationPoints.filter((ip) => getParentId(ip) === item._id);
            // Group the installation points per floor plan
            const groupByFloorPlanId = groupBy(ips, ({ floorPlanId }) => floorPlanId);

            // Keep track of how many items on each floor plan
            let quantity = item.quantity;

            // We need to multiply quantity with parent if we have a child device or accessory
            const parentId = getParentId(item);
            if (parentId?.startsWith('item:')) {
                quantity *= items[parentId]?.quantity || 1;
            }

            if (mappedId || externalId) {
                Object.entries(groupByFloorPlanId).forEach(([key, value]) => {
                    const floorPlan = floorPlans[key];
                    if (!floorPlan) {
                        return;
                    }
                    lines.push(
                        toBidconLine(
                            item,
                            floorPlan.name,
                            value.length,
                            mappedId,
                            externalId,
                            piaItem,
                        ),
                    );

                    quantity -= value.length;
                });

                // If we have an item that is not placed on maps - add it with empty map name
                // For example if the item's quantity is 5 but only 2 of them are placed on maps
                if (quantity > 0) {
                    lines.push(toBidconLine(item, '', quantity, mappedId, externalId, piaItem));
                }
            }
        });
        const mergedCvsLines = mergeLines(lines).map(toCsvLine);
        csvLines.push(...mergedCvsLines);

        return csvLines;
    },
);

// Merge all lines that is exactly the same except quantity
function mergeLines(lines: IBidconLine[]) {
    const groups = groupBy(
        lines,
        ({ color, mapName, mappedId, name, notes }) =>
            `${color}_${mapName}_${mappedId}_${name}_${notes}`,
    );

    return Object.values(groups).map((group) => ({
        color: group[0].color,
        mapName: group[0].mapName,
        mappedId: group[0].mappedId,
        name: group[0].name,
        notes: group[0].notes,
        quantity: group.reduce((prev, curr) => prev + curr.quantity, 0),
        externalId: group[0].externalId,
    }));
}

function toBidconLine(
    item: IItem,
    mapName: string,
    quantity: number,
    mappedId: string,
    externalId: string,
    piaItem?: IPiaItem,
): IBidconLine {
    return {
        color: item.color ? ColorsEnum[item.color] : ColorsEnum.blue,
        mapName,
        quantity,
        mappedId,
        externalId,
        name: item.name || piaItem?.name || '',
        notes: item.notes || '',
    };
}

function toCsvLine({ name, notes, mapName, color, quantity, mappedId, externalId }: IBidconLine) {
    return [
        name, // Ämne
        notes, // Anmärkning
        mapName, // Sidetikett
        color, // Färg
        '', // Lager
        '', // Utrymme
        '0', // Längd
        '', // Längd Enhet
        quantity, // Antal
        'st', // Bidcon enhet
        '0', // Total vertikal längd
        '', // Vertikal höjd
        '', // Summerad längd
        '', // Ledningsantal
        'Avmarkerad', // Exporterad
        'Antal', // Exportvärde
        mappedId, // System ID
        externalId, // Externt ID
    ].join(';');
}
