import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IEntity } from '../../entities';
import type { IPersistenceRepository } from '../../repositories';
import { DeletableEntity } from '../models';

/**
 * Removes orphan timeSerie entities from deleted projects
 * Time series to existing schedules get an full updated path including project, and not just the schedule.
 */
@injectable()
export class Migration1To2 extends MigrationProviderBase {
    public from: number = 1;
    public to: number = 2;

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'timeSerie') {
                const schedules = await repository.getAll('schedule');
                const timeSerie = entity as IEntity;

                const foundSchedule = schedules.find((schedule) => {
                    return schedule._id === timeSerie.path[0];
                });

                if (!foundSchedule) {
                    return Promise.resolve(new DeletableEntity(entity));
                } else {
                    entity.path = [foundSchedule.path[0], foundSchedule._id, timeSerie._id];
                }
            }
            return Promise.resolve(entity);
        };
    }
}
