import { flatMap } from 'lodash-es';
import { createSelector } from 'reselect';
import {
    getPiaItemsRecord,
    getCurrentProjectItems,
    getCurrentProjectItemRelationsArray,
    getCurrentProjectRecordingSolutionItems,
    getIsCompanionSolutionSelected,
    getSelectedRecordingSolutionType,
    createDeepEqualSelector,
} from 'app/modules/common';
import { isSDCardCompanionSystem, nTimes } from './common';
import type { IComponentWithQuantity, IRecordingSolutionItem } from './common';
import type { IItemEntity, Id } from 'app/core/persistence';
import { PartnerSystemItemCategory } from 'app/core/partner';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemRecorderCategory, PiaItemSoftwareCategory } from 'app/core/pia';
import { groupByPiaId } from './groupByPiaId';

/**
 * Checks if a recording solution has items (No Third-party) but lacks a recorder.
 * E.g if this is a Companion SD-card solution
 */
export const getIsSolutionWithoutRecorder = createSelector(
    [getCurrentProjectRecordingSolutionItems, getPiaItemsRecord],
    (items, piaItems) => {
        return (
            items.length > 0 &&
            !items.some(
                (item) =>
                    item.properties.partnerSystemComponent?.category ===
                        PartnerSystemItemCategory.SERVER ||
                    // Check if items is a license, server, terminal or S30
                    (item.productId &&
                        (piaItems[item.productId].category === PiaItemSoftwareCategory.VMS ||
                            piaItems[item.productId].category ===
                                PiaItemRecorderCategory.RECORDERS2 ||
                            piaItems[item.productId].category ===
                                PiaItemRecorderCategory.DESKTOPTERMINALS ||
                            piaItems[item.productId].properties.series === 'S30')),
            )
        );
    },
);

/**
 * Checks if solution contains an S30 server and an ACS license, but no terminals or S11/S12 servers.
 */
export const getIsS30WithoutTerminalOrServer = createSelector(
    [getCurrentProjectRecordingSolutionItems, getPiaItemsRecord],
    (items, piaItemsRecord): boolean => {
        let hasS30Server: boolean = false;
        let hasTerminal: boolean = false;
        let hasS11OrS22: boolean = false;
        let hasACS: boolean = false;

        items.forEach((current) => {
            if (!current.productId) return;
            const piaItem: IPiaItem = piaItemsRecord[current.productId];

            if (piaItem.properties.series === 'S30') {
                hasS30Server = true;
            }
            if (piaItem.category === PiaItemRecorderCategory.DESKTOPTERMINALS) {
                hasTerminal = true;
            }
            if (piaItem.properties.series === 'S11' || piaItem.properties.series === 'S22') {
                hasS11OrS22 = true;
            }
            if (piaItem.properties.series === 'AXIS Camera Station') {
                hasACS = true;
            }
        });
        return hasS30Server && hasACS && !hasTerminal && !hasS11OrS22;
    },
);

/**
 * Get a map between device id and pia accessories
 */
export const getItemAccessories = createSelector(
    [getCurrentProjectItems, getCurrentProjectItemRelationsArray],
    (items, itemRelations) =>
        itemRelations.reduce((acc: Record<Id, IItemEntity[]>, relation) => {
            const accessory = items[relation.childId];
            if (accessory?.productId) {
                acc[relation.parentId] = [...(acc[relation.parentId] ?? []), accessory];
            }
            return acc;
        }, {}),
);

/**
 * Get the persisted recording solution product ids in the current project
 */
export const getCurrentSolutionPiaIds = createSelector(
    [getCurrentProjectRecordingSolutionItems, getPiaItemsRecord],
    (items, piaItems) => items.map((item) => piaItems[item.productId!].id),
);

/**
 * true if there are no products in the selected solution
 */
export const isCurrentSolutionEmpty = createSelector(
    [getCurrentProjectRecordingSolutionItems],
    (items) => items.length === 0,
);

export const getCurrentSolutionWithAdditionalStorage = createDeepEqualSelector(
    [getCurrentProjectRecordingSolutionItems, getItemAccessories, getPiaItemsRecord],
    (solutionItems, accessories, piaItems): IRecordingSolutionItem[] => {
        const items = solutionItems.map((item) => {
            const piaItem = item.productId === null ? undefined : piaItems[item.productId];
            return {
                piaId: item.productId ?? 0,
                name: piaItem?.name ?? '',
                quantity: item.quantity,
                piaItem,
                accessories: (accessories[item._id] ?? [])
                    .filter(
                        ({ productId }) => productId && piaItems[productId]?.category === 'storage',
                    )
                    .map((accessory) => {
                        const piaAccessory = piaItems[accessory.productId ?? 0];
                        return {
                            piaId: accessory.productId ?? 0,
                            name: piaAccessory?.name ?? '',
                            quantity: accessory.quantity,
                            piaItem: piaAccessory,
                        };
                    }),
            };
        });

        // Fix WT-8545, Group by piaId and sum the quantities and accessories.
        return groupByPiaId(items);
    },
);

export const getRecordingSolutionComponents = createDeepEqualSelector(
    [getCurrentSolutionWithAdditionalStorage, getPiaItemsRecord],
    (solutionItems, piaItems) => {
        const recordingSolutionComponents: IComponentWithQuantity[] = [];
        solutionItems.forEach((item) => {
            const extraStorage = flatMap(item.accessories, ({ piaId, quantity }) => {
                const piaItem = piaItems[piaId];
                return piaItem ? nTimes(piaItem, quantity) : [];
            });
            const component = piaItems[item.piaId];
            if (component) {
                const componentWithQuantity = {
                    component: {
                        ...component,
                        extraStorage,
                    },
                    quantity: item.quantity,
                };
                recordingSolutionComponents.push(componentWithQuantity);
            }
        });

        return recordingSolutionComponents;
    },
);

/*
 * Check whether the current solution i a pure Companion system, i.e. has only
 * S30 series recorders or SD-cards. Later on we will add support for hybrid ACS/Companion systems,
 * hence the differentiation between pure and hybrid Companion systems
 */
export const isPureCompanionSystem = createSelector(
    [getIsCompanionSolutionSelected, getRecordingSolutionComponents],
    (isCompanionSolutionSelected, components) => {
        if (!isCompanionSolutionSelected) {
            return false;
        }

        const recorders = components.filter(({ component }) => component.category === 'recorders2');
        const storage = components.filter(({ component }) => component.category === 'storage');

        const onlyCompanionVMS =
            recorders.length > 0 &&
            recorders.every(({ component }) => component.properties.series === 'S30');
        const onlySDCards =
            recorders.length < 1 &&
            storage.every(({ component }) => component.properties.memoryCardSize);

        return onlyCompanionVMS || onlySDCards;
    },
);

export const getIsSDCardCompanionSystem = createSelector(
    [getRecordingSolutionComponents, getSelectedRecordingSolutionType],
    (components, selectedRecordingSolutionType) =>
        selectedRecordingSolutionType === 'AxisCompanionSDCard' &&
        isSDCardCompanionSystem(components),
);
