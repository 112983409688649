import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem, getCurrentProjectProfiles } from '../../project';
import { deviceTypeCheckers } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';

export const getAssociatedProfile = createCachedSelector(
    [getCurrentProjectItem, getCurrentProjectProfiles],
    (item, currentProjectProfiles) => {
        if (!item) {
            return undefined;
        }

        if (deviceTypeCheckers.isAnalogCamera(item)) {
            return currentProjectProfiles[item.properties.analogCamera.associatedProfile];
        }

        if (deviceTypeCheckers.isDoorStation(item) || deviceTypeCheckers.isCamera(item)) {
            return currentProjectProfiles[item.properties.camera.associatedProfile];
        }

        if (deviceTypeCheckers.isSensorUnit(item)) {
            return currentProjectProfiles[item.properties.sensorUnit.associatedProfile];
        }

        if (deviceTypeCheckers.isVirtualProduct(item)) {
            return currentProjectProfiles[item.properties.virtualProduct.associatedProfile];
        }

        return undefined;
    },
)(toCacheKey);
