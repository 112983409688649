import { Box, Stack } from 'app/components';
import type { IItemEntity, IPersistence, IInstallationPointEntity } from 'app/core/persistence';
import { getUnplacedDevicesQuantity } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { DevicesNotPlacedOnMap } from './DevicesNotPlacedOnMap';
import { DevicesPlacedOnMap } from './DevicesPlacedOnMap';

interface IInstallationPointList {
    item: IPersistence<IItemEntity>;
    installationPoints?: IInstallationPointEntity[];
    fullWidth?: boolean;
}

export const InstallationPointList: React.FunctionComponent<IInstallationPointList> = ({
    installationPoints = [],
    item,
    fullWidth,
}: IInstallationPointList) => {
    const unplacedQuantity = useSelector((state: IStoreState) =>
        getUnplacedDevicesQuantity(state, item._id),
    );

    return (
        <Box
            width="100%"
            maxHeight="100%"
            flex="fullWidth"
            overflow="hidden"
            overflowY="auto"
            height={fullWidth ? '450px' : 'auto'}
            alignItems="start"
        >
            <Stack vertical spacing="doublePanel">
                {installationPoints.length > 0 && <DevicesPlacedOnMap item={item} />}
                {unplacedQuantity > 0 && <DevicesNotPlacedOnMap item={item} />}
            </Stack>
        </Box>
    );
};

InstallationPointList.displayName = 'InstallationPointList';
