import * as React from 'react';
import { Text, PiaImage, Stack, Button, Box, Quantity, Badge, Spacer } from 'app/components';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import type { PiaId, IPiaItem, IPiaSystemComponent } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import { getCurrentProjectLocked, getPiaItemsRecord, PiaItemMsrp } from 'app/modules/common';
import { t } from 'app/translate';
import { ServiceLocator } from 'app/ioc';
import { RecordingAccessoriesService } from '../services';
import type { Id } from 'app/core/persistence';
import { getQuantityForRecordingAccessories, getIsRecommended } from '../selectors';
import { eventTracking } from 'app/core/tracking';
import { getFreeHddBays } from '../selectors/getFreeHddBays';

interface IRecordingItemProps {
    piaItem: IPiaItem;
    parentId?: Id;
    quantity?: number;
    maxQuantity: number;
    isDiscontinued: boolean;
    isRecommended: boolean;
    isLocked: boolean;
    canAddAccessory: boolean;
}

interface IRecordingItemOwnProps {
    productId: PiaId;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IRecordingItemOwnProps,
): IRecordingItemProps => {
    const piaItem = getPiaItemsRecord(storeState)[ownProps.productId] as IPiaSystemComponent;
    const parentId = storeState.recordingSelector.selectedDeviceDetails;
    const quantity = getQuantityForRecordingAccessories(storeState, parentId)[ownProps.productId];
    const isRecommended = getIsRecommended(storeState, parentId, ownProps.productId);
    const isLocked = getCurrentProjectLocked(storeState);

    let maxQuantity = 999;
    let canAddAccessory = true;
    const isStorageAccessory = piaItem.properties.maxRecordingStorageMegaBytes !== undefined;
    if (isStorageAccessory && parentId) {
        const freeHddBays = getFreeHddBays(storeState);
        canAddAccessory = freeHddBays[parentId] > 0;
        maxQuantity = canAddAccessory ? maxQuantity : quantity;
    }

    return {
        piaItem,
        parentId,
        quantity,
        maxQuantity,
        isDiscontinued: piaItem.state > PiaItemState.EXTERNALLY_ANNOUNCED,
        isRecommended,
        isLocked,
        canAddAccessory,
    };
};

const addItem = (parentId: Id, productId: PiaId) => {
    const accessoriesService = ServiceLocator.get(RecordingAccessoriesService);
    accessoriesService.add(parentId, productId);
    eventTracking.logUserEvent('System Components', 'Add recording accessory', `${productId}`);
};

const updateQuantity = (parentId: Id, productId: PiaId, quantity: number) => {
    const accessoriesService = ServiceLocator.get(RecordingAccessoriesService);
    accessoriesService.updateQuantity(parentId, productId, quantity);
    eventTracking.logUserEvent(
        'System Components',
        'Update recording accessory quantity',
        `${productId}`,
        quantity,
    );
};

const deleteItem = (parentId: Id, productId: PiaId) => {
    const accessoriesService = ServiceLocator.get(RecordingAccessoriesService);
    accessoriesService.delete(parentId, productId);
    eventTracking.logUserEvent('System Components', 'Delete recording accessory', `${productId}`);
};

export const AccessoryItemContainer: React.FunctionComponent<IRecordingItemProps> = ({
    piaItem,
    parentId,
    isDiscontinued,
    isRecommended,
    quantity,
    maxQuantity,
    isLocked,
    canAddAccessory,
}) => {
    return (
        <Box
            direction="column"
            flex="fullWidth"
            paddingY="half"
            paddingX="base"
            color={!isLocked && quantity && quantity > 0 ? 'yellow1' : undefined}
            minHeight="56px"
        >
            <Stack justifyContent="between" alignItems="center">
                <Stack>
                    <PiaImage imageSize="lg" piaId={piaItem.id} icon="category" />
                    <Text>{piaItem.name}</Text>
                    {isDiscontinued && <Badge label={t.badgeDiscontinued} type="red" compact />}
                    {isRecommended && <Badge label={t.recommended} type="green" compact />}
                </Stack>
                <Stack alignItems="center" spacing="doublePanel">
                    <PiaItemMsrp piaId={piaItem.id} includeMultipacks />
                    <Spacer />
                    {quantity && quantity > 0 && (
                        <Quantity
                            min={1}
                            max={maxQuantity}
                            value={quantity}
                            disabled={isLocked}
                            onDelete={() => parentId && deleteItem(parentId, piaItem.id)}
                            onChange={(value) =>
                                parentId && updateQuantity(parentId, piaItem.id, value)
                            }
                        />
                    )}
                    {!isLocked && !quantity && (
                        <Button
                            disabled={!canAddAccessory}
                            onClick={() => parentId && addItem(parentId, piaItem.id)}
                        >
                            {t.add}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

export const AccessoryItem = connect(mapStateToProps)(AccessoryItemContainer);
AccessoryItemContainer.displayName = 'AccessoryItem';
