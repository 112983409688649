import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { EditContactDetailsComponent } from './EditContactDetails.component';
import { eventTracking } from 'app/core/tracking';
import { CommonActionService } from '../services';
import { getUserSignedIn } from '../authentication';
import { getContactDetails } from '../selectors';

interface IEditContactDetailsOwnProps {
    onSaved(): void;
    onCancel(): void;
}

interface IEditContactDetailsProps {
    userApiAddress: string;
    address: string;
    isSignedIn?: boolean;
    onSaved(): void;
    onCancel(): void;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IEditContactDetailsOwnProps,
): IEditContactDetailsProps => {
    const { address, userApiAddress } = getContactDetails(storeState);

    return {
        ...ownProps,
        isSignedIn: getUserSignedIn(storeState),
        userApiAddress,
        address,
        onSaved: ownProps.onSaved,
        onCancel: ownProps.onCancel,
    };
};

class Container extends React.Component<IEditContactDetailsProps> {
    private commonActionService: CommonActionService;

    constructor(props: IEditContactDetailsProps) {
        super(props);
        this.commonActionService = ServiceLocator.get(CommonActionService);
    }

    public render() {
        const { address, userApiAddress } = this.props;
        return (
            <EditContactDetailsComponent
                address={address}
                userApiAddress={userApiAddress}
                onSave={this.onSave}
                onCancel={this.props.onCancel}
            />
        );
    }

    private onSave = (address: string) => {
        eventTracking.logUserEvent(
            'User',
            'Edit Contact Details Saved',
            String(this.props.isSignedIn),
        );
        this.commonActionService.saveContactDetails({ address });
        this.props.onSaved();
    };
}

export const EditContactDetails = connect(mapStateToProps)(Container);
