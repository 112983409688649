import { injectable } from 'inversify';
import type {
    Id,
    IPersistence,
    IItemEntity,
    IItem,
    IItemPropertiesEntity,
    IPartnerItem,
    IPartnerItemEntity,
    IApplicationItemEntity,
} from 'app/core/persistence';
import { ItemService, CurrentProjectService, PartnerItemService } from 'app/core/persistence';
import type { IApplicationItem, IPartnerProductResponse } from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import { isAxis } from 'app/modules/common';
import { AXIS_OBJECT_ANALYTICS, AXIS_PERIMETER_DEFENDER } from 'app/core/common';
import { FetchPartnerProductsCommunicator } from './FetchPartnerProducts.communicator';
import { IPiaApplication, PiaItemState } from 'app/core/pia';
import { groupBy } from 'lodash-es';

@injectable()
export class AddonSelectorService {
    constructor(
        private partnerItemService: PartnerItemService,
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private fetchPartnerProductsCommunicator: FetchPartnerProductsCommunicator,
    ) {}

    public async getAllPartnerProducts(
        key: string | null,
    ): Promise<IPartnerProductResponse | null> {
        const partnerProductsResponse =
            await this.fetchPartnerProductsCommunicator.fetchAllPartnerProducts(key);

        if (!partnerProductsResponse) {
            return null;
        }

        return partnerProductsResponse;
    }

    /** Toggles the application, returns true if the applications was added, otherwise false */
    public toggleApplication = async (
        parentDeviceId: Id,
        application: IApplicationItem,
        hasBothApdParams: boolean,
    ): Promise<boolean> => {
        eventTracking.logUserEvent('Application Selector', 'Toggle application');
        const isAxisApplication = isAxis(application.vendor);
        const itemId = await this.getExistingApplicationId(
            parentDeviceId,
            application.productId,
            isAxisApplication,
        );

        if (itemId) {
            // Delete
            isAxisApplication
                ? await this.deleteAxisApplication(itemId, application.acapId, parentDeviceId)
                : await this.partnerItemService.deleteItem(itemId);
            return false;
        } else {
            // Add
            isAxisApplication
                ? await this.addAxisApplication(parentDeviceId, application, hasBothApdParams)
                : await this.addPartnerApplication(parentDeviceId, application);
            return true;
        }
    };

    public convertApplicationLicenseType = async (
        persistedApplications: IPersistence<IApplicationItemEntity>[],
        compatibleApplications: IPiaApplication[],
        isELicensePreferred: boolean,
    ) => {
        const applicationsGroupedByACAP = groupBy(compatibleApplications, 'properties.ACAPId');
        const persistedConvertibleApplications = persistedApplications.filter(
            (application) =>
                applicationsGroupedByACAP[application.properties.application.acapId].length > 1,
        );

        const convertedIds = persistedConvertibleApplications
            .filter(
                (application) =>
                    application.properties.application.isELicense !== isELicensePreferred,
            )
            .map(async (oldApplication) => {
                const newApplication = compatibleApplications
                    .filter(
                        (application) => application.properties.isELicense === isELicensePreferred,
                    )
                    .find(
                        (application) =>
                            application.properties.ACAPId ===
                            oldApplication.properties.application.acapId,
                    );

                if (newApplication) {
                    return this.changeApplication(oldApplication._id, newApplication);
                }
            });

        await Promise.all(convertedIds);
    };

    private toApplicationPropertiesEntity(application: IPiaApplication) {
        return {
            application: {
                officialFullName: application.name,
                productId: application.id,
                discontinued: application.state > PiaItemState.EXTERNALLY_ANNOUNCED ?? false,
                isIncluded: false,
                versions: application.versions,
                acapId: application.properties.ACAPId,
                isELicense: application.properties.isELicense,
                vendorName: application.properties.vendor,
            },
        };
    }

    private changeApplication(id: string, piaApplication: IPiaApplication) {
        const properties = this.toApplicationPropertiesEntity(piaApplication);
        return this.itemService.updateItem(id, {
            productId: piaApplication.id,
            properties,
        });
    }

    private async deleteAxisApplication(
        itemId: string,
        applicationId: number | undefined,
        parentDeviceId: string,
    ) {
        await this.itemService.deleteItem(itemId);
        if (applicationId === AXIS_OBJECT_ANALYTICS) {
            await this.itemService.clearAOAAnalyticRange(parentDeviceId);
        } else if (applicationId === AXIS_PERIMETER_DEFENDER) {
            await this.itemService.clearAPDAnalyticRange(parentDeviceId);
        } else {
            await this.itemService.setAnalyticRangeApplicationId(parentDeviceId, undefined);
        }
    }

    private async addAxisApplication(
        parentDeviceId: string,
        application: IApplicationItem,
        hasBothApdParams: boolean,
    ) {
        await this.addApplication(parentDeviceId, application);
        application.acapId === AXIS_OBJECT_ANALYTICS ||
            (application.acapId === AXIS_PERIMETER_DEFENDER &&
                (await this.itemService.addAnalyticRangeValues(
                    parentDeviceId,
                    application.acapId,
                    hasBothApdParams,
                )));
    }

    private async addApplication(
        parentId: Id,
        application: IApplicationItem,
    ): Promise<IPersistence<IItemEntity>> {
        const item: IItem = {
            name: '',
            description: '',
            notes: '',
            productId: application.productId,
            quantity: 1,
            properties: this.toApplicationProperties(application),
        };

        const persistedItem = await this.itemService.addByParentId(parentId, item);
        await this.itemService.addItemRelation(parentId, persistedItem._id, 'acap');

        return persistedItem;
    }

    private async addPartnerApplication(
        parentId: Id,
        application: IApplicationItem,
    ): Promise<IPersistence<IPartnerItemEntity>> {
        const item: IPartnerItem = {
            name: application.name,
            productId: application.productId,
            vendor: application.vendor,
            url: application.url ?? '',
            vendorId: application.vendorId ?? 0,
            quantity: 1,
            properties: { application: {} },
        };
        eventTracking.logUserEvent(
            'Application Selector',
            'Add partner application',
            application.name,
        );
        const persistedItem = await this.partnerItemService.addByParentId(parentId, item);
        await this.partnerItemService.addItemRelation(parentId, persistedItem._id, 'partnerAcap');

        return persistedItem;
    }

    private toApplicationProperties(application: IApplicationItem): IItemPropertiesEntity {
        return {
            application: {
                officialFullName: application.name,
                productId: application.productId,
                discontinued: application.discontinued ?? false,
                isIncluded: application.isIncluded ?? false,
                versions: application?.versions ?? [],
                acapId: application.acapId ?? 0,
                isELicense: application.isELicense ?? false,
                vendorName: application.vendor,
            },
        };
    }

    private getExistingApplicationId = async (
        parentId: Id,
        productId: number,
        isItem: boolean,
    ): Promise<string | undefined> => {
        const relations = this.currentProjectService.getItemRelations(parentId);
        const acapItems = relations
            .filter((rel) =>
                isItem ? rel.relationType === 'acap' : rel.relationType === 'partnerAcap',
            )
            .map((acapRel) =>
                isItem
                    ? this.currentProjectService.getEntity(acapRel.childId, 'item')
                    : this.currentProjectService.getEntity(acapRel.childId, 'partnerItem'),
            );
        const childRelationItem = acapItems.find((rel) => rel.productId === productId);
        return childRelationItem?._id ? childRelationItem?._id : undefined;
    };
}
