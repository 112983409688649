import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    Modal,
    Box,
    Stack,
    Border,
    Icon,
    TextInput,
    Grid,
    PillButton,
    Button,
    Text,
} from 'app/components';
import { CategoryGroupsEnum } from '../services/CategoryGroups.service';
import { ServiceLocator } from 'app/ioc';
import { PartnerConfigActionService } from '../services';
import type { ISearchFilter, ISelectableProduct } from '../models';
import { getSearchResult, getSearchResultAllExceptCategory } from '../selectors/getSearchResults';
import { DeviceItem } from './DeviceItem.component';

interface IEditProductsModalProps {
    setShowModal(show: boolean): void;
}

export const EditProductsModal: React.FC<IEditProductsModalProps> = ({ setShowModal }) => {
    const [actions] = React.useState(ServiceLocator.get(PartnerConfigActionService));
    const searchFilter = useSelector<IStoreState, ISearchFilter>(
        (state) => state.partnerConfig.searchFilter,
    );
    const selectableProducts = useSelector<IStoreState, ISelectableProduct[]>(getSearchResult);
    const searchResultAllOthers = useSelector<IStoreState, ISelectableProduct[]>(
        getSearchResultAllExceptCategory,
    );

    const setSearchText = (text?: string) => {
        actions.setSearchFilter({ searchText: text ?? '' });
    };

    const setSearchCategory = (category: CategoryGroupsEnum) => () => {
        actions.setSearchFilter({ category });
    };

    return (
        <Modal
            onClose={() => {
                setShowModal(false);
                setSearchText();
            }}
            color="white"
            height="728px"
        >
            <Box height="100%">
                <Stack vertical>
                    <Box paddingX="base" width="100%">
                        <Border bottomWidth={2} color="black">
                            <Box width="100%">
                                <Stack flex="fullWidth" spacing="half">
                                    <Icon opaque icon="search" color="black" />
                                    <Box display="block" width="100%">
                                        <Text inline style="subheader">
                                            <TextInput
                                                autoFocus
                                                noBorder
                                                grow
                                                clear={setSearchText}
                                                changeCriteria="debounced"
                                                placeholder={t.searchForModel}
                                                value={searchFilter.searchText}
                                                onChange={setSearchText}
                                            />
                                        </Text>
                                    </Box>
                                </Stack>
                            </Box>
                        </Border>
                    </Box>
                    <Box paddingX="base">
                        <Grid spacing="quart">
                            <PillButton
                                checkable
                                selected={searchFilter.category === CategoryGroupsEnum.Cameras}
                                text={t.cameras}
                                onClick={setSearchCategory(CategoryGroupsEnum.Cameras)}
                            />
                            <PillButton
                                checkable
                                text={t.fSeries}
                                selected={searchFilter.category === CategoryGroupsEnum.Modulars}
                                onClick={setSearchCategory(CategoryGroupsEnum.Modulars)}
                            />
                            <PillButton
                                checkable
                                selected={
                                    searchFilter.category === CategoryGroupsEnum.EncodersDecoders
                                }
                                text={t.allowlistCategoryEncodersDecoders}
                                onClick={setSearchCategory(CategoryGroupsEnum.EncodersDecoders)}
                            />
                            <PillButton
                                checkable
                                text={t.speakers}
                                selected={searchFilter.category === CategoryGroupsEnum.Audio}
                                onClick={setSearchCategory(CategoryGroupsEnum.Audio)}
                            />
                            <PillButton
                                checkable
                                text={t.accessControl}
                                selected={searchFilter.category === CategoryGroupsEnum.Pacs}
                                onClick={setSearchCategory(CategoryGroupsEnum.Pacs)}
                            />
                            <PillButton
                                checkable
                                text={t.wearables}
                                selected={searchFilter.category === CategoryGroupsEnum.Wearables}
                                onClick={setSearchCategory(CategoryGroupsEnum.Wearables)}
                            />
                            <PillButton
                                checkable
                                text={t.recorders}
                                selected={searchFilter.category === CategoryGroupsEnum.Recorders}
                                onClick={setSearchCategory(CategoryGroupsEnum.Recorders)}
                            />
                            <PillButton
                                checkable
                                text={t.software}
                                selected={searchFilter.category === CategoryGroupsEnum.Software}
                                onClick={setSearchCategory(CategoryGroupsEnum.Software)}
                            />
                            <PillButton
                                checkable
                                text={t.other}
                                selected={searchFilter.category === CategoryGroupsEnum.Others}
                                onClick={setSearchCategory(CategoryGroupsEnum.Others)}
                            />
                        </Grid>
                    </Box>
                    <Stack vertical lineBetweenColor="blackOpacity" spacing="none">
                        {selectableProducts.map((product) => (
                            <DeviceItem
                                key={product.id}
                                piaId={product.id}
                                selected={product.selected}
                            >
                                <Box paddingRight="base">
                                    <Button
                                        primary={!product.selected}
                                        onClick={() =>
                                            product.selected
                                                ? actions.removeFromAllowList(product.id)
                                                : actions.addToAllowList(product.id)
                                        }
                                    >
                                        {product.selected ? t.remove : t.add}
                                    </Button>
                                </Box>
                            </DeviceItem>
                        ))}
                        {!selectableProducts.length && searchResultAllOthers.length > 0 && (
                            <Box padding="base">
                                <Text italic>{t.searchResultsMissingFoundOthers}</Text>
                            </Box>
                        )}
                        {selectableProducts.length > 0 && searchResultAllOthers.length > 0 && (
                            <Box padding="base">
                                <Text italic>{t.searchResultsOthers}</Text>
                            </Box>
                        )}
                        {searchResultAllOthers.map((product) => (
                            <DeviceItem
                                key={product.id}
                                piaId={product.id}
                                selected={product.selected}
                            >
                                <Box paddingRight="base">
                                    <Button
                                        primary={!product.selected}
                                        onClick={() =>
                                            product.selected
                                                ? actions.removeFromAllowList(product.id)
                                                : actions.addToAllowList(product.id)
                                        }
                                    >
                                        {product.selected ? t.remove : t.add}
                                    </Button>
                                </Box>
                            </DeviceItem>
                        ))}
                        {!selectableProducts.length && !searchResultAllOthers.length && (
                            <Box padding="base">
                                <Text italic>{t.searchResultsMissing}</Text>
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
};

EditProductsModal.displayName = 'EditProductsModal';
