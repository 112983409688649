import * as React from 'react';
import { useSelector } from 'react-redux';
import { Border, Box, DropDown, DropDownMenuButton, Icon, IconText, NoPrint } from 'app/components';
import { getMapsSortedByType, getSelectedMapOrDefault, getInitialTabMap } from '../../selectors';
import { useFindOverflowingIndex } from './useFindOverflowingIndex.hook';
import { MapTabItem } from './MapTabItem';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../services';
import type { IFloorPlanEntity, Id } from 'app/core/persistence';
import { AddFloorPlanButton } from './AddFloorPlanButton';
import { t } from 'app/translate';
import { useMount } from 'app/hooks';
import { HelpMaps } from '../help/HelpMaps';

export const MapTabs: React.FC = () => {
    const mapsActionService = useService(MapsActionService);
    const initialMap = useSelector(getInitialTabMap);
    const selected = useSelector(getSelectedMapOrDefault);
    const floorPlans = useSelector(getMapsSortedByType);

    const setSelectedMap = React.useCallback(
        (id: Id) => {
            mapsActionService.setSelectedMap(id);
            mapsActionService.goToLatestViewBounds(id);
        },
        [mapsActionService],
    );

    useMount(() => {
        if (initialMap?._id) {
            setSelectedMap(initialMap?._id);
        }
    });

    const container = React.useRef<HTMLDivElement>(null);
    const overflowingTabIndex = useFindOverflowingIndex(
        container,
        floorPlans.map((fp) => fp.name || t.map),
        floorPlans.indexOf(selected),
    );
    const visibleTabs =
        overflowingTabIndex > 0 ? floorPlans.slice(0, overflowingTabIndex) : floorPlans;
    const overflowingTabs = overflowingTabIndex > 0 ? floorPlans.slice(overflowingTabIndex) : [];
    const isSelectedTabVisible =
        overflowingTabIndex > 0 ? floorPlans.indexOf(selected) < overflowingTabIndex : true;

    const getIcon = (floorPlan: IFloorPlanEntity) =>
        !floorPlan.name
            ? 'satellite'
            : floorPlan.mapType === 'FloorPlan'
              ? floorPlan.image?.geoLocation
                  ? 'floorplan_geo'
                  : 'floorplan'
              : 'place';

    if (!floorPlans.length) return <Box />;

    return (
        <NoPrint>
            <Border bottomWidth={1} color="grey3">
                <Box innerRef={container} color="grey1" paddingTop="half" paddingLeft="quart">
                    {visibleTabs.map((floorPlan) => {
                        const isActive = floorPlan._id === selected?._id;
                        return (
                            <MapTabItem
                                testId={`map_tab_${floorPlan.name || t.map}`}
                                key={floorPlan._id}
                                floorPlan={floorPlan}
                                text={floorPlan.name || t.map}
                                icon={getIcon(floorPlan)}
                                isActive={isActive}
                                isDefault={floorPlan.isDefault}
                                onClick={() => setSelectedMap(floorPlan._id)}
                            />
                        );
                    })}
                    {overflowingTabIndex > 0 && (
                        <DropDown
                            trigger={
                                <MapTabItem
                                    testId={
                                        isSelectedTabVisible
                                            ? 'map_tab_more'
                                            : `map_tab_${selected.name}`
                                    }
                                    isActive={!isSelectedTabVisible}
                                    text={
                                        isSelectedTabVisible ? (
                                            <Icon
                                                icon="keyboard_arrow_down"
                                                color="grey7"
                                                size="ms"
                                            />
                                        ) : (
                                            <IconText alignIconRight icon="keyboard_arrow_down">
                                                {selected.name}
                                            </IconText>
                                        )
                                    }
                                    icon="more_horiz"
                                />
                            }
                            contents={overflowingTabs.map((floorPlan) => (
                                <DropDownMenuButton
                                    testId={`map_tab_drop_down_${floorPlan.name}`}
                                    key={floorPlan._id}
                                    selected={floorPlan._id === selected?._id}
                                    icon={getIcon(floorPlan)}
                                    onClick={() => setSelectedMap(floorPlan._id)}
                                >
                                    {floorPlan.name}
                                </DropDownMenuButton>
                            ))}
                        />
                    )}
                    <AddFloorPlanButton />
                    <HelpMaps />
                </Box>
            </Border>
        </NoPrint>
    );
};

MapTabs.displayName = 'MapTabs';
