import { getIdFromProps, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { getSelectedMapItem, getDerotatedInstallationPoints } from './getProjectInstallationPoints';

export const getParentLocation = createCachedSelector(
    [getDerotatedInstallationPoints, getSelectedMapItem, getIdFromProps],
    (installationPoints, selectedMapItem, parentInstallationPointId) => {
        if (!parentInstallationPointId) return undefined;

        const parentInstallationPoint = installationPoints[parentInstallationPointId];

        return selectedMapItem?.id === parentInstallationPointId
            ? selectedMapItem.location
            : parentInstallationPoint?.location;
    },
)(toCacheKey);
