import { createSelector } from 'reselect';
import {
    CategoryEnum,
    getPiaItemsRecord,
    getCurrentProjectItemsArray,
    productFilters,
} from 'app/modules/common';
import type { IPersistence, IItemEntity } from 'app/core/persistence';
import { isAccessory, deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaItem, PiaCategory, PiaId } from 'app/core/pia';
import { PiaItemSoftwareCategory } from 'app/core/pia';
import type { IDocumentationDevice } from '..';
import type { IStoreState } from 'app/store';
import { nameComparator } from 'app/utils';

export const getDeviceFilter = (state: IStoreState) => state.documentation.searchFilter;
const getAccessoriesWithDataSheets = (state: IStoreState) =>
    state.documentation.accessories.accessoriesWithDataSheets;

const searchFilter =
    (searchStr: string) =>
    ({ name }: IDocumentationDevice) =>
        name.toLowerCase().includes(searchStr.toLowerCase());

const undesiredDocumentationCategories = new Set<PiaCategory>([PiaItemSoftwareCategory.VMS]);

export const getSelectedAccessoryIds = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItemsRecord): PiaId[] =>
        items.reduce((accessoryIds: PiaId[], item) => {
            if (item.productId === null || !isAccessory(piaItemsRecord[item.productId]))
                return accessoryIds;
            return [...accessoryIds, item.productId];
        }, []),
);

const getDocumentationDevices = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord, getAccessoriesWithDataSheets],
    (devices, piaProductsRecord, accessoriesWithDataSheets) =>
        devices
            .filter(
                ({ productId }) =>
                    productId &&
                    !undesiredDocumentationCategories.has(piaProductsRecord[productId].category) &&
                    productFilters.isVendorAxis(piaProductsRecord[productId]?.properties.vendor),
            )
            .filter(
                (device) =>
                    // Filter out accessories unless they have verified data sheets
                    device.productId !== null &&
                    (!isAccessory(piaProductsRecord[device.productId]) ||
                        accessoriesWithDataSheets.has(device.productId)) &&
                    !device.properties.lens &&
                    !device.properties.environment &&
                    !deviceTypeCheckers.isVirtualProduct(device),
            )
            .map((device) => {
                return createDocumentationDevice(device, piaProductsRecord[device.productId!]);
            })
            .sort(nameComparator),
);

const createDocumentationDevice = (
    item: IPersistence<IItemEntity>,
    piaProduct: IPiaItem,
): IDocumentationDevice => ({
    id: piaProduct.id,
    name: piaProduct.name,
    category: getCategoryEnum(item),
});

function getCategoryEnum(item: IPersistence<IItemEntity>): CategoryEnum {
    if (item.properties.camera && item.properties.pac) {
        return CategoryEnum.DoorStation;
    } else if (item.properties.camera) {
        return CategoryEnum.Camera;
    } else if (item.properties.encoder) {
        return CategoryEnum.Encoder;
    } else if (item.properties.analogCamera) {
        return CategoryEnum.AnalogCamera;
    } else if (item.properties.mainUnit || item.properties.sensorUnit) {
        return CategoryEnum.FSeries;
    } else if (item.properties.speaker) {
        return CategoryEnum.Speaker;
    } else if (item.properties.decoder) {
        return CategoryEnum.Decoder;
    } else if (item.properties.pac || item.properties.doorController) {
        return CategoryEnum.Pac;
    } else if (item.properties.radarDetector) {
        return CategoryEnum.RadarDetector;
    } else if (
        item.properties.accessory ||
        item.properties.systemAccessory ||
        item.properties.lens
    ) {
        return CategoryEnum.Accessory;
    } else if (item.properties.application) {
        return CategoryEnum.Software;
    } else if (item.properties.systemComponent) {
        return CategoryEnum.RecordingAndNetwork;
    } else if (item.properties.peopleCounter) {
        return CategoryEnum.PeopleCounters;
    } else if (deviceTypeCheckers.isWearable(item) || deviceTypeCheckers.isCameraExtension(item)) {
        return CategoryEnum.Wearables;
    } else if (deviceTypeCheckers.isAlerter(item)) {
        return CategoryEnum.Alerters;
    }
    return CategoryEnum.Miscellaneous;
}

const getVisibleDocumentationDevices = createSelector(
    [getDocumentationDevices, getDeviceFilter],
    (devices, filterStr) =>
        devices
            .filter(
                (deviceItem, index) =>
                    devices.map((item) => item.id).indexOf(deviceItem.id) === index,
            )
            .filter(searchFilter(filterStr)),
);

export const getSortedDocumentationDevicesLength = createSelector(
    [getVisibleDocumentationDevices],
    (devices) => devices.length,
);

export const getVisibleDevicesId = createSelector([getVisibleDocumentationDevices], (devices) =>
    devices.map((device) => device.id),
);

export const getDocumentationDevicesRecord = createSelector(
    [getVisibleDocumentationDevices],
    (devices) => {
        return devices.reduce(
            (devicesByCategory, item) => {
                devicesByCategory[item.category] = devicesByCategory[item.category]
                    ? [...devicesByCategory[item.category], item]
                    : [item];
                return devicesByCategory;
            },
            {} as Record<CategoryEnum, IDocumentationDevice[]>,
        );
    },
);

export const getSelectedDocumentationPiaIds = (state: IStoreState) =>
    state.documentation.selectedPiaIds;

/**
 * Get selected pia ids for products instead of product variants.
 * If none are selected, all items are returned instead.
 */
export const getSelectedDocumentationProductIds = createSelector(
    [getPiaItemsRecord, getSelectedDocumentationPiaIds, getDocumentationDevices],
    (piaItems, selectedPiaIds, documentationDevices) => {
        if (selectedPiaIds.length > 0) {
            return selectedPiaIds.map((piaId) => piaItems[piaId].parentId);
        }

        return documentationDevices.map(
            (documentationDevice) => piaItems[documentationDevice.id].parentId,
        );
    },
);
