import { injectable } from 'inversify';
import type {
    IBodyWornCameraItemEntity,
    ICameraExtensionItemEntity,
    IDockingStationItemEntity,
    IItemEntity,
    IPersistence,
    ISystemControllerItemEntity,
} from 'app/core/persistence';
import {
    isBodyWornCamera,
    isCameraExtension,
    isDockingStation,
    isSystemController,
    deviceTypeCheckers,
} from 'app/core/persistence';
import type { IInstallationReportWearableDevice } from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum } from 'app/modules/common';
import type {
    IPiaBodyWornCamera,
    IPiaCameraExtension,
    IPiaDockingStation,
    IPiaItem,
    IPiaSystemController,
} from 'app/core/pia';

@injectable()
export class WearablesDeviceService {
    constructor(private baseDeviceService: BaseDeviceService) {}

    public async mapDevice(
        item: IPersistence<IItemEntity>,
        piaItem: IPiaItem,
    ): Promise<IInstallationReportWearableDevice | null> {
        if (deviceTypeCheckers.isBodyWornCamera(item) && isBodyWornCamera(piaItem)) {
            return this.mapBodyWornCamera(item, piaItem);
        }
        if (deviceTypeCheckers.isCameraExtension(item) && isCameraExtension(piaItem)) {
            return this.mapCameraExtension(item, piaItem);
        }
        if (deviceTypeCheckers.isDockingStation(item) && isDockingStation(piaItem)) {
            return this.mapDockingStation(item, piaItem);
        }
        if (deviceTypeCheckers.isSystemController(item) && isSystemController(piaItem)) {
            return this.mapSystemController(item, piaItem);
        }
        return null;
    }

    public async mapBodyWornCamera(
        item: IPersistence<IBodyWornCameraItemEntity>,
        piaItem: IPiaBodyWornCamera,
    ): Promise<IInstallationReportWearableDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Wearables,
        };
    }
    public async mapCameraExtension(
        item: IPersistence<ICameraExtensionItemEntity>,
        piaItem: IPiaCameraExtension,
    ): Promise<IInstallationReportWearableDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Wearables,
        };
    }
    public async mapDockingStation(
        item: IPersistence<IDockingStationItemEntity>,
        piaItem: IPiaDockingStation,
    ): Promise<IInstallationReportWearableDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Wearables,
        };
    }
    public async mapSystemController(
        item: IPersistence<ISystemControllerItemEntity>,
        piaItem: IPiaSystemController,
    ): Promise<IInstallationReportWearableDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Wearables,
        };
    }
}
