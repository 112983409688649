import * as React from 'react';
import type { IDropDownMenuItemProps } from './DropDownMenuItem.component';
import { DropDownMenuItem } from './DropDownMenuItem.component';
import { Clickable } from '../../functional';
import type { IAutoTestable } from '../../ui-test';
import type { IExtendableComponent } from 'app/components/models';

export interface IDropDownMenuButtonProps
    extends IDropDownMenuItemProps,
        IAutoTestable,
        IExtendableComponent {
    /**
     * Prevents the click event from bubbling.
     */
    stopPropagation?: boolean;
    /**
     * The text label of the item
     */
    label?: string;
    /**
     * The onClick callback function for the contents (ie. second drop down item)
     */
    onClick(event: React.SyntheticEvent): void;
}

/**
 * A drop down menu item which when clicked will display its children.
 * Useful for delete operations where the user has to click twice (the second
 * time to confirm the delete)
 */
export class DropDownMenuButton extends React.PureComponent<IDropDownMenuButtonProps> {
    public render() {
        const { onClick, stopPropagation, label, children, ...props } = this.props;
        return (
            <Clickable
                stopPropagation={stopPropagation}
                onClick={onClick}
                disabled={props.disabled}
            >
                <DropDownMenuItem {...props}>{label ?? children}</DropDownMenuItem>
            </Clickable>
        );
    }
}
