import type { IPiaPartnerItem } from 'app/core/pia';
import { t } from 'i18next';
import type { IPartnerProductFilter } from '../models';
import { getUseCaseTranslationKey } from '../utils';

export class PartnerProductFilter {
    public static filter(
        allPartnerItems: IPiaPartnerItem[],
        filter: IPartnerProductFilter,
    ): IPiaPartnerItem[] {
        const lowerCaseSearchFilter = filter.searchFilter.toLowerCase();

        return allPartnerItems.filter((partnerProduct) => {
            if (
                filter.solutionType &&
                !partnerProduct.properties.solutionType.includes(filter.solutionType)
            ) {
                return false;
            }
            if (filter.useCase && !partnerProduct.properties.useCases.includes(filter.useCase)) {
                return false;
            }

            return (
                partnerProduct.name.toLowerCase().includes(lowerCaseSearchFilter) ||
                partnerProduct.properties.vendor.toLowerCase().includes(lowerCaseSearchFilter) ||
                partnerProduct.properties.useCases.some((useCase) =>
                    t(getUseCaseTranslationKey(useCase), { ns: 'partnerProducts' })
                        .toLowerCase()
                        .includes(lowerCaseSearchFilter),
                )
            );
        });
    }
}
