import { injectable } from 'inversify';
import { debounce } from 'lodash-es';
import { ActionCreator, IAction } from 'app/store';
import { PersistenceActions } from '../PersistenceActions';
import { callWhenArgsChange, getItemLocalStorageJSON, setItemLocalStorage } from '../utils';

export type SyncStatus = 'in_sync' | 'syncing' | 'not_in_sync' | 'off' | 'sync_failed';

interface ISyncState {
    lastChangeTimestamp: number;
    lastSyncTimestamp: number;
}

const SYNC_CHECK_TIMEOUT = 5 * 1000;
const RECENCY_THRESHOLD = 7 * 24 * 60 * 60 * 1000; // one week

const initialSyncState = {
    lastChangeTimestamp: 0,
    lastSyncTimestamp: 0,
};

@injectable()
export class SyncService {
    private lastChangeTimestamp: number;
    private lastSyncTimestamp: number;

    @ActionCreator()
    private updateSyncStatus(payload: SyncStatus): IAction<SyncStatus> {
        return {
            type: PersistenceActions.UpdateSyncStatus,
            payload,
        };
    }

    @ActionCreator()
    private updateSyncDateTime(payload: number): IAction<number> {
        return {
            type: PersistenceActions.UpdateSyncDateTime,
            payload,
        };
    }

    // dispatch an action when the value of inSync changes
    private dispatchInSyncAction = callWhenArgsChange(this.updateSyncStatus);

    // debounced dispatch of action to set the timestamp of the last sync
    private dispatchLastSyncDateTimeAction = debounce(this.updateSyncDateTime, 1000);

    private checkSyncStatus = () => {
        const lastChangeWasSynced = this.lastSyncTimestamp >= this.lastChangeTimestamp;
        const lastSyncWasRecent = this.lastSyncTimestamp + RECENCY_THRESHOLD >= Date.now();
        const neverSynced = this.lastSyncTimestamp === 0;
        const syncStatus: SyncStatus = lastChangeWasSynced
            ? 'in_sync'
            : lastSyncWasRecent || neverSynced
              ? 'not_in_sync'
              : 'sync_failed';

        this.dispatchInSyncAction(syncStatus);
    };

    private checkSyncStatusDebounced = debounce(this.checkSyncStatus, SYNC_CHECK_TIMEOUT);

    constructor() {
        const loadedSyncStatus = this.load();

        this.lastSyncTimestamp = loadedSyncStatus.lastSyncTimestamp;
        this.lastChangeTimestamp = loadedSyncStatus.lastChangeTimestamp;

        this.dispatchLastSyncDateTimeAction(this.lastSyncTimestamp);
        this.checkSyncStatus();
    }

    // called when a local change has been made
    public onLocalChange() {
        this.lastChangeTimestamp = Date.now();
        this.persist();

        this.checkSyncStatusDebounced();
        this.dispatchInSyncAction('syncing');
    }

    // called when sync has been completed
    public onSync() {
        this.lastSyncTimestamp = Date.now();
        this.persist();

        this.checkSyncStatus();
        this.dispatchLastSyncDateTimeAction(this.lastSyncTimestamp);
    }

    // persist current sync status
    private persist() {
        setItemLocalStorage(
            'LatestSyncInfo',
            JSON.stringify({
                lastChangeTimestamp: this.lastChangeTimestamp,
                lastSyncTimestamp: this.lastSyncTimestamp,
            }),
        );
    }

    // load current sync status
    private load(): ISyncState {
        const syncState = getItemLocalStorageJSON('LatestSyncInfo', {});

        try {
            return {
                ...initialSyncState,
                ...syncState,
            };
        } catch {
            return initialSyncState;
        }
    }
}
