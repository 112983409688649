import * as React from 'react';
import { useSelector } from 'react-redux';
import type { Id } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import type { IBandwidthStorageRequirementsModel } from 'app/modules/common';
import {
    getDeviceBandwidthPerDevice,
    getDeviceCount,
    StorageBandwidthInfo,
} from 'app/modules/common';

import { Box } from 'app/components';

interface IBandwidthInfoProps {
    deviceId: Id;
}

export const BandwidthInfo: React.FC<IBandwidthInfoProps> = ({ deviceId }) => {
    const bandwidthEstimate = useSelector<
        IStoreState,
        IBandwidthStorageRequirementsModel | undefined
    >((state) => getDeviceBandwidthPerDevice(state, deviceId));

    const cameraCount = useSelector<IStoreState, number>((state) =>
        getDeviceCount(state, deviceId),
    );

    const storage = bandwidthEstimate?.formattedStorage;
    return (
        <>
            <Box justifyContent="end" alignItems="center">
                <StorageBandwidthInfo
                    hasLiveView={false}
                    formattedStorage={storage}
                    cameraCount={cameraCount}
                    disabledCustomBandwidth={false}
                />
            </Box>
        </>
    );
};
