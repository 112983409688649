import { trigonometry } from 'axis-webtools-util';
import type { IPiaDevice, IPiaCamera, IPiaSensorUnit } from 'app/core/pia';

export const isMultisensor = (
    piaProduct: IPiaDevice | null,
): piaProduct is IPiaCamera | IPiaSensorUnit =>
    piaProduct !== null &&
    'panCameraType' in piaProduct.properties &&
    piaProduct.properties.panCameraType === 'Multisensor';

export const isMultiDirectional = (piaProduct: IPiaDevice | null) =>
    piaProduct !== null &&
    'panCameraType' in piaProduct.properties &&
    piaProduct.properties.panCameraType === 'Multidirectional';

export const hasMultipleSensors = (piaProduct: IPiaDevice | null) =>
    isMultisensor(piaProduct) || isMultiDirectional(piaProduct);

export const isFullPanoramic = (horizontalFovRad: number, verticalFovRad: number) =>
    horizontalFovRad >= trigonometry.toRadians(180) &&
    !isWideAnglePanoramic(horizontalFovRad, verticalFovRad);

export const isWideAnglePanoramic = (horizontalFovRad: number, verticalFovRad: number) =>
    horizontalFovRad >= trigonometry.toRadians(180) && verticalFovRad < trigonometry.toRadians(120);

export const isTiltableMultisensor = (piaProduct: IPiaDevice | null) =>
    isMultisensor(piaProduct) &&
    piaProduct.properties.maxHorizontalFOV >= 180 &&
    (piaProduct.properties.maxLensCalcFOV ?? piaProduct.properties.maxVerticalFOV) < 180;
