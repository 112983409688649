import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';
import type { IAcap } from '../../models/cameras/IAcap';
import { getCurrentAvailableAcaps } from '../../selectors/getAcaps';

const nameComparator = (a: IAcap, b: IAcap): number =>
    a.officialFullName.toLowerCase().localeCompare(b.officialFullName.toLowerCase());

export const ApplicationSupportFilter: React.FC = () => {
    const [actionService] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );
    const desiredApplications = useSelector<IStoreState, number[]>(
        (store) => store.deviceSelector.cameraFilter.desiredCamera.applications ?? [],
    );
    const allACAPs = useSelector<IStoreState, IAcap[]>((store) =>
        getCurrentAvailableAcaps(store).sort(nameComparator),
    );
    const isAcapSelected = (acapId: number) => {
        const acapItem = allACAPs.find((item) => {
            if (item.ACAPId === acapId) {
                return item;
            }
        });
        return (acapItem?.piaIds ?? []).some((id) => desiredApplications.includes(id));
    };
    const updateDesiredCamera = actionService.updateDesiredCamera;
    const updateApplications = (applicationId: number[]) => {
        applicationId.forEach((piaId) => {
            if (desiredApplications.includes(piaId)) {
                const removedItemArray = desiredApplications.filter(
                    (application) => application !== piaId,
                );
                updateDesiredCamera({
                    applications: removedItemArray,
                });
            } else {
                updateDesiredCamera({ applications: [...desiredApplications, piaId] });
            }
        });
    };

    return (
        <DropDown
            stayOpen
            openInPortal
            minWidth={270}
            maxWidth={350} //Necessary to prevent dropdown item from rendering beyond window screen when in RTL mode
            trigger={
                <PillButton
                    text={
                        desiredApplications.length > 0
                            ? `${t.cameraSelectorAcapSupport} \u00b7 ${desiredApplications.length}`
                            : t.cameraSelectorAcapSupport
                    }
                    icon="keyboard_arrow_down"
                    selected={desiredApplications.length > 0}
                />
            }
            contents={
                <>
                    {allACAPs.map((acap) => {
                        return (
                            <DropDownMenuButton
                                key={acap.ACAPId}
                                label={acap.officialFullName}
                                selected={isAcapSelected(acap.ACAPId)}
                                onClick={() => updateApplications(acap.piaIds)}
                            />
                        );
                    })}
                </>
            }
        />
    );
};

ApplicationSupportFilter.displayName = 'ApplicationSupportFilter';
