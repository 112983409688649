import { t } from 'app/translate';

enum WeekDays {
    Monday = 64,
    Tuesday = 32,
    Wednesday = 16,
    Thursday = 8,
    Friday = 4,
    Saturday = 2,
    Sunday = 1,
}

/**
 * The week object is an immutable representation of a schedules active days
 * during a week.
 */
export class ScheduleDays {
    private monday: boolean;
    private tuesday: boolean;
    private wednesday: boolean;
    private thursday: boolean;
    private friday: boolean;
    private saturday: boolean;
    private sunday: boolean;

    /**
     * Creates a new days object from either a persisted week
     * number or a days array.
     *
     * The number must be between 0 - 127 and the number's bit
     * representation will be used to determine which days are
     * active in the week where the most significant bit
     * (7th bit) is monday and the least significant bit
     * (first bit) is sunday.
     *
     * The days array consists of 7 booleans, where a boolean
     * set to true determines if a specific day is active. The
     * first index of the array is monday while the 7th index
     * (last index) is sunday.
     *
     * @param week number or days array
     */
    constructor(week: number | boolean[]) {
        if (typeof week === 'number') {
            this.monday = this.isDayActive(week, WeekDays.Monday);
            this.tuesday = this.isDayActive(week, WeekDays.Tuesday);
            this.wednesday = this.isDayActive(week, WeekDays.Wednesday);
            this.thursday = this.isDayActive(week, WeekDays.Thursday);
            this.friday = this.isDayActive(week, WeekDays.Friday);
            this.saturday = this.isDayActive(week, WeekDays.Saturday);
            this.sunday = this.isDayActive(week, WeekDays.Sunday);
        } else if (Array.isArray(week)) {
            this.monday = week[0] === true;
            this.tuesday = week[1] === true;
            this.wednesday = week[2] === true;
            this.thursday = week[3] === true;
            this.friday = week[4] === true;
            this.saturday = week[5] === true;
            this.sunday = week[6] === true;
        } else {
            throw new Error(`Invalid value passed to Days constructor: ${week}`);
        }
    }

    public hasMonday(): boolean {
        return this.monday;
    }

    public setMonday(value: boolean): ScheduleDays {
        return this.setDayAndGetNewDays(WeekDays.Monday, value);
    }

    public hasTuesday(): boolean {
        return this.tuesday;
    }

    public setTuesday(value: boolean): ScheduleDays {
        return this.setDayAndGetNewDays(WeekDays.Tuesday, value);
    }

    public hasWednesday(): boolean {
        return this.wednesday;
    }

    public setWednesday(value: boolean): ScheduleDays {
        return this.setDayAndGetNewDays(WeekDays.Wednesday, value);
    }

    public hasThursday(): boolean {
        return this.thursday;
    }

    public setThursday(value: boolean): ScheduleDays {
        return this.setDayAndGetNewDays(WeekDays.Thursday, value);
    }

    public hasFriday(): boolean {
        return this.friday;
    }

    public setFriday(value: boolean): ScheduleDays {
        return this.setDayAndGetNewDays(WeekDays.Friday, value);
    }

    public hasSaturday(): boolean {
        return this.saturday;
    }

    public setSaturday(value: boolean): ScheduleDays {
        return this.setDayAndGetNewDays(WeekDays.Saturday, value);
    }

    public hasSunday(): boolean {
        return this.sunday;
    }

    public setSunday(value: boolean): ScheduleDays {
        return this.setDayAndGetNewDays(WeekDays.Sunday, value);
    }

    /**
     * Returns a string represenation of the Days object.
     */
    public toString(): string {
        return [
            `Mon: ${this.hasMonday()}`,
            `Tue: ${this.hasTuesday()}`,
            `Wed: ${this.hasWednesday()}`,
            `Thu: ${this.hasThursday()}`,
            `Fri: ${this.hasFriday()}`,
            `Sat: ${this.hasSaturday()}`,
            `Sun: ${this.hasSunday()}`,
        ].join(', ');
    }

    /**
     * Returns the Days object as a persistable number.
     */
    public toPersistable(): number {
        return (
            (this.monday ? WeekDays.Monday : 0) |
            (this.tuesday ? WeekDays.Tuesday : 0) |
            (this.wednesday ? WeekDays.Wednesday : 0) |
            (this.thursday ? WeekDays.Thursday : 0) |
            (this.friday ? WeekDays.Friday : 0) |
            (this.saturday ? WeekDays.Saturday : 0) |
            (this.sunday ? WeekDays.Sunday : 0)
        );
    }

    private isDayActive(days: number, weekDay: number): boolean {
        return (days & weekDay) > 0;
    }

    private setDayAndGetNewDays(dayToSet: WeekDays, value: boolean): ScheduleDays {
        const daysArray = this.getDaysArray();

        switch (dayToSet) {
            case WeekDays.Monday:
                daysArray[0] = value;
                break;
            case WeekDays.Tuesday:
                daysArray[1] = value;
                break;
            case WeekDays.Wednesday:
                daysArray[2] = value;
                break;
            case WeekDays.Thursday:
                daysArray[3] = value;
                break;
            case WeekDays.Friday:
                daysArray[4] = value;
                break;
            case WeekDays.Saturday:
                daysArray[5] = value;
                break;
            case WeekDays.Sunday:
                daysArray[6] = value;
                break;
            default:
                throw new Error(`Unknown WeekDay value: ${dayToSet}`);
        }

        return new ScheduleDays(daysArray);
    }

    private getDaysArray(): boolean[] {
        return [
            this.monday,
            this.tuesday,
            this.wednesday,
            this.thursday,
            this.friday,
            this.saturday,
            this.sunday,
        ];
    }

    /**
     * Returns an string representation of the Days object.
     * Eg, if Mon, Tue, Wed, Sat, Sun, are active we get the string Mon - Wed, Sat - Sun
     * Eg. if Mon, Tue, Thu, Sun, are active we get the string Mon - Tue, Thu, Sun
     */
    public abbreviatedDaysString(): string {
        let index = 0;
        const days = this.getDaysArray();
        const dayGroups = new Array<{ dayGroupStart: number; dayGroupEnd: number }>();
        while (index < 7) {
            const dayGroupStart = days.indexOf(true, index);
            if (dayGroupStart === -1) {
                break;
            }

            let dayGroupEnd = dayGroupStart;
            index = dayGroupStart + 1;
            while (days.length > index && days[index++]) {
                dayGroupEnd++;
            }
            dayGroups.push({ dayGroupStart, dayGroupEnd });
        }

        const abbreviatedDays = dayGroups.map((dayGroup) =>
            dayGroup.dayGroupStart < dayGroup.dayGroupEnd
                ? `${this.indexToAbbreviatedDay(
                      dayGroup.dayGroupStart,
                  )} - ${this.indexToAbbreviatedDay(dayGroup.dayGroupEnd)}`
                : this.indexToAbbreviatedDay(dayGroup.dayGroupStart),
        );

        return abbreviatedDays.join(', ');
    }

    public isAnyDayActive(): boolean {
        return this.toPersistable() > 0;
    }

    private indexToAbbreviatedDay(dayIndex: number) {
        switch (dayIndex) {
            case 0:
                return t.dayAbbreviationsGROUP.monday;
            case 1:
                return t.dayAbbreviationsGROUP.tuesday;
            case 2:
                return t.dayAbbreviationsGROUP.wednesday;
            case 3:
                return t.dayAbbreviationsGROUP.thursday;
            case 4:
                return t.dayAbbreviationsGROUP.friday;
            case 5:
                return t.dayAbbreviationsGROUP.saturday;
            case 6:
                return t.dayAbbreviationsGROUP.sunday;
            default:
                throw new Error(`Unknown WeekDay value: ${dayIndex}`);
        }
    }
}
