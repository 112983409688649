function addPrefix(actionName: string) {
    return `INSTALLATION_REPORT_${actionName}`;
}

export const InstallationReportActions = {
    GetProjectDeviceCounts: addPrefix('GET_PROJECT_DEVICE_COUNTS'),
    GetInstallationReportDevices: addPrefix('GET_INSTALLATION_REPORT_DEVICES'),
    GetScheduleNames: addPrefix('GET_INSTALLATION_REPORT_SCHEDULE_NAMES'),
    GetProjectSchedules: addPrefix('GET_PROJECT_SCHEDULES'),
    SET_SORT_ORDER: addPrefix('SET_SORT_ORDER'),
};
