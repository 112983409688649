import * as React from 'react';
import { css } from '@emotion/css';
import { t } from 'app/translate';
import { Box, Border, Text, Clickable, Icon } from 'app/components';
import { type Id, getDeviceImageByType } from 'app/core/persistence';
import { useDrag } from '../../../../hooks';
import type { PiaId } from 'app/core/pia';

const dragIndicatorLarge = require('assets/images/map-drag-five-points.svg');
const noPointerEvents = css`
    pointer-events: none;
`;

const grabHandle = css`
    cursor: move;
`;

export const AddExpansionModuleMenuItem: React.FunctionComponent<{
    locked: boolean;
    text?: string;
    parentId: Id;
    productId: PiaId;
    testId?: string;
}> = ({ locked, text, parentId, productId, testId }) => {
    const dragRef = React.useRef<HTMLDivElement>(null);
    const dragImageRef = React.useRef<HTMLDivElement>(null);
    const [brokenImage, setBrokenImage] = React.useState<boolean>(false);

    useDrag({
        effect: 'link',
        ref: dragRef,
        imageRef: dragImageRef,
        imageOffsetX: 25,
        imageOffsetY: 25,
        data: { type: 'relayexpmodules', id: '', piaId: productId, parentId },
    });

    return (
        <div style={{ cursor: 'move', display: 'flex', flex: '1 0 0%' }}>
            <Border width={1} color="grey3" radius="4px">
                <Clickable disabled={locked} allowDragThrough>
                    <Box
                        testId={testId}
                        innerRef={dragRef}
                        padding="quart"
                        color="white"
                        alignItems="center"
                        width="100%"
                        minHeight="66px"
                    >
                        <Box
                            direction="column"
                            spacing="halfQuart"
                            padding="base"
                            paddingLeft="half"
                            flex="grow"
                            minWidth="0"
                        >
                            <Box width="150px">
                                <Text style="semibold" color="grey8" whiteSpace="nowrap">
                                    {text}
                                </Text>
                            </Box>
                            <Box width="150px">
                                <Text style="small" color="grey6" whiteSpace="nowrap">
                                    {t.dragToMap}
                                </Text>
                            </Box>
                        </Box>

                        <Border color="grey2" width={3} radius="50%">
                            <Box
                                innerRef={dragImageRef}
                                color="white"
                                padding="halfCell"
                                width="48px"
                                height="48px"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {brokenImage ? (
                                    <Icon icon="add_box" size="md" opaque color="grey5" />
                                ) : (
                                    <img
                                        src={getDeviceImageByType(
                                            productId,
                                            'relayexpmodules',
                                            'inherit',
                                        )}
                                        onError={() => setBrokenImage(true)}
                                        width="24"
                                        height="24"
                                        data-test-id={`add_drag_image`}
                                        className={noPointerEvents}
                                    />
                                )}
                            </Box>
                        </Border>

                        <Box __htmlAttributes={{ className: grabHandle }} paddingLeft="half">
                            <img src={dragIndicatorLarge} />
                        </Box>
                    </Box>
                </Clickable>
            </Border>
        </div>
    );
};

AddExpansionModuleMenuItem.displayName = 'AddExpansionModuleMenuItem';
