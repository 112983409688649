import { getSortedProjectDevices } from './getSortedProjectDevices';
import { getHasAvailableChildren } from './getHasAvailableChildren';
import {
    createDeepEqualSelector,
    getAvailablePiaItemsRecord,
    getCurrentProjectItemRelationsArray,
    getCurrentProjectItems,
    getCurrentProjectRelationsRecord,
} from 'app/modules/common';

export const getDevicesWithAvailableChannelsIds = createDeepEqualSelector(
    [
        getCurrentProjectRelationsRecord,
        getAvailablePiaItemsRecord,
        getCurrentProjectItems,
        getSortedProjectDevices,
        getCurrentProjectItemRelationsArray,
    ],
    (
        currentProjectRelationsRecord,
        piaItemsRecord,
        currentProjectItems,
        projectDevices,
        currentProjectRelationsArray,
    ) => {
        return projectDevices
            .filter((device) =>
                getHasAvailableChildren(
                    device.id,
                    currentProjectItems,
                    currentProjectRelationsRecord,
                    piaItemsRecord,
                    currentProjectRelationsArray,
                ),
            )
            .map(({ id }) => id);
    },
);
