import { injectable } from 'inversify';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { CurrentProjectService, ItemService } from 'app/core/persistence';
import { AppStore } from 'app/store';
import { isEqual } from 'lodash-es';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { PiaEnvironmentCategory, PiaRelationService } from 'app/core/pia';
import { getCurrentProjectRegions } from '../../project/selectors/getCurrentProject';
import { getPiaItem } from '../../item/selectors/getPiaItem';
import { getSelectedMountPIAIdsForItemId } from '../../mounts/selectors/getMountsCommon';
import { ModalService } from 'app/modal';
import { t } from 'app/translate';
import { ItemsToRemove } from '../components';
import { nameComparator } from 'app/utils';

interface IChildrenCompatibility {
    compatible: Id[];
    incompatible: Id[];
}

@injectable()
export class AccessoryService {
    constructor(
        private appStore: AppStore,
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private piaRelationService: PiaRelationService,
        private modalService: ModalService,
    ) {}

    public hasAccessoriesOrMount(id: Id): boolean {
        const relationsForEntity = this.currentProjectService.getItemRelations(id);

        return relationsForEntity.some((relation) => {
            // If type is primaryMount and productId is null it is included
            return (
                (relation.relationType === 'primaryMount' &&
                    this.currentProjectService.getEntity(relation.childId, 'item').productId !==
                        null) ||
                relation.relationType === 'accessory'
            );
        });
    }

    public hasAddedChildren(id: Id): boolean {
        const relationsForEntity = this.currentProjectService.getItemRelations(id);

        return relationsForEntity.some((relation) => {
            // If type is primaryMount and productId is null it is included
            return (
                (relation.relationType === 'primaryMount' &&
                    this.currentProjectService.getEntity(relation.childId, 'item').productId !==
                        null) ||
                relation.relationType === 'accessory' ||
                relation.relationType === 'acap' ||
                relation.relationType === 'partnerAcap'
            );
        });
    }

    public hasApplications(id: Id): boolean {
        const relationsForEntity = this.currentProjectService.getItemRelations(id);
        return relationsForEntity.some(
            (relation) =>
                relation.relationType === 'acap' || relation.relationType === 'partnerAcap',
        );
    }

    public hasLenses(id: Id): boolean {
        const relationsForEntity = this.currentProjectService.getItemRelations(id);
        return relationsForEntity.some((relation) => relation.relationType === 'lenses');
    }

    public async removeIncompatibleAccessoriesAndMounts(
        id: Id,
        newPiaId: PiaId | undefined | null,
    ): Promise<IPersistence<IItemEntity>> {
        const currentProjectRelations =
            this.currentProjectService.getAllEntitiesOfType('itemRelation');

        const exceptions = this.getChildrenCompatibility(id, newPiaId).compatible;
        const accessoryAndMountsRelations = currentProjectRelations
            .filter((entity) => entity.parentId === id)
            .filter(
                ({ relationType }) =>
                    relationType === 'acap' ||
                    relationType === 'partnerAcap' ||
                    relationType === 'accessory' ||
                    relationType === 'environment' ||
                    relationType === 'primaryMount' ||
                    relationType === 'environmentMount' ||
                    relationType === 'deviceMount' ||
                    relationType === 'lenses',
            )
            .filter((item) => !exceptions.includes(item.childId));

        if (accessoryAndMountsRelations.length > 0) {
            await Promise.all(
                accessoryAndMountsRelations.map((item) =>
                    item.relationType === 'partnerAcap'
                        ? this.itemService.deletePartnerItem(item.childId)
                        : this.itemService.deleteItem(item.childId),
                ),
            );
        }
        return this.itemService.getItem(id);
    }

    public getHasPiaItemsToRemove(itemId: Id, newPiaId?: PiaId | null): boolean {
        return this.getPiaItemsToRemove(itemId, newPiaId).length > 0;
    }

    /** If items need to be removed when changing product, this method will display a modal asking if the user wants to continue. */
    public getConfirmDialogue = async (
        itemId: Id,
        newPiaId?: PiaId | null,
        headerText: string = t.removeAccessoriesConfirmationGROUP.header,
        bodyText: string = t.removeAccessoriesConfirmationBody,
    ): Promise<boolean> => {
        const piaItems = this.getPiaItemsToRemove(itemId, newPiaId);

        if (piaItems.length === 0) return true;

        const confirm = await this.modalService.createConfirmDialog({
            header: headerText,
            body: ItemsToRemove({ piaItems, message: bodyText }),
            cancelButtonText: t.cancel,
            confirmButtonText: t.change,
        })();
        return confirm;
    };

    /** Checks which children are compatible and incompatible with the new product when changing device.
     *  This can be used to check which child devices can be kept and which need to be removed. */
    private getChildrenCompatibility(
        itemId: Id,
        newPiaId: PiaId | undefined | null,
    ): IChildrenCompatibility {
        const itemAccessories = this.currentProjectService.getDeviceChildren(itemId, [
            'accessory',
            'acap',
            'lenses',
        ]);

        const environment = this.currentProjectService.getDeviceChildren(itemId, 'environment');
        const state = this.appStore.Store.getState();
        const selectedMountPiaIds = getSelectedMountPIAIdsForItemId(state, itemId);
        const regions = getCurrentProjectRegions(state);

        const mountingChainItems = this.currentProjectService
            .getDeviceChildren(itemId, ['deviceMount', 'primaryMount', 'environmentMount'])
            .concat(environment);

        if (!newPiaId) {
            //* All items are incompatible if no newPiaId is provided
            return {
                compatible: [],
                incompatible: [
                    ...itemAccessories.map((acc) => acc._id),
                    ...mountingChainItems.map((mItem) => mItem._id),
                ],
            };
        }

        const allCompatiblePaths = !environment[0]?.productId
            ? []
            : this.piaRelationService.getAllCompatiblePaths(
                  newPiaId,
                  environment[0].productId,
                  regions,
              );

        const mountingChainIsCompatible = allCompatiblePaths.some((path) =>
            isEqual(path, [newPiaId, ...selectedMountPiaIds, environment[0].productId]),
        );

        const accessoriesCompatibility: IChildrenCompatibility = itemAccessories.reduce(
            (compatibilityRecord, accessory) => {
                //* If the accessory has no productId, it is incompatible
                if (!accessory.productId) {
                    return {
                        ...compatibilityRecord,
                        incompatible: [...compatibilityRecord.incompatible, accessory._id],
                    };
                }
                //* If the accessory is compatible with the newPiaId, add it to compatible
                if (
                    this.piaRelationService.isCompatibleWith(newPiaId, accessory.productId) ||
                    this.piaRelationService.isRecommended(newPiaId, accessory.productId)
                ) {
                    return {
                        ...compatibilityRecord,
                        compatible: [...compatibilityRecord.compatible, accessory._id],
                    };
                }
                //* Otherwise add it to incompatible
                return {
                    ...compatibilityRecord,
                    incompatible: [...compatibilityRecord.incompatible, accessory._id],
                };
            },
            { compatible: [], incompatible: [] } as IChildrenCompatibility,
        );

        //* Either keep or discard the entire mounting chain
        const mountingChainToKeep = mountingChainIsCompatible
            ? mountingChainItems.map((mount) => mount._id)
            : [];
        const mountingChainToRemove = mountingChainIsCompatible
            ? []
            : mountingChainItems.map((mount) => mount._id);

        const incompatible = [...accessoriesCompatibility.incompatible, ...mountingChainToRemove];
        const compatible = [...accessoriesCompatibility.compatible, ...mountingChainToKeep];

        return { compatible, incompatible };
    }

    /** Checks which accessories and mount items that need to be removed when changing a product */
    private getPiaItemsToRemove(itemId: Id, newPiaId?: PiaId | null): IPiaItem[] {
        if (!newPiaId) return [];
        const idsToRemove = this.getChildrenCompatibility(itemId, newPiaId).incompatible;
        if (idsToRemove.length === 0) return [];
        const state = this.appStore.Store.getState();

        //* Ignore environments since we don't want to show them as incompatible items to the user
        const piaItems = idsToRemove
            .reduce((piaNames, id) => {
                const piaItem = getPiaItem(state, id);
                if (!piaItem || piaItem.category === PiaEnvironmentCategory.ENVIRONMENTS)
                    return piaNames;
                return [...piaNames, piaItem];
            }, [] as IPiaItem[])
            .sort(nameComparator);

        return piaItems;
    }
}
