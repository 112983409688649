import {
    deviceTypeCheckers,
    isAccessoryAmplifier,
    isAccessoryBridge,
    isAccessoryServer,
    isCustomCamera,
    isDeviceSpecified,
    isSystemComponent,
    isWearableSystemDevice,
} from 'app/core/persistence';
import type { IItemEntity, IPersistence, Id, IItemRelationEntity } from 'app/core/persistence';
import {
    PiaAccessoryCategory,
    PiaItemRecorderCategory,
    PiaItemSoftwareCategory,
} from 'app/core/pia';
import type { IPiaItem, IPiaPoeConsumer } from 'app/core/pia';

import { modelAndNameComparator } from 'app/utils';
import { createSelector } from 'reselect';
import {
    getCurrentProjectItem,
    getCurrentProjectItems,
    getCurrentProjectItemsArray,
} from '../../project/selectors/getCurrentProject';
import {
    getPiaItemForProductId,
    getPiaItemsRecord,
} from '../../piaDevices/selectors/getPiaDevices';
import {
    getCustomCameraMaxRatingNoLoss,
    getHasPoeAccessory,
    getPowerFromPowerConsumptionWOrPoeClass,
    getTypicalPowerFromPiaForDevice,
} from './deviceRequirements';
import { isAxis } from '../../utils';
import { getParentRelationsRecord } from '../../relations';
import { getCalculatedQuantity } from '../../quotation';
import { getIdFromProps } from '../../selectors';

/**
 * Returns if an item has a poe-accessory (i.e including midspan)
 */
export const getHasPoeAccessoryForDeviceId = createSelector(
    [getCurrentProjectItem, getHasPoeAccessory],
    (item, poeAccessoryMap) => {
        if (!item?._id) return false;
        const hasPoeAccessory = poeAccessoryMap[item._id];
        return hasPoeAccessory;
    },
);

/**
 * Return true for an item that is a device to show power for
 */
const isPowerDevice = (
    item: IPersistence<IItemEntity>,
    piaItems: Record<number, IPiaItem>,
): boolean => {
    return (
        (isDeviceSpecified(item) &&
            isAxis(piaItems[item.productId].properties.vendor) && // temp until 2N products gets needed properties in pia then also add them
            // decoders/encoders
            (deviceTypeCheckers.isDecoder(item) || // pia category 'decoders'
                deviceTypeCheckers.isEncoder(item) || // pia category 'encoders'
                // pacs
                deviceTypeCheckers.isPac(item) || //properties.pac (doorstations, iorelays, accessServers, networkreaders, answeringUnits, intercom - microphone has category systemAccessory)
                deviceTypeCheckers.isDoorController(item) || // pia category 'doorcontrollers'
                // audio
                deviceTypeCheckers.isSpeaker(item) || // pia category 'speakers'
                (item.productId && isAccessoryBridge(piaItems[item.productId])) || // pia category 'bridges'
                (item.productId && isAccessoryServer(piaItems[item.productId])) || // pia category 'servers'
                (item.productId && isAccessoryAmplifier(piaItems[item.productId])) || // pia category 'amplifiers'
                // modular cameras
                deviceTypeCheckers.isMainUnit(item) || // pia category 'mainunits'
                // todo: add completemodulars
                // detectors
                deviceTypeCheckers.isAlerter(item) || // pia category 'alerters'
                deviceTypeCheckers.isPeopleCounter(item) || // pia category 'peopleCounters'
                deviceTypeCheckers.isRadarDetector(item) || // pia category 'radardetectors'
                deviceTypeCheckers.isConnectivityDevice(item) || // pia category 'connectivitydevices'
                // wearables
                deviceTypeCheckers.isSystemController(item) || // pia category 'systemControllers'
                (item.productId && isWearableSystemDevice(piaItems[item.productId])) || // pia category 'wearablesystemdevice'
                // intercoms
                deviceTypeCheckers.isDoorStation(item) || // pia category 'doorstations'
                deviceTypeCheckers.isCamera(item) ||
                deviceTypeCheckers.isPagingConsole(item))) || // pia cateogyr 'pagingconsole'
        isCustomCamera(item)
    );
};

/**
 * Return true for an item that is a recorder to show power for
 */
const isPowerSystemComponent = (item: IPersistence<IItemEntity>): boolean => {
    return deviceTypeCheckers.isSystemComponent(item);
};

/**
 * Return true for an item that is of category type illuminator
 */
export const getIsIlluminator = createSelector(
    [getCurrentProjectItems, getPiaItemsRecord, getIdFromProps],
    (items, piaItems, itemId) => {
        if (!itemId) return false;
        const item = items[itemId];
        return item?.productId
            ? piaItems[item.productId].category === PiaAccessoryCategory.ILLUMINATORS
            : false;
    },
);

/**
 * Return all device items in the project that we want to show power for
 * quantity for device is total quantity for item,
 * including multiplication with possible parent device quantity
 */
export const getPowerDeviceItems = createSelector(
    [
        getCurrentProjectItemsArray,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getParentRelationsRecord,
    ],
    (items, currentProjectItems, piaItems, relationsRecord) => {
        return items
            .filter((item) => isPowerDevice(item, piaItems), piaItems)
            .map((item) => getItemWithQuantity(item, currentProjectItems, relationsRecord))
            .sort((a, b) =>
                modelAndNameComparator(
                    { model: a.productId ? piaItems[a.productId].name : '', name: a.name },
                    { model: b.productId ? piaItems[b.productId].name : '', name: b.name },
                ),
            );
    },
);

/**
 * Returns the item with the quantity for the item multiplied with the quantity for the possible parent device
 * @param item Item to update
 * @param itemsRecord record of current project items
 * @param relationsRecord record of relations in current project
 * @returns Item with updated quantity
 */
const getItemWithQuantity = (
    item: IPersistence<IItemEntity>,
    itemsRecord: Record<string, IPersistence<IItemEntity> | undefined>,
    relationsRecord: Record<string, IItemRelationEntity>,
) => {
    const relation = relationsRecord[item._id];
    const parentItem = relation ? itemsRecord[relation.parentId] : undefined;
    const quantity = getCalculatedQuantity(item, itemsRecord, relationsRecord);
    return parentItem
        ? {
              ...item,
              quantity,
          }
        : item;
};

/**
 * Set the product name of the parent to the accessory (illuminator) name (not possible to assign names to accessories)
 * and the quantity for the accessory multiplied with the quantity for the parent device
 * of the accessory to get correct quantity in project
 * @param item Item to update
 * @param itemsRecord record of current project items
 * @param relationsRecord record of relations in current project
 * @param piaItemsRecord record of piaItems
 * @returns Item with updated name and quantity
 */
const getIlluminatorNameAndQuantity = (
    item: IPersistence<IItemEntity>,
    itemsRecord: Record<string, IPersistence<IItemEntity> | undefined>,
    relationsRecord: Record<string, IItemRelationEntity>,
    piaItemsRecord: Record<number, IPiaItem>,
) => {
    const relation = relationsRecord[item._id];
    const parentItem = relation ? itemsRecord[relation.parentId] : undefined;
    const quantity = getCalculatedQuantity(item, itemsRecord, relationsRecord);
    return parentItem
        ? {
              ...item,
              quantity: quantity,
              name:
                  parentItem && parentItem.productId
                      ? piaItemsRecord[parentItem?.productId].name
                      : '',
          }
        : item;
};

/**
 * Return all illuminator items in the project
 * quantity will be the total quantity for the item,
 * including multiplication with possible parent device quantity
 */
export const getPowerIlluminatorItems = createSelector(
    [
        getCurrentProjectItemsArray,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getParentRelationsRecord,
    ],
    (items, currentProjectItems, piaItems, relationsRecord) => {
        return items
            .filter((item) => {
                return (
                    isDeviceSpecified(item) &&
                    item.productId &&
                    piaItems[item.productId].category === PiaAccessoryCategory.ILLUMINATORS &&
                    isAxis(piaItems[item.productId].properties.vendor)
                );
            })
            .map((item) =>
                getIlluminatorNameAndQuantity(item, currentProjectItems, relationsRecord, piaItems),
            )
            .sort((a, b) =>
                modelAndNameComparator(
                    { model: a.productId ? piaItems[a.productId].name : '', name: a.name },
                    { model: b.productId ? piaItems[b.productId].name : '', name: b.name },
                ),
            );
    },
);

/**
 * Return all recorder items in the project
 */
export const getPowerRecorderItems = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItems) => {
        const systemComponents = items.filter(isPowerSystemComponent);
        const recordersAndSwitches = systemComponents
            .filter((item) => {
                return (
                    item.productId &&
                    piaItems[item.productId].category !== PiaItemSoftwareCategory.VMS &&
                    isAxis(piaItems[item.productId].properties.vendor)
                );
            })
            .sort((a, b) =>
                modelAndNameComparator(
                    { model: a.productId ? piaItems[a.productId].name : '', name: a.name },
                    { model: b.productId ? piaItems[b.productId].name : '', name: b.name },
                ),
            );

        return recordersAndSwitches;
    },
);

/**
 * Gets a record with typical and max power for all specified (has a pia product) devices
 * */
export const getPowerForItems = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItems) => {
        const powers = items.reduce(
            (powerRecord, item) => {
                // We only calculate power for devices that have a pia product or custom cameras
                if (!isDeviceSpecified(item) && !isCustomCamera(item)) return powerRecord;

                // Calculations for custom cameras
                if (isCustomCamera(item)) {
                    powerRecord[item._id] = {
                        typical: getCustomCameraMaxRatingNoLoss(item),
                        maxPower: getCustomCameraMaxRatingNoLoss(item),
                    };
                    return powerRecord;
                }

                const piaItem = piaItems[item.productId];

                // Calculations for system components
                if (
                    isPowerSystemComponent(item) &&
                    isSystemComponent(piaItem) &&
                    piaItem.category === PiaItemRecorderCategory.RECORDERS2
                ) {
                    const maxPower = piaItem.properties.powerConsumptionW ?? 0;
                    const typicalPower = piaItem.properties.typicalACPower ?? 0;
                    powerRecord[item._id] = { typical: typicalPower, maxPower };
                    return powerRecord;
                }

                // Calculations for devices
                const typical =
                    getTypicalPowerFromPiaForDevice(piaItem) ??
                    getPowerFromPowerConsumptionWOrPoeClass(piaItem) ??
                    0;
                const maxPower = getPowerFromPowerConsumptionWOrPoeClass(piaItem) ?? 0;
                powerRecord[item._id] = { typical, maxPower };
                return powerRecord;
            },
            {} as Record<Id, { typical: number; maxPower: number }>,
        );

        return powers;
    },
);

/**
 * Return the sum of both typical power and max power for all device items
 */
export const getPowerDeviceSum = createSelector(
    [getPowerDeviceItems, getPowerForItems],
    (devices, powerRecord) => {
        return summarizePower(devices, powerRecord);
    },
);

/**
 * Return the sum of both typical power and max power for all illuminator items
 */
export const getPowerIlluminatorSum = createSelector(
    [getPowerIlluminatorItems, getPowerForItems],
    (illuminators, powerRecord) => {
        return summarizePower(illuminators, powerRecord);
    },
);

/**
 * Return the sum of both typical power and max power for all recorder items
 */
export const getPowerRecorderSum = createSelector(
    [getPowerRecorderItems, getPowerForItems],
    (recorderItems, powerRecord) => {
        return summarizePower(recorderItems, powerRecord);
    },
);

function summarizePower(
    items: IPersistence<IItemEntity>[],
    powerRecord: ReturnType<typeof getPowerForItems>,
) {
    const itemPowerSum = items.reduce(
        (sum, item) => {
            const powerItem = powerRecord[item._id];
            return {
                typical: sum.typical + powerItem.typical * item.quantity,
                maxPower: sum.maxPower + powerItem.maxPower * item.quantity,
            };
        },
        { typical: 0, maxPower: 0 } as { typical: number; maxPower: number },
    );
    return itemPowerSum;
}

const getAcInputString = (piaItem: IPiaPoeConsumer) => {
    return piaItem.properties.ACInputVoltage
        ? piaItem.properties.ACInputVoltage.min === piaItem.properties.ACInputVoltage.max
            ? `${piaItem.properties.ACInputVoltage.min} V AC`
            : `${piaItem.properties.ACInputVoltage.min}-${piaItem.properties.ACInputVoltage.max} V AC`
        : undefined;
};

const getDcInputString = (piaItem: IPiaPoeConsumer) => {
    return piaItem.properties.DCInputVoltage
        ? piaItem.properties.DCInputVoltage.min === piaItem.properties.DCInputVoltage.max
            ? `${piaItem.properties.DCInputVoltage.min} V DC`
            : `${piaItem.properties.DCInputVoltage.min}-${piaItem.properties.DCInputVoltage.max} V DC`
        : undefined;
};

/**
 * Return a descriptive text of the power supply for an item in the project. Empty text if not found
 */
export const getPowerSupplyForDeviceId = createSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (item, piaItems) => {
        if (!item?.productId) return '';
        const piaItem = piaItems[item.productId] as IPiaPoeConsumer;
        if (piaItem.properties.powerOverEthernet && piaItem.properties.PoEClass) {
            switch (piaItem.properties.PoEClass) {
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                    return `PoE Class ${piaItem.properties.PoEClass}`;
                case 'highPoe':
                    return 'High PoE';
                default:
                    return '';
            }
        } else {
            const acInput = getAcInputString(piaItem);
            const dcInput = getDcInputString(piaItem);
            if (acInput && dcInput) {
                return `${acInput} / ${dcInput}`;
            }
            return acInput ?? dcInput ?? '';
        }
    },
);

/**
 * returns if a piaProduct includes a 'poe' (midspan) or not.
 */
export const getIsPoeIncludedForItem = createSelector(
    [getPiaItemsRecord, getPiaItemForProductId],
    (piaItems, currentPiaItem): boolean => {
        if (!currentPiaItem) return false;

        const relations = currentPiaItem.relations.filter(
            (relation) => relation.relationType === 'includes',
        );
        return relations.some((relation) => {
            return piaItems[relation.id]?.category === 'poe';
        });
    },
);

/**
 * Returns true if project contains any product from any other vendor than Axis, false otherwise
 */
export const getIsOtherProductInProject = createSelector(
    [getCurrentProjectItemsArray, getPiaItemsRecord],
    (items, piaItems) => {
        return items.some(
            (item) =>
                (item.productId && !isAxis(piaItems[item.productId].properties.vendor)) ||
                item.properties.partnerSystemComponent,
        );
    },
);
