import { createCachedSelector } from 're-reselect';
import { getAssociatedProfile, getProfileOverride } from '../../item/selectors';

import { Time24 } from 'app/core/persistence';
import { getRecordingType } from './getRecordingType';
import { getIdFromPropsRequired } from '../../selectors';
import type { IScheduleSelectorModel } from '../models';
import { getScheduleModels } from './getSchedules';
import { toCacheKey } from '../../cacheKey';

export const getScheduleSelectorModel = createCachedSelector(
    [
        getAssociatedProfile,
        getProfileOverride,
        getScheduleModels,
        getIdFromPropsRequired,
        getRecordingType,
    ],
    (profile, profileOverride, schedules, _, recordingType): IScheduleSelectorModel => {
        const scheduleId =
            profileOverride && profileOverride[recordingType].schedule !== undefined
                ? profileOverride[recordingType].schedule
                : profile && profile[recordingType].schedule;

        return {
            lightStart:
                (profileOverride &&
                    profileOverride.scenario.lightStart &&
                    new Time24(profileOverride.scenario.lightStart)) ||
                (profile && new Time24(profile.scenario.lightStart)) ||
                new Time24(7),
            lightEnd:
                (profileOverride &&
                    profileOverride.scenario.lightEnd &&
                    new Time24(profileOverride.scenario.lightEnd)) ||
                (profile && new Time24(profile.scenario.lightEnd)) ||
                new Time24(17),
            nightLighting:
                profileOverride?.scenario.nightLighting || profile?.scenario.nightLighting || 8,
            schedules,
            selectedSchedule: schedules.find((schedule) => schedule.id === scheduleId),
        };
    },
)(toCacheKey);
