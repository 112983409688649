import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { eventTracking } from 'app/core/tracking';
import { Box, Button, Card, IconText, Spacer, Spinner, Stack, Text } from 'app/components';
import { useService } from 'app/ioc';
import {
    CommonActionService,
    CouchDBCompactionService,
    getCompactionStatus,
    getCouchDBInfo,
    getHasCompactionResult,
} from '../common/';
import { useMount } from 'app/hooks';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';

export const CouchDBCompactionContainer: React.FunctionComponent = () => {
    const couchDBCompactionService = useService(CouchDBCompactionService);
    const commonActionService = useService(CommonActionService);
    const couchDBInfo = useSelector(getCouchDBInfo);

    // The compact status reported from the lambda can be one of the following:
    // 'Available' | 'Initiated' | 'Success' | 'Fail' | 'SeriousError';
    const compactionStatus = useSelector(getCompactionStatus);
    const hasCompactionResult = useSelector(getHasCompactionResult);
    // Key used when logging to Matomo, to be able to see difference before and after compaction
    const [logKey] = React.useState(uuidv4());
    const [error, setError] = React.useState(false);
    const [compactRunning, setCompactRunning] = React.useState(false);

    const timer = React.useRef<NodeJS.Timer>();

    useMount(
        async () => {
            try {
                await couchDBCompactionService.initiateCompact(logKey, couchDBInfo?.fileSize);
                setCompactRunning(true);
                commonActionService.setCompactionInitiated(true);
            } catch (e) {
                setError(true);
                eventTracking.logError(
                    `Failed to initiate compaction procedure - ${e}`,
                    'Optimization',
                );
            }
        },
        () => {
            // Reset compaction initiated
            commonActionService.setCompactionInitiated(false);
        },
    );

    React.useEffect(() => {
        if (compactRunning) {
            timer.current = setInterval(() => {
                commonActionService.updateCompactStatus();
            }, 3000);
        } else {
            clearInterval(timer.current);
        }

        return () => clearInterval(timer.current);
    }, [commonActionService, compactRunning]);

    React.useEffect(() => {
        if (hasCompactionResult) {
            setCompactRunning(false);
        }
    }, [compactRunning, hasCompactionResult]);

    React.useEffect(() => {
        if (compactRunning && compactionStatus && hasCompactionResult) {
            couchDBCompactionService
                .logCompactionResult(compactionStatus, logKey)
                .then(() => setCompactRunning(false));
        }
    }, [hasCompactionResult, compactionStatus, couchDBCompactionService, compactRunning, logKey]);

    /**
     * All results should trigger navigateHome and application will reload.
     * This will setup userDataDB with replication etc. so no need to turn on replication again.
     */
    const navigateUserProjects = async () => {
        // Reset compaction initiated since destructor not running when setting window location.
        commonActionService.setCompactionInitiated(false);
        window.location.href = '/';
    };

    const inProgress = !error && !hasCompactionResult;
    const success = !compactRunning && compactionStatus === 'Success';
    const failure = error || (!compactRunning && compactionStatus === 'Fail');
    const seriousError = !compactRunning && compactionStatus === 'SeriousError';

    return (
        <Box
            direction="column"
            paddingTop="doublePanel"
            alignItems="center"
            justifyContent="center"
        >
            {inProgress && (
                <>
                    <Box paddingTop="doublePanel" alignItems="center">
                        <Stack spacing="half">
                            <Spinner size={40} />
                            <Text style="title">{t.optimizingPerformance}</Text>
                        </Stack>
                    </Box>
                    <Spacer />
                    <Spacer />
                    <Stack vertical notFullWidth spacing="half" alignItems="center">
                        <Text>{t.takeSomeTime}</Text>
                        <Text>{t.doNotRefresh}</Text>
                    </Stack>
                </>
            )}
            {failure && (
                <>
                    <Box paddingTop="doublePanel" alignItems="center">
                        <IconText
                            icon="error"
                            iconProps={{ opaque: true, color: 'red', size: 'lg' }}
                            textProps={{ style: 'title' }}
                        >
                            {t.somethingWentWrong}
                        </IconText>
                    </Box>
                    <Spacer />
                    <Spacer />
                    <Stack vertical notFullWidth spacing="half" alignItems="center">
                        <Text>{t.failedToOptimize}</Text>
                        <Button primary onClick={navigateUserProjects}>
                            {t.refresh}
                        </Button>
                    </Stack>
                </>
            )}
            {success && (
                <>
                    <Box paddingTop="doublePanel" alignItems="center">
                        <IconText
                            icon="check_circle"
                            iconProps={{ opaque: true, color: 'green', size: 'lg' }}
                            textProps={{ style: 'title' }}
                        >
                            {t.done}
                        </IconText>
                    </Box>
                    <Spacer />
                    <Spacer />
                    <Stack vertical notFullWidth spacing="half" alignItems="center">
                        <Text>{t.clickButtonToRefresh}</Text>
                        <Button primary onClick={navigateUserProjects}>
                            {t.refresh}
                        </Button>
                    </Stack>
                </>
            )}
            {seriousError && (
                <>
                    <Box paddingTop="doublePanel" alignItems="center" maxWidth="40%">
                        <Card borderColor="red" color="white" paddingX="panel" paddingY="panel">
                            <Stack vertical notFullWidth spacing="base" alignItems="center">
                                <IconText
                                    icon="error"
                                    iconProps={{ opaque: true, color: 'red', size: 'lg' }}
                                    textProps={{ style: 'title' }}
                                >
                                    {t.somethingWentWrong}
                                </IconText>
                                <Text style="semibold" color="red">
                                    {t.seriousErrorMessage1}
                                </Text>
                                <Text style="semibold" color="red">
                                    {t.seriousErrorMessage2}
                                </Text>
                            </Stack>
                        </Card>
                    </Box>
                    <Spacer />
                    <Button primary onClick={navigateUserProjects}>
                        {t.clickButtonToRefresh}
                    </Button>
                </>
            )}
        </Box>
    );
};
