import * as React from 'react';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { Box, Heading, SearchField, Stack, Card, Text, Spacer, Positioned } from 'app/components';
import type { IPiaItem } from 'app/core/pia';
import { getAvailableSystemAccessoryCategories, getAccessoriesToShow } from '../selectors';
import { MenuItem } from './MenuItem.component';
import { AccessoryItem } from './AccessoryItem.container';
import { CountBadge } from './CountBadge.container';
import type { ISystemAccessoriesState } from '../state/ISystemAccessoriesState';
import { SystemAccessoriesActionService } from '../services';
import { DisplayCategory, DISPLAY_CATEGORY_TO_PIA_CATEGORY } from './DisplayCategory';
import { getCurrentProjectLocked } from 'app/modules/common';

type categoryString = keyof typeof t.accessoryDisplayCategoriesGROUP;
const noSearchResults = require('src/assets/images/no-search-result.svg');

interface ISystemAccessoriesProps extends ISystemAccessoriesState {
    accessories: IPiaItem[];
    availableCategories: DisplayCategory[];
    isLocked: boolean;
}

const mapStateToProps = (storeState: IStoreState): ISystemAccessoriesProps => {
    const accessories = getAccessoriesToShow(storeState);
    const availableCategories = getAvailableSystemAccessoryCategories(storeState);
    const isLocked = getCurrentProjectLocked(storeState);
    return {
        accessories,
        availableCategories,
        isLocked,
        ...storeState.systemAccessories,
    };
};

const SystemAccessoriesContainer: React.FC<ISystemAccessoriesProps> = ({
    accessories,
    availableCategories,
    isLocked,
    isCurrentSelectionSelected,
    isSearchResultSelected,
    isAllAccessoriesSelected,
    searchText,
    selectedCategory,
}) => {
    const [systemAccessoriesActionService] = React.useState<SystemAccessoriesActionService>(
        ServiceLocator.get(SystemAccessoriesActionService),
    );
    const [title, setTitle] = React.useState<string>();

    const getCategoryTranslation = React.useCallback((category: DisplayCategory) => {
        if (category === DisplayCategory.AUDIOANDIO) {
            return t.accessoryDisplayCategoriesGROUP.audioandio;
        }
        if (category === DisplayCategory.CONNECTIVITY) {
            return t.accessoryDisplayCategoriesGROUP.connectivity;
        }
        return t.accessoryDisplayCategoriesGROUP[
            DISPLAY_CATEGORY_TO_PIA_CATEGORY[category] as categoryString
        ];
    }, []);

    React.useEffect(() => {
        if (isAllAccessoriesSelected) {
            return setTitle(t.allAccessories);
        }
        if (isCurrentSelectionSelected) {
            return setTitle(accessories.length === 0 ? '' : t.currentSelection);
        }
        if (isSearchResultSelected) {
            return setTitle(t.searchResultsFor(searchText ?? ''));
        }
        if (selectedCategory) {
            return setTitle(getCategoryTranslation(selectedCategory));
        }
    }, [
        accessories.length,
        getCategoryTranslation,
        isAllAccessoriesSelected,
        isCurrentSelectionSelected,
        isSearchResultSelected,
        searchText,
        selectedCategory,
    ]);

    // Clear search field on unmount.
    React.useEffect(() => {
        return () => {
            systemAccessoriesActionService.search('');
        };
    }, [systemAccessoriesActionService]);

    return (
        <Box padding="doublePanel" justifyContent="center">
            <Box direction="column" width="100%" maxWidth="1000px">
                <Box justifyContent="between" width="100%" paddingBottom="panel">
                    <Heading testId="system_accessories_header" style="title">
                        {t.systemAccessories}
                    </Heading>
                    {!isLocked && (
                        <SearchField
                            value={searchText ?? ''}
                            onValueChange={systemAccessoriesActionService.search}
                            testId={`system_accessory_search`}
                        />
                    )}
                </Box>

                <Stack alignItems="start" spacing="none">
                    <Positioned position="sticky" top="0">
                        <Box flex="none" paddingTop="base" minWidth="240px">
                            <Stack vertical alignItems="start" spacing="halfCell">
                                <MenuItem
                                    selected={isCurrentSelectionSelected}
                                    icon="list"
                                    text={t.currentSelection}
                                    onClick={systemAccessoriesActionService.showCurrentSelection}
                                >
                                    <CountBadge />
                                </MenuItem>
                                {!isLocked && (
                                    <>
                                        <MenuItem
                                            selected={isSearchResultSelected}
                                            disabled={!searchText && !isSearchResultSelected}
                                            icon="search"
                                            text={t.searchResults}
                                            onClick={
                                                systemAccessoriesActionService.showSearchResult
                                            }
                                        />
                                        <Spacer />
                                        <MenuItem
                                            selected={isAllAccessoriesSelected}
                                            icon="category"
                                            text={t.allAccessories}
                                            onClick={
                                                systemAccessoriesActionService.showAllAccessories
                                            }
                                        />
                                        <Spacer />
                                        {availableCategories.map((category, idx) => (
                                            <MenuItem
                                                key={idx}
                                                selected={selectedCategory === category}
                                                text={getCategoryTranslation(category)}
                                                onClick={() =>
                                                    systemAccessoriesActionService.showCategory(
                                                        category,
                                                    )
                                                }
                                            >
                                                <CountBadge category={category} />
                                            </MenuItem>
                                        ))}
                                    </>
                                )}
                            </Stack>
                        </Box>
                    </Positioned>
                    <Card paddingX="panel" paddingY="panel" minHeight="400px" flex="shrinkAndGrow">
                        <Stack vertical>
                            <Text color="grey9" style="subheader">
                                {title}
                            </Text>
                            {isSearchResultSelected && accessories.length === 0 && (
                                <Box height="100%" justifyContent="center" alignItems="center">
                                    <img src={noSearchResults} />
                                </Box>
                            )}
                            {isCurrentSelectionSelected && accessories.length === 0 && (
                                <Box height="100%">
                                    <Stack alignItems="center" justifyContent="center" vertical>
                                        <Heading style="subheader">{t.noSystemAccessories}</Heading>
                                        {!isLocked && (
                                            <Text color="grey6">{t.noAccessoriesHint}</Text>
                                        )}
                                    </Stack>
                                </Box>
                            )}
                            <Stack vertical spacing="none" lineBetweenColor="grey2">
                                {accessories.map((accessory, i) => (
                                    <AccessoryItem
                                        key={i}
                                        hideSelectionBackground={isCurrentSelectionSelected}
                                        productId={accessory.id}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>
            </Box>
        </Box>
    );
};

export const SystemAccessories = connect(mapStateToProps)(SystemAccessoriesContainer);
