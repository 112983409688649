import * as React from 'react';
import { Box, Text, Icon, Border } from 'app/components';
import { t } from 'app/translate';
import { clientIsMacOs } from 'app/modules/common';
import { css } from '@emotion/css';

interface IHotkey {
    name: string;
    command: string | React.ReactNode;
}

const LEFT_PART_WIDTH = '400px';

const keyboardButtonStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    min-width: 20px;
    color: #666;
    padding: 0 5px;
    height: 20px;
    border: 1px solid #f2f2f2;
    border-radius: 3px;
    background: linear-gradient(to bottom, #fff 0%, #fff 90%, #eee 100%);
    font-family: sans-serif;
    box-shadow:
        0px 0px 0 #cdcdcd,
        -1px 0px 1px rgb(153 153 153 / 0.25),
        0px 1px 2px rgb(153 153 153 / 0.25);
`;
const keyButton = (content: string | React.ReactNode) => {
    return <div className={keyboardButtonStyle}>{content}</div>;
};

const keyCombo = (separator: string, ...content: string[] | React.ReactNode[]) => {
    return (
        <Box spacing="quart">
            {content.map((item, index) => (
                <React.Fragment key={index}>
                    {keyButton(item)}
                    {separator && content.length - 1 > index && <Text>{separator}</Text>}
                </React.Fragment>
            ))}
        </Box>
    );
};

/** Generates layout for a given hotkey group. */
const hotkeyGroup = (
    /** Headline for hotkey group */
    headline: string,
    /** Array of {@link IHotkey} containing name of hotkey and key commands to execute */
    hotkeys: IHotkey[],
    /** Name of GIF file excluding path and file ending (.gif) */
    gifFileName?: string,
    /** Optional description for hotkey group. This is JSX to allow for icons in the middle of text. */
    description?: JSX.Element,
): JSX.Element => (
    <Box direction="column" lineBetweenColor="grey3" spacing="base">
        <Text style="semibold" color="grey7">
            {headline}
        </Text>
        <Box paddingRight="base" paddingBottom="cell">
            <Box spacing="doublePanel" justifyContent="between">
                <Box direction="column" spacing="half">
                    {description && (
                        <Box width={LEFT_PART_WIDTH} paddingBottom="base" wrap>
                            {description}
                        </Box>
                    )}
                    {hotkeys.map((hotkey) => (
                        <Box width={LEFT_PART_WIDTH} key={hotkey.name}>
                            <Box
                                spacing="base"
                                justifyContent="between"
                                alignItems="start"
                                minWidth={LEFT_PART_WIDTH}
                            >
                                <Text color="grey7">{hotkey.name}</Text>
                                <Text inline align="right">
                                    {hotkey.command}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </Box>
                {gifFileName && (
                    <Border color="grey6">
                        <Box>
                            <img
                                height="180px"
                                width="240px"
                                src={require(`src/assets/images/shortcuts/${gifFileName}.gif`)}
                            />
                        </Box>
                    </Border>
                )}
            </Box>
        </Box>
    </Box>
);

/** Modal that presents shortcuts and other information on how to use various SiteDesigner functionality */
export const KeyboardShortcuts: React.FC = () => {
    const macOs = clientIsMacOs();
    const ctrlOrCmd = macOs ? '⌘' : t.keyboardKeyControl;
    const altOrOption = macOs ? '⌥' : t.keyboardKeyAlt;
    const del = macOs ? keyCombo('+', 'fn', '⌫') : keyButton(t.keyboardKeyDelete);
    const move = keyCombo(
        '',
        <Icon opaque icon="keyboard_arrow_up" size="sm" />,
        <Icon opaque icon="keyboard_arrow_left" size="sm" />,
        <Icon opaque icon="keyboard_arrow_down" size="sm" />,
        <Icon opaque icon="keyboard_arrow_right" size="sm" />,
    );

    return (
        <Box paddingY="base">
            <Box direction="column" spacing="half">
                {hotkeyGroup(
                    t.devices,
                    [
                        {
                            name: t.addToQuantity,
                            command: keyCombo('+', altOrOption, t.clickAndDrag),
                        },
                        {
                            name: t.duplicate,
                            command: keyCombo('+', ctrlOrCmd, altOrOption, t.clickAndDrag),
                        },
                        { name: t.removeFromMap, command: del },
                        { name: t.move, command: move },
                        ...[
                            {
                                name: t.rotate,
                                command: (
                                    <Box direction="row" spacing="quart">
                                        {keyButton(t.keyboardKeyShift)}
                                        <Text>{'+'}</Text>
                                        {move}
                                    </Box>
                                ),
                            },
                        ],
                    ],
                    'Duplicate',
                )}
                {hotkeyGroup(
                    t.leafletEditLine,
                    [
                        { name: t.leafletFinishTitle, command: keyButton(t.keyboardKeyEnter) },
                        {
                            name: t.leafletActionsTitle,
                            command: keyButton(t.keyboardKeyEscape),
                        },
                        { name: t.leafletActionsSnap, command: keyButton(t.keyboardKeyShift) },
                    ],
                    'Blockers',
                    <span>
                        {t.blockerSelectInfo}
                        <Icon icon="line_tool" size="md" />
                        {t.blockerDrawInfo}
                    </span>,
                )}
                {hotkeyGroup(t.undo, [
                    {
                        name: t.undo,
                        command: keyCombo('+', ctrlOrCmd, 'Z'),
                    },
                    { name: t.redo, command: keyCombo('+', ctrlOrCmd, 'Y') },
                ])}
            </Box>
        </Box>
    );
};

KeyboardShortcuts.displayName = 'KeyboardShortcuts';
