import { css } from '@emotion/css';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import { fromLarge } from 'app/styles/breakpoints.type';

export const QuotationTableStyle = css`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 4em;

    td,
    th {
        padding: 0 ${SpacingsEnum.half};
        min-width: 60px;
        text-align: start;
    }

    td:last-child,
    th:last-child {
        text-align: end;
    }

    caption {
        text-align: start;
        padding-bottom: ${SpacingsEnum.half};
    }

    tbody tr {
        border-bottom: 2px solid ${ColorsEnum.grey2};
        height: 30px;
    }

    tbody tr:last-child {
        border-bottom: 2px solid ${ColorsEnum.transparent};
    }

    tbody tr[data-discontinued='true'] {
        color: ${ColorsEnum.grey5};
    }

    tbody th[scope='colgroup'] {
        text-align: start;
        font-weight: bold;
        border-bottom: 2px solid ${ColorsEnum.yellow};
        padding-bottom: 5px;
        padding-top: 20px;
    }

    tbody th[scope='row'] {
        width: 40%;
        font-weight: normal;
        position: relative;
    }

    tbody &:first-of-type th {
        padding-top: 0;
    }

    tfoot tr {
        border: none !important;
    }

    tfoot td {
        padding-top: ${SpacingsEnum.base};
    }

    @media screen and (max-width: ${fromLarge}) {
        td,
        th {
            min-width: 60px;
        }
    }

    @media print {
        @page {
            margin-left: 0;
            margin-right: 0;
        }

        table {
            page-break-before: auto;
        }

        tbody:after {
            /* Hack to avoid page breaks inside table body */
            content: '\\a0';
            display: block;
        }

        tfoot {
            display: table-row-group;
        }

        max-width: 100%;
    }
`;

export const DiscontinuedStyle = css`
    padding: 1px 6px 0px 6px;
    background-color: ${ColorsEnum.red};
    color: white;
`;

export const MsrpColumnHeaderStyle = css`
    margin-top: Calc(-1 * 2px);
`;

export const RightAlignedStyle = css`
    text-align: end !important;
`;

export const MsrpCurrencyStyle = css`
    margin-inline-start: 8px;
    color: ${ColorsEnum.grey4};
`;
