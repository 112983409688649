import * as React from 'react';
import { Stack, Box, Text } from 'app/components';
import { LightConditionSetting } from './LightConditionSetting.component';
import { t } from 'app/translate';
import { constants } from '../../constants';

interface ILightConditionSettingRowProps {
    label: string;
    color: 'yellow' | 'blue';
    triggerValue: number;
    triggerValueOverride?: number;
    motionValue: number;
    motionValueOverride?: number;
    triggerOnChange(value: number): void;
    triggerOnMouseUp(): void;
    motionOnChange(value: number): void;
    motionOnMouseUp(): void;
}

export class LightConditionSettingRow extends React.PureComponent<ILightConditionSettingRowProps> {
    public render() {
        return (
            <Stack alignItems="start">
                <Box width={constants.leftColumnWidth} justifyContent="end" paddingX="quart">
                    <Text>{this.props.label}</Text>
                </Box>
                <Box flex="shrinkAndGrow">
                    <Stack flex="shrinkAndGrow">
                        <LightConditionSetting
                            color={this.props.color}
                            label={t.profilesScheduledRecordingMotionInScene}
                            value={this.props.triggerValue}
                            overrideValue={this.props.triggerValueOverride}
                            type="motionInScene"
                            onChange={this.props.triggerOnChange}
                            onMouseUp={this.props.triggerOnMouseUp}
                        />
                        <LightConditionSetting
                            color={this.props.color}
                            label={t.profilesScheduledRecordingPartsOfSceneMoving}
                            value={this.props.motionValue}
                            overrideValue={this.props.motionValueOverride}
                            type="sceneMoving"
                            onChange={this.props.motionOnChange}
                            onMouseUp={this.props.motionOnMouseUp}
                        />
                    </Stack>
                </Box>
            </Stack>
        );
    }
}
