import { DistanceUnit } from 'app/core/persistence';
import { doriPixelLimitsMeter, doriPixelLimitsFeet, doriPixelOpacity } from 'app/core/common';

const ppm25Img = require('src/assets/images/dd-25pxm.png');
const ppm63Img = require('src/assets/images/dd-63pxm.png');
const ppm125Img = require('src/assets/images/dd-125pxm.png');
const ppm250Img = require('src/assets/images/dd-250pxm.png');

export type PixelDensityRequirement = 'detect' | 'observe' | 'recognize' | 'identify';

export interface IPixelDensity {
    meterValue: number;
    feetValue: number;
    image: any;
    opacity: number;
    operationalRequirement: PixelDensityRequirement;
}

export const PixelDensities: IPixelDensity[] = [
    {
        meterValue: doriPixelLimitsMeter.DORI_LIMIT_DETECT,
        feetValue: doriPixelLimitsFeet.DORI_LIMIT_DETECT,
        image: ppm25Img,
        opacity: doriPixelOpacity.DORI_OPACITY_DETECT,
        operationalRequirement: 'detect',
    },
    {
        meterValue: doriPixelLimitsMeter.DORI_LIMIT_OBSERVE,
        feetValue: doriPixelLimitsFeet.DORI_LIMIT_OBSERVE,
        image: ppm63Img,
        opacity: doriPixelOpacity.DORI_OPACITY_OBSERVE,
        operationalRequirement: 'observe',
    },
    {
        meterValue: doriPixelLimitsMeter.DORI_LIMIT_RECOGNIZE,
        feetValue: doriPixelLimitsFeet.DORI_LIMIT_RECOGNIZE,
        image: ppm125Img,
        opacity: doriPixelOpacity.DORI_OPACITY_RECOGNIZE,
        operationalRequirement: 'recognize',
    },
    {
        meterValue: doriPixelLimitsMeter.DORI_LIMIT_IDENTIFY,
        feetValue: doriPixelLimitsFeet.DORI_LIMIT_IDENTIFY,
        image: ppm250Img,
        opacity: doriPixelOpacity.DORI_OPACITY_IDENTIFY,
        operationalRequirement: 'identify',
    },
];

export const getPixelDensityValue = (pixelDensity: IPixelDensity, displayUnit: DistanceUnit) => {
    return displayUnit === DistanceUnit.Feet ? pixelDensity.feetValue : pixelDensity.meterValue;
};
