import type { IPiaItem, PiaId } from 'app/core/pia';

export const hasNotToBeFollowedByAccessory = (
    piaId: PiaId,
    notToBeFollowedByAccessories: Record<PiaId, IPiaItem[][]>,
    selectedAccessoriesIds: PiaId[],
) => {
    return (
        getNotToBeFollowedByArray(piaId, notToBeFollowedByAccessories, selectedAccessoriesIds)
            .length > 0
    );
};

/**
 * Find products that are neither allowed to follow by nor be followed by pia id
 *
 * @param piaId
 * @param notToBeFollowedByAccessories
 * @param selectedAccessoriesIds
 * @returns an array of ids of pia items that should not be followed by @param piaId.
 */
export const getNotToBeFollowedByArray = (
    piaId: PiaId,
    notToBeFollowedByAccessories: Record<PiaId, IPiaItem[][]>,
    selectedAccessoriesIds: PiaId[],
) => {
    const result: number[] = [];

    const ntbfbForPiaIDArray = notToBeFollowedByAccessories[piaId];
    selectedAccessoriesIds.map((accessoryId) => {
        if (
            ntbfbForPiaIDArray &&
            ntbfbForPiaIDArray.find((products) =>
                products.find((product) => product.id === accessoryId),
            )
        ) {
            result.push(accessoryId);
        }
    });

    Object.entries(notToBeFollowedByAccessories).map(([key, productsArray]) => {
        if (productsArray.find((products) => products.find((product) => product.id === piaId))) {
            result.push(parseInt(key));
        }
    });

    if (result.length > 0) {
        return selectedAccessoriesIds.filter((id) => result.includes(id));
    }

    return [];
};
