export interface ICustomPouchError {
    name: string;
    message: string;
    status: number;
    reason: string;
}

export function isCustomPouchError(payload: any): payload is ICustomPouchError {
    if (!payload) {
        return false;
    }
    const hasName = payload.hasOwnProperty('name');
    const hasMessage = payload.hasOwnProperty('message');
    const hasStatus = payload.hasOwnProperty('status');
    const hasReason = payload.hasOwnProperty('reason');
    return hasName && hasMessage && hasStatus && hasReason;
}
