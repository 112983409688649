import type { PolyLine } from 'app/core/persistence';
import {
    getOffset,
    distance,
    orientation,
    ORIGO,
    equals,
    isBetween,
    diff,
    dotProduct,
    add,
    mul,
    type Point,
} from 'axis-webtools-util';
import { clamp } from 'lodash-es';

export interface ISegment {
    start: Point;
    end: Point;
    myFill?: {
        above: boolean | null;
        below: boolean | null;
    };
    otherFill?: {
        above: boolean | null;
        below: boolean | null;
    } | null;
}

/** Check whether a segment has an endpoint in origo */
export const isSegmentInOrigo = (segment: ISegment): boolean =>
    (segment.start[0] === 0 && segment.start[1] === 0) ||
    (segment.end[0] === 0 && segment.end[1] === 0);

const areSegmentsEqual = (a: ISegment, b: ISegment): boolean =>
    (a.start === b.start && a.end === b.end) || (a.start === b.end && a.end === b.start);

/**
 * Traverse all segments merge them so that points closer to each other than the
 * specified threshold snap to each other. Delete any zero length segments
 */
export function mergeSegments(segments: ISegment[], threshold = 0.01): ISegment[] {
    if (segments.length === 0) {
        return [];
    }

    const first = segments[0];
    const rest = segments.slice(1);

    if (distance(first.start, first.end) < threshold) {
        // segment is shorter than threshold, skip
        return mergeSegments(rest, threshold);
    }
    for (const segment of rest) {
        if (distance(first.start, segment.start) < threshold) {
            segment.start = first.start;
        }
        if (distance(first.start, segment.end) < threshold) {
            segment.end = first.start;
        }
        if (distance(first.end, segment.start) < threshold) {
            segment.start = first.end;
        }
        if (distance(first.end, segment.end) < threshold) {
            segment.end = first.end;
        }
    }

    const restWithoutDuplicates = rest.filter((segment) => !areSegmentsEqual(segment, first));

    return [first, ...mergeSegments(restWithoutDuplicates, threshold)];
}

/**
 * Orient segments clockwise, i.e. make sure they point in a clockwise direction
 * seen from origo
 *
 * @param segments - An array of segments
 * @returns The clockwise oriented segments
 */
export const orientSegmentsClockwise = (segments: ISegment[]): ISegment[] => {
    return segments.map((segment: ISegment) =>
        orientation(ORIGO, segment.start, segment.end) > 0
            ? segment
            : { start: segment.end, end: segment.start },
    );
};

/**
 * Optimize segments by ignoring segments that are already completely shadowed by
 * other segments
 *
 * @param segments - An array of segments
 * @returns The segments that are visible from the camera (origo)
 */
export function optimizeSegments(segments: ISegment[]): ISegment[] {
    // sort segments by distance
    const sorted = segments.sort(segmentDistanceComparator);

    // direct segments clockwise
    const directed = orientSegmentsClockwise(sorted);

    const optimized = simplify(directed);

    return optimized;
}

/**
 * Merge two line segments if they overlap or touch
 * The segments describe a clockwise rotation around origo and this rotation may be
 * more than 180 degrees.
 *
 * Special case: If the merged segments cover a full 360 degree rotation, a segment
 * from [0, 0] to [0, 0] is returned.
 *
 * @param a - The first segment
 * @param b - The second segment
 * @returns A merged segment or null if a and b can't be merged
 */
const mergeIfOverlaps = (a: ISegment, b: ISegment): ISegment | null => {
    if (equals(a.start, b.start) && equals(a.end, b.end)) {
        return a;
    }
    const startBetween = isBetween(a.start, a.end, b.start);
    const endBetween = isBetween(a.start, a.end, b.end);

    const overlapsClockwise = equals(a.end, b.start) || startBetween;
    const overlapsCounterClockwise = equals(b.end, a.start) || endBetween;

    if (overlapsClockwise && overlapsCounterClockwise) {
        // if both ends overlap we have two possible cases: Either the segments
        // completely overlap or they together describe a full 360 degree revolution
        const aOverlapsB = isBetween(b.start, b.end, a.start) && isBetween(b.start, b.end, a.end);
        if (aOverlapsB) {
            // Special case: 360 degrees
            return {
                start: [0, 0],
                end: [0, 0],
            };
        } else {
            return a;
        }
    } else if (overlapsClockwise) {
        return {
            start: a.start,
            end: b.end,
        };
    } else if (overlapsCounterClockwise) {
        return {
            start: b.start,
            end: a.end,
        };
    } else {
        return null;
    }
};

/**
 * Merge shadows so that overlapping or adjacent shadow segments are combined
 * The merged segments describe a clockwise rotation around origo and this rotation
 * may be more than 180 degrees.
 *
 * Special case: If the merged segments cover a full 360 degree rotation, a segment
 * from [0, 0] to [0, 0] is returned.
 *
 * @param segments - The shadow segments to be merged (clockwise oriented)
 * @returns An array of merged shadow segments
 */
export const mergeShadows = (segments: ISegment[]): ISegment[] => {
    if (segments.length === 0) {
        return segments;
    }

    const [first, ...rest] = segments;
    const unmerged: ISegment[] = [];

    let merged = first;

    const mergedRest = mergeShadows(rest); // recurse on rest

    // loop over merged rest and try to merge with this segment
    mergedRest.forEach((segment: ISegment) => {
        const result = mergeIfOverlaps(merged, segment);
        if (result === null) {
            unmerged.push(segment);
        } else {
            merged = result;
        }
    });

    return [merged, ...unmerged];
};

/**
 * Filter out shadowed segments
 *
 * @param segments - The segments to be filtered
 * @param shadowers - The segments shadowing
 * @returns All segments not shadowed by shadowers
 */
export const removeShadowed = (segments: ISegment[], shadowers: ISegment[]): ISegment[] => {
    return segments.filter((segment: ISegment) => {
        return !shadowers.some((shadower: ISegment) => completelyShadows(shadower, segment));
    });
};

/**
 * Simplify segments by removing segments that can't be seen by the camera (in origo)
 *
 * Assuems segments to be oriented clockwise
 *
 * @param segments - The segments to be simplified
 * @param [shadows] - Only used by recursive calls
 * @returns optimized segments
 */
const simplify = (segments: ISegment[], shadows: ISegment[] = []): ISegment[] => {
    const otherSegments: ISegment[] = [];
    const shadowingSegments: ISegment[] = [];

    // partition segments as shadowing and other. Shadowing segments aren't
    // shadowed themselves. Other segments may be shadowed
    segments.forEach((segment: ISegment) => {
        const isShadowed = segments.some((other: ISegment) => partiallyShadows(other, segment));

        if (isShadowed) {
            otherSegments.push(segment);
        } else {
            shadowingSegments.push(segment);
        }
    });

    const allShadows: ISegment[] = [...shadowingSegments, ...shadows];

    const shadowAreas = mergeShadows(allShadows);

    const simplified = removeShadowed(otherSegments, shadowAreas);

    return [
        ...shadowingSegments,
        ...(shadowingSegments.length > 0 ? simplify(simplified, shadowAreas) : simplified),
    ];
};

/**
 * Checks whether a segment partially shadows another segment
 *
 * @param shadower - The shadowing segment
 * @param shadowee - The segment to check
 * @returns Whether shadower partially shadows shadowee
 */
export const partiallyShadows = (shadower: ISegment, shadowee: ISegment): boolean => {
    if (shadower === shadowee) {
        return false;
    }

    const shadoweeStart: ISegment = {
        start: ORIGO,
        end: shadowee.start,
    };

    const shadoweeEnd: ISegment = {
        start: ORIGO,
        end: shadowee.end,
    };

    const intersectsStart = intersects(shadoweeStart, shadower);
    const intersectsEnd = intersects(shadoweeEnd, shadower);
    const intersectsEachOther = intersects(shadower, shadowee);

    const startBetween = isBetween(shadowee.start, shadowee.end, shadower.start, false);
    const shadowsBetween =
        startBetween && orientation(shadowee.start, shadowee.end, shadower.start) > 0;

    return intersectsStart || intersectsEnd || intersectsEachOther || shadowsBetween;
};

/**
 * Checks whether a segment completely shadows another segment
 *
 * @param shadower - The shadowing segment
 * @param shadowee - The segment to check
 * @returns Whether shadower completely shadows shadowee
 */
export const completelyShadows = (shadower: ISegment, shadowee: ISegment): boolean => {
    // Special case: A segment from [0, 0] to [0, 0] is to be considered as a full 260 degrees rotation
    if (equals(shadower.start, ORIGO) && equals(shadower.end, ORIGO)) {
        return true;
    }

    const startBetween = isBetween(shadower.start, shadower.end, shadowee.start);
    const endBetween = isBetween(shadower.start, shadower.end, shadowee.end);

    if (startBetween && endBetween) {
        const shadowStartsInSegment = isBetween(
            shadowee.start,
            shadowee.end,
            shadower.start,
            false,
        );
        const shadowEndsInSegment = isBetween(shadowee.start, shadowee.end, shadower.end, false);

        if (shadowStartsInSegment || shadowEndsInSegment) {
            return false;
        } else {
            return true;
        }
    }

    return false;
};

/**
 * Checks whether a point is on a line segment
 *
 * @param segment - The line segment
 * @param p - The point to check
 * @returns Whether the point p is on segment segment
 */
const onSegment = (segment: ISegment, p: Point): boolean => {
    return (
        p[0] <= Math.max(segment.start[0], segment.end[0]) &&
        p[0] >= Math.min(segment.start[0], segment.end[0]) &&
        p[1] <= Math.max(segment.start[1], segment.end[1]) &&
        p[1] >= Math.min(segment.start[1], segment.end[1])
    );
};

/**
 * Checks whether two line segments intersect
 * Touching end points don't count as an intersection
 *
 * See https://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
 *
 * @param a - The first segment
 * @param b - The second segment
 * @returns Whether a and b intersect
 */
export const intersects = (a: ISegment, b: ISegment): boolean => {
    if (
        equals(a.start, b.start) ||
        equals(a.start, b.end) ||
        equals(a.end, b.start) ||
        equals(a.end, b.end)
    ) {
        // don't count touching endpoints as intersection
        return false;
    }

    // Find the four orientations needed for general and
    // special cases
    const o1 = orientation(a.start, a.end, b.start);
    const o2 = orientation(a.start, a.end, b.end);
    const o3 = orientation(b.start, b.end, a.start);
    const o4 = orientation(b.start, b.end, a.end);

    // General case
    if (o1 !== o2 && o3 !== o4) {
        return true;
    }

    // Special Cases
    // a and b.start are colinear and b.start lies on segment a
    if (o1 === 0 && onSegment(a, b.start)) {
        return true;
    }

    // a and b.end are colinear and b.end lies on segment a
    if (o2 === 0 && onSegment(a, b.end)) {
        return true;
    }

    // b and a.start are colinear and a.start lies on segment b
    if (o3 === 0 && onSegment(b, a.start)) {
        return true;
    }

    // b and a.end are colinear and a.end lies on segment b
    if (o4 === 0 && onSegment(b, a.end)) {
        return true;
    }

    return false; // Doesn't fall in any of the above cases
};

/**
 * Comparator function comparing the mimimum distance to origo for two line segments
 *
 * @param a - The first segment
 * @param b - The second segment
 * @returns A number indicating the order of a and b
 *         -1 if a is closer to origo than b
 *          1 if b is closer to origo than a
 *          0 if both segments are have same distance to origo
 */
const segmentDistanceComparator = (a: ISegment, b: ISegment): number => {
    const distanceDiff = distanceToSegment(a, ORIGO) - distanceToSegment(b, ORIGO);
    if (distanceDiff === 0) {
        // if distances are equal it probably means we have a corner closest to the camera.
        // If both endpoint vectors of segment b point in directions between the endpoint
        // vectors of segment a we know that segment b is behind a
        const bBehindA = isBetween(a.start, a.end, b.start) && isBetween(a.start, a.end, b.end);

        return bBehindA ? -1 : 1;
    }
    return distanceDiff;
};

/** convert a line to an array of line segments */
const convertLineToSegments = (line: Point[]): ISegment[] => {
    if (line.length < 2) {
        return [];
    }

    return line.reduce((prev, current, index, array) => {
        const next = array[index + 1];
        const segment = next
            ? {
                  start: current,
                  end: next,
              }
            : {
                  start: array[index - 1],
                  end: current,
              };
        return [...prev, segment];
    }, [] as ISegment[]);
};

/** Convert leaflet layers to line segments for which we can render shadows */
export const convertLayersToSegments = (blockers: PolyLine[], origin: L.LatLng): ISegment[] =>
    blockers.flatMap((blocker) => convertLineToSegments(blocker.map(getOffset(origin))));

export const distanceToSegment = (segment: ISegment, point: Point): number => {
    // vector from segment start to point
    const startToPoint = diff(point, segment.start);

    // vector from segment start to end
    const startToEnd = diff(segment.end, segment.start);

    // squared distance of segment
    const squareDistance = startToEnd[0] * startToEnd[0] + startToEnd[1] * startToEnd[1];

    // project startToPoint on startToEnd and clamp the result in the range [0...1]
    const param = clamp(dotProduct(startToEnd, startToPoint) / squareDistance, 0, 1);

    // construct the closest point
    const closestPoint = param === 1 ? segment.end : add(segment.start, mul(startToEnd, param));

    return distance(closestPoint, point);
};
