import { Module } from 'app/ioc';
import { DocumentationActionService, DocumentationService } from './services';
import { DocumentationCommunicator } from './services/Documentation.communicator';
import { documentationReducer } from './DocumentationReducer';

@Module({
    binder: (binder) => {
        binder.bindService(DocumentationService);
        binder.bindService(DocumentationCommunicator);
        binder.bindService(DocumentationActionService);
    },
    reducers: {
        documentation: documentationReducer,
    },
})
export class DocumentationModule {}
