import { reducerBuilder } from 'app/store';
import type { IProjectDevicesContainerState, IProjectDevicePanelItem } from './models';
import { ProjectDevicesActions } from './projectDevices.actions';
import { defaultDeviceListSortOrder, type IProjectDeviceSort, type Id } from 'app/core/persistence';

const initialState: IProjectDevicesContainerState = {
    deviceFilter: '',
    sortOrder: defaultDeviceListSortOrder,
    expandedDevices: [],
};

export const projectDevicesReducer = reducerBuilder<IProjectDevicesContainerState>()
    .setInitialState(initialState)
    .onAction<string>(ProjectDevicesActions.ResetToInitialState, () => ({
        ...initialState,
    }))
    .onAction<string>(ProjectDevicesActions.DeviceFilterChange, (state, action) => ({
        ...state,
        deviceFilter: action.payload,
    }))
    .onAction<IProjectDeviceSort>(ProjectDevicesActions.SetSortOrder, (state, action) => ({
        ...state,
        sortOrder: action.payload,
    }))
    .onAction<IProjectDevicePanelItem>(ProjectDevicesActions.SetPanelItem, (state, action) => ({
        ...state,
        panelItem: {
            ...state.panelItem,
            ...action.payload,
        },
    }))
    .onAction<Id | undefined>(
        ProjectDevicesActions.SetMultipleCopiesModalData,
        (state, action) => ({
            ...state,
            multipleCopiesModalDeviceId: action.payload,
        }),
    )
    .onAction<Id[]>(ProjectDevicesActions.ToggleExpandedDevice, (state, action) => ({
        ...state,
        expandedDevices: action.payload,
    }))
    .create();
