export interface IUserDBError {
    error: string;
    reason: string;
    statusCode: number;
}

export class UserDBError extends Error {
    public statusCode: number;
    public error: string;
    constructor(statusCode: number, response: IUserDBError) {
        super(`${statusCode}: ${response.error}, ${response.reason}`);

        this.statusCode = statusCode;
        this.error = response.error;
    }
}
