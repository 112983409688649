import { t } from 'app/translate';
import * as React from 'react';
import {
    Box,
    DropDown,
    Button,
    DualRange,
    PillButton,
    Stack,
    Text,
    Label,
    Border,
} from 'app/components';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { TemperatureScale } from 'app/core/persistence';

import { convert } from 'axis-webtools-util';
import { getOperationalTemperaturesMinMax } from '../../../selectors';

interface IOperationalTemperatureFilterProps {
    filterActive: boolean;
    filterMin: number;
    filterMax: number;
    setOperationalTemperature(min: number, max: number): void;
    inactivateOperationalTemperature(min: number, max: number): void;
}

export const OperationalTemperatureFilter: React.FC<IOperationalTemperatureFilterProps> = ({
    filterActive,
    filterMin,
    filterMax,
    setOperationalTemperature,
    inactivateOperationalTemperature,
}) => {
    const [temperatureRange, setTemperatureRange] = React.useState('');

    const temperatureScale = useSelector<IStoreState, TemperatureScale | undefined>(
        (store) => store.currentProject.project?.temperatureScale,
    );
    const temperatureUnit =
        temperatureScale === 'fahrenheit'
            ? t.abbreviationsGROUP.fahrenheit
            : t.abbreviationsGROUP.celsius;

    const [minTemperature, maxTemperature] = useSelector<IStoreState, number[]>((store) =>
        getOperationalTemperaturesMinMax(store),
    );

    const getTemperature = React.useCallback(
        (tempC: number) => {
            return temperatureScale === 'fahrenheit'
                ? Math.round(convert.celsiusToFahrenheit(tempC))
                : tempC;
        },
        [temperatureScale],
    );
    React.useEffect(() => {
        const operationalTemperatureText = `${getTemperature(filterMin)} – ${getTemperature(
            filterMax,
        )}${temperatureUnit}`;
        setTemperatureRange(operationalTemperatureText);
    }, [filterActive, filterMin, filterMax, temperatureScale, getTemperature, temperatureUnit]);

    return (
        <DropDown
            stayOpen
            openInPortal
            minWidth={300}
            trigger={
                <PillButton
                    text={filterActive ? temperatureRange : t.operationalTemperature}
                    // Setting min width (adjusted to worst case language) to prevent jumpy UI
                    minWidth={246}
                    icon="keyboard_arrow_down"
                    selected={filterActive}
                    onClick={() => setOperationalTemperature(filterMin, filterMax)}
                />
            }
            contents={
                <Stack vertical spacing="none" lineBetweenColor="grey3">
                    <Box
                        width="100%"
                        color="white"
                        direction="column"
                        padding="half"
                        paddingBottom="base"
                    >
                        <Box paddingBottom="panel">
                            <Label opaque>
                                {t.operationalTemperature}
                                {
                                    <>
                                        :
                                        <Text style="semibold" inline color="grey6">
                                            {` ${temperatureRange}`}
                                        </Text>
                                    </>
                                }
                            </Label>
                        </Box>

                        <Stack alignItems="end" spacing="none">
                            <Border rightWidth={1} color="grey3">
                                <Box paddingRight="half" height="30px" alignItems="center">
                                    <Text style="small">
                                        {`${getTemperature(minTemperature)}${temperatureUnit}`}
                                    </Text>
                                </Box>
                            </Border>
                            <DualRange
                                rangeMin={getTemperature(minTemperature)}
                                rangeMax={getTemperature(maxTemperature)}
                                minValue={getTemperature(filterMin)}
                                maxValue={getTemperature(filterMax)}
                                minLabel={`${getTemperature(filterMin)}${temperatureUnit}`}
                                maxLabel={`${getTemperature(filterMax)}${temperatureUnit}`}
                                onChange={(newMin, newMax) =>
                                    setOperationalTemperature(
                                        temperatureScale === 'fahrenheit'
                                            ? Math.round(convert.fahrenheitToCelsius(newMin))
                                            : newMin,
                                        temperatureScale === 'fahrenheit'
                                            ? Math.round(convert.fahrenheitToCelsius(newMax))
                                            : newMax,
                                    )
                                }
                            />
                            <Border leftWidth={1} color="grey3">
                                <Box paddingLeft="half" height="30px" alignItems="center">
                                    <Text style="small">
                                        {`${getTemperature(maxTemperature)}${temperatureUnit}`}
                                    </Text>
                                </Box>
                            </Border>
                        </Stack>
                    </Box>
                    <Box color="white" alignItems="end" justifyContent="end">
                        <Button
                            text
                            onClick={() => inactivateOperationalTemperature(filterMin, filterMax)}
                        >
                            {t.clear}
                        </Button>
                    </Box>
                </Stack>
            }
        />
    );
};

OperationalTemperatureFilter.displayName = 'OperationalTemperature';
