import * as React from 'react';
import { t } from 'app/translate';
import { EditableText, Heading, Card } from 'app/components';
import { AppConstants } from 'app/AppConstants';

interface IEditableNameContextItemProps {
    ipName: string;
    onNameChange(name: string): void;
}

export const EditableNameContextItem: React.FunctionComponent<IEditableNameContextItemProps> = ({
    ipName,
    onNameChange,
}) => {
    return (
        <Card paddingY="base" paddingX="base">
            <Heading width="100%">
                <EditableText
                    value={ipName}
                    maxLength={AppConstants.deviceNameMaxLength}
                    onChange={onNameChange}
                    placeholder={t.name}
                />
            </Heading>
        </Card>
    );
};

EditableNameContextItem.displayName = 'EditableNameContextItem';
