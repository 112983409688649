import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import { getRecommendedProductIds } from './getRecommendedProducts';
import { getSelectedProductId } from './getProductItem';

const isSelectedDeviceRecommended = createSelector(
    [getRecommendedProductIds, getSelectedProductId],
    (recommended, selectedId) => selectedId !== null && recommended.includes(selectedId),
);

const isRecommendationVisible = (state: IStoreState) =>
    state.deviceSelector.topRecommendationVisible;

export const isSelectedDeviceVisibleAsRecommendation = createSelector(
    [isSelectedDeviceRecommended, isRecommendationVisible],
    (selectedDeviceIsRecommended, recommendationIsVisible) =>
        selectedDeviceIsRecommended && recommendationIsVisible,
);
