import { AppConstants } from 'app/AppConstants';
import { getAllSpeakers, getCurrentProjectItem, toCacheKey } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';
import { getCeilingSpeakerQuantity, getWallSpeakerQuantity } from '../utils';
import { getSelectedProductId } from './getProductItem';

export const getSpeakerFilter = (state: IStoreState) => state.deviceSelector.speakerFilter;

export const getSelectedSpeaker = createCachedSelector(
    [getSelectedProductId, getCurrentProjectItem, getAllSpeakers],
    (selectedProductId, device, speakers) => {
        if (selectedProductId) {
            return speakers.find((speaker) => speaker.id === selectedProductId);
        } else if (device) {
            return speakers.find((speaker) => speaker.id === device.productId);
        }
        return undefined;
    },
)(toCacheKey);

export const getCalculatedSpeakerQuantity = createCachedSelector(
    [getSelectedSpeaker, getSpeakerFilter],
    (selectedSpeaker, speakerFilter) => {
        if (selectedSpeaker && speakerFilter.placement) {
            return speakerFilter.placement === 'wall' && speakerFilter.wallLength
                ? Math.min(
                      getWallSpeakerQuantity(
                          speakerFilter.installationHeight,
                          speakerFilter.wallLength,
                          speakerFilter.basicSolution,
                          selectedSpeaker.properties.formFactor
                              ? selectedSpeaker.properties.formFactor
                              : '',
                          speakerFilter.outdoor,
                      ),
                      AppConstants.componentQuantityMax,
                  )
                : speakerFilter.placement === 'ceiling' && speakerFilter.listeningArea
                  ? Math.min(
                        getCeilingSpeakerQuantity(
                            speakerFilter.installationHeight,
                            speakerFilter.listeningArea,
                            speakerFilter.basicSolution,
                            Math.max(
                                selectedSpeaker.properties.horizontalSpeakerCoverage,
                                selectedSpeaker.properties.verticalSpeakerCoverage,
                            ),
                        ),
                        AppConstants.componentQuantityMax,
                    )
                  : 0;
        }
        return 0;
    },
)(toCacheKey);
