import { BufferGeometry, BufferAttribute, EdgesGeometry, Vector3, Plane, Line3 } from 'three';
import { getClosestIntersection, getPlaneFromPoints } from '../../math';
import { calculateSquareEdgeVectors } from '../../../utils';
import type { IConeGeometry } from './IConeGeometry';

const LONG_DISTANCE = 1000;
const SHORT_DISTANCE = 1e-3;

const mapToPlane = (plane: Plane) => (vector: Vector3) => {
    const up = new Line3(
        vector.clone().add(new Vector3(0, -SHORT_DISTANCE, 0)),
        vector.clone().add(new Vector3(0, LONG_DISTANCE, 0)),
    );

    return (getClosestIntersection(up, [plane]) ?? new Vector3(1, 0, 0)).normalize();
};

const getUpperEdgeVectors = (
    tiltAngle: number,
    topVectors: Vector3[],
    rightVectors: Vector3[],
    bottomVectors: Vector3[],
    leftVectors: Vector3[],
) => {
    if (tiltAngle > 0) {
        return [...leftVectors, ...topVectors, ...rightVectors, bottomVectors[0]];
    } else {
        // camera is pointing upwards, i.e. top is "wider" than bottom.
        // Map bottom, and side vectors to top
        const topVectorPlane = getPlaneFromPoints(
            new Vector3(0, 0, 0),
            topVectors[0],
            topVectors[topVectors.length - 1],
        );

        return [...rightVectors, ...bottomVectors, ...leftVectors, topVectors[0]]
            .reverse()
            .map(mapToPlane(topVectorPlane));
    }
};

const getLowerEdgeVectors = (
    tiltAngle: number,
    topVectors: Vector3[],
    rightVectors: Vector3[],
    bottomVectors: Vector3[],
    leftVectors: Vector3[],
) => {
    if (tiltAngle > 0) {
        return bottomVectors;
    } else {
        return [...rightVectors, ...bottomVectors, ...leftVectors, topVectors[0]];
    }
};

export const getCameraCone = (
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    tiltAngle: number,
    intersectWithBlockers: (vector: Vector3) => Vector3,
    resolution = 500,
): IConeGeometry => {
    const { topVectors, rightVectors, bottomVectors, leftVectors } = calculateSquareEdgeVectors(
        horizontalFov,
        verticalFov,
        tiltAngle,
        resolution,
    );

    const upperEdges = getUpperEdgeVectors(
        tiltAngle,
        topVectors,
        rightVectors,
        bottomVectors,
        leftVectors,
    ).map((vector) => intersectWithBlockers(vector));

    const lowerEdges = getLowerEdgeVectors(
        tiltAngle,
        topVectors,
        rightVectors,
        bottomVectors,
        leftVectors,
    ).map((vector) => intersectWithBlockers(vector));

    // construct the planes bounding the FOV cone
    const bottomPlane = getPlaneFromPoints(
        new Vector3(0, 0, 0),
        bottomVectors[0],
        bottomVectors[bottomVectors.length - 1],
    );
    const leftPlane = getPlaneFromPoints(
        new Vector3(0, 0, 0),
        leftVectors[0],
        leftVectors[leftVectors.length - 1],
    );
    const rightPlane = getPlaneFromPoints(
        new Vector3(0, 0, 0),
        rightVectors[0],
        rightVectors[rightVectors.length - 1],
    );
    const groundPlane = new Plane(new Vector3(0, 1, 0), cameraHeight);

    const planes = [leftPlane, bottomPlane, rightPlane, groundPlane];

    const camVertex = [0, 0, 0];

    // construct the geometry by adding all the vertices
    const verticeArray = [];

    for (let i = 0; i < upperEdges.length - 1; i++) {
        const isFirst = i === 0;
        const isLast = i === upperEdges.length - 1;

        const lineStartOffset = tiltAngle > 0 && !isFirst ? -SHORT_DISTANCE : SHORT_DISTANCE;
        const nextLineStartOffset = tiltAngle > 0 && !isLast ? -SHORT_DISTANCE : SHORT_DISTANCE;

        const vector = upperEdges[i];
        const nextVector = upperEdges[i + 1];

        // construct a long line from vector and straight down
        const lineToFloor = new Line3(
            vector.clone().add(new Vector3(0, lineStartOffset, 0)),
            vector.clone().add(new Vector3(0, -LONG_DISTANCE, 0)),
        );

        // construct a long line from next vector and straight down
        const nextLineToFloor = new Line3(
            nextVector.clone().add(new Vector3(0, nextLineStartOffset, 0)),
            nextVector.clone().add(new Vector3(0, -LONG_DISTANCE, 0)),
        );

        // find orthogonal projection on floor/cone bounds of vector
        const vectorFloor = getClosestIntersection(lineToFloor, planes);
        // find orthogonal projection on floor/cone bounds of next vector
        const nextVectorFloor = getClosestIntersection(nextLineToFloor, planes);

        // construct face between camera, and both edge vectors
        verticeArray.push(...camVertex);
        verticeArray.push(...vector.toArray());
        verticeArray.push(...nextVector.toArray());

        if (vectorFloor) {
            // construct faces between edge and floor/lower cone bound
            verticeArray.push(...vector.toArray());
            verticeArray.push(...nextVector.toArray());
            verticeArray.push(...vectorFloor.toArray());

            if (nextVectorFloor) {
                verticeArray.push(...nextVector.toArray());
                verticeArray.push(...nextVectorFloor.toArray());
                verticeArray.push(...vectorFloor.toArray());
            }
        }
    }

    // add lower faces
    for (let i = 0; i < lowerEdges.length - 1; i++) {
        const vector = lowerEdges[i];
        const nextVector = lowerEdges[i + 1];

        verticeArray.push(...camVertex);
        verticeArray.push(...vector.toArray());
        verticeArray.push(...nextVector.toArray());
    }

    const vertices = new Float32Array(verticeArray);

    const geometry = new BufferGeometry();

    geometry.setAttribute('position', new BufferAttribute(vertices, 3));

    // cancel rotation used when calculating intersections. The geometry is rotated
    // together with the axis camera model later
    geometry.rotateZ(tiltAngle);

    const edges = new EdgesGeometry(geometry, 20);

    return {
        geometry,
        edges,
    };
};
