import { getParentId, ScheduleModelService } from 'app/core/persistence';
import { nameComparator } from 'app/utils';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { toCacheKey } from '../../cacheKey/toCacheKey';
import {
    getCurrentProjectSchedules,
    getCurrentProjectSchedulesArray,
    getCurrentProjectTimeSeriesArray,
} from '../../project/selectors';
import { getScheduleIdFromProps } from '../../selectors/getIdFromProps';

export const getSchedule = (scheduleId: string | undefined) =>
    createSelector([getCurrentProjectSchedules], (schedules) => {
        return scheduleId ? schedules[scheduleId] : undefined;
    });

export const getScheduleWithId = createCachedSelector(
    [getCurrentProjectSchedules, getScheduleIdFromProps],
    (currentSchedules, scheduleId) => {
        if (!scheduleId) {
            return undefined;
        }
        return currentSchedules[scheduleId];
    },
)(toCacheKey);

export const getSchedules = createSelector([getCurrentProjectSchedulesArray], (schedules) =>
    schedules.sort(nameComparator),
);

export const getScheduleModels = createSelector(
    [getSchedules, getCurrentProjectTimeSeriesArray],
    (schedules, timeSeries) => {
        return schedules.map((schedule) =>
            ScheduleModelService.mapToScheduleModel(
                schedule,
                ScheduleModelService.mapToTimeSeriesModels(
                    schedule._id,
                    timeSeries
                        .filter((ts) => getParentId(ts) === schedule._id)
                        .sort((serieA, serieB) => {
                            return serieA.creationDate > serieB.creationDate
                                ? 1
                                : serieA.creationDate < serieB.creationDate
                                  ? -1
                                  : 0;
                        }),
                ),
            ),
        );
    },
);
