import type { DeviceAndSubType, DeviceType, SubType } from '../models';
import type {
    IDoorStationItemEntity,
    ICameraItemEntity,
    IEncoderItemEntity,
    IAnalogCameraItemEntity,
    IMainUnitItemEntity,
    ISensorUnitItemEntity,
    ISpeakerItemEntity,
    IRadarItemEntity,
    IPacItemEntity,
    IDecoderItemEntity,
    IItemEntity,
    IPeopleCounterItemEntity,
    IBodyWornCameraItemEntity,
    IDockingStationItemEntity,
    ISystemControllerItemEntity,
    IItemPropertiesEntity,
    ICameraExtensionItemEntity,
    ICustomCameraItemEntity,
    IApplicationItemEntity,
    IAccessoryItemEntity,
    IPersistableRangeType,
    IDoorItemEntity,
    IDoorControllerItemEntity,
    IAlerterItemEntity,
    IVirtualProductItemEntity,
    IPersistence,
    IConnectivityDeviceItemEntity,
    IPagingConsoleItemEntity,
} from '../userDataPersistence';
import type { IPiaItem, IPiaSystemComponent } from 'app/core/pia';
import { PiaAccessoryCategory, PiaItemPacCategory, PiaItemRecorderCategory } from 'app/core/pia';
import type { Colors } from 'app/styles';
import {
    isDecoder,
    isIoRelay,
    isPeopleCounter,
    isRadar,
    isSystemController,
    isAlerter,
    isVirtualCamera,
} from './piaDeviceTypeCheckers';
import { UnreachableCaseError } from 'axis-webtools-util';

const UNMANAGED = 'UNMANAGED';

export const isDevice = (item: IItemEntity): boolean =>
    Object.values(deviceTypeCheckers).some((fn) => fn(item));

export const deviceTypeCheckers = {
    isDoorStation: (item: IItemEntity): item is IDoorStationItemEntity =>
        !!item.properties.camera && !!item.properties.pac,
    isPeopleCounter: (item: IItemEntity): item is IPeopleCounterItemEntity =>
        !!item.properties.peopleCounter,
    isCamera: (item: IItemEntity): item is ICameraItemEntity => !!item.properties.camera,
    isAccessory: (item: IItemEntity): item is IAccessoryItemEntity => !!item.properties.accessory,
    isEncoder: (item: IItemEntity): item is IEncoderItemEntity => !!item.properties.encoder,
    isAnalogCamera: (item: IItemEntity): item is IAnalogCameraItemEntity =>
        !!item.properties.analogCamera,
    isMainUnit: (item: IItemEntity): item is IMainUnitItemEntity => !!item.properties.mainUnit,
    isSensorUnit: (item: IItemEntity): item is ISensorUnitItemEntity =>
        !!item.properties.sensorUnit,
    isSpeaker: (item: IItemEntity): item is ISpeakerItemEntity => !!item.properties.speaker,
    isVirtualProduct: (item: IItemEntity): item is IVirtualProductItemEntity =>
        !!item.properties.virtualProduct,
    isDecoder: (item: IItemEntity): item is IDecoderItemEntity => !!item.properties.decoder,
    isRadarDetector: (item: IItemEntity): item is IRadarItemEntity =>
        !!item.properties.radarDetector,
    isConnectivityDevice: (item: IItemEntity): item is IConnectivityDeviceItemEntity =>
        !!item.properties.connectivityDevice,
    isPagingConsole: (item: IItemEntity): item is IPagingConsoleItemEntity =>
        !!item.properties.pagingConsole,
    isPac: (item: IItemEntity): item is IPacItemEntity => !!item.properties.pac,
    isBodyWornCamera: (item: IItemEntity): item is IBodyWornCameraItemEntity =>
        !!item.properties.bodyWornCamera,
    isSystemController: (item: IItemEntity): item is ISystemControllerItemEntity =>
        !!item.properties.systemController,
    isDockingStation: (item: IItemEntity): item is IDockingStationItemEntity =>
        !!item.properties.dockingStation,
    isCameraExtension: (item: IItemEntity): item is ICameraExtensionItemEntity =>
        !!item.properties.cameraExtension,
    isApplication: (item: IItemEntity): item is IApplicationItemEntity =>
        !!item.properties.application,
    isDoor: (item: IItemEntity): item is IDoorItemEntity => !!item.properties.door,
    isDoorController: (item: IItemEntity): item is IDoorControllerItemEntity =>
        !!item.properties.doorController,
    isAlerter: (item: IItemEntity): item is IAlerterItemEntity => !!item.properties.alerter,
    isWearable: (
        item: IItemEntity,
    ): item is
        | IDockingStationItemEntity
        | ISystemControllerItemEntity
        | IBodyWornCameraItemEntity
        | ICameraExtensionItemEntity =>
        deviceTypeCheckers.isDockingStation(item) ||
        deviceTypeCheckers.isSystemController(item) ||
        deviceTypeCheckers.isBodyWornCamera(item) ||
        deviceTypeCheckers.isCameraExtension(item),
    isSystemComponent: (item: IItemEntity): item is IItemEntity =>
        !!item.properties.systemComponent,
    isPartnerSystemComponent: (item: IItemEntity): item is IItemEntity =>
        !!item.properties.partnerSystemComponent,
};

export const isDeviceSpecified = (
    device: IItemEntity,
): device is IItemEntity & { productId: number } => device.productId !== null;

export const isCustomCamera = (item: IItemEntity | undefined): item is ICustomCameraItemEntity =>
    !!item?.properties.camera?.customCameraProperties?.activated;

export const isDeviceByEntity = (item: IItemEntity): boolean =>
    Object.values(deviceTypeCheckers).some((checker) => checker(item));

export const getDeviceType = (item: IItemEntity, piaItem?: IPiaItem): DeviceType | undefined => {
    if (deviceTypeCheckers.isDoorStation(item)) {
        return 'doorstation';
    } else if (deviceTypeCheckers.isPeopleCounter(item)) {
        return 'peopleCounter';
    } else if (deviceTypeCheckers.isCamera(item)) {
        return 'camera';
    } else if (deviceTypeCheckers.isEncoder(item)) {
        return 'encoder';
    } else if (deviceTypeCheckers.isAnalogCamera(item)) {
        return 'analogCamera';
    } else if (deviceTypeCheckers.isMainUnit(item)) {
        return 'mainUnit';
    } else if (deviceTypeCheckers.isSensorUnit(item)) {
        return 'sensorUnit';
    } else if (deviceTypeCheckers.isSpeaker(item)) {
        return 'speaker';
    } else if (deviceTypeCheckers.isDecoder(item)) {
        return 'decoder';
    } else if (deviceTypeCheckers.isRadarDetector(item)) {
        return 'radardetector';
    } else if (deviceTypeCheckers.isPac(item)) {
        return 'pac';
    } else if (deviceTypeCheckers.isBodyWornCamera(item)) {
        return 'bodyWornCamera';
    } else if (deviceTypeCheckers.isSystemController(item)) {
        return 'systemController';
    } else if (deviceTypeCheckers.isDockingStation(item)) {
        return 'dockingStation';
    } else if (deviceTypeCheckers.isCameraExtension(item)) {
        return 'cameraExtension';
    } else if (deviceTypeCheckers.isDoor(item)) {
        return 'door';
    } else if (deviceTypeCheckers.isDoorController(item)) {
        return 'doorcontroller';
    } else if (deviceTypeCheckers.isAlerter(item)) {
        return 'alerter';
    } else if (isVirtualCamera(piaItem)) {
        return 'camera';
    } else if (deviceTypeCheckers.isConnectivityDevice(item)) {
        return 'connectivitydevice';
    } else if (deviceTypeCheckers.isPagingConsole(item)) {
        return 'pagingconsole';
    } else {
        return undefined;
    }
};

/**
 * NOTE: For logging purposes only
 */
export const getDeviceTypeLogging = (item: IItemEntity): string => {
    const deviceType = getDeviceType(item);
    if (!deviceType) {
        return 'Could not map DeviceType to unknown IItemEntity';
    }
    return deviceType.valueOf();
};

export const getSubType = (piaItem: IPiaItem | undefined): SubType | null => {
    switch (piaItem?.category) {
        case 'doorcontrollers':
            return 'doorcontroller';
        case 'doorstations':
            return 'doorstation';
        case 'iorelays':
            return 'iorelay';
        case 'accessServers':
            return 'accessServer';
        case 'answeringUnits':
            return 'answeringUnit';
        case 'networkReaders':
            return 'networkReader';
        case 'relayexpmodules':
            return 'relayexpmodules';
        default:
            return null;
    }
};

const getIsNetworkCapableSwitch = (piaDevice: IPiaItem | null): boolean =>
    piaDevice?.category === PiaAccessoryCategory.NETWORKSWITCHES &&
    !piaDevice?.name.toUpperCase().includes(UNMANAGED);

const getIsNetworkCapableSystemComponent = (
    properties: IItemPropertiesEntity,
    piaDevice: IPiaItem | null,
): boolean => {
    if (!properties.systemComponent || !piaDevice?.category) {
        return false;
    }
    const { category } = piaDevice;
    return (
        category === PiaItemRecorderCategory.DESKTOPTERMINALS ||
        category === PiaItemRecorderCategory.RECORDERS2 ||
        getIsNetworkCapableSwitch(piaDevice)
    );
};

/**
 * Wether this item requires an ethernet port
 */
export const needsPort = (item: IPersistence<IItemEntity>, piaItem?: IPiaItem) =>
    ((item.properties.camera ||
        item.properties.encoder ||
        item.properties.mainUnit ||
        item.properties.speaker ||
        (item.properties.pac && piaItem?.category !== PiaItemPacCategory.RELAYEXPMODULES) ||
        item.properties.decoder ||
        item.properties.peopleCounter ||
        item.properties.systemController ||
        item.properties.doorController ||
        item.properties.radarDetector ||
        item.properties.connectivityDevice ||
        item.properties.pagingConsole ||
        item.properties.alerter) &&
        item.productId !== null) ||
    isCustomCamera(item);

/**
 * Wether this items requires a VMS license or not
 */
export const needsLicense = (item: IPersistence<IItemEntity>, piaItem?: IPiaItem): boolean => {
    const isLicenseDevice =
        (item.properties.alerter ||
            item.properties.bodyWornCamera ||
            item.properties.camera ||
            item.properties.decoder ||
            item.properties.doorController ||
            item.properties.encoder ||
            item.properties.mainUnit ||
            (item.properties.pac && piaItem?.category !== PiaItemPacCategory.RELAYEXPMODULES) ||
            item.properties.peopleCounter ||
            item.properties.radarDetector ||
            item.properties.connectivityDevice ||
            item.properties.pagingConsole ||
            item.properties.speaker) &&
        item.productId !== null;

    const isGeneric = Boolean(item.properties.camera?.customCameraProperties?.activated);

    return isLicenseDevice || isGeneric;
};

export const hasNetworkSettingsCapability = (
    properties: IItemPropertiesEntity,
    piaDevice: IPiaItem | null,
): boolean => {
    return (
        !!properties.camera ||
        !!properties.mainUnit ||
        !!properties.encoder ||
        !!properties.speaker ||
        // Access control (Intercoms pass check as cameras)
        !!properties.doorController ||
        isIoRelay(piaDevice) ||
        // Wearables
        isSystemController(piaDevice) ||
        // "Other" devices
        isPeopleCounter(piaDevice) ||
        isRadar(piaDevice) ||
        isDecoder(piaDevice) ||
        isAlerter(piaDevice) ||
        // Recorders
        getIsNetworkCapableSystemComponent(properties, piaDevice)
    );
};

export const getNetworkRangeType = (
    properties: IItemPropertiesEntity,
    piaDevice: IPiaSystemComponent | null,
): IPersistableRangeType | undefined => {
    if (!hasNetworkSettingsCapability(properties, piaDevice)) {
        return undefined;
    }

    // Type of device for a network range.
    if (properties.camera) {
        return 'cameras';
    } else if (properties.systemComponent) {
        if (
            piaDevice?.category === PiaItemRecorderCategory.DESKTOPTERMINALS ||
            piaDevice?.category === PiaItemRecorderCategory.RECORDERS2 ||
            getIsNetworkCapableSwitch(piaDevice)
        ) {
            // PIA item is a recorder.
            return 'recorders';
        } else {
            // PIA item can't have network settings.
            return undefined;
        }
    } else {
        return 'other';
    }
};

export const customCameraImage = require('src/assets/images/videoCam-grey.png');

export const getDeviceImage = (
    productId: number | null | undefined,
    entity: IItemEntity | null | undefined,
): string => {
    if (!entity) return require(`src/assets/images/camera-grey.png`);
    if (isCustomCamera(entity)) return customCameraImage;

    const deviceType = getDeviceType(entity);
    return getDeviceImageByType(productId, deviceType);
};

/**
 * Will return icon for device when tiny icon is enabled
 */
export const getDeviceIcon = (entity: IItemEntity | null | undefined) => {
    const deviceType = entity ? getDeviceType(entity) : undefined;
    if (!deviceType) return require('src/assets/images/generic-device-grey.png');

    switch (deviceType) {
        case 'camera':
        case 'doorstation':
            return require(`src/assets/images/videoCam-grey.png`);
        case 'encoder':
        case 'analogCamera':
        case 'mainUnit':
        case 'sensorUnit':
        case 'speaker':
        case 'radardetector':
        case 'doorcontroller':
        case 'door':
        case 'pagingconsole':
            return require(`src/assets/images/${deviceType}-grey.png`);
        case 'decoder':
        case 'pac':
        case 'peopleCounter':
        case 'bodyWornCamera':
        case 'systemController':
        case 'dockingStation':
        case 'cameraExtension':
        case 'alerter':
            return require(`src/assets/images/generic-device-grey.png`);
        case 'connectivitydevice':
            return require(`src/assets/images/mainUnit-grey.png`);
        default:
            throw new UnreachableCaseError(deviceType);
    }
};

/**
 * Will return piaImage for device, or the generic image based on device type
 * NOTE: images for new device types must be named as device type.
 */
export const getDeviceImageByType = (
    productId: number | null | undefined,
    deviceType: DeviceAndSubType | undefined,
    color?: Colors,
): string => {
    try {
        return productId
            ? `https://www.axis.com/images/products/${productId}-50.png`
            : require(`src/assets/images/${deviceType}-${color || 'grey'}.png`);
    } catch (error) {
        return require(`src/assets/images/camera-grey.png`);
    }
};
