import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Removes the dayDetail and nightDetail properties from the IScenarioEntity
 */
@injectable()
export class Migration5To6 extends MigrationProviderBase {
    public from: number = 5;
    public to: number = 6;

    public provideMigration() {
        return (entity: any): Promise<any> => {
            if (entity.type === 'profile') {
                delete entity.scenario.dayDetail;
                delete entity.scenario.nightDetail;
            }

            return Promise.resolve(entity);
        };
    }
}
