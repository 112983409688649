import * as React from 'react';
import {
    Text,
    ReadDirection,
    PiaImage,
    Button,
    Box,
    Quantity,
    Badge,
    IconButton,
} from 'app/components';
import {
    getSelectedRecordingSolutionType,
    getSelectedRecordingVendor,
    PiaItemMsrp,
    Subscription,
} from 'app/modules/common';
import { format } from 'axis-webtools-util';
import { t } from 'app/translate';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { RecordingSelectorService } from '../services/RecordingSelector.service';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { AppConstants } from 'app/AppConstants';
import { RecordingChanger } from './RecordingChanger.container';
import { getViewingAxisAcsType, isCurrentSolutionEmpty } from '../selectors';
import type {
    CameraStationType,
    RecordingSolutionType,
    SelectedVendorType,
} from 'app/core/persistence';
import { PartnerImage } from './PartnerImage';
import { useSelector } from 'react-redux';
import type { IRecordingListItem } from '../models/IRecordingListItem';
import { isSingleQuantityCategory } from 'app/core/pia';

const selectedBackground = css`
    background: ${ColorsEnum.yellow1};
`;

const addItem = async (
    item: IRecordingListItem,
    isSolutionEmpty: boolean,
    selectedRecordingSolutionType: RecordingSolutionType,
    selectedVendorName: SelectedVendorType | undefined,
    viewingAcsType: CameraStationType | undefined,
) => {
    const recordingService = ServiceLocator.get(RecordingSelectorService);
    recordingService.addItemWithVendorConsistency(
        item,
        isSolutionEmpty,
        selectedRecordingSolutionType,
        selectedVendorName,
        viewingAcsType,
    );
};

const updateQuantity = (
    item: IRecordingListItem,
    newQuantity: number,
    isSolutionEmpty: boolean,
    selectedRecordingSolutionType: RecordingSolutionType,
    selectedVendorName: SelectedVendorType | undefined,
    viewingAcsType: CameraStationType | undefined,
) => {
    const recordingService = ServiceLocator.get(RecordingSelectorService);
    if (isSingleQuantityCategory(item.category)) {
        newQuantity > item.quantity
            ? addItem(
                  item,
                  isSolutionEmpty,
                  selectedRecordingSolutionType,
                  selectedVendorName,
                  viewingAcsType,
              )
            : deleteItem(item);
    } else if (item.piaId) {
        recordingService.updateQuantity(item.piaId, newQuantity);
        eventTracking.logUserEvent(
            'System Components',
            'Update recording item quantity',
            `${item.piaId}`,
            newQuantity,
        );
    } else if (item.vendor === 'genetec' || item.vendor === 'milestone') {
        recordingService.updatePartnerQuantity(item.name, newQuantity);
        eventTracking.logUserEvent(
            'System Components',
            `Update ${item.vendor} recording item quantity`,
            `${item.name}`,
            newQuantity,
        );
    }
};

const deleteItem = (item: IRecordingListItem) => {
    const recordingService = ServiceLocator.get(RecordingSelectorService);
    if (item.piaId) {
        recordingService.delete(item.piaId);
        eventTracking.logUserEvent('System Components', 'Delete recording item', `${item.piaId}`);
    } else if (item.vendor === 'genetec' || item.vendor === 'milestone') {
        recordingService.deletePartnerItem(item.name);
        eventTracking.logUserEvent(
            'System Components',
            `Delete recording ${item.vendor} item`,
            `${item.name}`,
        );
    }
};

const disableAddForUnselectedVmsLicenseType = (
    category: string,
    vendor: string,
    eLicensePreference: boolean,
    isELicense?: boolean,
) => {
    return (
        (category === 'vms' && vendor === 'Axis' && !isELicense && eLicensePreference) ||
        (category === 'vms' && vendor === 'Axis' && isELicense && !eLicensePreference)
    );
};

interface IRecordingItemProps {
    item: IRecordingListItem;
    eLicensePreference: boolean;
}

export const RecordingItem: React.FunctionComponent<IRecordingItemProps> = ({
    item,
    eLicensePreference,
}) => {
    const selectedVendorName = useSelector(getSelectedRecordingVendor);
    const isSolutionEmpty = useSelector(isCurrentSolutionEmpty);
    const selectedRecordingSolutionType = useSelector(getSelectedRecordingSolutionType);
    const viewingAcsType = useSelector(getViewingAxisAcsType);
    const isSingleQuantityItem = isSingleQuantityCategory(item.category);
    const showVmsLicenseType = item.category === 'vms' && selectedVendorName === 'Axis';

    return (
        <tr className={item.quantity && item.quantity > 0 ? selectedBackground : undefined}>
            <th scope="row">
                <Box
                    spacing="base"
                    alignItems="center"
                    justifyContent="between"
                    testId="recording_item_row"
                >
                    <Box spacing="base" alignItems="center">
                        {item.piaId ? (
                            <PiaImage
                                imageSize="lg"
                                piaId={item.piaId ? item.piaId : null}
                                icon="recording"
                            />
                        ) : (
                            <PartnerImage imageUrl={item.imageUrl} icon="recording" />
                        )}

                        <Box direction="column" spacing="quart">
                            <Box spacing="quart" direction="row" alignItems="center">
                                <Text testId={`recording_item_${item.name}`}>{item.name}</Text>
                                {item.piaId && <PiaItemMsrp piaId={item.piaId} />}
                                {item.dataSheetUrl && (
                                    <IconButton
                                        size="sm"
                                        icon="open_in_new"
                                        onClick={() => window.open(item.dataSheetUrl)}
                                    />
                                )}
                            </Box>
                            <Subscription
                                subscriptionIntervalInMonths={item.subscriptionIntervalInMonths}
                            />
                        </Box>

                        {item.isDiscontinued && item.piaId && (
                            <Box spacing="base">
                                <RecordingChanger piaId={item.piaId} />
                            </Box>
                        )}
                    </Box>
                    <Box alignItems="center">
                        {showVmsLicenseType && (
                            <Box paddingRight={!item.quantity ? 'base' : 'half'}>
                                <Badge
                                    label={
                                        item.isELicense
                                            ? t.applicationSelectorELicense
                                            : t.applicationSelectorPaperLicense
                                    }
                                    type={'yellow'}
                                    compact
                                />
                            </Box>
                        )}
                        {item.quantity && item.quantity > 0 && (
                            <Quantity
                                min={1}
                                max={AppConstants.componentQuantityMax}
                                disabledInput={isSingleQuantityItem}
                                value={item.quantity}
                                onDelete={() => deleteItem(item)}
                                onChange={(newQuantity) =>
                                    updateQuantity(
                                        item,
                                        newQuantity,
                                        isSolutionEmpty,
                                        selectedRecordingSolutionType,
                                        selectedVendorName,
                                        viewingAcsType,
                                    )
                                }
                            />
                        )}
                        {!item.quantity && item.category && item.vendor && (
                            <Button
                                testId={item.name + '_Add_Item'}
                                disabled={disableAddForUnselectedVmsLicenseType(
                                    item.category,
                                    item.vendor,
                                    eLicensePreference,
                                    item.isELicense,
                                )}
                                onClick={() =>
                                    addItem(
                                        item,
                                        isSolutionEmpty,
                                        selectedRecordingSolutionType,
                                        selectedVendorName,
                                        viewingAcsType,
                                    )
                                }
                            >
                                {t.add}
                            </Button>
                        )}
                    </Box>
                </Box>
            </th>
            <td>
                <Text align="center" style="semibold">
                    {item.nrOfChannels || null}
                </Text>
            </td>
            <td>
                <Text align="center" style="semibold">
                    {item.nrOfLicenses || null}
                </Text>
            </td>
            <td>
                <ReadDirection direction="ltr">
                    <Text align="center" style="semibold">
                        {item.storage ? format.storage(item.storage) : null}
                    </Text>
                </ReadDirection>
                {Boolean(item.freeHddBays) && (
                    <Text style="small" color="grey6" align="center">
                        ({t.extendable})
                    </Text>
                )}
            </td>
            <td>
                <ReadDirection direction="ltr">
                    <Text align="center" style="semibold">
                        {item.bandwidth ? format.bandwidth(item.bandwidth) : null}
                    </Text>
                </ReadDirection>
            </td>
            <td>
                <ReadDirection direction="ltr">
                    <Text align="center" style="semibold">
                        {item.power ? `${item.power} W` : null}
                    </Text>
                </ReadDirection>
            </td>
            <td>
                <Text align="center" style="semibold">
                    {item.ports || null}
                </Text>
            </td>
            <td>
                <Text align="center" style="semibold">
                    {item.video || null}
                </Text>
            </td>
        </tr>
    );
};

RecordingItem.displayName = 'RecordingItem';
