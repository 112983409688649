import type { IStoreState } from 'app/store';
import { couchDBInfoValidator } from '../couchDBCompaction/utils';
import { createSelector } from 'reselect';

export const getCompactionStatus = (state: IStoreState) => state.common.compactionStatus;
export const getCouchDBInfo = (state: IStoreState) => state.common.couchDBInfo;
export const getCompactionInitiated = (state: IStoreState) => state.common.compactionInitiated;

export const getIsSeriousError = createSelector(
    [getCompactionStatus],
    (status) => status === 'SeriousError',
);

export const getIsCompactionAvailable = createSelector(
    [getCouchDBInfo, getCompactionStatus],
    (dbInfo, status) =>
        status === 'Available' && dbInfo
            ? couchDBInfoValidator.isCompactionAvailable(dbInfo)
            : false,
);

export const getHasCompactionResult = createSelector(
    [getCompactionStatus],
    (status) => status === 'Success' || status === 'Fail' || status === 'SeriousError',
);
