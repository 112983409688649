import * as React from 'react';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import { ContentPanel, Box, Text, Stack, Button } from 'app/components';
import type { IStoreState } from 'app/store';
import { ScheduledRecordingTypes, getProfile } from 'app/modules/common';
import { ProfileEditorHeader } from './ProfileEditorHeader.container';
import { ScenarioEditor } from './ScenarioEditor.container';
import { ScheduledRecordingEditor } from './ScheduledRecordingEditor.container';
import { ZipstreamSettings } from './ZipstreamSettings.container';
import { StorageSettings } from './StorageSettings.container';
import type { Id, IPersistence, IProfileEntity } from 'app/core/persistence';
import { AudioSettings } from './AudioSettings.container';

interface IProfileEditorOwnProps {
    profileId?: Id;
    onClose(): void;
}

interface IProfileEditorProps extends IProfileEditorOwnProps {
    profile?: IPersistence<IProfileEntity>;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProfileEditorOwnProps,
): IProfileEditorProps => {
    const profile = ownProps.profileId ? getProfile(storeState, ownProps.profileId) : undefined;

    return {
        profile,
        ...ownProps,
    };
};

class ProfileEditorContainer extends React.Component<IProfileEditorProps> {
    public render() {
        return this.props.profile ? (
            <ContentPanel>
                <ProfileEditorHeader
                    profileId={this.props.profile._id}
                    onClose={() => this.props.onClose()}
                />
                <ScenarioEditor profileId={this.props.profile._id} />
                <ScheduledRecordingEditor
                    profileId={this.props.profile._id}
                    recordingType={ScheduledRecordingTypes.triggered}
                />
                <ScheduledRecordingEditor
                    profileId={this.props.profile._id}
                    recordingType={ScheduledRecordingTypes.continuous}
                />
                <ScheduledRecordingEditor
                    profileId={this.props.profile._id}
                    recordingType={ScheduledRecordingTypes.liveView}
                />
                <AudioSettings profileId={this.props.profile._id} />
                <ZipstreamSettings profileId={this.props.profile._id} />
                <StorageSettings profileId={this.props.profile._id} />
            </ContentPanel>
        ) : (
            <Box height="80%" justifyContent="center" alignItems="center">
                <Stack vertical alignItems="center" justifyContent="center">
                    <Text style="heading">{t.missingScenario}</Text>
                    <Button primary onClick={this.props.onClose}>
                        {t.close}
                    </Button>
                </Stack>
            </Box>
        );
    }
}

export const ProfileEditor = connect(mapStateToProps)(ProfileEditorContainer);
