import * as React from 'react';
import { useService } from 'app/ioc';
import { Box, Card, IconText, Stack, Text } from 'app/components';
import { ContentHeader, UserProjectsList } from './components';
import { t } from 'app/translate';
import { ProjectDbOrigin } from 'app/core/persistence';
import { getIsSeriousError } from 'app/modules/common';
import { useSelector } from 'react-redux';
import { UserProjectsActionService } from './services';

interface IUserProjectsProps {
    currentView: ProjectDbOrigin;
}

export const UserProjects: React.FunctionComponent<IUserProjectsProps> = ({ currentView }) => {
    const userProjectsActionService = useService(UserProjectsActionService);
    const hasCouchDBCompactSeriousError = useSelector(getIsSeriousError);
    const getHeader = () => {
        switch (currentView) {
            case ProjectDbOrigin.asdLocalUserData:
                return t.localProjects;
            case ProjectDbOrigin.asdUserData:
            default:
                return t.myProjects;
        }
    };

    React.useEffect(() => {
        userProjectsActionService.clearSelection();
        userProjectsActionService.showArchivedProjects(false);

        if (currentView === ProjectDbOrigin.asdUserData) {
            // trigger replication/sync for remote projects only
            userProjectsActionService.syncUnarchived();
        }
    }, [currentView, userProjectsActionService]);

    if (currentView === ProjectDbOrigin.asdUserData && hasCouchDBCompactSeriousError) {
        return (
            <Box justifyContent="center">
                <Box paddingTop="doublePanel" maxWidth="80%">
                    <Card borderColor="red" color="white" paddingX="panel" paddingY="panel">
                        <Stack vertical notFullWidth spacing="base" alignItems="center">
                            <IconText
                                icon="error"
                                iconProps={{ opaque: true, color: 'red', size: 'lg' }}
                                textProps={{ style: 'title' }}
                            >
                                {t.inaccessibleDatabase}
                            </IconText>
                            <Stack vertical spacing="half">
                                <Text style="semibold" color="red">
                                    {t.seriousErrorMessage1}
                                </Text>
                                <Text style="semibold" color="red">
                                    {t.seriousErrorMessage2}
                                </Text>
                                <Text style="semibold" color="red"></Text>
                            </Stack>
                        </Stack>
                    </Card>
                </Box>
            </Box>
        );
    }

    return (
        <Stack vertical>
            <ContentHeader heading={getHeader()} />
            <Box paddingX="base">
                <UserProjectsList />
            </Box>
        </Stack>
    );
};
