import { GopMode } from 'app/core/persistence/models/profile';
import { ProjectZipType } from 'app/core/persistence/userDataPersistence/entities/project/IProject';
import { getParentId } from 'app/core/persistence/utils/getParentId';
import { zipStrengths } from 'app/modules/common/profile/models';
import { injectable } from 'inversify';
import type { IPersistenceRepository } from '../../repositories';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * projectZipSetting added to settings.
 * New projects will receive a default projectZipSetting (medium)
 * Existing projects will receive a deafult projectZipSetting (low) if no changes were made to zipstream
 * If project has made changes the setting will be set to off
 */
@injectable()
export class Migration28To29 extends MigrationProviderBase {
    public from: number = 28;
    public to: number = 29;

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            // if project does not have projectZipSetting set, set to new profile default medium,
            // unless the old profile hasn't changed the defaults. Then we set it to low
            if (entity.type === 'project' && entity.projectZipSetting === undefined) {
                const projectId = entity._id;
                const profiles = (await repository.getAll('profile')).filter(
                    (profile) => getParentId(profile) === projectId,
                ) as any;
                let projectZipSetting = ProjectZipType.medium;
                const setZipSettingLow = profiles.every((profile: any) => {
                    return (
                        profile.zipstream.gopMode === GopMode.Fixed &&
                        profile.zipstream.fpsMode === 'fixed' &&
                        profile.zipstream.zipStrength === zipStrengths.low
                    );
                });
                if (setZipSettingLow) {
                    projectZipSetting = ProjectZipType.low;
                }

                entity.projectZipSetting = projectZipSetting;
            }

            // if profile does not have useProjectSetting set, set to useProjectSetting off (false),
            // unless the old profile hasn't changed the defaults. Then we set it to on
            if (entity.type === 'profile' && entity.zipstream.useProjectSetting === undefined) {
                const projectId = getParentId(entity);
                const profiles = (await repository.getAll('profile')).filter(
                    (profile) => getParentId(profile) === projectId,
                ) as any;
                const setUseProjectSettingOn = profiles.every((profile: any) => {
                    return (
                        profile.zipstream.gopMode === GopMode.Fixed &&
                        profile.zipstream.fpsMode === 'fixed' &&
                        profile.zipstream.zipStrength === zipStrengths.low
                    );
                });

                entity.zipstream.useProjectSetting = setUseProjectSettingOn;
            }

            // if item is a camera and have a profile override with any of fpsMode, gopMode or zipStrength set,
            // set useProjectSetting to off (false),
            if (
                entity.type === 'item' &&
                entity.properties &&
                entity.properties.camera &&
                entity.properties.camera.profileOverride &&
                entity.properties.camera.profileOverride.zipstream &&
                (entity.properties.camera.profileOverride.zipstream.fpsMode !== undefined ||
                    entity.properties.camera.profileOverride.zipstream.gopMode !== undefined ||
                    entity.properties.camera.profileOverride.zipstream.zipStrength !== undefined)
            ) {
                entity.properties.camera.profileOverride.zipstream.useProjectSetting = false;
            }

            // if item is an analog camera and have a profile override,
            // with any of fpsMode, gopMode or zipStrength set,
            // set useProjectSetting to off (false)
            if (
                entity.type === 'item' &&
                entity.properties &&
                entity.properties.analogCamera &&
                entity.properties.analogCamera.profileOverride &&
                entity.properties.analogCamera.profileOverride.zipstream &&
                (entity.properties.analogCamera.profileOverride.zipstream.fpsMode !== undefined ||
                    entity.properties.analogCamera.profileOverride.zipstream.gopMode !==
                        undefined ||
                    entity.properties.analogCamera.profileOverride.zipstream.zipStrength !==
                        undefined)
            ) {
                entity.properties.analogCamera.profileOverride.zipstream.useProjectSetting = false;
            }

            // if item is a main unit and have a profile override,
            // with any of fpsMode, gopMode or zipStrength set,
            // set useProjectSetting to off (false)
            if (
                entity.type === 'item' &&
                entity.properties &&
                entity.properties.mainUnit &&
                entity.properties.mainUnit.profileOverride &&
                entity.properties.mainUnit.profileOverride.zipstream &&
                (entity.properties.mainUnit.profileOverride.zipstream.fpsMode ||
                    entity.properties.mainUnit.profileOverride.zipstream.gopMode ||
                    entity.properties.mainUnit.profileOverride.zipstream.zipStrength)
            ) {
                entity.properties.mainUnit.profileOverride.zipstream.useProjectSetting = false;
            }

            return Promise.resolve(entity);
        };
    }
}
