import { t } from 'app/translate';
import * as React from 'react';
import { Icon, PopOver, Currency, Text, Heading, Box, Button, Quantity } from 'app/components';
import { QuotationEditSubsection } from './QuotationEditSubsection.component';
import type { IProduct, CategoryEnum, IMsrpProps } from 'app/modules/common';
import {
    translateCategory,
    quotationFilter,
    getCurrentProjectLocale,
    getCurrentProjectCurrency,
    VendorEnum,
    getProductsWithPrices,
} from 'app/modules/common';
import type { Id, IPriceMap } from 'app/core/persistence';
import { ProjectQuotationService } from 'app/core/persistence';
import { quotationCostCalculator } from '../../util';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import type { QuotationSection } from '../../models';
import {
    getMsrpForQuotation,
    getSectionHasMsrpProduct,
    getSortedProductsForSection,
    getSectionHasMsrpHeader,
    getSectionHasMsrpDisclaimer,
} from '../../selectors';
import { ServiceLocator } from 'app/ioc';
import { MsrpColumnHeaderStyle, QuotationTableStyle, RightAlignedStyle } from './QuotationStyle';

interface IQuotationEditSectionOwnProps {
    sectionType: QuotationSection;
    quotationId: Id;
    sectionHeader: string;
}

interface IQuotationEditSectionProps {
    quotationId: Id;
    sectionType: QuotationSection;
    products: IProduct[];
    msrp?: IMsrpProps;
    sectionHeader: string;
    locale: string;
    currency: string;
    sectionHasMsrpHeader: boolean;
    sectionHasMsrpDisclaimer: boolean;
    allProductsWithPrices: IProduct[];
    sectionHasMsrpProduct: boolean;
}

interface IQuotationEditSectionState {
    msrpMargin: number;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IQuotationEditSectionOwnProps,
): IQuotationEditSectionProps => {
    return {
        sectionHeader: ownProps.sectionHeader,
        sectionType: ownProps.sectionType,
        quotationId: ownProps.quotationId,
        msrp: getMsrpForQuotation(storeState),
        locale: getCurrentProjectLocale(storeState),
        currency: getCurrentProjectCurrency(storeState),
        products: getSortedProductsForSection(storeState, ownProps.sectionType),
        sectionHasMsrpHeader: getSectionHasMsrpHeader(storeState, ownProps.sectionType),
        sectionHasMsrpDisclaimer: getSectionHasMsrpDisclaimer(storeState, ownProps.sectionType),
        allProductsWithPrices: getProductsWithPrices(storeState),
        sectionHasMsrpProduct: getSectionHasMsrpProduct(storeState, ownProps.sectionType),
    };
};

class QuotationEditSectionContainer extends React.Component<
    IQuotationEditSectionProps,
    IQuotationEditSectionState
> {
    private projectQuotationService: ProjectQuotationService;

    constructor(props: IQuotationEditSectionProps) {
        super(props);
        this.projectQuotationService = ServiceLocator.get(ProjectQuotationService);

        this.state = {
            msrpMargin: (props.msrp && props.msrp.initialMsrpMargin) || 0,
        };
    }

    public render() {
        const { products, currency, sectionHeader } = this.props;

        if (products.length === 0) {
            return null;
        }

        const renderTable = (category: CategoryEnum) => (
            <QuotationEditSubsection
                key={category}
                header={translateCategory(category)}
                products={quotationFilter.filterProductsByCategory(products, category)}
                change={this.updateQuote}
                msrp={this.props.msrp}
                currency={currency}
                locale={this.props.locale}
            />
        );

        return (
            <>
                <Heading style="subheader">{sectionHeader}</Heading>
                <table className={QuotationTableStyle}>
                    <colgroup span={7} />

                    <thead>
                        <tr>
                            <th scope="col" />
                            <th scope="col">{t.quotationPartNumber}</th>
                            <th scope="col">{this.msrpHeader()}</th>
                            <th scope="col">{t.quotationQuotePrice}</th>
                            <th scope="col" className={RightAlignedStyle}>
                                {t.quotationQty}
                            </th>
                            <th scope="col">{t.quotationTotal}</th>
                        </tr>
                    </thead>

                    {quotationFilter.renderAllCategories(renderTable)}

                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <Text italic>
                                    {this.props.msrp &&
                                        this.props.sectionHasMsrpProduct &&
                                        this.props.msrp.msrpInformationText}
                                </Text>
                            </td>
                            <td colSpan={2}>
                                <Box width={230}>
                                    <Text bold>
                                        {t.quotationSectionTotal(sectionHeader, currency)}
                                    </Text>
                                </Box>
                            </td>
                            <td>
                                <Currency
                                    value={quotationCostCalculator.calculateTotalCost(products)}
                                    currency={this.props.currency}
                                    locale={this.props.locale}
                                />
                            </td>
                        </tr>
                        {this.props.msrp && this.props.sectionHasMsrpDisclaimer && (
                            <tr>
                                <td colSpan={6}>
                                    <Text italic>
                                        <sup>*</sup>
                                        {t.quotationMsrpDisclaimer}
                                        {this.props.msrp.msrpAvailableInLocalCurrency &&
                                            this.props.currency.toUpperCase() !==
                                                this.props.msrp.msrpCurrency.toUpperCase() &&
                                            t.quotationMsrpCurrencyDisclaimer(
                                                this.props.msrp.msrpCurrency.toUpperCase(),
                                            )}
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tfoot>
                </table>
            </>
        );
    }

    private msrpHeader() {
        const matchingCurrency = (
            <Box justifyContent="end">
                <PopOver
                    trigger={
                        <span className={MsrpColumnHeaderStyle}>
                            {t.quotationMsrp} <sup>*</sup>{' '}
                            <Icon testId="MSRP" icon="keyboard_arrow_right" size="sm" />
                        </span>
                    }
                    content={
                        <Box direction="column" width="160px">
                            <Text style="semibold">{t.quotationCopyMsrpTitle}</Text>
                            <div>
                                <Text>{t.quotationAdjustBy}</Text>
                                <Box paddingY="base" justifyContent="center">
                                    <Quantity
                                        max={Number.MAX_SAFE_INTEGER}
                                        min={Number.MIN_SAFE_INTEGER}
                                        value={this.state.msrpMargin}
                                        onChange={this.updateMsrpMargin}
                                        changeOnKey
                                    />
                                </Box>
                            </div>
                            <Button testId="copy_MSRP_btn" primary onClick={this.setMsrpMargin}>
                                {t.quotationCopyMsrp}
                            </Button>
                        </Box>
                    }
                />
            </Box>
        );

        const nonMatchingCurrency = (
            <span>
                {t.quotationMsrp} <sup>*</sup>
            </span>
        );

        if (!this.props.sectionHasMsrpHeader || !this.props.msrp) {
            return '';
        }

        return this.props.msrp.msrpAvailableInLocalCurrency
            ? matchingCurrency
            : nonMatchingCurrency;
    }

    private setMsrpMargin = () => {
        const { msrp, allProductsWithPrices } = this.props;
        if (msrp) {
            const calculateNewQuotes = (productPrices: IPriceMap, product: IProduct): IPriceMap => {
                if (product.msrp && product.msrp.price && product.msrp.price > 0) {
                    const newMsrp = msrp.msrpAvailableInLocalCurrency
                        ? product.msrp.priceList
                        : product.msrp.price;
                    productPrices[product.id] = newMsrp * (this.state.msrpMargin / 100 + 1);
                }

                return productPrices;
            };

            const priceMap: IPriceMap = allProductsWithPrices.reduce(calculateNewQuotes, {});

            this.updatePriceAndMsrpMargin(priceMap);
        }
    };

    private updateMsrpMargin = (value: number) => {
        this.setState({
            msrpMargin: value,
        });
    };

    private updateQuote = (quotePrice: number, product: IProduct) => {
        const priceMap: IPriceMap = {};
        priceMap[product.id] = quotePrice;

        if ([VendorEnum.Axis, VendorEnum.twoN].includes(product.vendor)) {
            this.projectQuotationService.update(this.props.quotationId, {
                pricesByPartNumber: priceMap,
            });
        } else if ([VendorEnum.Genetec].includes(product.vendor)) {
            this.projectQuotationService.update(this.props.quotationId, {
                partnerItemPrices: priceMap,
            });
        } else {
            this.projectQuotationService.update(this.props.quotationId, {
                customItemPrices: priceMap,
            });
        }
    };

    private updatePriceAndMsrpMargin = (priceMap: IPriceMap) => {
        this.projectQuotationService.update(this.props.quotationId, {
            pricesByPartNumber: priceMap,
            msrpToQuoteMargin: this.state.msrpMargin,
        });
    };
}
export const QuotationEditSection = connect(mapStateToProps)(QuotationEditSectionContainer);
