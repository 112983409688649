import * as React from 'react';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { AppConstants } from 'app/AppConstants';
import { ExpandableButton } from '../expandableButton';
import { type IAutoTestable, toTestIdFormat } from '../../ui-test';

const windowSizeBreakpoint = 1100;
const menuStyle = css`
    display: flex;
    height: 100%;

    & aside {
        z-index: ${AppConstants.mainMenuDepth};
        background-color: ${ColorsEnum.grey2};
        border-right: 1px solid ${ColorsEnum.grey3};
        transition:
            max-width 250ms ease-in-out,
            box-shadow 250ms ease-in-out;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        flex: 1 1 100%;
        overflow-y: auto;
        scrollbar-width: none;
        overflow: hidden;
    }

    & [aria-expanded='true'] {
        width: 300px;
    }

    [aria-label='description'] {
        width: 200px;
        transition-delay: 250ms;
        transition:
            visibility 250ms linear,
            opacity 250ms linear;
    }

    & [aria-expanded='false'] {
        max-width: 64px;

        [aria-label='description'] {
            visibility: hidden;
            opacity: 0;
            overflow: hidden;
        }
    }

    @media screen and (max-width: ${windowSizeBreakpoint}px) {
        & aside {
            position: fixed;
            top: 60px;
            bottom: 0;
        }

        & aside[aria-expanded='true'] {
            box-shadow: 4px 0px 5px 0px rgba(70, 70, 70, 0.2);
        }
    }

    @media print {
        display: none !important;
    }
`;

export interface ISidebarMenu extends IAutoTestable {
    content: React.ReactNode;
    isMenuExpanded: boolean;
    onExpandedChange(): void;
}

export const SidebarMenu: React.FunctionComponent<ISidebarMenu> = ({
    content,
    isMenuExpanded,
    testId,
    onExpandedChange,
}) => {
    return (
        <section className={menuStyle}>
            <aside data-test-id={toTestIdFormat(testId)} role="menu" aria-expanded={isMenuExpanded}>
                {content}
                <ExpandableButton buttonExpanded={isMenuExpanded} onClick={onExpandedChange} />
            </aside>
        </section>
    );
};
