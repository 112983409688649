import * as React from 'react';
import { t } from 'app/translate';
import { Box, Heading, Action, Stack } from 'app/components';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getIsStandalone, getCurrentProjectLocked } from 'app/modules/common';
import { AccessorySelectorService, AccessorySelectorActionService } from '../../services';
import { useService } from 'app/ioc';
import type { Id } from 'app/core/persistence';
import { DeviceSelection } from '../standalone/DeviceSelection.container';
import { DeviceMount } from './DeviceMount.container';
import { PrimaryMount } from './PrimaryMount.container';
import { EnvironmentMount } from './EnvironmentMount.container';
import { EnvironmentSelection } from './EnvironmentSelection.container';
import { DeviceItem } from './DeviceItem.container';
import { getSelectedDeviceId, getSelectedEnvironment } from '../../selectors';
import { MOBILE_WINDOW_SIZE } from '../../constants';

const mountingLine = css`
    position: absolute;
    z-index: 0;
    background: ${ColorsEnum.blackOpacity};
    height: 3px;
    top: 40%;
    left: 0px;
    right: 0px;

    @media screen and (max-width: ${MOBILE_WINDOW_SIZE}px) {
        width: 3px;
        height: unset;
        top: 16px;
        bottom: 0;
        left: 50%;
        right: unset;
    }
`;

export const MountingChain: React.FC = () => {
    const accessorySelectorService = useService(AccessorySelectorService);
    const accessorySelectorActionService = useService(AccessorySelectorActionService);
    const isLocked = useSelector(getCurrentProjectLocked);
    const selectedDeviceId = useSelector(getSelectedDeviceId);
    const selectedEnvironmentId = useSelector<IStoreState, Id | undefined>(
        (store) => getSelectedEnvironment(store, selectedDeviceId)?.id,
    );
    const allowDeviceSelection = useSelector(getIsStandalone);

    const resetMounting = () =>
        selectedDeviceId && accessorySelectorService.resetEnvironment(selectedDeviceId);

    const resetAll = () =>
        selectedDeviceId && accessorySelectorActionService.reset(selectedDeviceId);

    return (
        <Box display="block">
            <Stack alignItems="center" spacing="base">
                <Heading>{t.mountingTitle}</Heading>
                {allowDeviceSelection && selectedDeviceId && (
                    <Action title={t.reset} onClick={resetAll} />
                )}
                {!allowDeviceSelection && !isLocked && selectedEnvironmentId && (
                    <Action title={t.reset} onClick={resetMounting} />
                )}
            </Stack>
            <Box overflowX="auto">
                <Box
                    width="100%"
                    padding="panel"
                    paddingLeft="half"
                    alignItems={window.innerWidth > MOBILE_WINDOW_SIZE ? 'start' : 'center'}
                    direction={window.innerWidth > MOBILE_WINDOW_SIZE ? 'row' : 'column'}
                >
                    {allowDeviceSelection ? <DeviceSelection /> : <DeviceItem />}
                    <Box
                        position="relative"
                        alignItems="start"
                        justifyContent="center"
                        flex="grow"
                        paddingX="base"
                        minHeight="202px"
                        paddingY={window.innerWidth > MOBILE_WINDOW_SIZE ? undefined : 'panel'}
                    >
                        <div className={mountingLine} />
                        <Stack
                            alignItems={window.innerWidth > MOBILE_WINDOW_SIZE ? 'start' : 'center'}
                            vertical={window.innerWidth <= MOBILE_WINDOW_SIZE}
                        >
                            <DeviceMount />
                            <PrimaryMount />
                            <EnvironmentMount />
                        </Stack>
                    </Box>
                    <EnvironmentSelection />
                </Box>
            </Box>
        </Box>
    );
};
