import { getInstallationPoint, isGeoLocated } from 'app/modules/common';
import { getFloorPlanForInstallationPoint } from 'app/modules/maps';
import { createSelector } from 'reselect';

/**
 * Get installation point with a geo location. If the installation point is not geo located, return undefined.
 */
export const getGeoLocatedInstallationPoint = createSelector(
    [getInstallationPoint, getFloorPlanForInstallationPoint],
    (installationPoint, ipMap) => {
        return !installationPoint ||
            (ipMap[installationPoint._id] && !isGeoLocated(ipMap[installationPoint._id]))
            ? undefined
            : installationPoint;
    },
);
