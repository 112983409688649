import type { IBounds } from 'app/core/persistence';
import { useState, useEffect, useDeferredValue } from 'react';
import type { LeafletMap } from '../components/map/LeafletMap';

export const useLeafletMapBounds = (map: LeafletMap | undefined) => {
    const [leafletBounds, setLeafletBounds] = useState<IBounds | undefined>(undefined);
    const deferredBounds = useDeferredValue(leafletBounds);

    useEffect(() => {
        if (!map) {
            return;
        }

        const updateMapViewBounds = () => {
            const bounds = map?.getCurrentMapIBounds();
            setLeafletBounds(bounds);
        };

        map.map.on('zoomend', updateMapViewBounds);
        map.map.on('dragend', updateMapViewBounds);

        return () => {
            map.map.off('zoomend', updateMapViewBounds);
            map.map.off('dragend', updateMapViewBounds);
        };
    }, [map]);

    return deferredBounds;
};
