import { originToClientId } from 'app/AppSettings';
import { parseJwt } from './parseJwt';

export interface IAuthTokens {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    refreshAt: number;
}

const isValidCode = (code: any): boolean => !!code;

const isValidTokenData = (data: any): boolean =>
    data &&
    !data.error &&
    data.token_type === 'bearer' &&
    data.access_token &&
    data.refresh_token &&
    !!data.expires_in;

export const fetchAuthTokens = async (code?: string): Promise<IAuthTokens> => {
    if (!isValidCode(code)) throw new Error('Invalid auth code');

    const now = Date.now();
    let res;
    let data;

    if (originToClientId(window.location.origin) === 'sitedesigner_webapp_local') {
        const requestBody = new URLSearchParams({
            client_id: 'sitedesigner_webapp_local',
            client_secret: window.appConfig.auth.clientSecret || '',
            redirect_uri: `${window.location.origin}${window.appConfig.auth.authorizationCallback}`,
            grant_type: 'authorization_code',
            code: code || '',
        });

        res = await fetch(window.appConfig.auth.tokenServiceUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: requestBody.toString(),
        });
        data = await res.json();
    } else {
        res = await fetch(`${window.appConfig.auth.tokenServiceUrl}?code=${code}`);
        data = await res.json();
    }

    if (!isValidTokenData(data)) throw new Error('Invalid auth tokens');

    return {
        idToken: data.id_token,
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        refreshAt: now + data.expires_in * 500, // i.e. refresh before it expires
    };
};

export const refreshAuthTokens = async (oldTokens?: IAuthTokens): Promise<IAuthTokens> => {
    if (!oldTokens) throw new Error('Missing old auth tokens');

    const now = Date.now();
    let data;
    let res;
    if (originToClientId(window.location.origin) === 'sitedesigner_webapp_local') {
        const requestBody = new URLSearchParams({
            client_id: 'sitedesigner_webapp_local',
            client_secret: window.appConfig.auth.clientSecret || '',
            grant_type: 'refresh_token',
            refresh_token: oldTokens.refreshToken,
        });

        res = await fetch(window.appConfig.auth.tokenServiceUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: requestBody.toString(),
        });
        data = await res.json();
    } else {
        res = await fetch(
            `${window.appConfig.auth.tokenServiceUrl}?refresh=${oldTokens.refreshToken}`,
        );
        data = await res.json();
    }

    if (!isValidTokenData(data)) throw new Error('Invalid refresh tokens');

    return {
        ...oldTokens,
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        refreshAt: now + data.expires_in * 500, // i.e. refresh before it expires
    };
};

export const saveAuthTokens = (tokens: IAuthTokens): void => {
    try {
        sessionStorage.setItem('auth', JSON.stringify(tokens));
    } catch {
        console.error('Error while save auth tokens');
    }
};

export const loadAuthTokens = (): IAuthTokens | null => {
    try {
        return JSON.parse(sessionStorage.getItem('auth') || '');
    } catch {
        return null;
    }
};

export const clearAuthTokens = (): void => {
    try {
        sessionStorage.removeItem('auth');
    } catch {
        console.error('Error while clear auth tokens');
    }
};

export const getDisplayName = (jwt: string): string | undefined => {
    const payload = parseJwt(jwt);
    if (payload) {
        const { firstName, lastName, email } = payload;

        if (firstName && lastName) return `${firstName} ${lastName}`;
        if (email) return email;
    }
    return undefined;
};
