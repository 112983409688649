import type { IConnectivityDeviceItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaConnectivityDevice } from 'app/core/pia';
import { CategoryEnum } from 'app/modules/common';
import { injectable } from 'inversify';
import type { IInstallationReportConnectivityDevice } from '../../models';
import { BaseDeviceService } from './BaseDevice.service';

@injectable()
export class ConnectivityDeviceService {
    constructor(private baseDeviceService: BaseDeviceService) {}

    public async mapDevice(
        item: IPersistence<IConnectivityDeviceItemEntity>,
        piaItem: IPiaConnectivityDevice,
    ): Promise<IInstallationReportConnectivityDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.ConnectivityDevice,
        };
    }
}
