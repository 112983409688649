import type { Colors } from 'app/styles';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getCurrentProjectItem, getCurrentProjectItems } from '../../project';
import { getParentIdOfChildDevice } from './getParentIdOfChildDevice';

export const getItemColor = createCachedSelector(
    [getCurrentProjectItem, getParentIdOfChildDevice, getCurrentProjectItems],
    (item, parentId, items): Colors => {
        if (item?.color) {
            return item.color;
        }
        const parentItem = parentId ? items[parentId] : undefined;
        if (parentItem?.color) {
            return parentItem.color;
        }
        return 'devicePalette7';
    },
)(toCacheKey);
