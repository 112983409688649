import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Box, PiaImage, Checkbox, IconButton, Stack } from 'app/components';
import { ServiceLocator } from 'app/ioc';
import { DocumentationActionService, DocumentationService } from '../services';
import type { CategoryEnum } from 'app/modules/common';
import { getCurrentProjectName } from 'app/modules/common';
import { deviceIcons } from 'app/modules/common/products';
import { t } from 'app/translate';

interface IDocumentationOwnProps {
    productId: number;
    productName: string;
    productCategory: CategoryEnum;
}

interface IDocumentationItemProps extends IDocumentationOwnProps {
    isProductSelected: boolean;
    projectName: string;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IDocumentationOwnProps,
): IDocumentationItemProps => {
    return {
        isProductSelected: storeState.documentation.selectedPiaIds.includes(ownProps.productId),
        projectName: getCurrentProjectName(storeState),
        ...ownProps,
    };
};

class DocumentationItemContainer extends React.Component<IDocumentationItemProps> {
    private documentationActionService: DocumentationActionService;
    private documentationService: DocumentationService;

    constructor(props: Readonly<IDocumentationItemProps>) {
        super(props);
        this.documentationService = ServiceLocator.get(DocumentationService);
        this.documentationActionService = ServiceLocator.get(DocumentationActionService);
    }

    public render() {
        return (
            <Box
                justifyContent="between"
                paddingY="halfCell"
                paddingX="panel"
                color={this.props.isProductSelected ? 'yellow1' : 'transparent'}
            >
                <Stack>
                    <PiaImage
                        piaId={this.props.productId}
                        icon={deviceIcons.categoryEnumToIcon(this.props.productCategory)}
                        imageSize="lg"
                    />
                    <Box padding="base">{this.props.productName}</Box>
                </Stack>
                <Stack>
                    <Checkbox
                        selected={this.props.isProductSelected}
                        onChange={() => this.checkboxClicked(this.props.productId)}
                    />
                    <IconButton
                        icon="download_doc"
                        text={t.download}
                        onClick={() => this.documentationService.getDatasheet(this.props.productId)}
                    />
                </Stack>
            </Box>
        );
    }

    private checkboxClicked(id: number) {
        this.props.isProductSelected
            ? this.documentationActionService.deselectDevice(id)
            : this.documentationActionService.selectDevice(id);
    }
}

export const DocumentationItem = connect(mapStateToProps)(DocumentationItemContainer);
