import type {
    Id,
    IItemEntity,
    IItemRelationEntity,
    IPersistence,
    ItemRelationType,
} from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaItem, IPiaSpeaker } from 'app/core/pia';
import { getSelectedEnvironment } from 'app/modules/accessorySelector/selectors';
import {
    getCurrentProjectItem,
    getCurrentProjectRelationsForItem,
    getCurrentProjectRelationsRecord,
    getIdFromProps,
    getPiaItem,
    getPiaItemsRecord,
    getCurrentProjectSchedulesArray,
    CategoryEnum,
    toCacheKey,
    getCurrentProjectItemsArray,
    getCurrentProjectItemRelationsArray,
    getSelectedDeviceMountsForItemId,
    getSelectedEnvironmentMountsForItemId,
    getSelectedPrimaryMountForItemId,
} from 'app/modules/common';
import { getCurrentProjectItems } from 'app/modules/maps';
import { isDefined } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import type { IInstallationBaseReportAccessory, IInstallationReportSpeakerDevice } from '../models';
import { flatten, groupBy } from 'lodash-es';

const modelComparator = (
    a: IInstallationBaseReportAccessory,
    b: IInstallationBaseReportAccessory,
): number =>
    a.model.toLowerCase().localeCompare(b.model.toLowerCase(), undefined, { numeric: true });

const getSelectedMounts = createCachedSelector(
    [
        getSelectedDeviceMountsForItemId,
        getSelectedEnvironmentMountsForItemId,
        getSelectedPrimaryMountForItemId,
        getIdFromProps,
    ],
    (deviceMount, environmentMount, primaryMount) => {
        return [...deviceMount, primaryMount, ...environmentMount].filter(isDefined);
    },
)(toCacheKey);

export const getReportMounts = createCachedSelector(
    [
        getCurrentProjectItems,
        getPiaItemsRecord,
        getCurrentProjectRelationsRecord,
        getSelectedMounts,
        getSelectedEnvironment,
        getIdFromProps,
    ],
    (items, piaItems, relationRecord, selectedMounts, selectedEnvironment) => {
        const mounts = selectedMounts.map((mount) => {
            if (!mount) {
                return;
            }

            const mountAccessoryRelations = relationRecord[mount.itemId]?.filter(
                (rel) => rel.relationType === 'accessory',
            );
            const mountApplicationRelations = relationRecord[mount.itemId]?.filter(
                (rel) => rel.relationType === 'acap',
            );

            const accessories = mountAccessoryRelations
                ? getAccessoriesForMounts(mountAccessoryRelations, items, piaItems)
                : [];
            const applications = mountApplicationRelations
                ? getAccessoriesForMounts(mountApplicationRelations, items, piaItems)
                : [];

            return {
                model: mount.name,
                piaId: mount.id,
                quantity: items[mount.itemId]?.quantity,
                accessories,
                applications,
            } as IInstallationBaseReportAccessory;
        });

        const environment = selectedEnvironment
            ? ({
                  model: selectedEnvironment?.originalName,
                  piaId: selectedEnvironment?.productId,
              } as IInstallationBaseReportAccessory)
            : undefined;

        return [...mounts, environment].filter(isDefined);
    },
)(toCacheKey);

const getAccessoriesForMounts = (
    relations: IItemRelationEntity[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    piaItems: Record<number, IPiaItem>,
) => {
    return relations
        .map((rel) => {
            const accessoryItem = items[rel.childId];
            if (!accessoryItem || !accessoryItem.productId) {
                return;
            }

            const accessoryPiaItem = piaItems[accessoryItem.productId];
            return {
                model: accessoryPiaItem.name,
                piaId: accessoryPiaItem.id,
                quantity: accessoryItem.quantity,
            } as IInstallationBaseReportAccessory;
        })
        .filter(isDefined);
};

export const getReportAccessories = createCachedSelector(
    [
        getCurrentProjectRelationsForItem,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getIdFromProps,
        getCurrentProjectRelationsRecord,
    ],
    (relations, items, piaRecord, itemId, relationsRecord) => {
        const accessories = getAccessoriesOrApplications(
            relations,
            items,
            piaRecord,
            itemId,
            'accessory',
            relationsRecord,
        );
        const lenses = getAccessoriesOrApplications(relations, items, piaRecord, itemId, 'lenses');
        const lensesGroupedByProduct = Object.values(groupBy(lenses, ({ piaId }) => piaId)).map(
            (lens) => {
                return {
                    model: lens[0].model,
                    piaId: lens[0].piaId,
                    quantity: lens.length,
                } as IInstallationBaseReportAccessory;
            },
        );
        const accessoriesAndLenses = [...accessories, ...lensesGroupedByProduct];
        return accessoriesAndLenses;
    },
)(toCacheKey);

export const getReportAccessoriesForDoor = createCachedSelector(
    [
        getCurrentProjectRelationsForItem,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getIdFromProps,
        getCurrentProjectRelationsRecord,
    ],
    (relations, items, piaRecord, itemId, relationsRecord) => {
        const accessories = getAccessoriesForDoor(
            relations,
            items,
            piaRecord,
            itemId,
            'accessory',
            relationsRecord,
        );

        return accessories;
    },
)(toCacheKey);

export const getReportApplication = createCachedSelector(
    [getCurrentProjectRelationsForItem, getCurrentProjectItems, getPiaItemsRecord, getIdFromProps],
    (relations, items, piaRecord, itemId) => {
        return getAccessoriesOrApplications(relations, items, piaRecord, itemId, 'acap');
    },
)(toCacheKey);

const getSubAccessories = (
    accessories: IPersistence<IItemEntity>[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    types: ItemRelationType[],
    relationsRecord?: Record<string, IItemRelationEntity[]>,
): IPersistence<IItemEntity>[] => {
    if (accessories.length === 0) {
        return [];
    }
    const subRelations = relationsRecord
        ? flatten(
              accessories.map((accessory) =>
                  (relationsRecord[accessory._id] || []).filter((relation) =>
                      types.includes(relation.relationType),
                  ),
              ),
          )
        : [];

    const metaAccessoryItems = subRelations.map((rel) => items[rel.childId]).filter(isDefined);
    const subAccessories = getSubAccessories(metaAccessoryItems, items, types, relationsRecord);
    return [...accessories, ...subAccessories];
};

const getAccessoriesOrApplications = (
    relations: IItemRelationEntity[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    piaRecord: Record<number, IPiaItem>,
    itemId: Id | undefined,
    type: ItemRelationType,
    relationsRecord?: Record<string, IItemRelationEntity[]>,
) => {
    const accessoryRelations = relations.filter(({ parentId, relationType }) => {
        return relationType === type && parentId === itemId;
    });

    const accessoryItems = accessoryRelations.map((rel) => items[rel.childId]).filter(isDefined);
    // Recursively fetches sub accessories and adds them to accessoryItems
    const accessories = getSubAccessories(accessoryItems, items, [type], relationsRecord);

    const piaItems = accessories
        .map((acc) =>
            acc.productId && piaRecord[acc.productId]
                ? { ...piaRecord[acc.productId], quantity: acc.quantity }
                : undefined,
        )
        .filter(isDefined);

    return piaItems
        .map((item) => {
            return {
                model: item.name,
                piaId: item.id,
                quantity: item.quantity,
            } as IInstallationBaseReportAccessory;
        })
        .filter(isDefined);
};

const getAccessoriesForDoor = (
    relations: IItemRelationEntity[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    piaRecord: Record<number, IPiaItem>,
    itemId: Id | undefined,
    type: ItemRelationType,
    relationsRecord?: Record<string, IItemRelationEntity[]>,
) => {
    const doorSideRelations = itemId
        ? relations.filter(
              (relation) =>
                  (relation.relationType === 'doorSideA' && relation.path.includes(itemId)) ||
                  (relation.relationType === 'doorSideB' && relation.path.includes(itemId)),
          )
        : [];

    const accessoryItems = doorSideRelations.map((rel) => items[rel.childId]).filter(isDefined);

    // Recursively fetches sub accessories and adds them to accessoryItems
    const accessories = getSubAccessories(accessoryItems, items, [type], relationsRecord);

    const concatDuplicatesAccessories = accessories.filter((item, index, allAccessories) => {
        // find itemIndex of item with same productId as current
        const indexOfItem = allAccessories.findIndex(
            (currentItem) =>
                currentItem.productId === item.productId &&
                currentItem.properties.accessory?.category === item.properties.accessory?.category,
        );
        // check if current item is the first found
        const currentItemFirstOccurring = indexOfItem === index;
        if (!currentItemFirstOccurring) {
            // increase the first found element with this item quantity
            const firstElem = allAccessories[indexOfItem];
            firstElem.quantity = firstElem.quantity + item.quantity;
        }
        return currentItemFirstOccurring;
    });

    // if no product id, the item is either other-item for reader or rex. We fake them as a pia-accessory, since they will not be found in the piaRecord
    const piaItems = concatDuplicatesAccessories
        .map((acc) =>
            acc.productId && piaRecord[acc.productId]
                ? { ...piaRecord[acc.productId], quantity: acc.quantity }
                : {
                      id: 0,
                      name: acc.name,
                      category: acc.properties.accessory?.category,
                      quantity: acc.quantity,
                  },
        )
        .filter(isDefined);

    return piaItems
        .map((item) => {
            return {
                model: item.name,
                piaId: item.id,
                quantity: item.quantity,
                category: item.category,
            } as IInstallationBaseReportAccessory;
        })
        .filter(isDefined);
};

export const getInstallationReportDevice = createCachedSelector(
    [getCurrentProjectItem, getReportAccessories, getReportApplication, getPiaItem],
    (item, accessories, applications, piaItem) => {
        return {
            piaId: item?.productId,
            model: piaItem?.name,
            quantity: item?.quantity,
            accessories,
            applications,
            analyticRange: item?.analyticRange,
        } as IInstallationBaseReportAccessory;
    },
)(toCacheKey);

export const getSpeakerReportDevice = createCachedSelector(
    [getCurrentProjectItem, getReportAccessories, getReportApplication, getPiaItem],
    (item, accessories, applications, piaItem) => {
        if (!item || !deviceTypeCheckers.isSpeaker(item)) {
            return undefined;
        }
        const piaSpeaker = piaItem as IPiaSpeaker | null;
        return {
            piaId: item.productId,
            model: piaItem?.name,
            quantity: item.quantity,
            accessories,
            applications,
            category: piaItem?.category ?? CategoryEnum.Speaker,
            placement: item.properties.speaker?.filter.placement,
            outdoor: item.properties.speaker?.filter.outdoor,
            splashProof: piaSpeaker?.properties.outdoorReady,
        } as IInstallationReportSpeakerDevice;
    },
)(toCacheKey);

export const getScheduleNamesRecord = createSelector(
    [getCurrentProjectSchedulesArray],
    (schedules) => {
        const record: Record<string, string> = {};

        schedules.forEach((schedule) => {
            record[schedule._id] = schedule.name;
        });

        return record;
    },
);

/**
 * Get accessories with type 'accessory', 'lenses', 'primaryMount' and 'deviceMount' defined in {@see ItemRelationType}.
 *
 * Usage:
 *    createSelector([getAccessoriesByIdRecord], (accessoriesByIdRecord) => {
 *        ...
 *        // Format of item.id is 'item:6ad513ee-770c-4316-8804-6b3376705d25'
 *        console.log('Item has following array of accessories ', accessoriesByIdRecord[item.id]);
 *    }
 */
export const getAccessoriesByIdRecord = createSelector(
    [
        getCurrentProjectItemsArray,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getCurrentProjectRelationsRecord,
        getCurrentProjectItemRelationsArray,
    ],
    (
        itemsArray,
        items,
        piaItemsRecord,
        relationsRecord,
        relations,
    ): Record<Id, IInstallationBaseReportAccessory[]> => {
        const accessoriesByIdRecord: Record<Id, IInstallationBaseReportAccessory[]> = {};
        const accessoryRelationTypes: ItemRelationType[] = [
            'accessory',
            'lenses',
            'primaryMount',
            'deviceMount',
            'environmentMount',
        ];

        itemsArray.map((item) => {
            if (!item.productId) {
                return;
            }

            const accessoryRelations = relations
                .filter(
                    (relation) =>
                        accessoryRelationTypes.includes(relation.relationType) &&
                        relation.parentId === item._id &&
                        relation.path.includes(item._id),
                )
                .map((relation) => items[relation.childId])
                .filter(isDefined);

            if (!accessoryRelations) {
                return;
            }
            // create empty accessories array for all items.
            accessoriesByIdRecord[item._id] = [];

            // Recursively fetches sub accessories and adds them to accessoryItems
            const accessories = getSubAccessories(
                accessoryRelations,
                items,
                accessoryRelationTypes,
                relationsRecord,
            );

            accessoriesByIdRecord[item._id] = Object.values(groupBy(accessories, 'productId'))
                .map((acc) => {
                    const productId = acc[0].productId;
                    const quantity = acc.reduce((sum, cur) => {
                        return sum + cur.quantity;
                    }, 0);
                    return productId
                        ? ({
                              model: piaItemsRecord[productId].name,
                              quantity,
                              piaId: productId,
                          } as IInstallationBaseReportAccessory)
                        : undefined;
                })
                .filter(isDefined)
                .sort(modelComparator);
        });
        return accessoriesByIdRecord;
    },
);
