import * as React from 'react';
import { t } from 'app/translate';
import {
    Badge,
    Box,
    Clickable,
    DropDown,
    DropDownMenuItem,
    PiaImage,
    Stack,
    Text,
} from 'app/components';
import type { Id, IDoorItemEntity, IIdRev, IPersistence } from 'app/core/persistence';
import { ShadowButton } from './ShadowButton';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IRelatedPiaItem } from 'app/modules/common';
import {
    getPiaItem,
    getCurrentProjectLocked,
    PiaItemMsrp,
    DoorControllerService,
} from 'app/modules/common';
import { t as i18nextT } from 'i18next';
import { PiaAccessoryCategory } from 'app/core/pia';
import type { IPiaItem, PiaId } from 'app/core/pia';

import {
    getAllRexDevicesForProduct,
    getDoor,
    getMaxNbrOfRexDevicesForProduct,
    getNbrSelectedRexDevicesForDoorController,
    getSelectedRexForDoorSideAndId,
} from '../selectors';
import { ServiceLocator } from 'app/ioc';
import type { ISelectedMountAccessory } from 'app/modules/accessorySelector';

interface IRexSelectorProps {
    deviceItemId: Id;
    doorItemId: Id;
    isSideA: boolean;
}

export const RexSelector: React.FC<IRexSelectorProps> = ({ deviceItemId, doorItemId, isSideA }) => {
    const isLocked = useSelector<IStoreState, boolean>((state) => getCurrentProjectLocked(state));

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, deviceItemId),
    );

    const rexDevices = useSelector<IStoreState, IRelatedPiaItem[]>((state) =>
        getAllRexDevicesForProduct(state, piaItem?.id),
    );

    const showDropDown = rexDevices.length > 1;

    const door = useSelector<IStoreState, IPersistence<IDoorItemEntity> | undefined>((state) =>
        getDoor(state, doorItemId),
    );

    const maxNbrOfRexDevices = useSelector<IStoreState, number>((state) =>
        getMaxNbrOfRexDevicesForProduct(state, deviceItemId),
    );

    const [doorControllerService] = React.useState<DoorControllerService>(
        ServiceLocator.get(DoorControllerService),
    );

    const selectedRexForDoorSide = useSelector<
        IStoreState,
        Record<PiaId, ISelectedMountAccessory> | undefined
    >((state) =>
        getSelectedRexForDoorSideAndId(state, doorItemId, isSideA ? 'doorSideA' : 'doorSideB'),
    );

    const nbrSelectedRexDevicesDoorSide =
        (selectedRexForDoorSide && Object.values(selectedRexForDoorSide).length) || 0;

    const totalNbrSelectedRexDevices = useSelector<IStoreState, number>((state) =>
        getNbrSelectedRexDevicesForDoorController(state, deviceItemId),
    );
    const disableRexDevices =
        maxNbrOfRexDevices - totalNbrSelectedRexDevices + nbrSelectedRexDevicesDoorSide < 1;

    // for now we only allow one rex device. Take the name of the first one (and only)
    const selectedRexDeviceName =
        selectedRexForDoorSide && Object.values(selectedRexForDoorSide)[0]
            ? Object.values(selectedRexForDoorSide)[0].productId
                ? Object.values(selectedRexForDoorSide)[0].name
                : t.other
            : undefined;

    const getRexDevicesToRemove = (): IIdRev[] => {
        let rexDevicesToRemove: IIdRev[] = [];
        if (selectedRexForDoorSide && Object.keys(selectedRexForDoorSide).length > 0) {
            const cardReadersToRemoveAxis = Object.values(selectedRexForDoorSide).map((reader) => ({
                _id: reader.id,
                _rev: reader.rev,
            }));
            rexDevicesToRemove = rexDevicesToRemove.concat(cardReadersToRemoveAxis);
        }
        return rexDevicesToRemove;
    };

    const onRexDeviceSelect = (piaId: number) => {
        if (!door?.properties.door) {
            return;
        }
        // if rex-device already selected remove it
        if (selectedRexForDoorSide && selectedRexForDoorSide[piaId]) {
            const itemIdToRemove = selectedRexForDoorSide[piaId];
            doorControllerService.removeCardReader({
                _id: itemIdToRemove.id,
                _rev: itemIdToRemove.rev,
            });
        } else {
            // if some rex-device selected, remove them
            const cardReadersToRemove = getRexDevicesToRemove();
            doorControllerService.addRexDevice(door?._id, piaId, isSideA, cardReadersToRemove);
        }
    };

    // When Axis has added REX-devices this can be removed and we go for the solution with drop-down instead
    const onRexDeviceToggle = () => {
        onRexDeviceSelect(rexDevices[0].id);
    };

    const content = (
        <>
            <DropDownMenuItem isText>
                <Text style="heading">
                    {i18nextT(`accessoryDisplayCategoriesGROUP.${PiaAccessoryCategory.REX}`)}
                </Text>
            </DropDownMenuItem>
            {rexDevices.map((rexDevice) => (
                <Clickable
                    key={rexDevice.id}
                    onClick={() => {
                        onRexDeviceSelect(rexDevice.id);
                    }}
                >
                    <DropDownMenuItem
                        testId={`card_reader_${rexDevice.name}`}
                        onlyContent
                        selected={
                            selectedRexForDoorSide &&
                            selectedRexForDoorSide[rexDevice.id] !== undefined
                        }
                    >
                        <Box
                            padding="cell"
                            justifyContent="between"
                            alignItems="center"
                            width="100%"
                        >
                            <Stack>
                                <PiaImage piaId={rexDevice.id} icon="category" imageSize="md" />
                                <Stack vertical spacing="none">
                                    <Text>{rexDevice.id ? rexDevice.name : t.other}</Text>
                                </Stack>
                            </Stack>
                            <Stack>
                                {rexDevice.isIncluded && (
                                    <Badge compact label={t.included} type="yellow" />
                                )}
                                {rexDevice.isRecommended && !rexDevice.isIncluded && (
                                    <Badge compact label={t.recommended} type="green" />
                                )}
                                {!rexDevice.isIncluded && (
                                    <PiaItemMsrp piaId={rexDevice.id} includeMultipacks />
                                )}
                            </Stack>
                        </Box>
                    </DropDownMenuItem>
                </Clickable>
            ))}
        </>
    );

    // when Axis has added REX device as accessory we can remove the showDropDown bool and only go for DropDown
    // and remove ShadowButton
    return showDropDown ? (
        <DropDown
            disabled={isLocked || disableRexDevices}
            minWidth={400}
            trigger={
                <ShadowButton
                    icon="add"
                    label={
                        selectedRexDeviceName
                            ? `${t.rexDevice}: ${selectedRexDeviceName}`
                            : t.rexDevice
                    }
                    selected={selectedRexDeviceName !== undefined}
                />
            }
            placeHolder={t.rexDevice}
            contents={content}
        />
    ) : (
        <ShadowButton
            disabled={isLocked || disableRexDevices}
            label={t.rexDevice}
            onClick={onRexDeviceToggle}
            selected={selectedRexDeviceName !== undefined}
        />
    );
};

RexSelector.displayName = 'RexSelector';
