import { t } from 'app/translate';
import i18next from 'i18next';
import { createSelector } from 'reselect';
import { getLocationId } from './getCurrentProject';
import { needHelpConfig } from './needHelpConfig';

export const getNeedHelpConfig = createSelector([getLocationId], (projectCountryId) => {
    const languageCode = i18next.language;

    const config = needHelpConfig()[projectCountryId]?.[languageCode] ?? undefined;
    if (!config) return undefined;

    const telephoneLink = config.phoneNumber
        ? `+1${config.phoneNumber.replace(/-/g, '')}`
        : undefined;
    return {
        ...config,
        telephoneLink,
        title: config.title ?? t.needDesignAssistance,
        linkText: config.linkText ?? t.scheduleAssistance,
    };
});
