import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    Border,
    Box,
    Button,
    Card,
    Icon,
    OverlayPanel,
    Positioned,
    TextInput,
    Checkbox,
    Clickable,
} from 'app/components';
import { getSelectedMapOrDefault, getShowAddMapModal, getShowCopyMapModal } from '../../selectors';
import { getFloorPlanConfigOverlayPanel } from '../../selectors/getOverlayPanel';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../services';
import { t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import { FloorPlanService, ImageService } from 'app/core/persistence';
import { ColorsEnum } from 'app/styles';
import { getUploading } from '../../selectors/getUploadImage';

import { getIsScalingToolEnabled } from '../../selectors/getIsScalingToolEnabled';
import { UploadFloorPlanModal } from './UploadFloorPlanModal';
import { getCurrentProject, isGeoLocated } from 'app/modules/common';
import { CopyFloorPlanModal } from './CopyFloorPlanModal';
import { eventTracking } from 'app/core/tracking';
import { FloorPlanOpacityRange } from '../opacityRange';

export const FloorPlanConfigOverlayPanel: React.FC = () => {
    const floorPlanService = useService(FloorPlanService);
    const imageService = useService(ImageService);
    const mapsActions = useService(MapsActionService);

    const project = useSelector(getCurrentProject);
    const overlayPanel = useSelector(getFloorPlanConfigOverlayPanel);
    const floorPlan = useSelector(getSelectedMapOrDefault);
    const uploading = useSelector(getUploading);
    const isEditingScale = useSelector(getIsScalingToolEnabled);
    const isFloorPlanGeoLocated = useSelector(() => isGeoLocated(floorPlan));
    const showChangeFloorPlanImage = useSelector(getShowAddMapModal);
    const showCopyMapModal = useSelector(getShowCopyMapModal);
    const [showAutoRotate, setShowAutoRotate] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState<string | undefined>('');

    React.useEffect(() => {
        const image = floorPlan && floorPlan?.image;
        if (image) {
            const getPreviewImageSrc = async () => {
                try {
                    const previewImageSrc = await imageService.getImageUrlAsBase64(image.key);

                    setPreviewImage(previewImageSrc);
                } catch (error) {
                    setPreviewImage(undefined);
                    // The error is logged to event tracking in Image service so we can ignore it here
                }
            };

            getPreviewImageSrc();

            const fetchExif = async () => {
                try {
                    const isRotated = await imageService.isEXIFRotated(image.key);

                    setShowAutoRotate(isRotated);
                } catch (error) {
                    setShowAutoRotate(false);
                    // The error is logged to event tracking in Image service so we can ignore it here
                }
            };

            fetchExif();
        }
    }, [floorPlan, imageService]);

    const onNameChange = async (newName: string) => {
        if (floorPlan) {
            eventTracking.logUserEvent('Maps', 'Change floor plan name');
            await floorPlanService.updateFloorPlan({
                ...floorPlan,
                name: newName,
            });
        }
    };

    const enableAutorotation = async (autoRotate: boolean) => {
        if (floorPlan && floorPlan.image) {
            await floorPlanService.updateFloorPlanDebounced({
                ...floorPlan,
                image: {
                    ...floorPlan.image,
                    ignoreEXIF: !autoRotate,
                },
            });
        }
    };

    const toggleCopyFloorPlanModal = () => {
        eventTracking.logUserEvent('Maps', 'Copy floor plans');
        mapsActions.toggleCopyMapModal(!showCopyMapModal);
    };

    const toggleScalingTool = () => {
        eventTracking.logUserEvent('Maps', 'Change floor plan scale');
        mapsActions.toggleScalingTool();
        mapsActions.toggleFloorPlanConfigOverlayPanel(false);
    };

    const toggleChangeImageModal = () => {
        eventTracking.logUserEvent('Maps', 'Change floor plan image');
        mapsActions.toggleAddMapModal(!showChangeFloorPlanImage);
    };

    const editGeolocation = () => {
        if (floorPlan) {
            eventTracking.logUserEvent('Maps', 'Change floor plan location');
            mapsActions.goToGeolocation(floorPlan._id);
            mapsActions.toggleGeoLocationTool(floorPlan._id);
            mapsActions.toggleFloorPlanConfigOverlayPanel(false);
        }
    };

    const removeFloorPlan = () => {
        if (floorPlan) {
            eventTracking.logUserEvent('Maps', 'Delete floor plan');
            mapsActions.removeFloorPlan(floorPlan);
            mapsActions.toggleFloorPlanConfigOverlayPanel(false);
        }
    };

    if (!floorPlan) {
        return null;
    }

    return (
        <OverlayPanel active={overlayPanel ?? false} maxWidth="350px" onInactivate={() => {}}>
            <Box paddingTop="half" justifyContent="end">
                <Button text onClick={() => mapsActions.toggleFloorPlanConfigOverlayPanel(false)}>
                    {t.close}
                </Button>
            </Box>
            <Box direction="column" spacing="base" padding="base" paddingTop="none">
                <Card>
                    <Box direction="column" padding="base" spacing="base" width="100%">
                        <TextInput
                            maxLength={AppConstants.deviceNameMaxLength}
                            value={floorPlan.name}
                            onChange={onNameChange}
                            label={t.name}
                            required
                            grow
                        />
                        {floorPlan.image && (
                            <>
                                <Box position="relative" width="100%">
                                    <img
                                        style={{
                                            opacity: floorPlan.image?.opacity || 0.5,
                                            maxWidth: '100%',
                                            border: `1px solid ${ColorsEnum.grey4}`,
                                            imageOrientation: floorPlan.image?.ignoreEXIF
                                                ? 'none'
                                                : 'unset',
                                        }}
                                        src={previewImage}
                                    />
                                    {/* Hide icon for changing floorplan if geolocated */}
                                    {!isFloorPlanGeoLocated && (
                                        <Clickable onClick={toggleChangeImageModal}>
                                            <Positioned
                                                position="absolute"
                                                top="calc(50% - 28px)"
                                                left="calc(50% - 28px)"
                                                transformOrigin="center"
                                            >
                                                <Border>
                                                    <Box
                                                        testId="change_floor_plan"
                                                        color="whiteOpacity"
                                                        hoverColor="white"
                                                        borderRadius="round"
                                                        padding="half"
                                                    >
                                                        <Icon
                                                            opaque
                                                            icon="edit"
                                                            color="blue"
                                                            size="lg"
                                                        />
                                                    </Box>
                                                </Border>
                                            </Positioned>
                                        </Clickable>
                                    )}
                                </Box>

                                {showAutoRotate && (
                                    <Checkbox
                                        selected={!floorPlan.image?.ignoreEXIF}
                                        onChange={enableAutorotation}
                                        labelLeft
                                        slider
                                    >
                                        {t.imageRotation}
                                    </Checkbox>
                                )}

                                <FloorPlanOpacityRange floorPlan={floorPlan} />
                            </>
                        )}
                    </Box>
                </Card>
                {floorPlan.image && (
                    <>
                        <Button testId="copy_floor_plan" onClick={toggleCopyFloorPlanModal}>
                            {t.copyFloorPlan}
                        </Button>
                        {isFloorPlanGeoLocated && (
                            <Button testId="edit_geolocation" onClick={editGeolocation}>
                                {t.editGeolocation}
                            </Button>
                        )}
                        <Button
                            testId="edit_scale"
                            primary={isEditingScale}
                            onClick={toggleScalingTool}
                        >
                            {t.editScale}
                        </Button>
                    </>
                )}
                <Button primary onClick={removeFloorPlan} testId="delete_map_button">
                    {t.deleteMap}
                </Button>
            </Box>

            {showChangeFloorPlanImage && (
                <UploadFloorPlanModal
                    currentFloorPlan={floorPlan}
                    onClose={toggleChangeImageModal}
                />
            )}

            {showCopyMapModal && (
                <CopyFloorPlanModal
                    projectId={project._id}
                    floorPlan={floorPlan}
                    uploading={uploading}
                    previewImage={previewImage}
                    onClose={toggleCopyFloorPlanModal}
                />
            )}
        </OverlayPanel>
    );
};

FloorPlanConfigOverlayPanel.displayName = 'FloorPlanConfigOverlayPanel';
