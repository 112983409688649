import { getDeviceType, getParentId } from 'app/core/persistence';
import {
    createInstallationPointDerotationTransform,
    getCurrentProjectItems,
    getIdsFromProps,
} from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { toMiniMapMarker } from '../utils';
import {
    getMapsSortedByDate,
    getInstallationPointsPerFloorPlan,
    createGetMapLegendId,
} from '../../../selectors';
import { defaultColors } from 'app/core/common';
import type { IMiniMapMarker } from '../models';

export const getMapMarkersForFloorPlan = createSelector(
    [
        getMapsSortedByDate,
        getInstallationPointsPerFloorPlan,
        createGetMapLegendId,
        getCurrentProjectItems,
    ],
    (floorPlans, installationPointsPerFloorPlan, getLegendId, currentProjectItems) => {
        return floorPlans.reduce(
            (groupedByFloorPlan, floorPlan) => {
                const ipTransform = createInstallationPointDerotationTransform(floorPlan);
                groupedByFloorPlan[floorPlan._id] = (
                    installationPointsPerFloorPlan[floorPlan._id] ?? []
                )
                    .map((ip) => ipTransform(ip))
                    .map((ip) => {
                        if (!floorPlan.image?.bounds) {
                            return undefined;
                        }

                        const deviceType = getDeviceType(ip.parentDevice);
                        const legendId = getLegendId(floorPlan._id, ip.parentDevice._id);

                        const parentId = getParentId(ip.parentDevice);

                        let ipColor = ip.parentDevice.color ?? defaultColors.DEFAULT_DEVICE_COLOR;

                        if (parentId) {
                            ipColor = currentProjectItems[parentId]?.color ?? ipColor;
                        }

                        return toMiniMapMarker(ip, floorPlan, ipColor, deviceType, legendId);
                    })
                    .filter(isDefined);

                return groupedByFloorPlan;
            },
            {} as Record<string, IMiniMapMarker[]>,
        );
    },
);

export const getMapMarkersForFloorPlanWithDeviceIds = createCachedSelector(
    [getMapMarkersForFloorPlan, getIdsFromProps],
    (mapMarkersForFloorPlan, itemIds) => {
        const markers: Record<string, IMiniMapMarker[]> = {};

        Object.entries(mapMarkersForFloorPlan).forEach(([key, values]) => {
            const withItems = values.filter((value) => itemIds.includes(value.deviceId));
            if (withItems.length > 0) {
                if (!markers[key]) {
                    markers[key] = [];
                }
                markers[key] = markers[key].concat(withItems);
            }
        });

        return markers;
    },
)((_state, itemIds) => itemIds.join(','));
