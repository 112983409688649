import { PiaAccessoryCategory } from 'app/core/pia';

export enum DisplayCategories {
    AUDIOANDIO = 'audioandio',
    CONNECTIVITY = 'connectivity',
    HOUSINGSANDCABINETS = 'housingsandcabinets',
    ILLUMINATORS = 'illuminators',
    LENSES = 'lenses',
    MODULES = 'extModules',
    OTHER = 'other',
    POWER = 'power',
    READERS = 'readers',
    MOUNTS = 'mounts',
    STORAGE = 'storage',
    JOYSTICKSCONTR = 'joystickscontr',
}

export const PIA_CATEGORY_TO_DISPLAY_CATEGORY: Record<string, DisplayCategories> = {
    [PiaAccessoryCategory.MODULES]: DisplayCategories.MODULES,

    [PiaAccessoryCategory.ILLUMINATORS]: DisplayCategories.ILLUMINATORS,
    [PiaAccessoryCategory.LENSES]: DisplayCategories.LENSES,

    [PiaAccessoryCategory.POWERSUPPLIES]: DisplayCategories.POWER,
    [PiaAccessoryCategory.POE]: DisplayCategories.POWER,

    [PiaAccessoryCategory.CABLESCON]: DisplayCategories.CONNECTIVITY,
    [PiaAccessoryCategory.ETHERNETOVERCOAX]: DisplayCategories.CONNECTIVITY,
    [PiaAccessoryCategory.MEDIACONVERTERS]: DisplayCategories.CONNECTIVITY,
    [PiaAccessoryCategory.SURGEPROTECTORS]: DisplayCategories.CONNECTIVITY,
    [PiaAccessoryCategory.POEEXTENDERS]: DisplayCategories.CONNECTIVITY,

    [PiaAccessoryCategory.COVERSDOMES]: DisplayCategories.HOUSINGSANDCABINETS,
    [PiaAccessoryCategory.CABINETS]: DisplayCategories.HOUSINGSANDCABINETS,
    [PiaAccessoryCategory.HOUSINGS]: DisplayCategories.HOUSINGSANDCABINETS,

    [PiaAccessoryCategory.AUDIOANDIO]: DisplayCategories.AUDIOANDIO,

    [PiaAccessoryCategory.JOYSTICKSCONTR]: DisplayCategories.JOYSTICKSCONTR,

    [PiaAccessoryCategory.READERS]: DisplayCategories.READERS,

    [PiaAccessoryCategory.MOUNTS]: DisplayCategories.MOUNTS,

    [PiaAccessoryCategory.STORAGE]: DisplayCategories.STORAGE,
};
