import * as React from 'react';
import {
    BillOfMaterials,
    InstallationReport,
    PowerAndBandwidthReport,
    ProposalReport,
    MapsReport,
} from 'app/modules/reports';
import { QuotationView, QuotationEdit } from 'app/modules/quotation';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import { getCurrentProjectId, getCurrentProjectLocked } from 'app/modules/common';
import type { Id } from 'app/core/persistence';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'app/routes/components';

interface IReportRoutesProps {
    projectId: Id;
    projectLocked: boolean;
}

const mapStateToProps = (storeState: IStoreState): IReportRoutesProps => {
    return {
        projectId: getCurrentProjectId(storeState),
        projectLocked: getCurrentProjectLocked(storeState),
    };
};

export class ReportRoutesContainer extends React.Component<IReportRoutesProps> {
    public render() {
        return (
            <Routes>
                <Route path="bom/" element={<BillOfMaterials />} />
                <Route path="bandwidth/" element={<PowerAndBandwidthReport />} />
                <Route path="installation/" element={<InstallationReport />} />
                <Route path="proposal/" element={<ProposalReport />} />
                <Route path="maps/" element={<MapsReport />} />
                <Route
                    path="quotation/edit/"
                    element={
                        <PrivateRoute
                            component={<QuotationEdit />}
                            condition={!this.props.projectLocked}
                            fallbackRoute={`/project/${this.props.projectId}/reports/quotation/view/`}
                        />
                    }
                />
                <Route path="quotation/view/" element={<QuotationView />} />
                {/* Redirect to quotation/edit when first clicking reports, and when having non-matching paths */}
                <Route path="*" element={<Navigate to="quotation/edit/" replace />} />
            </Routes>
        );
    }
}

export const ReportRoutes = connect(mapStateToProps)(ReportRoutesContainer);
