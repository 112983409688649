import * as React from 'react';
import type { IPlaylistItem } from './PlaylistItem';
import { PlaylistItem } from './PlaylistItem';
import { css } from '@emotion/css';
import { Box } from '../../containers';
import { throttle } from 'lodash-es';
import { useResizeObserver } from 'app/hooks';
import { eventTracking } from 'app/core/tracking';

interface IVideoProps {
    /** The vide source file */
    src: string;
    /** The current time of the video in seconds */
    currentTime?: number;
    /** A list of playable items */
    playlist?: IPlaylistItem[];
}

const ONE_MINUTE = 60 * 1000;

export const Video: React.FC<IVideoProps> = ({ src, currentTime, playlist }) => {
    const [activePlaylistIndex, setActivePlaylistIndex] = React.useState(0);
    const [playlistMaxHeight, setPlaylistMaxHeight] = React.useState<number | undefined>(undefined);
    const [playlistWidth, setPlaylistWidth] = React.useState(playlist ? 300 : 0);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const videoRef = useResizeObserver<HTMLVideoElement>({
        onResize: ({ height }) => {
            setPlaylistMaxHeight(height);
        },
    }).ref;

    React.useEffect(() => {
        if (currentTime && videoRef.current && videoRef.current?.currentTime !== currentTime) {
            videoRef.current.currentTime = currentTime;
        }
    }, [currentTime, videoRef]);

    const setVideoTime = (time: number, logKey?: string) => {
        if (videoRef.current) {
            eventTracking.logUserEvent('Maps', 'View help section', logKey ?? '(No log key)');
            videoRef.current.currentTime = time;
            videoRef.current.paused && videoRef.current.play();
        }
    };

    const updateActivePlaylistItem = (videoTimeInSeconds: number) => {
        // Get the index of the playlist item that is currently active based on the video time
        const index = playlist?.findIndex(
            (item, i) =>
                videoTimeInSeconds >= item.startTime &&
                videoTimeInSeconds < (playlist[i + 1]?.startTime ?? Infinity),
        );

        // Update the active playlist index if it has changed
        if (playlist && index !== activePlaylistIndex) {
            const activeIndex = index ?? 0;
            eventTracking.logApplicationEvent(
                'Maps',
                'View help section',
                playlist[activeIndex].logKey ?? '(No log key)',
            );
            setActivePlaylistIndex(activeIndex);
        }
    };

    const resizeVideoToFitWindow = (videoAspectRatio: number) => {
        const overflow =
            Math.abs(containerRef.current?.getBoundingClientRect().bottom ?? 0) -
            (window.innerHeight - 24); // 24 is the padding of the container

        if (overflow < -50) {
            // Reset the width of the playlist if the window has resized and the video is no longer overflowing
            setPlaylistWidth(playlist ? 300 : 0);
        }

        if (overflow > 0) {
            // Calculate the new width of the playlist based on the aspect ratio of the video
            // the 16px is the padding of the playlist
            const deltaWidth = Math.ceil(Math.abs(overflow) * videoAspectRatio) + 16;
            setPlaylistWidth((width) => width + deltaWidth);
        }
    };

    /** Update the active playlist item based on the video time */
    const videoTimeUpdated = throttle(() => {
        if (!playlist || !videoRef.current) return;

        updateActivePlaylistItem(videoRef.current.currentTime);
        resizeVideoToFitWindow(videoRef.current.videoWidth / videoRef.current.videoHeight);
    }, 500);

    return (
        <Box
            innerRef={containerRef}
            display="grid"
            grid={{ gridTemplateColumns: `1fr ${playlistWidth}px` }}
            spacing={playlist ? 'base' : 'none'}
        >
            <video
                ref={videoRef}
                controls
                playsInline
                muted
                disablePictureInPicture
                controlsList="nodownload"
                preload="metadata"
                src={`${src}#t=0.1`} // Add a time to the end of the video source to make sure first frame is shown in Safari
                className={css`
                    width: 100% !important;
                    height: auto !important;
                `}
                onTimeUpdate={videoTimeUpdated}
                onPlay={() =>
                    throttle(
                        () => eventTracking.logUserEvent('Maps', 'Play help video'),
                        ONE_MINUTE,
                    )
                }
            />
            {playlist?.length && (
                <Box
                    justifyContent="start"
                    direction="column"
                    lineBetweenColor="grey2"
                    maxHeight={playlistMaxHeight}
                    overflowY="auto"
                >
                    {playlist.map((item, index) => (
                        <PlaylistItem
                            key={index}
                            active={activePlaylistIndex === index}
                            header={item.header}
                            description={item.description}
                            startTime={item.startTime}
                            onClick={(time) => setVideoTime(time, item.logKey)}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

Video.displayName = 'Video';
