import { css } from '@emotion/css';
import type { IExtendableComponentWithChildren } from 'app/components';
import { Border, Box, Positioned, Opacity, Text } from 'app/components';
import { ColorsEnum } from 'app/styles';
import React from 'react';

interface IWelcomeCard extends IExtendableComponentWithChildren {
    header: React.ReactNode;
    showTracks?: boolean;
}

export const WelcomeCard: React.FC<IWelcomeCard> = ({
    header,
    showTracks,
    children,
    ...extendedProps
}) => {
    return (
        <Border {...extendedProps} color="grey3" hoverColor="yellow" radius="10px">
            <Box color="white" lineBetweenColor="grey3" direction="column" overflow="hidden">
                <Box paddingX="half" minHeight="50px" alignItems="center" justifyContent="center">
                    {typeof header === 'string' ? (
                        <Text style="semibold" color="grey9">
                            {header}
                        </Text>
                    ) : (
                        header
                    )}
                </Box>
                <Box
                    padding={showTracks ? 'panel' : 'none'}
                    position="relative"
                    height="100%"
                    __htmlAttributes={{
                        className: css`
                            background: linear-gradient(
                                137deg,
                                ${ColorsEnum['white']} 0.54%,
                                ${ColorsEnum['grey3']} 126.04%
                            );
                        ,`,
                    }}
                >
                    <Box
                        padding="base"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="100%"
                    >
                        {children}
                    </Box>
                    {showTracks && (
                        <Positioned position="absolute" bottom={0} right={0}>
                            <Opacity alpha={0.9}>
                                <Box width={100} height={100}>
                                    <img src={require('assets/images/tracks.svg')} />
                                </Box>
                            </Opacity>
                        </Positioned>
                    )}
                </Box>
            </Box>
        </Border>
    );
};
