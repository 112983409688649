import { Box, Icon, Text } from 'app/components';
import * as React from 'react';
import { css } from '@emotion/css';
import { Trans } from 'react-i18next';
import { LINK_TERMS_AND_CONDITIONS } from './GenetecTermsAndConditions';

const disclaimerHiddenStyle = css`
    visibility: hidden;
`;

interface IGenetecDisclaimerProps {
    hidden?: boolean;
}

export const GenetecDisclaimer: React.FC<IGenetecDisclaimerProps> = ({ hidden }) => {
    const disclaimerNode = React.useMemo(
        () => (
            <Text color="grey5" align="left">
                <Trans
                    i18nKey="genetecGROUP.disclaimer"
                    components={{
                        Link: (
                            <a
                                data-test-id="genetec_terms_of_use_link"
                                target="_blank"
                                rel="noreferrer noopener"
                                href={LINK_TERMS_AND_CONDITIONS}
                            ></a>
                        ),
                    }}
                />
            </Text>
        ),
        [],
    );
    return (
        <Box
            __htmlAttributes={{
                className: hidden ? disclaimerHiddenStyle : undefined,
            }}
            justifyContent="center"
            paddingBottom="quart"
            testId="genetec_disclaimer"
        >
            <Box paddingTop="halfQuart" paddingLeft="halfQuart" paddingRight="quart">
                <Icon color={'blue'} icon={'info'} opaque size="sm" />
            </Box>
            {disclaimerNode}
        </Box>
    );
};

GenetecDisclaimer.displayName = 'GenetecDisclaimer';
