import {
    Box,
    Button,
    Clickable,
    ColorPalette,
    DropDown,
    DropDownMenuItem,
    Grid,
    Icon,
    IconButton,
    Label,
    Modal,
    PillButton,
    Quantity,
    Stack,
    Text,
    Textarea,
    TextInput,
} from 'app/components';
import type { IAddProductProps } from 'app/modules/common';
import {
    getDefaultProfileId,
    getNewDeviceNameFromCategory,
    getCurrentProjectItem,
    getNumberOfConnectableChildren,
    getPiaItemForProductId,
    getPiaItemShouldHaveScenarios,
    getPiaDeviceProfileSupport,
    getSortedProfiles,
    getChannelInformation,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import type { DeviceGroup } from '../models';
import type { IPiaItem, PiaCategory, PiaId } from 'app/core/pia';
import {
    PiaItemCameraCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemEncoderCategory,
    PiaItemMainUnitCategory,
    PiaItemPacCategory,
    PiaItemPagingConsoleCategory,
    PiaItemPeopleCounterCategory,
    PiaItemSensorUnitCategory,
    PiaItemSpeakerCategory,
    PiaItemWearablesCategory,
} from 'app/core/pia';
import { getCalculatedSpeakerQuantity } from '../selectors';
import type { Colors } from 'app/styles';
import { AppConstants } from 'app/AppConstants';
import { ApplicationActionService } from 'app/modules/application';
import { ServiceLocator } from 'app/ioc';
import type { IPersistence, IProfileEntity } from 'app/core/persistence';
import { RequiredComponentsSelector } from './RequiredComponentsSelector';
import { defaultColors } from 'app/core/common';

const MAX_QUANTITY = 9999;

interface IAddProductModalProps {
    productInfo: { productId: number | null; name: string };
    parentDeviceId: string | null;
    deviceGroup?: DeviceGroup;
    onClose(): void;
    onAddProduct(props: IAddProductProps): void;
}

export const AddProductModal: React.FC<IAddProductModalProps> = ({
    productInfo,
    parentDeviceId,
    deviceGroup,
    onClose,
    onAddProduct,
}) => {
    const [applicationActionService] = React.useState<ApplicationActionService>(
        ServiceLocator.get(ApplicationActionService),
    );
    const defaultProfileId = useSelector<IStoreState, string | undefined>(getDefaultProfileId);

    const [selectedScenario, setSelectedScenario] = useState(defaultProfileId);
    const [notes, setNotes] = useState('');
    const [selectedRequiredComponentPiaIds, setSelectedRequiredComponentPiaIds] = useState(
        [] as PiaId[],
    );

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItemForProductId(state, productInfo.productId),
    );

    const scenarios = Object.values(
        useSelector<IStoreState, IPersistence<IProfileEntity>[]>(getSortedProfiles),
    );

    const profileSupportRecord = useSelector<IStoreState, Record<string, boolean>>((state) =>
        getPiaDeviceProfileSupport(state, piaItem?.id),
    );

    const isExpanded = useSelector<IStoreState, boolean>(
        (state) => state.app.userPreferences.addProductExpanded,
    );

    const shouldHaveScenarios = useSelector<IStoreState, boolean>((state) =>
        getPiaItemShouldHaveScenarios(state, piaItem?.id),
    );

    let maxQuantity = MAX_QUANTITY;
    const availableChannels = useSelector<IStoreState, number>((state) =>
        parentDeviceId ? getNumberOfConnectableChildren(state, parentDeviceId) : 0,
    );
    const usedChannels = useSelector<IStoreState, number>((state) =>
        parentDeviceId ? getChannelInformation(state, parentDeviceId).usedChannels : 0,
    );
    if (parentDeviceId) {
        maxQuantity = availableChannels - usedChannels;
    }

    const calculatedSolutionQuantity = useSelector<IStoreState, number>((state) =>
        getCalculatedSpeakerQuantity(state, undefined),
    );

    const defaultColor = useSelector<IStoreState, Colors>(
        (state) =>
            getCurrentProjectItem(state, parentDeviceId ?? undefined)?.color ?? 'devicePalette7',
    );

    const addProductProps = getAddProductProps(
        piaItem?.category,
        calculatedSolutionQuantity,
        defaultColor,
        deviceGroup,
    );

    const [quantity, setQuantity] = useState<number>(addProductProps.quantity);
    const [name, setName] = useState<string>(addProductProps.name);
    const [color, setColor] = useState<Colors>(addProductProps.color);

    const toggleExpansion = () => {
        applicationActionService.setUserPreferences({
            addProductExpanded: !isExpanded,
        });
    };

    const renderScenarioButton = (scenario: IPersistence<IProfileEntity>) => (
        <PillButton
            key={scenario._id}
            selected={scenario._id === selectedScenario}
            icon={profileSupportRecord[scenario._id] === false ? 'warning_small' : undefined}
            iconColor="yellow"
            onClick={() => setSelectedScenario(scenario._id)}
            text={scenario.name}
        />
    );

    const renderScenarioDropDownItem = (scenario: IPersistence<IProfileEntity> | undefined) => {
        if (scenario === undefined) {
            return null;
        }

        return (
            <Clickable key={scenario._id} onClick={() => setSelectedScenario(scenario._id)}>
                <DropDownMenuItem selected={scenario._id === selectedScenario}>
                    <Stack spacing="half" alignItems="start">
                        <Text>{scenario.name}</Text>
                        {profileSupportRecord[scenario._id] === false && (
                            <Icon icon="warning_small" color="yellow7" size="sm" opaque />
                        )}
                    </Stack>
                </DropDownMenuItem>
            </Clickable>
        );
    };

    return (
        <Modal
            title={!piaItem ? `${t.addDevice}` : `${t.addPiaDevice(productInfo.name)}`}
            minWidth="480px"
            onClose={onClose}
        >
            <Stack vertical>
                <Stack spacing="base" alignItems="end">
                    <Box direction="column" width="100%">
                        <Label>{t.name}</Label>
                        <TextInput
                            grow
                            autoFocus
                            changeCriteria="key"
                            value={name}
                            error={name.trim().length < 1}
                            onChange={setName}
                            onEnter={() =>
                                onAddProduct({
                                    name,
                                    quantity,
                                    color,
                                    notes,
                                    scenario: selectedScenario,
                                    accessories: selectedRequiredComponentPiaIds,
                                })
                            }
                            maxLength={AppConstants.deviceNameMaxLength}
                        ></TextInput>
                    </Box>
                    <Box direction="column" alignItems="center">
                        <Label>{t.quantity}</Label>
                        <Quantity
                            changeOnKey
                            min={1}
                            max={maxQuantity}
                            value={quantity}
                            onChange={setQuantity}
                        />
                    </Box>
                    {/* Disable color picker for child units e.g sensor units since they inherit color from parent in maps */}
                    <ColorPalette
                        updateColor={setColor}
                        color={color}
                        disabled={parentDeviceId !== null}
                    />
                </Stack>
                <RequiredComponentsSelector
                    piaId={piaItem?.id}
                    onChange={setSelectedRequiredComponentPiaIds}
                />
            </Stack>
            {isExpanded && (
                <Box
                    paddingLeft="none"
                    paddingY="panel"
                    justifyContent="between"
                    direction="column"
                >
                    {shouldHaveScenarios && (
                        <Stack vertical spacing="halfCell" notFullWidth justifyContent="center">
                            <Label>{t.deviceListProfile}</Label>
                            {scenarios.length < 5 && (
                                <Box maxWidth="450px" paddingRight="quart">
                                    <Grid spacing="quart">
                                        {scenarios.map((scenario) =>
                                            renderScenarioButton(scenario),
                                        )}
                                    </Grid>
                                </Box>
                            )}
                            {selectedScenario && scenarios.length > 5 && (
                                <Box>
                                    <DropDown
                                        openInPortal
                                        contents={scenarios.map((scenario) =>
                                            renderScenarioDropDownItem(scenario),
                                        )}
                                        trigger={
                                            <PillButton
                                                text={
                                                    scenarios.find(
                                                        (scenario) =>
                                                            scenario._id === selectedScenario,
                                                    )?.name ?? ''
                                                }
                                                icon="keyboard_arrow_down"
                                            />
                                        }
                                    ></DropDown>
                                </Box>
                            )}
                            <Box height="18px">
                                {selectedScenario &&
                                    profileSupportRecord[selectedScenario] === false && (
                                        <Stack spacing="half">
                                            <Icon
                                                size="sm"
                                                icon="warning_small"
                                                color="yellow7"
                                                opaque
                                            />
                                            <Text>{t.profilesSupportNotSupported}</Text>
                                        </Stack>
                                    )}
                            </Box>
                        </Stack>
                    )}
                    <Box paddingTop="base">
                        <Stack vertical spacing="halfCell">
                            <Label>{t.notes}</Label>
                            <Textarea
                                rows={4}
                                value={notes}
                                onChange={setNotes}
                                changeCriteria="key"
                            />
                        </Stack>
                    </Box>
                </Box>
            )}
            <Box paddingTop={isExpanded ? 'cell' : 'doublePanel'} justifyContent="between">
                <Stack alignItems="center">
                    <IconButton
                        text={isExpanded ? t.less : t.deviceListAddOtherListMore}
                        icon={isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                        onClick={toggleExpansion}
                    ></IconButton>
                </Stack>
                <Box />
                <Box justifyContent="end">
                    <Stack spacing="base">
                        <Button text onClick={onClose}>
                            {t.cancel}
                        </Button>
                        <Button
                            testId={'add_product_modal_btn'}
                            primary
                            disabled={name.trim().length < 1}
                            onClick={() =>
                                onAddProduct({
                                    name,
                                    quantity,
                                    color,
                                    notes,
                                    scenario: selectedScenario,
                                    accessories: selectedRequiredComponentPiaIds,
                                })
                            }
                        >
                            {t.add}
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
};

function getAddProductProps(
    piaCategory: PiaCategory | undefined,
    calculatedSolutionQuantity: number,
    color: Colors,
    deviceGroup?: DeviceGroup,
): IAddProductProps {
    if (!piaCategory) {
        switch (deviceGroup) {
            case 'cameras':
                return { name: t.cameraSelectorNewCamera, quantity: 1, color };
            case 'encoders':
                return { name: t.encoderSelectorNewEncoder, quantity: 1, color };
            case 'mainunits':
                return { name: t.mainUnitSelectorNewMainUnit, quantity: 1, color };
            case 'sensorunits':
                return { name: t.cameraSelectorNewSensorUnit, quantity: 1, color };
            case 'accessControls':
                return { name: t.newDoorController, quantity: 1, color: 'devicePalette8' };
            case 'speakers':
                return {
                    name: t.speakerSelectorNewSpeaker,
                    quantity: Math.max(1, calculatedSolutionQuantity),
                    color: 'devicePalette3',
                };
            default:
                return { name: t.newDevice, quantity: 1, color };
        }
    }
    const newDefaultName = getNewDeviceNameFromCategory(piaCategory);
    switch (piaCategory) {
        case PiaItemCameraCategory.THERMAL:
        case PiaItemCameraCategory.PTZ:
        case PiaItemCameraCategory.FIXEDDOME:
        case PiaItemCameraCategory.FIXED:
        case PiaItemCameraCategory.CAMERAEX:
        case PiaItemCameraCategory.COMPLETEMODULAR:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemEncoderCategory.ENCODER:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemMainUnitCategory.MAINUNIT:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemSensorUnitCategory.SENSORUNIT:
        case PiaItemSensorUnitCategory.THERMALSENSOR:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemSpeakerCategory.SPEAKER:
            return {
                name: newDefaultName,
                quantity: Math.max(1, calculatedSolutionQuantity),
                color: 'devicePalette3',
            };

        case PiaItemWearablesCategory.CAMERAEXTENSIONS:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemWearablesCategory.CAMERAS:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemWearablesCategory.CONTROLLER:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemWearablesCategory.DOCKING:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemPacCategory.DOORCONTROLLERS:
            return { name: newDefaultName, quantity: 1, color: 'devicePalette8' };

        case PiaItemPacCategory.IORELAYS:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemPacCategory.ACCESSSERVER:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemPacCategory.ANSWERINGUNIT:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemPacCategory.NETWORKREADER:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemPacCategory.DOORSTATIONS:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemDecoderCategory.DECODER:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemDetectorCategory.RADARDETECTORS:
            return { name: newDefaultName, quantity: 1, color: 'devicePalette5' };

        case PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemPeopleCounterCategory.PEOPLECOUNTERS:
            return { name: newDefaultName, quantity: 1, color };

        case PiaItemPagingConsoleCategory.PAGINGCONSOLE:
            return {
                name: newDefaultName,
                quantity: 1,
                color: defaultColors.DEFAULT_SPEAKER_COLOR,
            };

        default:
            return { name: newDefaultName, quantity: 1, color };
    }
}
