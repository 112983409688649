import type { DeviceAndSubType } from 'app/core/persistence';
import type { IPiaSensorUnit } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getDeviceAndSubTypeForItem } from './getDeviceTypeForItem';
import { getPiaItem } from './getPiaItem';

const sensorUnitTypes: DeviceAndSubType[] = ['sensorUnit'];

export const getPiaSensorUnit = createCachedSelector(
    [getPiaItem, getDeviceAndSubTypeForItem],
    (piaItem, deviceType) => {
        if (!piaItem || !deviceType || !sensorUnitTypes.includes(deviceType)) {
            return null;
        }

        return piaItem as IPiaSensorUnit;
    },
)(toCacheKey);
