import { createSelector } from 'reselect';
import { getDeviceGroupFilter } from './getProductsForDeviceGroup';

export const getSustainabilityFilterCount = createSelector(
    [getDeviceGroupFilter],
    (deviceGroupFilter) => {
        let count = 0;
        if (deviceGroupFilter === undefined) {
            return count;
        }
        count += deviceGroupFilter.bfrCfrFree ? 1 : 0;
        count += deviceGroupFilter.pvcFree ? 1 : 0;
        count += deviceGroupFilter.minRecycledPlastic ? 1 : 0;
        return count;
    },
);
