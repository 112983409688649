import * as React from 'react';
import * as moment from 'moment';

// See https://momentjs.com/docs/#/displaying/format/ for format documentation.

const shortFormats = {
    time: 'LT',
    date: 'L',
    timeAndDate: 'LLL',
};

const longFormats = {
    time: 'LTS',
    date: 'LL',
    timeAndDate: 'LLLL',
};

/**
 * Use this to print a localized date.
 * Defaults to short format timeAndDate: September 4, 1986 8:30 PM
 */
export interface IDateProps {
    /**
     * The date to format
     */
    date: Date;
    /**
     * What to display
     *
     * Time = 8:30 PM
     * Date = 09/04/1986
     * TimeAndDate = September 4, 1986 8:30 PM
     */
    display?: 'time' | 'date' | 'timeAndDate';
    /**
     * Use long format
     *
     * Time = 8:30:25 PM
     * Date = September 4, 1986
     * TimeAndDate = Thursday, September 4, 1986 8:30 PM
     */
    long?: boolean;
}

/**
 * Format a date/time localized according to user language.
 */
export class DateField extends React.Component<IDateProps> {
    public render() {
        const { date } = this.props;
        return this.formatDate(date);
    }

    private formatDate(date: Date): string {
        const formats = this.props.long ? longFormats : shortFormats;
        const format = this.props.display ? formats[this.props.display] : formats.timeAndDate;
        return moment(date).format(format);
    }
}
