import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { generateExcelFile } from 'app/utils/generateExcelFile';
import { getMountWithAccessoriesIncludingAllMounts, getSelectedDeviceId } from '../../selectors';
import { AccessoriesListItem } from '../accessoriesList/AccessoriesListItem.container';
import { Border, Box, Exclude, IconButton, SelectableList, Stack, Text } from 'app/components';
import {
    getCurrentProjectItem,
    getCurrentProjectLocation,
    getLocaleFromCountryCode,
    copyToClipboard,
    getSelectedMountIdsForItemId,
} from 'app/modules/common';
import { t } from 'app/translate';
import { isDefined } from 'axis-webtools-util';
import generatePDFDocument from './generatePDFDocument';
import type { IPdfItem } from './PdfTableDocument';
import { PdfTableDocument } from './PdfTableDocument';
import { css } from '@emotion/css';

const textCopyStyle = css`
    user-select: text;
`;

export const StandaloneAccessoriesList: React.FC = () => {
    const selectedDeviceId = useSelector<IStoreState, Id | undefined>(getSelectedDeviceId);

    const deviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) => {
        return getCurrentProjectItem(state, selectedDeviceId);
    });
    const locale = useSelector<IStoreState, string>((state) =>
        getLocaleFromCountryCode(getCurrentProjectLocation(state)?.countryCode),
    );
    const selectedMountIds = useSelector<IStoreState, Id[]>((state) =>
        getSelectedMountIdsForItemId(state, selectedDeviceId),
    );
    const selectedIds = (selectedDeviceId ? [selectedDeviceId, ...selectedMountIds] : []).filter(
        isDefined,
    );

    /**
     * Recursively fetches all selected items for BoM pdf
     **/
    const allAccessories = useSelector<IStoreState, IPdfItem[]>((state) => {
        const allIds: IPdfItem[] = [];

        const modelNameWithSpace = (depthIndex: number, modelName: string) => {
            const emptySpace = '  ';
            for (let i = 0; i < depthIndex; i++) {
                modelName = emptySpace + modelName;
            }
            return modelName;
        };

        const fetchAllAccessories = (id: Id, level: number = 0) => {
            const mountWithAccessories = getMountWithAccessoriesIncludingAllMounts(state, id);
            const { partNumber } = mountWithAccessories;
            const model = mountWithAccessories.model
                ? modelNameWithSpace(level, mountWithAccessories.model)
                : '-';

            allIds.push({ model, partNumber });
            mountWithAccessories.accessories.forEach((accessory) =>
                fetchAllAccessories(accessory.id, level + 1),
            );
        };
        selectedIds.forEach((id) => fetchAllAccessories(id));
        return allIds;
    });

    const exportToPdf = () => {
        const header = `AXIS ${t.accessorySelector} - ${t.billOfMaterial}, ${new Date(
            Date.now(),
        ).toLocaleDateString(locale)}`;
        const fileName = `${header}.pdf`;
        generatePDFDocument(
            <PdfTableDocument header={header} allAccessories={allAccessories} />,
            fileName,
        );
    };

    const exportToExcel = () => {
        const dataTitles: string[] = [`${t.name}`, `${t.partNumber}`];
        const dataToExport: string[][] = [];
        allAccessories.forEach((selectedItem) => {
            const model = selectedItem.model || '';
            const partNumber = selectedItem.partNumber || '-';
            dataToExport.push([model, partNumber]);
        });

        generateExcelFile(
            t.billOfMaterial,
            t.billOfMaterialsExcelSheetTitle,
            dataTitles,
            dataToExport,
        );
    };

    const onCopyToClipboardClick = () => {
        let copyText = `${t.name}\t${t.partNumber}\n`;
        allAccessories.forEach((selectedItem) => {
            const model = selectedItem.model || '';
            const partNumber = selectedItem.partNumber || '-';
            copyText = copyText.concat(`${model}\t${partNumber}\n`);
        });
        copyToClipboard(copyText);
    };

    return (
        <Box justifyContent="center">
            <Border color="blackOpacity">
                <Box padding="base" borderRadius="rounded" color="whiteOpacity" width="1000px">
                    <Stack vertical>
                        <Text style="heading">{t.billOfMaterial}</Text>
                        <div className={textCopyStyle}>
                            <SelectableList
                                noBackground
                                noHeaderBackground
                                notScrollable
                                verticallyCenterContents
                                header={[
                                    <Text color="grey6" whiteSpace="nowrap" bold>
                                        {t.name}
                                    </Text>,
                                    '',
                                    ...(selectedIds.length > 0
                                        ? [
                                              <Box paddingRight="half" justifyContent="end">
                                                  <Text color="grey6" whiteSpace="nowrap" bold>
                                                      {t.accessories}
                                                  </Text>
                                              </Box>,
                                              <Text color="grey6" whiteSpace="nowrap" bold>
                                                  {t.partNumber}
                                              </Text>,
                                          ]
                                        : ['', '']),
                                    '',
                                ]}
                            >
                                {selectedIds.map((id, index) => (
                                    <AccessoriesListItem
                                        key={id}
                                        itemId={id}
                                        parentQuantity={index === 0 ? 1 : deviceItem?.quantity}
                                        showAccessoryButton={!selectedMountIds.includes(id)}
                                    />
                                ))}
                            </SelectableList>
                        </div>
                        <Box justifyContent="center" paddingY="half">
                            <Stack>
                                <IconButton
                                    disabled={selectedIds.length === 0}
                                    icon="download_doc"
                                    text={t.downloadAsPdf}
                                    onClick={exportToPdf}
                                />
                                <Exclude for="iOS">
                                    <IconButton
                                        disabled={selectedIds.length === 0}
                                        icon="file_download"
                                        text={t.quotationExportToExcel}
                                        onClick={exportToExcel}
                                    />
                                </Exclude>
                                <IconButton
                                    disabled={selectedIds.length === 0}
                                    icon="content_copy"
                                    text={t.clipboardCopyToClipboard}
                                    onClick={onCopyToClipboardClick}
                                />
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Border>
        </Box>
    );
};
