type RecordableKeys<T> = {
    [K in keyof T]: T[K] extends string | number | symbol ? K : never;
}[keyof T];

/** Converts an array to a record with a key you designate.
 * I.E toRecord([{id: 'a', quantity: 3}, {id: 'b', quantity: 5}], 'id')
 * will yield {a: {id: 'a', quantity: 3}, b: {id: 'b', quantity: 5}}
 * @param entities The array that will be converted to a record.
 * @param key The property that will be the key of the record.
 */
export const toRecord = <
    T extends { [P in RecordableKeys<T>]: string | number | symbol },
    K extends RecordableKeys<T>,
>(
    entities: T[],
    key: K,
): Record<T[K], T> =>
    entities.reduce((acc, item) => ((acc[item[key]] = item), acc), {} as Record<T[K], T>);
