import { createCachedSelector } from 're-reselect';
import { Resolution } from 'app/core/persistence';
import { ScheduledRecordingTypes } from '../models';
import { getRecordingType } from './getRecordingType';
import { getProfile } from './getProfiles';
import { getMergedProfile } from '../../item';
import { toCacheKey } from '../../cacheKey';
import { getProfileIdFromProps } from '../../selectors';

export const getDeviceHasInconsistentRecordingQuality = createCachedSelector(
    [getMergedProfile, getProfileIdFromProps, getRecordingType],
    (mergedProfile, _profileId, recordingType): boolean => {
        if (
            recordingType !== ScheduledRecordingTypes.continuous &&
            recordingType !== ScheduledRecordingTypes.triggered
        ) {
            return false;
        }

        if (
            !mergedProfile?.continuousRecording.schedule ||
            !mergedProfile?.triggeredRecording.schedule
        ) {
            return false;
        }

        if (
            mergedProfile.continuousRecording.frameRate > mergedProfile.triggeredRecording.frameRate
        ) {
            return true;
        }

        if (
            new Resolution(mergedProfile.continuousRecording.resolution).getPixels() >
            new Resolution(mergedProfile.triggeredRecording.resolution).getPixels()
        ) {
            return true;
        }

        return false;
    },
)(toCacheKey);

export const getHasInconsistentRecordingQuality = createCachedSelector(
    [getProfile, getProfileIdFromProps, getRecordingType],
    (profile, _profileId, recordingType): boolean => {
        if (
            recordingType !== ScheduledRecordingTypes.continuous &&
            recordingType !== ScheduledRecordingTypes.triggered
        ) {
            return false;
        }

        if (!profile?.continuousRecording.schedule || !profile?.triggeredRecording.schedule) {
            return false;
        }

        if (profile.continuousRecording.frameRate > profile.triggeredRecording.frameRate) {
            return true;
        }

        if (
            new Resolution(profile.continuousRecording.resolution).getPixels() >
            new Resolution(profile.triggeredRecording.resolution).getPixels()
        ) {
            return true;
        }

        return false;
    },
)(toCacheKey);
