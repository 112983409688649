import type { IPolygon } from 'app/modules/common';
import type * as leaflet from 'leaflet';
import type { Point } from 'axis-webtools-util';

// converts a leaflet polygon to an IPolygon
export const latLngToPolygon = (polygon: leaflet.Polygon, map: leaflet.Map): IPolygon => {
    let points: Point[] = [];
    const polygonLatLngs = polygon.getLatLngs() as leaflet.LatLng[][];
    points = polygonLatLngs[0].map((pointLatLng) => {
        const leafletPoint = map.project(pointLatLng, map.getZoom());
        const cornerPoint: Point = [leafletPoint.x, leafletPoint.y];
        return cornerPoint;
    });
    return {
        regions: [points],
        inverted: false,
    };
};
