import { createCachedSelector } from 're-reselect';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItem } from '../../project';
import { toCacheKey } from '../../cacheKey';
import type { IPiaItem } from 'app/core/pia';

export const getPiaItem = createCachedSelector(
    [getPiaItemsRecord, getCurrentProjectItem],
    (piaItemsRecord, item): IPiaItem | null => {
        if (!item || !item.productId) {
            return null;
        }
        const piaItem = piaItemsRecord[item.productId];
        return piaItem;
    },
)(toCacheKey);
