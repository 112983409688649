import { css, cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';

export const compareStyle = css`
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
`;

export const warningStyle = css`
    position: absolute;
    top: 4px;
    left: 4px;
    width: 30px;
    height: 30px;
    opacity: 50%;
    transition: opacity 150ms ease-in-out;
`;

export const itemCardStyle = css`
    position: relative;
    display: inline-block; //needs to be inline-block to solve https://jira.se.axis.com/browse/WT-7206
    border-radius: 8px;
`;

export const hoverItemCardStyle = css`
    :hover {
        display: inline-block;
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    }

    :hover .full-opacity {
        opacity: 1;
    }

    transition: box-shadow 300ms ease-in-out;
`;

export const productItemStyle = cx(itemCardStyle, hoverItemCardStyle);

export const uspContainerStyle = css`
    display: table;
    background-position: center;
    padding: 0 20px;
    height: 70px;
    @media screen and (max-width: 1024px) {
        padding: 0 8px;
        height: 105px;
    }
`;

export const uspTextStyle = css`
    display: table-cell;
    text-align: center;
`;

export const hoverSectionStyle = (width: number) => css`
    width: ${width}px;
    background-color: ${ColorsEnum.grey1};
    position: absolute;
    top: -6px;
    padding-bottom: 2px;
    border-style: solid;
    border-width: 1px;
    border-radius: 0 0 8px 8px;
    animation: shadowAnimation 300ms ease-in-out forwards;
    @keyframes shadowAnimation {
        0% {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
        }
        100% {
            box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
        }
    }
`;

const hoverSmallScreenStyle = css`
    @media screen and (max-width: 1024px) {
        width: 163px;
    }
`;

export const hoverProductSectionStyle = (width: number) =>
    cx(hoverSectionStyle(width), hoverSmallScreenStyle);

/**
 * Show selected item as 'hovered' so touch devices are supported.
 * Also gives the user the possibility to compare selected product with a hovered one.
 */
export const selectedItemStyle = css`
    position: relative;
    display: inline-block;
    border-radius: 8px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    :hover .full-opacity {
        opacity: 1;
    }
`;

export const selectedItemHoverContainerStyle = css`
    display: block;
    position: absolute;
`;

export const hoverContainerStyle = css`
    display: block;
    position: absolute;
`;

export const discontinuedLabelStyle = css`
    position: absolute;
    padding: 2px 8px;
    left: 50%;
    transform: translate(-50%);
    background-color: ${ColorsEnum.red};
    border: 1px solid ${ColorsEnum.red};
    border-radius: 0 0 8px 8px;
`;
