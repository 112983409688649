/**
 * linspace is an in-built function in Python’s NumPy library. It is used to create an evenly spaced sequence in a specified interval.
 *
 * @param start The starting value of the sequence.
 * @param stop The end value of the sequence unless the endpoint is set to False.
 * @param num The number of samples needed to generate within the interval.
 * @param endpoint  If the endpoint is set to false, then the end value is not included in the sequence.
 */
export function linspace(start: number, stop: number, num: number, endpoint: boolean = true) {
    if (start === stop) {
        return Array(num).fill(start);
    }

    const arr = [];
    const n = num - (endpoint ? 1 : 0);
    const step = n === 0 ? 0 : (stop - start) / n;

    for (let i = 0; i < num; i++) {
        arr.push(start + step * i);
    }

    return arr;
}
