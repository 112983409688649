import * as React from 'react';
import { Box, Button, Heading, Icon, PiaImage, Stack, Table, TableRow, Text } from 'app/components';
import type { IComparableProduct } from '../../selectors/getCompare';
import { getComparableProducts } from '../../selectors/getCompare';
import type { IDesiredCamera } from 'app/modules/common';
import {
    generateToDisplayUnitConverter,
    getCurrentProjectDisplayUnit,
    ProductName,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { distanceUnitShortText, isMainUnit } from 'app/core/persistence';
import { convertDensityToDisplayUnit } from 'app/core/common';
import type { DistanceUnit, IItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaIdWithModel } from '../../models';
import type { IProductLensRecord } from '../../selectors';
import {
    getCompareProductLenses,
    getDesiredCamera,
    getEditItem,
    getPixelDensityInMeters,
    getSelectedParentPiaDevice,
} from '../../selectors';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { css } from '@emotion/css';
import { DeviceWithLens } from '../DeviceWithLens.component';
import { CompareDiscontinued } from './CompareDiscontinued';
import { CompareResolution } from './CompareResolution';

const CompareItemStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    min-height: 180px;

    @media print {
        display: block;
        min-height: 120px;
        padding: 0;
    }
`;

interface ICompareProducts {
    onChangeDevice(productId: PiaId, lensPiaId?: PiaId): void;
}

export const CompareProducts: React.FC<ICompareProducts> = ({ onChangeDevice }) => {
    const productsToCompare = useSelector<IStoreState, IComparableProduct[]>(getComparableProducts);
    const desiredCamera = useSelector<IStoreState, IDesiredCamera>(getDesiredCamera);
    const compareLenses = useSelector<IStoreState, IProductLensRecord>(getCompareProductLenses);
    const pixelDensity = useSelector<IStoreState, number[]>((state) =>
        productsToCompare.map((compareProduct) =>
            getPixelDensityInMeters(
                state,
                compareProduct.piaId,
                compareLenses[compareProduct.piaId]?.id,
            ),
        ),
    );

    const parentDevice = useSelector<IStoreState, IPiaItem | undefined>(getSelectedParentPiaDevice);

    const displayUnit = useSelector<IStoreState, DistanceUnit>(getCurrentProjectDisplayUnit);
    const convertToDisplayUnit = generateToDisplayUnitConverter(displayUnit);

    const [actionService] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );

    // Add or edit mode?
    const editDeviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        getEditItem,
    );

    React.useEffect(() => {
        eventTracking.logUserEvent(
            'Camera Selector',
            'Comparing',
            productsToCompare.map((product) => product.name).join(', '),
        );
    }, [productsToCompare]);

    const getCheckMark = (value?: boolean) =>
        value ? (
            <Box justifyContent="center">
                <Icon icon="check" />
            </Box>
        ) : (
            <Text align="center">-</Text>
        );

    const getIsEqualTextValue = (values: (string | undefined)[]) => {
        for (let index = 0; index < values.length - 1; index++) {
            if (values[index] !== values[index + 1]) {
                return false;
            }
        }
        return true;
    };

    const productsDiffer = (prop: keyof IComparableProduct) => {
        return (
            productsToCompare.some((product) => product[prop]) &&
            !productsToCompare.every((product) => product[prop])
        );
    };

    const getProductCharacteristicRows = () => {
        return (
            <>
                <TableRow
                    cells={[
                        <Box paddingTop="quart">
                            <Heading>{t.productCharacteristics}</Heading>
                        </Box>,
                    ]}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.outdoorReady,
                        ...productsToCompare.map((product) => getCheckMark(product.outdoorReady)),
                    ]}
                    highLighted={productsDiffer('outdoorReady')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.vandalResistant,
                        ...productsToCompare.map((product) =>
                            getCheckMark(product.vandalResistant),
                        ),
                    ]}
                    highLighted={productsDiffer('vandalResistant')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.vandalRating,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.vandalRating ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        productsToCompare.some((product) => product.vandalRating) &&
                        !productsToCompare.every((product) => product.vandalRating)
                    }
                />
                <TableRow
                    cells={[
                        t.ruggedized,
                        ...productsToCompare.map((product) => getCheckMark(product.ruggedized)),
                    ]}
                    highLighted={productsDiffer('ruggedized')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.casingMaterial,
                        ...productsToCompare.map((product) => getCheckMark(product.stainlessSteel)),
                    ]}
                    highLighted={productsDiffer('stainlessSteel')}
                />

                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.pvcFree,
                        ...productsToCompare.map((product) => getCheckMark(product.pvcFree)),
                    ]}
                    highLighted={productsDiffer('pvcFree')}
                />

                <TableRow
                    cells={[
                        t.operationalTemperature,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.operationalTemperature ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.operationalTemperature),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.powerConsumption,
                        ...productsToCompare.map((product) => (
                            <Text align="center" testId={`power_consumption_${product.name}`}>
                                {product.powerConsumption ?? '-'}
                            </Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.powerConsumption),
                        )
                    }
                />
            </>
        );
    };

    const getImagingRows = () => {
        return (
            <>
                <TableRow
                    cells={[
                        <Box paddingTop="base">
                            <Heading>{t.imaging}</Heading>
                        </Box>,
                    ]}
                />

                <TableRow
                    cells={[
                        t.maxVideoResolution,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.maxResolution ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.maxResolution),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.horizontalFieldOfView,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.horizontalFieldOfView ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.horizontalFieldOfView),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.verticalFieldOfView,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.verticalFieldOfView ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.verticalFieldOfView),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.cameraSelectorFieldOfViewCorridor,
                        ...productsToCompare.map((product) => getCheckMark(product.corridor)),
                    ]}
                    highLighted={productsDiffer('corridor')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.lightFinder,
                        ...productsToCompare.map((product) => getCheckMark(product.lightfinder)),
                    ]}
                    highLighted={productsDiffer('lightfinder')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.WDRTechnology,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.WDRTechnology ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.WDRTechnology),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.dayNightFunc,
                        ...productsToCompare.map((product) =>
                            getCheckMark(product.dayAndNightFunc),
                        ),
                    ]}
                    highLighted={productsDiffer('dayAndNightFunc')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.opticalZoom,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.opticalZoom ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.opticalZoom),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.varifocal,
                        ...productsToCompare.map((product) => getCheckMark(product.varifocal)),
                    ]}
                    highLighted={productsDiffer('varifocal')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.lensChangeable,
                        ...productsToCompare.map((product) =>
                            getCheckMark(product.replaceableLens),
                        ),
                    ]}
                    highLighted={productsDiffer('replaceableLens')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.remoteFocus,
                        ...productsToCompare.map((product) => getCheckMark(product.remoteFocus)),
                    ]}
                    highLighted={productsDiffer('remoteFocus')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.remoteZoom,
                        ...productsToCompare.map((product) => getCheckMark(product.remoteZoom)),
                    ]}
                    highLighted={productsDiffer('remoteZoom')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.imageStabilization,
                        ...productsToCompare.map((product) =>
                            getCheckMark(product.imageStabilization),
                        ),
                    ]}
                    highLighted={productsDiffer('imageStabilization')}
                />
            </>
        );
    };

    const getFunctionalityRows = () => {
        return (
            <>
                <TableRow
                    cells={[
                        <Box paddingTop="base">
                            <Heading>{t.functionality}</Heading>
                        </Box>,
                    ]}
                ></TableRow>
                <TableRow
                    cells={[
                        t.alarmInputsOutputs,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.alarmInputsOutputs ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.alarmInputsOutputs),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.audioSupport,
                        ...productsToCompare.map((product) => getCheckMark(product.audioSupport)),
                    ]}
                    highLighted={productsDiffer('audioSupport')}
                />
                <TableRow
                    cells={[
                        t.cameraSelectorUspsTwoWayAudio,
                        ...productsToCompare.map((product) => getCheckMark(product.twoWayAudio)),
                    ]}
                    highLighted={productsDiffer('twoWayAudio')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.builtInMicrophone,
                        ...productsToCompare.map((product) =>
                            getCheckMark(product.builtInMicrophone),
                        ),
                    ]}
                    highLighted={productsDiffer('builtInMicrophone')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.builtInIR,
                        ...productsToCompare.map((product) => getCheckMark(product.optimizedIR)),
                    ]}
                    highLighted={productsDiffer('optimizedIR')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.zipStream,
                        ...productsToCompare.map((product) => getCheckMark(product.zipstream)),
                    ]}
                    highLighted={productsDiffer('zipstream')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.hdmi,
                        ...productsToCompare.map((product) => getCheckMark(product.hdmi)),
                    ]}
                    highLighted={productsDiffer('hdmi')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.slotForMemoryCard,
                        ...productsToCompare.map((product) => getCheckMark(product.localStorage)),
                    ]}
                    highLighted={productsDiffer('localStorage')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.ONVIFProfiles,
                        ...productsToCompare.map((product) => (
                            <Text align="center">{product.onvifProfiles ?? '-'}</Text>
                        )),
                    ]}
                    highLighted={
                        !getIsEqualTextValue(
                            productsToCompare.map((product) => product.onvifProfiles),
                        )
                    }
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.powerOverEthernet,
                        ...productsToCompare.map((product) =>
                            getCheckMark(product.powerOverEthernet),
                        ),
                    ]}
                    highLighted={productsDiffer('powerOverEthernet')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.wireless,
                        ...productsToCompare.map((product) => getCheckMark(product.wireless)),
                    ]}
                    highLighted={productsDiffer('wireless')}
                />
            </>
        );
    };

    const getSecurityRows = () => {
        return (
            <>
                <TableRow
                    cells={[
                        <Box paddingTop="base">
                            <Heading>{t.deviceSecurity}</Heading>
                        </Box>,
                    ]}
                />
                <TableRow
                    cells={[
                        t.edgeVault,
                        ...productsToCompare.map((product) => getCheckMark(product.edgeVault)),
                    ]}
                    highLighted={productsDiffer('edgeVault')}
                />
                <TableRow
                    cells={[
                        t.secureBoot,
                        ...productsToCompare.map((product) => getCheckMark(product.secureBoot)),
                    ]}
                    highLighted={productsDiffer('secureBoot')}
                />
                <TableRow
                    cells={[
                        t.signedOS,
                        ...productsToCompare.map((product) => getCheckMark(product.signedFirmware)),
                    ]}
                    highLighted={productsDiffer('signedFirmware')}
                />
                <TableRow
                    cells={[
                        t.tpm,
                        ...productsToCompare.map((product) => getCheckMark(product.TPM)),
                    ]}
                    highLighted={productsDiffer('TPM')}
                />
            </>
        );
    };

    const getSustainabilityRows = () => {
        return (
            <>
                <TableRow
                    cells={[
                        <Box paddingTop="base">
                            <Heading>{t.sustainability}</Heading>
                        </Box>,
                    ]}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.pvcFree,
                        ...productsToCompare.map((product) => getCheckMark(product.pvcFree)),
                    ]}
                    highLighted={productsDiffer('pvcFree')}
                />
                <TableRow
                    cells={[
                        t.advancedFiltersGROUP.bfrCfrFree,
                        ...productsToCompare.map((product) => getCheckMark(product.bfrCfrFree)),
                    ]}
                    highLighted={productsDiffer('bfrCfrFree')}
                />
                <TableRow
                    cells={[
                        t.recycledPlastics,
                        ...productsToCompare.map((product) => (
                            <Text align="center">
                                {!product.recycledPlasticPercent
                                    ? '-'
                                    : `${product.recycledPlasticPercent}%`}
                            </Text>
                        )),
                    ]}
                    highLighted={productsDiffer('recycledPlasticPercent')}
                />
            </>
        );
    };

    const getPixelDensityHeading = () => {
        return desiredCamera ? (
            <TableRow
                cells={[
                    <Box paddingTop="base">
                        <Heading>{t.cameraSelectorFieldOfViewPixelDensity}</Heading>
                    </Box>,
                ]}
            ></TableRow>
        ) : null;
    };

    const getPixelDensityInfo = () => {
        return desiredCamera ? (
            <Stack spacing="none" vertical>
                <Text>
                    <Text inline>{`${t.cameraSelectorFieldOfViewDistanceToTarget}: `}</Text>
                    <Text inline color="grey6">
                        {getDistanceValueToString(desiredCamera.distanceToTarget)}
                    </Text>
                </Text>
                <Text>
                    <Text inline>{`${t.installationHeight}: `}</Text>
                    <Text inline color="grey6">
                        {getDistanceValueToString(desiredCamera.installationHeight)}
                    </Text>
                </Text>
                <Text>
                    <Text inline>{`${t.cameraSelectorFieldOfViewTargetHeight}: `}</Text>
                    <Text inline color="grey6">
                        {getDistanceValueToString(desiredCamera.targetHeight)}
                    </Text>
                </Text>
            </Stack>
        ) : (
            ''
        );
    };

    const parentDeviceJsx =
        parentDevice && isMainUnit(parentDevice) ? (
            <Box paddingX="base">
                <Stack vertical>
                    <Box justifyContent="center" alignItems="center">
                        <PiaImage icon="device" piaId={parentDevice.id} imageSize="xxl" />
                    </Box>
                    <Box direction="column">
                        <Heading align="center">
                            {parentDevice.name.split(' ').splice(0, 2).join(' ')}
                        </Heading>
                        <Text align="center">{t.selectedMainUnit}</Text>
                    </Box>
                </Stack>
            </Box>
        ) : null;

    const getPixelDensityString = (pixelDensityValue: number) => {
        return `${convertDensityToDisplayUnit(pixelDensityValue, displayUnit)} ${
            t.abbreviationsGROUP.pixel
        }/${distanceUnitShortText(displayUnit)}`;
    };

    const getDistanceValueToString = (distance: number) => {
        return `${convertToDisplayUnit(distance).toFixed(1)}${distanceUnitShortText(displayUnit)}`;
    };

    const setProductToAdd = (product: IPiaIdWithModel) => {
        actionService.setProductToAdd(product);
    };

    return (
        <Table
            headerExtraBottomPadding
            testId="compare"
            omitLastLine
            headers={[
                <Box>{parentDeviceJsx}</Box>,
                ...productsToCompare.map((product) => {
                    return (
                        <div className={CompareItemStyle}>
                            <DeviceWithLens
                                mainDevicePiaId={product.piaId}
                                lensName={compareLenses[product.piaId]?.name}
                                lensPiaId={compareLenses[product.piaId]?.id}
                            />
                            <Box
                                direction="column"
                                flex="grow"
                                justifyContent="between"
                                paddingTop="base"
                                paddingBottom="half"
                            >
                                <ProductName large name={product.name} />
                                <CompareDiscontinued piaId={product.piaId} piaName={product.name} />
                                <Button
                                    onClick={() =>
                                        editDeviceItem
                                            ? onChangeDevice(
                                                  product.piaId,
                                                  compareLenses[product.piaId]?.id,
                                              )
                                            : setProductToAdd({
                                                  productId: product.piaId,
                                                  model: product.name,
                                              })
                                    }
                                >
                                    {editDeviceItem ? t.change : t.add}
                                </Button>
                            </Box>
                        </div>
                    );
                }),
            ]}
        >
            {getProductCharacteristicRows()}
            {getImagingRows()}
            {getFunctionalityRows()}
            {getSecurityRows()}
            {getSustainabilityRows()}
            {getPixelDensityHeading()}
            <TableRow
                cells={[
                    getPixelDensityInfo(),
                    ...productsToCompare.map((product, index) => (
                        <CompareResolution
                            index={index}
                            piaId={product.piaId}
                            pixelDensity={pixelDensity}
                            getPixelDensityString={getPixelDensityString}
                        />
                    )),
                ]}
                highLighted={
                    !getIsEqualTextValue(
                        productsToCompare.map(
                            (_product, index) =>
                                pixelDensity && getPixelDensityString(pixelDensity[index]),
                        ),
                    )
                }
            />
        </Table>
    );
};

CompareProducts.displayName = 'CompareProducts';
