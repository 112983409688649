export enum BreakpointsEnum {
    small = '768px',
    medium = '1024px',
    large = '1280px',
    xlarge = '1440px',
}

export const fromSmall = `screen and (min-width: ${BreakpointsEnum.small})`;
export const fromMedium = `screen and (min-width: ${BreakpointsEnum.medium})`;
export const fromLarge = `screen and (min-width: ${BreakpointsEnum.large})`;
export const fromXlarge = `screen and (min-width: ${BreakpointsEnum.xlarge})`;

export const forMedium = `screen and (max-width: ${BreakpointsEnum.medium})`;
export const forLarge = `screen and (max-width: ${BreakpointsEnum.large})`;
export const forXlarge = `screen and (max-width: ${BreakpointsEnum.xlarge})`;
