import * as React from 'react';
import { css } from '@emotion/css';
import { t } from 'app/translate';
import { Stack, Box, Text, Clickable } from 'app/components';
import { LightConditionSettingRow } from './LightConditionSettingRow.component';
import type { IRecordingSettingsModel } from 'app/core/persistence';
import { isEqual, merge } from 'lodash-es';
import { ServiceLocator } from 'app/ioc';
import { ProfileOverrideService } from '../../services';

const ExpandablePanelStyle = css`
    max-height: 200px;
    overflow-y: hidden;
    transition: max-height 0.3s;
    display: none;

    &[aria-expanded='true'] {
        display: flex;
    }
`;

export interface IRecordingSceneEditorProps {
    noSchedule: boolean;
    expanded: boolean;
    recordingSettings: IRecordingSettingsModel;
    recordingSettingsOverride?: Partial<IRecordingSettingsModel>;
    onRecordingSettingsChange(settings: Partial<IRecordingSettingsModel>): void;
    onToggleRecordingSettings(): void;
}

interface IIRecordingSceneEditorState {
    mergedRecordingSettings: IRecordingSettingsModel;
    model: Partial<IRecordingSettingsModel>;
}

export class RecordingSceneEditor extends React.Component<
    IRecordingSceneEditorProps,
    IIRecordingSceneEditorState
> {
    private profileOverrideService: ProfileOverrideService;

    constructor(props: IRecordingSceneEditorProps) {
        super(props);
        this.state = {
            mergedRecordingSettings: this.getMergedSettings(),
            model: this.props.recordingSettingsOverride || this.props.recordingSettings,
        };
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public componentDidUpdate(oldProps: IRecordingSceneEditorProps) {
        if (
            !isEqual(this.props.recordingSettings, oldProps.recordingSettings) ||
            !isEqual(this.props.recordingSettingsOverride, oldProps.recordingSettingsOverride)
        ) {
            this.setState({
                mergedRecordingSettings: this.getMergedSettings(),
                model: this.props.recordingSettingsOverride || this.props.recordingSettings,
            });
        }
    }

    public render() {
        if (this.props.noSchedule) {
            return null;
        }

        const { dayMotion, nightMotion, dayTriggerTime, nightTriggerTime } =
            this.state.mergedRecordingSettings;
        return (
            <Stack vertical spacing="half">
                <Box justifyContent="end">
                    <Clickable onClick={this.props.onToggleRecordingSettings}>
                        <Stack spacing="quart">
                            <Text color="yellow6">
                                {t.profilesScheduledRecordingGoodLightMotion}
                            </Text>
                            <Text>{`${this.state.mergedRecordingSettings.dayTriggerTime}%,`}</Text>
                            <Text color="blue6">{t.profilesScheduledRecordingLowLightMotion}</Text>
                            <Text>{`${this.state.mergedRecordingSettings.nightTriggerTime}%`}</Text>
                        </Stack>
                    </Clickable>
                </Box>
                <div aria-expanded={this.props.expanded} className={ExpandablePanelStyle}>
                    <Stack vertical>
                        <LightConditionSettingRow
                            color="yellow"
                            label={t.profilesScheduledRecordingGoodLightConditions}
                            triggerValue={dayTriggerTime}
                            triggerValueOverride={
                                this.props.recordingSettingsOverride &&
                                this.props.recordingSettingsOverride.dayTriggerTime
                            }
                            motionValue={dayMotion}
                            motionValueOverride={
                                this.props.recordingSettingsOverride &&
                                this.props.recordingSettingsOverride.dayMotion
                            }
                            motionOnChange={this.onDayMotionChange}
                            motionOnMouseUp={this.onDayMotionMouseUp}
                            triggerOnChange={this.onDayTriggerChange}
                            triggerOnMouseUp={this.onDayTriggerMouseUp}
                        />
                        <LightConditionSettingRow
                            color="blue"
                            label={t.profilesScheduledRecordingLowLightConditions}
                            triggerValue={nightTriggerTime}
                            triggerValueOverride={
                                this.props.recordingSettingsOverride &&
                                this.props.recordingSettingsOverride.nightTriggerTime
                            }
                            motionValue={nightMotion}
                            motionValueOverride={
                                this.props.recordingSettingsOverride &&
                                this.props.recordingSettingsOverride.nightMotion
                            }
                            motionOnChange={this.onNightMotionChange}
                            motionOnMouseUp={this.onNightMotionMouseUp}
                            triggerOnChange={this.onNightTriggerChange}
                            triggerOnMouseUp={this.onNightTriggerMouseUp}
                        />
                    </Stack>
                </div>
            </Stack>
        );
    }

    private getMergedSettings(): IRecordingSettingsModel {
        return merge({}, this.props.recordingSettings, this.props.recordingSettingsOverride);
    }

    private onDayTriggerChange = (value: number) => {
        const settings = { ...this.state.mergedRecordingSettings, dayTriggerTime: value };
        this.setState({ mergedRecordingSettings: settings });
    };

    private onDayTriggerMouseUp = () => {
        const triggerValue = this.profileOverrideService.getValueToSet<number>(
            this.props.recordingSettings.dayTriggerTime,
            this.state.mergedRecordingSettings.dayTriggerTime,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({
            ...this.state.model,
            dayTriggerTime: triggerValue,
        });
    };

    private onDayMotionChange = (value: number) => {
        const settings = { ...this.state.mergedRecordingSettings, dayMotion: value };
        this.setState({ mergedRecordingSettings: settings });
    };

    private onDayMotionMouseUp = () => {
        const dayMotionvalue = this.profileOverrideService.getValueToSet<number>(
            this.props.recordingSettings.dayMotion,
            this.state.mergedRecordingSettings.dayMotion,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({ ...this.state.model, dayMotion: dayMotionvalue });
    };

    private onNightTriggerChange = (value: number) => {
        const settings = { ...this.state.mergedRecordingSettings, nightTriggerTime: value };
        this.setState({ mergedRecordingSettings: settings });
    };

    private onNightTriggerMouseUp = () => {
        const nightTriggerValue = this.profileOverrideService.getValueToSet<number>(
            this.props.recordingSettings.nightTriggerTime,
            this.state.mergedRecordingSettings.nightTriggerTime,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({
            ...this.state.model,
            nightTriggerTime: nightTriggerValue,
        });
    };

    private onNightMotionChange = (value: number) => {
        const settings = { ...this.state.mergedRecordingSettings, nightMotion: value };
        this.setState({ mergedRecordingSettings: settings });
    };

    private onNightMotionMouseUp = () => {
        const nightMotionValue = this.profileOverrideService.getValueToSet<number>(
            this.props.recordingSettings.nightMotion,
            this.state.mergedRecordingSettings.nightMotion,
            this.props.recordingSettingsOverride,
        );
        this.props.onRecordingSettingsChange({
            ...this.state.model,
            nightMotion: nightMotionValue,
        });
    };
}
