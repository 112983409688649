import * as React from 'react';
import { Box, Icon, Title, DropDown, DropDownMenuButton } from 'app/components';
import type { IApplicationItem } from 'app/modules/common';
import {
    getPiaItem,
    getAnalyticMode,
    hasApdParamsBoth,
    analyticSettings,
} from 'app/modules/common';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import type { AnalyticMode, Id } from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { AXIS_PERIMETER_DEFENDER } from 'app/core/common';
import { eventTracking } from 'app/core/tracking';
import { getPersistedApplications } from '../selectors';

interface IAnalyticModeDropDownProps {
    application: IApplicationItem;
    parentDeviceId?: Id;
}

/**
 * DropDown selection of analytic mode if both AI and Calibration parameters are available for device
 * @param application The application item to render the DropDown
 * @param parentDeviceId The id of the device the application is added to
 */
export const AnalyticModeDropDown: React.FC<IAnalyticModeDropDownProps> = ({
    application,
    parentDeviceId,
}) => {
    const parentDevice = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, parentDeviceId),
    );
    const selectedAnalyticMode = useSelector<IStoreState, AnalyticMode | undefined>((store) =>
        getAnalyticMode(store, parentDeviceId),
    );

    const selectedApplications = useSelector<IStoreState, IApplicationItem[]>((store) =>
        getPersistedApplications(store, parentDeviceId),
    );

    const itemService = useService(ItemService);

    const showAnalyticModeSetting: boolean =
        !!parentDevice &&
        hasApdParamsBoth(parentDevice) &&
        application.acapId === AXIS_PERIMETER_DEFENDER &&
        selectedApplications.some((selectedApp) => selectedApp.acapId === application.acapId);

    const selectAnalyticMode = async (selection: AnalyticMode) => {
        if (parentDeviceId && application?.acapId) {
            eventTracking.logUserEvent(
                'Application Selector',
                'Select analytic mode in acap selector',
                application.name,
            );

            await itemService.updateAnalyticMode(parentDeviceId, selection);
        }
    };

    return (
        <Box direction="column" width={40} paddingTop="quart" paddingRight="quart">
            {showAnalyticModeSetting && (
                <Title title={t.analyticModeDescription}>
                    <Box
                        alignItems="end"
                        justifyContent="end"
                        minWidth={'50%'}
                        testId={`analytic_setting_${application.name}`}
                    >
                        <DropDown
                            openInPortal
                            trigger={<Icon opaque icon="settings" color="grey4" />}
                            contents={analyticSettings.map((setting) => (
                                <DropDownMenuButton
                                    testId={`analytic_mode_${setting}`}
                                    label={
                                        setting === 'ai'
                                            ? t.analyticModeAi
                                            : t.analyticModeCalibration
                                    }
                                    selected={selectedAnalyticMode === setting}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        selectAnalyticMode(setting);
                                    }}
                                />
                            ))}
                        />
                    </Box>
                </Title>
            )}
        </Box>
    );
};

AnalyticModeDropDown.displayName = 'AnalyticModeDropDown';
