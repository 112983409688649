function addPrefix(actionName: string) {
    return `RECORDING_SELECTOR_${actionName}`;
}

export const RecordingSelectorActions = {
    ToggleProductSelector: addPrefix('TOGGLE_PRODUCT_SELECTOR'),
    FilterProductSelector: addPrefix('FILTER_PRODUCT_SELECTOR'),
    ToggleDeviceDetails: addPrefix('SHOW_DEVICE_DETAILS'),
    SetNetworkSettingsDetails: addPrefix('SET_NETWORK_SETTINGS_DETAILS'),
    ShowProductSelector: addPrefix('SHOW_PRODUCT_SELECTOR'),
    SetSmallOverlay: addPrefix('SET_SMALL_OVERLAY'),
    SetValidationRunning: addPrefix('SET_VALIDATION_RUNNING'),
    SetValidationResult: addPrefix('SET_VALIDATION_RESULT'),
    GetGenetecRecommendations: addPrefix('GET_GENETEC_RECOMMENDATIONS'),
    SetGenetecRecommendationError: addPrefix('GET_GENETEC_RECOMMENDATION_ERROR'),
    GetGenetecProducts: addPrefix('GET_GENETEC_PRODUCTS'),
    SetViewingAxisAcsType: addPrefix('SET_VIEWING_AXIS_ACS_TYPE'),
    SetAxisCenter: addPrefix('SET_AXIS_CENTER'),
    ResetToInitialState: addPrefix('RESET_TO_INITIAL_STATE'),
    SetGenetecProjectId: addPrefix('SET_GENETEC_PROJECT_ID'),
    SetSearchFilter: addPrefix('SET_SEARCH_FILTER'),
};
