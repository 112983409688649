import type { IPiaApplication, IPiaRelationReference, PiaId } from 'app/core/pia';
import { PiaItemState, PiaRelationTypes } from 'app/core/pia';
import type { IApplicationItem } from 'app/modules/common';

const isIncluded = (piaRelations: IPiaRelationReference[], relatedPiaId: number): boolean => {
    const includes = piaRelations.reduce((piaIds, current) => {
        if (current.relationType === PiaRelationTypes.Includes) {
            return [...piaIds, current.id];
        }
        return piaIds;
    }, [] as PiaId[]);

    return includes.indexOf(relatedPiaId) >= 0;
};

export const mapIPiaApplicationToIApplicationItem =
    (parentRelations: IPiaRelationReference[]) =>
    (application: IPiaApplication): IApplicationItem => ({
        name: application.properties.officialFullName ?? application.name,
        productId: application.id,
        discontinued: application.state > PiaItemState.EXTERNALLY_ANNOUNCED,
        isIncluded: parentRelations ? isIncluded(parentRelations, application.id) : false,
        versions: application.versions,
        acapId: application.properties.ACAPId,
        isELicense: application.properties.isELicense,
        vendor: application.properties.vendor,
        categories: application.categories,
        category: application.category,
    });
