import * as React from 'react';
import { connect } from 'react-redux';
import type { Id, IPersistence, IProfileEntity } from 'app/core/persistence';
import { ProfileService } from 'app/core/persistence';
import { AudioSettingsComponent, getProfile } from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';

export interface IAudioSettingsOwnProps {
    profileId: Id;
}

interface IAudioSettingsProps extends IAudioSettingsOwnProps {
    profile?: IPersistence<IProfileEntity>;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IAudioSettingsOwnProps,
): IAudioSettingsProps => {
    return {
        profile: getProfile(storeState, ownProps.profileId),
        ...ownProps,
    };
};
class AudioSettingsContainer extends React.Component<IAudioSettingsProps> {
    private profileService: ProfileService;
    constructor(props: IAudioSettingsProps) {
        super(props);
        this.profileService = ServiceLocator.get(ProfileService);
    }

    public render() {
        if (this.props.profile) {
            const recordingAudioEnabled = this.props.profile.audio.recordingEnabled;
            const liveViewAudioEnabled = this.props.profile.audio.liveViewEnabled;

            return (
                <AudioSettingsComponent
                    liveViewAudioEnabled={liveViewAudioEnabled}
                    liveViewAudioOverridden={false}
                    recordingAudioEnabled={recordingAudioEnabled}
                    recordingAudioOverridden={false}
                    onLiveViewAudioChanged={this.onLiveViewAudioChanged}
                    onRecordingAudioChanged={this.onRecordingAudioChanged}
                />
            );
        }
        return null;
    }

    private onLiveViewAudioChanged = (enabled: boolean) => {
        if (this.props.profile) {
            this.props.profile.audio.liveViewEnabled = enabled;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onRecordingAudioChanged = (enabled: boolean) => {
        if (this.props.profile) {
            this.props.profile.audio.recordingEnabled = enabled;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };
}

export const AudioSettings = connect(mapStateToProps)(AudioSettingsContainer);
