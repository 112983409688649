import type { IPiaItem, PiaId } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import type {
    IItemEntity,
    IItemRelationEntity,
    IPersistence,
    Id,
    ItemRelationType,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import { getChildrenForDevice } from '../../devices';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItemRelationsArray, getCurrentProjectItems } from '../../project';
import {
    getCurrentProjectRelationsForItem,
    getCurrentProjectRelationsRecord,
} from '../../relations';
import { getIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';

export interface ISelectedPiaMount extends IPiaItem {
    itemId: Id;
}

export const getPrimaryMount = (
    piaItems: Record<number, IPiaItem>,
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    relations: IItemRelationEntity[],
    deviceId: string | undefined,
): ISelectedPiaMount | undefined => {
    if (!deviceId || !relations) {
        return undefined;
    }

    const primaryMountRelation = relations.find(
        (relation) => relation.relationType === 'primaryMount' && relation.parentId === deviceId,
    );
    const primaryMount = primaryMountRelation?.childId
        ? items[primaryMountRelation.childId]
        : undefined;

    return primaryMount && primaryMount.productId
        ? ({
              ...piaItems[primaryMount.productId],
              itemId: primaryMount._id,
          } as ISelectedPiaMount)
        : undefined;
};

export const getSelectedMounts = (
    piaItems: Record<number, IPiaItem>,
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    relations: IItemRelationEntity[],
    deviceId: Id | undefined,
    relationsRecord: Record<string, IItemRelationEntity[]>,
    relationType: ItemRelationType,
) => {
    if (!deviceId) {
        return [];
    }

    const device = items[deviceId];
    // get Children for device (a child can be an analogCamera, sensorUnit or door)
    const deviceChildren =
        device?._id &&
        getChildrenForDevice(relationsRecord[device._id], items).map((child) => child._id);

    // only get relations to the actual device, not its children (i.e for main units only get
    // selected mounts for main unit and not for it's sensor units since they should be displayed for the sensor unit)
    const includesAnyChild = (children: '' | string[] | undefined, relationPaths: string[]) => {
        if (children) {
            return relationPaths.some((path) => children?.includes(path));
        }
        return false;
    };

    const mountRelations = relations.filter(
        (relation) =>
            relation.relationType === relationType &&
            relation.path.includes(deviceId) &&
            !includesAnyChild(deviceChildren, relation.path),
    );

    const mounts = mountRelations.map((relation) => items[relation.childId]).filter(isDefined);
    return mounts
        .sort((mountA, mountB) => {
            if (mountA && mountB) {
                return mountA.path.length - mountB.path.length;
            }
            return 0;
        })
        .map((mount) =>
            mount?.productId
                ? ({ ...piaItems[mount.productId], itemId: mount._id } as ISelectedPiaMount)
                : undefined,
        )
        .filter(isDefined);
};

/** Gets selected device mounts for a specific id */
export const getSelectedDeviceMountsForItemId = createCachedSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
        getCurrentProjectRelationsRecord,
        getIdFromProps,
    ],
    (piaItems, items, relations, relationsRecord, deviceId) =>
        getSelectedMounts(piaItems, items, relations, deviceId, relationsRecord, 'deviceMount'),
)(toCacheKey);

/** Gets selected primary mount for a specific id */
export const getSelectedPrimaryMountForItemId = createCachedSelector(
    [getPiaItemsRecord, getCurrentProjectItems, getCurrentProjectRelationsForItem, getIdFromProps],
    (piaItems, items, relations, deviceId): ISelectedPiaMount | undefined =>
        getPrimaryMount(piaItems, items, relations, deviceId),
)(toCacheKey);

/** Gets selected primary mount pia id for a specific id */
export const getSelectedPrimaryMountPiaIdForItemId = createCachedSelector(
    [getPiaItemsRecord, getCurrentProjectItems, getCurrentProjectRelationsForItem, getIdFromProps],
    (piaItems, items, relations, deviceId): PiaId | undefined =>
        getPrimaryMount(piaItems, items, relations, deviceId)?.id,
)(toCacheKey);

/** Gets selected environment mount for a specific id */
export const getSelectedEnvironmentMountsForItemId = createCachedSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
        getCurrentProjectRelationsRecord,
        getIdFromProps,
    ],
    (piaItems, items, relations, relationsRecord, deviceId) =>
        getSelectedMounts(
            piaItems,
            items,
            relations,
            deviceId,
            relationsRecord,
            'environmentMount',
        ),
)(toCacheKey);

export const mapSelectedMountItemId = (
    deviceMount: ISelectedPiaMount[],
    primaryMount: ISelectedPiaMount | undefined,
    environmentMount: ISelectedPiaMount[],
): Id[] => {
    return (
        [
            ...deviceMount.map((m) => m.itemId),
            primaryMount?.itemId,
            ...environmentMount.map((m) => m.itemId),
        ] as Id[]
    ).filter(isDefined);
};

/** Gets selected mount ids for a specific id */
export const getSelectedMountIdsForItemId = createCachedSelector(
    [
        getSelectedDeviceMountsForItemId,
        getSelectedPrimaryMountForItemId,
        getSelectedEnvironmentMountsForItemId,
    ],
    (deviceMount, primaryMount, environmentMount): Id[] =>
        mapSelectedMountItemId(deviceMount, primaryMount, environmentMount),
)(toCacheKey);

/** Gets selected device mount PiaIds for a specific id */
export const getSelectedMountPIAIdsForItemId = createCachedSelector(
    [
        getSelectedDeviceMountsForItemId,
        getSelectedPrimaryMountForItemId,
        getSelectedEnvironmentMountsForItemId,
    ],
    (deviceMount, primaryMount, environmentMount): PiaId[] =>
        mapSelectedMountPiaId(deviceMount, primaryMount, environmentMount),
)(toCacheKey);

export const mapSelectedMountPiaId = (
    deviceMount: ISelectedPiaMount[],
    primaryMount: ISelectedPiaMount | undefined,
    environmentMount: ISelectedPiaMount[],
): PiaId[] => {
    return (
        [
            ...deviceMount.map((m) => m.id),
            primaryMount?.id,
            ...environmentMount.map((m) => m.id),
        ] as PiaId[]
    ).filter(isDefined);
};
