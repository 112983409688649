import * as React from 'react';
import type { Colors } from 'app/styles';
import { Border, Box, Clickable, Text } from 'app/components';

interface IEditableDayProps {
    day: string;
    isActive: boolean;
    readOnly: boolean;
    onToggleDay(): void;
}

export const EditableDayComponent: React.FunctionComponent<IEditableDayProps> = ({
    day,
    isActive,
    readOnly,
    onToggleDay,
}) => {
    const borderColor: Colors = isActive ? 'yellow2' : 'grey2';
    const backgroundColor: Colors = isActive ? 'yellow1' : 'grey1';
    const textColor: Colors = isActive ? 'yellowText' : 'grey6';
    return (
        <Clickable readOnly={readOnly} onClick={onToggleDay}>
            <Border color={borderColor} width={4}>
                <Box
                    justifyContent="center"
                    alignItems="center"
                    height="42px"
                    width="42px"
                    borderRadius="circle"
                    color={backgroundColor}
                >
                    <Text inline style="semibold" color={textColor}>
                        {day}
                    </Text>
                </Box>
            </Border>
        </Clickable>
    );
};

EditableDayComponent.displayName = 'EditableDayComponent';
