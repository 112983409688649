import { t } from 'app/translate';
import * as React from 'react';
import { Text, Box, NumberInput, Spacer, Checkbox, Stack, Icon } from 'app/components';
import { useSelector } from 'react-redux';
import { HelpButton, getCurrentProjectUnitSystem } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import type { Id, UnitSystem } from 'app/core/persistence';

import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../../../services';

import type { IPiaSpeaker } from 'app/core/pia';
import {
    getEditDeviceId,
    getProductsForDeviceGroup,
    getSelectedSpeaker,
    getCalculatedSpeakerQuantity,
} from '../../../selectors';
import {
    convertFromDisplayValue,
    getFilterInfoMessage,
    convertToDisplayValue,
} from '../../../utils';
import type { IProductGroup, ISpeakerFilter } from '../../../models';
import { SpeakerDescriptionContainer } from './SpeakerDescriptions.container';
import { SpeakerSolution } from './SpeakerSolution.container';
import { AppConstants } from 'app/AppConstants';
import { speakerCalculationUrl } from 'app/core/common/speakerCalculationUrl';

export const SpeakerRecommendedQuantityContainer: React.FC = () => {
    const speakerFilter = useSelector<IStoreState, ISpeakerFilter>(
        (store) => store.deviceSelector.speakerFilter,
    );
    const { placement, listeningArea, wallLength, basicSolution } = speakerFilter;

    const unitSystem = useSelector<IStoreState, UnitSystem>((store) =>
        getCurrentProjectUnitSystem(store),
    );

    const filteredSpeakerProducts = useSelector<IStoreState, IProductGroup[]>((state) =>
        getProductsForDeviceGroup(state),
    );

    const editDeviceId = useSelector<IStoreState, Id | null>((state) => getEditDeviceId(state));

    const quantity = useSelector<IStoreState, number>((store) =>
        getCalculatedSpeakerQuantity(store, editDeviceId ?? undefined),
    );

    const selectedSpeaker = useSelector<IStoreState, IPiaSpeaker | undefined>((state) =>
        getSelectedSpeaker(state, editDeviceId ?? undefined),
    );

    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const updateSpeakerWallLength = (newWallLength: number) => {
        const valueToUpdate = convertFromDisplayValue(unitSystem, false, newWallLength);
        actionService.updateSpeakerFilter({
            wallLength: valueToUpdate,
        });
    };

    const updateSpeakerListeningArea = (newListeningArea: number) => {
        const valueToUpdate = convertFromDisplayValue(unitSystem, true, newListeningArea);
        actionService.updateSpeakerFilter({
            listeningArea: valueToUpdate,
        });
    };

    const toggleBasicSolution = () => {
        actionService.updateSpeakerFilter({
            basicSolution: !basicSolution,
        });
    };

    const isSpeakerValid = () => {
        let speakerFound = false;
        for (let group = 0; group < filteredSpeakerProducts.length; group++) {
            const filteredSpeakerGroup = filteredSpeakerProducts[group];
            const foundSpeaker = filteredSpeakerGroup?.products?.find(
                (speaker) => speaker.piaItem.id === selectedSpeaker?.id,
            );
            if (foundSpeaker) {
                speakerFound = true;
                break;
            }
        }
        return speakerFound;
    };

    const isCeilingActive = placement === 'ceiling';
    const isWallActive = placement === 'wall';

    const quantityMessage = getFilterInfoMessage(
        selectedSpeaker,
        speakerFilter,
        isSpeakerValid(),
        Math.min(quantity, AppConstants.componentQuantityMax),
    );

    const showMaxQuantityWarning =
        quantity === AppConstants.componentQuantityMax &&
        quantityMessage === t.speakerSelectorInfoMessagesMaxQuantityReached(quantity);

    const showRecommendedQuantity =
        selectedSpeaker &&
        isSpeakerValid() &&
        !!placement &&
        (quantityMessage === '' || showMaxQuantityWarning);

    return (
        <Stack vertical>
            <Text style="semibold" color="grey6">
                {t.speakerSelectorCalculateQuantity}
            </Text>

            <Stack spacing="panel">
                {/* ListeningArea for ceiling placement */}
                <Box direction="column">
                    <NumberInput
                        disabled={!isCeilingActive}
                        decimals={1}
                        step={0.1}
                        changeCriteria={'key'}
                        optional
                        min={1}
                        label={t.speakerSelectorListeningArea}
                        unit={
                            unitSystem === 'metric'
                                ? t.abbreviationsGROUP.squareMeter
                                : t.abbreviationsGROUP.squareFeet
                        }
                        value={convertToDisplayValue(unitSystem, true, listeningArea)}
                        onChange={(value: number) => updateSpeakerListeningArea(value)}
                    />
                </Box>

                {/* Wall length for wall placement */}
                <Box direction="column">
                    <NumberInput
                        disabled={!isWallActive}
                        decimals={1}
                        step={0.1}
                        changeCriteria={'key'}
                        optional
                        min={1}
                        label={t.speakerSelectorWallLength}
                        unit={
                            unitSystem === 'metric'
                                ? t.abbreviationsGROUP.meter
                                : t.abbreviationsGROUP.feet
                        }
                        value={convertToDisplayValue(unitSystem, false, wallLength)}
                        onChange={(value: number) => updateSpeakerWallLength(value)}
                    />
                </Box>
            </Stack>
            <Spacer spacing="half"></Spacer>
            <Stack>
                <Checkbox slider selected={basicSolution} onChange={() => toggleBasicSolution()}>
                    {t.speakerSelectorBasicSolution}
                </Checkbox>
                <HelpButton
                    modalTitle={t.speakerSelectorCoverageTitle}
                    modalInfo={t.speakerSelectorCoverageMessage}
                    manualLink={speakerCalculationUrl}
                    linkText={t.readMore}
                />
            </Stack>
            {/* suggested selected quantity */}
            <Spacer spacing="half"></Spacer>
            {showRecommendedQuantity && selectedSpeaker && <SpeakerSolution />}
            <Box alignItems="center">
                {showMaxQuantityWarning && <Icon icon="warning" color="red" opaque />}
                {quantityMessage && <Text>{quantityMessage}</Text>}
            </Box>

            {/* descriptions */}
            <SpeakerDescriptionContainer />
        </Stack>
    );
};
