import type { IBaseEntity, Id, IPersistence } from 'app/core/persistence';
import type { IAction } from 'app/store';

export function updateEntityInRecord<T extends IBaseEntity>(
    items: Record<Id, IPersistence<T> | undefined>,
    action: IAction<IPersistence<T>>,
) {
    const updatedRecord: Record<Id, IPersistence<T> | undefined> = {};

    Object.entries(items).map(([key, entity]) => {
        if (key !== action.payload._id) {
            updatedRecord[key] = entity;
        }
    });

    updatedRecord[action.payload._id] = { ...action.payload };

    return updatedRecord;
}

export function updateEntityInAllDefinedRecord<T extends IBaseEntity>(
    items: Record<Id, IPersistence<T>>,
    action: IAction<IPersistence<T>>,
) {
    const updatedRecord: Record<Id, IPersistence<T>> = {};

    Object.entries(items).map(([key, entity]) => {
        if (key !== action.payload._id) {
            updatedRecord[key] = entity;
        }
    });

    updatedRecord[action.payload._id] = { ...action.payload };

    return updatedRecord;
}
