export const productFilters = {
    // Products that are missing the vendor property are implicitly Axis products
    isVendorAxis(vendor?: string): boolean {
        return !vendor || vendor.toLowerCase() === 'axis';
    },
    isVendor2N(vendor?: string): boolean {
        return vendor?.toLowerCase() === '2n';
    },
    isVendorGenetec(vendor?: string): boolean {
        return vendor?.toLowerCase() === 'genetec';
    },
};
