import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getCurrentProject, getShouldShow2NSelector } from 'app/modules/common';
import { ContentHeaderBar, Stack, LinkButton, SearchField } from 'app/components';
import { t } from 'app/translate';
import { ServiceLocator } from 'app/ioc';
import { ProjectDevicesActionService } from '../../services';
import type { IPersistence, IProjectEntity } from 'app/core/persistence';
import { getProjectDeviceFilter } from '../../selectors';
import { QuickAddDevices } from 'app/modules/deviceSelector';
interface IProjectDevicesHeaderProps {
    project: IPersistence<IProjectEntity>;
    deviceFilter: string;
    shouldShow2NSelector: boolean;
}

const mapStateToProps = (storeState: IStoreState): IProjectDevicesHeaderProps => {
    return {
        project: getCurrentProject(storeState),
        deviceFilter: getProjectDeviceFilter(storeState),
        shouldShow2NSelector: getShouldShow2NSelector(storeState),
    };
};

class ProjectDevicesHeaderContainer extends React.PureComponent<IProjectDevicesHeaderProps> {
    private actionService: ProjectDevicesActionService;

    constructor(props: IProjectDevicesHeaderProps) {
        super(props);
        this.actionService = ServiceLocator.get(ProjectDevicesActionService);
    }

    public render() {
        return (
            <ContentHeaderBar>
                <Stack>
                    {
                        <LinkButton
                            testId="btn_add_camera"
                            primary
                            disabled={this.props.project.locked}
                            link={`/project/${this.props.project._id}/device-selector/`}
                        >
                            {t.addDevice}
                        </LinkButton>
                    }
                    {!this.props.project.locked && (
                        <QuickAddDevices testId="npt_quick_add_device" />
                    )}
                </Stack>

                <SearchField
                    testId="device_list_search"
                    value={this.props.deviceFilter}
                    onValueChange={this.actionService.deviceFilterChange}
                />
            </ContentHeaderBar>
        );
    }
}

export const ProjectDevicesHeader = connect(mapStateToProps)(ProjectDevicesHeaderContainer);
