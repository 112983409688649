import { take, uniq } from 'lodash-es';
import type { PiaId } from 'app/core/pia';
import { getItemLocalStorageJSON, setItemLocalStorageJSON } from 'app/core/persistence';

/**
 * Function that stores up to 10 piaIds to localstorage, in order to show them as history in quickAdd.
 * @param productId
 */
export const addProductToPreviouslyAdded = (productId: PiaId) => {
    const previouslyAdded: number[] = getItemLocalStorageJSON('RecentlyAddedProducts', []);
    setItemLocalStorageJSON(
        'RecentlyAddedProducts',
        take(uniq([productId, ...previouslyAdded]), 10),
    );
};

export const getPreviouslyAdded = (): number[] =>
    getItemLocalStorageJSON('RecentlyAddedProducts', []);
