import * as React from 'react';
import { ToggleOnClick } from '../../functional';
import type { Icons } from '../../ui';
import { DropDownMenuItem } from './DropDownMenuItem.component';
import type { IAutoTestable } from '../../ui-test';

export interface IDropDownMenuToggleProps extends IAutoTestable {
    /**
     * The icon to display in the first drop down item
     */
    icon: Icons;
    /**
     * The text label of the first drop down item
     */
    label: string;
    /**
     * Disable the item
     */
    disabled?: boolean;
    children: JSX.Element;
}

/**
 * A drop down menu item which when clicked will display its children.
 * Useful for delete operations where the user has to click twice (the second
 * time to confirm the delete)
 */
export class DropDownMenuToggle extends React.Component<IDropDownMenuToggleProps> {
    public render() {
        return (
            <ToggleOnClick
                disabled={this.props.disabled}
                __htmlAttributes={{ 'data-should-not-close-drop-down': true }}
            >
                {(hasBeenClicked: boolean) =>
                    !hasBeenClicked ? (
                        <DropDownMenuItem
                            testId={this.props.testId}
                            disabled={this.props.disabled}
                            icon={this.props.icon}
                        >
                            {this.props.label}
                        </DropDownMenuItem>
                    ) : (
                        this.props.children
                    )
                }
            </ToggleOnClick>
        );
    }
}
