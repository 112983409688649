import { trigonometry } from 'axis-webtools-util';
import { constants } from './constants';
import type { IFOVLimits } from '../piaDevices';
import type { IPiaCameraProperties } from 'app/core/pia';

/*
 * Calculate vertical FOV from horizontal
 */
export function calculateVerticalFov(
    horizontalFov: number,
    maxHorizontalFov?: number,
    minHorizontalFov?: number,
    maxVerticalFov?: number,
    minVerticalFov?: number,
) {
    if (
        maxHorizontalFov === undefined ||
        minHorizontalFov === undefined ||
        maxVerticalFov === undefined ||
        minVerticalFov === undefined
    ) {
        return calculateGenericVerticalFovRad(horizontalFov);
    }

    return interpolateFOVFromLimits(
        horizontalFov,
        maxHorizontalFov,
        minHorizontalFov,
        maxVerticalFov,
        minVerticalFov,
        false,
    );
}

/*
 * Calculate horizontal FOV from vertical
 */
export function calculateHorizontalFov(
    verticalFov: number,
    maxHorizontalFov: number,
    minHorizontalFov: number,
    maxVerticalFov: number,
    minVerticalFov: number,
) {
    return interpolateFOVFromLimits(
        verticalFov,
        maxHorizontalFov,
        minHorizontalFov,
        maxVerticalFov,
        minVerticalFov,
        true,
    );
}

/**
 * Calculate vertical field of view for the following cases:
 * Generic camera
 * Specific camera
 * Specific camera with lens
 */
export const getVerticalFovOfCamera = (
    horizontalFov: number,
    cameraProperties?: IPiaCameraProperties,
    lensFOVLimits?: IFOVLimits,
    aspectRatio?: number,
) => {
    if (lensFOVLimits) {
        return calculateVerticalFov(
            horizontalFov,
            lensFOVLimits.horizontal.max,
            lensFOVLimits.horizontal.min,
            lensFOVLimits.vertical.max,
            lensFOVLimits.vertical.min,
        );
    }
    if (cameraProperties) {
        const { minLensCalcFOV, maxLensCalcFOV } = cameraProperties;

        const { maxHorizontalFOV, minHorizontalFOV, maxVerticalFOV, minVerticalFOV } =
            cameraProperties;

        return calculateVerticalFov(
            horizontalFov,
            maxLensCalcFOV || maxHorizontalFOV,
            minLensCalcFOV || minHorizontalFOV,
            maxVerticalFOV,
            minVerticalFOV,
        );
    }

    return trigonometry.toDegrees(
        calculateGenericVerticalFovRad(trigonometry.toRadians(horizontalFov), aspectRatio),
    );
};

export function calculateGenericVerticalFovRad(horizontalFov: number, aspectRatio?: number) {
    return 2 * Math.atan(Math.tan(horizontalFov / 2) / (aspectRatio ?? constants.ASPECT_RATIO));
}

/*
 * Interpolate the vertical FOV from horizontal FOV and the FOV ranges
 * Or if corridor format: Interpolate the horizontal FOV from vertical FOV
 * and the FOV ranges
 *
 */
function interpolateFOVFromLimits(
    fov: number,
    maxHorizontalFov: number,
    minHorizontalFov: number,
    maxVerticalFov: number,
    minVerticalFov: number,
    corridorFormat: boolean,
) {
    // If the camera has a fixed focal length, this is the minVerticalFov, else the
    // value should be interpolated
    const horizontalRange: { min: number; max: number } = corridorFormat
        ? { min: minVerticalFov, max: maxVerticalFov }
        : { min: minHorizontalFov, max: maxHorizontalFov };
    const verticalRange: { min: number; max: number } = corridorFormat
        ? { min: minHorizontalFov, max: maxHorizontalFov }
        : { min: minVerticalFov, max: maxVerticalFov };

    // interpolate
    const interpolatedFov =
        horizontalRange.max === horizontalRange.min
            ? verticalRange.min
            : verticalRange.min +
              ((verticalRange.max - verticalRange.min) * (fov - horizontalRange.min)) /
                  (horizontalRange.max - horizontalRange.min);

    return interpolatedFov;
}
