import type { Id, IItemNetworkSettings } from 'app/core/persistence';
import { Validator } from 'ip-num';
import type { IIpItem } from '../selectors';
import type { fieldName, IDeviceNetworkErrors } from './DeviceNetworkForm';

/**
 * Help function to determine if an IP address is assigned to more than one address field on an item.
 * @param value Address on the item whose address is being edited
 * @param itemAddresses All IP addresses on item
 * @returns true if IP is already occupied by the same item. Otherwise false.
 */
const hasDuplicateAddressOnSameItem = (value: string, itemAddresses: string[]) => {
    const count = itemAddresses.reduce((sum, cur) => {
        if (cur === value) {
            return sum + 1;
        }
        return sum;
    }, 0);
    return count > 1;
};

/**
 * Checks if all network input fields are valid IP addresses
 * @param networkSettings Network settings on item being edited.
 * @returns Booleans for each individual field. True for non valid strings, false for valid strings.
 */
export const getErrors = (networkSettings: IItemNetworkSettings): IDeviceNetworkErrors => ({
    addresses:
        networkSettings.override && !networkSettings.dhcp
            ? networkSettings.addresses.map(
                  (address) => !Validator.isValidIPv4String(address ?? '')[0],
              )
            : Array(networkSettings.addresses.length).fill(false),
    subnetMask:
        networkSettings.override && !networkSettings.dhcp
            ? !Validator.isValidIPv4String(networkSettings.subnetMask ?? '')[0]
            : false,
    defaultRouter:
        networkSettings.override && !networkSettings.dhcp
            ? !Validator.isValidIPv4String(networkSettings.defaultRouter ?? '')[0]
            : false,
});

/**
 * Checks if an IP is available or already taken.
 * @param key The type of network settings field being edited.
 * @param value Input value in the field being edited.
 * @param ipToIdRecord Record to look up which items are utilizing an IP address, and on which field.
 * @param deviceId Id of the device whose network settings are being edited.
 * @param addresses All IP addresses on item being edited. Should be undefined for subnet masks and default routers.
 * @returns True if IP is occupied. Otherwise false.
 */
export const getIpIsOccupied = (
    key: fieldName,
    value: string,
    ipToIdRecord: Record<string, IIpItem[]>,
    deviceId: Id,
    addresses?: string[],
): false | IIpItem[] => {
    //* For now we skip this validation of subnet masks
    if (key === 'subnetMask') {
        return false;
    }
    if (!ipToIdRecord[value]) {
        return false;
    }
    //* Router address can be assigned to multiple devices but can't be occupied by an IP address
    if (key === 'defaultRouter') {
        const otherDevicesUsingIpAddress = ipToIdRecord[value].filter(
            (address) => address.usingAddressAs === 'address',
        );
        return otherDevicesUsingIpAddress.length > 0 ? otherDevicesUsingIpAddress : false;
    }
    //* If IP is occupied by another address on the same item, return the item being edited.
    if (addresses && hasDuplicateAddressOnSameItem(value, addresses)) {
        return ipToIdRecord[value];
    }

    //* If other devices than the one we're editing occupies the IP address return those items
    const otherItemsWithIp = ipToIdRecord[value].filter(
        (device) => device.id !== deviceId || device.usingAddressAs !== 'address',
    );
    return otherItemsWithIp.length > 0 ? otherItemsWithIp : false;
};
