/* eslint-disable import/no-named-as-default-member */
/* hash 91bfc1f307420ccd016cce62a9129f5b2a531896 */
import i18next from 'i18next';

export const t = {
    abbreviationsGROUP: {
        get celsius(): string {
            return i18next.t('abbreviationsGROUP.celsius');
        },
        get decibel(): string {
            return i18next.t('abbreviationsGROUP.decibel');
        },
        get fahrenheit(): string {
            return i18next.t('abbreviationsGROUP.fahrenheit');
        },
        get feet(): string {
            return i18next.t('abbreviationsGROUP.feet');
        },
        get meter(): string {
            return i18next.t('abbreviationsGROUP.meter');
        },
        get notApplicable(): string {
            return i18next.t('abbreviationsGROUP.notApplicable');
        },
        get pieces(): string {
            return i18next.t('abbreviationsGROUP.pieces');
        },
        get pixel(): string {
            return i18next.t('abbreviationsGROUP.pixel');
        },
        get quantity(): string {
            return i18next.t('abbreviationsGROUP.quantity');
        },
        get squareFeet(): string {
            return i18next.t('abbreviationsGROUP.squareFeet');
        },
        get squareMeter(): string {
            return i18next.t('abbreviationsGROUP.squareMeter');
        },
        get watt(): string {
            return i18next.t('abbreviationsGROUP.watt');
        },
    },
    get about(): string {
        return i18next.t('about');
    },
    get accessCodeForCameraStation(): string {
        return i18next.t('accessCodeForCameraStation');
    },
    get accessControl(): string {
        return i18next.t('accessControl');
    },
    get accessories(): string {
        return i18next.t('accessories');
    },
    accessoryDisplayCategoriesGROUP: {
        get acap(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.acap');
        },
        get amplifiers(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.amplifiers');
        },
        get audioandio(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.audioandio');
        },
        get bridges(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.bridges');
        },
        get cabinets(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.cabinets');
        },
        get connectivity(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.connectivity');
        },
        get extModules(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.extModules');
        },
        get housingsandcabinets(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.housingsandcabinets');
        },
        get illuminators(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.illuminators');
        },
        get installtools(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.installtools');
        },
        get joystickscontr(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.joystickscontr');
        },
        get lenses(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.lenses');
        },
        get microphones(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.microphones');
        },
        get misc(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.misc');
        },
        get mounts(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.mounts');
        },
        get other(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.other');
        },
        get power(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.power');
        },
        get readers(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.readers');
        },
        get servers(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.servers');
        },
        get storage(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.storage');
        },
        get rex(): string {
            return i18next.t('accessoryDisplayCategoriesGROUP.rex');
        },
    },
    accessoryEnvironmentsGROUP: {
        get ceiling(): string {
            return i18next.t('accessoryEnvironmentsGROUP.ceiling');
        },
        get column(): string {
            return i18next.t('accessoryEnvironmentsGROUP.column');
        },
        get dinRail(): string {
            return i18next.t('accessoryEnvironmentsGROUP.dinRail');
        },
        get externalCorner(): string {
            return i18next.t('accessoryEnvironmentsGROUP.externalCorner');
        },
        get halfNPSpipe(): string {
            return i18next.t('accessoryEnvironmentsGROUP.halfNPSpipe');
        },
        get interiorCorner(): string {
            return i18next.t('accessoryEnvironmentsGROUP.interiorCorner');
        },
        get parapet(): string {
            return i18next.t('accessoryEnvironmentsGROUP.parapet');
        },
        get pole(): string {
            return i18next.t('accessoryEnvironmentsGROUP.pole');
        },
        get Rack(): string {
            return i18next.t('accessoryEnvironmentsGROUP.Rack');
        },
        get recessedCeiling(): string {
            return i18next.t('accessoryEnvironmentsGROUP.recessedCeiling');
        },
        get Shelf(): string {
            return i18next.t('accessoryEnvironmentsGROUP.Shelf');
        },
        get Soffit(): string {
            return i18next.t('accessoryEnvironmentsGROUP.Soffit');
        },
        get threeQuartersNPSpipe(): string {
            return i18next.t('accessoryEnvironmentsGROUP.threeQuartersNPSpipe');
        },
        get vehicleExterior(): string {
            return i18next.t('accessoryEnvironmentsGROUP.vehicleExterior');
        },
        get vehicleInterior(): string {
            return i18next.t('accessoryEnvironmentsGROUP.vehicleInterior');
        },
        get wall(): string {
            return i18next.t('accessoryEnvironmentsGROUP.wall');
        },
        get wearable(): string {
            return i18next.t('accessoryEnvironmentsGROUP.wearable');
        },
    },
    get accessorySelector(): string {
        return i18next.t('accessorySelector');
    },
    get accessServers(): string {
        return i18next.t('accessServers');
    },
    get acsMissingFromS30RecorderSolution(): string {
        return i18next.t('acsMissingFromS30RecorderSolution');
    },
    get acsShareMapDownloadErrorMessage(): string {
        return i18next.t('acsShareMapDownloadErrorMessage');
    },
    get acsShareMapDownloadErrorTitle(): string {
        return i18next.t('acsShareMapDownloadErrorTitle');
    },
    get add(): string {
        return i18next.t('add');
    },
    get addAccessories(): string {
        return i18next.t('addAccessories');
    },
    get addAnalyticsDetectionRange(): string {
        return i18next.t('addAnalyticsDetectionRange');
    },
    get addApplication(): string {
        return i18next.t('addApplication');
    },
    get addBlockers(): string {
        return i18next.t('addBlockers');
    },
    get addCameras(): string {
        return i18next.t('addCameras');
    },
    get addDevice(): string {
        return i18next.t('addDevice');
    },
    get addDevices(): string {
        return i18next.t('addDevices');
    },
    get keyboardKeyEnter(): string {
        return i18next.t('keyboardKeyEnter');
    },
    get keyboardKeyEscape(): string {
        return i18next.t('keyboardKeyEscape');
    },
    get keyboardKeyShift(): string {
        return i18next.t('keyboardKeyShift');
    },
    get keyboardKeyFunction(): string {
        return i18next.t('keyboardKeyFunction');
    },
    get keyboardKeyControl(): string {
        return i18next.t('keyboardKeyControl');
    },
    get keyboardKeyAlt(): string {
        return i18next.t('keyboardKeyAlt');
    },
    get keyboardKeyDelete(): string {
        return i18next.t('keyboardKeyDelete');
    },
    get addDevicesToGetRecommendation(): string {
        return i18next.t('addDevicesToGetRecommendation');
    },
    get addDevicesToGetWearablesRequirements(): string {
        return i18next.t('addDevicesToGetWearablesRequirements');
    },
    get addDoor(): string {
        return i18next.t('addDoor');
    },
    get addFileTooLarge(): string {
        return i18next.t('addFileTooLarge');
    },
    get addFileTooLargeHeading(): string {
        return i18next.t('addFileTooLargeHeading');
    },
    get addFloorPlan(): string {
        return i18next.t('addFloorPlan');
    },
    get addFloorPlanToMap(): string {
        return i18next.t('addFloorPlanToMap');
    },
    get addInverse(): string {
        return i18next.t('addInverse');
    },
    get addIORelayExpansion(): string {
        return i18next.t('addIORelayExpansion');
    },
    get addIpConfigurations(): string {
        return i18next.t('addIpConfigurations');
    },
    get additionalAccessoriesRequired(): string {
        return i18next.t('additionalAccessoriesRequired');
    },
    get addLocalProject(): string {
        return i18next.t('addLocalProject');
    },
    get addLocation(): string {
        return i18next.t('addLocation');
    },
    addLocationAs: (name: string | number): string => {
        return i18next.t('addLocationAs', {
            name,
        });
    },
    get addLogo(): string {
        return i18next.t('addLogo');
    },
    get addNew(): string {
        return i18next.t('addNew');
    },
    get addNewFloorPlan(): string {
        return i18next.t('addNewFloorPlan');
    },
    get addNewFreeText(): string {
        return i18next.t('addNewFreeText');
    },
    get addNewMapLocation(): string {
        return i18next.t('addNewMapLocation');
    },
    get addNotAllowed(): string {
        return i18next.t('addNotAllowed');
    },
    get addNotes(): string {
        return i18next.t('addNotes');
    },
    get addOns(): string {
        return i18next.t('addOns');
    },
    get addOtherDevices(): string {
        return i18next.t('addOtherDevices');
    },
    get addPartnerApplication(): string {
        return i18next.t('addPartnerApplication');
    },
    get addPartnerProduct(): string {
        return i18next.t('addPartnerProduct');
    },
    addPiaDevice: (modelName: string | number): string => {
        return i18next.t('addPiaDevice', {
            modelName,
        });
    },
    get addRequiredAccessories(): string {
        return i18next.t('addRequiredAccessories');
    },
    get addSetting(): string {
        return i18next.t('addSetting');
    },
    get addSharedProject(): string {
        return i18next.t('addSharedProject');
    },
    get addToQuantity(): string {
        return i18next.t('addToQuantity');
    },
    advancedFiltersGROUP: {
        get all(): string {
            return i18next.t('advancedFiltersGROUP.all');
        },
        get any(): string {
            return i18next.t('advancedFiltersGROUP.any');
        },
        get audioSupport(): string {
            return i18next.t('advancedFiltersGROUP.audioSupport');
        },
        get builtInIR(): string {
            return i18next.t('advancedFiltersGROUP.builtInIR');
        },
        get builtInMicrophone(): string {
            return i18next.t('advancedFiltersGROUP.builtInMicrophone');
        },
        get camera(): string {
            return i18next.t('advancedFiltersGROUP.camera');
        },
        get casingMaterial(): string {
            return i18next.t('advancedFiltersGROUP.casingMaterial');
        },
        get completeModular(): string {
            return i18next.t('advancedFiltersGROUP.completeModular');
        },
        get dayNightFunc(): string {
            return i18next.t('advancedFiltersGROUP.dayNightFunc');
        },
        get dynamicCapture(): string {
            return i18next.t('advancedFiltersGROUP.dynamicCapture');
        },
        get dynamicContrast(): string {
            return i18next.t('advancedFiltersGROUP.dynamicContrast');
        },
        get explosionProtectedCameras(): string {
            return i18next.t('advancedFiltersGROUP.explosionProtectedCameras');
        },
        get fixedCameras(): string {
            return i18next.t('advancedFiltersGROUP.fixedCameras');
        },
        get fixedDomeCameras(): string {
            return i18next.t('advancedFiltersGROUP.fixedDomeCameras');
        },
        get forensicOrDynamicCapture(): string {
            return i18next.t('advancedFiltersGROUP.forensicOrDynamicCapture');
        },
        get hdmi(): string {
            return i18next.t('advancedFiltersGROUP.hdmi');
        },
        get hideAdvancedFilters(): string {
            return i18next.t('advancedFiltersGROUP.hideAdvancedFilters');
        },
        get imageStabilization(): string {
            return i18next.t('advancedFiltersGROUP.imageStabilization');
        },
        get intelligentVideoCapable(): string {
            return i18next.t('advancedFiltersGROUP.intelligentVideoCapable');
        },
        get lensChangeable(): string {
            return i18next.t('advancedFiltersGROUP.lensChangeable');
        },
        get lightFinder(): string {
            return i18next.t('advancedFiltersGROUP.lightFinder');
        },
        get minResolution(): string {
            return i18next.t('advancedFiltersGROUP.minResolution');
        },
        get notSpecified(): string {
            return i18next.t('advancedFiltersGROUP.notSpecified');
        },
        get ONVIFProfile(): string {
            return i18next.t('advancedFiltersGROUP.ONVIFProfile');
        },
        get ONVIFProfiles(): string {
            return i18next.t('advancedFiltersGROUP.ONVIFProfiles');
        },
        get opticalZoom(): string {
            return i18next.t('advancedFiltersGROUP.opticalZoom');
        },
        get outdoorReady(): string {
            return i18next.t('advancedFiltersGROUP.outdoorReady');
        },
        get panoramic(): string {
            return i18next.t('advancedFiltersGROUP.panoramic');
        },
        get powerOverEthernet(): string {
            return i18next.t('advancedFiltersGROUP.powerOverEthernet');
        },
        get ptzCameras(): string {
            return i18next.t('advancedFiltersGROUP.ptzCameras');
        },
        get pvcFree(): string {
            return i18next.t('advancedFiltersGROUP.pvcFree');
        },
        get bfrCfrFree(): string {
            return i18next.t('advancedFiltersGROUP.bfrCfrFree');
        },
        recycledPlastics: (percent: string | number): string => {
            return i18next.t('advancedFiltersGROUP.recycledPlastics', {
                percent,
            });
        },
        get remoteFocus(): string {
            return i18next.t('advancedFiltersGROUP.remoteFocus');
        },
        get remoteZoom(): string {
            return i18next.t('advancedFiltersGROUP.remoteZoom');
        },
        get showAdvancedFilters(): string {
            return i18next.t('advancedFiltersGROUP.showAdvancedFilters');
        },
        get slotForMemoryCard(): string {
            return i18next.t('advancedFiltersGROUP.slotForMemoryCard');
        },
        get thermalCameras(): string {
            return i18next.t('advancedFiltersGROUP.thermalCameras');
        },
        get title(): string {
            return i18next.t('advancedFiltersGROUP.title');
        },
        get vandalRating(): string {
            return i18next.t('advancedFiltersGROUP.vandalRating');
        },
        get vandalResistant(): string {
            return i18next.t('advancedFiltersGROUP.vandalResistant');
        },
        get varifocal(): string {
            return i18next.t('advancedFiltersGROUP.varifocal');
        },
        get WDRTechnology(): string {
            return i18next.t('advancedFiltersGROUP.WDRTechnology');
        },
        get wireless(): string {
            return i18next.t('advancedFiltersGROUP.wireless');
        },
        get zipStream(): string {
            return i18next.t('advancedFiltersGROUP.zipStream');
        },
    },
    get alarmInputsOutputs(): string {
        return i18next.t('alarmInputsOutputs');
    },
    get alarmIO(): string {
        return i18next.t('alarmIO');
    },
    get all(): string {
        return i18next.t('all');
    },
    get allAccessories(): string {
        return i18next.t('allAccessories');
    },
    get allDevices(): string {
        return i18next.t('allDevices');
    },
    get allDevicesOnFloorPlan(): string {
        return i18next.t('allDevicesOnFloorPlan');
    },
    get allDevicesOnLocation(): string {
        return i18next.t('allDevicesOnLocation');
    },
    get allowlistCategoryAll(): string {
        return i18next.t('allowlistCategoryAll');
    },
    get allowlistCategoryAudio(): string {
        return i18next.t('allowlistCategoryAudio');
    },
    get allowlistCategoryCameras(): string {
        return i18next.t('allowlistCategoryCameras');
    },
    get allowlistCategoryEncodersDecoders(): string {
        return i18next.t('allowlistCategoryEncodersDecoders');
    },
    get allowlistCategoryModulars(): string {
        return i18next.t('allowlistCategoryModulars');
    },
    get allowlistCategoryPacs(): string {
        return i18next.t('allowlistCategoryPacs');
    },
    get allowlistDescription(): string {
        return i18next.t('allowlistDescription');
    },
    get analyticDayDark(): string {
        return i18next.t('analyticDayDark');
    },
    get analyticDayLight(): string {
        return i18next.t('analyticDayLight');
    },
    get analyticModeAi(): string {
        return i18next.t('analyticModeAi');
    },
    get analyticModeCalibration(): string {
        return i18next.t('analyticModeCalibration');
    },
    get analyticModeDescription(): string {
        return i18next.t('analyticModeDescription');
    },
    get analyticModeFog(): string {
        return i18next.t('analyticModeFog');
    },
    get analyticModeLight(): string {
        return i18next.t('analyticModeLight');
    },
    get analyticsComputeCapability(): string {
        return i18next.t('analyticsComputeCapability');
    },
    get analyticWeatherClear(): string {
        return i18next.t('analyticWeatherClear');
    },
    get analyticWeatherFog(): string {
        return i18next.t('analyticWeatherFog');
    },
    get analyticWeatherHeavyFog(): string {
        return i18next.t('analyticWeatherHeavyFog');
    },
    get answeringUnits(): string {
        return i18next.t('answeringUnits');
    },
    get any(): string {
        return i18next.t('any');
    },
    get aoaDeviceInfo(): string {
        return i18next.t('aoaDeviceInfo');
    },
    get aoaForBestResults(): string {
        return i18next.t('aoaForBestResults');
    },
    get applicationDetectionRangeInfo(): string {
        return i18next.t('applicationDetectionRangeInfo');
    },
    get applicationDetectionRangeIRIlluminatorRequired(): string {
        return i18next.t('applicationDetectionRangeIRIlluminatorRequired');
    },
    get applicationDetectionRangeTitle(): string {
        return i18next.t('applicationDetectionRangeTitle');
    },
    get applicationDetectionZoneFusion(): string {
        return i18next.t('applicationDetectionZoneFusion');
    },
    get applicationDetectionZoneFusionHover(): string {
        return i18next.t('applicationDetectionZoneFusionHover');
    },
    get applicationDetectionZoneRadar(): string {
        return i18next.t('applicationDetectionZoneRadar');
    },
    get applicationDetectionZoneRadarHover(): string {
        return i18next.t('applicationDetectionZoneRadarHover');
    },
    get applicationFeedbackAnonymityNote(): string {
        return i18next.t('applicationFeedbackAnonymityNote');
    },
    get applicationFeedbackFeedbackSentHeader(): string {
        return i18next.t('applicationFeedbackFeedbackSentHeader');
    },
    get applicationFeedbackFeedbackSentMessage(): string {
        return i18next.t('applicationFeedbackFeedbackSentMessage');
    },
    get applicationFeedbackSend(): string {
        return i18next.t('applicationFeedbackSend');
    },
    get applicationFeedbackText(): string {
        return i18next.t('applicationFeedbackText');
    },
    get applicationMigrating(): string {
        return i18next.t('applicationMigrating');
    },
    get applicationMigrationError(): string {
        return i18next.t('applicationMigrationError');
    },
    get applicationNone(): string {
        return i18next.t('applicationNone');
    },
    get applicationPortraitWarningDescription(): string {
        return i18next.t('applicationPortraitWarningDescription');
    },
    get applicationPortraitWarningTitle(): string {
        return i18next.t('applicationPortraitWarningTitle');
    },
    get applicationQuotaExceededError(): string {
        return i18next.t('applicationQuotaExceededError');
    },
    get applicationRetrievingUser(): string {
        return i18next.t('applicationRetrievingUser');
    },
    get applicationSelectorELicense(): string {
        return i18next.t('applicationSelectorELicense');
    },
    get applicationSelectorIncluded(): string {
        return i18next.t('applicationSelectorIncluded');
    },
    get applicationSelectorPaperLicense(): string {
        return i18next.t('applicationSelectorPaperLicense');
    },
    get applicationSelectorPartnerHeader(): string {
        return i18next.t('applicationSelectorPartnerHeader');
    },
    get applicationSelectorPartnerText(): string {
        return i18next.t('applicationSelectorPartnerText');
    },
    get applicationSelectorPerformanceHeader(): string {
        return i18next.t('applicationSelectorPerformanceHeader');
    },
    get applicationSelectorPerformanceText(): string {
        return i18next.t('applicationSelectorPerformanceText');
    },
    get applicationSelectorPreferELicense(): string {
        return i18next.t('applicationSelectorPreferELicense');
    },
    applicationsFound: (nbrApplications: string | number): string => {
        return i18next.t('applicationsFound', {
            nbrApplications,
        });
    },
    get availableToAndEProgram(): string {
        return i18next.t('availableToAndEProgram');
    },
    get averageBitrate(): string {
        return i18next.t('averageBitrate');
    },
    get averageDailyUse(): string {
        return i18next.t('averageDailyUse');
    },
    get axisCameraStationCenterFiveYear(): string {
        return i18next.t('axisCameraStationCenterFiveYear');
    },
    get axisCameraStationCenterOneYear(): string {
        return i18next.t('axisCameraStationCenterOneYear');
    },
    get axisRecordingSolutionDescription(): string {
        return i18next.t('axisRecordingSolutionDescription');
    },
    get axisRecordingSolutionHeading(): string {
        return i18next.t('axisRecordingSolutionHeading');
    },
    get axisS30disclaimer(): string {
        return i18next.t('axisS30disclaimer');
    },
    get back(): string {
        return i18next.t('back');
    },
    get backgroundColor(): string {
        return i18next.t('backgroundColor');
    },
    get backupProjects(): string {
        return i18next.t('backupProjects');
    },
    get badgeDiscontinued(): string {
        return i18next.t('badgeDiscontinued');
    },
    get bandwidth(): string {
        return i18next.t('bandwidth');
    },
    get bandwidthAndPowerReportNoDevices(): string {
        return i18next.t('bandwidthAndPowerReportNoDevices');
    },
    get bandwidthCalculatorLightConditionsIlluminated(): string {
        return i18next.t('bandwidthCalculatorLightConditionsIlluminated');
    },
    get bandwidthCalculatorLightConditionsNoIllumination(): string {
        return i18next.t('bandwidthCalculatorLightConditionsNoIllumination');
    },
    get bandwidthCalculatorLightConditionsWellLit(): string {
        return i18next.t('bandwidthCalculatorLightConditionsWellLit');
    },
    get bandwidthConsumed(): string {
        return i18next.t('bandwidthConsumed');
    },
    get bandwidthReportDisclaimerHeading(): string {
        return i18next.t('bandwidthReportDisclaimerHeading');
    },
    get bandwidthReportDisclaimerText(): string {
        return i18next.t('bandwidthReportDisclaimerText');
    },
    get bandwidthReportDisclaimerText1(): string {
        return i18next.t('bandwidthReportDisclaimerText1');
    },
    bandwidthReportExcelFileName: (project: string | number): string => {
        return i18next.t('bandwidthReportExcelFileName', {
            project,
        });
    },
    get bandwidthReportNoBandwidthDevices(): string {
        return i18next.t('bandwidthReportNoBandwidthDevices');
    },
    get bandwidthReportNoPowerDevices(): string {
        return i18next.t('bandwidthReportNoPowerDevices');
    },
    get bandwidthVersionUpdateMessage(): string {
        return i18next.t('bandwidthVersionUpdateMessage');
    },
    get bandwidthVersionUpdateTitle(): string {
        return i18next.t('bandwidthVersionUpdateTitle');
    },
    get bandwidthWhileRecording(): string {
        return i18next.t('bandwidthWhileRecording');
    },
    get bareboneModels(): string {
        return i18next.t('bareboneModels');
    },
    get beforeYouBegin(): string {
        return i18next.t('beforeYouBegin');
    },
    get billOfMaterial(): string {
        return i18next.t('billOfMaterial');
    },
    billOfMaterialsExcelCountryInfo: (country: string | number): string => {
        return i18next.t('billOfMaterialsExcelCountryInfo', {
            country,
        });
    },
    billOfMaterialsExcelFileName: (project: string | number): string => {
        return i18next.t('billOfMaterialsExcelFileName', {
            project,
        });
    },
    get billOfMaterialsExcelSheetTitle(): string {
        return i18next.t('billOfMaterialsExcelSheetTitle');
    },
    get billOfMaterialsModel(): string {
        return i18next.t('billOfMaterialsModel');
    },
    get billOfMaterialsNoDevices(): string {
        return i18next.t('billOfMaterialsNoDevices');
    },
    get billOfMaterialsPartNumber(): string {
        return i18next.t('billOfMaterialsPartNumber');
    },
    get billOfMaterialsQuantity(): string {
        return i18next.t('billOfMaterialsQuantity');
    },
    get blockerDrawInfo(): string {
        return i18next.t('blockerDrawInfo');
    },
    get blockerSelectInfo(): string {
        return i18next.t('blockerSelectInfo');
    },
    cameraCount: (num: string | number): string => {
        return i18next.t('cameraCount', {
            num,
        });
    },
    cameraCountSingular: (num: string | number): string => {
        return i18next.t('cameraCountSingular', {
            num,
        });
    },
    get cameraExtensions(): string {
        return i18next.t('cameraExtensions');
    },
    get cameras(): string {
        return i18next.t('cameras');
    },
    get cameraSelectorAcapSupport(): string {
        return i18next.t('cameraSelectorAcapSupport');
    },
    get cameraSelectorCameraType(): string {
        return i18next.t('cameraSelectorCameraType');
    },
    get cameraSelectorDistanceToTargetTooLargeMessage(): string {
        return i18next.t('cameraSelectorDistanceToTargetTooLargeMessage');
    },
    get cameraSelectorFieldOfViewBacklight(): string {
        return i18next.t('cameraSelectorFieldOfViewBacklight');
    },
    get cameraSelectorFieldOfViewBlindSpot(): string {
        return i18next.t('cameraSelectorFieldOfViewBlindSpot');
    },
    get cameraSelectorFieldOfViewCorridor(): string {
        return i18next.t('cameraSelectorFieldOfViewCorridor');
    },
    get cameraSelectorFieldOfViewDaylight(): string {
        return i18next.t('cameraSelectorFieldOfViewDaylight');
    },
    get cameraSelectorFieldOfViewDistanceToTarget(): string {
        return i18next.t('cameraSelectorFieldOfViewDistanceToTarget');
    },
    get cameraSelectorFieldOfViewHorizontalFieldOfView(): string {
        return i18next.t('cameraSelectorFieldOfViewHorizontalFieldOfView');
    },
    get cameraSelectorFieldOfViewInstallationHeight(): string {
        return i18next.t('cameraSelectorFieldOfViewInstallationHeight');
    },
    get cameraSelectorFieldOfViewLens(): string {
        return i18next.t('cameraSelectorFieldOfViewLens');
    },
    get cameraSelectorFieldOfViewLightConditions(): string {
        return i18next.t('cameraSelectorFieldOfViewLightConditions');
    },
    get cameraSelectorFieldOfViewLowLight(): string {
        return i18next.t('cameraSelectorFieldOfViewLowLight');
    },
    get cameraSelectorFieldOfViewNoLight(): string {
        return i18next.t('cameraSelectorFieldOfViewNoLight');
    },
    get cameraSelectorFieldOfViewNormal(): string {
        return i18next.t('cameraSelectorFieldOfViewNormal');
    },
    get cameraSelectorFieldOfViewOrbit(): string {
        return i18next.t('cameraSelectorFieldOfViewOrbit');
    },
    get cameraSelectorFieldOfViewPanoramic(): string {
        return i18next.t('cameraSelectorFieldOfViewPanoramic');
    },
    get cameraSelectorFieldOfViewPixelDensity(): string {
        return i18next.t('cameraSelectorFieldOfViewPixelDensity');
    },
    get cameraSelectorFieldOfViewPixelOptionsDetect(): string {
        return i18next.t('cameraSelectorFieldOfViewPixelOptionsDetect');
    },
    get cameraSelectorFieldOfViewPixelOptionsIdentify(): string {
        return i18next.t('cameraSelectorFieldOfViewPixelOptionsIdentify');
    },
    get cameraSelectorFieldOfViewPixelOptionsObserve(): string {
        return i18next.t('cameraSelectorFieldOfViewPixelOptionsObserve');
    },
    get cameraSelectorFieldOfViewPixelOptionsRecognize(): string {
        return i18next.t('cameraSelectorFieldOfViewPixelOptionsRecognize');
    },
    get cameraSelectorFieldOfViewSetTo(): string {
        return i18next.t('cameraSelectorFieldOfViewSetTo');
    },
    get cameraSelectorFieldOfViewSide(): string {
        return i18next.t('cameraSelectorFieldOfViewSide');
    },
    get cameraSelectorFieldOfViewTargetHeight(): string {
        return i18next.t('cameraSelectorFieldOfViewTargetHeight');
    },
    get cameraSelectorFieldOfViewTele(): string {
        return i18next.t('cameraSelectorFieldOfViewTele');
    },
    get cameraSelectorFieldOfViewTop(): string {
        return i18next.t('cameraSelectorFieldOfViewTop');
    },
    get cameraSelectorFieldOfViewWide(): string {
        return i18next.t('cameraSelectorFieldOfViewWide');
    },
    get cameraSelectorFieldOfViewWidthAtTarget(): string {
        return i18next.t('cameraSelectorFieldOfViewWidthAtTarget');
    },
    get cameraSelectorFovTooHighForLensViewMessage(): string {
        return i18next.t('cameraSelectorFovTooHighForLensViewMessage');
    },
    cameraSelectorMultiChannelMessage: (channels: string | number): string => {
        return i18next.t('cameraSelectorMultiChannelMessage', {
            channels,
        });
    },
    get cameraSelectorNewCamera(): string {
        return i18next.t('cameraSelectorNewCamera');
    },
    get cameraSelectorNewSensorUnit(): string {
        return i18next.t('cameraSelectorNewSensorUnit');
    },
    get cameraSelectorRequirementsTitle(): string {
        return i18next.t('cameraSelectorRequirementsTitle');
    },
    cameraSelectorSetupRendererError: (errorMessage: string | number): string => {
        return i18next.t('cameraSelectorSetupRendererError', {
            errorMessage,
        });
    },
    get cameraSelectorTopRecommendations(): string {
        return i18next.t('cameraSelectorTopRecommendations');
    },
    cameraSelectorTopRecommendationsFor: (analyticProductName: string | number): string => {
        return i18next.t('cameraSelectorTopRecommendationsFor', {
            analyticProductName,
        });
    },
    get cameraSelectorUspsBuiltInSpeaker(): string {
        return i18next.t('cameraSelectorUspsBuiltInSpeaker');
    },
    get cameraSelectorUspsDcIris(): string {
        return i18next.t('cameraSelectorUspsDcIris');
    },
    get cameraSelectorUspsPIris(): string {
        return i18next.t('cameraSelectorUspsPIris');
    },
    get cameraSelectorUspsTwoWayAudio(): string {
        return i18next.t('cameraSelectorUspsTwoWayAudio');
    },
    cameraTypeGROUP: {
        get dome(): string {
            return i18next.t('cameraTypeGROUP.dome');
        },
        get explosionProtected(): string {
            return i18next.t('cameraTypeGROUP.explosionProtected');
        },
        get fixed(): string {
            return i18next.t('cameraTypeGROUP.fixed');
        },
        get ptz(): string {
            return i18next.t('cameraTypeGROUP.ptz');
        },
        get thermal(): string {
            return i18next.t('cameraTypeGROUP.thermal');
        },
    },
    get cancel(): string {
        return i18next.t('cancel');
    },
    canNotBeUsedWithProductName: (productName: string | number): string => {
        return i18next.t('canNotBeUsedWithProductName', {
            productName,
        });
    },
    get cannotQuickAdd(): string {
        return i18next.t('cannotQuickAdd');
    },
    get cardReader(): string {
        return i18next.t('cardReader');
    },
    get category(): string {
        return i18next.t('category');
    },
    get celsius(): string {
        return i18next.t('celsius');
    },
    get change(): string {
        return i18next.t('change');
    },
    get changed(): string {
        return i18next.t('changed');
    },
    get changeFloorPlan(): string {
        return i18next.t('changeFloorPlan');
    },
    get changeName(): string {
        return i18next.t('changeName');
    },
    get channels(): string {
        return i18next.t('channels');
    },
    get childDeviceNotAddedMessage(): string {
        return i18next.t('childDeviceNotAddedMessage');
    },
    get chooseInstallationCountry(): string {
        return i18next.t('chooseInstallationCountry');
    },
    get chooseInstallationCountryRegion(): string {
        return i18next.t('chooseInstallationCountryRegion');
    },
    get chooseLocation(): string {
        return i18next.t('chooseLocation');
    },
    get chooseRecordingSolution(): string {
        return i18next.t('chooseRecordingSolution');
    },
    get classic(): string {
        return i18next.t('classic');
    },
    get clear(): string {
        return i18next.t('clear');
    },
    get clickAndDrag(): string {
        return i18next.t('clickAndDrag');
    },
    get clickButtonToRefresh(): string {
        return i18next.t('clickButtonToRefresh');
    },
    get clickOrDrag(): string {
        return i18next.t('clickOrDrag');
    },
    get clickToAddMap(): string {
        return i18next.t('clickToAddMap');
    },
    get clickToAddText(): string {
        return i18next.t('clickToAddText');
    },
    get clickToChangeModel(): string {
        return i18next.t('clickToChangeModel');
    },
    get clipboardCopiedToClipboardError(): string {
        return i18next.t('clipboardCopiedToClipboardError');
    },
    get clipboardCopiedToClipboardSuccess(): string {
        return i18next.t('clipboardCopiedToClipboardSuccess');
    },
    get clipboardCopiedToClipboardSuccessVerbose(): string {
        return i18next.t('clipboardCopiedToClipboardSuccessVerbose');
    },
    get clipboardCopyToClipboard(): string {
        return i18next.t('clipboardCopyToClipboard');
    },
    get clipboardTrySelectAndCopyInstead(): string {
        return i18next.t('clipboardTrySelectAndCopyInstead');
    },
    get close(): string {
        return i18next.t('close');
    },
    get closeOtherTabs(): string {
        return i18next.t('closeOtherTabs');
    },
    get closeOtherTabsText(): string {
        return i18next.t('closeOtherTabsText');
    },
    get color(): string {
        return i18next.t('color');
    },
    get companionRecordingSolutionHeading(): string {
        return i18next.t('companionRecordingSolutionHeading');
    },
    get companionRecordingSolutionHeadingEdge(): string {
        return i18next.t('companionRecordingSolutionHeadingEdge');
    },
    get companionSDCardSolutionDescription(): string {
        return i18next.t('companionSDCardSolutionDescription');
    },
    get companionSDCardSolutionDescriptionEdge(): string {
        return i18next.t('companionSDCardSolutionDescriptionEdge');
    },
    get companionSolutionDescription(): string {
        return i18next.t('companionSolutionDescription');
    },
    get companionSolutionDescriptionEdge(): string {
        return i18next.t('companionSolutionDescriptionEdge');
    },
    get compare(): string {
        return i18next.t('compare');
    },
    compareMaxReached: (quantity: string | number): string => {
        return i18next.t('compareMaxReached', {
            quantity,
        });
    },
    get compatibleLenses(): string {
        return i18next.t('compatibleLenses');
    },
    get configuration(): string {
        return i18next.t('configuration');
    },
    get confirmDelete(): string {
        return i18next.t('confirmDelete');
    },
    confirmDiscardAllAnalogCamerasGROUP: {
        get body(): string {
            return i18next.t('confirmDiscardAllAnalogCamerasGROUP.body');
        },
        get confirmButtonText(): string {
            return i18next.t('confirmDiscardAllAnalogCamerasGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('confirmDiscardAllAnalogCamerasGROUP.header');
        },
    },
    confirmDiscardExceedingAnalogCamerasGROUP: {
        get body(): string {
            return i18next.t('confirmDiscardExceedingAnalogCamerasGROUP.body');
        },
        get confirmButtonText(): string {
            return i18next.t('confirmDiscardExceedingAnalogCamerasGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('confirmDiscardExceedingAnalogCamerasGROUP.header');
        },
    },
    confirmLogoutGROUP: {
        get body(): string {
            return i18next.t('confirmLogoutGROUP.body');
        },
        get cancelButtonText(): string {
            return i18next.t('confirmLogoutGROUP.cancelButtonText');
        },
        get confirmButtonText(): string {
            return i18next.t('confirmLogoutGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('confirmLogoutGROUP.header');
        },
    },
    confirmLogoutOfflineGROUP: {
        get body(): string {
            return i18next.t('confirmLogoutOfflineGROUP.body');
        },
        get cancelButtonText(): string {
            return i18next.t('confirmLogoutOfflineGROUP.cancelButtonText');
        },
        get confirmButtonText(): string {
            return i18next.t('confirmLogoutOfflineGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('confirmLogoutOfflineGROUP.header');
        },
    },
    confirmLogoutUnsavedGROUP: {
        get body(): string {
            return i18next.t('confirmLogoutUnsavedGROUP.body');
        },
        get cancelButtonText(): string {
            return i18next.t('confirmLogoutUnsavedGROUP.cancelButtonText');
        },
        get confirmButtonText(): string {
            return i18next.t('confirmLogoutUnsavedGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('confirmLogoutUnsavedGROUP.header');
        },
    },
    get contact(): string {
        return i18next.t('contact');
    },
    get continue(): string {
        return i18next.t('continue');
    },
    get copyFloorPlan(): string {
        return i18next.t('copyFloorPlan');
    },
    get couldNotAddDevice(): string {
        return i18next.t('couldNotAddDevice');
    },
    get couldNotDownloadFile(): string {
        return i18next.t('couldNotDownloadFile');
    },
    get couldNotGetOrganizations(): string {
        return i18next.t('couldNotGetOrganizations');
    },
    get couldNotRepairProject(): string {
        return i18next.t('couldNotRepairProject');
    },
    get coverageArea(): string {
        return i18next.t('coverageArea');
    },
    get coverageAreaNotSupported(): string {
        return i18next.t('coverageAreaNotSupported');
    },
    get create(): string {
        return i18next.t('create');
    },
    get createCopies(): string {
        return i18next.t('createCopies');
    },
    get createYourOwnSolution(): string {
        return i18next.t('createYourOwnSolution');
    },
    get creatingProjectFile(): string {
        return i18next.t('creatingProjectFile');
    },
    get creatingSettingsFile(): string {
        return i18next.t('creatingSettingsFile');
    },
    get currentLocation(): string {
        return i18next.t('currentLocation');
    },
    get currentSelection(): string {
        return i18next.t('currentSelection');
    },
    get custom(): string {
        return i18next.t('custom');
    },
    get customBandwidth(): string {
        return i18next.t('customBandwidth');
    },
    get customItemsAdd(): string {
        return i18next.t('customItemsAdd');
    },
    customItemsCategoriesGROUP: {
        get installationService(): string {
            return i18next.t('customItemsCategoriesGROUP.installationService');
        },
        get miscellaneous(): string {
            return i18next.t('customItemsCategoriesGROUP.miscellaneous');
        },
        get network(): string {
            return i18next.t('customItemsCategoriesGROUP.network');
        },
        get software(): string {
            return i18next.t('customItemsCategoriesGROUP.software');
        },
    },
    get customItemsCategory(): string {
        return i18next.t('customItemsCategory');
    },
    get customItemsName(): string {
        return i18next.t('customItemsName');
    },
    get customItemsNoItems(): string {
        return i18next.t('customItemsNoItems');
    },
    get customItemsOtherVendorProducts(): string {
        return i18next.t('customItemsOtherVendorProducts');
    },
    get customItemsPartNumber(): string {
        return i18next.t('customItemsPartNumber');
    },
    get customItemsQuantity(): string {
        return i18next.t('customItemsQuantity');
    },
    get customizeSolution(): string {
        return i18next.t('customizeSolution');
    },
    dayAbbreviationsGROUP: {
        get friday(): string {
            return i18next.t('dayAbbreviationsGROUP.friday');
        },
        get monday(): string {
            return i18next.t('dayAbbreviationsGROUP.monday');
        },
        get saturday(): string {
            return i18next.t('dayAbbreviationsGROUP.saturday');
        },
        get sunday(): string {
            return i18next.t('dayAbbreviationsGROUP.sunday');
        },
        get thursday(): string {
            return i18next.t('dayAbbreviationsGROUP.thursday');
        },
        get tuesday(): string {
            return i18next.t('dayAbbreviationsGROUP.tuesday');
        },
        get wednesday(): string {
            return i18next.t('dayAbbreviationsGROUP.wednesday');
        },
    },
    get daysLowerCase(): string {
        return i18next.t('daysLowerCase');
    },
    get dcPowerSupply(): string {
        return i18next.t('dcPowerSupply');
    },
    get delete(): string {
        return i18next.t('delete');
    },
    get deleteFloorPlanAuthErrorMessage(): string {
        return i18next.t('deleteFloorPlanAuthErrorMessage');
    },
    get deleteFloorPlanErrorHeader(): string {
        return i18next.t('deleteFloorPlanErrorHeader');
    },
    get deleteFloorPlanUnknownError(): string {
        return i18next.t('deleteFloorPlanUnknownError');
    },
    get deleteFloorPlanUnknownNetworkErrorMessage(): string {
        return i18next.t('deleteFloorPlanUnknownNetworkErrorMessage');
    },
    deleteHeader: (name: string | number): string => {
        return i18next.t('deleteHeader', {
            name,
        });
    },
    get deleteInstallationPointsHeader(): string {
        return i18next.t('deleteInstallationPointsHeader');
    },
    get deleteInstallationPointsText(): string {
        return i18next.t('deleteInstallationPointsText');
    },
    get deleteMap(): string {
        return i18next.t('deleteMap');
    },
    get deleteProjectAuthErrorMessage(): string {
        return i18next.t('deleteProjectAuthErrorMessage');
    },
    get deleteProjectErrorHeader(): string {
        return i18next.t('deleteProjectErrorHeader');
    },
    get deleteProjectUnknownError(): string {
        return i18next.t('deleteProjectUnknownError');
    },
    get deleteProjectUnknownNetworkErrorMessage(): string {
        return i18next.t('deleteProjectUnknownNetworkErrorMessage');
    },
    get descriptionUnavailable(): string {
        return i18next.t('descriptionUnavailable');
    },
    get device(): string {
        return i18next.t('device');
    },
    deviceAddedErrorMessage: (model: string | number): string => {
        return i18next.t('deviceAddedErrorMessage', {
            model,
        });
    },
    get deviceAddedSuccessHeader(): string {
        return i18next.t('deviceAddedSuccessHeader');
    },
    deviceAddedSuccessMessage: (model: string | number): string => {
        return i18next.t('deviceAddedSuccessMessage', {
            model,
        });
    },
    get deviceDetailsApplicationsApplications(): string {
        return i18next.t('deviceDetailsApplicationsApplications');
    },
    get deviceDetailsApplicationsNoApplications(): string {
        return i18next.t('deviceDetailsApplicationsNoApplications');
    },
    get deviceDetailsOverviewHeading(): string {
        return i18next.t('deviceDetailsOverviewHeading');
    },
    get deviceDetailsProfileCreateProfile(): string {
        return i18next.t('deviceDetailsProfileCreateProfile');
    },
    get deviceDetailsProfileCreateProfileAction(): string {
        return i18next.t('deviceDetailsProfileCreateProfileAction');
    },
    get deviceDetailsProfileHeading(): string {
        return i18next.t('deviceDetailsProfileHeading');
    },
    get deviceDetailsProfileProfileExplanation(): string {
        return i18next.t('deviceDetailsProfileProfileExplanation');
    },
    get deviceDetailsProfileProfileName(): string {
        return i18next.t('deviceDetailsProfileProfileName');
    },
    get deviceDetailsProfileProfileNamePlaceholder(): string {
        return i18next.t('deviceDetailsProfileProfileNamePlaceholder');
    },
    get deviceDetailsProfileResetToOriginalProfile(): string {
        return i18next.t('deviceDetailsProfileResetToOriginalProfile');
    },
    get deviceDetailsProfileUsing(): string {
        return i18next.t('deviceDetailsProfileUsing');
    },
    get deviceDetailsProfileWithCustomSettings(): string {
        return i18next.t('deviceDetailsProfileWithCustomSettings');
    },
    get deviceIsHidden(): string {
        return i18next.t('deviceIsHidden');
    },
    get deviceListAddAnalogCamera(): string {
        return i18next.t('deviceListAddAnalogCamera');
    },
    get deviceListAddOther(): string {
        return i18next.t('deviceListAddOther');
    },
    get deviceListAddOtherListMore(): string {
        return i18next.t('deviceListAddOtherListMore');
    },
    get deviceListAddOtherListTwoN(): string {
        return i18next.t('deviceListAddOtherListTwoN');
    },
    get deviceListAddSensorUnit(): string {
        return i18next.t('deviceListAddSensorUnit');
    },
    get deviceListAnalogCameraModel(): string {
        return i18next.t('deviceListAnalogCameraModel');
    },
    get deviceListApplications(): string {
        return i18next.t('deviceListApplications');
    },
    get deviceListBandwidth(): string {
        return i18next.t('deviceListBandwidth');
    },
    get deviceListChangeCamera(): string {
        return i18next.t('deviceListChangeCamera');
    },
    get deviceListChangeDevice(): string {
        return i18next.t('deviceListChangeDevice');
    },
    get deviceListChangeEncoder(): string {
        return i18next.t('deviceListChangeEncoder');
    },
    get deviceListChangeMainUnit(): string {
        return i18next.t('deviceListChangeMainUnit');
    },
    get deviceListChangeSensorUnit(): string {
        return i18next.t('deviceListChangeSensorUnit');
    },
    get deviceListChangeSpeaker(): string {
        return i18next.t('deviceListChangeSpeaker');
    },
    get deviceListDiscontinuedAction(): string {
        return i18next.t('deviceListDiscontinuedAction');
    },
    get deviceListDiscontinuedDescription(): string {
        return i18next.t('deviceListDiscontinuedDescription');
    },
    get deviceListDiscontinuedHeader(): string {
        return i18next.t('deviceListDiscontinuedHeader');
    },
    get deviceListMissingInCountryAction(): string {
        return i18next.t('deviceListMissingInCountryAction');
    },
    get deviceListMissingInCountryDescription(): string {
        return i18next.t('deviceListMissingInCountryDescription');
    },
    get deviceListMissingInCountryHeader(): string {
        return i18next.t('deviceListMissingInCountryHeader');
    },
    get deviceListModel(): string {
        return i18next.t('deviceListModel');
    },
    get deviceListName(): string {
        return i18next.t('deviceListName');
    },
    get deviceListNoAddedDevices(): string {
        return i18next.t('deviceListNoAddedDevices');
    },
    get deviceListNoProfileSelected(): string {
        return i18next.t('deviceListNoProfileSelected');
    },
    get deviceListProfile(): string {
        return i18next.t('deviceListProfile');
    },
    get deviceListStorage(): string {
        return i18next.t('deviceListStorage');
    },
    deviceListUsedChannels: (
        usedChannels: string | number,
        availableChannels: string | number,
    ): string => {
        return i18next.t('deviceListUsedChannels', {
            usedChannels,
            availableChannels,
        });
    },
    deviceListUsedModules: (
        usedModules: string | number,
        availableModules: string | number,
    ): string => {
        return i18next.t('deviceListUsedModules', {
            usedModules,
            availableModules,
        });
    },
    get deviceNotOnMap(): string {
        return i18next.t('deviceNotOnMap');
    },
    get deviceOverviewDescription(): string {
        return i18next.t('deviceOverviewDescription');
    },
    get deviceOverviewNotes(): string {
        return i18next.t('deviceOverviewNotes');
    },
    get devices(): string {
        return i18next.t('devices');
    },
    get deviceSecurity(): string {
        return i18next.t('deviceSecurity');
    },
    get deviceSelectorSensorUnitType(): string {
        return i18next.t('deviceSelectorSensorUnitType');
    },
    devicesGROUP: {
        get accessServer(): string {
            return i18next.t('devicesGROUP.accessServer');
        },
        get analogCamera(): string {
            return i18next.t('devicesGROUP.analogCamera');
        },
        get alerter(): string {
            return i18next.t('devicesGROUP.alerter');
        },
        get answeringUnit(): string {
            return i18next.t('devicesGROUP.answeringUnit');
        },
        get bodyWornCamera(): string {
            return i18next.t('devicesGROUP.bodyWornCamera');
        },
        get camera(): string {
            return i18next.t('devicesGROUP.camera');
        },
        get cameraExtension(): string {
            return i18next.t('devicesGROUP.cameraExtension');
        },
        get decoder(): string {
            return i18next.t('devicesGROUP.decoder');
        },
        get dockingStation(): string {
            return i18next.t('devicesGROUP.dockingStation');
        },
        get doorcontroller(): string {
            return i18next.t('devicesGROUP.doorcontroller');
        },
        get doorstation(): string {
            return i18next.t('devicesGROUP.doorstation');
        },
        get encoder(): string {
            return i18next.t('devicesGROUP.encoder');
        },
        get iorelay(): string {
            return i18next.t('devicesGROUP.iorelay');
        },
        get mainUnit(): string {
            return i18next.t('devicesGROUP.mainUnit');
        },
        get networkReader(): string {
            return i18next.t('devicesGROUP.networkReader');
        },
        get pac(): string {
            return i18next.t('devicesGROUP.pac');
        },
        get peopleCounter(): string {
            return i18next.t('devicesGROUP.peopleCounter');
        },
        get radardetector(): string {
            return i18next.t('devicesGROUP.radardetector');
        },
        get sensorUnit(): string {
            return i18next.t('devicesGROUP.sensorUnit');
        },
        get speaker(): string {
            return i18next.t('devicesGROUP.speaker');
        },
        get systemController(): string {
            return i18next.t('devicesGROUP.systemController');
        },
        get door(): string {
            return i18next.t('devicesGROUP.door');
        },
        get relayexpmodules(): string {
            return i18next.t('devicesGROUP.relayexpmodules');
        },
        get connectivitydevice(): string {
            return i18next.t('devicesGROUP.connectivitydevice');
        },
        get pagingconsole(): string {
            return i18next.t('devicesGROUP.pagingconsole');
        },
    },
    get devicesInstalled(): string {
        return i18next.t('devicesInstalled');
    },
    devicesNotOnMap: (numberOfDevices: string | number): string => {
        return i18next.t('devicesNotOnMap', {
            numberOfDevices,
        });
    },
    get devicesScaleWarningMessage(): string {
        return i18next.t('devicesScaleWarningMessage');
    },
    get deviceType(): string {
        return i18next.t('deviceType');
    },
    get diagnosticsTitle(): string {
        return i18next.t('diagnosticsTitle');
    },
    get discontinued(): string {
        return i18next.t('discontinued');
    },
    get dlpu(): string {
        return i18next.t('dlpu');
    },
    get dockingBays(): string {
        return i18next.t('dockingBays');
    },
    get dockingStations(): string {
        return i18next.t('dockingStations');
    },
    get documentation(): string {
        return i18next.t('documentation');
    },
    get documentationDatasheet(): string {
        return i18next.t('documentationDatasheet');
    },
    get documentationDownloadingDatasheet(): string {
        return i18next.t('documentationDownloadingDatasheet');
    },
    get documentationDownloadingDatasheetTitle(): string {
        return i18next.t('documentationDownloadingDatasheetTitle');
    },
    get documentationDownloadSelected(): string {
        return i18next.t('documentationDownloadSelected');
    },
    get documentationInEnglishAsFallback(): string {
        return i18next.t('documentationInEnglishAsFallback');
    },
    get documentationSelectAll(): string {
        return i18next.t('documentationSelectAll');
    },
    get documentationSuccessfullDownload(): string {
        return i18next.t('documentationSuccessfullDownload');
    },
    get documentationSuccessfullDownloadTitle(): string {
        return i18next.t('documentationSuccessfullDownloadTitle');
    },
    get doesNotMeetRequirements(): string {
        return i18next.t('doesNotMeetRequirements');
    },
    get done(): string {
        return i18next.t('done');
    },
    get doNotRefresh(): string {
        return i18next.t('doNotRefresh');
    },
    get doorControllerNeeded(): string {
        return i18next.t('doorControllerNeeded');
    },
    get doorControllers(): string {
        return i18next.t('doorControllers');
    },
    get doors(): string {
        return i18next.t('doors');
    },
    get doorSideA(): string {
        return i18next.t('doorSideA');
    },
    get doorSideB(): string {
        return i18next.t('doorSideB');
    },
    get doorStations(): string {
        return i18next.t('doorStations');
    },
    get download(): string {
        return i18next.t('download');
    },
    get downloadAsPdf(): string {
        return i18next.t('downloadAsPdf');
    },
    get downloadDocumentation(): string {
        return i18next.t('downloadDocumentation');
    },
    get downloadDocumentErrorErrorMessage(): string {
        return i18next.t('downloadDocumentErrorErrorMessage');
    },
    get downloadDocumentErrorTitle(): string {
        return i18next.t('downloadDocumentErrorTitle');
    },
    get downloadProjectFile(): string {
        return i18next.t('downloadProjectFile');
    },
    get downloadProjectToFile(): string {
        return i18next.t('downloadProjectToFile');
    },
    get downloadProjectToFileInfo(): string {
        return i18next.t('downloadProjectToFileInfo');
    },
    get downloadSbom(): string {
        return i18next.t('downloadSbom');
    },
    get downloadSpecification(): string {
        return i18next.t('downloadSpecification');
    },
    get dragToMap(): string {
        return i18next.t('dragToMap');
    },
    get duplicate(): string {
        return i18next.t('duplicate');
    },
    get dynamicFps(): string {
        return i18next.t('dynamicFps');
    },
    get dynamicGOP(): string {
        return i18next.t('dynamicGOP');
    },
    get edgeStorage(): string {
        return i18next.t('edgeStorage');
    },
    get edgeVault(): string {
        return i18next.t('edgeVault');
    },
    get edit(): string {
        return i18next.t('edit');
    },
    get edited(): string {
        return i18next.t('edited');
    },
    get editGeolocation(): string {
        return i18next.t('editGeolocation');
    },
    get editScale(): string {
        return i18next.t('editScale');
    },
    get emptyList(): string {
        return i18next.t('emptyList');
    },
    get emptyProposalReport(): string {
        return i18next.t('emptyProposalReport');
    },
    get encoderNeeded(): string {
        return i18next.t('encoderNeeded');
    },
    get encoders(): string {
        return i18next.t('encoders');
    },
    get encoderSelectorAnalogCamera(): string {
        return i18next.t('encoderSelectorAnalogCamera');
    },
    get encoderSelectorAnalogCameraListHeaderStorage(): string {
        return i18next.t('encoderSelectorAnalogCameraListHeaderStorage');
    },
    get encoderSelectorBlade(): string {
        return i18next.t('encoderSelectorBlade');
    },
    get encoderSelectorGenericAnalogCameraName(): string {
        return i18next.t('encoderSelectorGenericAnalogCameraName');
    },
    get encoderSelectorNewEncoder(): string {
        return i18next.t('encoderSelectorNewEncoder');
    },
    get encoderSelectorTwoWayAudio(): string {
        return i18next.t('encoderSelectorTwoWayAudio');
    },
    get errorBoundaryButton(): string {
        return i18next.t('errorBoundaryButton');
    },
    get errorBoundaryHeader(): string {
        return i18next.t('errorBoundaryHeader');
    },
    get errorBoundarySentHeader(): string {
        return i18next.t('errorBoundarySentHeader');
    },
    get errorBoundarySentMessage(): string {
        return i18next.t('errorBoundarySentMessage');
    },
    get errorBoundaryShare(): string {
        return i18next.t('errorBoundaryShare');
    },
    get errorBoundaryText(): string {
        return i18next.t('errorBoundaryText');
    },
    get errorLoadingPartnerApplication(): string {
        return i18next.t('errorLoadingPartnerApplication');
    },
    get errorLoadingPartnerApplicationMessage(): string {
        return i18next.t('errorLoadingPartnerApplicationMessage');
    },
    get errorLoadingPartnerProduct(): string {
        return i18next.t('errorLoadingPartnerProduct');
    },
    get errorQuota(): string {
        return i18next.t('errorQuota');
    },
    get errorReporterMiddlewareConflictHeader(): string {
        return i18next.t('errorReporterMiddlewareConflictHeader');
    },
    get errorReporterMiddlewareConflictMessage(): string {
        return i18next.t('errorReporterMiddlewareConflictMessage');
    },
    get estimatedBandwidth(): string {
        return i18next.t('estimatedBandwidth');
    },
    get estimatedPowerConsumption(): string {
        return i18next.t('estimatedPowerConsumption');
    },
    get estimatedRetentionTime(): string {
        return i18next.t('estimatedRetentionTime');
    },
    get eulaAccept(): string {
        return i18next.t('eulaAccept');
    },
    get eulaWelcome(): string {
        return i18next.t('eulaWelcome');
    },
    get exitFullScreen(): string {
        return i18next.t('exitFullScreen');
    },
    get expandFilterPanel(): string {
        return i18next.t('expandFilterPanel');
    },
    get exportGenericCamera(): string {
        return i18next.t('exportGenericCamera');
    },
    get exportImportantProjects(): string {
        return i18next.t('exportImportantProjects');
    },
    get exportingProjects(): string {
        return i18next.t('exportingProjects');
    },
    get exportProject(): string {
        return i18next.t('exportProject');
    },
    get exportToBidcon(): string {
        return i18next.t('exportToBidcon');
    },
    get extendable(): string {
        return i18next.t('extendable');
    },
    get fahrenheit(): string {
        return i18next.t('fahrenheit');
    },
    get failedToOptimize(): string {
        return i18next.t('failedToOptimize');
    },
    get feedback(): string {
        return i18next.t('feedback');
    },
    get filterNoMatch(): string {
        return i18next.t('filterNoMatch');
    },
    get findPartnerApplications(): string {
        return i18next.t('findPartnerApplications');
    },
    get floorPlanNamePlaceholder(): string {
        return i18next.t('floorPlanNamePlaceholder');
    },
    get floorPlanOpacity(): string {
        return i18next.t('floorPlanOpacity');
    },
    get floorPlans(): string {
        return i18next.t('floorPlans');
    },
    get foregroundColor(): string {
        return i18next.t('foregroundColor');
    },
    get forensicWDR(): string {
        return i18next.t('forensicWDR');
    },
    get fps(): string {
        return i18next.t('fps');
    },
    get fSeries(): string {
        return i18next.t('fSeries');
    },
    get functionality(): string {
        return i18next.t('functionality');
    },
    get generateCombinedSpecification(): string {
        return i18next.t('generateCombinedSpecification');
    },
    get generateCombinedSpecificationSignIn(): string {
        return i18next.t('generateCombinedSpecificationSignIn');
    },
    get genericCamera(): string {
        return i18next.t('genericCamera');
    },
    get genericCameraInReports(): string {
        return i18next.t('genericCameraInReports');
    },
    get genericDoorController(): string {
        return i18next.t('genericDoorController');
    },
    get genericEncoder(): string {
        return i18next.t('genericEncoder');
    },
    get genericMainUnit(): string {
        return i18next.t('genericMainUnit');
    },
    get genericSensorUnit(): string {
        return i18next.t('genericSensorUnit');
    },
    get genericSpeaker(): string {
        return i18next.t('genericSpeaker');
    },
    genetecGROUP: {
        get addRecommendationsToAxis(): string {
            return i18next.t('genetecGROUP.addRecommendationsToAxis');
        },
        get andChoose(): string {
            return i18next.t('genetecGROUP.andChoose');
        },
        get communicationError(): string {
            return i18next.t('genetecGROUP.communicationError');
        },
        get communicationErrorMessage(): string {
            return i18next.t('genetecGROUP.communicationErrorMessage');
        },
        get configuredOption(): string {
            return i18next.t('genetecGROUP.configuredOption');
        },
        get configureByUseCalculator(): string {
            return i18next.t('genetecGROUP.configureByUseCalculator');
        },
        get configureRecommendations(): string {
            return i18next.t('genetecGROUP.configureRecommendations');
        },
        get confirmHeader(): string {
            return i18next.t('genetecGROUP.confirmHeader');
        },
        get confirmMessageLink(): string {
            return i18next.t('genetecGROUP.confirmMessageLink');
        },
        get disclaimer(): string {
            return i18next.t('genetecGROUP.disclaimer');
        },
        get exportProjectFile(): string {
            return i18next.t('genetecGROUP.exportProjectFile');
        },
        get noSolutions(): string {
            return i18next.t('genetecGROUP.noSolutions');
        },
        get onlineInfoMessage(): string {
            return i18next.t('genetecGROUP.onlineInfoMessage');
        },
        get selectExportedFile(): string {
            return i18next.t('genetecGROUP.selectExportedFile');
        },
        get selectRecommendedProducts(): string {
            return i18next.t('genetecGROUP.selectRecommendedProducts');
        },
        get showMeHow(): string {
            return i18next.t('genetecGROUP.showMeHow');
        },
        solutionName: (index: string | number): string => {
            return i18next.t('genetecGROUP.solutionName', {
                index,
            });
        },
        get solutionTypeAllInOneLocal(): string {
            return i18next.t('genetecGROUP.solutionTypeAllInOneLocal');
        },
        get solutionTypeAllInOneArchiving(): string {
            return i18next.t('genetecGROUP.solutionTypeAllInOneArchiving');
        },
        get solutionTypeEntryRackmount(): string {
            return i18next.t('genetecGROUP.solutionTypeEntryRackmount');
        },
        get solutionTypeEnterprise(): string {
            return i18next.t('genetecGROUP.solutionTypeEnterprise');
        },
        get solutionTypeCentralized(): string {
            return i18next.t('genetecGROUP.solutionTypeCentralized');
        },
        get termsOfUse(): string {
            return i18next.t('genetecGROUP.termsOfUse');
        },
        get useGenetec(): string {
            return i18next.t('genetecGROUP.useGenetec');
        },
        get useStreamvaultCalculator(): string {
            return i18next.t('genetecGROUP.useStreamvaultCalculator');
        },
        get visit(): string {
            return i18next.t('genetecGROUP.visit');
        },
        get whatWouldGenetecRecommend(): string {
            return i18next.t('genetecGROUP.whatWouldGenetecRecommend');
        },
    },
    get geolocationModalMessage(): string {
        return i18next.t('geolocationModalMessage');
    },
    get geolocationModalTitle(): string {
        return i18next.t('geolocationModalTitle');
    },
    get getSolutions(): string {
        return i18next.t('getSolutions');
    },
    get getStartedByAddDevices(): string {
        return i18next.t('getStartedByAddDevices');
    },
    get getStartedByLocation(): string {
        return i18next.t('getStartedByLocation');
    },
    get getStartedBySelectingSolution(): string {
        return i18next.t('getStartedBySelectingSolution');
    },
    get getToKnowMaps(): string {
        return i18next.t('getToKnowMaps');
    },
    gopGROUP: {
        get dynamic(): string {
            return i18next.t('gopGROUP.dynamic');
        },
        get fixed(): string {
            return i18next.t('gopGROUP.fixed');
        },
    },
    get gotIt(): string {
        return i18next.t('gotIt');
    },
    get goToGeolocation(): string {
        return i18next.t('goToGeolocation');
    },
    get goToLocation(): string {
        return i18next.t('goToLocation');
    },
    get goToMySystems(): string {
        return i18next.t('goToMySystems');
    },
    get horizontalFieldOfView(): string {
        return i18next.t('horizontalFieldOfView');
    },
    get horizontalShort(): string {
        return i18next.t('horizontalShort');
    },
    get illumination(): string {
        return i18next.t('illumination');
    },
    get imageLoadingError(): string {
        return i18next.t('imageLoadingError');
    },
    get imageRotation(): string {
        return i18next.t('imageRotation');
    },
    get imaging(): string {
        return i18next.t('imaging');
    },
    get importGenericCamera(): string {
        return i18next.t('importGenericCamera');
    },
    get inaccessibleDatabase(): string {
        return i18next.t('inaccessibleDatabase');
    },
    get included(): string {
        return i18next.t('included');
    },
    get increaseAndDuplicate(): string {
        return i18next.t('increaseAndDuplicate');
    },
    get inSolution(): string {
        return i18next.t('inSolution');
    },
    get installation(): string {
        return i18next.t('installation');
    },
    get installationHeight(): string {
        return i18next.t('installationHeight');
    },
    get installationReportAccessories(): string {
        return i18next.t('installationReportAccessories');
    },
    get installationReportApplications(): string {
        return i18next.t('installationReportApplications');
    },
    get installationReportBandwidthStorageHeader(): string {
        return i18next.t('installationReportBandwidthStorageHeader');
    },
    get installationReportBandwidthStorageTotalBandwidth(): string {
        return i18next.t('installationReportBandwidthStorageTotalBandwidth');
    },
    get installationReportBandwidthStorageTotalStorage(): string {
        return i18next.t('installationReportBandwidthStorageTotalStorage');
    },
    installationReportDays: (number: string | number): string => {
        return i18next.t('installationReportDays', {
            number,
        });
    },
    installationReportInstallationTiltAngle: (degrees: string | number): string => {
        return i18next.t('installationReportInstallationTiltAngle', {
            degrees,
        });
    },
    get installationReportInstallationTiltDirectionDown(): string {
        return i18next.t('installationReportInstallationTiltDirectionDown');
    },
    get installationReportInstallationTiltDirectionUp(): string {
        return i18next.t('installationReportInstallationTiltDirectionUp');
    },
    get installationReportInstallationTiltTitle(): string {
        return i18next.t('installationReportInstallationTiltTitle');
    },
    get installationReportMountAccessories(): string {
        return i18next.t('installationReportMountAccessories');
    },
    get installationReportMounts(): string {
        return i18next.t('installationReportMounts');
    },
    get installationReportSchedulesHeader(): string {
        return i18next.t('installationReportSchedulesHeader');
    },
    get installationReportSettingsCompression(): string {
        return i18next.t('installationReportSettingsCompression');
    },
    get installationReportSettingsContinuous(): string {
        return i18next.t('installationReportSettingsContinuous');
    },
    get installationReportSettingsFrameRate(): string {
        return i18next.t('installationReportSettingsFrameRate');
    },
    get installationReportSettingsGopMode(): string {
        return i18next.t('installationReportSettingsGopMode');
    },
    get installationReportSettingsLive(): string {
        return i18next.t('installationReportSettingsLive');
    },
    get installationReportSettingsResolution(): string {
        return i18next.t('installationReportSettingsResolution');
    },
    get installationReportSettingsSchedule(): string {
        return i18next.t('installationReportSettingsSchedule');
    },
    get installationReportSettingsStorageTime(): string {
        return i18next.t('installationReportSettingsStorageTime');
    },
    get installationReportSettingsTitle(): string {
        return i18next.t('installationReportSettingsTitle');
    },
    get installationReportSettingsTriggered(): string {
        return i18next.t('installationReportSettingsTriggered');
    },
    get installationReportSettingsZipStrength(): string {
        return i18next.t('installationReportSettingsZipStrength');
    },
    get installationReportSpeakersEvenlyDistributed(): string {
        return i18next.t('installationReportSpeakersEvenlyDistributed');
    },
    get installationReportSpeakersFaceDown(): string {
        return i18next.t('installationReportSpeakersFaceDown');
    },
    get installationReportSpeakersRainProof(): string {
        return i18next.t('installationReportSpeakersRainProof');
    },
    get installationReportSummaryDetails(): string {
        return i18next.t('installationReportSummaryDetails');
    },
    get installationReportSummaryDevicesToInstall(): string {
        return i18next.t('installationReportSummaryDevicesToInstall');
    },
    get installationReportSummaryModulars(): string {
        return i18next.t('installationReportSummaryModulars');
    },
    get installationReportToggleOneDevice(): string {
        return i18next.t('installationReportToggleOneDevice');
    },
    get installationsWithAxis(): string {
        return i18next.t('installationsWithAxis');
    },
    get invalidScaleValue(): string {
        return i18next.t('invalidScaleValue');
    },
    get inverted(): string {
        return i18next.t('inverted');
    },
    get ipAddress(): string {
        return i18next.t('ipAddress');
    },
    ipAddressAlreadyUsedBy: (deviceName: string | number): string => {
        return i18next.t('ipAddressAlreadyUsedBy', {
            deviceName,
        });
    },
    get ipAddresses(): string {
        return i18next.t('ipAddresses');
    },
    get ipAddressesCreatedMessage(): string {
        return i18next.t('ipAddressesCreatedMessage');
    },
    get ipAddressesCreateMessage(): string {
        return i18next.t('ipAddressesCreateMessage');
    },
    get ipAddressFieldError(): string {
        return i18next.t('ipAddressFieldError');
    },
    get ipAddressRange(): string {
        return i18next.t('ipAddressRange');
    },
    get ipAddressUsedByProjectRouter(): string {
        return i18next.t('ipAddressUsedByProjectRouter');
    },
    get ipAvailableAddresses(): string {
        return i18next.t('ipAvailableAddresses');
    },
    get ipConfiguration(): string {
        return i18next.t('ipConfiguration');
    },
    get ipConfigurationAlertNonMatchingItems(): string {
        return i18next.t('ipConfigurationAlertNonMatchingItems');
    },
    ipConfigurationAlertNumberOfDeviceInRange: (number: string | number): string => {
        return i18next.t('ipConfigurationAlertNumberOfDeviceInRange', {
            number,
        });
    },
    get ipConfigurationOverride(): string {
        return i18next.t('ipConfigurationOverride');
    },
    get ipConfigurationOverrideDisabledMsg(): string {
        return i18next.t('ipConfigurationOverrideDisabledMsg');
    },
    get ipDefaultRouter(): string {
        return i18next.t('ipDefaultRouter');
    },
    ipNetworkWillSupportUpToNumberDevices: (number: string | number): string => {
        return i18next.t('ipNetworkWillSupportUpToNumberDevices', {
            number,
        });
    },
    get ipOutsideRange(): string {
        return i18next.t('ipOutsideRange');
    },
    get ipRangeEnd(): string {
        return i18next.t('ipRangeEnd');
    },
    ipRangeIpStartToIpEnd: (ipStart: string | number, ipEnd: string | number): string => {
        return i18next.t('ipRangeIpStartToIpEnd', {
            ipStart,
            ipEnd,
        });
    },
    get ipRangeStart(): string {
        return i18next.t('ipRangeStart');
    },
    get ipRating(): string {
        return i18next.t('ipRating');
    },
    get ipSubnetMask(): string {
        return i18next.t('ipSubnetMask');
    },
    get keyboardShortcuts(): string {
        return i18next.t('keyboardShortcuts');
    },
    get labelPreparedFor(): string {
        return i18next.t('labelPreparedFor');
    },
    get labels(): string {
        return i18next.t('labels');
    },
    get landscape(): string {
        return i18next.t('landscape');
    },
    get lastSync(): string {
        return i18next.t('lastSync');
    },
    get leafletActionsCancelText(): string {
        return i18next.t('leafletActionsCancelText');
    },
    get leafletActionsClearAllText(): string {
        return i18next.t('leafletActionsClearAllText');
    },
    get leafletActionsSnap(): string {
        return i18next.t('leafletActionsSnap');
    },
    get leafletActionsTitle(): string {
        return i18next.t('leafletActionsTitle');
    },
    get leafletButtonsPolyline(): string {
        return i18next.t('leafletButtonsPolyline');
    },
    get leafletCenterMap(): string {
        return i18next.t('leafletCenterMap');
    },
    get leafletDelete(): string {
        return i18next.t('leafletDelete');
    },
    get leafletEditLine(): string {
        return i18next.t('leafletEditLine');
    },
    get leafletEditTooltipText(): string {
        return i18next.t('leafletEditTooltipText');
    },
    get leafletFinishText(): string {
        return i18next.t('leafletFinishText');
    },
    get leafletFinishTitle(): string {
        return i18next.t('leafletFinishTitle');
    },
    get leafletPolylineStart(): string {
        return i18next.t('leafletPolylineStart');
    },
    get leafletRemoveTooltipText(): string {
        return i18next.t('leafletRemoveTooltipText');
    },
    get leafletToggleDori(): string {
        return i18next.t('leafletToggleDori');
    },
    get leafletToggleMeasureTool(): string {
        return i18next.t('leafletToggleMeasureTool');
    },
    get leafletToggleSatelliteMode(): string {
        return i18next.t('leafletToggleSatelliteMode');
    },
    get leafletZoomInTitle(): string {
        return i18next.t('leafletZoomInTitle');
    },
    get leafletZoomOutTitle(): string {
        return i18next.t('leafletZoomOutTitle');
    },
    get learnAboutFog(): string {
        return i18next.t('learnAboutFog');
    },
    get learnMore(): string {
        return i18next.t('learnMore');
    },
    get learnMoreOnYouTube(): string {
        return i18next.t('learnMoreOnYouTube');
    },
    get less(): string {
        return i18next.t('less');
    },
    get letsGo(): string {
        return i18next.t('letsGo');
    },
    get licenses(): string {
        return i18next.t('licenses');
    },
    get licensesHeader(): string {
        return i18next.t('licensesHeader');
    },
    get licensesNoticeText(): string {
        return i18next.t('licensesNoticeText');
    },
    get liveViewBandwidth(): string {
        return i18next.t('liveViewBandwidth');
    },
    get loading(): string {
        return i18next.t('loading');
    },
    get localProjects(): string {
        return i18next.t('localProjects');
    },
    get localProjectsNoSync(): string {
        return i18next.t('localProjectsNoSync');
    },
    get location(): string {
        return i18next.t('location');
    },
    get lock(): string {
        return i18next.t('lock');
    },
    get lockProject(): string {
        return i18next.t('lockProject');
    },
    get locks(): string {
        return i18next.t('locks');
    },
    get logo(): string {
        return i18next.t('logo');
    },
    get logoToasterLogoDeleteFailureBody(): string {
        return i18next.t('logoToasterLogoDeleteFailureBody');
    },
    get logoToasterLogoDeleteFailureHeader(): string {
        return i18next.t('logoToasterLogoDeleteFailureHeader');
    },
    get logoToasterLogoUploadFailureHeader(): string {
        return i18next.t('logoToasterLogoUploadFailureHeader');
    },
    get mainUnitNeeded(): string {
        return i18next.t('mainUnitNeeded');
    },
    get mainUnitSelectorChannels(): string {
        return i18next.t('mainUnitSelectorChannels');
    },
    get mainUnitSelectorNewMainUnit(): string {
        return i18next.t('mainUnitSelectorNewMainUnit');
    },
    get mainUnitSelectorTwoWayAudio(): string {
        return i18next.t('mainUnitSelectorTwoWayAudio');
    },
    get makeUnique(): string {
        return i18next.t('makeUnique');
    },
    get map(): string {
        return i18next.t('map');
    },
    get mapItemDuplicationErrorMsg(): string {
        return i18next.t('mapItemDuplicationErrorMsg');
    },
    get maps(): string {
        return i18next.t('maps');
    },
    get mapSettings(): string {
        return i18next.t('mapSettings');
    },
    get mapSignIn(): string {
        return i18next.t('mapSignIn');
    },
    get mapWelcome(): string {
        return i18next.t('mapWelcome');
    },
    matchingProducts: (matching: string | number, total: string | number): string => {
        return i18next.t('matchingProducts', {
            matching,
            total,
        });
    },
    get maxPoe(): string {
        return i18next.t('maxPoe');
    },
    get maxPoeClass(): string {
        return i18next.t('maxPoeClass');
    },
    get maxPower(): string {
        return i18next.t('maxPower');
    },
    get maxVideoResolution(): string {
        return i18next.t('maxVideoResolution');
    },
    members: (nbrMembers: string | number): string => {
        return i18next.t('members', {
            nbrMembers,
        });
    },
    get midspanAdded(): string {
        return i18next.t('midspanAdded');
    },
    get midspanIncluded(): string {
        return i18next.t('midspanIncluded');
    },
    get minFps(): string {
        return i18next.t('minFps');
    },
    get missingPorts(): string {
        return i18next.t('missingPorts');
    },
    get missingScenario(): string {
        return i18next.t('missingScenario');
    },
    get missingSchedule(): string {
        return i18next.t('missingSchedule');
    },
    get mjpegSettingInformation(): string {
        return i18next.t('mjpegSettingInformation');
    },
    get mlpu(): string {
        return i18next.t('mlpu');
    },
    get model(): string {
        return i18next.t('model');
    },
    get modelName(): string {
        return i18next.t('modelName');
    },
    get monthsGROUP(): string[] {
        return i18next.t('monthsGROUP', { returnObjects: true });
    },
    get moreFilters(): string {
        return i18next.t('moreFilters');
    },
    moreFrom: (name: string | number): string => {
        return i18next.t('moreFrom', {
            name,
        });
    },
    get mountIncluded(): string {
        return i18next.t('mountIncluded');
    },
    get mountingAccessories(): string {
        return i18next.t('mountingAccessories');
    },
    get mountingTitle(): string {
        return i18next.t('mountingTitle');
    },
    get move(): string {
        return i18next.t('move');
    },
    get multiple(): string {
        return i18next.t('multiple');
    },
    get multipleCopies(): string {
        return i18next.t('multipleCopies');
    },
    get mustBeAddedToDevice(): string {
        return i18next.t('mustBeAddedToDevice');
    },
    get mustBeAddedToMainUnit(): string {
        return i18next.t('mustBeAddedToMainUnit');
    },
    get myLocalProjectsDescription(): string {
        return i18next.t('myLocalProjectsDescription');
    },
    get myLocalProjectsWarning(): string {
        return i18next.t('myLocalProjectsWarning');
    },
    get myProjects(): string {
        return i18next.t('myProjects');
    },
    get myProjectsDescription(): string {
        return i18next.t('myProjectsDescription');
    },
    get name(): string {
        return i18next.t('name');
    },
    get needDesignAssistance(): string {
        return i18next.t('needDesignAssistance');
    },
    get needHelp(): string {
        return i18next.t('needHelp');
    },
    get needToUpdateSolution(): string {
        return i18next.t('needToUpdateSolution');
    },
    get network(): string {
        return i18next.t('network');
    },
    get networkError(): string {
        return i18next.t('networkError');
    },
    networkPortsGROUP: {
        get nonPoe(): string {
            return i18next.t('networkPortsGROUP.nonPoe');
        },
        get poeClass0(): string {
            return i18next.t('networkPortsGROUP.poeClass0');
        },
        get poeClass1(): string {
            return i18next.t('networkPortsGROUP.poeClass1');
        },
        get poeClass2(): string {
            return i18next.t('networkPortsGROUP.poeClass2');
        },
        get poeClass3(): string {
            return i18next.t('networkPortsGROUP.poeClass3');
        },
        get poeHigh(): string {
            return i18next.t('networkPortsGROUP.poeHigh');
        },
        get poePlus(): string {
            return i18next.t('networkPortsGROUP.poePlus');
        },
    },
    get networkReaders(): string {
        return i18next.t('networkReaders');
    },
    get never(): string {
        return i18next.t('never');
    },
    get newAccessServer(): string {
        return i18next.t('newAccessServer');
    },
    get newAnsweringUnit(): string {
        return i18next.t('newAnsweringUnit');
    },
    get newAudioVisualAlerter(): string {
        return i18next.t('newAudioVisualAlerter');
    },
    get newBodyWornCamera(): string {
        return i18next.t('newBodyWornCamera');
    },
    get newCameraExtension(): string {
        return i18next.t('newCameraExtension');
    },
    get newConnectivityDevice(): string {
        return i18next.t('newConnectivityDevice');
    },
    get newDecoder(): string {
        return i18next.t('newDecoder');
    },
    get newDevice(): string {
        return i18next.t('newDevice');
    },
    get newDockingStation(): string {
        return i18next.t('newDockingStation');
    },
    get newDoorController(): string {
        return i18next.t('newDoorController');
    },
    get newExpansionModule(): string {
        return i18next.t('newExpansionModule');
    },
    get newIntercom(): string {
        return i18next.t('newIntercom');
    },
    get newIORelay(): string {
        return i18next.t('newIORelay');
    },
    get newLocation(): string {
        return i18next.t('newLocation');
    },
    get newNetworkReader(): string {
        return i18next.t('newNetworkReader');
    },
    get newPagingConsole(): string {
        return i18next.t('newPagingConsole');
    },
    get newPeopleCounter(): string {
        return i18next.t('newPeopleCounter');
    },
    get newRadar(): string {
        return i18next.t('newRadar');
    },
    get newSystemController(): string {
        return i18next.t('newSystemController');
    },
    get newText(): string {
        return i18next.t('newText');
    },
    get noAccessories(): string {
        return i18next.t('noAccessories');
    },
    get noAccessoriesHint(): string {
        return i18next.t('noAccessoriesHint');
    },
    get noAccessoriesSelectedForDevice(): string {
        return i18next.t('noAccessoriesSelectedForDevice');
    },
    get noAccessoriesSelectedForMounting(): string {
        return i18next.t('noAccessoriesSelectedForMounting');
    },
    get noApplicationsSelected(): string {
        return i18next.t('noApplicationsSelected');
    },
    get noFreeIp(): string {
        return i18next.t('noFreeIp');
    },
    get noFreeIp_description(): string {
        return i18next.t('noFreeIp_description');
    },
    get noLens(): string {
        return i18next.t('noLens');
    },
    get noLensesAvailableForThisDevice(): string {
        return i18next.t('noLensesAvailableForThisDevice');
    },
    get noMountsAvailable(): string {
        return i18next.t('noMountsAvailable');
    },
    get noMultipleRecordingsWithRecordingSolution(): string {
        return i18next.t('noMultipleRecordingsWithRecordingSolution');
    },
    get noProductsAvailable(): string {
        return i18next.t('noProductsAvailable');
    },
    get noProductSelected(): string {
        return i18next.t('noProductSelected');
    },
    noSearchResultsFor: (searchString: string | number): string => {
        return i18next.t('noSearchResultsFor', {
            searchString,
        });
    },
    get noSystemAccessories(): string {
        return i18next.t('noSystemAccessories');
    },
    get notAvailable(): string {
        return i18next.t('notAvailable');
    },
    get notes(): string {
        return i18next.t('notes');
    },
    get notOnMap(): string {
        return i18next.t('notOnMap');
    },
    get notOutdoor(): string {
        return i18next.t('notOutdoor');
    },
    get notSelected(): string {
        return i18next.t('notSelected');
    },
    get notSupportedByMap(): string {
        return i18next.t('notSupportedByMap');
    },
    get notUsed(): string {
        return i18next.t('notUsed');
    },
    numberOfDevices: (numberOf: string | number): string => {
        return i18next.t('numberOfDevices', {
            numberOf,
        });
    },
    get obtainIPAddressAutomatically(): string {
        return i18next.t('obtainIPAddressAutomatically');
    },
    get obtainIPAddressesAutomatically(): string {
        return i18next.t('obtainIPAddressesAutomatically');
    },
    get off(): string {
        return i18next.t('off');
    },
    get onlineError(): string {
        return i18next.t('onlineError');
    },
    get onlyItemsOnThisLocation(): string {
        return i18next.t('onlyItemsOnThisLocation');
    },
    get onlyOnThisFloorPlan(): string {
        return i18next.t('onlyOnThisFloorPlan');
    },
    get onlyOnThisLocation(): string {
        return i18next.t('onlyOnThisLocation');
    },
    get onMap(): string {
        return i18next.t('onMap');
    },
    get open(): string {
        return i18next.t('open');
    },
    get operationalTemperature(): string {
        return i18next.t('operationalTemperature');
    },
    get optimizePerformance(): string {
        return i18next.t('optimizePerformance');
    },
    get optimizePerformanceInfo(): string {
        return i18next.t('optimizePerformanceInfo');
    },
    get optimizePerformanceNow(): string {
        return i18next.t('optimizePerformanceNow');
    },
    get optimizingPerformance(): string {
        return i18next.t('optimizingPerformance');
    },
    get organizationName(): string {
        return i18next.t('organizationName');
    },
    get organizationSettingsOnlyRecommendedProducts(): string {
        return i18next.t('organizationSettingsOnlyRecommendedProducts');
    },
    get other(): string {
        return i18next.t('other');
    },
    otherProjectDeviceSelectorCategoriesGROUP: {
        get doorControllers(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.doorControllers');
        },
        get doorStations(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.doorStations');
        },
        get ioRelayModules(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.ioRelayModules');
        },
        get peopleCounters(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.peopleCounters');
        },
        get radarDetectors(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.radarDetectors');
        },
        get videoDecoders(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.videoDecoders');
        },
        get audioVisualAlerters(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.audioVisualAlerters');
        },
        get connectivityDevices(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.connectivityDevices');
        },
        get pagingConsoles(): string {
            return i18next.t('otherProjectDeviceSelectorCategoriesGROUP.pagingConsoles');
        },
    },
    get outdoor(): string {
        return i18next.t('outdoor');
    },
    get outdoorPlacement(): string {
        return i18next.t('outdoorPlacement');
    },
    get output(): string {
        return i18next.t('output');
    },
    get panorama(): string {
        return i18next.t('panorama');
    },
    get paperSize(): string {
        return i18next.t('paperSize');
    },
    get partnerConfigCompanyNameDescription(): string {
        return i18next.t('partnerConfigCompanyNameDescription');
    },
    get partnerConfigDownload(): string {
        return i18next.t('partnerConfigDownload');
    },
    get partnerConfigEnableCustomPartNumber(): string {
        return i18next.t('partnerConfigEnableCustomPartNumber');
    },
    get partnerConfigEnableCustomPartNumberDescription(): string {
        return i18next.t('partnerConfigEnableCustomPartNumberDescription');
    },
    get partnerConfigEnableLimitedDeviceList(): string {
        return i18next.t('partnerConfigEnableLimitedDeviceList');
    },
    get partnerConfigEnableLocIdAccessDescription(): string {
        return i18next.t('partnerConfigEnableLocIdAccessDescription');
    },
    get partnerConfigEnableLocIdAccessInfo(): string {
        return i18next.t('partnerConfigEnableLocIdAccessInfo');
    },
    get partnerConfigEnablePublicUrlAccessDescription(): string {
        return i18next.t('partnerConfigEnablePublicUrlAccessDescription');
    },
    get partnerConfigExcludedProductsMessageLabel(): string {
        return i18next.t('partnerConfigExcludedProductsMessageLabel');
    },
    get partnerConfigLoad(): string {
        return i18next.t('partnerConfigLoad');
    },
    get partnerConfigLoadSuccess(): string {
        return i18next.t('partnerConfigLoadSuccess');
    },
    get partnerConfigLocIdMissing(): string {
        return i18next.t('partnerConfigLocIdMissing');
    },
    partnerConfigurationErrorLoadingGeneral: (partnerId: string | number): string => {
        return i18next.t('partnerConfigurationErrorLoadingGeneral', {
            partnerId,
        });
    },
    partnerConfigurationErrorLoadingOffline: (partnerId: string | number): string => {
        return i18next.t('partnerConfigurationErrorLoadingOffline', {
            partnerId,
        });
    },
    get partnerConfigurationErrorLoadingShort(): string {
        return i18next.t('partnerConfigurationErrorLoadingShort');
    },
    get partnerConfigurationPartnerSiteFor(): string {
        return i18next.t('partnerConfigurationPartnerSiteFor');
    },
    get partnerProductsFromOtherVendorsHeader(): string {
        return i18next.t('partnerProductsFromOtherVendorsHeader');
    },
    get partnerProductsFromOtherVendorsText(): string {
        return i18next.t('partnerProductsFromOtherVendorsText');
    },
    partnerRecordingSolutionDescription: (partnerName: string | number): string => {
        return i18next.t('partnerRecordingSolutionDescription', {
            partnerName,
        });
    },
    get partNumber(): string {
        return i18next.t('partNumber');
    },
    get pending(): string {
        return i18next.t('pending');
    },
    get peopleCounters(): string {
        return i18next.t('peopleCounters');
    },
    get percentOfTime(): string {
        return i18next.t('percentOfTime');
    },
    get piaError(): string {
        return i18next.t('piaError');
    },
    get piaLoading(): string {
        return i18next.t('piaLoading');
    },
    get pickModelLater(): string {
        return i18next.t('pickModelLater');
    },
    get pixelsPerInch(): string {
        return i18next.t('pixelsPerInch');
    },
    get placed(): string {
        return i18next.t('placed');
    },
    get placement(): string {
        return i18next.t('placement');
    },
    get placeSearchHint(): string {
        return i18next.t('placeSearchHint');
    },
    get placeSearchNoMatch(): string {
        return i18next.t('placeSearchNoMatch');
    },
    get placeSearchPlaceholder(): string {
        return i18next.t('placeSearchPlaceholder');
    },
    get pleaseCheckYourNetworkConnection(): string {
        return i18next.t('pleaseCheckYourNetworkConnection');
    },
    poeClassesClassNbr: (classNbr: string | number): string => {
        return i18next.t('poeClassesClassNbr', {
            classNbr,
        });
    },
    get poeClassesHighPoe(): string {
        return i18next.t('poeClassesHighPoe');
    },
    get poeClassesNonPoe(): string {
        return i18next.t('poeClassesNonPoe');
    },
    get poePower(): string {
        return i18next.t('poePower');
    },
    get portrait(): string {
        return i18next.t('portrait');
    },
    get ports(): string {
        return i18next.t('ports');
    },
    get power(): string {
        return i18next.t('power');
    },
    get powerConsumption(): string {
        return i18next.t('powerConsumption');
    },
    get powerReportDisclaimerHeading(): string {
        return i18next.t('powerReportDisclaimerHeading');
    },
    get powerReportDisclaimerText(): string {
        return i18next.t('powerReportDisclaimerText');
    },
    get powerReportDisclaimerTextOtherProducts(): string {
        return i18next.t('powerReportDisclaimerTextOtherProducts');
    },
    get powerSupply(): string {
        return i18next.t('powerSupply');
    },
    get primaryMount(): string {
        return i18next.t('primaryMount');
    },
    get print(): string {
        return i18next.t('print');
    },
    get printMap(): string {
        return i18next.t('printMap');
    },
    get printSettings(): string {
        return i18next.t('printSettings');
    },
    get proceed(): string {
        return i18next.t('proceed');
    },
    get productCharacteristics(): string {
        return i18next.t('productCharacteristics');
    },
    get productHighlights(): string {
        return i18next.t('productHighlights');
    },
    get productName(): string {
        return i18next.t('productName');
    },
    get productsChangeDevice(): string {
        return i18next.t('productsChangeDevice');
    },
    productsFound: (nbrOfProducts: string | number): string => {
        return i18next.t('productsFound', {
            nbrOfProducts,
        });
    },
    get productsToCompare(): string {
        return i18next.t('productsToCompare');
    },
    get profileEditProfile(): string {
        return i18next.t('profileEditProfile');
    },
    get profileNotCompatibleWithRecordingSolution(): string {
        return i18next.t('profileNotCompatibleWithRecordingSolution');
    },
    get profiles(): string {
        return i18next.t('profiles');
    },
    get profilesAddProfile(): string {
        return i18next.t('profilesAddProfile');
    },
    get profilesAudioAndZipstreamSettingsLiveViewAudio(): string {
        return i18next.t('profilesAudioAndZipstreamSettingsLiveViewAudio');
    },
    get profilesAudioAndZipstreamSettingsRecordingAudio(): string {
        return i18next.t('profilesAudioAndZipstreamSettingsRecordingAudio');
    },
    profilesChangeProfileWarning: (deviceCount: string | number): string => {
        return i18next.t('profilesChangeProfileWarning', {
            deviceCount,
        });
    },
    get profilesMakeDefault(): string {
        return i18next.t('profilesMakeDefault');
    },
    profilesNameChangeFailedToastBody: (name: string | number): string => {
        return i18next.t('profilesNameChangeFailedToastBody', {
            name,
        });
    },
    get profilesNameChangeFailedToastHeader(): string {
        return i18next.t('profilesNameChangeFailedToastHeader');
    },
    get profilesNewProfileName(): string {
        return i18next.t('profilesNewProfileName');
    },
    get profilesProfileName(): string {
        return i18next.t('profilesProfileName');
    },
    get profilesRecordingSettingsAudio(): string {
        return i18next.t('profilesRecordingSettingsAudio');
    },
    get profilesRecordingSettingsAverageBitrate(): string {
        return i18next.t('profilesRecordingSettingsAverageBitrate');
    },
    get profilesRecordingSettingsCameraMaxResolution(): string {
        return i18next.t('profilesRecordingSettingsCameraMaxResolution');
    },
    get profilesRecordingSettingsCompression(): string {
        return i18next.t('profilesRecordingSettingsCompression');
    },
    get profilesRecordingSettingsFrameRate(): string {
        return i18next.t('profilesRecordingSettingsFrameRate');
    },
    get profilesRecordingSettingsResolution(): string {
        return i18next.t('profilesRecordingSettingsResolution');
    },
    get profilesRecordingSettingsVideoEncoding(): string {
        return i18next.t('profilesRecordingSettingsVideoEncoding');
    },
    get profilesScenarioEditorLowGoodLight(): string {
        return i18next.t('profilesScenarioEditorLowGoodLight');
    },
    get profilesScenarioEditorScenario(): string {
        return i18next.t('profilesScenarioEditorScenario');
    },
    get profilesScheduledRecordingContinuousRecording(): string {
        return i18next.t('profilesScheduledRecordingContinuousRecording');
    },
    get profilesScheduledRecordingGoodLightConditions(): string {
        return i18next.t('profilesScheduledRecordingGoodLightConditions');
    },
    get profilesScheduledRecordingGoodLightMotion(): string {
        return i18next.t('profilesScheduledRecordingGoodLightMotion');
    },
    get profilesScheduledRecordingInconsistentRecordingQualityWarning(): string {
        return i18next.t('profilesScheduledRecordingInconsistentRecordingQualityWarning');
    },
    get profilesScheduledRecordingLiveView(): string {
        return i18next.t('profilesScheduledRecordingLiveView');
    },
    get profilesScheduledRecordingLowLightConditions(): string {
        return i18next.t('profilesScheduledRecordingLowLightConditions');
    },
    get profilesScheduledRecordingLowLightMotion(): string {
        return i18next.t('profilesScheduledRecordingLowLightMotion');
    },
    get profilesScheduledRecordingMotionInScene(): string {
        return i18next.t('profilesScheduledRecordingMotionInScene');
    },
    get profilesScheduledRecordingMotionObjectSizeLarge(): string {
        return i18next.t('profilesScheduledRecordingMotionObjectSizeLarge');
    },
    get profilesScheduledRecordingMotionObjectSizeMedium(): string {
        return i18next.t('profilesScheduledRecordingMotionObjectSizeMedium');
    },
    get profilesScheduledRecordingMotionObjectSizeMediumLarge(): string {
        return i18next.t('profilesScheduledRecordingMotionObjectSizeMediumLarge');
    },
    get profilesScheduledRecordingMotionObjectSizeSmall(): string {
        return i18next.t('profilesScheduledRecordingMotionObjectSizeSmall');
    },
    get profilesScheduledRecordingMotionObjectSizeSmallMedium(): string {
        return i18next.t('profilesScheduledRecordingMotionObjectSizeSmallMedium');
    },
    get profilesScheduledRecordingMotionOccurrenceAlways(): string {
        return i18next.t('profilesScheduledRecordingMotionOccurrenceAlways');
    },
    get profilesScheduledRecordingMotionOccurrenceNever(): string {
        return i18next.t('profilesScheduledRecordingMotionOccurrenceNever');
    },
    get profilesScheduledRecordingPartsOfSceneMoving(): string {
        return i18next.t('profilesScheduledRecordingPartsOfSceneMoving');
    },
    get profilesScheduledRecordingTriggeredRecording(): string {
        return i18next.t('profilesScheduledRecordingTriggeredRecording');
    },
    get profilesScheduledRecordingWearable(): string {
        return i18next.t('profilesScheduledRecordingWearable');
    },
    get profilesStorageSettingsStorageTime(): string {
        return i18next.t('profilesStorageSettingsStorageTime');
    },
    profilesSupportFrameRateMessage: (frameRate: string | number): string => {
        return i18next.t('profilesSupportFrameRateMessage', {
            frameRate,
        });
    },
    get profilesSupportNoAudio(): string {
        return i18next.t('profilesSupportNoAudio');
    },
    get profilesSupportNotSupported(): string {
        return i18next.t('profilesSupportNotSupported');
    },
    get profilesSupportNoZipstream(): string {
        return i18next.t('profilesSupportNoZipstream');
    },
    profilesSupportResolutionMessage: (resolution: string | number): string => {
        return i18next.t('profilesSupportResolutionMessage', {
            resolution,
        });
    },
    profilesSupportVideoEncodingMessage: (videoEncoding: string | number): string => {
        return i18next.t('profilesSupportVideoEncodingMessage', {
            videoEncoding,
        });
    },
    get profilesSystemDefinedNamesIndoorProfile(): string {
        return i18next.t('profilesSystemDefinedNamesIndoorProfile');
    },
    get profilesSystemDefinedNamesOutdoorProfile(): string {
        return i18next.t('profilesSystemDefinedNamesOutdoorProfile');
    },
    get projectAddProject(): string {
        return i18next.t('projectAddProject');
    },
    get projectArchive(): string {
        return i18next.t('projectArchive');
    },
    get projectArchived(): string {
        return i18next.t('projectArchived');
    },
    get projectArchiveInfoBody(): string {
        return i18next.t('projectArchiveInfoBody');
    },
    get projectArchiveInfoHeader(): string {
        return i18next.t('projectArchiveInfoHeader');
    },
    get projectBackToProjects(): string {
        return i18next.t('projectBackToProjects');
    },
    get projectChooseInstallationCountry(): string {
        return i18next.t('projectChooseInstallationCountry');
    },
    get projectChooseInstallationCountryRegion(): string {
        return i18next.t('projectChooseInstallationCountryRegion');
    },
    get projectDuplicateErrorHeader(): string {
        return i18next.t('projectDuplicateErrorHeader');
    },
    get projectDuplicateErrorMessage(): string {
        return i18next.t('projectDuplicateErrorMessage');
    },
    get projectDuplicateSuccessHeader(): string {
        return i18next.t('projectDuplicateSuccessHeader');
    },
    projectDuplicateSuccessMessage: (projectName: string | number): string => {
        return i18next.t('projectDuplicateSuccessMessage', {
            projectName,
        });
    },
    get projectDuplicatingHeader(): string {
        return i18next.t('projectDuplicatingHeader');
    },
    get projectDuplicatingMessage(): string {
        return i18next.t('projectDuplicatingMessage');
    },
    get projectExport(): string {
        return i18next.t('projectExport');
    },
    get projectExportErrorHeader(): string {
        return i18next.t('projectExportErrorHeader');
    },
    get projectExportingHeader(): string {
        return i18next.t('projectExportingHeader');
    },
    get projectExportModalMessage(): string {
        return i18next.t('projectExportModalMessage');
    },
    get projectExportModalTitle(): string {
        return i18next.t('projectExportModalTitle');
    },
    get projectExportRequirementError(): string {
        return i18next.t('projectExportRequirementError');
    },
    get projectExportSuccessMessage(): string {
        return i18next.t('projectExportSuccessMessage');
    },
    get projectExportWithFloorPlans(): string {
        return i18next.t('projectExportWithFloorPlans');
    },
    get projectExportWithoutFloorPlans(): string {
        return i18next.t('projectExportWithoutFloorPlans');
    },
    get projectHasFloorPlans(): string {
        return i18next.t('projectHasFloorPlans');
    },
    get projectImperial(): string {
        return i18next.t('projectImperial');
    },
    get projectImportActionLabel(): string {
        return i18next.t('projectImportActionLabel');
    },
    get projectImportErrorHeader(): string {
        return i18next.t('projectImportErrorHeader');
    },
    get projectImportErrorsEntityImportFailed(): string {
        return i18next.t('projectImportErrorsEntityImportFailed');
    },
    get projectImportErrorsFileContentsUnreadable(): string {
        return i18next.t('projectImportErrorsFileContentsUnreadable');
    },
    get projectImportErrorsInvalidFileFormat(): string {
        return i18next.t('projectImportErrorsInvalidFileFormat');
    },
    get projectImportErrorsUnknownError(): string {
        return i18next.t('projectImportErrorsUnknownError');
    },
    get projectImportImportingHeader(): string {
        return i18next.t('projectImportImportingHeader');
    },
    get projectImportImportingMessage(): string {
        return i18next.t('projectImportImportingMessage');
    },
    get projectImportModalMessage(): string {
        return i18next.t('projectImportModalMessage');
    },
    get projectImportModalTitle(): string {
        return i18next.t('projectImportModalTitle');
    },
    get projectImportSuccessHeader(): string {
        return i18next.t('projectImportSuccessHeader');
    },
    projectImportSuccessMessage: (projectName: string | number): string => {
        return i18next.t('projectImportSuccessMessage', {
            projectName,
        });
    },
    get projectImportWithoutFloorPlans(): string {
        return i18next.t('projectImportWithoutFloorPlans');
    },
    get projectIsBeingExportedMessage(): string {
        return i18next.t('projectIsBeingExportedMessage');
    },
    get projectLoading(): string {
        return i18next.t('projectLoading');
    },
    get projectLocked(): string {
        return i18next.t('projectLocked');
    },
    get projectMaxPower(): string {
        return i18next.t('projectMaxPower');
    },
    get projectMetric(): string {
        return i18next.t('projectMetric');
    },
    get projectMultiExportModalMessage(): string {
        return i18next.t('projectMultiExportModalMessage');
    },
    get projectMultiExportRequirementError(): string {
        return i18next.t('projectMultiExportRequirementError');
    },
    get projectNotFoundHeader(): string {
        return i18next.t('projectNotFoundHeader');
    },
    get projectNotFoundInfo(): string {
        return i18next.t('projectNotFoundInfo');
    },
    get projectOverview(): string {
        return i18next.t('projectOverview');
    },
    get projectPoeClass(): string {
        return i18next.t('projectPoeClass');
    },
    get projectPowerCalculation(): string {
        return i18next.t('projectPowerCalculation');
    },
    projectPricingGROUP: {
        get requestProjectPricing(): string {
            return i18next.t('projectPricingGROUP.requestProjectPricing');
        },
        get mainHeadline(): string {
            return i18next.t('projectPricingGROUP.mainHeadline');
        },
        get intro(): string {
            return i18next.t('projectPricingGROUP.intro');
        },
        get downloadBOMFile(): string {
            return i18next.t('projectPricingGROUP.downloadBOMFile');
        },
        get clickToDownload(): string {
            return i18next.t('projectPricingGROUP.clickToDownload');
        },
        get sendFile(): string {
            return i18next.t('projectPricingGROUP.sendFile');
        },
        get includeBOMFileInRequest(): string {
            return i18next.t('projectPricingGROUP.includeBOMFileInRequest');
        },
        get or(): string {
            return i18next.t('projectPricingGROUP.or');
        },
        get requestOnPartnerPages(): string {
            return i18next.t('projectPricingGROUP.requestOnPartnerPages');
        },
        get waitForConfirmation(): string {
            return i18next.t('projectPricingGROUP.waitForConfirmation');
        },
        get awaitConfirmedPricing(): string {
            return i18next.t('projectPricingGROUP.awaitConfirmedPricing');
        },
    },
    get projectProgress(): string {
        return i18next.t('projectProgress');
    },
    get projectProjectName(): string {
        return i18next.t('projectProjectName');
    },
    get projectReportsBillOfMaterialsHeading(): string {
        return i18next.t('projectReportsBillOfMaterialsHeading');
    },
    get projectReportsCreatingReport(): string {
        return i18next.t('projectReportsCreatingReport');
    },
    get projectReportsEditQuotationHeading(): string {
        return i18next.t('projectReportsEditQuotationHeading');
    },
    get projectReportsInstallationReportHeading(): string {
        return i18next.t('projectReportsInstallationReportHeading');
    },
    get projectReportsPowerAndBandwidthReportHeading(): string {
        return i18next.t('projectReportsPowerAndBandwidthReportHeading');
    },
    get projectRequirements(): string {
        return i18next.t('projectRequirements');
    },
    get projectRestore(): string {
        return i18next.t('projectRestore');
    },
    get projectRestoreFromArchive(): string {
        return i18next.t('projectRestoreFromArchive');
    },
    get projectRestoreToOpen(): string {
        return i18next.t('projectRestoreToOpen');
    },
    get projectsAreBeingExported(): string {
        return i18next.t('projectsAreBeingExported');
    },
    get projectsExported(): string {
        return i18next.t('projectsExported');
    },
    get projectsExportedSuccessfully(): string {
        return i18next.t('projectsExportedSuccessfully');
    },
    get projectsFailedToExport(): string {
        return i18next.t('projectsFailedToExport');
    },
    get projectShareByAccessCodeDetails(): string {
        return i18next.t('projectShareByAccessCodeDetails');
    },
    get projectShareBySettingsFileDetails(): string {
        return i18next.t('projectShareBySettingsFileDetails');
    },
    projectShareCodeExpired: (expirationDate: string | number): string => {
        return i18next.t('projectShareCodeExpired', {
            expirationDate,
        });
    },
    projectShareCodeExpires: (expirationDate: string | number): string => {
        return i18next.t('projectShareCodeExpires', {
            expirationDate,
        });
    },
    get projectShareDetails(): string {
        return i18next.t('projectShareDetails');
    },
    get projectShareDownloadSettingsFile(): string {
        return i18next.t('projectShareDownloadSettingsFile');
    },
    get projectShareErrorForbidden(): string {
        return i18next.t('projectShareErrorForbidden');
    },
    get projectShareErrorPayloadTooLarge(): string {
        return i18next.t('projectShareErrorPayloadTooLarge');
    },
    get projectShareErrorRevokeCodeFailed(): string {
        return i18next.t('projectShareErrorRevokeCodeFailed');
    },
    get projectShareErrorUnauthorized(): string {
        return i18next.t('projectShareErrorUnauthorized');
    },
    get projectShareErrorUnknownNetworkError(): string {
        return i18next.t('projectShareErrorUnknownNetworkError');
    },
    get projectShareGenerateCode(): string {
        return i18next.t('projectShareGenerateCode');
    },
    get projectShareGenerateNewCode(): string {
        return i18next.t('projectShareGenerateNewCode');
    },
    get projectShareRevokeCode(): string {
        return i18next.t('projectShareRevokeCode');
    },
    get projectShareShareByAccessCode(): string {
        return i18next.t('projectShareShareByAccessCode');
    },
    get projectShareShareByFile(): string {
        return i18next.t('projectShareShareByFile');
    },
    get projectShowArchived(): string {
        return i18next.t('projectShowArchived');
    },
    get projectStorageTime(): string {
        return i18next.t('projectStorageTime');
    },
    get projectSuccessToastBody(): string {
        return i18next.t('projectSuccessToastBody');
    },
    get projectSuccessToastHeader(): string {
        return i18next.t('projectSuccessToastHeader');
    },
    get projectUnitSystem(): string {
        return i18next.t('projectUnitSystem');
    },
    get projectWaitWhileLoadingProjects(): string {
        return i18next.t('projectWaitWhileLoadingProjects');
    },
    get projectWasArchivedHeader(): string {
        return i18next.t('projectWasArchivedHeader');
    },
    get projectWasArchivedMessage(): string {
        return i18next.t('projectWasArchivedMessage');
    },
    get projectWasRestoredHeader(): string {
        return i18next.t('projectWasRestoredHeader');
    },
    get projectWasRestoredMessage(): string {
        return i18next.t('projectWasRestoredMessage');
    },
    proposalSummaryCategoriesGROUP: {
        get accessoriesTotal(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.accessoriesTotal');
        },
        get cameras(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.cameras');
        },
        get equipmentTotal(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.equipmentTotal');
        },
        get hardwareAccessories(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.hardwareAccessories');
        },
        get otherHardware(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.otherHardware');
        },
        get recordingSolution(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.recordingSolution');
        },
        get recordingSolutionSpec(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.recordingSolutionSpec');
        },
        get software(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.software');
        },
        get summary(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.summary');
        },
        get systemProperties(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.systemProperties');
        },
        get totalMonthlyRecurring(): string {
            return i18next.t('proposalSummaryCategoriesGROUP.totalMonthlyRecurring');
        },
    },
    get publishProjectForInstallation(): string {
        return i18next.t('publishProjectForInstallation');
    },
    get quantity(): string {
        return i18next.t('quantity');
    },
    get quickAddHelp(): string {
        return i18next.t('quickAddHelp');
    },
    get quickAddNoMatch(): string {
        return i18next.t('quickAddNoMatch');
    },
    get quickAddPlaceholder(): string {
        return i18next.t('quickAddPlaceholder');
    },
    get quotationAddDevices(): string {
        return i18next.t('quotationAddDevices');
    },
    get quotationAddFooter(): string {
        return i18next.t('quotationAddFooter');
    },
    get quotationAddHeader(): string {
        return i18next.t('quotationAddHeader');
    },
    get quotationAdjustBy(): string {
        return i18next.t('quotationAdjustBy');
    },
    get quotationAnalogCamera(): string {
        return i18next.t('quotationAnalogCamera');
    },
    get quotationAxisProducts(): string {
        return i18next.t('quotationAxisProducts');
    },
    get quotationCameras(): string {
        return i18next.t('quotationCameras');
    },
    get quotationCopyMsrp(): string {
        return i18next.t('quotationCopyMsrp');
    },
    get quotationCopyMsrpTitle(): string {
        return i18next.t('quotationCopyMsrpTitle');
    },
    get quotationCountryInfo(): string {
        return i18next.t('quotationCountryInfo');
    },
    get quotationCurrentCountry(): string {
        return i18next.t('quotationCurrentCountry');
    },
    get quotationDateValidDisclaimer(): string {
        return i18next.t('quotationDateValidDisclaimer');
    },
    get quotationDecoders(): string {
        return i18next.t('quotationDecoders');
    },
    get quotationDiscontinued(): string {
        return i18next.t('quotationDiscontinued');
    },
    get quotationDoorStations(): string {
        return i18next.t('quotationDoorStations');
    },
    get quotationEncoders(): string {
        return i18next.t('quotationEncoders');
    },
    get quotationEstimatedBandwidth(): string {
        return i18next.t('quotationEstimatedBandwidth');
    },
    get quotationEstimatedStorage(): string {
        return i18next.t('quotationEstimatedStorage');
    },
    quotationExcelCountryInfo: (country: string | number): string => {
        return i18next.t('quotationExcelCountryInfo', {
            country,
        });
    },
    quotationExcelFileName: (project: string | number): string => {
        return i18next.t('quotationExcelFileName', {
            project,
        });
    },
    quotationExcelQuote: (project: string | number): string => {
        return i18next.t('quotationExcelQuote', {
            project,
        });
    },
    quotationExcelQuotePrice: (currency: string | number): string => {
        return i18next.t('quotationExcelQuotePrice', {
            currency,
        });
    },
    get quotationExcelSheetTitle(): string {
        return i18next.t('quotationExcelSheetTitle');
    },
    quotationExcludingTaxes: (currency: string | number): string => {
        return i18next.t('quotationExcludingTaxes', {
            currency,
        });
    },
    get quotationExportToBOM(): string {
        return i18next.t('quotationExportToBOM');
    },
    get quotationExportToExcel(): string {
        return i18next.t('quotationExportToExcel');
    },
    get quotationFSeries(): string {
        return i18next.t('quotationFSeries');
    },
    get quotationHeading(): string {
        return i18next.t('quotationHeading');
    },
    get quotationInstallationService(): string {
        return i18next.t('quotationInstallationService');
    },
    get quotationMiscellaneous(): string {
        return i18next.t('quotationMiscellaneous');
    },
    get quotationModel(): string {
        return i18next.t('quotationModel');
    },
    get quotationModelName(): string {
        return i18next.t('quotationModelName');
    },
    get quotationMsrp(): string {
        return i18next.t('quotationMsrp');
    },
    quotationMsrpChangeCountry: (country: string | number): string => {
        return i18next.t('quotationMsrpChangeCountry', {
            country,
        });
    },
    quotationMsrpCurrencyDisclaimer: (currency: string | number): string => {
        return i18next.t('quotationMsrpCurrencyDisclaimer', {
            currency,
        });
    },
    get quotationMsrpDisclaimer(): string {
        return i18next.t('quotationMsrpDisclaimer');
    },
    get quotationMsrpGoOnline(): string {
        return i18next.t('quotationMsrpGoOnline');
    },
    get quotationMsrpLoading(): string {
        return i18next.t('quotationMsrpLoading');
    },
    quotationMsrpValidFor: (month: string | number, year: string | number): string => {
        return i18next.t('quotationMsrpValidFor', {
            month,
            year,
        });
    },
    get quotationNoDevices(): string {
        return i18next.t('quotationNoDevices');
    },
    get quotationNotAvailable(): string {
        return i18next.t('quotationNotAvailable');
    },
    get quotationOther(): string {
        return i18next.t('quotationOther');
    },
    get quotationPacs(): string {
        return i18next.t('quotationPacs');
    },
    get quotationPartNumber(): string {
        return i18next.t('quotationPartNumber');
    },
    get quotationProductName(): string {
        return i18next.t('quotationProductName');
    },
    get quotationProducts(): string {
        return i18next.t('quotationProducts');
    },
    get quotationProfileName(): string {
        return i18next.t('quotationProfileName');
    },
    get quotationQty(): string {
        return i18next.t('quotationQty');
    },
    get quotationQuantity(): string {
        return i18next.t('quotationQuantity');
    },
    get quotationQuotation(): string {
        return i18next.t('quotationQuotation');
    },
    get quotationQuotationValidUntil(): string {
        return i18next.t('quotationQuotationValidUntil');
    },
    quotationQuotationValidUntilDate: (date: string | number): string => {
        return i18next.t('quotationQuotationValidUntilDate', {
            date,
        });
    },
    get quotationQuotePrice(): string {
        return i18next.t('quotationQuotePrice');
    },
    get quotationRadarDetectors(): string {
        return i18next.t('quotationRadarDetectors');
    },
    get quotationRadarConnectivityDevices(): string {
        return i18next.t('quotationRadarConnectivityDevices');
    },
    get quotationRecordingAndNetwork(): string {
        return i18next.t('quotationRecordingAndNetwork');
    },
    quotationSectionTotal: (section: string | number, currency: string | number): string => {
        return i18next.t('quotationSectionTotal', {
            section,
            currency,
        });
    },
    get quotationService(): string {
        return i18next.t('quotationService');
    },
    get quotationServices(): string {
        return i18next.t('quotationServices');
    },
    get quotationSoftware(): string {
        return i18next.t('quotationSoftware');
    },
    get quotationSpeakers(): string {
        return i18next.t('quotationSpeakers');
    },
    quotationStateGROUP: {
        get design(): string {
            return i18next.t('quotationStateGROUP.design');
        },
        get done(): string {
            return i18next.t('quotationStateGROUP.done');
        },
        get installed(): string {
            return i18next.t('quotationStateGROUP.installed');
        },
        get quoteAccepted(): string {
            return i18next.t('quotationStateGROUP.quoteAccepted');
        },
        get quoteSent(): string {
            return i18next.t('quotationStateGROUP.quoteSent');
        },
    },
    get quotationStorageTime(): string {
        return i18next.t('quotationStorageTime');
    },
    get quotationSummary(): string {
        return i18next.t('quotationSummary');
    },
    get quotationTotal(): string {
        return i18next.t('quotationTotal');
    },
    get quotationTotalServices(): string {
        return i18next.t('quotationTotalServices');
    },
    get quotationUnlockProject(): string {
        return i18next.t('quotationUnlockProject');
    },
    get quotationViewSalesQuote(): string {
        return i18next.t('quotationViewSalesQuote');
    },
    get radarCoexistenceWarning(): string {
        return i18next.t('radarCoexistenceWarning');
    },
    get radarDetectors(): string {
        return i18next.t('radarDetectors');
    },
    get radarWarningDismiss(): string {
        return i18next.t('radarWarningDismiss');
    },
    get radarWarningUnDismiss(): string {
        return i18next.t('radarWarningUnDismiss');
    },
    get readDocument(): string {
        return i18next.t('readDocument');
    },
    get readManual(): string {
        return i18next.t('readManual');
    },
    get readMore(): string {
        return i18next.t('readMore');
    },
    get readMoreAboutSustainability(): string {
        return i18next.t('readMoreAboutSustainability');
    },
    get reassignIp(): string {
        return i18next.t('reassignIp');
    },
    get recommend(): string {
        return i18next.t('recommend');
    },
    get recommended(): string {
        return i18next.t('recommended');
    },
    get recorders(): string {
        return i18next.t('recorders');
    },
    get recorderValidationAddMarginsToLargeSystems(): string {
        return i18next.t('recorderValidationAddMarginsToLargeSystems');
    },
    recorderValidationAlternativeUnderCapacity: (
        dimension1: string | number,
        dimension2: string | number,
        quantity: string | number,
    ): string => {
        return i18next.t('recorderValidationAlternativeUnderCapacity', {
            dimension1,
            dimension2,
            quantity,
        });
    },
    recorderValidationCombinedUnderCapacity: (
        dimension: string | number,
        quantity: string | number,
    ): string => {
        return i18next.t('recorderValidationCombinedUnderCapacity', {
            dimension,
            quantity,
        });
    },
    recorderValidationCompanionMissingSDCard: (
        deviceName: string | number,
        sdCardModelName: string | number,
    ): string => {
        return i18next.t('recorderValidationCompanionMissingSDCard', {
            deviceName,
            sdCardModelName,
        });
    },
    recorderValidationCompanionTooMany: (
        supported: string | number,
        actual: string | number,
    ): string => {
        return i18next.t('recorderValidationCompanionTooMany', {
            supported,
            actual,
        });
    },
    recorderValidationCompanionUnsupportedDevice: (modelName: string | number): string => {
        return i18next.t('recorderValidationCompanionUnsupportedDevice', {
            modelName,
        });
    },
    get recording(): string {
        return i18next.t('recording');
    },
    get recordingBandwidth(): string {
        return i18next.t('recordingBandwidth');
    },
    get recordingSolutionAxis(): string {
        return i18next.t('recordingSolutionAxis');
    },
    get recordingSolutionGenetec(): string {
        return i18next.t('recordingSolutionGenetec');
    },
    get recordingSolutionMilestone(): string {
        return i18next.t('recordingSolutionMilestone');
    },
    recordingValidationNbrDoorsNotSupported: (
        nbrDoors: string | number,
        channels: string | number,
    ): string => {
        return i18next.t('recordingValidationNbrDoorsNotSupported', {
            nbrDoors,
            channels,
        });
    },
    get recycledPlastics(): string {
        return i18next.t('recycledPlastics');
    },
    get redo(): string {
        return i18next.t('redo');
    },
    get refresh(): string {
        return i18next.t('refresh');
    },
    get reload(): string {
        return i18next.t('reload');
    },
    get remove(): string {
        return i18next.t('remove');
    },
    get removeAccessoriesAndInstallationPointsConfirmationBody(): string {
        return i18next.t('removeAccessoriesAndInstallationPointsConfirmationBody');
    },
    removeAccessoriesAndInstallationPointsConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeAccessoriesAndInstallationPointsConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeAccessoriesAndInstallationPointsConfirmationGROUP.header');
        },
    },
    get removeAccessoriesConfirmationBody(): string {
        return i18next.t('removeAccessoriesConfirmationBody');
    },
    removeAccessoriesConfirmationGROUP: {
        get header(): string {
            return i18next.t('removeAccessoriesConfirmationGROUP.header');
        },
    },
    get removeAnalogCamerasAndAccessoriesConfirmationBody(): string {
        return i18next.t('removeAnalogCamerasAndAccessoriesConfirmationBody');
    },
    removeAnalogCamerasAndAccessoriesConfirmationGROUP: {
        get header(): string {
            return i18next.t('removeAnalogCamerasAndAccessoriesConfirmationGROUP.header');
        },
    },
    removeAnalogCamerasConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeAnalogCamerasConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeAnalogCamerasConfirmationGROUP.header');
        },
    },
    removeConnectedDevicesAndAccessoriesConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeConnectedDevicesAndAccessoriesConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeConnectedDevicesAndAccessoriesConfirmationGROUP.header');
        },
    },
    removeConnectedDevicesConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeConnectedDevicesConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeConnectedDevicesConfirmationGROUP.header');
        },
    },
    get removeDoorsAndAccessoriesConfirmationBody(): string {
        return i18next.t('removeDoorsAndAccessoriesConfirmationBody');
    },
    removeDoorsAndAccessoriesConfirmationGROUP: {
        get header(): string {
            return i18next.t('removeDoorsAndAccessoriesConfirmationGROUP.header');
        },
    },
    removeDoorsConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeDoorsConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeDoorsConfirmationGROUP.header');
        },
    },
    get removeFromMap(): string {
        return i18next.t('removeFromMap');
    },
    removeInstallationPointsConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeInstallationPointsConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeInstallationPointsConfirmationGROUP.header');
        },
    },
    get removeLogo(): string {
        return i18next.t('removeLogo');
    },
    get removeLogoShort(): string {
        return i18next.t('removeLogoShort');
    },
    removePiaDevice: (modelName: string | number): string => {
        return i18next.t('removePiaDevice', {
            modelName,
        });
    },
    removeProductAccessoriesConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeProductAccessoriesConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeProductAccessoriesConfirmationGROUP.header');
        },
    },
    removeProjectNetworkConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeProjectNetworkConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeProjectNetworkConfirmationGROUP.header');
        },
    },
    get removeSensorUnitsAndAccessoriesConfirmationBody(): string {
        return i18next.t('removeSensorUnitsAndAccessoriesConfirmationBody');
    },
    removeSensorUnitsAndAccessoriesConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeSensorUnitsAndAccessoriesConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeSensorUnitsAndAccessoriesConfirmationGROUP.header');
        },
    },
    removeSensorUnitsConfirmationGROUP: {
        get body(): string {
            return i18next.t('removeSensorUnitsConfirmationGROUP.body');
        },
        get header(): string {
            return i18next.t('removeSensorUnitsConfirmationGROUP.header');
        },
    },
    get removeTheme(): string {
        return i18next.t('removeTheme');
    },
    get removeUnsupportedLenses(): string {
        return i18next.t('removeUnsupportedLenses');
    },
    get repairProjectBody(): string {
        return i18next.t('repairProjectBody');
    },
    get repairProjectHeader(): string {
        return i18next.t('repairProjectHeader');
    },
    get replace(): string {
        return i18next.t('replace');
    },
    replaceProduct: (product1: string | number, product2: string | number): string => {
        return i18next.t('replaceProduct', {
            product1,
            product2,
        });
    },
    get replaceRecordingSolutionHeading(): string {
        return i18next.t('replaceRecordingSolutionHeading');
    },
    get replaceRecordingSolutionMessage(): string {
        return i18next.t('replaceRecordingSolutionMessage');
    },
    get replaceSDCardAccessoriesHeading(): string {
        return i18next.t('replaceSDCardAccessoriesHeading');
    },
    get replaceSDCardAccessoriesMessage(): string {
        return i18next.t('replaceSDCardAccessoriesMessage');
    },
    get reports(): string {
        return i18next.t('reports');
    },
    get required(): string {
        return i18next.t('required');
    },
    get requiredPixelDensity(): string {
        return i18next.t('requiredPixelDensity');
    },
    get requirementsSaved(): string {
        return i18next.t('requirementsSaved');
    },
    get reservedRanges(): string {
        return i18next.t('reservedRanges');
    },
    get reservedRangesInsufficient(): string {
        return i18next.t('reservedRangesInsufficient');
    },
    get reset(): string {
        return i18next.t('reset');
    },
    get resetRecordingSolutionHeading(): string {
        return i18next.t('resetRecordingSolutionHeading');
    },
    get resetRecordingSolutionMessage(): string {
        return i18next.t('resetRecordingSolutionMessage');
    },
    get resolution(): string {
        return i18next.t('resolution');
    },
    get reviewCameraViews(): string {
        return i18next.t('reviewCameraViews');
    },
    get rexDevice(): string {
        return i18next.t('rexDevice');
    },
    get rotate(): string {
        return i18next.t('rotate');
    },
    get ruggedized(): string {
        return i18next.t('ruggedized');
    },
    get s11RecordingSolutionDescription(): string {
        return i18next.t('s11RecordingSolutionDescription');
    },
    get s11RecordingSolutionDescriptionPro(): string {
        return i18next.t('s11RecordingSolutionDescriptionPro');
    },
    get s11RecordingSolutionHeading(): string {
        return i18next.t('s11RecordingSolutionHeading');
    },
    get s22RecordingSolutionDescription(): string {
        return i18next.t('s22RecordingSolutionDescription');
    },
    get s22RecordingSolutionDescriptionPro(): string {
        return i18next.t('s22RecordingSolutionDescriptionPro');
    },
    get s22RecordingSolutionHeading(): string {
        return i18next.t('s22RecordingSolutionHeading');
    },
    get s30RecordingSolutionDescription(): string {
        return i18next.t('s30RecordingSolutionDescription');
    },
    get s30RecordingSolutionDescriptionPro(): string {
        return i18next.t('s30RecordingSolutionDescriptionPro');
    },
    get s30RecordingSolutionHeading(): string {
        return i18next.t('s30RecordingSolutionHeading');
    },
    get salesQuoteDetails(): string {
        return i18next.t('salesQuoteDetails');
    },
    get save(): string {
        return i18next.t('save');
    },
    get scaleMessage(): string {
        return i18next.t('scaleMessage');
    },
    get scaleTitle(): string {
        return i18next.t('scaleTitle');
    },
    get scaleWarningTitle(): string {
        return i18next.t('scaleWarningTitle');
    },
    scenarioNamesGROUP: {
        get atm(): string {
            return i18next.t('scenarioNamesGROUP.atm');
        },
        get busyStation(): string {
            return i18next.t('scenarioNamesGROUP.busyStation');
        },
        get checkoutLine(): string {
            return i18next.t('scenarioNamesGROUP.checkoutLine');
        },
        get emergencyExit(): string {
            return i18next.t('scenarioNamesGROUP.emergencyExit');
        },
        get highway(): string {
            return i18next.t('scenarioNamesGROUP.highway');
        },
        get intersection(): string {
            return i18next.t('scenarioNamesGROUP.intersection');
        },
        get mall(): string {
            return i18next.t('scenarioNamesGROUP.mall');
        },
        get onboard(): string {
            return i18next.t('scenarioNamesGROUP.onboard');
        },
        get parking(): string {
            return i18next.t('scenarioNamesGROUP.parking');
        },
        get pedestrianZone(): string {
            return i18next.t('scenarioNamesGROUP.pedestrianZone');
        },
        get perimeter(): string {
            return i18next.t('scenarioNamesGROUP.perimeter');
        },
        get reception(): string {
            return i18next.t('scenarioNamesGROUP.reception');
        },
        get retail(): string {
            return i18next.t('scenarioNamesGROUP.retail');
        },
        get runningTrain(): string {
            return i18next.t('scenarioNamesGROUP.runningTrain');
        },
        get streetCorner(): string {
            return i18next.t('scenarioNamesGROUP.streetCorner');
        },
        get indoorLow(): string {
            return i18next.t('scenarioNamesGROUP.indoorLow');
        },
        get indoorHigh(): string {
            return i18next.t('scenarioNamesGROUP.indoorHigh');
        },
        get outdoor(): string {
            return i18next.t('scenarioNamesGROUP.outdoor');
        },
    },
    get sceneRequirements(): string {
        return i18next.t('sceneRequirements');
    },
    get scheduleAssistance(): string {
        return i18next.t('scheduleAssistance');
    },
    get schedules(): string {
        return i18next.t('schedules');
    },
    get schedulesAddSchedule(): string {
        return i18next.t('schedulesAddSchedule');
    },
    get schedulesEditSchedule(): string {
        return i18next.t('schedulesEditSchedule');
    },
    schedulesNameChangeFailedToastBody: (name: string | number): string => {
        return i18next.t('schedulesNameChangeFailedToastBody', {
            name,
        });
    },
    get schedulesNameChangeFailedToastHeader(): string {
        return i18next.t('schedulesNameChangeFailedToastHeader');
    },
    get schedulesNewScheduleName(): string {
        return i18next.t('schedulesNewScheduleName');
    },
    get schedulesSystemDefinedNamesAlways(): string {
        return i18next.t('schedulesSystemDefinedNamesAlways');
    },
    get schedulesSystemDefinedNamesOff(): string {
        return i18next.t('schedulesSystemDefinedNamesOff');
    },
    get schedulesSystemDefinedNamesOfficeHours(): string {
        return i18next.t('schedulesSystemDefinedNamesOfficeHours');
    },
    get schedulesSystemDefinedNamesUseMotionTriggerRec(): string {
        return i18next.t('schedulesSystemDefinedNamesUseMotionTriggerRec');
    },
    get scrollToSelection(): string {
        return i18next.t('scrollToSelection');
    },
    get search(): string {
        return i18next.t('search');
    },
    get searchForAccessoriesStandAlone(): string {
        return i18next.t('searchForAccessoriesStandAlone');
    },
    get searchForModel(): string {
        return i18next.t('searchForModel');
    },
    get searchResults(): string {
        return i18next.t('searchResults');
    },
    searchResultsFor: (searchString: string | number): string => {
        return i18next.t('searchResultsFor', {
            searchString,
        });
    },
    get searchResultsMissing(): string {
        return i18next.t('searchResultsMissing');
    },
    get searchResultsMissingFoundOthers(): string {
        return i18next.t('searchResultsMissingFoundOthers');
    },
    get searchResultsOthers(): string {
        return i18next.t('searchResultsOthers');
    },
    get secureBoot(): string {
        return i18next.t('secureBoot');
    },
    get selectDevice(): string {
        return i18next.t('selectDevice');
    },
    get selectedAnalogCamera(): string {
        return i18next.t('selectedAnalogCamera');
    },
    get selectedApplications(): string {
        return i18next.t('selectedApplications');
    },
    get selectedAudioVisualAlerter(): string {
        return i18next.t('selectedAudioVisualAlerter');
    },
    get selectedCamera(): string {
        return i18next.t('selectedCamera');
    },
    get selectedConnectivityDevice(): string {
        return i18next.t('selectedConnectivityDevice');
    },
    get selectedDecoder(): string {
        return i18next.t('selectedDecoder');
    },
    get selectedDockingStation(): string {
        return i18next.t('selectedDockingStation');
    },
    get selectedDoorController(): string {
        return i18next.t('selectedDoorController');
    },
    get selectedDoorstation(): string {
        return i18next.t('selectedDoorstation');
    },
    get selectedEncoder(): string {
        return i18next.t('selectedEncoder');
    },
    get selectedMainUnit(): string {
        return i18next.t('selectedMainUnit');
    },
    get selectedMapItem(): string {
        return i18next.t('selectedMapItem');
    },
    get selectedPeopleCounter(): string {
        return i18next.t('selectedPeopleCounter');
    },
    get selectedProducts(): string {
        return i18next.t('selectedProducts');
    },
    get selectedRadarDetector(): string {
        return i18next.t('selectedRadarDetector');
    },
    get selectedSensor(): string {
        return i18next.t('selectedSensor');
    },
    get selectedSensorUnit(): string {
        return i18next.t('selectedSensorUnit');
    },
    get selectedSolution(): string {
        return i18next.t('selectedSolution');
    },
    get selectedSpeaker(): string {
        return i18next.t('selectedSpeaker');
    },
    get selectedSystemController(): string {
        return i18next.t('selectedSystemController');
    },
    selectedVmsAndServerSolution: (
        ServerName: string | number,
        VmsName: string | number,
    ): string => {
        return i18next.t('selectedVmsAndServerSolution', {
            ServerName,
            VmsName,
        });
    },
    get selectFolder(): string {
        return i18next.t('selectFolder');
    },
    get selectLayers(): string {
        return i18next.t('selectLayers');
    },
    get selectLocation(): string {
        return i18next.t('selectLocation');
    },
    get selectModelToSeeCoverage(): string {
        return i18next.t('selectModelToSeeCoverage');
    },
    get selectNewSolution(): string {
        return i18next.t('selectNewSolution');
    },
    get selectOrganization(): string {
        return i18next.t('selectOrganization');
    },
    get selectPage(): string {
        return i18next.t('selectPage');
    },
    get selectPlacement(): string {
        return i18next.t('selectPlacement');
    },
    sendSalesQuoteConfirmationGROUP: {
        get body(): string {
            return i18next.t('sendSalesQuoteConfirmationGROUP.body');
        },
        get confirmButtonText(): string {
            return i18next.t('sendSalesQuoteConfirmationGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('sendSalesQuoteConfirmationGROUP.header');
        },
    },
    sensorUnitTypeGROUP: {
        get sensorunits(): string {
            return i18next.t('sensorUnitTypeGROUP.sensorunits');
        },
        get thermalsensor(): string {
            return i18next.t('sensorUnitTypeGROUP.thermalsensor');
        },
    },
    get seriousErrorMessage1(): string {
        return i18next.t('seriousErrorMessage1');
    },
    get seriousErrorMessage2(): string {
        return i18next.t('seriousErrorMessage2');
    },
    get servers(): string {
        return i18next.t('servers');
    },
    get setGeolocation(): string {
        return i18next.t('setGeolocation');
    },
    get setLocation(): string {
        return i18next.t('setLocation');
    },
    get settings(): string {
        return i18next.t('settings');
    },
    get settingsForGenericCamera(): string {
        return i18next.t('settingsForGenericCamera');
    },
    get setToCurrentLocation(): string {
        return i18next.t('setToCurrentLocation');
    },
    get share(): string {
        return i18next.t('share');
    },
    get sharedProjects(): string {
        return i18next.t('sharedProjects');
    },
    get sharedProjectsDescription(): string {
        return i18next.t('sharedProjectsDescription');
    },
    get sharedProjectsSignIn(): string {
        return i18next.t('sharedProjectsSignIn');
    },
    get showAll(): string {
        return i18next.t('showAll');
    },
    get showInFullScreen(): string {
        return i18next.t('showInFullScreen');
    },
    get signedOS(): string {
        return i18next.t('signedOS');
    },
    get signedVideo(): string {
        return i18next.t('signedVideo');
    },
    get skip(): string {
        return i18next.t('skip');
    },
    get software(): string {
        return i18next.t('software');
    },
    get solutionNotViable(): string {
        return i18next.t('solutionNotViable');
    },
    get somethingWentWrong(): string {
        return i18next.t('somethingWentWrong');
    },
    sortingGROUP: {
        get created(): string {
            return i18next.t('sortingGROUP.created');
        },
        get devices(): string {
            return i18next.t('sortingGROUP.devices');
        },
        get label(): string {
            return i18next.t('sortingGROUP.label');
        },
        get model(): string {
            return i18next.t('sortingGROUP.model');
        },
        get name(): string {
            return i18next.t('sortingGROUP.name');
        },
        get quantity(): string {
            return i18next.t('sortingGROUP.quantity');
        },
        get type(): string {
            return i18next.t('sortingGROUP.type');
        },
        get updated(): string {
            return i18next.t('sortingGROUP.updated');
        },
    },
    sortOrderGROUP: {
        get alphabetical(): string {
            return i18next.t('sortOrderGROUP.alphabetical');
        },
        get byCreation(): string {
            return i18next.t('sortOrderGROUP.byCreation');
        },
        get byFoV(): string {
            return i18next.t('sortOrderGROUP.byFoV');
        },
        get byPopularity(): string {
            return i18next.t('sortOrderGROUP.byPopularity');
        },
        get byName(): string {
            return i18next.t('sortOrderGROUP.byName');
        },
        get byVendor(): string {
            return i18next.t('sortOrderGROUP.byVendor');
        },
        get byPowerConsumption(): string {
            return i18next.t('sortOrderGROUP.byPowerConsumption');
        },
        get byPriceHighToLow(): string {
            return i18next.t('sortOrderGROUP.byPriceHighToLow');
        },
        get byPriceLowToHigh(): string {
            return i18next.t('sortOrderGROUP.byPriceLowToHigh');
        },
        get bySeries(): string {
            return i18next.t('sortOrderGROUP.bySeries');
        },
        get includeDiscontinued(): string {
            return i18next.t('sortOrderGROUP.includeDiscontinued');
        },
    },
    get soundPressureLevel(): string {
        return i18next.t('soundPressureLevel');
    },
    get speakers(): string {
        return i18next.t('speakers');
    },
    get speakerSelectorBasicSolution(): string {
        return i18next.t('speakerSelectorBasicSolution');
    },
    get speakerSelectorCalculateQuantity(): string {
        return i18next.t('speakerSelectorCalculateQuantity');
    },
    get speakerSelectorCeiling(): string {
        return i18next.t('speakerSelectorCeiling');
    },
    get speakerSelectorCoverageMessage(): string {
        return i18next.t('speakerSelectorCoverageMessage');
    },
    get speakerSelectorCoverageTitle(): string {
        return i18next.t('speakerSelectorCoverageTitle');
    },
    get speakerSelectorHowToDescriptionsCalculateArea(): string {
        return i18next.t('speakerSelectorHowToDescriptionsCalculateArea');
    },
    get speakerSelectorHowToDescriptionsCalculateLength(): string {
        return i18next.t('speakerSelectorHowToDescriptionsCalculateLength');
    },
    get speakerSelectorHowToDescriptionsDifferentHeights(): string {
        return i18next.t('speakerSelectorHowToDescriptionsDifferentHeights');
    },
    get speakerSelectorHowToDescriptionsNarrowRoomImperial(): string {
        return i18next.t('speakerSelectorHowToDescriptionsNarrowRoomImperial');
    },
    get speakerSelectorHowToDescriptionsNarrowRoomMetric(): string {
        return i18next.t('speakerSelectorHowToDescriptionsNarrowRoomMetric');
    },
    get speakerSelectorInfoMessagesInvalidModel(): string {
        return i18next.t('speakerSelectorInfoMessagesInvalidModel');
    },
    speakerSelectorInfoMessagesMaxQuantityReached: (quantity: string | number): string => {
        return i18next.t('speakerSelectorInfoMessagesMaxQuantityReached', {
            quantity,
        });
    },
    get speakerSelectorInfoMessagesModelMissing(): string {
        return i18next.t('speakerSelectorInfoMessagesModelMissing');
    },
    get speakerSelectorInfoMessagesModelPlacementMissing(): string {
        return i18next.t('speakerSelectorInfoMessagesModelPlacementMissing');
    },
    get speakerSelectorInfoMessagesModelPlacementValuesMissing(): string {
        return i18next.t('speakerSelectorInfoMessagesModelPlacementValuesMissing');
    },
    get speakerSelectorInfoMessagesModelValuesMissing(): string {
        return i18next.t('speakerSelectorInfoMessagesModelValuesMissing');
    },
    get speakerSelectorInfoMessagesPlacementMissing(): string {
        return i18next.t('speakerSelectorInfoMessagesPlacementMissing');
    },
    get speakerSelectorInfoMessagesPlacementValuesMissing(): string {
        return i18next.t('speakerSelectorInfoMessagesPlacementValuesMissing');
    },
    get speakerSelectorInfoMessagesValuesMissing(): string {
        return i18next.t('speakerSelectorInfoMessagesValuesMissing');
    },
    get speakerSelectorInstallationHeight(): string {
        return i18next.t('speakerSelectorInstallationHeight');
    },
    get speakerSelectorListeningArea(): string {
        return i18next.t('speakerSelectorListeningArea');
    },
    get speakerSelectorNewSpeaker(): string {
        return i18next.t('speakerSelectorNewSpeaker');
    },
    get speakerSelectorWall(): string {
        return i18next.t('speakerSelectorWall');
    },
    get speakerSelectorWallLength(): string {
        return i18next.t('speakerSelectorWallLength');
    },
    get specifyNewLocation(): string {
        return i18next.t('specifyNewLocation');
    },
    get standardLens(): string {
        return i18next.t('standardLens');
    },
    get storage(): string {
        return i18next.t('storage');
    },
    storageCapacity: (usedBytes: string | number, totalBytes: string | number): string => {
        return i18next.t('storageCapacity', {
            usedBytes,
            totalBytes,
        });
    },
    get storageProfile(): string {
        return i18next.t('storageProfile');
    },
    get storageUnreachable(): string {
        return i18next.t('storageUnreachable');
    },
    get storageUsed(): string {
        return i18next.t('storageUsed');
    },
    storeVideoFor: (days: string | number): string => {
        return i18next.t('storeVideoFor', {
            days,
        });
    },
    subscriptionIntervalMany: (year: string | number): string => {
        return i18next.t('subscriptionIntervalMany', {
            year,
        });
    },
    subscriptionIntervalOne: (year: string | number): string => {
        return i18next.t('subscriptionIntervalOne', {
            year,
        });
    },
    get suggestedApplications(): string {
        return i18next.t('suggestedApplications');
    },
    supportedFileTypes: (fileFormats: string | number): string => {
        return i18next.t('supportedFileTypes', {
            fileFormats,
        });
    },
    get sustainability(): string {
        return i18next.t('sustainability');
    },
    get switches(): string {
        return i18next.t('switches');
    },
    get systemAccessories(): string {
        return i18next.t('systemAccessories');
    },
    get systemComponentsVendor(): string {
        return i18next.t('systemComponentsVendor');
    },
    get systemControllers(): string {
        return i18next.t('systemControllers');
    },
    get systemMargins(): string {
        return i18next.t('systemMargins');
    },
    get systemProposal(): string {
        return i18next.t('systemProposal');
    },
    get takeSomeTime(): string {
        return i18next.t('takeSomeTime');
    },
    get targetHeight(): string {
        return i18next.t('targetHeight');
    },
    get temperatureScale(): string {
        return i18next.t('temperatureScale');
    },
    get terminals(): string {
        return i18next.t('terminals');
    },
    get termsOfUse(): string {
        return i18next.t('termsOfUse');
    },
    get textBoxes(): string {
        return i18next.t('textBoxes');
    },
    get theme(): string {
        return i18next.t('theme');
    },
    get tosUpdateMessage(): string {
        return i18next.t('tosUpdateMessage');
    },
    get tosUpdateTitle(): string {
        return i18next.t('tosUpdateTitle');
    },
    get total(): string {
        return i18next.t('total');
    },
    get totalBandwidth(): string {
        return i18next.t('totalBandwidth');
    },
    get totalMaxPower(): string {
        return i18next.t('totalMaxPower');
    },
    get totalMaxYearlyEnergy(): string {
        return i18next.t('totalMaxYearlyEnergy');
    },
    totalPowerBudget: (powerBudget: string | number): string => {
        return i18next.t('totalPowerBudget', {
            powerBudget,
        });
    },
    get totalStorage(): string {
        return i18next.t('totalStorage');
    },
    get totalTypicalPower(): string {
        return i18next.t('totalTypicalPower');
    },
    get totalTypicalYearlyEnergy(): string {
        return i18next.t('totalTypicalYearlyEnergy');
    },
    get tpm(): string {
        return i18next.t('tpm');
    },
    get typeToAddLocation(): string {
        return i18next.t('typeToAddLocation');
    },
    get typicalPower(): string {
        return i18next.t('typicalPower');
    },
    get unauthenticated(): string {
        return i18next.t('unauthenticated');
    },
    get undo(): string {
        return i18next.t('undo');
    },
    unlockProjectConfirmationGROUP: {
        get body(): string {
            return i18next.t('unlockProjectConfirmationGROUP.body');
        },
        get confirmButtonText(): string {
            return i18next.t('unlockProjectConfirmationGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('unlockProjectConfirmationGROUP.header');
        },
    },
    get unSpecifiedModel(): string {
        return i18next.t('unSpecifiedModel');
    },
    get updateAvailable(): string {
        return i18next.t('updateAvailable');
    },
    updateAvailableGROUP: {
        get body(): string {
            return i18next.t('updateAvailableGROUP.body');
        },
        get confirmButtonText(): string {
            return i18next.t('updateAvailableGROUP.confirmButtonText');
        },
        get header(): string {
            return i18next.t('updateAvailableGROUP.header');
        },
    },
    get updateAvailableText(): string {
        return i18next.t('updateAvailableText');
    },
    get updateSolution(): string {
        return i18next.t('updateSolution');
    },
    get upgrade(): string {
        return i18next.t('upgrade');
    },
    get uploadCustomCamera(): string {
        return i18next.t('uploadCustomCamera');
    },
    get uploadCustomCameraHeader(): string {
        return i18next.t('uploadCustomCameraHeader');
    },
    get uploadFailedHeader(): string {
        return i18next.t('uploadFailedHeader');
    },
    get uploadFileLoadError(): string {
        return i18next.t('uploadFileLoadError');
    },
    get uploadFileQuotaExceeded(): string {
        return i18next.t('uploadFileQuotaExceeded');
    },
    get uploadFileTooLarge(): string {
        return i18next.t('uploadFileTooLarge');
    },
    get uploadFileUnknownError(): string {
        return i18next.t('uploadFileUnknownError');
    },
    get uploadFloorPlan(): string {
        return i18next.t('uploadFloorPlan');
    },
    get uploadingFloorPlan(): string {
        return i18next.t('uploadingFloorPlan');
    },
    get uploadLogo(): string {
        return i18next.t('uploadLogo');
    },
    get uploadLogoShort(): string {
        return i18next.t('uploadLogoShort');
    },
    get url(): string {
        return i18next.t('url');
    },
    get useDHCP(): string {
        return i18next.t('useDHCP');
    },
    get useLayers(): string {
        return i18next.t('useLayers');
    },
    get userContactDetailsEditTitle(): string {
        return i18next.t('userContactDetailsEditTitle');
    },
    get userContactDetailsSave(): string {
        return i18next.t('userContactDetailsSave');
    },
    get userNewProjectName(): string {
        return i18next.t('userNewProjectName');
    },
    get userSignBackIn(): string {
        return i18next.t('userSignBackIn');
    },
    get userSignedIn(): string {
        return i18next.t('userSignedIn');
    },
    get userSignIn(): string {
        return i18next.t('userSignIn');
    },
    get userSignInReminder(): string {
        return i18next.t('userSignInReminder');
    },
    get userSignOut(): string {
        return i18next.t('userSignOut');
    },
    validUntil: (date: string | number): string => {
        return i18next.t('validUntil', {
            date,
        });
    },
    get versionLabel(): string {
        return i18next.t('versionLabel');
    },
    get verticalFieldOfView(): string {
        return i18next.t('verticalFieldOfView');
    },
    get verticalShort(): string {
        return i18next.t('verticalShort');
    },
    get video(): string {
        return i18next.t('video');
    },
    get videoPorts(): string {
        return i18next.t('videoPorts');
    },
    get viewKeyboardShortcuts(): string {
        return i18next.t('viewKeyboardShortcuts');
    },
    get viewMapsAuthErrorMessage(): string {
        return i18next.t('viewMapsAuthErrorMessage');
    },
    get viewReports(): string {
        return i18next.t('viewReports');
    },
    get visitProductPage(): string {
        return i18next.t('visitProductPage');
    },
    get waitForOptimization(): string {
        return i18next.t('waitForOptimization');
    },
    get wearables(): string {
        return i18next.t('wearables');
    },
    get welcomeSharedProjects(): string {
        return i18next.t('welcomeSharedProjects');
    },
    get welcomeTitle(): string {
        return i18next.t('welcomeTitle');
    },
    get whatsNew(): string {
        return i18next.t('whatsNew');
    },
    get willAddAsSystemAccessory(): string {
        return i18next.t('willAddAsSystemAccessory');
    },
    get willAddToRecordingSolution(): string {
        return i18next.t('willAddToRecordingSolution');
    },
    get year(): string {
        return i18next.t('year');
    },
    get yourAccessCode(): string {
        return i18next.t('yourAccessCode');
    },
    get yourOrganization(): string {
        return i18next.t('yourOrganization');
    },
    get zipProfile(): string {
        return i18next.t('zipProfile');
    },
    zipStepsDescriptionGROUP: {
        get off(): string {
            return i18next.t('zipStepsDescriptionGROUP.off');
        },
        get low(): string {
            return i18next.t('zipStepsDescriptionGROUP.low');
        },
        get medium(): string {
            return i18next.t('zipStepsDescriptionGROUP.medium');
        },
        get high(): string {
            return i18next.t('zipStepsDescriptionGROUP.high');
        },
        get max(): string {
            return i18next.t('zipStepsDescriptionGROUP.max');
        },
    },
    zipStepsGROUP: {
        get off(): string {
            return i18next.t('zipStepsGROUP.off');
        },
        get medium(): string {
            return i18next.t('zipStepsGROUP.medium');
        },
        get max(): string {
            return i18next.t('zipStepsGROUP.max');
        },
    },
    zipStepsTitleGROUP: {
        get off(): string {
            return i18next.t('zipStepsTitleGROUP.off');
        },
        get low(): string {
            return i18next.t('zipStepsTitleGROUP.low');
        },
        get medium(): string {
            return i18next.t('zipStepsTitleGROUP.medium');
        },
        get high(): string {
            return i18next.t('zipStepsTitleGROUP.high');
        },
        get max(): string {
            return i18next.t('zipStepsTitleGROUP.max');
        },
    },
    get zipStreamGlobal(): string {
        return i18next.t('zipStreamGlobal');
    },
    get zipStreamGlobalToggle(): string {
        return i18next.t('zipStreamGlobalToggle');
    },
    get zipstreamGlobalWarning(): string {
        return i18next.t('zipstreamGlobalWarning');
    },
    get zipstreamGlobalWarningHeader(): string {
        return i18next.t('zipstreamGlobalWarningHeader');
    },
    get zipstreamInfo(): string {
        return i18next.t('zipstreamInfo');
    },
    get zipStrength(): string {
        return i18next.t('zipStrength');
    },
    zipStrengthsGROUP: {
        get off(): string {
            return i18next.t('zipStrengthsGROUP.off');
        },
        get low(): string {
            return i18next.t('zipStrengthsGROUP.low');
        },
        get medium(): string {
            return i18next.t('zipStrengthsGROUP.medium');
        },
        get high(): string {
            return i18next.t('zipStrengthsGROUP.high');
        },
        get extreme(): string {
            return i18next.t('zipStrengthsGROUP.extreme');
        },
    },
};
