import { injectable } from 'inversify';
import { CreateEntityService } from '../../repositories';
import type { IPersistenceRepository } from '../../repositories';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IEntity } from '../../entities';

type EntityWithQuantity = IEntity & { quantity?: number };

/**
 * Define vendor property for custom items if undefined.
 * Add unique custom items to userCustomItemRepository.
 */
@injectable()
export class Migration31To32 extends MigrationProviderBase {
    public from: number = 31;
    public to: number = 32;

    constructor(private createEntityService: CreateEntityService) {
        super();
    }

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'customItem') {
                entity.vendor = entity.vendor ?? '';

                const alreadyAdded = await this.isDuplicate(entity, repository);

                if (!alreadyAdded) {
                    const userCustomItemEntity = this.createEntityService.create('userCustomItem', {
                        ...entity,
                        type: 'userCustomItem',
                        path: [],
                    }) as EntityWithQuantity;
                    delete userCustomItemEntity.quantity;

                    await repository.add(userCustomItemEntity);
                }
                return entity;
            }
            return entity;
        };
    }

    private isDuplicate = async (
        customItemEntity: any,
        repository: IPersistenceRepository,
    ): Promise<boolean> => {
        const itemsInDatabase = await repository.getAll('userCustomItem');

        return itemsInDatabase.some(
            (dbItem: any) =>
                dbItem.name === customItemEntity.name &&
                dbItem.partNumber === customItemEntity.partNumber &&
                dbItem.vendor === customItemEntity.vendor &&
                dbItem.category === customItemEntity.category,
        );
    };
}
