import * as React from 'react';
import type { IAutoTestable } from '../../ui-test';
import { Text } from '../../text';
import type { Colors } from 'app/styles';
import { Box } from '../../containers/box/Box.component';
import { Icon } from '../icon';
import type { Icons } from '../icon';

export interface ICircleAvatarProps extends IAutoTestable {
    /**
     * The name to make avatar from. If empty only a circle will be displayed.
     */
    name?: string;
    /**
     * Background color for the avatar.
     */
    color?: Colors;
    icon?: Icons;
}

const getInitials = (name: string): string => {
    let names = name.split(' ');
    if (names.length > 2) {
        names = names.slice(0, 2);
    }
    const initials = names.map((subName) => subName.substring(0, 1).toUpperCase());
    return initials.join('');
};

/**
 * A component that shows a circle with an avatar made from initials of name
 *
 */
export const CircleAvatar: React.FunctionComponent<ICircleAvatarProps> = ({
    name,
    color,
    icon,
}) => {
    const initials = name ? getInitials(name) : '';

    return (
        <Box
            borderRadius="circle"
            padding="halfCell"
            width={35}
            height={35}
            color={color ? color : 'blackOpacity'}
            display="flex"
            flex="none"
            alignItems="center"
            justifyContent="center"
        >
            {!icon && name && (
                <Text style="subheader" color={'grey6'}>
                    {initials}
                </Text>
            )}
            {icon && <Icon icon={icon} color="grey7" opaque />}
        </Box>
    );
};

CircleAvatar.displayName = 'CircleAvatar';
