import { convert } from 'axis-webtools-util';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Sets project customInstallationHeight if undefined
 */
@injectable()
export class Migration21To22 extends MigrationProviderBase {
    public from: number = 21;
    public to: number = 22;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'project' && entity.customInstallationHeight === undefined) {
                entity.customInstallationHeight =
                    entity.unitSystem === 'imperial' ? convert.feetToMeters(10) : 3;
            }

            return entity;
        };
    }
}
