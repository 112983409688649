import { defaultSustainabilityFilter } from 'app/core/persistence';
import type { IPiaDevice, IPiaMainUnit } from 'app/core/pia';
import { meetsSustainabilityRequirements } from './SustainabilityFilter';
import type { IMainUnitFilter } from 'app/modules/common';

export const defaultMainUnitFilter: IMainUnitFilter = {
    channels: undefined,
    twoWayAudio: false,
    WDRTechnology: false,
    ruggedizedEN50155: false,
    alarmInputsOutputs: false,
    outdoor: false,
    ...defaultSustainabilityFilter,
};

const hasForensicWDR = (piaDevice: IPiaDevice): boolean => {
    return 'WDRTechnology' in piaDevice.properties
        ? piaDevice.properties.WDRTechnology === 'Forensic WDR' ||
              piaDevice.properties.WDRTechnology === 'Forensic Capture'
        : false;
};

export class MainUnitFilter {
    public static filter(
        mainUnitFilter: IMainUnitFilter | null,
        allMainUnits: IPiaMainUnit[],
        searchFilter: string,
    ): IPiaMainUnit[] {
        const lowerCaseSearchFilter = searchFilter.toLowerCase();
        return allMainUnits.filter((mainUnit) => {
            if (!mainUnit.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (mainUnitFilter === null) {
                return true;
            }
            if (mainUnitFilter.twoWayAudio && !mainUnit.properties.twoWayAudio) {
                return false;
            }
            if (mainUnitFilter.WDRTechnology && !hasForensicWDR(mainUnit)) {
                return false;
            }
            if (mainUnitFilter.ruggedizedEN50155 && !mainUnit.properties.ruggedizedEN50155) {
                return false;
            }
            if (mainUnitFilter.alarmInputsOutputs && !mainUnit.properties.alarmInputsOutputs) {
                return false;
            }
            if (
                typeof mainUnitFilter.channels === 'number' &&
                mainUnitFilter.channels > 0 &&
                mainUnit.properties.channels !== mainUnitFilter.channels
            ) {
                return false;
            }
            if (!meetsSustainabilityRequirements(mainUnitFilter, mainUnit)) {
                return false;
            }

            return true;
        });
    }
}
