import * as React from 'react';
import { Linkable } from '../../functional';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import type { IIconButtonProps } from '../iconButton/IconButton.component';
import { IconButton } from '../iconButton/IconButton.component';

interface ILinkIconButtonProps extends IIconButtonProps, IAutoTestable {
    /**
     * In-app url to redirect to
     */
    link: string;
    /**
     * Link to an external URL
     */
    external?: boolean;
    /**
     * Disables link.
     */
    disabled?: boolean;
}

/**
 * A Button that takes a link prop
 * and uses react-router to redirect
 * the browser on click.
 *
 * Use instead of Link when you want a button.
 */
export class LinkIconButton extends React.Component<ILinkIconButtonProps> {
    public render() {
        const { link, external, ...props } = this.props;

        return (
            <Linkable link={link} external={external}>
                <IconButton data-test-id={toTestIdFormat(this.props.testId)} {...props} />
            </Linkable>
        );
    }
}
