import * as React from 'react';
import { Icon } from '../icon';
import type { IAutoTestable } from '../../ui-test';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import { css } from '@emotion/css';
import type { IExtendableComponent } from 'app/components/models';

const menuExpandButton = css`
    display: flex;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: none;
    background: ${ColorsEnum.grey3};
    color: ${ColorsEnum.grey7};
    padding: ${SpacingsEnum.half};
    width: 100%;
`;

export interface IMenuButton extends IExtendableComponent, IAutoTestable {
    buttonExpanded: boolean;
    onClick(): void;
}

export const ExpandableButton: React.FunctionComponent<IMenuButton> = ({
    buttonExpanded,
    onClick,
}) => {
    return (
        <button
            className={menuExpandButton}
            role="switch"
            aria-checked={buttonExpanded}
            onClick={() => onClick()}
        >
            <Icon icon={buttonExpanded ? 'keyboard_arrow_left' : 'keyboard_arrow_right'} />
        </button>
    );
};
