import type { Icons } from 'app/components';
import type { DeviceType } from 'app/core/persistence';
import { UnreachableCaseError } from 'axis-webtools-util';

export const getDeviceIconByType = (deviceType?: DeviceType): Icons | undefined => {
    if (!deviceType) return undefined;
    switch (deviceType) {
        case 'camera':
            return 'device';
        case 'mainUnit':
            return 'main_unit';
        case 'sensorUnit':
            return 'sensor_unit';
        case 'encoder':
            return 'encoder';
        case 'analogCamera':
            return 'analog_camera';
        case 'speaker':
            return 'speaker';
        case 'radardetector':
            return 'radar';
        case 'doorcontroller':
            return 'door_controller';
        case 'door':
            return 'door_front';
        case 'connectivitydevice':
            return 'main_unit';
        case 'pagingconsole':
            return 'pagingconsole';
        case 'alerter':
        case 'bodyWornCamera':
        case 'cameraExtension':
        case 'decoder':
        case 'dockingStation':
        case 'doorstation':
        case 'pac':
        case 'peopleCounter':
        case 'systemController':
            return undefined;
        default:
            throw new UnreachableCaseError(deviceType, 'Unknown device type');
    }
};
