import * as React from 'react';
import { useSelector } from 'react-redux';
import { getStreetModeOn, getDesiredBoundsFactory, getMapViewBoundsFactory } from '../../selectors';
import { useMount, usePrevious } from 'app/hooks';
import { useLeafletMapBounds } from '../../hooks';
import { MapsActionService } from '../../services';
import { useService } from 'app/ioc';
import type { LeafletMap } from './LeafletMap';
import { type IFloorPlanEntity } from 'app/core/persistence';
import { DEFAULT_ZOOM_LEVEL } from '../../mapConstants';
import { toLatLngBounds } from '../../utils';

interface IGeoMap {
    /** Used in device list to fit to items in leaflet */
    fitToMarkers?: boolean;
    readOnly?: boolean;
    leafletMap: LeafletMap;
    geoMap: IFloorPlanEntity;
}

/** Component that renders a google map in leaflet */
export const GeoMap: React.FC<IGeoMap> = ({ fitToMarkers, readOnly, leafletMap, geoMap }) => {
    const mapsActionService = useService(MapsActionService);
    const streetModeOn = useSelector(getStreetModeOn);
    const leafletBounds = useLeafletMapBounds(leafletMap);
    const desiredBounds = useSelector(getDesiredBoundsFactory)(geoMap._id);
    const mapViewBounds = useSelector(getMapViewBoundsFactory)(geoMap._id);
    const previousMapViewBounds = usePrevious(mapViewBounds);

    const [animating, setAnimating] = React.useState(false);
    const [initialized, setInitialized] = React.useState(false);

    useMount(
        () => {},
        () => {
            leafletMap.hideTiles();
        },
    );

    /** Runs the first time to either set view to map bounds or initialize map to default location */
    React.useEffect(() => {
        if (!initialized && !previousMapViewBounds) {
            geoMap.location && leafletMap.initializeGeoMap(streetModeOn, fitToMarkers);
            // This is the first time we set the bounds. Jump instead of fly
            if (mapViewBounds) {
                const bounds = toLatLngBounds(mapViewBounds);
                leafletMap.jumpTo(bounds);
            } else {
                geoMap.location && leafletMap.map.setView(geoMap.location, DEFAULT_ZOOM_LEVEL);
            }

            setInitialized(true);
        }
    }, [
        fitToMarkers,
        geoMap.location,
        initialized,
        leafletMap,
        mapViewBounds,
        previousMapViewBounds,
        streetModeOn,
    ]);

    /** Animates a fly to when desired bounds changes, resets to undefined when animation is complete */
    React.useEffect(() => {
        if (fitToMarkers || !desiredBounds || !initialized) return;

        // WHen desired bounds change, fly to them
        setAnimating(true);
        const bounds = toLatLngBounds(desiredBounds);

        leafletMap.flyTo(bounds).then(() => {
            setAnimating(false);
            // Clear the desired bounds after the map has finished animating
            mapsActionService.setDesiredBounds(geoMap._id, undefined);
        });
    }, [desiredBounds, fitToMarkers, geoMap._id, leafletMap, mapsActionService, initialized]);

    /** Update view bounds in the state when leaflet bounds change. This stores the updated
     bounds in the state so that they can be used when the map is opened again 
     */
    React.useEffect(() => {
        // the animating flag is used to prevent the bounds from being updated while
        // the map is animating to a new location
        if (initialized && leafletBounds && !animating && !readOnly) {
            mapsActionService.setViewBounds(geoMap._id, leafletBounds);
        }
    }, [mapsActionService, leafletBounds, geoMap._id, animating, initialized, readOnly]);

    /** Sets street map mode to satellite or roads mode */
    React.useEffect(() => {
        leafletMap.setStreetMode(streetModeOn);
    }, [leafletMap, streetModeOn]);

    return null;
};

GeoMap.displayName = 'GeoMap';
