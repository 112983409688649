import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds project.state and sets its value to the default: 'designing'
 */
@injectable()
export class Migration9To10 extends MigrationProviderBase {
    public from: number = 9;
    public to: number = 10;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'project') {
                entity.state = 'designing';
                entity.quotation = {
                    pricesByPartNumber: {},
                    customItemPrices: {},
                    header: '',
                    footer: '',
                    msrpToQuoteMargin: 0,
                };
            }

            return entity;
        };
    }
}
