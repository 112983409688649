import type { PiaCategory } from 'app/core/pia';
import type { SortOrder } from './sorting';

export type DeviceGroup =
    | 'cameras'
    | 'mainunits'
    | 'sensorunits'
    | 'encoders'
    | 'speakers'
    | 'accessControls'
    | 'wearables'
    | 'other'
    | 'twoNProducts';

export enum DeviceGroups {
    CAMERAS = 'cameras',
    MAINUNITS = 'mainunits',
    SENSORUNITS = 'sensorunits',
    ENCODERS = 'encoders',
    SPEAKERS = 'speakers',
    ACCESSCONTROLS = 'accessControls',
    WEARABLES = 'wearables',
    OTHER = 'other',
    RADARDETECTORS = 'radardetectors',
    CONNECTIVITYDEVICES = 'connectivitydevices',
    PAGINGCONSOLES = 'pagingConsoles',
    TWONPRODUCTS = 'twoNProducts',
}

export interface IDeviceGroupInfo {
    deviceGroup: DeviceGroup;
    categories: PiaCategory[];
    sortOrders: SortOrder[];
    defaultSortOrder: SortOrder;
}
