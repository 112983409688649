import * as React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { Box } from '../../containers';
import type { IAutoTestable } from '../../ui-test';
import { ColorsEnum } from 'app/styles';
import type { IWithChildren } from 'app/components/models';

const ButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 44px;
    min-width: 44px;

    padding: 0 spacing-half;

    border-width: 0;
    border-style: solid;
    border-radius: 2px;

    background-color: ${ColorsEnum.grey3};
    color: ${ColorsEnum.grey7};

    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }

    &[aria-pressed='true'] {
        background-color: ${ColorsEnum.blue};
        color: ${ColorsEnum.white};
    }
`;

const FramedStyle = css`
    padding: 0;
    border-width: 2px;
    border-color: ${ColorsEnum.transparent};

    &[aria-pressed='true'] {
        background-color: ${ColorsEnum.grey3};
        border-color: ${ColorsEnum.blue};
    }
`;

const FixedWidthStyle = css`
    min-width: 80px;
`;

export type ToggleButtonType = 'framed' | 'fixedWidth';
interface IToggleButton extends IAutoTestable, IWithChildren {
    /**
     * The id of the button
     */
    id?: string;
    /**
     * Whether the button is pressed
     */
    pressed?: boolean;
    /**
     * The type of the button
     */
    type?: ToggleButtonType;
    /**
     * Triggered on change
     */
    onChange(pressed: boolean, id?: string): void;
}

/**
 * A button that can be pressed.
 * Works like a checkbox.
 */
export class ToggleButton extends React.PureComponent<IToggleButton> {
    public render() {
        // Added wrapping Box because of IE11 bug with flexbox items do not align correctly - WTSIDESTWO-266
        return (
            <Box>
                <button
                    data-test-id={this.props.testId}
                    className={classNames(ButtonStyle, {
                        [FramedStyle]: this.props.type === 'framed',
                        [FixedWidthStyle]: this.props.type === 'fixedWidth',
                    })}
                    aria-pressed={this.props.pressed}
                    onClick={this.clickHandler}
                >
                    {this.props.children}
                </button>
            </Box>
        );
    }

    private clickHandler = () => this.props.onChange(!this.props.pressed, this.props.id);
}
