import * as React from 'react';
import * as DOMPurify from 'dompurify';
import { t } from 'app/translate';
import type { ITextProps, ITextPlaceholderProps } from 'app/components';
import { Text, TextPlaceholder, Stack } from 'app/components';
import type { PiaId } from 'app/core/pia';
import { useService } from 'app/ioc';
import { usePromise } from 'app/hooks';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getShouldShowPiaDescriptionForPiaId } from '../item';
import { DeviceDescriptionService } from 'app/core/persistence';

interface IPiaDescriptionOwnProps extends ITextProps, ITextPlaceholderProps {
    productId: PiaId;
    showErrorMessage?: boolean;
    title?: string;
    rows?: number;
}

export const PiaDescription: React.FC<IPiaDescriptionOwnProps> = (props) => {
    const { title, productId, showErrorMessage, rows } = props;
    const shouldShowPiaDescription = useSelector<IStoreState, boolean>((state) =>
        getShouldShowPiaDescriptionForPiaId(state, productId),
    );

    const deviceDescriptionService = useService(DeviceDescriptionService);
    const descriptionPromise = React.useMemo(
        () => deviceDescriptionService.getShortDescription(productId),
        [deviceDescriptionService, productId],
    );

    const { result: description, pending } = usePromise(descriptionPromise, [descriptionPromise]);

    if (!shouldShowPiaDescription) return null;

    const descriptionJsx = description ? (
        title ? (
            <Stack vertical spacing="quart">
                <Text>{title}</Text>
                <Text onlyStyle {...props}>
                    <div
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                    ></div>
                </Text>
            </Stack>
        ) : (
            <Text onlyStyle {...props}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}></div>
            </Text>
        )
    ) : null;

    const errorJsx = title ? (
        <Stack vertical spacing="quart">
            <Text>{title}</Text>
            <Text italic faded>
                {t.descriptionUnavailable}
            </Text>
        </Stack>
    ) : (
        <Text italic faded>
            {t.descriptionUnavailable}
        </Text>
    );

    return pending ? (
        <TextPlaceholder rows={rows ?? 5} />
    ) : description ? (
        descriptionJsx
    ) : showErrorMessage ? (
        errorJsx
    ) : null;
};

PiaDescription.displayName = 'PiaDescription';
