import type { IInstallationPointEntity, IPersistence } from 'app/core/persistence';

/** Gets largest horizontal FoV for an array of installation points */
export const largestHorizontalFov = (
    installationPoints: IPersistence<IInstallationPointEntity>[],
) =>
    Math.max(
        ...installationPoints.map((ip) =>
            Math.max(...ip.sensors.map((sensor) => sensor.settings.horizontalFov)),
        ),
    );
