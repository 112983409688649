import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

export const getPartnerConfig = (state: IStoreState) => state.common.partnerConfig;

export const getPartnerLogo = createSelector([getPartnerConfig], ({ loaded, logo }) =>
    loaded ? logo : undefined,
);
export const getShowAxisLogo = createSelector(
    [getPartnerConfig, getPartnerLogo],
    (partnerConfig, partnerLogo): boolean => partnerConfig.loaded && !partnerLogo,
);

export const getHeaderStyle = createSelector([getPartnerConfig], (partnerConfig) =>
    partnerConfig.loaded ? partnerConfig.style?.header : undefined,
);
