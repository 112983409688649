import type { Id, IInstallationPointModel, IMinMax } from 'app/core/persistence';
import type { ILatLng } from 'app/core/persistence/models/maps';
import type { IPiaRadarDetector } from 'app/core/pia';
import { PiaItemDetectorCategory } from 'app/core/pia';
import { intersectPolygons } from 'app/modules/common';
import { rotate, trigonometry } from 'axis-webtools-util';
import * as leaflet from 'leaflet';
import { latLngToPolygon } from '../components/map/cone/utils';
import { getCircle, polygonToLatLngs, toPolygon } from './cameraCalc';

// useCarRange was introduced in WT-5100. Link to how coexistence is described: https://help.axis.com/axis-d2110-ve-security-radar
function calculateCoexistenceRadarLatLngPolygon(
    installationPoint: IInstallationPointModel,
    useCarRange: boolean = false,
): ILatLng[][] | undefined {
    const piaRadar = installationPoint.parentPiaDevice as IPiaRadarDetector;
    if (
        !piaRadar ||
        !(
            installationPoint.radar ||
            installationPoint.parentPiaDevice?.categories.includes(
                PiaItemDetectorCategory.RADARDETECTORS,
            )
        ) ||
        !piaRadar.properties.radarHorizontalFieldOfDetection
    ) {
        return undefined;
    }

    const radarHorizontalFieldOfDetection = piaRadar.properties.radarHorizontalFieldOfDetection;
    const radarCoexistenceRadius = useCarRange
        ? piaRadar.properties.maxRadarDetectionRangeVehicle
        : piaRadar.properties.radarCoexistenceRadius;

    const horizontalAngle =
        installationPoint.radar?.target.horizontalAngle ??
        installationPoint.sensors[0].target.horizontalAngle;
    if (!radarCoexistenceRadius) {
        return undefined;
    }

    const coexistenceLine = getCircle(
        radarCoexistenceRadius,
        parseInt(radarHorizontalFieldOfDetection),
    );

    // Add 90 degrees since the cone assumes 0 is to the right (it is down in our map)
    const rotationAngleRad = trigonometry.toRadians(horizontalAngle + 90);
    const rotatedCoexistLine = coexistenceLine.map(rotate(rotationAngleRad));

    // Coexistence area
    const coexistArea = toPolygon(rotatedCoexistLine);

    return polygonToLatLngs(installationPoint.location, coexistArea);
}

/**
 * Check if the primary installation point
 * (the radar that we check the zone of, includes another radar)
 */
function doesInstallationPointsIntersect(
    primaryInstallationPoint: IInstallationPointModel,
    installationPointB: IInstallationPointModel,
    map: leaflet.Map,
): boolean {
    const latLngPolygonA = calculateCoexistenceRadarLatLngPolygon(primaryInstallationPoint);
    const latLngPolygonB = calculateCoexistenceRadarLatLngPolygon(installationPointB, true);
    if (!latLngPolygonA || !latLngPolygonB) {
        return false;
    }
    const leafletPolygonA = leaflet.polygon(latLngPolygonA);
    const leafletPolygonB = leaflet.polygon(latLngPolygonB);
    const intersectPolygon = intersectPolygons(
        latLngToPolygon(leafletPolygonA, map),
        latLngToPolygon(leafletPolygonB, map),
    );

    return intersectPolygon.regions.length > 0;
}

/**
 * Check if one radar range is inside (or is same) as another radar range
 * @param radarRangeToCheck range for the first radar
 * @param radarRange range for the second radar
 * @returns true if ranges are inside other range for some frequency
 */
function isRadarRangeInsideOtherRange(radarRangeToCheck: IMinMax, radarRange: IMinMax) {
    if (radarRangeToCheck.min >= radarRange.min && radarRangeToCheck.min <= radarRange.max) {
        return true;
    }
    if (radarRangeToCheck.max >= radarRange.min && radarRangeToCheck.max <= radarRange.max) {
        return true;
    }
    return false;
}

/**
 * Return a record of all installation points that intersects with the installation point to check
 * including the installation point that is investigated. The installation point to check has value true,
 * others false.
 */
function getIntersectingIdsForInstallationPoint(
    installationPoints: IInstallationPointModel[],
    installationPointToCheck: IInstallationPointModel,
    radarCoexistenceRadius: number,
    maxRadarDetectionRangeVehicle: number,
    map: leaflet.Map,
): Record<Id, boolean> {
    const intersectIdsRecord: Record<Id, boolean> = {};
    intersectIdsRecord[installationPointToCheck._id] = true;
    const piaRadarToCheck = installationPointToCheck.parentPiaDevice as IPiaRadarDetector;

    for (const ip of installationPoints) {
        if (ip._id === installationPointToCheck._id) {
            continue;
        }
        // check if same frequencyRange
        const currentPiaRadar = ip.parentPiaDevice as IPiaRadarDetector;
        if (
            !piaRadarToCheck.properties.radarFrequencyRange ||
            !currentPiaRadar.properties.radarFrequencyRange
        ) {
            continue;
        }
        const sameRange = isRadarRangeInsideOtherRange(
            piaRadarToCheck.properties.radarFrequencyRange,
            currentPiaRadar.properties.radarFrequencyRange,
        );

        if (!sameRange) {
            continue;
        }
        // check if inside distance (to avoid heavy intersect-operation if not needed) is this needed?
        const latLngTarget = leaflet.latLng(
            installationPointToCheck.location.lat,
            installationPointToCheck.location.lng,
        );
        const latLngToCompare = leaflet.latLng(ip.location.lat, ip.location.lng);
        const distance = latLngTarget.distanceTo(latLngToCompare);
        if (distance >= radarCoexistenceRadius + maxRadarDetectionRangeVehicle) {
            continue;
        }

        // check if intersects
        if (doesInstallationPointsIntersect(installationPointToCheck, ip, map)) {
            intersectIdsRecord[ip._id] = false;
        }
    }

    return intersectIdsRecord;
}

/**
 * Return all installationPoint id:s that are affected by coexistence mode,
 * @param installationPoints radar installation points
 * @param map leaflet map
 * @returns record where the key is the installationPoint id of affected radar,
 * and the value is a boolean indicating if the radar is the primary radar or not
 */
export function getCoexistingInstallationPointIds(
    installationPoints: IInstallationPointModel[],
    map: leaflet.Map,
): Record<Id, boolean> {
    const coexistingIds: string[] = [];
    const coexistingIdsRecord: Record<Id, boolean> = {};
    for (const installationPoint of installationPoints) {
        if (
            !(
                installationPoint.radar ||
                installationPoint.parentPiaDevice?.categories.includes(
                    PiaItemDetectorCategory.RADARDETECTORS,
                )
            )
        ) {
            continue;
        }
        const piaRadar = installationPoint.parentPiaDevice as IPiaRadarDetector;
        if (
            !piaRadar.properties.radarCoexistenceMax ||
            !piaRadar.properties.radarCoexistenceRadius ||
            installationPoints.length < piaRadar.properties.radarCoexistenceMax
        ) {
            continue;
        }

        const intersectIdsRecord = getIntersectingIdsForInstallationPoint(
            installationPoints,
            installationPoint,
            piaRadar.properties.radarCoexistenceRadius,
            piaRadar.properties.maxRadarDetectionRangeVehicle,
            map,
        );

        if (Object.keys(intersectIdsRecord).length > piaRadar.properties.radarCoexistenceMax) {
            for (const key in intersectIdsRecord) {
                if (coexistingIdsRecord[key] !== true) {
                    coexistingIdsRecord[key] = intersectIdsRecord[key];
                }
            }
            coexistingIds.push(installationPoint._id);
        }
    }

    return coexistingIdsRecord;
}
