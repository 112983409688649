import { createCachedSelector } from 're-reselect';
import { deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';
import {
    calculateFOVLimits,
    getAvailablePiaDevicesRecord,
    getCurrentProjectItems,
    getIdFromProps,
    getSelectedDeviceChosenLens,
    toCacheKey,
    toTiltAngle,
} from 'app/modules/common';
import { clamp, isEmpty } from 'lodash-es';

/**
 * Get horizontal view within bounds of camera and lens
 */
const getHorizontalFOVWithinBounds = createCachedSelector(
    [
        getIdFromProps,
        getCurrentProjectItems,
        getAvailablePiaDevicesRecord,
        getSelectedDeviceChosenLens,
    ],
    (itemId, currentProjectItems, piaDevices, selectedLens) => {
        const item = itemId ? currentProjectItems[itemId] : undefined;
        if (
            !item ||
            !item.productId ||
            !(deviceTypeCheckers.isCamera(item) || deviceTypeCheckers.isSensorUnit(item))
        ) {
            return undefined;
        }
        const piaCamera = piaDevices[item.productId] as IPiaCamera | IPiaSensorUnit;

        const lensRelation = (piaCamera?.relations ?? []).find(
            (relation) => relation.id === selectedLens?.id,
        );
        const fovLimits = calculateFOVLimits(piaCamera, lensRelation?.relationProperties, item);

        const horizontalFov =
            item.properties.camera?.filter.horizontalFov ??
            item.properties.sensorUnit?.filter.horizontalFov;

        return horizontalFov
            ? clamp(horizontalFov, fovLimits.horizontal.min, fovLimits.horizontal.max)
            : undefined;
    },
)(toCacheKey);

export const getTiltAngleForDevice = createCachedSelector(
    [
        getIdFromProps,
        getCurrentProjectItems,
        getHorizontalFOVWithinBounds,
        getAvailablePiaDevicesRecord,
        getSelectedDeviceChosenLens,
    ],
    (itemId, currentProjectItems, limitedHorizontalFov, piaDevices, selectedLens) => {
        const item = itemId ? currentProjectItems[itemId] : undefined;
        const { filter } = item?.properties.camera ?? item?.properties.sensorUnit ?? {};

        if (
            !item ||
            !filter ||
            isEmpty(filter) ||
            filter.panoramaMode !== false ||
            !(deviceTypeCheckers.isCamera(item) || deviceTypeCheckers.isSensorUnit(item))
        ) {
            return [];
        }
        const piaCamera = item.productId
            ? (piaDevices[item.productId] as IPiaCamera | IPiaSensorUnit)
            : null;

        // multisensor cameras will not display tilt angle for now (just as they will not be displayed for installation points)
        if (piaCamera?.properties.channels && piaCamera.properties.channels > 1) {
            return [];
        }

        const horizontalFieldOfView = limitedHorizontalFov ?? filter.horizontalFov;

        const itemRelations = (piaCamera?.relations ?? []).find(
            (relation) => relation.id === selectedLens?.id,
        );

        const tiltAngle = toTiltAngle(
            filter.installationHeight,
            filter.targetHeight,
            filter.distanceToTarget,
            filter.corridorFormat,
            horizontalFieldOfView,
            item,
            piaCamera,
            itemRelations?.relationProperties,
        );
        return tiltAngle ? [tiltAngle] : [];
    },
)(toCacheKey);
