import * as React from 'react';
import type { IInstallationBaseReportAccessory } from '../../models/devices';
import { Stack, Box } from 'app/components';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    getInstallationReportDevice,
    getReportAccessories,
    getReportAccessoriesForDoor,
    getReportApplication,
    getReportMounts,
    getScheduleNamesRecord,
} from '../../selectors';
import type {
    Id,
    IItemEntity,
    IPersistence,
    IProfileEntity,
    IProjectNetworkSettings,
} from 'app/core/persistence';
import {
    getCurrentProjectNetworkSettings,
    getDeviceVirtualChildren,
    getMergedProfile,
} from 'app/modules/common';
import {
    AccessoryTable,
    MountingChain,
    NetworkItem,
    ReportHeading,
    SettingsTable,
    SettingsTableBodyWorn,
} from '../common';

interface IReportItemProps extends IReportItemOwnProps {
    profile?: IPersistence<IProfileEntity> | undefined;
    virtualChildrenWithProfiles: IDeviceWithProfile[];
    mounts: IInstallationBaseReportAccessory[];
    accessories: IInstallationBaseReportAccessory[];
    applications: IInstallationBaseReportAccessory[];
    device: IInstallationBaseReportAccessory;
    scheduleNames?: Record<string, string>;
    networkSettings?: IProjectNetworkSettings;
    isBodyWorn?: boolean;
}

interface IReportItemOwnProps {
    id: Id;
    deviceQuantityIndex?: number;
    isBodyWorn?: boolean;
    isDoor?: boolean;
}

interface IDeviceWithProfile {
    device: IPersistence<IItemEntity>;
    profile: IPersistence<IProfileEntity>;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IReportItemOwnProps,
): IReportItemProps => {
    const virtualChildrenWithProfiles = getDeviceVirtualChildren(storeState, ownProps.id).reduce(
        (virtualChildren, virtualChild) => {
            const profile = getMergedProfile(storeState, virtualChild._id);
            if (!profile) return virtualChildren;
            return [
                ...virtualChildren,
                {
                    device: virtualChild,
                    profile,
                },
            ];
        },
        [] as IDeviceWithProfile[],
    );

    return {
        ...ownProps,
        mounts: getReportMounts(storeState, ownProps.id),
        accessories: ownProps.isDoor
            ? getReportAccessoriesForDoor(storeState, ownProps.id)
            : getReportAccessories(storeState, ownProps.id),
        applications: getReportApplication(storeState, ownProps.id),
        profile: getMergedProfile(storeState, ownProps.id),
        virtualChildrenWithProfiles,
        device: getInstallationReportDevice(storeState, ownProps.id),
        scheduleNames: getScheduleNamesRecord(storeState),
        networkSettings: getCurrentProjectNetworkSettings(storeState),
    };
};

class ReportItemContainer extends React.PureComponent<IReportItemProps> {
    public render() {
        const {
            mounts,
            device,
            accessories,
            profile,
            virtualChildrenWithProfiles,
            scheduleNames,
            applications,
            networkSettings,
            deviceQuantityIndex,
            isBodyWorn,
        } = this.props;

        const mountsForTable = mounts.slice(0, -1);
        const mountsForMountingChain = [device, ...mounts];

        // The item it self is always included in the chain
        const displayMountingChain = mountsForMountingChain.length > 1;
        const displayAccessoryTables = mountsForTable.length > 0 || accessories.length > 0;
        const displaySettings = profile && !!scheduleNames;
        const displayNetwork = !!networkSettings;
        const displayApplications = applications && applications.length > 0;

        return displayMountingChain ||
            displayAccessoryTables ||
            displaySettings ||
            displayApplications ||
            displayNetwork ||
            isBodyWorn ? (
            <Box paddingY="base" avoidPageBreakInside>
                <Stack spacing="panel" vertical>
                    {displayMountingChain && (
                        <>
                            <ReportHeading title={t.installationReportMounts} />
                            <MountingChain testId={device.model} mounts={mountsForMountingChain} />
                        </>
                    )}

                    {displayAccessoryTables && (
                        <Stack alignItems="start">
                            {/** Wrapped with box because Firefox doesn't like having two tables next to each other in flex */}
                            <Box flex="shrinkAndGrow">
                                <AccessoryTable
                                    testId={device.model}
                                    title={t.installationReportMountAccessories}
                                    items={mountsForTable}
                                />
                            </Box>
                            <Box flex="shrinkAndGrow">
                                <AccessoryTable
                                    testId={device.model}
                                    title={t.installationReportAccessories}
                                    items={accessories}
                                />
                            </Box>
                        </Stack>
                    )}
                    {displaySettings && (
                        <SettingsTable
                            testId={device.piaId ? device.model : 'Generic'}
                            itemId={this.props.id}
                            piaId={device.piaId}
                            profile={profile!}
                            scheduleNames={scheduleNames!}
                        />
                    )}
                    {/* //TODO:  Improve alignment and description for virtual devices' settings */}
                    {virtualChildrenWithProfiles.map((virtualChild) => (
                        <SettingsTable
                            key={virtualChild.device._id}
                            itemId={virtualChild.device._id}
                            piaId={virtualChild.device.productId}
                            profile={virtualChild.profile}
                            scheduleNames={scheduleNames!}
                        />
                    ))}
                    {isBodyWorn && (
                        <SettingsTableBodyWorn
                            deviceId={this.props.id}
                            scheduleNames={scheduleNames!}
                        />
                    )}
                    {displayNetwork && (
                        <NetworkItem
                            itemId={this.props.id}
                            deviceQuantityIndex={deviceQuantityIndex}
                        />
                    )}
                    {displayApplications && (
                        <AccessoryTable
                            title={t.installationReportApplications}
                            items={applications}
                            analyticRange={device.analyticRange}
                        />
                    )}
                </Stack>
            </Box>
        ) : null;
    }
}

export const ReportItem = connect(mapStateToProps)(ReportItemContainer);
