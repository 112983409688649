import { CategoryEnum, VendorEnum, getProductsWithPrices, toCacheKey } from 'app/modules/common';
import { createSelector } from 'reselect';
import type { QuotationSection } from '../models';
import { getSectionFromProps } from './getSectionFromProps';
import { createCachedSelector } from 're-reselect';

/** Finds out which section, if any, that should display MSRP header.
 *  If no selected product has MSRP, return undefined.
 *  Otherwise return the top most rendered section. */
const getSectionForMsrpHeader = createSelector(
    [getProductsWithPrices],
    (products): QuotationSection | undefined => {
        if (!products.some((product) => product.msrp?.price)) {
            return undefined;
        }

        if (products.some((product) => product.vendor === VendorEnum.Axis)) {
            return 'axis';
        }
        if (
            products.some((product) =>
                [VendorEnum.Other, VendorEnum.twoN, VendorEnum.Genetec].includes(product.vendor),
            )
        ) {
            return 'other';
        }
        if (products.some((product) => product.category === CategoryEnum.InstallationService)) {
            return 'installationServices';
        }
        return undefined;
    },
);

/** Checks if a specific quotation section should render MSRP header */
export const getSectionHasMsrpHeader = createCachedSelector(
    [getSectionForMsrpHeader, getSectionFromProps],
    (sectionWithMsrpHeader, sectionFromProps): boolean =>
        sectionWithMsrpHeader === sectionFromProps,
)(toCacheKey);
