import { injectable } from 'inversify';
import { UserCustomItemRepository } from '../userDataPersistence';
import type { Id, IBaseUserCustomItemEntity } from '../userDataPersistence';
import type { UserCustomItemRecord } from '../models';
import { toRecord } from './serviceUtils';
import { AppStore } from 'app/store';

@injectable()
export class UserCustomItemService {
    constructor(
        private userCustomItemRepository: UserCustomItemRepository,
        private appStore: AppStore,
    ) {}

    /**
     * Retrieves record of all user custom items from repository
     * @returns record of user custom items
     */
    public async getUserCustomItems(): Promise<UserCustomItemRecord> {
        return toRecord(await this.userCustomItemRepository.getAll(), '_id');
    }

    /**
     * Deletes a user custom item from repository
     * @param id Id of item to be deleted
     * @returns Id of the deleted item
     */
    public deleteUserCustomItem(id: Id): Promise<Id> {
        return this.userCustomItemRepository.deleteById(id);
    }

    /**
     * Adds userCustomItem to repository unless it's a duplicate of an already existing item
     * @param userCustomItem
     * @returns The result of adding it to userCustomItem repository if not a duplicate. Otherwise void.
     */
    public addUnique = async (userCustomItem: IBaseUserCustomItemEntity) => {
        const isDuplicated = await this.isDuplicate(userCustomItem);
        if (!isDuplicated) {
            return this.userCustomItemRepository.add(userCustomItem);
        }
    };

    /**
     * Checks if user already has added this custom item. Ignores quantity.
     * @param customItemEntity
     * @returns True if user already has item, otherwise false
     */
    private isDuplicate = async (customItemEntity: IBaseUserCustomItemEntity): Promise<boolean> => {
        const userCustomItems = Object.values(this.appStore.Store.getState().userCustomItems);

        return userCustomItems.some(
            (item) =>
                item.name === customItemEntity.name &&
                item.partNumber === customItemEntity.partNumber &&
                item.vendor === customItemEntity.vendor &&
                item.category === customItemEntity.category,
        );
    };
}
