import type { PiaQuery, IPiaLocation } from 'app/core/pia';
import { PiaLocationService } from 'app/core/pia';
import { injectable } from 'inversify';

@injectable()
export class LocationQueriesService {
    public all: PiaQuery<IPiaLocation>;

    constructor(private piaLocationService: PiaLocationService<IPiaLocation>) {
        this.all = this.piaLocationService.getAll();
    }

    public getByCountryCode = (countryCode: string) =>
        this.piaLocationService.getByCountryCode(countryCode);
}
