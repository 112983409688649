import * as React from 'react';
import { Text, Label } from '../../text';
import { Box } from '../../containers';
import type { IDropDownProps } from '../../ui/dropDown';
import { DropDown } from '../../ui/dropDown';
import { Icon } from '../../ui/icon';
import { DropDownMenuButton } from '../../ui/dropDownMenu';
import type { IAutoTestable } from '../../ui-test';
import { memoize } from 'lodash-es';
import { Border } from 'app/components/style';

interface ISelectProps extends IAutoTestable {
    /**
     * The available options
     */
    options: IOptionProps[];
    /**
     * The currently selected value
     */
    value: string | number;
    /**
     * Adds a label describing the select options
     */
    label?: string;
    /**
     * Disables input
     */
    disabled?: boolean;
    /**
     * The minimum width of the dropDown content
     */
    dropDownMinWidth?: number;
    /**
     * Remove the border
     */
    noBorder?: boolean;
    /**
     * Open drop down in a React portal
     */
    openInPortal?: IDropDownProps['openInPortal'];
    /**
     * Use a custom element as trigger
     */
    customTrigger?: JSX.Element;
    /**
     * Fill 100% of the available width.
     */
    fillWidth?: boolean;
    /**
     * Fill 100% of the available width also for dropdown options.
     */
    fillDropdownWidth?: boolean;
    /**
     * sets the height to 100%.
     */
    fullHeight?: boolean;
    /**
     * use no border and adds underline to the trigger
     */
    underline?: boolean;
    /**
     * Adjust width to longest option. Defaults to true.
     */
    adjustWidthToLongestOption?: boolean;
    /**
     * Triggered on change
     */
    onChange(newVal: string | number): void;
    /**
     * Use a simple arrow down icon instead of filled arrow
     */
    simpleArrowDown?: boolean;
}

export interface IOptionProps {
    /**
     * The text of the option
     */
    text: string;
    /**
     * The value of the option
     */
    value: string | number;
    /**
     * Disable the option
     */
    disabled?: boolean;
}

/**
 * Render an accessible select box using `<DropDown>`
 */
export class Select extends React.Component<ISelectProps> {
    // For some reason it does not compile without this
    public static defaultProps: Partial<ISelectProps> = {};

    public render() {
        const { options, adjustWidthToLongestOption = true } = this.props;

        const selectedOption = options.find(({ value }) => value === this.props.value);
        const triggerText = selectedOption ? selectedOption.text : '';

        const width = adjustWidthToLongestOption
            ? this.getWidestOption([...options], this.props.underline ? 14 : 12) +
              (this.props.underline ? 30 : 0)
            : undefined;

        // width is text width + 30px for the arrow down icon
        const trigger = this.props.underline ? (
            <Box
                width={width}
                maxWidth="100%"
                alignItems="stretch"
                direction="column"
                testId={this.props.testId}
            >
                <Border bottomWidth={1} color="grey5">
                    <Box display="grid" grid={{ gridTemplateColumns: '1fr auto' }}>
                        <Text large testId={this.props.testIdChild}>
                            {triggerText}
                        </Text>
                        <Icon color="grey8" icon="keyboard_arrow_down" />
                    </Box>
                </Border>
            </Box>
        ) : (
            <Box width="100%" maxWidth="100%" justifyContent="between" testId={this.props.testId}>
                {/* Max width is 100% minus down arrow icon size and padding */}
                <Box width={width} maxWidth="calc(100% - 22px)" flex="shrinkAndGrow">
                    <Text testId={this.props.testIdChild} whiteSpace="nowrap">
                        {triggerText}
                    </Text>
                </Box>
                <Box paddingLeft="half" flex="none" alignItems="center">
                    <Icon
                        icon={
                            this.props.simpleArrowDown
                                ? 'keyboard_arrow_down'
                                : 'arrow_down_special'
                        }
                        size="sm"
                    />
                </Box>
            </Box>
        );
        const focusedItemIndex = Math.max(
            options.findIndex((option) => option.value === this.props.value),
            0,
        );

        const content = options.map((option) => (
            <DropDownMenuButton
                testId={String(option.text)}
                key={option.value}
                label={option.text}
                selected={this.props.value === option.value}
                disabled={option.disabled}
                onClick={() => this.props.onChange(option.value)}
            />
        ));

        return (
            <Box
                direction="column"
                flex={this.props.fillWidth ? 'fullWidth' : undefined}
                maxWidth="100%"
            >
                {this.props.label && <Label opaque>{this.props.label}</Label>}
                <DropDown
                    testIdChild={this.props.testIdChild}
                    disabled={this.props.disabled}
                    border={
                        this.props.noBorder || this.props.customTrigger || this.props.underline
                            ? false
                            : true
                    }
                    background={this.props.customTrigger ? undefined : 'white'}
                    trigger={this.props.customTrigger || trigger}
                    contents={content}
                    openInPortal={this.props.openInPortal}
                    focusedItemIndex={focusedItemIndex}
                    fillWidth={this.props.fillWidth}
                    minWidth={this.props.dropDownMinWidth}
                    fillDropdownWidth={this.props.fillDropdownWidth}
                    fullHeight={this.props.fullHeight}
                />
            </Box>
        );
    }

    private getWidestOption = (
        optionsCopy: IOptionProps[],
        fontSize: number = 12,
        fontWeight: string = 'normal',
    ) => {
        if (optionsCopy.length === 0) return 0;

        const textWidthArray = optionsCopy.map((value) =>
            this.getTextWidth(value.text, fontSize, fontWeight),
        );
        return Math.max(...textWidthArray);
    };

    /**
     * Measure the text width
     * Re-implemented to avoid dependency to common from components
     */
    private getTextWidth(text: string, fontSize: number = 12, fontWeight = 'normal') {
        const getContext = memoize(() => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;
            ctx.font = `${fontWeight} ${fontSize}px "Open sans"`;
            return ctx;
        });
        return Math.ceil(getContext().measureText(text).width) + 5;
    }
}
