import { UspService } from './Usp.service';
import type { IPiaSensorDevice } from 'app/core/pia';
import type { IUspGroup } from '../../models';
import { cameraUsps } from './CameraUsps';

export class CameraUspService extends UspService {
    private cameraUsps: IUspGroup[] = [];

    /**
     * Returns up to 4 usps for all cameras passed in, in the following manner:
     *
     * First usp - Resolution (preferrably HDTVResolution).
     * Second usp - Top 3 characteristics for the specific camera that is NOT the 3:rd or 4:th usp (see below).
     * Third usp - Top characteristics (usp) for any camera, but NOT all cameras.
     * Fourth usp - Second best characteristics (usp) for any camera, but NOT all cameras.
     *
     * Special case for when finding only one camera:
     * Second usp - Top 3 characteristics
     * Third usp - 4th characteristics
     * Fourth usp - 5th characteristics
     */
    public getUsps(cameras: IPiaSensorDevice[]): string[][] {
        this.cameraUsps = cameraUsps();

        switch (cameras.length) {
            case 0:
                return [];
            case 1:
                return [this.getUspsForSingleCamera(cameras[0])];
            default:
                return this.getUspsForMultipleCameras(cameras);
        }
    }

    private getUspsForSingleCamera(camera: IPiaSensorDevice): string[] {
        const usps: string[] = [];
        const supportedUspTexts: string[] = this.cameraUsps.reduce((upsTexts: string[], usp) => {
            return Array.isArray(usp.condition)
                ? this.getHasValidWDR(camera, usp.condition)
                    ? upsTexts.concat(usp.text)
                    : upsTexts
                : this.getHasValidUspValue(camera, usp)
                  ? upsTexts.concat(usp.text)
                  : upsTexts;
        }, []);
        return usps.concat(
            this.getResolutionUsp(camera),
            supportedUspTexts.length > 0 ? supportedUspTexts.slice(0, 3).join(', ') : '-',
            supportedUspTexts.length > 2 ? supportedUspTexts[3] : '-',
            supportedUspTexts.length > 3 ? supportedUspTexts[4] : '-',
        );
    }

    private getUspsForMultipleCameras(cameras: IPiaSensorDevice[]): string[][] {
        const firstDifferentiatedUsp = this.getDifferentiatingUsp(cameras);
        const secondDifferentiatedUsp = this.getDifferentiatingUsp(cameras);

        return cameras.map((camera) =>
            this.getCameraUsps(camera, firstDifferentiatedUsp, secondDifferentiatedUsp),
        );
    }

    /**
     * Get an usp supported by any camera, but NOT by all cameras
     */
    private getDifferentiatingUsp(cameras: IPiaSensorDevice[]): IUspGroup | undefined {
        const uspIndex = this.cameraUsps.findIndex((usp) => {
            let numberOfValidCameras = 0;

            // Special case for WDRTechnology since multivalues with same keys
            if (Array.isArray(usp.condition)) {
                numberOfValidCameras = cameras.reduce((count, camera) => {
                    return this.getHasValidWDR(camera, usp.condition! as string[])
                        ? count + 1
                        : count;
                }, 0);
            } else {
                numberOfValidCameras = cameras.reduce((count, camera) => {
                    return this.getHasValidUspValue(camera, usp) ? count + 1 : count;
                }, 0);
            }

            if (numberOfValidCameras > 0 && numberOfValidCameras < cameras.length) {
                return true;
            }
            return false;
        });

        return uspIndex >= 0 ? this.cameraUsps.splice(uspIndex, 1)[0] : undefined;
    }

    private getHasValidWDR(camera: IPiaSensorDevice, values: string[]): boolean {
        return (
            camera.properties.WDRTechnology !== undefined &&
            values.includes(camera.properties.WDRTechnology)
        );
    }

    private getCameraUsps(
        camera: IPiaSensorDevice,
        firstDifferentiatingUsp?: IUspGroup,
        secondDifferentiatingUsp?: IUspGroup,
    ): string[] {
        const topThreeUsp = this.getTopThreeUsp(camera);
        return [
            this.getResolutionUsp(camera),
            topThreeUsp,
            this.getDifferentiatingUspValue(camera, firstDifferentiatingUsp),
            this.getDifferentiatingUspValue(camera, secondDifferentiatingUsp),
        ];
    }

    private getHasValidUspValue(camera: IPiaSensorDevice, usp: IUspGroup): boolean {
        return (
            camera.properties[usp.key] !== undefined &&
            (usp.condition
                ? camera.properties[usp.key] === usp.condition
                : camera.properties[usp.key] !== usp.nCondition)
        );
    }

    private getDifferentiatingUspValue(camera: IPiaSensorDevice, usp?: IUspGroup): string {
        if (!(usp && camera.properties[usp.key])) {
            return '-';
        }
        // Check WDR
        if (Array.isArray(usp.condition)) {
            return this.getHasValidWDR(camera, usp.condition) ? usp.text : '-';
        } else {
            return camera.properties[usp.key] === usp.condition ||
                camera.properties[usp.key] !== usp.nCondition
                ? usp.text
                : '-';
        }
    }

    private getTopThreeUsp(camera: IPiaSensorDevice): string {
        return (
            this.cameraUsps
                .filter(
                    (usp) =>
                        this.getHasValidUspValue(camera, usp) ||
                        (Array.isArray(usp.condition)
                            ? this.getHasValidWDR(camera, usp.condition)
                            : false),
                )
                .map((usp) => usp.text)
                .slice(0, 3)
                .join(', ') || '-'
        );
    }

    private getResolutionUsp(camera: IPiaSensorDevice): string {
        const hdtvResolution = this.getHDTVResolution(camera);
        const resolutionUsps = [];

        if (hdtvResolution.vertical > 0 && hdtvResolution.text) {
            resolutionUsps.push(this.getHDTVResolutionsString(hdtvResolution.text));
        }

        if (hdtvResolution.vertical < camera.properties.maxVideoResolutionVertical) {
            resolutionUsps.push(
                this.toMaxResString(
                    camera.properties.maxVideoResolutionHorizontal,
                    camera.properties.maxVideoResolutionVertical,
                ),
            );
        }

        return resolutionUsps.join(' / ');
    }

    private getHDTVResolution(camera: IPiaSensorDevice) {
        const hdtvResolution = camera.properties.HDTVResolution;
        const resolution = {
            text: hdtvResolution,
            vertical: 0,
        };

        if (hdtvResolution) {
            if (!hdtvResolution.includes('4K')) {
                const verticalRes = parseInt(hdtvResolution, 10);
                resolution.vertical = verticalRes || 0;
            } else {
                resolution.vertical = (4000 * 9) / 16;
            }
        }

        return resolution;
    }

    private getHDTVResolutionsString(resolution: string) {
        return 'HDTV ' + resolution;
    }

    private toMaxResString(horizontal: number, vertical: number) {
        const maxResStr = `${horizontal}x${vertical}`;
        switch (maxResStr) {
            case '640x480':
                return 'VGA';
            case '800x600':
                return 'SVGA';
            case '1024x768':
                return 'XVGA';
            case '704x576':
                return '4CIF';
            case '704x480':
                return '4CIF';
            case '720x576':
                return 'D1';
            case '720x480':
                return 'D1';
            case '768x576':
                return 'Extended D1';
            default:
                return Math.round((horizontal * vertical) / 100000) / 10 + ' megapixel';
        }
    }
}
