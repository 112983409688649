import React from 'react';

/**
 * Hook to determine if the mouse is hovering over an element.
 * On a touch device, the hover is triggered by a touch move event.
 */
export const useMouseHover = (ref: React.RefObject<HTMLElement>) => {
    const [isHovering, setIsHovering] = React.useState(false);

    React.useEffect(() => {
        const element = ref.current;
        if (!element) {
            return;
        }

        const onMouseEnter = () => {
            setIsHovering(true);
            window.addEventListener(
                'touchstart',
                () => setTimeout(() => setIsHovering(false), 500),
                { once: true },
            );
        };
        const onMouseLeave = () => setIsHovering(false);

        element.addEventListener('mouseover', onMouseEnter);
        element.addEventListener('touchmove', onMouseEnter);
        element.addEventListener('mouseout', onMouseLeave);

        return () => {
            element.removeEventListener('mouseover', onMouseEnter);
            element.removeEventListener('mouseout', onMouseLeave);
            element.removeEventListener('touchmove', onMouseEnter);
        };
    }, [ref]);

    return isHovering;
};
