import { injectable } from 'inversify';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';

@injectable()
export class PiaDevicesService {
    public constructor(private piaItemService: PiaItemService<IPiaItem>) {}

    public getPiaDevices(): IPiaItem[] {
        return this.piaItemService.getAll().toList();
    }
}
