import * as L from 'leaflet';
import { ColorsEnum } from 'app/styles';
import type { Icons } from 'app/components';
import { css } from '@emotion/css';
import { t } from 'app/translate';

import type { IDrawOptions } from './Models';
import { EventsEnum } from './Events';
import { PolylineHandler } from './PolylineHandler';
import { EditHandler } from './EditHandler';
import { DeleteHandler } from './DeleteHandler';

const mapButtonClass = css`
    font-family: 'axis-wt-icons';
    font-weight: 400;
    font-size: 24px;
    position: relative;
    cursor: pointer;
`;

const actionButtonContainerClass = css`
    position: absolute;
    top: 0px;
    right: 30px;
    display: flex;
    flex-direction: row-reverse;

    [dir='rtl'] & {
        right: 0px;
        left: 54px;
    }
`;

const actionButtonClass = css`
    font: 12px 'Open sans';
    line-height: 30px;
    height: 30px;
    background: ${ColorsEnum.blue};
    color: ${ColorsEnum.white};
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    border-left: 1px solid ${ColorsEnum.blue3};
    transition: background 150ms ease-in-out;

    &:hover {
        background: ${ColorsEnum.blue3};
    }

    &:last-child {
        border-radius: 5px 0px 0px 5px;
        border-left: 0;
    }
`;

interface IAcionsButtonDefinition {
    text: string;
    callback: (event: Event) => void;
    dataTestId?: string;
}

export class DrawControls extends L.Control {
    private map?: L.Map;

    private polylineHandler!: PolylineHandler;
    private editHandler!: EditHandler;
    private deleteHandler!: DeleteHandler;

    private polylineButton!: HTMLElement;
    private editButton!: HTMLElement;
    private deleteButton!: HTMLElement;
    private polylineActionButtons!: HTMLElement;
    private editActionButtons!: HTMLElement;
    private deleteActionButtons!: HTMLElement;

    constructor(public options: IDrawOptions) {
        super(options);
    }

    public onAdd(map: L.Map) {
        this.map = map;

        this.polylineHandler = new PolylineHandler(this.map);
        this.editHandler = new EditHandler(this.map, this.options);
        this.deleteHandler = new DeleteHandler(this.map, this.options);

        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');

        this.polylineButton = this.createButton(container, 'line_tool', t.leafletButtonsPolyline);
        this.polylineActionButtons = this.createActionButtons(this.polylineButton, [
            {
                text: t.leafletFinishText,
                callback: this.onFinishPolygon,
                dataTestId: 'finish_blocker',
            },
        ]);

        this.editButton = this.createButton(
            container,
            'edit',
            t.leafletEditLine,
            !this.hasBlockers(),
            'btn_edit_blockers',
        );
        this.editActionButtons = this.createActionButtons(this.editButton, [
            {
                text: t.leafletActionsCancelText,
                callback: this.onCancelEdit,
            },
            {
                text: t.leafletFinishText,
                callback: this.onFinishEdit,
                dataTestId: 'finish_edit_blockers',
            },
        ]);

        this.deleteButton = this.createButton(
            container,
            'delete_small',
            t.leafletDelete,
            !this.hasBlockers(),
            'btn_delete_blockers',
        );
        this.deleteActionButtons = this.createActionButtons(this.deleteButton, [
            {
                text: t.leafletActionsCancelText,
                callback: this.onCancelDelete,
            },
            {
                text: t.leafletFinishText,
                callback: this.onFinishDelete,
            },
            {
                text: t.leafletActionsClearAllText,
                callback: this.onDeleteAll,
                dataTestId: 'delete_all_blockers',
            },
        ]);

        L.DomEvent.on(this.polylineButton, 'click', this.onAddPolygon, this);
        if (this.hasBlockers()) {
            L.DomEvent.on(this.editButton, 'click', this.onEdit, this);
            L.DomEvent.on(this.deleteButton, 'click', this.onDelete, this);
        }

        this.map.on(EventsEnum.CreateStop, this.hideActionButtons, this);
        this.map.on(EventsEnum.EditStop, this.hideActionButtons, this);
        this.map.on(EventsEnum.DeleteStop, this.hideActionButtons, this);

        this.options.edit.featureGroup.on('layeradd', this.toggleEditAndCancelBtn, this);
        this.options.edit.featureGroup.on('layerremove', this.toggleEditAndCancelBtn, this);

        return container;
    }

    public onRemove() {
        L.DomEvent.off(this.polylineButton, 'click', this.onAddPolygon, this);
        L.DomEvent.off(this.editButton, 'click', this.onEdit, this);
        L.DomEvent.off(this.deleteButton, 'click', this.onDelete, this);

        this.map?.off(EventsEnum.CreateStop, this.hideActionButtons, this);
        this.map?.off(EventsEnum.EditStop, this.hideActionButtons, this);
        this.map?.off(EventsEnum.DeleteStop, this.hideActionButtons, this);

        this.options.edit.featureGroup.off('layeradd', this.toggleEditAndCancelBtn, this);
        this.options.edit.featureGroup.off('layerremove', this.toggleEditAndCancelBtn, this);
        // Cancel edit mode when changing floor plan
        this.disable();
    }

    public disable() {
        this.polylineHandler.disable();
        this.editHandler.disable();
        this.deleteHandler.disable();
    }

    public disableDrawButtons() {
        this.deleteButton.className = `${mapButtonClass} leaflet-disabled`;
        this.editButton.className = `${mapButtonClass} leaflet-disabled`;
        this.polylineButton.className = `${mapButtonClass} leaflet-disabled`;
    }

    /**
     * Disable edit and delete buttons if blockers are missing
     */
    private toggleEditAndCancelBtn() {
        if (this.hasBlockers()) {
            L.DomEvent.on(this.deleteButton, 'click', this.onDelete, this);
            L.DomEvent.on(this.editButton, 'click', this.onEdit, this);
            this.deleteButton.className = `${mapButtonClass}`;
            this.editButton.className = `${mapButtonClass}`;
        } else {
            L.DomEvent.off(this.deleteButton, 'click', this.onDelete, this);
            L.DomEvent.off(this.editButton, 'click', this.onEdit, this);
            this.deleteButton.className = `${mapButtonClass} leaflet-disabled`;
            this.editButton.className = `${mapButtonClass} leaflet-disabled`;
        }
    }

    private hasBlockers() {
        const blockerLayers = this.options.edit.featureGroup.getLayers() as Array<
            L.Polygon | L.Polyline
        >;
        const blockerLatLngs = blockerLayers.map(
            (blocker) => blocker.getLatLngs() as L.LatLng[] | L.LatLng[][],
        );

        return blockerLatLngs.some((latLng) => latLng.length > 0);
    }

    private hideActionButtons() {
        this.polylineActionButtons.style.visibility = 'hidden';
        this.editActionButtons.style.visibility = 'hidden';
        this.deleteActionButtons.style.visibility = 'hidden';
    }

    private createButton(
        container: HTMLElement,
        icon: Icons,
        text: string,
        disable?: boolean,
        dataTestId?: string,
    ) {
        const button = L.DomUtil.create(
            'a',
            `${mapButtonClass} ${disable && 'leaflet-disabled'}`,
            container,
        );

        button.textContent = icon;
        button.setAttribute('role', 'button');
        button.setAttribute('title', text);
        dataTestId && button.setAttribute('data-test-id', dataTestId);

        return button;
    }

    private createActionButtons(container: HTMLElement, actions: IAcionsButtonDefinition[]) {
        const actionButtons = L.DomUtil.create('div', actionButtonContainerClass, container);

        actions.forEach((action) => {
            const actionButton = L.DomUtil.create('div', actionButtonClass, actionButtons);
            actionButton.textContent = action.text;
            actionButton.setAttribute('role', 'button');
            action.dataTestId && actionButton.setAttribute('data-test-id', action.dataTestId);
            L.DomEvent.on(actionButton, 'click', action.callback, this);
        });

        actionButtons.style.visibility = 'hidden';
        return actionButtons;
    }

    private onAddPolygon(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.disable();
        this.polylineHandler.enable();
        this.polylineActionButtons.style.visibility = 'visible';
    }

    private onFinishPolygon(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.polylineHandler.save();
        this.polylineHandler.disable();
    }

    private onEdit(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.disable();
        this.editHandler.enable();
        this.editActionButtons.style.visibility = 'visible';
    }

    private onFinishEdit(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.editHandler.save();
        this.editHandler.disable();
    }

    private onCancelEdit(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.editHandler.disable();
    }

    private onDelete(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.disable();
        this.deleteHandler.enable();
        this.deleteActionButtons.style.visibility = 'visible';
    }

    private onFinishDelete(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.deleteHandler.save();
        this.deleteHandler.disable();
    }

    private onCancelDelete(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.deleteHandler.cancel();
        this.deleteHandler.disable();
    }

    private onDeleteAll(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);

        this.deleteHandler.deleteAll();
        this.deleteHandler.disable();
    }
}

export const createDrawControls = function (options: IDrawOptions) {
    return new DrawControls(options);
};
