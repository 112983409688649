import { injectable } from 'inversify';
import type { IPersistenceConfigurationService } from './IPersistenceConfigurationService';
import { LocalPersistenceConfigurationService } from './LocalPersistenceConfiguration.service';
import { PersistenceConfigurationService } from './PersistenceConfiguration.service';
import { ProjectDbOrigin } from '../userDataPersistence';

@injectable()
export class PersistenceConfigurationServiceFactory {
    constructor(
        private persistenceConfigurationService: PersistenceConfigurationService,
        private localPersistenceConfigurationService: LocalPersistenceConfigurationService,
    ) {}

    public createPersistenceConfigurationService(
        projectDbOrigin: ProjectDbOrigin,
    ): IPersistenceConfigurationService {
        switch (projectDbOrigin) {
            case ProjectDbOrigin.asdLocalUserData:
                return this.localPersistenceConfigurationService;
            case ProjectDbOrigin.asdUserData:
            default:
                return this.persistenceConfigurationService;
        }
    }
}
