import * as React from 'react';
import { connect } from 'react-redux';
import { getCurrentProject, getProfile, StorageSettingsComponent } from 'app/modules/common';
import type { Id, IPersistence, IProfileEntity, IProjectEntity } from 'app/core/persistence';
import { ProfileService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import type { IZipstreamSettingsOwnProps } from './ZipstreamSettings.container';

interface IStorageSettingsOwnProps {
    profileId: Id;
}

interface IStorageSettingsProps extends IStorageSettingsOwnProps {
    profile?: IPersistence<IProfileEntity>;
    project: IPersistence<IProjectEntity>;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IZipstreamSettingsOwnProps,
): IStorageSettingsProps => {
    const profile = getProfile(storeState, ownProps.profileId);
    const project = getCurrentProject(storeState);

    return {
        profile,
        project,
        ...ownProps,
    };
};
class StorageSettingsContainer extends React.Component<IStorageSettingsProps> {
    private profileService: ProfileService;
    constructor(props: IStorageSettingsProps) {
        super(props);
        this.profileService = ServiceLocator.get(ProfileService);
    }

    public render() {
        return this.props.profile ? (
            <StorageSettingsComponent
                profileSetting={this.props.profile.storage}
                projectSettingRetentionTime={this.props.project.recordingRetentionTimeInDays}
                onChangeRetentionTime={this.onRetentionTime}
                onChangeUseProjectSetting={this.onChangeUseProjectSetting}
            />
        ) : null;
    }

    private onRetentionTime = (newRetentionTime: number) => {
        if (this.props.profile) {
            this.props.profile.storage.retentionTime = newRetentionTime;
            this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private onChangeUseProjectSetting = (useProjectSetting: boolean) => {
        const { profile } = this.props;
        if (profile) {
            profile.storage.useProjectSetting = useProjectSetting;
            this.profileService.updateProfileEntity(profile);
        }
    };
}

export const StorageSettings = connect(mapStateToProps)(StorageSettingsContainer);
