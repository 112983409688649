import * as React from 'react';
import { css, cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { useResizeObserver } from 'app/hooks';
import type { IAutoTestable } from 'app/components';

interface IEditableTextProps extends IAutoTestable {
    value: string;
    placeholder: string;
    maxLength: number;
    autoFillToContainer?: boolean;
    changeCriteria?: 'blur' | 'key';
    /** Forces update to trigger on blur even if value hasn't changed */
    forceUpdateOnBlur?: boolean;
    onChange(newValue: string): void;
    required?: boolean;
}

const fillStyle = css`
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

const inputStyle = css`
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background: transparent;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;

    ::placeholder {
        color: ${ColorsEnum.grey5};
    }
`;

const containerStyle = css`
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
`;

const highlightStyle = css`
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    white-space: pre;
    color: transparent;
    background-color: transparent;

    ::after {
        content: '';
        position: absolute;
        left: 2px;
        right: 2px;
        top: 2.3ex;
        bottom: 3px;
        border-bottom: 2px dotted ${ColorsEnum.blue};
        opacity: 0.18;
        transition: all 75ms;
    }
`;

const activeStyle = css`
    ::after {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-bottom: 2px solid ${ColorsEnum.blue};
        opacity: 0.75;
    }
`;

/**
 * The margin (in pixels) between the EditableText
 * and its container before the EditableText will start to fill
 * the container instead of expanding.
 */
const containerMargin = 50;

/**
 * Increases the size of the calculated text size to make it work in IE.
 */
const textWidthAddition = 20;

/**
 * An in-place editable text that expands and
 * contracts with the value. Stops expanding when
 * the width becomes larger than the containing element.
 */
export const EditableText: React.FunctionComponent<IEditableTextProps> = ({
    value,
    placeholder,
    maxLength,
    changeCriteria,
    autoFillToContainer = true,
    testId,
    forceUpdateOnBlur = false,
    required = false,
    onChange: onChangeCallback,
}) => {
    const [shouldFillContainer, setShouldFillContainer] = React.useState<boolean>(false);
    const [currentValue, setCurrentValue] = React.useState<string>(value);
    const [inputWidth, setInputWidth] = React.useState<number>(0);
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const updateInputWidth = React.useCallback(
        (textEl: HTMLDivElement | null) => {
            // Width of the element containing the EditableText component
            const containerParentWidth =
                textEl?.parentElement?.parentElement?.getBoundingClientRect().width;
            // Width of the written text (aka. the text inside the input)
            const textWidth = textEl?.scrollWidth
                ? textEl?.scrollWidth + textWidthAddition
                : undefined;
            // Do nothing in case we get no width back
            if (!textWidth) {
                return;
            }
            // Set the width of the input element to the width of the text
            setInputWidth(textWidth);
            // If the text is longer than the containing element we switch the
            // EditableText to fill the entire container.
            if (autoFillToContainer) {
                setShouldFillContainer(
                    containerParentWidth
                        ? textWidth >= containerParentWidth - containerMargin
                        : false,
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentValue],
    );

    // Update the internal current value to new incoming value prop if they differ
    React.useEffect(
        () => {
            currentValue.trim().localeCompare(value.trim()) !== 0 || changeCriteria === 'blur'
                ? setCurrentValue(value)
                : undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value],
    );

    const { ref } = useResizeObserver<HTMLDivElement>({
        onResize: ({ width: containerWidth }) => {
            if (containerWidth && autoFillToContainer) {
                const widthDiff = containerWidth - inputWidth;
                if (widthDiff === 0) return;
                if (widthDiff > 0 && widthDiff <= containerMargin && !shouldFillContainer) {
                    setShouldFillContainer(true);

                    return;
                }
                if (widthDiff > containerMargin && shouldFillContainer) {
                    setShouldFillContainer(false);
                    return;
                }
            }
        },
    });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value ?? '';
        setCurrentValue(newValue);
        if (currentValue.trim() !== newValue.trim() && changeCriteria !== 'blur') {
            onChangeCallback(newValue.trim());
        }
    };

    const onFocus = () => {
        setIsActive(true);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            setCurrentValue(value);
            onChangeCallback(value.trim());
        }

        // Trigger update on enter or tab
        if (event.key === 'Enter' || event.key === 'Tab') {
            if (required && !currentValue.trim()) {
                setCurrentValue(value);
            } else {
                setCurrentValue(currentValue.trim());
                onChangeCallback(currentValue.trim());
            }
        }
    };

    const onBlur = () => {
        setIsActive(false);
        if (required && !currentValue.trim()) {
            setCurrentValue(value.trim());
            return;
        }

        if (changeCriteria === 'blur') {
            setCurrentValue(currentValue.trim());
            onChangeCallback(currentValue.trim());
            forceUpdateOnBlur && setCurrentValue(value);
        }
    };

    return (
        <div
            className={cx(
                containerStyle,
                shouldFillContainer &&
                    css`
                        display: flex;
                        flex: 1 1 auto;
                    `,
            )}
            ref={ref}
        >
            <mark
                className={cx(
                    highlightStyle,
                    isActive && activeStyle,
                    shouldFillContainer && fillStyle,
                )}
                ref={updateInputWidth}
            >
                {currentValue || placeholder}
            </mark>
            <input
                title={shouldFillContainer ? currentValue : ''}
                maxLength={maxLength}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                type="text"
                data-test-id={testId}
                value={currentValue}
                placeholder={placeholder}
                className={cx(
                    inputStyle,
                    shouldFillContainer
                        ? fillStyle
                        : css`
                              width: ${inputWidth}px;
                          `,
                )}
                spellCheck={false}
            />
        </div>
    );
};

EditableText.displayName = 'EditableText';
