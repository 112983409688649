import { createCachedSelector } from 're-reselect';
import { getShouldShow2NSelector, getVendor, toCacheKey } from 'app/modules/common';

/**
 * If 2N products have been added to a US or Canadian project
 * we don't want to allow users to change them to other models
 * if they've changed the project country afterwards.
 * They should still be visible in the device list though, so this
 * selector is only used to hide their change buttons.
 */
export const getShouldHideChangeDevice = createCachedSelector(
    [getVendor, getShouldShow2NSelector],
    (vendor, isUsOrCanadianProject) => {
        return vendor === '2N' && !isUsOrCanadianProject;
    },
)(toCacheKey);
