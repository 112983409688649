import { injectable } from 'inversify';
import type { Id } from 'app/core/persistence';
import { ProjectService } from 'app/core/persistence';
import type { IPiaLocation } from 'app/core/pia';
import { PiaLocationService } from 'app/core/pia';
import { Frequency } from '../../models';

@injectable()
export class ProjectFrequencyService {
    constructor(
        private projectService: ProjectService,
        private piaLocationService: PiaLocationService<IPiaLocation>,
    ) {}

    public async getFrequency(projectId: Id) {
        const project = await this.projectService.get(projectId);

        if (!project) {
            throw new Error(`Project ${projectId} was not found`);
        }

        const location = this.piaLocationService.get(project.installationPiaLocationId).first();

        if (!location) {
            throw new Error(`Location ${project.installationPiaLocationId} was not found`);
        }

        return location.frequency60Hz ? Frequency.Hz60 : Frequency.Hz50;
    }
}
