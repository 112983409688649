import React from 'react';
import { ServiceLocator } from 'app/ioc';
import type { IPiaRadarDetector } from 'app/core/pia';
import { rotate, trigonometry } from 'axis-webtools-util';
import type { IInstallationPointModel } from 'app/core/persistence';
import { LeafletItemFactory } from '../../../services';
import { getCircle, polygonToLatLngs, toPolygon } from '../../../utils';
import type { LeafletMap } from '../LeafletMap';

interface IRadarCoexistenceZoneProps {
    map: LeafletMap;
    installationPoint: IInstallationPointModel;
    rotationAngle: number;
}

export const RadarCoexistenceZone: React.FC<IRadarCoexistenceZoneProps> = ({
    map,
    installationPoint,
    rotationAngle,
}) => {
    const [factory] = React.useState(ServiceLocator.get(LeafletItemFactory));
    const [coexistencePolygon] = React.useState(factory.createDashedPolygon([], 'red', '3,10', 3));
    const [coexistenceArea] = React.useState(() => {
        const piaRadar = installationPoint.parentPiaDevice as IPiaRadarDetector;
        if (!piaRadar) return undefined;
        const { radarHorizontalFieldOfDetection, radarCoexistenceRadius } = piaRadar.properties;
        return getCircle(radarCoexistenceRadius, parseInt(radarHorizontalFieldOfDetection));
    });

    React.useMemo(() => {
        if (coexistenceArea) {
            const rotationAngleRad = trigonometry.toRadians(rotationAngle + 90);
            const rotatedCoexistenceArea = coexistenceArea.map(rotate(rotationAngleRad));
            const latLngs = polygonToLatLngs(
                installationPoint.location,
                toPolygon(rotatedCoexistenceArea),
            );
            coexistencePolygon.setLatLngs(latLngs);
        }
    }, [coexistenceArea, rotationAngle, installationPoint.location, coexistencePolygon]);

    React.useEffect(() => {
        coexistencePolygon.addTo(map.map);

        return () => {
            coexistencePolygon.removeFrom(map.map);
        };
    }, [coexistencePolygon, map.map]);

    return null;
};
