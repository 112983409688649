import type { IPiaCameraProperties } from 'app/core/pia';
import type { ICustomCameraPropertiesEntity } from '../userDataPersistence';

export function getCustomCameraProperties(
    customCamera: ICustomCameraPropertiesEntity,
): IPiaCameraProperties {
    return {
        minHorizontalFOV: customCamera?.horizontalFovMin,
        minVerticalFOV: 0,
        maxHorizontalFOV: customCamera?.horizontalFovMax,
        maxVerticalFOV: 0,
        canChangePanoramaMode: false,
        defaultPanoramaMode: 'horizontal',
        maxFPS50Hz: 0,
        maxFPS60Hz: 0,
        maxVideoResolutionVertical: customCamera.resolutionVertical,
        maxVideoResolutionHorizontal: customCamera.resolutionHorizontal,
        vendor: 'Other',
    };
}
