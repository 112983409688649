import { Module } from 'app/ioc';
import { DashboardActionService } from './services';
import { dashboardReducer } from './state';

@Module({
    binder: (binder) => {
        binder.bindService(DashboardActionService);
    },
    reducers: {
        dashboard: dashboardReducer,
    },
})
export class DashboardModule {}
