import type { PiaCategory } from 'app/core/pia';
import {
    PiaItemCameraCategory,
    PiaItemEncoderCategory,
    PiaItemMainUnitCategory,
    PiaItemSensorUnitCategory,
    PiaItemSpeakerCategory,
    PiaItemWearablesCategory,
    PiaItemPacCategory,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemPeopleCounterCategory,
    PiaItemAlerterCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from 'app/core/pia';
import { t } from 'app/translate';

export const getNewDeviceNameFromCategory = (piaCategory: PiaCategory | undefined) => {
    switch (piaCategory) {
        case PiaItemCameraCategory.THERMAL:
        case PiaItemCameraCategory.PTZ:
        case PiaItemCameraCategory.FIXEDDOME:
        case PiaItemCameraCategory.FIXED:
        case PiaItemCameraCategory.CAMERAEX:
        case PiaItemCameraCategory.COMPLETEMODULAR:
            return t.cameraSelectorNewCamera;

        case PiaItemEncoderCategory.ENCODER:
            return t.encoderSelectorNewEncoder;

        case PiaItemMainUnitCategory.MAINUNIT:
            return t.mainUnitSelectorNewMainUnit;

        case PiaItemSensorUnitCategory.SENSORUNIT:
        case PiaItemSensorUnitCategory.THERMALSENSOR:
            return t.cameraSelectorNewSensorUnit;

        case PiaItemSpeakerCategory.SPEAKER:
            return t.speakerSelectorNewSpeaker;

        case PiaItemWearablesCategory.CAMERAEXTENSIONS:
            return t.newCameraExtension;

        case PiaItemWearablesCategory.CAMERAS:
            return t.newBodyWornCamera;

        case PiaItemWearablesCategory.CONTROLLER:
            return t.newSystemController;

        case PiaItemWearablesCategory.DOCKING:
            return t.newDockingStation;

        case PiaItemPacCategory.DOORCONTROLLERS:
            return t.newDoorController;

        case PiaItemPacCategory.RELAYEXPMODULES:
            return t.newExpansionModule;

        case PiaItemPacCategory.IORELAYS:
            return t.newIORelay;

        case PiaItemPacCategory.ACCESSSERVER:
            return t.newAccessServer;

        case PiaItemPacCategory.ANSWERINGUNIT:
            return t.newAnsweringUnit;

        case PiaItemPacCategory.NETWORKREADER:
            return t.newNetworkReader;

        case PiaItemPacCategory.DOORSTATIONS:
            return t.newIntercom;

        case PiaItemDecoderCategory.DECODER:
            return t.newDecoder;

        case PiaItemDetectorCategory.RADARDETECTORS:
            return t.newRadar;

        case PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES:
            return t.newConnectivityDevice;

        case PiaItemPeopleCounterCategory.PEOPLECOUNTERS:
            return t.newPeopleCounter;

        case PiaItemAlerterCategory.ALERTERS:
            return t.newAudioVisualAlerter;

        case PiaItemPagingConsoleCategory.PAGINGCONSOLE:
            return t.newPagingConsole;

        default:
            return t.newDevice;
    }
};
