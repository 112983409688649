import type { LanguageCode } from 'app/utils';
import { languageCodeToTextLanguage } from 'app/utils';
import i18next from 'i18next';
import { injectable } from 'inversify';

/** Service for interacting with i18Next */
@injectable()
export class LanguageService {
    constructor() {}
    /** Gets currently used language as LanguageCode. I.E. "en" for English. */
    public getLanguageCode(): string {
        const selectedLanguage = i18next.language as LanguageCode;
        const languageCode = languageCodeToTextLanguage[selectedLanguage];
        return languageCode;
    }
}
