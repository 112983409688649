import * as L from 'leaflet';
import { css } from '@emotion/css';

import { ColorsEnum } from 'app/styles';
import { EventsEnum } from './Events';
import { KeysEnum } from './Constants';
import type { IDrawOptions } from './Models';

const pointStyle = css`
    background-color: ${ColorsEnum.white};
    border: 1px solid ${ColorsEnum.black};
    box-sizing: border-box;
    outline: none;
`;

const pointIcon = new L.DivIcon({
    html: `<div>`,
    className: pointStyle,
    iconSize: [20, 20],
});

const getLatLngs = (polyline: L.Polyline): L.LatLng[] => {
    const latLngs = polyline.getLatLngs();
    if (Array.isArray(latLngs[0])) {
        // we only handle one-dimensional polylines. Should we encounter anything else
        // return an empty array
        return [];
    } else {
        return [...latLngs] as L.LatLng[];
    }
};

export class EditHandler extends L.Handler {
    private color? = '';
    private latLngs: L.LatLng[][] = [];
    private markers: L.Marker[] = [];
    private polylines: L.Polyline[] = [];
    private markerMap = new Map<L.Marker, { latLngs: L.LatLng[]; indexes: number[] }>();
    private midMarkers: L.Marker[][] = [];

    constructor(
        private map: L.Map,
        private options: IDrawOptions,
    ) {
        super(map);
    }

    public addHooks() {
        const container = this.map.getContainer();
        const featureGroup = this.options.edit.featureGroup;
        const layers = featureGroup.getLayers() as L.Polyline[];

        featureGroup.removeFrom(this.map);

        L.DomEvent.on(container, 'keyup', this.onKeyUp, this);
        layers.forEach((layer) => {
            if (layer instanceof L.Polyline) {
                this.latLngs.push(getLatLngs(layer));
            }
        });

        // get color from passed in feature group
        this.color = layers[0]?.options.color;

        this.create();

        this.map.fire(EventsEnum.EditStart);
    }

    public removeHooks() {
        const container = this.map.getContainer();

        this.options.edit.featureGroup.addTo(this.map);

        L.DomEvent.off(container, 'keyup', this.onKeyUp, this);

        // remove all edit-mode markers
        this.markers.forEach((marker) => {
            marker.removeFrom(this.map);
            marker.off();
        });
        this.markers = [];

        // remove all edit-mode polylines
        this.polylines.forEach((polyline) => polyline.removeFrom(this.map));
        this.polylines = [];

        // remove all edit-mode mid-markers
        this.midMarkers.forEach((latLngs) =>
            latLngs.forEach((marker) => {
                marker.removeFrom(this.map);
                marker.off();
            }),
        );
        this.midMarkers = [];

        this.markerMap.clear();
        this.latLngs = [];

        this.map.fire(EventsEnum.EditStop);
    }

    public save() {
        const layers = this.options.edit.featureGroup.getLayers() as L.Polyline[];
        layers.forEach((layer, i) => {
            const latLngs = this.polylines[i].getLatLngs();
            layer.setLatLngs(latLngs);
        });

        this.options.edit.featureGroup.addTo(this.map);
        this.map.fire(EventsEnum.Edited);
    }

    // Add edit-mode polygons and -markers to map. If a current marker is passed in,
    // it won't be recreated. This is used when dragging a marker.
    private create(current?: L.Marker) {
        this.markers.forEach((marker) => marker !== current && marker.removeFrom(this.map));
        this.markers = [];
        this.polylines.forEach((polyline) => polyline.removeFrom(this.map));
        this.polylines = [];
        this.midMarkers.forEach((latLngs) =>
            latLngs.forEach((marker) => marker !== current && marker.removeFrom(this.map)),
        );
        this.midMarkers = [];
        this.markerMap.clear();

        this.latLngs.forEach((latLngs, i) => {
            this.midMarkers.push([]);
            const polyline = L.polyline(
                latLngs.map(({ lat, lng }) => [lat, lng]),
                {
                    dashArray: '10, 10',
                    color: this.color,
                },
            );
            polyline.addTo(this.map);
            this.polylines.push(polyline);

            const isClosed = latLngs.length > 0 && latLngs[0].equals(latLngs[latLngs.length - 1]);

            // if the polyline is closed, include only one endpoint
            (isClosed ? latLngs.slice(0, -1) : latLngs).forEach(({ lat, lng }, index) => {
                if (current && current.getLatLng().equals(latLngs[index])) {
                    // reuse current marker
                    this.markers.push(current);
                    current.off('mousedown', this.onMidpointDrag, this);

                    const indexes = index === 0 && isClosed ? [index, latLngs.length - 1] : [index];
                    this.markerMap.set(current, { latLngs, indexes });
                    current.setOpacity(1);
                } else {
                    // create new marker
                    const marker = new L.Marker([lat, lng], {
                        icon: pointIcon,
                        draggable: true,
                    });

                    marker.on('drag', this.onDrag, this);

                    marker.addTo(this.map);

                    this.markers.push(marker);

                    const indexes = index === 0 && isClosed ? [index, latLngs.length - 1] : [index];
                    this.markerMap.set(marker, { latLngs, indexes });
                }

                // create midpoint markers
                if (index + 1 < latLngs.length) {
                    const nextLatLng = latLngs[index + 1];
                    const marker = L.marker(
                        [(lat + nextLatLng.lat) / 2, (lng + nextLatLng.lng) / 2],
                        {
                            icon: pointIcon,
                            draggable: true,
                            opacity: 0.7,
                        },
                    );

                    marker.on('mousedown', this.onMidpointDrag, this);
                    marker.on('drag', this.onDrag, this);

                    marker.addTo(this.map);

                    this.markerMap.set(marker, { latLngs, indexes: [index] });
                    this.midMarkers[i].push(marker);
                    this.markers.push(marker);
                }
            });
        });
    }

    /* update polylines and mid-marker positions */
    private update() {
        this.polylines.forEach((polyline, i) => {
            const latLngs = this.latLngs[i];
            polyline.setLatLngs(latLngs.map(({ lat, lng }) => [lat, lng]));
            this.midMarkers[i].forEach((midMarker, j) => {
                const prev = latLngs[j];
                const next = latLngs[j + 1];
                midMarker.setLatLng(
                    new L.LatLng((prev.lat + next.lat) / 2, (prev.lng + next.lng) / 2),
                );
            });
        });
    }

    /* a corner marker is dragged */
    private onDrag(event: L.LeafletEvent) {
        const marker = event.target;
        const draggedLatLngs = this.markerMap.get(marker);

        if (draggedLatLngs) {
            const { latLngs, indexes } = draggedLatLngs;
            const position = marker.getLatLng();

            for (const index of indexes) {
                latLngs[index] = position;
            }

            this.update();
        }

        this.map.fire(EventsEnum.Editing);
    }

    /* a midpoint marker is dragged */
    private onMidpointDrag(event: L.LeafletMouseEvent) {
        const marker = event.target;
        const draggedLatLngs = this.markerMap.get(marker);
        if (draggedLatLngs) {
            const { latLngs, indexes } = draggedLatLngs;
            const position = marker.getLatLng();

            const index = indexes[0];
            latLngs.splice(index + 1, 0, position);

            this.create(marker);
        }

        this.map.fire(EventsEnum.Editing);
    }

    private onKeyUp(event: Event) {
        const keyCode = (event as KeyboardEvent).keyCode;
        switch (keyCode) {
            case KeysEnum.Enter:
                this.save();
                this.disable();
                break;
            case KeysEnum.Esc:
                this.disable();
                break;
        }
    }
}
