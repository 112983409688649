import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { PiaLocationSelect } from 'app/components';
import type { IPiaLocationOption } from 'app/modules/userProjects';
import { getPiaLocationOptionsSortedByName } from 'app/modules/common';

interface IEditProjectCountryContainerOwnProps {
    disabled?: boolean;
    locationId?: number;
    onChangeLocationId(piaLocationId: number): void;
}

interface IEditProjectCountryContainerProps extends IEditProjectCountryContainerOwnProps {
    piaLocations: IPiaLocationOption[];
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IEditProjectCountryContainerOwnProps,
): IEditProjectCountryContainerProps => {
    return {
        piaLocations: getPiaLocationOptionsSortedByName(storeState),
        ...ownProps,
    };
};

const EditProjectCountryContainer: React.FunctionComponent<IEditProjectCountryContainerProps> = ({
    locationId,
    piaLocations,
    onChangeLocationId,
    disabled = false,
}) => (
    <PiaLocationSelect
        fillWidth
        openInPortal
        disabled={disabled}
        selectedPiaLocationId={locationId}
        piaLocations={piaLocations}
        onChange={onChangeLocationId}
    />
);

EditProjectCountryContainer.displayName = 'EditProjectCountryContainer';

export const EditProjectCountry = connect(mapStateToProps)(EditProjectCountryContainer);
