import * as React from 'react';
import type { IAutoTestable, IOptionProps } from 'app/components';
import { Select } from 'app/components';
import { t } from 'app/translate';
import type { ZipStrength } from '../../models';
import { zipStrengths } from '../../models';

export interface IZipStrengthProps extends IAutoTestable {
    zipStrength: number;
    disabled?: boolean;
    onZipStrengthChange(value: number): void;
}

export class ZipStrengthSelector extends React.PureComponent<IZipStrengthProps> {
    private zipStrengthOptions: IOptionProps[] = Object.keys(zipStrengths).map(
        (strength: string) => {
            return {
                key: strength,
                text: t.zipStrengthsGROUP[strength as ZipStrength],
                value: zipStrengths[strength as ZipStrength],
            };
        },
    );

    public render() {
        return (
            <Select
                testIdChild="zip_strength_selected"
                value={this.props.zipStrength}
                options={this.zipStrengthOptions}
                onChange={this.onZipStrengthChange}
                disabled={this.props.disabled}
            />
        );
    }

    private onZipStrengthChange = (value: string) => {
        this.props.onZipStrengthChange(Number(value));
    };
}
