import { t } from 'app/translate';
import * as React from 'react';
import * as moment from 'moment';
import { Stack } from '../../layout';
import type { IOptionProps } from '../select/Select.component';
import { Select } from '../select/Select.component';

interface IDateInputProps {
    /** The date to display */
    selectedDate: Date;
    /** The earliest possible year to select */
    startYear: number;
    /** The latest possible year to select */
    endYear: number;
    /** Callback function when user edits the date */
    onChange(date: Date): void;
}

/**
 * This component is used for the quotation. It allows the user to specify a date using select boxes.
 */
export class DateInput extends React.Component<IDateInputProps> {
    public render() {
        return (
            <Stack>
                <Select
                    value={this.props.selectedDate.getDate().toString()}
                    onChange={this.onDayChange}
                    options={this.getDayList()}
                />
                <Select
                    value={this.props.selectedDate.getMonth().toString()}
                    options={this.getMonthList()}
                    onChange={this.onMonthChange}
                />
                <Select
                    value={this.props.selectedDate.getFullYear().toString()}
                    options={this.getYearList()}
                    onChange={this.onYearChange}
                />
            </Stack>
        );
    }

    private onDayChange = (date: number) => {
        const newTimestamp = new Date(this.props.selectedDate);
        newTimestamp.setDate(date);
        this.props.onChange(newTimestamp);
    };

    private onMonthChange = (month: number) => {
        const newTimestamp = new Date(this.props.selectedDate);

        // Set the desired month number
        newTimestamp.setMonth(month);

        // If we change from January 31 to February, actualNewMonth will be March
        // since that's equivalent of February 31 (because February only has 28 days).
        const actualNewMonth = newTimestamp.getMonth();

        // Check if we actually have the month we want as per above
        // if not, reset the date to 1 (ie. February 1)
        if (actualNewMonth > month) {
            newTimestamp.setDate(1);
            newTimestamp.setMonth(month);
        }

        this.props.onChange(newTimestamp);
    };

    private onYearChange = (year: number) => {
        const newTimestamp = new Date(this.props.selectedDate);
        newTimestamp.setFullYear(year);
        this.props.onChange(newTimestamp);
    };

    private getMonthList = (): IOptionProps[] =>
        t.monthsGROUP.map((text: string, index: number) => ({
            text,
            value: String(index),
        }));

    private getYearList = (): IOptionProps[] => {
        const lastYear = this.props.endYear;
        const yearOptions: string[] = [];
        for (let i = this.props.startYear; i <= lastYear; i++) {
            yearOptions.push(i.toString());
        }
        return yearOptions.reverse().map((option) => ({
            text: option,
            value: option,
        }));
    };

    private getDayList = (): IOptionProps[] => {
        const dayEnd = moment(this.props.selectedDate).daysInMonth();
        const dayOptions: string[] = [];
        for (let i = 1; i <= dayEnd; i++) {
            dayOptions.push(i.toString());
        }
        return dayOptions.map((option) => ({
            text: option,
            value: option,
        }));
    };
}
