import * as React from 'react';
import { memoize } from 'lodash-es';
import { css } from '@emotion/css';
import { t } from 'app/translate';
import type { Icons } from '../../ui/icon';
import { Icon } from '../../ui/icon';
import { checkChildrenForKeys, renderReactChildren } from '../../services';
import type { IBoxProps } from '../../containers';
import { Box } from '../../containers';
import { Clickable } from '../../functional';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import type { Spacings } from 'app/styles';
import { forMedium } from 'app/styles/breakpoints.type';
import { AppConstants } from 'app/AppConstants';

const alignDataSymbol = '-6px';

const HeaderStyleSticky = css`
    thead {
        position: sticky;
        top: 0;
        z-index: ${AppConstants.tableHeaderDepth};
    }
`;

const WhiteBackgroundStyle = css`
    background: ${ColorsEnum.white};
`;

const EmptyRowStyle = css`
    background: ${ColorsEnum.grey2};

    & > * {
        opacity: 0.54;
    }
`;

const WhiteHeaderBackgroundStyle = css`
    thead th {
        background: linear-gradient(to top, ${ColorsEnum.grey2} 1px, ${ColorsEnum.grey1} 0px);
    }
`;

const NotScrollableStyle = css`
    th {
        position: relative;
    }
`;

const VerticallyCenterContentStyle = css`
    td {
        vertical-align: middle;
    }
`;

interface ISelectableListProps extends IAutoTestable {
    /**
     * Supply an array of components and they will render as
     * header columns.
     * If you supply a single component this will render in its
     * entirety, you have to handle the columns in this component yourself.
     */
    header: React.ReactNode;
    /**
     * Set if header should not be sticky
     */
    notStickyHeader?: boolean;
    /**
     * Supply an array of components and they will render as
     * footer columns.
     * If you supply a single component it will be appended after the list.
     */
    footer?: React.ReactNode;
    /**
     * An array of children to render. Should be `<tr>` elements
     */
    children: React.ReactNodeArray;
    /**
     * Disable the scrolling of the list
     */
    notScrollable?: boolean;
    /**
     * Disables the default background color.
     */
    noBackground?: boolean;
    /**
     * Disables the default header background color.
     */
    noHeaderBackground?: boolean;
    /**
     * Specify an array of column indexes that will be hidden
     * when the list gets too narrow
     */
    columnsToHideInCompactView?: number[];
    /**
     * Which icon to display if the list is empty
     */
    emptyIcon?: Icons;
    /**
     * What text to display if the list is empty
     */
    emptyText?: string;
    /**
     * Set the vertical alignment of cell contents to `middle`.
     * Defaults to `top`.
     */
    verticallyCenterContents?: boolean;
    /**
     * Callback function which will trigger when any child is selected.
     * This returns the key of the selected child
     */
    onSelect?(key: string): void;
    /**
     * Callback when all items are deselcted in the list.
     */
    onDeselect?(): void;
    /**
     * Set outer left padding
     */
    outerLeftPadding?: Spacings | undefined;
    /**
     * Adds a compact view style to the table rows. Sets padding in table rows to 'quart' instead of 'cell'
     */
    compactRows?: boolean;
}

interface ISelectableListState {
    selected: string | null;
}

/**
 * Render a scrollable table where all table rows are selectable.
 */
export class SelectableList extends React.Component<ISelectableListProps, ISelectableListState> {
    private SelectableListStyle = css`
        /** Hack to make cell contents adapt to cell height **/
        height: 1%;
        width: 100%;
        border-collapse: collapse;
        position: relative;

        thead {
            color: ${ColorsEnum.grey6};
        }

        tr[aria-selected='true'] {
            background: ${ColorsEnum.yellow1};
            z-index: 1;
        }

        tr[aria-details],
        tr[id],
        thead tr {
            border-bottom: none;
        }

        tr[aria-details] td {
            position: relative;
        }

        td.child-item-symbol::before,
        tr[aria-details] td:first-child::before,
        tr[aria-details] td:nth-child(2)::before {
            font-family: 'axis-wt-icons' !important;
            font-size: icon-font-size;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Enable Ligatures ================ */
            letter-spacing: 0;
            font-feature-settings: 'liga';
            font-variant-ligatures: discretionary-ligatures;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            content: attr(data-symbol);
            font-size: 68px;
            position: absolute;
            inset-inline-start: ${alignDataSymbol};
            top: -3px;
            color: ${ColorsEnum.grey3};
            width: 55px;
            max-width: 55px;
            [dir='rtl'] & {
                transform: rotateY(180deg);
                width: 68px;
                max-width: 68px;
            }
        }

        tfoot {
            position: sticky;
            bottom: 0;
            z-index: ${AppConstants.tableFooterDepth};
        }

        thead th,
        tfoot th {
            padding: ${SpacingsEnum.quart};
            padding-bottom: ${SpacingsEnum.half};
            vertical-align: bottom;
        }

        tfoot th {
            /* Table foot must be below table head to stay behind drop downs */
            z-index: 0;
            bottom: 0;
            background: linear-gradient(
                to bottom,
                ${ColorsEnum.grey2} 2px,
                ${ColorsEnum.grey1} 0px
            );
        }

        tfoot tr {
            border: none !important;
        }

        td {
            padding: ${this.props.compactRows ? SpacingsEnum.quart : SpacingsEnum.cell};
            padding-inline-start: ${this.props.compactRows
                ? SpacingsEnum.quart
                : SpacingsEnum.none};
        }

        td[role='button'] {
            width: 0;
        }

        td.text {
            vertical-align: middle;
        }

        td:last-of-type {
            vertical-align: middle;
        }

        tr td:not(.td-device-detail-panel) {
            border-top: 1px solid ${ColorsEnum.grey2};
        }

        tr[aria-details] td:nth-child(-n + 2) {
            border-top-width: 0;
        }
    `;

    constructor(props: ISelectableListProps) {
        super(props);
        if (props.children.length > 0) {
            checkChildrenForKeys(props.children, 'SelectableList');
        }
        this.state = {
            selected: null,
        };
    }

    public componentDidUpdate(prevProps: ISelectableListProps) {
        if (prevProps.children.length !== this.props.children.length) {
            const wasSelectedRemoved = !this.props.children.some((child: any) =>
                this.isSelected(child.key),
            );

            if (wasSelectedRemoved) {
                this.deselect();
            }
        }
    }

    public render() {
        const {
            columnsToHideInCompactView,
            notScrollable,
            notStickyHeader,
            noBackground,
            noHeaderBackground,
            verticallyCenterContents,
            header,
            footer,
            children,
        } = this.props;
        const mediaQueryClasses = columnsToHideInCompactView
            ? columnsToHideInCompactView.map((index) => this.getColumnToHideStyle(index))
            : [];

        const boxProps: IBoxProps = notScrollable
            ? {
                  display: 'block',
                  position: 'relative',
                  width: '100%',
              }
            : {
                  display: 'block',
                  height: '100%',
                  paddingX: 'base',
                  paddingLeft: this.props.outerLeftPadding ?? 'base',
                  paddingY: 'none',
                  width: '100%',
                  position: 'relative',
                  overflowY: 'auto',
              };
        return (
            <Clickable noInteraction allowClickThrough onClick={this.deselect}>
                <Box {...boxProps}>
                    <table
                        data-test-id={toTestIdFormat(this.props.testId)}
                        className={[
                            this.SelectableListStyle,
                            !notStickyHeader && HeaderStyleSticky,
                            notScrollable && NotScrollableStyle,
                            verticallyCenterContents && VerticallyCenterContentStyle,
                            !noBackground && WhiteBackgroundStyle,
                            !noHeaderBackground && WhiteHeaderBackgroundStyle,
                            ...mediaQueryClasses,
                        ].join(' ')}
                    >
                        <thead onClick={this.deselect}>
                            <tr>{Array.isArray(header) ? this.renderRow(header) : header}</tr>
                        </thead>
                        <tbody>
                            {children.length > 0
                                ? this.renderChildren(children)
                                : this.renderEmpty()}
                        </tbody>
                        {Array.isArray(footer) && (
                            <tfoot>
                                <tr>{this.renderRow(footer)}</tr>
                            </tfoot>
                        )}
                    </table>
                    {!Array.isArray(footer) && footer}
                </Box>
            </Clickable>
        );
    }

    private getColumnToHideStyle = (index: number) => {
        return css`
            @media ${forMedium} {
                tr td:nth-child(${index}),
                tr th:nth-child(${index}) {
                    display: none;
                }
            }
        `;
    };

    private renderRow = (headerContents: React.ReactNode) => {
        const renderColumn = (child: React.ReactNode) => <th>{child}</th>;
        return React.Children.map(headerContents, renderColumn);
    };

    private renderChildren = (children: React.ReactNode) =>
        renderReactChildren(children, (child) =>
            React.cloneElement(child, {
                onFocusFunction: this.select,
                isSelectedFunction: this.isSelected,
                isSelected: this.isSelected(child.key!.toString()),
                onFocus: this.select(child.key!.toString()),
                onClick: this.select(child.key!.toString()),
            }),
        );

    private renderEmpty = () => (
        <tr className={EmptyRowStyle} key="emptyList">
            <td style={{ width: '40px' }}>
                <Icon size="lg" icon={this.props.emptyIcon || 'list'} />
            </td>
            <td
                colSpan={
                    this.props.header && Array.isArray(this.props.header)
                        ? this.props.header.length - 1
                        : 100
                }
            >
                {this.props.emptyText || t.emptyList}
            </td>
        </tr>
    );

    private isSelected = (key: string) => this.state.selected === key;

    private select = memoize((selected: string) => {
        return () => {
            if (this.state.selected !== selected) {
                this.setState({ selected });
                return this.props.onSelect ? this.props.onSelect(selected) : null;
            }
        };
    });

    private deselect = () => {
        this.setState({ selected: null });
        return this.props.onDeselect ? this.props.onDeselect() : null;
    };
}
