import type {
    IBaseProfileModel,
    IScenarioSettingsModel,
    IRecordingSettingsModel,
    IStorageSettingsModel,
} from './';
import type { IZipstreamSettingsModel } from './IZipstreamSettingsModel';
import type { IAudioSettingsModel } from './IAudioSettingsModel';
import type { IPersistence, IProfileEntity } from '../../userDataPersistence';
import { mapToRecordingSettingsModel, mapToScenarioModel } from './utils/ProfileMapper';

export class BaseProfile implements IBaseProfileModel {
    public name: string;
    public scenario: IScenarioSettingsModel;
    public triggeredRecording: IRecordingSettingsModel;
    public continuousRecording: IRecordingSettingsModel;
    public liveView: IRecordingSettingsModel;
    public storage: IStorageSettingsModel;
    public zipstream: IZipstreamSettingsModel;
    public audio: IAudioSettingsModel;

    constructor(profile: IPersistence<IProfileEntity>) {
        this.name = profile.name;
        this.storage = profile.storage;
        this.zipstream = profile.zipstream;
        this.audio = profile.audio;
        this.scenario = mapToScenarioModel(profile.scenario);
        this.triggeredRecording = mapToRecordingSettingsModel(profile.triggeredRecording);
        this.continuousRecording = mapToRecordingSettingsModel(profile.continuousRecording);
        this.liveView = mapToRecordingSettingsModel(profile.liveView);
    }
}
