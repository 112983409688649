import * as React from 'react';
import type { IExtendableComponent } from '../../models';
import { extendableProps } from '../../services';
import { Box } from '../../containers';
import { Icon } from '../../ui/icon';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { css } from '@emotion/css';
import classNames from 'classnames';

const SmallStyle = css`
    width: 32px;
    height: 32px;
`;

const MediumStyle = css`
    width: 48px;
    height: 48px;
`;

const LargeStyle = css`
    width: 64px;
    height: 64px;
`;

type Size = 'sm' | 'md' | 'lg';

export interface IDropDownMenuColorProps extends IExtendableComponent {
    /**
     * Adds a checkmark to show that this item
     * is selected.
     */
    selected?: boolean;
    /**
     * Size of the color circle.
     */
    size: Size;
    /**
     * Color
     */
    color: Colors;
}

/**
 * Color picker for drop downs.
 */
export class DropDownMenuColor extends React.PureComponent<IDropDownMenuColorProps> {
    public render() {
        const { selected, color, size, ...extendedProps } = this.props;
        const attributes = extendableProps(extendedProps, {
            'aria-selected': selected,
            tabIndex: -1,
            style: {
                border: 'none',
                backgroundColor: ColorsEnum[color],
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
            },
            className: classNames({
                [SmallStyle]: size === 'sm',
                [MediumStyle]: size === 'md',
                [LargeStyle]: size === 'lg',
            }),
        });

        return (
            <Box
                testId={color}
                {...attributes}
                borderRadius="circle"
                alignItems="center"
                justifyContent="center"
                color={color}
            >
                {selected !== undefined && (
                    <Icon icon="check" hidden={!selected} color="white" opaque />
                )}
            </Box>
        );
    }
}
