import * as React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { debounce } from 'lodash-es';
import type { Property } from 'csstype';
import type { Colors } from 'app/styles';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import type { Icons } from '../../ui/icon';
import { IconButton } from '../../ui/iconButton';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { stopUndoRedoPropagation } from '../utils';
import { TextInputStyle } from '../textInput/TextInput.component';
import type { InputChangeCriteria } from '../numberInput/NumberInput.component';

const TextareaContainerStyle = css`
    width: 100%;
    position: relative;
`;

const TextareaStyle = css`
    height: 100%;
    resize: none;
    padding-top: ${SpacingsEnum.quart};

    &::placeholder {
        color: ${ColorsEnum.grey5};
    }
`;

const TextRightPaddingStyle = css`
    padding-right: 35px;
`;

const HideOverflowStyle = css`
    overflow-y: hidden;
`;

const NoBorderStyle = css`
    border-width: 0 !important;
`;

const TransparentStyle = css`
    background: ${ColorsEnum.transparent};

    &:hover,
    &:focus {
        background-color: ${ColorsEnum.white};
    }
`;

const CancelButtonStyle = css`
    position: absolute;
    right: 18px;
    top: 3px;
    line-height: 1.7;

    &:focus {
        outline: none;
        opacity: 1;
    }
`;

interface ITextarea extends IAutoTestable {
    /**
     * Number of rows
     */
    rows?: number;
    /**
     * The max length
     */
    maxLength?: number;
    /**
     * The value
     */
    value: string;
    /**
     * Background color
     */
    color?: Colors;
    /**
     * The color of the text
     */
    textColor?: Colors;
    /**
     * Set the font weight of the text
     */
    fontWeight?: Property.FontWeight;
    /**
     * The Placeholder
     */
    placeholder?: string;
    /**
     * How to trigger change event on text area
     */
    changeCriteria?: InputChangeCriteria;
    /**
     * Disallow line breaks
     */
    noLineBreaks?: boolean;
    /**
     * Hide overflowing text
     */
    hideOverflow?: boolean;
    /**
     * Hide the border
     */
    noBorder?: boolean;
    /**
     * Remove the padding
     */
    noPadding?: boolean;
    /**
     * Toggles transparency
     */
    transparent?: boolean;
    /**
     * Determines whether this component should get focus automatically
     */
    autoFocus?: boolean;
    /**
     * Not allow empty values
     */
    required?: boolean;
    /**
     * Disable text area
     */
    disabled?: boolean;
    /**
     * Read only text area
     */
    readonly?: boolean;
    /**
     * Override the clear icon
     */
    clearIcon?: Icons;
    /**
     * Triggered on change
     */
    onChange(data: string): void;
    /**
     * Provide a method to clear the field.
     * Will display a cancel button.
     */
    clear?(): void;

    /**
     * Set height in pixels
     */
    height?: React.CSSProperties['height'];
}

interface IStateTextarea {
    value: string;
    originalValue: string;
}

const ESC_KEY: number = 27;
const ENTER_KEY: number = 13;

/**
 * Render a `<textarea>` element with some custom props.
 * Used for when the user input is expected to be a long string.
 */
export class Textarea extends React.Component<ITextarea, IStateTextarea> {
    public static defaultProps: Partial<ITextarea> = {
        changeCriteria: 'blur',
    };

    private inputRef: React.RefObject<HTMLTextAreaElement>;
    private usesDeactivate = false;
    private debouncedOnChange = debounce((value) => this.props.onChange(value), 600);

    constructor(props: ITextarea) {
        super(props);

        this.state = {
            value: this.props.value,
            originalValue: this.props.value,
        };

        this.inputRef = React.createRef();
    }

    public render() {
        const classes = classNames(TextInputStyle, TextareaStyle, {
            [HideOverflowStyle]: this.props.hideOverflow === true,
            [NoBorderStyle]: this.props.noBorder,
            [TransparentStyle]: this.props.transparent,
            [TextRightPaddingStyle]: this.props.clear !== undefined,
            [css`
                height: ${this.props.height};
            `]: this.props.height !== undefined,
        });

        return (
            <div className={TextareaContainerStyle}>
                <textarea
                    data-test-id={toTestIdFormat(this.props.testId)}
                    ref={this.inputRef}
                    name="textarea"
                    className={classes}
                    style={{
                        backgroundColor: this.props.color
                            ? ColorsEnum[this.props.color]
                            : undefined,
                        color: this.props.textColor ? ColorsEnum[this.props.textColor] : undefined,
                        fontWeight: this.props.fontWeight,
                        padding: this.props.noPadding ? 0 : undefined,
                    }}
                    maxLength={this.props.maxLength}
                    placeholder={this.props.placeholder}
                    rows={this.props.rows}
                    value={this.state.value}
                    disabled={this.props.disabled}
                    readOnly={this.props.readonly}
                    onChange={this.onTextChange}
                    onBlur={this.onBlur}
                    onKeyUp={this.onKeyUp}
                    onKeyDown={this.onKeyDown}
                    autoFocus={this.props.autoFocus}
                />
                {this.props.clear && (
                    <IconButton
                        __htmlAttributes={{
                            className: CancelButtonStyle,
                        }}
                        size="sm"
                        onClick={this.props.clear}
                        icon={this.props.clearIcon || 'cancel'}
                    />
                )}
            </div>
        );
    }

    public componentDidMount() {
        // Since blur is asynchronous in IE11 we can't guarantee that a change
        // will be saved onBlur when changing component model. The IE exclusive
        // `deactivate` event triggers before any other click handlers.
        this.inputRef.current!.addEventListener('deactivate', this.onDeactivate);
        this.inputRef.current!.addEventListener('keydown', stopUndoRedoPropagation);
    }

    public componentWillUnmount() {
        this.inputRef.current!.removeEventListener('keydown', stopUndoRedoPropagation);
    }

    public componentDidUpdate(prevProps: ITextarea) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value,
                originalValue: this.props.value,
            });
        }
    }

    private onBlur = () => {
        // Prevent onTextBlur to be called twice
        // if onDeactivate already was called (in case of edge or ie11)
        if (this.usesDeactivate) {
            return;
        }
        this.onTextBlur();
    };

    private onDeactivate = () => {
        this.usesDeactivate = true;
        this.onTextBlur();
    };

    private onKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        switch (e.keyCode) {
            case ESC_KEY:
                // Need to save the target because of synthetic event wrapper see
                // https://fb.me/react-event-pooling
                const currentTarget = e.currentTarget;

                this.setState(
                    {
                        value: this.state.originalValue,
                    },
                    () => {
                        currentTarget.blur();
                    },
                );
                break;
            case ENTER_KEY:
                if (this.props.noLineBreaks) {
                    this.onTextBlur();
                }
                break;
        }
    };

    private onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (this.props.noLineBreaks && e.keyCode === ENTER_KEY) {
            e.preventDefault();
        }
    };

    private onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ value: e.target.value });
        if (this.props.changeCriteria === 'key' || this.props.changeCriteria === 'debounced') {
            const value = e.target.value;
            const hasValue = value.trim();
            if (this.props.required && !hasValue) {
                return;
            }

            if (this.props.changeCriteria === 'debounced') {
                this.debouncedOnChange(value);
            } else {
                this.props.onChange(value);
            }
        }
    };

    private onTextBlur = () => {
        let value = this.state.value || '';
        const hasValue = value.trim();
        if (this.props.noLineBreaks) {
            value = value.replace(/\n/g, '');
        }
        const valueChanged = value !== this.state.originalValue;
        const hasRequiredValue = this.props.required ? hasValue : true;

        if (valueChanged && hasRequiredValue && this.props.onChange) {
            this.setState({ originalValue: value });
            this.props.onChange(value);
        } else {
            if (this.props.changeCriteria === 'blur') {
                if (this.state.value !== this.state.originalValue) {
                    this.props.onChange(this.state.value);
                }
            } else if (this.props.changeCriteria === 'debounced') {
                this.debouncedOnChange.flush();
            }
        }
    };
}
