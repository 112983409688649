import type { Id } from 'app/core/persistence';
import { t } from 'app/translate';
import { createSelector } from 'reselect';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectNetworkSettings } from '../../project';
import { getCurrentProjectItemsWithNetworkSettings } from './getCurrentProjectItemsWithNetworkSettings';

export type addressType = 'address' | 'subnetMask' | 'defaultRouter' | 'projectDefaultRouter';
export interface IIpItem {
    id: Id;
    name: string;
    piaModelName?: string;
    usingAddressAs: addressType;
}

export const getIpToIdRecord = createSelector(
    [
        getCurrentProjectItemsWithNetworkSettings,
        getPiaItemsRecord,
        getCurrentProjectNetworkSettings,
    ],
    (items, piaItemsRecord, projectNetworkSettings) => {
        const projectDefaultRouter = projectNetworkSettings?.defaultRouter;
        const netWorkRecord: Record<string, IIpItem[]> = {};
        if (projectDefaultRouter) {
            netWorkRecord[projectDefaultRouter] = [
                {
                    id: projectDefaultRouter,
                    name: t.ipDefaultRouter,
                    usingAddressAs: 'projectDefaultRouter',
                },
            ];
        }

        items.forEach((item) => {
            const piaItem = item.productId ? piaItemsRecord[item.productId] : undefined;

            item.networkSettings?.forEach((setting) => {
                if (setting.addresses) {
                    setting.addresses.forEach((address) => {
                        if (netWorkRecord[address]) {
                            netWorkRecord[address].push({
                                id: item._id,
                                name: item.name,
                                piaModelName: piaItem?.name ?? '',
                                usingAddressAs: 'address',
                            });
                        } else {
                            netWorkRecord[address] = [
                                {
                                    id: item._id,
                                    name: item.name,
                                    piaModelName: piaItem?.name,
                                    usingAddressAs: 'address',
                                },
                            ];
                        }
                    });
                }
                if (setting.defaultRouter) {
                    if (netWorkRecord[setting.defaultRouter]) {
                        netWorkRecord[setting.defaultRouter].push({
                            id: item._id,
                            name: item.name,
                            piaModelName: piaItem?.name ?? '',
                            usingAddressAs: 'defaultRouter',
                        });
                    } else {
                        netWorkRecord[setting.defaultRouter] = [
                            {
                                id: item._id,
                                name: item.name,
                                piaModelName: piaItem?.name,
                                usingAddressAs: 'defaultRouter',
                            },
                        ];
                    }
                }
            });
        });
        return netWorkRecord;
    },
);
