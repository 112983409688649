import type { IItemEntity, DeviceAndSubType, DeviceType } from 'app/core/persistence';
import { isCustomCamera, getDeviceType } from 'app/core/persistence';
import { productFilters } from 'app/modules/common';

import type { Colors } from 'app/styles';

export const customCameraImage = require('src/assets/images/videoCam-grey.png');
const genericCamera = require('assets/images/map-generic-camera.svg');
const genericMainUnit = require('assets/images/map-generic-main-unit.svg');
const genericSensorUnit = require('assets/images/map-generic-sensor-unit.svg');
const genericEncoder = require('assets/images/map-generic-encoder.svg');
const genericSpeakerYellow = require('assets/images/map-generic-speaker-yellow.svg');
const genericAnalogCamera = require('assets/images/map-generic-analog-camera.svg');
const genericRadarGreen = require('assets/images/map-generic-radar-green.svg');
const genericDoorControllerPurple = require('assets/images/map-generic-door-controller-purple.svg');
const axis = require('assets/images/axis.png');
const twoN = require('assets/images/2n.png');

const getVendorImage = (vendor?: string) => {
    if (productFilters.isVendorAxis(vendor)) return axis; // axis;
    if (productFilters.isVendor2N(vendor)) return twoN; //twoN;
    return undefined;
};

export const getDeviceImage = (piaId: number | null, entity: IItemEntity) => {
    if (isCustomCamera(entity)) return customCameraImage;

    const deviceType = getDeviceType(entity);
    return getDeviceImageByType(piaId, deviceType);
};

/**
 * Will return piaImage for device, or the generic image based on device type
 * NOTE: images for new device types must be named as device type.
 */
export const getDeviceImageByType = (
    piaId: number | null,
    deviceType: DeviceAndSubType | undefined,
    color?: Colors,
) => {
    try {
        return piaId
            ? `https://www.axis.com/images/products/${piaId}-50.png`
            : require(`src/assets/images/${deviceType}-${color || 'grey'}.png`);
    } catch (error) {
        return require(`src/assets/images/camera-grey.png`);
    }
};

export const getGenericAddImage = (deviceType: DeviceType, vendor?: string) => {
    switch (deviceType) {
        case 'camera':
            return genericCamera;
        case 'mainUnit':
            return genericMainUnit;
        case 'sensorUnit':
            return genericSensorUnit;
        case 'encoder':
            return genericEncoder;
        case 'analogCamera':
            return genericAnalogCamera;
        case 'speaker':
            return genericSpeakerYellow;
        case 'radardetector':
            return genericRadarGreen;
        case 'doorcontroller':
            return genericDoorControllerPurple;
        default:
            return vendor ? getVendorImage(vendor) : undefined;
    }
};
