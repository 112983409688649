import { deleteDatabaseKey, deleteDatabase } from './databaseUtil';
import type { IMigration } from './models/IMigration';

export const migrate1to2: IMigration = {
    from: 1,
    to: 2,
    run: migrate,
};

async function migrate(): Promise<void> {
    clearTemplates();
    await Promise.all([clearPrivileges(), clearPIAData()]);
}

function clearPrivileges(): Promise<void> {
    return deleteDatabaseKey('axis.webtools.user', 'privileges');
}

function clearPIAData(): Promise<[void, void]> {
    return Promise.all([
        deleteDatabase('axis.webtools.pia'),
        deleteDatabase('axis.webtools.piaFiltered'),
    ]);
}

function clearTemplates() {
    localStorage.removeItem('asd_templates');
}
