import { getCurrentProjectItemsArray } from 'app/modules/common';
import { createSelector } from 'reselect';
import type { PiaId } from 'app/core/pia';

/**
 * Get the persisted recording items from the current project.
 */
export const getCurrentProjectRecordingItems = createSelector(
    [getCurrentProjectItemsArray],
    (items) => items.filter((item) => !!item.properties.systemComponent && item.productId),
);

/**
 * Returns a record with PiaId as key and the currently selected quantity
 * as value, including individual quantities for that PiaId.
 */
export const getQuantityForRecordingProducts = createSelector(
    [getCurrentProjectRecordingItems],
    (selectedRecorders) =>
        selectedRecorders.reduce((obj: Record<PiaId, number>, item) => {
            if (item.productId) {
                obj[item.productId] = (obj[item.productId] ?? 0) + item.quantity;
            }
            return obj;
        }, {}),
);

/**
 * Get the persisted recording items from the current project.
 */
export const getCurrentProjectPartnerRecordingItems = createSelector(
    [getCurrentProjectItemsArray],
    (items) => items.filter((item) => !!item.properties.partnerSystemComponent),
);

/**
 * Returns a record with productName as key and the currently selected quantity
 * as value
 */
export const getQuantityForPartnerRecordingProducts = createSelector(
    [getCurrentProjectPartnerRecordingItems],
    (selectedRecorders) =>
        selectedRecorders.reduce((obj: Record<string, number>, item) => {
            if (item.name) {
                obj[item.name] = (obj[item.name] ?? 0) + item.quantity;
            }
            return obj;
        }, {}),
);
