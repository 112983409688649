import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import { ReadDirection, Text } from 'app/components';
import { getRecordingSolutionTotalSpecs } from '../selectors/getRecordingSolutionSpecs';
import { format } from 'axis-webtools-util';
import { cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { isPureCompanionSystem } from '../selectors/getRecordingSolution';
import { getRecordingSolutionRequirements } from '../selectors/getRecordingSolutionRequirements';
import {
    dashedLeftBorder,
    footerTotalCellStyle,
    footerMarginCellStyle,
    darkRightBorder,
} from './solutionTable.styles';

export const SelectedSolutionTableFooter: React.FC = () => {
    const totalSpecs = useSelector(getRecordingSolutionTotalSpecs);
    const disableLicenses = useSelector(isPureCompanionSystem);
    const requirements = useSelector(getRecordingSolutionRequirements);

    // Totals
    const totChannels =
        totalSpecs.nrOfChannels === 0 || totalSpecs.nrOfChannels === Number.POSITIVE_INFINITY
            ? t.abbreviationsGROUP.notApplicable
            : totalSpecs.nrOfChannels;
    const totLicenses = disableLicenses
        ? t.abbreviationsGROUP.notApplicable
        : totalSpecs.nrOfLicenses;
    const totStorage = format.storage(totalSpecs.storage);
    const totBandwidth =
        totalSpecs.bandwidth === 0
            ? t.abbreviationsGROUP.notApplicable
            : format.bandwidth(totalSpecs.bandwidth);
    const totPower = format.power(totalSpecs.power);
    const totPorts = totalSpecs.ports.totalPorts;
    const totVideoPorts = totalSpecs.video || null;

    // Margins
    const margChannels =
        totalSpecs.nrOfChannels === 0 || totalSpecs.nrOfChannels === Number.POSITIVE_INFINITY
            ? t.abbreviationsGROUP.notApplicable
            : totalSpecs.nrOfChannels - requirements.nrOfChannels;
    const margLicenses = disableLicenses
        ? t.abbreviationsGROUP.notApplicable
        : totalSpecs.nrOfLicenses - requirements.nrOfLicenses;
    const margStorage = format.storage(totalSpecs.storage - requirements.storage);
    const margBandwidth =
        totalSpecs.bandwidth === 0
            ? t.abbreviationsGROUP.notApplicable
            : format.bandwidth(totalSpecs.bandwidth - requirements.bandwidth);
    const margPower = format.power(totalSpecs.power - requirements.power);
    const margPorts = totalSpecs.ports.totalPorts - requirements.ports.totalPorts;

    return (
        <>
            {/** TOTALS */}
            <div
                className={footerTotalCellStyle}
                style={{
                    borderInlineEnd: 'none',
                    alignItems: 'start',
                    borderInlineStart: `1px solid ${ColorsEnum['grey3']}`,
                }}
            >
                <Text style="semibold" color="grey9">
                    {t.total}
                </Text>
            </div>
            <div className={cx(footerTotalCellStyle, dashedLeftBorder)}>{totChannels}</div>
            <div className={footerTotalCellStyle}>{totLicenses}</div>
            <div dir="ltr" className={footerTotalCellStyle}>
                {totStorage}
            </div>
            <div dir="ltr" className={footerTotalCellStyle}>
                {totBandwidth}
            </div>
            <div className={cx(footerTotalCellStyle, dashedLeftBorder)}>
                <ReadDirection direction="ltr">
                    <Text style="semibold">{totPower}</Text>
                </ReadDirection>
            </div>
            <div className={footerTotalCellStyle}>{totPorts}</div>
            <div className={cx(footerTotalCellStyle, dashedLeftBorder, darkRightBorder)}>
                {totVideoPorts}
            </div>

            {/** MARGINS */}
            <div
                className={footerMarginCellStyle}
                style={{
                    borderInlineEnd: 'none',
                    alignItems: 'start',
                    borderInlineStart: `1px solid ${ColorsEnum['grey3']}`,
                }}
            >
                {t.systemMargins}
            </div>
            <div className={cx(footerMarginCellStyle, dashedLeftBorder)}>{margChannels}</div>
            <div className={footerMarginCellStyle}>{margLicenses}</div>
            <div dir="ltr" className={footerMarginCellStyle}>
                {margStorage}
            </div>
            <div dir="ltr" className={footerMarginCellStyle}>
                {margBandwidth}
            </div>
            <div className={cx(footerMarginCellStyle, dashedLeftBorder)}>
                <ReadDirection direction="ltr">
                    <Text color="grey6">{margPower}</Text>
                </ReadDirection>
            </div>
            <div className={footerMarginCellStyle}>{margPorts}</div>
            <div className={cx(footerMarginCellStyle, dashedLeftBorder, darkRightBorder)} />
        </>
    );
};
