import { AppConstants } from 'app/AppConstants';
import { Border, Box, Card, ColorPalette, EditableText, Heading, Text } from 'app/components';
import { ItemService } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { getCurrentProjectLocked, getInstallationPointsForItemCount } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import type { Colors } from 'app/styles';
import { t } from 'app/translate';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { isMapsCamera, isMapsSpeaker } from '../../../../models';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import {
    ChangeDevice,
    DeviceAnalyticRangesComponent,
    PanoramaOptions,
    PixelDensity,
    PlacedDevices,
    Placement,
} from '../device';
import { DeviceAddOns } from '../device/DeviceAddOns';
import { PiaItemPacCategory } from 'app/core/pia';

export const DeviceDetails: React.FC = () => {
    const device = useSelector(getSelectedDeviceOrParentDevice);
    const deviceRef = React.useRef(device);
    deviceRef.current = device;
    const placedInstallationPoints = useSelector<IStoreState, number>((state) => {
        return getInstallationPointsForItemCount(state, device?.id);
    });
    const itemService = useService(ItemService);
    const projectLocked = useSelector(getCurrentProjectLocked);

    const changeName = (newName: string) => {
        deviceRef.current && itemService.updateItem(deviceRef.current.id, { name: newName });
    };

    const updateQuantity = async (quantity: number, revertCallback: () => void) => {
        if (!deviceRef.current) return;

        if (projectLocked && quantity > deviceRef.current.quantity) {
            revertCallback();
            return;
        }

        const updatedItem = await itemService.updateItem(deviceRef.current.id, {
            quantity,
        });

        !updatedItem && revertCallback();
    };

    const updateColor = (color: Colors) => {
        deviceRef.current && itemService.updateItem(deviceRef.current.id, { color });
    };

    if (!device) return null;

    const isAnalogDevice = device.deviceType === 'analogCamera' || device.deviceType === 'door';

    return (
        <Card paddingY="base">
            <Box direction="column" spacing="base" width="100%">
                <Border bottomWidth={isAnalogDevice ? 0 : 1} color="grey2">
                    <Box width="100%" paddingLeft="base" paddingBottom="base">
                        <Heading width="100%">
                            <EditableText
                                testId="map_device_name_input"
                                value={device.name}
                                maxLength={AppConstants.deviceNameMaxLength}
                                onChange={debounce(changeName, 600)}
                                placeholder={t.name}
                                required
                            />
                        </Heading>
                    </Box>
                </Border>
                {!isAnalogDevice && (
                    <>
                        <Box paddingX="base">
                            <ChangeDevice />
                        </Box>

                        {device.deviceType !== 'sensorUnit' &&
                            device.piaProduct?.category !== PiaItemPacCategory.RELAYEXPMODULES && (
                                <Border bottomWidth={1} color="grey2">
                                    <Box paddingX="base" paddingBottom="base" display="block">
                                        <Box justifyContent="between">
                                            <Box direction="column" spacing="halfCell">
                                                <Text faded>{t.placed}</Text>
                                                <PlacedDevices
                                                    placedInstallationPoints={
                                                        placedInstallationPoints
                                                    }
                                                    totalQuantity={device.quantity}
                                                    updateQuantity={updateQuantity}
                                                />
                                            </Box>

                                            <Box
                                                direction="column"
                                                spacing="halfCell"
                                                alignItems="end"
                                            >
                                                <Text faded>{t.color}</Text>

                                                <ColorPalette
                                                    updateColor={updateColor}
                                                    color={device.color}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Border>
                            )}

                        {isMapsCamera(device) && device.canChangePanoramaMode && (
                            <Box paddingX="base">
                                <PanoramaOptions />
                            </Box>
                        )}
                        {isMapsCamera(device) && (
                            <Box paddingX="base">
                                <PixelDensity color={device.color} />
                            </Box>
                        )}
                        {isMapsSpeaker(device) && (
                            <Box paddingX="base">
                                <Placement />
                            </Box>
                        )}
                        {device.piaProduct && (
                            <>
                                <Box paddingX="base">
                                    <DeviceAddOns deviceId={device.id} />
                                </Box>
                                <Box paddingX="base">
                                    <DeviceAnalyticRangesComponent device={device} />
                                </Box>
                            </>
                        )}
                    </>
                )}
            </Box>
        </Card>
    );
};

DeviceDetails.displayName = 'DeviceDetails';
