import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { deviceTypeCheckers, getParentId } from 'app/core/persistence';
import {
    getDeviceProfileSupport,
    getAssociatedProfile,
    getProfileIdsSortedByProfileName,
    getIsItemProfileValidForRecordingSolution,
} from 'app/modules/common';
import { Border, Box, DropDown, DropDownMenuText, DropDownMenuButton, Icon } from 'app/components';
import type { IAutoTestable } from 'app/components';
import { t } from 'app/translate';
import { ProfileSelectorItem } from './ProfileSelectorItem.container';
import { ProjectDevicesActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';

export interface IProfileSelectorOwnProps extends IAutoTestable {
    item: IPersistence<IItemEntity>;
}

interface IProfileSelectorContainerProps extends IAutoTestable {
    profileIds: Id[];
    showUnsupportedProfileWarning: boolean;
    showRecordingSolutionWarning: boolean;
    disabled: boolean;
    item: IPersistence<IItemEntity>;
    selectedProfileId: Id;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProfileSelectorOwnProps,
): IProfileSelectorContainerProps => {
    const profileSupport = getDeviceProfileSupport(storeState, ownProps.item._id);
    const showRecordingSolutionWarning = !getIsItemProfileValidForRecordingSolution(
        storeState,
        ownProps.item._id,
    );

    return {
        profileIds: getProfileIdsSortedByProfileName(storeState),
        showUnsupportedProfileWarning: profileSupport?.isAnySettingUnsupported ?? false,
        showRecordingSolutionWarning,
        disabled: false,
        selectedProfileId: getAssociatedProfile(storeState, ownProps.item._id)?._id ?? '',
        item: ownProps.item,
        testId: ownProps.testId,
    };
};

class ProfileSelectorContainer extends React.PureComponent<IProfileSelectorContainerProps> {
    private actionService: ProjectDevicesActionService;

    constructor(props: IProfileSelectorContainerProps) {
        super(props);
        this.actionService = ServiceLocator.get(ProjectDevicesActionService);
    }

    public render() {
        return (
            <DropDown
                fullHeight
                fillWidth
                disabled={this.props.disabled}
                minWidth={200}
                trigger={
                    <Border radius="4px" color="grey3">
                        <Box
                            flex="fullWidth"
                            justifyContent="between"
                            paddingRight="quart"
                            alignItems="center"
                            height="100%"
                        >
                            <Box flex="shrinkAndGrow">
                                {this.renderItem(this.props.selectedProfileId)}
                            </Box>
                            <Box flex="none" alignItems="center" height="100%">
                                <Icon icon="arrow_down" color="grey4" size="md" />
                            </Box>
                        </Box>
                    </Border>
                }
                contents={
                    <>
                        {this.props.showUnsupportedProfileWarning && (
                            <DropDownMenuText
                                label={t.profilesSupportNotSupported}
                                icon="warning_small"
                                iconProps={{ color: 'yellow7' }}
                            />
                        )}
                        {this.props.showRecordingSolutionWarning && (
                            <DropDownMenuText
                                label={t.profileNotCompatibleWithRecordingSolution}
                                testId="warning_text_indoor_scenario"
                                icon="warning_small"
                                iconProps={{ color: 'yellow7' }}
                            />
                        )}
                        <DropDownMenuButton
                            label={t.profileEditProfile}
                            onClick={this.onEditProfile}
                        />
                        {this.props.profileIds.map((id) => this.renderItem(id, true))}
                    </>
                }
            />
        );
    }

    private renderItem = (id: string, isDropDown?: boolean) => (
        <ProfileSelectorItem
            isDropDownItem={isDropDown}
            testId={this.props.testId}
            key={id}
            profileId={id}
            itemId={this.props.item._id}
            selected={this.props.selectedProfileId === id}
            showWarning={
                this.props.showUnsupportedProfileWarning || this.props.showRecordingSolutionWarning
            }
        />
    );

    private onEditProfile = () => {
        const parentId = getParentId(this.props.item);
        if (deviceTypeCheckers.isVirtualProduct(this.props.item) && parentId) {
            return this.actionService.togglePanel(parentId, `profile_${this.props.item._id}`);
        }
        this.actionService.togglePanel(this.props.item._id, 'profile');
    };
}

export const ProfileSelector = connect(mapStateToProps)(ProfileSelectorContainer);
