import * as React from 'react';
import type { Colors } from 'app/styles';
import { Border } from '../style';
import { Box } from '../containers';

interface IBarChartProps {
    percent: number;
    color?: Colors;
}

export const BarChart: React.FunctionComponent<IBarChartProps> = ({ percent, color }) => {
    const normalizedPercent = Math.max(0, Math.min(percent, 100));
    if (normalizedPercent !== percent && percent > 100) {
        color = 'red';
        percent = normalizedPercent;
    }
    const height = `${percent}%`;

    return (
        <Border color={color ? color : 'grey4'}>
            <Box width="28px" height="37px" alignItems="end" flex="none">
                <Box flex="shrinkAndGrow" height={height} color={color ? color : 'grey4'} />
            </Box>
        </Border>
    );
};

BarChart.displayName = 'BarChart';
