import * as React from 'react';
import * as leaflet from 'leaflet';
import { css } from '@emotion/css';
import { AppConstants } from 'app/AppConstants';
import { ServiceLocator } from 'app/ioc';
import type { PolyLine, Id, IInstallationPointModel } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { BaseCone } from './BaseCone';
import type { LeafletMap } from '../LeafletMap';
import type { ISensorCoverageAreaInfo } from '../../../models';
import { MapsActionService } from '../../../services';
import {
    useDragEndEvent,
    useDragEvent,
    useMouseClickEvent,
    useMouseDownEvent,
} from '../../../hooks/useLeafletMouseEvent';
import { getHorizontalFov } from '../../../utils';
import { popupStyle } from './utils';
import { calculateVerticalFov, estimateVerticalFOV } from 'app/modules/common';
import type { IFOVLimits } from 'app/modules/common';
import { usePopupCloseDelay } from './usePopupDelay.hook';

function getPopupAngle(
    horizontalFov: number,
    fovLimits: IFOVLimits,
    corridorFormat: boolean,
    productId?: Id,
) {
    if (!corridorFormat) return horizontalFov;
    const verticalFov = productId
        ? calculateVerticalFov(
              horizontalFov,
              fovLimits.horizontal.max,
              fovLimits.horizontal.min,
              fovLimits.vertical.max,
              fovLimits.vertical.min,
          )
        : estimateVerticalFOV(horizontalFov);

    return verticalFov;
}

const fovDragHandleStyle = (color: Colors) => css`
    background-color: ${ColorsEnum[color]};
    border: 2px solid ${ColorsEnum.white};
    width: 16px;
    height: 16px;
    border-radius: 50px;
`;

const draggableAreaStyle = css`
    margin: -13px;
    padding: 4px;
    border-radius: 50px;
`;

interface IFovHandleProps {
    map: LeafletMap;
    blockers: PolyLine[] | undefined;
    color: Colors;
    coverageArea: BaseCone;
    installationPoint: IInstallationPointModel;
    sensorCoverageAreaInfo: ISensorCoverageAreaInfo;
}

/**
 * Component responsible for creating the fov handle
 */
export const FovHandle: React.FC<IFovHandleProps> = ({
    map,
    blockers,
    color,
    coverageArea,
    installationPoint,
    sensorCoverageAreaInfo,
}) => {
    const [fovHandle] = React.useState<leaflet.Marker>(
        leaflet.marker(coverageArea.getFovHandleLatLng() ?? installationPoint.location, {
            draggable: !map.readOnly,
            interactive: !map.readOnly,
            icon: new leaflet.DivIcon({
                className: draggableAreaStyle,
                html: `<div class="${fovDragHandleStyle(
                    color,
                )}" data-test-id="fov_drag_handle"></div>`,
                iconSize: undefined, // bug! Leaflet defaults to 12x12 otherwise
            }),
            zIndexOffset: AppConstants.fovHandleDepth,
            opacity: 1,
            bubblingMouseEvents: false,
            title: '',
        }),
    );

    const showPopup = (popupContent: string | null) => {
        if (popupContent) {
            fovHandle.setPopupContent(popupContent);
            fovHandle.openPopup();
            setIsPopupVisible(true);
        }
    };

    const [actions] = React.useState(ServiceLocator.get(MapsActionService));
    const [isPopupVisible, setIsPopupVisible] = React.useState(false);
    const closePopup = React.useCallback(() => {
        fovHandle.closePopup();
        setIsPopupVisible(false);
    }, [fovHandle]);
    const closeWhenNoActivity = usePopupCloseDelay(closePopup);

    React.useEffect(() => {
        fovHandle.closePopup();
        setIsPopupVisible(false);
    }, [sensorCoverageAreaInfo.corridorFormat, fovHandle]);

    useMouseDownEvent(fovHandle, () => {
        actions.selectMapItem(
            installationPoint,
            'installationPoint',
            coverageArea.sensor?.sensorId,
        );
    });

    useDragEvent(fovHandle, (e) => {
        const horizontalFov = Math.min(
            getHorizontalFov(
                installationPoint,
                sensorCoverageAreaInfo,
                e.latlng,
                coverageArea.getTargetCoords(),
            ),
            145,
        );

        const popupContent = coverageArea.getFovPopupContent(
            getPopupAngle(
                horizontalFov,
                sensorCoverageAreaInfo.fovLimits,
                sensorCoverageAreaInfo.corridorFormat,
                installationPoint.parentId,
            ),
        );
        showPopup(popupContent);
        closeWhenNoActivity();
        coverageArea.reDraw(blockers, { horizontalFov });

        actions.updateSelectedCoverageAreaInfo({
            ...sensorCoverageAreaInfo,
            horizontalFov,
        });
    });

    useDragEndEvent(fovHandle, () => {
        coverageArea.persistCoverageInfo({
            horizontalAngle: sensorCoverageAreaInfo.horizontalAngle,
            distance: sensorCoverageAreaInfo.targetDistance,
        });
    });

    useMouseClickEvent(fovHandle, () => {
        if (!isPopupVisible) {
            const popupContent = coverageArea.getFovPopupContent(
                getPopupAngle(
                    sensorCoverageAreaInfo.horizontalFov,
                    sensorCoverageAreaInfo.fovLimits,
                    sensorCoverageAreaInfo.corridorFormat,
                    installationPoint.parentId,
                ),
            );
            showPopup(popupContent);
            closeWhenNoActivity();
        } else {
            fovHandle.closePopup;
            setIsPopupVisible(false);
        }
    });

    React.useEffect(() => {
        fovHandle.bindPopup('', {
            closeOnClick: false,
            className: popupStyle,
            closeButton: false,
        });
        fovHandle.addTo(map.map);

        return () => {
            fovHandle.removeFrom(map.map);
        };
    }, [fovHandle, map.map]);

    React.useEffect(() => {
        fovHandle.setIcon(
            new leaflet.DivIcon({
                className: draggableAreaStyle,
                html: `<div class="${fovDragHandleStyle(
                    color,
                )}" data-test-id="fov_drag_handle"></div>`,
                iconSize: undefined, // bug! Leaflet defaults to 12x12 otherwise
            }),
        );
    }, [color, fovHandle]);

    React.useMemo(() => {
        const fovHandleLatLng = coverageArea.getFovHandleLatLng();
        if (!fovHandleLatLng) {
            return;
        }

        fovHandle.setLatLng(fovHandleLatLng);
        // We also need to update position when the ip/sensor has changed e.g
        // dragging targetHandle, dragging ip, installation height
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        coverageArea,
        fovHandle,
        sensorCoverageAreaInfo,
        installationPoint.location,
        installationPoint.height,
    ]);

    return null;
};

FovHandle.displayName = 'FovHandle';
