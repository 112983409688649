import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';

export const getPressedModifierKeys = createSelector([getMapsState], (state) => {
    return state.pressedModifierKeys;
});

export const getDuplicationInfo = createSelector([getMapsState], (state) => {
    return state.duplicationInfo;
});
