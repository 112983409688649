/**
 * A JavaScript model of the Normal (or Gaussian) distribution.
 * http://en.wikipedia.org/wiki/Normal_distribution
 */
export class Guassian {
    constructor(
        private mean: number,
        private standardDeviation: number,
    ) {}

    /**
     * Probability density function
     * Implemented per notes from https://docs.scipy.org/doc/scipy/reference/generated/scipy.stats.norm.html#scipy-stats-norm
     */
    public pdf(array: number[]) {
        return array.map((n) => {
            n = (n - this.mean) / this.standardDeviation;
            const e = Math.exp(-Math.pow(n, 2) / 2);
            const m = Math.sqrt(2 * Math.PI);
            return e / m / this.standardDeviation;
        });
    }
}
