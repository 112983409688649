import * as React from 'react';
import { CommonActionService } from 'app/modules/common';
import { useService } from 'app/ioc';
import { Heading, Box, Button, Wrapper, Text, Spacer } from 'app/components';
import { t } from 'app/translate';

const mapImage = require('assets/images/mapSplash.svg');

export const MapSignIn: React.FC = () => {
    const commonActionService = useService(CommonActionService);

    const onSignIn = () => {
        commonActionService.login();
    };

    return (
        <Box
            position="relative"
            justifyContent="between"
            alignItems="start"
            height="100%"
            width="100%"
            overflowX="hidden"
        >
            <Wrapper>
                <Box
                    direction="column"
                    spacing="base"
                    flex="shrinkAndGrow"
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src={mapImage} />
                    <Heading style="headline">{t.mapWelcome}</Heading>
                    <Box maxWidth="300px">
                        <Text color="grey6" style="heading" align="center">
                            {t.mapSignIn}
                        </Text>
                    </Box>

                    <Button testId="btn_map_sign_in" primary onClick={onSignIn}>
                        {t.userSignIn}
                    </Button>
                </Box>
                <Spacer customSpacing="35%" />
            </Wrapper>
        </Box>
    );
};
