import * as React from 'react';
import type { ErrorInfo } from 'react';
import type { IExtendableComponentWithChildren } from 'app/components';
import {
    Stack,
    Button,
    Icon,
    Heading,
    Box,
    Text,
    Border,
    Spinner,
    extendableProps,
    renderReactChildren,
} from 'app/components';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { ApplicationActionService } from 'app/modules/application';
import { ServiceLocator } from 'app/ioc';
import { throttle } from 'lodash-es';
import { AppConstants } from 'app/AppConstants';
interface IErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
    lastMsg: string | Event;
    shareButtonDisabled: boolean;
    reloadButtonDisabled: boolean;
    sourceMappedStackTraces?: string[];
}

// this class catch JavaSCript errors anywhere in the child component tree, log those errors and display a fallback UI.
// Error boundaries catch errors during rendering, in lifecycle methods and in constructors of the whole tree below them.
// Use this class around any component where you want this fallback UI to be displayed if component crash.

export default class ErrorBoundary extends React.Component<
    IExtendableComponentWithChildren,
    IErrorBoundaryState
> {
    private applicationActionService: ApplicationActionService;

    constructor(props: IExtendableComponentWithChildren) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
            lastMsg: '',
            shareButtonDisabled: false,
            reloadButtonDisabled: false,
        };
        this.applicationActionService = ServiceLocator.get(ApplicationActionService);
    }

    static getDerivedStateFromError(error: string) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo });
        this.onComponentDidCatchThrottled(error);
    }

    componentDidMount() {
        window.addEventListener('error', this.onWindowErrorThrottled);
    }

    componentWillUnmount() {
        window.removeEventListener('error', this.onWindowErrorThrottled);
    }

    render() {
        const { ...extendedProps } = this.props;

        if (this.state.hasError) {
            return (
                <Box
                    testId="error_boundary"
                    height="100%"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    {...extendedProps}
                >
                    <Border color="grey6">
                        <Box
                            color="grey2"
                            borderRadius="rounded"
                            padding="panel"
                            justifyContent="center"
                            flex="dontShrink"
                            alignItems="center"
                            minWidth="30%"
                        >
                            <Stack vertical alignItems="center" notFullWidth>
                                <Stack alignItems="center" spacing="quart">
                                    {!this.state.reloadButtonDisabled && (
                                        <>
                                            <Icon
                                                color={
                                                    this.state.shareButtonDisabled ? 'green' : 'red'
                                                }
                                                icon={
                                                    this.state.shareButtonDisabled
                                                        ? 'check'
                                                        : 'error'
                                                }
                                                opaque
                                                size="md"
                                            />

                                            <Heading style="title">
                                                {this.state.shareButtonDisabled
                                                    ? t.errorBoundarySentHeader
                                                    : t.errorBoundaryHeader}
                                            </Heading>
                                        </>
                                    )}
                                </Stack>
                                {this.state.reloadButtonDisabled && <Spinner size={50} />}
                                {!this.state.reloadButtonDisabled && (
                                    <Text large>
                                        {this.state.shareButtonDisabled
                                            ? t.errorBoundarySentMessage
                                            : t.errorBoundaryText}
                                    </Text>
                                )}
                                <Stack spacing="half">
                                    <Button
                                        onClick={this.reload}
                                        primary={this.state.shareButtonDisabled ? true : false}
                                        disabled={this.state.reloadButtonDisabled}
                                    >
                                        {t.errorBoundaryButton}
                                    </Button>
                                    <Button
                                        onClick={this.onErrorShare}
                                        primary={this.state.shareButtonDisabled ? false : true}
                                        disabled={this.state.shareButtonDisabled}
                                    >
                                        {t.errorBoundaryShare}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Border>
                </Box>
            );
        }

        const attributes = extendableProps(extendedProps, {});

        return renderReactChildren(
            this.props.children,
            (c) => React.cloneElement(c, attributes),
            (c) => React.cloneElement(c, attributes.__htmlAttributes),
        );
    }

    private reload() {
        location.assign(location.origin);
    }

    private logError = async (error: Error) => {
        const errorMessage = `error: ${error}`;
        eventTracking.logError(errorMessage, 'ErrorBoundary');
    };

    private onWindowErrorThrottled = throttle((event: ErrorEvent) => {
        this.onWindowError(event);
    }, 1000);

    private onComponentDidCatchThrottled = throttle((error: Error) => {
        this.logError(error);
    }, 1000);

    private onWindowError = async (event: ErrorEvent) => {
        const { error, filename, lineno, message } = event;
        if (this.state.lastMsg !== message) {
            this.setState({ lastMsg: message });
            const errorLocation = `WindowOnError:\nfilename: ${filename} \nline: ${lineno}`;
            eventTracking.logError(`message: ${message} \nerror: ${error}\n\n`, errorLocation);
            return true;
        }
        return false;
    };

    private onErrorShare = async () => {
        this.setState({ shareButtonDisabled: true, reloadButtonDisabled: true });
        this.setState({ reloadButtonDisabled: false });
        const userAgent = window.navigator.userAgent;
        const language = window.navigator.language;
        const stack = `stack: ${this.state.sourceMappedStackTraces?.join('\n\n') ?? ''}`;
        const componentStack = this.state.errorInfo?.componentStack ?? '';
        const componentStackInfo = `componentStack: ${componentStack}`;
        const errorMessage = `error: ${this.state.error}`;
        const browser = this.getBrowser();
        const errorToSend =
            `${errorMessage}\n\n` +
            `${stack}\n\n` +
            `${componentStackInfo}\n\n` +
            `userAgent: ${userAgent}\n\n` +
            `Browser language: ${language}\n\n` +
            `Browser: ${browser}\n\n` +
            `Location: ${window.location.href}\n\n` +
            `Version: ${AppConstants.appVersion}`;
        if (errorToSend) {
            this.applicationActionService.sendFeedback(errorToSend).payload;
        }
    };

    private getBrowser = () => {
        const userAgent = window.navigator.userAgent;
        let browserName;
        if (userAgent?.match(/edg/i)) {
            browserName = 'Edge';
        } else if (userAgent?.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
        } else if (userAgent?.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
        } else if (userAgent?.match(/safari/i)) {
            browserName = 'Safari';
        } else if (userAgent?.match(/opr\//i)) {
            browserName = 'Opera';
        } else {
            browserName = 'No browser detection';
        }
        return browserName;
    };
}
