import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';

interface ITitle extends IExtendableComponentWithChildren {
    /** The title attribute value */
    title: string;
}
/**
 * Adds the title attribute to its children
 */
export class Title extends React.Component<ITitle> {
    public render() {
        const { children, title, ...extendedProps } = this.props;
        const attributes = extendableProps(extendedProps, { title });
        return renderReactChildren(
            children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes.__htmlAttributes),
            (child) => <span title={title}>{child}</span>,
        );
    }
}
