import { Module } from 'app/ioc';
import { ModalActionService } from './Modal.ActionService';
import { ModalService } from './Modal.service';
import { modalReducer } from './modalReducer';

@Module({
    binder: (binder) => {
        binder.bindService(ModalService);
        binder.bindService(ModalActionService);
    },
    reducers: {
        modal: modalReducer,
    },
})
export class ModalModule {}
