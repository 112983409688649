import * as React from 'react';
import { DocumentationActionService, DocumentationService } from '../services';
import { Checkbox, IconButton, Stack } from 'app/components';
import { t } from 'app/translate';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import { getVisibleDevicesId, getSortedDocumentationDevicesLength } from '../selectors';
import type { PiaId } from 'app/core/pia';
import { getCurrentProjectName } from 'app/modules/common';

interface IDocumentationBottomProps {
    projectName: string;
    selectedDevices: PiaId[];
    devicesId: PiaId[];
    isAllSelected: boolean;
}

const mapStateToProps = (storeState: IStoreState): IDocumentationBottomProps => {
    const devicesLength = getSortedDocumentationDevicesLength(storeState);
    return {
        projectName: getCurrentProjectName(storeState),
        selectedDevices: storeState.documentation.selectedPiaIds,
        devicesId: getVisibleDevicesId(storeState),
        isAllSelected:
            devicesLength > 0 && storeState.documentation.selectedPiaIds.length === devicesLength,
    };
};

class DocumentationBottomContainer extends React.Component<IDocumentationBottomProps> {
    private documentationActionService: DocumentationActionService;
    private documentationService: DocumentationService;

    constructor(props: Readonly<IDocumentationBottomProps>) {
        super(props);
        this.documentationActionService = ServiceLocator.get(DocumentationActionService);
        this.documentationService = ServiceLocator.get(DocumentationService);
    }

    public render() {
        return (
            <Stack justifyContent="end">
                <Stack>
                    <Checkbox
                        testId="select_all"
                        selected={this.props.isAllSelected}
                        onChange={this.selectAll}
                    >
                        {t.documentationSelectAll}
                    </Checkbox>
                    <IconButton
                        text={t.documentationDownloadSelected}
                        icon="file_download"
                        onClick={() =>
                            this.documentationService.getDatasheets(
                                this.props.selectedDevices,
                                this.props.projectName,
                            )
                        }
                        disabled={this.props.selectedDevices.length === 0}
                    />
                </Stack>
            </Stack>
        );
    }

    private selectAll = () => {
        this.props.isAllSelected
            ? this.documentationActionService.clearSelectedDevices()
            : this.documentationActionService.selectDevices(this.props.devicesId);
    };
}

export const DocumentationBottom = connect(mapStateToProps)(DocumentationBottomContainer);
