import { useService } from 'app/ioc';
import * as React from 'react';
import { ProjectDevicesActionService } from '../../services';
import type { IItemEntity, IPersistence, Id } from 'app/core/persistence';
import {
    getCurrentProjectItem,
    getDeviceBandwidthTotal,
    getDeviceStorageTotal,
    getModelName,
} from 'app/modules/common';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ReadDirection, Stack, toTestIdFormat, Text } from 'app/components';
import { getIsExpanded } from '../../selectors';
import { DevicesDetailPanel } from './detailPanel';
import { css, cx } from '@emotion/css';
import {
    AddOnsCol,
    ExpandButton,
    DropDownCol,
    ModelCol,
    NameInputAndIconCol,
    ProfileCol,
    QuantityCol,
} from './deviceListColumns';
import { ColorsEnum } from 'app/styles';

interface IDeviceListItem {
    itemId: Id;
    isChild?: boolean;
    isSelected?: boolean;
    parentId?: Id;
    isLast?: boolean;
    onFocus?(id?: string | React.SyntheticEvent): void;
}

const pseudoElementShadowStyle = css`
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 65px;
    display: block;
    box-shadow:
        0px 8px 12px 6px rgba(0, 0, 0, 0.1),
        0px 4px 4px rgba(0, 0, 0, 0.15);
    transition: opacity 300ms ease-in-out;
    pointer-events: none;
`;

const trStyle = css`
    background-color: ${ColorsEnum.white};
    &:after {
        ${pseudoElementShadowStyle}
        transition-delay: 300ms;
        opacity: 0;
    }
`;

const selectedTrStyle = css`
    ${trStyle}
    z-index: 1;
`;

const centerAlignStyle = { verticalAlign: 'middle' };

const expandButtonCellStyle = {
    width: '15px',
    height: '100%',
};

const boxShadowStyle = css`
    &:after {
        ${pseudoElementShadowStyle}
        opacity: 1;
    }
`;

const collapsedStyle = css`
    display: none;
    opacity: 0;
`;

const visibleStyle = css`
    opacity: 1;
`;

export const DeviceListItem: React.FC<IDeviceListItem> = ({
    itemId,
    isSelected,
    isChild,
    parentId,
    isLast,
    onFocus,
}) => {
    const actionService = useService(ProjectDevicesActionService);
    const item = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, itemId),
    );
    const model = useSelector<IStoreState, string>((state) => getModelName(state, itemId));

    const expanded = useSelector<IStoreState, boolean>((state) => getIsExpanded(state, itemId));
    const onExpand = () => {
        actionService.toggleExpandedDevice(itemId);
    };

    const isParentExpanded = useSelector<IStoreState, boolean>((state) =>
        parentId ? getIsExpanded(state, parentId) : false,
    );

    const bandwidth = useSelector<IStoreState, string | undefined>(
        (state) => getDeviceBandwidthTotal(state, itemId)?.formattedBandwidth,
    );
    const storage = useSelector<IStoreState, string | undefined>(
        (state) => getDeviceStorageTotal(state, itemId)?.formattedStorage,
    );

    if (!item) return null;

    return (
        <>
            <tr
                data-test-id={toTestIdFormat(`tr_${model}`)}
                onClick={onFocus}
                aria-selected={isSelected}
                aria-details={isChild ? parentId : undefined}
                className={cx(
                    isSelected ? selectedTrStyle : trStyle,
                    expanded ? boxShadowStyle : undefined,
                    isParentExpanded ? collapsedStyle : visibleStyle,
                )}
            >
                <td style={expandButtonCellStyle}>
                    <ExpandButton
                        testId={`expand_btn_${model}`}
                        hideExpandButton={isChild}
                        isOpen={expanded}
                        onToggle={onExpand}
                    />
                </td>
                <NameInputAndIconCol
                    itemId={itemId}
                    isChild={isChild}
                    isLast={isLast}
                    piaId={item.productId ?? null}
                />
                <td style={centerAlignStyle}>
                    <QuantityCol itemId={itemId} isChild={isChild} />
                </td>
                <td>
                    <ModelCol itemId={itemId} />
                </td>
                <td>
                    <ProfileCol itemId={itemId} />
                </td>
                <td>
                    <AddOnsCol itemId={itemId} piaId={item.productId ?? null} />
                </td>
                <td style={centerAlignStyle}>
                    <Stack>
                        <ReadDirection direction="ltr">
                            <Text testId={`td_bandwidth_${model}`}>{bandwidth}</Text>
                        </ReadDirection>
                    </Stack>
                </td>
                <td style={centerAlignStyle}>
                    <Stack>
                        <ReadDirection direction="ltr">
                            <Text testId={`td_storage_${model}`}>{storage}</Text>
                        </ReadDirection>
                    </Stack>
                </td>
                <td>
                    <DropDownCol itemId={itemId} isChild={isChild} />
                </td>
            </tr>
            {!isChild && (
                <tr>
                    <DevicesDetailPanel isOpen={expanded} item={item} />
                </tr>
            )}
        </>
    );
};

DeviceListItem.displayName = 'DeviceListItem';
