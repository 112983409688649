export type Point = [number, number];

export const ORIGO: Point = [0, 0];

export const isPoint = (point: any): point is Point =>
    Array.isArray(point) &&
    point.length === 2 &&
    typeof point[0] === 'number' &&
    point[1] === 'number';

/** calculate the difference between two points */
export const diff = (a: Point, b: Point): Point => [a[0] - b[0], a[1] - b[1]];

/** add two points */
export const add = (a: Point, b: Point): Point => [a[0] + b[0], a[1] + b[1]];

/** multiply the coordinates with a scalar value */
export const mul = (point: Point, val: number): Point => [point[0] * val, point[1] * val];

/** Calculate the 2D cross product. Note that the result is a scalar and not a vector */
export const crossProduct = (a: Point, b: Point): number => a[0] * b[1] - a[1] * b[0];

/** Calculate the dot product */
export const dotProduct = (a: Point, b: Point): number => a[0] * b[0] + a[1] * b[1];

/** calculate the distance between two points */
export const distance = (a: Point, b: Point): number => distanceFromOrigo(diff(a, b));

/** calculate the distance from a point to origo */
export const distanceFromOrigo = (point: Point): number =>
    Math.sqrt(point[0] * point[0] + point[1] * point[1]);

/**
 * Checks whether a vector point between two other vectors. A clockwise oreintation
 * is assumed.
 *
 * @param start - The first vector to check against
 * @param end - The second vector to check against
 * @param x - The vector to check
 * @param includeEndPoints - Whether end points should be included
 * @returns Whether x is between start and end (clockwise)
 */
export const isBetween = (start: Point, end: Point, x: Point, includeEndPoints = true): boolean => {
    // check whether we have a less than 180 degree rotation
    const lt180 = orientation(ORIGO, start, end) >= 0;
    const toStart = orientation(ORIGO, x, start);
    const toEnd = orientation(ORIGO, x, end);

    if (includeEndPoints) {
        return lt180 ? toStart <= 0 && toEnd >= 0 : !(toStart > 0 && toEnd < 0);
    } else {
        return lt180 ? toStart < 0 && toEnd > 0 : !(toStart >= 0 && toEnd <= 0);
    }
};

/**
 * Checks two points are equal
 *
 * @param a - The first point
 * @param b - The second point
 * @returns Whether the points are equal
 */
export const equals = (a: Point, b: Point) => a[0] === b[0] && a[1] === b[1];

/**
 * Returns the orientation of three points, i.e whether they describe a clockwise,
 * counter clockwise rotation or are colinear.
 *
 * See https://www.geeksforgeeks.org/orientation-3-ordered-points/
 *
 * @param p - The first point
 * @param q - The second point
 * @param r - The third point
 * @returns The orientation of the points:
 *          1 if the points describe a clockwise rotation
 *         -1 if the points describe a counter clockwise rotation
 *          0 if the points are colinear
 */
export const orientation = (p: Point, q: Point, r: Point): number => {
    const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);

    if (val === 0) {
        // colinear
        return 0;
    }

    return val > 0 ? 1 : -1; // clock or counterclock wise
};

/** Check whether the vector b is rotated clockwise compared to vector a */
export const isRotatedClockwise = (a: Point, b: Point): boolean => crossProduct(a, b) > 0;
