import { injectable } from 'inversify';
import type { IHourSegment, DaySegments } from '../../models';
import type { IAggregatedScheduleModel, ISegmentModel } from 'app/modules/common';

const QUARTERS_PER_HOUR = 4;
const HOURS_PER_DAY = 24;

interface ITimeValues {
    startHour: number;
    startQuarter: number;
    endHour: number;
    endQuarter: number;
}

@injectable()
export class AggregatedScheduleToQuartersService {
    public getQuarters(aggregatedSchedule: IAggregatedScheduleModel): DaySegments {
        return {
            monday: this.getDayHours(aggregatedSchedule.monday),
            tuesday: this.getDayHours(aggregatedSchedule.tuesday),
            wednesday: this.getDayHours(aggregatedSchedule.wednesday),
            thursday: this.getDayHours(aggregatedSchedule.thursday),
            friday: this.getDayHours(aggregatedSchedule.friday),
            saturday: this.getDayHours(aggregatedSchedule.saturday),
            sunday: this.getDayHours(aggregatedSchedule.sunday),
        };
    }

    private getDayHours(daySegments: ISegmentModel[]): IHourSegment[] {
        const hours = this.getNewHoursArray();

        for (const daySegment of daySegments) {
            const { startHour, startQuarter, endHour, endQuarter } = this.getTimeValues(daySegment);

            if (startHour === endHour) {
                this.setQuarters(startQuarter, endQuarter, hours[startHour].quarters);
                continue;
            }

            // Set quarters of the first hour of the period
            this.setQuarters(startQuarter, QUARTERS_PER_HOUR, hours[startHour].quarters);

            // Settings quarters of full hours between start and end hour, if period is longer than one hour
            for (let hour = startHour + 1; hour < endHour; hour++) {
                this.setQuarters(0, QUARTERS_PER_HOUR, hours[hour].quarters);
            }

            if (endQuarter === 0) {
                continue;
            }

            if (endHour >= HOURS_PER_DAY) {
                continue;
            }

            this.setQuarters(0, endQuarter, hours[endHour].quarters);
        }

        return hours;
    }

    private setQuarters(from: number, to: number, quarters: boolean[]) {
        for (let i = from; i < to; i++) {
            quarters[i] = true;
        }
    }

    private getNewHoursArray(): IHourSegment[] {
        const hours: IHourSegment[] = [];

        for (let i = 0; i < HOURS_PER_DAY; i++) {
            hours.push({ quarters: new Array(QUARTERS_PER_HOUR).fill(false) });
        }

        return hours;
    }

    private getTimeValues(segment: ISegmentModel): ITimeValues {
        const startHour = Math.floor(segment.start);
        const endHour = Math.floor(segment.end);

        return {
            startHour,
            startQuarter: (segment.start - startHour) * QUARTERS_PER_HOUR,
            endHour,
            endQuarter: (segment.end - endHour) * QUARTERS_PER_HOUR,
        };
    }
}
