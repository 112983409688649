export const getCookieValue = (cookieName: string) => {
    return document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${cookieName}=`))
        ?.split('=')[1];
};

export const isMeasurementAllowedByCookie = () => {
    const cookieValue = getCookieValue('cookie-agreed-categories');
    return cookieValue ? cookieValue.indexOf('measurement') > 0 : false;
};

export const isMarketingAllowedByCookie = () => {
    const cookieValue = getCookieValue('cookie-agreed-categories');
    return cookieValue ? cookieValue.indexOf('marketing') > 0 : false;
};
