import { getPiaIdFromProps } from './../../common/selectors/getIdFromProps';
import { getSelectedMountIdsForSelectedItem } from './getMounts';
import { isDefined } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectItem,
    getCurrentProjectItems,
    getCurrentProjectLocation,
    getCurrentProjectRelationsForItem,
    getCurrentProjectRelationsRecord,
    getIdFromProps,
    getIsStandalone,
    getItemVersion,
    getPiaItem,
    getPiaItemsRecord,
    getRelatedPiaItemsForItem,
    getSelectedMountIdsForItemId,
    toCacheKey,
} from 'app/modules/common';
import type { IPiaItem, PiaId, IPiaAccessoryProperties, IPiaLocation } from 'app/core/pia';
import {
    PiaItemWearablesCategory,
    PiaAccessoryCategory,
    filterProducts,
    isDiscontinued,
} from 'app/core/pia';

import { PIA_CATEGORY_TO_DISPLAY_CATEGORY, DisplayCategories } from '../DisplayCategories';
import {
    isDoorController,
    type Id,
    type IIdRevModel,
    type IItemEntity,
    type IItemRelationEntity,
    type IPersistence,
} from 'app/core/persistence';
import { t } from 'i18next';
import { getSelectedMount } from './getDeviceMounts';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import { nameComparator } from 'app/utils';
import { getSelectedDeviceId } from './getSelectedDevice';

const ACCESSORY_CATEGORIES: string[] = [
    // Connectivity
    PiaAccessoryCategory.CABLESCON,
    PiaAccessoryCategory.ETHERNETOVERCOAX,
    PiaAccessoryCategory.MEDIACONVERTERS,
    PiaAccessoryCategory.SURGEPROTECTORS,
    PiaAccessoryCategory.POEEXTENDERS,
    // Power
    PiaAccessoryCategory.POWERSUPPLIES,
    // Storage
    PiaAccessoryCategory.STORAGE,
    // Housings and cabinets
    PiaAccessoryCategory.COVERSDOMES,
    PiaAccessoryCategory.CABINETS,
    PiaAccessoryCategory.HOUSINGS,
    // Modules
    PiaAccessoryCategory.MODULES,
    // Mounts
    PiaAccessoryCategory.MOUNTS,
    // Audio and I/O
    PiaAccessoryCategory.AUDIOANDIO,
    PiaAccessoryCategory.AMPLIFIERS,
    PiaAccessoryCategory.BRIDGES,
    PiaAccessoryCategory.MICROPHONES,
    PiaAccessoryCategory.POE,
    // Illuminators
    PiaAccessoryCategory.ILLUMINATORS,
    // Servers
    PiaAccessoryCategory.SERVERS,
    // Miscellaneous
    PiaAccessoryCategory.MISCELLANEOUS,
];

const ACCESSORY_CATEGORIES_ONLY_STANDALONE: string[] = [
    // Lenses
    PiaAccessoryCategory.LENSES,
];

interface IRelatedPiaItem extends IPiaItem {
    isIncluded: boolean;
    isRecommended: boolean;
}

export interface ICategorizedAccessoryItem extends IRelatedPiaItem {
    displayCategory: string;
    hasRequiredAccessories: boolean;
}

export interface ISelectedMountAccessory extends IIdRevModel {
    productId: PiaId;
    /** Product/model name */
    name: string;
    isDiscontinued: boolean;
}
/**
 * Usually accessories of type 'mount' that is not of sortingPriority 1 is hidden as accessories
 * since they are possible to select in the mounting chain. Wearable mount are not possible to select in
 * the mounting chain since:
 * 1. it should be possible to select more than one 'mount' (perhaps you want different mounts to the same wearable camera).
 * 2. For the moment it is not possible to select any wearable mount environment at all (environment internally launched only)
 *
 * @param category string
 */
const isWearableExceptionCategory = (category: string | undefined) => {
    return (
        category === PiaItemWearablesCategory.CAMERAS ||
        category === PiaItemWearablesCategory.CAMERAEXTENSIONS
    );
};

export const getAccessorySearchFilter = (state: IStoreState) =>
    state.accessorySelector.accessoryFilter;

export const getAccessoriesMatchingSearch = (
    searchText: string | undefined,
    accessories: Record<string, ICategorizedAccessoryItem[]>,
) => {
    if (searchText) {
        return Object.entries(accessories).reduce(
            (filteredAccessories, [category, accessoriesCategory]) => {
                const matchingAccessories = accessoriesCategory.filter((accessory) =>
                    accessory.name.toLowerCase().includes(searchText?.toLowerCase()),
                );
                filteredAccessories[category] = matchingAccessories;
                return filteredAccessories;
            },
            {} as Record<string, ICategorizedAccessoryItem[]>,
        );
    } else {
        return accessories;
    }
};

export const getMountAccessoriesForProduct = createCachedSelector(
    [
        getRelatedPiaItemsForItem,
        getIsStandalone,
        getPiaItemsRecord,
        getPiaIdFromProps,
        getCurrentProjectItems,
    ],
    (relatedPiaItems, isStandalone, piaRecord, piaId, currentProjectItems) =>
        mapMountAccessoriesForProduct(
            relatedPiaItems,
            isStandalone,
            piaRecord,
            piaId,
            currentProjectItems,
        ),
)(toCacheKey);

export const getAllMountAccessoriesForProduct = createCachedSelector(
    [
        getRelatedPiaItemsForItem,
        getIsStandalone,
        getPiaItemsRecord,
        getPiaIdFromProps,
        getCurrentProjectItems,
        getSelectedMount,
    ],
    (relatedPiaItems, isStandalone, piaRecord, piaId, currentProjectItems, selectedMount) => {
        const mountAccessories = mapMountAccessoriesForProduct(
            relatedPiaItems,
            isStandalone,
            piaRecord,
            piaId,
            currentProjectItems,
            selectedMount,
            true,
        );

        return mountAccessories;
    },
)(toCacheKey);

const mapMountAccessoriesForProduct = (
    relatedPiaItems: IRelatedPiaItem[],
    isStandalone: boolean,
    piaRecord: Record<number, IPiaItem>,
    piaId: PiaId | undefined | null,
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
    selectedMount?: IPersistence<IItemEntity>,
    includeAllMounts?: boolean,
) => {
    const selectedPiaItem = piaId ? piaRecord[piaId] : undefined;
    const accessoryCategories = isStandalone
        ? [...ACCESSORY_CATEGORIES, ...ACCESSORY_CATEGORIES_ONLY_STANDALONE]
        : ACCESSORY_CATEGORIES;

    if (!isDoorController(selectedPiaItem) || isStandalone) {
        accessoryCategories.push(PiaAccessoryCategory.READERS);
    }

    const piaIdsInPath = selectedMount?.path.map(
        (pathItem) => currentProjectItems[pathItem]?.productId,
    );
    const mountAccessoriesNotInMountingChain = relatedPiaItems
        .filter(filterProducts.byCategories(accessoryCategories))
        .filter(
            isWearableExceptionCategory(selectedPiaItem?.category) || includeAllMounts
                ? () => true
                : isNotInMountingChain,
        )
        //* Ensure items can't be selected in a loop
        .filter((item) => !piaIdsInPath?.includes(item.id))
        .map((relationPiaItem) => {
            return {
                ...relationPiaItem,
                displayCategory:
                    combineCategories(PIA_CATEGORY_TO_DISPLAY_CATEGORY[relationPiaItem.category]) ??
                    DisplayCategories.OTHER,
            } as ICategorizedAccessoryItem;
        })
        .filter(isDefined);

    const accessoriesByCategory = mountAccessoriesNotInMountingChain.reduce(
        (byCategory, accessory) => {
            byCategory[accessory.displayCategory] = [
                ...(byCategory[accessory.displayCategory] ?? []),
                accessory,
            ];
            return byCategory;
        },
        {} as Record<string, ICategorizedAccessoryItem[]>,
    );

    for (const key of Object.keys(accessoriesByCategory)) {
        accessoriesByCategory[key].sort(nameComparator);
    }

    return accessoriesByCategory;
};

/** Gets selected mount accessories for a specific id */
export const getSelectedMountAccessoriesForItemId = createCachedSelector(
    [
        getCurrentProjectRelationsForItem,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getSelectedMountIdsForItemId,
        getIdFromProps,
    ],
    (
        relations,
        items,
        piaItems,
        selectedMountIds,
        selectedId,
    ): Record<PiaId, ISelectedMountAccessory> =>
        toMountAccessoryRecord(relations, items, piaItems, selectedMountIds, selectedId),
)(toCacheKey);

/** Gets selected mount accessories for currently selected item in device details */
export const getSelectedMountAccessoriesForSelectedItem = createSelector(
    [
        getCurrentProjectRelationsRecord,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getSelectedMountIdsForSelectedItem,
        getSelectedDeviceId,
    ],
    (
        relationRecord,
        items,
        piaItems,
        selectedMountIds,
        selectedId,
    ): Record<PiaId, ISelectedMountAccessory> =>
        toMountAccessoryRecord(
            selectedId && relationRecord[selectedId] ? relationRecord[selectedId] : [],
            items,
            piaItems,
            selectedMountIds,
            selectedId,
        ),
);

const toMountAccessoryRecord = (
    relations: IItemRelationEntity[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    piaItems: Record<number, IPiaItem>,
    selectedMountIds: string[],
    selectedId: string | undefined,
): Record<PiaId, ISelectedMountAccessory> => {
    const selectedItem = selectedId ? items[selectedId] : undefined;
    const selectedPiaItem =
        selectedItem && selectedItem.productId ? piaItems[selectedItem.productId] : undefined;

    return relations
        .map((relation) => items[relation.childId])
        .filter(isDefined)
        .filter((relation) => relation.properties.accessory)
        .map((item) => {
            const piaItem = item.productId ? piaItems[item.productId] : undefined;
            return piaItem &&
                (isWearableExceptionCategory(selectedPiaItem?.category) ||
                    isNotSelectedInMountingChain(selectedMountIds, item._id))
                ? ({
                      id: item._id,
                      rev: item._rev,
                      productId: item.productId,
                      name: piaItem.name,
                      isDiscontinued: isDiscontinued(piaItem),
                  } as ISelectedMountAccessory)
                : undefined;
        })
        .filter(isDefined)
        .reduce(
            (accessoriesRecord, accessory) => {
                accessoriesRecord[accessory.productId] = accessory;
                return accessoriesRecord;
            },
            {} as Record<PiaId, ISelectedMountAccessory>,
        );
};

export interface ISelectedMountWithAccessories {
    id: Id;
    productId?: PiaId;
    model?: string;
    isDiscontinued: boolean;
    partNumber?: string;
    quantity: number;
    accessories: ISelectedMountAccessory[];
}

export const getMountWithAccessories = createCachedSelector(
    [
        getCurrentProjectItem,
        getPiaItem,
        getCurrentProjectLocation,
        getSelectedMountAccessoriesForItemId,
        getIdFromProps,
    ],
    (item, piaItem, location, selectedMountAccessories) =>
        mapToSelectedMountWithAccessories(item, piaItem, location, selectedMountAccessories),
)(toCacheKey);

export const getMountWithAccessoriesIncludingAllMounts = createCachedSelector(
    [
        getCurrentProjectItem,
        getPiaItem,
        getCurrentProjectLocation,
        getSelectedMountAccessoriesForItemId,
        getIdFromProps,
    ],
    (item, piaItem, location, selectedMountAccessories) =>
        mapToSelectedMountWithAccessories(item, piaItem, location, selectedMountAccessories),
)(toCacheKey);

const mapToSelectedMountWithAccessories = (
    item: IPersistence<IItemEntity> | undefined,
    piaItem: IPiaItem | null,
    location: IPiaLocation | undefined,
    selectedMountAccessories: Record<number, ISelectedMountAccessory>,
) => {
    const partNo = piaItem ? getItemVersion(piaItem, location, true)?.partno : undefined;

    if (!item) {
        throw Error('Item not found');
    }

    return {
        id: item._id,
        productId: piaItem?.id,
        model: piaItem?.name,
        isDiscontinued: piaItem ? isDiscontinued(piaItem) : false,
        partNumber: partNo,
        quantity: item.quantity,
        accessories: Object.values(selectedMountAccessories).sort(nameComparator) ?? [],
    } as ISelectedMountWithAccessories;
};

/**
 * Combines microphones, bridges, servers and amplifiers as audio and I/O category.
 */
const combineCategories = (category: string) => {
    if (
        category === PiaAccessoryCategory.MICROPHONES ||
        category === PiaAccessoryCategory.BRIDGES ||
        category === PiaAccessoryCategory.SERVERS ||
        category === PiaAccessoryCategory.AMPLIFIERS
    ) {
        return PiaAccessoryCategory.AUDIOANDIO;
    }
    return category;
};

const isNotSelectedInMountingChain = (selectedMountIds: Id[], itemId: Id) => {
    return !selectedMountIds.some((mountId) => mountId === itemId);
};

const isNotInMountingChain = (piaItem: IPiaItem) => {
    // We do not allow mount accessories that are selectable in the mounting chain to be added as accessories
    // Mounting accessories with sortingPriority 1 represents accessories that are not selectable in the mounting-chain
    return !(
        piaItem.category === PiaAccessoryCategory.MOUNTS &&
        (piaItem.properties as IPiaAccessoryProperties)?.sortingPriority !== 1
    );
};

export const sortMountAccessoryCategories = (catA: string, catB: string) => {
    // Sort other last
    if (catA === 'other') return 1;
    if (catB === 'other') return -1;
    return t(`accessoryDisplayCategoriesGROUP.${catA}`)
        .toLowerCase()
        .localeCompare(t(`accessoryDisplayCategoriesGROUP.${catB}`).toLowerCase());
};

export const getFilteredAllAccessoriesMatchingSearch = createSelector(
    [getAllMountAccessoriesForProduct, getAccessorySearchFilter],
    (accessories, accessoryFilter): Record<string, ICategorizedAccessoryItem[]> => {
        if (!accessoryFilter) {
            return accessories;
        }

        return getAccessoriesMatchingSearch(accessoryFilter.searchText, accessories);
    },
);

export const getFilteredAccessoriesMatchingSearch = createSelector(
    [getMountAccessoriesForProduct, getAccessorySearchFilter],
    (accessories, accessoryFilter): Record<string, ICategorizedAccessoryItem[]> => {
        if (!accessoryFilter) {
            return accessories;
        }

        return getAccessoriesMatchingSearch(accessoryFilter.searchText, accessories);
    },
);
