import type { IScenario } from '../../models';
import type { BandwidthOptions } from './BandwidthOptions';
import { BitrateCalculator } from './Bitrate.calculator';
import type { Condition } from './Condition';
import { constants } from './constants';

/**
 * Used for fixed mode fps calculation
 */
export class FixedFpsCalculator extends BitrateCalculator {
    constructor(bandwidthOptions: BandwidthOptions) {
        super(bandwidthOptions);
    }

    public getBitrate(condition: Condition) {
        const motionLevel = this.getMotionLevel(
            condition.motion,
            this.bandwidthOptions.fpsFactor,
            condition.weather,
        );
        return this.calcBitrate(condition, motionLevel, this.bandwidthOptions.frameRate);
    }

    private getMotionLevel(
        motionLevel: keyof IScenario['motionRef'],
        fpsFactor: number,
        isWeather: boolean,
    ) {
        const { scenario } = this.bandwidthOptions;

        const motionLevelRef = scenario.motionRef[motionLevel];
        return (
            motionLevelRef * fpsFactor +
            (isWeather && !scenario.indoor ? constants.weatherMotionLevel : 0)
        );
    }
}
