import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import type { Id, IPersistence, IProjectEntity, IItemEntity } from 'app/core/persistence';
import { Stack, Box, Text, EditableText, Heading, Card } from 'app/components';
import { AddDeviceMenuItem } from '../devices/AddDeviceMenuItem.component';
import {
    getCurrentProjectItem,
    getModelName,
    getCurrentProjectLocked,
    getCurrentProject,
    getDeviceChildren,
} from 'app/modules/common';
import { DeviceMenuItem } from '../devices/DeviceMenuItem.container';
import type { IMapsDevice } from '../../../../models';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import { AppConstants } from 'app/AppConstants';

interface IMainUnitContextItemProps {
    project: IPersistence<IProjectEntity> | null;
    itemId: Id;
    device?: IMapsDevice;
    hasAvailableChannels: boolean;
    usedChannels: number;
    maxChannels: number;
    name?: string;
    model: string;
    quantity?: number;
    piaId?: number | null;
    locked: boolean;
    children: IPersistence<IItemEntity>[];
    ipName?: string;
    onNameChange(name: string): void;
}

export interface IMainUnitContextItemOwnProps {
    itemId: Id;
    maxChannels: number;
    ipName?: string;
    onNameChange(name: string): void;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IMainUnitContextItemOwnProps,
): IMainUnitContextItemProps => {
    const item = getCurrentProjectItem(storeState, ownProps.itemId);
    const children = getDeviceChildren(storeState, ownProps.itemId);
    const usedChannels = children.reduce((sum, sensorUnit) => (sum += sensorUnit.quantity), 0);
    const hasAvailableChannels = usedChannels < ownProps.maxChannels;

    return {
        project: getCurrentProject(storeState),
        itemId: ownProps.itemId,
        device: getSelectedDeviceOrParentDevice(storeState),
        hasAvailableChannels,
        usedChannels,
        maxChannels: ownProps.maxChannels,
        name: item?.name,
        model: getModelName(storeState, ownProps.itemId),
        quantity: item?.quantity,
        piaId: item?.productId,
        locked: getCurrentProjectLocked(storeState),
        children,
        ipName: ownProps.ipName,
        onNameChange: ownProps.onNameChange,
    };
};

class MainUnitContextItemContainer extends React.Component<IMainUnitContextItemProps> {
    public render() {
        const {
            itemId,
            hasAvailableChannels,
            usedChannels,
            maxChannels,
            children,
            locked,
            project,
            piaId,
            ipName,
            onNameChange,
        } = this.props;

        return (
            <Stack vertical spacing="panel">
                <Card paddingY="base" paddingX="base">
                    <Heading width="100%">
                        <EditableText
                            value={ipName ?? ''}
                            maxLength={AppConstants.deviceNameMaxLength}
                            onChange={onNameChange}
                            placeholder={t.name}
                        />
                    </Heading>
                </Card>

                {!hasAvailableChannels && children.length === 0 && (
                    <Text color="grey5" align="center">
                        {t.mainUnitNeeded}
                    </Text>
                )}
                {children.length > 0 && (
                    <Box>
                        <Stack vertical spacing="quart">
                            {this.renderSensorUnits()}
                        </Stack>
                    </Box>
                )}
                {piaId !== null && !locked && usedChannels < maxChannels && (
                    <Box flex="none">
                        <AddDeviceMenuItem
                            locked={locked}
                            link={`/project/${project?._id}/device-selector/sensorunits/parent/${itemId}/`}
                            deviceType="sensorUnit"
                            text={t.deviceListAddSensorUnit}
                            parentId={itemId}
                        />
                    </Box>
                )}
                <Text color="grey5" testId="sensor_unit_quantity" semiBold align="center">
                    {t.deviceListUsedChannels(usedChannels, maxChannels)}
                </Text>
            </Stack>
        );
    }

    private renderSensorUnits = () =>
        this.props.children.map((child) => {
            return (
                <DeviceMenuItem
                    key={child._id}
                    id={child._id}
                    color={this.props.device?.color || 'transparent'}
                    disabled={!this.props.hasAvailableChannels}
                    openSettings={() => {}}
                />
            );
        });
}

export const MainUnitContextItem = connect(mapStateToProps)(MainUnitContextItemContainer);
