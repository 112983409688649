import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IItemEntity, IBaseEntity } from '../../entities';
import type { IPiaSensorDevice } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { PanoramaModes } from '../../../models';

const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

const getPanoramaMode = (piaDevice: IPiaSensorDevice | false | undefined | null): PanoramaModes => {
    if (!piaDevice) {
        return false;
    }
    return piaDevice.properties.defaultPanoramaMode ?? false;
};

/**
 * Since user's local pia databases where not updated correctly when switching infrastructure we have have to re-do Migration13To14.
 * This time we also have to check for "false" values (since Migration13To14 would have incorrectly added them)
 */
@injectable()
export class Migration15To16 extends MigrationProviderBase {
    public from: number = 15;
    public to: number = 16;

    constructor(private piaItemService: PiaItemService<IPiaSensorDevice>) {
        super();
    }

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (
                    entity.properties.camera &&
                    (entity.properties.camera.filter.panoramaMode === undefined ||
                        entity.properties.camera.filter.panoramaMode === false)
                ) {
                    const piaProduct = entity.productId
                        ? this.piaItemService.get(entity.productId).first()
                        : null;
                    entity.properties.camera.filter.panoramaMode = getPanoramaMode(piaProduct);
                }
            }
            return entity;
        };
    }
}
