import type { IPersistableRangeType, IProjectNetworkSettings } from 'app/core/persistence';
import { convertFromIpRange } from './convertFromIPRange';

const RANGE_MIN = 1;

export const getInitMin = (
    rangeType: IPersistableRangeType,
    projectNetwork: IProjectNetworkSettings | undefined,
    rangeSize: number,
) => {
    const deviceTypeRange = projectNetwork?.ranges[rangeType];
    if (!deviceTypeRange) return RANGE_MIN;
    const startOnRange = convertFromIpRange(projectNetwork.ipStart, deviceTypeRange.ipStart);
    const endOnRange = convertFromIpRange(projectNetwork.ipStart, deviceTypeRange.ipEnd);
    //* Limit min to project range
    if (startOnRange > rangeSize || startOnRange < RANGE_MIN || startOnRange >= endOnRange)
        return RANGE_MIN;

    return startOnRange;
};

export const getInitMax = (
    rangeType: IPersistableRangeType,
    projectNetwork: IProjectNetworkSettings | undefined,
    rangeSize: number,
    rangeInitMin: number,
) => {
    const deviceTypeRange = projectNetwork?.ranges[rangeType];
    if (!deviceTypeRange) return rangeSize;

    const numOnRange =
        convertFromIpRange(deviceTypeRange.ipStart, deviceTypeRange.ipEnd) +
        rangeInitMin -
        RANGE_MIN;

    //* Limit max to project range
    if (numOnRange > rangeSize || numOnRange <= RANGE_MIN || numOnRange <= rangeInitMin)
        return rangeSize;

    return numOnRange;
};
