import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { getPiaItemsRecord } from '../../piaDevices';
import { getDeviceType, getSubType } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';

export const getDeviceAndSubTypeForItem = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (item, piaItemsRecord) => {
        if (!item) {
            return 'camera';
        }

        const piaItem = item.productId ? piaItemsRecord[item.productId] : undefined;

        if (piaItem) {
            const subType = getSubType(piaItem);

            if (subType) {
                return subType;
            }
        }

        return getDeviceType(item, piaItem);
    },
)(toCacheKey);

export const getDeviceTypeForItem = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (item, piaItemsRecord) => {
        if (!item) {
            return undefined;
        }

        const piaItem = item.productId ? piaItemsRecord[item.productId] : undefined;
        return getDeviceType(item, piaItem);
    },
)(toCacheKey);
