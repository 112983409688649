import type { Colors } from 'app/styles';

export interface IBadgeColor {
    red: Colors;
    yellow: Colors;
    green: Colors;
    grey: Colors;
    lightgrey: Colors;
    white: Colors;
}

export const primaryColor: IBadgeColor = {
    red: 'red',
    yellow: 'yellow',
    green: 'green',
    grey: 'grey3',
    lightgrey: 'grey2',
    white: 'white',
};

export const textColor: IBadgeColor = {
    red: 'white',
    yellow: 'yellowText',
    green: 'white',
    grey: 'grey8',
    lightgrey: 'grey5',
    white: 'grey5',
};

export const outlineColor: IBadgeColor = {
    red: 'red',
    yellow: 'yellow6',
    green: 'green',
    grey: 'grey5',
    lightgrey: 'grey3',
    white: 'grey4',
};

export const outlineBackgroundColor: IBadgeColor = {
    red: 'transparent',
    yellow: 'transparent',
    green: 'transparent',
    grey: 'transparent',
    lightgrey: 'transparent',
    white: 'white',
};
