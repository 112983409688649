import * as React from 'react';
import { Box, PiaImage, Spacer } from 'app/components';
import type { IApplicationItem } from 'app/modules/common';
import { isAxis } from 'app/modules/common';
import type { Id } from 'app/core/persistence';
import { PartnerLogo } from './PartnerLogo';
import { AnalyticModeDropDown } from './AnalyticModeDropDown';

interface IApplicationTopProps {
    application: IApplicationItem;
    parentDeviceId?: Id;
}

/**
 * Top section of Application card, including Image (either PIA image or partnerLogo)
 * and possible settings for application (so far only for APD that has both ai and calibration mode)
 * @param application The application item to render the card for
 * @param parentDeviceId The id of the device the application is added to
 */
export const ApplicationTop: React.FC<IApplicationTopProps> = ({ application, parentDeviceId }) => {
    const isPartnerVendor = !isAxis(application.vendor);

    return (
        <Box direction="row" justifyContent="between" minWidth={'100%'} flex="grow">
            {/* Spacing should have the same width as the analyticModeSetting below to get 
            the piaImage or logo in the center */}
            <Spacer horizontal customSpacing="40px" />
            <Box paddingTop="base">
                {isPartnerVendor && application.vendorId ? (
                    <Box height={80} justifyContent="center" alignItems="center">
                        <PartnerLogo vendorPiaId={application.vendorId} />
                    </Box>
                ) : (
                    <PiaImage
                        piaId={application.productId}
                        icon="extension"
                        iconProps={{
                            color: 'blue',
                            opaque: true,
                        }}
                        imageSize="xxl"
                        imageUrlFallback={undefined}
                    />
                )}
            </Box>
            <AnalyticModeDropDown application={application} parentDeviceId={parentDeviceId} />
        </Box>
    );
};

ApplicationTop.displayName = 'ApplicationTop';
