export interface ISustainabilityFilter {
    pvcFree?: boolean;
    bfrCfrFree?: boolean;
    minRecycledPlastic?: number;
}

export const defaultSustainabilityFilter: ISustainabilityFilter = {
    pvcFree: false,
    bfrCfrFree: false,
    minRecycledPlastic: undefined,
};
