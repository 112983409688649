import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import type { ICustomCameraPropertiesEntity, Id } from 'app/core/persistence';
import { deviceTypeCheckers, isCustomCamera, ItemService } from 'app/core/persistence';
import {
    Stack,
    Text,
    Box,
    Textarea,
    TextInput,
    Checkbox,
    Button,
    UploadFile,
} from 'app/components';
import { t } from 'app/translate';
import {
    PiaDescription,
    getCurrentProjectItem,
    getModelName,
    getCurrentProjectLocked,
} from 'app/modules/common';

import type { PiaId } from 'app/core/pia';
import { getShouldHideChangeDevice } from '../../selectors';
import { CustomCameraSettings, CustomCameraSettingsService } from '../customCameraSettings';
import { isFeatureEnabled } from 'app/featureFlags';

interface IDeviceOverviewOwnProps {
    deviceId: Id;
}

interface IDeviceOverviewProps {
    deviceId: Id;
    name?: string;
    model?: string;
    productId: PiaId | null;
    description?: string;
    notes?: string;
    shouldHideChangeDevice: boolean;
    shouldHideColorPicker: boolean;
    isCustomCamera: boolean;
    isLocked: boolean;
    showCustomCameraCheckbox: boolean;
    customCameraProperties?: ICustomCameraPropertiesEntity;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceOverviewOwnProps,
): IDeviceOverviewProps => {
    const device = getCurrentProjectItem(state, ownProps.deviceId);

    return {
        deviceId: ownProps.deviceId,
        productId: device?.productId ?? null,
        name: device?.name,
        model: getModelName(state, ownProps.deviceId),
        description: device?.description,
        notes: device?.notes,
        shouldHideChangeDevice: getShouldHideChangeDevice(state, ownProps.deviceId),
        shouldHideColorPicker: !!(device && deviceTypeCheckers.isSensorUnit(device)),
        isCustomCamera: !!(device && isCustomCamera(device)),
        isLocked: getCurrentProjectLocked(state),
        showCustomCameraCheckbox: !!(
            device &&
            !device?.productId &&
            deviceTypeCheckers.isCamera(device)
        ),
        customCameraProperties: device?.properties.camera?.customCameraProperties,
    };
};

class DeviceOverviewContainer extends React.Component<IDeviceOverviewProps> {
    private itemService: ItemService = ServiceLocator.get(ItemService);
    private customCameraSettingsService: CustomCameraSettingsService = ServiceLocator.get(
        CustomCameraSettingsService,
    );

    public render() {
        const {
            deviceId,
            model,
            productId,
            description,
            notes,
            shouldHideChangeDevice,
            isLocked,
            showCustomCameraCheckbox,
        } = this.props;

        return (
            <Stack vertical spacing="panel">
                <Box alignItems="center">
                    <Box direction="column" paddingX="base">
                        {!shouldHideChangeDevice && (
                            <Box paddingTop="half" justifyContent="start">
                                <Stack spacing="halfCell" alignItems="stretch"></Stack>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Stack vertical spacing="quart">
                    <Text>{t.deviceOverviewDescription}</Text>
                    <TextInput value={description} onChange={this.onDescriptionChange} />
                </Stack>
                <Stack vertical spacing="quart">
                    <Text>{t.deviceOverviewNotes}</Text>
                    <Box height="150px">
                        <Textarea value={notes} onChange={this.onNotesChange} />
                    </Box>
                </Stack>
                {productId && (
                    <Box width="100%" maxWidth="700px">
                        <PiaDescription showErrorMessage productId={productId} title={model} />
                    </Box>
                )}
                {showCustomCameraCheckbox && (
                    <Stack vertical alignItems="start">
                        <Checkbox
                            onChange={this.onCustomCameraChange}
                            disabled={isLocked}
                            selected={this.props.isCustomCamera}
                            slider
                        >
                            {t.genericCamera}
                        </Checkbox>
                        <Text faded italic>
                            {t.genericCameraInReports}
                        </Text>

                        {this.props.isCustomCamera && <CustomCameraSettings deviceId={deviceId} />}
                        {!shouldHideChangeDevice &&
                            isFeatureEnabled('custom_cameras_export_import') &&
                            (this.props.isCustomCamera ? (
                                <Button onClick={this.exportCustomCamera}>
                                    {t.exportGenericCamera}
                                </Button>
                            ) : (
                                <UploadFile
                                    accept={'application/json'}
                                    onFileUploaded={this.importCustomCamera}
                                >
                                    <Button>{t.importGenericCamera}</Button>
                                </UploadFile>
                            ))}
                    </Stack>
                )}
            </Stack>
        );
    }

    private exportCustomCamera = () => {
        if (!this.props.customCameraProperties || !this.props.name) {
            return undefined;
        }

        this.customCameraSettingsService.exportCustomCamera(
            this.props.customCameraProperties,
            this.props.name,
        );
    };

    private importCustomCamera = async (file: File) => {
        return this.customCameraSettingsService.importCustomCamera(file, this.props.deviceId);
    };

    private onCustomCameraChange = (isCustom: boolean) => {
        isCustom
            ? this.customCameraSettingsService.activateCustomCamera(this.props.deviceId)
            : this.customCameraSettingsService.deactivateCustomCamera(this.props.deviceId);
    };

    private onDescriptionChange = (description: string) => {
        this.itemService.updateItem(this.props.deviceId, { description });
    };

    private onNotesChange = (notes: string) => {
        this.itemService.updateItem(this.props.deviceId, { notes });
    };
}

export const DeviceOverview = connect(mapStateToProps)(DeviceOverviewContainer);
