import * as React from 'react';
import type { IClickableProps } from '../../functional';
import { Clickable } from '../../functional';
import { extendableProps } from '../../services';
import { css, cx } from '@emotion/css';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';

const baseStyle = css`
    box-sizing: border-box;
    min-height: 35px;
    min-width: 80px;
    padding: ${SpacingsEnum.half} ${SpacingsEnum.base};
    border: 1px solid transparent;
    border-radius: 2px;
    outline: none;
    line-height: 17px;
    font-weight: 600;
    background-color: transparent;
    /* Make sure buttons don't shrink in IE11 */
    flex-shrink: 0;

    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 4px 0 ${ColorsEnum.blue};
    }
    &::-moz-focus-inner {
        border: 0;
    }

    @media print {
        display: none;
    }
`;

const outlineStyle = css`
    border-color: ${ColorsEnum.blue};
    color: ${ColorsEnum.blue};

    &:hover {
        color: ${ColorsEnum.blue5};
        border-color: ${ColorsEnum.blue5};
    }

    &:active {
        color: ${ColorsEnum.blue6};
        border-color: ${ColorsEnum.blue6};
        background-color: ${ColorsEnum.grey2};
    }
`;

const outlineWarningStyle = css`
    border-color: ${ColorsEnum.red};
    color: ${ColorsEnum.red};

    &:hover {
        color: ${ColorsEnum.red};
        border-color: ${ColorsEnum.red};
    }

    &:active {
        color: ${ColorsEnum.red};
        border-color: ${ColorsEnum.red};
        background-color: ${ColorsEnum.grey2};
    }
`;

const primaryStyle = css`
    color: ${ColorsEnum.white};
    background-color: ${ColorsEnum.blue};

    &:hover {
        color: ${ColorsEnum.white};
        background-color: ${ColorsEnum.blue4Light};
    }
    &:active {
        color: ${ColorsEnum.white};
        background: ${ColorsEnum.blue5};
    }
    &:focus {
        background-color: ${ColorsEnum.blue};
    }
`;

const primaryWarningStyle = css`
    color: ${ColorsEnum.white};
    background-color: ${ColorsEnum.red};

    &:hover {
        color: ${ColorsEnum.white};
        background-color: ${ColorsEnum.red};
    }
    &:active {
        color: ${ColorsEnum.white};
        background: ${ColorsEnum.red};
    }
    &:focus {
        background-color: ${ColorsEnum.red};
    }
`;

const textStyle = css`
    color: ${ColorsEnum.blue};

    &:hover {
        color: ${ColorsEnum.blue5};
    }
    &:active {
        color: ${ColorsEnum.blue6};
        background-color: ${ColorsEnum.grey2};
    }
    &:focus {
        color: ${ColorsEnum.blue};
        text-decoration: underline;
    }
`;

const textWarningStyle = css`
    color: ${ColorsEnum.red};

    &:hover {
        color: ${ColorsEnum.red};
    }
    &:active {
        color: ${ColorsEnum.red};
        background-color: ${ColorsEnum.grey2};
    }
    &:focus {
        color: ${ColorsEnum.red};
        text-decoration: underline;
    }
`;

const noPaddingStyle = css`
    padding: ${SpacingsEnum.half} 0;
    min-width: 0;
`;

export interface IButtonProps extends IClickableProps, IAutoTestable {
    /**
     * Indicate that this button is a primary action.
     * Will display with opaque background colo.
     * Defaults to a secondary action, which is
     * outlined without fill.
     */
    primary?: boolean;
    /**
     * Indicate that this should look like text rather
     * than an actual button.
     */
    text?: boolean;
    /**
     * Show the button in a warning color.
     */
    warning?: boolean;
    /**
     * Removes X-padding and minimum width from the button.
     */
    noXPadding?: boolean;
}

export class Button extends React.Component<IButtonProps> {
    public render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { primary, text, warning, noXPadding, children, ...extendedProps } = this.props;
        return (
            <Clickable
                onClick={this.onClick}
                {...extendedProps}
                {...extendableProps(extendedProps, {
                    className: cx(
                        baseStyle,
                        { [outlineStyle]: !primary && !text },
                        { [primaryStyle]: !!primary },
                        { [textStyle]: !!text },
                        { [primaryWarningStyle]: !!primary && !!warning },
                        { [outlineWarningStyle]: !primary && !text && !!warning },
                        { [textWarningStyle]: !!text && !!warning },
                        { [noPaddingStyle]: !!noXPadding },
                    ),
                })}
            >
                <button data-test-id={toTestIdFormat(this.props.testId)}>{children}</button>
            </Clickable>
        );
    }

    private onClick = (event: React.MouseEvent) => {
        if (this.props.onClick) {
            event.preventDefault();
            event.stopPropagation();
            this.props.onClick(event);
        }
    };
}
