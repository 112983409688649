import type { IPiaAccessory, IPiaItem, IPiaItemVersion, IPiaRelationReference } from 'app/core/pia';
import { PiaRelationTypes } from 'app/core/pia';
import type { IDesiredCamera } from 'app/modules/common';
import { filterOutMultiPacks, calculate } from 'app/modules/common';
import { trigonometry } from 'axis-webtools-util';

/** If we later change our minds regarding leaving out multi-packs, setting this constant to false will solve it. */
const shouldFilterOutMultiPacks = false;

/**
 * Searches for lenses that match scene requirements when original lens does not.
 * @param relations Relations for the Pia Camera you want to search lenses for
 * @param piaLenses Record of available Pia lenses
 * @param desiredHorizontalFoVRadians Desired Field of View in radians. Typically governed by camera filter.
 * @param maxVideoResolutionHorizontal: Max resolution of camera
 * @param shortestFocalLength: Decides if priority should be given to lens with shortest min focal length or longest max focal length
 * @returns If at least one applicable lens matches scene requirements, returns the best match. Otherwise undefined.
 */
export const getBestApplicableLens = (
    relations: IPiaRelationReference[],
    piaLenses: Record<number, IPiaItem>,
    desiredCamera: IDesiredCamera,
    maxVideoResolutionHorizontal: number,
    shortestFocalLength?: boolean,
) =>
    relations.reduce(
        (currentlyBestLens, currentRel) => {
            //* Filter out all relations that are not compatible lenses to product
            if (!relationIsCompatibleLens(currentRel, piaLenses)) {
                return currentlyBestLens;
            }

            const currentPiaLens = piaLenses[currentRel.id] as IPiaAccessory;
            const onlySoldInMultiPack = isOnlySoldInMultiPack(currentPiaLens.versions);

            // Filter out multi packs to avoid accidentally buying more lenses than necessary
            if (onlySoldInMultiPack && shouldFilterOutMultiPacks) {
                return currentlyBestLens;
            }

            const fovAndPixelDensityFulfilled = getFovAndPixelDensityFulfilled(
                desiredCamera,
                currentRel,
                maxVideoResolutionHorizontal,
            );

            /** If lens fulfills FoV and pixel density requirements compare it to current best lens.
         Cameras that fail pixel density requirements should get shortest possible focal length
         Cameras that fail max FoV requirements should get longest possible focal length **/
            if (fovAndPixelDensityFulfilled) {
                return shortestFocalLength
                    ? getLensWithShorterFocalLength(currentPiaLens, currentlyBestLens)
                    : getLensWithLongerFocalLength(currentPiaLens, currentlyBestLens);
            }
            return currentlyBestLens;
        },
        undefined as IPiaAccessory | undefined,
    );

/**
 * Checks if a device can fulfill filter requirements with the addition of an accessory lens.
 * If you instead wish to retrieve such a lens, use {@link getBestApplicableLens}.
 * @param relations
 * @param piaLenses
 * @param desiredCamera
 * @param maxVideoResolutionHorizontal
 * @returns true if device can fulfill requirements with an accessory lens, otherwise false.
 */
export const hasApplicableLens = (
    relations: IPiaRelationReference[],
    piaLenses: Record<number, IPiaItem>,
    desiredCamera: IDesiredCamera,
    maxVideoResolutionHorizontal: number,
) =>
    relations.some((relation) => {
        //* Filter out all relations that are not compatible lenses to product
        if (!relationIsCompatibleLens(relation, piaLenses)) {
            return undefined;
        }

        const currentPiaLens = piaLenses[relation.id] as IPiaAccessory;
        const onlySoldInMultiPack = isOnlySoldInMultiPack(currentPiaLens.versions);

        // Filter out multi packs to avoid accidentally buying more lenses than necessary
        if (onlySoldInMultiPack && shouldFilterOutMultiPacks) {
            return undefined;
        }

        return getFovAndPixelDensityFulfilled(
            desiredCamera,
            relation,
            maxVideoResolutionHorizontal,
        );
    });

const isLensPixelDensityFulfilled = (
    horizontalFoVRadians: { min: number; max: number },
    desiredHorizontalFoVRadians: number,
    desiredPixelDensity: number,
    trueDistance: number,
    maxVideoResolutionHorizontal: number,
): boolean => {
    const maxHorizontalFoVRadians = horizontalFoVRadians.max;
    const minHorizontalFoVRadians = horizontalFoVRadians.min;

    const selectedHorizontalFOVRadians = calculate.getSelectedFovRadians(
        desiredHorizontalFoVRadians,
        minHorizontalFoVRadians,
        maxHorizontalFoVRadians,
    );

    const pixelDensity =
        maxVideoResolutionHorizontal / (selectedHorizontalFOVRadians * trueDistance);

    return pixelDensity >= desiredPixelDensity;
};

const getLensWithShorterFocalLength = (
    currentPiaLens: IPiaAccessory,
    currentlyBestLens: IPiaAccessory | undefined,
) => {
    const currentMinFocalLength = currentPiaLens.properties.focalLength?.min;
    const previouslyBestMinFocalLength = currentlyBestLens?.properties.focalLength?.min;

    return !previouslyBestMinFocalLength ||
        (currentMinFocalLength && previouslyBestMinFocalLength > currentMinFocalLength)
        ? currentPiaLens
        : currentlyBestLens;
};

const getLensWithLongerFocalLength = (
    currentPiaLens: IPiaAccessory,
    currentlyBestLens: IPiaAccessory | undefined,
) => {
    const currentMaxFocalLength = currentPiaLens.properties.focalLength?.max;
    const previouslyBestMaxFocalLength = currentlyBestLens?.properties.focalLength?.max;

    return !previouslyBestMaxFocalLength ||
        (currentMaxFocalLength && previouslyBestMaxFocalLength < currentMaxFocalLength)
        ? currentPiaLens
        : currentlyBestLens;
};

export const getFovAndPixelDensityFulfilled = (
    desiredCamera: IDesiredCamera,
    relation: IPiaRelationReference,
    maxVideoResolutionHorizontal: number,
) => {
    const trueDistance = calculate.trueDistance(
        desiredCamera.installationHeight,
        desiredCamera.distanceToTarget,
        desiredCamera.targetHeight,
    );

    const desiredHorizontalFoVRadians = desiredCamera.horizontalFOVRadians;

    const maxHorizontalFoVRadians = trigonometry.toRadians(
        relation.relationProperties?.horizontalFOV?.max ?? 0,
    );
    const minHorizontalFoVRadians = trigonometry.toRadians(
        relation.relationProperties?.horizontalFOV?.min ?? 0,
    );
    const pixelDensityFulfilled = isLensPixelDensityFulfilled(
        { max: maxHorizontalFoVRadians, min: minHorizontalFoVRadians },
        desiredHorizontalFoVRadians,
        desiredCamera.pixelDensity,
        trueDistance,
        maxVideoResolutionHorizontal,
    );

    return maxHorizontalFoVRadians >= desiredHorizontalFoVRadians && pixelDensityFulfilled;
};

export const relationIsCompatibleLens = (
    relation: IPiaRelationReference,
    piaLenses: Record<number, IPiaItem>,
) => relation.relationType === PiaRelationTypes.Compatible && !!piaLenses[relation.id];

export const isOnlySoldInMultiPack = (versions: IPiaItemVersion[]) =>
    versions.filter(filterOutMultiPacks).length === 0;
