import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import { Button, Text, ContentPanel, Stack, Spinner } from 'app/components';
import { copyToClipboard, getCurrentUser } from 'app/modules/common';
import { getIsUserOnline } from '../application';
import { AppConstants } from 'app/AppConstants';
import type { IUserInfo } from 'app/core/persistence';
import { FloorPlanService, ImageService, getItemLocalStorage } from 'app/core/persistence';

export interface IDiagnosticsData {
    [key: string]: IDiagnosicsDataPoint;
}

interface IDiagnosicsDataPoint {
    friendlyName: string;
    value: string | string[] | number | undefined;
    explanation?: string;
}

export const DiagnosticsContainer: React.FunctionComponent = () => {
    const user = useSelector<IStoreState, IUserInfo | null>(getCurrentUser);
    const userOnline = useSelector<IStoreState, boolean>(getIsUserOnline);
    const floorPlanService = useService(FloorPlanService);
    const imageService = useService(ImageService);
    const [floorPlanIds, setFloorPlanIds] = React.useState<string[] | null>(null);
    const [orphanImages, setOrphanImages] = React.useState<string[] | null>(null);
    const [diagnosticsData, setDiagnosticsData] = React.useState<IDiagnosticsData>({});

    React.useEffect(() => {
        const getFloorPlanIds = async () => {
            const ids = await floorPlanService.getAllFloorPlanIdsFromAllProjects();
            setFloorPlanIds(ids);
        };
        getFloorPlanIds();
    }, [floorPlanService]);

    React.useEffect(() => {
        const getOrphanImages = async () => {
            if (floorPlanIds !== null) {
                if (floorPlanIds.length > 0) {
                    const orphans = await imageService.getOrphanImages(floorPlanIds);
                    setOrphanImages(orphans);
                } else {
                    setOrphanImages([]);
                }
            }
        };
        getOrphanImages();
    }, [imageService, floorPlanIds]);

    React.useEffect(() => {
        const data = {
            version: {
                friendlyName: 'Version',
                value: AppConstants.appVersion,
                explanation: 'App version number',
            },
            userAgent: {
                friendlyName: 'User agent',
                value: navigator.userAgent,
                explanation: 'Information about the browser and operating system',
            },
            cookieEnabled: {
                friendlyName: 'Cookies enabled',
                value: String(navigator.cookieEnabled),
                explanation: 'Whether cookies are enabled in the browser',
            },
            cookie: {
                friendlyName: 'Cookies',
                value: document.cookie,
                explanation: 'All cookies accessible from this page',
            },
            browserLanguage: {
                friendlyName: 'Browser language',
                value: navigator.language,
                explanation: 'Preferred language in the browser',
            },
            time: {
                friendlyName: 'Time',
                value: new Date().toUTCString(),
                explanation: 'When this page was last refreshed',
            },
            timeZone: {
                friendlyName: 'Time zone',
                value: new Date().getTimezoneOffset() / 60 + ' hours',
                explanation: 'Time-zone offset from current host system settings to GMT',
            },
            screenWidth: {
                friendlyName: 'Screen width',
                value: screen.width + ' pixels',
                explanation: 'Width of the screen',
            },
            screenHeight: {
                friendlyName: 'Screen height',
                value: screen.height + ' pixels',
                explanation: 'Height of the screen',
            },
            innerWidth: {
                friendlyName: 'Window width',
                value: innerWidth + ' pixels',
                explanation: 'Width of the browser window viewport',
            },
            innerHeight: {
                friendlyName: 'Window height',
                value: innerHeight + 'pixels',
                explanation: 'Height of the browser window viewport',
            },
            hid: {
                friendlyName: 'Hid',
                value: user ? user.hid : undefined,
                explanation: 'Axis user hid',
            },
            email: {
                friendlyName: 'Email',
                value: user ? user.email : undefined,
                explanation: 'Axis user email',
            },
            userOnline: {
                friendlyName: 'Online',
                value: String(userOnline),
                explanation: 'Whether the user is online',
            },
            asd1DataExists: {
                friendlyName: 'ASD1 data exist',
                value: String(!!getItemLocalStorage('Asd1Data')),
                explanation: 'True if Axis Site Designer 1 data exists on this device',
            },
        };
        setDiagnosticsData(data);
    }, [user, userOnline]);

    const getClipboardData = () => {
        return (
            JSON.stringify(diagnosticsData, null, 4) +
            JSON.stringify('Unreferenced images:', null, 4) +
            JSON.stringify(orphanImages, null, 4)
        );
    };

    return (
        <ContentPanel userSelect>
            <Stack vertical>
                <Text style="headline">{t.diagnosticsTitle}</Text>
                {Object.entries(diagnosticsData).map(([key, dataPoint]) => (
                    <Stack key={key} vertical spacing="quart">
                        <Text>
                            <Text inline bold>
                                {dataPoint.friendlyName + ': '}
                            </Text>
                            {dataPoint.value}
                        </Text>
                        {dataPoint.explanation && <Text italic>{dataPoint.explanation}</Text>}
                    </Stack>
                ))}
                <Stack spacing="quart" vertical>
                    <Text bold>Unreferenced Images</Text>
                    {orphanImages === null && <Spinner size={18} />}
                    {orphanImages && orphanImages.length < 1 ? (
                        <Text testId="unreferenced_images">None</Text>
                    ) : (
                        orphanImages && orphanImages.map((id, index) => <div key={index}>{id}</div>)
                    )}
                </Stack>
                <div>
                    <Button primary onClick={() => copyToClipboard(getClipboardData())}>
                        {t.clipboardCopyToClipboard}
                    </Button>
                </div>
            </Stack>
        </ContentPanel>
    );
};
