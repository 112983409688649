import * as React from 'react';
import { useService } from 'app/ioc';
import ErrorBoundaryLazy from './ErrorBoundaryLazy';
import { t } from 'app/translate';
import { Modal, Box, LoadingIndicator } from 'app/components';
import { getIsUserOnline } from 'app/modules/application';
import { ModalService } from 'app/modal';
import { getIsOtherSelectorOpen } from 'app/modules/common';
import { useSelector } from 'react-redux';
import { AddonSelectorActionService } from '../services';

const LazyPartnerProductsOtherModal = React.lazy(() => import('./PartnerProductsOtherModal'));

export const PartnerOtherProducts: React.FC = () => {
    const addonSelectorActionService = useService(AddonSelectorActionService);
    const showPartnerProductModal = useSelector(getIsOtherSelectorOpen);
    const modalService = useService(ModalService);
    const isOnline = useSelector(getIsUserOnline);
    const closePartnerListModal = () => {
        addonSelectorActionService.setIsOtherProductSelectorOpen(false);
    };

    // Use effect to show info message if offline and it is not possible to show partner products
    React.useEffect(() => {
        const showInfoMessage = () => {
            addonSelectorActionService.setIsOtherProductSelectorOpen(false);
            modalService.createConfirmDialog({
                header: t.errorLoadingPartnerApplication,
                body: t.errorLoadingPartnerApplicationMessage,
                confirmButtonText: t.close,
            })();
        };
        isOnline
            ? addonSelectorActionService.setIsOtherProductSelectorOpen(true)
            : showInfoMessage();
    }, [isOnline, modalService, addonSelectorActionService]);

    return (
        <>
            {showPartnerProductModal && isOnline && (
                <React.Suspense
                    fallback={
                        <Modal>
                            <Box
                                width="197px"
                                height="250px "
                                alignItems="center"
                                justifyContent="center"
                            >
                                <LoadingIndicator message={t.loading} />
                            </Box>
                        </Modal>
                    }
                >
                    <ErrorBoundaryLazy
                        onClose={() => closePartnerListModal()}
                        title={t.errorLoadingPartnerProduct}
                    >
                        <LazyPartnerProductsOtherModal onClose={() => closePartnerListModal()} />
                    </ErrorBoundaryLazy>
                </React.Suspense>
            )}
        </>
    );
};

PartnerOtherProducts.displayName = 'PartnerOtherProducts';
