import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Add useAverageBitrate to continousRecording if not defined for continuousRecording
 */
@injectable()
export class Migration30To31 extends MigrationProviderBase {
    public from: number = 30;
    public to: number = 31;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'profile') {
                if (entity.continuousRecording.useAverageBitrate === undefined) {
                    entity.continuousRecording.useAverageBitrate = false;
                }
            }

            return entity;
        };
    }
}
