import { debounce } from 'lodash-es';
import { useState, useEffect, useRef, useCallback } from 'react';

interface IWindowSize {
    width: number;
    height: number;
    previousWidth: number;
    previousHeight: number;
}

/**
 * Hook used to get the current inner width and height of the window.
 * Will return width = height = 0 if no window exists.
 */
export function useWindowSize(): IWindowSize {
    const isClient = typeof window === 'object';

    const getSize = useCallback(
        (previousWidth: number, previousHeight: number): IWindowSize => {
            return {
                width: isClient ? window.innerWidth : 0,
                height: isClient ? window.innerHeight : 0,
                previousWidth,
                previousHeight,
            };
        },
        [isClient],
    );

    const previousWindowSize = useRef(getSize(0, 0));
    const [windowSize, setWindowSize] = useState(getSize(window.innerWidth, window.innerHeight));

    useEffect(() => {
        if (!isClient) {
            return;
        }

        const onWindowResize = debounce(() => {
            const newSize = getSize(
                previousWindowSize.current.width,
                previousWindowSize.current.height,
            );
            setWindowSize(newSize);
            previousWindowSize.current = newSize;
        }, 500);

        window.addEventListener('resize', onWindowResize);

        return () => window.removeEventListener('resize', onWindowResize);
    }, [getSize, isClient]); // Should only run on mount and unmount

    return windowSize;
}
