// The order of this enum is important. It is the desired order the categories
// should be shown in the Reports.

export enum CategoryEnum {
    Camera = 'camera',
    Encoder = 'encoder',
    AnalogCamera = 'analogCamera',
    FSeries = 'fSeries',
    Speaker = 'speaker',
    Alerters = 'alerters',
    Software = 'software',
    RecordingAndNetwork = 'recordingAndNetwork',
    Decoder = 'decoder',
    Pac = 'pac',
    DoorStation = 'doorStation',
    RadarDetector = 'radarDetector',
    PeopleCounters = 'peopleCounters',
    Wearables = 'wearables',
    Miscellaneous = 'miscellaneous',
    Accessory = 'accessories',
    InstallationService = 'installationService',
    Service = 'service',
    DoorControllers = 'doorControllers',
    Doors = 'doors',
    ConnectivityDevice = 'connectivityDevice',
    PagingConsole = 'pagingConsole',
}
