import * as React from 'react';
import type { IWithChildren } from 'app/components/models';
import { Box } from '../../containers';
import { Border } from '../../style';
import { ToggleItem } from './ToggleItem.component';
import { ToggleLinkItem } from './ToggleLinkItem.component';

export interface IToggle extends IWithChildren {
    width?: string;
    activeItemId?: string;
    toggleItems?: ISimpleToggleItem[];
    onToggledChanged?(toggleId: string): void;
}

export interface ISimpleToggleItem {
    id: string;
    title: string;
    link?: string;
}

export class Toggle extends React.Component<IToggle> {
    public render() {
        const toggleItems = this.props.toggleItems ? this.props.toggleItems : [];
        {
            /* NOTE: Toggle items won't be visible in Edge without first div */
        }
        return (
            <div style={{ margin: '0 auto', width: this.props.width, boxSizing: 'border-box' }}>
                <Border color="blackOpacity">
                    <Box
                        borderRadius="round"
                        height="35px"
                        color="grey1"
                        flex="evenSpace"
                        justifyContent="between"
                        alignItems="stretch"
                        overflow="hidden"
                    >
                        {toggleItems.map(this.renderToggleItems)}
                        {this.props.children}
                    </Box>
                </Border>
            </div>
        );
    }

    private renderToggleItems = (toggleItem: ISimpleToggleItem) => {
        const isActive = toggleItem.id === this.props.activeItemId;
        if (toggleItem.link) {
            return (
                <ToggleLinkItem
                    key={toggleItem.id}
                    id={toggleItem.id}
                    active={isActive}
                    title={toggleItem.title}
                    link={toggleItem.link}
                />
            );
        }
        return (
            <ToggleItem
                key={toggleItem.id}
                id={toggleItem.id}
                active={isActive}
                onClick={() => this.toggleItem(toggleItem.id)}
                title={toggleItem.title}
            />
        );
    };

    private toggleItem = (id: string) => {
        if (this.props.onToggledChanged && this.props.activeItemId !== id) {
            this.props.onToggledChanged(id);
        }
    };
}
