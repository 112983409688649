import type { IExportablePersistedEntity, IExportedItemBase } from '../models';
import type {
    IItemEntity,
    IItemNetworkSettings,
    IPersistence,
    IProjectNetworkSettings,
} from 'app/core/persistence';

const multiplyByQuantity = <A extends IExportedItemBase>(item: A): A[] => {
    return item.quantity === 1
        ? [item]
        : Array(item.quantity)
              .fill(item)
              .map((duplicate: A, index) => ({
                  ...duplicate,
                  name: `${item.name} (${index + 1}/${item.quantity})`,
                  quantity: 1,
                  id: generateUniqueSplitId(item.id, index + 1),
              }));
};

const multiplyEntityByQuantity = (
    item: IPersistence<IItemEntity>,
    projectNetworkSettings?: IProjectNetworkSettings,
): IExportablePersistedEntity[] => {
    return item.quantity === 1
        ? [
              {
                  ...item,
                  networkSettings: item.networkSettings
                      ? [getNetworkSettings(item.networkSettings?.[0], projectNetworkSettings)]
                      : undefined,
                  exportId: item._id,
              },
          ]
        : Array(item.quantity)
              .fill(item)
              .map((duplicate, index) => ({
                  ...duplicate,
                  name: `${item.name} (${index + 1}/${item.quantity})`,
                  quantity: 1,
                  exportId: generateUniqueSplitId(item._id, index + 1),
                  networkSettings: item.networkSettings
                      ? [getNetworkSettings(item.networkSettings?.[index], projectNetworkSettings)]
                      : undefined,
              }));
};

export function generateUniqueSplitId(itemId: string, index: number) {
    return `${itemId}_${index}`;
}

export const splitByQuantity = <A extends IExportedItemBase>(devices: A[]) =>
    devices.reduce((items, item) => [...items, ...multiplyByQuantity(item)], [] as A[]);

export const splitEntitiesByQuantity = <A extends IPersistence<IItemEntity>>(
    devices: A[],
    projectNetworkSettings?: IProjectNetworkSettings,
) =>
    devices.reduce(
        (items, item) => [...items, ...multiplyEntityByQuantity(item, projectNetworkSettings)],
        [] as IExportablePersistedEntity[],
    );

const getNetworkSettings = (
    itemNetworkSettings?: IItemNetworkSettings,
    projectNetworkSettings?: IProjectNetworkSettings,
): IItemNetworkSettings | undefined => {
    if (!itemNetworkSettings) {
        return undefined;
    }

    return {
        addresses: itemNetworkSettings?.addresses,
        override: itemNetworkSettings?.override,
        dhcp: itemNetworkSettings?.override
            ? itemNetworkSettings?.dhcp
            : projectNetworkSettings?.dhcp,
        subnetMask: itemNetworkSettings?.override
            ? itemNetworkSettings?.subnetMask
            : projectNetworkSettings?.subnetMask,
        defaultRouter: itemNetworkSettings?.override
            ? itemNetworkSettings?.defaultRouter
            : projectNetworkSettings?.defaultRouter,
    };
};
