import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { isEmpty } from 'lodash';

/**
 * Change reserved ranges structure when ranges is an empty object
 * or any of the categories inside ranges are missing or empty objects
 */
@injectable()
export class Migration34To35 extends MigrationProviderBase {
    public from: number = 34;
    public to: number = 35;
    private categories = ['cameras', 'other', 'recorders'];

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (
                entity.type === 'project' &&
                entity.networkSettings &&
                (isEmpty(entity.networkSettings?.ranges) ||
                    this.hasEmptyOrMissingCategories(entity))
            ) {
                const ipStartValue = entity.networkSettings.ipStart ?? '';
                const ipEndValue = entity.networkSettings.ipEnd ?? '';

                for (const category of this.categories) {
                    if (
                        !entity.networkSettings?.ranges[category] ||
                        isEmpty(entity.networkSettings?.ranges[category])
                    ) {
                        entity.networkSettings.ranges[category] = {
                            ipStart: ipStartValue,
                            ipEnd: ipEndValue,
                        };
                    }
                }
            }

            return entity;
        };
    }

    private hasEmptyOrMissingCategories(entity: any) {
        return this.categories.some(
            (category) =>
                isEmpty(entity.networkSettings?.ranges[category]) ||
                !entity.networkSettings?.ranges[category],
        );
    }
}
