import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import type { IPriceList } from '../../models';
import { VendorEnum } from '../Vendor.enum';
import { getPriceListForRegion } from '../../prices';
import type { IPrices } from 'app/modules/msrp';
import { getCurrentProjectQuotation } from '../../project/selectors';
import { getProducts } from './getProducts';
import { quotationFilter } from '../quotationFilter';

const getMsrpPriceList = (state: IStoreState) => getPriceListForRegion(state);
const getPricesList = (state: IStoreState) => state.quotation.priceList;
const getPartNumberPrices = (state: IStoreState) =>
    getCurrentProjectQuotation(state)?.pricesByPartNumber ?? {};
const getCustomItemPrices = (state: IStoreState) =>
    getCurrentProjectQuotation(state)?.customItemPrices ?? {};
const getPartnerItemPrices = (state: IStoreState) =>
    getCurrentProjectQuotation(state)?.partnerItemPrices ?? {};

const getPartNumberProducts = createSelector([getProducts], (products) => {
    return products.filter(quotationFilter.byVendors(VendorEnum.Axis, VendorEnum.twoN));
});

const getCustomItemProducts = createSelector([getProducts], (products) => {
    return products.filter((product) => product.vendor === VendorEnum.Other);
});

const getPartnerItemProducts = createSelector([getProducts], (products) => {
    return products.filter((product) => product.vendor === VendorEnum.Genetec);
});

const getPartNumberProductsWithPrices = createSelector(
    [getPartNumberProducts, getPartNumberPrices, getMsrpPriceList, getPricesList],
    (products, prices, msrpPrices, priceList) => {
        return products.map((product) => {
            product.quotePrice = product.id in prices ? prices[product.id] : undefined;
            product.msrp = getMsrpObject(product.id, msrpPrices, priceList);
            return product;
        });
    },
);

const getCustomItemsWithPrices = createSelector(
    [getCustomItemProducts, getCustomItemPrices],
    (products, prices) => {
        return products.map((product) => {
            product.quotePrice = product.id in prices ? prices[product.id] : undefined;
            return product;
        });
    },
);

const getPartnerItemsWithPrices = createSelector(
    [getPartnerItemProducts, getPartnerItemPrices],
    (products, prices) => {
        return products.map((product) => {
            product.quotePrice = product.id in prices ? prices[product.id] : undefined;
            return product;
        });
    },
);

function getMsrpObject(productId: string, msrpPrices?: IPriceList, priceList?: IPrices | null) {
    if (!(msrpPrices && priceList)) {
        return undefined;
    }
    return {
        price: msrpPrices.list[productId],
        priceList: priceList.list[productId],
    };
}

export const getProductsWithPrices = createSelector(
    [getPartNumberProductsWithPrices, getCustomItemsWithPrices, getPartnerItemsWithPrices],
    (partNumberProducts, customItems, partnerItems) => {
        return partNumberProducts.concat(customItems).concat(partnerItems);
    },
);
