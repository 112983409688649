export const getPercentValueAsDecimal = (value: number) => {
    return value * 0.01;
};

export const getIdleTime = (triggerTime: number) => {
    return 100 - triggerTime;
};

export const getKiloBytesPerDay = (weeklyHours: number, bandwidth: number): number => {
    const secondsOfRecordingsPerDay = (weeklyHours / 7) * 3.6e3;
    const kiloBytesPerSecondBandwidth = bandwidth / 8;
    return secondsOfRecordingsPerDay * kiloBytesPerSecondBandwidth;
};
