import * as React from 'react';
import type { Id } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { Grid } from 'app/components';
import { isFeatureEnabled } from 'app/featureFlags';
import { useMount } from 'app/hooks';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { IApplicationItem } from 'app/modules/common';
import {
    getArePartnerProductsLoaded,
    getCompatiblePartnerProductExistsForItemId,
} from '../selectors';
import { ApplicationItem } from './ApplicationItem';
import { AddPartnerApplication } from './AddPartnerApplication';
import { AddonSelectorActionService } from '../services';

interface IApplicationGridComponentProps {
    parentId: Id;
    applications: IApplicationItem[];
    section: 'selected' | 'suggested';
}

export const ApplicationGrid: React.FC<IApplicationGridComponentProps> = ({
    parentId,
    applications,
    section,
}) => {
    const addonSelectorActionService = useService(AddonSelectorActionService);

    // Reset clicked application when changing parent device
    React.useEffect(() => {
        addonSelectorActionService.setClickedApplication(undefined);
    }, [addonSelectorActionService, parentId]);

    const allLoaded = useSelector<IStoreState, boolean>((state) =>
        getArePartnerProductsLoaded(state),
    );

    useMount(() => {
        //* Only fetch partner products in the suggested section to avoid fetching twice
        if (!allLoaded && section === 'suggested') {
            addonSelectorActionService.getAllPartnerProducts();
        }
    });

    const compatiblePartnerProductsExists = useSelector<IStoreState, boolean>((state) => {
        return parentId ? getCompatiblePartnerProductExistsForItemId(state, parentId) : false;
    });

    return (
        <Grid spacing="quart">
            {applications.map((application) => (
                <ApplicationItem
                    application={application}
                    parentDeviceId={parentId}
                    key={application.productId}
                />
            ))}
            {section === 'suggested' &&
                isFeatureEnabled('third_party_acap_selector') &&
                compatiblePartnerProductsExists && <AddPartnerApplication parentId={parentId} />}
        </Grid>
    );
};

ApplicationGrid.displayName = 'ApplicationGrid';
