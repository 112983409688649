import * as React from 'react';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import type { Property } from 'csstype';
import { Closeable } from '../../functional';
import { Positioned } from '../../layout';
import { Box } from '../box/Box.component';
import type { IWithChildren } from 'app/components/models';
import { isPageRTL } from 'app/translate';

interface IOverlayPanelProps extends IWithChildren {
    /**
     * Whether the panel is active
     */
    active: boolean;
    /**
     * Max width of active panel
     */
    maxWidth?: string | number;
    /**
     * Min width of active panel
     */
    minWidth?: string | number;
    /**
     * Triggered when panel is inactivated
     */
    onInactivate(): void;
}

const timeout = {
    appear: 0,
    enter: 0,
    exit: 400,
};

const transitionDuration = 400;

const stateToTranslation: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '100%',
    entering: '100%',
    entered: '0',
    exiting: '100%',
    exited: '100%',
};

//RTL support
const stateToPanelTranslationRTL: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '-100%',
    entering: '-100%',
    entered: '0',
    exiting: '-100%',
    exited: '-100%',
};

/**
 * Display a panel that slides in from the right hand side
 * to cover part of the main view.
 */
export const OverlayPanel: React.FunctionComponent<IOverlayPanelProps> = ({
    active,
    maxWidth,
    minWidth,
    onInactivate,
    children,
}) => {
    const isRTL = isPageRTL();
    // https://reactcommunity.org/react-transition-group/transition
    // nodeRef: This prop is optional, but recommended in order to avoid defaulting to ReactDOM.findDOMNode, which is deprecated in StrictMode
    const transitionElement = React.useRef<HTMLDivElement>(null);
    return (
        <Transition
            in={active}
            timeout={timeout}
            mountOnEnter
            unmountOnExit
            nodeRef={transitionElement}
        >
            {(state) => (
                <Closeable close={onInactivate} innerRef={transitionElement}>
                    <Positioned
                        position="absolute"
                        top="0"
                        insetInlineEnd="0"
                        bottom="0"
                        translateX={
                            isRTL ? stateToPanelTranslationRTL[state] : stateToTranslation[state]
                        }
                        transition
                        duration={transitionDuration}
                    >
                        <Box
                            innerRef={transitionElement}
                            width={maxWidth ?? '90%'}
                            minWidth={minWidth}
                            overflowY="auto"
                            color="grey1"
                            display="block"
                        >
                            {children}
                        </Box>
                    </Positioned>
                </Closeable>
            )}
        </Transition>
    );
};

OverlayPanel.displayName = 'OverlayPanel';
