export class MigrationNotSameAsEntityVersionError extends Error {
    constructor(expectedFromVersion: number, receivedFromVersion: number) {
        super(
            `Migrations dont end up with same version as entity version. The entity version is ${receivedFromVersion}
            but we expected ${expectedFromVersion}. If entity version has been updated this error could be caused by new Migration not added to PersistenceModule.ts file.`,
        );

        Object.setPrototypeOf(this, MigrationNotSameAsEntityVersionError.prototype);
    }
}
