import type { IMigration } from './models/IMigration';
import { deleteDatabase } from './databaseUtil';

export const migrate4to5: IMigration = {
    from: 4,
    to: 5,
    run: migrate,
};

async function migrate(): Promise<void> {
    await Promise.all([deleteDatabase('_pouch_asd-pia-v2')]);
}
