import * as React from 'react';
import type { IStoreState } from 'app/store';
import { getLensesForProductItem, getSensorDetails, getShowResolutionPreview } from '../selectors';
import { useSelector } from 'react-redux';
import { Border, Box, Columns, Icon, Stack, Text } from 'app/components';
import type { ISensorDetails } from '../models/cameras';
import type { IPiaAccessory, PiaId } from 'app/core/pia';
import { ResolutionPreview } from 'app/modules/common';
import { getPixelDensityInMeters } from '../selectors/getPixelDensity';

interface ISensorDetailsProps {
    productId?: PiaId;
}

export const SensorDetails: React.FunctionComponent<ISensorDetailsProps> = ({ productId }) => {
    const sensorDetails = useSelector<IStoreState, ISensorDetails[]>((state) =>
        productId ? getSensorDetails(state, productId) : [],
    );

    const accessoryLens = useSelector<IStoreState, IPiaAccessory | undefined>(
        (state) => getLensesForProductItem(state, productId || null)?.[0],
    );
    const pixelDensityPerMeter = useSelector<IStoreState, number>((state) =>
        getPixelDensityInMeters(state, productId || null, accessoryLens?.id),
    );

    const showResolutionPreview = useSelector<IStoreState, boolean>(getShowResolutionPreview);

    /**
     * Removes border below sensorDetails when no resolution preview is shown.
     */
    const getBottomBorderWidth = (index: number) =>
        index === sensorDetails.length - 1 && !showResolutionPreview ? 0 : 1;

    return !productId ? null : (
        <Stack vertical spacing="halfQuart" justifyContent="center">
            <Border topWidth={1} color="grey3">
                <Stack vertical spacing="none">
                    {sensorDetails.map((detail, index) => {
                        return (
                            <Columns key={index}>
                                <Border bottomWidth={getBottomBorderWidth(index)} color="grey3">
                                    <Box width="100%">
                                        <Border rightWidth={1} color="grey3">
                                            <Box
                                                width="100%"
                                                justifyContent="end"
                                                padding="half"
                                                alignItems="center"
                                            >
                                                {detail.icon ? (
                                                    <Icon
                                                        icon={detail.icon}
                                                        color="grey6"
                                                        size="sm"
                                                    />
                                                ) : (
                                                    <Text color="grey6" align="right">
                                                        {detail.label ?? ''}
                                                    </Text>
                                                )}
                                            </Box>
                                        </Border>
                                        <Box width="100%" padding="half" direction="column">
                                            <Text style="semibold">{detail.value}</Text>
                                            {detail.secondaryValue && (
                                                <Text style="semibold">
                                                    {detail.secondaryValue}
                                                </Text>
                                            )}
                                        </Box>
                                    </Box>
                                </Border>
                            </Columns>
                        );
                    })}
                </Stack>
            </Border>
            {showResolutionPreview && (
                <Box justifyContent="center" paddingY="half">
                    <Box width="60%">
                        <ResolutionPreview
                            pixelDensityPerMeter={pixelDensityPerMeter}
                            productId={productId}
                        />
                    </Box>
                </Box>
            )}
        </Stack>
    );
};
