import * as React from 'react';
import type { Icons } from '../icon';
import { Icon } from '../icon';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import type { Colors } from 'app/styles';
import { Border } from '../../style/Border';
import { Clickable } from '../../functional';
import { Box } from '../../containers';
import { Stack } from '../../layout';
import { Text } from '../../text';
import { css } from '@emotion/css';
import type { IExtendableComponentWithChildren } from 'app/components/models';
import { extendableProps } from 'app/components/services';

const toggleIconOnlyStyle = css`
    width: 100%;
    transition: width 250ms linear;

    [aria-label='title'] {
        width: 170px;
        transition-delay: 250ms;
        transition:
            visibility 250ms linear,
            opacity 250ms linear;
    }

    &[aria-label='menu-button-icon-only'] {
        width: 64px;

        [aria-label='title'] {
            visibility: hidden;
            opacity: 0;
            overflow: hidden;
        }
    }
`;
export interface IMenuButton extends IExtendableComponentWithChildren, IAutoTestable {
    icon?: Icons;
    text?: string;
    selected?: boolean;
    disabled?: boolean;
    iconOnly?: boolean;
    onClick(): void;
}

export const MenuButton: React.FunctionComponent<IMenuButton> = ({
    children,
    icon,
    text,
    selected,
    disabled,
    iconOnly = true,
    onClick,
    testId,
    ...extendedProps
}) => {
    const attributes = extendableProps(
        { ...extendedProps },
        { className: toggleIconOnlyStyle },
        true,
    );

    const textAndIconColor: Colors = selected ? 'grey9' : 'grey7';

    return (
        <div aria-label={iconOnly ? 'menu-button-icon-only' : 'menu-button'} {...attributes}>
            <Border rightWidth={4} color={selected ? 'yellow' : 'transparent'}>
                <Clickable disabled={disabled} onClick={onClick}>
                    <Box
                        width="100%"
                        direction="column"
                        paddingX="panel"
                        paddingY="halfCell"
                        alignItems="start"
                        testId={toTestIdFormat(testId)}
                    >
                        <Stack alignItems="center" spacing="half">
                            {icon && <Icon icon={icon} color={textAndIconColor} size="md" />}

                            {text && (
                                <span aria-label="title">
                                    <Text
                                        style="semibold"
                                        color={textAndIconColor}
                                        whiteSpace="nowrap"
                                    >
                                        {text}
                                    </Text>
                                </span>
                            )}
                        </Stack>

                        <Box paddingLeft="panel" alignItems="center" justifyContent="center">
                            {children}
                        </Box>
                    </Box>
                </Clickable>
            </Border>
        </div>
    );
};
