import { css } from '@emotion/css';

export type AlignItems = 'start' | 'end' | 'center' | 'baseline' | 'stretch';

export type Direction = 'row' | 'column' | 'rowReverse' | 'columnReverse';

export type Display = 'none' | 'flex' | 'block' | 'inlineBlock' | 'tableCell' | 'grid';

export type Flex =
    | 'grow'
    | 'shrink'
    | 'shrinkAndGrow'
    | 'evenSpace'
    | 'none'
    | 'dontShrink'
    | 'fullWidth';

export type JustifyContent = 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';

export type Overflow = 'hidden' | 'scroll' | 'unset' | 'auto';

export type Position = 'static' | 'relative';

export const Flex = css`
    display: flex;
`;

export const FlexAlignHorizontalCenter = css`
    display: flex;
    align-items: center;
`;

export const Col1 = css`
    width: 8.3%;
`;

export const Col2 = css`
    width: 16.6%;
`;

export const Col3 = css`
    width: 25%;
`;

export const Col4 = css`
    width: 33.3%;
`;

export const Col5 = css`
    width: 41.6%;
`;

export const Col6 = css`
    width: 50%;
`;

export const Col7 = css`
    width: 58.3%;
`;

export const Col8 = css`
    width: 64.6%;
`;

export const Col9 = css`
    width: 75%;
`;

export const Col10 = css`
    width: 83.3%;
`;

export const Col11 = css`
    width: 91.6%;
`;

export const Col12 = css`
    width: 100%;
`;

export const DisplayNone = css`
    display: none !important;
`;

export const DisplayFlex = css`
    display: flex !important;
`;

export const DisplayBlock = css`
    display: block !important;
`;

export const DisplayInlineBlock = css`
    display: inline-block !important;
`;

export const DisplayTableCell = css`
    display: table-cell !important;
`;

export const DisplayGrid = css`
    display: grid !important;
`;

export const VisibilityHidden = css`
    visibility: hidden !important;
    pointer-events: none !important;
`;

export const OverflowHidden = css`
    overflow: hidden;
`;

export const OverflowScroll = css`
    overflow: scroll;
`;

export const OverflowAuto = css`
    overflow: auto;
`;

export const OverflowUnset = css`
    overflow: unset !important;
`;

export const OverflowXHidden = css`
    overflow-x: hidden;
`;

export const OverflowXScroll = css`
    overflow-x: scroll;
`;

export const OverflowXAuto = css`
    overflow-x: auto;
`;

export const OverflowXUnset = css`
    overflow-x: unset;
`;

export const OverflowYHidden = css`
    overflow-y: hidden;
`;

export const OverflowYScroll = css`
    overflow-y: scroll;
`;

export const OverflowYAuto = css`
    overflow-y: auto;
`;
export const OverflowYUnset = css`
    overflow-y: unset;
`;

export const ItemsStart = css`
    align-items: flex-start;
`;

export const ItemsEnd = css`
    align-items: flex-end;
`;

export const ItemsCenter = css`
    align-items: center;
`;

export const ItemsBaseline = css`
    align-items: baseline;
`;

export const ItemsStretch = css`
    align-items: stretch;
`;

export const JustifyStart = css`
    justify-content: flex-start;
`;

export const JustifyEnd = css`
    justify-content: flex-end;
`;

export const JustifyCenter = css`
    justify-content: center;
`;

export const JustifyBetween = css`
    justify-content: space-between;
`;

export const JustifyAround = css`
    justify-content: space-around;
`;

export const JustifyEvenly = css`
    justify-content: space-evenly;
`;

export const FlexDirectionRow = css`
    flex-direction: row;
`;

export const FlexDirectionColumn = css`
    flex-direction: column;
`;

export const FlexDirectionColumnReverse = css`
    flex-direction: column-reverse;
`;

export const FlexDirectionRowReverse = css`
    flex-direction: row-reverse;
`;

export const FlexShrinkAndGrow = css`
    flex: 1 1 auto;
`;

export const FlexGrow = css`
    flex: 1 0 auto;
`;

export const FlexFullWidth = css`
    flex: 1 1 100%;
`;

export const FlexShrink = css`
    flex: 0 1 auto;
`;

export const FlexEvenSpace = css`
    flex: 1 1 0;
`;

export const FlexNone = css`
    flex: 0 0 auto;
`;

export const FlexWrap = css`
    flex-wrap: wrap;
`;

export const DontShrink = css`
    flex-shrink: 0;
`;

export const PositionStatic = css`
    position: static;
`;

export const PositionRelative = css`
    position: relative;
`;

export const NoPointerEvents = css`
    pointer-events: none !important;
`;
