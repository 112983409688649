export const PARTNER_PRODUCT_TYPE_ALL: PartnerProductType = 'All';

export type PartnerProductType =
    | 'All'
    | 'ACAP application'
    | 'Access control equipment'
    | 'Access control system'
    | 'Accessories'
    | 'Audio'
    | 'Audio analytics'
    | 'Audio solution'
    | 'Body worn solution'
    | 'Billing management platform'
    | 'Common management platform'
    | 'Computing'
    | 'Content management'
    | 'Display'
    | 'Hardware integration'
    | 'Home automation'
    | 'Integrated platform'
    | 'IT integration service'
    | 'Live streaming solution'
    | 'Management and maintenance'
    | 'Networking'
    | 'Sensors'
    | 'Storage'
    | 'Video analytics'
    | 'Video management system';

export const partnerProductTypes: PartnerProductType[] = [
    'All',
    'ACAP application',
    'Access control equipment',
    'Access control system',
    'Accessories',
    'Audio',
    'Audio analytics',
    'Audio solution',
    'Body worn solution',
    'Billing management platform',
    'Common management platform',
    'Computing',
    'Content management',
    'Display',
    'Hardware integration',
    'Home automation',
    'Integrated platform',
    'IT integration service',
    'Live streaming solution',
    'Management and maintenance',
    'Networking',
    'Sensors',
    'Storage',
    'Video analytics',
    'Video management system',
];
