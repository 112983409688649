const feetsPerMeter = 3.2808;

export const convert = {
    toKilo: (value: number): number => {
        return value / 1e3;
    },
    toMega: (value: number): number => {
        return value / 1e6;
    },
    toGiga: (value: number): number => {
        return value / 1e9;
    },
    toTera: (value: number): number => {
        return value / 1e12;
    },
    fromKilo: (value: number): number => {
        return value * 1e3;
    },
    fromMega: (value: number): number => {
        return value * 1e6;
    },
    fromGiga: (value: number): number => {
        return value * 1e9;
    },
    fromTera: (value: number): number => {
        return value * 1e12;
    },
    metersToFeet: (meters: number): number => {
        return meters * feetsPerMeter;
    },
    squareMetersToSquareFeet: (squareMeters: number): number => {
        return squareMeters * feetsPerMeter ** 2;
    },
    metersToInches: (meters: number): number => {
        return meters * 39.37;
    },
    metersToYards: (meters: number): number => {
        return meters * 1.0936;
    },
    metersToMiles: (meters: number): number => {
        return meters * 0.00062137;
    },
    feetToMeters: (feet: number): number => {
        return feet / feetsPerMeter;
    },
    squareFeetToSquareMeters: (squareFeet: number): number => {
        return squareFeet / feetsPerMeter ** 2;
    },
    yardsToMeters: (yards: number): number => {
        return yards / 1.0936;
    },
    milesToMeters: (miles: number): number => {
        return miles / 0.00062137;
    },
    inchesToMeters: (inches: number): number => {
        return inches / 39.37;
    },
    celsiusToFahrenheit: (degreesInCelsius: number): number => {
        return degreesInCelsius * (9 / 5) + 32;
    },
    fahrenheitToCelsius: (degreesInFahrenheit: number): number => {
        return (degreesInFahrenheit - 32) * (5 / 9);
    },
};
