import * as React from 'react';
import { connect } from 'react-redux';
import { UnreachableCaseError } from 'axis-webtools-util';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { DropDown, DropDownMenuButton, IconText } from 'app/components';
import { ServiceLocator } from 'app/ioc';
import type { SortOrder } from '../models';
import { getCurrentSortOrder, getSortOrderOptions } from '../selectors';
import { DeviceSelectorActionService } from '../services';

interface IDeviceSortOrderProps {
    currentSortOrder: SortOrder;
    sortOrders: SortOrder[];
}

const mapStateToProps = (state: IStoreState): IDeviceSortOrderProps => {
    return {
        currentSortOrder: getCurrentSortOrder(state),
        sortOrders: getSortOrderOptions(state),
    };
};

const DeviceSortOrderContainer: React.FunctionComponent<IDeviceSortOrderProps> = ({
    currentSortOrder,
    sortOrders,
}) => {
    const [actionService] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const setSortOrder = (sortOrder: SortOrder) => actionService.setSortOrder(sortOrder);

    const getSortOrderString = (sortOrder: SortOrder) => {
        switch (sortOrder) {
            case 'alphabetical':
                return t.sortOrderGROUP.alphabetical;
            case 'byName':
                return t.sortOrderGROUP.byName;
            case 'bySeries':
                return t.sortOrderGROUP.bySeries;
            case 'byFov':
                return t.sortOrderGROUP.byFoV;
            case 'byPriceLowToHigh':
                return t.sortOrderGROUP.byPriceLowToHigh;
            case 'byPriceHighToLow':
                return t.sortOrderGROUP.byPriceHighToLow;
            case 'byPowerConsumption':
                return t.sortOrderGROUP.byPowerConsumption;
            default:
                throw new UnreachableCaseError(sortOrder);
        }
    };

    return (
        <DropDown
            trigger={
                <IconText
                    icon="arrow_down"
                    textProps={{ color: 'blue', semiBold: true }}
                    iconProps={{ opaque: true, color: 'blue', size: 'sm' }}
                    alignIconRight
                >
                    {getSortOrderString(currentSortOrder)}
                </IconText>
            }
            contents={sortOrders.map((sortOrder) => {
                return (
                    <DropDownMenuButton
                        label={getSortOrderString(sortOrder)}
                        selected={sortOrder === currentSortOrder}
                        onClick={() => setSortOrder(sortOrder)}
                    />
                );
            })}
        />
    );
};

export const DeviceSortOrder = connect(mapStateToProps)(DeviceSortOrderContainer);
DeviceSortOrder.displayName = 'DeviceSortOrder';
