import { externallyAnnouncedOnly, getAllApplications } from 'app/modules/common';
import { createSelector } from 'reselect';
import type { IAcap } from '../models/cameras/IAcap';

export const getCurrentAvailableAcaps = createSelector([getAllApplications], (devices) => {
    /**
     * applications are all piaIds that are of category ACAP,
     * this means that we can get more than one piaId that have the same ACAPId
     * (example:
     * piaId 22681 AXIS Perimeter Defender and
     * piaId 22683 AXIS Perimeter Defender eLicense, both have ACAPId 48330 )
     */
    const applications = devices.filter(externallyAnnouncedOnly);
    const allACAPs: IAcap[] = [];
    applications.forEach((application) => {
        const element = allACAPs.find((acap) => {
            if (acap.ACAPId === application.properties.ACAPId) {
                return acap;
            }
        });
        if (element) {
            element.piaIds.push(application.id);
        } else {
            allACAPs.push({
                ACAPId: application.properties.ACAPId,
                officialFullName: application.properties.officialFullName,
                piaIds: [application.id],
            });
        }
    });
    return allACAPs;
});
