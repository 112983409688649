import { isRelayExpansionModule, type DeviceType, type IItem } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';

/** Checks if the provided item is supported in maps.
 * Note: This will not check supported child items use isSupportedChildDevice to validate children
 */
export const isSupportedDevice = (item: IItem, piaItem: IPiaItem | undefined | null) => {
    return (
        item.properties.camera ||
        item.properties.mainUnit ||
        item.properties.encoder ||
        item.properties.speaker ||
        item.properties.radarDetector ||
        item.properties.doorController ||
        item.properties.peopleCounter ||
        item.properties.alerter ||
        item.properties.decoder ||
        item.properties.dockingStation ||
        item.properties.systemController ||
        item.properties.connectivityDevice ||
        item.properties.pagingConsole ||
        (item.properties.pac && !isRelayExpansionModule(piaItem))
    );
};

/** Checks if the provided child item is supported in maps */
export const isSupportedChildDevice = (childItem: IItem, piaItem: IPiaItem | undefined) => {
    return (
        childItem.properties.sensorUnit ||
        childItem.properties.analogCamera ||
        childItem.properties.door ||
        (childItem.properties.pac && isRelayExpansionModule(piaItem))
    );
};

/** Returns the supported DeviceType:s in maps */
export const supportedDeviceTypes: DeviceType[] = [
    'camera',
    'encoder',
    'analogCamera',
    'mainUnit',
    'sensorUnit',
    'speaker',
    'decoder',
    'pac',
    'radardetector',
    'doorstation',
    'peopleCounter',
    'bodyWornCamera',
    'systemController',
    'dockingStation',
    'cameraExtension',
    'doorcontroller',
    'door',
    'alerter',
    'connectivitydevice',
    'pagingconsole',
];
