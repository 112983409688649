export type DuplicateErrorReason = 'imageQuotaFull';

export class DuplicationError extends Error {
    constructor(
        public reason: DuplicateErrorReason,
        public optionalMessage?: string,
    ) {
        super(reason);
        Object.setPrototypeOf(this, DuplicationError.prototype);
    }
}
