import * as React from 'react';
import type { CategoryEnum, IProduct } from 'app/modules/common';
import {
    translateCategory,
    quotationFilter,
    getCurrentProjectLocale,
    getCurrentProjectCurrency,
} from 'app/modules/common';
import { QuotationViewSubsection } from './QuotationViewSubsection.component';
import { Currency, Heading, Text } from 'app/components';
import { quotationCostCalculator } from '../../util/quotationCostCalculator';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import type { QuotationSection } from '../../models';
import { getSortedProductsForSection } from '../../selectors';
import { QuotationTableStyle } from '../edit';

interface IQuotationViewSectionOwnProps {
    sectionHeader: string;
    sectionType: QuotationSection;
}

interface IQuotationViewSectionProps {
    products: IProduct[];
    sectionHeader: string;
    locale: string;
    currency: string;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IQuotationViewSectionOwnProps,
): IQuotationViewSectionProps => {
    return {
        sectionHeader: ownProps.sectionHeader,
        products: getSortedProductsForSection(storeState, ownProps.sectionType),
        locale: getCurrentProjectLocale(storeState),
        currency: getCurrentProjectCurrency(storeState),
    };
};

class QuotationViewSectionContainer extends React.Component<IQuotationViewSectionProps> {
    public render() {
        const { products, currency, sectionHeader, locale } = this.props;

        if (products.length === 0) {
            return null;
        }

        const renderTable = (category: CategoryEnum) => (
            <QuotationViewSubsection
                key={category}
                header={translateCategory(category)}
                products={quotationFilter.filterProductsByCategory(products, category)}
                currency={currency}
                locale={locale}
            />
        );

        return (
            <>
                <table className={QuotationTableStyle}>
                    <caption>
                        <Heading style="subheader">{sectionHeader}</Heading>
                    </caption>
                    <colgroup span={5} />
                    <thead>
                        <tr>
                            <th scope="col" />
                            <th scope="col">{t.quotationPartNumber}</th>
                            <th scope="col">{t.quotationQuotePrice}</th>
                            <th scope="col">{t.quotationQty}</th>
                            <th scope="col">{t.quotationTotal}</th>
                        </tr>
                    </thead>

                    {quotationFilter.renderAllCategories(renderTable)}

                    <tfoot>
                        <tr>
                            <td colSpan={4}>
                                <Text bold>{t.quotationSectionTotal(sectionHeader, currency)}</Text>
                            </td>
                            <td>
                                <Currency
                                    value={quotationCostCalculator.calculateTotalCost(products)}
                                    currency={currency}
                                    locale={locale}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </>
        );
    }
}

export const QuotationViewSection = connect(mapStateToProps)(QuotationViewSectionContainer);
