import * as React from 'react';
import { DeviceSelector } from 'app/modules/deviceSelector';
import { useParams } from 'react-router-dom';

export const ProjectDeviceSelectorEditChild: React.FunctionComponent = () => {
    // If deviceGroup not provided - all device groups are shown in device selector
    return <DeviceSelector parentId={useParams().itemId} editDeviceId={useParams().subItemId} />;
};

ProjectDeviceSelectorEditChild.displayName = 'ProjectDeviceSelectorEditChild';
