import type { IItemEntity, RecordingSolutionType } from 'app/core/persistence';
import type { IPiaItem, PiaId } from 'app/core/pia';
import {
    getCurrentSolutionSortedByRecorderCategory,
    getPiaItemsRecord,
    getRecordingSolutionTypeFromProject,
} from 'app/modules/common';
import { t } from 'app/translate';
import { UnreachableCaseError } from 'axis-webtools-util';
import { createSelector } from 'reselect';

const AXIS_CAMERA_STATION = 'AXIS Camera Station';
const AXIS_CAMERA_STATION_5 = 'AXIS Camera Station 5';
const AXIS_COMPANION = 'AXIS Companion';
const GENETEC_SECURITY_CENTER = 'Genetec Security Center';
const MILESTONE_XPROTECT = 'Milestone XProtect';

export const getSelectedSolutionHeader = createSelector(
    [
        getRecordingSolutionTypeFromProject,
        getCurrentSolutionSortedByRecorderCategory,
        getPiaItemsRecord,
    ],
    (recordingSolutionType, recordingSolution, piaItems) => {
        const vmsName = getVmsName(recordingSolutionType);
        const serverName = getServerName(recordingSolutionType, recordingSolution, piaItems);

        if (!vmsName || !serverName) return t.selectedSolution;

        return t.selectedVmsAndServerSolution(serverName, vmsName);
    },
);

function getVmsName(recordingSolutionType: RecordingSolutionType | undefined): string | undefined {
    if (!recordingSolutionType) return undefined;

    switch (recordingSolutionType) {
        case 'AxisS1X':
        case 'AxisS2X':
        case 'AxisS30':
            return AXIS_CAMERA_STATION_5;
        case 'AxisS1XPro':
        case 'AxisS2XPro':
        case 'AxisS30Pro':
            return AXIS_CAMERA_STATION;
        case 'AxisCompanionS30':
        case 'AxisCompanionSDCard':
            return AXIS_COMPANION;
        case 'Milestone':
            return MILESTONE_XPROTECT;
        case 'Genetec':
            return GENETEC_SECURITY_CENTER;
        default:
            throw new UnreachableCaseError(recordingSolutionType);
    }
}

function getServerName(
    recordingSolutionType: RecordingSolutionType | undefined,
    recordingSolution: IItemEntity[],
    piaItems: Record<PiaId, IPiaItem>,
): string | undefined {
    if (!recordingSolutionType || recordingSolution.length === 0) return undefined;

    switch (recordingSolutionType) {
        case 'AxisS1X':
        case 'AxisS1XPro':
            return t.s11RecordingSolutionHeading;
        case 'AxisS2X':
        case 'AxisS2XPro':
            return t.s22RecordingSolutionHeading;
        case 'AxisS30':
        case 'AxisS30Pro':
            return t.s30RecordingSolutionHeading;
        case 'AxisCompanionS30':
            return t.s30RecordingSolutionHeading;
        case 'AxisCompanionSDCard':
            return t.edgeStorage;
        case 'Genetec':
            return extractFirstTwoWordsFromPartnerServer(recordingSolution[0].name);
        case 'Milestone':
            const milestoneRecorder = recordingSolution[0]?.productId
                ? piaItems[recordingSolution[0].productId]
                : undefined;
            return extractFirstTwoWordsFromPartnerServer(milestoneRecorder?.name);
        default:
            throw new UnreachableCaseError(recordingSolutionType);
    }
}

function extractFirstTwoWordsFromPartnerServer(text?: string): string | undefined {
    const words = text?.split(' ');
    if (
        !words ||
        words.length < 2 ||
        words.some((word) => word.toLocaleLowerCase().includes('axis'))
    ) {
        return undefined;
    }

    const threeFirstWords = `${words[0]} ${words[1]} ${words[2]}`;
    if (
        threeFirstWords.includes(MILESTONE_XPROTECT) ||
        threeFirstWords.includes(GENETEC_SECURITY_CENTER)
    ) {
        // Do not return the server name if it is the same as the VMS name
        return undefined;
    }

    return `${words[0]} ${words[1]}`;
}
