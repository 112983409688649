import { ServiceLocator } from 'app/ioc';
import { AppStore } from 'app/store';
import { PersistenceActions } from '../../../PersistenceActions';
import type { ICommand } from '../../../models';

export const pushUndoCommand = (command: ICommand) => {
    const appStore = ServiceLocator.get(AppStore);

    appStore.Store.dispatch({
        type: PersistenceActions.PushUndoAction,
        payload: command,
    });
};

export const pushRedoCommand = (command: ICommand) => {
    const appStore = ServiceLocator.get(AppStore);

    appStore.Store.dispatch({
        type: PersistenceActions.PushRedoAction,
        payload: command,
    });
};

export const clearRedoQueue = () => {
    const appStore = ServiceLocator.get(AppStore);

    appStore.Store.dispatch({
        type: PersistenceActions.ClearRedoQueue,
    });
};
