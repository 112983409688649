import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem, getCurrentProjectItemParent } from '../../project';
import { toCacheKey } from '../../cacheKey';

export const getDeviceCount = createCachedSelector(
    [getCurrentProjectItem, getCurrentProjectItemParent],
    (item, parent) => {
        if (!item) {
            return 0;
        }

        if (parent) {
            return parent.quantity * item.quantity;
        }
        return item.quantity;
    },
)(toCacheKey);
