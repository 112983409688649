import { css } from '@emotion/css';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
export type IconTypes = 'human' | 'vehicle';
export const ICONS_ZOOM_BREAKPOINT = 18;
export const ICON_OFFSET = 4;
export const ICON_OFFSET_DOUBLE = 8;
export const HUMAN_ICON_HEIGHT = 24;
export const HUMAN_ICON_WIDTH = 13;
export const VEHICLE_ICON_HEIGHT = 26;
export const VEHICLE_ICON_WIDTH = 18;

export const BLUR_OPACITY = 0.2;
export const FOCUS_OPACITY = 0.9;

export const svgIconStyleCenter = (height: number, width: number) => css`
    margin: calc(${-height / 2}px) calc(${-width / 2}px);
`;

export const svgIconStyle = css`
    margin: 0px -5px;
`;

export const colorStyle = (color: Colors) => css`
    fill: ${ColorsEnum[color]};
`;

export const visibleStyle = css`
    visibility: visible;
`;

export const hiddenStyle = css`
    visibility: hidden;
`;

export const popupStyle = css`
    .leaflet-popup-tip-container {
        display: none;
    }
    .leaflet-popup-content-wrapper {
        border-radius: 4px;
        border: 1ps solid ${ColorsEnum.grey4};
    }
    .leaflet-popup-content {
        margin: 4px 8px;
    }
`;
