import { createSelector } from 'reselect';
import { getCurrentProjectRecordingSolutionItems } from './selectedRecordingSolution';
import { getPiaItemsRecord } from '../piaDevices/selectors/getPiaDevices';
import { PiaItemRecorderCategory } from 'app/core/pia';

export const getHasAcsSolution = createSelector(
    [getCurrentProjectRecordingSolutionItems, getPiaItemsRecord],
    (solutionItems, piaItemsRecord) =>
        solutionItems.some((solutionItem) => {
            if (solutionItem.productId === null) return false;
            const piaItem = piaItemsRecord[solutionItem.productId];
            if (
                piaItem.category === PiaItemRecorderCategory.RECORDERS2 &&
                piaItem.properties.vendor === 'Axis'
            ) {
                return true;
            }
        }),
);
