import * as React from 'react';
import type { IRange } from 'app/components';
import { Range } from 'app/components';
import { convert } from 'axis-webtools-util';
import { DistanceUnit, distanceUnitShortText } from 'app/core/persistence';

interface IDistanceRangeProps extends IRange {
    /**
     * Length unit to use, meter or feet.
     */
    displayUnit: DistanceUnit.Meter | DistanceUnit.Feet;
}

/**
 * A special variant of `<Range>` that displays distances in meters or feet.
 */
export class DistanceRange extends React.PureComponent<IDistanceRangeProps> {
    public render() {
        return (
            <Range
                {...this.props}
                min={this.props.min}
                max={this.props.max}
                value={this.convertToDisplayValue(this.props.value)}
                unit={distanceUnitShortText(this.props.displayUnit)}
                onChange={this.onChange}
                decimals={this.props.decimals}
            />
        );
    }

    private onChange = (displayValue: number) => {
        this.props.onChange(this.convertFromDisplayValue(displayValue));
    };

    private roundValue = (value: number, decimals = 0): number => {
        const decimalFactor = Math.pow(10, decimals);
        return Math.round(value * decimalFactor) / decimalFactor;
    };

    private convertToDisplayValue = (value: number) => {
        if (this.props.displayUnit === DistanceUnit.Feet) {
            value = convert.metersToFeet(value);
        }

        return this.roundValue(value, this.props.decimals);
    };

    private convertFromDisplayValue = (value: number) => {
        if (this.props.displayUnit === DistanceUnit.Feet) {
            value = convert.feetToMeters(value);
        }
        return value;
    };
}
