import React from 'react';
import type * as leaflet from 'leaflet';
import type { DistanceUnit, IDeviceAnalyticRange } from 'app/core/persistence';
import { distanceUnitShortText } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { ServiceLocator } from 'app/ioc';
import {
    HUMAN_ICON_HEIGHT,
    HUMAN_ICON_WIDTH,
    VEHICLE_ICON_HEIGHT,
    VEHICLE_ICON_WIDTH,
    svgIconStyleCenter,
    colorStyle,
    visibleStyle,
} from './utils/iconConstants';
import type { BaseCone } from './BaseCone';
import type { LeafletMap } from '../LeafletMap';
import { LeafletItemFactory } from '../../../services';
import { useMouseClickEvent } from '../../../hooks';
import type { analyticProperties } from '../../../utils';
import { getAnalyticProperties } from '../../../utils';
import { IconStyle } from 'app/components';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    generateToDisplayUnitConverter,
    getAnalyticRangeForItem,
    getCurrentProjectDisplayUnit,
} from 'app/modules/common';
import { convertDensityToDisplayUnit } from 'app/core/common';
import { usePopupCloseDelay } from './usePopupDelay.hook';

export const BLUR_OPACITY = 0.2;
export const FOCUS_OPACITY = 0.9;

interface IIconRendererProps {
    map: LeafletMap;
    coverageArea: BaseCone;
    isOpaque: boolean;
    color: Colors;
    deviceId: string;
}

export const IconRenderer: React.FC<IIconRendererProps> = ({
    map,
    coverageArea,
    isOpaque,
    color,
    deviceId,
}) => {
    const [factory] = React.useState(ServiceLocator.get(LeafletItemFactory));
    const [humanIcon] = React.useState<leaflet.Marker>(
        factory.createHumanMarker({ lat: 0, lng: 0 }, color, true),
    );
    const [vehicleIcon] = React.useState<leaflet.Marker>(
        factory.createVehicleMarker({ lat: 0, lng: 0 }, color, true),
    );
    const displayUnit = useSelector<IStoreState, DistanceUnit>((state) =>
        getCurrentProjectDisplayUnit(state),
    );

    const analyticRange = useSelector<IStoreState, IDeviceAnalyticRange | undefined>((store) =>
        getAnalyticRangeForItem(store, deviceId),
    );

    const [analyticValues, setAnalyticValues] = React.useState<analyticProperties | undefined>(
        analyticRange?.applicationId && coverageArea.sensor?.parentPiaDevice
            ? getAnalyticProperties(
                  analyticRange?.applicationId,
                  coverageArea.sensor?.parentPiaDevice,
                  analyticRange?.lightCondition,
                  analyticRange?.analyticMode,
              )
            : undefined,
    );
    const convertToDisplayUnit = generateToDisplayUnitConverter(displayUnit);
    const popupContent = coverageArea.getAnalyticsPopupContent();

    const getRow = (value: number | string, abbreviation: string) => `
        <tr>
            <td style="text-align: right">${value}</td>
            <td style="color: ${ColorsEnum.grey5};">${abbreviation}</td>
        </tr>
    `;

    const getAnalyticsPopupContent = (
        marker: 'person' | 'vehicle',
        distance?: number,
        pxPerMeter?: number,
        pixelCount?: number,
        percent?: number,
    ) => {
        return `
        <div style="display: flex; align-items: center;">
        <i class="${IconStyle}" style="color: ${ColorsEnum.grey5};" width="80px">
            ${marker === 'person' ? 'directions_walk' : 'directions_car'}
        </i>
        <table data-test-id="device_ranges">
            ${
                distance
                    ? getRow(
                          convertToDisplayUnit(distance).toFixed(1),
                          distanceUnitShortText(displayUnit),
                      )
                    : ''
            }
            ${
                pxPerMeter
                    ? getRow(
                          convertDensityToDisplayUnit(pxPerMeter, displayUnit).toFixed(0),
                          `${t.abbreviationsGROUP.pixel}/${distanceUnitShortText(displayUnit)}`,
                      )
                    : ''
            }
            ${
                pixelCount && pxPerMeter
                    ? getRow(
                          (
                              convertDensityToDisplayUnit(pxPerMeter, displayUnit) * pixelCount
                          ).toFixed(0),
                          t.abbreviationsGROUP.pixel,
                      )
                    : ''
            }
            ${percent ? getRow(percent, '%') : ''}
        </table>
    </div>
`;
    };

    // update analytic values when values change and close possible popups
    React.useEffect(() => {
        vehicleIcon.closePopup();
        humanIcon.closePopup();
        const analyticProperties =
            analyticRange?.applicationId && coverageArea.sensor?.parentPiaDevice
                ? getAnalyticProperties(
                      analyticRange?.applicationId,
                      coverageArea.sensor?.parentPiaDevice,
                      analyticRange?.lightCondition,
                      analyticRange?.analyticMode,
                  )
                : undefined;
        setAnalyticValues(analyticProperties);
    }, [analyticRange, coverageArea.sensor?.parentPiaDevice, humanIcon, vehicleIcon]);
    const showPopup = React.useCallback(
        (content: string | null, icon: 'person' | 'vehicle') => {
            if (content) {
                if (icon === 'person') {
                    humanIcon.setPopupContent(content);
                    humanIcon.openPopup();
                } else {
                    vehicleIcon.setPopupContent(content);
                    vehicleIcon.openPopup();
                }
            }
        },
        [humanIcon, vehicleIcon],
    );

    // callback for closing the popup
    const closeWhenNoActivity = usePopupCloseDelay(() => {
        humanIcon.closePopup();
        vehicleIcon.closePopup();
    });

    useMouseClickEvent(humanIcon, () => {
        showPopup(
            getAnalyticsPopupContent(
                'person',
                popupContent?.person.rangeLimit,
                popupContent?.person.pxPerMeter,
                analyticValues?.person.height,
                analyticValues?.person.percent,
            ),
            'person',
        );
        closeWhenNoActivity();
    });

    useMouseClickEvent(vehicleIcon, () => {
        showPopup(
            getAnalyticsPopupContent(
                'vehicle',
                popupContent?.vehicle.rangeLimit,
                popupContent?.vehicle.pxPerMeter,
                analyticValues?.vehicle.height,
                analyticValues?.vehicle.percent,
            ),
            'vehicle',
        );
        closeWhenNoActivity();
    });

    React.useEffect(() => {
        humanIcon.addTo(map.map);
        vehicleIcon.addTo(map.map);

        coverageArea.onIconPositionsChanged = (iconInfo) => {
            if (!iconInfo) {
                humanIcon.removeFrom(map.map);
                vehicleIcon.removeFrom(map.map);
                return;
            }
            if (iconInfo['human'].visible) {
                humanIcon.addTo(map.map);
                humanIcon.setLatLng(iconInfo['human'].latLng);
            } else {
                humanIcon.removeFrom(map.map);
            }

            if (iconInfo['vehicle'].visible) {
                vehicleIcon.addTo(map.map);
                vehicleIcon.setLatLng(iconInfo['vehicle'].latLng);
            } else {
                vehicleIcon.removeFrom(map.map);
            }
        };

        return () => {
            coverageArea.onIconPositionsChanged = undefined;
            humanIcon.removeFrom(map.map);
            vehicleIcon.removeFrom(map.map);
        };
    }, [coverageArea, humanIcon, map.map, vehicleIcon]);

    React.useEffect(() => {
        humanIcon.setIcon(
            factory.createHumanIcon(
                svgIconStyleCenter(HUMAN_ICON_HEIGHT, HUMAN_ICON_WIDTH),
                visibleStyle,
                colorStyle(color),
            ),
        );
        vehicleIcon.setIcon(
            factory.createVehicleIcon(
                svgIconStyleCenter(VEHICLE_ICON_HEIGHT, VEHICLE_ICON_WIDTH),
                visibleStyle,
                colorStyle(color),
            ),
        );
    }, [color, factory, humanIcon, vehicleIcon]);

    React.useEffect(() => {
        humanIcon?.setOpacity(isOpaque ? FOCUS_OPACITY : BLUR_OPACITY);
        vehicleIcon?.setOpacity(isOpaque ? FOCUS_OPACITY : BLUR_OPACITY);
    }, [isOpaque, humanIcon, vehicleIcon]);

    return null;
};
