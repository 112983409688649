import { Module } from 'app/ioc';
import { CustomItemsActionService } from './services';
import { customItemsReducer } from './CustomItemsReducer';

@Module({
    binder: (binder) => {
        binder.bindService(CustomItemsActionService);
    },
    reducers: {
        customItems: customItemsReducer,
    },
})
export class CustomItemsModule {}
