import * as React from 'react';
import { Box, TableRow, Text, Icon } from 'app/components';
import type { IStoreState } from 'app/store';
import {
    getHasPoeAccessoryForDeviceId,
    getModelName,
    getPowerSupplyForDeviceId,
    getIsPoeIncludedForItem,
    getIsIlluminator,
} from 'app/modules/common';
import type { IItemEntity, IPersistence } from 'app/core/persistence';
import { getBareboneForId } from 'app/modules/lensSelector/selectors';
import { useSelector } from 'react-redux';
import type { IPiaItem } from 'app/core/pia';

import { format } from 'axis-webtools-util';
import { t } from 'app/translate';

interface IPowerTableRowOwnProps {
    item: IPersistence<IItemEntity>;
    typicalPower: number;
    maxPower: number;
}

export const PowerTableRow: React.FC<IPowerTableRowOwnProps> = ({
    item,
    typicalPower,
    maxPower,
}) => {
    const barebone = useSelector<IStoreState, IPiaItem | undefined>((state) =>
        getBareboneForId(state, item._id),
    );
    const bareboneProduct = item?.replaceWithBareboneId ? barebone : undefined;

    const productModelName = useSelector<IStoreState, string>((state) =>
        getModelName(state, item._id),
    );
    const modelName =
        item?.replaceWithBareboneId && bareboneProduct ? bareboneProduct.name : productModelName;

    const hasAddedMidspan = useSelector<IStoreState, boolean>((state) =>
        getHasPoeAccessoryForDeviceId(state, item._id),
    );

    const hasIncludedMidspan = useSelector<IStoreState, boolean | undefined>((state) =>
        getIsPoeIncludedForItem(state, item.productId),
    );

    const isIlluminator = useSelector<IStoreState, boolean>((state) =>
        getIsIlluminator(state, item._id),
    );

    const midspan = hasAddedMidspan
        ? ` - ${t.midspanAdded}`
        : hasIncludedMidspan
          ? ` - ${t.midspanIncluded}`
          : '';

    const powerSupply = useSelector<IStoreState, string>((state) =>
        getPowerSupplyForDeviceId(state, item._id),
    );

    const maxPowerString = maxPower
        ? item.quantity > 1
            ? `${format.power(maxPower * item.quantity)} (${item.quantity} × ${format.power(maxPower)})`
            : format.power(maxPower)
        : '';

    const typicalPowerString = typicalPower
        ? item.quantity > 1
            ? `${format.power(typicalPower * item.quantity)} (${item.quantity} × ${format.power(typicalPower)})`
            : format.power(typicalPower)
        : maxPowerString; // Typical power missing on some devices use max power instead as fallback.

    return (
        <>
            <TableRow
                testId={`power_tr_${modelName}`}
                cells={[
                    item.name ? (
                        <Box spacing="half" wrap>
                            {!isIlluminator && (
                                <>
                                    <Text
                                        inline
                                        testId={`power_tr_model_${modelName}`}
                                    >{`${modelName}${midspan}`}</Text>
                                    <Text
                                        inline
                                        color="grey6"
                                        testId={`power_tr_name_${modelName}`}
                                    >
                                        ({item.name})
                                    </Text>
                                </>
                            )}
                            {isIlluminator && (
                                <Box alignItems="center">
                                    <Text inline testId={`power_tr_model_${modelName}`}>
                                        {modelName}
                                    </Text>
                                    <Icon icon="keyboard_arrow_right" color="grey6" size="sm" />
                                    <Text
                                        inline
                                        color="grey6"
                                        testId={`power_tr_parent_${modelName}`}
                                    >
                                        {item.name}
                                    </Text>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        modelName
                    ),
                    item.quantity,
                    <Text testId={`power_tr_${modelName}_typical_power`}>
                        {typicalPowerString}
                    </Text>,
                    <Text testId={`power_tr_${modelName}_max_power`}>{maxPowerString}</Text>,
                    <Text testId={`power_tr_${modelName}_power_supply`}>{powerSupply}</Text>,
                ]}
            />
        </>
    );
};
