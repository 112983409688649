import * as React from 'react';
import { t } from 'app/translate';
import { Stack, Button, UploadFile, Text, Box, Card } from 'app/components';

const SUPPORTED_IMAGE_TYPES = 'image/gif, image/svg, image/png, image/ico, image/jpg, image/jpeg';

interface ILogoProps {
    urlEncodedLogo?: string | null;
    setLogo(logo: string | undefined): void;
}

export const Logo: React.FC<ILogoProps> = ({ urlEncodedLogo, setLogo }) => {
    const [unsupportedFileTypeError, setUnsupportedFileTypeError] = React.useState<boolean>(false);

    const onLogoUploaded = async (file: File): Promise<void> => {
        if (
            SUPPORTED_IMAGE_TYPES.split(',')
                .map((type) => type.trim())
                .includes(file.type)
        ) {
            setUnsupportedFileTypeError(false);

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    setLogo(reader.result);
                }
            };
        } else {
            setUnsupportedFileTypeError(true);
        }
    };

    const onRemoveLogo = () => {
        setLogo(undefined);
    };

    return (
        <Stack vertical notFullWidth>
            {!urlEncodedLogo && (
                <Card color="grey2">
                    <Box alignItems="center" justifyContent="center" width="150px" height="80px">
                        <UploadFile
                            text
                            title={t.uploadLogo}
                            accept={SUPPORTED_IMAGE_TYPES}
                            onFileUploaded={onLogoUploaded}
                        />
                    </Box>
                </Card>
            )}
            {unsupportedFileTypeError && (
                <Text color="red">{t.supportedFileTypes(SUPPORTED_IMAGE_TYPES)}</Text>
            )}
            {urlEncodedLogo && (
                <img
                    height="60px"
                    width="150px"
                    src={urlEncodedLogo}
                    style={{ objectFit: 'contain' }}
                />
            )}
            {urlEncodedLogo && (
                <Button text onClick={onRemoveLogo}>
                    {t.removeLogo}
                </Button>
            )}
        </Stack>
    );
};

Logo.displayName = 'Logo';
