import { getDeviceIconByType } from 'app/modules/common';
import { t } from 'app/translate';
import { createSelector } from 'reselect';
import type { DeviceGroup, IGenericProductInfo } from '../models';
import { getDeviceGroup } from './getProductsForDeviceGroup';

export const getGenericProductInfo = createSelector([getDeviceGroup], (deviceGroup) =>
    getGenericInfo(deviceGroup),
);

const getGenericInfo = (deviceGroup: DeviceGroup): IGenericProductInfo => {
    switch (deviceGroup) {
        case 'cameras':
            return {
                deviceGroup,
                productName: t.genericCamera,
                icon: getDeviceIconByType('camera') ?? 'device',
            };
        case 'encoders':
            return {
                deviceGroup,
                productName: t.genericEncoder,
                icon: getDeviceIconByType('encoder') ?? 'device',
            };
        case 'mainunits':
            return {
                deviceGroup,
                productName: t.genericMainUnit,
                icon: getDeviceIconByType('mainUnit') ?? 'device',
            };
        case 'sensorunits':
            return {
                deviceGroup,
                productName: t.genericSensorUnit,
                icon: getDeviceIconByType('sensorUnit') ?? 'device',
            };
        case 'speakers':
            return {
                deviceGroup,
                productName: t.genericSpeaker,
                icon: getDeviceIconByType('speaker') ?? 'device',
            };
        case 'accessControls':
            return {
                deviceGroup,
                productName: t.genericDoorController,
                icon: getDeviceIconByType('doorcontroller') ?? 'device',
            };

        default:
            return {
                deviceGroup,
                productName: t.other,
                icon: 'device',
            };
    }
};
