import * as React from 'react';
import { Box, Stack, Text } from 'app/components';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import { css } from '@emotion/css';

import { Schedules } from '../../../installationReport/components/schedules';
import { getScheduleForId } from '../../../installationReport/selectors';
import type { IInstallationReportSchedule } from '../../../installationReport/models';

import type { IStoreState } from 'app/store';

interface IProposalDeviceDetailSettingsOwnProps {
    id: string;
    continuousRecordingScheduleId?: string;
    continuousRecordingResolution?: string;
    continuousRecordingFramerate?: number;
    motionTriggeredScheduleId?: string;
    motionTriggeredResolution?: string;
    motionTriggeredFramerate?: number;
    retentionTime?: number;
}
interface IProposalDeviceDetailSettingsProps extends IProposalDeviceDetailSettingsOwnProps {
    continuousRecordingSchedule: IInstallationReportSchedule | undefined;
    motionTriggeredSchedule: IInstallationReportSchedule | undefined;
}

const tableStyle = css`
    tr {
        page-break-inside: avoid;
    }
`;

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IProposalDeviceDetailSettingsOwnProps,
): IProposalDeviceDetailSettingsProps => {
    return {
        continuousRecordingSchedule: getScheduleForId(
            storeState,
            ownProps.continuousRecordingScheduleId,
        ),
        motionTriggeredSchedule: getScheduleForId(storeState, ownProps.motionTriggeredScheduleId),
        ...ownProps,
    };
};
class ProposalDeviceDetailSettingsContainer extends React.Component<IProposalDeviceDetailSettingsProps> {
    public render() {
        const {
            continuousRecordingSchedule,
            motionTriggeredSchedule,
            continuousRecordingResolution,
            continuousRecordingFramerate,
            motionTriggeredFramerate,
            motionTriggeredResolution,
            retentionTime,
        } = this.props;
        const hasSchedule = !!continuousRecordingSchedule || !!motionTriggeredSchedule;

        return hasSchedule ? (
            <table className={tableStyle}>
                <tbody>
                    <tr>
                        <td>
                            <Box justifyContent="center" flex="shrink" paddingTop="half">
                                <Stack spacing="doublePanel" alignItems="start">
                                    {continuousRecordingFramerate &&
                                        continuousRecordingResolution &&
                                        continuousRecordingSchedule &&
                                        retentionTime && (
                                            <Stack spacing="half" vertical alignItems="center">
                                                <Box paddingBottom="half">
                                                    <Text style="title" color="warmGrey6">
                                                        {
                                                            t.profilesScheduledRecordingContinuousRecording
                                                        }
                                                    </Text>
                                                </Box>

                                                <Text style="heading">
                                                    {t.storeVideoFor(retentionTime)}
                                                </Text>
                                                <Text style="heading">
                                                    {`${continuousRecordingResolution} (${continuousRecordingFramerate} ${t.fps})`}
                                                </Text>

                                                <Schedules
                                                    hideHeader
                                                    schedules={[continuousRecordingSchedule]}
                                                />
                                            </Stack>
                                        )}

                                    {motionTriggeredSchedule &&
                                        motionTriggeredFramerate &&
                                        motionTriggeredResolution &&
                                        retentionTime && (
                                            <Stack spacing="half" vertical alignItems="center">
                                                <Box paddingBottom="half">
                                                    <Text style="title" color="warmGrey6">
                                                        {
                                                            t.profilesScheduledRecordingTriggeredRecording
                                                        }
                                                    </Text>
                                                </Box>

                                                <Text style="heading">
                                                    {t.storeVideoFor(retentionTime)}
                                                </Text>
                                                <Text style="heading">
                                                    {`${motionTriggeredResolution} (${motionTriggeredFramerate} ${t.fps})`}
                                                </Text>

                                                <Schedules
                                                    hideHeader
                                                    schedules={[motionTriggeredSchedule]}
                                                />
                                            </Stack>
                                        )}
                                </Stack>
                            </Box>
                        </td>
                    </tr>
                </tbody>
            </table>
        ) : null;
    }
}

export const ProposalDeviceDetailSettings = connect(mapStateToProps)(
    ProposalDeviceDetailSettingsContainer,
);
