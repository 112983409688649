import { createSelector } from 'reselect';
import type { Id } from 'app/core/persistence';
import { uniq } from 'lodash-es';
import { getMapsState } from './getMapsState';
import {
    getCurrentProjectInstallationPointsArray,
    getCurrentProjectFreeTextPointsArray,
} from 'app/modules/common';

export const getOriginFilter = createSelector([getMapsState], (state): Id | undefined => {
    return state.originFilter;
});

export const getShouldHaveMapOriginFilter = createSelector(
    [getCurrentProjectInstallationPointsArray, getCurrentProjectFreeTextPointsArray],
    (installationPoints, freeTextPoints) => {
        // all items that can have a map origin
        const allItems = [...installationPoints, ...freeTextPoints];
        // all origins pointing on map locations
        const mapLocationOrigins = allItems
            .map((item) => item.mapOrigin)
            .filter((origin) => origin !== undefined && origin.startsWith('mapLocation:'));

        return uniq(mapLocationOrigins).length > 1;
    },
);
