import {
    DropDownMenu,
    DropDownMenuButton,
    DropDownMenuDelete,
    DropDownMenuLink,
} from 'app/components';
import type { Id } from 'app/core/persistence';
import { DuplicationService, ItemService } from 'app/core/persistence';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getChangeLabel, getShouldHideChangeDevice } from '../../../selectors';
import type { IStoreState } from 'app/store';
import {
    getCurrentProjectLocked,
    getDeviceSelectorEditLink,
    getDeviceAndSubTypeForItem,
    getModelName,
} from 'app/modules/common';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { ProjectDevicesActionService } from '../../../services';

interface IDropDownCol {
    itemId: Id;
    isChild?: boolean;
}

export const DropDownCol: React.FC<IDropDownCol> = ({ itemId, isChild }) => {
    const model = useSelector<IStoreState, string>((state) => getModelName(state, itemId));
    const editLink = useSelector<IStoreState, string | undefined>((state) =>
        getDeviceSelectorEditLink(state, itemId),
    );
    const hideChangeDevice = useSelector<IStoreState, boolean>((state) =>
        getShouldHideChangeDevice(state, itemId),
    );

    const locked = useSelector(getCurrentProjectLocked);

    const itemService = useService(ItemService);
    const duplicationService = useService(DuplicationService);
    const projectDevicesActionService = useService(ProjectDevicesActionService);

    const deviceType = useSelector<IStoreState, string | undefined>((state) =>
        getDeviceAndSubTypeForItem(state, itemId),
    );

    const changeLabel = useSelector<IStoreState, string>((state) => getChangeLabel(state, itemId));

    const onDelete = () => {
        eventTracking.logUserEvent('Project Devices', 'Delete Device', deviceType);
        itemService.deleteItem(itemId);
    };

    const onDuplicate = () => {
        eventTracking.logUserEvent('Project Devices', 'Duplicate Device', deviceType);
        duplicationService.copyItemInCurrentProject(itemId, 1);
    };

    const onCopy = () => {
        projectDevicesActionService.openMultipleCopiesModal(itemId);
    };

    return (
        <DropDownMenu testId={`select_ddm_${model}`} testIdChild="ddm_device_in_table">
            {editLink && !hideChangeDevice && (
                <DropDownMenuLink
                    testId="change_device_in_ddm"
                    disabled={locked}
                    label={changeLabel}
                    icon="repeat"
                    link={editLink}
                />
            )}
            {!isChild && (
                <>
                    <DropDownMenuButton
                        testId="duplicate_device"
                        disabled={locked}
                        label={t.duplicate}
                        icon="content_copy"
                        onClick={onDuplicate}
                    />
                    <DropDownMenuButton
                        disabled={locked}
                        label={t.multipleCopies}
                        icon="content_copy"
                        onClick={onCopy}
                    />
                </>
            )}
            <DropDownMenuDelete testId="delete_device" disabled={locked} onDelete={onDelete} />
        </DropDownMenu>
    );
};

DropDownCol.displayName = 'DropDownCol';
