import { deleteDatabaseKey } from './databaseUtil';
import type { IMigration } from './models/IMigration';
import { KeyValueStore } from 'axis-webtools-util';

export const migrate2to3: IMigration = {
    from: 2,
    to: 3,
    run: migrate,
};

async function migrate(): Promise<void> {
    await moveAddressByProjectId();
    await clearAddressByProjectId();
}

const moveAddressByProjectId = async () => {
    const asd1Store = new KeyValueStore<any>('axis.webtools.customerInfo', 'addressByProjectId');
    const asd2Store = new KeyValueStore<any>('axis.webtools.customerInfo', 'customerInfo');

    const items = await asd1Store.getItems();
    asd2Store.setItems(items);
};

const clearAddressByProjectId = () =>
    deleteDatabaseKey('axis.webtools.customerInfo', 'addressByProjectId');
