import type { IBaseEntity, IItemEntity } from 'app/core/persistence';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

/**
 * Migrate new properties isSceneFilterActive and applications (acaps) in camera filter properties.
 *
 * Since other members of the team (f.e testers) might already have projects with these properties set,
 * we don't override values. This would not be necessary from a customers perspective.
 */
@injectable()
export class Migration23To24 extends MigrationProviderBase {
    public from: number = 23;
    public to: number = 24;

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (entity.properties.camera && entity.properties.camera.filter) {
                    if (entity.properties.camera.filter.isSceneFilterActive === undefined) {
                        entity.properties.camera.filter.isSceneFilterActive = true;
                    }
                    if (entity.properties.camera.filter.applications === undefined) {
                        entity.properties.camera.filter.applications = [];
                    }
                }
            }

            return entity;
        };
    }
}
