import * as React from 'react';
import { useDeferredValue } from 'react';
import { useMount } from 'app/hooks';
import { Viewable } from '../viewable/Viewable';
import type { IExtendableComponentWithChildren } from '../../models';

interface ILazyRenderProps extends IExtendableComponentWithChildren {
    // whether the item should be visible initially
    // Useful for always making the first n elements show up immediately
    initiallyVisible?: boolean;
    heightEstimate?: number;
}

/**
 * Defers the rendering of children until they become visible
 */
export const LazyRender: React.FC<ILazyRenderProps> = ({
    initiallyVisible = false,
    heightEstimate = 1,
    children,
}) => {
    const [visible, setVisible] = React.useState(false);
    const deferredVisible = useDeferredValue(visible);

    useMount(() => {
        // don't set initial state until on mount. This way deferredVisible will be
        // initially false which is beneficial for render performance
        setVisible(initiallyVisible);
    });

    return (
        <Viewable
            onBecomesVisible={() => {
                setVisible(true);
            }}
        >
            {deferredVisible ? children : <div style={{ height: heightEstimate }} />}
        </Viewable>
    );
};

LazyRender.displayName = 'LazyRender';
