import { injectable } from 'inversify';

@injectable()
export class TimestampProviderService {
    public getIsoTimestamp() {
        return new Date().toISOString();
    }

    public isISODateString(date?: string): boolean {
        try {
            return !!date && new Date(date).toISOString() === date;
        } catch {
            return false;
        }
    }
}
