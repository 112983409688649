import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds description and notes properties to IItemEntity.
 */
@injectable()
export class Migration6To7 extends MigrationProviderBase {
    public from: number = 6;
    public to: number = 7;

    public provideMigration() {
        return (entity: any): Promise<any> => {
            if (entity.type === 'item') {
                entity.description = '';
                entity.notes = '';
            }

            return Promise.resolve(entity);
        };
    }
}
