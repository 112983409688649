import { createCachedSelector } from 're-reselect';
import type { Icons } from 'app/components';
import { deviceIcons } from '../../products';
import { toCacheKey } from '../../cacheKey';
import { getDeviceAndSubTypeForItem } from './getDeviceTypeForItem';
import { getIsCustomCamera } from './getIsCustomCamera';

export const getPiaLoadingIcon = createCachedSelector(
    [getDeviceAndSubTypeForItem, getIsCustomCamera],
    (deviceType, isCustomCamera): Icons => {
        if (isCustomCamera) return 'videocam';
        return deviceIcons.toIcon(deviceType);
    },
)(toCacheKey);
