// Based on: https://gist.githubusercontent.com/scf37/6b4bf47dce4d78be92216323b12f2d21/raw/478e81b131ee823d796c20017cce0a0b54264253/imageOrientation.ts
// Based on: https://stackoverflow.com/a/46814952/283851
// Based on: https://gist.github.com/mindplay-dk/72f47c1a570e870a375bd3dbcb9328fb

/**
 * @returns EXIF orientation value of file (or undefined if no EXIF orientation available)
 */
export const readExifOrientation = (file: Blob) =>
    new Promise<number | undefined>((resolve) => {
        const reader = new FileReader();

        reader.onload = () =>
            resolve(
                (() => {
                    const view = new DataView(
                        /** @type {ArrayBuffer} */ reader.result as ArrayBuffer,
                    );

                    if (view.getUint16(0, false) != 0xffd8) {
                        return; // not JPEG
                    }

                    const length = view.byteLength;

                    let offset = 2;

                    while (offset < length) {
                        const marker = view.getUint16(offset, false);

                        offset += 2;

                        if (marker == 0xffe1) {
                            offset += 2;

                            if (view.getUint32(offset, false) != 0x45786966) {
                                return; // no EXIF
                            }

                            offset += 6;

                            const little = view.getUint16(offset, false) == 0x4949;

                            offset += view.getUint32(offset + 4, little);

                            const tags = view.getUint16(offset, little);

                            offset += 2;

                            for (let i = 0; i < tags; i++) {
                                if (view.getUint16(offset + i * 12, little) == 0x0112) {
                                    return view.getUint16(offset + i * 12 + 8, little);
                                }
                            }
                        } else if ((marker & 0xff00) != 0xff00) {
                            break;
                        } else {
                            offset += view.getUint16(offset, false);
                        }
                    }
                })(),
            );

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    });
