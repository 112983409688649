import { Resolution } from 'app/core/persistence';

export const getMaxResolutionString = (
    resolutionString: string,
    maxVideoResolutionHorizontal: number,
    maxVideoResolutionVertical: number,
) => {
    const resolution = new Resolution(resolutionString);

    const maxHorizontal = maxVideoResolutionHorizontal;
    const maxVertical = maxVideoResolutionVertical;

    if (
        maxHorizontal &&
        maxHorizontal < resolution.getHorizontal() &&
        maxVertical &&
        maxVertical < resolution.getVertical()
    ) {
        return `${maxHorizontal}×${maxVertical}`;
    } else {
        return resolution.toString();
    }
};
