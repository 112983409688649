import * as React from 'react';
import { useSelector } from 'react-redux';
import { Stack, Button, Text, Textarea } from 'app/components';
import type { ILocation, IPlaceSearchResult } from 'app/modules/common';
import { PlaceSearch, GoogleStaticMap, getCurrentProjectLocation } from 'app/modules/common';

import { EditProjectCountry } from './EditProjectCountry.container';
import { t } from 'app/translate';
import type { IBounds } from 'app/core/persistence';

interface IEditProjectLocationProps {
    preparedFor: string;
    locationId: number;
    locationName?: string;
    defaultLocation?: ILocation;
    defaultBounds?: IBounds;
    onSave(
        preparedFor: string,
        location?: ILocation,
        bounds?: IBounds,
        locationName?: string,
        locationId?: number,
    ): void;
    onCancel(): void;
}

export const EditProjectLocation: React.FunctionComponent<IEditProjectLocationProps> = ({
    preparedFor: initialPreparedFor,
    locationId: initialLocationId,
    locationName: initialLocationName,
    onSave,
    onCancel,
    defaultLocation: currentLocation,
    defaultBounds: currentBounds,
}) => {
    const defaultCountry = useSelector(getCurrentProjectLocation)?.name;
    const [location, setLocation] = React.useState<ILocation | undefined>(currentLocation);
    const [bounds, setBounds] = React.useState<IBounds | undefined>(currentBounds);
    const [locationName, setLocationName] = React.useState<string | undefined>(initialLocationName);
    const [locationId, setLocationId] = React.useState<number | undefined>(initialLocationId);
    const [preparedFor, setPreparedFor] = React.useState<string>(initialPreparedFor);

    const onSelectLocation = (newLocation: IPlaceSearchResult) => {
        setLocation(newLocation.location);
        if (newLocation.address) {
            setLocationName(newLocation.address);
            setLocationId(newLocation.locationId);
            setPreparedFor(newLocation.address);
            setBounds(newLocation.bounds);
        }
    };

    const hasChanges =
        location !== currentLocation ||
        locationId !== initialLocationId ||
        preparedFor !== initialPreparedFor;

    return (
        <Stack vertical>
            <GoogleStaticMap
                lat={location?.lat}
                lng={location?.lng}
                location={defaultCountry}
                containerWidth="600px"
                height={200}
                width={600}
                bounds={bounds}
            />
            <PlaceSearch onSelect={onSelectLocation} />

            <Stack vertical spacing="cell">
                <Text style="semibold">{t.projectChooseInstallationCountryRegion}</Text>
                <EditProjectCountry locationId={locationId} onChangeLocationId={setLocationId} />
            </Stack>

            <Stack vertical spacing="cell">
                <Text style="semibold">{t.labelPreparedFor}</Text>
                <Textarea
                    fontWeight={600}
                    textColor="grey7"
                    rows={6}
                    changeCriteria="key"
                    value={preparedFor}
                    onChange={setPreparedFor}
                />
            </Stack>
            <Stack justifyContent="end">
                <Button text onClick={onCancel}>
                    {t.cancel}
                </Button>
                <Button
                    disabled={!hasChanges}
                    primary
                    onClick={() => onSave(preparedFor, location, bounds, locationName, locationId)}
                    testId="save_btn"
                >
                    {t.userContactDetailsSave}
                </Button>
            </Stack>
        </Stack>
    );
};

EditProjectLocation.displayName = 'EditProjectLocation';
