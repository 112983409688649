import { Box } from 'app/components';
import { getItemLocalStorage } from 'app/core/persistence';
import { getCurrentProjectSystemComponentsIncludingPartner } from 'app/modules/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getGenetecRecommendations, getIsFetchingGenetecRecommendations } from '../../selectors';
import { GenetecDisclaimer } from './GenetecDisclaimer';
import { GenetecRecommendationItems } from './GenetecRecommendationItems';
import { GenetecStreamVaultCalculator } from './GenetecStreamVaultCalculator';

import { NoRecommendations } from './NoRecommendations';

interface IGenetecRecommendationsProps {
    onModifyRecommendations(): void;
}

export const GenetecRecommendations: React.FC<IGenetecRecommendationsProps> = ({
    onModifyRecommendations,
}) => {
    const recommendations = useSelector(getGenetecRecommendations);
    const isFetchingRecommendations = useSelector(getIsFetchingGenetecRecommendations);
    const showGenetecDisclaimer =
        useSelector(getCurrentProjectSystemComponentsIncludingPartner).length > 0 ||
        recommendations.length > 0;

    const isTermsAndConditionsAccepted =
        getItemLocalStorage('TermsAndConditionsAccepted') === 'true';

    return (
        <Box direction="column" spacing="base" width="100%">
            {recommendations.length > 0 || isFetchingRecommendations ? (
                <GenetecRecommendationItems maxWidth={200} />
            ) : (
                !isFetchingRecommendations && <NoRecommendations />
            )}
            <GenetecStreamVaultCalculator
                disabled={!isTermsAndConditionsAccepted}
                onModifyRecommendations={onModifyRecommendations}
            />
            <Box alignItems="center" paddingX="panel">
                {showGenetecDisclaimer && <GenetecDisclaimer />}
            </Box>
        </Box>
    );
};

GenetecRecommendations.displayName = 'GenetecRecommendations';
