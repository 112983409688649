import * as React from 'react';
import { Stack } from '../../layout/stack/Stack.component';
import { css } from '@emotion/css';
import type { IExtendableComponent } from '../../models';
import type { AlignItems } from 'app/styles';

export interface ITextPlaceholderProps extends IExtendableComponent {
    /**
     * Define number of rows in the text skeleton.
     */
    rows?: number;

    width?: React.CSSProperties['width'];

    textAlign?: AlignItems;
}

const getTextRowStyle = (delayMs: number, width: React.CSSProperties['width']) => css`
    display: flex;
    width: ${width};
    height: 15px;
    border-radius: 4px;
    background: black;
    opacity: 0.08;
    animation: opacityAnimation 2s ease infinite;
    animation-delay: ${delayMs}ms;
    @keyframes opacityAnimation {
        0% {
            opacity: 0.08;
        }
        50% {
            opacity: 0.15;
        }
        100% {
            opacity: 0.08;
        }
    }
`;

/**
 * Used to indicate loading state for a text. Will show a flashing text skeleton.
 * @param rows
 */
export const TextPlaceholder: React.FunctionComponent<ITextPlaceholderProps> = ({
    rows,
    width,
    textAlign,
    ...extendedProps
}) => {
    const textRows = Array.from(Array(rows ?? 1).keys());
    const [rowWidths] = React.useState(
        textRows.map((row) => {
            return textRows.length > 1 && row === textRows.length - 1
                ? Math.floor(Math.random() * (60 + 1) + 15)
                : Math.floor(Math.random() * (10 + 1) + 90);
        }),
    );

    return (
        <Stack
            {...extendedProps.__htmlAttributes}
            alignItems={textAlign}
            vertical
            spacing="quart"
            flex="shrinkAndGrow"
        >
            {textRows.map((_, index) => (
                <div
                    key={index}
                    className={getTextRowStyle(
                        index * 100,
                        width ? `${width}` : `${rowWidths[index]}%`,
                    )}
                />
            ))}
        </Stack>
    );
};

TextPlaceholder.displayName = 'TextPlaceholder';
