import { ServiceLocator } from 'app/ioc';
import type {
    Id,
    ICurrentProjectRepository,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import { ScheduleModelService, getParentId } from 'app/core/persistence';
import type { IPiaLocation, IPiaDevice } from 'app/core/pia';
import { isDefined } from 'axis-webtools-util';
import type { BandwidthStorageEstimate } from './models';
import { BandwidthStorageEstimateService } from './services';
import { ProfileOverrideService } from '../profile/services';
import { Frequency } from '../models';
import { isBandwidthDevice } from './utils';

/**
 * returns the bandwidth and storage for all devices in currentProject
 */
export const getEstimate = (
    projectZipSetting: ProjectZipType,
    items: ICurrentProjectRepository['items'],
    schedules: ICurrentProjectRepository['schedules'],
    profiles: ICurrentProjectRepository['profiles'],
    timeSeries: ICurrentProjectRepository['timeSeries'],
    location: IPiaLocation | undefined,
    piaItems: IPiaDevice[],
    projectBandwidthVersion: BandwidthVersion,
    projectRetentionTimeInDays: number,
): Record<Id, BandwidthStorageEstimate> => {
    const estimator = ServiceLocator.get(BandwidthStorageEstimateService);
    const profileOverrideService = ServiceLocator.get(ProfileOverrideService);

    const getTimeSeriesForSchedule = (id: Id) => {
        return Object.values(timeSeries)
            .filter(isDefined)
            .filter((serie) => getParentId(serie) === id);
    };

    const getSchedule = (id: Id) => {
        const rawSchedule = schedules[id];
        const timeSeriesForSchedule = ScheduleModelService.mapToTimeSeriesModels(
            id,
            getTimeSeriesForSchedule(id),
        );

        return rawSchedule
            ? ScheduleModelService.mapToScheduleModel(rawSchedule, timeSeriesForSchedule)
            : undefined;
    };

    const getProfile = (id: Id) => {
        return profiles[id];
    };

    const getMergedProfileFromDevice = (
        item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
    ) => {
        const profile = getProfile(item.associatedProfile);
        return profile
            ? profileOverrideService.getMergedProfileFromDeviceAndProfile(
                  item,
                  profile,
                  projectRetentionTimeInDays,
              )
            : undefined;
    };

    const getPiaItem = (id: number) => {
        return piaItems.find((item) => item.id === id);
    };

    const frequency = location?.frequency60Hz ? Frequency.Hz60 : Frequency.Hz50;

    const bandwidthStorage = Object.values(items)
        .filter(isDefined)
        .filter(isBandwidthDevice)
        .reduce((acc: Record<Id, BandwidthStorageEstimate>, item) => {
            const estimate = estimator.getEstimate(
                projectZipSetting,
                item,
                frequency,
                getPiaItem,
                getSchedule,
                getMergedProfileFromDevice,
                projectBandwidthVersion,
            );

            if (estimate) {
                acc[item._id] = estimate;
            }

            return acc;
        }, {});

    return bandwidthStorage;
};
