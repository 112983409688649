/**
 * Represents a window in the UI.
 * Ie. a modal, dialog, drop down or something
 * else that should be closed when clicked outside of.
 */
interface IWindow {
    /**
     * The React Ref to the element that represents
     * the window (ie. a modal, drop down, dialog etc)
     */
    ref: React.RefObject<HTMLElement>;
    /**
     * Persistent windows will not be closed when clicking
     * outside of them or hitting `ESC`.
     */
    persistent: boolean;
    /**
     * The z-index/depth of the window.
     */
    layer: number;
    /**
     * The parent element, will default to the offsetParent if not set.
     * Useful for explicitly setting a trigger element.
     */
    parent?: React.RefObject<HTMLElement>;
    /**
     * The method to call when closing the window.
     */
    closer(): void;
}

const MAX_DRAG_DELTA = 5; // maximum drag distance detected as a document click
/**
 * A service to manage open windows within the UI.
 * This will keep track of the opened windows and
 * close windows when clicking outside of them or
 * on hitting `ESC`, unless they are set up to be `persistent`.
 */
class WindowService {
    private windows: IWindow[] = [];
    private startX = 0;
    private startY = 0;

    constructor() {
        document.addEventListener('mouseup', this.mouseUp, { capture: true });
        document.addEventListener('mousedown', this.mouseDown, { capture: true });
        document.addEventListener('touchend', this.documentClick, { capture: true });
        document.addEventListener('keyup', this.documentKeyUp, true);
    }

    /**
     * Add a new window to manage.
     */
    public addWindow = (
        ref: IWindow['ref'],
        closer: () => void,
        persistent = false,
        parent: IWindow['parent'],
    ) => {
        this.windows = [
            ...this.windows,
            {
                ref,
                persistent,
                closer,
                layer: this.getTopLayer() + 1,
                parent,
            },
        ];
        return this.windows;
    };

    /**
     * Remove a window from the service
     */
    public removeWindow = (ref: React.RefObject<HTMLElement>) => {
        this.windows = [...this.windows.filter(this.nonMatchingWindow(ref))];
        return this.windows;
    };

    /**
     * Trigger the window's close method
     * unless the window is persistent.
     */
    public closeWindow = (ref: React.RefObject<HTMLElement>) => {
        const theWindow = this.windows.find(this.matchingWindow(ref));
        if (theWindow && !theWindow.persistent) {
            theWindow.closer();
        }
    };

    /**
     * Find the latest opened window
     * and try to close it.
     */
    public closeLatestWindow = () => {
        const theWindow = this.getLatestWindow();
        if (theWindow) {
            this.closeWindow(theWindow.ref);
        }
    };

    /**
     * Try to close all currently opened windows.
     */
    public closeAllWindows = () => {
        this.windows.forEach((theWindow) => this.closeWindow(theWindow.ref));
    };

    /**
     * Get the layer (z-index depth) of the window.
     */
    public getDepth = (ref: React.RefObject<HTMLElement>) => {
        const theWindow = this.windows.find(this.matchingWindow(ref));
        return theWindow ? theWindow.layer : this.windows.length + 3;
    };

    private mouseDown = (event: MouseEvent) => {
        this.startX = event.pageX;
        this.startY = event.pageY;
    };

    private mouseUp = (event: MouseEvent) => {
        const diff = Math.abs(event.pageX - this.startX) + Math.abs(event.pageY - this.startY);

        if (diff < MAX_DRAG_DELTA) {
            // if the dragged distance is less than delta we consider it a document click
            this.documentClick(event);
        }
    };

    /**
     * Unless the click is made on the latest opened
     * window (or an element inside of it) try
     * to close the window.
     */
    private documentClick = (event: MouseEvent | TouchEvent) => {
        if (this.windows.length === 0) {
            return;
        }

        const theWindow = this.getLatestWindow();

        if (!theWindow) {
            return;
        }

        const windowEl = theWindow.ref.current;

        const parent = theWindow.parent ? theWindow.parent.current : false;

        const target = event.target instanceof HTMLElement ? event.target : false;

        if (!target) {
            return;
        }

        if (parent && parent.contains(target)) {
            return;
        }

        if (windowEl && !windowEl.contains(target)) {
            this.closeWindow(theWindow.ref);
        }
    };

    /**
     * Try to close the latest opened window on `ESC`
     */
    private documentKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Escape' || event.key === 'Esc') {
            this.closeLatestWindow();
        }
    };

    /**
     * Find the highest layer (z-index) amongst the windows
     */
    private getTopLayer = () =>
        this.windows.reduce(
            (highestLayer, window) => (window.layer > highestLayer ? window.layer : highestLayer),
            3,
        );

    /**
     * Get the latest opened window
     */
    private getLatestWindow = () => this.windows[this.windows.length - 1];

    private matchingWindow = (ref: React.RefObject<HTMLElement>) => (theWindow: IWindow) =>
        ref === theWindow.ref;

    private nonMatchingWindow = (ref: React.RefObject<HTMLElement>) => (theWindow: IWindow) =>
        ref !== theWindow.ref;
}
export const windowService = new WindowService();
