import type { IPiaApplication, PiaId } from 'app/core/pia';
import { toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { getCompatibleAxisApplications } from './applicationSelectors';
import { getPersistedApplications } from './getPersistedApplications';

/**
 * Checks if an acap has both paper and electronic license versions
 * @param productId Unique product id
 * @param acapId Id that refer to paper and/or electronic version(s) of the same acap
 * @returns boolean that describes if acap has multiple versions or not
 */
const hasMultipleVersions = (
    compatibleApplications: IPiaApplication[],
    productId: PiaId | null,
    acapId?: number,
): boolean => {
    return compatibleApplications.some(
        (app) => app.properties.ACAPId === acapId && app.id !== productId,
    );
};

/**
 * Checks if device has any selected application with multiple versions.
 * Returns true if it's eLicense, false if it's paper license and undefined if device has no multi version apps.
 */
export const getSelectedMultiAppsELicensePreferred = createCachedSelector(
    [getCompatibleAxisApplications, getPersistedApplications],
    (compatibleApplications, selectedApplications) => {
        const multiVersionApp = selectedApplications.find((app) =>
            hasMultipleVersions(compatibleApplications, app.productId, app.acapId),
        );

        return multiVersionApp?.isELicense;
    },
)(toCacheKey);
