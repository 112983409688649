import * as React from 'react';
import { t } from 'app/translate';
import { Box, SelectableList, Stack, Text } from 'app/components';
import type { Id } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';

import type { PiaId } from 'app/core/pia';

import { getSelectedCardReadersForIdAndDoorSide } from '../selectors';
import type { ISelectedMountAccessory } from 'app/modules/accessorySelector';
import { AccessoriesListItem } from 'app/modules/accessorySelector/components/accessoriesList/AccessoriesListItem.container';

interface IDoorAccessoryListProps {
    doorItemId: Id;
    isSideA: boolean;
}

export const DoorAccessoryList: React.FC<IDoorAccessoryListProps> = ({ doorItemId, isSideA }) => {
    const selectedCardReadersForDoorSide = useSelector<
        IStoreState,
        Record<PiaId, ISelectedMountAccessory> | undefined
    >((state) =>
        getSelectedCardReadersForIdAndDoorSide(
            state,
            doorItemId,
            isSideA ? 'doorSideA' : 'doorSideB',
        ),
    );

    const mapSelectedCardReaders =
        (selectedCardReadersForDoorSide &&
            Object.values(selectedCardReadersForDoorSide).filter(
                (cardReader) => cardReader.productId > 0,
            )) ||
        [];

    const hasAccessories =
        mapSelectedCardReaders && Object.values(mapSelectedCardReaders).length > 0;

    return (
        <Box padding="half" width="100%">
            <Stack vertical>
                <SelectableList
                    notStickyHeader
                    noBackground
                    noHeaderBackground
                    notScrollable
                    header={[
                        <Text color="grey6" whiteSpace="nowrap">
                            {t.name}
                        </Text>,
                        '',
                        hasAccessories ? (
                            <Text color="grey6" whiteSpace="nowrap">
                                {t.quantity}
                            </Text>
                        ) : (
                            ''
                        ),
                        '',
                        '',
                    ]}
                    emptyIcon="category"
                    emptyText={t.noProductSelected}
                    verticallyCenterContents
                >
                    {mapSelectedCardReaders.map((cardReader) => (
                        <AccessoriesListItem
                            key={cardReader.id}
                            itemId={cardReader.id}
                            hideParent
                            isChildAccessory
                            parentQuantity={1}
                            showAccessoryButton
                            maxQuantity={1}
                        />
                    )) ?? []}
                </SelectableList>
            </Stack>
        </Box>
    );
};

DoorAccessoryList.displayName = 'DoorAccessoryList';
