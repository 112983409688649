import { differenceWith, isEqual } from 'lodash-es';
import { createSelector } from 'reselect';
import { getRecommendations } from '../recommendations';
import { getRecordingSolutionRequirements } from '../getRecordingSolutionRequirements';
import { getRecordingSolutionTotalSpecs } from '../getRecordingSolutionSpecs';
import {
    getIsSDCardCompanionSystem,
    isPureCompanionSystem,
    getCurrentSolutionWithAdditionalStorage,
} from '../getRecordingSolution';
import { getRecorderProps, getSwitchProps, type IRecordingSolutionItem } from '../common';
import { bySize } from '../comparators';

/**
 * Comparator for sorting a recording solution items by size in ascending order
 */
const byItemSize = (a: IRecordingSolutionItem, b: IRecordingSolutionItem) =>
    !a.piaItem || !b.piaItem ? 0 : bySize(a.piaItem, b.piaItem);

/**
 * Get the storage of a recording solution item
 */
const getStorage = (item: IRecordingSolutionItem) =>
    item.piaItem ? getRecorderProps(item.piaItem).maxRecordingStorageMegaBytes : 0;

/**
 * Get the total storage of a recording solution item, including all accessories
 */
const getTotalStorage = (item: IRecordingSolutionItem): number =>
    getStorage(item) +
    (item.accessories ?? []).reduce(
        (acc, accessory) => acc + accessory.quantity * getStorage(accessory),
        0,
    );

/**
 * Get the number of free HDD bays in the recorder
 */
const getFreeHddBays = (item: IRecordingSolutionItem): number => {
    const freeBays = item.piaItem ? getRecorderProps(item.piaItem).freeHddBays : 0;

    const usedBays = (item.accessories ?? []).reduce(
        (acc, accessory) => acc + (getStorage(accessory) > 0 ? accessory.quantity : 0),
        0,
    );

    return freeBays - usedBays;
};

/**
 * Add the total storage from all external disks to the properties of the recorder
 * Used for comparing two recording solution items
 */
const accumulateStorage = (item: IRecordingSolutionItem): IRecordingSolutionItem => ({
    ...item,
    piaItem: item.piaItem && {
        ...item.piaItem,
        properties: {
            ...item.piaItem.properties,
            maxRecordingStorageMegaBytes: getTotalStorage(item),
            freeHddBays: getFreeHddBays(item),
        },
    },
    accessories: [],
});

/**
 * Get the properties of a recording solution item as a map mapping property names
 * to values. Used for comparing two recording solution items.
 */
const getProps = (item: IRecordingSolutionItem): Record<string, number> => {
    if (!item.piaItem) {
        return {};
    }

    const recorderProps = getRecorderProps(item.piaItem);
    const switchProps = getSwitchProps(item.piaItem);

    return {
        ...recorderProps,
        ...switchProps,
    };
};

/**
 * Check whether a recording solution item is larger or equal to another, i.e. has
 * more capacity in ALL dimensions
 */
const isLargerOrEqual = (a: IRecordingSolutionItem, b: IRecordingSolutionItem) => {
    const aProps = getProps(a);
    const bProps = getProps(b);

    // We don't want to compare freeHddBays as it is not a dimension of the system,
    // but just a potential of expanding the system
    delete aProps.freeHddBays;
    delete bProps.freeHddBays;

    return Object.entries(bProps).every(([key, value]) => {
        return aProps[key] <= value;
    });
};

/**
 * Check if two recording solution items are equal
 */
const areItemsEqual = (a: IRecordingSolutionItem, b: IRecordingSolutionItem) =>
    a.piaId === b.piaId &&
    a.quantity == b.quantity &&
    isEqual(a.piaItem?.properties, b.piaItem?.properties);

/**
 * Check if a recording solution is a superset of another. A is a superset of B if
 * A is larger or equal to B in all dimensions.
 */
export const isSystemSuperset = (
    systemA: IRecordingSolutionItem[],
    systemB: IRecordingSolutionItem[],
) => {
    // add any additional disk storage to the items
    const systemAWithTotalStorage = systemA.map(accumulateStorage);
    const systemBWithTotalStorage = systemB.map(accumulateStorage);

    // remove items that are identical in both lists so that we only compare the
    // remaining items
    // Example:
    //   a = [1,2,3]
    //   b = [2,3,4]
    // Result:
    //   a = [1]
    //   b = [4]
    const remainingA = differenceWith(
        systemAWithTotalStorage,
        systemBWithTotalStorage,
        areItemsEqual,
    ).sort(byItemSize);

    const remainingB = differenceWith(
        systemBWithTotalStorage,
        systemAWithTotalStorage,
        areItemsEqual,
    ).sort(byItemSize);

    // check if remainingB is a superset of remainingA
    return (
        differenceWith(
            remainingA,
            remainingB,
            (a, b) => a.quantity <= b.quantity && isLargerOrEqual(a, b),
        ).length === 0
    );
};

/*
 * Check whether the current recording solution is equal to or a superset of one of
 * the recording solution recommendations.
 */
export const isSystemARecommendationSuperset = createSelector(
    [getRecommendations, getCurrentSolutionWithAdditionalStorage],
    (recommendations, currentSelection) =>
        currentSelection.length > 0 &&
        recommendations.some((recommendation) => {
            return isSystemSuperset(recommendation.items, currentSelection);
        }),
);

export const areBasicRequirementsFulfilled = createSelector(
    [
        getRecordingSolutionRequirements,
        getRecordingSolutionTotalSpecs,
        isPureCompanionSystem,
        getIsSDCardCompanionSystem,
    ],
    (requirements, specs, pureCompanionSystem, isAnSDCardCompanionSystem) =>
        (isAnSDCardCompanionSystem &&
            specs.power >= requirements.power &&
            specs.ports.totalPorts >= requirements.ports.totalPorts) ||
        (specs.nrOfChannels >= requirements.nrOfChannels &&
            (pureCompanionSystem || specs.nrOfLicenses >= requirements.nrOfLicenses) &&
            specs.storage >= requirements.storage &&
            specs.bandwidth >= requirements.bandwidth &&
            specs.power >= requirements.power &&
            specs.ports.totalPorts >= requirements.ports.totalPorts),
);
