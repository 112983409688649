export type callback = (...args: any[]) => any;

export function debounce(callbackFunction: callback, waitTime = 500): callback {
    let timer: number;

    function debouncer(this: any, ...args: any[]) {
        const executeCallback = () => callbackFunction.apply(this, args);

        clearTimeout(timer);
        timer = window.setTimeout(executeCallback, waitTime);
    }

    return debouncer;
}
