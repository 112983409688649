import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import type {
    Id,
    ICurrentProjectRepository,
    IItemEntity,
    IPersistence,
} from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import { format, isDefined } from 'axis-webtools-util';
import type {
    BandwidthStorageEstimate,
    IBandwidthStorageMainUnitEstimateModel,
    IBandwidthStorageEncoderEstimateModel,
} from '../models';
import { getEstimate } from '../getEstimate';
import {
    getCurrentProjectProjectZipSetting,
    getCurrentProjectItemRelations,
    getCurrentProjectItems,
    getCurrentProjectLocation,
    getCurrentProjectProfiles,
    getCurrentProjectSchedules,
    getCurrentProjectTimeSeries,
    getCurrentProjectBandwidthVersion,
    getCurrentProjectItem,
    getCurrentProjectRetentionTime,
} from '../../project';
import { getAllPiaDevices } from '../../piaDevices';
import { getIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';
import { getDeviceVirtualChildren } from '../../devices/selectors/getDeviceChildren';

/**
 * Selector for getting the bandwidth and storage for all devices in currentProject
 */
const getBandwidthStorage = createSelector(
    [
        getCurrentProjectProjectZipSetting,
        getCurrentProjectItems,
        getCurrentProjectSchedules,
        getCurrentProjectProfiles,
        getCurrentProjectTimeSeries,
        getCurrentProjectLocation,
        getAllPiaDevices,
        getCurrentProjectBandwidthVersion,
        // Needed to make sure we re-calculate when retention time changes
        getCurrentProjectRetentionTime,
        // Needed to make sure we re-calculate when a new relation is added
        getCurrentProjectItemRelations,
    ],
    getEstimate,
);

const isEncoderEstimate = (
    estimate: BandwidthStorageEstimate,
): estimate is IBandwidthStorageEncoderEstimateModel => estimate.type === 'encoder';

const isMainUnitEstimate = (
    estimate: BandwidthStorageEstimate,
): estimate is IBandwidthStorageMainUnitEstimateModel => estimate.type === 'mainUnit';

/**
 * Selector for getting the bandwidth and storage for all devices, including analog
 * cameras and sensor units.
 */
export const getBandwidthStorageEstimateForItems = createSelector(
    [getBandwidthStorage, getCurrentProjectItemRelations],
    (bandwidthStorage, itemRelations: ICurrentProjectRepository['itemRelations']) => {
        return Object.keys(bandwidthStorage)
            .filter(isDefined)
            .reduce((acc: Record<Id, BandwidthStorageEstimate>, id) => {
                const estimate = bandwidthStorage[id];
                acc[id] = estimate;
                if (isMainUnitEstimate(estimate) || isEncoderEstimate(estimate)) {
                    Object.values(itemRelations)
                        .filter(isDefined)
                        .filter(
                            ({ parentId, relationType }) =>
                                (relationType === 'sensorUnit' ||
                                    relationType === 'analogCamera') &&
                                parentId === id,
                        )
                        .forEach(({ childId }, i) => {
                            acc[childId] = {
                                type: 'camera',
                                ...estimate.perAttachedCamera[i],
                            };
                        });
                }
                return acc;
            }, {});
    },
);

/**
 * Function for getting the bandwidth and storage of a specific device
 */
export const getBandwidthStorageEstimateForItem = createCachedSelector(
    [getBandwidthStorageEstimateForItems, getIdFromProps],
    (bandwidthStorageEstimateForItems, itemId) => {
        return itemId ? bandwidthStorageEstimateForItems[itemId] : undefined;
    },
)(toCacheKey);

/**
 * Calculate total bandwidth and storage
 */
const calculateTotals = (devices: BandwidthStorageEstimate[]) => {
    const storage = devices.reduce((sum, device) => sum + device.total.storageInMB, 0);
    const bandwidth = devices.reduce(
        (sum, device) => sum + device.total.recordingBandwidth + device.total.liveViewBandwidth,
        0,
    );
    return {
        storage,
        bandwidth,
        formattedStorage: format.storage(storage),
        formattedBandwidth: format.bandwidth(bandwidth),
    };
};

/**
 * Calculate recording bandwidth and storage
 */
const calculateRecording = (devices: BandwidthStorageEstimate[]) => {
    const storage = devices.reduce((sum, device) => sum + device.total.storageInMB, 0);
    const bandwidth = devices.reduce((sum, device) => sum + device.total.recordingBandwidth, 0);
    return {
        storage,
        bandwidth,
        formattedStorage: format.storage(storage),
        formattedBandwidth: format.bandwidth(bandwidth),
    };
};

/** Gets bandwidth for a device and its virtual products for device list and bandwidth report.
 *  We don't display bandwidth for encoders and main units there, but we do for analog cameras and sensor units.
 *  This is the reverse from how we present it in system proposal report. Bodyworn camera has storage but not bandwidth
 */
export const getDeviceBandwidthTotal = createCachedSelector(
    [getBandwidthStorageEstimateForItems, getCurrentProjectItem, getDeviceVirtualChildren],
    (bandwidthStorageEstimates, item, virtualChildren) => {
        const showBandwidth =
            item &&
            (deviceTypeCheckers.isCamera(item) ||
                deviceTypeCheckers.isSensorUnit(item) ||
                deviceTypeCheckers.isAnalogCamera(item));
        if (!showBandwidth) {
            return undefined;
        }

        return calculateTotalsForDevice(item._id, virtualChildren, bandwidthStorageEstimates);
    },
)(toCacheKey);

/** Gets storage for a device and its virtual products for device list and bandwidth report.
 *  We don't display bandwidth for encoders and main units there, but we do for analog cameras and sensor units.
 *  This is the reverse from how we present it in system proposal report. Bodyworn camera has storage but not bandwidth
 */
export const getDeviceStorageTotal = createCachedSelector(
    [getBandwidthStorageEstimateForItems, getCurrentProjectItem, getDeviceVirtualChildren],
    (bandwidthStorageEstimates, item, virtualChildren) => {
        const showBandwidth =
            item &&
            (deviceTypeCheckers.isCamera(item) ||
                deviceTypeCheckers.isSensorUnit(item) ||
                deviceTypeCheckers.isAnalogCamera(item) ||
                deviceTypeCheckers.isBodyWornCamera(item));
        if (!showBandwidth) {
            return undefined;
        }

        return calculateTotalsForDevice(item._id, virtualChildren, bandwidthStorageEstimates);
    },
)(toCacheKey);

/** Sums up estimates for a device and its virtual children */
export const calculateTotalsForDevice = (
    itemId: Id,
    virtualChildren: IPersistence<IItemEntity>[],
    bandwidthStorageEstimateForItems: Record<string, BandwidthStorageEstimate>,
) => {
    const itemIds: Id[] = [itemId, ...virtualChildren.map((virtualChild) => virtualChild._id)];
    const estimates: BandwidthStorageEstimate[] = itemIds
        .map((id) => bandwidthStorageEstimateForItems[id])
        .filter(isDefined);
    return calculateTotals(estimates);
};

/**
 * Selector for getting the total bandwidth and storage for current project
 */
export const getBandwidthStorageEstimate = createSelector(
    [getBandwidthStorage],
    (bandwidthStorage) => {
        return calculateTotals(Object.values(bandwidthStorage));
    },
);

/**
 * Selector for getting the total recording bandwidth and storage for current project
 */
export const getRecordingBandwidthStorageEstimate = createSelector(
    [getBandwidthStorage],
    (bandwidthStorage) => {
        return calculateRecording(Object.values(bandwidthStorage));
    },
);
