import { injectable } from 'inversify';
import { t } from 'app/translate';
import type { Id } from 'app/core/persistence';
import {
    ItemService,
    CurrentProjectService,
    getDefaultProfileOverrideEntity,
} from 'app/core/persistence';
import { PiaId } from 'app/core/pia';

@injectable()
export class ChildItemService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async addAnalogCamera(encoderId: Id, quantity = 1) {
        const analogCameraItem = await this.itemService.addByParentId(encoderId, {
            productId: null,
            name: t.encoderSelectorGenericAnalogCameraName,
            description: '',
            notes: '',
            quantity: quantity,
            properties: {
                analogCamera: {
                    associatedProfile: this.currentProjectService.getProjectEntity().defaultProfile,
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
        });

        await this.itemService.addItemRelation(encoderId, analogCameraItem._id, 'analogCamera');
        return analogCameraItem;
    }

    public async addDoor(doorControllerId: Id) {
        const door = await this.itemService.addByParentId(doorControllerId, {
            name: t.devicesGROUP['door'],
            quantity: 1,
            description: '',
            notes: '',
            productId: null,
            properties: {
                door: {
                    nbrOfLocks: 1,
                },
            },
        });

        await this.itemService.addItemRelation(doorControllerId, door._id, 'door');
        return door;
    }

    public async addRelayExpansionModule(ioRelayId: Id, productId: PiaId | null | undefined) {
        if (!productId) throw new Error('Relay expansion modules must have a product id');
        const expansion = await this.itemService.addByParentId(ioRelayId, {
            name: t.newExpansionModule,
            quantity: 1,
            description: '',
            notes: '',
            productId: productId,
            properties: {
                pac: { filter: { outdoor: false } },
            },
        });

        await this.itemService.addItemRelation(ioRelayId, expansion._id, 'iorelays');
        return expansion;
    }
}
