import * as React from 'react';
import { t } from 'app/translate';
import type { IMsrpProps, IProduct } from 'app/modules/common';
import { Subscription } from 'app/modules/common';
import type { IAutoTestable } from 'app/components';
import {
    NumberInput,
    Icon,
    Currency,
    Badge,
    Clickable,
    Box,
    Stack,
    toTestIdFormat,
} from 'app/components';
import { MsrpCurrencyStyle, RightAlignedStyle } from './QuotationStyle';

export interface IQuotationEditSubsectionProps extends IAutoTestable {
    header: string;
    products: IProduct[];
    msrp?: IMsrpProps;
    currency: string;
    locale: string;
    change(quotePrice: number, product: IProduct): void;
}

export class QuotationEditSubsection extends React.Component<IQuotationEditSubsectionProps> {
    public render() {
        const { products } = this.props;

        if (products.length === 0) {
            return null;
        }

        const tableRows = products.map((product: IProduct) => (
            <tr key={product.id} data-discontinued={product.discontinued}>
                <th scope="row">
                    <Box spacing="base" width="100%" justifyContent="between" alignItems="center">
                        <div data-test-id={`div_name_${toTestIdFormat(product.name)}`}>
                            {product.name}
                        </div>
                        {product.discontinued && (
                            <Badge compact type="red" label={t.quotationDiscontinued} />
                        )}
                        {!product.discontinued && (
                            <Subscription
                                subscriptionIntervalInMonths={product.subscriptionIntervalInMonths}
                            />
                        )}
                    </Box>
                </th>
                <td data-test-id={`part_number_${toTestIdFormat(product.name)}`}>
                    {product.partNumber || t.quotationNotAvailable}
                </td>
                {this.msrpColumn(product)}
                <td>
                    <NumberInput
                        color="grey2"
                        hideSpinner
                        noBorder
                        noHeight
                        optional
                        rightAlign
                        value={product.quotePrice}
                        onChange={this.onChange(product)}
                        decimals={2}
                        width="80px"
                        changeCriteria="blur"
                        disabled={product.msrp?.price === 0}
                    />
                </td>
                <td
                    data-test-id={`td_quantity_${toTestIdFormat(product.name)}`}
                    className={RightAlignedStyle}
                >
                    {product.quantity}
                </td>
                <td>
                    <Currency
                        value={this.calculateTotal(product.quantity, product.quotePrice)}
                        currency={this.props.currency}
                        locale={this.props.locale}
                    />
                </td>
            </tr>
        ));

        return (
            <tbody>
                <tr>
                    <th colSpan={6} scope="colgroup">
                        {this.props.header}
                    </th>
                </tr>

                {tableRows}
            </tbody>
        );
    }

    private msrpColumn = (product: IProduct) => {
        if (
            !product.msrp ||
            !product.msrp.price ||
            !this.props.msrp ||
            product.msrp.price === undefined
        ) {
            return <td />;
        }

        const { locale, currency } = this.props;

        const msrp = (
            <Currency
                value={product.msrp.price}
                locale={locale}
                currency={this.props.msrp.msrpCurrency}
                showCurrency
                hideFractions
            />
        );

        const msrpCurrencyIsMatching =
            currency.toUpperCase() === this.props.msrp.msrpCurrency.toUpperCase();

        const msrpInLocalCurrency = (
            <Currency
                value={product.msrp!.priceList}
                locale={locale}
                currency={currency}
                showCurrency
                hideFractions
            />
        );

        const msrpInLocalCurrencyExists = this.props.msrp.msrpAvailableInLocalCurrency;

        const renderWithLocalCurrency = (
            <td className={RightAlignedStyle}>
                <Clickable onClick={this.useMsrpPrice(product)}>
                    <Box justifyContent="end">
                        <Stack spacing="half">
                            {msrpInLocalCurrency}
                            {!msrpCurrencyIsMatching && (
                                <div className={MsrpCurrencyStyle}>({msrp})</div>
                            )}
                            <Icon icon="keyboard_arrow_right" size="sm" />
                        </Stack>
                    </Box>
                </Clickable>
            </td>
        );

        const renderWithoutLocalCurrency = <td>{msrp}</td>;

        return msrpInLocalCurrencyExists ? renderWithLocalCurrency : renderWithoutLocalCurrency;
    };

    private onChange = (product: IProduct) => (value: number) => {
        this.props.change(value, product);
    };

    private useMsrpPrice = (product: IProduct) => () => {
        if (product.msrp !== undefined && product.msrp.price !== undefined) {
            this.props.change(product.msrp.priceList || product.msrp.price, product);
        }
    };

    private calculateTotal = (amount: number, price: number | undefined) => {
        return price === undefined ? null : amount * price;
    };
}
