import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';
import { css } from '@emotion/css';

// See https://bugzilla.mozilla.org/show_bug.cgi?id=939897
const firefoxPrintHack = css`
    @media print {
        display: block !important;
        background-color: white;
        margin: 0 !important;
        padding: 0 !important;
        min-width: 0 !important;
    }
`;

/**
 * Print something as `block`
 *
 * This is provided as a way to print components as block elements
 * in order to work around a bug in Firefox:
 * (https://bugzilla.mozilla.org/show_bug.cgi?id=939897)
 */
export class PrintFix extends React.Component<IExtendableComponentWithChildren> {
    public render() {
        const attributes = extendableProps(this.props, {
            className: firefoxPrintHack,
        });

        return renderReactChildren(
            this.props.children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes.__htmlAttributes),
            (child) => <div className={firefoxPrintHack}>{child}</div>,
        );
    }
}
