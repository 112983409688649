import { injectable } from 'inversify';
import type { PiaId } from 'app/core/pia';
import type { IDescriptionText, IBullet, ILogoUrl } from './DeviceDescriptionModels';
import { DeviceDescriptionCommunicator } from './DeviceDescription.communicator';
import { LanguageService } from '../Language.service';

/**
 * Provides translated descriptive device summaries.
 */
@injectable()
export class DeviceDescriptionService {
    constructor(
        private communicator: DeviceDescriptionCommunicator,
        private languageService: LanguageService,
    ) {}
    private descriptionMemoryCache: Record<PiaId, Promise<IDescriptionText>> = {};
    private bulletMemoryCache: Record<PiaId, Promise<IBullet>> = {};
    private logoUrlCache: Record<PiaId, Promise<ILogoUrl>> = {};

    /** Gets shortest available description for a product.
     * @param piaId Id for product.
     * @return Description for product.
     *  */
    public getShortDescription(piaId: PiaId): Promise<string | undefined> {
        return this.getDescription(piaId, false);
    }
    public getLongDescription(piaId: PiaId, isPartnerProduct: boolean) {
        const useLong = isPartnerProduct;
        // Only partner products have long descriptions.
        return this.getDescription(piaId, useLong);
    }

    /** Gets description from TXT API for a specific product or vendor.
     * @param piaId Pia Id for product/vendor.
     * @param long Fetch longDescriptiveCopy instead of descriptiveCopy. Partner products have both longDescriptiveCopy and descriptiveCopy. Axis products only have descriptiveCopy.
     */
    private async getDescription(piaId: PiaId, long?: boolean): Promise<string | undefined> {
        const languageCode = this.languageService.getLanguageCode();

        // See if the description is already cached
        const cachedDescriptionPromise = this.descriptionMemoryCache[piaId];
        if (cachedDescriptionPromise) {
            const cachedDescription = await cachedDescriptionPromise;
            if (
                cachedDescription.description &&
                cachedDescription.languageCode === languageCode &&
                cachedDescription.long === long
            ) {
                return cachedDescription.description;
            }
        }

        const fetchDescriptionPromise = this.communicator.fetchDescription(
            piaId,
            languageCode,
            long,
        );
        this.descriptionMemoryCache[piaId] = fetchDescriptionPromise;

        return (await fetchDescriptionPromise).description;
    }

    /** Gets bullet points from TXT API for a specific product.
     * @param piaId Pia Id for product.
     */
    public async getBulletPoints(piaId: PiaId): Promise<string[]> {
        const languageCode = this.languageService.getLanguageCode();

        const cachedBulletPromise = this.bulletMemoryCache[piaId];
        if (cachedBulletPromise) {
            const cachedBullet = await cachedBulletPromise;
            if (cachedBullet.bullets && cachedBullet.languageCode === languageCode) {
                return cachedBullet.bullets;
            }
        }

        const fetchBulletsPromise = this.communicator.fetchBulletPoints(piaId, languageCode);
        this.bulletMemoryCache[piaId] = fetchBulletsPromise;

        return (await fetchBulletsPromise)?.bullets ?? [];
    }

    /** Fetches logo URL for a specific partner.
     * @param piaId Pia Id for partner.
     */
    public async getPartnerLogo(piaId: PiaId | undefined): Promise<string> {
        if (piaId === undefined) return '';
        const languageCode = 'en-US';
        const cachedLogoUrlPromise = this.logoUrlCache[piaId];
        if (cachedLogoUrlPromise) {
            const cachedUrl = await cachedLogoUrlPromise;
            if (cachedUrl.logoUrl) {
                return cachedUrl.logoUrl;
            }
        }
        const fetchLogoUrlPromise = this.communicator.fetchPartnerLogo(piaId, languageCode);
        this.logoUrlCache[piaId] = fetchLogoUrlPromise;

        return (await fetchLogoUrlPromise).logoUrl ?? '';
    }
}
