import { createSelector } from 'reselect';
import { uniqBy } from 'lodash-es';
import type { Id } from 'app/core/persistence';
import { getInstallationPointsPerFloorPlan } from './floorPlanMapping';
import { nameComparator } from 'app/utils';

const LEGEND_IDS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

// Get the legend id for a decimal value. The legend ids are generated the same way
// as spreadsheet columns are i.e.  A,B,C...X,Y,Z,AA,AB,AC...AX,AY,AZ,BA...ZZ,AAA,AAB...
const decToId = (value: number): string => {
    const base = LEGEND_IDS.length;
    const rest = Math.floor(value / base);
    const lastChar = LEGEND_IDS[value % base];

    return (rest === 0 ? '' : decToId(rest - 1)) + lastChar;
};

export const createGetMapLegendId = createSelector(
    [getInstallationPointsPerFloorPlan],
    (installationPoints) => {
        const idMap = Object.keys(installationPoints).reduce(
            (groupedByFloorPlan, floorPlanId) => {
                const devices = uniqBy(
                    installationPoints[floorPlanId].map((ip) => ip.parentDevice),
                    '_id',
                )
                    .filter(
                        ({ productId, properties }) =>
                            productId ||
                            properties.analogCamera ||
                            properties.camera?.customCameraProperties?.activated,
                    )
                    .sort(nameComparator);

                groupedByFloorPlan[floorPlanId] = devices.reduce(
                    (acc, device, i) => {
                        acc[device._id] = decToId(i);
                        return acc;
                    },
                    {} as Record<Id, string>,
                );
                return groupedByFloorPlan;
            },
            {} as Record<Id, Record<Id, string>>,
        );

        return (floorPlanId: Id, deviceId: Id) => idMap[floorPlanId][deviceId] ?? '';
    },
);
