import { Module } from 'app/ioc';
import { projectDevicesReducer } from './ProjectDevicesReducer';
import { ProjectDevicesActionService, ProjectDevicesService } from './services';
import { CustomCameraSettingsService } from './components';

@Module({
    binder: (binder) => {
        binder.bindService(ProjectDevicesActionService);
        binder.bindService(ProjectDevicesService);
        binder.bindService(CustomCameraSettingsService);
    },
    reducers: {
        projectDevices: projectDevicesReducer,
    },
})
export class ProjectDevicesModule {}
