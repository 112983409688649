import { createSelector } from 'reselect';
import { UnreachableCaseError } from 'axis-webtools-util';
import type { IUserProjectsListItem, ProjectsSortOrder } from '../models';
import {
    creationDateComparator,
    creationDateReverseComparator,
    nameComparator,
    nameComparatorReverse,
    updatedDateComparator,
    updatedDateComparatorReverse,
} from 'app/utils';
import type { IStoreState } from 'app/store';

const getSorting = (state: IStoreState) => state.userProjects.sortOrder;
export const getCurrentView = (state: IStoreState) => state.userProjects.currentView;

export const getUserProjectNameFilter = (state: IStoreState) =>
    state.userProjects.userProjectsFilter;

export const getUserProjects = (state: IStoreState) => state.userProjects.userProjects;

export const getDeepProjectData = (state: IStoreState) => state.userProjects.deepProjectData;

export const getIsReplicating = (state: IStoreState) => state.userProjects.replicating;

export const getUserProjectsLoaded = (state: IStoreState) =>
    state.userProjects.loaded && state.userProjects.error === null;

export const getShowArchivedProjects = (state: IStoreState) =>
    state.userProjects.showArchivedProjects;

export const getVisibleUserProjects = createSelector(
    [
        getShowArchivedProjects,
        getUserProjectNameFilter,
        getUserProjects,
        getSorting,
        getDeepProjectData,
    ],
    (showArchivedProjects, userProjectNameFilter, userProjects, sortOrder, deepProjectData) => {
        return userProjects
            .map((project) => {
                const deepData = deepProjectData[project.id];
                if (deepData) {
                    // merge in deep project data
                    return {
                        ...project,
                        devicesQuantity: deepData.devicesQuantity,
                        hasFloorPlanOrLocation: deepData.hasFloorPlanOrLocation,
                        updatedDate: deepData.updatedDate,
                        locked: deepData.locked,
                    };
                }

                return project;
            })
            .filter((userProject) => {
                if (!showArchivedProjects && userProject.archived) {
                    return false;
                }

                return userProject.name.toLowerCase().includes(userProjectNameFilter.toLowerCase());
            })
            .sort(getSortFunction(sortOrder));
    },
);

function getSortFunction(sortProperty: ProjectsSortOrder) {
    switch (sortProperty.sort) {
        case 'none':
            return (_a: IUserProjectsListItem, _b: IUserProjectsListItem) => 0;
        case 'name':
            if (sortProperty.direction === 'ascending') return nameComparator;
            else return nameComparatorReverse;
        case 'updated':
            if (sortProperty.direction === 'ascending') return updatedDateComparator;
            else return updatedDateComparatorReverse;

        case 'created':
            if (sortProperty.direction === 'ascending') return creationDateComparator;
            else return creationDateReverseComparator;
        case 'devices':
            if (sortProperty.direction === 'ascending')
                return (a: IUserProjectsListItem, b: IUserProjectsListItem) =>
                    b.devicesQuantity - a.devicesQuantity;
            else
                return (a: IUserProjectsListItem, b: IUserProjectsListItem) =>
                    a.devicesQuantity - b.devicesQuantity;
        default:
            throw new UnreachableCaseError(sortProperty.sort);
    }
}
