import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { deviceTypeCheckers } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';

export const getProfileOverride = createCachedSelector([getCurrentProjectItem], (item) => {
    if (!item) {
        return undefined;
    }

    if (deviceTypeCheckers.isAnalogCamera(item)) {
        return item.properties.analogCamera.profileOverride;
    }

    if (deviceTypeCheckers.isDoorStation(item) || deviceTypeCheckers.isCamera(item)) {
        return item.properties.camera.profileOverride;
    }

    if (deviceTypeCheckers.isSensorUnit(item)) {
        return item.properties.sensorUnit.profileOverride;
    }

    if (deviceTypeCheckers.isVirtualProduct(item)) {
        return item.properties.virtualProduct.profileOverride;
    }

    return undefined;
})(toCacheKey);
