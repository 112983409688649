import { t } from 'app/translate';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FilterPanel } from './FilterPanel';
import {
    Action,
    DropDown,
    DropDownMenuButton,
    Grid,
    PillButton,
    Stack,
    Text,
} from 'app/components';
import type { IStoreState } from 'app/store';
import { defaultEncoderFilter } from '../../models';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { isEqual } from 'lodash-es';
import { SearchField } from './common';
import { SustainabilityFilter } from './SustainabilityFilter';
import type { IEncoderFilter } from 'app/modules/common';

export const EncoderFilterPanel: React.FC = () => {
    type checkBoxType = 'blade' | 'twoWayAudio';

    const [deviceSelectorActionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const encoderFilter = useSelector<IStoreState, IEncoderFilter>(
        (store) => store.deviceSelector.encoderFilter,
    );

    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);

    const { blade, channels, twoWayAudio } = encoderFilter;

    const channelCountOptions = [1, 4, 6, 16];

    const channelButtonText = t.channels + (channels ? `: ${channels}` : '');

    /**
     * Updates channel count in encoder filter. Selecting the current option will deactivate the filter
     * @param {number} channelCountOption - The amount of channels selected in the encoder filter panel
     */
    const updateChannelCount = (channelCountOption: number) => {
        const clickedCurrentOption = channels === channelCountOption;
        deviceSelectorActionService.updateEncoderFilter({
            channels: clickedCurrentOption ? undefined : channelCountOption,
        });
    };

    /**
     * Updates redux state for checkbox filters
     * @param filterType - The checkbox interacted with
     */
    const updateFilterCheckbox = (filterType: checkBoxType) => {
        deviceSelectorActionService.updateEncoderFilter({
            [filterType]: !encoderFilter[filterType],
        });
    };

    /**
     * Resets encoder filter to its default values.
     * Also sets includeDiscontinued to false and clears search text.
     */
    const resetFilter = () => {
        if (searchText) {
            deviceSelectorActionService.setSearchText('');
        }
        if (!isEqual(encoderFilter, defaultEncoderFilter)) {
            deviceSelectorActionService.updateEncoderFilter(defaultEncoderFilter);
        }
    };

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                <Stack alignItems="center" justifyContent="between">
                    <Text style="semibold" color="grey6">
                        {t.cameraSelectorRequirementsTitle}
                    </Text>
                    <Action title={t.reset} onClick={resetFilter} />
                </Stack>
                <Grid spacing="halfQuart">
                    <DropDown
                        trigger={
                            <PillButton
                                text={channelButtonText}
                                icon="keyboard_arrow_down"
                                selected={!!channels}
                            />
                        }
                        contents={channelCountOptions.map((channelCount) => (
                            <DropDownMenuButton
                                key={channelCount}
                                label={String(channelCount)}
                                selected={channels === channelCount}
                                onClick={() => updateChannelCount(channelCount)}
                            ></DropDownMenuButton>
                        ))}
                    />
                    <PillButton
                        checkable
                        text={t.encoderSelectorBlade}
                        selected={blade}
                        onClick={() => updateFilterCheckbox('blade')}
                    />
                    <PillButton
                        checkable
                        text={t.encoderSelectorTwoWayAudio}
                        selected={twoWayAudio}
                        onClick={() => updateFilterCheckbox('twoWayAudio')}
                    />
                    <SustainabilityFilter />
                </Grid>
            </Stack>
        </FilterPanel>
    );
};

EncoderFilterPanel.displayName = 'EncoderFilterPanel';
