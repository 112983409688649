import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';

export const getShowAddMapModal = createSelector([getMapsState], (state) => {
    return state.showAddMapModal;
});

export const getShowLocationModal = createSelector([getMapsState], (state) => {
    return state.showAddLocationModal;
});

export const getShowCopyMapModal = createSelector([getMapsState], (state) => {
    return state.showCopyMapModal;
});
