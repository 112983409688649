import React from 'react';
import { useSelector } from 'react-redux';

import { t } from 'app/translate';
import { format } from 'axis-webtools-util';
import type { IDataToDisplay } from '../../models';
import {
    getPowerDeviceSum,
    getPowerIlluminatorSum,
    getPowerRecorderSum,
    getSelectedPowerIlluminatorTime,
} from 'app/modules/common';

/** Hours per year without counting leap years */
const hoursPerYear = 24 * 365;
const whiteSpace = ' ';

export const usePowerSummary = () => {
    const sumDevice = useSelector(getPowerDeviceSum);
    const sumIlluminators = useSelector(getPowerIlluminatorSum);
    const sumRecorders = useSelector(getPowerRecorderSum);
    const illluminatorTimeInHours = useSelector(getSelectedPowerIlluminatorTime);

    const summaryItems = React.useMemo(() => {
        const illuminatorHoursPerYear = illluminatorTimeInHours * 365;

        // max Power
        const totalMaxPower = sumDevice.maxPower + sumIlluminators.maxPower + sumRecorders.maxPower;
        const totalMaxPowerExcludingIlluminators = sumDevice.maxPower + sumRecorders.maxPower;
        const totalMaxEnergy =
            totalMaxPowerExcludingIlluminators * hoursPerYear +
            sumIlluminators.maxPower * illuminatorHoursPerYear;

        // typical Power
        const totalTypicalPower =
            sumDevice.typical + sumIlluminators.typical + sumRecorders.typical;
        const totalTypicalPowerExcludingIlluminators = sumDevice.typical + sumRecorders.typical;
        const totalTypicalEnergy =
            totalTypicalPowerExcludingIlluminators * hoursPerYear +
            sumIlluminators.typical * illuminatorHoursPerYear;

        // formatted values
        const formattedTypicalPower = format.power(totalTypicalPower).split(whiteSpace);
        const formattedTypicalEnergy = format.energy(totalTypicalEnergy).split(whiteSpace);
        const formattedMaxPower = format.power(totalMaxPower).split(whiteSpace);
        const formattedTotalMaxEnergy = format.energy(totalMaxEnergy).split(whiteSpace);

        return {
            sumDevice,
            sumIlluminators,
            sumRecorders,
            summarySpecs: [
                {
                    title: t.totalTypicalPower,
                    value: formattedTypicalPower[0],
                    subTitle: formattedTypicalPower[1],
                },
                {
                    title: t.totalTypicalYearlyEnergy,
                    subTitle: `${formattedTypicalEnergy[1]} / ${t.year}`,
                    value: formattedTypicalEnergy[0],
                },
                {
                    title: t.totalMaxPower,
                    value: formattedMaxPower[0],
                    subTitle: formattedMaxPower[1],
                },
                {
                    title: t.totalMaxYearlyEnergy,
                    subTitle: `${formattedTotalMaxEnergy[1]} / ${t.year}`,
                    value: formattedTotalMaxEnergy[0],
                },
            ] as IDataToDisplay[],
        };
    }, [sumDevice, sumIlluminators, sumRecorders, illluminatorTimeInHours]);

    return summaryItems;
};
