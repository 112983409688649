import { injectable } from 'inversify';
import type { IInstallationReportSchedule } from '../../models';
import { AggregatedScheduleToQuartersService } from './AggregatedScheduleToQuarters.service';
import type { IScheduleModel, Id } from 'app/core/persistence';
import { ScheduleModelService } from 'app/core/persistence';
import { ScheduleAggregationService } from 'app/modules/common';

@injectable()
export class InstallationReportScheduleService {
    constructor(
        private scheduleModelService: ScheduleModelService,
        private scheduleAggregationService: ScheduleAggregationService,
        private aggregatedScheduleToQuartersService: AggregatedScheduleToQuartersService,
    ) {}

    public async getSchedules(projectId: Id): Promise<IInstallationReportSchedule[]> {
        const schedules = await this.scheduleModelService.getProjectSchedules(projectId);

        return schedules
            .filter((schedule: IScheduleModel) => !schedule.systemDefined)
            .map((schedule): IInstallationReportSchedule => {
                const aggregatedSchedule =
                    this.scheduleAggregationService.getAggregatedSchedule(schedule);

                return {
                    id: schedule.id,
                    rev: schedule.rev,
                    name: schedule.name,
                    daySegments:
                        this.aggregatedScheduleToQuartersService.getQuarters(aggregatedSchedule),
                };
            });
    }
}
