import {
    CARIBBEAN_COUNTRY_CODES,
    JAPAN_COUNTRY_CODES,
    MIDDLE_AMERICA_COUNTRY_CODES,
    NORTH_AMERICA_COUNTRY_CODES,
    NORTH_ASIA_COUNTRY_CODES,
    OCEANIA_COUNTRY_CODES,
    SOUTH_AMERICA_COUNTRY_CODES,
    SOUTH_ASIA_PACIFIC_COUNTRY_CODES,
    createDeepEqualSelector,
    getCurrentProjectLocation,
} from 'app/modules/common';
import { createSelector } from 'reselect';
import { partnerContactLinks } from '../models';
import type { IStoreState } from 'app/store';

const AMERICAS_REGIONS: number[] = [
    ...NORTH_AMERICA_COUNTRY_CODES,
    ...MIDDLE_AMERICA_COUNTRY_CODES,
    ...SOUTH_AMERICA_COUNTRY_CODES,
    ...CARIBBEAN_COUNTRY_CODES,
];

const ASIA_PACIFIC_REGIONS: number[] = [
    ...OCEANIA_COUNTRY_CODES,
    ...JAPAN_COUNTRY_CODES,
    ...NORTH_ASIA_COUNTRY_CODES,
    ...SOUTH_ASIA_PACIFIC_COUNTRY_CODES,
];

const getPartnerLinkParameters = (_state: IStoreState, vendor: string, productName: string) => ({
    vendor,
    productName,
});

/** Gets the base link for the contact button in TIP read more modal.
 * The link is based on the current project location. */
const getPartnerContactLink = createSelector([getCurrentProjectLocation], (location) => {
    // If no location is set, let the user select region on their own. This should never happen though.
    if (!location) return partnerContactLinks.DEFAULT;

    if (AMERICAS_REGIONS.includes(location.id)) {
        return partnerContactLinks.AMERICAS;
    }
    if (ASIA_PACIFIC_REGIONS.includes(location.id)) {
        return partnerContactLinks.APAC;
    }

    // Else assume the country is part of EMEA
    return partnerContactLinks.EMEA;
});

/** Concatenates the base contact link with partner & solution parameters.
 * @param state - The store state.
 * @param vendor - The vendor of the partner product.
 * @param productName - The name of the partner product.
 * @returns The full contact link with parameters.
 */
export const getFullPartnerContactLink = createDeepEqualSelector(
    [getPartnerContactLink, getPartnerLinkParameters],
    (baseLink, urlParameters) => {
        // URL encode spaces with %20
        const vendorString = encodeURIComponent(urlParameters.vendor);
        const productNameString = encodeURIComponent(urlParameters.productName);

        return `${baseLink}?partner=${vendorString}&solution=${productNameString}`;
    },
);
