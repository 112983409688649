import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    deviceIcons,
    getProductNameParts,
    getPiaItemsRecord,
    getCurrentProjectItem,
    getAvailablePiaDevicesRecord,
    getReplacements,
    getDeviceAndSubTypeForItem,
    getShouldShow2NSelector,
    getDeviceSelectorEditLink,
} from 'app/modules/common';
import type { IPiaDevice, IPiaItem, PiaId } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import {
    DropDownMenu,
    DropDownMenuText,
    DropDownMenuButton,
    DropDownMenuLink,
} from 'app/components';
import { t } from 'app/translate';
import type { DeviceAndSubType } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { ProjectDevicesService } from '../../services';
import { getShouldHideChangeDevice } from '../../selectors';

interface IDeviceChangerOwnProps {
    itemId: string;
}

interface IDeviceChangerProps extends IDeviceChangerOwnProps {
    replacements: IPiaDevice[];
    deviceType?: DeviceAndSubType;
    deviceIsMissingInRegion?: boolean;
    deviceIsDiscontinued?: boolean;
    deviceIsHidden?: boolean;
    shouldHideReplacements: boolean;
    vendor: string | undefined;
    editLink: string | undefined;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IDeviceChangerOwnProps,
): IDeviceChangerProps => {
    const item = getCurrentProjectItem(storeState, ownProps.itemId);
    const piaItem = item?.productId && getPiaItemsRecord(storeState)[item.productId];
    const vendor = piaItem ? piaItem.properties.vendor : undefined;
    const shouldHideReplacements = item ? getShouldHideChangeDevice(storeState, item._id) : false;
    const shouldShow2NDevices = getShouldShow2NSelector(storeState);
    const editLink = getDeviceSelectorEditLink(storeState, ownProps.itemId);

    const deviceIsDiscontinued = piaItem
        ? piaItem.state > PiaItemState.EXTERNALLY_ANNOUNCED
        : false;

    const deviceIsMissingInRegion =
        (!storeState.common.partnerConfig.useAllowlist &&
            !!item?.productId &&
            !getAvailablePiaDevicesRecord(storeState)[item.productId]) ||
        // The reason we need to check for this is because Cambodia has access to north american product versions,
        // but should still have no access to 2N products due to business regulations.
        (vendor === '2N' && !shouldShow2NDevices);

    return {
        itemId: ownProps.itemId,
        deviceType: item ? getDeviceAndSubTypeForItem(storeState, item._id) : undefined,
        replacements: getReplacements(storeState, ownProps.itemId),
        deviceIsDiscontinued,
        deviceIsMissingInRegion,
        deviceIsHidden: (piaItem as IPiaItem)?.externallyHidden,
        shouldHideReplacements,
        vendor,
        editLink,
    };
};

class DeviceChangerContainer extends React.PureComponent<IDeviceChangerProps> {
    private projectDevicesService: ProjectDevicesService;

    constructor(props: IDeviceChangerProps) {
        super(props);
        this.projectDevicesService = ServiceLocator.get(ProjectDevicesService);
    }

    public render() {
        if (
            (!this.props.deviceIsDiscontinued && !this.props.deviceIsMissingInRegion) ||
            this.props.deviceIsHidden
        ) {
            return null;
        }

        return (
            <DropDownMenu
                icon="warning_small"
                arrow
                iconProps={{ size: 'sm', color: 'red', opaque: true }}
                showOnlyTriggerContent
                alignItems="start"
            >
                <DropDownMenuText
                    label={
                        this.props.deviceIsMissingInRegion
                            ? t.deviceListMissingInCountryHeader
                            : t.deviceListDiscontinuedHeader
                    }
                    icon="warning_small"
                    iconProps={{ color: 'red', opaque: true }}
                    description={
                        this.props.deviceIsMissingInRegion
                            ? t.deviceListMissingInCountryDescription
                            : t.deviceListDiscontinuedDescription
                    }
                />
                {!this.props.shouldHideReplacements &&
                    this.props.replacements.map((device: IPiaDevice) => (
                        <DropDownMenuButton
                            label={getProductNameParts(device.name).model}
                            key={device.id}
                            onClick={() => this.onChangeModel(device.id, device.name)}
                            description={getProductNameParts(device.name).description}
                            piaImageProps={{
                                icon: deviceIcons.toIcon(this.props.deviceType),
                                piaId: device.id,
                                imageSize: 'lg',
                            }}
                        />
                    ))}
                {!this.props.shouldHideReplacements && (
                    <DropDownMenuLink
                        label={
                            this.props.deviceIsMissingInRegion
                                ? t.deviceListMissingInCountryAction
                                : t.deviceListDiscontinuedAction
                        }
                        link={this.props.editLink ?? ''}
                    />
                )}
            </DropDownMenu>
        );
    }

    private onChangeModel = async (piaId: PiaId, modelName: string) => {
        eventTracking.logUserEvent('Project Devices', 'Update Device Model', modelName);
        await this.projectDevicesService.changeModel(this.props.itemId, piaId);
    };
}

export const DeviceChanger = connect(mapStateToProps)(DeviceChangerContainer);
