import type { ISpeakerPropertiesFilterModel, UnitSystem } from 'app/core/persistence';
import { convert } from 'axis-webtools-util';

const InstallationHeight = {
    metric: 3,
    imperial: convert.feetToMeters(10.0),
};

export function getDefaultSpeakerFilter(unit: UnitSystem): ISpeakerPropertiesFilterModel {
    return {
        placement: undefined,
        outdoor: false,
        basicSolution: true,
        installationHeight: InstallationHeight[unit],
        listeningArea: undefined,
        wallLength: undefined,
        isFilterChanged: false,
    };
}
