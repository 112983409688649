import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';

export const getErrorMessage = createSelector([getMapsState], (state) => {
    return state.errorMessage;
});

export const getUploadErrorMessage = createSelector([getMapsState], (state) => {
    return state.uploadErrorMessage;
});

export const getIsUploadingFloorPlan = createSelector([getMapsState], (state) => {
    return state.uploading;
});
