export type ImportErrorReason =
    | 'invalidFileFormat'
    | 'fileContentsUnreadable'
    | 'entityImportFailed'
    | 'imageQuotaFull'
    | 'diskSpaceFull';

export class ImportError extends Error {
    constructor(
        public reason: ImportErrorReason,
        public optionalMessage?: string,
    ) {
        super(reason);
        Object.setPrototypeOf(this, ImportError.prototype);
    }
}
