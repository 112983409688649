import type { TemperatureScale, UnitSystem } from 'app/core/persistence';
import type { IPiaLocation } from 'app/core/pia';
import type { ILocation } from '../models';

const LOCATION_API_URL = 'https://www.axis.com/api/geo/location/sitedesigner';

export const AXIS_HQ: [number, number] = [55.716684, 13.216013];

/**
 * Use the external AXIS geolocation API to get a country code
 * for the users current position. Probably uses IP-address.
 * Will return null if the API call fails.
 */
export const getUserCountryCode = async (): Promise<string | null> => {
    try {
        const response = await fetch(LOCATION_API_URL);
        const location = await response.json();
        return location.country_code;
    } catch {
        return null;
    }
};

/**
 * Only Unites States and Liberia use the non-metric system.
 * Myanmar (Burma) is transitioning to the metric system.
 * This will return non-metric for Myanmar.
 */
export const getUnitSystemByCountryCode = (countryCode: string): UnitSystem => {
    switch (countryCode.toLowerCase()) {
        case 'us':
        case 'lr':
        case 'mm':
            return 'imperial';
        default:
            return 'metric';
    }
};

export const getTemperatureScaleByCountryCode = (
    piaLocation: IPiaLocation | null,
): TemperatureScale => {
    const countryCode = piaLocation?.countryCode;

    switch (countryCode?.toLowerCase()) {
        case 'us': // United States of America
        case 'pw': // Palau
        case 'ky': // Cayman Islands
        case 'vi': // U.S Virgin Islands
        case 'lr': // Liberia
        case 'gu': // Guam
        case 'mp': // Northern Mariana Islands
        case 'fm': // Micronesia
        case 'mh': // Marshall Islands
        case 'as': // American Samoa
        case 'pr': // Puerto Rico
        case 'bs': // Bahamas
        case 'bz': // Belize - Might prioritize Celsius. Not 100% sure
        case 'bm': // Bermuda - Unclear if they primarily use Celsius or Fahrenheit
        case 'ai': // Anguilla - Unclear if they primarily use Celsius or Fahrenheit
        case 'vg': // British Virgin Islands - Unclear if they primarily use Celsius or Fahrenheit
        case 'ag': // Antigua and Barbuda - Unclear if they primarily use Celsius or Fahrenheit
        case 'kn': // Saint Kitts and Nevis - Unclear if they primarily use Celsius or Fahrenheit
        case 'ms': // Montserrat - Unclear if they primarily use Celsius or Fahrenheit
            return 'fahrenheit';
        default:
            return 'celsius';
    }
};

/**
 * Use the external AXIS geolocation API to get the geolocation
 * for the users current position. Probably uses IP-address.
 * Will return Axis HQ if the API call fails.
 */
export const getUserGeolocation = async (): Promise<ILocation> => {
    try {
        const response = await fetch(LOCATION_API_URL);
        const location = await response.json();
        return {
            lat: Number(location.lat),
            lng: Number(location.long),
        };
    } catch {
        return {
            lat: AXIS_HQ[0],
            lng: AXIS_HQ[1],
        };
    }
};
