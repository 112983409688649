import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import type { IStoreState } from 'app/store';
import type { IPiaItem } from 'app/core/pia';
import { filterProducts } from 'app/core/pia';
import {
    exceptBarebones,
    exceptEnvironments,
    externallyAnnouncedOnly,
    getAvailablePiaItems,
    getPiaIdsFromProps,
    getShouldShow2NSelector,
    exceptVirtualProducts,
    exceptAxisAnd2N,
    exceptCamerasWithoutLens,
    isAxis,
} from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import { isRecordingSolutionComponent, isSystemAccessory } from 'app/core/persistence';
import { isDisabledQuickAddItem } from '../components/quickAddDevices/isDisabledQuickAddItem';
import { sortBy } from 'lodash-es';

const getQuickAddSearchText = (state: IStoreState) => state.deviceSelector.quickAddSearchValue;

const createPiaDeviceFilter = (searchText: string) => (device: IPiaItem) => {
    return device.name.toLowerCase().includes(searchText.toLowerCase());
};

const getCurrentAvailableQuickAddPiaItems = createSelector(
    [getAvailablePiaItems, getShouldShow2NSelector],
    (devices, shouldShow2NDevices) => {
        const availableDevices = devices
            .filter(externallyAnnouncedOnly)
            .filter(exceptEnvironments)
            .filter(exceptBarebones)
            .filter(exceptCamerasWithoutLens)
            .filter(exceptVirtualProducts)
            .filter(exceptAxisAnd2N);

        if (shouldShow2NDevices) {
            return availableDevices;
        } else {
            return availableDevices.filter(filterProducts.byExcludingVendor('2N'));
        }
    },
);

const isDeviceListItem = (item: IPiaItem): boolean =>
    !isSystemAccessory(item) && !isRecordingSolutionComponent(item);

/** Gets quick add items matching search text. Sorts disabled items lowest, followed by items not visible in device list, followed by non Axis items. Finally the alternatives are sorted alphabetically. */
export const getFilteredQuickAddItems = createSelector(
    [getCurrentAvailableQuickAddPiaItems, getQuickAddSearchText],
    (items, searchText) => {
        return searchText !== ''
            ? sortBy(items.filter(createPiaDeviceFilter(searchText)), (item) => [
                  isDisabledQuickAddItem(item),
                  !isDeviceListItem(item),
                  !isAxis(item.properties.vendor),
                  item.name,
              ])
            : [];
    },
);

export const getPiaDevicesForPiaIds = createCachedSelector(
    [getCurrentAvailableQuickAddPiaItems, getPiaIdsFromProps],
    (devices, piaIds) => {
        const allPreviousDevices = piaIds.map((piaId) =>
            devices.find((device) => device.id === piaId),
        );
        return allPreviousDevices.filter(isDefined).splice(0, 5);
    },
)((_state, piaIds) => piaIds.join(','));
