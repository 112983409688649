import type { IProject, TemperatureScale } from 'app/core/persistence';
import type { IPiaLocation } from 'app/core/pia';
import { PiaLocationService } from 'app/core/pia';
import { getTemperatureScaleByCountryCode } from 'app/modules/common/locations/services/Location.service';
import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * TemperatureScale options added to settings.
 * New projects will receive a default temperature scale based on the user's default region.
 * Existing projects will receive a default temperature scale based on their project's
 * selected region at the time of this migration.
 */
@injectable()
export class Migration19To20 extends MigrationProviderBase {
    public from: number = 19;
    public to: number = 20;

    constructor(private piaLocationService: PiaLocationService<IPiaLocation>) {
        super();
    }

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'project' && entity.temperatureScale === undefined) {
                const project = entity as IProject;

                const piaLocation = this.piaLocationService
                    .get(project.installationPiaLocationId)
                    .first();

                const temperatureScale: TemperatureScale =
                    getTemperatureScaleByCountryCode(piaLocation);
                entity.temperatureScale = temperatureScale;
            }

            return entity;
        };
    }
}
