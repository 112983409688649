import * as React from 'react';
import { ContentPanel, Box, Text } from 'app/components';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { PartnerConfig } from './components/PartnerConfig';
import { useSelector } from 'react-redux';
import { getUrlPartnerId } from 'app/partnerUrl';

export const PartnerConfigView: React.FC = () => {
    const locId = useSelector<IStoreState, number | undefined>(
        (state) => state.common.user.user?.locid,
    );
    const loadedByUrl = getUrlPartnerId();

    return (
        <ContentPanel>
            <Box paddingY="panel">
                {!locId || loadedByUrl ? (
                    <Text>{t.partnerConfigLocIdMissing}</Text>
                ) : (
                    <PartnerConfig />
                )}
            </Box>
        </ContentPanel>
    );
};

PartnerConfigView.displayName = 'PartnerConfigView';
