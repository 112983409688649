import { trigonometry } from 'axis-webtools-util';

export const calculate = {
    trueDistance(cameraHeight: number, targetDistance: number, targetHeight: number) {
        return trigonometry.hypotenuse(cameraHeight - targetHeight, targetDistance);
    },
    getSelectedFovRadians(
        desiredFovRadians: number,
        selectedMinFovRadians: number | undefined,
        selectedMaxFovRadians: number | undefined,
    ) {
        if (selectedMaxFovRadians === undefined || selectedMinFovRadians === undefined) {
            return desiredFovRadians;
        }
        return Math.max(selectedMinFovRadians, Math.min(desiredFovRadians, selectedMaxFovRadians));
    },
};
