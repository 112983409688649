import { injectable } from 'inversify';
import type { IUserInfo } from '../../../models';
import { UserDataRepository } from './UserData.repository';
import { UserApiCommunicator } from './UserApi.communicator';
import type {
    IPersistedUserInfo,
    IPersistedUserPrivileges,
    IUserInfoResponse,
    IUserPrivilegesResponse,
} from '../models';
import { AppSettings } from 'app/AppSettings';
import { memoize } from 'lodash-es';

@injectable()
export class UserService {
    constructor(
        private userDataRepository: UserDataRepository,
        private userApiCommunicator: UserApiCommunicator,
        private appSettings: AppSettings,
    ) {}

    /**
     * Get the user info. Will return null if the user has no cookie
     * (ie. is/has not been logged in) or if the database fails.
     */
    public getUser = memoize(async (): Promise<IUserInfo | null> => {
        if (!this.hasCookie()) {
            try {
                const lastUser = await this.getUserFromStore();
                return lastUser;
            } catch {
                return null;
            }
        }
        try {
            return await this.getUserInfo();
        } catch {
            return null;
        }
    });

    /**
     * Clear all locally stored user info and privileges.
     */
    public clearUserInfo = () => this.userDataRepository.clearUserInfo();

    /**
     * Check if user is, or has been, logged in.
     */
    public hasCookie = () => document.cookie.includes(this.appSettings.authenticationCookie);

    /**
     * A function to check if user has changed, previously logged in user not same as current logged in user.
     * @returns true if user has changed, false otherwise.
     */
    public hasUserChanged = async (): Promise<boolean> => {
        const previousUserHid = await this.userDataRepository.getPreviousUserHid();
        const user = await this.getUser();
        if (!previousUserHid) {
            if (user) {
                await this.userDataRepository.setPreviousUserHid(user.hid);
            }
            // No previous user data - nothing changed.
            return false;
        }

        if (user) {
            await this.userDataRepository.setPreviousUserHid(user.hid);
        }

        // We previously stored `user.unid` to check last user, but are now using `user.hid`
        // and we need to support both
        if (user !== null && user.hid !== previousUserHid && user.unid !== previousUserHid) {
            return true;
        }

        return false;
    };

    /**
     * Fetch user info from API and update store. Returns an IUserInfo object
     * with the data from store. Will throw if database fails.
     */
    private getUserInfo = async (): Promise<IUserInfo> => {
        await this.fetchAndUpdateStore();
        return this.getUserFromStore();
    };

    /**
     * Read user info from store and create an IUserInfo object.
     * Will throw if database fails or if no user is stored.
     */
    private getUserFromStore = async (): Promise<IUserInfo> => {
        const userInfo = await this.userDataRepository.getUserInfo();
        const userPrivileges = await this.userDataRepository.getUserPrivileges();

        if (!userInfo) {
            throw new Error('No user in store');
        }

        return this.createUserFromStore(userInfo, userPrivileges);
    };

    /**
     * Fetch user info from the api and update the store with this data.
     * Will throw if database fails.
     */
    private fetchAndUpdateStore = async (): Promise<void> => {
        const userInfo = await this.userApiCommunicator.fetchUserInfo();
        const userPrivileges = await this.userApiCommunicator.fetchUserPrivileges();

        return this.updateStore(userInfo, userPrivileges);
    };

    /**
     * Convert the fetched data to stored format and save to store.
     * Will throw if database fails.
     */
    private updateStore = async (
        userInfo: IUserInfoResponse | null,
        userPrivileges: IUserPrivilegesResponse | null,
    ): Promise<void> => {
        if (userInfo) {
            await this.userDataRepository.setUserInfo(userInfo);
        }

        if (userPrivileges) {
            await this.userDataRepository.setUserPrivileges({
                locid: userPrivileges.locid,
            });
        }
    };

    /**
     * Create an IUserInfo object from the stored user info.
     */
    private createUserFromStore = (
        userInfo: IPersistedUserInfo,
        userPrivileges?: IPersistedUserPrivileges,
    ): IUserInfo => {
        return {
            ...userInfo,
            locid: userPrivileges ? userPrivileges.locid : undefined,
        };
    };
}
