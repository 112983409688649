import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';

interface IWDRTechnologyFilterProps {
    selectedWDRTechnology?: string;
    onClick(WDRTechnology: string): void;
}

const WDR = {
    any: 'Forensic Capture|Dynamic Capture|Dynamic Contrast|Forensic WDR|WDR - dynamic capture|WDR - dynamic contrast',
    forensicOrDynamicCapture: 'Forensic Capture|Dynamic Capture|Forensic WDR|WDR - dynamic capture',
    forensic: 'Forensic Capture|Forensic WDR',
    dynamicCapture: 'Dynamic Capture|WDR - dynamic capture',
    dynamicContrast: 'Dynamic Contrast|WDR - dynamic contrast',
};

const getPillButtonText = (selected: string | undefined) => {
    switch (selected) {
        case WDR.dynamicCapture:
            return t.advancedFiltersGROUP.dynamicCapture;
        case WDR.dynamicContrast:
            return t.advancedFiltersGROUP.dynamicContrast;
        case WDR.forensic:
            return t.forensicWDR;
        case WDR.forensicOrDynamicCapture:
            return t.advancedFiltersGROUP.forensicOrDynamicCapture;
        default:
            return t.advancedFiltersGROUP.WDRTechnology;
    }
};

export const WDRTechnologyFilter: React.FC<IWDRTechnologyFilterProps> = ({
    selectedWDRTechnology,
    onClick,
}) => {
    return (
        <DropDown
            trigger={
                <PillButton
                    icon="keyboard_arrow_down"
                    selected={!!selectedWDRTechnology}
                    text={getPillButtonText(selectedWDRTechnology)}
                />
            }
            contents={
                <>
                    <DropDownMenuButton
                        label={t.advancedFiltersGROUP.any}
                        selected={selectedWDRTechnology === WDR.any}
                        onClick={() => onClick(WDR.any)}
                    />
                    <DropDownMenuButton
                        label={t.advancedFiltersGROUP.forensicOrDynamicCapture}
                        selected={selectedWDRTechnology === WDR.forensicOrDynamicCapture}
                        onClick={() => onClick(WDR.forensicOrDynamicCapture)}
                    />
                    <DropDownMenuButton
                        label={t.forensicWDR}
                        selected={selectedWDRTechnology === WDR.forensic}
                        onClick={() => onClick(WDR.forensic)}
                    />
                    <DropDownMenuButton
                        label={t.advancedFiltersGROUP.dynamicCapture}
                        selected={selectedWDRTechnology === WDR.dynamicCapture}
                        onClick={() => onClick(WDR.dynamicCapture)}
                    />
                    <DropDownMenuButton
                        label={t.advancedFiltersGROUP.dynamicContrast}
                        selected={selectedWDRTechnology === WDR.dynamicContrast}
                        onClick={() => onClick(WDR.dynamicContrast)}
                    />
                </>
            }
        />
    );
};

WDRTechnologyFilter.displayName = 'WDRTechnologyFilter';
