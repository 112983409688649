import { getParentId } from 'app/core/persistence';

import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectInstallationPoints,
    getIdFromProps,
    getInstallationPointsPerDeviceSortedByCreation,
    toCacheKey,
} from 'app/modules/common';

export const getIndexForInstallationPointId = createCachedSelector(
    [
        getInstallationPointsPerDeviceSortedByCreation,
        getCurrentProjectInstallationPoints,
        getIdFromProps,
    ],
    (installationPointsPerDevice, installationPointsRecord, installationPointId) => {
        if (!installationPointId) return 0;

        const installationPoint = installationPointsRecord[installationPointId];
        if (!installationPoint || installationPoint?.parentId) return 0;

        const deviceId = getParentId(installationPoint);
        if (!deviceId) return 0;

        const installationPointsForDevice = installationPointsPerDevice[deviceId];
        return installationPointsForDevice.findIndex((ip) => ip._id === installationPointId) + 1;
    },
)(toCacheKey);
