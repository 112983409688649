import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IPersistenceRepository } from '../../repositories';
import { v4 as uuidv4 } from 'uuid';
import { AddableEntity } from '../models';

/**
 * Breaks out quotation from project to separate document
 */
@injectable()
export class Migration12To13 extends MigrationProviderBase {
    public from: number = 12;
    public to: number = 13;

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            const migratedEntities = [];

            if (entity.type === 'project') {
                const descendants = await repository.getDescendants(entity._id);

                const existingQuotations = descendants.filter(
                    (descendant) => descendant.type === 'quotation',
                );

                if (!existingQuotations.length) {
                    const id = 'quotation:' + uuidv4();
                    const projectQuotation = entity.quotation;

                    migratedEntities.push(
                        new AddableEntity({
                            ...projectQuotation,
                            _id: id,
                            _rev: '',
                            type: 'quotation',
                            path: [...entity.path, id],
                            creationDate: entity.creationDate,
                            updatedDate: entity.updatedDate,
                            entityVersion: this.to,
                        }),
                    );
                }
                delete entity.quotation;
            }
            migratedEntities.push(entity);
            return migratedEntities;
        };
    }
}
