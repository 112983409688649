import * as React from 'react';
import { t } from 'app/translate';
import { Alert, Border, Box, Heading, ReadDirection, Table, Text } from 'app/components';
import { PowerTableRow } from './PowerTableRow.container';
import type { IItemEntity, IPersistence } from 'app/core/persistence';
import { useSelector } from 'react-redux';

import { format } from 'axis-webtools-util';
import { SummaryInfo } from './SummaryInfo';
import { usePowerSummary } from '../selectors/usePowerSummary.hook';
import {
    getIsOtherProductInProject,
    getPowerDeviceItems,
    getPowerForItems,
    getPowerIlluminatorItems,
    getPowerRecorderItems,
} from 'app/modules/common';
import { SelectIlluminationTime } from './SelectIlluminationTime';

const columnWidths = ['40%', '5%', '20%', '20%', '15%'];

export interface IPowerReportSectionProps {
    tableRefDevice?: React.Ref<HTMLTableElement>;
    tableRefIlluminator?: React.Ref<HTMLTableElement>;
    tableRefRecorders?: React.Ref<HTMLTableElement>;
}

export const PowerReportSection: React.FC<IPowerReportSectionProps> = ({
    tableRefDevice,
    tableRefIlluminator,
    tableRefRecorders,
}) => {
    const powerItems = useSelector(getPowerDeviceItems);
    const illuminatorItems = useSelector(getPowerIlluminatorItems);
    const recorderItems = useSelector(getPowerRecorderItems);
    const powerRecord = useSelector(getPowerForItems);

    const summary = usePowerSummary();

    const productsFromOtherVendorIsIncluded = useSelector(getIsOtherProductInProject);
    const disclaimerOtherVendors = productsFromOtherVendorIsIncluded
        ? `\n\n${t.powerReportDisclaimerTextOtherProducts}`
        : '';

    const renderTableRow = (item: IPersistence<IItemEntity>) => {
        const powerItem = powerRecord[item._id];
        return (
            <PowerTableRow
                key={item._id}
                item={item}
                typicalPower={powerItem.typical}
                maxPower={powerItem.maxPower}
            />
        );
    };

    const renderTotalRow = (total: { typical: number; maxPower: number }) => (
        <tr>
            <td />
            <td style={{ textAlign: 'end' }}>
                <b>{t.quotationTotal}:</b>
            </td>
            <td data-test-id="td_total_typical_power">
                <Box>
                    <ReadDirection direction="ltr">
                        <Text>{format.power(total.typical)}</Text>
                    </ReadDirection>
                </Box>
            </td>
            <td data-test-id="td_total_max_power">
                <Box>
                    <ReadDirection direction="ltr">
                        <Text>{format.power(total.maxPower)}</Text>
                    </ReadDirection>
                </Box>
            </td>
            <td />
            <td />
        </tr>
    );

    if (powerItems.length <= 0 && illuminatorItems.length <= 0 && recorderItems.length <= 0) {
        return (
            <Box direction="column" spacing="panel">
                <Heading style="subheader">{t.estimatedPowerConsumption}</Heading>
                <Text faded>{t.bandwidthReportNoPowerDevices}</Text>
            </Box>
        );
    }

    return (
        <Box spacing="panel" direction="column">
            <Heading style="subheader">{t.estimatedPowerConsumption}</Heading>
            {powerItems.length > 0 && (
                <Table
                    tableRef={tableRefDevice}
                    omitLastLine
                    headers={[
                        t.devices,
                        t.quotationQuantity,
                        t.typicalPower,
                        t.maxPower,
                        t.powerSupply,
                    ]}
                    columnWidth={columnWidths}
                >
                    {powerItems.map(renderTableRow)}
                    {renderTotalRow(summary.sumDevice)}
                </Table>
            )}

            {illuminatorItems.length > 0 && (
                <Table
                    tableRef={tableRefIlluminator}
                    omitLastLine
                    columnWidth={columnWidths}
                    headers={[
                        <Box alignItems="center" spacing="quart">
                            <Text style="semibold">
                                {t.accessoryDisplayCategoriesGROUP['illuminators']}
                            </Text>
                            <Border leftWidth={1} color="grey3">
                                <Box paddingLeft="quart">
                                    <Text color="grey6">{t.averageDailyUse}</Text>
                                </Box>
                            </Border>
                            <SelectIlluminationTime />
                        </Box>,
                        t.quotationQuantity,
                        t.typicalPower,
                        t.maxPower,
                        t.powerSupply,
                    ]}
                >
                    {illuminatorItems.map(renderTableRow)}
                    {renderTotalRow(summary.sumIlluminators)}
                </Table>
            )}

            {recorderItems.length > 0 && (
                <Table
                    tableRef={tableRefRecorders}
                    columnWidth={columnWidths}
                    omitLastLine
                    headers={[
                        t.recorders,
                        t.quotationQuantity,
                        t.typicalPower,
                        t.maxPower,
                        t.powerSupply,
                    ]}
                >
                    {recorderItems.map(renderTableRow)}
                    {renderTotalRow(summary.sumRecorders)}
                </Table>
            )}
            <Box avoidPageBreakInside justifyContent="end">
                <Box justifyContent="end">
                    <SummaryInfo
                        testId="power_properties_container"
                        backgroundColor="yellow"
                        items={summary.summarySpecs}
                    />
                </Box>
            </Box>

            <Alert
                heading={t.powerReportDisclaimerHeading}
                text={`${t.powerReportDisclaimerText}${disclaimerOtherVendors}`}
                level="info"
                color="blue"
            />
        </Box>
    );
};
