import * as React from 'react';
import { Label, Box, Stack, ToggleButton, NumberInput } from 'app/components';
import { UnreachableCaseError } from 'axis-webtools-util';
import { t } from 'app/translate';
import type { DistanceUnit } from 'app/core/persistence';
import { distanceUnitShortText } from 'app/core/persistence';
import type { PixelDensityRequirement, IPixelDensity } from 'app/modules/common/pixelDensity';
import { PixelDensities, getPixelDensityValue } from 'app/modules/common/pixelDensity';
import { css } from '@emotion/css';
import { convertDensityToDisplayUnit, convertDensityToMeters } from 'app/core/common';

interface IPixelDensityProps {
    pixelDensity: number;
    max: number;
    displayUnit: DistanceUnit.Meter | DistanceUnit.Feet;
    onPixelDensityChange(value: number): void;
}

const labelValueStyle = css`
    white-space: pre;
    opacity: 0.54;
`;

export class PixelDensity extends React.PureComponent<IPixelDensityProps> {
    public render() {
        return (
            <div>
                <Label opaque>
                    {t.cameraSelectorFieldOfViewPixelDensity}
                    <span className={labelValueStyle}>{this.getPixelDensityInfo()}</span>
                </Label>
                <Box justifyContent="between" alignItems="center">
                    <Stack spacing="quart">
                        {PixelDensities.map((resolution) => {
                            return (
                                <ToggleButton
                                    key={resolution.meterValue}
                                    onChange={() => this.onPixelDensityToggleClick(resolution)}
                                    type="framed"
                                    pressed={this.isPixelDensityToggled(resolution)}
                                >
                                    <img src={resolution.image} />
                                </ToggleButton>
                            );
                        })}
                    </Stack>
                    <NumberInput
                        testId="pixel_density_npt"
                        min={1}
                        max={this.props.max}
                        value={convertDensityToDisplayUnit(
                            this.props.pixelDensity,
                            this.props.displayUnit,
                        )}
                        onChange={this.onPixelDensityChange}
                        changeCriteria="key"
                    />
                </Box>
            </div>
        );
    }

    private getPixelDensityInfo() {
        const pixelDensityString =
            `: ${convertDensityToDisplayUnit(this.props.pixelDensity, this.props.displayUnit)}` +
            ` ${t.abbreviationsGROUP.pixel}/${distanceUnitShortText(this.props.displayUnit)}`;

        const pixelDensity = PixelDensities.find(this.isPixelDensityToggled);
        return pixelDensity
            ? pixelDensityString.concat(
                  ', ',
                  this.getOperationalRequirement(pixelDensity.operationalRequirement),
              )
            : pixelDensityString;
    }

    private getOperationalRequirement(pixelDensity: PixelDensityRequirement): string {
        switch (pixelDensity) {
            case 'detect':
                return t.cameraSelectorFieldOfViewPixelOptionsDetect;
            case 'observe':
                return t.cameraSelectorFieldOfViewPixelOptionsObserve;
            case 'recognize':
                return t.cameraSelectorFieldOfViewPixelOptionsRecognize;
            case 'identify':
                return t.cameraSelectorFieldOfViewPixelOptionsIdentify;
            default:
                throw new UnreachableCaseError(pixelDensity, 'Unknown pixel density');
        }
    }

    private isPixelDensityToggled = (density: IPixelDensity) => {
        return (
            convertDensityToDisplayUnit(this.props.pixelDensity, this.props.displayUnit) ===
            getPixelDensityValue(density, this.props.displayUnit)
        );
    };

    private onPixelDensityToggleClick = (pixelDensity: IPixelDensity) => {
        this.onPixelDensityChange(getPixelDensityValue(pixelDensity, this.props.displayUnit));
    };

    private onPixelDensityChange = (value: number) => {
        this.props.onPixelDensityChange(convertDensityToMeters(value, this.props.displayUnit));
    };
}
