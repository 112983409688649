import { getIdFromProps } from './../../selectors/getIdFromProps';
import { createCachedSelector } from 're-reselect';
import { getCurrentProjectInstallationPoints } from './../../project/selectors/getCurrentProject';
import { createSelector } from 'reselect';
import { isDefined } from 'axis-webtools-util';
import { toCacheKey } from '../../cacheKey';

export const getInstallationPointsArray = createSelector(
    [getCurrentProjectInstallationPoints],
    (installationPoints) => Object.values(installationPoints).filter(isDefined),
);

export const getInstallationPoint = createCachedSelector(
    [getCurrentProjectInstallationPoints, getIdFromProps],
    (installationPoints, installationPointId) =>
        installationPointId ? installationPoints[installationPointId] : undefined,
)(toCacheKey);
