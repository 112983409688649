import { trigonometry, createTransformer } from 'axis-webtools-util';
import type {
    ILatLng,
    IBlockerEntity,
    IInstallationPointModel,
    IInstallationPointSensorModel,
    IInstallationPointSpeaker,
    IInstallationPointRadar,
    IInstallationPointPanRange,
    IFloorPlanEntity,
    IFreeTextPointEntity,
} from 'app/core/persistence';
import { createDerotationTransform, getFloorPlanGeoLocation } from 'app/modules/common';

export type TransformerFunction = (latLng: ILatLng) => ILatLng;
type FilterFunction = (blocker: ILatLng[]) => boolean;

/** a filter function that lets everything through */
const ALL_PASS_FILTER = () => true;

/**
 * Transform a blocker
 * @param transform the transformation function
 * @param filter a filter function used to determine which blockers to transform
 * @param blocker the blocker to transform
 * @returns the transformed blocker
 */
export const transformBlocker = (transform: TransformerFunction, blocker: ILatLng[]): ILatLng[] => {
    return blocker.map(transform);
};

/**
 * Transform a list of blockers
 * @param transform the transformation function
 * @param filter a filter function used to determine which blockers to transform
 * @param blockers the blockers to transform
 * @returns the transformed blockers
 */
export const transformBlockers = (
    transform: TransformerFunction,
    filter: FilterFunction = ALL_PASS_FILTER,
    blockers: ILatLng[][],
): ILatLng[][] => {
    return blockers.map((blocker) => {
        if (filter(blocker)) {
            // transform blocker if it matches the filter
            return transformBlocker(transform, blocker);
        } else {
            // otherwise leave as is
            return blocker;
        }
    });
};

/**
 * Transform a blocker entity
 * @param transform the transformation function
 * @param blocker the blocker entity to transform
 * @returns the transformed blocker entity
 */
export const transformBlockerEntity = (
    transform: TransformerFunction,
    blocker: IBlockerEntity,
): IBlockerEntity => {
    return {
        ...blocker,
        latLngs: transformBlocker(transform, blocker.latLngs),
    };
};

/**
 * Transform a list of blocker entities
 * @param transform the transformation function
 * @param blockers the blocker entities to transform
 * @returns the transformed blocker entities
 */
export const transformBlockerEntities = (
    transform: TransformerFunction,
    blockers: IBlockerEntity[],
): IBlockerEntity[] => {
    return blockers.map((blocker) => transformBlockerEntity(transform, blocker));
};

/**
 * Transform an installation point sensor according to the passed in transformation
 * @param transform the transformation function
 * @param rotation the rotation angle in radians, used to transform the target angle
 * @param sensor the installation point sensor to transform
 * @returns the transformed sensor
 */
export const transformInstallationPointSensor = (
    transform: TransformerFunction,
    rotation: number,
    sensor: IInstallationPointSensorModel,
    scale?: number,
): IInstallationPointSensorModel => {
    const rotationDeg = trigonometry.toDegrees(rotation);

    return {
        ...sensor,
        location: transform(sensor.location),
        target: {
            ...sensor.target,
            horizontalAngle: sensor.target.horizontalAngle + rotationDeg,
            distance: scale ? sensor.target.distance * scale : sensor.target.distance,
        },
    };
};

/**
 * Transform an installation point speaker according to the passed in rotation
 * @param rotation the rotation angle in radians, used to transform the target angle
 * @param speaker the installation point speaker to transform
 * @returns the transformed speaker
 */
export const transformInstallationPointSpeaker = (
    rotation: number,
    speaker: IInstallationPointSpeaker,
    scale?: number,
): IInstallationPointSpeaker => {
    const rotationDeg = trigonometry.toDegrees(rotation);

    return {
        ...speaker,
        target: {
            ...speaker.target,
            horizontalAngle: speaker.target.horizontalAngle + rotationDeg,
            distance: scale ? speaker.target.distance * scale : speaker.target.distance,
        },
    };
};

/**
 * Transform an installation point radar according to the passed in rotation
 * @param rotation the rotation angle in radians, used to transform the target angle
 * @param radar the installation point radar to transform
 * @returns the transformed radar
 */
export const transformInstallationPointRadar = (
    rotation: number,
    radar: IInstallationPointRadar,
    scale?: number,
): IInstallationPointRadar => {
    const rotationDeg = trigonometry.toDegrees(rotation);

    return {
        ...radar,
        target: {
            ...radar.target,
            horizontalAngle: radar.target.horizontalAngle + rotationDeg,
            distance: scale ? radar.target.distance * scale : radar.target.distance,
        },
    };
};

/**
 * Transform an installation point panRange according to the passed in rotation
 * @param rotation the rotation angle in radians, used to transform the target angle
 * @param panRange the installation point panRange to transform
 * @returns the transformed panRange
 */
export const transformInstallationPointPanRange = (
    rotation: number,
    panRange: IInstallationPointPanRange,
    scale?: number,
): IInstallationPointPanRange => {
    const rotationDeg = trigonometry.toDegrees(rotation);

    return {
        ...panRange,
        target: {
            ...panRange.target,
            horizontalAngle: panRange.target.horizontalAngle + rotationDeg,
            distance: scale ? panRange.target.distance * scale : panRange.target.distance,
        },
    };
};

/**
 * Transform an installation point according to the passed in transformation
 * @param transform the transformation function
 * @param rotation the rotation angle in radians, used to transform the target angle
 * @param ip the installation point to transform
 * @returns the transformed installation point
 */
export const transformInstallationPoint = (
    transform: TransformerFunction,
    rotation: number,
    ip: IInstallationPointModel,
    scale?: number,
): IInstallationPointModel => {
    return {
        ...ip,
        location: transform(ip.location),
        sensors: ip.sensors.map((sensor) =>
            transformInstallationPointSensor(transform, rotation, sensor, scale),
        ),
        speaker: ip.speaker && transformInstallationPointSpeaker(rotation, ip.speaker, scale),
        radar: ip.radar && transformInstallationPointRadar(rotation, ip.radar, scale),
        panRange: ip.panRange && transformInstallationPointPanRange(rotation, ip.panRange, scale),
    };
};

/**
 * Transform a floor plan according to the passed in transformation
 * @param transform the transformation function
 * @param rotation the rotation angle in degrees, used to transform the floor plan orientation
 * @param floorPlan the floor plan to transform
 * @returns the transformed floor plan
 */
export const transformFloorPlan = (
    transform: TransformerFunction,
    rotation: number,
    floorPlan: IFloorPlanEntity,
) => ({
    ...floorPlan,
    image: floorPlan.image && {
        ...floorPlan.image,
        geoLocation: floorPlan.image.geoLocation && {
            ...floorPlan.image.geoLocation,
            position: transform(floorPlan.image.geoLocation.position),
            angle: floorPlan.image.geoLocation.angle + rotation,
        },
    },
});

/**
 * Transform a free text point according to the passed in transformation
 * @param transform the transformation function
 * @param freeTextPoint the free text point to transform
 * @returns the transformed free text point
 */
export const transformFreeTextPoint = (
    transform: TransformerFunction,
    freeTextPoint: IFreeTextPointEntity,
) => ({
    ...freeTextPoint,
    location: transform(freeTextPoint.location),
});

export const createRotationTransform = (floorPlan: IFloorPlanEntity | undefined) => {
    const geoLocation = floorPlan && getFloorPlanGeoLocation(floorPlan);

    if (!geoLocation) {
        // identity
        return (latLng: ILatLng) => latLng;
    }

    return createTransformer(geoLocation.position, geoLocation.position, 1, geoLocation.angle);
};

export const createInstallationPointDerotationTransform = (
    floorPlan: IFloorPlanEntity | undefined,
): ((ip: IInstallationPointModel) => IInstallationPointModel) => {
    if (!floorPlan) {
        return (ip) => ip;
    }

    const transform = createDerotationTransform(floorPlan);
    const rotation = -(getFloorPlanGeoLocation(floorPlan)?.angle ?? 0);

    return (ip) => ({
        ...ip,
        location: transform(ip.location),
        sensors: ip.sensors.map((sensor) =>
            transformInstallationPointSensor(transform, rotation, sensor),
        ),
        speaker: ip.speaker && transformInstallationPointSpeaker(rotation, ip.speaker),
        radar: ip.radar && transformInstallationPointRadar(rotation, ip.radar),
        panRange: ip.panRange && transformInstallationPointPanRange(rotation, ip.panRange),
    });
};
