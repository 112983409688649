import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Box, Grid, Stack, Text, Viewable } from 'app/components';
import { ProductItem } from './ProductItem.container';
import { t } from 'app/translate';
import type { PiaId } from 'app/core/pia';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../services';
import {
    getDesiredImportantACAP,
    getRecommendedProductIds,
    getSelectedProductId,
} from '../selectors';
import { css } from '@emotion/css';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import type { IAcap } from '../models/cameras/IAcap';

const headerStyle = css`
    position: relative;
    display: flex;
    justify-content: center;
    height: ${SpacingsEnum.panel};
    width: 100%;
`;

const lineStyle = css`
    height: ${SpacingsEnum.half};
    width: 100%;
    border-bottom: 1px solid ${ColorsEnum.grey3};
`;

const textStyle = css`
    position: absolute;
    background-color: ${ColorsEnum.white};
    padding: 0 ${SpacingsEnum.half};
`;

export const TopRecommendations: React.FC = () => {
    const recommendedProductIds = useSelector<IStoreState, PiaId[]>(getRecommendedProductIds);
    const selectedId = useSelector<IStoreState, PiaId | null>(getSelectedProductId);
    const importantAcap = useSelector<IStoreState, IAcap | undefined>(getDesiredImportantACAP);

    const [actionService] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );

    if (recommendedProductIds.length < 2) {
        return null;
    }
    return (
        <Viewable
            onBecomesVisible={() => {
                actionService.setTopRecommendationVisible(true);
            }}
            onBecomesInvisible={() => {
                actionService.setTopRecommendationVisible(false);
            }}
        >
            <Box paddingY="half">
                <Stack vertical spacing="quart" notFullWidth>
                    <div className={headerStyle}>
                        <div className={lineStyle} />
                        <div className={textStyle}>
                            <Text style="semibold">
                                {importantAcap
                                    ? t.cameraSelectorTopRecommendationsFor(
                                          importantAcap.officialFullName,
                                      )
                                    : t.cameraSelectorTopRecommendations}
                            </Text>
                        </div>
                    </div>
                    <Grid spacing="quart">
                        {recommendedProductIds.map((id) => {
                            return (
                                <ProductItem
                                    key={id}
                                    productId={id}
                                    selected={selectedId === id}
                                    topRecommended
                                />
                            );
                        })}
                    </Grid>
                </Stack>
            </Box>
        </Viewable>
    );
};
