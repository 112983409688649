import * as React from 'react';
import type { IStorageSettingsEntity, Id } from 'app/core/persistence';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    StorageSettingsComponent,
    getAssociatedProfile,
    getProfileOverride,
    ProfileOverrideService,
    getCurrentProject,
} from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';

interface IStorageSettingsOwnProps {
    deviceId: Id;
}

export interface IStorageSettingsProps {
    deviceId: Id;
    profileSetting: IStorageSettingsEntity;
    overrideSettings?: Partial<IStorageSettingsEntity>;
    projectRetentionTime: number;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IStorageSettingsOwnProps,
): IStorageSettingsProps => {
    const profile = getAssociatedProfile(state, ownProps.deviceId);
    const profileOverride = getProfileOverride(state, ownProps.deviceId);
    const project = getCurrentProject(state);
    return {
        deviceId: ownProps.deviceId,
        profileSetting: profile!.storage,
        overrideSettings: profileOverride?.storage,
        projectRetentionTime: project.recordingRetentionTimeInDays,
    };
};

class DeviceStorageSettingsContainer extends React.Component<IStorageSettingsProps> {
    private profileOverrideService: ProfileOverrideService;

    constructor(props: IStorageSettingsProps) {
        super(props);
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public render() {
        return (
            <StorageSettingsComponent
                profileSetting={this.props.profileSetting}
                profileSettingOverride={this.props.overrideSettings}
                projectSettingRetentionTime={this.props.projectRetentionTime}
                onChangeRetentionTime={(retentionTime) =>
                    this.onStorageChange(retentionTime, false)
                }
                onChangeUseProjectSetting={(useProjectSetting) =>
                    this.onStorageChange(
                        useProjectSetting ? undefined : this.props.profileSetting.retentionTime,
                        useProjectSetting,
                    )
                }
            />
        );
    }

    private onStorageChange = (retentionTime?: number, useProjectSetting?: boolean) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            storage: {
                retentionTime,
                useProjectSetting,
            },
        });
    };
}
export const DeviceStorageSettings = connect(mapStateToProps)(DeviceStorageSettingsContainer);
