import type { Icons } from 'app/components';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { deviceIcons } from '../../products/components/icons/deviceIcons';
import { getPiaIdFromProps } from '../../selectors/getIdFromProps';
import { getPiaItemsRecord } from './getPiaDevices';

export const getPiaIcon = createCachedSelector(
    [getPiaItemsRecord, getPiaIdFromProps],
    (piaItems, piaId): Icons => {
        if (!piaId) return 'device';

        const piaItem = piaItems[piaId];
        if (!piaItems) return 'device';

        return deviceIcons.categoryToIcon(piaItem.category);
    },
)(toCacheKey);
