import * as React from 'react';
import type { Icons } from 'app/components';
import { Box, Stack, Border, PiaImage, Text, Icon, Opacity } from 'app/components';
import { deviceIcons } from 'app/modules/common';
import type { PiaId } from 'app/core/pia';
import type { Colors } from 'app/styles';
import type { DeviceAndSubType } from 'app/core/persistence';

const isChildDevice = (deviceType: DeviceAndSubType) => {
    return deviceType === 'sensorUnit' || deviceType === 'analogCamera';
};

interface IDeviceSummaryProps {
    name: string;
    model: string;
    quantity: number;
    deviceType: DeviceAndSubType;
    deviceSubType: DeviceAndSubType;
    productId: PiaId | null;
    color: Colors;
    isCustomCamera?: boolean;
    iconOverride?: Icons;
    disabled?: boolean;
}

export const DeviceSummary = React.forwardRef<HTMLDivElement, IDeviceSummaryProps>(
    (
        {
            name,
            model,
            quantity,
            color,
            deviceType,
            deviceSubType,
            productId,
            iconOverride,
            isCustomCamera,
            disabled = false,
        },
        ref,
    ) => {
        return (
            <>
                <Box paddingX="base" paddingLeft="half" flex="shrinkAndGrow" minWidth="0">
                    <Stack vertical notFullWidth spacing="halfQuart">
                        <Box width="150px">
                            <Text style="semibold" color="grey8" whiteSpace="nowrap">
                                {isChildDevice(deviceType) && quantity > 1 && `${quantity} × `}
                                {name}
                            </Text>
                        </Box>
                        <Box width="150px">
                            <Text style="small" color="grey6" whiteSpace="nowrap">
                                {model}
                            </Text>
                        </Box>
                    </Stack>
                </Box>

                <Box flex="none">
                    {iconOverride ? (
                        <Box
                            color="white"
                            padding="halfCell"
                            width="48px"
                            height="48px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Icon icon={iconOverride} size="xl" color="grey5" />
                        </Box>
                    ) : (
                        <Opacity disabled={disabled}>
                            <Border color={color} width={3} radius="50%">
                                <Box
                                    innerRef={ref}
                                    color="white"
                                    padding="halfCell"
                                    width="48px"
                                    height="48px"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <PiaImage
                                        icon={
                                            isCustomCamera
                                                ? 'videocam'
                                                : deviceIcons.toIcon(deviceSubType)
                                        }
                                        piaId={productId}
                                        imageSize="max"
                                        noPointerEvents
                                    />
                                </Box>
                            </Border>
                        </Opacity>
                    )}
                </Box>
            </>
        );
    },
);

DeviceSummary.displayName = 'DeviceSummary';
