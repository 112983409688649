import * as React from 'react';
import type { IWithChildren } from 'app/components';
import { Box, Border } from 'app/components';

export const ProfileEditorRow: React.FunctionComponent<IWithChildren> = ({ children }) => {
    return (
        <Border bottomWidth={1} color="grey4">
            <Box padding="base" direction="column" flex="shrinkAndGrow">
                {children}
            </Box>
        </Border>
    );
};

ProfileEditorRow.displayName = 'ProfileEditorRow';
