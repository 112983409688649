import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Stack } from 'app/components';
import { getCurrentSortOrder, getDeviceGroup } from '../selectors';
import type { DeviceGroup, SortOrder } from '../models';
import { ProductSelectorList } from './ProductSelectorList.container';
import { ProductSelectorTopPanel } from './ProductSelectorTopPanel.component';
import { ProductSelectorHeader } from './ProductSelectorHeader.container';
import { FloatingScene3d } from './filterPanels/scene/FloatingScene3d';
import { CompareBar } from './compare/CompareBar';
import { CompareModal } from './compare/CompareModal';

/**
 * The purpose of this component is to improve performance in device selector when:
 * Switching between camera tab and other tabs, as well as changing sort order in camera tab
 *
 * This is due to the fact that camera tab can contain approx. 500 items when discontinued included.
 * It has been proved that React is much faster throwing old instance and creating a new one, than re-render the current one.
 * Other than performance wise, it has no meaning, but it has major impact on user experience since ui (like sort order component)
 * gets a chance to re-render when old instance is thrown away.
 */
export const ProductSelector: React.FC = () => {
    const deviceGroup = useSelector<IStoreState, DeviceGroup>((state) => getDeviceGroup(state));
    const sortOrder = useSelector<IStoreState, SortOrder>((state) => getCurrentSortOrder(state));
    return (
        <Stack vertical spacing="none">
            {deviceGroup === 'cameras' && <ProductSelectorTopPanel />}
            <ProductSelectorHeader />
            {deviceGroup !== 'cameras' && <ProductSelectorList />}
            {deviceGroup === 'cameras' && sortOrder === 'bySeries' && <ProductSelectorList />}
            {deviceGroup === 'cameras' && sortOrder === 'byFov' && <ProductSelectorList />}
            {deviceGroup === 'cameras' && sortOrder === 'byName' && <ProductSelectorList />}
            {deviceGroup === 'cameras' && sortOrder === 'byPriceLowToHigh' && (
                <ProductSelectorList />
            )}
            {deviceGroup === 'cameras' && sortOrder === 'byPriceHighToLow' && (
                <ProductSelectorList />
            )}
            {deviceGroup === 'cameras' && sortOrder === 'byPowerConsumption' && (
                <ProductSelectorList />
            )}
            <FloatingScene3d />
            <CompareBar />
            <CompareModal />
        </Stack>
    );
};

ProductSelector.displayName = 'ProductSelector';
