import type { IStoreState } from 'app/store';
import type { Colors } from 'app/styles';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { t } from 'app/translate';
import type { IApplicationItem } from 'app/modules/common';

interface IBadgeState {
    boxColor: Colors;
    textColor: Colors;
    text: string;
}

/** Sets text for application card badge */
const getText = (application: IApplicationItem): string => {
    const { isIncluded, isELicense, discontinued } = application;
    if (discontinued) return t.discontinued;
    if (isIncluded) return t.applicationSelectorIncluded;
    if (isELicense) return t.applicationSelectorELicense;
    return t.applicationSelectorPaperLicense;
};

/** Sets box and text color for application card badge */
const getColors = (
    /** The application represented in the card */
    application: IApplicationItem,
    /** Whether or not the application's license type matches the preferred license type */
    isPreferredLicenseType: boolean,
    isAdded: boolean,
): { boxColor: Colors; textColor: Colors } => {
    if (application.discontinued || !isPreferredLicenseType)
        return { boxColor: 'red', textColor: 'white' };
    if (isAdded) return { boxColor: 'yellow2', textColor: 'yellow7' };
    if (application.isIncluded) return { boxColor: 'green', textColor: 'white' };
    return { boxColor: 'blue1', textColor: 'blue4' };
};

/** Retrieves styling properties for application card badge */
export const useBadgeState = (application: IApplicationItem, isAdded: boolean) => {
    const [badgeState, setBadgeState] = useState<IBadgeState>({
        boxColor: 'white',
        textColor: 'white',
        text: '',
    });

    /** Checks if application's license type matches the preferred license type. */
    const isPreferredLicenseType = useSelector<IStoreState, boolean>(
        (state) =>
            application.isIncluded ||
            state.addonSelector.isELicensePreferred === application.isELicense,
    );

    useEffect(() => {
        const { boxColor, textColor } = getColors(application, isPreferredLicenseType, isAdded);
        const text = getText(application);
        setBadgeState({ boxColor, textColor, text });
    }, [application, isAdded, isPreferredLicenseType]);

    return badgeState;
};
