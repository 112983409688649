import { IProductItem } from './../models/IProductItem';
import { eventTracking } from 'app/core/tracking';
import { MAX_COMPARE_COUNT } from './../state/DeviceSelector.reducer';
import { injectable } from 'inversify';
import { ActionCreator, IAction, ThunkAction } from 'app/store';
import { PiaId, PiaItemPacCategory, PiaRelationService } from 'app/core/pia';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';
import type {
    ICameraPropertiesFilterEntity,
    IInstallationPointEntity,
    IItemEntity,
    ISpeakerPropertiesFilterEntity,
    ISustainabilityFilter,
} from 'app/core/persistence';
import {
    ItemService,
    IPersistence,
    Id,
    CurrentProjectService,
    deviceTypeCheckers,
    LensSelectorService,
} from 'app/core/persistence';
import type {
    IAccessControlFilter,
    ISpeakerFilter,
    IOtherDeviceFilter,
    ITwoNFilter,
    ICameraFilter,
    ISensorUnitFilter,
    IDesiredSensorUnit,
    ILensToAdd,
    IPiaIdWithModel,
} from '../models';
import {
    getDefaultSpeakerFilter,
    IWearablesFilter,
    IDeviceGroupInfo,
    SortOrder,
    getDefaultCameraFilter,
    DeviceGroup,
} from '../models';

import { DeviceSelectorActions } from '../state';
import {
    getCurrentProject,
    getCurrentProjectCustomInstallationHeight,
    getCurrentProjectUnitSystem,
    getInstallationPointsArray,
    getInstallationPointsForItem,
    getNumberOfConnectableChildren,
    getOnlyMultipackForPiaItem,
    getPiaCameraForProductId,
    getPiaItemForProductId,
    getChannelInformation,
    DoorControllerService,
    IAddProductProps,
    TwoNDevicesService,
    desiredCameraUtils,
    IEncoderFilter,
    EncodersService,
    OtherDevicesService,
    SpeakersService,
    IMainUnitFilter,
    MainUnitsService,
    IDesiredCamera,
    combineCameraFilterFromAllInstallationPoints,
    VirtualProductService,
    CamerasService,
} from 'app/modules/common';
import {
    getCalculatedSpeakerQuantity,
    getDeviceGroup,
    getSelectedEncoderChannels,
} from '../selectors';
import { t } from 'app/translate';
import { toaster } from 'app/toaster';
import { UnreachableCaseError } from 'axis-webtools-util';
import { WearablesService } from './Wearables.service';
import { isEqual } from 'lodash-es';
import { History } from 'app/NavigateSetter';
import { AccessoryPersistenceService } from 'app/modules/accessorySelector';
import { PacDeviceService } from 'app/modules/reports/installationReport/services';

@injectable()
export class DeviceSelectorActionService {
    constructor(
        private otherDevicesService: OtherDevicesService,
        private camerasService: CamerasService,
        private speakersService: SpeakersService,
        private mainUnitsService: MainUnitsService,
        private encodersService: EncodersService,
        private twoNDevicesService: TwoNDevicesService,
        private wearablesService: WearablesService,
        private currentProjectService: CurrentProjectService,
        private lensSelectorService: LensSelectorService,
        private itemService: ItemService,
        private piaRelationService: PiaRelationService,
        private doorControllerService: DoorControllerService,
        private virtualProductService: VirtualProductService,
        private accessoryPersistenceService: AccessoryPersistenceService,
        private pacDeviceService: PacDeviceService,
    ) {}

    @ActionCreator()
    public setSearchText(newSearchText: string) {
        return {
            type: DeviceSelectorActions.SetSearchText,
            payload: newSearchText,
        };
    }

    @ActionCreator()
    public setFilterPanelExpanded(expanded: boolean) {
        return {
            type: DeviceSelectorActions.SetFilterPanelExpanded,
            payload: expanded,
        };
    }

    @ActionCreator()
    public resetFilters() {
        return {
            type: DeviceSelectorActions.ResetFilters,
            payload: null,
        };
    }

    @ActionCreator()
    public setDefaultFilters(editDeviceItem?: IPersistence<IItemEntity>): ThunkAction {
        return (dispatch, getState) => {
            const installationPoints = getInstallationPointsForItem(
                getState(),
                editDeviceItem?._id,
            );

            if (
                editDeviceItem &&
                installationPoints.length &&
                editDeviceItem.properties.camera &&
                !editDeviceItem.properties.camera.filter.isFilterChanged
            ) {
                editDeviceItem.properties.camera.filter =
                    this.getCameraFilterFromInstallationPoints(
                        editDeviceItem.properties.camera.filter,
                        installationPoints,
                    );
            }

            if (
                editDeviceItem &&
                installationPoints.length &&
                editDeviceItem.properties.sensorUnit &&
                !editDeviceItem.properties.sensorUnit.filter.isFilterChanged
            ) {
                editDeviceItem.properties.sensorUnit.filter =
                    this.getCameraFilterFromInstallationPoints(
                        editDeviceItem.properties.sensorUnit.filter,
                        installationPoints,
                    );
            }

            if (
                editDeviceItem &&
                installationPoints.length &&
                editDeviceItem.properties.speaker &&
                !editDeviceItem.properties.speaker.filter.isFilterChanged
            ) {
                editDeviceItem.properties.speaker.filter =
                    this.getSpeakerFilterFromInstallationPoints(
                        editDeviceItem.properties.speaker.filter,
                        installationPoints,
                    );
            }

            if (editDeviceItem && deviceTypeCheckers.isSensorUnit(editDeviceItem)) {
                const desiredSensorUnitProps = desiredCameraUtils.convertPropertiesToDesiredCamera(
                    editDeviceItem.properties.sensorUnit.filter,
                );
                return this.updateDesiredSensorUnit(desiredSensorUnitProps);
            }

            if (editDeviceItem && deviceTypeCheckers.isCamera(editDeviceItem)) {
                const desiredCameraProps = desiredCameraUtils.convertPropertiesToDesiredCamera(
                    editDeviceItem.properties.camera.filter,
                );
                return this.updateDesiredCamera(desiredCameraProps);
            }

            if (editDeviceItem && deviceTypeCheckers.isSpeaker(editDeviceItem)) {
                return this.updateSpeakerFilter(editDeviceItem.properties.speaker.filter);
            }

            if (editDeviceItem && deviceTypeCheckers.isMainUnit(editDeviceItem)) {
                return this.updateMainUnitFilter(editDeviceItem.properties.mainUnit.filter);
            }

            if (editDeviceItem && deviceTypeCheckers.isEncoder(editDeviceItem)) {
                return this.updateEncoderFilter(editDeviceItem.properties.encoder.filter);
            }

            dispatch({
                type: DeviceSelectorActions.SetDefaultFilters,
                payload: this.currentProjectService.getProjectEntity(),
            });
        };
    }

    @ActionCreator()
    public setDefaultCameraFilter(): IAction<ICameraFilter> {
        return {
            type: DeviceSelectorActions.SetCameraFilter,
            payload: getDefaultCameraFilter(
                this.currentProjectService.getProjectUnitSystem(),
                this.currentProjectService.getProjectCustomInstallationHeight(),
            ),
        };
    }

    @ActionCreator()
    public setDefaultSpeakerFilter(): IAction<ISpeakerFilter> {
        return {
            type: DeviceSelectorActions.SetSpeakerFilter,
            payload: getDefaultSpeakerFilter(
                this.currentProjectService.getProjectCustomInstallationHeight(),
            ),
        };
    }

    @ActionCreator()
    public setDeviceGroupInfo(deviceGroupInfo: IDeviceGroupInfo): IAction<IDeviceGroupInfo> {
        return {
            type: DeviceSelectorActions.SetDeviceGroupInfo,
            payload: deviceGroupInfo,
        };
    }

    @ActionCreator()
    public updateWearablesFilter(wearablesFilter: IWearablesFilter): IAction<IWearablesFilter> {
        this.trackFilterChange(wearablesFilter, 'Wearables filter');
        return {
            type: DeviceSelectorActions.UpdateWearablesFilter,
            payload: wearablesFilter,
        };
    }

    @ActionCreator()
    public updateDesiredCamera(
        desiredCamera: Partial<IDesiredCamera>,
    ): IAction<Partial<IDesiredCamera>> {
        this.trackFilterChange(desiredCamera, 'Desired camera');
        return {
            type: DeviceSelectorActions.UpdateDesiredCamera,
            payload: desiredCamera,
        };
    }

    @ActionCreator()
    public updateDesiredSensorUnit(
        desiredSensorUnit: Partial<IDesiredSensorUnit>,
    ): IAction<Partial<IDesiredSensorUnit>> {
        this.trackFilterChange(desiredSensorUnit, 'Desired sensor unit');
        return {
            type: DeviceSelectorActions.UpdateDesiredSensorUnit,
            payload: desiredSensorUnit,
        };
    }

    @ActionCreator()
    public updateSensorUnitFilter(
        sensorUnitFilter: Partial<ISensorUnitFilter>,
    ): IAction<Partial<ISensorUnitFilter>> {
        this.trackFilterChange(sensorUnitFilter, 'Sensor unit filter');
        return {
            type: DeviceSelectorActions.UpdateSensorUnitFilter,
            payload: sensorUnitFilter,
        };
    }

    @ActionCreator()
    public setIsOperationalTemperatureFilterUsed(active: boolean): IAction<boolean> {
        active && eventTracking.logUserEvent('Camera Selector', 'Operational temperature');
        return {
            type: DeviceSelectorActions.SetIsOperationalTemperatureFilterUsed,
            payload: active,
        };
    }

    @ActionCreator()
    public updateCameraFilter(
        cameraFilter: Partial<ICameraFilter>,
    ): IAction<Partial<ICameraFilter>> {
        this.trackFilterChange(cameraFilter, 'Camera filter');
        return {
            type: DeviceSelectorActions.UpdateCameraFilter,
            payload: cameraFilter,
        };
    }

    @ActionCreator()
    public updateMainUnitFilter(
        mainUnitFilter: Partial<IMainUnitFilter>,
    ): IAction<Partial<IMainUnitFilter>> {
        this.trackFilterChange(mainUnitFilter, 'Main unit filter');
        return {
            type: DeviceSelectorActions.UpdateMainUnitFilter,
            payload: mainUnitFilter,
        };
    }

    @ActionCreator()
    public updateEncoderFilter(
        encoderFilter: Partial<IEncoderFilter>,
    ): IAction<Partial<IEncoderFilter>> {
        this.trackFilterChange(encoderFilter, 'Encoder filter');
        return {
            type: DeviceSelectorActions.UpdateEncoderFilter,
            payload: encoderFilter,
        };
    }

    @ActionCreator()
    public updateAccessControlFilter(
        accessControlFilter: Partial<IAccessControlFilter>,
    ): IAction<Partial<IAccessControlFilter>> {
        this.trackFilterChange(accessControlFilter, 'Access control filter');
        return {
            type: DeviceSelectorActions.UpdateAccessControlFilter,
            payload: accessControlFilter,
        };
    }

    @ActionCreator()
    public updateSpeakerFilter(
        speakerFilter: Partial<ISpeakerFilter>,
    ): IAction<Partial<ISpeakerFilter>> {
        this.trackFilterChange(speakerFilter, 'Speaker filter');
        return {
            type: DeviceSelectorActions.UpdateSpeakerFilter,
            payload: speakerFilter,
        };
    }

    @ActionCreator()
    public resetSpeakerFilter(): ThunkAction {
        return (dispatch: any, getState) => {
            const state = getState();
            const installationHeight = getCurrentProjectCustomInstallationHeight(state);
            const defaultSpeakerFilter = getDefaultSpeakerFilter(installationHeight);

            return dispatch({
                type: DeviceSelectorActions.ResetSpeakerFilter,
                payload: defaultSpeakerFilter,
            });
        };
    }

    @ActionCreator()
    public updateOtherDeviceFilter(
        otherDeviceFilter: Partial<IOtherDeviceFilter>,
    ): IAction<Partial<IOtherDeviceFilter>> {
        this.trackFilterChange(otherDeviceFilter, 'Other device filter');
        return {
            type: DeviceSelectorActions.UpdateOtherDeviceFilter,
            payload: otherDeviceFilter,
        };
    }

    @ActionCreator()
    public updateTwoNFilter(twoNFilter: Partial<ITwoNFilter>): IAction<Partial<ITwoNFilter>> {
        this.trackFilterChange(twoNFilter, '2N filter');
        return {
            type: DeviceSelectorActions.UpdateTwoNFilter,
            payload: twoNFilter,
        };
    }

    @ActionCreator()
    public updateSustainabilityFilter(
        sustainabilityFilter: Partial<ISustainabilityFilter>,
        deviceType: DeviceGroup,
    ): IAction<Partial<ISustainabilityFilter>> {
        switch (deviceType) {
            case 'accessControls':
                return this.updateAccessControlFilter(sustainabilityFilter);
            case 'cameras':
                return this.updateCameraFilter(sustainabilityFilter);
            case 'encoders':
                return this.updateEncoderFilter(sustainabilityFilter);
            case 'mainunits':
                return this.updateMainUnitFilter(sustainabilityFilter);
            case 'other':
                return this.updateOtherDeviceFilter(sustainabilityFilter);
            case 'sensorunits':
                return this.updateSensorUnitFilter(sustainabilityFilter);
            case 'speakers':
                return this.updateSpeakerFilter(sustainabilityFilter);
            case 'twoNProducts':
                return this.updateTwoNFilter(sustainabilityFilter);
            case 'wearables':
                return this.updateWearablesFilter(sustainabilityFilter);
            default:
                throw new UnreachableCaseError(
                    deviceType,
                    `${deviceType} doesn't have a sustainability filter`,
                );
        }
    }

    @ActionCreator()
    public setSortOrder(sortOrder: SortOrder): IAction<SortOrder> {
        return {
            type: DeviceSelectorActions.SetSortOrder,
            payload: sortOrder,
        };
    }

    @ActionCreator()
    public setSelectedProductId(productId: PiaId | null): IAction<PiaId | null> {
        return {
            type: DeviceSelectorActions.SetSelectedProductId,
            payload: productId,
        };
    }

    @ActionCreator()
    public toggleDiscontinued(includeDiscontinued?: boolean): IAction<boolean | undefined> {
        includeDiscontinued &&
            eventTracking.logUserEvent('Camera Selector', 'Include discontinued');
        return {
            type: DeviceSelectorActions.ToggleDiscontinued,
            payload: includeDiscontinued,
        };
    }

    @ActionCreator()
    public setEditDeviceId(deviceId: Id | null): IAction<Id | null> {
        return {
            type: DeviceSelectorActions.SetEditDeviceId,
            payload: deviceId,
        };
    }

    @ActionCreator()
    public setParentId(parentId: Id | null): IAction<Id | null> {
        return {
            type: DeviceSelectorActions.SetParentId,
            payload: parentId,
        };
    }

    @ActionCreator()
    public setIsAddingOrUpdating(isAddingOrUpdating: boolean): IAction<boolean> {
        return {
            type: DeviceSelectorActions.SetIsAddingOrUpdating,
            payload: isAddingOrUpdating,
        };
    }

    @ActionCreator()
    public addOrUpdateDevice(
        productId: PiaId | null,
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
        accessoryLens?: ILensToAdd | null,
    ): ThunkAction {
        return (dispatch: any, getState) => {
            this.setIsAddingOrUpdating(true);
            let newItemId: undefined | Id;
            const state = getState();
            const deviceGroup = getDeviceGroup(state);
            const piaItem = getPiaItemForProductId(state, productId);
            const { defaultProfile } = getCurrentProject(state);
            const lensRelationProperties =
                accessoryLens && productId
                    ? this.piaRelationService.getRelationProperties(
                          productId,
                          accessoryLens.lensPiaId,
                      )
                    : undefined;

            const installationPointsForDevice = itemToEdit
                ? getInstallationPointsArray(state).filter((ip) =>
                      ip.path.includes(itemToEdit?._id || ''),
                  )
                : [];

            // Remove virtual products for item if model has changed
            if (itemToEdit && itemToEdit.productId !== productId) {
                this.virtualProductService.removeAllVirtualProducts(itemToEdit._id);
            }

            let payload: Promise<any> = Promise.resolve();
            switch (deviceGroup) {
                case 'cameras':
                    const cameraFilter = state.deviceSelector.cameraFilter;
                    const installationHeight = getCurrentProjectCustomInstallationHeight(state);

                    payload = this.camerasService
                        .addOrUpdateDevice(
                            piaItem as IPiaCamera,
                            defaultProfile,
                            cameraFilter.desiredCamera,
                            installationHeight,
                            installationPointsForDevice,
                            itemToEdit,
                            newItemProps,
                            lensRelationProperties,
                        )
                        ?.then((addedOrUpdatedDevice) => (newItemId = addedOrUpdatedDevice?._id));

                    newItemId ||= itemToEdit?._id;
                    break;
                case 'sensorunits':
                    throw new Error('Sensor units should be updated as child device');
                case 'speakers':
                    const speakerDefaultFilter = getDefaultSpeakerFilter(
                        getCurrentProjectCustomInstallationHeight(getState()),
                    );
                    const speakerFilter = {
                        ...state.deviceSelector.speakerFilter,
                        isFilterChanged:
                            state.deviceSelector.speakerFilter.isFilterChanged ||
                            !isEqual(state.deviceSelector.speakerFilter, speakerDefaultFilter),
                    };

                    // If changing speaker model, the calculated solution quantity, if any,
                    // has higher prio, than than the quantity persisted with the previous device.
                    if (itemToEdit) {
                        const solutionQuantity = getCalculatedSpeakerQuantity(
                            state,
                            itemToEdit._id,
                        );
                        itemToEdit.quantity =
                            solutionQuantity > 0 ? solutionQuantity : itemToEdit.quantity;
                    }
                    payload = this.speakersService.addOrUpdateDevice(
                        productId,
                        speakerFilter,
                        installationPointsForDevice,
                        itemToEdit,
                        newItemProps,
                    );
                    break;
                case 'accessControls':
                    // Only door controllers are supported as generic devices.
                    // A generic access control that is added is assumed to be a door controller, since there is no pia category for it yet
                    if (!piaItem || piaItem.category === 'doorcontrollers') {
                        payload = this.doorControllerService.addOrUpdateDevice(
                            productId,
                            itemToEdit,
                            newItemProps,
                        );
                    } else if (
                        piaItem &&
                        piaItem.category === PiaItemPacCategory.IORELAYS &&
                        itemToEdit
                    ) {
                        payload = this.pacDeviceService.updateIoRelay(piaItem.id, itemToEdit);
                    } else {
                        payload = this.otherDevicesService.addOrUpdateDevice(
                            piaItem.id,
                            piaItem.category,
                            piaItem.categories,
                            defaultProfile,
                            installationPointsForDevice,
                            itemToEdit,
                            newItemProps,
                        );
                    }
                    break;
                case 'other':
                    if (!piaItem) {
                        throw new Error('Other devices does not support generic devices.');
                    }
                    payload = this.otherDevicesService.addOrUpdateDevice(
                        piaItem.id,
                        piaItem.category,
                        piaItem.categories,
                        defaultProfile,
                        installationPointsForDevice,
                        itemToEdit,
                        newItemProps,
                    );
                    break;
                case 'mainunits':
                    const mainUnitFilter = state.deviceSelector.mainUnitFilter;
                    payload = this.mainUnitsService.addOrUpdateDevice(
                        productId,
                        mainUnitFilter,
                        itemToEdit,
                        newItemProps,
                    );
                    break;
                case 'encoders':
                    const channels = getSelectedEncoderChannels(state, productId ?? undefined);
                    const encoderFilter = state.deviceSelector.encoderFilter;
                    payload = this.encodersService.addOrUpdateDevice(
                        productId,
                        encoderFilter,
                        defaultProfile,
                        channels,
                        itemToEdit,
                        newItemProps,
                    );
                    break;
                case 'wearables':
                    if (!piaItem) {
                        throw new Error('Wearables does not support generic devices.');
                    }
                    payload = this.wearablesService.addOrUpdateDevice(
                        piaItem.id,
                        piaItem.category,
                        itemToEdit,
                        newItemProps,
                    );
                    break;
                case 'twoNProducts':
                    if (!piaItem) {
                        throw new Error('2 N products does not support generic devices.');
                    }
                    payload = this.twoNDevicesService.addOrUpdateDevice(
                        piaItem.id,
                        piaItem.category,
                        itemToEdit,
                        newItemProps,
                        installationPointsForDevice,
                    );
                    break;
                default:
                    throw new UnreachableCaseError(deviceGroup);
            }

            payload
                .then(async (item: IPersistence<IItemEntity>) => {
                    // Show toaster if new item added, not if updated.
                    if (!itemToEdit) {
                        toaster.success(
                            t.deviceAddedSuccessHeader,
                            t.deviceAddedSuccessMessage(
                                piaItem ? piaItem.name : t.unSpecifiedModel,
                            ),
                        );
                    } else {
                        History.navigate(-1);
                    }
                    /** Add accessories like cables, etc */
                    (newItemProps?.accessories ?? []).forEach((piaId) =>
                        this.accessoryPersistenceService.setItem(item._id, piaId, 'accessory'),
                    );
                    //* Add or remove lens(es)
                    if (newItemId) {
                        const onlyMultipackBarebone = getOnlyMultipackForPiaItem(
                            getState(),
                            accessoryLens?.barebonePiaId,
                        );
                        await this.addLensAndSetBarebone(
                            accessoryLens,
                            newItemId,
                            !onlyMultipackBarebone,
                        );
                    }
                })
                .then(() => this.setIsAddingOrUpdating(false))
                .catch(() => {
                    if (!itemToEdit) {
                        toaster.error(
                            t.couldNotAddDevice,
                            t.deviceAddedErrorMessage(piaItem ? piaItem.name : t.unSpecifiedModel),
                        );
                    }
                });

            return dispatch({
                type: DeviceSelectorActions.AddOrUpdateDevice,
                payload,
            });
        };
    }

    @ActionCreator()
    public addOrUpdateChildDevice(
        productId: PiaId | null,
        parentId: Id,
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
        accessoryLens?: ILensToAdd | null,
    ): ThunkAction {
        return (dispatch: any, getState) => {
            let newItemId: undefined | Id;
            const state = getState();
            const deviceGroup = getDeviceGroup(state);
            const piaItem = getPiaCameraForProductId(state, productId);
            const lensRelationProperties =
                accessoryLens && productId
                    ? this.piaRelationService.getRelationProperties(
                          productId,
                          accessoryLens.lensPiaId,
                      )
                    : undefined;

            const installationPointsForDevice = itemToEdit
                ? getInstallationPointsArray(state).filter((ip) =>
                      ip.path.includes(itemToEdit?._id || ''),
                  )
                : [];

            if (deviceGroup === 'sensorunits') {
                const availableChannels = parentId
                    ? getNumberOfConnectableChildren(state, parentId)
                    : 0;
                const usedChannels = parentId
                    ? getChannelInformation(state, parentId).usedChannels
                    : 0;
                const unitSystem = getCurrentProjectUnitSystem(state);
                const installationHeight = getCurrentProjectCustomInstallationHeight(state);
                const { defaultProfile } = getCurrentProject(state);
                const sensorUnitFilter = state.deviceSelector.sensorUnitFilter;

                let payload: Promise<any> = Promise.resolve();

                if (newItemProps) {
                    if (usedChannels < availableChannels) {
                        payload = this.camerasService
                            .addSensorUnit(
                                parentId,
                                (piaItem as IPiaSensorUnit) ?? null,
                                defaultProfile,
                                unitSystem,
                                installationHeight,
                                sensorUnitFilter.desiredSensorUnit,
                                newItemProps,
                            )
                            .then((addedSensorUnit) => {
                                newItemId = addedSensorUnit._id;
                                /** Add accessories like cables, etc */
                                return Promise.all(
                                    (newItemProps.accessories ?? []).map((piaId) =>
                                        this.accessoryPersistenceService.setItem(
                                            addedSensorUnit._id,
                                            piaId,
                                            'accessory',
                                        ),
                                    ),
                                );
                            });
                    } else {
                        return toaster.info(t.couldNotAddDevice, t.childDeviceNotAddedMessage);
                    }
                } else if (itemToEdit) {
                    payload = this.camerasService
                        .updateCamera(
                            piaItem as IPiaSensorUnit,
                            sensorUnitFilter.desiredSensorUnit,
                            itemToEdit,
                            installationHeight,
                            installationPointsForDevice,
                            parentId,
                            lensRelationProperties,
                        )
                        .then((editedSensorUnit) => (newItemId = editedSensorUnit?._id));
                }

                const allChannelsInUse =
                    newItemProps && newItemProps.quantity + usedChannels >= availableChannels;

                payload
                    .then(async () => {
                        // Show toaster if new item added unless all channels are used up, not if updated.
                        if (!itemToEdit && !allChannelsInUse) {
                            toaster.success(
                                t.deviceAddedSuccessHeader,
                                t.deviceAddedSuccessMessage(piaItem?.name ?? t.unSpecifiedModel),
                            );
                        } else {
                            History.navigate(-1);
                        }
                        //* Add or remove lens(es)
                        if (newItemId) {
                            const onlyMultipackBarebone = getOnlyMultipackForPiaItem(
                                getState(),
                                accessoryLens?.barebonePiaId,
                            );
                            await this.addLensAndSetBarebone(
                                accessoryLens,
                                newItemId,
                                !onlyMultipackBarebone,
                            );
                        }
                    })
                    .catch(() => {
                        if (!itemToEdit) {
                            toaster.error(
                                t.couldNotAddDevice,
                                t.deviceAddedErrorMessage(
                                    piaItem ? piaItem.name : t.unSpecifiedModel,
                                ),
                            );
                        }
                    });

                return dispatch({
                    type: DeviceSelectorActions.AddOrUpdateDevice,
                    payload,
                });
            }
        };
    }

    @ActionCreator()
    public setTopRecommendationVisible(visible: boolean) {
        return {
            type: DeviceSelectorActions.SetTopRecommendationVisible,
            payload: visible,
        };
    }

    @ActionCreator()
    public quickAddFilterChange(value: string): IAction<string> {
        return {
            type: DeviceSelectorActions.SetSelectedQuickAddDevice,
            payload: value,
        };
    }

    private getCameraFilterFromInstallationPoints(
        filter: ICameraPropertiesFilterEntity,
        installationPoints: IPersistence<IInstallationPointEntity>[],
    ) {
        const installationPointsFilter =
            combineCameraFilterFromAllInstallationPoints(installationPoints);

        return {
            ...filter,
            horizontalFov: installationPointsFilter.horizontalFov,
            corridorFormat: installationPointsFilter.corridorFormat,
            distanceToTarget: installationPointsFilter.distanceToTarget,
            installationHeight: installationPointsFilter.installationHeight,
            targetHeight: installationPointsFilter.targetHeight,
            isSceneFilterActive: true,
        };
    }

    @ActionCreator()
    public showBigScene3d() {
        this.updateDesiredCamera({
            isSceneFilterActive: true,
        });

        eventTracking.logUserEvent('Camera Selector', 'Show 3D scene');
        return {
            type: DeviceSelectorActions.SetBigScene3dVisible,
            payload: true,
        };
    }

    @ActionCreator()
    public hideBigScene3d() {
        return {
            type: DeviceSelectorActions.SetBigScene3dVisible,
            payload: false,
        };
    }

    private getSpeakerFilterFromInstallationPoints(
        filter: ISpeakerPropertiesFilterEntity,
        installationPoints: IPersistence<IInstallationPointEntity>[],
    ) {
        const installationHeight = Math.max(...installationPoints.map((ip) => ip.height));
        const basicSolution = installationPoints.every((ip) => ip.speaker?.settings.basicSolution);

        return {
            ...filter,
            installationHeight,
            basicSolution,
        };
    }

    @ActionCreator()
    public clearCompareSelection() {
        return {
            type: DeviceSelectorActions.ClearCompare,
            payload: false,
        };
    }

    @ActionCreator()
    public showComparison(show: boolean) {
        show && eventTracking.logUserEvent('Camera Selector', 'Show comparison');
        return {
            type: DeviceSelectorActions.ShowComparison,
            payload: show,
        };
    }

    @ActionCreator()
    public addProductToCompare(product: IProductItem): ThunkAction {
        eventTracking.logUserEvent('Camera Selector', 'Add to compare', product.name);
        return (dispatch: any, getState) => {
            const canAddToCompare =
                getState().deviceSelector.productsToCompare.length < MAX_COMPARE_COUNT;
            if (canAddToCompare) {
                dispatch({
                    type: DeviceSelectorActions.AddToCompare,
                    payload: product.productId,
                });
            } else {
                toaster.info(t.compareMaxReached(MAX_COMPARE_COUNT));
            }
        };
    }

    @ActionCreator()
    public removeProductFromCompare(productId: PiaId) {
        return {
            type: DeviceSelectorActions.RemoveFromCompare,
            payload: productId,
        };
    }

    @ActionCreator()
    public setProductToAdd(product: IPiaIdWithModel | null) {
        return {
            type: DeviceSelectorActions.SetProductToAdd,
            payload: product,
        };
    }

    /**
     * Adds lenses and sets barebone if provided with an accessory lens. If null is sent as argument
     * it will instead remove all lenses from item.
     */
    private addLensAndSetBarebone = async (
        accessoryLens: ILensToAdd | undefined | null,
        newItemId: string,
        useBarebone: boolean,
    ) => {
        if (accessoryLens === undefined) {
            return;
        }

        if (accessoryLens) {
            for (let i = 0; i < accessoryLens.numberOfSensors; i++) {
                await this.lensSelectorService.toggleLens(newItemId, accessoryLens.lensPiaId, i);
            }
            //* Set barebone by default if this option exists
            accessoryLens.barebonePiaId &&
                useBarebone &&
                (await this.itemService.setBareboneId(newItemId, accessoryLens.barebonePiaId));
        } else if (accessoryLens === null) {
            const lenses = this.currentProjectService.getDeviceChildren(newItemId, 'lenses');
            for (let i = 0; i < lenses.length; i++) {
                await this.itemService.deleteItem(lenses[i]._id);
            }
        }
    };

    private trackFilterChange(filter: any, logAction: string) {
        const filterUpdate = JSON.stringify(filter).replace('{', '').replace('}', '');
        eventTracking.logUserEvent('Camera Selector', logAction, filterUpdate);
    }
}
