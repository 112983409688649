import type { IProjectQuotationEntity } from '../../entities';

export function getDefaultProjectQuotationEntity(parentPath: string[]): IProjectQuotationEntity {
    return {
        pricesByPartNumber: {},
        customItemPrices: {},
        partnerItemPrices: {},
        header: '',
        footer: '',
        msrpToQuoteMargin: 0,
        path: parentPath,
        type: 'quotation',
        archived: false,
    };
}
