import { injectable } from 'inversify';
import { BaseRepository } from './Base.repository';
import {
    PersistenceDatabaseRepository,
    TimestampProviderService,
    EntitySettings,
    CreateEntityService,
    PersistenceMemoryRepository,
} from './persistence';
import type { IFloorPlan, IBaseEntity } from '../entities';

interface IFloorPlanBaseEntity extends IFloorPlan, IBaseEntity {
    readonly type: 'floorPlan';
}

@injectable()
export class FloorPlanRepository extends BaseRepository<IFloorPlanBaseEntity> {
    constructor(
        entitySettings: EntitySettings,
        persistenceRepository: PersistenceDatabaseRepository,
        persistenceMemoryRepository: PersistenceMemoryRepository,
        timestampProvider: TimestampProviderService,
        createEntityService: CreateEntityService,
    ) {
        super(
            entitySettings,
            persistenceRepository,

            persistenceMemoryRepository,
            timestampProvider,
            createEntityService,
        );
    }

    protected prefix() {
        return 'floorPlan';
    }
}
