import { injectable } from 'inversify';
import type React from 'react';
import type { IModalProps } from './Modal.ActionService';
import { ModalActionService } from './Modal.ActionService';

interface IConfirmDialogProps {
    header: string;
    body: React.ReactNode;
    confirmButtonText: string;
    cancelButtonText?: string;
    warning?: boolean;
}

@injectable()
export class ModalService {
    constructor(private modalActionService: ModalActionService) {}

    public onUserConfirm(value: boolean): void {
        this.modalActionService.hideModal();
        this.resolve(value);
    }

    public createConfirmDialog({
        header,
        body,
        confirmButtonText,
        cancelButtonText,
        warning,
    }: IConfirmDialogProps): () => Promise<boolean> {
        const buttons = [
            {
                text: confirmButtonText,
                value: true,
                primary: true,
            },
        ];

        if (cancelButtonText) {
            buttons.unshift({
                text: cancelButtonText,
                value: false,
                primary: false,
            });
        }

        return () =>
            this.askUserForConfirmation({
                header,
                body,
                buttons,
                warning,
            });
    }

    private resolve(_value: boolean) {
        throw Error('ModalService resolve should always be overwritten');
    }

    private askUserForConfirmation(modalProps: IModalProps): Promise<boolean> {
        this.modalActionService.showModal(modalProps);

        return new Promise<boolean>((resolve) => {
            this.resolve = resolve;
        });
    }
}
