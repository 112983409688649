import { injectable } from 'inversify';
import type { ThunkAction } from 'app/store';
import type { IAuthTokens } from '../utils/auth/authTokens';
import { loadAuthTokens, refreshAuthTokens } from '../utils/auth/authTokens';
import { CommonActionService } from './CommonAction.service';

@injectable()
export class TokenService {
    constructor(private commonActionService: CommonActionService) {}

    public async refreshTokens(tokens: IAuthTokens): Promise<ThunkAction> {
        const newTokens = await refreshAuthTokens(tokens);
        return this.commonActionService.updateTokens(newTokens);
    }

    public async getAccessToken(): Promise<string> {
        const tokens = loadAuthTokens();
        if (!tokens) {
            this.commonActionService.reauthorize();
        } else if (tokens.refreshAt <= Date.now()) {
            try {
                await this.refreshTokens(tokens);
            } catch (error) {
                this.commonActionService.reauthorize();
            }
        }
        return Promise.resolve(tokens?.accessToken || '');
    }
}
