import { t } from 'app/translate';

export enum DistanceUnit {
    Meter,
    Feet,
}

export function distanceUnitShortText(distanceUnit: DistanceUnit) {
    switch (distanceUnit) {
        case DistanceUnit.Feet:
            return t.abbreviationsGROUP.feet;
        case DistanceUnit.Meter:
            return t.abbreviationsGROUP.meter;
    }
}
