import { injectable } from 'inversify';
import type { IPersistenceRepository } from '../repositories';
import type { Migration } from './models';

@injectable()
export abstract class MigrationProviderBase {
    /**
     * The version this migration updates from
     */
    public abstract get from(): number;

    /**
     * The version this migration updates to
     */
    public abstract get to(): number;

    /**
     * Provides a migration function. This function should always return a new
     * function that can be used to migrate. The repository passed to this function
     * should never be cached outside of the scope of the returned function since
     * the repository may change at any time.
     */
    public abstract provideMigration(persistenceRepository: IPersistenceRepository): Migration;
}
