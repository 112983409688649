import type { IOptionProps } from 'app/components';
import { Box, Checkbox, Select } from 'app/components';
import type { CameraStationCenterType } from 'app/core/persistence';
import { ProjectService } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { getSelectedCenterChoice } from 'app/modules/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RecordingSelectorService } from '../../services';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';

export const AxisSelectCenter: React.FC = () => {
    const selectCenterOptions: IOptionProps[] = [
        {
            text: t.axisCameraStationCenterOneYear,
            value: 'Center1year',
        },
        {
            text: t.axisCameraStationCenterFiveYear,
            value: 'Center5years',
        },
    ];

    const disabledCenterOptions: IOptionProps[] = [
        {
            text: t.axisCameraStationCenterOneYear,
            value: 'NoCenter',
        },
    ];
    const projectService = useService(ProjectService);
    const recordingSelectorService = useService(RecordingSelectorService);
    const centerChoice = useSelector(getSelectedCenterChoice) ?? 'NoCenter';
    const isCenterEnabled = centerChoice !== 'NoCenter';

    const onChangeAxisCenter = async (type: CameraStationCenterType) => {
        await recordingSelectorService.deleteCenterLicenses();
        eventTracking.logUserEvent('System Components', 'Use center', type);
        projectService.updateCurrentProject({
            selectedCameraStationCenterType: type,
        });
    };

    const onToggleAxisCenter = async (enableCenter: boolean) => {
        if (!enableCenter) {
            await recordingSelectorService.deleteCenterLicenses();
        } else {
            eventTracking.logUserEvent('System Components', 'Use center', 'Center1year');
        }
        projectService.updateCurrentProject({
            selectedCameraStationCenterType: enableCenter ? 'Center1year' : 'NoCenter',
        });
    };

    return (
        <Box height={40} spacing="half">
            <Checkbox
                testId="toggle_center"
                selected={isCenterEnabled}
                slider
                onChange={() => onToggleAxisCenter(!isCenterEnabled)}
            />
            <Select
                testId="select_center_type"
                testIdChild="select_type_option"
                disabled={!isCenterEnabled}
                fillDropdownWidth
                fillWidth
                fullHeight
                options={isCenterEnabled ? selectCenterOptions : disabledCenterOptions}
                onChange={onChangeAxisCenter}
                value={centerChoice}
                underline
            />
        </Box>
    );
};
AxisSelectCenter.displayName = 'SelectSolutionType';
