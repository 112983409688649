import { injectable } from 'inversify';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import { MapsActions } from '../actions';
import { IFileTransferState } from '../models';

@injectable()
export class FileTransferService {
    @ActionCreator()
    public addFileTransfer(payload: number): IAction<number> {
        return {
            type: MapsActions.AddFileTransfer,
            payload,
        };
    }

    @ActionCreator()
    public updateFileTransfer(payload: IFileTransferState) {
        return {
            type: MapsActions.UpdateFileTransfer,
            payload,
        };
    }

    @ActionCreator()
    public endFileTransfer(payload: number): ThunkAction {
        return (dispatch: any, getState) => {
            dispatch({ type: MapsActions.EndFileTransfer, payload });

            const state = getState();
            const fileTransfers = state.maps.fileTransfers;
            if (
                fileTransfers.length > 0 &&
                fileTransfers.every((transfer) => transfer.loaded === transfer.total)
            ) {
                dispatch({
                    type: MapsActions.ClearFileTransfers,
                });
            }
        };
    }

    @ActionCreator()
    public clearFileTransfers(): IAction<void> {
        return {
            type: MapsActions.ClearFileTransfers,
            payload: undefined,
        };
    }
}
