import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import type { IProjectShareToken } from 'app/core/persistence';
import { getCurrentProject } from 'app/modules/common';
import type { IProjectSettingToken } from '../models';

const getGeneratedToken = (state: IStoreState) => getCurrentProject(state)?.shareToken;

export const getProjectSettingToken = createSelector([getGeneratedToken], (generatedToken) => {
    return mapGeneratedTokenToProjectSettingToken(generatedToken);
});

export function mapGeneratedTokenToProjectSettingToken(
    projectShareToken: IProjectShareToken | undefined,
): IProjectSettingToken | null {
    if (!projectShareToken) {
        return null;
    }

    const validUntil = new Date(projectShareToken.validUntil);
    const expired = new Date().getTime() > validUntil.getTime();

    return {
        expired,
        generatedAt: new Date(projectShareToken.generatedAt),
        token: projectShareToken.token,
        validUntil,
    };
}
