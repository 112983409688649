export const defaultEncoderFilter: IEncoderPropertiesFilterEntity = {
    channels: undefined,
    blade: false,
    twoWayAudio: false,
    outdoor: false,
};

export interface IEncoderPropertiesFilterEntity {
    channels: number | undefined;
    blade: boolean;
    twoWayAudio: boolean;
    outdoor: boolean;
}
