import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    getCurrentProject,
    getCurrentProjectHasOnlyDefaultMap,
    getCurrentProjectInstallationPointsArray,
    getCurrentProjectMapLocationsArray,
    getIsLocalProject,
    getUserGeolocation,
    getUserSignedIn,
} from 'app/modules/common';
import { Map, MapSignIn } from './components';
import { useMount } from 'app/hooks';
import { useService } from 'app/ioc';
import { MapsActionService } from './services';
import { getDefaultGeoMap } from './selectors';

export const MapsView: React.FC = () => {
    const hasOnlyDefaultMap = useSelector(getCurrentProjectHasOnlyDefaultMap);
    const hasMapLocations = useSelector(getCurrentProjectMapLocationsArray).length > 0;
    const hasInstallationPoints = useSelector(getCurrentProjectInstallationPointsArray).length > 0;
    const defaultGeoMap = useSelector(getDefaultGeoMap);
    const currentProject = useSelector(getCurrentProject);
    const mapsActionService = useService(MapsActionService);
    const isSignedIn = useSelector(getUserSignedIn);
    const isLocalProject = useSelector(getIsLocalProject);

    // Create default map if not exist
    useMount(async () => {
        if (!defaultGeoMap) {
            // Use location either from project or users' current location
            const location = currentProject.location ?? (await getUserGeolocation());
            mapsActionService.addMap(currentProject._id, '', location);
        }
    });

    return (
        <>
            {!isSignedIn && !isLocalProject ? (
                <MapSignIn />
            ) : (
                <Map
                    showWelcome={hasOnlyDefaultMap && !hasMapLocations && !hasInstallationPoints}
                />
            )}
        </>
    );
};

MapsView.displayName = 'MapsView';
