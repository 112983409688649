import { BufferGeometry, BufferAttribute, EdgesGeometry, Vector3, Plane, Line3 } from 'three';
import { calculateActualEdgeVectors } from '../../../utils';
import { getClosestIntersection } from '../../math';
import type { IConeGeometry } from './IConeGeometry';

const LONG_DISTANCE = 100000;

export const getWideFovCone = (
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    tiltAngle: number,
    intersectWithBlockers: (vector: Vector3) => Vector3,
    resolution = 200,
): IConeGeometry => {
    const { topVectors, rightVectors, bottomVectors, leftVectors } = calculateActualEdgeVectors(
        horizontalFov,
        verticalFov,
        tiltAngle,
        resolution,
    );

    const upperEdges = [...leftVectors, ...topVectors, ...rightVectors, bottomVectors[0]].map(
        (vector) => intersectWithBlockers(vector),
    );

    const lowerEdges = [...bottomVectors, leftVectors[0]].map((vector) =>
        intersectWithBlockers(vector),
    );

    const groundPlane = new Plane(new Vector3(0, 1, 0), cameraHeight - 0.02);

    const planes = [groundPlane];

    const camVertex = [0, 0, 0];

    // construct the geometry by adding all the vertices
    const verticeArray = [];

    for (let i = 0; i < upperEdges.length - 1; i++) {
        const lineStartOffset = 1;

        const vector = upperEdges[i];
        const nextVector = upperEdges[i + 1];

        // construct a long line from vector and straight down
        const lineToFloor = new Line3(
            vector.clone().add(new Vector3(0, lineStartOffset, 0)),
            vector.clone().add(new Vector3(0, -LONG_DISTANCE, 0)),
        );

        // construct a long line from next vector and straight down
        const nextLineToFloor = new Line3(
            nextVector.clone().add(new Vector3(0, lineStartOffset, 0)),
            nextVector.clone().add(new Vector3(0, -LONG_DISTANCE, 0)),
        );

        // find orthogonal projection on floor/cone bounds of vector
        const vectorFloor = getClosestIntersection(lineToFloor, planes);
        // find orthogonal projection on floor/cone bounds of next vector
        const nextVectorFloor = getClosestIntersection(nextLineToFloor, planes);

        // construct face between camera, and both edge vectors
        verticeArray.push(...camVertex);
        verticeArray.push(...vector.toArray());
        verticeArray.push(...nextVector.toArray());

        if (vectorFloor) {
            // construct faces between edge and floor/lower cone bound
            verticeArray.push(...vectorFloor.toArray());
            verticeArray.push(...nextVector.toArray());
            verticeArray.push(...vector.toArray());

            if (nextVectorFloor) {
                verticeArray.push(...vectorFloor.toArray());
                verticeArray.push(...nextVectorFloor.toArray());
                verticeArray.push(...nextVector.toArray());
            }
        }
    }

    // add lower faces
    for (let i = 0; i < lowerEdges.length - 1; i++) {
        const vector = lowerEdges[i];
        const nextVector = lowerEdges[i + 1];

        verticeArray.push(...camVertex);
        verticeArray.push(...vector.toArray());
        verticeArray.push(...nextVector.toArray());
    }

    const vertices = new Float32Array(verticeArray);

    const geometry = new BufferGeometry();

    geometry.setAttribute('position', new BufferAttribute(vertices, 3));

    // cancel rotation used when calculating intersections. The geometry is rotated
    // together with the axis camera model later
    geometry.rotateZ(tiltAngle);

    const edges = new EdgesGeometry(geometry, 20);
    // const edges = geometry;

    return {
        geometry,
        edges,
    };
};
