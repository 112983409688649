export enum PiaAccessoryCategory {
    AMPLIFIERS = 'amplifiers',
    ACCESSORIES = 'accessories',
    AUDIOANDIO = 'audioandio',
    BRIDGES = 'bridges',
    CABINETS = 'cabinets',
    CABLESCON = 'cablescon',
    COVERSDOMES = 'coversdomes',
    ETHERNETOVERCOAX = 'ethernetovercoax',
    HOUSINGS = 'housings',
    ILLUMINATORS = 'illuminators',
    INSTALLTOOLS = 'installtools',
    JOYSTICKSCONTR = 'joystickscontr',
    LENSES = 'lenses',
    MEDIACONVERTERS = 'mediaconverters',
    MICROPHONES = 'microphones',
    MISCELLANEOUS = 'misc',
    MODULES = 'extModules',
    MOUNTS = 'mounts',
    NETWORKSWITCHES = 'networkswitches',
    POE = 'poe',
    POEEXTENDERS = 'poeextenders',
    POWERSUPPLIES = 'powersupplies',
    SURGEPROTECTORS = 'surgeprotectors',
    PTHEADS = 'ptheads',
    READERS = 'readers',
    SERVERS = 'servers',
    STORAGE = 'storage',
    REX = 'rex',
}
