import * as React from 'react';
import { connect } from 'react-redux';
import { debounce, flatMap, isEqual } from 'lodash-es';
import type { IStoreState } from 'app/store';
import { Box, Grid, Heading, NoPrint, Stack, Viewable, Text } from 'app/components';
import type { IPiaItem, PiaId } from 'app/core/pia';
import type { IPiaItemWithPrice } from 'app/modules/common';
import type { DeviceGroup, IProductGroup, SortOrder } from '../models';
import { CHUNK_SIZE } from '../models';
import {
    getCurrentSortOrder,
    getDeviceGroup,
    getMatchingProductsCount,
    getProductsForDeviceGroup,
    getSelectedProductId,
    isSelectedProductInCurrentDeviceGroup,
    isSelectedDeviceVisibleAsRecommendation,
} from '../selectors';
import { ProductItem } from './ProductItem.container';
import { GenericItem } from './GenericItem.container';
import { ProductSelection } from './ProductSelection.container';
import { t } from 'app/translate';

interface IProductSelectorOwnProps {
    products: IProductGroup[];
    productCount: number;
    sortOrder: SortOrder;
    selectedId: PiaId | null;
}

interface IProductSelectorProps extends IProductSelectorOwnProps {
    deviceGroup: DeviceGroup;
    isSelectedProductOnCurrentTab: boolean;
    selectedDeviceIsVisibleAsRecommendation: boolean;
}

const mapStateToProps = (state: IStoreState): IProductSelectorProps => {
    return {
        products: getProductsForDeviceGroup(state),
        productCount: getMatchingProductsCount(state),
        sortOrder: getCurrentSortOrder(state),
        selectedId: getSelectedProductId(state),
        deviceGroup: getDeviceGroup(state),
        isSelectedProductOnCurrentTab: isSelectedProductInCurrentDeviceGroup(state),
        selectedDeviceIsVisibleAsRecommendation: isSelectedDeviceVisibleAsRecommendation(state),
    };
};

interface IProductSelectorState {
    limit: number;
    products: IProductGroup[];
    isSelectedProductVisible: boolean;
    canSeeBottomOfList: boolean;
    loading: boolean;
    showNoMatchIcon: boolean;
}

/**
 * This component is deliberately written as Component and not a FunctionComponent, since it has superior performance,
 * and has been used and proven in old camera selector. This component use a local variable in render function that is the growing collection of cameras,
 * and wasn't successful in creating a FunctionComponent with the same performance, and since performance is of great importance when rendering ~500 items,
 * it was rated higher than code compliance.
 */
class ProductSelectorContainer extends React.Component<
    IProductSelectorProps,
    IProductSelectorState
> {
    private selectedRef: React.RefObject<HTMLDivElement> = React.createRef();
    private loadProducts = debounce(() => {
        this.setState({ limit: CHUNK_SIZE, products: this.props.products }, this.loadChunk);
    }, 200);

    constructor(props: IProductSelectorProps) {
        super(props);
        this.state = {
            limit: 0,
            products: [],
            isSelectedProductVisible: false,
            canSeeBottomOfList: true,
            loading: false,
            showNoMatchIcon: false,
        };
    }

    public componentDidMount() {
        this.loadProducts();
    }

    public componentDidUpdate(prevProps: IProductSelectorProps) {
        if (this.props.deviceGroup !== prevProps.deviceGroup) {
            this.setState({ products: [], canSeeBottomOfList: true });
        }
        if (!isEqual(this.props.products, prevProps.products)) {
            this.loadProducts();
        }
    }

    public componentWillUnmount() {
        this.loadProducts.cancel();
    }

    public render() {
        const { sortOrder } = this.props;
        const products = this.takeNProducts(this.state.products, this.state.limit);

        return (
            <NoPrint>
                <Box direction="column" width="100%" alignItems="start">
                    {sortOrder === 'bySeries' &&
                        products.map((productGroup) => {
                            return (
                                <Box key={productGroup.group} paddingTop="base">
                                    <Stack vertical spacing="half">
                                        {productGroup.group !== 'undefined' && (
                                            <Heading>{productGroup.group}</Heading>
                                        )}
                                        <Grid spacing="quart">
                                            {this.renderProductGroup(productGroup.products)}
                                            {productGroup.products[0].piaItem.category ===
                                                'doorcontrollers' && <GenericItem />}
                                        </Grid>
                                    </Stack>
                                </Box>
                            );
                        })}
                    {sortOrder !== 'bySeries' && (
                        <Box paddingTop={products.length > 0 ? 'base' : 'none'}>
                            <Grid spacing="quart">
                                {products.map((group) => this.renderProductGroup(group.products))}
                            </Grid>
                        </Box>
                    )}
                    {products.length < 1 &&
                        this.state.showNoMatchIcon &&
                        this.renderNoMatchingDevices()}
                    {this.props.deviceGroup !== 'cameras' &&
                        this.props.deviceGroup !== 'accessControls' && <GenericItem />}
                    <ProductSelection
                        visible={this.shouldShowFloatingSelection()}
                        productId={this.props.selectedId}
                        onClickScrollTo={
                            this.canScrollToSelection()
                                ? () => this.scrollToDevice(this.props.selectedId)
                                : undefined
                        }
                    />
                    {this.state.products.length > 0 && !this.state.loading && (
                        <Viewable
                            onBecomesVisible={this.bottomOfListBecomesVisible}
                            onBecomesInvisible={this.bottomOfListBecomesInvisible}
                        />
                    )}
                </Box>
            </NoPrint>
        );
    }

    private renderProductGroup(items: IPiaItemWithPrice<IPiaItem>[]) {
        return items.map((prod) => {
            const isSelected = this.props.selectedId === prod.piaItem.id;
            return (
                <div key={prod.piaItem.id} ref={isSelected ? this.selectedRef : null}>
                    <Viewable
                        active={isSelected}
                        onBecomesVisible={() => {
                            this.setState({ isSelectedProductVisible: true });
                        }}
                        onBecomesInvisible={() => {
                            this.setState({ isSelectedProductVisible: false });
                        }}
                    >
                        <ProductItem
                            key={prod.piaItem.id}
                            productId={prod.piaItem.id}
                            selected={isSelected}
                        />
                    </Viewable>
                </div>
            );
        });
    }

    private noSearchResults = require('src/assets/images/no-search-result.svg');

    private renderNoMatchingDevices = () => (
        <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            direction="row"
            paddingTop="cell"
        >
            <Box paddingRight="cell">
                <img height="30px" src={this.noSearchResults} />
            </Box>
            <Text testId="no_matching_devices" style="caption">
                {t.quickAddNoMatch}
            </Text>
        </Box>
    );

    private shouldShowFloatingSelection() {
        return (
            this.props.isSelectedProductOnCurrentTab &&
            !(
                this.state.isSelectedProductVisible ||
                this.props.selectedDeviceIsVisibleAsRecommendation
            )
        );
    }

    private canScrollToSelection() {
        return this.getDeviceIndex(this.props.selectedId) >= 0;
    }

    private getDeviceIndex(deviceId: PiaId | null): number {
        const allProducts = flatMap(this.props.products, ({ products }) => products);
        return allProducts.findIndex(({ piaItem }) => piaItem.id === deviceId);
    }

    private scrollToDevice(deviceId: PiaId | null): void {
        const deviceIndex = this.getDeviceIndex(deviceId);

        if (deviceIndex >= 0) {
            this.setState({ limit: deviceIndex + CHUNK_SIZE }, () => {
                setTimeout(() => {
                    this.selectedRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }, 10);
            });
        }
    }

    private bottomOfListBecomesVisible = () => {
        this.setState({ canSeeBottomOfList: true }, this.loadChunk);
    };

    private bottomOfListBecomesInvisible = () => {
        this.setState({ canSeeBottomOfList: false });
    };

    private loadChunk = () => {
        if (this.state.canSeeBottomOfList && this.state.limit < this.props.productCount) {
            this.setState(
                {
                    limit: this.state.limit + CHUNK_SIZE,
                    loading: true,
                },
                () => {
                    // set loading to false again and then load another chunk if needed
                    this.setState({
                        loading: false,
                    });
                },
            );
        } else {
            this.setState({ showNoMatchIcon: this.props.products.length < 1 });
        }
    };

    private takeNProducts(productGroups: IProductGroup[], n: number): IProductGroup[] {
        const newProductGroups: IProductGroup[] = [];
        let remaining = n;

        for (const { group, products } of productGroups) {
            if (remaining <= 0) {
                break;
            }

            const newGroup = {
                group,
                products: products.slice(0, remaining),
            };
            remaining -= newGroup.products.length;

            newProductGroups.push(newGroup);
        }

        return newProductGroups;
    }
}

export const ProductSelectorList = connect(mapStateToProps)(ProductSelectorContainer);
ProductSelectorList.displayName = 'ProductSelectorList';
