import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IMapsCamera, ISensorCoverageAreaInfo } from '../../../../models';
import { ensureCameraOrUndefined } from '../../../../models';
import {
    getSelectedCoverageAreaInfo,
    getSelectedDeviceOrParentDevice,
    getSensorCoverageAreaInfo,
} from '../../../../selectors';
import { Stack, Box, Toggle, ToggleItem, Text, Icon } from 'app/components';
import type { PanoramaModes } from 'app/core/persistence';
import { ItemService, InstallationPointService, isCustomCamera } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { getCurrentProjectItem } from 'app/modules/common';
import { MapsActionService } from '../../../../services';

interface IPanoramaOptionsContainerProps {
    device?: IMapsCamera;
    canTurnOffPanorama: boolean;
    sensorCoverageAreaInfo?: ISensorCoverageAreaInfo;
}

const mapStateToProps = (storeState: IStoreState): IPanoramaOptionsContainerProps => {
    const device = ensureCameraOrUndefined(getSelectedDeviceOrParentDevice(storeState));
    const item = getCurrentProjectItem(storeState, device?.id);
    const canTurnOffPanorama = !device?.productId && !(item && isCustomCamera(item));
    const selectedCoverageAreaInfo = getSelectedCoverageAreaInfo(storeState);
    const sensorCoverageAreaInfo = getSensorCoverageAreaInfo(
        storeState,
        selectedCoverageAreaInfo?.sensorId,
    );

    return {
        device,
        canTurnOffPanorama,
        sensorCoverageAreaInfo,
    };
};

class PanoramaOptionsContainer extends React.Component<IPanoramaOptionsContainerProps> {
    private itemService: ItemService;
    private installationPointService: InstallationPointService;
    private mapsActionService: MapsActionService;

    constructor(props: IPanoramaOptionsContainerProps) {
        super(props);
        this.itemService = ServiceLocator.get(ItemService);
        this.installationPointService = ServiceLocator.get(InstallationPointService);
        this.mapsActionService = ServiceLocator.get(MapsActionService);
    }

    public render() {
        if (!this.props.device) {
            return null;
        }
        return (
            <Stack vertical justifyContent="center" spacing="half">
                <Text color="grey7" align="center" style="semibold">
                    {t.panorama}
                </Text>
                <Box>
                    <Toggle width="230px">
                        {this.props.canTurnOffPanorama && (
                            <ToggleItem
                                testId="panorama_off_tgl"
                                onClick={() => this.setPanoramaMode(false)}
                                active={this.isPanoramaModeActive(false)}
                                fluid
                            >
                                {t.off}
                            </ToggleItem>
                        )}
                        <ToggleItem
                            testId="panorama_wall_tgl"
                            onClick={() => this.setPanoramaMode('vertical')}
                            active={this.isPanoramaModeActive('vertical')}
                            fluid
                        >
                            <Icon icon="dome_wall" opaque />
                        </ToggleItem>
                        <ToggleItem
                            testId="panorama_ceiling_tgl"
                            onClick={() => this.setPanoramaMode('horizontal')}
                            active={this.isPanoramaModeActive('horizontal')}
                            fluid
                        >
                            <Icon icon="dome" opaque />
                        </ToggleItem>
                    </Toggle>
                </Box>
            </Stack>
        );
    }

    private setPanoramaMode = async (panoramaMode: PanoramaModes) => {
        if (!this.props.device) {
            return;
        }

        eventTracking.logUserEvent(
            'Maps',
            'Change panorama mode',
            `New panorama mode: ${panoramaMode}`,
        );
        await this.itemService.updatePanoramaMode(this.props.device.id, panoramaMode);

        if (!this.props.device.productId) {
            const installationPointDescendants =
                await this.installationPointService.getInstallationPointDescendants(
                    this.props.device.id,
                );
            const installationPoints = installationPointDescendants.descendants;
            const horizontalFov = !panoramaMode ? 55 : 180;
            installationPoints.forEach((installationPoint) => {
                this.installationPointService.updateInstallationPoint(installationPoint._id, {
                    ...installationPoint,
                    sensors: installationPoint.sensors.map((sensor) => ({
                        ...sensor,
                        settings: { ...sensor.settings, horizontalFov },
                    })),
                });
            });
            if (this.props.sensorCoverageAreaInfo) {
                this.mapsActionService.updateSelectedCoverageAreaInfo({
                    ...this.props.sensorCoverageAreaInfo,
                    horizontalFov,
                });
            }
        }
    };

    private isPanoramaModeActive = (mode: PanoramaModes) =>
        this.props.device?.panoramaMode === mode;
}

export const PanoramaOptions = connect(mapStateToProps)(PanoramaOptionsContainer);
