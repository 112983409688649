export enum AuthState {
    NONE,
    AUTHORIZED,
    LOADING,
}

export interface IAuth {
    state: AuthState;
    idToken?: string;
    name?: string;
}
