import * as React from 'react';
import { css, cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';

interface IFillableBarProps {
    /**
     * The amount of the bar that is filled up
     */
    partAmount: number;
    /**
     * Total amount represented by the bar
     */
    totalAmount: number;
}

/**
 * A bar that represents a percentage value from 0-100%.
 * The higher value, the more the bar is filled up.
 */
export const FillableBar: React.FunctionComponent<IFillableBarProps> = ({
    partAmount,
    totalAmount,
}) => {
    // The max function ensures that the meter is displayed correctly in the
    // (unlikely) cases of negative values or zero total storage space
    const percentageFilled = (partAmount / Math.max(totalAmount, 1)) * 100;
    const filledColor = percentageFilled >= 90 ? ColorsEnum.red : ColorsEnum.grey4;

    const barStyle = css`
        height: 8px;
        width: 200px;
        border-radius: 5px;
        background: linear-gradient(
            90deg,
            ${filledColor} ${percentageFilled}%,
            ${ColorsEnum.grey3} ${percentageFilled}%
        );
        [dir='rtl'] & {
            rotate: 180deg;
        }
    `;

    return <div className={cx(barStyle)} />;
};

FillableBar.displayName = 'FillableBar';
