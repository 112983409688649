import * as React from 'react';
import { useInView } from 'app/hooks';
import type { IExtendableComponentWithChildren } from '../../models';

const UNHIDE_TIMEOUT = 100;

interface IViewableProps extends IExtendableComponentWithChildren {
    active?: boolean;
    /**
     * The action to trigger when the component becomes visible
     */
    onBecomesVisible?(): void;
    /**
     * The action to trigger when the component becomes invisible
     */
    onBecomesInvisible?(): void;
}

/**
 * Takes an optional child and adds an
 * callbacks that are called when it becomes visible/invisible
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export const Viewable: React.FC<IViewableProps> = ({
    children,
    active = true,
    onBecomesVisible,
    onBecomesInvisible,
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const timerRef = React.useRef<number>(0);
    const isInView = useInView(active ? ref : null);
    const [hidden, setHidden] = React.useState(true);

    React.useEffect(() => {
        clearTimeout(timerRef.current);

        if (!active) {
            return;
        }

        if (isInView) {
            onBecomesVisible?.();
        } else {
            onBecomesInvisible?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView, active]);

    React.useEffect(() => {
        // wait until useInView is ready to react on visibility changes
        // and then unhide. Unfortunately there seem to be no other way
        timerRef.current = window.setTimeout(() => {
            setHidden(false);
        }, UNHIDE_TIMEOUT);

        return () => {
            clearTimeout(timerRef.current);
            setHidden(true);

            // fire onBecomesInvisible on component unmount
            onBecomesInvisible?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{
                    width: 1,
                    height: 1,
                    position: 'absolute',
                    display: hidden ? 'none' : 'block',
                }}
                ref={ref}
            />
            {children}
        </div>
    );
};

Viewable.displayName = 'Viewable';
