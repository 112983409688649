import * as React from 'react';
import { CustomItemsActionService } from './services';
import { Box, SearchField, Heading, Card, Button } from 'app/components';
import { CustomItemList } from './components';
import { t } from 'app/translate';
import { AddonSelectorActionService, PartnerOtherProducts } from '../addonSelector';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import { getCustomItemsSearchFilter } from './selectors';
import { isFeatureEnabled } from 'app/featureFlags';
import { getIsOtherSelectorOpen } from '../common';

export const CustomItems: React.FC = () => {
    const searchFilter = useSelector(getCustomItemsSearchFilter);
    const customItemsActionService = useService(CustomItemsActionService);
    const addonSelectorActionService = useService(AddonSelectorActionService);
    const showPartnerProductModal = useSelector(getIsOtherSelectorOpen);

    return (
        <Box
            testId="other_page"
            direction="column"
            height="100%"
            width="100%"
            position="relative"
            padding="doublePanel"
        >
            <Box justifyContent="between" paddingBottom="panel">
                <Heading style="title">{t.customItemsOtherVendorProducts}</Heading>
                <SearchField
                    value={searchFilter}
                    onValueChange={customItemsActionService.filterCustomItems}
                />
            </Box>
            <Card paddingX="base" paddingY="base">
                <CustomItemList />
            </Card>
            {isFeatureEnabled('other_partner_products') && (
                <Box justifyContent="end" paddingTop="base">
                    <Button
                        testId="btn_add_partner_product"
                        primary
                        onClick={() =>
                            addonSelectorActionService.setIsOtherProductSelectorOpen(true)
                        }
                    >
                        {t.addPartnerProduct}
                    </Button>
                </Box>
            )}

            {showPartnerProductModal && <PartnerOtherProducts />}
        </Box>
    );
};

CustomItems.displayName = 'CustomItems';
