function addPrefix(val: string) {
    return `PROJECT_DEVICES_2_${val}`;
}

export const ProjectDevicesActions = {
    ResetToInitialState: addPrefix('RESET_STATE'),
    SetSortOrder: addPrefix('SET_SORT_ORDER'),
    SetPanelItem: addPrefix('SET_PANEL_ITEM'),
    DeviceFilterChange: addPrefix('DEVICE_FILTER_CHANGE'),
    SetMultipleCopiesModalData: addPrefix('SET_MULTIPLE_COPIES_MODAL_DATA'),
    ToggleExpandedDevice: addPrefix('TOGGLE_EXPANDED_DEVICE'),
};
