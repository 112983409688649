import { systemAccessoriesMenuTestId, recordingMenuTestId } from 'app/routes/project';
import { injectable } from 'inversify';
import type { IAddProductProps } from 'app/modules/common';
import {
    CamerasService,
    DoorControllerService,
    EncodersService,
    getDefaultSpeakerFilter,
    getNewDeviceNameFromCategory,
    productFilters,
    MainUnitsService,
    OtherDevicesService,
    SpeakersService,
    TwoNDevicesService,
} from 'app/modules/common';
import { type IPiaCamera, type IPiaDevice, type IPiaItem, type PiaCategory } from 'app/core/pia';
import { UnreachableCaseError } from 'axis-webtools-util';
import type { DeviceType, RecordingSolutionType, SelectedVendorType } from 'app/core/persistence';
import {
    defaultEncoderFilter,
    defaultMainUnitFilter,
    CurrentProjectService,
} from 'app/core/persistence';
import { t } from 'app/translate';
import { SystemAccessoriesService } from 'app/modules/systemAccessories';
import { RecordingSelectorService } from 'app/modules/recordingSelector';
// Animation service cannot be accessed by index file in routes/project
import { ProjectMenuAnimationService } from 'app/routes/project/services';
import { toaster } from 'app/toaster';
import { defaultColors } from 'app/core/common';
import type { Colors } from 'app/styles';
import { WearablesService } from './Wearables.service';

const itemProps = (defaultName: string, defaultColor?: Colors) => {
    return {
        name: defaultName,
        quantity: 1,
        color: defaultColor ?? defaultColors.DEFAULT_DEVICE_COLOR,
    } as IAddProductProps;
};

@injectable()
export class QuickAddDeviceService {
    constructor(
        private currentProjectService: CurrentProjectService,
        private systemAccessoriesService: SystemAccessoriesService,
        private recordingSelectorService: RecordingSelectorService,
        private projectMenuAnimationService: ProjectMenuAnimationService,

        private camerasService: CamerasService,
        private otherDevicesService: OtherDevicesService,
        private encodersService: EncodersService,
        private doorControllerService: DoorControllerService,
        private mainUnitsService: MainUnitsService,
        private speakersService: SpeakersService,
        private wearablesService: WearablesService,
        private twoNDevicesService: TwoNDevicesService,
    ) {}

    public async quickAddDevice(deviceType: DeviceType, item: IPiaItem) {
        const newDefaultName = getNewDeviceNameFromCategory(item.category);

        const is2NDevice = productFilters.isVendor2N(item.properties.vendor);
        if (is2NDevice) {
            return this.quickAdd2NProduct(item.id, item.category, newDefaultName);
        }

        switch (deviceType) {
            case 'analogCamera':
            case 'door':
            case 'sensorUnit':
                return Promise.resolve('');
            case 'camera':
            case 'doorstation':
                return this.quickAddCamera(item as IPiaCamera, newDefaultName);
            case 'encoder':
                return this.quickAddEncoder(item.id, item as IPiaDevice, newDefaultName);
            case 'mainUnit':
                return this.quickAddMainUnit(item.id, newDefaultName);
            case 'alerter':
            case 'decoder':
            case 'systemController':
            case 'peopleCounter':
            case 'pac':
            case 'connectivitydevice':
            case 'pagingconsole':
                return this.quickAddOtherProduct(
                    item.id,
                    item.category,
                    item.categories,
                    newDefaultName,
                    defaultColors.DEFAULT_SPEAKER_COLOR,
                );
            case 'radardetector':
                return this.quickAddOtherProduct(
                    item.id,
                    item.category,
                    item.categories,
                    newDefaultName,
                    defaultColors.DEFAULT_RADAR_COLOR,
                );
            case 'doorcontroller':
                return this.quickAddDoorController(item.id, newDefaultName);
            case 'speaker':
                return this.quickAddSpeaker(item.id, newDefaultName);
            case 'bodyWornCamera':
            case 'dockingStation':
            case 'cameraExtension':
                return this.quickAddWearable(item.id, item.category, newDefaultName);
            default:
                throw new UnreachableCaseError(deviceType, 'DeviceType not added to quick add.');
        }
    }

    public async quickAddSystemAccessory(systemAccessory: IPiaItem) {
        try {
            await this.systemAccessoriesService.add(systemAccessory.id);
            this.projectMenuAnimationService.notify(systemAccessoriesMenuTestId);
            toaster.success(t.deviceAddedSuccessMessage(systemAccessory.name));
        } catch (e) {
            if (e instanceof Error) {
                toaster.error(t.deviceAddedErrorMessage(systemAccessory.name));
            } else {
                throw e;
            }
        }
    }

    public async quickAddRecordingSolutionItem(
        recordingItem: IPiaItem,
        selectedRecordingSolution: RecordingSolutionType,
        selectedVendor: SelectedVendorType | undefined,
        isSolutionEmpty: boolean,
    ) {
        try {
            const goAhead = await this.recordingSelectorService.confirmQuickAddProduct(
                isSolutionEmpty,
                selectedRecordingSolution,
                selectedVendor,
                recordingItem,
            );
            if (goAhead) {
                await this.recordingSelectorService.add(recordingItem.id);
                this.projectMenuAnimationService.notify(recordingMenuTestId);
                toaster.success(t.deviceAddedSuccessMessage(recordingItem.name));
            }
        } catch (e) {
            if (e instanceof Error) {
                toaster.error(t.deviceAddedErrorMessage(recordingItem.name));
            } else {
                throw e;
            }
        }
    }

    private async quickAddCamera(device: IPiaDevice, newDefaultName?: string) {
        const camera = await this.camerasService.addCameraWithDefaults(device.id, newDefaultName);
        return camera._id;
    }

    private async quickAddEncoder(productId: number, device: IPiaDevice, defaultName: string) {
        const channels = ('channels' in device.properties && device.properties.channels) || 1;
        const { defaultProfile } = this.currentProjectService.getProjectEntity();

        this.encodersService.addOrUpdateDevice(
            productId,
            defaultEncoderFilter,
            defaultProfile,
            channels,
            undefined,
            itemProps(defaultName),
        );
    }

    private async quickAddDoorController(productId: number, defaultName: string) {
        const doorController = await this.doorControllerService.addOrUpdateDevice(
            productId,
            undefined,
            itemProps(defaultName, defaultColors.DEFAULT_DOOR_CONTROLLER_COLOR),
        );

        return doorController?._id;
    }

    private async quickAddMainUnit(productId: number, defaultName: string) {
        const mainUnit = await this.mainUnitsService.addOrUpdateDevice(
            productId,
            defaultMainUnitFilter,
            undefined,
            itemProps(defaultName),
        );
        return mainUnit?._id;
    }

    private async quickAdd2NProduct(
        productId: number,
        category: PiaCategory,
        defaultName: string,
        defaultColor?: Colors,
    ) {
        const newDevice = await this.twoNDevicesService.addOrUpdateDevice(
            productId,
            category,
            undefined,
            itemProps(defaultName, defaultColor),
        );
        return newDevice?._id;
    }

    private async quickAddOtherProduct(
        productId: number,
        category: PiaCategory,
        categories: PiaCategory[],
        defaultName: string,
        defaultColor?: Colors,
    ) {
        const { defaultProfile } = this.currentProjectService.getProjectEntity();
        const newDevice = await this.otherDevicesService.addOrUpdateDevice(
            productId,
            category,
            categories,
            defaultProfile,
            [],
            undefined,
            itemProps(defaultName, defaultColor),
        );
        return newDevice?._id;
    }

    private async quickAddSpeaker(productId: number, defaultName: string) {
        const { unitSystem } = this.currentProjectService.getProjectEntity();
        const filter = getDefaultSpeakerFilter(unitSystem);

        const newSpeaker = await this.speakersService.addOrUpdateDevice(
            productId,
            filter,
            [],
            undefined,
            itemProps(defaultName, defaultColors.DEFAULT_SPEAKER_COLOR),
        );
        return newSpeaker?._id;
    }

    private async quickAddWearable(productId: number, category: PiaCategory, defaultName: string) {
        return this.wearablesService.addOrUpdateDevice(
            productId,
            category,
            undefined,
            itemProps(defaultName),
        );
    }
}
