import { injectable } from 'inversify';
import { t } from 'app/translate';
import type {
    Id,
    IItem,
    IPersistence,
    IItemEntity,
    IAnalogCameraItemEntity,
} from 'app/core/persistence';
import {
    ItemService,
    getDefaultProfileOverrideEntity,
    CurrentProjectService,
    InstallationPointService,
} from 'app/core/persistence';
import { PiaItemService } from 'app/core/pia';
import type { PiaId, IPiaEncoder } from 'app/core/pia';
import { ModalService } from 'app/modal';
import type { Colors } from 'app/styles';
import { AccessoryService } from '../../accessory/services';
import { IAddProductProps, IEncoderFilter } from '../models';

@injectable()
export class EncodersService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private accessoryService: AccessoryService,
        private installationPointService: InstallationPointService,
        private modalService: ModalService,
        private piaItemService: PiaItemService<IPiaEncoder>,
    ) {}

    public async addOrUpdateDevice(
        productId: PiaId | null,
        filter: IEncoderFilter,
        profileId: Id,
        channels: number | undefined,
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
    ) {
        if (itemToEdit) {
            return this.updateEncoder(productId, itemToEdit.quantity, filter, itemToEdit);
        } else if (newItemProps) {
            return this.addEncoder(productId, newItemProps, filter, channels, profileId);
        }
    }

    private getAnalogCameraItems = (encoderItemId: Id) =>
        this.currentProjectService.getDeviceChildren(
            encoderItemId,
            'analogCamera',
        ) as IPersistence<IAnalogCameraItemEntity>[];

    private async addEncoder(
        productId: PiaId | null,
        newItemProps: IAddProductProps,
        filter: IEncoderFilter,
        channels: number | undefined,
        profileId: Id,
    ) {
        const item: IItem = this.toIItem(
            newItemProps.name,
            '',
            newItemProps.notes || '',
            newItemProps.quantity,
            newItemProps.color,
            productId,
            filter.blade,
            filter.twoWayAudio,
            filter.outdoor,
            filter.channels,
        );

        const encoder = await this.itemService.addToCurrentProject(item);

        if (channels && channels > 0) {
            await this.addAnalogCamera(encoder._id, channels, profileId);
        }

        return encoder;
    }

    private async updateEncoder(
        productId: PiaId | null,
        quantity: number,
        filter: IEncoderFilter,
        itemToEdit: IPersistence<IItemEntity>,
    ) {
        const productChanged = itemToEdit.productId !== productId;

        const { descendants } = await this.installationPointService.getInstallationPointDescendants(
            itemToEdit._id,
        );
        const encodersInMap = descendants.filter((item) => !item.parentId);
        const analogCamerasInMap = descendants.filter((item) => item.parentId);

        // If quantity changed - check if below quantity in maps
        if (quantity < itemToEdit.quantity) {
            if (encodersInMap.length > quantity) {
                const removeAnalogCameras = await this.modalService.createConfirmDialog({
                    header: t.removeInstallationPointsConfirmationGROUP.header,
                    body: t.removeInstallationPointsConfirmationGROUP.body,
                    cancelButtonText: t.cancel,
                    confirmButtonText: t.change,
                })();
                if (removeAnalogCameras) {
                    await this.installationPointService.removeInstallationPoints(descendants);
                } else {
                    return;
                }
            }
        }

        // Model changed
        if (productChanged) {
            const hasIncompatibleChildren = this.accessoryService.getHasPiaItemsToRemove(
                itemToEdit._id,
                productId,
            );
            const hasIncompatibleAnalogCameras = this.hasIncompatibleAnalogCameras(
                itemToEdit._id,
                productId,
            );

            if (hasIncompatibleAnalogCameras && hasIncompatibleChildren) {
                const result = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                    t.removeAnalogCamerasAndAccessoriesConfirmationGROUP.header,
                    t.removeAnalogCamerasAndAccessoriesConfirmationBody,
                );
                if (result) {
                    await this.installationPointService.removeInstallationPoints(
                        analogCamerasInMap,
                    );
                } else {
                    return;
                }
            } else if (hasIncompatibleAnalogCameras) {
                const result = await this.modalService.createConfirmDialog({
                    header: t.removeAnalogCamerasConfirmationGROUP.header,
                    body: t.removeAnalogCamerasConfirmationGROUP.body,
                    cancelButtonText: t.cancel,
                    confirmButtonText: t.change,
                })();
                if (result) {
                    await this.installationPointService.removeInstallationPoints(
                        analogCamerasInMap,
                    );
                } else {
                    return;
                }
            } else if (hasIncompatibleChildren) {
                const result = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                );
                if (!result) {
                    return;
                }
            }
        }

        itemToEdit.properties.encoder = {
            filter: {
                blade: filter.blade,
                twoWayAudio: filter.twoWayAudio,
                channels: filter.channels,
                outdoor: filter.outdoor,
            },
        };

        const itemProps: Partial<IItem> = {
            name: itemToEdit.name,
            quantity: itemToEdit.quantity,
            description: itemToEdit.description,
            notes: itemToEdit.notes,
            productId,
            properties: itemToEdit.properties,
        };

        if (productChanged) {
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                itemToEdit._id,
                productId,
            );
        }

        if (this.hasIncompatibleAnalogCameras(itemToEdit._id, productId)) {
            await this.removeAllAnalogCameras(itemToEdit._id);
        }

        return this.itemService.updateItem(itemToEdit._id, itemProps);
    }

    private hasIncompatibleAnalogCameras = (id: Id, productId: PiaId | null): boolean => {
        const piaEncoder = productId ? this.piaItemService.get(productId).first() : undefined;
        if (!piaEncoder) {
            return true;
        }
        const analogCameras = this.getAnalogCameraItems(id);
        const channelsUsed = analogCameras.reduce((prev, cur) => {
            return prev + cur.quantity;
        }, 0);

        return channelsUsed > piaEncoder.properties.channels;
    };

    private removeAllAnalogCameras = async (encoderId: Id) => {
        const analogCameras = await this.getAnalogCameraItems(encoderId);

        await this.removeAnalogCameras(analogCameras);
        return this.itemService.getItem(encoderId);
    };

    private removeAnalogCameras = async (analogCameras: IPersistence<IAnalogCameraItemEntity>[]) =>
        Promise.all(
            analogCameras.map(async (analogCamera) =>
                this.itemService.deleteItem(analogCamera._id),
            ),
        );

    private async addAnalogCamera(encoderId: Id, quantity: number, defaultProfileId: Id) {
        const analogCameraItem = await this.itemService.addByParentId(encoderId, {
            name: t.encoderSelectorGenericAnalogCameraName,
            quantity,
            description: '',
            notes: '',
            productId: null,
            properties: {
                analogCamera: {
                    associatedProfile: defaultProfileId,
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
        });
        await this.itemService.addItemRelation(encoderId, analogCameraItem._id, 'analogCamera');
        return analogCameraItem;
    }

    private toIItem(
        name: string,
        description: string,
        notes: string,
        quantity: number,
        color: Colors,
        selectedEncoderId: number | null,
        blade: boolean = false,
        twoWayAudio: boolean = false,
        outdoor: boolean,
        channels: number | undefined = undefined,
    ): IItem {
        const model: IItem = {
            name,
            description,
            notes,
            productId: selectedEncoderId,
            quantity,
            color,
            properties: {
                encoder: {
                    filter: {
                        blade,
                        twoWayAudio,
                        channels,
                        outdoor,
                    },
                },
            },
        };

        return model;
    }
}
