import * as React from 'react';
import { ReportRoutes } from './components';
import { CommonActionService } from 'app/modules/common';
import { useService } from 'app/ioc';
import { ReportLinksMenu } from './components/ReportLinksMenu.component';
import { ContentPanel, Wrapper } from 'app/components';
import { useLocation, useParams } from 'react-router-dom';

export const Reports: React.FC = () => {
    const commonActionService = useService(CommonActionService);
    const { pathname } = useLocation();
    const projectId = useParams().projectId ?? '';

    React.useEffect(() => {
        return () => {
            commonActionService.unloadProjectItems();
        };
    }, [commonActionService]);

    return (
        <Wrapper testId="panel_reports">
            <ReportLinksMenu
                projectId={projectId}
                basePath={`project/${useParams().projectId}/reports/`}
                currentRoute={pathname}
            />
            <ContentPanel>
                <ReportRoutes />
            </ContentPanel>
        </Wrapper>
    );
};

Reports.displayName = 'Reports';
