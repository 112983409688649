import * as leaflet from 'leaflet';
import type * as React from 'react';
import { css, cx } from '@emotion/css';
import { ColorsEnum, getFontColor } from 'app/styles';
import type { IFloorPlanImage } from 'app/core/persistence';
import { DistanceUnit, distanceUnitShortText } from 'app/core/persistence';
import { useEffect, useRef } from 'react';
import {
    generateToDisplayUnitConverter,
    getCurrentProjectUnitSystem,
    utils,
} from 'app/modules/common';
import { SmallStyle, TextStyle } from 'app/components';
import { useMapContext } from '../context';
import { useSelector } from 'react-redux';

interface IMapMeasureToolProps {
    /**
     * Controls whether label should be shown or not
     */
    showLabel: boolean;
    /**
     * Optional callback fired when the distance has been changed
     */
    onDistanceChange?(distance: number): void;
}

const PIXEL_WIDTH_LABEL = 50;
const PIXEL_HEIGHT_LABEL = 15;

const labelStyle = css`
    background-color: ${ColorsEnum.devicePalette7};
    border-radius: 4px;
    line-height: 15px;
    white-space: nowrap;
    text-align: center;
    color: ${getFontColor(ColorsEnum.devicePalette7)}!important;
    padding: 4px;
    z-index: 1000 !important; // hackish but seems to be the only possibility
    // to make the label appear on top of device markers
`;

const initMarker = (latLng: leaflet.LatLng, showLabel: boolean): leaflet.Marker => {
    return leaflet.marker(latLng, {
        draggable: true,
        icon: new leaflet.Icon({
            iconUrl: showLabel
                ? `${require('src/assets/images/drag-marker-blue.svg')}`
                : `${require('src/assets/images/drag-marker.svg')}`,
            iconSize: [36, 50],
            iconAnchor: [15, 13],
        }),
    });
};

export const MapMeasureTool: React.FC<IMapMeasureToolProps> = ({ showLabel, onDistanceChange }) => {
    const { leafletMap, floorPlan } = useMapContext();

    const displayUnit =
        useSelector(getCurrentProjectUnitSystem) === 'imperial'
            ? DistanceUnit.Feet
            : DistanceUnit.Meter;

    // the two end markers
    const marker1 = useRef(initMarker(leaflet.latLng(0, 0), showLabel));
    const marker2 = useRef(initMarker(leaflet.latLng(0, 0), showLabel));

    // the line between the markers
    const line = useRef(
        leaflet.polyline([marker1.current.getLatLng(), marker2.current.getLatLng()], {
            color: showLabel ? ColorsEnum.devicePalette7 : ColorsEnum.red,
            dashArray: '4 4',
            weight: 2,
        }),
    );

    // the label (for showing the distance)
    const labelMarker = useRef(
        leaflet.marker([0, 0], {
            icon: new leaflet.DivIcon({}),
            draggable: true,
        }),
    );

    // estimate the bounds of the floor plan image if no map bounds have been set yet
    const getEstimatedBounds = (floorPlanImage: IFloorPlanImage) => {
        const { topLeft, bottomRight } = utils.getFloorPlanImageTemporaryBounds(floorPlanImage);
        return new leaflet.LatLngBounds(topLeft, bottomRight);
    };

    useEffect(() => {
        // If the floor plan does not have any bound set (not yet scaled), show the scaling tool
        // at the estimated bounds. Else show it in the current center of the map. Fixes WT-3707.

        // generate a converter function for the desired unit
        const convertToDisplayUnit = generateToDisplayUnitConverter(displayUnit);

        // copy current refs (to make sure that we clean up the right refs later)
        const leafletMarker1 = marker1.current;
        const leafletMarker2 = marker2.current;
        const leafletLine = line.current;
        const leafletLabel = labelMarker.current;

        // store the initial drag positions
        const dragStartPositions = {
            label: { lat: 0, lng: 0 },
            marker1: { lat: 0, lng: 0 },
            marker2: { lat: 0, lng: 0 },
        };

        // store the current marker positions when the label is dragged
        const onLabelMarkerDragStart = () => {
            dragStartPositions.label = labelMarker.current.getLatLng();
            dragStartPositions.marker1 = marker1.current.getLatLng();
            dragStartPositions.marker2 = marker2.current.getLatLng();
        };

        // when dragging the label, also move the end markers and line
        const onLabelMarkerPositionChanged = () => {
            const deltaLat = dragStartPositions.label.lat - labelMarker.current.getLatLng().lat;
            const deltaLng = dragStartPositions.label.lng - labelMarker.current.getLatLng().lng;
            marker1.current.setLatLng([
                dragStartPositions.marker1.lat - deltaLat,
                dragStartPositions.marker1.lng - deltaLng,
            ]);
            marker2.current.setLatLng([
                dragStartPositions.marker2.lat - deltaLat,
                dragStartPositions.marker2.lng - deltaLng,
            ]);
            line.current.setLatLngs([marker1.current.getLatLng(), marker2.current.getLatLng()]);
        };

        // when dragging a marker, recalculate the positions of all items
        const onUpdateMarker = () => {
            const begin = marker1.current.getLatLng();
            const end = marker2.current.getLatLng();
            const distance = begin.distanceTo(end);
            const displayDistance = convertToDisplayUnit(distance).toFixed(1);

            const middle = leaflet.latLng((begin.lat + end.lat) / 2, (begin.lng + end.lng) / 2);
            labelMarker.current.setLatLng(middle);
            labelMarker.current.setIcon(
                new leaflet.DivIcon({
                    className: cx(labelStyle, TextStyle, SmallStyle),
                    iconSize: [PIXEL_WIDTH_LABEL, PIXEL_HEIGHT_LABEL],
                    iconAnchor: [PIXEL_WIDTH_LABEL / 2 + 4, PIXEL_HEIGHT_LABEL / 2 + 4],
                    html: `${displayDistance}${distanceUnitShortText(displayUnit)}`,
                }),
            );

            line.current.setLatLngs([begin, end]);
        };

        // call onDistanceChange callback with new distance after drag
        const onDragEnd = () => {
            const begin = marker1.current.getLatLng();
            const end = marker2.current.getLatLng();
            const distance = begin.distanceTo(end);

            onDistanceChange?.(distance);
        };

        // initialize when map is ready
        leafletMap.map.whenReady(() => {
            const mapBounds =
                floorPlan.image && !floorPlan.image.bounds
                    ? getEstimatedBounds(floorPlan.image)
                    : leafletMap.map.getBounds();

            const left = (mapBounds.getCenter().lng + mapBounds.getWest()) / 2;
            const right = (mapBounds.getCenter().lng + mapBounds.getEast()) / 2;

            leafletMarker1.setLatLng(leaflet.latLng(mapBounds.getCenter().lat, left));
            leafletMarker2.setLatLng(leaflet.latLng(mapBounds.getCenter().lat, right));
            onUpdateMarker();

            leafletMarker1.addTo(leafletMap.map);
            leafletMarker2.addTo(leafletMap.map);
            leafletLine.addTo(leafletMap.map);

            leafletMarker1.on('drag', onUpdateMarker);
            leafletMarker2.on('drag', onUpdateMarker);
            leafletMarker1.on('dragend', onDragEnd);
            leafletMarker2.on('dragend', onDragEnd);

            leafletLabel.on('dragstart', onLabelMarkerDragStart);
            leafletLabel.on('drag', onLabelMarkerPositionChanged);

            onDragEnd();
        });

        return () => {
            // cleanup
            leafletMarker1.clearAllEventListeners();
            leafletMarker2.clearAllEventListeners();
            leafletLabel.clearAllEventListeners();

            leafletMarker1.removeFrom(leafletMap.map);
            leafletMarker2.removeFrom(leafletMap.map);
            leafletLine.removeFrom(leafletMap.map);
            leafletLabel.removeFrom(leafletMap.map);
        };
    }, [leafletMap, floorPlan.image, displayUnit, onDistanceChange]);

    useEffect(() => {
        if (showLabel) {
            labelMarker.current.addTo(leafletMap.map);
        } else {
            labelMarker.current.removeFrom(leafletMap.map);
        }
    }, [leafletMap, showLabel]);

    return null;
};

MapMeasureTool.displayName = 'MapMeasureTool';
