import { RangedSet } from 'ip-num';
import { createSelector } from 'reselect';
import { getCurrentProjectNetworkSettings } from '../../project/selectors/getCurrentProject';

export const getTotalAvailableIPAddresses = createSelector(
    [getCurrentProjectNetworkSettings],
    (settings) => {
        if (!settings) return 0;
        const { ipStart, ipEnd } = settings;

        if (!ipStart || !ipEnd) return 0;
        const rangeSet = RangedSet.fromRangeString(`${ipStart}-${ipEnd}`);
        if (!rangeSet.getFirst().isGreaterThanOrEquals(rangeSet.getLast())) {
            return Number(rangeSet.getSize());
        }
        return 0;
    },
);
