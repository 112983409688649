import { createCachedSelector } from 're-reselect';
import { isCamera, isMainUnit, isEncoder, isVirtualCamera } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';
import { getIsChildDevice } from './getIsChildDevice';
import { getParentPiaDevice } from './getParentPiaDevice';
import { getPiaDevice } from './getPiaDevice';

export const getZipStreamIsSupported = createCachedSelector(
    [getPiaDevice, getIsChildDevice, getParentPiaDevice],
    (originalPiaDevice, isChildDevice, parentPiaDevice) => {
        const piaDevice = isChildDevice ? parentPiaDevice : originalPiaDevice;

        if (!piaDevice) {
            return false;
        }
        if (
            (isCamera(piaDevice) ||
                isMainUnit(piaDevice) ||
                isEncoder(piaDevice) ||
                isVirtualCamera(piaDevice)) &&
            piaDevice.properties.zipStream
        ) {
            return piaDevice.properties.zipStream;
        }
        return false;
    },
)(toCacheKey);
