import type { IPiaItem, PiaId } from 'app/core/pia';
import { getNotToBeFollowedByArray } from './hasNotToBeFollowedByAccessory';

export const getProductNamesNotToBeFollowedBy = (
    piaId: PiaId,
    notToBeFollowedByAccessories: Record<number, IPiaItem[][]>,
    selectedAccessoriesAndLensIds: number[],
    piaItemsRecord: Record<number, IPiaItem>,
) =>
    getNotToBeFollowedByArray(piaId, notToBeFollowedByAccessories, selectedAccessoriesAndLensIds)
        .map((id) => piaItemsRecord[id].name)
        .join(', ');
