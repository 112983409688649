function addPrefix(val: string) {
    return `DASHBOARD_${val}`;
}

export const DashboardActions = {
    EditProjectSettings: addPrefix('EDIT_PROJECT_SETTINGS'),
    EditProjectLocation: addPrefix('EDIT_PROJECT_LOCATION'),
    ShowShareProject: addPrefix('SHOW_SHARE_PROJECT'),
    ShowProjectNetwork: addPrefix('SHOW_PROJECT_NETWORK'),
};
