import {
    isSystemAccessory,
    isRecordingSolutionComponent,
    isStorageDevice,
    getDeviceTypeFromPiaCategory,
    isAudioManagementSoftware,
} from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';

export const isDisabledQuickAddItem = (item: IPiaItem): boolean => {
    const supportedDeviceType = getDeviceTypeFromPiaCategory(item.category);
    const isSystemAccessoryItem = isSystemAccessory(item) || isAudioManagementSoftware(item);
    const isRecordingSolutionItem = isRecordingSolutionComponent(item) && !isStorageDevice(item);
    return !supportedDeviceType && !isSystemAccessoryItem && !isRecordingSolutionItem;
};
