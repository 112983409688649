import { createCachedSelector } from 're-reselect';
import type { Icons } from 'app/components';
import type { Id } from 'app/core/persistence';
import { getDeviceType, isCustomCamera } from 'app/core/persistence';
import { createSelector } from 'reselect';
import { deviceIcons } from '../../products/components/icons/deviceIcons';
import { getCurrentProjectItemsArray } from '../../project/selectors/getCurrentProject';
import { getIdFromProps } from '../../selectors/getIdFromProps';

export const getItemIcons = createSelector([getCurrentProjectItemsArray], (items) => {
    const icons = items.reduce(
        (itemIcons, item) => {
            if (isCustomCamera(item)) {
                itemIcons[item._id] = 'videocam';
                return itemIcons;
            }

            itemIcons[item._id] = deviceIcons.toIcon(getDeviceType(item) ?? 'camera');
            return itemIcons;
        },
        {} as Record<Id, Icons>,
    );
    return icons;
});

export const getIconForItem = createCachedSelector([getItemIcons, getIdFromProps], (icons, id) => {
    if (!id) {
        return 'device';
    }

    return icons[id];
})((_state, id) => id ?? '');
