import { injectable } from 'inversify';
import { CurrentProjectService, getParentId } from 'app/core/persistence';
import type {
    Id,
    IScheduleModel,
    IBaseProfileModel,
    IPersistence,
    IItemEntity,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import { ProfileBandwidthStorageEstimateService } from './bandwidth/ProfileBandwidthStorageEstimate.service';
import { format } from 'axis-webtools-util';
import type { IBandwidthStorageCameraEstimateModel } from '../models';
import { ScenarioService } from '../../profile/services';
import type { Frequency } from '../../models';
import type { IPiaVirtualCameraProperties } from 'app/core/pia';

@injectable()
export class VirtualCameraEstimateService {
    constructor(
        private scenarioService: ScenarioService,
        private profileBandwidthStorageEstimateService: ProfileBandwidthStorageEstimateService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public getVirtualCameraEstimate(
        projectZipSetting: ProjectZipType,
        virtualCamera: IPersistence<IItemEntity>,
        productProperties: IPiaVirtualCameraProperties,
        frequency: Frequency,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        getMergedProfileFromDevice: (
            item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ) => IBaseProfileModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
    ): IBandwidthStorageCameraEstimateModel | undefined {
        if (!virtualCamera.properties.virtualProduct) {
            throw Error('Item is not a virtual camera');
        }

        const profile = getMergedProfileFromDevice(virtualCamera.properties.virtualProduct);
        if (!profile) {
            return undefined;
        }

        const scenario = this.scenarioService.getScenarioOrThrow(profile.scenario.scenarioId);
        const customBandwidth = virtualCamera.properties.virtualProduct.profileOverride
            ? virtualCamera.properties.virtualProduct.profileOverride.customBandwidth
            : undefined;

        const profileEstimate = this.profileBandwidthStorageEstimateService.getProfileEstimate(
            projectZipSetting,
            scenario,
            profile,
            productProperties,
            frequency,
            getSchedule,
            projectBandwidthVersion,
            customBandwidth,
        );

        const parentId = getParentId(virtualCamera);

        const parentDevice = this.getParentDevice(parentId);
        const quantity = parentDevice?.quantity ?? 1;

        //* Custom bandwidth is set as total for all channels of virtual product. Hence custom bandwidth shouldn't be multiplied by channels.
        const videoChannels = customBandwidth
            ? 1
            : productProperties.channels || productProperties.imageSensors || 1;

        const recordingBandwidthPerCamera = profileEstimate.recordingBandwidth * videoChannels;
        const liveViewBandwidthPerCamera = profileEstimate.liveViewBandwidth * videoChannels;
        const storagePerCamera = profileEstimate.storageInMB * videoChannels;

        const totalAverageRecordingBandwidth = recordingBandwidthPerCamera * quantity;
        const totalAverageLiveViewBandwidth = liveViewBandwidthPerCamera * quantity;
        const totalStorageInMB = storagePerCamera * quantity;

        return {
            type: 'camera',
            perCamera: {
                recordingBandwidth: recordingBandwidthPerCamera,
                formattedRecordingBandwidth: format.bandwidth(recordingBandwidthPerCamera),
                liveViewBandwidth: liveViewBandwidthPerCamera,
                formattedLiveViewBandwidth: format.bandwidth(liveViewBandwidthPerCamera),
                storageInMB: storagePerCamera,
                bandwidthInBps: recordingBandwidthPerCamera + liveViewBandwidthPerCamera,
                formattedStorage: format.storage(storagePerCamera),
                formattedBandwidth: format.bandwidth(
                    recordingBandwidthPerCamera + liveViewBandwidthPerCamera,
                ),
                retentionTime: profile.storage.retentionTime,
            },
            total: {
                recordingBandwidth: totalAverageRecordingBandwidth,
                formattedRecordingBandwidth: format.bandwidth(totalAverageRecordingBandwidth),
                liveViewBandwidth: totalAverageLiveViewBandwidth,
                formattedLiveViewBandwidth:
                    totalAverageLiveViewBandwidth !== 0
                        ? format.bandwidth(totalAverageLiveViewBandwidth)
                        : '',
                storageInMB: totalStorageInMB,
                bandwidthInBps: totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth,
                formattedStorage: totalStorageInMB !== 0 ? format.storage(totalStorageInMB) : '',
                formattedBandwidth:
                    totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth !== 0
                        ? format.bandwidth(
                              totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth,
                          )
                        : '',
                retentionTime: profile.storage.retentionTime,
            },
            channels: videoChannels,
            triggeredRecording: profileEstimate.triggeredRecording,
            continuousRecording: profileEstimate.continuousRecording,
        };
    }

    private getParentDevice(parentId?: Id): IPersistence<IItemEntity> | undefined {
        if (!parentId) return undefined;
        try {
            return this.currentProjectService.getEntity(parentId, 'item');
        } catch (error) {
            //* When deleting a device with virtual children the parent device is removed before currentProjectService can look it up.
            //* This prevents application from crashing.
            return undefined;
        }
    }
}
