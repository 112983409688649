import { injectable } from 'inversify';
import { ActionCreator, IAction, ThunkAction } from 'app/store';
import { IIFrameParentBoundaries, IPartnerProductFilter, IReadMore } from '../models';
import { addonSelectorActions } from '../addonSelectorActions';
import { IApplicationItem, IPartnerProductResponse } from 'app/modules/common';
import { IPiaPartnerItem, PiaId } from 'app/core/pia';
import { AddonSelectorService } from './AddonSelector.service';
import { Id } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { getCompatibleAxisApplications, getPersistedAxisApplicationsForId } from '../selectors';

@injectable()
export class AddonSelectorActionService {
    constructor(private addonSelectorService: AddonSelectorService) {}

    @ActionCreator()
    public getAllPartnerProducts(): ThunkAction {
        return async () => {
            let next: string | null = null;
            this.resetPartnerProducts();
            do {
                const partnerProductsResult: IPartnerProductResponse | null =
                    await this.addonSelectorService.getAllPartnerProducts(next);
                if (!partnerProductsResult) {
                    this.setAllProductsLoaded();
                    return;
                }
                next = partnerProductsResult.next ?? null;
                this.setItems(partnerProductsResult.items);
            } while (next !== null);
            this.setAllProductsLoaded();
        };
    }

    @ActionCreator()
    public setShowReadMoreModal(readMore: IReadMore): IAction<IReadMore> {
        return {
            type: addonSelectorActions.SetShowReadMoreModal,
            payload: readMore,
        };
    }

    @ActionCreator()
    public setReadMoreApplication(application: IApplicationItem): IAction<IApplicationItem> {
        return {
            type: addonSelectorActions.SetReadMoreApplication,
            payload: application,
        };
    }

    @ActionCreator()
    public setClickedApplication(piaId: PiaId | undefined) {
        return {
            type: addonSelectorActions.SetClickedApplication,
            payload: piaId,
        };
    }

    @ActionCreator()
    public setIsOtherProductSelectorOpen(isOpen: boolean): IAction<boolean> {
        return {
            type: addonSelectorActions.SetIsOtherProductSelectorOpen,
            payload: isOpen,
        };
    }

    @ActionCreator()
    public setShowPartnerAcapListModal(show: boolean): IAction<boolean> {
        return {
            type: addonSelectorActions.SetShowPartnerAcapListModal,
            payload: show,
        };
    }

    @ActionCreator()
    public updatePartnerProductFilter(
        filter: Partial<IPartnerProductFilter>,
    ): IAction<Partial<IPartnerProductFilter>> {
        return {
            type: addonSelectorActions.UpdatePartnerProductFilter,
            payload: filter,
        };
    }

    @ActionCreator()
    public resetPartnerProductFilter(): IAction<void> {
        return {
            type: addonSelectorActions.ResetPartnerProductFilter,
            payload: undefined,
        };
    }

    @ActionCreator()
    public resetPartnerProductFilterExceptCompatible(): IAction<void> {
        return {
            type: addonSelectorActions.ResetPartnerProductFilterExceptCompatible,
            payload: undefined,
        };
    }

    @ActionCreator()
    public updateReadMoreModalTopPosition(
        parentBoundaries: IIFrameParentBoundaries,
    ): IAction<IIFrameParentBoundaries> {
        return {
            type: addonSelectorActions.UpdateReadMoreTopPosition,
            payload: parentBoundaries,
        };
    }

    @ActionCreator()
    public toggleELicense(parentId: Id, isELicensePreferred: boolean): ThunkAction {
        eventTracking.logUserEvent(
            'Application Selector',
            'Toggle e-license',
            isELicensePreferred ? 'Enable' : 'Disable',
        );

        return async (dispatch, getState) => {
            const state = getState();
            const persistedApplications = getPersistedAxisApplicationsForId(state, parentId);
            const compatibleApplications = getCompatibleAxisApplications(state, parentId);

            await this.addonSelectorService.convertApplicationLicenseType(
                persistedApplications,
                compatibleApplications,
                isELicensePreferred,
            );

            dispatch({
                type: addonSelectorActions.ToggleELicense,
                payload: isELicensePreferred,
            });
        };
    }

    @ActionCreator()
    public setELicensePreferred(isPreferred: boolean) {
        return {
            type: addonSelectorActions.ToggleELicense,
            payload: isPreferred,
        };
    }

    @ActionCreator()
    private resetPartnerProducts(): IAction<void> {
        return {
            type: addonSelectorActions.ResetPartnerProducts,
            payload: undefined,
        };
    }

    @ActionCreator()
    private setAllProductsLoaded(): IAction<void> {
        return {
            type: addonSelectorActions.SetAllProductsLoaded,
            payload: undefined,
        };
    }

    @ActionCreator()
    private setItems(items: IPiaPartnerItem[]): IAction<IPiaPartnerItem[]> {
        return {
            type: addonSelectorActions.SetItems,
            payload: items,
        };
    }
}
