import type { Icons } from 'app/components';
import { ToasterService } from './Toaster.service';
import { ServiceLocator } from 'app/ioc';
import type { IProgressToast } from '../models/IProgressToast.interface';

export const toaster = {
    success(title: string, message?: string, icon?: Icons) {
        const toasterService = ServiceLocator.get(ToasterService);
        toasterService.success(title, message, icon);
    },
    info(title: string, message?: string, icon?: Icons) {
        const toasterService = ServiceLocator.get(ToasterService);
        toasterService.info(title, message, icon);
    },
    warning(title: string, message?: string, icon?: Icons) {
        const toasterService = ServiceLocator.get(ToasterService);
        toasterService.warning(title, message, icon);
    },
    error(title: string, message?: string, icon?: Icons) {
        const toasterService = ServiceLocator.get(ToasterService);
        toasterService.error(title, message, icon);
    },
    progress(title: string, message?: string): IProgressToast {
        const toasterService = ServiceLocator.get(ToasterService);
        return toasterService.progress(title, message);
    },
};
