import { t } from 'app/translate';
import type { IOptionProps } from 'app/components';
import { GenetecType, GENETEC_CONFIGURED } from '../types';
import { getGenetecProjectId } from './getRecordingDevices';
import { createSelector } from 'reselect';

export const getGenetecTypeOptions = createSelector([getGenetecProjectId], (genetecProjectId) => {
    const includeConfiguredOption = genetecProjectId !== undefined;
    const options: IOptionProps[] = Object.entries(GenetecType).map(
        ([key, value]) =>
            ({
                text: t.genetecGROUP[value],
                value: Number(key),
            }) as IOptionProps,
    );

    if (includeConfiguredOption) {
        options.push({
            text: t.genetecGROUP.configuredOption,
            value: GENETEC_CONFIGURED,
        });
    }
    return options;
});
