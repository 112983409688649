import { FrontSide, Mesh, MeshBasicMaterial, Object3D, PlaneGeometry, TextureLoader } from 'three';

/**
 * Interface for a floor plan in the 3D view
 */
export interface IFloorPlan3d {
    key: string;
    url: string;
    width: number;
    height: number;
    angle: number;
    offsetX: number;
    offsetZ: number;
}

/**
 * Class to handle floor plans in the 3D view
 */
export class FloorPlans extends Object3D {
    // Map to keep track of floor plans by key
    private floorPlanMeshMap: Map<string, Mesh> = new Map();

    constructor() {
        super();
    }

    /**
     * Set the image of a floor plan
     * @param mesh - The mesh to set the image on
     * @param url - The url of the image to set
     */
    private async setFloorPlanImage(mesh: Mesh, url: string) {
        const texture = await new TextureLoader().loadAsync(url);

        const fpMaterial = new MeshBasicMaterial({
            map: texture,
            side: FrontSide,
        });

        mesh.material = fpMaterial;
    }

    /**
     * Set the floor plan images
     * @param floorPlans - The floor plans to set
     */
    public async setFloorPlanImages(floorPlans: IFloorPlan3d[]) {
        // clear floor plans that are not in the new list
        this.floorPlanMeshMap.forEach((mesh, key) => {
            if (!floorPlans.find((floorPlan) => floorPlan.key === key)) {
                this.remove(mesh);
                this.floorPlanMeshMap.delete(key);
            }
        });

        // update existing floor plans
        floorPlans.forEach((floorPlan) => {
            const mesh = this.floorPlanMeshMap.get(floorPlan.key);
            if (mesh) {
                mesh.position.x = floorPlan.offsetX;
                mesh.position.z = floorPlan.offsetZ;
                mesh.rotation.z = floorPlan.angle;
                this.add(mesh);
            }
        });

        // add new floor plans
        floorPlans.forEach((floorPlan) => {
            if (!this.floorPlanMeshMap.has(floorPlan.key)) {
                const mesh = new Mesh();
                mesh.geometry = new PlaneGeometry(floorPlan.width, floorPlan.height, 1, 1);

                mesh.position.y = -0.01;
                mesh.position.x = floorPlan.offsetX;
                mesh.position.z = floorPlan.offsetZ;
                mesh.rotation.z = floorPlan.angle;
                mesh.rotation.x = -Math.PI / 2;
                this.add(mesh);

                this.setFloorPlanImage(mesh, floorPlan.url);
                this.floorPlanMeshMap.set(floorPlan.key, mesh);
            }
        });
    }
}
