import { injectable } from 'inversify';
import type {
    IBaseCustomItemEntity,
    IBaseUserCustomItemEntity,
    ICustomItemEntity,
    Id,
} from '../userDataPersistence';
import { CustomItemRepository } from '../userDataPersistence';
import type { ICustomItem } from '../models';
import { CustomItemCategoryType } from '../models';
import { AppConstants } from 'app/AppConstants';
import { NameGenerationService } from './NameGeneration.service';
import { CurrentProjectService } from './CurrentProject.service';
import { UserCustomItemService } from './UserCustomItem.service';
import type { IApplicationItem } from 'app/modules/common/application';
import { eventTracking } from 'app/core/tracking';
import type { PiaCategory, PiaId } from 'app/core/pia';
import { PiaItemRecorderCategory, PiaItemSoftwareCategory } from 'app/core/pia';
import { PiaItemPoeCategory } from 'app/core/pia/client/types/PiaItemPoeCategory.enum';
import { doesEnumIncludeValue } from 'app/modules/partnerConfig/services/CategoryGroups.service';

@injectable()
export class CustomItemService {
    constructor(
        private customItemRepository: CustomItemRepository,
        private nameGenerationService: NameGenerationService,
        private currentProjectService: CurrentProjectService,
        private userCustomItemService: UserCustomItemService,
    ) {}

    public toggleCustomItem = async (partnerProductItem: IApplicationItem): Promise<void> => {
        eventTracking.logUserEvent(
            'Partner Product Selector',
            'Toggle partner product',
            `Product: ${partnerProductItem.productId}`,
        );

        const itemId = await this.getExistingPartnerProduct(partnerProductItem.productId)?._id;

        if (itemId) {
            // Delete
            this.deleteCustomItem(itemId);
        } else {
            // Add
            const customItemToAdd = {
                name: partnerProductItem.name,
                category: this.mapCategoryToCustomItemCategory(partnerProductItem.category),
                partNumber: '',
                vendor: partnerProductItem.vendor,
                quantity: 1,
                partnerProductId: partnerProductItem.productId,
            };
            this.addProjectCustomItem(customItemToAdd);
        }
    };

    public async addProjectCustomItem(customItem: ICustomItem): Promise<ICustomItem> {
        const { _id: projectId, archived } = this.currentProjectService.getProjectEntity();

        const newCustomItem: IBaseCustomItemEntity = {
            type: 'customItem',
            path: [projectId],
            archived,
            name: customItem.name,
            category: customItem.category,
            partNumber: customItem.partNumber,
            vendor: customItem.vendor,
            quantity: customItem.quantity,
            partnerProductId: customItem.partnerProductId,
        };

        const newUserCustomItem: IBaseUserCustomItemEntity = {
            type: 'userCustomItem',
            path: [],
            archived,
            name: customItem.name,
            category: customItem.category,
            partNumber: customItem.partNumber,
            vendor: customItem.vendor,
        };

        // we only want to add customItems that are not partner products
        // as userCustomItems?
        if (customItem.partnerProductId === undefined) {
            await this.userCustomItemService.addUnique(newUserCustomItem);
        }
        return this.customItemRepository.add(newCustomItem);
    }

    public async updateCustomItem(id: Id, props: DeepPartial<ICustomItem>): Promise<ICustomItem> {
        const entity = this.currentProjectService.getEntity(id, 'customItem');

        const updatedEntity = await this.customItemRepository.update({
            _id: entity._id,
            _rev: entity._rev,
            type: entity.type,
            entityVersion: entity.entityVersion,
            path: entity.path,
            archived: entity.archived,
            creationDate: entity.creationDate,
            updatedDate: entity.updatedDate,
            locked: entity.locked,
            name: props.name ?? entity.name,
            category: props.category ?? entity.category,
            quantity: props.quantity ?? entity.quantity,
            partNumber: props.partNumber ?? entity.partNumber,
            vendor: props.vendor ?? entity.vendor,
            partnerProductId: props.partnerProductId ?? entity.partnerProductId,
        });

        const newUserCustomItem: IBaseUserCustomItemEntity = {
            type: 'userCustomItem',
            path: [],
            archived: entity.archived,
            name: updatedEntity.name,
            category: updatedEntity.category,
            partNumber: updatedEntity.partNumber,
            vendor: updatedEntity.vendor,
        };
        await this.userCustomItemService.addUnique(newUserCustomItem);

        return updatedEntity;
    }

    public async duplicateCustomItem(customItemId: Id): Promise<ICustomItem> {
        const oldEntity = this.currentProjectService.getEntity(customItemId, 'customItem');
        const newEntity = this.copyEntity(oldEntity);

        return this.addProjectCustomItem(newEntity);
    }

    public async deleteCustomItem(id: Id): Promise<Id> {
        const { _id, _rev } = this.currentProjectService.getEntity(id, 'customItem');
        return this.customItemRepository.delete(_id, _rev);
    }

    private getNewName(desiredName: string): string {
        const names = this.currentProjectService
            .getAllEntitiesOfType('customItem')
            .map(({ name }) => name);
        return this.nameGenerationService.getName(
            desiredName,
            names,
            AppConstants.deviceNameMaxLength,
        );
    }

    private copyEntity(entity: ICustomItemEntity): ICustomItemEntity {
        const newName = this.getNewName(entity.name);

        return {
            ...entity,
            name: newName,
        };
    }

    private getExistingPartnerProduct(partnerProductId: PiaId): ICustomItemEntity | undefined {
        const customItems = this.currentProjectService.getAllEntitiesOfType('customItem');
        return customItems.find((item) => item.partnerProductId === partnerProductId);
    }

    private mapCategoryToCustomItemCategory(category: PiaCategory): CustomItemCategoryType {
        if (doesEnumIncludeValue(PiaItemRecorderCategory, category)) {
            return CustomItemCategoryType.installationService;
        }
        if (doesEnumIncludeValue(PiaItemSoftwareCategory, category)) {
            return CustomItemCategoryType.software;
        }
        if (doesEnumIncludeValue(PiaItemPoeCategory, category)) {
            return CustomItemCategoryType.network;
        }
        // all other, i.e
        // PiaAccessoryCategory:
        // PiaEnvironmentCategory:
        // PiaItemAlerterCategory:
        // PiaItemDecoderCategory:
        // PiaItemDetectorCategory:
        // PiaItemEncoderCategory:
        // PiaItemMainUnitCategory:
        // PiaItemPacCategory:
        // PiaItemSensorUnitCategory:
        // PiaItemSpeakerCategory:
        // PiaItemPeopleCounterCategory:
        // PiaItemBareboneCategory:
        // PiaItemVirtualProductCategory:
        // PiaItemWearablesCategory:

        return CustomItemCategoryType.miscellaneous;
    }
}
