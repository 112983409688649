import type { IPolygon } from 'app/modules/common';
import type * as L from 'leaflet';
import { toPolygon } from './cameraCalc';

import type { ISegment } from './segments';
import {
    isSegmentInOrigo,
    mergeSegments,
    optimizeSegments,
    convertLayersToSegments,
    distanceToSegment,
} from './segments';
import type { PolyLine } from 'app/core/persistence';
import { isBetween, isRotatedClockwise, type Point } from 'axis-webtools-util';

const nullPolygon = toPolygon([]);

/** Project a vector on the "shadow limit" i.e. a square with the side 2 * SHADOW_LIMIT  */
function projectOnShadowLimit(point: Point, limit: number): Point {
    const f = limit / Math.max(Math.abs(point[0]), Math.abs(point[1]));
    return f > 1 ? [Math.round(point[0] * f), Math.round(point[1] * f)] : point;
}

const shouldSegmentBeRendered = (threshold: number) => (segment: ISegment) => {
    const distance = distanceToSegment(segment, [0, 0]);

    return distance <= threshold;
};

/** Get a polygon representing the areas not visible from origin */
export function getShadowPolygons(
    origin: L.LatLng,
    blockerLayers: PolyLine[] | undefined,
    renderHorizon: number,
): IPolygon[] {
    // Convert the visible line blockers to segments
    const lineBlockerSegments = convertLayersToSegments(blockerLayers ?? [], origin).filter(
        shouldSegmentBeRendered(renderHorizon),
    );

    if (lineBlockerSegments.length === 0) {
        // Skip the rest if there are no blockers in view
        return [nullPolygon];
    }

    const polygons: IPolygon[] = [];
    const limit = Math.max(3 * renderHorizon, 5000);

    // construct corner vectors for all four corners of the shadow limit square
    const cornerVectorsCounterClockwise: Point[] = [
        [limit, limit],
        [-limit, limit],
        [-limit, -limit],
        [limit, -limit],
    ];

    const cornerVectorsClockwise = [...cornerVectorsCounterClockwise].reverse();

    const mergedSegments = mergeSegments(lineBlockerSegments);
    const optimizedSegments = optimizeSegments(mergedSegments);

    optimizedSegments.forEach((segment: ISegment) => {
        if (isSegmentInOrigo(segment)) {
            // segment starts or ends on camera position. Ignore it
            return;
        }

        const projectedStart = projectOnShadowLimit(segment.start, limit);
        const projectedEnd = projectOnShadowLimit(segment.end, limit);

        // order shadow limit corner vectors in same rotation direction as projectedStart -> projectedEnd
        const orderedCornerVectors = isRotatedClockwise(projectedStart, projectedEnd)
            ? cornerVectorsClockwise
            : cornerVectorsCounterClockwise;

        // add the three first corners to the shadow polygon
        const shadowPolygonCorners = [segment.start, segment.end, projectedEnd];

        // loop through corner vectors and add corners as needed
        orderedCornerVectors.forEach((cornerVector) => {
            if (isBetween(projectedStart, projectedEnd, cornerVector)) {
                // if the corner vector is in between start and end we should
                // add add it to the shadow polygon
                shadowPolygonCorners.push(cornerVector);
            }
        });

        shadowPolygonCorners.push(projectedStart); // add last corner

        polygons.push(toPolygon(shadowPolygonCorners));
    });

    return polygons;
}
