import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IOptionProps } from 'app/components';
import {
    Checkbox,
    DualRange,
    Box,
    Grid,
    Select,
    Stack,
    Text,
    TextInput,
    NumberInput,
} from 'app/components';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { CameraType } from 'app/core/persistence';

import { ServiceLocator } from 'app/ioc/ServiceLocator';
import { FOV_LIMIT, getCurrentProjectItem, getCurrentProjectLocked } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { resolutionOptions } from './resolutionOptions';
import { CustomCameraSettingsService } from './services';
import { PoeClass } from 'app/core/pia';
import { convert } from 'axis-webtools-util';
import { AppConstants } from 'app/AppConstants';
import { eventTracking } from 'app/core/tracking';

const PANORAMA_LIMIT = 170;

export const CustomCameraSettings: React.FC<{ deviceId: Id }> = ({ deviceId }) => {
    const entity = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) => {
        return getCurrentProjectItem(state, deviceId);
    });

    const isLocked = useSelector<IStoreState, boolean>((state) => {
        return getCurrentProjectLocked(state);
    });

    const [customCameraSettingsService] = React.useState<CustomCameraSettingsService>(
        ServiceLocator.get(CustomCameraSettingsService),
    );

    if (!entity || !entity.properties.camera) {
        return null;
    }

    const { customCameraProperties } = entity.properties.camera;

    const cameraTypeOptions: IOptionProps[] = Object.values([
        CameraType.Dome,
        CameraType.Fixed,
        CameraType.PTZ,
    ]).map((type) => {
        return { text: t.cameraTypeGROUP[type], value: type };
    });

    const updateModelName = (value: string) => {
        customCameraSettingsService.updateModelName(value, deviceId);
    };

    const updateCameraType = (value: CameraType) => {
        customCameraSettingsService.updateCameraType(value, deviceId);
    };

    const updateResolution = (value: string) => {
        customCameraSettingsService.updateResolution(value, deviceId);
    };

    const updatePowerConsumption = (value: number) => {
        customCameraSettingsService.updatePowerConsumption(value, deviceId);
    };

    const updateBandwidth = (value: number) => {
        customCameraSettingsService.updateBandwidth(value, deviceId);
    };

    const getResolutionString = () => {
        return (
            customCameraProperties?.resolutionHorizontal +
            'x' +
            customCameraProperties?.resolutionVertical
        );
    };

    const updatePoe = (value: PoeClass) => {
        customCameraSettingsService.updatePoeClass(value, deviceId);
    };

    const updateHorizontalFOV = (min: number, max: number) => {
        customCameraSettingsService.updateHorizontalFOV(min, max, deviceId);
    };

    const isPanorama = () => {
        return (
            (customCameraProperties?.horizontalFovMin ?? 0) >= PANORAMA_LIMIT ||
            (customCameraProperties?.horizontalFovMax ?? 0) >= PANORAMA_LIMIT
        );
    };

    const togglePanoramaMode = (panorama: boolean) => {
        eventTracking.logUserEvent('Custom Camera', 'Panorama mode', String(panorama));

        return panorama
            ? updateHorizontalFOV(PANORAMA_LIMIT, 180)
            : updateHorizontalFOV(0, FOV_LIMIT);
    };

    const poeOptions: IOptionProps[] = [
        { text: t.networkPortsGROUP.nonPoe, value: '' },
        { text: t.poeClassesClassNbr(1), value: PoeClass.Class1 },
        { text: t.poeClassesClassNbr(2), value: PoeClass.Class2 },
        { text: t.poeClassesClassNbr(3), value: PoeClass.Class3 },
        { text: t.poeClassesClassNbr(4), value: PoeClass.Class4 },
        { text: t.poeClassesClassNbr(5), value: PoeClass.Class5 },
        { text: t.poeClassesClassNbr(6), value: PoeClass.Class6 },
        { text: t.poeClassesClassNbr(7), value: PoeClass.Class7 },
        { text: t.poeClassesClassNbr(8), value: PoeClass.Class8 },
        { text: t.poeClassesHighPoe, value: PoeClass.HighPoE },
    ];

    return (
        <Box paddingTop="base">
            <Stack vertical>
                <Text>{t.settingsForGenericCamera}</Text>

                <Grid spacing="base">
                    <Stack vertical notFullWidth>
                        <Text faded>{t.devicesGROUP.camera}</Text>
                        <Stack vertical spacing="none">
                            <Text>{t.modelName}</Text>
                            <TextInput
                                disabled={isLocked}
                                maxLength={AppConstants.modelNameMaxLength}
                                value={customCameraProperties?.modelName}
                                required
                                onChange={updateModelName}
                            />
                        </Stack>
                        <Stack vertical spacing="none">
                            <Text>{t.cameraSelectorCameraType}</Text>
                            <Select
                                disabled={isLocked}
                                value={customCameraProperties?.cameraType}
                                options={cameraTypeOptions}
                                onChange={updateCameraType}
                                fillWidth
                            />
                        </Stack>
                    </Stack>
                    <Stack vertical notFullWidth>
                        <Text faded>{t.video}</Text>
                        <Stack vertical spacing="none">
                            <Text>{t.installationReportSettingsResolution}</Text>
                            <Select
                                disabled={isLocked}
                                value={getResolutionString()}
                                options={resolutionOptions}
                                onChange={updateResolution}
                                fillWidth
                            />
                        </Stack>

                        <Stack vertical spacing="none">
                            <Text>{t.cameraSelectorFieldOfViewHorizontalFieldOfView}</Text>
                            <DualRange
                                rangeMax={FOV_LIMIT}
                                showValue
                                minValue={
                                    isPanorama() ||
                                    customCameraProperties?.horizontalFovMin === undefined
                                        ? 0
                                        : customCameraProperties?.horizontalFovMin
                                }
                                maxValue={
                                    isPanorama() ||
                                    customCameraProperties?.horizontalFovMax === undefined
                                        ? FOV_LIMIT
                                        : customCameraProperties?.horizontalFovMax
                                }
                                onChange={updateHorizontalFOV}
                                changeCriteria="debounced"
                                direction="column"
                                disabled={isPanorama() || isLocked}
                            />
                            <Box paddingTop="base">
                                <Checkbox
                                    slider
                                    selected={isPanorama()}
                                    onChange={togglePanoramaMode}
                                    disabled={isLocked}
                                >
                                    {t.panorama}
                                </Checkbox>
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack vertical notFullWidth>
                        <Text faded>{t.power}</Text>
                        <Stack vertical spacing="none">
                            <Text>{t.projectPoeClass}</Text>
                            <Select
                                disabled={isLocked}
                                value={entity.properties.camera.customCameraProperties?.poe || ''}
                                options={poeOptions}
                                fillWidth
                                onChange={updatePoe}
                            />
                        </Stack>

                        <Stack vertical spacing="none">
                            <Text>{t.powerConsumption}</Text>
                            <NumberInput
                                disabled={isLocked}
                                width={'100%'}
                                min={0}
                                max={1000}
                                value={customCameraProperties?.powerConsumption}
                                onChange={updatePowerConsumption}
                            />
                        </Stack>
                    </Stack>
                    <Stack vertical notFullWidth>
                        <Text faded>{t.quotationEstimatedBandwidth}</Text>
                        <Stack vertical spacing="none">
                            <Text>{t.bandwidthWhileRecording}</Text>
                            <NumberInput
                                disabled={isLocked}
                                width={'100%'}
                                min={0}
                                max={100}
                                decimals={2}
                                value={
                                    customCameraProperties?.bandwidth
                                        ? convert.toMega(customCameraProperties?.bandwidth)
                                        : customCameraProperties?.bandwidth
                                }
                                onChange={updateBandwidth}
                            />
                        </Stack>
                    </Stack>
                </Grid>
            </Stack>
        </Box>
    );
};
