import * as React from 'react';
import { css } from '@emotion/css';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import classNames from 'classnames';
import { Box } from '../../containers';
import { Clickable, Closeable } from '../../functional';
import { getShouldOpenDownwards, getShouldOpenRightwards } from '../../services';

const dropDownMargin = '9px';

const PopOverStyle = css`
    border-style: solid;
    border-width: 1px;
    border-top-width: 2px;
    border-bottom-width: 2px;
    margin-top: ${dropDownMargin};
    margin-bottom: ${dropDownMargin};
    padding: ${SpacingsEnum.half};
`;

const DropDownTriggerOpenStyle = css`
    &::before {
        font-family: 'axis-wt-icons';
        font-size: 36px;
        text-align: right;
        line-height: 0.2;
        position: absolute;
        pointer-events: none;
        right: calc(-1 * ${SpacingsEnum.quart});

        /* Enable Ligatures ================ */
        letter-spacing: 0;
        font-feature-settings: 'liga';
        font-variant-ligatures: discretionary-ligatures;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

const DropRightwardsStyle = css`
    &::before {
        text-align: left;
        left: calc(-1 * ${SpacingsEnum.quart});
    }
`;

const DropDownwardsStyle = css`
    &::before {
        content: 'arrow_up';
        top: calc(-1 * ${dropDownMargin});
    }
`;

const DropUpwardsStyle = css`
    &::before {
        content: 'arrow_down';
        bottom: calc(-1 * ${dropDownMargin});
    }
`;
export interface IPopOverProps {
    content: React.ReactNode;
    trigger: JSX.Element;
    disabled?: boolean;
    maxHeight?: number;
}

export interface IPopOverState {
    isOpen: boolean;
    shouldOpenDownwards: boolean;
    shouldOpenRightwards: boolean;
}

export class PopOver extends React.Component<IPopOverProps, IPopOverState> {
    private colors = {
        borderColor: ColorsEnum.grey3,
        borderTopColor: ColorsEnum.black,
        borderBottomColor: ColorsEnum.black,
        backgroundColor: ColorsEnum.white,
    };
    private triggerElement: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: IPopOverProps) {
        super(props);
        this.state = {
            isOpen: false,
            shouldOpenDownwards: false,
            shouldOpenRightwards: false,
        };
    }
    public componentDidMount() {
        this.updateOpeningDirection();
    }

    public componentDidUpdate() {
        this.updateOpeningDirection();
    }

    public render() {
        return (
            <Box position="relative">
                <Clickable onClick={this.togglePopOver} disabled={this.props.disabled}>
                    <Box innerRef={this.triggerElement} alignItems="stretch">
                        {this.props.trigger}
                    </Box>
                </Clickable>
                {this.state.isOpen && (
                    <Closeable close={this.close}>
                        <Box
                            display="block"
                            __htmlAttributes={{
                                className: classNames(PopOverStyle, DropDownTriggerOpenStyle, {
                                    [DropUpwardsStyle]: !this.state.shouldOpenDownwards,
                                    [DropDownwardsStyle]: this.state.shouldOpenDownwards,
                                    [DropRightwardsStyle]: this.state.shouldOpenRightwards,
                                }),
                                style: {
                                    ...this.colors,
                                    position: 'absolute',
                                    top: this.state.shouldOpenDownwards ? '100%' : undefined,
                                    bottom: this.state.shouldOpenDownwards ? undefined : '100%',
                                    right: this.state.shouldOpenRightwards ? undefined : '0',
                                    left: this.state.shouldOpenRightwards ? '0' : undefined,
                                },
                            }}
                        >
                            <Box
                                maxHeight={this.props.maxHeight}
                                overflowY={this.props.maxHeight ? 'auto' : undefined}
                                overflowX={this.props.maxHeight ? 'hidden' : undefined}
                            >
                                {this.props.content}
                            </Box>
                        </Box>
                    </Closeable>
                )}
            </Box>
        );
    }

    private togglePopOver = () => {
        this.state.isOpen ? this.close() : this.open();
    };

    private open = () => {
        this.setState({ isOpen: true });
    };

    private close = () => {
        const activeElement = document.activeElement as HTMLElement | null;

        if (activeElement) {
            activeElement.blur();
        }
        this.setState({ isOpen: false });
    };

    private setShouldOpenDownwards = (shouldOpenDownwards: boolean) => {
        this.setState({ shouldOpenDownwards });
    };

    private setShouldOpenRightwards = (shouldOpenRightwards: boolean) => {
        this.setState({ shouldOpenRightwards });
    };

    private updateOpeningDirection = () => {
        const { shouldOpenDownwards, shouldOpenRightwards } = this.state;
        const calculatedShouldOpenDownwards = getShouldOpenDownwards(
            this.triggerElement,
            this.props.maxHeight || 100,
        );
        const calculatedShouldOpenRightwards = getShouldOpenRightwards(this.triggerElement, 440);

        if (shouldOpenDownwards !== calculatedShouldOpenDownwards) {
            this.setShouldOpenDownwards(calculatedShouldOpenDownwards);
        }

        if (shouldOpenRightwards !== calculatedShouldOpenRightwards) {
            this.setShouldOpenRightwards(calculatedShouldOpenRightwards);
        }
    };
}
