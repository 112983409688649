import { useState, useEffect } from 'react';

interface IUseDrop {
    /**
     * A ref to the element that is to be the drop zone
     */
    ref: React.RefObject<HTMLElement>;
    onDrop(event: DragEvent): void;
}

/**
 * Enable dropping draggable components onto a component.
 *
 * It uses the `useEffect` hook to add event listeners to the
 * element supplied by the `ref` prop.
 */
export const useDrop = ({ ref, onDrop }: IUseDrop) => {
    const [dropState, updateDropState] = useState('droppable');

    const dragOver = (event: DragEvent) => {
        event.preventDefault();
        updateDropState('dragOver');
    };

    const dragEnter = (event: DragEvent) => {
        event.preventDefault();
        updateDropState('dragEnter');
    };

    const drop = (event: DragEvent) => {
        event.preventDefault();
        onDrop(event);
        updateDropState('drop');
    };

    useEffect(() => {
        const element = ref.current;
        if (element) {
            element.addEventListener('dragover', dragOver);
            element.addEventListener('drop', drop);
            element.addEventListener('dragenter', dragEnter);
            return () => {
                element.removeEventListener('dragover', dragOver);
                element.removeEventListener('drop', drop);
                element.removeEventListener('dragenter', dragEnter);
            };
        }
    });
    return {
        dropState,
    };
};
