import type { IItemEntity, IPersistence } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    isBodyWornCamera,
    isDockingStation,
    isSystemController,
} from 'app/core/persistence';
import type { IPiaDockingStation } from 'app/core/pia';
import {
    getAvailablePiaItems,
    getCurrentProjectItemsArray,
    getPiaItemsRecord,
} from 'app/modules/common';
import { xor } from 'lodash-es';
import { createSelector } from 'reselect';

export const getWearablesInSystem = createSelector([getCurrentProjectItemsArray], (items) => {
    return {
        bodyWornCameras: items.filter(deviceTypeCheckers.isBodyWornCamera),
        dockingStations: items.filter(deviceTypeCheckers.isDockingStation),
        systemControllers: items.filter(deviceTypeCheckers.isSystemController),
    };
});

export const getAvailableDockingStations = createSelector([getAvailablePiaItems], (piaItems) =>
    piaItems.filter(isDockingStation).map((dockingStation) => ({
        productId: dockingStation.id,
        name: dockingStation.name,
        cameraBays: dockingStation.properties.cameraBays,
    })),
);

export const getAvailableSystemControllers = createSelector([getAvailablePiaItems], (piaItems) =>
    piaItems.filter(isSystemController).map((controller) => ({
        productId: controller.id,
        name: controller.name,
        maxDockingStations: controller.properties.maxDockingStations,
        maxCameraCount: controller.properties.maxCameraCount,
    })),
);

export const getAvailableBodyWornCameras = createSelector([getAvailablePiaItems], (piaItems) =>
    piaItems.filter(isBodyWornCamera).map((bwCamera) => ({
        productId: bwCamera.id,
        name: bwCamera.name,
    })),
);

export const getBodyWornCameraCount = createSelector([getWearablesInSystem], (wearables) => {
    return wearables.bodyWornCameras.reduce((count, camera) => count + camera.quantity, 0);
});

/**
 * Retrieve all docking stations in PIA grouped by compatability i.e. all docking station that is compatible with a W101 camera is grouped together.
 * @return Docking station grouped by compatibility. Example: [[W701, W702], [W702, W703]]
 */
export const getDockingStationsGroups = createSelector(
    [getAvailablePiaItems],
    (piaItems): IPiaDockingStation[][] => {
        const cameras = piaItems.filter(isBodyWornCamera);
        const dockingStations = piaItems.filter(isDockingStation);

        const groups: IPiaDockingStation[][] = [];

        dockingStations.reduce((availableDocks, dock) => {
            // Find a compatible camera of the current {dock}
            const compatibleCamera = cameras.find((camera) =>
                dock.relations.find(
                    (relation) =>
                        relation.id === camera.id && relation.relationType === 'compatible',
                ),
            );

            if (!compatibleCamera) {
                return availableDocks;
            }

            // All docks that are compatible with {compatibleCamera} form a group.
            const group = availableDocks.filter(({ id }) =>
                compatibleCamera.relations.some((rel) => rel.id === id),
            );

            // Push those docks to {groups} and then remove all docks belonging to that group from the {availableDocks}
            groups.push(group);
            return xor(availableDocks, group);
        }, dockingStations);

        return groups.filter((group) => group.length > 0);
    },
);

/**
 * Count camera bays in system per group
 * @return a record where index is the index of the group and value is number of camera bays
 */
export const getAvailableBaysByGroup = createSelector(
    [getWearablesInSystem, getPiaItemsRecord, getDockingStationsGroups],
    (wearables, piaItemRecord, dockingStationGroups): Record<number, number> => {
        const groupsIds = dockingStationGroups.map((group) => group.map(({ id }) => id));
        const bayCountByGroup = {} as Record<number, number>;

        groupsIds.forEach((group, index) => {
            const wearablesIngroup: IPersistence<IItemEntity>[] = wearables.dockingStations.filter(
                (dock) => dock.productId && group.includes(dock.productId),
            );

            const bayCount = wearablesIngroup.reduce((totalCount, wearable) => {
                const nbrOfBays = wearable.productId
                    ? (piaItemRecord[wearable.productId] as IPiaDockingStation).properties
                          .cameraBays
                    : 1;
                return (totalCount += nbrOfBays * wearable.quantity);
            }, 0);
            bayCountByGroup[index] = bayCount;
        });

        return bayCountByGroup;
    },
);

/**
 *  Count cameras in system per group
 * @return a record where index is the index of the group and value is number of cameras
 */
export const getCameraCountByGroup = createSelector(
    [getWearablesInSystem, getDockingStationsGroups],
    (wearables, dockingStationGroups): Record<number, number> => {
        const cameraCountByGroup = {} as Record<number, number>;

        dockingStationGroups.forEach((group, index) => {
            const camerasInGroup = wearables.bodyWornCameras.filter((camera) => {
                // All cameras that are compatible with any of the docking station in {group} belong to that group. Therefor it's sufficient to only look at the firt element of {group}.
                return group[0].relations.some(
                    ({ id, relationType }) =>
                        id === camera.productId && relationType === 'compatible',
                );
            });

            const count = camerasInGroup.reduce(
                (totalCount, camera) => (totalCount += camera.quantity),
                0,
            );

            cameraCountByGroup[index] = count;
        });

        return cameraCountByGroup;
    },
);

export const getSystemControllerCount = createSelector([getWearablesInSystem], (wearables) => {
    return wearables.systemControllers.reduce(
        (count, controller) => count + controller.quantity,
        0,
    );
});

export const getDockingStationCount = createSelector([getWearablesInSystem], (wearables) => {
    return wearables.dockingStations.reduce((count, docking) => count + docking.quantity, 0);
});
