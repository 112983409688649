import type { IPersistence, IUserCustomItemEntity } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { isDefined } from 'axis-webtools-util';
import { createSelector } from 'reselect';

const getUserCustomItems = (state: IStoreState) => state.userCustomItems;

export const getCustomUserItemsArray = createSelector(
    [getUserCustomItems],
    (userItemsRecord): IPersistence<IUserCustomItemEntity>[] =>
        Object.values(userItemsRecord).filter(isDefined),
);
