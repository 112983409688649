import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Checkbox, Stack, Text } from 'app/components';
import type { IStoreState } from 'app/store';
import {
    getIncludeDiscontinued,
    getMatchingProductsCount,
    getTotalProductsCount,
} from '../selectors';
import { DeviceSortOrder } from './DeviceSortOrder.container';
import { t } from 'app/translate';
import { css } from '@emotion/css';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../services';
import { AppConstants } from 'app/AppConstants';

const headerStyle = css`
    position: sticky;
    top: 50px;
    z-index: ${AppConstants.productSelectorHeaderDepth};
    border-bottom: 1px solid ${ColorsEnum.grey3};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ${SpacingsEnum.base};
    padding-bottom: ${SpacingsEnum.quart};
    background-color: ${ColorsEnum.white};
    margin-top: -2px;

    @media print {
        display: none !important;
    }
`;

export const ProductSelectorHeader: React.FunctionComponent = () => {
    const [actionService] = React.useState(ServiceLocator.get(DeviceSelectorActionService));
    const includeDiscontinued = useSelector<IStoreState, boolean>(getIncludeDiscontinued);
    const matchingProductsCount = useSelector<IStoreState, number>(getMatchingProductsCount);
    const totalProductsCount = useSelector<IStoreState, number>(getTotalProductsCount);

    return (
        <div className={headerStyle}>
            <Stack lineBetweenColor="grey3">
                <Box paddingRight="base">
                    <Text style="semibold">
                        {t.matchingProducts(matchingProductsCount, totalProductsCount)}
                    </Text>
                </Box>
                <DeviceSortOrder />
            </Stack>
            <Checkbox
                testId="show_discontinued_tgl"
                slider
                selected={includeDiscontinued}
                onChange={actionService.toggleDiscontinued}
            >
                {t.sortOrderGROUP.includeDiscontinued}
            </Checkbox>
        </div>
    );
};

ProductSelectorHeader.displayName = 'ProductSelectorHeader';
