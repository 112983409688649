export class CoreProfileNameError extends Error {
    constructor(
        profileName: string,
        public isNameEmptyString: boolean = false,
    ) {
        super(`Error setting profile name ${profileName}`);

        Object.setPrototypeOf(this, CoreProfileNameError.prototype);
    }
}
