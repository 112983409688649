import type { IPolygon } from 'app/modules/common';
import { type Point } from 'axis-webtools-util';

/**
 * This is the visible area polygon with the additional (0,0) point for the camera,
 * thus creating an outline of the visible area + the dead zone in front of the camera.
 * If there is no visible area (visibleArea.length < 4) or if the target height is above
 * the installation height (visibleArea > 4) it will revert to just using the fovHandle (and a mirrored point)
 * as the cone coordinates (plus the camera point). It will just use the visible area if the fov ix over 179.
 */
export function calculateConeOutline(
    horizontalFov: number,
    visibleArea: IPolygon,
    isTiltable: boolean,
    fovHandlePoint?: Point,
): IPolygon {
    if (horizontalFov >= 180 || !fovHandlePoint || !isTiltable) {
        return visibleArea;
    }

    const firstLine = visibleArea.regions[0];

    return {
        regions: [
            firstLine?.length === 4
                ? [firstLine[1], firstLine[2], [0, 0], firstLine[3], firstLine[0]]
                : [fovHandlePoint, [0, 0], [fovHandlePoint[0], -fovHandlePoint[1]]],
        ],
        inverted: false,
    };
}
