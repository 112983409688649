import * as React from 'react';
import { useService } from 'app/ioc';
import { useMount } from 'app/hooks';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Carousel, Border, Box, Clickable, Stack, Text } from 'app/components';
import type { PiaId } from 'app/core/pia';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { t } from 'app/translate';
import type { IApplicationItem } from 'app/modules/common';
import { PartnerLogo } from './PartnerLogo';
import { getArePartnerProductsLoaded, getPartnerProductsByVendorId } from '../selectors';
import { AddonSelectorActionService } from '../services';

interface IMoreFromVendorProps {
    vendorId: PiaId;
    vendorName: string;
    selectedId: PiaId;
}

const vendorCardStyle = css`
    border: 1px solid ${ColorsEnum['grey3']};
    border-radius: 8px;
    background-color: ${ColorsEnum['white']};
    padding: 25px 8px;
    scale: 1;
    transition:
        scale 200ms ease,
        box-shadow 200ms ease;
    &:hover {
        scale: 1.05;
        box-shadow:
            rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
            rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
`;

const selectedVendorCardStyle = css`
    ${vendorCardStyle}
    background-color: ${ColorsEnum['yellow1']};
`;

const renderApplicationLink = (
    piaId: PiaId,
    vendorPiaId: PiaId,
    name: string,
    selectedId: PiaId,
    onClick: () => void,
) => {
    return (
        <Clickable key={piaId} onClick={onClick}>
            <Box paddingY="quart">
                <div className={piaId === selectedId ? selectedVendorCardStyle : vendorCardStyle}>
                    <Box width="155px">
                        <Stack vertical justifyContent="center" alignItems="center">
                            <PartnerLogo vendorPiaId={vendorPiaId} />
                            <Text align="center">{name}</Text>
                        </Stack>
                    </Box>
                </div>
            </Box>
        </Clickable>
    );
};

export const MoreFromVendor: React.FC<IMoreFromVendorProps> = ({
    vendorId,
    vendorName,
    selectedId,
}) => {
    const addonSelectorActionService = useService(AddonSelectorActionService);

    const allLoaded = useSelector<IStoreState, boolean>((state) =>
        getArePartnerProductsLoaded(state),
    );

    useMount(() => {
        // fetch partner products if not loaded
        if (!allLoaded) {
            addonSelectorActionService.getAllPartnerProducts();
        }
    });

    const vendorProducts = useSelector<IStoreState, IApplicationItem[]>((state) =>
        getPartnerProductsByVendorId(state, vendorId),
    );

    return (
        <Stack vertical spacing="quart">
            <Box paddingLeft="base">
                <Text style="semibold">{t.moreFrom(vendorName)}</Text>
            </Box>
            <Border color="transparent">
                <Box borderRadius="rounded" color="grey2" display="block">
                    <Carousel navigationBtnOverlay>
                        {vendorProducts.map(
                            (product) =>
                                product.vendorId &&
                                renderApplicationLink(
                                    product.productId,
                                    product.vendorId,
                                    product.name,
                                    selectedId,
                                    () =>
                                        addonSelectorActionService.setReadMoreApplication(product),
                                ),
                        )}
                    </Carousel>
                </Box>
            </Border>
        </Stack>
    );
};

MoreFromVendor.displayName = 'MoreFromVendor';
