import * as React from 'react';
import { css } from '@emotion/css';
import { Text, Box, Heading, Stack, Currency } from 'app/components';

const GrandTotalNumberStyle = css`
    text-align: end;
    margin-inline-start: 8px;
    min-width: 7em;
    display: inline-block;
`;
export interface ITotalProps {
    amount: number;
    currency: string;
    locale: string;
    totalText: string;
    editMode?: boolean;
}

export class TotalPrice extends React.Component<ITotalProps> {
    // Different rendering in edit vs. view mode.
    public render() {
        return this.props.editMode ? (
            <Box direction="column">
                <Text>{this.props.totalText}</Text>
                <Stack spacing="half">
                    <Heading testId="total_amount_in_edit_sales_quote" style="headline">
                        <Currency
                            value={this.props.amount}
                            locale={this.props.locale}
                            currency={this.props.currency}
                            showCurrency
                        />
                    </Heading>
                </Stack>
            </Box>
        ) : (
            <Heading testId="total_amount_in_view_sales_quote" style="subheader">
                <span>{this.props.totalText}</span>
                <span className={GrandTotalNumberStyle}>
                    {
                        <Currency
                            value={this.props.amount}
                            locale={this.props.locale}
                            currency={this.props.currency}
                            showCurrency
                        />
                    }
                </span>
            </Heading>
        );
    }
}
