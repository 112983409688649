import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import type { Colors } from 'app/styles';
import { Card, EditableText, Heading, Stack, Text } from 'app/components';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import {
    getCurrentProjectItem,
    getCurrentProjectLocked,
    getDeviceChildren,
} from 'app/modules/common';
import { getCanAddDoor, getmaxNbrOfDoorsForProduct } from 'app/modules/doorSelector';
import type { IMapsDevice } from '../../../../models';
import { AddDeviceMenuItem } from '../devices/AddDeviceMenuItem.component';
import { DeviceMenuItem } from '../devices/DeviceMenuItem.container';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import { AppConstants } from 'app/AppConstants';

const renderDoors = (children: IPersistence<IItemEntity>[], color?: Colors) =>
    children.map((child) => {
        return (
            <DeviceMenuItem
                key={child._id}
                id={child._id}
                color={color || 'transparent'}
                disabled
                openSettings={() => {}}
            />
        );
    });

export const DoorControllerContextItem: React.FC<{
    itemId: Id;
    name?: string;
    onNameChange(name: string): void;
}> = ({ itemId, name, onNameChange }) => {
    const children = useSelector<IStoreState, IPersistence<IItemEntity>[]>((state) =>
        getDeviceChildren(state, itemId),
    );
    const piaId = useSelector<IStoreState, number | null | undefined>(
        (state) => getCurrentProjectItem(state, itemId)?.productId,
    );
    const locked = useSelector<IStoreState, boolean>((state) => getCurrentProjectLocked(state));
    const device = useSelector<IStoreState, IMapsDevice | undefined>((state) =>
        getSelectedDeviceOrParentDevice(state),
    );
    const canAddDoor = useSelector<IStoreState, boolean>((state) => getCanAddDoor(state, itemId));

    const maxNbrOfDoors = useSelector<IStoreState, number>((state) =>
        getmaxNbrOfDoorsForProduct(state, itemId),
    );

    const usedChannels = children.reduce((sum, doors) => (sum += doors.quantity), 0);

    const hasAvailableChannels = usedChannels < maxNbrOfDoors;

    return (
        <Stack vertical spacing="panel">
            <Card paddingY="base" paddingX="base">
                <Heading width="100%">
                    <EditableText
                        value={name ?? ''}
                        maxLength={AppConstants.deviceNameMaxLength}
                        onChange={onNameChange}
                        placeholder={t.name}
                    />
                </Heading>
            </Card>
            {!hasAvailableChannels && children.length === 0 && (
                <Text color="grey5" align="center">
                    {t.doorControllerNeeded}
                </Text>
            )}
            {children.length > 0 && (
                <Stack vertical spacing="quart">
                    {renderDoors(children, device?.color)}
                </Stack>
            )}
            {piaId !== null && !locked && canAddDoor && (
                <AddDeviceMenuItem
                    locked={locked}
                    deviceType="door"
                    text={t.addDoor}
                    parentId={itemId}
                />
            )}
            <Text color="grey5" semiBold align="center">
                {t.deviceListUsedChannels(usedChannels, maxNbrOfDoors)}
            </Text>
        </Stack>
    );
};
