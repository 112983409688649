import { createCachedSelector } from 're-reselect';
import type { IItemEntity, IPersistence, IProfileEntity } from 'app/core/persistence';
import { getParentId, deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaItem, IPiaEncoder, IPiaCamera, IPiaMainUnit, IPiaSensorUnit } from 'app/core/pia';
import { getProfileOverride } from './getProfileOverride';
import { getAssociatedProfile } from './getAssociatedProfile';
import {
    getCurrentProjectFrequency,
    getCurrentProjectItems,
    getCurrentProjectItem,
    getCurrentProjectRetentionTime,
} from '../../project';
import { getPiaItemsRecord } from '../../piaDevices';
import { MainUnitEstimateService } from '../../bandwidth/services';
import { ProfileSupportService } from '../../profile/services';
import { toCacheKey } from '../../cacheKey';
import { mergeProfiles } from '../../profile/utils';

export const getDeviceProfileSupport = createCachedSelector(
    [
        getCurrentProjectFrequency,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getCurrentProjectItem,
        getProfileOverride,
        getAssociatedProfile,
        getCurrentProjectRetentionTime,
    ],
    (
        currentProjectFrequency,
        currentProjectItems,
        piaItemsRecord,
        item,
        profileOverride,
        profile,
        projectRetentionTime,
    ) => {
        if (!item) {
            return undefined;
        }

        const piaProductProperties = getPiaProductProperties(
            item,
            piaItemsRecord,
            currentProjectItems,
        );

        if (!profileOverride || !profile || !piaProductProperties) {
            return undefined;
        }

        const mergedProfile = mergeProfiles(
            profile,
            profileOverride,
            projectRetentionTime,
        ) as IProfileEntity;

        return ProfileSupportService.getProfileSupportSync(
            mergedProfile,
            piaProductProperties,
            currentProjectFrequency,
        );
    },
)(toCacheKey);

export function getPiaProductProperties(
    item: IPersistence<IItemEntity>,
    piaItemsRecord: Record<string, IPiaItem>,
    currentProjectItems: Record<string, IPersistence<IItemEntity> | undefined>,
) {
    if (deviceTypeCheckers.isAnalogCamera(item)) {
        const parentId = getParentId(item);
        if (!parentId) {
            return null;
        }

        const encoder = currentProjectItems[parentId];
        if (!encoder || encoder.productId === null) {
            return null;
        }

        return (piaItemsRecord[encoder.productId] as IPiaEncoder)?.properties ?? null;
    }

    if (deviceTypeCheckers.isSensorUnit(item)) {
        const parentId = getParentId(item);
        if (!parentId) {
            return null;
        }

        const mainUnit = currentProjectItems[parentId];
        if (!mainUnit || mainUnit.productId === null || item.productId === null) {
            return null;
        }

        const mainUnitPiaProduct = piaItemsRecord[mainUnit.productId] as IPiaMainUnit;
        const sensorUnitProduct = piaItemsRecord[item.productId] as IPiaSensorUnit;

        if (!mainUnitPiaProduct || !sensorUnitProduct) {
            return null;
        }

        return MainUnitEstimateService.mergeFSeriesProperties(
            mainUnitPiaProduct,
            sensorUnitProduct,
        );
    }

    if (item.productId === null) {
        return null;
    }
    return (piaItemsRecord[item.productId] as IPiaCamera)?.properties ?? null;
}
