import { createSelector } from 'reselect';
import { Frequency } from '../../models';
import { getCurrentProjectLocation } from '../../project/selectors';
import type { IPiaItem } from 'app/core/pia';

const FREQUENCY_REGEX = /(50|60) ?Hz$/;

const getDeviceFrequency = ({ name }: IPiaItem): Frequency | null => {
    // eslint-disable-next-line no-sparse-arrays
    const [, deviceFrequency] = name.match(FREQUENCY_REGEX) || [, null];
    switch (deviceFrequency) {
        case null:
            return null;
        case '50':
            return Frequency.Hz50;
        case '60':
            return Frequency.Hz60;
        default:
            throw Error(`Encountered invalid frequency for device ${name}`);
    }
};

export const getProjectFrequencies = createSelector([getCurrentProjectLocation], (piaLocation) => {
    const frequencies = [];

    if (piaLocation?.frequency50Hz) {
        frequencies.push(Frequency.Hz50);
    }
    if (piaLocation?.frequency60Hz) {
        frequencies.push(Frequency.Hz60);
    }

    return frequencies;
});

/**
 * Retrieves singular frequency for project location. If a country has both 50Hz and 60Hz priority is given to 60Hz.
 * @returns Single location frequency or undefined
 */
export const getProjectFrequency = createSelector([getProjectFrequencies], (frequencies) => {
    if (frequencies.includes(Frequency.Hz60)) {
        return Frequency.Hz60;
    }
    if (frequencies.includes(Frequency.Hz50)) {
        return Frequency.Hz50;
    }
    return undefined;
});

export const createFrequencyFilter = (frequencies: Frequency[]) => (device: IPiaItem) => {
    const deviceFrequency = getDeviceFrequency(device);

    return !deviceFrequency || frequencies.includes(deviceFrequency);
};
