import type {
    IPiaRelationReference,
    IPiaRelationItem,
    IPiaRelationRepository,
    IPiaRelationsGraph,
} from '../types';

// User to do prefix range search in pouch
const HIGH_VALUE_UNICODE = '\uffff';

export class PiaRelationsPouchDbRepository implements IPiaRelationRepository {
    constructor(private database: PouchDB.Database) {}

    public getRelationsGraph(): Promise<IPiaRelationsGraph> {
        return this.database
            .allDocs({
                include_docs: true,
                startkey: 'item:',
                endkey: `item:${HIGH_VALUE_UNICODE}`,
            })
            .then((response) => response.rows.map((row) => row.doc))
            .then((items) => this.buildRelationalGraph(items));
    }

    private buildRelationalGraph(items: any[]) {
        const itemsDict = items.reduce((dict, item) => {
            dict[item.id] = item;
            return dict;
        }, {});

        const createRelationItem = (
            relations: IPiaRelationItem[],
            relation: IPiaRelationReference,
        ): IPiaRelationItem[] => {
            if (itemsDict.hasOwnProperty(relation.id)) {
                const relatedItem = itemsDict[relation.id];
                relations.push({
                    id: relation.id,
                    state: relatedItem.state,
                    relationType: relation.relationType,
                    category: relatedItem.category,
                    relationProperties: relation.relationProperties,
                });
            }

            return relations;
        };

        return items.reduce((graph, item) => {
            graph[item.id] = item.relations.reduce(createRelationItem, []);
            return graph;
        }, {});
    }
}
