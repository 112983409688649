import * as React from 'react';
import { Box, Clickable, Icon, Title } from 'app/components';
import { UploadFloorPlanModal } from '../overlaypanel/UploadFloorPlanModal';
import { t } from 'app/translate';

export const AddFloorPlanButton: React.FC = () => {
    const [showUploadFloorPlan, setShowUploadFloorPlan] = React.useState(false);

    return (
        <>
            <Clickable onClick={() => setShowUploadFloorPlan(true)}>
                <Title title={t.addNewFloorPlan}>
                    <Box padding="halfQuart" paddingLeft="quart">
                        <Box
                            justifyContent="center"
                            alignItems="center"
                            padding="quart"
                            hoverColor="blue0"
                            borderRadius="round"
                        >
                            <Icon opaque icon="add_circle" color="blue" testId="add_floorplan" />
                        </Box>
                    </Box>
                </Title>
            </Clickable>
            {showUploadFloorPlan && (
                <UploadFloorPlanModal onClose={() => setShowUploadFloorPlan(false)} />
            )}
        </>
    );
};

AddFloorPlanButton.displayName = 'AddFloorPlanButton';
