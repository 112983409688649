/**
 * Error usable to detect e.g exhaustive switch scenarios.
 *
 * More info: https://stackoverflow.com/a/39419171
 */
export class UnreachableCaseError extends Error {
    constructor(value: never, optionalMessage?: string) {
        let message = `Unreachable case ${value}`;

        if (optionalMessage) {
            message += ` - ${optionalMessage}`;
        }

        super(message);
        Object.setPrototypeOf(this, UnreachableCaseError.prototype);
    }
}

/*
Since the above error can't be unit tested we have to rely
on manual tests using the code below. Just uncomment it
if required.

type Foo = 'value1' | 'value2';

function bar(foo: Foo) {
    switch (foo) {
        case 'value1':
            break;
        case 'value2':
            break;
        default:
            throw new UnreachableCaseError(foo, `pew dew`);
    }
}
*/
