import * as React from 'react';
import { t } from 'app/translate';
import { Text, Stack, Button, Heading, Positioned, Border, Box, IconButton } from 'app/components';

import { FloorPlans } from './FloorPlans';
import { MapsActionService } from '../../../services';
import { useService } from 'app/ioc';

interface IGeolocationToolProps {
    onCancel(): void;
}

export const GeolocationTool: React.FC<IGeolocationToolProps> = ({ onCancel }) => {
    const mapsActionService = useService(MapsActionService);

    const cancel = () => {
        onCancel();
        mapsActionService.toggleGeoLocationTool(null);
    };

    const save = () => {
        mapsActionService.saveFloorPlanGeolocation();
        mapsActionService.toggleGeoLocationTool(null);
    };

    return (
        <>
            <Border color="grey1" radius="20px" shadow="0px 5px 12px rgba(0, 0, 0, 0.15)">
                <Box
                    color="grey1"
                    paddingY="base"
                    paddingX="doublePanel"
                    maxWidth="400px"
                    position="relative"
                >
                    <Positioned position="absolute" top="10px" right="10px">
                        <IconButton opaque={false} color="red" icon="cancel" onClick={cancel} />
                    </Positioned>
                    <Stack vertical alignItems="center" spacing="panel">
                        <Stack vertical spacing="base" alignItems="center">
                            <Heading testId="set_scale_title" style="title">
                                {t.geolocationModalTitle}
                            </Heading>
                            <Box>
                                <Stack spacing="halfCell" vertical>
                                    <Text testId="set_scale_message" align="center">
                                        {t.geolocationModalMessage}
                                    </Text>
                                </Stack>
                            </Box>
                        </Stack>
                        <Stack alignItems="center" spacing="panel">
                            <Button testId="cancel_geolocation" onClick={cancel}>
                                {t.cancel}
                            </Button>
                            <Button testId="set_geolocation" primary onClick={save}>
                                {t.done}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Border>

            <FloorPlans editable />
        </>
    );
};

GeolocationTool.displayName = 'GeolocationTool';
