import type { IPiaItem, IPiaSystemComponent } from 'app/core/pia';
import { PiaItemRecorderCategory } from 'app/core/pia';
import { getNumberOfPorts } from 'app/modules/recordingSelector/selectors/utils';

const isPiaRecorder = (device: IPiaItem | null): device is IPiaSystemComponent =>
    !!device && device.category === PiaItemRecorderCategory.RECORDERS2;

const isServerPlusSwitch = (piaItem: IPiaItem | null): boolean =>
    !!piaItem?.properties && isPiaRecorder(piaItem) && getNumberOfPorts(piaItem).totalPorts > 0;

/**
 * Determines how many IP addresses a piaItem should have per quantity.
 * @param piaItem
 * @returns number of addresses per quantity
 */
export const getNumberOfAddressesPerQuantity = (piaItem: IPiaItem | null) => {
    // One address for server itself plus one for its switch
    if (isServerPlusSwitch(piaItem)) {
        return 2;
    }
    return 1;
};
