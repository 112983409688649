import { useState, useEffect } from 'react';

/**
 * Use to know if an element has entered the view.
 *
 * @param ref The element to observe when entering the view.
 * @param threshold The threshold (how much of the element that needs to be visible) to trigger the inView state change.
 */
export const useInView = (ref: React.RefObject<HTMLElement> | null, threshold: number = 0) => {
    const [inView, setInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting);
            },
            { threshold },
        );
        if (ref?.current) {
            observer.observe(ref.current);
            // Remove the observer when no longer needed
            return () => {
                observer.disconnect();
            };
        }
    }, [ref, threshold]);
    return inView;
};
