import * as React from 'react';
import { extendableProps, renderReactChildren } from '../../services';
import type { IExtendableComponentWithChildren } from '../../models';
import { css } from '@emotion/css';

interface IGridItem extends IExtendableComponentWithChildren {
    /**
     * Either specify the name of the area to place this item, or this property is a shorthand property for the grid-row-start, grid-column-start, grid-row-end, and grid-column-end properties.
     */
    gridArea?: React.CSSProperties['gridArea'];
    /**
     * A shorthand property for the grid-row-start and the grid-row-end properties
     */
    gridRow?: React.CSSProperties['gridRow'];
    /**
     * Specifies the row to start the grid item
     */
    gridRowStart?: React.CSSProperties['gridRowStart'];
    /**
     * Specifies the row to end the grid item
     */
    gridRowEnd?: React.CSSProperties['gridRowEnd'];
    /**
     * A shorthand property for the grid-column-start and the grid-column-end properties
     */
    gridColumn?: React.CSSProperties['gridColumn'];
    /**
     * Specifies the column to start the grid item
     */
    gridColumnStart?: React.CSSProperties['gridColumnStart'];
    /**
     * Specifies the column to end the grid item
     */
    gridColumnEnd?: React.CSSProperties['gridColumnEnd'];
}

/**
 * Applies grid placements to its child.
 */
export class GridItem extends React.Component<IGridItem> {
    public render() {
        const {
            gridArea,
            gridColumn,
            gridColumnEnd,
            gridColumnStart,
            gridRow,
            gridRowEnd,
            gridRowStart,
            children,
            ...extendedProps
        } = this.props;
        const child = React.Children.only(children);

        const className = css`
            grid-area: ${gridArea};
            grid-column: ${gridColumn};
            grid-column-start: ${gridColumnStart};
            grid-column-end: ${gridColumnEnd};
            grid-row: ${gridRow};
            grid-row-start: ${gridRowStart};
            grid-area: ${gridRowEnd};
        `;

        const attributes = extendableProps(extendedProps, {
            className,
        });

        return renderReactChildren(
            child,
            (c) => React.cloneElement(c, attributes),
            (c) => React.cloneElement(c, attributes.__htmlAttributes),
        );
    }
}
