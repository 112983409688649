import type { IPiaDevice } from 'app/core/pia';
import { DEFAULT_SPEAKER_PLACEMENT } from 'app/core/persistence';
import type { SpeakerPlacement } from 'app/core/persistence';

const PIA_WALL_IDS = [
    17160, // wall
    43315, // wall direct
];

const PIA_CEILING_IDS = [
    17161, // ceiling
    43342, // ceiling direct
    17169, // recessed ceiling
];

export const isCeilingCompatible = (piaProduct: IPiaDevice) =>
    piaProduct.relations
        .filter(({ relationType }) => relationType === 'compatible')
        .some(({ id }) => PIA_CEILING_IDS.includes(id));

export const isWallCompatible = (piaProduct: IPiaDevice) =>
    piaProduct.relations
        .filter(({ relationType }) => relationType === 'compatible')
        .some(({ id }) => PIA_WALL_IDS.includes(id));

export const getPossibleSpeakerPlacements = (piaProduct: IPiaDevice): SpeakerPlacement[] => {
    const placements: SpeakerPlacement[] = [];

    if (isWallCompatible(piaProduct)) {
        placements.push('wall');
    }
    if (isCeilingCompatible(piaProduct)) {
        placements.push('ceiling');
    }

    if (placements.length === 0) {
        placements.push(DEFAULT_SPEAKER_PLACEMENT);
    }

    return placements;
};
