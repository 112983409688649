import * as React from 'react';
import type { IFloorPlanEntity } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { FloorPlan } from './FloorPlan';
import {
    getDerotatedMapsArrayWithPendingGeolocation,
    getGeolocatedFloorPlans,
    getFloorPlanToGeolocateId,
} from '../../../selectors';
import {
    createDerotationTransform,
    getFloorPlanGeoLocationWithFallback,
    isGeoLocated,
    transformFloorPlan,
} from 'app/modules/common';

interface IFloorPlansProps {
    editable?: boolean;
    floorPlan?: IFloorPlanEntity;
    renderDerotated?: boolean;
}

/**
 * Adds a virtual geo location to a floor plan if it is not geolocated.
 * The virtual geo location is the Axis T building and is used as a reference point
 * for unlocated floor plans.
 */
const addVirtualGeoLocation = (floorPlan: IFloorPlanEntity): IFloorPlanEntity => {
    const geoLocation = getFloorPlanGeoLocationWithFallback(floorPlan);
    return {
        ...floorPlan,
        image: floorPlan.image && {
            ...floorPlan.image,
            geoLocation,
        },
    };
};

/**
 * get the corresponding derotated floorplan
 * @param floorPlan - the floorplan to derotate
 * @returns transformed floorplan
 */
const getDerotatedFloorPlan = (floorPlan: IFloorPlanEntity) => {
    const transform = createDerotationTransform(floorPlan);
    const geoLocation = floorPlan.image?.geoLocation;
    const rotation = -(geoLocation?.angle ?? 0);
    const transformedFloorPlan = transformFloorPlan(transform, rotation, floorPlan);

    return transformedFloorPlan;
};

/**
 * Checks if the passed floorplan should be derotated (i.e if it is a geolocated floorPlan of mapType 'FloorPlan')
 * @param floorPlan floorPlan - the passed floorPlan if any
 * @returns true if floorPlan should be derotated, false otherwise
 */
const shouldDerotateFloorPlan = (floorPlan?: IFloorPlanEntity) => {
    return floorPlan && floorPlan.mapType === 'FloorPlan' && isGeoLocated(floorPlan);
};

/**
 * Returns geolocated floorplan (derotated floorPlan if geolocated floorplan should render)
 * @param geoLocatedFloorPlans all geolocated floorplans for the project
 * @param floorPlan the floorPlan to render
 * @param renderDerotated true if geolocated floorplan should be rendered
 */
const getGeoLocatedFloorPlan = (
    geoLocatedFloorPlans: IFloorPlanEntity[],
    floorPlan: IFloorPlanEntity,
    renderDerotated: boolean,
) => {
    return renderDerotated && shouldDerotateFloorPlan(floorPlan)
        ? [getDerotatedFloorPlan(floorPlan)]
        : geoLocatedFloorPlans; // if floor plan is passed and geo located, use geolocated floor plans
};

export const FloorPlans: React.FC<IFloorPlansProps> = ({
    editable = false,
    floorPlan,
    renderDerotated = false,
}) => {
    const geoLocatedFloorPlans = useSelector(getGeolocatedFloorPlans);
    const derotatedGeoLocatedFloorPlans = useSelector(getDerotatedMapsArrayWithPendingGeolocation);
    const floorPlanToGeolocateId = useSelector(getFloorPlanToGeolocateId);

    const floorPlans = !floorPlan
        ? derotatedGeoLocatedFloorPlans // if no floor plan is passed, use derotated floor plans
        : isGeoLocated(floorPlan)
          ? getGeoLocatedFloorPlan(geoLocatedFloorPlans, floorPlan, renderDerotated)
          : [addVirtualGeoLocation(floorPlan)]; // if an unlocated floor plan is passed, add virtual geo location

    return (
        <>
            {floorPlans.map(({ image, _id }) => {
                const showHandles = editable && _id === floorPlanToGeolocateId;
                return (
                    image && (
                        <FloorPlan
                            key={_id}
                            id={_id}
                            floorPlanImage={image}
                            editable={showHandles}
                        />
                    )
                );
            })}
        </>
    );
};

FloorPlans.displayName = 'FloorPlans';
