import * as React from 'react';
import { Modal, Button, Box, VideoFrame } from 'app/components';
import { t } from 'app/translate';

interface IWelcomeProps {
    onFinished(): void;
}

export const Welcome: React.FunctionComponent<IWelcomeProps> = (props) => {
    return (
        <Modal title={t.welcomeTitle}>
            <Box direction="column" overflow="auto">
                <Box padding="doublePanel">
                    <VideoFrame
                        src="https://www.axis.com/videoplayer/38208"
                        allowFullScreen
                        width="640px"
                        height="360px"
                    />
                </Box>
                <Box justifyContent="end">
                    <Button testId="btn_lets_go" onClick={props.onFinished} primary>
                        {t.letsGo}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

Welcome.displayName = 'Welcome';
