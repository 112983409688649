import { injectable } from 'inversify';
import { Id } from 'app/core/persistence';
import { IAction, ActionCreator } from 'app/store';
import { InstallationReportActions } from '../InstallationReport.actions';
import { InstallationReportService } from './InstallationReport.service';
import type { IInstallationReportSchedule, IInstallationReportDevicesResponse } from '../models';
import { IInstallationReportSortBy } from '../models';
import { InstallationReportScheduleService } from './schedules';

@injectable()
export class InstallationReportActionService {
    constructor(
        private installationReportService: InstallationReportService,
        private installationReportScheduleService: InstallationReportScheduleService,
    ) {}

    @ActionCreator()
    public getProjectScheduleNames(projectId: Id): IAction<Promise<Record<string, string>>> {
        return {
            type: InstallationReportActions.GetScheduleNames,
            payload: this.installationReportService.getScheduleNames(projectId),
        };
    }

    @ActionCreator()
    public getProjectSchedules(projectId: Id): IAction<Promise<IInstallationReportSchedule[]>> {
        return {
            type: InstallationReportActions.GetProjectSchedules,
            payload: this.installationReportScheduleService.getSchedules(projectId),
        };
    }

    @ActionCreator()
    public getInstallationReportItems(
        projectId: Id,
    ): IAction<Promise<IInstallationReportDevicesResponse>> {
        return {
            type: InstallationReportActions.GetInstallationReportDevices,
            payload: this.installationReportService.getInstallationReportItems(projectId),
        };
    }

    @ActionCreator()
    public setSortOrder(sortOrder: IInstallationReportSortBy): IAction<IInstallationReportSortBy> {
        return {
            type: InstallationReportActions.SET_SORT_ORDER,
            payload: sortOrder,
        };
    }
}
