import type { IPiaItemVersion } from '../';

interface IHasPiaVersion {
    versions: IPiaItemVersion[];
}

export const filterProducts = {
    byCategory(category: string) {
        return (item: { category: string }) => item.category === category;
    },
    byCategories(categories: string[]) {
        return (item: { category: string }) => categories.some((c) => c === item.category);
    },
    byIncludesAnyOfCategories(filterCategories: string[]) {
        return (item: { categories: string[] }) =>
            item.categories.some((c) => filterCategories.includes(c));
    },
    byIncludesAnyOfCategoriesExceptCategory(
        acceptedCategories: string[],
        rejectedCategory: string,
    ) {
        return (item: { categories: string[]; category: string }) =>
            item.category !== rejectedCategory &&
            item.categories.some((c) => acceptedCategories.includes(c));
    },
    byExcludingCategory(category: string) {
        return (item: { category: string }) => item.category !== category;
    },
    byState(state: number) {
        return (item: { state: number }) => item.state === state;
    },
    byStates(states: number[]) {
        return (item: { state: number }) => states.some((s) => s === item.state);
    },
    byRegion(region: string) {
        return (item: IHasPiaVersion) =>
            item.versions.length === 0 ||
            item.versions.some(({ versions }) => versions.includes(region));
    },
    byRegions(regions: string[]) {
        return (item: IHasPiaVersion) =>
            regions.map(filterProducts.byRegion).some((rF) => rF(item));
    },
    byVendor(vendor: string) {
        return (item: { properties: { vendor: string } }) =>
            item.properties.vendor.toLowerCase() === vendor.toLowerCase();
    },
    byVendors(vendors: string[]) {
        return (item: { properties: { vendor: string } }) =>
            vendors.some((v) => v.toLowerCase() === item.properties.vendor.toLowerCase());
    },
    byExcludingVendor(vendor: string) {
        return (item: { properties: { vendor: string } }) => item.properties.vendor !== vendor;
    },
    byExcludingGenerics() {
        return (item: {
            productId: number | null;
            properties: { camera?: { customCameraProperties?: { activated: boolean } } };
        }) => item.properties.camera?.customCameraProperties?.activated || item.productId !== null;
    },
    byExternallyHidden() {
        return (item: { externallyHidden: boolean }) => !item.externallyHidden;
    },
};
