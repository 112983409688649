import { injectable } from 'inversify';
import type { IItemEntity, IPersistence, Id } from 'app/core/persistence';
import { CurrentProjectService } from 'app/core/persistence';
import type { IPiaItem, PiaId } from 'app/core/pia';
import type {
    IInstallationBaseReportDevice,
    IInstallationBaseReportAccessory,
} from '../../models/devices/IInstallationReportBaseDevice';
import { compact } from 'lodash-es';
import { PiaQueriesService } from 'app/modules/common';

@injectable()
export class BaseDeviceService {
    constructor(
        private piaQueryService: PiaQueriesService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async mapDevice(
        item: IPersistence<IItemEntity>,
        piaItem: IPiaItem,
    ): Promise<Omit<IInstallationBaseReportDevice, 'category'>> {
        return {
            id: item._id,
            name: item.name,
            notes: item.notes,
            quantity: item.quantity,
            description: item.description,
            piaId: piaItem.id,
            model: piaItem.name,
            accessories: await this.getAccessories(item._id),
            applications: await this.getApplications(item._id),
            mounts: await this.getMounts(item._id),
        };
    }

    public getPiaItemFromProductId = (piaId: PiaId): IPiaItem | null =>
        this.piaQueryService.getPiaItemFromId(piaId);

    private getAccessories = async (id: Id): Promise<IInstallationBaseReportAccessory[]> => {
        const items = this.currentProjectService.getDeviceChildren(id, 'accessory');
        return compact(items.map(this.createReportAccessory));
    };

    private getApplications = async (id: Id): Promise<IInstallationBaseReportAccessory[]> => {
        const items = this.currentProjectService.getDeviceChildren(id, 'acap');
        return compact(items.map(this.createReportAccessory));
    };

    private getMounts = async (id: Id): Promise<IInstallationBaseReportAccessory[]> => {
        const items = this.currentProjectService.getDeviceChildren(id, [
            'deviceMount',
            'environmentMount',
            'primaryMount',
        ]);

        return compact(items.map(this.createReportAccessory));
    };

    private createReportAccessory = (
        entity: IPersistence<IItemEntity>,
    ): IInstallationBaseReportAccessory | null => {
        const piaItem = entity.productId
            ? this.piaQueryService.getPiaItemFromId(entity.productId)
            : null;
        return piaItem
            ? {
                  model: piaItem.name,
                  piaId: piaItem.id,
                  quantity: entity.quantity,
              }
            : null;
    };
}
