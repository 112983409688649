import { convert, isDefined } from 'axis-webtools-util';
import {
    getCurrentProjectUnitSystem,
    getPiaItemsRecord,
    getPowerForCameraFromMaxRating,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import type { IPiaCameraProperties, IPiaMainUnitProperties, PiaId } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import { isCamera, isMainUnit, isSensorUnit, Resolution } from 'app/core/persistence';
import { t } from 'app/translate';
import { getSelectedParentPiaDevice } from './getSelectedParentPiaItem';
import { pick } from 'lodash-es';
import { findLensFov } from './getAccessoryLensFov';
import { getCompareProductLenses } from './getCompareProductLenses';

export const getProductIdsToCompare = (state: IStoreState) =>
    state.deviceSelector.productsToCompare;

type mainUnitPropKey = keyof IPiaMainUnitProperties;
export interface IComparableProduct {
    piaId: PiaId;
    name: string;
    // Product characteristics
    outdoorReady?: boolean;
    vandalResistant?: boolean;
    vandalRating?: string;
    stainlessSteel?: boolean;
    ruggedized?: boolean;
    operationalTemperature?: string;
    powerConsumption?: string;
    // Imaging
    maxResolution?: string;
    horizontalFieldOfView?: string;
    verticalFieldOfView?: string;
    corridor?: boolean;
    lightfinder?: boolean;
    WDRTechnology?: string;
    dayAndNightFunc?: boolean;
    opticalZoom?: string;
    varifocal?: boolean;
    replaceableLens?: boolean;
    remoteFocus?: boolean;
    remoteZoom?: boolean;
    imageStabilization?: boolean;
    // Functionality
    alarmInputsOutputs?: string;
    audioSupport?: boolean;
    twoWayAudio?: boolean;
    optimizedIR?: boolean;
    builtInMicrophone?: boolean;
    zipstream?: boolean;
    hdmi?: boolean;
    localStorage?: boolean;
    onvifProfiles?: string;
    powerOverEthernet?: boolean;
    wireless?: boolean;
    // Security
    edgeVault?: boolean;
    secureBoot?: boolean;
    signedFirmware?: boolean;
    TPM?: boolean;
    // Sustainability
    bfrCfrFree?: boolean;
    pvcFree?: boolean;
    recycledPlasticPercent?: number;
}

/**
 * Combines main unit and sensor unit properties to a new object
 * @param sensorUnitProps
 * @param mainUnitProps
 * @returns Combined properties as IPiaCameraProperties
 */
const getSensorAndMainUnitCombinedProps = (
    sensorUnitProps: IPiaCameraProperties,
    mainUnitProps: IPiaMainUnitProperties,
): IPiaCameraProperties => {
    const mainUnitKeys: mainUnitPropKey[] = [
        'corridorFormat',
        'lowLight',
        'imageStabilization',
        'alarmInputsOutputs',
        'audioSupport',
        'twoWayAudio',
        'builtInMicrophone',
        'optimizedIR',
        'zipStream',
        'HDMIOutput',
        'localStorage',
        'ONVIFProfile',
        'PoEClass',
        'wireless',
        'edgeVault',
        'secureBoot',
        'signedFirmware',
        'TPM',
    ];

    // Originate from sensor unit props and then override for specified main unit keys
    return {
        ...sensorUnitProps,
        ...pick(mainUnitProps, mainUnitKeys),
    };
};

const formatFoV = (max: number, min: number) => (max === min ? `${max}` : `${max} - ${min}`);

export const getComparableProducts = createSelector(
    [
        getProductIdsToCompare,
        getPiaItemsRecord,
        getCurrentProjectUnitSystem,
        getSelectedParentPiaDevice,
        getCompareProductLenses,
    ],
    (productIds, piaItems, unitSystem, parentDevice, lensRecord): IComparableProduct[] => {
        const getOperationalTemperatureText = (supportedTemperatureRange?: {
            min: number;
            max: number;
        }) => {
            if (!supportedTemperatureRange) {
                return undefined;
            }

            const minTemperature =
                unitSystem === 'metric'
                    ? supportedTemperatureRange.min
                    : Math.round(convert.celsiusToFahrenheit(supportedTemperatureRange.min));
            const maxTemperature =
                unitSystem === 'metric'
                    ? supportedTemperatureRange.max
                    : Math.round(convert.celsiusToFahrenheit(supportedTemperatureRange.max));
            const unit =
                unitSystem === 'metric'
                    ? t.abbreviationsGROUP.celsius
                    : t.abbreviationsGROUP.fahrenheit;
            return `${minTemperature} – ${maxTemperature}${unit}`;
        };

        const piaMainUnit = parentDevice && isMainUnit(parentDevice) ? parentDevice : undefined;

        return productIds
            .map((productId) => {
                const piaItem = piaItems[productId];
                if (!isCamera(piaItem) && !isSensorUnit(piaItem)) {
                    return undefined;
                }

                const cameraProps = piaMainUnit
                    ? getSensorAndMainUnitCombinedProps(piaItem.properties, piaMainUnit.properties)
                    : piaItem.properties;

                const lens = lensRecord[productId] ?? undefined;
                const lensFoV = lens ? findLensFov([lens], piaItem) : undefined;
                const fov = lensFoV ?? cameraProps;

                const powerForDevice = getPowerForCameraFromMaxRating(piaItem.properties);

                const comparableProduct: IComparableProduct = {
                    piaId: productId,
                    name: piaItem.name,
                    outdoorReady: cameraProps.outdoorReady,
                    vandalResistant: cameraProps.vandalResistant,
                    vandalRating: cameraProps.vandalRating,
                    ruggedized: cameraProps.ruggedizedEN50155,
                    stainlessSteel: (cameraProps.casingMaterial?.length ?? 0) > 0,
                    operationalTemperature: getOperationalTemperatureText(
                        cameraProps.operationalTemperatureC,
                    ),
                    powerConsumption: powerForDevice !== 0 ? `${powerForDevice}W` : undefined,

                    maxResolution: new Resolution(
                        cameraProps.maxVideoResolutionHorizontal,
                        cameraProps.maxVideoResolutionVertical,
                    ).toString(),
                    horizontalFieldOfView: formatFoV(fov.maxHorizontalFOV, fov.minHorizontalFOV),
                    verticalFieldOfView: formatFoV(fov.maxVerticalFOV, fov.minVerticalFOV),
                    corridor: cameraProps.corridorFormat,
                    lightfinder: !!cameraProps.lowLightTechnology,
                    WDRTechnology: cameraProps.WDRTechnology,
                    dayAndNightFunc: cameraProps.dayNightFunc,
                    opticalZoom: cameraProps.opticalZoom,
                    varifocal: cameraProps.varifocal,
                    replaceableLens: cameraProps.lensChangeable,
                    remoteFocus: cameraProps.remoteFocus,
                    remoteZoom: cameraProps.remoteZoom,
                    imageStabilization: cameraProps.imageStabilization,

                    alarmInputsOutputs: cameraProps.alarmInputsOutputs,
                    audioSupport: cameraProps.audioSupport,
                    twoWayAudio: cameraProps.twoWayAudio,
                    builtInMicrophone: cameraProps.builtInMicrophone,
                    optimizedIR: cameraProps.optimizedIR,
                    zipstream: cameraProps.zipStream,
                    hdmi: cameraProps.HDMIOutput,
                    localStorage: cameraProps.localStorage,
                    onvifProfiles: cameraProps.ONVIFProfile?.join(', '),
                    powerOverEthernet: !!cameraProps.PoEClass,
                    wireless: cameraProps.wireless,

                    edgeVault: cameraProps.edgeVault,
                    secureBoot: cameraProps.secureBoot,
                    signedFirmware: cameraProps.signedFirmware,
                    TPM: cameraProps.TPM,

                    bfrCfrFree: cameraProps.bfrCfrFree,
                    pvcFree: cameraProps.pvcFree,
                    recycledPlasticPercent: cameraProps.recycledPlasticPercent,
                };

                return comparableProduct;
            })
            .filter(isDefined);
    },
);

export const getCompareHasDiscontinued = createSelector(
    [getProductIdsToCompare, getPiaItemsRecord],
    (compareProductIds, piaItemsRecord) =>
        compareProductIds.some((productId) => isDiscontinued(piaItemsRecord[productId])),
);
