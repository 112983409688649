import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getNewItemNetworkSettings, ItemService } from 'app/core/persistence';
import type {
    IItemNetworkSettings,
    IProjectNetworkSettings,
    Id,
    IItemEntity,
    IPersistence,
} from 'app/core/persistence';
import { t } from 'app/translate';
import { Box, IconButton } from 'app/components';
import { ServiceLocator } from 'app/ioc';
import { debounce } from 'lodash-es';
import { DeviceNetworkForm } from './components/DeviceNetworkForm';
import { getJointContractedIpAddresses } from './utils';
import {
    getCurrentProjectItem,
    getCurrentProjectItemsArray,
    getCurrentProjectNetworkSettings,
} from '../project';
import { ProjectNetworkModal } from './modal/ProjectNetworkModal';
import { getPiaItemForProductId } from '../piaDevices';

const DEBOUNCE_TIME = 500; // ms

interface IDeviceNetworkProps {
    deviceId: Id;
    networkSettings: IItemNetworkSettings[];
}

const getMultipleAddressFromNetworkSettings = (
    networkSettings: IItemNetworkSettings[],
): string | undefined => {
    if (networkSettings.length > 1) {
        return networkSettings.some(({ dhcp }) => dhcp)
            ? t.useDHCP
            : getJointContractedIpAddresses(networkSettings);
    }
    return undefined;
};

export const DeviceNetwork: React.FunctionComponent<IDeviceNetworkProps> = ({
    deviceId,
    networkSettings,
}) => {
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));

    const currentProjectNetworkSettings = useSelector<
        IStoreState,
        IProjectNetworkSettings | undefined
    >((state) => getCurrentProjectNetworkSettings(state));

    const selectedDevice = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        (state) => getCurrentProjectItem(state, deviceId),
    );

    const piaItem = useSelector((state: IStoreState) =>
        getPiaItemForProductId(state, selectedDevice?.productId ?? null),
    );

    const currentProjectItems = useSelector<IStoreState, IPersistence<IItemEntity>[]>(
        (state: IStoreState) => getCurrentProjectItemsArray(state),
    );

    const [multipleNetworkSettings, setMultipleNetworkSettings] = React.useState(
        getMultipleAddressFromNetworkSettings(networkSettings),
    );
    const [showProjectNetworkModal, setShowProjectNetworkModal] = React.useState(false);
    const toggleProjectNetworkModal = () => {
        setShowProjectNetworkModal(!showProjectNetworkModal);
    };

    // Update form values when network settings change
    React.useEffect(() => {
        setMultipleNetworkSettings(getMultipleAddressFromNetworkSettings(networkSettings));
    }, [networkSettings]);

    if (!selectedDevice) {
        return null;
    }

    return (
        <>
            <DeviceNetworkForm
                deviceId={deviceId}
                multipleNetworkSettings={multipleNetworkSettings}
                networkSettings={networkSettings[0]}
                currentProjectNetworkSettings={currentProjectNetworkSettings}
                onChange={debounce(async (value: IItemNetworkSettings) => {
                    if (!value.override) {
                        // Clear network settings when not using override anymore.
                        const newNetworkSettings = getNewItemNetworkSettings(
                            deviceId,
                            selectedDevice.properties,
                            selectedDevice.quantity,
                            piaItem,
                            currentProjectNetworkSettings,
                            currentProjectItems,
                        );
                        await itemService.updateItem(deviceId, {
                            networkSettings: newNetworkSettings,
                        });
                    } else {
                        await itemService.updateItem(deviceId, {
                            networkSettings: [value],
                        });
                    }
                }, DEBOUNCE_TIME)}
            />
            <Box paddingTop="base">
                <IconButton
                    text={t.ipAddresses}
                    icon="settings"
                    size="md"
                    color="blue"
                    onClick={toggleProjectNetworkModal}
                />
            </Box>
            {showProjectNetworkModal && <ProjectNetworkModal onClose={toggleProjectNetworkModal} />}
        </>
    );
};
DeviceNetwork.displayName = 'DeviceNetwork';
