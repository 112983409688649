export interface IDateProvider {
    now(): number;
}

export interface ITimeService {
    isUpdateOfDataRequired(lastFetch?: number): boolean;
}

export class TimeService implements ITimeService {
    constructor(private dateProvider: IDateProvider) {}

    public isUpdateOfDataRequired(lastFetch?: number): boolean {
        if (lastFetch === undefined) {
            return true;
        }

        const date = new Date(lastFetch);
        date.setDate(0);

        const next = this.getNextTimestamp(lastFetch);
        return this.dateProvider.now() > next;
    }

    private getNextTimestamp(lastFetch: number): number {
        const next = new Date(lastFetch);
        next.setHours(2);
        next.setDate(1);
        next.setMonth(next.getMonth() + 1);
        return next.getTime();
    }
}
