import { injectGlobal } from '@emotion/css';
// the css global style in this file is separated from globalStyles due
// to scrollbar problem in the styleguide.
injectGlobal`
    html,
    body,
    #app {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
        user-select: none;
    }

`;
