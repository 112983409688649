import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ProjectTitle } from './ProjectTitle.container';
import { Box, Heading } from 'app/components';
import { NavLink } from 'react-router-dom';
import type { Property } from 'csstype';
import { getPartnerConfigHeaderStyle } from 'app/modules/common';

interface IAppTitleProps {
    colorOverride?: Property.Color;
    projectLoaded: boolean;
}

const mapStateToProps = (store: IStoreState): IAppTitleProps => {
    return {
        colorOverride: getPartnerConfigHeaderStyle(store)?.foregroundColor,
        projectLoaded: !!store.currentProject.project?._id,
    };
};

class AppTitleContainer extends React.Component<IAppTitleProps> {
    public render() {
        return this.props.projectLoaded ? (
            <ProjectTitle />
        ) : (
            <Box flex="fullWidth">
                <NavLink to="/">
                    <Heading colorOverride={this.props.colorOverride} style="title" width="100%">
                        AXIS Site Designer
                    </Heading>
                </NavLink>
            </Box>
        );
    }
}

export const AppTitle = connect(mapStateToProps)(AppTitleContainer);
