import { injectable } from 'inversify';
import { t } from 'i18next';
import { uniq } from 'lodash-es';
import type { IPiaAccessory, IPiaItem, PiaId, PiaQuery, IPiaRelationReference } from 'app/core/pia';
import {
    filterProducts,
    PiaItemSensorUnitCategory,
    PiaItemPacCategory,
    PiaItemDecoderCategory,
    PiaItemService,
    PiaRelationService,
    PiaItemState,
    PiaAccessoryCategory,
    PiaEnvironmentCategory,
    PiaItemSoftwareCategory,
} from 'app/core/pia';
import type { IEnvironment } from '../models';
import { MOUNTING_CATEGORIES } from '../MountingCategories';

// All accessories a device is compatible will be included except these black listed categories
const ACCESSORY_CATEGORIES_BLACK_LIST: string[] = [
    PiaItemSoftwareCategory.ACAP,
    PiaAccessoryCategory.PTHEADS,
    PiaAccessoryCategory.INSTALLTOOLS,
    PiaAccessoryCategory.NETWORKSWITCHES,
    PiaEnvironmentCategory.ENVIRONMENTS,
];

const DEVICE_CATEGORIES_BLACK_LIST: string[] = [
    PiaItemSensorUnitCategory.SENSORUNIT,
    PiaItemSensorUnitCategory.THERMALSENSOR,
    PiaItemPacCategory.IORELAYS,
    PiaItemPacCategory.DOORCONTROLLERS,
    PiaItemDecoderCategory.DECODER,
];

const ENVIRONMENT_NAME_TO_TRANSLATION_KEY: Record<string, string> = {
    '3/4_NPS_pipe': 'threeQuartersNPSpipe',
    '1/2_NPS_pipe': 'halfNPSpipe',
    'DIN rail': 'dinRail',
};

@injectable()
export class ProductQueriesService {
    constructor(
        private piaItemService: PiaItemService<IPiaItem>,
        private piaRelationService: PiaRelationService,
    ) {}

    public getPiaItem(productId: PiaId): IPiaItem | null {
        const piaItem = this.piaItemService.get(productId);
        return piaItem.first();
    }

    public getAccessoriesByRelations(
        relations: IPiaRelationReference[],
        regions?: string[],
    ): PiaQuery<IPiaAccessory> {
        const compatibleIds = uniq(
            relations
                .filter(
                    (relation) =>
                        relation.relationType === 'compatible' ||
                        relation.relationType === 'recommends',
                )
                .map((relation) => relation.id),
        );

        return this.piaItemService
            .getMultiple(compatibleIds, regions)
            .filter(({ category }) => !ACCESSORY_CATEGORIES_BLACK_LIST.includes(category))
            .filter(({ category }) => !DEVICE_CATEGORIES_BLACK_LIST.includes(category))
            .filter(filterProducts.byStates([PiaItemState.EXTERNALLY_ANNOUNCED]));
    }

    public getDeviceCompatibleEnvironments = (
        productId: PiaId,
        regions: string[],
    ): PiaQuery<IEnvironment> =>
        this.piaItemService
            .getAll()
            .filter(filterProducts.byCategories(['environments']))
            .filter((environment) =>
                this.piaRelationService.isTransitivelyCompatible(
                    productId,
                    environment.id,
                    regions,
                    [PiaItemState.EXTERNALLY_ANNOUNCED],
                    MOUNTING_CATEGORIES,
                ),
            )
            .map(({ name, id }) => ({
                name: this.translateEnvironment(name),
                originalName: name,
                productId: id,
            }));

    public getEnvironment = (environmentId: PiaId): IEnvironment | null =>
        this.piaItemService
            .get(environmentId)
            .map(({ name, id }) => ({
                name: this.translateEnvironment(name),
                originalName: name,
                productId: id,
            }))
            .first();

    private translateEnvironment = (name: string) =>
        t(`accessoryEnvironmentsGROUP.${ENVIRONMENT_NAME_TO_TRANSLATION_KEY[name] || name}`);
}
