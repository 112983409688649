import { getPartnerConfig } from 'app/modules/common';
import { createSelector } from 'reselect';

const IDS_WITH_BIDCON_EXPORT = [
    // Test partner
    '118738',
    // Securitas
    '200194',
];

export const getShowBidconExport = createSelector([getPartnerConfig], (partnerConfig) => {
    return IDS_WITH_BIDCON_EXPORT.includes(partnerConfig.id);
});
