import { PiaRelationTypes } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getPiaIdFromProps } from '../../selectors/getIdFromProps';
import { getPiaItemsRecord } from './getPiaDevices';

/**
 * Check if a piaItem with id has virtual products
 */
export const hasVirtualRelatedItems = createCachedSelector(
    [getPiaItemsRecord, getPiaIdFromProps],
    (piaItems, productId) => {
        if (!productId) return false;

        const piaItem = piaItems[productId];
        return piaItem.relations.some(
            (relation) => relation.relationType === PiaRelationTypes.VirtuallyIncludes,
        );
    },
)(toCacheKey);
