import { Box, Button, Carousel, Positioned, Stack, Text } from 'app/components';
import type {
    IFloorPlanEntity,
    IInstallationPointEntity,
    IPersistence,
    Id,
} from 'app/core/persistence';
import { useService } from 'app/ioc';
import {
    NumberedMarker,
    MapProvider,
    getMapsPerDevice,
    getDeviceIpMarkerLabels,
    getInstallationPointsPerMap,
    FloorPlans,
} from 'app/modules/maps';
import type { IStoreState } from 'app/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { CommonActionService, getIsLocalProject, getUserSignedIn } from 'app/modules/common';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';

const MULTI_MAP_WIDTH = 680;
const MAP_CONTAINER_WIDTH = 640;

const renderMap = (
    maps: IFloorPlanEntity[],
    deviceId: Id,
    markerLabels: Record<string, string>,
    ipPerFloorPlan: Record<string, IPersistence<IInstallationPointEntity>[]>,
    fullWidth?: boolean,
) => {
    return maps.map((floorPlan) => {
        const ips = ipPerFloorPlan[floorPlan._id] ?? [];
        return (
            <Box
                flex="none"
                width={fullWidth ? '100%' : '600px'}
                height={fullWidth ? '250px' : '400px'}
                key={`${deviceId + floorPlan._id}`}
                direction="column"
                position="relative"
            >
                <MapProvider
                    fitToMarkers
                    readOnly
                    floorPlan={floorPlan}
                    googleMapDivId={`${deviceId + floorPlan._id}_map_root`}
                >
                    {ips.map((ip) => (
                        <NumberedMarker
                            installationPointId={ip._id}
                            markerLabels={markerLabels}
                            key={ip._id}
                            floorPlanId={floorPlan._id}
                        />
                    ))}
                    <FloorPlans floorPlan={floorPlan} renderDerotated />
                </MapProvider>
                {!floorPlan.isDefault && (
                    <Positioned sendForward position="absolute" top="4px">
                        <Box justifyContent="center" width="100%">
                            <Box
                                color="whiteOpacity"
                                paddingX="base"
                                paddingY="halfQuart"
                                borderRadius="rounded"
                            >
                                <Text style="heading" color="grey9">
                                    {floorPlan.name}
                                </Text>
                            </Box>
                        </Box>
                    </Positioned>
                )}
            </Box>
        );
    });
};

interface IDeviceListMap {
    deviceId: Id;
    fullWidth?: boolean;
}

// sort StreetMaps before FloorPlans
const byMapType = (a: IFloorPlanEntity, b: IFloorPlanEntity) => (a.mapType > b.mapType ? -1 : 1);

const DeviceListMap = ({ deviceId, fullWidth }: IDeviceListMap) => {
    const commonActions = useService(CommonActionService);

    const isSignedIn = useSelector(getUserSignedIn);
    const isLocalProject = useSelector(getIsLocalProject);
    const mapsPerDevice = useSelector(getMapsPerDevice);
    const maps = mapsPerDevice[deviceId] ? mapsPerDevice[deviceId].sort(byMapType) : [];

    const markerLabels = useSelector((state: IStoreState) =>
        getDeviceIpMarkerLabels(state, deviceId),
    );

    const ipsPerMap = useSelector(getInstallationPointsPerMap);

    const ipsPerFloorPlan = React.useMemo(
        () =>
            Object.keys(ipsPerMap).reduce(
                (acc, mapId) => {
                    const installationPoints = ipsPerMap[mapId];
                    const filteredInstallationPoints = installationPoints.filter(
                        (installationPoint) => installationPoint.path[1] === deviceId,
                    );
                    return { ...acc, [mapId]: filteredInstallationPoints };
                },
                {} as Record<Id, IInstallationPointEntity[]>,
            ),
        [ipsPerMap, deviceId],
    );

    if (!maps) return null;

    if (!isSignedIn && !isLocalProject) {
        const signIn = () => {
            eventTracking.logUserEvent('Application', 'Login');
            commonActions.login();
        };
        return (
            <Box
                width={fullWidth ? '100%' : MULTI_MAP_WIDTH}
                alignItems="center"
                justifyContent="center"
                height="100%"
            >
                <Stack vertical notFullWidth>
                    <Text color="grey6" style="semibold">
                        {t.viewMapsAuthErrorMessage}
                    </Text>
                    <Box justifyContent="center">
                        <Button primary onClick={signIn}>
                            {t.userSignIn}
                        </Button>
                    </Box>
                </Stack>
            </Box>
        );
    }

    return (
        <Box width={fullWidth ? '100%' : MULTI_MAP_WIDTH} position="relative" alignItems="center">
            {maps.length > 1 ? (
                <Carousel>
                    {renderMap(maps, deviceId, markerLabels, ipsPerFloorPlan, fullWidth)}
                </Carousel>
            ) : (
                <Box width={fullWidth ? '100%' : MAP_CONTAINER_WIDTH}>
                    {renderMap(maps, deviceId, markerLabels, ipsPerFloorPlan, fullWidth)}
                </Box>
            )}
        </Box>
    );
};

export default DeviceListMap;
