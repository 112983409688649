import * as leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import {
    convert,
    distanceFromOrigo,
    offset,
    rotate,
    trigonometry,
    type Point,
} from 'axis-webtools-util';
import { calculateSpeakerArea, calculateSoundPressureLevel, toPolygon } from '../../../utils';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { LeafletMap } from '../LeafletMap';
import type {
    ILatLng,
    IInstallationPointSpeaker,
    IItemEntity,
    SpeakerPlacement,
    IInstallationPointModel,
    Id,
    ISpeakerItemEntity,
    PolyLine,
    IInstallationPoint,
} from 'app/core/persistence';
import { DistanceUnit, distanceUnitShortText } from 'app/core/persistence';
import type { IPiaSpeaker } from 'app/core/pia';
import { BaseCone } from './BaseCone';
import { getPossibleSpeakerPlacements } from 'app/modules/common';
import { t } from 'app/translate';
import { IconStyle } from 'app/components';

export class SpeakerCone extends BaseCone {
    public targetHeight: number;
    public cameraHeight: number;
    public horizontalAngle: number;
    public coverage: number;
    public placement: SpeakerPlacement;
    public basicSolution: boolean;
    public outdoor: boolean;
    public isGeneric: boolean;
    public isHornSpeaker: boolean;
    public maxHeight: number;
    public soundPressureLevel?: number;
    public targetDistance: number;
    public fovHandleLatLng: leaflet.LatLng | undefined;

    private possiblePlacements: SpeakerPlacement[] = ['wall', 'ceiling'];

    constructor(
        currentSpeaker: IInstallationPointSpeaker,
        private installationPointId: Id,
        location: ILatLng,
        piaSpeaker: IPiaSpeaker,
        speakerItem: ISpeakerItemEntity,
        height: number,
        map: LeafletMap,
        color: Colors,
        private distanceUnit: DistanceUnit,
    ) {
        super(map, leaflet.latLng(location.lat, location.lng), color);

        this.isGeneric = !piaSpeaker;
        this.coverage = piaSpeaker?.properties.horizontalSpeakerCoverage ?? 0;
        this.maxHeight = Math.min(piaSpeaker?.properties.maxRecommendedMountingHeight ?? 0, 20);
        this.isHornSpeaker = piaSpeaker?.properties.formFactor === 'horn';
        this.soundPressureLevel = piaSpeaker?.properties.soundPressureLevel;
        this.placement = speakerItem.properties.speaker!.filter.placement ?? 'ceiling';

        if (piaSpeaker) {
            this.possiblePlacements = getPossibleSpeakerPlacements(piaSpeaker);
        }

        this.targetHeight = currentSpeaker.target.height;
        this.targetDistance = currentSpeaker.target.distance;
        this.horizontalAngle = currentSpeaker.target.horizontalAngle;
        this.cameraHeight = height;
        this.basicSolution = currentSpeaker.settings.basicSolution;
        this.outdoor = currentSpeaker.settings.outdoor;
        this.setColor(color);
    }

    public getHasTargetLine = () => {
        return true;
    };

    public updateDoriPixelsOn(): void {
        // noop
    }

    public toggleTinyIcons(): void {
        // noop
    }

    public getRotatedTarget(
        installationPoint: IInstallationPoint,
        rotationOffsetInDeg: number,
    ): DeepPartial<IInstallationPoint> {
        const rotatedTarget = this.rotateTargetAngle(
            'speaker',
            installationPoint,
            rotationOffsetInDeg,
        );
        return rotatedTarget;
    }

    public getTargetPopupContent = (targetDistance: number) => {
        const euclideanDistance = Math.sqrt(
            Math.pow(targetDistance, 2) + Math.pow(this.cameraHeight - this.targetHeight, 2),
        );
        const spl =
            this.soundPressureLevel &&
            calculateSoundPressureLevel(this.soundPressureLevel, euclideanDistance);
        const distance =
            this.distanceUnit === DistanceUnit.Feet
                ? convert.metersToFeet(targetDistance)
                : targetDistance;
        const distanceAbbreviation = distanceUnitShortText(this.distanceUnit);

        const splMarkup = () => {
            if (!spl) {
                return '';
            }

            return `<tr>
            <td style="text-align: right">${spl.toFixed()}</td>
            <td style="color: ${ColorsEnum.grey4};">${t.abbreviationsGROUP.decibel}</td>
        </tr>`;
        };

        return `
            <table>
                <tr>
                    <td rowSpan="2">
                        <i
                            class="${IconStyle}"
                            style="color: ${ColorsEnum.grey4};"
                            width="25px">accessibility
                        </i>
                    </td>
                    <td style="text-align: right">${distance.toFixed(1)}</td>
                    <td style="color: ${ColorsEnum.grey4};">${distanceAbbreviation}</td>
                </tr>
                ${splMarkup()}
            </table>
        `;
    };

    public getFovPopupContent = (_angle: number) => {
        return null;
    };

    public getAnalyticsPopupContent() {
        return null;
    }

    public getTargetCoords(): ILatLng {
        const rotationAngleRad = trigonometry.toRadians(this.horizontalAngle);

        const targetPos: Point = [0, -this.targetDistance];
        const rotatedPos = rotate(rotationAngleRad)(targetPos);
        return offset(this.latLng)(rotatedPos);
    }

    public changeLocation(location: ILatLng, blockers: PolyLine[] | undefined) {
        this.reDraw(blockers, { location: leaflet.latLng(location.lat, location.lng) });
    }

    public changeDeviceHeight = (cameraHeight: number, blockers: PolyLine[] | undefined) => {
        this.reDraw(blockers, { cameraHeight });
    };

    public changeDevice = (piaDevice: IPiaSpeaker | null, parentDevice: IItemEntity) => {
        this.isGeneric = !piaDevice;
        this.coverage = piaDevice?.properties.horizontalSpeakerCoverage ?? 0;
        this.maxHeight = Math.min(piaDevice?.properties.maxRecommendedMountingHeight ?? 0, 20);
        this.isHornSpeaker = piaDevice?.properties.formFactor === 'horn';
        this.soundPressureLevel = piaDevice?.properties.soundPressureLevel;
        this.placement = parentDevice.properties.speaker!.filter.placement ?? 'ceiling';

        if (piaDevice) {
            this.possiblePlacements = getPossibleSpeakerPlacements(piaDevice);
        }
    };

    public update(ip: IInstallationPointModel, blockers: PolyLine[] | undefined) {
        const speaker = ip.speaker;
        const piaSpeaker = ip.parentPiaDevice as IPiaSpeaker;
        const speakerEntity = ip.parentDevice.properties.speaker;
        if (!speaker || !speakerEntity) return;

        // Properties might updated due to change device
        this.isGeneric = !piaSpeaker;
        this.coverage = piaSpeaker?.properties.horizontalSpeakerCoverage ?? 0;
        this.maxHeight = Math.min(piaSpeaker?.properties.maxRecommendedMountingHeight ?? 0, 20);
        this.isHornSpeaker = piaSpeaker?.properties.formFactor === 'horn';
        this.soundPressureLevel = piaSpeaker?.properties.soundPressureLevel;
        this.placement = speakerEntity!.filter.placement ?? 'ceiling';
        if (piaSpeaker) {
            this.possiblePlacements = getPossibleSpeakerPlacements(piaSpeaker);
        }

        this.reDraw(blockers, {
            targetDistance: speaker.target.distance,
            cameraHeight: ip.height,
            targetHeight: speaker.target.height,
            horizontalAngle: speaker.target.horizontalAngle,
            location: leaflet.latLng(ip.location.lat, ip.location.lng),
            basicSolution: speaker.settings.basicSolution,
            outdoor: speaker.settings.outdoor,
            placement: speakerEntity.filter.placement,
        });
    }

    public persistCoverageInfo = async ({
        distance = this.targetDistance,
        height = this.targetHeight,
        horizontalAngle = this.horizontalAngle,
        basicSolution = this.basicSolution,
        outdoor = this.outdoor,
    }) => {
        const target = { distance, height, horizontalAngle };
        const settings = { basicSolution, outdoor };
        const speaker: IInstallationPointSpeaker = { target, settings };

        return this.mapsService.updateCoverageArea(this.installationPointId, {
            speaker,
        });
    };

    public reDraw(
        blockers: PolyLine[] | undefined,
        {
            targetDistance = this.targetDistance,
            cameraHeight = this.cameraHeight,
            targetHeight = this.targetHeight,
            horizontalAngle = this.horizontalAngle,
            location = this.latLng,
            placement = this.placement,
            basicSolution = this.basicSolution,
            outdoor = this.outdoor,
        },
    ) {
        try {
            if (this.isGeneric) {
                // hide the visible area if the camera is generic
                this.applyBlockerShadows(blockers ?? [], {
                    renderHorizon: 0,
                    resolutionGuide: toPolygon([]),
                });
                return;
            }

            this.latLng = location;
            this.horizontalAngle = horizontalAngle;
            this.targetDistance = targetDistance;
            this.cameraHeight = cameraHeight;
            this.targetHeight = targetHeight;
            this.placement = placement;
            this.basicSolution = basicSolution;
            this.outdoor = outdoor;

            if (!this.possiblePlacements.includes(this.placement)) {
                this.placement = this.possiblePlacements[0];
            }
            const audibleArea = calculateSpeakerArea(
                this.placement,
                this.cameraHeight,
                this.targetHeight,
                this.targetDistance,
                this.coverage,
                this.basicSolution,
                this.outdoor,
                this.isHornSpeaker,
                this.maxHeight,
            );

            const rotationAngleRad = trigonometry.toRadians(this.horizontalAngle + 90);
            const rotatedAudibleArea = audibleArea.map(rotate(rotationAngleRad));

            const renderHorizon = audibleArea.reduce((acc, point) => {
                return Math.max(acc, distanceFromOrigo(point));
            }, 0);

            this.applyBlockerShadows(blockers, {
                renderHorizon,
                resolutionGuide: toPolygon(rotatedAudibleArea),
            });
        } catch (error) {
            console.error(error);
        }
    }

    protected onFovHandleDrag = (_e: leaflet.LeafletEvent) => {
        throw Error('onFovHandleDrag not supported for speakers');
    };

    public getIsFovAdjustable() {
        return false;
    }

    public getIsTargetAdjustable() {
        return !this.isGeneric;
    }

    public getIconInfo() {
        return undefined;
    }

    public updateRangeAnalytics() {
        // noop
    }
    public getHasDirectionalArrow() {
        return this.placement === 'wall' && this.getIsTargetAdjustable();
    }
    public getFovHandleLatLng() {
        return undefined;
    }
}
