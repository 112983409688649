import type { IAlerterItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaAlerter } from 'app/core/pia';
import { CategoryEnum } from 'app/modules/common';
import { injectable } from 'inversify';
import type { IInstallationReportAlerterDevice } from '../../models';
import { BaseDeviceService } from './BaseDevice.service';

@injectable()
export class AlerterDeviceService {
    constructor(private baseDeviceService: BaseDeviceService) {}

    public async mapDevice(
        item: IPersistence<IAlerterItemEntity>,
        piaItem: IPiaAlerter,
    ): Promise<IInstallationReportAlerterDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Alerters,
        };
    }
}
