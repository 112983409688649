import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type {
    ScenarioId,
    IProfileEntity,
    IPersistence,
    Id,
    IScenarioSettingsModel,
} from 'app/core/persistence';
import { ProfileService, mapToScenarioEntity } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import type { ILightCondition, IScenarioModel } from 'app/modules/common';
import { ScenarioEditorComponent, ScenarioService, getProfile } from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import { getProfileScenarioSettings } from '../common/profile/selectors/getScenarioSettings';

interface IScenarioEditorOwnProps {
    profileId: Id;
}

interface IScenarioEditorProps extends IScenarioEditorOwnProps {
    profile?: IPersistence<IProfileEntity>;
    scenarios: IScenarioModel[];
    scenario?: IScenarioModel;
    lightConditions: ILightCondition[];
    scenarioSettings: IScenarioSettingsModel;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IScenarioEditorOwnProps,
): IScenarioEditorProps => {
    const profile = getProfile(state, ownProps.profileId);
    const scenarioService = ServiceLocator.get(ScenarioService);
    const scenario = profile ? scenarioService.getScenario(profile.scenario.scenarioId) : undefined;
    const scenarioSettings = getProfileScenarioSettings(state, ownProps.profileId);

    return {
        profile,
        scenarios: scenarioService.getScenarios(),
        scenario,
        lightConditions: scenarioService.getLightConditions(),
        scenarioSettings,
        ...ownProps,
    };
};

class ScenarioEditorContainer extends React.Component<IScenarioEditorProps> {
    private profileService: ProfileService;
    private scenarioService: ScenarioService;

    constructor(props: IScenarioEditorProps) {
        super(props);
        this.profileService = ServiceLocator.get(ProfileService);
        this.scenarioService = ServiceLocator.get(ScenarioService);
    }

    public render() {
        return (
            <>
                {this.props.scenario && (
                    <ScenarioEditorComponent
                        scenarios={this.props.scenarios}
                        scenario={this.props.scenario}
                        lightConditions={this.props.lightConditions}
                        scenarioSettings={this.props.scenarioSettings}
                        onScenarioIdChange={this.onScenarioIdChange}
                        onScenarioSettingsModelChange={this.onScenarioSettingsChange}
                    />
                )}
            </>
        );
    }

    private onScenarioIdChange = async (id: ScenarioId) => {
        if (this.props.profile) {
            eventTracking.logUserEvent('Project Scenarios', 'Scene Changed', id);
            const profile = this.updateScenarioAndRecordingSettings(this.props.profile, id);
            return this.profileService.updateProfileEntity(profile);
        }
    };

    private onScenarioSettingsChange = (settings: IScenarioSettingsModel) => {
        if (this.props.profile) {
            this.props.profile.scenario = mapToScenarioEntity(settings);
            return this.profileService.updateProfileEntity(this.props.profile);
        }
    };

    private updateScenarioAndRecordingSettings(
        profile: IPersistence<IProfileEntity>,
        scenarioId: ScenarioId,
    ): IPersistence<IProfileEntity> {
        const scenario = this.scenarioService.getScenario(scenarioId);
        if (scenario) {
            profile.scenario = {
                scenarioId: scenario.id,
                lightStart: scenario.dependencies.scenarioLightStart.toPersistable(),
                lightEnd: scenario.dependencies.scenarioLightEnd.toPersistable(),
                nightLighting: scenario.dependencies.nightLighting,
                sceneDetails: scenario.dependencies.sceneDetails,
            };
            profile.triggeredRecording.dayMotion = scenario.dependencies.triggeredDayMotion;
            profile.triggeredRecording.nightMotion = scenario.dependencies.triggeredNightMotion;
            profile.continuousRecording.dayMotion = scenario.dependencies.continuousDayMotion;
            profile.continuousRecording.nightMotion = scenario.dependencies.continuousNightMotion;
            profile.liveView.dayMotion = scenario.dependencies.continuousDayMotion;
            profile.liveView.nightMotion = scenario.dependencies.continuousNightMotion;
        }
        return profile;
    }
}

export const ScenarioEditor = connect(mapStateToProps)(ScenarioEditorContainer);
