import * as React from 'react';
import { css } from '@emotion/css';
import type { IExtendableComponentWithChildren } from '../../models';
import type { ExtendableProps } from '../../services';
import { extendableProps, renderReactChildren } from '../../services';

const ClickableStyle = css`
    cursor: pointer;
    &:focus {
        outline-offset: 0.2em;
        outline-color: rgba(0, 0, 0, 0.5);
        outline-width: 1px;
        outline-style: dotted;
    }
    &:focus:hover,
    &:focus:active {
        outline: none;
    }
    &[aria-disabled='true'] {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
    }
    &[aria-readonly='true'] {
        cursor: default;
        pointer-events: none;
    }
`;

export interface IClickableProps extends IExtendableComponentWithChildren {
    /**
     * Prevent the click event from propagating to other listeners
     * */
    stopPropagation?: boolean;
    /**
     * If disabled, no pointer events are
     * triggered and the element appears
     * slightly transparent.
     */
    disabled?: boolean;
    /**
     * If readOnly, the element is not editable, but appears without transparency.
     */
    readOnly?: boolean;
    /**
     * Set a tabindex to allow defining
     * the tabbing order. Defaults to 0
     * (ie. focusable in the html order)
     */
    tabIndex?: number;
    /**
     * Only provide onClick handler, do not
     * alter the interaction. Do not add
     * focus handling, click handling or classes.
     */
    noInteraction?: boolean;
    /**
     * Enable this to stop the onClick action from triggering
     * when clicking an element inside the Clickable.
     */
    allowClickThrough?: boolean;
    /**
     * Enable this to allow drag events to pass through
     */
    allowDragThrough?: boolean;
    /**
     * The action to trigger on click.
     */
    onClick?(event: React.SyntheticEvent): void;
}

/**
 * Takes a child and adds an
 * onClick handler together with focusability
 * and appropriate functional styling.
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export class Clickable extends React.PureComponent<IClickableProps> {
    private clickFn: React.EventHandler<React.SyntheticEvent> | undefined;

    private isSwiping = false;
    private hasMouseDown = false;

    public render() {
        const { children, disabled, readOnly, tabIndex, onClick, noInteraction, ...extendedProps } =
            this.props;

        this.clickFn =
            (extendedProps &&
                extendedProps.__htmlAttributes &&
                extendedProps.__htmlAttributes.onClick) ||
            onClick;

        const attributes = noInteraction
            ? extendableProps(extendedProps, { onClick: this.triggerEvent })
            : extendableProps(extendedProps, {
                  onClick: this.props.stopPropagation ? this.onClick : undefined,
                  onClickCapture: this.props.stopPropagation ? this.onClick : undefined,
                  onMouseDownCapture: this.onMouseDown,
                  onMouseUp: this.clickFn ? this.onMouseUp : undefined,
                  onKeyDown: this.onKeyDown,
                  onKeyUp: this.clickFn ? this.onKeyUp : undefined,
                  onTouchMove: this.clickFn ? this.onTouchMove : undefined,
                  onTouchStart: this.clickFn ? this.onTouchStart : undefined,
                  onTouchEnd: this.clickFn ? this.onTouchEnd : undefined,
                  tabIndex: disabled ? -1 : tabIndex || 0,
                  className: this.clickFn ? ClickableStyle : undefined,
                  'aria-disabled': disabled,
                  'aria-readonly': readOnly,
              });

        return this.renderChildren(children, attributes);
    }

    private renderChildren = (children: React.ReactNode, attributes: ExtendableProps) =>
        renderReactChildren(
            children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes.__htmlAttributes),
        );

    private triggerEvent = (event: React.SyntheticEvent) => {
        const activeElement = document.activeElement as HTMLElement | null;

        if (activeElement && activeElement !== event.target) {
            activeElement.blur();
        }

        if (this.shouldClickThrough(event)) {
            return event;
        }

        return this.props.disabled || this.props.readOnly || !this.clickFn
            ? false
            : this.clickFn(event);
    };

    /**
     * Prevent the click event from propagating to other listeners
     */
    private onClick = (event: React.PointerEvent<HTMLElement>) => {
        if (this.props.stopPropagation) {
            event.preventDefault();
            event.stopPropagation();

            // When stop propagation is active we need to trigger the event
            // onClick, otherwise we won't stop the click event from bubbling
            this.triggerEvent(event);
        }
    };

    /**
     * Prevent other events from happening before click.
     * Ie. focus happens after the click instead of before.
     */
    private onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
        this.hasMouseDown = true;
        if (this.shouldClickThrough(event) || this.shouldDragThrough()) {
            return event;
        }
        event.preventDefault();
        if (this.props.stopPropagation) {
            event.stopPropagation();
        }
    };

    private onMouseUp = (event: React.MouseEvent<HTMLElement>) => {
        if (!this.hasMouseDown) {
            // Only trigger mouse up event if mouse down was captured
            return;
        }
        this.hasMouseDown = false;
        if (this.props.stopPropagation) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (this.shouldDragThrough() && !this.isSwiping) {
            this.triggerEvent(event);
        }
        if (event.button === 0 && !this.shouldDragThrough() && !this.props.stopPropagation) {
            this.triggerEvent(event);
        }
    };

    private onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (this.shouldClickThrough(event)) {
            return event;
        }

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
        }

        if (this.props.stopPropagation) {
            event.stopPropagation();
        }
    };

    private onKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            this.triggerEvent(event);
        }
    };

    private onTouchStart = () => {
        this.isSwiping = false;
        this.hasMouseDown = !this.props.stopPropagation;
    };

    private onTouchMove = () => {
        this.isSwiping = true;
    };

    private onTouchEnd = (event: React.TouchEvent<HTMLElement>) => {
        if (this.isSwiping === false) {
            event.preventDefault();
            this.triggerEvent(event);
            // Simulate MouseEvent type 'click'.
            // This will trigger event listener in DropDown component to close.
            // Do not simulate when drop-down should not be closed (ex. delete)
            if (
                this.hasMouseDown &&
                !this.props.stopPropagation &&
                !this.props.__htmlAttributes?.['data-should-not-close-drop-down']
            ) {
                event.currentTarget.click();
            }
        }
    };

    private shouldDragThrough = () => this.props.allowDragThrough;

    private shouldClickThrough = (event: React.SyntheticEvent) =>
        this.props.allowClickThrough && event.target !== event.currentTarget;
}
