import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getCurrentProjectLocation, GoogleStaticMap } from 'app/modules/common';
import type { IExtendableComponentWithChildren } from 'app/components';
import { useResizeObserver } from 'app/hooks';

interface IProjectLocationContainerProps extends IExtendableComponentWithChildren {
    project: IStoreState['currentProject']['project'];
    projectCountry: string;
}

const mapStateToProps = (storeState: IStoreState): IProjectLocationContainerProps => {
    const location = getCurrentProjectLocation(storeState);
    return {
        project: storeState.currentProject.project,
        projectCountry: location?.name ?? '',
    };
};

const ProjectLocationContainer: React.FunctionComponent<IProjectLocationContainerProps> = ({
    project,
    projectCountry,
    children,
}) => {
    const [mapWidth, setMapWidth] = React.useState<number>(990);
    let timeOut: NodeJS.Timeout | null = null;
    let currentMapWidth = mapWidth;

    React.useEffect(() => {
        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            }
        };
    }, [timeOut]);

    const { ref } = useResizeObserver<HTMLDivElement>({
        onResize: ({ width: containerWidth }) => {
            containerWidth && updateMapWidth(containerWidth);
        },
    });

    const updateMapWidth = (width: number) => {
        currentMapWidth = width;
        if (!timeOut) {
            timeOut = setTimeout(() => {
                setMapWidth(currentMapWidth);
                timeOut = null;
            }, 500);
        }
    };

    if (!project) {
        return null;
    }

    return (
        <div style={{ width: '100%' }} ref={ref}>
            <GoogleStaticMap
                width={mapWidth}
                lat={project.location?.lat}
                lng={project.location?.lng}
                location={projectCountry}
                bounds={project.bounds}
            >
                {children}
            </GoogleStaticMap>
        </div>
    );
};

ProjectLocationContainer.displayName = 'ProjectLocationContainer';

export const ProjectLocation = connect(mapStateToProps)(ProjectLocationContainer);
