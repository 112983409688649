import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { UnitSystem, PowerCalcMethod, TemperatureScale } from 'app/core/persistence';
import {
    ProjectService,
    DistanceUnit,
    defaultInstallationHeightMetric,
    defaultInstallationHeightImperial,
} from 'app/core/persistence';
import { Stack, Text, Box, Toggle, ToggleItem, Card, NumberInput } from 'app/components';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { ServiceLocator } from 'app/ioc';
import {
    getCurrentProjectCustomInstallationHeight,
    getCurrentProjectDisplayUnit,
} from 'app/modules/common';
import { convert } from 'axis-webtools-util';

interface IEditProjectSettingsContainerProps {
    project: IStoreState['currentProject']['project'];
    displayUnit: DistanceUnit;
    maxInstallationHeight: number;
    customInstallationHeight: number;
}

const mapStateToProps = (storeState: IStoreState): IEditProjectSettingsContainerProps => {
    const displayUnit = getCurrentProjectDisplayUnit(storeState);
    const maxInstallationHeight = displayUnit === DistanceUnit.Meter ? 50 : 150;
    const customInstallationHeight = getCurrentProjectCustomInstallationHeight(storeState);

    return {
        project: storeState.currentProject.project,
        displayUnit,
        maxInstallationHeight,
        customInstallationHeight,
    };
};

export class EditProjectSettingsContainer extends React.Component<IEditProjectSettingsContainerProps> {
    private projectService: ProjectService;

    constructor(props: IEditProjectSettingsContainerProps) {
        super(props);
        this.projectService = ServiceLocator.get(ProjectService);
    }

    public render() {
        if (!this.props.project) {
            return null;
        }

        return (
            <Box testId="modal_settings" padding="base" width="400px">
                <Stack vertical spacing="panel">
                    <Card paddingX="base" paddingY="base">
                        <Stack vertical spacing="panel">
                            <Stack vertical spacing="cell" alignItems="center">
                                <Text style="semibold">{t.projectUnitSystem}</Text>
                                <Box>
                                    <Toggle>
                                        <ToggleItem
                                            testId="tgl_metric"
                                            title={t.projectMetric}
                                            active={this.props.project.unitSystem === 'metric'}
                                            onClick={() => this.changeUnitSystem('metric')}
                                        />
                                        <ToggleItem
                                            testId="tgl_imperial"
                                            title={t.projectImperial}
                                            active={this.props.project.unitSystem === 'imperial'}
                                            onClick={() => this.changeUnitSystem('imperial')}
                                        />
                                    </Toggle>
                                </Box>
                            </Stack>

                            <Stack vertical spacing="cell" alignItems="center">
                                <Text style="semibold">{t.projectPowerCalculation}</Text>
                                <Box direction="column">
                                    <Toggle>
                                        <ToggleItem
                                            testId="tgl_max_power"
                                            title={t.projectMaxPower}
                                            active={
                                                this.props.project.powerCalcMethod === 'maxPower'
                                            }
                                            onClick={() => this.changePowerCalculation('maxPower')}
                                        />
                                        <ToggleItem
                                            testId="tgl_poe_class"
                                            title={t.projectPoeClass}
                                            active={
                                                this.props.project.powerCalcMethod === 'poeClass'
                                            }
                                            onClick={() => this.changePowerCalculation('poeClass')}
                                        />
                                    </Toggle>
                                </Box>
                            </Stack>
                            <Stack vertical spacing="cell" alignItems="center">
                                <Text style="semibold">{t.temperatureScale}</Text>
                                <Box>
                                    <Toggle>
                                        <ToggleItem
                                            testId="tgl_celsius"
                                            title={t.celsius}
                                            active={
                                                this.props.project.temperatureScale === 'celsius'
                                            }
                                            onClick={() => this.changeTemperatureScale('celsius')}
                                        />
                                        <ToggleItem
                                            testId="tgl_fahrenheit"
                                            title={t.fahrenheit}
                                            active={
                                                this.props.project.temperatureScale === 'fahrenheit'
                                            }
                                            onClick={() =>
                                                this.changeTemperatureScale('fahrenheit')
                                            }
                                        />
                                    </Toggle>
                                </Box>
                                <Box paddingTop={'panel'} direction={'row'}>
                                    <Stack spacing={'base'}>
                                        <Text style={'semibold'}>
                                            {t.speakerSelectorInstallationHeight}
                                        </Text>
                                        <NumberInput
                                            decimals={1}
                                            min={0.1}
                                            max={this.props.maxInstallationHeight}
                                            step={0.1}
                                            changeCriteria={'debounced'}
                                            unit={
                                                this.props.displayUnit === DistanceUnit.Meter
                                                    ? t.abbreviationsGROUP.meter
                                                    : t.abbreviationsGROUP.feet
                                            }
                                            value={
                                                this.props.displayUnit === DistanceUnit.Meter
                                                    ? this.props.customInstallationHeight
                                                    : convert.metersToFeet(
                                                          this.props.customInstallationHeight,
                                                      )
                                            }
                                            onChange={this.changeCustomInstallationHeight}
                                        />
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>
            </Box>
        );
    }

    private changeUnitSystem = async (unitSystem: UnitSystem) => {
        eventTracking.logUserEvent('User Projects', 'Change Unit System', unitSystem);
        // If user changes unitSystem to imperial without altering default height
        // we want our default height for imperial to be chosen, rather than exactly what the default
        // metric installation height translates to - i.e. 10 ft instead of 9.8 ft

        if (
            unitSystem === 'imperial' &&
            this.props.customInstallationHeight === defaultInstallationHeightMetric
        ) {
            return this.projectService.updateCurrentProject({
                unitSystem,
                customInstallationHeight: defaultInstallationHeightImperial,
            });
        }
        if (
            unitSystem === 'metric' &&
            this.props.customInstallationHeight === defaultInstallationHeightImperial
        ) {
            return this.projectService.updateCurrentProject({
                unitSystem,
                customInstallationHeight: defaultInstallationHeightMetric,
            });
        }
        return this.projectService.updateCurrentProject({ unitSystem });
    };

    private changePowerCalculation = (powerCalcMethod: PowerCalcMethod) => {
        eventTracking.logUserEvent('User Projects', 'Change Power Calculation', powerCalcMethod);
        this.projectService.updateCurrentProject({ powerCalcMethod });
    };

    private changeTemperatureScale = (temperatureScale: TemperatureScale) => {
        eventTracking.logUserEvent('User Projects', 'Change Temperature Scale', temperatureScale);
        this.projectService.updateCurrentProject({ temperatureScale });
    };

    private changeCustomInstallationHeight = async (customInstallationHeight: number) => {
        eventTracking.logUserEvent('User Projects', 'Change Custom Installation Height');
        if (this.props.displayUnit === DistanceUnit.Feet) {
            return this.projectService.updateCurrentProject({
                customInstallationHeight: convert.feetToMeters(customInstallationHeight),
            });
        }
        return this.projectService.updateCurrentProject({ customInstallationHeight });
    };
}
export const EditProjectSettings = connect(mapStateToProps)(EditProjectSettingsContainer);
