import { injectable } from 'inversify';
import type { IPersistenceRepository, IPersistence } from '../repositories';
import { CreateEntityService, getDefaultProjectQuotationEntity } from '../repositories';
import type { Id, IEntity, IItemRelationEntity } from '../entities';
import type { IIdRev } from '../models';
import { eventTracking } from 'app/core/tracking';
import { tail } from 'lodash-es';
import * as moment from 'moment';

/**
 * Service that should be used to find and fix errors and remove dangling entities in the database.
 */

@injectable()
export class RepairService {
    constructor(private createEntityService: CreateEntityService) {}

    /**
     * Check and repair a project
     * @param projectId the project to check and repair if errors are found
     * @param repository repository to use for the operations
     */
    public async checkAndRepairProject(
        projectId: Id,
        repository: IPersistenceRepository,
    ): Promise<void> {
        const entities = await repository.getDescendants(projectId);
        await this.repairQuotationIfNeeded(entities, projectId, repository);

        // The repository.delete method passed to RepairService doesn't delete descendants at the time of this writing.
        // Consequently the repository.delete method should not be used in RepairService after dangling docs have been removed.
        const danglingDocs = this.getDanglingDocs(entities);

        if (danglingDocs.length > 0) {
            await repository.bulkDelete(danglingDocs);
        }
    }

    /**
     * Checks so that there is only one quotation on the project.
     * If there is none it will create an empty quotation, if there is more then one
     * it will only keep the latest updated.
     */
    private async repairQuotationIfNeeded(
        entities: IEntity[],
        projectId: Id,
        repository: IPersistenceRepository,
    ) {
        const quotationPrefix = 'quotation';

        const quotations = entities.filter((entity) => {
            return entity._id.startsWith(quotationPrefix + ':');
        });

        if (quotations.length === 0) {
            repository.add(
                this.createEntityService.create(
                    quotationPrefix,
                    getDefaultProjectQuotationEntity([projectId]),
                ),
            );
        } else if (quotations.length > 1) {
            const oldestQuotations = tail(
                quotations.sort((a, b) => moment(b.updatedDate).diff(a.updatedDate)),
            );

            for (const quotation of oldestQuotations) {
                await repository.delete({ _id: quotation._id, _rev: quotation._rev });
            }
        }
    }

    /**
     * Gets all the dangling ids for a set of entities by looking at the `path` property and itemRelations.
     * If an entity reference an id that does not exist in the list it should be returned.
     *
     * @param entities the entities to check for dangling ids
     * @returns Dangling Ids and revs
     */
    private getDanglingDocs(entities: IEntity[]): IIdRev[] {
        const paths: Id[][] = [];
        const itemRelations: Array<IPersistence<IItemRelationEntity>> = [];

        const danglingIds: Record<Id, IIdRev> = {};
        const idMap: Record<Id, IEntity> = {};

        entities.forEach((entity) => {
            idMap[entity._id] = entity;
            paths.push(entity.path);

            if (entity._id.startsWith('itemRelation:')) {
                itemRelations.push(entity as IPersistence<IItemRelationEntity>);
            }
        });

        // If an entity has a parent that does not exist in its path it is considered dangling
        paths.forEach((path) => {
            if (path.some((p) => !idMap[p])) {
                const entity = idMap[path[path.length - 1]];
                if (entity) {
                    danglingIds[entity._id] = { _id: entity._id, _rev: entity._rev };
                    delete idMap[entity._id];
                }
            }
        });

        // If we have item relations that reference entities that does not exist they are considered dangling
        itemRelations.forEach((relation) => {
            if (!idMap[relation.childId] || !idMap[relation.parentId]) {
                danglingIds[relation._id] = {
                    _id: relation._id,
                    _rev: relation._rev,
                };
            }
        });

        const danglingDocsArray = Object.values(danglingIds);

        if (danglingDocsArray.length > 0) {
            eventTracking.logError(
                `Found ${danglingDocsArray.length} dangling ids in project`,
                'RepairService',
            );
        }

        return danglingDocsArray;
    }
}
