import { createCachedSelector } from 're-reselect';
import { t } from 'app/translate';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItem } from '../../project';
import { deviceTypeCheckers, isCustomCamera } from 'app/core/persistence';
import { toCacheKey } from '../../cacheKey';

export const getModelName = createCachedSelector(
    [getPiaItemsRecord, getCurrentProjectItem],
    (piaItemsRecord, item) => {
        if (!item) {
            return t.notSelected;
        }

        if (isCustomCamera(item)) {
            return item.properties.camera.customCameraProperties.modelName;
        }

        if (!item.productId) {
            return deviceTypeCheckers.isAnalogCamera(item)
                ? t.deviceListAnalogCameraModel
                : deviceTypeCheckers.isDoor(item)
                  ? t.devicesGROUP['door']
                  : t.notSelected;
        }

        const piaItem = piaItemsRecord[item.productId];

        if (!piaItem) {
            return t.notSelected;
        }

        return piaItem.name;
    },
)(toCacheKey);
