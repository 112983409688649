import { injectable } from 'inversify';
import type { PiaId } from 'app/core/pia';
import type { Id, IItem } from 'app/core/persistence';
import { ItemService, CurrentProjectService } from 'app/core/persistence';

@injectable()
export class RecordingAccessoriesService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public add = async (parentId: Id, productId: PiaId) => {
        const item: IItem = {
            name: '',
            description: '',
            notes: '',
            productId,
            quantity: 1,
            properties: {
                accessory: {},
            },
        };

        const persistedItem = await this.itemService.addByParentId(parentId, item);
        await this.itemService.addItemRelation(parentId, persistedItem._id, 'accessory');
    };

    public updateQuantity = (parentId: Id, productId: PiaId, quantity: number) => {
        const item = this.getRecordingAccessoryFromCurrentProject(parentId, productId);

        if (item) {
            this.itemService.updateItem(item._id, { quantity });
        }
    };

    public delete = (parentId: Id, productId: PiaId) => {
        const item = this.getRecordingAccessoryFromCurrentProject(parentId, productId);

        if (item) {
            this.itemService.deleteItem(item._id);
        }
    };

    private getRecordingAccessoryFromCurrentProject = (parentId: Id, productId: PiaId) =>
        this.currentProjectService
            .getAllEntitiesOfType('item')
            .filter((item) => !!item.properties.accessory)
            .find((item) => item.productId === productId && item.path.includes(parentId));
}
