import * as leaflet from 'leaflet';
import type { ILatLng, IPoint } from 'app/core/persistence';
import type { LeafletMap } from '../components/map/LeafletMap';

const PIXEL_HEIGHT_LABEL = 15;
const PIXEL_MARGIN_TOP = 7.5;

export const getLabelLineOffsetEndPoint = (
    installationPointLocation: ILatLng,
    labelOffset: IPoint,
    map: LeafletMap,
): ILatLng => {
    const labelPosition = getLabelPos(
        installationPointLocation,
        labelOffset,
        map,
        map.map.getZoom(),
    );
    return getLabelLineEndPoint(installationPointLocation, labelPosition, map);
};

export const getLabelLineEndPoint = (
    installationPointLocation: ILatLng,
    labelPosition: ILatLng,
    map: LeafletMap,
) => {
    const pixelPointLabelPosition = map.map.project(labelPosition, map.map.getZoom());

    const diffLat = labelPosition.lat - installationPointLocation.lat;
    const offset = diffLat > 0 ? PIXEL_HEIGHT_LABEL : -(PIXEL_HEIGHT_LABEL - PIXEL_MARGIN_TOP);
    const labelPositionPixels = leaflet.point([
        pixelPointLabelPosition.x,
        pixelPointLabelPosition.y + offset,
    ]);
    const latLngLabelPosition = map.map.unproject(labelPositionPixels, map.map.getZoom());
    return latLngLabelPosition;
};

export const getLabelPos = (
    installationPointLocation: ILatLng,
    offset: IPoint,
    map: LeafletMap,
    zoomLevel: number,
) => {
    const pixelPointInstallationPointPosition = map.map.project(
        installationPointLocation,
        zoomLevel,
    );

    const labelPositionPixels = leaflet.point([
        pixelPointInstallationPointPosition.x + offset.x,
        pixelPointInstallationPointPosition.y + offset.y,
    ]);

    const latLngLabelPosition = map.map.unproject(labelPositionPixels, zoomLevel);
    return latLngLabelPosition;
};

export const getLabelOffset = (
    installationPointLocation: ILatLng,
    labelPosition: ILatLng,
    map: LeafletMap,
) => {
    const newPositionPixel = map.map.project(labelPosition, map.map.getZoom());
    const installationPointPositionPixels = map.map.project(
        installationPointLocation,
        map.map.getZoom(),
    );

    const deltaX = Math.abs(newPositionPixel.x - installationPointPositionPixels.x);
    const deltaY = Math.abs(newPositionPixel.y - installationPointPositionPixels.y);

    const newOffsetX = newPositionPixel.x > installationPointPositionPixels.x ? deltaX : -deltaX;
    const newOffsetY = newPositionPixel.y > installationPointPositionPixels.y ? deltaY : -deltaY;

    const labelOffsetPixels = {
        x: newOffsetX,
        y: newOffsetY,
    };

    return labelOffsetPixels;
};
