import * as React from 'react';
import { t } from 'app/translate';
import { Stack, Card, Box, Border, Button, Heading, PiaImage } from 'app/components';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import { ProjectRequirements } from './ProjectRequirements.component';
import type { IPiaItem } from 'app/core/pia';
import { ServiceLocator } from 'app/ioc';
import { CommonActionService } from 'app/modules/common';
import type { IPiaItemRecordingAccessory } from '../selectors';
import { getSelectedRecordingProduct, getRecordingAccessoriesByCategory } from '../selectors';
import { AccessoryItem } from './AccessoryItem.container';

interface IAccessoriesSelectorProps {
    selectedProduct?: IPiaItem;
    recordingAccessoriesByCategory: Record<string, IPiaItemRecordingAccessory[]>;
}

const mapStateToProps = (storeState: IStoreState): IAccessoriesSelectorProps => {
    const selectedProduct = getSelectedRecordingProduct(storeState);
    const recordingAccessoriesByCategory = getRecordingAccessoriesByCategory(storeState);

    return {
        selectedProduct,
        recordingAccessoriesByCategory,
    };
};

const hidePanel = () => {
    const commonActionService = ServiceLocator.get(CommonActionService);
    commonActionService.showOverlayPanel(false);
};

const AccessoriesSelectorContainer: React.FunctionComponent<IAccessoriesSelectorProps> = ({
    selectedProduct,
    recordingAccessoriesByCategory,
}) => {
    const translatedCategories = t.accessoryDisplayCategoriesGROUP as Record<string, string>;
    return (
        <Stack vertical>
            <Border bottomWidth={1} color="grey3">
                <Box justifyContent="between" paddingY="half" paddingLeft="base">
                    <Stack spacing="half">
                        {selectedProduct && (
                            <PiaImage imageSize="md" icon="recording" piaId={selectedProduct.id} />
                        )}
                        {selectedProduct && <Heading>{selectedProduct.name}</Heading>}
                    </Stack>
                    <Button text onClick={hidePanel}>
                        {t.close}
                    </Button>
                </Box>
            </Border>
            <Box paddingX="doublePanel" paddingBottom="doublePanel">
                <Stack vertical spacing="panel">
                    <Stack vertical alignItems="center" fullHeight spacing="none">
                        <Card paddingY="base">
                            <Stack vertical spacing="panel">
                                {Object.keys(recordingAccessoriesByCategory).map(
                                    (category: string) => (
                                        <Stack key={category} vertical spacing="none">
                                            <Box paddingX="base">
                                                <Heading>{translatedCategories[category]}</Heading>
                                            </Box>
                                            <Stack vertical lineBetweenColor="grey2" spacing="none">
                                                {recordingAccessoriesByCategory[category].map(
                                                    (acc) => (
                                                        <AccessoryItem
                                                            key={acc.id}
                                                            productId={acc.id}
                                                        />
                                                    ),
                                                )}
                                            </Stack>
                                        </Stack>
                                    ),
                                )}
                            </Stack>
                        </Card>
                    </Stack>
                    <Stack justifyContent="center">
                        <ProjectRequirements hideZipstreamSettings />
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    );
};

export const AccessoriesSelector = connect(mapStateToProps)(AccessoriesSelectorContainer);

AccessoriesSelectorContainer.displayName = 'AccessoriesSelector';
