import * as L from 'leaflet';

import { EventsEnum } from './Events';
import { KeysEnum } from './Constants';
import type { IDrawOptions } from './Models';
import { defaultColors } from 'app/core/common';
import { ColorsEnum } from 'app/styles';

export class DeleteHandler extends L.Handler {
    private layersToDelete: L.Polyline[] = [];
    private defaultBlockerColor = ColorsEnum[defaultColors.DEFAULT_BLOCKER_COLOR];

    constructor(
        private map: L.Map,
        private options: IDrawOptions,
    ) {
        super(map);
        this.options = options;
    }

    public addHooks() {
        const container = this.map.getContainer();
        const layers = this.getPolylineLayersWithBounds();

        L.DomEvent.on(container, 'keyup', this.onKeyUp, this);

        layers.forEach((line) => {
            line.setStyle({ color: this.defaultBlockerColor, weight: 7 });
            line.on('click', this.onLayerClick, this);
            line.on('mouseover', this.onMouseOver, this);
            line.on('mouseout', this.onMouseOut, this);
        });

        this.map.fire(EventsEnum.DeleteStart);
    }

    public removeHooks() {
        const layers = this.getPolylineLayersWithBounds();
        const container = this.map.getContainer();

        // remove event handlers
        L.DomEvent.off(container, 'keyup', this.onKeyUp, this);
        [...layers, ...this.layersToDelete].forEach((layer) => {
            layer.off('click', this.onLayerClick, this);
            layer.off('mouseover', this.onMouseOver, this);
            layer.off('mouseout', this.onMouseOut, this);
            layer.setStyle({ color: this.defaultBlockerColor, weight: 3 });
        });

        // add back any layers marked for deletion
        const featureGroup = this.options.edit.featureGroup;
        this.layersToDelete.forEach((layer) => {
            layer.addTo(featureGroup);
        });
        this.layersToDelete = [];
        this.map.fire(EventsEnum.DeleteStop);
    }

    public deleteAll() {
        const featureGroup = this.options.edit.featureGroup;
        const layers = this.getPolylineLayersWithBounds();

        layers.forEach((layer) => {
            featureGroup.removeLayer(layer);
            this.layersToDelete.push(layer);
        });

        this.save();
    }

    public cancel() {
        const featureGroup = this.options.edit.featureGroup;
        this.layersToDelete.forEach((layer) => {
            layer.addTo(featureGroup);
        });
        this.layersToDelete = [];
    }

    public save() {
        if (this.layersToDelete.length > 0) {
            this.map.fire(EventsEnum.Edited);
        }
        this.layersToDelete = [];
    }

    private onLayerClick(event: L.LeafletMouseEvent) {
        const line = event.target;
        if (line instanceof L.Polyline) {
            line.removeFrom(this.map);
            this.options.edit.featureGroup.removeLayer(line);
            this.layersToDelete.push(line);
        }
    }

    private onKeyUp(event: Event) {
        const keyCode = (event as KeyboardEvent).keyCode;
        switch (keyCode) {
            case KeysEnum.Enter:
                this.save();
                this.disable();
                break;
            case KeysEnum.Esc:
                this.cancel();
                this.disable();
                break;
        }
    }

    private onMouseOver(event: L.LeafletMouseEvent) {
        const line = event.target;
        if (line instanceof L.Polyline) {
            line.setStyle({ color: ColorsEnum.blue4 });
        }
    }

    private onMouseOut(event: L.LeafletMouseEvent) {
        const line = event.target;
        if (line instanceof L.Polyline) {
            line.setStyle({ color: this.defaultBlockerColor });
        }
    }

    private getPolylineLayersWithBounds() {
        const featureGroup = this.options.edit.featureGroup;
        return (featureGroup.getLayers() as L.Polyline[]).filter((line) =>
            line.getBounds().isValid(),
        );
    }
}
