import { eventTracking } from 'app/core/tracking';
import { injectable } from 'inversify';

@injectable()
// Service to find loading time for the 'my projects' part of the initial landing page load.
export class ProjectLoadingTimerService {
    private timestamp: number = 0;
    private hasRun: boolean = false;
    public startTimer() {
        if (!this.hasRun) {
            this.timestamp = Date.now();
        }
    }
    public stopTimer() {
        if (this.hasRun || this.timestamp === 0) {
            return;
        }
        const diff = Date.now() - this.timestamp;
        eventTracking.logApplicationEvent(
            'Application',
            'Landing Page Loaded',
            'Landing Page Load time(ms)',
            diff,
        );

        this.hasRun = true;
    }
}
