import * as React from 'react';
import { t } from 'app/translate';
import { Box, Card, Stack } from 'app/components';
import type { Id } from 'app/core/persistence';
import { DoorSide } from './DoorSide';
import { DoorHeader } from './DoorHeader';

interface IDoorProps {
    deviceItemId: Id;
    doorItemId: Id;
    showDelete?: boolean;
}

export const Door: React.FC<IDoorProps> = ({ deviceItemId, doorItemId, showDelete }) => {
    return (
        <Card>
            <Box width="100%" direction="column" paddingBottom="base">
                <DoorHeader
                    deviceItemId={deviceItemId}
                    showDelete={showDelete}
                    doorItemId={doorItemId}
                />
                {/* door sides */}
                <Stack
                    flex="evenSpace"
                    lineBetweenColor="grey4"
                    spacing="none"
                    alignItems="stretch"
                >
                    <DoorSide
                        label={t.doorSideA}
                        deviceItemId={deviceItemId}
                        doorItemId={doorItemId}
                        isSideA={true}
                    />
                    <DoorSide
                        label={t.doorSideB}
                        deviceItemId={deviceItemId}
                        doorItemId={doorItemId}
                        isSideA={false}
                    />
                </Stack>
            </Box>
        </Card>
    );
};

Door.displayName = 'Door';
