import { isDoorController, isIoRelay } from 'app/core/persistence';
import { createCachedSelector } from 're-reselect';
import type { IPiaItemBaseProperties } from 'app/core/pia';
import { getCurrentProjectItem } from '../../project';
import { getPiaItemsRecord } from '../../piaDevices';
import { toCacheKey } from '../../cacheKey';

export const getNumberOfConnectableChildren = createCachedSelector(
    [getPiaItemsRecord, getCurrentProjectItem],
    (piaItemsRecord, item) => {
        if (!item || !item.productId || !piaItemsRecord[item.productId]) {
            return 0;
        }

        const piaItem = piaItemsRecord[item.productId];

        if (isDoorController(piaItem)) {
            return piaItem.properties.supportedOnboardDoors;
        }
        if (isIoRelay(piaItem)) {
            return piaItem.properties.nbrSupportedExpansionModules ?? 0;
        }
        if (!hasChannels(piaItem.properties)) {
            return 0;
        }

        return piaItem.properties.channels;
    },
)(toCacheKey);

function hasChannels(
    piaItemProperties: IPiaItemBaseProperties,
): piaItemProperties is IPiaItemBaseProperties & { channels: number } {
    return 'channels' in piaItemProperties;
}
