import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IExtendableComponent } from 'app/components';
import { Stack, Border, Text, Box } from 'app/components';
import {
    getContactDetails,
    getCurrentProjectName,
    getIsLocalProject,
    getUserSignedIn,
} from 'app/modules/common';
import { ColorsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { Logo } from 'app/modules/user/logo';

const tracksContainer = css`
    display: flex;
    flex: none;
    justify-content: end;
    align-items: flex-end;
    margin-left: -4px !important;
    [dir='rtl'] & {
        transform: rotateY(180deg);
    }
`;

interface IReportHeaderOwnProps {
    title: string;
}

interface IReportHeaderProps extends IReportHeaderOwnProps, IExtendableComponent {
    projectName: string;
    address: string;
    shouldShowLogo: boolean;
    title: string;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IReportHeaderOwnProps,
): IReportHeaderProps => {
    const { address } = getContactDetails(storeState);
    return {
        title: ownProps.title,
        projectName: getCurrentProjectName(storeState),
        address,
        shouldShowLogo: getUserSignedIn(storeState) || getIsLocalProject(storeState),
    };
};

const ReportHeaderContainer: React.FunctionComponent<IReportHeaderProps> = (props) => {
    return (
        <Box
            __htmlAttributes={props.__htmlAttributes}
            justifyContent="between"
            paddingBottom="panel"
        >
            <Border bottomWidth={4} color="warmGrey4">
                <Box justifyContent="between" alignItems="center" width="100%" paddingBottom="half">
                    <Stack vertical spacing="none">
                        <Text color="warmGrey4" style="subheader">
                            {props.title}
                        </Text>
                        <Text style="headline">{props.projectName}</Text>
                    </Stack>
                    {props.shouldShowLogo && <Logo hideAddLogo={true} />}
                    <Box flex="none" paddingLeft="panel" alignItems="center">
                        <Text color="warmGrey6" whiteSpace="pre-wrap">
                            {props.address}
                        </Text>
                    </Box>
                </Box>
            </Border>
            <div className={tracksContainer}>
                <svg
                    width="195"
                    height="78"
                    viewBox="0 0 195 78"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M70.5 0H129.5L58.5 78H0L70.5 0Z" fill={ColorsEnum.warmGrey4} />
                    <path d="M135.5 0H194.5L123.5 78H65L135.5 0Z" fill={ColorsEnum.yellow} />
                </svg>
            </div>
        </Box>
    );
};

export const ReportHeader = connect(mapStateToProps)(ReportHeaderContainer);

ReportHeader.displayName = 'ReportHeader';
