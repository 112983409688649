import { getSelectedDeviceId } from './getSelectedDevice';
import {
    getCurrentProjectItem,
    getCurrentProjectItemsArray,
    getIdFromProps,
    getPiaIdFromProps,
    toCacheKey,
} from 'app/modules/common';
import { createCachedSelector } from 're-reselect';

export const getSelectedMount = createCachedSelector(
    [getCurrentProjectItemsArray, getSelectedDeviceId, getPiaIdFromProps],
    (items, selectedDeviceId, productId) => {
        if (!selectedDeviceId) return undefined;
        return items.find(
            (item) => item.productId === productId && item.path.includes(selectedDeviceId),
        );
    },
)(toCacheKey);

export const getSelectedMountForId = createCachedSelector(
    [getCurrentProjectItemsArray, getCurrentProjectItem, getIdFromProps],
    (items, currentProjectItem) => {
        if (!currentProjectItem) return undefined;
        return items.find(
            (item) =>
                item.productId === currentProjectItem.productId &&
                item.path.includes(currentProjectItem._id),
        );
    },
)(toCacheKey);
