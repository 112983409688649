import * as React from 'react';
import { Box, PiaImage, Text, Stack, Heading, Clickable, Badge } from 'app/components';
import { t } from 'app/translate';
import { PiaItemMsrp } from '../../prices';
import { PiaAccessoryCategory } from 'app/core/pia';
import type { IAccessory } from '../models';
import { nameComparator } from 'app/utils';

export interface IAccessoryPickerProps {
    items: IAccessory[];
    accessoryCategoryToDisplayCategoryMapping: Record<string, string>;
    otherCategory: string;
    onClick(productId: number): void;
}

interface IAccessoryPickerState {
    currentItems: IAccessory[];
    currentCategory: string | null;
}

type AccessoriesByCategory = Record<string, IAccessory[]>;

export class AccessoryPicker extends React.Component<IAccessoryPickerProps, IAccessoryPickerState> {
    private translatedCategories: Record<string, string>;

    public constructor(props: IAccessoryPickerProps) {
        super(props);
        this.state = {
            currentItems: [],
            currentCategory: null,
        };
        this.translatedCategories = t.accessoryDisplayCategoriesGROUP;
    }

    public render() {
        const allItems: AccessoriesByCategory = {};

        this.props.items.forEach((item) => {
            const accessoryCategory =
                this.props.accessoryCategoryToDisplayCategoryMapping[item.productCategory] ||
                this.props.otherCategory;
            const displayCategory = this.getDisplayCategory(accessoryCategory);
            allItems[displayCategory] = allItems[displayCategory]
                ? [...allItems[displayCategory], item]
                : [item];
        });

        Object.keys(allItems).forEach((key) => allItems[key].sort(nameComparator));

        /**
         * Categories should be alphabetically sorted
         */
        const categoriesWithoutOther = Object.keys(allItems)
            .sort()
            .filter((category) => category !== 'other');

        /**
         * The Other category should be displayed last
         */
        const hasOther = 'other' in allItems;
        const categories: string[] = hasOther
            ? [...categoriesWithoutOther, 'other']
            : categoriesWithoutOther;

        return (
            <Box minWidth="600px" direction="column" padding="base" height="100%">
                {categories.map((category) => (
                    <Box paddingBottom="base">
                        <Stack vertical spacing="half">
                            <Heading>{this.translatedCategories[category]}</Heading>
                            <Box direction="column">{this.renderCategory(allItems, category)}</Box>
                        </Stack>
                    </Box>
                ))}
            </Box>
        );
    }

    private getDisplayCategory = (category: string) => {
        if (
            category === PiaAccessoryCategory.MICROPHONES ||
            category === PiaAccessoryCategory.BRIDGES ||
            category === PiaAccessoryCategory.SERVERS ||
            category === PiaAccessoryCategory.AMPLIFIERS
        ) {
            return PiaAccessoryCategory.AUDIOANDIO;
        }
        return category;
    };

    private renderCategory = (allItems: AccessoriesByCategory, category: string) =>
        category &&
        allItems[category] &&
        allItems[category].sort(nameComparator).map((item) => (
            <Clickable key={item.productId} onClick={() => this.props.onClick(item.productId)}>
                <Box
                    padding="cell"
                    justifyContent="between"
                    alignItems="center"
                    color={item.id ? 'yellow1' : undefined}
                >
                    <Stack>
                        <PiaImage piaId={item.productId} icon="category" imageSize="md" />
                        <Text>{item.name}</Text>
                    </Stack>
                    <Stack>
                        {item.isIncluded && <Badge compact label="Included" type="yellow" />}
                        {item.isRecommended && !item.isIncluded && (
                            <Badge compact label="Recommended" type="green" />
                        )}
                        <PiaItemMsrp piaId={item.productId}></PiaItemMsrp>
                    </Stack>
                </Box>
            </Clickable>
        ));
}
