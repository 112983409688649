import type { DeviceAndSubType } from 'app/core/persistence';
import type { IPiaCamera } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getDeviceAndSubTypeForItem } from './getDeviceTypeForItem';
import { getPiaItem } from './getPiaItem';

const cameraTypes: DeviceAndSubType[] = ['analogCamera', 'camera', 'sensorUnit'];

export const getPiaCamera = createCachedSelector(
    [getPiaItem, getDeviceAndSubTypeForItem],
    (piaItem, deviceType) => {
        if (!piaItem || !deviceType || !cameraTypes.includes(deviceType)) {
            return null;
        }

        return piaItem as IPiaCamera;
    },
)(toCacheKey);
