import * as React from 'react';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id, IRecordingSettingsEntity, IRecordingSettingsModel } from 'app/core/persistence';
import type { IScheduleSelectorModel, IScheduleRecordingSupport } from 'app/modules/common';
import {
    getIsItemProfileValidForRecordingSolution,
    getProfileOverride,
    getAssociatedProfile,
    ScheduledRecordingTypes,
    ScheduledRecordingEditorComponent,
    getDeviceProfileSupport,
    ProfileOverrideService,
    getDeviceHasInconsistentRecordingQuality,
    getDeviceRecordingSettings,
    getRecordingSettingsOverride,
    getScheduleSelectorModel,
} from 'app/modules/common';

import { UnreachableCaseError } from 'axis-webtools-util';
import { ServiceLocator } from 'app/ioc';

interface IDeviceScheduledRecordingEditorOwnProps {
    deviceId: Id;
    recordingType: ScheduledRecordingTypes;
    deactivate?: boolean;
}

interface IDeviceScheduledRecordingEditorProps {
    recordingType: ScheduledRecordingTypes;
    scheduleSelector: IScheduleSelectorModel;
    recordingSettings?: IRecordingSettingsModel;
    recordingSettingsOverride?: Partial<IRecordingSettingsModel>;
    scheduleRecordingSupport?: IScheduleRecordingSupport;
    deactivate?: boolean;
    hasInconsistentRecordingQuality: boolean;
    deviceId: Id;
    useProjectSetting: boolean;
    isValidForRecordingSolution: boolean;
}

interface IDeviceScheduledRecordingEditorState {
    recordingSceneExpanded: boolean;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceScheduledRecordingEditorOwnProps,
): IDeviceScheduledRecordingEditorProps => {
    const profileSupport = getDeviceProfileSupport(state, ownProps.deviceId);
    const profileOverride = getProfileOverride(state, ownProps.deviceId);
    const isValidForRecordingSolution = getIsItemProfileValidForRecordingSolution(
        state,
        ownProps.deviceId,
    );
    // If device has a profile override with a different useProjectSetting (zipstream) than the project,
    // get the setting from the overridden profile, otherwise get the setting from the associated profile
    const useProjectSetting =
        profileOverride?.zipstream.useProjectSetting !== undefined
            ? profileOverride?.zipstream.useProjectSetting
            : getAssociatedProfile(state, ownProps.deviceId)?.zipstream?.useProjectSetting ?? false;

    return {
        deviceId: ownProps.deviceId,
        recordingType: ownProps.recordingType,
        recordingSettings: getDeviceRecordingSettings(
            state,
            ownProps.deviceId,
            ownProps.recordingType,
        ),
        recordingSettingsOverride: getRecordingSettingsOverride(
            state,
            ownProps.deviceId,
            ownProps.recordingType,
        ),
        deactivate: ownProps.deactivate,
        scheduleRecordingSupport: profileSupport && profileSupport[ownProps.recordingType],
        scheduleSelector: getScheduleSelectorModel(
            state,
            ownProps.deviceId,
            ownProps.recordingType,
        ),
        hasInconsistentRecordingQuality: getDeviceHasInconsistentRecordingQuality(
            state,
            ownProps.deviceId,
            ownProps.recordingType,
        ),
        useProjectSetting,
        isValidForRecordingSolution,
    };
};

class DeviceScheduledRecordingEditorContainer extends React.Component<
    IDeviceScheduledRecordingEditorProps,
    IDeviceScheduledRecordingEditorState
> {
    private profileOverrideService: ProfileOverrideService;

    constructor(props: IDeviceScheduledRecordingEditorProps) {
        super(props);
        this.state = {
            recordingSceneExpanded: false,
        };
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public render() {
        return this.props.recordingSettings ? (
            <ScheduledRecordingEditorComponent
                scheduleRecordingSupport={this.props.scheduleRecordingSupport}
                heading={this.getRecordingTypeHeader(this.props.recordingType)}
                expanded={this.state.recordingSceneExpanded}
                scheduleSelector={this.props.scheduleSelector}
                recordingSettings={this.props.recordingSettings}
                recordingSettingsOverride={this.props.recordingSettingsOverride}
                onScheduleChange={this.onScheduleChange}
                onRecordingSettingsChange={this.onRecordingSettingsChange}
                onToggleRecordingSettings={this.onToggleRecordingSettings}
                inconsistentRecordingQuality={this.props.hasInconsistentRecordingQuality}
                deactivate={this.props.deactivate}
                liveView={this.props.recordingType === ScheduledRecordingTypes.liveView}
                useProjectSetting={this.props.useProjectSetting}
                showRecordingSolutionWarning={
                    !this.props.isValidForRecordingSolution &&
                    this.props.recordingType === ScheduledRecordingTypes.continuous
                }
            />
        ) : null;
    }

    private getRecordingTypeHeader(recordingType: ScheduledRecordingTypes) {
        switch (recordingType) {
            case ScheduledRecordingTypes.continuous:
                return t.profilesScheduledRecordingContinuousRecording;
            case ScheduledRecordingTypes.triggered:
                return t.profilesScheduledRecordingTriggeredRecording;
            case ScheduledRecordingTypes.liveView:
                return t.profilesScheduledRecordingLiveView;
            default:
                throw new UnreachableCaseError(recordingType);
        }
    }

    private onScheduleChange = (scheduleId: Id | null) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            [this.props.recordingType]: {
                schedule: scheduleId,
            },
        });
    };

    private onToggleRecordingSettings = () => {
        this.setState({ recordingSceneExpanded: !this.state.recordingSceneExpanded });
    };

    private onRecordingSettingsChange = (model: Partial<IRecordingSettingsModel>) => {
        const entity: Partial<IRecordingSettingsEntity> = {
            ...model,
            resolution: model.resolution ? model.resolution.toPersistable() : undefined,
        };

        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            [this.props.recordingType]: {
                ...entity,
            },
        });
    };
}

export const DeviceScheduledRecordingEditor = connect(mapStateToProps)(
    DeviceScheduledRecordingEditorContainer,
);
