import * as React from 'react';
import { Stack, Icon, Border, Box, Heading, Clickable } from 'app/components';
import {
    getCurrentProjectId,
    getCurrentProjectLocked,
    getCurrentProjectName,
    getCurrentProjectQuotationProgress,
    getOverlayPanelOpen,
    getPartnerConfigHeaderStyle,
    SyncIndicator,
} from 'app/modules/common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProjectStateIndicator } from 'app/modules/userProjects';

export const ProjectTitle: React.FC = () => {
    const colorOverride = useSelector(getPartnerConfigHeaderStyle)?.foregroundColor;
    const projectId = useSelector(getCurrentProjectId);
    const projectName = useSelector(getCurrentProjectName);
    const projectLocked = useSelector(getCurrentProjectLocked);
    const projectQuotationState = useSelector(getCurrentProjectQuotationProgress);
    const panelOpen = useSelector(getOverlayPanelOpen);
    const navigate = useNavigate();

    const navigateBack = () => {
        // If the panel is open, do nothing. It will close automatically since we
        // clicked something outside of it.
        if (panelOpen) {
            // Do nothing the panel is closing
            return;
        }

        const partnerDashes = window.location.pathname.includes('partner') ? 2 : 0;
        const dashesCount = (window.location.pathname.match(/\//gm) || []).length - partnerDashes;
        const isReportsPage = window.location.pathname.includes('reports');
        const isDeviceSelectorPage = window.location.pathname.includes('device-selector');

        if (isDeviceSelectorPage) {
            navigate(-1);
        } else if (isReportsPage || dashesCount <= 4) {
            // Navigate home
            navigate('/');
        } else {
            navigate(-1);
        }
    };

    return (
        <Stack spacing="half" flex="fullWidth" minWidth="0">
            <Clickable onClick={navigateBack}>
                <Icon
                    opaque
                    color="blue"
                    icon="arrow_back"
                    testId="btn_arrow_back"
                    colorOverride={colorOverride}
                />
            </Clickable>
            <div>
                <Border color="grey3" colorOverride={colorOverride}>
                    <Box height="30px" width="1px" />
                </Border>
            </div>
            <Stack alignItems="center" spacing="half" flex="fullWidth" minWidth="0">
                <Box minWidth="0" maxWidth="calc(100% - 8px)">
                    <Heading
                        overflowEllipsis
                        testId="hdr_project_title"
                        style="title"
                        colorOverride={colorOverride}
                    >
                        {projectName}
                    </Heading>
                    <SyncIndicator />
                </Box>
                {projectLocked && (
                    <Box flex="none">
                        <Border color="grey3" colorOverride={colorOverride}>
                            <Box height="30px" width="1px" />
                        </Border>
                        <Box paddingX="half">
                            <ProjectStateIndicator
                                projectId={projectId}
                                projectState={projectQuotationState}
                            />
                        </Box>
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

ProjectTitle.displayName = 'ProjectTitle';
