import { injectable } from 'inversify';
import type { IPiaCamera } from 'app/core/pia';
import type { IPiaItemWithPrice, IRecommendedItem } from '../models';

@injectable()
export abstract class RecommendedItemsService {
    public abstract getRecommendedItems(
        items: Array<IPiaItemWithPrice<IPiaCamera>>,
    ): IRecommendedItem[];
}
