import * as React from 'react';
import { Box } from '../../containers';
import { Clickable } from '../../functional';
import { Text } from '../../text/text/Text.component';
import { Border } from '../../style';
import { Heading } from '../../text';
import { IconButton } from '../iconButton';
import { Icon } from '../icon';
import type { Icons } from '../icon';

export interface IPlaylistItem {
    header: React.ReactNode;
    description?: React.ReactNode;
    startTime: number;
    logKey?: string;
    icon?: Icons;
    active?: boolean;
    onClick?: (time: number) => void;
}

export const PlaylistItem: React.FC<IPlaylistItem> = ({
    active,
    header,
    description,
    icon,
    startTime,
    onClick,
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
        <Box direction="column">
            <Clickable onClick={() => onClick && onClick(startTime)}>
                <Border
                    leftWidth={3}
                    color={active ? 'yellow' : 'transparent'}
                    // Settings hover color to same as color to get a transition effect
                    hoverColor={active ? 'yellow' : 'transparent'}
                >
                    <Box
                        color="white"
                        hoverColor={active ? 'white' : 'grey2'}
                        padding="half"
                        spacing="half"
                        alignItems="center"
                        display="grid"
                        grid={{ gridTemplateColumns: 'max-content 1fr max-content' }}
                    >
                        <Border color="yellow" radius="100%">
                            <Box padding="quart">
                                <Icon
                                    opaque
                                    icon={icon ?? 'play'}
                                    color={active ? 'yellow' : 'grey4'}
                                    size="ms"
                                />
                            </Box>
                        </Border>
                        {typeof header === 'string' ? <Heading>{header}</Heading> : header}
                        {description && (
                            <IconButton
                                opaque
                                color="grey4"
                                icon={expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    setExpanded((value) => !value);
                                }}
                            />
                        )}
                    </Box>
                </Border>
            </Clickable>
            {description && (
                <Box
                    aria-hidden={!expanded}
                    display="grid"
                    grid={{ gridTemplateRows: expanded ? '1fr' : '0fr' }}
                    color="grey2"
                    __htmlAttributes={{
                        style: { transition: 'grid-template-rows 0.3s ease-in-out' },
                    }}
                >
                    <Box overflow="hidden">
                        <Box padding="half">
                            {typeof description === 'string' ? (
                                <Text color="grey7">{description}</Text>
                            ) : (
                                description
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

PlaylistItem.displayName = 'PlaylistItem';
