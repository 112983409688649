import { Module } from 'app/ioc';
import { PartnerConfigReducer } from './PartnerConfigReducer';
import { PartnerConfigActionService } from './services/';

@Module({
    binder: (bind) => {
        bind.bindService(PartnerConfigActionService);
    },
    reducers: {
        partnerConfig: PartnerConfigReducer,
    },
})
export class PartnerConfigModule {}
