import type { PiaCategory, IPiaSystemComponent } from 'app/core/pia';
import { PoeClass, PiaItemRecorderCategory } from 'app/core/pia';
import type {
    IDeviceRequirement,
    ISwitchComponentProps,
    IRecorderComponentProps,
    IRecorderDiff,
    ISwitchDiff,
    ISwitchConfiguration,
} from './common';
import { getRecorderProps, getSwitchProps } from './common';

const POE_CLASS_MAP = {
    NoPoE: 0,
    [PoeClass.Class1]: 1,
    [PoeClass.Class2]: 2,
    [PoeClass.Class3]: 3,
    [PoeClass.Class4]: 4,
    [PoeClass.Class5]: 5,
    [PoeClass.Class6]: 6,
    [PoeClass.Class7]: 7,
    [PoeClass.Class8]: 8,
    [PoeClass.HighPoE]: 9,
};

export const byRecorderProps = (a: IRecorderComponentProps, b: IRecorderComponentProps): number =>
    a.maxCameraCount - b.maxCameraCount ||
    a.maxRecordingStorageMegaBytes - b.maxRecordingStorageMegaBytes ||
    a.maxRecordingBandwidthBits - b.maxRecordingBandwidthBits;

export const bySwitchProps = (a: ISwitchComponentProps, b: ISwitchComponentProps): number =>
    a.poeTotalPower - b.poeTotalPower ||
    a.highPoEPortCount - b.highPoEPortCount ||
    a.poeClass8PortCount - b.poeClass8PortCount ||
    a.poeClass7PortCount - b.poeClass7PortCount ||
    a.poeClass6PortCount - b.poeClass6PortCount ||
    a.poeClass5PortCount - b.poeClass5PortCount ||
    a.poeClass4PortCount - b.poeClass4PortCount ||
    a.poeClass3PortCount - b.poeClass3PortCount ||
    a.poeClass2PortCount - b.poeClass2PortCount ||
    a.poeClass1PortCount - b.poeClass1PortCount;

export const byStorageSize = (a: IPiaSystemComponent, b: IPiaSystemComponent): number => {
    const aProps = getRecorderProps(a);
    const bProps = getRecorderProps(b);

    return aProps.maxRecordingStorageMegaBytes - bProps.maxRecordingStorageMegaBytes;
};

export const bySize = (a: IPiaSystemComponent, b: IPiaSystemComponent): number =>
    byRecorderProps(getRecorderProps(a), getRecorderProps(b)) ||
    bySwitchProps(getSwitchProps(a), getSwitchProps(b));

export const bySwitchRequirement = (a: IDeviceRequirement, b: IDeviceRequirement): number =>
    POE_CLASS_MAP[a.poeClass] - POE_CLASS_MAP[b.poeClass] || a.power - b.power;

export const bySortingPriority = (a: IPiaSystemComponent, b: IPiaSystemComponent): number =>
    (a.properties.sortingPriority ?? Infinity) - (b.properties.sortingPriority ?? Infinity);

export const byRecorderUtilization = (a: IRecorderDiff, b: IRecorderDiff): number =>
    b.selectedDevices.length - a.selectedDevices.length ||
    byRecorderProps(a.overCapacity, b.overCapacity) ||
    bySortingPriority(a.recorder, b.recorder);

export const bySwitchUtilization = (a: ISwitchDiff, b: ISwitchDiff): number =>
    a.selectedDevices.length - b.selectedDevices.length ||
    -bySwitchProps(a.overCapacity, b.overCapacity);

export const byItemId = (a: IDeviceRequirement, b: IDeviceRequirement): number =>
    a.itemId.localeCompare(b.itemId);

const getCategoryPriority = (category: PiaCategory) =>
    category === PiaItemRecorderCategory.RECORDERS2 ? 1 : 0;

export const bySwitchRecommendation = (a: ISwitchConfiguration, b: ISwitchConfiguration): number =>
    getCategoryPriority(a.networkSwitch.category) - getCategoryPriority(b.networkSwitch.category) ||
    bySwitchProps(a.overCapacity, b.overCapacity);
