import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import { getCategoryName } from '../services';
import type { ICustomItemEntity, Id } from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { PiaId } from 'app/core/pia';
import { nameComparator, nameComparatorReverse } from 'app/utils';

export const getCustomItemsSearchFilter = (state: IStoreState) => state.customItems.searchFilter;
const getCustomItems = (state: IStoreState) => state.currentProject.customItems;
export const getCustomItemSortOrder = (state: IStoreState) => state.customItems.sortOrder;

export const getCustomItem = (id: Id) =>
    createSelector([getCustomItems], (customItems) => customItems[id]);

const getCustomItemsArray = createSelector([getCustomItems], (customItems) =>
    Object.values(customItems)
        .filter(isDefined)
        .sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()),
);

const getProductIdFromProps = (_state: IStoreState, productId: PiaId) => productId;

export const getPartnerProductItem = createSelector(
    [getCustomItems, getProductIdFromProps],
    (customItems, partnerProductId) => {
        return Object.values(customItems)
            .filter(isDefined)
            .filter((customItem) => customItem.partnerProductId)
            .find(
                (partnerItem) =>
                    partnerItem.partnerProductId &&
                    partnerItem.partnerProductId === partnerProductId,
            );
    },
);

/** Gets function that sorts quantity by number and other properties alphabetically, either ascending or descending. */
const getSortFunction = createSelector([getCustomItemSortOrder], (sortOrder) => {
    const { sortBy, direction } = sortOrder;
    if (sortBy === 'quantity') {
        return (a: ICustomItemEntity, b: ICustomItemEntity) =>
            direction === 'ascending' ? a.quantity - b.quantity : b.quantity - a.quantity;
    }
    return (a: ICustomItemEntity, b: ICustomItemEntity) =>
        direction === 'ascending'
            ? nameComparator({ name: a[sortBy] }, { name: b[sortBy] })
            : nameComparatorReverse({ name: a[sortBy] }, { name: b[sortBy] });
});

export const getFilteredCustomItems = createSelector(
    [getCustomItemsSearchFilter, getCustomItemsArray, getSortFunction],
    (searchFilter, customItems, sortFunction) => {
        return customItems.filter(filterCustomItems(searchFilter)).sort(sortFunction);
    },
);

export const getFilteredCustomItemIds = createSelector([getFilteredCustomItems], (customItems) =>
    customItems.map((customItem) => customItem._id),
);

const filterCustomItems =
    (filter: string) =>
    ({ name, partNumber, category }: ICustomItemEntity) =>
        name.toLowerCase().includes(filter.toLowerCase()) ||
        partNumber.toLowerCase().includes(filter.toLowerCase()) ||
        getCategoryName(category).toLowerCase().includes(filter.toLowerCase());
