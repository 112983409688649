import { css } from '@emotion/css';

export enum SpacingsEnum {
    doublePanel = '50px',
    panel = '25px',
    cell = '12px',
    halfCell = '6px',
    base = '16px',
    half = '8px',
    quart = '4px',
    halfQuart = '2px',
    none = '0px',
}

export type Spacings = keyof typeof SpacingsEnum;

export const PaddingBase = css`
    padding: ${SpacingsEnum.base};
`;
export const PaddingCell = css`
    padding: ${SpacingsEnum.cell};
`;
export const PaddingHalfCell = css`
    padding: ${SpacingsEnum.halfCell};
`;
export const PaddingHalf = css`
    padding: ${SpacingsEnum.half};
`;
export const PaddingPanel = css`
    padding: ${SpacingsEnum.panel};
`;
export const PaddingDoublePanel = css`
    padding: ${SpacingsEnum.doublePanel};
`;
export const PaddingQuart = css`
    padding: ${SpacingsEnum.quart};
`;
export const PaddingHalfQuart = css`
    padding: ${SpacingsEnum.halfQuart};
`;

export const PaddingXBase = css`
    padding-inline-end: ${SpacingsEnum.base};
    padding-inline-start: ${SpacingsEnum.base};
`;
export const PaddingXCell = css`
    padding-inline-end: ${SpacingsEnum.cell};
    padding-inline-start: ${SpacingsEnum.cell};
`;
export const PaddingXHalfCell = css`
    padding-inline-end: ${SpacingsEnum.halfCell};
    padding-inline-start: ${SpacingsEnum.halfCell};
`;
export const PaddingXHalf = css`
    padding-inline-end: ${SpacingsEnum.half};
    padding-inline-start: ${SpacingsEnum.half};
`;
export const PaddingXPanel = css`
    padding-inline-end: ${SpacingsEnum.panel};
    padding-inline-start: ${SpacingsEnum.panel};
`;
export const PaddingXDoublePanel = css`
    padding-inline-end: ${SpacingsEnum.doublePanel};
    padding-inline-start: ${SpacingsEnum.doublePanel};
`;
export const PaddingXQuart = css`
    padding-inline-end: ${SpacingsEnum.quart};
    padding-inline-start: ${SpacingsEnum.quart};
`;
export const PaddingXHalfQuart = css`
    padding-inline-end: ${SpacingsEnum.halfQuart};
    padding-inline-start: ${SpacingsEnum.halfQuart};
`;

export const PaddingYBase = css`
    padding-top: ${SpacingsEnum.base};
    padding-bottom: ${SpacingsEnum.base};
`;
export const PaddingYCell = css`
    padding-top: ${SpacingsEnum.cell};
    padding-bottom: ${SpacingsEnum.cell};
`;
export const PaddingYHalfCell = css`
    padding-top: ${SpacingsEnum.halfCell};
    padding-bottom: ${SpacingsEnum.halfCell};
`;
export const PaddingYHalf = css`
    padding-top: ${SpacingsEnum.half};
    padding-bottom: ${SpacingsEnum.half};
`;
export const PaddingYPanel = css`
    padding-top: ${SpacingsEnum.panel};
    padding-bottom: ${SpacingsEnum.panel};
`;
export const PaddingYDoublePanel = css`
    padding-top: ${SpacingsEnum.doublePanel};
    padding-bottom: ${SpacingsEnum.doublePanel};
`;
export const PaddingYQuart = css`
    padding-top: ${SpacingsEnum.quart};
    padding-bottom: ${SpacingsEnum.quart};
`;
export const PaddingYHalfQuart = css`
    padding-top: ${SpacingsEnum.halfQuart};
    padding-bottom: ${SpacingsEnum.halfQuart};
`;

export const PaddingTopNone = css`
    padding-top: 0 !important;
`;
export const PaddingTopBase = css`
    padding-top: ${SpacingsEnum.base} !important;
`;
export const PaddingTopCell = css`
    padding-top: ${SpacingsEnum.cell} !important;
`;
export const PaddingTopHalfCell = css`
    padding-top: ${SpacingsEnum.halfCell} !important;
`;
export const PaddingTopHalf = css`
    padding-top: ${SpacingsEnum.half} !important;
`;
export const PaddingTopPanel = css`
    padding-top: ${SpacingsEnum.panel} !important;
`;
export const PaddingTopDoublePanel = css`
    padding-top: ${SpacingsEnum.doublePanel} !important;
`;
export const PaddingTopQuart = css`
    padding-top: ${SpacingsEnum.quart} !important;
`;
export const PaddingTopHalfQuart = css`
    padding-top: ${SpacingsEnum.halfQuart} !important;
`;

export const PaddingBottomNone = css`
    padding-bottom: 0 !important;
`;
export const PaddingBottomBase = css`
    padding-bottom: ${SpacingsEnum.base} !important;
`;
export const PaddingBottomCell = css`
    padding-bottom: ${SpacingsEnum.cell} !important;
`;
export const PaddingBottomHalfCell = css`
    padding-bottom: ${SpacingsEnum.halfCell} !important;
`;
export const PaddingBottomHalf = css`
    padding-bottom: ${SpacingsEnum.half} !important;
`;
export const PaddingBottomPanel = css`
    padding-bottom: ${SpacingsEnum.panel} !important;
`;
export const PaddingBottomDoublePanel = css`
    padding-bottom: ${SpacingsEnum.doublePanel} !important;
`;
export const PaddingBottomQuart = css`
    padding-bottom: ${SpacingsEnum.quart} !important;
`;
export const PaddingBottomHalfQuart = css`
    padding-bottom: ${SpacingsEnum.halfQuart} !important;
`;

export const PaddingLeftNone = css`
    padding-inline-start: 0 !important;
`;
export const PaddingLeftBase = css`
    padding-inline-start: ${SpacingsEnum.base} !important;
`;
export const PaddingLeftCell = css`
    padding-inline-start: ${SpacingsEnum.cell} !important;
`;
export const PaddingLeftHalfCell = css`
    padding-inline-start: ${SpacingsEnum.halfCell} !important;
`;
export const PaddingLeftHalf = css`
    padding-inline-start: ${SpacingsEnum.half} !important;
`;
export const PaddingLeftPanel = css`
    padding-inline-start: ${SpacingsEnum.panel} !important;
`;
export const PaddingLeftDoublePanel = css`
    padding-inline-start: ${SpacingsEnum.doublePanel} !important;
`;
export const PaddingLeftQuart = css`
    padding-inline-start: ${SpacingsEnum.quart} !important;
`;
export const PaddingLeftHalfQuart = css`
    padding-inline-start: ${SpacingsEnum.halfQuart} !important;
`;

export const PaddingRightNone = css`
    padding-inline-end: 0 !important;
`;
export const PaddingRightBase = css`
    padding-inline-end: ${SpacingsEnum.base} !important;
`;
export const PaddingRightCell = css`
    padding-inline-end: ${SpacingsEnum.cell} !important;
`;
export const PaddingRightHalfCell = css`
    padding-inline-end: ${SpacingsEnum.halfCell} !important;
`;
export const PaddingRightHalf = css`
    padding-inline-end: ${SpacingsEnum.half} !important;
`;
export const PaddingRightPanel = css`
    padding-inline-end: ${SpacingsEnum.panel} !important;
`;
export const PaddingRightDoublePanel = css`
    padding-inline-end: ${SpacingsEnum.doublePanel} !important;
`;
export const PaddingRightQuart = css`
    padding-inline-end: ${SpacingsEnum.quart} !important;
`;
export const PaddingRightHalfQuart = css`
    padding-inline-end: ${SpacingsEnum.halfQuart} !important;
`;

export const SpacingNone = css`
    gap: 0;
`;
export const SpacingBase = css`
    gap: ${SpacingsEnum.base};
`;
export const SpacingCell = css`
    gap: ${SpacingsEnum.cell};
`;
export const SpacingHalfCell = css`
    gap: ${SpacingsEnum.halfCell};
`;
export const SpacingHalf = css`
    gap: ${SpacingsEnum.half};
`;
export const SpacingPanel = css`
    gap: ${SpacingsEnum.panel};
`;
export const SpacingDoublePanel = css`
    gap: ${SpacingsEnum.doublePanel};
`;
export const SpacingQuart = css`
    gap: ${SpacingsEnum.quart};
`;
export const SpacingHalfQuart = css`
    gap: ${SpacingsEnum.halfQuart};
`;
