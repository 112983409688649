import * as React from 'react';
import { Box, Grid, LoadingIndicator, Spacer, Text } from 'app/components';
import { SearchPartnerProduct } from './SearchPartnerProduct';
import { SortPartnerProducts } from './SortPartnerProducts';
import type { Id } from 'app/core/persistence';
import { t as i18nextT } from 'i18next';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { PartnerProductFilterComponent } from './PartnerProductFilterComponent';
import { useMount } from 'app/hooks';
import { getPiaItem } from 'app/modules/common';
import type { IPiaItem } from 'app/core/pia';
import {
    getArePartnerProductsLoaded,
    getSortedPartnerProductsAsIApplicationItem,
} from '../selectors';
import { ApplicationItem } from './ApplicationItem';
import { AddonSelectorActionService } from '../services';

interface IListPartnerApplicationsModalProps {
    parentId?: Id;
    onClose?(): void;
}
const t = (key: string) => i18nextT(key, { ns: 'partnerProducts' });

export const PartnerProductSelector: React.FC<IListPartnerApplicationsModalProps> = ({
    parentId,
    onClose,
}) => {
    const showAllProducts = !parentId; // for standalone and for 'Add partner product' in 'Other'

    const partnerProducts = useSelector(getSortedPartnerProductsAsIApplicationItem);

    const allLoaded = useSelector<IStoreState, boolean>((state) =>
        getArePartnerProductsLoaded(state),
    );

    const addonSelectorActionService = useService(AddonSelectorActionService);
    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, parentId),
    );

    useMount(() => {
        if (showAllProducts && !allLoaded) {
            addonSelectorActionService.getAllPartnerProducts();
        }
        addonSelectorActionService.updatePartnerProductFilter({
            compatibleWithDevicePiaId: piaItem?.id ?? undefined,
        });
    });

    return (
        <Box direction="column">
            <SearchPartnerProduct />
            <PartnerProductFilterComponent />
            <SortPartnerProducts foundApplications={partnerProducts.length} />
            <Box paddingRight="cell" paddingTop="cell">
                <Grid spacing="quart">
                    {partnerProducts && partnerProducts.length > 0 ? (
                        partnerProducts.map((application) => (
                            <ApplicationItem
                                application={application}
                                parentDeviceId={parentId}
                                key={application.productId}
                                onToggled={onClose}
                            />
                        ))
                    ) : !allLoaded ? (
                        <LoadingIndicator />
                    ) : (
                        <Text>{t('noProducts')}</Text>
                    )}
                </Grid>
            </Box>
            <Spacer customSpacing="250px" />
        </Box>
    );
};

PartnerProductSelector.displayName = 'PartnerProductSelector';
