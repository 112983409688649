import * as React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { Box } from '../../containers';
import { Clickable } from '../../functional';
import { Icon } from '../icon';
import { Positioned } from '../../layout/positioned';
import { ColorsEnum } from 'app/styles';
import type { IWithChildren } from 'app/components/models';

const SpinnerStyle = css`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    border: 4px solid ${ColorsEnum.grey3};
    border-top: 4px solid ${ColorsEnum.blue};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
`;

const ColorOkStyle = css`
    background: ${ColorsEnum.blue};
    border-color: ${ColorsEnum.blue};
`;

const ColorErrorStyle = css`
    background: ${ColorsEnum.red};
    border-color: ${ColorsEnum.red};
`;

const ColorFinishedStyle = css`
    background: ${ColorsEnum.green};
    border-color: ${ColorsEnum.green};
`;

const ColorNoneStyle = css`
    background: ${ColorsEnum.grey3};
`;

const BallActiveStyle = css`
    background: ${ColorsEnum.transparent};
`;

const BallStyle = css`
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
`;

const BallUndefinedStyle = css`
    border-color: ${ColorsEnum.blue};
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
`;

const LineStyle = css`
    width: 2px;
    flex: 1;
`;

const ContentStyle = css`
    color: ${ColorsEnum.grey8};
    display: flex;
    flex: 1;
`;

const ContentFinishedStyle = css`
    color: ${ColorsEnum.green};
`;

const ContentActiveStyle = css`
    color: ${ColorsEnum.blue};
`;

type State = 'ok' | 'error' | 'finished';

interface IProgressIndicatorProps extends IWithChildren {
    /**
     * The state of this progress step 'ok' or 'error'
     */
    state?: State;
    /**
     * Whether this is the currently active step
     */
    active?: boolean;
    /**
     * Whether this is the first step
     */
    first?: boolean;
    /**
     * Whether this is the last step
     */
    last?: boolean;
    /**
     * Whether not clickable
     */
    disabled?: boolean;
    /**
     * Called when clicking on Ball
     */
    onClick?(): void;
}

export const ProgressIndicator: React.FunctionComponent<IProgressIndicatorProps> = ({
    active,
    state,
    first,
    last,
    disabled,
    onClick,
    children,
}) => {
    const stateClass = getStateClass(state);

    const ballClass = classNames(stateClass, {
        [BallStyle]: state !== undefined,
        [BallActiveStyle]: active && !last,
        [BallUndefinedStyle]: !state,
    });

    const spinnerClass = classNames(SpinnerStyle);

    const lineBeforeClass = classNames(LineStyle, !first && stateClass);

    const lineAfterClass = classNames(LineStyle, !last && stateClass, {
        [ColorNoneStyle]: active && !last,
    });

    const contentClass = classNames(ContentStyle, {
        [ContentActiveStyle]: active && !last,
        [ContentFinishedStyle]: active && last,
    });

    const linesAndBall = (
        <Box direction="column" alignItems="center">
            <div className={lineBeforeClass} />
            {disabled && active ? (
                <div className={spinnerClass}></div>
            ) : (
                <div className={ballClass}>
                    {(state === 'finished' || (state === 'ok' && !active)) && (
                        <Positioned position="relative" top="-1px" insetInlineStart="-1px">
                            <Icon color="white" opaque icon="check" size="sm" />
                        </Positioned>
                    )}
                </div>
            )}
            <div className={lineAfterClass} />
        </Box>
    );

    return (
        <Clickable readOnly={disabled} onClick={onClick}>
            <Box minHeight={36}>
                {linesAndBall}
                <div className={contentClass}>
                    <Box direction="column" width="100%" paddingX="base" justifyContent="center">
                        {children}
                    </Box>
                </div>
            </Box>
        </Clickable>
    );
};

ProgressIndicator.displayName = 'ProgressIndicator';

const getStateClass = (state?: State) => {
    switch (state) {
        case 'ok':
            return ColorOkStyle;
        case 'error':
            return ColorErrorStyle;
        case 'finished':
            return ColorFinishedStyle;
        default:
            return ColorNoneStyle;
    }
};
