import { Module } from 'app/ioc';
import { QuotationActionService, QuotationService } from './services';
import { quotationReducer } from './quotationReducer';

@Module({
    binder: (binder) => {
        binder.bindService(QuotationActionService);
        binder.bindService(QuotationService);
    },
    reducers: {
        quotation: quotationReducer,
    },
})
export class QuotationModule {}
