import * as React from 'react';
import { ServiceLocator } from 'app/ioc';
import { RecordingSelectorActionService } from '../services';
import { getSelectedRecordingSolutionType } from 'app/modules/common';
import {
    getDeviceRequirementsWithoutName,
    getRecordingSolutionComponents,
    areBasicRequirementsFulfilled,
} from '../selectors';
import { useSelector } from 'react-redux';

export const ValidationTrigger: React.FC = () => {
    const deviceRequirements = useSelector(getDeviceRequirementsWithoutName);
    const recordingSolution = useSelector(getRecordingSolutionComponents);
    const basicCheckOk = useSelector(areBasicRequirementsFulfilled);
    const selectedRecordingSolutionType = useSelector(getSelectedRecordingSolutionType);

    React.useEffect(() => {
        const actionService = ServiceLocator.get(RecordingSelectorActionService);

        actionService.validateSystem();
    }, [deviceRequirements, recordingSolution, basicCheckOk, selectedRecordingSolutionType]);

    return null;
};

ValidationTrigger.displayName = 'ValidationTrigger';
