import { ActionCreator, IAction } from 'app/store';
import { injectable } from 'inversify';
import type { IModalButton } from './IModalState';
import { ModalActions } from './modalActions';

export interface IModalProps {
    header: string;
    body: React.ReactNode;
    buttons: IModalButton[];
    warning?: boolean;
}

@injectable()
export class ModalActionService {
    @ActionCreator()
    public showModal(payload: IModalProps): IAction<IModalProps> {
        return {
            type: ModalActions.ShowModal,
            payload,
        };
    }

    @ActionCreator()
    public hideModal(): IAction<void> {
        return {
            type: ModalActions.HideModal,
            payload: undefined,
        };
    }
}
