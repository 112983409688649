export const partnerPathnamePrefix = 'partner';

export function getUrlPartnerId(): string | undefined {
    const pathnameParts = window.location.pathname.split('/');
    const storedPartnerId = sessionStorage.getItem('partnerId');

    if (pathnameParts[1] === partnerPathnamePrefix && pathnameParts[2]) {
        return pathnameParts[2];
    }
    if (!storedPartnerId || storedPartnerId === '') {
        return undefined;
    }

    return storedPartnerId;
}

export function getPartnerBase(): string | undefined {
    const partnerId = getUrlPartnerId();
    if (partnerId) {
        return `/${partnerPathnamePrefix}/${partnerId}`;
    }
    return undefined;
}
