import type { IPolygon } from 'app/modules/common';
import { getCircle, toPolygon } from './cameraCalc';
import * as PolyBool from 'polybooljs';

/** Get The polygon (circle) representing the resolution guide */
export function getResolutionGuidePolygon(
    cameraHeight: number,
    targetHeight: number,
    resolutionGuide: number,
    fov = 45,
    angle = 0,
): IPolygon {
    const resolutionGuideRadius = Math.sqrt(
        Math.pow(resolutionGuide, 2) - Math.pow(cameraHeight - targetHeight, 2),
    );

    const resolutionGuideLine = getCircle(resolutionGuideRadius, fov, angle);

    return toPolygon(resolutionGuideLine);
}

/** Get The polygon with min and max radius - used for radar-oxxo where min value is not same as resolution guide */
export function getAnalyticGuidePolygon(radiusMin: number, radiusMax: number): IPolygon {
    const noDetectLine = radiusMin ? getCircle(radiusMin) : undefined;
    const maxDetectLine = getCircle(radiusMax);
    const polygon = noDetectLine
        ? PolyBool.xor(toPolygon(noDetectLine), toPolygon(maxDetectLine))
        : toPolygon(maxDetectLine);

    return polygon;
}
