import * as React from 'react';
import { IconButton, Border, Box, Stack, Text, Positioned, NoPrint } from 'app/components';
import type { IExtendableComponentWithChildren } from 'app/components';
import type { Spacings } from 'app/styles';
import { SpacingsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../../services';

interface ISidePanelProps extends IExtendableComponentWithChildren {
    title?: string;
    /**
     * X axis padding
     */
    paddingX?: Spacings;
    /**
     * Y axis padding
     */
    paddingY?: Spacings;
}

export const FilterPanel: React.FC<ISidePanelProps> = (props) => {
    const { title, paddingX, paddingY, children, ...extendedProps } = props;

    const containerStyle = css`
        padding-left: ${paddingX ? SpacingsEnum[paddingX] : SpacingsEnum['half']};
        padding-right: ${paddingX ? SpacingsEnum[paddingX] : SpacingsEnum['half']};
        padding-top: ${paddingY ? SpacingsEnum[paddingY] : SpacingsEnum['quart']};
        padding-bottom: ${paddingY ? SpacingsEnum[paddingY] : SpacingsEnum['half']};
        height: 100%;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        position: relative;
    `;

    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const onCollapseFilterPanel = () => {
        actionService.setFilterPanelExpanded(false);
    };

    return (
        <NoPrint>
            <Box>
                <Border rightWidth={1} color="grey3" {...extendedProps}>
                    <Box
                        color="grey1"
                        minWidth="415px"
                        maxWidth="415px"
                        overflowY="auto"
                        height="100%"
                    >
                        <div className={containerStyle}>
                            <Stack vertical spacing="none">
                                {onCollapseFilterPanel && (
                                    <Positioned top="5px" insetInlineEnd={5} position="absolute">
                                        <IconButton
                                            icon={'keyboard_arrow_left'}
                                            onClick={onCollapseFilterPanel}
                                        />
                                    </Positioned>
                                )}
                                {title && (
                                    <Text style="semibold" color="grey6">
                                        {title}
                                    </Text>
                                )}
                                <Stack vertical>{children}</Stack>
                            </Stack>
                        </div>
                    </Box>
                </Border>
            </Box>
        </NoPrint>
    );
};
