import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { Button, Text, Box } from 'app/components';
import { ServiceLocator } from 'app/ioc';
import type { EventCategory } from 'app/core/tracking';
import { eventTracking } from 'app/core/tracking';
import { getUserSignedIn } from '../selectors';
import { CommonActionService } from '../../services';
import { ProjectDbOrigin } from 'app/core/persistence';

interface ISignInReminderOwnProps {
    currentView: ProjectDbOrigin;
    trackingCategory?: EventCategory;
}

interface ISignInReminderContainerProps extends ISignInReminderOwnProps {
    showReminder: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ISignInReminderOwnProps,
): ISignInReminderContainerProps => {
    return {
        ...ownProps,
        showReminder: !getUserSignedIn(storeState),
    };
};

class SignInReminderContainer extends React.Component<ISignInReminderContainerProps> {
    private commonActionService: CommonActionService;

    constructor(props: ISignInReminderContainerProps) {
        super(props);
        this.commonActionService = ServiceLocator.get(CommonActionService);
    }

    public render() {
        return (
            this.props.showReminder && (
                <Box paddingY="panel" display="block">
                    <Box padding="base" justifyContent="between" alignItems="center" color="grey2">
                        <Text large>
                            {this.props.currentView === ProjectDbOrigin.asdUserData
                                ? t.userSignInReminder
                                : t.sharedProjectsSignIn}
                        </Text>
                        <Button primary onClick={this.onSignIn}>
                            {t.userSignIn}
                        </Button>
                    </Box>
                </Box>
            )
        );
    }

    private onSignIn = () => {
        if (this.props.trackingCategory) {
            eventTracking.logUserEvent(this.props.trackingCategory, 'Sign In Reminder Login');
        }
        this.commonActionService.login();
    };
}

export const SignInReminder = connect(mapStateToProps)(SignInReminderContainer);
