import { Clickable } from '../../functional';

import { DropDownMenuColor } from './DropDownMenuColor.component';
import type { Colors, Spacings } from 'app/styles';
import { Box } from 'app/components/containers';
import { css } from '@emotion/css';
import React from 'react';

export interface IDropDownMenuColorButtonProps {
    /**
     * Prevents the click event from bubbling.
     */
    stopPropagation?: boolean;
    /**
     * Color of the button
     */
    color: Colors;
    /**
     * The onClick callback function for the contents (ie. second drop down item)
     */
    onClick(event: React.SyntheticEvent): void;
    /**
     * Adds a checkmark to show that this item
     * is selected.
     */
    selected?: boolean;
    /** Adds padding to button */
    padding?: Spacings;
}

const focusStyle = css`
    transition: scale 100ms ease-in-out;
    :focus-visible {
        scale: 1.15;
        outline: none;
    }
    :focus {
        outline: none;
    }
`;

/**
 * A drop down menu item which when clicked will display its children.
 * Useful for delete operations where the user has to click twice (the second
 * time to confirm the delete)
 */
export const DropDownMenuColorButton: React.FC<IDropDownMenuColorButtonProps> = ({
    onClick,
    stopPropagation,
    color,
    selected,
    padding,
}) => {
    return (
        <Clickable
            stopPropagation={stopPropagation}
            onClick={onClick}
            __htmlAttributes={{ className: focusStyle }}
        >
            <Box borderRadius="circle" padding={padding}>
                <DropDownMenuColor color={color} size="sm" selected={selected} />
            </Box>
        </Clickable>
    );
};

DropDownMenuColorButton.displayName = 'DropDownMenuColorButton';
