import * as React from 'react';
import { useSelector } from 'react-redux';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import type { IExtendableComponent } from 'app/components';
import {
    NoPrint,
    Box,
    DropDownMenuButton,
    TabbedPanel,
    Button,
    DropDown,
    IconText,
} from 'app/components';
import { t } from 'app/translate';
import type { DeviceGroup, IDeviceGroupInfo } from '../models';
import { getDeviceGroup } from '../selectors';
import { DeviceSelectorActionService } from '../services';
import { getShouldShow2NSelector } from 'app/modules/common';
import { getDeviceTabs } from '../utils/deviceTabsUtils';
import { useWindowSize } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
interface IDeviceTabsProps extends IExtendableComponent {
    deviceGroups: Record<DeviceGroup, IDeviceGroupInfo>;
    showAll?: boolean;
}

// Same constants as in breakpoints.type.ts
const MEDIUM_MAX_WIDTH = 1024;
const XLARGE_MIN_WIDTH = 1440;

export const DeviceTabs: React.FunctionComponent<IDeviceTabsProps> = ({
    deviceGroups,
    showAll,
    ...extendedProps
}) => {
    const deviceGroup = useSelector<IStoreState, DeviceGroup>((state) => getDeviceGroup(state));
    const shouldShow2NSelector = useSelector<IStoreState, boolean>((state) =>
        getShouldShow2NSelector(state),
    );

    const [actionService] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );
    const navigate = useNavigate();

    // We adjust number of visible tabs for different screen sizes, until we have a smart floating tab control.
    const { width } = useWindowSize();
    const maxMainTabs =
        width <= MEDIUM_MAX_WIDTH ? 3 : width <= XLARGE_MIN_WIDTH ? 4 : Number.MAX_VALUE;

    const deviceTabs = getDeviceTabs(
        showAll || false,
        deviceGroup,
        maxMainTabs,
        shouldShow2NSelector,
    );

    const onActiveTabChange = (group: DeviceGroup) => {
        const deviceInfo = deviceGroups[group];
        actionService.setDeviceGroupInfo(deviceInfo);
    };

    const getCollapsedTabTitle = (): string => {
        const selectedTab = deviceTabs.collapsedTabs?.find((tab) => tab.id === deviceGroup);
        return selectedTab?.title ?? t.deviceListAddOtherListMore;
    };

    return (
        <Box
            {...extendedProps}
            width="100%"
            paddingRight="half"
            justifyContent="between"
            color="white"
            height={52}
        >
            <NoPrint>
                <Box flex="shrinkAndGrow">
                    <Box spacing="panel" alignItems="baseline">
                        <TabbedPanel
                            wrap
                            noBorder
                            background="white"
                            tabs={deviceTabs.mainTabs}
                            activeTab={deviceGroup}
                            onActiveTabChange={onActiveTabChange}
                        />
                        {deviceTabs.collapsedTabs && (
                            <DropDown
                                trigger={
                                    <IconText
                                        icon="arrow_down"
                                        textProps={{
                                            color: 'blue',
                                            semiBold: true,
                                            whiteSpace: 'nowrap',
                                        }}
                                        iconProps={{
                                            opaque: true,
                                            color: 'blue',
                                            size: 'sm',
                                        }}
                                        alignIconRight
                                    >
                                        {getCollapsedTabTitle()}
                                    </IconText>
                                }
                                contents={deviceTabs.collapsedTabs.map((tab) => {
                                    return (
                                        <DropDownMenuButton
                                            key={tab.id}
                                            label={tab.title}
                                            selected={deviceGroup === tab.id}
                                            onClick={() => onActiveTabChange(tab.id as DeviceGroup)}
                                        />
                                    );
                                })}
                            />
                        )}
                    </Box>
                </Box>
                <Box flex="none">
                    <Button testId="close_selector_btn" text onClick={() => navigate(-1)}>
                        {t.close}
                    </Button>
                </Box>
            </NoPrint>
        </Box>
    );
};

DeviceTabs.displayName = 'DeviceTabs';
