import * as React from 'react';
import type { JustifyContent } from 'app/styles/layout.type';
import { NoPrint } from '../../functional/noPrint/NoPrint';
import { Box } from '../box/Box.component';
import type { IWithChildren } from 'app/components/models';

interface IContentHeaderBar extends IWithChildren {
    /**
     * Determines how children are justified
     */
    justifyContent?: JustifyContent;
}

/**
 * A container for the toolbar featured above many pages. It usually contains some buttons and a filter input.
 */
export const ContentHeaderBar: React.FunctionComponent<IContentHeaderBar> = ({
    children,
    justifyContent,
}) => (
    <NoPrint>
        <Box
            width="100%"
            minHeight="68px"
            justifyContent={justifyContent || 'between'}
            alignItems="center"
            padding="base"
        >
            {children}
        </Box>
    </NoPrint>
);

ContentHeaderBar.displayName = 'ContentHeaderBar';
