import * as React from 'react';
import { t } from 'app/translate';
import { Box, Card, RequirementItem, Text } from 'app/components';
import { useSelector } from 'react-redux';
import {
    getAvailableBaysByGroup,
    getDockingStationsGroups,
    getSystemControllerCount,
    getCameraCountByGroup,
    getDockingStationCount,
    getAvailableSystemControllers,
} from '../../selectors';

const splitName = (name: string) => name.split(' ', 2).join(' ');

export const WearablesRequirements: React.FC = () => {
    const systemControllerCount = useSelector(getSystemControllerCount);
    const dockingStationCount = useSelector(getDockingStationCount);
    const dockingStationGroups = useSelector(getDockingStationsGroups);
    const availableBaysByGroup = useSelector(getAvailableBaysByGroup);
    const cameraCountByGroup = useSelector(getCameraCountByGroup);
    // Since there is only one system controller for now, there is no need to look at more than one
    const availableSystemControllers = useSelector(getAvailableSystemControllers)[0];

    const desiredControllerCount = React.useMemo(() => {
        return Math.ceil(dockingStationCount / availableSystemControllers.maxDockingStations);
    }, [dockingStationCount, availableSystemControllers]);

    const showRequirements = (index: number) => {
        return availableBaysByGroup[index] > 0 || cameraCountByGroup[index] > 0;
    };
    const noDevicesAdded = dockingStationGroups.every((_, index) => !showRequirements(index));

    return (
        <Box direction="column" alignItems="center" spacing="half">
            <Box paddingTop="panel" width="100%" justifyContent="center">
                <Text color="grey6" style="semibold">
                    {t.systemControllers}
                </Text>
            </Box>
            <Card notFullWidth>
                <Box direction="column" justifyContent="center" alignItems="center" padding="base">
                    <RequirementItem
                        label={splitName(availableSystemControllers.name)}
                        totalInSolution={systemControllerCount}
                        totalRequired={desiredControllerCount}
                        inSolutionText={systemControllerCount}
                        requiredText={desiredControllerCount}
                    />
                </Box>
            </Card>
            <Box paddingTop="panel" width="100%" justifyContent="center">
                <Text color="grey6" style="semibold">
                    {t.dockingStations}
                </Text>
            </Box>
            <Card notFullWidth>
                {noDevicesAdded ? (
                    <Box justifyContent="center" alignItems="center" padding="panel" width="100%">
                        <Text color="grey5">{t.addDevicesToGetWearablesRequirements}</Text>
                    </Box>
                ) : (
                    <Box justifyContent="center" spacing="base" padding="base">
                        {dockingStationGroups.map(
                            (group, index) =>
                                showRequirements(index) && (
                                    <RequirementItem
                                        key={index}
                                        label={`${group
                                            .map(({ name }) => splitName(name))
                                            .join(', ')}`}
                                        totalInSolution={availableBaysByGroup[index]}
                                        totalRequired={cameraCountByGroup[index]}
                                        inSolutionText={availableBaysByGroup[index]}
                                        requiredText={cameraCountByGroup[index]}
                                        descriptionInSolution={t.dockingBays}
                                        descriptionRequired={t.cameras}
                                    />
                                ),
                        )}
                    </Box>
                )}
            </Card>
        </Box>
    );
};
WearablesRequirements.displayName = 'WearablesRequirements';
