import * as React from 'react';
import type { IMenuItem } from 'app/components';
import { MenuItem } from 'app/components';
import { useService } from 'app/ioc';
import type { IAnimationObserver } from '../services';
import { ProjectMenuAnimationService } from '../services';

export const ProjectMenuItem: React.FC<IMenuItem> = ({ link, icon, text, testId, warn }) => {
    const [animate, setAnimate] = React.useState<boolean | undefined>(undefined);
    const animationService = useService(ProjectMenuAnimationService);
    const animationNotify = (idToAnimate: string) => {
        if (testId === idToAnimate) {
            setAnimate(true);
            setTimeout(() => setAnimate(false), 2000);
        }
    };
    const [observer] = React.useState<IAnimationObserver>({ animate: animationNotify });

    React.useEffect(() => {
        animationService.attach(observer);
        return () => {
            animationService.detach(observer);
        };
    });

    return (
        <MenuItem
            testId={testId}
            link={link}
            icon={icon}
            text={text}
            warn={warn}
            animateOnAdd={animate}
        />
    );
};

ProjectMenuItem.displayName = 'ProjectMenuItem';
