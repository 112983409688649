import type { IUserCustomItemEntity } from 'app/core/persistence';
import { memoize } from 'lodash-es';
import type { CustomItemField } from '../components';
import type { ICustomItemDisplayCat } from '../models';

export const getDropdownUserItems = memoize(
    (items: IUserCustomItemEntity[], parameter: CustomItemField, searchValue: string | number) => {
        if (items.length < 1 || !searchValue) {
            return [];
        }
        const checkedItems: Record<string, ICustomItemDisplayCat[]> = {};

        items.forEach((item: ICustomItemDisplayCat) => {
            // Only add items that match search searchValue
            if (
                !(item[parameter] ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(searchValue.toString().toLowerCase())
            ) {
                return;
            }

            // Display category for items that are identical except for category
            const recordId = [item.name, item.partNumber, item.vendor].join(', ');
            if (!checkedItems[recordId]) {
                checkedItems[recordId] = [item];
            } else {
                checkedItems[recordId].forEach(
                    (checkedItem) => (checkedItem.displayCategory = true),
                );
                checkedItems[recordId].push({ ...item, displayCategory: true });
            }
        });

        return Object.values(checkedItems).flat();
    },
    (...args) => JSON.stringify(args),
);
