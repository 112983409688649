import { injectable } from 'inversify';
import { AppSettings } from 'app/AppSettings';
import type { IUserInfoResponse, IUserPrivilegesResponse } from '../models';
import { eventTracking } from 'app/core/tracking';

/** Use name when sending token to server like
 * @example
 * const tokens = await this.userApiCommunicator.fetchUserToken();
 * const headers = new Headers();
 * headers.append(X_AXIS_USER_API_AUTH, `Bearer ${tokens?.accessToken}`);
 */
export const X_AXIS_USER_API_AUTH = 'x-axis-user-api-auth';

export interface IUsersAPIToken {
    accessToken: string;
    expiresIn: string;
    scope: string;
    tokenType: string;
}

@injectable()
export class UserApiCommunicator {
    constructor(private appSettings: AppSettings) {}

    /**
     * Fetch user data from API.
     * Will return null upon error.
     * Any errors will be logged.
     */
    public fetchUserInfo = async (): Promise<IUserInfoResponse | null> => {
        try {
            const response = await this.genericUserApiRequest(this.appSettings.userApiEndpoint);
            return response.json();
        } catch {
            return null;
        }
    };

    /**
     * Fetch user token from API.
     * Will return null upon error.
     * Any errors will be logged.
     */
    public fetchUserToken = async (): Promise<IUsersAPIToken | null> => {
        try {
            let token = this.loadUserToken();
            if (token) {
                // Load user token and check if expired.
                const now = new Date();
                const expiresIn = new Date(JSON.parse(token.expiresIn));
                if (expiresIn < now) {
                    // User token has expired fetch new token.
                    token = await this.getUserApiToken();
                    this.saveUserToken(token);
                }
            } else {
                // No user token fetch new token
                token = await this.getUserApiToken();
                this.saveUserToken(token);
            }
            return token;
        } catch {
            return null;
        }
    };

    public saveUserToken = (token: IUsersAPIToken): void => {
        try {
            sessionStorage.setItem('user_api_auth', JSON.stringify(token));
        } catch {
            console.error('Error while save user_api_auth tokens');
        }
    };

    public loadUserToken = (): IUsersAPIToken | null => {
        try {
            return JSON.parse(sessionStorage.getItem('user_api_auth') || '');
        } catch {
            return null;
        }
    };

    public clearUserToken = (): void => {
        try {
            sessionStorage.removeItem('user_api_auth');
        } catch {
            console.error('Error while clear user_api_auth token');
        }
    };

    /**
     * Fetch user token as Headers from API.
     * Will return null upon error.
     * Any errors will be logged.
     */
    public fetchUserTokenHeaders = async (): Promise<Headers> => {
        const tokens = await this.fetchUserToken();
        const headers = new Headers();
        headers.append(X_AXIS_USER_API_AUTH, `Bearer ${tokens?.accessToken}`);
        return headers;
    };

    /**
     * Fetch user privileges from API.
     * Will return null upon error.
     * Any errors will be logged.
     */
    public fetchUserPrivileges = async (): Promise<IUserPrivilegesResponse | null> => {
        try {
            const response = await this.genericUserApiRequest(
                this.appSettings.userApiPrivilegesEndpoint,
            );
            return response.json();
        } catch {
            return null;
        }
    };

    /**
     * Fetch token to be used in the user API.
     */
    private getUserApiToken = (): Promise<IUsersAPIToken> => {
        const url = this.appSettings.userApiTokenEndpoint;

        return fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            cache: 'no-cache',
        })
            .then(async (response) => {
                if (!response.ok) {
                    this.logHttpErrors(response);
                    throw new Error('Response not ok');
                } else {
                    const tokens = await response.json();
                    const tokenExpiresIn = new Date();
                    tokenExpiresIn.setSeconds(new Date().getSeconds() + tokens.expires_in);

                    return {
                        accessToken: tokens.access_token,
                        expiresIn: JSON.stringify(tokenExpiresIn),
                        scope: tokens.scope,
                        tokenType: tokens.token_type,
                    };
                }
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    /**
     * Call the user API.
     * Logs errors and "not ok" responses.
     */
    private genericUserApiRequest = async (endpoint: string): Promise<Response> => {
        const tokens = await this.getUserApiToken();
        const headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append(X_AXIS_USER_API_AUTH, `Bearer ${tokens?.accessToken}`);

        return fetch(endpoint, {
            method: 'GET',
            headers,
            credentials: 'include',
            cache: 'no-cache',
        })
            .then((response) => {
                if (!response.ok) {
                    this.logHttpErrors(response);
                    throw new Error('Response not ok');
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    /**
     * Log any http errors except 403 Forbidden since that is used
     * if the auth cookie is missing.
     */
    private logHttpErrors = (response: Response): void => {
        if (response.status === 403) {
            return;
        }
        eventTracking.logError(
            response.statusText,
            this.appSettings.userApiEndpoint,
            response.status,
        );
    };
}
