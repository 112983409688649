import { AXIS_OBJECT_ANALYTICS, AXIS_PERIMETER_DEFENDER } from 'app/core/common';
import type { AnalyticMode as AnalyticModes } from 'app/core/persistence';
import { t } from 'app/translate';
import type { IAnalyticsAdditionalInfo } from './IAnalyticsAdditionalInfo';

interface IAnalyticsConstantProps {
    analyticName: string;
    analyticInfo: string;
    analyticManualLink: string;
    productInfo?: string;
    productManualLink?: string;
    additionalInfo?: IAnalyticsAdditionalInfo[];
}

export const analyticSettings: AnalyticModes[] = ['calibration', 'ai'];

const getManualLink = (productName?: string) => {
    const productLink = productName?.toLowerCase().replace(' ', '-');
    const link = productName ? `https://help.axis.com/en/${productLink}` : '';
    return link;
};

/** Gets record of title, information text and link associated with applications that should have an information modal. */
export const getAnalyticsInfo = (
    acapId?: number,
    productName?: string,
): IAnalyticsConstantProps | undefined => {
    switch (acapId) {
        case AXIS_OBJECT_ANALYTICS:
            return {
                analyticName: 'AXIS Object Analytics',
                analyticInfo: t.aoaForBestResults,
                analyticManualLink: 'https://help.axis.com/axis-object-analytics',
                productInfo: productName ? t.aoaDeviceInfo : undefined,
                productManualLink: productName ? getManualLink(productName) : undefined,
            };
        case AXIS_PERIMETER_DEFENDER:
            return {
                analyticName: 'AXIS Perimeter Defender',
                analyticInfo: t.aoaForBestResults,
                analyticManualLink: 'https://help.axis.com/axis-perimeter-defender',
                productInfo: productName ? t.aoaDeviceInfo : undefined,
                productManualLink: productName ? getManualLink(productName) : undefined,
                additionalInfo: [
                    {
                        linkText: t.readDocument,
                        informationText: t.learnAboutFog,
                        link: 'https://www.axis.com/dam/public/1c/66/25/thermal-cameras-en-US-350481.pdf',
                    },
                ],
            };
        default:
            return undefined;
    }
};
