import * as React from 'react';
import { useWindowSize } from './useWindowSize.hook';

export const DesktopMinSizePx = 1024;
export const TabletMinSizePx = 768;
export const MobileMaxSizePx = 768;

/**
 * Use this hook if your component needs to have different values depending on the current screen size.
 * @param onDesktop Value to be returned on desktop screen size
 * @param onMobile Value to be returned on mobile screen size
 * @param onTablet Value to be returned on tablet screen size
 * @param breakPoints Custom break points to use
 */
export const useResponsive = <T>(
    onDesktop: T,
    onMobile?: T,
    onTablet?: T,
    breakPoints?: { desktop?: number; mobile?: number; tablet?: number },
) => {
    const [value, setValue] = React.useState<T>();
    const windowSize = useWindowSize();
    const desktopMinSize = breakPoints?.desktop ?? DesktopMinSizePx;
    const tabletMinSize = breakPoints?.tablet ?? TabletMinSizePx;
    const mobileMaxSize = breakPoints?.mobile ?? MobileMaxSizePx;

    React.useEffect(() => {
        if (windowSize.width > desktopMinSize) {
            setValue(onDesktop);
        } else if (onTablet && windowSize.width > tabletMinSize) {
            setValue(onTablet);
        } else if (!onTablet && windowSize.width > tabletMinSize) {
            setValue(onDesktop);
        } else if (onMobile && windowSize.width <= mobileMaxSize) {
            setValue(onMobile);
        } else {
            setValue(undefined);
        }
    }, [desktopMinSize, mobileMaxSize, onDesktop, onMobile, onTablet, tabletMinSize, windowSize]);

    return value;
};
