import { injectable } from 'inversify';
import { KeyValueStore } from 'axis-webtools-util';
import type { IContactDetails } from 'app/modules/common';
import { AppSettings } from 'app/AppSettings';
import type { IUserInfo } from '../models';
import { eventTracking } from 'app/core/tracking';

@injectable()
export class ContactDetailsService {
    private userInfoOverrideStore: KeyValueStore<IContactDetails>;

    constructor(private appSettings: AppSettings) {
        this.userInfoOverrideStore = new KeyValueStore<IContactDetails>(
            appSettings.userInfoDbName,
            appSettings.userInfoOverrideStoreName,
        );
    }

    public async getContactDetails(userApiInfo: IContactDetails | null): Promise<IContactDetails> {
        const editedInfo = await this.getContactDetailsFromDB();
        return {
            ...userApiInfo,
            ...editedInfo,
        };
    }

    public setContactDetails(newContactDetails: IContactDetails): Promise<void> {
        try {
            return this.userInfoOverrideStore.setItem(
                this.appSettings.userInfoOverrideStoreName,
                newContactDetails,
            );
        } catch (error) {
            eventTracking.logError(
                'Updating contact details in IndexedDB failed',
                'ContactDetailsService',
            );
            throw error;
        }
    }

    public clear(): Promise<void> {
        return this.userInfoOverrideStore.clear();
    }

    public convertUserInfoToContactDetails(user: IUserInfo | null): IContactDetails | null {
        if (!user) {
            return null;
        }

        return {
            companyName: user.companyName || undefined,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber || undefined,
            streetAddress: user.streetAddress || undefined,
            city: user.city || undefined,
            zipCode: user.zipCode || undefined,
            country: user.country || undefined,
            usState: user.state || undefined,
        };
    }

    private async getContactDetailsFromDB(): Promise<IContactDetails | null> {
        try {
            return this.userInfoOverrideStore.getItem(this.appSettings.userInfoOverrideStoreName);
        } catch (error) {
            eventTracking.logError(
                'Retrieving contact details in IndexedDB failed',
                'ContactDetailsService',
            );
            return null;
        }
    }
}
