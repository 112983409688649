import * as React from 'react';
import type { IOptionProps } from '../../input/select/Select.component';
import { Select } from '../../input/select/Select.component';

interface IPiaLocationSelectProps {
    selectedPiaLocationId?: number;
    piaLocations: Array<{
        value: string | number;
        text: string;
    }>;
    disabled?: boolean;
    openInPortal?: boolean;
    label?: string;
    fillWidth?: boolean;
    onChange(piaLocationId: number): void;
}

export class PiaLocationSelect extends React.Component<IPiaLocationSelectProps> {
    public render() {
        return (
            <Select
                testId="select_ddm_project_country"
                testIdChild="ddm_project_country"
                value={this.props.selectedPiaLocationId}
                options={this.setOptions()}
                disabled={this.props.disabled}
                onChange={this.onChange}
                openInPortal={this.props.openInPortal}
                label={this.props.label}
                fillWidth={this.props.fillWidth}
            />
        );
    }

    private setOptions = (): IOptionProps[] => {
        return this.props.piaLocations.map((location) => ({
            value: location.value,
            text: location.text,
        }));
    };

    private onChange = (piaLocationId: string) => {
        this.props.onChange(Number(piaLocationId));
    };
}
