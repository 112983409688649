import type { IProgressToast } from './IProgressToast.interface';
import { ToastModel } from './Toast.model';

export class ProgressToastModel extends ToastModel implements IProgressToast {
    constructor(
        readonly id: number,
        headline: string,
        message: string | undefined,
        readonly secondsToLive: number = 0,
        readonly deleteAction: (toastToDelete: ToastModel) => void,
        readonly updateToast: (toastToUpdate: ToastModel) => void,
    ) {
        super(id, headline, message, undefined, secondsToLive, 'progress', deleteAction);
    }

    public setSuccess(headline: string, message: string): void {
        this.headline = headline;
        this.message = message;
        this.toastType = 'success';
        this.icon = 'check_circle';
        this.startDeletionTimer();
        this.updateToast(this);
    }

    public setError(headline: string, message?: string | undefined): void {
        this.headline = headline;
        this.message = message;
        this.toastType = 'error';
        this.icon = 'error';
        this.startDeletionTimer();
        this.updateToast(this);
    }
}
