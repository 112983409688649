import { createCachedSelector } from 're-reselect';
import {
    getBarebonePiaId,
    getCurrentProjectItems,
    getPiaIdFromProps,
    toCacheKey,
} from 'app/modules/common';
import { getBestReplacementLens } from './getBestReplacementLens';
import { getEditDeviceId } from './getProductItem';
import { getDeviceGroup } from './getProductsForDeviceGroup';
import { DeviceGroups } from '../models';
import type { IStoreState } from 'app/store';
import { getLensesForProductItem } from './getLensToDisplayForProductItem';
import type { PiaId } from 'app/core/pia';

const getIsAddingOrUpdating = (state: IStoreState) => state.deviceSelector.isAddingOrUpdating;

/**
 * Retrieves piaIds for MSRP related to a product. For cameras/sensor units that match scene requirements only in combination with an accessory lens,
 * add accessory lens to MSRP ids and check if a barebone model is available.
 * If so, also replaces primary productId for MSRP with barebone Id.
 */
export const getPiaIdsForMsrp = createCachedSelector(
    [
        getPiaIdFromProps,
        getBarebonePiaId,
        getBestReplacementLens,
        getEditDeviceId,
        getCurrentProjectItems,
        getDeviceGroup,
        getIsAddingOrUpdating,
        getLensesForProductItem,
    ],
    (
        piaIdFromProps,
        availableBarebonePiaId,
        bestReplacementLens,
        editDeviceId,
        currentProjectItems,
        deviceGroup,
        isAddingOrUpdating,
        lensToDisplay,
    ): PiaId[] => {
        if (piaIdFromProps === undefined) {
            return [];
        }
        if (deviceGroup !== DeviceGroups.CAMERAS && deviceGroup !== DeviceGroups.SENSORUNITS) {
            // Return piaIdFromProps early if accessory lenses and barebone are not applicable to device group
            return [piaIdFromProps];
        }

        const editItem =
            !!editDeviceId &&
            currentProjectItems?.[editDeviceId]?.productId === piaIdFromProps &&
            currentProjectItems[editDeviceId];

        // If item is being edited, check for it's persisted barebone property. Otherwise barebone should be selected by default if available.
        const bareboneProductId =
            editItem && !isAddingOrUpdating
                ? editItem.replaceWithBareboneId
                : availableBarebonePiaId;

        // If a replacement lens that matches scene requirement and barebone is available for product set barebonePiaId as primary item id.
        const primaryItemPiaId =
            bestReplacementLens && bareboneProductId ? bareboneProductId : piaIdFromProps;

        return [primaryItemPiaId, ...(lensToDisplay ? lensToDisplay.map((lens) => lens.id) : [])];
    },
)(toCacheKey);
