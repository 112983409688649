import { createSelector } from 'reselect';
import type {
    IOrganizationOrResourceGroup,
    IResourceGroupInfo,
    ResourceGroupType,
} from 'app/core/persistence';
import { isEmpty } from 'lodash-es';
import { getSelectedOrganization, getSharedProjectTreeInfo } from './getOrganizations';

/** Gets resource tree below selected organization */
export const getResourceGroups = createSelector(
    [getSelectedOrganization],
    (selectedOrganization) => {
        const resourceTree: ResourceGroupType | undefined =
            selectedOrganization?.resourceTree.resourceTree;

        return resourceTree && !isEmpty(resourceTree)
            ? resourceTree[Object.keys(resourceTree)[0]].children
            : undefined;
    },
);

export const getFlattenedResourceTree = createSelector([getResourceGroups], (resourceGroups) => {
    return resourceGroups ? getFlattened(resourceGroups) : undefined;
});

export const getSelectedResourceGroup = createSelector(
    [getSharedProjectTreeInfo],
    (sharedProjectInfo) => sharedProjectInfo?.resourceGroup,
);

const getFlattened = (resourceGroup: Record<string, IResourceGroupInfo>, parentName?: string) => {
    const flattenedGroups: IOrganizationOrResourceGroup[] = [];
    for (const [key, value] of Object.entries(resourceGroup)) {
        const displayName = parentName ? `${parentName} / ${value.name}` : `${value.name}`;
        flattenedGroups.push({
            name: value.name,
            arn: key,
            displayName,
        });
        if (value.children) {
            flattenedGroups.push(...getFlattened(value.children, displayName));
        }
    }
    return flattenedGroups;
};
