import { IPv4, IPv4CidrRange, IPv4Mask, IPv4Prefix, RangedSet, Validator } from 'ip-num';
import type { IProjectNetworkSettings } from './IProjectNetworkSettings';

export const getNbrTotalAvailableIPAddresses = (
    networkSettings: IProjectNetworkSettings | undefined,
) => {
    let rangeSize = 0;
    if (networkSettings) {
        const { ipStart, ipEnd, subnetMask, defaultRouter } = networkSettings;
        if (ipStart && ipEnd) {
            try {
                const rangeSet = RangedSet.fromRangeString(`${ipStart}-${ipEnd}`);
                if (!rangeSet.getFirst().isGreaterThanOrEquals(rangeSet.getLast())) {
                    rangeSize = Number(rangeSet.getSize());
                }
                if (
                    defaultRouter &&
                    Validator.isValidIPv4String(defaultRouter)[0] &&
                    rangeSize > 0
                ) {
                    const router = new IPv4(defaultRouter);
                    if (
                        rangeSet.getFirst().isLessThanOrEquals(router) &&
                        rangeSet.getLast().isGreaterThanOrEquals(router)
                    ) {
                        rangeSize -= 1;
                    }
                }

                if (subnetMask && Validator.isValidIPv4String(subnetMask)[0]) {
                    const ipv4CidrRange = new IPv4CidrRange(
                        new IPv4(ipStart),
                        new IPv4Prefix(BigInt(IPv4Mask.fromDecimalDottedString(subnetMask).prefix)),
                    );
                    //  first address is the network identification.
                    if (ipv4CidrRange.getFirst().isEquals(rangeSet.getFirst()) && rangeSize > 0) {
                        rangeSize -= 1;
                    }
                    // last address is the broadcast.
                    if (ipv4CidrRange.getLast().isEquals(rangeSet.getLast()) && rangeSize > 0) {
                        rangeSize -= 1;
                    }
                }
            } catch (e) {
                // Ignore error when parsing IP address strings
                rangeSize = 0;
            }
        }
    }
    return rangeSize;
};
