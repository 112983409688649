import { injectable } from 'inversify';
import type { IItemEntity, IPersistence, IItemRelationEntity } from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import type { DeviceCount } from '../../models';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';

@injectable()
export class DeviceCountService {
    constructor(private piaItemService: PiaItemService<IPiaItem>) {}

    /**
     * Returns the total amount of devices considered as "installable".
     * @param deviceCount quantity of the different devices in the project
     */
    public getInstallableDevicesCount(deviceCount: DeviceCount): number {
        return (
            deviceCount.accessServer +
            deviceCount.answeringUnit +
            deviceCount.camera +
            deviceCount.decoder +
            deviceCount.doorcontroller +
            deviceCount.doorstation +
            deviceCount.encoder +
            deviceCount.mainUnit +
            deviceCount.networkReader +
            deviceCount.pac +
            deviceCount.radardetector +
            deviceCount.sensorUnit +
            deviceCount.speaker +
            deviceCount.peopleCounter +
            deviceCount.alerter
        );
    }

    /**
     * Returns the sum of all devices in argument
     * @param deviceCount quantity of the different devices in the project
     * @returns total number of devices
     */
    public getTotalDevicesCount(deviceCount: DeviceCount): number {
        return Object.values(deviceCount).reduce((sum, current) => sum + current, 0);
    }

    /**
     * Returns the quantity of different types of devices from an array of items
     * @param items the items to count
     **/
    public getDeviceCount(
        items: IPersistence<IItemEntity>[],
        itemRelations: IPersistence<IItemRelationEntity>[],
    ): DeviceCount {
        const deviceCount = this.getNewDeviceCountObject();

        for (const item of items) {
            const piaItem = item.productId ? this.piaItemService.get(item.productId).first() : null;

            if (piaItem?.category === 'doorstations') {
                deviceCount.doorstation += item.quantity;
            } else if (piaItem?.category === 'accessServers') {
                deviceCount.accessServer += item.quantity;
            } else if (piaItem?.category === 'answeringUnits') {
                deviceCount.answeringUnit += item.quantity;
            } else if (piaItem?.category === 'alerters') {
                deviceCount.alerter += item.quantity;
            } else if (deviceTypeCheckers.isBodyWornCamera(item)) {
                deviceCount.bodyWornCamera += item.quantity;
            } else if (deviceTypeCheckers.isCamera(item)) {
                deviceCount.camera += item.quantity;
            } else if (deviceTypeCheckers.isCameraExtension(item)) {
                deviceCount.cameraExtension += item.quantity;
            } else if (deviceTypeCheckers.isDecoder(item)) {
                deviceCount.decoder += item.quantity;
            } else if (deviceTypeCheckers.isDockingStation(item)) {
                deviceCount.dockingStation += item.quantity;
            } else if (deviceTypeCheckers.isDoorController(item)) {
                deviceCount.doorcontroller += item.quantity;
            } else if (deviceTypeCheckers.isEncoder(item)) {
                deviceCount.encoder += item.quantity;
            } else if (deviceTypeCheckers.isMainUnit(item)) {
                deviceCount.mainUnit += item.quantity;
            } else if (piaItem?.category === 'networkReaders') {
                deviceCount.networkReader += item.quantity;
            } else if (deviceTypeCheckers.isPac(item)) {
                deviceCount.pac += item.quantity;
            } else if (deviceTypeCheckers.isPeopleCounter(item)) {
                deviceCount.peopleCounter += item.quantity;
            } else if (deviceTypeCheckers.isRadarDetector(item)) {
                deviceCount.radardetector += item.quantity;
            } else if (deviceTypeCheckers.isSpeaker(item)) {
                deviceCount.speaker += item.quantity;
            } else if (deviceTypeCheckers.isSystemController(item)) {
                deviceCount.systemController += item.quantity;
            } else if (deviceTypeCheckers.isConnectivityDevice(item)) {
                deviceCount.connectivitydevice += item.quantity;
            }

            const relations = itemRelations.filter((relation) => relation.parentId === item._id);

            for (const relation of relations) {
                const childItem = items.find(
                    (relationItem) => relationItem._id === relation.childId,
                );

                if (childItem) {
                    if (relation.relationType === 'analogCamera') {
                        deviceCount.analogCamera += item.quantity * childItem.quantity;
                    } else if (relation.relationType === 'sensorUnit') {
                        deviceCount.sensorUnit += item.quantity * childItem.quantity;
                    }
                }
            }
        }

        return deviceCount;
    }

    private getNewDeviceCountObject(): DeviceCount {
        return {
            accessServer: 0,
            analogCamera: 0,
            answeringUnit: 0,
            alerter: 0,
            camera: 0,
            cameraExtension: 0,
            decoder: 0,
            doorcontroller: 0,
            doorstation: 0,
            encoder: 0,
            mainUnit: 0,
            networkReader: 0,
            iorelay: 0,
            pac: 0,
            radardetector: 0,
            connectivitydevice: 0,
            sensorUnit: 0,
            speaker: 0,
            peopleCounter: 0,
            bodyWornCamera: 0,
            systemController: 0,
            dockingStation: 0,
            door: 0,
            relayexpmodules: 0,
            pagingconsole: 0,
        };
    }
}
