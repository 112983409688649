import { isSpeaker } from 'app/core/persistence';
import { getCeilingMaxDistance } from '../../../../utils';
import type { IInstallationPointModel } from 'app/core/persistence';

export const getMaxDistance = (installationPoint: IInstallationPointModel) => {
    const piaDevice = installationPoint.parentPiaDevice;
    const isCeilingMountedSpeaker =
        installationPoint &&
        isSpeaker(piaDevice) &&
        installationPoint.parentDevice.properties.speaker?.filter.placement === 'ceiling';

    if (isCeilingMountedSpeaker) {
        return getCeilingMaxDistance(
            installationPoint.height,
            piaDevice.properties?.horizontalSpeakerCoverage,
            installationPoint.speaker?.settings.basicSolution ?? true,
        );
    } else {
        return Number.MAX_VALUE;
    }
};
