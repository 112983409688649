import { injectable } from 'inversify';
import type {
    IPersistence,
    IScheduleEntity,
    ITimeSerieEntity,
    Id,
    ITimeSerie,
} from '../userDataPersistence';
import { ScheduleRepository, TimeSerieRepository } from '../userDataPersistence';
import { Time24, ScheduleDays } from '../models';
import { ProjectService } from './Project.service';
import { NameGenerationService } from './NameGeneration.service';
import { AppConstants } from 'app/AppConstants';
import { t } from 'app/translate';
import { CurrentProjectService } from './CurrentProject.service';
import { toaster } from 'app/toaster';
import { getParentId } from '../utils';

@injectable()
export class ScheduleService {
    constructor(
        private scheduleRepository: ScheduleRepository,
        private timeSerieRepository: TimeSerieRepository,
        private nameGenerationService: NameGenerationService,
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async addSchedule(): Promise<IPersistence<IScheduleEntity>> {
        const { _id: projectId, archived } = this.currentProjectService.getProjectEntity();

        const name = this.getNewName(t.schedulesNewScheduleName);

        const scheduleEntity: IScheduleEntity = {
            type: 'schedule',
            path: [projectId],
            archived,
            name,
            systemDefined: false,
            locked: await this.projectService.isLocked(projectId!),
        };
        const schedule = await this.scheduleRepository.add(scheduleEntity);

        const timeSerie = this.getDefaultBaseTimeSerieEntity();
        const timeSerieEntity: ITimeSerieEntity = {
            type: 'timeSerie',
            path: [...schedule.path],
            archived,
            start: timeSerie.start,
            end: timeSerie.end,
            days: timeSerie.days,
            locked: schedule.locked,
        };
        await this.timeSerieRepository.add(timeSerieEntity);

        return schedule;
    }

    public async updateSchedule(id: Id, name: string): Promise<IScheduleEntity | boolean> {
        const schedule = this.currentProjectService.getEntity(id, 'schedule');
        if (schedule.systemDefined) {
            throw new Error('System defined schedules cannot be edited');
        }

        const scheduleNames = this.getScheduleNames().filter(
            (scheduleName) => scheduleName !== schedule.name,
        );
        const trimmedName = name.trim();
        const isNameAlreadyUsed = scheduleNames.some(
            (scheduleName) => scheduleName === trimmedName,
        );

        if (isNameAlreadyUsed || trimmedName.length < 1) {
            toaster.warning(
                t.schedulesNameChangeFailedToastHeader,
                trimmedName.length < 1 ? '' : t.schedulesNameChangeFailedToastBody(trimmedName),
            );
            return false;
        }

        return this.scheduleRepository.update({ ...schedule, name: trimmedName });
    }

    public async duplicateSchedule(scheduleId: Id): Promise<IPersistence<IScheduleEntity>> {
        const oldEntity = this.currentProjectService.getEntity(scheduleId, 'schedule');
        const newEntity = this.copyEntity(oldEntity);
        const newSchedule = await this.scheduleRepository.add(newEntity);

        const timeSeries = this.getTimeSeriesForSchedule(scheduleId);
        // Check for inverted schedule that also needs to be duplicated
        let invertedSchedule = null;
        if (timeSeries.length > 0) {
            invertedSchedule = await this.getInvertedSchedule(timeSeries[0]);
        }
        for (const timeSerie of timeSeries) {
            const newTimeSerie = {
                ...timeSerie,
                path: [...newSchedule.path],
            };

            await this.timeSerieRepository.add(newTimeSerie);
        }

        if (invertedSchedule) {
            await this.addInvertedSchedule(newSchedule._id, invertedSchedule.name);
        }
        return newSchedule;
    }

    public async deleteSchedule(id: Id): Promise<Id> {
        const schedule = this.currentProjectService.getEntity(id, 'schedule');
        return this.scheduleRepository.delete(schedule._id, schedule._rev);
    }

    public async addTimeSerie(
        schedule: IPersistence<IScheduleEntity>,
    ): Promise<IPersistence<ITimeSerieEntity>> {
        const timeSerie = this.getDefaultBaseTimeSerieEntity();
        const timeSerieEntity: ITimeSerieEntity = {
            type: 'timeSerie',
            path: [...schedule.path],
            start: timeSerie.start,
            end: timeSerie.end,
            days: timeSerie.days,
            locked: schedule.locked,
            archived: schedule.archived,
        };
        const newTimeSerie = await this.timeSerieRepository.add(timeSerieEntity);
        // If an inverted schedule exists, update it.
        const invertedSchedule = await this.getInvertedSchedule(newTimeSerie);
        if (invertedSchedule) {
            await this.addInvertedTimeSerie(invertedSchedule, newTimeSerie);
            await this.updateInactiveDaysTimeSerie(schedule._id);
        }
        return newTimeSerie;
    }

    /**
     * Creates an inverse of a schedule with its timeseries.
     * The new inverted schedule will be a child to the original.
     *
     * @param originalId  - The schedule to be inverted
     */
    public async addInvertedSchedule(
        originalId: Id,
        name?: string,
    ): Promise<IPersistence<IScheduleEntity>> {
        const originalSchedule = this.currentProjectService.getEntity(originalId, 'schedule');
        const scheduleName = this.getNewName(name ?? `${originalSchedule.name} - ${t.inverted}`);

        const newEntity: IScheduleEntity = {
            type: 'schedule',
            path: [...originalSchedule.path],
            name: scheduleName,
            systemDefined: false,
            locked: originalSchedule.locked,
            archived: originalSchedule.archived,
        };
        const newSchedule = await this.scheduleRepository.add(newEntity);
        const timeSeries = this.getTimeSeriesForSchedule(originalId);

        await Promise.all(
            timeSeries.map((timeSerie) => {
                this.addInvertedTimeSerie(newSchedule, timeSerie);
            }),
        );

        await this.updateInactiveDaysTimeSerie(originalSchedule._id);

        return newSchedule;
    }

    public async updateTimeSerieTime(
        id: Id,
        startValue: Time24,
        endValue: Time24,
    ): Promise<ITimeSerieEntity> {
        const timeSerie = this.currentProjectService.getEntity(id, 'timeSerie');
        const updatedTimeSerie = await this.updateTimeSerie({
            ...timeSerie,
            start: startValue.toPersistable(),
            end: endValue.toPersistable(),
        });

        return updatedTimeSerie;
    }

    public async updateTimeSerieDays(id: Id, newDays: ScheduleDays): Promise<ITimeSerieEntity> {
        const timeSerie = this.currentProjectService.getEntity(id, 'timeSerie');
        const updatedTimeSerie = await this.updateTimeSerie({
            ...timeSerie,
            days: newDays.toPersistable(),
        });

        return updatedTimeSerie;
    }

    public async updateTimeSerie(
        timeSerie: IPersistence<ITimeSerieEntity>,
    ): Promise<IPersistence<ITimeSerieEntity>> {
        // Check for inverted timeSerie
        const timeSeries = this.currentProjectService.getAllEntitiesOfType('timeSerie');
        const existingSerie = timeSeries.find((serie) => serie.originalId === timeSerie._id);
        if (existingSerie) {
            await this.updateInvertedTimeSerie(timeSerie, existingSerie);
        }

        return this.timeSerieRepository.update(timeSerie);
    }

    public async removeTimeSerie(id: Id): Promise<Id> {
        const timeSerie = this.currentProjectService.getEntity(id, 'timeSerie');
        // Check for inverted timeSerie
        const invertedSerie = this.currentProjectService
            .getAllEntitiesOfType('timeSerie')
            .find((serie) => serie.originalId === id);
        if (invertedSerie) {
            await this.timeSerieRepository.delete(invertedSerie._id, invertedSerie._rev);
            await this.updateInactiveDaysTimeSerie(getParentId(timeSerie));
        }

        return this.timeSerieRepository.delete(timeSerie._id, timeSerie._rev);
    }

    /**
     * Adds an inverted timeserie with originalId pointing to original timeserie.
     */
    public async addInvertedTimeSerie(
        schedule: IPersistence<IScheduleEntity>,
        timeSerie: IPersistence<ITimeSerieEntity>,
    ): Promise<IPersistence<ITimeSerieEntity>> {
        const invertedTimeSerie = this.getInvertedTimeSerie(timeSerie);
        const timeSerieEntity: ITimeSerieEntity = {
            type: 'timeSerie',
            path: [...schedule.path],
            days: invertedTimeSerie.days,
            start: invertedTimeSerie.start,
            end: invertedTimeSerie.end,
            locked: schedule.locked,
            archived: schedule.archived,
            originalId: timeSerie._id,
        };
        return this.timeSerieRepository.add(timeSerieEntity);
    }

    private getInvertedTimeSerie(original: ITimeSerie): ITimeSerie {
        const invertedTimeSerie: ITimeSerie = {
            start: original.end,
            end: original.start,
            days: original.days,
        };
        if (original.start === '00:00' && original.end !== '24:00') {
            invertedTimeSerie.end = '24:00';
        } else if (original.start !== '00:00' && original.end === '24:00') {
            invertedTimeSerie.start = '00:00';
        }
        return invertedTimeSerie;
    }

    private getNewName(desiredName: string): string {
        const names = this.getScheduleNames();
        return this.nameGenerationService.getName(
            desiredName,
            names,
            AppConstants.scheduleNameMaxLength,
        );
    }

    private copyEntity(entity: IScheduleEntity): IScheduleEntity {
        const { _id: projectId } = this.currentProjectService.getProjectEntity();
        const newName = this.getNewName(entity.name);

        return {
            ...entity,
            path: [projectId],
            name: newName,
        };
    }

    private getScheduleNames(): string[] {
        return this.currentProjectService
            .getAllEntitiesOfType('schedule')
            .map((schedule) => schedule.name);
    }

    private getDefaultBaseTimeSerieEntity(): ITimeSerie {
        return {
            start: new Time24('8:00').toPersistable(),
            end: new Time24('20:00').toPersistable(),
            days: new ScheduleDays([true, true, true, true, true, true, true]).toPersistable(),
        };
    }

    private getTimeSeriesForSchedule(scheduleId: Id): IPersistence<ITimeSerieEntity>[] {
        return this.currentProjectService
            .getAllEntitiesOfType('timeSerie')
            .filter((serie) => getParentId(serie) === scheduleId);
    }

    private async updateInvertedTimeSerie(
        original: IPersistence<ITimeSerieEntity>,
        existing: IPersistence<ITimeSerieEntity>,
    ) {
        const invertedTimeSerie = this.getInvertedTimeSerie(original);
        this.timeSerieRepository.update({
            ...existing,
            days: invertedTimeSerie.days,
            start: invertedTimeSerie.start,
            end: invertedTimeSerie.end,
        });
        this.updateInactiveDaysTimeSerie(getParentId(original));
    }

    private async updateInactiveDaysTimeSerie(originalScheduleId?: Id) {
        if (!originalScheduleId) {
            return null;
        }
        const originalSeries = (await this.timeSerieRepository.getAll()).filter(
            (serie) => getParentId(serie) === originalScheduleId,
        );
        const invertedSchedule = await this.getInvertedSchedule(originalSeries[0]);
        if (!invertedSchedule) {
            return null;
        }
        const invertedSeries = (await this.timeSerieRepository.getAll()).filter(
            (serie) => getParentId(serie) === invertedSchedule?._id,
        );

        const mappedTimeSeries = invertedSeries.filter((serie) => serie.originalId);
        const inactiveDaysTimeSerie = invertedSeries.find((serie) => !serie.originalId);

        // 127 is a full week
        const invertedDays = 127 - this.getScheduledDays(mappedTimeSeries);
        if (inactiveDaysTimeSerie && inactiveDaysTimeSerie.days === invertedDays) {
            return null;
        }

        if (invertedDays > 0) {
            // Not all days are set - create or update timeSerie
            if (inactiveDaysTimeSerie) {
                const updated = {
                    ...inactiveDaysTimeSerie,
                    days: invertedDays,
                };
                this.timeSerieRepository.update(updated);
            } else {
                const timeSerieEntity: ITimeSerieEntity = {
                    type: 'timeSerie',
                    path: [...invertedSchedule.path],
                    start: '00:00',
                    end: '24:00',
                    days: invertedDays,
                    locked: invertedSchedule.locked,
                    archived: invertedSchedule.archived,
                };
                this.timeSerieRepository.add(timeSerieEntity);
            }
        } else {
            // Remove existing if any
            inactiveDaysTimeSerie && this.removeTimeSerie(inactiveDaysTimeSerie._id);
        }
    }

    private getScheduledDays(timeSeries: IPersistence<ITimeSerieEntity>[]): number {
        let week = new ScheduleDays(0);
        timeSeries.forEach((serie) => {
            const timeSerieWeek = new ScheduleDays(serie.days);
            if (!week.hasMonday()) {
                week = week.setMonday(timeSerieWeek.hasMonday());
            }
            if (!week.hasTuesday()) {
                week = week.setTuesday(timeSerieWeek.hasTuesday());
            }
            if (!week.hasWednesday()) {
                week = week.setWednesday(timeSerieWeek.hasWednesday());
            }
            if (!week.hasThursday()) {
                week = week.setThursday(timeSerieWeek.hasThursday());
            }
            if (!week.hasFriday()) {
                week = week.setFriday(timeSerieWeek.hasFriday());
            }
            if (!week.hasSaturday()) {
                week = week.setSaturday(timeSerieWeek.hasSaturday());
            }
            if (!week.hasSunday()) {
                week = week.setSunday(timeSerieWeek.hasSunday());
            }
        });
        return week.toPersistable();
    }

    private async getInvertedSchedule(
        originalTimeSerie: IPersistence<ITimeSerieEntity>,
    ): Promise<IPersistence<IScheduleEntity> | null> {
        const scheduleId = getParentId(originalTimeSerie);
        if (!scheduleId) {
            return null;
        }

        const { descendants } = await this.scheduleRepository.getDescendants(scheduleId);
        return descendants.length > 0 ? descendants[0] : null;
    }
}
