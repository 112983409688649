import { PiaClient } from './client';

// If you want to change what pia database to run against locally change the variable in `webpack.config.dev`
const REMOTE_PIA_DATABASE_NAME = process.env.REMOTE_PIA_DATABASE_NAME || 'asd2-pia';

export function PiaClientFactory() {
    const piaClient = new PiaClient<any, any>({
        databaseName: 'asd-pia-v4',
        remoteDatabaseUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/pia-db/${REMOTE_PIA_DATABASE_NAME}`,
    });

    piaClient.initialize();

    return piaClient;
}
