import * as React from 'react';
import { Button } from '../button/Button.component';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { DisplayNone } from 'app/styles';

interface IUploadFileProps extends IAutoTestable {
    /**
     * Title is used as the button text if not custom trigger is supplied
     */
    title?: string;
    disabled?: boolean;
    primary?: boolean;
    text?: boolean;
    /**
     * A comma-separated string of file types to accept
     */
    accept: React.InputHTMLAttributes<HTMLInputElement>['accept'];
    /**
     * Provide a clickable child to render as custom trigger
     */
    children?: JSX.Element;
    onFileUploaded(file: File): void;
}

export class UploadFile extends React.PureComponent<IUploadFileProps> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IUploadFileProps) {
        super(props);
        this.inputRef = React.createRef();
    }

    public render() {
        const customComponent = this.props.children
            ? React.Children.only(this.props.children)
            : false;
        return (
            <>
                {customComponent ? (
                    React.cloneElement(customComponent, {
                        onClick: this.onActionClick,
                        disabled: this.props.disabled,
                    })
                ) : (
                    <Button
                        text={this.props.text}
                        primary={this.props.primary}
                        disabled={this.props.disabled}
                        onClick={this.onActionClick}
                    >
                        {this.props.title}
                    </Button>
                )}
                <input
                    data-test-id={toTestIdFormat(this.props.testId)}
                    className={DisplayNone}
                    ref={this.inputRef}
                    type="file"
                    accept={this.props.accept}
                    onChange={this.onFileInputChange}
                />
            </>
        );
    }

    private onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && this.inputRef.current && this.inputRef.current.value) {
            this.props.onFileUploaded(e.target.files[0]);
            /*
             * We reset the value of the file input by setting
             * its current value to 'null'. Since the typings
             * for this manuever requires a string we have to
             * case the value. Both of these approaches are a
             * bit of hacky, but there are no other ways unless
             * we would force a form around this control which is
             * not really an option.
             *
             * More about using 'null' to clear the field here:
             * https://stackoverflow.com/a/24608023
             */
            this.inputRef.current.value = null as any;
        }
    };

    private onActionClick = () => {
        // Event propagation does not work for the click so we
        // have to trigger it manually
        if (this.inputRef.current) {
            this.inputRef.current.click();
        }
    };
}
