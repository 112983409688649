import * as React from 'react';
import { useSelector } from 'react-redux';
import { getFloorPlansInstallationPointIdsFilteredByLayers } from '../../selectors';
import { InstallationPointMapItem } from './InstallationPointMapItem';

export const InstallationPoints: React.FC = () => {
    const installationPointIdsFiltered = useSelector(
        getFloorPlansInstallationPointIdsFilteredByLayers,
    );

    if (installationPointIdsFiltered.length === 0) return null;

    return (
        <>
            {installationPointIdsFiltered.map((id) => (
                <InstallationPointMapItem key={id} installationPointId={id} />
            ))}
        </>
    );
};

InstallationPoints.displayName = 'InstallationPoints';
