import { createSelector } from 'reselect';
import { getCurrentProject } from '../project';

const ILLUMINATOR_DEFAULT_VALUE = 2;

/**
 * Returns selected illuminator time in hours
 */
export const getSelectedPowerIlluminatorTime = createSelector(
    [getCurrentProject],
    (projectState) => projectState?.powerIlluminatorTimeInHours ?? ILLUMINATOR_DEFAULT_VALUE,
);
