import { injectable } from 'inversify';
import type {
    ProjectZipType,
    Id,
    IProjectNetworkSettings,
    BandwidthVersion,
} from 'app/core/persistence';
import { ProjectService, TimestampProviderService } from 'app/core/persistence';
import type {
    IExportProjectSettingsResponse,
    IExportedItems,
    IExportedProjectSettingsFile,
} from '../models';
import { ProjectFrequencyService } from 'app/modules/common';
import {
    CamerasExporterService,
    EncodersExporterService,
    MainUnitsExporterService,
    ScheduleExporterService,
    SpeakersExporterService,
    ZonesExporterService,
    RadarsExporterService,
    PacsExporterService,
    DoorStationsExporterService,
    DecoderExporterService,
    MapsExporterService,
    PeopleCountersExporterService,
    AlertersExporterService,
} from './exporters';
import { splitEntitiesByQuantity } from './splitByQuantity';
import { ProjectSettingsCommunicator } from './ProjectSettings.communicator';
import { toaster } from 'app/toaster';
import saveAs from 'file-saver';
import { t } from 'app/translate';

@injectable()
export class ProjectSettingsExporterService {
    constructor(
        private projectService: ProjectService,
        private scheduleExporterService: ScheduleExporterService,
        private projectFrequencyService: ProjectFrequencyService,
        private cameraExporterService: CamerasExporterService,
        private encodersExporterService: EncodersExporterService,
        private mainUnitsExporterService: MainUnitsExporterService,
        private speakersExporterService: SpeakersExporterService,
        private zonesExporterService: ZonesExporterService,
        private timestampProviderService: TimestampProviderService,
        private radarsExporterService: RadarsExporterService,
        private pacsExporterService: PacsExporterService,
        private doorStationsExporterService: DoorStationsExporterService,
        private decoderExporterService: DecoderExporterService,
        private mapsExporterService: MapsExporterService,
        private peopleCountersExporterService: PeopleCountersExporterService,
        private projectSettingsCommunicator: ProjectSettingsCommunicator,
        private alertersExporterService: AlertersExporterService,
    ) {}

    public async generateProjectSettingsFile(
        projectId: Id,
        embedImages = false,
    ): Promise<IExportedProjectSettingsFile> {
        const project = await this.projectService.get(projectId);
        const projectZipSetting = project.projectZipSetting;
        const projectNetworkSettings = project.networkSettings;
        const projectBandwidthVersion = project.bandwidthVersion;

        return {
            fileVersion: '1.2',
            fileType: 'projectSettings',
            settings: {
                id: project._id,
                revision: project._rev,
                name: project.name,
                notes: project.notes,
                schedules: await this.scheduleExporterService.getExportedSchedules(projectId),
                items: await this.getExportedProjectItems(
                    projectId,
                    projectZipSetting,
                    projectBandwidthVersion,
                    projectNetworkSettings,
                ),
                zones: await this.zonesExporterService.getExportedZones(projectId),
                exportDate: this.timestampProviderService.getIsoTimestamp(),
                maps: await this.mapsExporterService.getExportedMaps(projectId, embedImages),
            },
        };
    }
    public async createAndSaveSettingsFile(projectId: Id): Promise<void> {
        const progressToast = toaster.progress(t.creatingSettingsFile);
        const projectSettings = await this.generateProjectSettingsFile(projectId, true);

        const fileBlob = new Blob([JSON.stringify(projectSettings)], {
            type: 'text/plain;charset=utf-8',
        });

        const fileName = `${projectSettings.settings.name}.asds`;
        saveAs(fileBlob, fileName);
        progressToast.setSuccess(t.documentationSuccessfullDownloadTitle, fileName);
    }

    public async uploadSettingsFile(projectId: Id, oldToken: string | null): Promise<void> {
        const projectSettings = await this.generateProjectSettingsFile(projectId);

        const response =
            await this.projectSettingsCommunicator.uploadProjectSettings(projectSettings);

        if (!response || !response.ok) {
            this.handleNetworkError(response);
            return;
        }

        const responseJson: IExportProjectSettingsResponse = await response.json();
        await this.projectService.update(projectId, {
            shareToken: {
                token: responseJson.token,
                generatedAt: this.timestampProviderService.getIsoTimestamp(),
                validUntil: responseJson.validUntil,
            },
        });

        // Remove old token
        if (oldToken) {
            await this.revokeToken(oldToken);
        }
    }

    public async revokeTokenAndUpdateProject(projectId: Id, token: string): Promise<void> {
        const tokenRevokedSuccessfully = await this.revokeToken(token);
        if (tokenRevokedSuccessfully) {
            await this.projectService.update(projectId, { shareToken: undefined });
        }
    }

    private async revokeToken(token: string): Promise<boolean> {
        const response = await this.projectSettingsCommunicator.revokeToken(token);

        if (!response || !response.ok) {
            this.handleNetworkError(response);
            return false;
        }
        return true;
    }

    private handleNetworkError(response: Response | null): void {
        let errorMessage = t.projectShareErrorUnknownNetworkError;

        if (response) {
            switch (response.status) {
                case 401:
                    errorMessage = t.projectShareErrorUnauthorized;
                    break;
                case 403:
                    errorMessage = t.projectShareErrorForbidden;
                    break;
                case 413:
                    errorMessage = t.projectShareErrorPayloadTooLarge;
                    break;
            }
        }

        toaster.error(t.projectExportErrorHeader, errorMessage);
    }

    private async getProjectRetentionTimeInDays(projectId: Id): Promise<number> {
        return (await this.projectService.get(projectId)).recordingRetentionTimeInDays;
    }

    private async getExportedProjectItems(
        projectId: Id,
        projectZipSetting: ProjectZipType,
        projectBandwidthVersion: BandwidthVersion,
        projectNetworkSettings?: IProjectNetworkSettings,
    ): Promise<IExportedItems> {
        const frequency = await this.projectFrequencyService.getFrequency(projectId);
        const baseChildEntities = await this.projectService.getChildItems(projectId);
        const childEntities = splitEntitiesByQuantity(baseChildEntities, projectNetworkSettings);
        const projectRetentionTimeInDays = await this.getProjectRetentionTimeInDays(projectId);

        return {
            cameras: await this.cameraExporterService.mapItemsToExportedAxisCameras(
                projectZipSetting,
                childEntities,
                frequency,
                projectBandwidthVersion,
                projectRetentionTimeInDays,
            ),
            encoders: await this.encodersExporterService.mapItemsToExportedEncoders(
                projectZipSetting,
                projectId,
                childEntities,
                frequency,
                projectBandwidthVersion,
                projectRetentionTimeInDays,
            ),
            mainUnits: await this.mainUnitsExporterService.mapItemsToExportedMainUnits(
                projectZipSetting,
                projectId,
                childEntities,
                frequency,
                projectBandwidthVersion,
                projectRetentionTimeInDays,
            ),
            speakers: this.speakersExporterService.mapItemsToExportedSpeakers(childEntities),
            doorStations: await this.doorStationsExporterService.mapItemsToExportedDoorStations(
                projectZipSetting,
                childEntities,
                frequency,
                projectBandwidthVersion,
                projectRetentionTimeInDays,
            ),
            physicalAccessControllers:
                this.pacsExporterService.mapItemsToExportedPacs(childEntities),
            radars: this.radarsExporterService.mapItemsToExportedRadars(childEntities),
            decoders: this.decoderExporterService.mapItemsToExportedDecoders(childEntities),
            peopleCounters:
                this.peopleCountersExporterService.mapItemsToExportedPeopleCounters(childEntities),
            alerters: this.alertersExporterService.mapItemsToExportedAlerters(childEntities),
        };
    }
}
