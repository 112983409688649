import { Vector3 } from 'three';

interface IEdgeVectors {
    topVectors: Vector3[];
    bottomVectors: Vector3[];
    leftVectors: Vector3[];
    rightVectors: Vector3[];
}

const Z_AXIS = new Vector3(0, 0, 1);

export function calculateCornerVectors(horizontalFov: number, verticalFov: number) {
    const cosVer2 = Math.cos(verticalFov / 2);
    const cosHor2 = Math.cos(horizontalFov / 2);
    const sinVer2 = Math.sin(verticalFov / 2);
    const sinHor2 = Math.sin(horizontalFov / 2);

    return {
        up: new Vector3(0, 1, 0),
        down: new Vector3(0, -1, 0),
        center: new Vector3(1, 0, 0),
        topCenter: new Vector3(cosVer2 * cosHor2, sinVer2 * cosHor2, 0).normalize(),
        bottomCenter: new Vector3(cosVer2 * cosHor2, -sinVer2 * cosHor2, 0).normalize(),
        leftCenter: new Vector3(cosVer2 * cosHor2, 0, -sinHor2 * cosVer2).normalize(),
        rightCenter: new Vector3(cosVer2 * cosHor2, 0, sinHor2 * cosVer2).normalize(),
        topLeft: new Vector3(cosVer2 * cosHor2, sinVer2 * cosHor2, -sinHor2 * cosVer2).normalize(),
        topRight: new Vector3(cosVer2 * cosHor2, sinVer2 * cosHor2, sinHor2 * cosVer2).normalize(),
        bottomLeft: new Vector3(
            cosVer2 * cosHor2,
            -sinVer2 * cosHor2,
            -sinHor2 * cosVer2,
        ).normalize(),
        bottomRight: new Vector3(
            cosVer2 * cosHor2,
            -sinVer2 * cosHor2,
            sinHor2 * cosVer2,
        ).normalize(),
    };
}

// calculates the actual corner vectors. Usually we pretend that cameras have a
// rectangular field of view but for wide angles we have to consider the distortion
export function calculateActualCornerVectors(horizontalFov: number, verticalFov: number) {
    const zAxis = new Vector3(0, 0, 1);
    const yAxis = new Vector3(0, 1, 0);

    const vFov2 = verticalFov / 2;
    const hFov2 = horizontalFov / 2;

    const center = new Vector3(1, 0, 0);
    const topCenter = center.clone().applyAxisAngle(zAxis, vFov2);
    const bottomCenter = center.clone().applyAxisAngle(zAxis, -vFov2);
    const topLeft = topCenter.clone().applyAxisAngle(yAxis, hFov2);
    const topRight = topCenter.clone().applyAxisAngle(yAxis, -hFov2);
    const bottomLeft = bottomCenter.clone().applyAxisAngle(yAxis, hFov2);
    const bottomRight = bottomCenter.clone().applyAxisAngle(yAxis, -hFov2);

    return {
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
    };
}

export function tilt(vectors: Vector3[], angle: number) {
    return vectors.map((vector) => vector.applyAxisAngle(Z_AXIS, -angle));
}

export function calculateActualEdgeVectors(
    horizontalFov: number,
    verticalFov: number,
    tiltAngle: number,
    resolution = 16,
): IEdgeVectors {
    const xAxis = new Vector3(1, 0, 0);
    const yAxis = new Vector3(0, 1, 0);
    const zAxis = new Vector3(0, 0, 1);

    const vFov2 = verticalFov / 2;
    const hFov2 = horizontalFov / 2;

    const center = new Vector3(1, 0, 0);
    const topCenter = center.clone().applyAxisAngle(zAxis, vFov2);
    const bottomCenter = center.clone().applyAxisAngle(zAxis, -vFov2);
    const topLeft = topCenter.clone().applyAxisAngle(yAxis, hFov2);
    const topRight = topCenter.clone().applyAxisAngle(yAxis, -hFov2);
    const bottomLeft = bottomCenter.clone().applyAxisAngle(yAxis, hFov2);
    const bottomRight = bottomCenter.clone().applyAxisAngle(yAxis, -hFov2);

    const topVectors = [];
    const bottomVectors = [];
    const rightVectors = [];
    const leftVectors = [];

    const step = (i: number) => {
        // put the highest resolution where we need it most (in the center)
        // Third degree polynomial with approximately this form
        //
        // 1 -|                /
        //    |               /
        //    |           _.-'
        //    |     __---'
        //    |  ,-'
        //    | /
        //    |/
        // 0 -+------------------
        //    '                 '
        //    0                 1

        // return Math.pow(2 * (i / resolution - 0.5), 3) / 2 + 0.5;
        return i / resolution;
    };

    for (let i = 0; i <= resolution; i++) {
        const topVector = topLeft
            .clone()
            .applyAxisAngle(yAxis, -horizontalFov * step(i))
            .applyAxisAngle(zAxis, -tiltAngle);
        const bottomVector = bottomRight
            .clone()
            .applyAxisAngle(yAxis, horizontalFov * step(i))
            .applyAxisAngle(zAxis, -tiltAngle);
        topVectors.push(topVector);
        bottomVectors.push(bottomVector);
    }

    const vAngle = topLeft.angleTo(bottomLeft);

    for (let i = 1; i < resolution; i++) {
        const leftVector = bottomLeft
            .clone()
            .applyAxisAngle(xAxis, vAngle * step(i))
            .applyAxisAngle(zAxis, -tiltAngle)
            .normalize();
        const rightVector = topRight
            .clone()
            .applyAxisAngle(xAxis, vAngle * step(i))
            .applyAxisAngle(zAxis, -tiltAngle)
            .normalize();
        leftVectors.push(leftVector);
        rightVectors.push(rightVector);
    }

    return {
        topVectors,
        rightVectors,
        bottomVectors,
        leftVectors,
    };
}

export function calculateSquareEdgeVectors(
    horizontalFov: number,
    verticalFov: number,
    tiltAngle: number,
    resolution = 16,
): IEdgeVectors {
    const zAxis = new Vector3(0, 0, 1);
    const yAxis = new Vector3(0, 1, 0);

    const vFov2 = verticalFov / 2;
    const hFov2 = horizontalFov / 2;

    const center = new Vector3(1, 0, 0);
    const topCenter = center.clone().applyAxisAngle(zAxis, vFov2);
    const bottomCenter = center.clone().applyAxisAngle(zAxis, -vFov2);
    const leftCenter = center.clone().applyAxisAngle(yAxis, hFov2);
    const rightCenter = center.clone().applyAxisAngle(yAxis, -hFov2);

    const topRotationAxis = topCenter
        .clone()
        .applyAxisAngle(zAxis, Math.PI / 2)
        .normalize();

    const bottomRotationAxis = bottomCenter
        .clone()
        .applyAxisAngle(zAxis, -Math.PI / 2)
        .normalize();

    const leftRotationAxis = leftCenter
        .clone()
        .applyAxisAngle(yAxis, Math.PI / 2)
        .normalize();

    const rightRotationAxis = rightCenter
        .clone()
        .applyAxisAngle(yAxis, -Math.PI / 2)
        .normalize();

    const topLeft = new Vector3().crossVectors(leftRotationAxis, topRotationAxis);
    const topRight = new Vector3().crossVectors(topRotationAxis, rightRotationAxis);
    const bottomRight = new Vector3().crossVectors(rightRotationAxis, bottomRotationAxis);
    const bottomLeft = new Vector3().crossVectors(bottomRotationAxis, leftRotationAxis);

    const hAngle = topLeft.angleTo(topRight);
    const vAngle = topLeft.angleTo(bottomLeft);

    const topVectors = [];
    const bottomVectors = [];
    const leftVectors = [];
    const rightVectors = [];

    const step = (i: number) => {
        return i / resolution;
    };

    for (let i = 0; i <= resolution; i++) {
        const topVector = topRight
            .clone()
            .applyAxisAngle(topRotationAxis, hAngle * step(i))
            .applyAxisAngle(zAxis, -tiltAngle)
            .normalize();
        const bottomVector = bottomRight
            .clone()
            .applyAxisAngle(bottomRotationAxis, -hAngle * step(i))
            .applyAxisAngle(zAxis, -tiltAngle)
            .normalize();
        topVectors.unshift(topVector);
        bottomVectors.push(bottomVector);
    }

    for (let i = 1; i < resolution; i++) {
        const leftVector = topLeft
            .clone()
            .applyAxisAngle(leftRotationAxis, vAngle * step(i))
            .applyAxisAngle(zAxis, -tiltAngle)
            .normalize();
        const rightVector = topRight
            .clone()
            .applyAxisAngle(rightRotationAxis, -vAngle * step(i))
            .applyAxisAngle(zAxis, -tiltAngle)
            .normalize();
        leftVectors.unshift(leftVector);
        rightVectors.push(rightVector);
    }

    return {
        topVectors,
        rightVectors,
        bottomVectors,
        leftVectors,
    };
}

export function calculateRadialVectors(
    tiltAngle: number,
    horizontalFov: number,
    resolution = 16,
): Vector3[] {
    const yAxis = new Vector3(0, 1, 0);
    const zAxis = new Vector3(0, 0, 1);

    const center = new Vector3(1, 0, 0).applyAxisAngle(zAxis, -tiltAngle);

    const vectors = [];

    const step = (i: number) => {
        return i / resolution;
    };

    for (let i = 0; i <= resolution; i++) {
        const vector = center
            .clone()
            .applyAxisAngle(yAxis, -horizontalFov / 2 + horizontalFov * step(i))
            .normalize();
        vectors.push(vector);
    }

    return vectors;
}
