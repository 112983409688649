import type { LanguageCode } from 'app/utils';
import { languageCodeToTextLanguage } from 'app/utils';
import i18next from 'i18next';

export const useLanguage = () => {
    const languageCode = i18next.language as LanguageCode;
    const language = languageCodeToTextLanguage[languageCode];
    return language;
};

export const useLanguageCode = () => {
    const languageCode = i18next.language as LanguageCode;
    return languageCode;
};
