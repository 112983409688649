import { t } from 'app/translate';
import * as React from 'react';
import { Text, Box, Card } from 'app/components';
import { useSelector } from 'react-redux';
import { getCurrentProjectUnitSystem } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import type { UnitSystem } from 'app/core/persistence';

import type { ISpeakerFilter } from '../../../models';

const ceilingAreaImg = require('src/assets/images/speakers/ceiling-area.png');
const ceilingMultiHeightImg = require('src/assets/images/speakers/ceiling-multi-height.png');
const wallLengthNarrowImperialImg = require('src/assets/images/speakers/wall-length-narrow-imperial.png');
const wallLengthNarrowMetricImg = require('src/assets/images/speakers/wall-length-narrow-metric.png');
const wallLengthImg = require('src/assets/images/speakers/wall-length.png');

export const SpeakerDescriptionContainer: React.FC = () => {
    const speakerFilter = useSelector<IStoreState, ISpeakerFilter>(
        (store) => store.deviceSelector.speakerFilter,
    );
    const { placement } = speakerFilter;

    const unitSystem = useSelector<IStoreState, UnitSystem>((store) =>
        getCurrentProjectUnitSystem(store),
    );

    const isCeilingActive = placement === 'ceiling';
    const isWallActive = placement === 'wall';

    return (
        <Box>
            {/* descriptions */}
            {isCeilingActive && (
                <Card hideOverflow>
                    <Box direction="column" padding="base" width="100%">
                        <Box alignItems="center">
                            <img src={ceilingAreaImg} />
                            <Text>{t.speakerSelectorHowToDescriptionsCalculateArea}</Text>
                        </Box>
                        <Box alignItems="center">
                            <img src={ceilingMultiHeightImg} />
                            <Text>{t.speakerSelectorHowToDescriptionsDifferentHeights}</Text>
                        </Box>
                    </Box>
                </Card>
            )}
            {isWallActive && (
                <Card hideOverflow>
                    <Box direction="column" padding="base" width="100%">
                        <Box alignItems="center">
                            <img src={wallLengthImg} />
                            <Text>{t.speakerSelectorHowToDescriptionsCalculateLength}</Text>
                        </Box>
                        {unitSystem === 'imperial' && (
                            <Box alignItems="center">
                                <img src={wallLengthNarrowImperialImg} />
                                <Text>{t.speakerSelectorHowToDescriptionsNarrowRoomImperial}</Text>
                            </Box>
                        )}
                        {unitSystem === 'metric' && (
                            <Box alignItems="center">
                                <img src={wallLengthNarrowMetricImg} />
                                <Text>{t.speakerSelectorHowToDescriptionsNarrowRoomMetric}</Text>
                            </Box>
                        )}
                    </Box>
                </Card>
            )}
        </Box>
    );
};
