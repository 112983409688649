import { Border, Box, Text } from 'app/components';
import type { DeviceAndSubType, Id } from 'app/core/persistence';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    getCurrentProjectLocked,
    getDeviceAndSubTypeForItem,
    getModelName,
} from 'app/modules/common';
import { t } from 'app/translate';
import { DeviceChanger } from '../../deviceChanger';

interface IModelCol {
    itemId: Id;
}

export const ModelCol: React.FC<IModelCol> = ({ itemId }) => {
    const locked = useSelector<IStoreState, boolean>((state) => getCurrentProjectLocked(state));
    const model = useSelector<IStoreState, string>((state) => getModelName(state, itemId));
    const deviceType = useSelector<IStoreState, DeviceAndSubType | undefined>((state) =>
        getDeviceAndSubTypeForItem(state, itemId),
    );

    return (
        <Border topWidth={1} color="transparent">
            <Box direction="column" height="100%" justifyContent={'center'}>
                <Box spacing="half" alignItems="start">
                    <Text testId={`td_modelName_${model}`}>{model}</Text>
                    {!locked && <DeviceChanger itemId={itemId} />}
                </Box>
                <Text testId={`td_deviceType_${model}`} color="grey6">
                    {deviceType ? t.devicesGROUP[deviceType] : ''}
                </Text>
            </Box>
        </Border>
    );
};

ModelCol.displayName = 'ModelCol';
