import { Box, Text, Button } from 'app/components';
import { t } from 'app/translate';
import * as React from 'react';

interface IGenetecErrorInfoProps {
    onReload(): void;
}

export const GenetecErrorInfo: React.FC<IGenetecErrorInfoProps> = ({ onReload }) => {
    return (
        <Box width="100%" justifyContent="center" height="100%">
            <Box
                direction="column"
                spacing="base"
                width="80%"
                justifyContent="center"
                alignItems="center"
            >
                <Text color="grey8">{t.genetecGROUP.communicationErrorMessage}</Text>
                <Button primary onClick={onReload}>
                    {t.reload}
                </Button>
            </Box>
        </Box>
    );
};

GenetecErrorInfo.displayName = 'GenetecErrorInfo';
