import type { IBaseEntity, Id, IPersistence } from 'app/core/persistence';
import type { IAction } from 'app/store';

export function deleteEntityInRecord<T extends IBaseEntity>(
    items: Record<Id, IPersistence<T> | undefined>,
    action: IAction<string>,
) {
    const updatedRecord: Record<Id, IPersistence<T> | undefined> = {};

    Object.entries(items)
        .filter(([key, _data]) => key !== action.payload)
        .forEach(([key, entity]) => {
            updatedRecord[key] = entity;
        });

    return updatedRecord;
}

export function deleteEntityInAllDefinedRecord<T extends IBaseEntity>(
    items: Record<Id, IPersistence<T>>,
    action: IAction<string>,
) {
    const updatedRecord: Record<Id, IPersistence<T>> = {};

    Object.entries(items)
        .filter(([key, _data]) => key !== action.payload)
        .forEach(([key, entity]) => {
            updatedRecord[key] = entity;
        });

    return updatedRecord;
}
