import * as React from 'react';
import { t } from 'app/translate';
import { Box, Icon, Heading, Text, Stack, Button } from 'app/components';

interface IProjectError {
    heading: string;
    message: string;
}

export class ProjectError extends React.Component<IProjectError> {
    public render() {
        return (
            <Box justifyContent="center" padding="base">
                <Stack vertical spacing="half" alignItems="center">
                    <Stack spacing="half" alignItems="center">
                        <Icon icon="error" color="red" />
                        <Heading style="subheader">{this.props.heading}</Heading>
                    </Stack>

                    <Text>{this.props.message}</Text>
                    <Box padding="base">
                        <Button primary onClick={() => window.open('/', '_self')}>
                            {t.projectBackToProjects}
                        </Button>
                    </Box>
                </Stack>
            </Box>
        );
    }
}
