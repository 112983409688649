import { createSelector } from 'reselect';
import { isDefined } from 'axis-webtools-util';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaSystemComponent } from 'app/core/pia';
import { getCurrentProjectItemRelationsArray, getCurrentProjectItems } from '../../project';
import { getPiaItemsRecord } from '../../piaDevices';
interface IBandwidthStorage {
    bandwidth: number;
    storage: number;
}

const isSystemComponent = (item: IItemEntity) =>
    !!item.properties.systemComponent || !!item.properties.partnerSystemComponent;

const getRecorderStorage = (
    recorder: IPersistence<IItemEntity>,
    piaItem?: IPiaSystemComponent,
): number => {
    if (piaItem) {
        return piaItem.properties.maxRecordingStorageMegaBytes ?? 0;
    }
    if (recorder.properties.partnerSystemComponent) {
        return recorder.properties.partnerSystemComponent.maxRecordingStorageMegaBytes ?? 0;
    }
    return 0;
};

const getRecorderBandwidth = (
    recorder: IPersistence<IItemEntity>,
    piaItem?: IPiaSystemComponent,
): number => {
    if (piaItem) {
        return piaItem.properties.maxRecordingBandwidthBits ?? 0;
    }
    if (recorder.properties.partnerSystemComponent) {
        return recorder.properties.partnerSystemComponent.maxRecordingBandwidthBits ?? 0;
    }
    return 0;
};

/**
 * Selector for getting the total available storage in currentProject
 */
export const getBandwidthStorageTotal = createSelector(
    [getCurrentProjectItems, getCurrentProjectItemRelationsArray, getPiaItemsRecord],
    (items, relations, piaItemsRecord): IBandwidthStorage => {
        // get accessories of an item by id
        const getAccessories = (id: Id) =>
            relations.filter(
                ({ parentId, relationType }) => relationType === 'accessory' && parentId === id,
            );
        // get project item by id
        const getItem = (id: Id) => {
            return items[id];
        };
        // get the total additional storage of a recording solution
        const getAccessoryStorage = (recorderId: Id): number => {
            const accessoriesRelations = getAccessories(recorderId);
            return accessoriesRelations.reduce((acc, accessoriesRelation) => {
                const accessory = getItem(accessoriesRelation.childId);
                if (!accessory?.productId) {
                    return acc;
                }
                const piaAccessory = piaItemsRecord[accessory.productId] as IPiaSystemComponent;
                const accessoryStorage = piaAccessory.properties.maxRecordingStorageMegaBytes ?? 0;
                return acc + accessoryStorage * accessory.quantity;
            }, 0);
        };

        const systemComponents = Object.values(items).filter(isDefined).filter(isSystemComponent);

        // calculate storage
        const storage = systemComponents.reduce((acc, recorder) => {
            const accessoryStorage = getAccessoryStorage(recorder._id);
            const piaItem = recorder.productId
                ? (piaItemsRecord[recorder.productId] as IPiaSystemComponent)
                : undefined;
            const recorderStorage = getRecorderStorage(recorder, piaItem);

            return acc + (recorderStorage + accessoryStorage) * recorder.quantity;
        }, 0);

        // calculate bandwidth
        const bandwidth = systemComponents.reduce((acc, recorder) => {
            const piaItem = recorder.productId
                ? (piaItemsRecord[recorder.productId] as IPiaSystemComponent)
                : undefined;
            return acc + getRecorderBandwidth(recorder, piaItem) * recorder.quantity;
        }, 0);

        return {
            storage,
            bandwidth,
        };
    },
);
