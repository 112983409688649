import { injectable } from 'inversify';
import type { IBaseEntity, Id, IEntity } from '../../entities';
import { v4 as uuidv4 } from 'uuid';
import { EntitySettings } from './EntitySettings';
import { TimestampProviderService } from './TimestampProvider.service';

type DateIsoString = string;

@injectable()
export class CreateEntityService {
    constructor(
        private entitySettings: EntitySettings,
        private timestampProvider: TimestampProviderService,
    ) {}

    public create(prefix: string, baseEntity: IBaseEntity, timestamp?: DateIsoString): IEntity {
        const id = this.generateDatabaseId(prefix);
        return this.createWithId(id, baseEntity, timestamp);
    }

    public createWithId(id: Id, baseEntity: IBaseEntity, timestamp?: DateIsoString): IEntity {
        const isoTimestamp =
            !!timestamp && this.timestampProvider.isISODateString(timestamp)
                ? timestamp
                : this.timestampProvider.getIsoTimestamp();
        const newEntity: IEntity = {
            ...baseEntity,
            _id: id,
            _rev: '',
            creationDate: isoTimestamp,
            updatedDate: isoTimestamp,
            entityVersion: this.entitySettings.version,
            path: baseEntity.path ? baseEntity.path : [],
        };

        // Adds itself last in path
        newEntity.path.push(newEntity._id);
        return newEntity;
    }

    public generateDatabaseId(prefix: string): Id {
        return prefix + this.entitySettings.databaseDelimiter + uuidv4();
    }
}
