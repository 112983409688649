import * as React from 'react';
import { t } from 'app/translate';
import { Stack, ToggleButton, Text, NumberInput, Box } from 'app/components';
import type { DistanceUnit } from 'app/core/persistence';
import { distanceUnitShortText } from 'app/core/persistence';
import type { IPixelDensity } from 'app/modules/common/pixelDensity';
import { PixelDensities, getPixelDensityValue } from 'app/modules/common/pixelDensity';
import type { Colors } from 'app/styles';
import { ColorsEnum, colorToRgba } from 'app/styles';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { css } from '@emotion/css';
import { convertDensityToMeters, convertDensityToDisplayUnit } from 'app/core/common';
import { getIsDoriPixelsOn } from '../../../../selectors/mapUiState';

interface IPixelDensityProps {
    pixelDensity: number;
    displayUnit: DistanceUnit.Meter | DistanceUnit.Feet;
    onPixelDensityChange(value: number): void;
    color: Colors;
}

const doriPixelStyle = (opacity: number, color: Colors) => css`
    background-color: ${getPixelDensityColor(opacity, color)};
    width: 100px;
    max-width: 100%;
    height: 7px;
    margin: 3px 5px 0px 5px;
`;

export const PixelDensityComponent: React.FunctionComponent<IPixelDensityProps> = ({
    pixelDensity,
    displayUnit,
    onPixelDensityChange,
    color,
}) => {
    const doriPixelsOn = useSelector<IStoreState, boolean>(getIsDoriPixelsOn);

    const onValueChange = React.useCallback(
        (value: number) => {
            onPixelDensityChange(convertDensityToMeters(value, displayUnit));
        },
        [onPixelDensityChange, displayUnit],
    );

    const onToggleClick = React.useCallback(
        (resolution: IPixelDensity) => {
            onValueChange(getPixelDensityValue(resolution, displayUnit));
        },
        [onValueChange, displayUnit],
    );

    const isPressed = React.useCallback(
        (resolution: IPixelDensity) =>
            convertDensityToDisplayUnit(pixelDensity, displayUnit) ===
            getPixelDensityValue(resolution, displayUnit),
        [pixelDensity, displayUnit],
    );

    return (
        <Stack vertical spacing="half">
            <Text semiBold align="center" color="grey7">
                {t.requiredPixelDensity}
            </Text>
            <Box display="block" height="60px">
                <Stack spacing="quart" justifyContent="center">
                    {PixelDensities.map((resolution) => {
                        return (
                            <ToggleButton
                                testId={`pixel_density_${resolution.meterValue}_tgl`}
                                key={resolution.meterValue}
                                onChange={() => onToggleClick(resolution)}
                                type="framed"
                                pressed={isPressed(resolution)}
                            >
                                <img src={resolution.image} style={{ maxWidth: '100%' }} />
                            </ToggleButton>
                        );
                    })}
                </Stack>
                {doriPixelsOn && (
                    <Stack spacing="quart" justifyContent="center">
                        {PixelDensities.map((resolution) => {
                            return (
                                <div
                                    key={resolution.meterValue}
                                    className={doriPixelStyle(resolution.opacity, color)}
                                ></div>
                            );
                        })}
                    </Stack>
                )}
            </Box>
            <Stack justifyContent="between" alignItems="center">
                <Stack spacing="quart">
                    <Text semiBold color="grey7">{`${convertDensityToDisplayUnit(
                        pixelDensity,
                        displayUnit,
                    )} ${t.abbreviationsGROUP.pixel}/${distanceUnitShortText(displayUnit)}`}</Text>
                    <Text color="grey7">-</Text>
                    <Text color="grey7">{`${getPixelDensityDescription(pixelDensity)}`}</Text>
                </Stack>
                <NumberInput
                    min={1}
                    max={2000}
                    value={convertDensityToDisplayUnit(pixelDensity, displayUnit)}
                    changeCriteria="debounced"
                    onChange={onValueChange}
                />
            </Stack>
        </Stack>
    );
};

const getPixelDensityDescription = (pixelDensity: number): string => {
    if (pixelDensity < PixelDensities[1].meterValue) {
        return t.cameraSelectorFieldOfViewPixelOptionsDetect;
    }
    if (pixelDensity < PixelDensities[2].meterValue) {
        return t.cameraSelectorFieldOfViewPixelOptionsObserve;
    }
    if (pixelDensity < PixelDensities[3].meterValue) {
        return t.cameraSelectorFieldOfViewPixelOptionsRecognize;
    }

    return t.cameraSelectorFieldOfViewPixelOptionsIdentify;
};

const getPixelDensityColor = (opacity: number, color: Colors): string => {
    return colorToRgba(ColorsEnum[color], opacity);
};

PixelDensityComponent.displayName = 'PixelDensityComponent';
