import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon, Clickable, Stack, Box, Title } from 'app/components';
import {
    CommonActionService,
    hasUndoCommand,
    hasRedoCommand,
    canUndoNavigation,
    isUndoRedoDisabled,
    getPartnerConfigHeaderStyle,
    clientIsMacOs,
} from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import { css } from '@emotion/css';

const UndoRedoStyle = css`
    [dir='rtl'] & {
        transform: rotateY(180deg);
    }
`;

export const UndoRedo: React.FC = () => {
    const [actionService] = useState(ServiceLocator.get(CommonActionService));
    useLocation(); // trigger a rerender on when location changes

    const undo = () => {
        actionService.undo();
    };

    const redo = () => {
        actionService.redo();
    };

    const canUndo = useSelector(hasUndoCommand) || canUndoNavigation();
    const canRedo = useSelector(hasRedoCommand) && !canUndoNavigation();
    const colorOverride = useSelector<IStoreState, string | undefined>(
        (store) => getPartnerConfigHeaderStyle(store)?.foregroundColor,
    );

    const isMacOs = clientIsMacOs();
    const undoShortcut = `${isMacOs ? '⌘' : 'Ctrl'}+Z`;
    const redoShortcut = `${isMacOs ? '⌘' : 'Ctrl'}+Y`;

    return isUndoRedoDisabled() ? null : (
        <Stack>
            <div className={UndoRedoStyle}>
                <Clickable onClick={undo} disabled={!canUndo}>
                    <Title title={undoShortcut}>
                        <Box>
                            <Icon
                                icon="undo"
                                color="yellowText"
                                colorOverride={colorOverride}
                                opaque={true}
                            />
                        </Box>
                    </Title>
                </Clickable>
            </div>
            <div className={UndoRedoStyle}>
                <Clickable onClick={redo} disabled={!canRedo}>
                    <Title title={redoShortcut}>
                        <Box>
                            <Icon
                                icon="redo"
                                color="yellowText"
                                colorOverride={colorOverride}
                                opaque={true}
                            />
                        </Box>
                    </Title>
                </Clickable>
            </div>
        </Stack>
    );
};

UndoRedo.displayName = 'UndoRedo';
