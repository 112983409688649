import { Box, Select } from 'app/components';
import { ProjectService } from 'app/core/persistence';
import { useService } from 'app/ioc';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    getGenetecTypeOptions,
    getGenetecProjectId,
    getGenetecRecommendationType,
} from '../../selectors';
import { DEFAULT_GENETEC_TYPE, GENETEC_CONFIGURED } from '../../types';

interface IGenetecSelectTypeProps {
    underline?: boolean;
}

export const GenetecSelectType: React.FC<IGenetecSelectTypeProps> = ({ underline }) => {
    const genetecOptions = useSelector(getGenetecTypeOptions);
    const genetecProjectId = useSelector(getGenetecProjectId);
    const selectedRecommendationType =
        useSelector(getGenetecRecommendationType) ?? DEFAULT_GENETEC_TYPE;

    const projectService = useService(ProjectService);

    // add an item to the dropdown that shows that we are using genetec configuration from the calculator
    const showGenetecConfiguredInDropDown = genetecProjectId !== undefined;
    const value = showGenetecConfiguredInDropDown ? GENETEC_CONFIGURED : selectedRecommendationType;

    const changeType = (newValue: number) => {
        projectService.updateCurrentProject({
            genetecRecommendationType: newValue,
        });
    };

    return (
        <Box height={40}>
            <Select
                testId="select_solution_type"
                testIdChild="genetec_select_type"
                fillDropdownWidth
                fillWidth
                fullHeight
                options={genetecOptions}
                onChange={changeType}
                value={value}
                disabled={showGenetecConfiguredInDropDown}
                underline={underline}
            />
        </Box>
    );
};

GenetecSelectType.displayName = 'GenetecSelectType';
