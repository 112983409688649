import { injectable } from 'inversify';
import { t } from 'app/translate';
import type {
    IBodyWornCameraProfile,
    IItem,
    IItemEntity,
    IItemPropertiesEntity,
    IPersistence,
} from 'app/core/persistence';
import {
    defaultDockingStationFilter,
    defaultCameraExtensionFilter,
    defaultBodyWornFilter,
    getBodyWornCameraDefaultProfile,
    CurrentProjectService,
    deviceTypeCheckers,
    ItemService,
} from 'app/core/persistence';

import type { PiaCategory, PiaId } from 'app/core/pia';
import { PiaItemWearablesCategory } from 'app/core/pia';
import type { IAddProductProps } from 'app/modules/common';
import { AccessoryService } from 'app/modules/common';
import type { IWearablesRecommendation } from './WearablesRecommendations.service';

@injectable()
export class WearablesService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private accessoryService: AccessoryService,
    ) {}

    public async useSolution(recommendedSolution: IWearablesRecommendation[]): Promise<void> {
        await this.removeAllWearables();
        const items = this.getItemsToAdd(recommendedSolution);
        await this.itemService.addItemsToCurrentProject(items);
    }

    public addOrUpdateDevice = async (
        piaItemId: PiaId,
        category: PiaCategory,
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
    ) => {
        if (itemToEdit) {
            return this.updateWearable(piaItemId, category, itemToEdit);
        } else if (newItemProps) {
            return this.addDevice(piaItemId, newItemProps, category);
        }
    };

    private async addDevice(
        productId: PiaId,
        newItemProps: IAddProductProps,
        category: PiaCategory,
    ) {
        const item: IItem = {
            productId,
            name: newItemProps.name,
            description: '',
            notes: newItemProps.notes || '',
            properties: this.toProperties(category),
            quantity: newItemProps.quantity,
            color: newItemProps.color,
        };

        return this.itemService.addToCurrentProject(item);
    }

    private async updateWearable(
        piaId: PiaId,
        category: PiaCategory,
        itemToUpdate: IPersistence<IItemEntity>,
    ) {
        const productIdIsUpdated = itemToUpdate && itemToUpdate.productId !== piaId;
        const hasIncompatibleChildren = this.accessoryService.getHasPiaItemsToRemove(
            itemToUpdate._id,
            piaId,
        );

        const accessoriesMustBeRemoved = productIdIsUpdated && hasIncompatibleChildren;
        const item = await this.itemService.getItem(itemToUpdate._id);
        if (accessoriesMustBeRemoved) {
            const confirm = await this.accessoryService.getConfirmDialogue(itemToUpdate._id, piaId);

            // If the user clicked cancel we should not continue
            if (!confirm) {
                return;
            }
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                itemToUpdate._id,
                piaId,
            );
        }
        const profile = item?.properties.bodyWornCamera?.profile;
        const updatedProps = {
            productId: piaId,
            properties: this.toProperties(category, profile),
        };

        await this.itemService.updateItem(itemToUpdate._id, updatedProps);
    }

    private toProperties(
        category: PiaCategory,
        profile?: IBodyWornCameraProfile,
        itemToEdit?: IPersistence<IItemEntity>,
    ) {
        switch (category) {
            case PiaItemWearablesCategory.CAMERAEXTENSIONS:
                return {
                    cameraExtension: {
                        filter:
                            itemToEdit?.properties.cameraExtension !== undefined
                                ? itemToEdit.properties.cameraExtension.filter
                                : defaultCameraExtensionFilter,
                    },
                };
            case PiaItemWearablesCategory.CAMERAS:
                const wearableDefaultProfile = getBodyWornCameraDefaultProfile();
                const alwaysSchedule = this.currentProjectService.getCurrentProjectAlwaysSchedule();
                const alwaysScheduleId = alwaysSchedule?._id || wearableDefaultProfile.scheduleId;

                return {
                    bodyWornCamera: {
                        profile: {
                            sceneId: profile?.sceneId || wearableDefaultProfile.sceneId,
                            resolution: profile?.resolution || wearableDefaultProfile.resolution,
                            retentionTimeInDays:
                                profile?.retentionTimeInDays ||
                                wearableDefaultProfile.retentionTimeInDays,
                            scheduleId: profile?.scheduleId || alwaysScheduleId,
                            activeRecordingInPercent:
                                profile?.activeRecordingInPercent ||
                                wearableDefaultProfile.activeRecordingInPercent,
                        },
                        filter:
                            itemToEdit?.properties.bodyWornCamera !== undefined
                                ? itemToEdit.properties.bodyWornCamera.filter
                                : defaultBodyWornFilter,
                    },
                };
            case PiaItemWearablesCategory.CONTROLLER:
                return {
                    systemController: {},
                };
            case PiaItemWearablesCategory.DOCKING:
                return {
                    dockingStation: {
                        filter:
                            itemToEdit?.properties.dockingStation !== undefined
                                ? itemToEdit.properties.dockingStation.filter
                                : defaultDockingStationFilter,
                    },
                };
            default:
                throw new Error('Not a wearables category');
        }
    }

    private removeAllWearables(): Promise<string[]> {
        const wearableItems = this.currentProjectService
            .getAllEntitiesOfType('item')
            .filter(deviceTypeCheckers.isWearable);
        return this.itemService.deleteItems(wearableItems);
    }

    private getItemsToAdd(recommendedSolution: IWearablesRecommendation[]): IItem[] {
        return recommendedSolution.map(this.convertToIItem);
    }

    private convertToIItem(recommendedItem: IWearablesRecommendation): IItem {
        const wearableDefaultProfile = getBodyWornCameraDefaultProfile();
        const alwaysSchedule = this.currentProjectService.getCurrentProjectAlwaysSchedule();
        const alwaysScheduleId = alwaysSchedule?._id || wearableDefaultProfile.scheduleId;
        wearableDefaultProfile.scheduleId = alwaysScheduleId;
        const getItemProperty = (itemType: string): IItemPropertiesEntity => {
            switch (itemType) {
                case 'dockingStation':
                    return { dockingStation: { filter: defaultDockingStationFilter } };
                case 'systemController':
                    return { systemController: {} };
                case 'bodyWornCamera':
                    return {
                        bodyWornCamera: {
                            profile: wearableDefaultProfile,
                            filter: defaultBodyWornFilter,
                        },
                    };
                default:
                    throw new Error(`Unknown type: ${itemType}`);
            }
        };
        return {
            description: '',
            name: t.newDevice,
            notes: '',
            productId: recommendedItem.productId,
            properties: getItemProperty(recommendedItem.type),
            quantity: recommendedItem.count,
        };
    }
}
