import * as React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { SliderConstants } from './SliderConstants';
import { Segment } from './Segment';
import { cssGradient } from './cssGradients';
import { Time24 } from 'app/core/persistence';
import { ColorsEnum } from 'app/styles';

const grey1 = '#4d4d4d';
const grey2 = '#8a8a8a';
const grey8 = '#e1dfda';
const grey11 = '#eae9e3';
const grey14 = '#fafafa';
const colorBtn = '#646163';

const trackHeight = '10px';
const sliderPointerSize = '5px';
const sliderPointerPos = '7px';

const SliderTrackStyle = css`
    position: relative;

    width: 100%;
    height: ${trackHeight};
    margin: 6px 0;
    padding: 0;

    opacity: 1;
    border-radius: 0.5px;
    background-color: ${grey14};
    background-repeat: no-repeat;
`;

const SliderMarkerStyle = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    color: ${ColorsEnum.yellow};
    background-color: ${ColorsEnum.blue};
    border: 1px solid ${ColorsEnum.grey3};

    @media print {
        /* Makes sure marker is printed in Safari and Chrome
            In other browsers the marker (background-color) won't be visible when printed */
        -webkit-print-color-adjust: exact;
    }
`;

const SliderMarkerGreenStyle = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    color: ${ColorsEnum.green};
    background-color: ${ColorsEnum.green};
    border: 1px solid ${ColorsEnum.grey3};

    @media print {
        /* Makes sure marker is printed in Safari and Chrome
            In other browsers the marker (background-color) won't be visible when printed */
        -webkit-print-color-adjust: exact;
    }
`;

const SliderLabelsStyle = css`
    color: ${ColorsEnum.grey6};
    font-size: 11px;
`;

const SliderPointerStyle = css`
    position: absolute;
    top: -1px;
    min-width: 30px;
    height: 17px;
    margin-left: calc(-1 * (${sliderPointerSize} + ${sliderPointerPos}));
    padding: 0 2px;
    -webkit-transition: top 0.3s;
    transition: top 0.3s;
    color: ${colorBtn};
    border: 1px solid ${grey11};
    background-color: ${grey11};

    &::before {
        position: absolute;
        bottom: -5px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${grey11};
        border-right-color: ${ColorsEnum.transparent};
        border-bottom: none;
        border-left-color: ${ColorsEnum.transparent};
    }

    &::after {
        position: absolute;
        bottom: -4px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${grey11};
        border-right-color: ${ColorsEnum.transparent};
        border-bottom: none;
        border-left-color: ${ColorsEnum.transparent};
    }

    @media print {
        border-color: ${ColorsEnum.black};
        background-color: ${ColorsEnum.white} !important;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }
`;

const SliderPointerEditStyle = css`
    position: absolute;
    top: calc(-1 * ${trackHeight} * 2);
    min-width: 30px;
    height: 17px;
    margin-left: calc(-1 * (${sliderPointerSize} + ${sliderPointerPos}));
    transition: top 0.3s;
    padding: 0 2px;
    color: ${colorBtn};
    border: 1px solid ${grey11};
    background-color: ${grey11};

    &::before {
        position: absolute;
        bottom: -5px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${grey11};
        border-right-color: ${ColorsEnum.transparent};
        border-bottom: none;
        border-left-color: ${ColorsEnum.transparent};
    }

    &::after {
        position: absolute;
        bottom: -4px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${grey11};
        border-right-color: ${ColorsEnum.transparent};
        border-bottom: none;
        border-left-color: ${ColorsEnum.transparent};
    }

    @media print {
        border-color: ${ColorsEnum.black};
        background-color: ${ColorsEnum.white} !important;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }
`;

const SliderPointerGreenStyle = css`
    position: absolute;
    top: -1px;
    min-width: 30px;
    height: 17px;
    margin-left: calc(-1 * (${sliderPointerSize} + ${sliderPointerPos}));
    transition: top 0.3s;
    background-color: ${ColorsEnum.yellow2};
    border: 1px solid ${ColorsEnum.yellow2};
    border-radius: 4px;
    padding: 0 4px;
    color: ${ColorsEnum.yellowText};
    font-weight: 600;

    &::before {
        position: absolute;
        bottom: -5px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${ColorsEnum.yellow2};
        border-right-color: transparent;
        border-bottom: none;
        border-left-color: transparent;
    }

    &::after {
        position: absolute;
        bottom: -4px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${ColorsEnum.yellow2};
        border-right-color: transparent;
        border-bottom: none;
        border-left-color: transparent;
    }

    @media print {
        border-color: ${ColorsEnum.black};
        background-color: ${ColorsEnum.white} !important;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }
`;

const SliderPointerGreenEditStyle = css`
    position: absolute;
    top: calc(-1 * ${trackHeight} * 2);
    min-width: 30px;
    height: 17px;
    margin-left: calc(-1 * (${sliderPointerSize} + ${sliderPointerPos}));
    transition: top 0.3s;
    background-color: ${ColorsEnum.yellow2};
    border: 1px solid ${ColorsEnum.yellow2};
    border-radius: 4px;
    padding: 0 4px;
    color: ${ColorsEnum.yellowText};
    font-weight: 600;

    &::before {
        position: absolute;
        bottom: -5px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${ColorsEnum.yellow2};
        border-right-color: transparent;
        border-bottom: none;
        border-left-color: transparent;
    }

    &::after {
        position: absolute;
        bottom: -4px;
        left: ${sliderPointerPos};

        width: 0;
        height: 0;

        content: '';

        border: ${sliderPointerSize} solid ${ColorsEnum.yellow2};
        border-right-color: transparent;
        border-bottom: none;
        border-left-color: transparent;
    }

    @media print {
        border-color: ${ColorsEnum.black};
        background-color: ${ColorsEnum.white} !important;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }
`;

const SliderStyle = css`
    display: block;
    width: 100%;
`;

const ContainerStyle = css`
    position: relative;
`;

const ClearfixStyle = css`
    &::after {
        display: block;
        visibility: hidden;
        clear: both;

        height: 0;

        content: '.';
    }
`;

const PullLeftStyle = css`
    float: left;
`;

const PullRightStyle = css`
    float: right;
`;

const FixedLeft25Style = css`
    position: absolute;
    left: 25%;
`;

const FixedLeft50Style = css`
    position: absolute;
    left: 50%;
`;

const FixedLeft75Style = css`
    position: absolute;
    left: 75%;
`;

const CenterInBoxStyle = css`
    margin-left: -50%;
`;

type StateProp = 'startValue' | 'endValue';

export interface ITwentyFourHourSliderProps {
    /**
     * Sets a mode if slider is to be used for setting light or time
     */
    setLight: boolean;
    /**
     * What level of grey color should be used for track. Only relevant if start > min or end < max.
     * Accepts a value between 0 and 3. Defaults to 0.
     */
    darkLight?: number;
    /**
     * Time when light (daylight) begins.
     */
    lightStart: Time24;
    /**
     * Time when light (daylight) ends.
     */
    lightEnd: Time24;
    /**
     * Start time
     */
    start: Time24;
    /**
     * End time
     */
    end: Time24;
    /**
     * If slider is maneuverable or just read-only.
     */
    readOnly: boolean;
    /**
     * Boolean to make it appear with color green as in recording schedules.
     * Defaults to false.
     */
    green?: boolean;
    /**
     * Invoked with start and end time when releasing mouse.
     */
    onChange?(start: Time24, end: Time24): void;
}

export interface IColorStop {
    color: string;
    value: number;
}

interface ITwentyFourHourSliderState {
    startValue: Time24;
    endValue: Time24;
}

/**
 * Allow the user to select a time interval during a 24 hour window
 */
export class TwentyFourHourSlider extends React.Component<
    ITwentyFourHourSliderProps,
    ITwentyFourHourSliderState
> {
    private sliderEndPointer!: React.RefObject<HTMLDivElement>;
    private sliderStartPointer!: React.RefObject<HTMLDivElement>;
    private sliderEnd!: React.RefObject<HTMLDivElement>;
    private sliderStart!: React.RefObject<HTMLDivElement>;
    private sliderMarker!: React.RefObject<HTMLDivElement>;
    private track!: React.RefObject<HTMLDivElement>;
    private element!: React.RefObject<HTMLDivElement>;
    private sliderColor!: string;
    private sliderBackgroundColor!: string;
    private max: number = 0;
    private mouseInitX: number = 0;
    private sliderWidth: number = 0;
    private initialValue: number = 0;

    constructor(props: ITwentyFourHourSliderProps) {
        super(props);
        this.state = {
            startValue: this.props.start,
            endValue: this.props.end,
        };
        this.element = React.createRef();
        this.track = React.createRef();
        this.sliderMarker = React.createRef();
        this.sliderStart = React.createRef();
        this.sliderEnd = React.createRef();
        this.sliderStartPointer = React.createRef();
        this.sliderEndPointer = React.createRef();
    }

    public componentDidUpdate(prevProps: ITwentyFourHourSliderProps) {
        if (this.props.start.toNumber() !== prevProps.start.toNumber()) {
            this.setState({ startValue: this.props.start });
        }
        if (this.props.end.toNumber() !== prevProps.end.toNumber()) {
            this.setState({ endValue: this.props.end });
        }
    }

    public componentDidMount() {
        this.max = 24;

        if (this.sliderMarker.current) {
            const appliedStyles = window.getComputedStyle(this.sliderMarker.current);
            this.sliderColor = this.getSliderColor(appliedStyles);
            this.sliderBackgroundColor = this.getSliderBackgroundColor(appliedStyles);
        }

        if (this.sliderStart.current && this.sliderEnd.current) {
            this.sliderStart.current.addEventListener('mousedown', this.onStartMouseDown, false);
            this.sliderEnd.current.addEventListener('mousedown', this.onEndMouseDown, false);
            document.addEventListener('mouseup', this.onMouseUp, false);
        }

        this.render();
    }

    public render() {
        if (
            this.element.current &&
            this.sliderStart.current &&
            this.sliderStartPointer.current &&
            this.sliderEnd.current &&
            this.sliderEndPointer.current &&
            this.sliderMarker.current &&
            this.track.current
        ) {
            this.sliderWidth = this.element.current.getBoundingClientRect().width;
            this.sliderStart.current.style.left =
                this.getPercentVal(this.state.startValue.toNumber(), this.max) + '%';
            this.sliderStartPointer.current.style.left =
                this.getPercentVal(this.state.startValue.toNumber(), this.max) + '%';
            this.sliderStartPointer.current.textContent = this.state.startValue.toString();
            this.sliderEnd.current.style.left =
                this.getPercentVal(this.state.endValue.toNumber(), this.max) + '%';
            this.sliderEndPointer.current.style.left =
                this.getPercentVal(this.state.endValue.toNumber(), this.max) + '%';
            this.sliderEndPointer.current.textContent = this.state.endValue.toString();

            const background = this.getSliderOverlay(
                this.props.setLight
                    ? SliderConstants.MODE.SET_LIGHT
                    : SliderConstants.MODE.SET_TIME,
                this.state.startValue.toNumber(),
                this.state.endValue.toNumber(),
                this.props.lightStart.toNumber(),
                this.props.lightEnd.toNumber(),
                this.max,
                SliderConstants.COLOR.TRANSPARENT,
                this.sliderColor,
                this.sliderBackgroundColor,
            );

            // Important is set to override print css that would make marker transparent
            this.sliderMarker.current.style.setProperty('background', background, 'important');
            this.track.current.style.backgroundImage = this.getTrackBackground(
                this.props.setLight
                    ? this.state.startValue.toNumber()
                    : this.props.lightStart.toNumber(),
                this.props.setLight
                    ? this.state.endValue.toNumber()
                    : this.props.lightEnd.toNumber(),
                this.max,
            );
        }

        const PointerStyle =
            this.props.green && this.props.readOnly
                ? SliderPointerGreenStyle
                : this.props.green
                  ? SliderPointerGreenEditStyle
                  : this.props.readOnly
                    ? SliderPointerStyle
                    : SliderPointerEditStyle;

        return (
            <div className={classNames(SliderStyle)} ref={this.element}>
                <div className={classNames(SliderLabelsStyle, ContainerStyle, ClearfixStyle)}>
                    <div className={PullLeftStyle}>
                        <span>00:00</span>
                    </div>
                    <div className={FixedLeft25Style}>
                        <span className={classNames(CenterInBoxStyle)}>06:00</span>
                    </div>
                    <div className={FixedLeft50Style}>
                        <span className={classNames(CenterInBoxStyle)}>12:00</span>
                    </div>
                    <div className={FixedLeft75Style}>
                        <span className={classNames(CenterInBoxStyle)}>18:00</span>
                    </div>
                    <span className={classNames(PullRightStyle)}>24:00</span>
                    <div className={classNames(PointerStyle)} ref={this.sliderStartPointer} />
                    <div className={classNames(PointerStyle)} ref={this.sliderEndPointer} />
                </div>

                <div className={this.getTrackClassName(this.props.darkLight)} ref={this.track}>
                    <div
                        className={this.getSliderStartEndStyle(this.props.darkLight)}
                        ref={this.sliderStart}
                        onTouchStart={this.onTouchStart('startValue')}
                        onTouchMove={this.onTouchMove('startValue')}
                        onTouchEnd={this.onTouchEnd}
                    />
                    <div
                        className={this.props.green ? SliderMarkerGreenStyle : SliderMarkerStyle}
                        ref={this.sliderMarker}
                    />
                    <div
                        className={this.getSliderStartEndStyle(this.props.darkLight)}
                        ref={this.sliderEnd}
                        onTouchStart={this.onTouchStart('endValue')}
                        onTouchMove={this.onTouchMove('endValue')}
                        onTouchEnd={this.onTouchEnd}
                    />
                </div>
            </div>
        );
    }

    private getSliderTrackBackground(index: number) {
        switch (index) {
            case 0:
                return css`
                    background-color: ${ColorsEnum.white};
                `;
            case 1:
                return css`
                    background-color: ${grey1};
                `;
            case 2:
                return css`
                    background-color: ${grey2};
                `;
            case 3:
                return css`
                    background-color: ${grey8};
                `;
            default:
                return css`
                    background-color: ${ColorsEnum.white};
                `;
        }
    }

    private getSliderStartEndStyle(index: number = 0) {
        let color;

        if (this.props.green) {
            color = ColorsEnum.green;
        } else {
            switch (index) {
                case 0:
                    color = grey8;
                    break;
                case 1:
                    color = grey1;
                    break;
                case 2:
                    color = grey2;
                    break;
                case 3:
                    color = grey8;
                    break;
                default:
                    color = grey8;
                    break;
            }
        }

        if (this.props.readOnly) {
            return css`
                position: absolute;
                z-index: 1;
                width: calc(${trackHeight} * 3);
                height: calc(${trackHeight} * 3);
                margin: calc(-1 * ${trackHeight}) 0 0 calc(-1 * ${trackHeight} * 1.5);
                cursor: default;
                opacity: 1;
                background: ${ColorsEnum.transparent};

                &::before {
                    display: block;
                    box-sizing: border-box;
                    width: 0;
                    height: 0;
                    margin: calc(${trackHeight} * 1.5) 0 0 calc(${trackHeight} * 1.5);
                    content: '';
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s;
                    opacity: 0;
                }
            `;
        } else {
            return css`
                position: absolute;
                z-index: 1;
                width: calc(${trackHeight} * 3);
                height: calc(${trackHeight} * 3);
                margin: calc(-1 * ${trackHeight}) 0 0 calc(-1 * ${trackHeight} * 1.5);
                cursor: col-resize;
                opacity: 1;
                background: ${ColorsEnum.transparent};

                &::before {
                    display: block;
                    box-sizing: border-box;
                    background-color: ${color} !important;
                    width: calc(${trackHeight} * 2);
                    height: calc(${trackHeight} * 2);
                    margin: calc(${trackHeight} / 2) 0 0 calc(${trackHeight} / 2);
                    content: '';
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s;
                    opacity: 1;
                    border: 3px solid ${ColorsEnum.white};
                    border-radius: 50%;
                }
            `;
        }
    }

    private getTrackClassName(darkLight: number = 0) {
        const classes = classNames(SliderTrackStyle, this.getSliderTrackBackground(darkLight));

        return classes;
    }

    private getSliderColor(styleObj: CSSStyleDeclaration): string {
        return styleObj && styleObj.color ? styleObj.color : SliderConstants.COLOR.DEFAULT;
    }

    private getSliderBackgroundColor(styleObj: CSSStyleDeclaration) {
        return styleObj && styleObj.backgroundColor
            ? styleObj.backgroundColor
            : SliderConstants.COLOR.DEFAULT;
    }

    private getSliderOverlay(
        mode: number,
        start: number,
        end: number,
        lightStart: number,
        lightEnd: number,
        max: number,
        color1: string,
        color2: string,
        color3: string,
    ) {
        const { linearGradient, colorStop, DIRECTIONS: GRADIENT_DIRECTIONS } = cssGradient;
        if (mode === SliderConstants.MODE.SET_LIGHT) {
            return 'none';
        }
        const colorStops = this.calculatePointsForSlider(
            start,
            end,
            lightStart,
            lightEnd,
            max,
            color1,
            color2,
            color3,
        ).map((point: IColorStop) => {
            return colorStop(point.color, this.getPercentVal(point.value, max)) + '%';
        });
        return linearGradient(GRADIENT_DIRECTIONS.TO_RIGHT, ...colorStops);
    }

    private getTrackBackground(start: number, end: number, max: number) {
        const { linearGradient, colorStop, DIRECTIONS: GRADIENT_DIRECTIONS } = cssGradient;
        const transparent = SliderConstants.COLOR.TRANSPARENT;

        if ((end === 0 && start === max) || start === end) {
            return 'none';
        }
        if (end > start || (end === max && start === 0)) {
            return linearGradient(
                GRADIENT_DIRECTIONS.TO_RIGHT,
                colorStop(transparent),
                colorStop(
                    transparent,
                    this.getPercentVal(start, max) - SliderConstants.LIGHT_DARK_FADE_PERCENT,
                ) + '%',
                colorStop(
                    SliderConstants.COLOR.LIGHT,
                    this.getPercentVal(start, max) + SliderConstants.LIGHT_DARK_FADE_PERCENT,
                ) + '%',
                colorStop(
                    SliderConstants.COLOR.LIGHT,
                    this.getPercentVal(end, max) - SliderConstants.LIGHT_DARK_FADE_PERCENT,
                ) + '%',
                colorStop(
                    transparent,
                    this.getPercentVal(end, max) + SliderConstants.LIGHT_DARK_FADE_PERCENT,
                ) + '%',
            );
        }
        return linearGradient(
            GRADIENT_DIRECTIONS.TO_RIGHT,
            colorStop(SliderConstants.COLOR.LIGHT),
            colorStop(
                SliderConstants.COLOR.LIGHT,
                this.getPercentVal(end, max) - SliderConstants.LIGHT_DARK_FADE_PERCENT,
            ) + '%',
            colorStop(
                transparent,
                this.getPercentVal(end, max) + SliderConstants.LIGHT_DARK_FADE_PERCENT,
            ) + '%',
            colorStop(
                transparent,
                this.getPercentVal(start, max) - SliderConstants.LIGHT_DARK_FADE_PERCENT,
            ) + '%',
            colorStop(
                SliderConstants.COLOR.LIGHT,
                this.getPercentVal(start, max) + SliderConstants.LIGHT_DARK_FADE_PERCENT,
            ) + '%',
        );
    }

    private onTouchStart = (stateProp: StateProp) => (e: React.TouchEvent<HTMLElement>) => {
        this.initialValue = this.state[stateProp].toNumber();
        this.mouseInitX = e.touches[0].clientX;
    };

    private onTouchEnd = () => {
        this.triggerOnChangeIfSlidersChanged();
    };

    private onTouchMove = (stateProp: StateProp) => (e: React.TouchEvent<HTMLElement>) => {
        const value = new Time24(this.getValueFromTouchPosition(e));
        this.setValue(stateProp, value);
    };

    private setValue(stateProp: StateProp, value: Time24) {
        switch (stateProp) {
            case 'startValue':
                this.setState({ startValue: value });
                break;
            case 'endValue':
                this.setState({ endValue: value });
                break;
        }
    }

    // Slider start knob
    private onStartMouseDown = (e: MouseEvent) => {
        this.initialValue = this.state.startValue.toNumber();
        this.mouseInitX = e.clientX;
        if (!this.props.readOnly) {
            document.addEventListener('mousemove', this.onStartMouseMoved, false);
        }
    };

    private onStartMouseMoved = (e: MouseEvent) => {
        const value = new Time24(this.getValueFromMousePosition(e));
        this.setState({ startValue: value });
    };

    // Slider end knob
    private onEndMouseDown = (e: MouseEvent) => {
        this.initialValue = this.state.endValue.toNumber();
        this.mouseInitX = e.clientX;
        if (!this.props.readOnly) {
            document.addEventListener('mousemove', this.onEndMouseMoved, false);
        }
    };

    private onMouseUp = () => {
        document.removeEventListener('mousemove', this.onStartMouseMoved, false);
        document.removeEventListener('mousemove', this.onEndMouseMoved, false);
        this.triggerOnChangeIfSlidersChanged();
    };

    private onEndMouseMoved = (e: MouseEvent) => {
        const value = new Time24(this.getValueFromMousePosition(e));
        this.setState({ endValue: value });
    };

    private triggerOnChangeIfSlidersChanged() {
        if (
            this.props.onChange &&
            (this.props.start.toNumber() !== this.state.startValue.toNumber() ||
                this.props.end.toNumber() !== this.state.endValue.toNumber())
        ) {
            this.props.onChange(this.state.startValue, this.state.endValue);
        }
    }

    private getValueFromMousePosition(e: MouseEvent) {
        return this.getValueFromPosition(e.clientX);
    }

    private getValueFromTouchPosition(e: React.TouchEvent<HTMLElement>) {
        return this.getValueFromPosition(e.touches[0].clientX);
    }

    private getValueFromPosition(x: number) {
        return this.getValueBetweenMinAndMaxRoundedToQuarter(
            0,
            this.max,
            this.initialValue + ((x - this.mouseInitX) / this.sliderWidth) * this.max,
        );
    }

    private getPercentVal(val: number, max: number): number {
        return Math.round((val / max) * 1000) / 10; // Percent with one decimal
    }

    private getValueBetweenMinAndMaxRoundedToQuarter(min: number, max: number, val: number) {
        return Math.max(min, Math.min(Math.round(val * 4) / 4, max));
    }

    private calculatePointsForSlider(
        start: number,
        end: number,
        lightStart: number,
        lightEnd: number,
        max: number,
        color1: string,
        primaryColor: string,
        secondaryColor: string,
    ): IColorStop[] {
        const segmentTotal = new Segment(0, max);
        const segmentsDark =
            lightStart <= lightEnd
                ? [new Segment(0, lightStart), new Segment(lightEnd, max)]
                : [new Segment(lightEnd, lightStart)];
        const activeHoursSegments =
            start <= end
                ? [new Segment(start, end)]
                : [new Segment(0, end), new Segment(start, max)];

        // Get the active hours segments that are inside the "dark" segments
        const darkActiveHoursSegments = Segment.getAllIntersectionsForSegments(
            segmentsDark,
            activeHoursSegments,
        );

        // Subtract those dark segments from the active hours segments and we
        // will get the active hour segments that are in the light parts
        const lightActiveHoursSegments = activeHoursSegments.reduce((accumulator, segment) => {
            return [...accumulator, ...segment.subtractMultiple(darkActiveHoursSegments)];
        }, [] as Segment[]);

        // Get segments representing the inactive hours
        const inactiveHoursSegments = segmentTotal.subtractMultiple(activeHoursSegments);

        // Map all the segments to their color
        const darkColoredSegments = darkActiveHoursSegments.map((segment) => {
            return { color: secondaryColor, segment };
        }, []);

        const lightColoredSegments = lightActiveHoursSegments.map((segment) => {
            return { color: primaryColor, segment };
        }, []);

        const transparentSegments = inactiveHoursSegments.map((segment) => {
            return { color: color1, segment };
        }, []);

        // Sort the list of segments and convert the colored segments to a list of points that
        // will be used to create the CSS gradient
        return [...darkColoredSegments, ...lightColoredSegments, ...transparentSegments]
            .sort((a, b) => a.segment.start - b.segment.start)
            .reduce((colorPoints, colorSegment) => {
                return [
                    ...colorPoints,
                    { color: colorSegment.color, value: colorSegment.segment.start },
                    { color: colorSegment.color, value: colorSegment.segment.end },
                ];
            }, [] as IColorStop[]);
    }
}
