import * as React from 'react';
import { IconButton } from 'app/components';
import { eventTracking } from 'app/core/tracking';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import { connect } from 'react-redux';
import { getShowBidconExport } from '../selectors';
import { BillOfMaterialsActionService } from '../services';

interface IBillOfMaterialsExportBidconProps {
    visible: boolean;
}

const mapStateToProps = (state: IStoreState): IBillOfMaterialsExportBidconProps => {
    return {
        visible: getShowBidconExport(state),
    };
};

const BillOfMaterialsExportBidconContainer: React.FunctionComponent<
    IBillOfMaterialsExportBidconProps
> = ({ visible }) => {
    const [billOfMaterialsActionService] = React.useState<BillOfMaterialsActionService>(
        ServiceLocator.get(BillOfMaterialsActionService),
    );

    if (!visible) {
        return null;
    }

    const exportToBidcon = () => {
        eventTracking.logUserEvent('Reports', 'Export to Bidcon file', 'Bill Of Materials');
        eventTracking.pushToGA4('SiteDesignerClick', 'Export', 'Bill Of Materials', 'Bidcon');
        billOfMaterialsActionService.generateBidconExportFile();
    };

    return <IconButton icon="file_download" text={t.exportToBidcon} onClick={exportToBidcon} />;
};

export const BillOfMaterialsExportBidcon = connect(mapStateToProps)(
    BillOfMaterialsExportBidconContainer,
);

BillOfMaterialsExportBidcon.displayName = 'BillOfMaterialsExportBidcon';
