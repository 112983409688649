import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import type { IInstallationReportSortBy, IInstallationReportDevice } from '../models';
import {
    CategoryEnum,
    getCurrentProjectDevices,
    getPiaItemsRecord,
    getChildrenForDevice,
    getCurrentProjectRelationsRecord,
    getCurrentProjectItems,
} from 'app/modules/common';
import { isDefined, UnreachableCaseError } from 'axis-webtools-util';
import type { IItemEntity, IPersistence } from 'app/core/persistence';
import { modelComparator, nameComparator } from 'app/utils';

const getInstallationReportDevices = (storeState: IStoreState) =>
    storeState.installationReport.devices;

const getInstallationReportSortBy = (storeState: IStoreState) =>
    storeState.installationReport.sortBy;

export interface IItemWithChildren extends IPersistence<IItemEntity> {
    children: IPersistence<IItemEntity>[];
}

export const getSortedCurrentProjectDevices = createSelector(
    [
        getCurrentProjectDevices,
        getPiaItemsRecord,
        getCurrentProjectRelationsRecord,
        getCurrentProjectItems,
        getInstallationReportSortBy,
    ],
    (devices, piaItems, currentProjectRelationsRecord, currentProjectItems, sortByValue) => {
        const deviceWithPiaModel = devices
            .map((device) => {
                const piaItem = device.productId ? piaItems[device.productId] : undefined;
                if (!piaItem) {
                    return undefined;
                }
                const children = getChildrenForDevice(
                    currentProjectRelationsRecord[device._id],
                    currentProjectItems,
                );

                return {
                    ...device,
                    model: piaItem.name,
                    children,
                };
            })
            .filter(isDefined);

        return deviceWithPiaModel.sort(getDeviceSortComparator(sortByValue)) as IItemWithChildren[];
    },
);

export const getProposalDeviceDetails = createSelector(
    [getInstallationReportDevices],
    (devices) => {
        return devices.filter((device) =>
            [
                CategoryEnum.Camera,
                CategoryEnum.Alerters,
                CategoryEnum.AnalogCamera,
                CategoryEnum.FSeries,
                CategoryEnum.DoorStation,
                CategoryEnum.Encoder,
                CategoryEnum.Speaker,
                CategoryEnum.RadarDetector,
                CategoryEnum.Wearables,
                CategoryEnum.DoorControllers,
                CategoryEnum.Doors,
                CategoryEnum.Decoder,
                CategoryEnum.PeopleCounters,
                CategoryEnum.Pac,
                CategoryEnum.ConnectivityDevice,
                CategoryEnum.PagingConsole,
            ].includes(device.category),
        ) as IInstallationReportDevice[];
    },
);

function getDeviceSortComparator(sortByValue: IInstallationReportSortBy) {
    switch (sortByValue) {
        case 'byModel':
            return modelComparator;
        case 'byName':
            return nameComparator;
        default:
            throw new UnreachableCaseError(sortByValue);
    }
}
