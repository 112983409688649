import { injectable } from 'inversify';
import { Config, msrpService } from 'app/modules/msrp';
import { AppSettings } from 'app/AppSettings';
import { UserApiCommunicator } from './user';

@injectable()
export class PriceService {
    public constructor(
        private appSettings: AppSettings,
        public userApiCommunicator: UserApiCommunicator,
    ) {
        Config.set({
            userApiCommunicator,
            subUrlDistributors: this.appSettings.priceSubUrlDistributors,
            subUrlPrices: this.appSettings.priceSubUrlPrices,
            subUrlRates: this.appSettings.priceSubUrlRates,
            url: this.appSettings.priceUrl,
        });
    }

    /**
     * Check if user has access to MSRP information and cache this
     * data locally. If not, clear the cache (will not clear auth status).
     */
    public cacheMsrpData = async () => {
        const isAuthorized = await msrpService.cacheAuthorizationStatus();
        return isAuthorized ? msrpService.cacheAll() : msrpService.clearCache();
    };

    /**
     * Remove all of the MSRP information, including aut status
     */
    public clearMsrpData = () => msrpService.dropDatabase();
}
