import { t } from 'app/translate';
import * as React from 'react';
import classNames from 'classnames';
import type { IProduct, CategoryEnum } from 'app/modules/common';
import { translateCategory, quotationFilter } from 'app/modules/common';
import { BillOfMaterialsSubsection } from './BillOfMaterialsSubsection.component';
import { Heading } from 'app/components';
import { QuotationTableStyle } from 'app/modules/quotation';

export interface IBillOfMaterialsSectionProps {
    products: IProduct[];
    sectionHeader: string;
}

export const BillOfMaterialsSection: React.FunctionComponent<IBillOfMaterialsSectionProps> = (
    props,
) => {
    if (props.products.length === 0) {
        return null;
    }

    const renderTable = (category: CategoryEnum) => (
        <BillOfMaterialsSubsection
            key={category}
            header={translateCategory(category)}
            products={quotationFilter.filterProductsByCategory(props.products, category)}
        />
    );

    return (
        <>
            <Heading style="subheader">{props.sectionHeader}</Heading>
            <table className={classNames(QuotationTableStyle)}>
                <colgroup span={3} />
                <thead>
                    <tr>
                        <th scope="col" />
                        <th scope="col">{t.quotationPartNumber}</th>
                        <th scope="col">{t.quotationQuantity}</th>
                    </tr>
                </thead>

                {quotationFilter.renderAllCategories(renderTable)}
            </table>
        </>
    );
};

BillOfMaterialsSection.displayName = 'BillOfMaterialsSection';
