import type { IRelatedPiaItem } from 'app/modules/common';
import {
    getAvailablePiaItemsRecord,
    getCurrentProjectItems,
    getCurrentProjectLocation,
    getIdFromProps,
    getPiaItemsWithPrice,
    getPriceListForRegion,
} from 'app/modules/common';
import { createSelector } from 'reselect';
import type { IPiaItem } from 'app/core/pia';

export const getCompatibleExpansionModules = createSelector(
    [
        getAvailablePiaItemsRecord,
        getPriceListForRegion,
        getCurrentProjectLocation,
        getCurrentProjectItems,
        getIdFromProps,
    ],
    (piaItems, priceListForRegion, projectLocation, items, parentDeviceId) => {
        const productId = parentDeviceId ? items[parentDeviceId]?.productId : undefined;
        if (!productId) {
            return [];
        }

        const piaItem = piaItems[productId];
        const relations = piaItem.relations.filter(
            (relation) => relation.relationType === 'compatible',
        );
        const availableItems = Object.values(relations)
            .map(
                (relation) =>
                    ({
                        ...piaItems[relation.id],
                        isIncluded: relation.relationType === 'includes',
                        isRecommended: relation.relationType === 'recommends',
                    }) as IRelatedPiaItem,
            )
            .filter((relation) => relation.category === 'relayexpmodules');
        return getPiaItemsWithPrice<IPiaItem>(availableItems, priceListForRegion, projectLocation);
    },
);
