import * as React from 'react';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import type { Property } from 'csstype';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { Box, Border, Card, Clickable, IconText, PiaImage, Positioned, Text } from 'app/components';
import type { PiaId } from 'app/core/pia';
import { getEditItem, getParentDeviceId, getProductItem } from '../selectors';
import type { IProductItem } from '../models';
import { DeviceSelectorActionService } from '../services';
import { ServiceLocator } from 'app/ioc';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { AddProductModal } from './AddProductModal';
import type { IAddProductProps } from 'app/modules/common';
import { ProductName } from 'app/modules/common';

interface IProductSelectionProps {
    visible: boolean;
    productId: PiaId | null;
    onClickScrollTo?(): void;
}

const transitionTimeout = {
    appear: 400,
    enter: 400,
    exit: 400,
};

const transitionDuration = 400;

const stateToTranslation: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '300px',
    entering: '300px',
    entered: '0',
    exiting: '300px',
    exited: '300px',
};

export const ProductSelection: React.FC<IProductSelectionProps> = ({
    visible,
    productId,
    onClickScrollTo,
}) => {
    const [actionService] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );
    const [showAddProductModal, setShowAddProductModal] = React.useState<boolean>(false);
    const productItem = useSelector<IStoreState, IProductItem | undefined>((state) =>
        getProductItem(state, productId ?? undefined),
    );
    const parentDeviceId = useSelector<IStoreState, Id | null>((state) => getParentDeviceId(state));
    const editDeviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        getEditItem,
    );

    const isEditMode = editDeviceItem !== undefined;

    const nodeRef = React.useRef(null);

    if (!productItem) {
        return null;
    }

    const addOrUpdateProduct = (addProductProps: IAddProductProps) => {
        parentDeviceId
            ? actionService.addOrUpdateChildDevice(
                  productItem.productId,
                  parentDeviceId,
                  undefined,
                  addProductProps,
              )
            : actionService.addOrUpdateDevice(productItem.productId, undefined, addProductProps);
        setShowAddProductModal(false);
    };

    const onAddOrChangedClicked = () => {
        if (editDeviceItem) {
            updateProduct();
        } else {
            setShowAddProductModal(true);
        }
    };

    const updateProduct = () => {
        parentDeviceId
            ? actionService.addOrUpdateChildDevice(
                  productItem.productId,
                  parentDeviceId,
                  editDeviceItem,
              )
            : actionService.addOrUpdateDevice(productItem.productId, editDeviceItem);
    };

    return (
        <>
            {showAddProductModal && (
                <AddProductModal
                    productInfo={{
                        productId: productItem.productId,
                        name: productItem.name,
                    }}
                    parentDeviceId={parentDeviceId}
                    onClose={() => setShowAddProductModal(false)}
                    onAddProduct={addOrUpdateProduct}
                />
            )}
            <Transition
                in={visible}
                appear
                timeout={transitionTimeout}
                mountOnEnter
                unmountOnExit
                nodeRef={nodeRef}
            >
                {(state) => (
                    <div ref={nodeRef}>
                        <Positioned
                            position="fixed"
                            right="50px"
                            bottom="20px"
                            translateY={stateToTranslation[state]}
                            transition
                            duration={transitionDuration}
                            bringToFront
                        >
                            <Card notFullWidth hideOverflow shadow borderWidth={0} color="yellow1">
                                <Box direction="column" alignItems="center" width="160px">
                                    <Clickable onClick={onClickScrollTo}>
                                        <Box direction="column" alignItems="center" width="100%">
                                            <Box padding="base">
                                                <PiaImage
                                                    imageSize="xl"
                                                    piaId={productItem.productId}
                                                    icon={productItem.icon}
                                                />
                                            </Box>
                                            <Box
                                                direction="column"
                                                alignItems="center"
                                                paddingX="half"
                                            >
                                                <ProductName name={productItem.name} large />
                                            </Box>
                                            <Box
                                                width="100%"
                                                justifyContent="center"
                                                padding="half"
                                            >
                                                {onClickScrollTo ? (
                                                    <IconText
                                                        icon="unfold_more"
                                                        iconProps={{ opaque: true, color: 'grey6' }}
                                                    >
                                                        {t.scrollToSelection}
                                                    </IconText>
                                                ) : (
                                                    <IconText
                                                        icon="warning"
                                                        iconProps={{
                                                            opaque: true,
                                                            color: 'yellowText',
                                                        }}
                                                    >
                                                        {t.doesNotMeetRequirements}
                                                    </IconText>
                                                )}
                                            </Box>
                                        </Box>
                                    </Clickable>
                                    <Border topWidth={1} color="yellow">
                                        <Clickable onClick={onAddOrChangedClicked}>
                                            <Box
                                                width="100%"
                                                justifyContent="center"
                                                padding="half"
                                                color="yellow2"
                                                hoverColor="yellow3"
                                            >
                                                <Text style="semibold" color="yellowText">
                                                    {isEditMode ? t.change : t.add}
                                                </Text>
                                            </Box>
                                        </Clickable>
                                    </Border>
                                </Box>
                            </Card>
                        </Positioned>
                    </div>
                )}
            </Transition>
        </>
    );
};

ProductSelection.displayName = 'ProductSelection';
