import type { IMsrp } from '../communicator/communicator';
import { communicator } from '../communicator/communicator';
import type { ICurrencies, ICurrency } from '../models/currency.interfaces';
import type {
    IDistributor,
    IDistributorRecord,
    IDistributors,
} from '../models/distributor.interfaces';
import type { IPriceRecord, IPrices } from '../models/price.interfaces';
import repository from '../repository/repository';
import type { ITimeService } from './time.service';

const CURRENCY_DECIMALS = 2;

export class Service {
    constructor(private timeService: ITimeService) {}

    public async getDistributors(): Promise<IDistributors> {
        const lastFetch = await repository.getLastFetch('distributors');
        if (this.timeService.isUpdateOfDataRequired(lastFetch)) {
            return this.fetchAndStoreDistributors().catch((e) => {
                if (e.status === 403) {
                    this.clearCache();
                }
                return Promise.reject(e);
            });
        }

        const allDistributors = await repository.getDistributors();
        const distributorResponse: IDistributors = {
            list: allDistributors,
            lastUpdated: lastFetch,
        };

        return distributorResponse;
    }

    public async getPrices(distributor: IDistributor, partNumbers?: string[]): Promise<IPrices> {
        const lastFetch = await repository.getLastFetch('prices');

        if (this.timeService.isUpdateOfDataRequired(lastFetch)) {
            try {
                await this.fetchAndStorePrices(distributor.priceList, distributor.locid);
            } catch (e: any) {
                if (e.status === 403) {
                    this.clearCache();
                }
                return this.createPriceObject({}, lastFetch, null);
            }
        }

        const { prices, currencyCode } = await repository.getPrices(
            distributor.priceList,
            partNumbers,
        );

        return this.createPriceObject(this.roundPrices(prices), lastFetch, currencyCode);
    }

    public async convertPricesToCurrency(
        msrp: IPrices,
        currencyCode: string,
    ): Promise<IPrices | null> {
        if (!msrp || !msrp.currencyCode) {
            return null;
        }

        // The prices are already in the correct currency
        if (msrp.currencyCode === currencyCode) {
            return msrp;
        }

        const toCurrency = await this.getCurrency(currencyCode);
        const fromCurrency = await this.getCurrency(msrp.currencyCode);

        // Could not get currencies from rates api
        if (!toCurrency || !fromCurrency) {
            return null;
        }

        const rate = fromCurrency.toUsdRate / toCurrency.toUsdRate;
        const convertedPrices = this.convertPrices(msrp.list, rate);

        return this.createPriceObject(
            this.roundPrices(convertedPrices),
            msrp.lastUpdated,
            toCurrency.code,
        );
    }

    public async convertPriceToCurrency(
        msrp: number,
        currentCurrencyCode: string,
        newCurrencyCode: string,
    ): Promise<number | null> {
        if (!msrp) {
            return null;
        }

        // The prices are already in the correct currency
        if (currentCurrencyCode === newCurrencyCode) {
            return msrp;
        }

        const toCurrency = await this.getCurrency(newCurrencyCode);
        const fromCurrency = await this.getCurrency(currentCurrencyCode);

        // Could not get currencies from rates api
        if (!toCurrency || !fromCurrency) {
            return null;
        }

        const rate = fromCurrency.toUsdRate / toCurrency.toUsdRate;

        return msrp * rate;
    }

    public async getCurrency(currency: string): Promise<ICurrency | undefined> {
        const lastFetch = await repository.getLastFetch('currencies');
        if (this.timeService.isUpdateOfDataRequired(lastFetch)) {
            await this.fetchAndStoreRates();
        }
        return repository.getCurrency(currency);
    }

    /**
     * Return a Record of currency data where the currencyCode
     * (in uppercase) is the key and the value is an ICurrency object.
     */
    public async getCurrencies(): Promise<ICurrencies> {
        const lastFetch = await repository.getLastFetch('currencies');
        if (this.timeService.isUpdateOfDataRequired(lastFetch)) {
            await this.fetchAndStoreRates();
        }

        const currencies = await repository.getCurrencies();
        return Object.values(currencies).reduce((record, currency) => {
            record[currency.code] = currency;
            return record;
        }, {} as ICurrencies);
    }

    public async cacheAllDistributorsPriceLists(): Promise<void[]> {
        const distributors = await this.fetchAndStoreDistributors();
        const distArray: IDistributor[] = distributors.list;

        return Promise.all(
            distArray.map((key: IDistributor) => {
                return this.fetchAndStorePrices(key.priceList, key.locid);
            }),
        );
    }

    public async cacheAll(): Promise<[void[], void]> {
        return Promise.all([this.cacheAllDistributorsPriceLists(), this.fetchAndStoreRates()]);
    }

    /**
     * Clear the MSRP prices and distributor
     * local database.
     *
     * This does not include authorization status.
     */
    public async clearCache(): Promise<void> {
        await repository.clearPriceData();
    }

    /**
     * Remove the entire MSRP local database,
     * including authorization status.
     */
    public async dropDatabase(): Promise<void> {
        await repository.dropDatabase();
    }

    /**
     * Check if user is authorized to access to the MSRP API and
     * save the status to cache unless API is unresponsive.
     * Will reject the promise if API is unresponsive.
     * Returns true if user is authorized, false if not.
     */
    public async cacheAuthorizationStatus(): Promise<boolean> {
        const apiRequest = await communicator.testApi();
        const isAuthorized = apiRequest.status !== 403;

        repository.setIsAuthorized(isAuthorized);

        return isAuthorized;
    }

    /**
     * Check if user is authorized to access to the MSRP API.
     * First tries the cache, then does an API request.
     * If the request fails it returns false.
     * Returns true or false.
     */
    public async isAuthorized(): Promise<boolean> {
        const isAuthorized = await repository.getIsAuthorized();

        if (isAuthorized !== undefined) {
            return isAuthorized;
        }

        try {
            return await this.cacheAuthorizationStatus();
        } catch {
            return false;
        }
    }

    private createPriceObject(
        list: IPriceRecord,
        lastUpdated: number | undefined,
        currencyCode: string | null,
    ): IPrices {
        return {
            list,
            lastUpdated,
            currencyCode,
        };
    }

    private roundPrices(prices: IPriceRecord): IPriceRecord {
        return Object.keys(prices).reduce((roundedPrices, partNumber) => {
            const price = prices[partNumber];
            prices[partNumber] = price && this.roundNumber(price, CURRENCY_DECIMALS);
            return roundedPrices;
        }, prices);
    }

    private roundNumber(number: number, decimals: number) {
        const factor = 10 ** decimals;
        return Math.round(number * factor) / factor;
    }

    private convertPrices(prices: IPriceRecord, rate: number): IPriceRecord {
        return Object.keys(prices).reduce((convertedPrices, partNumber) => {
            const price = prices[partNumber];
            convertedPrices[partNumber] = price && price * rate;
            return convertedPrices;
        }, {} as IPriceRecord);
    }

    private fetchAndStoreDistributors(): Promise<IDistributors> {
        return communicator.getDistributors().then(async (distributors: IDistributorRecord) => {
            await repository.deleteDistributors();
            await repository.setDistributors(distributors).catch((e) => console.warn(e));

            const updatedTimestamp = Date.now();
            await repository.setLastFetch('distributors', updatedTimestamp);

            const distributorResponse: IDistributors = {
                list: Object.keys(distributors).map((locid: string) => distributors[locid]),
                lastUpdated: updatedTimestamp,
            };
            return distributorResponse;
        });
    }

    private fetchAndStorePrices(priceList: string, locId: number): Promise<void> {
        return communicator.getPrices(priceList, locId).then(async (msrp: IMsrp) => {
            await repository.deletePriceList(priceList);
            await repository
                .setPrices(priceList, msrp.currencyCode, msrp.prices)
                .catch((e) => console.warn(e));

            const updatedTimestamp = Date.now();
            await repository.setLastFetch('prices', updatedTimestamp);
        });
    }

    private fetchAndStoreRates(): Promise<void> {
        return communicator
            .getCurrencies()
            .then(async (currencies: ICurrencies) => {
                await repository.setCurrencies(currencies);

                const updatedTimestamp = Date.now();
                await repository.setLastFetch('currencies', updatedTimestamp);
            })
            .catch(() => {});
    }
}
