import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    getCurrentProjectItem,
    getCurrentProjectLocation,
    getProductAllowlist,
    getCurrentProjectLocked,
    getIsStandalone,
    getPiaItemForProductId,
} from 'app/modules/common';
import {
    Border,
    Box,
    Stack,
    Text,
    PiaImage,
    DropDown,
    Checkbox,
    DropDownMenuButton,
    Positioned,
    Icon,
} from 'app/components';
import {
    getEnvironments,
    getSelectedEnvironment,
    getAvailableEnvironments,
    getSelectedDeviceId,
    getSelectedNotToBeFollowedBy,
    getFilterOutdoorForSelectedDevice,
} from '../../selectors';
import type { IEnvironment, ISelectedEnvironment } from '../../models';
import { t } from 'app/translate';
import { ServiceLocator } from 'app/ioc';
import type { Id, IItemEntity, IPartnerConfigAllowlist, IPersistence } from 'app/core/persistence';

import { MOBILE_WINDOW_SIZE } from '../../constants';
import { AccessorySelectorService } from '../../services';
import type { IPiaItem, IPiaLocation, IPiaOptionalDeviceProperties } from 'app/core/pia';
import { css } from '@emotion/css';
import { useState } from 'react';
import { eventTracking } from 'app/core/tracking';

const cursorStyle = css`
    cursor: default !important;
`;

export const EnvironmentSelection: React.FunctionComponent = () => {
    const [accessorySelectorService] = React.useState(() =>
        ServiceLocator.get(AccessorySelectorService),
    );

    const isLocked = useSelector<IStoreState, boolean>((state) => getCurrentProjectLocked(state));
    const projectLocation = useSelector<IStoreState, IPiaLocation | undefined>((state) =>
        getCurrentProjectLocation(state),
    );
    const partnerConfig = useSelector<IStoreState, IPartnerConfigAllowlist | undefined>((state) =>
        getProductAllowlist(state),
    );
    const selectedDeviceId = useSelector<IStoreState, Id | undefined>((state) =>
        getSelectedDeviceId(state),
    );
    const isStandalone = useSelector<IStoreState, boolean>((state) => getIsStandalone(state));
    const environments = useSelector<IStoreState, IEnvironment[]>((state) =>
        selectedDeviceId
            ? getAvailableEnvironments(state, selectedDeviceId)
            : getEnvironments(state),
    );
    const selectedEnvironment = useSelector<IStoreState, ISelectedEnvironment | undefined>(
        (state) => (selectedDeviceId ? getSelectedEnvironment(state, selectedDeviceId) : undefined),
    );
    const deviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        selectedDeviceId ? getCurrentProjectItem(state, selectedDeviceId) : undefined,
    );

    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItemForProductId(state, deviceItem?.productId ?? null),
    );

    const showOutdoorToggle =
        (piaItem?.properties as IPiaOptionalDeviceProperties)?.outdoorReady ?? false;

    const isOnlyOutdoor = useSelector<IStoreState, boolean>(getFilterOutdoorForSelectedDevice);

    const selectedNotToBeFollowedBy = useSelector(getSelectedNotToBeFollowedBy);

    const [disableSelection, setDisableSelection] = useState(false);

    const onOutdoorToggle = () => {
        eventTracking.logUserEvent(
            'Accessory Selector',
            'Toggle outdoor',
            piaItem?.name,
            !isOnlyOutdoor ? 1 : 0,
        );
        selectedDeviceId &&
            accessorySelectorService.setMountingFilterOutdoor(selectedDeviceId, !isOnlyOutdoor);
    };

    const setEnvironment = React.useCallback(
        async (productId: number) => {
            if (selectedDeviceId && deviceItem?.productId && projectLocation) {
                setDisableSelection(true);
                await accessorySelectorService.setEnvironment(
                    selectedDeviceId,
                    deviceItem.productId,
                    selectedEnvironment?.id,
                    productId,
                    projectLocation,
                    selectedNotToBeFollowedBy,
                    isOnlyOutdoor,
                    partnerConfig,
                );
                setDisableSelection(false);
            }
        },
        [
            accessorySelectorService,
            deviceItem?.productId,
            isOnlyOutdoor,
            partnerConfig,
            projectLocation,
            selectedDeviceId,
            selectedEnvironment?.id,
            selectedNotToBeFollowedBy,
        ],
    );

    const renderSelectedEnvironment = () => (
        <div className={isLocked ? cursorStyle : undefined}>
            <Border
                color="blackOpacity"
                radius={isStandalone ? '5px' : '10px'}
                shadow={isLocked ? undefined : '0 4px 10px rgba(0,0,0,.15)'}
            >
                <Box padding="base" color={isLocked ? 'grey1' : 'white'} position="relative">
                    {!isLocked && environments.length > 0 && (
                        <Positioned position="absolute" top={0} right=".25rem">
                            <Icon icon="keyboard_arrow_down" color="grey6" />
                        </Positioned>
                    )}
                    <Stack
                        vertical={window.innerWidth > MOBILE_WINDOW_SIZE}
                        alignItems="center"
                        justifyContent={window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'}
                    >
                        <PiaImage
                            piaId={selectedEnvironment?.productId ?? null}
                            icon="place"
                            iconProps={{
                                color: 'blue',
                                opaque: true,
                            }}
                            imageSize={window.innerWidth > MOBILE_WINDOW_SIZE ? 'xl' : 'lg'}
                            imageUrlFallback={
                                selectedEnvironment?.productId
                                    ? undefined
                                    : require('src/assets/images/mounting/placement.png')
                            }
                        />
                        <Box
                            height="3em"
                            width={window.innerWidth > MOBILE_WINDOW_SIZE ? '135px' : undefined}
                            alignItems="center"
                            justifyContent={
                                window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : 'start'
                            }
                        >
                            <Text
                                testId={`mounting_${
                                    selectedEnvironment?.name ??
                                    (environments.length > 0
                                        ? t.selectPlacement
                                        : t.noMountsAvailable)
                                }`}
                                style="semibold"
                                align={
                                    window.innerWidth > MOBILE_WINDOW_SIZE ? 'center' : undefined
                                }
                            >
                                {selectedEnvironment?.name ??
                                    (environments.length > 0
                                        ? t.selectPlacement
                                        : t.noMountsAvailable)}
                            </Text>
                        </Box>
                    </Stack>
                </Box>
            </Border>
        </div>
    );

    return (
        <Stack vertical spacing="quart" notFullWidth>
            <Box paddingBottom="half" width="100%" justifyContent="center">
                <Text bold color="grey5">
                    {t.placement}
                </Text>
            </Box>
            {environments.length > 0 ? (
                <DropDown
                    openInPortal
                    minWidth={170}
                    disabled={!deviceItem}
                    trigger={renderSelectedEnvironment()}
                    contents={
                        isLocked
                            ? null
                            : environments.map(({ name, productId }) => (
                                  <DropDownMenuButton
                                      disabled={disableSelection}
                                      display="block"
                                      onlyContent
                                      selected={productId === selectedEnvironment?.productId}
                                      onClick={() => setEnvironment(productId!)}
                                  >
                                      <Box padding="cell" key={name} justifyContent="between">
                                          <Stack>
                                              <PiaImage
                                                  imageSize="md"
                                                  piaId={productId}
                                                  icon="category"
                                              />
                                              <Text testId={`mounting_${name}`}>{name}</Text>
                                          </Stack>
                                      </Box>
                                  </DropDownMenuButton>
                              ))
                    }
                />
            ) : (
                renderSelectedEnvironment()
            )}
            {showOutdoorToggle && (
                <Box paddingTop="half" width="100%" justifyContent="center">
                    <Checkbox
                        slider
                        selected={isOnlyOutdoor}
                        onChange={onOutdoorToggle}
                        testId={'outdoor_chain_toggle'}
                    >
                        {t.outdoor}
                    </Checkbox>
                </Box>
            )}
        </Stack>
    );
};
