import * as React from 'react';
import { Stack, Heading, Box, Checkbox, Grid } from 'app/components';
import { t } from 'app/translate';
import { constants } from '../../constants';
import { PropertyOverrideText, NotSupportedText } from '../common';
import { ProfileEditorRow } from '../profileEditorRow';
import type { IProfileSupport } from '../../models';

export interface IAudioSettingsComponentProps {
    liveViewAudioEnabled: boolean;
    liveViewAudioOverridden: boolean;
    recordingAudioEnabled: boolean;
    recordingAudioOverridden: boolean;
    profileSupport?: IProfileSupport;
    deactivate?: boolean;
    onLiveViewAudioChanged(value: boolean): void;
    onRecordingAudioChanged(value: boolean): void;
}

export class AudioSettingsComponent extends React.Component<IAudioSettingsComponentProps> {
    constructor(props: IAudioSettingsComponentProps) {
        super(props);
    }

    public render() {
        const { profileSupport } = this.props;

        return (
            <ProfileEditorRow>
                <Stack vertical spacing="half">
                    <Heading>{t.profilesRecordingSettingsAudio}</Heading>
                    <Stack>
                        <Box width={constants.leftColumnWidth} />
                        <Box testId="audio_zip_stream_settings_container" flex="shrinkAndGrow">
                            <Grid>
                                <div>
                                    <Stack spacing="halfQuart" vertical>
                                        <PropertyOverrideText
                                            text={t.profilesAudioAndZipstreamSettingsRecordingAudio}
                                            propertyValue={this.props.recordingAudioOverridden}
                                        />
                                        <div style={{ paddingTop: '2px' }}>
                                            <Checkbox
                                                selected={this.props.recordingAudioEnabled}
                                                onChange={this.props.onRecordingAudioChanged}
                                                disabled={this.props.deactivate}
                                            />
                                            {this.props.recordingAudioEnabled && (
                                                <NotSupportedText
                                                    supportedWithMessage={
                                                        profileSupport && profileSupport.audio
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack spacing="halfQuart" vertical>
                                        <PropertyOverrideText
                                            text={t.profilesAudioAndZipstreamSettingsLiveViewAudio}
                                            propertyValue={this.props.liveViewAudioOverridden}
                                        />
                                        <div style={{ paddingTop: '2px' }}>
                                            <Checkbox
                                                selected={this.props.liveViewAudioEnabled}
                                                onChange={this.props.onLiveViewAudioChanged}
                                                disabled={this.props.deactivate}
                                            />
                                            {this.props.liveViewAudioEnabled && (
                                                <NotSupportedText
                                                    supportedWithMessage={
                                                        profileSupport && profileSupport.audio
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Stack>
                                </div>
                            </Grid>
                        </Box>
                    </Stack>
                </Stack>
            </ProfileEditorRow>
        );
    }
}
