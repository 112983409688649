import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getSelectedDeviceOrParentDevice } from '../../../../selectors';
import type { IMapsCamera } from '../../../../models';
import { ensureCameraOrUndefined } from '../../../../models';
import { ServiceLocator } from 'app/ioc';
import { PixelDensityComponent } from './PixelDensity.component';
import type { DistanceUnit } from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';
import { getCurrentProjectDisplayUnit } from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import type { Colors } from 'app/styles';

interface IPixelDensityContainerOwnProps {
    color: Colors;
}

interface IPixelDensityContainer extends IPixelDensityContainerOwnProps {
    mapsDevice?: IMapsCamera;
    pixelDensity: number | undefined;
    displayUnit: DistanceUnit;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IPixelDensityContainerOwnProps,
): IPixelDensityContainer => {
    const mapsDevice = ensureCameraOrUndefined(getSelectedDeviceOrParentDevice(storeState));
    const displayUnit = getCurrentProjectDisplayUnit(storeState);

    return {
        mapsDevice,
        pixelDensity: mapsDevice && mapsDevice.pixelDensity,
        displayUnit,
        color: ownProps.color,
    };
};

class PixelDensityContainer extends React.Component<IPixelDensityContainer> {
    private itemService: ItemService;

    constructor(props: IPixelDensityContainer) {
        super(props);
        this.itemService = ServiceLocator.get(ItemService);
    }

    public render() {
        return (
            <>
                {this.props.pixelDensity && (
                    <PixelDensityComponent
                        pixelDensity={this.props.pixelDensity}
                        displayUnit={this.props.displayUnit}
                        onPixelDensityChange={this.onPixelDensityChanged}
                        color={this.props.color}
                    />
                )}
            </>
        );
    }

    private onPixelDensityChanged = async (newPixelDensity: number) => {
        if (this.props.mapsDevice) {
            eventTracking.logUserEvent(
                'Maps',
                'Change pixel density',
                `New pixel density: ${newPixelDensity}`,
            );
            await this.itemService.updatePixelDensity(this.props.mapsDevice.id, newPixelDensity);
        }
    };
}

export const PixelDensity = connect(mapStateToProps)(PixelDensityContainer);
