import * as React from 'react';
import { FreeTextMapItem } from './FreeTextMapItem';
import { useSelector } from 'react-redux';
import { getFreeTextIds } from '../../selectors/getFreeTextPointEntity';
import { getSelectedMapOrDefault, getIsFreeTextToolActive } from '../../selectors';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../services';

export const FreeTexts: React.FC = () => {
    const selectedFloorPlan = useSelector(getSelectedMapOrDefault);
    const isFreeTextToolActive = useSelector(getIsFreeTextToolActive);
    const mapsActionService = useService(MapsActionService);
    const freeTextPointIds = useSelector(getFreeTextIds);

    const disableFreeTextOnEsc = React.useCallback(
        (keyEvent: KeyboardEvent) =>
            keyEvent.key === 'Escape' && mapsActionService.toggleFreeTextTool(false),
        [mapsActionService],
    );

    React.useEffect(() => {
        if (isFreeTextToolActive) {
            // Add escape hotkey for deactivating free text.
            window.addEventListener('keydown', disableFreeTextOnEsc);
            // Remove hotkey on cleanup when free text tool gets deactivated.
            return () => window.removeEventListener('keydown', disableFreeTextOnEsc);
        }
    }, [disableFreeTextOnEsc, isFreeTextToolActive]);

    // Close free text tool when changing floor plan.
    React.useEffect(() => {
        mapsActionService.toggleFreeTextTool(false);
    }, [mapsActionService, selectedFloorPlan?._id]);

    if (freeTextPointIds.length === 0) return null;

    return (
        <>
            {freeTextPointIds.map((id) => (
                <FreeTextMapItem key={id} id={id} />
            ))}
        </>
    );
};

FreeTexts.displayName = 'FreeTexts';
