import * as React from 'react';
import type { IInstallationBaseReportAccessory } from '../../models/devices';
import type { IAutoTestable } from 'app/components';
import { Box, ProductCardItem } from 'app/components';
import { t } from 'app/translate';

interface IMountingChainProps extends IAutoTestable {
    mounts: IInstallationBaseReportAccessory[];
}

export class MountingChain extends React.Component<IMountingChainProps> {
    public render() {
        return (
            <Box justifyContent="between" position="relative" wrap>
                {this.props.mounts.map((mount) => (
                    <ProductCardItem
                        key={mount.piaId}
                        testId={`${this.props.testId}_mounting_${mount.model}`}
                        name={
                            t.accessoryEnvironmentsGROUP[
                                mount.model as keyof typeof t.accessoryEnvironmentsGROUP
                            ] || mount.model
                        }
                        productId={mount.piaId}
                        imageSize="xl"
                        maxWidth={this.props.mounts.length > 5 ? 100 : undefined}
                    />
                ))}
            </Box>
        );
    }
}
