import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { getPiaItemsRecord } from '../../piaDevices';
import { toCacheKey } from '../../cacheKey';

export const getPiaCategory = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (item, piaRecord) => {
        const foundDevice = item?.productId && piaRecord[item.productId];
        return foundDevice ? foundDevice.category : undefined;
    },
)(toCacheKey);
