import * as L from 'leaflet';
import { offset } from 'axis-webtools-util';
import type { ILatLng } from 'app/core/persistence';

// 1x1 pixel white png
const WHITE_PIXEL =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';

export class RotatedImageOverlay extends L.ImageOverlay {
    protected _image!: HTMLImageElement;
    private touchStartOffset: { x: number; y: number } = { x: 0, y: 0 };

    constructor(
        imgSrc: string | undefined,
        private position: L.LatLng,
        private width: number,
        private height: number,
        private angle: number,
        options: L.ImageOverlayOptions,
    ) {
        super(
            imgSrc ?? WHITE_PIXEL,
            L.latLngBounds(position, position), // not used but required
            options,
        );
    }

    public onTouchStart(callback: () => void) {
        return this._image.addEventListener('touchstart', (e) => {
            const imgRect = this._image.getBoundingClientRect();
            const imgCenter = {
                x: imgRect.left + imgRect.width / 2,
                y: imgRect.top + imgRect.height / 2,
            };
            this.touchStartOffset = {
                x: e.touches[0].clientX - imgCenter.x,
                y: e.touches[0].clientY - imgCenter.y,
            };
            callback();
        });
    }

    public onTouchMove(callback: (position: ILatLng) => void) {
        const mapContainer = this._map.getContainer();
        const mapRect = mapContainer.getBoundingClientRect();
        return this._image.addEventListener('touchmove', (e) => {
            e.stopPropagation();
            e.preventDefault();
            const { clientX, clientY } = e.touches[0];
            const position = this._map.containerPointToLatLng([
                clientX - mapRect.left - this.touchStartOffset.x,
                clientY - mapRect.top - this.touchStartOffset.y,
            ]);
            callback(position);
        });
    }

    public onTouchEnd(callback: () => void) {
        return this._image.addEventListener('touchend', callback);
    }

    public offTouchMove() {
        this._image.removeEventListener('touchmove', () => {});
        this._image.removeEventListener('touchend', () => {});
    }

    public offTouch() {
        this.offTouchMove();
        this._image.removeEventListener('touchstart', () => {});
    }

    public setUrl(url: string): this {
        super.setUrl(url);

        // after the image is loaded, recalculate the transformation matrix
        this.once('load', () => {
            this._reset();
        });
        return this;
    }

    public setProps(position: L.LatLng, width: number, height: number, angle: number) {
        this.position = position;
        this.width = width;
        this.height = height;
        this.angle = angle;

        this._reset();
    }

    protected _reset() {
        if (!this._map) {
            return;
        }

        const image = this._image;

        const center = this._map.latLngToLayerPoint(this.position);

        const offsetPosition = offset(this.position)([this.width, this.height]);

        // construct a bounds object. Not the actual bounds,
        // but with the right size
        const bounds = new L.Bounds(center, this._map.latLngToLayerPoint(offsetPosition));
        const size = bounds.getSize();

        // Construct a transformation matrix for the translate, zoom and rotation
        //
        // Translation matrix
        //     [  1  ,  0  ,  tx ]
        // T = [  0  ,  1  ,  ty ]
        //     [  0  ,  0  ,  1  ]
        //
        // Rotation matrix
        //     [cos a, -sin a, 0]
        // R = [sin a,  cos a, 0]
        //     [  0  ,  0    , 1]
        //
        // Scale matrix
        //     [  sx ,  0  ,  0 ]
        // S = [  0  ,  sy ,  0 ]
        //     [  0  ,  0  ,  1 ]
        //
        // Compound transformation matrix
        //                 [ sx*cos a, -sy*sin a, tx ]
        // C = T x R x S = [ sx*sin a,  sy*cos a, ty ]
        //                 [    0    ,     0    ,  1 ]

        const sx = size.x / image.width;
        const sy = size.y / image.height;
        const tx = center.x - image.width / 2;
        const ty = center.y - image.height / 2;
        const cosa = Math.cos(this.angle);
        const sina = Math.sin(this.angle);

        // the actual matrix (in the CSS matrix transform format)
        image.style.transform = `matrix(
            ${sx * cosa}, ${sx * sina},
            ${-sy * sina}, ${sy * cosa},
            ${tx}, ${ty}
        )`;
    }
}

export const createRotatedImageOverlay = (
    imgSrc: string | undefined,
    position: L.LatLng,
    width: number,
    height: number,
    angle: number,
    options: L.ImageOverlayOptions,
) => new RotatedImageOverlay(imgSrc, position, width, height, angle, options);
