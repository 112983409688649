// These namespace values are populated from the different app.config.[env].js files.

/**
 * Returns true if the application is running in production mode
 */
export function isProduction(): boolean {
    return process.env.NODE_ENV === 'production';
}

/**
 * Returns true if the application is running in dev environment
 */
export function isDev(): boolean {
    return window.appConfig.namespace === 'dev';
}

/**
 * Returns true if the application is running in local environment
 */
export function isLocal(): boolean {
    return window.appConfig.namespace === 'local';
}
