import type { Id } from 'app/core/persistence';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { convertMsrpToProjectCurrency, getItemIdMsrp } from '../selectors';
import type { ILocaleCurrencyProps } from './LocaleCurrency.container';
import { LocaleCurrency } from './LocaleCurrency.container';

interface IItemMsrpOwnProps {
    /**
     * The persisted item id for which to look up a MSRP
     */
    itemId: Id;
    /**
     * If true will multiply the returned MSRP with the quantity
     * of the item. Otherwise returns MSRP for one (1) item.
     */
    includeQuantity?: boolean;
}

function mapStateToProps(
    storeState: IStoreState,
    ownProps: IItemMsrpOwnProps,
): ILocaleCurrencyProps {
    const msrpInPriceListCurrency = getItemIdMsrp(storeState, ownProps);

    // Return nothing if there is no MSRP
    if (!msrpInPriceListCurrency) {
        return {};
    }

    const msrpInProjectCurrency = convertMsrpToProjectCurrency(storeState, msrpInPriceListCurrency);

    return {
        msrpInPriceListCurrency,
        msrpInProjectCurrency,
    };
}

/**
 * A component that fetches the MSRP of the provided
 * item id and displays it in the appropriate currency
 * and locale.
 */
export const ItemMsrp = connect(mapStateToProps)(LocaleCurrency);

ItemMsrp.displayName = 'ItemMsrp';
