import { trigonometry } from 'axis-webtools-util';
import type { IPiaSensorDevice, PiaId } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import type { IPiaItemWithPrice } from 'app/modules/common';
import { calculate } from 'app/modules/common';
import { compact, flatMap, flatten, zip } from 'lodash-es';
import { createSelector } from 'reselect';
import type { ICameraFilter } from '../models';
import { ProductSorter } from '../models';
import {
    getCameraFilter,
    getDeviceGroup,
    getFilteredProducts,
    getHorizontalFOVRadians,
} from './getProductsForDeviceGroup';
import { isPixelDensityFulfilled } from '../utils';
import type { IStoreState } from 'app/store';
import { getCurrentAvailableAcaps } from './getAcaps';
import type { IAcap } from '../models/cameras/IAcap';

const MAX_RECOMMENDED_ITEMS = 3;
const axisObjectAnalyticsPiaId = 62078;
const axisPerimeterDefenderPiaIds = { paperLicense: 22681, eLicense: 22683 };

interface ICamerasBySeries {
    seriesM: IPiaItemWithPrice<IPiaSensorDevice>[];
    seriesP: IPiaItemWithPrice<IPiaSensorDevice>[];
    seriesQ: IPiaItemWithPrice<IPiaSensorDevice>[];
    withLenses: IPiaItemWithPrice<IPiaSensorDevice>[];
}

const getDesiredApplications = (state: IStoreState) =>
    state.deviceSelector.cameraFilter.desiredCamera.applications;

export const getDesiredImportantACAP = createSelector(
    [getDesiredApplications, getCurrentAvailableAcaps],
    (desiredApplications, availableApplications) => {
        const hasObjectAnalytics = desiredApplications.includes(axisObjectAnalyticsPiaId);
        const hasPerimeterDefender =
            desiredApplications.includes(axisPerimeterDefenderPiaIds.paperLicense) ||
            desiredApplications.includes(axisPerimeterDefenderPiaIds.eLicense);

        // Only return important ACAP if strictly one is selected
        if (
            (!hasObjectAnalytics && !hasPerimeterDefender) ||
            (hasObjectAnalytics && hasPerimeterDefender)
        ) {
            return undefined;
        }
        if (hasObjectAnalytics) {
            return availableApplications.find(
                (acap) => acap.piaIds[0] === axisObjectAnalyticsPiaId,
            );
        }
        if (hasPerimeterDefender) {
            return availableApplications.find(
                (acap) =>
                    acap.piaIds[0] === axisPerimeterDefenderPiaIds.paperLicense ||
                    acap.piaIds[0] === axisPerimeterDefenderPiaIds.eLicense,
            );
        }
    },
);

/**
 * Tries to find one camera each from M, P and Q series with FoV closest to current filter settings.
 */
export const getRecommendedProductIds = createSelector(
    [
        getFilteredProducts,
        getDeviceGroup,
        getHorizontalFOVRadians,
        getCameraFilter,
        getDesiredImportantACAP,
    ],
    (products, deviceGroup, horizontalFOVRadians, cameraFilter, importantAcap): PiaId[] => {
        const nonDiscontinuedProducts = products.filter(
            (product) => !isDiscontinued(product.piaItem),
        );

        if (deviceGroup === 'cameras') {
            const cameras = nonDiscontinuedProducts as IPiaItemWithPrice<IPiaSensorDevice>[];
            const sortedCameraGroups = getSortedCameraGroups(
                importantAcap,
                cameras,
                horizontalFOVRadians,
            );
            const sortedCameras = flatMap(
                sortedCameraGroups,
                (group) => group.products as IPiaItemWithPrice<IPiaSensorDevice>[],
            );
            return getRecommendedCameras(sortedCameras, cameraFilter);
        }
        return [];
    },
);

const getSortedCameraGroups = (
    desiredImportantAcap: IAcap | undefined,
    cameras: IPiaItemWithPrice<IPiaSensorDevice>[],
    horizontalFOVRadians: number | undefined,
) => {
    const fovRadians = horizontalFOVRadians ?? trigonometry.toRadians(55);
    if (desiredImportantAcap?.piaIds[0] === axisObjectAnalyticsPiaId) {
        return ProductSorter.sortByAOA(cameras, fovRadians);
    }
    if (
        desiredImportantAcap?.piaIds[0] === axisPerimeterDefenderPiaIds.paperLicense ||
        desiredImportantAcap?.piaIds[0] === axisPerimeterDefenderPiaIds.eLicense
    ) {
        return ProductSorter.sortByAPD(cameras, fovRadians);
    }
    return ProductSorter.sortByFoV(cameras, fovRadians);
};

const getRecommendedCameras = (
    sortedSensorsWithPrice: Array<IPiaItemWithPrice<IPiaSensorDevice>>,
    cameraFilter: ICameraFilter,
): PiaId[] => {
    if (sortedSensorsWithPrice.length <= MAX_RECOMMENDED_ITEMS) {
        return getRecommendedIds(sortedSensorsWithPrice);
    }

    const camerasBySeries = groupCamerasBySeries(sortedSensorsWithPrice, cameraFilter);
    const { seriesM, seriesP, seriesQ, withLenses } = camerasBySeries;

    if (sortedCamerasIsNotOfSeriesMPQ(seriesM.length, seriesP.length, seriesQ.length)) {
        // Recommend the first three cameras of the sorted array
        return getRecommendedIds(sortedSensorsWithPrice.slice(0, 3));
    }

    const recommendedCameras: Array<IPiaItemWithPrice<IPiaSensorDevice>> = compact(
        // give cameras with lenses lowest priority by placing them last in the array
        flatten([...zip(seriesM, seriesP, seriesQ), withLenses]),
    ).slice(0, MAX_RECOMMENDED_ITEMS);

    return getRecommendedIds(recommendedCameras);
};

/**
 * Groups cameras by M, P and Q series but separates cameras requiring accessory lenses into its own array.
 * @param sortedCamerasWithMsrp
 * @param cameraFilter
 * @returns Cameras grouped by M, P and Q series, with cameras requiring accessory lenses separated.
 */
const groupCamerasBySeries = (
    sortedCamerasWithMsrp: Array<IPiaItemWithPrice<IPiaSensorDevice>>,
    cameraFilter: ICameraFilter,
): ICamerasBySeries => {
    const cameraSeries: ICamerasBySeries = {
        seriesM: [],
        seriesP: [],
        seriesQ: [],
        withLenses: [],
    };
    sortedCamerasWithMsrp.forEach((camera) => {
        const isCameraWithLens = cameraNeedsAccessoryLens(cameraFilter, camera);

        if (isCameraWithLens) {
            return cameraSeries.withLenses.push(camera);
        }

        const seriesInitial =
            camera.piaItem.properties.series &&
            camera.piaItem.properties.series.charAt(0).toUpperCase();

        switch (seriesInitial) {
            case 'M':
                return cameraSeries.seriesM.push(camera);
            case 'P':
                return cameraSeries.seriesP.push(camera);
            case 'Q':
                return cameraSeries.seriesQ.push(camera);
            default:
                break;
        }
    });
    return cameraSeries;
};

const cameraNeedsAccessoryLens = (
    cameraFilter: ICameraFilter,
    sensor: IPiaItemWithPrice<IPiaSensorDevice>,
): boolean => {
    const { desiredCamera } = cameraFilter;
    const {
        installationHeight,
        distanceToTarget,
        targetHeight,
        horizontalFOVRadians,
        isSceneFilterActive,
    } = desiredCamera;

    const trueDistance = calculate.trueDistance(installationHeight, distanceToTarget, targetHeight);

    if (!isPixelDensityFulfilled(sensor.piaItem.properties, desiredCamera, trueDistance)) {
        return true;
    }

    const maxHorizontalFovRadians = trigonometry.toRadians(
        sensor.piaItem.properties.maxHorizontalFOV,
    );

    const desiredFoVRadians = horizontalFOVRadians;

    const fovNotFulfilled = isSceneFilterActive && desiredFoVRadians > maxHorizontalFovRadians;

    return fovNotFulfilled;
};

const getRecommendedIds = (
    camerasWithMsrp: Array<IPiaItemWithPrice<IPiaSensorDevice>>,
): PiaId[] => {
    return camerasWithMsrp.map((camera) => camera.piaItem.id);
};

const sortedCamerasIsNotOfSeriesMPQ = (nbrOfM: number, nbrOfP: number, nbrOfQ: number) => {
    return nbrOfM + nbrOfP + nbrOfQ === 0;
};
