import { injectable } from 'inversify';
import { POUCHDB_ERROR_REASON_QUOTA_EXCEEDED } from 'app/errorReporterMiddleware';
import type { IExportFileV1 } from './fileFormats/IExportFileV1';
import type { ImportErrorReason } from './ImportError';
import { ImportError } from './ImportError';
import { EntityImportService } from './EntityImport.service';
import type { IEntity, Id, IProjectEntity } from '../../userDataPersistence';
import {
    ProjectDbOrigin,
    PersistenceMemoryRepository,
    MigrationService,
    EntitySettings,
    ProjectRepository,
} from '../../userDataPersistence';

import { eventTracking } from 'app/core/tracking';
import { NameGenerationService } from '../NameGeneration.service';
import { AppConstants } from 'app/AppConstants';
import { FloorPlanService } from '../FloorPlan.service';
import { toErrorMessage } from '../../utils';
import { isCustomPouchError } from 'app/utils';

@injectable()
export class ProjectImportService {
    constructor(
        private migrationService: MigrationService,
        private entityImportService: EntityImportService,
        private entitySettings: EntitySettings,
        private projectRepository: ProjectRepository,
        private nameGenerationService: NameGenerationService,
    ) {}

    public importProject(
        file: File,
        excludeFloorPlanMaps: boolean,
        newProjectDbOrigin: ProjectDbOrigin,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        return this.importAsd2Project(
            file,
            excludeFloorPlanMaps,
            newProjectDbOrigin,
            usedImageQuota,
            totalImageQuota,
        );
    }

    public async hasFloorPlans(file: File): Promise<boolean> {
        if (this.isAsd1Project(file.name)) {
            return Promise.resolve(false);
        } else {
            try {
                // need to catch invalid files here
                const fileData = await this.parseFile(file);
                return (
                    Array.isArray(fileData.children) &&
                    fileData.children.some((entity) => FloorPlanService.isFloorPlanMapType(entity))
                );
            } catch (error) {
                throw this.getFileReadError(file.name);
            }
        }
    }

    public async isLocalProject(file: File): Promise<boolean> {
        try {
            // need to catch invalid files here
            const fileData = await this.parseFile(file);
            const projectEntity = fileData.project as Partial<IProjectEntity>;

            return projectEntity.projectDbOrigin
                ? projectEntity.projectDbOrigin === ProjectDbOrigin.asdLocalUserData
                : false;
        } catch (error) {
            throw this.getFileReadError(file.name);
        }
    }

    private isAsd1Project(filename: string): boolean {
        return filename.endsWith('.asdp');
    }

    private async importAsd2Project(
        file: File,
        excludeFloorPlanMaps: boolean,
        newProjectDbOrigin: ProjectDbOrigin,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        const fileData = await this.parseFile(file);

        /**
         * Right now we only have 1 version to handle which makes
         * the verification simpler here. When more formats are
         * added the verification has to be more thorough.
         *
         * We validate all criteria in the file to ensure that it
         * is not corrupt, or some newer file format is added in a
         * newer version.
         */
        if (
            fileData.fileVersion === 1 &&
            fileData.fileType === 'exportedProject' &&
            fileData.application === 'SiteDesigner2' &&
            fileData.project != null &&
            Array.isArray(fileData.children)
        ) {
            if (excludeFloorPlanMaps) {
                fileData.children = await FloorPlanService.filterOutFloorPlanMaps(
                    fileData.children,
                );
            }

            const fileEntities = [fileData.project, ...fileData.children];
            const memoryRepository = new PersistenceMemoryRepository(
                fileEntities,
                this.entitySettings,
            );

            // check if floorplans included and calculate total size of images in that case
            if (newProjectDbOrigin !== ProjectDbOrigin.asdLocalUserData) {
                const floorPlans: IEntity[] = await memoryRepository.getAll('floorPlan');
                if (
                    floorPlans.length &&
                    totalImageQuota != undefined &&
                    usedImageQuota != undefined
                ) {
                    const totalImageSize = FloorPlanService.getApproximateSizeInBytes(floorPlans);
                    const notEnoughImageSpace =
                        totalImageQuota - usedImageQuota - totalImageSize < 0;
                    if (notEnoughImageSpace) {
                        return this.handleImportError(
                            'ASD2',
                            'imageQuotaFull',
                            'There is not enough space on the image server',
                        );
                    }
                }
            }

            const importedProjectName = await this.importedProjectName(file);

            try {
                await this.migrationService.migrateAll(memoryRepository);
                return await this.entityImportService.importProject(
                    fileData.project._id,
                    memoryRepository,
                    importedProjectName,
                    newProjectDbOrigin,
                );
            } catch (error) {
                if (isCustomPouchError(error)) {
                    if (error.reason === POUCHDB_ERROR_REASON_QUOTA_EXCEEDED) {
                        return this.handleImportError(
                            'ASD2',
                            'diskSpaceFull',
                            toErrorMessage(error),
                        );
                    }
                }
                return this.handleImportError('ASD2', 'entityImportFailed', toErrorMessage(error));
            }
        } else {
            return this.handleImportError(
                'ASD2',
                'invalidFileFormat',
                'The file is not recognized as a Site Designer 2 file',
            );
        }
    }

    private async parseFile(file: File): Promise<IExportFileV1> {
        const fileData: string = await this.getFileContents(file);
        return JSON.parse(fileData) as IExportFileV1;
    }

    private getFileContents(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result && typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject(this.getFileReadError(file.name));
                }
            };

            reader.onerror = () => {
                reject(this.getFileReadError(file.name));
            };

            reader.readAsText(file);
        });
    }

    private getFileReadError(filename: string): ImportError {
        const errorMessage = `Unable to read from the file '${filename}'`;
        return new ImportError('fileContentsUnreadable', errorMessage);
    }

    private getFilenameWithoutSuffix(filename: string): string {
        return filename.split('.').slice(0, -1).join('.');
    }

    private async importedProjectName(file: File): Promise<string> {
        const projectName = this.getFilenameWithoutSuffix(file.name);
        const existingProjects = await this.projectRepository.getAll();
        const existingNames = existingProjects.map((existingProject) => existingProject.name);
        return this.nameGenerationService.getName(
            projectName,
            existingNames,
            AppConstants.projectNameMaxLength,
        );
    }

    private handleImportError(
        importType: 'ASD1' | 'ASD2',
        reason: ImportErrorReason,
        message: string,
    ): never {
        eventTracking.logError(
            `Failed to import ${importType === 'ASD1' ? 'ASD 1' : 'ASD 2'} project - ${message}`,
            'ProjectImportService',
        );
        throw new ImportError(reason, message);
    }
}
