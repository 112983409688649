import { nameComparator } from 'app/utils';
import type { RecordingSolutionType } from 'app/core/persistence';
import { flatMap, groupBy } from 'lodash-es';
import type {
    ISystemConfiguration,
    IRecorderConfiguration,
    ISwitchConfiguration,
    ISDCardComponent,
    IRecordingSolution,
    IRecordingSolutionItem,
} from './common';

/**
 * Creates a summarized recording solution from a recommendation
 *
 * @param recommendation - An array of recorderConfigurations
 * @returns summarized recording solution
 *
 * Example output:
 * {
 *   name: 'AXIS S11/S12 System',
 *   description: 'AXIS solution with separate network switches',
 *   items: [
 *      {
 *          piaId: 48647,
 *          name: 'AXIS S1116',
 *          accessories: [
 *              {
 *                  piaId: 25342,
 *                  name: 'AXIS ... Hard drive 6 TB',
 *              }
 *          ],
 *          quantity: 2,
 *      },
 *      {
 *          piaId: 25304,
 *          name: 'AXIS ... License',
 *          quantity: 6,
 *      },
 *      {
 *          piaId: 40477,
 *          name: 'AXIS T8524 ... Switch',
 *          quantity: 1,
 *      },
 *   ]
 * }
 */
export const toRecordingSolution = (
    name: string,
    description: string,
    recommendation: ISystemConfiguration | null,
    recordingSolutionId: RecordingSolutionType,
): IRecordingSolution | null => {
    if (recommendation === null) {
        return null;
    }
    const recorders = Object.values(
        groupBy(recommendation.recorders, ({ recorder, disks }) =>
            [recorder.id, ...disks.map(({ id }) => id)].join(','),
        ),
    )
        .map((group) => {
            const recorderConfig = group[0];

            return {
                piaId: recorderConfig.recorder.id,
                name: recorderConfig.recorder.name,
                piaItem: recorderConfig.recorder,
                accessories: Object.values(groupBy(recorderConfig.disks, 'id')).map((diskGroup) => {
                    const disk = diskGroup[0];
                    return {
                        piaId: disk.id,
                        name: disk.name,
                        quantity: diskGroup.length,
                        piaItem: disk,
                    };
                }),
                quantity: group.length,
            };
        })
        .sort(nameComparator);

    const switches = Object.values(
        groupBy(
            recommendation.switches
                .filter(
                    (switchConfig) =>
                        // filter out internal switches
                        switchConfig.recorders.length > 1 ||
                        switchConfig.networkSwitch !== switchConfig.recorders[0].recorder,
                )
                .map(({ networkSwitch }) => networkSwitch),
            'id',
        ),
    )
        .map((group) => {
            const item = group[0];
            return {
                piaId: item.id,
                name: item.name,
                piaItem: item,
                quantity: group.length,
            };
        })
        .sort(nameComparator);

    const licenses = Object.values(
        groupBy(
            flatMap(recommendation.recorders, (recorderConfig) => recorderConfig.licenses),
            'id',
        ),
    )
        .map((group) => {
            const item = group[0];
            return {
                piaId: item.id,
                name: item.name,
                piaItem: item,
                quantity: group.length,
            };
        })
        .sort(nameComparator);

    const desktopTerminals = recommendation.desktopTerminal
        ? [
              {
                  piaId: recommendation.desktopTerminal.id,
                  name: recommendation.desktopTerminal.name,
                  piaItem: recommendation.desktopTerminal,
                  quantity: 1,
              },
          ]
        : [];

    const items = [...recorders, ...desktopTerminals, ...switches, ...licenses];

    return {
        name,
        description,
        items,
        id: recordingSolutionId,
    };
};

/**
 * Return a human readable graph visualization of a recording solution recommendation
 *
 * @remarks
 * This function is intended for debugging purposes
 *
 * @param recommendation - An array of recorderConfigurations
 * @returns stringified recommendation
 *
 * Example:
 *
 * Recorder: AXIS S1116 Racked
 *  |   Extra licenses: 16
 *  |
 *  |
 *  +---Switch: AXIS T8524 PoE+ Network Switch
 *  |      |
 *  |      |   DEVICES (24)
 *  |      +---Camera #1
 *  |      +---  ...
 *  |      +---Camera #24
 *  |
 *  +---Switch: AXIS T8508 PoE+ Network Switch
 *         |
 *         |   DEVICES (8)
 *         +---Camera #24
 *         +---  ...
 *         +---Camera #32
 */
export const stringifySystemGraph = (recommendation: ISystemConfiguration | null) => {
    if (recommendation === null) return '';

    const recorderLines = flatMap(recommendation.recorders, (recorderConfig) =>
        stringifyRecorder(recorderConfig),
    );
    const switchLines = flatMap(recommendation.switches, (switchConfig) =>
        stringifySwitch(switchConfig),
    );

    const lines = [...recorderLines, ...switchLines];
    return lines.filter((line) => line !== null).join('\n');
};

/**
 * Return a human readable graph visualization of a single recorder configuration
 *
 * @param recorderConfig - the recorder configuration to stringify
 * @returns stringified recorder configuration
 */
const stringifyRecorder = (recorderConfig: IRecorderConfiguration) => {
    const recorderLines = [
        `Recorder: ${recorderConfig.recorder.name}`,
        recorderConfig.licenses.length > 0
            ? `   |   Extra licenses: ${recorderConfig.licenses.length}`
            : null,
        recorderConfig.disks.length > 0
            ? `   |   Disks: ${recorderConfig.disks.map((item) => item.name).join(', ')}`
            : null,
        `   |`,
        `   |   DEVICES (${recorderConfig.devices.length})`,
        ...recorderConfig.devices.map((item) => `   +---${item.name}`),
        ``,
    ];

    return recorderLines;
};

/**
 * Return a human readable graph visualization of a single switch configuration
 *
 * @param switchConfig - the switch configuration to stringify
 * @returns stringified switch configuration
 */
const stringifySwitch = (switchConfig: ISwitchConfiguration) => {
    const switchLines = [
        `Switch: ${switchConfig.networkSwitch.name}`,
        `   |`,
        `   |   DEVICES (${switchConfig.devices.length})`,
        ...switchConfig.devices.map((item) => `   +---${item.name}`),
        ``,
    ];

    return switchLines;
};

/**
 * Converts a IComponentWithQuantity to IRecordingSolutionItem, used with sd-cards.
 */
export const toRecordingSolutionItem = (
    sdCardComponent: ISDCardComponent[],
): IRecordingSolutionItem[] =>
    sdCardComponent.map((item) => {
        return {
            piaId: item.component.id,
            name: item.component.name,
            quantity: item.quantity,
            piaItem: item.component,
            deviceIds: item.deviceIds,
        };
    });
