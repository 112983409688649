import * as React from 'react';
import { t } from 'app/translate';
import { Border, Box, Stack, Icon, Text } from 'app/components';

interface IAddAccessoryButtonTriggerProps {
    numberOfSelectedAccessories: number;
    style: 'large' | 'small';
}

export const AddAccessoryButtonTrigger: React.FC<IAddAccessoryButtonTriggerProps> = ({
    numberOfSelectedAccessories,
    style,
}) => {
    const largeTrigger = (
        <Border color="blackOpacity" radius="5px" shadow="0 0 8px rgba(0,0,0,.15)">
            <Box
                color={numberOfSelectedAccessories > 0 ? 'yellow1' : 'white'}
                alignItems="center"
                justifyContent="center"
                padding="half"
                flex="dontShrink"
            >
                <Stack spacing="half" justifyContent="center" alignItems="center">
                    {numberOfSelectedAccessories > 0 ? (
                        <Text
                            semiBold
                            color={numberOfSelectedAccessories > 0 ? 'yellowText' : 'blue'}
                        >
                            {numberOfSelectedAccessories}
                        </Text>
                    ) : (
                        <Icon opaque size="ms" icon="add" color="blue" />
                    )}
                    <Text
                        testId="device_accessories"
                        semiBold
                        color={numberOfSelectedAccessories > 0 ? 'yellowText' : 'blue'}
                    >
                        {t.accessories}
                    </Text>
                </Stack>
            </Box>
        </Border>
    );

    const smallTrigger = (
        <Box alignItems="center" justifyContent="center">
            <Border color="blue" radius="4px">
                <Box
                    color={numberOfSelectedAccessories > 0 ? 'blue' : undefined}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon
                        title={t.addAccessories}
                        icon="category"
                        color={numberOfSelectedAccessories > 0 ? 'white' : 'blue'}
                        opaque
                    />
                </Box>
            </Border>
        </Box>
    );

    switch (style) {
        case 'large':
            return largeTrigger;
        case 'small':
            return smallTrigger;
    }
};

AddAccessoryButtonTrigger.displayName = 'AddAccessoryButtonTrigger';
