interface IItemWithName {
    name: string;
}

interface IItemWithText {
    text: string;
}

interface IItemWithPiaItem {
    piaItem: IItemWithName;
}

export const nameComparator = (a: IItemWithName, b: IItemWithName): number =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true });

export const nameComparatorReverse = (a: IItemWithName, b: IItemWithName): number =>
    nameComparator(b, a);

export const textComparator = (a: IItemWithText, b: IItemWithText) =>
    a.text.toLowerCase().localeCompare(b.text.toLowerCase(), undefined, { numeric: true });

export const piaItemComparator = (a: IItemWithPiaItem, b: IItemWithPiaItem) =>
    a.piaItem.name
        .toLowerCase()
        .localeCompare(b.piaItem.name.toLowerCase(), undefined, { numeric: true });
