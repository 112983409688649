import { getProductsWithPrices } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

const getMsrp = (storeState: IStoreState) => storeState.quotation.msrpProps;

export const getMsrpForQuotation = createSelector(
    [getMsrp, getProductsWithPrices],
    (msrp, products) => {
        // Only show msrp if any selected product has msrp
        const hasMsrpProduct = products.some((product) => product.msrp?.price);

        if (hasMsrpProduct) {
            return msrp;
        }
        return undefined;
    },
);
