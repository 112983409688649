import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';
import { CameraType } from 'app/core/persistence';

interface ICameraTypeFilterProps {
    selectedCameraType?: CameraType;
    onClick(cameraType: CameraType): void;
}

export const CameraTypeFilter: React.FC<ICameraTypeFilterProps> = ({
    selectedCameraType,
    onClick,
}) => {
    return (
        <DropDown
            trigger={
                <PillButton
                    minWidth="100px"
                    text={
                        selectedCameraType
                            ? t.cameraTypeGROUP[selectedCameraType]
                            : t.cameraSelectorCameraType
                    }
                    icon="keyboard_arrow_down"
                    selected={!!selectedCameraType}
                />
            }
            contents={
                <>
                    <DropDownMenuButton
                        label={t.cameraTypeGROUP.fixed}
                        selected={selectedCameraType === CameraType.Fixed}
                        onClick={() => onClick(CameraType.Fixed)}
                    />
                    <DropDownMenuButton
                        label={t.cameraTypeGROUP.dome}
                        selected={selectedCameraType === CameraType.Dome}
                        onClick={() => onClick(CameraType.Dome)}
                    />
                    <DropDownMenuButton
                        label={t.cameraTypeGROUP.ptz}
                        selected={selectedCameraType === CameraType.PTZ}
                        onClick={() => onClick(CameraType.PTZ)}
                    />
                    <DropDownMenuButton
                        label={t.cameraTypeGROUP.thermal}
                        selected={selectedCameraType === CameraType.Thermal}
                        onClick={() => onClick(CameraType.Thermal)}
                    />
                    <DropDownMenuButton
                        label={t.cameraTypeGROUP.explosionProtected}
                        selected={selectedCameraType === CameraType.ExplosionProtected}
                        onClick={() => onClick(CameraType.ExplosionProtected)}
                    />
                </>
            }
        />
    );
};

CameraTypeFilter.displayName = 'CameraTypeFilter';
