import * as React from 'react';
import { t } from 'app/translate';
import {
    DropDownMenu,
    Stack,
    DropDownMenuButton,
    DropDownMenuDelete,
    TextInput,
    Select,
    NumberInput,
    Text,
    Box,
    Icon,
} from 'app/components';
import type { CustomItemCategory, Id, ICustomItem } from 'app/core/persistence';
import { CustomItemCategoryType, CustomItemService } from 'app/core/persistence';
import { getCategoryName } from '../services';
import { AppConstants } from 'app/AppConstants';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';
import { getCustomItem } from '../selectors';

interface ICustomItemListRowOwnProps {
    customItemId: Id;
    isSelected?: boolean;
    onFocus?(id?: string | React.SyntheticEvent): void;
}

interface ICustomItemListRowProps extends ICustomItemListRowOwnProps {
    customItem: ICustomItem | undefined;
    locked: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ICustomItemListRowOwnProps,
): ICustomItemListRowProps => {
    const customItem = getCustomItem(ownProps.customItemId)(storeState);

    return {
        customItem,
        locked: !!customItem?.locked,
        ...ownProps,
    };
};

class CustomItemListRowContainer extends React.Component<ICustomItemListRowProps> {
    private categories = Object.keys(CustomItemCategoryType).map((category) => ({
        text: getCategoryName(category as CustomItemCategory),
        value: category,
    }));
    private customItemService: CustomItemService;

    constructor(props: ICustomItemListRowProps) {
        super(props);
        this.customItemService = ServiceLocator.get(CustomItemService);
    }

    public render() {
        const { onFocus, isSelected, customItem, locked } = this.props;

        if (!customItem) {
            return null;
        }

        return (
            <tr onFocus={onFocus} onClick={onFocus} aria-selected={isSelected}>
                <td style={{ width: '40px', paddingRight: '4px' }}>
                    {customItem.partnerProductId && (
                        <Box justifyContent="end" alignItems="center" direction="row">
                            <Icon
                                size="sm"
                                icon="handshake"
                                testId={`handshake_icon_${customItem.partnerProductId}`}
                            />
                        </Box>
                    )}
                </td>
                <td>
                    {customItem.partnerProductId !== undefined ? (
                        <Box paddingLeft="quart">
                            <Text whiteSpace="nowrap">{customItem.name}</Text>
                        </Box>
                    ) : (
                        <TextInput
                            disabled={locked}
                            value={customItem.name}
                            onChange={this.onNameUpdate}
                            maxLength={AppConstants.deviceNameMaxLength}
                            noBorder
                        />
                    )}
                </td>
                <td>
                    {customItem.partnerProductId !== undefined ? (
                        <Box paddingLeft="quart">
                            <Text whiteSpace="nowrap">{customItem.vendor}</Text>
                        </Box>
                    ) : (
                        <TextInput
                            disabled={locked || customItem.partnerProductId !== undefined}
                            value={customItem.vendor}
                            onChange={this.onVendorUpdate}
                            maxLength={AppConstants.deviceNameMaxLength}
                            noBorder
                        />
                    )}
                </td>
                <td>
                    {customItem.partnerProductId !== undefined ? (
                        <Box paddingLeft="quart">
                            <Text whiteSpace="nowrap">{customItem.partNumber}</Text>
                        </Box>
                    ) : (
                        <TextInput
                            disabled={locked}
                            value={customItem.partNumber}
                            onChange={this.onPartNumberUpdate}
                            maxLength={AppConstants.deviceNameMaxLength}
                            noBorder
                        />
                    )}
                </td>
                <td>
                    {customItem.partnerProductId !== undefined ? (
                        <Box paddingLeft="quart">
                            <Text whiteSpace="nowrap">{getCategoryName(customItem.category)}</Text>
                        </Box>
                    ) : (
                        <Select
                            disabled={locked}
                            options={this.categories}
                            value={customItem.category}
                            onChange={this.onCategoryUpdate}
                            fillWidth
                            dropDownMinWidth={237}
                        />
                    )}
                </td>
                <td>
                    <NumberInput
                        testId={`input_number${customItem.name}`}
                        disabled={locked}
                        changeCriteria="blur"
                        min={1}
                        max={AppConstants.componentQuantityMax}
                        value={customItem.quantity}
                        onChange={this.onQuantityUpdate}
                        noBorder
                        hideSpinner={!this.props.isSelected}
                    />
                </td>
                <td>
                    <Stack testId="selctDdm" justifyContent="end">
                        {this.renderCustomItemDropDown()}
                    </Stack>
                </td>
            </tr>
        );
    }

    private renderCustomItemDropDown = () =>
        this.props.customItem ? (
            <DropDownMenu testId={`${this.props.customItem.name}_more_options`}>
                <DropDownMenuButton
                    disabled={
                        this.props.locked || this.props.customItem.partnerProductId !== undefined
                    }
                    testId="btn_duplicate"
                    label={t.duplicate}
                    icon="content_copy"
                    onClick={this.onDuplicate}
                />
                <DropDownMenuDelete
                    testId="btn_delete"
                    disabled={this.props.locked}
                    onDelete={this.onDelete}
                />
            </DropDownMenu>
        ) : null;

    private onNameUpdate = (name: string) => {
        if (name.trim()) {
            this.customItemService.updateCustomItem(this.props.customItemId, { name });
        }
    };

    private onVendorUpdate = (vendor: string) => {
        if (vendor.trim()) {
            this.customItemService.updateCustomItem(this.props.customItemId, { vendor });
        }
    };

    private onPartNumberUpdate = (partNumber: string) => {
        if (partNumber.trim()) {
            this.customItemService.updateCustomItem(this.props.customItemId, { partNumber });
        }
    };

    private onCategoryUpdate = (category: CustomItemCategory) => {
        if (category.trim()) {
            this.customItemService.updateCustomItem(this.props.customItemId, { category });
        }
    };

    private onQuantityUpdate = (quantity: number) => {
        this.customItemService.updateCustomItem(this.props.customItemId, { quantity });
    };

    private onDelete = () => {
        eventTracking.logUserEvent('Custom Items', 'Delete Custom Item');
        this.customItemService.deleteCustomItem(this.props.customItemId);
    };

    private onDuplicate = () => {
        eventTracking.logUserEvent('Custom Items', 'Duplicate Custom Item');
        this.customItemService.duplicateCustomItem(this.props.customItemId);
    };
}

export const CustomItemListRow = connect(mapStateToProps)(CustomItemListRowContainer);
