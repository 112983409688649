import { injectable } from 'inversify';
import type {
    Id,
    IScheduleModel,
    IBaseProfileModel,
    IItemEntity,
    IPersistence,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import { CurrentProjectService } from 'app/core/persistence';
import { ProfileBandwidthStorageEstimateService } from './bandwidth/ProfileBandwidthStorageEstimate.service';
import type { IPiaEncoder } from 'app/core/pia';
import { format } from 'axis-webtools-util';
import type {
    IBandwidthStorageAttachedCameraEstimateModel,
    IBandwidthStorageEncoderEstimateModel,
    IBandwidthStorageRequirementsModel,
} from '../models';
import { ScenarioService } from '../../profile/services';
import type { Frequency } from '../../models';

@injectable()
export class EncoderEstimateService {
    constructor(
        private scenarioService: ScenarioService,
        private profileBandwidthStorageEstimateService: ProfileBandwidthStorageEstimateService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public getEncoderEstimate(
        projectZipSetting: ProjectZipType,
        encoder: IPersistence<IItemEntity>,
        product: IPiaEncoder,
        frequency: Frequency,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        getMergedProfileFromDevice: (
            item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ) => IBaseProfileModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
    ): IBandwidthStorageEncoderEstimateModel {
        if (!encoder.properties.encoder) {
            throw Error(`Item is not an encoder`);
        }

        const analogCameras = this.currentProjectService.getDeviceChildren(
            encoder._id,
            'analogCamera',
        );

        const attachedCamerasEstimates = this.getAttachedCamerasEstimates(
            projectZipSetting,
            analogCameras,
            product,
            frequency,
            projectBandwidthVersion,
            encoder.quantity,
            getSchedule,
            getMergedProfileFromDevice,
        );

        return {
            type: 'encoder',
            perAttachedCamera: attachedCamerasEstimates,
            perEncoder: this.getPerEncoderEstimate(attachedCamerasEstimates),
            total: this.getEncoderTotalEstimate(attachedCamerasEstimates),
            channels: this.getAmountOfAnalogCameras(analogCameras),
            triggeredRecording: undefined,
            continuousRecording: undefined,
        };
    }

    private getAttachedCamerasEstimates(
        projectZipSetting: ProjectZipType,
        attachedChannels: IPersistence<IItemEntity>[],
        product: IPiaEncoder,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        encoderQuantity: number,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        getMergedProfileFromDevice: (
            item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ) => IBaseProfileModel | undefined,
    ): IBandwidthStorageAttachedCameraEstimateModel[] {
        const attachedCamerasEstimates: IBandwidthStorageAttachedCameraEstimateModel[] = [];

        for (const channel of attachedChannels) {
            if (!channel.properties.analogCamera) {
                throw new Error(`Invalid relation type or properties for ${channel.name}`);
            }

            const profile = getMergedProfileFromDevice(channel.properties.analogCamera);

            if (!profile) {
                break;
            }

            const scenario = this.scenarioService.getScenarioOrThrow(profile.scenario.scenarioId);
            const customBandwidth = channel.properties.analogCamera.profileOverride
                ? channel.properties.analogCamera.profileOverride.customBandwidth
                : undefined;
            const profileEstimate = this.profileBandwidthStorageEstimateService.getProfileEstimate(
                projectZipSetting,
                scenario,
                profile,
                product.properties,
                frequency,
                getSchedule,
                projectBandwidthVersion,
                customBandwidth,
            );

            const totalLiveViewBandwidth =
                profileEstimate.liveViewBandwidth * channel.quantity * encoderQuantity;

            const totalRecordingBandwidth =
                profileEstimate.recordingBandwidth * channel.quantity * encoderQuantity;

            const totalStorageInMB =
                profileEstimate.storageInMB * channel.quantity * encoderQuantity;

            attachedCamerasEstimates.push({
                perCamera: {
                    liveViewBandwidth: profileEstimate.liveViewBandwidth,
                    recordingBandwidth: profileEstimate.recordingBandwidth,
                    storageInMB: profileEstimate.storageInMB,
                    bandwidthInBps:
                        profileEstimate.recordingBandwidth + profileEstimate.liveViewBandwidth,
                    formattedLiveViewBandwidth: format.bandwidth(profileEstimate.liveViewBandwidth),
                    formattedRecordingBandwidth: format.bandwidth(
                        profileEstimate.recordingBandwidth,
                    ),
                    formattedStorage: format.storage(profileEstimate.storageInMB),
                    formattedBandwidth: format.bandwidth(
                        profileEstimate.recordingBandwidth + profileEstimate.liveViewBandwidth,
                    ),
                    retentionTime: profile.storage.retentionTime,
                },
                total: {
                    liveViewBandwidth: totalLiveViewBandwidth,
                    recordingBandwidth: totalRecordingBandwidth,
                    storageInMB: totalStorageInMB,
                    bandwidthInBps: totalRecordingBandwidth + totalLiveViewBandwidth,
                    formattedLiveViewBandwidth: format.bandwidth(totalLiveViewBandwidth),
                    formattedRecordingBandwidth: format.bandwidth(totalRecordingBandwidth),
                    formattedStorage: format.storage(totalStorageInMB),
                    formattedBandwidth: format.bandwidth(
                        totalRecordingBandwidth + totalLiveViewBandwidth,
                    ),
                    retentionTime: profile.storage.retentionTime,
                },
                channels: channel.quantity,
                triggeredRecording: profileEstimate.triggeredRecording,
                continuousRecording: profileEstimate.continuousRecording,
            });
        }

        return attachedCamerasEstimates;
    }

    private getPerEncoderEstimate(
        attachedChannelsEstimates: IBandwidthStorageAttachedCameraEstimateModel[],
    ): IBandwidthStorageRequirementsModel {
        const encoderEstimates: IBandwidthStorageRequirementsModel = Object.values(
            attachedChannelsEstimates,
        ).reduce(
            (summary, current) => {
                summary.liveViewBandwidth += current.perCamera.liveViewBandwidth * current.channels;
                summary.recordingBandwidth +=
                    current.perCamera.recordingBandwidth * current.channels;
                summary.storageInMB += current.perCamera.storageInMB * current.channels;
                summary.bandwidthInBps += summary.liveViewBandwidth + summary.recordingBandwidth;

                return summary;
            },
            {
                liveViewBandwidth: 0,
                recordingBandwidth: 0,
                storageInMB: 0,
                bandwidthInBps: 0,
                formattedLiveViewBandwidth: '',
                formattedRecordingBandwidth: '',
                formattedStorage: '',
                formattedBandwidth: '',
                retentionTime: 0,
            },
        );

        this.formatRequirements(encoderEstimates);

        return encoderEstimates;
    }

    private getEncoderTotalEstimate(
        attachedChannelsEstimates: IBandwidthStorageAttachedCameraEstimateModel[],
    ): IBandwidthStorageRequirementsModel {
        const encoderEstimates: IBandwidthStorageRequirementsModel = Object.values(
            attachedChannelsEstimates,
        ).reduce(
            (summary, current) => {
                summary.liveViewBandwidth += current.total.liveViewBandwidth;
                summary.recordingBandwidth += current.total.recordingBandwidth;
                summary.storageInMB += current.total.storageInMB;
                summary.bandwidthInBps = summary.liveViewBandwidth + summary.recordingBandwidth;

                return summary;
            },
            {
                liveViewBandwidth: 0,
                recordingBandwidth: 0,
                storageInMB: 0,
                bandwidthInBps: 0,
                formattedLiveViewBandwidth: '',
                formattedRecordingBandwidth: '',
                formattedStorage: '',
                formattedBandwidth: '',
                retentionTime: 0,
            },
        );

        this.formatRequirements(encoderEstimates);

        return encoderEstimates;
    }

    private formatRequirements(requirements: IBandwidthStorageRequirementsModel) {
        requirements.formattedLiveViewBandwidth = format.bandwidth(requirements.liveViewBandwidth);

        requirements.formattedRecordingBandwidth = format.bandwidth(
            requirements.recordingBandwidth,
        );

        requirements.formattedStorage = format.storage(requirements.storageInMB);

        requirements.formattedBandwidth = format.bandwidth(
            requirements.recordingBandwidth + requirements.liveViewBandwidth,
        );
    }

    private getAmountOfAnalogCameras(analogCameras: IPersistence<IItemEntity>[]): number {
        return analogCameras.reduce((current, analogCamera) => {
            return (current += analogCamera.quantity);
        }, 0);
    }
}
