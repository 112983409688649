import * as React from 'react';
import type { IBandwidthTotals } from '../../models';
import { t } from 'app/translate';
import { Alert, Box, Heading, ReadDirection, Stack, Table, Text } from 'app/components';
import { BandwidthTableRow } from './BandwidthTableRow.container';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { getFormattedBandwidth, getFormattedStorage } from 'app/modules/common';
import { getBandwidthDeviceIds } from '../../selectors';
import { SummaryInfo } from './SummaryInfo';

export interface IBandwidthReportSectionOwnProps {
    showTotals?: boolean;
    tableRef?: React.Ref<HTMLTableElement>;
}

export interface IBandwidthReportSectionProps {
    itemIds: Id[];
    totals: IBandwidthTotals | undefined;
    tableRef?: React.Ref<HTMLTableElement>;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IBandwidthReportSectionOwnProps,
): IBandwidthReportSectionProps => {
    return {
        tableRef: ownProps.tableRef,
        itemIds: getBandwidthDeviceIds(storeState),
        totals: ownProps.showTotals
            ? {
                  formattedStorage: getFormattedStorage(storeState),
                  formattedBandwidth: getFormattedBandwidth(storeState),
              }
            : undefined,
    };
};

const BandwidthReportSectionContainer: React.FunctionComponent<IBandwidthReportSectionProps> = ({
    itemIds,
    totals,
    tableRef,
}) => {
    if (itemIds.length <= 0) {
        return (
            <Box direction="column" spacing="panel">
                <Heading style="subheader">{t.estimatedBandwidth}</Heading>
                <Text faded>{t.bandwidthReportNoBandwidthDevices}</Text>
            </Box>
        );
    }

    const dataToDisplay = totals
        ? [
              {
                  title: t.totalBandwidth,
                  value: totals.formattedBandwidth.split(' ')[0],
                  subTitle: totals.formattedBandwidth.split(' ')[1],
              },
              {
                  title: t.totalStorage,
                  subTitle: totals.formattedStorage.split(' ')[1],
                  value: totals.formattedStorage.split(' ')[0],
              },
          ]
        : [];

    const productGroup = (itemId: Id) => {
        return <BandwidthTableRow key={itemId} itemId={itemId} />;
    };

    const totalRow = (storage: string, bandwidth: string) => (
        <tr>
            <td />
            <td />
            <td />
            <td />
            <td style={{ textAlign: 'end' }}>
                <b>{t.quotationTotal}:</b>
            </td>
            <td data-test-id="td_total_bandwidth">
                <Stack>
                    <ReadDirection direction="ltr">
                        <Text>{bandwidth}</Text>
                    </ReadDirection>
                </Stack>
            </td>
            <td data-test-id="td_total_storage" style={{ textAlign: 'end' }}>
                <Stack justifyContent="end">
                    <ReadDirection direction="ltr">
                        <Text>{storage}</Text>
                    </ReadDirection>
                </Stack>
            </td>
        </tr>
    );

    return (
        <Box direction="column" spacing="panel">
            <Heading style="subheader">{t.estimatedBandwidth}</Heading>
            <Table
                tableRef={tableRef}
                columnWidth={['20%', '30%', '5%', '15%', '10%', '10%', '10%']}
                omitLastLine
                rightAlignLastColumn
                headers={[
                    t.quotationProductName,
                    t.quotationModelName,
                    t.quotationQuantity,
                    t.quotationProfileName,
                    t.quotationStorageTime,
                    t.quotationEstimatedBandwidth,
                    t.quotationEstimatedStorage,
                ]}
            >
                {itemIds.map(productGroup)}

                {totals && totalRow(totals.formattedStorage, totals.formattedBandwidth)}
            </Table>
            <Box avoidPageBreakInside justifyContent="end">
                <Box justifyContent="end">
                    <SummaryInfo
                        backgroundColor="yellow"
                        items={dataToDisplay}
                        testId="system_properties_container"
                    />
                </Box>
            </Box>
            <Alert
                heading={t.bandwidthReportDisclaimerHeading}
                text={t.bandwidthReportDisclaimerText}
                level="warning"
                color="red"
            />
        </Box>
    );
};

export const BandwidthReportSection = connect(mapStateToProps)(BandwidthReportSectionContainer);
