import { injectable } from 'inversify';
import { trigonometry } from 'axis-webtools-util';
import type {
    IPiaCamera,
    IPiaItem,
    IPiaRelationPropertyItem,
    IPiaSensorUnit,
    PiaId,
} from 'app/core/pia';
import { PiaItemPacCategory, PiaItemService } from 'app/core/pia';
import type {
    Id,
    IDeviceAnalyticRange,
    IInstallationPointEntity,
    IItem,
    IItemEntity,
    IItemPropertiesEntity,
    IPersistence,
    UnitSystem,
} from 'app/core/persistence';
import {
    isCameraDoorStation,
    isMainUnit,
    getParentId,
    deviceTypeCheckers,
    ItemService,
    CurrentProjectService,
    InstallationPointService,
    ParentChildInstallationPointService,
    getDefaultProfileOverrideEntity,
    defaultPacFilter,
    isCamera,
} from 'app/core/persistence';
import { t } from 'app/translate';
import { ModalService } from 'app/modal';
import { AppStore } from 'app/store';
import { defaultColors } from 'app/core/common';
import { AccessoryService } from '../../accessory/services';
import { VirtualProductService } from './VirtualProducts.service';
import {
    IAddProductProps,
    IDesiredCamera,
    IEditCameraItem,
    IInstallationPointsFilter,
} from '../models';
import {
    combineCameraFilterFromAllInstallationPoints,
    desiredCameraUtils,
    isSelectedCameraSupportedByEditCameraItem,
    largestHorizontalFov,
    usesCorridorFormat,
} from '../utils';
import { getCompatibleApplicationsForPiaId } from '../selectors';

@injectable()
export class CamerasService {
    constructor(
        private itemService: ItemService,
        private accessoryService: AccessoryService,
        private installationPointService: InstallationPointService,
        private currentProjectService: CurrentProjectService,
        private parentChildInstallationPointService: ParentChildInstallationPointService,
        private modalService: ModalService,
        private piaCameraService: PiaItemService<IPiaCamera>,
        private piaItemService: PiaItemService<IPiaItem>,
        private appStore: AppStore,
        private virtualProductService: VirtualProductService,
    ) {}

    public async addOrUpdateDevice(
        desiredPiaCamera: IPiaCamera | null,
        defaultProfile: Id,
        desiredCamera: IDesiredCamera,
        installationHeight: number,
        installationPoints: IInstallationPointEntity[],
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
        lensRelationProperties?: IPiaRelationPropertyItem,
    ) {
        const profile = newItemProps?.scenario || defaultProfile;

        if (itemToEdit) {
            return this.updateCamera(
                desiredPiaCamera,
                desiredCamera,
                itemToEdit,
                installationHeight,
                installationPoints,
                undefined,
                lensRelationProperties,
            );
        } else if (newItemProps) {
            if (!desiredPiaCamera) {
                // add generic camera with desired filter settings
                return this.addCamera(null, newItemProps, profile, desiredCamera);
            }
            return this.addCamera(desiredPiaCamera, newItemProps, profile, desiredCamera);
        }
    }

    /**
     * Add camera without defining desired filter settings. Name, color and quantity will be set to default values.
     * @param piaId
     */
    public async addCameraWithDefaults(piaId?: PiaId, defaultName?: string) {
        const piaCamera = piaId ? this.piaCameraService.get(piaId).first() : null;
        const defaultProfileId = this.currentProjectService.getProjectDefaultProfile();
        const defaultAddItemProps = {
            name: defaultName ?? t.cameraSelectorNewCamera,
            quantity: 1,
            color: defaultColors.DEFAULT_DEVICE_COLOR,
        } as IAddProductProps;

        return this.addCamera(piaCamera, defaultAddItemProps, defaultProfileId);
    }

    private async addCamera(
        desiredPiaCamera: IPiaCamera | null,
        newItemProps: IAddProductProps,
        profile: Id,
        desiredCamera?: IDesiredCamera,
    ) {
        const item: IItem = {
            name: newItemProps.name,
            description: '',
            notes: newItemProps.notes || '',
            productId: desiredPiaCamera?.id ?? null,
            quantity: newItemProps.quantity,
            color: newItemProps.color,
            properties: this.toProperties(profile, desiredPiaCamera, desiredCamera),
        };
        const newCameraItem = await this.itemService.addToCurrentProject(item);
        await this.virtualProductService.addVirtualProducts(
            newCameraItem._id,
            desiredPiaCamera,
            profile,
        );

        return newCameraItem;
    }

    /**
     * Adds a sensor unit and adds child units to map.
     */
    public async addSensorUnit(
        mainUnitId: Id | undefined,
        desiredPiaCamera: IPiaSensorUnit | null,
        defaultProfile: Id,
        unitSystem: UnitSystem,
        installationHeight: number,
        desiredCamera: IDesiredCamera,
        newItemProps: IAddProductProps,
    ) {
        if (!mainUnitId) {
            throw new Error('MainUnitId cannot be null when adding sensor unit');
        }

        const sensorUnitItem = await this.itemService.addByParentId(mainUnitId, {
            productId: desiredPiaCamera?.id ?? null,
            name: newItemProps.name,
            description: '',
            notes: newItemProps.notes || '',
            quantity: newItemProps.quantity,
            properties: {
                sensorUnit: {
                    filter: desiredCameraUtils.convertDesiredCameraToFilterProperties(
                        desiredCamera,
                        installationHeight,
                        desiredPiaCamera,
                        unitSystem,
                    ),
                    associatedProfile: newItemProps.scenario || defaultProfile,
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
        });

        await this.itemService.addItemRelation(mainUnitId, sensorUnitItem._id, 'sensorUnit');
        await this.parentChildInstallationPointService.addChildItemToMap(
            sensorUnitItem._id,
            sensorUnitItem.quantity,
            mainUnitId,
        );
        return sensorUnitItem;
    }

    public async updateCamera(
        desiredPiaCamera: IPiaCamera | IPiaSensorUnit | null,
        desiredCamera: IDesiredCamera,
        itemToEdit: IPersistence<IItemEntity>,
        installationHeight: number,
        installationPoints: IInstallationPointEntity[],
        parentId?: Id,
        lensRelationProperties?: IPiaRelationPropertyItem,
    ) {
        const editCameraItem = await this.getEditCameraItem(itemToEdit._id, installationPoints);
        const modelHasChanged = editCameraItem.piaCameraId !== desiredPiaCamera?.id;

        if (
            !(await this.coverageAreaConfirmation(desiredPiaCamera, itemToEdit, installationPoints))
        ) {
            return;
        }

        if (
            modelHasChanged &&
            (editCameraItem.hasAccessories ||
                editCameraItem.hasApplications ||
                editCameraItem.hasLenses)
        ) {
            const confirm = await this.accessoryService.getConfirmDialogue(
                itemToEdit._id,
                desiredPiaCamera?.id,
            );

            // If the user clicked cancel we should not continue
            if (!confirm) {
                return;
            }
        }

        const item = await this.itemService.getItem(editCameraItem.persistedId);
        if (!(item.properties.camera || item.properties.sensorUnit)) {
            throw new Error(`Item is not a camera or sensor unit`);
        }

        if (
            deviceTypeCheckers.isDoorStation(item) ||
            desiredPiaCamera?.category.includes(PiaItemPacCategory.DOORSTATIONS)
        ) {
            item.properties.pac = {
                filter: desiredCameraUtils.convertDesiredCameraToPacFilterProperties(desiredCamera),
            };
        }
        if (modelHasChanged) {
            await this.virtualProductService.addVirtualProducts(
                item._id,
                desiredPiaCamera,
                item.properties.camera?.associatedProfile ??
                    item.properties.sensorUnit?.associatedProfile,
            );
        }

        if (item.properties.camera) {
            item.properties.camera = {
                filter: desiredCameraUtils.convertDesiredCameraToFilterProperties(
                    desiredCamera,
                    installationHeight,
                    desiredPiaCamera,
                ),
                associatedProfile: item.properties.camera.associatedProfile,
                profileOverride: editCameraItem.profileOverride,
                customCameraProperties: !desiredPiaCamera?.id
                    ? item.properties.camera.customCameraProperties
                    : undefined,
            };
        } else if (item.properties.sensorUnit) {
            item.properties.sensorUnit = {
                filter: desiredCameraUtils.convertDesiredCameraToFilterProperties(
                    desiredCamera,
                    installationHeight,
                    desiredPiaCamera,
                ),
                associatedProfile: item.properties.sensorUnit.associatedProfile,
                profileOverride: editCameraItem.profileOverride,
            };
            if (itemToEdit.quantity > item.quantity && parentId) {
                // Add installation points for added sensor units
                await this.parentChildInstallationPointService.addChildItemToMap(
                    item._id,
                    itemToEdit.quantity - item.quantity,
                    parentId,
                );
            }
        } else {
            throw new Error(`Item is not a camera`);
        }

        const removeAccessoriesAndMountsAndApplications = item.productId !== desiredPiaCamera?.id;
        item.quantity = Math.max(1, itemToEdit.quantity);
        item.name = itemToEdit.name;
        item.productId = desiredPiaCamera?.id ?? null;
        if (item.replaceWithBareboneId && modelHasChanged) {
            item.replaceWithBareboneId = null;
        }

        const itemProps: Partial<IItem> = {
            name: item.name,
            quantity: item.quantity,
            description: item.description,
            notes: item.notes,
            productId: item.productId,
            properties: item.properties,
            color: item.color,
            replaceWithBareboneId: item.replaceWithBareboneId,
            analyticRange: this.getCompatibleAnalyticRange(item.productId, item.analyticRange),
        };

        if (removeAccessoriesAndMountsAndApplications) {
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                item._id,
                desiredPiaCamera?.id,
            );
        }
        const updatedDevice = await this.itemService.updateItem(item._id, itemProps);

        if (installationPoints && updatedDevice) {
            await this.installationPointService.updateInstallationPointsToNewDevice(
                updatedDevice._id,
                editCameraItem.piaCameraId,
                installationPoints,
                lensRelationProperties,
            );
        }

        return updatedDevice;
    }

    public async getEditCameraItem(
        id: Id,
        installationPoints: IInstallationPointEntity[],
    ): Promise<IEditCameraItem> {
        const device = await this.itemService.getItem(id);
        const cameraPropertiesEntity = device.properties.camera ?? device.properties.sensorUnit;
        if (!cameraPropertiesEntity) {
            throw new Error(`Device is not a camera or sensor unit`);
        }

        // combine a filter if the device has installation points
        const installationPointsFilter =
            installationPoints.length > 0
                ? combineCameraFilterFromAllInstallationPoints(installationPoints)
                : undefined;
        let desiredCamera = desiredCameraUtils.convertPropertiesToDesiredCamera(
            cameraPropertiesEntity.filter,
        );
        if (!device.productId && installationPointsFilter && !desiredCamera.isFilterChanged) {
            desiredCamera = this.updateDesiredCamera(desiredCamera, installationPointsFilter);
        }

        const hasAccessoriesOrMount = this.accessoryService.hasAccessoriesOrMount(device._id);

        return {
            name: device.name,
            quantity: device.quantity,
            persistedId: device._id,
            persistedRev: device._rev,
            desiredCamera,
            installationPointsFilter,
            profileOverride: cameraPropertiesEntity.profileOverride,
            piaCameraId: device.productId,
            hasLenses: this.accessoryService.hasLenses(device._id),
            hasAccessories: hasAccessoriesOrMount,
            hasApplications: this.accessoryService.hasApplications(device._id),
        };
    }

    private updateDesiredCamera(
        desiredCamera: IDesiredCamera,
        installationPointsFilter: IInstallationPointsFilter,
    ) {
        desiredCamera.corridorFormat = installationPointsFilter.corridorFormat;
        desiredCamera.distanceToTarget = installationPointsFilter.distanceToTarget;
        desiredCamera.horizontalFOVRadians = trigonometry.toRadians(
            installationPointsFilter.horizontalFov,
        );
        desiredCamera.installationHeight = installationPointsFilter.installationHeight;
        desiredCamera.targetHeight = installationPointsFilter.targetHeight;
        return desiredCamera;
    }

    private toProperties(
        profile: Id,
        desiredPiaCamera: IPiaCamera | null,
        desiredCamera?: IDesiredCamera,
    ): IItemPropertiesEntity {
        const isDoorStation = isCameraDoorStation(desiredPiaCamera);
        const { unitSystem, customInstallationHeight } =
            this.currentProjectService.getProjectEntity();

        // determine a default panorama mode based on pia camera
        const defaultPanoramaMode = desiredPiaCamera?.properties?.canChangePanoramaMode
            ? desiredPiaCamera.properties.defaultPanoramaMode
            : false;

        return {
            pac: isDoorStation
                ? {
                      filter: desiredCamera
                          ? desiredCameraUtils.convertDesiredCameraToPacFilterProperties(
                                desiredCamera,
                            )
                          : defaultPacFilter,
                  }
                : undefined,
            camera:
                /**
                 * At this time we still do not support 2N doorStations to be treated as cameras
                 * due to missing properties in Pia. Once this support has been added, we can stop
                 * caring about vendor for doorStations.
                 *  */
                (desiredPiaCamera && !isCamera(desiredPiaCamera)) ||
                desiredPiaCamera?.properties.vendor === '2N'
                    ? undefined
                    : {
                          filter: desiredCamera
                              ? desiredCameraUtils.convertDesiredCameraToFilterProperties(
                                    desiredCamera,
                                    customInstallationHeight,
                                    desiredPiaCamera,
                                    unitSystem,
                                )
                              : this.currentProjectService.getProjectDefaultCameraFilter(
                                    isDoorStation,
                                    defaultPanoramaMode,
                                ),
                          associatedProfile: profile,
                          profileOverride: getDefaultProfileOverrideEntity(),
                      },
        };
    }

    /** Returns an item's analyticRange if it's compatible with new model. Otherwise undefined. */
    private getCompatibleAnalyticRange = (
        piaId: PiaId | null,
        analyticRange?: IDeviceAnalyticRange,
    ): IDeviceAnalyticRange | undefined => {
        if (piaId === null || !analyticRange) {
            return undefined;
        }
        const state = this.appStore.Store.getState();
        const compatibleApps = getCompatibleApplicationsForPiaId(state, piaId);
        const analyticRangeCompatible = compatibleApps.some(
            (app) => app.properties.ACAPId === analyticRange?.applicationId,
        );
        return analyticRangeCompatible ? analyticRange : undefined;
    };

    /** Checks if coverage area is supported for new device model.
     * If not it will trigger a modal prompting user confirmation to continue or cancel. */
    private coverageAreaConfirmation = async (
        desiredPiaCamera: IPiaCamera | IPiaSensorUnit | null,
        editCameraItem: IPersistence<IItemEntity>,
        installationPoints: IInstallationPointEntity[],
    ): Promise<boolean> => {
        if (this.getCoverageAreaSupported(desiredPiaCamera, editCameraItem, installationPoints)) {
            return true;
        }

        return this.modalService.createConfirmDialog({
            header: t.coverageAreaNotSupported,
            body: t.notSupportedByMap,
            cancelButtonText: t.cancel,
            confirmButtonText: t.change,
        })();
    };

    /** Checks if coverage area is supported for camera or sensor unit.
     * For sensor units this is determined by the main unit it is connected to. */
    private getCoverageAreaSupported = (
        piaCamera: IPiaCamera | IPiaSensorUnit | null,
        editCameraItem: IPersistence<IItemEntity>,
        installationPointsForEditItem: IInstallationPointEntity[],
    ) => {
        if (
            !piaCamera ||
            !editCameraItem ||
            (!deviceTypeCheckers.isCamera(editCameraItem) &&
                !deviceTypeCheckers.isSensorUnit(editCameraItem))
        ) {
            return true;
        }

        if (!installationPointsForEditItem.length) {
            return true;
        }

        // Check if any sensor is set to corridor format
        const editCorridorFormat = usesCorridorFormat(installationPointsForEditItem);

        // Corridor format support for sensor units depend on the main unit they are connected to
        const piaCorridorFormat =
            this.getMainUnitCorridorFormat(editCameraItem) ?? piaCamera.properties.corridorFormat;

        // Check current panorama mode
        const editPanoramaMode = deviceTypeCheckers.isCamera(editCameraItem)
            ? editCameraItem.properties.camera.filter.panoramaMode
            : editCameraItem.properties.sensorUnit.filter.panoramaMode;

        // Get largest field of view of all sensors
        const editHorizontalFov = largestHorizontalFov(installationPointsForEditItem);

        return isSelectedCameraSupportedByEditCameraItem(
            { ...piaCamera.properties, corridorFormat: piaCorridorFormat },
            editPanoramaMode,
            editCorridorFormat,
            editHorizontalFov,
        );
    };

    private getMainUnitCorridorFormat = (sensorItem: IPersistence<IItemEntity>) => {
        if (!deviceTypeCheckers.isSensorUnit(sensorItem)) {
            return undefined;
        }
        const parentId = getParentId(sensorItem);
        if (!parentId) {
            return undefined;
        }
        const parentItem = this.currentProjectService.getEntity(parentId, 'item');
        const parentPiaItem = parentItem?.productId
            ? this.piaItemService.get(parentItem.productId).first()
            : undefined;
        const mainUnitCorridor = isMainUnit(parentPiaItem)
            ? parentPiaItem.properties.corridorFormat
            : undefined;
        return mainUnitCorridor;
    };
}
