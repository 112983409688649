/**
 * Accepts an error and returns its message.
 * @param error - should always be an error. Has type unknown since parameter in catch clause is of type unknown.
 * @returns message from error.
 */
export const toErrorMessage = (error: unknown): string => (isErrorType(error) ? error.message : '');

/**
 * Type guard that is useful when accessing properties of an error, such as in {@link toErrorMessage}.
 * @param error - For typical use cases we expect this to be an error. Has type unknown since parameter in catch clause is of type unknown.
 * @returns error is Error
 */
export const isErrorType = (error: unknown): error is Error => error instanceof Error;
