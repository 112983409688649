import * as React from 'react';
import { Box, DropDownMenu, DropDownMenuButton, Modal } from 'app/components';
import { t } from 'app/translate';
import { HelpVideo } from './HelpVideo';
import { KeyboardShortcuts } from './KeyboardShortcuts';
import { useModalLeftMargin } from '../../hooks/useModalLeftMargin';
import { useSelector } from 'react-redux';
import { getShowGetToKnowMaps } from '../../selectors';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../services';
import { eventTracking } from 'app/core/tracking';

export const HelpMaps: React.FC = () => {
    const actionService = useService(MapsActionService);
    const showGetToKnowMaps = useSelector(getShowGetToKnowMaps);
    const [showKeyboardShortcuts, setShowKeyboardShortcuts] = React.useState<boolean>(false);
    const modalLeftMargin = useModalLeftMargin();

    return (
        <>
            <Box
                width="100%"
                alignItems="center"
                justifyContent="end"
                paddingRight="base"
                testId="map_help_button"
            >
                <DropDownMenu icon="help" iconProps={{ color: 'blue', opaque: true }}>
                    <DropDownMenuButton
                        icon="play_outline"
                        onClick={() => {
                            eventTracking.logUserEvent('Maps', 'Show help video');
                            actionService.toggleGetToKnowMaps(true);
                        }}
                    >
                        {t.getToKnowMaps}
                    </DropDownMenuButton>
                    <DropDownMenuButton
                        icon="keyboard"
                        onClick={() => setShowKeyboardShortcuts(true)}
                    >
                        {t.keyboardShortcuts}
                    </DropDownMenuButton>
                </DropDownMenu>
            </Box>
            {showGetToKnowMaps && (
                <Modal
                    expandable
                    title={t.getToKnowMaps}
                    width="900px"
                    marginLeft={modalLeftMargin}
                    onClose={() => actionService.toggleGetToKnowMaps(false)}
                >
                    <HelpVideo />
                </Modal>
            )}
            {showKeyboardShortcuts && (
                <Modal
                    title={t.keyboardShortcuts}
                    onClose={() => setShowKeyboardShortcuts(false)}
                    marginLeft={modalLeftMargin}
                >
                    <KeyboardShortcuts />
                </Modal>
            )}
        </>
    );
};

HelpMaps.displayName = 'HelpMaps';
