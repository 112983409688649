import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { ItemService } from 'app/core/persistence';
import {
    Badge,
    Box,
    IconButton,
    Icon,
    LinkIconButton,
    NumberInput,
    PiaImage,
    Spacer,
    TableRow,
    Text,
    Positioned,
    IconText,
    Clickable,
    DropDownMenuItem,
    DropDown,
} from 'app/components';
import type { ISelectedMountWithAccessories } from '../../selectors';
import {
    getSelectedDeviceId,
    getMountWithAccessories,
    hasAccessoryRequiredAccessories,
    getNotToBeFollowedByAccessories,
    getSelectedMountsAccessoriesAndLensIds,
    getAllRequiredAccessories,
    hasUnmatchedAccessoryRequirement,
} from '../../selectors';
import { ColorsEnum } from 'app/styles';
import { ServiceLocator } from 'app/ioc';
import { isPageRTL, t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import {
    getCurrentProjectLocked,
    getHasRequiredComponents,
    getIsStandalone,
    getPiaItemForProductId,
    getPiaItemsRecord,
    getRequiredComponentsForItem,
    MAX_ACCESSORY_DEPTH,
    PiaItemMsrp,
} from 'app/modules/common';
import { AddAccessoriesButton } from '../mounting/AddAccessoriesButton.container';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import { hasNotToBeFollowedByAccessory } from '../mounting/hasNotToBeFollowedByAccessory';
import { getProductNamesNotToBeFollowedBy } from '../mounting/getProductNamesNotToBeFollowedBy';
import { AccessoryPersistenceService } from '../../services';
import { useResponsive } from 'app/hooks';
const linesLTR = (
    <>
        <line x1="6" y1="0" x2="6" y2="16" stroke={ColorsEnum.grey4} />{' '}
        <line x1="6" y1="16" x2="21" y2="16" stroke={ColorsEnum.grey4} />{' '}
    </>
);
const linesRTL = (
    <>
        <line x1="21" y1="0" x2="21" y2="16" stroke={ColorsEnum.grey4} />{' '}
        <line x1="6" y1="16" x2="21" y2="16" stroke={ColorsEnum.grey4} />{' '}
    </>
);
interface IAccessoriesListItemProps {
    itemId: Id;
    parentQuantity?: number;
    isChildAccessory?: boolean;
    hideParent?: boolean;
    showAccessoryButton?: boolean;
    chainIndex?: number;
    maxQuantity?: number;
}

export const AccessoriesListItem: React.FC<IAccessoriesListItemProps> = ({
    itemId,
    parentQuantity,
    isChildAccessory,
    hideParent,
    showAccessoryButton,
    chainIndex,
    maxQuantity,
}) => {
    const isMobile = useResponsive(false, true, false);
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));
    const isStandalone = useSelector<IStoreState, boolean>((state) => getIsStandalone(state));
    const isLocked = useSelector<IStoreState, boolean>((state) => getCurrentProjectLocked(state));
    const selectedDeviceId = useSelector<IStoreState, Id | undefined>(getSelectedDeviceId);
    const item = useSelector<IStoreState, ISelectedMountWithAccessories>((state) =>
        getMountWithAccessories(state, itemId),
    );
    const piaItem = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItemForProductId(state, item.productId ?? null),
    );

    const requiresAccessories = useSelector<IStoreState>((state) =>
        hasAccessoryRequiredAccessories(state, item.productId),
    );

    const hasMissingRequiredComponent = useSelector<IStoreState, boolean>(
        (state) => !getHasRequiredComponents(state, itemId),
    );
    const hasMissingAccessories = useSelector<IStoreState>(hasUnmatchedAccessoryRequirement);
    const needsRequiredAccessories = hasMissingAccessories || hasMissingRequiredComponent;

    const requiredAccessoryAccessories = useSelector<IStoreState, IPiaItem[][]>(
        getAllRequiredAccessories,
    );

    const requiredComponentsAccessories = useSelector<IStoreState, IPiaItem[][]>((state) =>
        getRequiredComponentsForItem(state, selectedDeviceId),
    );
    const requiredAccessories = requiredAccessoryAccessories.concat(requiredComponentsAccessories);

    const notToBeFollowedByAccessories = useSelector<IStoreState, Record<PiaId, IPiaItem[][]>>(
        getNotToBeFollowedByAccessories,
    );
    const selectedMountsAndAccessoriesIds = useSelector<IStoreState, PiaId[]>(
        getSelectedMountsAccessoriesAndLensIds,
    );

    const selectedAccessoriesAndLensIds = useSelector(getSelectedMountsAccessoriesAndLensIds);
    const piaItemsRecord = useSelector(getPiaItemsRecord);

    const placeInChain = chainIndex ?? 0;
    const updateQuantity = (quantity: number) => {
        itemService.updateItem(itemId, { quantity });
    };
    const deleteItem = () => {
        itemService.deleteItem(itemId);
    };
    //* Main device is part of accessory list in stand alone, which is why we allow one level deeper there
    const maxChainDepth = isStandalone ? MAX_ACCESSORY_DEPTH : MAX_ACCESSORY_DEPTH - 1;

    const showStandaloneAccessoriesButton =
        item.id !== selectedDeviceId &&
        item.productId &&
        showAccessoryButton &&
        placeInChain < maxChainDepth;

    const dropDownItems = requiredAccessories.map((accessoryCombination, i) => (
        <Clickable
            key={i}
            onClick={() => {
                if (selectedDeviceId) {
                    const accessoryPersistenceService = ServiceLocator.get(
                        AccessoryPersistenceService,
                    );
                    accessoryCombination.forEach(({ id }) =>
                        accessoryPersistenceService.setItem(selectedDeviceId, id, 'accessory'),
                    );
                }
            }}
        >
            <DropDownMenuItem>
                {accessoryCombination.map((accessory) => (
                    <Box key={accessory.id} justifyContent="between" alignItems="center">
                        <Box spacing="base" alignItems="center">
                            <PiaImage imageSize="md" piaId={accessory.id} icon="category" />
                            <Text>{accessory.name}</Text>
                        </Box>
                        <PiaItemMsrp piaId={accessory.id} includeMultipacks />
                    </Box>
                ))}
            </DropDownMenuItem>
        </Clickable>
    ));

    return (
        <>
            {(isChildAccessory || !hideParent) && (
                <TableRow
                    testId={`accessoryListItem_${item.model}`}
                    columnWidth={['100%', '100px', '100px']}
                    cells={[
                        <Box alignItems="center">
                            <Box alignItems="center" spacing="quart">
                                <Box>
                                    {placeInChain > 0 && (
                                        <Spacer
                                            horizontal
                                            customSpacing={`${(placeInChain - 1) * 34 + 8}px`}
                                        />
                                    )}
                                    {isChildAccessory && !hideParent && (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="32"
                                            viewBox="0 0 24 32"
                                        >
                                            {isPageRTL() ? linesRTL : linesLTR}
                                        </svg>
                                    )}
                                </Box>
                                <Box alignItems="center" spacing="base">
                                    <PiaImage
                                        piaId={item.productId ?? null}
                                        icon="category"
                                        imageSize="md"
                                    />
                                    {requiresAccessories ? (
                                        <Positioned position="absolute" left="16px" top="8px">
                                            <Icon opaque icon="info" color="blue" size="xs" />
                                        </Positioned>
                                    ) : null}
                                    <Box direction="column">
                                        <Box>
                                            <Text>{item.productId ? item.model : t.other}</Text>
                                            {item.isDiscontinued && (
                                                <Badge compact type="red" label={t.discontinued} />
                                            )}
                                        </Box>
                                        {item.productId &&
                                            hasNotToBeFollowedByAccessory(
                                                item.productId,
                                                notToBeFollowedByAccessories,
                                                selectedMountsAndAccessoriesIds,
                                            ) && (
                                                <IconText
                                                    icon="warning"
                                                    iconProps={{
                                                        size: 'xs',
                                                        color: 'red',
                                                        opaque: true,
                                                    }}
                                                >
                                                    {t.canNotBeUsedWithProductName(
                                                        getProductNamesNotToBeFollowedBy(
                                                            item.productId,
                                                            notToBeFollowedByAccessories,
                                                            selectedAccessoriesAndLensIds,
                                                            piaItemsRecord,
                                                        ),
                                                    )}
                                                </IconText>
                                            )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>,
                        <Box />,
                        ...(isStandalone
                            ? [
                                  <Box />,
                                  <Box justifyContent="end">
                                      {needsRequiredAccessories && (
                                          <DropDown
                                              trigger={
                                                  <Box
                                                      paddingRight={
                                                          showStandaloneAccessoriesButton
                                                              ? 'base'
                                                              : undefined
                                                      }
                                                  >
                                                      {isMobile ? (
                                                          <Icon
                                                              size="md"
                                                              icon="warning_small"
                                                              color="yellow7"
                                                          />
                                                      ) : (
                                                          <IconText
                                                              icon="warning_small"
                                                              color="yellow7"
                                                              spacing="half"
                                                              iconProps={{ size: 'xs' }}
                                                              textProps={{
                                                                  style: 'heading',
                                                                  whiteSpace: 'nowrap',
                                                              }}
                                                              testId="warning_required_accessory"
                                                          >
                                                              {t.addRequiredAccessories}
                                                          </IconText>
                                                      )}
                                                  </Box>
                                              }
                                              contents={dropDownItems}
                                              minWidth={isMobile ? 200 : 600}
                                              maxWidth={isMobile ? 400 : undefined}
                                              openInPortal={isMobile ? false : true}
                                          />
                                      )}
                                      {showStandaloneAccessoriesButton && item.productId && (
                                          <AddAccessoriesButton
                                              itemId={item.id}
                                              productId={item.productId}
                                              includeAllMounts
                                              style="small"
                                          />
                                      )}
                                  </Box>,
                                  <Box paddingX="quart">
                                      <Text whiteSpace="nowrap">{item.partNumber ?? '-'}</Text>
                                  </Box>,
                                  <React.Fragment>
                                      {piaItem && !isDiscontinued(piaItem) && (
                                          <LinkIconButton
                                              size="md"
                                              icon="description"
                                              external
                                              link={`https://www.axis.com/find/product/${piaItem.parentId}`}
                                          />
                                      )}
                                  </React.Fragment>,
                              ]
                            : []),
                        ...(!isStandalone
                            ? [
                                  <Box>
                                      {item.productId &&
                                          showAccessoryButton &&
                                          placeInChain < maxChainDepth && (
                                              <AddAccessoriesButton
                                                  itemId={item.id}
                                                  productId={item.productId}
                                                  includeAllMounts
                                                  style="small"
                                              />
                                          )}
                                  </Box>,
                                  <Box justifyContent="center" alignItems="center">
                                      {parentQuantity && parentQuantity > 1 && (
                                          <Text color="grey5">{parentQuantity}&nbsp;×&nbsp;</Text>
                                      )}
                                      {isChildAccessory && !isLocked ? (
                                          <NumberInput
                                              min={1}
                                              max={maxQuantity || AppConstants.componentQuantityMax}
                                              value={item.quantity}
                                              onChange={updateQuantity}
                                          />
                                      ) : (
                                          <Text>{item.quantity}</Text>
                                      )}
                                  </Box>,
                              ]
                            : []),
                        ...(isChildAccessory && !isLocked
                            ? [
                                  <Box>
                                      <IconButton
                                          wrapText={false}
                                          icon="delete"
                                          size="md"
                                          text={isStandalone ? '' : t.remove}
                                          onClick={deleteItem}
                                      />
                                  </Box>,
                              ]
                            : []),
                    ].filter((_, index) => (isStandalone ? index !== 2 : index !== 1))}
                />
            )}
            {item.accessories.map((accessoryItem) => (
                <AccessoriesListItem
                    key={accessoryItem.id}
                    itemId={accessoryItem.id}
                    isChildAccessory
                    parentQuantity={(parentQuantity ?? 1) * item.quantity}
                    showAccessoryButton={showAccessoryButton}
                    chainIndex={placeInChain + 1}
                />
            ))}
        </>
    );
};
