import { t } from 'app/translate';
import type { ProjectState } from 'app/core/persistence';

export function getProjectStateLabel(projectState: ProjectState) {
    switch (projectState) {
        case 'designing':
            return t.quotationStateGROUP.design;
        case 'quoteSent':
            return t.quotationStateGROUP.quoteSent;
        case 'quoteAccepted':
            return t.quotationStateGROUP.quoteAccepted;
        case 'installed':
            return t.quotationStateGROUP.installed;
        case 'done':
            return t.quotationStateGROUP.done;

        default:
            throw new Error(`Unknown project state: ${projectState}`);
    }
}
