import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import type { Id } from 'app/core/persistence';
import type { IScenarioModel } from '../models';

export const getScenarios = (state: IStoreState) => state.common.scenarios;

/**
 * Returns a record with scenario id as key and scenario model as value.
 */
export const getScenariosRecord = createSelector([getScenarios], (scenarios) =>
    scenarios.reduce((obj: Record<Id, IScenarioModel>, scenario) => {
        obj[scenario.id] = scenario;
        return obj;
    }, {}),
);
