import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'app/translate';
import { Box, ReportCard, Stack, Table, Text } from 'app/components';
import type { DeviceAndSubType, IPersistence, IProjectEntity } from 'app/core/persistence';
import { getCurrentProject, getProjectDeviceCount } from 'app/modules/common';
import type { IStoreState } from 'app/store';

interface ISummaryDetails {
    project: IPersistence<IProjectEntity>;
    deviceCount: Record<DeviceAndSubType, number>;
}

const mapStateToProps = (state: IStoreState): ISummaryDetails => {
    return {
        project: getCurrentProject(state),
        deviceCount: getProjectDeviceCount(state),
    };
};

class SummaryDetailsContainer extends React.PureComponent<ISummaryDetails> {
    constructor(props: ISummaryDetails) {
        super(props);
    }

    render() {
        const { deviceCount } = this.props;

        return (
            <ReportCard title={t.installationReportSummaryDetails} panelPaddingX="halfQuart">
                <Stack vertical>
                    {this.props.project.notes && (
                        <Box padding="half">
                            <Text testId="note_installation_report_page" whiteSpace="pre-wrap">
                                {this.props.project.notes}
                            </Text>
                        </Box>
                    )}
                    <Table omitLastLine rightAlignLastColumn>
                        {this.renderDeviceTypeRow('camera', deviceCount)}
                        {this.renderDeviceTypeRow('encoder', deviceCount)}
                        {this.renderModularRow(deviceCount)}
                        {this.renderDeviceTypeRow('speaker', deviceCount)}
                        {this.renderDeviceTypeRow('alerter', deviceCount)}
                        {this.renderDeviceTypeRow('decoder', deviceCount)}
                        {this.renderDeviceTypeRow('pac', deviceCount)}
                        {this.renderDeviceTypeRow('radardetector', deviceCount)}
                        {this.renderDeviceTypeRow('doorstation', deviceCount)}
                        {this.renderDeviceTypeRow('answeringUnit', deviceCount)}
                        {this.renderDeviceTypeRow('accessServer', deviceCount)}
                        {this.renderDeviceTypeRow('networkReader', deviceCount)}
                        {this.renderDeviceTypeRow('iorelay', deviceCount)}
                        {this.renderDeviceTypeRow('doorcontroller', deviceCount)}
                        {this.renderDeviceTypeRow('peopleCounter', deviceCount)}
                        {this.renderDeviceTypeRow('relayexpmodules', deviceCount)}
                        {this.renderDeviceTypeRow('connectivitydevice', deviceCount)}
                        {this.renderDeviceTypeRow('pagingconsole', deviceCount)}
                    </Table>
                </Stack>
            </ReportCard>
        );
    }

    private renderDeviceTypeRow(
        type: DeviceAndSubType,
        deviceCount: Record<DeviceAndSubType, number>,
    ) {
        return this.renderRow(t.devicesGROUP[type], deviceCount[type]);
    }

    private renderModularRow(deviceCount: Record<DeviceAndSubType, number>) {
        const modularCount = deviceCount.mainUnit + deviceCount.sensorUnit;
        return this.renderRow(t.installationReportSummaryModulars, modularCount);
    }

    private renderRow(label: string, quantity: number) {
        if (quantity === 0 || quantity === undefined) {
            return undefined;
        }

        return (
            <tr>
                <td>
                    <Text>{label}</Text>
                </td>
                <td>
                    <Text align="right">{quantity}</Text>
                </td>
            </tr>
        );
    }
}

export const SummaryDetails = connect(mapStateToProps)(SummaryDetailsContainer);

SummaryDetails.displayName = 'SummaryDetails';
