import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project';
import { isCustomCamera } from 'app/core/persistence';

export const getIsCustomCamera = createCachedSelector([getCurrentProjectItem], (item): boolean => {
    if (!item) {
        return false;
    }
    return isCustomCamera(item);
})((_state, itemId) => itemId ?? '');
