export enum IPRatings {
    IP42 = 'IP42',
    IP51 = 'IP51',
    IP52 = 'IP52',
    IP65 = 'IP65',
    IP66 = 'IP66',
    IP67 = 'IP67',
    IP68 = 'IP68',
    IP69 = 'IP69',
    IP6K9K = 'IP6K9K',
}
