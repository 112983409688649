import { LeafletItemFactory } from './../../services/LeafletItemFactory';
import { useService } from 'app/ioc';
import React from 'react';
import type { IPersistence, IFreeTextPointEntity } from 'app/core/persistence';

export const useFreeTextDraggableItem = (
    freeTextEntity: IPersistence<IFreeTextPointEntity> | undefined,
    isEditing: boolean,
) => {
    const factory = useService(LeafletItemFactory);
    const freeTextPoint = React.useMemo(() => {
        return factory.createFreeText(
            freeTextEntity?.text ?? '',
            freeTextEntity?.size,
            freeTextEntity?.textColor,
            freeTextEntity?.backgroundColor,
            isEditing,
        );
    }, [factory, freeTextEntity, isEditing]);

    const [draggableFreeText, setDraggableFreeText] = React.useState(
        factory.createInteractiveItem(
            freeTextEntity?.location ?? { lat: 0, lng: 0 },
            freeTextPoint,
        ),
    );

    React.useEffect(
        () =>
            setDraggableFreeText(
                factory.createInteractiveItem(
                    freeTextEntity?.location ?? { lat: 0, lng: 0 },
                    freeTextPoint,
                ),
            ),
        [factory, freeTextEntity?.location, freeTextPoint],
    );

    return draggableFreeText;
};
