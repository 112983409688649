import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';
import { IPRatings } from 'app/core/pia';

interface IIPRatingFilterProps {
    selectedIPRating?: IPRatings;
    onClick(rating: IPRatings | undefined): void;
}

export const IPRatingFilter: React.FC<IIPRatingFilterProps> = ({ selectedIPRating, onClick }) => (
    <DropDown
        trigger={
            <PillButton
                icon="keyboard_arrow_down"
                selected={!!selectedIPRating}
                text={selectedIPRating ?? t.ipRating}
            />
        }
        contents={
            <>
                {Object.values(IPRatings).map((option) => (
                    <DropDownMenuButton
                        key={option}
                        label={option}
                        selected={selectedIPRating === option}
                        onClick={() => onClick(selectedIPRating === option ? undefined : option)}
                    />
                ))}
            </>
        }
    />
);

IPRatingFilter.displayName = 'IPRatingFilter';
