import { RecordingSelectorActions } from './../state/RecordingSelector.actions';
import { injectable } from 'inversify';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import type {
    IPartnerRecommendationResponse,
    IPartnerSystemComponent,
    RecordingCategoriesFilter,
} from 'app/core/persistence';
import { Id, CameraStationType, CameraStationCenterType } from 'app/core/persistence';
import type { IDeviceRequirement } from '../selectors';
import {
    areBasicRequirementsFulfilled,
    getDeviceRequirements,
    getRecordingSolutionComponents,
    isSystemARecommendationSuperset,
    getCompanionSDCardsNeeded,
} from '../selectors';
import type { BandwidthStorageEstimate } from 'app/modules/common';
import { getSelectedRecordingSolutionType } from 'app/modules/common';
import { GenetecStreamVaultProjectService } from './GenetecStreamVaultProject.service';
import type { PiaId, IPiaItem } from 'app/core/pia';
import { RecordingValidatorService } from './RecordingValidator.service';

@injectable()
export class RecordingSelectorActionService {
    constructor(
        private recordingValidatorService: RecordingValidatorService,
        private genetecStreamVaultProjectService: GenetecStreamVaultProjectService,
    ) {}

    @ActionCreator()
    public filterProductSelector(
        payload: RecordingCategoriesFilter,
    ): IAction<RecordingCategoriesFilter> {
        return {
            type: RecordingSelectorActions.FilterProductSelector,
            payload,
        };
    }

    @ActionCreator()
    public showDeviceDetails(payload: Id): IAction<Id> {
        return {
            type: RecordingSelectorActions.ToggleDeviceDetails,
            payload,
        };
    }

    @ActionCreator()
    public setNetworkSettings(payload: Id): IAction<Id> {
        return {
            type: RecordingSelectorActions.SetNetworkSettingsDetails,
            payload,
        };
    }

    @ActionCreator()
    public getGenetecRecommendations(
        deviceRequirements: IDeviceRequirement[],
        bandwidthStorageEstimateForItems: Record<Id, BandwidthStorageEstimate>,
        piaItems: Record<PiaId, IPiaItem>,
        country: string,
        recommendationType: number,
        projectId?: string,
    ): IAction<Promise<IPartnerRecommendationResponse | undefined>> {
        return {
            type: RecordingSelectorActions.GetGenetecRecommendations,
            payload: this.genetecStreamVaultProjectService.getGenetecRecommendations(
                deviceRequirements,
                bandwidthStorageEstimateForItems,
                piaItems,
                country,
                recommendationType,
                projectId,
            ),
        };
    }

    @ActionCreator()
    public getGenetecProducts(countryCode: string): IAction<Promise<IPartnerSystemComponent[]>> {
        return {
            type: RecordingSelectorActions.GetGenetecProducts,
            payload: this.genetecStreamVaultProjectService.getGenetecProducts(countryCode),
        };
    }

    @ActionCreator()
    public setGenetecProjectId(projectId: string | undefined): IAction<string | undefined> {
        return {
            type: RecordingSelectorActions.SetGenetecProjectId,
            payload: projectId,
        };
    }

    @ActionCreator()
    public hideDeviceDetails(): IAction<void> {
        return {
            type: RecordingSelectorActions.ToggleDeviceDetails,
            payload: undefined,
        };
    }

    @ActionCreator()
    public hideNetworkSettings(): IAction<void> {
        return {
            type: RecordingSelectorActions.SetNetworkSettingsDetails,
            payload: undefined,
        };
    }

    @ActionCreator()
    public showProductSelector(vendorName?: string): ThunkAction {
        return async (dispatch: any) => {
            dispatch({
                type: RecordingSelectorActions.ToggleProductSelector,
                payload: true,
            });
            if (vendorName) {
                dispatch({
                    type: RecordingSelectorActions.ShowProductSelector,
                    payload: vendorName,
                });
            }
        };
    }

    @ActionCreator()
    public validateSystem(): ThunkAction {
        return async (dispatch, getState) => {
            const state = getState();

            const basicCheckOk = areBasicRequirementsFulfilled(state);
            const requirements = getDeviceRequirements(state);
            const currentSolutionComponents = getRecordingSolutionComponents(state);
            const isSuperset = isSystemARecommendationSuperset(state);
            const recordingSolutionType = getSelectedRecordingSolutionType(state);
            const sdCardRequirements =
                recordingSolutionType === 'AxisCompanionSDCard'
                    ? getCompanionSDCardsNeeded(state)
                    : [];

            dispatch({
                type: RecordingSelectorActions.SetValidationRunning,
            });

            try {
                const validationResult = await this.recordingValidatorService.validateSystem(
                    requirements,
                    currentSolutionComponents,
                    sdCardRequirements,
                    basicCheckOk,
                    isSuperset,
                    recordingSolutionType,
                );
                dispatch({
                    type: RecordingSelectorActions.SetValidationResult,
                    payload: validationResult,
                });
            } catch (e) {
                dispatch({
                    type: RecordingSelectorActions.SetValidationResult,
                    payload: null,
                });
            }
        };
    }

    @ActionCreator()
    public setSearchFilter(filter: string): IAction<string> {
        return {
            type: RecordingSelectorActions.SetSearchFilter,
            payload: filter,
        };
    }

    @ActionCreator()
    public setViewingAxisAcsType(type: CameraStationType): IAction<CameraStationType> {
        return {
            type: RecordingSelectorActions.SetViewingAxisAcsType,
            payload: type,
        };
    }
    @ActionCreator()
    public setAxisCenter(type: CameraStationCenterType): IAction<CameraStationCenterType> {
        return {
            type: RecordingSelectorActions.SetAxisCenter,
            payload: type,
        };
    }
    @ActionCreator()
    public resetToInitialState() {
        return {
            type: RecordingSelectorActions.ResetToInitialState,
            payload: undefined,
        };
    }
}
