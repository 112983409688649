import { isCamera, isSensorUnit, isVirtualCamera } from 'app/core/persistence';
import { calculateFOVLimits, getFovLimits, toCacheKey, calculate } from 'app/modules/common';
import { calculatePixelDensity } from 'app/modules/common/pixelDensity';
import { trigonometry } from 'axis-webtools-util';
import { clamp } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { getSelectedSensorId } from './getSelectedId';
import { getSelectedInstallationPoint } from './getSelectedInstallationPoint';

export const getPixelDensity = createCachedSelector(
    [getSelectedInstallationPoint, getSelectedSensorId, getFovLimits],
    (installationPoint, selectedSensorId, fovLimits) => {
        const sensorCount = installationPoint?.sensors.length ?? 0;
        const sensorId = clamp(selectedSensorId || 1, 1, sensorCount);

        const currentSensor = installationPoint?.sensors.find(
            (sensor) => sensor.sensorId === sensorId,
        );

        const piaCamera = currentSensor?.parentPiaDevice
            ? currentSensor.parentPiaDevice
            : installationPoint?.parentPiaDevice;

        if (
            piaCamera &&
            !(isCamera(piaCamera) || isSensorUnit(piaCamera) || isVirtualCamera(piaCamera))
        ) {
            return 0;
        }

        const lensItemEntity = installationPoint?.lenses?.find((lens) => {
            // sensorIndex starts at 0 and sensorId starts at 1.
            const sensorIndex = lens.properties.lens?.sensorIndex || 0;
            return sensorIndex + 1 === sensorId;
        });

        const itemEntity = currentSensor?.parentDevice
            ? currentSensor?.parentDevice
            : installationPoint?.parentDevice;

        const lensRelation = piaCamera?.relations.find(
            (relation) => relation.id === lensItemEntity?.productId,
        );

        // calculate lensFovLimits also if lensRelation is undefined since we want the sensor's piaCamera to be used
        // (due to different piaDevice for virtual sensors). Selector getFovLimits takes the selected parentPiaCamera
        // for calculations
        const lensFovLimits =
            piaCamera && (lensRelation || currentSensor?.isVirtual)
                ? calculateFOVLimits(piaCamera, lensRelation?.relationProperties, itemEntity)
                : fovLimits;

        const horizontalFovRadians = currentSensor
            ? trigonometry.toRadians(currentSensor.settings.horizontalFov)
            : 0;

        const distance = calculate.trueDistance(
            installationPoint?.height ?? 0,
            currentSensor?.target.distance ?? 0,
            currentSensor?.target.height ?? 0,
        );

        return calculatePixelDensity({
            distance,
            horizontalFovRadians,
            lensFovLimits,
            parentDevice: itemEntity,
            piaCamera,
        });
    },
)(toCacheKey);
