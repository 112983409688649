import { Box, Text, Icon } from 'app/components';
import { t } from 'app/translate';
import * as React from 'react';

export const GenetecOffline: React.FC = () => {
    return (
        <Box width="100%" justifyContent="center" height="100%">
            <Box
                direction="column"
                spacing="base"
                width="80%"
                justifyContent="center"
                alignItems="center"
            >
                <Icon color="grey6" icon={'cloud_disabled'} />
                <Text color="grey8">{t.genetecGROUP.onlineInfoMessage}</Text>
            </Box>
        </Box>
    );
};

GenetecOffline.displayName = 'GenetecOffline';
