import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import type { ScheduledRecordingTypes } from '../models';

// itemId must be provided even thought it is not used because re-select expects arguments to be in the correct order
export const getRecordingType = (
    _state: IStoreState,
    _itemId: Id,
    recordingType: ScheduledRecordingTypes,
) => recordingType;
