import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    CommonActionService,
    getCurrentProjectLocked,
    getPiaItemForProductId,
} from 'app/modules/common';
import { Border, Box, IconButton, NoPrint, PiaImage, ReadDirection, Text } from 'app/components';
import { type IItemEntity, type IPersistence, type Id } from 'app/core/persistence';
import { isDiscontinued, type IPiaSoftware, type IPiaSystemComponent } from 'app/core/pia';
import { format } from 'axis-webtools-util';
import { cx } from '@emotion/css';
import { PartnerImage } from './PartnerImage';
import { RecordingChanger } from './RecordingChanger.container';
import { getHasSelectedAndAvailableAccessories } from '../selectors/getRecordingAccessories';
import { useService } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { dataCellStyle, dashedLeftBorder, darkRightBorder } from './solutionTable.styles';
import type { IRecordingItemStats } from '../selectors/getRecordingSolutionSpecs';

export interface ISelectedSolutionTableItem {
    item: IPersistence<IItemEntity>;
    stats: IRecordingItemStats | undefined;
}

export const SelectedSolutionTableItem: React.FC<ISelectedSolutionTableItem> = ({
    item,
    stats,
}) => {
    const commonActions = useService(CommonActionService);
    const piaRecordingItem = useSelector<IStoreState, (IPiaSystemComponent & IPiaSoftware) | null>(
        (state) => {
            if (item.productId === null) return null;
            const piaItem = getPiaItemForProductId(state, item.productId);
            return piaItem === null ? null : (piaItem as IPiaSystemComponent & IPiaSoftware);
        },
    );

    const isLocked = useSelector(getCurrentProjectLocked);
    const hasRecordingAccessories = useSelector(getHasSelectedAndAvailableAccessories);

    if (!stats) return null;

    const hasAvailableAccessories = hasRecordingAccessories[item._id].hasAvailableAccessories;
    const hasSelectedAccessories = hasRecordingAccessories[item._id].hasSelectedAccessories;

    const channels = stats.nrOfChannels || null;
    const licenses = stats.nrOfLicenses || null;
    const storage = stats.storage ? format.storage(stats.storage - stats.additionalStorage) : null;
    const additionalStorage =
        stats.additionalStorage > 0 ? format.storage(stats.additionalStorage) : null;
    const bandwidth = stats.bandwidth ? format.bandwidth(stats.bandwidth) : null;
    const ports = stats.ports.totalPorts || null;
    const power = stats.power > 0 ? format.power(stats.power) : null;
    const videoPorts = stats.video || null;

    const showNetworkSettingsPanel = (id: Id) => {
        commonActions.setRecordingOverlay(undefined, id);
        eventTracking.logUserEvent('System Components', 'Show recording item IP settings');
    };

    const showDeviceDetailsPanel = (id: Id) => {
        commonActions.setRecordingOverlay(id, undefined);
        eventTracking.logUserEvent('System Components', 'Show recording item accessories');
    };

    return (
        <>
            <Border bottomWidth={1} color="grey2" leftWidth={1} sideColor={{ left: 'grey3' }}>
                <Box spacing="doublePanel" paddingX="base" color="white" justifyContent="between">
                    <Box alignItems="center" spacing="base">
                        {item.productId && (
                            <PiaImage
                                imageSize="lg"
                                piaId={piaRecordingItem?.id ?? null}
                                icon="recording"
                            />
                        )}
                        {item.properties.partnerSystemComponent?.imageUrl && (
                            <PartnerImage
                                icon="recording"
                                imageUrl={item.properties.partnerSystemComponent?.imageUrl}
                            />
                        )}
                        <Text
                            testId={`${item.quantity}_quantity_${
                                piaRecordingItem ? piaRecordingItem.name : item.name
                            }`}
                        >
                            {item.quantity} × {piaRecordingItem?.name ?? item.name}
                        </Text>
                        {piaRecordingItem && isDiscontinued(piaRecordingItem) && (
                            <RecordingChanger
                                piaId={piaRecordingItem.id}
                                piaCategory={piaRecordingItem.category}
                                browseAlternativesOption
                            />
                        )}
                    </Box>
                    <Box alignItems="center" justifyContent="end" spacing="half">
                        {!!item.networkSettings && (
                            <NoPrint>
                                <Border color="blue" radius="4px">
                                    <Box padding="halfQuart">
                                        <IconButton
                                            testId={
                                                (piaRecordingItem?.name ?? item.name) +
                                                '_IP_configuration_btn'
                                            }
                                            title={t.ipConfiguration}
                                            icon="recording_bw_xs"
                                            color={'blue'}
                                            onClick={() => showNetworkSettingsPanel(item._id)}
                                        />
                                    </Box>
                                </Border>
                            </NoPrint>
                        )}
                        {((hasAvailableAccessories && !isLocked) || hasSelectedAccessories) && (
                            <NoPrint>
                                <Border color="blue" radius="4px">
                                    <Box
                                        color={hasSelectedAccessories ? 'blue' : undefined}
                                        padding="halfQuart"
                                    >
                                        <IconButton
                                            title={t.addAccessories}
                                            icon="category"
                                            color={hasSelectedAccessories ? 'white' : 'blue'}
                                            onClick={() => showDeviceDetailsPanel(item._id)}
                                        />
                                    </Box>
                                </Border>
                            </NoPrint>
                        )}
                    </Box>
                </Box>
            </Border>
            <div className={cx(dataCellStyle, dashedLeftBorder)}>{channels}</div>
            <div className={dataCellStyle}>{licenses}</div>
            <div dir="ltr" className={dataCellStyle}>
                {storage}
                <Text style="small" color="grey6" inline>
                    {additionalStorage && `+${additionalStorage}`}
                </Text>
            </div>
            <div dir="ltr" className={dataCellStyle}>
                {bandwidth}
            </div>
            <div className={cx(dataCellStyle, dashedLeftBorder)}>
                <ReadDirection direction="ltr">
                    <Text style="semibold">{power}</Text>
                </ReadDirection>
            </div>
            <div className={dataCellStyle}>{ports}</div>
            <div className={cx(dataCellStyle, dashedLeftBorder, darkRightBorder)}>{videoPorts}</div>
        </>
    );
};
