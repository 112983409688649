import type { IStoreState } from 'app/store/IStoreState';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash-es';
import { getCurrentProjectLocation } from '../../project';
import { isFeatureEnabled } from 'app/featureFlags';
import type { IPriceList } from '../../models';

const getPriceLists = (state: IStoreState) => state.common.msrp;
const getDistributors = (state: IStoreState) => state.common.distributors;

export const getDistributorForRegion = createSelector(
    [getDistributors, getCurrentProjectLocation],
    (distributors, location) => {
        if (!distributors) {
            return undefined;
        }

        return (
            distributors.list.find(
                (distributor) =>
                    distributor.countryCode.toLocaleLowerCase() ===
                    location?.countryCode.toLocaleLowerCase(),
            ) || undefined
        );
    },
);

const addMockedPriceListForLicenses = (priceList: IPriceList) => {
    priceList.list['03081-001'] = 29; //'AXIS Camera Station Center (1y) License TEMP'
    priceList.list['03082-001'] = 129; //'AXIS Camera Station Center (5y) License TEMP'
};

export const getPriceListForRegion = createSelector(
    [getPriceLists, getDistributorForRegion],
    (priceLists, distributor) => {
        if (!priceLists || !distributor) {
            return undefined;
        }
        const priceList = priceLists.find(({ locid }) => locid === distributor.locid);
        if (isFeatureEnabled('mocked_acs_center_licenses') && priceList) {
            addMockedPriceListForLicenses(priceList);
        }
        return priceList && !isEmpty(priceList.list) ? priceList : undefined;
    },
);

export const arePricesAvailable = createSelector([getPriceListForRegion], (priceLists) => {
    return priceLists !== undefined;
});
