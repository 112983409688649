import { getIdFromProps, toCacheKey } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';

const getExpandedDevices = (state: IStoreState) => state.projectDevices.expandedDevices;

export const getIsExpanded = createCachedSelector(
    [getExpandedDevices, getIdFromProps],
    (expandedDevices, itemId) => {
        return !!(itemId && expandedDevices.includes(itemId));
    },
)(toCacheKey);
