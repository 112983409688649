import { injectable } from 'inversify';
import { getScenarioModels, getLightConditions } from '../scenario';
import type {
    ScenarioId,
    Id,
    IRecordingSettingsEntity,
    IScenarioEntity,
} from 'app/core/persistence';
import type { IScenarioModel, ILightCondition } from '../models';
import { ProfileOverrideService } from './ProfileOverride.service';

@injectable()
export class ScenarioService {
    private scenarios: IScenarioModel[];
    private lightConditions: ILightCondition[];

    constructor(private profileOverrideService: ProfileOverrideService) {
        this.scenarios = getScenarioModels()
            .map((scenario) => ({
                ...scenario,
                name: scenario.name,
                image: scenario.image,
            }))
            .filter((scenario) => !scenario.inactive);
        this.lightConditions = getLightConditions().map((condition) => ({
            name: condition.name,
            value: condition.value,
            image: condition.image,
        }));
    }

    public getScenarios(): IScenarioModel[] {
        return this.scenarios;
    }

    public getScenario(id: ScenarioId): IScenarioModel | undefined {
        return this.scenarios.find((scenario) => {
            return scenario.id === id;
        });
    }

    public getScenarioOrThrow(id: ScenarioId): IScenarioModel {
        const scenario = this.getScenario(id);

        if (scenario === undefined) {
            throw Error(`Scenario ${id} does not exist`);
        }

        return scenario;
    }

    public getLightConditions(): ILightCondition[] {
        return this.lightConditions;
    }

    public setScenarioOverride(scenarioId: ScenarioId, itemId: Id) {
        const newScenario = this.getScenarios().find((scenario) => scenario.id === scenarioId);
        if (!newScenario) {
            throw new Error(`Could not find scenario with id: ${scenarioId}`);
        }

        const scenarioSettingsToSave: Partial<IScenarioEntity> = {
            lightEnd: newScenario.dependencies.scenarioLightEnd.toPersistable(),
            lightStart: newScenario.dependencies.scenarioLightStart.toPersistable(),
            nightLighting: newScenario.dependencies.nightLighting,
            scenarioId,
            sceneDetails: newScenario.dependencies.sceneDetails,
        };

        const triggeredRecordingSettings: Partial<IRecordingSettingsEntity> = {
            dayMotion: newScenario.dependencies.triggeredDayMotion,
            nightMotion: newScenario.dependencies.triggeredNightMotion,
        };

        const continuousRecordingSettings: Partial<IRecordingSettingsEntity> = {
            dayMotion: newScenario.dependencies.continuousDayMotion,
            nightMotion: newScenario.dependencies.continuousNightMotion,
        };

        const liveViewSettings: Partial<IRecordingSettingsEntity> = {
            dayMotion: newScenario.dependencies.continuousDayMotion,
            nightMotion: newScenario.dependencies.continuousNightMotion,
        };

        return this.profileOverrideService.updateProfileOverride(itemId, {
            scenario: scenarioSettingsToSave,
            triggeredRecording: triggeredRecordingSettings,
            continuousRecording: continuousRecordingSettings,
            liveView: liveViewSettings,
        });
    }
}
