import type { IPartnerConfigServerResponse } from 'app/core/persistence';
import type { IPartnerConfigState } from './../models/IPartnerConfigState';

export function convertToPartnerConfigState(
    downloadedPartnerConfig: IPartnerConfigServerResponse,
): Partial<IPartnerConfigState> {
    return {
        partnerConfigId: downloadedPartnerConfig.id,
        companyName: downloadedPartnerConfig.companyName,
        excludedProductMessage: downloadedPartnerConfig.allowlist?.excludedProductsMessage ?? '',
        isCustomPartNumberEnabled: !!downloadedPartnerConfig.allowCustomPartNumber,
        isOnlySelectedProductsAvailable: Boolean(
            downloadedPartnerConfig.allowlist &&
                !downloadedPartnerConfig.allowlist.allowExcludedProducts,
        ),
        isPartnerConfigEnabled: downloadedPartnerConfig.allowLocIdAccess,
        isPartnerConfigUrlEnabled: downloadedPartnerConfig.allowPublicUrlAccess,
        allowedProducts: [
            ...(downloadedPartnerConfig.allowlist?.recommendedProducts ?? []),
            ...(downloadedPartnerConfig.allowlist?.otherProducts ?? []),
        ],
        mappedPartNumbers: downloadedPartnerConfig.mappedPiaIds,
        logo: downloadedPartnerConfig.logo,
        style: {
            foregroundColor: downloadedPartnerConfig.style?.header.foregroundColor,
            backgroundColor: downloadedPartnerConfig.style?.header.backgroundColor,
        },
    };
}

export function convertToPartnerConfigServerResponse(
    partnerConfig: IPartnerConfigState,
): IPartnerConfigServerResponse {
    return {
        id: partnerConfig.partnerConfigId ?? '',
        companyName: partnerConfig.companyName,
        style: {
            header: {
                backgroundColor: partnerConfig.style?.backgroundColor,
                foregroundColor: partnerConfig.style?.foregroundColor,
            },
        },
        logo: partnerConfig.logo,
        allowlist: {
            recommendedProducts: [],
            allowExcludedProducts: !partnerConfig.isOnlySelectedProductsAvailable,
            excludedProductsMessage: partnerConfig.excludedProductMessage,
            otherProducts: partnerConfig.allowedProducts,
        },
        allowLocIdAccess: partnerConfig.isPartnerConfigEnabled,
        allowPublicUrlAccess: partnerConfig.isPartnerConfigUrlEnabled,
        mappedPiaIds: partnerConfig.mappedPartNumbers,
        allowCustomPartNumber: !!partnerConfig.isCustomPartNumberEnabled,
    };
}
