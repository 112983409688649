import * as React from 'react';
import { Border, Box, Text, Clickable, Icon, Title } from 'app/components';
import type { IApplicationItem } from 'app/modules/common';
import {
    isAxis,
    productItemStyle,
    selectedItemStyle,
    getPiaItem,
    getAnalyticMode,
    getIsPartnerProductAdded,
    getIsOtherSelectorOpen,
    hasApdParams,
} from 'app/modules/common';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import type { AnalyticMode, Id } from 'app/core/persistence';
import { toTestIdFormat } from 'app/components/ui-test';
import type { IPiaItem } from 'app/core/pia';
import { AXIS_PERIMETER_DEFENDER } from 'app/core/common';
import { ApplicationHoverSection } from './ApplicationHoverSection';
import {
    getIsApplicationAddedToDevice,
    getIsClickedApplication,
    getPersistedApplications,
} from '../selectors';
import { ApplicationTop } from './ApplicationTop';
import { ApplicationBottom } from './ApplicationBottom';
import { AddonSelectorActionService } from '../services';
import { useBadgeState } from '../utils';

interface IApplicationItemProps {
    application: IApplicationItem;
    parentDeviceId?: Id;
    onToggled?(): void;
}

export const ApplicationItem: React.FC<IApplicationItemProps> = ({
    application,
    parentDeviceId,
    onToggled,
}) => {
    const isApplicationAdded = useSelector<IStoreState, boolean>((state) =>
        getIsApplicationAddedToDevice(state, parentDeviceId, application.productId),
    );
    const isPartnerProductAdded = useSelector<IStoreState, boolean>((state) =>
        getIsPartnerProductAdded(state, application.productId),
    );
    const isPartnerProductOtherSelector = useSelector(getIsOtherSelectorOpen);
    const isAdded = isPartnerProductOtherSelector ? isPartnerProductAdded : isApplicationAdded;
    const isAddedOrIncluded = isAdded || (application.isIncluded ?? false);
    const isClickedApplication = useSelector<IStoreState, boolean>((state) =>
        getIsClickedApplication(state, application.productId),
    );
    const parentDevice = useSelector<IStoreState, IPiaItem | null>((state) =>
        getPiaItem(state, parentDeviceId),
    );
    const selectedAnalyticMode = useSelector<IStoreState, AnalyticMode | undefined>((store) =>
        getAnalyticMode(store, parentDeviceId),
    );

    const [hover, setHover] = React.useState<boolean>(false);
    const addonActionService = useService(AddonSelectorActionService);

    const setClickedApplication = () => {
        return addonActionService.setClickedApplication(
            isClickedApplication ? undefined : application.productId,
        );
    };

    const badgeState = useBadgeState(application, isAdded);
    const isPartnerVendor = !isAxis(application.vendor);
    const showLicense = application.acapId && application.acapId > 0;

    const setHoverFalse = React.useCallback(() => {
        setHover(false);
    }, []);

    const renderPopularity = (numberOfInstallationsWithAxis: number) => (
        <Title title={t.installationsWithAxis}>
            <Box height={15} justifyContent="end" paddingRight="quart">
                {numberOfInstallationsWithAxis > 0 && (
                    <>
                        <Icon icon="star_border" size="xs" color="grey6" />
                        <Text color="grey6" style="small" italic>
                            {`${numberOfInstallationsWithAxis}+`}
                        </Text>
                    </>
                )}
            </Box>
        </Title>
    );

    const renderTopBadge = () => (
        <Box color={badgeState.boxColor} justifyContent="center" height="15px">
            <Text color={badgeState.textColor} small>
                {badgeState.text}
            </Text>
        </Box>
    );

    const renderEmptyTopBadge = () => <Box justifyContent="center" height="15px"></Box>;

    const selectedApplications = useSelector<IStoreState, IApplicationItem[]>((store) =>
        getPersistedApplications(store, parentDeviceId),
    );

    const showAnalyticModeSetting: boolean =
        !!parentDevice &&
        hasApdParams(parentDevice) &&
        application.acapId === AXIS_PERIMETER_DEFENDER &&
        selectedApplications.some((selectedApp) => selectedApp.acapId === application.acapId);

    return (
        <div
            className={isClickedApplication ? selectedItemStyle : productItemStyle}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            data-test-id={`${toTestIdFormat(application.name)}_container`}
        >
            <Clickable onClick={setClickedApplication}>
                <Box overflow="hidden" paddingY="none" width="199px" height="250px">
                    <Border color={isAdded ? 'yellow4' : 'grey3'} radius="8px">
                        <Box
                            color={isClickedApplication ? 'yellow1' : 'white'}
                            position="relative"
                            overflow="hidden"
                            direction="column"
                            width="100%"
                        >
                            {isPartnerVendor &&
                            application.numberOfInstallationsWithAxis !== undefined
                                ? renderPopularity(application.numberOfInstallationsWithAxis)
                                : showLicense
                                  ? renderTopBadge()
                                  : renderEmptyTopBadge()}

                            <Box direction="column" justifyContent="between" flex="grow">
                                <Box direction="column" alignItems="center">
                                    <ApplicationTop
                                        application={application}
                                        parentDeviceId={parentDeviceId}
                                    />
                                    <Box
                                        alignItems="center"
                                        justifyContent="start"
                                        direction="column"
                                        height={isPartnerVendor ? '65px' : '35px'}
                                        paddingTop="base"
                                        paddingX="base"
                                    >
                                        <Text align="center">{application.name}</Text>
                                        {showAnalyticModeSetting && (
                                            <Text
                                                align="center"
                                                color="grey6"
                                                style="small"
                                                testId={`analytic_mode_${application.name}`}
                                            >
                                                {selectedAnalyticMode === 'ai'
                                                    ? t.analyticModeAi
                                                    : t.analyticModeCalibration}
                                            </Text>
                                        )}
                                        {application.vendor && isPartnerVendor && (
                                            <Text
                                                whiteSpace="nowrap"
                                                align="center"
                                                color="grey6"
                                                style="small"
                                            >
                                                {application.vendor}
                                            </Text>
                                        )}
                                    </Box>
                                </Box>

                                <ApplicationBottom
                                    application={application}
                                    parentDeviceId={parentDeviceId}
                                    onToggled={onToggled}
                                />
                            </Box>
                        </Box>
                    </Border>
                </Box>
                {(hover || isClickedApplication) && (
                    <ApplicationHoverSection
                        application={application}
                        isSelected={isAddedOrIncluded}
                        isClicked={isClickedApplication}
                        setHoverFalse={setHoverFalse}
                        parentDeviceId={parentDeviceId}
                    />
                )}
            </Clickable>
        </div>
    );
};

ApplicationItem.displayName = 'ApplicationItem';
