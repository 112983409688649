export const SliderConstants = {
    LIGHT_DARK_FADE_PERCENT: 2,
    COLOR: {
        DEFAULT: '#ffcc33',
        LIGHT: '#ffffff',
        DARK_NONE: '#fafafa',
        TRANSPARENT: 'transparent',
    },
    MODE: {
        SET_LIGHT: 0,
        SET_TIME: 1,
    },
};
