import { injectable } from 'inversify';
import { BaseRepository } from './Base.repository';
import {
    PersistenceDatabaseRepository,
    TimestampProviderService,
    EntitySettings,
    CreateEntityService,
    PersistenceMemoryRepository,
} from './persistence';
import type { IPersistence } from './persistence';
import type { IEntity } from '../entities';
import { pushUndoCommand, pushRedoCommand, clearRedoQueue } from './utils';

/*
 * This repository is used for modifying entities without prior knowledge of their
 * type. Used by the undo/redo functionality
 */
@injectable()
export class GenericEntityRepository extends BaseRepository<IEntity> {
    constructor(
        entitySettings: EntitySettings,
        persistenceRepository: PersistenceDatabaseRepository,
        persistenceMemoryRepository: PersistenceMemoryRepository,
        timestampProvider: TimestampProviderService,
        createEntityService: CreateEntityService,
    ) {
        super(
            entitySettings,
            persistenceRepository,

            persistenceMemoryRepository,
            timestampProvider,
            createEntityService,
        );
    }

    /**
     * Create a new item.
     * @param entity the item to add.
     * @param undoable whether a reverting action should be added to the undo stack.
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    public async create(
        entity: IPersistence<IEntity>,
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
    ): Promise<IPersistence<IEntity>> {
        const props = {
            ...entity,
            _id: undefined,
            _rev: undefined,
            path: entity.path.slice(0, -1),
        };
        const prefix = entity._id.split(':')[0];
        const newEntity = this.createEntityService.create(prefix, props);
        const result = this.getRepository().add(newEntity) as Promise<IPersistence<IEntity>>;

        const revertingCommand = {
            deletes: [
                {
                    id: newEntity._id,
                },
            ],
        };

        if (undoable) {
            pushUndoCommand(revertingCommand);
        }
        if (redoable) {
            pushRedoCommand(revertingCommand);
        }
        if (clearRedoHistory) {
            clearRedoQueue();
        }

        return result;
    }

    protected prefix() {
        throw Error('Should never be called');
        return 'generic, not used';
    }
}
