import * as React from 'react';
import type { IWithChildren } from 'app/components';
import { AutoComplete, DropDownMenuButton } from 'app/components';
import { GoogleMapsService } from './services';
import { getPiaLocations } from './selectors';
import type { IPlaceSearchResult } from './models';
import { t } from 'app/translate';
import { debounce } from 'axis-webtools-util';
import { useSelector } from 'react-redux';
import { isImplicitlySelectableLocation } from 'app/core/pia';

interface ISearchResult {
    description: string;
    place_id: string;
}

interface IPlaceSearchProps extends IWithChildren {
    noBorder?: boolean;
    noIcon?: boolean;
    openInPortal?: boolean;
    placeHolderHint?: string;
    onSelect(location: IPlaceSearchResult): void;
    onSearchTextChange?(text: string): void;
    onClear?(): void;
}

const latLngRegExp = /^-?\d{1,3}(?:\.\d+)?, -?\d{1,3}(?:\.\d+)?$/;
const pilotRegexp = new RegExp(latLngRegExp);

export const PlaceSearch: React.FC<IPlaceSearchProps> = ({
    noBorder,
    noIcon,
    placeHolderHint,
    openInPortal = true,
    onSearchTextChange,
    onSelect,
    onClear,
    children,
}) => {
    const [searchText, setSearchText] = React.useState<string>('');
    const [searchResult, setSearchResult] = React.useState<Array<ISearchResult>>([]);
    const piaLocations = useSelector(getPiaLocations);

    const search = debounce(async (text: string) => {
        const results = await GoogleMapsService.getAutoCompleteResultsForAddress(text);
        setSearchResult(results);
    });

    const searchLatLng = debounce(async (text: string) => {
        const results = await GoogleMapsService.getLocationForLatLng(text);
        setSearchResult(results);
    });

    const handleValueChange = (text: string) => {
        onSearchTextChange?.(text);
        setSearchText(text);
        if (text === '') {
            setSearchResult([]);
        } else if (pilotRegexp.exec(text)) {
            searchLatLng(text);
        } else if (text !== '') {
            search(text);
        } else {
            if (onClear) {
                setSearchResult([]);
                onClear();
            }
        }
    };
    const select = async (result: ISearchResult) => {
        const place = await GoogleMapsService.getLocationForPlaceId(result.place_id);
        const firstMatch = place[0];
        setSearchText(firstMatch.address.split('\n').join(', '));

        const piaLocationsFiltered = piaLocations.filter(isImplicitlySelectableLocation);

        const locationId = piaLocationsFiltered.find(
            ({ countryCode }) =>
                countryCode.toLowerCase() === firstMatch?.countryCode?.toLowerCase(),
        )?.id;

        onSelect({
            location: firstMatch.location,
            bounds: firstMatch.bounds,
            address: firstMatch.address,
            locationId,
        });
    };

    const suggestions: React.ReactNode[] = searchResult.map((result, i) => (
        <DropDownMenuButton
            testId={`Location_search_result_nr_${i}`}
            width={300}
            label={result.description}
            onClick={() => select(result)}
        />
    ));

    if (children) {
        suggestions.unshift(children);
    }

    return (
        <AutoComplete
            grow
            autoFocus
            noBorder={noBorder}
            noMatchHint={t.placeSearchNoMatch}
            placeholderHint={placeHolderHint ?? t.placeSearchPlaceholder}
            openInPortal={openInPortal}
            searchText={searchText}
            minWidth={300}
            icon={noIcon ? undefined : 'place'}
            testId="type_to_find_a_location"
            suggestions={suggestions.length > 0 ? suggestions : undefined}
            onValueChange={handleValueChange}
            onClear={onClear}
        />
    );
};

PlaceSearch.displayName = 'PlaceSearchContainer';
