import { t } from 'app/translate';
import * as React from 'react';
import { Box, Icon, Text } from 'app/components';

export const SBOMDownload: React.FC = () => {
    return (
        <a href="assets/sbom/site-designer-sbom.json" download>
            <Box spacing="half">
                <Icon icon="file_download" opaque testId="download_sbom_button" />
                <Text color="blue" style="heading">
                    {t.downloadSbom}
                </Text>
            </Box>
        </a>
    );
};

SBOMDownload.displayName = 'SBOMDownload';
