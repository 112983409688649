import { createCachedSelector } from 're-reselect';
import type { IProfileOverridePropertiesEntity, IItemEntity } from 'app/core/persistence';
import { getCurrentProjectItem } from '../../project';
import { toCacheKey } from '../../cacheKey';

export const getHasEditedProfile = createCachedSelector([getCurrentProjectItem], (item) => {
    return hasOverridesExceptCustomBandwidth(getProfileOverride(item));
})(toCacheKey);

export const getHasEditedCustomBandwidthProfile = createCachedSelector(
    [getCurrentProjectItem],
    (item) => {
        const profileOverride = getProfileOverride(item);
        return profileOverride?.customBandwidth !== undefined;
    },
)(toCacheKey);

export const getEditedProfileScenarioId = createCachedSelector([getCurrentProjectItem], (item) => {
    return getProfileOverride(item)?.scenario?.scenarioId;
})(toCacheKey);

const getProfileOverride = (item?: IItemEntity) =>
    (
        item?.properties.camera ||
        item?.properties.sensorUnit ||
        item?.properties.analogCamera ||
        item?.properties.virtualProduct
    )?.profileOverride;

function hasOverridesExceptCustomBandwidth(profileOverride?: IProfileOverridePropertiesEntity) {
    if (profileOverride) {
        const profileOverrideCopy = Object.assign({}, profileOverride);
        delete profileOverrideCopy.customBandwidth;
        const members = Object.values(profileOverrideCopy);

        return members.some((properties) => {
            if (typeof properties === 'object') {
                const values = Object.values(properties);
                return values.some((value) => value !== undefined);
            }

            return typeof properties !== 'undefined';
        });
    }
    return false;
}
