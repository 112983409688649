import * as React from 'react';
import type { IProduct } from 'app/modules/common';
import { Subscription } from 'app/modules/common';
import { Box, Currency, toTestIdFormat } from 'app/components';

export interface IQuotationViewSubsectionProps {
    header: string;
    products: IProduct[];
    currency: string;
    locale: string;
}

export class QuotationViewSubsection extends React.Component<IQuotationViewSubsectionProps> {
    public render() {
        const { products } = this.props;

        if (products.length === 0) {
            return null;
        }

        const tableRows = products.map((product: IProduct) => (
            <tr key={product.id}>
                <th scope="row">
                    <Box spacing="base" width="100%" justifyContent="between" alignItems="center">
                        <div data-test-id={`div_name_${toTestIdFormat(product.name)}`}>
                            {product.name}
                        </div>
                        <Subscription
                            subscriptionIntervalInMonths={product.subscriptionIntervalInMonths}
                        />
                    </Box>
                </th>
                <td>{product.partNumber}</td>
                <td>
                    <Currency
                        value={product.quotePrice === undefined ? null : product.quotePrice}
                        locale={this.props.locale}
                        currency={this.props.currency}
                    />
                </td>
                <td>{product.quantity}</td>
                <td>
                    <Currency
                        value={this.calculateTotal(product.quantity, product.quotePrice)}
                        locale={this.props.locale}
                        currency={this.props.currency}
                    />
                </td>
            </tr>
        ));

        return (
            <tbody>
                <tr>
                    <th colSpan={5} scope="colgroup">
                        {this.props.header}
                    </th>
                </tr>

                {tableRows}
            </tbody>
        );
    }

    private calculateTotal = (amount: number, price: number | undefined) => {
        return price === undefined ? null : amount * price;
    };
}
