import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';
import { getCurrentProject } from '../../project';
import { toCacheKey } from '../../cacheKey';
import type { PreviewImageType } from '../models/PreviewImageType';

const USA_LOCATION_ID = 230;
const CANADA_LOCATION_ID = 37;

const getCategoryFromProps = (_state: IStoreState, cameraCategory: string | undefined) =>
    cameraCategory;

export const getPreviewImageType = createCachedSelector(
    [getCurrentProject, getCategoryFromProps],
    ({ installationPiaLocationId }, cameraCategory): PreviewImageType => {
        if (cameraCategory === 'thermal') {
            return 'Thermal';
        } else {
            return installationPiaLocationId === USA_LOCATION_ID ||
                installationPiaLocationId === CANADA_LOCATION_ID
                ? 'US'
                : 'Other';
        }
    },
)(toCacheKey);
