import type {
    IBounds,
    PanoramaModes,
    DeviceType,
    IInstallationPointSensor,
    IInstallationPointSpeaker,
    IFloorPlanEntity,
    IInstallationPointRadar,
    IInstallationPointModel,
} from 'app/core/persistence';
import type { IPiaRadarDetector } from 'app/core/pia';
import type { Colors } from 'app/styles';
import type { IMiniMapMarker, IMiniMapSensor } from './models';
import { getOffset, isDefined } from 'axis-webtools-util';
import { getDeviceIconByType } from 'app/modules/common';
import { defaultColors } from 'app/core/common';

// make sure topLeft is actually not above and to the left of bottomRight
const sanitizeBounds = (bounds: IBounds | undefined) => {
    if (!bounds) {
        return {
            topLeft: {
                lat: 0,
                lng: 0,
            },
            bottomRight: {
                lat: 0,
                lng: 0,
            },
        };
    }
    return {
        topLeft: {
            lat: Math.max(bounds.topLeft.lat, bounds.bottomRight.lat),
            lng: Math.min(bounds.topLeft.lng, bounds.bottomRight.lng),
        },
        bottomRight: {
            lat: Math.min(bounds.topLeft.lat, bounds.bottomRight.lat),
            lng: Math.max(bounds.topLeft.lng, bounds.bottomRight.lng),
        },
    };
};

export const toMiniMapMarker = (
    ip: IInstallationPointModel,
    floorPlan: IFloorPlanEntity,
    ipColor: Colors,
    deviceType?: DeviceType,
    legendId?: string,
): IMiniMapMarker => {
    const saneBounds = sanitizeBounds(floorPlan.image?.bounds);

    const offsetToIP = getOffset(saneBounds.topLeft)(ip.location);
    const offsetToBottomRight = getOffset(saneBounds.topLeft)(saneBounds.bottomRight);

    const relativeCoords = [
        offsetToIP[0] / offsetToBottomRight[0],
        offsetToIP[1] / offsetToBottomRight[1],
    ];
    const fallbackColor = defaultColors.DEFAULT_DEVICE_COLOR;

    // set "panorama mode" for speakers according to placement. This is somewhat
    // hacky but since we want to add support for real camera- and speaker cones
    // soon this will change anyway.
    let panoramaMode =
        ip.parentDevice.properties.camera?.filter.panoramaMode ??
        (ip.parentDevice.properties.speaker?.filter.placement === 'wall' ? false : 'horizontal');
    if (ip.parentDevice.properties.radarDetector) {
        // force MiniMap component to render halfcircle for radar.
        panoramaMode = false;
    }

    const piaRadar = ip.parentPiaDevice as IPiaRadarDetector | null;

    return {
        deviceId: ip.parentDevice._id,
        installationPointId: ip._id,
        floorPlanId: floorPlan._id,
        xRelative: relativeCoords[0],
        yRelative: relativeCoords[1],
        color: ipColor ?? fallbackColor,
        sensors: [
            ...(ip.sensors ?? []).map(ipSensorToMiniMapSensor),
            ip.speaker ? ipSpeakerToMiniMapSensor(ip.speaker, panoramaMode) : undefined,
            ip.radar ? ipRadarToMiniMapSensor(ip.radar, piaRadar) : undefined,
        ].filter(isDefined),
        panoramaMode,
        legendId,
        icon: getDeviceIconByType(deviceType) ?? 'place',
    };
};

const ipSensorToMiniMapSensor = (ipSensor: IInstallationPointSensor): IMiniMapSensor => {
    return {
        rotation: ipSensor.target.horizontalAngle,
        fov: ipSensor.settings.horizontalFov,
    };
};

const ipSpeakerToMiniMapSensor = (
    ipSpeaker: IInstallationPointSpeaker,
    panoramaMode?: PanoramaModes,
): IMiniMapSensor => {
    return {
        rotation: ipSpeaker.target.horizontalAngle,
        fov: panoramaMode === 'horizontal' ? 180 : 90,
    };
};

const ipRadarToMiniMapSensor = (
    ipRadar: IInstallationPointRadar,
    piaRadar: IPiaRadarDetector | null,
): IMiniMapSensor => {
    const fieldOfDetection = piaRadar?.properties.radarHorizontalFieldOfDetection;
    return {
        rotation: ipRadar.target.horizontalAngle,
        fov: fieldOfDetection ? parseInt(fieldOfDetection) : 180,
    };
};
