import type { VendorEnum } from './Vendor.enum';
import type { IProduct } from './IProduct';
import { CategoryEnum } from './Category.enum';

export const quotationFilter = {
    /**
     * Filter function for filtering products by a vendor
     */
    byVendor: (vendor: VendorEnum) => (product: IProduct) => product.vendor === vendor,

    /**
     * Filter function for filtering products by several vendors
     */
    byVendors:
        (...vendors: VendorEnum[]) =>
        (product: IProduct) =>
            vendors.includes(product.vendor),

    /**
     * Filter an array of products by a vendor enum
     */
    filterProductsByVendor: (products: IProduct[], vendor: VendorEnum) =>
        products.filter(quotationFilter.byVendor(vendor)),

    /**
     * Filter an array of products by several vendor enums
     */
    filterProductsByVendors: (products: IProduct[], ...vendors: VendorEnum[]) =>
        products.filter(quotationFilter.byVendors(...vendors)),

    /**
     * Filter function for filtering products by a category
     */
    byCategory: (category: CategoryEnum) => (product: IProduct) => product.category === category,

    /**
     * Filter function for filtering products by several categories
     */
    byCategories:
        (...categories: CategoryEnum[]) =>
        (product: IProduct) =>
            categories.includes(product.category),

    /**
     * Filter an array of products by a category enum
     */
    filterProductsByCategory: (products: IProduct[], category: CategoryEnum) =>
        products.filter(quotationFilter.byCategory(category)),

    /**
     * Filter an array of products by several category enums
     */
    filterProductsByCategories: (products: IProduct[], ...categories: CategoryEnum[]) =>
        products.filter(quotationFilter.byCategories(...categories)),

    /**
     * Apply a render function for each available category in the CategoryEnum enum
     */
    renderAllCategories: (renderFunction: (category: CategoryEnum) => JSX.Element) =>
        allCategories.map(renderFunction),
};

const allCategories: CategoryEnum[] = Object.keys(CategoryEnum).map(
    (name: string) => (CategoryEnum as any)[name],
);
