import { ProjectService } from './Project.service';
import { injectable } from 'inversify';
import type { IBlockerEntity, Id, IIdRev } from '../userDataPersistence';
import { BlockerRepository } from '../userDataPersistence';
import type { ILatLng } from '../models';
import { CurrentProjectService } from './CurrentProject.service';

@injectable()
export class BlockerService {
    constructor(
        private blockerRepository: BlockerRepository,
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async addBlocker(
        projectId: Id,
        mapId: Id,
        latLngs: ILatLng[],
        options: {
            global?: boolean;
            floorLevel?: number;
        },
    ): Promise<IBlockerEntity> {
        const path = [projectId];

        if (!options?.global) {
            path.push(mapId);
        }

        return this.blockerRepository.add({
            type: 'blocker',
            path,
            floorLevel: options.floorLevel ?? 0,
            latLngs,
            mapOrigin: mapId,
            archived: await this.projectService.isArchived(projectId),
            locked: await this.projectService.isLocked(projectId),
        });
    }

    public async updateBlocker(blocker: IBlockerEntity): Promise<IBlockerEntity> {
        const entity = await this.blockerRepository.get(blocker._id);

        return this.blockerRepository.update({
            _id: entity._id,
            _rev: entity._rev,
            creationDate: entity.creationDate,
            entityVersion: entity.entityVersion,
            locked: entity.locked,
            archived: entity.archived,
            path: blocker.path,
            floorLevel: blocker.floorLevel,
            latLngs: blocker.latLngs,
            mapOrigin: blocker.mapOrigin,
            updatedDate: entity.updatedDate,
            type: 'blocker',
        });
    }

    public async updateBlockers(blockers: IBlockerEntity[]): Promise<IBlockerEntity[]> {
        return this.blockerRepository.bulkUpdate(blockers);
    }

    public async removeBlocker(blockerId: Id): Promise<Id> {
        const deletedId = await this.blockerRepository.deleteById(blockerId, true, true);
        // Touches the current project in order for date/time to update correctly
        await this.currentProjectService.touchCurrentProjectEntity();
        return deletedId;
    }

    public removeBlockers(blockers: IIdRev[]): Promise<IIdRev[]> {
        return this.blockerRepository.bulkDelete(blockers);
    }
}
