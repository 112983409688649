import * as leaflet from 'leaflet';
import * as geolib from 'geolib';
import type { IFloorPlanImage, IBounds } from 'app/core/persistence';
import { AXIS_HQ } from '../locations';

export const utils = {
    calculateDistancePosition(latLng: leaflet.LatLng, distance: number, rotation: number = 0) {
        const newDist = geolib.computeDestinationPoint(
            { latitude: latLng.lat, longitude: latLng.lng },
            distance,
            rotation + 90, // Starts a 90
        );
        return leaflet.latLng(newDist.latitude, newDist.longitude);
    },
    transformCoordinateWithNewScale(
        currentCoord: leaflet.LatLng,
        southWest: leaflet.LatLng,
        scaleRatio: number,
    ) {
        const currentDistanceX = leaflet
            .latLng(currentCoord.lat, southWest.lng)
            .distanceTo(currentCoord);
        const newDistanceX = currentDistanceX * scaleRatio;

        const currentDistanceY = leaflet
            .latLng(southWest.lat, currentCoord.lng)
            .distanceTo(currentCoord);
        const newDistanceY = currentDistanceY * scaleRatio;

        const newCoord1 = utils.calculateDistancePosition(southWest, newDistanceX);
        const newCoord2 = utils.calculateDistancePosition(newCoord1, newDistanceY, -90);

        return leaflet.latLng(newCoord2.lat, newCoord1.lng);
    },
    /**
     * Get the temporary (estimated) bounds of a floorPlanImage
     * without any set bounds using a "pixels per meter" approximation
     */
    getFloorPlanImageTemporaryBounds(floorPlanImage: IFloorPlanImage): IBounds {
        const topLeft = floorPlanImage.bounds
            ? leaflet.latLng(floorPlanImage.bounds.topLeft)
            : leaflet.latLng(AXIS_HQ[0], AXIS_HQ[1]);

        const pixelsPerMeter = 20;
        const size = {
            width: floorPlanImage.dimensions.width,
            height: floorPlanImage.dimensions.height,
        };
        const bottom = utils.calculateDistancePosition(topLeft, size.height / pixelsPerMeter, 90);
        const right = utils.calculateDistancePosition(topLeft, size.width / pixelsPerMeter, 0);

        const bottomRight = floorPlanImage.bounds
            ? leaflet.latLng(floorPlanImage.bounds.bottomRight)
            : leaflet.latLng(bottom.lat, right.lng);

        return { topLeft, bottomRight };
    },
};

export const clientIsMacOs = () => navigator.userAgent.includes('Mac');

export const clientIsWindowsOs = () => navigator.userAgent.includes('Win');
