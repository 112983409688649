import { injectable } from 'inversify';
import type { IBaseEntity } from '../../entities';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IPersistenceRepository } from '../../repositories';

/**
 * Make archived flag mandatory on all entities
 */

@injectable()
export class Migration40to41 extends MigrationProviderBase {
    public from: number = 40;
    public to: number = 41;

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entity.archived !== undefined) {
                return entity;
            }

            const newEntity = { ...entity };
            if (newEntity.type === 'project') {
                newEntity.archived = false;
            } else {
                // inherit from project
                try {
                    const projectId = newEntity.path[0];
                    const project = await repository.get(projectId);

                    newEntity.archived = project.archived || false;
                } catch {
                    // Due to the non-transactional nature of couchdb, we may
                    // encounter orphan items. Let's set them to archived
                    newEntity.archived = true;
                }
            }

            return newEntity;
        };
    }
}
