import { injectable } from 'inversify';
import type { IBaseEntity, IItemEntity } from '../../entities';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Change color for radar from 'green' to 'devicePalette5' and for speaker from 'yellow' to 'devicePalette3'
 */
const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

@injectable()
export class Migration38To39 extends MigrationProviderBase {
    public from: number = 38;
    public to: number = 39;

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (entity.properties.speaker && entity.color === 'yellow') {
                    entity.color = 'devicePalette3';
                }
                if (entity.properties.radarDetector && entity.color === 'green') {
                    entity.color = 'devicePalette5';
                }
            }

            return entity;
        };
    }
}
