import * as React from 'react';
import { css } from '@emotion/css';
import { Positioned, Text, Box, Border, TableRow } from 'app/components';
import { t } from 'app/translate';
import { SpacingsEnum } from 'app/styles';
import { convertDensityToDisplayUnit } from 'app/core/common';
import { useSelector } from 'react-redux';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';
import type { PreviewImageType } from '../../models/PreviewImageType';
import { getCurrentProjectDisplayUnit } from '../../../project/selectors/getCurrentProject';

const previewImage = require('./resolution-preview-540mm-640px.png');
const previewImageUs = require('./resolution-preview-540mm-640px-us.png');
const previewImageThermal = require('./resolution-preview-540mm-640px-thermal.png');

const previewImageSizeInMeters = 0.54;
const aspectRatio = 1;
const minWidth = 90;

interface IResolutionPreviewComponentProps {
    resolutionPreviewImageType: PreviewImageType;
    unit: string;
    showDetails?: boolean;
    tiltAngle?: number;
    alternateLensName?: string;
    hidePixelPerUnit?: boolean;
    isGenericCamera?: boolean;
    pixelDensityPerMeter: number;
    piaCamera: IPiaCamera | IPiaSensorUnit | undefined;
}

export const ResolutionPreviewComponent: React.FC<IResolutionPreviewComponentProps> = ({
    piaCamera,
    resolutionPreviewImageType,
    unit,
    showDetails,
    tiltAngle,
    alternateLensName,
    hidePixelPerUnit,
    isGenericCamera,
    pixelDensityPerMeter,
}) => {
    const noLens =
        !alternateLensName &&
        piaCamera?.properties.maxHorizontalFOV === 0 &&
        piaCamera?.properties.minHorizontalFOV === 0;
    const canvas = React.createRef<HTMLCanvasElement>();
    const image = React.useMemo(() => new Image(), []);

    const draw = (width?: number, height?: number) => {
        const canvasEl = canvas.current;
        if (!canvasEl) {
            return;
        }

        const parentWidth =
            Math.round(canvasEl.parentElement!.getBoundingClientRect().width) > 0
                ? Math.round(canvasEl.parentElement!.getBoundingClientRect().width)
                : minWidth;
        const canvasWidth = width ?? parentWidth;
        const canvasHeight = height || Math.round(canvasWidth * aspectRatio);

        const context = canvasEl.getContext('2d');
        if (context !== null && image.height > 0) {
            canvasEl.width = canvasWidth;
            canvasEl.height = canvasHeight;
            context.clearRect(0, 0, canvasEl.width, canvasEl.height);

            const scaledWidth = Math.max(
                Math.min(Math.round(pixelDensityPerMeter * previewImageSizeInMeters), canvasWidth),
                1,
            );
            const scaledHeight = Math.min(Math.round(scaledWidth * aspectRatio), canvasHeight);

            context.imageSmoothingEnabled = context.imageSmoothingEnabled = true;
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.drawImage(image, 0, 0, scaledWidth, scaledHeight);
            context.drawImage(
                canvasEl,
                0,
                0,
                scaledWidth,
                scaledHeight,
                0,
                0,
                canvasWidth,
                canvasHeight,
            );
        }
    };
    image.onload = () => draw();
    const getImageSrc = (previewImageType: PreviewImageType) => {
        switch (previewImageType) {
            case 'US':
                return previewImageUs;
            case 'Thermal':
                return previewImageThermal;
            default:
                return previewImage;
        }
    };
    image.src = getImageSrc(resolutionPreviewImageType);
    const formatPixelDensityWithUnit = (): string => {
        return `${convertDensityToDisplayUnit(pixelDensityPerMeter, displayUnit)} ${unit}`;
    };
    const displayUnit = useSelector(getCurrentProjectDisplayUnit);

    React.useEffect(() => {
        draw();
    });
    React.useEffect(() => {
        image.src = getImageSrc(resolutionPreviewImageType);
    }, [image, resolutionPreviewImageType]);

    return showDetails ? (
        <Box direction="column" alignItems="center" spacing="base">
            <Border color="grey3" radius="4px">
                <Box maxWidth="150px" overflow="hidden">
                    <canvas
                        ref={canvas}
                        className={css`
                            opacity: ${isGenericCamera ? 0.1 : 1};
                        `}
                    />
                </Box>
            </Border>
            <table
                cellPadding={SpacingsEnum.quart}
                className={css`
                    width: 100%;
                `}
            >
                <tbody>
                    {alternateLensName && (
                        <TableRow
                            columnWidth="50%"
                            cells={[
                                <Text color="grey6" align="right">
                                    {`${t.cameraSelectorFieldOfViewLens}:`}
                                </Text>,
                                <Box alignItems="end">
                                    <Text
                                        testId="alternate_lens_name"
                                        style="semibold"
                                        color="grey7"
                                        align="left"
                                    >
                                        {alternateLensName}
                                    </Text>
                                </Box>,
                            ]}
                        />
                    )}
                    {noLens && (
                        <Text align="center" color="grey6">
                            {t.noLens}
                        </Text>
                    )}
                    {!isGenericCamera && !noLens && (
                        <TableRow
                            columnWidth="50%"
                            cells={[
                                <Text color="grey6" align="right">
                                    {t.cameraSelectorFieldOfViewPixelDensity}:
                                </Text>,
                                <Text testId="pixel_density" style="semibold" color="grey7">
                                    {formatPixelDensityWithUnit()}
                                </Text>,
                            ]}
                        />
                    )}
                    {tiltAngle !== undefined && !noLens && (
                        <TableRow
                            columnWidth="50%"
                            cells={[
                                <Text color="grey6" align="right">
                                    {t.installationReportInstallationTiltTitle}:
                                </Text>,
                                <Text testId="tilt_angle" style="semibold" color="grey7">
                                    {t.installationReportInstallationTiltAngle(
                                        Math.abs(Math.round(tiltAngle)),
                                    )}
                                    &nbsp;
                                    {tiltAngle > 0 &&
                                        t.installationReportInstallationTiltDirectionUp}
                                    {tiltAngle < 0 &&
                                        t.installationReportInstallationTiltDirectionDown}
                                </Text>,
                            ]}
                        />
                    )}
                </tbody>
            </table>
        </Box>
    ) : (
        <Border color="grey3">
            <Box position="relative" width="100%">
                <canvas
                    ref={canvas}
                    className={css`
                        width: 100%;
                        height: 100%;
                    `}
                />
                {!hidePixelPerUnit && (
                    <Positioned position="absolute" top="0" left="0">
                        <Box color="white" padding="quart" borderRadius="blunt">
                            <Text testId="canvas_pixel_density" small>
                                {formatPixelDensityWithUnit()}
                            </Text>
                        </Box>
                    </Positioned>
                )}
            </Box>
        </Border>
    );
};

ResolutionPreviewComponent.displayName = 'ResolutionPreviewComponent';
