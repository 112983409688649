import type { IPrice } from './models';

interface ISortableByPrice {
    price?: IPrice;
}

export const priceLowToHigh = (prev: ISortableByPrice, next: ISortableByPrice) => {
    const prevPrice = prev.price?.value || 0;
    const nextPrice = next.price?.value || 0;
    return prevPrice - nextPrice;
};

export const priceHighToLow = (prev: ISortableByPrice, next: ISortableByPrice) => {
    const prevPrice = prev.price?.value || 0;
    const nextPrice = next.price?.value || 0;
    return nextPrice - prevPrice;
};
