export const getDropPosition = (e: MouseEvent, mapDiv: HTMLDivElement): [number, number] => {
    const mapRect = mapDiv.getBoundingClientRect();
    const mapX = mapRect.left;
    const mapY = mapRect.top;
    // Get drop event location
    const eventX = e.pageX;
    const eventY = e.pageY;
    // Calculate drop position in map
    // (this is done to make sure that the camera lands where the user drops it,
    // without interference from objects in the map such as camera cones and blockers)
    const position: [number, number] = [eventX - mapX, eventY - mapY];
    return position;
};
