import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import {
    getProductNameParts,
    getReplacementsForPiaId,
    CommonActionService,
    getCurrentProjectLocked,
} from 'app/modules/common';
import type { IPiaItem, PiaCategory, PiaId } from 'app/core/pia';
import {
    PiaAccessoryCategory,
    PiaItemRecorderCategory,
    PiaItemSoftwareCategory,
} from 'app/core/pia';
import {
    DropDownMenuText,
    DropDownMenuButton,
    Border,
    Box,
    Badge,
    DropDown,
    Stack,
    Icon,
    Text,
} from 'app/components';
import { t } from 'app/translate';
import { ServiceLocator, useService } from 'app/ioc';
import { RecordingSelectorService } from '../services';
import { eventTracking } from 'app/core/tracking';
import type { RecordingCategoriesFilter } from 'app/core/persistence';
import { UserPreferencesService } from 'app/core/persistence';

interface IRecordingChangerProps {
    /**
     * Product Id for the device that can be replaced.
     */
    piaId: PiaId;
    /**
     * Category for the device to replace.
     * This is used to determine which tab should open when browsing replacements.
     */
    piaCategory?: PiaCategory;
    /**
     * Adds a dropdown button for browsing alternatives.
     * The button will open up the customize solution tab corresponding to its piaCategory.
     */
    browseAlternativesOption?: boolean;
}

export const RecordingChanger: React.FC<IRecordingChangerProps> = ({
    piaId,
    piaCategory,
    browseAlternativesOption,
}) => {
    const [commonActionService] = React.useState<CommonActionService>(
        ServiceLocator.get(CommonActionService),
    );
    const [recordingSelectorService] = React.useState<RecordingSelectorService>(
        ServiceLocator.get(RecordingSelectorService),
    );

    const replacements = useSelector<IStoreState, IPiaItem[]>((state) =>
        getReplacementsForPiaId(state, piaId),
    );

    const projectIsLocked = useSelector<IStoreState, boolean>(getCurrentProjectLocked);

    const userPreferencesService = useService(UserPreferencesService);

    const openCustomSolutionTab = () => {
        userPreferencesService.set({ customizeSolutionRecordingCategory: getTabName() });
        commonActionService.setRecordingOverlay(undefined, undefined, true);
    };

    const getTabName = (): RecordingCategoriesFilter => {
        switch (piaCategory) {
            case PiaItemRecorderCategory.RECORDERS2:
                return 'servers';
            case PiaItemRecorderCategory.DESKTOPTERMINALS:
                return 'terminals';
            case PiaAccessoryCategory.NETWORKSWITCHES:
                return 'switches';
            case PiaItemSoftwareCategory.VMS:
                return 'licenses';
            default:
                return 'all';
        }
    };

    const onChangeModel = async (currentPiaId: PiaId, replacementPiaId: PiaId) => {
        eventTracking.logUserEvent(
            'System Components',
            'Replace discontinued recording device',
            `${currentPiaId} with ${replacementPiaId}`,
        );
        recordingSelectorService.replaceDevice(currentPiaId, replacementPiaId);
    };

    // If there are no buttons in the dropdown we render the discontinued badge instead of the dropdown menu
    if (replacements.length === 0 && !browseAlternativesOption) {
        return <Badge label={t.badgeDiscontinued} type="red" compact />;
    }

    return (
        <DropDown
            disabled={projectIsLocked}
            contents={
                <>
                    <DropDownMenuText
                        label={t.deviceListDiscontinuedHeader}
                        icon="warning_small"
                        iconProps={{ color: 'red', opaque: true }}
                        description={t.deviceListDiscontinuedDescription}
                    />
                    {replacements.map((device: IPiaItem) => (
                        <DropDownMenuButton
                            label={getProductNameParts(device.name).model}
                            key={device.id}
                            onClick={() => onChangeModel(piaId, device.id)}
                            description={getProductNameParts(device.name).description}
                            piaImageProps={{
                                icon: 'recording',
                                piaId: device.id,
                                imageSize: 'lg',
                            }}
                        />
                    ))}
                    {browseAlternativesOption && (
                        <DropDownMenuButton
                            label={t.deviceListDiscontinuedAction}
                            onClick={openCustomSolutionTab}
                        />
                    )}
                </>
            }
            trigger={
                <Border color="red" radius="4px">
                    <Box paddingLeft="half">
                        <Stack spacing="half">
                            <Icon icon="warning_small" color="red" size="xs" opaque></Icon>
                            <Text style="body" color="red">
                                {t.discontinued}
                            </Text>
                            <Icon icon="arrow_down" size="md"></Icon>
                        </Stack>
                    </Box>
                </Border>
            }
        ></DropDown>
    );
};
