import type { IPersistence, IItemEntity } from 'app/core/persistence';
import { injectable } from 'inversify';

@injectable()
export class QuantityUpdateVerifier {
    private updateVerifiers: ((
        entity: IPersistence<IItemEntity>,
        newQuantity: number,
    ) => Promise<boolean>)[] = [];

    public async inquireQuantityUpdate(
        entity: IPersistence<IItemEntity>,
        newQuantity: number,
    ): Promise<boolean> {
        const isUpdateAllowed = await Promise.all(
            this.updateVerifiers.map((updateCheck) => updateCheck(entity, newQuantity)),
        );
        return isUpdateAllowed.every((allowUpdate) => allowUpdate);
    }

    public observeQuantityUpdates(
        updateCheck: (entity: IPersistence<IItemEntity>, newQuantity: number) => Promise<boolean>,
    ) {
        this.updateVerifiers.push(updateCheck);
    }
}
