import { t } from 'app/translate';
import * as React from 'react';
import { FilterPanel } from './FilterPanel';
import { Action, Text, Grid, PillButton, Stack } from 'app/components';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { ITwoNFilter, twoNType } from '../../models';
import { defaultTwoNFilter } from '../../models';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { isEqual } from 'lodash-es';
import { getIsEditMode } from '../../selectors';
import { SearchField } from './common';

export const TwoNFilterPanel: React.FC = () => {
    const [deviceSelectorActionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);
    const twoNFilter = useSelector<IStoreState, ITwoNFilter>(
        (store) => store.deviceSelector.twoNFilter,
    );
    const { selectedCategory } = twoNFilter;

    /**
     * Updates redux state for checkbox filters
     * @param filterType - The checkbox interacted with
     */
    const updateSelectedCategory = (filterType: twoNType) => {
        deviceSelectorActionService.updateTwoNFilter({
            selectedCategory: filterType === selectedCategory ? undefined : filterType,
        });
    };

    const isEditMode = useSelector<IStoreState, boolean>((store) => getIsEditMode(store));

    /**
     * Resets main unit filter to its default values.
     * Also sets includeDiscontinued to false and removes search text.
     */
    const resetFilter = () => {
        if (searchText) {
            deviceSelectorActionService.setSearchText('');
        }
        if (!isEqual(twoNFilter, defaultTwoNFilter)) {
            deviceSelectorActionService.updateTwoNFilter(defaultTwoNFilter);
        }
    };

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                {!isEditMode && (
                    <>
                        <Stack alignItems="center" justifyContent="between">
                            <Text style="semibold" color="grey6">
                                {t.cameraSelectorRequirementsTitle}
                            </Text>
                            <Action title={t.reset} onClick={resetFilter} />
                        </Stack>
                        <Grid spacing="halfQuart">
                            <PillButton
                                text={t.doorStations}
                                selected={selectedCategory === 'interComs'}
                                onClick={() => updateSelectedCategory('interComs')}
                            />
                            <PillButton
                                text={t.answeringUnits}
                                selected={selectedCategory === 'answeringUnits'}
                                onClick={() => updateSelectedCategory('answeringUnits')}
                            />
                            <PillButton
                                text={t.accessServers}
                                selected={selectedCategory === 'accessServers'}
                                onClick={() => updateSelectedCategory('accessServers')}
                            />
                            <PillButton
                                text={t.networkReaders}
                                selected={selectedCategory === 'networkReaders'}
                                onClick={() => updateSelectedCategory('networkReaders')}
                            />
                        </Grid>
                    </>
                )}
            </Stack>
        </FilterPanel>
    );
};

TwoNFilterPanel.displayName = 'TwoNFilterPanel';
