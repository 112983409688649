import type { Container } from 'inversify';
import { createContainer } from './createContainer';
import { ModuleRegistry } from './ModuleRegistry';

export function initializeContainer(): Container {
    return createContainer((containerBinder) => {
        // Bind all services
        ModuleRegistry.getModules().forEach((module) => {
            if (module.options.binder) {
                module.options.binder(containerBinder);
            }
        });

        // Create services that needs to be created before startup
        containerBinder.initializeServicesOnStartup();
    });
}
