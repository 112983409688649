import { ActionCreator, IAction } from 'app/store';
import { injectable } from 'inversify';
import type { ToastModel } from '../models';
import { ToastActions } from '../state/Toast.actions';

@injectable()
export class ToasterActionService {
    @ActionCreator()
    public updateToastItems(toasts: ToastModel[]): IAction<ToastModel[]> {
        return {
            type: ToastActions.ToastItemsChanged,
            payload: toasts,
        };
    }
}
