import { injectable } from 'inversify';
import type { IItemEntity, Id, IBaseEntity, IItemRelationEntity } from '../entities';
import { BaseRepository } from './Base.repository';
import { ItemRelationRepository } from './ItemRelation.repository';
import type { IPersistence } from './persistence';
import {
    PersistenceDatabaseRepository,
    TimestampProviderService,
    EntitySettings,
    CreateEntityService,
    PersistenceMemoryRepository,
} from './persistence';
import type { IGetDescendantsWithRelations } from './models';
import type { IIdRev } from '../models';

@injectable()
export class ItemRepository extends BaseRepository<IItemEntity> {
    constructor(
        entitySettings: EntitySettings,
        persistenceRepository: PersistenceDatabaseRepository,
        persistenceMemoryRepository: PersistenceMemoryRepository,
        timestampProvider: TimestampProviderService,
        createEntityService: CreateEntityService,
        private itemRelationRepository: ItemRelationRepository,
    ) {
        super(
            entitySettings,
            persistenceRepository,

            persistenceMemoryRepository,
            timestampProvider,
            createEntityService,
        );
    }

    public async delete(id: Id, rev: string): Promise<Id> {
        await this.itemRelationRepository.deleteAllRelationsForEntity(id);
        return super.delete(id, rev);
    }

    public async bulkDelete(idRevs: IIdRev[]): Promise<IIdRev[]> {
        await this.itemRelationRepository.deleteAllRelationsForEntity(
            ...idRevs.map((idRev) => idRev._id),
        );
        return super.bulkDelete(idRevs);
    }

    /**
     * Gets items descendants for parent with item relations in one single database query
     * for performance.
     *
     * @param parentId the id for the parent.
     */
    public async getDescendantsWithRelations<ParentType extends IBaseEntity>(
        parentId: Id,
    ): Promise<IGetDescendantsWithRelations<ParentType, IItemEntity>> {
        const descendants = await this.getRepository().getDescendants(parentId);
        return {
            parent: descendants[0] as IPersistence<ParentType>,
            descendants: descendants.filter(
                this.filterDescendants<IPersistence<IItemEntity>>(this.prefix()),
            ),
            relations: descendants.filter(
                this.filterDescendants<IPersistence<IItemRelationEntity>>(
                    this.itemRelationRepository.prefix(),
                ),
            ),
        };
    }

    public prefix() {
        return 'item';
    }
}
