import type { Line3 } from 'three';
import { Vector3, Plane } from 'three';
import { curry } from 'lodash-es';

export interface ICameraVectors {
    center: Vector3;
    bottomCenter: Vector3;
    topCenter: Vector3;
    leftCenter: Vector3;
    rightCenter: Vector3;
    topLeft: Vector3;
    topRight: Vector3;
    bottomLeft: Vector3;
    bottomRight: Vector3;
    up: Vector3;
    down: Vector3;
}

export const clamp = curry((min: number, max: number, val: number) =>
    Math.max(min, Math.min(max, val)),
);

export const calculateDistanceToTarget = (
    desiredCameraY: number,
    targetDistance: number,
    targetHeight: number,
) => {
    const cameraPos = new Vector3(0, desiredCameraY, 0);
    const targetPos = new Vector3(targetDistance, targetHeight, 0);

    return cameraPos.distanceTo(targetPos);
};

export const calculateResolutionLimitDistance = (
    resolution: number | undefined,
    fov: number | undefined,
    pixelDensity: number,
    distance: number,
) => {
    if (!resolution || !fov) {
        return distance;
    } else {
        return resolution / (fov * pixelDensity);
    }
};

export const calculateCameraLookAtLocation = (tiltAngle: number, cameraPos: Vector3) => {
    const direction = new Vector3(1, 0, 0).applyAxisAngle(new Vector3(0, 0, 1), -tiltAngle);

    // don't look backwards
    direction.x = Math.max(direction.x, 0.001);

    return cameraPos.clone().add(direction);
};

export const byVectorLength = (a: Vector3 | undefined, b: Vector3 | undefined) =>
    (a?.length() ?? Infinity) - (b?.length() ?? Infinity);

export const getClosestIntersection = (line: Line3, planes: Plane[]) => {
    const intersections = planes
        .map((plane) => plane.intersectLine(line, new Vector3()))
        .map((vector) => vector?.sub(line.start)) // subtract start to get length
        .sort(byVectorLength)
        .map((vector) => vector?.add(line.start)); // add start again after sort
    return intersections[0];
};

export const getPlaneFromPoints = (point1: Vector3, point2: Vector3, point3: Vector3): Plane => {
    const plane = new Plane(new Vector3(0, 0, 0), 0);
    plane.setFromCoplanarPoints(point1, point2, point3);
    return plane;
};

export const increaseLength = (vector: Vector3, diff: number) => {
    const length = vector.length();
    const factor = (diff + length) / length;
    return vector.multiplyScalar(factor);
};
