import * as React from 'react';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { Box, OverlayPanel, Wrapper } from 'app/components';
import { DeviceDetails } from 'app/modules/projectDevices';
import {
    Maps,
    getMapsSelectionInfo,
    getDeviceOverlayPanel,
    MapsActionService,
} from 'app/modules/maps';

export const ProjectMaps: React.FC = () => {
    const mapSelection = useSelector(getMapsSelectionInfo);
    const deviceOverlayPanel = useSelector(getDeviceOverlayPanel);
    const mapActions = useService(MapsActionService);
    const selectedDeviceId = mapSelection?.deviceId ?? mapSelection?.mapItem?.parentDeviceId;

    return (
        <Wrapper>
            <Box
                position="relative"
                alignItems="center"
                height="100%"
                width="100%"
                overflowX="hidden"
            >
                <OverlayPanel
                    active={deviceOverlayPanel?.isOpen ?? false}
                    onInactivate={() => {
                        // Delay the close action to make it possible to toggle
                        // the panel by clicking on the add-ons buttons
                        setTimeout(() => mapActions.toggleDeviceOverlayPanel(), 10);
                    }}
                    maxWidth="70%"
                >
                    {selectedDeviceId && (
                        <DeviceDetails
                            deviceId={selectedDeviceId}
                            initialTab={deviceOverlayPanel?.initialTab}
                        />
                    )}
                </OverlayPanel>
                <Maps />
            </Box>
        </Wrapper>
    );
};
