import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { UnreachableCaseError } from 'axis-webtools-util';
import type { DeviceAndSubType } from 'app/core/persistence';
import type { PiaCategory } from 'app/core/pia';
import {
    PiaItemCameraCategory,
    getAllCameraCategories,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemEncoderCategory,
    PiaItemMainUnitCategory,
    PiaItemPacCategory,
    PiaItemPeopleCounterCategory,
    PiaItemSensorUnitCategory,
    PiaItemSpeakerCategory,
    PiaItemWearablesCategory,
    PiaItemAlerterCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from 'app/core/pia';

import {
    getCurrentProjectItem,
    getDeviceAndSubTypeForItem,
    getPiaCategory,
    getVendor,
    isPriceListAvailable,
    toCacheKey,
} from 'app/modules/common';
import type { DeviceGroup, IDeviceGroupInfo, SortOrder } from '../models';
import { DeviceGroups } from '../models';
import { getCurrentSortOrder } from './getProductsForDeviceGroup';

const getAvailableSortOrders = (category: DeviceGroup, hasPrices: boolean): SortOrder[] => {
    const sortOrdersByPrice: SortOrder[] = hasPrices
        ? ['byPriceLowToHigh', 'byPriceHighToLow']
        : [];

    switch (category) {
        case 'cameras':
            return ['bySeries', 'byName', 'byFov', 'byPowerConsumption', ...sortOrdersByPrice];
        case 'sensorunits':
            return ['byName', 'byFov', ...sortOrdersByPrice];
        case 'accessControls':
        case 'encoders':
        case 'mainunits':
        case 'other':
        case 'speakers':
        case 'twoNProducts':
        case 'wearables':
        default:
            return ['bySeries', 'byName', ...sortOrdersByPrice];
    }
};

const getInitialSortOrder = (
    deviceGroup: DeviceGroup,
    currentSortOrder: SortOrder,
    hasPrices: boolean,
): SortOrder => {
    if (
        currentSortOrder &&
        getAvailableSortOrders(deviceGroup, hasPrices).includes(currentSortOrder)
    ) {
        return currentSortOrder;
    }
    return getAvailableSortOrders(deviceGroup, hasPrices)[0];
};

export const getDeviceGroups = createSelector(
    [getCurrentSortOrder, isPriceListAvailable],
    (currentSortOrder, hasPrices): Record<DeviceGroup, IDeviceGroupInfo> => {
        const othersCategories: (
            | PiaItemDecoderCategory
            | PiaItemDetectorCategory
            | PiaItemPeopleCounterCategory
            | PiaItemAlerterCategory
            | PiaItemConnectivityDevicesCategory
            | PiaItemPagingConsoleCategory
        )[] = [
            PiaItemDecoderCategory.DECODER,
            PiaItemDetectorCategory.RADARDETECTORS,
            PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES,
            PiaItemPeopleCounterCategory.PEOPLECOUNTERS,
            PiaItemAlerterCategory.ALERTERS,
            PiaItemPagingConsoleCategory.PAGINGCONSOLE,
        ];

        return {
            cameras: {
                deviceGroup: DeviceGroups.CAMERAS,
                categories: getAllCameraCategories(),
                sortOrders: getAvailableSortOrders(DeviceGroups.CAMERAS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.CAMERAS,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            encoders: {
                deviceGroup: DeviceGroups.ENCODERS,
                categories: [PiaItemEncoderCategory.ENCODER],
                sortOrders: getAvailableSortOrders(DeviceGroups.ENCODERS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.ENCODERS,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            mainunits: {
                deviceGroup: DeviceGroups.MAINUNITS,
                categories: [PiaItemMainUnitCategory.MAINUNIT],
                sortOrders: getAvailableSortOrders(DeviceGroups.MAINUNITS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.MAINUNITS,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            sensorunits: {
                deviceGroup: DeviceGroups.SENSORUNITS,
                categories: [
                    PiaItemSensorUnitCategory.SENSORUNIT,
                    PiaItemSensorUnitCategory.THERMALSENSOR,
                ],
                sortOrders: getAvailableSortOrders(DeviceGroups.SENSORUNITS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.SENSORUNITS,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            speakers: {
                deviceGroup: DeviceGroups.SPEAKERS,
                categories: [PiaItemSpeakerCategory.SPEAKER],
                sortOrders: getAvailableSortOrders(DeviceGroups.SPEAKERS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.SPEAKERS,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            accessControls: {
                deviceGroup: DeviceGroups.ACCESSCONTROLS,
                categories: [
                    PiaItemPacCategory.DOORCONTROLLERS,
                    PiaItemPacCategory.DOORSTATIONS,
                    PiaItemPacCategory.IORELAYS,
                ],
                sortOrders: getAvailableSortOrders(DeviceGroups.ACCESSCONTROLS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.ACCESSCONTROLS,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            wearables: {
                deviceGroup: DeviceGroups.WEARABLES,
                categories: [
                    PiaItemWearablesCategory.CAMERAEXTENSIONS,
                    PiaItemWearablesCategory.CAMERAS,
                    PiaItemWearablesCategory.CONTROLLER,
                    PiaItemWearablesCategory.DOCKING,
                ],
                sortOrders: getAvailableSortOrders(DeviceGroups.WEARABLES, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.WEARABLES,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            twoNProducts: {
                deviceGroup: DeviceGroups.TWONPRODUCTS,
                categories: [
                    PiaItemPacCategory.ACCESSSERVER,
                    PiaItemPacCategory.ANSWERINGUNIT,
                    PiaItemPacCategory.DOORSTATIONS,
                    PiaItemPacCategory.NETWORKREADER,
                ],
                sortOrders: getAvailableSortOrders(DeviceGroups.TWONPRODUCTS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.TWONPRODUCTS,
                    currentSortOrder,
                    hasPrices,
                ),
            },
            other: {
                deviceGroup: DeviceGroups.OTHER,
                categories: othersCategories,
                sortOrders: getAvailableSortOrders(DeviceGroups.OTHER, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.OTHER,
                    currentSortOrder,
                    hasPrices,
                ),
            },
        };
    },
);

/**
 * If editing a device we only want to show products from the same category being edited,
 * and not all product that we normally show on that tab when adding a device.
 */
export const getSelectedDeviceGroup = createCachedSelector(
    [
        getDeviceGroups,
        getCurrentProjectItem,
        getPiaCategory,
        getVendor,
        getDeviceAndSubTypeForItem,
        getCurrentSortOrder,
        isPriceListAvailable,
    ],
    (deviceGroups, deviceItem, category, vendor, deviceType, currentSortOrder, hasPrices) => {
        // If editing a device we only want to show products from the same category being edited,
        // and not all product that we normally show on that tab when adding a device.

        // Pia product
        if (category && deviceItem) {
            return getDeviceGroupInfoByPiaCategory(category, currentSortOrder, hasPrices, vendor);
            // Generic product
        } else if (deviceType) {
            // Special case for radars and door controllers that can be generic (if added in maps),
            // but normally is one of more categories on the 'other' / 'access controls' tab
            if (deviceType === 'radardetector') {
                return {
                    deviceGroup: 'other',
                    categories: [PiaItemDetectorCategory.RADARDETECTORS],
                    filter: undefined,
                    sortOrders: getAvailableSortOrders(DeviceGroups.OTHER, hasPrices),
                    defaultSortOrder: getInitialSortOrder(
                        DeviceGroups.OTHER,
                        currentSortOrder,
                        hasPrices,
                    ),
                } as IDeviceGroupInfo;
            }
            if (deviceType === 'doorcontroller') {
                return {
                    deviceGroup: DeviceGroups.ACCESSCONTROLS,
                    categories: [PiaItemPacCategory.DOORCONTROLLERS],
                    filter: undefined,
                    sortOrders: getAvailableSortOrders(DeviceGroups.ACCESSCONTROLS, hasPrices),
                    defaultSortOrder: getInitialSortOrder(
                        DeviceGroups.ACCESSCONTROLS,
                        currentSortOrder,
                        hasPrices,
                    ),
                } as IDeviceGroupInfo;
            }
            const deviceGroup = mapDeviceTypeToDeviceGroup(deviceType);
            return deviceGroups[deviceGroup];
        }
        return undefined;
    },
)(toCacheKey);

const mapDeviceTypeToDeviceGroup = (deviceType: DeviceAndSubType): DeviceGroup => {
    switch (deviceType) {
        case 'camera':
            return DeviceGroups.CAMERAS;
        case 'encoder':
            return DeviceGroups.ENCODERS;
        case 'mainUnit':
            return DeviceGroups.MAINUNITS;
        case 'sensorUnit':
            return DeviceGroups.SENSORUNITS;
        case 'speaker':
            return DeviceGroups.SPEAKERS;
        case 'doorcontroller':
        case 'doorstation':
        case 'iorelay':
        case 'relayexpmodules':
        case 'pac':
            return DeviceGroups.ACCESSCONTROLS;
        case 'bodyWornCamera':
        case 'cameraExtension':
        case 'dockingStation':
        case 'systemController':
            return DeviceGroups.WEARABLES;
        case 'radardetector':
        case 'accessServer':
        case 'analogCamera':
        case 'answeringUnit':
        case 'networkReader':
        case 'peopleCounter':
        case 'decoder':
        case 'alerter':
        case 'door':
        case 'connectivitydevice':
        case 'pagingconsole':
            return DeviceGroups.OTHER;
        default:
            throw new UnreachableCaseError(deviceType);
    }
};

const getDeviceGroupInfoByPiaCategory = (
    category: PiaCategory,
    currentSortOrder: SortOrder,
    hasPrices: boolean,
    vendor?: string,
): IDeviceGroupInfo => {
    switch (category) {
        case PiaItemCameraCategory.THERMAL:
        case PiaItemCameraCategory.PTZ:
        case PiaItemCameraCategory.FIXEDDOME:
        case PiaItemCameraCategory.FIXED:
        case PiaItemCameraCategory.CAMERAEX:
        case PiaItemCameraCategory.COMPLETEMODULAR:
            return {
                deviceGroup: DeviceGroups.CAMERAS,
                categories: getAllCameraCategories(),
                sortOrders: getAvailableSortOrders(DeviceGroups.CAMERAS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.CAMERAS,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemEncoderCategory.ENCODER:
            return {
                deviceGroup: DeviceGroups.ENCODERS,
                categories: [category],
                sortOrders: getAvailableSortOrders(DeviceGroups.ENCODERS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.ENCODERS,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemMainUnitCategory.MAINUNIT:
            return {
                deviceGroup: DeviceGroups.MAINUNITS,
                categories: [category],
                sortOrders: getAvailableSortOrders(DeviceGroups.MAINUNITS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.MAINUNITS,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemSensorUnitCategory.SENSORUNIT:
        case PiaItemSensorUnitCategory.THERMALSENSOR:
            return {
                deviceGroup: DeviceGroups.SENSORUNITS,
                categories: [
                    PiaItemSensorUnitCategory.SENSORUNIT,
                    PiaItemSensorUnitCategory.THERMALSENSOR,
                ],
                sortOrders: getAvailableSortOrders(DeviceGroups.SENSORUNITS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.SENSORUNITS,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemSpeakerCategory.SPEAKER:
            return {
                deviceGroup: DeviceGroups.SPEAKERS,
                categories: [category],
                sortOrders: getAvailableSortOrders(DeviceGroups.SPEAKERS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.SPEAKERS,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemWearablesCategory.CAMERAEXTENSIONS:
        case PiaItemWearablesCategory.CAMERAS:
        case PiaItemWearablesCategory.CONTROLLER:
        case PiaItemWearablesCategory.DOCKING:
            return {
                deviceGroup: DeviceGroups.WEARABLES,
                categories: [category],
                sortOrders: getAvailableSortOrders(DeviceGroups.WEARABLES, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.WEARABLES,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemPacCategory.DOORCONTROLLERS:
        case PiaItemPacCategory.IORELAYS:
            return {
                deviceGroup: DeviceGroups.ACCESSCONTROLS,
                categories: [category],
                sortOrders: getAvailableSortOrders(DeviceGroups.ACCESSCONTROLS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.ACCESSCONTROLS,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemPacCategory.ACCESSSERVER:
        case PiaItemPacCategory.ANSWERINGUNIT:
        case PiaItemPacCategory.NETWORKREADER:
            return {
                deviceGroup: DeviceGroups.TWONPRODUCTS,
                categories: [category],
                sortOrders: getAvailableSortOrders(DeviceGroups.TWONPRODUCTS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.TWONPRODUCTS,
                    currentSortOrder,
                    hasPrices,
                ),
            };

        case PiaItemPacCategory.DOORSTATIONS:
            if (vendor === '2N') {
                return {
                    deviceGroup: DeviceGroups.TWONPRODUCTS,
                    categories: [category],
                    sortOrders: getAvailableSortOrders(DeviceGroups.TWONPRODUCTS, hasPrices),
                    defaultSortOrder: getInitialSortOrder(
                        DeviceGroups.TWONPRODUCTS,
                        currentSortOrder,
                        hasPrices,
                    ),
                };
            } else {
                return {
                    deviceGroup: DeviceGroups.ACCESSCONTROLS,
                    categories: [category],
                    sortOrders: getAvailableSortOrders(DeviceGroups.ACCESSCONTROLS, hasPrices),
                    defaultSortOrder: getInitialSortOrder(
                        DeviceGroups.ACCESSCONTROLS,
                        currentSortOrder,
                        hasPrices,
                    ),
                };
            }

        case PiaItemDecoderCategory.DECODER:
        case PiaItemDetectorCategory.RADARDETECTORS:
        case PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES:
        case PiaItemPeopleCounterCategory.PEOPLECOUNTERS:
        case PiaItemAlerterCategory.ALERTERS:
        case PiaItemPagingConsoleCategory.PAGINGCONSOLE:
            return {
                deviceGroup: DeviceGroups.OTHER,
                categories: [category],
                sortOrders: getAvailableSortOrders(DeviceGroups.OTHER, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.OTHER,
                    currentSortOrder,
                    hasPrices,
                ),
            };
        default:
            return {
                deviceGroup: DeviceGroups.CAMERAS,
                categories: getAllCameraCategories(),
                sortOrders: getAvailableSortOrders(DeviceGroups.CAMERAS, hasPrices),
                defaultSortOrder: getInitialSortOrder(
                    DeviceGroups.CAMERAS,
                    currentSortOrder,
                    hasPrices,
                ),
            };
    }
};
