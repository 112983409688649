import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItems } from '../../project';
import { getParentRelationsRecord } from '../../relations';
import { getIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';

export const getParentIdOfChildDevice = createCachedSelector(
    [getParentRelationsRecord, getCurrentProjectItems, getIdFromProps],
    (parentRelationsRecord, items, itemId) => {
        if (!itemId) {
            return undefined;
        }

        const item = items[itemId];
        const parentRelation = parentRelationsRecord[itemId];
        if (!item || !parentRelation) {
            return undefined;
        }

        return parentRelation.parentId;
    },
)(toCacheKey);
