import type { Id } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';
import { getIdFromProps, toCacheKey } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';
import { getPersistedApplications } from './getPersistedApplications';

// parentId must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getProductId = (_state: IStoreState, _parentId: Id | undefined, piaId: PiaId) => piaId;

export const getIsApplicationAddedToDevice = createCachedSelector(
    [getPersistedApplications, getIdFromProps, getProductId],
    (selectedApplications, parentId, productId): boolean =>
        !parentId ? false : selectedApplications.some((app) => app.productId === productId),
)(toCacheKey);
