import { trigonometry } from 'axis-webtools-util';
import { Vector3, Ray } from 'three';
import type { PanoramaModes } from 'app/core/persistence';
import {
    calculateCornerVectors,
    calculateActualEdgeVectors,
    calculateTiltAngleFromRadians,
} from 'app/modules/common';

/** Get the camera's bounding vectors and tilt so that target top is barely visible */
function getCameraCornerVectors(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
) {
    const rotationAxis = new Vector3(0, 0, 1);

    // calculate camera tilt angle
    const angle =
        horizontalFov >= Math.PI
            ? 0
            : -trigonometry.toRadians(
                  calculateTiltAngleFromRadians(
                      cameraHeight,
                      targetHeight,
                      targetDistance,
                      verticalFov,
                  ),
              );

    const vectors = calculateCornerVectors(horizontalFov, verticalFov);

    Object.values(vectors).forEach((vector) => vector.applyAxisAngle(rotationAxis, -angle));

    return vectors;
}

function getCameraEdgeVectors(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    panoramaMode: PanoramaModes,
) {
    // calculate minimum tilt. We don't allow a negative blind spot for wall mounted cameras
    const minTilt = panoramaMode === 'vertical' ? (Math.PI - verticalFov) / 2 : Math.PI / 2;

    // calculate camera tilt angle
    const angle =
        panoramaMode === 'horizontal'
            ? Math.PI / 2
            : Math.min(
                  -trigonometry.toRadians(
                      calculateTiltAngleFromRadians(
                          cameraHeight,
                          targetHeight,
                          targetDistance,
                          verticalFov,
                      ),
                  ),
                  minTilt,
              );

    const { topVectors, rightVectors, bottomVectors, leftVectors } = calculateActualEdgeVectors(
        horizontalFov,
        verticalFov,
        angle,
    );

    return [...topVectors, ...rightVectors, ...bottomVectors, ...leftVectors];
}

export function calculateCornerRays(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
) {
    const cameraPos = new Vector3(0, cameraHeight, 0);

    const vectors = getCameraCornerVectors(
        horizontalFov,
        verticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
    );

    return {
        bottom: new Ray(cameraPos, vectors.bottomCenter),
        topLeft: new Ray(cameraPos, vectors.topLeft),
        topRight: new Ray(cameraPos, vectors.topRight),
        bottomLeft: new Ray(cameraPos, vectors.bottomLeft),
        bottomRight: new Ray(cameraPos, vectors.bottomRight),
    };
}

export function calculateEdgeRays(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    panoramaMode: PanoramaModes,
) {
    const cameraPos = new Vector3(0, cameraHeight, 0);

    const vectors = getCameraEdgeVectors(
        horizontalFov,
        verticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
        panoramaMode,
    );

    return vectors.map((vector) => new Ray(cameraPos, vector));
}
