import * as React from 'react';
import { Icon, ToggleButton, Stack, Positioned } from 'app/components';
import type { PanoramaModes } from 'app/core/persistence';

export interface IPanoramaModeSwitcherProps {
    current: PanoramaModes;
    onPanoramaModeChange(mode: PanoramaModes): void;
}

export class PanoramaModeSwitcher extends React.Component<IPanoramaModeSwitcherProps> {
    public render() {
        return (
            <Positioned position="absolute" bottom="5px" right="5px">
                <Stack spacing="half">
                    <ToggleButton
                        testId="dome_wall_tgl"
                        pressed={this.props.current === 'vertical'}
                        onChange={() => this.props.onPanoramaModeChange('vertical')}
                    >
                        <Icon opaque icon="dome_wall" />
                    </ToggleButton>
                    <ToggleButton
                        testId="dome_ceiling_tgl"
                        pressed={this.props.current === 'horizontal'}
                        onChange={() => this.props.onPanoramaModeChange('horizontal')}
                    >
                        <Icon opaque icon="dome" />
                    </ToggleButton>
                </Stack>
            </Positioned>
        );
    }
}
