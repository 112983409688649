import { AXIS_OBJECT_ANALYTICS, AXIS_PERIMETER_DEFENDER } from 'app/core/common';
import type { AnalyticMode, AnalyticRangeLightCondition } from 'app/core/persistence';
import { isCamera } from 'app/core/persistence';

import type { IPiaCamera, IPiaSpeaker, IPiaMainUnit, IPiaRadarDetector } from 'app/core/pia';
import { hasApdParams } from 'app/modules/common';

export type analyticProperties = {
    person: {
        height: number;
        percent: number;
    };
    vehicle: {
        height: number;
        percent: number;
    };
};

const defaultAOAProps = {
    person: {
        height: 1.7,
        percent: 4,
    },
    vehicle: {
        height: 1.7,
        percent: 3,
    },
    multiplier: 2,
};
const defaultAPDProps = {
    person: {
        height: 1.7,
    },
    vehicle: {
        height: 1.7,
    },
};

const lightConditionMultipliers: Record<AnalyticRangeLightCondition, number> = {
    good_light: 1,
    medium_light: 2,
    low_light: 4,
    ir: 5,
};

const lightConditionMultipliersMLPU: Record<AnalyticRangeLightCondition, number> = {
    good_light: 2,
    medium_light: 3,
    low_light: 5,
    ir: 6,
};

export const getAnalyticProperties = (
    acapId: number,
    parentPiaDevice?: IPiaCamera | IPiaSpeaker | IPiaMainUnit | IPiaRadarDetector | null,
    lightCondition?: AnalyticRangeLightCondition,
    analyticMode?: AnalyticMode,
) => {
    if (parentPiaDevice && acapId === AXIS_OBJECT_ANALYTICS) {
        const multipliersRecord =
            parentPiaDevice.properties.analyticsComputeCapability === 'MLPU'
                ? lightConditionMultipliersMLPU
                : lightConditionMultipliers;

        const multiplier = lightCondition
            ? multipliersRecord[lightCondition]
            : defaultAOAProps.multiplier;

        const aoaMinObjectSizeHumans =
            isCamera(parentPiaDevice) && parentPiaDevice.properties.aoaMinObjectSizeHumans
                ? Number(parentPiaDevice.properties.aoaMinObjectSizeHumans)
                : defaultAOAProps.person.percent;

        const aoaMinObjectSizeVehicles =
            isCamera(parentPiaDevice) && parentPiaDevice.properties.aoaMinObjectSizeVehicles
                ? Number(parentPiaDevice.properties.aoaMinObjectSizeVehicles)
                : defaultAOAProps.vehicle.percent;

        const aoaHumansPercent = aoaMinObjectSizeHumans * multiplier;
        const aoaVehiclesPercent = aoaMinObjectSizeVehicles * multiplier;

        const analyticProps = {
            person: { height: defaultAOAProps.person.height, percent: aoaHumansPercent },
            vehicle: { height: defaultAOAProps.vehicle.height, percent: aoaVehiclesPercent },
        };
        return analyticProps;
    }

    if (parentPiaDevice && acapId === AXIS_PERIMETER_DEFENDER) {
        if (isCamera(parentPiaDevice) && hasApdParams(parentPiaDevice)) {
            const apdHumansPercent =
                analyticMode === 'ai'
                    ? Number(parentPiaDevice.properties.apdAiMinObjectSizeHumans)
                    : Number(parentPiaDevice.properties.apdMinObjectSizeHumans);
            const apdVehiclesPercent =
                analyticMode === 'ai'
                    ? Number(parentPiaDevice.properties.apdAiMinObjectSizeVehicles)
                    : Number(parentPiaDevice.properties.apdMinObjectSizeVehicles);
            const analyticProps = {
                person: { ...defaultAPDProps.person, percent: apdHumansPercent },
                vehicle: { ...defaultAPDProps.vehicle, percent: apdVehiclesPercent },
            };
            return analyticProps;
        }
        // don't return any default values if props are missing in PIA
        return;
    }
};
