import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Change reserved ranges structure from array to object
 */
@injectable()
export class Migration32To33 extends MigrationProviderBase {
    public from: number = 32;
    public to: number = 33;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (
                entity.type === 'project' &&
                entity.networkSettings &&
                Array.isArray(entity.networkSettings?.ranges)
            ) {
                entity.networkSettings.ranges = {};
            }

            return entity;
        };
    }
}
