import { isDefined, UnreachableCaseError } from 'axis-webtools-util';
import { createSelector } from 'reselect';
import {
    getCurrentProjectCustomCameras,
    getCurrentProjectItems,
    getCurrentProjectPartnerApplicationItemsArray,
    getCurrentProjectRegions,
} from '../../project';
import type {
    IItemEntity,
    IItemRelationEntity,
    ICustomItemEntity,
    CustomItemCategory,
    IPersistence,
} from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import type { IPiaItem, IPiaItemVersion, IPiaSoftware } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import type { IProduct } from '../IProduct';
import { sortBy, flatten, groupBy } from 'lodash-es';
import type { IPart } from '../IPart';
import { VendorEnum } from '../Vendor.enum';
import { getCategory } from '../getCategory';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { CategoryEnum } from '../Category.enum';
import { getPiaItemsRecord } from '../../piaDevices';
import { getParentRelationsRecord } from '../../relations';
import { getVendorEnum } from '../getVendorEnum';
import { productFilters } from '../ProductFilters';

const getCustomItems = (storeState: IStoreState) => storeState.currentProject.customItems;

const getCustomPrices = (storeState: IStoreState) =>
    storeState.currentProject.quotations?.customItemPrices;

const getPartnerPrices = (storeState: IStoreState) =>
    storeState.currentProject.quotations?.partnerItemPrices;

export const getCustomCameraProducts = createSelector(
    [getCurrentProjectCustomCameras, getCustomPrices],
    (cameras, customPrices): IProduct[] =>
        cameras.map((camera) => ({
            id: camera._id,
            name: camera.properties.camera?.customCameraProperties?.modelName ?? camera.name,
            partNumber: '',
            quantity: camera.quantity,
            vendor: VendorEnum.Other,
            category: CategoryEnum.Camera,
            discontinued: false,
            quotePrice: customPrices ? customPrices[camera._id] : undefined,
        })),
);

export const getPartnerApplicationProducts = createSelector(
    [getCurrentProjectPartnerApplicationItemsArray, getPartnerPrices],
    (partnerProducts, customPrices): IProduct[] =>
        partnerProducts.map((application) => ({
            id: application._id,
            name: application.name,
            partNumber: '-',
            category: CategoryEnum.Software,
            vendor: VendorEnum.Other,
            discontinued: false,
            quantity: application.quantity,
            quotePrice: customPrices ? customPrices[application._id] : undefined,
        })),
);

export const getProducts = createSelector(
    [
        getCurrentProjectItems,
        getCustomItems,
        getPiaItemsRecord,
        getParentRelationsRecord,
        getCurrentProjectRegions,
        getCustomCameraProducts,
        getPartnerApplicationProducts,
    ],
    (
        itemsRecord,
        customItems,
        piaItems,
        relationsRecord,
        regions,
        customCameras,
        partnerItemProducts,
    ) => {
        const allItems = Object.values(itemsRecord)
            .filter(isDefined)
            .filter((item) => !item.properties.environment)
            .filter((item) => !deviceTypeCheckers.isVirtualProduct(item))
            .map((item) => getItemWithCalculatedQuantity(item, itemsRecord, relationsRecord));

        const products: IProduct[] = flatten(
            mergeItems(allItems)
                .map((item) => {
                    const idToUse = item.replaceWithBareboneId
                        ? item.replaceWithBareboneId
                        : item.productId;
                    return idToUse && piaItems[idToUse]
                        ? createProductsFromPiaItem(item, piaItems[idToUse], regions, item.quantity)
                        : undefined;
                })
                .filter(isDefined),
        );

        const customItemProducts = Object.values(customItems)
            .filter(isDefined)
            .map(createProductFromCustomItem);

        const partnerSystemProducts = Object.values(itemsRecord)
            .filter((item) => item?.properties.partnerSystemComponent)
            .filter(isDefined)
            .map(createProductFromPartnerItem);

        return [
            ...products,
            ...customItemProducts,
            ...customCameras,
            ...mergePartnerProducts(partnerItemProducts),
            ...partnerSystemProducts,
        ];
    },
);

const mergeItems = (items: IPersistence<IItemEntity>[]) => {
    const groupedByProductAndBarebone = Object.values(
        groupBy(items, ({ productId, replaceWithBareboneId }) => {
            const bareboneCompare = replaceWithBareboneId ? replaceWithBareboneId : '';
            return `${productId}-${bareboneCompare}`;
        }),
    );

    const mergedItems = groupedByProductAndBarebone.map((group) => ({
        ...group[0],
        quantity: group.reduce((acc, { quantity }) => acc + quantity, 0),
    }));

    return mergedItems;
};

/** Merges products by product name. */
const mergePartnerProducts = (products: IProduct[]): IProduct[] => {
    const productRecord = products.reduce(
        (productsByModel, product) => {
            if (productsByModel[product.name]) {
                productsByModel[product.name].quantity += product.quantity;
            } else {
                productsByModel[product.name] = product;
            }
            return productsByModel;
        },
        {} as Record<string, IProduct>,
    );
    return Object.values(productRecord);
};

const getItemWithCalculatedQuantity = (
    item: IPersistence<IItemEntity>,
    itemsRecord: Record<string, IPersistence<IItemEntity> | undefined>,
    relationsRecord: Record<string, IItemRelationEntity>,
) => {
    const quantity = calculateRecursive(item, itemsRecord, relationsRecord);
    return {
        ...item,
        quantity,
    };
};

export const getCalculatedQuantity = (
    item: IPersistence<IItemEntity>,
    itemsRecord: Record<string, IPersistence<IItemEntity> | undefined>,
    relationsRecord: Record<string, IItemRelationEntity>,
) => {
    const quantity = calculateRecursive(item, itemsRecord, relationsRecord);
    return quantity;
};

const calculateRecursive = (
    item: IPersistence<IItemEntity>,
    itemsRecord: Record<string, IPersistence<IItemEntity> | undefined>,
    relations: Record<string, IItemRelationEntity>,
): number => {
    const relation = relations[item._id];
    const parentItem = relation ? itemsRecord[relation.parentId] : undefined;
    if (parentItem) {
        return item.quantity * calculateRecursive(parentItem, itemsRecord, relations);
    }

    return item.quantity;
};

export const createProductsFromPiaItem = (
    item: IPersistence<IItemEntity>,
    piaItem: IPiaItem,
    regions: string[],
    quantity: number,
): IProduct[] => {
    let piaVersions = piaItem.versions
        .filter((version) => version.state === PiaItemState.EXTERNALLY_ANNOUNCED)
        .filter(getVersionsFromRegions(regions));

    if (piaVersions.length === 0) {
        return [createProductFromPiaItem(item, piaItem, quantity)];
    }

    // Using sortBy because Chrome has unstable sorting
    piaVersions = sortBy(piaVersions, (part) => -part.scaleQuantity);
    piaVersions = sortBy(piaVersions, (part) => getRegionPrioOrder(part.versions, regions));

    const partsWithQuantities = piaVersions.reduce(getPartsWithQuantities, {
        rest: quantity,
        parts: [],
    });
    return partsWithQuantities.parts.map(createProductFromPart(item, piaItem));
};

const createProductFromPiaItem = (
    item: IPersistence<IItemEntity>,
    piaItem: IPiaItem,
    quantity: number,
): IProduct => {
    const vendor = productFilters.isVendorAxis(piaItem.properties.vendor)
        ? VendorEnum.Axis
        : VendorEnum.Other;

    return {
        id: String(piaItem.id),
        name: piaItem.name,
        partNumber: '-',
        piaId: piaItem.id,
        quantity,
        discontinued: isDiscontinued(piaItem),
        vendor,
        piaCategory: piaItem.category,
        category: getCategory(item, piaItem),
        series: piaItem.properties.series,
        bareboneId: item.replaceWithBareboneId,
        subscriptionIntervalInMonths:
            getCategory(item, piaItem) === CategoryEnum.Software
                ? (piaItem as IPiaSoftware).properties.subscriptionIntervalInMonths
                : undefined,
    };
};

const createProductFromPart = (item: IPersistence<IItemEntity>, piaItem: IPiaItem) => {
    const vendorEnum = getVendorEnum(piaItem.properties.vendor);

    return (part: IPart): IProduct => {
        return {
            // 3rd party products are saved as custom items price with pia id as key
            id: vendorEnum === VendorEnum.Other ? String(piaItem.id) : part.partno,
            name:
                piaItem.name +
                (part.scaleQuantity > 1
                    ? ', ' + part.scaleQuantity + t.abbreviationsGROUP.pieces
                    : ''),
            partNumber: part.partno,
            piaId: piaItem.id,
            discontinued: isDiscontinued(piaItem),
            quantity: part.quantity,
            vendor: vendorEnum,
            category: getCategory(item, piaItem),
            piaCategory: piaItem.category,
            series: piaItem.properties.series,
            subscriptionIntervalInMonths:
                getCategory(item, piaItem) === CategoryEnum.Software
                    ? (piaItem as IPiaSoftware).properties.subscriptionIntervalInMonths
                    : undefined,
        };
    };
};

const createProductFromCustomItem = (item: ICustomItemEntity): IProduct => {
    return {
        category: convertCustomCategoryToProductCategory(item.category),
        discontinued: false,
        id: item._id,
        name: item.name,
        partNumber: item.partNumber,
        quantity: item.quantity,
        vendor: VendorEnum.Other,
    };
};

export const createProductFromPartnerItem = (item: IPersistence<IItemEntity>): IProduct => {
    return {
        id: item._id,
        name: item.name,
        partNumber: '-',
        piaId: undefined,
        discontinued: false,
        quantity: item.quantity,
        vendor: VendorEnum.Genetec,
        category: getCategory(item),
        piaCategory: undefined,
        series: undefined,
    };
};

const getVersionsFromRegions = (regions: string[]) => {
    return (productVersion: IPiaItemVersion) =>
        productVersion.versions.some((version) => regions.includes(version));
};

/**
 * Gets the prio for the versions. For example China can use both "CH" and "AUS" part numbers
 * but item versions with "CH" in it should be prioritized because they are first in the regions array.
 */
const getRegionPrioOrder = (versions: string[], regions: string[]) => {
    let prio = Number.MAX_VALUE;

    for (const version of versions) {
        prio = regions.indexOf(version);
        if (prio >= 0) {
            break;
        }
    }
    return prio;
};

const getPartsWithQuantities = (
    partsAndRest: { rest: number; parts: IPart[] },
    piaItemVersion: IPiaItemVersion,
    index: number,
    allPiaItemVersions: IPiaItemVersion[],
) => {
    const isLast = index === allPiaItemVersions.length - 1;
    const hasRest = partsAndRest.rest > 0;
    const partsNeeded = partsAndRest.rest / piaItemVersion.scaleQuantity;
    const quantityOfPart = isLast && hasRest ? Math.ceil(partsNeeded) : Math.floor(partsNeeded);

    if (quantityOfPart > 0) {
        partsAndRest.rest = partsAndRest.rest - quantityOfPart * piaItemVersion.scaleQuantity;
        partsAndRest.parts.push({
            partno: piaItemVersion.partno,
            quantity: quantityOfPart,
            scaleQuantity: piaItemVersion.scaleQuantity,
            versions: piaItemVersion.versions,
            state: piaItemVersion.state,
        });
    }
    return partsAndRest;
};

const isDiscontinued = (piaItem: IPiaItem): boolean => {
    return piaItem.state > PiaItemState.EXTERNALLY_ANNOUNCED;
};

const convertCustomCategoryToProductCategory = (category: CustomItemCategory) => {
    switch (category) {
        case 'software':
            return CategoryEnum.Software;
        case 'network':
            return CategoryEnum.RecordingAndNetwork;
        case 'miscellaneous':
            return CategoryEnum.Miscellaneous;
        case 'installationService':
            return CategoryEnum.InstallationService;
        default:
            throw new UnreachableCaseError(category);
    }
};
