import type {
    IInstallationReportState,
    IInstallationReportSchedule,
    IInstallationReportDevicesResponse,
    IInstallationReportSortBy,
} from './models';
import { reducerBuilder } from 'app/store';
import { InstallationReportActions } from './InstallationReport.actions';

const initialState: IInstallationReportState = {
    schedules: [],
    devices: [],
    sortBy: 'byName',
    loadingDevices: true,
};

export const installationReportReducer = reducerBuilder<IInstallationReportState>()
    .setInitialState(initialState)
    .onAsyncAction(InstallationReportActions.GetScheduleNames, (handler) => {
        handler.onFulfilled<Record<string, string>>((state, action) => ({
            ...state,
            scheduleNames: action.payload,
        }));
    })
    .onAsyncAction(InstallationReportActions.GetProjectSchedules, (handler) => {
        handler.onFulfilled<IInstallationReportSchedule[]>((state, action) => ({
            ...state,
            schedules: action.payload,
        }));
    })
    .onAsyncAction(InstallationReportActions.GetInstallationReportDevices, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                loadingDevices: true,
            }))
            .onFulfilled<IInstallationReportDevicesResponse>((state, action) => ({
                ...state,
                loadingDevices: false,
                totalDevicesCount: action.payload.totalDevicesCount,
                deviceCount: action.payload.deviceCount,
                devices: action.payload.devices,
            }));
    })
    .onAction<IInstallationReportSortBy>(
        InstallationReportActions.SET_SORT_ORDER,
        (state, action) => ({
            ...state,
            sortBy: action.payload,
        }),
    )
    .create();
