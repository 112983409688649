import { injectable } from 'inversify';

import type { PiaId, IPiaAccessory } from 'app/core/pia';
import { PiaRelationService, PiaItemService } from 'app/core/pia';
import { uniqWith, isEqual, memoize } from 'lodash-es';
import { MOUNTING_CATEGORIES } from '../MountingCategories';
import type { IEnvironment } from '../models';
import type { IPartnerConfigAllowlist } from 'app/core/persistence';
import { nameComparator } from 'app/utils';

const MAX_MOUNTING_OPTION_LENGTH = 3;

interface IPrimaryMount {
    item: IPiaAccessory | null;
    /** The length of the mounting chain */
    length: number;
    /**
     * The lengths of the available device mounts, used to determine if there are any valid options
     * or the primary mount should be removed
     */
    left: number[];
    /**
     * The lengths of the available environment mounts, used to determine if there are any valid options
     * or the primary mount should be removed
     */
    right: number[];
    /**
     * If the product is recommended, it is taken into account for sorting
     */
    isRecommended: boolean;
}

type IPrimaryMounts = Record<string, IPrimaryMount>;

@injectable()
export class MountService {
    private getMountingChains = memoize(
        (
            environmentId: PiaId,
            deviceId: PiaId,
            regions: string[],
            onlyOutdoor?: boolean,
            partnerConfig?: IPartnerConfigAllowlist,
        ): PiaId[][] => {
            const mountingChains = this.piaRelation.getAllCompatiblePaths(
                deviceId,
                environmentId,
                regions,
                MOUNTING_CATEGORIES,
                undefined,
                undefined,
                onlyOutdoor,
            );

            if (partnerConfig) {
                const filteredMountingChains = mountingChains.filter((mountingChain: number[]) => {
                    const innerMountingChain = mountingChain.slice(1, -1);

                    return innerMountingChain.every((id) => {
                        return (
                            partnerConfig.recommendedProducts.includes(id) ||
                            partnerConfig.otherProducts.includes(id)
                        );
                    });
                });

                return filteredMountingChains;
            }

            return mountingChains;
        },
        (
            environmentId: PiaId,
            deviceId: PiaId,
            regions: string[],
            onlyOutdoor,
            partnerConfig?: IPartnerConfigAllowlist,
        ) => {
            let key = `${environmentId},${deviceId},${onlyOutdoor},${regions.join('|')}`;
            if (partnerConfig) {
                key = key + '|' + partnerConfig;
            }

            return key;
        },
    );
    public constructor(
        private piaRelation: PiaRelationService,
        private piaService: PiaItemService<IPiaAccessory>,
    ) {}

    public environmentsWithAvailableMountingChainsFilter(
        environments: IEnvironment[],
        deviceId: PiaId,
        regions: string[],
        partnerConfig?: IPartnerConfigAllowlist,
        onlyOutdoor?: boolean,
    ) {
        return environments.filter((environment) => {
            const id = environment.productId;

            if (!id) {
                return false;
            }

            return this.getMountingChains(id, deviceId, regions, onlyOutdoor, partnerConfig).length;
        });
    }

    /**
     * Get an array of all possible primary mounts for a given environment and device.
     */
    public getPrimaryMounts(
        environmentId: PiaId,
        deviceId: PiaId,
        regions: string[],
        onlyOutdoor?: boolean,
        partnerConfig?: IPartnerConfigAllowlist,
    ): Array<IPiaAccessory | null> {
        const mountingChainsIds = this.getMountingChains(
            environmentId,
            deviceId,
            regions,
            onlyOutdoor,
            partnerConfig,
        );
        const recommendedMounts = this.getRecommendedRelations(deviceId);
        const isRecommended = (mount: IPrimaryMount) =>
            !!(mount.item && recommendedMounts?.includes(mount.item?.id));

        // Inner mounting chains exclude device and environment
        const innerMountingChainsItems = this.getPiaItems(
            mountingChainsIds.map((path) => path.slice(1, -1)),
        ).map((piaItems) => (piaItems.length === 0 ? [null] : piaItems));

        // Extract all chain items that can be used as primary mount
        const uniquePrimaryMounts = innerMountingChainsItems.reduce(
            this.extractPrimaryMounts,
            {} as IPrimaryMounts,
        );

        const uniquePrimaryMountsWithRecommended = Object.values(uniquePrimaryMounts).map(
            (mount) => {
                return { ...mount, isRecommended: isRecommended(mount) };
            },
        );

        return uniquePrimaryMountsWithRecommended
            .filter(this.isValidPrimaryMount)
            .sort(this.sortPrimaryMounts)
            .map((primaryMount) => primaryMount.item);
    }

    /**
     * Get all possible device and environment mounts for a given primary mount,
     * environment and device, in the order environment > device.
     */
    public getMounts = (
        primaryMountId: PiaId,
        environmentId: PiaId,
        piaDeviceId: PiaId,
        regions: string[],
        selectedDeviceMountIds?: PiaId[],
        selectedEnvironmentMountIds?: PiaId[],
        partnerConfig?: IPartnerConfigAllowlist,
        onlyOutdoor?: boolean,
    ) => {
        const recommendedMounts = this.getRecommendedRelations(piaDeviceId);

        const isRecommended = (mountId: PiaId) => !!recommendedMounts?.includes(mountId);

        const mountingChainsIds = this.getMountingChains(
            environmentId,
            piaDeviceId,
            regions,
            onlyOutdoor,
            partnerConfig,
        );
        const mountingChainsWithPrimaryMount = mountingChainsIds.filter((ids) =>
            ids.includes(primaryMountId),
        );
        // If a deviceMount or environmentMount is selected, it should be included in a pattern to find valid chains
        const pattern = selectedDeviceMountIds
            ? selectedDeviceMountIds?.concat(primaryMountId)
            : selectedEnvironmentMountIds
              ? [primaryMountId].concat(selectedEnvironmentMountIds)
              : [primaryMountId];

        const validMountingChains = mountingChainsWithPrimaryMount.filter((ids) =>
            pattern.every((item) => ids.includes(item)),
        );

        const deviceMountsIds: PiaId[][] = [];
        const environmentMountsIds: PiaId[][] = [];

        validMountingChains.forEach((ids) => {
            const positionOfPrimaryMount = ids.indexOf(primaryMountId);

            const precedingMountsIds = ids.slice(positionOfPrimaryMount + 1, -1);
            const followingMountsIds = ids.slice(1, positionOfPrimaryMount);

            if (followingMountsIds.length <= MAX_MOUNTING_OPTION_LENGTH) {
                deviceMountsIds.push(followingMountsIds);
            }

            if (precedingMountsIds.length <= MAX_MOUNTING_OPTION_LENGTH) {
                environmentMountsIds.push(precedingMountsIds);
            }
        });

        const uniqueDeviceMountsIds = uniqWith(deviceMountsIds, isEqual);
        const uniqueEnvironmentMountsIds = uniqWith(environmentMountsIds, isEqual);

        const deviceMounts = this.getPiaItems(uniqueDeviceMountsIds)
            .map((items) =>
                items.map((item) => ({ ...item, isRecommended: isRecommended(item.id) })),
            )
            .sort(this.sortMountingChains);

        const environmentMounts = this.getPiaItems(uniqueEnvironmentMountsIds)
            .map((items) =>
                items.map((item) => ({ ...item, isRecommended: isRecommended(item.id) })),
            )
            .sort(this.sortMountingChains);

        return {
            deviceMounts,
            environmentMounts,
        };
    };

    private getRecommendedRelations = (piaId: PiaId) => {
        const piaItem = this.piaService.get(piaId).first();
        const recommendedMounts = piaItem?.relations
            ? piaItem?.relations
                  .filter((rel) => rel.relationType === 'recommends')
                  .map((rel) => rel.id)
            : [];
        return recommendedMounts;
    };

    private getPiaItems = (paths: PiaId[][]) =>
        paths.map((ids) => this.piaService.getMultiple(ids).toList());

    private extractPrimaryMounts = (
        primaryMountAccumulator: IPrimaryMounts,
        chain: Array<IPiaAccessory | null>,
    ) =>
        chain.reduce((primaryMounts, item, index) => {
            if (item === null) {
                primaryMounts.mountIncluded = {
                    left: [],
                    right: [],
                    length: 0,
                    item,
                    isRecommended: false,
                };

                return primaryMounts;
            }

            const isPrimaryMount = this.isPrimaryMount(item);

            if (isPrimaryMount) {
                const primaryMount = primaryMounts[item.id] || {
                    left: [],
                    right: [],
                    length: chain.length,
                    item,
                };

                primaryMount.left.push(index);
                primaryMount.right.push(chain.length - index - 1);

                primaryMounts[item.id] = primaryMount;
            }

            return primaryMounts;
        }, primaryMountAccumulator);

    /** Sorts primary mounts by length, recommended and name */
    private sortPrimaryMounts = (a: IPrimaryMount, b: IPrimaryMount) =>
        a.length === b.length
            ? a.isRecommended === b.isRecommended
                ? a.item === null
                    ? -1
                    : b.item === null
                      ? 1
                      : nameComparator(a.item, b.item)
                : b.isRecommended
                  ? 1
                  : -1
            : a.length - b.length;

    /** Sorts mounting chain by length, recommended and name */
    private sortMountingChains = (
        a: { name: string; isRecommended: boolean }[],
        b: { name: string; isRecommended: boolean }[],
    ) => {
        if (a.length !== b.length) {
            return a.length - b.length;
        }
        const nrOfRecommendedA = this.countRecommended(a);
        const nrOfRecommendedB = this.countRecommended(b);

        return nrOfRecommendedA === nrOfRecommendedB
            ? a[0].name.localeCompare(b[0].name)
            : nrOfRecommendedA - nrOfRecommendedB;
    };

    private countRecommended = (mountingChain: { name: string; isRecommended: boolean }[]) =>
        mountingChain.filter((item) => !item.isRecommended).length;

    private isValidPrimaryMount = (mountObject: IPrimaryMount) =>
        mountObject.item === null ||
        (mountObject.left.some((optionLength) => optionLength <= MAX_MOUNTING_OPTION_LENGTH) &&
            mountObject.right.some((optionLength) => optionLength <= MAX_MOUNTING_OPTION_LENGTH));

    private isPrimaryMount = (item: IPiaAccessory) => item.properties.accMainMount === true;
}
