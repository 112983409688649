import * as React from 'react';
import type { Icons } from 'app/components';
import {
    Border,
    Text,
    Box,
    Clickable,
    DropDown,
    Icon,
    IconText,
    DropDownMenuButton,
} from 'app/components';
import { isPageRTL } from 'app/translate';
import type { Colors } from 'app/styles';

export interface IButtonAction {
    text: string;
    icon: Icons;
    iconColor?: Colors;
    selected?: boolean;
    onClick(): void;
}

interface IMapButtonProps {
    text: string;
    icon: Icons;
    disabled?: boolean;
    actions?: IButtonAction[];
    dropDownContent?: React.ReactNode;
    highlight?: boolean;
    onClick(): void;
}

export const MapButton: React.FC<IMapButtonProps> = ({
    text,
    icon,
    disabled,
    actions,
    dropDownContent,
    highlight,
    onClick,
}) => {
    const isRtl = isPageRTL();

    const color = highlight ? 'yellow1' : 'white';
    const hoverColor = highlight ? undefined : 'grey2';
    return (
        <Border radius="25px" color="grey4">
            <Box color={color} justifyContent="center" lineBetweenColor="grey4" minHeight={30}>
                <Clickable onClick={onClick} disabled={disabled}>
                    <Border
                        width={0}
                        radius={
                            actions || dropDownContent
                                ? isRtl
                                    ? '0 25px 25px 0'
                                    : '25px 0 0 25px'
                                : '25px'
                        }
                    >
                        <Box
                            paddingX={actions || dropDownContent ? 'half' : 'base'}
                            paddingY="quart"
                            overflow="hidden"
                            hoverColor={hoverColor}
                        >
                            <IconText iconProps={{ size: 'ms' }} icon={icon}>
                                <Text whiteSpace="nowrap" testId={text}>
                                    {text}
                                </Text>
                            </IconText>
                        </Box>
                    </Border>
                </Clickable>
                {(actions || dropDownContent) && (
                    <Border width={0} radius={isRtl ? '25px 0 0 25px' : '0 25px 25px 0'}>
                        <Box hoverColor={hoverColor}>
                            <DropDown
                                disabled={disabled}
                                trigger={
                                    <Box paddingRight="half" paddingLeft="quart">
                                        <Icon icon="keyboard_arrow_down" size="ms" />
                                    </Box>
                                }
                                contents={
                                    <>
                                        {actions?.map((action, index) => (
                                            <DropDownMenuButton
                                                key={`${index}_${action.text}`}
                                                selected={action.selected}
                                                icon={action.icon}
                                                iconProps={{
                                                    opaque: !!action.iconColor,
                                                    color: action.iconColor,
                                                }}
                                                onClick={action.onClick}
                                            >
                                                {action.text}
                                            </DropDownMenuButton>
                                        ))}
                                        {dropDownContent}
                                    </>
                                }
                            />
                        </Box>
                    </Border>
                )}
            </Box>
        </Border>
    );
};

MapButton.displayName = 'MapButton';
