import { injectable } from 'inversify';
import type {
    IProjectEntity,
    Id,
    IItemEntity,
    IItemRelationEntity,
    IFloorPlanEntity,
    IMapLocationEntity,
} from '../entities';
import { BaseRepository } from './Base.repository';
import type { IPersistence } from './persistence';
import {
    PersistenceDatabaseRepository,
    TimestampProviderService,
    EntitySettings,
    CreateEntityService,
    PersistenceMemoryRepository,
} from './persistence';
import { ItemRelationRepository } from './ItemRelation.repository';
import { ItemRepository } from './Item.repository';

@injectable()
export class ProjectRepository extends BaseRepository<IProjectEntity> {
    constructor(
        entitySettings: EntitySettings,
        persistenceRepository: PersistenceDatabaseRepository,
        persistenceMemoryRepository: PersistenceMemoryRepository,
        timestampProvider: TimestampProviderService,
        createEntityService: CreateEntityService,
        private itemRepository: ItemRepository,
        private itemRelationRepository: ItemRelationRepository,
    ) {
        super(
            entitySettings,
            persistenceRepository,
            persistenceMemoryRepository,
            timestampProvider,
            createEntityService,
        );
    }

    public getDbName(): string {
        return this.persistenceRepository.getDbName();
    }

    public async getProjectAndAllDescendants(projectId: Id) {
        const entities = await this.getRepository().getDescendants(projectId);

        // We don't try to handle a project without entities. Even in the case of
        // standalone accessory selector et al. we expect at least one entity.
        if (entities.length === 0) {
            throw Error(`No project or entities found for id ${projectId}`);
        }

        return {
            project: entities[0] as IPersistence<IProjectEntity>,
            descendants: entities.slice(1),
        };
    }

    public async getProjectAndAllDescendantsWithItems(projectId: Id) {
        const entities = await this.getRepository().getDescendants(projectId);

        if (entities.length === 0) {
            throw Error();
        }

        const items: Array<IPersistence<IItemEntity>> = [];
        const relations: Array<IPersistence<IItemRelationEntity>> = [];
        const allDescendants = entities.slice(1);

        allDescendants.forEach((descendant) => {
            if (
                this.isEntityOfType<IPersistence<IItemEntity>>(
                    this.itemRepository.prefix(),
                    descendant,
                )
            ) {
                items.push(descendant);
            } else if (
                this.isEntityOfType<IPersistence<IItemRelationEntity>>(
                    this.itemRelationRepository.prefix(),
                    descendant,
                )
            ) {
                relations.push(descendant);
            }
        });

        return {
            project: entities[0] as IPersistence<IProjectEntity>,
            allDescendants,
            items,
            relations,
        };
    }

    public async getHasAnyFloorPlanOrMapLocation(projectId: Id) {
        const entities = await this.getRepository().getDescendants(projectId);

        if (entities.length === 0) {
            throw Error();
        }

        return entities.some(
            (entity) =>
                this.isEntityOfType<IPersistence<IMapLocationEntity>>('mapLocation', entity) ||
                (this.isEntityOfType<IPersistence<IFloorPlanEntity>>('floorPlan', entity) &&
                    !entity.isDefault),
        );
    }

    protected prefix() {
        return 'project';
    }
}
