import * as React from 'react';
import { t } from 'app/translate';
import type { Resolution, Id, IBodyWornCameraProfile } from 'app/core/persistence';

import {
    getMaxResolutionString,
    getWearableProfile,
    getBodyWornMaxResolution,
} from 'app/modules/common';

import { Table } from 'app/components';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';

interface ISettingsTableBodyWornProps {
    deviceId: Id;
    scheduleNames: Record<string, string>;
}

export const SettingsTableBodyWorn: React.FC<ISettingsTableBodyWornProps> = ({
    deviceId,
    scheduleNames,
}) => {
    const profile = useSelector<IStoreState, IBodyWornCameraProfile | undefined>((state) =>
        getWearableProfile(state, deviceId),
    );

    const deviceMaxResolution = useSelector<IStoreState, Resolution>((state) =>
        getBodyWornMaxResolution(state, deviceId),
    );
    const resolutionValue = profile?.resolution
        ? getMaxResolutionString(
              profile.resolution,
              deviceMaxResolution.getHorizontal(),
              deviceMaxResolution.getVertical(),
          )
        : undefined;

    const selectedRetentionTime = useSelector<IStoreState, number | undefined>(
        (state) => getWearableProfile(state, deviceId)?.retentionTimeInDays,
    );
    const displayTitle = t.profilesScheduledRecordingWearable;

    const getRow = (
        title: string,
        resolution?: string,
        scheduleId?: Id,
        retentionTime?: number,
        showRetentionTime = true,
    ): Array<string | number | JSX.Element> => {
        return [
            title,
            resolution ? resolution : '',
            '-',
            '-',
            '-',
            '-',
            scheduleId ? scheduleNames[scheduleId] : '',
            showRetentionTime && retentionTime ? t.installationReportDays(retentionTime) : '',
        ];
    };

    return (
        <Table
            headers={[
                t.installationReportSettingsTitle,
                t.installationReportSettingsResolution,
                t.installationReportSettingsFrameRate,
                t.installationReportSettingsCompression,
                t.installationReportSettingsZipStrength,
                t.installationReportSettingsGopMode,
                t.installationReportSettingsSchedule,
                t.installationReportSettingsStorageTime,
            ]}
            rows={[
                getRow(displayTitle, resolutionValue, profile?.scheduleId, selectedRetentionTime),
            ]}
            rightAlignLastColumn
            wideFirstColumn
        />
    );
};
