import * as React from 'react';
import { Box, Icon, PiaImage, Text } from 'app/components';
import type { IPiaItem } from 'app/core/pia';

interface IItemsToRemove {
    piaItems: IPiaItem[];
    message: string;
}

/** Presents which products need to be removed when changing product */
export const ItemsToRemove: React.FC<IItemsToRemove> = ({ piaItems, message }) => {
    return (
        <Box spacing="none" direction="column">
            <Box paddingBottom="cell">
                <Text>{message}</Text>
            </Box>
            {piaItems.map((piaItem, index) => (
                <Box
                    alignItems="center"
                    spacing="half"
                    paddingBottom="halfQuart"
                    paddingLeft="panel"
                    key={`${piaItem.id}_${index}`}
                    testId={`itemToRemove_${piaItem.name}`}
                >
                    <Icon size="sm" color="red" icon="close" />
                    <PiaImage icon="device" piaId={piaItem.id} imageSize="ms" />
                    <Text align="left" wordBreak="normal">
                        {piaItem.name}
                    </Text>
                </Box>
            ))}
        </Box>
    );
};

ItemsToRemove.displayName = 'ItemsToRemove';
