import { t } from 'app/translate';
import * as React from 'react';
import { SegmentedControl, Box, Border, NoPrint } from 'app/components';
import { withTrailingSlash } from 'app/utils';

export interface IReportLinksMenuProps {
    basePath: string;
    projectId: string;
    currentRoute: string;
}

const QUOTATION_ROUTE = 'quotation';

const QUOTATION_FINANCING_ID = 'quotationfinancing';

export class ReportLinksMenu extends React.Component<IReportLinksMenuProps> {
    constructor(props: IReportLinksMenuProps) {
        super(props);
        this.state = { selectedItem: 0 };
    }

    public render() {
        const basePath = withTrailingSlash(this.props.basePath);
        const linkItems = this.getLinkItems(basePath);
        return (
            <Border color="grey3" bottomWidth={1}>
                <NoPrint>
                    <Box color="white">
                        <SegmentedControl
                            items={linkItems}
                            activeTabColor="grey1"
                            selectedId={this.getSelectedIdFromRoute(
                                this.props.currentRoute.replace('/', ''),
                            )}
                        />
                    </Box>
                </NoPrint>
            </Border>
        );
    }

    private getLinkItems(basePath: string) {
        const reportTabs = [
            {
                id: QUOTATION_FINANCING_ID,
                link: `quotation/edit/`,
                text: t.quotationHeading,
                tabTestId: 'quotation',
            },
            {
                id: `${basePath}bom/`,
                link: `bom/`,
                text: t.projectReportsBillOfMaterialsHeading,
                tabTestId: 'billOfMaterials',
            },
            {
                id: `${basePath}bandwidth/`,
                link: `bandwidth/`,
                text: t.projectReportsPowerAndBandwidthReportHeading,
                tabTestId: 'bandwidthReport',
            },
            {
                id: `${basePath}installation/`,
                link: `installation/`,
                text: t.projectReportsInstallationReportHeading,
                tabTestId: 'installationReport',
            },
            {
                id: `${basePath}proposal/`,
                link: `proposal/`,
                text: t.systemProposal,
                tabTestId: 'proposalReport',
            },
            {
                id: `${basePath}maps/`,
                link: `maps/`,
                text: t.maps,
                tabTestId: 'mapsReport',
            },
        ];

        return reportTabs;
    }

    private getSelectedIdFromRoute = (route: string) => {
        if (route.includes(QUOTATION_ROUTE)) {
            return QUOTATION_FINANCING_ID;
        }
        return route;
    };
}
