export class Segment {
    /**
     * Given two list of segments finds all the intersections of them and returns them
     * as a list of segments (Note, intersections between segments in the same list is not checked)
     * @static
     * @param {Segment[]} segmentsA
     * @param {Segment[]} segmentsB
     * @returns {Segment[]}
     */
    public static getAllIntersectionsForSegments(
        segmentsA: Segment[],
        segmentsB: Segment[],
    ): Segment[] {
        const intersectionSegments = segmentsA.reduce(
            (allIntersections, segmentA) => {
                return [
                    ...allIntersections,
                    ...segmentsB.map((segmentB) => segmentA.intersection(segmentB)),
                ];
            },
            [] as Array<Segment | null>,
        );

        return intersectionSegments.filter((segment) => segment !== null) as Segment[];
    }

    constructor(
        public start: number,
        public end: number,
    ) {
        this.start = start;
        this.end = end;
    }

    get distance() {
        return this.end - this.start;
    }

    /**
     * Returns the intersection of the two segments or null if they do not overlap
     * @param {Segment} otherSegment - segment to check for intersection with
     * @returns {Segment | null}
     */
    public intersection(otherSegment: Segment): Segment | null {
        const intersectionStart = Math.max(this.start, otherSegment.start);
        const intersectionEnd = Math.min(this.end, otherSegment.end);

        if (intersectionEnd - intersectionStart <= 0) {
            return null;
        }

        return new Segment(intersectionStart, intersectionEnd);
    }

    /**
     * Subtracts the given segment from the segment (the parts overlapping), returns
     * an array of new segments
     * @param {Segment} subtractingSegment
     * @returns {Segment[]}
     */
    public subtract(subtractingSegment: Segment): Segment[] {
        const intersection = this.intersection(subtractingSegment);

        // If the segments don't overlap, we return the main segment unchanged
        if (intersection === null) {
            return [new Segment(this.start, this.end)];
        }

        const segments = [];
        const reversedIntersectionStart = new Segment(this.start, intersection.start);
        const reversedIntersectionEnd = new Segment(intersection.end, this.end);

        if (reversedIntersectionStart.distance > 0) {
            segments[segments.length] = reversedIntersectionStart;
        }

        if (reversedIntersectionEnd.distance > 0) {
            segments[segments.length] = reversedIntersectionEnd;
        }

        return segments;
    }

    /**
     * Subtracts all the given segments from the segment, returns
     * an array of new segments
     * @param {Segment[]} segments
     * @returns {Segment[]}
     */
    public subtractMultiple(multiSegments: Segment[]): Segment[] {
        return multiSegments.reduce(
            (resultingSegments, subtractSegment) => {
                return [
                    ...resultingSegments.reduce((segments, segment) => {
                        return [...segments, ...segment.subtract(subtractSegment)];
                    }, [] as Segment[]),
                ];
            },
            [this] as Segment[],
        );
    }
}
