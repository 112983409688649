import * as React from 'react';
import { Box, Border, Stack, Icon, TextInput, Text, IconButton } from 'app/components';
import { t } from 'app/translate';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import { getPartnerProductSearchFilter } from '../selectors';
import { AddonSelectorActionService } from '../services';

export const SearchPartnerProduct: React.FC = () => {
    const searchText = useSelector(getPartnerProductSearchFilter);
    const actionService = useService(AddonSelectorActionService);
    const updateSearchFilter = (text: string) =>
        actionService.updatePartnerProductFilter({ searchFilter: text });

    return (
        <Box width="100%" paddingY="base">
            <Border bottomWidth={1} color="grey7">
                <Box width="100%">
                    <Stack flex="fullWidth" spacing="half">
                        {searchText ? (
                            <IconButton
                                icon="cancel"
                                color="blue"
                                onClick={() => updateSearchFilter('')}
                            />
                        ) : (
                            <Icon opaque icon="search" color="grey8" />
                        )}
                        <Box display="block" width="100%">
                            <Text inline style="subheader">
                                <TextInput
                                    testId="search"
                                    noBorder
                                    grow
                                    alwaysTransparent
                                    clear={() => updateSearchFilter('')}
                                    value={searchText ?? ''}
                                    changeCriteria="debounced"
                                    onChange={(text) => updateSearchFilter(text)}
                                    placeholder={t.search}
                                    noClearButton
                                />
                            </Text>
                        </Box>
                    </Stack>
                </Box>
            </Border>
        </Box>
    );
};

SearchPartnerProduct.displayName = 'SearchPartnerProduct';
