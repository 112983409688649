import { ProjectService } from './Project.service';
import { injectable } from 'inversify';
import type {
    IFloorPlanEntity,
    IFloorPlanMapType,
    IGeoMapType,
    IFloorPlan,
    IFloorPlanExportEntity,
    IEntity,
    Id,
    IInstallationPointEntity,
} from '../userDataPersistence';
import { FloorPlanRepository, ProjectDbOrigin } from '../userDataPersistence';
import type { ILatLng, IBounds, IFloorPlanImageExport, IFloorPlanImage } from '../models';
import { ImageService } from './imageStore';
import { debounce } from 'lodash-es';
import { CurrentProjectService } from './CurrentProject.service';
import { toErrorMessage, getProjectId } from '../utils';
import { eventTracking } from 'app/core/tracking';
import { getOffset, offset } from 'axis-webtools-util';

@injectable()
export class FloorPlanService {
    public updateFloorPlanDebounced = debounce(this.updateFloorPlan, 200);
    public updateFloorPlanOpacityDebounced = debounce(this.updateFloorPlanOpacity, 200);

    constructor(
        private floorPlanRepository: FloorPlanRepository,
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private imageService: ImageService,
    ) {}

    public async addFloorPlan(projectId: Id, floorPlan: IFloorPlan): Promise<IFloorPlanEntity> {
        const hasDefaultFloorPlan = (await this.floorPlanRepository.getAll()).some(
            (fp) => getProjectId(fp) === projectId && fp.isDefault,
        );
        return this.floorPlanRepository.add(
            {
                type: 'floorPlan',
                path: [projectId],
                archived: await this.projectService.isArchived(projectId),
                name: floorPlan.name,
                image: floorPlan.image,
                mapType: floorPlan.mapType,
                location: floorPlan.location,
                locked: await this.projectService.isLocked(projectId),
                isDefault: hasDefaultFloorPlan ? undefined : floorPlan.isDefault,
            },
            false,
            false,
        );
    }

    public async updateDefaultMapLocation(location: ILatLng): Promise<void> {
        const defaultMap = this.currentProjectService
            .getAllEntitiesOfType('floorPlan')
            .find((floorPlan) => floorPlan.isDefault);
        if (!defaultMap) return;
        await this.floorPlanRepository.updatePartial(defaultMap._id, { location });
    }

    public async updateFloorPlanGeolocation(
        floorPlanId: Id,
        position: ILatLng,
        width?: number,
        height?: number,
        angle?: number,
    ) {
        const entity = await this.floorPlanRepository.get(floorPlanId);

        const image = entity.image;

        if (image?.bounds) {
            const xyDistance = getOffset(image.bounds.topLeft)(image.bounds.bottomRight);
            const fpWidth = width ?? Math.abs(xyDistance[0]);
            const fpHeight = height ?? Math.abs(xyDistance[1]);
            const fpAngle = angle ?? 0;

            const geoLocation = {
                position,
                width: fpWidth,
                height: fpHeight,
                angle: fpAngle,
            };

            const newBounds: IBounds = {
                topLeft: offset(position)([fpWidth / 2, -fpHeight / 2]),
                bottomRight: offset(position)([-fpWidth / 2, fpHeight / 2]),
            };
            image.geoLocation = geoLocation;
            image.bounds = newBounds;
        }

        const noMapLocations =
            this.currentProjectService.getAllEntitiesOfType('mapLocation').length === 0;
        if (noMapLocations) {
            await this.updateDefaultMapLocation(position);
        }

        return this.floorPlanRepository.update({
            ...entity,
            image,
        });
    }

    /**
     * Update the opacity of a floor plan.
     */
    public async updateFloorPlanOpacity(floorPlanId: Id, opacity: number): Promise<void> {
        const entity = await this.floorPlanRepository.get(floorPlanId);
        const image = entity.image;
        if (image) {
            image.opacity = opacity;
            await this.floorPlanRepository.update({
                ...entity,
                image,
            });
        }
    }

    public async updateFloorPlan(floorPlan: IFloorPlanEntity): Promise<IFloorPlanEntity> {
        const entity = await this.floorPlanRepository.get(floorPlan._id);
        const sameImage = floorPlan.image?.key === entity.image?.key;

        return this.floorPlanRepository.update(
            {
                path: entity.path,
                _id: entity._id,
                _rev: entity._rev,
                creationDate: entity.creationDate,
                entityVersion: entity.entityVersion,
                locked: entity.locked,
                archived: entity.archived,
                mapType: floorPlan.mapType,
                image: floorPlan.image,
                location: floorPlan.location,
                name: floorPlan.name,
                updatedDate: entity.updatedDate,
                type: 'floorPlan',
                isRadarWarningDismissed: floorPlan.isRadarWarningDismissed,
            },
            sameImage,
            sameImage,
        );
    }

    public async getAllFloorPlanIdsFromAllProjects(): Promise<string[]> {
        const projects = await this.projectService.getAllProjectEntities();
        const noLocalProjects = projects.filter(
            (project) =>
                !project.projectDbOrigin || project.projectDbOrigin === ProjectDbOrigin.asdUserData,
        );
        const floorPlanIds: string[] = [];

        await Promise.all(
            noLocalProjects.map(async (project) => {
                const entities = await this.projectService.getDescendants(project._id);
                const floorPlans = entities.filter((entity) =>
                    FloorPlanService.isFloorPlanMapType(entity),
                ) as IFloorPlanMapType[];

                floorPlans.forEach((floorPlan) => {
                    floorPlanIds.push(floorPlan.image.key);
                });
            }),
        );

        return floorPlanIds;
    }

    public async setUnDismissRadarWarning(floorPlanId: Id): Promise<IFloorPlanEntity> {
        return this.floorPlanRepository.updatePartial(floorPlanId, {
            isRadarWarningDismissed: undefined,
        });
    }

    public async setDismissRadarWarning(floorPlanId: Id): Promise<IFloorPlanEntity> {
        return this.floorPlanRepository.updatePartial(
            floorPlanId,
            {
                isRadarWarningDismissed: true,
            },
            true,
            true,
        );
    }

    public async removeFloorPlanImages(projectId: Id): Promise<void[]> {
        const { descendants } =
            await this.floorPlanRepository.getDescendants<IFloorPlanEntity>(projectId);
        const deleteTasks = descendants.map((floorPlan) =>
            FloorPlanService.isFloorPlanMapType(floorPlan)
                ? this.imageService.deleteImage(floorPlan.image.key, true, projectId)
                : Promise.resolve(),
        );

        try {
            return Promise.all(deleteTasks);
        } catch (error) {
            eventTracking.logError(
                `Failed to delete all images in floorplans - projectId: ${projectId}`,
                'Floorplan Service',
            );
            switch (toErrorMessage(error)) {
                case '401':
                case '403':
                    throw Error('AuthError');
                default:
                    throw Error('UnknownNetworkError');
            }
        }
    }

    /**
     * Takes a floor plan entity and returns an exportable floor plan entity with the floor plan image embedded. Throws an error if fetching the image fails.
     */
    public async getExportableFloorPlan(
        floorPlan: IFloorPlanMapType,
        projectId: Id,
    ): Promise<IFloorPlanExportEntity> {
        const image: IFloorPlanImage = floorPlan.image;
        const base64Image = await this.imageService.getImageAsBase64(image.key, projectId);
        const exportableImage: IFloorPlanImageExport = {
            name: image.name,
            bounds: image.bounds,
            dimensions: image.dimensions,
            geoLocation: image.geoLocation,
            base64: base64Image,
            opacity: image.opacity,
            ignoreEXIF: image.ignoreEXIF,
        };

        return {
            ...floorPlan,
            type: 'floorPlan',
            image: exportableImage,
        };
    }

    public async getFloorPlanFromExportable(
        floorPlan: IFloorPlanExportEntity,
    ): Promise<IFloorPlanEntity> {
        if (floorPlan.mapType == 'FloorPlan' && this.isFloorPlanImageExport(floorPlan.image)) {
            const imageExportData = floorPlan.image;

            const imageKey = await this.imageService.copyBase64Image(
                imageExportData.base64,
                imageExportData.name,
            );

            return {
                ...floorPlan,
                image: {
                    key: imageKey,
                    name: imageExportData.name,
                    bounds: imageExportData.bounds,
                    dimensions: imageExportData.dimensions,
                    geoLocation: imageExportData.geoLocation,
                    opacity: imageExportData.opacity,
                    ignoreEXIF: imageExportData.ignoreEXIF,
                },
            };
        } else {
            return floorPlan as IFloorPlanEntity;
        }
    }

    public async removeFloorPlan(floorPlan: IFloorPlanEntity): Promise<Id> {
        const deletedId = await this.floorPlanRepository.delete(
            floorPlan._id,
            floorPlan._rev,
            false,
            false,
        );
        if (floorPlan.image?.key) {
            this.removeFloorPlanImage(floorPlan.image.key);
        }
        // Touches the current project in order for date/time to update correctly
        await this.currentProjectService.touchCurrentProjectEntity();
        return deletedId;
    }

    public static isFloorPlanEntity(entity: IEntity): entity is IFloorPlanEntity {
        return entity.type === 'floorPlan';
    }

    public static isFloorPlanMapType(entity: IEntity): entity is IFloorPlanMapType {
        return this.isFloorPlanEntity(entity) && entity.mapType === 'FloorPlan';
    }

    public static isGeoMapType(entity: IEntity): entity is IGeoMapType {
        return this.isFloorPlanEntity(entity) && entity.mapType === 'StreetMap';
    }

    public static async filterOutFloorPlanMaps(entities: IEntity[]): Promise<IEntity[]> {
        const excludedFloorPlanIds: Id[] = entities
            .filter((entity) => FloorPlanService.isFloorPlanMapType(entity))
            .map(({ _id }) => _id);

        const excludedInstallationPointIds: Id[] = entities
            .filter(
                (entity) =>
                    this.isInstallationPointEntity(entity) &&
                    excludedFloorPlanIds.includes(entity.floorPlanId ?? ''),
            )
            .map(({ _id }) => _id);

        return entities.filter(
            (entity) =>
                !excludedFloorPlanIds.concat(excludedInstallationPointIds).includes(entity._id),
        );
    }

    /**
     * Return the approximated size in bytes for the floorPlans
     */
    public static getApproximateSizeInBytes(floorPlans: IEntity[]): number {
        const totalImageSize = floorPlans.reduce((size, entity) => {
            const floorPlanEntity = entity as IFloorPlanExportEntity;

            if (floorPlanEntity.image && !!floorPlanEntity.image.base64) {
                const imageExportData = floorPlanEntity.image;
                const fileSize = this.getApproxBase64FileSize(imageExportData.base64);
                return size + fileSize;
            }
            return size;
        }, 0);
        return totalImageSize;
    }

    /**
     * Return the approximated size in bytes for the floorPlan
     * https://stackoverflow.com/questions/13378815/base64-length-calculation
     * https://stackoverflow.com/questions/34109053/what-file-size-is-data-if-its-450kb-base64-encoded
     * The second one seems to give the best compared to the blob size.
     * Do not care about the paddingCount (1 or 2 bytes)
     */
    private static getApproxBase64FileSize(base64String: string) {
        if (base64String && base64String.length) {
            const characterCount = base64String.length;
            return (characterCount * 3) / 4;
        }
        return 0;
    }

    private isFloorPlanImageExport(image: any): image is IFloorPlanImageExport {
        return image && !!image.base64;
    }

    private static isInstallationPointEntity(entity: IEntity): entity is IInstallationPointEntity {
        return entity.type === 'installationPoint';
    }

    private async removeFloorPlanImage(key: string): Promise<void> {
        await this.imageService.deleteImage(key).catch((error) => {
            switch (error.message) {
                case '401':
                case '403':
                    throw Error('AuthError');
                default:
                    throw Error('UnknownNetworkError');
            }
        });
    }
}
