import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { ScenarioId, Id, IScenarioSettingsModel } from 'app/core/persistence';
import type { ILightCondition, IScenarioModel } from 'app/modules/common';
import {
    getScenariosRecord,
    ScenarioEditorComponent,
    getProfileOverride,
    getLightConditions,
    getAssociatedProfile,
    ScenarioService,
    ProfileOverrideService,
    getScenarios,
} from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import { ServiceLocator } from 'app/ioc';
import {
    getDeviceScenarioSettings,
    getScenarioSettingsOverride,
} from 'app/modules/common/profile/selectors/getScenarioSettings';

export interface IDeviceScenarioEditorOwnProps {
    deviceId: Id;
    deactivate?: boolean;
}

interface IDeviceScenarioEditorProps {
    deviceId: Id;
    deactivate?: boolean;
    scenarios: IScenarioModel[];
    scenario: IScenarioModel;
    lightConditions: ILightCondition[];
    scenarioSettings: IScenarioSettingsModel;
    scenarioSettingsOverride?: Partial<IScenarioSettingsModel>;
}

const mapStateToProps = (
    state: IStoreState,
    ownProps: IDeviceScenarioEditorOwnProps,
): IDeviceScenarioEditorProps => {
    const profileOverride = getProfileOverride(state, ownProps.deviceId);
    const profile = getAssociatedProfile(state, ownProps.deviceId);
    const scenarios = getScenarios(state);
    const scenarioId =
        profileOverride?.scenario.scenarioId || profile?.scenario.scenarioId || 'atm';

    const scenario = getScenariosRecord(state)[scenarioId];

    return {
        deviceId: ownProps.deviceId,
        scenario,
        scenarios,
        lightConditions: getLightConditions(),
        scenarioSettings: getDeviceScenarioSettings(state, ownProps.deviceId),
        scenarioSettingsOverride: getScenarioSettingsOverride(state, ownProps.deviceId),
        deactivate: ownProps.deactivate,
    };
};

class DeviceScenarioEditorContainer extends React.Component<IDeviceScenarioEditorProps> {
    private scenarioService: ScenarioService;
    private profileOverrideService: ProfileOverrideService;

    constructor(props: IDeviceScenarioEditorProps) {
        super(props);
        this.scenarioService = ServiceLocator.get(ScenarioService);
        this.profileOverrideService = ServiceLocator.get(ProfileOverrideService);
    }

    public render() {
        return (
            <ScenarioEditorComponent
                scenarios={this.props.scenarios}
                scenario={this.props.scenario}
                lightConditions={this.props.lightConditions}
                scenarioSettings={this.props.scenarioSettings}
                scenarioSettingsOverride={this.props.scenarioSettingsOverride}
                onScenarioIdChange={this.onScenarioIdOverride}
                onScenarioSettingsModelChange={this.onScenarioSettingsOverride}
                disabled={this.props.deactivate}
            />
        );
    }

    private onScenarioIdOverride = (id: ScenarioId) => {
        eventTracking.logUserEvent('Project Devices', 'Scene Changed', id);
        this.scenarioService.setScenarioOverride(id, this.props.deviceId);
    };

    private onScenarioSettingsOverride = (model: Partial<IScenarioSettingsModel>) => {
        this.profileOverrideService.updateProfileOverride(this.props.deviceId, {
            scenario: {
                nightLighting: model.nightLighting,
                lightEnd: model.lightEnd?.toPersistable(),
                lightStart: model.lightStart?.toPersistable(),
                sceneDetails: model.sceneDetails,
                scenarioId: model.scenarioId,
            },
        });
    };
}

export const DeviceScenarioEditor = connect(mapStateToProps)(DeviceScenarioEditorContainer);
