import type { Colors } from 'app/styles';
import { createCachedSelector } from 're-reselect';
import { getIdFromProps, toCacheKey } from 'app/modules/common';
import { getDerotatedInstallationPointsArray } from './getProjectInstallationPoints';
import type { IItemEntity, IPersistence } from 'app/core/persistence';
import { defaultColors } from 'app/core/common';

export const getInstallationPointColor = createCachedSelector(
    [getDerotatedInstallationPointsArray, getIdFromProps],
    (installationPoints, installationPointId): Colors => {
        const installationPoint = installationPoints.find((ip) => ip._id === installationPointId);
        let deviceColor = installationPoint?.parentDevice.color;
        if (installationPoint?.parentId) {
            const parentInstallationPoint = installationPoints.find(
                (ip) => ip._id === installationPoint.parentId,
            );
            deviceColor = parentInstallationPoint?.parentDevice.color;
        }
        return deviceColor ?? getDefaultColor(installationPoint?.parentDevice);
    },
)(toCacheKey);

export const getDefaultColor = (device: IPersistence<IItemEntity> | undefined): Colors => {
    if (device?.properties.speaker || device?.properties.pagingConsole) {
        return defaultColors.DEFAULT_SPEAKER_COLOR;
    }
    if (device?.properties.radarDetector) {
        return defaultColors.DEFAULT_RADAR_COLOR;
    }
    return defaultColors.DEFAULT_DEVICE_COLOR;
};
