import { deviceTypeCheckers, Resolution } from 'app/core/persistence';
import type { IPiaBodyWornCamera } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { getCurrentProjectItem } from '../../project/selectors';
import { getPiaItemsRecord } from './getPiaDevices';
import { toCacheKey } from '../../cacheKey';

export const getBodyWornMaxResolution = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (item, piaItemsRecord) => {
        if (!item || !item.productId || !deviceTypeCheckers.isBodyWornCamera(item)) {
            return new Resolution(0, 0);
        }
        const properties =
            (piaItemsRecord[item.productId] as IPiaBodyWornCamera)?.properties ?? undefined;
        const maxResolution =
            properties?.maxVideoResolutionHorizontal && properties?.maxVideoResolutionVertical
                ? new Resolution(
                      properties.maxVideoResolutionHorizontal,
                      properties.maxVideoResolutionVertical,
                  )
                : new Resolution(0, 0);
        return maxResolution;
    },
)(toCacheKey);
