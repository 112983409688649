import { injectable } from 'inversify';
import type { IDecoderItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaDecoder } from 'app/core/pia';
import type { IInstallationReportDecoderDevice } from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum } from 'app/modules/common';

@injectable()
export class DecoderDeviceService {
    constructor(private baseDeviceService: BaseDeviceService) {}

    public async mapDevice(
        item: IPersistence<IDecoderItemEntity>,
        piaItem: IPiaDecoder,
    ): Promise<IInstallationReportDecoderDevice> {
        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Decoder,
        };
    }
}
