import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, DropDownMenuButton, PillButton } from 'app/components';
import { getPoeClassNumber, PoeClass } from 'app/core/pia';
interface IMaxPoeClassFilterProps {
    selectedMaxPoe?: PoeClass;
    onClick(cameraType: PoeClass): void;
}

export const MaxPoeClassFilter: React.FC<IMaxPoeClassFilterProps> = ({
    selectedMaxPoe,
    onClick,
}) => {
    const getButtonLabel = () => {
        if (selectedMaxPoe === undefined) {
            return t.maxPoeClass;
        }

        return `${t.zipStepsGROUP.max}: ${getOptionLabel(selectedMaxPoe)}`;
    };

    const getOptionLabel = (poeClass: PoeClass) => {
        if (poeClass === PoeClass.HighPoE) {
            return t.poeClassesHighPoe;
        }
        const poeClassNumber = getPoeClassNumber(poeClass) <= 3 ? '0-3' : poeClass;
        return t.poeClassesClassNbr(poeClassNumber);
    };

    return (
        <DropDown
            trigger={
                <PillButton
                    minWidth="100px"
                    text={getButtonLabel()}
                    icon="keyboard_arrow_down"
                    selected={selectedMaxPoe !== undefined}
                />
            }
            contents={
                <>
                    {Object.values(PoeClass).map((poeClass) =>
                        getPoeClassNumber(poeClass) < 3 ? null : (
                            <DropDownMenuButton
                                key={poeClass}
                                label={getOptionLabel(poeClass)}
                                selected={selectedMaxPoe === poeClass}
                                onClick={() => onClick(poeClass)}
                            />
                        ),
                    )}
                </>
            }
        />
    );
};

MaxPoeClassFilter.displayName = 'MaxPoeClassFilter';
