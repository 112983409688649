import { createCachedSelector } from 're-reselect';
import { getIdFromProps } from '../../selectors/getIdFromProps';
import { getCurrentProjectRelationsForItem } from './getCurrentProjectRelations';
import {
    getCurrentProjectItem,
    getCurrentProjectItems,
} from '../../project/selectors/getCurrentProject';
import { toCacheKey } from '../../cacheKey';
import { getAvailablePiaItemsRecord, getPiaItemsRecord } from '../../piaDevices';
import { isDefined } from 'axis-webtools-util';
import type { IPiaItem } from 'app/core/pia';

/**
 *  Checks if the item has at least one of it's required components.
 *  Returns true if no required components are needed.
 */
export const getHasRequiredComponents = createCachedSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectItems,
        getCurrentProjectItem,
        getCurrentProjectRelationsForItem,
        getIdFromProps,
    ],
    (piaItems, items, item, itemRelations): boolean => {
        if (!item?.productId) {
            return true;
        }

        const requiredComponentPiaIdGroups =
            piaItems[item.productId].properties.reqComponents ?? [];
        if (requiredComponentPiaIdGroups.length === 0) {
            return true;
        }

        // Checks to find at least one relation that contain all products in the required components group
        // Required components is an array of arrays, requirement is satisfied if all products within a group are present
        return itemRelations.some((itemRelation) =>
            requiredComponentPiaIdGroups.some((reqPiaIdGroup) =>
                reqPiaIdGroup.every(
                    (reqPiaId) => items[itemRelation.childId]?.productId === reqPiaId,
                ),
            ),
        );
    },
)(toCacheKey);

/**
 * Gets a list of required pia items for specified item id. Required components are in groups,
 * since sometimes a group of items are needed.
 */
export const getRequiredComponentsForItem = createCachedSelector(
    [getAvailablePiaItemsRecord, getCurrentProjectItem, getIdFromProps],
    (piaItems, item): IPiaItem[][] => {
        if (!item?.productId) {
            return [];
        }

        const reqComponentIds = piaItems[item.productId]?.properties.reqComponents ?? [];

        return reqComponentIds.reduce((reqPiaItems, reqPiaIdGroup) => {
            const piaItemGroup = reqPiaIdGroup.map((reqPiaItemId) => piaItems[reqPiaItemId]);
            const isAllPiaItemsAvailable = piaItemGroup.every(isDefined);
            if (isAllPiaItemsAvailable) {
                reqPiaItems.push(piaItemGroup);
            }
            return reqPiaItems;
        }, [] as IPiaItem[][]);
    },
)(toCacheKey);
