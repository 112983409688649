import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

interface IFileTransferState {
    loaded: number;
    total: number;
    id: number;
}

const getMapsState = (storeState: IStoreState) => storeState.maps;

const reduceFileTransfers = (fileTransfers: IFileTransferState[]) => {
    if (fileTransfers.length < 1) {
        return 0;
    }
    const sums = fileTransfers.reduce(
        (sum, transfer) => sum + (transfer.loaded / transfer.total) * 100,
        0,
    );

    return Math.ceil(sums / fileTransfers.length);
};

export const getProgressPercent = createSelector([getMapsState], ({ fileTransfers }) =>
    reduceFileTransfers(fileTransfers),
);
