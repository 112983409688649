import { eventTracking } from 'app/core/tracking';
import * as leaflet from 'leaflet';
import { DEFAULT_GOOGLE_MAP_ID } from '../../constants';
type MapType = 'roads' | 'satellite' | 'floorplan';
const GOOGLE_CONTROLS_TIMEOUT = 1000;

export class GoogleTileLayer extends leaflet.TileLayer {
    private googleMap: google.maps.Map | undefined;
    private googleMapDiv: HTMLElement;
    private logoControl: leaflet.Control | undefined;

    constructor(
        private type: MapType,
        private googleMapDivId = DEFAULT_GOOGLE_MAP_ID,
    ) {
        super(GoogleTileLayer.getGoogleTileUrl(type), {
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            maxZoom: 25,
            maxNativeZoom: 20,
            detectRetina: true,
        });
        this.googleMapDiv = document.createElement('div');
    }

    public onAdd(map: leaflet.Map): this {
        this.googleMapDiv =
            document.getElementById(this.googleMapDivId) ?? document.createElement('div');
        this.googleMap = this.createGoogleMap(this.googleMapDiv, this.type);
        this.addEventListeners(map);
        setTimeout(() => this.updateGoogleMapPosition(map), GOOGLE_CONTROLS_TIMEOUT / 2);
        this.trySetGoogleInfo();
        return super.onAdd(map);
    }

    public onRemove(map: leaflet.Map): this {
        this.removeEventListeners(map);
        this.googleMap?.unbindAll();
        this._map?.attributionControl?.setPrefix('');
        this.googleMapDiv.innerHTML = '';
        if (this.logoControl) map.removeControl(this.logoControl);
        return super.onRemove(map);
    }

    public setMapType(type: MapType) {
        const previousType = this.type;
        this.type = type;
        this.googleMap?.setMapTypeId(
            type === 'roads' ? google.maps.MapTypeId.ROADMAP : google.maps.MapTypeId.SATELLITE,
        );

        // First determine whether we should show the tiles or not
        if (type === 'floorplan' && previousType !== 'floorplan') {
            this.hideTilesAndGoogleInfo();
        } else if (type !== 'floorplan') {
            this.showTiles();
        }

        // Then set the zoom level, url and info.
        // Switching map type recreates the tiles at the current zoom level (which can be a floating value).
        // Make sure that the zoom level is a fixed value, since google does not support floating zoom levels.
        this._map?.setZoom(Number(this._map.getZoom().toFixed()));
        this.setUrl(GoogleTileLayer.getGoogleTileUrl(type), false);
        this.trySetGoogleInfo();
    }

    public _invalidateAll() {
        // Prevent reload of all tiles on every zoom step by overriding
        // the `_invalidateAll` method of extended class
    }

    private addEventListeners(map: leaflet.Map) {
        if (!this.googleMap) return;

        this.googleMap.addListener('center_changed', () => {
            this.trySetGoogleInfo();
        });
        map.on('moveend', () => this.updateGoogleMapPosition(map));
        map.on('viewreset', () => this.updateGoogleMapPosition(map));
    }

    private removeEventListeners(map: leaflet.Map) {
        map.off('moveend');
        map.off('viewreset');
    }

    private updateGoogleMapPosition(map: leaflet.Map) {
        if (!map || !this.googleMap) {
            return;
        }
        this.googleMap.moveCamera({ center: map.getCenter(), zoom: map.getZoom() });
    }

    private setGoogleInfo() {
        if (this.type === 'floorplan') return;
        const positions = this.getPositionedControls();
        if (positions) {
            this.setAttribution(
                positions.get(google.maps.ControlPosition.BOTTOM_RIGHT) as HTMLDivElement,
            );
            this.setGoogleLogo(
                positions.get(google.maps.ControlPosition.BOTTOM_LEFT) as HTMLDivElement,
            );
        } else {
            throw new TypeError('Could not get positions');
        }
    }

    private setAttribution(attributionDiv: HTMLDivElement) {
        this._map?.attributionControl?.setPrefix(attributionDiv.innerHTML);
    }

    private setGoogleLogo(logoDiv: HTMLDivElement) {
        if (this.logoControl) {
            this._map?.removeControl(this.logoControl);
        }

        const LogoControl = leaflet.Control.extend({
            onAdd: () => {
                return logoDiv;
            },
        });
        this.logoControl = new LogoControl({ position: 'bottomleft' }).addTo(this._map);
    }

    private hideTilesAndGoogleInfo() {
        if (!this._map) return;
        this.setOpacity(0);
        if (this.logoControl) {
            this._map.removeControl(this.logoControl);
        }
        this._map.attributionControl?.setPrefix('');
    }

    private showTiles() {
        this.setOpacity(1);
    }

    private createGoogleMap(mapDiv: HTMLElement, type: MapType) {
        return new google.maps.Map(mapDiv, {
            center: { lat: 0, lng: 0 },
            zoom: 0,
            tilt: 0,
            mapTypeId:
                type === 'satellite'
                    ? google.maps.MapTypeId.SATELLITE
                    : google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            draggable: false,
            disableDoubleClickZoom: true,
            scrollwheel: false,
            styles: [],
            backgroundColor: 'transparent',
        });
    }

    private static getGoogleTileUrl(type: MapType) {
        return `https://{s}.google.com/vt/lyrs=${type === 'roads' ? 'm' : 's'}&x={x}&y={y}&z={z}`;
    }

    /**
     * This method accesses the private layoutManager. Since this is not exposed in the API we need
     * to use this hacky solution.
     * Accessing the layoutManager give you access to the div:s that google map uses to place information
     * in specified places in the map. We use this to copy the logo and attribution from the map into leaflet.
     */
    private getPositionedControls(): Map<google.maps.ControlPosition, HTMLElement> | null {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const layoutManager = this.googleMap?.__gm?.layoutManager;
        if (!layoutManager) return null;
        for (const key of Object.keys(layoutManager)) {
            const layoutDivElement = layoutManager[key];
            if (layoutDivElement.get && layoutDivElement.get(1) instanceof Node) {
                return layoutDivElement;
            }
        }

        return null;
    }

    private trySetGoogleInfo(maxRetries: number = 3) {
        try {
            this.setGoogleInfo();
        } catch (error: any) {
            if (error instanceof TypeError && maxRetries > 0) {
                setTimeout(() => this.trySetGoogleInfo(--maxRetries), GOOGLE_CONTROLS_TIMEOUT);
            } else {
                eventTracking.logError('Could not get Google attribution and logo', 'Maps');
            }
        }
    }
}
