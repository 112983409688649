import type { IPiaDevice } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';
import { getDeviceAndSubTypeForItem } from './getDeviceTypeForItem';
import { getPiaItem } from './getPiaItem';

export const getPiaDevice = createCachedSelector(
    [getPiaItem, getDeviceAndSubTypeForItem],
    (piaItem, deviceType) => {
        if (!piaItem) {
            return undefined;
        }

        switch (deviceType) {
            case 'accessServer':
            case 'answeringUnit':
            case 'camera':
            case 'encoder':
            case 'mainUnit':
            case 'networkReader':
            case 'sensorUnit':
            case 'speaker':
            case 'iorelay':
            case 'doorcontroller':
            case 'decoder':
            case 'radardetector':
            case 'doorstation':
            case 'peopleCounter':
            case 'analogCamera':
                return piaItem as IPiaDevice;

            default:
                undefined;
        }
    },
)(toCacheKey);
