import { injectable } from 'inversify';

@injectable()
export class NameGenerationService {
    public getName(desiredName: string, names: string[], maxLength: number): string {
        if (!names.includes(desiredName)) {
            return desiredName;
        }

        const countMatchExpression = /(.*)\s\((\d+)\)$/;
        // eslint-disable-next-line no-sparse-arrays
        const [, nameComp, indexComp] = desiredName.match(countMatchExpression) || [
            ,
            desiredName,
            1,
        ];
        const newIndexSuffix = ` (${Number(indexComp) + 1})`;
        const lengthAdjustedNameComp = String(nameComp).slice(0, maxLength - newIndexSuffix.length);
        const newName = lengthAdjustedNameComp + newIndexSuffix;

        return this.getName(newName, names, maxLength);
    }
}
