import * as React from 'react';
import { Linkable, Clickable } from '../../functional';
import type { IDropDownMenuItemProps } from './DropDownMenuItem.component';
import { DropDownMenuItem } from './DropDownMenuItem.component';

export interface IDropDownMenuLinkProps extends IDropDownMenuItemProps {
    /**
     * Use external link
     */
    external?: boolean;
    /**
     * The text label of the item
     */
    label: string;
    /**
     * The route which the user will be redirected to to upon click
     */
    link: string;
}

/**
 * A drop down menu item which when clicked will redirect the user to another
 * part of the application.
 */
export class DropDownMenuLink extends React.PureComponent<IDropDownMenuLinkProps> {
    public render() {
        const { link, external, label, ...props } = this.props;
        return (
            <Linkable external={external} link={link}>
                <Clickable disabled={props.disabled}>
                    <DropDownMenuItem {...props}>{label}</DropDownMenuItem>
                </Clickable>
            </Linkable>
        );
    }
}
