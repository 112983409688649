import { createSelector } from 'reselect';
import { getDefaultGeoMap, getSelectedMapOrDefault } from './getFloorPlans';
import { getMapsState } from './getMapsState';
import { calculateLocationFromBounds } from '../utils';

const getMapViewBoundsRecord = createSelector([getMapsState], (state) => {
    return state.mapViewBounds;
});

/**
 * Returns a function that gets the map view bounds for a specific map.
 * Using a factory instead of a cached selector to make sure the updated bounds are always used.
 */
export const getMapViewBoundsFactory = createSelector(
    [getMapViewBoundsRecord],
    (bounds) => (mapId: string) => bounds[mapId],
);

export const getDefaultMapViewBounds = createSelector(
    [getMapViewBoundsRecord, getDefaultGeoMap],
    (bounds, defaultMap) => {
        if (!defaultMap) return undefined;
        return bounds[defaultMap._id];
    },
);

export const getDefaultMapCurrentLocation = createSelector([getDefaultMapViewBounds], (bounds) => {
    if (!bounds) return undefined;
    return calculateLocationFromBounds(bounds);
});

/** Gets the bounds of the current map view that is only saved in the state */
export const getCurrentMapViewBounds = createSelector(
    [getSelectedMapOrDefault, getMapViewBoundsRecord],
    (floorPlan, bounds) => (floorPlan ? bounds[floorPlan._id] : undefined),
);
