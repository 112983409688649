import * as React from 'react';
import type { IExtendableComponentWithChildren } from '../../models';
import { Box } from '../../containers/box/Box.component';
import { Border } from '../../style';
import { NoPrint, Print, PrintFix } from '../../functional';
import { Stack, Positioned, Spacer } from '../../layout';
import { IconButton } from '../../ui/iconButton';
import { Heading } from '../../text';
import { t } from 'app/translate';
import { print } from '../../services';
import { SpacingsEnum } from 'app/styles';
import type { IAutoTestable } from '../../ui-test';

interface IPrintPanelProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * The heading for the document.
     * Usually the project name
     */
    heading?: string;
    /**
     * A title displayed above the heading when printing
     * Usually the report type (ie. "Bill of materials")
     */
    printHeading?: string;
    /**
     * Display the print button
     */
    printable?: boolean;
    /**
     * Any other buttons to display in the top right corner
     */
    buttons?: JSX.Element;
    /**
     * Event triggered before print has occurred
     */
    onPrint?(e: React.SyntheticEvent): void;
}

/**
 * Display a printable document inside a frame with optional print button.
 */
export class PrintPanel extends React.Component<IPrintPanelProps> {
    public render() {
        const { heading, printable, buttons, children, printHeading, ...attributes } = this.props;
        return (
            <PrintFix>
                <Box testId={this.props.testId} justifyContent="center" width="100%" userSelect>
                    <Box width="100%" maxWidth="1300px" display="block">
                        <Border
                            noPrint
                            color="grey3"
                            radius="2px"
                            shadow="rgba(0,0,0,0.05) 0 0 6px 0px"
                            {...attributes}
                        >
                            <PrintFix>
                                <Box
                                    color="white"
                                    height="100%"
                                    padding="panel"
                                    direction="column"
                                    position="relative"
                                >
                                    <NoPrint>
                                        <Positioned
                                            sendForward
                                            position="sticky"
                                            top={0}
                                            right={SpacingsEnum.panel}
                                        >
                                            <Box justifyContent="end">
                                                <Box
                                                    color="whiteOpacity"
                                                    borderRadius="round"
                                                    paddingLeft="half"
                                                >
                                                    <Stack justifyContent="end">
                                                        {buttons}
                                                        {printable && (
                                                            <IconButton
                                                                opaque
                                                                icon="print"
                                                                color="blue"
                                                                text={t.print}
                                                                onClick={this.onPrint}
                                                            />
                                                        )}
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        </Positioned>
                                    </NoPrint>
                                    <Box
                                        paddingY={!heading && !printHeading ? 'none' : 'base'}
                                        direction="column"
                                    >
                                        <Print>
                                            <Heading>{printHeading}</Heading>
                                        </Print>
                                        <Heading style="title">{heading}</Heading>
                                    </Box>
                                    <PrintFix>
                                        <Stack vertical spacing="none">
                                            <Spacer spacing="quart"></Spacer>
                                            {children}
                                        </Stack>
                                    </PrintFix>
                                </Box>
                            </PrintFix>
                        </Border>
                    </Box>
                </Box>
            </PrintFix>
        );
    }

    private onPrint = (e: React.SyntheticEvent) => {
        if (this.props.onPrint) {
            this.props.onPrint(e);
        }

        print();
    };
}
