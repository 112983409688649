import * as React from 'react';
import { Box, Card, Stack, Text, Icon, Button } from 'app/components';
import { t } from 'app/translate';

interface IIPAlertCardProps {
    message: string;
    buttonText?: string;
    onClick?(): void;
}

export const IPAlertCard: React.FC<IIPAlertCardProps> = ({
    message,
    onClick,
    buttonText = t.addIpConfigurations,
}) => (
    <Card borderColor="yellow" paddingX="base" paddingY="base">
        <Stack vertical spacing="quart">
            <Stack justifyContent="center" spacing="base">
                <Icon color="yellow" icon="info" opaque size="lg" />
                <Stack>
                    <Stack vertical spacing="none">
                        <Text color="yellowText">{message}</Text>
                    </Stack>
                </Stack>
            </Stack>
            {onClick && (
                <Box flex="shrinkAndGrow" justifyContent="end" alignItems="end">
                    <Button onClick={onClick} primary testId="add_IP_configuration_btn">
                        {buttonText}
                    </Button>
                </Box>
            )}
        </Stack>
    </Card>
);

IPAlertCard.displayName = 'IPAlertCard';
