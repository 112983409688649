interface IItemWithCreationDate {
    creationDate: Date | string;
}

export const creationDateComparator = (a: IItemWithCreationDate, b: IItemWithCreationDate) => {
    return a.creationDate > b.creationDate ? -1 : 1;
};

export const creationDateReverseComparator = (a: IItemWithCreationDate, b: IItemWithCreationDate) =>
    creationDateComparator(b, a);
