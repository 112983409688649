import * as React from 'react';
import { Heading, Box, Stack } from 'app/components';
import { LightSelector } from './LightSelector.component';
import { ScenarioSelector } from './ScenarioSelector.component';
import { t } from 'app/translate';
import type { IScenarioSettingsModel, ScenarioId } from 'app/core/persistence';
import { constants } from '../../constants';
import type { IScenarioModel, ILightCondition } from '../../models';
import { ProfileEditorRow } from '../profileEditorRow';
import type { IWearableSceneModel, WearableSceneId } from '../../scenario/WearableScenes';

export interface IScenarioEditorComponentProps {
    scenarios: IScenarioModel[] | IWearableSceneModel[];
    lightConditions?: ILightCondition[];
    scenario: IScenarioModel | IWearableSceneModel;
    scenarioSettings?: IScenarioSettingsModel;
    scenarioSettingsOverride?: Partial<IScenarioSettingsModel>;
    disabled?: boolean;
    onScenarioIdChange(id: ScenarioId | WearableSceneId): void;
    onScenarioSettingsModelChange?(model: IScenarioSettingsModel): void;
}

export class ScenarioEditorComponent extends React.Component<IScenarioEditorComponentProps> {
    public render() {
        return (
            <ProfileEditorRow>
                <Stack vertical spacing="half">
                    <Heading>{t.profilesScenarioEditorScenario}</Heading>
                    <Stack alignItems="start">
                        <Box width={constants.leftColumnWidth}>
                            <ScenarioSelector
                                scenarios={this.props.scenarios}
                                scenario={this.props.scenario}
                                scenarioOverride={this.props.scenarioSettingsOverride}
                                onScenarioChange={this.props.onScenarioIdChange}
                                disabled={this.props.disabled}
                            />
                        </Box>
                        {this.props.lightConditions &&
                            this.props.scenarioSettings &&
                            this.props.onScenarioSettingsModelChange && (
                                <Box flex="shrinkAndGrow">
                                    <LightSelector
                                        lightConditions={this.props.lightConditions}
                                        scenarioSettings={this.props.scenarioSettings}
                                        scenarioSettingsOverride={
                                            this.props.scenarioSettingsOverride
                                        }
                                        onChange={this.props.onScenarioSettingsModelChange}
                                        disabled={this.props.disabled}
                                    />
                                </Box>
                            )}
                    </Stack>
                </Stack>
            </ProfileEditorRow>
        );
    }
}
