import { PiaItemSoftwareCategory, type IPiaApplication } from 'app/core/pia';
import type { IApplicationItem } from 'app/modules/common';
import {
    getCurrentProjectItems,
    getCurrentProjectPartnerItems,
    getCurrentProjectRelationsForItem,
    getPiaItemsRecord,
    toCacheKey,
} from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import { mapIPiaApplicationToIApplicationItem, sortByIncluded } from '../utils';
import type { IPartnerItemEntity, IPersistence } from 'app/core/persistence';

const mapEntityPropertiesToIApplicationItem = (
    entity: IPersistence<IPartnerItemEntity>,
): IApplicationItem => {
    return {
        productId: entity.productId ?? 0,
        name: entity.name,
        vendor: entity.vendor,
        url: entity.url,
        vendorId: entity.vendorId,
        categories: entity.properties.application ? [PiaItemSoftwareCategory.ACAP] : [],
        category: PiaItemSoftwareCategory.ACAP,
    };
};

export const getPersistedApplications = createCachedSelector(
    [
        getPiaItemsRecord,
        getCurrentProjectRelationsForItem,
        getCurrentProjectItems,
        getCurrentProjectPartnerItems,
    ],
    (
        piaItemsRecord,
        itemRelations,
        currentProjectItems,
        currentProjectPartnerItems,
    ): IApplicationItem[] =>
        itemRelations
            .filter(({ relationType }) => relationType === 'acap' || relationType === 'partnerAcap')
            .map((rel) => {
                const entity =
                    currentProjectItems[rel.childId] ?? currentProjectPartnerItems[rel.childId];
                const parentEntity = currentProjectItems[rel.parentId];

                if (!entity?.productId || !parentEntity?.productId) {
                    return null;
                }

                const piaApp = piaItemsRecord[entity.productId] as IPiaApplication;
                const parentProduct = piaItemsRecord[parentEntity.productId];

                const application =
                    entity.type === 'item'
                        ? mapIPiaApplicationToIApplicationItem(parentProduct.relations)(piaApp)
                        : mapEntityPropertiesToIApplicationItem(entity);

                return application;
            })
            .filter(isDefined)
            .sort(sortByIncluded),
)(toCacheKey);
