import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

const getPartnerProductFilter = (state: IStoreState) => state.addonSelector.partnerProductFilter;

export const getPartnerProductSearchFilter = createSelector(
    [getPartnerProductFilter],
    ({ searchFilter }) => searchFilter,
);
export const getPartnerProductUseCase = createSelector(
    [getPartnerProductFilter],
    ({ useCase }) => useCase,
);
export const getPartnerProductSolutionType = createSelector(
    [getPartnerProductFilter],
    ({ solutionType }) => solutionType,
);
export const getPartnerProductSortOrder = createSelector(
    [getPartnerProductFilter],
    ({ sortOrder }) => sortOrder,
);
