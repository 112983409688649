import * as React from 'react';
import type { IMapContext } from './MapProvider';
import { MapContext } from './MapProvider';

export const useMapContext = (): IMapContext => {
    const mapContext = React.useContext(MapContext);
    if (mapContext === null) {
        throw new Error('useMapContext must be used within a MapProvider');
    }

    return mapContext;
};
