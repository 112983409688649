import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Border, Box, Print, Stack, Spacer, NoPageBreak, Text } from 'app/components';
import type { IPersistence, IFloorPlanEntity } from 'app/core/persistence';
import { MiniMap } from 'app/modules/maps';
import type { IFloorPlanLegendItem } from '../selectors/getProposalFloorPlans';
import {
    getLegendForFloorPlan,
    getFloorPlansForSystemProposal,
} from '../selectors/getProposalFloorPlans';
import { Headline } from './Headline.component';
import type { IProposalOrCustomDevice } from '../selectors/getProposalDeviceDetails';
import { getAllProposalDevices } from '../selectors/getProposalDeviceDetails';
import { flatMap } from 'lodash-es';
import { ReportHeader } from '../../components/ReportHeader.container';
import { t } from 'app/translate';
import { getUserSignedIn, getIsLocalProject } from 'app/modules/common';

interface IProposalFloorPlansProps {
    floorPlans: IPersistence<IFloorPlanEntity>[];
    itemsForFloorPlan: Record<string, IFloorPlanLegendItem[]>;
    detailedDevicesList: IProposalOrCustomDevice[];
    showMiniMap: boolean;
}

const mapStateToProps = (storeState: IStoreState): IProposalFloorPlansProps => {
    const itemsForFloorPlan = getLegendForFloorPlan(storeState);
    const floorPlans = getFloorPlansForSystemProposal(storeState);
    const isSignedIn = getUserSignedIn(storeState);
    const isLocalProject = getIsLocalProject(storeState);

    return {
        detailedDevicesList: getAllProposalDevices(storeState),
        floorPlans,
        itemsForFloorPlan,
        showMiniMap: isSignedIn || isLocalProject,
    };
};

const ProposalFloorPlansContainer: React.FunctionComponent<IProposalFloorPlansProps> = ({
    floorPlans,
    itemsForFloorPlan,
    detailedDevicesList,
    showMiniMap,
}) => {
    const deviceIds = flatMap(
        detailedDevicesList,
        ({ id, sensorUnits, analogCameras, expansionModules }) => [
            id,
            ...sensorUnits.map(({ id: sensorId }) => sensorId),
            ...analogCameras.map(({ id: analogCameraId }) => analogCameraId),
            ...expansionModules.map(({ id: expansionModuleId }) => expansionModuleId),
        ],
    );
    return (
        <React.Fragment>
            {floorPlans.map((floorPlan) => (
                <Box key={floorPlan._id} paddingX="base" display="block" pageBreakBefore>
                    <Print display="flex">
                        <ReportHeader title={t.systemProposal} />
                    </Print>
                    <div style={{ paddingTop: '4px' }}>
                        <Headline text={floorPlan.name} />
                        <Box
                            flex="none"
                            justifyContent="center"
                            alignItems="center"
                            paddingY="doublePanel"
                        >
                            {floorPlan.image && showMiniMap && (
                                <MiniMap
                                    testId={`mini_map_proposal_overview`}
                                    noBorder
                                    showLegendIds
                                    deviceIds={deviceIds}
                                    floorPlanId={floorPlan._id}
                                />
                            )}
                        </Box>
                        <Box display="block">
                            <Spacer />
                            {(itemsForFloorPlan[floorPlan._id] ?? []).map((item) => {
                                return (
                                    <NoPageBreak key={item.id}>
                                        <Border topWidth={1} color="warmGrey4">
                                            <Box minHeight="60px">
                                                <Stack>
                                                    <Box paddingLeft="doublePanel">
                                                        <Border width={2} color={item.color}>
                                                            <Box
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                borderRadius="circle"
                                                                padding="quart"
                                                                color={item.color}
                                                                width="28px"
                                                                height="28px"
                                                            >
                                                                <Text
                                                                    align="center"
                                                                    semiBold
                                                                    color="white"
                                                                >
                                                                    {item.legendId}
                                                                </Text>
                                                            </Box>
                                                        </Border>
                                                    </Box>
                                                    <Box width="350px">
                                                        <Text style="headline">{item.name}</Text>
                                                    </Box>
                                                    <Box width="55px" justifyContent="end">
                                                        <Text
                                                            style="title"
                                                            color="warmGrey6"
                                                            whiteSpace="nowrap"
                                                        >
                                                            {`${item.quantity} ×`}
                                                        </Text>
                                                    </Box>
                                                    <Text style="title" color="warmGrey6">
                                                        {item.model || ''}
                                                    </Text>
                                                </Stack>
                                            </Box>
                                        </Border>
                                    </NoPageBreak>
                                );
                            })}
                        </Box>
                    </div>
                </Box>
            ))}
        </React.Fragment>
    );
};

export const ProposalFloorPlans = connect(mapStateToProps)(ProposalFloorPlansContainer);

ProposalFloorPlans.displayName = 'ProposalFloorPlans';
