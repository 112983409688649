import { t } from 'app/translate';
import * as React from 'react';

import { FilterPanel } from './FilterPanel';
import {
    Action,
    DropDown,
    DropDownMenuButton,
    Grid,
    PillButton,
    Stack,
    Text,
} from 'app/components';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { defaultMainUnitFilter } from '../../models';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import { isEqual } from 'lodash-es';
import { SearchField } from './common';
import { SustainabilityFilter } from './SustainabilityFilter';
import type { IMainUnitFilter } from 'app/modules/common';

export const MainUnitFilterPanel: React.FC = () => {
    type checkBoxType = 'alarmInputsOutputs' | 'twoWayAudio' | 'WDRTechnology';

    const [deviceSelectorActionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);

    const mainUnitFilter = useSelector<IStoreState, IMainUnitFilter>(
        (store) => store.deviceSelector.mainUnitFilter,
    );

    const { alarmInputsOutputs, channels, twoWayAudio, WDRTechnology } = mainUnitFilter;

    const channelCountOptions = [1, 4];

    const channelButtonText = t.mainUnitSelectorChannels + (channels ? `: ${channels}` : '');

    /**
     * Updates channel count in main unit filter. Selecting the current option will deactivate the filter
     * @param {number} channelCountOption - The amount of channels selected in the main unit filter panel
     */
    const updateChannelCount = (channelCountOption: number) => {
        const clickedToggle = channels === channelCountOption;
        deviceSelectorActionService.updateMainUnitFilter({
            channels: clickedToggle ? undefined : channelCountOption,
        });
    };

    /**
     * Updates redux state for checkbox filters
     * @param filterType - The checkbox interacted with
     */
    const updateFilterCheckbox = (filterType: checkBoxType) => {
        deviceSelectorActionService.updateMainUnitFilter({
            [filterType]: !mainUnitFilter[filterType],
        });
    };

    /**
     * Resets main unit filter to its default values.
     * Also sets includeDiscontinued to false and removes search text.
     */
    const resetFilter = () => {
        if (searchText) {
            deviceSelectorActionService.setSearchText('');
        }
        if (!isEqual(mainUnitFilter, defaultMainUnitFilter)) {
            deviceSelectorActionService.updateMainUnitFilter(defaultMainUnitFilter);
        }
    };

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                <Stack alignItems="center" justifyContent="between">
                    <Text style="semibold" color="grey6">
                        {t.cameraSelectorRequirementsTitle}
                    </Text>
                    <Action title={t.reset} onClick={resetFilter} />
                </Stack>
                <Grid spacing="halfQuart">
                    <DropDown
                        trigger={
                            <PillButton
                                text={channelButtonText}
                                icon="keyboard_arrow_down"
                                selected={!!channels}
                            />
                        }
                        contents={channelCountOptions.map((channelCount) => (
                            <DropDownMenuButton
                                key={channelCount}
                                label={String(channelCount)}
                                selected={channels === channelCount}
                                onClick={() => updateChannelCount(channelCount)}
                            ></DropDownMenuButton>
                        ))}
                    />
                    <PillButton
                        checkable
                        text={t.alarmIO}
                        selected={alarmInputsOutputs}
                        onClick={() => updateFilterCheckbox('alarmInputsOutputs')}
                    />
                    <PillButton
                        checkable
                        text={t.forensicWDR}
                        selected={WDRTechnology}
                        onClick={() => updateFilterCheckbox('WDRTechnology')}
                    />
                    <PillButton
                        checkable
                        text={t.mainUnitSelectorTwoWayAudio}
                        selected={twoWayAudio}
                        onClick={() => updateFilterCheckbox('twoWayAudio')}
                    />
                    <SustainabilityFilter />
                </Grid>
            </Stack>
        </FilterPanel>
    );
};

MainUnitFilterPanel.displayName = 'MainUnitFilterPanel';
