import { createSelector } from 'reselect';
import { getBandwidthStorageEstimate } from './getBandwidthStorageEstimate';
import { getBandwidthStorageTotal } from './getBandwidthStorageTotal';

/**
 * Selector for getting the formatted storage of currentProject
 */
export const getFormattedStorage = createSelector(
    [getBandwidthStorageEstimate],
    (estimate) => estimate.formattedStorage,
);

/**
 * Selector for getting the used storage of currentProject
 */
export const getStorageUsed = createSelector(
    [getBandwidthStorageEstimate],
    (estimate) => estimate.storage,
);

/**
 * Selector for getting the total available storage in currentProject
 */
export const getStorageTotal = createSelector([getBandwidthStorageTotal], (total) => total.storage);
