import type { Id } from '../../entities';
import type { RepositoryAction } from '../RepositoryAction';

export class RepositoryEntityNotFoundError extends Error {
    constructor(action: RepositoryAction, notFoundId: Id) {
        super(`Could not find entity in database with id: ${notFoundId}, action: ${action}`);

        Object.setPrototypeOf(this, RepositoryEntityNotFoundError.prototype);
    }
}
