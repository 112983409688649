import { injectable } from 'inversify';
import { getItemLocalStorage, setItemLocalStorage } from '../utils';

@injectable()
export class WelcomeService {
    public setAsFinished(): void {
        setItemLocalStorage('WelcomeShown', 'true');
    }

    public hasUserFinished(): boolean {
        const hasFinished = getItemLocalStorage('WelcomeShown');
        return hasFinished === 'true';
    }
}
