import * as React from 'react';
import { Box } from 'app/components';
import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';

const grabHandleStyle = css`
    cursor: move;
`;
interface IDragHandleProps {
    compact?: boolean;
    disabled?: boolean;
    color?: ColorsEnum;
    disabledColor?: ColorsEnum;
}

export const DragHandle: React.FunctionComponent<IDragHandleProps> = ({
    disabled = false,
    compact = false,
    color = ColorsEnum.grey3,
    disabledColor = ColorsEnum.grey2,
}) => {
    const colorToUse = disabled ? disabledColor : color;

    if (compact) {
        return (
            <Box __htmlAttributes={{ className: grabHandleStyle }} paddingLeft="half">
                <svg
                    width="8"
                    height="20"
                    viewBox="0 0 10 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="2" cy="3" r="1.5" fill={colorToUse} />
                    <circle cx="2" cy="9" r="1.5" fill={colorToUse} />
                    <circle cx="2" cy="15" r="1.5" fill={colorToUse} />
                    <circle cx="8" cy="3" r="1.5" fill={colorToUse} />
                    <circle cx="8" cy="9" r="1.5" fill={colorToUse} />
                    <circle cx="8" cy="15" r="1.5" fill={colorToUse} />
                </svg>
            </Box>
        );
    } else {
        return (
            <Box __htmlAttributes={{ className: grabHandleStyle }} paddingLeft="half">
                <svg
                    width="12"
                    height="25"
                    viewBox="0 0 12 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="2.5" cy="2.5" r="2.5" fill={colorToUse} />
                    <circle cx="2.5" cy="12.5" r="2.5" fill={colorToUse} />
                    <circle cx="2.5" cy="22.5" r="2.5" fill={colorToUse} />
                    <circle cx="9.5" cy="17.5" r="2.5" fill={colorToUse} />
                    <circle cx="9.5" cy="7.5" r="2.5" fill={colorToUse} />
                </svg>
            </Box>
        );
    }
};

DragHandle.displayName = 'DragHandle';
