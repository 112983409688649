import * as React from 'react';
import type { IInstallationBaseReportAccessory } from '../../models/devices';
import type { IAutoTestable } from 'app/components';
import { Table, Text } from 'app/components';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'app/translate';
import { PiaAccessoryCategory } from 'app/core/pia';
import type { IDeviceAnalyticRange } from 'app/core/persistence';

interface IAccessoryTableProps extends IAutoTestable {
    items: IInstallationBaseReportAccessory[];
    title: string;
    analyticRange?: IDeviceAnalyticRange;
}

const AXIS_PERIMETER_DEFENDER_PIAID = 22681;
const AXIS_PERIMETER_DEFENDER_ELICENSE_PIAID = 22683;

export const AccessoryTable: React.FC<IAccessoryTableProps> = ({
    items,
    title,
    analyticRange,
    testId,
}) => {
    if (items.length === 0) {
        return null;
    }

    /*
    Method to get other item name for non-pia-items, i.e card reader or rex device
    of type other
    */
    const getNonPiaItemName = (category?: string) => {
        if (category === PiaAccessoryCategory.READERS) {
            return `${t.cardReader}: ${t.other}`;
        }
        if (category === PiaAccessoryCategory.REX) {
            return `${t.rexDevice}: ${t.other}`;
        }
        return '';
    };

    function renderAccessoryRow(isChild: boolean) {
        return function renderAccessory(item: IInstallationBaseReportAccessory): JSX.Element {
            const name =
                (item.piaId === AXIS_PERIMETER_DEFENDER_PIAID ||
                    item.piaId === AXIS_PERIMETER_DEFENDER_ELICENSE_PIAID) &&
                analyticRange?.analyticMode
                    ? `${item.model} - ${
                          analyticRange.analyticMode === 'ai'
                              ? t.analyticModeAi
                              : t.analyticModeCalibration
                      }`
                    : item.model;
            return (
                <React.Fragment key={uuidv4()}>
                    <tr>
                        <th scope="row" style={{ paddingLeft: isChild ? '35px' : undefined }}>
                            <Text testId={`${testId}_td_accessory_${item.model}`}>
                                {item.piaId ? name : getNonPiaItemName(item.category)}
                            </Text>
                        </th>
                        <td>
                            <Text testId={`${testId}_td_accessory_quantity_${item.model}`}>
                                {item.quantity}
                            </Text>
                        </td>
                    </tr>
                    {item.accessories && item.accessories.map(renderAccessoryRow(true))}
                </React.Fragment>
            );
        };
    }

    return (
        <Table headers={[title, '']} rightAlignLastColumn>
            {items.map(renderAccessoryRow(false))}
        </Table>
    );
};

AccessoryTable.displayName = 'AccessoryTable';
