import { productFilters } from './ProductFilters';
import { VendorEnum } from './Vendor.enum';

export const getVendorEnum = (vendor: string) => {
    if (productFilters.isVendorAxis(vendor)) {
        return VendorEnum.Axis;
    }
    if (productFilters.isVendor2N(vendor)) {
        return VendorEnum.twoN;
    }
    if (productFilters.isVendorGenetec(vendor)) {
        return VendorEnum.Genetec;
    }
    return VendorEnum.Other;
};
