import { injectable } from 'inversify';
import type { IPiaMainUnit, IPiaSensorUnit } from 'app/core/pia';
import type {
    Id,
    IMainUnitItemEntity,
    IPersistence,
    ISensorUnitItemEntity,
} from 'app/core/persistence';
import { CurrentProjectService } from 'app/core/persistence';
import type {
    IInstallationReportFSeriesDevice,
    IInstallationReportCameraDevice,
} from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum } from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import { InstallationDetailsService } from '../InstallationDetails.service';

@injectable()
export class FSeriesDeviceService {
    constructor(
        private baseDeviceService: BaseDeviceService,
        private installationDetailsService: InstallationDetailsService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public async mapDevice(
        item: IPersistence<IMainUnitItemEntity>,
        piaItem: IPiaMainUnit,
        projectId: Id,
    ): Promise<IInstallationReportFSeriesDevice> {
        const sensorItems = this.currentProjectService.getDeviceChildren(
            item._id,
            'sensorUnit',
        ) as IPersistence<ISensorUnitItemEntity>[];

        const sensors = await Promise.all(
            sensorItems
                .map((sensorItem) => {
                    const sensorPiaItem = sensorItem.productId
                        ? (this.baseDeviceService.getPiaItemFromProductId(
                              sensorItem.productId,
                          ) as IPiaSensorUnit)
                        : null;
                    return sensorPiaItem
                        ? this.mapSensor(sensorItem, sensorPiaItem, projectId)
                        : null;
                })
                .filter(isDefined),
        );

        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            sensors,
            category: CategoryEnum.FSeries,
        };
    }

    private mapSensor = async (
        item: IPersistence<ISensorUnitItemEntity>,
        piaItem: IPiaSensorUnit,
        projectId: Id,
    ): Promise<IInstallationReportCameraDevice> => ({
        ...(await this.baseDeviceService.mapDevice(item, piaItem)),
        ...(await this.installationDetailsService.getCameraSettings(
            item.properties.sensorUnit,
            piaItem,
            projectId,
        )),
        ...this.installationDetailsService.getCameraInstallationDetails(
            item.properties.sensorUnit,
            piaItem,
        ),
        category: CategoryEnum.Camera,
    });
}
