import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Adds liveView settings to the profile entity.
 */
@injectable()
export class Migration3To4 extends MigrationProviderBase {
    public from: number = 3;
    public to: number = 4;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'profile') {
                entity.liveView = {
                    audio: true,
                    compression: 30,
                    videoEncoding: 'h264',
                    dayMotion: 3,
                    dayTriggerTime: 25,
                    nightMotion: 1,
                    nightTriggerTime: 5,
                    fpsMode: 'fixed',
                    frameRate: 5,
                    gopDefault: 32,
                    gopMax: 300,
                    gopMode: 'fixed',
                    quality: 'medium',
                    schedule: null,
                    zipStrength: 10,
                    resolution: '1920x1080',
                };
            }

            return Promise.resolve(entity);
        };
    }
}
