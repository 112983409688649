import { injectable } from 'inversify';
import type {
    Id,
    IScheduleModel,
    IPersistence,
    IBodyWornCameraProfile,
    IBodyWornCameraItemEntity,
} from 'app/core/persistence';
import { Resolution } from 'app/core/persistence';
import { convert, format } from 'axis-webtools-util';
import type {
    IAggregatedScheduleModel,
    IBandwidthStorageBodyWornEstimateModel,
    ISegmentModel,
} from '../models';

import type { IPiaBodyWornCameraProperties } from 'app/core/pia';
import { ScheduleAggregationService } from './storage/ScheduleAggregation.service';

@injectable()
export class BodyWornCameraEstimateService {
    constructor(private scheduleAggregationService: ScheduleAggregationService) {}

    public getBodyWornCameraEstimate(
        camera: IPersistence<IBodyWornCameraItemEntity>,
        productProperties: IPiaBodyWornCameraProperties,
        getSchedule: (id: Id) => IScheduleModel | undefined,
    ): IBandwidthStorageBodyWornEstimateModel | undefined {
        const profile = camera.properties.bodyWornCamera.profile;

        const storageInMB = profile
            ? this.getStorageInMB(profile, getSchedule, productProperties)
            : 0;

        const profileEstimate = {
            recordingBandwidth: 0,
            liveViewBandwidth: 0,
            storageInMB,
        };

        const videoChannels = productProperties.channels || 1;

        const recordingBandwidthPerCamera = profileEstimate.recordingBandwidth * videoChannels;
        const liveViewBandwidthPerCamera = profileEstimate.liveViewBandwidth * videoChannels;
        const storagePerCamera = profileEstimate.storageInMB * videoChannels;

        const totalAverageRecordingBandwidth = recordingBandwidthPerCamera * camera.quantity;
        const totalAverageLiveViewBandwidth = liveViewBandwidthPerCamera * camera.quantity;
        const totalStorageInMB = storagePerCamera * camera.quantity;

        return {
            type: 'bodyWornCamera',
            perCamera: {
                recordingBandwidth: recordingBandwidthPerCamera,
                formattedRecordingBandwidth: format.bandwidth(recordingBandwidthPerCamera),
                liveViewBandwidth: liveViewBandwidthPerCamera,
                formattedLiveViewBandwidth: format.bandwidth(liveViewBandwidthPerCamera),
                storageInMB: storagePerCamera,
                bandwidthInBps: recordingBandwidthPerCamera + liveViewBandwidthPerCamera,
                formattedStorage: format.storage(storagePerCamera),
                formattedBandwidth: format.bandwidth(
                    recordingBandwidthPerCamera + liveViewBandwidthPerCamera,
                ),
                retentionTime: profile.retentionTimeInDays,
            },
            total: {
                recordingBandwidth: totalAverageRecordingBandwidth,
                formattedRecordingBandwidth: format.bandwidth(totalAverageRecordingBandwidth),
                liveViewBandwidth: totalAverageLiveViewBandwidth,
                formattedLiveViewBandwidth:
                    totalAverageLiveViewBandwidth !== 0
                        ? format.bandwidth(totalAverageLiveViewBandwidth)
                        : '',
                storageInMB: totalStorageInMB,
                bandwidthInBps: totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth,
                formattedStorage: totalStorageInMB !== 0 ? format.storage(totalStorageInMB) : '',
                formattedBandwidth:
                    totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth !== 0
                        ? format.bandwidth(
                              totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth,
                          )
                        : '',
                retentionTime: profile.retentionTimeInDays,
            },
            channels: videoChannels,
            triggeredRecording: undefined,
            continuousRecording: undefined,
        };
    }

    private getStorageInMB(
        profile: IBodyWornCameraProfile,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        productProperties: IPiaBodyWornCameraProperties,
    ) {
        const schedule = profile.scheduleId ? getSchedule(profile.scheduleId) : undefined;

        const resolution = this.getResolution(profile.resolution, productProperties);

        const recordingBandwidthBitPerSecond = this.getRecordingBandwidthInBitPerSecond(
            resolution,
            profile.sceneId,
        );

        const aggregatedSchedule =
            schedule && this.scheduleAggregationService.getAggregatedSchedule(schedule);

        const timeInSeconds = this.getRecordingTimeInSeconds(
            profile.retentionTimeInDays,
            profile.activeRecordingInPercent,
            aggregatedSchedule,
        );

        const storageInMegaBits = convert.toMega(recordingBandwidthBitPerSecond * timeInSeconds);

        // storage in MegaBytes
        const storageInMB = storageInMegaBits / 8;
        return storageInMB;
    }

    private getRecordingBandwidthInBitPerSecond(resolution: string, scenarioId: Id) {
        const resolution1080p = resolution === '1920x1080';
        const resolution720p = resolution === '1280x720';
        // returned recording bandwidth are hard coded estimated values that we got from the bodyworn team.
        // see https://jira.se.axis.com/browse/WT-3856
        if (resolution720p) {
            if (scenarioId === 'indoorLow') {
                return 3500000;
            }
            if (scenarioId === 'indoorHigh') {
                return 6000000;
            }
            if (scenarioId === 'outdoor') {
                return 7000000;
            }
        } else if (resolution1080p) {
            if (scenarioId === 'indoorLow') {
                return 7000000;
            }
            if (scenarioId === 'indoorHigh') {
                return 12000000;
            }
            if (scenarioId === 'outdoor') {
                return 14000000;
            }
        }
        return 0;
    }

    private getRecordingTimeInSeconds(
        retentionTime: number,
        activeRecordingInPercent: number,
        aggregatedSchedule?: IAggregatedScheduleModel,
    ) {
        if (!aggregatedSchedule) {
            return 0;
        }
        let totalSeconds = 0;
        const segments: ISegmentModel[] = [
            ...aggregatedSchedule.monday,
            ...aggregatedSchedule.tuesday,
            ...aggregatedSchedule.wednesday,
            ...aggregatedSchedule.thursday,
            ...aggregatedSchedule.friday,
            ...aggregatedSchedule.saturday,
            ...aggregatedSchedule.sunday,
        ];
        segments.forEach((segment) => {
            const totalMinutesPerDay = segment.end * 60 - segment.start * 60;
            totalSeconds += totalMinutesPerDay * 60;
        });

        return ((totalSeconds * retentionTime) / 7) * (activeRecordingInPercent / 100);
    }

    private getResolution = (
        resolutionString: string,
        productProperties: IPiaBodyWornCameraProperties,
    ) => {
        const resolution = new Resolution(resolutionString);

        const maxHorizontal = productProperties?.maxVideoResolutionHorizontal;
        const maxVertical = productProperties?.maxVideoResolutionVertical;

        /**
         * if the saved value is the choise for maximum resolution (i.e value:
         * `${Number.MAX_SAFE_INTEGER}x${Number.MAX_SAFE_INTEGER}`,
         * then find the cameras actual max resolution and return that value.
         * otherwise return the selcted saved value.
         */
        if (
            maxHorizontal &&
            maxHorizontal < resolution.getHorizontal() &&
            maxVertical &&
            maxVertical < resolution.getVertical()
        ) {
            return `${maxHorizontal}x${maxVertical}`;
        } else {
            return resolution.toString();
        }
    };
}
