import * as React from 'react';
import { LensSelector } from './components';
import type { Id } from 'app/core/persistence';

interface ILensSelectorViewProps {
    itemId: Id;
}

export const LensSelectorView = ({ itemId }: ILensSelectorViewProps) => (
    <LensSelector itemId={itemId} />
);
