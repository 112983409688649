import * as React from 'react';
import type { IAutoTestable } from 'app/components';
import { Border, Box, ReadDirection, RingChart, Text } from 'app/components';
import { t } from 'app/translate';
import { CompactRequirementItem } from './CompactRequirementItem';

interface IRequirementItemProps extends IAutoTestable {
    /** Label of the requirement item */
    label: string;
    /* The value representing how much of the requirement is fulfilled */
    totalInSolution: number;
    /* The required value to be satisfied */
    totalRequired: number;
    /* The text representing how much of the requirement is fulfilled  */
    inSolutionText: React.ReactNode;
    /* The text representeing the required value to be satisfied */
    requiredText: React.ReactNode;
    /* Label next to inSolutionText describing the value. Default is 'In solution' */
    descriptionInSolution?: string;
    /* Label next to requiredText describing the value. Default is 'Required' */
    descriptionRequired?: string;
    /* Use to force error color */
    warn?: boolean;
    /* Use to set condition for when requirements not are applicable */
    notApplicable?: boolean;
    /* Size of the requirement item */
    showSmall?: boolean;
}

/**
 * A radial bar charts displaying how much of required values are fulfilled in percent.
 */
export const RequirementItem: React.FC<IRequirementItemProps> = ({
    label,
    totalInSolution,
    totalRequired,
    inSolutionText,
    requiredText,
    descriptionInSolution,
    descriptionRequired,
    warn,
    notApplicable,
    showSmall,
    testId,
}) => {
    const percent =
        totalRequired === 0 || (totalRequired === 0 && totalInSolution === 0)
            ? 100
            : (totalInSolution / totalRequired) * 100;
    if (showSmall) {
        const solutionNotFulfilled = warn || totalInSolution < totalRequired ? true : false;
        return (
            <CompactRequirementItem
                label={label}
                percent={percent}
                inSolutionText={inSolutionText}
                requiredText={requiredText}
                warn={solutionNotFulfilled}
                notApplicable={notApplicable}
                testId={testId}
            />
        );
    }

    const color = notApplicable
        ? 'grey3'
        : warn || totalInSolution < totalRequired
          ? 'red'
          : 'green';

    return (
        <Box testId={testId} direction="column" alignItems="center">
            <RingChart
                color={warn ? 'red' : undefined}
                backgroundColor={notApplicable ? 'grey3' : undefined}
                percent={notApplicable ? 0 : percent}
                setBackgroundAsColor={totalRequired === 0 && !notApplicable}
                hidePercent={notApplicable}
                text={notApplicable ? t.abbreviationsGROUP.notApplicable : undefined}
            />
            <Box paddingTop="base" paddingBottom="quart">
                <Text style="heading" color="grey9">
                    {label}
                </Text>
            </Box>
            <Box display="grid" grid={{ gridTemplateColumns: '1fr min-content 1fr' }}>
                <Box alignItems="end" direction="column">
                    <ReadDirection direction="ltr">
                        <Text
                            testId={`${testId}_provided`}
                            style="semibold"
                            whiteSpace="nowrap"
                            color="grey6"
                            lineHeight={1}
                        >
                            {inSolutionText}
                        </Text>
                    </ReadDirection>
                    <Text style="small" whiteSpace="nowrap" color="grey6">
                        {descriptionInSolution ?? t.inSolution}
                    </Text>
                </Box>
                <Box>
                    <Border rightWidth={1} color="grey4">
                        <Box width={8}></Box>
                    </Border>
                    <Box width={8}></Box>
                </Box>
                <Box alignItems="start" direction="column">
                    <ReadDirection direction={notApplicable ? undefined : 'ltr'}>
                        <Text
                            testId={`${testId}_required`}
                            style="semibold"
                            whiteSpace="nowrap"
                            color={color}
                            lineHeight={1}
                        >
                            {notApplicable ? t.abbreviationsGROUP.notApplicable : requiredText}
                        </Text>
                    </ReadDirection>
                    <Text style="small" whiteSpace="nowrap" color="grey6">
                        {descriptionRequired ?? t.required}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

RequirementItem.displayName = 'RequirementItem';
