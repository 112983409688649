import * as React from 'react';
import { Navigate } from 'react-router-dom';

interface IPrivateRouteProps {
    component: React.ReactElement;
    condition: boolean;
    fallbackRoute: string;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
    component,
    condition,
    fallbackRoute,
}) => {
    return condition ? component : <Navigate replace to={fallbackRoute} />;
};

PrivateRoute.displayName = 'PrivateRoute';
