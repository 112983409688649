import { injectable } from 'inversify';
import type { Id, IItem, IItemEntity, IPersistence } from 'app/core/persistence';
import { ItemService, CurrentProjectService, InstallationPointService } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';
import { t } from 'app/translate';
import { ModalService } from 'app/modal';
import type { Colors } from 'app/styles';
import { AccessoryService } from '../../accessory/services';
import { IAddProductProps, IMainUnitFilter } from '../models';

@injectable()
export class MainUnitsService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private accessoryService: AccessoryService,
        private installationPointService: InstallationPointService,
        private modalService: ModalService,
    ) {}

    public async addOrUpdateDevice(
        productId: PiaId | null,
        filter: IMainUnitFilter,
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
    ) {
        if (itemToEdit) {
            return this.updateMainUnit(productId, itemToEdit.quantity, filter, itemToEdit);
        } else if (newItemProps) {
            return this.addMainUnit(productId, newItemProps, filter);
        }
    }

    private async addMainUnit(
        productId: PiaId | null,
        newItemProps: IAddProductProps,
        filter: IMainUnitFilter,
    ) {
        const item: IItem = this.toIItem(
            productId,
            newItemProps.name,
            '',
            newItemProps.notes || '',
            newItemProps.quantity,
            newItemProps.color,
            filter.channels,
            filter.twoWayAudio,
            filter.WDRTechnology,
            filter.ruggedizedEN50155,
            filter.alarmInputsOutputs,
            filter.outdoor,
        );

        const mainUnit = await this.itemService.addToCurrentProject(item);

        return this.itemService.getItem(mainUnit._id);
    }

    private async updateMainUnit(
        productId: number | null,
        quantity: number,
        filter: IMainUnitFilter,
        itemToEdit: IPersistence<IItemEntity>,
    ) {
        const productChanged = itemToEdit.productId !== productId;

        const { descendants } = await this.installationPointService.getInstallationPointDescendants(
            itemToEdit._id,
        );
        const mainUnitsInMap = descendants.filter((item) => !item.parentId);
        const sensorUnitsInMap = descendants.filter((item) => item.parentId);

        // If quantity changed - check if below quantity in maps
        if (quantity < itemToEdit.quantity) {
            if (mainUnitsInMap.length > quantity) {
                const removeMainUnits = await this.modalService.createConfirmDialog({
                    header: t.removeInstallationPointsConfirmationGROUP.header,
                    body: t.removeInstallationPointsConfirmationGROUP.body,
                    cancelButtonText: t.cancel,
                    confirmButtonText: t.change,
                })();
                if (removeMainUnits) {
                    await this.installationPointService.removeInstallationPoints(descendants);
                } else {
                    return;
                }
            }
        }

        // Model changed
        if (productChanged) {
            const hasIncompatibleAccessories = this.accessoryService.getHasPiaItemsToRemove(
                itemToEdit._id,
                productId,
            );
            const hasSensorUnits = this.hasSensorUnits(itemToEdit._id);

            if (hasSensorUnits && hasIncompatibleAccessories) {
                const result = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                    t.removeSensorUnitsAndAccessoriesConfirmationGROUP.header,
                    t.removeSensorUnitsAndAccessoriesConfirmationBody,
                );
                if (result) {
                    await this.installationPointService.removeInstallationPoints(sensorUnitsInMap);
                } else {
                    return;
                }
            } else if (hasSensorUnits) {
                const result = await this.modalService.createConfirmDialog({
                    header: t.removeSensorUnitsConfirmationGROUP.header,
                    body: t.removeSensorUnitsConfirmationGROUP.body,
                    cancelButtonText: t.cancel,
                    confirmButtonText: t.change,
                })();
                if (result) {
                    await this.installationPointService.removeInstallationPoints(sensorUnitsInMap);
                } else {
                    return;
                }
            } else if (hasIncompatibleAccessories) {
                const result = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                );
                if (!result) {
                    return;
                }
            }
        }

        itemToEdit.properties.mainUnit = {
            filter: {
                twoWayAudio: filter.twoWayAudio,
                WDRTechnology: filter.WDRTechnology,
                ruggedizedEN50155: filter.ruggedizedEN50155,
                alarmInputsOutputs: filter.alarmInputsOutputs,
                channels: filter.channels,
                outdoor: filter.outdoor,
            },
        };

        const itemProps: Partial<IItem> = {
            name: itemToEdit.name,
            quantity: itemToEdit.quantity,
            description: itemToEdit.description,
            notes: itemToEdit.notes,
            productId,
            properties: itemToEdit.properties,
        };

        await this.removeAllSensorUnits(itemToEdit);

        if (productChanged) {
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                itemToEdit._id,
                productId,
            );
        }
        return this.itemService.updateItem(itemToEdit._id, itemProps);
    }

    private removeAllSensorUnits = async (mainUnit: IPersistence<IItemEntity>) => {
        const relations = this.currentProjectService.getItemRelations(mainUnit._id);
        const sensorUnitRelations = relations.filter(
            ({ relationType }) => relationType === 'sensorUnit',
        );

        await Promise.all(
            sensorUnitRelations.map((rel) => this.itemService.deleteItem(rel.childId)),
        );
        return this.itemService.getItem(mainUnit._id);
    };

    private toIItem(
        selectedMainUnitId: number | null,
        name: string,
        description: string,
        notes: string,
        quantity: number,
        color: Colors,
        channels: number | undefined = undefined,
        twoWayAudio: boolean = false,
        WDRTechnology: boolean,
        ruggedizedEN50155: boolean = false,
        alarmInputsOutputs: boolean,
        outdoor: boolean,
    ): IItem {
        const item: IItem = {
            name,
            description,
            notes,
            productId: selectedMainUnitId,
            quantity,
            color,
            properties: {
                mainUnit: {
                    filter: {
                        twoWayAudio,
                        WDRTechnology,
                        ruggedizedEN50155,
                        alarmInputsOutputs,
                        channels,
                        outdoor,
                    },
                },
            },
        };

        return item;
    }

    private hasSensorUnits(id: Id): boolean {
        const relations = this.currentProjectService.getItemRelations(id);
        return relations.some((rel) => rel.relationType === 'sensorUnit');
    }
}
