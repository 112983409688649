import { getItemLocalStorage } from 'app/core/persistence';
import * as React from 'react';
import { GenetecErrorInfo } from './GenetecErrorInfo';
import { GenetecStreamVaultCalculator } from './GenetecStreamVaultCalculator';

interface IGenetecErrorProps {
    onReload(): void;
    onModifyRecommendations(): void;
}

export const GenetecError: React.FC<IGenetecErrorProps> = ({
    onReload,
    onModifyRecommendations,
}) => {
    const isTermsAndConditionsAccepted =
        getItemLocalStorage('TermsAndConditionsAccepted') === 'true';

    return (
        <>
            <GenetecErrorInfo onReload={onReload} />
            <GenetecStreamVaultCalculator
                disabled={!isTermsAndConditionsAccepted}
                onModifyRecommendations={onModifyRecommendations}
            />
        </>
    );
};

GenetecError.displayName = 'GenetecError';
