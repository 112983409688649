import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { TimeSerie } from './TimeSerie.container';
import {
    getTimeSerieIdsForInvertedSchedule,
    getTimeSerieIdsForSelectedSchedule,
} from './selectors';
interface ITimeSeriesProps {
    inverted: boolean;
}

export const TimeSeries: React.FC<ITimeSeriesProps> = ({ inverted }) => {
    const timeSerieIds = useSelector<IStoreState, string[]>((state) => {
        return inverted
            ? getTimeSerieIdsForInvertedSchedule(state)
            : getTimeSerieIdsForSelectedSchedule(state);
    });
    const isRemovable = timeSerieIds.length > 1;

    return (
        <>
            {timeSerieIds.map((id) => (
                <TimeSerie
                    key={id}
                    timeSerieId={id}
                    readOnly={inverted}
                    isRemovable={isRemovable}
                />
            ))}
        </>
    );
};

TimeSeries.displayName = 'TimeSeries';
