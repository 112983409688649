import { Ray, Vector3 } from 'three';
import type { ICameraVectors } from '../math';

export interface ICameraRays {
    up: Ray;
    down: Ray;
    topCenter: Ray;
    bottomCenter: Ray;
    leftCenter: Ray;
    rightCenter: Ray;
    topLeft: Ray;
    topRight: Ray;
    bottomLeft: Ray;
    bottomRight: Ray;
}

export const getCornerRays = (vectors: ICameraVectors, cameraHeight: number): ICameraRays => {
    const cameraPos = new Vector3(0, cameraHeight, 0);

    return {
        topLeft: new Ray(cameraPos, vectors.topLeft),
        topRight: new Ray(cameraPos, vectors.topRight),
        bottomLeft: new Ray(cameraPos, vectors.bottomLeft),
        bottomRight: new Ray(cameraPos, vectors.bottomRight),
        bottomCenter: new Ray(cameraPos, vectors.bottomCenter),
        topCenter: new Ray(cameraPos, vectors.topCenter),
        leftCenter: new Ray(cameraPos, vectors.leftCenter),
        rightCenter: new Ray(cameraPos, vectors.rightCenter),
        up: new Ray(cameraPos, vectors.up),
        down: new Ray(cameraPos, vectors.down),
    };
};
