import { injectable } from 'inversify';
import { isCustomPouchError } from 'app/utils';

export type IDoc = PouchDB.Core.ExistingDocument<Record<string, any>>;

interface IDocWithPath extends IDoc {
    locked?: boolean;
    path: PouchDB.Core.DocumentId[];
}

interface IDocWithConflicts extends IDoc {
    _conflicts: PouchDB.Core.RevisionId[];
}

@injectable()
export class ConflictResolutionService {
    public setupConflictResolution(db: PouchDB.Database): void {
        db.changes<IDoc>({
            since: 'now',
            live: true,
            include_docs: true,
            conflicts: true,
            style: 'all_docs',
        }).on('change', async (change) => {
            const doc = change.doc;
            if (doc) {
                if (this.hasConflict(doc)) {
                    // handle cases where both sides modified the same document
                    const conflictRev = doc._conflicts[0];
                    return db
                        .get<IDoc>(doc._id, { rev: conflictRev })
                        .then((conflictingDoc) => this.resolveConflict(db, doc, conflictingDoc));
                } else if (!this.hasLockFlag(doc) && (await this.hasLockedParent(db, doc))) {
                    // Remove unlocked objects that were added to the locked project
                    // this code doesn't have to catch all cases
                    return db.remove(doc._id, doc._rev);
                }
            }
        });
    }

    private hasConflict(doc: IDoc): doc is IDocWithConflicts {
        return !!(doc && doc._conflicts && doc._conflicts.length > 0);
    }

    private hasPath(doc: IDoc): doc is IDocWithPath {
        return !!(doc && doc.path && doc.path.length > 0);
    }

    private hasLockFlag(doc: IDoc): boolean {
        return !!doc.locked;
    }

    private async hasLockedParent(db: PouchDB.Database, doc: IDoc): Promise<boolean> {
        if (this.hasPath(doc)) {
            for (const parentId of doc.path) {
                try {
                    const parentDoc = await db.get<IDoc>(parentId);
                    if (this.hasLockFlag(parentDoc)) {
                        return true;
                    }
                } catch (e) {
                    // A non existent parent shouldn't be considered as locked
                    // This can happen due to the non-transactional nature of pouch
                    if (!isCustomPouchError(e) || e.name !== 'not_found') {
                        throw e;
                    }
                }
            }
        }
        return false;
    }

    private resolveConflict(db: PouchDB.Database, currentDoc: IDoc, conflictingDoc: IDoc) {
        if (this.shouldKeepCurrent(currentDoc, conflictingDoc)) {
            return db.remove(conflictingDoc._id, conflictingDoc._rev);
        } else {
            return db.remove(currentDoc._id, currentDoc._rev);
        }
    }

    private shouldKeepCurrent(currentDoc: IDoc, conflictingDoc: IDoc) {
        // only discard current if the conflicting doc is locked and current is not
        return !this.hasLockFlag(conflictingDoc) || this.hasLockFlag(currentDoc);
    }
}
