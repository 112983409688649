import * as React from 'react';
import type { LatLng, LeafletEvent, LeafletMouseEvent } from 'leaflet';
import { useCallback, useEffect, useState } from 'react';
import { useService } from 'app/ioc';
import type { Id, IFloorPlanImage, ILatLng } from 'app/core/persistence';
import { ImageService } from 'app/core/persistence';
import { useMapContext } from '../../context';
import { MapsActionService } from '../../../services';
import {
    add,
    dotProduct,
    getBearing,
    getMidpoint,
    getOffset,
    mul,
    offset,
    rotate,
} from 'axis-webtools-util';
import { PolyLine } from './PolyLine';
import { ControlPoint } from './ControlPoint';
import { Image } from './Image';
import { useOldValueIfUnchanged } from 'app/hooks';
import { getShowGeoLocationTool } from 'app/modules/maps/selectors';
import { useSelector } from 'react-redux';
import { eventTracking } from 'app/core/tracking';

interface IFloorPlanProps {
    id: Id;
    floorPlanImage: IFloorPlanImage;
    editable: boolean;
}

export const FloorPlan: React.FC<IFloorPlanProps> = ({ id, floorPlanImage, editable }) => {
    const imageService = useService(ImageService);
    const mapsActionService = useService(MapsActionService);
    const { leafletMap } = useMapContext();
    const isGeoLocationActive = useSelector(getShowGeoLocationTool);

    const [topLeftCorner, setTopLeftCorner] = useState<ILatLng>({ lat: 0, lng: 0 });
    const [topRightCorner, setTopRightCorner] = useState<ILatLng>({ lat: 0, lng: 0 });
    const [bottomRightCorner, setBottomRightCorner] = useState<ILatLng>({ lat: 0, lng: 0 });
    const [bottomLeftCorner, setBottomLeftCorner] = useState<ILatLng>({ lat: 0, lng: 0 });
    const [rotationHandle, setRotationHandle] = useState<ILatLng>({ lat: 0, lng: 0 });

    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [lat, setLat] = useState<number>(floorPlanImage.geoLocation?.position.lat ?? 0);
    const [lng, setLng] = useState<number>(floorPlanImage.geoLocation?.position.lng ?? 0);
    const [aspectRatio, setAspectRatio] = useState<number>(1);
    const [angle, setAngle] = useState<number>(floorPlanImage.geoLocation?.angle ?? 0);
    const [width, setWidth] = useState<number>(floorPlanImage.geoLocation?.width ?? 0);
    const [height, setHeight] = useState<number>(floorPlanImage.geoLocation?.height ?? 0);

    const geoLocation = useOldValueIfUnchanged(floorPlanImage.geoLocation);

    const onTopLeftDrag = useCallback(
        (event: LeafletEvent) => {
            const e = event as LeafletMouseEvent;

            const right = rotate(angle)([1, 0]);
            const up = rotate(angle)([0, 1]);
            const diagonal = getOffset(bottomRightCorner)(e.latlng);
            const calculatedWidth = Math.abs(dotProduct(diagonal, right));
            const calculatedHeight = Math.abs(dotProduct(diagonal, up));
            const constrainedWidth = Math.min(calculatedWidth, calculatedHeight * aspectRatio);
            const constrainedHeight = Math.min(calculatedHeight, calculatedWidth / aspectRatio);

            const centerPoint = offset(bottomRightCorner)(
                add(mul(up, constrainedHeight / 2), mul(right, -constrainedWidth / 2)),
            );

            setLat(centerPoint.lat);
            setLng(centerPoint.lng);
            setWidth(constrainedWidth);
            setHeight(constrainedHeight);
        },
        [bottomRightCorner, angle, aspectRatio],
    );

    const onTopRightDrag = useCallback(
        (event: LeafletEvent) => {
            const e = event as LeafletMouseEvent;

            const right = rotate(angle)([1, 0]);
            const up = rotate(angle)([0, 1]);
            const diagonal = getOffset(bottomLeftCorner)(e.latlng);
            const calculatedWidth = Math.abs(dotProduct(diagonal, right));
            const calculatedHeight = Math.abs(dotProduct(diagonal, up));
            const constrainedWidth = Math.min(calculatedWidth, calculatedHeight * aspectRatio);
            const constrainedHeight = Math.min(calculatedHeight, calculatedWidth / aspectRatio);

            const centerPoint = offset(bottomLeftCorner)(
                add(mul(up, constrainedHeight / 2), mul(right, constrainedWidth / 2)),
            );

            setLat(centerPoint.lat);
            setLng(centerPoint.lng);
            setWidth(constrainedWidth);
            setHeight(constrainedHeight);
        },
        [bottomLeftCorner, angle, aspectRatio],
    );

    const onBottomRightDrag = useCallback(
        (event: LeafletEvent) => {
            const e = event as LeafletMouseEvent;

            const right = rotate(angle)([1, 0]);
            const up = rotate(angle)([0, 1]);
            const diagonal = getOffset(topLeftCorner)(e.latlng);
            const calculatedWidth = Math.abs(dotProduct(diagonal, right));
            const calculatedHeight = Math.abs(dotProduct(diagonal, up));
            const constrainedWidth = Math.min(calculatedWidth, calculatedHeight * aspectRatio);
            const constrainedHeight = Math.min(calculatedHeight, calculatedWidth / aspectRatio);

            const centerPoint = offset(topLeftCorner)(
                add(mul(up, -constrainedHeight / 2), mul(right, constrainedWidth / 2)),
            );

            setLat(centerPoint.lat);
            setLng(centerPoint.lng);
            setWidth(constrainedWidth);
            setHeight(constrainedHeight);
        },
        [topLeftCorner, angle, aspectRatio],
    );

    const onBottomLeftDrag = useCallback(
        (event: LeafletEvent) => {
            const e = event as LeafletMouseEvent;

            const right = rotate(angle)([1, 0]);
            const up = rotate(angle)([0, 1]);
            const diagonal = getOffset(topRightCorner)(e.latlng);
            const calculatedWidth = Math.abs(dotProduct(diagonal, right));
            const calculatedHeight = Math.abs(dotProduct(diagonal, up));
            const constrainedWidth = Math.min(calculatedWidth, calculatedHeight * aspectRatio);
            const constrainedHeight = Math.min(calculatedHeight, calculatedWidth / aspectRatio);

            const centerPoint = offset(topRightCorner)(
                add(mul(up, -constrainedHeight / 2), mul(right, -constrainedWidth / 2)),
            );

            setLat(centerPoint.lat);
            setLng(centerPoint.lng);
            setWidth(constrainedWidth);
            setHeight(constrainedHeight);
        },
        [topRightCorner, angle, aspectRatio],
    );

    const onRotationHandleDrag = useCallback(
        (event: LeafletEvent) => {
            const e = event as LeafletMouseEvent;
            const alpha = getBearing({ lat, lng }, e.latlng) - Math.PI / 2;

            setAngle(alpha);
        },
        [lat, lng],
    );

    const onImageDrag = useCallback((latlng: LatLng) => {
        setLat(latlng.lat);
        setLng(latlng.lng);
    }, []);

    useEffect(() => {
        if (!geoLocation) {
            return;
        }

        setLat(geoLocation.position.lat);
        setLng(geoLocation.position.lng);
        setWidth(geoLocation.width);
        setHeight(geoLocation.height);
        setAngle(geoLocation.angle);
        setAspectRatio(geoLocation.width / geoLocation.height);
    }, [leafletMap, geoLocation]);

    useEffect(() => {
        const position = { lat, lng };
        const offsetPosition = offset(position);
        const rotateAngle = rotate(angle);

        setTopLeftCorner(offsetPosition(rotateAngle([-width / 2, height / 2])));
        setTopRightCorner(offsetPosition(rotateAngle([width / 2, height / 2])));
        setBottomRightCorner(offsetPosition(rotateAngle([width / 2, -height / 2])));
        setBottomLeftCorner(offsetPosition(rotateAngle([-width / 2, -height / 2])));

        setRotationHandle(offsetPosition(rotateAngle([width, 0])));

        if (editable) {
            mapsActionService.updateFloorPlanGeolocationChangeState({
                [id]: {
                    position,
                    width,
                    height,
                    angle,
                },
            });
        }
    }, [mapsActionService, editable, id, lat, lng, width, height, angle]);

    useEffect(() => {
        if (floorPlanImage.key) {
            (async () => {
                try {
                    const url = await imageService.getImageUrlAsBase64(floorPlanImage.key);
                    setImageUrl(url);
                } catch (e) {
                    setLoadingError(true);
                    setImageUrl(undefined);
                }
            })();
        }
    }, [floorPlanImage.key, imageService]);

    const onDoubleClick = () => {
        if (!isGeoLocationActive) {
            mapsActionService.setSelectedMap(id);
            eventTracking.logUserEvent('Maps', 'Double click on floor plan');
        }
    };

    return (
        <>
            <Image
                src={imageUrl}
                loadError={loadingError}
                position={{ lat, lng }}
                angle={angle}
                width={width}
                height={height}
                opacity={floorPlanImage.opacity}
                onDrag={editable ? onImageDrag : undefined}
                onDoubleClick={onDoubleClick}
            />
            <PolyLine
                latLngs={[
                    topLeftCorner,
                    topRightCorner,
                    bottomRightCorner,
                    bottomLeftCorner,
                    topLeftCorner,
                ]}
                color="grey9"
                opacity={0.5}
                weight={0.2}
            />
            {editable && (
                <>
                    <PolyLine
                        latLngs={[
                            topLeftCorner,
                            topRightCorner,
                            bottomRightCorner,
                            bottomLeftCorner,
                            topLeftCorner,
                        ]}
                    />
                    <PolyLine
                        latLngs={[rotationHandle, getMidpoint(topRightCorner, bottomRightCorner)]}
                    />
                    <ControlPoint latLng={topLeftCorner} onDrag={onTopLeftDrag} />
                    <ControlPoint latLng={topRightCorner} onDrag={onTopRightDrag} />
                    <ControlPoint latLng={bottomRightCorner} onDrag={onBottomRightDrag} />
                    <ControlPoint latLng={bottomLeftCorner} onDrag={onBottomLeftDrag} />
                    <ControlPoint latLng={rotationHandle} onDrag={onRotationHandleDrag} round />
                </>
            )}
        </>
    );
};

FloorPlan.displayName = 'FloorPlan';
