import * as React from 'react';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { Box } from '../containers/box/Box.component';
import { Text } from '../text/text/Text.component';
import { Positioned } from '../layout';

interface IRingChartProps {
    /** Percentage (over 100% will create a secondary colored ring) */
    percent: number;
    /** Hides the percentage value in the center of the ring */
    hidePercent?: boolean;
    /** Color of the filled percentage */
    color?: Colors;
    /** Color of the background ring */
    backgroundColor?: Colors;
    /** Set background color as color prop (for instance when percent is 0 and desired background color is same as filled percent) */
    setBackgroundAsColor?: boolean;
    /** Shows a text instead of percentage in the center of the ring */
    text?: React.ReactNode;
    /** Sets the width and height of the ring */
    size?: React.CSSProperties['width'];
    /** Shows percentage or text with small style */
    smallText?: boolean;
}

const svgStyle = css`
    transform: rotate(-0.25turn);
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: visible !important;
`;

const pieStyle = (
    color?: Colors,
    opacity?: React.CSSProperties['opacity'],
    strokeWidth: React.CSSProperties['strokeWidth'] = '5%',
) => css`
    stroke: ${color ? ColorsEnum[color] : ColorsEnum['grey4']};
    stroke-width: ${strokeWidth};
    fill: none;
    opacity: ${opacity};
    transition: stroke ease 250ms;
`;

/** Creates a chart that is formed like a ring */
export const RingChart: React.FunctionComponent<IRingChartProps> = ({
    percent,
    color = 'green',
    setBackgroundAsColor = false,
    backgroundColor = 'red',
    hidePercent,
    text,
    size = '56px',
    smallText,
}) => {
    const normalizedPercent = Math.min(100, Math.max(0, percent));
    const additionalPercent = Math.min(100, Math.max(0, percent - 100));
    const displayedPercent = percent > 999 ? 999 : Math.round(Math.max(0, percent));

    return (
        <Box width={size} height={size} flex="none" position="relative">
            {/* The view box and svg style is used to make a cartesian coordinate system */}
            <svg id="ring" viewBox="-1 -1 2 2" className={svgStyle}>
                {/** Path for the background ring */}
                {!additionalPercent && (
                    <path
                        d={getPathData(100)}
                        className={pieStyle(setBackgroundAsColor ? color : backgroundColor)}
                    />
                )}
                {/** Path for the percent ring */}
                <path
                    d={getPathData(normalizedPercent)}
                    className={pieStyle(color, additionalPercent ? 0.5 : 1, '6%')}
                />
                {/** Path for the ring shown when > 100 percent */}
                {additionalPercent && (
                    <path d={getPathData(additionalPercent)} className={pieStyle(color, 1, '6%')} />
                )}
            </svg>
            {!hidePercent && (
                <Positioned position="absolute" top={0} left={0} bottom={0} right={0}>
                    <Box justifyContent="center" alignItems="center">
                        <Text
                            whiteSpace="nowrap"
                            color="grey6"
                            style={smallText ? 'small' : 'semibold'}
                        >
                            {displayedPercent === 999 && '>'}
                            {displayedPercent}%
                        </Text>
                    </Box>
                </Positioned>
            )}
            {hidePercent && text && (
                <Positioned position="absolute" top={0} left={0} bottom={0} right={0}>
                    <Box justifyContent="center" alignItems="center">
                        <Text
                            whiteSpace="nowrap"
                            color="grey6"
                            style={smallText ? 'small' : 'semibold'}
                        >
                            {text}
                        </Text>
                    </Box>
                </Positioned>
            )}
        </Box>
    );
};

const getPathData = (percent: number): string => {
    const [startX, startY] = [1, 0];
    const [endX, endY] = getCoordinatesForPercent(percent);
    const largeArcFlag = percent > 50 ? 1 : 0;

    const pathData = [`M ${startX} ${startY}`, `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`].join(
        ' ',
    );

    return pathData;
};

const getCoordinatesForPercent = (percent: number) => {
    const x = Math.cos((2 * Math.PI * percent) / 100);
    // Use -0.0001 to make sure the ring goes all the way around and not represent 0 percent.
    const y = percent >= 100 ? -0.0001 : Math.sin((2 * Math.PI * percent) / 100);
    return [x, y];
};

RingChart.displayName = 'RingChart';
