import { KeyValueStore } from 'axis-webtools-util';
import { injectable } from 'inversify';
import type { Id } from '../userDataPersistence';
import { ProjectService } from './Project.service';

@injectable()
export class ProjectCustomerInfoService {
    private customerInfoStore = new KeyValueStore<string | undefined>(
        'axis.webtools.customerInfo',
        'customerInfo',
    );

    public constructor(private projectService: ProjectService) {}

    public setCustomerInfo(projectId: Id, value: string): Promise<void> {
        return this.customerInfoStore.setItem(projectId, value);
    }

    public async getCustomerInfo(projectId: Id): Promise<string> {
        const project = await this.projectService.get(projectId);
        let customerInfo = await this.customerInfoStore.getItem(project._id);
        if (customerInfo) {
            return customerInfo;
        }

        // Item could have asd1 key if migrated project
        if (project.origin && project.origin.source === 'asd1') {
            customerInfo = await this.customerInfoStore.getItem(project.origin.id);

            // Update key with asd2 project id if found
            if (customerInfo) {
                const preparedFor = JSON.parse(customerInfo);
                this.customerInfoStore.setItem(projectId, preparedFor);
                this.customerInfoStore.removeItem(project.origin.id);
                return preparedFor;
            }
        }

        // We always default to an empty string for validation simplicity
        // in the rest of the application.
        return '';
    }

    public async clearCustomerInfo(): Promise<void> {
        return this.customerInfoStore.clear();
    }
}
