import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { QuotationActionService } from '../../services';
import { EmptyReport } from '../../../reports/components';
import type { Id } from 'app/core/persistence';
import { ProjectQuotationService } from 'app/core/persistence';
import type { IMsrpProps } from 'app/modules/common';
import {
    getIsLocalProject,
    CommonActionService,
    getDistributorForRegion,
    getCountryFromCountryCode,
    getProductsWithPrices,
    getUserShouldSeeMsrp,
    getUserSignedIn,
    getCurrentProjectName,
    getCurrentProjectId,
    getCurrentProjectLocation,
    ContactDetails,
    getCurrentProjectCurrency,
    getCurrentProjectLocale,
    getCurrentProjectQuotation,
} from 'app/modules/common';
import type { IDistributors, IDistributor } from 'app/modules/msrp';
import { t } from 'app/translate';
import { QuotationEditSection } from './QuotationEditSection.container';
import {
    Border,
    Box,
    DateInput,
    Heading,
    Label,
    LinkButton,
    PrintPanel,
    Stack,
    Text,
    Textarea,
} from 'app/components';
import { QuotationSectionTotal } from '../total';
import { Logo } from 'app/modules/user';

interface IEditQuotationProps {
    projectId: Id;
    projectName: string;
    hasProducts: boolean;
    quotationId?: Id;
    header: string;
    footer: string;
    distributors: IDistributors | null;
    distributor: IDistributor | undefined;
    locale: string;
    currency: string;
    msrpProps?: IMsrpProps;
    userShouldSeeMsrp: boolean;
    isOffline: boolean;
    projectCountryName?: string;
    customerInfo: string;
    shouldShowLogo: boolean;
    validUntil?: string;
}

const mapStateToProps = (storeState: IStoreState): IEditQuotationProps => {
    const distributors = storeState.common.distributors;
    const distributor = getDistributorForRegion(storeState);
    const location = getCurrentProjectLocation(storeState);
    const projectCountry = getCountryFromCountryCode(location?.countryCode);
    const userIsSignedIn = getUserSignedIn(storeState);
    const isLocalProject = getIsLocalProject(storeState);
    const projectQuotation = getCurrentProjectQuotation(storeState);

    return {
        ...storeState.quotation,
        isOffline: !storeState.app.userOnline,
        projectName: getCurrentProjectName(storeState),
        projectId: getCurrentProjectId(storeState),
        distributors,
        distributor,
        locale: getCurrentProjectLocale(storeState),
        currency: getCurrentProjectCurrency(storeState),
        userShouldSeeMsrp: getUserShouldSeeMsrp(storeState),
        projectCountryName: projectCountry ? projectCountry.countryName : undefined,
        hasProducts: getProductsWithPrices(storeState).length > 0,
        customerInfo: storeState.common.projectCustomerInfo,
        shouldShowLogo: userIsSignedIn || isLocalProject,
        footer: projectQuotation?.footer || '',
        header: projectQuotation?.header || '',
        quotationId: projectQuotation?._id,
        validUntil: projectQuotation?.validUntilDate,
    };
};

class EditQuotationContainer extends React.Component<IEditQuotationProps> {
    private actionService: QuotationActionService;
    private commonActionService: CommonActionService;
    private projectQuotationService: ProjectQuotationService;

    constructor(props: IEditQuotationProps) {
        super(props);
        this.actionService = ServiceLocator.get(QuotationActionService);
        this.commonActionService = ServiceLocator.get(CommonActionService);
        this.projectQuotationService = ServiceLocator.get(ProjectQuotationService);
    }

    public componentDidMount() {
        if (!this.props.quotationId) {
            return;
        }

        const { distributors, distributor } = this.props;

        this.projectQuotationService.setValidUntilDate(this.props.quotationId, distributors);

        this.actionService.getLocalCurrencyPriceListAndMsrpProps(
            this.props.quotationId,
            distributor ? distributor.currency : null,
            distributors && distributors.lastUpdated ? distributors.lastUpdated : null,
        );
    }

    public render() {
        return this.props.hasProducts ? this.renderTable() : this.renderEmpty();
    }

    private renderTable() {
        if (!this.props.quotationId) {
            return null;
        }

        const validUntil = new Date(this.props.validUntil ?? '');

        return (
            <Box direction="column" alignItems="center" width="100%">
                <Stack spacing="panel" vertical>
                    <PrintPanel testId="panel_reports_quotation_edit_panel">
                        <Stack vertical spacing="none">
                            <Text color="warmGrey4" style="subheader">
                                {t.quotationHeading}
                            </Text>
                            <Text style="headline">{this.props.projectName}</Text>
                        </Stack>
                        <Box paddingY="half" justifyContent="between" width="100%">
                            <Box display="block" flex="shrinkAndGrow" paddingRight="half">
                                <Label opaque>{t.labelPreparedFor}</Label>
                                <Box maxWidth="70%">
                                    <Textarea
                                        testId="prepared_for_in_sales_quote_page"
                                        color="grey2"
                                        noBorder
                                        rows={6}
                                        value={this.props.customerInfo}
                                        onChange={this.setCustomerInfo}
                                    />
                                </Box>
                            </Box>
                            <Box flex="none" paddingTop="base">
                                {this.props.shouldShowLogo && <Logo />}
                                <Box flex="none" paddingLeft="panel">
                                    <ContactDetails />
                                </Box>
                            </Box>
                        </Box>
                        <Textarea
                            rows={6}
                            placeholder={t.quotationAddHeader}
                            color="grey2"
                            noBorder
                            value={this.props.header}
                            onChange={this.setHeader}
                        />
                        <QuotationEditSection
                            quotationId={this.props.quotationId}
                            sectionType="axis"
                            sectionHeader={t.quotationAxisProducts}
                        />
                        <QuotationEditSection
                            quotationId={this.props.quotationId}
                            sectionType="other"
                            sectionHeader={t.quotationOther}
                        />
                        <QuotationEditSection
                            quotationId={this.props.quotationId}
                            sectionType="installationServices"
                            sectionHeader={t.quotationServices}
                        />
                        <Text bold>{t.quotationQuotationValidUntil}</Text>
                        <DateInput
                            selectedDate={validUntil}
                            startYear={Math.min(new Date().getFullYear(), validUntil.getFullYear())}
                            endYear={new Date().getFullYear() + 5}
                            onChange={this.updateValidUntilDate}
                        />
                        {this.props.userShouldSeeMsrp &&
                            this.props.msrpProps &&
                            !this.dateWithinMonth(validUntil) && (
                                <Text italic color="red">
                                    {t.quotationDateValidDisclaimer}
                                </Text>
                            )}
                        {this.props.userShouldSeeMsrp && !this.props.msrpProps && (
                            <Text italic color="red">
                                {this.props.isOffline
                                    ? t.quotationMsrpGoOnline
                                    : t.quotationMsrpChangeCountry(
                                          this.props.projectCountryName ||
                                              t.quotationCurrentCountry,
                                      )}
                            </Text>
                        )}
                        <Textarea
                            rows={6}
                            placeholder={t.quotationAddFooter}
                            color="grey2"
                            noBorder
                            value={this.props.footer}
                            onChange={this.setFooter}
                        />
                        <Box justifyContent="end">
                            <Text bold>{t.quotationExcludingTaxes(this.props.currency)}</Text>
                        </Box>
                    </PrintPanel>
                    <Box justifyContent="center" width="100%" userSelect>
                        <Box width="100%" maxWidth="1300px" display="block">
                            <Border
                                noPrint
                                color="grey3"
                                radius="2px"
                                shadow="rgba(0,0,0,0.05) 0 0 6px 0px"
                            >
                                <Box color="white">
                                    <Box
                                        width="50%"
                                        direction="column"
                                        padding="panel"
                                        justifyContent="between"
                                    ></Box>
                                    <Border leftWidth={1} color="grey4" style="dashed">
                                        <Box
                                            width="50%"
                                            direction="column"
                                            padding="panel"
                                            justifyContent="between"
                                        >
                                            <Heading style="subheader">
                                                {t.quotationHeading}
                                            </Heading>
                                            <Box paddingY="base">
                                                <QuotationSectionTotal
                                                    productType="products"
                                                    editMode={true}
                                                />
                                            </Box>
                                            <Box paddingY="base">
                                                <QuotationSectionTotal
                                                    productType="services"
                                                    editMode={true}
                                                    hideIfZero
                                                />
                                            </Box>
                                            <Box justifyContent="end">
                                                <LinkButton
                                                    testId="view_sales_quote"
                                                    link={`/project/${this.props.projectId}/reports/quotation/view/`}
                                                >
                                                    {t.quotationViewSalesQuote}
                                                </LinkButton>
                                            </Box>
                                        </Box>
                                    </Border>
                                </Box>
                            </Border>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        );
    }

    private dateWithinMonth = (date: Date) => {
        const now = new Date();
        return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
    };

    private renderEmpty() {
        return (
            <EmptyReport
                projectId={this.props.projectId}
                projectName={this.props.projectName}
                text={t.quotationNoDevices}
            />
        );
    }

    private updateValidUntilDate = (value: Date) => {
        if (!this.props.quotationId) {
            return;
        }
        this.projectQuotationService.update(this.props.quotationId, {
            validUntilDate: value.toISOString(),
        });
    };

    private setCustomerInfo = (value: string) => {
        this.commonActionService.updateProjectCustomerInfo(value);
        this.commonActionService.loadCurrentCustomerInfo(this.props.projectId);
    };

    private setHeader = (header: string) => {
        if (!this.props.quotationId) {
            return;
        }
        this.projectQuotationService.update(this.props.quotationId, { header });
    };

    private setFooter = (footer: string) => {
        if (!this.props.quotationId) {
            return;
        }
        this.projectQuotationService.update(this.props.quotationId, { footer });
    };
}

export const QuotationEdit = connect(mapStateToProps)(EditQuotationContainer);
