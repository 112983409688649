import { findLastIndex } from 'lodash-es';
import type { IPiaSystemComponent } from 'app/core/pia';
import type { IDeviceRequirement } from '../common';
import { getRecorderProps } from '../common';

// estimate price difference by comparing number of supported cameras, storage and bandwidth
const byPrice = (a: IPiaSystemComponent, b: IPiaSystemComponent): number => {
    const aProps = getRecorderProps(a);
    const bProps = getRecorderProps(b);

    return (
        aProps.maxCameraCount - bProps.maxCameraCount ||
        aProps.maxRecordingStorageMegaBytes - bProps.maxRecordingStorageMegaBytes ||
        aProps.maxRecordingBandwidthBits - bProps.maxRecordingBandwidthBits
    );
};

// determine how many of a recorder type we need
export const getRequiredRecorderCount = (
    recorder: IPiaSystemComponent,
    devices: IDeviceRequirement[],
): number => {
    let props = getRecorderProps(recorder);

    // calculate how many recorders we need to cover all devices' storage requirement
    let recorderCountStorage = 1;
    for (const device of devices) {
        if (props.maxRecordingStorageMegaBytes > device.storage) {
            props.maxRecordingStorageMegaBytes -= device.storage;
        } else {
            recorderCountStorage++;
            props = getRecorderProps(recorder);
        }
    }

    // calculate how many recorders we need to cover all devices' channel requirement
    let recorderCountCamCount = 1;
    for (const device of devices) {
        if (props.maxCameraCount >= device.channelCount) {
            props.maxCameraCount -= device.channelCount;
        } else {
            recorderCountCamCount++;
            props = getRecorderProps(recorder);
        }
    }

    // calculate how many recorders we need to cover all devices' bandwidth requirement
    let recorderCountBandwidth = 1;
    for (const device of devices) {
        if (props.maxRecordingBandwidthBits > device.bandwidth) {
            props.maxRecordingBandwidthBits -= device.bandwidth;
        } else {
            recorderCountBandwidth++;
            props = getRecorderProps(recorder);
        }
    }

    // return the smallest number of required recorders that fulfill all aspects
    return Math.max(recorderCountStorage, recorderCountCamCount, recorderCountBandwidth);
};

export const preprocessRecorders = (
    recorders: IPiaSystemComponent[],
    devices: IDeviceRequirement[],
): IPiaSystemComponent[] => {
    // sort recorders by their price (estimated)
    const sortedRecorders = [...recorders].sort(byPrice).reverse();

    // for each recorder, get the number of recorders required to fulfill the requirements
    const recorderCounts = sortedRecorders.map((recorder) =>
        getRequiredRecorderCount(recorder, devices),
    );

    // find the index of the cheapest recorder for which we need an equal or lower number of instances than we need of then most expensive recorder
    const lastIndex = findLastIndex(recorderCounts, (count) => count <= recorderCounts[0]);

    // remove the expensive recorders that don't help us lowering the number of required
    // recorders
    return sortedRecorders.slice(lastIndex);
};
