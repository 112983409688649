import { t } from 'app/translate';
import type { IUspGroup } from '../../models';

export const cameraUsps = (): IUspGroup[] => [
    {
        key: 'zipStream',
        condition: true,
        text: t.advancedFiltersGROUP.zipStream,
    },
    {
        key: 'lowLightTechnology',
        condition: 'Lightfinder',
        text: t.advancedFiltersGROUP.lightFinder,
    },
    {
        key: 'imageStabilization',
        condition: true,
        text: t.advancedFiltersGROUP.imageStabilization,
    },
    {
        key: 'WDRTechnology',
        condition: ['Forensic WDR', 'Forensic Capture'],
        text: t.forensicWDR,
    },
    {
        key: 'WDRTechnology',
        condition: ['Dynamic Capture', 'WDR - Dynamic Capture'],
        text: t.advancedFiltersGROUP.dynamicCapture,
    },
    {
        key: 'IRLEDs',
        condition: true,
        text: t.advancedFiltersGROUP.builtInIR,
    },
    {
        key: 'lensChangeable',
        condition: true,
        text: t.advancedFiltersGROUP.lensChangeable,
    },
    {
        key: 'outdoorReady',
        condition: true,
        text: t.advancedFiltersGROUP.outdoorReady,
    },
    {
        key: 'PIris',
        condition: true,
        text: t.cameraSelectorUspsPIris,
    },
    {
        key: 'DCiris',
        condition: true,
        text: t.cameraSelectorUspsDcIris,
    },
    {
        key: 'twoWayAudio',
        condition: true,
        text: t.cameraSelectorUspsTwoWayAudio,
    },
    {
        key: 'audioSupport',
        condition: true,
        text: t.advancedFiltersGROUP.audioSupport,
    },
    {
        key: 'builtInMicrophone',
        condition: true,
        text: t.advancedFiltersGROUP.builtInMicrophone,
    },
    {
        key: 'builtInSpeaker',
        condition: true,
        text: t.cameraSelectorUspsBuiltInSpeaker,
    },
    {
        key: 'vandalResistant',
        condition: true,
        text: t.advancedFiltersGROUP.vandalResistant,
    },
    {
        key: 'ruggedizedEN50155',
        condition: true,
        text: t.ruggedized,
    },
    {
        key: 'alarmInputsOutputs',
        nCondition: '0/0',
        text: t.alarmInputsOutputs,
    },
];
