import type { IAction } from '../IAction';
import { ActionHandler } from './ActionHandler';
import type { ActionCallback, IActionHandler } from './reducer.types';

/**
 * `IActionHandler` for handling asynchronous/promise-based actions.
 */
export class AsyncActionHandler<TState> implements IActionHandler<TState, any> {
    private handlers: Array<ActionHandler<TState, any>> = [];

    constructor(private actionName: string) {}

    /**
     * Add a callback for handling `PENDING` (started) status for an async action.
     */
    public onPending(callback: ActionCallback<TState, void>): AsyncActionHandler<TState> {
        this.handlers.push(new ActionHandler(`${this.actionName}_PENDING`, callback));
        return this;
    }

    /**
     * Add a callback for handling `FULFILLED` (completed) status for an async action.
     */
    public onFulfilled<TFulfilledPayload>(
        callback: ActionCallback<TState, TFulfilledPayload>,
    ): AsyncActionHandler<TState> {
        this.handlers.push(new ActionHandler(`${this.actionName}_FULFILLED`, callback));
        return this;
    }

    /**
     * Add a callback for handling `REJECTED` (error) status for an async action.
     */
    public onRejected<TRejectionPayload>(
        callback: ActionCallback<TState, TRejectionPayload>,
    ): AsyncActionHandler<TState> {
        this.handlers.push(new ActionHandler(`${this.actionName}_REJECTED`, callback));
        return this;
    }

    public handle(prevState: TState, action: IAction<any>): TState {
        /**
         * Handle all actions in sequence in the same order they were added.
         * Normally we usually trigger one or no state change when calling
         * all handlers. Since we could possibly have multiple handlers for
         * the same action we have to iterate all handlers.
         */
        return this.handlers.reduce((currentState: TState, handler: ActionHandler<TState, any>) => {
            return handler.handle(currentState, action);
        }, prevState);
    }
}
