import * as React from 'react';
import { Card, Box, Icon, Text } from 'app/components';

import { Trans } from 'react-i18next';

const LINK_S30_INFO =
    'https://help.axis.com/en-us/axis-camera-station-integrator-guide#set-up-axis-s3008-recorder';

/** Shows info about S30 limitations if S30 solution is selected */
export const S30SolutionInfo: React.FC = () => {
    return (
        <Card notFullWidth paddingX="half" paddingY="half" testId="s30_info">
            <Box
                display="grid"
                spacing="half"
                alignItems="center"
                grid={{
                    gridTemplateColumns: 'min-content 1fr',
                }}
            >
                <Icon opaque icon="info" color="blue" />
                <Text color="grey8">
                    <Trans
                        i18nKey="axisS30disclaimer"
                        components={{
                            Link: (
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={LINK_S30_INFO}
                                ></a>
                            ),
                        }}
                    />
                </Text>
            </Box>
        </Card>
    );
};

S30SolutionInfo.displayName = 'S30SolutionInfo';
