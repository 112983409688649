import * as React from 'react';
import type { IToggleItem } from './ToggleItem.component';
import { Box } from '../../containers';
import { Stack } from '../../layout';
import { Icon } from '../icon';
import { Text } from '../../text';
import { Clickable, Linkable } from '../../functional';
export interface IToggleLinkItem extends IToggleItem {
    link: string;
}
export class ToggleLinkItem extends React.Component<IToggleLinkItem> {
    public render() {
        const background = this.props.active ? 'blue' : undefined;
        const color = this.props.active ? 'white' : 'grey6';
        const { icon, title, active, link } = this.props;

        return (
            <Linkable link={link}>
                <Clickable>
                    <Box
                        color={background}
                        justifyContent="center"
                        flex="evenSpace"
                        alignItems="center"
                        paddingX="base"
                        minWidth="140px"
                    >
                        <Stack spacing="half" justifyContent="center" alignItems="center">
                            {icon && <Icon color={color} {...this.props} size="sm" opaque />}
                            {title && (
                                <Text whiteSpace="nowrap" semiBold={active} color={color}>
                                    {title}
                                </Text>
                            )}
                        </Stack>
                    </Box>
                </Clickable>
            </Linkable>
        );
    }
}
