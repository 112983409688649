import * as React from 'react';
import { t } from 'app/translate';
import { Box, Spacer, Stack, StepRange, Text } from 'app/components';
import type { ProjectZipType } from 'app/core/persistence';
import { ProjectService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import {
    getCurrentProjectProjectZipSetting,
    getProfilesUseProjectSetting,
} from 'app/modules/common';
import { useSelector } from 'react-redux';
import { toaster } from 'app/toaster';
import { debounce } from 'lodash';
import { eventTracking } from 'app/core/tracking';

interface IProjectZipStreamSettingProps {
    textAlign?: 'left' | 'center' | 'right';
}

interface ISteps {
    label: string;
    title: string;
    description: string;
}

/**
 * Generate a range with marked steps with labels. Selected step is marked with a thumb.
 */
export const ProjectZipStreamSetting: React.FC<IProjectZipStreamSettingProps> = ({
    textAlign = 'left',
}) => {
    const projectZipSetting = useSelector<IStoreState, ProjectZipType>(
        getCurrentProjectProjectZipSetting,
    );

    const anyProfileUseProjectSetting = useSelector<IStoreState, boolean>(
        getProfilesUseProjectSetting,
    );

    const [step, setStep] = React.useState(projectZipSetting);
    React.useEffect(() => setStep(projectZipSetting), [projectZipSetting]);

    const [projectService] = React.useState<ProjectService>(ServiceLocator.get(ProjectService));

    const steps: ISteps[] = [
        {
            label: t.zipStepsGROUP.off,
            title: t.zipStepsGROUP.off,
            description: t.zipStepsDescriptionGROUP.off,
        },
        {
            label: '',
            title: t.zipStepsTitleGROUP.low,
            description: t.zipStepsDescriptionGROUP.low,
        },
        {
            label: t.zipStepsGROUP.medium,
            title: t.zipStepsGROUP.medium,
            description: t.zipStepsDescriptionGROUP.medium,
        },
        {
            label: '',
            title: t.zipStepsTitleGROUP.high,
            description: t.zipStepsDescriptionGROUP.high,
        },
        {
            label: t.zipStepsGROUP.max,
            title: t.zipStepsTitleGROUP.max,
            description: t.zipStepsDescriptionGROUP.max,
        },
    ];

    const debouncedOnStepChange = React.useMemo(
        () =>
            debounce((newValue) => {
                projectService.updateCurrentProject({ projectZipSetting: newValue });
                if (!anyProfileUseProjectSetting) {
                    toaster.warning(t.zipstreamGlobalWarningHeader, t.zipstreamGlobalWarning);
                }
            }, 200),
        [anyProfileUseProjectSetting, projectService],
    );

    const onStepChange = (value: number) => {
        setStep(value);
        eventTracking.logUserEvent('System Components', 'Zipstream setting', value.toString());
        debouncedOnStepChange(value);
    };

    return (
        <Stack vertical spacing="none" lineBetweenColor="grey3">
            <Box padding="half" justifyContent="center" direction="column" paddingRight="base">
                <Text color="grey6" align={textAlign}>
                    {t.zipStreamGlobal}
                </Text>
                <Spacer spacing="half"></Spacer>
                <StepRange
                    iconLeft={'movie_filter'}
                    iconRight={'compress'}
                    labels={[
                        steps[0].label,
                        steps[1].label,
                        steps[2].label,
                        steps[3].label,
                        steps[4].label,
                    ]}
                    value={step}
                    onChange={onStepChange}
                ></StepRange>
                <Box direction="column" paddingTop="base" minHeight="67px">
                    <Text color="grey8" style="semibold">
                        {steps[step].title}
                    </Text>
                    <Text color="grey7">{steps[step].description}</Text>
                </Box>
            </Box>
        </Stack>
    );
};
