import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Icon, type Icons, Text, RadioButton } from 'app/components';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { getRequiresIRIllumination } from 'app/modules/common';
import type { Id, AnalyticRangeLightCondition } from 'app/core/persistence';

const lightConditions: { id: AnalyticRangeLightCondition; icon: Icons }[] = [
    {
        id: 'ir',
        icon: 'nightlight',
    },
    {
        id: 'low_light',
        icon: 'sunny_50',
    },
    {
        id: 'medium_light',
        icon: 'sunny_75',
    },
    {
        id: 'good_light',
        icon: 'sunny',
    },
];

interface ILightConditionsSelectorProps {
    itemId: Id;
    selectedLightCondition: AnalyticRangeLightCondition;
    onSelect: (lightCondition: AnalyticRangeLightCondition) => void;
}
export const LightConditionsSelector: React.FC<ILightConditionsSelectorProps> = ({
    itemId,
    selectedLightCondition,
    onSelect,
}) => {
    const requiresIrIllumination = useSelector<IStoreState, boolean>((store) =>
        getRequiresIRIllumination(store, itemId),
    );

    return (
        <Box direction="column" paddingY="quart" paddingX="half" width="100%" alignItems="center">
            <Box alignItems="center" spacing="quart">
                <Text color="grey7" style="small">
                    {t.cameraSelectorFieldOfViewLightConditions}
                </Text>
                {requiresIrIllumination && (
                    <Icon
                        testId={`ir_warning`}
                        opaque
                        icon="warning"
                        size="xs"
                        color="yellow"
                        title={t.applicationDetectionRangeIRIlluminatorRequired}
                    />
                )}
            </Box>
            <Box width="100%">
                {lightConditions.map(({ id, icon }) => {
                    const selected = id === selectedLightCondition;
                    const color = selected ? 'grey9' : 'grey6';

                    return (
                        <Box flex="shrinkAndGrow" key={id}>
                            <RadioButton
                                fullWidth
                                isActive={selected}
                                paddingY="half"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onSelect(id);
                                }}
                            >
                                <Icon testId={id} opaque icon={icon} size="sm" color={color} />
                                {id === 'ir' && (
                                    <Text style="small" color={color}>
                                        +IR
                                    </Text>
                                )}
                            </RadioButton>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

LightConditionsSelector.displayName = 'LightConditionsSelector';
