import * as React from 'react';
import { Box } from 'app/components';
import type { IItemEntity, IPersistence, Id } from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import { getCurrentProjectItem, getDeviceVirtualChildren, getModelName } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { ProfileSelector } from '../../profileSelector';

interface IProfileCol {
    itemId: Id;
}

export const ProfileCol: React.FC<IProfileCol> = ({ itemId }) => {
    const item = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, itemId),
    );
    const model = useSelector<IStoreState, string>((state) => getModelName(state, itemId));
    const virtualProductsForItem = useSelector<IStoreState, IPersistence<IItemEntity>[]>((state) =>
        getDeviceVirtualChildren(state, itemId),
    );

    const showProfileSelector = item
        ? deviceTypeCheckers.isCamera(item) ||
          deviceTypeCheckers.isSensorUnit(item) ||
          deviceTypeCheckers.isAnalogCamera(item)
        : false;

    return (
        <Box height="100%" spacing="quart">
            {showProfileSelector &&
                item &&
                [item, ...virtualProductsForItem].map((itemWithProfile) => (
                    <ProfileSelector
                        testId={model}
                        item={itemWithProfile}
                        key={itemWithProfile._id}
                    />
                ))}
        </Box>
    );
};

ProfileCol.displayName = 'ProfileCol';
