import * as React from 'react';

export type nodeRenderer<T> = (child: React.ReactText) => T;
export type elementRenderer<T> = (child: React.ReactElement<any>) => T;
export type componentRenderer<T> = (child: React.ReactElement<any>) => T;

/**
 * Throws errors if the provided children
 * do not have keys or if the children have
 * duplicate key values.
 */
export function checkChildrenForKeys(children: React.ReactNode, componentName = 'This components') {
    const keys: string[] = React.Children.map(children, (child: any) => child.key);

    if (!keys || keys.length === 0) {
        throw new Error(`${componentName} children require the key prop.`);
    }

    const hasDuplicateKeys = !!keys
        .sort()
        .find((value, index, array) => value === array[index - 1]);

    if (hasDuplicateKeys) {
        throw new Error(
            `${componentName} children require unique key props. Current keys are ${keys}`,
        );
    }
}

export function isReactElement(node: React.ReactNode): node is React.ReactElement {
    return !!node && typeof node === 'object' && 'props' in node && 'key' in node && 'type' in node;
}

export function isReactChild(node: React.ReactNode): node is React.ReactChild {
    return isReactElement(node) || typeof node === 'string' || typeof node === 'number';
}

export function isReactComponent(node: React.ReactNode): node is React.ReactElement {
    return isReactElement(node) && typeof node.type !== 'string';
}

/**
 * Take children and render them using different
 * functions depending on the type of the child.
 *
 * If only the `renderAsComponent` function is supplied
 * it will simply return the child in case it is an
 * element or a node.
 */
export function renderReactChildren<T>(
    children: React.ReactNode,
    renderAsComponent: componentRenderer<T>,
    renderAsElement?: elementRenderer<T>,
    renderAsNode?: nodeRenderer<T>,
) {
    const renderChild = (child: React.ReactNode) => {
        if (!isReactChild(child)) {
            return null;
        }

        if (typeof child !== 'object') {
            return renderAsNode ? renderAsNode(child) : child;
        }

        if (!isReactComponent(child)) {
            return renderAsElement ? renderAsElement(child) : child;
        }

        return renderAsComponent(child);
    };

    return React.Children.map(children, renderChild);
}
