import * as React from 'react';
import { PureComponent } from 'react';
import { css, cx } from '@emotion/css';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import type { Property } from 'csstype';
import { ColorsEnum } from 'app/styles';
import type { IWithChildren } from 'app/components/models';

const labelStyle = css`
    display: block;
    margin: 0;
    opacity: 0.54;
    white-space: nowrap;
    font-weight: 600;
    line-height: 16px;
`;

const pointerStyle = css`
    cursor: pointer;
`;

const opaqueStyle = css`
    opacity: 1;
`;

const errorStyle = css`
    color: ${ColorsEnum.red};
`;

export interface ILabel extends IAutoTestable, IWithChildren {
    /**
     * Render with full opacity.
     * Defaults to 54%.
     */
    opaque?: boolean;
    /**
     * The ID of the HTML element that this label labels.
     */
    for?: string;
    /**
     * Change the text alignment
     */
    align?: Property.TextAlign;
    /**
     * Makes the label appear faded
     */
    disabled?: boolean;
    /**
     * Makes the label red
     */
    error?: boolean;
}

/**
 * Renders a `<label>` element to be used in forms/with input components.
 */
export class Label extends PureComponent<ILabel> {
    public render() {
        return (
            <label
                data-test-id={toTestIdFormat(this.props.testId)}
                htmlFor={this.props.for}
                className={cx(
                    labelStyle,
                    { [opaqueStyle]: !!this.props.opaque },
                    { [pointerStyle]: !!this.props.for },
                    { [errorStyle]: !!this.props.error },
                    this.props.align &&
                        css`
                            text-align: ${this.props.align};
                        `,
                )}
                aria-disabled={this.props.disabled ? 'true' : 'false'}
            >
                {this.props.children}
            </label>
        );
    }
}
