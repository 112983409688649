import { injectable } from 'inversify';
import type {
    Id,
    IScheduleModel,
    IBaseProfileModel,
    IItemEntity,
    IPersistence,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import {
    isVirtualCamera,
    deviceTypeCheckers,
    isCustomCamera,
    getCustomCameraProperties,
} from 'app/core/persistence';
import type {
    IPiaCamera,
    IPiaEncoder,
    IPiaMainUnit,
    IPiaDevice,
    IPiaBodyWornCamera,
} from 'app/core/pia';
import type { BandwidthStorageEstimate, IBandwidthStorageEmptyEstimateModel } from '../models';
import { CameraEstimateService } from './CameraEstimate.service';
import { EncoderEstimateService } from './EncoderEstimate.service';
import { MainUnitEstimateService } from './MainUnitEstimate.service';
import type { Frequency } from '../../models';
import { BodyWornCameraEstimateService } from './BodyWornCameraEstimate.service';
import { VirtualCameraEstimateService } from './VirtualCameraEstimate.service';

@injectable()
export class BandwidthStorageEstimateService {
    constructor(
        private cameraEstimateService: CameraEstimateService,
        private virtualCameraEstimateService: VirtualCameraEstimateService,
        private encoderEstimateService: EncoderEstimateService,
        private mainUnitEstimateService: MainUnitEstimateService,
        private bodyWornCameraEstimateService: BodyWornCameraEstimateService,
    ) {}

    public getEstimate(
        projectZipSetting: ProjectZipType,
        item: IPersistence<IItemEntity>,
        frequency: Frequency,
        getPiaItem: (id: number) => IPiaDevice | undefined,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        getMergedProfileFromDevice: (
            item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ) => IBaseProfileModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
    ): BandwidthStorageEstimate | undefined {
        if (item.productId === null && !isCustomCamera(item)) {
            throw new Error(`Item ${item.name} has no productId`);
        }
        const product: IPiaDevice | undefined = item.productId
            ? getPiaItem(item.productId)
            : undefined;

        if (!product && !isCustomCamera(item)) {
            return this.getEmptyEstimate();
        }

        if (item.properties.camera && item.productId) {
            return this.cameraEstimateService.getCameraEstimate(
                projectZipSetting,
                item,
                (product as IPiaCamera).properties,
                frequency,
                getSchedule,
                getMergedProfileFromDevice,
                projectBandwidthVersion,
            );
        } else if (deviceTypeCheckers.isVirtualProduct(item) && isVirtualCamera(product)) {
            return this.virtualCameraEstimateService.getVirtualCameraEstimate(
                projectZipSetting,
                item,
                product.properties,
                frequency,
                getSchedule,
                getMergedProfileFromDevice,
                projectBandwidthVersion,
            );
        } else if (item.properties.encoder) {
            return this.encoderEstimateService.getEncoderEstimate(
                projectZipSetting,
                item,
                product as IPiaEncoder,
                frequency,
                getSchedule,
                getMergedProfileFromDevice,
                projectBandwidthVersion,
            );
        } else if (item.properties.mainUnit) {
            return this.mainUnitEstimateService.getMainUnitEstimate(
                projectZipSetting,
                item,
                product as IPiaMainUnit,
                frequency,
                getPiaItem,
                getSchedule,
                getMergedProfileFromDevice,
                projectBandwidthVersion,
            );
        } else if (deviceTypeCheckers.isBodyWornCamera(item)) {
            return this.bodyWornCameraEstimateService.getBodyWornCameraEstimate(
                item,
                (product as IPiaBodyWornCamera).properties,
                getSchedule,
            );
        } else if (item.properties.camera?.customCameraProperties?.activated) {
            return this.cameraEstimateService.getCameraEstimate(
                projectZipSetting,
                item,
                getCustomCameraProperties(item.properties.camera?.customCameraProperties),
                frequency,
                getSchedule,
                getMergedProfileFromDevice,
                projectBandwidthVersion,
            );
        }

        return this.getEmptyEstimate();
    }

    private getEmptyEstimate(): IBandwidthStorageEmptyEstimateModel {
        return {
            type: 'empty',
            channels: 0,
            total: {
                formattedLiveViewBandwidth: '',
                formattedRecordingBandwidth: '',
                formattedStorage: '',
                liveViewBandwidth: 0,
                recordingBandwidth: 0,
                storageInMB: 0,
                bandwidthInBps: 0,
                formattedBandwidth: '',
                retentionTime: 0,
            },
            triggeredRecording: undefined,
            continuousRecording: undefined,
        };
    }
}
