interface INeedHelpConfig {
    /** Link to external help page */
    link?: string;
    /** Text for external link button */
    linkText?: string;
    /** Telephone support number */
    phoneNumber?: string;
    /** Shows office hours for support */
    supportOfficeHours?: string;
    /** Title for need help menu */
    title?: string;
}

type countryCode = number;
type languageCode = string;
type helpConfig = Record<countryCode, Record<languageCode, INeedHelpConfig>>;
const AT_TELEPHONE_NUMBER = '0800 297862';
const CH_TELEPHONE_NUMBER = '0800 559949';
const DE_TELEPHONE_NUMBER = '+49 89 3588 17 277';
const NA_TELEPHONE_NUMBER = '800-444-2947';

/** Config file for Need Help menu. Configs are grouped by country codes with language codes as sub groups.
 *  As additional combinations of countries and languages are needed it will be sufficient to add them to this file. */
export const needHelpConfig = (): helpConfig => ({
    // Austria
    13: {
        en: {
            phoneNumber: AT_TELEPHONE_NUMBER,
            supportOfficeHours: 'Office hours: 9-12; 13-17 CET.',
        },
        de: {
            phoneNumber: AT_TELEPHONE_NUMBER,
            supportOfficeHours: 'Geschäftszeiten: Mo-Fr 9-12 Uhr; 13-17 Uhr CET.',
        },
    },
    // Canada
    37: {
        en: {
            link: 'https://www.americas-comms.axis.com/l/953153/2022-08-29/3sf1t',
            phoneNumber: NA_TELEPHONE_NUMBER,
        },
        fr: {
            link: 'https://www.americas-comms.axis.com/l/953153/2022-08-29/3sf24',
            phoneNumber: NA_TELEPHONE_NUMBER,
        },
    },
    // Germany
    79: {
        en: {
            phoneNumber: DE_TELEPHONE_NUMBER,
            supportOfficeHours: 'Office hours: 9-12; 13-17 CET.',
        },
        de: {
            phoneNumber: DE_TELEPHONE_NUMBER,
            supportOfficeHours: 'Geschäftszeiten: Mo-Fr 9-12 Uhr; 13-17 Uhr CET.',
        },
    },
    // Switzerland
    209: {
        en: {
            phoneNumber: CH_TELEPHONE_NUMBER,
            supportOfficeHours: 'Office hours: 9-12; 13-17 CET.',
        },
        de: {
            phoneNumber: CH_TELEPHONE_NUMBER,
            supportOfficeHours: 'Geschäftszeiten: Mo-Fr 9-12 Uhr; 13-17 Uhr CET.',
        },
        fr: {
            phoneNumber: CH_TELEPHONE_NUMBER,
            supportOfficeHours: 'Heures d’ouverture: lundi-vendredi 9h-12h et 13h-17h CET.',
        },
    },
    // USA
    230: {
        en: {
            link: 'https://www.americas-comms.axis.com/l/953153/2022-07-11/322p1',
            phoneNumber: NA_TELEPHONE_NUMBER,
        },
    },
});
