import { injectable } from 'inversify';
import { CONSTANTS } from '../../../modules/application/constants';
import { setItemLocalStorage } from '../utils';

@injectable()
export class EulaService {
    public setAsAccepted(): void {
        setItemLocalStorage('EulaAcceptedVersion', CONSTANTS.eulaVersion.toString());
    }
}
