import { injectable } from 'inversify';
import type { IPersistence, ISpeakerItemEntity } from 'app/core/persistence';
import type { IInstallationReportSpeakerDevice } from '../../models/devices';
import type { IPiaSpeaker } from 'app/core/pia';
import { BaseDeviceService } from './BaseDevice.service';
import { InstallationDetailsService } from '../InstallationDetails.service';
import { CategoryEnum } from 'app/modules/common';

@injectable()
export class SpeakerDeviceService {
    constructor(
        private baseDeviceService: BaseDeviceService,
        private installationDetailsService: InstallationDetailsService,
    ) {}

    public async mapDevice(
        item: IPersistence<ISpeakerItemEntity>,
        piaItem: IPiaSpeaker,
    ): Promise<IInstallationReportSpeakerDevice> {
        const filter = item.properties.speaker.filter;
        const placement = filter.placement ?? null;
        const outdoor = filter.outdoor;
        const splashProof = piaItem.properties.outdoorReady || false;

        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            installationHeight: this.installationDetailsService.getInstallationHeight(
                item.properties.speaker.filter.installationHeight,
            ),
            category: CategoryEnum.Speaker,
            splashProof,
            placement,
            outdoor,
        };
    }
}
