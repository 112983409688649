import * as React from 'react';
import type { Id } from 'app/core/persistence';
import { Box, Text, Clickable } from 'app/components';
import type { IScenarioModel } from '../../models';
import type { IWearableSceneModel } from '../../scenario/WearableScenes';

interface IScenarioItem {
    scenario: IScenarioModel | IWearableSceneModel;
    onScenarioClick(id: Id): void;
}

export class ScenarioItem extends React.PureComponent<IScenarioItem> {
    public render() {
        return (
            <Clickable onClick={this.onScenarioClick}>
                <Box alignItems="center" direction="column" padding="half" maxWidth="222px">
                    <img src={this.props.scenario.image} />
                    <Text>{this.props.scenario.name}</Text>
                </Box>
            </Clickable>
        );
    }

    private onScenarioClick = () => {
        this.props.onScenarioClick(this.props.scenario.id);
    };
}
