import { createCachedSelector } from 're-reselect';
import { getBandwidthStorageEstimateForItem } from '../../bandwidth';
import { toCacheKey } from '../../cacheKey';

export const getDeviceBandwidthPerDevice = createCachedSelector(
    [getBandwidthStorageEstimateForItem],
    (bandwidthStorageEstimateForItem) => {
        if (
            (bandwidthStorageEstimateForItem &&
                bandwidthStorageEstimateForItem.type === 'camera') ||
            (bandwidthStorageEstimateForItem &&
                bandwidthStorageEstimateForItem.type === 'bodyWornCamera')
        ) {
            return bandwidthStorageEstimateForItem.perCamera;
        }

        return undefined;
    },
)(toCacheKey);
