import * as React from 'react';
import { css } from '@emotion/css';
import { Text } from '../../text/text/Text.component';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import type { IWithChildren } from 'app/components/models';

const TableStyle = css`
    width: 100%;
    border-collapse: collapse;

    td,
    th {
        padding: ${SpacingsEnum.halfCell} ${SpacingsEnum.half};
        text-align: start;
        vertical-align: top;
    }
    caption {
        text-align: start;
        padding-bottom: ${SpacingsEnum.half};
    }
    thead th {
        text-align: start;
        border-bottom: 2px solid ${ColorsEnum.yellow};
        padding-bottom: 5px;
    }
    tbody tr {
        border-bottom: 2px solid ${ColorsEnum.grey2};
    }
    tfoot tr {
        border: none !important;
    }
    tfoot td {
        padding-top: ${SpacingsEnum.base};
    }
`;

const TableAlignLastStyle = css`
    td:last-child > *,
    th:last-child > * {
        text-align: end;
    }
    td:first-child > *,
    th:first-child > * {
        text-align: start;
    }
`;

const WideFirstColumnStyle = css`
    td:first-child,
    th:first-child {
        width: 30%;
    }
`;

const OmitLastLineStyle = css`
    tbody tr:last-child {
        border-bottom: none;
    }
`;

const HeaderExtraBottomPaddingStyle = css`
    thead th {
        padding-bottom: ${SpacingsEnum.panel};
    }
`;

export interface ITableProps extends IAutoTestable, IWithChildren {
    /**
     * An array of the column headers
     */
    headers?: Array<string | number | JSX.Element>;
    /**
     * An array of arrays with the contents of each row.
     * The contents may be JSX Elements, strings, numbers or `null`.OmitLastLine
     */
    rows?: Array<Array<string | number | JSX.Element> | null>;

    /**
     * Aligns the text in the last column to the right
     */
    rightAlignLastColumn?: boolean;
    /**
     * Increases the width of the first column
     */
    wideFirstColumn?: boolean;
    /**
     * Removes the last bottom border of the table
     */
    omitLastLine?: boolean;
    /**
     * Width of each column
     */
    columnWidth?: string[];
    /**
     * Adds extra space between header row and the line below it
     */
    headerExtraBottomPadding?: boolean;
    tableRef?: React.Ref<HTMLTableElement>;
}

/**
 * This component makes it easier to create a table element.
 *
 * If the `rows` prop is not used you can use `children` to render anything inside the `<table>` element generated here.
 */
export class Table extends React.PureComponent<ITableProps> {
    public render() {
        return (
            <table
                ref={this.props.tableRef}
                data-test-id={`${toTestIdFormat(this.props.testId)}_settings_table`}
                className={[
                    TableStyle,
                    this.props.rightAlignLastColumn && TableAlignLastStyle,
                    this.props.wideFirstColumn && WideFirstColumnStyle,
                    this.props.omitLastLine && OmitLastLineStyle,
                    this.props.headerExtraBottomPadding && HeaderExtraBottomPaddingStyle,
                ].join(' ')}
            >
                {this.props.headers && this.props.headers.length > 0 && (
                    <thead>
                        <tr>
                            {this.props.headers.map((header, index) =>
                                this.props.columnWidth ? (
                                    <th
                                        key={header + String(index)}
                                        style={{ width: this.props.columnWidth[index] }}
                                    >
                                        {typeof header === 'number' ||
                                            (typeof header === 'string' ? (
                                                <Text style="semibold">{header}</Text>
                                            ) : (
                                                header
                                            ))}
                                    </th>
                                ) : (
                                    <th key={header + String(index)}>
                                        {typeof header === 'number' ||
                                            (typeof header === 'string' ? (
                                                <Text style="semibold">{header}</Text>
                                            ) : (
                                                header
                                            ))}
                                    </th>
                                ),
                            )}
                        </tr>
                    </thead>
                )}
                <tbody data-test-id={`${toTestIdFormat(this.props.testId)}_settings_tbody`}>
                    {this.props.rows &&
                        this.props.rows.map(
                            (cells, rowIndex) =>
                                cells &&
                                cells.length > 0 && (
                                    <tr
                                        data-test-id={`${toTestIdFormat(
                                            this.props.testId,
                                        )}_settings_table_row_${rowIndex}`}
                                        key={rowIndex}
                                    >
                                        {cells.map((cell, cellIndex) => {
                                            let cellContent = cell;

                                            if (
                                                typeof cell === 'string' ||
                                                typeof cell === 'number'
                                            ) {
                                                cellContent = (
                                                    <Text
                                                        testId={`${this.props.testId}_settings_row_${rowIndex}_cell_${cellIndex}`}
                                                    >
                                                        {cell}
                                                    </Text>
                                                );
                                            }

                                            return (
                                                <td
                                                    data-test-id={`${toTestIdFormat(
                                                        this.props.testId,
                                                    )}_settings_row_${rowIndex}_td_${cellIndex}`}
                                                    key={cellIndex}
                                                >
                                                    {cellContent}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ),
                        )}
                    {this.props.children}
                </tbody>
            </table>
        );
    }
}
