import { Module } from 'app/ioc';
import { CouchDBCompactCommunicator } from '../common/couchDBCompaction/services/CouchDBCompaction.communicator';
import { CouchDBCompactionService } from '../common';

@Module({
    binder: (binder) => {
        binder.bindService(CouchDBCompactCommunicator);
        binder.bindService(CouchDBCompactionService);
    },
})
export class CouchDBCompactionModule {}
