import type { Id } from 'app/core/persistence';
import { getCurrentAxisRecordingItems, getPiaItemsRecord } from 'app/modules/common';
import { createSelector } from 'reselect';
import type { IPiaSystemComponent, IPiaSystemComponentProperties } from 'app/core/pia';
import { getSelectedAccessories } from './getRecordingAccessories';

export const getFreeHddBays = createSelector(
    [getSelectedAccessories, getCurrentAxisRecordingItems, getPiaItemsRecord],
    (selectedAccessories, selectedRecorders, piaItems) => {
        return selectedRecorders.reduce(
            (freeHddBays, item) => {
                if (item.productId) {
                    const piaRecorder = piaItems[item.productId] as IPiaSystemComponent;
                    const freeBays = piaRecorder.properties.freeHddBays;
                    if (freeBays) {
                        const usedBays = selectedAccessories.reduce(
                            (storageAccessoryCount, accessory) => {
                                if (!accessory.productId) {
                                    return storageAccessoryCount;
                                }

                                return accessory.path.includes(item._id) &&
                                    (
                                        piaItems[accessory.productId]
                                            .properties as IPiaSystemComponentProperties
                                    ).maxRecordingStorageMegaBytes
                                    ? storageAccessoryCount + accessory.quantity
                                    : storageAccessoryCount;
                            },
                            0,
                        );
                        freeHddBays[item._id] = freeBays - usedBays;
                    }
                }

                return freeHddBays;
            },
            {} as Record<Id, number>,
        );
    },
);
