import { injectable } from 'inversify';
import type {
    IRecordingSettingsModel,
    IScenarioSettingsModel,
    IStorageSettingsModel,
    IScheduleModel,
} from 'app/core/persistence';
import type {
    IStorageEstimateModel,
    ILightHoursModel,
    IBandwidthCalculationResult,
} from '../../models';
import { LightHoursCalculationService } from './LightHoursCalculation.service';
import { ScheduleAggregationService } from './ScheduleAggregation.service';
import {
    getContinuousQuota,
    getIdleTime,
    getKiloBytesPerDay,
    getPercentValueAsDecimal,
    getTotalCustomBandwidthContinuous,
    getTriggeredBandwidthTotal,
    getTriggeredQuota,
} from './utils';

@injectable()
export class StorageCalculationService {
    constructor(
        private lightHoursCalculationService: LightHoursCalculationService,
        private scheduleAggregationService: ScheduleAggregationService,
    ) {}

    public getTriggeredStorageEstimate(
        scenarioSettings: IScenarioSettingsModel,
        recordingSettings: IRecordingSettingsModel,
        storageSettings: IStorageSettingsModel,
        bandwidthEstimate: IBandwidthCalculationResult,
        schedule: IScheduleModel,
        customBandwidth?: number,
    ): IStorageEstimateModel {
        const lightHours = this.getLightHours(schedule, scenarioSettings);

        const averageBandwidth =
            customBandwidth || this.getAverageBandwidthTriggered(lightHours, bandwidthEstimate);

        let goodLightBandwidth = bandwidthEstimate.goodLight;
        let lowLightBandwidth = bandwidthEstimate.lowLight;

        if (customBandwidth) {
            const triggeredQuota = getTriggeredQuota(bandwidthEstimate);

            const triggeredCustomBandwidthTotal = getTriggeredBandwidthTotal(
                customBandwidth,
                lightHours,
                triggeredQuota.goodLightQuota,
                triggeredQuota.lowLightQuota,
            );
            goodLightBandwidth = triggeredCustomBandwidthTotal * triggeredQuota.goodLightQuota;
            lowLightBandwidth = triggeredCustomBandwidthTotal * triggeredQuota.lowLightQuota;
        }

        const goodLightStorage = this.getStorageTriggered(
            lightHours.goodLightHours,
            goodLightBandwidth,
            recordingSettings.dayTriggerTime,
            storageSettings.retentionTime,
        );

        const lowLightStorage = this.getStorageTriggered(
            lightHours.lowLightHours,
            lowLightBandwidth,
            recordingSettings.nightTriggerTime,
            storageSettings.retentionTime,
        );

        return this.getResult(averageBandwidth, goodLightStorage, lowLightStorage);
    }

    public getContinuousStorageEstimate(
        scenarioSettings: IScenarioSettingsModel,
        recordingSettings: IRecordingSettingsModel,
        storageSettings: IStorageSettingsModel,
        bandwidthEstimate: IBandwidthCalculationResult,
        schedule: IScheduleModel,
        dynamicFps: boolean,
        customBandwidth?: number,
    ): IStorageEstimateModel {
        const lightHours = this.getLightHours(schedule, scenarioSettings);

        const averageBandwidth =
            customBandwidth ||
            this.getAverageBandwidthContinuous(
                lightHours,
                bandwidthEstimate,
                recordingSettings.dayTriggerTime,
                recordingSettings.nightTriggerTime,
                dynamicFps,
            );

        let goodLightBandwidth = bandwidthEstimate.goodLight;
        let goodLightIdleBandwidth = bandwidthEstimate.goodLightIdle;
        let lowLightBandwidth = bandwidthEstimate.lowLight;
        let lowLightIdleBandwidth = bandwidthEstimate.lowLightIdle;
        // If customBandwidth is used we want to divide the bandwidth in the same quotes as without custom
        if (customBandwidth) {
            const continuousQuota = getContinuousQuota(bandwidthEstimate);

            // customBandwidth is the average bandwidth. We have to find out the totalCustomBandwidth (for continuous)
            const customBandwidthTotal = getTotalCustomBandwidthContinuous(
                customBandwidth,
                lightHours,
                bandwidthEstimate,
                recordingSettings.dayTriggerTime,
                recordingSettings.nightTriggerTime,
                dynamicFps,
            );

            goodLightBandwidth = continuousQuota.goodLightQuota * customBandwidthTotal;
            goodLightIdleBandwidth = continuousQuota.goodLightIdleQuota * customBandwidthTotal;
            lowLightBandwidth = continuousQuota.lowLightQuota * customBandwidthTotal;
            lowLightIdleBandwidth = continuousQuota.lowLightIdleQuota * customBandwidthTotal;
        }

        const goodLightStorage = this.getStorageContinuous(
            lightHours.goodLightHours,
            goodLightBandwidth,
            goodLightIdleBandwidth,
            recordingSettings.dayTriggerTime,
            storageSettings.retentionTime,
            dynamicFps,
        );

        const lowLightStorage = this.getStorageContinuous(
            lightHours.lowLightHours,
            lowLightBandwidth,
            lowLightIdleBandwidth,
            recordingSettings.nightTriggerTime,
            storageSettings.retentionTime,
            dynamicFps,
        );

        return this.getResult(averageBandwidth, goodLightStorage, lowLightStorage);
    }

    private getLightHours(
        schedule: IScheduleModel,
        scenarioSettings: IScenarioSettingsModel,
    ): ILightHoursModel {
        const aggregatedSchedule = this.scheduleAggregationService.getAggregatedSchedule(schedule);
        return this.lightHoursCalculationService.getLightHours(
            aggregatedSchedule,
            scenarioSettings.lightStart,
            scenarioSettings.lightEnd,
        );
    }

    private getResult(
        averageBandwidth: number,
        goodLightStorage: number,
        lowLightStorage: number,
    ): IStorageEstimateModel {
        return {
            averageBandwidth,
            storageEstimate: goodLightStorage + lowLightStorage,
        };
    }

    private getAverageBandwidthTriggered(
        lightHours: ILightHoursModel,
        bandwidthEstimate: IBandwidthCalculationResult,
    ): number {
        if (lightHours.goodLightHours === 0 && lightHours.lowLightHours === 0) {
            return 0;
        }

        const totalHoursPerWeek = lightHours.goodLightHours + lightHours.lowLightHours;
        const goodLightBandwidth = lightHours.goodLightHours * bandwidthEstimate.goodLight;
        const lowLightBandwidth = lightHours.lowLightHours * bandwidthEstimate.lowLight;

        return (goodLightBandwidth + lowLightBandwidth) / totalHoursPerWeek;
    }

    private getStorageTriggered(
        weeklyHours: number,
        bandwidth: number,
        triggerTime: number,
        retentionTime: number,
    ): number {
        const kiloBytesPerDay = getKiloBytesPerDay(weeklyHours, bandwidth);
        return kiloBytesPerDay * getPercentValueAsDecimal(triggerTime) * retentionTime;
    }

    public getAverageBandwidthContinuous(
        lightHours: ILightHoursModel,
        bandwidthEstimate: IBandwidthCalculationResult,
        goodLightTriggerTime: number,
        lowLightTriggerTime: number,
        dynamicFps: boolean,
    ): number {
        if (lightHours.goodLightHours === 0 && lightHours.lowLightHours === 0) {
            return 0;
        }

        const totalHoursPerWeek = lightHours.goodLightHours + lightHours.lowLightHours;

        const goodLightBandwidth = this.getContinuousBandwidth(
            getPercentValueAsDecimal(goodLightTriggerTime),
            lightHours.goodLightHours,
            bandwidthEstimate.goodLight,
            dynamicFps,
        );

        const lowLightBandwidth = this.getContinuousBandwidth(
            getPercentValueAsDecimal(lowLightTriggerTime),
            lightHours.lowLightHours,
            bandwidthEstimate.lowLight,
            dynamicFps,
        );

        const goodLightIdleBandwidth = this.getContinuousBandwidth(
            getPercentValueAsDecimal(getIdleTime(goodLightTriggerTime)),
            lightHours.goodLightHours,
            bandwidthEstimate.goodLightIdle,
            dynamicFps,
        );

        const lowLightIdleBandwidth = this.getContinuousBandwidth(
            getPercentValueAsDecimal(getIdleTime(lowLightTriggerTime)),
            lightHours.lowLightHours,
            bandwidthEstimate.lowLightIdle,
            dynamicFps,
        );

        const totalActiveBandwidth = goodLightBandwidth + lowLightBandwidth;
        const totalIdleBandwidth = goodLightIdleBandwidth + lowLightIdleBandwidth;

        return (totalActiveBandwidth + totalIdleBandwidth) / totalHoursPerWeek;
    }

    private getContinuousBandwidth(
        triggerTime: number,
        hours: number,
        bandwidth: number,
        dynamicFps: boolean,
    ): number {
        // With dynamic fps the trigger time is already used
        // to get the bandwidth by the bandwidth algorithm
        if (dynamicFps) {
            return hours * bandwidth;
        }

        return triggerTime * hours * bandwidth;
    }

    private getStorageContinuous(
        weeklyHours: number,
        bandwidth: number,
        idleBandwidth: number,
        triggerTime: number,
        retentionTime: number,
        dynamicFps: boolean,
    ): number {
        const kiloBytesPerDay = getKiloBytesPerDay(weeklyHours, bandwidth);
        let storage = kiloBytesPerDay * retentionTime;

        // Trigger time is already taken into consideration when calculating dynamic fps
        if (!dynamicFps) {
            storage *= getPercentValueAsDecimal(triggerTime);
        }

        if (idleBandwidth > 0 && !dynamicFps) {
            const idleKiloBytesPerDay = getKiloBytesPerDay(weeklyHours, idleBandwidth);
            const idleTriggerTime = getPercentValueAsDecimal(getIdleTime(triggerTime));
            storage += idleKiloBytesPerDay * idleTriggerTime * retentionTime;
        }

        return storage;
    }
}
