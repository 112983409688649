import { Module } from 'app/ioc';
import {
    AccessorySelectorActionService,
    AccessorySelectorService,
    AccessoryPersistenceService,
    MountService,
    ProductQueriesService,
} from './services';
import { accessorySelectorReducer } from './AccessorySelectorReducer';

@Module({
    binder: (binder) => {
        binder.bindService(AccessorySelectorActionService);
        binder.bindService(AccessorySelectorService);
        binder.bindService(AccessoryPersistenceService);
        binder.bindService(ProductQueriesService);
        binder.bindService(MountService);
    },
    reducers: {
        accessorySelector: accessorySelectorReducer,
    },
})
export class AccessorySelectorModule {}
