import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { t } from 'app/translate';
import { getUserSignedIn, SignInReminder, TableHeaderSort } from 'app/modules/common';
import type { IUserProjectsListItem } from '../models';
import { getCurrentView, getUserProjects, getVisibleUserProjects } from '../selectors';
import { ProjectDbOrigin } from 'app/core/persistence';
import type { Id, IIdRevModel } from 'app/core/persistence';
import {
    LoadingIndicator,
    Box,
    Stack,
    Icon,
    Card,
    Positioned,
    Border,
    Button,
    Text,
    Spacer,
    IconText,
    LazyRender,
    Opacity,
} from 'app/components';
import { ProjectItem } from './ProjectItem.container';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import type { Property } from 'csstype';
import { eventTracking } from 'app/core/tracking';
import type { IProjectListSort } from '../../models';
import { isCustomPouchError } from 'app/utils';
import { ArchiveHelpButton } from './ArchiveHelpButton';
import { ProjectProgressBar } from './ProjectProgressBar';
import { ProjectLoadingTimerService, UserProjectsActionService } from '../services';

const transitionDuration = 300;

const stateToTranslation: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '100%',
    entering: '0',
    entered: '0',
    exiting: '100%',
    exited: '100%',
};

interface IUserProjectsListContainerProps {
    userProjects: IUserProjectsListItem[];
    visibleUserProjects: IUserProjectsListItem[];
    isFiltered: boolean;
    loading: boolean;
    loaded: boolean;
    progress: number;
    error: Error | null;
    duplicatedProjectId: Id | null;
    selectedProjectIds: Id[];
    sortProperty: IProjectListSort;
    currentView: ProjectDbOrigin;
    isSignedIn?: boolean;
    showMultiArchive: boolean;
    showMultiUnarchive: boolean;
}

interface IUserProjectsListContainerState {
    deleteConfirmed: boolean;
    deleteEmptyConfirmed: boolean;
}

const mapStateToProps = (storeState: IStoreState): IUserProjectsListContainerProps => {
    const userProjects = getUserProjects(storeState);
    const selectedSorting = storeState.userProjects.sortOrder;
    const selectedProjectIds = storeState.userProjects.selectedProjectIds;
    const showMultiArchive = selectedProjectIds.every(
        (projectId) => userProjects.find(({ id }) => id === projectId)?.archived === false,
    );
    const showMultiUnarchive =
        selectedProjectIds.length > 0 &&
        selectedProjectIds.every(
            (projectId) => userProjects.find(({ id }) => id === projectId)?.archived === true,
        );

    return {
        sortProperty: selectedSorting,
        userProjects,
        visibleUserProjects: getVisibleUserProjects(storeState),
        currentView: getCurrentView(storeState),
        loading: storeState.userProjects.loading,
        loaded: storeState.userProjects.loaded && !storeState.userProjects.replicating,
        progress: storeState.app.migrationState.progress,
        error: storeState.userProjects.error,
        duplicatedProjectId: storeState.userProjects.duplicatedProjectId,
        isFiltered: storeState.userProjects.userProjectsFilter !== '',
        selectedProjectIds,
        isSignedIn: getUserSignedIn(storeState),
        showMultiArchive,
        showMultiUnarchive,
    };
};

class UserProjectsListContainer extends React.Component<
    IUserProjectsListContainerProps,
    IUserProjectsListContainerState
> {
    private userProjectsActionService: UserProjectsActionService;
    private projectLoadingTimerService: ProjectLoadingTimerService;

    constructor(props: IUserProjectsListContainerProps) {
        super(props);
        this.userProjectsActionService = ServiceLocator.get(UserProjectsActionService);
        this.projectLoadingTimerService = ServiceLocator.get(ProjectLoadingTimerService);
        this.state = {
            deleteConfirmed: false,
            deleteEmptyConfirmed: false,
        };
    }

    public componentDidMount() {
        if (this.props.currentView === ProjectDbOrigin.asdUserData) {
            this.projectLoadingTimerService.startTimer();
        }
        this.setState({ deleteConfirmed: false, deleteEmptyConfirmed: false });
    }

    public componentDidUpdate(prevProps: IUserProjectsListContainerProps) {
        if (
            this.props.selectedProjectIds.length !== prevProps.selectedProjectIds.length &&
            this.props.selectedProjectIds.length < 1
        ) {
            this.setState({ deleteConfirmed: false, deleteEmptyConfirmed: false });
        }
        if (this.props.loaded) {
            this.projectLoadingTimerService.stopTimer();
        }
    }

    public render() {
        const isReplicating = this.props.progress > 0 && this.props.progress < 1;

        if (this.props.error) {
            return (
                <div>
                    {isCustomPouchError(this.props.error)
                        ? this.props.error.reason
                        : this.props.error.message}
                </div>
            );
        } else if (
            this.props.visibleUserProjects.length < 1 &&
            !this.props.loaded &&
            !isReplicating
        ) {
            return <LoadingIndicator message={t.projectWaitWhileLoadingProjects} />;
        } else {
            return (
                <>
                    <Box
                        testId="user_project_list"
                        direction="column"
                        flex="fullWidth"
                        paddingBottom="doublePanel"
                    >
                        <Positioned sendForward position="sticky" top="113px">
                            <Box direction="column" paddingY="half" color="grey1">
                                <Box direction="column" position="relative">
                                    <Box spacing="half" alignItems="center">
                                        <Box flex="none" width="45px" />
                                        <Box flex="shrinkAndGrow">
                                            <TableHeaderSort
                                                onClick={() => this.onSortingChanged('name')}
                                                direction={
                                                    this.props.sortProperty.sort === 'name'
                                                        ? this.props.sortProperty.direction
                                                        : 'none'
                                                }
                                            >
                                                <Text color="grey6">{t.name} </Text>
                                            </TableHeaderSort>
                                        </Box>
                                        <Box flex="none" width="180px">
                                            <TableHeaderSort
                                                onClick={() => this.onSortingChanged('updated')}
                                                direction={
                                                    this.props.sortProperty.sort === 'updated'
                                                        ? this.props.sortProperty.direction
                                                        : 'none'
                                                }
                                            >
                                                <Text color="grey6">{t.changed} </Text>
                                            </TableHeaderSort>
                                        </Box>
                                        <Box flex="none" width="100px">
                                            <TableHeaderSort
                                                onClick={() => this.onSortingChanged('devices')}
                                                direction={
                                                    this.props.sortProperty.sort === 'devices'
                                                        ? this.props.sortProperty.direction
                                                        : 'none'
                                                }
                                            >
                                                <Text color="grey6">{t.devices} </Text>
                                            </TableHeaderSort>
                                        </Box>
                                        <Box flex="none" width="40px">
                                            <Icon size="ms" icon="check" />
                                        </Box>
                                        <Box
                                            flex="none"
                                            width="178px"
                                            justifyContent="end"
                                            paddingRight="base"
                                        >
                                            <Opacity
                                                alpha={isReplicating ? 1 : 0}
                                                animation="opacity 200ms ease-in 1s"
                                            >
                                                <Text color="grey4" align="right">
                                                    {`${Math.round(this.props.progress * 100)} %`}
                                                </Text>
                                            </Opacity>
                                        </Box>
                                    </Box>
                                    <ProjectProgressBar progress={this.props.progress} />
                                </Box>
                            </Box>
                        </Positioned>
                        {this.props.visibleUserProjects.length === 0 && this.props.loaded && (
                            <Card color="grey2">
                                <Box padding="half">
                                    <Box spacing="half" alignItems="center">
                                        <Icon icon="list" size="lg" color="grey6" />
                                        <Text semiBold color="grey6">
                                            {t.emptyList}
                                        </Text>
                                    </Box>
                                </Box>
                            </Card>
                        )}
                        {(this.props.visibleUserProjects.length > 0 || isReplicating) && (
                            <Card
                                hideOverflow={this.props.selectedProjectIds.length > 0}
                                borderRadius="0 0 8px 8px"
                            >
                                <Box direction="column" lineBetweenColor="grey1" flex="fullWidth">
                                    {this.props.visibleUserProjects.map((p, index) => (
                                        <LazyRender
                                            key={p.id}
                                            initiallyVisible={index < 20}
                                            heightEstimate={56}
                                        >
                                            <ProjectItem project={p} index={index} />
                                        </LazyRender>
                                    ))}
                                </Box>
                            </Card>
                        )}

                        {this.props.currentView === ProjectDbOrigin.asdUserData && (
                            <SignInReminder
                                currentView={this.props.currentView}
                                trackingCategory={'User Projects'}
                            />
                        )}
                        {this.props.currentView === ProjectDbOrigin.asdLocalUserData && (
                            <Box paddingY="panel" display="block">
                                <Box padding="base" alignItems="center" color="grey2">
                                    <IconText
                                        icon="warning"
                                        spacing="cell"
                                        textProps={{
                                            large: true,
                                        }}
                                        iconProps={{
                                            opaque: true,
                                            color: 'blue',
                                        }}
                                    >
                                        {t.myLocalProjectsWarning}
                                    </IconText>
                                </Box>
                            </Box>
                        )}
                        <Spacer spacing="doublePanel" />
                        <Transition
                            in={this.props.selectedProjectIds.length > 0}
                            timeout={transitionDuration}
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state) => (
                                <Positioned
                                    bringToFront
                                    position="fixed"
                                    right="0"
                                    left="0"
                                    bottom="0"
                                    translateY={stateToTranslation[state]}
                                    transition
                                    duration={transitionDuration}
                                >
                                    <Border color="grey3" shadow="rgba(0, 0, 0, 0.15) 0px 5px 12px">
                                        <Box
                                            color="white"
                                            flex="fullWidth"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Box
                                                paddingX="doublePanel"
                                                paddingY="panel"
                                                width="100%"
                                            >
                                                <Stack flex="fullWidth" justifyContent="between">
                                                    <Box></Box>
                                                    <Stack>
                                                        <Box
                                                            direction="column"
                                                            minWidth="135px"
                                                            alignItems="stretch"
                                                        >
                                                            <Button
                                                                testId="delete_project"
                                                                primary
                                                                warning={this.state.deleteConfirmed}
                                                                onClick={this.onMultiDeleteProjects}
                                                            >
                                                                {this.state.deleteConfirmed
                                                                    ? `${t.confirmDelete} (${this.props.selectedProjectIds.length})`
                                                                    : `${t.delete} (${this.props.selectedProjectIds.length})`}
                                                            </Button>
                                                        </Box>
                                                        <Box
                                                            direction="column"
                                                            minWidth="135px"
                                                            alignItems="stretch"
                                                        >
                                                            <Button
                                                                primary
                                                                onClick={this.onMultiExportProjects}
                                                            >
                                                                {`${t.projectExport} (${this.props.selectedProjectIds.length})`}
                                                            </Button>
                                                        </Box>
                                                        {this.props.showMultiArchive && (
                                                            <Box
                                                                direction="column"
                                                                minWidth="135px"
                                                                alignItems="stretch"
                                                            >
                                                                <Button
                                                                    primary
                                                                    onClick={() =>
                                                                        this.onMultiArchiveProjects(
                                                                            true,
                                                                        )
                                                                    }
                                                                >
                                                                    {`${t.projectArchive} (${this.props.selectedProjectIds.length})`}
                                                                </Button>
                                                            </Box>
                                                        )}
                                                        {this.props.showMultiUnarchive && (
                                                            <Box
                                                                direction="column"
                                                                minWidth="135px"
                                                                alignItems="stretch"
                                                            >
                                                                <Button
                                                                    primary
                                                                    onClick={() =>
                                                                        this.onMultiArchiveProjects(
                                                                            false,
                                                                        )
                                                                    }
                                                                >
                                                                    {`${t.projectRestoreFromArchive} (${this.props.selectedProjectIds.length})`}
                                                                </Button>
                                                            </Box>
                                                        )}

                                                        {(this.props.showMultiArchive ||
                                                            this.props.showMultiUnarchive) && (
                                                            <ArchiveHelpButton
                                                                header={t.projectArchiveInfoHeader}
                                                                body={t.projectArchiveInfoBody}
                                                            />
                                                        )}
                                                    </Stack>
                                                    <Button onClick={this.onClearSelection}>
                                                        {t.cancel}
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Border>
                                </Positioned>
                            )}
                        </Transition>
                    </Box>
                </>
            );
        }
    }

    private onMultiDeleteProjects = () => {
        if (this.state.deleteConfirmed) {
            const projects: IIdRevModel[] = this.props.userProjects
                .filter((project) => this.props.selectedProjectIds.includes(project.id))
                .map((project) => {
                    return { id: project.id, rev: project.rev };
                });

            this.userProjectsActionService.deleteMultipleProjects(projects);
            this.setState({ deleteConfirmed: false });
            this.onClearSelection();
        } else {
            this.setState({ deleteConfirmed: true });
        }
    };

    private onClearSelection = () => {
        this.userProjectsActionService.clearSelection();
        this.setState({ deleteConfirmed: false, deleteEmptyConfirmed: false });
    };

    private onSortingChanged = (sortProperty: IProjectListSort['sort']) => {
        const currentSorting = this.props.sortProperty;

        if (currentSorting === undefined) {
            throw Error(`Selected sorting: ${sortProperty} not found in options`);
        }

        if (
            !currentSorting.sort.includes(sortProperty) ||
            currentSorting.direction === 'descending'
        )
            this.userProjectsActionService.setSortOrder({
                sort: sortProperty,
                direction: 'ascending',
            });
        else
            this.userProjectsActionService.setSortOrder({
                sort: sortProperty,
                direction: 'descending',
            });

        eventTracking.logUserEvent('User Projects', 'Change Sort Order', sortProperty);
    };

    private onMultiExportProjects = () => {
        // Return early if somehow no projects are selected.
        if (this.props.selectedProjectIds.length === 0) return;

        eventTracking.logUserEvent(
            'User Projects',
            'Export Multiple Projects',
            `${this.props.selectedProjectIds.length} projects exported`,
        );

        if (this.props.selectedProjectIds.length > 1) {
            this.userProjectsActionService.exportMultipleProjects(
                this.props.selectedProjectIds,
                this.props.isSignedIn === true,
            );
        } else {
            // Use regular export if only one project is selected in order to get translations referring to a single project.
            this.userProjectsActionService.exportProject(this.props.selectedProjectIds[0]);
        }
        this.onClearSelection();
    };

    private onMultiArchiveProjects = async (archive: boolean) => {
        // Return early if somehow no projects are selected.
        if (this.props.selectedProjectIds.length === 0) return;

        eventTracking.logUserEvent(
            'User Projects',
            `${archive ? 'Archive' : 'Unarchive'} Multiple Projects`,
            `${this.props.selectedProjectIds.length} projects ${
                archive ? 'archived' : 'unarchived'
            }`,
        );

        await this.userProjectsActionService.archiveMultipleProjects(
            this.props.selectedProjectIds,
            archive,
        );

        this.onClearSelection();
    };
}

export const UserProjectsList = connect(mapStateToProps)(UserProjectsListContainer);
