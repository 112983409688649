import * as React from 'react';
import { Box, DropDown, Border, DropDownMenuButton, Text, Icon } from 'app/components';
import { t } from 'app/translate';
import type { IBounds } from 'app/core/persistence';

interface IGeoMapSelector {
    namedLocations: { _id: string; name: string; bounds: IBounds }[];
    onClick(bounds?: IBounds): void;
}

export const GeoMapSelector: React.FC<IGeoMapSelector> = ({ namedLocations, onClick }) => {
    if (namedLocations.length < 1) return null;

    return (
        <DropDown
            openInPortal
            fillDropdownWidth
            fillWidth
            trigger={
                <Border width={1} radius="4px" color="grey3">
                    <Box
                        justifyContent="between"
                        paddingY="cell"
                        paddingLeft="panel"
                        paddingRight="cell"
                        color="white"
                        width="100%"
                    >
                        <Text style="heading" color="grey7">
                            {t.selectLocation}
                        </Text>
                        <Icon
                            testId="maps_drop_down_menu"
                            opaque
                            icon="keyboard_arrow_down"
                            color="grey4"
                        />
                    </Box>
                </Border>
            }
            contents={namedLocations.map((namedLocation) => (
                <DropDownMenuButton
                    key={namedLocation._id}
                    label={namedLocation.name}
                    testId={namedLocation.name}
                    onClick={() => onClick(namedLocation.bounds)}
                />
            ))}
        />
    );
};

GeoMapSelector.displayName = 'GeoMapSelector';
