import * as THREE from 'three';

export class VectorPool {
    private idx = 0;
    private vectors: THREE.Vector3[];

    constructor(size: number) {
        this.vectors = Array(size)
            .fill(null)
            .map(() => new THREE.Vector3());
    }

    public get(): THREE.Vector3 {
        return this.vectors[this.idx++ % this.vectors.length];
    }

    public reset(): void {
        this.idx = 0;
    }
}
