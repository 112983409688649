import * as React from 'react';
import { t } from 'app/translate';
import { DropDown, PillButton, DropDownMenuButton } from 'app/components';

interface IIKRatingFilterProps {
    selectedIKRating?: string;
    onClick(rating: string): void;
}

export const IKRatingFilter: React.FC<IIKRatingFilterProps> = ({ selectedIKRating, onClick }) => {
    return (
        <DropDown
            trigger={
                <PillButton
                    icon="keyboard_arrow_down"
                    selected={!!selectedIKRating}
                    text={selectedIKRating ?? t.advancedFiltersGROUP.vandalRating}
                />
            }
            contents={
                <>
                    <DropDownMenuButton
                        label="IK08"
                        selected={selectedIKRating === 'IK08'}
                        onClick={() => onClick('IK08')}
                    />
                    <DropDownMenuButton
                        label="IK10"
                        selected={selectedIKRating === 'IK10'}
                        onClick={() => onClick('IK10')}
                    />
                    <DropDownMenuButton
                        label="IK10+"
                        selected={selectedIKRating === 'IK10+'}
                        onClick={() => onClick('IK10+')}
                    />
                </>
            }
        />
    );
};

IKRatingFilter.displayName = 'IKRatingFilter';
