import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IAutoTestable, Icons } from 'app/components';
import { Tab, Box, IconText } from 'app/components';
import type { IFloorPlanEntity, Id } from 'app/core/persistence';
import { isGeoLocated } from 'app/modules/common';
import { useDrag } from '../../hooks';
import { getSelectedFloorPlanType, getShowGeoLocationTool } from '../../selectors';

interface IMapTabItemProps extends IAutoTestable {
    text?: string | React.ReactNode;
    icon: Icons;
    isActive: boolean;
    floorPlan?: IFloorPlanEntity;
    isDefault?: boolean;
    onClick?: (id?: Id) => void;
}

export const MapTabItem: React.FC<IMapTabItemProps> = ({
    text,
    icon,
    isActive,
    floorPlan,
    isDefault,
    onClick,
    ...extendedProps
}) => {
    const selectedMapType = useSelector(getSelectedFloorPlanType);
    const geoLocated = isGeoLocated(floorPlan);
    const isGeoLocationActive = useSelector(getShowGeoLocationTool);
    const dragRef = React.useRef<HTMLDivElement>(null);
    const dragImageRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        // Load the image
        const floorPlanImg = require('assets/images/floorplan-drag.svg');
        const floorPlanDragImage = new Image();
        floorPlanDragImage.src = floorPlanImg;

        // Append the image to the document body this is
        // required for the drag and drop to work in Safari.
        floorPlanDragImage.style.position = 'absolute';
        floorPlanDragImage.style.opacity = '0';
        document.body.appendChild(floorPlanDragImage);

        // Store a reference to the image for drag and drop
        dragImageRef.current = floorPlanDragImage;

        // Cleanup function to remove the image when the component unmounts
        return () => {
            if (document.body.contains(floorPlanDragImage)) {
                document.body.removeChild(floorPlanDragImage);
            }
        };
    }, []);

    /**
     * Only allow dragging a MapTabItem (floorplan) if:
     * - The selected map is the 'StreetMap'
     * - The floor plan has scale set
     * - The floor plan is not already geo located
     * - There is no active geo location tool (for another floorplan)
     * - The floor plan is not just the trigger of an drop down and contains no floorplan (overflowing tabs)
     *
     * We always create space for the drag handle (if it is a floor plan tab and not in the drop down), but is only visible if the above conditions are met.
     * showDragHandleDisabled means that the drag handle is not visible to the user.
     */
    const hasDragHandle = floorPlan !== undefined;
    const showDragHandleDisabled =
        geoLocated ||
        selectedMapType === 'FloorPlan' ||
        isGeoLocationActive ||
        !floorPlan?.image?.bounds;

    useDrag({
        effect: 'link',
        ref: dragRef,
        imageRef: dragImageRef,
        imageOffsetX: 25,
        imageOffsetY: 25,
        data: { id: floorPlan?._id ?? '', type: 'floorPlan' },
        isDragDisabled: showDragHandleDisabled,
    });

    return (
        <Tab
            innerRef={dragRef}
            testId={extendedProps.testId}
            key={floorPlan?._id ?? `${text}_${icon}`}
            isActive={isActive}
            paddingX="base"
            hasDragHandle={hasDragHandle}
            showDragHandleDisabled={showDragHandleDisabled}
            onClick={() => onClick && onClick(floorPlan?._id)}
        >
            <Box>
                <IconText
                    testId={typeof text === 'string' ? text : undefined}
                    color={isActive ? 'grey9' : 'grey7'}
                    iconProps={{
                        size: 'ms',
                        color: isDefault && isActive ? 'green' : undefined,
                    }}
                    icon={icon}
                    spacing="half"
                    textProps={{ style: 'semibold', whiteSpace: 'nowrap' }}
                >
                    {text}
                </IconText>
            </Box>
        </Tab>
    );
};

MapTabItem.displayName = 'MapTabItem';
