import type { IPartnerConfigAllowlist } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';

export const createProductAllowlistFilter =
    (allowlist: IPartnerConfigAllowlist | null | undefined, useAllowlist: boolean) =>
    (piaId: PiaId) => {
        if (!allowlist || !useAllowlist) {
            return true;
        }

        const result =
            allowlist &&
            (allowlist.recommendedProducts.includes(piaId) ||
                allowlist.otherProducts.includes(piaId));
        return result;
    };
