import * as React from 'react';
import { Text } from '../../text';
import type { Colors } from 'app/styles';
import { Stack } from '../../layout';
import type { Icons, IIconProps } from '../../ui/icon';
import { Icon } from '../../ui/icon';
import type { IDropDownProps } from '../../ui/dropDown';
import { DropDown, getNodeLabels } from '../../ui/dropDown';
import { isReactElement } from '../../services';
import type { IAutoTestable } from '../../ui-test';
import type { IWithChildren } from 'app/components/models';

export interface IDropDownMenuProps extends IAutoTestable, IWithChildren {
    /**
     * Display the trigger as an icon
     */
    icon?: Icons;
    /**
     * Display the down arrow to the right of the trigger.
     * The arrow will display by default when a label is provided.
     */
    arrow?: boolean;
    /**
     * Display the trigger as a text with an arrow
     */
    label?: string;
    /**
     * Display the selected child as the trigger.
     * The child needs to have a `selected` prop set.
     */
    displaySelected?: boolean;
    /**
     * Allow the menu to stay open after clicking
     * on a drop down menu item. Otherwise it will close on click.
     */
    stayOpen?: boolean;
    /**
     * Set the color of the label and arrow
     */
    color?: Colors;
    /**
     * Set properties for the provided icon.
     */
    iconProps?: Omit<IIconProps, 'icon'>;
    /**
     * Disable the drop down menu
     */
    disabled?: boolean;
    /**
     * Remove padding and flex alignment from the
     * trigger. Useful for custom layouts.
     */
    showOnlyTriggerContent?: boolean;
    /**
     * Override the alignItems props on the trigger element.
     */
    alignItems?: IDropDownProps['alignItems'];
    /**
     * Show a border around the trigger
     */
    border?: IDropDownProps['border'];
    /**
     * Background color of the trigger
     */
    background?: IDropDownProps['background'];
    /**
     * Set to true if drop down should resize
     * to the currently selected option when
     * using `displaySelected`. Otherwise the
     * drop down width is decided by the longest
     * label among its children (like a select box)
     */
    useSelectedWidth?: boolean;
    /**
     * Sets the item in the dropdown menu to be focused
     */
    focusedItemIndex?: number;
    /**
     * Override the maximum height (in px) of the drop down.
     * Defaults to 300px.
     */
    maxHeight?: number;
    /**
     * Sets the dropdownMenu to full width and aligns the arrow to center
     */
    fullWidth?: boolean;
    /**
     * Set this to open the drop down in a portal.
     * This allows it to extend beyond overflow borders,
     * however it will not scroll or move with the trigger.
     */
    openInPortal?: boolean;
}

/**
 * A flexible drop down menu that renders
 * the trigger based on the provided props.
 */
export class DropDownMenu extends React.Component<IDropDownMenuProps> {
    public render() {
        const childArray = React.Children.toArray(this.props.children);
        const actualNumberOfChildren = childArray.length;
        const selectedChild = childArray.find((child) => this.getSelectedChild(child));
        return (
            actualNumberOfChildren > 0 && (
                <DropDown
                    testId={this.props.testId}
                    testIdChild={this.props.testIdChild}
                    border={this.props.border}
                    background={this.props.background}
                    disabled={this.props.disabled}
                    alignItems={this.props.alignItems}
                    trigger={this.getTrigger()}
                    contents={this.props.children}
                    stayOpen={this.props.stayOpen}
                    maxHeight={this.props.maxHeight}
                    focusedItemIndex={
                        selectedChild !== undefined ? childArray.indexOf(selectedChild) : undefined
                    }
                    openInPortal={this.props.openInPortal}
                />
            )
        );
    }

    private getSelectedChild = (child: React.ReactNode): boolean => {
        return (
            isReactElement(child) &&
            child.props &&
            (child.props.selected ||
                React.Children.toArray(child.props.children).some(this.getSelectedChild))
        );
    };

    private getTrigger = () =>
        this.props.displaySelected
            ? this.renderSelectedItem()
            : this.props.label
              ? this.renderLabel()
              : this.props.icon
                ? this.renderIcon()
                : this.renderMeatballs();

    private renderLabel = () => (
        <Stack spacing="quart">
            {this.props.icon && <Icon {...this.props.iconProps} icon={this.props.icon} />}
            <Text semiBold color={this.props.color || 'blue'}>
                {this.props.label}
            </Text>
            {this.props.arrow !== false &&
                this.renderArrow({ color: this.props.color || 'blue', opaque: true })}
        </Stack>
    );

    private renderIcon = () => (
        <Stack spacing="quart">
            {this.props.icon && <Icon {...this.props.iconProps} icon={this.props.icon} />}
            {this.props.arrow === true && this.renderArrow()}
        </Stack>
    );

    private renderSelectedItem = () => {
        return (
            <Stack
                spacing="quart"
                width={this.props.fullWidth ? '100%' : undefined}
                justifyContent={this.props.fullWidth ? 'between' : undefined}
                alignItems={this.props.alignItems}
            >
                {React.Children.map(this.props.children, this.renderSelectedChild)}
                {this.props.arrow === true && this.renderArrow()}
            </Stack>
        );
    };

    private renderMeatballs = () => <Icon icon="more_vert" />;

    private renderArrow = (props?: Omit<IIconProps, 'icon'>) => (
        <Icon {...props} icon="arrow_down_special" size="sm" />
    );

    private renderSelectedChild = (child: React.ReactNode): React.ReactNode =>
        isReactElement(child) && child.props.selected
            ? React.cloneElement(child, {
                  onlyContent: this.props.showOnlyTriggerContent,
                  width: this.props.useSelectedWidth
                      ? undefined
                      : this.getLongestChildLabel(this.props.children) * 7.5,
              })
            : isReactElement(child) && child.props.children
              ? React.Children.map(child.props.children, this.renderSelectedChild)
              : null;

    private getLongestChildLabel = (children: React.ReactNode): number =>
        Math.max(...getNodeLabels(children).map((label) => label.length));
}
