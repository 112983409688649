import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Currency, Text } from 'app/components';
import type { Price } from '../models';
import { getCurrentProjectLocale } from '../../project';
import { t } from 'app/translate';

export interface ILocaleCurrencyProps {
    /**
     * The msrp returned in the currency of the price list.
     * This is undefined if a MSRP can't be found for the item.
     */
    msrpInPriceListCurrency?: Price;
    /**
     * The msrp returned in the currency of the project.
     * This is undefined if AXIS can't convert to the correct currency or
     * if the price list MSRP is already in the project currency.
     */
    msrpInProjectCurrency?: Price;

    /**
     * Will show component even if no price exists.
     */
    alwaysShow?: boolean;
    /**
     * This is a render prop as explained on React's official documentation here: https://reactjs.org/docs/render-props.html
     * If a function is provided here, it will return a render with a (dynamic) JSX element as its argument,
     * typically wrapped in other JSX as declared in the function.
     */
    renderFn?(msrp: JSX.Element): JSX.Element;
}

interface ILocaleCurrencyContainerProps extends ILocaleCurrencyProps {
    /**
     * The locale by which to format the prices.
     */
    locale?: string;
}

function mapStateToProps(
    storeState: IStoreState,
    ownProps: ILocaleCurrencyProps,
): ILocaleCurrencyContainerProps {
    const locale = getCurrentProjectLocale(storeState);

    // Return nothing if there's no locale
    if (!locale) {
        return {};
    }

    return {
        locale,
        ...ownProps,
    };
}

const LocaleCurrencyComponent: React.FunctionComponent<ILocaleCurrencyContainerProps> = ({
    locale,
    msrpInPriceListCurrency,
    msrpInProjectCurrency,
    alwaysShow,
    renderFn,
}) => {
    if (!locale) {
        return null;
    }

    if (
        msrpInPriceListCurrency &&
        msrpInProjectCurrency &&
        !Number.isNaN(msrpInProjectCurrency.value)
    ) {
        const component = (
            <Text>
                {msrpInPriceListCurrency.scaleQuantity > 1 && (
                    <Text testId="price_text_3" inline style="caption" color="grey5">
                        {`${msrpInPriceListCurrency.scaleQuantity}${t.abbreviationsGROUP.pieces} `}
                    </Text>
                )}
                <Text testId="price_text_1" inline style="semibold" color="green">
                    <Currency
                        showCurrency
                        currency={msrpInProjectCurrency.currency}
                        value={msrpInProjectCurrency.value}
                        locale={locale}
                    />
                </Text>
                <Text testId="price_text_2" inline style="caption" color="grey5">
                    &nbsp;(
                    <Currency
                        showCurrency
                        currency={msrpInPriceListCurrency.currency}
                        value={msrpInPriceListCurrency.value}
                        locale={locale}
                    />
                    )
                </Text>
            </Text>
        );
        return renderFn ? renderFn(component) : component;
    }

    if (msrpInPriceListCurrency && !Number.isNaN(msrpInPriceListCurrency.value)) {
        const component = (
            <Text>
                {msrpInPriceListCurrency.scaleQuantity > 1 && (
                    <Text testId="price_text_3" inline style="caption" color="grey5">
                        {`${msrpInPriceListCurrency.scaleQuantity}${t.abbreviationsGROUP.pieces} `}
                    </Text>
                )}
                <Text inline style="semibold" color="green">
                    <Currency
                        showCurrency
                        currency={msrpInPriceListCurrency.currency}
                        value={msrpInPriceListCurrency.value}
                        locale={locale}
                    />
                </Text>
            </Text>
        );
        return renderFn ? renderFn(component) : component;
    }

    const getEmptyComponent = () => {
        const emptyComponent = (
            <Text inline style="semibold" color="green">
                <Currency currency="" value={null} locale={locale} />
            </Text>
        );

        const renderedEmptyComponent = renderFn ? renderFn(emptyComponent) : emptyComponent;
        return renderedEmptyComponent;
    };

    return alwaysShow ? getEmptyComponent() : null;
};

/**
 * A component that displays a MSRP in the correct locale.
 * Will display the MSRP in the preferred currency in green followed
 * by the price list value in parenthesis, otherwise only the price list
 * value is displayed in green.
 */
export const LocaleCurrency = connect(mapStateToProps)(LocaleCurrencyComponent);

LocaleCurrency.displayName = 'LocaleCurrency';
