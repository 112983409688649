import * as React from 'react';
import type { IDropDownMenuItemProps } from './DropDownMenuItem.component';
import { DropDownMenuItem } from './DropDownMenuItem.component';
import { Clickable } from '../../functional';
import type { IAutoTestable } from '../../ui-test';
import { Stack } from '../../layout/stack/Stack.component';
import { Text } from '../../text/text/Text.component';
import { Icon } from '../icon/Icon.component';

type NavigationDirection = 'back' | 'forward';

export interface IDropDownMenuNavigationButtonProps extends IDropDownMenuItemProps, IAutoTestable {
    /**
     * The text label of the item
     */
    label?: string;
    /**
     * Applies styling to make this item look like it navigates back or forward.
     * If not set, you can use your own styling of the children.
     */
    direction?: NavigationDirection;
    /**
     * The callback to do the menu navigation.
     */
    onNavigate(): void;
}

/**
 * A drop down menu item which when clicked will navigate in the drop down.
 * Clicking this item will not automatically close the drop down.
 */
export const DropDownMenuNavigationButton: React.FC<IDropDownMenuNavigationButtonProps> = ({
    label,
    direction,
    onNavigate,
    children,
    ...props
}) => {
    return (
        <Clickable stopPropagation onClick={onNavigate}>
            <DropDownMenuItem {...props}>
                {label && direction ? (
                    <Stack
                        justifyContent={direction === 'forward' ? 'between' : 'start'}
                        alignItems="center"
                    >
                        {direction === 'back' && <Icon color="grey5" icon="keyboard_arrow_left" />}
                        <Text>{label}</Text>
                        {direction === 'forward' && (
                            <Icon color="grey5" icon="keyboard_arrow_right" />
                        )}
                    </Stack>
                ) : (
                    label ?? children
                )}
            </DropDownMenuItem>
        </Clickable>
    );
};

DropDownMenuNavigationButton.displayName = 'DropDownMenuNavigationButton';
