import type { IScheduleModel } from 'app/core/persistence';

// return the always-schedule
export const getAlwaysSchedule = (schedules: IScheduleModel[]): IScheduleModel => {
    // Since we for now only have one systemDefined schedule which is 'Always' the
    // lookup will be to take the first systemDefined schedule we find
    const systemDefinedSchedules = schedules.filter((schedule) => schedule.systemDefined);

    if (systemDefinedSchedules.length !== 1) {
        throw new Error(`Expected 1 systemDefined schedule but got:
        ${systemDefinedSchedules.length}`);
    }

    const alwaysSchedule = systemDefinedSchedules[0];

    if (!alwaysSchedule) {
        throw new Error('The system defined always schedule is missing');
    }

    return alwaysSchedule;
};
