import { injectable } from 'inversify';
import type { Id, IScheduleModel, IProfileEntity, IPersistence } from 'app/core/persistence';
import { ProfileService, ScheduleModelService, NameGenerationService } from 'app/core/persistence';
import { t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import { ProjectDefaultsService } from './ProjectDefaults.service';
import { ScenarioService } from './Scenario.service';

@injectable()
export class ProjectProfileService {
    constructor(
        private coreProfileService: ProfileService,
        private scenarioService: ScenarioService,
        private projectDefaultsService: ProjectDefaultsService,
        private scheduleModelService: ScheduleModelService,
        private nameGenerationService: NameGenerationService,
    ) {}

    public async addProjectProfile(projectId: Id): Promise<IPersistence<IProfileEntity>> {
        const scenario = this.scenarioService.getScenario('retail');

        if (!scenario) {
            throw new Error('Default scenario "retail" is missing');
        }
        const alwaysSchedule = await this.getAlwaysSchedule(projectId);
        const defaultProfile = this.projectDefaultsService.getDefaultProfileSettings(
            t.profilesNewProfileName,
            alwaysSchedule,
            'retail',
        );
        const profileNames = (await this.coreProfileService.getProjectProfiles(projectId)).map(
            ({ name }) => name,
        );
        defaultProfile.name = this.nameGenerationService.getName(
            defaultProfile.name,
            profileNames,
            AppConstants.profileNameMaxLength,
        );
        const newProfile = await this.coreProfileService.addProjectProfile(
            projectId,
            defaultProfile,
        );
        return newProfile;
    }

    public async getAlwaysSchedule(projectId: Id): Promise<IScheduleModel> {
        const schedules = await this.scheduleModelService.getProjectSchedules(projectId);

        // Since we for now only have one systemDefined schedule which is 'Always' the
        // lookup will be to take the first systemDefined schedule we find
        const systemDefinedSchedules = schedules.filter((schedule) => schedule.systemDefined);

        if (systemDefinedSchedules.length !== 1) {
            throw new Error(`Expected 1 systemDefined schedule but got:
            ${systemDefinedSchedules.length}`);
        }

        const alwaysSchedule = systemDefinedSchedules[0];

        if (!alwaysSchedule) {
            throw new Error('The system defined always schedule is missing');
        }

        return alwaysSchedule;
    }
}
