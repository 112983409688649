import type { FpsMode } from 'app/core/persistence';
import { GopMode, ProjectZipType } from 'app/core/persistence';
import { zipStrengths } from '../profile/models/ZipStrengths';

/**
 * Return the ZipStrength for the selected global setting
 * @param step The global zip stream setting (i.e currentProject.project.projectZipSetting)
 * @returns the zipStrength for the corresponding selected global Zip stream setting
 */
export function getProjectZipStrength(step: ProjectZipType): number {
    switch (step) {
        case ProjectZipType.off:
            return zipStrengths.off;
        case ProjectZipType.low:
            return zipStrengths.low;
        case ProjectZipType.medium:
            return zipStrengths.medium;
        case ProjectZipType.high:
            return zipStrengths.high;
        case ProjectZipType.max:
            return zipStrengths.extreme;
    }
}

/**
 * Return the Dynamic GOP for the selected global setting
 * @param step The global zip stream setting (i.e currentProject.project.projectZipSetting)
 * @returns  GopMode indicating if dynamic gop should be used for the global setting, or fixed
 */
export function getProjectZipDynamicGop(step: ProjectZipType): GopMode {
    switch (step) {
        case ProjectZipType.off:
            return GopMode.Fixed;
        case ProjectZipType.low:
            return GopMode.Fixed;
        case ProjectZipType.medium:
            return GopMode.Dynamic;
        case ProjectZipType.high:
            return GopMode.Dynamic;
        case ProjectZipType.max:
            return GopMode.Dynamic;
    }
    return GopMode.Fixed;
}

/**
 * Return the Dynamic Fps for the selected global setting
 * @param step The global zip stream setting (i.e currentProject.project.projectZipSetting)
 * @returns  FpsMode indicating if fixed or dynamic should be used for the global setting
 */
export function getProjectZipDynamicFps(step: ProjectZipType): FpsMode {
    switch (step) {
        case ProjectZipType.off:
            return 'fixed';
        case ProjectZipType.low:
            return 'fixed';
        case ProjectZipType.medium:
            return 'fixed';
        case ProjectZipType.high:
            return 'fixed';
        case ProjectZipType.max:
            return 'dynamic';
    }
}

/**
 * Return the average bitrate mode for the selected global setting
 * @param step The global zip stream setting (i.e currentProject.project.projectZipSetting)
 * @returns  bitrate mode indicating if it should be on or off for the global setting
 */
export function getGlobalUseAverageBitrate(step: ProjectZipType): boolean {
    switch (step) {
        case ProjectZipType.off:
            return false;
        case ProjectZipType.low:
            return false;
        case ProjectZipType.medium:
            return true;
        case ProjectZipType.high:
            return true;
        case ProjectZipType.max:
            return true;
    }
}
