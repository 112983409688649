import type * as React from 'react';
import * as L from 'leaflet';
import { useEffect, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/css';
import { useService } from 'app/ioc';
import { ColorsEnum } from 'app/styles';
import type { ILatLng } from 'app/core/persistence';
import { useMapContext } from '../../context';
import { LeafletItemFactory } from '../../../services';

const controlPointStyle = (round: boolean) => css`
    background-color: ${ColorsEnum.white};
    outline: 2px solid ${ColorsEnum.blue};
    border-radius: ${round ? '100%' : '0'};
    opacity: 0.8;
`;

const invisibleStyle = css`
    opacity: 0;
`;

interface IControlPointProps {
    latLng: ILatLng;
    round?: boolean;
    onDrag: (e: L.LeafletEvent) => void;
}

export const ControlPoint: React.FC<IControlPointProps> = ({
    latLng: { lat, lng },
    round = false,
    onDrag,
}) => {
    const factory = useService(LeafletItemFactory);
    const { leafletMap } = useMapContext();

    // the visible marker
    const [marker] = useState(
        factory.createStaticItem(
            undefined,
            new L.DivIcon({
                html: '<div/>',
                className: controlPointStyle(round),
                iconSize: [20, 20],
            }),
        ),
    );

    // a transparent marker on top of the visible one,
    // used for drag event listeners
    const [dragMarker] = useState(
        factory.createInteractiveItem(
            undefined,
            new L.DivIcon({
                html: `<div>`,
                className: invisibleStyle,
                iconSize: [20, 20],
            }),
        ),
    );

    // register drag event listener
    useEffect(() => {
        dragMarker.on('drag', onDrag);

        return () => {
            dragMarker.off('drag', onDrag);
        };
    }, [onDrag, dragMarker]);

    // add markers to map
    useEffect(() => {
        marker.addTo(leafletMap.map);
        dragMarker.addTo(leafletMap.map);

        return () => {
            marker.remove();
            dragMarker.remove();
        };
    }, [leafletMap.map, marker, dragMarker]);

    // update markers positions
    useLayoutEffect(() => {
        marker.setLatLng([lat, lng]);
        dragMarker.setLatLng([lat, lng]);
    }, [lat, lng, marker, dragMarker]);

    return null;
};

ControlPoint.displayName = 'ControlPoint';
