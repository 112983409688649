import { DEVICE_MARKER_ZOOM_BREAKPOINT } from './../mapConstants';
import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';

export const getZoomLevel = createSelector([getMapsState], (state) => {
    return state.zoomLevel;
});

export const getUseBigImages = createSelector(
    [getZoomLevel],
    (zoomLevel) => zoomLevel >= DEVICE_MARKER_ZOOM_BREAKPOINT,
);
