import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Box, Stack, Text, IconButton, Card, Icon } from 'app/components';
import { t } from 'app/translate';
import { getSortedScheduleIds } from './selectors';
import type { Id } from 'app/core/persistence';
import { ScheduleService } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { ScheduleListItem } from './ScheduleListItem.container';
import { SchedulesActionService } from './services/SchedulesActionService.service';

interface ISchedulesListContainerOwnProps {
    onEditSchedule(scheduleId: Id): void;
}

interface ISchedulesListContainerProps extends ISchedulesListContainerOwnProps {
    scheduleIds: Id[];
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ISchedulesListContainerOwnProps,
): ISchedulesListContainerProps => {
    return {
        scheduleIds: getSortedScheduleIds(storeState),
        ...ownProps,
    };
};

class SchedulesListContainer extends React.Component<ISchedulesListContainerProps> {
    private scheduleService: ScheduleService;
    private actions: SchedulesActionService;

    constructor(props: ISchedulesListContainerProps) {
        super(props);
        this.scheduleService = ServiceLocator.get(ScheduleService);
        this.actions = ServiceLocator.get(SchedulesActionService);
    }
    public render() {
        return (
            <Box direction="column" width="100%">
                <Box padding="half" paddingRight="base" display="block">
                    <Stack justifyContent="between" alignItems="center">
                        <Text color="grey6" semiBold>
                            {t.schedules}
                        </Text>
                        <IconButton
                            icon="add_circle"
                            text={t.schedulesAddSchedule}
                            onClick={() => this.addSchedule()}
                        />
                    </Stack>
                </Box>
                {this.props.scheduleIds.length < 1 && (
                    <Card color="grey2">
                        <Box
                            padding="half"
                            minHeight="75px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Stack spacing="half">
                                <Icon icon="list" size="lg" color="grey6" />
                                <Text semiBold color="grey6">
                                    {t.emptyList}
                                </Text>
                            </Stack>
                        </Box>
                    </Card>
                )}
                {this.props.scheduleIds.length >= 1 && (
                    <Card>
                        <Stack vertical spacing="none" lineBetweenColor="grey3">
                            {this.props.scheduleIds.map((scheduleId) => (
                                <ScheduleListItem
                                    key={scheduleId}
                                    scheduleId={scheduleId}
                                    onEditSchedule={(schedule) => {
                                        this.actions.editSchedule(schedule);
                                        this.props.onEditSchedule(schedule);
                                    }}
                                />
                            ))}
                        </Stack>
                    </Card>
                )}
            </Box>
        );
    }
    private addSchedule = async () => {
        const newSchedule = await this.scheduleService.addSchedule();
        const newScheduleId = newSchedule._id;
        this.actions.editSchedule(newScheduleId);
        this.props.onEditSchedule(newScheduleId);
    };
}
export const SchedulesList = connect(mapStateToProps)(SchedulesListContainer);
