import { injectable } from 'inversify';
import type {
    Id,
    IScheduleModel,
    IBaseProfileModel,
    IItemEntity,
    IPersistence,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import { Resolution, CurrentProjectService } from 'app/core/persistence';
import { ProfileBandwidthStorageEstimateService } from './bandwidth/ProfileBandwidthStorageEstimate.service';
import type {
    IPiaMainUnit,
    IPiaSensorUnit,
    IPiaDevice,
    FpsSpecificResolutions,
} from 'app/core/pia';
import { PiaItemSensorUnitCategory } from 'app/core/pia';
import { format, isDefined } from 'axis-webtools-util';
import type {
    IBandwidthStorageMainUnitEstimateModel,
    IBandwidthStorageAttachedCameraEstimateModel,
    IBandwidthStorageRequirementsModel,
} from '../models';
import { ScenarioService } from '../../profile/services';
import type { Frequency } from '../../models';

@injectable()
export class MainUnitEstimateService {
    constructor(
        private scenarioService: ScenarioService,
        private profileBandwidthStorageEstimateService: ProfileBandwidthStorageEstimateService,
        private currentProjectService: CurrentProjectService,
    ) {}

    public getMainUnitEstimate(
        projectZipSetting: ProjectZipType,
        mainUnit: IPersistence<IItemEntity>,
        product: IPiaMainUnit,
        frequency: Frequency,
        getPiaItem: (id: number) => IPiaDevice | undefined,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        getMergedProfileFromDevice: (
            item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ) => IBaseProfileModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
    ): IBandwidthStorageMainUnitEstimateModel {
        if (!mainUnit.properties.mainUnit) {
            throw Error(`Item is not a main unit`);
        }

        const sensorUnits = this.currentProjectService.getDeviceChildren(
            mainUnit._id,
            'sensorUnit',
        );

        const attachedCamerasEstimates = this.getSensorUnitsEstimate(
            projectZipSetting,
            sensorUnits,
            product,
            frequency,
            mainUnit.quantity,
            getPiaItem,
            getSchedule,
            getMergedProfileFromDevice,
            projectBandwidthVersion,
        );

        return {
            type: 'mainUnit',
            perAttachedCamera: attachedCamerasEstimates,
            perMainUnit: this.getPerMainUnitEstimate(attachedCamerasEstimates),
            total: this.getMainUnitTotalEstimate(attachedCamerasEstimates),
            channels: this.getSensorUnitCount(sensorUnits),
            continuousRecording: undefined,
            triggeredRecording: undefined,
        };
    }

    public static mergeFSeriesProperties(mainUnit: IPiaMainUnit, sensorUnit: IPiaSensorUnit) {
        const supportedMergedFrameRates = this.getMergedSupportedFrameRates(mainUnit, sensorUnit);
        return {
            ...mainUnit.properties,
            lensCalcFPS:
                supportedMergedFrameRates.length > 0 ? supportedMergedFrameRates : undefined,
            WDRTechnology: sensorUnit.properties.WideDynamicRange
                ? mainUnit.properties.WDRTechnology
                : undefined, // use main unit value if sensorUnit has WideDynamicRange
            WideDynamicRange:
                sensorUnit.properties.WideDynamicRange && mainUnit.properties.WideDynamicRange, // require both
            maxFPS50Hz: Math.min(sensorUnit.properties.maxFPS50Hz, mainUnit.properties.maxFPS50Hz),
            maxFPS60Hz: Math.min(sensorUnit.properties.maxFPS60Hz, mainUnit.properties.maxFPS60Hz),
            maxVideoResolutionHorizontal: Math.min(
                sensorUnit.properties.maxVideoResolutionHorizontal,
                mainUnit.properties.maxVideoResolutionHorizontal,
            ),
            maxVideoResolutionVertical: Math.min(
                sensorUnit.properties.maxVideoResolutionVertical,
                mainUnit.properties.maxVideoResolutionVertical,
            ),
            maxHorizontalFOV: sensorUnit.properties.maxHorizontalFOV,
            maxVerticalFOV: sensorUnit.properties.maxVerticalFOV,
            minHorizontalFOV: sensorUnit.properties.minHorizontalFOV,
            minVerticalFOV: sensorUnit.properties.minVerticalFOV,
        };
    }

    private static getMergedSupportedFrameRates(
        mainUnit: IPiaMainUnit,
        sensorUnit: IPiaSensorUnit,
    ): FpsSpecificResolutions {
        if (!mainUnit.properties.lensCalcFPS) {
            return [];
        }

        const sortedMainUnitsFrameRates = mainUnit.properties.lensCalcFPS.sort(
            (frameRateA, frameRateB) => {
                const resolutionA = new Resolution(frameRateA.resolution).getPixels();
                const resolutionB = new Resolution(frameRateB.resolution).getPixels();
                return resolutionB - resolutionA;
            },
        );
        // keep resolutions that are lower than the maxVideoResolution for sensor Unit
        const maxSensorUnitResolution = new Resolution(
            sensorUnit.properties.maxVideoResolutionHorizontal,
            sensorUnit.properties.maxVideoResolutionVertical,
        );
        const mergedSupportedFrameRates = mainUnit.properties.lensCalcFPS.filter((element) => {
            if (new Resolution(element.resolution).resolutionLower(maxSensorUnitResolution)) {
                return element;
            }
        });
        const origElements = sortedMainUnitsFrameRates.length;
        const nbrLowerResolution = mergedSupportedFrameRates.length;
        // also add the first larger or equal one if needed
        if (nbrLowerResolution < origElements) {
            const indexToAdd = origElements - nbrLowerResolution - 1;
            mergedSupportedFrameRates.push(sortedMainUnitsFrameRates[indexToAdd]);
        }
        return mergedSupportedFrameRates;
    }

    private getSensorUnitsEstimate(
        projectZipSetting: ProjectZipType,
        attachedChannels: IPersistence<IItemEntity>[],
        product: IPiaMainUnit,
        frequency: Frequency,
        mainUnitQuantity: number,
        getPiaItem: (id: number) => IPiaDevice | undefined,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        getMergedProfileFromDevice: (
            item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ) => IBaseProfileModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
    ): IBandwidthStorageAttachedCameraEstimateModel[] {
        const estimateSensorUnit = (channel: IPersistence<IItemEntity>) => {
            if (!channel.properties.sensorUnit) {
                throw new Error(`Invalid relation type or properties for ${channel.name}`);
            }

            if (channel.productId === null) {
                return this.getEmptyEstimate();
            }

            const sensorUnit = getPiaItem(channel.productId);

            function isSensorUnit(item: IPiaDevice): item is IPiaSensorUnit {
                return (
                    item.category === PiaItemSensorUnitCategory.SENSORUNIT ||
                    item.category === PiaItemSensorUnitCategory.THERMALSENSOR
                );
            }

            if (!sensorUnit || !isSensorUnit(sensorUnit)) {
                return this.getEmptyEstimate();
            }

            const properties = MainUnitEstimateService.mergeFSeriesProperties(product, sensorUnit);

            const profile = getMergedProfileFromDevice(channel.properties.sensorUnit);

            if (!profile) {
                return undefined;
            }

            const scenario = this.scenarioService.getScenarioOrThrow(profile.scenario.scenarioId);
            const customBandwidth = channel.properties.sensorUnit.profileOverride
                ? channel.properties.sensorUnit.profileOverride.customBandwidth
                : undefined;
            const profileEstimate = this.profileBandwidthStorageEstimateService.getProfileEstimate(
                projectZipSetting,
                scenario,
                profile,
                properties,
                frequency,
                getSchedule,
                projectBandwidthVersion,
                customBandwidth,
            );

            const totalLiveViewBandwidth =
                profileEstimate.liveViewBandwidth * channel.quantity * mainUnitQuantity;

            const totalRecordingBandwidth =
                profileEstimate.recordingBandwidth * channel.quantity * mainUnitQuantity;

            const totalStorageInMB =
                profileEstimate.storageInMB * channel.quantity * mainUnitQuantity;

            return {
                perCamera: {
                    liveViewBandwidth: profileEstimate.liveViewBandwidth,
                    recordingBandwidth: profileEstimate.recordingBandwidth,
                    storageInMB: profileEstimate.storageInMB,
                    bandwidthInBps:
                        profileEstimate.recordingBandwidth + profileEstimate.liveViewBandwidth,
                    formattedLiveViewBandwidth: format.bandwidth(profileEstimate.liveViewBandwidth),
                    formattedRecordingBandwidth: format.bandwidth(
                        profileEstimate.recordingBandwidth,
                    ),
                    formattedStorage: format.storage(profileEstimate.storageInMB),
                    formattedBandwidth: format.bandwidth(
                        profileEstimate.recordingBandwidth + profileEstimate.liveViewBandwidth,
                    ),
                    retentionTime: profile.storage.retentionTime,
                },
                total: {
                    liveViewBandwidth: totalLiveViewBandwidth,
                    recordingBandwidth: totalRecordingBandwidth,
                    storageInMB: totalStorageInMB,
                    bandwidthInBps: totalRecordingBandwidth + totalLiveViewBandwidth,
                    formattedLiveViewBandwidth: format.bandwidth(totalLiveViewBandwidth),
                    formattedRecordingBandwidth: format.bandwidth(totalRecordingBandwidth),
                    formattedStorage: format.storage(totalStorageInMB),
                    formattedBandwidth: format.bandwidth(
                        totalRecordingBandwidth + totalLiveViewBandwidth,
                    ),
                    retentionTime: profile.storage.retentionTime,
                },
                channels: channel.quantity,
                triggeredRecording: profileEstimate.triggeredRecording,
                continuousRecording: profileEstimate.continuousRecording,
            };
        };

        return attachedChannels.map(estimateSensorUnit).filter(isDefined);
    }

    private getPerMainUnitEstimate(
        attachedChannelsEstimates: IBandwidthStorageAttachedCameraEstimateModel[],
    ): IBandwidthStorageRequirementsModel {
        const encoderEstimates: IBandwidthStorageRequirementsModel = Object.values(
            attachedChannelsEstimates,
        ).reduce(
            (summary, current) => {
                summary.liveViewBandwidth += current.perCamera.liveViewBandwidth * current.channels;
                summary.recordingBandwidth +=
                    current.perCamera.recordingBandwidth * current.channels;
                summary.storageInMB += current.perCamera.storageInMB * current.channels;
                summary.bandwidthInBps += summary.liveViewBandwidth + summary.recordingBandwidth;

                return summary;
            },
            {
                liveViewBandwidth: 0,
                recordingBandwidth: 0,
                storageInMB: 0,
                bandwidthInBps: 0,
                formattedLiveViewBandwidth: '',
                formattedRecordingBandwidth: '',
                formattedStorage: '',
                formattedBandwidth: '',
                retentionTime: 0,
            },
        );

        this.formatRequirements(encoderEstimates);

        return encoderEstimates;
    }

    private getMainUnitTotalEstimate(
        attachedChannelsEstimates: IBandwidthStorageAttachedCameraEstimateModel[],
    ): IBandwidthStorageRequirementsModel {
        const encoderEstimates: IBandwidthStorageRequirementsModel = Object.values(
            attachedChannelsEstimates,
        ).reduce(
            (summary, current) => {
                summary.liveViewBandwidth += current.total.liveViewBandwidth;
                summary.recordingBandwidth += current.total.recordingBandwidth;
                summary.storageInMB += current.total.storageInMB;
                summary.bandwidthInBps = summary.liveViewBandwidth + summary.recordingBandwidth;

                return summary;
            },
            {
                liveViewBandwidth: 0,
                recordingBandwidth: 0,
                storageInMB: 0,
                bandwidthInBps: 0,
                formattedLiveViewBandwidth: '',
                formattedRecordingBandwidth: '',
                formattedStorage: '',
                formattedBandwidth: '',
                retentionTime: 0,
            },
        );

        this.formatRequirements(encoderEstimates);

        return encoderEstimates;
    }

    private formatRequirements(requirements: IBandwidthStorageRequirementsModel) {
        requirements.formattedLiveViewBandwidth = format.bandwidth(requirements.liveViewBandwidth);

        requirements.formattedRecordingBandwidth = format.bandwidth(
            requirements.recordingBandwidth,
        );

        requirements.formattedStorage = format.storage(requirements.storageInMB);

        requirements.formattedBandwidth = format.bandwidth(
            requirements.recordingBandwidth + requirements.liveViewBandwidth,
        );
    }

    private getSensorUnitCount(sensorUnits: IPersistence<IItemEntity>[]): number {
        return sensorUnits.reduce((acc, { quantity }) => {
            return (acc += quantity);
        }, 0);
    }

    private getEmptyEstimate(): IBandwidthStorageAttachedCameraEstimateModel {
        return {
            perCamera: {
                liveViewBandwidth: 0,
                recordingBandwidth: 0,
                storageInMB: 0,
                bandwidthInBps: 0,
                formattedLiveViewBandwidth: '-',
                formattedRecordingBandwidth: '-',
                formattedStorage: '-',
                formattedBandwidth: '-',
                retentionTime: 0,
            },
            total: {
                liveViewBandwidth: 0,
                recordingBandwidth: 0,
                storageInMB: 0,
                bandwidthInBps: 0,
                formattedLiveViewBandwidth: '-',
                formattedRecordingBandwidth: '-',
                formattedStorage: '-',
                formattedBandwidth: '-',
                retentionTime: 0,
            },
            channels: 0,
            continuousRecording: undefined,
            triggeredRecording: undefined,
        };
    }
}
