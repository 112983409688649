import type { IMigration } from './models/IMigration';
import { deleteDatabase } from './databaseUtil';

export const migrate5to6: IMigration = {
    from: 5,
    to: 6,
    run: migrate,
};

async function migrate(): Promise<void> {
    await Promise.all([deleteDatabase('_pouch_asd-pia-v3')]);
}
