import { t } from 'app/translate';
import * as React from 'react';
import type { IProduct } from 'app/modules/common';
import type { IAutoTestable } from 'app/components';
import { Badge, Stack, Icon, toTestIdFormat } from 'app/components';
import { nameComparator } from 'app/utils';

export interface IBillOfMaterialsSubsectionProps extends IAutoTestable {
    header: string;
    products: IProduct[];
}

export const BillOfMaterialsSubsection: React.FunctionComponent<IBillOfMaterialsSubsectionProps> = (
    props,
) => {
    if (props.products.length === 0) {
        return null;
    }

    const tableRows = props.products.sort(nameComparator).map((product: IProduct) => (
        <tr key={product.id} data-discontinued={product.discontinued}>
            <th scope="row">
                <Stack>
                    <div data-test-id={`div_name_${toTestIdFormat(product.name)}`}>
                        {product.name}
                    </div>
                    {product.discontinued && (
                        <Badge compact type="red" label={t.quotationDiscontinued} />
                    )}
                    {product.allowlisted !== undefined && !product.allowlisted && (
                        <Icon color="blue" icon="feedback" />
                    )}
                </Stack>
            </th>
            <td>{product.partNumber || t.quotationNotAvailable}</td>
            <td data-test-id={`td_quantity_${toTestIdFormat(product.name)}`}>{product.quantity}</td>
        </tr>
    ));

    return (
        <tbody>
            <tr>
                <th colSpan={3} scope="colgroup">
                    {props.header}
                </th>
            </tr>

            {tableRows}
        </tbody>
    );
};

BillOfMaterialsSubsection.displayName = 'BillOfMaterialsSubsection';
