import * as React from 'react';
import type { IWithChildren } from 'app/components';
import { Icon, Clickable, Stack } from 'app/components';
import { css } from '@emotion/css';

const marginStyle = css`
    margin-bottom: -10px;
`;

interface IDeviceListHeaderItemProps extends IWithChildren {
    direction: 'ascending' | 'descending' | 'none';
    onClick(): void;
}

export class TableHeaderSort extends React.Component<IDeviceListHeaderItemProps> {
    public render() {
        return (
            <Stack justifyContent="start" alignItems="center" spacing="quart">
                <Clickable onClick={this.props.onClick}>
                    {this.props.children} {this.renderArrow()}
                </Clickable>
            </Stack>
        );
    }

    private renderArrow = () => {
        if (this.props.direction === 'descending') {
            return (
                <Stack notFullWidth vertical spacing="none">
                    <div className={marginStyle}>
                        <Icon size="sm" color="grey4" icon="arrow_up" />
                    </div>
                    <Icon size="sm" color="grey8" icon="arrow_down" />
                </Stack>
            );
        } else if (this.props.direction === 'ascending') {
            return (
                <Stack notFullWidth vertical spacing="none">
                    <div className={marginStyle}>
                        <Icon size="sm" color="grey8" icon="arrow_up" />
                    </div>
                    <Icon size="sm" color="grey4" icon="arrow_down" />
                </Stack>
            );
        }

        return (
            <Stack notFullWidth vertical spacing="none">
                <div className={marginStyle}>
                    <Icon size="sm" color="grey4" icon="arrow_up" />
                </div>
                <Icon size="sm" color="grey4" icon="arrow_down" />
            </Stack>
        );
    };
}
