import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/**
 * Set minimum dynamic fps to 5 if not defined for profiles as long as scenario isn't already using dynamic FPS.
 * If scenario is already using dynamic FPS but doesn't have minDynamicFps, set value to 1.
 */
@injectable()
export class Migration29To30 extends MigrationProviderBase {
    public from: number = 29;
    public to: number = 30;
    private defaultMinFpsForFixed = 5;
    private defaultMinFpsForDynamic = 1;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'profile') {
                if (!entity.zipstream.minDynamicFps) {
                    // If user is already using dynamic FPS we shouldn't set a minimum FPS that they haven't chosen themselves.
                    // Hence we set min FPS to 1 as that is the minimum allowed value.
                    // If user is using fixed FPS, we provide our new standard default value of 5, same as for new scenarios.
                    const defaultMinFps =
                        entity.zipstream.fpsMode === 'fixed'
                            ? this.defaultMinFpsForFixed
                            : this.defaultMinFpsForDynamic;

                    entity.zipstream.minDynamicFps = defaultMinFps;
                }
            }
            return entity;
        };
    }
}
