import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Svg,
    G,
    Path,
    Polyline,
    Polygon,
} from '@react-pdf/renderer';
import { t } from 'app/translate';
import { SpacingsEnum } from 'app/styles';

const styles = StyleSheet.create({
    pageStyle: {
        paddingTop: SpacingsEnum.doublePanel,
        paddingHorizontal: SpacingsEnum.panel,
        paddingBottom: SpacingsEnum.doublePanel,
    },

    tableStyle: {
        width: '100%',
    },

    tableRowStyle: {
        flexDirection: 'row',
    },
    colorTableRowStyle: {
        flexDirection: 'row',
        backgroundColor: '#f0f4fc',
    },

    firstTableColHeaderStyle: {
        width: '100%',
        backgroundColor: '#bdbdbd',
    },

    tableColHeaderStyle: {
        width: '100%',
        backgroundColor: '#bdbdbd',
    },

    firstTableColStyle: {
        width: '100%',
    },

    tableColStyle: {
        width: '100%',
    },

    tableCellHeaderStyle: {
        marginBottom: SpacingsEnum.halfQuart,
        fontSize: 12,
    },

    tableCellStyle: {
        fontSize: 12,
    },
});

export interface IPdfItem {
    model?: string;
    partNumber?: string;
}

interface IPdfTableDocument {
    header: string;
    allAccessories: IPdfItem[];
}

const TableHeader: React.FunctionComponent = () => (
    <View style={styles.tableRowStyle} fixed>
        <View style={styles.firstTableColHeaderStyle}>
            <Text style={[styles.tableCellHeaderStyle, { marginLeft: SpacingsEnum.quart }]}>
                {t.name}
            </Text>
        </View>

        <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyle}>{t.partNumber}</Text>
        </View>
    </View>
);

const TableRows: React.FunctionComponent<{ allAccessories: IPdfItem[] }> = ({ allAccessories }) => (
    <React.Fragment>
        {allAccessories.map((item, index) => (
            <View key={index} style={index % 2 ? styles.colorTableRowStyle : styles.tableRowStyle}>
                <View style={styles.firstTableColStyle}>
                    <Text style={[styles.tableCellStyle, { marginLeft: SpacingsEnum.quart }]}>
                        {item.model?.split('') ?? ''}
                    </Text>
                </View>
                <View style={styles.tableColStyle}>
                    <Text style={styles.tableCellStyle}>{item.partNumber ?? '-'}</Text>
                </View>
            </View>
        ))}
    </React.Fragment>
);

const AxisLogo: React.FunctionComponent = () => (
    <Svg viewBox="0 0 147 53" width="150" style={{ marginBottom: '30' }}>
        <G stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <Path
                d="M61.30975,9.1865 L68.534,9.1865 C68.5845,9.1865 68.6255,9.23 68.6255,9.28375 L68.6255,40.579 C68.6255,40.63275 68.5845,40.67625 68.534,40.67625 L61.30975,40.67625 C61.259,40.67625 61.218,40.63275 61.218,40.579 L61.218,9.28375 C61.218,9.23 61.259,9.1865 61.30975,9.1865"
                fill="#1A1918"
            ></Path>
            <Path
                d="M58.91075,9.1865 C58.95475,9.1865 58.97275,9.26 58.94875,9.299 L49.389,24.753 C49.298,24.8995 49.28625,25.05675 49.37775,25.20275 L59.012,40.5375 C59.06725,40.625 59.03075,40.67625 58.978,40.67625 L50.03775,40.67625 C49.95275,40.67625 49.8935,40.63875 49.8475,40.5635 L44.08175,31.1355 C44.01875,31.0325 43.96075,31.0435 43.90425,31.14175 L38.4635,40.55125 C38.4125,40.63925 38.3405,40.67625 38.243,40.67625 L29.85475,40.67625 C29.75375,40.67625 29.73975,40.64425 29.78925,40.563 L39.6365,24.38125 C39.74025,24.21025 39.732,24.032 39.63,23.85975 L30.98275,9.317 C30.95425,9.2695 30.98675,9.1865 31.03975,9.1865 L39.68375,9.1865 C39.76625,9.1865 39.81875,9.23275 39.86275,9.307 L45.0875,18.12225 C45.14525,18.21975 45.2105,18.219 45.27225,18.117 L50.65525,9.2995 C50.70125,9.2235 50.7605,9.1865 50.8455,9.1865 L58.91075,9.1865"
                fill="#1A1918"
            ></Path>
            <Polyline
                fill="#FFDA1A"
                points="120.63975 0.21675 146.59475 40.6585 124.06225 40.6585 115.56525 27.42125 114.47775 27.42125 113.90425 26.55225 103.7465 26.5505 120.63975 0.21675"
            ></Polyline>
            <Polyline
                fill="#1A1918"
                points="119.01975 25.23025 128.92025 40.6585 97.2875 40.6585 107.189 25.23025 119.01975 25.23025"
            ></Polyline>
            <G transform="translate(0.000000, 9.000000)">
                <Polyline
                    fill="#DD3B41"
                    points="116.4305 16.23025 126.331 31.6585 94.698 31.6585 104.59975 16.23025 116.4305 16.23025"
                ></Polyline>
                <Path
                    d="M20.0385,21.0765 C20.0385,21.10725 20.02275,21.1395 19.994,21.1395 L14.637,21.1395 C14.60975,21.1395 14.5875,21.1125 14.6155,21.069 L20.22575,12.03725 C20.2535,11.99375 20.2805,12.00775 20.2805,12.047 L20.0385,21.0765 L20.0385,21.0765 Z M27.09375,0.1865 L20.371,0.1865 C20.27875,0.1865 20.21925,0.23325 20.16725,0.31425 L0.011,31.575 C-0.011,31.60875 0.00075,31.67625 0.03975,31.67625 L8.253,31.67625 C8.326,31.67625 8.3835,31.6615 8.42475,31.5975 L11.162,27.33325 C11.20425,27.26775 11.26125,27.24725 11.33625,27.24725 L19.9265,27.24725 C19.9825,27.24725 20.03425,27.288 20.03425,27.34725 L20.03425,31.54775 C20.03425,31.60525 20.0475,31.67625 20.1015,31.67625 L27.12075,31.67625 C27.17325,31.67625 27.2015,31.61775 27.2015,31.562 L27.2015,0.315 C27.2015,0.248 27.157,0.1865 27.09375,0.1865 L27.09375,0.1865 Z"
                    fill="#1A1918"
                ></Path>
            </G>
            <Path
                d="M72.8775,40.67625 C72.828,40.67625 82.34825,40.67625 82.34825,40.67625 C83.55025,40.64175 84.76975,40.449 85.9085,40.08175 C90.27075,38.67425 92.61375,34.36775 92.61375,30.6525 C92.61375,24.72875 88.009,22.687 82.48875,20.2945 C81.5275,19.878 80.36125,19.3095 80.36125,18.04275 C80.36125,16.70575 81.62675,16.0445 83.02725,16.0445 L90.49425,16.0445 C90.54625,16.0445 90.58875,16.00025 90.58875,15.946 L90.584,9.1345 C90.584,9.08725 90.535,9.064 90.48975,9.064 L82.65025,9.064 C76.1065,9.064 72.25575,12.94825 72.25575,18.3805 C72.25575,23.10925 75.289,25.4385 79.661,27.16225 C82.731,28.3725 84.72375,28.9355 84.72375,31.4125 C84.72375,32.73525 83.94275,33.9455 82.327,33.9455 L72.8885,33.9455 C72.8115,33.9455 72.80675,34.0135 72.80675,34.0135 C72.80675,33.964 72.80675,40.531 72.80675,40.5805 C72.80675,40.6795 72.927,40.67625 72.8775,40.67625"
                fill="#1A1918"
            ></Path>
            <Path
                d="M96.672,13.99375 C95.358,13.99375 94.29275,12.92875 94.29275,11.61475 C94.29275,10.30075 95.358,9.23575 96.672,9.23575 C97.986,9.23575 99.051,10.30075 99.051,11.61475 C99.051,12.92875 97.986,13.99375 96.672,13.99375 L96.672,13.99375 Z M96.672,8.9955 C95.2255,8.9955 94.0525,10.16825 94.0525,11.61475 C94.0525,13.06125 95.2255,14.234 96.672,14.234 C98.1185,14.234 99.2915,13.06125 99.2915,11.61475 C99.2915,10.16825 98.1185,8.9955 96.672,8.9955 L96.672,8.9955 Z"
                fill="#1A1918"
            ></Path>
            <Path
                d="M96.08075,11.4955 L96.08075,10.399 L96.8445,10.399 C97.00525,10.399 97.135,10.4035 97.269,10.4975 C97.4165,10.596 97.48325,10.7435 97.48325,10.9225 C97.48325,11.07025 97.43425,11.218 97.327,11.3255 C97.17525,11.482 97.00525,11.4955 96.79975,11.4955 L96.08075,11.4955 L96.08075,11.4955 Z M97.89875,13.138 L97.29575,11.71475 C97.65325,11.61625 97.8675,11.2895 97.8675,10.927 C97.8675,10.6675 97.747,10.41225 97.5415,10.25575 C97.336,10.099 97.0945,10.06775 96.8445,10.06775 L95.6965,10.06775 L95.6965,13.138 L96.08075,13.138 L96.08075,11.80875 L96.9295,11.80875 L97.49675,13.138 L97.89875,13.138 L97.89875,13.138 Z"
                fill="#1A1918"
            ></Path>
            <Path
                d="M6.328,52.3895 C5.45,52.82925 4.7845,53 3.85075,53 C1.40125,53 0.1815,51.456 0.1815,49.87625 C0.1815,48.24275 1.5585,46.71675 3.971,46.71675 C4.84925,46.71675 5.63475,46.89625 6.254,47.25525 L6.254,48.61925 C5.56075,48.24275 4.8585,47.99125 4.082,47.99125 C2.74175,47.99125 1.799,48.76325 1.799,49.849 C1.799,50.94425 2.7325,51.725 4.045,51.725 C4.8675,51.725 5.42225,51.47375 6.328,51.052 L6.328,52.3895"
                fill="#1A1918"
            ></Path>
            <Path
                d="M15.39825,47.99125 C14.19675,47.99125 13.40175,48.73625 13.40175,49.85825 C13.40175,50.98025 14.19675,51.725 15.39825,51.725 C16.5905,51.725 17.3855,50.98025 17.3855,49.85825 C17.3855,48.73625 16.5905,47.99125 15.39825,47.99125 L15.39825,47.99125 Z M15.39825,53 C13.0875,53 11.78425,51.42 11.78425,49.85825 C11.78425,48.37725 13.02275,46.71675 15.39825,46.71675 C17.77375,46.71675 19.003,48.38625 19.003,49.85825 C19.003,51.42925 17.66275,53 15.39825,53 L15.39825,53 Z"
                fill="#1A1918"
            ></Path>
            <Polyline
                fill="#1A1918"
                points="29.84825 46.7975 31.21625 46.7975 31.21625 52.919 29.645 52.919 29.645 49.0955 27.94425 51.016 27.704 51.016 26.00325 49.0955 26.00325 52.919 24.432 52.919 24.432 46.7975 25.8 46.7975 27.824 49.06825 29.84825 46.7975"
            ></Polyline>
            <Polyline
                fill="#1A1918"
                points="42.7805 46.7975 44.14825 46.7975 44.14825 52.919 42.577 52.919 42.577 49.0955 40.87625 51.016 40.636 51.016 38.93525 49.0955 38.93525 52.919 37.364 52.919 37.364 46.7975 38.732 46.7975 40.75625 49.06825 42.7805 46.7975"
            ></Polyline>
            <Path
                d="M56.17475,46.7975 L56.17475,50.271 C56.17475,52.29975 54.75125,53 53.02275,53 C51.19275,53 49.871,52.174 49.871,50.28025 L49.871,46.7975 L51.44225,46.7975 L51.44225,50.316 C51.44225,51.19575 52.0985,51.725 53.0045,51.725 C53.83625,51.725 54.6035,51.30325 54.6035,50.20825 L54.6035,46.7975 L56.17475,46.7975"
                fill="#1A1918"
            ></Path>
            <Polyline
                fill="#1A1918"
                points="68.32325 52.919 67.0015 52.919 63.51675 48.9965 63.51675 52.919 61.9455 52.919 61.9455 46.7975 63.3875 46.7975 66.752 50.5405 66.752 46.7975 68.32325 46.7975 68.32325 52.919"
            ></Polyline>
            <Polygon
                fill="#1A1918"
                points="74.2695 52.919 75.84075 52.919 75.84075 46.7975 74.2695 46.7975"
            ></Polygon>
            <Path
                d="M87.18175,52.3895 C86.3035,52.82925 85.638,53 84.70475,53 C82.25525,53 81.035,51.456 81.035,49.87625 C81.035,48.24275 82.41225,46.71675 84.82475,46.71675 C85.70275,46.71675 86.4885,46.89625 87.10775,47.25525 L87.10775,48.61925 C86.4145,48.24275 85.712,47.99125 84.93575,47.99125 C83.59525,47.99125 82.65275,48.76325 82.65275,49.849 C82.65275,50.94425 83.586,51.725 84.8985,51.725 C85.72125,51.725 86.27575,51.47375 87.18175,51.052 L87.18175,52.3895"
                fill="#1A1918"
            ></Path>
            <Path
                d="M94.782,48.2605 L93.85775,50.639 L95.799,50.639 L94.782,48.2605 L94.782,48.2605 Z M98.47025,52.919 L96.77875,52.919 L96.261,51.70725 L93.442,51.70725 L92.9705,52.919 L91.30675,52.919 L93.84875,46.7975 L95.6695,46.7975 L98.47025,52.919 L98.47025,52.919 Z"
                fill="#1A1918"
            ></Path>
            <Polyline
                fill="#1A1918"
                points="107.658 47.9195 105.37525 47.9195 105.37525 52.919 103.804 52.919 103.804 47.9195 101.52075 47.9195 101.52075 46.7975 107.658 46.7975 107.658 47.9195"
            ></Polyline>
            <Polygon
                fill="#1A1918"
                points="112.44875 52.919 114.02025 52.919 114.02025 46.7975 112.44875 46.7975"
            ></Polygon>
            <Path
                d="M122.40325,47.99125 C121.2015,47.99125 120.40675,48.73625 120.40675,49.85825 C120.40675,50.98025 121.2015,51.725 122.40325,51.725 C123.5955,51.725 124.3905,50.98025 124.3905,49.85825 C124.3905,48.73625 123.5955,47.99125 122.40325,47.99125 L122.40325,47.99125 Z M122.40325,53 C120.0925,53 118.78925,51.42 118.78925,49.85825 C118.78925,48.37725 120.02775,46.71675 122.40325,46.71675 C124.77875,46.71675 126.008,48.38625 126.008,49.85825 C126.008,51.42925 124.66775,53 122.40325,53 L122.40325,53 Z"
                fill="#1A1918"
            ></Path>
            <Polyline
                fill="#1A1918"
                points="137.03825 52.919 135.71625 52.919 132.23175 48.9965 132.23175 52.919 130.6605 52.919 130.6605 46.7975 132.1025 46.7975 135.46675 50.5405 135.46675 46.7975 137.03825 46.7975 137.03825 52.919"
            ></Polyline>
            <Path
                d="M146.19175,48.38625 C145.2765,47.99125 144.73125,47.8385 144.19525,47.8385 C143.63125,47.8385 143.27075,48.036 143.27075,48.34125 C143.27075,49.26575 146.47825,49.00525 146.47825,51.14175 C146.47825,52.3175 145.46125,53 144.0565,53 C142.9565,53 142.41125,52.72175 141.7825,52.4075 L141.7825,51.08775 C142.68825,51.67125 143.243,51.878 143.93625,51.878 C144.537,51.878 144.86075,51.67125 144.86075,51.3125 C144.86075,50.30675 141.65325,50.675 141.65325,48.476 C141.65325,47.41675 142.596,46.71675 144.0565,46.71675 C144.759,46.71675 145.3965,46.86025 146.19175,47.19225 L146.19175,48.38625"
                fill="#1A1918"
            ></Path>
        </G>
    </Svg>
);

export const PdfTableDocument: React.FunctionComponent<IPdfTableDocument> = ({
    header,
    allAccessories,
}) => (
    <Document>
        <Page style={styles.pageStyle} size="A4" orientation="portrait">
            <AxisLogo />
            <Text style={{ marginBottom: '10', fontSize: 10 }}>{header}</Text>
            <View style={styles.tableStyle}>
                <TableHeader />
                <TableRows allAccessories={allAccessories} />
            </View>
        </Page>
    </Document>
);
