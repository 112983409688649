import * as React from 'react';
import type { WhiteSpace } from 'app/components';
import { Text, Heading } from 'app/components';
import { getProductNameParts } from '../utils';
import { getUserShouldSeeMsrp } from '../../prices';
import { useSelector } from 'react-redux';

interface IProductNameProps {
    /**
     * The product name
     */
    name: string;
    /**
     * Render the name as headings instead of paragraphs
     */
    large?: boolean;
    /**
     * Set the whitespace css behavior
     */
    whiteSpace?: WhiteSpace;
    /**
     * Apply full width to component
     */
    fullWidth?: boolean;
    /**
     * Sets both item and description to bold text and concatenates them.
     */
    allBold?: boolean;
}

/**
 * Render a product name in two rows with the
 * second row being the model description.
 */

export const ProductName: React.FC<IProductNameProps> = ({
    name,
    large,
    whiteSpace,
    fullWidth,
    allBold,
}) => {
    const { model, description } = getProductNameParts(name);
    const lineClamp = useSelector(getUserShouldSeeMsrp) ? 2 : undefined;

    if (allBold) {
        return <Heading lineClamp={lineClamp} align="center">{`${model} ${description}`}</Heading>;
    }

    if (large) {
        return (
            <>
                <Heading align="center">{model}</Heading>
                <Text fullWidth={fullWidth} whiteSpace={whiteSpace} align="center">
                    {description}
                </Text>
            </>
        );
    }

    return (
        <>
            <Text align="center">{model}</Text>
            <Text fullWidth whiteSpace="nowrap" align="center">
                {description}
            </Text>
        </>
    );
};
