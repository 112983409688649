import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { getSelectedDeviceId } from '../../selectors';
import { AccessoriesListItem } from './AccessoriesListItem.container';
import { SelectableList, Stack, Text } from 'app/components';
import { getCurrentProjectItem, getSelectedMountIdsForItemId } from 'app/modules/common';
import { t } from 'app/translate';
import { isDefined } from 'axis-webtools-util';

export const MountingAccessoriesList: React.FC = () => {
    const selectedDeviceId = useSelector(getSelectedDeviceId);
    const deviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) => {
        return selectedDeviceId ? getCurrentProjectItem(state, selectedDeviceId) : undefined;
    });
    const selectedIds = useSelector<IStoreState, Id[]>((state) => {
        return selectedDeviceId ? getSelectedMountIdsForItemId(state, selectedDeviceId) : [];
    }).filter(isDefined);

    const hasAccessories = selectedIds.length > 0;

    return (
        <Stack vertical>
            <Text style="heading">{t.mountingAccessories}</Text>
            <SelectableList
                notStickyHeader
                noBackground
                noHeaderBackground
                notScrollable
                header={[
                    <Text color="grey6" whiteSpace="nowrap">
                        {t.name}
                    </Text>,
                    '',
                    hasAccessories ? (
                        <Text color="grey6" whiteSpace="nowrap">
                            {t.quantity}
                        </Text>
                    ) : (
                        ''
                    ),
                    '',
                    '',
                ]}
                emptyIcon="category"
                emptyText={t.noAccessoriesSelectedForMounting}
                verticallyCenterContents
            >
                {selectedIds.map((id) => (
                    <AccessoriesListItem
                        key={id}
                        itemId={id}
                        parentQuantity={deviceItem?.quantity}
                    />
                ))}
            </SelectableList>
        </Stack>
    );
};
