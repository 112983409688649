import { isCamera, isSensorUnit } from 'app/core/persistence';
import type { IPiaCamera, IPiaSensorUnit } from 'app/core/pia';
import { getAllPiaDevices } from 'app/modules/common';
import { createSelector } from 'reselect';
import { defaultMinOperationalTemperature, defaultMaxOperationalTemperature } from '../constants';

const getAllVideoDevices = createSelector([getAllPiaDevices], (piaDevices) => {
    return piaDevices.filter((device) => isCamera(device) || isSensorUnit(device)) as
        | IPiaSensorUnit[]
        | IPiaCamera[];
});

/**
 * Gets the minimum and maximum temperature value of all devices (sensor units or cameras).
 */
export const getOperationalTemperaturesMinMax = createSelector([getAllVideoDevices], (devices) => {
    let minTemp = defaultMinOperationalTemperature;
    let maxTemp = defaultMaxOperationalTemperature;
    devices.forEach((device: IPiaSensorUnit | IPiaCamera) => {
        const temp = device.properties.operationalTemperatureC;
        minTemp = temp ? Math.min(temp.min, minTemp ?? temp.min) : minTemp;
        maxTemp = temp ? Math.max(temp.max, maxTemp ?? temp.max) : maxTemp;
    });

    return [minTemp, maxTemp];
});
