import * as React from 'react';
import type { Property } from 'csstype';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';
import { isDefined } from 'axis-webtools-util';
import { AppConstants } from 'app/AppConstants';

interface IPositionedProps extends IExtendableComponentWithChildren {
    position?: React.CSSProperties['position'];
    top?: React.CSSProperties['top'];
    left?: React.CSSProperties['left'];
    right?: React.CSSProperties['right'];
    bottom?: React.CSSProperties['bottom'];
    translateX?: Property.Translate<string>;
    translateY?: Property.Translate<string>;
    rotate?: React.CSSProperties['rotate'];
    transformOrigin?: React.CSSProperties['transformOrigin'];
    insetInlineStart?: React.CSSProperties['insetInlineStart'];
    insetInlineEnd?: React.CSSProperties['insetInlineEnd'];
    /**
     * Set this to move the element behind the parent.
     * (sets the z-index to -1)
     */
    sendToBack?: boolean;
    /**
     * Set this to move the element in front of content.
     * (sets the z-index to 1)
     */
    sendForward?: boolean;
    /**
     * Set this to move the element in front of all content.
     */
    bringToFront?: boolean;
    /**
     * Set this to move the element in front of all map content.
     */
    aboveMap?: boolean;
    /**
     * Set this to use a CSS transition.
     * Useful for animating position changes.
     */
    transition?: boolean;
    /**
     * The duration of the transition in ms.
     * Defaults to 400 (ms).
     */
    duration?: number;
    /**
     * Centers the element horizontally by setting left and right margins to auto.
     */
    centerHorizontally?: boolean;
    /**
     * Use this to pass a ref prop into this component, you will get
     * the underlying div element as a result.
     */
    innerRef?: React.RefObject<HTMLDivElement>;
}

/**
 * Apply css positioning to a child element
 */
export class Positioned extends React.Component<IPositionedProps> {
    public render() {
        const {
            position,
            top,
            left,
            right,
            bottom,
            translateX,
            translateY,
            rotate,
            transformOrigin,
            duration,
            transition,
            children,
            sendToBack,
            sendForward,
            bringToFront,
            aboveMap,
            centerHorizontally,
            insetInlineStart,
            insetInlineEnd,
            ...extendedProps
        } = this.props;

        const child = React.Children.only(children);

        const transforms = [
            translateX || translateY
                ? `translate(${translateX || 0}, ${translateY || 0})`
                : undefined,
            rotate ? `rotate(${rotate})` : undefined,
        ].filter(isDefined);

        const style: React.CSSProperties = {
            position,
            top,
            left,
            right,
            bottom,
            transform: transforms.length > 0 ? transforms.join(' ') : undefined,
            transformOrigin,
            transition: transition ? `transform ${duration || 400}ms ease` : undefined,
            marginLeft: centerHorizontally ? 'auto' : undefined,
            marginRight: centerHorizontally ? 'auto' : undefined,
            insetInlineStart,
            insetInlineEnd,
        };

        if (sendToBack) {
            style.zIndex = -1;
        }

        if (sendForward) {
            style.zIndex = 1;
        }

        if (bringToFront) {
            style.zIndex = AppConstants.contentMaxDepth;
        }

        if (aboveMap) {
            style.zIndex = AppConstants.mapMaxDepth;
        }

        const attributes = extendableProps(extendedProps, { style });

        const Component = renderReactChildren(
            child,
            (c) =>
                React.cloneElement(c, {
                    ...attributes,
                    innerRef: this.props.innerRef,
                }),
            (c) => React.cloneElement(c, attributes.__htmlAttributes),
        );

        return Component;
    }
}
