import * as React from 'react';
import type { IModalButton } from 'app/modal';
import { ModalService } from 'app/modal';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { Box, Button, Modal, Stack } from 'app/components';
import { connect } from 'react-redux';

interface IUserConfirmationModalState {
    showModal: boolean;
    header: string;
    body: React.ReactNode;
    buttons: IModalButton[];
    warning?: boolean;
}

const mapStateToProps = (storeState: IStoreState): IUserConfirmationModalState => {
    return {
        showModal: storeState.modal.visible,
        header: storeState.modal.header,
        body: storeState.modal.body,
        buttons: storeState.modal.buttons,
        warning: storeState.modal.warning,
    };
};

class Container extends React.Component<IUserConfirmationModalState> {
    private modalService: ModalService;

    constructor(props: IUserConfirmationModalState) {
        super(props);

        this.modalService = ServiceLocator.get(ModalService);
    }

    public render() {
        return (
            this.props.showModal && (
                <Modal title={this.props.header} warning={this.props.warning} maxWidth="500px">
                    <Stack vertical={true}>
                        <Stack spacing="half" alignItems="start">
                            <Stack vertical spacing="quart">
                                <div>{this.props.body}</div>
                            </Stack>
                        </Stack>
                        <Box flex="shrinkAndGrow" justifyContent="end">
                            <Stack spacing="quart">
                                {this.props.buttons.map((button) => (
                                    <Button
                                        testId={`modal_${button.text}_btn`}
                                        key={button.text}
                                        onClick={() =>
                                            this.modalService.onUserConfirm(button.value)
                                        }
                                        primary={button.primary}
                                        text={!button.primary}
                                    >
                                        {button.text}
                                    </Button>
                                ))}
                            </Stack>
                        </Box>
                    </Stack>
                </Modal>
            )
        );
    }
}

export const UserConfirmationModal = connect(mapStateToProps)(Container);
