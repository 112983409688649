import * as geolib from 'geolib';
import type { PolyLine, IFloorPlanEntity, IGeoLocation, ILatLng } from 'app/core/persistence';

import { getOffset, getMidpoint, offset, rotate } from 'axis-webtools-util';
import { utils } from 'app/modules/common';

const toGeolibLatLng = ({ lat, lng }: ILatLng) => ({
    latitude: lat,
    longitude: lng,
});

/**
 * Retrieves the geolocation information for a floor plan entity.
 *
 * @param {IFloorPlanEntity} floorPlan - The floor plan entity
 * @returns the geolocation, including position, width, height, and angle.
 */
export const getFloorPlanGeoLocation = (floorPlan: IFloorPlanEntity): IGeoLocation | undefined => {
    if (floorPlan.image?.geoLocation) {
        return floorPlan.image.geoLocation;
    } else if (floorPlan.image?.bounds) {
        const widthAndHeight = getOffset(floorPlan.image.bounds.topLeft)(
            floorPlan.image.bounds.bottomRight,
        );
        return {
            position: getMidpoint(
                floorPlan.image.bounds.topLeft,
                floorPlan.image.bounds.bottomRight,
            ),
            width: Math.abs(widthAndHeight[0]),
            height: Math.abs(widthAndHeight[1]),
            angle: 0,
        };
    }
};

/**
 * Retrieves the geolocation information for a floor plan entity. If geolocation
 * information is not available, fall back to default location (used for
 * non geolocated floor plans)
 *
 * @param {IFloorPlanEntity} floorPlan - The floor plan entity
 * @returns the geolocation, including position, width, height, and angle.
 */
export const getFloorPlanGeoLocationWithFallback = (
    floorPlan: IFloorPlanEntity,
): IGeoLocation | undefined => {
    const geoLocation = getFloorPlanGeoLocation(floorPlan);
    if (geoLocation) {
        return geoLocation;
    } else if (floorPlan.image) {
        const bounds = utils.getFloorPlanImageTemporaryBounds(floorPlan.image);
        const widthAndHeight = getOffset(bounds.topLeft)(bounds.bottomRight);
        return {
            position: getMidpoint(bounds.topLeft, bounds.bottomRight),
            width: Math.abs(widthAndHeight[0]),
            height: Math.abs(widthAndHeight[1]),
            angle: 0,
        };
    }
};

/**
 * Check whether a latlng is within the floor plan
 * @param floorPlan the floor plan
 * @returns a function taking a latLng as input and returning true
 * if it is within the floor plan
 */
export const isLatLngWithinFloorPlan = (floorPlan: IFloorPlanEntity) => {
    const geoLocation = floorPlan?.image?.geoLocation;

    // for an unlocated floor plan include nothing
    if (!geoLocation) return () => false;

    const offsetPosition = offset(geoLocation.position);
    const rotateAngle = rotate(geoLocation.angle);

    const corners = [
        offsetPosition(rotateAngle([-geoLocation.width / 2, geoLocation.height / 2])),
        offsetPosition(rotateAngle([geoLocation.width / 2, geoLocation.height / 2])),
        offsetPosition(rotateAngle([geoLocation.width / 2, -geoLocation.height / 2])),
        offsetPosition(rotateAngle([-geoLocation.width / 2, -geoLocation.height / 2])),
    ].map(toGeolibLatLng);

    return (latLng: ILatLng) => geolib.isPointInPolygon(toGeolibLatLng(latLng), corners);
};

/**
 * Check whether all latlngs of a blocker are within the floor plan
 * @param floorPlan the floor plan
 * @returns a function taking a blocker as input and returning true
 * if it is within the floor plan
 */
export const isBlockerWithinFloorPlan = (floorPlan: IFloorPlanEntity) => {
    const latLngFilter = isLatLngWithinFloorPlan(floorPlan);

    return (blocker: PolyLine) =>
        blocker.flatMap((blockerComponent) => blockerComponent).every(latLngFilter);
};

/**
 * Check whether a map is a geomap
 * @param map the map
 * @returns whether the map is a geomap or not
 */
export const isGeoMap = (
    map: IFloorPlanEntity | undefined,
): map is IFloorPlanEntity & { mapType: 'StreetMap' } => map?.mapType === 'StreetMap';

/**
 * Check whether a map is a geolocated floorPlan.
 * @param map the map
 * @returns whether the map is a geolocated floor plan or not
 */
export const isGeoLocatedFloorPlan = (
    map: IFloorPlanEntity | undefined,
): map is IFloorPlanEntity & { image: { geoLocation: IGeoLocation } } => !!map?.image?.geoLocation;

/**
 * Check whether a map is geolocated. Note that geomaps are considered
 * geolocated since they have a location in the world.
 * @param map the map
 * @returns whether the map is geolocated or not
 */
export const isGeoLocated = (map: IFloorPlanEntity | undefined) =>
    isGeoLocatedFloorPlan(map) || isGeoMap(map);

/**
 * Calculate the area of a floor plan. Undefined if the floor plan is not geolocated.
 * @param floorPlan the floor plan
 * @returns the area of the floor plan
 */
export const getFloorPlanArea = (floorPlan: IFloorPlanEntity) => {
    const geoLocation = floorPlan?.image?.geoLocation;
    if (!geoLocation) return undefined;
    return geoLocation.width * geoLocation.height;
};

/**
 * Make sure the floor plan image bounds are in the correct order (top left, bottom right)
 * @param floorPlan the floor plan
 * @returns the floor plan with the image bounds in the correct order
 */
export const rectifyImageBounds = (floorPlan: IFloorPlanEntity) => {
    if (!floorPlan.image?.bounds) {
        return floorPlan;
    }

    const lats = [
        floorPlan.image.bounds.topLeft.lat,
        floorPlan.image.bounds.bottomRight.lat,
    ].sort();

    const lngs = [
        floorPlan.image.bounds.topLeft.lng,
        floorPlan.image.bounds.bottomRight.lng,
    ].sort();

    floorPlan.image.bounds.topLeft = { lat: lats[1], lng: lngs[0] };
    floorPlan.image.bounds.bottomRight = { lat: lats[0], lng: lngs[1] };

    return floorPlan;
};

/**
 * Get the location of a map.
 * @param map the map
 * @returns the location of the map or undefined if the map is an unlocated floor plan
 */
export const getMapLocation = (map: IFloorPlanEntity | undefined): ILatLng | undefined => {
    if (!map) return undefined;

    if (map.location) {
        return map.location;
    } else {
        const geoLocation = getFloorPlanGeoLocation(map);
        return geoLocation?.position;
    }
};

/**
 * Sort an array of maps by their distance to a given location
 * @param maps the maps to sort
 * @param location the location to sort by
 * @returns the sorted maps
 */
export const sortMapsByDistance = (maps: IFloorPlanEntity[], location: ILatLng | undefined) => {
    if (!location) return [];

    return maps.sort((a, b) => {
        const aGeoLocation = getMapLocation(a);
        const bGeoLocation = getMapLocation(b);

        if (!aGeoLocation || !bGeoLocation) return 0;

        const aDistance = geolib.getDistance(
            toGeolibLatLng(location),
            toGeolibLatLng(aGeoLocation),
        );
        const bDistance = geolib.getDistance(
            toGeolibLatLng(location),
            toGeolibLatLng(bGeoLocation),
        );

        return aDistance - bDistance;
    });
};
