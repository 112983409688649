import * as React from 'react';
import { css } from '@emotion/css';
import classNames from 'classnames';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps, renderReactChildren } from '../../services';
import type { Display } from 'app/styles';

const printStyle = css`
    display: none !important;
`;

const displayInline = css`
    @media print {
        display: inline !important;
    }
`;

const displayFlex = css`
    @media print {
        display: flex !important;
    }
`;

const displayBlock = css`
    @media print {
        display: block !important;
    }
`;

const displayInlineBlock = css`
    @media print {
        display: inline-block !important;
    }
`;

interface IPrint extends IExtendableComponentWithChildren {
    display?: Display | 'inline';
}
/**
 * Displays the children only when printing
 *
 * Works with ordinary HTML elements and with
 * React components that implement ExtendableComponent
 */
export class Print extends React.Component<IPrint> {
    public render() {
        const { children, display, ...extendedProps } = this.props;
        const attributes = extendableProps(extendedProps, {
            className: classNames(printStyle, {
                [displayBlock]: display === 'block',
                [displayFlex]: display === 'flex',
                [displayInline]: display === 'inline',
                [displayInlineBlock]: display === 'inlineBlock',
            }),
        });
        return renderReactChildren(
            children,
            (child) => React.cloneElement(child, attributes),
            (child) => React.cloneElement(child, attributes.__htmlAttributes),
            (child) => <span className={classNames(printStyle, displayInline)}>{child}</span>,
        );
    }
}
