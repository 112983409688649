import * as PolyBool from 'polybooljs';
import type { Line } from 'app/modules/common';
import { getCircle, toPolygon } from './cameraCalc';
import type { IRadarDetectionRange } from '../components/map/cone/RadarCone';

export function calculateRadarArea(
    radarHorizontalFieldOfDetection: number,
    radarDetectionRange: IRadarDetectionRange,
    targetDistance: number,
): IRadarArea {
    const horizontalFov = radarHorizontalFieldOfDetection;

    // No detect area
    const noDetectDistance = Math.min(
        radarDetectionRange.vehicle.min,
        radarDetectionRange.human.min,
    );
    const noDetectLine = getCircle(noDetectDistance, horizontalFov);

    // Human detect area
    let humanDistance = Math.min(targetDistance, radarDetectionRange.human.max);
    if (humanDistance < noDetectDistance) {
        humanDistance = noDetectDistance;
    }
    const humanDetectLine = getCircle(humanDistance, horizontalFov);
    const humanPolygon = PolyBool.xor(toPolygon(noDetectLine), toPolygon(humanDetectLine));
    const humanArea = humanPolygon.regions.length > 0 ? humanPolygon.regions[0] : [];

    // Vehicle detect area
    let vehicleDistance = Math.min(targetDistance, radarDetectionRange.vehicle.max);
    if (vehicleDistance < noDetectDistance) {
        vehicleDistance = noDetectDistance;
    }
    const vehicleDetectLine = getCircle(vehicleDistance, horizontalFov);

    // Visible area
    const unionPolygon = PolyBool.union(toPolygon(humanDetectLine), toPolygon(vehicleDetectLine));
    const visiblePolygon = PolyBool.difference(unionPolygon, toPolygon(noDetectLine));
    const visibleArea = visiblePolygon.regions.length > 0 ? visiblePolygon.regions[0] : [];

    return {
        humanArea,
        visibleArea,
    };
}

export type IRadarArea = {
    humanArea: Line;
    visibleArea: Line;
};
