import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { DeviceGroup } from '../../models';
import { CameraFilterPanel } from './CameraFilterPanel.container';
import { MainUnitFilterPanel } from './MainUnitFilterPanel.container';
import { EncoderFilterPanel } from './EncoderFilterPanel.container';
import { AccessControlFilterPanel } from './AccessControlFilterPanel.container';
import { WearablesFilterPanel } from './WearablesFilterPanel.container';
import { SpeakerFilterPanel } from './SpeakerFilterPanel.container';
import { OtherDeviceFilterPanel } from './OtherDeviceFilterPanel.container';
import { TwoNFilterPanel } from './twoNFilterPanel.container';
import { SensorUnitFilterPanel } from './SensorUnitFilterPanel.container';

export const FilterPanelSelector: React.FC = () => {
    const deviceGroup = useSelector<IStoreState, DeviceGroup>(
        (state) => state.deviceSelector.deviceGroup,
    );

    if (deviceGroup === 'cameras') {
        return <CameraFilterPanel />;
    }
    if (deviceGroup === 'sensorunits') {
        return <SensorUnitFilterPanel />;
    }
    if (deviceGroup === 'mainunits') {
        return <MainUnitFilterPanel />;
    }
    if (deviceGroup === 'encoders') {
        return <EncoderFilterPanel />;
    }
    if (deviceGroup === 'wearables') {
        return <WearablesFilterPanel />;
    }
    if (deviceGroup === 'accessControls') {
        return <AccessControlFilterPanel />;
    }
    if (deviceGroup === 'speakers') {
        return <SpeakerFilterPanel />;
    }
    if (deviceGroup === 'other') {
        return <OtherDeviceFilterPanel />;
    }
    if (deviceGroup === 'twoNProducts') {
        return <TwoNFilterPanel />;
    }

    return null;
};
