import { getDeviceAndSubTypeForItem, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';

/** Get child device type for a parent id */
export const getChildType = createCachedSelector(
    [getDeviceAndSubTypeForItem],
    (parentDeviceType) => {
        if (parentDeviceType === 'encoder') {
            return 'analogCamera';
        } else if (parentDeviceType === 'mainUnit') {
            return 'sensorUnit';
        } else if (parentDeviceType === 'iorelay') {
            return 'relayexpmodules';
        } else {
            return 'door';
        }
    },
)(toCacheKey);
