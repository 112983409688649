import type { ISpeakerPropertiesFilterEntity } from '../userDataPersistence';

export function getDefaultSpeakerFilterEntity(
    installationHeight: number,
): ISpeakerPropertiesFilterEntity {
    return {
        placement: undefined,
        outdoor: false,
        basicSolution: true,
        installationHeight,
        listeningArea: undefined,
        wallLength: undefined,
        isFilterChanged: false,
    };
}
