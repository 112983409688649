import * as React from 'react';
import { Text, Heading } from '../../text';
import { Stack } from '../../layout';
import { Icon } from '../../ui/icon';
import { Box } from '../../containers';
import type { IExtendableComponent } from '../..';

interface IAlertProps extends IExtendableComponent {
    /**
     * The heading of the alert.
     * Will render in the color set.
     */
    heading?: string;
    /**
     * The body text of the alert.
     */
    text?: string;
    /**
     * Determines the icon to show on the alert
     */
    level: 'info' | 'warning' | 'error' | 'help';
    /**
     * The color of the icon and heading
     */
    color: 'blue' | 'yellow' | 'red';
}

/**
 * A panel containing a heading with a warning icon and text.
 */
export const Alert: React.FunctionComponent<IAlertProps> = ({
    heading,
    text,
    level,
    color,
    ...extendedProps
}) => {
    return (
        <Box {...extendedProps} color="grey2" padding="panel">
            <Stack spacing="panel">
                <Icon color={color} icon={level} opaque size="xl" />
                <Stack vertical spacing="half">
                    {heading && <Heading color={color}>{heading}</Heading>}
                    {text && <Text color="grey9">{text}</Text>}
                </Stack>
            </Stack>
        </Box>
    );
};

Alert.displayName = 'Alert';
