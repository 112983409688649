import { injectable } from 'inversify';
import { AuthenticationService } from '../../services/Authentication.service';
import {
    UserApiCommunicator,
    X_AXIS_USER_API_AUTH,
} from '../../services/user/services/UserApi.communicator';
import type { IUserDBError } from './UserDBError';
import { UserDBError } from './UserDBError';

const API_URL = `/userdata-storage`;
const SYNC_URL = `${window.location.origin}/userdata-storage/sync/`;

export interface IUserDBContext {
    application: string;
    storageUri: string;
}

@injectable()
export class UserDataStorageCommunicator {
    constructor(
        private authService: AuthenticationService,
        private userApiCommunicator: UserApiCommunicator,
    ) {}

    public async tryToGetUserDb(): Promise<IUserDBContext | null> {
        const authenticated = await this.authService.isAuthenticated();

        if (!authenticated) {
            return null;
        }

        return this.getOrCreateUserDb().catch((err) => {
            console.error('Could not establish user data db', err);
            return null;
        });
    }

    private getOrCreateUserDb(): Promise<IUserDBContext> {
        return this.getUserDb().catch((error) => {
            if (error.statusCode === 404) {
                return this.createUserDb();
            } else {
                throw error;
            }
        });
    }

    private async getUserDb(): Promise<IUserDBContext> {
        const tokens = await this.userApiCommunicator.fetchUserToken();
        const headers = new Headers();
        headers.append(X_AXIS_USER_API_AUTH, `Bearer ${tokens?.accessToken}`);

        const response = await fetch(API_URL, {
            credentials: 'include',
            headers,
        });

        const body = await response.json();

        if (response.status !== 200) {
            return Promise.reject(new UserDBError(response.status, body as IUserDBError));
        } else {
            return {
                storageUri: `${SYNC_URL}${body.storageUri}`,
                application: body.application,
            };
        }
    }

    private async createUserDb(): Promise<IUserDBContext> {
        const tokens = await this.userApiCommunicator.fetchUserToken();
        const headers = new Headers();
        headers.append(X_AXIS_USER_API_AUTH, `Bearer ${tokens?.accessToken}`);

        const response = await fetch(API_URL, {
            method: 'POST',
            credentials: 'include',
            headers,
        });

        const body = await response.json();

        if (response.status !== 201) {
            return Promise.reject(new UserDBError(response.status, body as IUserDBError));
        } else {
            return {
                storageUri: `${SYNC_URL}${body.storageUri}`,
                application: body.application,
            };
        }
    }
}
