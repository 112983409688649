import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IItemEntity, IBaseEntity } from '../../entities';
import type { IPiaSensorDevice } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { PanoramaModes } from '../../../models';

const entityIsItem = (entity: IBaseEntity): entity is IItemEntity => entity.type === 'item';

const getPanoramaMode = (piaDevice: IPiaSensorDevice | false | undefined | null): PanoramaModes => {
    if (!piaDevice) {
        return false;
    }
    return piaDevice.properties.defaultPanoramaMode ?? false;
};

/**
 * Adds default filter for panorama cameras
 */
@injectable()
export class Migration13To14 extends MigrationProviderBase {
    public from: number = 13;
    public to: number = 14;

    constructor(private piaItemService: PiaItemService<IPiaSensorDevice>) {
        super();
    }

    public provideMigration() {
        return async (entity: IBaseEntity): Promise<IBaseEntity> => {
            if (entityIsItem(entity)) {
                if (
                    entity.properties.camera &&
                    entity.properties.camera.filter.panoramaMode === undefined
                ) {
                    const piaProduct = entity.productId
                        ? this.piaItemService.get(entity.productId).first()
                        : null;
                    entity.properties.camera.filter.panoramaMode = getPanoramaMode(piaProduct);
                }
            }
            return entity;
        };
    }
}
