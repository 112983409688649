import { injectable } from 'inversify';
import type { IPiaDoorController } from 'app/core/pia';
import { PiaAccessoryCategory } from 'app/core/pia';
import { CurrentProjectService } from 'app/core/persistence';
import type {
    IDoorControllerItemEntity,
    IItemEntity,
    IPersistence,
    Id,
    ItemRelationType,
} from 'app/core/persistence';
import { BaseDeviceService } from './BaseDevice.service';
import { CategoryEnum, PiaQueriesService } from 'app/modules/common';
import type {
    IInstallationReportDoorControllerDevice,
    IInstallationBaseReportAccessory,
    IInstallationReportDoors,
} from '../../models/devices';

import { isDefined } from 'axis-webtools-util';
import { compact } from 'lodash-es';

@injectable()
export class DoorControllerDeviceService {
    constructor(
        private baseDeviceService: BaseDeviceService,
        private currentProjectService: CurrentProjectService,
        private piaQueryService: PiaQueriesService,
    ) {}

    public async mapDevice(
        item: IPersistence<IDoorControllerItemEntity>,
        piaItem: IPiaDoorController,
    ): Promise<IInstallationReportDoorControllerDevice> {
        const doorItems = this.currentProjectService.getDeviceChildren(
            item._id,
            'door',
        ) as IPersistence<IItemEntity>[];

        const doors = doorItems
            .map((doorItem) => {
                return doorItem ? this.mapDoor(doorItem) : null;
            })
            .filter(isDefined);

        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            doors,
            category: CategoryEnum.DoorControllers,
        };
    }

    private mapDoor = (item: IPersistence<IItemEntity>): IInstallationReportDoors => ({
        id: item._id,
        name: item.name,
        notes: item.notes,
        quantity: item.quantity,
        description: item.description,
        piaId: null,
        model: 'door',
        accessories: this.getAccessories(item._id),
        applications: this.getApplications(item._id),
        mounts: this.getMounts(item._id),
        category: CategoryEnum.Doors,
        door: {
            doorSideA: {
                reader: this.getDoorSideReader(item._id, 'doorSideA'),
                rex: this.getDoorSideRex(item._id, 'doorSideA'),
            },
            doorSideB: {
                reader: this.getDoorSideReader(item._id, 'doorSideB'),
                rex: this.getDoorSideRex(item._id, 'doorSideB'),
            },
        },
    });

    // this is probably not needed? We use the doorSideA and doorSideB in the door-object instead
    private getAccessories = (id: Id): IInstallationBaseReportAccessory[] => {
        const items = this.currentProjectService.getDeviceChildren(id, 'accessory');
        const doorSideA = this.currentProjectService.getDeviceChildren(id, 'doorSideA');
        const doorSideB = this.currentProjectService.getDeviceChildren(id, 'doorSideB');
        const allAccessories = items.concat(doorSideA, doorSideB);
        return compact(allAccessories.map(this.createReportAccessory));
    };

    /**
     * get the readers for the door with doorId and for the side (including other)
     * @param doorId
     * @param doorSide doorSideA or doorSideB
     * @returns readers for the door and side
     */
    private getDoorSideReader = (
        doorId: Id,
        doorSide: ItemRelationType,
    ): IInstallationBaseReportAccessory[] => {
        const children = this.currentProjectService.getDeviceChildren(doorId, doorSide);
        const readers = children.filter(
            (child) => child.properties.accessory?.category === PiaAccessoryCategory.READERS,
        );
        const readerReport = compact(readers.map(this.createReportAccessory));
        return readerReport;
    };

    /**
     * get the rex for the door with doorId and for the side (including other)
     * @param doorId
     * @param doorSide doorSideA or doorSideB
     * @returns rex devices for the door and side
     */
    private getDoorSideRex = (
        doorId: Id,
        doorSide: ItemRelationType,
    ): IInstallationBaseReportAccessory[] => {
        const children = this.currentProjectService.getDeviceChildren(doorId, doorSide);
        const rexs = children.filter(
            (child) => child.properties.accessory?.category === PiaAccessoryCategory.REX,
        );
        const rexReport = compact(rexs.map(this.createReportAccessory));

        return rexReport;
    };

    private getApplications = (id: Id): IInstallationBaseReportAccessory[] => {
        const items = this.currentProjectService.getDeviceChildren(id, 'acap');
        return compact(items.map(this.createReportAccessory));
    };

    private getMounts = (id: Id): IInstallationBaseReportAccessory[] => {
        const items = this.currentProjectService.getDeviceChildren(id, [
            'deviceMount',
            'environmentMount',
            'primaryMount',
        ]);

        return compact(items.map(this.createReportAccessory));
    };

    private createReportAccessory = (
        entity: IPersistence<IItemEntity>,
    ): IInstallationBaseReportAccessory | null => {
        if (!entity.productId && entity.properties.accessory?.category) {
            return { model: '', piaId: 0, quantity: 1 };
        }
        const piaItem = entity.productId
            ? this.piaQueryService.getPiaItemFromId(entity.productId)
            : null;
        return piaItem
            ? {
                  model: piaItem.name,
                  piaId: piaItem.id,
                  quantity: entity.quantity,
              }
            : null;
    };
}
