import * as React from 'react';
import { Modal, NoPrint, Spacer } from 'app/components';
import { CompareProducts } from './CompareProducts';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../../services';
import type { IPiaIdWithModel } from '../../models';
import { AddProductModal } from '../AddProductModal';
import type { IAddProductProps } from 'app/modules/common';
import {
    addProductToPreviouslyAdded,
    getBareboneParentRecord,
    getPiaItemsRecord,
} from 'app/modules/common';
import type { ISingularLens } from '../../selectors';
import { getEditItem, getParentDeviceId, getSingularLensToDisplay } from '../../selectors';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { getLensToAdd } from './getLensToAdd';

/**
 * Modal for showing either comparison or add product dialog when pressing add on any of the items in comparison,
 * since it was very har showing a modal in a modal.
 */
export const CompareModal: React.FC = () => {
    const [actionService] = React.useState(ServiceLocator.get(DeviceSelectorActionService));
    const showCompareModal = useSelector<IStoreState, boolean>(
        (state) => state.deviceSelector.showComparison,
    );
    const productToAdd = useSelector<IStoreState, IPiaIdWithModel | null>(
        (state) => state.deviceSelector.productToAdd,
    );
    const piaDeviceRecord = useSelector<IStoreState, Record<PiaId, IPiaItem>>(getPiaItemsRecord);
    const bareboneParentRecord = useSelector<IStoreState, Record<PiaId, IPiaItem>>(
        getBareboneParentRecord,
    );
    // Parent device id is used to determine if we are adding/editing a child device
    const parentDeviceId = useSelector<IStoreState, Id | null>(getParentDeviceId);
    const editDeviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        getEditItem,
    );
    const productToAddLens = useSelector<IStoreState, ISingularLens | undefined>((state) =>
        getSingularLensToDisplay(state, productToAdd?.productId ?? null),
    );
    const addProduct = (addProductProps: IAddProductProps) => {
        if (!productToAdd) {
            return;
        }
        const lens = getLensToAdd(
            productToAddLens?.piaId ?? undefined,
            productToAdd.productId,
            piaDeviceRecord,
            bareboneParentRecord,
        );
        parentDeviceId
            ? actionService.addOrUpdateChildDevice(
                  productToAdd.productId,
                  parentDeviceId,
                  undefined,
                  addProductProps,
                  lens,
              )
            : actionService.addOrUpdateDevice(
                  productToAdd.productId,
                  undefined,
                  addProductProps,
                  lens,
              );
        addProductToPreviouslyAdded(productToAdd.productId);
        hideAddProductModal();
    };

    const hideAddProductModal = () => actionService.setProductToAdd(null);

    const onChangeDevice = (productId: PiaId, lensPiaId?: PiaId) => {
        const lens = getLensToAdd(lensPiaId, productId, piaDeviceRecord, bareboneParentRecord);
        actionService.showComparison(false);
        parentDeviceId
            ? actionService.addOrUpdateChildDevice(
                  productId,
                  parentDeviceId,
                  editDeviceItem,
                  undefined,
                  lens,
              )
            : actionService.addOrUpdateDevice(productId, editDeviceItem, undefined, lens);
        addProductToPreviouslyAdded(productId);
    };

    if (productToAdd) {
        return (
            <AddProductModal
                productInfo={{
                    productId: productToAdd.productId,
                    name: productToAdd.model,
                }}
                parentDeviceId={parentDeviceId}
                onClose={hideAddProductModal}
                onAddProduct={addProduct}
            />
        );
    }

    if (showCompareModal) {
        return (
            <Modal printButton color="white" onClose={() => actionService.showComparison(false)}>
                <NoPrint>
                    <Spacer />
                </NoPrint>
                <CompareProducts onChangeDevice={onChangeDevice} />
            </Modal>
        );
    }

    return null;
};

CompareModal.displayName = 'CompareModal';
