import * as React from 'react';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';

import type { IBodyWornCameraProfile, Id } from 'app/core/persistence';
import { ItemService, Resolution } from 'app/core/persistence';

import type { ISupportedWithMessage } from 'app/modules/common';
import {
    NotSupportedText,
    ProfileEditorRow,
    ProfileSupportService,
    getBodyWornFpsSpecificResolutions,
    getBodyWornMaxResolution,
} from 'app/modules/common';

import type { IOptionProps } from 'app/components';
import { Stack, Heading, Box, Select } from 'app/components';

import { ServiceLocator } from 'app/ioc';

import { constants } from 'app/modules/common/profile/constants';
import type { FpsSpecificResolutions } from 'app/core/pia';

interface IResolutionSelectorProps {
    deviceId: Id;
    profile: IBodyWornCameraProfile;
}

export const ResolutionSelector: React.FC<IResolutionSelectorProps> = ({ deviceId, profile }) => {
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));
    const deviceMaxResolution = useSelector<IStoreState, Resolution>((state) =>
        getBodyWornMaxResolution(state, deviceId),
    );
    const deviceFpsSpecificResolutions = useSelector<IStoreState, FpsSpecificResolutions>((state) =>
        getBodyWornFpsSpecificResolutions(state, deviceId),
    );
    const recordingResolution = new Resolution(profile.resolution);
    const [resolutionSupport, setResolutionSupport] = React.useState<ISupportedWithMessage>(
        ProfileSupportService.isResolutionSupported(
            recordingResolution,
            deviceMaxResolution,
            deviceFpsSpecificResolutions,
        ),
    );

    const getResolutionOptions = (): IOptionProps[] => {
        return [
            {
                text: t.profilesRecordingSettingsCameraMaxResolution,
                value: `${Number.MAX_SAFE_INTEGER}x${Number.MAX_SAFE_INTEGER}`,
            },
            {
                text: '1080p (2MP)',
                value: '1920x1080',
            },
            {
                text: '720p (1MP)',
                value: '1280x720',
            },
        ];
    };

    const onChangeResolution = (newResolution: string) => {
        const newRecordingResolution = new Resolution(newResolution);
        const newResolutionSupport = ProfileSupportService.isResolutionSupported(
            newRecordingResolution,
            deviceMaxResolution,
            deviceFpsSpecificResolutions,
        );
        setResolutionSupport(newResolutionSupport);
        itemService.updateWearableProfile(deviceId, { resolution: newResolution });
    };

    return (
        <ProfileEditorRow>
            <Stack vertical spacing="half">
                <Heading>{t.installationReportSettingsResolution}</Heading>
                <Stack>
                    <Box width={constants.leftColumnWidth} />
                    <Box flex="shrinkAndGrow">
                        <Stack spacing="quart">
                            <Box alignItems="center">
                                <Select
                                    value={profile.resolution}
                                    options={getResolutionOptions()}
                                    onChange={onChangeResolution}
                                />
                            </Box>
                            <NotSupportedText supportedWithMessage={resolutionSupport} />
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </ProfileEditorRow>
    );
};

ResolutionSelector.displayName = 'ResolutionSelector';
