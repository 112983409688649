import * as React from 'react';
import { Border, Box, IconButton, Positioned } from 'app/components';
import { t } from 'app/translate';
import { css } from '@emotion/css';
import type { TransitionStatus } from 'react-transition-group';
import { Transition } from 'react-transition-group';
import type { Property } from 'csstype';
import type { IAutoTestable } from 'app/components';

const duration = 300;

const rotateStyle: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: css`
        transition: transform;
        transform: rotate(0);
        [dir='rtl'] & {
            transform: rotate(180deg);
        }
    `,
    entering: css`
        transition: transform;
        transform: rotate(0);
        [dir='rtl'] & {
            transform: rotate(180deg);
        }
    `,
    entered: css`
        transition: transform ${duration}ms;
        transform: rotate(90deg);
        [dir='rtl'] & {
            transform: rotate(90deg);
        }
    `,
    exiting: css`
        transition: transform ${duration}ms;
        transform: rotate(0);
        [dir='rtl'] & {
            transform: rotate(180deg);
        }
    `,
    exited: css`
        transition: transform;
        transform: rotate(0);
        [dir='rtl'] & {
            transform: rotate(180deg);
        }
    `,
};

interface IExpandButtonProps extends IAutoTestable {
    hideExpandButton?: boolean;
    isOpen: boolean;
    onToggle: (isOpen: boolean) => void;
}

export const ExpandButton: React.FC<IExpandButtonProps> = ({
    hideExpandButton: hideExpandButton,
    isOpen,
    onToggle,
    testId,
}) => {
    if (hideExpandButton) return null;

    return (
        <Positioned insetInlineStart="-16px" position="relative" sendForward>
            <Box alignItems="center" height="100%">
                <Border radius="50%" color="grey3" width={1}>
                    <Box color="white" alignItems="center">
                        <Transition in={isOpen} timeout={0}>
                            {(state) => (
                                <IconButton
                                    __htmlAttributes={{ className: rotateStyle[state] }}
                                    icon="keyboard_arrow_right"
                                    testId={testId}
                                    title={t.expandFilterPanel}
                                    onClick={() => {
                                        onToggle(!isOpen);
                                    }}
                                />
                            )}
                        </Transition>
                    </Box>
                </Border>
            </Box>
        </Positioned>
    );
};
