import * as React from 'react';
import { Border, Box, DropDown, DropDownMenuButton, IconText, Text } from 'app/components';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { getPartnerProductSortOrder } from '../selectors';
import { getIsOtherSelectorOpen, getIsStandalone } from 'app/modules/common';
import { AddonSelectorActionService } from '../services';

interface ISortPartnerProductsProps {
    foundApplications: number;
}

type sortOption = 'byPopularity' | 'byVendor';
const sortOptions: sortOption[] = ['byPopularity', 'byVendor'];

export const SortPartnerProducts: React.FunctionComponent<ISortPartnerProductsProps> = ({
    foundApplications,
}) => {
    const actionService = useService(AddonSelectorActionService);
    const sortOrder = useSelector(getPartnerProductSortOrder);
    const isStandalone = useSelector(getIsStandalone);
    const isPartnerProductOtherSelector = useSelector(getIsOtherSelectorOpen);
    const getNbrFoundText =
        isStandalone || isPartnerProductOtherSelector
            ? t.productsFound(foundApplications)
            : t.applicationsFound(foundApplications);

    return (
        <Box paddingY="base" width="100%">
            <Border bottomWidth={1} color="grey3">
                <Box paddingBottom="quart" width="100%" justifyContent="between">
                    <Box paddingRight="base">
                        <Text color="grey6" style="semibold">
                            {getNbrFoundText}
                        </Text>
                    </Box>
                    <DropDown
                        openInPortal={!isStandalone}
                        trigger={
                            <IconText
                                testId="sort_by"
                                icon="arrow_down"
                                textProps={{ color: 'blue', semiBold: true }}
                                iconProps={{ opaque: true, color: 'blue', size: 'sm' }}
                                alignIconRight
                            >
                                {t.sortOrderGROUP[sortOrder]}
                            </IconText>
                        }
                        contents={sortOptions.map((sortOption) => {
                            return (
                                <DropDownMenuButton
                                    testId={t.sortOrderGROUP[sortOption]}
                                    label={t.sortOrderGROUP[sortOption]}
                                    selected={sortOption === sortOrder}
                                    onClick={() =>
                                        actionService.updatePartnerProductFilter({
                                            sortOrder: sortOption,
                                        })
                                    }
                                />
                            );
                        })}
                    />
                </Box>
            </Border>
        </Box>
    );
};

SortPartnerProducts.displayName = 'ProductSelectorHeader';
