import { t } from 'app/translate';
import * as React from 'react';
import { FilterPanel } from './FilterPanel';
import { Action, Checkbox, Grid, PillButton, Stack, Text } from 'app/components';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import type { UnitSystem, CameraType } from 'app/core/persistence';
import { LightConditions, getDefaultCameraFilterEntity } from 'app/core/persistence';
import type { IDesiredCamera } from 'app/modules/common';
import {
    desiredCameraUtils,
    getCurrentProjectCustomInstallationHeight,
    getCurrentProjectUnitSystem,
} from 'app/modules/common';
import { SearchField } from './common';
import { AdvancedFilters } from './AdvancedFilters.container';
import { CameraTypeFilter } from './CameraTypeFilter.component';
import { ApplicationSupportFilter } from './ApplicationSupportFilter';
import { SceneFilterSettingsContainer } from './scene/SceneFilterSettings.container';
import { css } from '@emotion/css';
import { LightConditionsFilter } from './LightConditionsFilter.component';
import { SustainabilityFilter } from './SustainabilityFilter';

export const CameraFilterPanel: React.FC = () => {
    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const desiredCamera = useSelector<IStoreState, IDesiredCamera>(
        (store) => store.deviceSelector.cameraFilter.desiredCamera,
    );

    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);
    const installationHeight = useSelector<IStoreState, number>((store) =>
        getCurrentProjectCustomInstallationHeight(store),
    );
    const unitSystem = useSelector<IStoreState, UnitSystem>((store) =>
        getCurrentProjectUnitSystem(store),
    );

    const updateDesiredCamera = actionService.updateDesiredCamera;

    const updateDesiredFilter = (desiredCameraUpdates: Partial<IDesiredCamera>) => {
        updateDesiredCamera(desiredCameraUpdates);
    };

    const updateCameraTypes = (cameraType: CameraType) => {
        if (desiredCamera.cameraTypes.includes(cameraType)) {
            updateDesiredCamera({
                cameraTypes: [],
            });
        } else {
            updateDesiredCamera({
                cameraTypes: [cameraType],
            });
        }
    };

    const defaultDesiredCamera = desiredCameraUtils.convertPropertiesToDesiredCamera(
        getDefaultCameraFilterEntity(unitSystem, installationHeight),
    );

    /**
     * Resets camera filter to its default values.
     * Also sets includeDiscontinued to false and clears search text.
     */
    const resetFilter = () => {
        actionService.setDefaultCameraFilter();

        if (searchText) {
            actionService.setSearchText('');
        }
    };

    const resetSceneSettings = () => {
        if (desiredCamera.isSceneFilterActive !== defaultDesiredCamera.isSceneFilterActive) {
            actionService.updateDesiredCamera({
                isSceneFilterActive: defaultDesiredCamera.isSceneFilterActive,
            });
        }
        if (desiredCamera.horizontalFOVRadians !== defaultDesiredCamera.horizontalFOVRadians) {
            actionService.updateDesiredCamera({
                horizontalFOVRadians: defaultDesiredCamera.horizontalFOVRadians,
            });
        }
        if (desiredCamera.distanceToTarget !== defaultDesiredCamera.distanceToTarget) {
            actionService.updateDesiredCamera({
                distanceToTarget: defaultDesiredCamera.distanceToTarget,
            });
        }
        if (desiredCamera.pixelDensity !== defaultDesiredCamera.pixelDensity) {
            actionService.updateDesiredCamera({
                pixelDensity: defaultDesiredCamera.pixelDensity,
            });
        }
        if (desiredCamera.installationHeight !== defaultDesiredCamera.installationHeight) {
            actionService.updateDesiredCamera({
                installationHeight: defaultDesiredCamera.installationHeight,
            });
        }
        if (desiredCamera.targetHeight !== defaultDesiredCamera.targetHeight) {
            actionService.updateDesiredCamera({
                targetHeight: defaultDesiredCamera.targetHeight,
            });
        }
    };

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                <Stack alignItems="center" justifyContent="between">
                    <Text style="semibold" color="grey6">
                        {t.cameraSelectorRequirementsTitle}
                    </Text>
                    <Action title={t.reset} onClick={resetFilter} />
                </Stack>
                <Grid spacing="halfQuart">
                    <CameraTypeFilter
                        selectedCameraType={desiredCamera.cameraTypes[0]}
                        onClick={updateCameraTypes}
                    />
                    <PillButton
                        checkable
                        text={t.outdoor}
                        selected={desiredCamera.outdoor}
                        onClick={() => updateDesiredCamera({ outdoor: !desiredCamera.outdoor })}
                    />
                    <PillButton
                        testId="corridor_btn"
                        checkable
                        text={t.cameraSelectorFieldOfViewCorridor}
                        selected={desiredCamera.corridorFormat}
                        onClick={() =>
                            updateDesiredCamera({
                                corridorFormat: !desiredCamera.corridorFormat,
                            })
                        }
                    />
                    <PillButton
                        checkable
                        text={t.cameraSelectorFieldOfViewBacklight}
                        selected={desiredCamera.lightConditions.some(
                            (condition) => condition === LightConditions.BACKLIGHT,
                        )}
                        onClick={() =>
                            desiredCamera.lightConditions.some(
                                (condition) => condition === LightConditions.BACKLIGHT,
                            )
                                ? updateDesiredCamera({
                                      lightConditions: desiredCamera.lightConditions.filter(
                                          (condition) => condition !== LightConditions.BACKLIGHT,
                                      ),
                                  })
                                : updateDesiredCamera({
                                      lightConditions: [
                                          ...desiredCamera.lightConditions,
                                          LightConditions.BACKLIGHT,
                                      ],
                                  })
                        }
                    />
                    <LightConditionsFilter
                        selectedConditions={desiredCamera.lightConditions}
                        onClick={(lightConditions) => updateDesiredCamera({ lightConditions })}
                    />
                    <SustainabilityFilter />
                    <ApplicationSupportFilter />
                    <AdvancedFilters />
                </Grid>
            </Stack>
            <Stack vertical testId="device_filter">
                <Stack justifyContent="between" alignItems="center">
                    <Checkbox
                        slider
                        selected={Boolean(desiredCamera.isSceneFilterActive)}
                        onChange={() =>
                            updateDesiredCamera({
                                isSceneFilterActive: !desiredCamera.isSceneFilterActive,
                            })
                        }
                        testId="scene_requirements"
                    >
                        {t.sceneRequirements}
                    </Checkbox>
                    <Action title={t.reset} onClick={resetSceneSettings} />
                </Stack>
                <div
                    className={css`
                        opacity: ${desiredCamera.isSceneFilterActive ? 100 : 54}%;
                    `}
                >
                    <SceneFilterSettingsContainer updateFilter={updateDesiredFilter} />
                </div>
            </Stack>
        </FilterPanel>
    );
};
