import { Box, Text } from 'app/components';
import { t } from 'app/translate';
import * as React from 'react';

export const NoRecommendations: React.FC = () => {
    return (
        <Box width="100%" justifyContent="center" height="100%">
            <Box
                direction="column"
                spacing="base"
                width="80%"
                justifyContent="center"
                alignItems="center"
            >
                <Text color="grey8">{t.genetecGROUP.noSolutions}</Text>
            </Box>
        </Box>
    );
};

NoRecommendations.displayName = 'NoRecommendations';
