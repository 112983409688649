import { DropDownMenu, DropDownMenuButton, DropDownMenuDelete } from 'app/components';
import type { IInstallationPointEntity, IItemEntity, IPersistence } from 'app/core/persistence';
import { InstallationPointService } from 'app/core/persistence';

import { useService } from 'app/ioc';
import { getIsChildDevice } from 'app/modules/common';

import { ProjectDevicesService } from 'app/modules/projectDevices/services';
import type { IStoreState } from 'app/store';

import { t } from 'app/translate';
import React from 'react';
import { useSelector } from 'react-redux';

interface IInstallationPointDropDown {
    device: IPersistence<IItemEntity>;
    installationPoint: IInstallationPointEntity;
    indexName: string;
}

export const InstallationPointDropDownMenu: React.FunctionComponent<IInstallationPointDropDown> = ({
    device,
    installationPoint,
    indexName,
}) => {
    const installationPointService = useService(InstallationPointService);
    const projectDevicesService = useService(ProjectDevicesService);
    const isChildDevice = useSelector<IStoreState, boolean>((state) =>
        getIsChildDevice(state, device._id),
    );

    const onDeleteInstallationPoint = async () => {
        installationPointService.removeInstallationPoint(
            installationPoint._id,
            installationPoint._rev,
        );
    };

    const onMakeUnique = async () => {
        projectDevicesService.makeUnique(installationPoint, device._id, device.quantity);
    };

    return (
        <DropDownMenu openInPortal testId={`ip_drop_down_${device.name}_${indexName}`}>
            <DropDownMenuButton
                disabled={device.quantity <= 1 || isChildDevice}
                label={t.makeUnique}
                icon="make_unique"
                onClick={onMakeUnique}
                testId={`make_unique_${device.name}_${indexName}`}
            />

            <DropDownMenuDelete
                testId="delete_device"
                disabled={false}
                onDelete={onDeleteInstallationPoint}
            />
        </DropDownMenu>
    );
};

InstallationPointDropDownMenu.displayName = 'InstallationPointDropDownMenu';
