import type { IWithChildren } from 'app/components';
import { MessageModal } from 'app/components';
import { eventTracking } from 'app/core/tracking';
import { t } from 'app/translate';
import type { ErrorInfo } from 'react';
import React from 'react';

interface IErrorBoundaryLazyState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
    modalClosed: boolean;
}

interface IErrorBoundaryLazyProps extends IWithChildren {
    onClose(): void;
    title: string;
}

class ErrorBoundaryLazy extends React.Component<IErrorBoundaryLazyProps, IErrorBoundaryLazyState> {
    constructor(props: IErrorBoundaryLazyProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
            modalClosed: false,
        };
    }

    static getDerivedStateFromError(error: string) {
        return { hasError: true, error: error, modalClosed: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo });
        eventTracking.logError(
            `Failed loading partner applications Error: ${error} ErrorInfo: ${errorInfo}`,
            'AddPartnerApplication',
        );
    }

    render() {
        if (this.state.hasError && !this.state.modalClosed) {
            return (
                <MessageModal
                    title={this.props.title}
                    message={t.projectShareErrorUnknownNetworkError}
                    buttonText={t.close}
                    onClose={() => this.closeModal()}
                />
            );
        }

        return this.props.children;
    }

    private closeModal = () => {
        this.setState({ modalClosed: true, hasError: false, error: null, errorInfo: null });
        this.props.onClose();
    };
}

export default ErrorBoundaryLazy;
