import * as React from 'react';
import { css, cx } from '@emotion/css';
import { NavLink } from 'react-router-dom';
import type { Icons } from '../icon';
import { Icon } from '../icon';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { ColorsEnum } from 'app/styles';
import { appBold } from 'app/styles/defaults';
import { Box } from '../../containers/box/Box.component';

const addAnimation = css`
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 48px;
    color: ${ColorsEnum.white};

    i {
        margin: 0 !important;
        opacity: 0;
        animation: rotateIn 1s ease-in-out;
    }

    @keyframes rotateIn {
        0%,
        20% {
            opacity: 0;
            scale: 0;
            transform: rotate(0);
        }

        40%,
        60% {
            scale: 1;
            opacity: 0.8;
            transform: rotate(360deg);
        }

        80%,
        100% {
            scale: 0;
            opacity: 0;
            transform: rotate(0);
        }
    }
`;

const animateBackground = css`
    animation: backgroundFlash 1s ease-in-out;

    @keyframes backgroundFlash {
        0% {
            box-shadow: 0 0 0 0 ${ColorsEnum.green};
            z-index: 1;
        }

        50% {
            color: white;
            background: ${ColorsEnum.green};
            box-shadow: 0 0 5px 5px ${ColorsEnum.green};
            z-index: 1;
        }

        100% {
            box-shadow: 0 0 10px 10px ${ColorsEnum.transparent};
            z-index: unset;
        }
    }
`;

const MenuItemStyle = css`
    color: ${ColorsEnum.grey7};
    font-weight: ${appBold};
    padding: 12px 20px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    & > * {
        vertical-align: middle;
    }
    &[aria-current='page'] {
        color: ${ColorsEnum.grey9};
        border-inline-end: 4px solid ${ColorsEnum.yellow};
    }
    &[aria-current='page'] i {
        opacity: 1;
    }
    i {
        margin-inline-end: 22px;
    }
`;

const textStyle = css`
    display: inline-block;
`;

const OverlayIcon = css`
    position: absolute;
    inset-inline-start: 38px;
    top: 5px;
    animation: bounceIn 500ms;

    @keyframes bounceIn {
        from,
        20%,
        40%,
        60%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        20% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
        }

        40% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
        }

        80% {
            -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
        }

        to {
            opacity: 1;
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
`;

export interface IMenuItem extends IAutoTestable {
    /**
     * The link of the menu item
     */
    link: string;
    /**
     * The icon of the menu item
     */
    icon?: Icons;
    /**
     * The text of the menu item
     */
    text: string;
    /**
     * Display a small error-icon in the corner, on top of, the other icon
     */
    warn?: boolean;
    /**
     * Show animation when a product was added
     */
    animateOnAdd?: boolean;
}

export const MenuItem: React.FunctionComponent<IMenuItem> = ({
    link,
    icon,
    text,
    testId,
    warn,
    animateOnAdd,
}) => (
    <li>
        <NavLink
            data-test-id={toTestIdFormat(testId)}
            className={cx(MenuItemStyle, animateOnAdd ? animateBackground : undefined)}
            to={link}
        >
            {icon && <Icon icon={icon} />}
            {icon && warn && (
                <div className={OverlayIcon}>
                    <Icon opaque icon="error" color="red" size="xs" />
                </div>
            )}
            <span className={textStyle}>{text}</span>

            {animateOnAdd && (
                <div className={addAnimation}>
                    <Box alignItems="center" height="100%" justifyContent="center" width="48px">
                        <Icon icon="add_circle" />
                    </Box>
                </div>
            )}
        </NavLink>
    </li>
);

MenuItem.displayName = 'MenuItem';
