import { formatMultiValues, NUMBER_FORMAT_PRECISION, formatValue } from './formatHelper';
const UNIT_STORAGE = ['MB', 'GB', 'TB', 'PB'];
const UNIT_BANDWIDTH = ['bit/s', 'kbit/s', 'Mbit/s', 'Gbit/s', 'Tbit/s'];
const UNIT_POWER = ['W', 'kW', 'MW', 'GW', 'TW', 'PW'];
const UNIT_ENERGY = ['Wh', 'kWh', 'MWh', 'GWh', 'TWh', 'PWh'];

export const format = {
    bandwidth(bitsPerSecond: number, significantFigures: number = NUMBER_FORMAT_PRECISION): string {
        // This initial toPrecision is needed due to that the value could get rounded
        bitsPerSecond = parseFloat(bitsPerSecond.toPrecision(significantFigures));
        let negativeHandling = 1;
        if (bitsPerSecond < 0) {
            negativeHandling = -1;
            bitsPerSecond = bitsPerSecond * negativeHandling;
        }
        let exponent = Math.floor(Math.log(bitsPerSecond) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // bit/s
        }
        if (exponent > 4) {
            exponent = 4; // Tbit/s
        }
        const val = bitsPerSecond / Math.pow(1000, exponent);
        return `${(val * negativeHandling).toPrecision(significantFigures)} ${
            UNIT_BANDWIDTH[exponent]
        }`;
    },

    storage(megaBytes: number, significantFigures: number = NUMBER_FORMAT_PRECISION): string {
        let negativeHandling = 1;

        // This initial toPrecision is needed due to that the value could get rounded
        megaBytes = parseFloat(megaBytes.toPrecision(significantFigures));
        if (megaBytes < 0) {
            negativeHandling = -1;
            megaBytes = megaBytes * negativeHandling;
        }
        let exponent = Math.floor(Math.log(megaBytes) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // MB
        }
        if (exponent > 3) {
            exponent = 3; // PB
        }
        const val = megaBytes / Math.pow(1000, exponent);
        return `${(val * negativeHandling).toPrecision(significantFigures)} ${
            UNIT_STORAGE[exponent]
        }`;
    },

    /**
     * Returns the power in correct unit (TW, GW, MW, kW or W) and with correct decimals.
     *
     * @param power A numeric value for power
     * @param significantFigures Optional (Default: 3) - How many significant figures the result should provide
     */
    power(power: number, significantFigures: number = NUMBER_FORMAT_PRECISION): string {
        // This initial toPrecision is needed due to that the value could get rounded
        power = parseFloat(power.toPrecision(significantFigures));
        let negativeHandling = 1;
        if (power < 0) {
            negativeHandling = -1;
            power = power * negativeHandling;
        }

        let exponent = Math.floor(Math.log(power) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // W
        }
        if (exponent > 5) {
            exponent = 5; // PW
        }
        const val = power / Math.pow(1000, exponent);
        return `${(val * negativeHandling).toPrecision(significantFigures)} ${
            UNIT_POWER[exponent]
        }`;
    },

    /**
     * Returns the power in correct unit (TWh, GWh, MWh, kWh or Wh) and with correct decimals.
     *
     * @param energy A numeric value for energy
     * @param significantFigures Optional (Default: 3) - How many significant figures the result should provide
     */
    energy(energy: number, significantFigures: number = NUMBER_FORMAT_PRECISION): string {
        // This initial toPrecision is needed due to that the value could get rounded
        energy = parseFloat(energy.toPrecision(significantFigures));
        let negativeHandling = 1;
        if (energy < 0) {
            negativeHandling = -1;
            energy = energy * negativeHandling;
        }

        let exponent = Math.floor(Math.log(energy) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // W
        }
        if (exponent > 5) {
            exponent = 5; // PW
        }
        const val = energy / Math.pow(1000, exponent);
        return `${(val * negativeHandling).toPrecision(significantFigures)} ${
            UNIT_ENERGY[exponent]
        }`;
    },

    bandwidthRequirements(
        value1: number,
        value2: number,
        significantFigures: number = NUMBER_FORMAT_PRECISION,
    ): string {
        let maxValue = Math.max(value1, value2);
        // This initial toPrecision is needed due to that the value could get rounded
        maxValue = parseFloat(maxValue.toPrecision(significantFigures));

        let exponent = Math.floor(Math.log(maxValue) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // bit/s
        }
        if (exponent > 4) {
            exponent = 4; // Tbit/s
        }
        const formattedValue1 = value1 / Math.pow(1000, exponent);
        const formattedValue2 = value2 / Math.pow(1000, exponent);

        return formatMultiValues(
            formattedValue1,
            formattedValue2,
            UNIT_BANDWIDTH[exponent],
            significantFigures,
        );
    },

    storageRequirements(
        value1: number,
        value2: number,
        significantFigures: number = NUMBER_FORMAT_PRECISION,
    ): string {
        let maxValue = Math.max(value1, value2);
        // This initial toPrecision is needed due to that the value could get rounded
        maxValue = parseFloat(maxValue.toPrecision(significantFigures));
        let exponent = Math.floor(Math.log(maxValue) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // MB
        }
        if (exponent > 3) {
            exponent = 3; // PB
        }
        const formattedValue1 = value1 / Math.pow(1000, exponent);
        const formattedValue2 = value2 / Math.pow(1000, exponent);
        return formatMultiValues(
            formattedValue1,
            formattedValue2,
            UNIT_STORAGE[exponent],
            significantFigures,
        );
    },

    powerRequirements(
        value1: number,
        value2: number,
        significantFigures: number = NUMBER_FORMAT_PRECISION,
    ): string {
        let maxValue = Math.max(value1, value2);
        // This initial toPrecision is needed due to that the value could get rounded
        maxValue = parseFloat(maxValue.toPrecision(significantFigures));
        let exponent = Math.floor(Math.log(maxValue) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // W
        }
        if (exponent > 5) {
            exponent = 5; // TW
        }
        const formattedValue1 = value1 / Math.pow(1000, exponent);
        const formattedValue2 = value2 / Math.pow(1000, exponent);

        return formatMultiValues(
            formattedValue1,
            formattedValue2,
            UNIT_POWER[exponent],
            significantFigures,
        );
    },

    /**
     * Returns the output for poePower (either addedPower or requiredPower) in correct unit (TW, GW, MW, kW or W) and with correct decimals.
     *
     * @param addedPower A numeric value of the added powPower
     * @param requiredPower A numeric value of the required poePower
     * @param returnAddedPower If true addedPower will be returned else requiredPower
     * @param significantFigures Optional (Default: 3) - How many significant figures the result should provide
     */
    poePower(
        addedPower: number,
        requiredPower: number,
        returnAddedPower: boolean,
        significantFigures: number = NUMBER_FORMAT_PRECISION,
    ): string {
        // This initial toPrecision is needed due to that the value could get rounded
        let maxValue = Math.max(addedPower, requiredPower);
        maxValue = parseFloat(maxValue.toPrecision(significantFigures));
        const returnNumber = returnAddedPower ? addedPower : requiredPower;
        let exponent = Math.floor(Math.log(maxValue) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // W
        }
        if (exponent > 5) {
            exponent = 5; // TW
        }
        const val = returnNumber / Math.pow(1000, exponent);

        return formatValue(val, UNIT_POWER[exponent], significantFigures);
    },

    /**
     * Returns the output for storage (either addedStorage or requiredStorage) in correct unit (PB, TB, GB or MB) and with correct decimals.
     *
     * @param addedStorage A numeric value of the added storage in MB
     * @param requiredStorage A numeric value of the required storage in MB
     * @param returnAddedStorage If true addedStorage will be returned else requiredStorage
     * @param significantFigures Optional (Default: 3) - How many significant figures the result should provide
     */
    storageRequirementsOneValue(
        addedStorage: number,
        requiredStorage: number,
        returnAddedStorage: boolean,
        significantFigures: number = NUMBER_FORMAT_PRECISION,
    ): string {
        let maxValue = Math.max(addedStorage, requiredStorage);
        // This initial toPrecision is needed due to that the value could get rounded
        maxValue = parseFloat(maxValue.toPrecision(significantFigures));
        const returnNumber = returnAddedStorage ? addedStorage : requiredStorage;
        let exponent = Math.floor(Math.log(maxValue) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // MB
        }
        if (exponent > 3) {
            exponent = 3; // PB
        }
        const val = returnNumber / Math.pow(1000, exponent);

        return formatValue(val, UNIT_STORAGE[exponent], significantFigures);
    },

    /**
     * Returns the output for bandwidth (either addedBandwidth or requiredBandwidth) in correct unit (Tb/s, Gb/s, Mb/s, kb/s or bit/s) and with correct decimals.
     *
     * @param addedBandwidth A numeric value of the added bandwidth
     * @param requiredBandwidth A numeric value of the required bandwidth
     * @param returnAddedBandwidth If true addedBandwidth will be returned else requiredBandwidth
     * @param significantFigures Optional (Default: 3) - How many significant figures the result should provide
     */
    bandwidthRequirementsOneValue(
        addedBandwidth: number,
        requiredBandwidth: number,
        returnAddedBandwidth: boolean,
        significantFigures: number = NUMBER_FORMAT_PRECISION,
    ): string {
        let maxValue = Math.max(addedBandwidth, requiredBandwidth);
        // This initial toPrecision is needed due to that the value could get rounded
        maxValue = parseFloat(maxValue.toPrecision(significantFigures));
        const returnNumber = returnAddedBandwidth ? addedBandwidth : requiredBandwidth;
        let exponent = Math.floor(Math.log(maxValue) / Math.log(1000));
        if (exponent < 0) {
            exponent = 0; // bit/s
        }
        if (exponent > 4) {
            exponent = 4; // Tbit/s
        }
        const val = returnNumber / Math.pow(1000, exponent);
        return formatValue(val, UNIT_BANDWIDTH[exponent], significantFigures);
    },

    money(
        value: number,
        locale: string,
        currency: string,
        showCurrency: boolean = false,
        hideFractions: boolean = false,
    ): string {
        const localeOptions: Intl.NumberFormatOptions = {
            style: 'currency',
            currencyDisplay: showCurrency ? 'symbol' : 'code',
            currency,
            minimumFractionDigits: hideFractions ? 0 : undefined,
        };

        const priceString = new Intl.NumberFormat(locale, localeOptions).format(value);

        return showCurrency ? priceString : priceString.replace(currency.toUpperCase(), '');
    },
};
