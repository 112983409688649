import * as React from 'react';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { ParentChildInstallationPointService } from 'app/core/persistence';
import { ChildItemService } from 'app/modules/common';
import { AddDoorButton, Door } from './components';
import { Box, Stack } from 'app/components';
import { DoorsHeader } from './components/DoorsHeader';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { getDoorsForProduct, getCanAddDoor } from './selectors';
import { ServiceLocator, useService } from 'app/ioc';

interface IDoorsProps {
    deviceItemId: Id;
}

export const Doors: React.FunctionComponent<IDoorsProps> = ({ deviceItemId }) => {
    const [parentChildInstallationPointService] =
        React.useState<ParentChildInstallationPointService>(
            ServiceLocator.get(ParentChildInstallationPointService),
        );

    const childItemService = useService(ChildItemService);

    const doors = useSelector<IStoreState, IPersistence<IItemEntity>[]>((state) =>
        getDoorsForProduct(state, deviceItemId),
    );

    const canAddMoreDoors = useSelector<IStoreState, boolean>((state) =>
        getCanAddDoor(state, deviceItemId),
    );

    const onAddDoor = async () => {
        const addedItem = await childItemService.addDoor(deviceItemId);
        parentChildInstallationPointService.addChildItemToMap(addedItem._id, 1, deviceItemId);
    };

    return (
        <Stack vertical spacing="panel">
            <DoorsHeader deviceItemId={deviceItemId} />
            {doors.map((door) => (
                <Door key={door._id} deviceItemId={deviceItemId} doorItemId={door._id} />
            ))}
            <Box alignItems="end" justifyContent={'center'}>
                <AddDoorButton onClick={onAddDoor} disabled={!canAddMoreDoors} />
            </Box>
        </Stack>
    );
};

Doors.displayName = 'Doors';
