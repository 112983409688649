import type { IPiaItemVersion, IPiaLocation } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import { intersection } from 'lodash-es';

/**
 * Remove all multi pack versions
 */
export const filterOutMultiPacks = (version: IPiaItemVersion) => version.scaleQuantity === 1;

/**
 * Only return externally announced versions
 */
export const filterAllButExternallyAnnounced = (version: IPiaItemVersion) =>
    version.state === PiaItemState.EXTERNALLY_ANNOUNCED;

/**
 * Get the version matching the provided project location
 */
export const hasMatchingVersion = (projectLocation: IPiaLocation) => (version: IPiaItemVersion) => {
    return intersection(version.versions, projectLocation.productVersions).length > 0;
};
