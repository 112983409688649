import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IPersistableRangeType } from 'app/core/persistence';
import { Box, Clickable, Icon, MultiRange, Stack, Text } from 'app/components';
import { t } from 'app/translate';
import { getCurrentProjectNetworkSettings } from '../../project';
import { getTotalAvailableIPAddresses } from '../selectors';
import { IPRangeIcon } from './IPRangeSettings/IPRangeIcon';
import { ipAccordionStyle, ipExpandButtonStyle } from './IPRangeSettings/ipRangeStyleUtils';
import { IPRangeSlider } from './IPRangeSettings/IPRangeSlider';
import { getReservedRangesInit } from '../selectors/getReservedRangesInit';
import { getNotUsedRanges } from './getNotUsedRanges';
import { usePersistedState } from 'app/hooks';

export interface IIPRangeState {
    type: Readonly<IPersistableRangeType>;
    min: number;
    max: number;
}

const RANGE_MIN = 1;

/** Component where reserved IP ranges for certain device types can be edited. */
export const IPRangeSettings: React.FC = () => {
    const projectNetwork = useSelector(getCurrentProjectNetworkSettings);
    const rangeSize = useSelector(getTotalAvailableIPAddresses);
    const initValues = useSelector(getReservedRangesInit);
    const [focusedSlider, setFocusedSlider] = React.useState<IPersistableRangeType | undefined>();
    //** Used to check if rangeSize has updated */
    const projectRangeRef = React.useRef({
        start: projectNetwork?.ipStart,
        end: projectNetwork?.ipEnd,
    });

    const [expanded, setExpanded] = usePersistedState('IpRangesExpanded', false);

    const [cameraRange, setCameraRange] = React.useState<IIPRangeState>({
        type: 'cameras',
        min: initValues.cameras.min,
        max: initValues.cameras.max,
    });
    const [otherRange, setOtherRange] = React.useState<IIPRangeState>({
        type: 'other',
        min: initValues.other.min,
        max: initValues.other.max,
    });
    const [recorderRange, setRecorderRange] = React.useState<IIPRangeState>({
        type: 'recorders',
        min: initValues.recorders.min,
        max: initValues.recorders.max,
    });

    // Limit reserved ranges maximums to project max range
    React.useEffect(() => {
        // Only run when project range has updated
        if (
            projectRangeRef.current.start === projectNetwork?.ipStart &&
            projectRangeRef.current.end === projectNetwork?.ipEnd
        )
            return;

        // Update reference
        projectRangeRef.current = { start: projectNetwork?.ipStart, end: projectNetwork?.ipEnd };

        [cameraRange, otherRange, recorderRange].forEach((range) => {
            let setStateMethod = undefined;
            let rangeType: IPersistableRangeType | undefined = undefined;
            if (range === cameraRange) (setStateMethod = setCameraRange), (rangeType = 'cameras');
            else if (range === otherRange) (setStateMethod = setOtherRange), (rangeType = 'other');
            else (setStateMethod = setRecorderRange), (rangeType = 'recorders');

            setStateMethod({
                ...range,
                min: initValues[rangeType].min,
                max: initValues[rangeType].max,
            });
        });
    }, [
        cameraRange,
        initValues,
        projectNetwork?.ipEnd,
        projectNetwork?.ipStart,
        rangeSize,
        recorderRange,
        otherRange,
    ]);

    if (!projectNetwork) {
        return null;
    }

    /** Ranges that are not covered by active reserved ranges for cameras, speakers and servers */
    const otherRanges = getNotUsedRanges(
        [cameraRange, recorderRange, otherRange],
        rangeSize,
        projectNetwork.ipStart,
    );

    /** Toggles expanded state for reserved ranges, both locally and to database. */
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Box direction="column" paddingTop="half">
            <Clickable onClick={toggleExpanded}>
                <Stack justifyContent="start" alignItems="center" spacing="none">
                    <Text color="blue" style="semibold">
                        {t.reservedRanges}
                    </Text>
                    <div className={ipExpandButtonStyle} aria-expanded={expanded}>
                        <Icon color="blue" icon="arrow_down" />
                    </div>
                </Stack>
            </Clickable>
            <div aria-expanded={expanded} className={ipAccordionStyle}>
                <Box paddingY="panel" direction="column">
                    <Stack>
                        <Stack vertical spacing="base" notFullWidth>
                            <IPRangeIcon type="cameras" />
                            <IPRangeIcon type="other" />
                            <IPRangeIcon type="recorders" />
                            <IPRangeIcon type="notUsed" />
                        </Stack>
                        <Stack vertical>
                            <IPRangeSlider
                                range={cameraRange}
                                setRange={setCameraRange}
                                hasFocus={focusedSlider === 'cameras'}
                                onGotFocus={() => setFocusedSlider('cameras')}
                            />
                            <IPRangeSlider
                                range={otherRange}
                                setRange={setOtherRange}
                                hasFocus={focusedSlider === 'other'}
                                onGotFocus={() => setFocusedSlider('other')}
                            />
                            <IPRangeSlider
                                range={recorderRange}
                                setRange={setRecorderRange}
                                hasFocus={focusedSlider === 'recorders'}
                                onGotFocus={() => setFocusedSlider('recorders')}
                            />
                            <Box justifyContent="evenly" paddingRight="cell" width="100%">
                                <MultiRange
                                    rangeMax={rangeSize}
                                    rangeMin={RANGE_MIN}
                                    color="grey4"
                                    ranges={otherRanges}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </div>
        </Box>
    );
};

IPRangeSettings.displayName = 'IPRangeSettings';
