/**
 * Box generates a div with styling from predefined props.
 *
 * This implementation of Box is inspired by and partly copied from:
 * https://pinterest.github.io/gestalt/#/Box, where the description below can be found:
 */

import * as React from 'react';
import { css } from '@emotion/css';
import { mapping, toggle } from './transforms';
import type { IStyle } from './style';
import { concat, fromClassName, fromInlineStyle, toProps } from './style';
import type {
    AlignItems,
    Colors,
    Display,
    Direction,
    Flex,
    JustifyContent,
    Overflow,
    Position,
    Spacings,
    IGridConfig,
} from 'app/styles';
import {
    ColorsEnum,
    PaddingBase,
    PaddingBottomBase,
    PaddingBottomCell,
    PaddingBottomDoublePanel,
    PaddingBottomHalf,
    PaddingBottomHalfCell,
    PaddingBottomHalfQuart,
    PaddingBottomNone,
    PaddingBottomPanel,
    PaddingBottomQuart,
    PaddingCell,
    PaddingDoublePanel,
    PaddingHalf,
    PaddingHalfCell,
    PaddingHalfQuart,
    PaddingLeftBase,
    PaddingLeftCell,
    PaddingLeftDoublePanel,
    PaddingLeftHalf,
    PaddingLeftHalfCell,
    PaddingLeftHalfQuart,
    PaddingLeftNone,
    PaddingLeftPanel,
    PaddingLeftQuart,
    PaddingPanel,
    PaddingQuart,
    PaddingRightBase,
    PaddingRightCell,
    PaddingRightDoublePanel,
    PaddingRightHalf,
    PaddingRightHalfCell,
    PaddingRightHalfQuart,
    PaddingRightNone,
    PaddingRightPanel,
    PaddingRightQuart,
    PaddingTopBase,
    PaddingTopCell,
    PaddingTopDoublePanel,
    PaddingTopHalf,
    PaddingTopHalfCell,
    PaddingTopHalfQuart,
    PaddingTopNone,
    PaddingTopPanel,
    PaddingTopQuart,
    PaddingXBase,
    PaddingXCell,
    PaddingXDoublePanel,
    PaddingXHalf,
    PaddingXHalfCell,
    PaddingXHalfQuart,
    PaddingXPanel,
    PaddingXQuart,
    PaddingYBase,
    PaddingYCell,
    PaddingYDoublePanel,
    PaddingYHalf,
    PaddingYHalfCell,
    PaddingYHalfQuart,
    PaddingYPanel,
    PaddingYQuart,
    ItemsStart,
    DisplayBlock,
    DisplayFlex,
    DisplayInlineBlock,
    DisplayNone,
    VisibilityHidden,
    DisplayTableCell,
    FlexDirectionColumn,
    FlexDirectionRow,
    ItemsBaseline,
    ItemsCenter,
    ItemsEnd,
    ItemsStretch,
    DontShrink,
    FlexEvenSpace,
    FlexFullWidth,
    FlexGrow,
    FlexNone,
    FlexShrink,
    FlexShrinkAndGrow,
    JustifyAround,
    JustifyBetween,
    JustifyCenter,
    JustifyEnd,
    JustifyEvenly,
    JustifyStart,
    OverflowAuto,
    OverflowHidden,
    OverflowScroll,
    OverflowUnset,
    OverflowXAuto,
    OverflowXHidden,
    OverflowXScroll,
    OverflowXUnset,
    OverflowYAuto,
    OverflowYHidden,
    OverflowYScroll,
    OverflowYUnset,
    FlexWrap,
    PositionRelative,
    PositionStatic,
    SpacingNone,
    SpacingBase,
    SpacingCell,
    SpacingDoublePanel,
    SpacingHalf,
    SpacingHalfCell,
    SpacingHalfQuart,
    SpacingPanel,
    SpacingQuart,
    FlexDirectionRowReverse,
    FlexDirectionColumnReverse,
    DisplayGrid,
    NoPointerEvents,
} from 'app/styles';
import type { IExtendableComponentWithChildren } from '../../models';
import type { Corners } from 'app/styles/corners.type';
import { CornerBlunt, CornerCircle, CornerRound, CornerRounded } from 'app/styles/corners.type';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';

const BoxStyle = css`
    box-sizing: border-box;
    display: flex;
    -webkit-overflow-scrolling: touch;
`;

const IsSelectableStyle = css`
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: element;
    user-select: text;
`;

const PageBreakBeforeStyle = css`
    @media print {
        page-break-before: always;
    }
`;

/** Avoids page breaks inside of box. FF needs display: block; to work properly. */
const AvoidPageBreakInsideStyle = css`
    @media print {
        display: block;
        break-inside: avoid-page;
    }
`;

export type BoxValue = number | string;

export interface IBoxProps extends IExtendableComponentWithChildren, IAutoTestable {
    /**
     * Aligns a flex container's lines within when there is extra space in the cross-axis, similar to how justify-content aligns individual items within the main-axis
     */
    alignItems?: AlignItems;
    /**
     * The color of the box
     */
    color?: Colors;
    /**
     * Display mode
     */
    display?: Display;
    /**
     * Small Display mode
     */
    smDisplay?: Display;
    /**
     * Medium Display mode
     */
    mdDisplay?: Display;
    /**
     * Large Display mode
     */
    lgDisplay?: Display;
    /**
     * Extra large Display mode
     */
    xlDisplay?: Display;
    /**
     * Establishes the main-axis, thus defining the direction flex items are placed in the flex container
     */
    direction?: Direction;
    /**
     * Defines how a flex item will be sized. "grow", equivalent to "flex: 1 1 auto", will size the Box relative to its parent, growing and shrinking based on available space. "shrink", equivalent to "flex: 0 1 auto" (the browser default), allows the Box to shrink if compressed but not grow if given extra space. Finally, "none", equivalent to "flex: 0 0 auto", preserves the Box's size based on child content regardless of its container's size.
     */
    flex?: Flex;
    /**
     * A shorthand property for the grid-template-rows, grid-template-columns, grid-template-areas, grid-auto-rows, grid-auto-columns, and the grid-auto-flow properties, or a specified grid config.
     */
    grid?: React.CSSProperties['grid'] | IGridConfig;
    /**
     * The height of the box
     */
    height?: BoxValue;
    /**
     * Defines the alignment along the main axis. It helps distribute extra free space left over when either all the flex items on a line are inflexible, or are flexible but have reached their maximum size. It also exerts some control over the alignment of items when they overflow the line.
     */
    justifyContent?: JustifyContent;
    /**
     * The min height
     */
    minHeight?: BoxValue;
    /**
     * The min width
     */
    minWidth?: BoxValue;
    /**
     * The max height
     */
    maxHeight?: BoxValue;
    /**
     * The max width
     */
    maxWidth?: BoxValue;
    /**
     * The overflow behaviour
     */
    overflow?: Overflow;
    /**
     * The horizontal overflow behaviour
     */
    overflowX?: Overflow;
    /**
     * The vertical overflow behaviour
     */
    overflowY?: Overflow;
    /**
     * Padding
     */
    padding?: Spacings;
    /**
     * X axis padding
     */
    paddingX?: Spacings;
    /**
     * Y axis padding
     */
    paddingY?: Spacings;
    /**
     * Top padding
     */
    paddingTop?: Spacings;
    /**
     * Bottom padding
     */
    paddingBottom?: Spacings;
    /**
     * Left padding
     */
    paddingLeft?: Spacings;
    /**
     * Right padding
     */
    paddingRight?: Spacings;
    /**
     * Sets border radius on corners.
     */
    borderRadius?: Corners;
    /**
     * The spacing between children
     */
    spacing?: Spacings;
    /**
     * The position of the box
     */
    position?: Position;
    /**
     * The width of the box
     */
    width?: BoxValue;
    /**
     * When printing this will cause a page break before the
     * Box if needed.
     */
    pageBreakBefore?: boolean;
    /**
     * Avoid page break inside box
     */
    avoidPageBreakInside?: boolean;
    /**
     * By default, flex items will all try to fit onto one line. You can change that and allow the items to wrap onto multiple lines, from top to bottom.
     */
    wrap?: boolean;
    /**
     * Sets a data attribute which allows the Box to close opened windows
     * when clicked upon, even if the windows are inside of it.
     * Used for Modal where the backdrop Box should dismiss the modal
     * if clicked.
     */
    isBackdrop?: boolean;
    /**
     * Use this to pass a ref prop into this component, you will get
     * the underlying div element as a result.
     */
    innerRef?: React.RefObject<HTMLDivElement> | React.ForwardedRef<HTMLDivElement>;
    /**
     * Whether or not text within the component can be selected by user
     */
    userSelect?: boolean;
    /**
     * Sets color when hovering
     */
    hoverColor?: Colors;
    /**
     * Adds a colored line between each element in the box.
     */
    lineBetweenColor?: Colors;
    /**
     *  Hides the element, but keeps it in the DOM
     *  */
    hidden?: boolean;
    /**
     * Prevents pointer events on the element
     */
    noPointerEvents?: boolean;
}

/**
 * Maps styling props to classes or inline style
 */
const propToFn: Record<string, (key: any) => IStyle> = {
    alignItems: mapping({
        start: ItemsStart,
        end: ItemsEnd,
        center: ItemsCenter,
        baseline: ItemsBaseline,
        stretch: ItemsStretch,
    }),
    direction: mapping({
        row: FlexDirectionRow,
        rowReverse: FlexDirectionRowReverse,
        column: FlexDirectionColumn,
        columnReverse: FlexDirectionColumnReverse,
    }),
    /** default: flex */
    display: mapping({
        none: DisplayNone,
        flex: DisplayFlex,
        grid: DisplayGrid,
        block: DisplayBlock,
        inlineBlock: DisplayInlineBlock,
    }),
    smDisplay: mapping({
        none: DisplayNone,
        flex: DisplayFlex,
        grid: DisplayGrid,
        block: DisplayBlock,
        inlineBlock: DisplayInlineBlock,
        tableCell: DisplayTableCell,
    }),
    mdDisplay: mapping({
        none: DisplayNone,
        flex: DisplayFlex,
        grid: DisplayGrid,
        block: DisplayBlock,
        inlineBlock: DisplayInlineBlock,
        tableCell: DisplayTableCell,
    }),
    lgDisplay: mapping({
        none: DisplayNone,
        flex: DisplayFlex,
        grid: DisplayGrid,
        block: DisplayBlock,
        inlineBlock: DisplayInlineBlock,
        tableCell: DisplayTableCell,
    }),
    xlDisplay: mapping({
        none: DisplayNone,
        flex: DisplayFlex,
        grid: DisplayGrid,
        block: DisplayBlock,
        inlineBlock: DisplayInlineBlock,
        tableCell: DisplayTableCell,
    }),
    /**
     * Defines how a flex item will be sized. "grow", equivalent to "flex: 1 1 auto", will size the
     * Box relative to its parent, growing and shrinking based on available space. "shrink",
     * equivalent to "flex: 0 1 auto" (the browser default), allows the Box to shrink if compressed
     * but not grow if given extra space. Finally, "none", equivalent to "flex: 0 0 auto", preserves
     * the Box's size based on child content regardless of its container's size.
     */
    flex: mapping({
        fullWidth: FlexFullWidth,
        grow: FlexGrow,
        shrinkAndGrow: FlexShrinkAndGrow,
        shrink: FlexShrink,
        evenSpace: FlexEvenSpace,
        dontShrink: DontShrink,
        none: FlexNone,
    }),
    height: (height: BoxValue) => fromInlineStyle({ height }),
    justifyContent: mapping({
        start: JustifyStart,
        end: JustifyEnd,
        center: JustifyCenter,
        between: JustifyBetween,
        around: JustifyAround,
        evenly: JustifyEvenly,
    }),
    minHeight: (minHeight: BoxValue) => fromInlineStyle({ minHeight }),
    minWidth: (minWidth: BoxValue) => fromInlineStyle({ minWidth }),
    maxHeight: (maxHeight: BoxValue) => fromInlineStyle({ maxHeight }),
    maxWidth: (maxWidth: BoxValue) => fromInlineStyle({ maxWidth }),
    /** default: visible */
    overflow: mapping({
        hidden: OverflowHidden,
        scroll: OverflowScroll,
        auto: OverflowAuto,
        unset: OverflowUnset,
    }),
    /** default: visible */
    overflowX: mapping({
        hidden: OverflowXHidden,
        scroll: OverflowXScroll,
        auto: OverflowXAuto,
        unset: OverflowXUnset,
    }),
    /** default: visible */
    overflowY: mapping({
        hidden: OverflowYHidden,
        scroll: OverflowYScroll,
        auto: OverflowYAuto,
        unset: OverflowYUnset,
    }),
    padding: mapping({
        base: PaddingBase,
        cell: PaddingCell,
        halfCell: PaddingHalfCell,
        half: PaddingHalf,
        panel: PaddingPanel,
        doublePanel: PaddingDoublePanel,
        quart: PaddingQuart,
        halfQuart: PaddingHalfQuart,
    }),
    paddingX: mapping({
        base: PaddingXBase,
        cell: PaddingXCell,
        halfCell: PaddingXHalfCell,
        half: PaddingXHalf,
        panel: PaddingXPanel,
        doublePanel: PaddingXDoublePanel,
        quart: PaddingXQuart,
        halfQuart: PaddingXHalfQuart,
    }),
    paddingY: mapping({
        base: PaddingYBase,
        cell: PaddingYCell,
        halfCell: PaddingYHalfCell,
        half: PaddingYHalf,
        panel: PaddingYPanel,
        doublePanel: PaddingYDoublePanel,
        quart: PaddingYQuart,
        halfQuart: PaddingYHalfQuart,
    }),
    paddingTop: mapping({
        none: PaddingTopNone,
        base: PaddingTopBase,
        cell: PaddingTopCell,
        halfCell: PaddingTopHalfCell,
        half: PaddingTopHalf,
        panel: PaddingTopPanel,
        doublePanel: PaddingTopDoublePanel,
        quart: PaddingTopQuart,
        halfQuart: PaddingTopHalfQuart,
    }),
    paddingBottom: mapping({
        none: PaddingBottomNone,
        base: PaddingBottomBase,
        cell: PaddingBottomCell,
        halfCell: PaddingBottomHalfCell,
        half: PaddingBottomHalf,
        panel: PaddingBottomPanel,
        doublePanel: PaddingBottomDoublePanel,
        quart: PaddingBottomQuart,
        halfQuart: PaddingBottomHalfQuart,
    }),
    paddingLeft: mapping({
        none: PaddingLeftNone,
        base: PaddingLeftBase,
        cell: PaddingLeftCell,
        halfCell: PaddingLeftHalfCell,
        half: PaddingLeftHalf,
        panel: PaddingLeftPanel,
        doublePanel: PaddingLeftDoublePanel,
        quart: PaddingLeftQuart,
        halfQuart: PaddingLeftHalfQuart,
    }),
    paddingRight: mapping({
        none: PaddingRightNone,
        base: PaddingRightBase,
        cell: PaddingRightCell,
        halfCell: PaddingRightHalfCell,
        half: PaddingRightHalf,
        panel: PaddingRightPanel,
        doublePanel: PaddingRightDoublePanel,
        quart: PaddingRightQuart,
        halfQuart: PaddingRightHalfQuart,
    }),
    spacing: mapping({
        none: SpacingNone,
        base: SpacingBase,
        cell: SpacingCell,
        halfCell: SpacingHalfCell,
        half: SpacingHalf,
        panel: SpacingPanel,
        doublePanel: SpacingDoublePanel,
        quart: SpacingQuart,
        halfQuart: SpacingHalfQuart,
    }),
    borderRadius: mapping({
        blunt: CornerBlunt,
        rounded: CornerRounded,
        round: CornerRound,
        circle: CornerCircle,
    }),
    position: mapping({
        static: PositionStatic,
        relative: PositionRelative,
    }),
    color: (color: Colors) => fromInlineStyle({ backgroundColor: ColorsEnum[color] }),
    width: (width: BoxValue) => fromInlineStyle({ width }),
    /** Defaults to not set */
    wrap: toggle(FlexWrap),
    pageBreakBefore: toggle(PageBreakBeforeStyle),
    avoidPageBreakInside: toggle(AvoidPageBreakInsideStyle),
    userSelect: toggle(IsSelectableStyle),
    hidden: toggle(VisibilityHidden),
    noPointerEvents: toggle(NoPointerEvents),
};

export class Box extends React.Component<IBoxProps> {
    public render() {
        const {
            children,
            __htmlAttributes,
            isBackdrop,
            hoverColor,
            lineBetweenColor,
            grid,
            ...props
        } = this.props;

        const lineBetweenStyle = !lineBetweenColor
            ? css``
            : props.direction === 'column' || props.direction == 'columnReverse'
              ? css`
                    > *:not(:last-child) {
                        border-bottom: 1px solid ${ColorsEnum[lineBetweenColor]} !important;
                    }
                `
              : css`
                    > *:not(:last-child) {
                        border-inline-end: 1px solid ${ColorsEnum[lineBetweenColor]} !important;
                    }
                `;

        const gridStyle =
            grid !== undefined
                ? typeof grid === 'string'
                    ? css`
                          grid: ${grid};
                      `
                    : css`
                          grid-template: ${grid?.gridTemplate};
                          grid-template-columns: ${grid?.gridTemplateColumns};
                          grid-template-rows: ${grid?.gridTemplateRows};
                          grid-template-areas: ${grid?.gridTemplateAreas};
                          grid-auto-columns: ${grid?.gridAutoColumns};
                          grid-auto-rows: ${grid?.gridAutoRows};
                          grid-column-gap: ${grid?.columnGap};
                          grid-row-gap: ${grid?.rowGap};
                      `
                : css``;

        // All Box's are box-sized by default, so we start off building up the styles
        // to be applied with a Box base class.
        let styleEntities = fromClassName(
            BoxStyle,
            css`
                ${lineBetweenStyle}
                ${gridStyle}
                transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
                :hover {
                    background-color: ${hoverColor
                        ? `${ColorsEnum[hoverColor]} !important`
                        : undefined};
                }
            `,
        );

        // The record value is any because DetailedHTMLProps contains any
        const propsRecord = props as Record<string, any>;

        for (const prop in props) {
            // This loop covers styling props defined by a propToFn
            if (propToFn.hasOwnProperty(prop)) {
                const fn = propToFn[prop];
                const value = propsRecord[prop];

                styleEntities = concat([styleEntities, fn(value)]);
            }
        }

        let htmlAttributes = {};

        if (__htmlAttributes) {
            const { className, style, ...attributes } = __htmlAttributes;
            htmlAttributes = attributes;
            styleEntities = concat([
                styleEntities,
                fromInlineStyle(style || {}),
                fromClassName(className || ''),
            ]);
        }

        return (
            <div
                data-test-id={toTestIdFormat(this.props.testId)}
                {...htmlAttributes}
                {...toProps(styleEntities)}
                data-backdrop={isBackdrop}
                ref={this.props.innerRef}
            >
                {children}
            </div>
        );
    }
}
