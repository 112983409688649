import * as React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { debounce } from 'lodash-es';
import type { Icons } from '../../ui';
import { IconButton } from '../../ui/iconButton/IconButton.component';
import { Label } from '../../text';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';
import { stopUndoRedoPropagation } from '../utils';
import { ColorsEnum } from 'app/styles';
import {
    inputBorderRadius,
    inputItemBackgroundColor,
    inputItemBorder,
    inputItemHeight,
    inputItemPadding,
} from '../InputStyles';

export const TextInputStyle = css`
    box-sizing: border-box;
    width: 100%;
    height: ${inputItemHeight};
    padding-right: ${inputItemPadding};
    padding-left: ${inputItemPadding};

    border: ${inputItemBorder};
    border-radius: ${inputBorderRadius};
    background-color: ${inputItemBackgroundColor};

    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;

    &:focus,
    &:active {
        outline: none;
        background-color: ${ColorsEnum.white};
    }
`;

const TransparentStyle = css`
    background-color: ${ColorsEnum.transparent};
    &:hover,
    &:focus {
        background-color: ${ColorsEnum.white};
    }
`;

const AlwaysTransparentStyle = css`
    background-color: ${ColorsEnum.transparent} !important;
`;

const HasIconStyle = css`
    padding-left: 28px;
`;
const HasClearButtonStyle = css`
    padding-right: 28px;
`;
const TextInputContainerStyle = css`
    position: relative;
    &::before {
        font-family: 'axis-wt-icons';
        content: attr(data-icon);
        position: absolute;
        line-height: 1.3;
        inset-inline-start: 4px;
        top: 3px;
        color: ${ColorsEnum.blue};
        font-size: 19px;
        pointer-events: none;

        /* Enable Ligatures ================ */
        letter-spacing: 0;
        font-feature-settings: 'liga';
        font-variant-ligatures: discretionary-ligatures;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

const NoBorderStyle = css`
    border-width: 0 !important;
`;

const ErrorStyle = css`
    border-color: ${ColorsEnum.red};
`;

const CodeStyle = css`
    font-family: 'Courier New', Courier, monospace;
`;

const TextAlignCenterStyle = css`
    text-align: center;
`;

const TextAlignRightStyle = css`
    text-align: right;
`;

const HideOverflowStyle = css`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CancelButtonStyle = css`
    position: absolute;
    inset-inline-end: 4px;
    top: 3px;
    line-height: 1.7;
    &:focus {
        outline: none;
        opacity: 1;
    }
`;

type TextAlign = 'left' | 'center' | 'right';

interface ITextInput extends IAutoTestable {
    /**
     * Input element type attribute
     */
    type?: string;
    /**
     * The value
     */
    value: string;
    /**
     * The max length
     */
    maxLength?: number;
    /**
     * Placeholder text
     */
    placeholder?: string;
    /**
     * How to trigger change event on text input
     */
    changeCriteria: 'blur' | 'key' | 'debounced';
    /**
     * Disable the input
     */
    disabled?: boolean;
    /**
     * Determines whether this component should get focus automatically
     */
    autoFocus?: boolean;
    /**
     * Determines whether this component should get focus automatically and select the text
     */
    autoFocusAndSelect?: boolean;
    /**
     * Specify permission for browser to provide automated assistance
     * in filling out form field values
     */
    autoComplete?: boolean;
    /**
     * Hides the border
     */
    noBorder?: boolean;
    /**
     * Align the text
     */
    align?: TextAlign;
    /**
     * Transparent background when not focused
     */
    transparent?: boolean;
    /**
     * Transparent background even when focused
     */
    alwaysTransparent?: boolean;
    /**
     * Not allow empty values
     */
    required?: boolean;
    /**
     * Display an icon in the text field
     */
    icon?: Icons;
    /**
     * Prevents user from changing value
     */
    readOnly?: boolean;
    /**
     * Changes font to indicate that this is a code
     */
    code?: boolean;
    /**
     * Displays the border and label in red to indicate that input is invalid
     */
    error?: boolean;
    /**
     * Adds a label describing the input field
     */
    label?: string;
    /**
     * Override the clear icon
     */
    clearIcon?: Icons;
    /**
     * Allow text input to fill flex space
     */
    grow?: boolean;
    /**
     * Show ellipsis on overflow
     */
    hideOverflow?: boolean;
    /**
     * Removes clear button without removing the functionality behind it.
     * Useful when we still want to access clear function through hotkeys such as esc.
     */
    noClearButton?: boolean;
    /**
     * Provide a method to clear the field.
     * Will display a cancel button.
     */
    clear?(): void;

    /**
     * The function triggered on change when changeOnText is true
     */
    onChange(value: string): void;
    /**
     * A callback to trigger when the user hits the ENTER key
     */
    onEnter?(): void;
    /**
     * A callback to trigger when the input focus state changes
     */
    onFocusChanged?(hasFocus: boolean): void;
}

interface IStateTextInput {
    textValue: string;
    initialValue: string;
}

/**
 * A normal text input.
 */
export class TextInput extends React.PureComponent<ITextInput, IStateTextInput> {
    public static defaultProps: Partial<ITextInput> = {
        changeCriteria: 'blur',
    };

    private inputRef: React.RefObject<HTMLInputElement>;
    private usesDeactivate = false;
    private debouncedOnChange = debounce((value) => this.props.onChange(value), 600);

    constructor(props: ITextInput) {
        super(props);
        this.state = {
            textValue: this.props.value,
            initialValue: this.props.value,
        };
        this.inputRef = React.createRef();
    }

    public componentDidMount() {
        // Since blur is asynchronous in IE11 we can't guarantee that a change
        // will be saved onBlur when changing component model. The IE exclusive
        // `deactivate` event triggers before any other click handlers.
        this.inputRef.current!.addEventListener('deactivate', this.onDeactivate);
        this.inputRef.current!.addEventListener('keydown', stopUndoRedoPropagation);
    }

    public componentWillUnmount() {
        this.inputRef.current!.removeEventListener('keydown', stopUndoRedoPropagation);
    }

    public componentDidUpdate(prevProps: ITextInput) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                textValue: this.props.value,
                initialValue: this.props.value,
            });
        }
    }

    public render() {
        const classes = classNames(TextInputStyle, {
            [NoBorderStyle]: this.props.noBorder,
            [TransparentStyle]: this.props.transparent,
            [AlwaysTransparentStyle]: this.props.alwaysTransparent,
            [HasIconStyle]: !!this.props.icon,
            [HasClearButtonStyle]: !!this.props.clear,
            [CodeStyle]: !!this.props.code,
            [ErrorStyle]: !!this.props.error,
            [TextAlignCenterStyle]: this.props.align === 'center',
            [TextAlignRightStyle]: this.props.align === 'right',
            [HideOverflowStyle]: this.props.hideOverflow,
        });
        return (
            <div
                className={
                    this.props.grow
                        ? css`
                              flex-grow: 1;
                          `
                        : undefined
                }
            >
                {this.props.label && (
                    <Label opaque error={this.props.error}>
                        {this.props.label}
                    </Label>
                )}
                <div className={TextInputContainerStyle} data-icon={this.props.icon}>
                    <input
                        data-test-id={toTestIdFormat(this.props.testId)}
                        ref={this.inputRef}
                        type={this.props.type || 'text'}
                        className={classes}
                        maxLength={this.props.maxLength}
                        value={this.state.textValue}
                        placeholder={this.props.placeholder}
                        onChange={this.onTextChange}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        onKeyDownCapture={this.onKeyDownCapture}
                        disabled={this.props.disabled}
                        autoFocus={this.props.autoFocus || this.props.autoFocusAndSelect}
                        autoComplete={this.props.autoComplete ? 'on' : 'off'}
                        readOnly={this.props.readOnly}
                    />
                    {this.props.clear && !this.props.noClearButton && (
                        <IconButton
                            testId={`text_input_clear_${this.props.testId}`}
                            __htmlAttributes={{
                                className: CancelButtonStyle,
                            }}
                            size="sm"
                            hidden={this.state.textValue.length === 0}
                            onClick={() => {
                                this.setState({ textValue: '', initialValue: '' });
                                this.props.clear && this.props.clear();
                            }}
                            icon={this.props.clearIcon || 'cancel'}
                        />
                    )}
                </div>
            </div>
        );
    }

    private onBlur = () => {
        // Prevent onTextBlur to be called twice
        // if onDeactivate already was called (in case of edge or ie11)
        if (this.usesDeactivate) {
            return;
        }
        this.onTextBlur();
        this.props.onFocusChanged && this.props.onFocusChanged(false);
    };

    private onFocus = () => {
        if (this.props.autoFocusAndSelect) {
            // Need to wait for inputRef to be set
            setTimeout(() => this.inputRef.current?.select(), 100);
        }
        this.props.onFocusChanged && this.props.onFocusChanged(true);
    };

    private onDeactivate = () => {
        this.usesDeactivate = true;
        this.onTextBlur();
    };

    private onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ textValue: e.target.value });
        if (this.props.changeCriteria === 'key' || this.props.changeCriteria === 'debounced') {
            const value = e.target.value;
            const hasValue = value.trim();
            if (this.props.required && !hasValue) {
                return;
            }

            if (this.props.changeCriteria === 'debounced') {
                this.debouncedOnChange(value);
            } else {
                this.props.onChange(value);
            }
        }
    };

    private onTextBlur = () => {
        const hasValue = this.state.textValue.trim();
        if (!hasValue && this.props.required) {
            this.setState({
                textValue: this.state.initialValue,
            });
        } else {
            if (this.props.changeCriteria === 'blur') {
                if (this.state.textValue !== this.state.initialValue) {
                    this.props.onChange(this.state.textValue);
                }
            } else if (this.props.changeCriteria === 'debounced') {
                this.debouncedOnChange.flush();
            }
        }
    };

    private onKeyDownCapture = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.onEnter && e.key === 'Enter') {
            this.props.onEnter();
        }
        if (e.key === 'Escape') {
            if (this.props.clear) {
                this.props.clear();
                e.currentTarget.blur();
            } else {
                // Need to save the target because of synthetic event wrapper see
                // https://fb.me/react-event-pooling
                const currentTarget = e.currentTarget;
                this.setState(
                    {
                        textValue: this.state.initialValue,
                    },
                    () => {
                        currentTarget.blur();
                    },
                );
            }
        }
    };
}
