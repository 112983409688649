import { injectable } from 'inversify';
import { CreateEntityService } from '../../repositories/persistence/CreateEntity.service';
import { MigrationProviderBase } from '../MigrationProviderBase';
import type { IPersistenceRepository } from '../../repositories';
import { AddableEntity, DeletableEntity } from '../models';

/**
 * Migrate to new maps with support for geolocated floor plans.
 *
 * Algorithm:
 *
 * If entity is a floor plan of type 'FloorPlan', do the following:
 *
 * 1. If the floor plan has blockers, migrate the blockers to blocker entities,
 *    with the floor plan id as the parent and set the mapOrigin property to the id
 *    of the floor plan.
 * 2. Find all installation points referencing this floor plan and set the mapOrigin
 *    property to the id of the floor plan.
 * 3. Find all free text points referencing this floor plan and set the mapOrigin
 *    property to the id of the floor plan.
 *
 * If the entity is a floor plan of type 'StreetMap', do the following:
 *
 * 1. Create a new map location entity with the name and location of this street map.
 * 2. If the floor plan has blockers, migrate the blockers to blocker entities,
 *    with the project id as the parent and set the mapOrigin property to the id of
 *    the newly created map location.
 * 3. Find all installation points referencing this floor plan and make them global
 *    by unsetting the floorPlanId property. Set the mapOrigin property to the id of
 *    the newly created map location.
 * 4. Find all free text points referencing this floor plan and make them global by
 *    removing the floor plan id from their paths and set the mapOrigin property to
 *    the id of the newly created map location.
 * 5. If we haven't created a default map yet, create one with the location of this
 *    street map.
 * 6. Remove the floor plan entity.
 */
@injectable()
export class Migration45to46 extends MigrationProviderBase {
    public from: number = 45;
    public to: number = 46;

    constructor(private createEntityService: CreateEntityService) {
        super();
    }

    public provideMigration(repository: IPersistenceRepository) {
        // A set to keep track of for which projects we have already created a default map
        const projectsWithCreatedDefaultMaps = new Set<string>();

        /*
         * Create a new default map location entity if it doesn't exist
         */
        const createDefaultMap = async (floorPlan: any) => {
            const projectId = floorPlan.path[0];
            if (projectsWithCreatedDefaultMaps.has(projectId)) {
                return;
            }

            // Check if the project already has a default map
            const hasDefaultMap =
                (await repository.getDescendants(projectId)).filter(
                    (entity: any) => entity.isDefault && entity.type === 'floorPlan',
                ).length > 0;

            if (hasDefaultMap) {
                return;
            }

            projectsWithCreatedDefaultMaps.add(projectId);

            // create a new default map location entity
            const defaultMap = this.createEntityService.create(
                'floorPlan',
                {
                    type: 'floorPlan',
                    mapType: 'StreetMap',
                    name: '',
                    location: floorPlan.location,
                    path: [projectId],
                    isDefault: true,
                } as any,
                floorPlan.creationDate,
            );

            // add the new default map
            return new AddableEntity(defaultMap);
        };

        /*
         * Migrate floor plan to map location
         */
        const migrateToMapLocation = (floorPlan: any) => {
            // Create bounds from the location with a side length of around 200 meters.
            // This roughly corresponds to what is visible on the default zoom level 20
            // in old maps. The "square" will look more like a pie slice, as we approach
            // the poles, but that's ok since a latitude degree always corresponds to
            // the same distance (assuming the earth is a perfect sphere).
            // 0.001 latitude degrees is roughly 111 meters and 0.001 longitude degrees
            // is roughly 111 meters at the equator and approaches 0 meters at the poles.
            const bounds = {
                topLeft: {
                    lat: Number(floorPlan.location.lat) + 0.001,
                    lng: Number(floorPlan.location.lng) - 0.001,
                },
                bottomRight: {
                    lat: Number(floorPlan.location.lat) - 0.001,
                    lng: Number(floorPlan.location.lng) + 0.001,
                },
            };
            // create a new map location entity
            const newMapLocation = this.createEntityService.create(
                'mapLocation',
                {
                    type: 'mapLocation',
                    name: floorPlan.name,
                    bounds,
                    path: [floorPlan.path[0]],
                } as any,
                floorPlan.creationDate,
            );

            return new AddableEntity(newMapLocation);
        };

        /*
         * Migrate blockers to blocker entities
         */
        const migrateBlockers = (floorPlan: any, mapLocationId?: string) => {
            const blockers = (floorPlan.blockers as [][]).map((blocker) => {
                const path =
                    floorPlan.mapType === 'FloorPlan' ? [...floorPlan.path] : [floorPlan.path[0]];
                const newBlocker = this.createEntityService.create(
                    'blocker',
                    {
                        type: 'blocker',
                        path,
                        floorLevel: 0,
                        latLngs: blocker.flat(),
                        mapOrigin: mapLocationId ?? floorPlan._id,
                        archived: floorPlan.archived,
                        locked: floorPlan.locked,
                    } as any,
                    floorPlan.updatedDate,
                );

                return new AddableEntity(newBlocker);
            });

            return blockers;
        };

        /*
         * Migrate installation points
         */
        const migrateInstallationPoints = (
            descendants: any[],
            floorPlanId: string,
            mapLocationId?: string,
        ) => {
            const mapOrigin = mapLocationId ?? floorPlanId;
            const makeGlobal = Boolean(mapLocationId);

            // filter out installation points that are referencing this floor plan
            const referencedInstallationPoints = descendants.filter((descendent: any) => {
                return (
                    descendent.type === 'installationPoint' &&
                    (descendent.floorPlanId === floorPlanId || descendent.mapOrigin === floorPlanId)
                );
            }) as any[];

            // set the mapOrigin property to the id of the floor plan
            for (const installationPoint of referencedInstallationPoints) {
                installationPoint.mapOrigin = mapOrigin;
                installationPoint.floorPlanId = makeGlobal ? undefined : mapOrigin;
            }

            return referencedInstallationPoints;
        };

        /*
         * Migrate free text points
         */
        const migrateFreeTextPoints = (
            descendants: any[],
            floorPlanId: string,
            mapLocationId?: string,
        ) => {
            // Find all free text points referencing this floor plan
            const referencedFreeTextPoints = descendants.filter(
                (descendent) =>
                    descendent.type === 'freeTextPoint' &&
                    (descendent.path[1] === floorPlanId || descendent.mapOrigin === floorPlanId),
            ) as any[];

            // set the mapOrigin property to the id of the floor plan
            for (const freeTextPoint of referencedFreeTextPoints) {
                // If the free text point is global, remove the floor plan id from the path
                const path = mapLocationId
                    ? [freeTextPoint.path[0], freeTextPoint.path[2]]
                    : [...freeTextPoint.path];
                freeTextPoint.mapOrigin = mapLocationId ?? floorPlanId;
                freeTextPoint.path = path;
            }

            return referencedFreeTextPoints;
        };

        return async (oldEntity: any): Promise<any> => {
            const entity = { ...oldEntity };

            if (entity.type === 'installationPoint') {
                const floorPlanExists = await repository.exists(entity.floorPlanId);

                // if the installation point is referring a known floor plan, do nothing
                if (floorPlanExists || entity.mapOrigin) {
                    return [];
                }

                // if not, set the mapOrigin property to the floorPlanId and unset the floorPlanId
                entity.mapOrigin = entity.floorPlanId;
                delete entity.floorPlanId;

                return entity;
            }

            if (entity.type === 'freeTextPoint') {
                const floorPlanExists = await repository.exists(entity.path[1]);

                // if the free text point is referring a known floor plan, do nothing
                if (floorPlanExists || entity.mapOrigin) {
                    return [];
                }

                // if not, set the mapOrigin property to the floor plan id and remove the floor plan id from the path
                entity.mapOrigin = entity.path[1];
                entity.path = [entity.path[0], entity.path[2]];

                return entity;
            }

            if (entity.type !== 'floorPlan') {
                return entity;
            }

            const projectId = entity.path[0];

            if (entity.mapType === 'FloorPlan') {
                // Migrate the blockers from the floor plan to blocker entities
                const newBlockers = migrateBlockers(entity);

                // Remove the blockers from the floor plan entity
                delete entity.blockers;

                // Get all entities of the project
                const descendants = await repository.getDescendants(projectId);

                // Migrate the installation points
                const referencedInstallationPoints = migrateInstallationPoints(
                    descendants,
                    entity._id,
                );

                // Migrate the free text points
                const referencedFreeTextPoints = migrateFreeTextPoints(descendants, entity._id);

                return [
                    entity,
                    ...referencedInstallationPoints,
                    ...referencedFreeTextPoints,
                    ...newBlockers,
                ];
            } else if (entity.mapType === 'StreetMap') {
                // Migrate the floor plan to a map location entity
                const mapLocation = migrateToMapLocation(entity);

                // Migrate the blockers from the floor plan to blocker entities
                const newBlockers = migrateBlockers(entity, mapLocation.entity._id);

                // Get all entities of the project
                const descendants = await repository.getDescendants(projectId);

                // Migrate the installation points
                const referencedInstallationPoints = migrateInstallationPoints(
                    descendants,
                    entity._id,
                    mapLocation.entity._id,
                );

                // Migrate the free text points
                const referencedFreeTextPoints = migrateFreeTextPoints(
                    descendants,
                    entity._id,
                    mapLocation.entity._id,
                );

                // If we haven't created a default map yet, create one with the location of this street map
                const defaultMap = await createDefaultMap(entity);

                // Remove the floor plan entity by returning a deletable entity
                return [
                    new DeletableEntity(entity),
                    mapLocation,
                    ...(defaultMap ? [defaultMap] : []),
                    ...newBlockers,
                    ...referencedInstallationPoints,
                    ...referencedFreeTextPoints,
                ];
            } else {
                return entity;
            }
        };
    }
}
