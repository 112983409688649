import { createCachedSelector } from 're-reselect';
import type { PiaId } from 'app/core/pia';
import { toCacheKey } from '../../cacheKey';
import { getBareboneParentRecord } from './getBareboneParentRecord';
import { getPiaItemForProductId } from './getPiaDevices';

export const getBarebonePiaId = createCachedSelector(
    [getBareboneParentRecord, getPiaItemForProductId],
    (bareboneParentRecord, piaItem): PiaId | undefined => {
        if (!piaItem) {
            return undefined;
        }
        const bareboneItem = bareboneParentRecord[piaItem.parentId];
        if (!bareboneItem) {
            return undefined;
        }
        return bareboneItem.id;
    },
)(toCacheKey);
