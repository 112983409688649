import * as React from 'react';
import type { IButtonProps } from '../../ui/button/Button.component';
import { Button } from '../../ui/button/Button.component';
import { Linkable } from '../../functional';
import type { IAutoTestable } from '../../ui-test';
import { toTestIdFormat } from '../../ui-test';

interface ILinkButtonProps extends IButtonProps, IAutoTestable {
    /**
     * In-app url to redirect to
     */
    link: string;
    /**
     * Link to an external URL
     */
    external?: boolean;
}

/**
 * A Button that takes a link prop
 * and uses react-router to redirect
 * the browser on click.
 *
 * Use instead of Link when you want a button.
 */
export class LinkButton extends React.Component<ILinkButtonProps> {
    public render() {
        const { link, external, children, ...props } = this.props;

        return (
            <Linkable link={link} external={external}>
                <Button data-test-id={toTestIdFormat(this.props.testId)} {...props}>
                    {children}
                </Button>
            </Linkable>
        );
    }
}
