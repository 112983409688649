import { PartnerSystemItemCategory } from 'app/core/partner';
import type {
    IItemEntity,
    IPartnerSystemComponent,
    IPersistence,
    Id,
    IPartnerRecommendation,
    IPartnerRecommendationResponse,
} from 'app/core/persistence';
import {
    isBodyWornCamera,
    isEncoder,
    CurrentProjectService,
    isMainUnit,
    DEFAULT_RETENTION_TIME_IN_DAYS,
} from 'app/core/persistence';
import { type IPiaItem, type PiaId } from 'app/core/pia';
import type { BandwidthStorageEstimate } from 'app/modules/common';
import { toaster } from 'app/toaster';
import { t } from 'app/translate';
import { injectable } from 'inversify';
import type { Dictionary } from 'lodash';
import { countBy, uniqBy } from 'lodash-es';
import type { IDeviceRequirement } from '../selectors';
import type { IGenetecProject } from './GenetecProject';
import { GenetecRecommendationCommunicator } from './GenetecRecommendation.communicator';
import type { IGenetecProducts, IGenetecRecommendations } from './IGenetecRecommendations';
import { format } from 'axis-webtools-util';
import type { IStreamVaultJSONPayload } from './IStreamVaultJSONPayload';
import { eventTracking } from 'app/core/tracking';

const getGenetecDefaultCameraProps = {
    CameraCalculationType: 1,
    CameraResolutionName: '',
    CompressionAlgorithmName: '',
    CompressionAlgorithmId: -1,
    CameraResolutionId: -1,
    FramesPerSecond: 0,
    FrameSizeKB: 0,
};

const getGenetecDefaultProps = {
    Version: 1,
    SuggestedServerInput: {
        DaysOfRetention: DEFAULT_RETENTION_TIME_IN_DAYS,
    },
};

interface IAggregatedBandwidthAndStorage {
    storageInMB: number;
    bandwidthInBps: number;
    retentionTime: number;
}

interface IChildDevice {
    sensor: IItemEntity;
    total: IAggregatedBandwidthAndStorage;
    modelName: string;
}
interface IAggregatedDevice extends IDeviceRequirement {
    deviceCount: number;
    total: IAggregatedBandwidthAndStorage;
    childDevices: IChildDevice[];
}

export interface IStreamVaultCamera {
    Name: string;
    DaysOfRetention: number;
    BitrateKbps: number;
    RecordingPercentage: number;
    NumberOfCameras: number;
    CameraCalculationType: number;
    CameraResolutionName: string;
    CompressionAlgorithmName: string;
    CompressionAlgorithmId: number;
    CameraResolutionId: number;
    FramesPerSecond: number;
    FrameSizeKB: number;
}

interface IStreamVaultProject {
    ProjectName: string;
    ProjectNotes: string;
    Source: string;
    Cameras: IStreamVaultCamera[];
    Version: number;
    SuggestedServerInput: {
        DaysOfRetention: number;
    };
}

@injectable()
export class GenetecStreamVaultProjectService {
    constructor(
        private currentProjectService: CurrentProjectService,
        private genetecRecommendationCommunicator: GenetecRecommendationCommunicator,
    ) {}

    public getStreamVaultCalculatorProject(
        deviceRequirements: IDeviceRequirement[],
        bandwidthStorageEstimateForItems: Record<Id, BandwidthStorageEstimate>,
        piaItems: Record<PiaId, IPiaItem>,
    ): IStreamVaultProject {
        const counts = countBy(deviceRequirements, 'itemId');
        const uniqueDevices = this.getUniqDevices(deviceRequirements, counts);
        const aggregatedDevices = uniqueDevices.reduce((devices, device) => {
            const piaItem = device.productId ? piaItems[device.productId] : undefined;
            if (
                piaItem === undefined ||
                this.hasNoBandwidth(device, bandwidthStorageEstimateForItems) ||
                this.shouldBeExcluded(piaItem, device)
            ) {
                return devices;
            }

            devices.push(
                this.mapToAggregatedDevice(
                    device,
                    piaItem,
                    bandwidthStorageEstimateForItems,
                    piaItems,
                    counts,
                ),
            );
            return devices;
        }, new Array<IAggregatedDevice>());

        const cameras = this.getCamerasFromAggregatedDevices(aggregatedDevices, piaItems);
        const streamVaultProject = this.getProjectWithDevices(cameras);
        return streamVaultProject;
    }

    private getStreamVaultCalculatorJSONPayload(
        deviceRequirements: IDeviceRequirement[],
        bandwidthStorageEstimateForItems: Record<Id, BandwidthStorageEstimate>,
        piaItems: Record<PiaId, IPiaItem>,
        country: string,
        recommendationType: number,
        projectId?: string,
        projectUrl?: string,
    ): IStreamVaultJSONPayload {
        const counts = countBy(deviceRequirements, 'itemId');
        const uniqueDevices = this.getUniqDevices(deviceRequirements, counts);
        const aggregatedDevices = uniqueDevices.reduce((devices, device) => {
            const piaItem = device.productId ? piaItems[device.productId] : undefined;
            if (
                piaItem === undefined ||
                this.hasNoBandwidth(device, bandwidthStorageEstimateForItems) ||
                this.shouldBeExcluded(piaItem, device)
            ) {
                return devices;
            }

            devices.push(
                this.mapToAggregatedDevice(
                    device,
                    piaItem,
                    bandwidthStorageEstimateForItems,
                    piaItems,
                    counts,
                ),
            );
            return devices;
        }, new Array<IAggregatedDevice>());

        const cameras = this.getCamerasFromAggregatedDevices(aggregatedDevices, piaItems);
        const streamVaultProject = this.getProjectJSONWithDevices(
            cameras,
            country,
            recommendationType,
            projectId,
            projectUrl,
        );
        return streamVaultProject;
    }

    public async getGenetecRecommendations(
        deviceRequirements: IDeviceRequirement[],
        bandwidthStorageEstimateForItems: Record<Id, BandwidthStorageEstimate>,
        piaItems: Record<PiaId, IPiaItem>,
        country: string,
        recommendationType: number,
        projectId?: string,
    ): Promise<IPartnerRecommendationResponse> {
        const jsonPost = this.getStreamVaultCalculatorJSONPayload(
            deviceRequirements,
            bandwidthStorageEstimateForItems,
            piaItems,
            country,
            recommendationType,
            projectId,
        );
        const response =
            await this.genetecRecommendationCommunicator.postGetGenetecRecommendations(jsonPost);

        if (!response || !response.ok) {
            this.handleNetworkError(response);
            return {
                partnerRecommendation: [],
                error: true,
            };
        }

        const genetecRecommendations: IGenetecRecommendations = await response.json();

        const partnerRecommendations = genetecRecommendations.Recommendations.map(
            (recommendation, index) => {
                let summaryStorage = 0;
                let summaryBandwidth = 0;
                const partnerRecommendation: IPartnerRecommendation = {
                    name: t.genetecGROUP.solutionName(index + 1),

                    components: recommendation.Products.map((product) => {
                        const partnerComponent: IPartnerSystemComponent = {
                            name: product.LongProductName,
                            quantity: product.Quantity,
                            vendorName: 'genetec',
                            category: this.getPartnerCategory(product.ProductType),
                            dataSheetUrl: product.DatasheetUrl,
                            imageUrl: product.ProductImageUrl ?? undefined,
                            maxCameraCount: product.MaxNumberOfCameras,
                            maxRecordingBandwidthBits:
                                product.RecordingMaxThroughputMbps * 1_000_000,
                            maxRecordingStorageMegaBytes:
                                product.UsableStorageCapacityTB * 1_000_000,
                        };
                        summaryStorage += product.UsableStorageCapacityTB * 1_000_000;
                        summaryBandwidth += product.RecordingMaxThroughputMbps * 1_000_000;
                        return partnerComponent;
                    }),

                    description: `${format.storage(summaryStorage)} | ${format.bandwidth(
                        summaryBandwidth,
                    )}`,
                };
                return partnerRecommendation;
            },
        );

        // limit solutions to 4 (Genetec should also limit to 4, but limit on our side for safety's sake)
        return { partnerRecommendation: partnerRecommendations.slice(0, 4), error: false };
    }

    public async getGenetecProject(
        deviceRequirements: IDeviceRequirement[],
        bandwidthStorageEstimateForItems: Record<Id, BandwidthStorageEstimate>,
        piaItems: Record<PiaId, IPiaItem>,
        country: string,
        recommendationType: number,
        projectId?: string,
        projectUrl?: string,
    ): Promise<IGenetecProject | undefined> {
        const jsonPost = this.getStreamVaultCalculatorJSONPayload(
            deviceRequirements,
            bandwidthStorageEstimateForItems,
            piaItems,
            country,
            recommendationType,
            projectId,
            projectUrl,
        );
        const response =
            await this.genetecRecommendationCommunicator.postGetGenetecProject(jsonPost);

        if (!response || !response.ok) {
            this.handleNetworkError(response);
            return;
        }

        const project: IGenetecProject = await response.json();

        return project;
    }

    public async getGenetecProducts(countryCode: string): Promise<IPartnerSystemComponent[]> {
        const response =
            await this.genetecRecommendationCommunicator.getGenetecProducts(countryCode);

        if (!response) {
            this.handleNetworkError(response);
            return [];
        }
        const genetecProducts: IGenetecProducts = await response.json();

        const products = genetecProducts.Products.map((product) => {
            const genetecProduct: IPartnerSystemComponent = {
                name: product.LongProductName,
                quantity: product.Quantity,
                vendorName: 'genetec',
                // TODO get this information from genetec API (https://jira.se.axis.com/browse/WT-7903)
                category: this.getPartnerCategory(product.ProductType),
                dataSheetUrl: product.DatasheetUrl,
                imageUrl: product.ProductImageUrl ?? undefined,
                maxCameraCount: product.MaxNumberOfCameras,
                maxRecordingBandwidthBits: product.RecordingMaxThroughputMbps * 1_000_000,
                maxRecordingStorageMegaBytes: product.UsableStorageCapacityTB * 1_000_000,
            };
            return genetecProduct;
        });
        return products;
    }

    private getPartnerCategory(productType: number) {
        switch (productType) {
            case 0: // genetec Archiver
                return PartnerSystemItemCategory.SERVER;
            case 1: // genetec Directory
                return PartnerSystemItemCategory.SERVER;
            case 2: // genetec NAS
                return PartnerSystemItemCategory.SERVER;
            default:
                return PartnerSystemItemCategory.MISC;
        }
    }

    private handleNetworkError(response: Response | null): void {
        if (response) {
            eventTracking.logError(
                'Genetec response error',
                'GenetecStramVaultProjectService',
                response.status,
            );
        }
        toaster.error(t.genetecGROUP.communicationError, t.genetecGROUP.communicationErrorMessage);
    }

    private mapToAggregatedDevice(
        device: IDeviceRequirement,
        piaItem: IPiaItem,
        bandwidthStorageEstimateForItems: Record<Id, BandwidthStorageEstimate>,
        piaItems: Record<PiaId, IPiaItem>,
        counts: Dictionary<number>,
    ): IAggregatedDevice {
        let childDevices: IChildDevice[] = [];
        if (isMainUnit(piaItem)) {
            const sensors: IPersistence<IItemEntity>[] =
                this.currentProjectService.getDeviceChildren(device.itemId, 'sensorUnit');
            childDevices = sensors.map((sensor: IPersistence<IItemEntity>) => {
                const name = sensor.productId
                    ? piaItems[sensor.productId].name
                    : `${t.devicesGROUP.sensorUnit}`;
                return {
                    sensor,
                    total: bandwidthStorageEstimateForItems[sensor._id].total,
                    modelName: name,
                };
            });
        }
        if (isEncoder(piaItem)) {
            const sensors: IPersistence<IItemEntity>[] =
                this.currentProjectService.getDeviceChildren(device.itemId, 'analogCamera');
            childDevices = sensors.map((sensor: IPersistence<IItemEntity>) => {
                return {
                    sensor,
                    total: bandwidthStorageEstimateForItems[sensor._id].total,
                    modelName: `${t.devicesGROUP.analogCamera}`,
                };
            });
        }

        const totalEstimate: IAggregatedBandwidthAndStorage = this.getBandwidthStorageEstimate(
            bandwidthStorageEstimateForItems,
            device,
        );

        const aggregatedDevice: IAggregatedDevice = {
            ...device,
            deviceCount: counts[device.itemId],
            total: totalEstimate,
            childDevices,
        };
        return aggregatedDevice;
    }

    private getUniqDevices(
        deviceRequirements: IDeviceRequirement[],
        counts: Dictionary<number>,
    ): IDeviceRequirement[] {
        const uniqueDevices = uniqBy(deviceRequirements, 'itemId');
        const renamedUniqueDevices = uniqueDevices.map((device) => {
            // Device aggregation adds a '# [index]' to the device name if quantity
            // is greater than 1, this should not be in the exported file
            const hashIndex = device.name?.lastIndexOf('#') ?? -1;
            device.name =
                counts[device.itemId] > 1 && hashIndex > 0
                    ? device.name?.substring(0, hashIndex)
                    : device.name;
            return device;
        });
        return renamedUniqueDevices;
    }

    private getBandwidthStorageEstimate(
        bandwidthStorageEstimateForItems: Record<string, BandwidthStorageEstimate>,
        device: IDeviceRequirement,
    ) {
        const deviceTotal = bandwidthStorageEstimateForItems[device.itemId]?.total;
        const deviceEstimate: IAggregatedBandwidthAndStorage = {
            retentionTime: deviceTotal?.retentionTime ?? 0,
            storageInMB: deviceTotal?.storageInMB ?? 0,
            bandwidthInBps: deviceTotal?.bandwidthInBps ?? 0,
        };

        const totalEstimate = device.virtualChildren?.reduce((total, virtualChild) => {
            const virtualChildEstimate = bandwidthStorageEstimateForItems[virtualChild._id]?.total;
            if (virtualChildEstimate) {
                return {
                    ...total,
                    storageInMB: total.storageInMB + virtualChildEstimate.storageInMB,
                    bandwidthInBps: total.bandwidthInBps + virtualChildEstimate.bandwidthInBps,
                };
            }
            return total;
        }, deviceEstimate);

        return totalEstimate ?? deviceEstimate;
    }

    private getProjectJSONWithDevices(
        cameras: IStreamVaultCamera[],
        country: string,
        recommendationType: number,
        projectId?: string,
        projectUrl?: string,
    ): IStreamVaultJSONPayload {
        const sortByName = (a: IStreamVaultCamera, b: IStreamVaultCamera) =>
            a.Name.localeCompare(b.Name, undefined, { numeric: true });
        const sortedCamerasByName = cameras.sort(sortByName);
        return {
            ProjectName: 'AXIS project', //TODO: what should we call the project?
            ProjectNotes: this.getNotes(),
            Source: 'Axis',
            Cameras: sortedCamerasByName,
            ProjectId: projectId,
            ExternalProjectUrl: projectUrl,
            Version: 1,
            SuggestedServerInput: {
                Scenario: recommendationType,
                CountryCode: country,
            },
        };
    }

    private getProjectWithDevices(cameras: IStreamVaultCamera[]): IStreamVaultProject {
        const sortByName = (a: IStreamVaultCamera, b: IStreamVaultCamera) =>
            a.Name.localeCompare(b.Name, undefined, { numeric: true });
        const sortedCamerasByName = cameras.sort(sortByName);
        return {
            ProjectName: this.currentProjectService.getProjectEntity().name,
            ProjectNotes: this.getNotes(),
            Source: 'Axis',
            Cameras: sortedCamerasByName,
            ...getGenetecDefaultProps,
        };
    }

    private getCamerasFromAggregatedDevices(
        aggregatedDevices: IAggregatedDevice[],
        piaItems: Record<PiaId, IPiaItem>,
    ): IStreamVaultCamera[] {
        return aggregatedDevices.flatMap((device) => {
            const piaItem = device.productId ? piaItems[device.productId] : undefined;
            if (isMainUnit(piaItem) || isEncoder(piaItem)) {
                const cameras = device.childDevices.map((sensor: IChildDevice) => {
                    return {
                        Name: `${device.modelName} - ${sensor.modelName}`,
                        DaysOfRetention: sensor.total.retentionTime,
                        BitrateKbps:
                            this.getBandwidthInKiloBitPerSecond(sensor.total.bandwidthInBps) /
                            (device.deviceCount * sensor.sensor.quantity),
                        RecordingPercentage: this.getRecordingPercentage(
                            sensor.total.storageInMB,
                            sensor.total.bandwidthInBps,
                            sensor.total.retentionTime,
                        ),
                        NumberOfCameras: device.deviceCount * sensor.sensor.quantity,
                        ...getGenetecDefaultCameraProps,
                    };
                });
                return cameras;
            }
            const camera = {
                Name: this.getCameraName(piaItem),
                DaysOfRetention: device.total.retentionTime,
                BitrateKbps:
                    this.getBandwidthInKiloBitPerSecond(device.total.bandwidthInBps) /
                    device.deviceCount,
                RecordingPercentage: this.getRecordingPercentage(
                    device.total.storageInMB,
                    device.total.bandwidthInBps,
                    device.total.retentionTime,
                ),
                NumberOfCameras: device.deviceCount,
            };
            return { ...camera, ...getGenetecDefaultCameraProps };
        });
    }

    private getBandwidthInKiloBitPerSecond(bitsPerSecond: number): number {
        return Math.ceil(bitsPerSecond / 1000);
    }

    private getRecordingPercentage(
        storageInMegaByte: number,
        bandwidthInBitPerSecond: number,
        retentionTimeInDays: number,
    ): number {
        const storageInBytes = storageInMegaByte * 10 ** 6;
        const bandwidthInBytesPerDay = (bandwidthInBitPerSecond * 24 * 3600) / 8;
        let recordingPercentage = 100;
        if (bandwidthInBytesPerDay > 0 && retentionTimeInDays > 0) {
            recordingPercentage = Math.min(
                Math.round((storageInBytes * 100) / (bandwidthInBytesPerDay * retentionTimeInDays)),
                100,
            );
        }
        return recordingPercentage;
    }

    private getCameraName(piaItem: IPiaItem | undefined) {
        return `${piaItem?.name ?? t.genericCamera}`;
    }

    private getNotes() {
        return `${t.bandwidthReportDisclaimerText1} \n${t.bandwidthReportDisclaimerText}`;
    }

    private hasNoBandwidth(
        device: IDeviceRequirement,
        bandwidthStorageEstimateForItems: Record<string, BandwidthStorageEstimate>,
    ) {
        return bandwidthStorageEstimateForItems[device.itemId] === undefined;
    }

    private shouldBeExcluded(piaItem: IPiaItem | undefined, device: IDeviceRequirement) {
        return isBodyWornCamera(piaItem) || device.isVirtualCamera;
    }
}
