import * as React from 'react';
import { CircleAvatar } from '../circleAvatar';
import { Box } from '../../containers/box/Box.component';
import { Text } from '../../text';
import { Border } from '../../style/Border';
import type { Icons } from '../icon';

interface IPersona {
    primaryText: string;
    secondaryText: string;
    tertiaryText?: string;
    /** Icon to show instead of primary text initials */
    icon?: Icons;
}

export const Persona: React.FC<IPersona> = ({ primaryText, secondaryText, tertiaryText, icon }) => {
    return (
        <Box alignItems="start" flex="none" justifyContent="start" padding="half">
            <CircleAvatar name={primaryText} icon={icon} />
            <Box paddingLeft="half" direction="column">
                <Border bottomWidth={1} color="blackOpacity">
                    <Text style="semibold" whiteSpace="nowrap">
                        {primaryText}
                    </Text>
                </Border>
                <Box direction="column" spacing="quart">
                    <Text whiteSpace="nowrap">{secondaryText}</Text>
                    {tertiaryText && (
                        <Text color="grey6" whiteSpace="nowrap" style="small">
                            {tertiaryText}
                        </Text>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
