import { createCachedSelector } from 're-reselect';
import { getPiaIdFromProps } from './getIdFromProps';
import { getPiaItemsRecord } from '../piaDevices/selectors';
import { getCurrentProjectRegions } from '../project/selectors/getCurrentProject';
import { toCacheKey } from '../cacheKey/toCacheKey';

export const getOnlyMultipackForPiaItem = createCachedSelector(
    [getCurrentProjectRegions, getPiaItemsRecord, getPiaIdFromProps],
    (regions, piaItems, piaId): boolean => {
        if (!piaId) return false;

        const piaItem = piaItems[piaId];
        const versionsForRegion = piaItem.versions.filter((version) =>
            regions.some((region) => version.versions.includes(region) !== undefined),
        );

        return versionsForRegion.every((version) => version.scaleQuantity > 1);
    },
)(toCacheKey);
