import type { NavigateFunction, NavigateOptions } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

interface IHistory {
    navigate: NavigateFunction;
    push: (to: string, options: NavigateOptions) => void;
}

/** Solution to utilize routing history with useNavigate() hook outside of function components and without need for history middleware.
 * Inspired by https://stackoverflow.com/questions/68399876/how-to-navigate-outside-of-react-component-using-react-router-6 */
export const History: IHistory = {
    navigate: () => {},
    push: (page, ...rest) => {
        History.navigate(page, ...rest);
    },
};

/** Used in client.tsx to set History.navigate to useNavigate()
 * which enables access to History.push and History.navigate throughout the application. */
export const NavigateSetter = () => {
    History.navigate = useNavigate();
    return null;
};
