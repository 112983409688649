import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { Box, Button, Card, Clickable, Heading, Icon, Stack, Text } from 'app/components';
import {
    getEditDeviceId,
    getGenericProductInfo,
    getParentDeviceId,
    getSelectedProductId,
} from '../selectors';
import type { DeviceGroup, IGenericProductInfo } from '../models';
import { css } from '@emotion/css';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import type { IAddProductProps } from 'app/modules/common';
import { getCurrentProjectItem } from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../services';
import { useResponsive } from 'app/hooks';
import { AddProductModal } from './AddProductModal';
import { getFilterHasChanged } from '../selectors/getFilterHasChanged';

const genericItemStyle = css`
    position: relative;
    display: flex;
    border-radius: 8px;
    :hover {
        display: inline-block;
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    }
    transition: box-shadow 300ms ease-in-out;
`;

const selectedItemStyle = css`
    border-radius: 8px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
`;

const SELECTED_GENERIC_ID = -1;

export const GenericItem: React.FC = () => {
    const itemWidth = useResponsive('248px', '163px', '163px');
    const piaImageSize = useResponsive('xxl', 'xl', 'xl');
    const product = useSelector<IStoreState, IGenericProductInfo>((state) =>
        getGenericProductInfo(state),
    );
    const selectedId = useSelector<IStoreState, number | null>((state) =>
        getSelectedProductId(state),
    );
    const editDeviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        (state) => {
            const editDeviceId = getEditDeviceId(state);
            return editDeviceId ? getCurrentProjectItem(state, editDeviceId) : undefined;
        },
    );
    const parentDeviceId = useSelector<IStoreState, Id | null>(getParentDeviceId);

    const filterHasChanged = useSelector<IStoreState, boolean>(getFilterHasChanged);

    const [actionService] = React.useState(ServiceLocator.get(DeviceSelectorActionService));
    const [showAddProductModal, setShowAddProductModal] = React.useState<boolean>(false);

    // We're in edit mode if there is any editDeviceItem
    const isEditMode = !!editDeviceItem;
    // This is the current selected item if there is an editDeviceItem without a productId
    const isCurrentSelection = !!editDeviceItem && !editDeviceItem.productId;
    const selected = isCurrentSelection || selectedId === SELECTED_GENERIC_ID;

    const isGenericCamera = !!editDeviceItem && isCustomCamera(editDeviceItem);

    const setSelectedProduct = () => {
        // Don't change selection if this is the currently selected item.
        if (isCurrentSelection) {
            return;
        }
        actionService.setSelectedProductId(SELECTED_GENERIC_ID);
    };

    const categoriesWithoutGenerics: DeviceGroup[] = ['other', 'twoNProducts', 'wearables'];

    const toggleAddProductModalVisibility = (visible: boolean) => {
        setShowAddProductModal(visible);
    };

    const addOrUpdateProduct = () => {
        if (editDeviceItem) {
            updateProduct();
        } else {
            toggleAddProductModalVisibility(true);
        }
    };

    const addProduct = (addProductProps: IAddProductProps) => {
        parentDeviceId
            ? actionService.addOrUpdateChildDevice(null, parentDeviceId, undefined, addProductProps)
            : actionService.addOrUpdateDevice(null, undefined, addProductProps);
        setShowAddProductModal(false);
    };

    const updateProduct = () =>
        parentDeviceId
            ? actionService.addOrUpdateChildDevice(null, parentDeviceId, editDeviceItem)
            : actionService.addOrUpdateDevice(null, editDeviceItem);

    if (categoriesWithoutGenerics.includes(product.deviceGroup)) {
        return null;
    }

    return (
        <React.Fragment>
            {showAddProductModal && (
                <AddProductModal
                    productInfo={{
                        productId: null,
                        name: t.newDevice,
                    }}
                    parentDeviceId={parentDeviceId}
                    onClose={() => toggleAddProductModalVisibility(false)}
                    onAddProduct={addProduct}
                    deviceGroup={product.deviceGroup}
                />
            )}

            {product.deviceGroup !== 'cameras' && product.deviceGroup !== 'accessControls' && (
                <Box paddingTop="base" paddingBottom="half" alignItems="start">
                    <Heading>{t.pickModelLater}</Heading>
                </Box>
            )}
            <Stack vertical spacing="none" alignItems="center" notFullWidth>
                <div className={selected ? selectedItemStyle : genericItemStyle}>
                    <Card
                        color={isCurrentSelection ? 'yellow1' : 'white'}
                        notFullWidth
                        hideOverflow
                        borderColor={selected ? 'yellow' : 'grey3'}
                    >
                        <Clickable onClick={setSelectedProduct}>
                            <Box
                                direction="column"
                                paddingBottom="base"
                                alignItems="center"
                                width={itemWidth}
                                height={product.deviceGroup === 'cameras' ? '340px' : '270px'}
                            >
                                <Box padding="panel">
                                    <Icon
                                        icon={isGenericCamera ? 'videocam' : product.icon}
                                        size={piaImageSize}
                                        color="grey7"
                                    />
                                </Box>

                                <Box
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="between"
                                    height="100%"
                                >
                                    <Box direction="column" alignItems="center" paddingX="base">
                                        <Box minHeight="40px">
                                            <Heading align="center">
                                                {isGenericCamera
                                                    ? editDeviceItem?.properties.camera
                                                          ?.customCameraProperties?.modelName
                                                    : t.pickModelLater}
                                            </Heading>
                                        </Box>
                                        <Text inline color="grey6" align="center">
                                            {t.requirementsSaved}
                                        </Text>
                                    </Box>
                                    <Box padding="quart">
                                        <Button
                                            primary={selected}
                                            onClick={addOrUpdateProduct}
                                            disabled={isCurrentSelection && !filterHasChanged}
                                        >
                                            {isEditMode ? t.change : t.add}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Clickable>
                    </Card>
                </div>
            </Stack>
        </React.Fragment>
    );
};
