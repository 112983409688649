import type { IOptionProps } from 'app/components';
import { Box, Select } from 'app/components';
import type { CameraStationType } from 'app/core/persistence';
import { useService } from 'app/ioc';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getViewingAxisAcsType } from '../../selectors';
import { RecordingSelectorActionService } from '../../services';
import { eventTracking } from 'app/core/tracking';

const selectTypeOptions: IOptionProps[] = [
    {
        text: 'AXIS Camera Station',
        value: 'CameraStationPro',
    },
    {
        text: 'AXIS Camera Station 5',
        value: 'CameraStation5',
    },
];

export const AxisSelectType: React.FC = () => {
    const recordingSelectorActionService = useService(RecordingSelectorActionService);
    const viewingAcsType = useSelector(getViewingAxisAcsType);

    const onChangeAxisType = (type: CameraStationType) => {
        eventTracking.logUserEvent('System Components', 'Select ACS type', type);
        recordingSelectorActionService.setViewingAxisAcsType(type);
    };

    return (
        <Box height={40}>
            <Select
                testId="select_acs_type"
                testIdChild="axis_select_type"
                fillDropdownWidth
                fillWidth
                fullHeight
                options={selectTypeOptions}
                onChange={onChangeAxisType}
                value={viewingAcsType}
                underline
                adjustWidthToLongestOption={false}
            />
        </Box>
    );
};
AxisSelectType.displayName = 'SelectSolutionType';
