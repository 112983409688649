import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { IInstallationPointModel } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { LeafletItemFactory, MapsService, MapsActionService } from '../../services';
import type { LeafletMap } from './LeafletMap';
import {
    getLabelPos,
    getLabelLineOffsetEndPoint,
    getLabelOffset,
    getLabelLineEndPoint,
} from '../../utils';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import {
    getIsFocused,
    getZoomLevel,
    getSelectedInstallationPoint,
    getUseTinyIcons,
} from '../../selectors';
import {
    useDragEndEvent,
    useDragEvent,
    useMouseClickEvent,
} from '../../hooks/useLeafletMouseEvent';
import { getIndexForInstallationPointId } from '../../selectors/getInstallationPointIndex';

interface IInstallationPointLabelProps {
    installationPoint: IInstallationPointModel;
    map: LeafletMap;
    color: Colors;
}

export const InstallationPointLabel: React.FC<IInstallationPointLabelProps> = ({
    installationPoint,
    map,
    color,
}) => {
    const index = useSelector<IStoreState, number>((state) =>
        installationPoint.parentDevice.quantity > 1
            ? getIndexForInstallationPointId(state, installationPoint._id)
            : 0,
    );
    const zoomLevel = useSelector(getZoomLevel);
    const isOpaque = useSelector<IStoreState, boolean>((state) =>
        getIsFocused(state, installationPoint._id),
    );
    const selectedInstallationPoint = useSelector(getSelectedInstallationPoint);
    const useTinyIcons = useSelector<IStoreState, boolean>(getUseTinyIcons);

    const [mapsService] = React.useState(ServiceLocator.get(MapsService));
    const [factory] = React.useState(ServiceLocator.get(LeafletItemFactory));
    const [actions] = React.useState(ServiceLocator.get(MapsActionService));
    const label = React.useMemo(() => {
        return factory.createLabel(
            installationPoint.parentDevice.name,
            installationPoint.name ?? '',
            useTinyIcons ? 'white' : color,
            index,
            installationPoint.parentDevice.quantity,
        );
    }, [
        installationPoint.parentDevice.name,
        installationPoint.parentDevice.quantity,
        installationPoint.name,
        factory,
        useTinyIcons,
        color,
        index,
    ]);
    const [draggableLabel] = React.useState(
        factory.createInteractiveItem(
            getLabelPos(
                installationPoint.location,
                installationPoint.labelOffset,
                map,
                map.map.getZoom(),
            ),
            label,
        ),
    );

    draggableLabel
        .getElement()
        ?.setAttribute('data-test-id', `device_label_${installationPoint.name}_${index}`);

    const [labelLine] = React.useState(
        factory.createDashedPolyline(
            [
                installationPoint.location,
                getLabelLineOffsetEndPoint(
                    installationPoint.location,
                    installationPoint.labelOffset,
                    map,
                ),
            ],
            color,
        ),
    );

    React.useMemo(() => {
        draggableLabel.setLatLng(
            getLabelPos(installationPoint.location, installationPoint.labelOffset, map, zoomLevel),
        );
        labelLine.setLatLngs([
            installationPoint.location,
            getLabelLineOffsetEndPoint(
                installationPoint.location,
                installationPoint.labelOffset,
                map,
            ),
        ]);
    }, [
        draggableLabel,
        installationPoint.labelOffset,
        installationPoint.location,
        labelLine,
        map,
        zoomLevel,
    ]);

    useDragEvent(draggableLabel, (e) => {
        labelLine.setLatLngs([
            installationPoint.location,
            getLabelLineEndPoint(installationPoint.location, e.latlng, map),
        ]);
    });
    useDragEndEvent(draggableLabel, () => {
        const newLabelOffset = getLabelOffset(
            installationPoint.location,
            draggableLabel.getLatLng(),
            map,
        );
        const hasMoved = newLabelOffset !== installationPoint.labelOffset;
        if (hasMoved) {
            mapsService.updateInstallationPointLabelOffset(installationPoint._id, newLabelOffset);
        }
    });

    useMouseClickEvent(draggableLabel, () => {
        actions.selectMapItem(installationPoint, 'installationPoint');
    });

    React.useEffect(() => {
        draggableLabel.setLatLng(
            getLabelPos(
                installationPoint.location,
                installationPoint.labelOffset,
                map,
                map.map.getZoom(),
            ),
        );
        labelLine.setLatLngs([
            installationPoint.location,
            getLabelLineOffsetEndPoint(
                installationPoint.location,
                installationPoint.labelOffset,
                map,
            ),
        ]);
    }, [draggableLabel, installationPoint.labelOffset, installationPoint.location, labelLine, map]);

    React.useEffect(() => {
        label && draggableLabel.setIcon(label);
    }, [draggableLabel, label]);

    React.useEffect(() => {
        labelLine.setStyle({ color: ColorsEnum[color] });
    }, [color, labelLine]);

    React.useEffect(() => {
        if (isOpaque) {
            draggableLabel?.getElement()?.removeAttribute('data-blurred');
            labelLine.setStyle({ opacity: 1 });
        } else {
            draggableLabel?.getElement()?.setAttribute('data-blurred', 'true');
            labelLine.setStyle({ opacity: 0.4 });
        }
    }, [isOpaque, draggableLabel, labelLine]);

    React.useEffect(() => {
        if (selectedInstallationPoint?._id === installationPoint._id) {
            draggableLabel?.setZIndexOffset(1000);
        } else {
            draggableLabel?.setZIndexOffset(0);
        }
    }, [draggableLabel, installationPoint._id, selectedInstallationPoint]);

    React.useEffect(() => {
        draggableLabel.addTo(map.map);
        labelLine.addTo(map.map);

        return () => {
            draggableLabel.removeFrom(map.map);
            labelLine.removeFrom(map.map);
        };
    }, [draggableLabel, labelLine, map]);

    return null;
};

InstallationPointLabel.displayName = 'InstallationPointLabel';
