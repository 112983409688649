import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Icon, type Icons, Text, Border, Title, RadioButton } from 'app/components';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import {
    getAnalyticRangeForItem,
    getIsThermal,
    getRequiresAPDIRIllumination,
} from 'app/modules/common';
import type {
    Id,
    AnalyticRangeWeatherCondition,
    AnalyticRangeDayCondition,
    IDeviceAnalyticRange,
} from 'app/core/persistence';

const weatherConditions: { id: AnalyticRangeWeatherCondition; icon: Icons }[] = [
    {
        id: 'clear',
        icon: 'sunny',
    },
    {
        id: 'fog',
        icon: 'foggy',
    },
    {
        id: 'heavy_fog',
        icon: 'foggy_heavy',
    },
];

const dayConditions: { id: AnalyticRangeDayCondition; icon: Icons }[] = [
    {
        id: 'day',
        icon: 'sunny',
    },
    {
        id: 'night',
        icon: 'nightlight',
    },
];

interface IWeatherConditionsSelectorProps {
    itemId: Id;
    onSelectWeather: (weatherCondition: AnalyticRangeWeatherCondition) => void;
    onSelectDay: (dayCondition: AnalyticRangeDayCondition) => void;
}
export const WeatherConditionsSelector: React.FC<IWeatherConditionsSelectorProps> = ({
    itemId,
    onSelectWeather,
    onSelectDay,
}) => {
    const analyticRange = useSelector<IStoreState, IDeviceAnalyticRange | undefined>((store) =>
        getAnalyticRangeForItem(store, itemId),
    );

    const requiresIrIllumination = useSelector<IStoreState, boolean>((store) =>
        getRequiresAPDIRIllumination(store, itemId),
    );

    const isThermalDevice = useSelector<IStoreState, boolean>((store) =>
        getIsThermal(store, itemId),
    );

    const getHoverTextDay = (id: AnalyticRangeDayCondition) => {
        if (id === 'day') {
            return t.analyticDayLight;
        } else if (id === 'night') {
            return t.analyticDayDark;
        }
        return '';
    };

    const getHoverTextWeather = (id: AnalyticRangeWeatherCondition) => {
        if (id === 'clear') {
            return t.analyticWeatherClear;
        } else if (id === 'fog') {
            return t.analyticWeatherFog;
        } else if (id === 'heavy_fog') {
            return t.analyticWeatherHeavyFog;
        }
        return '';
    };

    return (
        <Box
            direction="row"
            paddingY="quart"
            paddingX="half"
            width="100%"
            alignItems="center"
            testId="analytic_weather_conditions"
        >
            {/* light settings */}
            <Box direction="column" width="40%" alignItems="center" paddingRight="half">
                <Box alignItems="center" spacing="quart">
                    <Text color="grey7" style="small">
                        {t.analyticModeLight}
                    </Text>
                    {!isThermalDevice && requiresIrIllumination && (
                        <Icon
                            testId={`ir_warning`}
                            opaque
                            icon="warning"
                            size="xs"
                            color="yellow"
                            title={t.applicationDetectionRangeIRIlluminatorRequired}
                        />
                    )}
                </Box>
                <Box width="100%">
                    {dayConditions.map(({ id, icon }) => {
                        const selected = id === analyticRange?.dayCondition ?? 'day';
                        const color = selected ? 'grey9' : 'grey6';

                        return (
                            <Title key={`title_${id}`} title={getHoverTextDay(id)}>
                                <Box key={id} width="100%">
                                    <RadioButton
                                        testId={`day_selection_${id}`}
                                        fullWidth
                                        isActive={selected}
                                        paddingY="half"
                                        paddingX="none"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onSelectDay(id);
                                        }}
                                    >
                                        <Icon
                                            testId={id}
                                            opaque
                                            icon={icon}
                                            size="sm"
                                            color={color}
                                        />
                                        {!isThermalDevice && id === 'night' && (
                                            <Text
                                                style="small"
                                                color={color}
                                                testId="weather_day_night_thermal"
                                            >
                                                +IR
                                            </Text>
                                        )}
                                    </RadioButton>
                                </Box>
                            </Title>
                        );
                    })}
                </Box>
            </Box>

            {/* fog settings */}
            <Box direction="column" width="60%" alignItems="center">
                <Box alignItems="center" paddingLeft="half">
                    <Text color="grey7" style="small">
                        {t.analyticModeFog}
                    </Text>
                </Box>
                <Border leftWidth={1} color="grey3">
                    <Box width="100%" paddingLeft="half">
                        {weatherConditions.map(({ id, icon }) => {
                            const selected = id === analyticRange?.weatherCondition ?? 'clear';
                            const color = selected ? 'grey9' : 'grey6';

                            return (
                                <Title key={`title_${id}`} title={getHoverTextWeather(id)}>
                                    <Box key={id} width="100%">
                                        <RadioButton
                                            testId={`weather_selection_${id}`}
                                            fullWidth
                                            isActive={selected}
                                            paddingY="half"
                                            paddingX="half"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                onSelectWeather(id);
                                            }}
                                        >
                                            <Icon
                                                testId={id}
                                                opaque
                                                icon={icon}
                                                size="sm"
                                                color={color}
                                            />
                                        </RadioButton>
                                    </Box>
                                </Title>
                            );
                        })}
                    </Box>
                </Border>
            </Box>
        </Box>
    );
};

WeatherConditionsSelector.displayName = 'WeatherConditionsSelector';
