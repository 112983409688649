import { injectable } from 'inversify';
import { isEqual, groupBy, flatMap } from 'lodash-es';
import type { IBaseEntity, Id, IEntity } from '../entities';
import type { IIdRev } from '../models';
import { RepositoryConflictError, RepositoryEntityNotFoundError } from './errors';
import type { IPersistence, IPersistenceRepository } from './persistence';
import {
    EntitySettings,
    PersistenceDatabaseRepository,
    TimestampProviderService,
    CreateEntityService,
    PersistenceMemoryRepository,
} from './persistence';
import { RepositoryAction } from './RepositoryAction';
import type { IGetDescendants } from './models';
import { pushUndoCommand, pushRedoCommand, clearRedoQueue } from './utils';
import type { ICommand } from '../../models';

@injectable()
export abstract class BaseRepository<TEntity extends IBaseEntity> {
    constructor(
        private entitySettings: EntitySettings,
        protected persistenceRepository: PersistenceDatabaseRepository,
        private persistenceMemoryRepository: PersistenceMemoryRepository,
        private timestampProvider: TimestampProviderService,
        protected createEntityService: CreateEntityService,
    ) {}

    /**
     * Retrieves all entities of prefixed type.
     */
    public async getAll(): Promise<Array<IPersistence<TEntity>>> {
        return (await this.getRepository().getAll(
            this.prefix() + this.entitySettings.databaseDelimiter,
        )) as Array<IPersistence<TEntity>>;
    }

    /**
     * Retrieves the latest single item using its id.
     * @param id the id of the item to retrieve.
     */
    public async get(id: Id): Promise<IPersistence<TEntity>> {
        return this.getRepository().get(id) as Promise<IPersistence<TEntity>>;
    }

    public getDbName(): string {
        return this.persistenceRepository.getDbName();
    }

    /**
     * Gets descendants for parent.
     * @param parentId the id for the parent.
     */
    public async getDescendants<ParentType extends IBaseEntity>(
        parentId: Id,
    ): Promise<IGetDescendants<ParentType, TEntity>> {
        const descendants = await this.getRepository().getDescendants(parentId);
        return {
            parent: descendants[0] as IPersistence<ParentType>,
            descendants: descendants.filter(
                this.filterDescendants<IPersistence<TEntity>>(this.prefix()),
            ),
        };
    }

    /**
     * Adds a new item.
     * @param entity the item to add.
     * @param undoable whether a reverting action should be added to the undo stack.
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    public async add(
        entity: TEntity,
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
    ): Promise<IPersistence<TEntity>> {
        const newEntity = this.createEntityService.create(this.prefix(), entity);
        return this.addEntity(newEntity, undoable, redoable, clearRedoHistory);
    }

    public async bulkAdd(
        entities: TEntity[],
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
    ): Promise<IPersistence<TEntity>[]> {
        const newEntities = entities.map((entity) =>
            this.createEntityService.create(this.prefix(), entity),
        );
        return this.bulkAddEntities(newEntities, undoable, redoable, clearRedoHistory);
    }

    /**
     * Adds a new entity with the specified id
     * @param entity the item to add.
     * @param id the desired id (use createNewEntityId() to obtain one)
     * @param undoable whether a reverting action should be added to the undo stack.
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    public addWithId(
        entity: TEntity,
        id: Id,
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
    ): Promise<IPersistence<TEntity>> {
        const newEntity = this.createEntityService.createWithId(id, entity);
        return this.addEntity(newEntity, undoable, redoable, clearRedoHistory);
    }

    /**
     * Creates a new entity id for use with addWithId()
     * @return a new Id
     */
    public createNewEntityId(): Id {
        return this.createEntityService.generateDatabaseId(this.prefix());
    }

    /**
     * Bulk updates items.
     * @param entities the updated items.
     * @param undoable whether a reverting action should be added to the undo stack
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                         to true since all "normal" actions should do this.
     *                         When executing an undo or redo, it is set to false
     * @param keepUpdatedDate whether to keep the updatedDate of the entities
     */
    public async bulkUpdate(
        entities: IPersistence<TEntity>[],
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
        keepUpdatedDate = false,
    ): Promise<IPersistence<TEntity>[]> {
        const revertingCommand: ICommand = {
            updates: [],
        };
        const updatedEntities: IPersistence<TEntity>[] = [];
        for (const entity of entities) {
            const old = await this.get(entity._id);
            const revertingChange = this.diff(entity, old);

            // Push the reverting change to the reverting command
            revertingCommand.updates?.push({
                id: entity._id,
                props: revertingChange,
            });

            // Push the updated entity to the updated entities
            updatedEntities.push(keepUpdatedDate ? entity : this.updateEntity(entity));
        }

        // Bulk update the entities
        const result = (await this.getRepository().bulkUpdate(
            updatedEntities,
        )) as unknown as Promise<IPersistence<TEntity>[]>;

        if (undoable) {
            pushUndoCommand(revertingCommand);
        }
        if (redoable) {
            pushRedoCommand(revertingCommand);
        }
        if (clearRedoHistory) {
            clearRedoQueue();
        }
        return result;
    }

    /**
     * Updates an item.
     * @param entity the updated item.
     * @param undoable whether a reverting action should be added to the undo stack
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    public async update(
        entity: IPersistence<TEntity>,
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
        keepUpdatedDate = false,
    ): Promise<IPersistence<TEntity>> {
        const bulkResult = await this.bulkUpdate(
            [entity],
            undoable,
            redoable,
            clearRedoHistory,
            keepUpdatedDate,
        );

        return bulkResult[0];
    }

    /**
     * Updates an item.
     * @param entity the updated item.
     * @param undoable whether a reverting action should be added to the undo stack
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    public async updatePartial(
        id: Id,
        props: Partial<TEntity>,
        undoable = false,
        redoable = false,
        clearRedoHistory = false,
    ): Promise<IPersistence<TEntity>> {
        const old = await this.get(id);
        const updatedEntity = {
            ...old,
            ...props,
        };
        return this.update(updatedEntity, undoable, redoable, clearRedoHistory);
    }

    /**
     * Set the updatedDate an item.
     * @param id the id of the item to update
     * @param updatedDate the new updatedDate
     */
    public async setUpdatedDate(id: Id, updatedDate: Date): Promise<IPersistence<TEntity>> {
        const old = await this.get(id);
        const updatedEntity = {
            ...old,
            updatedDate: updatedDate.toISOString(),
        };

        return this.writeEntity(updatedEntity);
    }

    public async deleteById(
        id: Id,
        undoable = false,
        redoable = false,
        clearRedoHistory = false,
    ): Promise<Id> {
        const entity = await this.get(id);
        if (entity) {
            return this.delete(entity._id, entity._rev, undoable, redoable, clearRedoHistory);
        } else {
            return id; // already deleted
        }
    }

    /**
     * Deletes an item.
     * @param id id of item to delete.
     * @param rev revision of item to delete.
     * @param undoable whether a reverting action should be added to the undo stack
     * @param redoable whether a reverting action should be added to the redo stack
     * @param clearRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    public async delete(
        id: Id,
        rev: string,
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
    ): Promise<Id> {
        await this.bulkDelete([{ _id: id, _rev: rev }], undoable, redoable, clearRedoHistory);
        return id;
    }

    public async bulkDelete(
        idRevs: IIdRev[],
        undoable = true,
        redoable = false,
        clearRedoHistory = true,
    ): Promise<IIdRev[]> {
        const entitiesToDeleteWithDescendants = await Promise.all(
            idRevs.map((idRev) => this.getRepository().getDescendants(idRev._id)),
        );

        entitiesToDeleteWithDescendants.forEach((entities, index) => {
            if (entities.length === 0) {
                throw new RepositoryEntityNotFoundError(RepositoryAction.Delete, idRevs[index]._id);
            }
        });

        entitiesToDeleteWithDescendants.forEach((entities, index) => {
            if (entities[0]._rev !== idRevs[index]._rev) {
                throw new RepositoryConflictError(RepositoryAction.Delete, [
                    { id: idRevs[index]._id, rev: idRevs[index]._rev, ok: false },
                ]);
            }
        });

        const entityBatches = this.partitionByDepth(flatMap(entitiesToDeleteWithDescendants));

        for (const entityBatch of entityBatches) {
            await this.getRepository().bulkDelete(entityBatch);

            const revertingCommand = {
                creates: entityBatch.map((entity) => ({
                    props: entity,
                })),
            };

            if (undoable) {
                pushUndoCommand(revertingCommand);
            }
            if (redoable) {
                pushRedoCommand(revertingCommand);
            }
        }

        if (clearRedoHistory) {
            clearRedoQueue();
        }
        return idRevs;
    }

    /**
     * Locks an item and all its descendants.
     * @param id id of item to lock.
     * @param lockState lock flag
     */
    public async setLock(id: string, lockState: boolean): Promise<void> {
        const entityWithDescendants = await this.getRepository().getDescendants(id);
        if (entityWithDescendants.length === 0) {
            throw new RepositoryEntityNotFoundError(RepositoryAction.Lock, id);
        }

        // set lock flag to all entities
        const lockedEntities = entityWithDescendants.map((entity) => ({
            ...entity,
            locked: lockState,
        }));

        // partition entities in update batches grouped and ordered by path length
        const batches = this.partitionByDepth(lockedEntities);

        // if we are locking an item, traverse the tree starting at the leaves (longest path)
        // otherwise, traverse the tree starting at the root
        if (lockState) {
            batches.reverse();
        }

        // update the batches one by one
        for (const batch of batches) {
            await this.getRepository().bulkUpdate(batch);

            // make locking undoable
            const revertingCommand = {
                updates: batch.map((item) => ({
                    id: item._id,
                    props: { locked: !lockState },
                })),
            };

            pushUndoCommand(revertingCommand);
        }
    }

    /**
     * Archives an entity and its descendants
     * @param id id of item to archive
     * @param archived archived state
     */
    public async setArchived(id: string, archived: boolean): Promise<void> {
        const entityWithDescendants = await this.getRepository().getDescendants(id);
        if (entityWithDescendants.length === 0) {
            throw new RepositoryEntityNotFoundError(RepositoryAction.Lock, id);
        }

        // set archived flag on all entities
        const archivedEntities = entityWithDescendants.map((entity) => ({
            ...entity,
            archived,
        }));

        // partition entities in update batches grouped and ordered by path length
        const batches = this.partitionByDepth(archivedEntities);

        // if we are archiving an item, traverse the tree starting at the root,
        // otherwise traverse the tree starting at the leaves
        if (!archived) {
            batches.reverse();
        }

        // update the batches one by one
        for (const batch of batches) {
            await this.getRepository().bulkUpdate(batch);

            // make archiving undoable
            const revertingCommand = {
                updates: batch.map((item) => ({
                    id: item._id,
                    props: { archived: !archived },
                })),
            };

            pushUndoCommand(revertingCommand);
        }
    }

    public getRepository(): IPersistenceRepository {
        if (this.useMemoryRepository()) {
            return this.persistenceMemoryRepository;
        } else {
            return this.persistenceRepository;
        }
    }

    /**
     * Filter outs descendants based on prefix and that its not first in the array (it is always the parent)
     */
    protected filterDescendants =
        <T extends IEntity>(prefix: string) =>
        (descendant: IEntity, index: number): descendant is T => {
            return index !== 0 && this.isEntityOfType(prefix, descendant);
        };

    /**
     * Checks if the entity belongs to the type of the prefix
     *
     * @param prefix database prefix that determent the type e.g. `item:123`
     * @param entity entity to see if it belongs to type
     */
    protected isEntityOfType<T extends IEntity>(prefix: string, entity: IEntity): entity is T {
        // If the descendant has been removed then it has no id, so we need to handle that
        // the id can be undefined
        return entity._id?.startsWith(prefix + this.entitySettings.databaseDelimiter);
    }

    /**
     * Prefix for the _id in the database
     */
    protected abstract prefix(): string;

    /**
     * Adds an entity and its corresponding undo/redo command
     * @param entity the entity to add.
     * @param undoable whether a reverting action should be added to the undo stack.
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    private addEntity(
        entity: IEntity,
        undoable: boolean,
        redoable: boolean,
        clearRedoHistory: boolean,
    ): Promise<IPersistence<TEntity>> {
        const result = this.getRepository().add(entity) as Promise<IPersistence<TEntity>>;

        const revertingCommand = {
            deletes: [
                {
                    id: entity._id,
                },
            ],
        };

        if (undoable) {
            pushUndoCommand(revertingCommand);
        }
        if (redoable) {
            pushRedoCommand(revertingCommand);
        }
        if (clearRedoHistory) {
            clearRedoQueue();
        }

        return result;
    }

    /**
     * Adds multiple entities and its corresponding undo/redo command
     * @param entities the entity to add.
     * @param undoable whether a reverting action should be added to the undo stack.
     * @param redoable whether a reverting action should be added to the redo stack
     * @param cleareRedoHistory whether the redo stack should be cleared. Defaults
     *                          to true since all "normal" actions should do this.
     *                          When executing an undo or redo, it is set to false
     */
    private bulkAddEntities(
        entities: IEntity[],
        undoable: boolean,
        redoable: boolean,
        clearRedoHistory: boolean,
    ): Promise<IPersistence<TEntity>[]> {
        const result = this.getRepository().bulkAdd(entities) as Promise<IPersistence<TEntity>[]>;

        const revertingCommand = {
            deletes: entities.map((entity) => ({ id: entity._id })),
        };

        if (undoable) {
            pushUndoCommand(revertingCommand);
        }
        if (redoable) {
            pushRedoCommand(revertingCommand);
        }
        if (clearRedoHistory) {
            clearRedoQueue();
        }

        return result;
    }

    /**
     * Partitions an array of entities grouping, and sorting by depth (path length)
     * We need this to make sure that we never have a state where a locked parent
     * has an unlocked child.
     */
    private partitionByDepth(entities: IEntity[]): IEntity[][] {
        const entitiesGroupedByDepth = groupBy(entities, ({ path }) => path.length);
        return Object.keys(entitiesGroupedByDepth)
            .sort()
            .map((key) => entitiesGroupedByDepth[key]);
    }

    private updateEntity(entity: IPersistence<TEntity>): IPersistence<TEntity> {
        entity.updatedDate = this.timestampProvider.getIsoTimestamp();
        return entity;
    }

    private useMemoryRepository() {
        return this.entitySettings.isStandalone;
    }

    private diff<T extends Record<keyof T, unknown>>(a: T, b: T): Partial<T> {
        const result: Partial<T> = {};
        let key: keyof T;

        for (key in { ...a, ...b }) {
            const bValue = b[key];
            const aValue = a[key];

            if (!isEqual(aValue, bValue)) {
                result[key] = bValue;
            }
        }

        return result;
    }

    /**
     * Writes an update to the persistence layer
     * @param entity the item to store.
     */
    public async writeEntity(entity: IEntity): Promise<IPersistence<TEntity>> {
        return (await this.getRepository().update(entity)) as unknown as Promise<
            IPersistence<TEntity>
        >;
    }
}
