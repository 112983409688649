import * as React from 'react';
import { t } from 'app/translate';
import { Box, Border, Text, Linkable, Clickable, Stack, DragHandle } from 'app/components';
import type { Id, DeviceAndSubType } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { useDrag } from '../../../../hooks';

const genericCamera = require('assets/images/map-generic-camera.svg');
const genericMainUnit = require('assets/images/map-generic-main-unit.svg');
const genericSensorUnit = require('assets/images/map-generic-sensor-unit.svg');
const genericEncoder = require('assets/images/map-generic-encoder.svg');
const genericSpeaker = require('assets/images/map-generic-speaker.svg');
const genericAnalogCamera = require('assets/images/map-generic-analog-camera.svg');
const genericRadar = require('assets/images/map-generic-radar.svg');
const genericDoorController = require('assets/images/map-generic-door-controller.svg');
const genericDoor = require('assets/images/map-generic-door.svg');

export const AddDeviceMenuItem: React.FunctionComponent<{
    locked: boolean;
    link?: string;
    deviceType: DeviceAndSubType;
    text?: string;
    parentId?: Id;
    testId?: string;
}> = ({ locked, link, deviceType, text, parentId, testId }) => {
    const dragRef = React.useRef<HTMLDivElement>(null);
    const dragImageRef = React.useRef<HTMLDivElement>(null);

    const getGenericImage = () => {
        switch (deviceType) {
            case 'camera':
                return genericCamera;
            case 'mainUnit':
                return genericMainUnit;
            case 'sensorUnit':
                return genericSensorUnit;
            case 'encoder':
                return genericEncoder;
            case 'analogCamera':
                return genericAnalogCamera;
            case 'speaker':
                return genericSpeaker;
            case 'radardetector':
                return genericRadar;
            case 'doorcontroller':
                return genericDoorController;
            case 'door':
                return genericDoor;
            default:
                throw new Error(`No generic image for ${deviceType}`);
        }
    };
    const genericImage = getGenericImage();

    useDrag({
        effect: 'link',
        ref: dragRef,
        imageRef: dragImageRef,
        imageOffsetX: 25,
        imageOffsetY: 25,
        data: { type: deviceType, id: '', parentId },
    });

    return (
        <div style={{ cursor: 'move', display: 'flex', flex: '1 0 0%' }}>
            <Border width={1} color="grey3" radius="4px">
                <Linkable
                    link={!link || locked ? undefined : link}
                    onNavigate={() =>
                        eventTracking.logUserEvent(
                            'Maps',
                            `Add new generic device clicked: ${deviceType}`,
                        )
                    }
                >
                    <Clickable disabled={locked} allowDragThrough>
                        <Box
                            testId={testId}
                            innerRef={dragRef}
                            padding="quart"
                            color="white"
                            alignItems="center"
                            width="100%"
                            minHeight="66px"
                        >
                            <Box padding="base" paddingLeft="half" flex="grow" minWidth="0">
                                <Stack vertical notFullWidth spacing="halfQuart">
                                    <Box width="150px">
                                        <Text style="semibold" color="grey8" whiteSpace="nowrap">
                                            {text}
                                        </Text>
                                    </Box>
                                    <Box width="150px">
                                        <Text style="small" color="grey6" whiteSpace="nowrap">
                                            <>
                                                {link && t.clickOrDrag}
                                                {!link && t.dragToMap}
                                            </>
                                        </Text>
                                    </Box>
                                </Stack>
                            </Box>

                            <Border color="grey2" width={3} radius="50%">
                                <Box
                                    innerRef={dragImageRef}
                                    color="white"
                                    padding="halfCell"
                                    width="48px"
                                    height="48px"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img src={genericImage} width="24" height="24" />
                                </Box>
                            </Border>
                            <DragHandle />
                        </Box>
                    </Clickable>
                </Linkable>
            </Border>
        </div>
    );
};

AddDeviceMenuItem.displayName = 'AddDeviceMenuItem';
