import type { IRelatedPiaItem } from './../models/IRelatedPiaItem';
import { nameComparator } from 'app/utils';
import { getPiaItemsRecord } from 'app/modules/common';
import { createSelector } from 'reselect';
import { getPartnerConfigProducts } from './getDevices';
import type { PiaId } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import { isAccessory } from 'app/core/persistence';

export const getAccessoriesRecord = createSelector(
    [getPiaItemsRecord, getPartnerConfigProducts],
    (piaItems, partnerProducts) => {
        return partnerProducts.reduce(
            (accessoriesByProduct, partnerProduct) => {
                const relatedItems = piaItems[partnerProduct.id].relations
                    .map((relation) => {
                        return {
                            ...piaItems[relation.id],
                            isIncluded: relation.relationType === 'includes',
                            isRecommended: relation.relationType === 'recommends',
                            isSelected:
                                partnerProducts.find((product) => product.id === relation.id) !==
                                undefined,
                        } as IRelatedPiaItem;
                    })
                    .filter(
                        (relatedItem) =>
                            relatedItem.state === PiaItemState.EXTERNALLY_ANNOUNCED &&
                            isAccessory(relatedItem),
                    )
                    .sort(nameComparator);

                accessoriesByProduct[partnerProduct.id] = Object.values(
                    relatedItems.reduce(
                        (uniqueItems, item) => {
                            const uniqueItem = uniqueItems[item.id];
                            if (uniqueItem && (uniqueItem.isIncluded || uniqueItem.isRecommended)) {
                                return uniqueItems;
                            }

                            uniqueItems[item.id] = item;
                            return uniqueItems;
                        },
                        {} as Record<PiaId, IRelatedPiaItem>,
                    ),
                );
                return accessoriesByProduct;
            },
            {} as Record<PiaId, IRelatedPiaItem[]>,
        );
    },
);

const getAccessoriesSearchText = (store: IStoreState) => store.partnerConfig.accessoriesSearchText;

export const getFilteredAccessoriesRecord = createSelector(
    [getAccessoriesRecord, getAccessoriesSearchText],
    (accessoriesRecord, searchText) => {
        if (!searchText) {
            return accessoriesRecord;
        }

        const filteredAccessories: Record<PiaId, IRelatedPiaItem[]> = {};

        Object.keys(accessoriesRecord).forEach((piaIdKey) => {
            const piaId = Number(piaIdKey);
            filteredAccessories[piaId] = accessoriesRecord[piaId].filter((accessory) =>
                accessory.name.toLowerCase().includes(searchText.toLowerCase()),
            );
        });

        return filteredAccessories;
    },
);
