import type { IPiaSensorDevice } from 'app/core/pia';
import type { IUspGroup } from 'app/modules/common';
import { t } from 'app/translate';

const NUMBER_OF_USPS = 5;

export const cameraUsps = (): IUspGroup[] => [
    {
        key: 'zipStream',
        condition: true,
        text: t.advancedFiltersGROUP.zipStream,
    },
    {
        key: 'lowLightTechnology',
        condition: 'Lightfinder',
        text: t.advancedFiltersGROUP.lightFinder,
    },
    {
        key: 'imageStabilization',
        condition: true,
        text: t.advancedFiltersGROUP.imageStabilization,
    },
    {
        key: 'WDRTechnology',
        condition: ['Forensic WDR', 'Forensic Capture'],
        text: t.forensicWDR,
    },
    {
        key: 'WDRTechnology',
        condition: ['Dynamic Capture', 'WDR - Dynamic Capture'],
        text: t.advancedFiltersGROUP.dynamicCapture,
    },
    {
        key: 'IRLEDs',
        condition: true,
        text: t.advancedFiltersGROUP.builtInIR,
    },
    {
        key: 'lensChangeable',
        condition: true,
        text: t.advancedFiltersGROUP.lensChangeable,
    },
    {
        key: 'outdoorReady',
        condition: true,
        text: t.advancedFiltersGROUP.outdoorReady,
    },
    {
        key: 'PIris',
        condition: true,
        text: t.cameraSelectorUspsPIris,
    },
    {
        key: 'DCiris',
        condition: true,
        text: t.cameraSelectorUspsDcIris,
    },
    {
        key: 'twoWayAudio',
        condition: true,
        text: t.cameraSelectorUspsTwoWayAudio,
    },
    {
        key: 'audioSupport',
        condition: true,
        text: t.advancedFiltersGROUP.audioSupport,
    },
    {
        key: 'builtInMicrophone',
        condition: true,
        text: t.advancedFiltersGROUP.builtInMicrophone,
    },
    {
        key: 'builtInSpeaker',
        condition: true,
        text: t.cameraSelectorUspsBuiltInSpeaker,
    },
    {
        key: 'vandalResistant',
        condition: true,
        text: t.advancedFiltersGROUP.vandalResistant,
    },
    {
        key: 'ruggedizedEN50155',
        condition: true,
        text: t.ruggedized,
    },
    {
        key: 'alarmInputsOutputs',
        nCondition: '0/0',
        text: t.alarmInputsOutputs,
    },
];

/**
 * Returns up to 4 usps for all cameras passed in, in the following manner:
 *
 * First usp - Resolution (preferrably HDTVResolution).
 * Second usp - Top 3 characteristics for the specific camera that is NOT the 3:rd or 4:th usp (see below).
 * Third usp - Top characteristics (usp) for any camera, but NOT all cameras.
 * Fourth usp - Second best characteristics (usp) for any camera, but NOT all cameras.
 *
 * Special case for when finding only one camera:
 * Second usp - Top 3 characteristics
 * Third usp - 4th characteristics
 * Fourth usp - 5th characteristics
 */
export const getUsps = (cameras: IPiaSensorDevice[]): string[][] => {
    switch (cameras.length) {
        case 0:
            return [];
        case 1:
            return [getUspsForSingleCamera(cameras[0])];
        default:
            return getUspsForMultipleCameras(cameras);
    }
};

export const getUspsForSingleCamera = (camera: IPiaSensorDevice): string[] => {
    const supportedUspTexts: string[] = cameraUsps().reduce(
        (upsTexts: string[], usp: IUspGroup) => {
            return Array.isArray(usp.condition)
                ? getHasValidWDR(camera, usp.condition)
                    ? upsTexts.concat(usp.text)
                    : upsTexts
                : getHasValidUspValue(camera, usp)
                  ? upsTexts.concat(usp.text)
                  : upsTexts;
        },
        [],
    );

    const resolutionUsp = getResolutionUsp(camera);
    if (resolutionUsp) {
        supportedUspTexts.unshift(resolutionUsp);
    }

    return supportedUspTexts.splice(
        0,
        supportedUspTexts.length < NUMBER_OF_USPS ? supportedUspTexts.length : NUMBER_OF_USPS,
    );
};

const getUspsForMultipleCameras = (cameras: IPiaSensorDevice[]): string[][] => {
    const firstDifferentiatedUsp = getDifferentiatingUsp(cameras);
    const secondDifferentiatedUsp = getDifferentiatingUsp(cameras);

    return cameras.map((camera) =>
        getCameraUsps(camera, firstDifferentiatedUsp, secondDifferentiatedUsp),
    );
};

/**
 * Get an usp supported by any camera, but NOT by all cameras
 */
const getDifferentiatingUsp = (cameras: IPiaSensorDevice[]): IUspGroup | undefined => {
    const uspIndex = cameraUsps().findIndex((usp) => {
        let numberOfValidCameras = 0;

        // Special case for WDRTechnology since multivalues with same keys
        if (Array.isArray(usp.condition)) {
            numberOfValidCameras = cameras.reduce((count, camera) => {
                return getHasValidWDR(camera, usp.condition! as string[]) ? count + 1 : count;
            }, 0);
        } else {
            numberOfValidCameras = cameras.reduce((count, camera) => {
                return getHasValidUspValue(camera, usp) ? count + 1 : count;
            }, 0);
        }

        if (numberOfValidCameras > 0 && numberOfValidCameras < cameras.length) {
            return true;
        }
        return false;
    });

    return uspIndex >= 0 ? cameraUsps().splice(uspIndex, 1)[0] : undefined;
};

const getHasValidWDR = (camera: IPiaSensorDevice, values: string[]): boolean => {
    return (
        camera.properties.WDRTechnology !== undefined &&
        values.includes(camera.properties.WDRTechnology)
    );
};

const getCameraUsps = (
    camera: IPiaSensorDevice,
    firstDifferentiatingUsp?: IUspGroup,
    secondDifferentiatingUsp?: IUspGroup,
): string[] => {
    const topThreeUsp = getTopThreeUsp(camera);
    return [
        getResolutionUsp(camera) ?? '',
        topThreeUsp,
        getDifferentiatingUspValue(camera, firstDifferentiatingUsp),
        getDifferentiatingUspValue(camera, secondDifferentiatingUsp),
    ];
};

const getHasValidUspValue = (camera: IPiaSensorDevice, usp: IUspGroup): boolean => {
    return (
        camera.properties[usp.key] !== undefined &&
        (usp.condition
            ? camera.properties[usp.key] === usp.condition
            : camera.properties[usp.key] !== usp.nCondition)
    );
};

const getDifferentiatingUspValue = (camera: IPiaSensorDevice, usp?: IUspGroup): string => {
    if (!(usp && camera.properties[usp.key])) {
        return '-';
    }
    // Check WDR
    if (Array.isArray(usp.condition)) {
        return getHasValidWDR(camera, usp.condition) ? usp.text : '-';
    } else {
        return camera.properties[usp.key] === usp.condition ||
            camera.properties[usp.key] !== usp.nCondition
            ? usp.text
            : '-';
    }
};

const getTopThreeUsp = (camera: IPiaSensorDevice): string => {
    return (
        cameraUsps()
            .filter(
                (usp) =>
                    getHasValidUspValue(camera, usp) ||
                    (Array.isArray(usp.condition) ? getHasValidWDR(camera, usp.condition) : false),
            )
            .map((usp) => usp.text)
            .slice(0, 3)
            .join(', ') || '-'
    );
};

const getResolutionUsp = (camera: IPiaSensorDevice): string | undefined => {
    const hdtvResolution = getHDTVResolution(camera);
    const resolutionUsps = [];

    if (hdtvResolution.vertical <= 0) {
        return undefined;
    }

    if (hdtvResolution.text) {
        resolutionUsps.push(getHDTVResolutionsString(hdtvResolution.text));
    }

    if (hdtvResolution.vertical < camera.properties.maxVideoResolutionVertical) {
        resolutionUsps.push(
            toMaxResString(
                camera.properties.maxVideoResolutionHorizontal,
                camera.properties.maxVideoResolutionVertical,
            ),
        );
    }

    return resolutionUsps.length > 0 ? resolutionUsps.join(' / ') : undefined;
};

const getHDTVResolution = (camera: IPiaSensorDevice) => {
    const hdtvResolution = camera.properties.HDTVResolution;
    const resolution = {
        text: hdtvResolution,
        vertical: 0,
    };

    if (hdtvResolution) {
        if (!hdtvResolution.includes('4K')) {
            const verticalRes = parseInt(hdtvResolution, 10);
            resolution.vertical = verticalRes || 0;
        } else {
            resolution.vertical = (4000 * 9) / 16;
        }
    }

    return resolution;
};

const getHDTVResolutionsString = (resolution: string) => {
    return 'HDTV ' + resolution;
};

const toMaxResString = (horizontal: number, vertical: number) => {
    const maxResStr = `${horizontal}x${vertical}`;
    switch (maxResStr) {
        case '640x480':
            return 'VGA';
        case '800x600':
            return 'SVGA';
        case '1024x768':
            return 'XVGA';
        case '704x576':
            return '4CIF';
        case '704x480':
            return '4CIF';
        case '720x576':
            return 'D1';
        case '720x480':
            return 'D1';
        case '768x576':
            return 'Extended D1';
        default:
            return Math.round((horizontal * vertical) / 100000) / 10 + ' megapixel';
    }
};
