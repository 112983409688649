import { createCachedSelector } from 're-reselect';
import { getCurrentProjectRelationsRecord } from '../../relations';
import { getCurrentProjectItems, getCurrentProjectItem } from '../../project';
import type { DeviceAndSubType } from 'app/core/persistence';
import { getDeviceAndSubTypeForItem } from './getDeviceTypeForItem';
import { toCacheKey } from '../../cacheKey';

const ignoredDeviceTypes = new Set<DeviceAndSubType | undefined>(['analogCamera', undefined]);

export const getHasAccessories = createCachedSelector(
    [
        getCurrentProjectRelationsRecord,
        getCurrentProjectItems,
        getCurrentProjectItem,
        getDeviceAndSubTypeForItem,
    ],
    (currentProjectRelationsRecord, currentProjectItems, item, deviceType) => {
        if (!item || ignoredDeviceTypes.has(deviceType)) {
            return undefined;
        }

        const relations = currentProjectRelationsRecord[item._id];

        if (!relations) {
            return false;
        }

        return relations.some(
            (relation) =>
                // If type is primaryMount and productId is null it is included
                (relation.relationType === 'primaryMount' &&
                    currentProjectItems[relation.childId]?.productId !== null) ||
                relation.relationType === 'accessory',
        );
    },
)(toCacheKey);
