import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import { DataWidget, Stack } from 'app/components';
import { getInstallationHeightText, getTiltAngleForDevice } from '../../selectors';
import { FOV_LIMIT, getCurrentProjectItem, getTiltAnglesForIp } from 'app/modules/common';

interface IInstallationDetailsProps {
    installationPointId?: Id;
    itemId: Id;
    modelName: string;
}

export const InstallationDetails: React.FC<IInstallationDetailsProps> = ({
    installationPointId,
    itemId,
    modelName,
}) => {
    const item = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((store) =>
        getCurrentProjectItem(store, itemId),
    );

    const installationHeightText = useSelector<IStoreState, string>((store) =>
        getInstallationHeightText(store, itemId, installationPointId),
    );
    const tiltAngles = useSelector<IStoreState, number[]>((store) =>
        installationPointId && installationPointId !== 'unplaced'
            ? getTiltAnglesForIp(store, installationPointId)
            : getTiltAngleForDevice(store, itemId),
    );

    if (installationHeightText.length === 0 && tiltAngles.length !== 1) {
        return null;
    }

    const arrowIcon =
        tiltAngles[0] > 0 ? 'arrow_upward' : tiltAngles[0] < 0 ? 'arrow_downward' : undefined;

    const shouldShowTiltAngle =
        tiltAngles.length === 1 &&
        item &&
        !deviceTypeCheckers.isDoorStation(item) &&
        !tiltAngles.some((angle) => angle >= FOV_LIMIT);

    return (
        <Stack spacing="panel" alignItems="end">
            {shouldShowTiltAngle && (
                <DataWidget
                    testId={`${modelName}_tilt_angle`}
                    icon="angle"
                    text={`${Math.abs(Math.round(tiltAngles[0]))}°`}
                    description={t.installationReportInstallationTiltTitle}
                    secondaryIcon={arrowIcon}
                />
            )}
            {installationHeightText.length > 0 && (
                <DataWidget
                    testId={`installation_report_installation_height_${modelName}`}
                    icon="height"
                    text={installationHeightText}
                    description={t.installationHeight}
                />
            )}
        </Stack>
    );
};

InstallationDetails.displayName = 'InstallationDetails';
