import { injectable } from 'inversify';
import type { IPiaPac, IPiaRelayExpansionModule } from 'app/core/pia';
import type { IItemEntity, IPacItemEntity, IPersistence, Id, IItem } from 'app/core/persistence';
import { InstallationPointService, ItemService, CurrentProjectService } from 'app/core/persistence';
import type { IInstallationReportPacDevice } from '../../models/devices';
import { BaseDeviceService } from './BaseDevice.service';
import { AccessoryService, CategoryEnum } from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import { ModalService } from 'app/modal';
import { t } from 'app/translate';

@injectable()
export class PacDeviceService {
    constructor(
        private baseDeviceService: BaseDeviceService,
        private currentProjectService: CurrentProjectService,
        private installationPointService: InstallationPointService,
        private modalService: ModalService,
        private accessoryService: AccessoryService,
        private itemService: ItemService,
    ) {}

    public async mapDevice(
        item: IPersistence<IPacItemEntity>,
        piaItem: IPiaPac,
    ): Promise<IInstallationReportPacDevice> {
        const moduleItems = this.currentProjectService.getDeviceChildren(
            item._id,
            'iorelays',
        ) as IPersistence<IPacItemEntity>[];

        const expansionModules = await Promise.all(
            moduleItems
                .map((moduleItem) => {
                    const modulePiaItem = moduleItem.productId
                        ? (this.baseDeviceService.getPiaItemFromProductId(
                              moduleItem.productId,
                          ) as IPiaRelayExpansionModule)
                        : null;
                    return modulePiaItem
                        ? this.mapExpansionModule(moduleItem, modulePiaItem)
                        : null;
                })
                .filter(isDefined),
        );

        return {
            ...(await this.baseDeviceService.mapDevice(item, piaItem)),
            category: CategoryEnum.Pac,
            expansionModules,
        };
    }

    public async updateIoRelay(
        productId: number | null,
        itemToEdit: IPersistence<IItemEntity>,
    ): Promise<void | IPersistence<IItemEntity> | undefined> {
        const productChanged = itemToEdit.productId !== productId;

        const { descendants } = await this.installationPointService.getInstallationPointDescendants(
            itemToEdit._id,
        );
        const expansionUnits = descendants.filter((item) => item.parentId);

        // Model changed
        if (productChanged) {
            const hasIncompatibleAccessories = this.accessoryService.getHasPiaItemsToRemove(
                itemToEdit._id,
                productId,
            );
            const hasExpansionUnits = this.hasExpansionUnits(itemToEdit._id);

            if (hasExpansionUnits && hasIncompatibleAccessories) {
                const result = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                    t.removeConnectedDevicesAndAccessoriesConfirmationGROUP.header,
                    t.removeConnectedDevicesAndAccessoriesConfirmationGROUP.body,
                );
                if (result) {
                    await this.installationPointService.removeInstallationPoints(expansionUnits);
                } else {
                    return;
                }
            } else if (hasExpansionUnits) {
                const result = await this.modalService.createConfirmDialog({
                    header: t.removeConnectedDevicesConfirmationGROUP.header,
                    body: t.removeConnectedDevicesConfirmationGROUP.body,
                    cancelButtonText: t.cancel,
                    confirmButtonText: t.change,
                })();
                if (result) {
                    await this.installationPointService.removeInstallationPoints(expansionUnits);
                } else {
                    return;
                }
            } else if (hasIncompatibleAccessories) {
                const result = await this.accessoryService.getConfirmDialogue(
                    itemToEdit._id,
                    productId,
                );
                if (!result) {
                    return;
                }
            }

            if (hasExpansionUnits) {
                await this.removeAllExpansionModules(itemToEdit);
            }
        }

        const itemProps: Partial<IItem> = {
            name: itemToEdit.name,
            quantity: itemToEdit.quantity,
            description: itemToEdit.description,
            notes: itemToEdit.notes,
            productId,
            properties: itemToEdit.properties,
        };

        if (productChanged) {
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                itemToEdit._id,
                productId,
            );
        }
        return this.itemService.updateItem(itemToEdit._id, itemProps);
    }

    private hasExpansionUnits(id: Id): boolean {
        const relations = this.currentProjectService.getItemRelations(id);
        return relations.some((rel) => rel.relationType === 'iorelays');
    }

    private removeAllExpansionModules = async (ioRelay: IPersistence<IItemEntity>) => {
        const relations = this.currentProjectService.getItemRelations(ioRelay._id);
        const expansionModulesRelations = relations.filter(
            ({ relationType }) => relationType === 'iorelays',
        );

        await Promise.all(
            expansionModulesRelations.map((rel) => this.itemService.deleteItem(rel.childId)),
        );
        return this.itemService.getItem(ioRelay._id);
    };

    private mapExpansionModule = async (
        item: IPersistence<IPacItemEntity>,
        piaItem: IPiaPac,
    ): Promise<IInstallationReportPacDevice> => ({
        ...(await this.baseDeviceService.mapDevice(item, piaItem)),
        category: CategoryEnum.Pac,
        expansionModules: [],
    });
}
