// super simple product-id encryption/decryption for obfuscating the text api

const ENCRYPTION_KEY = '51T3D3S16N3R';

const xorEncrypt = (text: string, key: string): string => {
    let result = '';

    for (let i = 0; i < text.length; i++) {
        result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }

    return result;
};

export const encrypt = (input: string | number): string =>
    btoa(encodeURIComponent(xorEncrypt(String(input), ENCRYPTION_KEY)));

export const decrypt = (ciphertext: string): string =>
    xorEncrypt(decodeURIComponent(atob(ciphertext)), ENCRYPTION_KEY);
