import { injectable } from 'inversify';
import type {
    IRecordingSettingsModel,
    IBaseProfileModel,
    IRecordingSettingsEntity,
    IProfileEntity,
    RecordingType,
    FpsMode,
} from 'app/core/persistence';
import { NoResolution, Resolution, VideoEncoding } from 'app/core/persistence';
import type {
    IPiaCameraProperties,
    IPiaEncoderProperties,
    IPiaBandwidthCalculationProperties,
    IPiaAudioSupportProperties,
    FpsSpecificResolutions,
} from 'app/core/pia';
import type {
    IProfileSupport,
    IScheduleRecordingSupport,
    IProductCapabilitiesForProfile,
    ISupportedWithMessage,
} from '../models';
import { t } from 'app/translate';
import { Frequency } from '../../models';

@injectable()
export class ProfileSupportService {
    public static getProfileSupportSync(
        mergedProfile: IProfileEntity,
        productProperties: IPiaCameraProperties | IPiaEncoderProperties,
        frequency: Frequency,
    ): IProfileSupport {
        return this.getProfileSupportObject(mergedProfile, productProperties, frequency);
    }

    public static getDeviceProfileSettings(
        frequency: Frequency,
        productProperties: IPiaBandwidthCalculationProperties,
        mergedProfile: IBaseProfileModel,
    ) {
        return {
            continuousRecording: this.getDeviceRecordingSettings(
                productProperties,
                frequency,
                mergedProfile.continuousRecording,
            ),
            triggeredRecording: this.getDeviceRecordingSettings(
                productProperties,
                frequency,
                mergedProfile.triggeredRecording,
            ),
            liveView: this.getDeviceRecordingSettings(
                productProperties,
                frequency,
                mergedProfile.liveView,
            ),
        };
    }

    public static getMaxResolution(
        productProperties: IPiaBandwidthCalculationProperties,
    ): Resolution {
        const maxResolution =
            productProperties.maxVideoResolutionHorizontal &&
            productProperties.maxVideoResolutionVertical
                ? new Resolution(
                      productProperties.maxVideoResolutionHorizontal,
                      productProperties.maxVideoResolutionVertical,
                  )
                : new NoResolution();

        return maxResolution;
    }

    /**
     * Since system designer allows profiles to have resolutions that
     * are not supported by the camera, we expect that a resolution
     * higher than the camera can support should result in using
     * the maximum available resolution according to the product
     * info for the camera.
     */
    public static getCameraResolution(
        productMaxResolution: Resolution,
        profileResolution: Resolution,
    ): Resolution {
        return profileResolution.fitInside(productMaxResolution)
            ? profileResolution
            : productMaxResolution;
    }

    public static isAudioSupportedAndEnabled(
        productProperties: IPiaAudioSupportProperties,
        audioEnabled: boolean,
    ): boolean {
        return productProperties.audioSupport ? audioEnabled : false;
    }

    public static getZipstreamStrength(
        properties: IPiaBandwidthCalculationProperties,
        zipStrength: number,
    ): number {
        return properties.zipStream ? zipStrength : 0;
    }

    /**
     * Get the usable fps mode for a device.
     * Falls back to `fixed` if the device uses settings that are incompatible with dynamic fps.
     */
    public static getFpsMode(
        properties: IPiaBandwidthCalculationProperties,
        zipStrength: number,
        fpsMode: FpsMode,
        videoEncoding?: VideoEncoding,
        recordingType?: RecordingType,
    ): FpsMode {
        if (
            !properties.zipStream ||
            videoEncoding === VideoEncoding.mjpeg ||
            recordingType === 'triggered' ||
            zipStrength === 0
        ) {
            return 'fixed';
        }

        return fpsMode;
    }

    /**
     * Get the maximum frame rate that the camera is capable of.
     * Some cameras (Q1659) support different frame rates for different
     * resolutions. This will return the maximum supported frame rate
     * for the specified resolution based on the PIA data available.
     */
    public static getMaxFrameRate(
        productProperties: IPiaBandwidthCalculationProperties,
        requestedResolutionInPixels: number,
        frequency: Frequency,
    ) {
        if (productProperties.lensCalcFPS) {
            const supportedFrameRates = productProperties.lensCalcFPS
                .map(({ fps50Hz, fps60Hz, resolution }) => ({
                    frameRate: frequency === Frequency.Hz50 ? fps50Hz : fps60Hz,
                    resolutionInPixels: new Resolution(resolution).getPixels(),
                }))
                .sort(
                    (frameRateA, frameRateB) =>
                        frameRateB.resolutionInPixels - frameRateA.resolutionInPixels,
                );

            // If the requested resolution is higher than any specified in the lensCalcFPS we default to the maximum resolution.
            const maximumResolutionFrameRate = supportedFrameRates[0].frameRate;

            const frameRateForRequestedResolution = supportedFrameRates.reduce(
                (frameRate, current) => {
                    if (requestedResolutionInPixels <= current.resolutionInPixels) {
                        frameRate = current.frameRate;
                    }
                    return frameRate;
                },
                maximumResolutionFrameRate,
            );

            return frameRateForRequestedResolution;
        }

        return frequency === Frequency.Hz60
            ? productProperties.maxFPS60Hz
            : productProperties.maxFPS50Hz;
    }

    public static getFrameRate(
        productProperties: IPiaBandwidthCalculationProperties,
        recordingSettings: IRecordingSettingsModel,
        frequency: Frequency,
        isGenericCamera?: boolean,
    ): number {
        const cameraMaxFps = isGenericCamera
            ? recordingSettings.frameRate
            : this.getMaxFrameRate(
                  productProperties,
                  recordingSettings.resolution.getPixels(),
                  frequency,
              );

        return Math.min(cameraMaxFps, recordingSettings.frameRate);
    }

    public static getVideoEncoding(
        productProperties: IPiaBandwidthCalculationProperties,
        recordingVideoEncoding: VideoEncoding,
    ): VideoEncoding {
        if (productProperties.h265 && recordingVideoEncoding === VideoEncoding.h265) {
            return VideoEncoding.h265;
        }
        if (productProperties.motionJPEG && recordingVideoEncoding === VideoEncoding.mjpeg) {
            return VideoEncoding.mjpeg;
        }
        return VideoEncoding.h264;
    }

    public static videoEncodingSupport(
        recordingVideoEncoding: VideoEncoding,
        productCapabilities: IPiaBandwidthCalculationProperties,
    ): ISupportedWithMessage {
        const supported =
            ProfileSupportService.getVideoEncoding(productCapabilities, recordingVideoEncoding) ===
            recordingVideoEncoding;

        return {
            supported,
            message: t.profilesSupportVideoEncodingMessage('H264'),
        };
    }

    public static isResolutionSupported(
        recordingResolution: Resolution,
        productMaxResolution: Resolution,
        fpsSpecificResolutions: FpsSpecificResolutions | undefined,
    ): ISupportedWithMessage {
        const supported =
            (recordingResolution.getHorizontal() === Number.MAX_SAFE_INTEGER &&
                recordingResolution.getVertical() === Number.MAX_SAFE_INTEGER) ||
            recordingResolution.fitInside(productMaxResolution) ||
            this.isSupportedByFpsSpecificResolution(
                recordingResolution,
                fpsSpecificResolutions ?? [],
            );

        return {
            supported,
            message: t.profilesSupportResolutionMessage(productMaxResolution.toString()),
        };
    }

    private static getProfileSupportObject(
        mergedProfile: IBaseProfileModel | IProfileEntity,
        productProperties: IPiaCameraProperties | IPiaEncoderProperties,
        frequency: Frequency,
    ): IProfileSupport {
        const scheduleRecordings = {
            continuousRecording: this.getScheduleRecordingSupport(
                productProperties,
                frequency,
                mergedProfile.continuousRecording,
            ),
            triggeredRecording: this.getScheduleRecordingSupport(
                productProperties,
                frequency,
                mergedProfile.triggeredRecording,
            ),
            liveView: this.getScheduleRecordingSupport(
                productProperties,
                frequency,
                mergedProfile.liveView,
            ),
        };

        const audioSupported = this.audioSupport(
            mergedProfile.audio.liveViewEnabled || mergedProfile.audio.recordingEnabled,
            !!productProperties.audioSupport,
        );

        const usingMjpeg =
            mergedProfile.continuousRecording.videoEncoding === VideoEncoding.mjpeg ||
            mergedProfile.liveView.videoEncoding === VideoEncoding.mjpeg ||
            mergedProfile.triggeredRecording.videoEncoding === VideoEncoding.mjpeg;

        const zipstreamSupported = this.zipstreamSupport(
            mergedProfile.zipstream.zipStrength,
            !!productProperties.zipStream,
            usingMjpeg,
        );

        return {
            ...scheduleRecordings,
            audio: audioSupported,
            zipstream: zipstreamSupported,
            isAnySettingUnsupported:
                !audioSupported.supported ||
                !zipstreamSupported.supported ||
                !this.isScheduleRecordingSupported(scheduleRecordings.continuousRecording) ||
                !this.isScheduleRecordingSupported(scheduleRecordings.liveView) ||
                !this.isScheduleRecordingSupported(scheduleRecordings.triggeredRecording),
        };
    }
    private static getDeviceRecordingSettings(
        productProperties: IPiaBandwidthCalculationProperties,
        frequency: Frequency,
        recordingSettings: IRecordingSettingsModel,
    ): IRecordingSettingsModel | null {
        if (recordingSettings.schedule === null) {
            return null;
        }

        const frameRate = this.getFrameRate(productProperties, recordingSettings, frequency);

        const maxResolution = this.getMaxResolution(productProperties);

        const resolution = this.getCameraResolution(maxResolution, recordingSettings.resolution);

        return {
            ...recordingSettings,
            frameRate,
            resolution,
        };
    }

    private static isScheduleRecordingSupported(scheduleRecording: IScheduleRecordingSupport) {
        return (
            !scheduleRecording.noSchedule ||
            (scheduleRecording.frameRate.supported &&
                scheduleRecording.videoEncoding.supported &&
                scheduleRecording.resolution.supported)
        );
    }

    private static getScheduleRecordingSupport(
        productProperties: IPiaCameraProperties | IPiaEncoderProperties,
        frequency: Frequency,
        recordingSettings: IRecordingSettingsModel | IRecordingSettingsEntity,
    ): IScheduleRecordingSupport {
        const resolution =
            typeof recordingSettings.resolution === 'string'
                ? new Resolution(recordingSettings.resolution)
                : recordingSettings.resolution;

        const productCapabilities = this.getProductCapabilities(
            productProperties,
            frequency,
            resolution,
        );

        return {
            noSchedule: recordingSettings.schedule != null,
            frameRate: this.frameRateSupport(
                recordingSettings.frameRate,
                productCapabilities.maxFrameRate,
            ),
            resolution: this.isResolutionSupported(
                resolution,
                productCapabilities.maxResolution,
                productProperties.lensCalcFPS,
            ),
            videoEncoding: this.videoEncodingSupport(
                recordingSettings.videoEncoding,
                productProperties,
            ),
        };
    }

    private static audioSupport(
        recordingHasAudio: boolean,
        productSupportAudio: boolean,
    ): ISupportedWithMessage {
        return {
            supported: !recordingHasAudio || (recordingHasAudio && productSupportAudio),
            message: t.profilesSupportNoAudio,
        };
    }

    private static frameRateSupport(
        recordingFrameRate: number,
        productMaxFrameRate: number,
    ): ISupportedWithMessage {
        return {
            supported: recordingFrameRate <= productMaxFrameRate,
            message: t.profilesSupportFrameRateMessage(productMaxFrameRate),
        };
    }

    private static isSupportedByFpsSpecificResolution(
        recordingResolution: Resolution,
        fpsSpecificResolutions: FpsSpecificResolutions,
    ): boolean {
        const supportedResolutions = fpsSpecificResolutions.map(
            ({ resolution }) => new Resolution(resolution),
        );

        return supportedResolutions.some((resolution) => recordingResolution.fitInside(resolution));
    }

    private static zipstreamSupport(
        recordingZipStrength: number,
        productHasZipstream: boolean,
        usingMjpeg: boolean,
    ): ISupportedWithMessage {
        const supported =
            recordingZipStrength === 0 || (recordingZipStrength > 0 && productHasZipstream);

        // MJPEG does not work with Zipstream
        if (supported && recordingZipStrength > 0 && usingMjpeg) {
            return {
                supported: false,
                message: t.mjpegSettingInformation,
            };
        }

        return {
            supported,
            message: t.profilesSupportNoZipstream,
        };
    }

    private static getProductCapabilities(
        productProperties: IPiaCameraProperties | IPiaEncoderProperties,
        frequency: Frequency,
        resolution: Resolution,
    ): IProductCapabilitiesForProfile {
        const maxFrameRate = this.getMaxFrameRate(
            productProperties,
            resolution.getPixels(),
            frequency,
        );
        return {
            hasAudio: !!productProperties.audioSupport,
            maxFrameRate,
            maxResolution: this.getMaxResolution(productProperties),
            hasZipstream: !!productProperties.zipStream,
            hasH264: !!productProperties.H264,
            hasH265: !!productProperties.h265,
        };
    }
}
