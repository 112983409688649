import * as React from 'react';
import { Box, Clickable, Border, Text } from 'app/components';
import { ColorsEnum } from 'app/styles';
import { useService } from 'app/ioc';
import { CommonActionService } from 'app/modules/common';
import type { SelectedVendorType } from 'app/core/persistence';
import { ProjectService } from 'app/core/persistence';
import { css } from '@emotion/css';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';

interface IVendorCardProps {
    vendor: SelectedVendorType;
}

const getVendorTitle = (vendor: SelectedVendorType) => {
    switch (vendor) {
        case 'Genetec':
            return t.recordingSolutionGenetec;
        case 'Milestone':
            return t.recordingSolutionMilestone;
        case 'Axis':
        default:
            return t.recordingSolutionAxis;
    }
};

const axisLogo = require('src/assets/images/axislogo@2x.png');
const milestoneLogo = require('src/assets/images/milestone.png');
const genetecLogo = require('src/assets/images/genetec.png');

export const VendorCard: React.FC<IVendorCardProps> = ({ vendor }) => {
    const commonActionService = useService(CommonActionService);
    const projectService = useService(ProjectService);
    const borderImageStyle = `linear-gradient(to right, ${ColorsEnum['grey1']}, ${ColorsEnum['grey3']}, ${ColorsEnum['grey1']}) 1`;
    const productTitle = getVendorTitle(vendor);

    const showProductsSelectorPanel = () => {
        eventTracking.logUserEvent('System Components', 'Select vendor', vendor);
        projectService.updateCurrentProject({ selectedRecordingVendor: vendor });
        commonActionService.setRecordingOverlay(undefined, undefined, true);
    };

    const imgStyle = css`
        max-height: 50px;
        max-width: 90px;
    `;

    return (
        <Clickable onClick={() => showProductsSelectorPanel()}>
            <Border radius="10px" color="grey3" hoverColor="yellow">
                <Box minWidth="200px" color="white" testId={`recommendation_test_${vendor}`}>
                    <Box
                        display="grid"
                        grid={{
                            gridTemplateRows: '3fr 1fr',
                        }}
                        width="100%"
                    >
                        <Box justifyContent="center" alignItems="center">
                            {vendor === 'Axis' && (
                                <img height="auto" width="80px" src={axisLogo}></img>
                            )}
                            {vendor === 'Milestone' && (
                                <img className={imgStyle} src={milestoneLogo}></img>
                            )}
                            {vendor === 'Genetec' && (
                                <img className={imgStyle} src={genetecLogo}></img>
                            )}
                        </Box>
                        <Border topWidth={1} borderImage={borderImageStyle}>
                            <Box minHeight="40px" justifyContent="center" alignItems="center">
                                <Text
                                    whiteSpace="nowrap"
                                    style="small"
                                    bold
                                    color="grey5"
                                    align="center"
                                    testId={productTitle}
                                >
                                    {productTitle}
                                </Text>
                            </Box>
                        </Border>
                    </Box>
                </Box>
            </Border>
        </Clickable>
    );
};

VendorCard.displayName = 'VendorCard';
