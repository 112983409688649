import * as React from 'react';
import { mapValues } from 'lodash-es';
import {
    Badge,
    Box,
    Clickable,
    DropDown,
    DropDownMenuItem,
    Icon,
    PiaImage,
    Stack,
    Text,
} from 'app/components';
import type { IPiaItem, IPiaSystemComponent, PiaId } from 'app/core/pia';
import { PiaAccessoryCategory } from 'app/core/pia';
import type { IStoreState } from 'app/store';
import { connect, useSelector } from 'react-redux';
import {
    getCurrentProjectLocked,
    getDeviceBandwidthPerDevice,
    getIsStandalone,
    getPiaItemsRecord,
    getTotalNbrAccessories,
    PiaItemMsrp,
} from 'app/modules/common';
import type { Id } from 'app/core/persistence';
import type { ICategorizedAccessoryItem, ISelectedMountAccessory } from '../../selectors';
import {
    getSelectedMountForId,
    getNotToBeFollowedByAccessories,
    getSelectedMountsAccessoriesAndLensIds,
    getSelectedMountAccessoriesForItemId,
    getFilteredAccessoriesMatchingSearch,
    getFilteredAllAccessoriesMatchingSearch,
    getAccessorySearchFilter,
    getSelectedMount,
    sortMountAccessoryCategories,
    hasAccessoryRequiredAccessories,
} from '../../selectors';

import { AddAccessoryButtonTrigger } from './AddAccessoriesButtonTrigger.container';
import { t as i18nextT } from 'i18next';
import { ServiceLocator } from 'app/ioc';
import { AccessoryPersistenceService, AccessorySelectorActionService } from '../../services';
import { t } from 'app/translate';
import type { IAccessorySearchFilter } from '../..';
import {
    getNotToBeFollowedByArray,
    hasNotToBeFollowedByAccessory,
} from './hasNotToBeFollowedByAccessory';
interface IAddAccessoriesButtonOwnProps {
    itemId?: Id;
    productId: PiaId;
    includeAllMounts?: boolean;
    style?: 'large' | 'small';
}

interface IAddAccessoriesButtonStateProps {
    id?: Id;
    isLocked: boolean;
    isStandalone: boolean;
    accessories: Record<string, ICategorizedAccessoryItem[]>;
    selectedAccessories: Record<PiaId, ISelectedMountAccessory>;
    sortedDisplayCategories: string[];
    style?: 'large' | 'small';
    accessorySearchFilter: IAccessorySearchFilter;
    storagePerDayInMB: number;
    totalNbrAccessories: number;
    notToBeFollowedByAccessories: Record<PiaId, IPiaItem[][]>;
    selectedMountsAccessoriesAndLensIds: PiaId[];
}

type IAddAccessoriesButtonProps = IAddAccessoriesButtonOwnProps & IAddAccessoriesButtonStateProps;

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IAddAccessoriesButtonOwnProps,
): IAddAccessoriesButtonStateProps => {
    const mountItem = ownProps.itemId
        ? getSelectedMountForId(storeState, ownProps.itemId)
        : getSelectedMount(storeState, ownProps.productId);

    const accessories = ownProps.includeAllMounts
        ? getFilteredAllAccessoriesMatchingSearch(storeState, ownProps.productId)
        : getFilteredAccessoriesMatchingSearch(storeState, ownProps.productId);

    const accessoriesWithRequirements = mapValues(accessories ?? [], (accessoryCategories) =>
        accessoryCategories.map((accessory) => ({
            ...accessory,
            hasRequiredAccessories: hasAccessoryRequiredAccessories(storeState, accessory.id),
        })),
    );

    const sortedDisplayCategories = Object.keys(accessories).sort(sortMountAccessoryCategories);
    const isLocked = getCurrentProjectLocked(storeState);
    const accessorySearchFilter = getAccessorySearchFilter(storeState);
    const totalNbrAccessories = getTotalNbrAccessories(storeState, mountItem?._id);
    const selectedAccessories = mountItem
        ? getSelectedMountAccessoriesForItemId(storeState, mountItem._id)
        : {};

    const isStandalone = getIsStandalone(storeState);
    const storage = isStandalone
        ? undefined
        : getDeviceBandwidthPerDevice(storeState, mountItem?._id);
    const storagePerDayInMB = (storage?.storageInMB ?? 0) / (storage?.retentionTime ?? 1);
    const notToBeFollowedByAccessories = getNotToBeFollowedByAccessories(storeState);
    const selectedMountsAccessoriesAndLensIds = getSelectedMountsAccessoriesAndLensIds(storeState);

    return {
        accessories: accessoriesWithRequirements,
        selectedAccessories,
        sortedDisplayCategories,
        id: mountItem?._id,
        isLocked,
        isStandalone,
        storagePerDayInMB,
        style: ownProps.style,
        accessorySearchFilter,
        totalNbrAccessories,
        notToBeFollowedByAccessories,
        selectedMountsAccessoriesAndLensIds,
    };
};

const AddAccessoriesButtonContainer: React.FunctionComponent<IAddAccessoriesButtonProps> = ({
    accessories,
    selectedAccessories,
    sortedDisplayCategories,
    id: mountId,
    isLocked,
    isStandalone,
    storagePerDayInMB,
    style,
    accessorySearchFilter,
    totalNbrAccessories,
    notToBeFollowedByAccessories,
    selectedMountsAccessoriesAndLensIds,
}) => {
    const INCLUDE_SEARCH_FIELD: boolean = true;
    const onToggleAccessory = (
        id: Id,
        accessoryPiaId: PiaId,
        selectedMountAccessory?: ISelectedMountAccessory,
    ) => {
        const accessoryPersistenceService = ServiceLocator.get(AccessoryPersistenceService);
        selectedMountAccessory
            ? accessoryPersistenceService.delete(selectedMountAccessory.id)
            : accessoryPersistenceService.setItem(id, accessoryPiaId, 'accessory');
    };

    const getNestedAccessoryLength = () => {
        let amount = 0;
        sortedDisplayCategories.forEach((category) => {
            // Count each category as one item
            amount++;
            // Count number of accessories belonging to each category
            amount += accessories[category].length;
        });
        // Account for search field to determine dropdown height
        if (INCLUDE_SEARCH_FIELD) amount++;
        return amount;
    };

    const noMatchAccessories = () => {
        // Count number of accessories belonging to each category
        const totalAmount = sortedDisplayCategories.reduce(
            (amount, category) => amount + accessories[category].length,
            0,
        );
        return totalAmount === 0;
    };

    const piaItemsRecord = useSelector<IStoreState, Record<number, IPiaItem>>((state) =>
        getPiaItemsRecord(state),
    );

    const [accessorySelectorActionService] = React.useState(() =>
        ServiceLocator.get(AccessorySelectorActionService),
    );

    const onSearchTextChanged = (searchText: string = '') => {
        accessorySelectorActionService.setAccessorySearchText(searchText);
    };

    const getProductNamesNotToBeFollowedBy = (piaId: PiaId) =>
        getNotToBeFollowedByArray(
            piaId,
            notToBeFollowedByAccessories,
            selectedMountsAccessoriesAndLensIds,
        )
            .map((id) => piaItemsRecord[id].name)
            .join(', ');

    const calculateRetentionTimeInDays = (accessory: ICategorizedAccessoryItem) => {
        const cardSizeInMB =
            ((accessory as IPiaSystemComponent).properties.memoryCardSize ?? 0) * 1000;
        if (cardSizeInMB > 0 && storagePerDayInMB > 0) {
            const retentionTime = Math.floor(cardSizeInMB / storagePerDayInMB);
            return retentionTime;
        }

        return t.notAvailable;
    };

    return Object.keys(accessories).length > 0 ? (
        <DropDown
            openInPortal
            stayOpen
            disabled={isLocked}
            minWidth={500}
            contentLengthOverride={getNestedAccessoryLength()}
            trigger={
                <AddAccessoryButtonTrigger
                    numberOfSelectedAccessories={totalNbrAccessories}
                    style={style ?? 'large'}
                />
            }
            includeSearchField={INCLUDE_SEARCH_FIELD}
            onSearchTextChanged={onSearchTextChanged}
            searchFieldText={accessorySearchFilter?.searchText}
            placeHolder={t.searchForAccessoriesStandAlone}
            contents={
                !noMatchAccessories() && (
                    <React.Fragment>
                        {sortedDisplayCategories.map(
                            (category) =>
                                accessories[category].length > 0 && (
                                    <React.Fragment key={category}>
                                        <DropDownMenuItem isText>
                                            <Text style="heading">
                                                {i18nextT(
                                                    `accessoryDisplayCategoriesGROUP.${category}`,
                                                )}
                                            </Text>
                                        </DropDownMenuItem>
                                        {accessories[category].map((accessory) => (
                                            <Clickable
                                                key={accessory.id}
                                                onClick={() =>
                                                    mountId &&
                                                    onToggleAccessory(
                                                        mountId,
                                                        accessory.id,
                                                        selectedAccessories[accessory.id],
                                                    )
                                                }
                                            >
                                                <DropDownMenuItem
                                                    testId={`mounting_${accessory.name}`}
                                                    onlyContent
                                                    selected={!!selectedAccessories[accessory.id]}
                                                    disabled={hasNotToBeFollowedByAccessory(
                                                        accessory.id,
                                                        notToBeFollowedByAccessories,
                                                        selectedMountsAccessoriesAndLensIds,
                                                    )}
                                                >
                                                    <Box
                                                        padding="cell"
                                                        justifyContent="between"
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <Stack>
                                                            <PiaImage
                                                                piaId={accessory.id}
                                                                icon="category"
                                                                imageSize="md"
                                                            />
                                                            <Stack vertical spacing="none">
                                                                <Text>{accessory.name}</Text>

                                                                {!isStandalone &&
                                                                    storagePerDayInMB > 0 &&
                                                                    accessory.category ===
                                                                        PiaAccessoryCategory.STORAGE && (
                                                                        <Stack spacing="quart">
                                                                            <Text small>
                                                                                {`${t.estimatedRetentionTime}:`}
                                                                            </Text>
                                                                            <Text small bold>
                                                                                {calculateRetentionTimeInDays(
                                                                                    accessory,
                                                                                )}
                                                                            </Text>
                                                                        </Stack>
                                                                    )}

                                                                {accessory.hasRequiredAccessories && (
                                                                    <Stack spacing="quart">
                                                                        <Icon
                                                                            opaque
                                                                            icon="info"
                                                                            color="blue"
                                                                            size="xs"
                                                                        />
                                                                        <Text small>
                                                                            {
                                                                                t.additionalAccessoriesRequired
                                                                            }
                                                                        </Text>
                                                                    </Stack>
                                                                )}
                                                                {hasNotToBeFollowedByAccessory(
                                                                    accessory.id,
                                                                    notToBeFollowedByAccessories,
                                                                    selectedMountsAccessoriesAndLensIds,
                                                                ) && (
                                                                    <Stack spacing="quart">
                                                                        <Icon
                                                                            opaque
                                                                            icon="warning_small"
                                                                            color="red"
                                                                            size="xs"
                                                                        />
                                                                        <Text small>
                                                                            {t.canNotBeUsedWithProductName(
                                                                                getProductNamesNotToBeFollowedBy(
                                                                                    accessory.id,
                                                                                ),
                                                                            )}
                                                                        </Text>
                                                                    </Stack>
                                                                )}
                                                            </Stack>
                                                        </Stack>
                                                        <Stack>
                                                            {accessory.isIncluded && (
                                                                <Badge
                                                                    compact
                                                                    label={t.included}
                                                                    type="yellow"
                                                                />
                                                            )}
                                                            {accessory.isRecommended &&
                                                                !accessory.isIncluded && (
                                                                    <Badge
                                                                        compact
                                                                        label={t.recommended}
                                                                        type="green"
                                                                    />
                                                                )}
                                                            {!accessory.isIncluded && (
                                                                <PiaItemMsrp
                                                                    piaId={accessory.id}
                                                                    includeMultipacks
                                                                />
                                                            )}
                                                        </Stack>
                                                    </Box>
                                                </DropDownMenuItem>
                                            </Clickable>
                                        ))}
                                    </React.Fragment>
                                ),
                        )}
                    </React.Fragment>
                )
            }
        />
    ) : null;
};

export const AddAccessoriesButton = connect(mapStateToProps)(AddAccessoriesButtonContainer);
AddAccessoriesButton.displayName = 'AddAccessoriesButton';
