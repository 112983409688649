import type { PiaId } from 'app/core/pia';
import { injectable } from 'inversify';

export interface IBodyWornCamera {
    productId: PiaId;
    name: string;
}
export interface IDockingStation {
    productId: PiaId;
    name: string;
    cameraBays: number;
}

export interface ISystemController {
    productId: PiaId;
    name: string;
    maxDockingStations: number;
    maxCameraCount: number;
}

type wearableType = 'dockingStation' | 'systemController' | 'bodyWornCamera';
export interface IWearablesRecommendation {
    productId: PiaId;
    name: string;
    type: wearableType;
    count: number;
}

@injectable()
export class WearablesRecommendations {
    // After 5 single bay docks, it gets cheaper to buy a multi bay dock
    private readonly maxSingleDocks = 5;

    getRecommendations(
        cameraCount: number,
        bodyWornCameras: IBodyWornCamera[],
        dockingStations: IDockingStation[],
        systemControllers: ISystemController[],
    ): IWearablesRecommendation[] {
        // Sort with descending camera bays and max camera count
        const sortedDockingStations = dockingStations.sort((a, b) => b.cameraBays - a.cameraBays);
        const sortedSystemControllers = systemControllers.sort(
            (a, b) => b.maxCameraCount - a.maxCameraCount,
        );

        // Calculate recommended controllers
        const recommendedControllers = this.getRecommendedControllers(
            cameraCount,
            sortedSystemControllers,
        );

        // Calculate recommended docking stations
        const recommendedDockingStations = this.getRecommendedDockingStations(
            cameraCount,
            sortedDockingStations,
            recommendedControllers,
        );

        // Create recommendation
        const recommendation = this.createRecommendation(
            cameraCount,
            bodyWornCameras,
            recommendedControllers,
            recommendedDockingStations,
        );

        return recommendation;
    }

    private getRecommendedControllers(
        cameraCount: number,
        systemControllers: ISystemController[],
    ): ISystemController[] {
        const recommendedControllers = new Array<ISystemController>();
        let assignedCameras: number = 0;

        // Assign cameras using controllers with highest camera count first
        for (const systemController of systemControllers) {
            const count = Math.floor(
                (cameraCount - assignedCameras) / systemController.maxCameraCount,
            );
            for (let i = 0; i < count; ++i) {
                recommendedControllers.push(systemController);
                assignedCameras = assignedCameras + systemController.maxCameraCount;
            }
        }

        // If it doesn't even up, add the system controller with the lowest maxCameraCount
        while (assignedCameras < cameraCount) {
            const lastControllerIndex = systemControllers.length - 1;
            recommendedControllers.push(systemControllers[lastControllerIndex]);
            assignedCameras += systemControllers[lastControllerIndex].maxCameraCount;
        }

        return recommendedControllers;
    }

    private getRecommendedDockingStations(
        cameraCount: number,
        dockingStations: IDockingStation[],
        recommendedControllers: ISystemController[],
    ): IDockingStation[] {
        let availableSlots = recommendedControllers.reduce(
            (slotsCount, { maxDockingStations }) => slotsCount + maxDockingStations,
            0,
        );
        const recommendedDockings = new Array<IDockingStation>();
        let unassignedCameras = cameraCount;

        // Assign cameras using controllers with highest camera count first
        for (const dockingStation of dockingStations) {
            if (unassignedCameras < 0) {
                break;
            }

            const count = Math.floor(unassignedCameras / dockingStation.cameraBays);
            if (
                dockingStation.cameraBays === 1 &&
                (count > this.maxSingleDocks || availableSlots < count)
            ) {
                // Do not allow more than {this.maxSingleDocks} single docks
                break;
            }

            for (let i = 0; i < count; ++i) {
                recommendedDockings.push(dockingStation);
                unassignedCameras -= dockingStation.cameraBays;
                --availableSlots;
            }
        }

        if (unassignedCameras > 0) {
            // This can happen when we are not allowed to add any more single slots
            const nonSingleDockingStation = dockingStations[dockingStations.length - 2];
            while (unassignedCameras > 0) {
                recommendedDockings.push(nonSingleDockingStation);
                unassignedCameras -= nonSingleDockingStation.cameraBays;
            }
        }

        return recommendedDockings;
    }

    private createRecommendation(
        cameraCount: number,
        recommendedBodyWornCameras: IBodyWornCamera[],
        recommendedControllers: ISystemController[],
        recommendedDockingStations: IDockingStation[],
    ): IWearablesRecommendation[] {
        const bodyWornCameras =
            cameraCount > 0
                ? recommendedBodyWornCameras.map(
                      (bodyWornCamera) =>
                          ({
                              productId: bodyWornCamera.productId,
                              name: bodyWornCamera.name,
                              type: 'bodyWornCamera',
                              count: cameraCount,
                          }) as IWearablesRecommendation,
                  )
                : [];
        const controllers = recommendedControllers.reduce(
            (controllerRecord, controller) => {
                controllerRecord[controller.productId] = controllerRecord[controller.productId]
                    ? {
                          productId: controller.productId,
                          name: controller.name,
                          type: 'systemController',
                          count: controllerRecord[controller.productId].count + 1,
                      }
                    : {
                          productId: controller.productId,
                          name: controller.name,
                          type: 'systemController',
                          count: 1,
                      };

                return controllerRecord;
            },
            {} as Record<PiaId, IWearablesRecommendation>,
        );
        const dockingStations = recommendedDockingStations.reduce(
            (dockingRecord, dock) => {
                dockingRecord[dock.productId] = dockingRecord[dock.productId]
                    ? {
                          productId: dock.productId,
                          name: dock.name,
                          type: 'dockingStation',
                          count: dockingRecord[dock.productId].count + 1,
                      }
                    : {
                          productId: dock.productId,
                          name: dock.name,
                          type: 'dockingStation',
                          count: 1,
                      };

                return dockingRecord;
            },
            {} as Record<PiaId, IWearablesRecommendation>,
        );
        return [
            ...bodyWornCameras,
            ...Object.values(controllers),
            ...Object.values(dockingStations),
        ];
    }
}
