import { createSelector } from 'reselect';
import { getCurrentProjectSchedulesArray, ScheduleAggregationService } from 'app/modules/common';
import { AggregatedScheduleToQuartersService } from '../services';
import type { IScheduleModel, ITimeSerieModel } from 'app/core/persistence';
import { ScheduleDays, Time24 } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { getTimeSeriesForSchedulesRecord } from 'app/modules/schedules/selectors';
import { isEmpty } from 'lodash-es';
import { nameComparator } from 'app/utils';

export const getInstallationReportSchedules = createSelector(
    [getCurrentProjectSchedulesArray, getTimeSeriesForSchedulesRecord],
    (schedules, timeSeriesRecord) => {
        const scheduleAggregationService = ServiceLocator.get(ScheduleAggregationService);
        const aggregatedScheduleToQuartersService = ServiceLocator.get(
            AggregatedScheduleToQuartersService,
        );

        const sortedSchedules = schedules
            .filter((schedule) => schedule.systemDefined === false)
            .sort(nameComparator);

        if (isEmpty(sortedSchedules)) {
            return [];
        }

        const scheduleWithTimeSeries = sortedSchedules.map((schedule) => {
            return {
                ...schedule,
                timeSeries: timeSeriesRecord[schedule._id]
                    ? timeSeriesRecord[schedule._id].map((timeSerie) => {
                          return { ...timeSerie, scheduleDays: new ScheduleDays(timeSerie.days) };
                      })
                    : [],
            };
        });

        return scheduleWithTimeSeries.map((schedule) => {
            const scheduleModel = {
                id: schedule._id,
                rev: schedule._rev,
                creationDate: new Date(schedule.creationDate),
                entityVersion: schedule.entityVersion,
                name: schedule.name,
                type: schedule.type,
                systemDefined: schedule.systemDefined,
                updatedDate: new Date(schedule.updatedDate),
                locked: schedule.locked,
                timeSeries: schedule.timeSeries.map((timeSerie) => {
                    return {
                        start: new Time24(timeSerie.start),
                        end: new Time24(timeSerie.end),
                        days: timeSerie.scheduleDays,
                        creationDate: new Date(timeSerie.creationDate),
                        entityVersion: timeSerie.entityVersion,
                        id: timeSerie._id,
                        rev: timeSerie._rev,
                        type: timeSerie.type,
                        updatedDate: new Date(timeSerie.updatedDate),
                        locked: timeSerie.locked,
                    } as ITimeSerieModel;
                }),
            } as IScheduleModel;

            return {
                id: schedule._id,
                rev: schedule._rev,
                name: schedule.name,
                daySegments: aggregatedScheduleToQuartersService.getQuarters(
                    scheduleAggregationService.getAggregatedSchedule(scheduleModel),
                ),
            };
        });
    },
);
