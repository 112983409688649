import * as React from 'react';
import type { PiaId } from 'app/core/pia';
import { usePromise } from 'app/hooks';
import { useService } from 'app/ioc';
import { css } from '@emotion/css';
import { DeviceDescriptionService } from 'app/core/persistence';
import { Icon, ImagePlaceholder } from 'app/components';

interface IPartnerLogoProps {
    vendorPiaId?: PiaId;
    maxHeight?: React.CSSProperties['maxHeight'];
    maxWidth?: React.CSSProperties['maxWidth'];
}

export const PartnerLogo: React.FC<IPartnerLogoProps> = ({ vendorPiaId, maxHeight, maxWidth }) => {
    const deviceDescriptionService = useService(DeviceDescriptionService);
    const logoPromise = React.useMemo(
        () => deviceDescriptionService.getPartnerLogo(vendorPiaId),
        [deviceDescriptionService, vendorPiaId],
    );
    const [brokenImage, setBrokenImage] = React.useState<boolean>(false);

    const { result: logoUrl, pending: loading } = usePromise(logoPromise, [logoPromise]);
    const imgStyle = css`
        max-height: ${maxHeight ?? '70px'};
        max-width: ${maxWidth ?? '150px'};
    `;

    if (loading) return <ImagePlaceholder height="50px" width="110px" />;
    if (!logoUrl) return <Icon icon="extension" size="xl" color="blue" opaque />;

    return brokenImage ? (
        <Icon icon="extension" size="xl" color="blue" opaque />
    ) : (
        <img
            data-test-id={`partner_logo_${vendorPiaId}`}
            className={imgStyle}
            src={logoUrl}
            onError={() => setBrokenImage(true)}
        />
    );
};

PartnerLogo.displayName = 'PartnerLogo';
