import { injectable } from 'inversify';
import type { IBandwidthParams } from '../../models';
import { BandwidthCalculation } from './BandwidthCalculation';

@injectable()
export class BandwidthEstimateCalculatorService {
    public getBandwidthEstimate(
        bandwidthParams: IBandwidthParams,
        hasAudio: boolean,
        badWeatherPercent: number = 0.3,
    ) {
        return new BandwidthCalculation(
            bandwidthParams,
            hasAudio,
            badWeatherPercent,
        ).getBitRatesSiteDesigner();
    }
}
