import * as React from 'react';
import { Grid, Quantity, PopOver, ProductCardItem, ProductCard } from 'app/components';
import type { IAccessoryPickerProps } from './AccessoryPicker.component';
import { AccessoryPicker } from './AccessoryPicker.component';
import { t } from 'app/translate';
import type { IAccessory } from '../';
import type { IPersistedAccessory } from '../models';

interface IAccessoryGrid {
    accessories: IAccessory[];
    disabled: boolean;
    discontinued?: boolean;
    accessoryCategoryToDisplayCategoryMapping: IAccessoryPickerProps['accessoryCategoryToDisplayCategoryMapping'];
    otherCategory: IAccessoryPickerProps['otherCategory'];
    allOptionsSelected?: boolean;
    toggle(productId: number): void;
    changeQuantity(itemId: string, value: number): void;
}

export class AccessoryGrid extends React.Component<IAccessoryGrid> {
    public render() {
        const {
            accessories,
            toggle,
            changeQuantity,
            disabled,
            accessoryCategoryToDisplayCategoryMapping,
            otherCategory,
            discontinued,
            allOptionsSelected,
        } = this.props;

        return (
            <Grid spacing="half">
                {!disabled && !allOptionsSelected && (
                    <PopOver
                        maxHeight={350}
                        disabled={discontinued}
                        trigger={
                            <ProductCard>
                                <ProductCardItem
                                    name={discontinued ? t.discontinued : t.addAccessories}
                                    productId={null}
                                    icon={'add_circle'}
                                />
                            </ProductCard>
                        }
                        content={
                            <AccessoryPicker
                                items={accessories}
                                onClick={toggle}
                                accessoryCategoryToDisplayCategoryMapping={
                                    accessoryCategoryToDisplayCategoryMapping
                                }
                                otherCategory={otherCategory}
                            />
                        }
                    />
                )}
                {accessories
                    .filter((child): child is IPersistedAccessory => Boolean(child.id))
                    .map((child) => (
                        <ProductCard key={child.id}>
                            <ProductCardItem
                                name={child.name}
                                productId={child.productId}
                                discontinued={child.discontinued}
                            />
                            <Quantity
                                disabled={disabled}
                                min={1}
                                max={999}
                                onDelete={() => toggle(child.productId)}
                                onChange={(value: number) => {
                                    // Setting the quantity while waiting for action prevents flicker
                                    child.quantity = value;
                                    changeQuantity(child.id, value);
                                }}
                                value={child.quantity}
                            />
                        </ProductCard>
                    ))}
            </Grid>
        );
    }
}
