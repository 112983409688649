import * as React from 'react';
import { useSelector } from 'react-redux';
import { css, cx } from '@emotion/css';
import { t } from 'app/translate';
import { ColorsEnum } from 'app/styles';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { Text, Box, Stack, PiaImage, Button } from 'app/components';
import type { IAddProductProps } from 'app/modules/common';
import { getCurrentProjectItem, PiaItemMsrp } from 'app/modules/common';
import type { IPiaSpeaker } from 'app/core/pia';
import {
    getEditDeviceId,
    getSelectedSpeaker,
    getCalculatedSpeakerQuantity,
} from '../../../selectors';
import { DeviceSelectorActionService } from '../../../services';
import { AddProductModal } from '../../AddProductModal';

export const SpeakerSolution: React.FC = () => {
    const dotted = css`
        border-bottom: 2px dotted ${ColorsEnum.grey5};
        flex: 1 1 auto;
        height: 12px;
        margin-left: 0px;
    `;

    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const editDeviceId = useSelector<IStoreState, Id | null>((state) => getEditDeviceId(state));

    const suggestedQuantity = useSelector<IStoreState, number>((state) =>
        getCalculatedSpeakerQuantity(state, editDeviceId ?? undefined),
    );

    const editDeviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        (state) => getCurrentProjectItem(state, editDeviceId ?? undefined),
    );

    const selectedSpeaker = useSelector<IStoreState, IPiaSpeaker | undefined>((state) =>
        getSelectedSpeaker(state, editDeviceId ?? undefined),
    );

    const [showAddProductModal, setShowAddProductModal] = React.useState<boolean>(false);

    if (!selectedSpeaker) {
        return null;
    }

    const addOrUpdateSpeaker = () => {
        if (!editDeviceItem) {
            setShowAddProductModal(true);
            return;
        }
        actionService.addOrUpdateDevice(selectedSpeaker.id, editDeviceItem, undefined);
    };

    const addProduct = (addProductProps: IAddProductProps) => {
        actionService.addOrUpdateDevice(selectedSpeaker.id, undefined, addProductProps);
        setShowAddProductModal(false);
    };

    return (
        <React.Fragment>
            {showAddProductModal && (
                <AddProductModal
                    productInfo={{ productId: selectedSpeaker.id, name: selectedSpeaker.name }}
                    parentDeviceId={null}
                    onClose={() => setShowAddProductModal(false)}
                    onAddProduct={addProduct}
                />
            )}
            <Stack vertical>
                <Stack flex="fullWidth" spacing="halfCell">
                    <Stack>
                        <PiaImage piaId={selectedSpeaker.id} icon="category" imageSize="lg" />
                        <Text style="body" color="grey8">
                            {selectedSpeaker.name}
                        </Text>
                    </Stack>
                    <p className={cx(dotted)}></p>
                    <Text style="body" color="grey8">
                        {`${suggestedQuantity} ${t.abbreviationsGROUP.pieces}`}
                    </Text>
                </Stack>
                <Stack vertical>
                    <PiaItemMsrp piaId={selectedSpeaker.id} quantity={suggestedQuantity} />
                    <Box justifyContent="end">
                        <Button
                            primary={false}
                            disabled={
                                selectedSpeaker.id === editDeviceItem?.productId &&
                                suggestedQuantity === editDeviceItem.quantity
                            }
                            onClick={addOrUpdateSpeaker}
                        >
                            {editDeviceItem
                                ? `${t.change} ${suggestedQuantity}`
                                : `${t.add} ${suggestedQuantity}`}
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </React.Fragment>
    );
};
