import { injectable } from 'inversify';
import { Id } from 'app/core/persistence';
import { ActionCreator, ThunkAction } from 'app/store';
import { QuotationActions as Actions } from '../actions';
import {
    getPriceListForRegion,
    getCurrencyFromCountryCode,
    getCurrentProjectLocation,
} from 'app/modules/common';
import { QuotationService } from './Quotation.service';
import { msrpService } from 'app/modules/msrp';

@injectable()
export class QuotationActionService {
    constructor(private quotationService: QuotationService) {}

    @ActionCreator()
    public getLocalCurrencyPriceListAndMsrpProps(
        projectQuotationId: Id,
        msrpCurrency: string | null,
        msrpLastUpdated: number | null,
    ): ThunkAction {
        return async (dispatch: any, getState) => {
            const state = getState();

            const location = getCurrentProjectLocation(state);
            const localCurrency = getCurrencyFromCountryCode(location?.countryCode);
            const msrpPriceList = getPriceListForRegion(state);
            const priceList = msrpPriceList
                ? (await msrpService.convertPricesToCurrency(msrpPriceList, localCurrency)) ||
                  msrpPriceList
                : undefined;

            dispatch({
                type: Actions.GetMsrpProps,
                payload: this.quotationService.getLocalCurrencyPriceListAndMsrpProps(
                    projectQuotationId,
                    msrpCurrency,
                    localCurrency,
                    msrpLastUpdated,
                    priceList,
                ),
            });
        };
    }
}
