import { injectable } from 'inversify';
import type { PiaId } from 'app/core/pia';
import { saveAs } from 'file-saver';
import { encrypt, isErrorType } from 'app/core/persistence';
import type { LanguageCode } from 'app/utils';
import { languageCodeToTextLanguage } from 'app/utils';
import { PiaQueriesService } from 'app/modules/common';
import i18next from 'i18next';

const TEXT_API_URL = '/content';

@injectable()
export class DocumentationCommunicator {
    constructor(private piaQueriesService: PiaQueriesService) {}
    private readonly documentationUrl = 'https://www.axis.com/dam/kaj/v2/';

    public getDataSheets = async (piaIds: PiaId[], fileName: string): Promise<void> => {
        const selectedLanguage = i18next.language as LanguageCode;
        const languageCode = languageCodeToTextLanguage[selectedLanguage];
        // according to KAJ documentation: "Note: This has to be a PIAId of a product or productvariant"
        // however product variant did not work when this was implemented, that's why parentPiaId is used instead.
        const parentPiaIds = new Set<PiaId>([]);
        piaIds.forEach((id) => {
            const piaItem = this.piaQueriesService.getPiaItemFromId(id);
            if (piaItem) {
                parentPiaIds.add(piaItem.parentId);
            }
        });
        const parentPiaIdsArray = Array.from(parentPiaIds);
        const documentQueryPart = parentPiaIdsArray.length > 1 ? 'zip/' : 'singledoc/';
        const fetchString = `${this.documentationUrl}${documentQueryPart}${parentPiaIdsArray.join(
            ',',
        )}/${languageCode}/ds`;
        try {
            await fetch(fetchString, {
                method: 'GET',
            })
                .then((response) => {
                    return response.blob();
                })
                .then((file) => {
                    saveAs(
                        file,
                        parentPiaIdsArray.length > 1 ? `${fileName}.zip` : `${fileName}.pdf`,
                    );
                });
        } catch (error) {
            if (isErrorType(error)) {
                throw error;
            }
        }
    };

    public checkDocumentationAvailability = async (
        piaIds: PiaId[],
    ): Promise<Record<PiaId, Record<string, Record<string, string>>> | undefined> => {
        const fetchString = `${TEXT_API_URL}/${encrypt(piaIds.join('y'))}/en-US/url_DS`;
        try {
            const result = await fetch(fetchString, {
                method: 'GET',
            });
            return result.json();
        } catch (error) {
            if (isErrorType(error)) {
                throw error;
            }
        }
    };
}
