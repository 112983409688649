import { reducerBuilder } from 'app/store';
import type { IModalState } from 'app/modal';
import { ModalActions } from './modalActions';

const initialModalState: IModalState = {
    visible: false,
    header: '',
    body: '',
    buttons: [],
    warning: false,
};

export const modalReducer = reducerBuilder<IModalState>()
    .setInitialState((): IModalState => {
        return initialModalState;
    })
    .onAction<IModalState>(ModalActions.ShowModal, (state, action) => ({
        ...state,
        ...action.payload,
        visible: true,
    }))
    .onAction(ModalActions.HideModal, () => ({
        ...initialModalState,
    }))
    .create();
