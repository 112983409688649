import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { isDoorController } from '../../../utils/piaDeviceTypeCheckers';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';

/**
 * Changes door controllers' properties type from pac to doorController
 */
@injectable()
export class Migration33To34 extends MigrationProviderBase {
    public from: number = 33;
    public to: number = 34;

    constructor(private piaItemService: PiaItemService<IPiaItem>) {
        super();
    }

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'item' && !!entity.properties.pac) {
                const piaItem = entity.productId
                    ? this.piaItemService.get(entity.productId).first()
                    : null;

                if (isDoorController(piaItem)) {
                    const oldProps = entity.properties.pac;
                    entity.properties = { doorController: oldProps };
                }
            }

            return entity;
        };
    }
}
