import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';

/** Migrate to support project retention time  */
@injectable()
export class Migration48To49 extends MigrationProviderBase {
    public from: number = 48;
    public to: number = 49;

    public provideMigration() {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'project') {
                entity.recordingRetentionTimeInDays = 30;
                return entity;
            }

            if (entity.type === 'profile') {
                entity.storage.useProjectSetting = false;
                if (entity.storage.retentionTime >= 9999) {
                    entity.storage.retentionTime = 9999;
                }
                return entity;
            }

            return entity;
        };
    }
}
