import { injectable } from 'inversify';
import type { IPersistence, IItemEntity, IIdRev, IItem } from 'app/core/persistence';
import { defaultPacFilter, ItemService, InstallationPointService } from 'app/core/persistence';

import { t } from 'app/translate';
import type { PiaCategory, PiaId } from 'app/core/pia';
import { PiaItemPacCategory } from 'app/core/pia';
import { ModalService } from 'app/modal';
import { AccessoryService } from '../../accessory/services';
import { IAddProductProps } from '../models';

@injectable()
export class TwoNDevicesService {
    constructor(
        private itemService: ItemService,
        private modalService: ModalService,
        private installationPointService: InstallationPointService,
        private accessoryService: AccessoryService,
    ) {}

    public async addOrUpdateDevice(
        piaItemId: PiaId,
        category: PiaCategory,
        itemToEdit?: IPersistence<IItemEntity>,
        newItemProps?: IAddProductProps,
        installationPointsIdRev?: IIdRev[],
    ) {
        if (itemToEdit) {
            return this.updateTwoNDevice(piaItemId, category, itemToEdit, installationPointsIdRev);
        } else if (newItemProps) {
            return this.addDevice(piaItemId, newItemProps, category);
        }
    }

    private async addDevice(
        productId: PiaId,
        newItemProps: IAddProductProps,
        category: PiaCategory,
    ) {
        const item: IItem = {
            productId,
            name: newItemProps.name,
            description: '',
            notes: newItemProps.notes || '',
            properties: this.toProperties(category),
            quantity: newItemProps.quantity,
            color: newItemProps.color,
        };

        return this.itemService.addToCurrentProject(item);
    }

    private async updateTwoNDevice(
        piaId: PiaId,
        category: PiaCategory,
        itemToUpdate: IPersistence<IItemEntity>,
        installationPointsIdRev?: IIdRev[],
    ) {
        const originalItemEntity = await this.itemService.getItem(itemToUpdate._id);

        const productIdIsUpdated = itemToUpdate && itemToUpdate.productId !== piaId;
        const hasIncompatibleChildren = this.accessoryService.getHasPiaItemsToRemove(
            itemToUpdate._id,
            piaId,
        );
        const accessoriesMustBeRemoved = productIdIsUpdated && hasIncompatibleChildren;

        const nbrInstallationPoints = installationPointsIdRev ? installationPointsIdRev.length : 0;

        const quantityLessThanIPs = itemToUpdate.quantity < nbrInstallationPoints;

        const installationPointsMustBeRemoved =
            installationPointsIdRev &&
            installationPointsIdRev.length > 0 &&
            (quantityLessThanIPs || category !== 'doorstations');

        if (
            installationPointsMustBeRemoved &&
            accessoriesMustBeRemoved &&
            installationPointsIdRev
        ) {
            const confirm = await this.accessoryService.getConfirmDialogue(
                itemToUpdate._id,
                piaId,
                t.removeAccessoriesAndInstallationPointsConfirmationGROUP.header,
                t.removeAccessoriesAndInstallationPointsConfirmationBody,
            );

            // If the user clicked cancel we should not continue
            if (!confirm) {
                return;
            }
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                originalItemEntity._id,
                piaId,
            );
            await this.installationPointService.removeInstallationPoints(installationPointsIdRev);
        } else if (accessoriesMustBeRemoved) {
            const confirm = await this.accessoryService.getConfirmDialogue(itemToUpdate._id, piaId);

            // If the user clicked cancel we should not continue
            if (!confirm) {
                return;
            }
            await this.accessoryService.removeIncompatibleAccessoriesAndMounts(
                originalItemEntity._id,
                piaId,
            );
        } else if (installationPointsMustBeRemoved && installationPointsIdRev) {
            /** Warn user if trying to change to a device that can't have installationPoints or when lowering quantity */
            const confirm = await this.modalService.createConfirmDialog({
                header: t.removeInstallationPointsConfirmationGROUP.header,
                body: t.removeInstallationPointsConfirmationGROUP.body,
                cancelButtonText: t.cancel,
                confirmButtonText: t.change,
            })();

            // If the user clicked cancel we should not continue
            if (!confirm) {
                return;
            }
            await this.installationPointService.removeInstallationPoints(installationPointsIdRev);
        }

        // const defaultProfile = this.currentProjectService.getProjectEntity().defaultProfile;

        const updatedProps = {
            quantity: itemToUpdate.quantity,
            name: itemToUpdate.name,
            productId: piaId,
            properties: this.toProperties(category, itemToUpdate),
        };

        return this.itemService.updateItem(itemToUpdate._id, updatedProps);
    }

    private toProperties(category: PiaCategory, itemToUpdate?: IPersistence<IItemEntity>) {
        switch (category) {
            case PiaItemPacCategory.ACCESSSERVER:
            case PiaItemPacCategory.ANSWERINGUNIT:
            case PiaItemPacCategory.NETWORKREADER:
                return {
                    pac: {
                        filter:
                            itemToUpdate && itemToUpdate.properties.pac
                                ? itemToUpdate.properties.pac.filter
                                : defaultPacFilter,
                    },
                };
            case PiaItemPacCategory.DOORSTATIONS:
                const props = {
                    pac: {
                        filter:
                            itemToUpdate && itemToUpdate.properties.pac
                                ? itemToUpdate.properties.pac?.filter
                                : defaultPacFilter,
                    },
                };
                return props;
            default:
                throw new Error('Not a 2N device category');
        }
    }
}
