import * as React from 'react';
import { t } from 'app/translate';
import { Text, SelectableList } from 'app/components';
import { CustomItemListRow } from './CustomItemListRow.container';
import { AddCustomItem } from './AddCustomItem.container';
import { getCustomItemSortOrder, getFilteredCustomItemIds } from '../selectors';
import { getCurrentProjectLocked, TableHeaderSort } from 'app/modules/common';
import { useService } from 'app/ioc';
import type {
    Id,
    ICustomItem,
    ICustomItemSort,
    CustomItemSortProperty,
} from 'app/core/persistence';
import { CustomItemService, UserCustomItemService } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { useSelector } from 'react-redux';
import { CustomItemsActionService } from '../services';

export const CustomItemList: React.FC = () => {
    const customItemService = useService(CustomItemService);
    const { updateSortOrder } = useService(CustomItemsActionService);
    const userCustomItemService = useService(UserCustomItemService);
    const customItemIds: Id[] = useSelector(getFilteredCustomItemIds);
    const projectLocked: boolean = useSelector(getCurrentProjectLocked);
    const sortOrder: ICustomItemSort = useSelector(getCustomItemSortOrder);

    const renderCustomItems = () =>
        customItemIds.map((id) => <CustomItemListRow key={id} customItemId={id} />);

    const getSortDirection = (sortProperty: CustomItemSortProperty) =>
        sortOrder.sortBy === sortProperty ? sortOrder.direction : 'none';

    const onAdd = (customItem: ICustomItem, fromUserCustomItems?: boolean) => {
        eventTracking.logUserEvent(
            'Custom Items',
            'Add Custom Item',
            fromUserCustomItems
                ? 'Added from existing user custom items'
                : 'Added as new custom item',
        );
        customItemService.addProjectCustomItem(customItem);
    };

    const onDeleteUserCustomItem = (id: Id) => {
        eventTracking.logUserEvent('Custom Items', 'Delete User Custom Item');
        userCustomItemService.deleteUserCustomItem(id);
    };

    const onChangeSortOrder = (sortProperty: CustomItemSortProperty) => {
        // User changes direction of sort order
        if (sortOrder.sortBy === sortProperty) {
            return updateSortOrder({
                sortBy: sortProperty,
                direction: sortOrder.direction === 'ascending' ? 'descending' : 'ascending',
            });
        }
        // User changes sorting property
        return updateSortOrder({ sortBy: sortProperty, direction: 'ascending' });
    };

    const items = renderCustomItems();
    const getTableHeader = (title: string, sortProperty: CustomItemSortProperty) => (
        <TableHeaderSort
            onClick={() => onChangeSortOrder(sortProperty)}
            direction={getSortDirection(sortProperty)}
        >
            <Text>{title}</Text>
        </TableHeaderSort>
    );

    if (!projectLocked) {
        items.push(
            <AddCustomItem
                key="addCustomItem"
                onAdd={onAdd}
                onDeleteUserCustomItem={onDeleteUserCustomItem}
            />,
        );
    }

    return (
        <SelectableList
            noHeaderBackground
            notScrollable
            verticallyCenterContents
            header={[
                '',
                getTableHeader(t.customItemsName, 'name'),
                getTableHeader(t.systemComponentsVendor, 'vendor'),
                getTableHeader(t.customItemsPartNumber, 'partNumber'),
                getTableHeader(t.customItemsCategory, 'category'),
                getTableHeader(t.customItemsQuantity, 'quantity'),
                '',
            ]}
            emptyText={t.customItemsNoItems}
        >
            {items}
        </SelectableList>
    );
};

CustomItemList.displayName = 'CustomItemList';
