import * as React from 'react';
import type { Spacings } from 'app/styles/spacings.type';
import { SpacingsEnum } from 'app/styles/spacings.type';
import type { IExtendableComponentWithChildren } from '../../models';
import { extendableProps } from '../../services';
import { css } from '@emotion/css';

interface ISpacerProps extends IExtendableComponentWithChildren {
    /**
     * Enables transitions on property changes
     */
    transition?: string;
    /**
     * Sets the spacer to have horizontal spacing instead of vertical
     */
    horizontal?: boolean;
    /**
     * The height of the spacer element using default spacings
     */
    spacing?: Spacings;
    /**
     * The custom height of the spacer element
     */
    customSpacing?: string;
}

/**
 * Add a vertical space. Use only if there are no other ways (Stack, Grid, etc) to achieve the required layout.
 */
export class Spacer extends React.Component<ISpacerProps> {
    public render() {
        const {
            children,
            horizontal,
            transition,
            spacing = 'base',
            customSpacing,
            ...extendedProps
        } = this.props;

        const attributes = extendableProps(
            extendedProps,
            {
                className: css`
                    height: ${horizontal
                        ? undefined
                        : customSpacing
                          ? customSpacing
                          : SpacingsEnum[spacing]};
                    width: ${horizontal
                        ? customSpacing
                            ? customSpacing
                            : SpacingsEnum[spacing]
                        : undefined};
                    flex-shrink: 0;
                    transition: ${transition};
                `,
            },
            true,
        );

        return <div {...attributes}>{children}</div>;
    }
}
