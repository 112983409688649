import * as React from 'react';
import { Label } from '../../text/label/Label.component';
import { v4 as uuidv4 } from 'uuid';
import type { IAutoTestable } from '../../ui-test';
import { Checkbox } from '../checkbox/Checkbox.component';
import { Box } from '../../containers/box/Box.component';

interface ISliderToggleProps extends IAutoTestable {
    /**
     * Whether the toggle is activated
     */
    active: boolean;
    /**
     * Disables the toggle
     */
    disabled?: boolean;
    /**
     * The label of the active value
     */
    activeLabel: string;
    /**
     * The label of the inactive value
     */
    inactiveLabel: string;
    /**
     * onChange callback function when user checks/un-checks the toggle
     */
    onChange(newVal: boolean): void;
}

/**
 * A slider toggle that toggles between two options
 */
export class SliderToggle extends React.Component<ISliderToggleProps> {
    private uuid = uuidv4();

    public render() {
        const id = `id-${this.uuid}`;

        return (
            <Box width="70%" alignItems="center">
                <Box width="33%" justifyContent="end">
                    <Label align="right" key="inactiveLabel" for={id}>
                        {this.props.inactiveLabel}
                    </Label>
                </Box>
                <Box width="33%" minWidth="50px" justifyContent="center">
                    <Checkbox
                        id={id}
                        slider
                        onChange={this.props.onChange}
                        selected={this.props.active}
                        disabled={this.props.disabled}
                    />
                </Box>
                <Box width="33%" justifyContent="start">
                    <Label align="left" key="activeLabel" for={id}>
                        {this.props.activeLabel}
                    </Label>
                </Box>
            </Box>
        );
    }
}
