import { createCachedSelector } from 're-reselect';
import { getPiaCameraForProductId, toCacheKey } from 'app/modules/common';
import { getLensesForProductItem } from './getLensToDisplayForProductItem';
import type { IPiaAccessory, IPiaCamera, IPiaItem, IPiaSensorUnit } from 'app/core/pia';

interface IFoV {
    maxVerticalFOV: number;
    minVerticalFOV: number;
    maxHorizontalFOV: number;
    minHorizontalFOV: number;
}

/**
 * Retrieves FoV for lens if used by an item being edited or an item that needs a replacement lens to fulfill filter requirements
 */
export const getAccessoryLensFov = createCachedSelector(
    [getLensesForProductItem, getPiaCameraForProductId],
    (lenses, piaCamera): IFoV | undefined => findLensFov(lenses, piaCamera),
)(toCacheKey);

export const findLensFov = (
    lenses: IPiaAccessory[] | undefined,
    piaCamera: IPiaCamera | IPiaSensorUnit | IPiaItem | undefined,
): IFoV | undefined => {
    if (!piaCamera || !lenses?.[0]) {
        return undefined;
    }

    const piaRelationReference = piaCamera.relations.find(({ id }) => id === lenses[0].id);
    if (!piaRelationReference) {
        return undefined;
    }

    const { horizontalFOV, verticalFOV } = piaRelationReference.relationProperties;
    return {
        maxHorizontalFOV: horizontalFOV?.max ?? 0,
        minHorizontalFOV: horizontalFOV?.min ?? 0,
        maxVerticalFOV: verticalFOV?.max ?? 0,
        minVerticalFOV: verticalFOV?.min ?? 0,
    };
};
