import {
    BufferAttribute,
    BufferGeometry,
    EdgesGeometry,
    LineSegments,
    Mesh,
    Object3D,
} from 'three';
import { blockerEdgeMaterial, blockerMaterial, transparentMaterial } from '../materials';

const INFINITY = 10000;

export class Blockers extends Object3D {
    private mesh: Mesh;
    private infiniteHeightMesh: Mesh;
    private wireframe: LineSegments;

    constructor() {
        super();
        this.mesh = new Mesh(undefined, blockerMaterial);
        this.infiniteHeightMesh = new Mesh(undefined, transparentMaterial);
        this.wireframe = new LineSegments(undefined, blockerEdgeMaterial);
        this.add(this.wireframe);
        this.add(this.mesh);
    }

    public update(blockers: number[][][], height: number) {
        const blockerGeometry = this.extrudeBlockers(blockers, height);
        const edgesGeometry = new EdgesGeometry(blockerGeometry);
        const infiniteHeightBlockerGeometry = this.extrudeBlockers(blockers, INFINITY);

        this.mesh.geometry = blockerGeometry;
        this.infiniteHeightMesh.geometry = infiniteHeightBlockerGeometry;
        this.wireframe.geometry = edgesGeometry;
        this.wireframe.material = blockerEdgeMaterial;
    }

    public getIntersectObjects(): Array<Object3D> {
        return [this.infiniteHeightMesh];
    }

    private extrudeBlockers(blockers: number[][][], height: number) {
        const bottom = 0.01;
        const geometry = new BufferGeometry();

        const vertices: number[] = [];
        blockers.forEach((blocker) => {
            for (let i = 0; i < blocker.length - 1; i++) {
                const p1 = blocker[i];
                const p2 = blocker[i + 1];

                vertices.push(p1[0], bottom, p1[1]);
                vertices.push(p2[0], bottom, p2[1]);
                vertices.push(p1[0], height, p1[1]);
                vertices.push(p1[0], height, p1[1]);
                vertices.push(p2[0], bottom, p2[1]);
                vertices.push(p2[0], height, p2[1]);
            }
        });
        geometry.setAttribute('position', new BufferAttribute(new Float32Array(vertices), 3));
        geometry.computeBoundingBox();
        geometry.computeVertexNormals();

        return geometry;
    }
}
