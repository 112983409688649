import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getPiaIdMsrp, convertMsrpToProjectCurrency } from '../selectors';
import type { ILocaleCurrencyProps } from './LocaleCurrency.container';
import { LocaleCurrency } from './LocaleCurrency.container';

interface IPiaItemMsrpOwnProps {
    /**
     * The PIA ID for which to look up a MSRP
     */
    piaId: number;
    /**
     * If set will multiply the returned MSRP with the specified quantity
     * Otherwise returns MSRP for one (1) item.
     */
    quantity?: number;
    alwaysShow?: boolean;
    includeMultipacks?: boolean;
    renderFn?(msrp: JSX.Element): JSX.Element;
}

function mapStateToProps(
    storeState: IStoreState,
    ownProps: IPiaItemMsrpOwnProps,
): ILocaleCurrencyProps {
    const msrpInPriceListCurrency = getPiaIdMsrp(storeState, ownProps);

    // Return nothing if there is no MSRP
    if (!msrpInPriceListCurrency || !msrpInPriceListCurrency.value) {
        return {};
    }

    const msrpInProjectCurrency = convertMsrpToProjectCurrency(storeState, msrpInPriceListCurrency);

    return {
        msrpInPriceListCurrency,
        msrpInProjectCurrency,
        alwaysShow: ownProps.alwaysShow,
        renderFn: ownProps.renderFn,
    };
}

/**
 * A component that fetches the MSRP of the provided
 * PIA ID and displays it in the appropriate currency
 * and locale.
 */
export const PiaItemMsrp = connect(mapStateToProps)(LocaleCurrency);

PiaItemMsrp.displayName = 'PiaItemMsrp';
