import { Module } from 'app/ioc';
import { systemAccessoriesReducer } from './state/SystemAccessories.reducer';
import { SystemAccessoriesService, SystemAccessoriesActionService } from './services';

@Module({
    binder: (binder) => {
        binder.bindService(SystemAccessoriesService);
        binder.bindService(SystemAccessoriesActionService);
    },
    reducers: {
        systemAccessories: systemAccessoriesReducer,
    },
})
export class SystemAccessoriesModule {}
