import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import type { IStoreState } from 'app/store';
import type { IPiaItem, IPiaSensorDevice } from 'app/core/pia';
import { PiaItemState } from 'app/core/pia';
import {
    createPrice,
    getCurrentProjectLocation,
    getPiaIdFromProps,
    getPiaItemsRecord,
    getPriceListForRegion,
    getAllPiaCameras,
    deviceIcons,
    toCacheKey,
    getAllPiaSensorUnits,
} from 'app/modules/common';
import type { DeviceGroup, IProductItem } from '../models';
import { getUspsForSingleCamera } from '../utils';
import { getGenericProductInfo } from './getGenericProductInfo';

export const getSelectedProductId = (state: IStoreState) => state.deviceSelector.selectedProductId;
export const getEditDeviceId = (state: IStoreState) => state.deviceSelector.editDeviceId;
export const getParentDeviceId = (state: IStoreState) => state.deviceSelector.parentId;
export const getIsEditMode = (state: IStoreState) => state.deviceSelector.editDeviceId !== null;

export const getProductItem = createCachedSelector(
    [
        getPiaItemsRecord,
        getGenericProductInfo,
        getPiaIdFromProps,
        getPriceListForRegion,
        getCurrentProjectLocation,
    ],
    (piaItems, productInfo, piaId, priceListForRegion, projectLocation?) => {
        const piaItem = piaId ? piaItems[piaId] : undefined;
        if (!piaItem || !projectLocation) {
            return;
        }

        const productItem: IProductItem = {
            icon: deviceIcons.categoryToIcon(piaItem.category),
            name: piaItem.name,
            productId: piaItem.id,
            discontinued: piaItem.state > PiaItemState.EXTERNALLY_ANNOUNCED,
            usps: getUsps(productInfo.deviceGroup, piaItem),
            vendor: piaItem.properties.vendor,
        };
        if (priceListForRegion) {
            const msrp = createPrice(piaItem.versions, projectLocation, priceListForRegion);

            productItem.currency = msrp.currency;
            productItem.value = msrp.value;
        }
        return productItem;
    },
)(toCacheKey);

export const getSelectedCamera = createSelector(
    [getSelectedProductId, getAllPiaCameras],
    (selectedProductId, cameras) => cameras.find(({ id }) => selectedProductId === id),
);

export const getSelectedSensorUnit = createSelector(
    [getSelectedProductId, getAllPiaSensorUnits],
    (selectedProductId, sensorUnits) => sensorUnits.find(({ id }) => selectedProductId === id),
);

export const getSelectedPiaItem = createSelector(
    [getSelectedProductId, getPiaItemsRecord],
    (selectedProductId, piaItems) => {
        return selectedProductId ? piaItems[selectedProductId] : undefined;
    },
);

export const getUsps = (deviceGroup: DeviceGroup, piaItem: IPiaItem) => {
    if (deviceGroup === 'cameras' || deviceGroup === 'sensorunits') {
        return getUspsForSingleCamera(piaItem as IPiaSensorDevice);
    }
    return undefined;
};
