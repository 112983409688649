import * as React from 'react';
import type { Icons } from '../../ui';
import { DropDown, DropDownMenuText } from '../../ui';
import { TextInput } from '../textInput/TextInput.component';
import type { IAutoTestable } from '../../ui-test';

export interface IAutoCompleteProps extends IAutoTestable {
    /** React Nodes to render in the suggestion box */
    suggestions: React.ReactNode;
    /** The search text */
    searchText: string;
    /** The text to show in the suggestion box before the user types */
    searchHint?: string;
    /** The text to show if no matching suggestions where found  */
    noMatchHint: string;
    /** The text to show as placeholder in the text input */
    placeholderHint: string;
    /** An optional icon to show in the text input */
    icon?: Icons;
    /** Open results in a React Portal, useful if shown in Modal */
    openInPortal?: boolean;
    /** Hides border of input field */
    noBorder?: boolean;
    /** Autofocus the input field */
    autoFocus?: boolean;
    /**
     * Allow text input to fill flex space
     */
    grow?: boolean;
    /**
     * Override the minimum width (in px) of the drop down.
     * Defaults to 140px.
     */
    minWidth?: number;
    /** What to do when the user types */
    onValueChange(newValue: string): void;
    /** Callback when the text was cleared */
    onClear?(): void;
}

/**
 * This is a simple component implementing a `<DropDown`> with a `<TextInput>` trigger.
 * It should be used with a container component that handles all the logic.
 */
export const AutoComplete: React.FC<IAutoCompleteProps> = (props) => {
    const getTrigger = () => {
        return (
            <TextInput
                noBorder={props.noBorder}
                testId={props.testId}
                clear={clear}
                icon={props.icon}
                value={props.searchText}
                onChange={props.onValueChange}
                autoFocus={props.autoFocus}
                placeholder={props.placeholderHint}
                changeCriteria="key"
                grow={props.grow}
            />
        );
    };

    const getContents = () => {
        return props.suggestions
            ? props.suggestions
            : props.searchText.length > 0
              ? [<DropDownMenuText label={props.noMatchHint} />]
              : props.searchHint && [<DropDownMenuText label={props.searchHint} />];
    };

    const clear = () => {
        props.onValueChange('');
        props.onClear?.();
    };
    return (
        <DropDown
            hideEmptyContentBorder
            openInPortal={props.openInPortal}
            hasInput
            trigger={getTrigger()}
            contents={getContents()}
            minWidth={props.minWidth}
        />
    );
};
