import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';

export const getIdFromProps = (_state: IStoreState, itemId: Id | undefined) => itemId;
export const getIdFromPropsRequired = (_state: IStoreState, itemId: Id) => itemId;
export const getProfileIdFromProps = (_state: IStoreState, profileId: Id) => profileId;
export const getScheduleIdFromProps = (_state: IStoreState, scheduleId: Id) => scheduleId;
export const getPiaIdFromProps = (_state: IStoreState, piaId: PiaId | undefined) => piaId;
export const getPiaIdsFromProps = (_state: IStoreState, piaIds: PiaId[]) => piaIds;
