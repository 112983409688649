import * as React from 'react';
import { Box, Checkbox, Heading, IconText, Stack } from 'app/components';
import type { Id, IItemNetworkSettings, IProjectNetworkSettings } from 'app/core/persistence';
import { t } from 'app/translate';
import { Validator } from 'ip-num';
import { IPAddressField } from './IPAddressField';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import type { IIpItem } from '../selectors';
import { getIpToIdRecord } from '../selectors';
import { getErrors, getIpIsOccupied } from './networkSettingsValidator';
interface IDeviceNetworkFormProps {
    deviceId: Id;
    multipleNetworkSettings?: string;
    networkSettings: IItemNetworkSettings;
    currentProjectNetworkSettings?: IProjectNetworkSettings;
    onChange(networkSettings: IItemNetworkSettings): void;
}

export interface IDeviceNetworkErrors {
    addresses: boolean[];
    subnetMask: boolean;
    defaultRouter: boolean;
}

export type fieldName = keyof Pick<
    IItemNetworkSettings,
    'defaultRouter' | 'subnetMask' | 'addresses'
>;

interface IOccupiedField {
    field: fieldName;
    occupiedBy: IIpItem;
}

const createFormValue = (
    networkSettings: IItemNetworkSettings,
    currentProjectNetworkSettings?: IProjectNetworkSettings,
): IItemNetworkSettings => ({
    override: networkSettings.override ?? false,
    dhcp: networkSettings.dhcp ?? false,
    subnetMask:
        'subnetMask' in networkSettings && !networkSettings.dhcp
            ? networkSettings.subnetMask
            : currentProjectNetworkSettings?.subnetMask ?? '',
    defaultRouter:
        'defaultRouter' in networkSettings && !networkSettings.dhcp
            ? networkSettings.defaultRouter
            : currentProjectNetworkSettings?.defaultRouter ?? '',
    addresses: networkSettings.addresses,
});

export const DeviceNetworkForm: React.FunctionComponent<IDeviceNetworkFormProps> = ({
    multipleNetworkSettings,
    networkSettings,
    currentProjectNetworkSettings,
    onChange,
    deviceId,
}) => {
    const [errors, setErrors] = React.useState<IDeviceNetworkErrors>({
        addresses: networkSettings.addresses.map(
            (address) => !Validator.isValidIPv4String(address)[0],
        ),
        subnetMask: false,
        defaultRouter: false,
    });
    const [formValue, setFormValue] = React.useState<IItemNetworkSettings>(
        createFormValue(networkSettings, currentProjectNetworkSettings),
    );

    const ipToIdRecord = useSelector<IStoreState, Record<string, IIpItem[]>>(getIpToIdRecord);

    const [duplicatedIp, setDuplicatedIp] = React.useState<false | IOccupiedField>(false);

    React.useEffect(() => {
        // Clear duplicate errors when changing device or toggling dhcp.
        setDuplicatedIp(false);
    }, [deviceId, networkSettings.dhcp]);

    // Update form values when selected device or network settings change
    React.useEffect(() => {
        setErrors(getErrors(networkSettings));
        setFormValue(createFormValue(networkSettings, currentProjectNetworkSettings));
    }, [networkSettings, deviceId, currentProjectNetworkSettings]);

    const onChangeIPField = (key: fieldName, value: string | string[], addressIndex = 0) => {
        const isValidIPv4 = Array.isArray(value)
            ? Validator.isValidIPv4String(value[addressIndex])[0]
            : Validator.isValidIPv4String(value)[0];

        const ipIsTaken = getIpIsOccupied(
            key,
            Array.isArray(value) ? value[addressIndex] : value,
            ipToIdRecord,
            deviceId,
            Array.isArray(value) ? value : undefined,
        );
        setDuplicatedIp(
            ipIsTaken !== false
                ? {
                      field: key,
                      occupiedBy: ipIsTaken[0],
                  }
                : false,
        );
        const hasError = !isValidIPv4 || ipIsTaken !== false;
        if (key === 'addresses') {
            setErrors({
                ...errors,
                // Only update errors for address field being edited
                addresses: errors.addresses.map((address, index) =>
                    addressIndex === index ? hasError : address,
                ),
            });
        } else {
            setErrors({ ...errors, [key]: hasError });
        }
        if (!hasError) {
            onChange({ ...formValue, [key]: value });
        }
    };

    return (
        <Stack vertical>
            <Heading>{t.ipConfiguration}</Heading>
            {formValue.override && (
                <Stack>
                    <Checkbox
                        testId="DHCP"
                        selected={!!formValue.dhcp}
                        onChange={(value) => onChange({ ...formValue, dhcp: value })}
                    >
                        {t.obtainIPAddressAutomatically}
                    </Checkbox>
                </Stack>
            )}
            {networkSettings && currentProjectNetworkSettings && (
                <Box maxWidth="300px">
                    <Stack vertical>
                        {formValue.dhcp ? (
                            <IPAddressField
                                testId="Automatic_DHCP"
                                label={t.ipAddress}
                                value={t.useDHCP}
                            />
                        ) : (
                            <>
                                {multipleNetworkSettings ? (
                                    <IPAddressField
                                        testId="IP_address"
                                        override={formValue.override}
                                        label={t.ipAddress}
                                        value={multipleNetworkSettings}
                                        error={errors.addresses[0]}
                                        onChange={(value) => onChangeIPField('addresses', [value])}
                                    />
                                ) : (
                                    formValue.addresses.map((address, index) => (
                                        <IPAddressField
                                            testId="IP_address"
                                            key={index}
                                            override={formValue.override}
                                            label={t.ipAddress}
                                            value={address ?? ''}
                                            error={errors.addresses[index]}
                                            onChange={(value) =>
                                                onChangeIPField(
                                                    'addresses',
                                                    formValue.addresses.map((formAddress, i) =>
                                                        i === index ? value : formAddress,
                                                    ),
                                                    index,
                                                )
                                            }
                                        />
                                    ))
                                )}
                                <IPAddressField
                                    testId="Subnet_mask"
                                    override={formValue.override}
                                    label={t.ipSubnetMask}
                                    value={formValue.subnetMask ?? ''}
                                    error={errors.subnetMask}
                                    onChange={(value) => onChangeIPField('subnetMask', value)}
                                />
                                <IPAddressField
                                    testId="Default_router"
                                    override={formValue.override}
                                    label={t.ipDefaultRouter}
                                    value={formValue.defaultRouter ?? ''}
                                    error={errors.defaultRouter}
                                    onChange={(value) => onChangeIPField('defaultRouter', value)}
                                />
                                {duplicatedIp && (
                                    <IconText
                                        icon="warning_small"
                                        iconProps={{
                                            size: 'sm',
                                        }}
                                        color="red"
                                    >
                                        {getIpOccupiedText(duplicatedIp)}
                                    </IconText>
                                )}
                            </>
                        )}
                    </Stack>
                </Box>
            )}
            <Box>
                <Checkbox
                    testId="IP_configuration_override"
                    slider
                    disabled={!!multipleNetworkSettings}
                    selected={!!formValue.override}
                    onChange={(value) => onChange({ ...formValue, override: value })}
                >
                    {t.ipConfigurationOverride}
                </Checkbox>
            </Box>
            {multipleNetworkSettings && (
                <IconText
                    align="start"
                    icon="feedback"
                    iconProps={{ color: 'grey6' }}
                    textProps={{ color: 'grey6' }}
                >
                    {t.ipConfigurationOverrideDisabledMsg}
                </IconText>
            )}
        </Stack>
    );
};

const getIpOccupiedText = (duplicatedIp: IOccupiedField) => {
    const { occupiedBy } = duplicatedIp;
    if (occupiedBy.usingAddressAs === 'projectDefaultRouter') {
        return t.ipAddressUsedByProjectRouter;
    }
    const name = occupiedBy?.name;
    const modelName = occupiedBy?.piaModelName;
    const nameAndModelString = `${name}${modelName ? ` (${modelName})` : ''}`;
    return t.ipAddressAlreadyUsedBy(nameAndModelString);
};

DeviceNetworkForm.displayName = 'DeviceNetworkForm';
