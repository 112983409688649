import { css } from '@emotion/css';
import { ColorsEnum } from 'app/styles';

const baseStyle = css`
    padding: 0.5rem 1rem;
    box-sizing: border-box;
`;

export const dashedLeftBorder = css`
    border-inline-start: 1px dashed ${ColorsEnum['grey5']};
`;

export const noRightBorder = css`
    border-inline-end: none;
`;

export const darkRightBorder = css`
    border-inline-end: 1px solid ${ColorsEnum['grey3']};
`;

export const headerStyle = (columnIndex: number, span: number) => css`
    ${dashedLeftBorder}
    padding: 0 1rem;
    grid-column: ${columnIndex} / span ${span};
    font-weight: semibold;
    color: ${ColorsEnum['grey9']};
`;

export const subHeaderStyle = css`
    ${baseStyle}
    border-inline-end: 1px solid ${ColorsEnum['grey2']};
    border-block-end: 1px solid ${ColorsEnum['grey3']};
    font-size: 10px;
    color: ${ColorsEnum['grey6']};
`;

export const dataCellStyle = css`
    ${baseStyle}
    background-color: ${ColorsEnum['white']};
    border-inline-end: 1px solid ${ColorsEnum['grey2']};
    border-block-end: 1px solid ${ColorsEnum['grey2']};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const footerTotalCellStyle = css`
    ${dataCellStyle}
    border-block-start: 2px solid ${ColorsEnum['grey2']};
    border-block-end: 1px dashed ${ColorsEnum['grey5']};
`;

export const footerMarginCellStyle = css`
    ${dataCellStyle}
    border-block-end: none;
    color: ${ColorsEnum['grey6']};
`;
