import * as React from 'react';
import { css } from '@emotion/css';
import { Box } from '../../containers';
import { Heading } from '../../text';
import type { Flex, Colors, Spacings } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IWithChildren } from 'app/components/models';

const CutCornerStyle = css`
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 0;
    inset-inline-end: 0;
    background-image: linear-gradient(
        135deg,
        ${ColorsEnum.grey1},
        ${ColorsEnum.grey1} 50%,
        ${ColorsEnum.white} 50%
    );
    [dir='rtl'] & {
        background-image: linear-gradient(
            225deg,
            ${ColorsEnum.grey1},
            ${ColorsEnum.grey1} 50%,
            ${ColorsEnum.white} 50%
        );
    }
`;

export interface IReportCardProps extends IWithChildren {
    title?: string;
    cutCorner?: boolean;
    panelColor?: Colors;
    panelPaddingX?: Spacings;
    panelPaddingY?: Spacings;
    flex?: Flex;
}

export class ReportCard extends React.Component<IReportCardProps> {
    public render() {
        const {
            cutCorner,
            title,
            flex,
            panelColor = 'grey1',
            panelPaddingX = 'base',
            panelPaddingY = 'half',
        } = this.props;

        return (
            <Box color={panelColor} flex={flex} direction="column" position="relative">
                {title && this.renderTitle(title)}
                <Box minHeight="60px">
                    <Box paddingX={panelPaddingX} paddingY={panelPaddingY} flex="shrinkAndGrow">
                        {this.props.children}
                    </Box>
                </Box>
                {cutCorner && <div className={CutCornerStyle} />}
            </Box>
        );
    }

    private renderTitle = (title: string) => {
        return (
            <>
                <Box color="grey2" paddingX="base" minHeight="40px" alignItems="center">
                    <Heading>{title}</Heading>
                </Box>
                <Box color="yellow" minHeight="3px" />
            </>
        );
    };
}
