import * as React from 'react';
import type { IOptionProps, IAutoTestable } from 'app/components';
import { Select } from 'app/components';
import { videoCompressions } from '../../models';

export interface ICompressionProps extends IAutoTestable {
    compression: number;
    disabled?: boolean;
    onCompressionChange(value: number): void;
}

const compressionOptions: IOptionProps[] = Object.values(videoCompressions).map((compression) => {
    return { key: compression, text: String(compression), value: compression };
});

export class CompressionSelector extends React.PureComponent<ICompressionProps> {
    public render() {
        return (
            <Select
                testIdChild={`${this.props.testId}_compression_selected`}
                value={this.props.compression}
                options={compressionOptions}
                onChange={this.onCompressionChange}
                disabled={this.props.disabled}
            />
        );
    }

    private onCompressionChange = (value: string) => {
        this.props.onCompressionChange(Number(value));
    };
}
