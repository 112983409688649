import { injectable } from 'inversify';
import { UserService } from './user';
import { X_AXIS_USER_API_AUTH, UserApiCommunicator } from './user/services/UserApi.communicator';

// minimum time between requests to server
const ONLINE_CHECK_RATE_LIMIT = 10000;
const AUTHENTICATION_URL = '/authentication';

@injectable()
export class AuthenticationService {
    constructor(
        private userApiCommunicator: UserApiCommunicator,
        private userService: UserService,
    ) {}

    private checkAuthRateLimited = this.rateLimit(() => this.checkAuth(), ONLINE_CHECK_RATE_LIMIT);

    public async isAuthenticated(): Promise<boolean> {
        try {
            const response = await this.checkAuthRateLimited();
            // If response is ok and body is not used, check if user has changed.
            if (response.status === 200 && !response.bodyUsed) {
                const user = await this.userService.getUser();
                // response.json() will consume the body, so we need to
                // check if user has changed before calling it, using response.bodyUsed.
                const body = await response.json();
                if (user?.hid !== body.hid) {
                    // Check if user has changed, previously logged
                    // in user not same as current logged in user.
                    // Reload page to get new user data.
                    window.location.reload();
                }
            }
            return response.status === 200;
        } catch (e) {
            return false;
        }
    }

    public async isOnline(): Promise<boolean> {
        try {
            await this.checkAuthRateLimited();
            return true;
        } catch (e) {
            return false;
        }
    }

    private rateLimit<T>(fn: () => T, limit: number): () => T {
        let lastCallTimeStamp = 0;
        let lastReturnValue: T;
        return () => {
            const now = Date.now();
            if (now - lastCallTimeStamp > limit) {
                lastCallTimeStamp = now;
                lastReturnValue = fn();
            }
            return lastReturnValue;
        };
    }

    private async checkAuth(): Promise<Response> {
        // Early return if no cookie
        if (!this.userService.hasCookie()) {
            return new Response(null, { status: 401 });
        }

        const tokens = await this.userApiCommunicator.fetchUserToken();

        // Never cache this request, IE11 caches this per default
        const headers = new Headers();
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
        headers.append(X_AXIS_USER_API_AUTH, `Bearer ${tokens?.accessToken}`);

        return fetch(AUTHENTICATION_URL, {
            credentials: 'include',
            headers,
        });
    }
}
