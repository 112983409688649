import type { IInstallationPointEntity } from 'app/core/persistence';
import type { IInstallationPointsFilter } from '../models';

export const combineCameraFilterFromAllInstallationPoints = (
    installationPoints: IInstallationPointEntity[],
): IInstallationPointsFilter => {
    const startValue = {
        installationHeight: installationPoints[0].height,
        corridorFormat: false,
        horizontalFov: installationPoints[0].sensors[0]?.settings.horizontalFov,
        distanceToTarget: installationPoints[0].sensors[0]?.target.distance,
        targetHeight: installationPoints[0].sensors[0]?.target.height,
    };

    return installationPoints.reduce((filter, installationPoint) => {
        const newValue = {
            ...filter,
            installationHeight:
                installationPoint.height > filter.installationHeight
                    ? installationPoint.height
                    : filter.installationHeight,
        };
        installationPoint.sensors.forEach((sensor) => {
            newValue.corridorFormat = sensor.settings.corridorFormat
                ? true
                : newValue.corridorFormat;
            newValue.horizontalFov =
                sensor.settings.horizontalFov > newValue.horizontalFov
                    ? sensor.settings.horizontalFov
                    : newValue.horizontalFov;
            newValue.distanceToTarget =
                sensor.target.distance > newValue.distanceToTarget
                    ? sensor.target.distance
                    : newValue.distanceToTarget;
            newValue.targetHeight =
                sensor.target.height < newValue.targetHeight
                    ? sensor.target.height
                    : newValue.targetHeight;
        });
        return newValue;
    }, startValue);
};
