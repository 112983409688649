import * as React from 'react';
import { Box, Grid, NoPrint } from 'app/components';
import { TopRecommendations } from './TopRecommendations.container';
import { GenericItem } from './GenericItem.container';

export const ProductSelectorTopPanel: React.FC = () => {
    return (
        <NoPrint>
            <Grid alignItems="end" spacing="quart">
                <TopRecommendations />
                <Box paddingY="half">
                    <GenericItem />
                </Box>
            </Grid>
        </NoPrint>
    );
};
