import { trigonometry, isDefined } from 'axis-webtools-util';
import { calculateCornerRays, calculateEdgeRays } from './calculateBoundingRays';
import type { Ray } from 'three';
import { Vector3, Plane } from 'three';
import * as PolyBool from 'polybooljs';
import type { IPolygon } from 'app/modules/common';
import type { PanoramaModes } from 'app/core/persistence';
import { getCircle, projectOnGround, toPolygon } from './cameraCalc';

const RENDER_HORIZON = 10e3;

/**
 * Calculate the intersection of a ray and a plane at the specified height
 * If there is no intersection, return a point on the ground plane far away
 * in the direction of the ray
 */
function getIntersection(ray: Ray, height = 0) {
    // if the ray origin is equal to height use a somewhat smaller height
    const calculationHeight = ray.origin.y === height ? height - 0.001 : height;

    const groundPlane = new Plane(new Vector3(0, -1, 0), calculationHeight);
    const farAwayPlane = new Plane(new Vector3(-1, 0, 0), RENDER_HORIZON);
    const leftFarAwayPlane = new Plane(new Vector3(0, 0, -1), RENDER_HORIZON);
    const rightFarAwayPlane = new Plane(new Vector3(0, 0, 1), RENDER_HORIZON);
    const backFarAwayPlane = new Plane(new Vector3(1, 0, 0), RENDER_HORIZON);

    let intersection = ray.intersectPlane(groundPlane, new Vector3());

    // if ray doesn't intersect ground plane, find a point at RENDER_HORIZON and then
    // project this point on ground plane
    if (!intersection || intersection.length() > RENDER_HORIZON) {
        const intersections = [
            ray.intersectPlane(farAwayPlane, new Vector3()),
            ray.intersectPlane(leftFarAwayPlane, new Vector3()),
            ray.intersectPlane(rightFarAwayPlane, new Vector3()),
            ray.intersectPlane(backFarAwayPlane, new Vector3()),
        ].sort((a, b) => (a?.length() ?? Number.MAX_VALUE) - (b?.length() ?? Number.MAX_VALUE));

        intersection = intersections[0]
            ? groundPlane.projectPoint(intersections[0], new Vector3())
            : null;
    }
    return intersection;
}

/**
 * return a polygon representing the visible area of a camera. The visible area is
 * defined as the area where both the ground plane and a plane at the target height
 * is visible through the lens
 */
function calculateNonPanoramaVisibleArea(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
): IPolygon {
    const cornerRays = calculateCornerRays(
        trigonometry.toRadians(horizontalFov),
        verticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
    );

    // find the intersections with a horizontal plane at target height. If target is above camera, there is no intersection
    const intersectionsAtTargetHeight =
        cameraHeight > targetHeight
            ? [
                  getIntersection(cornerRays.topLeft, targetHeight),
                  getIntersection(cornerRays.topRight, targetHeight),
                  getIntersection(cornerRays.bottomRight, targetHeight),
                  getIntersection(cornerRays.bottomLeft, targetHeight),
              ]
            : [
                  getIntersection(cornerRays.topLeft, targetHeight),
                  getIntersection(cornerRays.topRight, targetHeight),
                  getIntersection(cornerRays.topLeft, targetHeight),
                  getIntersection(cornerRays.topRight, targetHeight),
              ];

    // find the intersections with a horizontal plane at ground level
    const intersectionsAtGroundLevel = [
        getIntersection(cornerRays.topLeft),
        getIntersection(cornerRays.topRight),
        getIntersection(cornerRays.bottomRight),
        getIntersection(cornerRays.bottomLeft),
    ];

    // The (boolean) intersection between these two polygons is what we are after
    const intersections = PolyBool.intersect(
        toPolygon(intersectionsAtTargetHeight.filter(isDefined).map(projectOnGround)),
        toPolygon(intersectionsAtGroundLevel.filter(isDefined).map(projectOnGround)),
    );

    return intersections;
}

/**
 * Calculate the visible area for cameras that have a horizontal FOV that is larger than 179 degrees
 */
function calculatePanoramicVisibleArea(
    targetDistance: number,
    panoramaMode: PanoramaModes,
): IPolygon {
    return toPolygon(getCircle(targetDistance, panoramaMode === 'vertical' ? 180 : 360));
}

function calculateIntercomVisibleArea(targetDistance: number, horizontalFov: number): IPolygon {
    return toPolygon(getCircle(targetDistance, horizontalFov));
}

/**
 * Calculate the visible area for wide angle cameras
 */
function calculateWideAngleVisibleArea(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    panoramaMode: PanoramaModes,
): IPolygon {
    const edgeRays = calculateEdgeRays(
        trigonometry.toRadians(horizontalFov),
        verticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
        panoramaMode,
    );

    // determine at which hight we should check for intersections
    const calculationHeight = panoramaMode === 'horizontal' ? targetHeight : 0;

    if (panoramaMode === 'horizontal' && targetHeight > cameraHeight) {
        // special case: ceiling mounted camera where target is even higher
        return {
            regions: [],
            inverted: false,
        };
    }

    // calculate the visible area
    const visibleArea = edgeRays
        .map((ray) => getIntersection(ray, calculationHeight))
        .filter(isDefined);

    const intersections = PolyBool.intersect(
        toPolygon(visibleArea.map(projectOnGround)),
        toPolygon(getCircle(targetDistance, 360)),
    );

    return intersections;
}

export function calculateVisibleArea(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    panoramaMode: PanoramaModes,
    isTiltable: boolean,
): IPolygon {
    if (horizontalFov >= 180 && isTiltable) {
        if (verticalFov < Math.PI) {
            return calculateWideAngleVisibleArea(
                horizontalFov,
                verticalFov,
                cameraHeight,
                targetHeight,
                targetDistance,
                panoramaMode,
            );
        } else {
            return calculatePanoramicVisibleArea(targetDistance, panoramaMode);
        }
    } else if (!isTiltable) {
        return calculateIntercomVisibleArea(targetDistance, horizontalFov);
    } else {
        return calculateNonPanoramaVisibleArea(
            horizontalFov,
            verticalFov,
            cameraHeight,
            targetHeight,
            targetDistance,
        );
    }
}
